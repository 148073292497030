<div mat-dialog-title>
    <h3 class="w-100" style="border-bottom: solid 1px black">
        {{ institutionInfo && institutionInfo.name ? institutionInfo.name : 'Bank' }} Status
    </h3>
</div>
<mat-dialog-content>
    <div *ngIf="!institutionInfo || !institutionInfo.name">Bank status information is unavailable.</div>
    <div *ngIf="institutionInfo && institutionInfo.name">
        <div class="mb-2">
            <div *ngIf="institutionInfo.statuses.item_logins">
                Authorization:
                <span
                    [ngStyle]="
                        institutionInfo.statuses.item_logins.status === 'HEALTHY'
                            ? {color: 'green'}
                            : institutionInfo.statuses.item_logins.status === 'DEGRADED'
                              ? {color: 'orange'}
                              : institutionInfo.statuses.item_logins.status === 'DOWN'
                                ? {color: 'red'}
                                : {}
                    "
                    >{{ institutionInfo.statuses.item_logins.status }}</span
                >
                (changed {{ moment(institutionInfo.statuses.item_logins.last_status_change).fromNow() }})
                <div
                    class="ml-4 font-italic"
                    *ngIf="['DEGRADED'].includes(institutionInfo.statuses.item_logins.status)"
                >
                    Communication issues with banks services. Functionality partially downgraded.
                </div>
                <div class="ml-4 font-italic" *ngIf="['DOWN'].includes(institutionInfo.statuses.item_logins.status)">
                    Communication issues with banks services. Functionality paused.
                </div>
            </div>
            <div *ngIf="!institutionInfo.statuses || !institutionInfo.statuses.item_logins">
                Authorization: Unavailable
            </div>
        </div>
        <div class="mb-2">
            <div *ngIf="institutionInfo.statuses.transactions_updates">
                Transactions:
                <span
                    [ngStyle]="
                        institutionInfo.statuses.transactions_updates.status === 'HEALTHY'
                            ? {color: 'green'}
                            : institutionInfo.statuses.transactions_updates.status === 'DEGRADED'
                              ? {color: 'orange'}
                              : institutionInfo.statuses.transactions_updates.status === 'DOWN'
                                ? {color: 'red'}
                                : {}
                    "
                    >{{ institutionInfo.statuses.transactions_updates.status }}</span
                >
                (changed {{ moment(institutionInfo.statuses.transactions_updates.last_status_change).fromNow() }})
                <div
                    class="ml-4 font-italic"
                    *ngIf="['DEGRADED'].includes(institutionInfo.statuses.transactions_updates.status)"
                >
                    Communication issues with banks services. Functionality partially downgraded.
                </div>
                <div
                    class="ml-4 font-italic"
                    *ngIf="['DOWN'].includes(institutionInfo.statuses.transactions_updates.status)"
                >
                    Communication issues with banks services. Functionality paused.
                </div>
            </div>
            <div *ngIf="!institutionInfo.statuses || !institutionInfo.statuses.transactions_updates">
                Transactions: Unavailable
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex justify-content-end w-100">
        <button mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">Ok</button>
    </div>
</mat-dialog-actions>
