<div class="m-3" style="padding-bottom: 110px">
    <h3 class="d-flex align-items-center">
        <button mat-icon-button class="mat-primary sidenav-button" (click)="closeSidebar.emit()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        {{ transaction?.journal?.reason_type | fromSnakeCase }}
        <i class="sp-spacer"></i>
        <div
            [matTooltip]="
                'Reconciled transaction and transaction with related reconciled transactions can not be unmatched.'
            "
            [matTooltipDisabled]="!canNotBeUnmatched"
        >
            <button
                mat-button
                class="mat-primary sidenav-button"
                [disabled]="canNotBeUnmatched"
                *ngIf="transaction?.matched__transaction_external_id"
                (click)="unmatch()"
            >
                Unmatch
            </button>
        </div>
    </h3>
    <mat-card>
        <div class="row">
            <div class="col-4 text-muted align-text-right">Date:</div>
            <div class="col-8" *ngIf="transaction && transaction.journal">
                {{ transaction.journal.date | dateFromNumber | date: 'mediumDate' }}
            </div>
        </div>

        <div class="row">
            <div class="col-4 text-muted align-text-right">Type:</div>
            <div class="col-8 text-capitalize">{{ transaction?.debit_or_credit }}</div>
        </div>
        <div class="row">
            <div class="col-4 text-muted align-text-right">Amount:</div>
            <div class="col-8">
                <span>{{ transaction?.amount | currency: 'USD' : 'symbol' }}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-4 text-muted align-text-right">Source:</div>
            <div class="col-8">
                <a
                    *ngIf="
                        transaction?.journal?.related_entity_id ||
                        (transaction?.journal?.reason_type &&
                            $any(['manual_journal', 'opening_balance']).includes(transaction?.journal?.reason_type))
                    "
                    [routerLink]="getReferenceLink(transaction)"
                >
                    {{
                        transaction?.journal?.reference_number || (transaction?.journal?.reason_type | fromSnakeCase)
                    }}</a
                >
                <span
                    *ngIf="
                        !(
                            transaction?.journal?.related_entity_id ||
                            (transaction?.journal?.reason_type &&
                                $any(['manual_journal', 'opening_balance']).includes(transaction?.journal?.reason_type))
                        )
                    "
                >
                    {{
                        transaction?.journal?.reference_number || (transaction?.journal?.reason_type | fromSnakeCase)
                    }}</span
                >
            </div>
        </div>

        <div class="row">
            <div class="col-4 text-muted align-text-right">Description:</div>
            <div class="col-8">
                {{ transaction?.description }}
                <span *ngIf="!transaction?.description">&mdash;</span>
            </div>
        </div>

        <div class="row">
            <div class="col-4 text-muted align-text-right">Contact:</div>
            <div class="col-8">
                <a routerLink="/contacts/{{ transaction?.contact_fk_id }}">{{ transaction?.contact?.display_name }}</a>
                <span *ngIf="!transaction?.contact_fk_id">&mdash;</span>
            </div>
        </div>
    </mat-card>

    <div *ngIf="matchedDataSource.data.length > 0">
        <p class="mt-3 mb-1">Other transactions in match:</p>
        <table mat-table [dataSource]="matchedDataSource" class="mat-elevation-z8 match-table w-100">
            <ng-container matColumnDef="transaction">
                <td mat-cell *matCellDef="let element" class="match-cell">
                    <p *ngIf="element?.amount || element?.amount === 0">
                        <span class="text-capitalize">{{ element?.debit_or_credit }}</span> for:
                        <span>{{ element?.amount | currency: 'USD' : 'symbol' }}</span>
                    </p>
                    <p *ngIf="element?.journal?.date">
                        Dated: {{ element?.journal?.date | dateFromNumber | date: 'mediumDate' }}
                    </p>
                    <p *ngIf="element?.ledger_account">Account: {{ element?.ledger_account?.name }}</p>
                </td>
            </ng-container>

            <tr mat-row *matRowDef="let row; columns: matchedDisplayedColumns"></tr>
        </table>
    </div>

    <div *ngIf="externalMatchedDataSource.data.length > 0">
        <p class="mt-3 mb-1">External transactions in match:</p>
        <table mat-table [dataSource]="externalMatchedDataSource" class="mat-elevation-z8 match-table w-100">
            <ng-container matColumnDef="transaction">
                <td mat-cell *matCellDef="let element" class="match-cell">
                    <p *ngIf="element.amount || element.amount === 0">
                        <span class="text-capitalize">{{ element?.debit_or_credit }}</span> for:
                        <span>{{ element?.amount | currency: 'USD' : 'symbol' }}</span>
                    </p>
                    <p *ngIf="element.date">Dated: {{ element.date | dateFromNumber | date: 'mediumDate' }}</p>
                    <p *ngIf="element.name">Name: {{ element.name }}</p>
                </td>
            </ng-container>

            <tr mat-row *matRowDef="let row; columns: matchedDisplayedColumns"></tr>
        </table>
    </div>
</div>
