import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RbacModule} from '../rbac/rbac.module';
import {SharedModule} from '../shared/shared.module';
import {IndexComponent} from './pages/index.component';
import {CompanyGoalsComponent} from './common/company-goals.component';
import {GoalCreateComponent} from './common/goal-create.component';
import {GoalGeneralComponent} from './common/goal-general.component';
import {GoalMandatoryComponent} from './common/goal-mandatory.component';
import {GoalProgressComponent} from './common/goal-progress.component';
import {routing} from './goals.routing';

@NgModule({
    declarations: [
        // components
        CompanyGoalsComponent,
        GoalCreateComponent,
        GoalGeneralComponent,
        GoalMandatoryComponent,
        GoalProgressComponent,
        // pages
        IndexComponent
    ],
    imports: [
        RbacModule,
        SharedModule
        // routing,
    ],
    providers: [],
    exports: [
        BrowserModule,
        // components
        GoalProgressComponent,
        GoalGeneralComponent,
        GoalMandatoryComponent,
        GoalCreateComponent,
        CompanyGoalsComponent
    ],
    bootstrap: [IndexComponent]
})
export class GoalsModule {}
