import {Component, OnDestroy, OnInit} from '@angular/core';
import {pipe, Subject} from 'rxjs';
import {
    IBill,
    IBillItem,
    IGroup,
    IInvoiceItem,
    IProduct,
    IRecurringBill,
    IRecurringInvoicePeriod
} from '@cyberco-nodejs/zipi-typings';
import {AbstractControl, FormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {PAYMENT_TERMS, RECURRING_INVOICE_PERIOD} from 'app/local-typings';
import * as moment from 'moment';
import {GenericFormArray} from 'app/entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {ContactCreateDialogComponent} from '../../../../contacts/contact-dialogs/contact-create-dialog/contact-create-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {CreateProductDialogComponent} from '../../products/create-product-dialog/create-product-dialog.component';
import {FetchProducts} from '../../../store/finance.actions';
import {select, Store} from '@ngrx/store';
import {selectProducts} from '../../../store/finance.selectors';
import {IFinanceState} from '../../../store/finance.reducer';
import {get as getProp, isEqual} from 'lodash-es';
import {RecurringBillsService} from '../../../../../services/api/finance/recurring-bills.service';
import {GroupsSource} from 'app/services/sources/groups.source';
import {FormGroupArray, FormGroupWithFormControls} from '../../../../../typings/common';
import {currencyMaskitoOptions, unmaskCurrencyControlValue} from '../../../../../utilities/maskito';

@Component({
    selector: 'app-recurring-bill-edit',
    templateUrl: './recurring-bill-edit.component.html',
    styleUrls: ['./recurring-bill-edit.component.scss']
})
export class RecurringBillEditComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    repeatPeriod = RECURRING_INVOICE_PERIOD;
    repeat_custom: IRecurringInvoicePeriod | null = null;
    paymentTerms = PAYMENT_TERMS;

    currencyMaskitoMask = currencyMaskitoOptions;
    amountWithCurrencyMask: boolean[] = [];
    rateWithCurrencyMask: boolean[] = [];

    recurringBill: IRecurringBill | undefined;

    itemsArray: FormGroupArray = this.fb.array([this.createItem()]) as FormGroupArray;
    formGroup: FormGroupWithFormControls = this.fb.group({
        title: ['', Validators.required],
        status: ['active', Validators.required],
        start_on: [null, Validators.required],
        end_on: null,
        repeat: [{frequency: 1, period: 'month', is_anniversary: false}, [Validators.required]],
        repeat_custom_days: [1, []],
        payment_terms: ['due-on-receipt', Validators.required],
        payment_terms_custom: [null, []],
        create_bill_for_current_period: [false, []],
        total_amount: [{value: 0, disabled: true}, [Validators.required]],
        pay_to__payment_method_fk_ids: [[], []],
        paid_by__payment_method_fk_id: [null, []],
        items: this.itemsArray,
        money_receiver__contact_fk_id: [null, []],
        money_receiver__contact_class_fk_id: [null, []],
        wildcard_usage_mode: ['skip', []]
    }) as FormGroupWithFormControls;
    emptyItem = {rate: 0, quantity: 1, amount: 0};

    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    savedContacts: number[] = [];
    savedContactClasses: number[] = [];

    products: IProduct[] = [];

    today: number | null = null;
    isCreateInvoiceForCurrent: boolean = false;

    hasWildcardSelected: boolean = false;

    lastGroupId: number | null = null;

    groupList: IGroup[] = [];

    constructor(
        private fb: UntypedFormBuilder,
        protected activatedRoute: ActivatedRoute,
        public dialog: MatDialog,
        private store: Store<IFinanceState>,
        private recurringBillsService: RecurringBillsService,
        public router: Router,
        private groupSrc: GroupsSource
    ) {}

    comparePeriod: (o1: any, o2: any) => boolean = isEqual;

    private computeTotal = () => {
        if (this.hasWildcardSelected) {
            this.formGroup.controls.total_amount.patchValue(0);
        } else {
            const total = this.itemsArray.controls.reduce<number>((acc: number, item: AbstractControl) => {
                const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
                return itemGroup.getRawValue().amount + acc;
            }, 0);
            this.formGroup.controls.total_amount.patchValue(total);
        }
    };

    ngOnInit() {
        this.store.dispatch(new FetchProducts());
        this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe)).subscribe((data) => {
            this.products = data;
        });

        this.today = Number(moment().startOf('day').format('YYYYMMDD'));

        this.lastGroupId = Number(localStorage.getItem('current_company_group_id'));

        this.initGroup();

        this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (!isNaN(params['id'])) {
                const recurringBillId = params['id'];

                this.recurringBillsService
                    .getRecurringBillById(recurringBillId)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((recurringBill) => {
                        this.recurringBill = recurringBill;
                        this.formGroup.patchValue(recurringBill);

                        const items = getProp(recurringBill, 'items');
                        if (!items || items.length === 0) {
                            return;
                        }
                        this.itemsArray.removeAt(0);
                        items.forEach((item) => this.addItem(item));

                        if (recurringBill.repeat.period === 'custom') {
                            this.formGroup.controls.repeat.patchValue({frequency: 1, period: 'custom'});
                            this.formGroup.controls.repeat_custom_days.patchValue(recurringBill.repeat.frequency);
                        }
                        if (this.recurringBill && this.recurringBill.money_receiver__contact_fk_id) {
                            this.savedContacts = [this.recurringBill.money_receiver__contact_fk_id];
                        } else if (this.recurringBill && this.recurringBill.money_receiver__contact_class_fk_id) {
                            this.savedContactClasses = [this.recurringBill.money_receiver__contact_class_fk_id];
                        }

                        if (!recurringBill.wildcard_usage_mode) {
                            recurringBill.wildcard_usage_mode = 'skip';
                        }
                    });
            }
        });

        this.moneySenderCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            if (values && values.length > 0) {
                if (values[0].type === 'contact') {
                    this.formGroup.controls.money_receiver__contact_fk_id.setValue(values[0].target_id);
                } else if (values[0].type === 'contact_class') {
                    this.formGroup.controls.money_receiver__contact_class_fk_id.setValue(values[0].target_id);
                }
            } else {
                this.formGroup.controls.money_receiver__contact_fk_id.reset(null);
                this.formGroup.controls.money_receiver__contact_class_fk_id.reset(null, {emitEvent: false});
            }
        });

        this.itemsArray.valueChanges
            .pipe(
                tap(() => this.computeTotal()),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.formGroup.controls.start_on.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            if (Number.isInteger(value) && this.today) {
                this.isCreateInvoiceForCurrent = value < this.today;
            }
        });

        this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
            this.groupList = list.filter((group) => group.type === 'division') as IGroup[];
        });
    }

    initGroup() {
        this.formGroup.controls.repeat.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            if (value && value.period === 'custom') {
                this.repeat_custom = {
                    frequency: this.formGroup.controls.repeat_custom_days.value,
                    period: 'custom',
                    is_anniversary: false
                };
            } else {
                this.repeat_custom = null;
            }
        });

        this.formGroup.controls.repeat_custom_days.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            this.repeat_custom = {
                frequency: value,
                period: 'custom',
                is_anniversary: false
            };
        });
    }

    private createItem(item?: IBillItem, itemIndex: number = 0) {
        const group = this.fb.group(
            {
                product_fk_id: [item ? item.product_fk_id : null],
                name: [{value: item ? item.name : '', disabled: item && item.product_fk_id}, Validators.required],
                description: item ? item.description : '',
                quantity: [item ? item.quantity : 1, [Validators.required, Validators.min(1)]],
                rate: [{value: item ? item.rate : 0, disabled: false}],
                rate__wildcard_fk_id: [
                    {
                        value: item && item.rate__wildcard_fk_id ? item.rate__wildcard_fk_id : null,
                        disabled: false
                    }
                ],
                quantity__wildcard_fk_id: [
                    {
                        value: item && item.quantity__wildcard_fk_id ? item.quantity__wildcard_fk_id : null,
                        disabled: false
                    }
                ],
                amount: [{value: item ? item.amount : 0, disabled: true}],
                ledger_account_fk_id: [item ? item.ledger_account_fk_id : null, Validators.required],
                division__company_group_fk_id: [
                    {
                        value: item ? item.division__company_group_fk_id : this.lastGroupId,
                        disabled: !!this.lastGroupId
                    }
                ],
                selected_divisions: new GenericFormArray<ChipNode>([]),
                selected_division_ids: [item ? item.division__company_group_fk_id : null],
                selected_full_ledger: null
            },
            {validator: this.customAmountValidator}
        );

        group.controls.selected_divisions.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (changes && changes.length > 0) {
                group.controls.division__company_group_fk_id.setValue(changes[0].target_id);
            } else {
                group.controls.division__company_group_fk_id.setValue(null);
            }
        });

        const handleValueChanges = pipe(
            takeUntil(this.unsubscribe),
            tap(() => group.controls.amount.patchValue(group.controls.quantity.value * group.controls.rate.value)),
            tap(() => {
                this.amountWithCurrencyMask[itemIndex] = String(group.controls.amount.value).length < 13;
            })
        );

        const unmaskQtyFn = unmaskCurrencyControlValue(group.controls.quantity);
        const unmaskRateFn = unmaskCurrencyControlValue(group.controls.rate);

        group.controls.quantity.valueChanges
            .pipe(
                unmaskQtyFn,
                handleValueChanges,
                tap(() => this.computeTotal()),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
        group.controls.rate.valueChanges
            .pipe(
                unmaskRateFn,
                handleValueChanges,
                tap(() => this.computeTotal()),
                tap(() => {
                    this.rateWithCurrencyMask[itemIndex] = String(group.controls.rate.value).length < 13;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        group.controls.rate__wildcard_fk_id.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.checkHasWildcard());
        group.controls.quantity__wildcard_fk_id.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.checkHasWildcard());

        if (item) {
            group.patchValue(item);
        }
        return group;
    }

    private checkHasWildcard = () => {
        const hasWildcardSelected = this.itemsArray
            .getRawValue()
            .find((item) => !!item.rate__wildcard_fk_id || !!item.quantity__wildcard_fk_id);

        this.hasWildcardSelected = !!hasWildcardSelected;
    };

    customAmountValidator = (formGroup: UntypedFormGroup) => {
        const value = formGroup.controls.rate.value;
        const wildcardId = formGroup.controls.rate__wildcard_fk_id.value;
        if (!!wildcardId) {
            return null;
        }
        if (value === null || value === undefined) {
            return {invalidValue: true};
        }
        if (isNaN(Number(value))) {
            return {invalidValue: true};
        }
        if (Number(value) <= 0) {
            return {invalidValue: true};
        }
        return null;
    };

    addItem(item?: IBillItem) {
        this.itemsArray.push(this.createItem(item, this.itemsArray.length));
    }

    trimOnBlur(control: AbstractControl | null) {
        if (control && control.value) {
            const trimmedValue = control.value.trim().length === 0 ? null : control.value.trim();
            control.patchValue(trimmedValue);
        }
    }

    selectItem(item: AbstractControl, product: IProduct) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.controls.product_fk_id.patchValue(product.product_id);
        itemGroup.controls.rate.patchValue(product.price);
        itemGroup.controls.rate__wildcard_fk_id.patchValue(null);
        itemGroup.controls.description.patchValue(product.description);
        itemGroup.controls.ledger_account_fk_id.patchValue(product.ledger_account_fk_id);
        itemGroup.controls.quantity.patchValue(1);

        itemGroup.controls.ledger_account_fk_id.disable();
        itemGroup.controls.name.disable();
    }

    removeProductFkId(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.controls.ledger_account_fk_id.enable();
        itemGroup.controls.name.enable();
        itemGroup.controls.name.patchValue(null);
        itemGroup.controls.product_fk_id.patchValue(null);
    }

    resetItem(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.reset(this.emptyItem);
        itemGroup.controls.product_fk_id.patchValue(null);
        itemGroup.controls.name.patchValue(null);
        itemGroup.controls.description.patchValue(null);
        itemGroup.controls.ledger_account_fk_id.patchValue(null);
        itemGroup.controls.name.enable();
        itemGroup.controls.ledger_account_fk_id.enable();
    }

    changeStatus(status: 'active' | 'stopped') {
        this.formGroup.controls.status.setValue(status);
    }

    initEdit() {
        if (!this.recurringBill!.wildcard_usage_mode) {
            this.recurringBill!.wildcard_usage_mode = 'skip';
        }
        this.formGroup.patchValue(this.recurringBill!);

        if (this.recurringBill!.repeat.period === 'custom') {
            this.formGroup.controls.repeat.patchValue({frequency: 1, period: 'custom'});
            this.formGroup.controls.repeat_custom_days.patchValue(this.recurringBill!.repeat.frequency);
        }

        if (this.recurringBill && this.recurringBill.money_receiver__contact_fk_id) {
            this.savedContacts = [this.recurringBill.money_receiver__contact_fk_id];
        } else if (this.recurringBill && this.recurringBill.money_receiver__contact_class_fk_id) {
            this.savedContactClasses = [this.recurringBill.money_receiver__contact_class_fk_id];
        }

        const items = this.recurringBill!.items;
        if (!items || items.length === 0) {
            return;
        }
        this.itemsArray.removeAt(0);
        items.forEach((item) => this.addItem(item));
    }

    contactCreate() {
        const dialogRef = this.dialog.open(ContactCreateDialogComponent, {
            autoFocus: false,
            data: {
                category: 'customer'
            }
        });

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    createProduct() {
        const dialogRef = this.dialog.open(CreateProductDialogComponent, {});

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    getProducts(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        if (itemGroup.getRawValue().name) {
            const filteredValue = itemGroup.getRawValue().name.toLowerCase().trim();
            return this.products.filter((pr) => pr.name.toLowerCase().trim().includes(filteredValue));
        }

        return this.products;
    }

    createRecurringBill() {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            return;
        }

        const recurringBillDataForCreate = this.formGroup.getRawValue();

        recurringBillDataForCreate.items.forEach((item: {[key: string]: any}) => {
            delete item.selected_divisions;
            delete item.selected_division_ids;
            delete item.selected_full_ledger;
        });

        if (this.repeat_custom) {
            recurringBillDataForCreate.repeat = this.repeat_custom;
        }

        this.recurringBillsService
            .createRecurringBill(recurringBillDataForCreate)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((recurringBill) => {
                if (recurringBill) {
                    this.router.navigate(['/purchases/bills/recurring']);
                }
            });
    }

    updateRecurringBill() {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            return;
        }

        const recurringBillDataForUpdate = this.formGroup.getRawValue();

        if (this.repeat_custom) {
            recurringBillDataForUpdate.repeat = this.repeat_custom;
        }
        recurringBillDataForUpdate.items.forEach((item: {[key: string]: any}) => {
            delete item.selected_divisions;
            delete item.selected_division_ids;
            delete item.selected_full_ledger;
        });

        if (this.recurringBill && this.recurringBill.recurring_bill_id) {
            this.recurringBillsService
                .updateRecurringBill(this.recurringBill.recurring_bill_id, recurringBillDataForUpdate)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((recurringBill) => {
                    if (recurringBill) {
                        this.router.navigate(['/purchases/bills/recurring']);
                    }
                });
        }
    }

    deleteRecurringBill() {
        if (this.recurringBill && this.recurringBill.recurring_bill_id) {
            this.recurringBillsService
                .deleteRecurringBill(this.recurringBill.recurring_bill_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((recurringBill) => {
                    if (recurringBill) {
                        this.router.navigate(['/purchases/bills/recurring']);
                    }
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
