<div class="mt-3 mb-3">
    <!------ Create note button ------>
    <div class="w-100 py-3 mb-4 d-flex justify-content-center">
        <button
            type="button"
            rbac
            [rule]="{contacts__manage_personnel_records: true}"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'block'}"
            [denyStyle]="{display: 'none'}"
            mat-button
            (click)="openCreateNote()"
        >
            <mat-icon>add</mat-icon>
            Record
        </button>
    </div>

    <!------ Create note field ------>
    <div class="w-100 p-3 pt-0 mb-4 d-flex flex-column align-items-center" *ngIf="createMode">
        <mat-form-field class="w-80">
            <label for="noteCreateText" hidden>Create note</label>
            <textarea matInput placeholder="Leave a note" id="noteCreateText" [(ngModel)]="text"></textarea>
        </mat-form-field>

        <div *ngIf="errorMsg" class="w-80 d-flex align-slf-start font-weight-bold mb-3" style="color: indianred">
            <span>{{ errorMsg }}</span>
            <i class="material-icons ml-3" (click)="clearError()" style="cursor: pointer">close</i>
        </div>

        <div class="w-80 my-3" *ngIf="noteCreateDoc.value">
            <span class="font-weight-bold">File name: </span>
            {{ sourceFileDocs!.name }}
            <span class="font-italic">({{ sourceFileDocs!.size / 1048576 | number: '1.0-2' }} Mb)</span>
        </div>

        <div class="w-80 mb-3" *ngIf="loadingProgress !== null">
            <mat-progress-bar mode="determinate" [value]="loadingProgress"></mat-progress-bar>
        </div>

        <div class="w-80 d-flex justify-content-between">
            <div>
                <input type="file" hidden #noteCreateDoc (input)="handleUploadFileToBuffer(noteCreateDoc.files)" />
                <div class="mr-auto" *ngIf="!noteCreateDoc.value">
                    <button
                        mat-raised-button
                        rbac
                        [rule]="{contacts__view_personnel_records: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'block'}"
                        [denyStyle]="{display: 'none'}"
                        class="fix-focus-outline"
                        (click)="noteCreateDoc.click()"
                    >
                        Upload
                    </button>
                </div>

                <button
                    mat-raised-button
                    *ngIf="noteCreateDoc.value"
                    (click)="$event.stopPropagation(); closeFileMode()"
                >
                    Cancel attaching file
                </button>
            </div>

            <div>
                <button mat-raised-button color="accent" [disabled]="text.length === 0" (click)="addNote()">Ok</button>
                <button mat-raised-button (click)="closeNote()" class="ml-2">Cancel</button>
            </div>
        </div>
    </div>

    <!------ Notes list ------>
    <ng-container *ngIf="notes && notes.length > 0; else elseEmpty">
        <div class="w-100" *ngFor="let note of notes">
            <div class="d-flex mb-4">
                <!------ Note date ------>
                <h3 class="col-3 d-flex justify-content-end">
                    {{ note.created_at | date: 'mediumDate' }}
                </h3>

                <!------ Note text ------>
                <div class="col-6" *ngIf="editMode !== note.id; else editable">
                    <div class="w-100">{{ note.text }}</div>
                    <div class="w-100 mt-1" style="font-size: 12px">
                        {{ note.creator_profile!.first_name }} {{ note.creator_profile!.last_name }} ({{
                            note.creator_profile!.email
                        }})
                    </div>
                </div>

                <!------ Edit note ------>
                <ng-template #editable>
                    <div class="col-6">
                        <mat-form-field class="w-100">
                            <label for="noteEditText" hidden>Edit note</label>
                            <textarea
                                matInput
                                placeholder="Edit a note"
                                id="noteEditText"
                                [(ngModel)]="note.text"
                                #noteEditText
                            ></textarea>
                        </mat-form-field>
                        <div class="w-100 d-flex justify-content-end">
                            <button
                                mat-raised-button
                                color="accent"
                                (click)="editNote(noteEditText.value, note)"
                                [disabled]="note.text.length === 0"
                            >
                                Ok
                            </button>
                            <button mat-raised-button (click)="closeNote()" class="ml-2">Cancel</button>
                        </div>
                    </div>
                </ng-template>

                <!------ Note buttons ------>
                <div class="col-3 px-2 d-flex justify-content-end">
                    <button
                        type="button"
                        mat-icon-button
                        matTooltip="Download document"
                        *ngIf="note.document_fk_id"
                        rbac
                        [rule]="{contacts__view_personnel_records: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'block'}"
                        [denyStyle]="{display: 'none'}"
                        (click)="downloadFile(note.document_fk_id)"
                    >
                        <mat-icon mat-list-icon>get_app</mat-icon>
                    </button>
                    <button
                        type="button"
                        mat-icon-button
                        matTooltip="Edit note"
                        rbac
                        [rule]="{contacts__manage_personnel_records: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'block'}"
                        [denyStyle]="{display: 'none'}"
                        *ngIf="profileId === note.creator__profile_fk_id"
                        (click)="openEditNote(note)"
                        [disabled]="editMode === note.id"
                    >
                        <mat-icon mat-list-icon>edit</mat-icon>
                    </button>
                    <button
                        type="button"
                        mat-icon-button
                        matTooltip="Delete note"
                        rbac
                        [rule]="{contacts__manage_personnel_records: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'block'}"
                        [denyStyle]="{display: 'none'}"
                        *ngIf="note.id && profileId === note.creator__profile_fk_id"
                        (click)="deleteNote(note.id)"
                        [disabled]="editMode === note.id"
                    >
                        <mat-icon mat-list-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>

    <!------ Empty list ------>
    <ng-template #elseEmpty>
        <div class="text-center p-4">No Records Found</div>
    </ng-template>
</div>
