import {Injectable} from '@angular/core';
import {ServiceRequester} from '../service.requester';
import {NotificationsServiceZipi} from '../../modules/notifications/notifications.service';

@Injectable()
export class ProfileAccessApi {
    private url = '/profile-access/';

    constructor(
        public requester: ServiceRequester,
        protected notificationsService: NotificationsServiceZipi
    ) {}

    disconnectCurrentProfileFromCompany(): Promise<any> {
        return this.requester.makeMsCall(this.url + 'disconnect', 'DELETE');
    }
    removeAccessFromCompany(): Promise<any> {
        return this.requester.makeMsCall(this.url + 'remove-access', 'DELETE');
    }
}
