import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {firstValueFrom, Subject} from 'rxjs';
import {map, filter, takeUntil} from 'rxjs/operators';
import {IInvoice, IPaymentMethod, IFinancialTransfer, IInvoiceItem, IBill} from '@cyberco-nodejs/zipi-typings';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {BillsService} from 'app/services/api/finance/bills.service';
import {MatDialog} from '@angular/material/dialog';
import {ProductMatchingDialogComponent} from '../../common/product-matching-dialog/product-matching-dialog.component';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {FinancialTransferService} from '../../../services/financial-transfer.service';
import {assign} from 'lodash-es';
import {ShipperContactsService} from '../../../../../services/api/shipper.contacts.service';

@Component({
    selector: 'app-payout-for-approve-as-invoice-page',
    templateUrl: 'payout-for-approve-as-invoice-page.component.html',
    styleUrls: ['payout-for-approve-as-invoice-page.component.css']
})
export class PayoutForApproveAsInvoicePageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    payout: (IFinancialTransfer & {items: IInvoiceItem[]; created_at: number}) | undefined;

    connectedBill: IBill | undefined;
    connectedInvoice: IInvoice | undefined;

    currentAmount: UntypedFormControl;
    currentMethod: IPaymentMethod | undefined;

    constructor(
        private activatedRoute: ActivatedRoute,
        private invoiceSrv: InvoicesService,
        private billsService: BillsService,
        private financialTransferService: FinancialTransferService,
        private router: Router,
        public dialog: MatDialog,
        private fb: UntypedFormBuilder,
        private contactService: ShipperContactsService
    ) {
        this.currentAmount = this.fb.control(0, []);
    }

    ngOnInit() {
        this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['id']) {
                this.financialTransferService
                    .getFinancialTransferById(params['id'])
                    .pipe(
                        map((transfer) => {
                            const items = {
                                items: [
                                    {
                                        product_fk_id: transfer.result.product!.product_id,
                                        name: transfer.result.product!.name,
                                        description: transfer.result.product!.description,
                                        quantity: 1,
                                        rate: transfer.result.amount,
                                        amount: transfer.result.amount,
                                        ledger_account_fk_id: transfer.result.product!.ledger_account_fk_id
                                    }
                                ]
                            };

                            return assign(transfer.result, items);
                        }),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe((transfer) => {
                        this.payout = transfer as IFinancialTransfer & {items: IInvoiceItem[]; created_at: number};

                        if (this.payout && this.payout.partner__bill_fk_id) {
                            this.billsService
                                .getBillForApproveById(this.payout.partner__bill_fk_id)
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((bill) => {
                                    this.connectedBill = bill;
                                });
                        }

                        if (this.payout && this.payout.partner__invoice_fk_id) {
                            this.invoiceSrv
                                .getInvoiceForApproveById(this.payout.partner__invoice_fk_id)
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((response) => {
                                    this.connectedInvoice = response.invoice;
                                });
                        }
                    });
            }
        });
    }

    async createAndConnectInvoiceFromPayout() {
        if (this.payout && this.payout.partner__bill_fk_id) {
            const billsForBulkArray = [];
            if (this.connectedBill && this.connectedBill.payments && this.connectedBill.payments.length > 0) {
                const billsForBulk: {[key: number]: IBill} = {};
                for (const pay of this.connectedBill.payments) {
                    if (pay.source_payment_made && pay.source_payment_made.related_payments) {
                        for (const relatedPay of pay.source_payment_made.related_payments) {
                            if (relatedPay.bill && relatedPay.bill.bill_id) {
                                billsForBulk[relatedPay.bill.bill_id] = relatedPay.bill;
                            }
                        }
                    }
                }
                for (const key in billsForBulk) {
                    if (billsForBulk.hasOwnProperty(key)) {
                        billsForBulkArray.push(billsForBulk[key]);
                    }
                }
            }
            let contact = null;
            if (this.connectedBill && this.connectedBill.owner__company_fk_id) {
                contact = await firstValueFrom(
                    this.contactService.getContactInMyCompanyByPartnerCompanyId(this.connectedBill.owner__company_fk_id)
                );
            }
            const dialogRef = this.dialog.open(ProductMatchingDialogComponent, {
                maxHeight: '80vh',
                autoFocus: false,
                data: {
                    source_document: this.connectedBill,
                    source_documents: billsForBulkArray,
                    contact: contact
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((matchResult) => {
                    if (this.connectedBill) {
                        this.billsService
                            .approveBills({
                                ...matchResult,
                                source_bill_id: this.connectedBill.bill_id
                            })
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((result) => {
                                if (result) {
                                    this.router.navigate(['/sales/invoices']);
                                }
                            });
                    }
                });
        } else {
            const dialogRef = this.dialog.open(ProductMatchingDialogComponent, {
                maxHeight: '80vh',
                autoFocus: false,
                data: {
                    payout: this.payout
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((matchResult) => {
                    if (this.payout) {
                        this.financialTransferService
                            .createInvoiceFromPayout(this.payout.financial_transfer_id as number, matchResult)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((result) => {
                                if (result) {
                                    this.router.navigate(['/sales/invoices']);
                                }
                            });
                    }
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
