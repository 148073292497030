<div class="mt-4 mx-4">
    <div *ngIf="invoice" style="text-align: center">
        <img
            style="width: 180px"
            src="https://storage.googleapis.com/zipi-static/app-assets/skyslope-books-logo.svg"
            alt="SkySlope Books"
        />
    </div>

    <mat-card
        *ngIf="
            invoice &&
            !invoicePaid &&
            !invoice.deleted_at &&
            !['write_off', 'void', 'rejected'].includes(invoice.summary_status)
        "
        class="mx-5 mt-5"
        style="margin-bottom: 157px"
    >
        <div class="d-flex justify-content-between bg-darker text-white p-3 title-header">
            <div>
                <div class="font-weight-bold">
                    {{ invoice.owner_company?.title }}
                </div>
                <div>Status: {{ invoice.status_of_payment | fromSnakeCase }}</div>
                <div>Due: {{ $any(invoice.due_date) | dateFromNumber | amDateFormat: 'MMM DD, YYYY' }}</div>
            </div>

            <div class="text-right">
                <div>#{{ invoice.invoice_number || 'N/A' }}</div>
                <div style="font-size: 33px">{{ invoice?.total_amount | currency }}</div>
            </div>
        </div>

        <table class="table">
            <thead>
                <tr>
                    <td class="w-60 font-weight-bold">Item</td>
                    <td class="text-left font-weight-bold">Quantity</td>
                    <td class="text-left font-weight-bold">Rate</td>
                    <td class="text-left font-weight-bold">Amount</td>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of invoice?.items; index as idx">
                    <td>
                        <div>{{ item?.name }}</div>
                        <div style="font-size: 14px; color: #8c8c8c">{{ item?.description }}</div>
                    </td>
                    <td class="text-left">{{ item.quantity }}</td>
                    <td class="text-left">{{ item.rate | currency }}</td>
                    <td class="text-left">{{ item.amount | currency }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="2"></td>
                    <td class="text-left">Sub Total</td>
                    <td class="text-left">{{ invoice?.total_amount | currency }}</td>
                </tr>
                <tr>
                    <td colspan="2"></td>
                    <td class="text-left font-weight-bold">Total</td>
                    <td class="text-left font-weight-bold">{{ invoice?.total_amount | currency }}</td>
                </tr>

                <ng-container *ngIf="invoice.payments && invoice.payments.length > 0">
                    <ng-container *ngFor="let payment of invoice.payments; let i = index">
                        <tr
                            *ngIf="
                                ['completed', 'processing', 'pending', 'scheduled', 'review'].includes(payment.status)
                            "
                        >
                            <td colspan="2"></td>
                            <td class="text-left">
                                <!--                                <span class="zp-w-150 remove-payment-container">-->
                                <span
                                    *ngIf="
                                        payment.source_type === 'vendor_credit' ||
                                        payment.source_type === 'customer_credit' ||
                                        payment.source_type === 'based_on_vendor_credit' ||
                                        payment.source_type === 'based_on_customer_credit'
                                    "
                                    >Credits</span
                                >
                                <span
                                    *ngIf="
                                        (payment.source_type === 'payment_made' ||
                                            payment.source_type === 'payment_received' ||
                                            payment.source_type === 'deduction') &&
                                        payment.type === 'normal'
                                    "
                                    >Payment</span
                                >
                                <span
                                    *ngIf="
                                        (payment.source_type === 'payment_made' ||
                                            payment.source_type === 'payment_received' ||
                                            payment.source_type === 'deduction') &&
                                        payment.type === 'refund'
                                    "
                                    >Refund</span
                                >
                                <!--                                </span>-->
                            </td>
                            <td class="text-left">
                                <div
                                    [ngStyle]="{
                                        color:
                                            $any(payment.status) === 'canceled' || $any(payment.status) === 'error'
                                                ? '#f94242'
                                                : 'black'
                                    }"
                                >
                                    <div class="remove-payment-container">
                                        <span class="zp-total-nember">
                                            <span>{{ payment.amount | currency }}</span>
                                        </span>
                                    </div>

                                    <div
                                        *ngIf="payment.status === 'processing' || payment.status === 'review'"
                                        style="line-height: 2px"
                                    >
                                        <span class="zp-w-150"></span>

                                        <span class="zp-total-nember" style="color: grey; font-size: 14px">
                                            (processing)
                                        </span>
                                    </div>
                                    <div *ngIf="payment.status === 'scheduled'" style="line-height: 2px">
                                        <span class="zp-w-150"></span>

                                        <span class="zp-total-nember" style="color: grey; font-size: 14px">
                                            (scheduled)
                                        </span>
                                    </div>

                                    <div *ngIf="payment.status === 'pending'" style="line-height: 13px">
                                        <span class="zp-w-150"></span>

                                        <span
                                            class="zp-total-nember text-wrap"
                                            style="
                                                color: grey;
                                                font-size: 14px;
                                                width: 65px;
                                                text-align: center;
                                                padding: 0;
                                            "
                                        >
                                            Pending Deduction
                                        </span>
                                    </div>

                                    <div
                                        *ngIf="$any(payment.status) === 'canceled' || $any(payment.status) === 'error'"
                                        style="line-height: 2px"
                                    >
                                        <span class="zp-w-150"></span>

                                        <span class="zp-total-nember" style="color: grey; font-size: 14px">
                                            ({{ payment.status }})
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                <tr>
                    <td colspan="2"></td>
                    <td class="text-left font-weight-bold">Balance</td>
                    <td class="text-left font-weight-bold">{{ invoice.balance | currency }}</td>
                </tr>
                <tr *ngIf="invoice.balance !== invoice.pending_balance">
                    <td colspan="2"></td>
                    <td class="text-left font-weight-bold">Pending Balance</td>
                    <td class="text-left font-weight-bold">{{ invoice.pending_balance | currency }}</td>
                </tr>
            </tfoot>
        </table>
    </mat-card>

    <mat-card *ngIf="invoice && invoicePaid && !invoice.deleted_at" class="m-4">
        <h3 class="m-0">Invoice# {{ invoice.invoice_number }} is paid</h3>
    </mat-card>

    <mat-card *ngIf="invoice && invoice.deleted_at" class="m-4">
        <h3 class="m-0">Invoice# {{ invoice.invoice_number }} has been deleted</h3>
    </mat-card>

    <mat-card *ngIf="invoice && ['write_off', 'void', 'rejected'].includes(invoice.summary_status)" class="m-4">
        <h3 class="m-0">Invoice# {{ invoice.invoice_number }} has been voided</h3>
    </mat-card>
</div>

<div class="py-4 page-footer">
    <!--    <button type="button"-->
    <!--            mat-raised-button-->
    <!--            color="primary"-->
    <!--            class="_track_public_invoice_open_in_portal px-4 mx-4 pay-invoice-btn"-->
    <!--            *ngIf="!currentProfile-->
    <!--		            || !currentContact-->
    <!--		            || (currentProfile.company && invoice && currentProfile.company.id === invoice.owner__company_fk_id)-->
    <!--		            || (currentProfile.company && currentContact.partner__company_fk_id !== currentProfile.company.id)-->
    <!--					|| (currentProfile.company && invoice  && currentProfile.company.id !== invoice.money_sender__company_fk_id)"-->
    <!--            (click)="openInPortal()">-->
    <!--        Open in Portal-->
    <!--    </button>-->
    <!--    <button type="button"-->
    <!--            class="px-4 mx-4 pay-invoice-btn"-->
    <!--            mat-raised-button-->
    <!--            color="primary"-->
    <!--            *ngIf="canViewInApp"-->
    <!--            (click)="viewInApp()">-->
    <!--        View in App-->
    <!--    </button>-->
    <span class="sp-spacer"></span>
    <button
        mat-raised-button
        color="primary"
        [disabled]="
            !invoice ||
            invoicePaid ||
            invoice.deleted_at ||
            invoice.pending_balance === 0 ||
            ['write_off', 'void', 'rejected', 'draft'].includes(invoice.summary_status) ||
            (!invoicePayloadCardMethod && !invoicePayloadBankMethod && !invoiceAuthorizeNetMethod)
        "
        class="px-4 mx-4 pay-invoice-btn"
        (click)="payInvoice()"
    >
        Pay Invoice
    </button>
</div>
