import {Directive, ElementRef, HostListener, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
    selector: '[trimWhitespace]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TrimWhitespaceDirective),
            multi: true
        }
    ]
})
export class TrimWhitespaceDirective implements ControlValueAccessor {
    constructor(private el: ElementRef) {}

    private onChange: ((value: string) => void) | undefined = undefined;

    @HostListener('blur', ['$event.target.value'])
    onBlur(value: string): void {
        const trimmedValue = value.trim();
        this.el.nativeElement.value = trimmedValue;
        if (this.onChange) this.onChange(trimmedValue);
    }

    writeValue(value: string): void {
        this.el.nativeElement.value = value;
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(): void {}
}
