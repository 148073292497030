<div mat-dialog-title>
    <h3>{{ title }}</h3>
</div>

<mat-dialog-content>
    <div>
        <p>This contact is only stored in {{ addonTitle }} and was not created in SkySlope Books because:</p>
        <p>
            1) there was no email address added in {{ addonTitle }} for this person or<br />
            2) this is an agent in the brokerage and the email address in {{ addonTitle }} does not match their contact
            record in SkySlope Books
        </p>

        <p>And you can update it by existing contact or create a new one in SkySlope Books.</p>
    </div>
    <div class="d-flex align-items-center">
        <app-company-compensation-combined-picker
            style="width: 100%; margin-right: 8px"
            [availableTypes]="availableTypes"
            [title]="placeholder"
            [only_compensation_groups]="false"
            [singleSelected]="true"
            [bold_style]="false"
            [nodesFA]="contactsCtrlArr"
            [disableUpdate]="isDefault"
            [disabled]="isDefault"
            [required]="!isDefault"
            [contactWithoutPartnerCompany]="contactWithoutPartnerCompany"
            [contactMetatype]="metatype"
        ></app-company-compensation-combined-picker>

        <span class="ml-2 mr-3">or</span>
        <mat-checkbox [(ngModel)]="isDefault">Create New Contact</mat-checkbox>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="mb-2">
    <div class="d-flex justify-content-end w-100">
        <button *ngIf="!hideCancel" mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">Cancel</button>
        <button mat-raised-button color="primary" (click)="apply()" tabindex="1">Apply</button>
    </div>
</mat-dialog-actions>
