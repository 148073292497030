<section class="container-fluid" *ngIf="vendorCredit">
    <div class="w-100 text-right py-2">
        <a class="mr-2" [routerLink]="'/sales/creditnotes'" mat-raised-button> Back </a>
    </div>
    <mat-card class="p-5">
        <div>Vendor Credit Status: {{ vendorCredit.status | fromSnakeCase }}</div>

        <header class="row">
            <div class="col-6 zp-company-info">
                <!-- <p>Company Logo</p> -->
                <p class="zp-company-title">{{ vendorCredit?.creator_company?.title }}</p>
                <p class="text-uppercase">
                    {{ vendorCredit?.creator_profile?.first_name }} {{ vendorCredit?.creator_profile?.last_name }}
                </p>
            </div>

            <div class="col-6 text-right">
                <p class="zp-inveice_title">Vendor Credits</p>
                <p class="zp-invoice-num_value">#{{ vendorCredit?.vendor_credit_number }}</p>

                <p class="zp-refference_due_title">Credits Remaining</p>
                <p class="zp-refference_due_value">{{ vendorCredit?.total_amount | currency }}</p>
            </div>
        </header>

        <p>Bill To</p>
        <div class="row">
            <div class="col-6">
                <p>{{ vendorCredit?.vendor_contact?.display_name }}</p>
            </div>

            <div class="col-6 text-right">
                <p class="mb-2">
                    <span>Credit Date :</span>
                    <span class="zp-w-150">{{
                        $any(vendorCredit?.vendor_credit_date) | dateFromNumber | date: 'longDate'
                    }}</span>
                </p>

                <p class="mb-2">
                    <span>Ref# :</span>
                    <span class="zp-w-150">{{ vendorCredit?.reference }}</span>
                </p>
            </div>
        </div>

        <table class="table">
            <thead class="bg-dark text-white">
                <tr>
                    <td scope="col">#</td>
                    <td class="w-50" scope="col">Item & Description</td>
                    <td scope="col">Qty</td>
                    <td scope="col">Rate</td>
                    <td scope="col" class="text-right">Amount</td>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of vendorCredit?.items; index as idx">
                    <td>{{ idx + 1 }}</td>
                    <td class="w-50">
                        {{ item?.name }}
                        <i class="text-muted">{{ item?.description }}</i>
                    </td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.rate }}</td>
                    <td class="text-right">{{ item.amount }}</td>
                </tr>
            </tbody>
        </table>

        <div class="w-100 text-right">
            <p class="mb-2">
                Sub Total: <span class="zp-total-nember">{{ vendorCredit?.total_amount }}</span>
            </p>
            <p class="mb-2">
                <strong
                    >Total:
                    <span class="zp-total-nember font-weight-bold">{{ vendorCredit?.total_amount | currency }}</span>
                </strong>
            </p>
            <p>
                <strong class="bg-light py-2">
                    <span class="zp-w-150">&nbsp;</span>
                    Credits Remaining:
                    <strong class="zp-total-nember">{{ vendorCredit?.total_amount | currency }}</strong>
                </strong>
            </p>
        </div>
    </mat-card>
</section>
