import {Subject} from 'rxjs';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {ITransaction, ITransactionExternal, ITransactionListItem} from '@cyberco-nodejs/zipi-typings';
import {MatTableDataSource} from '@angular/material/table';
import {TransactionsService} from 'app/services/api/finance/transactions.service';
import {TransactionsExternalService} from 'app/services/api/finance/transactions-external.service';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-internal-transaction',
    templateUrl: './internal-transaction.component.html',
    styleUrls: ['../../banking.component.scss']
})
export class InternalTransactionComponent implements OnInit, OnChanges, OnDestroy {
    @Output() closeSidebar: EventEmitter<boolean> = new EventEmitter();
    @Input() internalTransaction: ITransactionListItem | undefined;

    private unsubscribe: Subject<void> = new Subject();

    transaction: ITransaction | undefined;

    matchedDataSource: MatTableDataSource<ITransaction>;
    externalMatchedDataSource: MatTableDataSource<ITransactionExternal>;
    matchedDisplayedColumns: string[] = ['transaction'];
    canNotBeUnmatched: boolean = false;

    constructor(
        private transactionsService: TransactionsService,
        private transactionsExternalService: TransactionsExternalService
    ) {
        this.matchedDataSource = new MatTableDataSource<ITransaction>([]);
        this.externalMatchedDataSource = new MatTableDataSource<ITransactionExternal>([]);
    }

    ngOnInit() {
        if (this.internalTransaction) {
            // this.transaction = this.internalTransaction;
            this.getTransaction(this.internalTransaction.transaction_id as number);
        }
    }

    ngOnChanges(changes: any) {
        if (changes.internalTransaction.currentValue) {
            // this.transaction = changes.internalTransaction.currentValue;
            this.getTransaction(changes.internalTransaction.currentValue.transaction_id);
        }
    }

    getTransaction(transactionId: number) {
        this.matchedDataSource.data = [];
        this.externalMatchedDataSource.data = [];
        this.transactionsService
            .getTransactionById(transactionId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.transaction = data.transaction;
                if (data.other_matched_transactions.length > 0) {
                    this.matchedDataSource.data = data.other_matched_transactions;
                }
                if (data.external_matched_transactions.length > 0) {
                    this.externalMatchedDataSource.data = data.external_matched_transactions;
                }
                this.canNotBeUnmatched =
                    !!this.transaction.reconciliation_fk_id ||
                    !!data.other_matched_transactions.find((tr) => !!tr.reconciliation_fk_id);
            });
    }

    unmatch() {
        if (typeof this.transaction === 'undefined') {
            return;
        }

        this.transactionsExternalService
            .unmatchTransaction(this.transaction.matched__transaction_external_id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.closeSidebar.emit(true);
            });
    }

    getReferenceLink(tr: ITransaction | undefined) {
        if (
            typeof tr === 'undefined' ||
            typeof tr.journal === 'undefined' ||
            [
                'card_payment',
                'vendor_payment',
                'transfer_fund',
                'owner_drawings',
                'customer_payment',
                'deposit'
            ].includes(tr.journal.reason_type) ||
            tr.journal.related_entity_type === 'payment'
        ) {
            return;
        }

        switch (tr.journal.reason_type) {
            case 'manual_journal':
                return `/company/finance/journals/${tr.journal_fk_id}`;
                break;
            case 'opening_balance':
                return `/company/finance/openingbalances`;
                break;
        }

        switch (tr.journal.related_entity_type) {
            case 'invoice':
                return `/sales/invoices/${tr.journal.related_entity_id}`;
                break;
            case 'bill':
                return `/purchases/bills/${tr.journal.related_entity_id}`;
                break;
            case 'payment_received':
                return `/sales/paymentsreceived/${tr.journal.related_entity_id}`;
                break;
            case 'payment_made':
                return `/purchases/payments/${tr.journal.related_entity_id}`;
                break;
            case 'expense':
                return `/purchases/expenses/${tr.journal.related_entity_id}`;
                break;
            case 'credit_note':
                return `/sales/creditnotes/${tr.journal.related_entity_id}`;
                break;
            case 'vendor_credit':
                return `/purchases/vendorcredits/${tr.journal.related_entity_id}`;
                break;
            case 'deposit_release':
                return `/deals/${tr.journal.context_entity_id}`;
                break;
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.closeSidebar.complete();
    }
}
