import {Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {Profile} from '../../../../models/profile';
import {DealsBoardBackendFilterSettingsByCompany} from '../../../deals/components/deal/common/deal.models';
import {MatDialogRef} from '@angular/material/dialog';
import {DealsViewPickerContainerComponent} from './deals-view-picker-container.component';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'deals-vew-picker-dialog',
    styles: [
        `
            .row {
                display: flex;
                justify-content: flex-start;
                flex-direction: row;
            }
            .col {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
            }
            .dialog-content {
                width: 100%;
                height: 80%;
                overflow: auto;
            }
            .dialog-container {
                height: 80vh;
            }
            .dialog-actions {
                justify-content: space-between;
                flex-flow: initial;
                margin-top: 24px;
                margin-bottom: 24px;
            }
        `
    ],
    template: `
        <div class="dialog-container">
            <h2 mat-dialog-title>Include deals form companies</h2>
            <div mat-dialog-content class="dialog-content">
                <deals-vew-picker-container
                    #pickerContainer
                    [availableProfiles]="availableProfiles"
                    [byCompany]="__byCompany"
                >
                </deals-vew-picker-container>
            </div>

            <div mat-dialog-actions class="row dialog-actions">
                <div class="row">
                    <button (click)="pickerContainer.checkAll()" mat-button class="mat-primary">Check all</button>
                    <button (click)="pickerContainer.uncheckAll()" mat-button>Uncheck all</button>
                </div>
                <div class="row" style="justify-content:  flex-end;">
                    <button (click)="dialogRef.close('save')" mat-button class="mat-primary">Save</button>
                    <button (click)="dialogRef.close('')" mat-button>Close</button>
                </div>
            </div>
        </div>
    `
})
export class DealsViewPickerContainerDialogComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    static get SAVE_ON_CLOSE() {
        return 'save';
    }
    @Input() byCompany: {[id: number]: DealsBoardBackendFilterSettingsByCompany} = {};
    public __byCompany: {[id: number]: DealsBoardBackendFilterSettingsByCompany} = {};
    @Input() availableProfiles: Profile[] = [];
    @ViewChild('pickerContainer', {static: true}) pickerContainer: DealsViewPickerContainerComponent | undefined;

    constructor(public dialogRef: MatDialogRef<DealsViewPickerContainerDialogComponent>) {
        dialogRef.beforeClosed().pipe(takeUntil(this.unsubscribe)).subscribe(this.onClose.bind(this));
    }

    init(availableProfiles: Profile[], byCompany: {[id: number]: DealsBoardBackendFilterSettingsByCompany}) {
        this.availableProfiles = availableProfiles;
        this.byCompany = byCompany;
        Object.assign(this.__byCompany, JSON.parse(JSON.stringify(byCompany)));
    }

    onClose(res: any) {
        if (res === DealsViewPickerContainerDialogComponent.SAVE_ON_CLOSE) {
            Object.assign(this.byCompany, JSON.parse(JSON.stringify(this.__byCompany)));
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
