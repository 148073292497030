import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FeatureFlagsService} from './feature-flags.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [FeatureFlagsService],
    bootstrap: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FeatureFlagsModule {}
