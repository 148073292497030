<section>
    <app-settings-toolbar (openTagDialogEmitter)="openTagDialog()"></app-settings-toolbar>

    <div class="container-fluid">
        <div class="row">
            <table mat-table [dataSource]="dataSource" class="w-100">
                <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef>Category</th>
                    <td class="w-30" mat-cell *matCellDef="let element">{{ element.title }}</td>
                </ng-container>

                <ng-container matColumnDef="tags">
                    <th class="pl-3" mat-header-cell *matHeaderCellDef>Tags</th>
                    <td class="pl-3" mat-cell *matCellDef="let element">
                        <span *ngFor="let tag of element.tags; last as last">
                            {{ tag.title }}<span *ngIf="!last">, </span>
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="settings">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button class="h-100" (click)="openTagDialog(element)">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>
    </div>
</section>
