<div class="container pt-5">
    <header class="page-header d-flex flex-column align-items-center">
        <img
            src="https://storage.googleapis.com/zipi-static/app-assets/skyslope-books-logo.svg"
            alt="SkySlope Books"
            width="138"
            height="35"
        />

        <h1 class="title mt-4 mb-1">{{ brokerName }} Invoice Statement</h1>
        <h2 class="subtitle">
            {{ statement.start_date | dateFromNumber | date: 'mediumDate' }}
            –
            {{ statement.end_date | dateFromNumber | date: 'mediumDate' }}
        </h2>
    </header>

    <main class="position-relative">
        <div class="total-invoice-count">
            {{ currentContact?.display_name }} ({{ statementInvoiceCount | i18nPlural: itemPluralMapping['invoice'] }})
        </div>
        <mat-tab-group animationDuration="0">
            <mat-tab label="Unpaid ({{ allUnpaidInvoices.length }})" disabled="{{ allUnpaidInvoices.length <= 0 }}">
                <div class="invoice-container">
                    <app-statement-public-invoice
                        *ngFor="let invoice of unpaidPreviousInvoices"
                        [invoice]="invoice"
                    ></app-statement-public-invoice>

                    <div class="prev-line" *ngIf="unpaidPreviousInvoices?.length">
                        <hr />
                        <div class="text">End Previous Statement</div>
                    </div>

                    <app-statement-public-invoice
                        *ngFor="let invoice of unpaidCurrentInvoices"
                        [invoice]="invoice"
                    ></app-statement-public-invoice>
                </div>
            </mat-tab>

            <mat-tab
                label="Processing ({{ processingInvoices.length }})"
                disabled="{{ processingInvoices.length <= 0 }}"
            >
                <div class="invoice-container">
                    <app-statement-public-invoice
                        *ngFor="let invoice of processingInvoices"
                        [invoice]="invoice"
                    ></app-statement-public-invoice>
                </div>
            </mat-tab>

            <mat-tab label="Paid ({{ paidInvoices.length }})" disabled="{{ paidInvoices.length <= 0 }}">
                <div class="invoice-container">
                    <app-statement-public-invoice
                        *ngFor="let invoice of paidInvoices"
                        [invoice]="invoice"
                    ></app-statement-public-invoice>
                </div>
            </mat-tab>
        </mat-tab-group>
    </main>
</div>

<div class="page-footer-spacer"></div>
<footer class="page-footer">
    <div class="container footer-inner">
        <div class="balances">
            <div>
                <div class="balance-title">Previous Balance</div>
                <div class="balance-value">{{ (previousBalance | currency: 'USD') ?? '—' }}</div>
            </div>
            <div>
                <div class="balance-title">Total Invoices</div>
                <div class="balance-value">{{ statementInvoiceCount }}</div>
            </div>
            <div>
                <div class="balance-title">Invoice Amount</div>
                <div class="balance-value">{{ (totalInvoiceAmount | currency: 'USD') ?? '—' }}</div>
            </div>
            <div>
                <div class="balance-title">Paid Amount</div>
                <div class="balance-value">{{ totalPaidAmount | currency: 'USD' }}</div>
            </div>
            <div>
                <div class="balance-title">Amount Due</div>
                <div class="balance-value">{{ totalAmountDue | currency: 'USD' }}</div>
            </div>
        </div>

        <button
            mat-raised-button
            color="primary"
            *ngIf="
                !isPayInvoiceButtonDisabled &&
                allInvoices &&
                (invoicePayloadBankMethods.length > 0 ||
                    invoicePayloadCardMethods.length > 0 ||
                    invoiceAuthorizeNetMethods.length > 0)
            "
            class="pay-invoice-btn"
            (click)="payInvoiceStatement()"
        >
            Pay Invoice
            <mat-icon>credit_card</mat-icon>
        </button>
    </div>
</footer>
