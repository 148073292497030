import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

import {SharedModule} from '../shared/shared.module';

import {routing} from './franchise.routing';

import {IndexComponent} from './components/index/index.component';
import {ParentChildComponent} from './components/parent-child.component';

import {franchiseReducer} from './store/franchise.reducer';
import {FranchiseRequestEffectsService} from './store/franchise.effects';
import {FranchiseRequestService} from '../../services/franchise-request.service';

@NgModule({
    declarations: [IndexComponent, ParentChildComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        routing,
        StoreModule.forFeature('franchise', franchiseReducer),
        EffectsModule.forFeature([FranchiseRequestEffectsService])
    ],
    exports: [BrowserModule],
    providers: [
        // FranchiseRequestEffectsService,
        FranchiseRequestService
    ],
    bootstrap: [IndexComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FranchiseModule {}
