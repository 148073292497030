import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {AuthService} from '../../../services/auth.service';
import {UserService} from '../../../services/user.service';
import {SessionService} from '../../../services/session.service';
import {ContactInvitesService} from '../../contact-invites/services/contact-invites.service';
import {IContactInvite} from '../../../../typings';
import {NotificationsServiceZipi} from '../../notifications/notifications.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {AfterSignUpDialogComponent} from '../after-sign-up-dialog/after-sign-up-dialog.component';

@Component({
    selector: 'app-sign-up-contact-invite',
    templateUrl: './sign-up-contact-invite.component.html',
    styleUrls: ['./sign-up-contact-invite.component.scss']
})
export class SignUpContactInviteComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    inviteHash = '';
    signUpForm: UntypedFormGroup | undefined;
    contactInvite: IContactInvite | undefined;

    // allPlans: Array<string> = Object.keys(CONFORMITY_PLAN).filter(plan => plan !== 'collaborator');
    allPlans = [];
    isProcessing: boolean = false;

    constructor(
        protected authService: AuthService,
        protected userService: UserService,
        protected notificationsService: NotificationsServiceZipi,
        protected route: ActivatedRoute,
        protected fb: UntypedFormBuilder,
        protected sessionService: SessionService,
        protected contactInvitesService: ContactInvitesService,
        protected router: Router,
        public dialog: MatDialog
    ) {
        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['invite_hash']) {
                this.inviteHash = params['invite_hash'];
            }
        });
    }

    async ngOnInit() {
        this.signUpForm = this.fb.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            account_name: ['', Validators.required],
            company_plan: ['', Validators.required]
        });

        this.contactInvitesService
            .getContactInviteByHash(this.inviteHash)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((invite) => {
                this.contactInvite = invite;

                if (!this.signUpForm) {
                    return;
                }

                this.signUpForm.get('email')!.setValue(this.contactInvite.invited_email);
                this.signUpForm.get('email')!.disable();
                this.signUpForm.get('company_plan')!.setValue('free');

                if (this.contactInvite.contact_company) {
                    this.signUpForm.get('account_name')!.setValue(this.contactInvite.contact_company.title);
                    this.signUpForm.get('account_name')!.disable();
                }
            });
    }

    submitSignUpForm() {
        if (this.isProcessing) {
            return;
        }
        if (!this.signUpForm || !this.signUpForm.valid) {
            this.notificationsService.addError('Not all required fields are filled');

            return false;
        }
        if (!this.contactInvite) {
            this.notificationsService.addError('Contact invite expected');
            return false;
        }
        this.isProcessing = true;
        const formObj = this.signUpForm.getRawValue();

        const company: {[key: string]: any} = {
            title: formObj.account_name,
            plan: formObj.company_plan
        };

        const user = {
            first_name: formObj.first_name,
            last_name: formObj.last_name,
            email: this.contactInvite.invited_email.toLowerCase(),
            firebase_id: ''
        };

        return this.authService
            .createUserWithEmailAndPassword(user.email, formObj.password)
            .then((firebaseUserInfo) => {
                if (!firebaseUserInfo.user) {
                    this.notificationsService.addError('Firebase user is not defined');
                    return;
                }
                user.firebase_id = firebaseUserInfo.user.uid;
                if (this.contactInvite && this.contactInvite.contact__company_fk_id) {
                    company['id'] = this.contactInvite.contact__company_fk_id;
                }

                return this.afterRegister(user, company);
            })
            .then(() => {
                this.isProcessing = false;
            })
            .catch((err) => {
                this.notificationsService.addError(err.message);
                this.isProcessing = false;
                throw err;
            });
    }

    tryGoogleAuth() {
        this.authService
            .signInWithGoogle()
            .then((firebaseUserCredential) => {
                if (!firebaseUserCredential.user) {
                    this.notificationsService.addError('Firebase user is not defined');
                    return;
                }
                const company = {
                    title: firebaseUserCredential.user.displayName + ' Account'
                };

                const user = {
                    first_name: firebaseUserCredential.user.displayName!.split(' ')[0],
                    last_name: firebaseUserCredential.user.displayName!.split(' ')[1],
                    email: firebaseUserCredential.user.email!.toLowerCase(),
                    firebase_id: firebaseUserCredential.user.uid
                };

                return this.afterRegister(user, company, true);
            })
            .catch((err) => {
                this.notificationsService.addError(err.message);
            });
    }

    protected afterRegister(user: any, company: any, fullPageReload = false) {
        if (!this.contactInvite) {
            return;
        }

        return this.userService.afterRegister(user, company, this.contactInvite.contact_fk_id).then((response) => {
            this.userService
                .afterRegisterContactInvite(this.contactInvite!.invite_hash!, user, response.company)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((res) => {
                    return this.openAfterRegisterPopUp();

                    // window.location.replace('/default-page');
                });
        });
    }

    openAfterRegisterPopUp() {
        const dialogRef = this.dialog.open(AfterSignUpDialogComponent, {
            minWidth: 320,
            data: {}
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((redirect) => {
                if (redirect) {
                    this.authService.logout().then(() => {
                        window.location.replace('/login');
                    });
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
