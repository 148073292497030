import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl, Validators} from '@angular/forms';

@Component({
    selector: 'app-first-check-number-dialog',
    templateUrl: './first-check-number-dialog.component.html',
    styleUrls: ['./first-check-number-dialog.component.scss']
})
export class FirstCheckNumberDialogComponent implements OnInit {
    firstCheckNumber: UntypedFormControl = new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(/^.*[0-9]+(?![a-zA-Z])$/)
    ]);

    constructor(public dialogRef: MatDialogRef<FirstCheckNumberDialogComponent>) {}

    next() {
        if (this.firstCheckNumber.invalid) {
            return;
        }
        this.dialogRef.close(this.firstCheckNumber.value);
    }

    ngOnInit(): void {}
}
