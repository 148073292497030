import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MembershipOrganization} from 'app/models/membership-organization';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {ICompanyWideState} from 'app/store/company-wide/company-wide.reducer';
import {
    CreateMembershipOrganizations,
    UpdateMembershipOrganizations
} from 'app/store/company-wide/company-wide.actions';
import {UntypedFormControl} from '@angular/forms';

interface IDialogData {
    organizations: MembershipOrganization[];
}

@Component({
    selector: 'app-contact-edit-compliance-create-membership-organization',
    template: `
        <section>
            <mat-dialog-content>
                <div class="d-flex flex-row align-items-baseline">
                    <h1 mat-dialog-title style="margin-right: 15px">Create organization or edit</h1>
                    <mat-form-field>
                        <mat-select
                            [(ngModel)]="currentMembershipOrganization"
                            (selectionChange)="selectedOrganizationChange($event.value)"
                            placeholder="Select organization"
                        >
                            <mat-option [value]="newMembershipOrganization">None</mat-option>
                            <mat-option *ngFor="let organization of membershipOrganizations" [value]="organization">
                                {{ organization.title }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="d-flex flex-column">
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field ngDefaultControl class="w-100">
                                <input
                                    [formControl]="organizationNameFC"
                                    matInput
                                    type="text"
                                    placeholder="Organization name"
                                    required
                                />
                            </mat-form-field>
                        </div>

                        <div class="col-6">
                            <mat-form-field ngDefaultControl class="w-100">
                                <mat-label>Organization type</mat-label>
                                <mat-select [formControl]="organizationTypeFC" required>
                                    <mat-option
                                        *ngFor="let moType of membershipOrganization.provideTypes()"
                                        [value]="moType"
                                    >
                                        {{ membershipOrganization.type_LABELS[moType] }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <mat-list>
                        <mat-list-item
                            style="height: 66px"
                            *ngFor="let motype of membershipTypes; trackBy: indexTracker; index as mi"
                        >
                            <mat-form-field>
                                <input matInput required [(ngModel)]="membershipTypes[mi]" />
                            </mat-form-field>

                            <button mat-button class="mat-default" (click)="deleteMembershipType(mi)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-list-item>
                    </mat-list>

                    <button
                        mat-raised-button
                        class="mat-default"
                        style="margin-bottom: 5px"
                        (click)="addMembershipType()"
                    >
                        Add new membership type
                    </button>
                </div>
            </mat-dialog-content>
            <mat-dialog-actions class="text-right">
                <div>
                    <button type="button" mat-raised-button tabindex="-1" (click)="dialogRef.close()">Cancel</button>
                    <button
                        type="button"
                        mat-raised-button
                        color="primary"
                        tabindex="1"
                        (click)="saveMembershipOrganization()"
                    >
                        Save
                    </button>
                </div>
            </mat-dialog-actions>
        </section>
    `,
    styles: [
        `
            mat-dialog-actions {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
            }
        `
    ]
})
export class CreateOrganizationDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    membershipOrganization: any = MembershipOrganization;
    organizationNameFC = new UntypedFormControl('');
    organizationTypeFC = new UntypedFormControl('');
    membershipTypes: string[] = [];
    currentMembershipOrganization: MembershipOrganization = new MembershipOrganization();
    newMembershipOrganization: MembershipOrganization = new MembershipOrganization();
    membershipOrganizations: MembershipOrganization[] = [];

    constructor(
        public dialogRef: MatDialogRef<CreateOrganizationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData,
        private store: Store<ICompanyWideState>
    ) {}

    addMembershipType() {
        this.membershipTypes.push('');
    }

    indexTracker(index: number) {
        return index;
    }

    selectedOrganizationChange(value: string) {
        if (value) {
            this.organizationTypeFC.patchValue(this.currentMembershipOrganization.type);
            this.membershipTypes = this.currentMembershipOrganization.membership_types;
            this.organizationNameFC.patchValue(this.currentMembershipOrganization.title);
        }
    }

    deleteMembershipType(membershipNumber: number) {
        this.membershipTypes.splice(membershipNumber, 1);
    }

    saveMembershipOrganization() {
        if (!this.organizationTypeFC.value || !this.organizationNameFC.value.trim()) {
            this.organizationTypeFC.markAsTouched();
            this.organizationNameFC.markAsTouched();
            return;
        }

        this.currentMembershipOrganization.membership_types = this.membershipTypes;
        this.currentMembershipOrganization.type = this.organizationTypeFC.value;
        this.currentMembershipOrganization.title = this.organizationNameFC.value.trim();
        if (!this.currentMembershipOrganization.id) {
            this.store.dispatch(new CreateMembershipOrganizations(this.currentMembershipOrganization));
            this.dialogRef.close(false);
        }
        if (this.currentMembershipOrganization.id) {
            this.store.dispatch(new UpdateMembershipOrganizations(this.currentMembershipOrganization));
            this.dialogRef.close(false);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngOnInit(): void {
        this.membershipOrganizations = this.data.organizations;
    }
}
