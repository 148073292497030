<div class="d-flex h-100 w-100 overflow-hidden flex-row">
    <div class="w-25 d-flex flex-column" style="border-right: 1px solid #dee5ed">
        <div class="page-title d-flex flex-column">
            <div class="title">Print Check</div>

            <div class="description">
                <span *ngIf="bankName">
                    {{ bankName }}
                </span>
                <span *ngIf="!bankName" class="font-italic" style="color: gray">(Empty Bank Name)</span>
            </div>
        </div>
        <div class="left-panel">
            <mat-card class="check-card" *ngFor="let paymentMade of paymentsMadeToPrint">
                <mat-card-content>
                    <div class="card-title">{{ paymentMade.vendor_name }}</div>
                    <div class="card-text">
                        {{ paymentMade.reference }}
                    </div>
                    <div class="card-text">
                        {{ paymentMade.amount | currency }}
                    </div>
                    <div class="card-text">
                        {{ paymentMade.paid_date | dateFromNumber | date: 'MM/dd/yyyy' }}
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

    <div class="w-75 d-flex flex-column">
        <div class="top-panel">
            <div [formGroup]="shiftsForm" class="d-flex justify-content-end align-items-center">
                <div class="input-group">
                    <label>Check Position</label>
                    <mat-select [(value)]="selectedPosition" class="select" (selectionChange)="selectionChange($event)">
                        <mat-option *ngFor="let position of positions" [value]="position">
                            {{ position }}
                        </mat-option>
                    </mat-select>
                </div>

                <div class="input-group" title="Offsets should not be more than +/- 100 pixels">
                    <label>Horizontal Shift</label>
                    <input formControlName="left" class="text-right" type="number" max="200" min="-100" />
                </div>
                <div class="input-group" title="Offsets should not be more than +/- 100 pixels">
                    <label>Vertical Shift</label>
                    <input formControlName="top" class="text-right" type="number" max="100" min="-100" />
                </div>

                <button
                    mat-raised-button
                    class="mat-default button apply-btn"
                    [disabled]="isPdfLoading || isChecksSaving || !shiftsForm.valid"
                    (click)="applyShift()"
                >
                    Apply Changes
                </button>
            </div>
        </div>

        <div class="content-panel">
            <div class="load-container" id="load-container" *ngIf="isPdfLoading">
                <div class="loader">Loading...</div>
            </div>

            <pdf-viewer
                *ngIf="!isPdfLoading"
                [src]="blobPreviewUrl"
                [render-text]="true"
                [original-size]="false"
                style="min-width: 500px; width: 100%; height: 100%; display: block"
            ></pdf-viewer>
        </div>
        <div class="bottom-panel">
            <div class="d-flex justify-content-end">
                <button
                    mat-raised-button
                    class="mat-default ml-2 button cancel-btn"
                    [disabled]="isChecksSaving"
                    (click)="close()"
                >
                    Cancel
                </button>
                <button
                    mat-raised-button
                    class="mat-primary ml-2 button print-btn"
                    (click)="print()"
                    [disabled]="isPdfLoading || isChecksSaving || saveDisabled || shiftsForm.invalid"
                >
                    Print
                </button>
            </div>
        </div>
    </div>
</div>
