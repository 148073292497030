<div class="dialog-container">
    <div mat-dialog-title>
        <!------- Header ------->
        <div class="d-flex flex-row align-items-baseline">
            <h1 mat-dialog-title class="mr-3">Manage Sales Entities</h1>
        </div>

        <!------- Body ------->
        <div class="d-flex flex-column" *ngIf="!disableButtons">
            <app-company-compensation-combined-picker
                #entityPicker
                [title]="'Add Sales Entity'"
                [availableTypes]="['company_group', 'individual']"
                [nodesFA]="selectedSalesForm"
                [initialProfileIds]="initialProfileIds"
                [initialGroupIds]="initialGroupIds"
                [initialContactIds]="initialContactIds"
                [only_compensation_groups]="true"
                [bold_style]="false"
                [displayMode]="'double-line'"
                [positionMode]="'none'"
                [isContactNeeded]="true"
                [needContactName]="true"
            ></app-company-compensation-combined-picker>
        </div>
    </div>

    <mat-dialog-content style="max-height: calc(100vh - 275px - 20vh)">
        <!------- Errors ------->
        <div class="warning-block p-2 mb-3 w-100" *ngIf="updatedCustomEntities.length && validateSum()">
            <span class="font-weight-bold mr-2">Something is not quite right:</span>
            <span>Total % of Deal Split must equal 100%</span>
        </div>
        <div class="warning-block p-2 mb-3 w-100" *ngIf="updatedCustomEntities.length && validateSalesVolumeSum()">
            <span class="font-weight-bold mr-2">Something is not quite right:</span>
            <span>% of Sales Volume must equal 100%</span>
        </div>
        <div class="warning-block p-2 mb-3 w-100" *ngIf="updatedCustomEntities.length && validateSideCountSum()">
            <span class="font-weight-bold mr-2">Something is not quite right:</span>
            <span>Total Side Count must equal 1.00</span>
        </div>
        <div class="warning-block p-2 mb-3 w-100" *ngIf="!updatedCustomEntities.length">
            <span class="font-weight-bold mr-2">Something is not quite right:</span>
            <span>Sales Entity Required</span>
        </div>
        <div class="warning-block p-2 mb-3 w-100" *ngIf="updatedCustomEntities.length && validateNegativeNumbers()">
            <span class="font-weight-bold mr-2">Something is not quite right:</span>
            <span>Values cannot be negative</span>
        </div>

        <div class="d-flex flex-column w-100">
            <!------- Header ------->
            <div class="d-grid">
                <div
                    style="grid-column-start: 3"
                    [ngClass]="data.showSideCountAndSalesVolumeFlag ? 'd-grid-detailed' : 'w-100'"
                >
                    <div class="grid-header">
                        <span>% of Deal Split</span>
                        <span>(Commission)</span>
                    </div>
                    <div class="grid-header" *ngIf="data.showSideCountAndSalesVolumeFlag">
                        <span>% Amount of Credit</span>
                        <span>(Sales Volume)</span>
                    </div>
                    <div class="grid-header" *ngIf="data.showSideCountAndSalesVolumeFlag">
                        <span>Amount of Credit</span>
                        <span>(Side Count)</span>
                    </div>
                </div>
            </div>
            <!------- Sales Entities ------->
            <div *ngFor="let item of updatedCustomEntities; let idx = index" class="d-grid">
                <!------- Icon ------->
                <div>
                    <mat-icon
                        matTooltip="Primary"
                        class="mr-3 role-icon"
                        *ngIf="item.role === SALES_ENTITY.role_SET.agent && item.primary"
                    >
                        grade
                    </mat-icon>
                    <mat-icon
                        matTooltip="Agent"
                        class="mr-3 role-icon"
                        *ngIf="item.role === SALES_ENTITY.role_SET.agent && !item.primary"
                    >
                        account_circle
                    </mat-icon>
                    <mat-icon
                        matTooltip="Transfer"
                        class="mr-3 role-icon"
                        *ngIf="item.role === SALES_ENTITY.role_SET.transfer"
                    >
                        swap_horizontal_circle
                    </mat-icon>
                    <mat-icon
                        matTooltip="Referral"
                        class="mr-3 role-icon"
                        *ngIf="item.role === SALES_ENTITY.role_SET.referral"
                    >
                        supervised_user_circle
                    </mat-icon>
                </div>
                <!------- Name ------->
                <div>{{ item.label }}</div>

                <!------- % of Deal Split ------->
                <div [ngClass]="data.showSideCountAndSalesVolumeFlag ? 'd-grid-detailed' : 'w-100'">
                    <div
                        [ngClass]="
                            data.showSideCountAndSalesVolumeFlag
                                ? 'justify-grid-center'
                                : 'd-flex align-items-baseline justify-content-end'
                        "
                    >
                        <ng-container *ngIf="item.role === SALES_ENTITY.role_SET.agent">
                            <mat-form-field
                                [ngStyle]="
                                    data.showSideCountAndSalesVolumeFlag
                                        ? {'margin-top': '16px', width: '115px'}
                                        : {'margin-top': '16px', width: '90%'}
                                "
                                class="{{
                                    item.percent < 0 || (updatedCustomEntities.length && validateSum())
                                        ? 'mat-form-field-invalid'
                                        : ''
                                }}"
                                floatLabel="never"
                                *ngIf="
                                    item?.financialTransferFG?.controls?.value_type?.value &&
                                    item?.financialTransferFG?.controls?.value_type?.value !==
                                        FINANCIAL_TRANSFER_ENTITY.value_type_SET.flat_fee
                                "
                                (click)="$event.stopPropagation()"
                            >
                                <input
                                    matInput
                                    #dealSplitInputs
                                    [ngModel]="item.percent"
                                    [disabled]="disableButtons || item.primary"
                                    class="sales-entity-input"
                                    [ngStyle]="
                                        item.percent < 0 || (updatedCustomEntities.length && validateSum())
                                            ? {color: 'red'}
                                            : {}
                                    "
                                    placeholder="% of Deal Split"
                                    name="value"
                                    [maskito]="numberMask"
                                    (input)="recalculateSumPrimaryAgent(idx, $event)"
                                    type="text"
                                />
                            </mat-form-field>
                            <span class="ml-1" [ngStyle]="item.primary ? {opacity: 0.5} : {}">%</span>
                        </ng-container>
                    </div>
                    <!------- Sales Volume ------->
                    <div class="justify-grid-center" *ngIf="data.showSideCountAndSalesVolumeFlag">
                        <ng-container *ngIf="item.role === SALES_ENTITY.role_SET.agent">
                            <mat-form-field
                                style="margin-top: 16px; width: 115px"
                                class="{{
                                    item.sales_volume_percent < 0 || validateSalesVolumeSum()
                                        ? 'mat-form-field-invalid'
                                        : ''
                                }}"
                                floatLabel="never"
                                (click)="$event.stopPropagation()"
                            >
                                <input
                                    matInput
                                    #salesVolumeInputs
                                    [ngModel]="item.sales_volume_percent"
                                    [disabled]="disableButtons || item.primary"
                                    class="sales-entity-input"
                                    [ngStyle]="
                                        item.sales_volume_percent < 0 || validateSalesVolumeSum() ? {color: 'red'} : {}
                                    "
                                    placeholder="Sales Volume"
                                    name="sales_volume_percent"
                                    [maskito]="numberMask"
                                    (input)="recalculateSumSalesVolume(idx, $event)"
                                    type="text"
                                />
                            </mat-form-field>
                            <span class="ml-1" [ngStyle]="item.primary ? {opacity: 0.5} : {}">%</span>
                        </ng-container>
                    </div>
                    <!------- Side Count ------->
                    <div class="justify-grid-center" *ngIf="data.showSideCountAndSalesVolumeFlag">
                        <ng-container *ngIf="item.role === SALES_ENTITY.role_SET.agent">
                            <!--view-->
                            <div
                                style="width: 115px"
                                class="fake-input"
                                *ngIf="!item.primary"
                                [ngStyle]="!!hideSideCountInput[idx] ? {} : {display: 'none'}"
                                (click)="toggleSideCount(idx)"
                            >
                                {{ item.side_count | number: '1.2-2' }}
                            </div>
                            <!--edit-->
                            <mat-form-field
                                style="margin-top: 16px; width: 115px"
                                class="{{
                                    item.side_count < 0 || validateSideCountSum() ? 'mat-form-field-invalid' : ''
                                }}"
                                floatLabel="never"
                                (click)="$event.stopPropagation()"
                                *ngIf="!item.primary"
                                [ngStyle]="!hideSideCountInput[idx] ? {} : {display: 'none'}"
                            >
                                <input
                                    matInput
                                    #sideCountInputs
                                    [ngModel]="item.side_count"
                                    [disabled]="disableButtons || item.primary"
                                    class="sales-entity-input"
                                    [ngStyle]="item.side_count < 0 || validateSideCountSum() ? {color: 'red'} : {}"
                                    placeholder="Side Count"
                                    name="side_count"
                                    [maskito]="numberMask"
                                    (input)="recalculateSumSideCount(idx, $event)"
                                    (blur)="onBlurSideCount(idx)"
                                    type="text"
                                />
                            </mat-form-field>
                            <!--show primary-->
                            <mat-form-field
                                style="margin-top: 16px; width: 115px"
                                class="{{
                                    item.side_count < 0 || validateSideCountSum() ? 'mat-form-field-invalid' : ''
                                }}"
                                floatLabel="never"
                                *ngIf="item.primary"
                                (click)="$event.stopPropagation()"
                                [ngStyle]="item.primary || !hideSideCountInput[idx] ? {} : {display: 'none'}"
                            >
                                <input
                                    matInput
                                    #sideCountInputs
                                    [ngModel]="item.side_count | number: '1.2-2'"
                                    [disabled]="disableButtons || item.primary"
                                    class="sales-entity-input"
                                    [ngStyle]="item.side_count < 0 || validateSideCountSum() ? {color: 'red'} : {}"
                                    placeholder="Side Count"
                                    name="side_count"
                                    [maskito]="numberMask"
                                    (input)="recalculateSumSideCount(idx, $event)"
                                    (blur)="onBlurSideCount(idx)"
                                    type="text"
                                />
                            </mat-form-field>
                        </ng-container>
                    </div>
                </div>

                <!------- Manage buttons menu ------->
                <div>
                    <div *ngIf="item.primary"></div>
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="manageEntityMenu"
                        *ngIf="!item.primary"
                        (click)="$event.stopPropagation()"
                        [disabled]="disableButtons"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #manageEntityMenu="matMenu" overlapTrigger="false">
                        <button
                            mat-menu-item
                            *ngIf="item.role === SALES_ENTITY.role_SET.agent"
                            [disabled]="disableButtons"
                            (click)="doMakePrimary(idx)"
                        >
                            Convert to Primary Agent
                        </button>
                        <!--                        Alex: Hidden because of to lots of conflicts with other functionality
                            (need to remove not only transfer with type participant_split) -->

                        <!--                        <button mat-menu-item-->
                        <!--                                *ngIf="item.role === SALES_ENTITY.role_SET.agent"-->
                        <!--                                [disabled]="disableButtons"-->
                        <!--                                (click)="doMakeTransfer(idx)"-->
                        <!--                                matTooltip="% of Deal Not Applicable"-->
                        <!--                                matTooltipPosition="right">-->
                        <!--                            Convert to Participant-->
                        <!--                        </button>-->
                        <button
                            mat-menu-item
                            *ngIf="
                                item.role === SALES_ENTITY.role_SET.transfer &&
                                item.sales_entity_type !== SALES_ENTITY.type_SET.contact
                            "
                            [disabled]="disableButtons"
                            (click)="doMakePrimaryFromTransfer(idx)"
                        >
                            Convert to Primary Agent
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="
                                item.role === SALES_ENTITY.role_SET.transfer &&
                                item.sales_entity_type !== SALES_ENTITY.type_SET.contact
                            "
                            [disabled]="disableButtons"
                            (click)="doMakeAgent(idx)"
                        >
                            Convert to Agent
                        </button>
                        <button
                            mat-menu-item
                            [disabled]="disableButtons || item.added_automatic"
                            (click)="removeOptionFromArray(idx)"
                        >
                            Remove from Deal
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <!------- Buttons ------->
    <mat-dialog-actions class="text-right">
        <div class="d-flex justify-content-end w-100 py-2">
            <button
                type="button"
                mat-raised-button
                tabindex="-1"
                (click)="dialogRef.close()"
                [disabled]="disableButtons"
            >
                Cancel
            </button>
            <button
                type="button"
                mat-raised-button
                color="primary"
                tabindex="1"
                (click)="save()"
                [disabled]="
                    disableButtons ||
                    validateSum() ||
                    validateNegativeNumbers() ||
                    validateSalesVolumeSum() ||
                    validateSideCountSum()
                "
            >
                Apply
            </button>
        </div>
    </mat-dialog-actions>
</div>
