import {Component, EventEmitter, OnDestroy, Input, OnInit, Output, OnChanges, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatCalendarCellCssClasses} from '@angular/material/datepicker';
import {MomentInput} from 'moment-timezone';

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnDestroy, OnChanges {
    private unsubscribe: Subject<void> = new Subject();

    @Input() dateControl: any = null;
    @Input() disabled: boolean = false;
    @Input() inputDisabled: boolean = false;
    @Input() minDate: any;
    @Input() maxDate: any;
    @Input() placeholder: string = 'Select Date';
    @Input() floatLabel: 'never' | 'always' = 'always';
    @Input() valueType: 'value_as_date' | 'value_as_int' = 'value_as_int';
    @Input() pickerWidthSet = {
        fullDisable: '270px',
        datePartEnabled: '150px',
        timePartEnabled: '110px'
    };
    @Input() isRequired: boolean = true;
    @Input() disableDateRules: {frequency: any; start_on: any; period: any; end_on: any} | null = null;
    @Input() dateClassRules: {frequency: any; start_on: any; period: any; end_on: any} | null = null;
    @Input() startDate: Date | null = new Date();
    @Input() startView: 'month' | 'year' | 'multi-year' = 'month';
    @Output() dateChangeEvent: EventEmitter<any> = new EventEmitter<any>();

    dateControlInternal: UntypedFormControl | null = null;

    constructor(private fb: UntypedFormBuilder) {
        this.dateControlInternal = this.fb.control({value: null, disabled: this.disabled || this.inputDisabled}, []);
    }

    myFilter = (d: MomentInput | undefined): boolean => {
        if (!this.disableDateRules) {
            return true;
        } else {
            const now = moment(new Date());
            const targetDate = moment(d);
            if (targetDate.format('YYYYMMDD') > this.disableDateRules.end_on.format('YYYYMMDD')) {
                return false;
            }

            if (targetDate.format('YYYYMMDD') > now.format('YYYYMMDD')) {
                return false;
            }

            if (targetDate.format('YYYYMMDD') < this.disableDateRules.start_on.format('YYYYMMDD')) {
                return false;
            }

            let next_date = moment(this.disableDateRules.start_on);
            for (let i = 1; true; i++) {
                if (next_date.format('YYYYMMDD') === targetDate.format('YYYYMMDD')) {
                    return true;
                }

                if (next_date.format('YYYYMMDD') > targetDate.format('YYYYMMDD')) {
                    return false; // end of loop
                }
                if (this.disableDateRules.period === 'custom') {
                    next_date = next_date.add(this.disableDateRules.frequency, `days`);
                } else {
                    next_date = next_date.add(this.disableDateRules.frequency, `${this.disableDateRules.period}s`);
                }
            }
        }
    };

    myDateClass = (d: Date): MatCalendarCellCssClasses => {
        if (!this.dateClassRules) {
            return '';
        } else {
            const now = moment(new Date());
            const targetDate = moment(d);
            if (targetDate.format('YYYYMMDD') > this.dateClassRules.end_on.format('YYYYMMDD')) {
                return '';
            }

            if (targetDate.format('YYYYMMDD') > now.format('YYYYMMDD')) {
                return '';
            }

            if (targetDate.format('YYYYMMDD') < this.dateClassRules.start_on.format('YYYYMMDD')) {
                return '';
            }

            let next_date = moment(this.dateClassRules.start_on);
            for (let i = 1; true; i++) {
                if (next_date.format('YYYYMMDD') === targetDate.format('YYYYMMDD')) {
                    return 'custom-date-class';
                }

                if (next_date.format('YYYYMMDD') > targetDate.format('YYYYMMDD')) {
                    return ''; // end of loop
                }
                if (this.dateClassRules.period === 'custom') {
                    next_date = next_date.add(this.dateClassRules.frequency, `days`);
                } else {
                    next_date = next_date.add(this.dateClassRules.frequency, `${this.dateClassRules.period}s`);
                }
            }
        }
    };

    ngOnInit() {
        const dateFormat = this.valueType === 'value_as_date' ? 'YYYY-MM-DD' : 'YYYYMMDD';
        if (this.dateControl) {
            this.dateControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((val: any) => {
                if (val) {
                    this.dateControlInternal!.patchValue(moment(val, dateFormat).toDate());
                } else {
                    this.dateControlInternal!.patchValue(null);
                }
            });
            this.dateControlInternal!.patchValue(moment(this.dateControl.value, dateFormat).toDate());
        }
        if (this.inputDisabled) {
            this.dateControlInternal?.disable();
        }
    }

    onDateChange(event: any) {
        let dateForOutput;
        if (this.valueType === 'value_as_date') {
            dateForOutput = moment(event.value, 'YYYY-MM-DD').format('YYYY-MM-DD');
        } else {
            dateForOutput = Number(moment(event.value, 'YYYYMMDD').format('YYYYMMDD'));
        }
        if (this.dateControl) {
            this.dateControl.patchValue(dateForOutput);
        }
        this.dateChangeEvent.emit(dateForOutput);
    }

    resetPicker() {
        if (this.dateControl) {
            this.dateControl.setValue(null);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled && this.dateControlInternal) {
            if (changes.disabled.currentValue) {
                this.dateControlInternal.disable();
            } else {
                this.dateControlInternal.enable();
            }
        }
        if (changes.hasOwnProperty('dateControl') && changes.dateControl.currentValue) {
            const dateFormat = this.valueType === 'value_as_date' ? 'YYYY-MM-DD' : 'YYYYMMDD';
            this.dateControlInternal!.patchValue(moment(changes.dateControl.currentValue.value, dateFormat).toDate());
        }
    }

    ngOnDestroy() {
        this.dateChangeEvent.complete();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
