<section class="bg-white">
    <header class="d-flex align-items-center px-3 list-header">
        <h3 class="m-0 mr-1 d-flex align-items-end">Reference Documents</h3>

        <mat-menu #matMenu="matMenu">
            <button
                mat-menu-item
                routerLink="/sales/creditnotes"
                [rule]="{sales__view_customer_credits: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'flex'}"
                [denyStyle]="{display: 'none'}"
            >
                Credit Notes
            </button>
            <button
                mat-menu-item
                routerLink="/sales/creditnotes/recurring"
                [rule]="{sales__view_customer_credits: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'flex'}"
                [denyStyle]="{display: 'none'}"
            >
                Recurring Credits
            </button>
        </mat-menu>
    </header>

    <!------- List of credit notes ------->
    <div class="table-background">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="vendor_credit_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Date</th>
                <td mat-cell *matCellDef="let element" class="w-10">
                    {{ element.vendor_credit_date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="vendor_contact">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20 pl-1">Vendor</th>
                <td mat-cell *matCellDef="let element" class="w-20 px-1">
                    {{ element.vendor_contact?.display_name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="vendor_credit_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20 pl-1">Credit Note#</th>
                <td mat-cell *matCellDef="let element" class="w-20 px-1">
                    {{ element.vendor_credit_number }}
                </td>
            </ng-container>

            <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20 pl-1">Reference Number</th>
                <td mat-cell *matCellDef="let element" class="w-20 px-1">
                    {{ element.reference }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Status</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.status | fromSnakeCase }}
                </td>
            </ng-container>

            <ng-container matColumnDef="total_amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 table-header-align-right">Amount</th>
                <td mat-cell *matCellDef="let element" class="w-10 pr-3 text-right">
                    {{ element.total_amount | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="balance">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 table-header-align-right">Balance</th>
                <td mat-cell *matCellDef="let element" class="w-10 text-right" style="padding-right: 40px">
                    {{ element.balance | currency }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="row.id" class="zp-table-row"></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div class="text-muted m-3 text-center no-items" *ngIf="dataSource.data && dataSource.data.length === 0">
            There are no source documents.
        </div>
    </div>
</section>
