import {Component, Input} from '@angular/core';
import {IInvoice} from 'typings';

@Component({
    selector: 'app-invoice-status-tag',
    templateUrl: './invoice-status-tag.component.html',
    styleUrls: ['./invoice-status-tag.component.scss']
})
export class InvoiceStatusTagComponent {
    @Input() public status!: IInvoice['status_of_payment'];
}
