import {
    ParticipantCalculation,
    TransferCalculation
} from '../modules/shared/components/calculations/calculations.model';
import {GenericEntity} from '../entites/generic.entity';
import {FinancialNode} from '../modules/shared/components/financial-node/financial-node.model';
import {Group} from './group';
import {Profile} from './profile';
import {CompensationPlanModel} from '../modules/account-info/compensation/models/compensation-plan.model';
import {DisbursementTemplateModel} from '../modules/account-info/compensation/models/disbursement-template.model';
import {OverheadModel} from '../modules/account-info/compensation/models/overhead.model';
import {Contact} from './contact';
import {IContact, IContactPartLink, IFinancialTransfer} from '../../typings';
import {FinancialTransferEntity} from '../modules/account-info/compensation/models/financial-transfer.entity';

export class SalesEntity extends GenericEntity {
    id: number | null = null;
    sales_entity_id: number | null = null;
    type: string = SalesEntity.type_SET.profile;
    role: string = SalesEntity.role_SET.agent;
    owner__profile_fk_id: number | null = null;
    profile: Profile = new Profile();
    company_group: Group = new Group();
    contact: IContact = new Contact();
    contact_part_link: Partial<IContactPartLink> = {contact_id: null, link_title: null};
    is_primary: boolean = false;
    ignore_in_caps: boolean = false;
    compensation_plan: CompensationPlanModel = new CompensationPlanModel();
    children: SalesEntity[] = [];
    calculation: ParticipantCalculation = new ParticipantCalculation();
    transfer_calculation: TransferCalculation = new TransferCalculation();

    commission_deductions: FinancialTransferEntity[] = [];
    additional_incomes: FinancialTransferEntity[] = [];
    association_incomes: FinancialTransferEntity[] = [];
    additional_expenses: FinancialTransferEntity[] = [];
    association_expenses: FinancialTransferEntity[] = [];
    referral_expenses: FinancialTransferEntity[] = [];

    user_royalties: FinancialTransferEntity[] = [];
    agent_splits: FinancialTransferEntity[] = [];
    default_agent_splits: FinancialTransferEntity[] = [];
    compensation_expenses: FinancialTransferEntity[] = [];
    compensation_incomes: FinancialTransferEntity[] = [];
    company_splits: FinancialTransferEntity[] = [];
    payout: FinancialTransferEntity = new FinancialTransferEntity();
    participant_split: FinancialTransferEntity = new FinancialTransferEntity();

    disbursement_template: DisbursementTemplateModel = new DisbursementTemplateModel();
    overheads: OverheadModel[] = [];
    incentives: OverheadModel[] = [];
    calculated_overheads: FinancialNode[] = [];
    added_automatic: boolean = false;
    preceding__sales_entity_fk_id: number | null = null;
    overriden__compensation_profile_fk_id: number | null = null;

    notes: string = '';
    show_in_disbursement_pdf: boolean = false;
    applied: any[] = [];
    applied_templates: any[] = [];

    sales_volume_percent: number = 0;
    side_count: number = 0;

    has_zipi_pay = false;
    has_receive_only = false;

    static get helpers() {
        return Object.assign(
            {
                props: Object.getOwnPropertyNames(new this()).reduce((acc: {[key: string]: any}, curr) => {
                    acc[curr] = curr;
                    return acc;
                }, {}),
                build: {
                    profile: (val: any) => Object.assign(new Profile(), val),
                    disbursement_template: (val: any) => Object.assign(new DisbursementTemplateModel(), val),
                    company_group: (val: any) => Object.assign(new Group(), val),
                    children: (val: any[], fabric: any) =>
                        val.map((item) => (this.FABRIC as any)(SalesEntity)[fabric](item))
                }
            },
            GenericEntity.helpers
        );
    }
    setProfile(profile: Profile): SalesEntity {
        this.type = SalesEntity.type_SET.profile;
        this.profile = profile;
        if (profile.contact) {
            this.contact = profile.contact;
            this.setContactPartLink({
                contact_id: profile.contact.id,
                link_title: profile.contact.display_name
            });
        }
        return this;
    }

    setType(type: string) {
        this.type = type;
        return this;
    }

    setRole(role: string) {
        this.role = role;
        return this;
    }

    setGroup(company_group: Group): SalesEntity {
        this.type = SalesEntity.type_SET.company_group;
        this.company_group = company_group;
        if (company_group.contact) {
            this.contact = company_group.contact;
            this.setContactPartLink({
                contact_id: company_group.contact.id,
                link_title: company_group.contact.display_name
            });
        }
        return this;
    }

    setContact(contact: IContact): SalesEntity {
        this.contact = contact;
        return this.setType(SalesEntity.type_SET.contact);
    }

    setOverridenCompensationProfileId(overriden__compensation_profile_fk_id: number | null) {
        this.overriden__compensation_profile_fk_id = overriden__compensation_profile_fk_id;
        return this;
    }

    setContactPartLink(contactPartLink: Partial<IContactPartLink>) {
        this.contact_part_link = {
            contact_id: contactPartLink.contact_id,
            link_title: contactPartLink.link_title
        };
        return this;
    }

    setIsPrimary(is_primary: boolean) {
        this.is_primary = is_primary;
        return this;
    }

    setSalesVolume(sales_volume_percent: number) {
        this.sales_volume_percent = sales_volume_percent;
        return this;
    }

    setSideCount(side_count: number) {
        this.side_count = side_count;
        return this;
    }

    static get type_SET() {
        return {
            company_group: 'company_group',
            profile: 'profile',
            contact: 'contact'
        };
    }

    static get role_SET() {
        return {
            agent: 'agent',
            transfer: 'transfer',
            referral: 'referral'
        };
    }
}
