<div>
    <mat-card class="preview-body" *ngIf="reportData.types.length && reportData.entities.length">
        <div class="w-100">
            <table class="mat-table tables w-100">
                <!-- Header data -->
                <tr class="mat-header-row">
                    <th class="header-cell sticky description"><span>Source of Business</span></th>
                    <th class="header-cell sticky" *ngFor="let type of reportData.types">
                        <table class="group-item">
                            <tr>
                                <td colspan="3" class="text-center">{{ type }}</td>
                            </tr>
                            <tr>
                                <td class="number"><span>#</span></td>
                                <td class="value text-right"><span>Value</span></td>
                                <td class="total text-right"><span>% Total</span></td>
                            </tr>
                        </table>
                    </th>
                    <th class="header-cell sticky">
                        <table class="group-item">
                            <tr>
                                <td colspan="3" class="text-center"><span>Totals</span></td>
                            </tr>
                            <tr>
                                <td class="number"><span>#</span></td>
                                <td class="value text-right"><span>Value</span></td>
                                <td class="total text-right"><span>% Total</span></td>
                            </tr>
                        </table>
                    </th>
                </tr>
                <!-- End Header data -->

                <!-- General data -->
                <tr class="body-row" *ngFor="let entity of reportData.entities">
                    <td class="row-item description">{{ entity.name }}</td>
                    <td class="row-item" *ngFor="let data of entity.data">
                        <table class="group-item">
                            <tr>
                                <td class="number">{{ data.deals_count }}</td>
                                <td class="value text-right">
                                    <span>${{ data.sum_volume | reportFormatted: 'financial' }}</span>
                                </td>
                                <td class="total text-right">
                                    <span>{{ data.percent_of_total | reportFormatted: 'percent' }}</span>
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td class="row-item" *ngIf="entity.total">
                        <table class="group-item">
                            <tr>
                                <td class="number">{{ entity.total.deals_count }}</td>
                                <td class="value text-right">
                                    <span>${{ entity.total.sum_volume | reportFormatted: 'financial' }}</span>
                                </td>
                                <td class="total text-right">
                                    <span>{{ entity.total.percent_of_total | reportFormatted: 'percent' }}</span>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <!-- End general data -->

                <!-- Total Data -->
                <tr class="total-row">
                    <td class="row-item description">Totals</td>
                    <td class="row-item" *ngFor="let totalObj of reportData.totals.data">
                        <table class="group-item">
                            <tr>
                                <td class="number">{{ totalObj.deals_count }}</td>
                                <td class="value text-right">
                                    <span>${{ totalObj.sum_volume | reportFormatted: 'financial' }}</span>
                                </td>
                                <td class="total text-right">
                                    <span>{{ totalObj.percent_of_total | reportFormatted: 'percent' }}</span>
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td class="row-item">
                        <table class="group-item">
                            <tr>
                                <td class="number">{{ reportData.totals.summary.deals_count }}</td>
                                <td class="value text-right">
                                    <span>{{
                                        reportData.totals.summary.sum_volume | reportFormatted: 'financial'
                                    }}</span>
                                </td>
                                <td class="total text-right">
                                    <span>{{
                                        reportData.totals.summary.percent_of_total | reportFormatted: 'percent'
                                    }}</span>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <!-- End Total Data -->
            </table>
        </div>
    </mat-card>

    <mat-card class="preview-body" *ngIf="!reportData.types || !reportData.types.length || !reportData.entities.length">
        <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
    </mat-card>
</div>
