<!------- Loading ------->
<div class="load-container d-flex align-items-center justify-content-center" id="load-container" *ngIf="!isLoaded">
    <mat-spinner></mat-spinner>
</div>

<div class="container" *ngIf="isLoaded && !showErrorCard">
    <mat-card class="card-override">
        <mat-card-content class="col card-component">
            <div class="row">
                <div class="col-6 intro-section">
                    <div class="mat-typography">
                        <div class="books-logo">
                            <img class="books-img" src="/assets/images/skyslope-books-white.png" alt="" />
                        </div>
                        <H1 class="books-title">Create Your Account</H1>
                        <p class="books-subtitle">
                            You are invited to join SkySlope
                            <span style="white-space: nowrap">Books&#8212;the</span> smart way to build a real estate
                            business.
                        </p>
                        <p class="description">Please confirm your name and password to get started.</p>
                        <p class="description">
                            Invitation email: <b>{{ invitedProfile.email }}</b
                            ><br />Company: <b>{{ invitedProfile.company!.title }}</b>
                        </p>
                    </div>
                </div>
                <div class="col-6 mt-2" style="flex: auto">
                    <div style="margin-top: 30px" id="okta-login-container"></div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<mat-card class="card" *ngIf="showErrorCard">
    <mat-card-content>
        <p>This invitation is expired or has been already accepted.</p>
        <p>Please use <a href="https://zipi.app/login">Login page</a> or contact your administrator.</p>
    </mat-card-content>
</mat-card>
