import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {ILedgerAccount, IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {ZipiFinancialTransferService} from '../../../../../../../services/api/finance/zipi-financia-transfer.service';
import {CompanyGatewayService} from '../../../../../../../services/api/finance/company-gateway.service';
import {takeUntil} from 'rxjs/operators';
import {LedgerAccountService} from '../../../../../../../services/api/finance/ledger-accounts.service';
import {PaymentMethodsService} from '../../../../../../profile/services/payment-methods.service';
import {environment} from '../../../../../../../../environments/environment';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector: 'app-muhnee-add-bank-account-hosted-dialog',
    templateUrl: './muhnee-add-bank-account-hosted-dialog.component.html',
    styleUrls: ['./muhnee-add-bank-account-hosted-dialog.component.scss']
})
export class MuhneeAddBankAccountHostedDialogComponent implements OnInit, AfterViewInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    paymentGateway: IPaymentGateway | undefined;

    link: string | null = null;
    sanitizedUrl: SafeResourceUrl | null = null;
    iframeInit: boolean = false;

    listener: any;

    selectedAccount: {id: string; title: string; verified: boolean; is_cloud_bank: boolean} | undefined;

    env = environment;

    alreadyConnectedBankIds: Array<string> = [];
    allAvailableBankAccounts: Array<any> = [];

    // selectedAccount: any;

    currentStep: '0' | '1' | '2' | '3' | null = '0';
    iframeStep: 'choice' | 'instantly' | 'manually' = 'choice';

    availableLedgerAccounts: ILedgerAccount[] = [];
    isDefault: boolean = false;
    accountId: UntypedFormControl | undefined;

    applyDisabled: boolean = false;
    isLoading: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<MuhneeAddBankAccountHostedDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {gateway: IPaymentGateway},
        private fb: UntypedFormBuilder,
        public sanitizer: DomSanitizer,
        private zipiFinancialTransferService: ZipiFinancialTransferService,
        private companyGatewayService: CompanyGatewayService,
        private ledgerAccountService: LedgerAccountService,
        private paymentMethodsService: PaymentMethodsService
    ) {
        this.accountId = this.fb.control(null, [Validators.required]);
        this.listener = (e: MessageEvent<any>) => {
            const eventData = JSON.parse(e.data);
            switch (eventData.eventType) {
                case 'cancel': {
                    this.iframeInit = false;
                    this.dialogRef.close({isError: false, success: false, message: eventData.message});
                    break;
                }
                case 'success': {
                    this.iframeInit = false;
                    if (eventData.data && eventData.data.message) {
                        if (eventData.data.message === 'success_instantly') {
                            this.selectedAccount = {
                                id: eventData.data.more.data.bankAcs[0].id,
                                verified: true,
                                title: `${eventData.data.more.data.bankAcs[0].bankName} (${eventData.data.more.data.bankAcs[0].accountNumber.slice(-4)})`,
                                is_cloud_bank: false
                            };
                        } else if (eventData.data.message === 'success_manually') {
                            this.selectedAccount = {
                                id: eventData.data.more.data.bankAcData.id,
                                verified: false,
                                title: `${eventData.data.more.data.bankAcData.bankName} (${eventData.data.more.data.bankAcData.bankAccountNumber.slice(-4)})`,
                                is_cloud_bank: false
                            };
                        }
                        this.currentStep = '2';
                    } else {
                        this.dialogRef.close({isError: false, success: false, message: `Unexpected data.`});
                    }
                    break;
                }
                case 'error': {
                    this.iframeInit = false;
                    this.dialogRef.close({isError: true, success: false, message: eventData.message});
                    break;
                }
                case 'message': {
                    if (eventData.data && eventData.data.message && eventData.data.message === 'add_manualy') {
                        this.iframeStep = 'manually';
                    }
                    if (eventData.data && eventData.data.message && eventData.data.message === 'add_instantly') {
                        this.iframeStep = 'instantly';
                    }
                    break;
                }
            }
        };
    }

    ngOnInit() {
        this.paymentGateway = this.data.gateway;
        this.loadExternalAccounts();

        this.ledgerAccountService
            .getAvailableLedgerAccountsForFundingSourceByGatewayType(this.paymentGateway.type)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((accounts) => {
                this.availableLedgerAccounts = accounts.filter((account) => {
                    return account.type !== 'credit_card';
                });
            });
    }

    ngAfterViewInit() {
        window.addEventListener('message', this.listener);
    }

    loadExternalAccounts() {
        if (this.paymentGateway && this.paymentGateway.payment_gateway_id) {
            this.isLoading = true;
            this.companyGatewayService
                .getMuhneeAccountsForConnection(this.paymentGateway.payment_gateway_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    this.isLoading = false;
                    if (response && response.accounts && response.accounts.length > 0) {
                        this.allAvailableBankAccounts = response.accounts;
                        this.alreadyConnectedBankIds = response.connected_accounts_ids;
                        const unconnectedAccounts = this.allAvailableBankAccounts.filter(
                            (account) => !this.alreadyConnectedBankIds.includes(account.id)
                        );
                        if (unconnectedAccounts && unconnectedAccounts.length > 0) {
                            this.currentStep = '0';
                        } else {
                            this.loadLink();
                        }
                    } else {
                        this.loadLink();
                    }
                });
        }
    }

    loadLink() {
        if (this.paymentGateway && this.paymentGateway.payment_gateway_id) {
            this.currentStep = '1';
            this.companyGatewayService
                .getBankAccountSetupLink(this.paymentGateway.payment_gateway_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response.link) {
                        this.link = response.link;
                        this.sanitize(this.link);
                        this.iframeInit = true;
                    } else if (response.link === null) {
                        this.dialogRef.close({isError: true, success: false, message: `Cannot load Banks.`});
                    }
                });
        }
    }

    sanitize(url: string | null) {
        if (url) {
            this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
    }

    connectBank(account: any) {
        if (account && account.id) {
            this.selectedAccount = {
                id: account.id,
                verified: account.verified,
                title: `${account.bankName} (${account.lastFour})`,
                is_cloud_bank: account.isCloudBank
            };

            this.currentStep = '2';
        }
    }

    apply(skipLedger: boolean) {
        if (!this.isDefault && this.accountId && this.accountId.invalid) {
            this.accountId.markAllAsTouched();
            return;
        }
        this.applyDisabled = true;
        if (this.selectedAccount && this.accountId && this.paymentGateway && this.paymentGateway.payment_gateway_id) {
            const ledgerAccountId = this.accountId.value;
            let settings: {
                public_token: any;
                account_id: string;
                pending_auth_status: 'pending_manual_verification' | 'manually_verified' | 'need_relogin' | '';
                title: string;
                related_ledger_account_id: number | null;
                is_default_receiving: boolean | null;
                needDefaultLedger: boolean | null;
                account_type: 'cloud_bank' | 'bank' | 'card';
            } | null = {
                public_token: null,
                account_id: this.selectedAccount.id,
                pending_auth_status: !this.selectedAccount.verified ? 'pending_manual_verification' : '',
                title: this.selectedAccount && this.selectedAccount.title ? this.selectedAccount.title : `Some Bank`,
                related_ledger_account_id: null,
                is_default_receiving: false,
                needDefaultLedger: null,
                account_type: this.selectedAccount.is_cloud_bank ? 'cloud_bank' : 'bank'
            };

            if (skipLedger) {
                settings.needDefaultLedger = false;
            } else if (this.isDefault) {
                settings.needDefaultLedger = true;
            } else {
                settings.needDefaultLedger = false;
                settings.related_ledger_account_id = ledgerAccountId;
            }

            this.createPaymentMethod(this.paymentGateway.payment_gateway_id, settings);
        }
    }

    createPaymentMethod(
        gatewayId: number,
        settings: {
            public_token: any;
            account_id: string;
            pending_auth_status: 'pending_manual_verification' | 'manually_verified' | 'need_relogin' | '';
            title: string;
            related_ledger_account_id: number | null;
            is_default_receiving: boolean | null;
            needDefaultLedger: boolean | null;
            account_type: 'cloud_bank' | 'bank' | 'card';
        }
    ) {
        this.paymentMethodsService
            .createZipiFinancialPaymentMethod(gatewayId, settings)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe({
                next: (response) => {
                    this.applyDisabled = false;
                    if (response) {
                        if (settings.pending_auth_status === 'pending_manual_verification') {
                            this.currentStep = '3';
                        } else {
                            this.dialogRef.close({isError: false, success: true, message: ''});
                        }
                    }
                },
                error: (error) => {
                    this.applyDisabled = false;
                }
            });
    }

    removeExternalAccount(bankAccountExternalId: string) {
        if (this.paymentGateway && this.paymentGateway.payment_gateway_id && bankAccountExternalId) {
            this.paymentMethodsService
                .removeExternalAccount(this.paymentGateway.payment_gateway_id, bankAccountExternalId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe({
                    next: (response) => {
                        if (response) {
                            this.loadExternalAccounts();
                        }
                    },
                    error: (error) => {}
                });
        }
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        window.removeEventListener('message', this.listener);
    }
}
