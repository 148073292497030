import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {IContactsState} from '../../../../../../../contacts/store/contacts.reducer';
import {IDealDepositRequest} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-resend-request-dialog',
    templateUrl: './resend-request-dialog.component.html',
    styleUrls: ['./resend-request-dialog.component.scss']
})
export class ResendRequestDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    currentRequest: IDealDepositRequest | undefined;
    requestReceiverEmail: UntypedFormControl = new UntypedFormControl();

    constructor(
        private fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<ResendRequestDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        protected store: Store<IContactsState>
    ) {}

    ngOnInit() {
        this.currentRequest = this.data.depositRequest;
        this.requestReceiverEmail = this.fb.control(this.currentRequest!.request_receiver_email, Validators.required);
    }

    continue() {
        if (this.requestReceiverEmail.invalid) {
            this.requestReceiverEmail.markAllAsTouched();
            return;
        }
        const requestData = {email: this.requestReceiverEmail.value};
        this.dialogRef.close(requestData);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
