<div mat-dialog-title>
    <h3 *ngIf="data.type === 'person'">Add Person</h3>
    <h3 *ngIf="data.type === 'department'">Add Department</h3>
</div>

<mat-dialog-content [formGroup]="personGroup">
    <div class="d-flex align-items-md-center p-2 mb-2">
        <mat-icon class="mr-2" *ngIf="personGroup.value.type === 'department'">group</mat-icon>
        <mat-icon class="mr-2" *ngIf="personGroup.value.type === 'person'">person</mat-icon>
        <div class="row align-items-end w-100 d-nline-flex">
            <mat-form-field class="col-md-6 col-lg-4">
                <input
                    matInput
                    formControlName="first_name"
                    type="text"
                    [value]="personGroup.value.first_name"
                    [placeholder]="personGroup.value.type === 'department' ? 'Department name' : 'First Name'"
                    required
                />
            </mat-form-field>

            <mat-form-field class="col-md-6 col-lg-4" *ngIf="personGroup.value.type === 'person'">
                <input
                    matInput
                    formControlName="last_name"
                    type="text"
                    placeholder="Last Name"
                    [value]="personGroup.value.last_name"
                />
            </mat-form-field>

            <mat-form-field class="col-md-6 col-lg-4">
                <input matInput placeholder="Nickname" formControlName="preferred_name" />
            </mat-form-field>

            <div class="col-md-6 col-lg-4">
                <mat-form-field>
                    <input
                        matInput
                        formControlName="phone"
                        type="text"
                        placeholder="Phone"
                        #phoneInput
                        [maskito]="phoneMaskitoInputMask"
                        (blur)="onBlurMethod('phone')"
                        (click)="onClickMethod('phone')"
                    />
                    <mat-error *ngIf="personGroup.controls.phone.hasError('incorrect')">
                        Phone must be 10 digits long.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-6 col-lg-4">
                <mat-form-field>
                    <input
                        matInput
                        formControlName="fax"
                        type="text"
                        placeholder="Fax"
                        #faxInput
                        [maskito]="phoneMaskitoInputMask"
                        (blur)="onBlurMethod('fax')"
                        (click)="onClickMethod('fax')"
                    />
                    <mat-error *ngIf="personGroup.controls.fax.hasError('incorrect')">
                        Fax must be 10 digits long.
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-form-field class="col-md-6 col-lg-4">
                <input
                    matInput
                    formControlName="email"
                    type="email"
                    placeholder="Email"
                    [required]="personGroup.controls!.email.getError('required')"
                />
                <mat-error>This field is required</mat-error>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="text-right w-100">
        <button mat-raised-button color="accent" (click)="addPerson()" tabindex="1">Create</button>
        <button *ngIf="!data.hideCancel" mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">
            Cancel
        </button>
    </div>
</mat-dialog-actions>
