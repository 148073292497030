import {merge as observableMerge, Observable, Subject} from 'rxjs';

import {startWith} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {CompanySetupFormModel} from '../../account-info/settings/company-setup.component';
import {UserSetupFormModel} from '../../progress/user-goals/user-goals.component';
import {Router} from '@angular/router';
import {CurrentProfileSource} from '../../../services/sources/current-profile.source';
import {CompanyGoalsFormModel} from '../../goals/common/model';

@Injectable()
export class InitialSetupApi {
    constructor(public requester: ServiceRequester) {}

    getCompanySetup(): Promise<CompanySetupFormModel> {
        return this.requester
            .makeMsCall('/initial-setup/company-setup', 'GET')
            .then((obj) => Object.assign(new CompanySetupFormModel(), obj));
    }

    postCompanySetup(companySetup: CompanySetupFormModel): Promise<any> {
        return this.requester.makeMsCall('/initial-setup/company-setup', 'POST', companySetup);
    }

    getCompanyGoals(): Promise<CompanyGoalsFormModel> {
        return this.requester
            .makeMsCall('/initial-setup/company-goals', 'GET')
            .then((obj) => Object.assign(new CompanyGoalsFormModel(), obj));
    }

    postCompanyGoals(companyGoals: CompanyGoalsFormModel): Promise<any> {
        return this.requester.makeMsCall('/initial-setup/company-goals', 'POST', companyGoals);
    }

    getUserSetup(): Promise<UserSetupFormModel> {
        return this.requester
            .makeMsCall('/initial-setup/user-setup', 'GET')
            .then((obj) => Object.assign(new UserSetupFormModel(), obj));
    }

    postUserSetup(userSetup: UserSetupFormModel): Promise<any> {
        return this.requester.makeMsCall('/initial-setup/user-setup', 'POST', userSetup);
    }

    getPagesList(): Promise<PagesListResponse> {
        return this.requester.makeMsCall('/initial-setup/pages-list', 'GET');
    }

    postInitialSetup(data: object) {
        return this.requester.makeMsCall('/initial-setup', 'POST', data);
    }
}

export class PagesListResponse {
    companySetup: boolean | undefined;
    goalsSetup: boolean | undefined;
}

@Injectable()
export class InitialSetupSource {
    public triggers = {
        pagesList: new Subject<PagesListResponse>(),
        companySetup: new Subject<object>(),
        companyGoals: new Subject<object>(),
        userSetup: new Subject<object>()
    };

    private events = {
        pagesList: <Observable<PagesListResponse>>observableMerge(this.triggers.pagesList),
        companySetup: <Observable<object>>observableMerge(this.triggers.companySetup),
        companyGoals: <Observable<object>>observableMerge(this.triggers.companyGoals),
        userSetup: <Observable<object>>observableMerge(this.triggers.userSetup)
    };

    protected current: {
        pagesList: PagesListResponse | null;
        companySetup: object | null;
        companyGoals: object | null;
        userSetup: object | null;
    } = {
        pagesList: null,
        companySetup: null,
        companyGoals: null,
        userSetup: null
    };

    // @TODO: CVTool import should be here
    public get pagesList(): Observable<PagesListResponse> {
        if (this.current.pagesList) {
            return this.events.pagesList.pipe(startWith(this.current.pagesList));
        }
        return this.events.pagesList;
    }

    public get companySetup(): Observable<object> {
        if (this.current.companySetup) {
            return this.events.companySetup.pipe(startWith(this.current.companySetup));
        }
        return this.events.companySetup;
    }

    public get companyGoals(): Observable<object> {
        if (this.current.companyGoals) {
            return this.events.companyGoals.pipe(startWith(this.current.companyGoals));
        }
        return this.events.companyGoals;
    }

    public get userSetup(): Observable<object> {
        if (this.current.userSetup) {
            return this.events.userSetup.pipe(startWith(this.current.userSetup));
        }
        return this.events.userSetup;
    }

    constructor(protected router: Router) {
        this.pagesList.subscribe((last) => {
            this.current.pagesList = last;
        });
        this.companySetup.subscribe((last) => {
            this.current.companySetup = last;
        });
        this.companyGoals.subscribe((last) => {
            this.current.companyGoals = last;
        });
        this.userSetup.subscribe((last) => {
            this.current.userSetup = last;
        });
    }
}

@Injectable()
export class InitialSetupService {
    public isCompanySetupNeeded: boolean = true;
    public isCompanyGoalsNeeded: boolean = true;
    public isUserSetupNeeded: boolean = true;
    public isLoaded = false;

    constructor(
        public api: InitialSetupApi,
        public source: InitialSetupSource,
        protected profileSource: CurrentProfileSource
    ) {
        this.profileSource.changeProfileEvent.subscribe(() => {
            this.isLoaded = false;
        });
    }

    preloadInitialSetupData() {
        if (this.isLoaded) {
            return Promise.resolve();
        }
        return this.api.getPagesList().then((list) => {
            const promises = [];

            if (list.goalsSetup) {
                promises.push(
                    this.api.getCompanyGoals().then((goals) => {
                        this.source.triggers.companyGoals.next(goals);
                    })
                );
            } else {
                this.isCompanyGoalsNeeded = false;
            }

            if (list.companySetup) {
                promises.push(
                    this.api.getCompanySetup().then((companySetup) => {
                        this.source.triggers.companySetup.next(companySetup);
                    })
                );
            } else {
                this.isCompanySetupNeeded = false;
            }
            promises.push(
                this.api.getUserSetup().then((userSetup) => {
                    this.source.triggers.userSetup.next(userSetup);
                })
            );

            return Promise.all(promises).then(() => {
                this.isLoaded = true;
            });
        });
    }
}
