import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PendingRequestsService} from 'app/services/pending-requests.service';
import {UserNotificationService} from 'app/modules/user-notification/user-notification.service';
import {SessionService} from '../services/session.service';
import {AuthService} from '../services/auth.service';
import {environment} from 'environments/environment';
import {Router} from '@angular/router';
import {MessagesService} from '../modules/messages/messages.service';
import {EventsService} from '../services/events.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NotificationsServiceZipi} from '../modules/notifications/notifications.service';
import {ICompanySettings} from '@cyberco-nodejs/zipi-typings';
import {MarketplaceAddonInstanceModel} from '../modules/account-info/marketplace/marketplace.models';
import {MarketplaceSource} from '../modules/account-info/marketplace/marketplace.source';
import {MarketplaceApi} from '../modules/account-info/marketplace/marketplace.api';
import {MatExpansionPanel} from '@angular/material/expansion';
import {MatSidenavContainer} from '@angular/material/sidenav';
import {RbacService} from '../modules/rbac/rbac.service';
import {FeatureFlagsService} from '../modules/feature-flags/feature-flags.service';
import {InvoiceBillModalDialogComponent} from '@app/modules/finance/components/invoice-bill-modal-dialog/invoice-bill-modal-dialog.component';
import {SupportAccountLookupModalComponent} from '@app/modules/account-info/users-licenses/support-account-lookup-modal-dialog/support-account-lookup-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {UserAdminService} from '@app/services/user-admin.service';
import {User} from '@app/models/user';
import {SkyslopeAuth} from '@app/modules/auth/services/okta/skyslope-auth.service';

@Component({
    selector: 'app-user-layout',
    templateUrl: './user-layout.component.html',
    styleUrls: ['./user-layout.component.scss']
})
export class UserLayoutComponent implements OnDestroy, OnInit {
    private unsubscribe: Subject<void> = new Subject();
    hoverer$ = new Subject<void>();
    public allAddonsInstances: MarketplaceAddonInstanceModel[] = [];
    public activatedAndFeaturedAddonsInstances: MarketplaceAddonInstanceModel[] = [];
    public sidenavOpened: boolean = localStorage.getItem('pw_sidenav_state') === 'opened';
    public sidenavTransition: boolean = false;
    public isSkyslopeAppsMenuFlagEnabled: boolean = true;
    public submenuHeaderHeight: string = '48px';
    public isAdminSupportUser: boolean = false;
    randomFragment = '';
    notificationsSidenavOpened = false;
    accountsSidenavOpened = false;
    unseenMessagesCounter: number;
    notifications: Array<any> = [];
    spinnerNeeded = false;
    enableInvoiceStatements: boolean = false;

    @ViewChild('deals', {static: false}) deals: MatExpansionPanel | undefined;
    @ViewChild('sales', {static: false}) sales: MatExpansionPanel | undefined;
    @ViewChild('purchases', {static: false}) purchases: MatExpansionPanel | undefined;

    public needOpenSubmenuItems: any = {
        deals: localStorage.getItem('pw_sidenav_deals') === 'opened',
        sales: localStorage.getItem('pw_sidenav_sales') === 'opened',
        purchases: localStorage.getItem('pw_sidenav_purchases') === 'opened',
        lending: localStorage.getItem('pw_sidenav_lending') === 'opened',
        accounting: localStorage.getItem('pw_sidenav_accounting') === 'opened',
        reporting: localStorage.getItem('pw_sidenav_reporting') === 'opened',
        settings: localStorage.getItem('pw_sidenav_settings') === 'opened',
        directory: localStorage.getItem('pw_sidenav_directory') === 'opened'
    };

    companySettings: ICompanySettings | null = null;
    notificationsNotLoadedYet = false;

    // feature flag
    public autoTagsEnabledFlag = false;

    public onSidenavSubmenuToggle(name: string) {
        if (localStorage.getItem(`pw_sidenav_${name}`) === 'opened') {
            Object.keys(this.needOpenSubmenuItems).forEach((item) => {
                localStorage.setItem(`pw_sidenav_${item}`, 'closed');
            });
            localStorage.setItem(`pw_sidenav_${name}`, 'closed');
        } else {
            Object.keys(this.needOpenSubmenuItems).forEach((item) => {
                localStorage.setItem(`pw_sidenav_${item}`, 'closed');
            });
            localStorage.setItem(`pw_sidenav_${name}`, 'opened');
        }
    }

    accountsSidenavOpenChanged(isOpen: boolean) {
        this.accountsSidenavOpened = isOpen;
    }
    openAccountsSidenav() {
        if (this.accountsSidenavOpened) {
            return;
        }
        this.accountsSidenavOpened = true;
    }
    closeAccountsSidenav() {
        if (this.accountsSidenavOpened === false) {
            return;
        }
        this.accountsSidenavOpened = false;
    }

    toggleNotificationsSidenav() {
        if (this.notificationsNotLoadedYet) {
            return;
        }

        if (this.notificationsSidenavOpened) {
            this.closeNotificationsSidenav();
            return;
        }
        this.closeAccountsSidenav();
        this.openNotificationsSidenav();
    }

    openNotificationsSidenav() {
        if (this.notificationsSidenavOpened) {
            return;
        }
        this.notificationsSidenavOpened = true;
    }
    closeNotificationsSidenav() {
        if (this.notificationsSidenavOpened === false) {
            return;
        }
        this.notificationsSidenavOpened = false;
    }
    notificationsSidenavOpenChanged(isOpen: boolean) {
        this.notificationsSidenavOpened = isOpen;
    }

    public toggleSidenav(sidenavContainer: MatSidenavContainer) {
        this.sidenavTransition = true;

        switch (this.sidenavOpened) {
            case false:
                localStorage.setItem('pw_sidenav_state', 'opened');
                this.sidenavOpened = true;
                break;
            case true:
                localStorage.setItem('pw_sidenav_state', 'closed');
                this.sidenavOpened = false;
                break;
        }

        // update after css transition ends change width
        setTimeout(() => {
            sidenavContainer.updateContentMargins();
            this.sidenavTransition = false;
        }, 500);
    }

    @HostListener('window:beforeunload')
    closeAllMenuDropdowns() {
        // set all dropdown menu closed
        Object.keys(this.needOpenSubmenuItems).forEach((item) => {
            localStorage.setItem(`pw_sidenav_${item}`, 'closed');
        });
    }

    constructor(
        public pendingRequestsService: PendingRequestsService,
        public userNotificationService: UserNotificationService,
        public router: Router,
        // DO NOT REMOVE THIS, tt loads addons into marketplaceSource, we use them here in the left navbar
        protected marketplaceApi: MarketplaceApi,
        protected marketplaceSource: MarketplaceSource,
        public sessionService: SessionService,
        public authService: AuthService,
        protected messagesService: MessagesService,
        private eventsService: EventsService,
        protected notificationService: NotificationsServiceZipi,
        private changeDetector: ChangeDetectorRef | null,
        protected rbacService: RbacService,
        protected featureFlagsService: FeatureFlagsService,
        protected dialog: MatDialog,
        private userAdminService: UserAdminService,
        private skyslopeAuthService: SkyslopeAuth
    ) {
        messagesService.initialize();

        // fetching addon instances to show them on left navigation
        this.marketplaceSource.addonsChangeEvent.pipe(takeUntil(this.unsubscribe)).subscribe((instances) => {
            this.allAddonsInstances = instances.map((instance) => {
                if (!instance.addon) {
                    return instance;
                }
                const res: MarketplaceAddonInstanceModel = Object.assign(
                    new MarketplaceAddonInstanceModel(),
                    instance.addon.preset
                );
                res.addon = instance.addon;
                res.activated = instance.activated;
                res.is_featured = instance.is_featured;
                return res;
            });
            // filter all addons instances so that only featuread and activated addons left
            this.activatedAndFeaturedAddonsInstances = this.allAddonsInstances.filter(
                (instance) =>
                    instance.activated && instance.addon && instance.addon.is_featurable && instance.is_featured
            );
        });

        this.unseenMessagesCounter = this.userNotificationService.asyncNotificationsUnreadCount;
        this.userNotificationService.asyncNotificationsUnreadCountChange
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((val) => {
                this.unseenMessagesCounter = val;
                // todo need to optimise without changeDetector
                this.changeDetector!.detectChanges();
            });

        this.spinnerNeeded = this.userNotificationService.asyncNotificationsSpinnerNeeded;
        this.userNotificationService.asyncNotificationsSpinnerNeededChange
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((val) => {
                this.spinnerNeeded = val;
                // todo need to optimise without changeDetector
                this.changeDetector!.detectChanges();
            });

        this.notificationsNotLoadedYet = this.userNotificationService.bellDisabled;
        this.userNotificationService.bellDisabledChange.pipe(takeUntil(this.unsubscribe)).subscribe((val) => {
            this.notificationsNotLoadedYet = val;
        });
    }

    getAddonAccessRule(addon: MarketplaceAddonInstanceModel): {[key: string]: boolean} | undefined {
        let rule;
        if (!addon.addon) {
            return {};
        }

        rule = this.marketplaceApi._getAddonAccessRuleByAddonSlug(addon.addon.slug);

        return rule;
    }

    goToSuperAdmin() {
        this.authService.logout();
        window.location.href = environment.services.pwAdmin.url + '/users/list';
    }

    openSupportAccountLookup() {
        const dialogRef = this.dialog.open(SupportAccountLookupModalComponent, {
            height: '80%',
            width: '85vw',
            maxWidth: '85vw',
            panelClass: 'full-screen-modal'
        });
    }

    clearContactSearch() {
        this.eventsService.updateContactsEventValue(null);
    }

    async ngOnInit() {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.autoTagsEnabledFlag = this.featureFlagsService.isFeatureEnabled('company:deals_auto_tagging');
                this.isSkyslopeAppsMenuFlagEnabled =
                    this.featureFlagsService.isFeatureEnabled('platform:skyslope_apps_menu');
                this.enableInvoiceStatements = this.featureFlagsService.isFeatureEnabled('invoices:statements');
            });

        // For some reason RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload})
        // didn't work. That's why,
        // I have to use this hack to reload page then navigating the same page
        this.hoverer$
            // interval(1000)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.randomFragment = (+new Date()).toString(32);
            });

        this.checkAdminAccess();
    }

    addonCompanySettings(instance: MarketplaceAddonInstanceModel) {
        let settingsPath = '/integration/settings';
        if (
            instance.addon &&
            instance.addon.settings &&
            instance.addon.settings.urls &&
            instance.addon.settings.enabled_company_settings
        ) {
            if (instance.addon.settings.urls.redirect_settings) {
                settingsPath = instance.addon.settings.urls.redirect_settings;
            }
        }

        if (instance.activated && instance.addon?.settings?.enabled_company_settings) {
            this.router.navigate([settingsPath]);
        }
    }

    async billsNavigateByClick() {
        if (await this.rbacService.isAllowed({purchases__view_bills: true})) {
            this.router.navigate(['/purchases/bills']);
        } else if (await this.rbacService.isAllowed({purchases__view_recurring_bills: true})) {
            this.router.navigate(['/purchases/bills/recurring']);
        } else if (await this.rbacService.isAllowed({purchases__view_source_documents: true})) {
            this.router.navigate(['/purchases/sourcedocuments']);
        }
    }

    async vendorCreditsNavigateByClick() {
        if (await this.rbacService.isAllowed({purchases__view_vendor_credits: true})) {
            this.router.navigate(['/purchases/vendorcredits']);
        } else if (await this.rbacService.isAllowed({purchases__view_source_documents: true})) {
            this.router.navigate(['/purchases/sourcecredits']);
        }
    }

    async creditNotesNavigateByClick() {
        if (await this.rbacService.isAllowed({sales__view_customer_credits: true})) {
            this.router.navigate(['/sales/creditnotes']);
        } else if (await this.rbacService.isAllowed({sales__view_source_documents: true})) {
            this.router.navigate(['/sales/sourcecredits']);
        }
    }

    async invoicesNavigateByClick() {
        if (await this.rbacService.isAllowed({sales__view_invoices: true})) {
            this.router.navigate(['/sales/invoices']);
        } else if (await this.rbacService.isAllowed({sales__view_recurring_invoices: true})) {
            this.router.navigate(['/sales/invoices/recurring']);
        } else if (await this.rbacService.isAllowed({sales__view_source_documents: true})) {
            this.router.navigate(['/sales/sourcedocuments']);
        }
    }

    async companyNavigateByClick() {
        if (await this.rbacService.isAllowed({company_settings__manage_general_settings: true})) {
            this.router.navigate(['/company/settings']);
        } else if (await this.rbacService.isAllowed({company_settings__manage_compensation: true})) {
            this.router.navigate(['/company/profiles']);
        } else if (await this.rbacService.isAllowed({company_settings__manage_purchase_settings: true})) {
            this.router.navigate(['/company/settings/purchase-settings']);
        } else if (await this.rbacService.isAllowed({company_settings__manage_tags: true})) {
            this.router.navigate(['/company/settings/tags']);
        } else if (await this.rbacService.isAllowed({company_settings__manage_tags: true})) {
            this.router.navigate(['/company/settings/automation']);
        } else if (await this.rbacService.isAllowed({company_settings__manage_franchise: true})) {
            this.router.navigate(['/franchise']);
        } else if (await this.rbacService.isAllowed({company_settings__view_roles: true})) {
            this.router.navigate(['/company/directory/roles']);
        }
    }

    async escrowDepositsNavigateByClick() {
        if (
            (await this.rbacService.isAllowed({escrow_deposits__access: true})) ||
            (await this.rbacService.isAllowed({deals__manage_escrow_deposits: true}))
        ) {
            this.router.navigate(['/escrowdeposit']);
        } else if (
            (await this.rbacService.isAllowed({escrow_deposits__access: true})) ||
            (await this.rbacService.isAllowed({deals__manage_escrow_deposits: true}))
        ) {
            this.router.navigate(['/escrowdeposit/releases']);
        }
    }

    async financeNewNavigateByClick() {
        if (await this.rbacService.isAllowed({company_settings__view_ledger_accounts: true})) {
            this.router.navigate(['/company/finance/chartofaccounts']);
        } else if (await this.rbacService.isAllowed({company_settings__view_products: true})) {
            this.router.navigate(['/company/finance/products']);
        } else if (await this.rbacService.isAllowed({company_settings__view_opening_balances: true})) {
            this.router.navigate(['/company/finance/openingbalances']);
        }
    }

    isMarketplaceHighlightedInNavigation() {
        const url = this.router.url;
        if (url.includes('/marketplace')) {
            if (this.activatedAndFeaturedAddonsInstances) {
                for (const instance of this.activatedAndFeaturedAddonsInstances) {
                    if (
                        instance.addon &&
                        instance.addon.settings &&
                        instance.addon.settings.urls &&
                        instance.addon.settings.urls.redirect_settings &&
                        url.includes(instance.addon.settings.urls.redirect_settings)
                    ) {
                        return false;
                    }
                }
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    checkAdminAccess() {
        this.skyslopeAuthService
            .getUser()
            .then((user) => {
                if (user) {
                    this.userAdminService.checkSupportAccess(user.id).then((observe) => {
                        observe.pipe(takeUntil(this.unsubscribe)).subscribe((response: any) => {
                            this.isAdminSupportUser = response.ok && response.status === 200;
                        });
                    });
                }
            })
            .catch((err) => {
                console.error(`Check Admin Access Error : ${err}`);
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.hoverer$.complete();
        this.changeDetector = null;
        return this.messagesService.disconnect();
    }
}
