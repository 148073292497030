import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {Store, select} from '@ngrx/store';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {SessionService} from '../../../services/session.service';
import {MessagesService} from '../../messages/messages.service';
import {ProfilesService} from '../../../services/profiles.service';
import {FranchiseRequestEffectsService} from '../store/franchise.effects';
import {IFranchiseState} from '../store/franchise.reducer';
import {
    SaveFranchiseRequestPendingAction,
    AcceptOrRejectFranchiseRequestPendingAction,
    CancelFranchiseRequestPendingAction,
    DisconnectFranchiseRequestPendingAction
} from '../store/franchise.actions';
import {
    selectFranchiseRequestsChildFromMe,
    selectFranchiseRequestsChildToMe,
    selectFranchiseRequestsParentFromMe,
    selectFranchiseRequestsParentToMe
} from '../store/franchise.selectors';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {simpleEmailValidator, simpleEmailValidatorPattern} from '../../contacts/store/contact.utilities';

@Component({
    selector: 'app-parent-child',
    template: `
        <section class="franchise-section p-4">
            <mat-card>
                <div class="d-flex">
                    <h2 class="d-inline-block">
                        {{ kind === 'parent' ? 'Parent company' : 'Child companies' }}
                    </h2>
                    <button
                        *ngIf="!expanded"
                        mat-button
                        color="primary"
                        class="mat-button"
                        (click)="toggleExpandedPanel()"
                    >
                        Request
                    </button>
                    <button *ngIf="expanded" mat-button color="warn" class="mat-button" (click)="toggleExpandedPanel()">
                        Cancel
                    </button>
                </div>
                <div *ngIf="expanded" [formGroup]="companyRoleForm">
                    <mat-form-field>
                        <input matInput formControlName="send_to_email" placeholder="Send to emails" />
                        <mat-error>Invalid email address.</mat-error>
                    </mat-form-field>
                    <mat-checkbox
                        *ngFor="let permission of permissions"
                        [formControlName]="permission.name"
                        class="mx-4"
                    >
                        {{ permission.label }}
                    </mat-checkbox>
                    <button mat-button color="primary" class="mat-button" (click)="send()">Send</button>
                </div>
                <hr />
                <h3>Requests from me:</h3>
                <mat-list role="list">
                    <mat-list-item
                        style="height: unset; padding: 5px 0;"
                        role="listitem"
                        *ngFor="let req of requestsFromMe"
                    >
                        <div class="d-flex w-100 justify-content-between" *ngIf="req.status === 'sent'">
                            <div>Sent to: {{ req.send_to_email.join(', ') }}</div>
                            <div>
                                Status: {{ req.status }}
                                <button mat-raised-button color="warn" (click)="cancelRequest(req.id)">Delete</button>
                            </div>
                        </div>
                        <div class="d-flex w-100 justify-content-between" *ngIf="req.status === 'approved'">
                            <div>
                                <div>Approved by: {{ req.approvedByProfile?.first_name }}</div>
                                <div>Email: {{ req.approvedByProfile?.email }}</div>
                                <div>Company: {{ req.approvedByCompany?.title }}</div>
                            </div>
                            <div>
                                Status: {{ req.status }}
                                <button mat-raised-button color="warn" (click)="disconnect(req.id)">Disconnect</button>
                            </div>
                        </div>
                        <div class="d-flex w-100 justify-content-between" *ngIf="req.status === 'rejected'">
                            <div>
                                <div>Rejected by: {{ req.approvedByProfile?.first_name }}</div>
                                <div>Email: {{ req.approvedByProfile?.email }}</div>
                                <div>Company: {{ req.approvedByCompany?.title }}</div>
                            </div>
                            <div>
                                Status: {{ req.status }}
                                <button mat-raised-button color="warn" (click)="cancelRequest(req.id)">Delete</button>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>
                <hr />
                <h3>Requests to me:</h3>
                <mat-list role="list">
                    <mat-list-item
                        style="height: unset; padding: 5px 0;"
                        role="listitem"
                        *ngFor="let req of requestsToMe"
                    >
                        <div class="d-flex w-100 justify-content-between" *ngIf="req.status === 'sent'">
                            <div>
                                <div>
                                    Request from: {{ req.sentByProfile?.first_name }} {{ req.sentByProfile?.last_name }}
                                </div>
                                <div>Email: {{ req.sentByProfile?.email }}</div>
                                <div>Company: {{ req.sentByCompany?.title }}</div>
                                <div>
                                    Permissions:
                                    <span *ngFor="let permission of permissions" class="mr-4">
                                        {{ getPermissionValue(req, permission) ? permission.label : '' }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <button
                                    class="mr-2"
                                    mat-raised-button
                                    color="primary"
                                    (click)="acceptOrReject(req.id, 'approved')"
                                >
                                    Accept
                                </button>
                                <button mat-raised-button color="warn" (click)="acceptOrReject(req.id, 'rejected')">
                                    Reject
                                </button>
                            </div>
                        </div>
                        <div class="d-flex w-100 justify-content-between" *ngIf="req.status === 'approved'">
                            <div>
                                <div>Approved by: {{ req.approvedByProfile?.first_name }}</div>
                                <div>Email: {{ req.approvedByProfile?.email }}</div>
                                <div>Company: {{ req.approvedByCompany?.title }}</div>
                            </div>
                            <div>
                                <button mat-raised-button color="warn" (click)="disconnect(req.id)">Disconnect</button>
                            </div>
                        </div>
                        <div class="d-flex w-100 justify-content-between" *ngIf="req.status === 'rejected'">
                            <div>
                                <div>Rejected by: {{ req.approvedByProfile?.first_name }}</div>
                                <div>Email: {{ req.approvedByProfile?.email }}</div>
                                <div>Company: {{ req.approvedByCompany?.title }}</div>
                            </div>
                            <div>
                                Status: {{ req.status }}
                                <button mat-raised-button color="warn" (click)="cancelRequest(req.id)">Delete</button>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>
            </mat-card>
        </section>
    `,
    styles: [
        `
            .mat-card {
                border: 1px lightgrey solid;
            }
        `
    ]
})
export class ParentChildComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() kind: string = '';
    public expanded: boolean = false;
    public requestsFromMe: any[] = [];
    public requestsToMe: any[] = [];
    permissions = [
        {name: 'recurring_expense_creation', label: 'Recurring Expense Creation'},
        {name: 'recurring_bill_creation', label: 'Recurring Bill Creation'},
        {name: 'deal_report_type_access', label: 'Deal Report Type Access'}
    ];
    companyRoleForm: UntypedFormGroup = this.fb.group({
        send_to_email: ['', [Validators.required, simpleEmailValidator]],
        recurring_expense_creation: [false],
        recurring_bill_creation: [false],
        deal_report_type_access: [false]
    });

    constructor(
        protected router: Router,
        protected sessionService: SessionService,
        protected messagesService: MessagesService,
        protected profilesService: ProfilesService,
        private store: Store<IFranchiseState>,
        private franchiseEffects: FranchiseRequestEffectsService,
        private fb: UntypedFormBuilder
    ) {}

    ngOnInit() {
        if (this.kind === 'parent') {
            this.store
                .pipe(select(selectFranchiseRequestsParentFromMe), takeUntil(this.unsubscribe))
                .subscribe((list) => (this.requestsFromMe = list));

            this.store
                .pipe(select(selectFranchiseRequestsChildToMe), takeUntil(this.unsubscribe))
                .subscribe((list) => (this.requestsToMe = list));
        } else if (this.kind === 'child') {
            this.store
                .pipe(select(selectFranchiseRequestsChildFromMe), takeUntil(this.unsubscribe))
                .subscribe((list) => (this.requestsFromMe = list));

            this.store
                .pipe(select(selectFranchiseRequestsParentToMe), takeUntil(this.unsubscribe))
                .subscribe((list) => (this.requestsToMe = list));
        }
    }

    toggleExpandedPanel() {
        this.expanded = !this.expanded;
    }

    validateEmail(email: string) {
        const re = simpleEmailValidatorPattern;
        return re.test(String(email).toLowerCase());
    }

    disconnect(reqId: number) {
        console.info('disconnect');
        if (confirm('Are you sure you want to disconnect this request?')) {
            this.store.dispatch(new DisconnectFranchiseRequestPendingAction(reqId));
        }
    }

    send() {
        let type = '';
        if (this.kind === 'parent') {
            type = 'request_to_parent';
        } else if (this.kind === 'child') {
            type = 'request_to_child';
        }

        const values = this.companyRoleForm.getRawValue();
        const emails = values.send_to_email.split(',').map((str: string) => str.trim());

        // if has invalid email
        const emailsInvalid = emails.some((email: string) => !this.validateEmail(email));
        if (emailsInvalid) {
            alert('some emails are invalid');
            return;
        }

        const reqData = {
            send_to_email: emails,
            type: type,
            parent_permissions: {
                recurring_expense_creation: values.recurring_expense_creation,
                recurring_bill_creation: values.recurring_bill_creation,
                deal_report_type_access: values.deal_report_type_access
            }
        };

        // this.franchiseSrv.createFranchiseRequest(this.franchiseObject)
        //     .subscribe(a => { console.info(a, 'a'); });
        this.store.dispatch(new SaveFranchiseRequestPendingAction(reqData));

        // clear state
        // this.franchiseObject = {
        //     send_to_email: '',
        //     recurring_expense_creation: false,
        //     deal_report_type_access: false,
        //     deal_report_type_access: false
        // };
        this.companyRoleForm.reset();
        this.expanded = false;
    }

    cancelRequest(reqId: number) {
        if (confirm('Are you sure you want to delete this request?')) {
            this.store.dispatch(new CancelFranchiseRequestPendingAction(reqId));
        }
    }

    acceptOrReject(reqId: number, status: string) {
        const data = {
            reqId,
            status
        };

        if (confirm('Are you sure you want to accept/reject this request?')) {
            this.store.dispatch(new AcceptOrRejectFranchiseRequestPendingAction(data));
        }
    }

    getPermissionValue(req: any, permission: any) {
        if (!req.parent_permissions) {
            return false;
        }
        return JSON.parse(req.parent_permissions)[permission.name];
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
