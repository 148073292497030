import {Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormBuilder, Validators, UntypedFormControl} from '@angular/forms';
import {IInvoice, IInvoiceItem} from '@cyberco-nodejs/zipi-typings';
import {Subject, of} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {IMaybeValidInvoiceTemplate} from '../../recurring-invoice/edit-recurring-invoice/edit-recurring-invoice.component';
import {NotificationsService} from 'angular2-notifications';
import {ActivatedRoute, Router} from '@angular/router';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {SessionService} from 'app/services/session.service';
import * as moment from 'moment';
import {TagsService} from 'app/services/api/tags.service';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {TransactionsService} from 'app/services/api/finance/transactions.service';
import {cleanCurrencyString} from '../../../../../utilities/maskito';

@Component({
    selector: 'app-edit-invoice-page',
    templateUrl: 'edit-invoice-page.component.html',
    styleUrls: ['edit-invoice-page.component.css']
})
export class EditInvoicePageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() dialogMode = false;
    @Input() dialogInvoiceId: number | undefined;
    @Output() invoiceUpdatedInDialog = new EventEmitter();

    @ViewChild('auto') matAutocomplete: MatAutocomplete | undefined;
    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement> | undefined;

    invoice: IInvoice | undefined;

    invoiceId: number | null = null;

    invoiceDateControl: UntypedFormControl;

    updateDisabled: boolean = false;
    createDisabled: boolean = false;
    deleteDisabled: boolean = false;

    chargeFromCredits: boolean;
    creditsBalance: number = 0;
    currentContactId: number | null = null;

    private maybeValidInvoice: IMaybeValidInvoiceTemplate = {valid: false, template: null, association: null};

    currentCompanyId: number | null = null;

    selectedTagsIds: Array<any> = [];
    currentTag: any;
    allTags: Array<{tag_id: number; title: string}> = [];
    disableTags: boolean = false;

    makeInvoiceFormTouch: Subject<void> = new Subject<void>();

    editType: 'full' | 'restricted' | 'blocked' = 'full';

    constructor(
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService,
        protected activatedRoute: ActivatedRoute,
        private invoicesService: InvoicesService,
        private tagsService: TagsService,
        public router: Router,
        private sessionService: SessionService,
        private transactionsService: TransactionsService
    ) {
        this.invoiceDateControl = this.fb.control(Number(moment().format('YYYYMMDD')), Validators.required);
        this.chargeFromCredits = false;
    }

    ngOnInit() {
        if (
            this.sessionService.profile &&
            this.sessionService.profile.company &&
            this.sessionService.profile.company.id
        ) {
            this.currentCompanyId = this.sessionService.profile.company.id;
        }
        const currentGroupId = Number(localStorage.getItem('current_company_group_id'));
        if (this.sessionService.profile && this.sessionService.profile.company_groups_member) {
            const currentGroupMember = this.sessionService.profile.company_groups_member.find((gm) => {
                if (gm.company_group) {
                    return gm.company_group.id === currentGroupId;
                } else {
                    return false;
                }
            });
            if (currentGroupMember && currentGroupMember.company_group && currentGroupMember.company_group.tag) {
                this.currentTag = currentGroupMember.company_group.tag;
            }
        }
        this.allTags = this.sessionService.companyTags;

        this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            this.invoiceId = params['id'];

            if (!this.invoiceId && this.dialogMode && this.dialogInvoiceId) {
                this.invoiceId = this.dialogInvoiceId;
            }

            if (this.invoiceId) {
                this.invoicesService
                    .getInvoiceForEditById(this.invoiceId)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((invoice) => {
                        if (invoice) {
                            if (
                                invoice &&
                                ['draft', 'need_approve', 'approved'].includes(invoice.status_of_invoice as string) &&
                                (invoice.creator__company_fk_id === this.currentCompanyId ||
                                    invoice.owner__company_fk_id === this.currentCompanyId)
                            ) {
                                this.invoice = invoice;
                                this.defineEditType();

                                this.invoiceDateControl.patchValue(invoice.invoice_date);

                                if (this.invoice && this.invoice.invoice_id) {
                                    this.tagsService
                                        .getInvoiceTagsIds(this.invoice.invoice_id)
                                        .pipe(takeUntil(this.unsubscribe))
                                        .subscribe((ids) => {
                                            this.selectedTagsIds = ids.result;
                                            this.disableTags = true;
                                        });
                                }
                            } else {
                                this.ntfs.warn(
                                    `Edit Invoice with status ${invoice.status_of_invoice} is not available.`
                                );
                                this.router.navigate(['/sales/invoices/']);
                            }
                        } else {
                            this.ntfs.warn(`Invoice #: ${this.invoiceId} is not available.`);
                            this.router.navigate(['/sales/invoices/']);
                        }
                    });
            } else {
                if (this.currentTag && this.currentTag.tag_id) {
                    this.selectedTagsIds.push(this.currentTag.tag_id);
                }
            }
        });
    }

    defineEditType() {
        if (this.invoice && this.invoice.summary_status && this.invoice.status_of_payment) {
            if (['write_off', 'void', 'rejected'].includes(this.invoice.summary_status)) {
                this.editType = 'blocked';
            } else if (
                ['partially_paid', 'paid'].includes(this.invoice.summary_status) ||
                ['processing', 'pending', 'partially_paid', 'paid'].includes(this.invoice.status_of_payment) ||
                !!this.invoice.source__deal_fk_id
            ) {
                this.editType = 'restricted';
            } else if (['draft', 'open', 'sent', 'overdue'].includes(this.invoice.summary_status)) {
                this.editType = 'full';
            }
        }
        if (!this.invoice || !this.invoice.invoice_id) {
            this.editType = 'full';
        }
    }

    private validateRecurringInvoice() {
        if (!this.maybeValidInvoice.valid || !this.maybeValidInvoice.template) {
            this.ntfs.warn('Invoice is not valid');
            return false;
        }

        return true;
    }

    handleInvoiceTemplateUpdate(maybeValidInvoice: IMaybeValidInvoiceTemplate) {
        this.maybeValidInvoice = maybeValidInvoice;

        if (
            this.maybeValidInvoice &&
            this.maybeValidInvoice.template &&
            this.maybeValidInvoice.template.money_sender__contact_fk_id
        ) {
            if (
                this.currentContactId &&
                this.currentContactId === this.maybeValidInvoice.template.money_sender__contact_fk_id
            ) {
                return;
            } else {
                this.currentContactId = this.maybeValidInvoice.template.money_sender__contact_fk_id;
                this.calculateCreditsBalance();
            }
        }
    }

    isPaymentsCanceled(invoice: IInvoice): boolean {
        if (invoice.payments) {
            return invoice.payments.some((pay) => pay.status !== 'canceled');
        } else {
            return false;
        }
    }

    calculateCreditsBalance() {
        if (this.currentContactId) {
            this.transactionsService
                .getExcessContactTransactions(this.currentContactId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((excess) => {
                    this.creditsBalance = excess.credit_note + excess.payment_received;
                });
        }
    }

    add(event: MatChipInputEvent): void {
        if (this.matAutocomplete && !this.matAutocomplete.isOpen) {
            const input = event.input;
            const value = event.value;

            if ((value || '').trim()) {
                this.selectedTagsIds.push(value.trim());
            }
        }
    }

    selectedTag(event: MatAutocompleteSelectedEvent) {
        this.selectedTagsIds.push(event.option.value);
    }

    remove(tagId: number) {
        const index = this.selectedTagsIds.indexOf(tagId);

        if (index >= 0) {
            this.selectedTagsIds.splice(index, 1);
        }
    }

    createInvoice(creationType: 'send' | 'draft' | 'open') {
        if (!this.validateRecurringInvoice() || this.createDisabled || !this.maybeValidInvoice.template) {
            this.makeInvoiceFormTouch.next();
            return;
        }

        const newInvoice = this.maybeValidInvoice.template;

        newInvoice.invoice_date = this.invoiceDateControl.value;
        newInvoice.scenario = 'regular';
        if (newInvoice.items) {
            newInvoice.items = this._checkItemsConsistency(newInvoice.items);
        }

        let invoiceAssociationForCreation = null;
        let invoiceAssociationForDeletion = null;
        if (
            this.maybeValidInvoice.association &&
            this.maybeValidInvoice.association.to_create &&
            this.maybeValidInvoice.association.to_create.length > 0
        ) {
            invoiceAssociationForCreation = this.maybeValidInvoice.association.to_create
                .map((association) => {
                    association.amount = Number(cleanCurrencyString(association.amount as any));
                    return association;
                })
                .filter((association) => association.amount > 0);
        }
        if (
            this.maybeValidInvoice.association &&
            this.maybeValidInvoice.association.to_delete &&
            this.maybeValidInvoice.association.to_delete.length > 0
        ) {
            invoiceAssociationForDeletion = this.maybeValidInvoice.association.to_delete;
        }
        const requestObject: {
            invoice: IInvoice;
            invoice_association: {
                to_create: Array<{[key: string]: any}>;
                to_delete: Array<{[key: string]: any}>;
            } | null;
        } = {
            invoice: newInvoice,
            invoice_association: {
                to_create: invoiceAssociationForCreation as any,
                to_delete: invoiceAssociationForDeletion as any
            }
        };

        if (!this.invoice || !this.invoice.invoice_id) {
            this.createDisabled = true;
            switch (creationType) {
                case 'send': {
                    this.createSend(requestObject);
                    break;
                }
                case 'draft': {
                    this.createAsDraft(requestObject);
                    break;
                }
                case 'open': {
                    this.createAsOpen(requestObject);
                    break;
                }
            }
        }
    }

    createSend(requestObj: {
        invoice: IInvoice;
        invoice_association: {to_create: Array<{[key: string]: any}>; to_delete: Array<{[key: string]: any}>} | null;
    }) {
        this.invoicesService
            .createInvoiceAndSend(requestObj)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (result: IInvoice) => {
                    if (result) {
                        this._createConnectionWithTag(result);
                        if (this.chargeFromCredits && result.status_of_invoice !== 'draft') {
                            this.router.navigate([`/sales/invoices/${result.invoice_id}`], {
                                queryParams: {chargeFromCredits: true}
                            });
                        } else {
                            this.router.navigate(['/sales/invoices/']);
                        }
                    }
                },
                () => {
                    this.createDisabled = false;
                }
            );
    }

    createAsDraft(requestObj: {
        invoice: IInvoice;
        invoice_association: {to_create: Array<{[key: string]: any}>; to_delete: Array<{[key: string]: any}>} | null;
    }) {
        this.invoicesService
            .createInvoiceAsDraft(requestObj)
            .pipe(
                catchError(() => {
                    this.createDisabled = false;
                    return of(null);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result) {
                    this._createConnectionWithTag(result);
                }
                this.router.navigate(['/sales/invoices/']);
            });
    }

    createAsOpen(requestObj: {
        invoice: IInvoice;
        invoice_association: {to_create: Array<{[key: string]: any}>; to_delete: Array<{[key: string]: any}>} | null;
    }) {
        this.invoicesService
            .createInvoiceAsOpen(requestObj)
            .pipe(
                catchError(() => {
                    this.createDisabled = false;
                    return of(null);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result) {
                    this._createConnectionWithTag(result);
                    if (this.chargeFromCredits && result.status_of_invoice !== 'draft') {
                        this.router.navigate([`/sales/invoices/${result.invoice_id}`], {
                            queryParams: {chargeFromCredits: true}
                        });
                    } else {
                        this.router.navigate(['/sales/invoices/']);
                    }
                }
            });
    }

    updateInvoice() {
        if (!this.validateRecurringInvoice() || this.createDisabled || !this.maybeValidInvoice.template) {
            return;
        }

        const invoiceForUpdate = this.maybeValidInvoice.template;

        if (
            this.invoice &&
            invoiceForUpdate.total_amount &&
            this.invoice.total_amount !== invoiceForUpdate.total_amount &&
            this.invoice.status_of_payment !== 'unpaid'
        ) {
            this.ntfs.warn(`Amount of Invoice with payments cannot be changed.`);
            return;
        }

        invoiceForUpdate.invoice_date = this.invoiceDateControl.value;

        if (this.invoice && this.invoice.invoice_id && !this.updateDisabled) {
            this.updateDisabled = true;

            let invoiceAssociationForCreation = null;
            let invoiceAssociationForDeletion = null;
            if (
                this.maybeValidInvoice.association &&
                this.maybeValidInvoice.association.to_create &&
                this.maybeValidInvoice.association.to_create.length > 0
            ) {
                invoiceAssociationForCreation = this.maybeValidInvoice.association.to_create
                    .map((association) => {
                        association.amount = Number(cleanCurrencyString(association.amount as any));
                        return association;
                    })
                    .filter((association) => association.amount > 0);
            }
            if (
                this.maybeValidInvoice.association &&
                this.maybeValidInvoice.association.to_delete &&
                this.maybeValidInvoice.association.to_delete.length > 0
            ) {
                invoiceAssociationForDeletion = this.maybeValidInvoice.association.to_delete;
            }
            const requestObject: {
                invoice: IInvoice;
                invoice_association: {
                    to_create: Array<{[key: string]: any}>;
                    to_delete: Array<{[key: string]: any}>;
                } | null;
                edit_type: 'full' | 'restricted' | 'blocked';
            } = {
                invoice: invoiceForUpdate,
                invoice_association: {
                    to_create: invoiceAssociationForCreation as any,
                    to_delete: invoiceAssociationForDeletion as any
                },
                edit_type: this.editType
            };
            this.invoicesService
                .updateInvoice(this.invoice.invoice_id, requestObject)
                .pipe(
                    catchError(() => {
                        this.updateDisabled = false;
                        return of(null);
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((result) => {
                    if (result) {
                        this.ntfs.success('Invoice successfully updated');
                    }
                    if (!this.dialogMode) {
                        this.router.navigate(['/sales/invoices/']);
                    } else {
                        this.invoiceUpdatedInDialog.emit();
                    }
                });
        }
    }

    disconnectInvoice() {
        if (this.invoice && this.invoice.invoice_id && !this.deleteDisabled) {
            this.deleteDisabled = true;

            this.invoicesService
                .disconnectInvoice(this.invoice.invoice_id)
                .pipe(
                    catchError(() => {
                        this.deleteDisabled = false;
                        return of(null);
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((result) => {
                    if (result) {
                        this.ntfs.success('Invoice successfully disconnected');
                    }
                    this.router.navigate(['/sales/invoices/']);
                });
        }
    }

    deleteInvoice() {
        if (this.invoice && this.invoice.invoice_id && !this.deleteDisabled) {
            this.deleteDisabled = true;

            this.invoicesService
                .deleteInvoice(this.invoice.invoice_id)
                .pipe(
                    catchError(() => {
                        this.deleteDisabled = false;
                        return of(null);
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((result) => {
                    if (result) {
                        this.ntfs.success('Invoice successfully deleted');
                    }
                    this.router.navigate(['/sales/invoices/']);
                });
        }
    }

    async _createConnectionWithTag(invoice: IInvoice) {
        if (invoice.invoice_id) {
            await this.tagsService.createTagInvoiceRelation(this.selectedTagsIds, invoice.invoice_id).toPromise();
            this.ntfs.success('Invoice successfully created');
        }
    }
    _checkItemsConsistency(items: IInvoiceItem[]) {
        if (items && items.length > 0) {
            for (const item of items) {
                if (!item.product_fk_id) {
                    item.product_fk_id = null;
                }
            }
        }

        return items;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.invoiceUpdatedInDialog.complete();
        this.makeInvoiceFormTouch.complete();
    }
}
