import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSidenav} from '@angular/material/sidenav';
import {of, Subject} from 'rxjs';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {GroupApi} from 'app/services/api/group.api';
import {catchError, takeUntil} from 'rxjs/operators';
import {ITransactionExternal, ITransactionListItem} from '@cyberco-nodejs/zipi-typings';
import {BankingService} from 'app/services/api/finance/banking.service';
import {ExpensesService} from '../../../../finance/services/expenses.service';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {TRANSACTION_TYPES} from 'app/local-typings';
import {PaymentsMadeService} from '../../../../finance/services/payments-made.service';
import {PaymentsReceivedService} from '../../../../finance/services/payments-received.service';

@Component({
    selector: 'app-banking-sidebar',
    templateUrl: 'banking-sidebar.component.html',
    styleUrls: ['banking-sidebar.component.scss']
})
export class BankingSidebarComponent implements OnInit, OnDestroy {
    @ViewChild('bankingSidebar') bankingSidebar: MatSidenav | undefined;
    @Input() isOpened: boolean = false;
    @Input() ledgerAccountId: number | null = null;

    @Output() deselectTransaction: EventEmitter<void> = new EventEmitter();
    @Output() loadExternalTransactions: EventEmitter<void> = new EventEmitter();
    @Output() loadInternalTransactions: EventEmitter<void> = new EventEmitter();

    public bankExternalTransactions: ITransactionExternal[] | null = null;
    public internalTransaction: ITransactionListItem | null = null;
    public transactionType: any;
    public createDisabled: boolean = false;

    private unsubscribe: Subject<void> = new Subject();
    categoryControl: UntypedFormControl = this.fb.control(null);
    transactionTypes = TRANSACTION_TYPES;

    bankExternalTransaction: ITransactionExternal | null = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder,
        public dialog: MatDialog,
        protected groupsService: GroupApi,
        private bankingService: BankingService,
        private expensesService: ExpensesService,
        private paymentsMadeService: PaymentsMadeService,
        private paymentsReceivedService: PaymentsReceivedService
    ) {}

    ngOnInit() {
        this.initSubscribe();
    }

    initSubscribe() {
        if (!!this.bankingSidebar) {
            this.bankingSidebar.opened = this.isOpened;
        }

        this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (typeof this.bankingSidebar !== 'undefined' && this.bankingSidebar.opened) {
                    this.close();
                }
            }
        });
    }

    close(load?: boolean | void) {
        if (load) {
            this.loadInternalTransactions.emit();
            this.loadExternalTransactions.emit();
        }
        this.deselectTransaction.emit();
        this.bankExternalTransactions = [];
        this.categoryControl.setValue(null);
        if (typeof this.bankingSidebar !== 'undefined') {
            this.bankingSidebar.close();
        }
    }

    openMatchingSidebar(bt: ITransactionExternal[]) {
        this.transactionType = null;
        this.internalTransaction = null;
        this.bankExternalTransactions = bt.slice();
        if (bt.length === 1) {
            this.bankExternalTransaction = bt[0];
        } else {
            this.bankExternalTransaction = null;
            this.categoryControl.setValue(null);
        }
        if (typeof this.bankingSidebar !== 'undefined') {
            this.bankingSidebar.open();
        }
    }

    openInternalTransactionSidebar(t: ITransactionListItem) {
        this.categoryControl.setValue(null);

        this.transactionType = null;
        this.internalTransaction = t;
        if (typeof this.bankingSidebar !== 'undefined') {
            this.bankingSidebar.open();
        }
    }

    openAddTransactionSidebar(transactionType: {value: string; label: string}) {
        this.categoryControl.setValue(null);

        this.internalTransaction = null;
        this.transactionType = transactionType;
        if (typeof this.bankingSidebar !== 'undefined') {
            this.bankingSidebar.open();
        }
    }

    /**
     * Deprecated
     * @param data
     */
    addTransaction(data: object) {
        this.createDisabled = true;

        const transactionType = this.transactionType.value;

        switch (transactionType) {
            case 'expense':
                this.expensesService
                    .createExpense({
                        expense: data,
                        vendor_credit: null
                    })
                    .pipe(
                        catchError(() => {
                            this.createDisabled = false;
                            return of(null);
                        }),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe(() => {
                        this.close(true);
                    });

                break;
            case 'vendor_payment':
                this.paymentsMadeService
                    .createPaymentMade(data)
                    .pipe(
                        catchError(() => {
                            this.createDisabled = false;
                            return of(null);
                        }),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe(() => {
                        this.close(true);
                    });

                break;
            case 'customer_payment':
                this.paymentsReceivedService
                    .createPaymentReceived(data)
                    .pipe(
                        catchError(() => {
                            this.createDisabled = false;
                            return of(null);
                        }),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe(() => {
                        this.close(true);
                    });

                break;
            default:
                this.bankingService
                    .createAdditionalTransaction({
                        transaction: data,
                        type: transactionType
                    })
                    .pipe(
                        catchError(() => {
                            this.createDisabled = false;
                            return of(null);
                        }),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe(() => {
                        this.close(true);
                    });
        }
    }

    manualMatch(manualTransaction: object) {
        this.createDisabled = true;

        const transactionType = this.categoryControl.value;
        if (this.bankExternalTransactions && this.bankExternalTransactions.length > 0) {
            const parentExternalTransactionId = this.bankExternalTransactions[0].transaction_external_id;

            switch (transactionType.value) {
                case 'vendor_payment':
                    this.paymentsMadeService
                        .createPaymentMade({
                            ...manualTransaction,
                            matched__transaction_external_id: parentExternalTransactionId,
                            external_transactions: this.bankExternalTransactions
                        })
                        .pipe(
                            catchError(() => {
                                this.createDisabled = false;
                                return of(null);
                            }),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe(() => {
                            this.loadExternalTransactions.emit();
                            this.createDisabled = false;
                            this.close(true);
                        });

                    break;
                case 'customer_payment':
                    this.paymentsReceivedService
                        .createPaymentReceived({
                            ...manualTransaction,
                            matched__transaction_external_id: parentExternalTransactionId,
                            external_transactions: this.bankExternalTransactions
                        })
                        .pipe(
                            catchError(() => {
                                this.createDisabled = false;
                                return of(null);
                            }),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe(() => {
                            this.loadExternalTransactions.emit();
                            this.createDisabled = false;
                            this.close(true);
                        });

                    break;
                default:
                    this.bankingService
                        .createMatchedTransaction(parentExternalTransactionId, {
                            transaction: manualTransaction,
                            type: transactionType.value,
                            external_transactions: this.bankExternalTransactions
                        })
                        .pipe(
                            catchError(() => {
                                this.createDisabled = false;
                                return of(null);
                            }),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe(() => {
                            this.loadExternalTransactions.emit();
                            this.createDisabled = false;
                            this.close(true);
                        });
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.deselectTransaction.complete();
        this.loadExternalTransactions.complete();
        this.loadInternalTransactions.complete();
    }
}
