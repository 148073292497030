<div class="col" style="justify-content: space-between; flex-direction: column; display: flex" [formGroup]="formGroup">
    <h3>Did your check printed correctly?</h3>

    <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="example-radio-group"
        [formControl]="isCorrectPrintControl"
    >
        <mat-radio-button class="w-100" [value]="false">
            No, keep check with to-be printed check status
        </mat-radio-button>
        <mat-radio-button class="w-100" [value]="true"> Yes </mat-radio-button>
    </mat-radio-group>

    <mat-form-field class="w-100 date-field">
        <input
            matInput
            type="text"
            required
            placeholder="Check Number"
            autocomplete="off"
            [formControl]="checkNumberControl"
        />
    </mat-form-field>

    <div class="row footer">
        <button mat-raised-button class="mat-primary" (click)="save()" [disabled]="saveDisabled">OK</button>
        <button mat-raised-button class="mat-default" (click)="close()">Cancel</button>
    </div>
</div>
