import {Profile} from './profile';

export class CommissionInstruction {
    id: number | null = null;
    created_at: string | null = null;
    text: string | null = null;
    profile_id: number | null = null;
    profile?: Profile = new Profile();

    constructor(profile?: Profile) {
        if (profile) {
            this.profile = profile;
        }
    }

    public setProfile(profile: Profile): CommissionInstruction {
        this.profile = profile;
        return this;
    }

    public setText(text: string): CommissionInstruction {
        this.text = text;
        return this;
    }

    public serialize(): CommissionInstruction {
        const instance = Object.assign(new CommissionInstruction(), this);
        if (this.profile) {
            instance.profile_id = this.profile.id;
        }

        delete instance.profile;

        if (this.profile) {
            instance.profile = <Profile>{
                first_name: this.profile.first_name,
                last_name: this.profile.last_name
            };
        }
        return instance;
    }
}
