<section class="goals-wrapper">
    <app-goals-create-bar #goalsCreate [isOpened]="isSidebarOpened" class="goals-bar">
        <!------- Filters ------->
        <div class="buttons d-flex align-items-center justify-content-between flex-wrap p-3">
            <!------- Button board list ------->
            <h3
                class="mb-0 d-flex align-items-end action-icon"
                style="font-size: 1.3em; margin-top: -3px"
                *ngIf="focusAreas.length"
                [matMenuTriggerFor]="menuTask"
            >
                <span>
                    {{
                        focusAreas[activeTabIndex].title === 'Personal'
                            ? sessionService?.user?.first_name
                            : focusAreas[activeTabIndex].title
                    }}
                </span>
                <span class="material-icons d-flex align-self-center">arrow_drop_down</span>
            </h3>
            <mat-menu #menuTask="matMenu">
                <button
                    mat-menu-item
                    class="d-flex align-items-center justify-content-between board-item"
                    (click)="activeTabChanged(idx)"
                    *ngFor="let board of focusAreas; let idx = index"
                >
                    {{ board.title === 'Personal' ? sessionService?.user?.first_name : board.title }}
                    <span
                        class="material-icons ml-3 default-board-icon"
                        [ngStyle]="
                            (defaultBoardIndex | isNumber) && defaultBoardIndex === idx
                                ? {visibility: 'visible', color: '#444'}
                                : null
                        "
                        (click)="$event.stopPropagation(); setBoardAsDefault(board.id)"
                    >
                        grade
                    </span>
                </button>
            </mat-menu>
            <!------- Button Manage ------->
            <button class="mat-primary mr-1" type="button" mat-raised-button (click)="goalsCreate.openManageSidebar()">
                Manage Goals
            </button>
        </div>

        <!------- Goal view ------->
        <app-goals #goals *ngIf="focusAreas.length" [focusArea]="focusAreas[activeTabIndex]"></app-goals>
    </app-goals-create-bar>
</section>
