<div
    class="affected-by-container"
    *ngIf="dealFormGroup?.controls?.affected_by?.getRawValue()?.length && (!isAllDismissed || showDismissWarnings)"
>
    <div class="warning-block">
        <span><strong>Warning!</strong> This deal is out of date. It was affected by previous deals</span>
        <span class="show-details" *ngIf="!isAllDismissed && !showDetails" (click)="showDetails = true; getBaseDeals()"
            >Show Details</span
        >
        <span class="show-details" *ngIf="!isAllDismissed && showDetails" (click)="showDetails = false"
            >Hide Details</span
        >
    </div>

    <div class="details" *ngIf="(showDetails && isBaseDealsLoaded) || isAllDismissed">
        <div class="details-title">Actions that affects this deal:</div>

        <div *ngFor="let affectedByAction of affectedByActions; let i = index">
            <ng-container *ngIf="!affectedByAction.is_dismissed">
                {{ moment(affectedByAction.affected_at).format('MM/DD/YYYY h:mma') }} -
                <span *ngIf="affectedByAction.entity_type">{{ affectedByAction.entity_type | titlecase }}</span
                >&nbsp;
                <a [routerLink]="['/deals', affectedByAction.entity_id]" target="_blank">{{
                    affectedByAction.entity_name
                }}</a>
                was
                <span *ngIf="affectedByAction.action">{{ action_types_LABELS[affectedByAction.action] }}</span>
            </ng-container>
        </div>

        <div
            class="dismissed show-dismissed"
            *ngIf="!isAllDismissed && !showDismissed && hasDismissed"
            (click)="showDismissed = !showDismissed"
        >
            Show Dismissed Warnings
        </div>

        <div
            class="dismissed hide-dismissed"
            *ngIf="!isAllDismissed && showDismissed && hasDismissed"
            (click)="showDismissed = !showDismissed"
        >
            Hide Dismissed Warnings
        </div>

        <ng-container *ngIf="isAllDismissed || showDismissed">
            <div *ngFor="let affectedByAction of affectedByActions; let i = index">
                <ng-container *ngIf="affectedByAction.is_dismissed">
                    {{ moment(affectedByAction.affected_at).format('MM/DD/YYYY h:mma') }} -
                    <span *ngIf="affectedByAction.entity_type">{{ affectedByAction.entity_type | titlecase }}</span
                    >&nbsp;
                    <a [routerLink]="['/deals', affectedByAction.entity_id]" target="_blank">{{
                        affectedByAction.entity_name
                    }}</a>
                    was
                    <span *ngIf="affectedByAction.action">{{ action_types_LABELS[affectedByAction.action] }}</span>
                </ng-container>
            </div>
        </ng-container>

        <div class="dismiss" *ngIf="!isAllDismissed" (click)="dismissWarnings()">Dismiss all Warnings</div>
    </div>
</div>
