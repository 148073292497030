<div id="dialog" class="set-dual-container">
    <div md-dialog-title class="header">Create Dual Relationship</div>

    <mat-card-content>
        <div class="description-text">
            Once this deal has been saved, the system will create a new corresponding deal. These two deals will be
            linked through the dual relationship functionality.
        </div>

        <label> Please, select with what deal you want to create dual relationship: </label>

        <mat-radio-group class="plan-radio-group" [(ngModel)]="creationMode">
            <div style="display: flex; flex-direction: column">
                <mat-radio-button class="plan-radio-button" value="new" [disabled]="!type || !oppositeType">
                    Create New Deal
                </mat-radio-button>
                <label *ngIf="creationMode === 'new'" style="color: darkgray"
                    >(all unsaved changes will not be applied to the new deal)</label
                >
            </div>

            <div>
                <mat-radio-button class="plan-radio-button" value="existed" [disabled]="!type || !oppositeType">
                    Select existing Deal
                </mat-radio-button>

                <app-deal-picker
                    [placeholder]="'Search Deal'"
                    [dealControl]="dealFormControl"
                    [isNeedRestrictWidth]="true"
                    *ngIf="creationMode === 'existed' && currentDealId"
                    [disableByTypes]="disallowedDealTypes"
                    [excludeDealsIds]="[currentDealId]"
                ></app-deal-picker>
            </div>

            <div
                *ngFor="let addonOption of addonsOptions"
                style="display: inline-block"
                [matTooltip]="
                    addonOption.disabled
                        ? 'You do not have permission to edit this deal'
                        : 'You will be redirected to addon page'
                "
            >
                <!-- if this user does not have permissions to edit this deal this option should be disabled -->
                <mat-radio-button
                    [disabled]="addonOption.disabled || !type || !oppositeType"
                    class="plan-radio-button"
                    [value]="addonOption.link"
                >
                    Select from {{ addonOption.label }}
                </mat-radio-button>
            </div>
        </mat-radio-group>

        <label *ngIf="!type" style="color: red; font-style: italic">
            You can not create a dual deal relationship, because the current deal type is empty.
        </label>

        <label *ngIf="!oppositeType && type" style="color: red; font-style: italic">
            You can not create a dual deal relationship, because the Deal type '{{ DEAL.type_LABELS[type] }}' does not
            have opposite value
        </label>
    </mat-card-content>

    <div md-dialog-actions class="footer">
        <button mat-raised-button class="mat-primary" (click)="save()" [disabled]="!type || !oppositeType">
            Continue
        </button>
        <button mat-raised-button class="mat-default" (click)="dialogRef.close()">Cancel</button>
    </div>
</div>
