import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {GenericFormArray, GenericFormGroup} from '../../../../entites/generic.entity';
import {ChipNode} from '../../../account-info/compensation/models/chip-node';
import {CompensationProfileFilter} from '../../../deals/components/deal/common/deal.models';
import {SessionService} from '../../../../services/session.service';
import {GroupsSource} from '../../../../services/sources/groups.source';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Group} from '../../../../models/group';

@Component({
    selector: 'app-deal-filter',
    templateUrl: './deal-filter.component.html',
    styleUrls: ['./deal-filter.component.scss']
})
export class DealFilterComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() dealFilter: CompensationProfileFilter | null = null;
    @Input() control: UntypedFormControl | null = null;
    @Input() advancedFilterFormGroup: GenericFormGroup<any> | null = null;
    @Input() nodesFA: GenericFormArray<ChipNode> | null = null;
    @Input() showRemoveButton: boolean = true;
    @Output() removeFilter = new EventEmitter();

    divisionDropdownOptions: {
        title: string;
        value: number;
    }[] = [
        {
            title: 'Privet',
            value: 1
        },
        {
            title: 'Kharasho',
            value: 2
        }
    ];
    tagsDropdownOptions: {
        title: string;
        value: any;
        deactivated: boolean;
    }[] = [
        {
            title: 'Privet TAG',
            value: 1,
            deactivated: false
        },
        {
            title: 'Kharasho TAG',
            value: 2,
            deactivated: false
        }
    ];

    constructor(
        protected sessionService: SessionService,
        protected groupsSource: GroupsSource
    ) {
        this.tagsDropdownOptions = this.sessionService.companyTags.map((tag) => {
            return {
                title: tag.title,
                value: tag.tag_id,
                deactivated: tag.deactivated
            };
        });
    }

    ngOnInit() {
        this.groupsSource.source.pipe(takeUntil(this.unsubscribe)).subscribe((groupList) => {
            this.divisionDropdownOptions =
                groupList
                    .filter((group) => group.type === Group.type_SET.division)
                    .map((division) => {
                        return {
                            title: division.title as string,
                            value: division.id as number
                        };
                    }) || [];
        });
    }

    changeElementDate(event: any) {
        this.control!.patchValue(event.value.toISOString());
    }

    unselectDeactivatedTag(tag: {title: string; value: any; deactivated: boolean}) {
        // if tag exists in the selected list and is deactivated
        if (!this.control?.value.find((tagId: any) => tagId === tag.value && tag.deactivated)) {
            return;
        }

        // update selected tags
        this.control?.patchValue(this.control?.value.filter((tagId: any) => tagId !== tag.value) || []);
    }

    ngOnDestroy() {
        this.removeFilter.complete();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
