import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-purchases-page',
    templateUrl: 'purchases-page.component.html',
    styleUrls: ['purchases-page.component.css']
})
export class PurchasesPageComponent implements OnInit {
    // links = [
    //     {path: '/purchases/bills', tooltip: '', label: 'Bills', matchForActive: 'bills'},
    //     {path: '/purchases/sourcedocuments', tooltip: 'These are Invoices from Vendors that can be connected to a Bill.', label: 'Source Documents', matchForActive: 'sourcedocuments'},
    //
    // ];

    constructor(public router: Router) {}

    ngOnInit() {}
}
