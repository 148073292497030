import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from '../../../../../../../../services/auth.service';
import {Router} from '@angular/router';
import {SessionService} from '../../../../../../../../services/session.service';
import {UserService} from '../../../../../../../../services/user.service';
import {LogoutAndGoToInvoiceAction} from '../../../../../../../contact-invites/store/contact-invites.actions';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {IContactInvitesState} from '../../../../../../../contact-invites/store/contact-invites.reducers';
import {ICompany, IProfile, IContact, IDealDepositRequest} from '@cyberco-nodejs/zipi-typings';
import {RbacService} from '../../../../../../../rbac/rbac.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-enter-to-portal',
    templateUrl: './deposit-enter-to-portal.component.html',
    styleUrls: ['./deposit-enter-to-portal.component.scss']
})
export class DepositEnterToPortalComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    companies: ICompany[] = [];
    currentContact: IContact | null = null;
    currentProfile: IProfile | null = null;
    depositRequest: IDealDepositRequest | null = null;
    depositRequestHash: string | null = null;
    public userFinancialCompanyId: boolean | number = false;

    constructor(
        protected authService: AuthService,
        private router: Router,
        public sessionService: SessionService,
        protected usersService: UserService,
        protected store: Store<IContactInvitesState>,
        public dialogRef: MatDialogRef<DepositEnterToPortalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        protected rbacService: RbacService
    ) {}

    ngOnInit() {
        this.companies = this.data.companies;
        this.currentContact = this.data.currentContact;
        this.currentProfile = this.data.currentProfile;
        this.depositRequest = this.data.depositRequest;
        this.depositRequestHash = this.data.depositRequestHash;

        if (this.companies.length) {
            // get user id
            let userId: boolean | number = false;
            this.companies.forEach((company: ICompany) => {
                if (company.profiles) {
                    company.profiles.forEach((profile: IProfile) => {
                        if (profile.user_fk_id) {
                            userId = profile.user_fk_id;
                        }
                    });
                }
            });
            // set userFinancialCompanyId
            if (userId) {
                this.companies.forEach((company: ICompany) => {
                    if (company.company_id && company.finance_for__user_fk_id === userId) {
                        this.userFinancialCompanyId = company.company_id;
                    }
                });
            }
        }
    }

    isUserCanAssociateDepositRequestToCompany(company: ICompany, request: IDealDepositRequest) {
        const profile = _.first(company.profiles);
        if (
            company &&
            company.id !== request.creator__company_fk_id &&
            profile &&
            (this.canConnectToOtherAccounts(profile, company) || this.isUserFinancialCompany(company))
        ) {
            return true;
        }
        return false;
    }

    canConnectToOtherAccounts(profile: IProfile, company: ICompany) {
        return this.rbacService.checkPermissions(profile.permissions, {
            company_settings__manage_connections_to_other_accounts: true
        });
    }

    isUserFinancialCompany(company: ICompany) {
        return this.userFinancialCompanyId === company.company_id;
    }

    registration() {
        this.logoutAndGoTo(
            ['auth/sign-up-contact-deposit-request', this.depositRequestHash!],
            'Register using this email to continue accepting invitation'
        );
        this.close();
    }

    associate(company: ICompany) {
        this.usersService
            .afterRegisterContactDepositRequest(this.depositRequestHash!, this.currentProfile!.user, company)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                localStorage.setItem('pw_sidenav_state', 'opened');
                window.location.replace(`/escrowdeposit/${this.depositRequest!.deposit_request_id}`);
                this.close();
            });
    }

    async loginAssociate(company: ICompany) {
        if (!company || !company.company_id) {
            return null;
        }
        await this.authService.logout();
        this.sessionService.setRedirectParams(
            [`/viewdepositrequest/${this.depositRequestHash}`],
            this.depositRequest!.request_receiver_email,
            ''
        );
        localStorage.setItem('desired_company_id', company.company_id + '');
        this.router.navigate([`auth/sign-in-contact-invoice`]);
        this.close();
    }

    async logoutAndGoTo(goTo: string[], authInfoMessage?: string) {
        return this.store.dispatch(new LogoutAndGoToInvoiceAction({goTo, authInfoMessage}));
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    /*
    Probably it's legacy and need to remove
     */
    async login(company: ICompany) {
        await this.authService.logout();

        localStorage.setItem('zipi_actual_profile_id', String(company.profiles![0].profile_id));

        this.sessionService.setRedirectParams(
            [`/escrowdeposit/${this.depositRequest!.deposit_request_id}`],
            this.depositRequest!.request_receiver_email,
            ''
        );
        this.router.navigate([`auth/sign-in-contact-invoice`]);
        this.close();
    }
}
