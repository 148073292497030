import {
    Component,
    Input,
    EventEmitter,
    Output,
    OnDestroy,
    ViewChild,
    ElementRef,
    AfterViewChecked,
    OnInit,
    HostListener,
    ChangeDetectorRef
} from '@angular/core';
import {DealsBoard} from '../../../common/deal.models';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SessionService} from '../../../../../../../services/session.service';
import {ProfilesService} from '../../../../../../../services/profiles.service';
import {DealService} from '../../../../../../../services/deal.service';

@Component({
    selector: 'app-deal-board',
    styles: [
        `
            .toolbar-card {
                z-index: 1;
                border-radius: 0;
                box-shadow: none;
            }
            .head-card {
                border-bottom: 1px lightgrey solid;
            }
        `
    ],
    template: `
        <mat-card class="p-0 toolbar-card">
            <div class="head-card" #header>
                <app-deal-board-view
                    [isCustomizeMode]="isCustomizeMode"
                    [boardsList]="boardsList"
                    [activeTabIndex]="activeTabIndex"
                    [activeTabIndex$]="activeTabIndex$"
                    [boardMeta]="boardMeta"
                    [defaultBoardIndex]="defaultBoardIndex"
                    [dealsBoard]="dealsBoard"
                    [offsetWidth]="offsetWidth"
                    [selectedDeal]="selectedDeal"
                    (edit)="isCustomizeMode = true"
                    (frontendFilterChanged)="frontendFilterChanged.emit($event)"
                    (activeTabIndexChanged)="dealsBoardChanged.emit($event); dealsBoard = $event"
                    (createBoardModeEmitter)="createBoardModeEmitter.emit($event)"
                    (savedActiveTabEmitter)="savedActiveTabEmitter.emit($event)"
                    (setBoardAsDefaultEmitter)="setBoardAsDefault($event)"
                ></app-deal-board-view>

                <app-deal-board-edit
                    *ngIf="!selectedDeal"
                    [isCustomizeMode]="isCustomizeMode"
                    [dealsBoard]="dealsBoard"
                    (view)="isCustomizeMode = false"
                    (dealsBoardRemoved)="onDealsBoardRemoved($event)"
                    (dealsBoardUpdated)="dealsBoardUpdated.emit($event)"
                >
                </app-deal-board-edit>
            </div>
        </mat-card>
    `
})
export class DealBoardComponent implements OnInit, OnDestroy, AfterViewChecked {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('header', {static: true}) header: ElementRef | undefined = undefined;

    offsetHeight = 0;
    offsetWidth = 0;

    @Input() dealsBoard: DealsBoard | undefined = undefined;
    @Input() boardsList: DealsBoard[] = [];
    @Input() activeTabIndex: number = 0;
    @Input() selectedDeal: boolean = false;
    @Input() boardMeta: {[key: string]: number} = {};
    @Input() defaultBoardIndex: number | undefined = undefined;

    @Output() dealsBoardUpdated = new EventEmitter();
    @Output() dealsBoardRemoved = new EventEmitter();
    @Output() dealsBoardChanged = new EventEmitter();
    @Output() frontendFilterChanged = new EventEmitter();
    @Output() createBoardModeEmitter = new EventEmitter();
    @Output() savedActiveTabEmitter = new EventEmitter();
    @Output() offsetHeightEmitter = new EventEmitter();

    public isCustomizeMode: boolean = false;
    activeTabIndex$: Subject<number> = new Subject<number>();

    // set css top style for second sticky menu if page had resized
    @HostListener('window:resize', ['$event'])
    onResize() {
        if (typeof this.header !== 'undefined') {
            this.offsetWidth = this.header.nativeElement.offsetWidth;
        }
    }

    constructor(
        private changeDetector: ChangeDetectorRef,
        private sessionService: SessionService,
        private profilesService: ProfilesService,
        public dealService: DealService
    ) {}

    ngOnInit() {}

    async onDealsBoardRemoved(board: DealsBoard) {
        // update list and board
        this.boardsList = this.boardsList.filter((b) => b.id !== board.id);
        if (this.boardsList.length === 0) {
            this.boardsList = await this.dealService.getDealsBoardsList();
        }
        this.dealsBoard = this.boardsList[this.activeTabIndex];

        // set default tab as active
        if (
            this.defaultBoardIndex !== this.boardsList.findIndex((b) => b.id === board.id) &&
            this.boardsList.find((b) => b.default as boolean)
        ) {
            this.defaultBoardIndex = this.boardsList.findIndex((b) => b.default as boolean);
            this.activeTabIndex = this.defaultBoardIndex;
        } else {
            this.activeTabIndex = 0;
        }
        this.activeTabIndex$.next(this.activeTabIndex);

        this.dealsBoardChanged.emit(this.dealsBoard);
        this.dealsBoardRemoved.emit(this.boardsList);
        this.isCustomizeMode = false;
    }

    setBoardAsDefault(boardId: number) {
        // check if selected default deal board is the same
        if (
            this.sessionService.profile!.settings &&
            this.sessionService.profile!.settings.deal_boards_settings &&
            this.sessionService.profile!.settings.deal_boards_settings.default_board_id &&
            this.sessionService.profile!.settings.deal_boards_settings.default_board_id === boardId
        ) {
            return;
        }

        const settings = Object.assign({}, this.sessionService.profile!.settings, {
            deal_boards_settings: {default_board_id: boardId}
        });

        // update profile in DB
        if (this.sessionService.profile!.id) {
            this.profilesService
                .updateProfileSettings(this.sessionService.profile!.id, settings)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    this.defaultBoardIndex = this.boardsList.findIndex((board) => board.id === boardId);
                    // update current profile
                    this.sessionService.setSettings = {
                        deal_boards_settings: {
                            default_board_id: this.boardsList[this.defaultBoardIndex].id
                        }
                    };
                });
        }
    }

    ngAfterViewChecked() {
        if (
            this.header &&
            this.header.nativeElement.offsetHeight &&
            this.offsetHeight !== this.header.nativeElement.offsetHeight
        ) {
            this.offsetHeight = this.header.nativeElement.offsetHeight;
            this.offsetHeightEmitter.emit(this.offsetHeight);
        }
        if (
            this.header &&
            this.header.nativeElement.offsetWidth &&
            this.offsetWidth !== this.header.nativeElement.offsetWidth
        ) {
            this.offsetWidth = this.header.nativeElement.offsetWidth;
            this.changeDetector.detectChanges();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.dealsBoardUpdated.complete();
        this.dealsBoardRemoved.complete();
        this.dealsBoardChanged.complete();
        this.frontendFilterChanged.complete();
        this.createBoardModeEmitter.complete();
        this.offsetHeightEmitter.complete();
        this.savedActiveTabEmitter.complete();
        this.activeTabIndex$.complete();
    }
}
