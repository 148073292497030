import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ServiceRequester} from '../../../services/service.requester';

@Injectable()
export class UploadService {
    url = '/uploads';

    constructor(public requester: ServiceRequester) {}

    uploadDeals(data: {path: string; name: string}): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/deal`, 'POST', 'zipi', data);
    }

    uploadInvoice(data: {path: string; name: string}): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/invoice`, 'POST', 'zipi', data);
    }

    uploadAccount(data: {path: string; name: string}): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/ledger-account`, 'POST', 'zipi', data);
    }

    getUploadList(): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/`, 'GET', 'zipi');
    }

    getBackgroundTasksList(): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/background-tasks`, 'GET', 'zipi');
    }

    getUploadById(uploadId: number): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/${uploadId}`, 'GET', 'zipi');
    }

    createDealsFromImportData(uploadId: number): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/deal/${uploadId}/create`, 'POST', 'zipi');
    }

    deleteImportedDealData(uploadId: number): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/deal/${uploadId}`, 'DELETE', 'zipi');
    }

    createInvoicesFromImportData(uploadId: number): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/invoice/${uploadId}/create`, 'POST', 'zipi');
    }

    deleteImportedInvoiceData(uploadId: number): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/invoice/${uploadId}`, 'DELETE', 'zipi');
    }

    exportProfiles(): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/export-data/profile`, 'POST', 'zipi');
    }

    exportProductsServices(): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/export-data/products-services`, 'POST', 'zipi');
    }

    async getLatestExportJournalsState(): Promise<any> {
        return await this.requester.makeMsCall(`${this.url}/export-data/journals/latest-state`, 'GET');
    }

    exportJournalsServices(data: {start_date: string; end_date: string; basis_type: string}): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/export-data/journals`, 'POST', 'zipi', data);
    }

    createItemsFromImport(data: {path: string; name: string; type: string}): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/import-data/create-items`, 'POST', 'zipi', data);
    }

    deleteImportedData(uploadId: number): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/import-data/${uploadId}`, 'DELETE', 'zipi');
    }

    async getFlatFileConfigData(portal_type: string): Promise<any> {
        return await this.requester.makeMsCall(`${this.url}/flatfile-portal-config/${portal_type}`, 'GET');
    }
}
