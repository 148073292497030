import {Component, OnInit, ChangeDetectorRef, Inject, Injectable, OnDestroy} from '@angular/core';
import {UserManager} from '@skyslope/auth-js';
import {SkyslopeAuth} from '../services/okta/skyslope-auth.service';
import {OktaAuthStateService, OKTA_AUTH} from '@okta/okta-angular';
import {AuthService} from 'app/services/auth.service';
import {Subject, takeUntil} from 'rxjs';
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {SessionService} from 'app/services/session.service';
import {UserService} from 'app/services/user.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-login',
    templateUrl: './sign-in-skyslope-conversion.component.html',
    styleUrls: ['./sign-in-skyslope-conversion.component.scss']
})
export class SignInSkyslopeConversionComponent implements OnInit, OnDestroy {
    title = 'Legacy Account Conversion';
    user: undefined;
    oktaSignIn: any;
    userManager: UserManager | undefined;
    private unsubscribe: Subject<void> = new Subject();
    private redirectParamUrl: string | null = null;
    isLoading: boolean = false;
    userEmail: string | null = null;

    constructor(
        private skyslopeAuth: SkyslopeAuth,
        private authStateService: OktaAuthStateService,
        private authService: AuthService,
        protected usersService: UserService,
        private activatedRoute: ActivatedRoute,
        public sessionService: SessionService,
        public dialog: MatDialog,
        public router: Router
    ) {}

    ngOnInit() {
        sessionStorage.removeItem('zohoInviteHash');
        sessionStorage.removeItem('inviteHash');
    }

    async showLogin(): Promise<void> {
        this.oktaSignIn = await this.skyslopeAuth.createWidget('okta-login-container', true);

        const email: string | null = this.userEmail;

        this.oktaSignIn.on('afterRender', async (context: {controller: string}) => {
            const isUserLoaded = await this.authService.loadAuth();
            if (isUserLoaded) {
                const firebaseUser = this.authService.currentFirebaseUser;
                if (firebaseUser) {
                    let field: any = document.getElementById('input80');
                    if (field) {
                        field.value = firebaseUser.email;
                        field.dispatchEvent(new Event('input', {bubbles: true}));
                        field.disabled = true;

                        await this.skyslopeAuth.closeSession();
                    }
                }
            }
        });
    }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.showLogin();
        }, 100);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
