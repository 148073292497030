import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

import {SharedModule} from '../shared/shared.module';
import {routing} from './finance.routing';

import {financeReducer} from './store/finance.reducer';
import {FinanceEffectsService} from './store/finance.effects';
import {FinancePageComponent} from './components/finance-page/finance-page.component';
import {InvoicesComponent} from './components/invoice/invoices/invoices.component';
import {RecurringInvoicesComponent} from './components/recurring-invoice/recurring-invoices/recurring-invoices.component';
import {EditRecurringInvoiceComponent} from './components/recurring-invoice/edit-recurring-invoice/edit-recurring-invoice.component';
import {InvoiceTemplateComponent} from './components/invoice/invoice-template/invoice-template.component';
import {ProductsPageComponent} from './components/products/products-page/products-page.component';
import {EditProductComponent} from './components/products/edit-product/edit-product.component';
import {LedgerAccountPageComponent} from './components/ledger-accounts/ledger-account-page/ledger-account-page.component';
import {EditLedgerComponent} from './components/ledger-accounts/edit-ledger-account/edit-ledger-account.component';
import {LedgerAccountTransactionsComponent} from './components/ledger-accounts/ledger-account-transations/ledger-account-transactions.component';
import {InvoicePublicPageComponent} from './components/invoice-public/invoice-public-page/invoice-public-page.component';
import {InvoicePublicService} from './services/invoice-public.service';
import {InvoicePageComponent} from './components/invoice/invoice-page/invoice-page.component';
import {BillsComponent} from './components/bills/billls/bills.component';
import {BillPageComponent} from './components/bills/bill-page/bill-page.component';
import {PurchasesPageComponent} from './components/purchases-page/purchases-page.component';
import {PaymentsReceivedListComponent} from './components/payments-received/payments-received-list/payments-received-list.component';
import {CreatePaymentReceivedComponent} from './components/payments-received/create-payment-received/create-payment-received.component';
import {CreatePaymentMadeComponent} from './components/payments-made/create-payment-made/create-payment-made.component';
import {PaymentsReceivedService} from './services/payments-received.service';
import {PaymentsMadeListComponent} from './components/payments-made/payments-made-list/payments-made-list.component';
import {EditInvoicePageComponent} from './components/invoice/edit-invoice-page/edit-invoice-page.component';
import {EditBillComponent} from './components/bills/edit-bill/edit-bill.component';
import {CreateBillConfirmComponent} from './components/bills/create-bill-confirm/create-bill-confirm.component';
import {ExpenseListComponent} from './components/expense/expense-list/expense-list.component';
import {ExpenseEditComponent} from './components/expense/expense-edit/expense-edit.component';
import {ExpensePageComponent} from './components/expense/expense-page/expense-page.component';
import {ExpensesService} from './services/expenses.service';
import {CreateProductDialogComponent} from './components/products/create-product-dialog/create-product-dialog.component';
import {EnterToPortalComponent} from './components/invoice-public/enter-to-portal/enter-to-portal.component';
// credits
import {ViewCreditNoteComponent} from './components/credit-notes/view-credit-note/view-credit-note.component';
import {CreditNotesComponent} from './components/credit-notes/credit-notes.component';
import {EditCreditNoteComponent} from './components/credit-notes/edit-credit-note/edit-credit-note.component';
import {SourceVendorCreditListComponent} from './components/credit-notes/source-vendor-credit-list/source-vendor-credit-list.component';
import {SourceVendorCreditComponent} from './components/credit-notes/source-vendor-credit/source-vendor-credit.component';
import {RecurringCreditNotesListComponent} from './components/recurring-credit-note/recurring-credit-notes-list/recurring-credit-notes-list.component';
import {EditRecurringCreditNoteComponent} from './components/recurring-credit-note/edit-recurring-credit-note/edit-recurring-credit-note.component';
import {CreditNoteTemplateComponent} from './components/recurring-credit-note/credit-note-template/credit-note-template.component';
import {VendorCreditsComponent} from './components/vendor-credits/vendor-credits.component';
import {EditVendorCreditComponent} from './components/vendor-credits/edit-vendor-credit/edit-vendor-credit.component';
import {ViewVendorCreditComponent} from './components/vendor-credits/view-vendor-credit/view-vendor-credit.component';
import {SourceCreditNotesListComponent} from './components/vendor-credits/source-credit-notes-list/source-credit-notes-list.component';
import {SourceCreditNoteComponent} from './components/vendor-credits/source-credit-note/source-credit-note.component';
import {CreditsService} from './services/credits.service';
import {CreditNotesService} from '../../services/api/finance/credit-notes.service';
import {RecurringCreditNotesService} from './services/recurring-credit-notes.service';
import {VendorCreditsService} from '../../services/api/finance/vendor-credits.service';

import {ManualMarkAsPaidConfirmDialogComponent} from './components/invoice/manual-mark-as-paid-confirm-dialog/manual-mark-as-paid-confirm-dialog.component';
import {ChargeCustomerDialogComponent} from './components/invoice/charge-customer-dialog/charge-customer-dialog.component';
import {PaymentReceivedPageComponent} from './components/payments-received/payment-received-page/payment-received-page.component';
import {PaymentMadePageComponent} from './components/payments-made/payment-made-page/payment-made-page.component';
import {CreateLedgerAccountDialogComponent} from './components/ledger-accounts/create-ledger-account-dialog/create-ledger-account-dialog.component';
import {ChargeLoanCustomerDialogComponent} from './components/lending/charge-loan-customer-dialog/charge-loan-customer-dialog.component';

import {LoansService} from '../../services/api/finance/loans.service';
import {AdvancesComponent} from './components/lending/advances/advances.component';
import {EditAdvancePageComponent} from './components/lending/edit-advance-page/edit-advance-page.component';
import {AdvancePageComponent} from './components/lending/advance-page/advance-page.component';
import {GarnishmentsComponent} from './components/lending/garnishments/garnishments.component';
import {EditGarnishmentPageComponent} from './components/lending/edit-garnishment-page/edit-garnishment-page.component';
import {GarnishmentPageComponent} from './components/lending/garnishment-page/garnishment-page.component';
import {UserLoansComponent} from './components/lending/user-loans/user-loans.component';
import {UserLoanPageComponent} from './components/lending/user-loan-page/user-loan-page.component';
import {RecurringInvoiceTemplateComponent} from './components/recurring-invoice/recurring-invoice-template/recurring-invoice-template.component';
import {InvoiceForApprovePageComponent} from './components/bills/invoice-for-approve-page/invoice-for-approve-page.component';
import {OpeningBalancesPageComponent} from './components/opening-balances/opening-balances-page/opening-balances-page.component';
import {EditOpeningBalancesComponent} from './components/opening-balances/edit-opening-balances/edit-opening-balances.component';
import {PaymentsMadeService} from './services/payments-made.service';
import {PaymentsService} from './services/payments.service';
import {ProductMatchingDialogComponent} from './components/common/product-matching-dialog/product-matching-dialog.component';
import {BillForApprovePageComponent} from './components/invoice/bill-for-approve-page/bill-for-approve-page.component';
import {BillsForApproveListComponent} from './components/invoice/bills-for-approve-list/bills-for-approve-list.component';
import {InvoicesForApproveListComponent} from './components/bills/invoices-for-approve-list/invoices-for-approve-list.component';
import {AssociateContactDialogComponent} from './components/bills/associate-contact-dialog/associate-contact-dialog.component';
import {ApplyCreditsToInvoiceDialogComponent} from './components/invoice/apply-credits-to-invoice-dialog/apply-credits-to-invoice-dialog.component';
import {ApplyCreditsToBillDialogComponent} from './components/bills/apply-credits-to-bill-dialog/apply-credits-to-bill-dialog.component';
import {FailedConnectionDialogComponent} from './components/common/failed-connection-dialog/failed-connection-dialog.component';

import {TagsService} from '../../services/api/tags.service';
import {TagListComponent} from '../account-info/settings/tabs/tab2-tags/tag-list/tag-list.component';
import {EditTagCategoryDialogComponent} from '../account-info/settings/tabs/tab2-tags/edit-tag-category/edit-tag-category-dialog.component';
import {PayoutForApproveAsBillPageComponent} from './components/bills/payout-for-approve-as-bill-page/payout-for-approve-as-bill-page.component';
import {PayoutForApproveAsInvoicePageComponent} from './components/invoice/payout-for-approve-as-invoice-page/payout-for-approve-as-invoice-page.component';
import {FinancialTransferService} from './services/financial-transfer.service';

import {CheckPrintSettingsDialogComponent} from './components/payments-made/check-print-settings-dialog/check-print-settings.dialog';
import {InvoiceBillModalDialogComponent} from './components/invoice-bill-modal-dialog/invoice-bill-modal-dialog.component';
import {PayToContactDialogComponent} from './components/bills/pay-to-contact-dialog/pay-to-contact-dialog.component';
// Journals
import {JournalCreateBarComponent} from './components/journals/journal-create-bar/journal-create-bar.component';
import {JournalPageComponent} from './components/journals/journal-page/journal-page.component';
import {JournalsComponent} from './components/journals/journals/journals.component';
import {RecurringJournalsComponent} from './components/recurring-journals/recurring-journals/recurring-journals.component';
import {EditRecurringJournalComponent} from './components/recurring-journals/edit-recurring-journal/edit-recurring-journal.component';
import {CreateJournalTemplateComponent} from './components/journal-templates/create-journal-template/create-journal-template.component';
import {JournalTemplatesComponent} from './components/journal-templates/journal-templates/journal-templates.component';
import {JournalTemplateComponent} from './components/journal-templates/journal-template/journal-template.component';

import {MoneySendersDetailsComponent} from './components/common/money-senders-details/money-senders-details.component';
import {MoneySendersCustomisationService} from './services/money-senders-customisation.service';
import {RequestListComponent} from './components/deal-finance/deal-finance-deposit-request/request-list/request-list.component';
import {RequestPageComponent} from './components/deal-finance/deal-finance-deposit-request/request-page/request-page.component';
import {DealDepositService} from './services/deal-deposit.service';
import {RefundDialogComponent} from './components/payments-received/refund-dialog/refund-dialog.component';
import {PayBillDialogComponent} from './components/bills/pay-bill-dialog/pay-bill-dialog.component';
import {PaymentReceivedRefundComponent} from './components/refunds/payment-received-refund/payment-received-refund.component';
import {RecurringBillListComponent} from './components/recurring-bill/recurring-bill-list/recurring-bill-list.component';
import {RecurringBillEditComponent} from './components/recurring-bill/recurring-bill-edit/recurring-bill-edit.component';
import {JournalListsComponent} from './components/journals/journal-lists/journal-lists.component';
import {ReleaseListComponent} from './components/deal-finance/deal-finance-deposit-release/release-list/release-list.component';
import {SpeedupTransferPublicPageComponent} from './components/speedup-transfer-public-page/speedup-transfer-public-page.component';
import {PayInvoiceDialogComponent} from './components/invoice/pay-invoice-dialog/pay-invoice-dialog.component';
import {PrintChecksDialogComponent} from './components/payments-made/print-checks-dialog/print-checks-dialog.component';
import {ChecksLedgerErrorPopupComponent} from './components/payments-made/checks-ledger-error-popup/checks-ledger-error-popup.component';
import {PostPrintChecksDialogComponent} from './components/payments-made/post-print-checks-dialog/post-print-checks-dialog.component';
import {FirstCheckNumberDialogComponent} from './components/payments-made/first-check-number-dialog/first-check-number-dialog.component';
import {InvoicePublicPageV2Component} from './components/invoice-public/invoice-public-page-v2/invoice-public-page-v2.component';
import {PaymentTypeSelectionDialogComponent} from './components/invoice-public/dialogs/payment-type-selection-dialog/payment-type-selection-dialog.component';
import {PayViaAuthorizeDialogComponent} from './components/invoice-public/dialogs/pay-via-authorize-dialog/pay-via-authorize-dialog.component';
import {StatementPageComponent} from './components/statement/statement-page/statement-page.component';
import {StatementTableComponent} from './components/statement/statement-table/statement-table.component';
import {StatementInvoicesSidenavComponent} from './components/statement/statement-invoices-sidenav/statement-invoices-sidenav.component';
import {StatementPublicPageComponent} from './components/statement-public/statement-public-page/statement-public-page.component';
import {StatementPublicInvoiceComponent} from './components/statement-public/statement-public-invoice/statement-public-invoice.component';
import {InvoiceStatusTagComponent} from './components/statement-public/invoice-status-tag/invoice-status-tag.component';
import {SetupPaymentInfoDialogComponent} from './components/statement-public/setup-payment-info-dialog/setup-payment-info-dialog.component';
import {SendingPaymentLoaderDialogComponent} from './components/common/sending-payment-loader-dialog/sending-payment-loader-dialog.component';
import {SnakeOrKebabToTitleCasePipe} from './pipes/snake-or-kebab-to-title-case';
import {Edit1099AmountsDialogComponent} from '@app/modules/finance/components/common/edit-1099-amounts-dialog/edit-1099-amounts-dialog.component';
import {PrintChecksForBillsDialogComponent} from './components/bills/check-only-dialog/print-checks-for-bills-dialog';
import {CommonModule} from '@angular/common';

@NgModule({
    declarations: [
        FinancePageComponent,
        InvoicesComponent,
        PaymentsReceivedListComponent,
        CreatePaymentReceivedComponent,
        PaymentReceivedPageComponent,
        CreatePaymentMadeComponent,
        PaymentMadePageComponent,
        RecurringInvoicesComponent,
        EditRecurringInvoiceComponent,
        InvoiceTemplateComponent,
        RecurringInvoiceTemplateComponent,
        LedgerAccountPageComponent,
        LedgerAccountTransactionsComponent,
        JournalPageComponent,
        JournalListsComponent,
        JournalsComponent,
        JournalCreateBarComponent,
        RecurringJournalsComponent,
        EditRecurringJournalComponent,
        CreateJournalTemplateComponent,
        JournalTemplatesComponent,
        JournalTemplateComponent,
        ProductsPageComponent,
        EditProductComponent,
        EditLedgerComponent,
        InvoicePublicPageComponent,
        InvoicePublicPageV2Component,
        InvoicePageComponent,
        BillsComponent,
        BillPageComponent,
        InvoiceForApprovePageComponent,
        PurchasesPageComponent,
        PaymentsMadeListComponent,
        EditInvoicePageComponent,
        EditBillComponent,
        CreateBillConfirmComponent,
        ExpenseListComponent,
        ExpenseEditComponent,
        ExpensePageComponent,
        CreateProductDialogComponent,
        CreateLedgerAccountDialogComponent,
        EnterToPortalComponent,
        CreditNotesComponent,
        EditCreditNoteComponent,
        VendorCreditsComponent,
        SourceCreditNotesListComponent,
        EditVendorCreditComponent,
        ViewCreditNoteComponent,
        ViewVendorCreditComponent,
        RecurringCreditNotesListComponent,
        EditRecurringCreditNoteComponent,
        CreditNoteTemplateComponent,
        ManualMarkAsPaidConfirmDialogComponent,
        ChargeCustomerDialogComponent,
        PayInvoiceDialogComponent,
        ChargeLoanCustomerDialogComponent,
        AdvancesComponent,
        EditAdvancePageComponent,
        AdvancePageComponent,
        GarnishmentsComponent,
        EditGarnishmentPageComponent,
        GarnishmentPageComponent,
        UserLoansComponent,
        UserLoanPageComponent,
        OpeningBalancesPageComponent,
        EditOpeningBalancesComponent,
        ProductMatchingDialogComponent,
        BillForApprovePageComponent,
        SourceVendorCreditComponent,
        SourceVendorCreditListComponent,
        SourceCreditNoteComponent,
        BillsForApproveListComponent,
        InvoicesForApproveListComponent,
        PayoutForApproveAsBillPageComponent,
        PayoutForApproveAsInvoicePageComponent,
        AssociateContactDialogComponent,
        FailedConnectionDialogComponent,
        ApplyCreditsToInvoiceDialogComponent,
        ApplyCreditsToBillDialogComponent,
        TagListComponent,
        EditTagCategoryDialogComponent,
        CheckPrintSettingsDialogComponent,
        InvoiceBillModalDialogComponent,
        PayToContactDialogComponent,
        MoneySendersDetailsComponent,
        ReleaseListComponent,
        RequestListComponent,
        RequestPageComponent,
        RefundDialogComponent,
        PayBillDialogComponent,
        PaymentReceivedRefundComponent,
        RecurringBillListComponent,
        RecurringBillEditComponent,
        SpeedupTransferPublicPageComponent,
        PrintChecksDialogComponent,
        ChecksLedgerErrorPopupComponent,
        PostPrintChecksDialogComponent,
        FirstCheckNumberDialogComponent,
        PaymentTypeSelectionDialogComponent,
        PayViaAuthorizeDialogComponent,
        StatementPageComponent,
        StatementTableComponent,
        StatementInvoicesSidenavComponent,
        StatementPublicPageComponent,
        StatementPublicInvoiceComponent,
        InvoiceStatusTagComponent,
        SetupPaymentInfoDialogComponent,
        SendingPaymentLoaderDialogComponent,
        Edit1099AmountsDialogComponent,
        PrintChecksForBillsDialogComponent
    ],
    imports: [
        SharedModule,
        routing,
        StoreModule.forFeature('finance', financeReducer),
        EffectsModule.forFeature([FinanceEffectsService]),
        SnakeOrKebabToTitleCasePipe,
        CommonModule
    ],
    providers: [
        InvoicePublicService,
        PaymentsReceivedService,
        ExpensesService,
        CreditNotesService,
        VendorCreditsService,
        LoansService,
        PaymentsMadeService,
        PaymentsService,
        CreditsService,
        RecurringCreditNotesService,
        TagsService,
        FinancialTransferService,
        MoneySendersCustomisationService,
        DealDepositService
    ],
    exports: [InvoicePageComponent, BillPageComponent],
    bootstrap: []
})
export class FinanceModule {}
