import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {get as getProp} from 'lodash-es';
import {UntypedFormBuilder, UntypedFormArray, Validators, UntypedFormControl} from '@angular/forms';

export interface IInputDialogData {
    title: string;
    placeholders: string[];
    values?: string[];
}

export class InputFormArray extends UntypedFormArray {
    public controls: UntypedFormControl[];

    constructor(inputs?: UntypedFormControl[]) {
        super(inputs || []);
        this.controls = inputs || [];
    }
}

@Component({
    selector: 'app-input-dialog',
    templateUrl: 'input-dialog.component.html'
})
export class InputDialogComponent implements OnInit {
    inputs: InputFormArray;
    constructor(
        public dialogRef: MatDialogRef<InputDialogComponent>,
        private fb: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: IInputDialogData
    ) {
        this.inputs = new InputFormArray();
    }

    submitData() {
        this.dialogRef.close(this.inputs.value);
    }

    ngOnInit() {
        let len = this.data.placeholders.length;

        while (--len >= 0) {
            this.inputs.push(this.fb.control(getProp(this.data, `values[${len}]`, ''), Validators.required));
        }
    }
}
