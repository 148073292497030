import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';
import {routing} from './banking.routing';
import {BankingComponent} from './banking.component';
import {BankingAccountsPageComponent} from './components/banking-accounts-page/banking-accounts-page.component';

import {BankTransactionsComponent} from './components/bank-transactions/bank-transactions.component';
import {InternalTransactionsComponent} from './components/internal-transactions/internal-transactions.component';
import {ReconciliationsListComponent} from './components/reconciliations/reconciliations-list/reconciliations-list.component';
import {ExternalTransactionsComponent} from './components/external-transactions/external-transactions-list/external-transactions.component';

import {BankingSidebarComponent} from './components/sidebar/banking-sidebar/banking-sidebar.component';
import {BankingMatchComponent} from './components/sidebar/banking-match/banking-match.component';
import {AdjustmentDialogComponent} from './components/sidebar/adjustment-dialog/adjustment-dialog.component';
import {ImportTransactionsComponent} from './components/external-transactions/import-transactions/import-transactions.component';
import {InternalTransactionComponent} from './components/sidebar/internal-transaction/internal-transaction.component';
import {BankingConnectToLedgerAccountDialogComponent} from './components/common/banking-connect-to-ledger-account-dialog/banking-connect-to-ledger-account-dialog.component';
import {ActivateFeedDialogComponent} from './components/common/activate-feed-dialog/activate-feed-dialog.component';
import {ManualMapAccountTypeDialogComponent} from './components/common/manual-map-account-type-dialog/manual-map-account-type-dialog.component';

import {MatchedTransactionListComponent} from './components/sidebar/matched-transaction-list/matched-transaction-list.component';
import {AddTransactionComponent} from './components/sidebar/add-transaction/add-transaction.component';
import {FormExpenseComponent} from './components/sidebar/add-transaction-forms/form-expense.component';
import {FormVendorAdvanceComponent} from './components/sidebar/add-transaction-forms/form-vendor-advance.component';
import {FormVendorPaymentComponent} from './components/sidebar/add-transaction-forms/form-vendor-payment.component';
import {FormCustomerAdvanceComponent} from './components/sidebar/add-transaction-forms/form-customer-advance.component';
import {FormCustomerPaymentComponent} from './components/sidebar/add-transaction-forms/form-customer-payment.component';
import {FormOtherDepositComponent} from './components/sidebar/add-transaction-forms/form-other-deposit.component';
import {FormOwnerDrawingsComponent} from './components/sidebar/add-transaction-forms/form-owner-drawings.component';
import {FormTransferToAnotherAccountComponent} from './components/sidebar/add-transaction-forms/form-transfer-to-another-account.component';
import {FormCardPaymentComponent} from './components/sidebar/add-transaction-forms/form-card-payment.component';
import {FormTransferFromAnotherAccountComponent} from './components/sidebar/add-transaction-forms/form-transfer-from-another-account.component';
import {FormOwnersContributionComponent} from './components/sidebar/add-transaction-forms/form-owners-contribution.component';
import {CreateReconciliationComponent} from './components/reconciliations/create-reconciliation/create-reconciliation.component';

import {ReconciliationsService} from './services/reconciliations.service';
import {StartReconciliationComponent} from './components/reconciliations/start-reconciliation/start-reconciliation.component';
import {ReconciliationPageComponent} from './components/reconciliations/reconciliation-page/reconciliation-page.component';
import {CustomSelectBankAccountDialogComponent} from './components/common/custom-select-bank-account-dialog/custom-select-bank-account-dialog.component';
import {UpdatePlaidCredsByLinkDialogComponent} from './components/common/update-plaid-creds-by-link-dialog/update-plaid-creds-by-link-dialog.component';
import {InstitutionStatusDialogComponent} from './components/common/institution-status-dialog/institution-status-dialog.component';
import {AddTransactionDialogComponent} from './components/sidebar/add-transaction-dialog/add-transaction-dialog.component';

@NgModule({
    declarations: [
        BankingComponent,
        BankingAccountsPageComponent,
        BankTransactionsComponent,
        ExternalTransactionsComponent,
        InternalTransactionsComponent,
        ReconciliationsListComponent,
        BankingSidebarComponent,
        BankingMatchComponent,
        AdjustmentDialogComponent,
        ActivateFeedDialogComponent,
        MatchedTransactionListComponent,
        ImportTransactionsComponent,
        InternalTransactionComponent,
        AddTransactionComponent,
        FormExpenseComponent,
        FormVendorAdvanceComponent,
        FormTransferToAnotherAccountComponent,
        FormCardPaymentComponent,
        FormOwnerDrawingsComponent,
        FormVendorPaymentComponent,
        FormCustomerAdvanceComponent,
        FormCustomerPaymentComponent,
        FormTransferFromAnotherAccountComponent,
        FormOtherDepositComponent,
        FormOwnersContributionComponent,
        ManualMapAccountTypeDialogComponent,
        BankingConnectToLedgerAccountDialogComponent,
        CreateReconciliationComponent,
        StartReconciliationComponent,
        ReconciliationPageComponent,
        CustomSelectBankAccountDialogComponent,
        UpdatePlaidCredsByLinkDialogComponent,
        InstitutionStatusDialogComponent,
        AddTransactionDialogComponent
    ],
    imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, routing],
    providers: [ReconciliationsService],
    exports: [],
    bootstrap: []
})
export class BankingModule {}
