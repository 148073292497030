<section class="container-fluid">
    <header class="py-2" *ngIf="!bankingMode">
        <h1 *ngIf="!ledgerAccount" class="">Create Ledger Account</h1>
        <h1 *ngIf="ledgerAccount" class="">Edit Ledger Account</h1>
    </header>

    <div [formGroup]="formGroup">
        <div class="row d-flex align-items-center">
            <div class="col-sm-6 d-flex align-items-center">
                <mat-form-field style="width: calc(100% - 6px)">
                    <mat-label>Account Type</mat-label>
                    <mat-select formControlName="type">
                        <mat-optgroup *ngFor="let group of typeGroups" [label]="group.group">
                            <mat-option
                                (onSelectionChange)="optionSelected($event)"
                                *ngFor="let type of group.list"
                                [disabled]="isTypeDisabled(type)"
                                [value]="type.value"
                            >
                                {{ type.label }}
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
                <mat-icon
                    *ngIf="ledgerAccount?.system_key"
                    matTooltip="System account: {{ ledgerAccount?.system_key | fromSnakeCase }}"
                >
                    error_outline
                </mat-icon>
            </div>

            <div class="col-sm-6">
                <div *ngIf="zipiFinancialMethods && zipiFinancialMethods.length > 0">
                    <div *ngFor="let method of zipiFinancialMethods">
                        <span class="text-muted">Money Transfers (EFT):</span>
                        {{ method!.payment_gateway!.title }} &#8212; {{ method!.title }}
                    </div>
                </div>
                <div *ngIf="bankingMethod"><span class="text-muted">Banking Feed:</span> {{ bankingMethod.title }}</div>
            </div>
        </div>
        <div class="row" style="color: red; font-size: 16px" *ngIf="isWarningShow">
            <div class="col-sm-6 pb-2">Warning! The account has been disconnected from the parent account.</div>
        </div>
        <div class="row">
            <div *ngIf="accType?.canBeSubAcc" class="col-sm-6">
                <mat-checkbox formControlName="has_parent">Make this a sub-account</mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6" *ngIf="formGroup.controls.has_parent.value && ledgerAccounts.length > 0">
                <mat-form-field class="w-100">
                    <mat-label>Parent Account</mat-label>
                    <mat-select #parentSelect formControlName="parent__ledger_account_fk_id" ariaLabel="Parent Account">
                        <mat-option *ngFor="let la of ledgerAccounts" [value]="la.id">
                            <span *ngFor="let lvl of [].constructor(la.level); let i = index">
                                <span *ngIf="i !== [].constructor(la.level).length - 1">&nbsp;&nbsp;&nbsp;</span>
                                <span *ngIf="i === [].constructor(la.level).length - 1 && parentSelect.panelOpen"
                                    >&#8226;</span
                                >
                            </span>
                            {{ la.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-6" *ngIf="formGroup.controls.has_parent.value && ledgerAccounts.length === 0">
                No accounts with such type
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6">
                <mat-form-field class="w-100">
                    <input
                        type="text"
                        matInput
                        formControlName="name"
                        placeholder="Ledger Account Name"
                        autocomplete="off"
                        required
                    />
                    <mat-icon
                        matSuffix
                        matTooltip="Enter the name of the ledger account you would like to create for your organization."
                    >
                        info
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-sm-6" *ngIf="accType?.hasBankName">
                <mat-form-field class="w-100">
                    <input
                        type="text"
                        matInput
                        formControlName="bank_name"
                        placeholder="Bank Name"
                        autocomplete="off"
                    />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <mat-form-field class="w-100">
                    <input
                        type="text"
                        matInput
                        formControlName="code"
                        placeholder="Ledger Account Code"
                        autocomplete="off"
                    />
                    <mat-icon matSuffix matTooltip="Unique reference code for the ledger account."> info </mat-icon>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div *ngIf="accType?.hasNumber" class="col-sm-6">
                <mat-form-field class="w-100">
                    <input
                        type="text"
                        matInput
                        formControlName="number"
                        placeholder="Bank Account Number"
                        autocomplete="off"
                    />
                </mat-form-field>
            </div>
            <div *ngIf="accType?.hasCurrency" class="col-sm-6">
                <mat-form-field class="w-100">
                    <mat-label>Currency</mat-label>
                    <mat-select formControlName="currency" ariaLabel="Currency">
                        <mat-option *ngFor="let currency of currencyList" [value]="currency">
                            {{ currency }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <textarea
                        matInput
                        formControlName="description"
                        placeholder="Ledger Account description"
                        autocomplete="doNotAutoComplete"
                    ></textarea>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="accType?.hasExpense" class="row">
            <div class="col-12">
                <mat-checkbox formControlName="expense">Show as an active account in Expenses</mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <mat-checkbox [formControl]="formGroup.controls.is_accessible_for_all"
                    >Accessible for all Divisions?</mat-checkbox
                >
            </div>
        </div>
        <div class="row">
            <div class="col-6" *ngIf="!formGroup.controls.is_accessible_for_all.value">
                <mat-form-field class="w-100">
                    <mat-chip-list #chipList aria-label="Groups selection">
                        <ng-container *ngFor="let group of groupsList">
                            <mat-chip *ngIf="selectedGroupsIds.includes(group.id!)" (removed)="removeGroup(group.id!)">
                                {{ group.title }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                        </ng-container>

                        <input
                            #groupInput
                            placeholder="Accessible for {{
                                formGroup.controls.is_accessible_for_all.value ? 'all' : ''
                            }}"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="chipList"
                            (keydown)="$event.stopPropagation(); $event.preventDefault(); clearGroupInput()"
                            (matChipInputTokenEnd)="addGroup($event)"
                        />
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedGroup($event)">
                        <ng-container *ngFor="let group of groupsList">
                            <mat-option [value]="group.id" *ngIf="!selectedGroupsIds.includes(group.id!)">
                                {{ group.title }}
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="d-flex">
            <div
                [matTooltip]="'The Ledger Account has associated transactions and cannot be deleted.'"
                [matTooltipDisabled]="!isLedgerHasConnectedTransaction"
            >
                <button
                    type="submit"
                    *ngIf="ledgerAccount"
                    mat-raised-button
                    color="warn"
                    [disabled]="
                        !!ledgerAccount?.system_key ||
                        ledgerAccount!.connected_payment_methods!.length > 0 ||
                        isLedgerHasConnectedTransaction
                    "
                    (click)="deleteLedgerAccount()"
                >
                    Delete
                </button>
            </div>
            <span class="sp-spacer"></span>
            <button *ngIf="!dialogMode" mat-raised-button class="ml-2" routerLink="/company/finance/chartofaccounts">
                Cancel
            </button>
            <button *ngIf="dialogMode" mat-raised-button class="ml-2" (click)="cancel.emit()">Cancel</button>
            <button
                type="!submit"
                class="ml-2"
                *ngIf="!ledgerAccount"
                mat-raised-button
                color="primary"
                (click)="createLedgerAccount()"
            >
                Create
            </button>
            <button
                type="submit"
                class="ml-2"
                *ngIf="ledgerAccount"
                mat-raised-button
                color="primary"
                (click)="updateLedgerAccount()"
            >
                Save
            </button>
        </div>
    </div>
</section>
