import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {GroupsSource} from '../../../services/sources/groups.source';
import {FeatureFlagsService} from '../../feature-flags/feature-flags.service';
import {SessionService} from '../../../services/session.service';
import {takeUntil} from 'rxjs/operators';
import {LEDGER_TYPES_GROUPS} from '../../../local-typings';
import {FLATFILE_IMPORT_SETTINGS_BY_TYPE} from './import-typings';
import {ILedgerAccount, IRole} from '../../../../typings';
import {Group} from '../../../models/group';
import {UsersAndLicencesApiService} from '../users-licenses/users-and-licences.api.service';
import * as _ from 'lodash-es';
import {CurrentProfileSource} from '../../../services/sources/current-profile.source';
import {ContactType} from '../../../models/contact-type';
import {ContactTypeService} from '../../../services/api/contact-type.service';
import {ContactClassService} from '../../../services/api/contact-class.service';
import {ContactClass} from '../../../models/contact-class';
import {ProfilesSource} from '../../../services/sources/profiles.source';
import {Profile} from '../../../models/profile';
import {AvailableRolesSource} from '../../../services/sources/available-roles.source';
import {Flatfile, FlatfileRecord, PartialRejection, RecordError, RecordsChunk} from '@flatfile/sdk';
import {UploadService} from '../services/upload.service';
import {NotificationsService} from 'angular2-notifications';

@Component({
    selector: 'app-import-data-button',
    styles: [
        `
            flatfile-button {
                display: block;
            }

            ::ng-deep app-import-data-button > flatfile-button > button {
                border: 0px solid;
                background-color: inherit;
                padding: 0;
                width: 100%;
                text-align: left;
                color: inherit;
            }
        `
    ],
    template: `
        <div *ngIf="isReady" (click)="openFlatFilePortal()">
            {{ buttonTitle }}
        </div>
    `
})
export class ImportDataButtonComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() type: string | null = null;
    @Input() buttonTitle: string | undefined;
    @Input() ledgerAccounts: ILedgerAccount[] = [];
    @Input() getFlatFileToken: (() => string) | undefined;

    typeGroups = LEDGER_TYPES_GROUPS;

    isReady: boolean = false;
    flatFileSettings = {
        disableManualInput: true,
        autoDetectHeaders: true,
        theme: {
            buttons: {
                primary: {
                    backgroundColor: '#607d8b',
                    border: 'none',
                    padding: '6px 16px',
                    fontSize: '15px'
                }
            }
        },
        type: '',
        fields: []
    };
    @Output() returnDataEmitter = new EventEmitter();
    public listOfLedgerAccountsPrepared: {
        label: string;
        value: string;
    }[] = [];
    public listOfCOATypesPrepared: {
        label: string;
        value: string;
    }[] = [];
    public listOfContacts: any[] = [];
    public contactTypes: ContactType[] = [];
    public contactClasses: ContactClass[] = [];
    public profilesList: Profile[] = [];
    public rolesList: IRole[] = [];

    public groups: Group[] = [];
    public divisions: Group[] = [];

    public disabledTypes = [
        'trust_liability',
        'other_current_asset_accounts_receivable',
        'current_liability',
        'retained_earnings'
    ];

    constructor(
        public router: Router,
        protected groupSrc: GroupsSource,
        protected featureFlagsService: FeatureFlagsService,
        public sessionService: SessionService,
        public usersAndLicencesApiService: UsersAndLicencesApiService,
        public currentProfileSource: CurrentProfileSource,
        private contactTypeService: ContactTypeService,
        private contactClassService: ContactClassService,
        protected profilesSource: ProfilesSource,
        private availableRolesSource: AvailableRolesSource,
        private uploadService: UploadService,
        private notificationsService: NotificationsService
    ) {}

    ngOnInit() {
        this.initSetting();
    }

    async initSetting() {
        switch (this.type) {
            case 'products_services':
                this.listOfLedgerAccountsPrepared = [];
                this.listOfLedgerAccountsPrepared = this.ledgerAccounts.map((ledger_account) => {
                    return {value: ledger_account.name, label: ledger_account.name};
                });
                const laIndex = FLATFILE_IMPORT_SETTINGS_BY_TYPE.products_services.fields.findIndex(
                    (field: {key: string}) => field.key === 'ledger_account_name'
                );
                if (laIndex && FLATFILE_IMPORT_SETTINGS_BY_TYPE.products_services.fields[laIndex]) {
                    // @ts-ignore
                    FLATFILE_IMPORT_SETTINGS_BY_TYPE.products_services.fields[laIndex].options =
                        this.listOfLedgerAccountsPrepared;
                }
                break;
            case 'chart_of_accounts':
                // fill type values
                this.listOfCOATypesPrepared = [];
                this.typeGroups.forEach((typeGroup: {list: any[]}) => {
                    typeGroup.list
                        .filter((typeItem: {value: string}) => !this.disabledTypes.includes(typeItem.value))
                        .map((type) => {
                            this.listOfCOATypesPrepared.push({value: type.value, label: type.label});
                        });
                });
                const typeIndex = FLATFILE_IMPORT_SETTINGS_BY_TYPE.chart_of_accounts.fields.findIndex(
                    (field: {key: string}) => field.key === 'type'
                );
                if (typeIndex && FLATFILE_IMPORT_SETTINGS_BY_TYPE.chart_of_accounts.fields[typeIndex]) {
                    // @ts-ignore
                    FLATFILE_IMPORT_SETTINGS_BY_TYPE.chart_of_accounts.fields[typeIndex].options =
                        this.listOfCOATypesPrepared;
                }

                // get divisions
                this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
                    this.divisions = list.filter((division) => division.type === 'division');
                });
                break;
            case 'contacts':
                this._getContacts();
                this.contactTypeService.getContactTypeListAsPromise().then((list) => {
                    this.currentProfileSource.contactTypes.next(list);
                    this.contactTypes = list;
                });
                this.contactClassService.getContactClassListAsPromise().then((list) => {
                    this.currentProfileSource.contactClasses.next(list);
                    this.contactClasses = list;
                });
                break;
            case 'profiles':
                this._getContacts();
                this.profilesSource.source.pipe(takeUntil(this.unsubscribe)).subscribe((profiles) => {
                    this.profilesList = profiles;
                });
                // get groups and divisions
                this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
                    this.groups = list.filter((division) => division.type === 'default');
                    this.divisions = list.filter((division) => division.type === 'division');
                });
                // get company roles
                this.availableRolesSource.source.pipe(takeUntil(this.unsubscribe)).subscribe((roles) => {
                    this.rolesList = [
                        ...roles.systemRoles.filter((role) => role.scope === 'company'),
                        ...roles.companyRoles.filter((role) => role.scope === 'company')
                    ];
                });
                break;
        }
        if (this.type && FLATFILE_IMPORT_SETTINGS_BY_TYPE[this.type]) {
            this.flatFileSettings = Object.assign(this.flatFileSettings, FLATFILE_IMPORT_SETTINGS_BY_TYPE[this.type]);
            this.isReady = true;
        }
    }

    async openFlatFilePortal() {
        const data: any[] = [];
        const config = await this.uploadService.getFlatFileConfigData(this.type || '');

        if (!!config) {
            Flatfile.requestDataFromUser({
                chunkSize: 5000,
                token: config.token,
                theme: {
                    logo: 'https://storage.googleapis.com/zipi-static/app-assets/skyslope-books-logo-blue-padded.svg',
                    hideConfetti: true,
                    displayName: 'SkySlope Books'
                },
                onData: (chunk: RecordsChunk, next: (arg0: PartialRejection) => void) => {
                    const errors: RecordError[] = [];

                    if (this.type === 'chart_of_accounts') {
                        for (const [index, record] of chunk.records.entries()) {
                            const coaErrors = this.validationCOAObject(record, chunk.records);
                            if (coaErrors.length) {
                                errors.push(new RecordError(record, coaErrors));
                            } else {
                                data.push(record.data);
                            }
                        }
                    }

                    if (this.type === 'contacts') {
                        for (const [index, record] of chunk.records.entries()) {
                            const contactErrors = this.validationContactObject(record);
                            if (contactErrors.length) {
                                errors.push(new RecordError(record, contactErrors));
                            } else {
                                data.push(record.data);
                            }
                        }
                    }

                    if (this.type === 'profiles') {
                        for (const [index, record] of chunk.records.entries()) {
                            const profileErrors = this.validationProfileObject(record);
                            if (profileErrors.length) {
                                errors.push(new RecordError(record, profileErrors));
                            } else {
                                data.push(record.data);
                            }
                        }
                    }

                    if (this.type === 'products_services') {
                        // Unlike the other data types, we don't have system validation for products/services.
                        for (const [index, record] of chunk.records.entries()) {
                            data.push(record.data);
                        }
                    }

                    next(new PartialRejection(errors));
                },
                onComplete: () => {
                    this.returnDataEmitter.emit({data: data, type: this.type});
                    this.notificationsService.success(
                        'The import is processing and you will receive a message in the top notifications bell when complete.'
                    );
                }
            });
        }
    }

    validationCOAObject(record: FlatfileRecord, csv: FlatfileRecord[]) {
        const recordData = record.data;
        const errors: {field: string; message: string}[] = [];
        // unique code
        if (recordData.code && recordData.code.toString().length) {
            // unique code in existed coa
            if (this.ledgerAccounts.find((coa) => coa.code === recordData.code)) {
                errors.push({field: 'code', message: `Account code '${recordData.code}' already exists.`});
            }
        }
        // unique name+type in existed coa
        if (this.ledgerAccounts.find((coa) => coa.name === recordData.name && coa.type === recordData.type)) {
            errors.push({field: 'name', message: `Account Name and Account Type already exists.`});
        }

        // division
        const accessibleFor = recordData.accessible_for;
        const divisionsTitles = this.divisions.map((division) => division.title);
        if (accessibleFor && accessibleFor.toString().length) {
            accessibleFor
                .toString()
                .split(',')
                .map((division: any) => {
                    if (division && divisionsTitles.indexOf(division.trim()) === -1) {
                        errors.push({
                            field: 'accessible_for',
                            message: `Company division '${division}' doesn't exist.`
                        });
                    }
                });
        }
        // type has currency
        // type don't has number
        // type hasn't expense
        // canBeSubAcc
        this.typeGroups.forEach((typeGroup: {list: any[]}) => {
            typeGroup.list.map((type) => {
                if (type.value === recordData.type) {
                    if (type.hasCurrency && !recordData.currency) {
                        errors.push({
                            field: 'currency',
                            message: `Currency is required for Account Type "${type.label}".`
                        });
                    }
                    if (!type.hasNumber && recordData.number) {
                        errors.push({
                            field: 'number',
                            message: `Chart of Account with ${type.label} type can't have an account number`
                        });
                    }
                    if (!type.hasExpense && recordData.expense === 'Yes') {
                        // Flatfile cannot take error messages > 100 chars. Had to remove variable for now.
                        // errors.push({field: "expense", message: `Account type ${type.label} cannot be shown as an active account in Expenses. Please select No.`});
                        errors.push({
                            field: 'expense',
                            message: `Account type cannot be shown as an active account in Expenses. Please select No.`
                        });
                    }
                    if (!type.canBeSubAcc && recordData.parentName) {
                        errors.push({field: 'parentName', message: `Account Type does not allow for sub-accounts.`});
                    }
                }
            });
        });
        // check parent
        if (recordData.parentName) {
            const coaParentLedgerAccount = this.ledgerAccounts.find(
                (coa) => coa.name.toString().trim() === recordData.parentName!.toString().trim()
            );
            const parentRecord = csv.find((r) => r.data.name === recordData.parentName);
            if (coaParentLedgerAccount) {
                if (this.disabledTypes.includes(coaParentLedgerAccount.type)) {
                    errors.push({
                        field: 'parentName',
                        message: `The parent account "${recordData.parentName}" does not allow sub-accounts, please remove the parent account value.`
                    });
                } else if (coaParentLedgerAccount.type !== recordData.type) {
                    errors.push({
                        field: 'parentName',
                        message: `The parent account "${recordData.parentName}" and sub-account "${recordData.name}" account types do not match. To correct this, change: the selected parent account, or the account type of the sub-account "${recordData.name}" to "${coaParentLedgerAccount.type}".`
                    });
                }
            } else if (parentRecord) {
                // Find the parent record in the csv. This will only work if we process this csv in one chunk.
                if (parentRecord && parentRecord.data.type !== recordData.type) {
                    errors.push({
                        field: 'parentName',
                        message: `The parent account "${recordData.parentName}" and sub-account "${recordData.name}" account types do not match. To correct this, change: the selected parent account, or the account type of the sub-account "${recordData.name}" to "${parentRecord.data.type}".`
                    });
                }
            } else {
                errors.push({
                    field: 'parentName',
                    message: `The parent account "${recordData.parentName}" does not exist in SkySlope Books.`
                });
            }
        }
        return errors;
    }

    validationContactObject(record: FlatfileRecord) {
        const recordData = record.data;
        const errors: {field: string; message: string}[] = [];

        // unique company_name
        if (recordData.company_name) {
            if (
                this.listOfContacts.find(
                    (contact) =>
                        recordData.company_name &&
                        contact.company_name === recordData.company_name.toString() &&
                        (!recordData.contact_id || // new company contact
                            (recordData.contact_id && Number(contact.contact_id) !== Number(recordData.contact_id))) // update existing company contact
                )
            ) {
                errors.push({
                    field: 'company_name',
                    message: `The Company Name "${recordData.company_name}" already in use.`
                });
            }
        }

        if (recordData.email) {
            const email = this._formatEmail(recordData.email.toString());

            if (
                this.listOfContacts.find(
                    (contact) =>
                        email &&
                        contact.email &&
                        contact.email.toLowerCase() === email.toString().toLowerCase() &&
                        (!recordData.contact_id || // new contact
                            (recordData.contact_id && Number(contact.contact_id) !== Number(recordData.contact_id))) // update existing contact
                )
            ) {
                errors.push({field: 'email', message: `The Email is already in use.`});
            }
        }

        // If category was provided, ensure the value(s) exist in the contactTypes list.
        if (recordData.category) {
            const availableContactTypes = this.contactTypes.map((c) => c.title);
            const validCategory = recordData.category
                .toString()
                .split(',')
                .every((contactCategory: string) =>
                    availableContactTypes.some((type) => type === contactCategory.trim())
                );

            if (!validCategory) {
                errors.push({
                    field: 'category',
                    message: `Category value(s) must be any of: ${availableContactTypes}.`
                });
            }
        }

        // If class was provided, ensure the value(s) exist in the contactClasses list.
        if (recordData.class) {
            const availableContactClasses = this.contactClasses.map((c) => c.title);
            const validClass = recordData.class
                .toString()
                .split(',')
                .every((contactClass: string) => availableContactClasses.some((c) => c === contactClass.trim()));

            if (!validClass) {
                errors.push({field: 'class', message: `Class value(s) must be any of: ${availableContactClasses}.`});
            }
        }

        // payrolls
        this._validatePayrolls(recordData);

        return errors;
    }

    validationProfileObject(record: FlatfileRecord) {
        const recordData = record.data;
        const errors: {field: string; message: string}[] = [];

        if (recordData.email) {
            const email = this._formatEmail(recordData.email.toString());

            if (
                this.listOfContacts.find(
                    (contact) =>
                        email && contact.email && contact.email.toLowerCase() === email.toString().toLowerCase()
                )
            ) {
                errors.push({field: 'email', message: `The Contact Email already in use.`});
            }
            if (
                this.profilesList.find(
                    (profile) =>
                        email && profile.email && profile.email.toLowerCase() === email.toString().toLowerCase()
                )
            ) {
                errors.push({field: 'email', message: `The Profile Email already in use.`});
            }
        }

        if (recordData.groups) {
            const availableGroups = this.groups.map((g) => g.title);
            const isValidGroups = recordData.groups
                .toString()
                .split(',')
                .every((group: string) => availableGroups.some((g) => g === group.trim()));

            if (!isValidGroups) {
                errors.push({field: 'groups', message: `Groups value(s) must be any of: ${availableGroups}.`});
            }
        }

        if (recordData.divisions) {
            const availableDivisions = this.divisions.map((d) => d.title);
            const isValidDivision = recordData.divisions
                .toString()
                .split(',')
                .every((division: string) => availableDivisions.some((d) => d === division.trim()));

            if (!isValidDivision) {
                errors.push({field: 'divisions', message: `Divisions value(s) must be any of: ${availableDivisions}.`});
            }
        }

        if (recordData.roles) {
            const availableRoles = this.rolesList.map((r) => r.title);
            const isValidRole = recordData.roles
                .toString()
                .split(',')
                .every((role: string) => availableRoles.some((r) => r === role.trim()));

            if (!isValidRole) {
                errors.push({field: 'roles', message: `Roles value(s) must be any of: ${availableRoles}.`});
            }
        }

        // payrolls
        this._validatePayrolls(recordData);

        return errors;
    }

    _validatePayrolls(record: any) {
        if (record.type) {
            if (record.type === '1099-NEC') {
                record.type = '1099_misc';
            } else if (record.type === 'W-2') {
                record.type = 'w_2';
            }
        }
    }

    _getContacts() {
        this.usersAndLicencesApiService
            .getContactsLite()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                this.listOfContacts = response.map((contact) => {
                    const mainPerson = contact.contact_persons.length ? _.first(contact.contact_persons) : false;
                    return {
                        contact_id: contact.contact_id,
                        display_name: contact.display_name,
                        company_name: contact.company_name,
                        email: mainPerson ? mainPerson.email : '',
                        first_name: mainPerson ? mainPerson.first_name : '',
                        last_name: mainPerson ? mainPerson.last_name : ''
                    };
                });
            });
    }

    _formatEmail(email: string) {
        return _.trim(email.toLowerCase()).replace(/(\r\n|\n|\r)/gm, '');
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.returnDataEmitter.complete();
    }
}
