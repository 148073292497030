<div>
    <mat-card class="preview-body" *ngIf="tableData && tableData.length">
        <div>
            <table class="mat-table tables w-100">
                <tr class="mat-header-row">
                    <th class="header-cell sticky">Date</th>
                    <th class="header-cell sticky">Account</th>
                    <th class="header-cell sticky">Transaction Details</th>
                    <th class="header-cell sticky">Account Type</th>
                    <th class="header-cell sticky">Journal Id</th>
                    <th class="header-cell sticky">Reference</th>
                    <th class="header-cell sticky">Debit</th>
                    <th class="header-cell sticky">Credit</th>
                </tr>
                <tr class="table-row" *ngFor="let transaction of tableData">
                    <ng-container *ngIf="transaction.is_header">
                        <td colspan="9" class="body-cell">{{ transaction.title }}</td>
                    </ng-container>
                    <ng-container *ngIf="!transaction.is_header">
                        <td class="body-cell">{{ transaction.date }}</td>
                        <td class="body-cell">{{ transaction.name }}</td>
                        <td class="body-cell">{{ transaction.t_details }}</td>
                        <td class="body-cell">{{ transaction.account_type }}</td>
                        <td class="body-cell">{{ transaction.j_id }}</td>
                        <td class="body-cell">{{ transaction.reference_number }}</td>
                        <td class="body-cell align-right">{{ transaction.debit | reportFormatted: 'financial' }}</td>
                        <td class="body-cell align-right">{{ transaction.credit | reportFormatted: 'financial' }}</td>
                    </ng-container>
                </tr>
            </table>
        </div>

        <p class="m-2">*Amount is displayed in your base currency <span class="badge-secondary">USD</span></p>
    </mat-card>

    <mat-card class="preview-body" *ngIf="!tableData || !tableData.length">
        <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
    </mat-card>
</div>
