<div>
    <div class="closing-line">
        <span class="sp-spacer"></span>
        <mat-icon class="sp-cursor-pointer" (click)="close()">close</mat-icon>
    </div>

    <div class="popup-header">
        <h2 style="margin-bottom: 0">Edit 1099 Amount</h2>
    </div>

    <div class="popup-content">
        <div [formGroup]="form1099" *ngIf="form1099">
            <div class="d-flex align-items-center">
                <div>
                    <mat-form-field>
                        <input matInput formControlName="amount_1099_nec" [maskito]="currencyMaskitoMask" />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div class="popup-actions">
        <span class="sp-spacer"></span>
        <button mat-raised-button (click)="close()">Back</button>
        <button mat-raised-button color="accent" class="ml-2" [disabled]="isButtonDisabled" (click)="saveAmounts()">
            Save
        </button>
    </div>
</div>
