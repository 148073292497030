<mat-card [formGroup]="formGroup">
    <div style="width: 275px">
        <app-date-picker
            [disabled]="!!startDate || !!openingBalanceDate"
            [placeholder]="'Start Date'"
            [maxDate]="maxDate"
            [dateControl]="formGroup.get('start_date')"
        >
        </app-date-picker>
        <app-date-picker [placeholder]="'End Date'" [minDate]="minDate" [dateControl]="formGroup.get('end_date')">
        </app-date-picker>
        <mat-form-field class="w-100">
            <input
                matInput
                formControlName="closing_balance"
                autocomplete="off"
                placeholder="Closing Balance"
                [maskito]="currencyMaskitoMask"
            />
        </mat-form-field>
    </div>

    <div *ngIf="!isOpeningBalanceExist" class="mb-3" style="color: red">
        The Opening Balance for this account has not been filled. To create one please visit section Company > Finance >
        Opening Balance.
    </div>

    <div class="d-flex mt-2">
        <button
            mat-raised-button
            class="mat-primary"
            [disabled]="!formGroup.valid || !isOpeningBalanceExist"
            (click)="continue()"
        >
            Start Reconciliation
        </button>
        <button mat-raised-button class="ml-2" (click)="cancel()">Cancel</button>
    </div>
</mat-card>
