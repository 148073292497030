import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GroupsSource} from 'app/services/sources/groups.source';
import {Profile} from 'app/models/profile';
import {Group} from 'app/models/group';
import {GenericFormArray} from 'app/entites/generic.entity';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {filter, takeUntil} from 'rxjs/operators';
import {Tab1OrgChartComponent} from './tab1-org-chart.component';
import {IGroup} from '@cyberco-nodejs/zipi-typings';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-company-org-charts-list',
    styles: [
        `
            .container-h {
                width: 100%;
                height: calc(100% - 45px);
            }
        `
    ],
    template: `
        <div class="container-h">
            <!------- Header with routing ------->
            <app-account-info-toolbar
                [section]="'divisions'"
                [createModeDivision]="createMode"
                (enterCreationModeDivisionEmitter)="enterCreationMode$.next($event)"
            ></app-account-info-toolbar>

            <app-company-groups-tab-1-org-chart
                [groupsListFA]="groupsListAllFA"
                #all
                (createModeEmitter)="createMode = $event"
                [enterCreationMode$]="enterCreationMode$"
            ></app-company-groups-tab-1-org-chart>
        </div>
    `
})
export class IndexComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('all', {static: true}) all: Tab1OrgChartComponent | undefined;

    public currentProfile: Profile = new Profile();
    public groupsListAllFA: GenericFormArray<Group> = new GenericFormArray<Group>([]);

    protected sortedGroupsList: any[] = [];

    public createMode: boolean = false;
    enterCreationMode$: Subject<any> = new Subject();

    constructor(
        private groupSrc: GroupsSource,
        protected currentProfileSource: CurrentProfileSource
    ) {}

    ngOnInit() {
        this.initDivisions();

        this.currentProfileSource.changeProfileEvent
            .pipe(
                filter((profile) => profile.company !== null),
                takeUntil(this.unsubscribe)
            )
            .subscribe((profile) => {
                this.currentProfile = profile;

                this.groupSrc.load(this.currentProfile).then(() => {
                    this.initDivisions();
                });
            });
    }

    initDivisions() {
        this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
            this.sortedGroupsList = [];

            const filteredGroups = list
                .filter((group) => group.type === Group.type_SET.division)
                .map((group) => {
                    return {...group, children: this.withChildren(list, group.id as number)};
                });

            const divisionsList = filteredGroups.filter((group) => !group.parent__group_fk_id);

            divisionsList.forEach((group) => {
                if (!group.parent__group_fk_id && !group.parent_group) {
                    const currentGroup = list.find((g) => g.id === group.id);
                    if (currentGroup) {
                        currentGroup.child_level = 0;
                        this.sortedGroupsList.push(currentGroup);
                        this.addChildren(list, group, 0);
                    }
                }
            });

            this.groupsListAllFA.patchValue(this.sortedGroupsList);
        });
    }

    addChildren(list: Group[], group: any, childLevel: number) {
        if (group.children && group.children.length) {
            group.children.forEach((childGroup: any) => {
                const child = list.find((g) => g.id === childGroup.id);
                if (child) {
                    child.child_level = childLevel + 1;
                    this.sortedGroupsList.push(child);
                    this.addChildren(list, childGroup, childLevel + 1);
                }
            });
        }
    }

    withChildren(allGroups: Group[], groupId: number): any[] {
        const children = allGroups.filter((group) => group.parent_group && group.parent_group.id === groupId);

        if (children.length) {
            return children.map((group) => {
                return {
                    ...group,
                    children: this.withChildren(allGroups, group.id as number)
                };
            });
        }
        return [];
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.enterCreationMode$.complete();
    }
}
