import {Injectable} from '@angular/core';
import {ServiceRequester} from '../service.requester';

@Injectable({
    providedIn: 'root'
})
export class WildcardsService {
    private url = '/wildcards/';

    constructor(protected requester: ServiceRequester) {}

    /** Use store */
    getWildcards() {
        return this.requester.makeMsCall(this.url + '', 'GET');
    }

    /** Use store */
    createWildcard(data: any) {
        return this.requester.makeMsCall(this.url + '', 'POST', data);
    }

    /** Use store */
    deleteWildcard(attribute_id: number) {
        return this.requester.makeMsCall(`${this.url}${attribute_id}`, 'DELETE');
    }

    /** Use store */
    updateWildcard(data: any) {
        return this.requester.makeMsCall(this.url + '', 'PUT', data);
    }

    getWildcardInstances(contactId: number) {
        return this.requester.makeMsCall$(this.url + 'instances/' + contactId, 'GET', 'zipi');
    }

    createWildcardInstance(contactId: number, data: any) {
        return this.requester.makeMsCall(this.url + 'instances/' + contactId, 'POST', data);
    }
}
