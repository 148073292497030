import {Observable, combineLatest, of} from 'rxjs';

import {switchMap, map, tap, first, filter, catchError, mapTo} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';

import {
    franchiseActionTypes,
    SaveFranchiseRequestPendingAction,
    SaveFranchiseRequestSuccessAction,
    SaveFranchiseRequestErrorAction,
    GetFranchiseRequestsPendingAction,
    GetFranchiseRequestsSuccessAction,
    GetFranchiseRequestsErrorAction,
    AcceptOrRejectFranchiseRequestPendingAction,
    AcceptOrRejectFranchiseRequestSuccessAction,
    AcceptOrRejectFranchiseRequestErrorAction,
    CancelFranchiseRequestPendingAction,
    CancelFranchiseRequestSuccessAction,
    CancelFranchiseRequestErrorAction,
    DisconnectFranchiseRequestPendingAction,
    DisconnectFranchiseRequestSuccessAction,
    DisconnectFranchiseRequestErrorAction
} from './franchise.actions';
import {IFranchiseState} from './franchise.reducer';
import {NotificationsService} from 'angular2-notifications';
// import {
//     selectExpenses
// } from './franchise.selectors';

import {FranchiseRequestService} from 'app/services/franchise-request.service';

@Injectable()
export class FranchiseRequestEffectsService {
    constructor(
        protected actions$: Actions,
        protected store: Store<IFranchiseState>,
        protected router: Router,
        private ntfs: NotificationsService,
        private franchiseSrv: FranchiseRequestService
    ) {}

    @Effect()
    createFranchise$ = this.actions$.pipe(
        ofType<SaveFranchiseRequestPendingAction>(franchiseActionTypes.SAVE_FRANCHISE_REQUEST_PENDING),
        switchMap(({payload}) =>
            this.franchiseSrv.createFranchiseRequest(payload).pipe(
                map((a) => {
                    this.ntfs.info(`Request successfully created`);
                    return new SaveFranchiseRequestSuccessAction(a);
                }),
                catchError((err) => of(new SaveFranchiseRequestErrorAction(err)))
            )
        )
    );

    @Effect()
    getFranchiseRequests$ = this.actions$.pipe(
        ofType<GetFranchiseRequestsPendingAction>(franchiseActionTypes.GET_FRANCHISE_REQUESTS_PENDING),
        switchMap(() =>
            this.franchiseSrv.getFranchiseRequests().pipe(
                map((a) => new GetFranchiseRequestsSuccessAction(a)),
                catchError((err) => of(new GetFranchiseRequestsErrorAction(err)))
            )
        )
    );

    @Effect()
    acceptOrRejectFranchise$ = this.actions$.pipe(
        ofType<AcceptOrRejectFranchiseRequestPendingAction>(
            franchiseActionTypes.ACCEPT_OR_REJECT_FRANCHISE_REQUESTS_PENDING
        ),
        switchMap(({payload}) =>
            this.franchiseSrv.updateFranchiseRequest(payload).pipe(
                map((a) => {
                    this.ntfs.info(`Request successfully updated`);
                    return new AcceptOrRejectFranchiseRequestSuccessAction(a);
                }),
                catchError((err) => of(new AcceptOrRejectFranchiseRequestErrorAction(err)))
            )
        )
    );

    @Effect()
    cancelFranchise$ = this.actions$.pipe(
        ofType<CancelFranchiseRequestPendingAction>(franchiseActionTypes.CANCEL_FRANCHISE_REQUEST_PENDING),
        switchMap(({payload}) =>
            this.franchiseSrv.deleteFranchiseRequest(payload).pipe(
                map((a) => {
                    this.ntfs.info(`Request successfully cancelled`);
                    return new CancelFranchiseRequestSuccessAction(a);
                }),
                catchError((err) => of(new CancelFranchiseRequestErrorAction(err)))
            )
        )
    );

    @Effect()
    disconnectFranchise$ = this.actions$.pipe(
        ofType<DisconnectFranchiseRequestPendingAction>(franchiseActionTypes.DISCONNECT_FRANCHISE_REQUEST_PENDING),
        switchMap(({payload}) =>
            this.franchiseSrv.disconnectFranchiseRequest(payload).pipe(
                map((a) => {
                    this.ntfs.info(`Request successfully disconnected`);
                    return new DisconnectFranchiseRequestSuccessAction(a);
                }),
                catchError((err) => of(new DisconnectFranchiseRequestErrorAction(err)))
            )
        )
    );
}
