import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {firstValueFrom, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {CompanyGatewayService} from '../../../../../../../services/api/finance/company-gateway.service';
import {MatDialog} from '@angular/material/dialog';
import {RbacService} from '../../../../../../rbac/rbac.service';
import {Router} from '@angular/router';
import {ZipiFinancialIframeDialogComponent} from '../../dialogs/zipi-financial-iframe-dialog/zipi-financial-iframe-dialog.component';
import {NotificationsServiceZipi} from '../../../../../../notifications/notifications.service';
import {FeatureFlagsService} from '../../../../../../feature-flags/feature-flags.service';
import {ZipiFinancialTransferMoneyDialogComponent} from '../../dialogs/zipi-financial-transfer-money-dialog/zipi-financial-transfer-money-dialog.component';
import {ZipiFinGatewayEditDialogComponent} from '../../dialogs/zipi-fin-gateway-edit-dialog/zipi-fin-gateway-edit-dialog.component';
import {ConfirmComponent} from '../../../../../../../layouts/confirm/confirm.component';
import {CompanyPaymentMethodsService} from '../../../../../../../services/api/finance/company-payment-methods.service';
import {AuthService} from '../../../../../../../services/auth.service';
import {IZipiFinancialIframeDialogData} from '../../../../../../../typings/zipi-financial-iframe';
import {MuhneeAddBankAccountDialogComponent} from '../../dialogs/muhnee-add-bank-account-dialog/muhnee-add-bank-account-dialog.component';
import {MuhneeAddBankAccountHostedDialogComponent} from '../../dialogs/muhnee-add-bank-account-hosted-dialog/muhnee-add-bank-account-hosted-dialog.component';

@Component({
    selector: 'app-zipi-fin-gateway-container',
    templateUrl: './zipi-fin-gateway-container.component.html',
    styleUrls: ['./zipi-fin-gateway-container.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ZipiFinGatewayContainerComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() currentGateway: IPaymentGateway | (IPaymentGateway & {is_suspended: boolean}) | undefined;
    @Input() showFinalPopup: {
        gateway_id: number;
        payment_method_id: number | null;
        popup_type: 'final' | 'ledger';
    } | null = null;
    @Input() operatingGateways: IPaymentGateway[] | undefined;
    @Input() trustGateways: IPaymentGateway[] | undefined;
    @Input() isTabChanged: Subject<string> | undefined;

    @Output() reload = new EventEmitter<any>();
    @Output() showFinPopupEvent: EventEmitter<{
        gateway_id: number;
        payment_method_id: number | null;
        popup_type: 'final' | 'ledger';
    }> = new EventEmitter<any>();
    @Output() cleanFinalPopupInfo = new EventEmitter<any>();
    @Output() setupStarted = new EventEmitter<any>();

    zipiFinancialGatewayOwnerInfo: ({[key: string]: any} & {representatives: Array<{[key: string]: any}>}) | undefined;

    activeSection: 'log' | 'info' | null = null;
    logButtonDisabled: boolean;
    infoButtonDisabled: boolean;

    // feature_flags
    isMoneyTransfersFeatureFlagEnabled: boolean = false;
    isMoovCreditCardFeatureFlagEnabled: boolean = false;
    // isActivityFeatureFlagEnabled: boolean = false;
    isActivityGroupBasedFeatureFlagEnabled: boolean = false;
    isGatewayCanBeDeleted: boolean = false;
    isMuhneeHostedIntegrationFeatureFlagEnabled: boolean = false;
    isMuhneeHostedBankAccountConnectionFeatureFlagEnabled: boolean = false;

    constructor(
        private companyGatewayService: CompanyGatewayService,
        private companyPaymentMethodsService: CompanyPaymentMethodsService,
        protected dialog: MatDialog,
        protected rbacService: RbacService,
        protected router: Router,
        private notificationServiceZipi: NotificationsServiceZipi,
        protected featureFlagsService: FeatureFlagsService,
        protected authService: AuthService
    ) {
        this.logButtonDisabled = true;
        this.infoButtonDisabled = true;
    }

    ngOnInit() {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.isMoneyTransfersFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:money_transfer'
                );
                this.isMoovCreditCardFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:moov_credit_card'
                );
                // this.isActivityFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled('marketplace:addons:zipi_financial:activity');
                this.isGatewayCanBeDeleted = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:delete_gateway'
                );
                this.isActivityGroupBasedFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:transfer_log'
                );
                this.isMuhneeHostedIntegrationFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:muhnee_hosted_integration'
                );
                this.isMuhneeHostedBankAccountConnectionFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:muhnee_hosted_bank_connection'
                );
                this.setupSections();
            });
        this.rbacService.isAllowed({marketplace__manage_zipipay: true}).then((can) => {
            if (!can) {
                this.router.navigate(['/']);
            }
        });
    }

    setupSections() {
        if (this.currentGateway && this.currentGateway.payment_gateway_id) {
            this.companyGatewayService
                .getZipiFinancialGatewayDetails(this.currentGateway.payment_gateway_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((info) => {
                    this.zipiFinancialGatewayOwnerInfo = info as any;
                });
            if (!this.isActivityGroupBasedFeatureFlagEnabled) {
                this.activeSection = 'info';
            } else {
                if (['active', 'inactive'].includes(this.currentGateway.status)) {
                    this.activeSection = 'log';
                    this.infoButtonDisabled = false;
                    this.logButtonDisabled = false;
                } else {
                    this.activeSection = 'info';
                }
            }
        }
    }

    addPaymentMethod(gateway: IPaymentGateway, fromRedirect: boolean, type: 'bank' | 'card') {
        if (gateway.driver_type === 'payload') {
            const dialogRef = this.dialog.open<ZipiFinancialIframeDialogComponent, IZipiFinancialIframeDialogData>(
                ZipiFinancialIframeDialogComponent,
                {
                    disableClose: true,
                    maxHeight: '80vh',
                    width: '650px',
                    // height: '800px',
                    panelClass: 'custom-dialog-container',
                    data: {
                        contactId: null,
                        gateway: gateway,
                        accessMethod: 'internal',
                        prefillData: null,
                        paymentMethod: null,
                        driverType: 'payload',
                        methodType: type,
                        isUniversal: false,
                        customerId: null,
                        creationSource: 'current_company',
                        storeAccountMethod: 'required',
                        disallowSetupLedger: true
                    }
                }
            );

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((result) => {
                    if (result.isError) {
                        this.notificationServiceZipi.addError(result.message);
                    } else if (result.success && gateway.payment_gateway_id) {
                        this.showFinPopupEvent.emit({
                            gateway_id: gateway.payment_gateway_id,
                            payment_method_id: result.data.payment_method_id,
                            popup_type: 'ledger'
                        });
                    }
                });
        } else if (gateway.driver_type === 'muhnee') {
            if (this.isMuhneeHostedIntegrationFeatureFlagEnabled) {
                const dialogRef = this.dialog.open<
                    MuhneeAddBankAccountHostedDialogComponent,
                    {gateway: IPaymentGateway}
                >(MuhneeAddBankAccountHostedDialogComponent, {
                    disableClose: true,
                    maxHeight: '80vh',
                    width: '700px',
                    // height: '800px',
                    panelClass: 'hide-dialog-padding',
                    data: {
                        gateway: gateway
                    }
                });

                dialogRef
                    .afterClosed()
                    .pipe(
                        filter((pn) => !!pn),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe((result) => {
                        if (result.isError) {
                            this.notificationServiceZipi.addError(result.message);
                        } else if (result.success) {
                            this.reload.emit(true);
                        }
                    });
            } else {
                const dialogRef = this.dialog.open<MuhneeAddBankAccountDialogComponent, {gateway: IPaymentGateway}>(
                    MuhneeAddBankAccountDialogComponent,
                    {
                        // disableClose: true,
                        maxHeight: '80vh',
                        width: '650px',
                        // height: '800px',
                        data: {
                            gateway: gateway
                        }
                    }
                );

                dialogRef
                    .afterClosed()
                    .pipe(
                        filter((pn) => !!pn),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe((result) => {
                        if (result.isError) {
                            this.notificationServiceZipi.addError(result.message);
                        } else if (result.success) {
                            this.reload.emit(true);
                        }
                    });
            }
        }
    }

    newTransfer() {
        const dialogRef = this.dialog.open(ZipiFinancialTransferMoneyDialogComponent, {
            width: '650px',
            data: {}
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {});
    }

    editGateway(currentGateway: IPaymentGateway) {
        const dialogRef = this.dialog.open(ZipiFinGatewayEditDialogComponent, {
            // width: '650px',
            minWidth: '400px',
            data: {
                gateway: currentGateway
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result && currentGateway && currentGateway.payment_gateway_id) {
                    this.companyGatewayService
                        .updateCompanyGatewayById(currentGateway.payment_gateway_id, result)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((response) => {
                            this.reload.emit(true);
                        });
                }
            });
    }

    async disconnectGateway(zipiFinancialGateway: IPaymentGateway) {
        let isTrustExist = false;
        let isLastOperating = false;
        if (zipiFinancialGateway && zipiFinancialGateway.payment_gateway_id) {
            if (['zipi_financial_business'].includes(zipiFinancialGateway.type)) {
                isTrustExist = !!this.trustGateways && this.trustGateways.length > 0;
                isLastOperating =
                    !!this.operatingGateways &&
                    (this.operatingGateways.length === 1 ||
                        (this.operatingGateways.length > 1 &&
                            !this.operatingGateways.some(
                                (gateway) =>
                                    gateway.payment_gateway_id !== zipiFinancialGateway.payment_gateway_id &&
                                    gateway.status === 'active'
                            )));
            }
            if (isTrustExist && isLastOperating) {
                const rejectDialogRef = this.dialog.open(ConfirmComponent, {
                    minWidth: 320,
                    minHeight: 160,
                    data: {
                        title: `Deleting Money Transfer Account`,
                        message: `All 'Trust' account types must be removed prior to removing the remaining 'Operating' account.`,
                        hideCancel: true
                    }
                });

                rejectDialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((confirmed: boolean) => {});
            } else {
                let balance = 0;
                const balanceAccount = zipiFinancialGateway.payment_methods?.find(
                    (pm) => pm.type === 'zipi_financial_balance'
                );
                if (balanceAccount && balanceAccount.payment_method_ref && balanceAccount.payment_method_id) {
                    balance = await firstValueFrom(
                        this.companyPaymentMethodsService.refreshBalanceByMethodId(balanceAccount.payment_method_id)
                    );
                }

                if (balance > 0) {
                    const rejectDialogRef = this.dialog.open(ConfirmComponent, {
                        minWidth: 320,
                        minHeight: 160,
                        data: {
                            title: `Deleting Money Transfer Account`,
                            message: `You have funds in your Balance Account. Please transfer money from the Balance Account before deleting the Money Transfer Account.`,
                            hideCancel: true
                        }
                    });

                    rejectDialogRef
                        .afterClosed()
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((confirmed: boolean) => {});
                } else {
                    const confirmDialogRef = this.dialog.open(ConfirmComponent, {
                        minWidth: 320,
                        minHeight: 160,
                        data: {
                            title: `Deleting Money Transfer Account`,
                            message: `Money Transfer Account will be deleted`,
                            buttonOkMessage: `Continue`
                        }
                    });

                    confirmDialogRef
                        .afterClosed()
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((confirmed: boolean) => {
                            if (confirmed && zipiFinancialGateway && zipiFinancialGateway.payment_gateway_id) {
                                this.companyGatewayService
                                    .deleteCompanyGatewayById(zipiFinancialGateway.payment_gateway_id)
                                    .pipe(takeUntil(this.unsubscribe))
                                    .subscribe((response) => {
                                        this.reload.emit(true);
                                    });
                            }
                        });
                }
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
