<div>
    <div *ngIf="retryBusinessForm">
        <app-notifications [notification_mode]="'popup'"></app-notifications>
    </div>
    <h2 *ngIf="errorFields && errorFields.length > 0">Retry Verification</h2>
    <h2 *ngIf="!errorFields || errorFields.length === 0">Edit Submitted Information</h2>
    <div class="mx-2 my-3" style="font-size: 14px">
        <div *ngIf="zipiFinancialCustomer.customer_type === 'business'">
            We do not store the following personally identifiable information: EIN.
        </div>
        <div *ngIf="zipiFinancialCustomer.customer_type === 'individual'">
            We do not store the following personally identifiable information: Date Of Birth, Last four digits of SSN.
        </div>
        <div>Please reenter this information prior to resubmitting.</div>
    </div>

    <div *ngIf="zipiFinancialCustomer">
        <div>
            <div
                *ngIf="zipiFinancialCustomer.customer_type === 'business' && retryBusinessForm"
                [formGroup]="retryBusinessForm"
            >
                <div class="my-1 w-100">
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="business_name" />
                        <mat-placeholder>Legal Business Name</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('legal_business_name')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Legal Business Name'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>

                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="ein" />
                        <mat-placeholder>Employer Identification Number</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('ein')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Employer Identification Number'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                </div>

                <div class="my-1 w-100">
                    <mat-form-field class="w-50 px-2" *ngIf="zipiFinancialCustomer.website">
                        <input matInput type="text" formControlName="web" required />
                        <mat-placeholder>Website URL</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('description-or-website')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Website URL'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                    <mat-form-field class="w-50 px-2" *ngIf="zipiFinancialCustomer.description">
                        <input matInput type="text" formControlName="description" required />
                        <mat-placeholder>Description</mat-placeholder>
                        <mat-error *ngIf="retryBusinessForm.controls.description.invalid"
                            >Must be more than 10 and less than 100 characters</mat-error
                        >
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('description-or-website')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Description'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                </div>

                <mat-form-field class="w-50 px-2 my-1">
                    <input
                        type="text"
                        matInput
                        [formControl]="industryTitle"
                        placeholder="Industry"
                        autocomplete="off"
                        [matAutocomplete]="autoGroup"
                    />
                    <mat-autocomplete #autoGroup="matAutocomplete">
                        <ng-container *ngIf="industries.length > 0">
                            <ng-container *ngFor="let industry of filteredIndustries | async">
                                <mat-option
                                    [value]="industry.title"
                                    (onSelectionChange)="selectIndustry($event, industry)"
                                >
                                    {{ industry.title }}
                                </mat-option>
                            </ng-container>
                        </ng-container>
                    </mat-autocomplete>
                    <span matSuffix style="color: red" *ngIf="errorFields.includes('industry')">
                        <mat-icon
                            [matTooltip]="'Please verify and re-enter Industry'"
                            style="font-size: 20px; cursor: help"
                            >warning</mat-icon
                        >
                    </span>
                </mat-form-field>

                <div class="my-1 w-100">
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="address_line_1" />
                        <mat-placeholder>Address Line 1 (No P.O. Box please)</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('address')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Address'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                    <mat-form-field class="w-50 px-2">
                        <input matInput type="text" formControlName="address_line_2" />
                        <mat-placeholder>Address Line 2</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('address')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Address'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                </div>

                <div class="my-1 w-100">
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="city" />
                        <mat-placeholder>City</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('address')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter City'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                    <mat-form-field class="w-50 px-2">
                        <mat-select required formControlName="state">
                            <mat-option *ngFor="let state of stateProvinceRegionList" [value]="state.slug">{{
                                state.title
                            }}</mat-option>
                        </mat-select>
                        <mat-placeholder>State</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('address')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter State'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                </div>

                <div class="my-1 w-100">
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="postalCode" />
                        <mat-placeholder>Zip (ex. 12345)</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('address')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Zip'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>

                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="phone_number" />
                        <mat-placeholder>Phone number (10 digits)</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('phone')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Phone number'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                </div>
            </div>

            <div
                *ngIf="zipiFinancialCustomer.customer_type === 'individual' && retryIndividualForm"
                [formGroup]="retryIndividualForm"
            >
                <div class="my-1 w-100">
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="first_name" />
                        <mat-placeholder>First Name</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('first_name')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter First Name'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="last_name" />
                        <mat-placeholder>Last Name</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('last_name')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Last Name'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                </div>

                <div class="d-flex my-1 w-100">
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="email" />
                        <mat-placeholder>Email</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('email')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Email'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                    <div class="d-flex w-50 px-2">
                        <app-date-picker
                            class="w-100"
                            [placeholder]="'Date Of Birth'"
                            [dateControl]="retryIndividualForm.controls.date_of_birth"
                            [startDate]="startDate"
                            [startView]="'year'"
                        ></app-date-picker>
                    </div>
                </div>

                <div class="my-1 w-100">
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="ssn" />
                        <mat-placeholder>Last four digits of SSN</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('ssn')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Last four digits of SSN'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                </div>

                <div class="my-1 w-100">
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="address_line_1" />
                        <mat-placeholder>Address Line 1 (No P.O. Box please)</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('address')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Address'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                    <mat-form-field class="w-50 px-2">
                        <input matInput type="text" formControlName="address_line_2" />
                        <mat-placeholder>Address Line 2</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('address')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Address'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                </div>

                <div class="my-1 w-100">
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="city" />
                        <mat-placeholder>City</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('address')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter City'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                    <mat-form-field class="w-50 px-2">
                        <mat-select required formControlName="state">
                            <mat-option *ngFor="let state of stateProvinceRegionList" [value]="state.slug">{{
                                state.title
                            }}</mat-option>
                        </mat-select>
                        <mat-placeholder>State</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('address')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter State'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                </div>

                <div class="my-1 w-100">
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="postalCode" />
                        <mat-placeholder>Zip (ex. 12345)</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('address')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Zip'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="phone_number" />
                        <mat-placeholder>Phone number (10 digits)</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('email-or-mobile')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Phone number'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-end">
        <button *ngIf="!data.hideCancel" mat-raised-button class="mr-2" (click)="dialogRef.close(false)" tabindex="-1">
            Cancel
        </button>
        <button mat-raised-button color="primary" (click)="continue()" [disabled]="lockButton" tabindex="1">
            Resubmit Details
        </button>
    </div>
</div>
