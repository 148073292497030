import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IFranchiseState} from './franchise.reducer';

export const selectSettingsFeature = createFeatureSelector<IFranchiseState>('franchise');

export const selectFranchiseRequestsChildFromMe = createSelector(selectSettingsFeature, (state: IFranchiseState) =>
    state.requestsFromMe.filter((req) => req.type === 'request_to_child')
);

export const selectFranchiseRequestsChildToMe = createSelector(selectSettingsFeature, (state: IFranchiseState) =>
    state.requestsToMe.filter((req) => req.type === 'request_to_child')
);

export const selectFranchiseRequestsParentFromMe = createSelector(selectSettingsFeature, (state: IFranchiseState) =>
    state.requestsFromMe.filter((req) => req.type === 'request_to_parent')
);

export const selectFranchiseRequestsParentToMe = createSelector(selectSettingsFeature, (state: IFranchiseState) =>
    state.requestsToMe.filter((req) => req.type === 'request_to_parent')
);
