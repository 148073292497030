<section class="container-fluid">
    <header class="py-2">
        <h1 *ngIf="!recurringJournal" class="">Create Recurring Journal</h1>
        <h1 *ngIf="recurringJournal" class="">Edit Recurring Journal</h1>
    </header>
    <div>
        <form [formGroup]="recurringFormGroup">
            <mat-form-field class="col col-sm-6">
                <input matInput type="text" placeholder="Title" formControlName="recurrence_title" autocomplete="off" />
                <mat-error *ngIf="recurringFormGroup.controls.recurrence_title.hasError('required')"
                    >required</mat-error
                >
            </mat-form-field>

            <mat-form-field
                class="col"
                [ngClass]="{
                    'col-sm-6':
                        !recurringFormGroup.controls.repeat.value ||
                        recurringFormGroup.controls.repeat.value?.period !== 'custom',
                    'col-sm-3': recurringFormGroup.controls.repeat.value?.period === 'custom'
                }"
            >
                <mat-select formControlName="repeat" placeholder="Repeat Every" [compareWith]="comparePeriod">
                    <mat-option *ngFor="let period of repeatPeriod" [value]="period[1]">
                        {{ period[0] | titlecase }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-sm-3" *ngIf="recurringFormGroup.controls.repeat.value?.period === 'custom'">
                <input matInput type="text" placeholder="Number of days" formControlName="repeat_custom_days" />
            </mat-form-field>

            <app-date-picker
                class="d-inline-block col-6"
                [placeholder]="'Start On'"
                [dateControl]="recurringFormGroup.controls.start_on"
            ></app-date-picker>

            <app-date-picker
                class="d-inline-block col-6"
                [isRequired]="false"
                [placeholder]="'End On'"
                [dateControl]="recurringFormGroup.controls.end_on"
            ></app-date-picker>
        </form>

        <mat-divider></mat-divider>

        <!--        <div class="col-12 my-3">-->
        <!--            <div class="d-flex align-items-center justify-content-end mb-3">-->
        <!--                <button mat-raised-button [matMenuTriggerFor]="menu">Choose Template</button>-->
        <!--                <mat-menu #menu="matMenu">-->
        <!--                    <ng-container *ngFor="let template of templates">-->
        <!--                        <button mat-menu-item (click)="patchTemplate(template)">-->
        <!--                            {{template.title}}-->
        <!--                        </button>-->
        <!--                    </ng-container>-->
        <!--                </mat-menu>-->

        <!--                <button type="button" mat-icon-button matSuffix-->
        <!--                        [routerLink]="['/company/finance/journaltemplates/create']"-->
        <!--                        matTooltip="Create Template">-->
        <!--                    <mat-icon>add</mat-icon>-->
        <!--                </button>-->
        <!--            </div>-->
        <!--            <mat-divider></mat-divider>-->
        <!--        </div>-->

        <app-journal-template
            (onUpdate)="onTemplateUpdate($event)"
            [journalTemplate]="journalTemplateBase"
            [closeEvent]="closeEvent"
        ></app-journal-template>

        <footer class="text-right my-2">
            <button
                type="submit"
                class="ml-2"
                color="warn"
                mat-raised-button
                *ngIf="recurringJournal"
                (click)="deleteRecurringJournal()"
            >
                Delete
            </button>

            <span class="sp-spacer"></span>

            <button mat-raised-button class="ml-2" (click)="router.navigate(['/company/finance/journals'])">
                Cancel
            </button>

            <button
                type="submit"
                class="ml-2"
                mat-raised-button
                color="primary"
                *ngIf="!recurringJournal"
                [disabled]="!recurringFormGroup.valid || !maybeValidJournal.valid"
                (click)="createRecurringJournal()"
            >
                Create
            </button>
            <button
                type="submit"
                class="ml-2"
                mat-raised-button
                color="primary"
                *ngIf="recurringJournal"
                [disabled]="!recurringFormGroup.valid || !maybeValidJournal.valid"
                (click)="updateRecurringJournal()"
            >
                Save
            </button>
        </footer>
    </div>
</section>

<ng-content></ng-content>
