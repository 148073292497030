import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatStepper} from '@angular/material/stepper';
import {UntypedFormControl, Validators} from '@angular/forms';
import {SessionService} from '../../../services/session.service';
import {AuthService} from '../../../services/auth.service';
import firebase from 'firebase/compat/app';
import {PickProfileFourmGroup, SignInFormGroup} from './add-existing-company.forms';
import {NotificationsServiceZipi} from '../../../modules/notifications/notifications.service';
import {UserService} from '../../../services/user.service';
import {Profile} from '../../../models/profile';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import AuthCredential = firebase.auth.AuthCredential;

@Component({
    selector: 'app-add-existing-company-dialog',
    styles: [
        `
            .row.footer {
                justify-content: space-between;
                flex-direction: row-reverse;
                flex: initial;
            }

            .col {
                min-width: 400px;
                min-height: 300px;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }
        `
    ],
    template: `
        <div class="col">
            <div class="header">Add Existing Company</div>
            <app-notifications></app-notifications>
            <div>
                <mat-stepper orientation="horizontal" [linear]="isLinear" #stepper>
                    <mat-step [stepControl]="firstFormGroup">
                        <ng-template matStepLabel>Confirm Log In</ng-template>
                        <app-add-existing-company-step-1
                            [firstFormGroup]="firstFormGroup"
                            (confirm)="firstStepConfirm()"
                        >
                        </app-add-existing-company-step-1>
                    </mat-step>
                    <mat-step [stepControl]="secondFormGroup">
                        <ng-template matStepLabel>Log in as</ng-template>
                        <app-add-existing-company-step-2
                            [secondFormGroup]="secondFormGroup"
                            (confirm)="secondStepLogIn()"
                        >
                        </app-add-existing-company-step-2>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>Pick company profile</ng-template>
                        <app-add-existing-company-step-3
                            [thirdFormGroup]="thirdFormGroup"
                            [availableProfiles]="availableProfiles"
                            [connected]="connectedIds"
                            (confirm)="thirdStepPickProfile()"
                        >
                        </app-add-existing-company-step-3>
                    </mat-step>
                </mat-stepper>
            </div>
            <div class="row footer">
                <button
                    mat-button
                    class="mat-primary"
                    (click)="dialogRef.close()"
                    *ngIf="firstFormGroup?.valid && secondFormGroup?.valid"
                >
                    Finish
                </button>
                <button mat-button class="mat-default" (click)="dialogRef.close()">Cancel</button>
            </div>
        </div>
    `
})
export class AddExistingCompanyDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('stepper', {static: true}) stepper: MatStepper | undefined;

    private firstStepCredentials: firebase.auth.AuthCredential | null = null;
    private firstStepProfile: Profile | null = null;

    public availableProfiles: Profile[] = [];

    public connectedIds: number[] = [];

    isLinear = true;

    firstFormGroup: SignInFormGroup | undefined;
    secondFormGroup: SignInFormGroup | undefined;
    thirdFormGroup: PickProfileFourmGroup | undefined;

    ngOnInit() {
        this.connectedIds = this.sessionService.user?.connected_profiles_ids || [];
        this.firstFormGroup = new SignInFormGroup(this.sessionService.user?.email || '');
        this.secondFormGroup = new SignInFormGroup('');

        this.thirdFormGroup = new PickProfileFourmGroup({
            pickedProfile: new UntypedFormControl('', Validators.required),
            submit: new UntypedFormControl(false, Validators.requiredTrue)
        });
    }

    firstStepConfirm() {
        if (
            typeof this.firstFormGroup === 'undefined' ||
            !this.firstFormGroup.valid ||
            !this.firstFormGroup.getRawValue().email ||
            !this.firstFormGroup.getRawValue().password
        ) {
            return;
        }
        const tryCredentials = this.authService.generateCredentials(
            this.firstFormGroup.getRawValue().email as string,
            this.firstFormGroup.getRawValue().password as string
        );
        this.authService.afAuth
            .signInWithCredential(tryCredentials)
            .then(() => {
                this.firstStepCredentials = tryCredentials;
                if (typeof this.stepper !== 'undefined') {
                    this.stepper.selected!.editable = false;
                    this.stepper.next();
                }
                return this.authService.getCurrentProfile().then((profile) => {
                    this.firstStepProfile = profile;
                    return this.authService.logout();
                });
            })
            .catch((err) => {
                this.notificationService.addError(err.message);
            });
    }

    secondStepLogIn() {
        if (
            typeof this.secondFormGroup === 'undefined' ||
            !this.secondFormGroup.valid ||
            !this.secondFormGroup.getRawValue().email ||
            !this.secondFormGroup.getRawValue().password
        ) {
            return;
        }

        if (
            typeof this.firstFormGroup !== 'undefined' &&
            this.secondFormGroup.getRawValue().email === this.firstFormGroup.getRawValue().email
        ) {
            this.notificationService.addError('Must log in as another user');
            return;
        }

        const tryCredentials = this.authService.generateCredentials(
            this.secondFormGroup.getRawValue().email as string,
            this.secondFormGroup.getRawValue().password as string
        );
        this.authService
            .signInWithCredential(tryCredentials)
            .then(() => {
                return this.authService.loadAuth();
            })
            .then(() => {
                return this.authService.getCurrentProfile().then((currentProfile) => {
                    return this.userService.getUserProfiles().then((res) => {
                        this.availableProfiles = res
                            .map((profile) => <Profile>profile)
                            .filter((profile) => profile.user?.id === this.sessionService.user?.id);
                        return;
                    });
                });
            })
            .then(() => {
                if (typeof this.stepper !== 'undefined') {
                    this.stepper.selected!.editable = false;
                    this.stepper.next();
                }
            })
            .catch((err) => {
                this.notificationService.addError(err.message);
            });
    }

    thirdStepPickProfile() {
        if (typeof this.thirdFormGroup === 'undefined' || !this.thirdFormGroup.valid || !this.firstStepProfile) {
            return;
        }
        this.userService
            .shareProfile(
                this.firstStepProfile.user?.id as number,
                this.thirdFormGroup.getRawValue().pickedProfile?.id as number
            )
            .then((res) => {
                this.connectedIds = res.connected_profiles_ids;
                this.thirdFormGroup?.controls['pickedProfile'].setValue('');
            });
    }

    constructor(
        public dialogRef: MatDialogRef<AddExistingCompanyDialogComponent>,
        public sessionService: SessionService,
        public authService: AuthService,
        public notificationService: NotificationsServiceZipi,
        public userService: UserService
    ) {
        dialogRef.beforeClosed().pipe(takeUntil(this.unsubscribe)).subscribe(this.onClose.bind(this));
    }

    onClose() {
        if (this.firstStepCredentials !== null) {
            return this.authService
                .logout()
                .then(() => {
                    return this.authService.afAuth
                        .signInWithCredential(this.firstStepCredentials as AuthCredential)
                        .then(() => {
                            return this.authService.setCurrentProfile(this.firstStepProfile as Profile, true);
                        });
                })
                .catch((err) => {
                    this.notificationService.addError(err.message);
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
