import {Component, OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl} from '@angular/forms';
import {Store, select} from '@ngrx/store';
import {isEqual, get as getProp} from 'lodash-es';
import {IInvoiceItem, IRecurringInvoice, IProduct, IPaymentGateway, IGroup} from '@cyberco-nodejs/zipi-typings';
import {pipe, Subject, combineLatest, ReplaySubject} from 'rxjs';
import {takeUntil, tap, map} from 'rxjs/operators';
import {IMaybeValidInvoiceTemplate} from '../edit-recurring-invoice/edit-recurring-invoice.component';
import {IFinanceState} from '../../../store/finance.reducer';
import {selectProducts} from 'app/store/root.selectors';
import {PAYMENT_TERMS} from 'app/local-typings';
import {FetchProducts} from '../../../store/finance.actions';
import {PaymentMethodsService} from '../../../../profile/services/payment-methods.service';
import {MatDialog} from '@angular/material/dialog';
import {CreateProductDialogComponent} from '../../products/create-product-dialog/create-product-dialog.component';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {GenericFormArray} from 'app/entites/generic.entity';
import {ContactCreateDialogComponent} from '../../../../contacts/contact-dialogs/contact-create-dialog/contact-create-dialog.component';
import {GroupsSource} from 'app/services/sources/groups.source';
import {FormGroupArray, FormGroupWithFormControls} from '../../../../../typings/common';
import {
    currencyMaskitoOptions,
    numberMaskitoOptions,
    unmaskCurrencyControlValue
} from '../../../../../utilities/maskito';

@Component({
    selector: 'app-recurring-invoice-template',
    templateUrl: 'recurring-invoice-template.component.html',
    styleUrls: ['recurring-invoice-template.component.scss']
})
export class RecurringInvoiceTemplateComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private gotInvoice = new ReplaySubject<IRecurringInvoice>(1);

    @Output() onUpdate = new EventEmitter<IMaybeValidInvoiceTemplate>();

    @Input() set invoice(inv: IRecurringInvoice | undefined) {
        if (!inv) {
            return;
        }
        this._invoice = inv;
        this.gotInvoice.next(inv);
    }
    @Input() recurring_invoice_id: number | undefined;

    get invoice(): IRecurringInvoice | undefined {
        return this._invoice;
    }

    public _invoice: IRecurringInvoice | undefined;

    itemsArray: FormGroupArray = this.fb.array([this.createItem()]) as FormGroupArray;
    moneySendersArray = this.fb.array([]);
    formGroup: FormGroupWithFormControls = this.fb.group({
        sales_person__profile_fk_id: null, // Sales Person
        payment_terms: ['due-on-receipt', Validators.required],
        payment_terms_custom: null,
        auto_charge_terms: ['due-on-receipt', Validators.required],
        auto_charge_terms_custom: null,
        total_amount: [{value: 0, disabled: true}],
        pay_to__payment_method_fk_ids: [[], []],
        pay_to_card__payment_method_fk_id: [null, []],
        pay_to_bank__payment_method_fk_id: [null, []],
        items: this.itemsArray,
        money_senders__company_group_fk_id: null,
        money_sender__contact_fk_id: null,
        money_sender__contact_class_fk_id: null,
        money_senders: this.moneySendersArray,
        is_deduct_from_deal: [true, []],
        late_fee: [0, []],
        late_fee_product_fk_id: null,
        wildcard_usage_mode: ['skip', []],
        bank_fee_payer: ['money_sender', []],
        card_fee_payer: ['money_sender', []]
    }) as FormGroupWithFormControls;

    hasWildcardSelected: boolean = false;

    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    savedContacts: number[] = [];
    selectedProfileArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    selectedProfileIds: number[] = [];
    savedContactClasses: number[] = [];
    savedGroups: number[] = [];

    emptyItem = {rate: 0, quantity: 1, amount: 0};

    currencyMaskitoMask = currencyMaskitoOptions;
    numberMaskitoMask = numberMaskitoOptions;
    amountWithCurrencyMask: boolean[] = [];
    rateWithCurrencyMask: boolean[] = [];

    products: IProduct[] | undefined;

    paymentTerms = PAYMENT_TERMS;

    companyGateways: Array<IPaymentGateway> | undefined;

    showGroupDetails: boolean;

    filterForm: UntypedFormGroup | undefined;

    isAllLoaded = false;

    lastGroupId: number | null = null;

    groupList: IGroup[] = [];

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store<IFinanceState>,
        private paymentMethodsService: PaymentMethodsService,
        public dialog: MatDialog,
        private groupSrc: GroupsSource
    ) {
        this.showGroupDetails = false;
    }

    isEqual: (o1: any, o2: any) => boolean = isEqual;

    private createItem(item?: IInvoiceItem, itemIndex: number = 0) {
        const group = this.fb.group(
            {
                product_fk_id: [item ? item.product_fk_id : null],
                name: [{value: item ? item.name : '', disabled: item && item.product_fk_id}, Validators.required],
                description: item ? item.description : '',
                quantity: [item ? item.quantity : 1, [Validators.required, Validators.min(1)]],
                rate: [{value: item ? item.rate : 0, disabled: false}],
                rate__wildcard_fk_id: [
                    {
                        value: item && item.rate__wildcard_fk_id ? item.rate__wildcard_fk_id : null,
                        disabled: false
                    }
                ],
                quantity__wildcard_fk_id: [
                    {
                        value: item && item.quantity__wildcard_fk_id ? item.quantity__wildcard_fk_id : null,
                        disabled: false
                    }
                ],
                amount: [{value: item ? item.amount : 0, disabled: true}],
                ledger_account_fk_id: [item ? item.ledger_account_fk_id : null, Validators.required],
                division__company_group_fk_id: [
                    {
                        value: item ? item.division__company_group_fk_id : this.lastGroupId,
                        disabled: !!this.lastGroupId
                    }
                ],
                selected_divisions: new GenericFormArray<ChipNode>([]),
                selected_division_ids: [item ? item.division__company_group_fk_id : null],
                selected_full_ledger: null
            },
            {validator: this.customAmountValidator}
        );

        group.controls.selected_divisions.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (changes && changes.length > 0) {
                group.controls.division__company_group_fk_id.setValue(changes[0].target_id);
            } else {
                group.controls.division__company_group_fk_id.setValue(null);
            }
        });

        const quantityControl = group.controls.quantity;
        const rateControl = group.controls.rate;
        const rateWildcardIdControl = group.controls.rate__wildcard_fk_id;
        const quantityWildcardIdControl = group.controls.quantity__wildcard_fk_id;

        if (quantityControl && rateControl) {
            const handleValueChanges = pipe(
                takeUntil(this.unsubscribe),
                tap(() => {
                    group.controls.amount!.patchValue(quantityControl.value * rateControl.value);
                }),
                tap(() => {
                    this.amountWithCurrencyMask[itemIndex] = String(group.controls.amount.value).length < 13;
                })
            );
            const unmaskQtyFn = unmaskCurrencyControlValue(quantityControl);
            const unmaskRateFn = unmaskCurrencyControlValue(rateControl);

            quantityControl.valueChanges
                .pipe(
                    unmaskQtyFn,
                    handleValueChanges,
                    tap(() => this.computeTotal()),
                    takeUntil(this.unsubscribe)
                )
                .subscribe();
            rateControl.valueChanges
                .pipe(
                    unmaskRateFn,
                    handleValueChanges,
                    tap(() => this.computeTotal()),
                    tap(() => {
                        this.rateWithCurrencyMask[itemIndex] = String(group.controls.rate.value).length < 13;
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe();
        }

        if (rateWildcardIdControl) {
            rateWildcardIdControl.valueChanges
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => this.checkHasWildcard());
        }

        if (quantityWildcardIdControl) {
            quantityWildcardIdControl.valueChanges
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => this.checkHasWildcard());
        }

        return group;
    }

    customAmountValidator = (formGroup: UntypedFormGroup) => {
        const rateControl = formGroup.controls.rate;

        let value = null;
        if (rateControl) {
            value = rateControl.value;
        }

        const rateWildcardControl = formGroup.controls.rate__wildcard_fk_id;
        let wildcardId = null;
        if (rateWildcardControl) {
            wildcardId = rateWildcardControl.value;
        }
        if (!!wildcardId) {
            return null;
        }
        if (value === null || value === undefined) {
            return {invalidValue: true};
        }
        if (isNaN(Number(value))) {
            return {invalidValue: true};
        }
        if (Number(value) <= 0) {
            return {invalidValue: true};
        }
        return null;
    };

    addItem(item?: IInvoiceItem) {
        this.itemsArray.push(this.createItem(item, this.itemsArray.length));
    }

    selectItem(itemGroup: UntypedFormGroup, product: IProduct) {
        itemGroup.controls.product_fk_id!.patchValue(product.product_id);
        itemGroup.controls.rate!.patchValue(product.price);
        itemGroup.controls.rate__wildcard_fk_id!.patchValue(null);
        itemGroup.controls.description!.patchValue(product.description);
        itemGroup.controls.ledger_account_fk_id!.patchValue(product.ledger_account_fk_id);
        itemGroup.controls.quantity!.patchValue(1);
        itemGroup.controls.quantity__wildcard_fk_id!.patchValue(null);

        itemGroup.controls.ledger_account_fk_id!.disable();
        itemGroup.controls.name!.disable();
    }

    removeProductFkId(itemGroup: UntypedFormGroup) {
        itemGroup.controls.ledger_account_fk_id!.enable();
        itemGroup.controls.name!.enable();
        itemGroup.controls.name!.patchValue(null);
        itemGroup.controls.product_fk_id!.patchValue(null);
    }

    resetItem(itemGroup: UntypedFormGroup) {
        itemGroup.reset(this.emptyItem);
        itemGroup.controls.product_fk_id!.patchValue(null);
        itemGroup.controls.name!.patchValue(null);
        itemGroup.controls.description!.patchValue(null);
        itemGroup.controls.ledger_account_fk_id!.patchValue(null);
        itemGroup.controls.name!.enable();
        itemGroup.controls.ledger_account_fk_id!.enable();
    }

    trimOnBlur(control: AbstractControl) {
        if (control.value) {
            const trimmedValue = control.value.trim().length === 0 ? null : control.value.trim();
            control.patchValue(trimmedValue);
        }
    }

    private computeTotal = () => {
        if (this.hasWildcardSelected) {
            this.formGroup.controls.total_amount!.patchValue(0);
        } else {
            const total = this.itemsArray.controls!.reduce<number>(
                (acc, itemGroup: UntypedFormGroup) => itemGroup.controls.amount!.value + acc,
                0
            );
            this.formGroup.controls.total_amount!.patchValue(total);
        }
    };

    private checkHasWildcard = () => {
        const hasWildcardSelected = this.itemsArray
            .getRawValue()
            .find((item) => !!item.rate__wildcard_fk_id || !!item.quantity__wildcard_fk_id);

        this.hasWildcardSelected = !!hasWildcardSelected;
    };

    initEdit(invoice: IRecurringInvoice) {
        if (!invoice.wildcard_usage_mode) {
            invoice.wildcard_usage_mode = 'skip';
        }
        this.formGroup.patchValue(invoice);

        const items = getProp(invoice, 'items');
        if (!items || items.length === 0) {
            return;
        }
        this.itemsArray.removeAt(0);
        items.forEach((item) => this.addItem(item));

        if (invoice && invoice.money_senders__company_group_fk_id) {
            this.savedGroups = [invoice.money_senders__company_group_fk_id];
        } else if (invoice && invoice.money_sender__contact_fk_id) {
            this.savedContacts = [invoice.money_sender__contact_fk_id];
        } else if (invoice && invoice.money_sender__contact_class_fk_id) {
            this.savedContactClasses = [invoice.money_sender__contact_class_fk_id];
        }

        if (invoice && invoice.sales_person__profile_fk_id) {
            this.selectedProfileIds = [invoice.sales_person__profile_fk_id];
        }
    }

    contactCreate() {
        const dialogRef = this.dialog.open(ContactCreateDialogComponent, {
            autoFocus: false,
            data: {
                category: 'customer'
            }
        });

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    createProduct() {
        const dialogRef = this.dialog.open(CreateProductDialogComponent, {});

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    getProducts(item: AbstractControl) {
        const iemGroup: UntypedFormGroup = item as UntypedFormGroup;
        if (iemGroup.getRawValue().name && this.products) {
            const filteredValue = iemGroup.getRawValue().name.toLowerCase().trim();
            return this.products.filter((pr) => pr.name.toLowerCase().trim().includes(filteredValue));
        }

        return this.products;
    }

    ngOnInit() {
        this.store.dispatch(new FetchProducts());

        this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe)).subscribe((data) => {
            this.products = data;
        });

        this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() =>
            this.onUpdate.emit({
                valid: this.formGroup.valid,
                template: {
                    ...this.formGroup.getRawValue(),
                    wildcard_usage_mode: this.hasWildcardSelected
                        ? this.formGroup.getRawValue().wildcard_usage_mode
                        : null
                },
                association: null
            })
        );

        this.formGroup.controls.payment_terms.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            if (value === 'custom') {
                this.formGroup.controls.payment_terms_custom.setValidators([Validators.required, Validators.min(1)]);
                this.formGroup.controls.payment_terms_custom.updateValueAndValidity();
            } else {
                this.formGroup.controls.payment_terms_custom.clearValidators();
                this.formGroup.controls.payment_terms_custom.updateValueAndValidity();
            }
        });

        this.formGroup.controls.auto_charge_terms.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            if (value === 'custom') {
                this.formGroup.controls.auto_charge_terms_custom.setValidators([
                    Validators.required,
                    Validators.min(1)
                ]);
                this.formGroup.controls.auto_charge_terms_custom.updateValueAndValidity();
            } else {
                this.formGroup.controls.auto_charge_terms_custom.clearValidators();
                this.formGroup.controls.auto_charge_terms_custom.updateValueAndValidity();
            }
        });

        this.itemsArray.valueChanges
            .pipe(
                tap(() => this.computeTotal()),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.paymentMethodsService
            .getCompanyGateways()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((gateways) => {
                this.companyGateways = gateways.filter((gateway) =>
                    ['authorize_net_merchant', 'dwolla_business'].includes(gateway.type)
                );
            });

        this.moneySenderCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            if (values && values.length > 0) {
                if (values[0].type === 'contact') {
                    this.formGroup.controls.money_sender__contact_fk_id.setValue(values[0].target_id);
                } else if (values[0].type === 'individuals_in_group') {
                    this.formGroup.controls.money_senders__company_group_fk_id.setValue(values[0].target_id);
                } else if (values[0].type === 'contact_class') {
                    this.formGroup.controls.money_sender__contact_class_fk_id.setValue(values[0].target_id);
                }
            } else {
                this.formGroup.controls.money_senders__company_group_fk_id.reset(null, {emitEvent: false});
                this.formGroup.controls.money_sender__contact_fk_id.reset(null);
                this.formGroup.controls.money_sender__contact_class_fk_id.reset(null, {emitEvent: false});
            }
        });

        this.selectedProfileArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: any[]) => {
            if (values && values.length > 0) {
                if (values[0].type === 'individual') {
                    this.formGroup.controls.sales_person__profile_fk_id.setValue(values[0].target_id);
                }
            } else {
                this.formGroup.controls.sales_person__profile_fk_id.reset(null, {emitEvent: false});
            }
        });

        this.filterForm = this.fb.group({
            filterData: ''
        });

        combineLatest(this.gotInvoice)
            .pipe(
                map(([invoice]) => {
                    this.initEdit(invoice);
                    this.isAllLoaded = true;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.formGroup.controls.late_fee.valueChanges
            .pipe(unmaskCurrencyControlValue(this.formGroup.controls.late_fee), takeUntil(this.unsubscribe))
            .subscribe();

        this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
            this.groupList = list.filter((group) => group.type === 'division') as IGroup[];
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.gotInvoice.complete();
        this.onUpdate.complete();
    }
}
