<div>
    <div *ngIf="companies && companies.length > 0">
        <h3>Your Companies</h3>
        <mat-card *ngFor="let comp of companies" class="d-flex" style="margin: 2px">
            <div class="w-60">
                {{ comp.title }}
            </div>
            <div class="w-40 d-flex justify-content-end" *ngIf="depositRequest && currentContact">
                <mat-icon
                    *ngIf="!isUserCanAssociateDepositRequestToCompany(comp, depositRequest)"
                    [matTooltip]="
                        'You don\'t have permission to associate Deal Deposit Request with company \'' +
                        comp?.title +
                        '\'. If you would like to proceed with connecting this document in the selected company, please ask the company administrator.'
                    "
                    style="color: red"
                    >warning</mat-icon
                >

                <button
                    type="button"
                    mat-raised-button
                    color="primary"
                    *ngIf="currentProfile && isUserCanAssociateDepositRequestToCompany(comp, depositRequest)"
                    (click)="associate(comp)"
                >
                    Associate
                </button>
                <button
                    type="button"
                    mat-raised-button
                    color="primary"
                    *ngIf="!currentProfile && isUserCanAssociateDepositRequestToCompany(comp, depositRequest)"
                    (click)="loginAssociate(comp)"
                >
                    Login & Associate
                </button>
            </div>
            <!--			<div class="w-40 d-flex justify-content-end" *ngIf="depositRequest && currentContact && (canConnectToOtherAccounts || comp.company_id === currentProfile.user.finance__company_fk_id)">-->
            <!--				<button type="button"-->
            <!--				        mat-raised-button-->
            <!--				        color="primary"-->
            <!--				        *ngIf="currentProfile-->
            <!--				        && currentProfile.company!.id !== depositRequest!.creator__company_fk_id-->
            <!--				        && !currentContact.partner__company_fk_id-->
            <!--						&& currentProfile.company!.id === comp!.company_id"-->
            <!--				        (click)="associate(comp)">-->
            <!--					Associate-->
            <!--				</button>-->

            <!--				<button type="button"-->
            <!--				        mat-raised-button-->
            <!--				        color="primary"-->
            <!--				        *ngIf="!currentContact.partner__company_fk_id-->
            <!--						&& !currentProfile"-->
            <!--				        (click)="loginAssociate()">-->
            <!--					Login-->
            <!--				</button>-->

            <!--				<button type="button"-->
            <!--				        mat-raised-button-->
            <!--				        color="primary"-->
            <!--				        *ngIf="currentContact.partner__company_fk_id-->
            <!--				        && currentContact.partner__company_fk_id === comp.company_id"-->
            <!--				        (click)="login(comp)">-->
            <!--					Login-->
            <!--				</button>-->

            <!--			</div>-->
        </mat-card>
    </div>
    <div class="my-3">
        <mat-card
            class="d-flex"
            *ngIf="
                (!currentProfile || !currentProfile.user || !currentProfile.user.finance__company_fk_id) &&
                !userFinancialCompanyId
            "
        >
            <div class="w-60">Register new Company</div>
            <div class="w-40 d-flex justify-content-end">
                <button type="button" mat-raised-button color="primary" (click)="registration()">Register</button>
            </div>

            <!--<button type="button"-->
            <!--mat-raised-button-->
            <!--color="primary"-->
            <!--*ngIf="data.currentContact && !data.currentProfile && !data.currentContact.partner__company_fk_id"-->
            <!--(click)="registration()">-->
            <!--Registration-->
            <!--</button>-->

            <!--<button type="button"-->
            <!--mat-raised-button-->
            <!--color="primary"-->
            <!--*ngIf="data.currentProfile && data.invoice && data.currentProfile.company.id === data.invoice.money_receiver__company_fk_id"-->
            <!--(click)="registration()">-->
            <!--Registration-->
            <!--</button>-->
        </mat-card>
    </div>
</div>
