import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {IContactsState} from '../../../../../../../contacts/store/contacts.reducer';
import {Subject} from 'rxjs';
import {IDealDepositRequest} from '@cyberco-nodejs/zipi-typings';
import * as moment from 'moment';
import {PAYMENT_MODES} from '../../../../../../../../local-typings';
import {takeUntil} from 'rxjs/operators';
import {FormGroupWithFormControls} from '../../../../../../../../typings/common';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../../../../utilities/maskito';

@Component({
    selector: 'app-create-payment-dialog',
    templateUrl: './create-payment-dialog.component.html',
    styleUrls: ['./create-payment-dialog.component.scss']
})
export class CreatePaymentDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    paymentForm: FormGroupWithFormControls = new UntypedFormGroup({}) as FormGroupWithFormControls;

    listOfRequests: IDealDepositRequest[] = [];
    currencyMaskitoMask = currencyMaskitoOptions;

    paymentModes = PAYMENT_MODES;

    selectedRequest: IDealDepositRequest | undefined;

    constructor(
        private fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<CreatePaymentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        protected store: Store<IContactsState>
    ) {}

    ngOnInit() {
        this.listOfRequests = this.data.requests;
        this.paymentForm = this.fb.group({
            request_id: [null, [Validators.required]],
            amount: [0, [Validators.required]],
            money_sender__contact_fk_id: [null, [Validators.required]],
            paid_date: [moment().format('YYYYMMDD'), [Validators.required]],
            pay_to__ledger_account_fk_id: [null, [Validators.required]],
            payment_mode: [null, [Validators.required]],
            check_number: [null, []],
            note: [null, []],
            memo: [null, []]
        }) as FormGroupWithFormControls;

        this.paymentForm
            .get('request_id')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((requestId) => {
                this.selectedRequest = this.listOfRequests.find((request) => request.deposit_request_id === requestId);
                this.paymentForm
                    .get('money_sender__contact_fk_id')!
                    .setValue(this.selectedRequest!.request_receiver__contact_fk_id);
                // if (this.selectedRequest && this.selectedRequest.request_receiver_contact && this.selectedRequest.request_receiver_contact.display_name) {
                //     this.requestReceiverContactName = this.selectedRequest.request_receiver_contact.display_name;
                // }
                if (this.selectedRequest) {
                    this.paymentForm.get('amount')!.setValue(this.selectedRequest.request_balance);
                }
            });
    }

    validateAmount($event: any) {
        const inputValue = Number(cleanCurrencyString($event.target.value));

        const amount = this.selectedRequest!.request_amount;

        if (amount < inputValue) {
            this.paymentForm.get('amount')!.setValidators([Validators.email]);
            this.paymentForm.get('amount')!.updateValueAndValidity();
            this.paymentForm.get('amount')!.markAsTouched();
            // this.payDataForm.markAllAsTouched();
        } else {
            this.paymentForm.get('amount')!.clearValidators();
            this.paymentForm.get('amount')!.updateValueAndValidity();
            // this.payDataForm.markAllAsTouched();
        }
    }

    continue() {
        if (this.paymentForm.invalid) {
            this.paymentForm.markAllAsTouched();
            return;
        }
        const payData = this.paymentForm.getRawValue();

        payData.amount = Number(cleanCurrencyString(payData.amount));

        this.dialogRef.close(payData);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
