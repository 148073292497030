import {Injectable} from '@angular/core';
import {ServiceRequester} from './service.requester';
import {Company} from '../models/company';
import {Profile} from '../models/profile';
import {SessionService} from './session.service';
import {AvailableProfilesSource} from './sources/available-profiles.source';
import {ICompanyBillingSettings, ICustomDealField} from '@cyberco-nodejs/zipi-typings';
import {Observable} from 'rxjs';
import {CustomDealField} from '../models/custom-deal-field.model';
import {SubSource} from '../models/sub-source';
import {SubType} from '../models/sub-type';
import {SourceOfBusiness} from '../models/source-of-business';

@Injectable()
export class CompaniesService {
    private url = '/company/';

    constructor(
        public requester: ServiceRequester,
        public sessionService: SessionService,
        public availableProfilesSource: AvailableProfilesSource
    ) {}

    getById(id: number): Promise<Company> {
        return this.requester.makeMsCall(this.url + id, 'GET');
    }

    /**
     *
     * @param {Company} companyData
     * @param {boolean} is_paid_by_parent
     * @return {Promise<Profile>} New profile in created company
     */
    create(companyData: Company, is_paid_by_parent: boolean = false): Promise<Profile> {
        return this.requester
            .makeMsCall(this.url, 'POST', {
                company: companyData,
                is_paid_by_parent: is_paid_by_parent
            })
            .then((newProfile) => {
                const newProfileEntity = Object.assign(new Profile(), newProfile);
                return newProfileEntity;
            });
    }

    update(id: number, data: Company): Promise<boolean> {
        return this.requester.makeMsCall(this.url + id, 'PUT', data);
    }

    getSubCompanies(parentCompanyId: number): Promise<Company[]> {
        return this.requester.makeMsCall(this.url + parentCompanyId + '/sub-companies', 'GET');
    }

    getMaxUserLicences(companyId: number): Promise<number> {
        return this.requester.makeMsCall(this.url + companyId + '/max-user-licences', 'GET');
    }

    setMaxUserLicences(companyId: number, maxUserLicences: number): Promise<number> {
        return this.requester.makeMsCall(this.url + companyId + '/max-user-licences', 'PUT', {
            maxUserLicences: maxUserLicences
        });
    }

    getLastBillingSettings(companyId: number): Observable<ICompanyBillingSettings> {
        return this.requester.makeMsCall$(`/companies/${companyId}/billing-settings`, 'GET', 'shipp');
    }

    createBillingSettings(companyId: number, data: ICompanyBillingSettings): Observable<ICompanyBillingSettings> {
        return this.requester.makeMsCall$(`/companies/${companyId}/billing-settings`, 'PUT', 'shipp', data);
    }

    getCompanyCommissionDepositSettings(
        companyId: number
    ): Observable<{balance: number; threshold: number; default_threshold: number}> {
        return this.requester.makeMsCall$(
            `/companies/${companyId}/billing-settings/commission-deposit`,
            'GET',
            'shipp'
        );
    }

    updateCustomField(
        companyId: number,
        field: CustomDealField
    ): Observable<{message: string; result: ICustomDealField[]}> {
        return this.requester.makeMsCall$(this.url + companyId + '/custom-fields', 'PUT', 'zipi', {field});
    }

    createCustomField(
        companyId: number,
        field: CustomDealField
    ): Observable<{message: string; result: ICustomDealField[]}> {
        return this.requester.makeMsCall$(this.url + companyId + '/custom-fields', 'POST', 'zipi', {field});
    }

    deleteCustomField(companyId: number, fieldId: number): Observable<{message: string; result: ICustomDealField[]}> {
        return this.requester.makeMsCall$(this.url + companyId + '/custom-fields/' + fieldId, 'DELETE');
    }

    getCustomFields(companyId: number | null): Observable<{message: string; result: ICustomDealField[]}> {
        return this.requester.makeMsCall$(this.url + companyId + '/custom-fields', 'GET');
    }

    /** Use store */
    getAvailableSubEntities(companyId: number): Observable<{
        message: string;
        result: {
            sub_sources: SubSource[];
            sub_types: SubType[];
            sources: SourceOfBusiness[];
        };
    }> {
        return this.requester.makeMsCall$(this.url + companyId + '/available-sub-entities', 'GET');
    }
}
