import {Subject} from 'rxjs';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationsService} from 'angular2-notifications';
import * as moment from 'moment';
import {takeUntil} from 'rxjs/operators';
import {cleanCurrencyString, currencyWithNegativeMaskitoOptions} from '../../../../../utilities/maskito';

@Component({
    selector: 'app-start-reconciliation',
    styleUrls: ['../../banking.component.scss'],
    templateUrl: 'start-reconciliation.component.html'
})
export class StartReconciliationComponent implements OnInit, OnChanges, OnDestroy {
    @Output() continueReconciliation: EventEmitter<any> = new EventEmitter<any>();
    @Input() ledgerAccountId: number | undefined | null;
    @Input() startDate: number | null = null;
    @Input() openingBalanceDate: number | null | undefined = null;
    @Input() isOpeningBalanceExist: boolean = false;

    private unsubscribe: Subject<void> = new Subject();
    currencyMaskitoMask = currencyWithNegativeMaskitoOptions;

    formGroup: UntypedFormGroup = this.fb.group({
        start_date: [null, [Validators.required]],
        end_date: [null, Validators.required],
        closing_balance: [null, Validators.required]
    });

    minDate: Date | undefined;
    maxDate: Date | undefined;

    constructor(
        private fb: UntypedFormBuilder,
        private router: Router,
        private ntfs: NotificationsService
    ) {}

    ngOnInit() {
        this.formGroup
            .get('start_date')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => {
                this.minDate = moment(value, 'YYYYMMDD').toDate();
            });
        this.formGroup
            .get('end_date')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => {
                this.maxDate = moment(value, 'YYYYMMDD').toDate();
            });
        if (this.startDate) {
            this.formGroup
                .get('start_date')!
                .patchValue(moment(this.startDate, 'YYYYMMDD').add(1, 'day').format('YYYYMMDD'));
        }
        if (this.openingBalanceDate) {
            this.formGroup.get('start_date')!.patchValue(this.openingBalanceDate);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.startDate && changes.startDate.currentValue) {
            this.formGroup
                .get('start_date')!
                .patchValue(moment(changes.startDate.currentValue, 'YYYYMMDD').add(1, 'day').format('YYYYMMDD'));
        }
        if (changes.openingBalanceDate && changes.openingBalanceDate.currentValue) {
            this.formGroup.get('start_date')!.patchValue(changes.openingBalanceDate.currentValue);
        }
    }

    continue() {
        if (!this.validateForm()) {
            return;
        }

        this.continueReconciliation.emit({
            ...this.formGroup.getRawValue(),
            closing_balance: Number(cleanCurrencyString(this.formGroup.get('closing_balance')?.value))
        });
    }

    cancel() {
        this.router.navigate(['/banking', this.ledgerAccountId, 'transactions'], {
            queryParams: {tab: 'reconciliations'}
        });
    }

    private validateForm() {
        const endDate = this.formGroup.get('end_date')?.value;
        const startDate = this.formGroup.get('start_date')?.value;
        if (!this.formGroup.valid || endDate < startDate || !endDate || !startDate) {
            this.ntfs.warn('Reconciliation is not valid');
            return false;
        }

        return true;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.continueReconciliation.complete();
    }
}
