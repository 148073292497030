import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IFinanceState} from '../../../store/finance.reducer';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {map, filter, takeUntil, tap} from 'rxjs/operators';
import {selectCompanyLocations} from 'app/store/root.selectors';
import {
    IContact,
    ICompanyLocation,
    IBill,
    IBillItem,
    IInvoiceLog,
    ICompany,
    ITransaction,
    IPayment,
    IFinancialTransfer
} from '@cyberco-nodejs/zipi-typings';
import {SessionService} from 'app/services/session.service';
import {BILL_INVOICE_STATUS_COLOR, FINANCIAL_TRANSFER_TYPE_LABELS, PAYMENT_TERMS} from 'app/local-typings';
import {BillsService} from 'app/services/api/finance/bills.service';
import {AddCreditCardDialogComponent} from '../../../../../layouts/cards-dialogs/add-credit-card-dialog/add-credit-card-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {FetchCompanyLocations} from 'app/modules/account-info/store/settings.actions';
import {isEqual} from 'lodash-es';
import {MatTableDataSource} from '@angular/material/table';
import {ApplyCreditsToBillDialogComponent} from '../apply-credits-to-bill-dialog/apply-credits-to-bill-dialog.component';
import {FailedConnectionDialogComponent} from '../../common/failed-connection-dialog/failed-connection-dialog.component';
import {TransactionsService} from 'app/services/api/finance/transactions.service';
import {DealService} from '../../../../../services/deal.service';
import {ConfirmComponent} from '../../../../../layouts/confirm/confirm.component';
import {PaymentsService} from '../../../services/payments.service';
import {PayBillDialogComponent} from '../pay-bill-dialog/pay-bill-dialog.component';
import {ShipperContactsService} from '../../../../../services/api/shipper.contacts.service';
import {QBSyncEventsSource} from '../../../../../services/sources/qb-sync-events.source';
import {MarketplaceSource} from '../../../../account-info/marketplace/marketplace.source';
import {IRelatedDealPayoutInfo} from '@app/modules/finance/types/financial.types';

@Component({
    selector: 'app-bill-page',
    templateUrl: 'bill-page.component.html',
    styleUrls: ['bill-page.component.scss']
})
export class BillPageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() dialogMode = false;
    @Input() billId: number | undefined;

    termsMap = new Map<string, string>(<any>PAYMENT_TERMS);

    bill: IBill | undefined;
    contacts: IContact[] = [];
    vendor_contact: IContact | undefined;

    billQBSync: {
        sync_status: 'not_synced' | 'in_progress' | 'completed' | 'error';
        qb_bill_ref: string | null;
        qb_link: string | null;
        is_loaded: boolean;
        is_active: boolean;
    } = {
        sync_status: 'not_synced',
        qb_bill_ref: null,
        qb_link: null,
        is_loaded: false,
        is_active: false
    };

    paymentSettings: boolean;

    billing_address: ICompanyLocation | undefined;

    dataSource: MatTableDataSource<ITransaction>;
    displayedColumns: string[] = ['account_name', 'debit', 'credit'];

    restrictedBasedOnDealConnection: boolean | null = null;

    transfersDataSource: MatTableDataSource<IRelatedDealPayoutInfo>;
    transferDisplayedColumns: string[] = ['index', 'deal_address', 'product', 'connection_type', 'amount'];

    showTransfers: boolean;
    showJournals: boolean;
    showHistory: boolean = false;
    invoiceLogs: IInvoiceLog[] = [];
    allowApply: boolean = false;
    creditsBalance: number = 0;

    currentCompany: ICompany | undefined;

    statusColor = BILL_INVOICE_STATUS_COLOR;
    FINANCIAL_TRANSFER_TYPE_LABELS = FINANCIAL_TRANSFER_TYPE_LABELS;

    constructor(
        private store: Store<IFinanceState>,
        private route: ActivatedRoute,
        public sessionService: SessionService,
        private billsService: BillsService,
        private paymentsService: PaymentsService,
        private transactionsService: TransactionsService,
        private dealService: DealService,
        public dialog: MatDialog,
        public router: Router,
        private contactsService: ShipperContactsService,
        public qbSyncEventsSource: QBSyncEventsSource,
        protected marketplaceSource: MarketplaceSource
    ) {
        this.paymentSettings = false;
        this.dataSource = new MatTableDataSource<ITransaction>([]);
        this.transfersDataSource = new MatTableDataSource<IRelatedDealPayoutInfo>([]);
        this.showJournals = false;
        this.showTransfers = true;
    }

    isEqual: (o1: any, o2: any) => boolean = isEqual;

    initPreview() {
        this.route.paramMap
            .pipe(
                map((pm) => {
                    const stringId: string | null = pm.get('id');
                    return Number(stringId);
                }),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                if (!id && this.dialogMode && this.billId) {
                    id = this.billId;
                }

                this.billsService
                    .loadQBSync(id)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((response) => {
                        if (response) {
                            this.billQBSync.qb_bill_ref = response.qb_entity_id;
                            this.billQBSync.sync_status = response.status;
                            this.billQBSync.qb_link = response.qb_entity_link;
                        } else {
                            this.billQBSync.qb_bill_ref = null;
                            this.billQBSync.qb_link = null;
                            this.billQBSync.sync_status = 'not_synced';
                        }
                        this.billQBSync.is_loaded = true;
                    });

                this.billsService
                    .getBillById(id)
                    .pipe(
                        filter((bill) => !!bill),
                        tap((bill) => {
                            this.bill = bill;

                            if (this.bill.money_receiver__contact_fk_id) {
                                this.calculateCreditsBalance();
                                this.vendor_contact = this.bill.money_receiver_contact;
                            } else if (this.bill.money_receiver__company_fk_id) {
                                this.getVendorContact();
                            }

                            this.getBillFinancialTransfers();

                            if (this.bill.items) {
                                // need to get related deals on backend
                                this.getItemsRelatedDeals();
                            }

                            if (
                                this.bill.connected_invoice &&
                                this.bill.connected_invoice.status_in_portal === 'linked' &&
                                this.bill.status_in_portal === 'connected'
                            ) {
                                const dialogRef = this.dialog.open(FailedConnectionDialogComponent, {
                                    minWidth: 320,
                                    data: {}
                                });

                                dialogRef
                                    .afterClosed()
                                    .pipe(
                                        filter((pn) => !!pn),
                                        takeUntil(this.unsubscribe)
                                    )
                                    .subscribe((result) => {
                                        if (this.bill && this.bill.bill_id) {
                                            if (result === 'reconnect') {
                                                let sourceInvoiceId: number | null = null;
                                                if (
                                                    this.bill.connected_invoice &&
                                                    this.bill.connected_invoice.invoice_id
                                                ) {
                                                    sourceInvoiceId = this.bill.connected_invoice.invoice_id;
                                                }
                                                this.billsService
                                                    .deleteBill(this.bill.bill_id)
                                                    .pipe(takeUntil(this.unsubscribe))
                                                    .subscribe((response) => {
                                                        if (!this.dialogMode) {
                                                            this.router.navigate([
                                                                `/purchases/sourcedocuments/approve/${sourceInvoiceId}`
                                                            ]);
                                                        }
                                                    });
                                            } else if (result === 'link') {
                                                this.billsService
                                                    .setLinkedStatus(this.bill.bill_id)
                                                    .pipe(takeUntil(this.unsubscribe))
                                                    .subscribe((response) => {
                                                        if (!this.dialogMode) {
                                                            this.router.navigate([`/purchases/bills`]);
                                                        }
                                                    });
                                            }
                                        }
                                    });
                            }
                            let transactions: ITransaction[] = [];
                            if (bill.journal && bill.journal.line_items) {
                                transactions = bill.journal.line_items;
                            }
                            this.dataSource.data = transactions;
                        }),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe((res) => {
                        // this.paymentMethodsService.getAllGatewaysForCompanyAndInvoiceContact()
                        //     .pipe(takeUntil(this.unsubscribe))
                        //     .subscribe(gateways => {
                        //         this.allAvailableGateways = gateways.filter(gateway => !['authorize_net_merchant', 'dwolla_receive_only'].includes(gateway.type));
                        //     });
                    });
            });
    }

    getVendorContact() {
        if (this.bill && this.bill.money_receiver__company_fk_id) {
            this.contactsService
                .getContactInMyCompanyByPartnerCompanyId(this.bill.money_receiver__company_fk_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((contact) => {
                    if (contact) {
                        this.vendor_contact = contact;
                    }
                });
        }
    }

    loadHistory() {
        if (this.showHistory && this.bill && this.bill.bill_id) {
            this.billsService
                .getBillHistory(this.bill.bill_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((logs) => (this.invoiceLogs = logs));
        }
    }

    getBillFinancialTransfers() {
        if (this.bill && this.bill.bill_id) {
            this.dealService.getFinancialTransfersByBillId(this.bill.bill_id).then((transfers) => {
                this.transfersDataSource.data = transfers;
                if (transfers && transfers.length > 0) {
                    this.restrictedBasedOnDealConnection = transfers.some((t) => t.is_escrow);
                } else {
                    this.restrictedBasedOnDealConnection = false;
                }
            });
        }
    }

    calculateCreditsBalance() {
        if (this.bill && this.bill.money_receiver__contact_fk_id) {
            this.transactionsService
                .getExcessContactTransactions(this.bill.money_receiver__contact_fk_id)
                .pipe(
                    map((excess) => {
                        this.creditsBalance = Number((excess.vendor_credit + excess.payment_made).toFixed(2));

                        this.allowApply =
                            !!this.bill &&
                            !!this.bill.pending_balance &&
                            this.bill.pending_balance > 0 &&
                            this.creditsBalance !== 0 &&
                            this.bill.status_of_invoice !== 'draft' &&
                            this.bill.status_of_payment !== 'paid';
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe();
        }
    }

    getItemsRelatedDeals() {
        if (this.bill && this.bill.items) {
            this.bill.items = this.bill.items.map((item: IBillItem) => {
                if (item.connected__deal_fk_id) {
                    this.dealService.getDealBaseView(item.connected__deal_fk_id).then((deal) => {
                        item.related_deal = deal;
                    });
                }
                return item;
            });
        } else {
            return [];
        }
    }

    getTotalFor(type: 'debit' | 'credit') {
        let total = 0;
        this.dataSource.data.forEach((transaction) => {
            if (transaction.debit_or_credit === type) {
                total += transaction.amount;
            }
        });
        return total;
    }

    addCard() {
        const dialogRef = this.dialog.open(AddCreditCardDialogComponent, {
            minWidth: 320,
            maxHeight: '80vh',
            data: {
                moneySenderProfileId: this.sessionService.profile!.id,
                invoice: this.bill,
                contactId: this.bill ? this.bill.money_receiver__contact_fk_id : null,
                ownerCompany: true
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {
                if (ok) {
                    this.ngOnInit();
                }
            });
    }

    isProcessingPayment() {
        if (this.bill && this.bill.payments && this.bill.payments.length > 0) {
            for (const pay of this.bill.payments) {
                if (pay.status === 'processing') {
                    return true;
                }
            }
        }
        return false;
    }

    markAsOpen() {
        if (this.bill && this.bill.bill_id) {
            this.billsService
                .markAsOpen(this.bill.bill_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (result) {
                        this.router.navigate(['/purchases/bills']);
                    }
                });
        }
    }

    sendToQuickbooks() {
        if (!this.bill || !this.bill.bill_id) {
            return;
        }
        this.billsService
            .syncBillWithQB(this.bill.bill_id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                if (response) {
                }
            });
    }
    viewInQuickbooks() {
        if (!this.bill || !this.billQBSync.qb_link) {
            return;
        }
        window.open(`${this.billQBSync.qb_link}`, '_blank');
    }

    paymentOptions() {
        const dialogRef = this.dialog.open(PayBillDialogComponent, {
            minWidth: '545px',
            // minHeight: '421px',
            data: {
                bill: this.bill
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((paymentsInfo) => {
                if (paymentsInfo) {
                    if (paymentsInfo === 'add_new_card') {
                        this.addCard();
                    } else {
                        if (this.bill && this.bill.bill_id) {
                            this.billsService
                                .payBill(this.bill.bill_id, paymentsInfo)
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((result) => {
                                    if (result && !this.dialogMode) {
                                        this.router.navigate(['/purchases/bills']);
                                    }
                                });
                        }
                    }
                }
            });
    }

    redirect(journalId: number) {
        this.router.navigate([`company/journals/${journalId}`]);
    }

    hasNonModifiableTransactions(): boolean {
        if (this.bill && this.bill.journal && this.bill.journal.line_items) {
            return (
                !!this.bill.journal &&
                !!this.bill.journal.line_items.find(
                    (tr) => !!tr.reconciliation_fk_id || !!tr.matched__transaction_external_id
                )
            );
        } else {
            return false;
        }
    }

    voidBill() {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            data: {
                title: 'Void Bill',
                message: `Bill will be voided.`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((c) => !!c),
                takeUntil(this.unsubscribe)
            )
            .subscribe((confirm) => {
                if (confirm && this.bill && this.bill.bill_id) {
                    this.billsService
                        .markAsVoid(this.bill.bill_id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((result) => {
                            if (result) {
                                this.ngOnInit();
                            }
                        });
                }
            });
    }

    editBill(billId: number) {
        this.router.navigate([`purchases/bills/edit/${billId}`]);
    }

    cancelPayment(payment: IPayment) {
        if (!payment || !payment.payment_id) {
            return;
        }

        const dialogRef = this.dialog.open(ConfirmComponent, {
            data: {
                title: 'Remove Payment',
                message: `Payment will be removed.`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((c) => !!c),
                takeUntil(this.unsubscribe)
            )
            .subscribe((confirm) => {
                if (confirm && payment.payment_id) {
                    this.paymentsService
                        .cancelPayment(payment.payment_id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((result) => {
                            if (result) {
                                this.initPreview();
                            }
                        });
                }
            });
    }

    openApplyCreditsToBillDialog() {
        if (!this.allowApply) {
            return;
        }

        const dialogRef = this.dialog.open(ApplyCreditsToBillDialogComponent, {
            minWidth: 700,
            data: {
                bill: this.bill
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((c) => !!c),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.ngOnInit();
            });
    }

    ngOnInit() {
        this.store.dispatch(new FetchCompanyLocations());

        this.store
            .select(selectCompanyLocations)
            .pipe(
                // delay(100),
                filter((lx) => lx.length > 0),
                tap(([billing_address]) => (this.billing_address = billing_address)),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.marketplaceSource.addonsChangeEvent.pipe(takeUntil(this.unsubscribe)).subscribe((instances) => {
            const qbAddon = instances.find(
                (instance) => instance.addon && instance.addon.slug === 'quickbooks_integration'
            );
            if (qbAddon) {
                this.billQBSync.is_active = !!qbAddon.activated;
            }
        });

        this.qbSyncEventsSource.qbSyncEvents.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            if (data && data.entity === 'bill' && data.books_entity_id === this.bill?.bill_id) {
                this.billQBSync.qb_bill_ref = data.qb_entity_id;
                this.billQBSync.qb_link = data.qb_entity_link;
                this.billQBSync.sync_status = data.status;
            }
        });

        this.initPreview();

        if (this.sessionService.profile) {
            this.currentCompany = this.sessionService.profile.company as ICompany;
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
