<div class="" *ngIf="gatewayForm && ['zipi_financial_business', 'zipi_financial_trust'].includes(gatewayType)">
    <div class="d-flex mb-2 align-items-center">
        <i class="sp-spacer"></i>
    </div>

    <div class="mb-1" [formGroup]="gatewayForm" *ngIf="step === 'step1'">
        <div class="d-flex w-100 my-3" *ngIf="!gatewayId">
            <div class="col logo">
                <h3>Money Transfers (EFT)</h3>
            </div>
            <span class="sp-spacer"></span>
            <div class="w-50 d-flex">
                <mat-form-field class="w-50 px-2" style="margin-top: 8px">
                    <input matInput required type="text" formControlName="title" />
                    <mat-placeholder>Gateway Title</mat-placeholder>
                </mat-form-field>

                <mat-radio-group
                    [disabled]="!isAbleToSetupTrust"
                    class="d-flex flex-column w-50 align-items-end"
                    color="primary"
                    formControlName="gateway_type"
                >
                    <mat-radio-button labelPosition="before" value="zipi_financial_business"
                        >Operating</mat-radio-button
                    >
                    <mat-radio-button
                        labelPosition="before"
                        value="zipi_financial_trust"
                        matTooltip="An 'Operating' account must be activated prior to establishing a 'Trust' account type."
                        [matTooltipDisabled]="isAbleToSetupTrust"
                    >
                        Trust
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div *ngIf="individualInfoForm && businessInfoForm && commonInfoForm && !gatewayId">
            <div>
                <h3>Account Information</h3>

                <p style="border: 1px solid #9a001f; background-color: #daab99; padding: 5px">
                    This feature is temporary unavailable. Please come back later.
                </p>
                <!--                <div *ngIf="driverType === 'plaid'">-->
                <!--                    <mat-form-field class="w-50 px-2">-->
                <!--                        <input matInput required type="text" formControlName="first_name">-->
                <!--                        <mat-placeholder>First Name</mat-placeholder>-->
                <!--                    </mat-form-field>-->
                <!--                    <mat-form-field class="w-50 px-2">-->
                <!--                        <input matInput required type="text" formControlName="last_name">-->
                <!--                        <mat-placeholder>Last Name</mat-placeholder>-->
                <!--                    </mat-form-field>-->
                <!--                    <mat-form-field class="w-50 px-2">-->
                <!--                        <input matInput required type="text" formControlName="email">-->
                <!--                        <mat-placeholder>Email</mat-placeholder>-->
                <!--                    </mat-form-field>-->
                <!--                </div>-->

                <div>
                    <mat-form-field class="w-50 px-2" [formGroup]="businessInfoForm">
                        <mat-select formControlName="business_type" (valueChange)="typeChange($event)">
                            <mat-option *ngFor="let type of businessTypesMap" [value]="type.slug">
                                {{ type.title }}
                            </mat-option>
                        </mat-select>
                        <mat-placeholder>Type of Entity</mat-placeholder>
                    </mat-form-field>

                    <mat-form-field class="w-50 px-2" *ngIf="gatewayForm.controls.customer_type.value === 'business'">
                        <input
                            type="text"
                            matInput
                            required
                            [formControl]="industryTitle"
                            placeholder="Industry"
                            autocomplete="off"
                            [matAutocomplete]="autoGroup"
                        />
                        <mat-autocomplete #autoGroup="matAutocomplete">
                            <ng-container *ngIf="industries.length > 0">
                                <ng-container *ngFor="let industry of filteredIndustries | async">
                                    <mat-option
                                        [value]="industry.title"
                                        (onSelectionChange)="selectIndustry($event, industry)"
                                    >
                                        {{ industry.title }}
                                    </mat-option>
                                </ng-container>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>

                    <div
                        *ngIf="gatewayForm.controls.customer_type.value === 'business' && businessInfoForm"
                        [formGroup]="businessInfoForm"
                    >
                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="business_name" />
                            <mat-placeholder>Legal Business Name</mat-placeholder>
                        </mat-form-field>

                        <!--                        <mat-form-field class="w-50 px-2">-->
                        <!--                            <input matInput type="text" formControlName="dba">-->
                        <!--                            <mat-placeholder>Doing Business As</mat-placeholder>-->
                        <!--                        </mat-form-field>-->

                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="ein" />
                            <mat-placeholder>Employer Identification Number</mat-placeholder>
                        </mat-form-field>

                        <div class="d-flex w-100">
                            <div class="d-flex w-50 px-2">
                                <label
                                    [ngClass]="{
                                        invalid:
                                            businessInfoForm.controls.is_has_website.touched &&
                                            businessInfoForm.controls.is_has_website.invalid
                                    }"
                                >
                                    Does your business have a website?
                                </label>
                                <mat-radio-group
                                    class="w-50 text-right"
                                    color="primary"
                                    formControlName="is_has_website"
                                    [ngClass]="{
                                        invalid:
                                            businessInfoForm.controls.is_has_website.touched &&
                                            businessInfoForm.controls.is_has_website.invalid
                                    }"
                                >
                                    <mat-radio-button labelPosition="before" value="yes">Yes</mat-radio-button>
                                    <mat-radio-button labelPosition="before" value="no">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="d-flex w-50 px-2">
                                <mat-form-field
                                    class="w-100"
                                    *ngIf="businessInfoForm.controls.is_has_website.value === 'yes'"
                                >
                                    <input matInput type="text" formControlName="web" required />
                                    <mat-placeholder>Website URL</mat-placeholder>
                                </mat-form-field>
                                <mat-form-field
                                    class="w-100"
                                    *ngIf="businessInfoForm.controls.is_has_website.value === 'no'"
                                >
                                    <input matInput type="text" formControlName="description" required />
                                    <mat-placeholder>Description</mat-placeholder>
                                    <mat-error *ngIf="businessInfoForm.controls.description.invalid"
                                        >Must be more than 10 and less than 100 characters</mat-error
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div
                        *ngIf="gatewayForm.controls.customer_type.value === 'individual' && individualInfoForm"
                        [formGroup]="individualInfoForm"
                    >
                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="first_name" />
                            <mat-placeholder>First Name</mat-placeholder>
                        </mat-form-field>
                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="last_name" />
                            <mat-placeholder>Last Name</mat-placeholder>
                        </mat-form-field>

                        <mat-form-field class="w-50 px-2">
                            <input matInput type="text" formControlName="middle_name" />
                            <mat-placeholder>Middle Name</mat-placeholder>
                        </mat-form-field>
                        <mat-form-field class="w-50 px-2">
                            <input matInput type="text" formControlName="suffix" />
                            <mat-placeholder>Suffix</mat-placeholder>
                        </mat-form-field>

                        <div class="d-flex">
                            <mat-form-field class="w-50 px-2">
                                <input matInput required type="text" formControlName="email" />
                                <mat-placeholder>Email</mat-placeholder>
                            </mat-form-field>
                            <div class="d-flex w-50 px-2">
                                <app-date-picker
                                    class="w-100"
                                    [placeholder]="'Date Of Birth'"
                                    [dateControl]="individualInfoForm.controls.date_of_birth"
                                    [startDate]="startDate"
                                    [startView]="'year'"
                                ></app-date-picker>
                            </div>
                        </div>

                        <div>
                            <mat-form-field class="w-50 px-2">
                                <input matInput required type="text" formControlName="ssn" />
                                <mat-placeholder>Last four digits of SSN</mat-placeholder>
                            </mat-form-field>
                            <!--                            <mat-form-field class="w-50 px-2">-->
                            <!--                                <input matInput  type="text" formControlName="itin">-->
                            <!--                                <mat-placeholder>itin</mat-placeholder>-->
                            <!--                            </mat-form-field>-->
                        </div>
                    </div>

                    <div class="mt-4" [formGroup]="commonInfoForm">
                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="address_line_1" />
                            <mat-placeholder>Address Line 1 (No P.O. Box please)</mat-placeholder>
                        </mat-form-field>
                        <mat-form-field class="w-50 px-2">
                            <input matInput type="text" formControlName="address_line_2" />
                            <mat-placeholder>Address Line 2</mat-placeholder>
                        </mat-form-field>

                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="city" />
                            <mat-placeholder>City</mat-placeholder>
                        </mat-form-field>
                        <mat-form-field class="w-50 px-2">
                            <mat-select required formControlName="state">
                                <mat-option *ngFor="let state of stateProvinceRegionList" [value]="state.slug">{{
                                    state.title
                                }}</mat-option>
                            </mat-select>
                            <mat-placeholder>State</mat-placeholder>
                        </mat-form-field>

                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="postalCode" />
                            <mat-placeholder>Zip (ex. 12345)</mat-placeholder>
                        </mat-form-field>
                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="phone_number" />
                            <mat-placeholder>Phone number (10 digits)</mat-placeholder>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <div class="policy" *ngIf="gatewayForm && !gatewayId">
            <div class="">
                <mat-checkbox [checked]="agree" (change)="agree = !agree" class="policy-check">
                    <span class="w-100 text-wrap">
                        By checking this box, I agree to SkySlope Books and it’s service partners’ Terms of Services and
                        Privacy Policies as linked below. Risk Prevention, Fraud Detection, Financial Services, Payment
                        Providers, Payment Processing, Identity Verification and Other Partners:
                        <a href="https://www.zipi.app/terms-of-use" target="_blank" class="zipi-terms-link"
                            >Terms of Service</a
                        >
                        <a href="https://www.zipi.app/privacy-policy" target="_blank" class="zipi-terms-link"
                            >Privacy Policy</a
                        >
                    </span>
                </mat-checkbox>
            </div>
        </div>
    </div>

    <mat-card *ngIf="step === 'step2' && representativesArray" class="mb-1">
        <div class="px-3">
            <div class="mb-2">
                List the owner details for each individual person that holds at-least a twenty-five percent (25%) stake
                in the company. Also you have to define at least one of them as Controller.
            </div>
        </div>

        <mat-radio-group [(ngModel)]="beneficialSetup">
            <div class="d-flex">
                <mat-radio-button class="radio-b" [value]="true">Representatives</mat-radio-button>
                <mat-radio-button class="radio-b" [value]="false">Setup Later</mat-radio-button>
            </div>
        </mat-radio-group>
        <div *ngIf="beneficialSetup">
            <div *ngFor="let beneficial of representativesArray.controls; let idx = index">
                <div class="d-flex my-2">
                    <mat-card class="mx-3 w-95" [formGroup]="beneficial">
                        <div class="d-flex align-items-center">
                            <mat-checkbox formControlName="is_controller">Is Controller</mat-checkbox>
                            <mat-checkbox
                                formControlName="is_owner"
                                (change)="isOwnerChange($event, beneficial)"
                                class="ml-2"
                                >Is Owner</mat-checkbox
                            >
                            <mat-icon
                                class="ml-2"
                                style="color: rgb(151, 150, 150); vertical-align: middle"
                                matTooltip="If this person is both a Controller and Owner (with more than 25% of the outstanding shares), please check both boxes."
                                >info_outlined</mat-icon
                            >
                        </div>

                        <mat-form-field class="w-50 px-2">
                            <input matInput type="text" formControlName="job_title" required />
                            <mat-placeholder>Job Title</mat-placeholder>
                        </mat-form-field>
                        <span class="w-50 px-2" *ngIf="!beneficial.controls.is_owner.value"></span>
                        <mat-form-field class="w-50 px-2" *ngIf="beneficial.controls.is_owner.value">
                            <input
                                matInput
                                type="text"
                                (keypress)="['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes($event.key)"
                                step="1"
                                formControlName="ownership_percentage"
                            />
                            <mat-placeholder>The percentage of ownership</mat-placeholder>
                            <mat-icon
                                matSuffix
                                style="color: rgb(151, 150, 150); vertical-align: middle"
                                matTooltip="To be considered an owner, the person must have a 25% minimum of the company's outstanding shares."
                            >
                                info_outlined
                            </mat-icon>
                        </mat-form-field>

                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="first_name" />
                            <mat-placeholder>First Name</mat-placeholder>
                        </mat-form-field>
                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="last_name" />
                            <mat-placeholder>Last Name</mat-placeholder>
                        </mat-form-field>

                        <mat-form-field class="w-50 px-2">
                            <input matInput type="text" formControlName="middle_name" />
                            <mat-placeholder>Middle Name</mat-placeholder>
                        </mat-form-field>
                        <mat-form-field class="w-50 px-2">
                            <input matInput type="text" formControlName="suffix" />
                            <mat-placeholder>Suffix</mat-placeholder>
                        </mat-form-field>

                        <div class="d-flex">
                            <mat-form-field class="w-50 px-2">
                                <input matInput required type="text" formControlName="email" />
                                <mat-placeholder>Email</mat-placeholder>
                            </mat-form-field>
                            <div class="d-flex w-50 px-2">
                                <app-date-picker
                                    class="w-100"
                                    [placeholder]="'Date Of Birth'"
                                    [dateControl]="beneficial.controls.date_of_birth"
                                    [startDate]="startDate"
                                    [startView]="'year'"
                                ></app-date-picker>
                            </div>
                        </div>

                        <div>
                            <mat-form-field class="w-50 px-2">
                                <input matInput required type="text" formControlName="ssn" />
                                <mat-placeholder>Last four digits of SSN</mat-placeholder>
                            </mat-form-field>
                        </div>

                        <!--                            <mat-form-field class="w-50 px-2">-->
                        <!--                                <input matInput type="text" formControlName="itin">-->
                        <!--                                <mat-placeholder>itin</mat-placeholder>-->
                        <!--                            </mat-form-field>-->

                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="address_line_1" />
                            <mat-placeholder>Address Line 1 (No P.O. Box please)</mat-placeholder>
                        </mat-form-field>
                        <mat-form-field class="w-50 px-2">
                            <input matInput type="text" formControlName="address_line_2" />
                            <mat-placeholder>Address Line 2</mat-placeholder>
                        </mat-form-field>

                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="city" />
                            <mat-placeholder>City</mat-placeholder>
                        </mat-form-field>
                        <mat-form-field class="w-50 px-2">
                            <mat-select required formControlName="state">
                                <mat-option *ngFor="let state of stateProvinceRegionList" [value]="state.slug">{{
                                    state.title
                                }}</mat-option>
                            </mat-select>
                            <mat-placeholder>State</mat-placeholder>
                        </mat-form-field>

                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="postalCode" />
                            <mat-placeholder>Zip (ex. 12345)</mat-placeholder>
                        </mat-form-field>
                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="phone_number" />
                            <mat-placeholder>Phone number (10 digits)</mat-placeholder>
                        </mat-form-field>
                    </mat-card>

                    <div>
                        <button
                            class=""
                            mat-icon-button
                            *ngIf="representativesArray.controls.length > 1"
                            (click)="removeBeneficial(idx)"
                        >
                            <mat-icon>remove</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="ml-3 mt-2">
                    <button
                        class=""
                        *ngIf="idx + 1 === representativesArray.controls.length"
                        mat-raised-button
                        (click)="addRepresentative()"
                    >
                        <mat-icon>add</mat-icon>
                        Representatives
                    </button>
                </div>
            </div>
        </div>

        <div class="m-2 d-flex justify-content-end">
            <mat-checkbox [checked]="certify" (change)="certify = !certify">
                I, {{ sessionService?.user?.first_name }} {{ sessionService?.user?.last_name }}, hereby certify, to the
                best of my knowledge that the information provided is complete and correct.
            </mat-checkbox>
        </div>
    </mat-card>

    <!--	<div class="d-flex">-->
    <!--		<i class="sp-spacer"></i>-->
    <!--		<button class="ml-2" [disabled]="!agree || !agreeOnDem || lockButton" type="button" mat-raised-button-->
    <!--		        (click)="continue()">-->
    <!--			Agree & Continue-->
    <!--		</button>-->
    <!--	</div>-->

    <div class="d-flex">
        <button class="ml-2" *ngIf="step === 'step2'" mat-raised-button (click)="stepBack()">Back</button>
        <i class="sp-spacer"></i>
        <button
            class="ml-2"
            *ngIf="step === 'step2'"
            [disabled]="beneficialSetup && (!certify || lockButton)"
            type="button"
            mat-raised-button
            (click)="finish()"
        >
            Continue
        </button>

        <button
            class="ml-2"
            *ngIf="step === 'step1' && !gatewayId"
            [disabled]="true || !agree || lockButton"
            type="button"
            mat-raised-button
            (click)="continue()"
        >
            Agree & Continue
        </button>
    </div>
</div>
