import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {takeUntil, tap} from 'rxjs/operators';
import {FetchProducts} from '../../../store/finance.actions';
import {select, Store} from '@ngrx/store';
import {ICreditItem, IExpense, IGroup, IProduct, IVendorCredit} from '@cyberco-nodejs/zipi-typings';
import {IFinanceState} from '../../../store/finance.reducer';
import {pipe, Subject} from 'rxjs';
import {NotificationsService} from 'angular2-notifications';
import {GenericFormArray} from '../../../../../entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {CreateProductDialogComponent} from '../../products/create-product-dialog/create-product-dialog.component';
import {selectProducts} from '../../../store/finance.selectors';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {ExpensesService} from '../../../services/expenses.service';
import {GroupsSource} from 'app/services/sources/groups.source';
import {FormGroupArray, FormGroupWithFormControls} from '../../../../../typings/common';
import {
    cleanCurrencyString,
    currencyMaskitoOptions,
    unmaskCurrencyControlValue
} from '../../../../../utilities/maskito';

@Component({
    selector: 'app-expense-edit',
    templateUrl: './expense-edit.component.html',
    styleUrls: ['./expense-edit.component.scss']
})
export class ExpenseEditComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    formGroup: FormGroupWithFormControls = this.fb.group({
        expense_date: [null, Validators.required],
        expense__ledger_account_fk_id: [null, Validators.required],
        amount: [0, Validators.required],
        credits_amount: [{value: 0, disabled: true}],

        paid_through__ledger_account_fk_id: [null, Validators.required],
        expense_vendor__contact_fk_id: [null, Validators.required],
        expense_note: [null, []],

        apply_credit: [false, Validators.required]
    }) as FormGroupWithFormControls;

    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);

    expense: IExpense | undefined;

    // vendor credit to apply

    itemsArray: FormGroupArray = this.fb.array([this.createItem()]) as FormGroupArray;
    creditFormGroup: FormGroupWithFormControls = this.fb.group({
        vendor_credit_number: [null],
        reference: [null],

        items: this.itemsArray,

        allow_auto_apply: [false, Validators.required]
    }) as FormGroupWithFormControls;

    initCreditSnapshot = {};

    currencyMaskitoMask = currencyMaskitoOptions;
    amountWithCurrencyMask: boolean[] = [];
    rateWithCurrencyMask: boolean[] = [];

    vendorCredit: IVendorCredit | undefined;

    products: IProduct[] = [];
    emptyItem = {
        rate: 0,
        quantity: 1,
        amount: 0,
        product_fk_id: null,
        name: null,
        description: null,
        ledger_account_fk_id: null,
        division__company_group_fk_id: null
    };

    savedContacts: Array<number> = [];

    groupList: IGroup[] = [];

    isButtonDisabled = false;

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store<IFinanceState>,
        private ntfs: NotificationsService,
        private route: ActivatedRoute,
        private router: Router,
        private expensesService: ExpensesService,
        public dialog: MatDialog,
        private groupSrc: GroupsSource
    ) {}

    ngOnInit() {
        this.store.dispatch(new FetchProducts());

        this.initCreditSnapshot = this.creditFormGroup.getRawValue();

        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            const expenseId = params['id'];
            if (expenseId) {
                this.expensesService
                    .getExpenseById(expenseId)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((expense) => {
                        this.expense = expense;

                        this.formGroup.patchValue(expense);
                        this.savedContacts = [expense.expense_vendor__contact_fk_id];

                        if (expense.vendor_credit) {
                            this.vendorCredit = expense.vendor_credit;
                            this.creditFormGroup.patchValue(expense.vendor_credit);
                            this.itemsArray.disable();
                            this.formGroup.controls.apply_credit.patchValue(true);
                            this.formGroup.controls.apply_credit.disable();
                        }
                    });
            }
        });

        this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe)).subscribe((data) => {
            this.products = data;
        });

        this.itemsArray.valueChanges
            .pipe(
                tap(() => this.computeTotal()),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.moneySenderCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            if (values && values.length > 0) {
                this.formGroup.controls.expense_vendor__contact_fk_id.patchValue(values[0].target_id);
            } else {
                this.formGroup.controls.expense_vendor__contact_fk_id.patchValue(null);
            }
        });

        this.formGroup.controls.apply_credit.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            if (!value) {
                this.creditFormGroup.patchValue(this.initCreditSnapshot);
                this.itemsArray.clear();
                this.addItem();
            }
        });

        this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
            this.groupList = list.filter((group) => group.type === 'division') as IGroup[];
        });
    }

    createExpense() {
        if (
            this.formGroup.invalid ||
            this.formGroup.controls.credits_amount.value > this.formGroup.controls.amount.value
        ) {
            this.ntfs.warn('Expense Form is not valid');
        } else {
            this.isButtonDisabled = true;
            const expense = this.formGroup.getRawValue();
            const vendor_credit = this.creditFormGroup.getRawValue();
            expense.amount = Number(cleanCurrencyString(expense.amount));
            this.expensesService
                .createExpense({
                    expense: expense,
                    vendor_credit: expense.apply_credit ? vendor_credit : null
                })
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    this.isButtonDisabled = false;
                    this.router.navigate(['/purchases/expenses']);
                });
        }
    }

    editExpense() {
        if (
            this.formGroup.invalid ||
            this.formGroup.controls.credits_amount.value > this.formGroup.controls.amount.value
        ) {
            this.ntfs.warn('Expense Form is not valid');
        } else {
            this.isButtonDisabled = true;
            const expense = this.formGroup.getRawValue();
            const vendor_credit = this.creditFormGroup.getRawValue();
            expense.amount = Number(cleanCurrencyString(expense.amount));

            if (this.expense && this.expense.expense_id) {
                this.expensesService
                    .editExpense(this.expense.expense_id, {
                        expense: expense,
                        vendor_credit: expense.apply_credit ? vendor_credit : null
                    })
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(() => {
                        this.isButtonDisabled = false;
                        this.router.navigate(['/purchases/expenses/', this.expense!.expense_id]);
                    });
            }
        }
    }

    deleteExpense() {
        if (this.expense && this.expense.expense_id) {
            this.isButtonDisabled = true;
            this.expensesService
                .deleteExpense(this.expense.expense_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    this.isButtonDisabled = false;
                    this.router.navigate(['/purchases/expenses']);
                });
        }
    }

    // vendor credit to apply

    private validateForm() {
        if (!this.creditFormGroup.valid) {
            this.ntfs.warn('Vendor Template is not valid');
            return false;
        }

        return true;
    }

    private createItem(item?: ICreditItem, itemIndex: number = 0) {
        const group = this.fb.group({
            product_fk_id: [null],
            name: [{value: item ? item.name : null, disabled: item && item.product_fk_id}, Validators.required],
            description: [null],
            quantity: [1, Validators.required],
            rate: [0, Validators.required],
            amount: [{value: 0, disabled: true}],
            ledger_account_fk_id: [
                {
                    value: item ? item.ledger_account_fk_id : null,
                    disabled: item && item.product_fk_id
                },
                Validators.required
            ],
            order_index: null,
            division__company_group_fk_id: [item ? item.division__company_group_fk_id : null, []],
            selected_divisions: new GenericFormArray<ChipNode>([])
        });

        group.controls.selected_divisions.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (changes && changes.length > 0) {
                group.controls.division__company_group_fk_id.setValue(changes[0].target_id);
            } else {
                group.controls.division__company_group_fk_id.setValue(null);
            }
        });

        const handleValueChanges = pipe(
            takeUntil(this.unsubscribe),
            tap(() => group.controls.amount.patchValue(group.controls.quantity.value * group.controls.rate.value)),
            tap(() => {
                this.amountWithCurrencyMask[itemIndex] = String(group.controls.amount.value).length < 13;
            })
        );

        const unmaskRateFn = unmaskCurrencyControlValue(group.controls.rate);

        group.controls.quantity.valueChanges.pipe(handleValueChanges, takeUntil(this.unsubscribe)).subscribe();
        group.controls.rate.valueChanges
            .pipe(
                unmaskRateFn,
                handleValueChanges,
                tap(() => {
                    this.rateWithCurrencyMask[itemIndex] = String(group.controls.rate.value).length < 13;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
        if (item) {
            group.patchValue(item);
        }
        return group;
    }

    addItem(item?: ICreditItem) {
        this.itemsArray.push(this.createItem(item, this.itemsArray.length));
    }

    selectItem(item: AbstractControl, product: IProduct) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.controls.product_fk_id.patchValue(product.product_id);
        itemGroup.controls.rate.patchValue(product.price);
        itemGroup.controls.description.patchValue(product.description);
        itemGroup.controls.ledger_account_fk_id.patchValue(product.ledger_account_fk_id);
        itemGroup.controls.quantity.patchValue(1);
        itemGroup.controls.name.patchValue(product.name);

        itemGroup.controls.name.disable();
        itemGroup.controls.ledger_account_fk_id.disable();
        itemGroup.controls.division__company_group_fk_id.disable();
    }

    removeProductFkId(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.controls.name.enable();
        itemGroup.controls.ledger_account_fk_id.enable();
        itemGroup.controls.name.patchValue('');
        itemGroup.controls.product_fk_id.patchValue(null);
    }

    resetItem(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.reset(this.emptyItem);
        itemGroup.controls.name.enable();
    }

    private computeTotal = () => {
        const total = this.itemsArray.controls.reduce<number>((acc: number, item: AbstractControl) => {
            const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
            return itemGroup.getRawValue().amount + acc;
        }, 0);
        this.formGroup.controls.credits_amount.patchValue(total);
    };

    getProducts(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        if (itemGroup.getRawValue().name) {
            const filteredValue = itemGroup.getRawValue().name.toLowerCase().trim();
            return this.products.filter((pr) => pr.name.toLowerCase().trim().includes(filteredValue));
        }

        return this.products;
    }

    createProduct() {
        const dialogRef = this.dialog.open(CreateProductDialogComponent);

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
