import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'findItemByIdInArray'
})
export class FindItemByIdInArrayPipe implements PipeTransform {
    /**
     * Using pipe method calls once or two times
     * Using usual function it called dozens times
     */
    transform(items: any[], id: number): any {
        return items.find((d) => d.id === id);
    }
}
