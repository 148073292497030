import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {IExpense} from '@cyberco-nodejs/zipi-typings';
import {ExpensesService} from '../../../services/expenses.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-expense-page',
    templateUrl: './expense-page.component.html',
    styleUrls: ['./expense-page.component.scss']
})
export class ExpensePageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    expense: IExpense | undefined;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private expensesService: ExpensesService,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        this.initPreview();
    }

    initPreview() {
        this.route.paramMap
            .pipe(
                map((pm) => {
                    const stringId: string | null = pm.get('id');
                    return Number(stringId);
                }),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                this.expensesService
                    .getExpenseById(id)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((expense) => {
                        this.expense = expense;
                    });
            });
    }

    deleteExpense() {
        if (this.expense && this.expense.expense_id) {
            this.expensesService
                .deleteExpense(this.expense.expense_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    this.router.navigate(['/purchases/expenses']);
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
