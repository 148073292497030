<section class="container-fluid" *ngIf="expense">
    <div class="w-100 text-right mt-3">
        <!--		<button mat-raised-button type="button" (click)="downloaInvoicePdf()">-->
        <!--			Download PDF-->
        <!--		</button>-->
        <button
            mat-raised-button
            [rule]="{purchases__manage_expenses: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            type="button"
            [routerLink]="['edit']"
        >
            Edit
        </button>
    </div>
    <mat-card class="p-5 my-3">
        <header class="row">
            <div class="col-6 zp-company-info">
                <!-- <p>Company Logo</p> -->
                <p class="zp-company-title">{{ expense?.creator_company?.title }}</p>
                <p *ngFor="let location of expense?.creator_company?.locations">
                    <span *ngIf="location.street_number">{{ location.street_number }} </span>
                    <span *ngIf="location.street_address">{{ location.street_address }} </span>
                    <span *ngIf="location.unit_number">{{ location.unit_number }} </span>
                    <br />
                    <span *ngIf="location.city">{{ location.city }}, </span>
                    <span *ngIf="location.state">{{ location.state }} </span>
                    <span *ngIf="location.zip">{{ location.zip }} </span>
                    <br />
                    <span *ngIf="location.country" class="text-muted">({{ location.country }})</span>
                </p>
            </div>

            <div class="col-6 text-right">
                <p class="zp-inveice_title">Expense</p>
                <p class="zp-invoice-num_value">
                    {{ $any(expense?.expense_date) | dateFromNumber | date: 'mediumDate' }}
                </p>

                <p class="zp-refference_due_title">Amount: {{ expense?.amount | currency }}</p>
            </div>
        </header>

        <div class="m-3">
            <h4 class="m-0">Expense Account:</h4>
            <div class="pl-2">
                <div>
                    <span>Name: </span>
                    <span>{{ expense.expense_account?.name }}</span>
                </div>
                <div>
                    <span>Code: </span>
                    <span>{{ expense?.expense_account?.code }}</span>
                </div>
                <div>
                    <span>Description: </span>
                    <span>{{ expense?.expense_account?.description }}</span>
                </div>
            </div>
        </div>

        <div class="m-3">
            <h4 class="m-0">Paid Through:</h4>
            <div class="pl-2">
                <div>
                    <span>Name: </span>
                    <span>{{ expense?.paid_through?.name }}</span>
                </div>
                <div>
                    <span>Code: </span>
                    <span>{{ expense?.paid_through?.code }}</span>
                </div>
                <div>
                    <span>Description: </span>
                    <span>{{ expense?.paid_through?.description }}</span>
                </div>
            </div>
        </div>

        <div class="w-100 text-right">
            <p class="mb-2">
                <strong
                    >Total: <span class="zp-total-nember font-weight-bold">{{ expense?.amount | currency }}</span>
                </strong>
            </p>
        </div>

        <mat-form-field class="w-100">
            <textarea matInput disabled cdkTextareaAutosize [value]="expense?.expense_note" placeholder="Notes">
            </textarea>
        </mat-form-field>
    </mat-card>
    <div class="d-flex mt-2">
        <span class="sp-spacer"></span>
        <button
            type="!submit"
            class="ml-1"
            mat-stroked-button
            color="warn"
            [rule]="{purchases__manage_expenses: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            (click)="deleteExpense()"
        >
            Delete Expense
        </button>
        <button mat-raised-button class="ml-2" routerLink="/purchases/expenses">Cancel</button>
    </div>
</section>
