import {Component, OnInit} from '@angular/core';
import {AuthService} from 'app/services/auth.service';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-skyslope-post-logout',
    templateUrl: './skyslope-post-logout.component.html'
})
export class SkyslopePostLogoutComponent implements OnInit {
    constructor(private authService: AuthService) {}

    ngOnInit(): void {
        this.authService.loadAuth().then((isUserLoaded) => {
            if (isUserLoaded) {
                this.authService.logout().then(() => this.redirectLogic());
            } else {
                this.redirectLogic();
            }
        });
    }

    redirectLogic() {
        if (environment.env === 'production') {
            window.location.replace(environment.marketingPageLogin);
        } else {
            window.location.replace(`${window.location.origin}${environment.unauthorizedUserRedirectPath}`);
        }
    }
}
