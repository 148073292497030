import {BaseNotification} from './base.notification';
import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-reload-notification',
    template: `
        <div class="reload-notification" (click)="reloadPage()">
            <div class="message">
                <span style="padding: 8px;">Hey, SkySlope Books just got even better!</span>
                <span style="padding: 8px;">Click to reload.</span>
                <mat-icon aria-label="Reload">autorenew</mat-icon>
            </div>
        </div>
    `,
    styles: [
        `
            .reload-notification {
                display: flex;
                position: fixed;
                z-index: 100;
                top: 0;
                left: 25%;
                height: 30px;
                width: 50%;
                cursor: pointer;
            }

            .message {
                background-color: #ff40da;
                width: 100%;
                display: flex;
                border-radius: 3px;
                align-items: center;
                justify-content: center;
                color: #ffffff;
                font-weight: bold;
            }
        `
    ]
})
export class ReloadNotificationComponent implements OnInit {
    @Input() notification: ReloadNotification | null = null;

    constructor() {}

    ngOnInit() {
        if (this.notification) {
            this.notification.state = 'active';
        }
    }

    reloadPage() {
        window.location.reload();
    }
}

export class ReloadNotification extends BaseNotification {
    protected position: 'reload' = 'reload';

    constructor() {
        super();
    }
}
