import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {NotificationsServiceZipi} from '../../notifications/notifications.service';
import {UiModsDealBadgeInListModel, UiModsModel} from '../models/ui-mods.model';
import {UiModsSource} from '../ui-mods.source';
import {SystemNotificationSource} from '../../user-notification/system-notification.source';
import {NotificationsService} from 'angular2-notifications';

@Injectable()
export class UiModsApi {
    constructor(
        public requester: ServiceRequester,
        protected notificationsService: NotificationsServiceZipi,
        protected uiModsSource: UiModsSource,
        protected systemNotifications: SystemNotificationSource,
        private ntfs: NotificationsService
    ) {
        this.systemNotifications.reloadUiModsSystemNotification.subscribe((notification) => {
            if (notification.text) {
                try {
                    const parsedText = JSON.parse(notification.text);

                    if (parsedText.context_deal_id && Number(parsedText.context_deal_id)) {
                        this.loadUiModsByDeal(parsedText.context_deal_id).then((res) => {
                            if (res && res.length) {
                                this.uiModsSource.updateDealBadgesInList(
                                    res.map((badge) => this.uiModsSource.buildUiModDealBadgeInList(badge, false))
                                );
                            }
                        });
                    }
                    return; // finish processing
                } catch (e) {
                    // Seems some problems with JSON parsing. Do nothing.
                }
            }

            // if we failed to process case with not empty notification.text and did not run 'return'
            // then we will try general solution
            this.loadUiMods().then((loadedMods) => {
                this.systemNotifications.triggers.dismissSystemNotification.next(notification);
                this.uiModsSource.triggers.initApp.next(loadedMods);
            });
        });
    }

    loadUiMods(): Promise<UiModsModel> {
        return this.requester.makeMsCall('/ui-mods', 'GET').then((res) => {
            return res;
        });
    }

    loadUiModsByDeal(dealId: number): Promise<UiModsDealBadgeInListModel[]> {
        return this.requester.makeMsCall('/ui-mods/' + dealId, 'GET').then((res) => {
            return res;
        });
    }

    uiModClicked(uiModId: number): Promise<any> {
        return this.requester.makeMsCall('/ui-mods/' + uiModId + '/clicked', 'POST').then((res) => {
            return res;
        });
    }

    uiModOptionClicked(uiModId: number, onClickEntity: any): Promise<any> {
        return this.requester
            .makeMsCall('/ui-mods/' + uiModId + '/option-clicked', 'POST', onClickEntity)
            .then((res) => {
                // if click on 'disconnect' - show success notification
                if (
                    (onClickEntity && onClickEntity.path === '/integrator/deal-to-loop/disconnect') || // dotloop
                    onClickEntity.path === '/integrator/deal-to-transaction/disconnect'
                ) {
                    // skyslope
                    this.ntfs.success('Successfully disconnected');
                } else if (
                    (onClickEntity && onClickEntity.path === '/integrator/deal-to-loop/upload-disbursement') ||
                    onClickEntity.path === '/integrator/deal-to-transaction/upload-disbursement' ||
                    onClickEntity.path === '/integrator/deal-to-loop/upload-tradesheet' ||
                    onClickEntity.path === '/integrator/deal-to-transaction/upload-tradesheet'
                ) {
                    this.ntfs.success('Document uploading added in tasks queue.');
                }
                return res;
            });
    }

    dealButtonClicked(uiModId: number, dealId: number): Promise<any> {
        return this.requester
            .makeMsCall('/ui-mods/' + uiModId + '/clicked', 'POST', {
                dealId: dealId
            })
            .then((res) => {
                return res;
            });
    }

    uiModsInnerRequest(uiModInstanceId: number, path: string, dealId: number): Promise<any> {
        // /:uiModInstanceId/inner-request/:dealId
        return this.requester.makeMsCall(`/ui-mods/${uiModInstanceId}/${path}/${dealId}`, 'GET').then((res) => {
            return res;
        });
    }
}
