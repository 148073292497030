import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {GenericFormArray, GenericFormGroup} from '../../../../../../entites/generic.entity';
import {FinancialTransferEntity} from '../../../../../account-info/compensation/models/financial-transfer.entity';
import {IInvoice} from '@cyberco-nodejs/zipi-typings';
import {Subject} from 'rxjs';
import {FinancialElementModel} from '../../../../../account-info/compensation/models/financial-element.model';
import {Router} from '@angular/router';
import {SalesEntity} from '../../../../../../models/sales-entity';
import {InvoiceBillModalDialogComponent} from '../../../../../finance/components/invoice-bill-modal-dialog/invoice-bill-modal-dialog.component';

@Component({
    selector: 'app-calculation-additional-expenses',
    styles: [
        `
            .association-item {
                padding: 0 3px;
                display: flex;
                justify-content: space-between;
            }
            .association-item:hover {
                background-color: #f1f1f1;
            }
            .association-item mat-icon {
                cursor: pointer;
            }
            .association-item div.label {
                display: table;
            }
            .association-item div.label span {
                display: table-cell; /* that is trick for vertical align to middle */
                vertical-align: middle;
            }
        `
    ],
    template: `
        <div class="option-item">
            <div class="d-flex">
                <h3 class="option-title">
                    {{ title }}
                </h3>
                <mat-spinner *ngIf="showSpinner" [diameter]="16" [strokeWidth]="3" class="ml-2"></mat-spinner>
            </div>
            <app-deal-transfers-collection
                [origin]="FINANCIAL_ELEMENT_ENTITY.type_set.disbursement_template"
                [buttonLabel]="buttonLabel"
                [allowPercents]="allowPercents"
                [showOnlyAmount]="false"
                [availableValueTypes]="availableValueTypes"
                [transfersFA]="financialNodesFA"
                [disabled]="disabled"
                [type]="type"
                [isSalesEntityAgentCardContext]="isSalesEntityAgentCardContext"
                [isExpense]="true"
                (addItem)="addExpense.emit($event)"
                (deleteItem)="removeTransfer.emit($event)"
            ></app-deal-transfers-collection>
            <div
                class="association-item"
                *ngFor="let associatedTransfer of salesEntityFG?.controls?.association_expenses?.controls"
            >
                <div class="label">
                    <span>
                        {{ associatedTransfer.controls.amount.value | currency }} has been associated to this deal for
                        reporting purposes, reference "{{ associatedTransfer.controls.label.value }}" on
                        <a
                            href="javascript:"
                            (click)="
                                openViewDialog(associatedTransfer.controls.connected__invoice_fk_id?.value, 'invoice')
                            "
                            matTooltip="View Invoice"
                            >{{ associatedTransfer.controls.connected_invoice?.value?.invoice_number }}</a
                        >
                        ({{ associatedTransfer.controls.connected_invoice?.value?.summary_status | fromSnakeCase }})
                    </span>
                </div>

                <mat-icon *ngIf="!salesEntityFG.disabled" color="warn" (click)="removeTransfer.emit(associatedTransfer)"
                    >clear</mat-icon
                >
            </div>
        </div>
    `
})
export class CalculationAdditionalExpensesComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() buttonLabel = 'Add expense';
    @Input() title = 'Additional Expense';
    @Input() type = FinancialTransferEntity.type_SET.transfer;
    @Input() allowPercents = true;
    @Input() availableValueTypes = ['percent', 'flat_fee', 'flat_fee_sales_net'];
    FINANCIAL_TRANSFER_ENTITY = FinancialTransferEntity;
    FINANCIAL_ELEMENT_ENTITY = FinancialElementModel;
    @Input() financialNodesFA: GenericFormArray<FinancialTransferEntity> = new GenericFormArray([]);
    @Input() disabled: boolean = false;
    @Input() isSalesEntityAgentCardContext: boolean = false;
    @Input() showSpinner: boolean = false;

    @Input() salesEntityFG: GenericFormGroup<SalesEntity> | undefined;

    @Output() addExpense = new EventEmitter<GenericFormGroup<FinancialTransferEntity>>();
    @Output() removeTransfer = new EventEmitter<GenericFormGroup<FinancialTransferEntity>>();

    newInvoice: IInvoice | undefined;
    isApplyingProcess: boolean;

    constructor(
        protected dialog: MatDialog,
        public router: Router
    ) {
        this.isApplyingProcess = false;
    }

    openViewDialog(entityId: number, type: string) {
        if (!['invoice', 'bill'].includes(type)) {
            return;
        }

        const data: {invoiceId?: number; billId?: number} = {};
        if (type === 'invoice') {
            data.invoiceId = entityId;
        }
        if (type === 'bill') {
            data.billId = entityId;
        }
        const dialogRef = this.dialog.open(InvoiceBillModalDialogComponent, {
            position: {
                top: '10px',
                right: '10px'
            },
            height: '98%',
            width: '100vw',
            maxWidth: '98vw',
            panelClass: 'full-screen-modal',
            data: data
        });
    }

    ngOnInit(): void {}

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.addExpense.complete();
        this.removeTransfer.complete();
    }
}
