<form [formGroup]="formGroup">
    <div class="row">
        <app-company-compensation-combined-picker
            class="d-inline-block col-3"
            [availableTypes]="['contact']"
            [title]="'Customer'"
            [only_compensation_groups]="false"
            [singleSelected]="true"
            [bold_style]="false"
            [nodesFA]="moneySenderCtrlArr"
            [initialContactIds]="initContactId"
        >
        </app-company-compensation-combined-picker>

        <mat-form-field class="col col-3">
            <input matInput formControlName="credit_note_number" placeholder="Credit Note#" type="text" />
        </mat-form-field>

        <mat-form-field class="col col-3">
            <input matInput formControlName="reference" placeholder="Reference#" type="text" />
        </mat-form-field>

        <app-date-picker
            class="d-inline-block col-3"
            [placeholder]="'Credit Note Date'"
            [dateControl]="formGroup.controls.credit_note_date"
        ></app-date-picker>

        <app-company-compensation-combined-picker
            class="d-inline-block col-3"
            [availableTypes]="['individual']"
            [title]="'Sales Person'"
            [singleSelected]="true"
            [bold_style]="false"
            [nodesFA]="selectedProfileArr"
            [initialProfileIds]="selectedProfileIds"
        ></app-company-compensation-combined-picker>
    </div>
    <div class="row mb-4">
        <mat-slide-toggle [formControl]="formGroup.controls.allow_auto_apply" class="col align-self-center">
            Auto-apply to future invoices.
        </mat-slide-toggle>
    </div>

    <!------- Items Header ------->
    <h3>
        Items
        <button type="button" mat-raised-button (click)="addItem()" matTooltip="Add item" class="short-button ml-2">
            <mat-icon>add</mat-icon>
        </button>
    </h3>
    <!------- Items ------->
    <div class="overflow-auto">
        <div
            class="d-grid-items-container"
            *ngFor="let itemGroup of itemsArray.controls; index as idx; first as first"
            [ngClass]="groupList.length ? 'with-groups' : 'no-groups'"
        >
            <div class="d-grid-items" [formGroup]="itemGroup">
                <!------- Product/Service ------->
                <div class="mr-2">
                    <ng-container *ngIf="!itemGroup.controls.product_fk_id.value">
                        <mat-form-field class="w-100">
                            <input
                                formControlName="name"
                                type="text"
                                matInput
                                placeholder="Product item"
                                [matAutocomplete]="auto"
                            />
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option (click)="$event.stopPropagation(); createProduct()"
                                    >Create New Product
                                </mat-option>
                                <mat-option
                                    *ngFor="let product of getProducts(itemGroup)"
                                    [value]="product.name"
                                    (onSelectionChange)="selectItem(itemGroup, product)"
                                >
                                    {{ product.name }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="itemGroup.controls.product_fk_id.value">
                        <mat-form-field class="w-100">
                            <input formControlName="name" matInput type="text" />
                            <button
                                mat-button
                                matSuffix
                                mat-icon-button
                                aria-label="Clear"
                                (click)="removeProductFkId(itemGroup)"
                            >
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </ng-container>
                </div>
                <!------- Description ------->
                <mat-form-field class="mx-2">
                    <input matInput formControlName="description" placeholder="Description" type="text" />
                </mat-form-field>
                <!------- Quantity ------->
                <mat-form-field class="mx-2">
                    <input matInput formControlName="quantity" placeholder="Quantity" type="number" min="1" />
                </mat-form-field>
                <!------- Rate ------->
                <mat-form-field class="mx-2">
                    <input
                        matInput
                        formControlName="rate"
                        placeholder="Rate"
                        [maskito]="rateWithCurrencyMask[idx] ? currencyMaskitoMask : numberMaskitoMask"
                    />
                </mat-form-field>
                <!------- Amount ------->
                <mat-form-field class="mx-2">
                    <input
                        matInput
                        formControlName="amount"
                        placeholder="Amount"
                        type="text"
                        [maskito]="rateWithCurrencyMask[idx] ? currencyMaskitoMask : numberMaskitoMask"
                    />
                </mat-form-field>
                <!------- Ledger Account ------->
                <div class="mx-2">
                    <app-ledger-account-selector
                        class="w-100"
                        [ledgerAccountControl]="itemGroup.get('ledger_account_fk_id')"
                        [ledgerAccountFullControl]="itemGroup.controls.selected_full_ledger"
                        [disabledReselect]="!!itemGroup.controls.product_fk_id.value"
                        [placeholder]="'Account'"
                    >
                    </app-ledger-account-selector>
                </div>
                <!--<app-combined-entity-picker
                    class="col-3"
                    [availableTypes]="['company_group']"
                    [title]="'Assign to Division'"
                    [initialGroupIds]="[itemGroup.get('selected_division_ids').value]"
                    [nodesFA]="itemGroup.get('selected_divisions')"
                    [singleSelected]="true"
                    [bold_style]="false"
                ></app-combined-entity-picker>-->
                <!------- Assign to Division ------->
                <div class="mx-2" *ngIf="groupList.length">
                    <app-division-picker
                        [isNeedToBeFiltered]="
                            itemGroup.controls.selected_full_ledger.value
                                ? !itemGroup.controls.selected_full_ledger.value.is_accessible_for_all
                                : false
                        "
                        [filterById]="
                            itemGroup.controls.selected_full_ledger.value
                                ? itemGroup.controls.selected_full_ledger.value.accessible_for
                                : []
                        "
                        [nodesFA]="itemGroup.controls.selected_divisions"
                        [initialGroupId]="itemGroup.controls.selected_division_ids.value"
                        [isTotallyDisabled]="!itemGroup.controls.ledger_account_fk_id.value"
                        [placeholder]="'Assign to Division'"
                    >
                    </app-division-picker>
                </div>
            </div>
            <!------- Actions ------->
            <div class="actions">
                <button
                    type="button"
                    *ngIf="itemsArray.length === 1"
                    mat-icon-button
                    (click)="resetItem(itemGroup)"
                    matTooltip="Clear Item"
                >
                    <mat-icon>close</mat-icon>
                </button>
                <button
                    type="button"
                    *ngIf="itemsArray.length > 1"
                    mat-icon-button
                    (click)="itemsArray.removeAt(idx)"
                    matTooltip="Remove Item"
                >
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-end align-items-baseline">
        <span class="mr-2"> Total amount: &nbsp; </span>
        <strong class="zp-total-amount">
            {{ formGroup!.controls.total_amount.value | currency }}
        </strong>
    </div>
</form>
