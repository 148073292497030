<section class="container-fluid py-4 px-5">
    <header class="mb-4">
        <h1 *ngIf="!paymentReceived">Create Payment Received</h1>
        <h1 *ngIf="paymentReceived">Edit Payment Received</h1>
    </header>
    <div [formGroup]="formGroup" class="mb-3">
        <h3 class="mb-3">Payment</h3>

        <div class="row mr-0">
            <div class="col col-sm-4">
                <div class="d-flex align-items-baseline">
                    <app-company-compensation-combined-picker
                        style="width: 100%; margin-right: 8px"
                        [availableTypes]="['contact']"
                        [title]="'Customer'"
                        [only_compensation_groups]="false"
                        [singleSelected]="true"
                        [bold_style]="false"
                        [nodesFA]="moneySenderCtrlArr"
                        [initialContactIds]="savedContacts"
                    >
                    </app-company-compensation-combined-picker>

                    <button
                        type="button"
                        mat-icon-button
                        matSuffix
                        (click)="contactCreate()"
                        matTooltip="Create Contact"
                        [anyRule]="[{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]"
                        rbac
                        [disabled]="!!paymentReceivedId"
                    >
                        <mat-icon>person_add</mat-icon>
                    </button>
                </div>
            </div>

            <mat-form-field class="col col-sm-4">
                <input matInput formControlName="reference" placeholder="Reference#" type="text" autocomplete="off" />
            </mat-form-field>

            <app-date-picker
                class="col col-sm-4 pr-0"
                [placeholder]="'Payment Date'"
                [dateControl]="formGroup.controls.paid_date"
                [disabled]="isPaymentMatched"
            ></app-date-picker>
        </div>
        <div class="row mr-0">
            <mat-form-field class="col col-sm-4">
                <mat-label>Amount</mat-label>
                <input
                    [maskito]="currencyMaskitoMask"
                    matInput
                    type="text"
                    formControlName="amount"
                    name="amount"
                    autocomplete="off"
                    matTooltipPosition="above"
                />
                <mat-error
                    *ngIf="
                        formGroup.controls.amount.invalid &&
                        formGroup.controls.amount.errors &&
                        formGroup.controls.amount.errors.message
                    "
                    >{{ formGroup.controls.amount.errors.message }}</mat-error
                >
            </mat-form-field>

            <mat-form-field class="col col-sm-4">
                <mat-label>Payment#</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="payment_number"
                    autocomplete="off"
                    matTooltipPosition="above"
                />
            </mat-form-field>

            <app-ledger-account-selector
                #ledgerAccountSelector
                class="col-sm-4 pr-0"
                [ledgerAccountControl]="formGroup.controls.pay_to__ledger_account_fk_id"
                [disabledReselect]="isReconciled"
                [placeholder]="'Deposit To'"
            >
            </app-ledger-account-selector>
        </div>
        <div class="row mr-0">
            <mat-form-field class="col col-sm-4" *ngIf="!paymentReceivedId">
                <mat-select formControlName="payment_mode">
                    <ng-container *ngFor="let mode of paymentModes">
                        <mat-option [value]="mode.slug" *ngIf="mode.is_selectable">
                            <span>{{ mode.title }}</span>
                        </mat-option>
                    </ng-container>
                </mat-select>
                <mat-placeholder>Payment Mode</mat-placeholder>
            </mat-form-field>

            <mat-form-field class="col col-sm-4" *ngIf="paymentReceivedId">
                <input
                    matInput
                    type="text"
                    autocomplete="off"
                    placeholder="Payment Mode"
                    disabled
                    [value]="showPaymentMode()"
                />
            </mat-form-field>
            <mat-slide-toggle formControlName="allow_auto_apply" class="col align-self-center">
                Auto-apply amount in excess to future Invoices based on Recurring Invoices.
            </mat-slide-toggle>
        </div>

        <div
            class="row mr-0"
            *ngIf="
                formGroup.controls.payment_mode &&
                formGroup.controls.payment_mode.value &&
                formGroup.controls.payment_mode.value === 'check_record'
            "
        >
            <div class="col col-sm-4">
                <mat-form-field class="w-100">
                    <input matInput autocomplete="off" type="search" formControlName="check_number" />
                    <mat-label>Check Number</mat-label>
                </mat-form-field>
            </div>
        </div>

        <mat-form-field class="w-100">
            <textarea formControlName="notes" type="text" matInput placeholder="Notes"> </textarea>
        </mat-form-field>

        <mat-form-field
            class="w-100"
            *ngIf="
                formGroup.controls.payment_mode &&
                formGroup.controls.payment_mode.value &&
                formGroup.controls.payment_mode.value === 'check_record'
            "
        >
            <textarea matInput type="text" formControlName="memo"> </textarea>
            <mat-label>Memo</mat-label>
        </mat-form-field>

        <div *ngIf="formGroup.controls.money_sender__contact_fk_id.value" class="invoices-list mb-3">
            <div class="d-flex justify-content-between">
                <span class="text-muted">Invoice Details</span>
                <span class="text-muted">Payment</span>
            </div>

            <div class="w-100 text-center p-5" *ngIf="invoicesArray.length === 0">
                There are no unpaid Invoices, that can be applied for this payment.
            </div>

            <ng-container *ngFor="let invoice of invoicesArray.controls; index as idx; first as first">
                <div class="invoice-item d-flex justify-content-between" [formGroup]="invoice">
                    <div class="invoice-details">
                        <p class="m-0 font-weight-bold">
                            <a
                                (click)="router.navigate(['sales/invoices', invoice.value.invoice_id])"
                                href="javascript:"
                            >
                                {{ invoice.value.invoice_number || 'N/A' }}
                            </a>
                        </p>
                        <p class="m-0">Due: {{ invoice.value.due_date | dateFromNumber | date: 'mediumDate' }}</p>
                        <p class="m-0">Balance: {{ invoice.value.balance | currency: 'USD' : 'symbol' }}</p>
                    </div>
                    <div class="payment">
                        <a
                            class="pay-full"
                            [ngStyle]="invoice.controls.payment.invalid ? {color: '#BB000A'} : {}"
                            (click)="payInFull(invoice)"
                            >Pay in Full</a
                        >
                        <mat-form-field class="w-100">
                            <input
                                [maskito]="currencyMaskitoMask"
                                matInput
                                type="text"
                                formControlName="payment"
                                autocomplete="off"
                                name="payment"
                            />
                            <mat-error
                                *ngIf="
                                    invoice.controls.payment.invalid &&
                                    invoice.controls.payment.errors &&
                                    invoice.controls.payment.errors.message
                                "
                                >{{ invoice.controls.payment.errors.message }}</mat-error
                            >
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="d-flex justify-content-end">
            <div class="payment-summary mb-3 p-3 text-right">
                <div class="row mb-3">
                    <div class="col-6 font-weight-bold">Amount Received:</div>
                    <div class="col-6 font-weight-bold">{{ amountReceived | currency: 'USD' : 'symbol' }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Applied:</div>
                    <div class="col-6">{{ paymentsAmount | currency: 'USD' : 'symbol' }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Refunded amount:</div>
                    <div class="col-6">{{ refundedAmount | currency: 'USD' : 'symbol' }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Amount in excess:</div>
                    <div class="col-6" [ngStyle]="excessAmount < 0 ? {color: '#BB000A'} : {}">
                        {{ excessAmount | currency: 'USD' : 'symbol' }}
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex">
            <div class="" *ngIf="paymentReceived" [matTooltip]="getDeleteTooltip()">
                <button
                    mat-raised-button
                    color="warn"
                    [disabled]="!isAbleToDelete() || (paymentReceived && !!paymentReceived.source__deal_fk_id)"
                    *ngIf="paymentReceivedId"
                    (click)="deletePayment()"
                >
                    Delete
                </button>
            </div>
            <span class="sp-spacer"></span>
            <button mat-raised-button class="mr-3" routerLink="/sales/paymentsreceived">Cancel</button>
            <button
                mat-raised-button
                color="primary"
                [disabled]="createDisabled"
                *ngIf="!paymentReceivedId"
                (click)="create()"
            >
                Create
            </button>
            <div
                matTooltip="You cannot modify Payment Received associated with Deal."
                [matTooltipDisabled]="paymentReceived && !paymentReceived.source__deal_fk_id"
            >
                <button
                    mat-raised-button
                    color="primary"
                    [disabled]="paymentReceived && !!paymentReceived.source__deal_fk_id"
                    *ngIf="paymentReceivedId"
                    (click)="editPayment()"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</section>
