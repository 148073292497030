import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {US_STATE_MAPPING_LIST, ZIPI_FINANCIAL_BUSINESS_INDUSTRIES} from '../../../../../../../local-typings';
import {IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {CompanyGatewayService} from '../../../../../../../services/api/finance/company-gateway.service';

@Component({
    selector: 'app-zipi-financial-retry-submit-gateway-dialog',
    templateUrl: './zipi-financial-retry-submit-gateway-dialog.component.html',
    styleUrls: ['./zipi-financial-retry-submit-gateway-dialog.component.scss']
})
export class ZipiFinancialRetrySubmitGatewayDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    startDate = new Date(1990, 0, 1);

    currentPaymentGateway: IPaymentGateway | undefined;

    zipiFinancialCustomer: any;
    errorFields: Array<string> = [];
    retryIndividualForm: UntypedFormGroup | undefined;
    retryBusinessForm: UntypedFormGroup | undefined;

    stateProvinceRegionList = US_STATE_MAPPING_LIST;

    industries: Array<{title: string; hash: string}> = ZIPI_FINANCIAL_BUSINESS_INDUSTRIES;
    industryTitle: UntypedFormControl | undefined;
    filteredIndustries: Observable<{title: string; hash: string}[]> | undefined;

    lockButton: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ZipiFinancialRetrySubmitGatewayDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {customerInfo: {[key: string]: any}; hideCancel?: boolean; gateway: IPaymentGateway},
        private fb: UntypedFormBuilder,
        private companyGatewayService: CompanyGatewayService
    ) {
        this.industryTitle = this.fb.control('', [Validators.required]);
    }

    async ngOnInit() {
        this.currentPaymentGateway = this.data.gateway;
        this.zipiFinancialCustomer = this.data.customerInfo;
        this.errorFields = this.zipiFinancialCustomer.errors || [];

        if (this.zipiFinancialCustomer.customer_type === 'individual') {
            this.retryIndividualForm = this.fb.group({
                first_name: [this.zipiFinancialCustomer.name.firstName, [Validators.required]],
                last_name: [this.zipiFinancialCustomer.name.lastName, [Validators.required]],
                date_of_birth: [null, [Validators.required]],
                ssn: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
                email: [
                    this.zipiFinancialCustomer.email,
                    [
                        Validators.required,
                        Validators.pattern(/^([a-zA-Z0-9_\-\.+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)
                    ]
                ],
                phone_number: [null, [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
                address_line_1: [
                    this.zipiFinancialCustomer.address.addressLine1,
                    [Validators.required, Validators.maxLength(32)]
                ],
                address_line_2: [this.zipiFinancialCustomer.address.addressLine2, [Validators.maxLength(32)]],
                city: [this.zipiFinancialCustomer.address.city, [Validators.required]],
                state: [this.zipiFinancialCustomer.address.stateOrProvince, [Validators.required]],
                postalCode: [this.zipiFinancialCustomer.address.postalCode, [Validators.required]],
                country: [this.zipiFinancialCustomer.address.country, [Validators.required]]
            });
            this.retryIndividualForm.controls.ssn = this._addSsnMaskListener(this.retryIndividualForm.controls.ssn);
            this.retryIndividualForm.controls.postalCode = this._addZipMaskListener(
                this.retryIndividualForm.controls.postalCode
            );
            this.retryIndividualForm.controls.phone_number = this._addPhoneMaskListener(
                this.retryIndividualForm.controls.phone_number
            );
            this.retryIndividualForm.controls.phone_number.setValue(this.zipiFinancialCustomer.phone.number);

            this.retryIndividualForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
                if (this.lockButton) {
                    this.lockButton = false;
                }
            });
        } else if (this.zipiFinancialCustomer.customer_type === 'business') {
            this.retryBusinessForm = this.fb.group({
                business_name: [this.zipiFinancialCustomer.legalBusinessName, [Validators.required]],
                business_type: [this.zipiFinancialCustomer.businessType, [Validators.required]],
                ein: ['', [Validators.required]],
                web: [this.zipiFinancialCustomer.website, []],
                description: [this.zipiFinancialCustomer.description, []],
                industry_hash: [this.zipiFinancialCustomer.industry_hash, []],
                phone_number: [null, [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
                address_line_1: [
                    this.zipiFinancialCustomer.address.addressLine1,
                    [Validators.required, Validators.maxLength(32)]
                ],
                address_line_2: [this.zipiFinancialCustomer.address.addressLine2, [Validators.maxLength(32)]],
                city: [this.zipiFinancialCustomer.address.city, [Validators.required]],
                state: [this.zipiFinancialCustomer.address.stateOrProvince, [Validators.required]],
                postalCode: [this.zipiFinancialCustomer.address.postalCode, [Validators.required]],
                country: [this.zipiFinancialCustomer.address.country, [Validators.required]]
            });
            if (this.zipiFinancialCustomer.description) {
                this.retryBusinessForm.controls.description.setValidators([
                    Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(100)
                ]);
                this.retryBusinessForm.controls.description.updateValueAndValidity();
            } else if (this.zipiFinancialCustomer.website) {
                this.retryBusinessForm.controls.web.setValidators([Validators.required]);
                this.retryBusinessForm.controls.web.updateValueAndValidity();
            }

            this.retryBusinessForm.controls.ein = this._addEnnMaskListener(this.retryBusinessForm.controls.ein);
            this.retryBusinessForm.controls.postalCode = this._addZipMaskListener(
                this.retryBusinessForm.controls.postalCode
            );
            this.retryBusinessForm.controls.phone_number = this._addPhoneMaskListener(
                this.retryBusinessForm.controls.phone_number
            );
            this.retryBusinessForm.controls.phone_number.setValue(this.zipiFinancialCustomer.phone.number);

            this.retryBusinessForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
                if (this.lockButton) {
                    this.lockButton = false;
                }
            });
        }
        this.filteredIndustries = this.industryTitle?.valueChanges.pipe(
            startWith(''),
            map((val) => this._filterIndustries(val)),
            takeUntil(this.unsubscribe)
        );
        this.industryTitle?.setValue(this._getIndustryTitleByHash(this.zipiFinancialCustomer.industry_hash));
    }

    selectIndustry($event: any, industry: any) {
        if ($event.isUserInput) {
            this.retryBusinessForm?.controls.industry_hash.setValue(industry.hash);
        }
    }

    continue() {
        let newCustomerData = null;
        if (this.zipiFinancialCustomer.customer_type === 'business') {
            if (this.retryBusinessForm?.invalid) {
                this.retryBusinessForm.markAllAsTouched();
                return;
            }
            if (this.industryTitle?.invalid) {
                this.industryTitle.markAllAsTouched();
                return;
            }
            newCustomerData = this.retryBusinessForm?.getRawValue();
        }
        if (this.zipiFinancialCustomer.customer_type === 'individual') {
            if (this.retryIndividualForm?.invalid) {
                this.retryIndividualForm.markAllAsTouched();
                return;
            }
            newCustomerData = this.retryIndividualForm?.getRawValue();
        }

        if (!newCustomerData) {
            return;
        }

        this.lockButton = true;

        this.companyGatewayService
            .doRetryForZipiFinancial(this.currentPaymentGateway?.payment_gateway_id!, newCustomerData)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                if (response) {
                    this.lockButton = false;
                    this.dialogRef.close(true);
                }
            });
    }

    _getIndustryTitleByHash(industryHash: string) {
        let title = '';
        if (industryHash) {
            const industryItem = this.industries.find((industry) => industry.hash.toLowerCase().includes(industryHash));
            if (industryItem) {
                title = industryItem.title;
            }
        } else {
        }
        return title;
    }

    _filterIndustries(value: string): {title: string; hash: string}[] {
        const filterValue = value.toLowerCase();

        const res = this.industries.filter((industry) => industry.title.toLowerCase().includes(filterValue));
        return res;
    }

    _addZipMaskListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            const charArr = changes.split('');
            const digitsArr: Array<string> = [];
            charArr.forEach((char: string, index: number) => {
                if (index <= 4) {
                    if (!isNaN(+char) && char !== ' ' && char !== null) {
                        digitsArr.push(char);
                    }
                }
            });
            control.setValue(digitsArr.join(''), {emitEvent: false});
        });
        return control;
    }

    _addSsnMaskListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            const charArr = changes.split('');
            const digitsArr: Array<string> = [];
            charArr.forEach((char: string, index: number) => {
                if (index <= 3) {
                    if (!isNaN(+char) && char !== ' ' && char !== null) {
                        digitsArr.push(char);
                    }
                }
            });
            control.setValue(digitsArr.join(''), {emitEvent: false});
        });
        return control;
    }

    _addEnnMaskListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (typeof changes === 'string') {
                const charArr = changes.split('');
                const digitsArr: Array<string> = [];
                const newCharArr: Array<string> = [];
                charArr.forEach((char: string, index: number) => {
                    if (!isNaN(+char) && char !== ' ' && char !== null) {
                        digitsArr.push(char);
                    }
                });
                digitsArr.forEach((digit, index) => {
                    if (index <= 8) {
                        if ([2].includes(index)) {
                            newCharArr.push('-');
                            newCharArr.push(digit);
                        } else {
                            newCharArr.push(digit);
                        }
                    }
                });

                const actualStr = newCharArr.join('');
                control.setValue(actualStr, {emitEvent: false});
            } else {
                control.setValue('', {emitEvent: false});
            }
        });

        return control;
    }

    _addPhoneMaskListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (typeof changes === 'string') {
                const charArr = changes.split('');
                const digitsArr: Array<string> = [];
                const newCharArr: Array<string> = [];
                charArr.forEach((char: string, index: number) => {
                    if (!isNaN(+char) && char !== ' ' && char !== null) {
                        digitsArr.push(char);
                    }
                });
                digitsArr.forEach((digit, index) => {
                    if (index <= 9) {
                        if ([3, 6].includes(index)) {
                            newCharArr.push('-');
                            newCharArr.push(digit);
                        } else {
                            newCharArr.push(digit);
                        }
                    }
                });

                const actualStr = newCharArr.join('');
                control.setValue(actualStr, {emitEvent: false});
            } else {
                control.setValue('', {emitEvent: false});
            }
        });

        return control;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
