import {Component, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {BaseWidgetComponent} from '../base.widget.component';
import {Deal} from '../../../../../../models/deal';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {Widget} from '../../../../../../models/widget';

@Component({
    selector: 'app-avg-price-widget',
    templateUrl: './avg-price.widget.component.html',
    styleUrls: ['../widgets.scss']
})
export class AvgPriceWidgetComponent extends BaseWidgetComponent implements OnDestroy, OnChanges {
    slug = 'avg';
    types = Deal.provideTypes().filter((el) => el === 'buyer' || el === 'seller');
    DEAL = Deal;

    private unsubscribeSub: Subject<void> = new Subject();
    public idFormName = 'avg-p-form-' + this.randomNum;
    public isDataExist = true;
    // public column_ChartData1 = [];
    public column_ChartDataNgx1: {name: string; value: number}[] = [];
    public startDateControl: UntypedFormControl = new UntypedFormControl();
    public endDateControl: UntypedFormControl = new UntypedFormControl();

    // public column_ChartOptions1 = {
    //     width: 250,
    //     height: 200,
    //     legend: 'none'
    // };

    /**
     * On init
     */
    onInit() {
        this.entitiesControl.valueChanges.pipe(takeUntil(this.unsubscribeSub)).subscribe((changes) => {
            this.handleEntityPicker(changes);
        });
    }

    initSubscription() {
        super.initSubscription();

        this.startDateControl.valueChanges.pipe(takeUntil(this.unsubscribeSub)).subscribe((val) => {
            this.widget!.settings.period_start_date = val;
        });

        this.endDateControl.valueChanges.pipe(takeUntil(this.unsubscribeSub)).subscribe((val) => {
            this.widget!.settings.period_end_date = val;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.customizeVisibility && !changes.customizeVisibility.currentValue && this.configPanelVisible) {
            this.hideConfigPanel();
        }
    }

    processData(widget: Widget) {
        this.data = widget.data;

        const settings = this.data.settings || {};
        this.startDateControl.setValue(settings.period_start_date);
        this.endDateControl.setValue(settings.period_end_date);

        if (Object.keys(this.data['widget_data']).length === 0) {
            return (this.isDataExist = false);
        }

        const avgBuyer = widget.data.widget_data.avg_buyers_price;
        const avgSeller = widget.data.widget_data.avg_sellers_price;

        // this.column_ChartData1 = [[ 'Element', 'Price'],
        //     ['Buyer', avgBuyer],
        //     ['Seller', avgSeller]
        // ];

        this.column_ChartDataNgx1 = [
            {name: 'Buyer', value: avgBuyer},
            {name: 'Seller', value: avgSeller}
        ];

        if (avgBuyer === 0 && avgSeller === 0) {
            this.isDataExist = false;
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.unsubscribeSub.next();
        this.unsubscribeSub.complete();
        super.ngOnDestroy();
    }
}
