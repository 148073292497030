export class CustomDealField {
    custom_deal_field_id?: number | null = null;
    id?: number | null = null;
    owner__company_fk_id?: number | null = null;
    type: 'date' | 'float' | 'number' | 'text' | 'select' | 'boolean' | null = null;
    label: string | null = null;
    options?: string[] = [];

    public static fieldTypes() {
        return ['date', 'float', 'number', 'text', 'select', 'boolean'];
    }
}
