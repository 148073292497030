import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {Deal} from '../../../../models/deal';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {OpeningPerformanceValuesEntity} from '../../../../entites/opening-performance-values.entity';
import {MultipleTargetsDealsQueryModel} from '../../../deals/components/deal/common/deal.models';
import {SessionService} from '../../../../services/session.service';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {CalculationSettingsService} from '../../../../services/calculation-settings.service';

interface IProductCAPValue {
    product_id: number | null;
    type: string;
    value: number;
}

@Component({
    selector: 'app-contact-edit-compliance-opening-performance-values-item',
    template: `
        <section>
            <div class="d-flex align-items-md-center bg-light rounded p-2 mb-3">
                <div class="row align-items-end w-100 d-nline-flex">
                    <mat-form-field ngDefaultControl class="col-md-6 col-lg-4">
                        <mat-label>Deal Type</mat-label>
                        <mat-select
                            *ngIf="
                                openingValuesFG.controls.parameters && openingValuesFG.controls.parameters.controls.type
                            "
                            [formControl]="openingValuesFG.controls.parameters.controls.type"
                            required
                        >
                            <mat-option *ngFor="let type of DEAL.provideTypes()" [value]="type">
                                {{ DEAL.type_LABELS[type] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field ngDefaultControl class="col-md-6 col-lg-4">
                        <mat-label>Deal Property Class</mat-label>
                        <mat-select
                            *ngIf="
                                openingValuesFG.controls.parameters &&
                                openingValuesFG.controls.parameters.controls.property_class
                            "
                            [formControl]="openingValuesFG.controls.parameters.controls.property_class"
                            required
                        >
                            <mat-option *ngFor="let type of DEAL.providePropertyClasses()" [value]="type">
                                {{ DEAL.property_class_LABELS[type] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="col-md-6 col-lg-4">
                        <input
                            *ngIf="
                                openingValuesFG.controls.parameters &&
                                openingValuesFG.controls.parameters.controls.close_of_escrow_from
                            "
                            [formControl]="$any(openingValuesFG.controls.parameters.controls.close_of_escrow_from)"
                            matInput
                            [matDatepicker]="picker"
                            placeholder="Apply Date"
                            required
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <strong class="col-md-9 col-lg-12" style="margin-bottom: 10px;">Virtual Values:</strong>

                    <mat-form-field class="col-lg-2dot4">
                        <input
                            *ngIf="
                                openingValuesFG.controls.virtual_values &&
                                openingValuesFG.controls.virtual_values.controls.count_of_deals
                            "
                            [formControl]="openingValuesFG.controls.virtual_values.controls.count_of_deals"
                            matInput
                            type="number"
                            placeholder="Deal Count"
                        />
                    </mat-form-field>

                    <mat-form-field class="col-lg-2dot4">
                        <input
                            *ngIf="
                                openingValuesFG.controls.virtual_values &&
                                openingValuesFG.controls.virtual_values.controls.sum_of_sales_price
                            "
                            [formControl]="openingValuesFG.controls.virtual_values.controls.sum_of_sales_price"
                            matInput
                            placeholder="Sales Price Total"
                            type="number"
                        />
                    </mat-form-field>

                    <mat-form-field class="col-lg-2dot4">
                        <input
                            *ngIf="
                                openingValuesFG.controls.virtual_values &&
                                openingValuesFG.controls.virtual_values.controls.sum_of_gross_commission
                            "
                            [formControl]="openingValuesFG.controls.virtual_values.controls.sum_of_gross_commission"
                            matInput
                            placeholder="Gross Commission Total"
                            type="number"
                        />
                    </mat-form-field>

                    <mat-form-field class="col-lg-2dot4">
                        <input
                            *ngIf="
                                openingValuesFG.controls.virtual_values &&
                                openingValuesFG.controls.virtual_values.controls.total_company_split
                            "
                            [formControl]="openingValuesFG.controls.virtual_values.controls.total_company_split"
                            matInput
                            type="number"
                            placeholder="Company Split Total"
                        />
                    </mat-form-field>

                    <mat-form-field class="col-lg-2dot4">
                        <input
                            *ngIf="
                                openingValuesFG.controls.virtual_values &&
                                openingValuesFG.controls.virtual_values.controls.total_agent_split
                            "
                            [formControl]="openingValuesFG.controls.virtual_values.controls.total_agent_split"
                            matInput
                            type="number"
                            placeholder="Sales Entity Split Total"
                        />
                    </mat-form-field>

                    <div class="d-flex flex-column w-100 m-3">
                        <div class="d-flex">
                            <button
                                mat-raised-button
                                style="width: fit-content;"
                                class="mb-3"
                                [matMenuTriggerFor]="productValueMenu"
                            >
                                Add Product Income/Expense
                            </button>
                        </div>

                        <mat-menu #productValueMenu="matMenu">
                            <button
                                mat-menu-item
                                *ngFor="let productValueType of object_values(available_products_values_types_SET)"
                                (click)="addNewProductValue(productValueType)"
                            >
                                {{ available_products_values_types_LABELS_SET[productValueType] }}
                            </button>
                        </mat-menu>

                        <div
                            class="product-values d-flex flex-column"
                            *ngFor="let productFG of this.productsValues; let ind = index"
                        >
                            <div class="product-value d-flex flex-row align-items-center">
                                <app-product-service-selector
                                    class="four-width"
                                    [placeholder]="'Product'"
                                    [productServiceControl]="productFG.controls.product_id"
                                    [validators]="null"
                                    [hideReselect]="true"
                                    [allowedProductsIdsOnly]="true"
                                    [allowedProductsIdsToSelect]="capableMetricsByProductIds"
                                >
                                </app-product-service-selector>
                                <mat-form-field class="col-lg-2dot4">
                                    <input
                                        [formControl]="productFG.controls.value!"
                                        matInput
                                        type="number"
                                        [placeholder]="
                                            available_products_values_types_LABELS_SET[productFG.controls.type?.value]
                                        "
                                    />
                                </mat-form-field>
                                <button
                                    (click)="removeProductValue(ind)"
                                    mat-icon-button
                                    type="button"
                                    [matTooltip]="
                                        'Remove ' +
                                        available_products_values_types_LABELS_SET[productFG.controls.type?.value]
                                    "
                                >
                                    <mat-icon>close</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <button (click)="delete.emit()" mat-icon-button type="button" matTooltip="Remove value">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </section>
    `,
    styleUrls: ['../contact-edit.component.css']
})
export class OpeningPerformanceValuesItemComponent implements OnInit, OnDestroy {
    available_products_values_types_SET: {income: string; expense: string} = {
        income: 'income',
        expense: 'expense'
    };
    available_products_values_types_LABELS_SET: {[keys: string]: string} = {
        income: 'Product Income',
        expense: 'Product Expense'
    };
    private unsubscribe: Subject<void> = new Subject();
    capableMetricsByProductIds: number[] = [];
    productsValues: GenericFormGroup<IProductCAPValue>[] = [];

    @Input() openingValuesFG: GenericFormGroup<OpeningPerformanceValuesEntity> =
        new GenericFormGroup<OpeningPerformanceValuesEntity>(
            new OpeningPerformanceValuesEntity().setParameters(
                new MultipleTargetsDealsQueryModel().setStatus(Deal.status_SET.closed)
            )
        );
    DEAL: any = Deal;

    @Output() delete = new EventEmitter();

    object_values(obj: {[key: string]: any}): string[] {
        return Object.values(obj);
    }

    constructor(
        protected sessionService: SessionService,
        protected calculationSettingsService: CalculationSettingsService
    ) {}

    addNewProductValue(type: string, productId: number | null = null, value = 0) {
        const newProductForm = new GenericFormGroup<IProductCAPValue>({product_id: productId, type, value: value});
        newProductForm.valueChanges.pipe(debounceTime(100), takeUntil(this.unsubscribe)).subscribe(() => {
            this.generateProductsSummary();
        });
        this.productsValues.push(newProductForm);
    }

    generateProductsSummary() {
        const total_product_values: {[keys: string]: {[keys: string]: number}} = {};

        this.productsValues
            .filter((productValueFG) => !!productValueFG.controls.product_id?.value)
            .forEach((productValueFG) => {
                const productValue: IProductCAPValue = productValueFG.value;
                let typeKey: string | null = null;
                if (productValue.type === this.available_products_values_types_SET.income) {
                    typeKey = 'total_income';
                }
                if (productValue.type === this.available_products_values_types_SET.expense) {
                    typeKey = 'total_expense';
                }
                if (productValue.product_id && typeKey) {
                    if (
                        total_product_values[productValue.product_id] &&
                        typeof total_product_values[productValue.product_id] === 'object'
                    ) {
                        total_product_values[productValue.product_id][typeKey] = productValue.value;
                    } else {
                        total_product_values[productValue.product_id] = {
                            [typeKey]: productValue.value
                        };
                    }
                }
            });
        if (Object.keys(total_product_values).length > 0) {
            this.openingValuesFG.controls.virtual_values!.controls.total_product_values = new GenericFormGroup<{
                [keys: number]: {[keys: string]: number};
            }>(total_product_values);
        } else {
            delete this.openingValuesFG.controls.virtual_values?.controls.total_product_values;
        }
    }

    ngOnInit() {
        this.calculationSettingsService.calculationSettings$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((calculationSettings) => {
                this.capableMetricsByProductIds = calculationSettings.capable_metrics_by_product_ids!;
            });
        this.calculationSettingsService.getCalculationSettings();
        const totalProductValues: {[keys: number]: {[keys: string]: number}} =
            this.openingValuesFG.controls.virtual_values?.controls.total_product_values?.value;

        if (typeof totalProductValues === 'object' && totalProductValues) {
            Object.keys(totalProductValues).forEach((productId) => {
                Object.keys(totalProductValues[Number(productId)]).forEach((valueType) => {
                    let type;
                    switch (valueType) {
                        case 'total_income':
                            type = this.available_products_values_types_SET.income;
                            break;
                        case 'total_expense':
                            type = this.available_products_values_types_SET.expense;
                            break;
                    }
                    if (type) {
                        this.addNewProductValue(
                            type,
                            Number(productId),
                            totalProductValues[Number(productId)][valueType]
                        );
                    }
                });
            });
        }
    }

    removeProductValue(elementIndex: number) {
        this.productsValues.splice(elementIndex, 1);
        this.generateProductsSummary();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
