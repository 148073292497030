import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationsServiceZipi} from '../../notifications/notifications.service';
import {ProfilesService} from '../../../services/profiles.service';
import {Profile} from '../../../models/profile';
import {IContact} from '@cyberco-nodejs/zipi-typings';
import {ShipperContactsService} from '../../../services/api/shipper.contacts.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SkyslopeAuth} from '../services/okta/skyslope-auth.service';

@Component({
    selector: 'app-invite',
    templateUrl: 'invite.component.html',
    styleUrls: ['invite.component.scss']
})
export class InviteComponent implements OnDestroy, AfterViewInit {
    private unsubscribe: Subject<void> = new Subject();
    protected inviteHash: string | null = null;
    public invitedProfile: Profile | null = null;
    private isNewOktaUser = true;
    public isLoaded = false;
    private oktaSignIn: any;
    profileContact: IContact | null = null;
    showErrorCard: boolean = false;

    constructor(
        protected profilesService: ProfilesService,
        protected route: ActivatedRoute,
        protected router: Router,
        private contactService: ShipperContactsService,
        protected notificationsService: NotificationsServiceZipi,
        public authService: AuthService,
        private skyslopeAuth: SkyslopeAuth
    ) {
        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['invite_hash']) {
                this.inviteHash = params['invite_hash'];
            }
        });
    }

    async showLogin(): Promise<void> {
        this.oktaSignIn = await this.skyslopeAuth.createWidget('okta-login-container', this.isNewOktaUser);

        this.oktaSignIn.on('afterRender', async (context: {controller: string}) => {
            if (this.invitedProfile?.email) {
                const fieldIds = ['input80', 'idp-discovery-username'];

                for (const fieldId of fieldIds) {
                    let field: any = document.getElementById(fieldId);
                    if (field) {
                        field.value = this.invitedProfile?.email;
                        field.dispatchEvent(new Event('input', {bubbles: true}));
                        field.disabled = true;

                        await this.skyslopeAuth.closeSession();
                    }
                }
            }
        });
    }

    ngAfterViewInit(): void {
        if (!this.inviteHash) {
            this.notificationsService.addError('No invite_hash provided');

            throw new Error('No invite_hash provided');
        }

        this.profilesService.getInvitedProfile(this.inviteHash).then((invitedProfileInfo) => {
            if ((invitedProfileInfo as any).error) {
                this.showErrorCard = true;
            } else {
                this.invitedProfile = invitedProfileInfo.profile;

                if (!this.invitedProfile.profile_id) {
                    return;
                }
                this.contactService
                    .getRelatedToProfileContact(this.invitedProfile.profile_id)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((contact) => {
                        this.profileContact = contact;
                    });
                this.isNewOktaUser = invitedProfileInfo.existingUser ? false : true;
                this.isLoaded = true;
                sessionStorage.setItem('inviteHash', this.inviteHash!);
                setTimeout(() => {
                    this.showLogin();
                }, 1000);
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
