import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-zipi-financial-after-setup-dialog',
    templateUrl: './zipi-financial-after-setup-dialog.component.html',
    styleUrls: ['./zipi-financial-after-setup-dialog.component.scss']
})
export class ZipiFinancialAfterSetupDialogComponent implements OnInit {
    url: string | null = null;

    constructor(
        public dialogRef: MatDialogRef<ZipiFinancialAfterSetupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        // this.url =
    }

    ngOnInit() {}
}
