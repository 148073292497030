import {BehaviorSubject, combineLatest as observableCombineLatest, firstValueFrom, Subject} from 'rxjs';

import {debounceTime, filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    ViewChild,
    ChangeDetectorRef,
    ViewChildren,
    QueryList,
    ElementRef
} from '@angular/core';

import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
    UntypedFormArray,
    UntypedFormControl,
    AbstractControl
} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatTabGroup} from '@angular/material/tabs';
import {select, Store} from '@ngrx/store';
import {sortBy, forEach, assign, trim, get as getProp, filter as filterObj, isEqual} from 'lodash-es';

import {
    IContact,
    IContactLocationTable,
    IContactPerson,
    IPaymentGateway,
    IPaymentMethod,
    IWildcard,
    IWildcardInstance
} from '@cyberco-nodejs/zipi-typings';

import {DeleteContact, UpdContact, AddContact} from '../store/contacts.actions';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {clearFormArray} from 'app/utilities';

import {NotificationsService} from 'angular2-notifications';
import {AddCreditCardDialogComponent} from 'app/layouts/cards-dialogs/add-credit-card-dialog/add-credit-card-dialog.component';
import {UsersAndLicencesApiService} from 'app/modules/account-info/users-licenses/users-and-licences.api.service';
import {PermissionsSource} from 'app/services/sources/permissions.source';
import {NEW_CONTACT_LOCATION, extractLocationComponents, simpleEmailValidator} from '../store/contact.utilities';
import {PaymentMethodsService} from '../../profile/services/payment-methods.service';
import {Deal} from 'app/models/deal';
import {GenericFormArray, GenericFormGroup} from 'app/entites/generic.entity';
import {ChipNode} from '../../account-info/compensation/models/chip-node';
import {ContactPartLink} from 'app/models/contact-part-link';
import {CompanyGatewayService} from 'app/services/api/finance/company-gateway.service';
import {MembershipService} from 'app/services/membership.service';
import {AddMyCreditCardForContactDialogComponent} from 'app/layouts/cards-dialogs/add-my-credit-card-for-contact-dialog/add-my-credit-card-for-contact-dialog.component';
import {ContactProductsMappingDialogComponent} from '../contact-dialogs/contact-products-mapping-dialog/contact-products-mapping-dialog.component';
import {ShipperContactsService} from 'app/services/api/shipper.contacts.service';
import {WildcardsService} from 'app/services/api/wildcards.service';
import {ContactTypeCreationDialogComponent} from '../contact-dialogs/contact-type-dialog/contact-type-creation-dialog.component';
import {CreateWildcardDialogComponent} from './compliance/create-wildcard-dialog.component';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {ContactType} from 'app/models/contact-type';
import {ContactTypeService} from 'app/services/api/contact-type.service';
import {SessionService} from 'app/services/session.service';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {Wildcard} from 'app/models/wildcard';
import {Router} from '@angular/router';
import {AvailableProfilesSource} from 'app/services/sources/available-profiles.source';
import {Profile} from 'app/models/profile';
import {AlertDeleteContactDialogComponent} from '../contact-dialogs/alert-delete-contact-dialog.component';
import {ProfilesService} from 'app/services/profiles.service';
import {CompanyPaymentMethodsService} from 'app/services/api/finance/company-payment-methods.service';
import {ContactClass} from 'app/models/contact-class';
import {ContactClassService} from 'app/services/api/contact-class.service';
import {ContactClassCreationDialogComponent} from '../contact-dialogs/contact-class-dialog/contact-class-creation-dialog.component';
import {RbacService} from '../../rbac/rbac.service';
import {selectWildcards} from 'app/store/company-wide/company-wide.selectors';
import {ICompanyWideState} from '../../../store/company-wide/company-wide.reducer';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {IStartingDealCount} from '@cyberco-nodejs/zipi-typings/companies.typings';
import {ZipiFinConnectToLedgerAccountDialogComponent} from '../../account-info/company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-fin-connect-to-ledger-account-dialog/zipi-fin-connect-to-ledger-account-dialog.component';
import {phoneMaskitoOptions} from '../../../utilities/maskito';
import {CalculationSettingsService} from '../../../services/calculation-settings.service';
import {CalculationSettingsService as NewCalculationSettingsService} from '@app/services/new-calculation-settings.service';

class ExtendedProfile extends Profile {
    starting_deal_count: IStartingDealCount | undefined = undefined;
}

@Component({
    selector: 'app-contact-edit',
    templateUrl: './contact-edit.component.html',
    styleUrls: ['./contact-edit.component.css']
})
export class ContactEditComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeSave: Subject<void> = new Subject();
    private stopListenDisployName: Subject<void> = new Subject();

    mentorContacts: GenericFormArray<ChipNode> = new GenericFormArray([]);
    mentorTeamLeaderContacts: GenericFormArray<ChipNode> = new GenericFormArray([]);
    trxCoordinatorContacts: GenericFormArray<ChipNode> = new GenericFormArray([]);
    availableWildcards: IWildcard[] = [];
    wildcards: IWildcard[] = [];
    wildcardInstances: IWildcardInstance[] = [];
    wildcardInstancesToDelete: IWildcardInstance[] = [];
    DEAL = Deal;
    WILDCARD = Wildcard;

    @Input() contact: IContact | undefined;
    @Input() contact_id: number | undefined;
    @Input() tabIndex: number | undefined;

    isExistingEmail: boolean[] = [];
    isEmailDuplicates: boolean[] = [];
    isSubmitDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    contactForm: UntypedFormGroup;
    profileForm: GenericFormGroup<ExtendedProfile> = new GenericFormGroup(new ExtendedProfile(), 'change');
    individualForm: UntypedFormGroup;
    starting_deal_count: UntypedFormGroup;

    recurringInvoicesArrayCtrl: UntypedFormArray | undefined;

    personsArrayCtrl: UntypedFormArray | undefined;
    locationsArrayCtrl: UntypedFormArray | undefined;
    wildcardInstanceArrayCtrl: Array<UntypedFormGroup> = [];
    wildcardInstanceArrayCtrlInitial: Array<UntypedFormGroup> | undefined;

    profilePermissions = {
        contacts__manage_compliance: false,
        contacts__manage_details: false,
        contacts__manage_locations: false,
        contacts__manage_payment_methods: false,
        contacts__manage_company_disbursement_instruction: false,
        contacts__manage_approvals: false,
        contacts__manage_payroll: false,
        contacts__manage_notes: false
    };

    myGateways: IPaymentGateway[] = [];
    contactGateways: IPaymentGateway[] = [];

    contactTypes: ContactType[] = [];
    contactClasses: ContactClass[] = [];

    @ViewChild('tabs', {static: true}) tabs: MatTabGroup | undefined;
    @ViewChildren('phoneInput') phoneInputs: QueryList<ElementRef> | undefined;
    @ViewChildren('faxInput') faxInputs: QueryList<ElementRef> | undefined;
    @ViewChild('ssnInput') ssnInput: ElementRef | undefined;

    locationPickerCtrl = new UntypedFormControl();
    editLocationIdx: number | undefined;

    profile: Profile | undefined;
    profiles: Profile[] = [];
    currentProfile: Profile | undefined;

    isCompanyCtrl = new UntypedFormControl();

    isDefaultCompanyContact: boolean = false;
    isDivisionContact: boolean = false;
    divisionEmail: string = '';
    directoryEmail: string | null = null;

    public phoneInputMask = phoneMaskitoOptions;

    errors: {[x: string]: boolean}[] = [];

    isCompanyZipiFinancialActivated: boolean;

    isNeedToAutoCreateInvoices: boolean;
    isNeedToAutoCreateBills: boolean;

    isAuthorizePanelOpen: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store<ICompanyWideState>,
        public dialog: MatDialog,
        private changeDetector: ChangeDetectorRef,
        private ntfs: NotificationsService,
        protected permissionsSource: PermissionsSource,
        public usersAndLicencesApiService: UsersAndLicencesApiService,
        private profileService: ProfilesService,
        private paymentMethodsService: PaymentMethodsService,
        private companyGatewayService: CompanyGatewayService,
        private cs: ShipperContactsService,
        private membershipService: MembershipService,
        private wildcardsService: WildcardsService,
        private contactTypeService: ContactTypeService,
        private contactClassService: ContactClassService,
        private companyPaymentMethodsService: CompanyPaymentMethodsService,
        public sessionService: SessionService,
        public currentProfileSource: CurrentProfileSource,
        private router: Router,
        protected availableProfilesSource: AvailableProfilesSource,
        private contactsService: ShipperContactsService,
        protected rbacService: RbacService,
        protected calculationSettingsService: CalculationSettingsService,
        protected newCalculationSettingsService: NewCalculationSettingsService
    ) {
        this.isCompanyZipiFinancialActivated = false;
        this.isNeedToAutoCreateInvoices = false;
        this.isNeedToAutoCreateBills = false;
        this.personsArrayCtrl = this.fb.array([]);
        this.locationsArrayCtrl = this.fb.array([]);
        this.recurringInvoicesArrayCtrl = this.fb.array([]);
        this.wildcardInstanceArrayCtrl = [];
        this.wildcardInstanceArrayCtrlInitial = [];
        this.starting_deal_count = this.fb.group(
            this.DEAL.provideTypes().reduce(
                (total, currentValue) => ({
                    ...total,
                    [currentValue]: [null, [Validators.min(0), Validators.max(9999)]]
                }),
                {}
            )
        );
        this.contactForm = this.fb.group(
            {
                display_name: [
                    {
                        value: '',
                        disabled:
                            this.contact && (this.isDefaultCompanyContact || this.isDivisionContact) ? true : false
                    },
                    [Validators.required, Validators.minLength(2), Validators.maxLength(100)]
                ],
                company_name: ['', [Validators.maxLength(100)]],
                disbursement_instructions: '',
                phone: '',
                fax: '',
                email: '',
                contact_persons: this.personsArrayCtrl,
                contact_locations: this.locationsArrayCtrl,
                notes: ['', [Validators.maxLength(5000)]],
                default_location: null,
                mentor: null,
                mentor_team_leader: null,
                trx_coordinator: null,
                trx_coordinator_amount: null,
                owner_type: 'company',
                product_mapping: [[], []],
                partner_company_settings: this.fb.group({
                    show_my_docs_as_source: [false, []],
                    approve_income_bills: [false, []],
                    approve_payout_income_bills: [false, []],
                    approve_income_invoices: [false, []],
                    approve_payout_income_invoices: [false, []],
                    approve_recurring_invoice_ids: this.recurringInvoicesArrayCtrl
                }),
                contact_type_ids: [],
                contact_class_ids: []
            },
            {updateOn: 'change'}
        );
        this.individualForm = this.fb.group({});
        this.starting_deal_count = this.fb.group({});
    }

    addRecurringInvoice(ri: any) {
        const recurringInvoice = this.fb.group({
            recurring_invoice_id: [ri.recurring_invoice_id],
            title: [ri.title, [Validators.required, Validators.minLength(1)]]
        });

        this.changeDetector.detectChanges();

        return recurringInvoice;
    }

    addLocation(lcn: Partial<IContactLocationTable> = NEW_CONTACT_LOCATION) {
        const isFirst = this.contactForm.controls.contact_locations.value.length === 0;
        const locationGroup = this.fb.group({
            contact_location_id: lcn.contact_location_id || null,
            contact_id: lcn.contact_id || null,
            label: lcn.label,
            // abbr: lcn.abbr,
            unit_number: lcn.unit_number,
            street_number: lcn.street_number,
            street_address: lcn.street_address,
            zip: lcn.zip,
            city: lcn.city,
            state: lcn.state,
            country: lcn.country
        });

        (<UntypedFormArray>this.contactForm.get('contact_locations')).push(locationGroup);

        this.changeDetector.detectChanges();

        return this.contactForm.value.contact_locations.length - 1;
    }

    private makeFormDirty() {
        if (this.contactForm.dirty) {
            return;
        }
        this.contactForm.markAsDirty();
    }

    emailCheck(person: AbstractControl, index: number) {
        this.isExistingEmail[index] = false;

        // check if persons emails are unique in current contact
        this.isEmailDuplicates = this.isEmailDuplicates.map(() => false);
        const contactPersonsEmail = this.contactForm.controls.contact_persons.value.map((item: IContactPerson) => {
            if (this.contact && item.type === 'main_person') {
                return this.contact.contact_persons.find((contact_person) => contact_person.type === 'main_person')
                    ?.email;
            }
            return item.email;
        });

        contactPersonsEmail.forEach((item: string, ii: number) => {
            if (contactPersonsEmail.indexOf(item) !== ii && !this.isExistingEmail[ii]) {
                this.isEmailDuplicates[ii] = true;
            }
        });
        if (this.isEmailDuplicates.find((item) => item) || this.isExistingEmail.find((item) => item)) {
            this.isSubmitDisabled.next(true);
        } else {
            this.isSubmitDisabled.next(false);
        }

        // check persons email in all contacts (except current if it's edit contact page)
        if (
            person.get('email')!.value &&
            person.get('email')!.value.length &&
            this.contact &&
            this.contact.contact_id
        ) {
            this.isSubmitDisabled.next(true);
            const data = {
                email: person.get('email')!.value,
                contact_id: this.contact.contact_id
            };
            return this.usersAndLicencesApiService
                .checkContactEmail(data)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response.result) {
                        this.isExistingEmail[index] = true;
                        this.isEmailDuplicates[index] = false;
                    } else {
                        this.isExistingEmail[index] = false;
                    }
                    if (this.isEmailDuplicates.find((item) => item) || this.isExistingEmail.find((item) => item)) {
                        this.isSubmitDisabled.next(true);
                    } else {
                        this.isSubmitDisabled.next(false);
                    }
                });
        }
    }

    removeLocation(ii: number) {
        return () => {
            (<UntypedFormArray>this.contactForm.get('contact_locations')).removeAt(ii);
            this.makeFormDirty();
        };
    }

    // Add new person or patch person or department
    addPerson(p?: IContactPerson, isDepartment?: true): UntypedFormGroup {
        const personGroup = this.fb.group({
            contact_person_id: (p && p.contact_person_id) || null,
            contact_id: (p && p.contact_id) || null,
            first_name: [
                {value: '', disabled: this.isDefaultCompanyContact ? true : false},
                [Validators.required, Validators.minLength(2), Validators.maxLength(40)]
            ],
            last_name: ['', [Validators.minLength(2), Validators.maxLength(40)]],
            full_name: '',
            preferred_name: ['', [Validators.minLength(2), Validators.maxLength(40)]],
            phone: '',
            fax: '',
            email: ['', [simpleEmailValidator]],
            type: isDepartment ? 'department' : 'person',
            default_location: null
        });
        p && personGroup.patchValue(p);

        // don't need this anymore - concat on server side
        // personGroup.valueChanges.pipe(
        //     takeUntil(this.unsubscribe),
        //     throttleTime(250),
        //     )
        //     .subscribe(person => {
        //         const full_name = [person.first_name, person.last_name].map(trim).join(' ');
        //         personGroup.patchValue({ full_name }, {emitEvent: false});
        //         if (personGroup.value.type !== 'main_person') { return; }
        //         this.changeDetector.detectChanges();
        //     });

        (<UntypedFormArray>this.contactForm.get('contact_persons')).push(personGroup);
        this.isExistingEmail.push(false);
        this.isEmailDuplicates.push(false);
        return personGroup;
    }

    removePerson(ii: number, ctrl: AbstractControl) {
        if (ctrl.value.type !== 'main_person') {
            (<UntypedFormArray>this.contactForm.get('contact_persons')).removeAt(ii);
            this.makeFormDirty();
        }
        delete this.isExistingEmail[ii];
        delete this.isEmailDuplicates[ii];
    }

    removeRecurring(ii: number) {
        this.recurringInvoicesArrayCtrl!.removeAt(ii);
        this.makeFormDirty();
    }

    private prepareContact() {
        if (!this.isCompanyCtrl.value) {
            this.resetCompany();
        } else {
            const mainPerson = this.contactForm.controls.contact_persons.value.find(
                (person: IContactPerson) => person.type === 'main_person'
            );
            if (mainPerson) {
                this.contactForm.controls.company_name.setValue(mainPerson.first_name);
            }
        }

        const contact = this.contactForm.getRawValue();

        const contactPersons = filterObj(contact.contact_persons, (p: IContactPerson) =>
            [p.first_name, p.last_name, p.email, p.phone, p.fax].some((val) => !!val)
        ).map((p: IContactPerson) => {
            if (p.default_location) {
                p.default_location = {...p.default_location, contact_id: contact.contact_id};
            }
            if (p.phone) {
                p.phone = p.phone.replace(/[^0-9]/g, '');
            }
            if (p.fax) {
                p.fax = p.fax.replace(/[^0-9]/g, '');
            }
            return p;
        });

        return Object.assign({}, contact, {
            contact_locations: contact.contact_locations,
            contact_persons: contactPersons,
            partner_company_settings: {
                ...contact.partner_company_settings,
                approve_recurring_invoice_ids: this.recurringInvoicesArrayCtrl
                    ? this.recurringInvoicesArrayCtrl.getRawValue()
                    : null
            }
        });
    }

    createWildcard() {
        const dialogRef = this.dialog.open(CreateWildcardDialogComponent, {width: '600px'});
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {});
    }

    addWildcardInstance(event: MatSelectChange) {
        const matSelect: MatSelect = event.source;
        matSelect.value = undefined;
        const item: IWildcard = event.value;

        if (typeof item !== 'undefined' && this.contact) {
            const wildcardInstance = this.fb.group(
                {
                    wildcard_instance_id: undefined,
                    item_title: item.title,
                    wildcard_type_sk: item.wildcard_type_sk,
                    wildcard_fk_id: item.wildcard_id,
                    contact_fk_id: this.contact.contact_id
                },
                {updateOn: 'change'}
            );
            switch (item.wildcard_type_sk) {
                case Wildcard.type_SET.numeric:
                    wildcardInstance.addControl('value', new UntypedFormControl('', Validators.required));
                    break;
                case Wildcard.type_SET.contact:
                    wildcardInstance.addControl('value', new GenericFormArray([], Validators.required));
                    break;
                case Wildcard.type_SET.text:
                    wildcardInstance.addControl('value', new UntypedFormControl('', Validators.required));

                    break;
            }

            this.availableWildcards.splice(
                this.availableWildcards.findIndex((wildcard) => wildcard.wildcard_id === item.wildcard_id),
                1
            );

            this.wildcardInstanceArrayCtrl.push(wildcardInstance);
        }
    }

    onBlurMethod(index: number | null, type: 'phone' | 'fax') {
        if (index && (type === 'phone' || type === 'fax')) {
            const tel = this.contactForm.controls.contact_persons.value[index][type];
            if (tel && tel.replace(/[^0-9]/g, '') && tel.replace(/[^0-9]/g, '').length < 10) {
                this.errors[index] = {[type]: true};
            } else {
                this.errors[index] = {[type]: false};
            }
        }
    }

    onClickMethod(index: number | null, type: 'phone' | 'fax' | 'ssn') {
        if (index && type === 'phone' && this.phoneInputs) {
            let length = this.phoneInputs.toArray()[index].nativeElement.value.replace(/[^0-9]/g, '').length;
            if (length >= 3) {
                length++;
            }
            if (length >= 7) {
                length++;
            }

            this.phoneInputs.toArray()[index].nativeElement.selectionStart = length;
            this.phoneInputs.toArray()[index].nativeElement.selectionEnd = length;
        } else if (index && type === 'fax' && this.faxInputs) {
            let length = this.faxInputs.toArray()[index].nativeElement.value.replace(/[^0-9]/g, '').length;
            if (length >= 3) {
                length++;
            }
            if (length >= 7) {
                length++;
            }

            this.faxInputs.toArray()[index].nativeElement.selectionStart = length;
            this.faxInputs.toArray()[index].nativeElement.selectionEnd = length;
        } else if (type === 'ssn' && this.ssnInput) {
            let length = this.ssnInput.nativeElement.value.replace(/[^0-9]/g, '').length;
            if (length >= 3) {
                length++;
            }
            if (length >= 6) {
                length++;
            }

            this.ssnInput.nativeElement.selectionStart = length;
            this.ssnInput.nativeElement.selectionEnd = length;
        }
    }

    submitForm() {
        // close previous observable
        this.unsubscribeSave.next();

        this.isSubmitDisabled
            .pipe(
                debounceTime(100), // wait sending emailCheck method
                take(2), // to avoid unexpected creating or updating contact
                filter((e) => e === false), // accept only suitable email
                takeUntil(this.unsubscribeSave)
            )
            .subscribe((res) => {
                const isWildcardInstancesValid = this.wildcardInstanceArrayCtrl.every(
                    (wildcardInstanceFG) => wildcardInstanceFG.valid
                );

                if (!isWildcardInstancesValid) {
                    this.ntfs.error('Compensation Attributes are not valid');
                    return;
                }

                // validate persons' phone and fax
                if (
                    this.errors &&
                    Object.keys(this.errors).find(
                        (key) => this.errors[Number(key)]['phone'] || this.errors[Number(key)]['fax']
                    )
                ) {
                    return;
                }

                const wildcardInstances = this.wildcardInstanceArrayCtrl.map((wildcardInstanceFG) => {
                    const newWildcardInstance = wildcardInstanceFG.getRawValue();
                    newWildcardInstance.value =
                        newWildcardInstance.wildcard_type_sk === Wildcard.type_SET.contact
                            ? newWildcardInstance.value[0]
                            : newWildcardInstance.value;
                    return newWildcardInstance;
                });

                if (this.contact && this.contact.contact_id) {
                    // run wildcard updating in case if user changed anniversary_date or
                    // commission calculation modifiers or licensing/memberships or compensation attributes
                    // because wildcard updating will run recalculation
                    if (
                        !isEqual(this.wildcardInstanceArrayCtrlInitial, wildcardInstances) ||
                        this.profileForm.controls.anniversary_date!.dirty ||
                        this.profileForm.controls.opening_performance_values!.dirty ||
                        this.profileForm.controls.profile_memberships!.dirty
                    ) {
                        const data = {
                            instancesToDelete: this.wildcardInstancesToDelete,
                            instancesToUpdate: wildcardInstances
                        };
                        this.wildcardsService.createWildcardInstance(this.contact.contact_id, data).then(() => {
                            this.updateContact();
                        });
                    } else {
                        this.updateContact();
                    }
                } else {
                    this.createContact();
                }
            });
    }

    createContact() {
        const contactPersons = filterObj(this.contactForm.controls.contact_persons.value, (p: IContactPerson) =>
            [p.first_name, p.last_name, p.email, p.phone, p.fax].some((val) => !!val)
        );
        if (this.contactForm.invalid) {
            this.contactForm.updateValueAndValidity({onlySelf: false, emitEvent: true});
            return;
        } else if (!contactPersons.length) {
            this.ntfs.error('Add at least one contact person.');
            return;
        }

        this.store.dispatch(new AddContact(Object.assign({}, this.prepareContact())));
    }

    async updateContact() {
        if (this.contact) {
            const contact: IContact & {
                is_need_to_auto_create_invoices: boolean;
                is_need_to_auto_create_bills: boolean;
            } = this.contact as unknown as IContact & {
                is_need_to_auto_create_invoices: boolean;
                is_need_to_auto_create_bills: boolean;
            };
            if (contact) {
                if (this.contactForm.invalid) {
                    this.contactForm.updateValueAndValidity({onlySelf: false, emitEvent: true});
                    return;
                }
                if (this.profileForm.invalid) {
                    this.profileForm.updateValueAndValidity({onlySelf: false, emitEvent: true});
                    if (!this.profileForm.controls.opening_performance_values!.valid) {
                        this.ntfs.error('Commission Calculation Modifiers are not valid');
                    }
                    if (!this.profileForm.controls.profile_memberships!.valid) {
                        this.ntfs.error('Licensing / Memberships are not valid');
                    }
                    return;
                }
                this.contact = Object.assign({}, contact, this.prepareContact(), {
                    related_profile: {
                        ...contact.related_profile,
                        starting_deal_count: this.starting_deal_count.getRawValue()
                    }
                });

                contact['is_need_to_auto_create_invoices'] = this.isNeedToAutoCreateInvoices;
                contact['is_need_to_auto_create_bills'] = this.isNeedToAutoCreateBills;

                if (this.contact) {
                    this.store.dispatch(new UpdContact({data: this.contact}));
                }
                this.initEditContact();

                if (!contact.related__profile_fk_id) {
                    return;
                }

                const isProfileEditAllowed = await this.rbacService.isAllowed({company_settings__manage_users: true});
                if (isProfileEditAllowed) {
                    this.usersAndLicencesApiService.update(
                        contact.related__profile_fk_id,
                        assign(this.profile, this.profileForm.getRawValue())
                    );
                }
            }
        }
    }

    unmarkSystemGenerated() {
        const contact = this.contact;
        if (contact) {
            this.contact = Object.assign({}, this.contact, this.prepareContact(), {is_system_generated: false});
            this.store.dispatch(new UpdContact({data: contact}));
            this.contactForm.reset(this.contact);
            // this.contactForm.get('category').patchValue(this.contact.category[0]);
            // this.contactForm.get('category').patchValue(this.contact.category || []);
        }
    }

    deleteContact() {
        const contact = this.contact;
        if (contact) {
            this.cs
                .isDeletableContact(Number(contact.id))
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (!result.isDeletable) {
                        this.dialog.open(AlertDeleteContactDialogComponent, {
                            minWidth: 200,
                            maxWidth: 400,
                            data: result
                        });
                    } else {
                        const dialogRef = this.dialog.open(ConfirmComponent, {
                            minWidth: 320,
                            data: {
                                title: 'Deleting contact',
                                message: `Contact "${contact.display_name}" will be deleted`
                            }
                        });

                        dialogRef
                            .afterClosed()
                            .pipe(
                                filter((pn) => !!pn),
                                takeUntil(this.unsubscribe)
                            )
                            .subscribe((ok) => {
                                this.store.dispatch(new DeleteContact(Number(contact.id)));
                            });
                    }
                });
        }
    }

    resetCompany() {
        this.contactForm.patchValue({
            company_name: ''
        });
    }

    initIndividualForm(p?: IContactPerson) {
        this.individualForm = this.addPerson();
        this.individualForm.patchValue({type: 'main_person'});
        if (p) {
            this.individualForm.patchValue(p);
        }
    }

    private initForm() {
        // commented because in html this input also commented
        // this.contactForm.controls.trx_coordinator_amount.valueChanges.subscribe(next => {
        //     this.contactForm.controls.trx_coordinator_amount.markAsDirty();
        // });

        this.contactForm.controls.display_name.valueChanges
            .pipe(
                tap((val) => {
                    if (this.stopListenDisployName.closed) {
                        return;
                    }
                    this.stopListenDisployName.next();
                    this.stopListenDisployName.complete();
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    }

    approveBills($event: MatCheckboxChange) {
        if (this.contact && $event.checked === true && this.contact.partner__company_fk_id) {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                minWidth: 350,
                // minHeight: 200,
                data: {
                    title: `Bills Auto Creation`,
                    message: `Also Auto-Create all past Bills`,
                    buttonOkMessage: `Confirm`
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((result) => {
                    if (result === true) {
                        this.isNeedToAutoCreateBills = true;
                    } else {
                        this.isNeedToAutoCreateBills = false;
                    }
                });
        } else {
            this.isNeedToAutoCreateBills = false;
        }
    }

    approveInvoices($event: MatCheckboxChange) {
        if (this.contact && $event.checked === true && this.contact.partner__company_fk_id) {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                minWidth: 350,
                // minHeight: 200,
                data: {
                    title: `Invoices Auto Creation`,
                    message: `Also Auto-Create all past Invoices`,
                    buttonOkMessage: `Confirm`
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((result) => {
                    if (result === true) {
                        this.isNeedToAutoCreateInvoices = true;
                    } else {
                        this.isNeedToAutoCreateInvoices = false;
                    }
                });
        } else {
            this.isNeedToAutoCreateInvoices = false;
        }
    }

    private async initProfileForm() {
        const profileId = getProp(this.contact, 'related__profile_fk_id');
        if (!profileId) {
            return;
        }

        const profiles = await this.profileService.getMyCompanyProfiles();
        const profile = profiles.find((p) => p.id === profileId);
        if (!profile) {
            return;
        }

        // @ts-ignore
        this.profileForm.patchValue(profile);
        this.profileForm.controls.starting_deal_count!.patchValue(profile.starting_deal_count);

        if (!this.profilePermissions.contacts__manage_compliance) {
            this.profileForm.controls.anniversary_date!.disable();
            this.profileForm.controls.date_of_birth!.disable();
            this.profileForm.controls.license_number!.disable();
            this.profileForm.controls.license_expiration!.disable();
            this.profileForm.controls.hire_date!.disable();
            this.profileForm.controls.termination_date!.disable();
            this.profileForm.controls.opening_performance_values!.disable();
            this.profileForm.controls.profile_memberships!.disable();
        }

        this.membershipService
            .getProfileMemberships(Number(profile.id))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((memberships) => {
                if (memberships) {
                    this.profileForm.controls.profile_memberships!.patchValue(memberships.result);
                }
            });
    }

    getSsnWithMask(ssn: string | null): string {
        if (!ssn || (ssn && ssn.length !== 9)) {
            return '';
        }
        return `${ssn.substr(0, 3)}-${ssn.substr(3, 2)}-${ssn.substr(5)}`;
    }

    private initLocations() {
        const contact = this.contact;
        if (contact) {
            clearFormArray(<UntypedFormArray>this.contactForm.controls.contact_locations);
            setTimeout(() => {
                if (contact.contact_locations && contact.contact_locations.length > 0) {
                    contact.contact_locations.forEach((lcn, idx) => this.addLocation(lcn));
                    return;
                }
            }, 0);
            // this.addLocation(<IContactLocationTable>{label: this.contact.display_name});
        }
    }

    private async initPersons() {
        if (this.contact) {
            const isAllowEdit = await this.rbacService.isAllowed({contacts__manage_details: true});
            clearFormArray(<UntypedFormArray>this.contactForm.get('contact_persons'));
            this.initIndividualForm();
            // forEach((p: IContactPerson) => {
            //     if (p.type === 'main_person') {
            //         this.individualForm.patchValue(p);
            //         if (!isAllowEdit) {
            //             this.individualForm.controls.first_name.disable();
            //             this.individualForm.controls.last_name.disable();
            //             this.individualForm.controls.preferred_name.disable();
            //             this.individualForm.controls.phone.disable();
            //             this.individualForm.controls.fax.disable();
            //             this.individualForm.controls.email.disable();
            //         } else {
            //             if (this.contact && this.contact.related__profile_fk_id) {
            //                 this.individualForm.controls.first_name.disable();
            //                 this.individualForm.controls.last_name.disable();
            //                 this.individualForm.controls.email.disable();
            //             }
            //             if (this.isDefaultCompanyContact) {
            //                 this.individualForm.controls.first_name.disable();
            //             }
            //         }
            //     } else {
            //         this.addPerson(p);
            //     }
            // }, this.contact.contact_persons);
            forEach(this.contact.contact_persons, (p: IContactPerson) => {
                if (p.type === 'main_person') {
                    this.individualForm.patchValue(p);
                    if (!isAllowEdit) {
                        this.individualForm.controls.first_name.disable();
                        this.individualForm.controls.last_name.disable();
                        this.individualForm.controls.preferred_name.disable();
                        this.individualForm.controls.phone.disable();
                        this.individualForm.controls.fax.disable();
                        this.individualForm.controls.email.disable();
                    } else {
                        if (this.contact && this.contact.related__profile_fk_id) {
                            this.individualForm.controls.first_name.disable();
                            this.individualForm.controls.last_name.disable();
                            this.individualForm.controls.email.disable();
                        }
                        if (this.isDefaultCompanyContact) {
                            this.individualForm.controls.first_name.disable();
                        }
                    }
                } else {
                    this.addPerson(p);
                }
            });
        }
    }

    private initRecurringInvoices() {
        clearFormArray(<UntypedFormArray>this.recurringInvoicesArrayCtrl);

        if (
            this.contact &&
            this.contact.partner_company_settings &&
            this.contact.partner_company_settings.approve_recurring_invoice_ids.length > 0
        ) {
            this.contact.partner_company_settings.approve_recurring_invoice_ids.forEach((ri, idx) => {
                (<UntypedFormArray>this.recurringInvoicesArrayCtrl).push(this.addRecurringInvoice(ri));
            });
            return;
        }
    }

    initEditContact() {
        const contact = this.contact;
        if (contact) {
            setTimeout(() => {
                const mutableObj: Partial<IContact> = {};
                // dirty hack to be able to add field
                // @ts-ignore todo@ Aleksey: recheck and rework
                Object.keys(contact).map((key: string) => (mutableObj[key] = contact[key]));
                if (!mutableObj.partner_company_settings) {
                    mutableObj['partner_company_settings'] = {
                        show_my_docs_as_source: false,
                        approve_income_bills: false,
                        approve_payout_income_bills: false,
                        approve_income_invoices: false,
                        approve_payout_income_invoices: false,
                        approve_recurring_invoice_ids: []
                    };
                }
                this.contactForm.patchValue(mutableObj);
                this.isCompanyCtrl.patchValue(!!contact.company_name);

                this.initPersons();
                this.initLocations();
                this.initRecurringInvoices();
            }, 0);
        }
    }

    async addCard() {
        const contact = this.contact;
        if (contact) {
            const dialogRef = this.dialog.open(AddCreditCardDialogComponent, {
                minWidth: 320,
                maxHeight: '80vh',
                data: {
                    moneySenderProfileId: contact.related__profile_fk_id,
                    invoice: null,
                    contactId: contact.id
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((ok) => {
                    if (ok) {
                        this.companyGatewayService
                            .getMyAuthGateways(Number(contact.contact_id))
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((gateways) => {
                                this.myGateways = gateways;
                            });
                        this.companyGatewayService
                            .getContactAuthGateways(Number(contact.contact_id))
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((gateways) => {
                                this.contactGateways = gateways;
                            });
                    }
                });
        }
    }

    addMyCard() {
        const contact = this.contact;
        if (contact) {
            const dialogRef = this.dialog.open(AddMyCreditCardForContactDialogComponent, {
                minWidth: 320,
                maxHeight: '80vh',
                data: {
                    contact: contact
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((ok) => {
                    if (ok) {
                        this.companyGatewayService
                            .getMyAuthGateways(Number(contact.contact_id))
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((gateways) => {
                                this.myGateways = gateways;
                            });
                        this.companyGatewayService
                            .getContactAuthGateways(Number(contact.contact_id))
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((gateways) => {
                                this.contactGateways = gateways;
                            });
                    }
                });
        }
    }

    deletePaymentMethod(methodId: number) {
        const contact = this.contact;
        if (contact) {
            this.paymentMethodsService
                .deleteMethod(methodId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((res) => {
                    if (res) {
                        this.companyGatewayService
                            .getMyAuthGateways(Number(contact.contact_id))
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((gateways) => {
                                this.myGateways = gateways;
                            });
                        this.companyGatewayService
                            .getContactAuthGateways(Number(contact.contact_id))
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((gateways) => {
                                this.contactGateways = gateways;
                            });
                    }
                });
        }
    }

    changeVisibleMode(methodId: number) {
        this.paymentMethodsService
            .changeMode(methodId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                if (res) {
                    for (const gateway of this.myGateways) {
                        if (gateway && gateway.payment_methods) {
                            for (const method of gateway.payment_methods) {
                                if (method.payment_method_id === methodId) {
                                    method.is_visible_to_connect = !method.is_visible_to_connect;
                                }
                            }
                        }
                    }
                }
            });
    }

    disconnectVisibleMethod(methodId: number) {
        const contact = this.contact;
        if (contact) {
            this.paymentMethodsService
                .disconnectMethod(methodId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((res) => {
                    if (res) {
                        this.router.navigate([`/contacts/${contact.contact_id}`]);
                    }
                });
        }
    }

    createNewLocation(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        // this.addLocation();
        if (this.tabs) {
            this.tabs.selectedIndex = 1;
        }
    }

    initDisplayNameWatchers() {
        observableCombineLatest(
            this.isCompanyCtrl.valueChanges,
            // .pipe(startWith(false)),
            this.individualForm.valueChanges
        )
            .pipe(
                tap(([_, person]) => {
                    if (!this.contact) {
                        let name = '';
                        if (this.isCompanyCtrl.value) {
                            name = person.first_name;
                        } else {
                            name = [person.first_name, person.last_name]
                                .filter((v) => !!v)
                                .map(trim)
                                .join(' ');
                        }
                        this.contactForm.controls.display_name.patchValue(name, {
                            emitEvent: false,
                            emitModelToViewChange: true
                        });
                    }
                    if (this.isCompanyCtrl.value) {
                        this.contactForm.controls.company_name.patchValue(person.first_name, {
                            emitEvent: false,
                            emitModelToViewChange: true
                        });
                    }
                }),
                takeUntil(this.stopListenDisployName),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    }

    comparePeriod: (o1: UntypedFormControl, o2: UntypedFormControl) => boolean = isEqual;

    private FocusOnCompany() {
        setTimeout(() => {
            this.changeDetector.detectChanges();
        }, 0);
    }

    private focusOnIndividual() {
        setTimeout(() => {
            this.changeDetector.detectChanges();
        }, 0);
    }

    productMappingManage() {
        const contact = this.contact;
        if (contact) {
            const dialogRef = this.dialog.open(ContactProductsMappingDialogComponent, {
                minWidth: 320,
                data: {
                    contactId: contact.contact_id
                }
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((contactForSave) => {
                    if (contactForSave) {
                        this.cs
                            .updateContactById(contactForSave.contact_id, contactForSave)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((result) => {
                                this.initEditContact();
                            });
                    }
                });
        }
    }

    getAndSubscribeContactTypes() {
        // get contactTypes from sessionService variable
        this.contactTypes = this.sessionService.contactTypes;

        // subscribe to contactTypes changes
        this.currentProfileSource.contactTypes.pipe(takeUntil(this.unsubscribe)).subscribe((newList) => {
            this.contactTypes = newList;
        });

        // if no contactTypes - get them from backend and save in currentProfileSource
        if (this.sessionService.contactTypes.length === 0) {
            this.contactTypeService.getContactTypeListAsPromise().then((list) => {
                this.currentProfileSource.contactTypes.next(list);
            });
        }
    }

    getAndSubscribeContactClasses() {
        // get contactClasses from sessionService variable
        this.contactClasses = sortBy(this.sessionService.contactClasses, ['title']);

        // subscribe to contactClasses changes
        this.currentProfileSource.contactClasses.pipe(takeUntil(this.unsubscribe)).subscribe((newList) => {
            this.contactClasses = sortBy(newList, ['title']);
        });

        // if no contactClasses - get them from backend and save in currentProfileSource
        if (this.sessionService.contactClasses.length === 0) {
            this.contactClassService.getContactClassListAsPromise().then((list) => {
                this.currentProfileSource.contactClasses.next(list);
            });
        }
    }

    getContactAndInit(contact_id: number) {
        this.contactsService
            .getContactById(contact_id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((contact) => {
                if (contact) {
                    this.contact = contact;
                    this.initPage();
                } else {
                    this.ntfs.error('The contact not found.');
                    this.router.navigate(['/contacts']);
                    return;
                }
            });
    }

    ngOnInit() {
        if (this.contact_id) {
            this.getContactAndInit(this.contact_id);
        } else {
            this.initPage();
        }
    }

    initPage() {
        this.getAndSubscribeContactTypes();
        this.getAndSubscribeContactClasses();
        this.calculationSettingsService.getCalculationSettings(true);
        this.newCalculationSettingsService.getAllMetrics(true);

        this.currentProfileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe((profile) => {
            this.currentProfile = profile;
            if (this.contact) {
                if (
                    this.currentProfile.company &&
                    this.currentProfile.company.company_group &&
                    this.currentProfile.company.company_group.contact_fk_id &&
                    this.currentProfile.company.company_group.contact_fk_id === this.contact.contact_id
                ) {
                    this.isDefaultCompanyContact = true;
                }
                if (this.contact.related__company_group_fk_id && this.contact.company_name) {
                    this.isDivisionContact = true;
                    this.divisionEmail =
                        this.contact.related_group && this.contact.related_group.email
                            ? this.contact.related_group.email.toString()
                            : '';
                }
                this.directoryEmail = this.contact.related_profile ? this.contact.related_profile.email : '';
            }
        });

        this.availableProfilesSource.availableProfiles
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((profilesList: Profile[]) => {
                this.profiles = profilesList
                    .filter((p) => !!p.company && p.type !== 'global')
                    .filter(
                        (p) =>
                            this.currentProfile &&
                            this.currentProfile.company &&
                            p.company &&
                            this.currentProfile.company.id !== p.company.id
                    );
            });

        this.store.pipe(select(selectWildcards), takeUntil(this.unsubscribe)).subscribe((wildcards) => {
            this.wildcards = Array.from(wildcards);
            this.availableWildcards = Array.from(wildcards);
            this.loadContactWildcardInstances();
        });

        this.mentorContacts.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((next) => {
            const chipNode = next.shift();
            if (!this.contactForm) {
                return;
            }

            if (chipNode) {
                this.contactForm.patchValue({
                    mentor: new ContactPartLink().setContactId(chipNode.target_id).setLinkTitle(chipNode.label)
                });
                this.contactForm.controls.mentor.markAsDirty();
                return;
            }

            this.contactForm.patchValue({
                mentor: null
            });
            this.contactForm.controls.mentor.markAsDirty();
        });

        this.mentorTeamLeaderContacts.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((next) => {
            const chipNode = next.shift();
            if (!this.contactForm) {
                return;
            }

            if (chipNode) {
                this.contactForm.patchValue({
                    mentor_team_leader: new ContactPartLink()
                        .setContactId(chipNode.target_id)
                        .setLinkTitle(chipNode.label)
                });
                this.contactForm.controls.mentor_team_leader.markAsDirty();
                return;
            }

            this.contactForm.patchValue({
                mentor_team_leader: null
            });
            this.contactForm.controls.mentor_team_leader.markAsDirty();
        });

        this.trxCoordinatorContacts.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((next) => {
            const chipNode = next.shift();
            if (!this.contactForm) {
                return;
            }

            if (chipNode) {
                this.contactForm.patchValue({
                    trx_coordinator: new ContactPartLink().setContactId(chipNode.target_id).setLinkTitle(chipNode.label)
                });
                this.contactForm.controls.trx_coordinator.markAsDirty();
                return;
            }

            this.contactForm.patchValue({
                trx_coordinator: null
            });
            this.contactForm.controls.trx_coordinator.markAsDirty();
        });

        if (this.contact && this.contact.mentor && this.contact.mentor.contact_id) {
            this.mentorContacts.push(
                new GenericFormGroup(
                    new ChipNode()
                        .setTargetId(this.contact.mentor.contact_id)
                        .setLabel(this.contact.mentor.link_title!)
                        .setType(ChipNode.type_SET.contact)
                )
            );
        }

        if (this.contact && this.contact.mentor_team_leader && this.contact.mentor_team_leader.contact_id) {
            this.mentorTeamLeaderContacts.push(
                new GenericFormGroup(
                    new ChipNode()
                        .setTargetId(this.contact.mentor_team_leader.contact_id)
                        .setLabel(this.contact.mentor_team_leader.link_title!)
                        .setType(ChipNode.type_SET.contact)
                )
            );
        }

        if (this.contact && this.contact.trx_coordinator && this.contact.trx_coordinator.contact_id) {
            this.trxCoordinatorContacts.push(
                new GenericFormGroup(
                    new ChipNode()
                        .setTargetId(this.contact.trx_coordinator.contact_id)
                        .setLabel(this.contact.trx_coordinator.link_title!)
                        .setType(ChipNode.type_SET.contact)
                )
            );
        }

        this.initForm();
        this.initIndividualForm();

        this.locationPickerCtrl.valueChanges
            .pipe(
                filter((l) => l),
                map(extractLocationComponents),
                map((location) => this.addLocation(location)),
                tap(() => this.locationPickerCtrl.reset(null)),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.isCompanyCtrl.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isCompany) => (isCompany ? this.FocusOnCompany() : this.focusOnIndividual()));

        this.permissionsSource.permissions.pipe(takeUntil(this.unsubscribe)).subscribe((permissions) => {
            // @ts-ignore
            this.profilePermissions = permissions;
            this.initProfileForm();
        });

        this.initDisplayNameWatchers();

        if (!this.contact) {
            this.isCompanyCtrl.patchValue(false);
            this.changeDetector.detectChanges();
            return;
        }

        this.initEditContact();

        this.companyGatewayService
            .getMyAuthGateways(Number(this.contact.contact_id))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((gateways) => {
                this.myGateways = gateways;
            });
        this.companyGatewayService
            .getContactAuthGateways(Number(this.contact.contact_id))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((gateways) => {
                this.contactGateways = gateways;
            });

        this.isContactAvailableToReceiveByPaymentMode(Number(this.contact.contact_id));
    }

    isContactAvailableToReceiveByPaymentMode(contact_id: number) {
        if (
            this.sessionService.profile?.company?.company_settings &&
            this.sessionService.profile?.company?.company_settings.is_zipi_pay_enabled
        ) {
            this.isCompanyZipiFinancialActivated = true;
        }
    }

    finishSetup(method: IPaymentMethod, gateway: IPaymentGateway) {
        const dialogRef = this.dialog.open(ZipiFinConnectToLedgerAccountDialogComponent, {
            data: {
                method: method,
                gateway: gateway
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                const settings = {
                    related_ledger_account_id: null,
                    needDefaultLedger: false
                };

                if (result === false || result === undefined) {
                    return;
                } else if (result === null) {
                    settings.needDefaultLedger = true;
                } else {
                    settings.needDefaultLedger = false;
                    settings.related_ledger_account_id = result;
                }
                this.companyPaymentMethodsService
                    .updateLedgerAccountForPaymentMethod(Number(method.payment_method_id), settings)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((response) => {
                        this.ngOnInit();
                    });
            });
    }

    loadContactWildcardInstances() {
        this.wildcardInstanceArrayCtrl = [];
        this.wildcardInstanceArrayCtrlInitial = [];
        if (typeof this.contact !== 'undefined' && typeof this.contact.contact_id !== 'undefined') {
            this.wildcardsService
                .getWildcardInstances(Number(this.contact.contact_id))
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((res) => {
                    if (res.result) {
                        this.wildcardInstances = res.result;
                        this.wildcardInstances.forEach((wildcardInstance) => {
                            const wildcardInstanceWildcard = wildcardInstance.wildcard;
                            if (this.contact && wildcardInstance && wildcardInstanceWildcard) {
                                const wildcardInstanceFG = this.fb.group(
                                    {
                                        wildcard_instance_id: wildcardInstance.wildcard_instance_id,
                                        item_title: wildcardInstanceWildcard.title,
                                        wildcard_type_sk: wildcardInstanceWildcard.wildcard_type_sk,
                                        wildcard_fk_id: wildcardInstanceWildcard.wildcard_id,
                                        contact_fk_id: this.contact.contact_id
                                    },
                                    {updateOn: 'change'}
                                );

                                switch (wildcardInstanceWildcard.wildcard_type_sk) {
                                    case Wildcard.type_SET.numeric:
                                        wildcardInstanceFG.addControl(
                                            'value',
                                            new UntypedFormControl(wildcardInstance.value, Validators.required)
                                        );
                                        break;
                                    case Wildcard.type_SET.contact:
                                        this.contactsService
                                            .getContactById(Number(wildcardInstance.value))
                                            .pipe(takeUntil(this.unsubscribe))
                                            .subscribe((relatedContact) => {
                                                if (relatedContact) {
                                                    wildcardInstanceFG.addControl(
                                                        'value',
                                                        new GenericFormArray(
                                                            [
                                                                new GenericFormGroup(
                                                                    new ChipNode()
                                                                        .setTargetId(Number(relatedContact.contact_id))
                                                                        .setLabel(relatedContact.display_name!)
                                                                        .setType(ChipNode.type_SET.contact)
                                                                )
                                                            ],
                                                            Validators.required
                                                        )
                                                    );
                                                } else {
                                                    wildcardInstanceFG.addControl(
                                                        'value',
                                                        new GenericFormArray([], Validators.required)
                                                    );
                                                }
                                            });
                                        break;
                                    case Wildcard.type_SET.text:
                                        wildcardInstanceFG.addControl(
                                            'value',
                                            new UntypedFormControl(wildcardInstance.value, Validators.required)
                                        );
                                        break;
                                }

                                this.availableWildcards.splice(
                                    this.availableWildcards.findIndex(
                                        (wildcard) => wildcard.wildcard_id === wildcardInstanceWildcard.wildcard_id
                                    ),
                                    1
                                );

                                this.wildcardInstanceArrayCtrl.push(wildcardInstanceFG);
                                if (this.wildcardInstanceArrayCtrlInitial) {
                                    this.wildcardInstanceArrayCtrlInitial.push(wildcardInstanceFG);
                                }
                            }
                        });
                    }
                });
        }
    }

    deleteInstance(instanceFGIndex: number) {
        const instanceFG = this.wildcardInstanceArrayCtrl[instanceFGIndex];
        if (instanceFG) {
            const instanceId = instanceFG.controls.wildcard_instance_id.value;
            if (instanceId === null) {
                const wildcardToAdd = this.wildcards.find((wildcard) => {
                    return wildcard.wildcard_id === instanceFG.controls.wildcard_fk_id.value;
                });
                if (wildcardToAdd) {
                    this.availableWildcards.push(wildcardToAdd);
                    this.wildcardInstanceArrayCtrl.splice(instanceFGIndex, 1);
                }
            } else {
                const confirmDialogRef = this.dialog.open(ConfirmComponent, {
                    minWidth: 320,
                    minHeight: 160,
                    data: {
                        title: `Confirm deleting compensations attribute`,
                        message: `Please, confirm deleting`
                    }
                });
                confirmDialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((confirmed: boolean) => {
                        if (!confirmed) {
                            return;
                        }
                        const instanceToRemove = this.wildcardInstances.find((wildcardInstance) => {
                            return wildcardInstance.wildcard_instance_id === instanceId;
                        });
                        if (typeof instanceToRemove !== 'undefined' && instanceToRemove.wildcard) {
                            this.wildcardInstancesToDelete.push(instanceToRemove);
                            this.availableWildcards.push(instanceToRemove.wildcard);
                            this.wildcardInstanceArrayCtrl.splice(instanceFGIndex, 1);
                        }
                    });
            }
        }
    }

    openContactTypeCreationDialog() {
        const dialogRef = this.dialog.open(ContactTypeCreationDialogComponent, {
            width: '600px',
            data: {
                contactTypes: this.contactTypes,
                profiles: this.profiles
            }
        });
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result: {contactType: ContactType; action: string} | null) => {
                if (!result) {
                    return;
                }
            });
    }

    openContactClassCreationDialog() {
        const dialogRef = this.dialog.open(ContactClassCreationDialogComponent, {
            width: '600px',
            data: {
                contactClasses: this.contactClasses,
                profiles: this.profiles
            }
        });
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result: {contactType: ContactType; action: string} | null) => {
                if (!result) {
                    return;
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.stopListenDisployName.next();
        this.stopListenDisployName.complete();
        this.unsubscribeSave.next();
        this.unsubscribeSave.complete();
    }
}
