import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {Observable} from 'rxjs';
import {IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';

@Injectable({
    providedIn: 'root'
})
export class PaymentMethodsService {
    url = '/finance/payment-methods';

    constructor(public requester: ServiceRequester) {}

    createZipiFinancialPaymentMethod(
        gatewayId: number,
        settings: {
            public_token: any;
            account_id: string;
            pending_auth_status: 'pending_manual_verification' | 'manually_verified' | 'need_relogin' | '';
            title: string;
            related_ledger_account_id: number | null;
            is_default_receiving: boolean | null;
            needDefaultLedger: boolean | null;
            account_type: 'cloud_bank' | 'bank' | 'card';
        }
    ): Observable<IPaymentMethod> {
        return this.requester.makeMsCall$(`${this.url}/${gatewayId}/zipi-fin`, 'POST', 'shipp', settings);
    }

    verifyZipiFinancialPaymentMethod(
        methodId: number,
        data: {amount1: number; amount2: number}
    ): Observable<{success: boolean; errorMsg: string}> {
        return this.requester.makeMsCall$(`${this.url}/${methodId}/zipi-fin/verify`, 'PUT', 'shipp', data);
    }

    createDwollPaymentMethodForReceiveOnlyCustomer(
        contactId: number,
        data: {
            customer: {[key: string]: any};
            funding_source: {[key: string]: any};
        }
    ): Observable<IPaymentMethod> {
        return this.requester.makeMsCall$(`${this.url}/${contactId}/receive-only`, 'POST', 'shipp', data);
    }

    createPublicZipiFinancialPaymentMethodForContact(gatewayId: number, data: any): Observable<IPaymentMethod> {
        return this.requester.makeMsCall$(
            `/public/zipi-financial/${gatewayId}/zipi-fin/for-contact`,
            'POST',
            'shipp',
            data
        );
    }

    createZipiFinancialPaymentMethodForContact(gatewayId: number, data: any): Observable<IPaymentMethod> {
        return this.requester.makeMsCall$(`${this.url}/${gatewayId}/zipi-fin/for-contact`, 'POST', 'shipp', data);
    }

    addCardForContact(data: {[key: string]: any}, contactId: number): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/contact/${contactId}`, 'POST', 'shipp', data, {}, true);
    }

    addCardForCompany(data: {[key: string]: any}, contactId: number): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/company/${contactId}`, 'POST', 'shipp', data, {}, true);
    }

    getPublicKey(companyGatewayId: number): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/public-key/${companyGatewayId}`, 'GET', 'shipp');
    }

    addCard(data: {[key: string]: any}): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}`, 'POST', 'shipp', data);
    }

    getInvoiceMethods(methodsIds: Array<number | null>): Observable<IPaymentMethod[]> {
        return this.requester.makeMsCall$(this.url + `/methods`, 'POST', 'shipp', methodsIds);
    }

    getAvailablePaymentMethodsForBill(billId: number): Observable<IPaymentMethod[]> {
        return this.requester.makeMsCall$(this.url + `/${billId}/bill-methods`, 'GET', 'shipp');
    }

    getCompanyGateways(): Observable<IPaymentGateway[]> {
        return this.requester.makeMsCall$(`${this.url}/gateways`, 'GET', 'shipp');
    }

    getAllGatewaysForCompanyAndInvoiceContact(): Observable<IPaymentGateway[]> {
        return this.requester.makeMsCall$(`${this.url}/gateways/invoice`, 'GET', 'shipp');
    }

    changeMode(methodId: number): Observable<boolean> {
        return this.requester.makeMsCall$(`${this.url}/${methodId}/mode`, 'PUT', 'shipp');
    }

    disconnectMethod(methodId: number): Observable<boolean> {
        return this.requester.makeMsCall$(`${this.url}/${methodId}/disconnect`, 'PUT', 'shipp');
    }

    deleteMethod(methodId: number): Observable<boolean> {
        return this.requester.makeMsCall$(`${this.url}/${methodId}`, 'DELETE', 'shipp');
    }

    removeExternalAccount(gatewayId: number, bankAccountExternalId: string): Observable<boolean> {
        return this.requester.makeMsCall$(
            `${this.url}/${gatewayId}/${bankAccountExternalId}/external`,
            'DELETE',
            'shipp'
        );
    }

    // changeDefaultStatus(cardId): Observable<boolean> {
    //     return this.requester.makeMsCall$(
    //         `${this.url}/${cardId}`,
    //         'PUT',
    //         'shipp',
    //     );
    // }

    getAllCompanyPaymentMethodsFromGateways(companyGateways: IPaymentGateway[]) {
        const paymentMethods: IPaymentMethod[] = [];
        companyGateways = companyGateways.filter((gateway) => {
            return [
                'authorize_net_merchant',
                'dwolla_business',
                'dwolla_trust',
                'system',
                'zipi_financial_business',
                'zipi_financial_trust'
            ].includes(gateway.type);
        });
        if (companyGateways && companyGateways.length) {
            companyGateways.forEach((gateway) => {
                if (gateway.payment_methods) {
                    gateway.payment_methods.forEach((payment_method) => {
                        if (payment_method.is_available_for_payment) {
                            paymentMethods.push(payment_method);
                        }
                    });
                }
            });
        }
        return paymentMethods;
    }
}
