import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {firstValueFrom, Subject} from 'rxjs';
import {IInvoice} from '@cyberco-nodejs/zipi-typings';
import {filter, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from 'app/services/session.service';
import {ProfilesService} from 'app/services/profiles.service';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {BILL_INVOICE_STATUS_COLOR, PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {MatOptionSelectionChange} from '@angular/material/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NotificationsService} from 'angular2-notifications';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {MatDialog} from '@angular/material/dialog';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {IScrollData} from 'app/models/scroll-data';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {GenericFormArray} from '../../../../../entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {addWarning} from '@angular-devkit/build-angular/src/utils/webpack-diagnostics';

@Component({
    selector: 'app-invoices',
    templateUrl: 'invoices.component.html',
    styleUrls: ['invoices.component.scss', '../../../../../../assets/infinite-scroll-table.scss']
})
export class InvoicesComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;

    selection: SelectionModel<number> = new SelectionModel<number>(true, []);

    currentCompanyId: number | null = null;
    contactIds: Array<number> = [];

    statusTypes = [
        'all',
        'open',
        'sent',
        'overdue',
        'partial_payment',
        'paid',
        'write_off',
        'void',
        'rejected',
        'draft'
    ];
    paymentTypes = ['all', 'unpaid', 'processing', 'pending', 'partially_paid', 'paid', 'need_confirm'];
    statusFilter: UntypedFormControl = new UntypedFormControl([]);

    statusColor = BILL_INVOICE_STATUS_COLOR;

    dataSource: MatTableDataSource<IInvoice>;
    displayedColumns = [
        'checkbox',
        'invoice_date',
        'invoice_number',
        'customer', // money_sender__contact_fk_id',
        'connected_bill_number',
        'summary_status',
        'due_date',
        'status_of_payment',
        'total_amount',
        'balance',
        'action'
    ];

    scrollData: IScrollData = {
        offset: 0,
        limit: 50,
        sort_column: 'invoice_number',
        sort_direction: 'desc',
        summary_status_filter: null,
        payment_status_filter: null,
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;
    isLoading: boolean = false;
    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    range: UntypedFormGroup = this.fb.group({
        start_date: [null, []],
        end_date: [null, []]
    });

    isSelectedForBulk: boolean;

    constructor(
        private sessionService: SessionService,
        private profileService: ProfilesService,
        public router: Router,
        public currentProfileSource: CurrentProfileSource,
        private invoicesService: InvoicesService,
        private ntfs: NotificationsService,
        public dialog: MatDialog,
        public fb: UntypedFormBuilder,
        protected route: ActivatedRoute
    ) {
        this.isSelectedForBulk = false;
        this.dataSource = new MatTableDataSource<IInvoice>([]);
    }

    async ngOnInit() {
        const routeQueryParams = await firstValueFrom(this.route.queryParams);
        if (routeQueryParams) {
            if (routeQueryParams.hasOwnProperty('contact_id')) {
                this.contactIds = [Number(routeQueryParams.contact_id)];
                this.scrollData.money_sender_id = routeQueryParams.contact_id;
            }
            if (routeQueryParams.hasOwnProperty('status')) {
                if (routeQueryParams.status === 'overdue') {
                    this.statusFilter.setValue([`invstat_${routeQueryParams.status}`]);
                }
                if (routeQueryParams.status === 'current') {
                    this.statusFilter.setValue(['invstat_sent', 'invstat_open', 'invstat_partial_payment']);
                }
            }
        }

        if (this.sessionService.profile) {
            if (this.sessionService.profile.company) {
                this.currentCompanyId = Number(this.sessionService.profile.company.id);
            }
            if (this.sessionService.profile.settings) {
                this.scrollData.sort_direction = this.sessionService.profile.settings.sort_invoices_direction;
                this.scrollData.sort_column = this.sessionService.profile.settings.sort_invoices_column;
            }
        }

        this.moneySenderCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            this.scrollData.money_sender_id = values && values.length ? values[0].target_id : '';
            this.resetData();
        });

        this.range.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values) => {
            this.scrollData.start_date = values.start_date || '';
            this.scrollData.end_date = values.end_date || '';
            this.resetData();
        });

        this.nextBatch();

        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.nextBatch();
            });
        }
    }

    getInvoiceStatuses() {
        return this.statusTypes.map((s) => `invstat_${s}`);
    }

    getPaymentStatuses() {
        return this.paymentTypes.map((s) => `paystat_${s}`);
    }

    getInvoiceStatusValue(value: string) {
        return value.replace('invstat_', '');
    }

    getPaymentStatusValue(value: string) {
        return value.replace('paystat_', '');
    }

    changeStatusFilter(event: MatOptionSelectionChange) {
        if (event.isUserInput) {
            const {value, selected} = event.source;
            const statusFilter = this.statusFilter.value;

            const invoicePrefix = 'invstat_';
            const paymentPrefix = 'paystat_';

            let iStatuses = statusFilter.filter((s: string) => s.indexOf('invstat_') !== -1);
            let pStatuses = statusFilter.filter((s: string) => s.indexOf('paystat_') !== -1);

            if (value.indexOf(invoicePrefix) !== -1) {
                if (value === 'invstat_all') {
                    iStatuses = selected ? this.getInvoiceStatuses() : [];
                } else if (value !== 'invstat_all') {
                    if (iStatuses.includes('invstat_all') && !selected) {
                        iStatuses = iStatuses.filter((f: string) => f !== 'invstat_all' && f !== value);
                    } else {
                        iStatuses = this.getInvoiceStatuses().filter(
                            (f) => (f !== value && iStatuses.includes(f)) || (f === value && selected)
                        );
                    }
                }
            }
            if (value.indexOf(paymentPrefix) !== -1) {
                if (value === 'paystat_all') {
                    pStatuses = selected ? this.getPaymentStatuses() : [];
                } else if (value !== 'paystat_all') {
                    if (pStatuses.includes('paystat_all') && !selected) {
                        pStatuses = pStatuses.filter((f: string) => f !== 'paystat_all' && f !== value);
                    } else {
                        pStatuses = this.getPaymentStatuses().filter(
                            (f) => (f !== value && pStatuses.includes(f)) || (f === value && selected)
                        );
                    }
                }
            }

            this.statusFilter.setValue(iStatuses.concat(pStatuses));
            this.resetData();
        }
    }

    nextBatch() {
        // cancel previous batch requests
        this.unsubscribeBatch.next();
        this.isLoading = true;

        const invoiceStatusFilter: Array<string> = [];
        const paymentStatusFilter: Array<string> = [];
        this.statusFilter.value.forEach((status: string) => {
            if (status.indexOf('invstat_') !== -1) {
                invoiceStatusFilter.push(status.replace('invstat_', ''));
            } else if (status.indexOf('paystat_') !== -1) {
                paymentStatusFilter.push(status.replace('paystat_', ''));
            }
        });

        if (invoiceStatusFilter && !invoiceStatusFilter.includes('all')) {
            this.scrollData.summary_status_filter = invoiceStatusFilter;
        } else {
            this.scrollData.summary_status_filter = '';
        }

        if (paymentStatusFilter && !paymentStatusFilter.includes('all')) {
            this.scrollData.payment_status_filter = paymentStatusFilter;
        } else {
            this.scrollData.payment_status_filter = '';
        }

        this.invoicesService
            .getPageInvoices(this.scrollData)
            .pipe(takeUntil(this.unsubscribeBatch))
            .subscribe((data) => {
                this.dataSource.data = data.result;
                this.scrollData.total = data._meta.total;
                this.isLoading = false;
            });
    }

    editInvoice(invoice: IInvoice) {
        this.router.navigate([`/sales/invoices/edit/${invoice.invoice_id}`]);
    }

    disconnectInvoice(invoice: IInvoice) {
        if (invoice && invoice.invoice_id) {
            this.invoicesService
                .disconnectInvoice(invoice.invoice_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (result) {
                        this.ntfs.success('Invoice successfully disconnected');
                    }
                    this.resetData();
                });
        }
    }

    resetData() {
        this.paginator!.pageIndex = 0;
        this.scrollData.offset = 0;
        this.scrollData.total = 0;

        this.nextBatch();
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        if (this.sessionService.profile && this.sessionService.profile.settings) {
            this.sessionService.profile.settings.sort_invoices_direction = this.scrollData.sort_direction;
            this.sessionService.profile.settings.sort_invoices_column = this.scrollData.sort_column;
            const id = Number(this.sessionService.profile.id);
            const settings = this.sessionService.profile.settings;
            this.profileService
                .updateProfileSettings(id, settings)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response) {
                        this.currentProfileSource.triggers.changeRequesterProfile.next(response.result);
                    }
                });

            this.resetData();
        }
    }

    setRange(range: {dateFrom: string; dateTo: string}) {
        this.range.controls.start_date.patchValue(range.dateFrom);
        this.range.controls.end_date.patchValue(range.dateTo);
    }

    toggleBulkCheckbox($event: any, id: number) {
        if ($event) {
            this.selection.toggle(id as never); // dirty hack
        }
        this.isSelectedForBulk = this.selection.selected.length !== 0;
    }

    bulkDelete() {
        const invoiceIdsForBulk = this.selection.selected;
        if (invoiceIdsForBulk.length > 0) {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                minWidth: 320,
                data: {
                    title: 'Deleting Invoices',
                    message: `${invoiceIdsForBulk.length} Invoices will be deleted.`,
                    messages: [
                        `If there is payment in the Invoice or related journal has matched or reconciled transaction, it will not be deleted.`,
                        `You must first remove payments from the Invoice, unmatch and unreconcile related transaction.`
                    ]
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((ok) => {
                    if (ok) {
                        this.invoicesService
                            .bulkDeleteInvoices(invoiceIdsForBulk)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((res) => {
                                this.selection.clear();
                                this.isSelectedForBulk = false;
                                this.resetData();

                                if (res.deleted_invoices && !res.not_deleted_invoices) {
                                    this.ntfs.success(
                                        `Invoices:<br>${res.deleted_invoices > 1 ? 'Invoices have' : 'Invoice has'} been successfully deleted`
                                    );
                                } else if (res.deleted_invoices && res.not_deleted_invoices) {
                                    this.ntfs.warn(
                                        'Invoices:<br>1 or more of your invoices could not be deleted. They are referenced by one or more of the following: a deal, a payment, a transaction'
                                    );
                                } else if (!res.deleted_invoices && res.not_deleted_invoices) {
                                    this.ntfs.error(
                                        `Invoices:<br>${res.not_deleted_invoices > 1 ? 'These invoices' : 'This invoice'} cannot be deleted. ${res.not_deleted_invoices > 1 ? 'They are' : 'It is'} referenced by one or more of the following: a deal, a payment, a transaction`
                                    );
                                }
                            });
                    }
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
    }
}
