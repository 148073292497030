import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {Profile} from '../models/profile';
import {CurrentProfileSource} from '../services/sources/current-profile.source';
import {AvailableProfilesSource} from '../services/sources/available-profiles.source';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SKYSLOPE_BOOKS_LARGE_LOGO} from '../local-typings';

@Component({
    selector: 'app-access-denied',
    styles: [
        `
            .subscription-inactive-card {
                margin-top: 20vh;
                width: 50vw;
                margin-left: 25vw;
                display: flex;
                flex-direction: row;
            }
            .error-content {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            .subscription-inactive-card__logo {
                width: 160px;
                margin: 20px 10px;
            }
        `
    ],
    template: `
        <mat-card class="subscription-inactive-card">
            <mat-card-content class="error-content">
                <!--<mat-card-title>{{currentProfile.company.title}}</mat-card-title>-->
                <img [src]="skyslopeLogo" alt="Books" class="subscription-inactive-card__logo" />
                <div>
                    You don't have access to this functionality.<br />
                    For further information, please contact your company administrator ({{
                        currentProfile?.company?.title
                    }}) or choose another company below. <br /><br />
                </div>
                <button mat-button *ngFor="let profile of availableProfiles" (click)="setCurrentProfile(profile)">
                    {{ profile?.company?.title }}
                </button>
                <mat-card-actions align="end">
                    <!--<button [disabled]="true" mat-button class="mat-primary">Request access (coming soon)</button>-->
                    <button mat-button (click)="doLogout()">Logout</button>
                </mat-card-actions>
            </mat-card-content>
        </mat-card>
    `
})
export class AccessDeniedComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    public availableProfiles: Profile[] = [];
    public currentProfile: Profile | null = null;
    skyslopeLogo = SKYSLOPE_BOOKS_LARGE_LOGO;

    constructor(
        public authService: AuthService,
        protected router: Router,
        protected availableProfilesSource: AvailableProfilesSource,
        protected currentProfileSource: CurrentProfileSource
    ) {
        this.currentProfileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe((currProfile) => {
            this.currentProfile = currProfile;
        });
    }

    async ngOnInit() {
        this.availableProfilesSource.availableProfiles.pipe(takeUntil(this.unsubscribe)).subscribe((profilesList) => {
            this.availableProfiles = profilesList.filter((p) => p.type !== 'global');
        });
    }

    async setCurrentProfile(profile: Profile) {
        await this.authService.loadProfile(profile.id as number);
        await this.router.navigate(['/default-page']);
    }

    doLogout() {
        this.authService.logout().then(() => {
            localStorage.removeItem('zipi_last_profile_id');
            if (environment.env === 'production') {
                window.location.replace(environment.marketingPageLogin);
            } else {
                window.location.replace(`${window.location.origin}${environment.unauthorizedUserRedirectPath}`);
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
