<!------- List of bills ------->
<div class="table-background" style="height: calc(100vh - 192px)">
    <table mat-table [dataSource]="dataSource" [ngClass]="{'d-none': dataSource.data.length === 0}" class="w-100">
        <ng-container matColumnDef="invoice_date">
            <th mat-header-cell *matHeaderCellDef class="w-25">Bill Date</th>
            <td mat-cell *matCellDef="let element" class="w-25">
                {{ element.invoice_date | dateFromNumber | date: 'mediumDate' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="bill_number">
            <th mat-header-cell *matHeaderCellDef class="w-25">Bill#</th>
            <td mat-cell *matCellDef="let element" class="w-25 px-1">
                {{ element.bill_number || 'N/A' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="total_amount">
            <th mat-header-cell *matHeaderCellDef class="w-25 pr-5 text-right">Total Amount</th>
            <td mat-cell *matCellDef="let element" class="w-25 pr-5 text-right">
                {{ element.total_amount | currency }}
            </td>
        </ng-container>

        <ng-container matColumnDef="summary_status">
            <th mat-header-cell *matHeaderCellDef class="w-25">Status</th>
            <td mat-cell *matCellDef="let element" class="w-25">
                {{ element.summary_status | fromSnakeCase }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [routerLink]="['../purchases/bills', row.bill_id]"
            class="zp-table-row"
        ></tr>
    </table>

    <!------- Paginator ------->
    <div class="custom-paginator-background" style="width: 638px"></div>
    <mat-paginator
        class="custom-paginator"
        [length]="scrollData.total"
        [pageSize]="scrollData.limit"
        [pageSizeOptions]="pageSizeOptions"
    >
    </mat-paginator>
    <!------- No values ------->
    <div
        class="text-muted m-3 text-center no-items"
        *ngIf="dataSource.data && dataSource.data.length === 0 && isLoaded"
    >
        No bills yet.
    </div>

    <div class="load-container" id="load-container" *ngIf="!isLoaded">
        <div class="loader">Loading...</div>
    </div>
</div>
