import {Component, OnInit, OnDestroy, Output, EventEmitter, Input, ViewChild} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {NotificationsService} from 'angular2-notifications';
import {IFinanceState} from '../../../store/finance.reducer';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {combineLatest, Subject} from 'rxjs';
import {map, filter, takeUntil, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {selectProducts} from 'app/store/root.selectors';
import {IProduct} from '@cyberco-nodejs/zipi-typings';
import {
    CreateProduct,
    UpdateProduct,
    DeleteProduct,
    FetchProducts,
    UpdateProductSuccess
} from '../../../store/finance.actions';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {ProductsService} from '../../../../../services/api/finance/products.service';
import {LedgerAccountSelectorComponent} from '../../../../shared/components/ledger-account-selector/ledger-account-selector.component';
import {FormGroupWithFormControls} from '../../../../../typings/common';
import {currencyMaskitoOptions, unmaskCurrencyControlValue} from 'app/utilities/maskito';

@Component({
    selector: 'app-edit-product',
    templateUrl: 'edit-product.component.html',
    styleUrls: ['edit-product.component.css']
})
export class EditProductComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('ledgerAccountPicker', {static: true}) public ledgerAccountPicker:
        | LedgerAccountSelectorComponent
        | undefined;
    @Input() dialogMode = false;
    @Output() create = new EventEmitter<IProduct>();
    @Output() cancel = new EventEmitter<void>();
    product: IProduct | undefined;

    currencyMaskitoMask = currencyMaskitoOptions;

    formGroup: FormGroupWithFormControls = this.fb.group({
        name: ['', Validators.required],
        price: [null, [Validators.required]],
        description: [''],
        ledger_account_fk_id: [null, Validators.required]
    }) as FormGroupWithFormControls;

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store<IFinanceState>,
        private route: ActivatedRoute,
        protected router: Router,
        public dialog: MatDialog,
        private ntfs: NotificationsService,
        private productsSrv: ProductsService
    ) {}

    initEdit() {
        combineLatest(
            this.route.paramMap.pipe(
                map((pm) => {
                    const stringId: string | null = pm.get('id');
                    return Number(stringId);
                }),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            ),
            this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe))
        )
            .pipe(
                map(([id, products]) => {
                    return products.find((product) => product.id === id);
                }),
                filter((ri) => !!ri),
                tap((product) => {
                    this.product = product;
                    if (product) {
                        this.formGroup.patchValue(product);
                        this.formGroup.controls.price.setValue(product.price + '');
                        if (product.system_key) {
                            this.formGroup.controls['ledger_account_fk_id'].disable();
                        }
                    }
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    }

    validaateForm() {
        if (this.formGroup.valid) {
            return true;
        }
        this.formGroup.markAllAsTouched();
        if (this.ledgerAccountPicker) {
            this.ledgerAccountPicker.validateControl();
        }

        this.ntfs.warn('Form is not valid');

        this.formGroup.updateValueAndValidity();

        return false;
    }

    createProduct() {
        if (this.validaateForm() === false) {
            return;
        }
        this.store.dispatch(new CreateProduct(this.formGroup.getRawValue()));
        this.create.emit(this.formGroup.getRawValue());

        if (this.dialogMode) {
            return;
        }
        this.router.navigate(['/company/finance/products']);
    }

    updateProduct() {
        if (this.validaateForm() === false) {
            return;
        }

        this.store.dispatch(new UpdateProduct(Object.assign({}, this.product, this.formGroup.getRawValue())));
    }

    archiveProduct() {
        if (!this.product || !this.product.product_id) {
            return;
        }

        this.productsSrv
            .archiveProduct(this.product.product_id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result && this.product) {
                    this.product.status = 'archived';
                    this.store.dispatch(new UpdateProductSuccess({ok: true, product: this.product}));
                    this.ntfs.warn(`Product archived`);
                    this.router.navigate(['/company/finance/products']);
                }
            });
    }

    unarchiveProduct() {
        if (!this.product || !this.product.product_id) {
            return;
        }
        this.productsSrv
            .unarchiveProduct(this.product.product_id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result && this.product) {
                    this.product.status = 'active';
                    this.store.dispatch(new UpdateProductSuccess({ok: true, product: this.product}));
                    this.ntfs.warn(`Product unarchived`);
                    this.router.navigate(['/company/finance/products']);
                }
            });
    }

    deleteProduct() {
        if (!this.product) {
            return;
        }
        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            data: {
                title: 'Deleting Product',
                message: `Product "${this.product.name}" will be deleted`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {
                if (this.product && this.product.product_id) {
                    this.store.dispatch(new DeleteProduct(this.product.product_id));
                }
            });
    }

    ngOnInit() {
        this.store.dispatch(new FetchProducts());

        this.formGroup.controls.price.valueChanges
            .pipe(unmaskCurrencyControlValue(this.formGroup.controls.price), takeUntil(this.unsubscribe))
            .subscribe();

        this.initEdit();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.create.complete();
        this.cancel.complete();
    }
}
