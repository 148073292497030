import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-skyslope-apps-menu',
    templateUrl: './skyslope-apps-menu.component.html',
    styleUrls: ['./skyslope-apps-menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SkyslopeAppsMenuComponent implements OnInit {
    skyslopeLink = environment.env === 'production' ? 'https://www.skyslope.com/' : 'https://integ.skyslope.com/';
    constructor() {}

    ngOnInit(): void {}
}
