<div mat-dialog-title>
    <h3>Confirm Invoice Payment</h3>
</div>

<mat-dialog-content [formGroup]="formGroup">
    <p>
        <!--You are going to create bill with payment method "{{data.payment_method}}"-->
        You are going to mark Invoice as paid manually.
    </p>

    <div class="d-flex">
        <mat-form-field class="w-50 mr-2">
            <mat-select formControlName="payment_mode">
                <ng-container *ngFor="let mode of paymentModes">
                    <mat-option [value]="mode.slug" *ngIf="mode.is_selectable">
                        <span>{{ mode.title }}</span>
                    </mat-option>
                </ng-container>
            </mat-select>
            <mat-placeholder>Payment Mode</mat-placeholder>
        </mat-form-field>

        <mat-form-field class="w-50" *ngIf="needAmount">
            <input
                matInput
                formControlName="payment_amount"
                placeholder="Amount"
                [maskito]="currencyMaskitoMask"
                (keyup)="validateAmount($event)"
            />
        </mat-form-field>
    </div>

    <div class="d-flex">
        <div class="w-50 mr-2">
            <app-date-picker [placeholder]="'Payment Date'" [dateControl]="formGroup.controls.paid_date">
            </app-date-picker>
        </div>

        <div class="w-50">
            <app-ledger-account-selector
                class="w-100"
                [ledgerAccountControl]="formGroup.controls.ledger_account_id"
                [placeholder]="'Ledger Account'"
            >
            </app-ledger-account-selector>
        </div>
    </div>

    <div
        *ngIf="
            formGroup.controls.payment_mode &&
            formGroup.controls.payment_mode.value &&
            formGroup.controls.payment_mode.value === 'check_record'
        "
    >
        <div class="w-50">
            <mat-form-field class="w-90">
                <input matInput autocomplete="off" type="search" formControlName="check_number" />
                <mat-label>Check Number</mat-label>
            </mat-form-field>
        </div>
        <div
            class="w-100"
            *ngIf="
                formGroup.controls.payment_mode &&
                formGroup.controls.payment_mode.value &&
                formGroup.controls.payment_mode.value === 'check_record'
            "
        >
            <mat-form-field class="w-100">
                <textarea matInput type="text" formControlName="memo"> </textarea>
                <mat-label>Memo</mat-label>
            </mat-form-field>
        </div>
    </div>

    <div class="py-3" [formGroup]="formGroup">
        <mat-form-field class="w-100">
            <textarea matInput type="text" formControlName="note" placeholder="Reference (optional)"> </textarea>
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="mb-2 d-flex">
    <span class="sp-spacer"></span>
    <button class="mr-1" *ngIf="!data.hideCancel" mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">
        Cancel
    </button>
    <button mat-raised-button color="primary" [disabled]="!formGroup.valid" (click)="continue()" tabindex="1">
        Continue
    </button>
</mat-dialog-actions>
