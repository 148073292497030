<div>
    <h4>Report Period</h4>
    <mat-form-field [style.width.%]="100">
        <mat-select [formControl]="selectedReportPeriodFC">
            <mat-option *ngFor="let period of timePeriods" [value]="period.value">
                {{ period.title }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div *ngIf="value?.time_period === 'date_range'">
        <div class="row m-3">
            <ng-container *ngIf="!isDisplayDueDate">
                <div class="col-xl-6">
                    <app-date-picker
                        class="spaced-at-right w-100"
                        [inputDisabled]="true"
                        [placeholder]="'Start date'"
                        [valueType]="dateValueType"
                        [startDate]="startDateFC.value"
                        [dateControl]="startDateFC"
                        [disabled]="startDateDisable"
                    ></app-date-picker>
                </div>
                <div class="col-xl-6">
                    <app-date-picker
                        class="spaced-at-right w-100"
                        [inputDisabled]="true"
                        [placeholder]="'End date'"
                        [valueType]="dateValueType"
                        [startDate]="endDateFC.value"
                        [dateControl]="endDateFC"
                        [disabled]="endDateDisable"
                    ></app-date-picker>
                </div>
            </ng-container>
            <ng-container *ngIf="isDisplayDueDate">
                <div class="col-xl-6">
                    <app-date-picker
                        class="spaced-at-right w-100"
                        [placeholder]="'Due date'"
                        [valueType]="dateValueType"
                        [dateControl]="endDateFC"
                    ></app-date-picker>
                </div>
            </ng-container>
        </div>
    </div>

    <section *ngIf="isPickingDateTypePossible">
        <h4>Run By</h4>
        <mat-form-field>
            <mat-select [formControl]="dateTypesFC">
                <mat-option *ngFor="let type of dateTypesArr" [value]="type.value">
                    {{ type.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </section>

    <section *ngIf="isCompareToPeriodPossible">
        <h4>Compare to Previous Period</h4>
        <section *ngIf="!isCompareToPeriodAllowed">
            If you would like to compare your report by different periods, please reset values for the "Grouping by"
            section.
        </section>
        <mat-form-field *ngIf="isCompareToPeriodAllowed" [style.width.%]="100">
            <mat-select multiple [formControl]="compareToPeriodsFC">
                <mat-option
                    *ngFor="let period of compareToPeriodArr"
                    [value]="period.value"
                    [disabled]="['previous_year', 'year_to_date', 'previous_year_to_date'].includes(period.value)"
                >
                    {{ period.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </section>

    <section *ngIf="compareToPeriodsFC.value?.length > 0">
        <h4>Display Options</h4>
        <section class="example-section">
            <mat-checkbox [style.margin-right.px]="10" [formControl]="showAmountChangeFC">$ Change</mat-checkbox>
            <mat-checkbox [style.margin-right.px]="10" [formControl]="showPercentChangeFC">% Change</mat-checkbox>
        </section>
    </section>
</div>
