<div *ngIf="cardForm" class="p-3">
    <div *ngIf="!authorizeMerchantGateway || !authorizeMerchantGateway.value">Authorize Gateway is not available</div>
    <mat-accordion *ngIf="showAccordion">
        <mat-expansion-panel *ngIf="authorizeMerchantGateway && authorizeMerchantGateway.value">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="logo">
                        <img
                            style="width: 80px"
                            src="https://storage.googleapis.com/zipi-static/addons/authnet/logos/authnet%20360x140.png"
                        />
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div [formGroup]="cardForm">
                <h1>Add Credit Card</h1>
                <app-notifications [notification_mode]="'popup'"></app-notifications>
                <div style="max-width: 500px; margin: auto; width: 500px" class="m-2">
                    <div class="my-3">
                        <h3>Card Details</h3>
                        <div class="w-100 px-2 d-flex">
                            <mat-form-field class="w-50 mr-2">
                                <input matInput required type="text" formControlName="firstName" />
                                <mat-placeholder>First Name</mat-placeholder>
                            </mat-form-field>

                            <mat-form-field class="w-50 mr-2">
                                <input matInput required type="text" formControlName="lastName" />
                                <mat-placeholder>Last Name</mat-placeholder>
                            </mat-form-field>
                        </div>

                        <div class="w-100 px-2 d-flex">
                            <mat-form-field class="w-50 mr-2">
                                <input matInput required type="text" formControlName="cardNumber" />
                                <mat-placeholder>Card Number</mat-placeholder>
                            </mat-form-field>

                            <mat-form-field class="w-50 mr-2">
                                <input matInput required type="text" formControlName="cardCode" />
                                <mat-placeholder>Card Code</mat-placeholder>
                            </mat-form-field>
                        </div>

                        <div class="w-100 px-2 d-flex">
                            <mat-form-field class="w-50 mr-2">
                                <input matInput required type="text" formControlName="expMonth" />
                                <mat-placeholder>Expiration Month(i.e 07)</mat-placeholder>
                            </mat-form-field>

                            <mat-form-field class="w-50 mr-2">
                                <input matInput required type="text" formControlName="expYear" />
                                <mat-placeholder>Expiration Year(i.e 23)</mat-placeholder>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="my-3">
                        <h3>Billing Address</h3>

                        <mat-form-field class="w-100 px-2">
                            <input matInput required type="text" formControlName="address" />
                            <mat-placeholder>Address</mat-placeholder>
                        </mat-form-field>

                        <mat-form-field class="w-100 px-2">
                            <input matInput required type="text" formControlName="city" />
                            <mat-placeholder>City</mat-placeholder>
                        </mat-form-field>

                        <div class="w-100 px-2 d-flex">
                            <mat-form-field class="w-50 mr-2">
                                <input matInput required type="text" formControlName="state" />
                                <mat-placeholder>State</mat-placeholder>
                            </mat-form-field>

                            <mat-form-field class="w-50 mr-2">
                                <input matInput required type="number" formControlName="zipCode" />
                                <mat-placeholder>Zip Code</mat-placeholder>
                            </mat-form-field>
                        </div>

                        <!--<mat-form-field class="w-100 px-2">-->
                        <!--<input matInput required type="text" formControlName="country">-->
                        <!--<mat-placeholder>Country</mat-placeholder>-->
                        <!--</mat-form-field>-->

                        <mat-form-field class="w-100 px-2">
                            <mat-select [placeholder]="'Country'" formControlName="country">
                                <mat-option *ngFor="let country of countryList" [value]="country.slug">
                                    {{ country.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <span>Based on Authorize.Net gateway</span>
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <button type="button" class="mr-2" mat-raised-button color="primary" (click)="saveCard()">
                    Continue
                </button>

                <button type="button" mat-raised-button (click)="close()">Cancel</button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
