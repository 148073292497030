<div mat-dialog-title>
    <h3>{{ data.title }}</h3>
</div>

<mat-dialog-content>
    <table mat-table [dataSource]="dataSource" class="w-100 my-4" matSort matSortDisableClear>
        <ng-container matColumnDef="license_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Deal Purchase#</th>
            <td mat-cell *matCellDef="let element">
                {{ element.license_id }}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th class="text-center px-3" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td class="text-center px-3" mat-cell *matCellDef="let element">
                {{ element.status }}
            </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let element">
                {{ element.type }}
            </td>
        </ng-container>

        <ng-container matColumnDef="activated">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Activated for</th>
            <td class="text-center" mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.type === 'deal' && element.activated_for_entity; else other">
                    <div
                        class="table-link"
                        [routerLink]="['/deals', element.activated_for_entity_id]"
                        (click)="dialogRef.close()"
                    >
                        {{ element.activated_for_entity }}
                    </div>
                </ng-container>
                <ng-template #other>
                    {{ element.activated_for_entity || '-' }}
                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <th mat-header-cell class="pr-3" *matHeaderCellDef mat-sort-header>Created at</th>
            <td mat-cell class="pr-3" *matCellDef="let element">
                {{ element.created_at | dateFromNumber | date: 'mediumDate' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="expire_date">
            <th mat-header-cell class="pr-3" *matHeaderCellDef mat-sort-header>Expire date</th>
            <td mat-cell class="pr-3" *matCellDef="let element">
                {{ element.expire_date | dateFromNumber | date: 'mediumDate' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="activated_at">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Activated at</th>
            <td class="text-center" mat-cell *matCellDef="let element">
                {{ element.activated_at ? (element.activated_at | dateFromNumber | date: 'mediumDate') : '-' }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="zp-table-row" *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</mat-dialog-content>

<mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="handleClick($event)">
</mat-paginator>

<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-raised-button (click)="dialogRef.close()" tabindex="-1">Cancel</button>
</mat-dialog-actions>
