export class DealSystemNote {
    system_key: string = '';
    is_dismissed: boolean = false;
    affected_entities_ids?: number[] = [];

    setSystemKey(system_key: string) {
        this.system_key = system_key;
        return this;
    }

    setIsDismissed(is_dismissed: boolean) {
        this.is_dismissed = is_dismissed;
        return this;
    }

    setAffectedEntitiesIds(ids: number[]) {
        this.affected_entities_ids = ids;
        return this;
    }

    static get system_keys_SET() {
        return {
            duplicated_transfers: 'duplicated_transfers',
            background_recalculation_failed: 'background_recalculation_failed'
        };
    }
}
