import {FinancialNode} from '../financial-node/financial-node.model';
import {FinancialTransferEntity} from '../../../account-info/compensation/models/financial-transfer.entity';

export class CompanyCalculation {
    referral_expenses: FinancialTransferEntity[] = [];
    royalty_expenses: FinancialTransferEntity[] = [];
    ancillary_incomes: FinancialTransferEntity[] = [];
    ancillary_expenses: FinancialTransferEntity[] = [];
    sales_incomes: FinancialTransferEntity[] = [];
    sales_expenses: FinancialTransferEntity[] = [];
    commission_deductions: FinancialTransferEntity[] = [];
    company_association_incomes: FinancialTransferEntity[] = [];
    company_association_expenses: FinancialTransferEntity[] = [];
    company_incomes: FinancialTransferEntity[] = [];
    company_expenses: FinancialTransferEntity[] = [];
    company_splits: FinancialTransferEntity[] = [];
    compensation_incomes: FinancialTransferEntity[] = [];
    notes: string = '';

    sales_price: FinancialNode = new FinancialNode();
    base_commission: FinancialNode = new FinancialNode();
    compensation_split_sum: FinancialNode = new FinancialNode();
    compensation_incomes_sum: FinancialNode = new FinancialNode();
    commission_incomes_sum: FinancialNode = new FinancialNode();
    commission_expenses_sum: FinancialNode = new FinancialNode();
    commission: FinancialNode = new FinancialNode();
    referral: FinancialNode = new FinancialNode();
    commission_after_referral: FinancialNode = new FinancialNode();
    royalty: FinancialNode = new FinancialNode();
    net_commission: FinancialNode = new FinancialNode();
    deal_incomes_sum: FinancialNode = new FinancialNode();
    deal_expenses_sum: FinancialNode = new FinancialNode();
    deal_net: FinancialNode = new FinancialNode();
    sales_total: FinancialNode = new FinancialNode();
    sales_incomes_sum: FinancialNode = new FinancialNode();
    sales_expenses_sum: FinancialNode = new FinancialNode();
    sales_net: FinancialNode = new FinancialNode();
    company_split: FinancialNode = new FinancialNode();
    company_incomes_sum: FinancialNode = new FinancialNode();
    company_expenses_sum: FinancialNode = new FinancialNode();
    company_net: FinancialNode = new FinancialNode();
    agents_net: FinancialNode = new FinancialNode();
    external_net: FinancialNode = new FinancialNode();

    deal_deposit_request_amount: FinancialNode = new FinancialNode();
    deal_deposit_received_amount: FinancialNode = new FinancialNode();
    deal_deposit_allocated_amount: FinancialNode = new FinancialNode();
    deal_deposit_unallocated_amount: FinancialNode = new FinancialNode();
}

export class ParticipantCalculation {
    percent_of_deal: FinancialNode = new FinancialNode();
    compensation: FinancialNode = new FinancialNode();
    sales_split: FinancialNode = new FinancialNode();
    sales_net: FinancialNode = new FinancialNode();
    sales_incomes_sum: FinancialNode = new FinancialNode();
    sales_expenses_sum: FinancialNode = new FinancialNode();
    agent_split: FinancialNode = new FinancialNode();
    team_split: FinancialNode = new FinancialNode();
    participant_royalty: FinancialNode = new FinancialNode();
    after_step_1: FinancialNode = new FinancialNode();
    additional: FinancialNode[] = [];
    additional_incomes_sum: FinancialNode = new FinancialNode();
    additional_expenses_sum: FinancialNode = new FinancialNode();
    referral_expenses_sum: FinancialNode = new FinancialNode();
    agent_split_after_referral: FinancialNode = new FinancialNode();
    after_step_2: FinancialNode = new FinancialNode();
    taxes: FinancialNode = new FinancialNode();
    biz_exp: FinancialNode = new FinancialNode();
    overhead: FinancialNode = new FinancialNode();
    incentive: FinancialNode = new FinancialNode();
    after_step_3: FinancialNode = new FinancialNode();
    net: FinancialNode = new FinancialNode();
    budget: FinancialNode = new FinancialNode();
    compensation_split_sum: FinancialNode = new FinancialNode();
    compensation_incomes_sum: FinancialNode = new FinancialNode();
    compensation_expenses_sum: FinancialNode = new FinancialNode();
}

export class TransferCalculation {
    additional_incomes_sum: FinancialNode = new FinancialNode();
    referral_incomes_sum: FinancialNode = new FinancialNode();
    additional_expenses_sum: FinancialNode = new FinancialNode();
    incomes_sum: FinancialNode = new FinancialNode();
    net: FinancialNode = new FinancialNode();
}
