<div
    *ngIf="
        focusArea &&
        focusArea.id &&
        goalsInstancesListByFocusArea[focusArea.id] &&
        goalsInstancesListByFocusArea[focusArea.id].length > 0
    "
>
    <div class="goals-table m-3">
        <div class="goals-header">
            <div class="header-controls mb-2">
                <button mat-icon-button>
                    <mat-icon aria-label="Previous week" (click)="prevWeek()">keyboard_arrow_left</mat-icon>
                </button>
                <div class="d-inline-block align-middle">
                    {{ moment(weekDay(1)).format('MMM DD') }} - {{ moment(weekDay(7)).format('MMM DD, YYYY') }}
                </div>
                <button mat-icon-button>
                    <mat-icon aria-label="Next week" (click)="nextWeek()">keyboard_arrow_right</mat-icon>
                </button>
            </div>
        </div>
        <div class="goals-subheader mr-3">
            <div class="subheader-title goals-goal-col">Goals</div>
            <div class="subheader-week goals-week-col">
                <div class="subheader-day goals-day-col">Mon<br />{{ moment(weekDay(1)).format('MMM DD') }}</div>
                <div class="subheader-day goals-day-col">Tue<br />{{ moment(weekDay(2)).format('MMM DD') }}</div>
                <div class="subheader-day goals-day-col">Wed<br />{{ moment(weekDay(3)).format('MMM DD') }}</div>
                <div class="subheader-day goals-day-col">Thu<br />{{ moment(weekDay(4)).format('MMM DD') }}</div>
                <div class="subheader-day goals-day-col">Fri<br />{{ moment(weekDay(5)).format('MMM DD') }}</div>
                <div class="subheader-day goals-day-col">Sat<br />{{ moment(weekDay(6)).format('MMM DD') }}</div>
                <div class="subheader-day goals-day-col">Sun<br />{{ moment(weekDay(7)).format('MMM DD') }}</div>
            </div>
            <div class="goals-summary-col subheader-summary">Status</div>
        </div>
        <div *ngFor="let instance of goalsInstancesListByFocusArea[focusArea.id]" class="goals-row mr-3">
            <ng-container *ngIf="instance.goal && instance.goal.type === 'progress'">
                <div class="goals-goal-col">
                    <div class="goal-title">{{ instance.goal.title }}</div>
                    <mat-progress-bar
                        class="mirror"
                        mode="determinate"
                        value="{{ getProgressBarValue(instance) }}"
                    ></mat-progress-bar>
                </div>
                <div class="goals-week-col">
                    <ng-container *ngFor="let day of [1, 2, 3, 4, 5, 6, 7]">
                        <div class="goals-day-col goals-day-value" *ngIf="!editableTime(instance, day)">
                            {{ getDayValue(instance, day, '-') }}
                        </div>
                        <div class="goals-day-col goals-day-value" *ngIf="editableTime(instance, day)">
                            <input
                                style="width: 80%; text-align: center"
                                type="text"
                                *ngIf="instance.id"
                                [(ngModel)]="weekValues[day][instance.id]"
                                [ngClass]="getCorrespondingFontClass(weekValues[day][instance.id])"
                                (focusout)="focusOut(instance, day)"
                                class="goal-value-input"
                                (focus)="onFocus(day, instance.id)"
                            />
                        </div>
                    </ng-container>
                </div>
                <div class="goals-summary-col" [ngClass]="getCorrespondingFontClass(getWeeklyStatus(instance))">
                    {{ getWeeklyStatus(instance) }}
                </div>
            </ng-container>
            <ng-container *ngIf="instance.goal && instance.goal.type === 'general'">
                <div class="goals-goal-col">
                    <div class="goal-title">{{ instance.goal.title }}</div>
                </div>
                <div class="goals-week-col">
                    <ng-container *ngFor="let day of [1, 2, 3, 4, 5, 6]">
                        <div class="goals-day-col goals-day-value"></div>
                    </ng-container>
                    <div class="goals-day-col goals-day-value">
                        <mat-checkbox
                            *ngIf="instance.id"
                            color="primary"
                            [disabled]="currentWeek > 0"
                            (change)="focusOut(instance, 7)"
                            [(ngModel)]="weekValues[7][instance.id]"
                        >
                            Done
                        </mat-checkbox>
                    </div>
                </div>
                <div class="goals-summary-col" *ngIf="instance.id && !weekValues[7][instance.id]">open</div>
                <div class="goals-summary-col" *ngIf="instance.id && weekValues[7][instance.id]">completed</div>
            </ng-container>
        </div>
    </div>
</div>
<div *ngIf="showNoGoalsBoard()" class="no-widgets-board">
    <div>No goals in this focus area.</div>
</div>
