<div>
    <mat-card
        *ngIf="
            reportData.asset.length ||
            reportData.asset.equity ||
            reportData.asset.expense ||
            reportData.asset.income ||
            reportData.liability.length
        "
    >
        <div class="report-table w-100">
            <div class="flex-row no-margin font-weight-bold sticky">
                <div class="item-name">
                    <button
                        mat-icon-button
                        class="header-actions"
                        (click)="toggleExpand()"
                        [matTooltip]="expandTooltip"
                    >
                        <mat-icon>{{ isExpand ? 'unfold_less' : 'unfold_more' }}</mat-icon>
                    </button>
                    <span>Account</span>
                </div>
                <div class="item-value">Account Code</div>
                <div *ngIf="reportType === 'summary_trial_balance'" class="item-value">Opening Balance</div>
                <div class="item-value">Debit</div>
                <div class="item-value">Credit</div>
                <div *ngIf="reportType === 'summary_trial_balance'" class="item-value">Closing Balance</div>
            </div>

            <ng-container *ngIf="reportData.asset.length">
                <p class="header-group">Assets</p>

                <mat-tree [dataSource]="assetDataSource" [treeControl]="treeControlAsset" class="tree">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                        <li class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div class="item w-100 flex-row" [ngClass]="{'font-weight-bold': node.type === 'total'}">
                                <div class="item-name">
                                    {{ node.la_name }}
                                </div>
                                <div class="item-value">{{ node.la_code }}</div>
                                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                    {{ node.opening_balance | reportFormatted: 'financial' }}
                                </div>
                                <div class="item-value">
                                    <ng-container *ngIf="node.is_display_debit">
                                        {{ node.debit_with_children | reportFormatted: 'financial' }}
                                    </ng-container>
                                </div>
                                <div class="item-value">
                                    <ng-container *ngIf="node.is_display_credit">
                                        {{ node.credit_with_children | reportFormatted: 'financial' }}
                                    </ng-container>
                                </div>
                                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                    {{ node.closing_balance | reportFormatted: 'financial' }}
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.la_name"
                                    (click)="changeState(node)"
                                >
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{ treeControlAsset.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                    </mat-icon>
                                </button>

                                <div class="item w-100 flex-row">
                                    <div class="item-name">
                                        {{ node.la_name }}
                                    </div>
                                    <div class="item-value">{{ node.la_code }}</div>
                                    <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                        {{ node.opening_balance | reportFormatted: 'financial' }}
                                    </div>
                                    <div class="item-value">
                                        <ng-container *ngIf="node.is_display_debit || node.debit_with_children">
                                            <ng-container
                                                *ngIf="!treeControlAsset.isExpanded(node) && node.debit_with_children"
                                            >
                                                {{ node.debit_with_children | reportFormatted: 'financial' }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="treeControlAsset.isExpanded(node) && node.is_display_debit"
                                            >
                                                {{ node.debit | reportFormatted: 'financial' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="item-value">
                                        <ng-container *ngIf="node.is_display_credit || node.credit_with_children">
                                            <ng-container
                                                *ngIf="!treeControlAsset.isExpanded(node) && node.credit_with_children"
                                            >
                                                {{ node.credit_with_children | reportFormatted: 'financial' }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="treeControlAsset.isExpanded(node) && node.is_display_credit"
                                            >
                                                {{ node.credit | reportFormatted: 'financial' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                        {{ node.closing_balance | reportFormatted: 'financial' }}
                                    </div>
                                </div>
                            </div>
                            <ul [class.tree-invisible]="!treeControlAsset.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
            </ng-container>

            <ng-container *ngIf="reportData.liability.length">
                <p class="header-group">Liability</p>

                <mat-tree [dataSource]="liabilityDataSource" [treeControl]="treeControlLiability" class="tree">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                        <li class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div class="item w-100 flex-row" [ngClass]="{'font-weight-bold': node.type === 'total'}">
                                <div class="item-name">
                                    {{ node.la_name }}
                                </div>
                                <div class="item-value">{{ node.la_code }}</div>
                                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                    {{ node.opening_balance | reportFormatted: 'financial' }}
                                </div>
                                <div class="item-value">
                                    <ng-container *ngIf="node.is_display_debit">
                                        {{ node.debit_with_children | reportFormatted: 'financial' }}
                                    </ng-container>
                                </div>
                                <div class="item-value">
                                    <ng-container *ngIf="node.is_display_credit">
                                        {{ node.credit_with_children | reportFormatted: 'financial' }}
                                    </ng-container>
                                </div>
                                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                    {{ node.closing_balance | reportFormatted: 'financial' }}
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.la_name"
                                    (click)="changeState(node)"
                                >
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{ treeControlLiability.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                    </mat-icon>
                                </button>

                                <div class="item w-100 flex-row">
                                    <div class="item-name">
                                        {{ node.la_name }}
                                    </div>
                                    <div class="item-value">{{ node.la_code }}</div>
                                    <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                        {{ node.opening_balance | reportFormatted: 'financial' }}
                                    </div>
                                    <div class="item-value">
                                        <ng-container *ngIf="node.is_display_debit || node.debit_with_children">
                                            <ng-container
                                                *ngIf="
                                                    !treeControlLiability.isExpanded(node) && node.debit_with_children
                                                "
                                            >
                                                {{ node.debit_with_children | reportFormatted: 'financial' }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="treeControlLiability.isExpanded(node) && node.is_display_debit"
                                            >
                                                {{ node.debit | reportFormatted: 'financial' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="item-value">
                                        <ng-container *ngIf="node.is_display_credit || node.credit_with_children">
                                            <ng-container
                                                *ngIf="
                                                    !treeControlLiability.isExpanded(node) && node.credit_with_children
                                                "
                                            >
                                                {{ node.credit_with_children | reportFormatted: 'financial' }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="treeControlLiability.isExpanded(node) && node.is_display_credit"
                                            >
                                                {{ node.credit | reportFormatted: 'financial' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                        {{ node.closing_balance | reportFormatted: 'financial' }}
                                    </div>
                                </div>
                            </div>
                            <ul [class.tree-invisible]="!treeControlLiability.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
            </ng-container>

            <ng-container *ngIf="reportData.equity.length">
                <p class="header-group">Equity</p>

                <mat-tree [dataSource]="equityDataSource" [treeControl]="treeControlEquity" class="tree">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                        <li class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div class="item w-100 flex-row" [ngClass]="{'font-weight-bold': node.type === 'total'}">
                                <div class="item-name">
                                    {{ node.la_name }}
                                </div>
                                <div class="item-value">{{ node.la_code }}</div>
                                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                    {{ node.opening_balance | reportFormatted: 'financial' }}
                                </div>
                                <div class="item-value">
                                    <ng-container *ngIf="node.is_display_debit">
                                        {{ node.debit_with_children | reportFormatted: 'financial' }}
                                    </ng-container>
                                </div>
                                <div class="item-value">
                                    <ng-container *ngIf="node.is_display_credit">
                                        {{ node.credit_with_children | reportFormatted: 'financial' }}
                                    </ng-container>
                                </div>
                                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                    {{ node.closing_balance | reportFormatted: 'financial' }}
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.la_name"
                                    (click)="changeState(node)"
                                >
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{ treeControlEquity.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                    </mat-icon>
                                </button>

                                <div class="item w-100 flex-row">
                                    <div class="item-name">
                                        {{ node.la_name }}
                                    </div>
                                    <div class="item-value">{{ node.la_code }}</div>
                                    <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                        {{ node.opening_balance | reportFormatted: 'financial' }}
                                    </div>
                                    <div class="item-value">
                                        <ng-container *ngIf="node.is_display_debit || node.debit_with_children">
                                            <ng-container
                                                *ngIf="!treeControlEquity.isExpanded(node) && node.debit_with_children"
                                            >
                                                {{ node.debit_with_children | reportFormatted: 'financial' }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="treeControlEquity.isExpanded(node) && node.is_display_debit"
                                            >
                                                {{ node.debit | reportFormatted: 'financial' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="item-value">
                                        <ng-container *ngIf="node.is_display_credit || node.credit_with_children">
                                            <ng-container
                                                *ngIf="!treeControlEquity.isExpanded(node) && node.credit_with_children"
                                            >
                                                {{ node.credit_with_children | reportFormatted: 'financial' }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="treeControlEquity.isExpanded(node) && node.is_display_credit"
                                            >
                                                {{ node.credit | reportFormatted: 'financial' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                        {{ node.closing_balance | reportFormatted: 'financial' }}
                                    </div>
                                </div>
                            </div>
                            <ul [class.tree-invisible]="!treeControlEquity.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
            </ng-container>

            <ng-container *ngIf="reportData.income.length">
                <p class="header-group">Income</p>

                <mat-tree [dataSource]="incomeDataSource" [treeControl]="treeControlIncome" class="tree">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                        <li class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div class="item w-100 flex-row" [ngClass]="{'font-weight-bold': node.type === 'total'}">
                                <div class="item-name">
                                    {{ node.la_name }}
                                </div>
                                <div class="item-value">{{ node.la_code }}</div>
                                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                    {{ node.opening_balance | reportFormatted: 'financial' }}
                                </div>
                                <div class="item-value">
                                    <ng-container *ngIf="node.is_display_debit">
                                        {{ node.debit_with_children | reportFormatted: 'financial' }}
                                    </ng-container>
                                </div>
                                <div class="item-value">
                                    <ng-container *ngIf="node.is_display_credit">
                                        {{ node.credit_with_children | reportFormatted: 'financial' }}
                                    </ng-container>
                                </div>
                                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                    {{ node.closing_balance | reportFormatted: 'financial' }}
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.la_name"
                                    (click)="changeState(node)"
                                >
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{ treeControlIncome.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                    </mat-icon>
                                </button>

                                <div class="item w-100 flex-row">
                                    <div class="item-name">
                                        {{ node.la_name }}
                                    </div>
                                    <div class="item-value">{{ node.la_code }}</div>
                                    <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                        {{ node.opening_balance | reportFormatted: 'financial' }}
                                    </div>
                                    <div class="item-value">
                                        <ng-container *ngIf="node.is_display_debit || node.debit_with_children">
                                            <ng-container
                                                *ngIf="!treeControlIncome.isExpanded(node) && node.debit_with_children"
                                            >
                                                {{ node.debit_with_children | reportFormatted: 'financial' }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="treeControlIncome.isExpanded(node) && node.is_display_debit"
                                            >
                                                {{ node.debit | reportFormatted: 'financial' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="item-value">
                                        <ng-container *ngIf="node.is_display_credit || node.credit_with_children">
                                            <ng-container
                                                *ngIf="!treeControlIncome.isExpanded(node) && node.credit_with_children"
                                            >
                                                {{ node.credit_with_children | reportFormatted: 'financial' }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="treeControlIncome.isExpanded(node) && node.is_display_credit"
                                            >
                                                {{ node.credit | reportFormatted: 'financial' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                        {{ node.closing_balance | reportFormatted: 'financial' }}
                                    </div>
                                </div>
                            </div>
                            <ul [class.tree-invisible]="!treeControlIncome.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
            </ng-container>

            <ng-container *ngIf="reportData.cost_of_goods_sold.length">
                <p class="header-group">Cost of Goods Sold</p>

                <mat-tree
                    [dataSource]="costOfGoodsSoldDataSource"
                    [treeControl]="treeControlCostOfGoodsSold"
                    class="tree"
                >
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                        <li class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div class="item w-100 flex-row" [ngClass]="{'font-weight-bold': node.type === 'total'}">
                                <div class="item-name">
                                    {{ node.la_name }}
                                </div>
                                <div class="item-value">{{ node.la_code }}</div>
                                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                    {{ node.opening_balance | reportFormatted: 'financial' }}
                                </div>
                                <div class="item-value">
                                    <ng-container *ngIf="node.is_display_debit">
                                        {{ node.debit_with_children | reportFormatted: 'financial' }}
                                    </ng-container>
                                </div>
                                <div class="item-value">
                                    <ng-container *ngIf="node.is_display_credit">
                                        {{ node.credit_with_children | reportFormatted: 'financial' }}
                                    </ng-container>
                                </div>
                                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                    {{ node.closing_balance | reportFormatted: 'financial' }}
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.la_name"
                                    (click)="changeState(node)"
                                >
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{
                                            treeControlCostOfGoodsSold.isExpanded(node)
                                                ? 'expand_more'
                                                : 'chevron_right'
                                        }}
                                    </mat-icon>
                                </button>

                                <div class="item w-100 flex-row">
                                    <div class="item-name">
                                        {{ node.la_name }}
                                    </div>
                                    <div class="item-value">{{ node.la_code }}</div>
                                    <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                        {{ node.opening_balance | reportFormatted: 'financial' }}
                                    </div>
                                    <div class="item-value">
                                        <ng-container *ngIf="node.is_display_debit || node.debit_with_children">
                                            <ng-container
                                                *ngIf="
                                                    !treeControlCostOfGoodsSold.isExpanded(node) &&
                                                    node.debit_with_children
                                                "
                                            >
                                                {{ node.debit_with_children | reportFormatted: 'financial' }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="
                                                    treeControlCostOfGoodsSold.isExpanded(node) && node.is_display_debit
                                                "
                                            >
                                                {{ node.debit | reportFormatted: 'financial' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="item-value">
                                        <ng-container *ngIf="node.is_display_credit || node.credit_with_children">
                                            <ng-container
                                                *ngIf="
                                                    !treeControlCostOfGoodsSold.isExpanded(node) &&
                                                    node.credit_with_children
                                                "
                                            >
                                                {{ node.credit_with_children | reportFormatted: 'financial' }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="
                                                    treeControlCostOfGoodsSold.isExpanded(node) &&
                                                    node.is_display_credit
                                                "
                                            >
                                                {{ node.credit | reportFormatted: 'financial' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                        {{ node.closing_balance | reportFormatted: 'financial' }}
                                    </div>
                                </div>
                            </div>
                            <ul [class.tree-invisible]="!treeControlCostOfGoodsSold.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
            </ng-container>

            <ng-container *ngIf="reportData.expense.length">
                <p class="header-group">Expense</p>

                <mat-tree [dataSource]="expenseDataSource" [treeControl]="treeControlExpense" class="tree">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                        <li class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div class="item w-100 flex-row" [ngClass]="{'font-weight-bold': node.type === 'total'}">
                                <div class="item-name">
                                    {{ node.la_name }}
                                </div>
                                <div class="item-value">{{ node.la_code }}</div>
                                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                    {{ node.opening_balance | reportFormatted: 'financial' }}
                                </div>
                                <div class="item-value">
                                    <ng-container *ngIf="node.is_display_debit">
                                        {{ node.debit_with_children | reportFormatted: 'financial' }}
                                    </ng-container>
                                </div>
                                <div class="item-value">
                                    <ng-container *ngIf="node.is_display_credit">
                                        {{ node.credit_with_children | reportFormatted: 'financial' }}
                                    </ng-container>
                                </div>
                                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                    {{ node.closing_balance | reportFormatted: 'financial' }}
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.la_name"
                                    (click)="changeState(node)"
                                >
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{ treeControlExpense.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                    </mat-icon>
                                </button>

                                <div class="item w-100 flex-row">
                                    <div class="item-name">
                                        {{ node.la_name }}
                                    </div>
                                    <div class="item-value">{{ node.la_code }}</div>
                                    <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                        {{ node.opening_balance | reportFormatted: 'financial' }}
                                    </div>
                                    <div class="item-value">
                                        <ng-container *ngIf="node.is_display_debit || node.debit_with_children">
                                            <ng-container
                                                *ngIf="!treeControlExpense.isExpanded(node) && node.debit_with_children"
                                            >
                                                {{ node.debit_with_children | reportFormatted: 'financial' }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="treeControlExpense.isExpanded(node) && node.is_display_debit"
                                            >
                                                {{ node.debit | reportFormatted: 'financial' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="item-value">
                                        <ng-container *ngIf="node.is_display_credit || node.credit_with_children">
                                            <ng-container
                                                *ngIf="
                                                    !treeControlExpense.isExpanded(node) && node.credit_with_children
                                                "
                                            >
                                                {{ node.credit_with_children | reportFormatted: 'financial' }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="treeControlExpense.isExpanded(node) && node.is_display_credit"
                                            >
                                                {{ node.credit | reportFormatted: 'financial' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                        {{ node.closing_balance | reportFormatted: 'financial' }}
                                    </div>
                                </div>
                            </div>
                            <ul [class.tree-invisible]="!treeControlExpense.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
            </ng-container>

            <ng-container *ngIf="reportData.other_income.length">
                <p class="header-group">Other Income</p>

                <mat-tree [dataSource]="otherIncomeDataSource" [treeControl]="treeControlOtherIncome" class="tree">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                        <li class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div class="item w-100 flex-row" [ngClass]="{'font-weight-bold': node.type === 'total'}">
                                <div class="item-name">
                                    {{ node.la_name }}
                                </div>
                                <div class="item-value">{{ node.la_code }}</div>
                                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                    {{ node.opening_balance | reportFormatted: 'financial' }}
                                </div>
                                <div class="item-value">
                                    <ng-container *ngIf="node.is_display_debit">
                                        {{ node.debit_with_children | reportFormatted: 'financial' }}
                                    </ng-container>
                                </div>
                                <div class="item-value">
                                    <ng-container *ngIf="node.is_display_credit">
                                        {{ node.credit_with_children | reportFormatted: 'financial' }}
                                    </ng-container>
                                </div>
                                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                    {{ node.closing_balance | reportFormatted: 'financial' }}
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.la_name"
                                    (click)="changeState(node)"
                                >
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{ treeControlOtherIncome.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                    </mat-icon>
                                </button>

                                <div class="item w-100 flex-row">
                                    <div class="item-name">
                                        {{ node.la_name }}
                                    </div>
                                    <div class="item-value">{{ node.la_code }}</div>
                                    <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                        {{ node.opening_balance | reportFormatted: 'financial' }}
                                    </div>
                                    <div class="item-value">
                                        <ng-container *ngIf="node.is_display_debit || node.debit_with_children">
                                            <ng-container
                                                *ngIf="
                                                    !treeControlOtherIncome.isExpanded(node) && node.debit_with_children
                                                "
                                            >
                                                {{ node.debit_with_children | reportFormatted: 'financial' }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="treeControlOtherIncome.isExpanded(node) && node.is_display_debit"
                                            >
                                                {{ node.debit | reportFormatted: 'financial' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="item-value">
                                        <ng-container *ngIf="node.is_display_credit || node.credit_with_children">
                                            <ng-container
                                                *ngIf="
                                                    !treeControlOtherIncome.isExpanded(node) &&
                                                    node.credit_with_children
                                                "
                                            >
                                                {{ node.credit_with_children | reportFormatted: 'financial' }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="
                                                    treeControlOtherIncome.isExpanded(node) && node.is_display_credit
                                                "
                                            >
                                                {{ node.credit | reportFormatted: 'financial' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                        {{ node.closing_balance | reportFormatted: 'financial' }}
                                    </div>
                                </div>
                            </div>
                            <ul [class.tree-invisible]="!treeControlOtherIncome.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
            </ng-container>

            <ng-container *ngIf="reportData.other_expense.length">
                <p class="header-group">Other Expense</p>

                <mat-tree [dataSource]="otherExpenseDataSource" [treeControl]="treeControlOtherExpense" class="tree">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                        <li class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div class="item w-100 flex-row" [ngClass]="{'font-weight-bold': node.type === 'total'}">
                                <div class="item-name">
                                    {{ node.la_name }}
                                </div>
                                <div class="item-value">{{ node.la_code }}</div>
                                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                    {{ node.opening_balance | reportFormatted: 'financial' }}
                                </div>
                                <div class="item-value">
                                    <ng-container *ngIf="node.is_display_debit">
                                        {{ node.debit_with_children | reportFormatted: 'financial' }}
                                    </ng-container>
                                </div>
                                <div class="item-value">
                                    <ng-container *ngIf="node.is_display_credit">
                                        {{ node.credit_with_children | reportFormatted: 'financial' }}
                                    </ng-container>
                                </div>
                                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                    {{ node.closing_balance | reportFormatted: 'financial' }}
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.la_name"
                                    (click)="changeState(node)"
                                >
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{ treeControlOtherExpense.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                    </mat-icon>
                                </button>

                                <div class="item w-100 flex-row">
                                    <div class="item-name">
                                        {{ node.la_name }}
                                    </div>
                                    <div class="item-value">{{ node.la_code }}</div>
                                    <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                        {{ node.opening_balance | reportFormatted: 'financial' }}
                                    </div>
                                    <div class="item-value">
                                        <ng-container *ngIf="node.is_display_debit || node.debit_with_children">
                                            <ng-container
                                                *ngIf="
                                                    !treeControlOtherExpense.isExpanded(node) &&
                                                    node.debit_with_children
                                                "
                                            >
                                                {{ node.debit_with_children | reportFormatted: 'financial' }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="
                                                    treeControlOtherExpense.isExpanded(node) && node.is_display_debit
                                                "
                                            >
                                                {{ node.debit | reportFormatted: 'financial' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="item-value">
                                        <ng-container *ngIf="node.is_display_credit || node.credit_with_children">
                                            <ng-container
                                                *ngIf="
                                                    !treeControlOtherExpense.isExpanded(node) &&
                                                    node.credit_with_children
                                                "
                                            >
                                                {{ node.credit_with_children | reportFormatted: 'financial' }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="
                                                    treeControlOtherExpense.isExpanded(node) && node.is_display_credit
                                                "
                                            >
                                                {{ node.credit | reportFormatted: 'financial' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                                        {{ node.closing_balance | reportFormatted: 'financial' }}
                                    </div>
                                </div>
                            </div>
                            <ul [class.tree-invisible]="!treeControlOtherExpense.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
            </ng-container>

            <div class="flex-row font-weight-bold profit">
                <div class="item-name total"><strong>Total</strong></div>
                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                    {{ reportData.totals.opening_balance | reportFormatted: 'financial' }}
                </div>
                <div class="item-value">{{ reportData.totals.debit | reportFormatted: 'financial' }}</div>
                <div class="item-value">{{ reportData.totals.credit | reportFormatted: 'financial' }}</div>
                <div class="item-value" *ngIf="reportType === 'summary_trial_balance'">
                    {{ reportData.totals.closing_balance | reportFormatted: 'financial' }}
                </div>
            </div>
        </div>

        <p class="m-2">*Amount is displayed in your base currency <span class="badge-secondary">USD</span></p>
    </mat-card>

    <mat-card
        class="preview-body"
        *ngIf="
            !reportData.asset.length &&
            !reportData.asset.equity &&
            !reportData.asset.expense &&
            !reportData.asset.income &&
            !reportData.liability.length
        "
    >
        <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
    </mat-card>
</div>
