<div>
    <h2 class="m-0">Bank Name</h2>
    <div *ngIf="methodForm" [formGroup]="methodForm">
        <div class="mb-3" style="font-size: 0.875rem">
            {{
                currentPaymentMethod && currentPaymentMethod.original_bank_name
                    ? currentPaymentMethod!.original_bank_name
                    : currentPaymentMethod!.title
            }}
        </div>

        <div class="d-flex w-100">
            <mat-form-field class="w-50 mr-2">
                <input matInput required type="text" formControlName="title" />
                <mat-placeholder>Preferred Bank Account Name</mat-placeholder>
            </mat-form-field>
            <mat-form-field class="w-50">
                <mat-select formControlName="related__ledger_account_fk_id">
                    <mat-option *ngFor="let account of availableLedgerAccounts" [value]="account.ledger_account_id">
                        {{ account.name }}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="availableLedgerAccounts.length === 0">No Accounts to Connect</mat-hint>
                <mat-placeholder>Ledger Account</mat-placeholder>
            </mat-form-field>
        </div>
    </div>
    <div class="d-flex justify-content-end">
        <button *ngIf="!data.hideCancel" mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">
            Cancel
        </button>
        <button mat-raised-button class="mx-2" color="primary" (click)="continue()" tabindex="1">Save</button>
    </div>
</div>
