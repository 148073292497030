import {merge as observableMerge, Observable, Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CurrentProfileSource} from './current-profile.source';
import {Profile} from '../../models/profile';
import {ProfilesService} from '../profiles.service';
import {DataSource} from '@angular/cdk/collections';

@Injectable()
export class ProfilesSource extends DataSource<Profile> {
    public sources: {[key: string]: Subject<Profile[]>} = {
        load: new Subject<Profile[]>()
    };

    private _source: Observable<Profile[]> = observableMerge(
        ...Object.keys(this.sources).map((key) => this.sources[key])
    );
    private _tmp: Profile[] | undefined;
    constructor(
        public profilesApi: ProfilesService,
        protected profileSource: CurrentProfileSource
    ) {
        super();
        profileSource.changeProfileEvent.subscribe((profile: Profile) => {
            if (profile.type === Profile.type.default) {
                this.load(profile);
            }
        });
    }

    connect() {
        return this.source;
    }

    disconnect() {}

    public load(profile: Profile): Promise<Profile[]> {
        return this.profilesApi.getCompanyProfiles(profile.company!).then((list: Profile[]) => {
            this._tmp = list.slice();
            this.sources.load.next(this._tmp);
            return list;
        });
    }

    public get source() {
        if (this._tmp) {
            return this._source.pipe(startWith(this._tmp));
        }
        return this._source;
    }
}
