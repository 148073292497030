import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../auth.service';

@Injectable()
export class NoAuthBootstrapService implements Resolve<{}> {
    constructor(
        @Inject(AuthService) protected authService: AuthService,
        protected router: Router
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.load(route, state);
    }

    load(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return Promise.resolve(true);
    }
}
