import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {InvoicesService} from '../../../../../../../services/api/finance/invoices.service';
import {firstValueFrom, Observable, Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {IFinanceState} from '../../../../../../finance/store/finance.reducer';
import {FetchLedgerAccounts, FetchProducts} from '../../../../../../finance/store/finance.actions';
import {selectLedgerAccounts, selectProducts} from '../../../../../../finance/store/finance.selectors';
import {PAYMENT_MODES, SENDER_VELOCITY_TYPES} from '../../../../../../../local-typings';
import {
    GatewayTypes,
    IContact,
    IDealDeposit,
    IDealDepositRelease,
    IFinancialTransfer,
    IPaymentGateway,
    IPaymentMethod,
    IProduct
} from '@cyberco-nodejs/zipi-typings';
import {NotificationsService} from 'angular2-notifications';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {environment} from '../../../../../../../../environments/environment';
import {CompanyGatewayService} from '../../../../../../../services/api/finance/company-gateway.service';
import {selectContacts} from '../../../../../../contacts/store/contacts.selectors';
import {takeUntil} from 'rxjs/operators';
import {Deal} from '../../../../../../../models/deal';
import {FinancialTransferEntity} from '../../../../../../account-info/compensation/models/financial-transfer.entity';
import {FormGroupArray} from '../../../../../../../typings/common';
import {FeatureFlagsService} from '../../../../../../feature-flags/feature-flags.service';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import moment from 'moment-timezone';
import {MatOptionSelectionChange} from '@angular/material/core';

@Component({
    selector: 'app-process-payout-dialog',
    templateUrl: './process-payout-dialog.component.html',
    styleUrls: ['./process-payout-dialog.component.scss']
})
export class ProcessPayoutDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    // @ts-ignore
    dealTypesMap: {[key: string]: string} = {
        buyer: 'Buyer Side',
        seller: 'Listing Side',
        rental_tenant: 'Rental Tenant',
        rental_owner: 'Rental Owner',
        referral: 'Referral',
        broker_price_opinion: 'Broker Price Opinion'
    };

    formGroup: UntypedFormGroup | undefined;
    product$: Observable<IProduct[]> | undefined;
    contacts: IContact[] = [];
    itemsArray: FormGroupArray = this.fb.array([]) as FormGroupArray;

    ledgerAccount$: any;
    paymentModes = PAYMENT_MODES;
    isProcessing = false;

    environment = environment.env;

    senderVelocityTypes = SENDER_VELOCITY_TYPES;

    companyMainContactId: number | undefined;

    zipiFinancialGateways: IPaymentGateway[] | undefined = [];
    contactAvailableGatewayTypes: {[key in GatewayTypes]: boolean} | undefined;

    brokerReleasesAmount: number = 0;

    dualTransfers: IFinancialTransfer[] = [];
    dualDealForm: UntypedFormGroup | undefined;
    dealForm: UntypedFormGroup | undefined;
    dualDealBrokerReleasesAmount: number = 0;
    dualDealDeposit: IDealDeposit | undefined;

    releaseList: IDealDepositRelease[] = [];

    disallowDualClosing: boolean;
    dualClosingErrors: Array<string>;

    closingForDual: UntypedFormControl;

    isScheduleEnabled: boolean = false;

    minScheduleDate: any;
    maxScheduleDate: any;

    // feature_flags
    isMoneyTransfersFeatureFlagEnabled: boolean = false;
    isScheduledPaymentFeatureFlagEnabled: boolean = false;
    isVelocityFeatureFlagEnabled: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private invoicesService: InvoicesService,
        private store: Store<IFinanceState>,
        private ntfs: NotificationsService,
        private companyGatewayService: CompanyGatewayService,
        public dialogRef: MatDialogRef<ProcessPayoutDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        protected featureFlagsService: FeatureFlagsService
    ) {
        this.disallowDualClosing = true;
        this.dualClosingErrors = [];
        this.closingForDual = this.fb.control(false, []);
    }

    async addItem(item: FinancialTransferEntity) {
        this.itemsArray.push(await this.createItem(item));
    }

    async createItem(transfer: FinancialTransferEntity) {
        const deal = this.data.dealFormGroup.getRawValue();
        let group: UntypedFormGroup;

        const contactReleases = this.releaseList.filter(
            (release) => transfer.receiver && transfer.receiver.contact_id === release.release_receiver__contact_fk_id
        );

        const releasesAmount = contactReleases.reduce((sum, release) => release.release_amount + sum, 0);

        // for invoices
        if (this.isInvoiceToCreate(transfer)) {
            group = this.fb.group({
                transfer_fk_id: [transfer.id, [Validators.required]],
                reference: [`Payment for deal# ${deal.id}. \r\n ${this.getDealFullAddress(deal)}`, []],
                invoice_date: [deal.close_of_escrow, [Validators.required]],
                escrow_date: [deal.close_of_escrow, []],
                ledger_account_id: [null, [Validators.required]],
                product_fk_id: [transfer.product_fk_id, [Validators.required]],
                payment_mode: ['check', [Validators.required]],
                is_check_tab: [false, []],
                amount: [Number(transfer.amount).toFixed(2), []],
                sender_link_title: [transfer.sender ? this.getContactName(transfer.sender.contact_id!) : null, []],
                sender_contact_id: [transfer.sender ? transfer.sender.contact_id : null, []],
                receiver_link_title: [
                    transfer.receiver ? this.getContactName(transfer.receiver.contact_id!) : null,
                    []
                ],
                pay_to__payment_method_fk_ids: [[], []],
                pay_to_card__payment_method_fk_id: [null, []],
                pay_to_bank__payment_method_fk_id: [null, []],
                is_invoice: [true, []],
                connected_number: [transfer.connected_invoice ? transfer.connected_invoice.invoice_number : null, []],
                connected_status: [transfer.connected_invoice ? transfer.connected_invoice.summary_status : null, []],
                connected_entity_id: [transfer.connected__invoice_fk_id, []],
                is_create_multiple_payments: [true, []],
                check_number: [null, []],
                releases_amount: [releasesAmount, []],
                apply_releases: [false, []],
                total_amount: [Number(transfer.amount).toFixed(2), []],
                sender_velocity: [null, []],
                restrict_downgrade: [false, []]
            });

            if (this.data.cardType !== 'escrow') {
                group.addControl('is_record_payment', new UntypedFormControl(true, []));
                group.controls
                    .is_record_payment!.valueChanges.pipe(takeUntil(this.unsubscribe))
                    .subscribe((checked) => {
                        if (checked) {
                            group.controls.ledger_account_id!.setValidators([Validators.required]);
                            group.controls.ledger_account_id!.updateValueAndValidity();
                            group.controls.payment_mode!.setValidators([Validators.required]);
                            group.controls.payment_mode!.updateValueAndValidity();
                        } else {
                            group.controls.ledger_account_id!.clearValidators();
                            group.controls.ledger_account_id!.updateValueAndValidity();
                            group.controls.payment_mode!.clearValidators();
                            group.controls.payment_mode!.updateValueAndValidity();
                        }
                    });
            }
        } else {
            // for bills
            group = this.fb.group({
                transfer_fk_id: [transfer.id, [Validators.required]],
                reference: [`Payment for deal# ${deal.id}. \r\n ${this.getDealFullAddress(deal)}`, []],
                invoice_date: [deal.close_of_escrow, [Validators.required]],
                escrow_date: [deal.close_of_escrow, []],
                ledger_account_id: [null, [Validators.required]],
                product_fk_id: [transfer.product_fk_id, [Validators.required]],
                payment_mode: ['check', [Validators.required]],
                is_check_tab: [false, []],
                amount: [Number(transfer.amount).toFixed(2), []],
                sender_link_title: [transfer.sender ? this.getContactName(transfer.sender.contact_id!) : null, []],
                sender_contact_id: [transfer.sender ? transfer.sender.contact_id : null, []],
                receiver_link_title: [
                    transfer.receiver ? this.getContactName(transfer.receiver.contact_id!) : null,
                    []
                ],
                is_pay_later: [false, []],
                paid_by__payment_method_fk_id: [null, [Validators.required]],
                memo: [this.getDealFullAddress(deal), []],
                check_number: [null, []],
                pay_to__payment_method_fk_ids: [[], []],
                pay_to_card__payment_method_fk_id: [null, []],
                pay_to_bank__payment_method_fk_id: [null, []],
                is_invoice: [false, []],
                connected_number: [transfer.connected_bill ? transfer.connected_bill.bill_number : null, []],
                connected_status: [transfer.connected_bill ? transfer.connected_bill.summary_status : null, []],
                connected_entity_id: [transfer.connected__bill_fk_id, []],
                is_create_multiple_payments: [true, []],
                releases_amount: [releasesAmount, []],
                apply_releases: [false, []],
                total_amount: [Number(transfer.amount).toFixed(2), []],
                // total_amount: [100000, []],
                scheduled_date: [null, []],
                sender_velocity: [null, []],
                restrict_downgrade: [false, []],
                selected_method_type: [null, []]
            });

            this.contactAvailableGatewayTypes = await this.isContactAvailableToReceiveByPaymentMode(
                transfer.receiver!.contact_id!,
                'zipi_financial'
            );
            let isContactAvailableToReceiveZipiBusiness = this.contactAvailableGatewayTypes['zipi_financial_business'];
            if (!isContactAvailableToReceiveZipiBusiness) {
                isContactAvailableToReceiveZipiBusiness =
                    this.contactAvailableGatewayTypes['zipi_financial_receive_only'];
            }

            this.zipiFinancialGateways = await this.getZipiGateways(
                ['zipi_financial_business'],
                transfer.receiver?.contact_id!
            );
            const isAvailableToSendZipiFinancialBusiness = !!this.zipiFinancialGateways.find(
                (gateway) => gateway.type === 'zipi_financial_business'
            );

            if (!isContactAvailableToReceiveZipiBusiness || !isAvailableToSendZipiFinancialBusiness) {
                group.addControl('zipi_financial_disabled', new UntypedFormControl(true, []));
            }

            group.controls.is_pay_later!.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((checked) => {
                if (checked) {
                    group.controls.paid_by__payment_method_fk_id!.disable();
                    group.controls.ledger_account_id!.disable();
                    group.controls.sender_velocity.clearValidators();
                    group.controls.sender_velocity.updateValueAndValidity();
                    group.updateValueAndValidity();
                } else {
                    group.controls.paid_by__payment_method_fk_id!.enable();
                    group.controls.ledger_account_id!.enable();
                    group.controls.sender_velocity.clearValidators();
                    group.controls.sender_velocity.updateValueAndValidity();
                    group.updateValueAndValidity();
                }
            });

            this.setPaymentMode(group, 'zipi-financial');
        }

        group.controls.apply_releases!.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((checked) => {
            if (checked) {
                const newAmount = group.controls.amount!.value - group.controls.releases_amount!.value;
                group.controls.total_amount!.setValue(newAmount <= 0 ? 0 : newAmount);
                if (newAmount <= 0) {
                    group.controls.paid_by__payment_method_fk_id!.disable();
                    group.controls.ledger_account_id!.disable();
                    group.updateValueAndValidity();
                }
            } else {
                group.controls.total_amount!.setValue(group.controls.amount!.value);
                group.controls.paid_by__payment_method_fk_id!.enable();
                group.controls.ledger_account_id!.enable();
                group.updateValueAndValidity();
            }
        });

        if (group.controls.releases_amount!.value > 0) {
            group.controls.apply_releases!.setValue(true);
        }
        if (Number(group.controls.total_amount.value) === 0) {
            group.controls.invoice_date.disable();
            group.controls.transfer_fk_id.clearValidators();
            group.controls.transfer_fk_id.updateValueAndValidity();
            group.controls.ledger_account_id.clearValidators();
            group.controls.ledger_account_id.updateValueAndValidity();
        }

        if (this.isVelocityFeatureFlagEnabled && this.data.cardType !== 'escrow') {
            if (Number(group.controls.total_amount.value) >= 100000) {
                group.controls.sender_velocity.clearValidators();
                group.controls.sender_velocity.setValidators([
                    Validators.required,
                    Validators.pattern(/^((?!instant).)*$/)
                ]);
                group.controls.sender_velocity.updateValueAndValidity();
            } else {
                group.controls.sender_velocity.clearValidators();
                group.controls.sender_velocity.setValidators([Validators.required]);
                group.controls.sender_velocity.updateValueAndValidity();
            }
        }

        return group;
    }

    isInvoiceToCreate(transfer: FinancialTransferEntity) {
        if (transfer.receiver && transfer.receiver.contact_id === this.companyMainContactId) {
            return true;
        }
        return false;
    }

    getDealFullAddress(deal: Deal) {
        let fullAddress = '';
        if (deal.address) {
            if (deal.street_number) {
                fullAddress += `${deal.street_number} `;
            }
            fullAddress += `${deal.address} `;
            if (deal.unit) {
                fullAddress += `${deal.unit}, `;
            }
            fullAddress += `${deal.city}, `;
            fullAddress += `${deal.state} `;
            fullAddress += deal.zip;
        } else {
            fullAddress = 'Property address';
        }
        return fullAddress;
    }

    async isContactAvailableToReceiveByPaymentMode(
        contact_id: number,
        mode: 'zipi_financial'
    ): Promise<{[key in GatewayTypes]: boolean}> {
        return await firstValueFrom(
            this.companyGatewayService.isContactAvailableToReceiveByPaymentMode(contact_id, mode)
        );
    }

    async getZipiGateways(allowedTypes: Array<GatewayTypes>, contact_id: number | null): Promise<IPaymentGateway[]> {
        return await firstValueFrom(this.companyGatewayService.getCompanyGatewaysByTypes(allowedTypes, contact_id));
    }

    setPaymentMode(group: UntypedFormGroup, mode: string) {
        switch (mode) {
            // case 'zipi-pay':
            //     group.controls.paid_by__payment_method_fk_id!.enable();
            //     group.controls.ledger_account_id!.reset(null);
            //     group.controls.ledger_account_id!.disable();
            //     group.controls.payment_mode!.patchValue('zipi_pay');
            //     group.controls.is_check_tab!.patchValue(false);
            //     group.controls.memo!.reset(null);
            //     group.updateValueAndValidity();
            //     break;
            case 'zipi-financial':
                group.controls.paid_by__payment_method_fk_id!.enable();
                group.controls.ledger_account_id!.reset(null);
                group.controls.ledger_account_id!.disable();
                group.controls.payment_mode!.patchValue('zipi_financial');
                group.controls.is_check_tab!.patchValue(false);
                group.controls.memo!.reset(null);
                group.controls.sender_velocity.clearValidators();
                group.controls.sender_velocity.updateValueAndValidity();
                group.updateValueAndValidity();
                break;
            case 'check':
                group.controls.paid_by__payment_method_fk_id!.disable();
                group.controls.ledger_account_id!.reset(null);
                group.controls.ledger_account_id!.enable();
                group.controls.payment_mode!.patchValue('check');
                group.controls.is_check_tab!.patchValue(true);
                group.controls.memo!.reset(this.getDealFullAddress(this.data.dealFormGroup.getRawValue()));
                group.controls.sender_velocity.clearValidators();
                group.controls.sender_velocity.updateValueAndValidity();
                group.updateValueAndValidity();
                break;
            case 'record':
                group.controls.paid_by__payment_method_fk_id!.disable();
                group.controls.ledger_account_id!.patchValue(null);
                group.controls.ledger_account_id!.enable();
                group.controls.payment_mode!.patchValue('check');
                group.controls.is_check_tab!.patchValue(false);
                group.controls.memo!.reset(this.getDealFullAddress(this.data.dealFormGroup.getRawValue()));
                group.controls.sender_velocity.clearValidators();
                group.controls.sender_velocity.updateValueAndValidity();
                group.updateValueAndValidity();
                break;
        }
        group.controls.scheduled_date.setValue(null);
        this.isScheduleEnabled = false;
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent, group: UntypedFormGroup) {
        switch (tabChangeEvent.index) {
            // case 0:
            //     this.setPaymentMode(group, 'zipi-pay');
            //     break;
            case 0:
                this.setPaymentMode(group, 'zipi-financial');
                break;
            case 1:
                this.setPaymentMode(group, 'check');
                break;
            case 2:
                this.setPaymentMode(group, 'record');
                break;
        }
    }

    methodChanged($event: MatOptionSelectionChange, gateway: IPaymentGateway, method: IPaymentMethod, itemGroup: any) {
        if ($event.isUserInput && gateway && gateway.sending_velocity && this.isVelocityFeatureFlagEnabled) {
            itemGroup.controls.restrict_downgrade.setValue(gateway.sending_velocity.restrict_auto_downgrade);
            if (this.isVelocityFeatureFlagEnabled) {
                if (Number(itemGroup.controls.total_amount.value) >= 100000) {
                    itemGroup.controls.sender_velocity.clearValidators();
                    itemGroup.controls.sender_velocity.setValidators([
                        Validators.required,
                        Validators.pattern(/^((?!instant).)*$/)
                    ]);
                    itemGroup.controls.sender_velocity.updateValueAndValidity();
                } else {
                    itemGroup.controls.sender_velocity.clearValidators();
                    itemGroup.controls.sender_velocity.setValidators([Validators.required]);
                    itemGroup.controls.sender_velocity.updateValueAndValidity();
                }
            }
            if (['zipi_financial_balance', 'zipi_financial_cloud_bank'].includes(method.type)) {
                itemGroup.controls.selected_method_type.setValue('balance');
                if (gateway.sending_velocity.velocity === 'standard') {
                    itemGroup.controls.sender_velocity.setValue('instant');
                } else {
                    itemGroup.controls.sender_velocity.setValue(gateway.sending_velocity.velocity);
                }
            } else {
                itemGroup.controls.selected_method_type.setValue('');
                itemGroup.controls.sender_velocity.setValue(gateway.sending_velocity.velocity);
            }
        }
    }

    commissionPayerChanged($event: MatSlideToggleChange, itemGroup: any) {
        if ($event.checked) {
            if (itemGroup.controls.selected_method_type.value === 'balance') {
                itemGroup.controls.sender_velocity.setValue('instant');
            } else {
                itemGroup.controls.sender_velocity.setValue('standard');
            }
        } else {
            itemGroup.controls.sender_velocity.setValue('no_fee');
        }
    }

    validateForm() {
        if (!this.itemsArray.valid) {
            return false;
        }
        return true;
    }

    isCompanySender(sender_contact_id: number) {
        if (
            sender_contact_id &&
            sender_contact_id === this.data.dealFormGroup.getRawValue().company.main__contact_fk_id
        ) {
            return true;
        }
        return false;
    }

    saveForm() {
        if (this.isProcessing) {
            return;
        }
        this.isProcessing = true;

        if (this.validateForm() !== true) {
            this.ntfs.warn('Form is not valid');
            return;
        }
        let dataToReturn = this.itemsArray.getRawValue();
        dataToReturn[0]['dual_amount'] = 0;
        // add system payment methods to return
        dataToReturn = dataToReturn.map((item) => {
            if (item.ledger_account_id) {
                item['pay_to__ledger_account_fk_id'] = item.ledger_account_id;
            }

            if (item.payment_mode !== 'check') {
                item.check_number = null;
                item.memo = null;
            }

            return item;
        });
        dataToReturn[0]['escrow_date'] = this.data.dealFormGroup.getRawValue().close_of_escrow;
        if (this.data.cardType === 'escrow' && dataToReturn.length === 1) {
            dataToReturn[0]['closingForDual'] = this.closingForDual.value;
            if (dataToReturn[0]['closingForDual']) {
                dataToReturn[0]['dual_amount'] = this.dualTransfers[0].amount;
            }
        }
        this.dialogRef.close(dataToReturn);
    }

    changePayMode($event: MatSlideToggleChange, itemGroup: UntypedFormGroup) {
        if ($event.checked) {
            this.isScheduleEnabled = true;
            this.minScheduleDate = moment().add(1, 'days').toDate();
            this.maxScheduleDate = moment().add(28, 'days').toDate();
        } else {
            this.isScheduleEnabled = false;
            itemGroup.controls.scheduled_date.setValue(null);
        }
    }

    ngOnInit() {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.isMoneyTransfersFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:money_transfer'
                );
                this.isScheduledPaymentFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:scheduled_transfer'
                );
                this.isVelocityFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:velocity'
                );
            });
        this.brokerReleasesAmount = this.data.brokerReleasesAmount;
        this.companyMainContactId = this.data.companyMainContactId;

        this.dualTransfers = this.data.dualTransfers;
        this.dualDealForm = this.data.dualDealForm;
        this.dealForm = this.data.dealFormGroup;
        this.dualDealBrokerReleasesAmount = this.data.dualDealBrokerReleasesAmount;
        this.dualDealDeposit = this.data.dualDealDeposit;

        if (this.data.releaseList && Array.isArray(this.data.releaseList)) {
            this.releaseList = this.data.releaseList;
        }

        this.store.dispatch(new FetchProducts());
        this.product$ = this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe));

        this.store.dispatch(new FetchLedgerAccounts());
        this.ledgerAccount$ = this.store.pipe(select(selectLedgerAccounts), takeUntil(this.unsubscribe));

        this.store
            .pipe(
                select(selectContacts), // migrate to ContactsLite: done
                takeUntil(this.unsubscribe)
            )
            .subscribe((data) => {
                this.contacts = data;
            });

        if (this.data.transfers.length > 0) {
            this.data.transfers.forEach((item: FinancialTransferEntity) => this.addItem(item));
        } else {
            // this.addItem();
        }

        if (this.dualDealForm && this.data.cardType === 'escrow') {
            this.checkIsDualClosingAble();
        }
    }

    checkIsDualClosingAble() {
        const dualDeal = this.dualDealForm!.getRawValue();

        if (!dualDeal.disbursement_approved) {
            this.dualClosingErrors.push(`Please approve disbursements of Dual Deal.`);
        }
        if (dualDeal.is_escrow_paid) {
            this.dualClosingErrors.push(`The Payment Received has already been confirmed for Dual Deal.`);
        }
        if (this.dualDealDeposit && this.dualDealDeposit.unreleased_balance >= 0.01) {
            this.dualClosingErrors.push(`The Dual Deal has unreleased deposits.`);
        }
        if (
            this.data.transfers[0].sender &&
            this.dualTransfers[0].sender &&
            this.data.transfers[0].sender.contact_id !== this.dualTransfers[0].sender.contact_id
        ) {
            this.dualClosingErrors.push(`The Dual Deal has different commission payer.`);
        }
        if (
            this.data.transfers[0].receiver &&
            this.dualTransfers[0].receiver &&
            this.data.transfers[0].receiver.contact_id !== this.dualTransfers[0].receiver.contact_id
        ) {
            this.dualClosingErrors.push(`The Dual Deal has different money receiver contact.`);
        }

        if (this.dualClosingErrors.length === 0) {
            this.disallowDualClosing = false;
        }
    }

    getContactName(contact_id: number) {
        const transferContact: IContact | undefined = this.contacts.find(
            (contact) => contact.contact_id === contact_id
        );
        return typeof transferContact !== 'undefined' ? transferContact.display_name : 'N/A';
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
