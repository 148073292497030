<div mat-dialog-title>
    <h3>Add Location</h3>
</div>

<mat-dialog-content>
    <div class="zp-place-picker">
        <app-gplaces-picker
            [locationCtrl]="locationPickerCtrl ? locationPickerCtrl : null"
            [focused]="true"
        ></app-gplaces-picker>
    </div>
    <app-contact-location [locationCtrl]="locationGroup" [isEditingMode]="true"> </app-contact-location>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="text-right w-100">
        <button mat-raised-button color="accent" (click)="dialogRef.close(locationGroup!.value)" tabindex="1">
            Add
        </button>
        <button mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">Cancel</button>
    </div>
</mat-dialog-actions>
