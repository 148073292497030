import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {RbacService} from '../../../rbac/rbac.service';
import {IScrollData} from '../../../../models/scroll-data';
import {MatDialog} from '@angular/material/dialog';
import {YodataEditEntityDialogComponent} from './edit-entity-dialog/edit-entity-dialog.component';
import {YodataCreateContactDialogComponent} from './create-contact-dialog/create-contact-dialog.component';
import {MarketplaceApi} from '../marketplace.api';
import {Group} from '../../../../models/group';
import {Profile} from '../../../../models/profile';

@Component({
    selector: 'app-yodata-settings-table',
    template: `
        <div class="table-background">
            <table
                mat-table
                [dataSource]="dataSource"
                [ngClass]="{'d-none': dataSource.data.length === 0}"
                class="w-100"
            >
                <ng-container matColumnDef="first_name">
                    <th mat-header-cell *matHeaderCellDef class="w-10">First Name</th>
                    <td mat-cell (click)="$event.stopPropagation()" *matCellDef="let element" class="w-10 px-1">
                        {{ element.first_name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="last_name">
                    <th mat-header-cell *matHeaderCellDef class="w-10">Last Name</th>
                    <td mat-cell (click)="$event.stopPropagation()" *matCellDef="let element" class="w-10 px-1">
                        {{ element.last_name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef class="w-10">Title</th>
                    <td mat-cell (click)="$event.stopPropagation()" *matCellDef="let element" class="w-10 px-1">
                        {{ element.title }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef class="w-10">Email</th>
                    <td mat-cell (click)="$event.stopPropagation()" *matCellDef="let element" class="w-10 px-1">
                        {{ element.email }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="yodata_mapping">
                    <th mat-header-cell *matHeaderCellDef class="w-10">POD URI</th>
                    <td mat-cell (click)="$event.stopPropagation()" *matCellDef="let element" class="w-10 px-1">
                        {{ element.yodata_mapping?.pod_uri }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="w-5">Action</th>
                    <td mat-cell (click)="$event.stopPropagation()" *matCellDef="let element" class="w-5">
                        <button
                            mat-icon-button
                            [rule]="{marketplace__manage_yodata: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            [matMenuTriggerFor]="menu"
                            (click)="$event.stopPropagation()"
                        >
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button
                                mat-menu-item
                                [rule]="{marketplace__manage_yodata: true}"
                                rbac
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'inline-block'}"
                                [denyStyle]="{display: 'none'}"
                                (click)="$event.stopPropagation(); editEntity(element, entityType)"
                            >
                                Edit
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    [routerLink]="row.id"
                    class="zp-table-row"
                ></tr>
            </table>

            <!------- Paginator ------->
            <div *ngIf="entityType === 'profile'" class="custom-paginator-background"></div>
            <mat-paginator
                [ngClass]="{'d-none': dataSource.data.length === 0 || entityType !== 'profile'}"
                class="custom-paginator"
                [length]="scrollData.total"
                [pageSize]="scrollData.limit"
                [pageSizeOptions]="pageSizeOptions"
            >
            </mat-paginator>
            <!------- No values ------->
            <div
                *ngIf="dataSource.data && dataSource.data.length === 0 && !isLoading"
                class="text-muted m-3 text-center no-items"
            >
                {{ entityType === 'profile' ? 'No profiles.' : 'No divisions.' }}
            </div>
        </div>
    `,
    styleUrls: []
})
export class YodataSettingsTableComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() events: Observable<void> | undefined;
    // private unsubscribeBatch: Subject<void> = new Subject();
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator = new MatPaginator(
        new MatPaginatorIntl(),
        ChangeDetectorRef.prototype
    );

    @Input() entityType: string = '';
    @Output() runEdit = new EventEmitter<void>();

    scrollData: IScrollData = {
        offset: 0,
        limit: 25,
        sort_column: 'first_name',
        sort_direction: 'desc',
        summary_status_filter: undefined,
        payment_status_filter: undefined,
        total: 0
    };
    pageSizeOptions = [25, 50, 100];
    @Input() dataSource: MatTableDataSource<Group | Profile | any> = new MatTableDataSource<Group | Profile | any>([]);
    @Input() displayedColumns = ['first_name', 'last_name', 'email', 'yodata_mapping', 'action'];
    @Input() divisions: MatTableDataSource<Group | any> = new MatTableDataSource<Group | any>([]);

    isLoading = false;
    isLoaded = false;
    currentProfile = null;

    constructor(
        public router: Router,
        protected rbacService: RbacService,
        public dialog: MatDialog,
        public marketplaceApi: MarketplaceApi
    ) {}

    ngOnInit() {
        if (this.entityType === 'profile') {
            this.nextBatch();
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.nextBatch();
            });
        } else if (this.entityType === 'division') {
            // this will fire after tab changed
        }

        if (this.events && this.entityType === 'profile') {
            this.events.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
                this.scrollData.offset = 0;
                this.scrollData.limit = 25;
                this.nextBatch();
            });
        }
    }

    patchCompanyProfilesListAfterImport(meta: {
        mappingsToCreate: Array<{
            owner__company_fk_id: number;
            profile_fk_id: number;
            company_group_fk_id: number | null;
            pod_uri: string;
        }>;
        mappingsToUpdate: Array<{
            yodata_pod_mapping_id: number;
            pod_uri: string;
        }>;
        updatedMappings: Array<{
            yodata_pod_mapping_id: number;
            pod_uri: string;
        }>;
        createdMappings: Array<{
            id: number;
            yodata_pod_mapping_id: number;
            owner__company_fk_id: number;
            profile_fk_id: number | null;
            company_group_fk_id: number | null;
            pod_uri: string;
            type: string;
        }>;

        mappingsViewModeUIreport: string[];
        mappingsEditModeUIreport: string[];
    }) {
        try {
            // if at least 1 mapping was created or updated - update UI
            if (meta.createdMappings.length || meta.updatedMappings.length) {
                if (!this.dataSource.data.length) {
                    return;
                }
                this.dataSource.data = this.dataSource.data.map((profile) => {
                    const createdMapping = meta.createdMappings.find((m) => profile && m.profile_fk_id === profile.id);
                    const updatedMapping = meta.updatedMappings.find(
                        (m) =>
                            profile &&
                            profile.yodata_mapping &&
                            m.yodata_pod_mapping_id === profile.yodata_mapping.yodata_pod_mapping_id
                    );
                    if (!createdMapping && !updatedMapping) {
                        return profile;
                    }

                    if (updatedMapping) {
                        return {
                            ...profile,
                            yodata_mapping: {
                                ...profile.yodata_mapping,
                                pod_uri: updatedMapping.pod_uri
                            }
                        };
                    } else if (createdMapping) {
                        return {
                            ...profile,
                            yodata_mapping: createdMapping
                        };
                    } else {
                        return profile;
                    }
                });
            }
        } catch (err) {
            console.info('Failed to update yodata company profiles list in UI', err);
            // todo: run request?
        }
    }

    openCreateContactDialog(data: {
        type: string;
        entity: Object;
        formValues: Object;
        isCreateNewInYodata?: boolean;
        divisions: any[];
    }) {
        const dialogRef = this.dialog.open(YodataCreateContactDialogComponent, {
            minWidth: 640,
            data: data
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((contactData: boolean | {zipiProfileId: number; yodataContactData: any}) => {
                if (!contactData || typeof contactData === 'boolean') {
                    return;
                }
                console.info('contactData ==>', contactData);
                return this.marketplaceApi.createYodataContactAction(contactData).then((res) => {
                    this.dataSource.data = this.dataSource.data.map((profile) => {
                        if (profile.id === contactData.zipiProfileId) {
                            return {...profile, yodata_mapping: res.createdMapping};
                        }
                        return profile;
                    });
                });
            });
    }

    editEntity(
        el: {
            first_name: string;
            last_name: string;
            title: string;
            yodata_mapping: any;
        },
        type: string
    ) {
        // console.info('editEntity', el);
        const dialogRef = this.dialog.open(YodataEditEntityDialogComponent, {
            minWidth: 320,
            data: {
                type: type,
                entity: el
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((data: {type: string; entity: Object; formValues: Object; isCreateNewInYodata?: boolean}) => {
                if (!data) {
                    return;
                }
                if (data.isCreateNewInYodata) {
                    this.openCreateContactDialog({...data, divisions: this.divisions.data});
                } else {
                    this.patchEntity(data);
                }
            });
    }

    patchEntity(data: {type: string; entity: any; formValues: any}) {
        if (!data || !data.entity || !data.formValues) {
            return;
        }
        if (data.type === 'profile') {
            if (!data.entity.yodata_mapping) {
                this.marketplaceApi
                    .createYodataMapping({
                        type: 'profile',
                        pod_uri: data.formValues.agent_office_pod,
                        profile_fk_id: data.entity.id,
                        company_group_fk_id: null
                    })
                    .then((createdMapping) => {
                        this.dataSource.data = this.dataSource.data.map((el) => {
                            // if (el.profile_id === data.entity.profile_id) {
                            if (el.id === data.entity.id) {
                                return {...el, yodata_mapping: createdMapping};
                            }
                            return el;
                        });
                    });
            } else {
                this.marketplaceApi
                    .updateYodataMapping(data.entity.yodata_mapping.yodata_pod_mapping_id, {
                        type: 'profile',
                        pod_uri: data.formValues.agent_office_pod,
                        profile_fk_id: data.entity.id,
                        company_group_fk_id: null
                    })
                    .then((wasUpdated) => {
                        if (wasUpdated) {
                            this.dataSource.data = this.dataSource.data.map((el) => {
                                // if (el.profile_id === data.entity.profile_id) {
                                if (el.id === data.entity.id) {
                                    return {
                                        ...el,
                                        yodata_mapping: {
                                            ...el.yodata_mapping,
                                            pod_uri: data.formValues.agent_office_pod
                                        }
                                    };
                                }
                                return el;
                            });
                        }
                    });
            }
        } else if (data.type === 'division') {
            if (!data.entity.yodata_mapping) {
                this.marketplaceApi
                    .createYodataMapping({
                        type: 'company_group',
                        pod_uri: data.formValues.agent_office_pod,
                        profile_fk_id: null,
                        company_group_fk_id: data.entity.id
                    })
                    .then((createdMapping) => {
                        this.dataSource.data = this.dataSource.data.map((el) => {
                            if (el.id === data.entity.id) {
                                return {...el, yodata_mapping: createdMapping};
                            }
                            return el;
                        });
                    });
            } else {
                this.marketplaceApi
                    .updateYodataMapping(data.entity.yodata_mapping.yodata_pod_mapping_id, {
                        type: 'company_group',
                        pod_uri: data.formValues.agent_office_pod,
                        profile_fk_id: null,
                        company_group_fk_id: data.entity.id
                    })
                    .then((wasUpdated) => {
                        if (wasUpdated) {
                            this.dataSource.data = this.dataSource.data.map((el) => {
                                if (el.id === data.entity.id) {
                                    return {
                                        ...el,
                                        yodata_mapping: {
                                            ...el.yodata_mapping,
                                            pod_uri: data.formValues.agent_office_pod
                                        }
                                    };
                                }
                                return el;
                            });
                        }
                    });
            }
        }
    }

    nextBatch() {
        this.isLoading = true;

        // GET PROFILES LIST
        this.marketplaceApi
            .getProfilesListForYodata(`?limit=${this.scrollData.limit}&offset=${this.scrollData.offset}`)
            .then((res) => {
                // yodata_pod_mapping
                // console.info(res, 'getProfilesListForYodata');
                this.dataSource.data = res.data;
                this.scrollData.total = res._meta.total;
                this.isLoading = false;
            })
            .catch(() => {
                this.isLoading = false;
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
