<div mat-dialog-title>
    <h3>Products</h3>
</div>

<div *ngIf="contact">
    <span *ngIf="contact.product_mapping.length === 0">Contact has no Matching Products</span>
    <div *ngFor="let map of contact.product_mapping">
        <div class="zp-items row no-gutters">
            <div class="col-5">
                <mat-form-field class="w-100">
                    <input
                        matInput
                        placeholder="Contact Product Name"
                        [disabled]="true"
                        [value]="getProductName(map.contact_company__product_fk_id)"
                    />
                </mat-form-field>
            </div>

            <div class="col col-md-5">
                <mat-form-field class="w-100">
                    <mat-select [(ngModel)]="map.matched__product_fk_id" placeholder="Product">
                        <mat-option (click)="$event.stopPropagation(); createProduct()">Create New Product</mat-option>
                        <ng-container *ngFor="let product of product$ | async">
                            <mat-option
                                *ngIf="product.status === 'active' || map.matched__product_fk_id === product.product_id"
                                [value]="product.product_id"
                            >
                                {{ product.name }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-2">
                <button
                    mat-button
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="removeProductFkId(map.matched__product_fk_id)"
                >
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>

<mat-dialog-actions class="d-flex mb-2 justify-content-end">
    <button *ngIf="!data.hideCancel" mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">Cancel</button>
    <button mat-raised-button color="primary" (click)="continue()" tabindex="1">Save</button>
</mat-dialog-actions>
