import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {
    IContact,
    IDealDeposit,
    IDealDepositRelease,
    IDealDepositRequest,
    IPayment,
    IPaymentMade
} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';
import {NotificationsServiceZipi} from '../../../../../../../notifications/notifications.service';
import {DealFinancialApiService} from '../../../../deal-financial.api.service';
import {CreateReleaseDialogComponent} from '../create-release-dialog/create-release-dialog.component';
import {PaymentsMadeService} from '../../../../../../../finance/services/payments-made.service';
import {PaymentsReceivedService} from '../../../../../../../finance/services/payments-received.service';
import {GenericFormGroup} from '../../../../../../../../entites/generic.entity';
import {FinancialTransferEntity} from '../../../../../../../account-info/compensation/models/financial-transfer.entity';
import {DealProcessingService} from '../../../../deal-processing.service';
import {CheckPrintConfirmDialogComponent} from '../../../../../../../shared/components/check-print-confirm-dialog/check-print-confirm.dialog';
import {PAYMENT_CREDITS_STATUS_COLOR} from '../../../../../../../../local-typings';
import {IPaymentReceivedMadeRequestObject} from '@cyberco-nodejs/zipi-typings/index';

@Component({
    selector: 'app-deposit-release-list',
    templateUrl: './deposit-release-list.component.html',
    styleUrls: ['./deposit-release-list.component.scss']
})
export class DepositReleaseListComponent implements OnInit, OnChanges, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() requestsList: IDealDepositRequest[] = [];
    @Input() paymentsList: IPayment[] = [];
    @Input() releaseList: IDealDepositRelease[] = [];
    @Input() dealId: number | null = null;
    @Input() dealAddress: string | null = null;
    @Input() dealName: string | null = null;
    @Input() dealDepositAmounts: IDealDeposit | null = null;
    @Input() disabled: boolean = false;
    @Output() refreshReleases = new EventEmitter();

    dataSourceRelease: MatTableDataSource<any>;
    displayedReleaseColumns: string[] = [
        'release_date',
        'receiver',
        'amount',
        'applied_to',
        'payment_status',
        'payment_number',
        'action'
    ];

    statusColor = PAYMENT_CREDITS_STATUS_COLOR;
    paymentMade: IPaymentMade | null = null;

    blobUrl: any = null;

    constructor(
        public dialog: MatDialog,
        private notificationServiceZipi: NotificationsServiceZipi,
        private dealFinancialApiService: DealFinancialApiService,
        public dealProcessingService: DealProcessingService,
        private paymentsMadeService: PaymentsMadeService,
        private paymentsReceivedService: PaymentsReceivedService
    ) {
        this.dataSourceRelease = new MatTableDataSource<any>([]);
    }

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('releaseList')) {
            this.dataSourceRelease.data = changes.releaseList.currentValue;
        }
    }

    addDepositRelease() {
        if (!this.dealId) {
            this.notificationServiceZipi.addError(`To add Deposit Payment save Deal firstly.`);
            return;
        }

        const dealContacts = this._selectDealContacts();
        const escrowPayouts = this.dealProcessingService.dealFG.getRawValue().escrow_payouts;
        const remainingPayouts = this.dealProcessingService.dealFG.getRawValue().remaining_payouts;
        const dealPayouts = escrowPayouts.concat(remainingPayouts);
        const dialogRef = this.dialog.open(CreateReleaseDialogComponent, {
            minWidth: '600px',
            maxHeight: '80vh',
            data: {
                requestsList: this.requestsList,
                releaseList: this.releaseList,
                dealAddress: this.dealAddress,
                dealContacts: dealContacts,
                dealPayouts: dealPayouts
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((data) => {
                data.releaseData['deal_fk_id'] = this.dealId;
                this.dealFinancialApiService
                    .createRelease(data)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((response) => {
                        this.refreshReleases.emit(true);
                        // data.paymentData.deposit_release_id = response.release.deposit_release_id;
                        // this.afterReleaseCreate(data, response);
                    });
            });
    }

    async afterReleaseCreate(
        data: {releaseMethod: string; paymentData: Partial<IPaymentReceivedMadeRequestObject>},
        response: any
    ) {
        switch (data.releaseMethod) {
            case 'remaining_invoice': {
                await this.paymentsReceivedService.createPaymentReceived(data.paymentData).toPromise();
                break;
            }
            case 'remaining_bill': {
                await this.paymentsMadeService.createPaymentMade(data.paymentData).toPromise();
                break;
            }
            case 'escrow_receiver_not_company': {
                await this.dealFinancialApiService
                    .createPaymentMadeForRelease(response.release.deposit_release_id, data.paymentData)
                    .toPromise();
                break;
            }
            case 'escrow_receiver_company': {
                await this.dealFinancialApiService
                    .createJournalForRelease(response.release.deposit_release_id, data.paymentData)
                    .toPromise();
                break;
            }
            case 'refund': {
                await this.dealFinancialApiService
                    .createPaymentMadeForRelease(response.release.deposit_release_id, data.paymentData)
                    .toPromise();
                break;
            }
        }

        if (response.transfer) {
            this.dealProcessingService.addFinancialTransfer(
                new GenericFormGroup(FinancialTransferEntity.FABRIC(FinancialTransferEntity).hydrate(response.transfer))
            );
        }

        this.refreshReleases.emit(true);
    }

    retryPayment(paymentMade: IPaymentMade) {
        if (paymentMade.summary_status === 'declined') {
            this.paymentsMadeService
                .retryPaymentMade(paymentMade?.payment_made_id!)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response) {
                        this.refreshReleases.emit(true);
                    }
                });
        }
    }

    openConfirm(release: IDealDepositRelease) {
        this.dealFinancialApiService
            .deleteDealDepositRelease(release.deposit_release_id!)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                if (response) {
                    this.refreshReleases.emit(true);
                }
            });
    }

    unlockReleasePayment(paymentMade: IPaymentMade) {
        this.paymentsMadeService
            .unlockPaymentMade(paymentMade.payment_made_id!)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                if (response) {
                    this.refreshReleases.emit(true);
                }
            });
    }

    _selectDealContacts() {
        // Now for list of contacts we need to select only Broker, Buyer, Seller, and Escrow.
        // But we want to keep code for selecting other contacts for some time.
        const uniqueContacts: {[index: number]: IContact | null} = {};

        const participants = this.dealProcessingService.dealFG.getRawValue().deal_participants;
        if (participants.buyers && participants.buyers.length > 0) {
            for (const buyer of participants.buyers) {
                if (buyer.contact_id) {
                    if (uniqueContacts.hasOwnProperty(buyer.contact_id)) {
                        if (!uniqueContacts[buyer.contact_id] && buyer.contact) {
                            uniqueContacts[buyer.contact_id] = buyer.contact;
                        }
                    } else {
                        uniqueContacts[buyer.contact_id] = buyer.contact ? buyer.contact : null;
                    }
                }
            }
        }

        if (participants.sellers && participants.sellers.length > 0) {
            for (const seller of participants.sellers) {
                if (seller.contact_id) {
                    if (uniqueContacts.hasOwnProperty(seller.contact_id)) {
                        if (!uniqueContacts[seller.contact_id] && seller.contact) {
                            uniqueContacts[seller.contact_id] = seller.contact;
                        }
                    } else {
                        uniqueContacts[seller.contact_id] = seller.contact ? seller.contact : null;
                    }
                }
            }
        }

        if (participants.escrow_agent && participants.escrow_agent.contact_id) {
            if (uniqueContacts.hasOwnProperty(participants.escrow_agent.contact_id)) {
                if (!uniqueContacts[participants.escrow_agent.contact_id] && participants.escrow_agent.contact) {
                    uniqueContacts[participants.escrow_agent.contact_id] = participants.escrow_agent.contact;
                }
            } else {
                uniqueContacts[participants.escrow_agent.contact_id] = participants.escrow_agent.contact
                    ? participants.escrow_agent.contact
                    : null;
            }
        }

        // const salesEntities = this.dealProcessingService.dealFG.getRawValue().sales_entities;
        // if (participants.buyer_agents && participants.buyer_agents.length > 0) {
        //     for (const buyerAgent of participants.buyer_agents) {
        //         if (buyerAgent.contact_id) {
        //             if (uniqueContacts.hasOwnProperty(buyerAgent.contact_id)) {
        //                 if (!uniqueContacts[buyerAgent.contact_id] && buyerAgent.contact) {
        //                     uniqueContacts[buyerAgent.contact_id] = buyerAgent.contact;
        //                 }
        //             } else {
        //                 uniqueContacts[buyerAgent.contact_id] = buyerAgent.contact ? buyerAgent.contact : null;
        //             }
        //         }
        //     }
        // }

        // if (participants.seller_agents && participants.seller_agents.length > 0) {
        //     for (const sellerAgent of participants.seller_agents) {
        //         if (sellerAgent.contact_id) {
        //             if (uniqueContacts.hasOwnProperty(sellerAgent.contact_id)) {
        //                 if (!uniqueContacts[sellerAgent.contact_id] && sellerAgent.contact) {
        //                     uniqueContacts[sellerAgent.contact_id] = sellerAgent.contact;
        //                 }
        //             } else {
        //                 uniqueContacts[sellerAgent.contact_id] = sellerAgent.contact ? sellerAgent.contact : null;
        //             }
        //         }
        //     }
        // }

        // if (participants.others && participants.others.length > 0) {
        //     for (const other of participants.others) {
        //         if (other.contact_id) {
        //             if (uniqueContacts.hasOwnProperty(other.contact_id)) {
        //                 if (!uniqueContacts[other.contact_id] && other.contact) {
        //                     uniqueContacts[other.contact_id] = other.contact;
        //                 }
        //             } else {
        //                 uniqueContacts[other.contact_id] = other.contact ? other.contact : null;
        //             }
        //         }
        //     }
        // }
        // if (participants.commission_payer && participants.commission_payer.contact_id) {
        //     if (uniqueContacts.hasOwnProperty(participants.commission_payer.contact_id)) {
        //         if (!uniqueContacts[participants.commission_payer.contact_id] && participants.commission_payer.contact) {
        //             uniqueContacts[participants.commission_payer.contact_id] = participants.commission_payer.contact;
        //         }
        //     } else {
        //         uniqueContacts[participants.commission_payer.contact_id] = participants.commission_payer.contact ? participants.commission_payer.contact : null;
        //     }
        // }

        // if (participants.referring_from_agent && participants.referring_from_agent.contact_id) {
        //     if (uniqueContacts.hasOwnProperty(participants.referring_from_agent.contact_id)) {
        //         if (!uniqueContacts[participants.referring_from_agent.contact_id] && participants.referring_from_agent.contact) {
        //             uniqueContacts[participants.referring_from_agent.contact_id] = participants.referring_from_agent.contact;
        //         }
        //     } else {
        //         uniqueContacts[participants.referring_from_agent.contact_id] = participants.referring_from_agent.contact ? participants.referring_from_agent.contact : null;
        //     }
        // }
        // if (participants.referring_to_agent && participants.referring_to_agent.contact_id) {
        //     if (uniqueContacts.hasOwnProperty(participants.referring_to_agent.contact_id)) {
        //         if (!uniqueContacts[participants.referring_to_agent.contact_id] && participants.referring_to_agent.contact) {
        //             uniqueContacts[participants.referring_to_agent.contact_id] = participants.referring_to_agent.contact;
        //         }
        //     } else {
        //         uniqueContacts[participants.referring_to_agent.contact_id] = participants.referring_to_agent.contact ? participants.referring_to_agent.contact : null;
        //     }
        // }
        // if (salesEntities && salesEntities.length > 0) {
        //     for (const salesEntity of salesEntities) {
        //         if (salesEntity.contact_part_link && salesEntity.contact_part_link.contact_id) {
        //             if (uniqueContacts.hasOwnProperty(salesEntity.contact_part_link.contact_id)) {
        //                 if (!uniqueContacts[salesEntity.contact_part_link.contact_id] && salesEntity.contact) {
        //                     uniqueContacts[salesEntity.contact_part_link.contact_id] = salesEntity.contact;
        //                 }
        //             } else {
        //                 uniqueContacts[salesEntity.contact_part_link.contact_id] = salesEntity.contact ? salesEntity.contact : null;
        //             }
        //         }
        //     }
        // }

        const dealContacts = {
            dealContactsIds: Object.keys(uniqueContacts),
            brokerContactId: null,
            commissionPayerContactId: participants.commission_payer ? participants.commission_payer.contact_id : null
        };

        return dealContacts;
    }

    print() {
        const iframe = document.createElement('iframe');
        iframe.id = 'print-frame';
        iframe.style.display = 'none';
        iframe.src = URL.createObjectURL(this.blobUrl);
        iframe.onload = () => {
            setTimeout(() => {
                iframe.contentWindow!.print();
            }, 0);
        };
        if (this.paymentMade!.check_info) {
            this.printConfirm();
        } else {
            this.refreshReleases.emit(true);
        }

        document.body.appendChild(iframe);
    }

    initBlobUrl(cb: any) {
        cb = cb.bind(this);
        this.paymentsMadeService
            .downloadCheckPdf(this.paymentMade!.payment_made_id!, {
                check_margins: true,
                show_background: false
            })
            .pipe(
                map((res) => new Blob([new Uint8Array(res.data)], {type: 'application/pdf'})),
                tap((result) => {
                    this.blobUrl = result;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => cb());
    }

    printPaymentPdf(paymentMade: IPaymentMade) {
        this.paymentMade = paymentMade;
        return this.initBlobUrl(this.print);
    }

    printConfirm() {
        const dialogRef = this.dialog.open(CheckPrintConfirmDialogComponent, {
            minWidth: 320,
            data: {
                payment_made_id: this.paymentMade!.payment_made_id,
                check_number: this.paymentMade!.check_info.check_number
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.refreshReleases.emit(true);
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.refreshReleases.complete();
        this.dataSourceRelease.disconnect();
    }
}
