import {Component, OnInit} from '@angular/core';
import {FetchProducts} from '../../../finance/store/finance.actions';
import {select, Store} from '@ngrx/store';
import {selectProducts} from '../../../finance/store/finance.selectors';
import {IFinanceState} from '../../../finance/store/finance.reducer';
import {Observable} from 'rxjs';
import {IProduct} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-company-compensation',
    template: `
        <div style="margin-bottom: 128px">
            <mat-tab-group>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <h3>Profiles</h3>
                    </ng-template>
                    <app-tab-1-profiles></app-tab-1-profiles>
                </mat-tab>
            </mat-tab-group>
        </div>
    `
})
export class IndexComponent implements OnInit {
    product$: Observable<IProduct[]> | undefined;

    constructor(private store: Store<IFinanceState>) {}

    ngOnInit() {
        this.store.dispatch(new FetchProducts());
        this.product$ = this.store.pipe(select(selectProducts));
    }
}
