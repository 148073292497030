import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSidenav} from '@angular/material/sidenav';
import {Subject} from 'rxjs';
import {TransactionsService} from 'app/services/api/finance/transactions.service';
import {ILedgerAccount, ITransactionListItem} from '@cyberco-nodejs/zipi-typings';
import {takeUntil} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {IScrollData} from 'app/models/scroll-data';
import {PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

@Component({
    selector: 'app-ledger-account-transactions',
    templateUrl: 'ledger-account-transactions.component.html',
    styleUrls: ['ledger-account-transactions.component.scss', '../../../../../../assets/infinite-scroll-table.scss']
})
export class LedgerAccountTransactionsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('sidebarTransactions', {static: true}) sidenavJournal: MatSidenav | undefined;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;

    @Output() onToggle = new EventEmitter<boolean>();
    @Input() isOpened: boolean = false;

    ledgerAccount: ILedgerAccount | null = null;

    scrollData: IScrollData = {
        basis: 'accrual',
        offset: 0,
        limit: 50,
        sort_column: 'journal_date',
        sort_direction: 'desc',
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;
    displayedColumns = ['journal_date', 'description', 'debit_or_credit', 'debit_amount', 'credit_amount'];

    dataSource: MatTableDataSource<ITransactionListItem>;

    constructor(
        private transactionsService: TransactionsService,
        public dialog: MatDialog
    ) {
        this.dataSource = new MatTableDataSource<ITransactionListItem>([]);
    }

    ngOnInit() {
        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.nextBatch();
            });
        }
    }

    close() {
        this.sidenavJournal!.close();
        this.ledgerAccount = null;
        this.dataSource.data = [];
        this.paginator!.pageIndex = 0;
        this.scrollData.offset = 0;
        this.scrollData.total = 0;
    }

    open(ledgerAccount: ILedgerAccount) {
        if (ledgerAccount) {
            this.ledgerAccount = ledgerAccount;

            this.nextBatch();
        }
    }

    nextBatch() {
        if (this.ledgerAccount && this.ledgerAccount.ledger_account_id) {
            this.transactionsService
                .getPageTransactionsByLedgerAccountId(this.ledgerAccount.ledger_account_id, this.scrollData)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((data) => {
                    this.dataSource.data = data.transactions;
                    this.scrollData.total = data.count;

                    if (this.sidenavJournal && !this.sidenavJournal.opened) {
                        this.sidenavJournal.open();
                    }
                });
        }
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        this.dataSource.data = [];
        this.paginator!.pageIndex = 0;
        this.scrollData.offset = 0;
        this.nextBatch();
    }

    changeBasis($event: MatSlideToggleChange) {
        if ($event.checked) {
            this.scrollData.basis = 'cash';
        } else {
            this.scrollData.basis = 'accrual';
        }
        this.dataSource.data = [];
        this.paginator!.pageIndex = 0;
        this.scrollData.offset = 0;
        this.nextBatch();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.onToggle.complete();
    }
}
