import {Component, OnInit, Input} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {unitedStates} from '@skyslope/utils';
import {countryList, statesAndTerritories} from '@app/utilities';

@Component({
    selector: 'app-contact-location-spaced',
    templateUrl: 'contact-location-spaced.component.html',
    styleUrls: ['contact-location-spaced.component.css']
})
export class ContactLocationSpacedComponent implements OnInit {
    @Input() locationCtrl: UntypedFormGroup | null = null;
    @Input() removeLocation: (() => void) | undefined;
    @Input() isEditingMode = true;
    @Input() isAbleToEdit = true;

    private unsubscribe: Subject<void> = new Subject<void>();
    showErrors = false;

    errMatcher = {
        isErrorState: (control: UntypedFormControl) => this.showErrors && control.hasError('required')
    };

    editLocation() {
        this.isEditingMode = true;
    }

    constructor() {}

    ngOnInit() {
        if (this.locationCtrl) {
            this.locationCtrl
                .get('street_number')
                ?.valueChanges.pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    this.combineStreetNumberAndAddress();
                });
        }
    }

    combineStreetNumberAndAddress() {
        if (this.locationCtrl) {
            const streetNumber = this.locationCtrl.get('street_number')?.value || '';
            const streetAddress = this.locationCtrl.get('street_address')?.value || '';
            const combinedStreet = `${streetNumber} ${streetAddress}`;
            this.locationCtrl.get('full_street')?.setValue(combinedStreet);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    protected readonly statesAndTerritories = statesAndTerritories;
    protected readonly countryList = countryList;
}
