<div class="mat-tab-background">
    <p *ngIf="dataSource && dataSource.data.length === 0" class="text-muted text-center p-3">
        There are no reconciliations done for this account yet.
    </p>

    <table
        mat-table
        [dataSource]="dataSource"
        [ngClass]="{'d-none': dataSource.data.length === 0}"
        class="mat-elevation-z8 transactions-table w-100"
    >
        <ng-container matColumnDef="reconciled_date">
            <th mat-header-cell *matHeaderCellDef>Reconciled Date</th>
            <td mat-cell *matCellDef="let element">
                {{ element.reconciled_date | dateFromNumber | date: 'mediumDate' }}
            </td>
        </ng-container>
        <ng-container matColumnDef="period">
            <th mat-header-cell *matHeaderCellDef>Period</th>
            <td mat-cell *matCellDef="let element">
                {{ element.start_date | dateFromNumber | date: 'mediumDate' }} &ndash;
                {{ element.end_date | dateFromNumber | date: 'mediumDate' }}
            </td>
        </ng-container>
        <ng-container matColumnDef="closing_balance">
            <th mat-header-cell *matHeaderCellDef style="text-align: right; padding-right: 50px">Closing Balance</th>
            <td mat-cell *matCellDef="let element" style="text-align: right; padding-right: 50px">
                <span
                    *ngIf="
                        element.closing_balance !== 0 &&
                        ledgerAccount.normal_balance_type !== element.closing_balance_debit_or_credit
                    "
                    >-</span
                >{{ element.closing_balance | currency }}
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">{{ element.status | fromSnakeCase }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button
                    mat-icon-button
                    [rule]="{banking__manage_reconciliation: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    [matMenuTriggerFor]="menu"
                    (click)="$event.stopPropagation()"
                    *ngIf="element.is_last_reconcile"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button
                        mat-menu-item
                        *ngIf="element.status === 'reconciled'"
                        (click)="$event.stopPropagation(); undoReconciliation(element.reconciliation_id)"
                    >
                        Undo Reconciliation
                    </button>
                    <button
                        mat-menu-item
                        (click)="$event.stopPropagation(); deleteReconciliation(element.reconciliation_id)"
                    >
                        Delete
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="navigateToRow(row)"
            class="banking-row"
        ></tr>
    </table>
    <mat-paginator
        class="mat-paginator-sticky"
        [ngClass]="{'d-none': dataSource.data.length === 0}"
        [length]="listLength"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        showFirstLastButtons="false"
    ></mat-paginator>
</div>
