<div
    class="popover-container"
    [ngClass]="'popover-theme-' + theme"
    [id]="'popover' + elementId"
    [ngStyle]="
        isVisible
            ? {
                  display: 'flex',
                  bottom: positionOptions.bottom + 'px',
                  left: positionOptions.left + 'px',
                  width: width + 'px'
              }
            : {display: 'none'}
    "
>
    <div class="actions-area">
        <ng-content></ng-content>
    </div>
</div>
<div
    class="popover-arrow"
    [ngClass]="'popover-arrow-theme-' + theme + '-' + position"
    [ngStyle]="
        isVisible
            ? {display: 'flex', bottom: arrowPositionOptions.bottom + 'px', left: arrowPositionOptions.left + 'px'}
            : {display: 'none'}
    "
></div>
