<!-------- Header -------->
<nav class="middle-menu ml-4 mt-4 mb-3 d-flex" *ngIf="deal">
    <app-ui-mod-deal-badges-in-list
        *ngIf="!isUiModsPending && deal.id"
        [dealId]="deal.id"
    ></app-ui-mod-deal-badges-in-list>

    <app-ui-mod-deal-badges-on-page
        *ngIf="!isUiModsPending && deal.id"
        [dealId]="deal.id"
    ></app-ui-mod-deal-badges-on-page>

    <!--<ul class="menu-items d-flex">
        <li class="mr-4">
            <button class="menu-item" type="button">
                Request Net Sheet
            </button>
        </li>
        <li class="mr-4">
            <button class="menu-item" type="button">
                View/Approve Trade Report
            </button>
        </li>
    </ul>-->
</nav>

<div class="row mx-3" *ngIf="deal">
    <!---------------- Left column ---------------->
    <div class="col-6 mt-2">
        <!-------- Deal Information -------->
        <div class="sub-column">
            <mat-card class="mat-card-hover-solid">
                <h3 class="font-weight-normal">Deal Information</h3>
            </mat-card>

            <div class="pt-4 px-3 pb-2 mb-2 items-fields-hover-solid">
                <hr />
                <div class="mb-3">
                    <div class="primary-text mb-2">Expiration Date:</div>
                    <ng-container *ngIf="deal.expiration_date; else noExpirationDate">
                        <div>{{ deal.expiration_date | date: 'mediumDate' }}</div>
                    </ng-container>
                    <ng-template #noExpirationDate>
                        <div class="comment-text">(no value)</div>
                    </ng-template>
                </div>

                <div class="mb-3">
                    <div class="primary-text mb-2">Deal Type:</div>
                    <div *ngIf="deal.type">{{ DEAL.type_LABELS[deal.type] }}</div>
                </div>

                <div class="mb-3">
                    <div class="primary-text mb-2">Source of Business:</div>
                    <ng-container *ngIf="deal.source_of_business; else noSourceOfBusiness">
                        <div>{{ deal.source_of_business }}</div>
                    </ng-container>
                    <ng-template #noSourceOfBusiness>
                        <div class="comment-text">(no value)</div>
                    </ng-template>
                </div>

                <div class="mb-3">
                    <div class="primary-text mb-2">Property class:</div>
                    <div class="text-capitalize">{{ deal.property_class }}</div>
                </div>
            </div>
        </div>

        <!-------- Closing Information -------->
        <div class="sub-column">
            <mat-card class="mat-card-hover-solid">
                <h3 class="font-weight-normal">Closing Information</h3>
            </mat-card>

            <div class="pt-4 px-3 pb-2 mb-2 items-fields-hover-solid">
                <hr />
                <div class="mb-3">
                    <div class="primary-text mb-2">Closing Number:</div>
                    <ng-container *ngIf="deal.escrow_number; else noEscrowNumber">
                        <div>{{ deal.escrow_number }}</div>
                    </ng-container>
                    <ng-template #noEscrowNumber>
                        <div class="comment-text">(no value)</div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>

    <!---------------- Right column ---------------->
    <div class="col-6 mt-2">
        <!-------- Financials -------->
        <div class="sub-column">
            <mat-card class="mat-card-hover-solid">
                <h3 class="font-weight-normal">Financials</h3>
            </mat-card>

            <div class="pt-4 px-3 pb-2 mb-2 items-fields-hover-solid">
                <hr />
                <div class="mb-3">
                    <div class="primary-text mb-2">Sales Price:</div>
                    <div>{{ deal.sales_price | currency }}</div>
                </div>

                <div class="mb-3">
                    <div class="primary-text mb-2">Commission:</div>
                    <ng-container *ngIf="deal.income_commission_value; else noCommission">
                        <div>{{ deal.income_commission_value }}%</div>
                    </ng-container>
                    <ng-template #noCommission>
                        <div class="comment-text">(no value)</div>
                    </ng-template>
                </div>

                <div class="mb-3">
                    <div class="primary-text mb-2">Flat commission:</div>
                    <ng-container *ngIf="deal.income_flat_commission_value; else noFlatCommission">
                        <div>{{ deal.income_flat_commission_value | currency }}</div>
                    </ng-container>
                    <ng-template #noFlatCommission>
                        <div class="comment-text">(no value)</div>
                    </ng-template>
                </div>

                <div class="mb-3">
                    <div class="primary-text mb-2">Total commission:</div>
                    <div>{{ deal.company_calculation.commission.amount | currency }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
