import {of as observableOf} from 'rxjs';
import {catchError, tap, switchMap, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
    CreateReportsCategory,
    CreateReport,
    DeleteReportsCategory,
    DeleteReport,
    FetchRecommendedReportsPresetsListSuccess,
    FetchReportsCategoriesList,
    FetchReportsCategoriesListSuccess,
    FetchReportList,
    FetchReportListSuccess,
    ReportsActionType,
    ToggleEditReportSidebarStatus,
    FetchReportsPreviewData,
    FetchReportsPreviewDataSuccess,
    UpdateReport,
    FetchRecommendedReportsPresetsList
} from './reports.action';
import {Router} from '@angular/router';
import {NotificationsService} from 'angular2-notifications';
import {ReportsService} from '../services/reports.service';
import {ReportCategoriesService} from '../services/report-categories.service';
import {ReportsLocalService} from '../services/reports-local.service';

@Injectable()
export class ReportsEffectsService {
    constructor(
        protected actions$: Actions,
        private reportsService: ReportsService,
        private reportsLocalService: ReportsLocalService,
        private reportsCategoriesService: ReportCategoriesService,
        protected store: Store<any>,
        private router: Router,
        private notificationsService: NotificationsService
    ) {}

    @Effect()
    FetchReportsList$ = this.actions$.pipe(
        ofType<FetchReportList>(ReportsActionType.FETCH_REPORT_LIST),
        switchMap((action) => {
            const queryObj = action.payload! && action.payload!.query ? action.payload!.query : {};
            const isAppendNewData = action.payload! && action.payload!.isAppend ? action.payload!.isAppend : false;
            return this.reportsService.getReports(queryObj).pipe(
                map(
                    (response) =>
                        new FetchReportListSuccess({
                            data: response.result,
                            isAppend: isAppendNewData,
                            isLoaded: true
                        })
                ),
                catchError((err) => observableOf(null))
            );
        })
    );

    @Effect({dispatch: false})
    FetchRecommendedReportsPresetsList$ = this.actions$.pipe(
        ofType<FetchRecommendedReportsPresetsList>(ReportsActionType.FETCH_RECOMMENDED_REPORTS_PRESETS_LIST),
        switchMap(() => {
            return this.reportsService.getRecommendedReportsPresetsList().pipe(
                map((response) => new FetchRecommendedReportsPresetsListSuccess(response.result)),
                catchError((err) => observableOf(null))
            );
        })
    );

    @Effect({dispatch: false})
    CreateReport$ = this.actions$.pipe(
        ofType<CreateReport>(ReportsActionType.CREATE_REPORT),
        switchMap((action) => {
            return this.reportsService.createReport(action.payload!).pipe(
                catchError((err) =>
                    observableOf({
                        isError: true
                    })
                )
            );
        }),
        tap((response) => {
            if (response && !response.isError) {
                this.notificationsService.success(`Report preset successfully created`);
                this.store.dispatch(new ToggleEditReportSidebarStatus({isOpened: false}));

                if (response.result && response.result.id) {
                    this.router.navigate(['reports/preview', response.result.id]);
                }
            }
        })
    );

    @Effect()
    FetchReportsCategoriesList$ = this.actions$.pipe(
        ofType<FetchReportsCategoriesList>(ReportsActionType.FETCH_REPORTS_CATEGORIES_LIST),
        switchMap(() => {
            return this.reportsCategoriesService.getReportsCategoriesList().pipe(
                map((data) => new FetchReportsCategoriesListSuccess(data.result)),
                catchError((err) => observableOf(null))
            );
        })
    );
    // filter(result => !!result),
    // tap((data: {result: IReportCategory[]}) => {
    //     this.store.dispatch(new FetchReportsCategoriesListSuccess(data.result));
    // }));

    @Effect({dispatch: false})
    DeleteReportsPreset$ = this.actions$.pipe(
        ofType<DeleteReport>(ReportsActionType.DELETE_REPORT),
        switchMap((action) => {
            return this.reportsService.deleteReportPreset(action.payload!.id).pipe(
                map(() => {
                    this.store.dispatch(new ToggleEditReportSidebarStatus({isOpened: false}));

                    const options = action.payload ? action.payload.options : null;
                    if (options.redirectTo) {
                        this.router.navigate(options.redirectTo);
                    }
                    if (options.isFetchReports) {
                        this.store.dispatch(new FetchReportList({query: {include: 'report_category'}}));
                    }
                }),
                catchError((err) => observableOf(null))
            );
        }),
        tap((success) => {
            this.notificationsService.success(`Report preset successfully deleted`);
        })
    );

    @Effect({dispatch: false})
    UpdateReport$ = this.actions$.pipe(
        ofType<UpdateReport>(ReportsActionType.UPDATE_REPORT),
        switchMap((action) => {
            const {request} = action.payload!;
            return this.reportsService.updateReport(request.id, request).pipe(
                map((response) => ({
                    response: response,
                    payload: action.payload!
                })),
                catchError((err) => observableOf(null))
            );
        }),
        tap((data) => {
            const options = data && data.payload ? data.payload.options : null;
            this.notificationsService.success(`Report preset successfully updated`);
            this.store.dispatch(new ToggleEditReportSidebarStatus({isOpened: false}));
            if (options) {
                if (options.isFetchCategory) {
                    this.store.dispatch(new FetchReportsCategoriesList());
                }
                if (options.isFetchReports) {
                    this.store.dispatch(new FetchReportList({query: {include: 'report_category'}}));
                }
                if (options.redirectTo) {
                    this.router.navigate(options.redirectTo);
                }
                if (options.isFetchReportsPreview && options.fetchReportPreviewId) {
                    this.store.dispatch(new FetchReportsPreviewData({id: options.fetchReportPreviewId}));
                }
            }
        })
    );

    @Effect({dispatch: false})
    CreateReportsCategory$ = this.actions$.pipe(
        ofType<CreateReportsCategory>(ReportsActionType.CREATE_REPORTS_CATEGORY),
        switchMap((action) => {
            return this.reportsCategoriesService
                .createReportsCategory(action.payload!)
                .pipe(catchError((err) => observableOf(null)));
        }),
        tap((success) => {
            this.notificationsService.success(`Category successfully created`);
        })
    );

    @Effect({dispatch: false})
    DeleteReportsCategory$ = this.actions$.pipe(
        ofType<DeleteReportsCategory>(ReportsActionType.DELETE_REPORTS_CATEGORY),
        switchMap((action) => {
            return this.reportsCategoriesService.deleteReportsCategory(action.payload!).pipe(
                map(() => {
                    this.store.dispatch(new FetchReportList({query: {include: 'report_category'}}));
                    this.store.dispatch(new FetchReportsCategoriesList());
                }),
                catchError((err) => observableOf(null))
            );
        }),
        tap((data) => {
            this.notificationsService.success(`Category successfully deleted`);
        })
    );

    @Effect({dispatch: false})
    FetchReportsPreviewData$ = this.actions$.pipe(
        ofType<FetchReportsPreviewData>(ReportsActionType.FETCH_REPORT_PREVIEW_DATA),
        switchMap((action) => {
            const queryParams = this.reportsLocalService.getReportPreviewQueryParams() || {};
            if (action.payload! && action.payload!.id) {
                return this.reportsService.getReportDataById(action.payload!.id, queryParams).pipe(
                    map((data) => this.store.dispatch(new FetchReportsPreviewDataSuccess(data.result))),
                    catchError((err) => observableOf(null))
                );
            } else {
                const reportData = this.reportsLocalService.getReportData();
                return this.reportsService.getReportDataByParams(reportData, queryParams).pipe(
                    map((data) => this.store.dispatch(new FetchReportsPreviewDataSuccess(data.result))),
                    catchError((err) => observableOf(null))
                );
            }
        })
    );
    // tap((response) => {
    //     this.store.dispatch(new FetchReportsPreviewDataSuccess(response));
    // }));
}
