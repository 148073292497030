<div class="row content">
    <div class="col-xl-4 left-side">
        <mat-button-toggle-group vertical="true" [value]="activePanel" (change)="onSelectOptions($event)">
            <mat-button-toggle
                *ngFor="let item of reportOptions"
                [value]="item.value"
                (change)="onSelectOptions($event)"
            >
                {{ item.title }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="col-xl-8 right-side">
        <ng-container *ngIf="reportSettings">
            <div *ngIf="activePanel === 'time_period'">
                <app-report-edit-bar-time-period
                    [value]="{
                        time_period: reportSettings.time_period,
                        time_period_start: reportSettings.time_period_start,
                        time_period_end: reportSettings.time_period_end
                    }"
                    (valueChanges)="onValueChanges('time_period', $event)"
                >
                </app-report-edit-bar-time-period>
            </div>

            <div *ngIf="activePanel === 'report_tagging'" class="content-item">
                <h4>Select tags</h4>
                <app-report-autocomplete
                    [list]="allTags"
                    [value]="reportSettings.tags"
                    (valueChanges)="onValueChanges('tags', $event)"
                    titleAttribute="title"
                    valueAttribute="tag_id"
                >
                </app-report-autocomplete>
            </div>

            <div *ngIf="activePanel === 'ledger_accounts'" class="content-item">
                <h4>Select ledger account</h4>
                <app-report-dropdown
                    [list]="listOfLedgerAccounts"
                    [value]="reportSettings.ledger_account_id"
                    (valueChanges)="onValueChanges('ledger_account_id', $event)"
                    titleAttribute="name"
                    valueAttribute="ledger_account_id"
                >
                </app-report-dropdown>
            </div>

            <div *ngIf="activePanel === 'display_individual_balance'">
                <app-report-edit-bar-button-list
                    [list]="listOfYesNoAnswer"
                    [value]="reportSettings.is_display_individual_balance"
                    (valueChanges)="onValueChanges('is_display_individual_balance', $event)"
                >
                </app-report-edit-bar-button-list>
            </div>

            <div *ngIf="activePanel === 'include_zero_balance'">
                <app-report-edit-bar-button-list
                    [list]="listOfYesNoAnswer"
                    [value]="reportSettings.is_include_zero_balance"
                    (valueChanges)="onValueChanges('is_include_zero_balance', $event)"
                >
                </app-report-edit-bar-button-list>
            </div>

            <div
                class="input-section"
                [formGroup]="advancedFormGroup"
                *ngIf="activePanel === 'trust_reconciliation_advanced' && advancedFormGroup"
            >
                <mat-checkbox formControlName="is_override_bank_balance"> Manually include bank balance </mat-checkbox>

                <mat-form-field class="w-100">
                    <input matInput placeholder="Statement Balance" formControlName="statement_bank_balance" required />
                </mat-form-field>
                <mat-form-field class="w-100">
                    <input matInput placeholder="Deposits in Transit" formControlName="deposit_bank_balance" required />
                </mat-form-field>
                <mat-form-field class="w-100">
                    <input
                        matInput
                        placeholder="Outstanding Payments"
                        formControlName="outstanding_bank_balance"
                        required
                    />
                </mat-form-field>
                <app-date-picker
                    *ngIf="advancedFormGroup"
                    class="spaced-at-right w-100"
                    [disabled]="isDisabledStatementBankDate"
                    [placeholder]="'Statement date'"
                    [dateControl]="advancedFormGroup.get('statement_bank_date')"
                    [valueType]="dateValueType"
                ></app-date-picker>
            </div>
        </ng-container>

        <div *ngIf="report">
            <app-report-edit-bar-action [reportId]="report.id" [isShowNext]="isShowNext" (action)="onAction($event)">
            </app-report-edit-bar-action>
        </div>
    </div>
</div>
