import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {Group} from '../../../../models/group';

@Component({
    selector: 'app-groups-settings-dialog',
    styles: [
        `
            .row.footer {
                justify-content: space-between;
            }

            mat-list {
                max-height: 400px;
                width: 100%;
                overflow-x: hidden;
            }
        `
    ],
    template: `
        <div class="col" style="justify-content: space-between; flex-direction: column; display: flex">
            <h3>Group Settings</h3>
            <br />
            <mat-form-field mat-icon-button class="flex-4">
                <input
                    matInput
                    type="text"
                    required
                    placeholder="Title"
                    name="name"
                    *ngIf="groupFG.controls.title"
                    [formControl]="groupFG.controls.title"
                />
            </mat-form-field>
            <div style="display: flex; flex-direction: column">
                <mat-slide-toggle
                    style="max-width: fit-content"
                    *ngIf="
                        groupFG.controls.permissions &&
                        groupFG.controls.permissions.controls &&
                        groupFG.controls.permissions.controls.use_in_compensation_as_group_of_entities
                    "
                    [formControl]="groupFG.controls.permissions.controls.use_in_compensation_as_group_of_entities"
                >
                    <div class="d-flex">
                        Enable 'User Finance Group'
                        <mat-icon
                            style="width: 30px; height: 30px; color: #9E9E9E;"
                            class="ml-2"
                            matTooltip="Activate this if you would like to make the members of this group available in the Finance module."
                        >
                            group
                        </mat-icon>
                    </div>
                </mat-slide-toggle>
            </div>
            <br />
            <div
                *ngIf="
                    false && groupFG.controls!.permissions!.controls!.use_in_compensation_as_group_of_entities!.value
                "
            >
                <app-date-picker
                    [placeholder]="'Override User Anniversary Date'"
                    [dateControl]="groupFG.controls.anniversary_date"
                >
                </app-date-picker>
            </div>
            <div class="row footer mx-3" style="justify-content: flex-end;">
                <button mat-raised-button class="mat-default" (click)="dialogRef.close(null)">Cancel</button>
                <button mat-raised-button class="mat-primary" (click)="save()">Save</button>
            </div>
        </div>
    `
})
export class SettingsDialogComponent {
    public groupFG: GenericFormGroup<Group> = new GenericFormGroup(new Group(), 'change');

    constructor(public dialogRef: MatDialogRef<SettingsDialogComponent>) {}

    save() {
        this.groupFG.controls.title!.setValue(this.groupFG.controls.title?.value.trim());
        this.dialogRef.close(this.groupFG.getRawValue());
    }
}
