import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {of, Subject} from 'rxjs';
import {PaymentsMadeService} from '../../../finance/services/payments-made.service';
import {catchError, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-check-print-confirm-dialog',
    styles: [
        `
            .row.footer {
                justify-content: flex-end;
            }

            mat-list {
                max-height: 500px;
                width: 100%;
                overflow-x: hidden;
            }
        `
    ],
    templateUrl: 'check-print-confirm.dialog.html'
})
export class CheckPrintConfirmDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    paymentMadeId: number | undefined;
    formGroup: UntypedFormGroup = this.fb.group({});
    checkNumberControl = this.fb.control(null, [Validators.required]);
    isCorrectPrintControl = this.fb.control(true, [Validators.required]);
    saveDisabled: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<CheckPrintConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder,
        private paymentsMadeService: PaymentsMadeService
    ) {}

    ngOnInit() {
        this.paymentMadeId = this.data.payment_made_id;
        this.checkNumberControl.patchValue(this.data.check_number);
    }

    save() {
        if (!this.saveDisabled) {
            this.saveDisabled = true;

            this.paymentsMadeService
                .setCheckPrinted(this.paymentMadeId as number, {
                    check_number:
                        (this.checkNumberControl.value && this.checkNumberControl.value.length) > 0
                            ? this.checkNumberControl.value
                            : null,
                    is_printed: this.isCorrectPrintControl.value
                })
                .pipe(
                    catchError(() => {
                        this.saveDisabled = false;
                        return of(null);
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe(() => this.dialogRef.close(true));
        }
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
