<div class="content">
    <div mat-dialog-title>
        <h3>{{ formGroup.controls.isEdit.value ? 'Modify' : 'Create' }} 1099 Tax Record</h3>
        <div class="heading">
            {{ formGroup.controls.isEdit.value ? 'Modify the' : 'Create a' }} tax record to be used for 1099-NEC,
            1099-INT, and 1099-MISC forms.
        </div>
    </div>

    <mat-dialog-content [formGroup]="formGroup">
        <div class="align-items-md-center p-2">
            <mat-form-field class="col-12">
                <input matInput formControlName="name" type="text" placeholder="Name of Recipient" required />
            </mat-form-field>

            <div class="row" style="padding: 0 15px">
                <div class="col">
                    <app-date-picker [placeholder]="'Start Date'" [dateControl]="formGroup.controls.start_date">
                    </app-date-picker>
                </div>

                <div class="col">
                    <mat-form-field class="w-100">
                        <mat-select placeholder="Tin Type" formControlName="tin_type" required>
                            <mat-option *ngFor="let item of tinTypeList" [value]="item.value">
                                {{ item.title }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.controls.tin_type.errors"></mat-error>
                    </mat-form-field>
                </div>

                <div class="col">
                    <div class="d-inline-flex w-100">
                        <mat-form-field *ngIf="!formGroup.controls.tin_edit_mode.value">
                            <input matInput required type="text" formControlName="existed_tin" />
                            <mat-placeholder>Tax SSN/EIN</mat-placeholder>
                            <button mat-button matSuffix mat-icon-button aria-label="Edit" (click)="editTin()">
                                <mat-icon>create</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-form-field class="w-100" *ngIf="formGroup.controls.tin_edit_mode.value">
                            <input
                                matInput
                                required
                                type="text"
                                formControlName="tin"
                                autocomplete="off"
                                [maskito]="activeTinTypeMask"
                            />
                            <mat-error *ngIf="formGroup.controls.tin.errors"> Invalid format </mat-error>
                            <mat-placeholder>Tax SSN/EIN</mat-placeholder>
                            <button
                                mat-button
                                *ngIf="formGroup.controls.existed_tin.value"
                                matSuffix
                                mat-icon-button
                                aria-label="Clear"
                                (click)="editTin()"
                            >
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="zp-place-picker">
                    <app-gplaces-picker [locationCtrl]="locationPickerCtrl" [focused]="true"></app-gplaces-picker>
                </div>
                <app-contact-location-spaced
                    [locationCtrl]="formGroup"
                    [isEditingMode]="true"
                ></app-contact-location-spaced>
            </div>
        </div>
    </mat-dialog-content>
</div>

<mat-divider></mat-divider>

<div class="footer">
    <mat-dialog-actions>
        <div class="text-right w-100">
            <button mat-raised-button class="ml-2" (click)="dialogRef.close(false)" tabindex="-1">Cancel</button>
            <button
                mat-raised-button
                class="ml-1"
                mat-raised-button
                color="primary"
                (click)="submitForm()"
                tabindex="1"
            >
                <span *ngIf="!formGroup.controls.contact_payroll_id.value">Add</span>
                <span *ngIf="formGroup.controls.contact_payroll_id.value">Save</span>
            </button>
        </div>
    </mat-dialog-actions>
</div>
