import {Component, Input} from '@angular/core';
import {FocusAreaFormGroup, GoalFormGroup} from './model';
import {Group} from '../../../models/group';
import {GenericFormGroup} from '../../../entites/generic.entity';

@Component({
    selector: 'app-goal-create',
    styles: [
        `
            mat-card-header {
                display: block;
                text-align: center;
                font-size: 20px;
                margin: 5%;
                color: #676161;
            }

            button {
                margin: 20px auto;
            }

            .prefix {
                font-size: 12px;
                margin-right: 4px;
                font-weight: 300;
            }

            button {
                height: 40px;
                margin: 4px;
            }

            mat-form-field {
                width: 100%;
            }
        `
    ],
    template: `
        <div style="display: flex; justify-content: flex-start; width:100%">
            <button [disabled]="focus_area.disabled" mat-button [matMenuTriggerFor]="addGoalMenu" *ngIf="focus_area">
                + Add new goal
            </button>
            <mat-menu #addGoalMenu="matMenu">
                <button mat-menu-item (click)="addProgressGoal('attain')">Attain</button>
                <button mat-menu-item (click)="addGeneralGoal()">General</button>
                <button mat-menu-item (click)="addProgressGoal()">Progress</button>
            </mat-menu>
        </div>
    `
})
export class GoalCreateComponent {
    @Input() focus_area: FocusAreaFormGroup | GenericFormGroup<Group> | undefined;
    @Input() index: number | null = null;

    addProgressGoal(dynamic: string = 'increase') {
        const goalFormGroup = new GoalFormGroup();
        goalFormGroup.patchValue({
            dynamic: dynamic,
            type: 'progress'
        });

        if (typeof this.focus_area !== 'undefined') {
            this.focus_area.controls.enumerable?.push(goalFormGroup);
        }
    }

    addGeneralGoal(dynamic: string = '') {
        const goalFormGroup = new GoalFormGroup();
        goalFormGroup.patchValue({type: 'general'});
        if (typeof this.focus_area !== 'undefined') {
            this.focus_area.controls.enumerable?.push(goalFormGroup);
        }
    }
}
