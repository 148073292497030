import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {firstValueFrom, Subject} from 'rxjs';
import {IContact, IMoneySenderCustomisation, IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {GroupApi} from '../../../../../services/api/group.api';
import {debounceTime, filter, map, takeUntil, tap} from 'rxjs/operators';
import {RecurringInvoicesService} from '../../../../../services/api/finance/recurring-invoices.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AddCreditCardDialogComponent} from '../../../../../layouts/cards-dialogs/add-credit-card-dialog/add-credit-card-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ShipperContactsService} from '../../../../../services/api/shipper.contacts.service';
import {MoneySendersCustomisationService} from '../../../services/money-senders-customisation.service';
import {Sort} from '@angular/material/sort';
import {ContactClassService} from '../../../../../services/api/contact-class.service';
import {IScrollData} from '../../../../../models/scroll-data';
import {InvoicePublicService} from '../../../services/invoice-public.service';
import {ZipiFinancialIframeDialogComponent} from '../../../../account-info/company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-financial-iframe-dialog/zipi-financial-iframe-dialog.component';
import {IZipiFinancialIframeDialogData} from '../../../../../typings/zipi-financial-iframe';
import {MarketplaceSource} from '@app/modules/account-info/marketplace/marketplace.source';
import {CompanyGatewayService} from '@app/services/api/finance/company-gateway.service';

const MIN_SYMBOLS_TO_START_SEARCH = 2;

@Component({
    selector: 'app-money-senders-details',
    templateUrl: './money-senders-details.component.html',
    styleUrls: ['./money-senders-details.component.scss']
})
export class MoneySendersDetailsComponent implements OnInit, OnChanges, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() showGroupDetails: boolean | undefined;
    @Input() contactId: number | null = null;
    @Input() contactClassId: number | null = null;
    @Input() groupId: number | null = null;
    @Input() recurringInvoiceId: number | null = null;
    @Input() invoiceId: number | null = null;
    @Input() recurringInvoiceForm: UntypedFormGroup | undefined;
    @Input() companyGateways: IPaymentGateway[] = [];

    @ViewChild('section') section: ElementRef | undefined;

    members: IContact[] = [];
    currentContactName: UntypedFormControl = this.fb.control(null, []);
    currentClassContactName: UntypedFormControl = this.fb.control(null, []);
    currentContact: IContact | null = null;

    customisationForm: UntypedFormGroup = this.fb.group({
        money_sender_customisation_id: [null, []],
        money_sender__company_fk_id: [null, []],
        money_sender__contact_fk_id: [null, [Validators.required]],
        money_sender__profile_fk_id: [null, []],

        recurring_invoice_fk_id: [null, []],
        invoice_fk_id: [null, []],

        billing_address__contact_location_fk_id: [null, []],
        shipping_address__contact_location_fk_id: [null, []],

        to_be_paid__payment_method_fk_id: [null, []]
    });

    dataSource: MatTableDataSource<any>;

    displayedColumns: string[] = ['customer_name', 'billing', 'shipping', 'method', 'actions'];

    totalCustomisations: number | null = null;
    pageIndex: number = 0;
    pageSize: number = 50;
    sort_column: string = 'customer_name';
    sort_direction: 'asc' | 'desc' | '' = 'asc';

    isFetching: boolean = false;
    isEditMode: boolean;

    isAbleToPayByPayloadCoCard: boolean = false;
    isAbleToPayByPayloadCoBank: boolean = false;
    isAbleToPayByAuthorizeCard: boolean = false;

    loading: boolean;

    constructor(
        private groupApi: GroupApi,
        private recurringInvoicesService: RecurringInvoicesService,
        private moneySendersCustomisationService: MoneySendersCustomisationService,
        public dialog: MatDialog,
        private fb: UntypedFormBuilder,
        private contactsService: ShipperContactsService,
        private contactClassService: ContactClassService,
        private invoicePublicService: InvoicePublicService,
        protected marketplaceSource: MarketplaceSource,
        private companyGatewayService: CompanyGatewayService
    ) {
        this.dataSource = new MatTableDataSource<any>([]);
        this.isEditMode = false;
        this.loading = false;
    }

    ngOnInit() {
        this.currentContactName.valueChanges.pipe(debounceTime(600), takeUntil(this.unsubscribe)).subscribe((data) => {
            if (data && data.length >= MIN_SYMBOLS_TO_START_SEARCH) {
                this.searchMembers(data);
            }
        });
        this.currentClassContactName.valueChanges
            .pipe(debounceTime(600), takeUntil(this.unsubscribe))
            .subscribe((data) => {
                if (data && data.length >= MIN_SYMBOLS_TO_START_SEARCH) {
                    this.searchClassMembers(data);
                }
            });

        if (
            this.recurringInvoiceForm &&
            this.recurringInvoiceForm.controls.pay_to_card__payment_method_fk_id &&
            this.recurringInvoiceForm.controls.pay_to_card__payment_method_fk_id.value
        ) {
            this.invoicePublicService
                .getInvoiceMethods([this.recurringInvoiceForm.controls.pay_to_card__payment_method_fk_id.value])
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((methods) => {
                    this.isAbleToPayByPayloadCoCard = methods.some(
                        (invoiceMethod) =>
                            invoiceMethod.payment_gateway?.type === 'zipi_financial_business' &&
                            invoiceMethod.payment_gateway?.driver_type === 'payload'
                    );
                    this.isAbleToPayByAuthorizeCard = methods.some(
                        (invoiceMethod) =>
                            invoiceMethod.payment_gateway &&
                            invoiceMethod.payment_gateway.type === 'authorize_net_merchant'
                    );
                });
        }
        if (
            this.recurringInvoiceForm &&
            this.recurringInvoiceForm.controls.pay_to_bank__payment_method_fk_id &&
            this.recurringInvoiceForm.controls.pay_to_bank__payment_method_fk_id.value
        ) {
            this.invoicePublicService
                .getInvoiceMethods([this.recurringInvoiceForm.controls.pay_to_bank__payment_method_fk_id.value])
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((methods) => {
                    this.isAbleToPayByPayloadCoBank = methods.some(
                        (invoiceMethod) =>
                            invoiceMethod.payment_gateway?.type === 'zipi_financial_business' &&
                            invoiceMethod.payment_gateway?.driver_type === 'payload'
                    );
                });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('groupId') && changes.groupId.currentValue) {
            this.searchMembers('');
        }
        if (changes.hasOwnProperty('contactId') && changes.contactId.currentValue) {
            if (this.contactId) {
                this.getContact(this.contactId);
            }
        }
        if (changes.hasOwnProperty('contactClassId') && changes.contactClassId.currentValue) {
            this.searchClassMembers('');
        }

        if (changes.hasOwnProperty('showGroupDetails') && !changes.showGroupDetails.currentValue) {
            this.currentContact = null;
            this.initForm();
        }

        if (changes.hasOwnProperty('showGroupDetails') && changes.showGroupDetails.currentValue) {
            setTimeout(() => this.initFetch(), 400);
        }
    }

    initFetch() {
        if (this.recurringInvoiceId) {
            this.pageIndex = 0;
            this.totalCustomisations = null;

            this.initSendersCustomisationsTable();
            this.addScrollListener();
        }
    }

    onSortChange(sort: Sort) {
        this.sort_direction = sort.direction;
        this.sort_column = sort.active;
        this.initFetch();
        return;
    }

    addScrollListener() {
        if (this.section) {
            this.section.nativeElement.removeAllListeners();
            this.section.nativeElement.addEventListener('scroll', (event: any) => {
                // note: do "-10" correction to care about "almost on bottom" case
                if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 10) {
                    this.nextPageFetch();
                }
            });
        }
    }

    nextPageFetch() {
        if (this.recurringInvoiceId && this.groupId) {
            if (this.isFetching) {
                return;
            }

            this.pageIndex = this.pageIndex + 1;
            this.initSendersCustomisationsTable();
        }
    }

    initSendersCustomisationsTable() {
        if (this.isFetching) {
            return;
        }

        this.isFetching = true;
        this.loading = true;

        const filterQuery: Partial<IScrollData> = {
            offset: Number(this.pageSize * this.pageIndex),
            limit: Number(this.pageSize),
            sort_column: this.sort_column,
            sort_direction: this.sort_direction
        };

        if (this.recurringInvoiceId) {
            if (this.groupId && !this.contactId && !this.contactClassId) {
                this.groupApi
                    .findGroupMembersByQuery(this.groupId, this.recurringInvoiceId, filterQuery)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((response) => {
                        const result = response.result;
                        this.setResults(result);
                    });
            } else if (!this.groupId && this.contactId && !this.contactClassId) {
                this.moneySendersCustomisationService
                    .getMoneySenderWithCustomisationByContactId(this.contactId, this.recurringInvoiceId, filterQuery)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((result) => {
                        this.setResults(result);
                    });
            } else if (!this.groupId && !this.contactId && this.contactClassId) {
                this.moneySendersCustomisationService
                    .getMoneySenderWithCustomisationByContactClassId(
                        this.contactClassId,
                        this.recurringInvoiceId,
                        filterQuery
                    )
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((result) => {
                        this.setResults(result);
                    });
            }
        } else if (this.invoiceId) {
            this.moneySendersCustomisationService
                .getMoneySendersCustomisationsByInvoiceId(this.invoiceId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    this.loading = false;
                    this.dataSource.data = result;
                });
        }
    }

    setResults(result: IContact[]) {
        this.isFetching = false;
        this.loading = false;
        if (this.pageIndex === 0) {
            this.dataSource.data = result;
        } else {
            this.dataSource.data = this.dataSource.data.concat(result);
        }

        if (this.section) {
            const parent = this.section.nativeElement;

            window.setTimeout(() => {
                if (result.length >= this.pageSize && parent.offsetHeight === parent.scrollHeight) {
                    this.nextPageFetch();
                }
            }, 500);
            if (result.length < this.pageSize) {
                parent.removeAllListeners();
                this.totalCustomisations = this.dataSource.data.length;
            }
        }
    }

    initForm() {
        this.customisationForm = this.fb.group({
            money_sender_customisation_id: [null, []],
            money_sender__company_fk_id: [null, []],
            money_sender__contact_fk_id: [null, [Validators.required]],
            money_sender__profile_fk_id: [null, []],

            recurring_invoice_fk_id: [null, []],
            invoice_fk_id: [null, []],

            billing_address__contact_location_fk_id: [null, []],
            shipping_address__contact_location_fk_id: [null, []],

            to_be_paid__payment_method_fk_id: [null, []]
        });
    }

    initNewForm() {
        this.customisationForm = this.fb.group({
            money_sender_customisation_id: [null, []],
            money_sender__company_fk_id: [this.currentContact ? this.currentContact.partner__company_fk_id : null, []],
            money_sender__contact_fk_id: [
                this.currentContact ? this.currentContact.contact_id : null,
                [Validators.required]
            ],
            money_sender__profile_fk_id: [this.currentContact ? this.currentContact.related__profile_fk_id : null, []],

            recurring_invoice_fk_id: [this.recurringInvoiceId ? this.recurringInvoiceId : null, []],
            invoice_fk_id: [this.invoiceId ? this.invoiceId : null, []],

            billing_address__contact_location_fk_id: [null, []],
            shipping_address__contact_location_fk_id: [null, []],

            to_be_paid__payment_method_fk_id: [null, []]
        });
    }

    initUpdateForm(existingCustomisation: IMoneySenderCustomisation) {
        this.customisationForm = this.fb.group({
            money_sender_customisation_id: [existingCustomisation.money_sender_customisation_id, []],
            money_sender__company_fk_id: [existingCustomisation.money_sender__company_fk_id, []],
            money_sender__contact_fk_id: [existingCustomisation.money_sender__contact_fk_id, [Validators.required]],
            money_sender__profile_fk_id: [existingCustomisation.money_sender__profile_fk_id, []],

            recurring_invoice_fk_id: [existingCustomisation.recurring_invoice_fk_id, []],
            invoice_fk_id: [existingCustomisation.invoice_fk_id, []],

            billing_address__contact_location_fk_id: [
                existingCustomisation.billing_address__contact_location_fk_id,
                []
            ],
            shipping_address__contact_location_fk_id: [
                existingCustomisation.shipping_address__contact_location_fk_id,
                []
            ],

            to_be_paid__payment_method_fk_id: [existingCustomisation.to_be_paid__payment_method_fk_id, []]
        });
    }

    getCustomisation(contactId: number) {
        this.loading = true;
        if (this.recurringInvoiceId) {
            this.moneySendersCustomisationService
                .getMoneySenderCustomisationByContactIdForRecurring(this.recurringInvoiceId, contactId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    this.loading = false;
                    if (result) {
                        if (result.contact) {
                            this.currentContact = result.contact;
                        }
                        this.initUpdateForm(result);
                    } else {
                        this.initNewForm();
                    }
                });
        } else if (this.invoiceId) {
            this.moneySendersCustomisationService
                .getMoneySenderCustomisationByContactIdForInvoice(this.invoiceId, contactId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    this.loading = false;
                    if (result) {
                        this.initUpdateForm(result);
                    } else {
                        this.initNewForm();
                    }
                });
        }
    }

    async addCard(type: 'contact_card' | 'contact_bank') {
        if (this.currentContact && this.currentContact.contact_id) {
            if (this.isAbleToPayByAuthorizeCard) {
                const dialogRef = this.dialog.open(AddCreditCardDialogComponent, {
                    minWidth: '320px',
                    maxHeight: '80vh',
                    data: {
                        moneySenderProfileId: this.currentContact.related__profile_fk_id,
                        invoice: null,
                        contactId: this.currentContact.contact_id
                    }
                });

                dialogRef
                    .afterClosed()
                    .pipe(
                        filter((pn) => !!pn),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe();
            } else if (this.isAbleToPayByPayloadCoCard || this.isAbleToPayByPayloadCoBank) {
                let customerRef = null;
                const addons = await firstValueFrom(this.marketplaceSource.addonsChangeEvent);
                if (!addons) {
                    console.error('Cannot find addons');
                    return;
                }
                const zipiFinancialInstance = addons.filter(
                    (instance) => instance.addon && instance.addon.slug === 'zipi_financial_integration'
                )[0];
                if (!zipiFinancialInstance) {
                    console.error('Cannot find addon instance');
                    return;
                }
                customerRef = zipiFinancialInstance.settings?.external_customer_ref;
                if (!customerRef) {
                    console.error('Cannot find reference to Customer.');
                    return;
                }

                let mainPerson = null;
                if (
                    this.currentContact &&
                    this.currentContact.contact_persons &&
                    this.currentContact.contact_persons.length > 0
                ) {
                    mainPerson = this.currentContact.contact_persons.find((person) => person.type === 'main_person');
                }
                const contactData = {
                    company_name: this.currentContact?.company_name ? this.currentContact?.company_name : '',
                    email: mainPerson && mainPerson.email ? mainPerson.email : '',
                    first_name: mainPerson && mainPerson.first_name ? mainPerson.first_name : '',
                    last_name: mainPerson && mainPerson.last_name ? mainPerson.last_name : ''
                };

                const gateway = await firstValueFrom(
                    this.companyGatewayService.getContactZipiFinancialReceiveOnlyGateway(
                        this.currentContact.contact_id as number,
                        'payload',
                        'all'
                    )
                );

                const dialogRef = this.dialog.open<ZipiFinancialIframeDialogComponent, IZipiFinancialIframeDialogData>(
                    ZipiFinancialIframeDialogComponent,
                    {
                        disableClose: true,
                        maxHeight: '80vh',
                        width: '650px',
                        panelClass: 'custom-dialog-container',
                        data: {
                            contactId: this.currentContact.contact_id,
                            gateway: gateway,
                            driverType: 'payload',
                            paymentMethod: null,
                            methodType: type,
                            accessMethod: 'internal',
                            isUniversal: true,
                            customerId: customerRef,
                            prefillData: contactData,
                            creationSource: 'current_company',
                            storeAccountMethod: 'required'
                        }
                    }
                );

                dialogRef
                    .afterClosed()
                    .pipe(
                        filter((pn) => !!pn),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe((result) => {
                        if (this.currentContact && this.currentContact.contact_id) {
                            this.contactsService
                                .showContact(this.currentContact.contact_id)
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((responre) => {
                                    this.currentContact = responre;
                                });
                        }
                    });
            }
        }
    }

    selectContact($event: any, contact: IContact) {
        if ($event.isUserInput) {
            this.currentContact = contact;
            if (this.currentContact && this.currentContact.contact_id) {
                this.getCustomisation(this.currentContact.contact_id);
            }
        }
    }

    searchMembers(value: string) {
        this.loading = true;
        if (this.groupId) {
            this.groupApi
                .findGroupMembersByName(this.groupId, value)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    this.members = response.result;
                    this.loading = false;
                });
        }
    }

    searchClassMembers(value: string) {
        this.loading = true;
        if (this.contactClassId) {
            this.contactClassService
                .findClassMembersByName(this.contactClassId, value)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    this.members = response;
                    this.loading = false;
                });
        }
    }

    getContact(contactId: number) {
        this.loading = true;
        this.contactsService
            .showContact(contactId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((responre) => {
                this.loading = false;
                this.members = [responre];
            });
    }

    closeCustomisation() {
        this.currentContact = null;
        this.initForm();
    }

    saveCustomisation() {
        if (this.customisationForm.invalid) {
            this.customisationForm.markAllAsTouched();
            return;
        }

        const custObj = this.customisationForm.getRawValue();

        this.loading = true;

        if (custObj.money_sender_customisation_id) {
            this.moneySendersCustomisationService
                .updateMoneySenderCustomisation(custObj)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (result) {
                        this.loading = false;
                        this.currentContact = null;
                        this.initForm();
                        this.initSendersCustomisationsTable();
                    }
                });
        } else {
            this.moneySendersCustomisationService
                .createMoneySenderCustomisation(custObj)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (result) {
                        this.loading = false;
                        this.currentContact = null;
                        this.initForm();
                        this.initSendersCustomisationsTable();
                    }
                });
        }
    }

    deleteCustomisation(customisationId: number) {
        this.loading = true;
        this.moneySendersCustomisationService
            .deleteMoneySenderCustomisation(customisationId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.loading = false;
                    this.currentContact = null;
                    this.initForm();
                    this.initSendersCustomisationsTable();
                }
            });
    }

    getCurrentMethodLabel() {
        const selectedMethodId = this.customisationForm?.controls.to_be_paid__payment_method_fk_id?.value;
        if (selectedMethodId && this.currentContact && this.currentContact.contact_payment_gateways) {
            for (const gateway of this.currentContact.contact_payment_gateways) {
                if (gateway.payment_methods && gateway.payment_methods.length > 0) {
                    for (const method of gateway.payment_methods) {
                        if (method.payment_method_id === selectedMethodId) {
                            return method.title;
                        }
                    }
                }
            }
        }
        return '';
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
