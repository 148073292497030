import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Deal} from '../../../../models/deal';
import {GenericFormArray, GenericFormGroup} from '../../../../entites/generic.entity';
import {OpeningPerformanceValuesEntity} from '../../../../entites/opening-performance-values.entity';
import {MultipleTargetsDealsQueryModel} from '../../../deals/components/deal/common/deal.models';
import {CalculationSettingsService as NewCalculationSettingsService} from '@app/services/new-calculation-settings.service';
import {FeatureFlagsService} from '@app/modules/feature-flags/feature-flags.service';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-contact-edit-compliance-opening-performance-values',
    template: `
        <div class="row w-100 compliance-item">
            <div class="col">
                <strong>Commission Calculation Modifiers</strong>
            </div>
        </div>

        <button
            type="button"
            (click)="doAdd()"
            mat-button
            matTooltip="Add opening performance value"
            style="margin-bottom: 10px;"
        >
            <mat-icon class="mr-1">add</mat-icon>
            Modification
        </button>

        <button
            type="button"
            *ngIf="isNewSettingsEnabled"
            (click)="doAddMetricModifier()"
            mat-button
            matTooltip="Add metric opening performance value"
            style="margin-bottom: 10px;"
        >
            <mat-icon class="mr-1">add</mat-icon>
            Metric Modification
        </button>

        <div *ngFor="let item of openingPerformanceValuesFA.controls; index as ii" class="zp-pd-15">
            <app-contact-edit-compliance-opening-performance-values-item
                *ngIf="item.controls.type.value !== OPENING_PERFORMANCE_VALUES_ENTITY.TYPE_set.metric_value_modifier"
                (delete)="doDelete(ii)"
                [openingValuesFG]="item"
            >
            </app-contact-edit-compliance-opening-performance-values-item>

            <app-opening-performance-values-metric-item
                *ngIf="
                    (metricsLoaded$ | async) &&
                    item.controls.type.value === OPENING_PERFORMANCE_VALUES_ENTITY.TYPE_set.metric_value_modifier
                "
                (delete)="doDelete(ii)"
                [openingValuesFG]="item"
            >
            </app-opening-performance-values-metric-item>
        </div>
    `,
    styleUrls: ['../contact-edit.component.css']
})
export class OpeningPerformanceValuesComponent implements OnDestroy, OnInit {
    private unsubscribe: Subject<void> = new Subject();
    isNewSettingsEnabled = false;
    OPENING_PERFORMANCE_VALUES_ENTITY = OpeningPerformanceValuesEntity;
    metricsLoaded$: Observable<boolean> = new Observable<boolean>();

    @Input() openingPerformanceValuesFA: GenericFormArray<OpeningPerformanceValuesEntity> = <
        GenericFormArray<OpeningPerformanceValuesEntity>
    >new GenericFormArray([]);
    DEAL = Deal;

    constructor(
        protected newCalculationSettingsService: NewCalculationSettingsService,
        protected featureFlagsService: FeatureFlagsService
    ) {
        this.metricsLoaded$ = this.newCalculationSettingsService.metricsInitiated.asObservable();
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.isNewSettingsEnabled = this.featureFlagsService.isFeatureEnabled('rules:new_calculation_settings');
            });
    }

    doDelete(ii: number) {
        this.openingPerformanceValuesFA.removeAt(ii);
    }

    doAdd() {
        this.openingPerformanceValuesFA.push(
            new GenericFormGroup(
                new OpeningPerformanceValuesEntity().setParameters(
                    new MultipleTargetsDealsQueryModel().setStatus(Deal.status_SET.closed)
                )
            )
        );
    }

    doAddMetricModifier() {
        this.openingPerformanceValuesFA.push(
            new GenericFormGroup(
                new OpeningPerformanceValuesEntity().setType(
                    OpeningPerformanceValuesEntity.TYPE_set.metric_value_modifier
                )
            )
        );
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngOnInit(): void {}
}
