import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../service.requester';
import {Observable} from 'rxjs';
import {GatewayTypes, IPaymentGateway} from '@cyberco-nodejs/zipi-typings';

@Injectable({
    providedIn: 'root'
})
export class CompanyGatewayService {
    gatewayUrl = '/finance/gateway';

    constructor(public requester: ServiceRequester) {}

    getCompanyGatewayByType(type: string): Observable<IPaymentGateway> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/${type}`, 'GET', 'shipp');
    }

    getCompanyGatewaysByTypes(
        allowedGatewayTypes: Array<string>,
        contactId: number | null
    ): Observable<IPaymentGateway[]> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/by/types`, 'GET', 'shipp', {
            types: allowedGatewayTypes,
            pay_to_contact: contactId
        });
    }

    getCompanyOverrides(companyId: string, scenarios: Array<string>): Observable<any> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/company/${companyId}/overrides`, 'GET', 'shipp', {
            scenarios: scenarios
        });
    }

    getCompanyDwollaGateways(): Observable<IPaymentGateway[]> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/dwolla/all`, 'GET', 'shipp');
    }

    getCompanyZipiFinancialGateways(): Observable<IPaymentGateway[]> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/zipi-fin/all`, 'GET', 'shipp');
    }

    getMuhneeAccountsForConnection(
        gatewayId: number
    ): Observable<{accounts: Array<any>; connected_accounts_ids: Array<string>; banks_manage_link: string}> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/muhnee/${gatewayId}/accounts-for-connection`,
            'GET',
            'shipp'
        );
    }

    getZipiFinancialGatewayFee(
        gatewayId: number,
        feeScenarios: Array<string>
    ): Observable<{[key: string]: {incoming: any; outgoing: any}}> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/zipi-financial/${gatewayId}/fee-info`, 'GET', 'shipp', {
            scenarios: feeScenarios
        });
    }

    checkGatewayInfo(
        gatewayId: string,
        accountId: string
    ): Observable<{
        public_info: {legal_name: string; dba_name: string; email: string; business_phone_number: string};
        problem_fields: string[];
    }> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/zipi-financial/${gatewayId}/account/${accountId}/onboarding-info`,
            'GET',
            'shipp'
        );
    }

    getDwollaGatewayById(gatewayId: number): Observable<IPaymentGateway> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/dwolla/${gatewayId}`, 'GET', 'shipp');
    }

    getZipiFinancialGatewayDetails(gatewayId: number): Observable<{[key: string]: any}> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/${gatewayId}/zipi-fin/details`, 'GET', 'shipp');
    }

    getMyAuthGateways(contact_id: number): Observable<IPaymentGateway[]> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/authorize/${contact_id}/my`, 'GET', 'shipp');
    }

    getContactAuthGateways(contact_id: number): Observable<IPaymentGateway[]> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/authorize/${contact_id}/contact`, 'GET', 'shipp');
    }

    getContactZipiFinancialReceiveOnlyGateway(
        contactId: number,
        driver: string,
        methodType: 'bank' | 'card' | 'all'
    ): Observable<IPaymentGateway> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/${contactId}/zipi-fin-receive-only`, 'GET', 'shipp', {
            driver_type: driver,
            method_type: methodType
        });
    }

    getMyZipiFinancialGateways(contactId: number): Observable<IPaymentGateway[]> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/${contactId}/my-gateways`, 'GET', 'shipp');
    }

    syncZipiFinancialPaymentGateway(gatewayId: number): Observable<boolean> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/zipi-pay/${gatewayId}/sync`, 'GET', 'shipp');
    }

    createPublicZipiFinancialReceiveOnlyCustomerWithFundigSource(
        contactId: number,
        customerData: any
    ): Observable<IPaymentGateway> {
        return this.requester.makeMsCall$(
            `/public/zipi-financial/${contactId}/zipi-fin-receive-only`,
            'POST',
            'shipp',
            customerData
        );
    }

    createPublicZipiFinancialCardOnlyCustomerWithFundigSource(
        contactId: number,
        customerData: any
    ): Observable<IPaymentGateway> {
        return this.requester.makeMsCall$(
            `/public/zipi-financial/${contactId}/zipi-fin-card-only`,
            'POST',
            'shipp',
            customerData
        );
    }

    createZipiFinancialReceiveOnlyCustomerWithFundigSource(
        contactId: number,
        customerData: any
    ): Observable<IPaymentGateway> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/${contactId}/zipi-fin-receive-only`,
            'POST',
            'shipp',
            customerData
        );
    }

    createZipiFinancialCardOnlyCustomerWithFundigSource(
        contactId: number,
        customerData: any
    ): Observable<IPaymentGateway> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/${contactId}/zipi-fin-card-only`,
            'POST',
            'shipp',
            customerData
        );
    }

    createDwollaGateway(payment_gateway: IPaymentGateway): Observable<IPaymentGateway> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/dwolla`, 'POST', 'shipp', payment_gateway);
    }
    createZipiFinancialGateway(
        customerData: Partial<IPaymentGateway>
    ): Observable<IPaymentGateway & {deep_link_redirect: string; error: {message: string}}> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/zipi-fin`, 'POST', 'shipp', customerData);
    }

    preSetupZipiFinancialGateway(
        customerData: Partial<IPaymentGateway>
    ): Observable<{data: any; payload_ui_token: string}> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/zipi-fin/pre-setup`, 'POST', 'shipp', customerData);
    }

    getVerificationLink(payment_gateway_id: number): Observable<{link: string}> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/zipi-financial/${payment_gateway_id}/verification`,
            'GET',
            'shipp'
        );
    }

    getBankAccountSetupLink(payment_gateway_id: number): Observable<{link: string}> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/zipi-financial/${payment_gateway_id}/bank-setup`,
            'GET',
            'shipp'
        );
    }

    doRetryForDwolla(payment_gateway_id: number, retryData: any): Observable<boolean> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/${payment_gateway_id}/dwolla/retry`,
            'POST',
            'shipp',
            retryData
        );
    }

    doRetryForZipiFinancial(payment_gateway_id: number, retryData: any): Observable<boolean> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/${payment_gateway_id}/zipi-fin/retry`,
            'POST',
            'shipp',
            retryData
        );
    }

    doRetryForZipiFinancialRepresentative(payment_gateway_id: number, retryData: any): Observable<boolean> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/${payment_gateway_id}/zipi-fin/representative/retry`,
            'POST',
            'shipp',
            retryData
        );
    }

    createOrGetBankingGateway(): Observable<IPaymentGateway> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/banking`, 'POST', 'shipp');
    }

    createAuthorizeCompanyGateway(payment_gateway: IPaymentGateway): Observable<any> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/authorize`, 'POST', 'shipp', payment_gateway);
    }

    addBeneficialToDwollaCustomer(gatewayId: number, beneficialList: Array<any>): Observable<any> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/${gatewayId}/dwolla/beneficial`,
            'POST',
            'shipp',
            beneficialList
        );
    }
    addZipiFinancialRepresentatives(gatewayId: number, beneficialList: Array<any>): Observable<any> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/zipi-financial/${gatewayId}/add-representatives`,
            'POST',
            'shipp',
            beneficialList
        );
    }

    deleteBeneficialOwnerByRef(gatewayId: number, settings: any): Observable<boolean> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/dwolla/${gatewayId}/beneficial`,
            'POST',
            'shipp',
            settings
        );
    }

    deleteRepresentativeByRef(gatewayId: number, settings: any): Observable<boolean> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/zipi-financial/${gatewayId}/delete-representatives`,
            'POST',
            'shipp',
            settings
        );
    }

    confirmRepresentatives(gatewayId: number): Observable<boolean> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/zipi-financial/${gatewayId}/confirm-representatives`,
            'PUT',
            'shipp'
        );
    }

    updateAuthorizeCompanyGateway(gatewayId: number, payment_gateway: IPaymentGateway): Observable<any> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/${gatewayId}`, 'PUT', 'shipp', payment_gateway);
    }

    uploadDocument(gatewayId: number, requestBody: any): Observable<any> {
        return this.requester.submitFormData$(
            `${this.gatewayUrl}/${gatewayId}/dwolla/document`,
            'POST',
            'shipp',
            requestBody
        );
    }

    uploadDocumentForBeneficialOwner(gatewayId: number, requestBody: any): Observable<any> {
        return this.requester.submitFormData$(
            `${this.gatewayUrl}/${gatewayId}/dwolla/owner-document`,
            'POST',
            'shipp',
            requestBody
        );
    }

    updateCompanyGatewayById(paymentGatewayId: number, gatewayData: any): Observable<boolean> {
        return this.requester.submitFormData$(
            `${this.gatewayUrl}/zipi-financial/${paymentGatewayId}`,
            'PUT',
            'shipp',
            gatewayData
        );
    }

    updateDwollaInfoByGatewayId(paymentGatewayId: number, dwollaData: any): Observable<boolean> {
        return this.requester.submitFormData$(
            `${this.gatewayUrl}/dwolla-info/${paymentGatewayId}`,
            'PUT',
            'shipp',
            dwollaData
        );
    }

    deleteCompanyGateway(gatewaySlug: string): Observable<any> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/all/${gatewaySlug}`, 'DELETE', 'shipp');
    }

    deleteCompanyGatewayById(gatewayId: number): Observable<any> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/${gatewayId}`, 'DELETE', 'shipp');
    }

    isContactAvailableToReceiveByPaymentMode(
        contactId: number,
        mode: string
    ): Observable<{[key in GatewayTypes]: boolean}> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/${contactId}/available-for-receiving`, 'GET', 'shipp', {
            mode: mode
        });
    }

    getOrCreateIfNeededZipiPayCustomer(): Observable<any> {
        return this.requester.makeMsCall$(`${this.gatewayUrl}/zipi-financial/customer`, 'GET', 'shipp');
    }

    connectAddonInstanceToZipiFinancialCustomer(addonInstanceId: number): Observable<any> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/zipi-financial/connect-customer/${addonInstanceId}`,
            'GET',
            'shipp'
        );
    }

    saveNewDefaultZipiFinancialGatewayVelocity(
        paymentGatewayId: number,
        data: {velocity: string; type: 'when_sending' | 'when_receiving'; auto_downgrade: boolean}
    ): Observable<any> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/zipi-financial/${paymentGatewayId}/velocity/`,
            'POST',
            'shipp',
            data
        );
    }

    saveDefaultFeePayerForGateway(
        paymentGatewayId: number,
        data: {default_bank_fee_payer: string; default_card_fee_payer: string}
    ): Observable<any> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/zipi-financial/${paymentGatewayId}/fee-payer`,
            'POST',
            'shipp',
            data
        );
    }

    changeDefaultPayloadCoMethod(paymentGatewayId: number, payloadMethodId: string): Observable<any> {
        return this.requester.makeMsCall$(
            `${this.gatewayUrl}/zipi-financial/${paymentGatewayId}/payload-default-method`,
            'PUT',
            'shipp',
            {method_id: payloadMethodId}
        );
    }
}
