import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
    selector: 'app-dwolla-connect-to-ledger-account-dialog',
    templateUrl: './institution-status-dialog.component.html',
    styleUrls: ['./institution-status-dialog.component.scss']
})
export class InstitutionStatusDialogComponent implements OnInit {
    institutionInfo: {[key: string]: any} | undefined;
    moment = moment;
    constructor(
        public dialogRef: MatDialogRef<InstitutionStatusDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {institutionInfo: {[key: string]: any}}
    ) {}

    ngOnInit() {
        this.institutionInfo = this.data.institutionInfo;
    }
}
