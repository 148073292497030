import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {GenericFormGroup} from '../../../../../../entites/generic.entity';
import {CompanyCalculation} from '../../../../../shared/components/calculations/calculations.model';

@Component({
    selector: 'app-deal-preview',
    styleUrls: ['preview.component.scss'],
    template: `
        <mat-card class="wide-card preview-card">
            <mat-card-title
                (click)="toggleCollapse()"
                style="font-size: 15px; align-items: center;display: flex;cursor: pointer;"
            >
                Preview
                <mat-icon>
                    {{ isZeroValuesExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                </mat-icon>
            </mat-card-title>

            <mat-card-content class="full-width">
                <div
                    class="preview-row subtotal-section pr-36"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (companyCalculationFG.controls.base_commission &&
                            companyCalculationFG.controls.base_commission.controls.amount &&
                            companyCalculationFG.controls.base_commission.controls.amount.value)
                    "
                >
                    <div class="w-55 label text-left">Base Commission:</div>
                    <div class="w-45  text-right">
                        <app-financial-node
                            moneyClass="fee-value"
                            *ngIf="companyCalculationFG.controls.base_commission"
                            [financialNodeFG]="companyCalculationFG.controls.base_commission"
                            [highlightIfNegative]="true"
                        ></app-financial-node>
                    </div>
                </div>

                <div
                    class="preview-row subtotal-section"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (companyCalculationFG.controls.commission_incomes_sum &&
                            companyCalculationFG.controls.commission_incomes_sum.controls.amount &&
                            companyCalculationFG.controls.commission_incomes_sum.controls.amount.value) ||
                        (companyCalculationFG.controls.commission_expenses_sum &&
                            companyCalculationFG.controls.commission_expenses_sum.controls.amount &&
                            companyCalculationFG.controls.commission_expenses_sum.controls.amount.value)
                    "
                >
                    <div
                        class="sub-row column"
                        *ngIf="
                            isZeroValuesExpanded ||
                            (companyCalculationFG.controls.commission_incomes_sum &&
                                companyCalculationFG.controls.commission_incomes_sum.controls.amount &&
                                companyCalculationFG.controls.commission_incomes_sum.controls.amount.value)
                        "
                    >
                        <div class="w-100 node-with-details">
                            <div class="w-60 label text-left">Additional Commission:</div>
                            <div class="w-30 text-right">
                                <app-financial-node
                                    moneyClass="fee-value positive"
                                    *ngIf="companyCalculationFG.controls.commission_incomes_sum"
                                    [financialNodeFG]="companyCalculationFG.controls.commission_incomes_sum"
                                    [highlightIfNegative]="true"
                                ></app-financial-node>
                            </div>
                            <div
                                class="w-10"
                                *ngIf="
                                    companyCalculationFG.controls.commission_incomes_sum &&
                                    companyCalculationFG.controls.commission_incomes_sum.controls.amount &&
                                    companyCalculationFG.controls.commission_incomes_sum.controls.amount.value
                                "
                            >
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.commission_incomes_sum.controls.details &&
                                        companyCalculationFG.controls.commission_incomes_sum.controls.details.length > 0
                                    "
                                    class="arrow-button"
                                    (click)="isCommissionIncomesDetailsOpened = !isCommissionIncomesDetailsOpened"
                                >
                                    <mat-icon>{{
                                        isCommissionIncomesDetailsOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                    }}</mat-icon>
                                </div>
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.commission_incomes_sum.controls.details &&
                                        companyCalculationFG.controls.commission_incomes_sum.controls.details.length ===
                                            0
                                    "
                                    style="height: 24px"
                                >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'open'"
                                        matTooltip="To see details section in this deal please save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'approved'"
                                        matTooltip="To see details section in this deal please disapprove it, save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                </div>
                            </div>
                        </div>
                        <app-financial-details
                            class="w-100"
                            *ngIf="
                                isCommissionIncomesDetailsOpened &&
                                companyCalculationFG.controls.commission_incomes_sum &&
                                companyCalculationFG.controls.commission_incomes_sum.controls.details
                            "
                            [detailsFA]="companyCalculationFG.controls.commission_incomes_sum.controls.details"
                        ></app-financial-details>
                    </div>
                    <div
                        class="sub-row column"
                        *ngIf="
                            isZeroValuesExpanded ||
                            (companyCalculationFG.controls.commission_expenses_sum &&
                                companyCalculationFG.controls.commission_expenses_sum.controls.amount &&
                                companyCalculationFG.controls.commission_expenses_sum.controls.amount.value)
                        "
                    >
                        <div class="w-100 node-with-details">
                            <div class="w-60 label text-left">Commission Expense:</div>
                            <div class="w-30 text-right">
                                <app-financial-node
                                    moneyClass="fee-value negative"
                                    *ngIf="companyCalculationFG.controls.commission_expenses_sum"
                                    [financialNodeFG]="companyCalculationFG.controls.commission_expenses_sum"
                                    [highlightIfNegative]="true"
                                ></app-financial-node>
                            </div>
                            <div
                                class="w-10"
                                *ngIf="
                                    companyCalculationFG.controls.commission_expenses_sum &&
                                    companyCalculationFG.controls.commission_expenses_sum.controls.amount &&
                                    companyCalculationFG.controls.commission_expenses_sum.controls.amount.value
                                "
                            >
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.commission_expenses_sum.controls.details &&
                                        companyCalculationFG.controls.commission_expenses_sum.controls.details.length >
                                            0
                                    "
                                    class="arrow-button"
                                    (click)="isCommissionExpensesDetailsOpened = !isCommissionExpensesDetailsOpened"
                                >
                                    <mat-icon>{{
                                        isCommissionExpensesDetailsOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                    }}</mat-icon>
                                </div>
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.commission_expenses_sum.controls.details &&
                                        companyCalculationFG.controls.commission_expenses_sum.controls.details
                                            .length === 0
                                    "
                                    style="height: 24px"
                                >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'open'"
                                        matTooltip="To see details section in this deal please save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'approved'"
                                        matTooltip="To see details section in this deal please disapprove it, save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                </div>
                            </div>
                        </div>
                        <app-financial-details
                            class="w-100"
                            *ngIf="
                                isCommissionExpensesDetailsOpened &&
                                companyCalculationFG.controls.commission_expenses_sum &&
                                companyCalculationFG.controls.commission_expenses_sum.controls.details
                            "
                            [detailsFA]="companyCalculationFG.controls.commission_expenses_sum.controls.details"
                        ></app-financial-details>
                    </div>
                </div>

                <div
                    class="preview-row subtotal-section pr-36"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (companyCalculationFG.controls.commission &&
                            companyCalculationFG.controls.commission.controls.amount &&
                            companyCalculationFG.controls.commission.controls.amount.value)
                    "
                >
                    <div class="w-55 label text-left">Gross Commission:</div>
                    <div class="w-45 text-right">
                        <app-financial-node
                            moneyClass="fee-value"
                            *ngIf="companyCalculationFG.controls.commission"
                            [financialNodeFG]="companyCalculationFG.controls.commission"
                            [highlightIfNegative]="true"
                        ></app-financial-node>
                    </div>
                </div>

                <div
                    class="preview-row subtotal-section"
                    *ngIf="
                        !isGroup &&
                        (isZeroValuesExpanded ||
                            (companyCalculationFG.controls.referral &&
                                companyCalculationFG.controls.referral.controls.amount &&
                                companyCalculationFG.controls.referral.controls.amount.value))
                    "
                >
                    <div class="sub-row column">
                        <div class="w-100 node-with-details">
                            <div class="w-55 label text-left">
                                <div style="white-space: nowrap">Referral:</div>
                            </div>
                            <div class="w-35 text-right">
                                <app-financial-node
                                    moneyClass="fee-value negative"
                                    *ngIf="companyCalculationFG.controls.referral"
                                    [financialNodeFG]="companyCalculationFG.controls.referral"
                                    [highlightIfNegative]="true"
                                ></app-financial-node>
                            </div>
                            <div
                                class="w-10"
                                *ngIf="
                                    companyCalculationFG.controls.referral &&
                                    companyCalculationFG.controls.referral.controls.amount &&
                                    companyCalculationFG.controls.referral.controls.amount.value
                                "
                            >
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.referral.controls.details &&
                                        companyCalculationFG.controls.referral.controls.details.length > 0
                                    "
                                    class="arrow-button"
                                    (click)="isReferralDetailsOpened = !isReferralDetailsOpened"
                                >
                                    <mat-icon>{{
                                        isReferralDetailsOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                    }}</mat-icon>
                                </div>
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.referral.controls.details &&
                                        companyCalculationFG.controls.referral.controls.details.length === 0
                                    "
                                    style="height: 24px"
                                >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'open'"
                                        matTooltip="To see details section in this deal please save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'approved'"
                                        matTooltip="To see details section in this deal please disapprove it, save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                </div>
                            </div>
                        </div>
                        <app-financial-details
                            class="w-100"
                            *ngIf="
                                isReferralDetailsOpened &&
                                companyCalculationFG.controls.referral &&
                                companyCalculationFG.controls.referral.controls.details
                            "
                            [detailsFA]="companyCalculationFG.controls.referral.controls.details"
                        ></app-financial-details>
                    </div>
                </div>

                <div
                    class="preview-row subtotal-section pr-36"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (companyCalculationFG.controls.commission_after_referral &&
                            companyCalculationFG.controls.commission_after_referral.controls.amount &&
                            companyCalculationFG.controls.commission_after_referral.controls.amount.value)
                    "
                >
                    <div class="w-55 label text-left">Adjusted Gross:</div>
                    <div class="w-45 text-right">
                        <app-financial-node
                            moneyClass="fee-value"
                            *ngIf="companyCalculationFG.controls.commission_after_referral"
                            [financialNodeFG]="companyCalculationFG.controls.commission_after_referral"
                            [highlightIfNegative]="true"
                        ></app-financial-node>
                    </div>
                </div>

                <div
                    class="preview-row subtotal-section"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (companyCalculationFG.controls.royalty &&
                            companyCalculationFG.controls.royalty.controls.amount &&
                            companyCalculationFG.controls.royalty.controls.amount.value)
                    "
                >
                    <div class="w-100 node-with-details">
                        <div class="w-55 label text-left">Royalty:</div>
                        <div class="w-35 text-right">
                            <app-financial-node
                                moneyClass="fee-value negative"
                                *ngIf="companyCalculationFG.controls.royalty"
                                [financialNodeFG]="companyCalculationFG.controls.royalty"
                                [highlightIfNegative]="true"
                            ></app-financial-node>
                        </div>
                        <div
                            class="w-10"
                            *ngIf="
                                companyCalculationFG.controls.royalty &&
                                companyCalculationFG.controls.royalty.controls.amount &&
                                companyCalculationFG.controls.royalty.controls.amount.value
                            "
                        >
                            <div
                                *ngIf="
                                    companyCalculationFG.controls.royalty.controls.details &&
                                    companyCalculationFG.controls.royalty.controls.details.length > 0
                                "
                                class="arrow-button"
                                (click)="isRoyaltyDetailsOpened = !isRoyaltyDetailsOpened"
                            >
                                <mat-icon>{{
                                    isRoyaltyDetailsOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                }}</mat-icon>
                            </div>
                            <div
                                *ngIf="
                                    companyCalculationFG.controls.royalty.controls.details &&
                                    companyCalculationFG.controls.royalty.controls.details.length === 0
                                "
                                style="height: 24px"
                            >
                                <mat-icon
                                    *ngIf="dealSystemStatus === 'open'"
                                    matTooltip="To see details section in this deal please save it and edit it again."
                                    >help_outline</mat-icon
                                >
                                <mat-icon
                                    *ngIf="dealSystemStatus === 'approved'"
                                    matTooltip="To see details section in this deal please disapprove it, save it and edit it again."
                                    >help_outline</mat-icon
                                >
                            </div>
                        </div>
                    </div>
                    <app-financial-details
                        class="w-100"
                        *ngIf="
                            isRoyaltyDetailsOpened &&
                            companyCalculationFG.controls.royalty &&
                            companyCalculationFG.controls.royalty.controls.details
                        "
                        [detailsFA]="companyCalculationFG.controls.royalty.controls.details"
                    ></app-financial-details>
                </div>

                <div
                    class="preview-row subtotal-section pr-36"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (companyCalculationFG.controls.net_commission &&
                            companyCalculationFG.controls.net_commission.controls.amount &&
                            companyCalculationFG.controls.net_commission.controls.amount.value)
                    "
                >
                    <div class="w-55 label text-left">Net Commission:</div>
                    <div class="w-45 text-right">
                        <app-financial-node
                            moneyClass="fee-value"
                            *ngIf="companyCalculationFG.controls.net_commission"
                            [financialNodeFG]="companyCalculationFG.controls.net_commission"
                            [highlightIfNegative]="true"
                        ></app-financial-node>
                    </div>
                </div>

                <div
                    class="preview-row subtotal-section"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (companyCalculationFG.controls.deal_incomes_sum &&
                            companyCalculationFG.controls.deal_incomes_sum.controls.amount &&
                            companyCalculationFG.controls.deal_incomes_sum.controls.amount.value) ||
                        (companyCalculationFG.controls.deal_expenses_sum &&
                            companyCalculationFG.controls.deal_expenses_sum.controls.amount &&
                            companyCalculationFG.controls.deal_expenses_sum.controls.amount.value)
                    "
                >
                    <div
                        class="sub-row column"
                        *ngIf="
                            isZeroValuesExpanded ||
                            (companyCalculationFG.controls.deal_incomes_sum &&
                                companyCalculationFG.controls.deal_incomes_sum.controls.amount &&
                                companyCalculationFG.controls.deal_incomes_sum.controls.amount.value)
                        "
                    >
                        <div class="w-100 node-with-details">
                            <div class="w-55 label text-left">Deal Income:</div>
                            <div class="w-35 text-right">
                                <app-financial-node
                                    moneyClass="fee-value positive"
                                    *ngIf="companyCalculationFG.controls.deal_incomes_sum"
                                    [financialNodeFG]="companyCalculationFG.controls.deal_incomes_sum"
                                    [highlightIfNegative]="true"
                                ></app-financial-node>
                            </div>
                            <div
                                class="w-10"
                                *ngIf="
                                    companyCalculationFG.controls.deal_incomes_sum &&
                                    companyCalculationFG.controls.deal_incomes_sum.controls.amount &&
                                    companyCalculationFG.controls.deal_incomes_sum.controls.amount.value
                                "
                            >
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.deal_incomes_sum.controls.details &&
                                        companyCalculationFG.controls.deal_incomes_sum.controls.details.length > 0
                                    "
                                    class="arrow-button"
                                    (click)="isDealIncomesDetailsOpened = !isDealIncomesDetailsOpened"
                                >
                                    <mat-icon>{{
                                        isDealIncomesDetailsOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                    }}</mat-icon>
                                </div>
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.deal_incomes_sum.controls.details &&
                                        companyCalculationFG.controls.deal_incomes_sum.controls.details.length === 0
                                    "
                                    style="height: 24px"
                                >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'open'"
                                        matTooltip="To see details section in this deal please save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'approved'"
                                        matTooltip="To see details section in this deal please disapprove it, save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                </div>
                            </div>
                        </div>
                        <app-financial-details
                            class="w-100"
                            *ngIf="
                                isDealIncomesDetailsOpened &&
                                companyCalculationFG.controls.deal_incomes_sum &&
                                companyCalculationFG.controls.deal_incomes_sum.controls.details
                            "
                            [detailsFA]="companyCalculationFG.controls.deal_incomes_sum.controls.details"
                        ></app-financial-details>
                    </div>
                    <div
                        class="sub-row column"
                        *ngIf="
                            isZeroValuesExpanded ||
                            (companyCalculationFG.controls.deal_expenses_sum &&
                                companyCalculationFG.controls.deal_expenses_sum.controls.amount &&
                                companyCalculationFG.controls.deal_expenses_sum.controls.amount.value)
                        "
                    >
                        <div class="w-100 node-with-details">
                            <div class="w-55 label text-left">Deal Expense:</div>
                            <div class="w-35 text-right">
                                <app-financial-node
                                    moneyClass="fee-value negative"
                                    *ngIf="companyCalculationFG.controls.deal_expenses_sum"
                                    [financialNodeFG]="companyCalculationFG.controls.deal_expenses_sum"
                                    [highlightIfNegative]="true"
                                ></app-financial-node>
                            </div>
                            <div
                                class="w-10"
                                *ngIf="
                                    companyCalculationFG.controls.deal_expenses_sum &&
                                    companyCalculationFG.controls.deal_expenses_sum.controls.amount &&
                                    companyCalculationFG.controls.deal_expenses_sum.controls.amount.value
                                "
                            >
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.deal_expenses_sum.controls.details &&
                                        companyCalculationFG.controls.deal_expenses_sum.controls.details.length > 0
                                    "
                                    class="arrow-button"
                                    (click)="isDealExpensesDetailsOpened = !isDealExpensesDetailsOpened"
                                >
                                    <mat-icon>{{
                                        isDealExpensesDetailsOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                    }}</mat-icon>
                                </div>
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.deal_expenses_sum.controls.details &&
                                        companyCalculationFG.controls.deal_expenses_sum.controls.details.length === 0
                                    "
                                    style="height: 24px"
                                >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'open'"
                                        matTooltip="To see details section in this deal please save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'approved'"
                                        matTooltip="To see details section in this deal please disapprove it, save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                </div>
                            </div>
                        </div>
                        <app-financial-details
                            class="w-100"
                            *ngIf="
                                isDealExpensesDetailsOpened &&
                                companyCalculationFG.controls.deal_expenses_sum &&
                                companyCalculationFG.controls.deal_expenses_sum.controls.details
                            "
                            [detailsFA]="companyCalculationFG.controls.deal_expenses_sum.controls.details"
                        ></app-financial-details>
                    </div>
                </div>
                <div
                    class="preview-row subtotal-section pr-36"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (companyCalculationFG.controls.deal_net &&
                            companyCalculationFG.controls.deal_net.controls.amount &&
                            companyCalculationFG.controls.deal_net.controls.amount.value)
                    "
                >
                    <div class="w-55 label text-left">Deal Net:</div>
                    <div class="w-45 text-right">
                        <app-financial-node
                            moneyClass="fee-value"
                            *ngIf="companyCalculationFG.controls.deal_net"
                            [financialNodeFG]="companyCalculationFG.controls.deal_net"
                            [highlightIfNegative]="true"
                        ></app-financial-node>
                    </div>
                </div>

                <div
                    class="preview-row subtotal-section pr-36"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (companyCalculationFG.controls.sales_total &&
                            companyCalculationFG.controls.sales_total.controls.amount &&
                            companyCalculationFG.controls.sales_total.controls.amount.value)
                    "
                >
                    <div class="w-55 label text-left">Sales Total:</div>
                    <div class="w-45  text-right">
                        <app-financial-node
                            moneyClass="fee-value"
                            *ngIf="companyCalculationFG.controls.sales_total"
                            [financialNodeFG]="companyCalculationFG.controls.sales_total"
                            [highlightIfNegative]="true"
                        ></app-financial-node>
                    </div>
                </div>

                <div
                    class="preview-row subtotal-section"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (companyCalculationFG.controls.sales_incomes_sum &&
                            companyCalculationFG.controls.sales_incomes_sum.controls.amount &&
                            companyCalculationFG.controls.sales_incomes_sum.controls.amount.value) ||
                        (companyCalculationFG.controls.sales_expenses_sum &&
                            companyCalculationFG.controls.sales_expenses_sum.controls.amount &&
                            companyCalculationFG.controls.sales_expenses_sum.controls.amount.value)
                    "
                >
                    <div
                        class="sub-row column"
                        *ngIf="
                            isZeroValuesExpanded ||
                            (companyCalculationFG.controls.sales_incomes_sum &&
                                companyCalculationFG.controls.sales_incomes_sum.controls.amount &&
                                companyCalculationFG.controls.sales_incomes_sum.controls.amount.value)
                        "
                    >
                        <div class="w-100 node-with-details">
                            <div class="w-55 label text-left">Sales Income:</div>
                            <div class="w-35 text-right">
                                <app-financial-node
                                    moneyClass="fee-value positive"
                                    *ngIf="companyCalculationFG.controls.sales_incomes_sum"
                                    [financialNodeFG]="companyCalculationFG.controls.sales_incomes_sum"
                                    [highlightIfNegative]="true"
                                ></app-financial-node>
                            </div>
                            <div
                                class="w-10"
                                *ngIf="
                                    companyCalculationFG.controls.sales_incomes_sum &&
                                    companyCalculationFG.controls.sales_incomes_sum.controls.amount &&
                                    companyCalculationFG.controls.sales_incomes_sum.controls.amount.value
                                "
                            >
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.sales_incomes_sum.controls.details &&
                                        companyCalculationFG.controls.sales_incomes_sum.controls.details.length > 0
                                    "
                                    class="arrow-button"
                                    (click)="isSalesIncomesDetailsOpened = !isSalesIncomesDetailsOpened"
                                >
                                    <mat-icon>{{
                                        isSalesIncomesDetailsOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                    }}</mat-icon>
                                </div>
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.sales_incomes_sum.controls.details &&
                                        companyCalculationFG.controls.sales_incomes_sum.controls.details.length === 0
                                    "
                                    style="height: 24px"
                                >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'open'"
                                        matTooltip="To see details section in this deal please save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'approved'"
                                        matTooltip="To see details section in this deal please disapprove it, save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                </div>
                            </div>
                        </div>
                        <app-financial-details
                            class="w-100"
                            *ngIf="
                                isSalesIncomesDetailsOpened &&
                                companyCalculationFG.controls.sales_incomes_sum &&
                                companyCalculationFG.controls.sales_incomes_sum.controls.details
                            "
                            [detailsFA]="companyCalculationFG.controls.sales_incomes_sum.controls.details"
                        ></app-financial-details>
                    </div>
                    <div
                        class="sub-row column"
                        *ngIf="
                            isZeroValuesExpanded ||
                            (companyCalculationFG.controls.sales_expenses_sum &&
                                companyCalculationFG.controls.sales_expenses_sum.controls.amount &&
                                companyCalculationFG.controls.sales_expenses_sum.controls.amount.value)
                        "
                    >
                        <div class="w-100 node-with-details">
                            <div class="w-55 label text-left">Sales Expense:</div>
                            <div class="w-35 text-right">
                                <app-financial-node
                                    moneyClass="fee-value negative"
                                    *ngIf="companyCalculationFG.controls.sales_expenses_sum"
                                    [financialNodeFG]="companyCalculationFG.controls.sales_expenses_sum"
                                    [highlightIfNegative]="true"
                                ></app-financial-node>
                            </div>
                            <div
                                class="w-10"
                                *ngIf="
                                    companyCalculationFG.controls.sales_expenses_sum &&
                                    companyCalculationFG.controls.sales_expenses_sum.controls.amount &&
                                    companyCalculationFG.controls.sales_expenses_sum.controls.amount.value
                                "
                            >
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.sales_expenses_sum.controls.details &&
                                        companyCalculationFG.controls.sales_expenses_sum.controls.details.length > 0
                                    "
                                    class="arrow-button"
                                    (click)="isSalesExpensesDetailsOpened = !isSalesExpensesDetailsOpened"
                                >
                                    <mat-icon>{{
                                        isSalesExpensesDetailsOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                    }}</mat-icon>
                                </div>
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.sales_expenses_sum.controls.details &&
                                        companyCalculationFG.controls.sales_expenses_sum.controls.details.length === 0
                                    "
                                    style="height: 24px"
                                >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'open'"
                                        matTooltip="To see details section in this deal please save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'approved'"
                                        matTooltip="To see details section in this deal please disapprove it, save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                </div>
                            </div>
                        </div>
                        <app-financial-details
                            class="w-100"
                            *ngIf="
                                isSalesExpensesDetailsOpened &&
                                companyCalculationFG.controls.sales_expenses_sum &&
                                companyCalculationFG.controls.sales_expenses_sum.controls.details
                            "
                            [detailsFA]="companyCalculationFG.controls.sales_expenses_sum.controls.details"
                        ></app-financial-details>
                    </div>
                </div>

                <div
                    class="preview-row subtotal-section pr-36"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (companyCalculationFG.controls.sales_net &&
                            companyCalculationFG.controls.sales_net.controls.amount &&
                            companyCalculationFG.controls.sales_net.controls.amount.value)
                    "
                >
                    <div class="w-55 label text-left">Sales Net:</div>
                    <div class="w-45 text-right">
                        <app-financial-node
                            moneyClass="fee-value"
                            *ngIf="companyCalculationFG.controls.sales_net"
                            [financialNodeFG]="companyCalculationFG.controls.sales_net"
                            [highlightIfNegative]="true"
                        ></app-financial-node>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    `
})
export class DealPreviewComponent {
    @Input() public isGroup: boolean = false;
    @Input() public companyCalculationFG: GenericFormGroup<CompanyCalculation> = new GenericFormGroup(
        new CompanyCalculation(),
        'change'
    );
    @Input() public dealSystemStatus: string = 'draft';

    isZeroValuesExpanded: boolean = false;

    isCommissionIncomesDetailsOpened: boolean = false;
    isCommissionExpensesDetailsOpened: boolean = false;
    isReferralDetailsOpened: boolean = false;
    isDealIncomesDetailsOpened: boolean = false;
    isDealExpensesDetailsOpened: boolean = false;
    isSalesIncomesDetailsOpened: boolean = false;
    isSalesExpensesDetailsOpened: boolean = false;
    isRoyaltyDetailsOpened: boolean = false;

    constructor(
        protected router: Router,
        protected dialog: MatDialog
    ) {}

    toggleCollapse() {
        this.isZeroValuesExpanded = !this.isZeroValuesExpanded;
    }
}
