<div class="row content">
    <div class="col-xl-4 left-side">
        <mat-button-toggle-group vertical="true" [value]="activePanel" (change)="onSelectOptions($event)">
            <mat-button-toggle
                *ngFor="let item of reportOptions"
                [value]="item.value"
                (change)="onSelectOptions($event)"
            >
                {{ item.title }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="col-xl-8 right-side">
        <div *ngIf="activePanel === 'time_period'">
            <app-report-edit-bar-time-period
                [value]="{
                    time_period: reportSettings.time_period,
                    time_period_start: reportSettings.time_period_start,
                    time_period_end: reportSettings.time_period_end
                }"
                [startDateDisable]="true"
                (valueChanges)="onValueChanges('time_period', $event)"
            >
            </app-report-edit-bar-time-period>
        </div>

        <div *ngIf="activePanel === 'report_view'">
            <app-report-edit-bar-button-list
                [list]="reportViewList"
                [value]="reportSettings.report_view"
                (valueChanges)="onValueChanges('report_view', $event)"
            >
            </app-report-edit-bar-button-list>
        </div>

        <div>
            <app-report-edit-bar-action [reportId]="report.id" [isShowNext]="isShowNext" (action)="onAction($event)">
            </app-report-edit-bar-action>
        </div>
    </div>
</div>
