import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {GenericFormArray, GenericFormGroup} from '../../../../../../../entites/generic.entity';
import {FinancialTransferEntity} from '../../../../../../account-info/compensation/models/financial-transfer.entity';
import {UntypedFormControl} from '@angular/forms';
import {ChipNode} from '../../../../../../account-info/compensation/models/chip-node';
import {Subject} from 'rxjs';
import {Wildcard} from '../../../../../../../models/wildcard';
import {IBill, IContact, IInvoice, IProduct, IWildcard} from '@cyberco-nodejs/zipi-typings/index';
import {FinancialElementModel} from '../../../../../../account-info/compensation/models/financial-element.model';
import {Store} from '@ngrx/store';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ProductsService} from '../../../../../../../services/api/finance/products.service';

// const extractContactPersons = pipe(
//     _filter((c: IContact) => c.contact_persons && c.contact_persons.length > 0),
//     _filter((c: IContact) => c.owner_type === 'company'),
//     _map((contact: IContact) => {
//         return contact.contact_persons.map(p => ({
//             contact_id: contact.id,
//             link_title: p.full_name + (!!contact.company_name && contact.company_name !== p.full_name ? `, ${contact.company_name}` : ''),
//             contact_person_fk_id: p.contact_person_id
//         }));
//     }),
//     flatten,
// );

@Component({
    selector: 'app-view-transfer',
    templateUrl: './view-transfer.component.html',
    styleUrls: ['./view-transfer.component.scss']
})
export class ViewTransferComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    WILDCARD = Wildcard;
    senderApplyTo: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    receiverApplyTo: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    wildcardList: IWildcard[] = [];
    FINANCIAL_TRANSFER_ENTITY = FinancialTransferEntity;
    FINANCIAL_ELEMENT_ENTITY = FinancialElementModel;
    _transferFG: GenericFormGroup<FinancialTransferEntity> = new GenericFormGroup(new FinancialTransferEntity());
    disbursementInstructionsFC: UntypedFormControl = new UntypedFormControl(false);

    @Input() hideSender: boolean = false;
    @Input() hideReceiver: boolean = false;
    @Input() hidePayAtEscrowFlag: boolean = false;
    @Input() hideDisbursementInstructionsFlag: boolean = false;
    @Input() hideProduct: boolean = false;
    @Input() hideLabel: boolean = false;
    @Input() hideAmount: boolean = false;
    @Input() hideSkip: boolean = false;
    @Input() hideValueType: boolean = false;
    @Input() hidePayAtClosing: boolean = false;

    @Input() receiverLabel: string = 'Receiver Contact';
    @Input() senderLabel: string = 'Sender Contact';

    @Input() type: string = FinancialTransferEntity.type_SET.income;
    @Input() allowPercents: boolean = false;
    @Input() showFlatFeeBasedOnPercent: boolean = false;
    @Input() isExpense: boolean = false;

    @Input() disabled: boolean = false;
    @Input() disabledContact: boolean = false;

    @Input() origin: string = 'default';

    @Input() enableDisbursementInstructionsToggle: boolean = true;

    availableExpenseTypes = {
        default: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        agent: [
            FinancialTransferEntity.value_type_SET.percent_of_adjusted_gross,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        user_royalty: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_sales_total,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.flat_fee,
            FinancialTransferEntity.value_type_SET.flat_fee_sales_net
        ],
        royalty: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_adjusted_gross,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        sales: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_sales_entity_sales_split,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        user_referral: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        referral: [
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        disbursement_template: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.flat_fee,
            FinancialTransferEntity.value_type_SET.flat_fee_sales_net
        ],
        company_income_expense: [
            FinancialTransferEntity.value_type_SET.percent_of_entity_split,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_company_split,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_primary_entity_split,
            FinancialTransferEntity.value_type_SET.percent_of_sales_total,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        company: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        participant: [FinancialTransferEntity.value_type_SET.percent_of_deal],
        company_partner: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_company_split,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_sales_total,
            FinancialTransferEntity.value_type_SET.percent_of_sales_net,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        company_split_individual: [FinancialTransferEntity.value_type_SET.remaining]
    };

    availableIncomeTypes = {
        default: [FinancialTransferEntity.value_type_SET.flat_fee],
        agent: [
            FinancialTransferEntity.value_type_SET.percent_of_adjusted_gross,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        user_royalty: [
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_sales_total,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        royalty: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        referral: [FinancialTransferEntity.value_type_SET.flat_fee],
        sales: [FinancialTransferEntity.value_type_SET.flat_fee],
        user_referral: [FinancialTransferEntity.value_type_SET.flat_fee],
        disbursement_template: [FinancialTransferEntity.value_type_SET.flat_fee],
        company_income_expense: [FinancialTransferEntity.value_type_SET.flat_fee],
        company: [FinancialTransferEntity.value_type_SET.flat_fee],
        participant: [FinancialTransferEntity.value_type_SET.percent_of_deal],
        company_partner: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_company_split,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_sales_total,
            FinancialTransferEntity.value_type_SET.flat_fee
        ]
    };

    previous: FinancialTransferEntity | null = null;

    applyForPrimaryAgent = false;
    applyForOtherAgents = false;

    contactPersons: any;
    contacts: IContact[] = [];

    products: IProduct[] = [];
    invoices: IInvoice[] = [];
    bills: IBill[] = [];

    @Input() set transferFG(fx: GenericFormGroup<FinancialTransferEntity>) {
        this._transferFG = fx;
        this.previous = fx.getRawValue();
    }

    constructor(
        private store: Store<any>,
        public dialog: MatDialog,
        public router: Router,
        protected productsService: ProductsService
    ) {}

    ngOnInit() {}

    isPercent(value: string): boolean {
        switch (value) {
            case FinancialTransferEntity.value_type_SET.percent:
            case FinancialTransferEntity.value_type_SET.percent_of_split:
            case FinancialTransferEntity.value_type_SET.percent_of_base_commission:
            case FinancialTransferEntity.value_type_SET.percent_of_base_commission_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_commission:
            case FinancialTransferEntity.value_type_SET.percent_of_commission_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_adjusted_gross:
            case FinancialTransferEntity.value_type_SET.percent_of_adjusted_gross_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_deal_net:
            case FinancialTransferEntity.value_type_SET.percent_of_deal_net_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_net_commission:
            case FinancialTransferEntity.value_type_SET.percent_of_net_commission_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_company_split:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_total:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_net:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_entity_sales_split:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_price:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_price_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_entity_sales_price:
            case FinancialTransferEntity.value_type_SET.percent_of_primary_entity_split:
            case FinancialTransferEntity.value_type_SET.percent_of_entity_split:
                return true;
            case FinancialTransferEntity.value_type_SET.flat_fee:
            case FinancialTransferEntity.value_type_SET.flat_fee_sales_net:
            case FinancialTransferEntity.value_type_SET.remaining:
                return false;
            default:
                throw new Error(`Unexpected value type - ${value}`);
        }
        return false;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
