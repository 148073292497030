<div>
    <div [formGroup]="releaseForm">
        <h2>Release</h2>

        <div class="d-flex w-100">
            <mat-form-field class="w-50 mr-1">
                <mat-select formControlName="request_payer__contact_fk_id">
                    <mat-option
                        *ngFor="let payer of requestsContactList"
                        [value]="payer.contact!.contact_id"
                        [disabled]="payer.availableAmount < 0.01"
                        (onSelectionChange)="setAvailableAmount(payer.availableAmount, $event.isUserInput)"
                    >
                        {{ payer.contact!.display_name }} ({{ payer.availableAmount | currency }})
                    </mat-option>
                </mat-select>
                <mat-placeholder>Deposit Received From</mat-placeholder>
            </mat-form-field>
        </div>

        <div class="d-flex w-100">
            <mat-form-field
                class="w-50 mr-1"
                *ngIf="releaseForm && releaseForm.controls.request_payer__contact_fk_id.value"
            >
                <mat-select formControlName="release_from__ledger_account_fk_id">
                    <mat-option *ngFor="let ledger of availableLedgerList" [value]="ledger.ledger_account_id">
                        {{ ledger.name }}
                    </mat-option>
                </mat-select>
                <mat-placeholder>Release Funds From</mat-placeholder>
            </mat-form-field>

            <mat-form-field class="w-50 mr-1">
                <mat-select formControlName="money_receiver__contact_fk_id" required>
                    <mat-optgroup [label]="'Broker'">
                        <mat-option *ngIf="brokerContact" [value]="brokerContact.contact_id">
                            <span>{{ brokerContact.display_name }}</span>
                        </mat-option>
                    </mat-optgroup>
                    <mat-optgroup [label]="'Commission Payer'">
                        <mat-option *ngIf="commissionPayerContact" [value]="commissionPayerContact.contact_id">
                            <span>{{ commissionPayerContact.display_name }}</span>
                        </mat-option>
                    </mat-optgroup>
                    <mat-optgroup [label]="'Deal Contacts'" *ngIf="dealContacts && dealContacts.length > 0">
                        <mat-option *ngFor="let contact of dealContacts" [value]="contact.contact_id">
                            <span>{{ contact.display_name }}</span>
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
                <mat-placeholder>Pay To</mat-placeholder>
            </mat-form-field>
        </div>
        <div class="d-flex w-100">
            <div class="w-100">
                <mat-form-field class="w-100">
                    <mat-select [formControl]="selectedBills">
                        <mat-option [value]="'default_option'" *ngIf="isPayoutExist">
                            <span>Deal Payout</span>
                        </mat-option>
                        <mat-option [value]="'default_option'" *ngIf="!isPayoutExist">
                            <span>Separate Transfer</span>
                        </mat-option>
                        <mat-option
                            *ngFor="let bill of availableBills"
                            [value]="bill"
                            [disabled]="bill.pending_balance! < 0.01"
                        >
                            <span>{{ bill.bill_number }} (Balance: {{ bill.pending_balance | currency }})</span>
                        </mat-option>
                    </mat-select>
                    <mat-placeholder>Apply To</mat-placeholder>
                </mat-form-field>
            </div>
        </div>

        <div>
            <div class="d-flex w-100 align-items-center">
                <mat-form-field class="w-50 mr-2">
                    <mat-label>Amount</mat-label>
                    <input
                        [maskito]="currencyMaskitoMask"
                        matInput
                        type="text"
                        formControlName="amount"
                        name="amount"
                        autocomplete="off"
                        matTooltipPosition="above"
                        (keyup)="validateAmount($event)"
                        [disabled]="
                            !releaseForm.controls.money_receiver__contact_fk_id ||
                            !releaseForm.controls.money_receiver__contact_fk_id.value
                        "
                    />
                </mat-form-field>

                <div class="d-flex justify-content-end w-50">
                    <span *ngIf="ledgerBalance !== null">Balance: {{ ledgerBalance | currency }}</span>
                </div>
            </div>
        </div>
        <div [formGroup]="paymentForm">
            <mat-tab-group (selectedTabChange)="tabChanged($event)" [selectedIndex]="currentTabIndex">
                <mat-tab label="Money Transfers (EFT)">
                    <div style="min-height: 215px" class="py-2">
                        <div
                            class="d-flex"
                            style="color: red"
                            *ngIf="releaseForm && !releaseForm.controls?.money_receiver__contact_fk_id?.value"
                        >
                            Please select a Receiver in the "Pay To" field.
                        </div>
                        <div
                            class="d-flex"
                            style="color: red"
                            *ngIf="
                                releaseForm &&
                                releaseForm.controls?.money_receiver__contact_fk_id?.value &&
                                !isZipiFinancialAvailableForContact
                            "
                        >
                            Money Transfers (EFT) is not available for this Receiver.
                        </div>

                        <div
                            class="d-flex"
                            style="color: red"
                            *ngIf="releaseForm && !releaseForm.controls?.release_from__ledger_account_fk_id?.value"
                        >
                            Please select an Account in the "Deposit Received From" field.
                        </div>
                        <div
                            class="d-flex"
                            style="color: red"
                            *ngIf="
                                releaseForm &&
                                releaseForm.controls?.release_from__ledger_account_fk_id?.value &&
                                !isZipiFinancialAvailableForLedger
                            "
                        >
                            The selected Account is not connected to Money Transfers (EFT) Account
                        </div>

                        <div class="d-flex" *ngIf="isZipiFinancialAvailableForContact">
                            <mat-form-field class="w-100">
                                <mat-select formControlName="paid_by__payment_method_fk_id">
                                    <mat-optgroup *ngFor="let gateway of gateways" [label]="gateway.title">
                                        <mat-option
                                            *ngFor="let method of gateway.payment_methods"
                                            [value]="method.payment_method_id"
                                            [disabled]="
                                                method.status !== 'active' || !method.related__ledger_account_fk_id
                                            "
                                        >
                                            <span>{{ method.title }}</span>
                                        </mat-option>
                                    </mat-optgroup>
                                </mat-select>
                                <mat-placeholder>Payment Method</mat-placeholder>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Check">
                    <div style="min-height: 215px" class="py-2">
                        <div class="d-flex w-100">
                            <div class="w-50 mr-2">
                                <mat-form-field class="w-100">
                                    <input matInput type="text" formControlName="check_number" placeholder="Check#" />
                                </mat-form-field>
                            </div>
                            <div class="w-50 mr-2">
                                <app-date-picker
                                    [disabled]="
                                        !releaseForm.controls.request_payer__contact_fk_id ||
                                        !releaseForm.controls.request_payer__contact_fk_id.value
                                    "
                                    [placeholder]="'Release Date'"
                                    [dateControl]="releaseForm.controls.paid_date"
                                >
                                </app-date-picker>
                            </div>
                        </div>

                        <div style="">
                            <mat-form-field class="w-100">
                                <textarea matInput type="text" formControlName="memo" placeholder="Memo"> </textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Record">
                    <div style="min-height: 215px" class="py-2">
                        <div class="d-flex">
                            <mat-form-field class="w-50 mr-2">
                                <mat-select formControlName="payment_mode">
                                    <ng-container *ngFor="let mode of paymentModes">
                                        <mat-option [value]="mode.slug" *ngIf="mode.is_selectable">
                                            <span>{{ mode.title }}</span>
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                                <mat-placeholder>Payment Mode</mat-placeholder>
                            </mat-form-field>

                            <div class="w-50 mr-2">
                                <app-date-picker
                                    [disabled]="
                                        !releaseForm.controls.request_payer__contact_fk_id ||
                                        !releaseForm.controls.request_payer__contact_fk_id.value
                                    "
                                    [placeholder]="'Release Date'"
                                    [dateControl]="releaseForm.controls.paid_date"
                                >
                                </app-date-picker>
                            </div>
                        </div>

                        <div *ngIf="paymentForm.controls.payment_mode.value === 'check_record'">
                            <mat-form-field class="w-100">
                                <input matInput type="text" formControlName="check_number" placeholder="Check#" />
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <textarea matInput type="text" formControlName="memo" placeholder="Memo"> </textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
            <div class="d-flex">
                <mat-form-field class="w-100">
                    <textarea matInput type="text" formControlName="reference" placeholder="Reference"> </textarea>
                </mat-form-field>
            </div>
            <div class="d-flex justify-content-end">
                <span>Amount: {{ currentAmountForDisplay | currency }}</span>
            </div>
        </div>

        <div class="d-flex justify-content-end mt-3">
            <button
                class="mr-2"
                *ngIf="!data.hideCancel"
                mat-raised-button
                (click)="dialogRef.close(false)"
                tabindex="-1"
            >
                Cancel
            </button>
            <button
                class="mr-2"
                mat-raised-button
                color="primary"
                [disabled]="
                    ledgerBalance! < 0.01 ||
                    (currentTabIndex === 0 &&
                        (!isZipiFinancialAvailableForLedger || !isZipiFinancialAvailableForContact))
                "
                (click)="continue()"
            >
                Create
            </button>
        </div>
    </div>
</div>
