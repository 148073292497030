import {Injectable} from '@angular/core';
import {ServiceRequester} from './service.requester';
import {MembershipOrganization} from '../models/membership-organization';

@Injectable()
export class MembershipService {
    private url = '/membership/';

    constructor(public requester: ServiceRequester) {}

    /** Use store */
    createMembershipOrganization(data: MembershipOrganization) {
        return this.requester.makeMsCall(this.url + 'create-membership-organization', 'POST', {
            membership_organization_data: data
        });
    }

    /** Use store */
    updateMembershipOrganization(data: MembershipOrganization) {
        return this.requester.makeMsCall(this.url + 'update-membership-organization', 'POST', {
            membership_organization_data: data
        });
    }

    /** Use store */
    getAvailableMembershipOrganizations() {
        return this.requester.makeMsCall$(this.url + 'get-available-membership-organizations', 'GET', 'zipi');
    }

    getProfileMemberships(profileId: number) {
        return this.requester.makeMsCall$(this.url + profileId + '/get-profile-memberships', 'GET', 'zipi');
    }
}
