import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {IPaymentGateway} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-muhnee-add-bank-account-dialog',
    templateUrl: './select-own-gateway-for-receive-only-dialog.component.html',
    styleUrls: ['./select-own-gateway-for-receive-only-dialog.component.scss']
})
export class SelectOwnGatewayForReceiveOnlyDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    paymentGateways: IPaymentGateway[] = [];

    constructor(
        public dialogRef: MatDialogRef<SelectOwnGatewayForReceiveOnlyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {gateways: IPaymentGateway[]}
    ) {}

    ngOnInit() {
        this.paymentGateways = this.data.gateways;
    }

    select(gateway: IPaymentGateway) {
        this.dialogRef.close(gateway);
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
