import {BaseModel} from './base.model';
import {Profile} from './profile';

export class ListItem {
    name: string | null = null;
    value: number | null = null;
    type?: 'percents' | 'flat_fee';
}

export const CALCULATION_TYPE_DEAL_OWNER = 'deal_owner';
export const CALCULATION_TYPE_TMS = 'team_member_split';

export class Calculation extends BaseModel {
    profit_value: number | null = null;
    profit_type: string | null = null; // percents or flat_fee

    profile: Profile = new Profile();

    incomes: ListItem[] = [];
    outcomes: ListItem[] = [];

    input = {
        salesPrice: {
            value: 0
        },

        // incomes
        commission: {
            value: 0,
            type: 'percents'
        },

        incomes: [],

        // expenses
        referralFee: {
            value: 0,
            type: 'percents'
        },

        teamMemberSplitList: [],

        outcomes: [],

        royalty: {
            value: 0,
            type: 'percents'
        },

        coSplit: {
            value: 0,
            type: 'percents'
        },

        // taxes
        taxes: {
            value: 0,
            type: 'percents'
        },

        bizExp: {
            value: 0,
            type: 'percents'
        }
    };
    output = {
        gci: {
            value: 0,
            percentage: 0
        },
        net: {
            value: 0
        },
        gciAfterRefFee: {
            value: 0
        },
        gciAfterRoyaltyAndCoSplit: {
            value: 0
        },
        gciAfterIncomesOutcomes: {
            value: 0
        },
        gciAfterTaxesBizExp: {
            value: 0
        },
        gciAfterTeamMemberSplit: {
            value: 0
        },
        referralFee: {
            value: 0,
            percentage: 0
        },
        teamMemberSplit: {
            value: 0,
            percentage: 0
        },
        royalty: {
            value: 0,
            percentage: 0
        },
        coSplit: {
            value: 0,
            percentage: 0
        },
        incomes: {
            value: 0,
            percentage: 0
        },
        outcomes: {
            value: 0,
            percentage: 0
        },
        taxes: {
            value: 0,
            percentage: 0
        },
        bizExp: {
            value: 0,
            percentage: 0
        },
        /**
         * That is trick for correct calculation processing.
         * Details: right now, if user click on Refresh in Preview section, we get calculations for Owner, save in typescript object
         * and send to backend. Backend save it as is. And after saving run recalculate process for entire deal. But, as recalculate
         * for owner give the same result as if was in getting preview - then system do not run recalculate for TMS, (because we
         * do to recalculate TMS if nothing changed for Owner).
         * So we add this flag just to get FALSE when backend will recalculate Owner calculation first time.
         * NOTE that we add this flag only due creating deal and only on front-end. Backend don't know about this flag.
         */
        forceRecalculate: true
    };

    type = CALCULATION_TYPE_DEAL_OWNER;
}

export class TeamMemberCalculation extends Calculation {
    type = CALCULATION_TYPE_TMS;
}
