import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {IFinancialTransfer, IInvoice, IProduct} from '@cyberco-nodejs/zipi-typings';
import {Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {DealService} from '../../../../../services/deal.service';
import {FormGroupArray} from '../../../../../typings/common';
import Decimal from 'decimal.js-light';
import {FinancialTransferEntity} from '../../../../account-info/compensation/models/financial-transfer.entity';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';
import {IRelatedDealPayoutInfo} from '@app/modules/finance/types/financial.types';

@Component({
    selector: 'app-deducted-payment-confirmation',
    templateUrl: './deducted-payment-confirmation.component.html',
    styleUrls: ['./deducted-payment-confirmation.component.scss']
})
export class DeductedPaymentConfirmationComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    amountForm: UntypedFormGroup | undefined;
    currencyMaskitoMask = currencyMaskitoOptions;

    invoice: IInvoice | undefined;
    invoiceTransfers: IRelatedDealPayoutInfo[] = [];
    totalNet: number | undefined;

    productDeductArray: FormGroupArray = this.fb.array([]) as FormGroupArray;
    warning: string;

    isIncorrectInvoice: boolean;
    isLoaded: boolean;

    constructor(
        private fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<DeductedPaymentConfirmationComponent>,
        private dealService: DealService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.warning = '';
        this.isIncorrectInvoice = true;
        this.isLoaded = false;
    }

    ngOnInit() {
        this.invoice = this.data.invoice;
        this.totalNet = this.data.totalNet;

        if (typeof this.invoice !== 'undefined') {
            this.isIncorrectInvoice = this.invoice.items?.some((item) => !item.product_fk_id) as boolean;
        }
        this.isLoaded = true;
        this.getInvoiceFinancialTransfers();

        this.productDeductArray.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((change) => {
            const items = this.productDeductArray.getRawValue().filter((deductItem) => {
                return Number(cleanCurrencyString(deductItem.amount)) > 0;
            });
            this.warning = items.length > 1 ? `${items.length} Financial Transfers / Payments will be created` : '';
        });
    }

    getInvoiceFinancialTransfers() {
        if (typeof this.invoice !== 'undefined') {
            this.dealService.getFinancialTransfersByInvoiceId(this.invoice.invoice_id as number).then((transfers) => {
                this.invoiceTransfers = transfers;
                this.createProductsArray();
            });
        }
    }

    createProductsArray() {
        const prodSet: {[key: number]: {product: IProduct; max_amount: Decimal}} = {};
        let netBalance: Decimal = this.totalNet ? new Decimal(this.totalNet) : new Decimal(0);
        if (this.invoice && this.invoice.items && this.invoice.items.length > 0) {
            this.invoice.items.forEach((item) => {
                if (item.amount && !isNaN(item.amount) && this.invoice && this.invoice.total_amount) {
                    if (item.product_fk_id && prodSet.hasOwnProperty(item.product_fk_id)) {
                        const newProductMax = prodSet[item.product_fk_id].max_amount.add(item.amount);
                        prodSet[item.product_fk_id].max_amount =
                            newProductMax.toDecimalPlaces(2).toNumber() > this.invoice.total_amount
                                ? new Decimal(this.invoice.total_amount)
                                : newProductMax;
                    } else if (item.product_fk_id) {
                        const newProductMax = new Decimal(item.amount);
                        prodSet[item.product_fk_id] = {
                            max_amount:
                                newProductMax.toDecimalPlaces(2).toNumber() > this.invoice.total_amount
                                    ? new Decimal(this.invoice.total_amount)
                                    : newProductMax,
                            product: item.product as IProduct
                        };
                    }
                }
            });
        }

        if (this.invoiceTransfers && this.invoiceTransfers.length > 0) {
            this.invoiceTransfers.forEach((item) => {
                if (
                    item.product_id &&
                    prodSet.hasOwnProperty(item.product_id) &&
                    item.type === FinancialTransferEntity.type_SET.deduct_transfer
                ) {
                    prodSet[item.product_id].max_amount = item.amount
                        ? prodSet[item.product_id].max_amount.sub(item.amount)
                        : prodSet[item.product_id].max_amount;
                }
            });
        }

        Object.keys(prodSet).forEach((prodId) => {
            this.productDeductArray.push(
                this.fb.group({
                    product: [prodSet[prodId as any].product, []],
                    amount: [
                        netBalance && netBalance.toDecimalPlaces(2).toNumber() > 0
                            ? netBalance.toDecimalPlaces(2).toNumber() >
                              prodSet[prodId as any].max_amount.toDecimalPlaces(2).toNumber()
                                ? prodSet[prodId as any].max_amount.toDecimalPlaces(2).toNumber()
                                : netBalance.toDecimalPlaces(2).toNumber()
                            : 0,
                        []
                    ],
                    max_amount: [prodSet[prodId as any].max_amount.toDecimalPlaces(2).toNumber(), []]
                })
            );
            netBalance = netBalance.sub(prodSet[prodId as any].max_amount);
        });
    }

    validateAmount(event: any, itemGroup: any) {
        const inputValue = Number(cleanCurrencyString(event.target.value));
        const availableAmount = itemGroup.get('max_amount').value;

        if (availableAmount < inputValue || (typeof this.totalNet !== 'undefined' && inputValue > this.totalNet)) {
            itemGroup.get('amount').setValidators([Validators.email]);
            itemGroup.get('amount').updateValueAndValidity();
            itemGroup.get('amount').markAsTouched();
        } else {
            itemGroup.get('amount').clearValidators();
            itemGroup.get('amount').updateValueAndValidity();
        }
    }

    apply() {
        if (this.productDeductArray.invalid) {
            this.productDeductArray.markAllAsTouched();
        } else {
            let amountFormData = this.productDeductArray.getRawValue();
            amountFormData = amountFormData
                .map((deductItem) => {
                    deductItem.amount = Number(cleanCurrencyString(deductItem.amount));
                    return {amount: deductItem.amount, product_id: deductItem.product.product_id};
                })
                .filter((deductItem) => deductItem.amount > 0);

            this.dialogRef.close(amountFormData);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
