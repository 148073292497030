import {GenericEntity} from '../../../../entites/generic.entity';
import {IBill, IContactPartLink, IFinancialTransfer, IInvoice} from '@cyberco-nodejs/zipi-typings';
import {ContactPartLink} from '../../../../models/contact-part-link';
import {CompensationPlanModel} from './compensation-plan.model';

export class FinancialTransferEntity extends GenericEntity implements IFinancialTransfer {
    _id: number | null = null;
    id: number | null = null;
    financial_transfer_id: number | null = null;
    // @ts-ignore to supress massive Property 'controls' does not exist on type 'FormControl | GenericFormGroup<IFinancialTransfer>' error
    override: FinancialTransferEntity = null;
    type: string | null = null;
    sender: IContactPartLink | null = null;
    receiver: IContactPartLink | null = null;
    sender_wildcard_id: number | null = null;
    receiver_wildcard_id: number | null = null;
    context_sales_entity__contact_fk_id: number | null = null;
    label: string | null = null;
    amount: number | null = null;
    source_amount: number | null = null;
    percent: number | null = null;
    added_automatic: boolean = false;
    value_type: string = FinancialTransferEntity.value_type_SET.flat_fee;
    percent_type: string = FinancialTransferEntity.percent_type_SET.gci;
    show_in_disbursement_pdf: boolean = false;
    disbursement_instructions: boolean = false;
    pay_at_escrow: boolean = true;
    notes: string | null = null;
    value_wildcard_fk_id: number | null = null;
    notes_wildcard_fk_id: number | null = null;
    unique_hash: string | null = null;
    invoice: IInvoice | null = null;
    invoice_fk_id: number | null = null;
    bill_fk_id: number | null = null;
    payment_fk_id: number | null = null;
    deal_fk_id: number | null = null;
    product_fk_id: number | null = null;
    is_edit_disable: boolean = false;
    is_enforced: boolean = true;
    is_modified_by_user: boolean = false;
    is_deleted_by_user: boolean = false;
    parent__financial_element_fk_id: number | null = null;
    origin: string = 'default';
    origin_is_expense: boolean = false;
    can_be_skipped: boolean = false;
    is_skipped: boolean = false;
    payout_to_hash: string | null = null;
    payout_from_hash: string | null = null;

    receiver_contact__company_fk_id: number | null = null;
    sender_contact__company_fk_id: number | null = null;
    connected__invoice_fk_id: number | null = null;
    connected__bill_fk_id: number | null = null;
    connected_invoice: IInvoice | null = null;
    connected_bill: IBill | null = null;
    status: string = FinancialTransferEntity.status_SET.pending;
    delete_automatic: boolean = false;
    creator__company_fk_id: number | null = null;

    partner__bill_fk_id: number | null = null;
    partner__invoice_fk_id: number | null = null;
    finance_connection_mode: 'from_deal' | 'from_finance' | null = null;
    apply_to_agents: 'primary' | 'other' | 'all' = 'primary';
    contribute_to_cap: string | null = null;
    processing_status: 'pending' | 'open' | 'locked' = 'pending';

    compensation_plan?: CompensationPlanModel = new CompensationPlanModel();

    setType(type: string) {
        this.type = type;
        return this;
    }

    setLabel(label: string) {
        this.label = label;
        return this;
    }

    setUniqueHash(unique_hash: string) {
        this.unique_hash = unique_hash;
        return this;
    }

    setOrigin(origin: string) {
        this.origin = origin;
        return this;
    }

    setValueType(valueType: string) {
        this.value_type = valueType;
        return this;
    }

    setPercent(percent: number) {
        this.percent = percent;
        return this;
    }

    setOriginIsExpense(isExpense: boolean) {
        this.origin_is_expense = isExpense;
        return this;
    }

    setProductId(productId: number) {
        this.product_fk_id = productId;
        return this;
    }

    setSenderWildcardId(wildcardId: number) {
        this.sender_wildcard_id = wildcardId;
        return this;
    }

    setSender(sender: IContactPartLink | null) {
        this.sender = sender;
        return this;
    }

    setReceiverWildcardId(wildcardId: number) {
        this.receiver_contact__company_fk_id = wildcardId;
        return this;
    }

    setReceiver(receiver: IContactPartLink) {
        this.receiver = receiver;
        return this;
    }

    setupByInvoiceWithoutAmount(invoice: IInvoice) {
        this.label = invoice.invoice_number;
        this.invoice_fk_id = invoice.invoice_id!;
        this.invoice = invoice;
        this.is_edit_disable = true;
        this.receiver = new ContactPartLink()
            .setLinkTitle(invoice.creator_company!.company_group!.contact!.display_name!)
            .setContactId(invoice.creator_company!.company_group!.contact!.contact_id!);
        this.product_fk_id = invoice.items && invoice.items.length ? invoice.items[0].product_fk_id : null;
        return this;
    }

    setSourceAmount(sourceAmount: number) {
        this.source_amount = sourceAmount;
        return this;
    }

    setAmount(amount: number) {
        this.amount = amount;
        return this;
    }

    setPaymentId(id: number) {
        this.payment_fk_id = id;
        return this;
    }

    get isPayout() {
        switch (this.type) {
            case FinancialTransferEntity.type_SET.payout:
            case FinancialTransferEntity.type_SET.company_payout:
            case FinancialTransferEntity.type_SET.agent_payout:
                return true;
            default:
                return false;
        }
    }

    get isSystem() {
        switch (this.type) {
            // case FinancialTransferEntity.type_SET.sales:
            case FinancialTransferEntity.type_SET.agent_split:
            case FinancialTransferEntity.type_SET.company_split:
            case FinancialTransferEntity.type_SET.company_split_individual:
            case FinancialTransferEntity.type_SET.participant_split:
                return true;
            default:
                return false;
        }
    }

    get getDisbursementInstructions() {
        return this.override && typeof this.override.disbursement_instructions !== 'undefined'
            ? this.override.disbursement_instructions
            : this.disbursement_instructions;
    }

    get getNotes() {
        return this.override && typeof this.override.notes !== 'undefined' ? this.override.notes : this.notes;
    }

    get isEscrowTransfer() {
        return this.override && typeof this.override.pay_at_escrow !== 'undefined'
            ? this.override.pay_at_escrow
            : this.pay_at_escrow;
    }

    get isEscrowPayout() {
        return this.isPayout && this.isEscrowTransfer;
    }

    get isDisbursementItem() {
        const isSystem = this.isSystem;
        const disbursementInstructions = this.getDisbursementInstructions;
        const isPayout = this.isPayout;
        const payAtEscrow = this.isEscrowTransfer;
        return payAtEscrow && (isPayout || (disbursementInstructions && !isSystem));
    }

    get isRemaining() {
        return (
            !this.isSystem && !this.isEscrowTransfer && this.type !== FinancialTransferEntity.type_SET.deposit_release
        );
    }

    static get helpers() {
        return Object.assign(
            {
                props: Object.getOwnPropertyNames(new this()).reduce((acc, curr) => {
                    // @ts-ignore
                    acc[curr] = curr;
                    return acc;
                }, {}),
                build: {
                    sender: (val: object, fabric: string) => GenericEntity.FABRIC(ContactPartLink).hydrate(val),
                    receiver: (val: object, fabric: string) => GenericEntity.FABRIC(ContactPartLink).hydrate(val)
                }
            },
            GenericEntity.helpers
        );
    }

    static get type_SET() {
        return {
            deposit_release: 'deposit_release',
            income: 'income',
            transfer: 'transfer',
            commission: 'commission',
            expense: 'expense',
            referral: 'referral',
            ancillary: 'ancillary',
            partner: 'partner',
            participant: 'participant',
            participant_split: 'participant_split',
            royalty: 'royalty',
            user_royalty: 'user_royalty',
            sales: 'sales',
            sales_individual: 'sales_individual',
            agent_split: 'agent_split',
            company_split: 'company_split',
            company_split_individual: 'company_split_individual',
            agent_payout: 'agent_payout',
            company_payout: 'company_payout',
            payout: 'payout',
            deduct_transfer: 'deduct_transfer',
            associate_transfer: 'associate_transfer',
            compensation_expense: 'compensation_expense'
        };
    }

    static get type_GET_COLORS(): {[key: string]: string} {
        return {
            deposit_release: '#0000FF',
            sales: '#CC99CC',
            income: '#333366',
            transfer: '#660000',
            commission: '#993366',
            expense: '#006633',
            referral: '#990066',
            ancillary: '#CCCC00',
            royalty: '#996600',
            user_royalty: '#CC3399',
            sales_individual: '#009966',
            agent_split: '#330066',
            company_split: '#006699',
            company_split_individual: '#336666',
            deduct_transfer: '#9933FF',
            associate_transfer: '#99CC33',
            compensation_expense: '#00CCCC'

            // skipped types of transfers because we don't show them on the Edit Deal page
            // partner: '',
            // participant: '',
            // participant_split: '',
            // agent_payout: '',
            // company_payout: '',
            // payout: '',
        };
    }

    static get value_type_SET() {
        return {
            flat_fee: 'flat_fee',
            flat_fee_sales_net: 'flat_fee_sales_net',
            remaining: 'remaining',
            percent: 'percent',
            percent_of_base_commission: 'percent_of_base_commission',
            percent_of_commission: 'percent_of_commission',
            percent_of_adjusted_gross: 'percent_of_adjusted_gross',
            percent_of_net_commission: 'percent_of_net_commission',
            percent_of_deal_net: 'percent_of_deal_net',
            percent_of_sales_total: 'percent_of_sales_total',
            percent_of_primary_entity_split: 'percent_of_primary_entity_split',
            percent_of_entity_split: 'percent_of_entity_split',
            percent_of_deal: 'percent_of_deal',
            // @TODO: Migration needed to convert to 'percent_of_deal'
            deal: 'deal',
            percent_of_sales_entity_sales_price: 'percent_of_sales_entity_sales_price',
            percent_of_sales_net: 'percent_of_sales_net',
            percent_of_split: 'percent_of_split',
            percent_of_company_split: 'percent_of_company_split',
            percent_of_sales_entity_sales_split: 'percent_of_sales_entity_sales_split',
            percent_of_sales_price: 'percent_of_sales_price',
            percent_of_sales_price_by_percent_of_deal: 'percent_of_sales_price_by_percent_of_deal',
            percent_of_base_commission_by_percent_of_deal: 'percent_of_base_commission_by_percent_of_deal',
            percent_of_commission_by_percent_of_deal: 'percent_of_commission_by_percent_of_deal',
            percent_of_adjusted_gross_by_percent_of_deal: 'percent_of_adjusted_gross_by_percent_of_deal',
            percent_of_net_commission_by_percent_of_deal: 'percent_of_net_commission_by_percent_of_deal',
            percent_of_deal_net_by_percent_of_deal: 'percent_of_deal_net_by_percent_of_deal'
        };
    }

    static get value_type_LABELS(): any {
        return {
            flat_fee: 'Flat Fee',
            flat_fee_sales_net: 'Flat Fee [based on deal split]',
            remaining: 'Remaining',
            percent: 'Percent',
            percent_of_base_commission: '% of Base Commission',
            percent_of_commission: '% of Gross Commission',
            percent_of_adjusted_gross: '% of Adjusted Gross Commission',
            percent_of_net_commission: '% of Net Commission',
            percent_of_deal_net: '% of Deal Net',
            percent_of_deal: '% of Deal',
            percent_of_sales_entity_sales_price: '% of Sales Entity Sales Price',
            percent_of_sales_total: '% of Sales Total',
            percent_of_sales_net: '% of Sales Net',
            percent_of_split: '% of Split',
            percent_of_company_split: '% of Company Split',
            percent_of_sales_entity_sales_split: '% of Sales Total',
            percent_of_sales_price: '% of Sales Price',
            percent_of_primary_entity_split: '% of Primary Agent Split',
            percent_of_entity_split: '% of Agent Split',
            percent_of_sales_price_by_percent_of_deal: '% of Sales Price [based on deal split]',
            percent_of_base_commission_by_percent_of_deal: '% of Base Commission [based on deal split]',
            percent_of_commission_by_percent_of_deal: '% of Gross Commission [based on deal split]',
            percent_of_adjusted_gross_by_percent_of_deal: '% of Adjusted Gross Commission [based on deal split]',
            percent_of_net_commission_by_percent_of_deal: '% of Net Commission [based on deal split]',
            percent_of_deal_net_by_percent_of_deal: '% of Deal Net By [based on deal split]'
        };
    }

    static get contribute_to_cap_LABELS(): any {
        return {
            count_of_deals: 'Count Of Deals',
            per_deal_adjusted_gross_commission: 'Per Deal Adjusted Gross Commission',
            per_deal_aggregate_commission: 'Per Deal Aggregate Commission',
            per_deal_gross_commission: 'Per Deal Gross Commission',
            per_deal_sales_price: 'Per Deal Sales Price',
            sum_of_sales_price: 'Sum of Sales Price',
            sum_of_gross_commission: 'Sum of Gross Commission',
            total_agent_split: 'Total Agent Split',
            total_company_split: 'Total Company Split',
            total_royalty_split: 'Total Royalty Split',
            total_user_royalty_split: 'Total User Royalty Split',
            receiver_split: 'Receiver Split'
        };
    }

    static get percent_type_SET() {
        return {
            gci: 'gci',
            sales_price: 'sales_price'
        };
    }

    static get status_SET() {
        return {
            pending: 'pending',
            open: 'open',
            paid: 'paid'
        };
    }

    static get agents_types_SET() {
        return {
            primary: 'primary',
            other: 'other',
            all: 'all'
        };
    }

    static get contribute_to_cap_SET() {
        return {
            sum_of_sales_price: 'sum_of_sales_price',
            per_deal_sales_price: 'per_deal_sales_price',
            sum_of_gross_commission: 'sum_of_gross_commission',
            per_deal_gross_commission: 'per_deal_gross_commission',
            per_deal_aggregate_commission: 'per_deal_aggregate_commission',
            per_deal_adjusted_gross_commission: 'per_deal_adjusted_gross_commission',
            count_of_deals: 'count_of_deals',
            total_company_split: 'total_company_split',
            receiver_split: 'receiver_split',
            total_agent_split: 'total_agent_split',
            total_royalty_split: 'total_royalty_split',
            total_user_royalty_split: 'total_user_royalty_split'
        };
    }

    static get allowed_types_to_set_amount_from_product_price(): {[key: string]: string} {
        return {
            deposit_release: 'deposit_release',
            income: 'income',
            transfer: 'transfer',
            commission: 'commission',
            expense: 'expense',
            referral: 'referral',
            ancillary: 'ancillary',
            partner: 'partner',
            participant: 'participant',
            participant_split: 'participant_split',
            royalty: 'royalty',
            user_royalty: 'user_royalty',
            sales: 'sales',
            sales_individual: 'sales_individual',
            agent_split: 'agent_split',
            deduct_transfer: 'deduct_transfer',
            associate_transfer: 'associate_transfer'
        };
    }
}
