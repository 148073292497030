import {UntypedFormArray, AbstractControl, UntypedFormGroup, UntypedFormControl, ValidatorFn} from '@angular/forms';
import {forOwn} from 'lodash-es';
import {ILedgerAccount} from '@cyberco-nodejs/zipi-typings';
import {cleanCurrencyString} from './maskito';
import {canada, unitedStates} from '@skyslope/utils';

export function clearFormArray(far: UntypedFormArray) {
    while (far.length !== 0) {
        far.removeAt(0);
    }
}

export function centerCrapByElement(
    fullSizeArr: string[],
    colNum = 0,
    maxXSize = 0 // 0 - means no limint
): string[] {
    maxXSize = maxXSize === 0 ? fullSizeArr.length : fullSizeArr.length < maxXSize ? fullSizeArr.length : maxXSize;

    let beforeFrom, afterTo, lPad, rPad;
    let beforeLack = 0;

    const totalPad = maxXSize - 1;
    const isSymPad = totalPad % 2 === 0;

    if (isSymPad) {
        lPad = rPad = totalPad / 2;
    } else {
        lPad = rPad = (totalPad - 1) / 2;
        rPad = rPad + 1;
    }

    if (colNum < lPad) {
        beforeFrom = 0;
        beforeLack = lPad - colNum;
    } else {
        beforeFrom = colNum - lPad;
    }

    if (colNum + rPad < fullSizeArr.length) {
        afterTo = colNum + beforeLack + rPad + 1;
    } else {
        afterTo = fullSizeArr.length;
        beforeFrom = beforeFrom - (rPad - (fullSizeArr.length - 1 - colNum));
    }

    return [...fullSizeArr.slice(beforeFrom, colNum), fullSizeArr[colNum], ...fullSizeArr.slice(colNum + 1, afterTo)];
}

// export const makePropsList = pipe(values, flatten);

// IBaseImportConfigurations[] -> columnSlug[]
// export const getUniqMappedColumns = pipe(
//     map((ic: IBaseImportConfigurations) => keys(ic.columns_matching)),
//     reduce(concat, []),
//     uniq,
// );

export const convertNumberToMaskedStringWithRequireDecimals = (value: number) => {
    if (typeof value === 'number') {
        if (String(value).includes('.')) {
            const parts = String(value).split('.');
            parts[1] = parts[1].slice(0, 2);
            if (parts[1].length < 2) {
                while (parts[1].length < 2) {
                    parts[1] += '0';
                }
            }
            return '$' + parts.join('.');
        } else {
            return '$' + value + '.00';
        }
    }
};

export const customAmountValidator = (control: UntypedFormControl) => {
    const value = control.value;
    if (value === null || value === undefined) {
        return {invalidValue: true};
    }
    if (isNaN(Number(value))) {
        return {invalidValue: true};
    }
    return null;
};

export const nestedElementsByParent = (someArr: ILedgerAccount[]): ILedgerAccount[] => {
    const withChildren = someArr.map((el) => ({...el, children: []}));
    let level = 0;

    function getNestedChildren(arr: any, parentId: number | null, newLevel: any) {
        const out = [];
        for (const i in arr) {
            if (arr[i].parent__ledger_account_fk_id === parentId) {
                const children = getNestedChildren(arr, arr[i].ledger_account_id, newLevel + 1);

                if (children.length) {
                    arr[i].children = children;
                } else {
                    arr[i].children = [];
                }
                out.push({...arr[i], level: newLevel});
                level = 0;
            } else if (
                arr[i].parent__ledger_account_fk_id &&
                (arr[i].accessible_for.length > 0 || arr[i].is_accessible_for_all) &&
                !parentId
            ) {
                const hasParent = arr.some((a: any) => a.ledger_account_id === arr[i].parent__ledger_account_fk_id);

                if (!hasParent) {
                    const children = getNestedChildren(arr, arr[i].id, newLevel + 1);

                    arr[i].children = children;

                    out.push({...arr[i], level: 0});
                    level = 0;
                }
            }
        }
        return out;
    }

    return getNestedChildren(withChildren, null, level);
};

export const flattenChildrenDeep = (deepArr: ILedgerAccount[]): ILedgerAccount[] => {
    const newArr: any[] = [];

    function flattenInner(arr: any[]) {
        arr.forEach((el) => {
            newArr.push(el);
            if (el.children && el.children.length) {
                flattenInner(el.children);
            }
        });
        return newArr.map(({children, ...rest}) => ({...rest, hasChildren: !!(children && children.length)}));
    }

    return flattenInner(deepArr);
};
export const flattenSortChildrenDeep = (
    deepArr: ILedgerAccount[],
    sortActive: string,
    isAsc: boolean
): ILedgerAccount[] => {
    const newArr: any[] = [];

    function flattenInner(arr: any[]) {
        arr.forEach((el) => {
            newArr.push(el);
            if (el.children && el.children.length) {
                const sortedChildren = el.children.sort((a: {[key: string]: any}, b: {[key: string]: any}) => {
                    switch (sortActive) {
                        case 'name':
                            return compare(a.name, b.name, isAsc);
                        case 'code':
                            return compare(a.code, b.code, isAsc);
                        case 'type':
                            return compare(a.type, b.type, isAsc);
                        default:
                            return 0;
                    }
                });

                flattenInner(sortedChildren);
            }
        });
        return newArr.map(({children, ...rest}) => ({...rest, hasChildren: !!(children && children.length)}));
    }

    return flattenInner(deepArr);
};

export const compare = (a: number | string, b: number | string, isAsc: boolean) => {
    if (a === b) {
        return 0;
    }

    if (!a && b) {
        return -1 * (isAsc ? 1 : -1);
    }

    if (a && !b) {
        return isAsc ? 1 : -1;
    }

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
};

/**
 * Mark group for validation
 * @param FormControls
 */
export const markFormGroupTouched = (FormControls: {[key: string]: AbstractControl} | AbstractControl[]): void => {
    const markFormGroupTouchedRecursive = (controls: {[key: string]: AbstractControl} | AbstractControl[]): void => {
        forOwn(controls, (c: any) => {
            c.markAsTouched();
            if (c instanceof UntypedFormGroup || c instanceof UntypedFormArray) {
                markFormGroupTouchedRecursive(c.controls);
            }
        });
    };
    markFormGroupTouchedRecursive(FormControls);
};

export const formatToDate = (value: any): number => {
    // const date = moment(value).format('YYYY-MM-DD');

    let day: string = value.getDate().toString();
    day = +day < 10 ? '0' + day : day;
    let month: string = (value.getMonth() + 1).toString();
    month = +month < 10 ? '0' + month : month;
    const year = value.getFullYear();
    const dateStr = `${year}${month}${day}`;

    return Number(dateStr);
};

export const amountValidator = (): ValidatorFn => {
    return (control: AbstractControl): {[key: string]: any} | null => {
        if (!control.parent) {
            return null;
        }

        const value = Number(cleanCurrencyString(control.value));

        return value > 0 ? null : {amount: {value}};
    };
};

export const getBrowserName = () => {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
        case agent.indexOf('edge') > -1:
            return 'edge';
        case agent.indexOf('opr') > -1 && !!(<any>window).opr:
            return 'opera';
        case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
            return 'chrome';
        case agent.indexOf('trident') > -1:
            return 'ie';
        case agent.indexOf('firefox') > -1:
            return 'firefox';
        case agent.indexOf('safari') > -1:
            return 'safari';
        default:
            return 'other';
    }
};

export const statesAndTerritories = [
    ...unitedStates.regions,
    {name: 'American Somoa', abbreviation: 'AS'},
    {name: 'Federated States of Micronesia', abbreviation: 'FM'},
    {name: 'Guam', abbreviation: 'GU'},
    {name: 'Marshall Islands', abbreviation: 'MH'},
    {name: 'Commonwealth of the Northern Mariana Islands', abbreviation: 'MP'},
    {name: 'Palau', abbreviation: 'PW'},
    {name: 'Puerto Rico', abbreviation: 'PR'},
    {name: 'U.S. Virgin Islands', abbreviation: 'VI'}
];

export const countryList: Array<{[key: string]: any}> = [
    {label: unitedStates.name, slug: unitedStates.abbreviation},
    {label: canada.name, slug: canada.abbreviation}
];
