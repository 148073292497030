<mat-form-field class="w-100 report-autocomplete">
    <mat-chip-list #chipList aria-label="Tags selection">
        <ng-container *ngFor="let group of initialList">
            <ng-container *ngFor="let item of group['list']">
                <mat-chip *ngIf="value.includes(item[valueAttribute])" (removed)="remove(item[valueAttribute])">
                    {{ item[titleAttribute] }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </ng-container>
        </ng-container>
    </mat-chip-list>
    <input #input [matAutocomplete]="auto" [matChipInputFor]="chipList" (keyup)="search($event)" />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="select($event)">
        <mat-option value="none">&#45;&#45; None &#45;&#45;</mat-option>
        <mat-optgroup *ngFor="let group of list" [label]="group[groupNameAttribute]">
            <ng-container *ngFor="let item of group['list']">
                <mat-option [value]="item[valueAttribute]">
                    {{ item[titleAttribute] }}
                </mat-option>
            </ng-container>
        </mat-optgroup>
    </mat-autocomplete>
</mat-form-field>
