<div class="row mx-3" *ngIf="deal">
    <!---------------- Left column ---------------->
    <div class="col-6 mt-4">
        <!-------- PURCHASE SIDE -------->
        <div class="sub-column">
            <h3 class="text-uppercase primary-text font-weight-normal">PURCHASE SIDE</h3>

            <!-------- Card Buyer -------->
            <ng-container *ngIf="isObjectNotEmpty(deal.deal_participants.buyers)">
                <ng-container *ngFor="let buyer of deal.deal_participants.buyers; let idx = index">
                    <mat-card
                        class="contact-link d-flex align-items-center my-3"
                        *ngIf="buyer.format === 'contact' || (buyer.format === 'text' && !buyer.is_ignored)"
                    >
                        <img
                            class="card-avatar image-circle"
                            [src]="'assets/images/user-circle.svg'"
                            alt="others-avatar"
                        />
                        <div class="ml-3 d-flex justify-content-between" style="width: calc(100% - 72px)">
                            <div class="d-flex flex-column card-text w-50">
                                <div class="card-status primary-text">Buyer</div>
                                <!-- Buyer name -->
                                <div
                                    class="card-name hidden-contact"
                                    *ngIf="buyer.hidden && buyer.format === 'contact'"
                                >
                                    {{ buyer.contact_person?.full_name }}
                                </div>
                                <div
                                    class="card-name"
                                    [routerLink]="['/contacts', buyer.contact_id]"
                                    *ngIf="!buyer.hidden && buyer.format === 'contact'"
                                >
                                    {{ buyer.contact_person?.full_name }}
                                </div>
                                <div
                                    class="card-name hidden-contact"
                                    *ngIf="buyer.format === 'text' && !buyer.is_ignored"
                                >
                                    {{ buyer.link_title }}
                                </div>
                                <!-- Company Buyer Name -->
                                <div
                                    class="card-name hidden-contact"
                                    *ngIf="getCompanyName(buyer.contact) && buyer.hidden && buyer.format === 'contact'"
                                >
                                    {{ getCompanyName(buyer.contact) }}
                                </div>
                                <div
                                    class="card-name"
                                    *ngIf="getCompanyName(buyer.contact) && !buyer.hidden && buyer.format === 'contact'"
                                    [routerLink]="['/contacts', buyer.contact_id]"
                                >
                                    {{ getCompanyName(buyer.contact) }}
                                </div>
                            </div>

                            <div class="d-flex flex-column w-50 pl-3" *ngIf="buyer.format === 'contact'">
                                <!-- Tooltip copy to clipboard -->
                                <div
                                    class="client d-flex align-items-center person-email"
                                    [matTooltip]="buyer.contact_person?.email"
                                    matTooltipPosition="above"
                                    *ngIf="buyer.contact_person?.email"
                                    [cdkCopyToClipboard]="buyer.contact_person?.email || ''"
                                    [cdkCopyToClipboardAttempts]="5"
                                    (click)="setAttribute('buyer-' + idx, 'block')"
                                    (mouseleave)="setAttribute('buyer-' + idx, 'none')"
                                >
                                    <i class="material-icons material-icons-outlined">email</i>
                                    <span
                                        class="text-client email-wrap"
                                        [ngClass]="buyer.hidden ? 'hidden-contact' : ''"
                                    >
                                        {{ buyer.contact_person?.email }}
                                    </span>
                                    <span class="tooltiptext" id="buyer-{{ idx }}">Copied to clipboard</span>
                                </div>
                                <!-- Person Phone -->
                                <div
                                    class="client d-flex align-items-center"
                                    *ngIf="buyer.contact_person?.phone"
                                    [ngClass]="buyer.hidden ? 'hidden-contact' : ''"
                                >
                                    <i class="material-icons">smartphone</i>
                                    <span class="text-client text-break">{{
                                        buyer.contact_person?.phone | phoneMask
                                    }}</span>
                                </div>
                                <!-- Company Phone -->
                                <div
                                    class="client d-flex align-items-center"
                                    *ngIf="getCompanyPhone(buyer.contact)"
                                    [ngClass]="buyer.hidden ? 'hidden-contact' : ''"
                                >
                                    <i class="material-icons">smartphone</i>
                                    <span class="text-client text-break">{{
                                        getCompanyPhone(buyer.contact) | phoneMask
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </ng-container>
            </ng-container>

            <!-------- Card Buyer Agent -------->
            <ng-container *ngIf="isObjectNotEmpty(deal.deal_participants.buyer_agents)">
                <ng-container *ngFor="let buyer_agent of deal.deal_participants.buyer_agents; let idx = index">
                    <mat-card
                        class="contact-link d-flex align-items-center my-3"
                        *ngIf="
                            buyer_agent.format === 'contact' ||
                            (buyer_agent.format === 'text' && !buyer_agent.is_ignored)
                        "
                    >
                        <img
                            class="card-avatar image-circle"
                            [src]="'assets/images/user-circle.svg'"
                            alt="others-avatar"
                        />
                        <div class="ml-3 d-flex justify-content-between" style="width: calc(100% - 72px)">
                            <div class="d-flex flex-column card-text w-50">
                                <div class="card-status primary-text">Buyer Agent</div>
                                <!-- Buyer Agent name -->
                                <div
                                    class="card-name hidden-contact"
                                    *ngIf="buyer_agent.hidden && buyer_agent.format === 'contact'"
                                >
                                    {{ buyer_agent.contact_person?.full_name }}
                                </div>
                                <div
                                    class="card-name"
                                    [routerLink]="['/contacts', buyer_agent.contact_id]"
                                    *ngIf="!buyer_agent.hidden && buyer_agent.format === 'contact'"
                                >
                                    {{ buyer_agent.contact_person?.full_name }}
                                </div>
                                <div
                                    class="card-name hidden-contact"
                                    *ngIf="buyer_agent.format === 'text' && !buyer_agent.is_ignored"
                                >
                                    {{ buyer_agent.link_title }}
                                </div>
                                <!-- Buyer Agent Company name -->
                                <div
                                    class="card-name hidden-contact"
                                    *ngIf="
                                        getCompanyName(buyer_agent.contact) &&
                                        buyer_agent.hidden &&
                                        buyer_agent.format === 'contact'
                                    "
                                >
                                    {{ getCompanyName(buyer_agent.contact) }}
                                </div>
                                <div
                                    class="card-name"
                                    *ngIf="
                                        getCompanyName(buyer_agent.contact) &&
                                        !buyer_agent.hidden &&
                                        buyer_agent.format === 'contact'
                                    "
                                    [routerLink]="['/contacts', buyer_agent.contact_id]"
                                >
                                    {{ getCompanyName(buyer_agent.contact) }}
                                </div>
                            </div>

                            <div class="d-flex flex-column w-50 pl-3" *ngIf="buyer_agent.format === 'contact'">
                                <!-- Tooltip copy to clipboard -->
                                <div
                                    class="client d-flex align-items-center person-email"
                                    [matTooltip]="buyer_agent.contact_person?.email"
                                    matTooltipPosition="above"
                                    *ngIf="buyer_agent.contact_person?.email"
                                    [cdkCopyToClipboard]="buyer_agent.contact_person?.email || ''"
                                    [cdkCopyToClipboardAttempts]="5"
                                    (click)="setAttribute('buyer-agent-' + idx, 'block')"
                                    (mouseleave)="setAttribute('buyer-agent-' + idx, 'none')"
                                >
                                    <i class="material-icons material-icons-outlined">email</i>
                                    <span
                                        class="text-client email-wrap"
                                        [ngClass]="buyer_agent.hidden ? 'hidden-contact' : ''"
                                    >
                                        {{ buyer_agent.contact_person?.email }}
                                    </span>
                                    <span class="tooltiptext" id="buyer-agent-{{ idx }}">Copied to clipboard</span>
                                </div>
                                <!-- Person Phone -->
                                <div class="client d-flex align-items-center" *ngIf="buyer_agent.contact_person?.phone">
                                    <i class="material-icons">smartphone</i>
                                    <span
                                        class="text-client text-break"
                                        [ngClass]="buyer_agent.hidden ? 'hidden-contact' : ''"
                                    >
                                        {{ buyer_agent.contact_person?.phone | phoneMask }}
                                    </span>
                                </div>
                                <!-- Company Phone -->
                                <div
                                    class="client d-flex align-items-center"
                                    *ngIf="getCompanyPhone(buyer_agent.contact)"
                                >
                                    <i class="material-icons">smartphone</i>
                                    <span
                                        class="text-client text-break"
                                        [ngClass]="buyer_agent.hidden ? 'hidden-contact' : ''"
                                    >
                                        {{ getCompanyPhone(buyer_agent.contact) | phoneMask }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </ng-container>
            </ng-container>

            <!-------- Card Referring to Agent -------->
            <ng-container
                *ngIf="
                    deal.deal_participants.referring_to_agent &&
                    isObjectNotEmpty(deal.deal_participants.referring_to_agent)
                "
            >
                <mat-card
                    class="contact-link d-flex align-items-center my-3"
                    *ngIf="
                        deal.deal_participants.referring_to_agent.format === 'contact' ||
                        (deal.deal_participants.referring_to_agent.format === 'text' &&
                            !deal.deal_participants.referring_to_agent.is_ignored)
                    "
                >
                    <img class="card-avatar image-circle" [src]="'assets/images/user-circle.svg'" alt="others-avatar" />
                    <div class="ml-3 d-flex justify-content-between" style="width: calc(100% - 72px)">
                        <div class="d-flex flex-column card-text w-50">
                            <div class="card-status primary-text">Referring to Agent</div>
                            <!-- Referring to Agent name -->
                            <div
                                class="card-name hidden-contact"
                                *ngIf="
                                    deal.deal_participants.referring_to_agent.hidden &&
                                    deal.deal_participants.referring_to_agent.format === 'contact'
                                "
                            >
                                {{ deal.deal_participants.referring_to_agent.contact_person?.full_name }}
                            </div>
                            <div
                                class="card-name"
                                [routerLink]="['/contacts', deal.deal_participants.referring_to_agent.contact_id]"
                                *ngIf="
                                    !deal.deal_participants.referring_to_agent.hidden &&
                                    deal.deal_participants.referring_to_agent.format === 'contact'
                                "
                            >
                                {{ deal.deal_participants.referring_to_agent.contact_person?.full_name }}
                            </div>
                            <div
                                class="card-name hidden-contact"
                                *ngIf="
                                    deal.deal_participants.referring_to_agent.format === 'text' &&
                                    !deal.deal_participants.referring_to_agent.is_ignored
                                "
                            >
                                {{ deal.deal_participants.referring_to_agent.link_title }}
                            </div>
                            <!-- Referring to Agent Company name -->
                            <div
                                class="card-name hidden-contact"
                                *ngIf="
                                    getCompanyName(deal.deal_participants.referring_to_agent.contact) &&
                                    deal.deal_participants.referring_to_agent.hidden &&
                                    deal.deal_participants.referring_to_agent.format === 'contact'
                                "
                            >
                                {{ getCompanyName(deal.deal_participants.referring_to_agent.contact) }}
                            </div>
                            <div
                                class="card-name"
                                [routerLink]="['/contacts', deal.deal_participants.referring_to_agent.contact_id]"
                                *ngIf="
                                    getCompanyName(deal.deal_participants.referring_to_agent.contact) &&
                                    !deal.deal_participants.referring_to_agent.hidden &&
                                    deal.deal_participants.referring_to_agent.format === 'contact'
                                "
                            >
                                {{ getCompanyName(deal.deal_participants.referring_to_agent.contact) }}
                            </div>
                        </div>

                        <div
                            class="d-flex flex-column w-50 pl-3"
                            *ngIf="deal.deal_participants.referring_to_agent.format === 'contact'"
                        >
                            <!-- Tooltip copy to clipboard -->
                            <div
                                class="client d-flex align-items-center person-email"
                                [matTooltip]="deal.deal_participants.referring_to_agent.contact_person?.email"
                                matTooltipClass="participants-tooltip"
                                matTooltipPosition="above"
                                *ngIf="deal.deal_participants.referring_to_agent.contact_person?.email"
                                [cdkCopyToClipboard]="
                                    deal.deal_participants.referring_to_agent.contact_person?.email || ''
                                "
                                [cdkCopyToClipboardAttempts]="5"
                                (click)="tooltipFakeReferringTo.setAttribute('style', 'display: block;')"
                                (mouseleave)="tooltipFakeReferringTo.setAttribute('style', 'display: none;')"
                            >
                                <i class="material-icons material-icons-outlined">email</i>
                                <span
                                    class="text-client email-wrap"
                                    [ngClass]="deal.deal_participants.referring_to_agent.hidden ? 'hidden-contact' : ''"
                                >
                                    {{ deal.deal_participants.referring_to_agent.contact_person?.email }}
                                </span>
                                <span class="tooltiptext" #tooltipFakeReferringTo>Copied to clipboard</span>
                            </div>
                            <!-- Person Phone -->
                            <div
                                class="client d-flex align-items-center"
                                *ngIf="deal.deal_participants.referring_to_agent.contact_person?.phone"
                            >
                                <i class="material-icons">smartphone</i>
                                <span
                                    class="text-client text-break"
                                    [ngClass]="deal.deal_participants.referring_to_agent.hidden ? 'hidden-contact' : ''"
                                >
                                    {{ deal.deal_participants.referring_to_agent.contact_person?.phone | phoneMask }}
                                </span>
                            </div>
                            <!-- Company Phone -->
                            <div
                                class="client d-flex align-items-center"
                                *ngIf="getCompanyPhone(deal.deal_participants.referring_to_agent.contact)"
                            >
                                <i class="material-icons">smartphone</i>
                                <span
                                    class="text-client text-break"
                                    [ngClass]="deal.deal_participants.referring_to_agent.hidden ? 'hidden-contact' : ''"
                                >
                                    {{ getCompanyPhone(deal.deal_participants.referring_to_agent.contact) | phoneMask }}
                                </span>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </ng-container>

            <ng-container
                *ngIf="
                    (!isObjectNotEmpty(deal.deal_participants.buyers) ||
                        checkIfAllItemsInTextFormatIsIgnored(deal.deal_participants.buyers)) &&
                    (!isObjectNotEmpty(deal.deal_participants.buyer_agents) ||
                        checkIfAllItemsInTextFormatIsIgnored(deal.deal_participants.buyer_agents)) &&
                    (!isObjectNotEmpty(deal.deal_participants.referring_to_agent) ||
                        (deal.deal_participants.referring_to_agent &&
                            deal.deal_participants.referring_to_agent.format === 'text' &&
                            deal.deal_participants.referring_to_agent.is_ignored))
                "
            >
                <mat-card class="d-flex justify-content-center align-items-center my-3">
                    <span class="comment-text">Empty</span>
                </mat-card>
            </ng-container>

            <!-------- Card Broker -------->
        </div>
    </div>

    <!---------------- Right column ---------------->
    <div class="col-6 mt-4">
        <!-------- LISTING SIDE -------->
        <div class="sub-column">
            <h3 class="text-uppercase primary-text font-weight-normal">LISTING SIDE</h3>

            <!-------- Card Seller -------->
            <ng-container *ngIf="isObjectNotEmpty(deal.deal_participants.sellers)">
                <ng-container *ngFor="let seller of deal.deal_participants.sellers; let idx = index">
                    <mat-card
                        class="contact-link d-flex align-items-center my-3"
                        *ngIf="seller.format === 'contact' || (seller.format === 'text' && !seller.is_ignored)"
                    >
                        <img
                            class="card-avatar image-circle"
                            [src]="'assets/images/user-circle.svg'"
                            alt="others-avatar"
                        />
                        <div class="ml-3 d-flex justify-content-between" style="width: calc(100% - 72px)">
                            <div class="d-flex flex-column card-text w-50">
                                <div class="card-status primary-text">Seller</div>
                                <!-- Seller name -->
                                <div
                                    class="card-name hidden-contact"
                                    *ngIf="seller.hidden && seller.format === 'contact'"
                                >
                                    {{ seller.contact_person?.full_name }}
                                </div>
                                <div
                                    class="card-name"
                                    [routerLink]="['/contacts', seller.contact_id]"
                                    *ngIf="!seller.hidden && seller.format === 'contact'"
                                >
                                    {{ seller.contact_person?.full_name }}
                                </div>
                                <div
                                    class="card-name hidden-contact"
                                    *ngIf="seller.format === 'text' && !seller.is_ignored"
                                >
                                    {{ seller.link_title }}
                                </div>
                                <!-- Seller Company name -->
                                <div
                                    class="card-name hidden-contact"
                                    *ngIf="
                                        getCompanyName(seller.contact) && seller.hidden && seller.format === 'contact'
                                    "
                                >
                                    {{ getCompanyName(seller.contact) }}
                                </div>
                                <div
                                    class="card-name"
                                    [routerLink]="['/contacts', seller.contact_id]"
                                    *ngIf="
                                        getCompanyName(seller.contact) && !seller.hidden && seller.format === 'contact'
                                    "
                                >
                                    {{ getCompanyName(seller.contact) }}
                                </div>
                            </div>

                            <div class="d-flex flex-column w-50 pl-3" *ngIf="seller.format === 'contact'">
                                <!-- Tooltip copy to clipboard -->
                                <div
                                    class="client d-flex align-items-center person-email"
                                    [matTooltip]="seller.contact_person?.email"
                                    matTooltipPosition="above"
                                    *ngIf="seller.contact_person?.email"
                                    [cdkCopyToClipboard]="seller.contact_person?.email || ''"
                                    [cdkCopyToClipboardAttempts]="5"
                                    (click)="setAttribute('seller-' + idx, 'block')"
                                    (mouseleave)="setAttribute('seller-' + idx, 'none')"
                                >
                                    <i class="material-icons material-icons-outlined">email</i>
                                    <span
                                        class="text-client email-wrap"
                                        [ngClass]="seller.hidden ? 'hidden-contact' : ''"
                                    >
                                        {{ seller.contact_person?.email }}
                                    </span>
                                    <span class="tooltiptext" id="seller-{{ idx }}">Copied to clipboard</span>
                                </div>
                                <!-- Person Phone -->
                                <div class="client d-flex align-items-center" *ngIf="seller.contact_person?.phone">
                                    <i class="material-icons">smartphone</i>
                                    <span
                                        class="text-client text-break"
                                        [ngClass]="seller.hidden ? 'hidden-contact' : ''"
                                    >
                                        {{ seller.contact_person?.phone | phoneMask }}
                                    </span>
                                </div>
                                <!-- Company Phone -->
                                <div class="client d-flex align-items-center" *ngIf="getCompanyPhone(seller.contact)">
                                    <i class="material-icons">smartphone</i>
                                    <span
                                        class="text-client text-break"
                                        [ngClass]="seller.hidden ? 'hidden-contact' : ''"
                                    >
                                        {{ getCompanyPhone(seller.contact) | phoneMask }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </ng-container>
            </ng-container>

            <!-------- Card Seller Agent -------->
            <ng-container *ngIf="isObjectNotEmpty(deal.deal_participants.seller_agents)">
                <ng-container *ngFor="let seller_agent of deal.deal_participants.seller_agents; let idx = index">
                    <mat-card
                        class="contact-link d-flex align-items-center my-3"
                        *ngIf="
                            seller_agent.format === 'contact' ||
                            (seller_agent.format === 'text' && !seller_agent.is_ignored)
                        "
                    >
                        <img
                            class="card-avatar image-circle"
                            [src]="'assets/images/user-circle.svg'"
                            alt="others-avatar"
                        />
                        <div class="ml-3 d-flex justify-content-between" style="width: calc(100% - 72px)">
                            <div class="d-flex flex-column card-text w-50">
                                <div class="card-status primary-text">Listing Agent</div>
                                <!-- Seller Agent name -->
                                <div
                                    class="card-name hidden-contact"
                                    *ngIf="seller_agent.hidden && seller_agent.format === 'contact'"
                                >
                                    {{ seller_agent.contact_person?.full_name }}
                                </div>
                                <div
                                    class="card-name"
                                    [routerLink]="['/contacts', seller_agent.contact_id]"
                                    *ngIf="!seller_agent.hidden && seller_agent.format === 'contact'"
                                >
                                    {{ seller_agent.contact_person?.full_name }}
                                </div>
                                <div
                                    class="card-name hidden-contact"
                                    *ngIf="seller_agent.format === 'text' && !seller_agent.is_ignored"
                                >
                                    {{ seller_agent.link_title }}
                                </div>
                                <!-- Seller Agent Company name -->
                                <div
                                    class="card-name hidden-contact"
                                    *ngIf="
                                        getCompanyName(seller_agent.contact) &&
                                        seller_agent.hidden &&
                                        seller_agent.format === 'contact'
                                    "
                                >
                                    {{ getCompanyName(seller_agent.contact) }}
                                </div>
                                <div
                                    class="card-name"
                                    [routerLink]="['/contacts', seller_agent.contact_id]"
                                    *ngIf="
                                        getCompanyName(seller_agent.contact) &&
                                        !seller_agent.hidden &&
                                        seller_agent.format === 'contact'
                                    "
                                >
                                    {{ getCompanyName(seller_agent.contact) }}
                                </div>
                            </div>

                            <div class="d-flex flex-column w-50 pl-3" *ngIf="seller_agent.format === 'contact'">
                                <!-- Tooltip copy to clipboard -->
                                <div
                                    class="client d-flex align-items-center person-email"
                                    [matTooltip]="seller_agent.contact_person?.email"
                                    matTooltipPosition="above"
                                    *ngIf="seller_agent.contact_person?.email"
                                    [cdkCopyToClipboard]="seller_agent.contact_person?.email || ''"
                                    [cdkCopyToClipboardAttempts]="5"
                                    (click)="setAttribute('seller-agent-' + idx, 'block')"
                                    (mouseleave)="setAttribute('seller-agent-' + idx, 'none')"
                                >
                                    <i class="material-icons material-icons-outlined">email</i>
                                    <span
                                        class="text-client email-wrap"
                                        [ngClass]="seller_agent.hidden ? 'hidden-contact' : ''"
                                    >
                                        {{ seller_agent.contact_person?.email }}
                                    </span>
                                    <span class="tooltiptext" id="seller-agent-{{ idx }}">Copied to clipboard</span>
                                </div>
                                <!-- Person Phone -->
                                <div
                                    class="client d-flex align-items-center"
                                    *ngIf="seller_agent.contact_person?.phone"
                                >
                                    <i class="material-icons">smartphone</i>
                                    <span
                                        class="text-client text-break"
                                        [ngClass]="seller_agent.hidden ? 'hidden-contact' : ''"
                                    >
                                        {{ seller_agent.contact_person?.phone | phoneMask }}
                                    </span>
                                </div>
                                <!-- Company Phone -->
                                <div
                                    class="client d-flex align-items-center"
                                    *ngIf="getCompanyPhone(seller_agent.contact)"
                                >
                                    <i class="material-icons">smartphone</i>
                                    <span
                                        class="text-client text-break"
                                        [ngClass]="seller_agent.hidden ? 'hidden-contact' : ''"
                                    >
                                        {{ getCompanyPhone(seller_agent.contact) | phoneMask }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </ng-container>
            </ng-container>

            <!-------- Card Referring from Agent -------->
            <ng-container
                *ngIf="
                    deal.deal_participants.referring_from_agent &&
                    isObjectNotEmpty(deal.deal_participants.referring_from_agent)
                "
            >
                <mat-card
                    class="contact-link d-flex align-items-center my-3"
                    *ngIf="
                        deal.deal_participants.referring_from_agent.format === 'contact' ||
                        (deal.deal_participants.referring_from_agent.format === 'text' &&
                            !deal.deal_participants.referring_from_agent.is_ignored)
                    "
                >
                    <img class="card-avatar image-circle" [src]="'assets/images/user-circle.svg'" alt="others-avatar" />
                    <div class="ml-3 d-flex justify-content-between" style="width: calc(100% - 72px)">
                        <div class="d-flex flex-column card-text w-50">
                            <div class="card-status primary-text">Referring from Agent</div>
                            <!-- Referring from Agent name -->
                            <div
                                class="card-name hidden-contact"
                                *ngIf="
                                    deal.deal_participants.referring_from_agent.hidden &&
                                    deal.deal_participants.referring_from_agent.format === 'contact'
                                "
                            >
                                {{ deal.deal_participants.referring_from_agent.contact_person?.full_name }}
                            </div>
                            <div
                                class="card-name"
                                [routerLink]="['/contacts', deal.deal_participants.referring_from_agent.contact_id]"
                                *ngIf="
                                    !deal.deal_participants.referring_from_agent.hidden &&
                                    deal.deal_participants.referring_from_agent.format === 'contact'
                                "
                            >
                                {{ deal.deal_participants.referring_from_agent.contact_person?.full_name }}
                            </div>
                            <div
                                class="card-name hidden-contact"
                                *ngIf="
                                    deal.deal_participants.referring_from_agent.format === 'text' &&
                                    !deal.deal_participants.referring_from_agent.is_ignored
                                "
                            >
                                {{ deal.deal_participants.referring_from_agent.link_title }}
                            </div>
                            <!-- Referring to Agent Company name -->
                            <div
                                class="card-name hidden-contact"
                                *ngIf="
                                    getCompanyName(deal.deal_participants.referring_from_agent.contact) &&
                                    deal.deal_participants.referring_from_agent.hidden &&
                                    deal.deal_participants.referring_from_agent.format === 'contact'
                                "
                            >
                                {{ getCompanyName(deal.deal_participants.referring_from_agent.contact) }}
                            </div>
                            <div
                                class="card-name"
                                [routerLink]="['/contacts', deal.deal_participants.referring_from_agent.contact_id]"
                                *ngIf="
                                    getCompanyName(deal.deal_participants.referring_from_agent.contact) &&
                                    !deal.deal_participants.referring_from_agent.hidden &&
                                    deal.deal_participants.referring_from_agent.format === 'contact'
                                "
                            >
                                {{ getCompanyName(deal.deal_participants.referring_from_agent.contact) }}
                            </div>
                        </div>

                        <div
                            class="d-flex flex-column w-50 pl-3"
                            *ngIf="deal.deal_participants.referring_from_agent.format === 'contact'"
                        >
                            <!-- Tooltip copy to clipboard -->
                            <div
                                class="client d-flex align-items-center person-email"
                                [matTooltip]="deal.deal_participants.referring_from_agent.contact_person?.email"
                                matTooltipPosition="above"
                                *ngIf="deal.deal_participants.referring_from_agent.contact_person?.email"
                                [cdkCopyToClipboard]="
                                    deal.deal_participants.referring_from_agent.contact_person?.email || ''
                                "
                                [cdkCopyToClipboardAttempts]="5"
                                (click)="tooltipFakeReferringFrom.setAttribute('style', 'display: block;')"
                                (mouseleave)="tooltipFakeReferringFrom.setAttribute('style', 'display: none;')"
                            >
                                <i class="material-icons material-icons-outlined">email</i>
                                <span
                                    class="text-client email-wrap"
                                    [ngClass]="
                                        deal.deal_participants.referring_from_agent.hidden ? 'hidden-contact' : ''
                                    "
                                >
                                    {{ deal.deal_participants.referring_from_agent.contact_person?.email }}
                                </span>
                                <span class="tooltiptext" #tooltipFakeReferringFrom>Copied to clipboard</span>
                            </div>
                            <!-- Person Phone -->
                            <div
                                class="client d-flex align-items-center"
                                *ngIf="deal.deal_participants.referring_from_agent.contact_person?.phone"
                            >
                                <i class="material-icons">smartphone</i>
                                <span
                                    class="text-client text-break"
                                    [ngClass]="
                                        deal.deal_participants.referring_from_agent.hidden ? 'hidden-contact' : ''
                                    "
                                >
                                    {{ deal.deal_participants.referring_from_agent.contact_person?.phone | phoneMask }}
                                </span>
                            </div>
                            <!-- Company Phone -->
                            <div
                                class="client d-flex align-items-center"
                                *ngIf="getCompanyPhone(deal.deal_participants.referring_from_agent.contact)"
                            >
                                <i class="material-icons">smartphone</i>
                                <span
                                    class="text-client text-break"
                                    [ngClass]="
                                        deal.deal_participants.referring_from_agent.hidden ? 'hidden-contact' : ''
                                    "
                                >
                                    {{
                                        getCompanyPhone(deal.deal_participants.referring_from_agent.contact) | phoneMask
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </ng-container>

            <ng-container
                *ngIf="
                    (!isObjectNotEmpty(deal.deal_participants.sellers) ||
                        checkIfAllItemsInTextFormatIsIgnored(deal.deal_participants.sellers)) &&
                    (!isObjectNotEmpty(deal.deal_participants.seller_agents) ||
                        checkIfAllItemsInTextFormatIsIgnored(deal.deal_participants.seller_agents)) &&
                    (!isObjectNotEmpty(deal.deal_participants.referring_from_agent) ||
                        (deal.deal_participants.referring_from_agent &&
                            deal.deal_participants.referring_from_agent.format === 'text' &&
                            deal.deal_participants.referring_from_agent.is_ignored))
                "
            >
                <mat-card class="d-flex justify-content-center align-items-center my-3">
                    <span class="comment-text">Empty</span>
                </mat-card>
            </ng-container>

            <!-------- Card Broker -------->
        </div>
    </div>
</div>

<div class="row mx-3" *ngIf="deal">
    <!---------------- Left column ---------------->
    <div class="col-6">
        <!-------- CLOSING COMPANY -------->
        <div class="sub-column mb-4">
            <h3 class="text-uppercase primary-text font-weight-normal">CLOSING COMPANY</h3>

            <!-------- Card Company & Representative -------->
            <ng-container *ngIf="deal.deal_participants.escrow_agent; else noEscrowAgent">
                <mat-card
                    class="contact-link d-flex align-items-center my-3"
                    *ngIf="
                        deal.deal_participants.escrow_agent.format === 'contact' ||
                            (deal.deal_participants.escrow_agent.format === 'text' &&
                                !deal.deal_participants.escrow_agent.is_ignored);
                        else noEscrowAgent
                    "
                >
                    <img
                        class="card-avatar image-circle"
                        [src]="'assets/images/user-circle.svg'"
                        alt="company-avatar"
                    />

                    <div class="ml-3 d-flex justify-content-between" style="width: calc(100% - 72px)">
                        <!-- Representative or Company -->
                        <div
                            class="d-flex flex-column card-text w-50"
                            *ngIf="deal.deal_participants.escrow_agent.format === 'contact'"
                        >
                            <div
                                *ngIf="deal.deal_participants.escrow_agent.contact_person"
                                class="card-status primary-text"
                            >
                                Representative
                            </div>
                            <div
                                *ngIf="!deal.deal_participants.escrow_agent.contact_person"
                                class="card-status primary-text"
                            >
                                Company
                            </div>
                            <div
                                *ngIf="
                                    deal.deal_participants.escrow_agent.contact_person &&
                                    deal.deal_participants.escrow_agent.hidden
                                "
                                class="card-name hidden-contact"
                            >
                                {{ deal.deal_participants.escrow_agent.contact_person?.full_name }}
                            </div>
                            <div
                                *ngIf="
                                    deal.deal_participants.escrow_agent.contact_person &&
                                    deal.deal_participants.escrow_agent.contact &&
                                    !deal.deal_participants.escrow_agent.hidden &&
                                    deal.deal_participants.escrow_agent.contact.contact_id
                                "
                                class="card-name"
                                [routerLink]="['/contacts', deal.deal_participants.escrow_agent.contact.contact_id]"
                            >
                                {{ deal.deal_participants.escrow_agent.contact_person?.full_name }}
                            </div>

                            <div
                                class="card-name hidden-contact"
                                *ngIf="
                                    getCompanyName(deal.deal_participants.escrow_agent.contact) &&
                                    deal.deal_participants.escrow_agent.hidden
                                "
                            >
                                {{ getCompanyName(deal.deal_participants.escrow_agent.contact) }}
                            </div>
                            <div
                                class="card-name"
                                [routerLink]="['/contacts', deal.deal_participants.escrow_agent.contact.contact_id]"
                                *ngIf="
                                    getCompanyName(deal.deal_participants.escrow_agent.contact) &&
                                    !deal.deal_participants.escrow_agent.hidden &&
                                    deal.deal_participants.escrow_agent.contact &&
                                    deal.deal_participants.escrow_agent.contact.contact_id
                                "
                            >
                                {{ getCompanyName(deal.deal_participants.escrow_agent.contact) }}
                            </div>
                        </div>
                        <!-- Text format -->
                        <div
                            class="card-text w-50"
                            *ngIf="
                                deal.deal_participants.escrow_agent.format === 'text' &&
                                !deal.deal_participants.escrow_agent.is_ignored
                            "
                        >
                            <div class="card-status primary-text">Company</div>
                            <div class="card-name hidden-contact">
                                {{ deal.deal_participants.escrow_agent.link_title }}
                            </div>
                        </div>

                        <div
                            class="d-flex flex-column w-50 pl-3"
                            *ngIf="deal.deal_participants.escrow_agent.format === 'contact'"
                        >
                            <!-- Tooltip copy to clipboard -->
                            <div
                                class="client d-flex align-items-center person-email"
                                [matTooltip]="deal.deal_participants.escrow_agent.contact_person?.email"
                                matTooltipPosition="above"
                                *ngIf="deal.deal_participants.escrow_agent.contact_person?.email"
                                [cdkCopyToClipboard]="deal.deal_participants.escrow_agent.contact_person?.email || ''"
                                [cdkCopyToClipboardAttempts]="5"
                                (click)="tooltipFakeCompany.setAttribute('style', 'display: block;')"
                                (mouseleave)="tooltipFakeCompany.setAttribute('style', 'display: none;')"
                            >
                                <i class="material-icons material-icons-outlined">email</i>
                                <span
                                    class="text-client email-wrap"
                                    [ngClass]="deal.deal_participants.escrow_agent.hidden ? 'hidden-contact' : ''"
                                >
                                    {{ deal.deal_participants.escrow_agent.contact_person?.email }}
                                </span>
                                <span class="tooltiptext" #tooltipFakeCompany>Copied to clipboard</span>
                            </div>
                            <!-- Person Phone -->
                            <div
                                class="client d-flex align-items-center"
                                *ngIf="deal.deal_participants.escrow_agent.contact_person?.phone"
                            >
                                <i class="material-icons">smartphone</i>
                                <span
                                    class="text-client text-break"
                                    [ngClass]="deal.deal_participants.escrow_agent.hidden ? 'hidden-contact' : ''"
                                >
                                    {{ deal.deal_participants.escrow_agent.contact_person?.phone | phoneMask }}
                                </span>
                            </div>
                            <!-- Company Phone -->
                            <div
                                class="client d-flex align-items-center"
                                *ngIf="getCompanyPhone(deal.deal_participants.escrow_agent.contact)"
                            >
                                <i class="material-icons">smartphone</i>
                                <span
                                    class="text-client text-break"
                                    [ngClass]="deal.deal_participants.escrow_agent.hidden ? 'hidden-contact' : ''"
                                >
                                    {{ getCompanyPhone(deal.deal_participants.escrow_agent.contact) | phoneMask }}
                                </span>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </ng-container>

            <ng-template #noEscrowAgent>
                <mat-card class="d-flex justify-content-center align-items-center my-3">
                    <span class="comment-text">Empty</span>
                </mat-card>
            </ng-template>
        </div>
    </div>

    <!---------------- Right column ---------------->
    <div class="col-6">
        <!-------- OTHERS -------->
        <div class="sub-column mb-4">
            <h3 class="text-uppercase primary-text font-weight-normal">OTHERS</h3>

            <!-------- Card Other -------->
            <ng-container *ngIf="isObjectNotEmpty(deal.deal_participants.others); else noOthers">
                <ng-container *ngFor="let other of deal.deal_participants.others">
                    <mat-card
                        class="contact-link d-flex align-items-center my-3"
                        *ngIf="
                            other.format === 'contact' || (other.format === 'text' && !other.is_ignored);
                            else noOthers
                        "
                    >
                        <img
                            class="card-avatar image-circle"
                            [src]="'assets/images/user-circle.svg'"
                            alt="others-avatar"
                        />
                        <div class="ml-3 d-flex justify-content-between" style="width: calc(100% - 72px)">
                            <div class="d-flex flex-column card-text w-50">
                                <!-- Other name -->
                                <div
                                    class="card-name hidden-contact"
                                    *ngIf="other.hidden && other.format === 'contact'"
                                >
                                    {{ other.contact_person?.full_name }}
                                </div>
                                <div
                                    class="card-name"
                                    [routerLink]="['/contacts', other.contact_id]"
                                    *ngIf="!other.hidden && other.format === 'contact'"
                                >
                                    {{ other.contact_person?.full_name }}
                                </div>
                                <div
                                    class="card-name hidden-contact"
                                    *ngIf="other.format === 'text' && !other.is_ignored"
                                >
                                    {{ other.link_title }}
                                </div>
                                <!-- Other Company name -->
                                <div
                                    class="card-name hidden-contact"
                                    *ngIf="getCompanyName(other.contact) && other.hidden && other.format === 'contact'"
                                >
                                    {{ getCompanyName(other.contact) }}
                                </div>
                                <div
                                    class="card-name"
                                    [routerLink]="['/contacts', other.contact_id]"
                                    *ngIf="getCompanyName(other.contact) && !other.hidden && other.format === 'contact'"
                                >
                                    {{ getCompanyName(other.contact) }}
                                </div>
                            </div>

                            <div class="d-flex flex-column w-50 pl-3" *ngIf="other.format === 'contact'">
                                <!-- Tooltip copy to clipboard -->
                                <div
                                    class="client d-flex align-items-center person-email"
                                    *ngIf="other.contact_person?.email"
                                    [matTooltip]="other.contact_person?.email"
                                    matTooltipPosition="above"
                                    [cdkCopyToClipboard]="other.contact_person?.email || ''"
                                    [cdkCopyToClipboardAttempts]="5"
                                    (click)="tooltipFakeOthers.setAttribute('style', 'display: block;')"
                                    (mouseleave)="tooltipFakeOthers.setAttribute('style', 'display: none;')"
                                >
                                    <i class="material-icons material-icons-outlined">email</i>
                                    <span
                                        class="text-client email-wrap"
                                        [ngClass]="other.hidden ? 'hidden-contact' : ''"
                                    >
                                        {{ other.contact_person?.email }}
                                    </span>
                                    <span class="tooltiptext" #tooltipFakeOthers>Copied to clipboard</span>
                                </div>
                                <!-- Person Phone -->
                                <div class="client d-flex align-items-center" *ngIf="other.contact_person?.phone">
                                    <i class="material-icons">smartphone</i>
                                    <span
                                        class="text-client text-break"
                                        [ngClass]="other.hidden ? 'hidden-contact' : ''"
                                    >
                                        {{ other.contact_person?.phone | phoneMask }}
                                    </span>
                                </div>
                                <!-- Company Phone -->
                                <div class="client d-flex align-items-center" *ngIf="getCompanyPhone(other.contact)">
                                    <i class="material-icons">smartphone</i>
                                    <span
                                        class="text-client text-break"
                                        [ngClass]="other.hidden ? 'hidden-contact' : ''"
                                    >
                                        {{ getCompanyPhone(other.contact) | phoneMask }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </ng-container>
            </ng-container>

            <ng-template #noOthers>
                <mat-card class="d-flex justify-content-center align-items-center my-3">
                    <span class="comment-text">Empty</span>
                </mat-card>
            </ng-template>
        </div>
    </div>
</div>
