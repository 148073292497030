import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-add-category-dialog',
    templateUrl: './add-category-dialog.component.html',
    styleUrls: ['./add-category-dialog.component.css']
})
export class AddCategoryDialogComponent implements OnInit {
    categoryName: string = '';
    categoryType: string = '';
    isShowCategoryType: boolean = false;
    categoryTypes: Array<{title: string; value: string}> = [
        {
            title: 'Personal',
            value: 'personal'
        },
        {
            title: 'Company',
            value: 'company'
        }
    ];

    constructor(
        public dialogRef: MatDialogRef<AddCategoryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public modeParams: any
    ) {}

    ngOnInit() {
        this.isShowCategoryType = !!(this.modeParams && this.modeParams.isShowCategoryType);
    }

    saveCategory() {
        this.dialogRef.close({
            categoryName: this.categoryName,
            categoryType: this.categoryType
        });
    }

    close() {
        this.dialogRef.close();
    }
}
