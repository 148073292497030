<section class="container-fluid">
    <header class="my-2">
        <h1 *ngIf="!paymentMade">Create Payment Made</h1>
        <h1 *ngIf="paymentMade">Edit Payment Made</h1>
    </header>

    <div [formGroup]="formGroup" class="m-3">
        <div class="row mr-0">
            <div class="col col-sm-4">
                <div class="d-flex align-items-baseline">
                    <app-company-compensation-combined-picker
                        style="width: 100%; margin-right: 8px"
                        [availableTypes]="['contact']"
                        [title]="'Vendor Name'"
                        [only_compensation_groups]="false"
                        [singleSelected]="true"
                        [bold_style]="false"
                        [nodesFA]="moneySenderCtrlArr"
                        [initialContactIds]="savedContacts"
                    >
                    </app-company-compensation-combined-picker>

                    <button
                        type="button"
                        mat-icon-button
                        matSuffix
                        (click)="contactCreate()"
                        matTooltip="Create Contact"
                        [anyRule]="[{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]"
                        rbac
                        [disabled]="!!billCheckId || !!paymentMadeId"
                    >
                        <mat-icon>person_add</mat-icon>
                    </button>
                </div>
            </div>

            <mat-form-field class="col col-sm-4">
                <input matInput formControlName="reference" placeholder="Reference#" type="text" autocomplete="off" />
            </mat-form-field>

            <app-date-picker
                class="col col-sm-4"
                [placeholder]="'Date'"
                [dateControl]="formGroup.controls.paid_date"
                [disabled]="isEditFormFullDisabled || isPaymentMatched"
            >
            </app-date-picker>
        </div>

        <div class="row mr-0">
            <mat-form-field class="col col-sm-4">
                <mat-label>Amount</mat-label>
                <input
                    [maskito]="currencyMaskitoMask"
                    matInput
                    type="text"
                    formControlName="amount"
                    name="amount"
                    matTooltipPosition="above"
                    autocomplete="off"
                />
                <mat-error
                    *ngIf="
                        formGroup.controls.amount.invalid &&
                        formGroup.controls.amount.errors &&
                        formGroup.controls.amount.errors.message
                    "
                    >{{ formGroup.controls.amount.errors.message }}</mat-error
                >
            </mat-form-field>

            <mat-form-field class="col col-sm-4">
                <mat-label>Payment#</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="payment_number"
                    matTooltipPosition="above"
                    autocomplete="off"
                />
            </mat-form-field>

            <app-ledger-account-selector
                #ledgerAccountSelector
                class="col-sm-4"
                *ngIf="!billCheckId"
                [ledgerAccountControl]="formGroup.controls.paid_by__ledger_account_fk_id"
                [disabledReselect]="isEditFormFullDisabled || isReconciled"
                [placeholder]="'Paid Through'"
            >
            </app-ledger-account-selector>

            <!--            // If Record Payment-->
            <app-ledger-account-selector
                #checkLedgerAccountSelector
                class="col-sm-4"
                *ngIf="billCheckId"
                [types]="['bank']"
                [ledgerAccountControl]="formGroup.controls.paid_by__ledger_account_fk_id"
                [disabledReselect]="isEditFormFullDisabled || isReconciled"
                [placeholder]="'Bank Account'"
            >
            </app-ledger-account-selector>
        </div>

        <div class="row mr-0" *ngIf="!billCheckId">
            <mat-form-field class="col col-sm-4" *ngIf="!billCheckId && !paymentMadeId">
                <mat-select formControlName="payment_mode" (valueChange)="changeMode($event)">
                    <ng-container *ngFor="let mode of paymentModes">
                        <mat-option [value]="mode.slug" *ngIf="mode.is_selectable">
                            <span>{{ mode.title }}</span>
                        </mat-option>
                    </ng-container>
                </mat-select>
                <mat-placeholder>Payment Mode</mat-placeholder>
            </mat-form-field>

            <mat-form-field class="col col-sm-4" *ngIf="billCheckId || paymentMadeId">
                <input
                    matInput
                    type="text"
                    autocomplete="off"
                    placeholder="Payment Mode"
                    disabled
                    [value]="showPaymentMode()"
                />
            </mat-form-field>
            <mat-form-field
                class="col col-sm-4"
                *ngIf="
                    billCheckId ||
                    paymentMade?.check_info ||
                    formGroup.controls.payment_mode.value === 'check' ||
                    formGroup.controls.payment_mode.value === 'check_record'
                "
            >
                <input matInput formControlName="check_number" placeholder="Check#" type="text" autocomplete="off" />
            </mat-form-field>
        </div>

        <mat-form-field class="w-100" *ngIf="!billCheckId && !paymentMade?.check_info">
            <textarea formControlName="notes" type="text" matInput placeholder="Notes"> </textarea>
        </mat-form-field>
        <mat-form-field
            class="w-100"
            *ngIf="
                billCheckId ||
                paymentMade?.check_info ||
                formGroup.controls.payment_mode.value === 'check' ||
                formGroup.controls.payment_mode.value === 'check_record'
            "
        >
            <textarea formControlName="memo" type="text" matInput placeholder="Memo"> </textarea>
        </mat-form-field>

        <div *ngIf="paymentMade && paymentMade.source__deposit_release_fk_id" class="mt-2 mb-3 font-weight-bold">
            <span
                >This Payment Made associated with the Deposit Release of the Deal:
                <span *ngIf="relatedDeal && relatedDeal.id">
                    <a [routerLink]="['/deals/edit', relatedDeal.id]">
                        <span *ngIf="!relatedDeal.address" class="text-muted">No address </span>
                        <span *ngIf="relatedDeal.address"
                            >{{ relatedDeal.street_number }} {{ relatedDeal.address }}
                        </span>
                        — {{ relatedDeal.type }}/{{ relatedDeal.status }}
                    </a>
                </span>
            </span>
        </div>

        <div
            *ngIf="
                formGroup.controls.money_receiver__contact_fk_id.value &&
                (!paymentMade || !paymentMade.is_locked_for_applying)
            "
            class="invoices-list mb-3"
        >
            <div class="d-flex justify-content-between">
                <span class="text-muted">Bill Details</span>
                <span class="text-muted">Payment</span>
            </div>

            <div class="w-100 text-center p-5" *ngIf="billsArray.length === 0">
                There are no unpaid Bills, that can be applied for this payment.
            </div>

            <ng-container *ngFor="let bill of billsArray.controls; index as idx; first as first">
                <div class="invoice-item d-flex justify-content-between" [formGroup]="bill">
                    <div class="invoice-details">
                        <p class="m-0">
                            <a (click)="router.navigate(['purchases/bills', bill.value.bill_id])" href="javascript:">
                                {{ bill.value.bill_number || 'N/A' }}
                            </a>
                        </p>
                        <p class="m-0">Due: {{ bill.value.due_date | dateFromNumber | date: 'mediumDate' }}</p>
                        <p class="m-0">Balance: {{ bill.value.balance | currency: 'USD' : 'symbol' }}</p>
                    </div>
                    <div class="payment">
                        <mat-form-field class="w-100">
                            <a
                                class="pay-full"
                                [ngStyle]="bill.controls.payment.invalid ? {color: '#BB000A'} : {}"
                                *ngIf="!isEditFormFullDisabled"
                                (click)="payInFull(bill)"
                                >Pay in Full</a
                            >
                            <input
                                [maskito]="currencyMaskitoMask"
                                matInput
                                type="text"
                                formControlName="payment"
                                autocomplete="off"
                            />
                            <mat-error
                                *ngIf="
                                    bill.controls.payment.invalid &&
                                    bill.controls.payment.errors &&
                                    bill.controls.payment.errors.message
                                "
                                >{{ bill.controls.payment.errors.message }}</mat-error
                            >
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="d-flex justify-content-end">
            <div
                class="payment-summary mb-3 p-3 text-right"
                *ngIf="!paymentMade || !paymentMade.is_locked_for_applying"
            >
                <div class="row mb-3">
                    <div class="col-6 font-weight-bold">Amount Paid:</div>
                    <div class="col-6 font-weight-bold">{{ amountReceived | currency: 'USD' : 'symbol' }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Applied:</div>
                    <div class="col-6">{{ paymentsAmount | currency: 'USD' : 'symbol' }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Amount in excess:</div>
                    <div class="col-6" [ngStyle]="excessAmount < 0 ? {color: '#BB000A'} : {}">
                        {{ excessAmount | currency: 'USD' : 'symbol' }}
                    </div>
                </div>
            </div>
            <div class="payment-summary mb-3 p-3 text-right" *ngIf="paymentMade && paymentMade.is_locked_for_applying">
                <div class="row mb-3">
                    <div class="col-6 font-weight-bold">Amount Paid:</div>
                    <div class="col-6 font-weight-bold">{{ paymentMade.amount | currency: 'USD' : 'symbol' }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Applied:</div>
                    <div class="col-6">{{ paymentMade.amount_applied | currency: 'USD' : 'symbol' }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Amount in excess:</div>
                    <div class="col-6" [ngStyle]="excessAmount < 0 ? {color: '#BB000A'} : {}">
                        {{ paymentMade.over_payment | currency: 'USD' : 'symbol' }}
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex" *ngIf="!billCheckId">
            <div class="" *ngIf="paymentMade" [matTooltip]="getDeleteTooltip()">
                <button
                    mat-raised-button
                    color="warn"
                    [disabled]="!isAbleToDelete() || (paymentMade && !!paymentMade.source__deal_fk_id)"
                    *ngIf="paymentMadeId"
                    (click)="deletePayment()"
                >
                    Delete
                </button>
            </div>
            <span class="sp-spacer"></span>
            <button mat-raised-button class="" routerLink="/purchases/payments">Cancel</button>
            <button
                mat-raised-button
                color="primary"
                class="ml-2"
                [disabled]="createDisabled"
                *ngIf="!paymentMadeId"
                (click)="create()"
            >
                Create
            </button>
            <div
                class="ml-2"
                matTooltip="You cannot modify Payment Made associated with Deal."
                [matTooltipDisabled]="paymentMade && !paymentMade.source__deal_fk_id"
            >
                <button
                    mat-raised-button
                    color="primary"
                    [disabled]="createDisabled || (paymentMade && !!paymentMade.source__deal_fk_id)"
                    *ngIf="paymentMadeId"
                    (click)="editPayment()"
                >
                    Save
                </button>
            </div>
        </div>
        <div class="d-flex" *ngIf="billCheckId">
            <span class="sp-spacer"></span>
            <button mat-raised-button class="" [routerLink]="['/purchases', 'bills', billCheckId]">Cancel</button>
            <button
                mat-raised-button
                color="primary"
                class="ml-2"
                [disabled]="createDisabled"
                (click)="saveAndPrint(true)"
            >
                Save & Print Now
            </button>
            <button
                mat-raised-button
                color="primary"
                class="ml-2"
                [disabled]="createDisabled"
                (click)="saveAndPrint(false)"
            >
                Save & Print Later
            </button>
        </div>
    </div>
</section>
