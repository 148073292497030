import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IFinanceState} from './finance.reducer';
import {flattenChildrenDeep, nestedElementsByParent} from 'app/utilities';

export const selectFinanceFeature = createFeatureSelector<IFinanceState>('finance');

export const selectInvoices = createSelector(selectFinanceFeature, (state: IFinanceState) => state.invoices);

export const selectBills = createSelector(selectFinanceFeature, (state: IFinanceState) => state.bills);

export const selectProducts = createSelector(selectFinanceFeature, (state: IFinanceState) => state.products);

export const selectLedgerAccounts = createSelector(
    selectFinanceFeature,
    (state: IFinanceState) => state.ledgerAccounts
);

export const selectLedgerAccountsSorted = createSelector(selectFinanceFeature, (state: IFinanceState) => {
    return flattenChildrenDeep(nestedElementsByParent(state.ledgerAccounts));
});
