<div class="row">
    <div class="col-12 mb-2">
        <input #file_input id="file_input_file" class="none" type="file" (change)="fileChangeListener($event)" hidden />
        <button
            class="w-100 upload"
            mat-stroked-button
            color="primary"
            type="button"
            (click)="file_input.click()"
            [disabled]="disabled"
        >
            Upload Photo
        </button>
        <button
            class="w-100"
            mat-button
            *ngIf="file_input.files && file_input.files.length > 0"
            (click)="fileClearListener($event)"
            [disabled]="disabled"
        >
            Clear
        </button>
        <p *ngIf="file_input.files && file_input.files.length > 0">
            <strong>File:</strong>
            {{ file_input.files[0].name }}
        </p>
    </div>
</div>
