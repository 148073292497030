<div class="p-4">
    <h3>Audit (History)</h3>
    <div *ngIf="auditLogs?.length > 0" class="mx-n2">
        <div *ngFor="let audit of auditLogs">
            <app-audit-log-row [auditLog]="audit"></app-audit-log-row>
        </div>
    </div>
    <div *ngIf="!isLoading && auditLogs?.length === 0" class="p-4 text-center">There is no audit history yet.</div>
    <div class="load-container" id="load-container" *ngIf="isLoading">
        <div class="loader">Loading...</div>
    </div>
</div>
