<div class="d-flex flex-wrap flex-column justify-content-center">
    <div>
        <h2>Create New Category</h2>
    </div>

    <mat-form-field class="w-100">
        <input matInput type="text" placeholder="Category Name" [(ngModel)]="categoryName" />
    </mat-form-field>

    <mat-form-field *ngIf="isShowCategoryType" class="w-100">
        <mat-select class="w-100" placeholder="Category Type" [(ngModel)]="categoryType">
            <mat-option *ngFor="let category of categoryTypes" [value]="category.value">
                {{ category.title }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="m-3"></div>

    <div class="d-flex justify-content-between w-100">
        <span class="sp-spacer"></span>

        <button mat-raised-button color="primary" class="fix-focus-outline m-2" (click)="saveCategory()">Save</button>

        <button mat-button class="fix-focus-outline m-2" (click)="close()">Cancel</button>
    </div>
</div>
