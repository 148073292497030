import {Action} from '@ngrx/store';
import {SourceOfBusiness} from 'app/models/source-of-business';
import {SubSource} from 'app/models/sub-source';
import {SubType} from 'app/models/sub-type';
import {MembershipOrganization} from 'app/models/membership-organization';
import {ITagCategory, IWildcard} from '@cyberco-nodejs/zipi-typings';

export enum CompanyWideActionType {
    FETCH_SUB_ENTITIES = '[COMPANYWIDE] FETCH_SUB_ENTITIES',
    FETCH_SUB_ENTITIES_SUCCESS = '[COMPANYWIDE] FETCH_SUB_ENTITIES_SUCCESS',
    UPDATE_SOB = '[COMPANYWIDE] UPDATE_SOB',
    UPDATE_SOB_SUCCESS = '[COMPANYWIDE] UPDATE_SOB_SUCCESS',
    UPDATE_SUB_TYPES = '[COMPANYWIDE] UPDATE_SUB_TYPES',
    UPDATE_SUB_TYPES_SUCCESS = '[COMPANYWIDE] UPDATE_SUB_TYPES_SUCCESS',
    UPDATE_SUB_SOURCES = '[COMPANYWIDE] UPDATE_SUB_SOURCES',
    UPDATE_SUB_SOURCES_SUCCESS = '[COMPANYWIDE] UPDATE_SUB_SOURCES_SUCCESS',

    FETCH_MEMBERSHIP_ORGANIZATIONS = '[COMPANYWIDE] FETCH_MEMBERSHIP_ORGANIZATIONS',
    FETCH_MEMBERSHIP_ORGANIZATIONS_SUCCESS = '[COMPANYWIDE] FETCH_MEMBERSHIP_ORGANIZATIONS_SUCCESS',
    CREATE_MEMBERSHIP_ORGANIZATIONS = '[COMPANYWIDE] CREATE_MEMBERSHIP_ORGANIZATIONS',
    CREATE_MEMBERSHIP_ORGANIZATIONS_SUCCESS = '[COMPANYWIDE] CREATE_MEMBERSHIP_ORGANIZATIONS_SUCCESS',
    UPDATE_MEMBERSHIP_ORGANIZATIONS = '[COMPANYWIDE] UPDATE_MEMBERSHIP_ORGANIZATIONS',
    UPDATE_MEMBERSHIP_ORGANIZATIONS_SUCCESS = '[COMPANYWIDE] UPDATE_MEMBERSHIP_ORGANIZATIONS_SUCCESS',

    FETCH_TAG_CATEGORIES = '[COMPANYWIDE] FETCH_TAG_CATEGORIES',
    FETCH_TAG_CATEGORIES_SUCCESS = '[COMPANYWIDE] FETCH_TAG_CATEGORIES_SUCCESS',
    CREATE_TAG_CATEGORY = '[COMPANYWIDE] CREATE_TAG_CATEGORY',
    CREATE_TAG_CATEGORY_SUCCESS = '[COMPANYWIDE] CREATE_TAG_CATEGORY_SUCCESS',
    UPDATE_TAG_CATEGORY = '[COMPANYWIDE] UPDATE_TAG_CATEGORY',
    UPDATE_TAG_CATEGORY_SUCCESS = '[COMPANYWIDE] UPDATE_TAG_CATEGORY_SUCCESS',
    DELETE_TAG_CATEGORY = '[COMPANYWIDE] DELETE_TAG_CATEGORY',
    DELETE_TAG_CATEGORY_SUCCESS = '[COMPANYWIDE] DELETE_TAG_CATEGORY_SUCCESS',

    FETCH_WILDCARDS = '[COMPANYWIDE] FETCH_WILDCARDS',
    FETCH_WILDCARDS_SUCCESS = '[COMPANYWIDE] FETCH_WILDCARDS_SUCCESS',
    CREATE_WILDCARD = '[COMPANYWIDE] CREATE_WILDCARD',
    CREATE_WILDCARD_SUCCESS = '[COMPANYWIDE] CREATE_WILDCARD_SUCCESS',
    UPDATE_WILDCARD = '[COMPANYWIDE] UPDATE_WILDCARD',
    UPDATE_WILDCARD_SUCCESS = '[COMPANYWIDE] UPDATE_WILDCARD_SUCCESS',
    DELETE_WILDCARD = '[COMPANYWIDE] DELETE_WILDCARD'
}

export class FetchSubEntities implements Action {
    readonly type: string = CompanyWideActionType.FETCH_SUB_ENTITIES;
    constructor(public payload?: any) {}
}

export class FetchSubEntitiesSuccess implements Action {
    readonly type: string = CompanyWideActionType.FETCH_SUB_ENTITIES_SUCCESS;

    constructor(
        public payload?: {
            sub_sources: SubSource[];
            sub_types: SubType[];
            sources: SourceOfBusiness[];
        }
    ) {}
}

export class UpdateSOB implements Action {
    readonly type: string = CompanyWideActionType.UPDATE_SOB;

    constructor(public payload?: SourceOfBusiness[]) {}
}

export class UpdateSOBSuccess implements Action {
    readonly type: string = CompanyWideActionType.UPDATE_SOB_SUCCESS;

    constructor(public payload?: SourceOfBusiness[]) {}
}

export class UpdateSubTypes implements Action {
    readonly type: string = CompanyWideActionType.UPDATE_SUB_TYPES;

    constructor(public payload?: SubType[]) {}
}

export class UpdateSubTypesSuccess implements Action {
    readonly type: string = CompanyWideActionType.UPDATE_SUB_TYPES_SUCCESS;

    constructor(public payload?: SubType[]) {}
}

export class UpdateSubSources implements Action {
    readonly type: string = CompanyWideActionType.UPDATE_SUB_SOURCES;

    constructor(public payload?: SubSource[]) {}
}

export class UpdateSubSourcesSuccess implements Action {
    readonly type: string = CompanyWideActionType.UPDATE_SUB_SOURCES_SUCCESS;

    constructor(public payload?: SubSource[]) {}
}

export class FetchMembershipOrganizations implements Action {
    readonly type: string = CompanyWideActionType.FETCH_MEMBERSHIP_ORGANIZATIONS;
    constructor(public payload?: any) {}
}

export class FetchMembershipOrganizationsSuccess implements Action {
    readonly type: string = CompanyWideActionType.FETCH_MEMBERSHIP_ORGANIZATIONS_SUCCESS;

    constructor(public payload?: MembershipOrganization[]) {}
}

export class CreateMembershipOrganizations implements Action {
    readonly type: string = CompanyWideActionType.CREATE_MEMBERSHIP_ORGANIZATIONS;

    constructor(public payload?: MembershipOrganization) {}
}

export class CreateMembershipOrganizationsSuccess implements Action {
    readonly type: string = CompanyWideActionType.CREATE_MEMBERSHIP_ORGANIZATIONS_SUCCESS;

    constructor(public payload?: MembershipOrganization) {}
}

export class UpdateMembershipOrganizations implements Action {
    readonly type: string = CompanyWideActionType.UPDATE_MEMBERSHIP_ORGANIZATIONS;

    constructor(public payload?: MembershipOrganization) {}
}

export class UpdateMembershipOrganizationsSuccess implements Action {
    readonly type: string = CompanyWideActionType.UPDATE_MEMBERSHIP_ORGANIZATIONS_SUCCESS;

    constructor(public payload?: MembershipOrganization) {}
}

export class FetchTagCategories implements Action {
    readonly type: string = CompanyWideActionType.FETCH_TAG_CATEGORIES;
    constructor(public payload?: any) {}
}

export class FetchTagCategoriesSuccess implements Action {
    readonly type: string = CompanyWideActionType.FETCH_TAG_CATEGORIES_SUCCESS;

    constructor(public payload?: ITagCategory[]) {}
}

export class CreateTagCategory implements Action {
    readonly type: string = CompanyWideActionType.CREATE_TAG_CATEGORY;

    constructor(public payload?: ITagCategory) {}
}

export class CreateTagCategorySuccess implements Action {
    readonly type: string = CompanyWideActionType.CREATE_TAG_CATEGORY_SUCCESS;

    constructor(public payload?: ITagCategory) {}
}

export class UpdateTagCategory implements Action {
    readonly type: string = CompanyWideActionType.UPDATE_TAG_CATEGORY;

    constructor(
        public payload: {
            tag_category: ITagCategory;
            removed_tags: number[];
        }
    ) {}
}

export class UpdateTagCategorySuccess implements Action {
    readonly type: string = CompanyWideActionType.UPDATE_TAG_CATEGORY_SUCCESS;

    constructor(public payload?: ITagCategory) {}
}

export class DeleteTagCategory implements Action {
    readonly type: string = CompanyWideActionType.DELETE_TAG_CATEGORY;

    constructor(public payload?: number) {}
}

export class DeleteTagCategorySuccess implements Action {
    readonly type: string = CompanyWideActionType.DELETE_TAG_CATEGORY_SUCCESS;

    constructor(public payload?: number) {}
}

export class FetchWildcards implements Action {
    readonly type: string = CompanyWideActionType.FETCH_WILDCARDS;
    constructor(public payload?: any) {}
}

export class FetchWildcardsSuccess implements Action {
    readonly type: string = CompanyWideActionType.FETCH_WILDCARDS_SUCCESS;

    constructor(public payload?: IWildcard[]) {}
}

export class CreateWildcard implements Action {
    readonly type: string = CompanyWideActionType.CREATE_WILDCARD;

    constructor(public payload?: IWildcard) {}
}

export class CreateWildcardSuccess implements Action {
    readonly type: string = CompanyWideActionType.CREATE_WILDCARD_SUCCESS;

    constructor(public payload?: IWildcard) {}
}

export class UpdateWildcard implements Action {
    readonly type: string = CompanyWideActionType.UPDATE_WILDCARD;

    constructor(public payload?: IWildcard) {}
}

export class UpdateWildcardSuccess implements Action {
    readonly type: string = CompanyWideActionType.UPDATE_WILDCARD_SUCCESS;

    constructor(public payload?: IWildcard) {}
}

export class DeleteWildcard implements Action {
    readonly type: string = CompanyWideActionType.DELETE_WILDCARD;

    constructor(public payload?: number) {}
}

export type CompanyWideAction =
    | FetchSubEntities
    | FetchSubEntitiesSuccess
    | UpdateSOB
    | UpdateSOBSuccess
    | UpdateSubTypes
    | UpdateSubTypesSuccess
    | UpdateSubSources
    | UpdateSubSourcesSuccess
    | FetchMembershipOrganizations
    | FetchMembershipOrganizationsSuccess
    | CreateMembershipOrganizations
    | CreateMembershipOrganizationsSuccess
    | UpdateMembershipOrganizations
    | UpdateMembershipOrganizationsSuccess
    | FetchTagCategories
    | FetchTagCategoriesSuccess
    | CreateTagCategory
    | CreateTagCategorySuccess
    | UpdateTagCategory
    | UpdateTagCategorySuccess
    | DeleteTagCategory
    | DeleteTagCategorySuccess
    | FetchWildcards
    | FetchWildcardsSuccess
    | CreateWildcard
    | CreateWildcardSuccess
    | UpdateWildcard
    | UpdateWildcardSuccess
    | DeleteWildcard;
