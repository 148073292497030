<mat-card *ngIf="contact">
    <div class="d-flex align-items-center justify-content-between">
        <mat-card-title class="mb-0 d-flex align-items-center">
            <div>{{ contact.display_name }}</div>
            <a
                mat-icon-button
                [rule]="{company_settings__view_users: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                [routerLink]="['/company/directory/users/', contact.related_profile!.profile_id]"
                *ngIf="contact?.related_profile && !contact?.related__company_group_fk_id"
            >
                <mat-icon
                    *ngIf="contact?.related_profile && !contact?.related__company_group_fk_id"
                    class="ml-2"
                    [matTooltip]="'Directory Email &#13;' + (contact?.related_profile)!.email"
                    [matTooltipClass]="'my-tooltip'"
                >
                    person
                </mat-icon>
            </a>
            <mat-icon
                [rule]="{company_settings__view_users: true}"
                rbac
                [denyMethod]="'style'"
                [denyStyle]="{display: 'inline-block'}"
                [allowStyle]="{display: 'none'}"
                *ngIf="contact?.related_profile && !contact?.related__company_group_fk_id"
                class="ml-2"
                [matTooltip]="'Directory Email &#13;' + (contact?.related_profile)!.email"
                [matTooltipClass]="'my-tooltip'"
            >
                person
            </mat-icon>
            <mat-icon
                *ngIf="
                    contact?.related__company_group_fk_id &&
                    contact.related_group &&
                    contact.related_group.type !== 'division'
                "
                class="ml-2"
                matTooltip="Directory entity"
            >
                apartment
            </mat-icon>
            <mat-icon
                *ngIf="
                    contact?.related__company_group_fk_id &&
                    contact.related_group &&
                    contact.related_group.type === 'division'
                "
                class="ml-2"
                matTooltip="Directory entity"
            >
                domain
            </mat-icon>
            <mat-icon *ngIf="isShowInZipiIcon(contact)" class="ml-2" matTooltip="In SkySlope Books platform">
                cloud_done
            </mat-icon>
        </mat-card-title>

        <div>
            <a
                mat-icon-button
                [anyRule]="[{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                matTooltip="Merged Contacts"
                (click)="openMergedContactsDialog()"
                *ngIf="contact.id && mode === 'preview' && mergedContacts && mergedContacts.length"
            >
                <mat-icon>compare_arrows</mat-icon>
            </a>

            <a
                mat-icon-button
                [anyRule]="[{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                [routerLink]="['/contacts', contact.id, 'edit']"
                [queryParams]="{index: selectedTabIndex}"
                matTooltip="Edit contact"
                *ngIf="contact.id && mode === 'preview' && !contact.is_delegated"
            >
                <mat-icon>edit</mat-icon>
            </a>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-between">
        <div class="mb-2 d-block col-6">
            <span *ngIf="contact.company_name">Company</span>
        </div>

        <div
            [anyRule]="[{sales__view_invoices: true}, {purchases__view_bills: true}]"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            class="col-2 flex-column"
            *ngIf="isFinanceSummaryReady && contact.id && mode === 'preview' && !contact.is_delegated"
        >
            <div style="font-size: 14px; text-align: end; font-weight: bold">Current</div>
            <div
                style="font-size: 13px; text-align: end"
                [rule]="{sales__view_invoices: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
            >
                <a href="javascript:" (click)="redirectTo('invoice', 'current')" matTooltip="Current A/R">
                    {{ financeSummaryReady.invoicesBalanceCurrent | currency }}
                </a>
            </div>
            <div
                style="font-size: 13px; text-align: end"
                [rule]="{purchases__view_bills: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
            >
                <a href="javascript:" (click)="redirectTo('bill', 'current')" matTooltip="Current A/P">
                    {{ financeSummaryReady.billsBalanceCurrent | currency }}
                </a>
            </div>
        </div>

        <div
            [anyRule]="[{sales__view_invoices: true}, {purchases__view_bills: true}]"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            class="col-2 flex-column"
            *ngIf="isFinanceSummaryReady && contact.id && mode === 'preview' && !contact.is_delegated"
        >
            <div style="font-size: 14px; text-align: end; font-weight: bold">Overdue</div>
            <div
                style="font-size: 13px; text-align: end"
                [rule]="{sales__view_invoices: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
            >
                <a href="javascript:" (click)="redirectTo('invoice', 'overdue')" matTooltip="Overdue A/R">
                    {{ financeSummaryReady.invoicesBalanceOverdue | currency }}
                </a>
            </div>
            <div
                style="font-size: 13px; text-align: end"
                [rule]="{purchases__view_bills: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
            >
                <a href="javascript:" (click)="redirectTo('bill', 'overdue')" matTooltip="Overdue A/P">
                    {{ financeSummaryReady.billsBalanceOverdue | currency }}
                </a>
            </div>
        </div>

        <div
            [anyRule]="[{purchases__view_vendor_credits: true}, {sales__view_customer_credits: true}]"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            class="col-2 flex-column"
            *ngIf="isFinanceSummaryReady && contact.id && mode === 'preview' && !contact.is_delegated"
            style="padding-right: 35px"
        >
            <div style="font-size: 14px; text-align: end; font-weight: bold">Credits</div>
            <div
                style="font-size: 13px; text-align: end"
                [rule]="{sales__view_customer_credits: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
            >
                <a href="javascript:" (click)="openCreditNotesDialog()" matTooltip="A/R Credit Note">
                    {{ financeSummaryReady.customerExcessFunds | currency }}
                </a>
            </div>
            <div
                style="font-size: 13px; text-align: end"
                [rule]="{purchases__view_vendor_credits: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
            >
                <a href="javascript:" (click)="openVendorCreditsDialog()" matTooltip="A/P Vendor Credit">
                    {{ financeSummaryReady.vendorExcessFunds | currency }}
                </a>
            </div>
        </div>
    </div>

    <mat-card-content>
        <section style="margin-bottom: 10px" class="d-flex justify-content-between w-100">
            <mat-chip-list
                *ngIf="contactContactTypes && contactContactTypes.length > 0 && !contact?.related__company_group_fk_id"
            >
                <mat-chip
                    *ngFor="let contactContactType of contactContactTypes"
                    disableRipple="true"
                    selectable="false"
                    class="mr-2"
                >
                    {{ contactContactType.title }}
                </mat-chip>
            </mat-chip-list>

            <ng-container *ngIf="contactClasses && contactClasses.length > 0">
                <mat-menu #classMenu="matMenu">
                    <button
                        class="contactClassesMenuButton"
                        mat-menu-item
                        *ngFor="let contactClass of contactClasses"
                        [disabled]="true"
                    >
                        {{ contactClass.title }}
                    </button>
                </mat-menu>

                <button mat-button [matMenuTriggerFor]="classMenu">Classes</button>
            </ng-container>
        </section>

        <section *ngIf="contact.company_name" class="mb-2">
            <!--<h2 class="mb-0 mb-2">Company: {{contact.company_name}}</h2>-->
            <div class="row">
                <div class="col-12 mt-2" *ngIf="contact.default_location">
                    <span class="zp-info-label">Default Location: </span>
                    <span *ngIf="contact.default_location.label">{{ contact.default_location.label + ':' }} </span>
                    <span *ngIf="contact.default_location.street_number"
                        >{{ contact.default_location.street_number }}
                    </span>
                    <span *ngIf="contact.default_location.street_address"
                        >{{ contact.default_location.street_address }}
                    </span>
                    <span *ngIf="contact.default_location.unit_number"
                        >{{ contact.default_location.unit_number }}
                    </span>
                    <span *ngIf="contact.default_location.city">{{ contact.default_location.city + ',' }} </span>
                    <span *ngIf="contact.default_location.state">{{ contact.default_location.state }} </span>
                    <span *ngIf="contact.default_location.zip">{{ contact.default_location.zip }} </span>
                    <span *ngIf="contact.default_location.country" class="text-muted"
                        >({{ contact.default_location.country }})
                    </span>
                </div>
            </div>
        </section>

        <div *ngIf="!isFullContactLoading">
            <mat-tab-group #tabs (selectedTabChange)="tabChanged($event)" [selectedIndex]="selectedTabIndex">
                <mat-tab
                    label="Details"
                    *ngIf="!contact.is_delegated && profilePermissions && profilePermissions.contacts__view_details"
                >
                    <section class="my-3 w-100 align-items-baseline">
                        <ng-container
                            *ngFor="let person of sortPersons(contact.contact_persons); index as ii; last as last"
                        >
                            <mat-card [class.mb-2]="last === false" class="px-0" *ngIf="person">
                                <div class="row w-100 align-items-center">
                                    <div class="col-12 d-flex align-items-center">
                                        <div
                                            class="px-2 mr-1"
                                            *ngIf="person.type === 'main_person' && contact.company_name"
                                        >
                                            <mat-icon class="mr-2">apartment</mat-icon>
                                        </div>
                                        <div class="px-2 mr-1" *ngIf="person.type === 'department'">
                                            <mat-icon class="mr-2">domain</mat-icon>
                                        </div>
                                        <div
                                            class="px-2 mr-1"
                                            *ngIf="
                                                person.type === 'person' ||
                                                (person.type === 'main_person' && !contact.company_name)
                                            "
                                        >
                                            <mat-icon class="mr-2">person</mat-icon>
                                        </div>

                                        <h3 class="mb-0" *ngIf="!showLinkedPersons[ii]">
                                            {{ person.preferred_name ? person.preferred_name : person.first_name }}
                                            <span *ngIf="person.last_name">{{ person.last_name }}</span>
                                        </h3>

                                        <h3 class="mb-0" *ngIf="showLinkedPersons[ii] && linkedPersons[ii]">
                                            {{
                                                linkedPersons[ii].preferred_name
                                                    ? linkedPersons[ii].preferred_name
                                                    : linkedPersons[ii].first_name
                                            }}
                                            <span *ngIf="linkedPersons[ii].last_name">{{
                                                linkedPersons[ii].last_name
                                            }}</span>
                                        </h3>

                                        <div *ngIf="person.type === 'main_person' && !showLinkedPersons[ii]">
                                            <app-contact-invite-status [contact]="contact"></app-contact-invite-status>
                                        </div>

                                        <span class="sp-spacer"></span>

                                        <div class="mt-n2" *ngIf="!showLinkedPersons[ii] && linkedPersons[ii]">
                                            <button
                                                type="button"
                                                mat-button
                                                *ngIf="
                                                    person.linked_to_contact_persons_ids &&
                                                    person.linked_to_contact_persons_ids.length
                                                "
                                                matTooltip="View Record Source"
                                                (click)="showLinkedPersons[ii] = !showLinkedPersons[ii]"
                                            >
                                                <mat-icon>insert_link</mat-icon>
                                                <mat-icon
                                                    class="icon-btn"
                                                    *ngIf="isShowPersonNotification(person, linkedPersons[ii])"
                                                    >error_outline</mat-icon
                                                >
                                            </button>
                                        </div>

                                        <div class="mt-n2" *ngIf="showLinkedPersons[ii] && linkedPersons[ii]">
                                            <button
                                                type="button"
                                                mat-button
                                                *ngIf="isShowPersonNotification(person, linkedPersons[ii])"
                                                (click)="updateLinkedPerson(person, linkedPersons[ii])"
                                                matTooltip="Update Linked Record"
                                            >
                                                Update Linked Record
                                            </button>
                                            <button
                                                type="button"
                                                mat-button
                                                (click)="showLinkedPersons[ii] = !showLinkedPersons[ii]"
                                                matTooltip="Cancel"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>

                                    <div class="col-12 zp-p-to-locations">
                                        <span class="mr-2" *ngIf="person.phone && !showLinkedPersons[ii]">
                                            Phone: {{ person.phone | phoneMask }}
                                        </span>
                                        <span
                                            class="mr-2"
                                            *ngIf="
                                                linkedPersons[ii] && linkedPersons[ii].phone && showLinkedPersons[ii]
                                            "
                                        >
                                            Phone:
                                            {{ linkedPersons[ii].phone ? linkedPersons[ii].phone : ('' | phoneMask) }}
                                        </span>

                                        <span class="mr-2" *ngIf="person.fax && !showLinkedPersons[ii]">
                                            Fax: {{ person.fax | phoneMask }}
                                        </span>
                                        <span
                                            class="mr-2"
                                            *ngIf="linkedPersons[ii] && linkedPersons[ii].fax && showLinkedPersons[ii]"
                                        >
                                            Fax: {{ linkedPersons[ii].fax ? linkedPersons[ii].fax : ('' | phoneMask) }}
                                        </span>

                                        <span class="mr-2" *ngIf="person.email && !showLinkedPersons[ii]"
                                            >Email: {{ person.email }}</span
                                        >
                                        <span
                                            class="mr-2"
                                            *ngIf="
                                                linkedPersons[ii] && linkedPersons[ii].email && showLinkedPersons[ii]
                                            "
                                            >Email: {{ linkedPersons[ii].email }}</span
                                        >
                                    </div>

                                    <div
                                        class="col-12 zp-p-to-locations"
                                        *ngIf="person.default_location && !showLinkedPersons[ii]"
                                    >
                                        <p>
                                            <span class="zp-info-label">Default Location: </span>
                                            <span *ngIf="person.default_location.label"
                                                >{{ person.default_location.label + ':' }}
                                            </span>
                                            <span *ngIf="person.default_location.street_number"
                                                >{{ person.default_location.street_number }}
                                            </span>
                                            <span *ngIf="person.default_location.street_address"
                                                >{{ person.default_location.street_address }}
                                            </span>
                                            <span *ngIf="person.default_location.unit_number"
                                                >{{ person.default_location.unit_number }}
                                            </span>
                                            <span *ngIf="person.default_location.city"
                                                >{{ person.default_location.city + ',' }}
                                            </span>
                                            <span *ngIf="person.default_location.state"
                                                >{{ person.default_location.state }}
                                            </span>
                                            <span *ngIf="person.default_location.zip"
                                                >{{ person.default_location.zip }}
                                            </span>
                                            <span *ngIf="person.default_location.country" class="text-muted"
                                                >({{ person.default_location.country }})
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </mat-card>
                        </ng-container>

                        <div class="mb-3"></div>

                        <div
                            [anyRule]="[
                                {contacts__manage_limited_contacts: true},
                                {contacts__manage_any_contacts: true}
                            ]"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                        >
                            <button
                                [rule]="{contacts__manage_details: true}"
                                rbac
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'inline-block'}"
                                [denyStyle]="{display: 'none'}"
                                *ngIf="contact.id && !isDefaultCompanyContact"
                                type="button"
                                mat-button
                                (click)="addPerson('person')"
                                matTooltip="Add person"
                            >
                                <mat-icon class="mr-1">add</mat-icon>
                                Person
                            </button>
                            <button
                                [rule]="{contacts__manage_details: true}"
                                rbac
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'inline-block'}"
                                [denyStyle]="{display: 'none'}"
                                *ngIf="contact.id && contact.company_name && !isDefaultCompanyContact"
                                type="button"
                                mat-button
                                (click)="addPerson('department')"
                                matTooltip="Add department"
                                class="ml-1"
                            >
                                <mat-icon class="mr-1">add</mat-icon>
                                Department
                            </button>
                        </div>
                    </section>
                </mat-tab>

                <!-- rbac not working with mat-tab, so used ngIf instead -->
                <mat-tab
                    label="Locations"
                    *ngIf="profilePermissions && profilePermissions.contacts__view_locations && !contact.is_delegated"
                >
                    <div
                        class=""
                        [anyRule]="[{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        <!--  <mat-card class="d-flex align-items-center mb-1" *ngFor="let location of contact.locations">-->
                        <mat-card
                            class="d-flex align-items-center mb-1"
                            *ngFor="let location of contact.contact_locations; index as ii"
                        >
                            <mat-icon class="mr-2">place</mat-icon>
                            <span *ngIf="location.label && !showLinkedLocations[ii]"
                                >{{ location.label + ':' }} &nbsp;</span
                            >
                            <span *ngIf="location.street_number && !showLinkedLocations[ii]"
                                >{{ location.street_number }} &nbsp;</span
                            >
                            <span *ngIf="location.street_address && !showLinkedLocations[ii]"
                                >{{ location.street_address }} &nbsp;</span
                            >
                            <span *ngIf="location.unit_number && !showLinkedLocations[ii]"
                                >{{ location.unit_number }} &nbsp;</span
                            >
                            <span *ngIf="location.city && !showLinkedLocations[ii]"
                                >{{ location.city + ',' }} &nbsp;</span
                            >
                            <span *ngIf="location.state && !showLinkedLocations[ii]">{{ location.state }} &nbsp;</span>
                            <span *ngIf="location.zip && !showLinkedLocations[ii]">{{ location.zip }}</span>
                            <span *ngIf="location.country && !showLinkedLocations[ii]" class="text-muted"
                                >({{ location.country }})</span
                            >

                            <span *ngIf="linkedLocations[ii] && linkedLocations[ii].label && showLinkedLocations[ii]"
                                >{{ linkedLocations[ii].label + ':' }} &nbsp;</span
                            >
                            <span
                                *ngIf="
                                    linkedLocations[ii] && linkedLocations[ii].street_number && showLinkedLocations[ii]
                                "
                                >{{ linkedLocations[ii].street_number }} &nbsp;</span
                            >
                            <span
                                *ngIf="
                                    linkedLocations[ii] && linkedLocations[ii].street_address && showLinkedLocations[ii]
                                "
                                >{{ linkedLocations[ii].street_address }} &nbsp;</span
                            >
                            <span
                                *ngIf="
                                    linkedLocations[ii] && linkedLocations[ii].unit_number && showLinkedLocations[ii]
                                "
                                >{{ linkedLocations[ii].unit_number }} &nbsp;</span
                            >
                            <span *ngIf="linkedLocations[ii] && linkedLocations[ii].city && showLinkedLocations[ii]"
                                >{{ linkedLocations[ii].city + ',' }} &nbsp;</span
                            >
                            <span *ngIf="linkedLocations[ii] && linkedLocations[ii].state && showLinkedLocations[ii]"
                                >{{ linkedLocations[ii].state }} &nbsp;</span
                            >
                            <span *ngIf="linkedLocations[ii] && linkedLocations[ii].zip && showLinkedLocations[ii]">{{
                                linkedLocations[ii].zip
                            }}</span>
                            <span
                                *ngIf="linkedLocations[ii] && linkedLocations[ii].country && showLinkedLocations[ii]"
                                class="text-muted"
                                >({{ linkedLocations[ii].country }})</span
                            >

                            <span class="sp-spacer"></span>

                            <div class="mt-n2" *ngIf="!showLinkedLocations[ii] && linkedLocations[ii]">
                                <button
                                    type="button"
                                    mat-button
                                    *ngIf="
                                        location.linked_to_contact_locations_ids &&
                                        location.linked_to_contact_locations_ids.length
                                    "
                                    matTooltip="View Record Source"
                                    (click)="showLinkedLocations[ii] = !showLinkedLocations[ii]"
                                >
                                    <mat-icon>insert_link</mat-icon>
                                    <mat-icon
                                        class="icon-btn"
                                        *ngIf="isShowLocationNotification(location, linkedLocations[ii])"
                                        >error_outline</mat-icon
                                    >
                                </button>
                            </div>

                            <div class="mt-n2" *ngIf="showLinkedLocations[ii] && linkedLocations[ii]">
                                <button
                                    type="button"
                                    mat-button
                                    *ngIf="isShowLocationNotification(location, linkedLocations[ii])"
                                    (click)="updateLinkedLocation(location, linkedLocations[ii])"
                                    matTooltip="Update Linked Record"
                                >
                                    Update Linked Record
                                </button>
                                <button
                                    type="button"
                                    mat-button
                                    (click)="showLinkedLocations[ii] = !showLinkedLocations[ii]"
                                    matTooltip="Cancel"
                                >
                                    Cancel
                                </button>
                            </div>
                        </mat-card>

                        <div class="mb-2"></div>
                        <div
                            [rule]="{contacts__manage_locations: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                        >
                            <button
                                [anyRule]="[
                                    {contacts__manage_limited_contacts: true},
                                    {contacts__manage_any_contacts: true}
                                ]"
                                rbac
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'inline-block'}"
                                [denyStyle]="{display: 'none'}"
                                *ngIf="contact.id && !isDefaultCompanyContact"
                                type="button"
                                mat-button
                                (click)="addLocation()"
                            >
                                <mat-icon>add</mat-icon>
                                Location
                            </button>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab
                    label="Payment Methods"
                    *ngIf="
                        contact.id &&
                        profilePermissions &&
                        profilePermissions.contacts__view_payment_methods &&
                        !contact.is_delegated
                    "
                >
                    <div
                        [anyRule]="[{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'block'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        <app-payment-methods-tab
                            *ngIf="!isDefaultCompanyContact"
                            [contact]="contact"
                            [mode]="'view'"
                            [isCompanyZipiFinancialActivated]="isCompanyZipiFinancialActivated"
                        ></app-payment-methods-tab>

                        <div class="mt-4 p-1">
                            <mat-accordion>
                                <mat-expansion-panel
                                    (opened)="isAuthorizePanelOpen = true"
                                    (closed)="isAuthorizePanelOpen = false"
                                >
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <span>Authorize.Net</span>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div *ngIf="isAuthorizePanelOpen">
                                        <div *ngIf="!isDefaultCompanyContact" style="max-width: 60%" class="pt-3 pb-3">
                                            <h3>Receive Money (from Contact):</h3>
                                            <div *ngIf="contactGateways && contactGateways.length > 0">
                                                <div *ngFor="let gateway of contactGateways">
                                                    <ng-container *ngFor="let method of gateway.payment_methods">
                                                        <mat-card
                                                            class="d-flex mb-1 align-items-center"
                                                            [ngClass]="
                                                                !method.is_visible_to_connect ? 'disabled-card' : ''
                                                            "
                                                            [matTooltip]="
                                                                'Not authorized to charge card, please contact the card holder to provide authorization from their portal: Edit Contact > Payment Method.'
                                                            "
                                                            [matTooltipDisabled]="method.is_visible_to_connect"
                                                        >
                                                            <div>
                                                                <span class="font-weight-bold mr-2">Credit Card: </span>
                                                                <span>{{ method.title }}</span>
                                                                <span class="ml-1" *ngIf="method.expiration_date"
                                                                    >({{ method.expiration_date }})</span
                                                                >
                                                            </div>

                                                            <i class="sp-spacer"></i>
                                                        </mat-card>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div
                                                [rule]="{contacts__manage_payment_methods: true}"
                                                rbac
                                                [denyMethod]="'style'"
                                                [allowStyle]="{display: 'inline-block'}"
                                                [denyStyle]="{display: 'none'}"
                                            >
                                                <button
                                                    [anyRule]="[
                                                        {contacts__manage_limited_contacts: true},
                                                        {contacts__manage_any_contacts: true}
                                                    ]"
                                                    rbac
                                                    [denyMethod]="'style'"
                                                    [allowStyle]="{display: 'inline-block'}"
                                                    [denyStyle]="{display: 'none'}"
                                                    class="my-2"
                                                    type="button"
                                                    mat-raised-button
                                                    (click)="addCard()"
                                                >
                                                    <!--<mat-icon>credit_card</mat-icon>-->
                                                    Add Contact Payment Method
                                                </button>
                                            </div>
                                        </div>

                                        <mat-divider *ngIf="!isDefaultCompanyContact"></mat-divider>
                                        <div
                                            *ngIf="!isDefaultCompanyContact"
                                            class="pt-3"
                                            style="display: flex; flex-direction: column; max-width: 60%"
                                        >
                                            <div class="d-flex flex-column justify-content-between w-100">
                                                <h3>Pay Money (to Contact):</h3>
                                                <div class="d-flex justify-content-between w-100">
                                                    <div class="d-flex flex-column justify-content-between w-100">
                                                        <div *ngIf="myGateways && myGateways.length > 0" class="w-100">
                                                            <div *ngFor="let gateway of myGateways" class="w-100">
                                                                <div
                                                                    *ngFor="let method of gateway.payment_methods"
                                                                    class="w-100"
                                                                >
                                                                    <mat-card
                                                                        *ngIf="method.is_available_for_payment"
                                                                        class="d-flex mb-1 w-100"
                                                                    >
                                                                        <div>
                                                                            <span class="font-weight-bold mr-2"
                                                                                >Credit Card:
                                                                            </span>
                                                                            <span>{{ method.title }}</span>
                                                                            <span
                                                                                class="ml-1"
                                                                                *ngIf="method.expiration_date"
                                                                                >({{ method.expiration_date }})</span
                                                                            >
                                                                        </div>

                                                                        <i class="sp-spacer"></i>
                                                                        <div
                                                                            class="d-flex flex-wrap justify-content-end"
                                                                            style="width: 200px"
                                                                        >
                                                                            <div>
                                                                                <a
                                                                                    class="font-weight-bold"
                                                                                    style="
                                                                                        color: #f8a504;
                                                                                        font-size: 20px;
                                                                                    "
                                                                                    href="#"
                                                                                    *ngIf="
                                                                                        !method.related__ledger_account_fk_id
                                                                                    "
                                                                                    (click)="
                                                                                        $event.preventDefault();
                                                                                        finishSetup(method, gateway)
                                                                                    "
                                                                                >
                                                                                    Complete Setup
                                                                                </a>
                                                                            </div>
                                                                            <div>
                                                                                <span
                                                                                    *ngIf="method.is_visible_to_connect"
                                                                                    >Contact Can Initialize
                                                                                    Payment</span
                                                                                >
                                                                                <span
                                                                                    *ngIf="
                                                                                        !method.is_visible_to_connect
                                                                                    "
                                                                                    >Contact Can Not Initialize
                                                                                    Payment</span
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </mat-card>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!--                                                    <div *ngIf="contactReceiveGateway">-->
                                                        <!--                                                        <div *ngFor="let method of contactReceiveGateway.payment_methods">-->
                                                        <!--                                                            <mat-card *ngIf="method.is_available_for_payment"-->
                                                        <!--                                                                      class="d-flex mb-1 align-items-center">-->
                                                        <!--                                                                <div>-->
                                                        <!--                                                    <span class="font-weight-bold mr-2">-->
                                                        <!--                                                        <img style="width:60px;" src="https://storage.googleapis.com/zipi-static/addons/zipipay/logos/Zipi%20Pay%20360x140.png">-->
                                                        <!--                                                        Bank: </span>-->
                                                        <!--                                                                    <span>{{method.title}}</span>-->
                                                        <!--                                                                    <span class="ml-1" *ngIf="method.expiration_date">({{method.expiration_date}})</span>-->
                                                        <!--                                                                </div>-->

                                                        <!--                                                                <i class="sp-spacer"></i>-->
                                                        <!--                                                                &lt;!&ndash;                                <button type="button" mat-raised-button>&ndash;&gt;-->
                                                        <!--                                                                &lt;!&ndash;                                    &lt;!&ndash;<mat-icon>credit_card</mat-icon>&ndash;&gt;&ndash;&gt;-->
                                                        <!--                                                                &lt;!&ndash;                                   Delete&ndash;&gt;-->
                                                        <!--                                                                &lt;!&ndash;                                </button>&ndash;&gt;-->
                                                        <!--                                                            </mat-card>-->
                                                        <!--                                                        </div>-->
                                                        <!--                                                    </div>-->
                                                        <div
                                                            class="my-2 d-inline-block"
                                                            [anyRule]="[
                                                                {contacts__manage_limited_contacts: true},
                                                                {contacts__manage_any_contacts: true}
                                                            ]"
                                                            rbac
                                                            [denyMethod]="'style'"
                                                            [allowStyle]="{display: 'inline-block'}"
                                                            [denyStyle]="{display: 'none'}"
                                                        >
                                                            <button
                                                                [rule]="{contacts__manage_payment_methods: true}"
                                                                rbac
                                                                [denyMethod]="'style'"
                                                                [allowStyle]="{display: 'inline-block'}"
                                                                [denyStyle]="{display: 'none'}"
                                                                class="mr-2 mb-2"
                                                                type="button"
                                                                mat-raised-button
                                                                (click)="addMyCard()"
                                                            >
                                                                <!--<mat-icon>credit_card</mat-icon>-->
                                                                Add Credit Card
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab
                    label="Compliance"
                    *ngIf="
                        contact?.related__profile_fk_id &&
                        profilePermissions &&
                        profilePermissions.contacts__view_compliance &&
                        !contact.is_delegated &&
                        !isDefaultCompanyContact
                    "
                >
                    <div
                        *ngIf="
                            contact.related_profile?.date_of_birth ||
                            contact.related_profile?.hire_date ||
                            contact.related_profile?.termination_date ||
                            contact.related_profile?.anniversary_date ||
                            contact.related_profile?.ssn_ein
                        "
                    >
                        <h3>General</h3>
                        <div class="row w-100 d-nline-flex zp-ofx-hide" style="padding-left: 30px">
                            <div
                                *ngIf="contact.related_profile && contact.related_profile.date_of_birth"
                                class="col col-sm-6 general-item"
                            >
                                <strong>Date of Birth: </strong>
                                {{ contact.related_profile.date_of_birth | dateFromNumber | date: 'shortDate' }}
                            </div>

                            <div
                                *ngIf="contact.related_profile && contact.related_profile.hire_date"
                                class="col col-sm-6 general-item"
                            >
                                <strong>Hire Date: </strong>
                                {{ contact.related_profile.hire_date | dateFromNumber | date: 'shortDate' }}
                            </div>

                            <div
                                *ngIf="contact.related_profile && contact.related_profile.termination_date"
                                class="col col-sm-6 general-item"
                            >
                                <strong>Termination Date: </strong>
                                {{ contact.related_profile.termination_date | dateFromNumber | date: 'shortDate' }}
                            </div>

                            <div
                                *ngIf="contact.related_profile && contact.related_profile.anniversary_date"
                                class="col col-sm-6 general-item"
                            >
                                <strong>Anniversary Date: </strong>
                                {{ contact.related_profile.anniversary_date | dateFromNumber | date: 'shortDate' }}
                            </div>
                        </div>
                        <hr *ngIf="isPMShown() || isCCMShown() || isCAShown()" />
                    </div>

                    <div *ngIf="profileMemberships && profileMemberships.length">
                        <h3>
                            <strong>Licensing / Memberships</strong>
                        </h3>
                        <div class="col w-100">
                            <div
                                *ngFor="let profileMembership of profileMemberships; last as last"
                                class="d-flex flex-row"
                            >
                                <div class="d-flex flex-column">
                                    <div>
                                        <strong *ngIf="profileMembership.membership_organization_fk_id">
                                            {{ getNameById(profileMembership.membership_organization_fk_id) }}
                                        </strong>
                                    </div>
                                    <div>Number: {{ profileMembership.member_license_number }}</div>

                                    <div *ngIf="profileMembership.expiration_date">
                                        Expiration:
                                        {{ profileMembership.expiration_date | dateFromNumber | date: 'shortDate' }}
                                    </div>

                                    <div>
                                        Dues Responsibility:
                                        <span class="text-capitalize">{{ profileMembership.dues_responsibility }}</span>
                                    </div>

                                    <div>Type: {{ profileMembership.membership_type }}</div>
                                    <div style="margin-bottom: 12px"><mat-divider *ngIf="!last"></mat-divider></div>
                                </div>
                            </div>
                        </div>
                        <hr *ngIf="isCAShown() || isCCMShown()" />
                    </div>

                    <div *ngIf="wildcardInstances.length">
                        <h3>
                            <strong>Custom Attributes</strong>
                        </h3>
                        <div class="row w-100 d-nline-flex zp-ofx-hide" style="padding-left: 30px">
                            <div *ngFor="let wildcardInstance of wildcardInstances" class="w-100">
                                <div class="col col-sm-6 ca-item" *ngIf="wildcardInstance.wildcard">
                                    <strong>{{ wildcardInstance.wildcard.title }}: </strong>
                                    <span
                                        *ngIf="wildcardInstance.wildcard.wildcard_type_sk !== WILDCARD.type_SET.contact"
                                        >{{ wildcardInstance.value }}</span
                                    >
                                    <span
                                        *ngIf="wildcardInstance.wildcard.wildcard_type_sk === WILDCARD.type_SET.contact"
                                        >{{ wildcardInstance.contact?.display_name }}</span
                                    >
                                </div>
                            </div>
                        </div>
                        <hr *ngIf="isCCMShown()" />
                    </div>
                    <div
                        *ngIf="
                            contact.related_profile &&
                            contact.related_profile.opening_performance_values &&
                            contact.related_profile.opening_performance_values.length
                        "
                    >
                        <h3>
                            <strong>Commission Calculation Modifiers</strong>
                        </h3>

                        <div class="d-flex flex-row ccm-area" style="padding-left: 15px">
                            <div
                                *ngFor="let opValue of contact.related_profile?.opening_performance_values"
                                style="width: calc(20% - 4px); min-width: 200px"
                            >
                                <mat-card class="ccm-card">
                                    <div>
                                        <strong> Deal Type: </strong>
                                        {{ opValue.parameters.type }}
                                    </div>
                                    <div>
                                        <strong> Apply Date: </strong>
                                        {{
                                            opValue.parameters.close_of_escrow_from | dateFromNumber | date: 'shortDate'
                                        }}
                                    </div>
                                    <div>
                                        <strong> Deal Property Class: </strong>
                                        {{ opValue.parameters.property_class }}
                                    </div>
                                    <div>
                                        <strong> Deal Count: </strong>
                                        {{ opValue.virtual_values.count_of_deals }}
                                    </div>
                                    <div>
                                        <strong>Sales Price Total: </strong>
                                        {{ opValue.virtual_values.sum_of_sales_price }}
                                    </div>
                                    <div>
                                        <strong>Gross Commission Total: </strong>
                                        {{ opValue.virtual_values.sum_of_gross_commission }}
                                    </div>
                                    <div>
                                        <strong>Company Split Total: </strong>
                                        {{ opValue.virtual_values.total_company_split }}
                                    </div>
                                    <div>
                                        <strong>Sales Entity Split Total: </strong>
                                        {{ opValue.virtual_values.total_agent_split }}
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab
                    label="Approvals"
                    *ngIf="
                        !contact.is_delegated &&
                        !isDefaultCompanyContact &&
                        profilePermissions &&
                        profilePermissions.contacts__view_approvals
                    "
                >
                    <div class="p-2 py-3" *ngIf="contact.partner_company_settings">
                        <div>
                            <h3>Portal Access</h3>
                            <div class="ml-3">
                                <mat-checkbox
                                    [(ngModel)]="contact.partner_company_settings.show_my_docs_as_source"
                                    [disabled]="true"
                                    >Allow Sales & Purchase Documents to be Viewed in Portal</mat-checkbox
                                >
                            </div>
                        </div>

                        <hr />

                        <div>
                            <h3>Automation Rules for Documents Received from {{ contact.display_name }}</h3>
                            <div class="ml-3">
                                <h4
                                    [anyRule]="[{contacts__manage_approvals_automation: true}]"
                                    rbac
                                    [denyMethod]="'style'"
                                    [allowStyle]="{display: 'block'}"
                                    [denyStyle]="{display: 'none'}"
                                >
                                    Company Wide
                                </h4>
                                <div
                                    class="ml-3"
                                    [anyRule]="[{contacts__manage_approvals_automation: true}]"
                                    rbac
                                    [denyMethod]="'style'"
                                    [allowStyle]="{display: 'inline-block'}"
                                    [denyStyle]="{display: 'none'}"
                                >
                                    <div>
                                        <mat-checkbox
                                            [(ngModel)]="contact.partner_company_settings.approve_income_bills"
                                            [disabled]="true"
                                            >Auto-Create Bills</mat-checkbox
                                        >
                                    </div>
                                    <div>
                                        <mat-checkbox
                                            [(ngModel)]="contact.partner_company_settings.approve_income_invoices"
                                            [disabled]="true"
                                            >Auto-Create Invoices</mat-checkbox
                                        >
                                    </div>

                                    <div>
                                        <mat-checkbox
                                            [(ngModel)]="contact.partner_company_settings.approve_payout_income_bills"
                                            [disabled]="true"
                                            >Auto-Create Bills From Payouts</mat-checkbox
                                        >
                                    </div>
                                    <div>
                                        <mat-checkbox
                                            [(ngModel)]="
                                                contact.partner_company_settings.approve_payout_income_invoices
                                            "
                                            [disabled]="true"
                                            >Auto-Create Invoices From Payouts</mat-checkbox
                                        >
                                    </div>
                                </div>
                                <h4>Recurring Invoices</h4>
                                <div
                                    class="ml-3"
                                    *ngIf="
                                        !contact.partner_company_settings ||
                                        !contact.partner_company_settings.approve_recurring_invoice_ids ||
                                        contact.partner_company_settings.approve_recurring_invoice_ids.length === 0
                                    "
                                >
                                    <span>No Recurring Invoices</span>
                                </div>
                                <div
                                    class="ml-3"
                                    *ngIf="
                                        contact.partner_company_settings &&
                                        contact.partner_company_settings.approve_recurring_invoice_ids &&
                                        contact.partner_company_settings.approve_recurring_invoice_ids.length > 0
                                    "
                                >
                                    <div
                                        class="mb-2"
                                        *ngFor="
                                            let ri of contact.partner_company_settings.approve_recurring_invoice_ids;
                                            index as idx
                                        "
                                    >
                                        <div>{{ idx + 1 }}. {{ ri.title }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab
                    label="Payroll"
                    *ngIf="
                        contact.id &&
                        !contact.is_delegated &&
                        !isDefaultCompanyContact &&
                        profilePermissions &&
                        profilePermissions.contacts__view_approvals
                    "
                >
                    <section class="py-2">
                        <app-contact-edit-payment-methods-deal-payouts
                            [anyRule]="[
                                {contacts__view_payment_methods: true},
                                {contacts__manage_company_disbursement_instruction: true}
                            ]"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            style="margin-left: 25px"
                            [view_mode]="true"
                            [contact]="contact"
                        >
                        </app-contact-edit-payment-methods-deal-payouts>

                        <mat-divider></mat-divider>

                        <app-contact-payrolls [contact]="contact" [isEditMode]="false"></app-contact-payrolls>
                    </section>
                </mat-tab>

                <mat-tab
                    label="Notes"
                    *ngIf="
                        contact.id &&
                        profilePermissions &&
                        profilePermissions.contacts__view_notes &&
                        !contact.is_delegated
                    "
                >
                    <section class="py-2">
                        <p>{{ contact.notes }}</p>

                        <div
                            class="py-3 mt-2"
                            [anyRule]="[
                                {contacts__manage_limited_contacts: true},
                                {contacts__manage_any_contacts: true}
                            ]"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                        >
                            <button
                                [rule]="{contacts__manage_notes: true}"
                                rbac
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'inline-block'}"
                                [denyStyle]="{display: 'none'}"
                                type="button"
                                mat-button
                                (click)="addNote()"
                            >
                                <mat-icon>add</mat-icon>
                                Note
                            </button>
                        </div>
                    </section>
                </mat-tab>

                <mat-tab label="Delegation" *ngIf="contact.is_delegated && !isDefaultCompanyContact">
                    <section class="py-2">
                        <p style="margin: 20px 0 0 20px">
                            This contact has been delegated to you by "{{ contact.creator_company!.title }}" company.
                        </p>

                        <div class="py-3">
                            <button
                                type="button"
                                mat-raised-button
                                (click)="addFromDelegate()"
                                style="margin: 20px 0 0 20px"
                            >
                                Create a new company contact from delegate
                            </button>
                        </div>
                        <div class="py-3">
                            <button
                                type="button"
                                mat-raised-button
                                (click)="isShowLinkSelectbox = !isShowLinkSelectbox"
                                style="margin: 0 20px"
                            >
                                Link delegate to an existing company contact
                            </button>
                            <div *ngIf="isShowLinkSelectbox" style="margin: 20px; max-width: 310px">
                                <app-company-compensation-combined-picker
                                    style="width: 100%"
                                    [availableTypes]="['individuals_in_group', 'contact', 'individual']"
                                    [title]="'Contact'"
                                    [only_compensation_groups]="false"
                                    [singleSelected]="true"
                                    [bold_style]="false"
                                    [nodesFA]="linkedUsersCtrlArr"
                                >
                                </app-company-compensation-combined-picker>
                            </div>
                        </div>
                    </section>
                </mat-tab>

                <mat-tab label="People" *ngIf="contact.is_delegated && !isDefaultCompanyContact">
                    <section class="my-3 w-100 align-items-baseline">
                        <ng-container *ngFor="let person of contact.contact_persons; index as ii; last as last">
                            <mat-card [class.mb-2]="last === false" class="px-0">
                                <div class="row w-100 align-items-center">
                                    <div class="col-12 d-flex align-items-center">
                                        <div class="px-2 mr-1" *ngIf="person.type === 'department'">
                                            <mat-icon mat-list-icon>group</mat-icon>
                                        </div>
                                        <div class="px-2 mr-1" *ngIf="person.type !== 'department'">
                                            <mat-icon mat-list-icon>person</mat-icon>
                                        </div>
                                        <h3 class="mb-0">
                                            {{ person.preferred_name ? person.preferred_name : person.first_name }}
                                            <span *ngIf="person.last_name">{{ person.last_name }}</span>
                                        </h3>

                                        <span class="sp-spacer"></span>
                                    </div>

                                    <div class="col-12 zp-p-to-locations">
                                        <span class="mr-2" *ngIf="person.phone">
                                            Phone: {{ person.phone | phoneMask }}
                                        </span>

                                        <span class="mr-2" *ngIf="person.fax"> Fax: {{ person.fax | phoneMask }} </span>

                                        <span class="mr-2" *ngIf="person.email">Email: {{ person.email }}</span>
                                    </div>
                                </div>
                            </mat-card>
                        </ng-container>
                    </section>
                </mat-tab>

                <mat-tab label="Locations" *ngIf="contact.is_delegated && !isDefaultCompanyContact">
                    <mat-card class="d-flex align-items-center mb-1" *ngFor="let location of contact.contact_locations">
                        <mat-icon class="mr-2">place</mat-icon>
                        <span *ngIf="location.label">{{ location.label + ':' }} &nbsp;</span>
                        <span *ngIf="location.street_number">{{ location.street_number }} &nbsp;</span>
                        <span *ngIf="location.street_address">{{ location.street_address }} &nbsp;</span>
                        <span *ngIf="location.unit_number">{{ location.unit_number }} &nbsp;</span>
                        <span *ngIf="location.city">{{ location.city + ',' }} &nbsp;</span>
                        <span *ngIf="location.state">{{ location.state }} &nbsp;</span>
                        <span *ngIf="location.zip">{{ location.zip }}</span>
                        <span *ngIf="location.country" class="text-muted">({{ location.country }})</span>
                    </mat-card>
                </mat-tab>
            </mat-tab-group>
        </div>
    </mat-card-content>

    <mat-card-actions>
        <ng-content></ng-content>
    </mat-card-actions>
</mat-card>
