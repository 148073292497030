import {Routes, RouterModule} from '@angular/router';
import {IndexComponent} from './index/index.component';
import {SettingsComponent} from './settings/settings.component';
import {UserLayoutComponent} from '../../layouts/user-layout.component';
import {AuthBootstrapService} from '../../services/resolvers/auth-bootstrap.service';
import {MarketplaceComponent} from './marketplace/marketplace.component';

import {routes as compensationRoutes} from './compensation/compensation.routing';
import {UsersLicensesComponent} from './users-licenses/users-licenses.component';
import {CompanyRolesManagementComponent} from './users-licenses/company-roles-management/company-roles-management.component';
import {routes as groupsRoutes} from './groups/groups.routing';
import {CompanyDataUploadsComponent} from './company-data-uploads/company-data-uploads.component';
import {AuthorizeGatewayEditComponent} from './company-gateway/company-gateway-edit/authorize-gateway-edit/authorize-gateway-edit.component';
import {RedirectGuard} from '../../services/resolvers/redirect-guard.service';
import {routes as divisionsRoutes} from './divisions/divisions.routing';
import {TagListComponent} from './settings/tabs/tab2-tags/tag-list/tag-list.component';
import {AddonConnectionComponent} from './company-gateway/company-gateway-edit/addon-connection/addon-connection.component';
import {PurchaseSettingsComponent} from './purchase-settings/purchase-settings.component';
import {AccessGuardService} from '../../services/resolvers/access-guard.service';
import {CompensationProfilesComponent} from './rules/profiles.component';
import {PendingChangesGuardService} from '../../services/resolvers/pending-changes-guard.service';
import {AutomationListComponent} from './settings/automation/automation-list/automation-list.component';
import {YodataSettingsComponent} from './marketplace/yodata-addon/yodata-settings.component';
import {DashAddonSettingsComponent} from './marketplace/dash-addon/dash-settings.component';
import {ZipiFinancialPageComponent} from './company-gateway/company-gateway-edit/zipi-finacial/zipi-fin-page/zipi-financial-page.component';
import {ZipiFinTransfersLogPageComponent} from './company-gateway/company-gateway-edit/zipi-finacial/zipi-fin-page/zipi-fin-gateway-container/zipi-fin-transfers-log/zipi-fin-transfers-log-page/zipi-fin-transfers-log-page.component';
import {QuickBooksAddonSettingsComponent} from './marketplace/quickbooks-addon/quickbooks-settings.component';

export const routes: Routes = [
    {
        path: 'marketplace',
        component: UserLayoutComponent,
        resolve: {
            authBootstrap: AuthBootstrapService
        },
        runGuardsAndResolvers: 'always',
        canActivate: [RedirectGuard, AccessGuardService],
        children: [
            {
                path: '',
                component: MarketplaceComponent,
                canActivate: [AccessGuardService],
                data: {
                    title: 'Marketplace',
                    access: {rule: {marketplace__access: true}}
                }
            },
            {
                path: 'setup/authorizenet',
                component: AuthorizeGatewayEditComponent,
                canActivate: [AccessGuardService],
                data: {
                    title: 'Authorize.Net',
                    access: {rule: {marketplace__manage_authorizenet: true}}
                }
            },
            {
                path: 'setup/moneytransfers',
                component: ZipiFinancialPageComponent,
                canActivate: [AccessGuardService],
                data: {
                    title: 'Money Transfers (EFT)',
                    access: {rule: {marketplace__manage_zipipay: true}}
                },
                canDeactivate: [PendingChangesGuardService]
            },
            {
                path: 'setup/yodata',
                component: YodataSettingsComponent,
                canActivate: [AccessGuardService],
                data: {
                    title: 'Yodata',
                    access: {rule: {marketplace__manage_yodata: true}}
                }
            },
            {
                path: 'setup/dash',
                component: DashAddonSettingsComponent,
                canActivate: [AccessGuardService],
                data: {
                    title: 'Dash (Realogy)',
                    access: {rule: {marketplace__manage_dash: true}}
                }
            },
            {
                path: 'setup/quickbooks',
                component: QuickBooksAddonSettingsComponent,
                canActivate: [AccessGuardService],
                data: {
                    title: 'QuickBooks Integration Setup',
                    access: {rule: {marketplace__manage_quickbooks: true}}
                }
            },
            {
                path: 'connection/addon/:addon',
                component: AddonConnectionComponent,
                canActivate: [AccessGuardService],
                data: {
                    title: 'Addon Connection',
                    access: {rule: {marketplace__access: true}}
                }
            }
        ]
    },
    {
        path: 'company',
        component: UserLayoutComponent,
        resolve: {
            authBootstrap: AuthBootstrapService
        },
        runGuardsAndResolvers: 'always',
        canActivate: [RedirectGuard, AccessGuardService],
        data: {
            access: {rule: {company_settings__access: true}},
            url: '/company',
            redirectGuardData: [
                {
                    path: 'finance',
                    rules: [
                        {company_settings__view_journals: true},
                        {company_settings__manage_compensation: true},
                        {company_settings__view_products: true},
                        {company_settings__view_ledger_accounts: true},
                        {company_settings__view_opening_balances: true}
                    ]
                },
                {path: 'import', rules: [{company_settings__manage_import_data: true}]},
                // { path: 'marketplace', rules: [{ marketplace__access: true }] },
                {
                    path: 'settings',
                    rules: [
                        {company_settings__manage_general_settings: true},
                        {company_settings__manage_purchase_settings: true},
                        {company_settings__manage_tags: true}
                    ]
                },
                {
                    path: 'directory',
                    rules: [
                        {company_settings__view_users: true},
                        {company_settings__view_groups: true},
                        {company_settings__view_divisions: true},
                        {company_settings__view_roles: true}
                    ]
                }
                // { path: 'journals', rules: [{}] },
                // { path: 'fundingsource', rules: [{}] },
            ]
        },
        children: [
            {
                path: '',
                component: IndexComponent,
                runGuardsAndResolvers: 'always', // important
                data: {
                    url: '/company'
                },

                children: [
                    {
                        runGuardsAndResolvers: 'always', // important
                        path: 'finance',
                        children: compensationRoutes
                    },
                    {
                        path: 'profiles',
                        component: CompensationProfilesComponent,
                        canActivate: [AccessGuardService],
                        data: {
                            title: 'Rules',
                            access: {rule: {company_settings__manage_compensation: true}}
                        },
                        canDeactivate: [PendingChangesGuardService]
                    },
                    {
                        path: 'finance/profiles',
                        redirectTo: 'profiles'
                    },
                    {
                        path: 'finance/templates',
                        redirectTo: 'profiles'
                    },
                    {
                        path: 'import',
                        component: CompanyDataUploadsComponent,
                        canActivate: [AccessGuardService],
                        data: {
                            title: 'Import',
                            access: {rule: {company_settings__manage_import_data: true}}
                        }
                    },
                    {
                        path: 'import/:id',
                        component: CompanyDataUploadsComponent,
                        canActivate: [AccessGuardService],
                        data: {
                            title: 'Import',
                            access: {rule: {company_settings__manage_import_data: true}}
                        }
                    },
                    {
                        path: 'calculation-settings',
                        loadChildren: () =>
                            import('../calculation-settings/calculation-settings.module').then(
                                (module) => module.CalculationSettingsModule
                            )
                    },
                    {
                        path: 'settings',
                        runGuardsAndResolvers: 'always',
                        canActivate: [RedirectGuard],
                        data: {
                            absolutePath: true,
                            url: '/company/settings',
                            redirectGuardData: [
                                {
                                    path: '/company/settings/general',
                                    rules: [{company_settings__manage_general_settings: true}]
                                },
                                {
                                    path: '/company/settings/purchase-settings',
                                    rules: [{company_settings__manage_purchase_settings: true}]
                                },
                                {path: '/company/settings/tags', rules: [{company_settings__manage_tags: true}]}
                            ],
                            title: 'Settings'
                        },
                        children: [
                            {
                                path: 'general',
                                component: SettingsComponent,
                                canActivate: [AccessGuardService],
                                data: {
                                    title: 'Settings',
                                    access: {rule: {company_settings__manage_general_settings: true}}
                                }
                            },
                            {
                                path: 'purchase-settings',
                                component: PurchaseSettingsComponent,
                                canActivate: [AccessGuardService],
                                data: {
                                    title: 'Purchase Settings',
                                    access: {rule: {company_settings__manage_purchase_settings: true}}
                                }
                            },
                            {
                                path: 'tags',
                                component: TagListComponent,
                                canActivate: [AccessGuardService],
                                data: {
                                    title: 'Tags',
                                    access: {rule: {company_settings__manage_tags: true}}
                                }
                            },
                            {
                                path: 'automation',
                                component: AutomationListComponent,
                                canActivate: [AccessGuardService],
                                data: {
                                    title: 'Automation',
                                    access: {rule: {company_settings__manage_tags: true}}
                                }
                            }
                        ]
                    },

                    // {
                    //     path: 'commission/wizard',
                    //     component: WizardComponent
                    // },
                    // @todo: We really don't need this page?
                    // {
                    //     path: 'goals',
                    //     children: goalsRoutes,
                    // },
                    // { path: 'sharing', component: CompanyDataPageComponent },
                    // { path: 'uploads', component: ImportLogsComponent },
                    // {
                    //     path: 'custom-fields',
                    //     component: CustomFieldsComponent,
                    //     children: [
                    //         {
                    //             path: '',
                    //             redirectTo: 'columns-settings',
                    //             pathMatch: 'full'
                    //
                    //         },
                    //         {
                    //             path: 'columns-settings',
                    //             component: ShipmentColumnsSettingsComponent,
                    //         },
                    //         {
                    //             path: 'value-groups',
                    //             children: [
                    //                 { path: '', component: ValueGroupsComponent },
                    //                 { path: 'create', component: ValueGroupEditComponent },
                    //                 { path: ':id', component: ValueGroupEditComponent }
                    //             ]
                    //         }
                    //     ]
                    // },

                    {
                        path: 'directory',
                        runGuardsAndResolvers: 'always',
                        canActivate: [RedirectGuard],
                        data: {
                            absolutePath: true,
                            url: '/company/directory',
                            redirectGuardData: [
                                {path: '/company/directory/users', rules: [{manage_users: true}]},
                                {path: '/company/directory/divisions', rules: [{manage_groups: true}]},
                                {path: '/company/directory/groups', rules: [{manage_groups: true}]},
                                {path: '/company/directory/roles', rules: [{manage_users: true}]}
                            ],
                            title: 'Directory'
                        },
                        children: [
                            {
                                path: 'users',
                                component: UsersLicensesComponent,
                                canActivate: [AccessGuardService],
                                children: [
                                    {path: '', pathMatch: 'full', component: UsersLicensesComponent},
                                    {path: ':id', component: UsersLicensesComponent}
                                ],
                                data: {
                                    access: {rule: {company_settings__view_users: true}}
                                }
                            },
                            {
                                path: 'groups',
                                children: groupsRoutes,
                                canActivate: [AccessGuardService],
                                data: {
                                    title: 'Groups',
                                    access: {rule: {company_settings__view_groups: true}}
                                }
                            },
                            {
                                path: 'divisions',
                                children: divisionsRoutes,
                                canActivate: [AccessGuardService],
                                data: {
                                    title: 'Divisions',
                                    access: {rule: {company_settings__view_divisions: true}}
                                }
                            },
                            {
                                path: 'roles',
                                component: CompanyRolesManagementComponent,
                                canActivate: [AccessGuardService],
                                data: {
                                    title: 'Roles',
                                    access: {rule: {company_settings__view_roles: true}}
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

export const routing = RouterModule.forChild(routes);
