<section class="container-fluid" *ngIf="bill">
    <!--    <div class="d-flex mt-2"-->
    <!--         [rule]="{sales__manage_source_documents: true}" rbac [denyMethod]="'style'"-->
    <!--         [allowStyle]="{display : 'inline-block'}" [denyStyle]="{display : 'none'}">-->
    <!--        <div  *ngIf="bill.status_in_portal !== 'connected'">-->
    <!--            <button mat-raised-button-->
    <!--                    class="ml-2"-->
    <!--                    [disabled]="bill.status_in_portal === 'archived'"-->
    <!--                    (click)="createAndConnectInvoiceFromBill()"-->
    <!--                    [rule]="{sales__view_invoices: true}" rbac [denyMethod]="'style'" [allowStyle]="{display : 'inline-block'}" [denyStyle]="{display : 'none'}">-->
    <!--                Approve as Connected Invoice-->
    <!--            </button>-->
    <!--        </div>-->
    <!--        <div  *ngIf="bill.status_in_portal !== 'linked'">-->
    <!--            <button mat-raised-button-->
    <!--                    class="ml-2"-->
    <!--                    [disabled]="bill.status_in_portal === 'archived' || bill.status_in_portal === 'connected'"-->
    <!--                    (click)="createAndLinkInvoiceFromBill()"-->
    <!--                    [rule]="{sales__view_invoices: true}" rbac [denyMethod]="'style'" [allowStyle]="{display : 'inline-block'}" [denyStyle]="{display : 'none'}">-->
    <!--                Approve as Linked Invoice-->
    <!--            </button>-->
    <!--        </div>-->
    <!--        <div  *ngIf="bill.status_in_portal !== 'archived'">-->
    <!--            <button mat-raised-button class="ml-2" [disabled]="bill.status_in_portal === 'connected'"-->
    <!--                    (click)="archiveBill()">Archive</button>-->
    <!--        </div>-->
    <!--    </div>-->

    <mat-card class="mt-3 px-4 py-5">
        <header class="row">
            <div class="col-9 zp-company-info">
                <!-- <p>Company Logo</p> -->
                <p class="zp-company-title">{{ companyTitle }}</p>
            </div>

            <div class="col-3 text-right">
                <p class="zp-inveice_title">Bill</p>
                <p class="zp-invoice-num_value">#{{ bill?.bill_number || 'N/A' }}</p>

                <p class="zp-refference_due_title">Amount: {{ bill?.total_amount | currency }}</p>
            </div>
        </header>

        <p *ngIf="vendorContact !== false">
            Bill From:
            <ng-template [ngIf]="vendorContact?.display_name" [ngIfElse]="unknownContactNGTemplate">
                {{ vendorContact.display_name }}
            </ng-template>
            <ng-template #unknownContactNGTemplate>
                <span style="color: #3c3c3c; font-style: italic">Contact not in your list...</span>
            </ng-template>
        </p>

        <div class="row">
            <div class="col-6">
                <div class="pl-2" *ngFor="let location of bill?.money_receiver_company?.locations">
                    <p *ngIf="location.label === bill?.money_receiver_company?.settings?.billing_address_label">
                        <span *ngIf="location.street_number">{{ location.street_number }} </span>
                        <span *ngIf="location.street_address">{{ location.street_address }} </span>
                        <span *ngIf="location.unit_number">{{ location.unit_number }} </span>
                        <br />
                        <span *ngIf="location.city">{{ location.city }}, </span>
                        <span *ngIf="location.state">{{ location.state }} </span>
                        <span *ngIf="location.zip">{{ location.zip }} </span>
                        <br />
                        <span *ngIf="location.country" class="text-muted">({{ location.country }})</span>
                    </p>
                </div>
            </div>
            <!--            <div class="col-6">-->
            <!--                <ng-container *ngIf="billing_address">-->
            <!--                    <span *ngIf="billing_address?.label">{{billing_address.label + ':'}}</span>-->
            <!--                    <span *ngIf="billing_address?.street_number">{{billing_address.street_number}}</span>-->
            <!--                    <span *ngIf="billing_address?.street_address">{{billing_address.street_address}}</span>-->
            <!--                    <span *ngIf="billing_address?.unit_number">{{billing_address.unit_number}}</span>-->
            <!--                    <ng-container *ngIf="billing_address?.city">-->
            <!--                        <br>-->
            <!--                        <span>{{billing_address.city + ','}}</span>-->
            <!--                    </ng-container>-->
            <!--                    <span *ngIf="billing_address?.state">{{billing_address.state}}</span>-->
            <!--                    <span *ngIf="billing_address?.zip">{{billing_address.zip}}</span>-->
            <!--                    <span *ngIf="billing_address?.country" class="text-muted">({{billing_address.country}})</span>-->
            <!--                </ng-container>-->
            <!--            </div>-->

            <div class="col-6 text-right">
                <p class="mb-0">
                    <span>Date:</span>
                    <span class="zp-w-150">{{ $any(bill?.invoice_date) | dateFromNumber | date: 'shortDate' }}</span>
                </p>

                <p class="mb-0">
                    <span>Reference:</span>
                    <span class="zp-w-150" *ngIf="bill?.connected_invoice?.invoice_number">{{
                        bill?.connected_invoice?.invoice_number
                    }}</span>
                    <span class="zp-w-150" *ngIf="!bill.connected_invoice?.invoice_number">N/A</span>
                </p>

                <p class="mb-0">
                    <span>Terms:</span>
                    <span class="zp-w-150" *ngIf="bill?.payment_terms && bill?.payment_terms !== 'custom'">{{
                        termsMap.get($any(bill?.payment_terms))
                    }}</span>
                    <span class="zp-w-150" *ngIf="bill?.payment_terms && bill?.payment_terms === 'custom'"
                        >Net {{ bill?.payment_terms_custom }}</span
                    >
                </p>

                <p>
                    <span>Due Date:</span>
                    <span class="zp-w-150">{{ $any(bill?.due_date) | dateFromNumber | date: 'shortDate' }}</span>
                </p>
            </div>
        </div>

        <table class="table" *ngIf="bill.items && bill.items.length > 0">
            <thead class="bg-secondary text-white">
                <tr>
                    <td scope="col">#</td>
                    <td class="w-50" scope="col">Item</td>
                    <td scope="col">Qty</td>
                    <td scope="col">Rate</td>
                    <td scope="col" class="text-right">Amount</td>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of bill?.items; index as idx">
                    <td>{{ idx + 1 }}</td>
                    <td class="w-50">
                        {{ item?.name }}
                        <i class="text-muted">{{ item?.description }}</i>
                    </td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.rate }}</td>
                    <td class="text-right">{{ item.amount }}</td>
                </tr>
            </tbody>
        </table>

        <div *ngIf="bill.items && bill.items.length === 0">
            <h3>Items Preview</h3>
            <table class="table">
                <thead class="bg-secondary text-white">
                    <tr>
                        <td class="w-5" scope="col">#</td>
                        <td class="w-35" scope="col">Item</td>
                        <td class="w-10" scope="col">Qty</td>
                        <td class="w-10" scope="col">Rate</td>
                        <td class="w-10 text-right" scope="col">Amount</td>
                        <td class="w-30 text-right" scope="col">Associate</td>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of bill?.items_preview; index as idx">
                        <td class="w-10">{{ idx + 1 }}</td>
                        <td class="w-40">
                            {{ item?.name }}
                            <i class="text-muted">{{ item?.description }}</i>
                        </td>
                        <td class="w-15">{{ item?.quantity }}</td>
                        <td class="w-15">{{ item?.rate }}</td>
                        <td class="w-20 text-right">{{ item?.amount }}</td>

                        <!--				    <td class="w-30 text-right">-->
                        <!--					    <mat-select placeholder="Item">-->
                        <!--						    <mat-option-->
                        <!--								    *ngFor="let product of product$ | async"-->
                        <!--								    [value]="product.id">-->
                        <!--							    {{product.name}}-->
                        <!--						    </mat-option>-->
                        <!--					    </mat-select>-->
                        <!--				    </td>-->
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="w-100 text-right">
            <p class="mb-0">
                Sub Total: <span class="zp-total-nember">{{ bill?.total_amount }}</span>
            </p>
            <p class="mb-0">
                Total: <span class="zp-total-nember">{{ bill?.total_amount | currency }}</span>
            </p>
            <div *ngIf="bill.payments && bill.payments.length > 0">
                <div *ngFor="let payment of bill.payments; let i = index">
                    <div
                        class="py-2"
                        *ngIf="['completed', 'processing', 'pending', 'review'].includes(payment.status)"
                        [ngStyle]="{
                            color:
                                payment.status === 'completed'
                                    ? 'black'
                                    : payment.status === 'pending'
                                      ? 'orange'
                                      : payment.status === 'canceled' || payment.status === 'error'
                                        ? '#f94242'
                                        : 'black'
                        }"
                    >
                        <div>
                            <span>
                                <span
                                    *ngIf="
                                        payment.source_type === 'based_on_customer_credit' ||
                                        payment.source_type === 'vendor_credit'
                                    "
                                    >Credits</span
                                >
                                <span
                                    *ngIf="
                                        payment.source_type === 'payment_received' ||
                                        payment.source_type === 'payment_made' ||
                                        payment.source_type === 'deduction'
                                    "
                                    >Payment</span
                                >
                            </span>

                            <strong class="zp-total-nember">{{ payment.amount | currency }}</strong>
                        </div>

                        <div *ngIf="payment.status === 'processing'" style="line-height: 2px">
                            <span class="zp-w-150"></span>

                            <span class="zp-total-nember" style="color: grey; font-size: 14px"> (processing) </span>
                        </div>

                        <div *ngIf="payment.status === 'pending'" style="line-height: 2px">
                            <span class="zp-w-150"></span>

                            <span class="zp-total-nember" style="color: grey; font-size: 14px"> (Deducted) </span>
                        </div>

                        <div
                            *ngIf="payment.status === 'canceled' || payment.status === 'error'"
                            style="line-height: 2px"
                        >
                            <span class="zp-w-150"></span>

                            <span class="zp-total-nember" style="color: grey; font-size: 14px">
                                ({{ payment.status }})
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <p>
                <strong class="bg-light py-2">
                    <span class="zp-w-150">&nbsp;</span>
                    Balance:
                    <strong class="zp-total-nember">{{ bill?.balance | currency }}</strong>
                </strong>
            </p>
            <p>
                <span class="bg-light py-2" *ngIf="isProcessingPayment()">
                    <span class="zp-w-150">&nbsp;</span>
                    Pending Balance:
                    <span class="zp-total-nember">{{ bill?.pending_balance | currency }}</span>
                </span>
            </p>
        </div>
        <div class="my-3">
            Related Deals
            <button mat-icon-button (click)="showTransfers = !showTransfers">
                <mat-icon *ngIf="showTransfers">expand_more</mat-icon>
                <mat-icon *ngIf="!showTransfers">chevron_right</mat-icon>
            </button>

            <div *ngIf="showTransfers">
                <table
                    mat-table
                    *ngIf="transfersDataSource.data && transfersDataSource.data.length > 0"
                    [dataSource]="transfersDataSource"
                    class="mat-elevation-z1 w-100 mx-3 journals-table"
                >
                    <ng-container matColumnDef="index">
                        <th mat-header-cell *matHeaderCellDef disableClear class="w-5">#</th>
                        <td mat-cell *matCellDef="let element; let i = index" class="w-5">{{ i + 1 }}</td>
                    </ng-container>

                    <ng-container matColumnDef="deal_address">
                        <th mat-header-cell *matHeaderCellDef class="w-45">Deal Address</th>
                        <td mat-cell *matCellDef="let element" class="w-45">
                            <span *ngIf="!element.deal_address" class="text-muted">No address </span>
                            <span *ngIf="element.deal_address"
                                >{{ element.deal_street_number }} {{ element.deal_address }} {{ element.deal_city }},
                                {{ element.deal_state }} {{ element.deal_zip }}
                            </span>
                            — {{ element.deal_type }}/{{ element.deal_status }}
                            <ng-container *ngIf="!element.deal_id">
                                <span *ngIf="!element.deal_address" class="text-muted">No address </span>
                                <span *ngIf="element.deal_address"
                                    >{{ element.deal_street_number }}&nbsp;{{ element.deal_address }} &nbsp;{{
                                        element.deal_city
                                    }},&nbsp;{{ element.deal_state }}&nbsp;{{ element.deal_zip }}
                                </span>
                                — {{ element.deal_type }}/{{ element.deal_status }}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="product">
                        <th mat-header-cell *matHeaderCellDef class="w-25">Product</th>
                        <td mat-cell *matCellDef="let element" class="w-25">
                            <span *ngIf="element.product_id; else elseBlock">
                                {{ element.product_name }}
                                <i class="text-muted">{{ element.product_description }}</i>
                            </span>
                            <ng-template #elseBlock>--</ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="connection_type">
                        <th mat-header-cell *matHeaderCellDef class="w-15">Connection Type</th>
                        <td mat-cell *matCellDef="let element" class="w-15">
                            {{ FINANCIAL_TRANSFER_TYPE_LABELS[element.type]! }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            style="text-align: right"
                            class="w-10 justify-content-end"
                        >
                            Amount
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            style="text-align: right"
                            class="w-10 justify-content-end"
                        >
                            {{ element.amount | currency }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="transferDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; let i = index; columns: transferDisplayedColumns"></tr>
                </table>
            </div>
        </div>
    </mat-card>
</section>
