import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {Observable} from 'rxjs';
import {
    IAccountingLog,
    IPaymentReceived,
    IPaymentReceivedMadeRequestObject,
    ITransactionExternal
} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class PaymentsReceivedService {
    private url = '/finance/paymentreceived/';

    constructor(protected requester: ServiceRequester) {}

    getPaymentsReceived(params = {}): Observable<{_meta: {total: number}; result: IPaymentReceived[]}> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp', params);
    }

    getContactOverpaymentPaymentsReceived(contactId: number): Observable<IPaymentReceived[]> {
        return this.requester.makeMsCall$(this.url + `${contactId}` + '/overpayment', 'GET', 'shipp');
    }

    getPaymentReceivedById(payment_id: number): Observable<IPaymentReceived> {
        return this.requester.makeMsCall$(this.url + `${payment_id}`, 'GET', 'shipp');
    }

    getRefundedPaymentsByPaymentReceivedId(paymentReceivedId: number): Observable<IPaymentReceived[]> {
        return this.requester.makeMsCall$(this.url + `${paymentReceivedId}/refunded`, 'GET', 'shipp');
    }

    createPaymentReceived(
        data: Partial<IPaymentReceivedMadeRequestObject> & {external_transactions?: ITransactionExternal[]}
    ): Observable<boolean> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', {data: data});
    }

    editPaymentReceived(paymentReceivedId: number, data: IPaymentReceivedMadeRequestObject): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + paymentReceivedId, 'PUT', 'shipp', {data: data});
    }

    applyToInvoices(
        paymentReceivedId: number,
        data: Array<{invoice_id: number; amount_to_credit: number}>
    ): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + paymentReceivedId + '/apply', 'PUT', 'shipp', data);
    }

    recheckCompanyReceivedPaymentsStatuses() {
        return this.requester.makeMsCall$(this.url + 'recheck', 'GET', 'shipp');
    }

    recheckPaymentReceivedStatusById(paymentReceivedId: number): Observable<string> {
        return this.requester.makeMsCall$(this.url + `${paymentReceivedId}/recheck`, 'GET', 'shipp');
    }

    refundPayment(paymentReceivedId: number, data: IPaymentReceivedMadeRequestObject): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${paymentReceivedId}/refund`, 'POST', 'shipp', data);
    }

    voidPaymentReceived(paymentReceivedId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${paymentReceivedId}/void`, 'GET', 'shipp');
    }

    deletePaymentById(paymentReceivedId: number) {
        return this.requester.makeMsCall$(this.url + `${paymentReceivedId}`, 'DELETE', 'shipp');
    }

    bulkDeletePaymentsReceived(paymentsReceivedIds: Array<number>): Observable<{
        not_deleted_payments: number;
        deleted_payments: number;
    }> {
        return this.requester.makeMsCall$(this.url + `bulk/delete`, 'PUT', 'shipp', paymentsReceivedIds);
    }

    getPaymentReceivedHistory(paymentReceivedId: number): Observable<IAccountingLog[]> {
        return this.requester.makeMsCall$(this.url + `${paymentReceivedId}/log`, 'GET', 'shipp');
    }
}
