import {Routes, RouterModule} from '@angular/router';
import {UserLayoutComponent} from '../../layouts/user-layout.component';
import {AuthBootstrapService} from 'app/services/resolvers/auth-bootstrap.service';
import {DenyGlobalViewResolver} from '../../services/resolvers/deny-global-view.resolver';
import {FocusAreasComponent} from './focus-areas/focus-areas.component';
import {ProgressComponent} from './progress.component';
import {AccessGuardService} from '../../services/resolvers/access-guard.service';

export const routes: Routes = [
    {
        // path: 'progress',
        path: 'tasks',
        component: UserLayoutComponent,
        resolve: {
            denyGv: DenyGlobalViewResolver,
            authBootstrap: AuthBootstrapService
        },
        runGuardsAndResolvers: 'always',
        canActivate: [AccessGuardService],
        data: {
            title: 'Tasks',
            access: {rule: {progress__access: true}}
        },
        children: [
            {
                path: '',
                component: ProgressComponent,
                children: [
                    {
                        path: '',
                        component: FocusAreasComponent
                    }
                ]
            }
        ]
    }
];

export const routing = RouterModule.forChild(routes);
