import {Component, OnDestroy} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {Profile} from '../models/profile';
import {CurrentProfileSource} from '../services/sources/current-profile.source';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-sign-in',
    styles: [
        `
            .subscription-inactive-card {
                margin-top: 20vh;
                width: 50vw;
                margin-left: 25vw;
                display: flex;
                flex-direction: row;
            }
            .error-content {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        `
    ],
    template: `
        <mat-card class="subscription-inactive-card">
            <mat-card-content class="error-content">
                <mat-card-title> {{ currentProfile?.company?.title }} Subscription </mat-card-title>
                <div>
                    The company subscription is <strong>inactive</strong>.<br /><br />
                    For further information, please contact your company administrator.
                    <br /><br />
                </div>
                <mat-card-actions align="end">
                    <button
                        rbac
                        [rule]="{manage_account: true}"
                        mat-button
                        class="mat-primary"
                        (click)="updateSubscriptionCard()"
                    >
                        Update card
                    </button>
                    <button mat-button (click)="doLogout()">Logout</button>
                </mat-card-actions>
            </mat-card-content>
        </mat-card>
    `
})
export class SubscriptionInactiveComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    public availableProfiles = [];
    public currentProfile: Profile | null = null;

    constructor(
        public authService: AuthService,
        protected router: Router,
        protected currentProfileSource: CurrentProfileSource
    ) {
        this.currentProfileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe((currProfile) => {
            this.currentProfile = currProfile;
        });
    }

    doLogout() {
        this.authService.logout().then(() => {
            localStorage.removeItem('zipi_last_profile_id');
            window.location.replace(window.location.pathname);
        });
    }

    updateSubscriptionCard() {
        // this.billingService.getHPUpdateSubscription(this.currentProfile.company.id)
        //     .then(hostedPage => {
        //         if (hostedPage.url) {
        //             window.location.href = hostedPage.url;
        //         }
        //     });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
