import {Injectable} from '@angular/core';
import {ServiceRequester} from './service.requester';

import {User} from '../models/user';
import {NotificationsServiceZipi} from '../modules/notifications/notifications.service';
import {Profile} from '../models/profile';
import {Company} from '../models/company';
import {ICompany, IUser} from '../../typings';
import {Observable} from 'rxjs';
import {SourceOfBusiness} from '../models/source-of-business';
import {SubType} from '../models/sub-type';
import {SubSource} from '../models/sub-source';

@Injectable()
export class UserService {
    private url = '/user/';

    constructor(
        public requester: ServiceRequester,
        protected notificationsService: NotificationsServiceZipi
    ) {}

    getAll(filters?: any) {
        if (filters) {
            // todo why did not move this into service requester?
            Object.keys(filters).forEach((v, i, a) => {
                filters[a[i]] = encodeURIComponent(filters[a[i]]);
            });
        }

        return this.requester.makeMsCall(this.url, 'GET', filters);
    }

    getMe() {
        return this.requester.makeMsCall(this.url + 'me', 'GET');
    }

    getMeByEmail() {
        return this.requester.makeMsCall(this.url + 'me-by-email', 'GET');
    }

    getCheckMe() {
        return this.requester.makeMsCall(this.url + 'me', 'GET');
    }

    revoke(id: number) {
        return this.requester.makeMsCall(this.url + 'revoke/' + id, 'PUT');
    }

    create(data: any) {
        return this.requester.makeMsCall(this.url, 'POST', data);
    }

    update(id: number, data: any) {
        return this.requester.makeMsCall(this.url + id, 'PUT', data);
    }

    afterRegister(user: User, company: Company, invited_contact_id: number | null = null) {
        return this.requester.makeMsCall(`/public/after-register`, 'POST', {
            user,
            company,
            invited_contact_id
        });
    }

    checkIfUserExists(firebaseId: string) {
        return this.requester.makeMsCall(`/public/check-user-exists?firebase_id=${firebaseId}`, 'GET');
    }

    checkIfOktaUserExists(OktaUid: string) {
        return this.requester.makeMsCall(`/public/check-user-exists?okta_id=${OktaUid}`, 'GET');
    }

    createFinancialsForProfile(profileId: number) {
        return this.requester.makeMsCall(`/public/financials/${profileId}`, 'POST');
    }

    // afterRegisterCustomer(user: User, company: Company) {
    //     return this.requester.makeMsCall(
    //         `/public/after-register/customer`,
    //         'POST',
    //         {
    //             user,
    //             company
    //         }
    //     );
    // }
    //
    // afterRegisterCarrier(user: User, company: Company) {
    //     return this.requester.makeMsCall(
    //         `/public/after-register/carrier`,
    //         'POST',
    //         {
    //             user,
    //             company
    //         }
    //     );
    // }
    //
    // afterRegisterShipper(contactInvite, user: User, company: Company) {
    //     return this.requester.makeMsCall(
    //         `/public/after-register/shipper`,
    //         'POST',
    //         {
    //             contactInvite,
    //             user,
    //             company
    //         }
    //     );
    // }

    afterRegisterContactInvite(contactInviteHash: string, user: IUser, company: ICompany): Observable<any> {
        return this.requester.makeMsCall$(
            `/public/contact-invites/${contactInviteHash}/after-register`,
            'POST',
            'shipp',
            {
                user,
                company
            }
        );
    }
    afterRegisterContactInvoice(invoiceHash: string, user: IUser, company: ICompany): Observable<any> {
        return this.requester.makeMsCall$(`/public/invoice/${invoiceHash}/after-register`, 'POST', 'shipp', {
            user,
            company
        });
    }

    afterRegisterContactDepositRequest(
        requestHash: string,
        user: IUser | undefined,
        company: ICompany
    ): Observable<any> {
        return this.requester.makeMsCall$(
            `/public/deal-deposit/deposit-request/${requestHash}/after-register`,
            'POST',
            'shipp',
            {
                user,
                company
            }
        );
    }

    getUserProfiles(): Promise<Profile[]> {
        return this.requester.makeMsCall(this.url + 'available-profiles', 'GET');
    }

    shareProfile(userId: number, profileId: number): Promise<any> {
        return this.requester.makeMsCall(this.url + userId + '/share/' + profileId, 'PUT');
    }

    /** Use store */
    updateSOBsListForCurrentProfile(sobsList: SourceOfBusiness[]): Promise<boolean> {
        return this.requester.makeMsCall(this.url + 'update-sobs-for-current-profile', 'PUT', {
            sobs: sobsList
        });
    }

    /** Use store */
    updateSubTypesListForCurrentProfile(subTypesList: SubType[]): Promise<boolean> {
        return this.requester.makeMsCall(this.url + 'update-sub-types-for-current-profile', 'PUT', {
            subTypes: subTypesList
        });
    }

    /** Use store */
    updateSubSourcesListForCurrentProfile(subSourcesList: SubSource[]): Promise<boolean> {
        return this.requester.makeMsCall(this.url + 'update-sub-sources-for-current-profile', 'PUT', {
            subSources: subSourcesList
        });
    }

    changeCurrentUserSignature(firstName: string, lastName: string): Promise<any> {
        return this.requester.makeMsCall(this.url + 'update-signature-for-current-user', 'PUT', {
            first_name: firstName,
            last_name: lastName
        });
    }

    changeCurrentUserCredentials(email: string) {
        return this.requester.makeMsCall(this.url + 'update-credentials-for-current-user', 'PUT', {
            email: email
        });
    }

    changeHelpButtonNecessity(isHelpButtonShown: boolean): Promise<any> {
        return this.requester.makeMsCall(this.url + 'update-help-button-necessity', 'PUT', {
            is_help_button_shown: isHelpButtonShown
        });
    }

    getCommunityUrl() {
        return this.requester.makeMsCall(this.url + 'get-community-url', 'GET');
    }

    getOktaUserByEmail(email: string) {
        return this.requester.makeMsCall(this.url + 'get-okta-user-by-email?email=' + encodeURIComponent(email), 'GET');
    }

    getOktaMetadataByKey(key: string, metadata: any[]) {
        const metadataObj = metadata.find((obj) => Object.prototype.hasOwnProperty.call(obj, key));
        if (metadataObj) {
            return metadataObj[key];
        }
        return null;
    }

    convertSkySlopeUser() {
        return this.requester.makeMsCall(this.url + 'convert-user-to-skyslope', 'POST', {});
    }
}
