import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {ZipiFinancialTransferService} from '../../../../../../../../../services/api/finance/zipi-financia-transfer.service';
import {takeUntil} from 'rxjs/operators';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {RbacService} from '../../../../../../../../rbac/rbac.service';
import {FormGroupWithFormControls} from '../../../../../../../../../typings/common';
import {PAGE_SIZE_OPTIONS} from '../../../../../../../../../local-typings';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {cleanCurrencyString} from '../../../../../../../../../utilities/maskito';

@Component({
    selector: 'app-zipi-fin-transfers-data-table',
    templateUrl: './zipi-fin-transfers-log-data-table.component.html',
    styleUrls: [
        './zipi-fin-transfers-log-data-table.component.scss',
        '../../../../../../../../../../assets/infinite-scroll-table.scss'
    ]
})
export class ZipiFinTransfersLogDataTableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();
    @Input() gatewayId: number | undefined;
    @Input() driverType: 'plaid' | 'moov' | 'muhnee' | null | undefined;
    @Input() tabTag: 'completed' | 'processing' | 'scheduled' | 'canceled' | undefined;

    @ViewChild('mainPaginator') mainPaginator: MatPaginator | undefined;
    @ViewChild('additionalPaginator') additionalPaginator: MatPaginator | undefined;

    filterForm: FormGroupWithFormControls | undefined;

    additionalDisplayedColumns = ['date', 'vendor_title', 'amount', 'status'];
    mainDisplayedColumns = ['date', 'vendor_title', 'amount'];
    mainPageSizeOptions = PAGE_SIZE_OPTIONS;
    additionalPageSizeOptions = [5];
    scrollData = {
        offset: 0,
        limit: 25,
        sortColumn: 'date',
        sortDirection: 'desc',
        status: '',
        startAmount: '',
        endAmount: '',
        startDate: '',
        endDate: '',
        total: 0,
        methods: [] as Array<any>,
        direction: null,
        contact: null,
        searchString: null,
        statuses: [] as Array<any>
    };
    additionalScrollData = {
        offset: 0,
        limit: 5,
        sortColumn: 'date',
        sortDirection: 'desc',
        status: '',
        startAmount: '',
        endAmount: '',
        startDate: '',
        endDate: '',
        total: 0,
        methods: [] as Array<any>,
        direction: null,
        contact: null,
        searchString: null,
        statuses: [] as Array<any>
    };
    mainDataSource: MatTableDataSource<any>;
    additionalDataSource: MatTableDataSource<any>;
    isLoading: boolean = false;
    isFilterSectionOpen: boolean = false;

    additionalSectionCollapsed: boolean = false;

    constructor(
        protected fb: UntypedFormBuilder,
        public dialog: MatDialog,
        protected rbacService: RbacService,
        private zipiFinancialTransferService: ZipiFinancialTransferService
    ) {
        this.mainDataSource = new MatTableDataSource<any>([]);
        this.additionalDataSource = new MatTableDataSource<any>([]);
    }

    ngOnInit() {
        this.filterForm = this.fb.group({
            status: ['', []],
            startAmount: ['', [Validators.min(0)]],
            endAmount: ['', [Validators.min(0)]],
            startDate: ['', []],
            endDate: ['', []],
            method: ['', []],
            direction: ['', []],
            search: ['', []]
        }) as FormGroupWithFormControls;
    }

    ngAfterViewInit() {
        if (this.mainPaginator) {
            this.mainPaginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.nextBatch();
            });
        }

        if (this.additionalPaginator) {
            this.additionalPaginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.additionalScrollData.limit = data.pageSize;
                this.additionalScrollData.offset = data.pageSize * data.pageIndex;

                this.nextBatch();
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('tabTag') && changes.tabTag.currentValue) {
            if (this.driverType === 'moov') {
                if (changes.tabTag.currentValue === 'completed') {
                    this.mainDisplayedColumns.push('balance');
                } else {
                    this.mainDisplayedColumns.push('status');
                }
            } else {
                if (changes.tabTag.currentValue !== 'completed') {
                    this.mainDisplayedColumns.push('status');
                }
                this.mainDisplayedColumns.splice(1, 0, 'my_bank');
                this.additionalDisplayedColumns.splice(1, 0, 'my_bank');
            }
            this.resetData();
        }
    }

    getScrollData() {
        if (!this.filterForm) {
            return null;
        }
        const data = this.filterForm.getRawValue();
        this.scrollData.startAmount =
            data.startAmount !== '' ? (Number(cleanCurrencyString(data.startAmount)) as any) : '';
        this.scrollData.endAmount = data.endAmount !== '' ? (Number(cleanCurrencyString(data.endAmount)) as any) : '';
        this.scrollData.status = data.status;
        this.scrollData.startDate = data.startDate;
        this.scrollData.endDate = data.endDate;
        this.scrollData.methods = !!data.method ? [data.method] : [];
        this.scrollData.direction = data.direction;
        this.scrollData.searchString = data.search;
        this.additionalScrollData.startAmount =
            data.startAmount !== '' ? (Number(cleanCurrencyString(data.startAmount)) as any) : '';
        this.additionalScrollData.endAmount =
            data.endAmount !== '' ? (Number(cleanCurrencyString(data.endAmount)) as any) : '';
        this.additionalScrollData.status = data.status;
        this.additionalScrollData.startDate = data.startDate;
        this.additionalScrollData.endDate = data.endDate;
        this.additionalScrollData.methods = !!data.method ? [data.method] : [];
        this.additionalScrollData.direction = data.direction;
        this.additionalScrollData.searchString = data.search;
    }

    resetData() {
        if (this.mainPaginator) {
            this.mainPaginator.pageIndex = 0;
        }
        if (this.additionalPaginator) {
            this.additionalPaginator.pageIndex = 0;
        }
        this.scrollData.offset = 0;
        this.scrollData.total = 0;
        this.additionalScrollData.offset = 0;
        this.additionalScrollData.total = 0;

        this.nextBatch();
    }

    resetFilters() {
        this.filterForm?.patchValue({
            status: '',
            startAmount: '',
            endAmount: '',
            startDate: null,
            endDate: null,
            method: '',
            direction: '',
            search: ''
        });
    }

    nextBatch() {
        this.unsubscribeBatch.next();
        this.isLoading = true;

        this.getScrollData();

        this.loadTransfers();
    }

    loadTransfers() {
        switch (this.tabTag) {
            case 'completed': {
                this.scrollData.statuses = ['completed'];
                this.getTransfers(this.scrollData, 'main');
                this.additionalScrollData.statuses = ['processing', 'queued'];
                this.getTransfers(this.additionalScrollData, 'additional');
                break;
            }
            case 'scheduled': {
                this.scrollData.statuses = ['scheduled'];
                this.getTransfers(this.scrollData, 'main');
                break;
            }
            case 'canceled': {
                this.scrollData.statuses = ['failed', 'reversed', 'canceled'];
                this.getTransfers(this.scrollData, 'main');
                break;
            }
        }
    }

    getTransfers(filterData: any, dataType: 'main' | 'additional') {
        if (this.gatewayId) {
            this.zipiFinancialTransferService
                .getTransfersLogByGatewayIdWithParams(this.gatewayId, filterData)
                .pipe(takeUntil(this.unsubscribeBatch))
                .subscribe((data) => {
                    // const trArr = data.result;
                    // data.result = trArr.concat(trArr.concat(trArr.concat(trArr)));
                    if (dataType === 'main') {
                        this.mainDataSource.data = data.result;
                        this.scrollData.total = data._meta.total;
                    } else {
                        this.additionalScrollData.total = data._meta.total;
                        this.additionalDataSource.data = data.result;
                    }
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
    }
}
