import {Component, OnDestroy} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {Group} from '../../../../models/group';
import {Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {simpleEmailValidator} from '../../../contacts/store/contact.utilities';

@Component({
    selector: 'app-groups-settings-dialog',
    styles: [
        `
            .row.footer {
                justify-content: space-between;
            }

            mat-list {
                max-height: 400px;
                width: 100%;
                overflow-x: hidden;
            }

            mat-slide-toggle::ng-deep .mat-slide-toggle-content div {
                align-items: center;
            }
        `
    ],
    template: `
        <div class="col" style="justify-content: space-between; flex-direction: column; display: flex">
            <h3>Division Settings</h3>
            <br />

            <div style="display: flex; align-items: center">
                <mat-form-field mat-icon-button class="flex-4 w-100">
                    <input
                        matInput
                        type="text"
                        required
                        placeholder="Title"
                        name="name"
                        *ngIf="groupFG.controls.title"
                        [formControl]="groupFG.controls.title"
                    />
                </mat-form-field>
                <mat-icon
                    *ngIf="hasProfile"
                    style="color: #9E9E9E;"
                    matTooltip="You can not change title of the division that has Financial Profile. This ability will be added soon."
                >
                    info
                </mat-icon>
            </div>

            <div style="display: flex; align-items: center; flex-direction: row; padding-bottom: 0.5em;">
                <mat-slide-toggle
                    style="width: 100%"
                    *ngIf="
                        groupFG.controls.permissions &&
                        groupFG.controls.permissions.controls &&
                        groupFG.controls.permissions.controls.use_in_compensation_as_entity_group
                    "
                    [formControl]="groupFG.controls.permissions.controls.use_in_compensation_as_entity_group"
                >
                    <div class="d-flex">Allow this entity to Conduct Transactions</div>
                </mat-slide-toggle>
                <mat-icon
                    style="color: #9E9E9E;"
                    class="ml-2"
                    matTooltip="This will create a separate company to allow users whom have been granted permission to login and track the group's finances as a separate legal entity."
                >
                    monetization_on
                </mat-icon>
            </div>

            <div
                *ngIf="groupFG.controls.permissions!.controls!.use_in_compensation_as_entity_group!.value"
                style="display: flex; align-items: center"
            >
                <mat-form-field mat-icon-button class="flex-4 w-100">
                    <input
                        matInput
                        type="text"
                        [required]="isEmailRequired"
                        placeholder="Email"
                        name="email"
                        *ngIf="groupFG.controls.email"
                        [formControl]="groupFG.controls.email"
                    />
                    <mat-error>Invalid email address.</mat-error>
                </mat-form-field>
                <mat-icon
                    *ngIf="hasProfile"
                    style="color: #9E9E9E;"
                    matTooltip="You can not change email of the division that has Financial Profile. This ability will be added soon."
                >
                    info
                </mat-icon>
            </div>

            <div style="display: flex; align-items: center; flex-direction: row; padding-bottom: 1.5em;">
                <mat-slide-toggle
                    style="width: 100%"
                    *ngIf="
                        groupFG.controls.permissions &&
                        groupFG.controls.permissions.controls &&
                        groupFG.controls.permissions.controls.use_in_compensation_as_group_of_entities
                    "
                    [formControl]="groupFG.controls.permissions.controls.use_in_compensation_as_group_of_entities"
                >
                    <div class="d-flex">Enable 'User Group' for Division</div>
                </mat-slide-toggle>
                <mat-icon
                    style="color: #9E9E9E;"
                    class="ml-2"
                    matTooltip="Activate this if you would like to make the members of this group available in the Finance module."
                >
                    group
                </mat-icon>
            </div>

            <div
                *ngIf="
                    false && groupFG.controls!.permissions!.controls!.use_in_compensation_as_group_of_entities!.value
                "
            >
                <app-date-picker
                    [placeholder]="'Override User Anniversary Date'"
                    [dateControl]="groupFG.controls.anniversary_date"
                >
                </app-date-picker>
            </div>
            <div class="row footer mx-3" style="justify-content: flex-end;">
                <button mat-raised-button class="mat-default ml-2" (click)="dialogRef.close(null)">Cancel</button>
                <button mat-raised-button class="mat-primary ml-2" [disabled]="groupFG.invalid" (click)="save()">
                    Save
                </button>
            </div>
        </div>
    `
})
export class OrgChartSettingsDialogComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    public groupFG: GenericFormGroup<Group> = new GenericFormGroup(new Group(), 'change');
    public isEmailRequired: boolean = this.groupFG.get('permissions')?.get('use_in_compensation_as_entity_group')
        ?.value;
    public hasProfile: boolean = false;

    constructor(public dialogRef: MatDialogRef<OrgChartSettingsDialogComponent>) {
        this.groupFG.get('email')?.setValidators(simpleEmailValidator);
        this.groupFG.get('email')?.markAsTouched();

        this.groupFG
            .get('permissions')
            ?.get('use_in_compensation_as_entity_group')
            ?.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => {
                this.isEmailRequired = !!value;
                if (this.isEmailRequired) {
                    this.groupFG.get('email')?.setValidators([Validators.required, simpleEmailValidator]);
                } else {
                    this.groupFG.get('email')?.setValidators([simpleEmailValidator]);
                }
                this.groupFG.get('email')?.updateValueAndValidity({emitEvent: false});
            });
    }

    save() {
        this.groupFG.controls.title?.setValue(this.groupFG.controls.title.value.trim());
        this.dialogRef.close(this.groupFG.getRawValue());
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
