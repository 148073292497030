<mat-expansion-panel hideToggle [expanded]="isShowModuleDetails" style="margin-bottom: 5px">
    <!------- List of section permissions ------->
    <mat-expansion-panel-header #panelH (click)="panelH._toggle()">
        <mat-panel-title style="margin-right: 0 !important">
            <div class="w-100 d-flex align-items-baseline justify-content-between">
                <h4 class="mb-0">{{ moduleAccessPermission.label }}</h4>
                <mat-slide-toggle
                    color="primary"
                    [checked]="isChecked(moduleAccessPermission)"
                    (change)="changePermissionSet(moduleAccessPermission, $event.checked)"
                    [disabled]="viewMode"
                >
                </mat-slide-toggle>
            </div>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <table class="mat-elevation-z1 w-100 company-role-table">
        <tr class="second-header">
            <th class="bg-white"></th>
            <th style="width: 75px" class="text-center">View</th>
            <th style="width: 75px" class="text-center">Manage</th>
            <th style="width: 75px" class="text-center">Create</th>
            <th style="width: 75px" class="text-center">Edit</th>
            <th style="width: 75px" class="text-center">Delete</th>
        </tr>
        <tr *ngIf="allPermission">
            <td>Full Access</td>
            <td class="skipped-row"></td>
            <td class="skipped-row text-center bg-white">
                <mat-checkbox
                    [checked]="isChecked(allPermission)"
                    (change)="changePermissionSet(allPermission, $event.checked)"
                    [disabled]="viewMode"
                >
                </mat-checkbox>
            </td>
            <td class="skipped-row"></td>
            <td class="skipped-row"></td>
            <td class="skipped-row"></td>
        </tr>
        <ng-container *ngIf="permissions">
            <tr *ngFor="let feature of permissions">
                <ng-container *ngIf="!isModuleAccessFeature(feature) && getAnyPermission(feature).is_public">
                    <td>
                        {{ getAnyPermission(feature)?.label }}
                        <mat-icon
                            style="font-size: 18px"
                            *ngIf="getFeatureInfo(feature)"
                            [matTooltip]="getFeatureInfo(feature)"
                            >help_outline</mat-icon
                        >
                    </td>
                    <td
                        class="text-center checkbox-td"
                        [ngClass]="!getFeaturePermissionByType(feature, 'view') ? 'skipped-row' : ''"
                    >
                        <mat-checkbox
                            *ngIf="getFeaturePermissionByType(feature, 'view')"
                            [checked]="isChecked(getFeaturePermissionByType(feature, 'view'))"
                            (change)="changePermissionSet(getFeaturePermissionByType(feature, 'view'), $event.checked)"
                            [disabled]="viewMode"
                        >
                        </mat-checkbox>
                    </td>
                    <td
                        class="text-center checkbox-td"
                        [ngClass]="!getFeaturePermissionByType(feature, 'manage') ? 'skipped-row' : ''"
                    >
                        <mat-checkbox
                            *ngIf="getFeaturePermissionByType(feature, 'manage')"
                            [checked]="isChecked(getFeaturePermissionByType(feature, 'manage'))"
                            (change)="
                                changePermissionSet(getFeaturePermissionByType(feature, 'manage'), $event.checked)
                            "
                            [disabled]="viewMode"
                        >
                        </mat-checkbox>
                    </td>
                    <td
                        class="text-center checkbox-td"
                        [ngClass]="!getFeaturePermissionByType(feature, 'create') ? 'skipped-row' : ''"
                    >
                        <mat-checkbox
                            *ngIf="getFeaturePermissionByType(feature, 'create')"
                            [checked]="isChecked(getFeaturePermissionByType(feature, 'create'))"
                            (change)="
                                changePermissionSet(getFeaturePermissionByType(feature, 'create'), $event.checked)
                            "
                            [disabled]="viewMode"
                        >
                        </mat-checkbox>
                    </td>
                    <td
                        class="text-center checkbox-td"
                        [ngClass]="!getFeaturePermissionByType(feature, 'edit') ? 'skipped-row' : ''"
                    >
                        <mat-checkbox
                            *ngIf="getFeaturePermissionByType(feature, 'edit')"
                            [checked]="isChecked(getFeaturePermissionByType(feature, 'edit'))"
                            (change)="changePermissionSet(getFeaturePermissionByType(feature, 'edit'), $event.checked)"
                            [disabled]="viewMode"
                        >
                        </mat-checkbox>
                    </td>
                    <td
                        class="text-center checkbox-td"
                        [ngClass]="!getFeaturePermissionByType(feature, 'delete') ? 'skipped-row' : ''"
                    >
                        <mat-checkbox
                            *ngIf="getFeaturePermissionByType(feature, 'delete')"
                            [checked]="isChecked(getFeaturePermissionByType(feature, 'delete'))"
                            (change)="
                                changePermissionSet(getFeaturePermissionByType(feature, 'delete'), $event.checked)
                            "
                            [disabled]="viewMode"
                        >
                        </mat-checkbox>
                    </td>
                </ng-container>
            </tr>
        </ng-container>
    </table>
</mat-expansion-panel>
