import {Company} from './company';
import {User} from './user';
import {GenericEntity} from '../entites/generic.entity';
import DateTimeFormat = Intl.DateTimeFormat;
import {
    ICompany,
    IProfile,
    IStartingDealCount,
    IOpeningPerformanceValues
} from '@cyberco-nodejs/zipi-typings/companies.typings';
import {
    IContact,
    IPaymentMethod,
    IProfileMembership,
    IRole,
    ISettings,
    IUser,
    IGroup,
    IGroupMember
} from '@cyberco-nodejs/zipi-typings';
import {Group} from './group';

export class Profile extends GenericEntity implements IProfile {
    id: number | null = null;
    profile_id?: number;
    created_at?: DateTimeFormat;
    updated_at?: DateTimeFormat;
    deleted_at?: DateTimeFormat;
    title?: string;
    company?: Company;
    company_fk_id?: number;
    contact?: IContact;
    user_fk_id?: number;
    // @ts-ignore
    user?: User;
    new_email?: string;
    email: string | null = null;
    first_name: string | null = null;
    last_name: string | null = null;
    status: 'active' | 'revoked' | 'invited' | 'suspended' | 'disconnected' | 'no_access' | null = null;
    type: 'default' | 'global' | 'group' = 'default';
    invite_hash?: string;
    change_email_hash?: string;
    is_default_data_required?: boolean;
    lastSetOfShipmentsModes?: Array<string> = [];
    dashboard_order?: Array<number> = [];
    deals_order?: Array<number> = [];
    anniversary_date: number | null = null;
    roles_ids: Array<number> | null = null;
    roles: Array<IRole> = [];
    preferred_name: string | null = null;
    hire_date: number | null = null;
    termination_date: number | null = null;
    license_number: string | null = null;
    license_expiration: Date | null = null;
    date_of_birth: number | null = null;
    starting_deal_count?: IStartingDealCount;
    is_agent_company_needed: boolean = false;
    settings?: ISettings;
    opening_performance_values?: IOpeningPerformanceValues[] = [];
    profile_memberships?: Array<IProfileMembership> = [];
    contact_fk_id?: number;

    role_fk_id?: number;
    payment_methods?: IPaymentMethod[];
    company_group?: {[key: string]: any};
    company_groups_member?: IGroupMember[];
    groups?: IGroup[];

    // __merged_role?: { [key: string]: any } = {}; // deprecated: we should not use this property on front-end
    permissions?: {[key: string]: any} = {};

    static get helpers() {
        return Object.assign(
            {
                props: Object.getOwnPropertyNames(new this()).reduce((acc: {[key: string]: any}, curr) => {
                    acc[curr] = curr;
                    return acc;
                }, {}),
                build: {}
            },
            GenericEntity.helpers
        );
    }

    setFirstName(firstName: string): Profile {
        this.first_name = firstName;
        return this;
    }

    setLastName(lastName: string): Profile {
        this.last_name = lastName;
        return this;
    }

    getNameOrEmail() {
        let ret = '';
        if (this.email) {
            ret += this.email;
        }

        if (this.first_name) {
            ret = this.first_name;
            if (this.last_name) {
                ret += ' ' + this.last_name;
            }
        } else if (this.last_name) {
            ret = this.last_name;
        }
        return ret;
    }

    getSignature() {
        let ret = '';
        if (this.first_name) {
            ret += this.first_name;
            if (this.last_name) {
                ret += ' ' + this.last_name;
            }
        } else if (this.last_name) {
            ret += this.last_name;
        }
        if (this.email) {
            ret += ' <' + this.email + '>';
        }
        return ret;
    }

    get signature() {
        return this.getSignature();
    }

    set signature(value) {}

    static get type() {
        return {
            default: 'default',
            group: 'group',
            global: 'global'
        };
    }

    /**
     *
     * @returns {PROFILE_CONST_STATUS}
     */
    static get status() {
        /**
         *
         * @typedef {{active: string, revoked: string, invited: string, suspended: string, disconnected: string, no_access: string}} PROFILE_CONST_STATUS
         */
        const status = {
            active: '',
            /**
             * @deprecated
             */
            revoked: '',
            invited: '',
            suspended: '',
            disconnected: '',
            no_access: ''
        };
        return Object.keys(status).reduce((accumulator: {[key: string]: any}, value) => {
            accumulator[value] = value;
            return accumulator;
        }, status);
    }
}
