import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../service.requester';
import {Observable} from 'rxjs';

@Injectable()
export class TransactionsExternalService {
    private url = '/finance/transactions-external/';

    constructor(public requester: ServiceRequester) {}

    getPageTransactions(ledgerAccountId: number, data: any): Observable<any> {
        return this.requester.makeMsCall$(this.url + `${ledgerAccountId}/page`, 'GET', 'shipp', data);
    }

    fetchBankTransactions(ledgerAccountId: number): Observable<any> {
        return this.requester.makeMsCall$(this.url + `${ledgerAccountId}`, 'POST', 'shipp');
    }

    importBankTransactions(ledgerAccountId: number, data: any): Observable<any> {
        return this.requester.makeMsCall$(this.url + `${ledgerAccountId}/import`, 'POST', 'shipp', data);
    }

    matchTransaction(matchTransactionId: number, data: any): Observable<any> {
        return this.requester.makeMsCall$(this.url + `${matchTransactionId}/match`, 'POST', 'shipp', data);
    }

    unmatchTransaction(matchTransactionId: number): Observable<any> {
        return this.requester.makeMsCall$(this.url + `${matchTransactionId}/unmatch`, 'POST', 'shipp');
    }

    bulkDeleteTransactions(ids: number[]): Observable<any> {
        return this.requester.makeMsCall$(this.url + `bulk/delete`, 'PUT', 'shipp', ids);
    }

    bulkDeleteAllTransactions(ledgerAccountId: number): Observable<any> {
        return this.requester.makeMsCall$(this.url + `bulk/delete/${ledgerAccountId}/all`, 'PUT', 'shipp');
    }

    bulkRestoreTransactions(ids: number[]): Observable<any> {
        return this.requester.makeMsCall$(this.url + `bulk/restore`, 'PUT', 'shipp', ids);
    }

    bulkRestoreAllTransactions(ledgerAccountId: number): Observable<any> {
        return this.requester.makeMsCall$(this.url + `bulk/restore/${ledgerAccountId}/all`, 'PUT', 'shipp');
    }
}
