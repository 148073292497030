<div style="height: 5px; padding-bottom: 2px">
    <mat-progress-bar
        mode="indeterminate"
        color="warn"
        [class.hide]="!loading"
        class="pw-progress-bar"
    ></mat-progress-bar>
</div>
<div class="m-1" *ngIf="showGroupDetails">
    <div *ngIf="contactId"></div>

    <div>
        <mat-form-field *ngIf="!contactClassId">
            <input
                matInput
                placeholder="Search for Person"
                [matAutocomplete]="auto"
                [autofocus]="false"
                [formControl]="currentContactName"
            />
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                    *ngFor="let contact of members"
                    [value]="contact.display_name"
                    (onSelectionChange)="selectContact($event, contact)"
                >
                    <span>{{ contact.display_name }}</span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="contactClassId">
            <input
                matInput
                placeholder="Search for Person of class"
                [matAutocomplete]="auto"
                [autofocus]="false"
                [formControl]="currentClassContactName"
            />
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                    *ngFor="let contact of members"
                    [value]="contact.display_name"
                    (onSelectionChange)="selectContact($event, contact)"
                >
                    <span>{{ contact.display_name }}</span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-card *ngIf="currentContact && customisationForm" [formGroup]="customisationForm" class="w-100">
            <h4 class="m-0 mt-3">{{ currentContact.display_name }}</h4>
            <div class="d-flex w-100 align-items-center">
                <mat-form-field class="w-30 mr-2">
                    <mat-select
                        [placeholder]="'Billing Address'"
                        formControlName="billing_address__contact_location_fk_id"
                    >
                        <mat-option [value]="null">None</mat-option>
                        <mat-option
                            *ngFor="let location of currentContact.contact_locations"
                            [value]="location.contact_location_id"
                        >
                            <span *ngIf="location.street_number">{{ location.street_number }}</span>
                            <span *ngIf="location.street_address"> {{ location.street_address }}</span>
                            <span *ngIf="location.unit_number"> {{ location.unit_number }}</span>
                            <span *ngIf="location.city"> {{ location.city + ',' }}</span>
                            <span *ngIf="location.state"> {{ location.state }}</span>
                            <span *ngIf="location.zip"> {{ location.zip }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="w-30 mr-2">
                    <mat-select
                        [placeholder]="'Shipping Address'"
                        formControlName="shipping_address__contact_location_fk_id"
                    >
                        <mat-option [value]="null">None</mat-option>
                        <mat-option
                            *ngFor="let location of currentContact.contact_locations"
                            [value]="location.contact_location_id"
                        >
                            <span *ngIf="location.street_number">{{ location.street_number }}</span>
                            <span *ngIf="location.street_address"> {{ location.street_address }}</span>
                            <span *ngIf="location.unit_number"> {{ location.unit_number }}</span>
                            <span *ngIf="location.city"> {{ location.city + ',' }}</span>
                            <span *ngIf="location.state"> {{ location.state }}</span>
                            <span *ngIf="location.zip"> {{ location.zip }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="w-30 mr-2">
                    <mat-select
                        [placeholder]="'Payment Method'"
                        disableOptionCentering
                        formControlName="to_be_paid__payment_method_fk_id"
                    >
                        <mat-select-trigger class="w-100">
                            {{ getCurrentMethodLabel() }}
                        </mat-select-trigger>

                        <mat-option
                            [value]="null"
                            class="add-card-option"
                            (click)="addCard('contact_card')"
                            *ngIf="isAbleToPayByPayloadCoCard || isAbleToPayByAuthorizeCard"
                        >
                            <mat-icon>add_circle_outline</mat-icon>
                            <span>Add Credit Card</span>
                        </mat-option>

                        <mat-option
                            [value]="null"
                            class="add-card-option"
                            (click)="addCard('contact_bank')"
                            *ngIf="isAbleToPayByPayloadCoBank"
                        >
                            <mat-icon>add_circle_outline</mat-icon>
                            <span>Add Bank Account</span>
                        </mat-option>

                        <mat-option [value]="null">None</mat-option>

                        <ng-container *ngFor="let gateway of currentContact.contact_payment_gateways">
                            <div *ngFor="let method of gateway.payment_methods" class="d-flex align-items-center">
                                <mat-option
                                    class="w-100"
                                    *ngIf="
                                        method.is_available_for_payment &&
                                        gateway.type === 'authorize_net_customer' &&
                                        isAbleToPayByAuthorizeCard
                                    "
                                    [value]="method.payment_method_id"
                                    [disabled]="
                                        ['authorize_net_customer', 'zipi_financial_receive_only'].includes(
                                            gateway.type
                                        ) &&
                                        (method.status !== 'active' || gateway.status !== 'active')
                                    "
                                >
                                    <div class="d-flex align-items-center">
                                        <mat-icon
                                            class="mr-1"
                                            *ngIf="
                                                gateway.driver_type === 'payload' &&
                                                method.type === 'zipi_financial_credit_card'
                                            "
                                            >credit_card</mat-icon
                                        >
                                        <mat-icon
                                            class="mr-1"
                                            *ngIf="gateway.driver_type === 'payload' && method.type === 'other'"
                                            >account_balance</mat-icon
                                        >
                                        <span>{{ method.title }}</span>
                                    </div>
                                </mat-option>
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let gateway of currentContact.contact_payment_gateways">
                            <div *ngFor="let method of gateway.payment_methods" class="d-flex align-items-center">
                                <mat-option
                                    class="w-100"
                                    *ngIf="
                                        method.is_available_for_payment &&
                                        gateway.driver_type === 'payload' &&
                                        method.type === 'zipi_financial_credit_card' &&
                                        isAbleToPayByPayloadCoCard
                                    "
                                    [value]="method.payment_method_id"
                                    [disabled]="
                                        ['authorize_net_customer', 'zipi_financial_receive_only'].includes(
                                            gateway.type
                                        ) &&
                                        (method.status !== 'active' || gateway.status !== 'active')
                                    "
                                >
                                    <div class="d-flex align-items-center">
                                        <mat-icon
                                            class="mr-1"
                                            *ngIf="
                                                gateway.driver_type === 'payload' &&
                                                method.type === 'zipi_financial_credit_card'
                                            "
                                            >credit_card</mat-icon
                                        >
                                        <mat-icon
                                            class="mr-1"
                                            *ngIf="gateway.driver_type === 'payload' && method.type === 'other'"
                                            >account_balance</mat-icon
                                        >
                                        <span>{{ method.title }}</span>
                                    </div>
                                </mat-option>
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let gateway of currentContact.contact_payment_gateways">
                            <div *ngFor="let method of gateway.payment_methods" class="d-flex align-items-center">
                                <mat-option
                                    class="w-100"
                                    *ngIf="
                                        method.is_available_for_payment &&
                                        gateway.driver_type === 'payload' &&
                                        method.type === 'other' &&
                                        isAbleToPayByPayloadCoBank
                                    "
                                    [value]="method.payment_method_id"
                                    [disabled]="
                                        ['authorize_net_customer', 'zipi_financial_receive_only'].includes(
                                            gateway.type
                                        ) &&
                                        (method.status !== 'active' || gateway.status !== 'active')
                                    "
                                >
                                    <div class="d-flex align-items-center">
                                        <mat-icon
                                            class="mr-1"
                                            *ngIf="
                                                gateway.driver_type === 'payload' &&
                                                method.type === 'zipi_financial_credit_card'
                                            "
                                            >credit_card</mat-icon
                                        >
                                        <mat-icon
                                            class="mr-1"
                                            *ngIf="gateway.driver_type === 'payload' && method.type === 'other'"
                                            >account_balance</mat-icon
                                        >
                                        <span>{{ method.title }}</span>
                                    </div>
                                </mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <button
                    class="mb-2 mr-1"
                    type="button"
                    mat-raised-button
                    color="primary"
                    style="min-width: 90px"
                    (click)="saveCustomisation()"
                >
                    {{ customisationForm.controls.money_sender_customisation_id.value ? 'Update' : 'Create' }}
                </button>
                <button class="" style="margin-bottom: 10px" mat-icon-button (click)="closeCustomisation()">
                    <mat-icon [matTooltip]="'Close'" style="font-size: 30px">close</mat-icon>
                </button>
            </div>
        </mat-card>
    </div>
    <div class="m-3" *ngIf="!dataSource.data || dataSource.data.length === 0">No Additional Details.</div>
    <div style="overflow-y: scroll" [ngStyle]="currentContact ? {height: '68vh'} : {height: '85vh'}" #section>
        <table
            mat-table
            (matSortChange)="onSortChange($event)"
            matSort
            matSortDisableClear
            matSortActive="{{ sort_column }}"
            [matSortDirection]="$any(sort_direction)"
            *ngIf="dataSource.data && dataSource.data.length > 0"
            [dataSource]="dataSource"
            class="mat-elevation-z1 w-100 my-3"
        >
            <ng-container matColumnDef="customer_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Customer</th>
                <td mat-cell *matCellDef="let element">{{ element.customer_name }}</td>
            </ng-container>

            <ng-container matColumnDef="billing">
                <th mat-header-cell *matHeaderCellDef>Billing Address</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="!element.customisation" style="color: #b9b9b9">N/A</div>
                    <div *ngIf="element.customisation && !element.customisation.billing_address">N/A</div>
                    <div *ngIf="element.customisation && element.customisation.billing_address">
                        <span *ngIf="element.customisation.billing_address.street_number">{{
                            element.customisation.billing_address.street_number
                        }}</span>
                        <span *ngIf="element.customisation.billing_address.street_address">
                            {{ element.customisation.billing_address.street_address }}</span
                        >
                        <span *ngIf="element.customisation.billing_address.unit_number">
                            {{ element.customisation.billing_address.unit_number }}</span
                        >
                        <span *ngIf="element.customisation.billing_address.city">
                            {{ element.customisation.billing_address.city + ',' }}</span
                        >
                        <span *ngIf="element.customisation.billing_address.state">
                            {{ element.customisation.billing_address.state }}</span
                        >
                        <span *ngIf="element.customisation.billing_address.zip">
                            {{ element.customisation.billing_address.zip }}</span
                        >
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="shipping">
                <th mat-header-cell *matHeaderCellDef>Shipping Address</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="!element.customisation" style="color: #b9b9b9">N/A</div>
                    <div *ngIf="element.customisation && !element.customisation.shipping_address">N/A</div>
                    <div *ngIf="element.customisation && element.customisation.shipping_address">
                        <span *ngIf="element.customisation.shipping_address.street_number">{{
                            element.customisation.shipping_address.street_number
                        }}</span>
                        <span *ngIf="element.customisation.shipping_address.street_address">
                            {{ element.customisation.shipping_address.street_address }}</span
                        >
                        <span *ngIf="element.customisation.shipping_address.unit_number">
                            {{ element.customisation.shipping_address.unit_number }}</span
                        >
                        <span *ngIf="element.customisation.shipping_address.city">
                            {{ element.customisation.shipping_address.city + ',' }}</span
                        >
                        <span *ngIf="element.customisation.shipping_address.state">
                            {{ element.customisation.shipping_address.state }}</span
                        >
                        <span *ngIf="element.customisation.shipping_address.zip">
                            {{ element.customisation.shipping_address.zip }}</span
                        >
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="method">
                <th mat-header-cell *matHeaderCellDef>Payment Method</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="!element.customisation" style="color: #b9b9b9">N/A</div>
                    <div *ngIf="element.customisation && !element.customisation.payment_method">N/A</div>
                    <div *ngIf="element.customisation && element.customisation.payment_method">
                        {{ element.customisation.payment_method.title }}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="selectContact({isUserInput: true}, element)">
                            {{
                                element.customisation && element.customisation.money_sender_customisation_id
                                    ? 'Edit'
                                    : 'Create'
                            }}
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="element.customisation"
                            (click)="deleteCustomisation(element.customisation.money_sender_customisation_id)"
                        >
                            Delete
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                [ngClass]="currentContact && currentContact.contact_id === row.contact_id ? 'customisation-row' : ''"
                *matRowDef="let row; columns: displayedColumns"
            ></tr>
        </table>
    </div>
</div>
