import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../service.requester';
import {Observable} from 'rxjs';
import {ITransaction, ITransactionExternal, ITransactionListItem} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class TransactionsService {
    private url = '/finance/transactions/';

    constructor(public requester: ServiceRequester) {}

    getTransactionById(transactionId: number): Observable<{
        transaction: ITransaction;
        other_matched_transactions: ITransaction[];
        external_matched_transactions: ITransactionExternal[];
    }> {
        return this.requester.makeMsCall$(this.url + `${transactionId}`, 'GET', 'shipp');
    }

    getPageTransactionsByLedgerAccountId(
        ledgerAccountId: number,
        data: {[key: string]: any}
    ): Observable<{
        transactions: ITransactionListItem[];
        count: number;
    }> {
        return this.requester.makeMsCall$(this.url + `${ledgerAccountId}` + '/page', 'GET', 'shipp', data);
    }

    getRecommendedIntrnalTransactionsByLedgerAccountId(
        ledgerAccountId: number,
        data: {[key: string]: any}
    ): Observable<{
        transactions: ITransactionListItem[];
        count: number;
    }> {
        return this.requester.makeMsCall$(this.url + `${ledgerAccountId}` + '/recommended', 'GET', 'shipp', data);
    }

    getReconciliationTransactions(
        ledgerAccountId: number,
        data: {[key: string]: number | ''}
    ): Observable<{
        transactions: (ITransactionListItem & {check_info: string})[];
        count: number;
    }> {
        return this.requester.makeMsCall$(this.url + `${ledgerAccountId}` + `/reconciliation`, 'GET', 'shipp', data);
    }

    getPageNotMatchedTransactions(
        ledgerAccountId: number,
        data: {[key: string]: number}
    ): Observable<{
        transactions: ITransactionListItem[];
        count: number;
    }> {
        return this.requester.makeMsCall$(this.url + `${ledgerAccountId}` + `/notmatched`, 'GET', 'shipp', data);
    }

    getTransactionsByMatchedTransactionId(matchedTransactionId: number): Observable<ITransaction[]> {
        return this.requester.makeMsCall$(this.url + `${matchedTransactionId}` + `/matched`, 'GET', 'shipp');
    }

    getExcessContactTransactions(contact_id: number): Observable<{
        vendor_credit: number;
        payment_made: number;
        credit_note: number;
        payment_received: number;
        invoice_pending_balance_current: number;
        invoice_pending_balance_overdue: number;
        bill_pending_balance_current: number;
        bill_pending_balance_overdue: number;
    }> {
        return this.requester.makeMsCall$(this.url + `${contact_id}` + `/excess`, 'GET', 'shipp');
    }

    getTransactionsForReport(data: {[key: string]: any}): Observable<{result: any[]; _meta: {total: number}}> {
        return this.requester.makeMsCall$(this.url + `search`, 'GET', 'shipp', data);
    }
}
