import {Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {timePeriodObj} from '../../../../constants';
import {getNextActivePanel, isLastPanelItem} from '../../helper';
import {MatButtonToggleChange} from '@angular/material/button-toggle';

@Component({
    selector: 'app-report-edit-bar-ap-aging',
    templateUrl: './ap-aging.component.html',
    styleUrls: ['./ap-aging.component.scss']
})
export class ApAgingBarComponent implements OnInit, OnDestroy {
    @Output() valueChanges: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Output() action: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Input() report: {[key: string]: any} = {};

    private defaultValues: {[key: string]: any} = {
        time_period: timePeriodObj.this_year.value,
        report_view: 'detailed'
    };

    public activePanel: string = 'time_period';
    public reportSettings: {[key: string]: any} = {};
    public isShowNext: boolean = true;
    public reportOptions: any = [
        {
            value: 'time_period',
            title: 'Report Period'
        },
        {
            value: 'report_view',
            title: 'Report View'
        }
    ];

    public reportViewList: Array<{title: string; value: string}> = [
        {
            title: 'Summary',
            value: 'summary'
        },
        {
            title: 'Detailed',
            value: 'detailed'
        }
    ];

    constructor() {}

    ngOnInit() {
        this.initData();
    }

    initData() {
        const settings = this.report.settings || {};
        this.reportSettings = {...this.defaultValues, ...settings};
    }

    onSelectOptions(event: MatButtonToggleChange) {
        this.activePanel = event.value;
        this.isShowNext = !isLastPanelItem(this.activePanel, this.reportOptions);
    }

    onValueChanges(property: string, data: any) {
        switch (property) {
            case 'time_period':
                this.reportSettings = {
                    ...this.reportSettings,
                    ...data
                };
                break;
            default:
                this.reportSettings[property] = data;
        }
    }

    onAction(event: any) {
        const response: any = {
            type: event
        };

        switch (event) {
            case 'save':
            case 'run':
                response['data'] = this.reportSettings;
                this.action.emit(response);
                break;
            case 'cancel':
            case 'delete':
                this.action.emit(response);
                break;
            case 'next':
                const nextData = getNextActivePanel(this.activePanel, this.reportOptions);
                this.activePanel = nextData.name;
                this.isShowNext = !nextData.isLast;
                break;
            default:
        }
    }

    ngOnDestroy() {
        this.action.complete();
    }
}
