<section class="container-fluid p-4">
    <div class="d-flex justify-content-between mb-2 align-items-end">
        <h3 *ngIf="!recurringInvoice" class="">Create Recurring Invoice</h3>
        <h3 *ngIf="recurringInvoice" class="">Edit Recurring Invoice</h3>

        <span class="sp-spacer"></span>

        <span
            [matMenuTriggerFor]="appMenu"
            *ngIf="recurringInvoice"
            style="font-size: 18px; margin-bottom: -8px; margin-right: 16px; font-weight: 600"
            class="sp-cursor-pointer"
        >
            <span *ngIf="formGroup.controls.status.value === 'active'" style="color: green">Active</span>
            <span *ngIf="formGroup.controls.status.value === 'stopped'" style="color: red">Suspended</span>
        </span>

        <mat-menu #appMenu="matMenu">
            <button
                mat-menu-item
                [disabled]="formGroup.controls.status.value === 'active'"
                (click)="changeStatus('active')"
            >
                Activate
            </button>
            <button
                mat-menu-item
                [disabled]="formGroup.controls.status.value === 'stopped'"
                (click)="changeStatus('stopped')"
            >
                Suspend
            </button>
        </mat-menu>
    </div>

    <div class="mb-3">
        <div class="row mb-3" [formGroup]="formGroup">
            <mat-form-field class="col col-sm-6">
                <input matInput type="text" placeholder="Title" formControlName="title" />
                <mat-error *ngIf="formGroup.controls.title.hasError('required')">required</mat-error>
            </mat-form-field>

            <mat-form-field
                class="col"
                [ngClass]="{
                    'col-sm-6':
                        !formGroup.controls.repeat.value || formGroup.controls.repeat.value?.period !== 'custom',
                    'col-sm-3': formGroup.controls.repeat.value?.period === 'custom'
                }"
            >
                <mat-select formControlName="repeat" placeholder="Repeat Every" [compareWith]="comparePeriod">
                    <mat-option *ngFor="let period of repeatPeriod" [value]="period[1]">{{
                        period[0] | titlecase
                    }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col col-sm-3" *ngIf="formGroup.controls.repeat.value?.period === 'custom'">
                <input matInput type="text" placeholder="Number of days" formControlName="repeat_custom_days" />
            </mat-form-field>

            <app-date-picker
                class="col col-sm-6"
                [placeholder]="'Start On'"
                [dateControl]="formGroup.controls.start_on"
            ></app-date-picker>

            <mat-form-field class="col col-sm-3" [formGroup]="endsGroup!">
                <mat-select formControlName="endsOptionControl" placeholder="End">
                    <mat-option value="none">None</mat-option>
                    <mat-option value="by">By</mat-option>
                    <mat-option value="after">After</mat-option>
                </mat-select>
            </mat-form-field>

            <app-date-picker
                class="col col-sm-3"
                [placeholder]="'Ends On'"
                [isRequired]="false"
                [dateControl]="formGroup.controls.end_on"
                *ngIf="endsGroup!.controls.endsOptionControl.value === 'by'"
            ></app-date-picker>

            <mat-form-field class="col col-sm-3" *ngIf="endsGroup!.controls.endsOptionControl.value === 'after'">
                <input matInput type="number" placeholder="Occurrences" min="1" formControlName="end_after" />
                <!--                <mat-hint>*if set to 0, no limits apply</mat-hint>-->
            </mat-form-field>

            <div class="col col-12" [ngStyle]="isCreateInvoiceForCurrent && !recurringInvoice ? {} : {display: 'none'}">
                <mat-checkbox formControlName="create_invoice_for_current_period">
                    Create an Invoice for Current Billing Period
                </mat-checkbox>
            </div>

            <mat-form-field
                class="col col-sm-6"
                *ngIf="
                    !formGroup.controls.draft_period.value || formGroup.controls.draft_period.value?.period !== 'day'
                "
            >
                <mat-select formControlName="draft_period" placeholder="Draft Period" [compareWith]="comparePeriod">
                    <mat-option *ngFor="let dp of recurringInvoiceDraftPeriod" [value]="dp[1]">{{ dp[0] }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col col-sm-3" *ngIf="formGroup.controls.draft_period.value?.period === 'day'">
                <mat-select formControlName="draft_period" placeholder="Draft Period" [compareWith]="comparePeriod">
                    <mat-option *ngFor="let dp of recurringInvoiceDraftPeriod" [value]="dp[1]">{{ dp[0] }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col col-sm-3" *ngIf="formGroup.controls.draft_period.value?.period === 'day'">
                <input matInput type="text" placeholder="Number of days" formControlName="draft_period_custom" />
            </mat-form-field>

            <mat-form-field class="col col-sm-6">
                <input matInput type="text" placeholder="Auto recharge #1 (days)" formControlName="auto_recharge_1" />
            </mat-form-field>

            <mat-form-field class="col col-sm-6">
                <mat-select
                    formControlName="notification_period"
                    placeholder="Notifications"
                    [compareWith]="comparePeriod"
                    multiple
                >
                    <mat-option *ngFor="let np of recurringInvoiceNotificationPeriod" [value]="np[1]">{{
                        np[0]
                    }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col col-sm-6">
                <input matInput type="text" placeholder="Auto recharge #2 (days)" formControlName="auto_recharge_2" />
            </mat-form-field>

            <mat-form-field class="col col-sm-6">
                <mat-select
                    formControlName="unpaid_reminder_period"
                    placeholder="Unpaid Reminder"
                    [compareWith]="comparePeriod"
                >
                    <mat-option *ngFor="let rp of recurringInvoiceUnpaidReminderPeriod" [value]="rp[1]">{{
                        rp[0]
                    }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col col-sm-6">
                <input matInput type="text" placeholder="Auto recharge #3 (days)" formControlName="auto_recharge_3" />
            </mat-form-field>
        </div>

        <h2>Invoice Template</h2>
        <div class="mb-4">
            <app-recurring-invoice-template
                (onUpdate)="handleInvoiceTemplateUpdate($event)"
                [recurring_invoice_id]="recurringInvoiceId"
                [invoice]="recurringInvoice"
            >
            </app-recurring-invoice-template>
        </div>

        <div class="d-flex" [formGroup]="formGroup">
            <button type="button" mat-raised-button color="warn" (click)="deleteRecurringInvoice()">Delete</button>

            <span class="sp-spacer"></span>

            <button mat-raised-button class="ml-2" routerLink="/sales/invoices/recurring">Cancel</button>

            <button
                type="!submit"
                class="ml-2"
                *ngIf="!recurringInvoice"
                mat-raised-button
                color="primary"
                (click)="createRecurringInvoice()"
            >
                Create
            </button>

            <button
                type="submit"
                class="ml-2"
                *ngIf="recurringInvoice"
                mat-raised-button
                color="primary"
                (click)="updateRecurringInvoice()"
            >
                Save
            </button>
        </div>
    </div>
</section>
