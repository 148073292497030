<section class="container-fluid">
    <header class="py-2">
        <h1 *ngIf="!product" class="">Create Product</h1>
        <h1 *ngIf="product" class="">Edit Product</h1>
    </header>

    <div [formGroup]="formGroup">
        <div class="row">
            <div class="col-col-sm-7 col-md-4">
                <mat-form-field class="w-100">
                    <input type="text" matInput formControlName="name" placeholder="Name" autocomplete="off" />
                </mat-form-field>
            </div>

            <div class="col-col-sm-7 col-md-4">
                <mat-form-field class="w-100">
                    <input
                        matInput
                        autocomplete="off"
                        formControlName="price"
                        placeholder="Price"
                        [maskito]="currencyMaskitoMask"
                    />
                </mat-form-field>
            </div>

            <div class="col-col-sm-7 col-md-4">
                <app-ledger-account-selector
                    #ledgerAccountPicker
                    [ledgerAccountControl]="formGroup.controls.ledger_account_fk_id"
                    [placeholder]="'Ledger Account'"
                    [disabled]="product && !!product.system_key"
                    [disabledReselect]="product && !!product.system_key"
                >
                </app-ledger-account-selector>
            </div>

            <div class="col-12">
                <mat-form-field class="w-100">
                    <textarea matInput formControlName="description" placeholder="Product description"></textarea>
                </mat-form-field>
            </div>
        </div>

        <footer class="text-right">
            <div
                class="ml-1 d-inline-block"
                *ngIf="product && product.status === 'active'"
                [matTooltip]="'System Product cannot be archived'"
                [matTooltipDisabled]="!product?.system_key"
            >
                <button
                    type="submit"
                    mat-raised-button
                    color="warn"
                    [disabled]="product?.system_key"
                    (click)="archiveProduct()"
                >
                    Archive
                </button>
            </div>
            <div class="ml-1 d-inline-block" *ngIf="product && product.status === 'archived'">
                <button type="submit" mat-raised-button color="warn" (click)="unarchiveProduct()">Unarchive</button>
            </div>

            <div
                class="ml-1 d-inline-block"
                *ngIf="product"
                [matTooltip]="'System Product cannot be deleted'"
                [matTooltipDisabled]="!product?.system_key"
            >
                <button
                    type="submit"
                    mat-raised-button
                    color="warn"
                    [disabled]="product?.system_key"
                    (click)="deleteProduct()"
                >
                    Delete
                </button>
            </div>
            <span class="sp-spacer"></span>

            <button *ngIf="!dialogMode" mat-raised-button class="ml-2" routerLink="/company/finance/products">
                Cancel
            </button>
            <button *ngIf="dialogMode" mat-raised-button class="ml-2" (click)="cancel.emit()">Cancel</button>

            <button
                type="!submit"
                class="ml-2"
                *ngIf="!product"
                mat-raised-button
                color="primary"
                (click)="createProduct()"
            >
                Create
            </button>
            <button
                type="submit"
                class="ml-2"
                *ngIf="product"
                mat-raised-button
                color="primary"
                (click)="updateProduct()"
            >
                Save
            </button>
        </footer>
    </div>
</section>
