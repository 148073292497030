import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {GenericFormGroup} from '../../../../../../entites/generic.entity';
import {Deal} from '../../../../../../models/deal';
import {takeUntil} from 'rxjs/operators';
import {IDealDepositRelease} from '@cyberco-nodejs/zipi-typings';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {PaymentsMadeService} from '../../../../../finance/services/payments-made.service';
import {DealService} from '../../../../../../services/deal.service';
import {DealFinancialApiService} from '../../deal-financial.api.service';
import {SessionService} from '../../../../../../services/session.service';

@Component({
    selector: 'app-deal-payouts',
    template: `
        <!------- Payouts Tab ------->
        <!--        <button mat-raised-button (click)="selectedTab = 'Summary'">Summary</button>-->
        <!--        <button mat-raised-button (click)="selectedTab = 'Closing'">Closing</button>-->
        <!--        <button mat-raised-button (click)="selectedTab = 'Remaining'">Remaining</button>-->
        <!--        <div [ngStyle]="selectedTab === 'Summary' ? {'display': 'block'} : {'display': 'none'}">-->
        <!--            <app-deal-payouts-summary-->
        <!--                [dealFormGroup]="dealFG"-->
        <!--                [brokerReleasesAmount]="brokerReleasesAmount"-->
        <!--                [dualDealBrokerReleasesAmount]="dualDealBrokerReleasesAmount">-->
        <!--            </app-deal-payouts-summary>-->
        <!--        </div>-->
        <!--        <div [ngStyle]="selectedTab === 'Closing' ? {'display': 'block'} : {'display': 'none'}">-->
        <!--            <app-deal-payouts-escrow-->
        <!--                [dealFormGroup]="dealFG"-->
        <!--                [lastUsedAccount]="lastUsedAccount"-->
        <!--                [brokerReleasesAmount]="brokerReleasesAmount"-->
        <!--                [dualDealBrokerReleasesAmount]="dualDealBrokerReleasesAmount">-->
        <!--            </app-deal-payouts-escrow>-->
        <!--        </div>-->
        <!--        <div [ngStyle]="selectedTab === 'Remaining' ? {'display': 'block'} : {'display': 'none'}">-->
        <!--            <app-deal-payouts-remaining-->
        <!--                [dealFormGroup]="dealFG"-->
        <!--                [lastUsedAccount]="lastUsedAccount"-->
        <!--                (updatePayouts)="updatePayouts()"-->
        <!--                [releaseList]="releaseList">-->
        <!--            </app-deal-payouts-remaining>-->
        <!--        </div>-->

        <mat-tab-group>
            <mat-tab label="Summary">
                <app-deal-payouts-summary
                    [dealFormGroup]="dealFG"
                    [brokerReleasesAmount]="brokerReleasesAmount"
                    [dualDealBrokerReleasesAmount]="dualDealBrokerReleasesAmount"
                >
                </app-deal-payouts-summary>
            </mat-tab>
            <mat-tab label="Closing">
                <app-deal-payouts-escrow
                    [dealFormGroup]="dealFG"
                    [lastUsedAccount]="lastUsedAccount"
                    [brokerReleasesAmount]="brokerReleasesAmount"
                    [dualDealBrokerReleasesAmount]="dualDealBrokerReleasesAmount"
                >
                </app-deal-payouts-escrow>
            </mat-tab>
            <mat-tab label="Remaining">
                <app-deal-payouts-remaining
                    [dealFormGroup]="dealFG"
                    [lastUsedAccount]="lastUsedAccount"
                    (updatePayouts)="updatePayouts()"
                    [releaseList]="releaseList"
                >
                </app-deal-payouts-remaining>
            </mat-tab>
        </mat-tab-group>
    `
})
export class PayoutsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() dealFG: GenericFormGroup<Deal> = new GenericFormGroup<Deal>(new Deal());

    @Output() needReloadDeaL = new EventEmitter();

    lastUsedAccount: any;

    dealId: number | undefined;

    selectedTab: string;

    releaseList: IDealDepositRelease[] = [];
    brokerReleasesAmount: number = 0;
    dualReleaseList: IDealDepositRelease[] = [];
    dualDealBrokerReleasesAmount: number = 0;

    constructor(
        public router: Router,
        private paymentsMadeService: PaymentsMadeService,
        public dealService: DealService,
        private dealFinancialApiService: DealFinancialApiService,
        private sessionService: SessionService
    ) {
        this.selectedTab = 'Summary';
    }

    ngOnInit() {
        this.dealId = this.dealFG.get('id')!.value;

        this.updatePayouts();

        if (this.dealFG && this.dealFG.getRawValue().id) {
            this.dealFinancialApiService
                .getDealDepositReleases(this.dealFG.getRawValue().id!)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((depositReleases) => {
                    if (depositReleases) {
                        this.releaseList = depositReleases;
                        const brokerId = this.sessionService.profile!.company!.main__contact_fk_id;
                        const brokerReleases = this.releaseList.filter(
                            (release) => release.release_receiver__contact_fk_id === brokerId
                        );

                        this.brokerReleasesAmount = brokerReleases.reduce(
                            (acc, release) => release.release_amount + acc,
                            0
                        );
                    }
                });
        }
        if (this.dealFG && this.dealFG.getRawValue().dual_deal_id) {
            this.dealFinancialApiService
                .getDealDepositReleases(this.dealFG.getRawValue().dual_deal_id!)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((depositReleases) => {
                    if (depositReleases) {
                        this.dualReleaseList = depositReleases;
                        const brokerId = this.sessionService.profile!.company!.main__contact_fk_id;
                        const brokerReleases = this.dualReleaseList.filter(
                            (release) => release.release_receiver__contact_fk_id === brokerId
                        );

                        this.dualDealBrokerReleasesAmount = brokerReleases.reduce(
                            (acc, release) => release.release_amount + acc,
                            0
                        );
                    }
                });
        }
    }

    updatePayouts() {
        this.paymentsMadeService
            .getLastUsedAccount()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((account_id) => {
                this.lastUsedAccount = account_id;
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.needReloadDeaL.complete();
    }
}
