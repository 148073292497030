import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe, CurrencyPipe, PercentPipe} from '@angular/common';

@Pipe({
    name: 'reportFormatted'
})
export class ReportFormattedPipe implements PipeTransform {
    constructor(
        private decimalPipe: DecimalPipe,
        private currencyPipe: CurrencyPipe,
        private percentPipe: PercentPipe
    ) {}

    transform(value: string | number, filterType?: any): any {
        const valueAsString = String(value || 0);
        if (!Array.isArray(filterType)) {
            filterType = [filterType];
        }
        let formattedValue = null;

        const params = filterType[1] ? filterType[1] : null;

        switch (filterType[0]) {
            case 'decimal': {
                const digitsInfo = params ? params : '1.1-1';
                formattedValue = this.decimalPipe.transform(valueAsString, digitsInfo);
                break;
            }

            case 'currency': {
                formattedValue = this.currencyPipe.transform(valueAsString);
                break;
            }

            case 'percent': {
                const digitsInfo = params ? params : '1.1-2';
                formattedValue = this.percentPipe.transform(parseFloat(valueAsString) / 100, digitsInfo);
                break;
            }

            case 'smart_percent': {
                if (value === null || value === undefined) {
                    formattedValue = 'n/a';
                    break;
                }
                const digitsInfo = params ? params : '1.1-2';
                formattedValue = this.percentPipe.transform(parseFloat(valueAsString) / 100, digitsInfo);
                break;
            }

            case 'financial': {
                const digitsInfo = params ? params : '1.2-2';
                let newValue = parseFloat(valueAsString);
                if (newValue < 0) {
                    newValue = Math.abs(newValue);
                    formattedValue = `(${this.decimalPipe.transform(Math.abs(newValue), digitsInfo)})`;
                } else {
                    formattedValue = this.decimalPipe.transform(newValue, digitsInfo);
                }
                break;
            }

            default:
                formattedValue = valueAsString;
        }

        return formattedValue;
    }
}
