<div
    *ngIf="!isCustomizeMode && boardsList && boardsList.length"
    class="d-flex justify-content-between align-items-center p-2"
>
    <div class="d-flex justify-content-between align-items-center w-100">
        <div class="buttons d-flex align-items-center flex-nowrap" #boardHeader>
            <!------- Button Create ------->
            <button
                mat-button
                rbac
                [routerLink]="['/deals/create']"
                [anyRule]="[{deals__create_own: true}, {deals__create_any: true}]"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                mat-raised-button
                class="mat-primary mr-1 dealboard__btn"
                *ngIf="!isMenuOptions"
            >
                Create
            </button>
            <!------- Button Create with mat-menu ------->
            <button
                rbac
                [matMenuTriggerFor]="menu"
                [anyRule]="[{deals__create_own: true}, {deals__create_any: true}]"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                mat-raised-button
                class="mat-primary mr-1 dealboard__btn"
                *ngIf="isMenuOptions"
            >
                Create
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item [routerLink]="['/deals/create']">Create deal</button>
                <app-ui-mod-create-deal-menu-options></app-ui-mod-create-deal-menu-options>
                <!--<button mat-menu-item [routerLink]="['/import/csv']">Import</button>-->
            </mat-menu>

            <!------- Button board list ------->
            <h3
                class="mb-0 ml-2 d-flex align-items-end action-icon"
                *ngIf="!selectedDeal"
                style="font-size: 1.3em"
                [matMenuTriggerFor]="menuDeals"
            >
                <span style="white-space: nowrap">{{ boardsList[activeTabIndex].name }}</span>
                <span class="material-icons d-flex align-self-center">arrow_drop_down</span>
            </h3>

            <mat-menu #menuDeals="matMenu">
                <ng-container *ngFor="let board of boardsList; let idx = index">
                    <button
                        mat-menu-item
                        class="d-flex align-items-center justify-content-between board-item"
                        (click)="handleBoardClick(board.id)"
                        *ngIf="board.id"
                    >
                        {{ board.name }}
                        <div class="d-flex">
                            <span
                                class="material-icons ml-3 default-board-icon"
                                matTooltip="Edit this board"
                                (click)="handleBoardClick(board.id); edit.emit()"
                            >
                                edit
                            </span>
                            <span
                                class="material-icons ml-2 default-board-icon default-board-icon-visible"
                                *ngIf="(defaultBoardIndex | isNumber) && defaultBoardIndex === idx"
                                (click)="$event.stopPropagation(); setBoardAsDefaultEmitter.next(board.id)"
                            >
                                grade
                            </span>
                            <span
                                class="material-icons ml-2 default-board-icon non-default-board-icon"
                                *ngIf="!(defaultBoardIndex | isNumber) || defaultBoardIndex !== idx"
                                matTooltip="Add to Favorites"
                                (click)="$event.stopPropagation(); setBoardAsDefaultEmitter.next(board.id)"
                            >
                                star_outline
                            </span>
                        </div>
                    </button>
                </ng-container>
                <button
                    mat-menu-item
                    style="font-style: italic"
                    class="board-item"
                    [disabled]="isExtraBoardsAllowed"
                    (rbacIsAllowed)="isExtraBoardsAllowed = !$event"
                    rbac
                    [rule]="{create_extra_dealboards: true}"
                    (click)="goToNewBoardTab()"
                >
                    Create new board +
                </button>
            </mat-menu>
        </div>

        <button class="mat-raised-button filter-button" mat-button [matMenuTriggerFor]="filterMenu">
            Filter <mat-icon>filter_list</mat-icon>
        </button>
        <mat-menu #filterMenu="matMenu" class="filter-container">
            <app-deal-board-view-filters
                *ngIf="filters"
                [toggleFiltersView]="toggleFiltersView"
                (filterChanged)="frontendFilterChanged.emit($event)"
                [filters]="filters"
                [boardMeta]="boardMeta"
                [selectedDeal]="selectedDeal"
            ></app-deal-board-view-filters>
        </mat-menu>
    </div>
</div>
