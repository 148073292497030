import {Component, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {BaseWidgetComponent} from '../base.widget.component';
import {Deal} from '../../../../../../models/deal';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {Widget} from '../../../../../../models/widget';

const STATUS_COLORS: {[key: string]: string} = {
    pending: '#fca130',
    active: '#b056ff',
    opportunity: '#66a3ff',
    closed: '#1fa14a',
    cancelled: '#b3b3b3',
    processed: '#DB7093'
};

@Component({
    selector: 'app-status-widget',
    templateUrl: './status.widget.component.html',
    styleUrls: ['../widgets.scss']
})
export class StatusWidgetComponent extends BaseWidgetComponent implements OnDestroy, OnChanges {
    slug = 'Deals by Status';
    DEAL = Deal;
    customColors = [];
    total = 0;

    // public pie_ChartData = [];
    private unsubscribeSub: Subject<void> = new Subject();
    public pie_ChartDataNgx1 = [];
    public idFormName = 'status-form-' + this.randomNum;
    public isDataExist = true;
    public startDateControl: UntypedFormControl = new UntypedFormControl();
    public endDateControl: UntypedFormControl = new UntypedFormControl();

    // public pie_ChartOptions = {
    //     pieHole: 0.7,
    //     width: 150,
    //     height: 150,
    //     pieSliceText: 'none',
    //     legend: 'none',
    //     tooltip: { trigger: 'none' },
    //     backgroundColor: { fill: 'transparent' },
    //     chartArea: {left: 10, top: 10, width: '130', height: '130'},
    //     slices: {
    //         0: { color: '#ffad33' }
    //     }
    // };

    /**
     * On init
     */
    onInit() {
        this.entitiesControl.valueChanges.pipe(takeUntil(this.unsubscribeSub)).subscribe((changes) => {
            this.handleEntityPicker(changes);
        });
    }

    initSubscription() {
        super.initSubscription();

        this.startDateControl.valueChanges.pipe(takeUntil(this.unsubscribeSub)).subscribe((val) => {
            this.widget!.settings.period_start_date = val;
        });

        this.endDateControl.valueChanges.pipe(takeUntil(this.unsubscribeSub)).subscribe((val) => {
            this.widget!.settings.period_end_date = val;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.customizeVisibility && !changes.customizeVisibility.currentValue && this.configPanelVisible) {
            this.hideConfigPanel();
        }
    }

    processData(widget: Widget) {
        this.data = widget.data;

        const settings = this.data.settings || {};
        this.startDateControl.setValue(settings.period_start_date);
        this.endDateControl.setValue(settings.period_end_date);

        // if no data - show N/A
        if (Object.keys(this.data['widget_data']).length === 0) {
            return (this.isDataExist = false);
        }

        this.total = this.data['widget_data'].reduce(
            (total: number, value: {deals_count: number}) => total + value.deals_count,
            0
        );

        this.pie_ChartDataNgx1 = this.data['widget_data'].map((el: any) => ({
            name: el.status,
            value: el.deals_count
        }));
        this.customColors = this.data['widget_data'].map((el: any) => ({
            name: el.status,
            value: STATUS_COLORS[el.status]
        }));
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.unsubscribeSub.next();
        this.unsubscribeSub.complete();
        super.ngOnDestroy();
    }
}
