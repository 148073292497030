import {Injectable} from '@angular/core';
import {CurrentProfileSource} from '../../../../services/sources/current-profile.source';
import {select, Store} from '@ngrx/store';
import {IContactsState} from '../../../contacts/store/contacts.reducer';
import {IFinancialTransfer, IInvoice, IPaymentReceivedMadeRequestObject, IProduct} from '@cyberco-nodejs/zipi-typings';

import {NotificationsServiceZipi} from '../../../notifications/notifications.service';
import {selectProducts} from '../../../finance/store/finance.selectors';
import * as moment from 'moment';
import {DealProcessingService} from './deal-processing.service';
import Decimal from 'decimal.js-light';

@Injectable()
export class DealPayoutsProcessingService {
    public products: IProduct[] = [];

    constructor(
        protected currentProfileSource: CurrentProfileSource,
        protected notificationService: NotificationsServiceZipi,
        private store: Store<IContactsState>,
        protected dealProcessingService: DealProcessingService
    ) {
        this.store.pipe(select(selectProducts)).subscribe((data) => {
            this.products = data;
        });
    }

    getNewInvoiceObjectForPaymentProcessing(paymentInfo: any, payout: IFinancialTransfer, gross: number | null = null) {
        const allTransfers = this.dealProcessingService.dealFG.getRawValue().financial_transfers;
        const contactId = payout.receiver?.contact_id;

        const positiveItems = allTransfers.filter((tr) => tr.payout_to_hash === payout.unique_hash);
        const negativeItems = allTransfers.filter((tr) => tr.payout_from_hash === payout.unique_hash);
        if (payout.type === 'agent_payout') {
            const splitTransfer = allTransfers.find(
                (tr) => tr.receiver?.contact_id === contactId && tr.type && ['agent_split'].includes(tr.type)
            );
            if (splitTransfer) {
                positiveItems.push(splitTransfer);
            }
        }

        // todo: recheck and remove this after "transfer sender = null" fix
        let senderContactCompanyId = payout.sender_contact__company_fk_id;
        if (!senderContactCompanyId && payout.sender && payout.sender.contact) {
            senderContactCompanyId = payout.sender.contact.partner__company_fk_id!;
        }

        const newInvoice: IInvoice = {
            creator__company_fk_id: null,
            creator__profile_fk_id: null,
            division__company_group_fk_id: null,
            invoice_number: null,
            source__company_fk_id: null,
            owner__company_fk_id: null,
            connected__bill_fk_id: null,
            status_of_connected_bill: null,
            items_preview: [],
            money_sender__company_fk_id: paymentInfo.money_sender__company_fk_id
                ? paymentInfo.money_sender__company_fk_id
                : senderContactCompanyId,
            money_sender__contact_fk_id: paymentInfo.money_sender__contact_fk_id
                ? paymentInfo.money_sender__contact_fk_id
                : payout.sender!.contact_id,

            sales_person__profile_fk_id: null,
            payment_terms: 'due-on-receipt',
            total_amount: gross ? gross : paymentInfo.amount,
            invoice_date: Number(moment(paymentInfo.escrow_date).format('YYYYMMDD')),
            due_date: Number(moment(paymentInfo.escrow_date).format('YYYYMMDD')),
            balance: null,
            pending_balance: null,
            to_be_paid__payment_method_fk_id: null,
            pay_to__payment_method_fk_ids: paymentInfo.pay_to__payment_method_fk_ids,
            pay_to_card__payment_method_fk_id: paymentInfo.pay_to_card__payment_method_fk_id,
            pay_to_bank__payment_method_fk_id: paymentInfo.pay_to_bank__payment_method_fk_id,
            items: [],

            invoice_note: null,
            recurring_invoice_fk_id: null,
            invoice_hash: null,
            is_deduct_from_deal: null,

            status_of_payment: null,
            status_of_notification: null,
            status_of_invoice: null,
            status_in_portal: null,

            summary_status: null,
            status_order: null,

            notification_period: [],
            unpaid_reminder_period: null,
            auto_recharge_1: null,
            auto_recharge_2: null,
            auto_recharge_3: null,
            late_fee: null,
            late_fee_product_fk_id: null,
            source__financial_transfer_fk_id: payout.financial_transfer_id ? payout.financial_transfer_id : null,
            source__deal_fk_id: payout.deal_fk_id,
            auto_creation_info: null,

            scenario: 'commission_payout',
            bank_fee_payer: 'money_sender',
            card_fee_payer: 'money_sender'
        };

        positiveItems.forEach((tr) => {
            const transferProduct = this.products.find((prod) => prod.product_id === tr.product_fk_id);
            newInvoice?.items?.push({
                product_fk_id: transferProduct && transferProduct.product_id ? transferProduct.product_id : null,
                name: transferProduct && transferProduct.name ? transferProduct.name : '',
                description: transferProduct && transferProduct.description ? transferProduct.description : '',
                quantity: 1,
                rate: tr.amount as number,
                amount: tr.amount as number,
                ledger_account_fk_id:
                    transferProduct && transferProduct.ledger_account_fk_id
                        ? transferProduct.ledger_account_fk_id
                        : null,
                invoice_fk_id: null,
                creator__company_fk_id: null,
                connected__financial_transfer_fk_id: null,
                connected__deal_fk_id: null,
                deal_connection_mode: null,
                order_index: 1,
                division__company_group_fk_id: null
            });
        });
        negativeItems.forEach((tr) => {
            const transferProduct = this.products.find((prod) => prod.product_id === tr.product_fk_id);
            newInvoice?.items?.push({
                product_fk_id: transferProduct && transferProduct.product_id ? transferProduct.product_id : null,
                name: transferProduct && transferProduct.name ? transferProduct.name : '',
                description: transferProduct && transferProduct.description ? transferProduct.description : '',
                quantity: 1,
                rate: (tr.amount as number) * -1,
                amount: (tr.amount as number) * -1,
                ledger_account_fk_id:
                    transferProduct && transferProduct.ledger_account_fk_id
                        ? transferProduct.ledger_account_fk_id
                        : null,
                invoice_fk_id: null,
                creator__company_fk_id: null,
                connected__financial_transfer_fk_id: null,
                connected__deal_fk_id: null,
                deal_connection_mode: null,
                order_index: 1,
                division__company_group_fk_id: null
            });
        });

        const itemsAmount: Decimal = newInvoice.items!.reduce(
            (acc, tr) => acc.add(tr.amount as number),
            new Decimal(0)
        );

        if (
            itemsAmount.toDecimalPlaces(2).toNumber() !==
            new Decimal(newInvoice.total_amount as number).toDecimalPlaces(2).toNumber()
        ) {
            const product = this.products.find((prod) => prod.product_id === paymentInfo.product_fk_id);

            newInvoice?.items?.push({
                product_fk_id: product && product.product_id ? product.product_id : null,
                name: 'Unknown Item',
                description: 'This Item was added to keep balance of Invoice',
                quantity: 1,
                rate: new Decimal(newInvoice.total_amount as number).sub(itemsAmount).toDecimalPlaces(2).toNumber(),
                amount: new Decimal(newInvoice.total_amount as number).sub(itemsAmount).toDecimalPlaces(2).toNumber(),
                ledger_account_fk_id: product && product.ledger_account_fk_id ? product.ledger_account_fk_id : null,
                invoice_fk_id: null,
                creator__company_fk_id: null,
                connected__financial_transfer_fk_id: null,
                connected__deal_fk_id: null,
                deal_connection_mode: null,
                order_index: 1,
                division__company_group_fk_id: null
            });
        }

        return newInvoice;
    }

    getNewInvoicePaymentObjectForPaymentProcessing(paymentInfo: any, transfer: IFinancialTransfer) {
        let requestPaymentInfo = null;

        if (paymentInfo.is_record_payment) {
            const requestObj: IPaymentReceivedMadeRequestObject = {
                money_receiver__contact_fk_id: null,
                money_sender__contact_fk_id: paymentInfo.money_sender__contact_fk_id
                    ? paymentInfo.money_sender__contact_fk_id
                    : transfer.sender!.contact_id,
                paid_date: Number(moment(paymentInfo.invoice_date, 'YYYYMMDD').format('YYYYMMDD')),
                scheduled_date: null,
                amount: Number(paymentInfo.amount),
                paid_by__ledger_account_fk_id: null,
                paid_by__payment_method_fk_id: null,
                pay_to__ledger_account_fk_id: paymentInfo.ledger_account_id,
                pay_to__payment_method_fk_id: null,
                payments: [],
                is_create_multiple_payments: true,
                payment_mode: paymentInfo.payment_mode,
                payment_number: null,
                reference: paymentInfo.reference,
                notes: null,

                matched__transaction_external_id: null,
                check_info: null,
                deposit_release_id: null,
                is_locked_for_applying: false,
                allow_auto_apply: false,
                source__deal_fk_id: transfer.deal_fk_id,
                sender_velocity: null,
                restrict_downgrade: false
            };
            if (paymentInfo.is_check_tab) {
                requestObj.check_info = {
                    memo: paymentInfo.memo,
                    check_number: paymentInfo.check_number,
                    check_status: 'uncleared',
                    print_status: 'not_printed'
                };
            }
            requestPaymentInfo = requestObj;
        }

        return requestPaymentInfo;
    }
}
