import {Component, OnDestroy, OnInit} from '@angular/core';
import {filter, takeUntil} from 'rxjs/operators';
import {firstValueFrom, forkJoin, Observable, Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {InvoicePublicService, type PublicInvoice} from '@app/modules/finance/services/invoice-public.service';
import {IContact, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {NotificationsServiceZipi} from '@app/modules/notifications/notifications.service';
import {ZipiFinancialIframeDialogComponent} from '@app/modules/account-info/company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-financial-iframe-dialog/zipi-financial-iframe-dialog.component';
import {IZipiFinancialIframeDialogData} from '@app/typings/zipi-financial-iframe';
import {MatDialog} from '@angular/material/dialog';
import {PaymentTypeSelectionDialogComponent} from '@app/modules/finance/components/invoice-public/dialogs/payment-type-selection-dialog/payment-type-selection-dialog.component';
import {SetupPaymentInfoDialogComponent} from '@app/modules/finance/components/statement-public/setup-payment-info-dialog/setup-payment-info-dialog.component';
import {IInvoiceGroup} from '@app/modules/finance/components/statement-public/statement-public-local-typings/statement.typings';
import {SendingPaymentLoaderDialogComponent} from '@app/modules/finance/components/common/sending-payment-loader-dialog/sending-payment-loader-dialog.component';

const win: any = window;
const Payload: any = win.Payload;

@Component({
    selector: 'app-statement-public-page',
    templateUrl: './statement-public-page.component.html',
    styleUrls: ['./statement-public-page.component.scss']
})
export class StatementPublicPageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    statementHash: string | null = null;
    zipiFinCustomerRef: string | null = null;

    statementInvoiceCount: number = 0;
    allInvoices: PublicInvoice[] = [];
    allUnpaidInvoices: PublicInvoice[] = [];
    unpaidPreviousInvoices: PublicInvoice[] = [];
    unpaidCurrentInvoices: PublicInvoice[] = [];
    processingInvoices: PublicInvoice[] = [];
    paidInvoices: PublicInvoice[] = [];

    currentContact: IContact | null = null;
    statement: {[key: string]: any} = {};

    invoicePayloadBankMethods: IPaymentMethod[] = [];
    invoicePayloadCardMethods: IPaymentMethod[] = [];
    invoiceAuthorizeNetMethods: IPaymentMethod[] = [];

    brokerName: string | null = null;
    totalInvoiceAmount: number = 0;
    totalAmountDue: number = 0;
    previousBalance: number | null = null;
    totalPaidAmount: number = 0;

    lastPaidType: 'payload_card' | 'payload_bank' | 'authorize_net' | null = null;

    isPayInvoiceButtonDisabled: boolean = false;

    itemPluralMapping = {
        invoice: {
            '=1': '1 Invoice',
            other: '# Invoices'
        }
    };

    constructor(
        protected activatedRoute: ActivatedRoute,
        private invoicePublicService: InvoicePublicService,
        protected notificationServiceZipi: NotificationsServiceZipi,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            this.statementHash = params['statement_hash'];
            this.loadStatementsDetails();
        });
    }

    async loadStatementsDetails() {
        if (!this.statementHash) return;

        const response = await firstValueFrom(
            this.invoicePublicService.getInfoForPublicStatementPageByHash(this.statementHash)
        );

        this.allInvoices = response.invoices.sort((a, b) => (a.invoice_date ?? 0) - (b.invoice_date ?? 0));
        let statementInvoices = this.allInvoices.filter((inv) => !inv.pre_statement);
        this.statementInvoiceCount = statementInvoices.length;

        this.allUnpaidInvoices = this.allInvoices.filter((inv) => inv.pending_balance && inv.pending_balance > 0);
        this.unpaidPreviousInvoices = this.allUnpaidInvoices.filter((inv) => inv.pre_statement);
        this.unpaidCurrentInvoices = this.allUnpaidInvoices.filter((inv) => !inv.pre_statement);

        this.processingInvoices = statementInvoices.filter(
            (inv) =>
                !inv.pending_balance &&
                inv.status_of_payment &&
                ['processing', 'pending'].includes(inv.status_of_payment)
        );
        this.paidInvoices = statementInvoices.filter((inv) => !inv.pending_balance && inv.summary_status === 'paid');

        this.statement = response.statement;
        this.zipiFinCustomerRef = response.zipi_fin_customer_ref;
        this.brokerName = response.company_title;
        this.previousBalance = response.previous_balance;

        this.totalInvoiceAmount = statementInvoices.reduce((acc, inv) => acc + (inv.total_amount ?? 0), 0);
        const totalPendingBalance = statementInvoices.reduce((acc, inv) => acc + (inv.pending_balance ?? 0), 0);
        this.totalPaidAmount = this.totalInvoiceAmount - totalPendingBalance;
        this.totalAmountDue = totalPendingBalance + (this.previousBalance ?? 0);

        this.loadContact();
        this.loadMethods();
    }

    loadContact() {
        if (!this.statement || !this.statement.contact_fk_id) return;
        this.invoicePublicService
            .getPublicContact(this.statement.contact_fk_id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((contact) => {
                this.currentContact = contact;
            });
    }

    loadMethods() {
        const bankMethodIds: Set<number> = new Set();
        const cardMethodIds: Set<number> = new Set();
        this.allUnpaidInvoices.forEach((invoice) => {
            if (invoice.pay_to_bank__payment_method_fk_id) {
                bankMethodIds.add(invoice.pay_to_bank__payment_method_fk_id);
            }
            if (invoice.pay_to_card__payment_method_fk_id) {
                cardMethodIds.add(invoice.pay_to_card__payment_method_fk_id);
            }
        });

        const requestsList: {
            banks?: Observable<IPaymentMethod[]>;
            cards?: Observable<IPaymentMethod[]>;
        } = {};
        if (bankMethodIds.size > 0) {
            requestsList.banks = this.invoicePublicService.getInvoiceMethods(Array.from(bankMethodIds));
        }
        if (cardMethodIds.size > 0) {
            requestsList.cards = this.invoicePublicService.getInvoiceMethods(Array.from(cardMethodIds));
        }

        forkJoin(requestsList)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.invoicePayloadBankMethods = [];
                this.invoicePayloadCardMethods = [];
                this.invoiceAuthorizeNetMethods = [];

                const isPayload = (method: IPaymentMethod) => method.payment_gateway?.driver_type === 'payload';

                if (data.banks && data.banks.length > 0) {
                    const filteredBankMethods = data.banks.filter(isPayload);
                    this.invoicePayloadBankMethods = filteredBankMethods;
                }
                if (data.cards && data.cards.length > 0) {
                    const filteredPayloadCardMethods = data.cards.filter(isPayload);
                    const filteredAuthorizeCardMethods = data.cards.filter(
                        (cardMethod) => cardMethod?.payment_gateway?.type === 'authorize_net_merchant'
                    );
                    this.invoicePayloadCardMethods = filteredPayloadCardMethods;
                    // TODO: we left this in case we decide to use it in the future
                    // this.invoiceAuthorizeNetMethods = filteredAuthorizeCardMethods;
                }
                if (this.lastPaidType) {
                    this.setupPaymentInfo(this.lastPaidType);
                }
                this.isPayInvoiceButtonDisabled = false;
            });
    }

    async setupZipiFinancialForContact(
        methodType: 'contact_gateway_only',
        driverType: 'payload',
        payloadPayType: 'bank' | 'card'
    ) {
        let mainPerson = null;
        if (
            this.currentContact &&
            this.currentContact.contact_persons &&
            this.currentContact.contact_persons.length > 0
        ) {
            mainPerson = this.currentContact.contact_persons.find((person) => person.type === 'main_person');
        }
        const contactData = {
            company_name: this.currentContact?.company_name ? this.currentContact?.company_name : '',
            email: mainPerson && mainPerson.email ? mainPerson.email : '',
            first_name: mainPerson && mainPerson.first_name ? mainPerson.first_name : '',
            last_name: mainPerson && mainPerson.last_name ? mainPerson.last_name : '',
            nick_name: mainPerson && mainPerson.preferred_name ? mainPerson.preferred_name : ''
        };
        const dialogRef = this.dialog.open<ZipiFinancialIframeDialogComponent, IZipiFinancialIframeDialogData>(
            ZipiFinancialIframeDialogComponent,
            {
                disableClose: true,
                maxHeight: '80vh',
                width: '650px',
                panelClass: 'custom-dialog-container',
                data: {
                    contactId: this.currentContact?.contact_id ? this.currentContact?.contact_id : null,
                    gateway: null,
                    ownGateway: null,
                    driverType: driverType,
                    paymentMethod: null,
                    methodType: methodType,
                    accessMethod: 'public',
                    isUniversal: true,
                    customerId: this.zipiFinCustomerRef,
                    prefillData: contactData,
                    creationSource: 'current_company',
                    storeAccountMethod: 'required'
                }
            }
        );

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result.isError) {
                    this.notificationServiceZipi.addError(result.message);
                }
                if (result.isClosed) {
                    return;
                }
                this.loadContact();
                this.setupPaymentInfo(payloadPayType === 'bank' ? 'payload_bank' : 'payload_card');
            });
    }

    payInvoiceStatement() {
        if (
            this.invoicePayloadBankMethods.length > 0 &&
            (this.invoicePayloadCardMethods.length > 0 || this.invoiceAuthorizeNetMethods.length > 0)
        ) {
            this.openSelectionPopup();
        } else if (
            this.invoiceAuthorizeNetMethods.length === 0 &&
            (this.invoicePayloadBankMethods.length > 0 || this.invoicePayloadCardMethods.length > 0)
        ) {
            this.prepareToOpenPayloadForm(this.invoicePayloadBankMethods.length > 0 ? 'bank' : 'card');
        } else if (
            this.invoicePayloadBankMethods.length === 0 &&
            this.invoicePayloadCardMethods.length === 0 &&
            this.invoiceAuthorizeNetMethods.length > 0
        ) {
            this.setupPaymentInfo('authorize_net');
        } else {
            this.notificationServiceZipi.addInfo('There is no way to pay this Invoice.');
        }
    }

    setupPaymentInfo(type: 'payload_card' | 'payload_bank' | 'authorize_net') {
        let allowedMethods = null;
        switch (type) {
            case 'payload_card':
                allowedMethods = this.invoicePayloadCardMethods;
                break;
            case 'payload_bank':
                allowedMethods = this.invoicePayloadBankMethods;
                break;
            case 'authorize_net':
                allowedMethods = this.invoiceAuthorizeNetMethods;
                break;
        }

        if (allowedMethods) {
            const methodIds = allowedMethods.map((method) => method.payment_method_id);

            const invoices = this.allUnpaidInvoices.filter((invoice) => {
                if (type === 'payload_bank') {
                    return (
                        invoice.pay_to_bank__payment_method_fk_id &&
                        methodIds.includes(invoice.pay_to_bank__payment_method_fk_id)
                    );
                } else {
                    return (
                        invoice.pay_to_card__payment_method_fk_id &&
                        methodIds.includes(invoice.pay_to_card__payment_method_fk_id)
                    );
                }
            });

            if (invoices.length > 0) {
                const dialogRef = this.dialog.open(SetupPaymentInfoDialogComponent, {
                    width: '750px',
                    maxHeight: '80vh',
                    panelClass: 'no-padding-dialog',
                    disableClose: true,
                    data: {
                        allowedMethods: allowedMethods,
                        type: type,
                        invoices: invoices,
                        brokerName: this.brokerName
                    }
                });

                dialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((result: IInvoiceGroup) => {
                        if (
                            result &&
                            result.payment_amount > 0 &&
                            result.invoice_ids.length > 0 &&
                            result.payment_type
                        ) {
                            switch (result.payment_type) {
                                case 'payload_card':
                                case 'payload_bank':
                                    this.payViaPayload(result);
                                    break;
                                case 'authorize_net':
                                    this.payViaAuthorize(result);
                                    break;
                            }
                        }
                    });
            }
        }
    }

    openSelectionPopup() {
        const dialogRef = this.dialog.open<PaymentTypeSelectionDialogComponent>(PaymentTypeSelectionDialogComponent, {
            minWidth: '600px',
            maxHeight: '80vh',
            disableClose: true,
            data: {}
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result: 'bank' | 'card' | null) => {
                if (result) {
                    if (result === 'bank') {
                        this.prepareToOpenPayloadForm('bank');
                    } else if (result === 'card') {
                        if (this.invoiceAuthorizeNetMethods.length > 0) {
                            this.setupPaymentInfo('authorize_net');
                        } else {
                            this.prepareToOpenPayloadForm('card');
                        }
                    }
                }
            });
    }

    prepareToOpenPayloadForm(payloadPayType: 'bank' | 'card') {
        if (this.currentContact) {
            if (
                !this.currentContact.contact_payment_gateways ||
                this.currentContact.contact_payment_gateways.length === 0 ||
                !this.currentContact.contact_payment_gateways.some((cpg) => cpg.driver_type === 'payload')
            ) {
                this.setupZipiFinancialForContact('contact_gateway_only', 'payload', payloadPayType);
            } else {
                this.setupPaymentInfo(payloadPayType === 'bank' ? 'payload_bank' : 'payload_card');
            }
        }
    }

    async payViaPayload(paymentData: IInvoiceGroup) {
        if (this.currentContact) {
            if (
                paymentData &&
                paymentData.invoice_ids.length > 0 &&
                paymentData.pay_to__payment_method_fk_id &&
                this.statementHash
            ) {
                this.isPayInvoiceButtonDisabled = true;
                let settings = null;
                try {
                    settings = await firstValueFrom(
                        this.invoicePublicService.getPayloadSettingsForStatement(paymentData, this.statementHash)
                    );
                } catch (e) {
                    console.error(e);
                }
                this.isPayInvoiceButtonDisabled = false;
                if (!settings || !settings.token) {
                    console.error('Cannot get settings to initialize payment popup.');
                    return;
                }
                Payload(settings.token);

                const checkout = new Payload.Checkout({
                    card_payments: paymentData.payment_type === 'payload_card',
                    bank_account_payments: paymentData.payment_type === 'payload_bank'
                })
                    .on('loaded', (evt: any) => {})
                    .on('authorized', (evt: any) => {})
                    .on('processed', (evt: {transaction_id: string; type: 'processed'}) => {})
                    .on('success', (evt: {transaction_id: string; type: 'success'}) => {
                        this.lastPaidType = paymentData.payment_type;
                        this.createPayloadCoPayment(evt.transaction_id, paymentData);
                    })
                    .on('declined', (evt: any) => {})
                    .on('closed', (evt: any) => {});
            }
        }
    }

    createPayloadCoPayment(payloadTransactionId: string, paymentData: IInvoiceGroup) {
        this.isPayInvoiceButtonDisabled = true;
        const payloadCoPaymentObj = {
            transaction_id: payloadTransactionId,
            payment_data: paymentData,
            statement_hash: this.statementHash
        };

        const dialogRef = this.dialog.open<SendingPaymentLoaderDialogComponent, {driver: 'payload_co' | 'authorize'}>(
            SendingPaymentLoaderDialogComponent,
            {
                minWidth: '500px',
                maxHeight: '80vh',
                disableClose: true,
                data: {
                    driver: 'payload_co'
                }
            }
        );

        this.invoicePublicService
            .publicPayloadCoInvoiceStatementPay(payloadCoPaymentObj)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                dialogRef.close();
                this.ngOnInit();
            });
    }

    payViaAuthorize(paymentData: IInvoiceGroup) {
        // TODO: we left this in case we decide to implement it in the future
        // if (this.invoice && this.currentContact && this.invoiceAuthorizeNetMethod) {
        //     const dialogRef = this.dialog.open<PayViaAuthorizeDialogComponent, { title: string; contact: IContact; method: IPaymentMethod; invoice: IInvoice }>(PayViaAuthorizeDialogComponent, {
        //         minWidth: '600px',
        //         maxHeight: '80vh',
        //         data: {
        //             title: 'Pay Invoice',
        //             invoice: this.invoice,
        //             contact: this.currentContact,
        //             method: this.invoiceAuthorizeNetMethod
        //         }
        //     });
        //
        //     dialogRef.afterClosed().pipe(
        //         filter(pn => !!pn),
        //         takeUntil(this.unsubscribe))
        //         .subscribe(result => {
        //             this.ngOnInit();
        //         });
        // }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
