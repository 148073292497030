<ng-container
    class="d-flex flex-column mb-4"
    [class.grey-out]="
        _transferFG.controls.pay_at_escrow &&
        !_transferFG.controls.pay_at_escrow.value &&
        !_transferFG.controls.override?.controls?.pay_at_escrow?.value
    "
>
    <div style="display: flex; flex-direction: column">
        <div class="options-wrapper">
            <!------- Label ------->
            <mat-form-field *ngIf="!hideLabel && _transferFG.controls.label" class="four-width transfer-field">
                <mat-icon
                    matPrefix
                    *ngIf="_transferFG.controls.is_modified_by_user && _transferFG.controls.is_modified_by_user.value"
                    matTooltip="Transfer has been changed by the user"
                >
                    drive_file_rename_outline
                </mat-icon>
                <input matInput placeholder="Label" [formControl]="_transferFG.controls.label" type="text" />
                <mat-error *ngIf="_transferFG.controls.label.invalid">Required field</mat-error>
            </mat-form-field>

            <!------- Sender/Receiver Contact ------->
            <app-company-compensation-combined-picker
                *ngIf="!isExpense && !hideSender"
                [seemDisabled]="!!(_transferFG.controls.pay_at_escrow && !_transferFG.controls.pay_at_escrow.value)"
                [disabled]="
                    !!(
                        _transferFG.controls.type &&
                        _transferFG.controls.type.value === FINANCIAL_TRANSFER_ENTITY.type_SET.agent_split
                    ) ||
                    !!(
                        _transferFG.controls.type &&
                        _transferFG.controls.type.value === FINANCIAL_TRANSFER_ENTITY.type_SET.company_split_individual
                    ) ||
                    disabled
                "
                [disableUpdate]="disabled"
                [title]="senderLabel"
                [availableTypes]="['contact', 'contact_wildcard', 'wildcard']"
                class="four-width four-width"
                [singleSelected]="true"
                [bold_style]="false"
                [allowDealWildcard]="
                    !!(
                        _transferFG.controls.origin &&
                        origin !== _transferFG.controls.origin.value &&
                        (_transferFG.controls.origin.value === FINANCIAL_ELEMENT_ENTITY.type_set.company ||
                            _transferFG.controls.origin.value === FINANCIAL_ELEMENT_ENTITY.type_set.referral ||
                            _transferFG.controls.origin.value === FINANCIAL_ELEMENT_ENTITY.type_set.sales)
                    )
                "
                [nodesFA]="senderApplyTo"
                [lineClamp]="true"
                [required]="true"
            ></app-company-compensation-combined-picker>
            <app-company-compensation-combined-picker
                *ngIf="isExpense && !hideReceiver"
                [seemDisabled]="!!(_transferFG.controls.pay_at_escrow && !_transferFG.controls.pay_at_escrow.value)"
                [disabled]="
                    !!(
                        _transferFG.controls.type &&
                        _transferFG.controls.type.value === FINANCIAL_TRANSFER_ENTITY.type_SET.agent_split
                    ) ||
                    !!(
                        _transferFG.controls.type &&
                        _transferFG.controls.type.value === FINANCIAL_TRANSFER_ENTITY.type_SET.company_split_individual
                    ) ||
                    disabled
                "
                [disableUpdate]="disabled"
                [title]="receiverLabel"
                [availableTypes]="['contact', 'contact_wildcard', 'wildcard']"
                class="four-width four-width"
                [singleSelected]="true"
                [bold_style]="false"
                [allowDealWildcard]="
                    !!(
                        _transferFG.controls.origin &&
                        origin !== _transferFG.controls.origin.value &&
                        (_transferFG.controls.origin.value === FINANCIAL_ELEMENT_ENTITY.type_set.company ||
                            _transferFG.controls.origin.value === FINANCIAL_ELEMENT_ENTITY.type_set.referral ||
                            _transferFG.controls.origin.value === FINANCIAL_ELEMENT_ENTITY.type_set.sales)
                    )
                "
                [nodesFA]="receiverApplyTo"
                [lineClamp]="true"
                [required]="true"
            ></app-company-compensation-combined-picker>

            <!------- Amount/Percent ------->
            <app-value-picker
                class="four-width"
                *ngIf="
                    !hideAmount &&
                    _transferFG.controls.type &&
                    _transferFG.controls.type.value === FINANCIAL_TRANSFER_ENTITY.type_SET.company_split_individual &&
                    _transferFG.controls.amount &&
                    _transferFG.controls.percent &&
                    _transferFG.controls.value_type &&
                    _transferFG.controls.value_wildcard_fk_id
                "
                [disabled]="true"
                [wildcardType]="$any(WILDCARD.type_SET.numeric)"
                [valueFC]="_transferFG.controls.amount"
                [percentFC]="_transferFG.controls.percent"
                [valueTypeFC]="_transferFG.controls.value_type"
                [valueWildcardIdFC]="_transferFG.controls.value_wildcard_fk_id"
                [allowPercents]="isExpense || allowPercents"
                [isPercent]="!!(_transferFG.controls.value_type && isPercent(_transferFG.controls.value_type.value))"
            >
            </app-value-picker>

            <app-value-picker
                class="four-width"
                *ngIf="
                    !hideAmount &&
                    _transferFG.controls.type &&
                    _transferFG.controls.type.value !== FINANCIAL_TRANSFER_ENTITY.type_SET.company_split_individual &&
                    _transferFG.controls.source_amount &&
                    _transferFG.controls.percent &&
                    _transferFG.controls.value_type &&
                    _transferFG.controls.value_wildcard_fk_id
                "
                [wildcardType]="$any(WILDCARD.type_SET.numeric)"
                [valueFC]="_transferFG.controls.source_amount"
                [percentFC]="_transferFG.controls.percent"
                [valueTypeFC]="_transferFG.controls.value_type"
                [valueWildcardIdFC]="_transferFG.controls.value_wildcard_fk_id"
                [allowPercents]="isExpense || allowPercents"
                [disabled]="disabled"
                [isPercent]="!!(_transferFG.controls.value_type && isPercent(_transferFG.controls.value_type.value))"
            >
            </app-value-picker>

            <!------- Value Type ------->
            <ng-container
                *ngIf="
                    isExpense ||
                    (_transferFG.controls.type &&
                        _transferFG.controls.type.value === FINANCIAL_TRANSFER_ENTITY.type_SET.agent_split)
                "
            >
                <mat-form-field
                    class="four-width transfer-field"
                    *ngIf="
                        !hideValueType &&
                        ((_transferFG.controls.origin_is_expense && _transferFG.controls.origin_is_expense.value) ||
                            (_transferFG.controls.type &&
                                _transferFG.controls.type.value === FINANCIAL_TRANSFER_ENTITY.type_SET.agent_split)) &&
                        _transferFG.controls.value_type
                    "
                    style="width: 135px"
                >
                    <mat-select
                        [class.grey-out]="
                            _transferFG.controls.pay_at_escrow && !_transferFG.controls.pay_at_escrow.value
                        "
                        [formControl]="_transferFG.controls.value_type"
                        name="referral_fee_type"
                    >
                        <ng-container *ngIf="_transferFG.controls.type">
                            <mat-option
                                [value]="valueType"
                                *ngFor="
                                    let valueType of availableExpenseTypes[
                                        _transferFG.controls.type &&
                                        _transferFG.controls.type.value ===
                                            FINANCIAL_TRANSFER_ENTITY.type_SET.agent_split &&
                                        _transferFG.controls.origin
                                            ? 'agent'
                                            : _transferFG.controls.origin && _transferFG.controls.origin.value
                                              ? _transferFG.controls.origin.value
                                              : 'agent'
                                    ]
                                "
                                [disabled]="
                                    valueType === FINANCIAL_TRANSFER_ENTITY.value_type_SET.percent_of_entity_split
                                "
                            >
                                {{ FINANCIAL_TRANSFER_ENTITY.value_type_LABELS[valueType] }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <mat-form-field
                    class="four-width transfer-field"
                    *ngIf="
                        !!(
                            !hideValueType &&
                            _transferFG.controls.origin_is_expense &&
                            !_transferFG.controls.origin_is_expense.value &&
                            _transferFG.controls.type &&
                            !(_transferFG.controls.type.value === FINANCIAL_TRANSFER_ENTITY.type_SET.agent_split) &&
                            !!_transferFG.controls.value_type
                        )
                    "
                    style="width: 135px"
                >
                    <mat-select
                        [class.grey-out]="
                            _transferFG.controls.pay_at_escrow && !_transferFG.controls.pay_at_escrow.value
                        "
                        [formControl]="_transferFG.controls.value_type"
                        name="referral_fee_type"
                    >
                        <ng-container *ngIf="_transferFG.controls.origin"
                            ><mat-option
                                [value]="valueType"
                                *ngFor="let valueType of availableIncomeTypes[_transferFG.controls.origin.value]"
                            >
                                {{ FINANCIAL_TRANSFER_ENTITY.value_type_LABELS[valueType] }}
                            </mat-option></ng-container
                        >
                    </mat-select>
                </mat-form-field>
            </ng-container>

            <!------- Product/Service ------->
            <app-product-service-selector
                class="four-width"
                *ngIf="!hideProduct"
                [placeholder]="'Product/Service'"
                (onProductSelectionChange)="selectItem($event)"
                [disabled]="disabled"
                [disabledReselect]="disabled"
                [productServiceControl]="_transferFG.controls.product_fk_id"
            >
            </app-product-service-selector>

            <!------- Pay At Closing ------->
            <ng-container
                *ngIf="
                    isExpense &&
                    !hidePayAtEscrowFlag &&
                    !['deduct_transfer', 'associate_transfer'].includes(_transferFG!.controls.type!.value)
                "
            >
                <app-pay-at-closing-picker
                    style="padding-right: 5px"
                    class="override-pay-at-closing"
                    [canOverride]="true"
                    [disabled]="_transferFG.disabled"
                    [financialTransferFG]="_transferFG"
                >
                </app-pay-at-closing-picker>
            </ng-container>

            <!------- Disbursement Instructions ------->
            <div class="disbursement-block mt-2" *ngIf="!isExpense && !hideDisbursementInstructionsFlag">
                <ng-container>
                    <mat-slide-toggle
                        color="primary"
                        style="margin-right: 5px; z-index: 2"
                        [ngClass]="{overridden: !!_transferFG.controls.override?.controls?.disbursement_instructions}"
                        [checked]="
                            !_transferFG.controls.override?.controls?.disbursement_instructions
                                ? _transferFG.controls.disbursement_instructions?.value
                                : _transferFG.controls.override?.controls?.disbursement_instructions?.value
                        "
                        [formControl]="disbursementInstructionsFC"
                    >
                        <span *ngIf="!disbursementInstructionsFC.value">Disbursement Instructions</span>
                    </mat-slide-toggle>
                    <div
                        *ngIf="
                            _transferFG.controls.override?.controls?.disbursement_instructions &&
                            disbursementInstructionsFC.value
                        "
                        style="
                            flex-direction: row;
                            display: flex;
                            align-items: center;
                            padding-right: 5px;
                            z-index: 2;
                            width: 100%;
                        "
                    >
                        <app-value-picker
                            color="primary"
                            *ngIf="
                                _transferFG.controls.override &&
                                _transferFG.controls.override.controls.notes &&
                                _transferFG.controls.override.controls.notes_wildcard_fk_id
                            "
                            [wildcardType]="$any(WILDCARD.type_SET.text)"
                            [valueFC]="_transferFG.controls.override.controls.notes"
                            [valueWildcardIdFC]="_transferFG.controls.override.controls.notes_wildcard_fk_id"
                            [class.fade-out]="
                                _transferFG.controls.override &&
                                _transferFG.controls.override.controls.disbursement_instructions &&
                                !_transferFG.controls.override.controls.disbursement_instructions.value
                            "
                            style="
                                z-index: 2;
                                justify-content: space-between;
                                display: flex;
                                align-items: center;
                                margin-right: 5px;
                                width: 100%;
                            "
                        >
                        </app-value-picker>
                    </div>
                    <div
                        *ngIf="
                            !_transferFG.controls.override?.controls?.disbursement_instructions &&
                            _transferFG.controls.disbursement_instructions &&
                            _transferFG.controls.disbursement_instructions.value
                        "
                        style="
                            flex-direction: row;
                            display: flex;
                            align-items: center;
                            padding-right: 5px;
                            z-index: 0;
                            width: 100%;
                        "
                    >
                        <app-value-picker
                            color="black"
                            *ngIf="_transferFG.controls.notes && _transferFG.controls.notes_wildcard_fk_id"
                            [wildcardType]="$any(WILDCARD.type_SET.text)"
                            [valueFC]="_transferFG.controls.notes"
                            [valueWildcardIdFC]="_transferFG.controls.notes_wildcard_fk_id"
                            [class.grey-out]="
                                _transferFG.controls.disbursement_instructions &&
                                !_transferFG.controls.disbursement_instructions.value
                            "
                            style="
                                justify-content: space-between;
                                display: flex;
                                align-items: center;
                                margin-right: 5px;
                                z-index: 0;
                                width: 100%;
                            "
                        >
                        </app-value-picker>
                    </div>
                    <mat-icon
                        style="color: rgba(0, 0, 0, 0.54); margin-right: 5px; cursor: help; z-index: 2"
                        matTooltip="Turn this on only if you would like to override any dynamically set individual or group disbursement instructions."
                    >
                        help_outline
                    </mat-icon>
                </ng-container>
            </div>

            <!------- Recalculation Icon ------->
            <div
                class="recalculation-info-icon"
                *ngIf="
                    _transferFG.controls.type &&
                    _transferFG.controls.type.value === FINANCIAL_TRANSFER_ENTITY.type_SET.company_split_individual &&
                    _transferFG.controls.is_deleted_by_user &&
                    _transferFG.controls.is_deleted_by_user.value &&
                    _transferFG.controls.amount &&
                    _transferFG.controls.amount.value > 0
                "
            >
                <mat-icon color="warn" [matTooltip]="'This transfer will be recalculated'">warning_amber</mat-icon>
            </div>
        </div>
    </div>
    <div
        class="cap-block disbursement-block"
        *ngIf="
            contributeToCapFlag &&
            isExpense &&
            isSalesEntityAgentCardContext &&
            availableContributeToCapsOrigins[_transferFG.controls.origin?.value]?.length &&
            !['deduct_transfer', 'associate_transfer'].includes(_transferFG!.controls.type!.value)
        "
    >
        <div style="flex-direction: row; display: flex; align-items: center; padding-right: 5px">
            <mat-slide-toggle
                style="margin-right: 5px; z-index: 0; z-index: 2"
                color="primary"
                [ngClass]="{overridden: !!_transferFG.controls.override?.controls?.contribute_to_cap}"
                [formControl]="contributeToCapFC"
            >
                <span *ngIf="!contributeToCapFC.value" [class.bold]="contributeToCapFC.value">Contribute to CAP</span>
            </mat-slide-toggle>
            <mat-form-field *ngIf="contributeToCapFC.value" style="z-index: 2">
                <mat-label>Contribute To Cap</mat-label>
                <ng-container *ngIf="_transferFG.controls.override?.controls?.contribute_to_cap">
                    <mat-select
                        placeholder="Contribute To Cap"
                        [formControl]="_transferFG.controls.override.controls.contribute_to_cap"
                    >
                        <mat-option
                            [value]="contributeToCap"
                            *ngFor="
                                let contributeToCap of availableContributeToCapsOrigins[
                                    _transferFG.controls.origin.value
                                ]
                            "
                        >
                            {{ FINANCIAL_TRANSFER_ENTITY.contribute_to_cap_LABELS[contributeToCap] }}
                        </mat-option>
                        <!--                        <ng-container *ngIf="_transferFG.controls.origin_is_expense && _transferFG.controls.origin_is_expense.value && _transferFG.controls.origin">-->
                        <!--                            <mat-option [value]="contributeToCap"-->
                        <!--                                        *ngFor="let contributeToCap of availableExpenseCaps[_transferFG.controls.origin.value]">-->
                        <!--                                {{FINANCIAL_TRANSFER_ENTITY.contribute_to_cap_LABELS[contributeToCap]}}-->
                        <!--                            </mat-option>-->
                        <!--                        </ng-container>-->
                        <!--                        <ng-container *ngIf="_transferFG.controls.origin_is_expense && !_transferFG.controls.origin_is_expense.value && _transferFG.controls.origin">-->
                        <!--                            <mat-option [value]="contributeToCap"-->
                        <!--                                        *ngFor="let contributeToCap of availableIncomeCaps[_transferFG.controls.origin.value]">-->
                        <!--                                {{FINANCIAL_TRANSFER_ENTITY.contribute_to_cap_LABELS[contributeToCap]}}-->
                        <!--                            </mat-option>-->
                        <!--                        </ng-container>-->
                    </mat-select>
                </ng-container>
                <ng-container
                    *ngIf="
                        !_transferFG.controls.override?.controls?.contribute_to_cap &&
                        _transferFG.controls.contribute_to_cap
                    "
                >
                    <mat-select placeholder="Contribute To Cap" [formControl]="_transferFG.controls.contribute_to_cap">
                        <mat-option
                            [value]="contributeToCap"
                            *ngFor="
                                let contributeToCap of availableContributeToCapsOrigins[
                                    _transferFG.controls.origin.value
                                ]
                            "
                        >
                            {{ FINANCIAL_TRANSFER_ENTITY.contribute_to_cap_LABELS[contributeToCap] }}
                        </mat-option>
                        <!--                        <ng-container *ngIf="_transferFG.controls.origin_is_expense && _transferFG.controls.origin_is_expense.value && _transferFG.controls.origin">-->
                        <!--                            <mat-option [value]="contributeToCap"-->
                        <!--                                        *ngFor="let contributeToCap of availableExpenseCaps[_transferFG.controls.origin.value]">-->
                        <!--                                {{FINANCIAL_TRANSFER_ENTITY.contribute_to_cap_LABELS[contributeToCap]}}-->
                        <!--                            </mat-option>-->
                        <!--                        </ng-container>-->
                        <!--                        <ng-container *ngIf="_transferFG.controls.origin_is_expense && !_transferFG.controls.origin_is_expense.value && _transferFG.controls.origin">-->
                        <!--                            <mat-option [value]="contributeToCap"-->
                        <!--                                        *ngFor="let contributeToCap of availableIncomeCaps[_transferFG.controls.origin.value]">-->
                        <!--                                {{FINANCIAL_TRANSFER_ENTITY.contribute_to_cap_LABELS[contributeToCap]}}-->
                        <!--                            </mat-option>-->
                        <!--                        </ng-container>-->
                    </mat-select>
                </ng-container>
            </mat-form-field>
        </div>
    </div>

    <div
        class="disbursement-block apply-as-receiver-split"
        *ngIf="
            !contributeToCapFlag &&
            compensationExpenseEnabledFlag &&
            _transferFG.controls.origin?.value === FINANCIAL_TRANSFER_ENTITY.type_SET.compensation_expense
        "
    >
        <div>
            <mat-slide-toggle [formControl]="applyAsReceiverSplitFC" (change)="onApplyAsReceiverSplitChange()">
                <span>Apply as Receiver Compensation</span>
            </mat-slide-toggle>
        </div>
    </div>

    <div
        class="invoice-link-popup"
        *ngIf="
            _transferFG.controls.connected__invoice_fk_id &&
            _transferFG.controls.connected__invoice_fk_id.value &&
            _transferFG.controls.connected_invoice &&
            _transferFG.controls.connected_invoice.value &&
            canShowLinkToInvoice
        "
    >
        <ng-container
            *ngIf="_transferFG.controls.type?.value === FINANCIAL_TRANSFER_ENTITY.type_SET.associate_transfer"
        >
            Associated
        </ng-container>
        <ng-container *ngIf="_transferFG.controls.type?.value === FINANCIAL_TRANSFER_ENTITY.type_SET.deduct_transfer">
            Payment deducted from Commission for
        </ng-container>

        <a
            href="javascript:"
            (click)="openInvoiceDialog(_transferFG.controls.connected__invoice_fk_id?.value)"
            matTooltip="View Invoice"
            >{{ _transferFG.controls.connected_invoice?.value?.invoice_number }}
        </a>
        ({{ _transferFG.controls.connected_invoice?.value?.summary_status | fromSnakeCase }})
    </div>
    <div
        class="invoice-link-popup"
        *ngIf="
            _transferFG.controls.connected__bill_fk_id &&
            _transferFG.controls.connected__bill_fk_id.value &&
            _transferFG.controls.connected_bill &&
            _transferFG.controls.connected_bill.value &&
            canShowLinkToBill
        "
    >
        <ng-container
            *ngIf="_transferFG.controls.type?.value === FINANCIAL_TRANSFER_ENTITY.type_SET.associate_transfer"
        >
            Associated
        </ng-container>
        <a
            href="javascript:"
            (click)="openBillDialog(_transferFG.controls.connected__bill_fk_id?.value)"
            matTooltip="View Bill"
            >{{ _transferFG.controls.connected_bill?.value?.bill_number }}
        </a>
        ({{ _transferFG.controls.connected_bill?.value?.summary_status | fromSnakeCase }})
    </div>
</ng-container>
