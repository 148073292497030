// @ts-nocheck
// @todo: Need revisit TypeScript linting in this file. And need remove "ts-nocheck" from top of this file.
//
import {CybercoEntity} from '@cyberco-nodejs/cyberco-entity';
import {UntypedFormArray, UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import {DateRangeModel} from '../modules/account-info/compensation/models/date-range.model';
// import {FormHooks} from "@angular/forms/src/model";

declare type FormHooks = 'change' | 'blur' | 'submit';

export class GenericEntity extends CybercoEntity {
    // static createInstance<A extends GenericEntity>(c: new () => A)

    constructor() {
        super();

        /**
         *
         * @type {Date}
         */
        this.created_at = null;
        /**
         *
         * @type {Date}
         */
        this.updated_at = null;
        /**
         *
         * @type {Date}
         */
        this.deleted_at = null;
    }

    static key: boolean = false;

    static FABRIC<A = GenericEntity>(
        c: new () => A,
        __props = null,
        __build = null
    ): {
        hydrate: (
            input: object | A,
            output?: A,
            props?: {[prop: string]: string},
            transform?: {[id: string]: (val, fabric) => any}
        ) => A;
        dehydrate: (
            input: object | A,
            output?: object,
            props?: {[prop: string]: string},
            transform?: {[id: string]: (val, fabric) => any}
        ) => object;
    } {
        const classInstance = new c();
        if (__props === null) {
            __props =
                Object.getPrototypeOf(classInstance).constructor.helpers.props ||
                Object.getOwnPropertyNames(classInstance).reduce((acc, curr) => {
                    acc[curr] = curr;
                    return acc;
                }, {});
        }
        if (__build === null) {
            __build = Object.getPrototypeOf(classInstance).constructor.helpers.build || {};
        }
        return {
            hydrate: (input, output = classInstance, props = __props, transform = __build) => {
                if (input === null) {
                    return null;
                }
                return CybercoEntity.fabric.default('hydrate', input, output, props, transform);
            },
            dehydrate: (input, output = {}, props = __props, transform = __build) => {
                if (input === null) {
                    return null;
                }
                return CybercoEntity.fabric.default('dehydrate', input, output, props, transform);
            }
        };
    }

    /**
     * @deprecated
     */
    static get fabric(): {
        hydrate?: (
            input: object | GenericEntity,
            output: GenericEntity,
            props: {[prop: string]: string},
            transform: {[id: string]: (val, fabric) => any}
        ) => GenericEntity;
        dehydrate?: (
            input: object | GenericEntity,
            output: object,
            props: {[prop: string]: string},
            transform: {[id: string]: (val, fabric) => any}
        ) => object;
    } {
        const build = {};
        return {
            hydrate: (
                input: object | GenericEntity,
                output: GenericEntity = new this(),
                props: {[prop: string]: string} = {},
                transform: {[id: string]: (val, fabric) => any} = build
            ) => {
                if (input === null) {
                    return null;
                }
                return CybercoEntity.fabric.default('hydrate', input, output, props, transform);
            },
            dehydrate: (
                input: object | GenericEntity,
                output: object = {},
                props: {[prop: string]: string} = {},
                transform: {[id: string]: (val, fabric) => any} = build
            ) => {
                if (input === null) {
                    return null;
                }
                return CybercoEntity.fabric.default('dehydrate', input, output, props, transform);
            }
        };
    }
}

export type GenericControl<T> = T extends (infer U)[]
    ? GenericFormArray<U>
    : T extends object
      ? GenericFormGroup<T>
      : UntypedFormControl;

export class GenericFormGroup<M> extends UntypedFormGroup {
    public controls: {[key in keyof M]?: GenericControl<M[key]>};

    constructor(entity: M, updateOn: FormHooks | {[key in keyof M]?: FormHooks} = 'change') {
        const controls = {};
        if (typeof updateOn === 'object' && updateOn !== null) {
            Object.keys(entity).map((key) => {
                const __updateOn: FormHooks = updateOn[key] ? updateOn[key] : 'blur';
                if (Array.isArray(entity[key])) {
                    if (entity[key].length > 0 && typeof entity[key][0] !== 'object') {
                        controls[key] = new UntypedFormControl(entity[key], {
                            updateOn: __updateOn
                        });
                    } else {
                        controls[key] = new GenericFormArray(
                            entity[key].map((item) => new GenericFormGroup(item, updateOn))
                        ).setUpdateOn(__updateOn);
                    }
                } else if (typeof entity[key] === 'object' && entity[key] !== null) {
                    controls[key] = new GenericFormGroup(entity[key], updateOn);
                } else {
                    controls[key] = new UntypedFormControl(entity[key], {
                        updateOn: __updateOn
                    });
                    // controls[key] = new FormControl(entity[key]);
                }
            });
        } else {
            Object.keys(entity).map((key) => {
                const __updateOn: FormHooks = <FormHooks>updateOn;
                if (Array.isArray(entity[key])) {
                    if (entity[key].length > 0 && typeof entity[key][0] !== 'object') {
                        controls[key] = new UntypedFormControl(entity[key], {
                            updateOn: __updateOn
                        });
                    } else {
                        controls[key] = new GenericFormArray(
                            entity[key].map((item) => new GenericFormGroup(item, updateOn))
                        ).setUpdateOn(__updateOn);
                    }
                } else if (typeof entity[key] === 'object' && entity[key] !== null) {
                    controls[key] = new GenericFormGroup(entity[key], updateOn);
                } else {
                    controls[key] = new UntypedFormControl(entity[key], {
                        updateOn: __updateOn
                    });
                    // controls[key] = new FormControl(entity[key]);
                }
            });
        }
        super(controls);
    }
    getRawValue(c: {new (): M} = null): M {
        if (c !== null) {
            return GenericEntity.FABRIC<M>(c).hydrate(super.getRawValue());
        }

        return <M>super.getRawValue();
    }

    patchValue(
        value: M,
        options: {
            onlySelf?: boolean;
            emitEvent?: boolean;
        } = {}
    ) {
        if (value !== null) {
            super.patchValue(value, options);
        }
    }

    get invalid() {
        return Object.keys(this.controls).reduce((invalid, controlKey) => {
            if (this.controls[controlKey].invalid) {
                // console.info(`INVALID ${controlKey}`, this.controls[controlKey]);
                return true;
            }
            if (invalid) {
                return true;
            }
            return false;
        }, false);
    }

    get valid() {
        return Object.keys(this.controls).reduce((valid, controlKey) => {
            if (!this.controls[controlKey].valid) {
                // console.info(`INVALID ${controlKey}`, this.controls[controlKey]);
                return false;
            }
            if (!valid) {
                return false;
            }
            return true;
        }, true);
    }

    static getControlAsFormControl<M>(
        controls: {[key in keyof M]?: GenericControl<M[key]>},
        controlName: keyof M
    ): UntypedFormControl {
        return controls[controlName] as UntypedFormControl;
    }
}

export class GenericFormArray<M> extends UntypedFormArray {
    public controls: GenericFormGroup<M>[];
    private __updateOn: FormHooks = 'blur';

    setUpdateOn(updateOn: FormHooks): this {
        this.__updateOn = updateOn;
        return this;
    }

    patchValue(
        value: M[],
        options: {
            onlySelf?: boolean;
            emitEvent?: boolean;
        } = {}
    ) {
        if (!Array.isArray(value)) {
            return;
        }

        super.patchValue(value, options);
        if (this.controls.length < value.length) {
            for (let i = this.controls.length; i < value.length; i++) {
                let newItem = null;
                if (value[i] instanceof Object) {
                    newItem = new GenericFormGroup(value[i], this.__updateOn);
                } else {
                    newItem = new UntypedFormControl(value[i], {
                        updateOn: this.__updateOn
                    });
                }
                newItem.patchValue(value[i], options);
                newItem.valueChanges.subscribe((newValue) => {
                    this.updateValueAndValidity({onlySelf: false, emitEvent: true});
                });
                this.controls.push(newItem);
            }
        } else if (this.controls.length > value.length) {
            this.controls.splice(value.length, this.controls.length - value.length);
        }
    }

    getRawValue(): M[] {
        return <M[]>super.getRawValue();
    }
    getEnabledValues(): M[] {
        return this.controls.filter((control) => !control.disabled).map((control) => control.getRawValue());
    }

    get invalid() {
        return this.controls.reduce((invalid, control, idx) => {
            if (control.invalid) {
                // console.info(`INVALID ${idx}`, control);
                return true;
            }
            if (invalid) {
                return true;
            }
            return false;
        }, false);
    }

    get valid() {
        return this.controls.reduce((valid, control, idx) => {
            if (!control.valid) {
                // console.info(`INVALID ${idx}`, control);
                return false;
            }
            if (!valid) {
                return false;
            }
            return true;
        }, true);
    }
}
