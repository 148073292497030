import {Component, Input} from '@angular/core';
import {UserNotificationItemBaseComponent} from './user-notification-item-base.component';
import {UserNotification} from 'app/models/user-notification';
import {Router} from '@angular/router';

@Component({
    selector: 'app-user-notification-item-import-json',
    styles: [
        `
            .message {
                /*padding: 15px 25px;*/
                margin-bottom: 0;
                border-radius: 0;
                /*min-height: 36px;*/
            }
            .actions {
                text-align: right;
            }
            .notification {
                /*padding: 15px 5px;*/
                width: 100%;
                /*border-bottom: 1px solid White;*/
            }

            .icon {
                font-size: 14px;
            }
        `
    ],
    template: `
        <div class="notification">
            <div class="message" *ngIf="notification">
                <i class="material-icons icon">save</i> {{ notification.message }}
            </div>
            <div class="actions">
                <button mat-button (click)="doDismiss()"><i class="material-icons">clear</i>Dismiss</button>
            </div>
        </div>
    `
})
export class UserNotificationItemImportJsonComponent extends UserNotificationItemBaseComponent {
    @Input() notification: UserNotification | null = null;

    doDismiss() {
        super.doDismiss();
    }

    constructor(private router: Router) {
        super();
    }
}
