import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ServiceRequester} from '../../../services/service.requester';
import {IRole} from '../../../../typings';

@Injectable()
export class RolesService {
    url = '/roles';

    constructor(public requester: ServiceRequester) {}

    getSystemRoles(): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/system`, 'GET', 'zipi');
    }

    /** Deprecated */
    getCompanyRoles(): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/company`, 'GET', 'zipi');
    }

    /** Use source */
    getAvailableRoles(): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/available`, 'GET', 'zipi');
    }

    /**
     * Get company base permissions
     * Use source
     */
    getAvailableCompanyPermissions(): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/company-permissions`, 'GET', 'zipi');
    }

    createCompanyRole(role: any): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/`, 'POST', 'zipi', role);
    }

    updateCompanyRole(roleId: number, role: any): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/${roleId}`, 'PUT', 'zipi', role);
    }

    deleteCompanyRole(roleId: number, force: boolean): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/${roleId}${force ? '?force=true' : ''}`, 'DELETE', 'zipi');
    }
}
