import {Component, OnDestroy} from '@angular/core';
import {AddonsSource} from './addons.source';
import {AddonsApi} from './addons.api';
import {AddonInstanceModel} from './addons.models';
import {AddonDeactivateConfirmationDialogComponent} from './addon-deactivate-confirmation.dialog';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {FeatureFlagsService} from '../../feature-flags/feature-flags.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-addons',
    template: `
        <div class="container">
            <h3>Apps</h3>
            <mat-tab-group (selectedIndexChange)="activeTabChanged($event)">
                <mat-tab [label]="'Company Addons'">
                    <ng-container *ngFor="let addonInstance of allAddonsInstances">
                        <mat-card
                            *ngIf="
                                addonInstance.addon?.settings?.enabled_personal_instances &&
                                (!addonInstance.addon.settings.is_feature_flag_needed ||
                                    featureFlags[addonInstance.addon.settings.feature_flag_id])
                            "
                        >
                            <mat-card-content class="row">
                                <div class="col flex-row justify-content-between">
                                    <div>
                                        <img
                                            *ngIf="addonInstance.logo"
                                            style="width:180px;"
                                            [src]="addonInstance.logo"
                                        />
                                    </div>
                                    <div class="d-flex flex-column">
                                        <button
                                            *ngIf="
                                                !addonInstance.activated &&
                                                addonInstance.addon?.settings?.enabled_personal_instances
                                            "
                                            mat-button
                                            class="mat-primary"
                                            (click)="setupAddon(addonInstance)"
                                        >
                                            Connect
                                        </button>
                                        <button
                                            *ngIf="addonInstance.activated"
                                            mat-button
                                            (click)="deactivateAddon(addonInstance)"
                                        >
                                            Deactivate
                                        </button>
                                        <button
                                            *ngIf="
                                                addonInstance.activated &&
                                                addonInstance.addon?.settings?.enabled_personal_settings
                                            "
                                            mat-button
                                            class="mat-primary"
                                            (click)="settingsAddon(addonInstance)"
                                        >
                                            Settings
                                        </button>
                                    </div>
                                </div>
                                <div class="col" style="flex: auto;">
                                    <div class="title">{{ addonInstance.title }}</div>
                                    <div class="description" [innerHTML]="addonInstance.description"></div>
                                    <!--show different text for 'activated' or 'connected' instances-->
                                    <div
                                        style="color: red;"
                                        *ngIf="
                                            addonInstance.addon?.settings?.enabled_personal_instances &&
                                            hasDefaultAddonDescription[addonInstance.addon.slug]
                                        "
                                    >
                                        {{
                                            addonInstance.activated ? 'Not Connected (click Settings)' : 'Not Connected'
                                        }}
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </ng-container>
                </mat-tab>
                <mat-tab [label]="'User Addons'">
                    <ng-container *ngFor="let userAddonInstance of allUserAddonsInstances">
                        <!--TODO: remove second part of *ngIf after marketplaceDSAddonEnabledFlag not need -->
                        <!--<mat-card *ngIf="userAddonInstance.addon?.settings?.enabled_user_instances-->
                        <!-- && ((userAddonInstance.addon!.slug !== 'datastudio_connector') || marketplaceDSAddonEnabledFlag)">-->
                        <mat-card
                            *ngIf="
                                userAddonInstance.addon?.settings?.enabled_user_instances &&
                                (!userAddonInstance.addon.settings.is_feature_flag_needed ||
                                    featureFlags[userAddonInstance.addon.settings.feature_flag_id])
                            "
                        >
                            <mat-card-content class="row">
                                <div class="col flex-row justify-content-between">
                                    <div>
                                        <img style="width:180px;" [src]="userAddonInstance.logo" />
                                    </div>
                                    <div class="d-flex flex-column">
                                        <button
                                            *ngIf="
                                                !userAddonInstance.activated &&
                                                userAddonInstance.addon?.settings?.enabled_user_instances
                                            "
                                            mat-button
                                            class="mat-primary"
                                            (click)="setupUserAddon(userAddonInstance)"
                                        >
                                            Activate
                                        </button>
                                        <button
                                            *ngIf="userAddonInstance.activated"
                                            mat-button
                                            (click)="deactivateUserAddon(userAddonInstance)"
                                        >
                                            Deactivate
                                        </button>
                                        <button
                                            *ngIf="
                                                userAddonInstance.activated &&
                                                userAddonInstance.addon?.settings?.enabled_personal_settings
                                            "
                                            mat-button
                                            class="mat-primary"
                                            (click)="settingsAddon(userAddonInstance)"
                                        >
                                            Settings
                                        </button>
                                    </div>
                                </div>
                                <div class="col" style="flex: auto;">
                                    <div class="title">{{ userAddonInstance.title }}</div>
                                    <div class="description" [innerHTML]="userAddonInstance.description"></div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </ng-container>
                </mat-tab>
            </mat-tab-group>
        </div>
    `,
    styles: [
        `
            .col {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }
            .row {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
            }
            .title {
                font-size: 20px;
                font-weight: bold;
            }
            .description {
                margin-top: 8px;
                font-size: 14px;
                font-weight: normal;
            }
            .logo {
                margin-right: 8px;
            }
            mat-card-actions {
                margin-left: 0px;
            }
        `
    ]
})
export class AddonsComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    protected activeTabIndex = 0;

    public showForm: boolean = false;

    public allAddonsInstances: AddonInstanceModel[] = [];
    public activatedAddonsInstances: AddonInstanceModel[] = [];

    public allUserAddonsInstances: AddonInstanceModel[] = [];
    public fetchUserAddonsPending = false;
    public setupUserAddonPending = false;
    public userAddonsLoaded = false;

    // public marketplaceDSAddonEnabledFlag = false;

    public hasDefaultAddonDescription: any = {};
    public featureFlags: {[key: string]: boolean} = {};

    constructor(
        protected featureFlagsService: FeatureFlagsService,
        protected addonsSource: AddonsSource,
        protected addonsApi: AddonsApi,
        protected dialog: MatDialog,
        protected router: Router
    ) {
        this.addonsSource.addonsChangeEvent.pipe(takeUntil(this.unsubscribe)).subscribe((instances) => {
            this.allAddonsInstances = instances.map((instance) => {
                const preset = instance.addon ? instance.addon.preset : {};
                const res: AddonInstanceModel = Object.assign(new AddonInstanceModel(), preset);
                res.addon = instance.addon;
                res.id = instance.id;
                res.activated = instance.activated;
                if (instance.title) {
                    res.title = instance.title;
                }
                if (instance.description) {
                    res.description = instance.description;
                }
                if (instance.logo) {
                    res.logo = instance.logo;
                }

                // detect if personal addon_instance 'activated', not 'connected'
                if (res.addon && res.addon.slug) {
                    this.hasDefaultAddonDescription[res.addon.slug] = !instance.description;
                }

                return res;
            });
            this.activatedAddonsInstances = this.allAddonsInstances.filter((instance) => instance.activated);
        });

        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                if (allFlags) {
                    Object.keys(allFlags).forEach((flagId) => {
                        this.featureFlags[flagId] = allFlags[flagId].enabled;
                    });
                }
                // this.marketplaceDSAddonEnabledFlag = this.featureFlagsService.isFeatureEnabled('marketplace_ds_connector');
            });
    }

    activeTabChanged(activeTabIndex: number) {
        this.activeTabIndex = activeTabIndex;

        if (activeTabIndex === 1 && !this.userAddonsLoaded) {
            this.fetchUserAddons();
        }
    }

    async setupUserAddon(instance: AddonInstanceModel) {
        if (this.setupUserAddonPending || !instance.addon) {
            return;
        }

        this.setupUserAddonPending = true;
        const res = await this.addonsApi.setupUserAddon(instance.addon);
        if (!res) {
            return;
        }
        this.allUserAddonsInstances = this.allUserAddonsInstances.map((ai) => {
            if (ai && ai.addon && ai.addon.id === res.addon.id) {
                return this.__hadleAddonInstance(res);
            }
            return ai;
        });
        this.setupUserAddonPending = false;
        // this.addonsApi.loadUserAddons();
    }

    setupAddon(instance: AddonInstanceModel) {
        if (!instance.addon) {
            return;
        }
        this.addonsApi.setupAddon(instance.addon).then(() => {
            // UPD: all addons with enabled_personal_instances should open in new tab
            if (!instance.addon) {
                return;
            }

            // update addons UI
            this.addonsApi.loadAddons();

            let path = '/integration/connect';
            if (instance.addon.settings.urls.redirect_profile_activated_addon_url) {
                path = instance.addon.settings.urls.redirect_profile_activated_addon_url;
            }
            // open in a new tab
            window.open(instance.addon.addon_url + path, '_blank');

            // } else {
            //     if (!instance.addon) { return; }
            //     window.location.href = instance.addon.addon_url + '/integration/connect';
            // }
        });
    }

    settingsAddon(instance: AddonInstanceModel) {
        if (!instance.addon) {
            return;
        }

        this.addonsApi.setupAddon(instance.addon).then(() => {
            if (!instance.addon) {
                return;
            }

            if (instance.addon.settings.urls.personal_settings_url) {
                this.router.navigate([instance.addon.settings.urls.personal_settings_url]);
                return;
            }
            // window.location.href = instance.addon.addon_url + '/integration/settings';

            // open in a new tab
            window.open(instance.addon.addon_url + '/integration/settings', '_blank');
        });
    }

    deactivateAddon(instance: AddonInstanceModel) {
        const dialogRef = this.dialog.open(AddonDeactivateConfirmationDialogComponent);
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((reallyDeactivate) => {
                if (reallyDeactivate) {
                    if (!instance.addon) {
                        return;
                    }
                    this.addonsApi.deactivateAddon(instance.addon).then(() => {
                        this.addonsApi.loadAddons();
                    });
                } else {
                    // dialog was just closed, no need to save anything
                    return;
                }
            });
    }

    deactivateUserAddon(instance: AddonInstanceModel) {
        const dialogRef = this.dialog.open(AddonDeactivateConfirmationDialogComponent);
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((reallyDeactivate) => {
                if (reallyDeactivate) {
                    if (!instance.id || !instance.addon || !instance.addon.id) {
                        console.error('Custom error: ID of addon instance is null');
                        return;
                    }
                    this.addonsApi.deactivateUserAddonInstance(instance.addon.id, instance.id).then((res) => {
                        if (res) {
                            // update UI
                            this.allUserAddonsInstances = this.allUserAddonsInstances.map((el) => {
                                if (el.id === instance.id) {
                                    return {...el, activated: false};
                                }
                                return el;
                            });
                        }
                    });
                }
            });
    }

    __hadleAddonInstance(instance: AddonInstanceModel) {
        const preset = instance.addon ? instance.addon.preset : {};
        const res: AddonInstanceModel = Object.assign(new AddonInstanceModel(), preset);
        res.addon = instance.addon;
        res.id = instance.id;
        res.activated = instance.activated;
        if (instance.title) {
            res.title = instance.title;
        }
        if (instance.description) {
            res.description = instance.description;
        }
        if (instance.logo) {
            res.logo = instance.logo;
        }

        return res;
    }

    fetchUserAddons() {
        if (this.fetchUserAddonsPending) {
            return;
        }
        this.fetchUserAddonsPending = true;
        this.allUserAddonsInstances = [];

        this.addonsApi
            .loadUserAddons()
            .then((res) => {
                this.fetchUserAddonsPending = false;
                this.allUserAddonsInstances = res.map(this.__hadleAddonInstance);
                this.userAddonsLoaded = true;
            })
            .catch((err) => {
                this.fetchUserAddonsPending = false;
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
