import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {takeUntil} from 'rxjs/operators';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {IScrollData} from 'app/models/scroll-data';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {InvoicesService} from '@app/services/api/finance/invoices.service';
import {Sort} from '@angular/material/sort';
import {GenericFormArray} from '@app/entites/generic.entity';
import {ChipNode} from '@app/modules/account-info/compensation/models/chip-node';
import {SelectionModel} from '@angular/cdk/collections';
import {NotificationsService} from 'angular2-notifications';
import {IInvoice} from 'typings';

export interface AgentStatementData {
    contact_id: number;
    contact_name: string;
    last_sent: Date;
    prev_invoice_count: number;
    prev_balance_due: number;
    invoice_count: number;
    total_amount: number;
    balance_due: number;
    payments_amount: number;
    payment_received_count: number;
    credit_notes: null;
    credit_notes_count: number;
}

@Component({
    selector: 'app-invoice-statements-page',
    templateUrl: 'statement-page.component.html',
    styleUrls: ['../../../../../../assets/custom-paginator.scss']
})
export class StatementPageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;

    statusFilter: UntypedFormControl = new UntypedFormControl([]);
    range: UntypedFormGroup = this.fb.group({
        start_date: [null, []],
        end_date: [null, []]
    });
    scrollData: IScrollData = {
        sort_column: 'credit_note_date',
        sort_direction: 'desc',
        filter: null,
        offset: 0,
        limit: 50,
        total: 1
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;
    initialDateFrom: Date = new Date();
    initialDateTo: Date = new Date();
    dataSource: MatTableDataSource<any>;
    contactFilterCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    selection = new SelectionModel<number>(true, []);
    invoicesByContactId: Record<number, IInvoice[]> = {};

    constructor(
        private invoicesService: InvoicesService,
        public fb: UntypedFormBuilder,
        public notificationService: NotificationsService
    ) {
        this.dataSource = new MatTableDataSource<any>([]);
    }

    ngOnInit() {
        // Set 'today' to the same day of the previous month
        let today = new Date();
        today.setMonth(today.getMonth() - 1);

        // Get the first day of the month
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        // Get the last day of the month
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        this.initialDateFrom = firstDay;
        this.initialDateTo = lastDay;
        this.setRange({
            dateFrom: this.getFormattedDate(this.initialDateFrom).toString(),
            dateTo: this.getFormattedDate(this.initialDateTo).toString()
        });

        this.getInvoiceStatements();

        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.nextBatch();
            });
        }

        this.contactFilterCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            this.scrollData.group_id = '';
            this.scrollData.contact_id = '';

            if (values?.length) {
                const type = values[0].type;
                const targetId = values[0].target_id || '';
                if (type === 'company_group') {
                    this.scrollData.group_id = targetId;
                } else if (type === 'contact') {
                    this.scrollData.contact_id = targetId;
                }
            }

            this.nextBatch();
        });
    }

    setRange(range: {dateFrom: string; dateTo: string}) {
        if (range.dateFrom) this.range.controls.start_date.patchValue(range.dateFrom);
        if (range.dateTo) this.range.controls.end_date.patchValue(range.dateTo);

        // All the cached data is for the previous date range, making it invalid
        this.invoicesByContactId = {};

        this.nextBatch();
    }

    getFormattedDate(date: Date): number {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = date.getDate().toString().padStart(2, '0');

        return Number(`${year}${month}${day}`);
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        this.nextBatch();
    }

    nextBatch() {
        // cancel previous batch requests
        this.unsubscribeBatch.next();

        this.getInvoiceStatements();
    }

    getInvoiceStatements() {
        const contactIds = this.scrollData.contact_id ? [Number(this.scrollData.contact_id)] : [];
        const groupIds = this.scrollData.group_id ? [Number(this.scrollData.group_id)] : [];

        this.invoicesService
            .getInvoiceStatements<AgentStatementData>(
                Number(this.range.controls.start_date.value),
                Number(this.range.controls.end_date.value),
                this.scrollData.sort_column,
                this.scrollData.sort_direction,
                this.scrollData.limit,
                this.scrollData.offset,
                contactIds,
                groupIds
            )
            .pipe(takeUntil(this.unsubscribeBatch))
            .subscribe((data) => {
                this.dataSource.data = data.result;
                this.scrollData.total = data._meta.total;
            });
    }

    async sendSummaries(): Promise<void> {
        const result = await this.invoicesService.sendInvoiceStatements({
            contactIds: this.selection.selected,
            startDate: Number(this.range.controls.start_date.value),
            endDate: Number(this.range.controls.end_date.value)
        });

        if (result) {
            this.notificationService.success(
                `Invoice ${this.selection.selected.length > 1 ? 'statements' : 'statement'} sent!`
            );
            this.selection.clear();
            this.nextBatch();
        } else {
            this.notificationService.error('Failed to send invoice statements');
        }
    }

    activeContactId: number | null = null;
    activeContactName: string | null = null;
    isSidenavOpen = false;
    async handleClickRow(row: AgentStatementData) {
        const groupIds = this.scrollData.group_id ? [Number(this.scrollData.group_id)] : [];

        if (!this.invoicesByContactId[row.contact_id]) {
            const data = await this.invoicesService.getInvoicesForContact({
                contactId: row.contact_id,
                start: Number(this.range.controls.start_date.value),
                end: Number(this.range.controls.end_date.value),
                groupIds
            });
            this.invoicesByContactId[row.contact_id] = data;
        }

        this.activeContactId = row.contact_id;
        this.activeContactName = row.contact_name;
        this.isSidenavOpen = true;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
    }
}
