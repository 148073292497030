import {Routes, RouterModule} from '@angular/router';
import {ContactCreateComponent} from './contact-create';
import {UserLayoutComponent} from '../../layouts/user-layout.component';
import {AuthBootstrapService} from '../../services/resolvers/auth-bootstrap.service';
import {ContactsRootComponent} from './contacts-root/contacts-root.component';
import {ContactEditPageComponent} from './contact-edit-page/contact-edit-page.component';
import {ContactCardComponent} from './contact-card';
import {AccessGuardService} from '../../services/resolvers/access-guard.service';

export const routes: Routes = [
    {
        path: 'contacts',
        component: UserLayoutComponent,
        resolve: {authBootstrap: AuthBootstrapService},
        canActivate: [AccessGuardService],
        data: {
            title: 'Contacts',
            access: {
                anyRule: [{contacts__access: true}, {directory__access: true}]
            }
        },
        children: [
            {
                canActivate: [AccessGuardService],
                path: 'create',
                component: ContactCreateComponent,
                data: {
                    access: {
                        anyRule: [{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]
                    }
                }
            },
            {
                path: '',
                component: ContactsRootComponent,
                children: [
                    {path: '', pathMatch: 'full', component: ContactsRootComponent},
                    {
                        canActivate: [AccessGuardService],
                        path: ':id',
                        component: ContactCardComponent,
                        data: {
                            access: {
                                anyRule: [{contacts__view_limited_contacts: true}, {contacts__view_any_contacts: true}]
                            }
                        }
                    }
                ]
            },
            {
                path: ':id',
                children: [
                    {
                        canActivate: [AccessGuardService],
                        path: '',
                        component: ContactCardComponent,
                        data: {
                            access: {
                                anyRule: [{contacts__view_limited_contacts: true}, {contacts__view_any_contacts: true}]
                            }
                        }
                    },
                    {
                        canActivate: [AccessGuardService],
                        path: 'edit',
                        component: ContactEditPageComponent,
                        data: {
                            access: {
                                anyRule: [
                                    {contacts__manage_limited_contacts: true},
                                    {contacts__manage_any_contacts: true}
                                ]
                            }
                        }
                    }
                ]
            }
        ]
    }
];

export const routing = RouterModule.forChild(routes);
