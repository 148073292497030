import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {RbacModule} from '../../rbac/rbac.module';
import {SharedModule} from '../../shared/shared.module';
import {GroupsApi} from './groups.service';
import {ShareDialogComponent} from './common/share-dialog.component';
import {IndexComponent} from './pages/index.component';
import {Tab1AllComponent} from './tabs/tab1-all.component';
import {NewComponent} from './common/new.component';
import {EditComponent} from './common/edit/edit.component';
import {GroupsListItemAllComponent} from './common/all-list-item.component';
import {SettingsDialogComponent} from './common/settings-dialog.component';
import {AccountInfoToolbarModule} from '../account-info-toolbar/account-info-toolbar.module';

@NgModule({
    declarations: [
        ShareDialogComponent,
        SettingsDialogComponent,
        Tab1AllComponent,
        NewComponent,
        EditComponent,
        GroupsListItemAllComponent,
        // pages
        IndexComponent
    ],
    imports: [
        RbacModule,
        SharedModule,
        RouterModule,
        AccountInfoToolbarModule
        // routing,
    ],
    providers: [GroupsApi],
    exports: [BrowserModule],
    bootstrap: [IndexComponent]
})
export class GroupsModule {}
