import {GoalEntity} from './goal.entity';
import {Profile} from '../../../models/profile';
import {GenericEntity} from '../../../entites/generic.entity';
import {GoalPeriodEntity} from './goal-period.entity';
import {GoalValueEntity} from './goal-value.entity';
import * as moment from 'moment';
import {MomentInput} from 'moment-timezone';

export class GoalInstanceEntity extends GenericEntity {
    id: number | undefined = undefined;
    profile: Profile | null = null;
    goal: GoalEntity | null = null;
    periods: GoalPeriodEntity[] = [];
    values: GoalValueEntity[] = [];

    constructor() {
        super();
    }

    static get helpers() {
        return Object.assign(
            {
                props: Object.getOwnPropertyNames(new this()).reduce(
                    (acc, curr) => {
                        acc[curr] = curr;
                        return acc;
                    },
                    {} as {[key: string]: any}
                ),
                build: {
                    goal: (val: GoalEntity, fabric: any) => GoalEntity.fabric[fabric](val),
                    periods: (val: GoalPeriodEntity[], fabric: any) =>
                        // @ts-ignore: cannot define type 'fabric' value
                        val.map((item: GoalPeriodEntity) => GoalPeriodEntity.fabric[fabric](item)),
                    profile: (val: Profile, fabric = 'object.assign') => Object.assign(new Profile(), val)
                }
            },
            GenericEntity.helpers
        );
    }

    static get fabric() {
        return Object.assign(GenericEntity.fabric, {
            hydrate: (
                input: object,
                output: GoalInstanceEntity = new this(),
                props: {[prop: string]: string} = this.helpers.props,
                transform: {[id: string]: (val: any, fabric: any) => any} = this.helpers.build
            ): GoalInstanceEntity => {
                // @ts-ignore: might be null if input is null
                return <GoalInstanceEntity>GenericEntity.fabric.hydrate(input, output, props, transform);
            },
            dehydrate: (
                input: object | GoalInstanceEntity,
                output: object = {},
                props: {[prop: string]: string} = this.helpers.props,
                transform: {[id: string]: (val: any, fabric: any) => any} = this.helpers.build
            ): object => {
                // @ts-ignore: might be null if input is null
                return <object>GenericEntity.fabric.dehydrate(input, output, props, transform);
            }
        });
    }

    getValueForDate(date: Date): GoalValueEntity | undefined {
        const date_key = date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate();

        return this.values.find((el: GoalValueEntity) => {
            return el.date_key === date_key;
        });
    }

    addValues(values: GoalValueEntity[]): GoalInstanceEntity {
        this.values = this.values.concat(values);

        const assoc = this.values.reduceRight(
            (acc, value) => {
                acc[moment(value.date as MomentInput).format('MMM DD YYYY')] = value;
                return acc;
            },
            {} as {[key: string]: GoalValueEntity}
        );

        this.values = Object.keys(assoc).map((key) => assoc[key]);
        return this;
    }

    setValues(values: GoalValueEntity[]): GoalInstanceEntity {
        this.values = values;
        return this;
    }

    setGoal(goal: GoalEntity): GoalInstanceEntity {
        this.goal = goal;
        return this;
    }
}
