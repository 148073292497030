import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ContactType} from '../../../../models/contact-type';
import {ContactTypeService} from '../../../../services/api/contact-type.service';
import {IContactTypeMetatype} from '@cyberco-nodejs/zipi-typings/contacts.typings';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NotificationsService} from 'angular2-notifications';
import {Profile} from 'app/models/profile';
import {MatOptionSelectionChange} from '@angular/material/core';

@Component({
    selector: 'app-contact-type-creation-dialog',
    template: `
        <div>
            <mat-dialog-content>
                <div class="d-flex flex-row align-items-baseline">
                    <h1 mat-dialog-title style="margin-right: 15px">Create/Edit Category</h1>
                    <mat-form-field>
                        <mat-select
                            [(ngModel)]="currentContactType"
                            (selectionChange)="selectedContactTypeChange($event.value)"
                            placeholder="Select Category"
                        >
                            <mat-option class="text-muted font-italic" [value]="newContactType">Create New</mat-option>
                            <!--<mat-option *ngFor="let contactType of contactTypes" [value]="contactType" [disabled]="isSystemContactType(contactType.id)">-->
                            <mat-option *ngFor="let contactType of contactTypes" [value]="contactType">
                                {{ contactType.title }} ({{ contactType.metatype }})
                            </mat-option>
                            <!--<mat-optgroup *ngFor="let metatype of contactTypes" [label]="metatype.metatype">-->
                            <!--    <mat-option *ngFor="let contactType of metatype.items" [value]="contactType" [disabled]="isSystemContactType(contactType.id)">-->
                            <!--        {{contactType.title}}-->
                            <!--    </mat-option>-->
                            <!--</mat-optgroup>-->
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="d-flex flex-column">
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field ngDefaultControl class="w-100">
                                <input [(ngModel)]="contactTypeTitle" matInput type="text" placeholder="Title" />
                            </mat-form-field>
                        </div>

                        <div class="col-6">
                            <mat-form-field ngDefaultControl class="w-100">
                                <mat-label>Type</mat-label>
                                <mat-select [(ngModel)]="contactTypeMetatype">
                                    <mat-option
                                        *ngFor="let metatype of contactType.provideMetatypes"
                                        [value]="metatype"
                                    >
                                        {{ contactType.metatype_LABELS[metatype] }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <!-- no field in database for isDelegateContacts -->
                        <!-- it turns ON if delegatedToCompaniesIds is not empty -->
                        <div class="col-12 mb-3">
                            <mat-slide-toggle labelPosition="before" [(ngModel)]="isDelegateContacts"
                                >Delegate Contacts</mat-slide-toggle
                            >
                        </div>

                        <div *ngIf="isDelegateContacts" class="col-12">
                            <mat-form-field required ngDefaultControl class="w-100">
                                <mat-label>Delegate to which company?</mat-label>
                                <mat-select multiple [(ngModel)]="delegatedToCompaniesIds">
                                    <mat-option *ngFor="let profile of profiles" [value]="profile.company!.id">
                                        {{ profile.company!.title }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-dialog-content>
            <mat-dialog-actions class="text-right">
                <div class="d-flex justify-content-between w-100 py-2">
                    <button
                        type="button"
                        color="warn"
                        mat-raised-button
                        tabindex="-1"
                        (click)="deleteContactType()"
                        [disabled]="!isEditMode(currentContactType)"
                    >
                        Delete
                    </button>

                    <div>
                        <button type="button" mat-raised-button tabindex="-1" (click)="dialogRef.close()">
                            Cancel
                        </button>
                        <button
                            type="button"
                            mat-raised-button
                            color="primary"
                            tabindex="1"
                            (click)="saveContactType()"
                        >
                            {{ isEditMode(currentContactType) ? 'Update' : 'Create' }}
                        </button>
                    </div>
                </div>
            </mat-dialog-actions>
        </div>
    `
})
export class ContactTypeCreationDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    contactType = ContactType;
    contactTypes: ContactType[] = [];
    profiles: Profile[] = [];

    newContactType: ContactType = new ContactType();
    currentContactType: ContactType = new ContactType();
    contactTypeTitle: string | null = '';
    contactTypeMetatype: IContactTypeMetatype | undefined | null;
    isDelegateContacts = false;
    delegatedToCompaniesIds: Array<number> = [];

    constructor(
        private ntfs: NotificationsService,
        public dialogRef: MatDialogRef<ContactTypeCreationDialogComponent>,
        private contactTypeService: ContactTypeService,
        @Inject(MAT_DIALOG_DATA) public data: {contactTypes: ContactType[]; profiles: Profile[]}
    ) {}

    selectedContactTypeChange(value: MatOptionSelectionChange) {
        if (value) {
            this.contactTypeTitle = this.currentContactType.title;
            this.contactTypeMetatype = this.currentContactType.metatype;

            this.isDelegateContacts = !!(
                this.currentContactType.delegated_to_companies_ids &&
                this.currentContactType.delegated_to_companies_ids.length > 0
            );
            this.delegatedToCompaniesIds = this.currentContactType.delegated_to_companies_ids || [];
        } else {
            this.contactTypeTitle = null;
            this.contactTypeMetatype = null;

            this.isDelegateContacts = false;
            this.delegatedToCompaniesIds = [];
        }
    }

    saveContactType() {
        if (this.isDelegateContacts && this.delegatedToCompaniesIds.length <= 0) {
            return;
        }
        if (!this.contactTypeTitle || this.contactTypeTitle.trim().length < 3 || !this.contactTypeMetatype) {
            return;
        }

        this.currentContactType.metatype = this.contactTypeMetatype;
        this.currentContactType.title = this.contactTypeTitle.trim();

        if (this.isDelegateContacts) {
            this.currentContactType.delegated_to_companies_ids = this.delegatedToCompaniesIds;
        } else {
            this.currentContactType.delegated_to_companies_ids = [];
        }

        // update
        if (this.currentContactType.id) {
            this.contactTypeService
                .updateContactType(this.currentContactType.id, this.currentContactType)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((success) => {
                    if (success) {
                        this.dialogRef.close({contactType: this.currentContactType, action: 'PUT'});
                    }
                }, this._handleError.bind(this));
        } else {
            // create
            this.contactTypeService
                .createContactType(this.currentContactType)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((createdContactType) => {
                    // console.info(createdContactType, 'createdContactType');
                    if (createdContactType) {
                        this.dialogRef.close({contactType: createdContactType, action: 'POST'});
                    }
                }, this._handleError.bind(this));
        }
    }

    _handleError(err: string) {
        this.dialogRef.close();
        this.ntfs.error(err || 'Something went wrong. Please try later.');
    }

    deleteContactType() {
        if (!this.currentContactType.id) {
            return;
        }

        this.contactTypeService
            .deleteContactType(this.currentContactType.id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.dialogRef.close({contactType: this.currentContactType, action: 'DELETE'});
            }, this._handleError.bind(this));
    }

    isEditMode(currentContactType: ContactType) {
        return !!(currentContactType && currentContactType.id);
    }

    ngOnInit(): void {
        this.contactTypes = this.data.contactTypes.filter((ct) => !ct.is_system);
        this.profiles = this.data.profiles;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
