import {combineLatest as observableCombineLatest, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {IReport, IReportCategory} from '@cyberco-nodejs/zipi-typings';
import {IReportsState} from '../../store/reports.reducer';
import {Store} from '@ngrx/store';
import {selectReportsCategoriesList, selectReportList} from '../../store/reports.selectors';
import {
    DeleteReportsCategory,
    FetchReportsCategoriesList,
    FetchReportList,
    ToggleEditReportSidebarStatus,
    UpdateReport,
    DeleteReport
} from '../../store/reports.action';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from '../../../../services/session.service';
import {MatDialog} from '@angular/material/dialog';
import {AddCategoryDialogComponent} from '../add-category-dialog/add-category-dialog.component';
import {EditCategoryDialogComponent} from '../edit-category-dialog/edit-category-dialog.component';
import {ConfirmComponent} from '../../../../layouts/confirm/confirm.component';
import {ReportCategoriesService} from '../../services/report-categories.service';
import {MatSelectChange} from '@angular/material/select';

@Component({
    selector: 'app-report-category',
    templateUrl: './report-category.component.html',
    styleUrls: ['./report-category.component.scss']
})
export class ReportCategoryComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    presets: IReport[] | any;
    categories: IReportCategory[] | null = null;

    categoryType: string = '';

    constructor(
        private store: Store<IReportsState>,
        public dialog: MatDialog,
        private activateRoute: ActivatedRoute,
        public sessionService: SessionService,
        private reportCategoriesService: ReportCategoriesService,
        public router: Router
    ) {}

    ngOnInit() {
        const preset$ = this.store.select(selectReportList);
        const categories$ = this.store.select(selectReportsCategoriesList);
        const routParam$ = this.activateRoute.params;

        observableCombineLatest(preset$, categories$, routParam$, (presets, categories, routParams) => {
            const filteredCategories =
                routParams['type'] === 'personal'
                    ? categories.filter((category) => category.category_type === 'personal')
                    : routParams['type'] === 'company'
                      ? categories.filter((category) => category.category_type === 'company')
                      : null;

            // const filteredPresets = presets.data;
            let filteredPresets: IReport[] = [];
            if (routParams['type'] === 'company') {
                filteredPresets = presets.data.filter((report) => {
                    return (
                        (report.category && report.category.category_type === 'company') ||
                        (this.sessionService.profile &&
                            this.sessionService.profile.id === report.creator__profile_fk_id)
                    );
                });
            } else if (routParams['type'] === 'personal') {
                filteredPresets = presets.data.filter((report) => {
                    return (
                        this.sessionService.profile && this.sessionService.profile.id === report.creator__profile_fk_id
                    );
                });
            }

            return {filteredPresets, filteredCategories, routParams};
        })
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(({filteredPresets, filteredCategories, routParams}) => {
                this.categoryType = routParams['type'];
                this.presets = filteredPresets;
                this.categories = filteredCategories;
            });
        this.store.dispatch(new FetchReportList({query: {include: 'report_category'}}));
        this.store.dispatch(new FetchReportsCategoriesList());
    }

    isUncategorized(preset: IReport) {
        if (!this.categories) {
            return false;
        }

        return !this.categories.some((category) => {
            if (preset.category && preset.category.id) {
                return category.id === preset.category.id;
            }
            return false;
        });
    }

    presetInCategory(category_id: number) {
        return this.presets.some((pre: IReport) => {
            if (pre.category && pre.category.id) {
                return pre.category.id === category_id;
            }
            return false;
        });
    }

    sharedTo(preset: IReport, value: string) {
        const np = Object.assign({}, preset);
        np.shared_to = value;
    }

    changeCategory(preset: IReport, $event: MatSelectChange) {
        if ($event.value !== null && $event.value !== 0) {
            const np = Object.assign({}, preset);
            np.category_id = $event.value;
            this.store.dispatch(
                new UpdateReport({
                    request: np,
                    options: {
                        isFetchReports: true
                    }
                })
            );
        }
    }

    addCategory() {
        const dialogRef = this.dialog.open(AddCategoryDialogComponent, {
            width: '350px'
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((categoryData) => {
                if (categoryData && (categoryData.categoryType || this.categoryType)) {
                    const newCategory: IReportCategory | any = {
                        title: '',
                        creator__profile_fk_id: null,
                        creator__company_fk_id: null,
                        category_type: categoryData.categoryType ? categoryData.categoryType : null,
                        id: undefined
                    };

                    newCategory.title = categoryData.categoryName;

                    if (!categoryData.categoryType && this.categoryType) {
                        if (this.categoryType === 'company') {
                            newCategory.category_type = 'company';
                        } else {
                            newCategory.category_type = 'personal';
                        }
                    }

                    this.reportCategoriesService
                        .createReportsCategory(newCategory)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe(() => {
                            this.store.dispatch(new FetchReportList({query: {include: 'report_category'}}));
                            this.store.dispatch(new FetchReportsCategoriesList());
                        });
                }
            });
    }

    editCategory(categoryId: number, title: string) {
        const dialogRef = this.dialog.open(EditCategoryDialogComponent, {
            width: '350px',
            data: {
                categoryId: categoryId,
                title: title
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.store.dispatch(new FetchReportsCategoriesList());
                this.store.dispatch(new FetchReportList({query: {include: 'report_category'}}));
            });
    }

    deleteCategory(categoryId: number) {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            minHeight: 100,
            data: {
                title: `Deleting Category`,
                message: `Action will delete this category. Please confirm`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.store.dispatch(new DeleteReportsCategory(categoryId));
            });
    }

    /**
     * Toggle edit report sidebar
     * @param status
     * @param reportId
     */
    toggleEditReportSidebar(status: boolean, reportId: number | null = null) {
        this.store.dispatch(
            new ToggleEditReportSidebarStatus({
                isOpened: status,
                editId: reportId,
                isEditMode: true
            })
        );
    }

    deleteReport(reportId: number) {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            minHeight: 100,
            data: {
                title: `Deleting Report`,
                message: `Action will delete this Report and any saved data. Please confirm`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.store.dispatch(
                    new DeleteReport({
                        id: reportId,
                        options: {
                            isFetchReports: true
                        }
                    })
                );
            });
    }

    /**
     * Load report data
     * @param report
     */
    loadReportData(report: IReport) {
        this.router.navigate(['reports/preview', report.id]);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
