<section class="container-fluid" style="margin-bottom: 120px">
    <header class="my-2 d-flex justify-content-between align-items-end">
        <div>
            <h1>
                <span *ngIf="ledgerAccount">{{ ledgerAccount.name }} &mdash; </span>Reconciliation
            </h1>
            <span
                class="text-muted"
                *ngIf="baseData && (baseData.closing_balance || baseData.closing_balance_debit_or_credit)"
            >
                Reconciliation Period:&nbsp;
                <span *ngIf="baseData.start_date">{{ baseData.start_date | dateFromNumber | date: 'mediumDate' }}</span>
                <span *ngIf="baseData.end_date">
                    &ndash; {{ baseData.end_date | dateFromNumber | date: 'mediumDate' }}</span
                >
            </span>
        </div>
        <table class="reconciliation-calculation" *ngIf="baseData">
            <tr>
                <td>
                    Opening Balance:<br />
                    <span class="text-muted" *ngIf="baseData.start_date">{{
                        baseData.start_date | dateFromNumber | date: 'MMM dd, YYYY'
                    }}</span>
                </td>
                <td>
                    <span *ngIf="baseData.opening_balance !== null && baseData.opening_balance !== undefined">
                        {{ baseData.opening_balance | currency: 'USD' : 'symbol' }}
                    </span>
                    <br />
                    <span class="text-muted" *ngIf="baseData.opening_balance_debit_or_credit">
                        {{ baseData.opening_balance_debit_or_credit | fromSnakeCase }}
                    </span>
                </td>
            </tr>
        </table>
    </header>

    <app-start-reconciliation
        *ngIf="!(baseData?.closing_balance || baseData?.closing_balance_debit_or_credit) && !reconciliationId"
        (continueReconciliation)="startReconciliation($event)"
        [ledgerAccountId]="ledgerAccountId"
        [openingBalanceDate]="accountOpeningBalance?.opening_balance_date"
        [startDate]="lastReconciliation?.end_date || null"
        [isOpeningBalanceExist]="!!(lastReconciliation || accountOpeningBalance || !isLoaded)"
    ></app-start-reconciliation>

    <div class="my-2" style="color: red; font-weight: bold" *ngIf="selection.selected.length === transactionLimit">
        Transaction limit reached. Currently you can reconcile {{ transactionLimit }} transactions at once.
    </div>

    <div
        [ngClass]="{
            'd-none': !(baseData?.closing_balance || baseData?.closing_balance_debit_or_credit) && !reconciliationId
        }"
    >
        <div class="w-100 d-flex justify-content-end" style="height: 40px; margin-bottom: 10px">
            <div *ngIf="matchedTransactionCounter > 0">
                Detected <span style="font-weight: bold">{{ matchedTransactionCounter }}</span> unselected matched
                transactions on this page.
                <button mat-raised-button class="ml-2" (click)="addMatchedOnPage()">Add them to reconcile</button>
            </div>
        </div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 match-table w-100">
            <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef disableClear class="w-10">Date</th>
                <td mat-cell *matCellDef="let element" class="w-10">
                    <span *ngIf="element?.journal_date">
                        {{ element?.journal_date | dateFromNumber | date: 'mediumDate' }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="contact_name">
                <th mat-header-cell *matHeaderCellDef disableClear>Payor / Payee</th>
                <td mat-cell *matCellDef="let element" (click)="$event.preventDefault()">
                    <span *ngIf="element?.contact_name">
                        <a routerLink="/contacts/{{ element?.contact_fk_id }}">{{ element.contact_name }}</a>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let element">
                    <span>{{ element?.description }}</span>
                    <ng-container
                        *ngIf="element?.journal_reason_type === 'payment_made' && element?.check_info?.check_number"
                    >
                        <ng-container *ngIf="element?.description">,</ng-container>
                        <span class="ml-1">check number: {{ element?.check_info?.check_number }}</span>
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element">
                    <div class="d-flex align-items-center">
                        <span style="width: 30px"
                            ><mat-icon
                                aria-hidden="true"
                                matTooltip="Transaction matched"
                                style="margin-top: 5px"
                                *ngIf="element.matched__transaction_external_id"
                                >link</mat-icon
                            ></span
                        >
                        <span *ngIf="element?.journal_reason_type">{{
                            element.journal_reason_type | fromSnakeCase
                        }}</span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="deposits">
                <th mat-header-cell *matHeaderCellDef>
                    {{ ledgerAccount && ledgerAccount.type === 'credit_card' ? 'Payments/Credits' : 'Deposit' }}
                </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element?.debit_or_credit === 'debit'">
                        {{ element?.amount | currency: 'USD' : 'symbol' }}
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="withdrawals">
                <th mat-header-cell *matHeaderCellDef>
                    {{ ledgerAccount && ledgerAccount.type === 'credit_card' ? 'Purchases' : 'Withdrawal' }}
                </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element?.debit_or_credit === 'credit'">
                        {{ element?.amount | currency: 'USD' : 'symbol' }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox
                        *ngIf="row?.id && (isSelected(row) || selection.selected.length < transactionLimit)"
                        (click)="$event.stopPropagation()"
                        (change)="$event.checked ? checked(row) : unchecked(row)"
                        [checked]="isSelected(row)"
                        [aria-label]="checkboxLabel(row)"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [ngStyle]="
                    row &&
                    ([
                        'invoice_reverted',
                        'bill_reverted',
                        'payment_made_reverted',
                        'payment_received_reverted',
                        'payment_reverted',
                        'expense_reverted',
                        'credit_note_reverted',
                        'vendor_credit_reverted'
                    ].includes(row.journal_reason_type) ||
                        row.reverted__journal_fk_id)
                        ? {'background-color': 'lightgrey'}
                        : null
                "
            ></tr>
        </table>
        <mat-paginator
            [length]="listLength"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            showFirstLastButtons="false"
        ></mat-paginator>

        <mat-card class="d-flex justify-content-between my-3">
            <div class="mr-3">
                <p style="font-size: 0.8em" *ngIf="adjustmentTransactions.length">
                    <b>Adjustments</b> will become transactions only after they are reconciled.
                </p>
                <ul class="match-transactions" *ngIf="adjustmentTransactions.length">
                    <li
                        *ngFor="let transaction of adjustmentTransactions; let i = index"
                        class="d-flex justify-content-between"
                    >
                        <div>
                            <p *ngIf="transaction.amount || transaction.amount === 0">
                                Reconcile Account
                                <span class="text-capitalize">{{ transaction.debit_or_credit }}</span> for:
                                <span>{{ transaction.amount | currency: 'USD' : 'symbol' }}</span>
                            </p>
                            <p *ngIf="transaction.date">
                                Dated: {{ transaction.date | dateFromNumber | date: 'mediumDate' }}
                            </p>
                        </div>
                        <div class="d-flex align-items-center">
                            <button mat-icon-button (click)="editAdjustment(transaction, i)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button color="warn" (click)="removeAdjustment(i)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>
                    </li>
                </ul>

                <button mat-raised-button class="mat-button-base" (click)="addAdjustment()">
                    <mat-icon>add</mat-icon>
                    Add adjustment
                </button>
            </div>

            <table class="reconciliation-calculation">
                <tr>
                    <td>
                        Closing Balance:<br />
                        <span class="text-muted">{{ baseData?.end_date | dateFromNumber | date: 'MMM dd, YYYY' }}</span>
                    </td>
                    <td>
                        <span>{{ closingBalance | currency: 'USD' : 'symbol' }}</span>
                        <br />
                        <span class="text-muted">{{ baseData?.closing_balance_debit_or_credit | fromSnakeCase }}</span>
                    </td>
                </tr>
                <tr>
                    <td>Cleared:</td>
                    <td>
                        <span>{{ clearedAmount | currency: 'USD' : 'symbol' }}</span>
                    </td>
                </tr>
                <tr>
                    <td>Difference:</td>
                    <td>
                        <span>{{ differAmount | currency: 'USD' : 'symbol' }}</span>
                    </td>
                </tr>
            </table>
        </mat-card>
        <mat-toolbar class="reconciliation-footer mat-elevation-z3">
            <div matTooltip="Difference must be zero for reconciliation" [matTooltipDisabled]="differAmount === 0">
                <button
                    mat-raised-button
                    class="mat-primary"
                    [disabled]="createDisabled || differAmount !== 0"
                    (click)="!createDisabled && save('reconciled')"
                >
                    Reconcile
                </button>
            </div>
            <button
                mat-raised-button
                class="ml-2"
                [disabled]="createDisabled"
                (click)="!createDisabled && save('in_progress')"
            >
                Save as Draft
            </button>
            <i class="sp-spacer"></i>
            <button mat-raised-button class="ml-2" (click)="cancel()">Cancel</button>
        </mat-toolbar>
    </div>
</section>
