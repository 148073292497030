import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {ILedgerAccount, IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {ZipiFinancialTransferService} from '../../../../../../../services/api/finance/zipi-financia-transfer.service';
import {CompanyGatewayService} from '../../../../../../../services/api/finance/company-gateway.service';
import {takeUntil} from 'rxjs/operators';
import {LedgerAccountService} from '../../../../../../../services/api/finance/ledger-accounts.service';
import {PaymentMethodsService} from '../../../../../../profile/services/payment-methods.service';
import {environment} from '../../../../../../../../environments/environment';

@Component({
    selector: 'app-muhnee-add-bank-account-dialog',
    templateUrl: './muhnee-add-bank-account-dialog.component.html',
    styleUrls: ['./muhnee-add-bank-account-dialog.component.scss']
})
export class MuhneeAddBankAccountDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    redirectToBankManage: string = '';
    redirectToBankVerify: string = '';
    env = environment;

    paymentGateway: IPaymentGateway | undefined;
    alreadyConnectedBankIds: Array<string> = [];
    allAvailableBankAccounts: Array<any> = [];

    selectedAccount: any;

    currentStep: '1' | '2' = '1';

    availableLedgerAccounts: ILedgerAccount[] = [];
    isDefault: boolean = false;
    accountId: UntypedFormControl | undefined;

    applyDisabled: boolean = false;
    isLoading: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<MuhneeAddBankAccountDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {gateway: IPaymentGateway},
        private fb: UntypedFormBuilder,
        private zipiFinancialTransferService: ZipiFinancialTransferService,
        private companyGatewayService: CompanyGatewayService,
        private ledgerAccountService: LedgerAccountService,
        private paymentMethodsService: PaymentMethodsService
    ) {
        this.accountId = this.fb.control(null, [Validators.required]);
    }

    ngOnInit() {
        this.paymentGateway = this.data.gateway;
        this.loadBankAccounts();

        this.ledgerAccountService
            .getAvailableLedgerAccountsForFundingSourceByGatewayType(this.paymentGateway.type)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((accounts) => {
                this.availableLedgerAccounts = accounts.filter((account) => {
                    return account.type !== 'credit_card';
                });
            });

        if (this.paymentGateway && this.paymentGateway.driver_type === 'muhnee') {
            this.redirectToBankManage = `${this.env.services.shippCore.url}/v1/public/redirect/${this.paymentGateway.customer_ref}/banksmanage`;
        }
    }

    loadBankAccounts() {
        if (this.paymentGateway && this.paymentGateway.payment_gateway_id) {
            this.isLoading = true;
            this.companyGatewayService
                .getMuhneeAccountsForConnection(this.paymentGateway.payment_gateway_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    this.allAvailableBankAccounts = response.accounts;
                    this.alreadyConnectedBankIds = response.connected_accounts_ids;
                    this.isLoading = false;
                });
        }
    }

    connectBank(account: any) {
        if (account && account.id) {
            this.selectedAccount = account;

            this.currentStep = '2';
        }
    }

    apply(skipLedger: boolean) {
        if (!this.isDefault && this.accountId && this.accountId.invalid) {
            this.accountId.markAllAsTouched();
            return;
        }
        this.applyDisabled = true;
        if (this.accountId && this.paymentGateway && this.paymentGateway.payment_gateway_id) {
            const ledgerAccountId = this.accountId.value;
            let settings: {
                public_token: any;
                account_id: string;
                pending_auth_status: 'pending_manual_verification' | 'manually_verified' | 'need_relogin' | '';
                title: string;
                related_ledger_account_id: number | null;
                is_default_receiving: boolean | null;
                needDefaultLedger: boolean | null;
                account_type: 'cloud_bank' | 'bank' | 'card';
            } | null = {
                public_token: null,
                account_id: this.selectedAccount.id,
                pending_auth_status: '',
                title: `${this.selectedAccount.accountName} (${this.selectedAccount.lastFour})`,
                related_ledger_account_id: null,
                is_default_receiving: false,
                needDefaultLedger: null,
                account_type: this.selectedAccount.isCloudBank ? 'cloud_bank' : 'bank'
            };

            if (skipLedger) {
                settings.needDefaultLedger = false;
            } else if (this.isDefault) {
                settings.needDefaultLedger = true;
            } else {
                settings.needDefaultLedger = false;
                settings.related_ledger_account_id = ledgerAccountId;
            }

            this.createPaymentMethod(this.paymentGateway.payment_gateway_id, settings);
        }
    }

    createPaymentMethod(
        gatewayId: number,
        settings: {
            public_token: any;
            account_id: string;
            pending_auth_status: 'pending_manual_verification' | 'manually_verified' | 'need_relogin' | '';
            title: string;
            related_ledger_account_id: number | null;
            is_default_receiving: boolean | null;
            needDefaultLedger: boolean | null;
            account_type: 'cloud_bank' | 'bank' | 'card';
        }
    ) {
        this.paymentMethodsService
            .createZipiFinancialPaymentMethod(gatewayId, settings)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe({
                next: (response) => {
                    this.applyDisabled = false;
                    if (response) {
                        this.dialogRef.close({isError: false, success: true, message: ''});
                    }
                },
                error: (error) => {
                    this.applyDisabled = false;
                }
            });
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
