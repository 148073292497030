<div>
    <div mat-dialog-title>
        <h3>{{ data.title }}</h3>
    </div>

    <ng-content></ng-content>
    <mat-dialog-content #reloadLicensesSection>
        <table
            mat-table
            [dataSource]="dataSource"
            class="mat-elevation-z8 w-100 my-4"
            matSort
            matSortDisableClear
            matSortActive="{{ sort_column }}"
            matSortDirection="{{ sort_direction }}"
            (matSortChange)="onSortChange()"
            style="box-shadow: unset"
        >
            <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef class="w-20" mat-sort-header>Date</th>
                <td mat-cell *matCellDef="let element" class="w-20">
                    {{ element.created_at | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="error_details">
                <th mat-header-cell *matHeaderCellDef class="w-40" mat-sort-header>Details</th>
                <td mat-cell *matCellDef="let element" class="w-40">
                    {{ element.error_details }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell class="px-3 w-10" *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let element" class="p-3 w-10">
                    {{ element.status }}
                </td>
            </ng-container>

            <ng-container matColumnDef="used_billing_settings">
                <th mat-header-cell class="px-3 w-30" *matHeaderCellDef mat-sort-header>Used Billing Settings</th>
                <td mat-cell *matCellDef="let element" class="p-3 w-30">
                    <div *ngIf="element.reload_type === 'license'">
                        <div *ngFor="let field of makeArray(element.used_billing_settings)">
                            <span class="font-italic mr-2">{{ field[0] }}:</span>
                            <span *ngIf="field[1] !== null; else empty">{{ field[1] }}</span>
                            <ng-template #empty>
                                <span class="font-italic" style="color: grey">(empty value)</span>
                            </ng-template>
                        </div>
                    </div>
                    <div *ngIf="element.reload_type === 'service_deposit'">
                        <span class="font-italic mr-2">Refill Amount:</span>
                        <span>{{ element.used_billing_settings.commission_deposit_refill_amount }}</span>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row class="zp-table-row" *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </mat-dialog-content>

    <mat-dialog-actions class="d-flex justify-content-end">
        <button mat-raised-button (click)="dialogRef.close()" tabindex="-1">Cancel</button>
    </mat-dialog-actions>
</div>
