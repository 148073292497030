<div class="container-fluid" *ngIf="gatewayForm">
    <div class="d-flex mb-2 align-items-center">
        <i class="sp-spacer"></i>

        <!--<button type="button" mat-raised-button [routerLink]="['/company/gateway']">-->
        <!--<mat-icon>arrow_back</mat-icon>-->
        <!--Back-->
        <!--</button>-->
    </div>

    <mat-card class="mb-1" [formGroup]="gatewayForm">
        <div class="w-100 my-3">
            <div class="col logo">
                <img
                    style="width: 120px"
                    src="https://storage.googleapis.com/zipi-static/addons/authnet/logos/authnet%20360x140.png"
                />
            </div>
        </div>
        <div [formGroup]="authorizeSettingsForm">
            <div class="w-50 px-2 d-inline-flex">
                <mat-form-field class="w-100" *ngIf="!authorizeSettingsForm.controls.api_login_edit_mode.value">
                    <input matInput type="text" formControlName="existed_api_login" />
                    <mat-placeholder>Api Login ID</mat-placeholder>
                    <button mat-button matSuffix mat-icon-button aria-label="Edit" (click)="editApiLogin()">
                        <mat-icon>create</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field class="w-100" *ngIf="authorizeSettingsForm.controls.api_login_edit_mode.value">
                    <input matInput type="text" formControlName="api_login" autocomplete="off" />
                    <mat-placeholder>Api Login ID</mat-placeholder>
                    <button
                        mat-button
                        *ngIf="authorizeSettingsForm.controls.existed_api_login.value"
                        matSuffix
                        mat-icon-button
                        aria-label="Clear"
                        (click)="editApiLogin()"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div class="w-50 px-2 d-inline-flex">
                <mat-form-field class="w-100" *ngIf="!authorizeSettingsForm.controls.key_edit_mode.value">
                    <input matInput type="text" formControlName="existed_transaction_key" />
                    <mat-placeholder>Transaction Key</mat-placeholder>
                    <button mat-button matSuffix mat-icon-button aria-label="Edit" (click)="editTransactionKey()">
                        <mat-icon>create</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field class="w-100" *ngIf="authorizeSettingsForm.controls.key_edit_mode.value">
                    <input matInput type="text" formControlName="transaction_key" autocomplete="off" />
                    <mat-placeholder>Transaction Key</mat-placeholder>
                    <button
                        mat-button
                        *ngIf="authorizeSettingsForm.controls.existed_transaction_key.value"
                        matSuffix
                        mat-icon-button
                        aria-label="Clear"
                        (click)="editTransactionKey()"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>

        <div class="w-50 px-2">
            <app-ledger-account-selector
                [ledgerAccountControl]="gatewayForm.controls.related_ledger_account_id"
                [placeholder]="'Related Ledger Account'"
            >
            </app-ledger-account-selector>
        </div>

        <div
            *ngIf="
                authorizeSettingsForm &&
                authorizeSettingsForm.controls.is_credentials_invalid &&
                authorizeSettingsForm.controls.is_credentials_invalid.value
            "
            class="d-flex justify-content-center my-3"
        >
            <span style="font-size: 16px; font-weight: bold; color: red"
                >It looks like Your Credentials are not valid. Please try updating your "Transaction Key".</span
            >
        </div>
    </mat-card>

    <div class="d-flex">
        <i class="sp-spacer"></i>

        <button class="ml-2" type="button" mat-raised-button routerLink="/marketplace">Cancel</button>
        <button class="ml-2" type="button" mat-raised-button color="primary" (click)="saveGateway()">Save</button>

        <!--<button class="ml-2" type="button" mat-raised-button *ngIf="gatewayId" (click)="deleteGateway()">-->
        <!--Delete-->
        <!--</button>-->
    </div>
</div>
