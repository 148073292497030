<div class="d-flex">
    <h4>Payment Options</h4>
    <span class="sp-spacer"></span>
    <mat-icon class="sp-cursor-pointer" (click)="close(null)">close</mat-icon>
</div>

<mat-dialog-content>
    <div style="width: 560px">
        <div
            class="list-item align-items-center sp-cursor-pointer d-flex mx-1 mt-3 _track_po_zipi_pay_receive_only"
            style="min-height: 50px"
            (click)="close('bank')"
        >
            <div class="icon-rectangle">
                <mat-icon>account_balance</mat-icon>
            </div>
            <div class="w-60 text-wrap">
                <div class="list-item-title" style="font-size: 14px">Financial Transfer</div>
                <div style="font-size: 12px">Send payment to the recipient’s SkySlope Books Financial account</div>
            </div>
            <div class="w-20 d-flex justify-content-end">
                <mat-icon class="icon-chevron-right">chevron_right</mat-icon>
            </div>
        </div>
        <div
            class="list-item align-items-center sp-cursor-pointer d-flex mx-1 mt-3 _track_po_zipi_pay_receive_only"
            style="min-height: 50px"
            (click)="close('card')"
        >
            <div class="icon-rectangle">
                <mat-icon class="material-icons-outlined">account_balance_wallet</mat-icon>
            </div>
            <div class="w-60 text-wrap">
                <div class="list-item-title" style="font-size: 14px">Credit Card</div>
                <div style="font-size: 12px">Use a credit card on file in SkySlope Books to make a payment</div>
            </div>
            <div class="w-20 d-flex justify-content-end">
                <mat-icon class="icon-chevron-right">chevron_right</mat-icon>
            </div>
        </div>
    </div>
</mat-dialog-content>
