<mat-card class="compact-card">
    <mat-card-header class="header deal-testing-header">
        <mat-card-title class="deal-testing-header-title"> Deal Calculation Tests </mat-card-title>

        <div>
            <b class="label">
                Status:
                <label [ngClass]="selfTestingEntity?.tests_status" *ngIf="selfTestingEntity?.tests_status">
                    {{ DEAL_SELF_TESTING_STATUSES_LABELS[selfTestingEntity?.tests_status] }}
                </label>
                <span *ngIf="selfTestingEntity?.tested_at" style="margin-left: 5px"
                    >({{ selfTestingEntity?.tested_at | date: 'short' }})</span
                >
            </b>
            <button mat-raised-button style="margin-left: 16px" (click)="runSelfTests()">Run Tests</button>
        </div>
    </mat-card-header>
    <mat-card-content style="margin-left: 16px">
        <mat-expansion-panel
            *ngIf="
                selfTestingEntity.tests_status !== DEAL_SELF_TESTING_STATUSES.not_tested &&
                selfTestingEntity?.tests_results?.deal_calculations_tests_results
            "
            style="margin-bottom: 4px"
        >
            <mat-expansion-panel-header [collapsedHeight]="'64px'">
                <b class="label">Deal</b>
            </mat-expansion-panel-header>

            <div class="rule" *ngFor="let ruleKey of objectKeys(DealSelfTestingEntity.deals_text_formulas_GET)">
                <div class="rule-text">{{ DealSelfTestingEntity.deals_text_formulas_GET[ruleKey] }}</div>
                <div
                    class="rule-status"
                    [ngClass]="
                        selfTestingEntity.tests_results.deal_calculations_tests_results[ruleKey]?.status === 'passed'
                            ? 'passed'
                            : 'failed'
                    "
                >
                    {{
                        selfTestingEntity.tests_results.deal_calculations_tests_results[ruleKey]?.status === 'passed'
                            ? 'Passed'
                            : 'Failed'
                    }}
                </div>
            </div>
        </mat-expansion-panel>

        <ng-container *ngIf="selfTestingEntity?.tests_results?.sales_entities_calculations_tests_results">
            <mat-expansion-panel
                *ngFor="
                    let salesEntityResults of selfTestingEntity.tests_results.sales_entities_calculations_tests_results
                "
                style="margin-bottom: 4px"
            >
                <mat-expansion-panel-header [collapsedHeight]="'64px'">
                    <b *ngIf="salesEntityResults.is_primary">
                        {{ salesEntityResults.contact_part_link.link_title }}
                        <span>({{ salesEntityResults.role }})</span>
                    </b>
                    <span *ngIf="!salesEntityResults.is_primary">
                        {{ salesEntityResults.contact_part_link.link_title }}
                        <span
                            *ngIf="
                                [SalesEntity.role_SET.agent, SalesEntity.role_SET.referral].includes(
                                    salesEntityResults.role
                                )
                            "
                        >
                            ({{ salesEntityResults.role }})
                        </span>
                    </span>
                </mat-expansion-panel-header>

                <ng-container *ngIf="salesEntityResults.role === SalesEntity.role_SET.agent">
                    <div class="rule" *ngFor="let ruleKey of objectKeys(DealSelfTestingEntity.agent_text_formulas_GET)">
                        <div class="rule-text">{{ DealSelfTestingEntity.agent_text_formulas_GET[ruleKey] }}</div>
                        <div
                            class="rule-status"
                            [ngClass]="salesEntityResults[ruleKey]?.status === 'passed' ? 'passed' : 'failed'"
                        >
                            {{ salesEntityResults[ruleKey]?.status === 'passed' ? 'Passed' : 'Failed' }}
                        </div>
                    </div>
                </ng-container>

                <ng-container
                    *ngIf="
                        [SalesEntity.role_SET.transfer, SalesEntity.role_SET.referral].includes(salesEntityResults.role)
                    "
                >
                    <div
                        class="rule"
                        *ngFor="let ruleKey of objectKeys(DealSelfTestingEntity.transfer_text_formulas_GET)"
                    >
                        <div class="rule-text">{{ DealSelfTestingEntity.transfer_text_formulas_GET[ruleKey] }}</div>
                        <div
                            class="rule-status"
                            [ngClass]="salesEntityResults[ruleKey]?.status === 'passed' ? 'passed' : 'failed'"
                        >
                            {{ salesEntityResults[ruleKey]?.status === 'passed' ? 'Passed' : 'Failed' }}
                        </div>
                    </div>
                </ng-container>
            </mat-expansion-panel>
        </ng-container>

        <mat-expansion-panel
            *ngIf="
                selfTestingEntity.tests_status !== DEAL_SELF_TESTING_STATUSES.not_tested &&
                selfTestingEntity?.tests_results?.payouts_calculations_tests_results
            "
            style="margin-bottom: 4px"
        >
            <mat-expansion-panel-header [collapsedHeight]="'64px'">
                <b class="label">Payouts</b>
            </mat-expansion-panel-header>

            <div class="rule" *ngFor="let ruleKey of objectKeys(DealSelfTestingEntity.payouts_text_formulas_GET)">
                <div class="rule-text">{{ DealSelfTestingEntity.payouts_text_formulas_GET[ruleKey] }}</div>
                <div
                    class="rule-status"
                    [ngClass]="
                        selfTestingEntity.tests_results.payouts_calculations_tests_results[ruleKey]?.status === 'passed'
                            ? 'passed'
                            : 'failed'
                    "
                >
                    {{
                        selfTestingEntity.tests_results.payouts_calculations_tests_results[ruleKey]?.status === 'passed'
                            ? 'Passed'
                            : 'Failed'
                    }}
                </div>
            </div>
        </mat-expansion-panel>
    </mat-card-content>
</mat-card>
