import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DealService} from '../../../../../services/deal.service';
import {Deal} from '../../../../../models/deal';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {SetDualComponent} from './dialog/set-dual.component';
import {GenericFormGroup} from '../../../../../entites/generic.entity';
import {UiModsSource} from '../../../../ui-mods/ui-mods.source';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {ConfirmComponent} from '../../../../../layouts/confirm/confirm.component';
import {
    UiModsDealBadgeInListModel,
    UiModsDealBadgeOnPageModel,
    UiModsMenuOptionModel
} from '../../../../ui-mods/models/ui-mods.model';

@Component({
    selector: 'app-edit-dual-deal',
    styleUrls: ['./edit-deal.component.scss'],
    template: `
        <ng-container *ngIf="canCreateDualDeal">
            <div style="display: flex; align-items: center; justify-content: flex-end">
                <ng-container *ngIf="dealFormGroup.controls.dual_deal_id && !dealFormGroup.controls.dual_deal_id.value">
                    <button
                        mat-button
                        (click)="setDualSetting()"
                        [disabled]="
                            disableCreatingDual || dealFormGroup.controls.dual_deal_id.disabled || isDealSaveInProgress
                        "
                    >
                        Dual <mat-icon style="line-height: 22px;">link_off</mat-icon>
                    </button>
                </ng-container>
                <div *ngIf="dealFormGroup.controls.dual_deal_id && dealFormGroup.controls.dual_deal_id.value">
                    <button
                        mat-button
                        [disabled]="dealFormGroup.controls.dual_deal_id.disabled || isDealSaveInProgress"
                        [matMenuTriggerFor]="dualMenu"
                    >
                        Dual <mat-icon style="line-height: 22px;">link</mat-icon>
                    </button>
                    <mat-menu #dualMenu="matMenu" xPosition="before">
                        <button
                            mat-menu-item
                            (click)="router.navigate(['/deals', dealFormGroup.controls.dual_deal_id?.value])"
                        >
                            Open Dual Deal
                        </button>
                        <button mat-menu-item (click)="divideDuals(dealFormGroup.controls.dual_deal_id?.value)">
                            Unlink
                        </button>
                    </mat-menu>
                </div>
            </div>
        </ng-container>
    `
})
export class EditDealDualDealComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() dealFormGroup: GenericFormGroup<Deal> = new GenericFormGroup(new Deal(), 'change');
    @Input() canCreateDualDeal = false;
    allListBadges: UiModsDealBadgeInListModel[] = [];
    dealListBadges: UiModsDealBadgeInListModel[] = [];
    menuOptionsBadges: UiModsMenuOptionModel[] = [];
    disableCreatingDual: boolean = false;
    @Input() afterDealSaving$: Subject<string> = new Subject<string>();
    @Input() isDealSaveInProgress: boolean = false;
    @Output() saveDealChanges = new EventEmitter<string>();

    ngOnInit() {
        this.dealFormGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((deal) => {
            this.dealListBadges = this.allListBadges.filter(
                (badge: UiModsDealBadgeOnPageModel) =>
                    badge.deal_id === deal.id &&
                    badge.uiMod &&
                    badge.uiMod.type === 'dealBadgeInList' &&
                    badge.uiMod.addon
                // && (badge.uiMod.addon.slug === 'skyslope_integration' || badge.uiMod.addon.slug === 'dotloop_integration')
            );
        });

        this.uiModsSource.dealBadgesInList.pipe(takeUntil(this.unsubscribe)).subscribe((badges) => {
            this.allListBadges = badges;
        });

        this.uiModsSource.createDealMenuOptions.pipe(takeUntil(this.unsubscribe)).subscribe((menu_options) => {
            this.menuOptionsBadges = menu_options;
        });

        this.afterDealSaving$.pipe(debounceTime(500), takeUntil(this.unsubscribe)).subscribe((eventType: string) => {
            if (eventType === 'create_dual') {
                this.setDualSetting(false);
            }
        });
    }

    constructor(
        public dealService: DealService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private uiModsSource: UiModsSource
    ) {}

    divideDuals(dualDealId: number | undefined) {
        if (typeof dualDealId === 'undefined') {
            return;
        }

        return this.dealService.divideDuals(this.dealFormGroup.controls.id!.value, dualDealId).then(() => {
            this.dealFormGroup.controls.dual_deal_id!.patchValue(null);
            this.dealFormGroup.controls.dual_deal!.patchValue(null);
        });
    }

    buildOptionsFromBadges(
        dealListBadges: UiModsDealBadgeInListModel[],
        menuOptionsBadges: UiModsMenuOptionModel[]
    ): Array<{link: string; label: string} | null> {
        if (dealListBadges.length && menuOptionsBadges.length) {
            return dealListBadges
                .map((listBadge) => {
                    try {
                        const menuBadge = menuOptionsBadges.find(
                            (menuOption) =>
                                menuOption.uiMod &&
                                listBadge.uiMod &&
                                menuOption.uiMod.addon.slug === listBadge.uiMod.addon.slug
                        );
                        if (!menuBadge || !menuBadge.uiMod || !menuBadge.uiMod.preset || !listBadge.uiMod) {
                            return null;
                        }

                        return {
                            label: menuBadge.uiMod.preset.option.label,
                            link: menuBadge.uiMod.addon.addon_url + menuBadge.uiMod.preset.option.on_click.path,
                            // when user does not have permissions to edit this deal
                            disabled: (listBadge.uiMod as any).disabled
                        };
                    } catch (e) {
                        console.warn('Menu badge not found for listBadge: ', listBadge);
                        return null;
                    }
                })
                .filter((el) => !!el);
        }

        return [];
    }

    setDualSetting(withCheckChanges: boolean = true) {
        if (withCheckChanges) {
            if (this.dealFormGroup.dirty) {
                this.saveBeforeCreateDualRelationship();
                return;
            }
        }

        const dialogRef = this.dialog.open(SetDualComponent, {
            data: {
                dealId: this.dealFormGroup.get('id')!.value,
                type: this.dealFormGroup.get('type')!.value,
                addonsOptions: this.buildOptionsFromBadges(this.dealListBadges, this.menuOptionsBadges)
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.disableCreatingDual = true;

                    // if not new nor existed - ignore
                    if (result.creation_mode !== 'new' && result.creation_mode !== 'existed') {
                        const redirectParams = {
                            firstDealId: this.dealFormGroup.get('id')!.value,
                            searchStr: this.dealFormGroup.get('name')!.value
                        };
                        window.open(
                            result.creation_mode +
                                '?' +
                                Object.keys(redirectParams)
                                    // @ts-ignore
                                    .map((key) => key + '=' + encodeURIComponent(redirectParams[key]))
                                    .join('&')
                        );

                        return;
                    }

                    if (result.creation_mode === 'new') {
                        result.create_new = true;
                    } else if (result.creation_mode === 'existed') {
                        result.create_new = false;
                    }

                    this.dealService
                        .createDualRelationship(this.dealFormGroup.get('id')!.value, result)
                        .then((relatedDeal) => {
                            this.dealFormGroup.get('dual_deal_id')!.patchValue(relatedDeal.id);
                            this.disableCreatingDual = false;
                        });
                }
            });
    }

    saveBeforeCreateDualRelationship() {
        const confirmDialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            minHeight: 160,
            data: {
                title: `Before Creating Dual Deal Relationship`,
                message: `Your changes are not saved, please save them before creating dual deal relationship.`,
                buttonOkMessage: `Save Deal`
            }
        });

        confirmDialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((confirmed: boolean) => {
                if (confirmed) {
                    this.saveDealChanges.emit('create_dual');
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
