<div mat-dialog-title>
    <h3 *ngIf="mode === 'merge'">Merge Contacts</h3>
    <h3 *ngIf="mode === 'unmerge'">Manage Merged Contacts</h3>
</div>

<mat-dialog-content>
    <div class="load-container" id="load-container" *ngIf="isInProcess">
        <div class="loader">Loading...</div>
    </div>

    <div *ngIf="mode === 'unmerge'">
        <table mat-table [dataSource]="dataSource" class="w-100 my-4" matSort matSortDisableClear>
            <ng-container matColumnDef="display_name">
                <th mat-header-cell *matHeaderCellDef class="w-45">Contact</th>
                <td mat-cell *matCellDef="let element" class="w-45">
                    {{ element.display_name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef class="w-45">Email</th>
                <td mat-cell *matCellDef="let element" class="w-45">
                    {{ element.email }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="w-5">Action</th>
                <td mat-cell *matCellDef="let element" class="w-5">
                    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="$event.stopPropagation(); unmergeContact(element)">
                            Restore Contact
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedUnmergeColumns"></tr>
            <tr mat-row class="zp-table-row" *matRowDef="let row; columns: displayedUnmergeColumns"></tr>
        </table>
    </div>

    <div *ngIf="mode === 'merge'">
        <p style="margin-bottom: 0">Select Primary Contact Record</p>

        <div *ngIf="errorData" class="error-data">
            <div *ngIf="errorData.message" class="item-box general-message" [innerHtml]="errorData.message"></div>
        </div>

        <mat-radio-group class="primary-radio-group" [(ngModel)]="primaryContact">
            <table mat-table [dataSource]="dataSource" class="w-100 my-4" matSort matSortDisableClear>
                <ng-container matColumnDef="contact_id">
                    <th mat-header-cell *matHeaderCellDef>Primary</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-radio-button
                            [checked]="element.checked"
                            [disabled]="isCurrentProfileInList"
                            class="primary-radio-button"
                            [value]="element"
                        ></mat-radio-button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="display_name">
                    <th mat-header-cell *matHeaderCellDef>Contact</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.display_name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.email }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Phone</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.phone }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    class="zp-table-row"
                    [class.row-is-disabled]="isCurrentProfileInList"
                    *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </table>
        </mat-radio-group>
        Select the Contact to be used as the Primary record. All listed items will be merged into one record. All
        activities related to these Contacts will be associated with the resulting Primary record.
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex w-100 my-3">
        <span class="sp-spacer"></span>
        <button mat-raised-button class="mr-2" (click)="dialogRef.close()" tabindex="-1">Cancel</button>
        <button
            mat-raised-button
            color="primary"
            (click)="mergeContacts()"
            tabindex="1"
            [disabled]="!primaryContact || isInProcess"
            *ngIf="mode === 'merge'"
        >
            Merge
        </button>
    </div>
</mat-dialog-actions>
