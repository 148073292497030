<div class="row content">
    <div class="col-xl-4 left-side">
        <mat-button-toggle-group vertical="true" [value]="activePanel" (change)="onSelectOptions($event)">
            <mat-button-toggle
                *ngFor="let item of reportOptions"
                [value]="item.value"
                (change)="onSelectOptions($event)"
            >
                {{ item.title }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="col-xl-8 right-side">
        <ng-container *ngIf="reportSettings">
            <div *ngIf="activePanel === 'time_period'">
                <app-report-edit-bar-time-period
                    [value]="{
                        time_period: reportSettings.time_period,
                        time_period_start: reportSettings.time_period_start,
                        time_period_end: reportSettings.time_period_end
                    }"
                    [startDateDisable]="true"
                    (valueChanges)="onValueChanges('time_period', $event)"
                >
                </app-report-edit-bar-time-period>
            </div>

            <div *ngIf="activePanel === 'report_basis'">
                <app-report-edit-bar-button-list
                    [list]="listOfReportBasis"
                    [value]="reportSettings.report_basis"
                    (valueChanges)="onValueChanges('report_basis', $event)"
                >
                </app-report-edit-bar-button-list>
            </div>

            <div *ngIf="activePanel === 'report_tagging'" class="content-item">
                <h4>Select tags</h4>
                <app-report-autocomplete
                    [list]="allTags"
                    [value]="reportSettings.tags"
                    (valueChanges)="onValueChanges('tags', $event)"
                    titleAttribute="title"
                    valueAttribute="tag_id"
                >
                </app-report-autocomplete>
            </div>

            <div *ngIf="activePanel === 'include_zero_balance'">
                <app-report-edit-bar-button-list
                    [list]="listOfYesNoAnswer"
                    [value]="reportSettings.is_include_zero_balance"
                    (valueChanges)="onValueChanges('is_include_zero_balance', $event)"
                >
                </app-report-edit-bar-button-list>
            </div>

            <div *ngIf="activePanel === 'financial_deals'">
                <app-report-edit-bar-deal-picker
                    [value]="reportSettings.financial_deals"
                    (valueChanges)="onValueChanges('financial_deals', $event)"
                >
                </app-report-edit-bar-deal-picker>
            </div>
        </ng-container>

        <div *ngIf="report">
            <app-report-edit-bar-action [reportId]="report.id" [isShowNext]="isShowNext" (action)="onAction($event)">
            </app-report-edit-bar-action>
        </div>
    </div>
</div>
