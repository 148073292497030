<div>
    <mat-card class="preview-body" *ngIf="reportData && reportData.length">
        <div class="tables">
            <div class="table-wrapper">
                <table mat-table [dataSource]="reportData" class="w-100">
                    <ng-container [matColumnDef]="column.value" *ngFor="let column of tableStructure">
                        <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                        <td mat-cell *matCellDef="let element" class="td-cell">{{ element[column.value] }}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                </table>
            </div>
        </div>
    </mat-card>

    <mat-card class="preview-body" *ngIf="!reportData || !reportData.length">
        <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
    </mat-card>
</div>
