import {Component, OnDestroy, OnInit} from '@angular/core';
import {CompaniesService} from '../../../services/companies.service';
import {LicensesService} from '../../../services/licenses.service';
import {ICompanyBillingSettings, ILicenseReload, IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {UpdateCompanyBillingSettings} from '../store/settings.actions';
import {Store} from '@ngrx/store';
import {ISettingsState} from '../store/settings.reducer';
import {MatDialog} from '@angular/material/dialog';
import {PurchasedDealsDialogComponent} from './purchased-deals-dialog/purchased-deals-dialog.component';
import {CompanyGatewayService} from '../../../services/api/finance/company-gateway.service';
import {LicenseReloadService} from '../../../services/license-reload.service';
import {SessionService} from 'app/services/session.service';
import {ReloadsHistoryDialogComponent} from './reloads-history-dialog/reloads-history-dialog.component';
import {FeatureFlagsService} from '../../feature-flags/feature-flags.service';

export class CustomFormGroup extends UntypedFormGroup {
    public controls: {
        license_price: UntypedFormControl;
        setting_expire_in_date: UntypedFormControl;
        min_increment_size: UntypedFormControl;
        threshold_for_reload: UntypedFormControl;
        current_increment_size: UntypedFormControl;
        days_to_expiration: UntypedFormControl;
        max_total_reloads: UntypedFormControl;
        total_reloads_left: UntypedFormControl;
        unlimit_reloads: UntypedFormControl;
        payment_method_fk_id: UntypedFormControl;
        commission_deposit_refill_amount: UntypedFormControl;
    };

    constructor(controls: {
        license_price: UntypedFormControl;
        setting_expire_in_date: UntypedFormControl;
        min_increment_size: UntypedFormControl;
        threshold_for_reload: UntypedFormControl;
        current_increment_size: UntypedFormControl;
        days_to_expiration: UntypedFormControl;
        max_total_reloads: UntypedFormControl;
        total_reloads_left: UntypedFormControl;
        unlimit_reloads: UntypedFormControl;
        payment_method_fk_id: UntypedFormControl;
        commission_deposit_refill_amount: UntypedFormControl;
    }) {
        super(controls);
        this.controls = controls;
    }
}

@Component({
    selector: 'app-company-license',
    templateUrl: 'purchase-settings.component.html',
    styles: [
        `
            .purchase-form {
                margin-top: 1rem;
            }
        `
    ]
})
export class PurchaseSettingsComponent implements OnInit, OnDestroy {
    companyId: number | undefined = undefined;

    licenseForm: CustomFormGroup | undefined = undefined;

    private unsubscribe: Subject<void> = new Subject();

    billingSettings: ICompanyBillingSettings | undefined = undefined;
    commissionDepositSettings: {balance: number; threshold: number} = {balance: 0, threshold: 0};
    licensesCount: number | undefined = undefined;
    processing: ILicenseReload[] = [];
    companyGateways: IPaymentGateway[] = [];
    previousPaymentValue: number | null = null;

    isCommissionDepositDataUnavailable: boolean = false;

    // feature_flags
    isCommissionDepositSettingsEnabled: boolean = false;

    constructor(
        private companiesService: CompaniesService,
        private licensesService: LicensesService,
        private fb: UntypedFormBuilder,
        private companyGatewayService: CompanyGatewayService,
        private store: Store<ISettingsState>,
        public dialog: MatDialog,
        private licenseReloadService: LicenseReloadService,
        protected sessionService: SessionService,
        protected featureFlagsService: FeatureFlagsService
    ) {}

    async ngOnInit() {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.isCommissionDepositSettingsEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:muhnee_commission_deposit_settings'
                );
            });
        if (this.sessionService.profile && this.sessionService.profile.company) {
            this.companyId = this.sessionService.profile.company.id;

            this.companiesService
                .getLastBillingSettings(this.companyId as number)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    this.billingSettings = result;
                    if (this.billingSettings.billing_settings) {
                        this.initForm(this.billingSettings.billing_settings);
                    }
                });

            this.companiesService
                .getCompanyCommissionDepositSettings(this.companyId as number)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (result) {
                        this.commissionDepositSettings.balance = this._modifyValueForView(result.balance, 2);
                        this.commissionDepositSettings.threshold = this._modifyValueForView(
                            result.threshold !== null ? result.threshold : result.default_threshold,
                            2
                        );
                    } else {
                        this.isCommissionDepositDataUnavailable = true;
                    }
                });

            // Get Licenses count
            this.licensesService
                .getAvailableLicensesCount(this.companyId as number)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    this.licensesCount = result.licensescount;
                });

            // Get Pending licenses reload
            this.licenseReloadService
                .getPendingLicenseReload(this.companyId as number)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    this.processing = result;
                });

            // Get payments
            this.companyGatewayService
                .getCompanyGatewaysByTypes(['zipi_financial_business'], null)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateways) => {
                    this.companyGateways = gateways;
                });
        }
    }

    initForm(defLicense: {
        license_price: number;
        min_increment_size: number;
        current_increment_size: number;
        threshold_for_reload: number;
        days_to_expiration: number;
        max_total_reloads: number;
        total_reloads_left: number;
        unlimit_reloads: boolean;
        setting_expire_in_date?: number; // uses in FormGroup
        payment_method_fk_id?: number; // uses in FormGroup
        commission_deposit_refill_amount: number;
    }) {
        this.licenseForm = new CustomFormGroup({
            license_price: new UntypedFormControl(defLicense.license_price, [Validators.required]),
            setting_expire_in_date: new UntypedFormControl(this.billingSettings?.expire_date, []),
            min_increment_size: new UntypedFormControl(defLicense.min_increment_size, []),
            threshold_for_reload: new UntypedFormControl(defLicense.threshold_for_reload, [
                Validators.required,
                Validators.min(0)
            ]),
            current_increment_size: new UntypedFormControl(defLicense.current_increment_size, [
                Validators.required,
                Validators.min(defLicense.min_increment_size)
            ]),
            days_to_expiration: new UntypedFormControl(
                {
                    value: defLicense.days_to_expiration,
                    disabled: true
                },
                []
            ),
            max_total_reloads: new UntypedFormControl(defLicense.max_total_reloads, []),
            total_reloads_left: new UntypedFormControl(defLicense.total_reloads_left, []),
            unlimit_reloads: new UntypedFormControl(defLicense.unlimit_reloads, []),
            payment_method_fk_id: new UntypedFormControl(this.billingSettings?.payment_method_fk_id, []),
            commission_deposit_refill_amount: new UntypedFormControl(defLicense.commission_deposit_refill_amount, [])
        });
        this.previousPaymentValue = this.billingSettings?.payment_method_fk_id || null;
    }

    async saveCompanyBillingSettings() {
        if (this.getFormValidationErrors() > 0 && typeof this.licenseForm !== 'undefined') {
            this.licenseForm.markAllAsTouched();
            return;
        }

        if (typeof this.billingSettings === 'undefined') {
            return;
        }
        this.billingSettings.expire_date = this.licenseForm?.controls.setting_expire_in_date.value;
        this.billingSettings.payment_method_fk_id = this.licenseForm?.controls.payment_method_fk_id.value;
        this.billingSettings.billing_settings = this.licenseForm?.getRawValue();
        delete this.billingSettings.billing_settings?.setting_expire_in_date;
        delete this.billingSettings.billing_settings?.payment_method_fk_id;

        this.companiesService
            .createBillingSettings(this.companyId as number, this.billingSettings)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                this.store.dispatch(new UpdateCompanyBillingSettings());
            });
    }

    getFormValidationErrors(): number {
        if (typeof this.licenseForm === 'undefined') {
            return 0;
        }

        const errors = Object.keys(this.licenseForm.controls).map((key) => {
            return this.licenseForm?.get(key)?.errors;
        });

        // Filter null values
        return errors.filter((item) => item).length;
    }

    openViewLicenses() {
        const dialogRef = this.dialog.open(PurchasedDealsDialogComponent, {
            minWidth: 320,
            data: {
                title: 'Purchased Deals',
                values: {
                    companyId: this.companyId
                }
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => typeof pn !== 'undefined' && pn.length > 0),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {});
    }

    showHistoryReloads() {
        const dialogRef = this.dialog.open(ReloadsHistoryDialogComponent, {
            minWidth: 320,
            autoFocus: false,
            data: {
                title: 'History of reloads',
                values: {
                    companyId: this.companyId
                }
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => typeof pn !== 'undefined' && pn.length > 0),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {});
    }

    _modifyValueForView(value: number, position: number) {
        let stringAmount = String(value);
        const isNegative = stringAmount.match('-');
        if (isNegative) {
            stringAmount = stringAmount.slice(1);
        }
        const parts = stringAmount.split('');
        if (position === 2) {
            if (parts.length === 1) {
                parts.splice(-position, 0, '.0');
            } else {
                parts.splice(-position, 0, '.');
            }
        } else {
            if (parts.length === 1) {
                // 1 => .001
                parts.splice(-position, 0, '.00');
            } else if (parts.length === 2) {
                // 12 => .012
                parts.splice(-position, 0, '.0');
            } else {
                // 123 => .123, 1234 => 1.234
                parts.splice(-position, 0, '.');
            }
        }
        stringAmount = parts.join('');
        if (isNegative) {
            stringAmount = '-' + stringAmount;
        }
        return Number(stringAmount);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
