<mat-sidenav-container (backdropClick)="close()" class="h-100" style="background-color: unset">
    <mat-sidenav #sidebarTransactions mode="over" [position]="'end'" style="background-color: #fff">
        <div class="w-100 px-3 d-flex justify-content-between align-items-center">
            <h1 class="m-0" *ngIf="ledgerAccount?.name">{{ ledgerAccount?.name }} Transactions</h1>
            <button mat-button class="mat-primary sidenav-button" (click)="close()">Close</button>
        </div>

        <div class="d-flex justify-content-between px-3 my-2 font-weight-bold">
            <div class="">Transaction Basis</div>
            <div class="d-flex w-30 align-items-center justify-content-center">
                <div class="mr-1">Accrual</div>
                <mat-slide-toggle (change)="changeBasis($event)">Cash</mat-slide-toggle>
            </div>
        </div>
        <!------- Table of ledger accounts ------->
        <div class="table-background ledger-account-table">
            <table
                mat-table
                [dataSource]="dataSource"
                [ngClass]="{'d-none': dataSource.data.length === 0}"
                matSort
                matSortDisableClear
                matSortActive="{{ scrollData.sort_column }}"
                [matSortDirection]="scrollData.sort_direction"
                (matSortChange)="changeSort($event)"
                class="w-100"
            >
                <ng-container matColumnDef="journal_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20">Date</th>
                    <td mat-cell *matCellDef="let element" class="w-20">
                        {{ element.journal_date | dateFromNumber | date: 'mediumDate' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef class="w-25">Description</th>
                    <td mat-cell *matCellDef="let element" class="w-25 px-1">
                        {{ element.description }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="debit_or_credit">
                    <th mat-header-cell *matHeaderCellDef class="w-15">Debit / Credit</th>
                    <td mat-cell *matCellDef="let element" class="w-15 px-1">
                        {{ element.debit_or_credit | fromSnakeCase }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="debit_amount">
                    <th mat-header-cell *matHeaderCellDef class="w-20 pl-3">Debit</th>
                    <td mat-cell *matCellDef="let element" class="w-20 px-1 text-right">
                        <span *ngIf="element.debit_or_credit === 'debit'">
                            {{ element.amount | currency: 'USD' : 'symbol' }}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="credit_amount">
                    <th mat-header-cell *matHeaderCellDef class="w-20">Credit</th>
                    <td mat-cell *matCellDef="let element" class="w-20 text-right">
                        <span *ngIf="element.debit_or_credit === 'credit'">
                            {{ element.amount | currency: 'USD' : 'symbol' }}
                        </span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" class="zp-table-row"></tr>
            </table>

            <!------- Paginator ------->
            <div class="custom-paginator-background" style="width: 494px"></div>
            <mat-paginator
                class="custom-paginator"
                [length]="scrollData.total"
                [pageSize]="scrollData.limit"
                [pageSizeOptions]="pageSizeOptions"
            >
            </mat-paginator>
            <!------- No values ------->
            <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 text-center no-items">
                There are no transactions.
            </div>
        </div>
    </mat-sidenav>

    <ng-content></ng-content>
</mat-sidenav-container>
