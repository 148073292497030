import {Component, EventEmitter, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {PaymentMethodsService} from '../../../../../../profile/services/payment-methods.service';
import {takeUntil} from 'rxjs/operators';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../../../utilities/maskito';

@Component({
    selector: 'app-muhnee-verify-bank-account-hosted-dialog',
    templateUrl: './muhnee-verify-bank-account-hosted-dialog.component.html',
    styleUrls: ['./muhnee-verify-bank-account-hosted-dialog.component.scss']
})
export class MuhneeVerifyBankAccountHostedDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    deleteMethodEvent: EventEmitter<number> = new EventEmitter();

    paymentGateway: IPaymentGateway | undefined;
    paymentMethodId: number | undefined;

    amountOne: UntypedFormControl = this.fb.control(0, [Validators.required, Validators.min(0.01)]);
    amountTwo: UntypedFormControl = this.fb.control(0, [Validators.required, Validators.min(0.01)]);

    currencyMaskitoMask = currencyMaskitoOptions;

    errorMessage: string = '';
    noMoreAttempts: boolean = false;

    isLoading: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<MuhneeVerifyBankAccountHostedDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {gateway: IPaymentGateway; payment_method_id: number},
        private fb: UntypedFormBuilder,
        private paymentMethodsService: PaymentMethodsService
    ) {}

    ngOnInit() {
        this.paymentGateway = this.data.gateway;
        this.paymentMethodId = this.data.payment_method_id;
    }

    verify() {
        if (this.amountOne.invalid || this.amountTwo.invalid) {
            this.amountOne.markAllAsTouched();
            this.amountTwo.markAllAsTouched();
            return;
        }

        this.isLoading = true;
        const data = {
            amount1: Number(cleanCurrencyString(this.amountOne.value)),
            amount2: Number(cleanCurrencyString(this.amountTwo.value))
        };

        if (this.paymentMethodId) {
            this.paymentMethodsService
                .verifyZipiFinancialPaymentMethod(this.paymentMethodId, data)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe({
                    next: (response) => {
                        this.isLoading = false;
                        if (response) {
                            if (response.success) {
                                this.dialogRef.close({isError: false, success: true, message: ''});
                            } else {
                                if (response.errorMsg) {
                                    if (response.errorMsg.match(/only have \d attempts/g)) {
                                        this.errorMessage = response.errorMsg;
                                    } else if (response.errorMsg.match(/attempt are completed/g)) {
                                        this.errorMessage = response.errorMsg;
                                        this.noMoreAttempts = true;
                                        this.deleteMethodEvent.emit(this.paymentMethodId);
                                    } else {
                                        this.errorMessage = 'Something went wrong.';
                                    }
                                } else {
                                    this.errorMessage = 'Something went wrong.';
                                }
                            }
                        }
                    },
                    error: (error) => {
                        this.isLoading = false;
                    }
                });
        }
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
