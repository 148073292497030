<div>
    <mat-card *ngFor="let focus_area of formGroup.controls.by_group.controls" class="mb-4">
        <mat-card-content>
            <h3>{{ focus_area.controls.title.value }}</h3>
            <div
                style="display: flex; width: 100%"
                *ngFor="let enumerable of focus_area.controls.enumerable.controls; index as ei"
            >
                <ng-container *ngIf="enumerable.value.mandatory_type === 'personal_template'; else pick">
                    <button mat-icon-button (click)="cloneCompanyGoal(enumerable)">
                        <mat-icon>file_copy</mat-icon>
                    </button>
                </ng-container>
                <ng-template #pick>
                    <mat-slide-toggle class="toggle" [formControl]="enumerable.controls.pick"></mat-slide-toggle>
                </ng-template>
                <app-goal-progress
                    *ngIf="enumerable.getRawValue().type === 'progress'"
                    style="width: 100%"
                    [goal]="enumerable"
                    [index]="ei"
                ></app-goal-progress>
                <app-goal-general
                    *ngIf="enumerable.getRawValue().type === 'general'"
                    style="width: 100%"
                    [goal]="enumerable"
                    [index]="ei"
                ></app-goal-general>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card class="mb-4">
        <mat-card-content>
            <h3>{{ formGroup.controls.personal_goals.controls.title.value }}</h3>
            <div
                style="display: flex; width: 100%"
                *ngFor="let enumerable of formGroup.controls.personal_goals.controls.enumerable.controls; index as ei"
            >
                <app-goal-progress
                    *ngIf="enumerable.value.type === 'progress'"
                    style="width: 100%"
                    [goal]="enumerable"
                    [index]="ei"
                ></app-goal-progress>
                <app-goal-general
                    *ngIf="enumerable.value.type === 'general'"
                    style="width: 100%"
                    [goal]="enumerable"
                    [index]="ei"
                ></app-goal-general>
                <div style="display: flex">
                    <button mat-icon-button color="warn">
                        <mat-icon aria-label="Delete Goal" (click)="deleteGoal(formGroup.controls.personal_goals, ei)"
                            >close
                        </mat-icon>
                    </button>
                </div>
            </div>
            <div style="display: flex; justify-content: flex-end; width: 100%">
                <button mat-button [matMenuTriggerFor]="addGoalMenu">+ Add new goal</button>
                <mat-menu #addGoalMenu="matMenu">
                    <button mat-menu-item (click)="addProgressGoal(formGroup.controls.personal_goals, 'attain')">
                        Attain
                    </button>
                    <button mat-menu-item (click)="addGeneralGoal(formGroup.controls.personal_goals)">General</button>
                    <button mat-menu-item (click)="addProgressGoal(formGroup.controls.personal_goals)">Progress</button>
                </mat-menu>
            </div>
        </mat-card-content>
    </mat-card>
</div>
