import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {GenericFormGroup} from '../../../../../../entites/generic.entity';
import {TransferCalculation} from '../../../../../shared/components/calculations/calculations.model';
import {DealService} from '../../../../../../services/deal.service';

@Component({
    selector: 'app-transfer-preview',
    styleUrls: ['preview.component.scss'],
    template: `
        <mat-card class="wide-card preview-card">
            <mat-card-title
                (click)="toggleCollapse()"
                style="font-size: 15px; align-items: center;display: flex;cursor: pointer;"
            >
                Preview
                <mat-icon>
                    {{ isZeroValuesExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                </mat-icon>
            </mat-card-title>

            <mat-card-content class="full-width">
                <div
                    class="preview-row subtotal-section column"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (transferCalculationFG.controls.referral_incomes_sum &&
                            transferCalculationFG.controls.referral_incomes_sum.controls.amount &&
                            transferCalculationFG.controls.referral_incomes_sum.controls.amount.value)
                    "
                >
                    <div class="w-100 node-with-details">
                        <div class="w-55 label text-left">Referral Incomes:</div>
                        <div class="w-35 text-right">
                            <app-financial-node
                                moneyClass="fee-value"
                                *ngIf="transferCalculationFG.controls.referral_incomes_sum"
                                [financialNodeFG]="transferCalculationFG.controls.referral_incomes_sum"
                                [highlightIfNegative]="true"
                            ></app-financial-node>
                        </div>
                        <div
                            class="w-10"
                            *ngIf="
                                transferCalculationFG.controls.referral_incomes_sum &&
                                transferCalculationFG.controls.referral_incomes_sum.controls.amount &&
                                transferCalculationFG.controls.referral_incomes_sum.controls.amount.value
                            "
                        >
                            <div
                                *ngIf="
                                    transferCalculationFG.controls.referral_incomes_sum.controls.details &&
                                    transferCalculationFG.controls.referral_incomes_sum.controls.details.length > 0
                                "
                                class="arrow-button"
                                (click)="isDetailsOpenedReferral = !isDetailsOpenedReferral"
                            >
                                <mat-icon>{{
                                    isDetailsOpenedReferral ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                }}</mat-icon>
                            </div>
                            <div
                                *ngIf="
                                    transferCalculationFG.controls.referral_incomes_sum.controls.details &&
                                    transferCalculationFG.controls.referral_incomes_sum.controls.details.length === 0
                                "
                                style="height: 24px"
                            >
                                <mat-icon
                                    *ngIf="dealSystemStatus === 'open'"
                                    matTooltip="To see details section in this deal please save it and edit it again"
                                    >help_outline</mat-icon
                                >
                                <mat-icon
                                    *ngIf="dealSystemStatus === 'approved'"
                                    matTooltip="To see details section in this deal please disapprove it, save it and edit it again."
                                    >help_outline</mat-icon
                                >
                            </div>
                        </div>
                    </div>
                    <app-financial-details
                        class="w-100"
                        *ngIf="
                            isDetailsOpenedReferral &&
                            transferCalculationFG.controls.referral_incomes_sum &&
                            transferCalculationFG.controls.referral_incomes_sum.controls.details
                        "
                        [detailsFA]="transferCalculationFG.controls.referral_incomes_sum.controls.details"
                    ></app-financial-details>
                </div>

                <div
                    class="preview-row subtotal-section"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (transferCalculationFG.controls.additional_incomes_sum &&
                            transferCalculationFG.controls.additional_incomes_sum.controls.amount &&
                            transferCalculationFG.controls.additional_incomes_sum.controls.amount.value) ||
                        (transferCalculationFG.controls.additional_expenses_sum &&
                            transferCalculationFG.controls.additional_expenses_sum.controls.amount &&
                            transferCalculationFG.controls.additional_expenses_sum.controls.amount.value)
                    "
                >
                    <div
                        class="sub-row column"
                        *ngIf="
                            isZeroValuesExpanded ||
                            (transferCalculationFG.controls.additional_incomes_sum &&
                                transferCalculationFG.controls.additional_incomes_sum.controls.amount &&
                                transferCalculationFG.controls.additional_incomes_sum.controls.amount.value)
                        "
                    >
                        <div class="w-100 node-with-details">
                            <div class="w-55 label text-left">Incomes:</div>
                            <div class="w-35 text-right">
                                <app-financial-node
                                    moneyClass="fee-value positive"
                                    *ngIf="transferCalculationFG.controls.additional_incomes_sum"
                                    [financialNodeFG]="transferCalculationFG.controls.additional_incomes_sum"
                                    [highlightIfNegative]="true"
                                ></app-financial-node>
                            </div>
                            <div
                                class="w-10"
                                *ngIf="
                                    transferCalculationFG.controls.additional_incomes_sum &&
                                    transferCalculationFG.controls.additional_incomes_sum.controls.amount &&
                                    transferCalculationFG.controls.additional_incomes_sum.controls.amount.value
                                "
                            >
                                <div
                                    *ngIf="
                                        transferCalculationFG.controls.additional_incomes_sum.controls.details &&
                                        transferCalculationFG.controls.additional_incomes_sum.controls.details.length >
                                            0
                                    "
                                    class="arrow-button"
                                    (click)="isDetailsOpenedIncomes = !isDetailsOpenedIncomes"
                                >
                                    <mat-icon>{{
                                        isDetailsOpenedIncomes ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                    }}</mat-icon>
                                </div>
                                <div
                                    *ngIf="
                                        transferCalculationFG.controls.additional_incomes_sum.controls.details &&
                                        transferCalculationFG.controls.additional_incomes_sum.controls.details
                                            .length === 0
                                    "
                                    style="height: 24px"
                                >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'open'"
                                        matTooltip="To see details section in this deal please save it and edit it again"
                                        >help_outline</mat-icon
                                    >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'approved'"
                                        matTooltip="To see details section in this deal please disapprove it, save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                </div>
                            </div>
                        </div>
                        <app-financial-details
                            class="w-100"
                            *ngIf="
                                isDetailsOpenedIncomes &&
                                transferCalculationFG.controls.additional_incomes_sum &&
                                transferCalculationFG.controls.additional_incomes_sum.controls.details
                            "
                            [detailsFA]="transferCalculationFG.controls.additional_incomes_sum.controls.details"
                        ></app-financial-details>
                    </div>
                    <div
                        class="sub-row column"
                        *ngIf="
                            isZeroValuesExpanded ||
                            (transferCalculationFG.controls.additional_expenses_sum &&
                                transferCalculationFG.controls.additional_expenses_sum.controls.amount &&
                                transferCalculationFG.controls.additional_expenses_sum.controls.amount.value)
                        "
                    >
                        <div class="w-100 node-with-details">
                            <div class="w-55 label text-left">Expenses:</div>
                            <div class="w-35 text-right">
                                <app-financial-node
                                    moneyClass="fee-value negative"
                                    *ngIf="transferCalculationFG.controls.additional_expenses_sum"
                                    [financialNodeFG]="transferCalculationFG.controls.additional_expenses_sum"
                                    [highlightIfNegative]="true"
                                ></app-financial-node>
                            </div>
                            <div
                                class="w-10"
                                *ngIf="
                                    transferCalculationFG.controls.additional_expenses_sum &&
                                    transferCalculationFG.controls.additional_expenses_sum.controls.amount &&
                                    transferCalculationFG.controls.additional_expenses_sum.controls.amount.value
                                "
                            >
                                <div
                                    *ngIf="
                                        transferCalculationFG.controls.additional_expenses_sum.controls.details &&
                                        transferCalculationFG.controls.additional_expenses_sum.controls.details.length >
                                            0
                                    "
                                    class="arrow-button"
                                    (click)="isDetailsOpenedExpenses = !isDetailsOpenedExpenses"
                                >
                                    <mat-icon>{{
                                        isDetailsOpenedExpenses ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                    }}</mat-icon>
                                </div>
                                <div
                                    *ngIf="
                                        transferCalculationFG.controls.additional_expenses_sum.controls.details &&
                                        transferCalculationFG.controls.additional_expenses_sum.controls.details
                                            .length === 0
                                    "
                                    style="height: 24px"
                                >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'open'"
                                        matTooltip="To see details section in this deal please save it and edit it again"
                                        >help_outline</mat-icon
                                    >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'approved'"
                                        matTooltip="To see details section in this deal please disapprove it, save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                </div>
                            </div>
                        </div>
                        <app-financial-details
                            class="w-100"
                            *ngIf="
                                isDetailsOpenedExpenses &&
                                transferCalculationFG.controls.additional_expenses_sum &&
                                transferCalculationFG.controls.additional_expenses_sum.controls.details
                            "
                            [detailsFA]="transferCalculationFG.controls.additional_expenses_sum.controls.details"
                        ></app-financial-details>
                    </div>
                </div>

                <div
                    class="preview-row subtotal-section pr-36"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (transferCalculationFG.controls.net &&
                            transferCalculationFG.controls.net.controls.amount &&
                            transferCalculationFG.controls.net.controls.amount.value)
                    "
                >
                    <div class="w-55 label text-left">NET:</div>
                    <div class="w-45 text-right">
                        <app-financial-node
                            moneyClass="fee-value"
                            *ngIf="transferCalculationFG.controls.net"
                            [financialNodeFG]="transferCalculationFG.controls.net"
                            [highlightIfNegative]="true"
                        ></app-financial-node>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    `
})
export class TransferPreviewComponent {
    @Input() public transferCalculationFG: GenericFormGroup<TransferCalculation> = new GenericFormGroup(
        new TransferCalculation(),
        'change'
    );
    @Input() public dealSystemStatus: string = 'draft';

    isZeroValuesExpanded: boolean = false;

    isDetailsOpenedReferral: boolean = false;
    isDetailsOpenedIncomes: boolean = false;
    isDetailsOpenedExpenses: boolean = false;

    constructor(
        protected dealService: DealService,
        protected router: Router,
        protected dialog: MatDialog
    ) {}

    toggleCollapse() {
        this.isZeroValuesExpanded = !this.isZeroValuesExpanded;
    }
}
