import {Component, OnInit, OnDestroy} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {IContact, ILoan, IProduct} from '@cyberco-nodejs/zipi-typings';
import {Subject, Observable, of} from 'rxjs';
import {catchError, filter, map, shareReplay, take, takeUntil, tap} from 'rxjs/operators';
import {IFinanceState} from 'app/modules/finance/store/finance.reducer';
import {FetchContacts} from 'app/modules/contacts/store/contacts.actions';
import {INTEREST_RATE_TYPE, INTEREST_RATE_LIMIT_TYPE, INTEREST_PERIOD} from 'app/local-typings';
import {selectContacts, selectProducts} from 'app/store/root.selectors';
import {FetchProducts} from 'app/modules/finance/store/finance.actions';
import {NotificationsService} from 'angular2-notifications';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from '../../../../../services/session.service';
import {ContactCreateDialogComponent} from '../../../../contacts/contact-dialogs/contact-create-dialog/contact-create-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {GenericFormArray} from '../../../../../entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {RbacService} from '../../../../rbac/rbac.service';
import {LoansService} from '../../../../../services/api/finance/loans.service';
import {FormGroupWithFormControls} from '../../../../../typings/common';
import {currencyMaskitoOptions, unmaskCurrencyControlValue} from '../../../../../utilities/maskito';

@Component({
    selector: 'app-edit-advance-page',
    templateUrl: 'edit-advance-page.component.html',
    styleUrls: ['edit-advance-page.component.css']
})
export class EditAdvancePageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    contact$: Observable<IContact[]> | undefined;
    contactsMap: Map<number, IContact> | undefined;
    product$: Observable<IProduct[]> | undefined;
    companyGroupcontact: IContact | undefined;
    interestRateType = INTEREST_RATE_TYPE;
    interestRateLimitType = INTEREST_RATE_LIMIT_TYPE;
    interestPeriod = INTEREST_PERIOD;
    advance: ILoan | undefined;
    hasPayments: boolean = false;

    currencyMaskitoMask = currencyMaskitoOptions;

    formGroup: FormGroupWithFormControls = this.fb.group({
        date: [null, Validators.required],
        receiver__contact_fk_id: [null, Validators.required],
        product_fk_id: [null, Validators.required],
        amount: [null, Validators.required],

        interest_rate_type: ['flat', Validators.required],
        interest_rate_flat: [null, []],
        interest_rate_percent: [null, []],

        interest_rate_limit_type: ['none', Validators.required],
        interest_rate_limit_date: [null, []],
        interest_rate_limit_number: [null, []],

        interest_period: ['none', Validators.required],
        is_deduct_from_deals: [true, []]
    }) as FormGroupWithFormControls;

    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    savedContacts: Array<number> = [];

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store<IFinanceState>,
        private ntfs: NotificationsService,
        private route: ActivatedRoute,
        protected router: Router,
        public sessionService: SessionService,
        public loansService: LoansService,
        public dialog: MatDialog,
        protected rbacService: RbacService
    ) {}

    ngOnInit() {
        this.rbacService.isAllowed({lending__view_advances: true}).then((can) => {
            if (!can) {
                this.router.navigate(['/']);
            }
        });
        this.store.dispatch(new FetchContacts());
        this.store.dispatch(new FetchProducts());

        this.product$ = this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe));

        this.contact$ = this.store.pipe(
            select(selectContacts), // migrate to ContactsLite: done
            shareReplay(1),
            takeUntil(this.unsubscribe)
        );

        this.contact$
            .pipe(
                map((contacts) => {
                    const tup = contacts.map((c) => [c.id, c]);
                    this.companyGroupcontact = contacts.find(
                        (c) => c.id === this.sessionService.profile!.company!.company_group!.contact!.id
                    );
                    return new Map<number, IContact>(<any>tup);
                }),
                tap((contactsMap) => (this.contactsMap = contactsMap)),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.initEdit();

        this.moneySenderCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            if (values && values.length > 0) {
                // this.selectMoneySender(values[0].target_id);
                this.formGroup.controls.receiver__contact_fk_id.patchValue(values[0].target_id);
            } else {
                // this.deselectCustomer();
                this.formGroup.controls.receiver__contact_fk_id.patchValue(null);
            }
        });
    }

    initEdit() {
        this.formGroup.controls.amount.valueChanges
            .pipe(unmaskCurrencyControlValue(this.formGroup.controls.amount), takeUntil(this.unsubscribe))
            .subscribe();

        this.formGroup.controls.interest_rate_flat.valueChanges
            .pipe(unmaskCurrencyControlValue(this.formGroup.controls.interest_rate_flat), takeUntil(this.unsubscribe))
            .subscribe();

        this.formGroup.controls.interest_rate_type.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((interest_rate_type) => {
                if (interest_rate_type === 'flat') {
                    this.formGroup.controls.interest_rate_percent.disable();
                    this.formGroup.controls.interest_rate_flat.enable();
                } else if (interest_rate_type === 'percent') {
                    this.formGroup.controls.interest_rate_flat.disable();
                    this.formGroup.controls.interest_rate_percent.enable();
                }
            });

        this.formGroup.controls.interest_rate_limit_type.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((interest_rate_limit_type) => {
                if (interest_rate_limit_type === 'date') {
                    this.formGroup.controls.interest_rate_limit_number.disable();
                    this.formGroup.controls.interest_rate_limit_date.enable();
                } else if (interest_rate_limit_type === 'number_of_periods') {
                    this.formGroup.controls.interest_rate_limit_date.disable();
                    this.formGroup.controls.interest_rate_limit_number.enable();
                } else {
                    this.formGroup.controls.interest_rate_limit_date.disable();
                    this.formGroup.controls.interest_rate_limit_number.disable();
                }
            });

        this.route.paramMap
            .pipe(
                map((pm) => {
                    const stringId: string | null = pm.get('id');
                    return Number(stringId);
                }),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                if (id) {
                    this.loansService
                        .getLoanById(id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((advance: any) => {
                            this.advance = advance;
                            this.hasPayments = advance.balance < advance.amount;
                            this.formGroup.patchValue(advance);
                            if (advance.receiver__contact_fk_id) {
                                this.savedContacts = [advance.receiver__contact_fk_id];
                            }
                        });
                }
            });
    }

    createAdvance() {
        if (this.formGroup.invalid) {
            this.ntfs.warn('Advance Form is not valid');
        } else {
            const advance = this.formGroup.getRawValue();
            advance.type = 'advance';
            advance.sender__contact_fk_id = this.companyGroupcontact ? this.companyGroupcontact.contact_id : null;

            this.loansService
                .createLoan(advance)
                .pipe(
                    catchError((err) => of(null)),
                    takeUntil(this.unsubscribe)
                )
                .subscribe(() => {
                    this.ntfs.info(`Advance created`);
                    this.router.navigate(['/lending/advances']);
                });
        }
    }

    updateAdvance() {
        if (this.formGroup.invalid) {
            return;
        }

        this.loansService
            .updateLoan(Object.assign({}, this.advance, this.formGroup.getRawValue()))
            .pipe(
                catchError((err) => of(null)),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.ntfs.info(`Advance updated`);
                this.router.navigate(['/lending/advances']);
            });
    }

    deleteAdvance() {
        if (this.advance && this.advance.loan_id) {
            this.loansService
                .deleteLoan(this.advance.loan_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((res) => {
                    if (res) {
                        this.router.navigate(['/lending/advances']);
                    }
                });
        }
    }

    contactCreate() {
        const dialogRef = this.dialog.open(ContactCreateDialogComponent, {
            autoFocus: false,
            data: {
                category: 'customer'
            }
        });

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    selectProduct(product: IProduct) {
        if (product.price > 0 && !this.formGroup.controls.amount.value) {
            this.formGroup.controls.amount.patchValue(product.price);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
