<mat-button-toggle-group multiple="true" vertical="true" [value]="value" (change)="onChange($event)">
    <mat-button-toggle
        *ngFor="let item of list"
        matTooltip="Coming Soon"
        [matTooltipDisabled]="item.value === 'table'"
        [disabled]="item.value !== 'table'"
        [value]="item.value"
    >
        <div class="flex-row">
            <div class="but-toggle-item icon">
                <div>
                    <span *ngIf="value && value.length && value.includes(item.value)" class="material-icons">done</span>
                </div>
            </div>
            <div class="but-toggle-item title">
                <mat-icon>{{ item.icon }}</mat-icon>
                <span>{{ item.title }}</span>
            </div>
        </div>
    </mat-button-toggle>
</mat-button-toggle-group>
