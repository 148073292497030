import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';

@Component({
    selector: 'app-report-edit-bar-button-list',
    templateUrl: './button-list.component.html',
    styleUrls: ['./button-list.component.scss']
})
export class ButtonListComponent implements OnDestroy {
    @Output() valueChanges: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
    @Input() multiple: boolean = false;
    @Input() value: any = [];
    @Input() list: Array<{title: string; value: string | number | boolean; [key: string]: any}> = [];
    @Input() titleAttribute: string = 'title';
    @Input() valueAttribute: string = 'value';
    @Input() disabled: boolean = false;

    onChange(event: {value: Array<string>}) {
        this.value = event.value;
        this.valueChanges.emit(event.value);
    }

    ngOnDestroy() {
        this.valueChanges.complete();
    }
}
