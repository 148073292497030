import {Subject, Subscription, fromEvent} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {SelectionModel} from '@angular/cdk/collections';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

import {ITransactionExternal, ITransactionListItem} from '@cyberco-nodejs/zipi-typings';

import {TransactionsService} from 'app/services/api/finance/transactions.service';
import {AdjustmentDialogComponent} from '../adjustment-dialog/adjustment-dialog.component';
import {TransactionsExternalService} from 'app/services/api/finance/transactions-external.service';
import {takeUntil, throttleTime} from 'rxjs/operators';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import Decimal from 'decimal.js-light';

@Component({
    selector: 'app-banking-match',
    templateUrl: 'banking-match.component.html',
    styleUrls: ['../../banking.component.scss']
})
export class BankingMatchComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator = new MatPaginator(
        new MatPaginatorIntl(),
        ChangeDetectorRef.prototype
    );
    @ViewChild('matchButton', {static: true, read: ElementRef}) matchButton: ElementRef | undefined;

    @Output() closeSidebar: EventEmitter<void> = new EventEmitter<void>();
    @Output() loadExternalTransactions: EventEmitter<void> = new EventEmitter<void>();
    @Output() loadInternalTransactions: EventEmitter<void> = new EventEmitter<void>();

    @Input() bankTransactions: ITransactionExternal[] | undefined;

    private unsubscribe: Subject<void> = new Subject();

    dataSource: MatTableDataSource<ITransactionListItem>;
    recommendedDataSource: MatTableDataSource<ITransactionListItem>;
    selection = new SelectionModel<any>(true, []);
    adjustmentTransactions: any[] = [];

    displayedColumns: string[] = ['match'];

    ledgerAccountId: number | null = null;

    listLength: number = 0;
    pageIndex: number = 0;
    pageSize: number = 25;
    pageSizeOptions: number[] = [25, 50, 75, 100];
    pending: number = 0;

    matchSub: Subscription | undefined;

    searchFilter: UntypedFormControl = this.fb.control(null, []);

    constructor(
        private route: ActivatedRoute,
        private transactionsService: TransactionsService,
        private transactionsExternalService: TransactionsExternalService,
        public dialog: MatDialog,
        private fb: UntypedFormBuilder
    ) {
        this.dataSource = new MatTableDataSource<ITransactionListItem>([]);
        this.recommendedDataSource = new MatTableDataSource<ITransactionListItem>([]);

        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['id']) {
                this.ledgerAccountId = +params['id'];
            }
        });
    }

    ngOnInit() {
        this.loadTransactions();
        this.selection.changed.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.countPending();
        });

        this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.pageSize = data.pageSize;
            this.pageIndex = data.pageIndex;

            this.loadTransactions();
        });

        this.searchFilter.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.loadTransactions());
    }

    ngOnChanges(changes: any) {
        if (changes.hasOwnProperty('bankTransactions') && changes.bankTransactions.currentValue) {
            this.loadRecommendedTransactions(changes.bankTransactions.currentValue);
            this.countPending();
        }
    }

    getReferenceLink(
        tr: ITransactionListItem & {reason_type: string; related_entity_type: string; related_entity_id: number}
    ) {
        if (
            [
                'card_payment',
                'vendor_payment',
                'transfer_fund',
                'owner_drawings',
                'customer_payment',
                'deposit'
            ].includes(tr.reason_type) ||
            tr.related_entity_type === 'payment'
        ) {
            return;
        }

        switch (tr.reason_type) {
            case 'manual_journal':
                return `/company/finance/journals/${tr?.journal_fk_id}`;
                break;
            case 'opening_balance':
                return `/company/finance/openingbalances`;
                break;
        }

        switch (tr.related_entity_type) {
            case 'invoice':
                return `/sales/invoices/${tr?.related_entity_id}`;
                break;
            case 'bill':
                return `/purchases/bills/${tr?.related_entity_id}`;
                break;
            case 'payment_received':
                return `/sales/paymentsreceived/${tr?.related_entity_id}`;
                break;
            case 'payment_made':
                return `/purchases/payments/${tr?.related_entity_id}`;
                break;
            case 'expense':
                return `/purchases/expenses/${tr?.related_entity_id}`;
                break;
            case 'credit_note':
                return `/sales/creditnotes/${tr?.related_entity_id}`;
                break;
            case 'vendor_credit':
                return `/purchases/vendorcredits/${tr?.related_entity_id}`;
                break;
            case 'deposit_release':
                return `/deals/${tr?.context_entity_id}`;
                break;
        }
    }

    ngAfterViewInit() {
        this.matchSub = fromEvent(this.matchButton?.nativeElement, 'click')
            .pipe(throttleTime(1000), takeUntil(this.unsubscribe))
            .subscribe(() => this.match());
    }

    countPending() {
        if (this.bankTransactions && this.bankTransactions.length > 0) {
            let sum: Decimal = this.bankTransactions.reduce(
                (acc, tr) => (tr.debit_or_credit === 'debit' ? acc.add(tr.amount) : acc.sub(tr.amount)),
                new Decimal(0)
            );

            const transactionToMatch = this.selection.selected.concat(this.adjustmentTransactions);

            transactionToMatch.forEach((transaction) => {
                if (transaction.debit_or_credit === 'debit') {
                    sum = sum.sub(transaction.amount);
                } else {
                    sum = sum.add(transaction.amount);
                }
            });

            this.pending = sum.toDecimalPlaces(2).toNumber();
        }
    }

    loadTransactions() {
        if (this.bankTransactions && this.bankTransactions.length > 0) {
            const filters: {[key: string]: any} = {
                offset: this.pageSize * this.pageIndex,
                limit: this.pageSize
            };

            if (this.searchFilter.value && !/[^A-Za-z0-9\(\)\s.,-]+/.test(this.searchFilter.value)) {
                filters.search_filter = this.searchFilter.value.trim();
            }

            this.transactionsService
                .getPageNotMatchedTransactions(this.ledgerAccountId as number, filters)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((data) => {
                    this.listLength = data.count;
                    this.dataSource.data = data.transactions;
                });
        }
    }

    loadRecommendedTransactions(transactions: ITransactionExternal[]) {
        this.transactionsService
            .getRecommendedIntrnalTransactionsByLedgerAccountId(this.ledgerAccountId as number, {
                basis: 'both',
                external_transactions: transactions.map((tr) => tr.transaction_external_id)
            })
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.recommendedDataSource.data = data.transactions || [];
            });
    }

    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    isSelected(row: any) {
        return this.selection.selected.find((sel) => sel.id === row.id);
    }

    checked(row: any) {
        const found = this.selection.selected.find((x) => x.id === row.id);

        if (!found) {
            this.selection.select(row);
        }
    }

    unchecked(row: any) {
        const found = this.selection.selected.find((x) => x.id === row.id);

        if (found) {
            found.checked = false;
        }

        this.selection.deselect(found);
    }

    removeAdjustment(index: number) {
        this.adjustmentTransactions.splice(index, 1);

        return this.countPending();
    }

    match() {
        const transactionsIds = this.selection.selected
            .filter((jt) => !jt.adjustment_temp_id)
            .map((jt) => jt.transaction_id);
        const adjustmentTransactions = this.adjustmentTransactions.map((at) => {
            return {
                debit_or_credit: at.debit_or_credit,
                amount: at.amount,
                date: at.date,
                ledger_account_fk_id: at.ledger_account_fk_id
            };
        });

        if (this.bankTransactions && this.bankTransactions.length > 0) {
            this.transactionsExternalService
                .matchTransaction(this.bankTransactions[0].transaction_external_id, {
                    ledger_account_id: this.ledgerAccountId,
                    transactions_ids: transactionsIds,
                    adjustment_transactions: adjustmentTransactions,
                    external_transactions: this.bankTransactions
                })
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    this.loadTransactions();
                    this.loadExternalTransactions.emit();
                    this.loadInternalTransactions.emit();
                    this.closeSidebar.emit();
                });
        }
    }

    addAdjustment() {
        const dialogRef = this.dialog.open(AdjustmentDialogComponent, {
            width: '500px'
        });

        if (this.pending) {
            dialogRef.componentInstance.pending = this.pending;
        }
        //
        // if (this.pending && this.pending >= 0) {
        //     if (typeof this.bankTransaction !== 'undefined') {
        //         dialogRef.componentInstance.type = this.bankTransaction.debit_or_credit;
        //     }
        // } else {
        //     if (typeof this.bankTransaction !== 'undefined') {
        //         dialogRef.componentInstance.type = this.bankTransaction.debit_or_credit === 'debit' ?
        //             'credit' : 'debit';
        //     }
        // }

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((newAdjustment) => {
                if (!newAdjustment) {
                    return;
                }

                this.adjustmentTransactions.push(newAdjustment);
                this.countPending();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.closeSidebar.complete();
        this.loadExternalTransactions.complete();
        this.loadInternalTransactions.complete();
    }
}
