import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {RbacModule} from '../../rbac/rbac.module';
import {SharedModule} from '../../shared/shared.module';
import {IndexComponent} from './pages/index.component';
import {NewComponent} from './common/new.component';
import {EditComponent} from './common/edit/edit.component';
import {GroupsListItemAllComponent} from './common/all-list-item.component';
import {OrgChartSettingsDialogComponent} from './common/settings-dialog.component';
import {Tab1OrgChartComponent} from './pages/tab1-org-chart.component';
import {GroupsApi} from '../groups/groups.service';
import {AccountInfoToolbarModule} from '../account-info-toolbar/account-info-toolbar.module';

@NgModule({
    declarations: [
        OrgChartSettingsDialogComponent,
        Tab1OrgChartComponent,
        NewComponent,
        EditComponent,
        GroupsListItemAllComponent,
        // pages
        IndexComponent
    ],
    imports: [
        RbacModule,
        SharedModule,
        RouterModule,
        AccountInfoToolbarModule
        // routing,
    ],
    providers: [GroupsApi],
    exports: [BrowserModule],
    bootstrap: [IndexComponent]
})
export class DivisionsModule {}
