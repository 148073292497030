<section class="container-fluid pb-4">
    <header class="py-2">
        <h1 *ngIf="!openingBalanceDate" class="">Create Opening Balances</h1>
        <h1 *ngIf="openingBalanceDate" class="">Edit Opening Balances</h1>
    </header>

    <div class="col-12 card-opening-balances">
        <div class="row opening-header-block">
            <div class="col-6">
                <div class="row-inner">
                    <app-date-picker
                        [matTooltip]="'One or more Ledger Accounts have a reconciliation'"
                        [matTooltipDisabled]="!isLockedItemExist"
                        [placeholder]="'Opening Balances Date'"
                        [dateControl]="formGroup!.controls.date"
                        [disabled]="isLockedItemExist"
                    ></app-date-picker>
                </div>
            </div>
        </div>
        <div class="row opening-header-block">
            <div class="col-12">
                <div class="row-inner">
                    * Add all your banks, foreign currency banks and credit card accounts in the Banking module of
                    SkySlope Books. Once you add them, they will be listed under this section where you can enter their
                    opening balances.
                </div>
                <div class="row-inner">
                    * Use the Trial Balance report from your previous accounting system to enter the balances for all
                    accounts. Ensure that the balances are entered as on the date of migration.
                </div>
            </div>
        </div>

        <table class="table">
            <thead class="bg-dark text-white">
                <tr>
                    <td style="width: 5%" scope="col">Code</td>
                    <td style="width: 50%" scope="col">Account</td>
                    <td style="width: 15%; text-align: center" scope="col">Zero Balance</td>
                    <td style="width: 15%" scope="col">Debit (USD)</td>
                    <td style="width: 15%" scope="col">Credit (USD)</td>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let itemGroup of itemsArray.controls; index as idx">
                    <td [formGroup]="itemGroup" class="name-td">
                        {{ itemGroup.controls.code.value }}
                    </td>
                    <td [formGroup]="itemGroup" class="name-td">
                        {{ itemGroup.controls.name.value }}
                        <span *ngIf="itemGroup.controls.status.value === 'inactive'" style="font-weight: bold">
                            (Deactivated)</span
                        >
                    </td>

                    <td [formGroup]="itemGroup" class="name-td" style="text-align: center">
                        <mat-checkbox
                            [checked]="itemGroup.controls.zero_balance.value"
                            (change)="changeZeroBalance($event.checked, itemGroup)"
                            [disabled]="itemGroup.controls.is_locked.value"
                        ></mat-checkbox>
                    </td>
                    <td [formGroup]="itemGroup">
                        <mat-form-field class="w-100" [style.fontSize.px]="13">
                            <div
                                [matTooltipDisabled]="!itemGroup.controls.is_locked.value"
                                matTooltip="This balance is used in the reconciliation entry"
                            >
                                <input matInput formControlName="debit" type="text" />
                            </div>
                        </mat-form-field>
                    </td>
                    <td [formGroup]="itemGroup">
                        <mat-form-field class="w-100" [style.fontSize.px]="13">
                            <div
                                [matTooltipDisabled]="!itemGroup.controls.is_locked.value"
                                matTooltip="This balance is used in the reconciliation entry"
                            >
                                <input matInput formControlName="credit" type="text" />
                            </div>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td style="text-align: right; font-weight: bold" colspan="3">Total</td>
                    <td style="font-weight: bold">{{ ledgerAccountsData.debit_total.toFixed(2) }}</td>
                    <td style="font-weight: bold">{{ ledgerAccountsData.credit_total.toFixed(2) }}</td>
                </tr>
                <tr>
                    <td style="text-align: right; font-weight: bold" colspan="3">Opening Balance Adjustments</td>
                    <td style="font-weight: bold">{{ ledgerAccountsData.debit_adjustments.toFixed(2) }}</td>
                    <td style="font-weight: bold">{{ ledgerAccountsData.credit_adjustments.toFixed(2) }}</td>
                </tr>
                <tr>
                    <td style="text-align: right; font-weight: bold" colspan="3">TOTAL AMOUNT</td>
                    <td style="font-weight: bold">{{ ledgerAccountsData.debit_total_amount.toFixed(2) }}</td>
                    <td style="font-weight: bold">{{ ledgerAccountsData.credit_total_amount.toFixed(2) }}</td>
                </tr>
            </tbody>
        </table>

        <div class="d-flex">
            <span class="sp-spacer"></span>

            <button mat-raised-button class="ml-2" routerLink="/company/finance/openingbalances">Cancel</button>

            <button
                *ngIf="!openingBalanceDate"
                type="!submit"
                class="ml-2"
                mat-raised-button
                color="primary"
                [disabled]="isButtonDisabled"
                (click)="saveOpeningBalances()"
            >
                Create
            </button>

            <button
                *ngIf="openingBalanceDate"
                type="!submit"
                class="ml-2"
                mat-raised-button
                color="primary"
                [disabled]="isButtonDisabled"
                (click)="updateOpeningBalances()"
            >
                Save
            </button>
        </div>
    </div>
</section>
