import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {GenericFormGroup} from '../../../../../../entites/generic.entity';
import {CompanyCalculation} from '../../../../../shared/components/calculations/calculations.model';

@Component({
    selector: 'app-company-preview',
    styleUrls: ['preview.component.scss'],
    template: `
        <mat-card class="wide-card preview-card">
            <mat-card-title
                (click)="toggleCollapse()"
                style="font-size: 15px; align-items: center;display: flex;cursor: pointer;"
            >
                Preview
                <mat-icon>
                    {{ isZeroValuesExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                </mat-icon>
            </mat-card-title>

            <mat-card-content class="full-width">
                <!------- Company Split ------->
                <div
                    class="preview-row subtotal-section"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (companyCalculationFG.controls.company_split &&
                            companyCalculationFG.controls.company_split.controls.amount &&
                            companyCalculationFG.controls.company_split.controls.amount.value)
                    "
                >
                    <div class="w-100 node-with-details">
                        <div class="w-55 label text-left">Company Split:</div>
                        <div class="w-35 text-right">
                            <app-financial-node
                                moneyClass="fee-value"
                                *ngIf="companyCalculationFG.controls.company_split"
                                [financialNodeFG]="companyCalculationFG.controls.company_split"
                                [highlightIfNegative]="true"
                            ></app-financial-node>
                        </div>
                        <div
                            class="w-10 ml-1"
                            *ngIf="
                                companyCalculationFG.controls.company_split &&
                                companyCalculationFG.controls.company_split.controls.amount &&
                                companyCalculationFG.controls.company_split.controls.amount.value
                            "
                        >
                            <div
                                class="arrow-button"
                                *ngIf="
                                    companyCalculationFG.controls.company_split.controls.details &&
                                    companyCalculationFG.controls.company_split.controls.details.length > 0
                                "
                                (click)="isDetailsOpenedCompanySplit = !isDetailsOpenedCompanySplit"
                            >
                                <mat-icon>{{
                                    isDetailsOpenedCompanySplit ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                }}</mat-icon>
                            </div>
                            <div
                                *ngIf="
                                    companyCalculationFG.controls.company_split.controls.details &&
                                    companyCalculationFG.controls.company_split.controls.details.length === 0
                                "
                                style="height: 24px"
                            >
                                <mat-icon
                                    *ngIf="dealSystemStatus === 'open'"
                                    matTooltip="To see details section in this deal please save it and edit it again"
                                    >help_outline</mat-icon
                                >
                                <mat-icon
                                    *ngIf="dealSystemStatus === 'approved'"
                                    matTooltip="To see details section in this deal please disapprove it, save it and edit it again."
                                    >help_outline</mat-icon
                                >
                            </div>
                        </div>
                    </div>
                    <app-financial-details
                        class="w-100"
                        *ngIf="
                            isDetailsOpenedCompanySplit &&
                            companyCalculationFG.controls.company_split &&
                            companyCalculationFG.controls.company_split.controls.details
                        "
                        [detailsFA]="companyCalculationFG.controls.company_split.controls.details"
                    ></app-financial-details>
                </div>

                <!------- Company Income ------->
                <div
                    class="preview-row subtotal-section"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (companyCalculationFG.controls.company_incomes_sum &&
                            companyCalculationFG.controls.company_incomes_sum.controls.amount &&
                            companyCalculationFG.controls.company_incomes_sum.controls.amount.value) ||
                        (companyCalculationFG.controls.company_expenses_sum &&
                            companyCalculationFG.controls.company_expenses_sum.controls.amount &&
                            companyCalculationFG.controls.company_expenses_sum.controls.amount.value)
                    "
                >
                    <div
                        class="sub-row column"
                        *ngIf="
                            isZeroValuesExpanded ||
                            (companyCalculationFG.controls.company_incomes_sum &&
                                companyCalculationFG.controls.company_incomes_sum.controls.amount &&
                                companyCalculationFG.controls.company_incomes_sum.controls.amount.value)
                        "
                    >
                        <div class="w-100 node-with-details">
                            <div class="w-55 label text-left ">Company Income:</div>
                            <div class="w-35 text-right">
                                <app-financial-node
                                    moneyClass="fee-value positive"
                                    *ngIf="companyCalculationFG.controls.company_incomes_sum"
                                    [financialNodeFG]="companyCalculationFG.controls.company_incomes_sum"
                                    [highlightIfNegative]="true"
                                ></app-financial-node>
                            </div>
                            <div
                                class="w-10 ml-1"
                                *ngIf="
                                    companyCalculationFG.controls.company_incomes_sum &&
                                    companyCalculationFG.controls.company_incomes_sum.controls.amount &&
                                    companyCalculationFG.controls.company_incomes_sum.controls.amount.value
                                "
                            >
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.company_incomes_sum.controls.details &&
                                        companyCalculationFG.controls.company_incomes_sum.controls.details.length > 0
                                    "
                                    class="arrow-button"
                                    (click)="isDetailsOpenedIncomes = !isDetailsOpenedIncomes"
                                >
                                    <mat-icon>{{
                                        isDetailsOpenedIncomes ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                    }}</mat-icon>
                                </div>
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.company_incomes_sum.controls.details &&
                                        companyCalculationFG.controls.company_incomes_sum.controls.details.length === 0
                                    "
                                    style="height: 24px"
                                >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'open'"
                                        matTooltip="To see details section in this deal please save it and edit it again"
                                        >help_outline</mat-icon
                                    >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'approved'"
                                        matTooltip="To see details section in this deal please disapprove it, save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                </div>
                            </div>
                        </div>
                        <app-financial-details
                            class="w-100"
                            *ngIf="
                                isDetailsOpenedIncomes &&
                                companyCalculationFG.controls.company_incomes_sum &&
                                companyCalculationFG.controls.company_incomes_sum.controls.details
                            "
                            [detailsFA]="companyCalculationFG.controls.company_incomes_sum.controls.details"
                        ></app-financial-details>
                    </div>

                    <!------- Company Expense ------->
                    <div
                        class="sub-row column"
                        *ngIf="
                            isZeroValuesExpanded ||
                            (companyCalculationFG.controls.company_expenses_sum &&
                                companyCalculationFG.controls.company_expenses_sum.controls.amount &&
                                companyCalculationFG.controls.company_expenses_sum.controls.amount.value)
                        "
                    >
                        <div class="w-100 node-with-details">
                            <div class="w-55 label text-left">Company Expense:</div>

                            <div class="w-35 text-right">
                                <app-financial-node
                                    moneyClass="fee-value negative"
                                    *ngIf="companyCalculationFG.controls.company_expenses_sum"
                                    [financialNodeFG]="companyCalculationFG.controls.company_expenses_sum"
                                    [highlightIfNegative]="true"
                                ></app-financial-node>
                            </div>
                            <div
                                class="w-10"
                                *ngIf="
                                    companyCalculationFG.controls.company_expenses_sum &&
                                    companyCalculationFG.controls.company_expenses_sum.controls.amount &&
                                    companyCalculationFG.controls.company_expenses_sum.controls.amount.value
                                "
                            >
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.company_expenses_sum.controls.details &&
                                        companyCalculationFG.controls.company_expenses_sum.controls.details.length > 0
                                    "
                                    class="arrow-button"
                                    (click)="isDetailsOpenedExpenses = !isDetailsOpenedExpenses"
                                >
                                    <mat-icon>{{
                                        isDetailsOpenedExpenses ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                    }}</mat-icon>
                                </div>
                                <div
                                    *ngIf="
                                        companyCalculationFG.controls.company_expenses_sum.controls.details &&
                                        companyCalculationFG.controls.company_expenses_sum.controls.details.length === 0
                                    "
                                    style="height: 24px"
                                >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'open'"
                                        matTooltip="To see details section in this deal please save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'approved'"
                                        matTooltip="To see details section in this deal please disapprove it, save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                </div>
                            </div>
                        </div>
                        <app-financial-details
                            class="w-100"
                            *ngIf="
                                isDetailsOpenedExpenses &&
                                companyCalculationFG.controls.company_expenses_sum &&
                                companyCalculationFG.controls.company_expenses_sum.controls.details
                            "
                            [detailsFA]="companyCalculationFG.controls.company_expenses_sum.controls.details"
                        ></app-financial-details>
                    </div>
                </div>

                <!------- Company NET ------->
                <div
                    class="preview-row subtotal-section pr-36"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (companyCalculationFG.controls.company_net &&
                            companyCalculationFG.controls.company_net.controls.amount &&
                            companyCalculationFG.controls.company_net.controls.amount.value)
                    "
                >
                    <div class="w-55 label text-left">Company NET:</div>
                    <div class="w-45 text-right">
                        <app-financial-node
                            moneyClass="fee-value"
                            *ngIf="companyCalculationFG.controls.company_net"
                            [financialNodeFG]="companyCalculationFG.controls.company_net"
                            [highlightIfNegative]="true"
                        ></app-financial-node>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    `
})
export class CompanyPreviewComponent {
    @Input() public isGroup: boolean = false;
    @Input() public companyCalculationFG: GenericFormGroup<CompanyCalculation> = new GenericFormGroup(
        new CompanyCalculation(),
        'change'
    );
    @Input() public dealSystemStatus: string = 'draft';

    isZeroValuesExpanded: boolean = false;

    isDetailsOpenedCompanySplit: boolean = false;
    isDetailsOpenedIncomes: boolean = false;
    isDetailsOpenedExpenses: boolean = false;

    constructor(
        protected router: Router,
        protected dialog: MatDialog
    ) {}

    toggleCollapse() {
        this.isZeroValuesExpanded = !this.isZeroValuesExpanded;
    }
}
