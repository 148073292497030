<div style="width: 302px; height: 452px">
    <div class="w-100" style="text-align: center">Select your account</div>
    <h3 class="w-100 my-2" style="text-align: center">{{ institutionName || 'No Institution Title' }}</h3>

    <div style="overflow-y: auto; height: 70%; padding: 0; box-shadow: 3px 5px 10px #f5f5f5">
        <ng-container *ngFor="let account of filteredBankAccounts">
            <div
                *ngIf="
                    ['depository', 'credit'].includes(account.type) &&
                    ['checking', 'savings', 'credit card'].includes(account.subtype) &&
                    (account.subtype !== 'credit card' || !isTrustAccount)
                "
                class="p-2 pointer"
                [ngStyle]="
                    selectedAccount?.id === account.id ? {border: '1px solid #e6e5e5'} : {border: '1px solid #f5f5f5'}
                "
            >
                <div class="d-flex justify-content-between w-100 align-items-center" (click)="selectAccount(account)">
                    <div>
                        <div class="font-weight-bold">
                            {{ account.name }}
                        </div>
                        <div style="font-size: 12px" class="pl-1">{{ account.subtype }} ( {{ account.mask }} )</div>
                    </div>
                    <div>
                        <div>
                            <mat-icon style="color: green" *ngIf="selectedAccount?.id === account.id"
                                >check_circle_outline</mat-icon
                            >
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>

            <div
                *ngIf="
                    ['depository', 'credit'].includes(account.type) &&
                    ['checking', 'savings', 'credit card'].includes(account.subtype) &&
                    account.subtype === 'credit card' &&
                    isTrustAccount
                "
                class="p-2"
                style="background-color: #e9e9e9; color: grey"
            >
                <div class="d-flex justify-content-between w-100 align-items-center">
                    <div>
                        <div class="font-weight-bold">
                            {{ account.name }}
                        </div>
                        <div style="font-size: 12px" class="pl-1">{{ account.subtype }} ( {{ account.mask }} )</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="d-flex justify-content-end w-100 mt-4">
        <button
            mat-raised-button
            class="mr-2"
            color="accent"
            [disabled]="!selectedAccount"
            (click)="continue()"
            tabindex="1"
        >
            Continue
        </button>
        <button mat-raised-button class="mr-2" (click)="close()" tabindex="-1">Cancel</button>
    </div>
</div>
