import {Component, Inject, OnDestroy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';

@Component({
    selector: 'app-invoice-bill-modal-dialog',
    styles: [
        `
            .full-screen-modal .mat-dialog-container {
                max-width: none;
            }
        `
    ],
    templateUrl: 'invoice-bill-modal-dialog.component.html'
})
export class InvoiceBillModalDialogComponent implements OnDestroy {
    isEditMode: boolean = false;

    private _unsubscribe: Subject<void> = new Subject();

    constructor(
        public dialogRef: MatDialogRef<InvoiceBillModalDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router
    ) {
        this.router.events.pipe(takeUntil(this._unsubscribe)).subscribe((event) => {
            this.dialogRef.close();
        });
    }

    toggleMode() {
        this.isEditMode = !this.isEditMode;
    }

    ngOnDestroy() {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }
}
