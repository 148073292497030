import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {routing} from './dashboard.routing';
import {BoardComponent} from './components/board/board.components';
import {IndexComponent} from './components/index/index.component';
import {WidgetService} from './services/widget.service';
import {BaseWidgetComponent} from './components/board/widgets/base.widget.component';
import {BoardSettingsComponent} from './components/board/settings/board-settings.component';
import {BoardService} from './services/board.service';
import {AddBoardComponent} from './components/add-board/add-board.component';
import {DecideWidgetComponent} from './components/board/widgets/decide-widget/decide-widget.component';

import {AvgPriceWidgetComponent} from './components/board/widgets/avg-price/avg-price.widget.component';
import {AvgCommissionWidgetComponent} from './components/board/widgets/avg-commission/avg-commission.widget.component';
import {SourceOfBusinessWidgetComponent} from './components/board/widgets/sob/sob.widget.component';
import {IncomeWidgetComponent} from './components/board/widgets/income/income.widget.component';
import {ProductionWidgetComponent} from './components/board/widgets/production/production.widget.component';
import {StatusWidgetComponent} from './components/board/widgets/status/status.widget.component';
import {CapCommissionStructureWidgetComponent} from './components/board/widgets/cap-commission-structure/cap-commission-structure.widget.component';
import {CompensationProfilePickerComponent} from './components/board/components/compensation-profile-picker/compensation-profile-picker.component';

import {SharedModule} from '../shared/shared.module';
import {RbacModule} from '../rbac/rbac.module';
import {MatInputModule} from '@angular/material/input';
import {AddWidgetComponent} from './components/board/widgets/add-widget/add.widget.component';
import {NoWidgetsBoardPipe} from './pipes/no-widgets-board.pipe';
import {WidgetSource} from './source/widget.source';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        // MaterialModule,
        ReactiveFormsModule,
        SharedModule,
        MatInputModule,
        RbacModule,
        routing
    ],
    exports: [NoWidgetsBoardPipe],
    declarations: [
        IndexComponent,
        BoardComponent,
        BaseWidgetComponent,
        BoardSettingsComponent,
        AddBoardComponent,
        AddWidgetComponent,
        DecideWidgetComponent,
        StatusWidgetComponent,
        SourceOfBusinessWidgetComponent,
        AvgCommissionWidgetComponent,
        AvgPriceWidgetComponent,
        IncomeWidgetComponent,
        ProductionWidgetComponent,
        NoWidgetsBoardPipe,
        CapCommissionStructureWidgetComponent,
        CompensationProfilePickerComponent
    ],
    providers: [WidgetService, BoardService, WidgetSource],
    bootstrap: [IndexComponent]
})
export class DashboardModule {}
