import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {routing} from './profile.routing';

import {BrowserModule} from '@angular/platform-browser';
import {IndexComponent} from './index/index.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {RbacModule} from '../rbac/rbac.module';
import {SharedModule} from '../shared/shared.module';
import {AddonsComponent} from './addons/addons.component';
import {AddonsSource} from './addons/addons.source';
import {AddonsApi} from './addons/addons.api';
import {AddonDeactivateConfirmationDialogComponent} from './addons/addon-deactivate-confirmation.dialog';
import {AccountInfoModule} from '../account-info/account-info.module';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        RbacModule,
        SharedModule,
        AccountInfoModule,
        routing
    ],
    exports: [BrowserModule],
    declarations: [IndexComponent, AddonsComponent, AddonDeactivateConfirmationDialogComponent],
    providers: [AddonsSource, AddonsApi],
    bootstrap: [IndexComponent]
})
export class ProfileModule {}
