<section class="container-fluid">
    <header class="my-2">
        <h1 class="" *ngIf="!expense">Create Expense</h1>
        <h1 class="" *ngIf="expense">Edit Expense</h1>
    </header>

    <div [formGroup]="formGroup" class="mb-3">
        <div class="row mr-0">
            <app-date-picker
                class="col col-sm-6"
                [placeholder]="'Date'"
                [dateControl]="formGroup.controls.expense_date"
            ></app-date-picker>
        </div>

        <div class="row mr-0">
            <div class="col col-md-6">
                <app-ledger-account-selector
                    class="w-100"
                    [ledgerAccountControl]="formGroup.controls.expense__ledger_account_fk_id"
                    [placeholder]="'Expense Account'"
                >
                </app-ledger-account-selector>
            </div>

            <div class="col col-md-6">
                <mat-form-field class="w-100">
                    <input
                        matInput
                        formControlName="amount"
                        [maskito]="currencyMaskitoMask"
                        placeholder="Amount"
                        type="text"
                    />
                </mat-form-field>
            </div>
        </div>

        <div class="row mr-0">
            <div class="col col-md-6">
                <app-company-compensation-combined-picker
                    style="width: 100%; margin-right: 8px"
                    [availableTypes]="['contact']"
                    [title]="'Vendor Name'"
                    [only_compensation_groups]="false"
                    [singleSelected]="true"
                    [bold_style]="false"
                    [nodesFA]="moneySenderCtrlArr"
                    [initialContactIds]="savedContacts"
                >
                </app-company-compensation-combined-picker>
            </div>

            <div class="col col-md-6">
                <app-ledger-account-selector
                    class="w-100"
                    [ledgerAccountControl]="formGroup.controls.paid_through__ledger_account_fk_id"
                    [placeholder]="'Paid Through'"
                >
                </app-ledger-account-selector>
            </div>
        </div>

        <mat-form-field class="w-100">
            <textarea matInput cdkTextareaAutosize formControlName="expense_note" placeholder="Notes"> </textarea>
        </mat-form-field>

        <div>
            <mat-slide-toggle [formControl]="formGroup.controls.apply_credit" class="align-self-center">
                Apply Credit
            </mat-slide-toggle>

            <div class="mb-4" *ngIf="formGroup.controls.apply_credit.value">
                <section [formGroup]="creditFormGroup">
                    <div class="row">
                        <div class="col-3 mr-2">
                            <mat-form-field>
                                <input
                                    matInput
                                    formControlName="vendor_credit_number"
                                    placeholder="Vendor#"
                                    type="text"
                                />
                            </mat-form-field>
                        </div>
                        <div class="col-3 mr-2">
                            <mat-form-field>
                                <input matInput formControlName="reference" placeholder="Reference#" type="text" />
                            </mat-form-field>
                        </div>
                    </div>
                    <!------- Items Header ------->
                    <h3 class="d-flex align-items-center">
                        Items
                        <button
                            type="button"
                            mat-icon-button
                            (click)="addItem()"
                            matTooltip="Add item"
                            *ngIf="!vendorCredit"
                        >
                            <mat-icon>add</mat-icon>
                        </button>
                    </h3>
                    <!------- Items ------->
                    <div class="overflow-auto">
                        <div
                            class="d-grid-items-container"
                            *ngFor="let itemGroup of itemsArray.controls; index as idx; first as first"
                        >
                            <div
                                [formGroup]="itemGroup"
                                [ngClass]="vendorCredit && groupList.length ? 'd-grid-items-vendor' : 'd-grid-items'"
                            >
                                <!------- Product/Service ------->
                                <div class="mx-2">
                                    <ng-container *ngIf="!itemGroup.controls.product_fk_id.value">
                                        <mat-form-field class="w-100">
                                            <input
                                                formControlName="name"
                                                type="text"
                                                matInput
                                                placeholder="Product item"
                                                [matAutocomplete]="auto"
                                            />
                                            <mat-autocomplete #auto="matAutocomplete">
                                                <mat-option (click)="$event.stopPropagation(); createProduct()"
                                                    >Create New Product
                                                </mat-option>

                                                <ng-container *ngFor="let product of getProducts(itemGroup)">
                                                    <mat-option
                                                        *ngIf="
                                                            product.status === 'active' ||
                                                            itemGroup.controls.product_fk_id.value ===
                                                                product.product_id
                                                        "
                                                        [value]="product.name"
                                                        (onSelectionChange)="selectItem(itemGroup, product)"
                                                    >
                                                        {{ product.name }}
                                                    </mat-option>
                                                </ng-container>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngIf="itemGroup.controls.product_fk_id.value">
                                        <mat-form-field class="w-100">
                                            <input formControlName="name" matInput type="text" />
                                            <button
                                                mat-button
                                                matSuffix
                                                mat-icon-button
                                                aria-label="Clear"
                                                [disabled]="!!vendorCredit"
                                                (click)="removeProductFkId(itemGroup)"
                                            >
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </ng-container>
                                </div>
                                <!------- Description ------->
                                <div class="mx-2">
                                    <mat-form-field class="w-100">
                                        <input
                                            matInput
                                            formControlName="description"
                                            placeholder="Description"
                                            type="text"
                                        />
                                    </mat-form-field>
                                </div>
                                <!------- Quantity ------->
                                <div class="mx-2">
                                    <mat-form-field class="w-100">
                                        <input
                                            matInput
                                            formControlName="quantity"
                                            placeholder="Quantity"
                                            type="number"
                                            min="1"
                                        />
                                    </mat-form-field>
                                </div>
                                <!------- Rate ------->
                                <div class="mx-2">
                                    <mat-form-field class="w-100">
                                        <input
                                            matInput
                                            formControlName="rate"
                                            placeholder="Rate"
                                            [maskito]="currencyMaskitoMask"
                                        />
                                    </mat-form-field>
                                </div>
                                <!------- Amount ------->
                                <div class="mx-2">
                                    <mat-form-field class="w-100">
                                        <input
                                            matInput
                                            formControlName="amount"
                                            placeholder="Amount"
                                            type="text"
                                            [maskito]="currencyMaskitoMask"
                                        />
                                    </mat-form-field>
                                </div>
                                <!------- Ledger Account ------->
                                <div class="mx-2">
                                    <app-ledger-account-selector
                                        class="w-100"
                                        [ledgerAccountControl]="itemGroup.controls.ledger_account_fk_id"
                                        [disabledReselect]="!!itemGroup.controls.product_fk_id.value || vendorCredit"
                                        [placeholder]="'Account'"
                                    >
                                    </app-ledger-account-selector>
                                </div>
                                <!------- Assign to Division ------->
                                <app-combined-entity-picker
                                    *ngIf="vendorCredit && groupList.length"
                                    class="mx-2"
                                    [availableTypes]="['company_group']"
                                    [title]="'Assign to Division'"
                                    [only_compensation_groups]="false"
                                    [onlyDivisions]="true"
                                    [initialGroupIds]="[itemGroup.controls.division__company_group_fk_id.value]"
                                    [nodesFA]="itemGroup.controls.selected_divisions"
                                    [singleSelected]="true"
                                    [bold_style]="false"
                                    [disableUpdate]="true"
                                    [seemDisabled]="true"
                                ></app-combined-entity-picker>
                            </div>
                            <!------- Actions ------->
                            <div class="actions" *ngIf="!vendorCredit">
                                <button
                                    type="button"
                                    mat-icon-button
                                    matTooltip="Clear Item"
                                    *ngIf="itemsArray.length === 1"
                                    (click)="resetItem(itemGroup)"
                                >
                                    <mat-icon>close</mat-icon>
                                </button>
                                <button
                                    type="button"
                                    mat-icon-button
                                    matTooltip="Remove Item"
                                    *ngIf="itemsArray.length > 1"
                                    (click)="itemsArray.removeAt(idx)"
                                >
                                    <mat-icon>close</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <div class="d-flex flex-column">
            <div class="d-flex justify-content-end align-items-baseline">
                <span class="mr-2">Total amount: &nbsp;</span>
                <strong class="zp-total-amount">{{ formGroup.controls.amount.value }}</strong>
            </div>
            <div class="d-flex justify-content-end align-items-baseline">
                <span class="mr-2">Credits amount: &nbsp;</span>
                <strong class="zp-total-amount">{{ formGroup.controls.credits_amount.value || 0 | currency }}</strong>
            </div>
        </div>

        <div class="d-flex mt-2">
            <button
                *ngIf="expense"
                type="!submit"
                mat-raised-button
                color="warn"
                [disabled]="isButtonDisabled"
                (click)="deleteExpense()"
            >
                Delete
            </button>

            <span class="sp-spacer"></span>

            <button *ngIf="!expense" mat-raised-button class="ml-2" routerLink="/purchases/expenses">Cancel</button>
            <button
                *ngIf="expense"
                mat-raised-button
                class="ml-2"
                [routerLink]="['/purchases/expenses/', expense.expense_id]"
            >
                Cancel
            </button>

            <button
                *ngIf="!expense"
                type="!submit"
                class="ml-2"
                mat-raised-button
                color="primary"
                [disabled]="isButtonDisabled"
                (click)="createExpense()"
            >
                Create
            </button>
            <button
                *ngIf="expense"
                type="!submit"
                class="ml-2"
                mat-raised-button
                color="primary"
                [disabled]="isButtonDisabled"
                (click)="editExpense()"
            >
                Save
            </button>
        </div>
    </div>
</section>
