<div mat-dialog-title>
    <h3>Success!</h3>
</div>

<mat-dialog-content>
    <div class="mb-1">You'll be redirected to the Login page to authorize in SkySlope Books.</div>
    <div class="mb-3">
        Redirect in <span style="color: blue">{{ counter }}</span> seconds
    </div>
    <div class="d-flex w-100 justify-content-center" (click)="goToLogin()"><a href="javascript:">Go to Login</a></div>
</mat-dialog-content>
