import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Board} from '../../../../../../models/board';
import {Widget} from '../../../../../../models/widget';
import {BaseWidgetComponent} from '../base.widget.component';
import {WidgetService} from '../../../../services/widget.service';
import {SessionService} from '../../../../../../services/session.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AuthService} from '../../../../../../services/auth.service';
import {RbacService} from '../../../../../rbac/rbac.service';
import {WidgetSource} from '../../../../source/widget.source';

@Component({
    selector: 'app-add-widget',
    templateUrl: `add.widget.component.html`,
    styleUrls: ['../widgets.scss', './add-widget.scss']
})
export class AddWidgetComponent extends BaseWidgetComponent {
    @Input() board: Board | null = null;
    @Output() widgetCreated = new EventEmitter();

    protected pseudoWidget: Widget | null = null;

    public addWidgetMode: 'button' | 'form' = 'button';

    public model = {
        name: '',
        type: ''
    };

    @Input() public customizeVisibility: boolean = false;

    constructor(
        protected widgetService: WidgetService,
        public sessionService: SessionService,
        protected dialog: MatDialog,
        protected router: Router,
        protected authService: AuthService,
        protected rbacService: RbacService,
        protected widgetSource: WidgetSource
    ) {
        super(widgetService, sessionService, dialog, router, authService, rbacService, widgetSource);
    }

    changeMode() {
        if (this.addWidgetMode === 'button') {
            this.addWidgetMode = 'form';
        } else if (this.addWidgetMode === 'form') {
            this.addWidgetMode = 'button';
        }
    }

    addPseudoWidget() {
        this.pseudoWidget = new Widget();
        if (this.sessionService.profile) {
            this.pseudoWidget.setupBackendSettings([this.sessionService.profile]);
        } else {
            this.pseudoWidget.setupBackendSettings([]);
        }

        this.pseudoWidget.type = this.model.type;
        this.pseudoWidget['board_id'] = Number(this.board!.id);
        this.widgetsList.splice(1, 0, this.pseudoWidget);
        this.changeWidgetList.emit();

        this.model.type = '';
    }
}
