import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {GenericFormGroup} from '../../../../../entites/generic.entity';
import {FinancialTransferEntity} from '../../models/financial-transfer.entity';
import {DisbursementTemplateModel} from '../../models/disbursement-template.model';
import {ContactPartLink} from '../../../../../models/contact-part-link';
import {FinancialElementModel} from '../../models/financial-element.model';
import {CurrentProfileSource} from '../../../../../services/sources/current-profile.source';
import {filter, takeUntil} from 'rxjs/operators';
import {Profile} from '../../../../../models/profile';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-company-ancillary',
    template: `
        <div style="margin-top: 16px">
            <h3 *ngIf="!hideIncome && !hideIncomeTitle">Income:</h3>
            <app-transfers-collection
                *ngIf="!hideIncome"
                style="margin-bottom:24px"
                [isOverride]="isOverride"
                [origin]="compensationType!"
                [showBoth]="true"
                [limit]="limitIncomes"
                [receiverLabel]="receiverLabel"
                [senderLabel]="senderLabel"
                [buttonLabel]="incomeLabel"
                [availableValueTypes]="
                    availableIncomeTypes[compensationType!]
                        ? availableIncomeTypes[compensationType!]
                        : availableIncomeTypes['default']
                "
                [allowPercents]="true"
                [showOnlyAmount]="false"
                [showAddButton]="true"
                [hideProduct]="hideProduct"
                [transfersFA]="disbursementTemplateFG!.controls.additional_incomes!"
                [disabled]="disabled"
                [hideSender]="hideSender"
                [hideReceiver]="hideReceiver"
                [hideLabel]="hideLabel"
                [type]="type"
                [defaultSender]="defaultSender"
                [defaultReceiver]="defaultReceiver"
                [defaultValueType]="defaultValueType"
                [defaultSenderWildcardId]="defaultSenderWildcardId"
                [defaultReceiverWildcardId]="defaultReceiverWildcardId"
                (addItem)="addIncome($event)"
                (deleteItem)="removeIncome($event)"
                [hidePayAtEscrowFlag]="hidePayAtEscrowFlag"
                [hideDisbursementInstructionsFlag]="hideDisbursementInstructionsFlag"
                [hideAmount]="hideAmount"
            ></app-transfers-collection>

            <h3 *ngIf="!hideExpense && !hideExpenseTitle">Expense:</h3>

            <app-transfers-collection
                *ngIf="!hideExpense"
                style="margin-bottom:24px"
                [isOverride]="isOverride"
                [origin]="compensationType!"
                [showAddButton]="true"
                [showBoth]="true"
                [limit]="limitExpenses"
                [receiverLabel]="receiverLabel"
                [senderLabel]="senderLabel"
                [buttonLabel]="expenseLabel"
                [availableValueTypes]="
                    availableExpenseTypes[compensationType!]
                        ? availableExpenseTypes[compensationType!]
                        : availableExpenseTypes['default']
                "
                [allowPercents]="true"
                [showOnlyAmount]="false"
                [hideProduct]="hideProduct"
                [transfersFA]="disbursementTemplateFG!.controls.additional_expenses!"
                [hideLabel]="hideLabel"
                [disabled]="disabled"
                [hideSender]="hideSender"
                [hideReceiver]="hideReceiver"
                [type]="type"
                [defaultSender]="defaultSender"
                [defaultReceiver]="defaultReceiver"
                [defaultValueType]="defaultValueType"
                [defaultSenderWildcardId]="defaultSenderWildcardId"
                [defaultReceiverWildcardId]="defaultReceiverWildcardId"
                [isExpense]="true"
                (addItem)="addExpense($event)"
                (deleteItem)="removeExpense($event)"
                [hideAmount]="hideAmount"
                [hidePayAtEscrowFlag]="hidePayAtEscrowFlag"
                [hideDisbursementInstructionsFlag]="hideDisbursementInstructionsFlag"
            ></app-transfers-collection>
        </div>
    `
})
export class ProvidesCompanyAncillaryComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() isOverride: boolean = false;
    @Input() disabled: boolean = false;
    @Input() incomeLabel: string | null = 'Add income';
    @Input() expenseLabel: string | null = 'Add expense';
    @Input() hideSender: boolean = false;
    @Input() hideReceiver: boolean = false;
    @Input() hideIncome: boolean = false;
    @Input() hideExpense: boolean = false;
    @Input() hideIncomeTitle: boolean = false;
    @Input() hideExpenseTitle: boolean = false;
    @Input() hidePayAtEscrowFlag: boolean = false;
    @Input() hideDisbursementInstructionsFlag: boolean = false;
    @Input() hideProduct: boolean = false;
    @Input() hideAmount: boolean = false;
    @Input() hideLabel: boolean = false;
    @Input() receiverLabel: string = 'Receiver Contact';
    @Input() senderLabel: string = 'Sender Contact';
    @Input() limitIncomes: number = 0;
    @Input() limitExpenses: number = 0;
    @Input() compensationType: string | null = 'default';
    @Input() type: string = FinancialTransferEntity.type_SET.transfer;
    @Input() defaultSender: ContactPartLink | null = null;
    @Input() defaultReceiver: ContactPartLink | null = null;
    @Input() defaultSenderWildcardId: number | null = null;
    @Input() defaultReceiverWildcardId: number | null = null;
    @Input() defaultValueType: string = FinancialTransferEntity.value_type_SET.flat_fee;

    @Input() disbursementTemplateFG: GenericFormGroup<DisbursementTemplateModel> | undefined;
    FINANCIAL_TRANSFER_ENTITY = FinancialTransferEntity;
    public currentProfile: Profile = new Profile();

    availableExpenseTypes: {[key: string]: string[]} = {
        default: [
            FinancialTransferEntity.value_type_SET.flat_fee,
            FinancialTransferEntity.value_type_SET.percent // @todo: for AndreyS - need refactor this
        ],
        user_referral: [
            FinancialTransferEntity.value_type_SET.flat_fee,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_split
        ],
        disbursement_template: [
            FinancialTransferEntity.value_type_SET.flat_fee,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.flat_fee_sales_net
        ],
        compensation_expense: [
            FinancialTransferEntity.value_type_SET.flat_fee,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.flat_fee_sales_net
        ],
        company_income_expense: [
            FinancialTransferEntity.value_type_SET.flat_fee,
            FinancialTransferEntity.value_type_SET.percent_of_company_split
        ],
        company: [
            FinancialTransferEntity.value_type_SET.flat_fee,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net
        ]
    };

    availableIncomeTypes: {[key: string]: string[]} = {
        default: [FinancialTransferEntity.value_type_SET.flat_fee],
        user_referral: [
            FinancialTransferEntity.value_type_SET.flat_fee,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_split
        ],
        disbursement_template: [
            FinancialTransferEntity.value_type_SET.flat_fee,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.flat_fee_sales_net
        ],
        company_income_expense: [
            FinancialTransferEntity.value_type_SET.flat_fee,
            FinancialTransferEntity.value_type_SET.percent_of_company_split,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.flat_fee_sales_net
        ],
        company: [
            FinancialTransferEntity.value_type_SET.flat_fee
            // FinancialTransferEntity.value_type_SET.percent_of_deal_net,
        ]
    };

    object_keys(obj: {[key: string]: any}): string[] {
        return Object.keys(obj);
    }

    addIncome(item: GenericFormGroup<FinancialTransferEntity>) {
        item.controls._id!.patchValue(this.disbursementTemplateFG!.controls.additional_incomes!.controls.length - 1, {
            emitEvent: false
        });
    }

    addExpense(item: GenericFormGroup<FinancialTransferEntity>) {
        item.controls._id!.patchValue(this.disbursementTemplateFG!.controls.additional_expenses!.controls.length - 1, {
            emitEvent: false
        });
    }

    removeIncome(item: GenericFormGroup<FinancialTransferEntity>) {
        const found = this.disbursementTemplateFG!.controls.additional_incomes!.controls.find(
            (el) => el.controls._id === item.controls._id
        );
        if (found) {
            this.disbursementTemplateFG!.controls.additional_incomes!.removeAt(found.controls._id!.value);
        }
        this.disbursementTemplateFG!.controls.additional_incomes!.controls.forEach((controlsItem, i) =>
            controlsItem.controls._id!.patchValue(i)
        );
    }
    removeExpense(item: GenericFormGroup<FinancialTransferEntity>) {
        const found = this.disbursementTemplateFG!.controls.additional_expenses!.controls.find(
            (el) => el.controls._id === item.controls._id
        );
        if (found) {
            this.disbursementTemplateFG!.controls.additional_expenses!.removeAt(found.controls._id!.value);
        }
        this.disbursementTemplateFG!.controls.additional_expenses!.controls.forEach((controlsItem, i) =>
            controlsItem.controls._id!.patchValue(i)
        );
    }

    ngOnInit() {
        // TODO: Need to change this condition if sales compensation elements will have more than 1 expense
        if (
            !this.disbursementTemplateFG!.controls.additional_expenses!.controls.length &&
            this.type === FinancialTransferEntity.type_SET.agent_split
        ) {
            // "origin" of the new transfer is different from the "type" of parent financial element( agent->company_split_individual )
            // this was added to set specific value types for this transfer(not based on the value types of the parent financial element)
            const newFT = new FinancialTransferEntity()
                .setType(FinancialTransferEntity.type_SET.company_split_individual)
                .setOrigin(FinancialElementModel.type_set.company_split_individual)
                .setOriginIsExpense(true)
                .setSender(null)
                .setReceiver(
                    new ContactPartLink()
                        .setContactId(this.currentProfile.company!.company_group!.contact!.id!)
                        .setLinkTitle(this.currentProfile.company!.company_group!.contact!.display_name!)
                )
                .setValueType(FinancialTransferEntity.value_type_SET.remaining);
            const newFG = new GenericFormGroup(newFT);
            newFG.controls.value_type!.disable();
            this.disbursementTemplateFG!.controls.additional_expenses!.controls.push(newFG);
            this.addExpense(newFG);
        }
    }

    constructor(protected currentProfileSource: CurrentProfileSource) {
        this.currentProfileSource.changeProfileEvent
            .pipe(
                filter((profile) => profile !== null),
                takeUntil(this.unsubscribe)
            )
            .subscribe((profile) => {
                this.currentProfile = profile;
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
