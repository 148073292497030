<form *ngIf="contactForm" [formGroup]="contactForm" class="bg-white p-3" (keydown.enter)="$event.preventDefault()">
    <section class="row">
        <div class="col-md-6">
            <mat-form-field class="w-100">
                <mat-placeholder>Display Name</mat-placeholder>
                <input matInput required type="text" formControlName="display_name" />
            </mat-form-field>
        </div>

        <div class="col-md-3">
            <div class="w-100 d-flex align-items-center">
                <mat-form-field class="w-100">
                    <mat-label>Categories</mat-label>
                    <mat-select
                        #contactTypeSelect
                        formControlName="contact_type_ids"
                        multiple
                        [disabled]="isDefaultCompanyContact || isDivisionContact"
                    >
                        <mat-option *ngFor="let contactType of contactTypes" [value]="contactType.id">
                            {{ contactType.title }} ({{ contactType.metatype }})
                        </mat-option>

                        <div
                            class="contact-types-add-edit-btn"
                            (click)="
                                $event.stopPropagation(); contactTypeSelect.close(); openContactTypeCreationDialog()
                            "
                        >
                            Create/Edit Category
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="col-md-3">
            <div class="w-100 d-flex align-items-center">
                <mat-form-field class="w-100 mat-form-field-contact-class">
                    <mat-label>Class</mat-label>
                    <mat-select
                        #contactClassSelect
                        formControlName="contact_class_ids"
                        multiple
                        [disabled]="isDefaultCompanyContact || isDivisionContact"
                        placeholder="Class"
                    >
                        <mat-option *ngFor="let contactClass of contactClasses" [value]="contactClass.contact_class_id">
                            {{ contactClass.title }}
                        </mat-option>

                        <mat-option *ngIf="!contactClasses.length" style="display: none"></mat-option>

                        <div
                            class="contact-types-add-edit-btn"
                            *ngIf="!isDefaultCompanyContact && !isDivisionContact"
                            rbac
                            [rule]="{contacts__view_classes: true}"
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            (click)="
                                $event.stopPropagation(); contactClassSelect.close(); openContactClassCreationDialog()
                            "
                        >
                            Create/Edit Class
                        </div>
                    </mat-select>
                </mat-form-field>

                <mat-icon
                    [matTooltip]="'Directory email ' + directoryEmail"
                    *ngIf="contact?.related__profile_fk_id && !isDivisionContact"
                >
                    person
                </mat-icon>
                <mat-icon
                    [matTooltip]="'Division email ' + divisionEmail"
                    *ngIf="contact?.related__profile_fk_id && isDivisionContact"
                >
                    apartment
                </mat-icon>
            </div>
        </div>
    </section>

    <section class="row no-gutters">
        <div class="col-md-6">
            <mat-radio-group [formControl]="isCompanyCtrl" [disabled]="isDefaultCompanyContact || isDivisionContact">
                <mat-radio-button [value]="false"> Individual </mat-radio-button>
                <mat-radio-button [value]="true"> Company </mat-radio-button>
            </mat-radio-group>
        </div>
    </section>

    <mat-tab-group #tabs [selectedIndex]="tabIndex">
        <mat-tab label="Details" *ngIf="profilePermissions && profilePermissions.contacts__manage_details">
            <div formArrayName="contact_persons" class="my-3">
                <ng-container *ngFor="let person of personsArrayCtrl!.controls; let ii = index">
                    <div
                        class="d-flex align-items-md-center bg-light rounded p-2 mb-2"
                        [formGroupName]="ii"
                        *ngIf="person.value.type !== 'main_person' || person.value.type === 'main_person'"
                    >
                        <mat-icon class="mr-2" *ngIf="person.value.type === 'main_person' && isCompanyCtrl.value"
                            >apartment</mat-icon
                        >
                        <mat-icon class="mr-2" *ngIf="person.value.type === 'department'">domain</mat-icon>
                        <mat-icon
                            class="mr-2"
                            *ngIf="
                                person.value.type === 'person' ||
                                (person.value.type === 'main_person' && !isCompanyCtrl.value)
                            "
                            >person_outline</mat-icon
                        >
                        <div class="row align-items-end w-100 d-nline-flex">
                            <mat-form-field class="col-md-6 col-lg-4">
                                <input
                                    matInput
                                    autofocus
                                    autocomplete="off"
                                    formControlName="first_name"
                                    type="text"
                                    [placeholder]="
                                        person.value.type === 'person' ||
                                        (person.value.type === 'main_person' && !isCompanyCtrl.value)
                                            ? 'First Name'
                                            : person.value.type === 'department'
                                              ? 'Department name'
                                              : 'Company Name'
                                    "
                                />
                            </mat-form-field>

                            <mat-form-field
                                class="col-md-6 col-lg-4"
                                *ngIf="
                                    person.value.type === 'person' ||
                                    (person.value.type === 'main_person' && !isCompanyCtrl.value)
                                "
                            >
                                <input
                                    matInput
                                    formControlName="last_name"
                                    type="text"
                                    placeholder="Last Name"
                                    autocomplete="off"
                                />
                            </mat-form-field>

                            <mat-form-field
                                class="col-md-6 col-lg-4"
                                *ngIf="
                                    person.value.type === 'person' ||
                                    (person.value.type === 'main_person' && !isCompanyCtrl.value)
                                "
                            >
                                <input
                                    matInput
                                    placeholder="Nickname"
                                    formControlName="preferred_name"
                                    autocomplete="off"
                                />
                            </mat-form-field>

                            <div class="col-md-6 col-lg-4">
                                <mat-form-field>
                                    <input
                                        matInput
                                        formControlName="phone"
                                        type="text"
                                        placeholder="Phone"
                                        autocomplete="off"
                                        #phoneInput
                                        [maskito]="phoneInputMask"
                                        (blur)="onBlurMethod(ii, 'phone')"
                                        (click)="onClickMethod(ii, 'phone')"
                                    />
                                </mat-form-field>
                                <!--Cannot use mat-error because contactForm.get('contact_persons') is not a FormArray-->
                                <div class="line-underline" *ngIf="errors && errors[ii] && errors[ii].phone"></div>
                                <div class="text-underline" *ngIf="errors && errors[ii] && errors[ii].phone">
                                    Phone must be 10 digits long.
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-4">
                                <mat-form-field>
                                    <input
                                        matInput
                                        formControlName="fax"
                                        type="text"
                                        placeholder="Fax"
                                        autocomplete="off"
                                        #faxInput
                                        [maskito]="phoneInputMask"
                                        (blur)="onBlurMethod(ii, 'fax')"
                                        (click)="onClickMethod(ii, 'fax')"
                                    />
                                </mat-form-field>
                                <!--Cannot use mat-error because contactForm.get('contact_persons') is not a FormArray-->
                                <div class="line-underline" *ngIf="errors && errors[ii] && errors[ii].fax"></div>
                                <div class="text-underline" *ngIf="errors && errors[ii] && errors[ii].fax">
                                    Fax must be 10 digits long.
                                </div>
                            </div>

                            <mat-form-field class="col-md-6 col-lg-4">
                                <input
                                    matInput
                                    autocomplete="off"
                                    formControlName="email"
                                    (blur)="emailCheck(person, ii)"
                                    type="email"
                                    placeholder="Email"
                                    [required]="person.get('email')!.getError('required')"
                                />
                                <mat-error>Invalid email address.</mat-error>
                                <mat-hint *ngIf="isExistingEmail[ii]" style="color: red"
                                    >Email Already Exists: All contact persons must have unique email
                                    addresses.</mat-hint
                                >
                                <mat-hint *ngIf="isEmailDuplicates[ii]" style="color: red"
                                    >Email Duplicates: All contact persons must have unique email addresses.</mat-hint
                                >
                            </mat-form-field>

                            <mat-form-field *ngIf="false" class="col-12">
                                <mat-select
                                    placeholder="Default Location (coming soon...)"
                                    formControlName="default_location"
                                    [compareWith]="comparePeriod"
                                >
                                    <mat-option (click)="createNewLocation($event)">Create new location...</mat-option>
                                    <mat-option [value]="null">None</mat-option>
                                    <mat-option *ngFor="let location of locationsArrayCtrl!.value" [value]="location">
                                        <span *ngIf="location.label">{{ location.label + ':' }} </span>
                                        <span *ngIf="location.street_number">{{ location.street_number }} </span>
                                        <span *ngIf="location.street_address">{{ location.street_address }} </span>
                                        <span *ngIf="location.unit_number">{{ location.unit_number }} </span>
                                        <span *ngIf="location.city">{{ location.city + ',' }} </span>
                                        <span *ngIf="location.state">{{ location.state }} </span>
                                        <span *ngIf="location.zip">{{ location.zip }} </span>
                                        <span *ngIf="location.country" class="text-muted"
                                            >({{ location.country }})</span
                                        >
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <button
                            mat-icon-button
                            type="button"
                            *ngIf="person.value.type !== 'main_person'"
                            (click)="removePerson(ii, person)"
                            matTooltip="Remove"
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                        <div style="width: 40px; height: 40px" *ngIf="person.value.type === 'main_person'"></div>
                    </div>
                </ng-container>

                <div
                    [anyRule]="[{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                >
                    <button
                        [rule]="{contacts__manage_details: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        type="button"
                        mat-button
                        (click)="addPerson()"
                        matTooltip="Add person"
                        *ngIf="!isDefaultCompanyContact"
                    >
                        <mat-icon class="mr-1">add</mat-icon>
                        Person
                    </button>
                    <button
                        [rule]="{contacts__manage_details: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        *ngIf="isCompanyCtrl.value && !isDefaultCompanyContact"
                        type="button"
                        mat-button
                        (click)="addPerson(undefined, true)"
                        matTooltip="Add department"
                        class="ml-1"
                    >
                        <mat-icon class="mr-1">add</mat-icon>
                        Department
                    </button>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Locations" *ngIf="profilePermissions && profilePermissions.contacts__manage_locations">
            <div class="mb-2" formArrayName="contact_locations">
                <app-contact-location
                    *ngFor="let locationCtrl of locationsArrayCtrl!.controls; index as idx; first as fst"
                    [locationCtrl]="$any(locationCtrl)"
                    [removeLocation]="removeLocation(idx)"
                    [isEditingMode]="idx === editLocationIdx"
                    [isAbleToEdit]="!isDefaultCompanyContact"
                >
                </app-contact-location>

                <div class="zp-place-picker" *ngIf="!isDefaultCompanyContact">
                    <app-gplaces-picker [locationCtrl]="locationPickerCtrl" [focused]="true"></app-gplaces-picker>
                </div>
                <button
                    type="button"
                    *ngIf="!isDefaultCompanyContact"
                    mat-raised-button
                    (click)="editLocationIdx = addLocation()"
                    matTooltip="Add location"
                >
                    <mat-icon class="mr-1">add_location</mat-icon>Add Location
                </button>
            </div>
        </mat-tab>

        <mat-tab
            label="Payment Methods"
            *ngIf="
                contact &&
                profilePermissions &&
                (profilePermissions.contacts__manage_payment_methods ||
                    profilePermissions.contacts__manage_company_disbursement_instruction)
            "
        >
            <app-payment-methods-tab
                *ngIf="!isDefaultCompanyContact"
                [contact]="contact"
                [mode]="'edit'"
                [isCompanyZipiFinancialActivated]="isCompanyZipiFinancialActivated"
            ></app-payment-methods-tab>

            <div class="mt-4 p-1">
                <mat-accordion>
                    <mat-expansion-panel (opened)="isAuthorizePanelOpen = true" (closed)="isAuthorizePanelOpen = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span>Authorize.Net</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngIf="isAuthorizePanelOpen">
                            <div *ngIf="!isDefaultCompanyContact" style="max-width: 60%" class="pt-3 pb-3">
                                <h3>Receive Money (from Contact):</h3>
                                <div *ngIf="contactGateways && contactGateways.length > 0">
                                    <div *ngFor="let gateway of contactGateways">
                                        <div *ngFor="let method of gateway.payment_methods">
                                            <mat-card
                                                class="d-flex mb-1 align-items-center"
                                                *ngIf="method.is_available_for_payment"
                                                [ngClass]="!method.is_visible_to_connect ? 'disabled-card' : ''"
                                                [matTooltip]="
                                                    'Not authorized to charge card, please contact the card holder to provide authorization from their portal: Edit Contact > Payment Method.'
                                                "
                                                [matTooltipDisabled]="method.is_visible_to_connect"
                                            >
                                                <div>
                                                    <span class="font-weight-bold mr-2">Credit Card: </span>
                                                    <span>{{ method.title }}</span>
                                                    <span class="ml-1" *ngIf="method.expiration_date"
                                                        >({{ method.expiration_date }})</span
                                                    >
                                                </div>

                                                <i class="sp-spacer"></i>
                                                <button
                                                    [rule]="{contacts__manage_payment_methods: true}"
                                                    rbac
                                                    [denyMethod]="'style'"
                                                    [allowStyle]="{display: 'inline-block'}"
                                                    [denyStyle]="{display: 'none'}"
                                                    class="mr-2"
                                                    [disabled]="!method.is_visible_to_connect"
                                                    *ngIf="method.id"
                                                    type="button"
                                                    mat-raised-button
                                                    color="primary"
                                                    (click)="disconnectVisibleMethod(method.id)"
                                                >
                                                    Disconnect
                                                </button>
                                                <button
                                                    [rule]="{contacts__manage_payment_methods: true}"
                                                    rbac
                                                    [denyMethod]="'style'"
                                                    [allowStyle]="{display: 'inline-block'}"
                                                    [denyStyle]="{display: 'none'}"
                                                    *ngIf="method.id"
                                                    type="button"
                                                    mat-raised-button
                                                    color="warn"
                                                    (click)="deletePaymentMethod(method.id)"
                                                >
                                                    <mat-icon>delete_forever</mat-icon>
                                                    Delete
                                                </button>
                                            </mat-card>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    [rule]="{contacts__manage_payment_methods: true}"
                                    rbac
                                    [denyMethod]="'style'"
                                    [allowStyle]="{display: 'inline-block'}"
                                    [denyStyle]="{display: 'none'}"
                                    class="mr-2 my-2"
                                    type="button"
                                    mat-raised-button
                                    (click)="addCard()"
                                >
                                    Add Contact Payment Method
                                </button>
                            </div>

                            <mat-divider *ngIf="!isDefaultCompanyContact"></mat-divider>
                            <div
                                *ngIf="!isDefaultCompanyContact"
                                class="pt-3"
                                style="display: flex; flex-direction: column; max-width: 60%"
                            >
                                <h3>Pay Money (to Contact):</h3>
                                <div
                                    style="
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-between;
                                        width: 100%;
                                    "
                                >
                                    <div
                                        style="display: flex; flex-direction: column; justify-content: space-between"
                                        class="w-100"
                                    >
                                        <div *ngIf="myGateways && myGateways.length > 0" class="w-100">
                                            <div *ngFor="let gateway of myGateways" class="w-100">
                                                <mat-card
                                                    class="d-flex mb-1 align-items-center w-100"
                                                    *ngFor="let method of gateway.payment_methods"
                                                >
                                                    <div class="w-100">
                                                        <div class="d-flex w-100">
                                                            <div>
                                                                <span class="font-weight-bold mr-2">Credit Card: </span>
                                                                <span>{{ method.title }}</span>
                                                                <span class="ml-1" *ngIf="method.expiration_date"
                                                                    >({{ method.expiration_date }})</span
                                                                >
                                                            </div>
                                                            <i class="sp-spacer"></i>
                                                            <div>
                                                                <a
                                                                    class="font-weight-bold"
                                                                    style="color: #f8a504; font-size: 20px"
                                                                    href="#"
                                                                    *ngIf="!method.related__ledger_account_fk_id"
                                                                    (click)="
                                                                        $event.preventDefault();
                                                                        finishSetup(method, gateway)
                                                                    "
                                                                >
                                                                    Complete Setup
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex w-100 align-items-end">
                                                            <i class="sp-spacer"></i>

                                                            <mat-checkbox
                                                                *ngIf="method.id"
                                                                [checked]="method.is_visible_to_connect"
                                                                style="height: 25px"
                                                                (change)="changeVisibleMode(method.id)"
                                                                class="mr-3"
                                                            >
                                                                Contact Can Initialize Payment
                                                            </mat-checkbox>
                                                            <button
                                                                [rule]="{contacts__manage_payment_methods: true}"
                                                                rbac
                                                                [denyMethod]="'style'"
                                                                [allowStyle]="{display: 'inline-block'}"
                                                                [denyStyle]="{display: 'none'}"
                                                                *ngIf="method.id"
                                                                type="button"
                                                                mat-raised-button
                                                                color="warn"
                                                                (click)="deletePaymentMethod(method.id)"
                                                            >
                                                                <mat-icon>delete_forever</mat-icon>
                                                                Delete
                                                            </button>
                                                        </div>
                                                    </div>
                                                </mat-card>
                                            </div>
                                        </div>
                                        <div
                                            style="display: flex !important; flex-direction: row"
                                            class="my-2 d-inline-block"
                                        >
                                            <button
                                                [rule]="{contacts__manage_payment_methods: true}"
                                                rbac
                                                [denyMethod]="'style'"
                                                [allowStyle]="{display: 'inline-block'}"
                                                [denyStyle]="{display: 'none'}"
                                                class="mr-2 mb-2"
                                                type="button"
                                                mat-raised-button
                                                (click)="addMyCard()"
                                            >
                                                Add Credit Card
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </mat-tab>

        <mat-tab
            label="Compliance"
            *ngIf="
                contact?.related__profile_fk_id &&
                !isDefaultCompanyContact &&
                profilePermissions &&
                profilePermissions.contacts__manage_compliance
            "
        >
            <br />
            <div *ngIf="profileForm" [formGroup]="$any(profileForm)">
                <div class="row w-100 py-4">
                    <div class="col">
                        <strong> General</strong>
                    </div>
                </div>
                <div class="row w-100 d-nline-flex zp-pd-15">
                    <!-- <div class="col col-sm-6 col-md-6">
                        <mat-form-field class="w-100">
                            <input matInput class="" placeholder="Nickname" name="preferred_name" formControlName="preferred_name">
                        </mat-form-field>
                    </div> -->
                    <div class="col col-sm-6">
                        <app-date-picker
                            [placeholder]="'Anniversary Date'"
                            [dateControl]="profileForm.controls.anniversary_date"
                        >
                        </app-date-picker>
                    </div>

                    <div class="col cos-sm-6 col-md-6">
                        <app-date-picker
                            [placeholder]="'Date of Birth'"
                            [dateControl]="profileForm.controls.date_of_birth"
                        >
                        </app-date-picker>
                    </div>

                    <div class="col col-sm-6">
                        <app-date-picker [placeholder]="'Hire Date'" [dateControl]="profileForm.controls.hire_date">
                        </app-date-picker>
                    </div>

                    <div class="col col-sm-6">
                        <app-date-picker
                            [placeholder]="'Termination Date'"
                            [dateControl]="profileForm.controls.termination_date"
                        >
                        </app-date-picker>
                    </div>
                </div>
                <div class="row w-100 compliance-item">
                    <div class="col">
                        <strong> Licensing / Memberships </strong>
                    </div>
                </div>

                <app-contact-edit-compliance-membership
                    *ngIf="profileForm.controls.profile_memberships"
                    [profileMembershipsFA]="$any(profileForm.controls.profile_memberships)"
                >
                </app-contact-edit-compliance-membership>

                <div class="row w-100 ca-item">
                    <div class="col">
                        <strong>Compensation Attributes</strong>
                    </div>
                </div>

                <mat-form-field class="w-45">
                    <mat-label>Options</mat-label>
                    <mat-select placeholder="Wildcard" (selectionChange)="addWildcardInstance($event)">
                        <mat-option
                            (click)="$event.stopPropagation(); createWildcard()"
                            rbac
                            [rule]="{contacts__manage_custom_attributes: true}"
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'block'}"
                            [denyStyle]="{display: 'none'}"
                        >
                            <strong> + Create/Update Attribute Type </strong>
                        </mat-option>
                        <mat-option *ngFor="let wildcard of availableWildcards" [value]="wildcard" class="pr-0">
                            <div class="d-flex align-items-center justify-content-between">
                                <span>{{ wildcard.title }}</span>
                                <mat-icon *ngIf="wildcard.wildcard_type_sk === WILDCARD.type_SET.numeric"
                                    >calculate</mat-icon
                                >
                                <mat-icon *ngIf="wildcard.wildcard_type_sk === WILDCARD.type_SET.text"
                                    >text_fields</mat-icon
                                >
                                <mat-icon *ngIf="wildcard.wildcard_type_sk === WILDCARD.type_SET.contact"
                                    >contacts</mat-icon
                                >
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div
                    class="row w-100 zp-pd-15"
                    rbac
                    [rule]="{contacts__manage_compliance: true}"
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'flex'}"
                    [denyStyle]="{display: 'none'}"
                >
                    <section
                        class="col-6"
                        [formGroup]="wildcardInstanceForm"
                        *ngFor="let wildcardInstanceForm of wildcardInstanceArrayCtrl; index as instanceFGIndex"
                    >
                        <div class="row">
                            <div class="col-4 wildcard-label">
                                <mat-label>{{ wildcardInstanceForm.controls.item_title!.value }}</mat-label>
                            </div>
                            <div class="col-7">
                                <mat-form-field
                                    ngDefaultControl
                                    class="w-100"
                                    *ngIf="
                                        wildcardInstanceForm.controls.wildcard_type_sk!.value ===
                                        WILDCARD.type_SET.numeric
                                    "
                                >
                                    <input [formControlName]="'value'" matInput type="number" placeholder="Numerical" />
                                </mat-form-field>

                                <mat-form-field
                                    ngDefaultControl
                                    class="w-100"
                                    *ngIf="
                                        wildcardInstanceForm.controls.wildcard_type_sk!.value === WILDCARD.type_SET.text
                                    "
                                >
                                    <input [formControlName]="'value'" matInput type="text" placeholder="Text" />
                                </mat-form-field>

                                <app-company-compensation-combined-picker
                                    *ngIf="
                                        wildcardInstanceForm &&
                                        wildcardInstanceForm.get('value') &&
                                        wildcardInstanceForm.get('wildcard_type_sk')!.value ===
                                            WILDCARD.type_SET.contact
                                    "
                                    class="w-100"
                                    [bold_style]="false"
                                    style="margin-top: 12px"
                                    title="Contact"
                                    [only_compensation_groups]="false"
                                    [availableTypes]="['contact']"
                                    [singleSelected]="true"
                                    [nodesFA]="$any(wildcardInstanceForm.get('value'))"
                                >
                                </app-company-compensation-combined-picker>
                            </div>
                            <button
                                (click)="deleteInstance(instanceFGIndex)"
                                class="col-1 wildcard-remove-button"
                                mat-icon-button
                                type="button"
                                matTooltip="Remove"
                            >
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </section>
                </div>

                <!--                <mat-divider style="margin: 10px 0"-->
                <!--                             rbac [rule]="{contacts__manage_compliance: true}" [denyMethod]="'style'" [allowStyle]="{display: 'flex'}" [denyStyle]="{display: 'none'}"></mat-divider>-->

                <!--                <section [formGroup]="starting_deal_count" class="row w-100">-->
                <!--                <div class="col col-sm-6">-->
                <!--                  <app-company-compensation-combined-picker-->
                <!--                    class="w-100"-->
                <!--                    [bold_style]="false" style="margin-top:12px"-->
                <!--                    title="Mentor"-->
                <!--                    [only_compensation_groups]="false"-->
                <!--                    [availableTypes]="['contact']"-->
                <!--                    singleSelected="true"-->
                <!--                    [nodesFA]="mentorContacts"-->
                <!--                  >-->
                <!--                  </app-company-compensation-combined-picker>-->
                <!--                </div>-->
                <!--                <div class="col col-sm-6">-->
                <!--                  <app-company-compensation-combined-picker-->
                <!--                      class="w-100"-->
                <!--                      [bold_style]="false" style="margin-top:12px"-->
                <!--                      title="Mentor Team Leader"-->
                <!--                      [only_compensation_groups]="false"-->
                <!--                      [availableTypes]="['contact']"-->
                <!--                      singleSelected="true"-->
                <!--                      [nodesFA]="mentorTeamLeaderContacts"-->
                <!--                  >-->
                <!--                  </app-company-compensation-combined-picker>-->
                <!--                </div>-->
                <!--                <div class="col col-sm-6">-->
                <!--                  <app-company-compensation-combined-picker-->
                <!--                    class="w-100"-->
                <!--                    [bold_style]="false" style="margin-top:12px"-->
                <!--                    title="TRX Coordinator"-->
                <!--                    [only_compensation_groups]="false"-->
                <!--                    [availableTypes]="['contact']"-->
                <!--                    singleSelected="true"-->
                <!--                    [nodesFA]="trxCoordinatorContacts"-->
                <!--                  >-->
                <!--                  </app-company-compensation-combined-picker>-->
                <!--                </div>-->
                <!--                <div class="col col-sm-6">-->
                <!--                  <mat-form-field class="w-100">-->
                <!--                    <input  matInput-->
                <!--                            type="number"-->
                <!--                            [placeholder]="'TRX Coordinator Amount'"-->
                <!--                            [name]="'trx_coordinator_amount'"-->
                <!--                            [formControl]="contactForm.controls.trx_coordinator_amount"-->
                <!--                    >-->
                <!--                  </mat-form-field>-->
                <!--                </div>-->

                <!--              </section>-->

                <app-contact-edit-compliance-opening-performance-values
                    *ngIf="profileForm.controls.opening_performance_values"
                    [openingPerformanceValuesFA]="$any(profileForm.controls.opening_performance_values)"
                >
                </app-contact-edit-compliance-opening-performance-values>

                <!-- <div formGroupName="starting_deal_count" class="row w-100">
                    <div *ngFor="let type of DEAL.provideTypes()" class="col col-sm-6">
                        <mat-form-field class="w-100">
                            <input
                                matInput
                                type="number"
                                [placeholder]="DEAL.type_LABELS[type] + ' deal count'"
                                [name]="type"
                                [formControlName]="type"
                            >
                        </mat-form-field>
                    </div>
                </div> -->
            </div>
        </mat-tab>

        <mat-tab
            label="Approvals"
            *ngIf="
                contact &&
                !isDefaultCompanyContact &&
                profilePermissions &&
                profilePermissions.contacts__manage_approvals
            "
        >
            <div
                class="p-2 py-3"
                *ngIf="contactForm.controls.partner_company_settings"
                [formGroup]="$any(contactForm.controls.partner_company_settings)"
            >
                <div>
                    <h3>Portal Access</h3>
                    <div class="ml-3">
                        <mat-checkbox formControlName="show_my_docs_as_source"
                            >Allow Sales & Purchase Documents to be Viewed in Portal</mat-checkbox
                        >
                    </div>
                </div>

                <mat-divider class="my-3"></mat-divider>

                <div>
                    <h3>Automation Rules for Documents Received from {{ contactForm.controls.display_name!.value }}</h3>
                    <div class="ml-3">
                        <h4
                            [anyRule]="[{contacts__manage_approvals_automation: true}]"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                        >
                            Company Wide
                        </h4>
                        <div
                            class="ml-3"
                            [anyRule]="[{contacts__manage_approvals_automation: true}]"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                        >
                            <div>
                                <mat-checkbox formControlName="approve_income_bills" (change)="approveBills($event)"
                                    >Auto-Create Bills (<a
                                        href=""
                                        (click)="$event.preventDefault(); productMappingManage()"
                                        >Change Product/Service Mapping</a
                                    >)
                                </mat-checkbox>
                            </div>
                            <div>
                                <mat-checkbox
                                    formControlName="approve_income_invoices"
                                    (change)="approveInvoices($event)"
                                    >Auto-Create Invoices (<a
                                        href=""
                                        (click)="$event.preventDefault(); productMappingManage()"
                                        >Change Product/Service Mapping</a
                                    >)</mat-checkbox
                                >
                            </div>

                            <div>
                                <mat-checkbox formControlName="approve_payout_income_bills"
                                    >Auto-Create Bills From Payouts
                                    <!--                                    (<a href="" (click)="$event.preventDefault(); productMappingManage()">Change Product/Service Mapping</a>)-->
                                </mat-checkbox>
                            </div>
                            <div>
                                <mat-checkbox formControlName="approve_payout_income_invoices"
                                    >Auto-Create Invoices From Payouts
                                    <!--                                     (<a href="" (click)="$event.preventDefault(); productMappingManage()">Change Product/Service Mapping</a>)-->
                                </mat-checkbox>
                            </div>
                        </div>
                        <h4>Recurring Invoices</h4>
                        <div
                            class="ml-3"
                            *ngIf="
                                !contactForm.get('partner_company_settings') ||
                                !contactForm.get('partner_company_settings')!.get('approve_recurring_invoice_ids') ||
                                !contactForm.get('partner_company_settings')!.get('approve_recurring_invoice_ids')!
                                    .value ||
                                contactForm.get('partner_company_settings')!.get('approve_recurring_invoice_ids')!.value
                                    .length === 0
                            "
                        >
                            <span>No Recurring Invoices</span>
                        </div>
                        <div
                            class="ml-3"
                            style="width: fit-content"
                            *ngIf="
                                recurringInvoicesArrayCtrl &&
                                recurringInvoicesArrayCtrl.controls &&
                                recurringInvoicesArrayCtrl.controls.length > 0
                            "
                        >
                            <div
                                class="d-flex align-items-center"
                                style="width: 400px"
                                [formGroup]="$any(riForm)"
                                *ngFor="let riForm of recurringInvoicesArrayCtrl.controls; index as idx"
                            >
                                <div style="width: 30px; margin-right: 0.5rem; margin-bottom: 5px">{{ idx + 1 }}.</div>
                                <mat-form-field class="w-100">
                                    <input matInput type="text" [formControl]="$any(riForm.get('title'))" />
                                </mat-form-field>
                                <span class="sp-spacer"></span>
                                <mat-icon class="ml-3" style="cursor: pointer" (click)="removeRecurring(idx)">
                                    cancel
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab
            label="Payroll"
            *ngIf="
                contact &&
                contact.id &&
                !isDefaultCompanyContact &&
                profilePermissions &&
                profilePermissions.contacts__manage_payroll
            "
        >
            <section class="py-2">
                <app-contact-edit-payment-methods-deal-payouts
                    [anyRule]="[
                        {contacts__manage_payment_methods: true},
                        {contacts__manage_company_disbursement_instruction: true}
                    ]"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    style="width: 35%; margin-left: 25px"
                    [contactFormGroup]="contactForm"
                >
                </app-contact-edit-payment-methods-deal-payouts>

                <mat-divider></mat-divider>

                <app-contact-payrolls [contact]="contact" [isEditMode]="true"></app-contact-payrolls>
            </section>
        </mat-tab>

        <mat-tab label="Notes" *ngIf="profilePermissions && profilePermissions.contacts__manage_notes && contact">
            <mat-form-field class="w-100 mt-3">
                <textarea matInput matTextareaAutosize formControlName="notes" placeholder="Notes"> </textarea>
                <mat-hint align="end">Characters {{ contactForm.get('notes')?.value?.length }} / 5000</mat-hint>
            </mat-form-field>
        </mat-tab>
    </mat-tab-group>

    <div class="sp-action-bar" *ngIf="contact">
        <button
            type="button"
            mat-stroked-button
            color="warn"
            [anyRule]="[{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            (click)="deleteContact()"
            *ngIf="!isDefaultCompanyContact"
        >
            Delete
        </button>

        <i class="sp-spacer"></i>

        <button
            type="button"
            mat-button
            color="primary"
            [routerLink]="['/contacts', contact.id]"
            style="margin-right: 5px"
        >
            Cancel
        </button>

        <button
            type="submit"
            *ngIf="!isDefaultCompanyContact"
            [anyRule]="[{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            [disabled]="isSubmitDisabled.value"
            (mousedown)="submitForm()"
            mat-raised-button
            color="primary"
        >
            Save
        </button>
    </div>

    <div class="col-12 text-right mt-2" *ngIf="!contact">
        <button
            type="submit"
            [anyRule]="[{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            [disabled]="isSubmitDisabled.value"
            (mousedown)="submitForm()"
            mat-raised-button
            color="primary"
        >
            Create
        </button>
    </div>
</form>
