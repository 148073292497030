<div class="mt-4 mb-2">
    <div *ngIf="isCompanyZipiFinancialActivated && isVelocityFeatureFlagEnabled" class="mt-3">
        <h3>Transfer Preferences</h3>

        <div class="d-flex">
            <div class="w-50 p-2 pr-3" [formGroup]="sendingTransferPreferenceForm">
                <div *ngIf="!isSendingOverrideEnabled" class="mb-2">
                    <div class="mb-2">
                        When money send to this contact fee and payment methods will be defined by Money Transfers
                        preferences.
                    </div>
                    <div class="d-flex w-70 align-items-center justify-content-start">
                        <button class="mr-2" type="button" mat-raised-button (click)="isSendingOverrideEnabled = true">
                            Modify
                        </button>
                    </div>
                </div>

                <div *ngIf="isSendingOverrideEnabled">
                    <div class="d-flex mb-2">
                        <div class="d-flex w-70 align-items-center justify-content-start">
                            When money send to this contact commission payer will be.
                        </div>
                        <div class="d-flex w-30 align-items-center justify-content-center">
                            <div class="mr-1">Receiver</div>
                            <mat-slide-toggle
                                [checked]="
                                    sendingTransferPreferenceForm.controls.velocity &&
                                    sendingTransferPreferenceForm.controls.velocity.value !== 'no_fee'
                                "
                                (change)="commissionPayerChanged($event, sendingTransferPreferenceForm)"
                            >
                                Me
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <div
                        class="d-flex mb-2"
                        *ngIf="
                            sendingTransferPreferenceForm.controls.velocity &&
                            sendingTransferPreferenceForm.controls.velocity.value !== 'no_fee'
                        "
                    >
                        <div class="d-flex w-70 align-items-center justify-content-start">
                            And I prefer payment method
                        </div>
                        <div class="d-flex w-30 align-items-center justify-content-center">
                            <mat-form-field class="w-100">
                                <mat-select [placeholder]="'Payment Method'" formControlName="velocity">
                                    <ng-container *ngFor="let type of senderVelocityTypes">
                                        <mat-option [value]="type">
                                            {{ type === 'instant' ? 'Expedited' : (type | fromSnakeCase) }}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div
                        class="d-flex mb-2"
                        *ngIf="
                            sendingTransferPreferenceForm.controls.velocity &&
                            sendingTransferPreferenceForm.controls.velocity.value !== 'no_fee'
                        "
                    >
                        <div class="d-flex w-70 align-items-center justify-content-start">
                            Do not send money if this payment method is not available.
                        </div>
                        <div class="d-flex w-30 align-items-center justify-content-center">
                            <mat-checkbox
                                style="margin-top: 10px"
                                formControlName="is_downgrade_restricted"
                            ></mat-checkbox>
                        </div>
                    </div>
                    <div class="d-flex mb-2">
                        <div class="d-flex w-70 align-items-center justify-content-start">
                            <button
                                class="mr-2"
                                type="button"
                                mat-raised-button
                                (click)="removeContactTransferPreference('when_sending')"
                            >
                                Restore
                            </button>
                        </div>
                        <div class="d-flex w-30 align-items-center justify-content-center">
                            <button
                                class="ml-2 mr-2"
                                type="button"
                                color="primary"
                                mat-raised-button
                                *ngIf="
                                    !sendingTransferPreference ||
                                    (sendingTransferPreferenceForm &&
                                        sendingTransferPreference &&
                                        (sendingTransferPreferenceForm.controls.velocity?.value !==
                                            sendingTransferPreference.velocity ||
                                            sendingTransferPreferenceForm.controls.is_downgrade_restricted?.value !==
                                                sendingTransferPreference.restrict_auto_downgrade))
                                "
                                (click)="saveTransferPreference('when_sending')"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="w-50 p-2 pl-3"
                style="border-left: solid 1px #aaaaaa"
                [formGroup]="receivingTransferPreferenceForm"
            >
                <div *ngIf="!isReceivingOverrideEnabled" class="mb-2">
                    <div class="mb-2">
                        When money receiving from this contact fee and payment methods will be defined by Money
                        Transfers preferences.
                    </div>
                    <div class="d-flex w-70 align-items-center justify-content-start">
                        <button
                            class="mr-2"
                            type="button"
                            mat-raised-button
                            (click)="isReceivingOverrideEnabled = true"
                        >
                            Modify
                        </button>
                    </div>
                </div>

                <div *ngIf="isReceivingOverrideEnabled">
                    <div class="d-flex mb-2">
                        <div class="d-flex w-70 align-items-center justify-content-start">
                            When money receiving from this contact I prefer payment method
                        </div>
                        <div class="d-flex w-30 align-items-center justify-content-center">
                            <mat-form-field class="mr-2">
                                <mat-select [placeholder]="'Payment Method'" formControlName="velocity">
                                    <mat-option *ngFor="let type of receiverVelocityTypes" [value]="type">
                                        {{ type === 'instant' ? 'Expedited' : (type | fromSnakeCase) }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="d-flex mb-2">
                        <div class="d-flex w-70 align-items-center justify-content-start">
                            Reject receiving money if this payment method is not available.
                        </div>
                        <div class="d-flex w-30 align-items-center justify-content-center">
                            <mat-checkbox
                                style="margin-top: 10px"
                                formControlName="is_downgrade_restricted"
                            ></mat-checkbox>
                        </div>
                    </div>

                    <div class="d-flex mb-2">
                        <div class="d-flex w-70 align-items-center justify-content-start">
                            <button
                                class="mr-2"
                                type="button"
                                mat-raised-button
                                (click)="removeContactTransferPreference('when_receiving')"
                            >
                                Restore
                            </button>
                        </div>
                        <div class="d-flex w-30 align-items-center justify-content-center">
                            <button
                                class="ml-2 mr-2"
                                type="button"
                                color="primary"
                                mat-raised-button
                                *ngIf="
                                    !receivingTransferPreference ||
                                    (receivingTransferPreferenceForm &&
                                        receivingTransferPreference &&
                                        (receivingTransferPreferenceForm.controls.velocity?.value !==
                                            receivingTransferPreference.velocity ||
                                            receivingTransferPreferenceForm.controls.is_downgrade_restricted?.value !==
                                                receivingTransferPreference.restrict_auto_downgrade))
                                "
                                (click)="saveTransferPreference('when_receiving')"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex flex-wrap mt-3">
        <!--        Receive Only Bank Account Section (ZilMoney)        -->
        <div style="width: 48%; min-width: 410px" *ngIf="isMuhneeAvailable">
            <h3>Direct Deposit {{ isMuhneeAvailable && isPayloadAvailable ? '(ZilMoney)' : '' }}</h3>
            <div
                class="mb-2"
                *ngIf="
                    (!contactZipiFinancialReceiveGateway ||
                        !contactZipiFinancialReceiveGateway.payment_methods ||
                        contactZipiFinancialReceiveGateway.payment_methods.length === 0) &&
                    isBankGatewayLoaded
                "
            >
                No Bank Accounts
            </div>
            <div *ngIf="contactZipiFinancialReceiveGateway && isReceiveOnlyFeatureFlagEnabled">
                <div *ngFor="let method of contactZipiFinancialReceiveGateway.payment_methods">
                    <div
                        *ngIf="method.is_available_for_payment && method.type !== 'zipi_financial_credit_card'"
                        class="d-flex mb-2 align-items-center"
                        [ngClass]="!method.is_visible_to_connect ? 'disabled-card' : ''"
                        [matTooltip]="'Not authorized to pay to this Account.'"
                        [matTooltipDisabled]="method.is_visible_to_connect"
                    >
                        <div class="d-flex align-items-center">
                            <mat-icon class="mr-1">account_balance</mat-icon>
                            <span>{{ method.title }}</span>
                            <span class="ml-1" *ngIf="method.expiration_date">({{ method.expiration_date }})</span>
                        </div>

                        <mat-icon
                            [rule]="{contacts__manage_payment_methods: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            *ngIf="contactZipiFinancialReceiveGateway"
                            (click)="deletePaymentMethod(method)"
                            class="sp-cursor-pointer ml-2"
                        >
                            delete
                        </mat-icon>
                    </div>
                </div>
            </div>
            <span matTooltip="Information is being loaded" matTooltipDisabled="{{ isBankGatewayLoaded }}">
                <button
                    [rule]="{contacts__manage_payment_methods: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    class="mb-2"
                    type="button"
                    mat-raised-button
                    (click)="setupZipiFinancialForContact('contact_bank', 'muhnee')"
                    *ngIf="isReceiveOnlyFeatureFlagEnabled"
                    [disabled]="!isBankGatewayLoaded"
                >
                    Add {{ isMuhneeAvailable && isPayloadAvailable ? 'ZilMoney' : '' }} Bank Account
                </button>
            </span>
        </div>
    </div>

    <div class="d-flex flex-wrap mt-3">
        <!--        Receive Only Bank Account Section (Payload.co)        -->
        <div style="width: 48%; min-width: 410px" *ngIf="isPayloadAvailable">
            <h3>Direct Deposit {{ isMuhneeAvailable && isPayloadAvailable ? '(Payload.co)' : '' }}</h3>
            <div
                class="mb-2"
                *ngIf="
                    (!contactPayloadReceiveGateway ||
                        !contactPayloadReceiveGateway.payment_methods ||
                        contactPayloadReceiveGateway.payment_methods.length === 0) &&
                    isBankGatewayLoaded
                "
            >
                No Bank Accounts
            </div>
            <div *ngIf="contactPayloadReceiveGateway">
                <div *ngFor="let method of contactPayloadReceiveGateway.payment_methods">
                    <div
                        *ngIf="method.is_available_for_payment && method.type !== 'zipi_financial_credit_card'"
                        class="d-flex mb-2 align-items-center"
                        [ngClass]="!method.is_visible_to_connect ? 'disabled-card' : ''"
                        [matTooltip]="'Not authorized to pay to this Account.'"
                        [matTooltipDisabled]="method.is_visible_to_connect"
                    >
                        <div class="d-flex align-items-center">
                            <mat-icon class="mr-1">account_balance</mat-icon>
                            <span>{{ method.title }}</span>
                            <span class="ml-1" *ngIf="method.expiration_date">({{ method.expiration_date }})</span>
                        </div>

                        <mat-icon
                            [rule]="{contacts__manage_payment_methods: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            *ngIf="contactPayloadReceiveGateway"
                            (click)="deletePaymentMethod(method)"
                            class="sp-cursor-pointer ml-2"
                        >
                            delete
                        </mat-icon>
                    </div>
                </div>
            </div>
            <span matTooltip="Information is being loaded" matTooltipDisabled="{{ isBankGatewayLoaded }}">
                <button
                    [rule]="{contacts__manage_payment_methods: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    class="mb-2"
                    type="button"
                    mat-raised-button
                    (click)="setupZipiFinancialForContact('contact_bank', 'payload')"
                    *ngIf="isReceiveOnlyFeatureFlagEnabled"
                    [disabled]="!isBankGatewayLoaded"
                >
                    Add {{ isMuhneeAvailable && isPayloadAvailable ? 'Payload.co' : '' }} Bank Account
                </button>
            </span>
        </div>
        <!--        PayloadCo Credit Card Section        -->
        <div style="width: 48%; min-width: 410px" *ngIf="isPayloadAvailable">
            <h3>Stored Cards</h3>
            <div
                class="mb-2"
                *ngIf="
                    (!contactPayloadCardGateway ||
                        !contactPayloadCardGateway.payment_methods ||
                        contactPayloadCardGateway.payment_methods.length === 0) &&
                    isCardGatewayLoaded
                "
            >
                No Cards
            </div>
            <div *ngIf="contactPayloadCardGateway">
                <div *ngFor="let method of contactPayloadCardGateway.payment_methods">
                    <div
                        *ngIf="method.is_available_for_payment && method.type === 'zipi_financial_credit_card'"
                        class="d-flex mb-2 align-items-center"
                        [ngClass]="!method.is_visible_to_connect ? 'disabled-card' : ''"
                        [matTooltip]="'Not authorized to charge this Card.'"
                        [matTooltipDisabled]="method.is_visible_to_connect"
                    >
                        <div class="d-flex align-items-center">
                            <mat-icon class="mr-1">credit_card</mat-icon>
                            <span>{{ method.title }}</span>
                            <span class="ml-1" *ngIf="method.expiration_date">({{ method.expiration_date }})</span>
                            <mat-icon
                                [rule]="{contacts__manage_payment_methods: true}"
                                rbac
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'inline-block'}"
                                [denyStyle]="{display: 'none'}"
                                *ngIf="contactPayloadCardGateway"
                                (click)="deletePaymentMethod(method)"
                                class="sp-cursor-pointer ml-2"
                            >
                                delete
                            </mat-icon>
                        </div>

                        <span class="sp-spacer"></span>
                    </div>
                </div>
            </div>
            <span matTooltip="Information is being loaded" matTooltipDisabled="{{ isCardGatewayLoaded }}">
                <button
                    [rule]="{marketplace__manage_zipipay_acquire_credit_card: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    class="mb-2"
                    type="button"
                    mat-raised-button
                    (click)="setupZipiFinancialForContact('contact_card', 'payload')"
                    [disabled]="!isCardGatewayLoaded"
                >
                    Add Credit Card
                </button>
            </span>
        </div>
    </div>
    <!--    <div class="d-flex w-100 justify-content-end mt-3"-->
    <!--         *ngIf="contact && contact.partner__company_fk_id && contactPartnerCompanyZipiFinancialSettings && contactPartnerCompanyZipiFinancialSettings.customer_ref && contactPartnerCompanyZipiFinancialSettings.target_contact_id">-->
    <!--        <a href="javascript:"-->
    <!--           [rule]="{contacts__manage_payment_methods: true}" rbac [denyMethod]="'style'"-->
    <!--           [allowStyle]="{display : 'inline-block'}" [denyStyle]="{display : 'none'}"-->
    <!--           (click)="openManage()">Manage My Personal Information and Payment Methods stored by this Contact.</a>-->
    <!--    </div>-->
</div>
