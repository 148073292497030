import {Component, Inject, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {currencyMaskitoOptions} from '@app/utilities/maskito';
import {DealPayoutsSource} from '@app/services/sources/deal-payouts.source';

@Component({
    selector: 'waiting-for-payouts-processing-dialog',
    templateUrl: 'waiting-for-payouts-processing-dialog.component.html',
    styleUrls: ['./waiting-for-payouts-processing-dialog.component.scss']
})
export class WaitingForPayoutsProcessingDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    processingPayoutIds: number[] = [];
    paymentMadeUniqueIds: Set<number> = new Set();

    constructor(
        public dialogRef: MatDialogRef<WaitingForPayoutsProcessingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {payoutIds: Array<number>},
        public dealPayoutsSource: DealPayoutsSource
    ) {}

    ngOnInit() {
        this.processingPayoutIds = this.data.payoutIds;
        this.dealPayoutsSource.payoutEvents.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            if (data.status === 'completed') {
                if (this.processingPayoutIds.includes(data.financial_transfer_id)) {
                    this.processingPayoutIds = this.processingPayoutIds.filter(
                        (id) => id !== data.financial_transfer_id
                    );
                    if (data.created_payment_made_id) {
                        this.paymentMadeUniqueIds.add(data.created_payment_made_id);
                    }
                }
            }
            if (this.processingPayoutIds.length === 0) {
                this.dialogRef.close(Array.from(this.paymentMadeUniqueIds));
            }
        });
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    protected readonly currencyMaskitoMask = currencyMaskitoOptions;
}
