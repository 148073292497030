import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-report-edit-bar-action',
    templateUrl: './action.component.html',
    styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit, OnDestroy {
    @Output() action: EventEmitter<string> = new EventEmitter<string>();
    @Input() reportId: number | null | undefined = null;
    @Input() isShowNext: boolean = true;
    @Input() enableSaveAndRun: boolean = false;

    isExistReport: boolean = false;

    ngOnInit() {
        this.isExistReport = !!this.reportId;
    }

    next() {
        this.action.emit('next');
    }

    save() {
        this.action.emit('save');
    }

    cancel() {
        this.action.emit('cancel');
    }

    delete() {
        this.action.emit('delete');
    }

    run() {
        this.action.emit('run');
    }

    saveAndRun() {
        this.action.emit('saveAndRun');
    }

    ngOnDestroy() {
        this.action.complete();
    }
}
