<div class="p-4">
    <div>
        <h4>Set your default ledger</h4>
        <p>
            Select a default Quickbooks Ledger for all unassigned SkySlope Books ledgers. <br />This applies only to
            bills being synced to QuickBooks.
        </p>
    </div>

    <div class="pt-3 pb-5">
        <mat-form-field [floatLabel]="'always'" class="w-25">
            <mat-label>Default QuickBooks Ledger</mat-label>
            <mat-select required [(value)]="defaultLedgerToUpdate">
                <mat-option *ngFor="let account of qbAccounts" [value]="account.Id">
                    {{ account.Name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div>
        <h4>Connect accounts</h4>
        <p>Assign your SkySlope Books ledgers to a QuickBooks ledger to have billing items appear in QuickBooks.</p>
    </div>

    <div class="table-container">
        <div style="padding-bottom: 100px">
            <table mat-table [dataSource]="dataSource" class="w-100 mapping-table">
                <ng-container matColumnDef="account_name">
                    <th mat-header-cell *matHeaderCellDef><b>Account Name</b></th>
                    <td mat-cell *matCellDef="let element">{{ element.account_name }}</td>
                </ng-container>

                <ng-container matColumnDef="account_code">
                    <th mat-header-cell *matHeaderCellDef><b>Account Code</b></th>
                    <td mat-cell *matCellDef="let element">{{ element.account_code }}</td>
                </ng-container>

                <ng-container matColumnDef="account_type">
                    <th mat-header-cell *matHeaderCellDef><b>Type</b></th>
                    <td mat-cell *matCellDef="let element">{{ element.account_type }}</td>
                </ng-container>

                <ng-container matColumnDef="mapped_qb_account">
                    <th mat-header-cell *matHeaderCellDef><b>Mapped QuickBooks Ledger</b></th>
                    <td mat-cell *matCellDef="let element">
                        <mat-form-field>
                            <mat-select
                                (selectionChange)="onSelectionChange($event, element)"
                                [(value)]="element.mapped_quickbooks_ledger"
                            >
                                <mat-option [value]="null"> No Mapping </mat-option>
                                <mat-option *ngFor="let account of qbAccounts" [value]="account.Id">
                                    {{ account.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>

        <div class="footer-container">
            <button mat-raised-button class="mat-primary ml-2 button print-btn" (click)="onSave()">Save</button>
        </div>
    </div>
</div>
