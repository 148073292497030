import {Component, Inject, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {Subject, firstValueFrom} from 'rxjs';
import {NotificationsService} from 'angular2-notifications';
import {ShipperContactsService} from 'app/services/api/shipper.contacts.service';

interface IDialogData {
    taxRecord: any;
}

@Component({
    selector: 'app-payroll-1099-tax-record-errors-dialog',
    templateUrl: 'payroll-1099-tax-record-errors-dialog.component.html',
    styleUrls: ['payroll-1099-tax-record-errors-dialog.component.css']
})
export class Payroll1099TaxRecordErrorsDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    protected taxRecordNotFound: boolean = false;
    protected emptyBox1: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<Payroll1099TaxRecordErrorsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData,
        private fb: UntypedFormBuilder,
        private changeDetector: ChangeDetectorRef,
        private ntfs: NotificationsService,
        private contactsService: ShipperContactsService
    ) {}

    async ngOnInit() {
        this.taxRecordNotFound = !this.data.taxRecord.contact_payroll_fk_id;
        this.emptyBox1 = this.data.taxRecord.other_income <= 0;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
