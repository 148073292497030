import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    FormArray,
    Validators,
    AbstractControl,
    FormControl
} from '@angular/forms';
import {IVendorCredit, IProduct, ICreditItem, IVendorCreditItem, IGroup} from '@cyberco-nodejs/zipi-typings';
import {of, pipe, Subject} from 'rxjs';
import {NotificationsService} from 'angular2-notifications';
import {select, Store} from '@ngrx/store';
import {catchError, filter, map, takeUntil, tap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {IFinanceState} from '../../../store/finance.reducer';
import {FetchProducts} from '../../../store/finance.actions';
import {selectProducts} from '../../../store/finance.selectors';
import {CreateProductDialogComponent} from '../../products/create-product-dialog/create-product-dialog.component';
import {VendorCreditsService} from 'app/services/api/finance/vendor-credits.service';
import {GenericFormArray} from 'app/entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {GroupsSource} from 'app/services/sources/groups.source';
import {FormGroupArray, FormGroupWithFormControls} from '../../../../../typings/common';
import {
    currencyMaskitoOptions,
    numberMaskitoOptions,
    unmaskCurrencyControlValue
} from '../../../../../utilities/maskito';
import {formatToDate} from '../../../../../utilities';

@Component({
    selector: 'app-edit-vendor-credit',
    templateUrl: 'edit-vendor-credit.component.html',
    styleUrls: ['edit-vendor-credit.component.scss']
})
export class EditVendorCreditComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    itemsArray: FormGroupArray = this.fb.array([this.createItem()]) as FormGroupArray;
    formGroup: FormGroupWithFormControls = this.fb.group({
        vendor__contact_fk_id: [null, [Validators.required]],
        vendor_credit_number: [null],
        reference: [null],
        vendor_credit_date: [new Date(), Validators.required],

        total_amount: [{value: 0, disabled: true}],
        items: this.itemsArray,

        allow_auto_apply: [false, Validators.required]
    }) as FormGroupWithFormControls;
    currencyMaskitoMask = currencyMaskitoOptions;
    numberMaskitoMask = numberMaskitoOptions;
    amountWithCurrencyMask: boolean[] = [];
    rateWithCurrencyMask: boolean[] = [];

    vendorCredit: IVendorCredit | null = null;
    disableEditItems: boolean = false;

    products: IProduct[] = [];

    emptyItem: ICreditItem = {
        rate: 0,
        quantity: 1,
        amount: 0,
        product_fk_id: null,
        name: null,
        description: null,
        ledger_account_fk_id: null,
        connected__deal_fk_id: null,
        connected__financial_transfer_fk_id: null,
        deal_connection_mode: null,
        order_index: 0,
        division__company_group_fk_id: null
    };

    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    savedContacts: Array<number> = [];

    groupList: IGroup[] = [];

    constructor(
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private store: Store<IFinanceState>,
        private vendorCreditsService: VendorCreditsService,
        private router: Router,
        private groupSrc: GroupsSource
    ) {}

    private validateForm() {
        if (!this.formGroup.valid) {
            this.ntfs.warn('Vendor Template is not valid');
            return false;
        }

        return true;
    }

    private createItem(item?: IVendorCreditItem, itemIndex: number = 0) {
        const group = this.fb.group({
            vendor_credit_item_id: [item ? item.vendor_credit_item_id : null],
            product_fk_id: [null],
            name: [{value: item ? item.name : null, disabled: item && item.product_fk_id}, Validators.required],
            description: [null],
            quantity: [1, Validators.required],
            rate: [0, Validators.required],
            amount: [{value: 0, disabled: true}],
            ledger_account_fk_id: [
                {value: item ? item.ledger_account_fk_id : null, disabled: item && item.product_fk_id},
                Validators.required
            ],
            connected__deal_fk_id: [item ? item.connected__deal_fk_id : null, []],
            connected__financial_transfer_fk_id: [item ? item.connected__financial_transfer_fk_id : null, []],
            deal_connection_mode: [item ? item.deal_connection_mode : null, []],
            order_index: [item && item.order_index],
            division__company_group_fk_id: [item ? item.division__company_group_fk_id : null, []],
            selected_divisions: new GenericFormArray<ChipNode>([]),
            selected_division_ids: [item ? item.division__company_group_fk_id : null],
            selected_full_ledger: null
        });

        group.controls.selected_divisions.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (changes && changes.length > 0) {
                group.controls.division__company_group_fk_id.setValue(changes[0].target_id);
            } else {
                group.controls.division__company_group_fk_id.setValue(null);
            }
        });

        const handleValueChanges = pipe(
            takeUntil(this.unsubscribe),
            tap(() => group.controls.amount!.patchValue(group.controls.quantity!.value * group.controls.rate!.value)),
            tap(() => {
                this.amountWithCurrencyMask[itemIndex] = String(group.controls.amount.value).length < 13;
            })
        );

        const unmaskRateFn = unmaskCurrencyControlValue(group.controls.rate!);

        group.controls.quantity!.valueChanges.pipe(handleValueChanges, takeUntil(this.unsubscribe)).subscribe();

        group.controls
            .rate!.valueChanges.pipe(
                // @ts-ignore
                unmaskRateFn,
                handleValueChanges,
                tap(() => {
                    this.rateWithCurrencyMask[itemIndex] = String(group.controls.rate.value).length < 13;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
        if (item) {
            group.patchValue(item);
        }
        return group;
    }

    addItem(item?: IVendorCreditItem) {
        this.itemsArray.push(this.createItem(item, this.itemsArray.length));
    }

    selectItem(itemGroup: UntypedFormGroup, product: IProduct) {
        itemGroup.controls.product_fk_id!.patchValue(product.product_id);
        itemGroup.controls.rate!.patchValue(product.price);
        itemGroup.controls.description!.patchValue(product.description);
        itemGroup.controls.ledger_account_fk_id!.patchValue(product.ledger_account_fk_id);
        itemGroup.controls.quantity!.patchValue(1);
        itemGroup.controls.name!.patchValue(product.name);

        itemGroup.controls.name!.disable();
        itemGroup.controls.ledger_account_fk_id!.disable();
    }

    removeProductFkId(itemGroup: UntypedFormGroup) {
        itemGroup.controls.name!.enable();
        itemGroup.controls.ledger_account_fk_id!.enable();
        itemGroup.controls.name!.patchValue('');
        itemGroup.controls.product_fk_id!.patchValue(null);
    }

    resetItem(itemGroup: UntypedFormGroup) {
        itemGroup.reset(this.emptyItem);
        itemGroup.controls.name!.enable();
    }

    private computeTotal = () => {
        const total = this.itemsArray.controls.reduce(
            (acc: number, itemGroup) => (itemGroup as UntypedFormGroup).getRawValue().amount + acc,
            0
        );
        this.formGroup.controls.total_amount!.patchValue(total);
    };

    getProducts(item: AbstractControl) {
        if ((item as UntypedFormGroup).getRawValue().name) {
            const filteredValue = (item as UntypedFormGroup).getRawValue().name.toLowerCase().trim();
            return this.products.filter((pr) => pr.name.toLowerCase().trim().includes(filteredValue));
        }

        return this.products;
    }

    createProduct() {
        const dialogRef = this.dialog.open(CreateProductDialogComponent);

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    createVendorCreditAsDraft() {
        if (this.validateForm() !== true) {
            return;
        }
        const vendorCredit: IVendorCredit = this.formGroup.getRawValue();
        vendorCredit.items = this.itemsArray.getRawValue().map((item, idx) => {
            return {...item, rate: Number(item.rate), order_index: idx + 1};
        });
        this.vendorCreditsService
            .createVendorCreditAsDraft(vendorCredit)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                if (res) {
                    this.ntfs.info(`Vendor Credit created`);
                    this.router.navigate(['/purchases/vendorcredits']);
                }
            });
    }

    createVendorCreditAsOpen() {
        if (this.validateForm() !== true) {
            return;
        }

        const vendorCredit: IVendorCredit = this.formGroup.getRawValue();
        vendorCredit.items = this.itemsArray.getRawValue().map((item, idx) => {
            return {...item, rate: Number(item.rate), order_index: idx + 1};
        });

        this.vendorCreditsService
            .createVendorCreditAsOpen(vendorCredit)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                if (res) {
                    this.ntfs.info(`Vendor Credit created`);
                    this.router.navigate(['/purchases/vendorcredits']);
                }
            });
    }

    updateVendorCredit() {
        if (!this.validateForm() || this.vendorCredit!.type === 'expense') {
            return;
        }

        const updatedVendorCredit = this.formGroup.getRawValue();

        if (
            this.vendorCredit!.total_amount !== updatedVendorCredit.total_amount &&
            this.vendorCredit!.payments!.length > 0
        ) {
            this.ntfs.warn(`Amount of Vendor Credit with payments cannot be changed.`);
            return;
        }

        this.vendorCreditsService
            .updateVendorCredit({
                ...updatedVendorCredit,
                vendor_credit_id: this.vendorCredit!.vendor_credit_id,
                items: this.itemsArray.getRawValue().map((item, idx) => {
                    return {...item, rate: Number(item.rate), order_index: idx + 1};
                })
            })
            .pipe(
                catchError((err) => of(null)),
                filter((act) => !!act),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.ntfs.info(`Vendor Credit updated`);
                this.router.navigate(['/purchases/vendorcredits']);
            });
    }

    deleteVendorCredit() {
        if (this.vendorCredit!.type === 'expense') {
            return;
        }

        if (this.vendorCredit && this.vendorCredit.vendor_credit_id) {
            this.vendorCreditsService
                .deleteVendorCredit(this.vendorCredit!.vendor_credit_id)
                .pipe(
                    catchError((err) => of(null)),
                    filter((act) => !!act),
                    takeUntil(this.unsubscribe)
                )
                .subscribe(() => {
                    this.ntfs.warn(`Vendor Credit deleted`);
                    this.router.navigate(['/purchases/vendorcredits']);
                });
        } else {
            this.ntfs.error(`Vendor Credit not loaded`);
        }
    }

    initEdit() {
        this.route.paramMap
            .pipe(
                map((pm) => +pm.get('id')!),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                this.vendorCreditsService
                    .getVendorCredit(id)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((vc) => {
                        if (!vc) {
                            return;
                        }
                        this.vendorCredit = vc;
                        this.formGroup.patchValue({
                            ...this.vendorCredit,
                            items: this.vendorCredit.items
                        });

                        this.itemsArray.removeAt(0);
                        this.vendorCredit.items!.forEach((item) => this.addItem(item));
                        if (this.vendorCredit.vendor__contact_fk_id) {
                            this.savedContacts = [this.vendorCredit.vendor__contact_fk_id];
                        }

                        // disable editing items until new items schema is created
                        this.disableEditItems = this.vendorCredit.type === 'expense';

                        if (this.vendorCredit.journal) {
                            for (const tr of this.vendorCredit.journal.line_items!) {
                                if (!!tr.reconciliation_fk_id || !!tr.matched__transaction_external_id) {
                                    this.disableEditItems = true;
                                    this.itemsArray.disable();
                                    break;
                                }
                            }
                        }
                    });
            });
    }

    ngOnInit() {
        this.store.dispatch(new FetchProducts());

        this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe)).subscribe((data) => {
            this.products = data;
        });

        this.initEdit();

        this.formGroup.controls.vendor_credit_date.patchValue(
            formatToDate(this.formGroup.controls.vendor_credit_date.value)
        );

        this.itemsArray.valueChanges
            .pipe(
                tap(() => this.computeTotal()),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.moneySenderCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<ChipNode>) => {
            if (values && values.length > 0) {
                // this.selectMoneySender(values[0].target_id);
                this.formGroup.controls.vendor__contact_fk_id.patchValue(values[0].target_id);
            } else {
                // this.deselectCustomer();
                this.formGroup.controls.vendor__contact_fk_id.patchValue(null);
            }
        });

        this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
            this.groupList = list.filter((group) => group.type === 'division') as IGroup[];
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
