import {Routes, RouterModule} from '@angular/router';
import {UserLayoutComponent} from '../../layouts/user-layout.component';
import {AuthBootstrapService} from '../../services/resolvers/auth-bootstrap.service';
import {IndexComponent} from './components/index/index.component';
import {AccessGuardService} from '../../services/resolvers/access-guard.service';

export const routes: Routes = [
    {
        path: 'insights',
        component: UserLayoutComponent,
        resolve: {
            authBootstrap: AuthBootstrapService
        },
        runGuardsAndResolvers: 'always',
        canActivate: [AccessGuardService],
        data: {
            title: 'Insights',
            access: {anyRule: [{dashboards__access: true}, {reports__view_dashboard: true}]}
        },
        children: [
            {
                path: '',
                component: IndexComponent
            }
        ]
    }
];

export const routing = RouterModule.forChild(routes);
