import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IFinanceState} from '../../../store/finance.reducer';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {map, filter, takeUntil} from 'rxjs/operators';
import {IContact, ICreditNote} from '@cyberco-nodejs/zipi-typings';
import {CreditsService} from '../../../services/credits.service';
import {NotificationsService} from 'angular2-notifications';

@Component({
    selector: 'app-view-credit-note',
    templateUrl: 'source-credit-note.component.html',
    styles: [
        `
            .zp-company-info {
                width: 100%;
            }

            .zp-company-title {
                font-weight: bold;
                margin-bottom: 0;
            }

            .zp-inveice_title {
                font-size: 33px;
                margin-bottom: 0;
            }

            .zp-invoice-num_value {
                font-size: 14px;
                letter-spacing: 0.07rem;
                font-weight: bold;
                margin-bottom: 25px;
                margin-top: -5px;
            }

            .zp-refference_due_title {
                margin-bottom: 0;
            }

            .zp-refference_due_value {
                font-size: 14px;
                margin-top: 0px;
                font-weight: bold;
                margin-bottom: 30px;
            }

            .zp-w-150 {
                width: 150px;
                display: inline-block;
            }

            .zp-balance-due {
                font-size: 20px;
            }

            .zp-total-nember {
                display: inline-block;
                width: 150px;
                text-align: right;
                padding-right: 0.75rem;
            }
        `
    ]
})
export class SourceCreditNoteComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    invoice: (ICreditNote & {contact: IContact}) | null = null;
    creditNoteId: number | null = null;
    creditNote: ICreditNote | null = null;

    constructor(
        private store: Store<IFinanceState>,
        private route: ActivatedRoute,
        private creditsService: CreditsService,
        private router: Router,
        protected ntfs: NotificationsService
    ) {}

    ngOnInit() {
        this.route.paramMap
            .pipe(
                map((pm) => {
                    const id = pm.get('id');
                    return id ? +id : null;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                this.creditNoteId = id;
                if (id) {
                    this.creditsService
                        .getSourceCustomerCreditById(id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((creditNote) => {
                            this.creditNote = creditNote;
                        });
                } else {
                    this.ntfs.error(`Vendor Credit id not found`);
                    this.router.navigate(['/purchases/vendorcredits']);
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
