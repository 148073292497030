export class DateRangeModel {
    date_from: Date | null = null;
    date_till: Date | null = null;
    days: number | null = null;
    type: string = DateRangeModel.type_SET.lifetime;

    static get type_SET() {
        return {
            lifetime: 'lifetime',
            days: 'days',
            specific_dates: 'specific_dates'
        };
    }
}
