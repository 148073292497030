import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-payment-type-selection-dialog',
    templateUrl: './payment-type-selection-dialog.component.html',
    styleUrls: ['./payment-type-selection-dialog.component.scss']
})
export class PaymentTypeSelectionDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public dialogRef: MatDialogRef<PaymentTypeSelectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {}

    close(value: string | null) {
        this.dialogRef.close(value);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
