import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {DealService} from '../../../../../../../services/deal.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import {Deal} from '../../../../../../../models/deal';
import {DealsBoard, DealsBoardFrontendFilterSettings, DealTemplateEntity} from '../../../common/deal.models';
import {Router} from '@angular/router';
import {AuthService} from '../../../../../../../services/auth.service';
import {SessionService} from '../../../../../../../services/session.service';
import {DealTemplatesSource} from '../../../common/dealTemplates.service';
import {BehaviorSubject, from, Subject} from 'rxjs';
import {AngularFireStorage, BUCKET} from '@angular/fire/compat/storage';
import {environment} from '../../../../../../../../environments/environment';
import {UiModsSource} from '../../../../../../ui-mods/ui-mods.source';
import {DeleteDealsWarningComponent} from '../delete-deals-warning/delete-deals-warning.component';
import {debounceTime, filter, takeUntil} from 'rxjs/operators';
import {MatDrawer} from '@angular/material/sidenav';
import {Location} from '@angular/common';
import {ExpandedDealListItemComponent} from './expanded-deal-list-item/expanded-deal-list-item.component';
import {select, Store} from '@ngrx/store';
import {IDealState} from '../../../store/deal.reducer';
import {
    AddDealToRemoveListAction,
    ClearDealToRemoveListAction,
    RmDealToRemoveListAction
} from '../../../store/deal.actions';
import {selectDealsIdsToRemove} from '../../../store/deal.selectors';
import {DealProcessingService} from '../../../deal-processing.service';
import {NotificationsService} from 'angular2-notifications';
import {FeatureFlagsService} from '@app/modules/feature-flags/feature-flags.service';

export function makeBucketName(env: any) {
    return [env.namespace, env.firebase.projectId, env.firestoreDataCSVUploadBucket].join('_');
}

@Component({
    selector: 'app-deal-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    providers: [{provide: BUCKET, useValue: makeBucketName(environment)}, AngularFireStorage]
})
export class ListComponent implements OnInit, OnDestroy, OnChanges {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeRefresh: Subject<void> = new Subject();

    @ViewChildren('dealComponent') dealComponents: QueryList<ExpandedDealListItemComponent> | undefined = undefined;
    @ViewChild('sidenav', {static: true}) sidenav: MatDrawer | undefined = undefined;

    @Input() dealsBoard: DealsBoard | undefined = undefined;
    @Input() createBoardMode: boolean = false;
    @Input() offsetHeight$: Subject<number> = new Subject<number>();
    @Input() boardsList: DealsBoard[] = [];
    @Input() activeTabIndex: number = 0;
    @Output() createBoardModeEmitter = new EventEmitter();
    @Output() savedActiveTabEmitter = new EventEmitter();
    @Output() dealsBoardRemoved = new EventEmitter();

    containerCssHeight: string = 'calc(100vh - 51px)';
    previousDealBoardId: number | undefined | null = undefined;

    deals: Deal[] = [];
    DEAL = Deal;
    boardMeta: {[key: string]: number} = {};

    isMenuOptions: boolean = false;
    showMore: boolean = true;
    limit: number = 20;
    offset: number = 0;
    checkViewport$: Subject<void> = new Subject<void>();
    scrollToIndex$: Subject<number> = new Subject<number>();

    boardChanged$: Subject<DealsBoard> = new Subject<DealsBoard>();
    boardUpdated$: Subject<DealsBoard> = new Subject<DealsBoard>();
    frontendFilter$: Subject<DealsBoardFrontendFilterSettings> = new Subject<DealsBoardFrontendFilterSettings>();
    filters:
        | {statuses: string[]; types: string[]; disbursement_statuses: string[]; property_classes: string[]}
        | undefined = undefined;

    // it defines height of lazy loading list
    offsetTopToLazyLoading$: BehaviorSubject<number> | undefined = undefined;

    selectedDeals: {id: number; dual_deal_exists: boolean}[] = [];

    public dealTemplates: {[id: number]: DealTemplateEntity} | null = null;

    public isBusy: boolean = false;

    sidenavStyle = {'min-width': '100%'};
    dealNameStyle = {'max-width': 'none'};
    selectedDeal: Deal | undefined = undefined;
    isEditSelectedDealAllowed: boolean = false;

    dealIsSelected$: BehaviorSubject<Deal | null> = new BehaviorSubject<Deal | null>(null);
    sidebarOpened$: Subject<string> = new Subject<string>();
    isTopBarButtonsLocked: boolean;

    currentSidebarTab: string = '';
    isDealOverridden: boolean = false;

    public defaultBoardIndex: number = 0;

    isPayStubPrintAvailable: boolean = false;

    constructor(
        public dealService: DealService,
        public dealProcessing: DealProcessingService,
        public router: Router,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected dealTemplatesSource: DealTemplatesSource,
        private storage: AngularFireStorage,
        protected dialog: MatDialog,
        public uiModsSource: UiModsSource,
        private changeDetector: ChangeDetectorRef,
        public location: Location,
        private store: Store<IDealState>,
        private ntfs: NotificationsService,
        protected featureFlagsService: FeatureFlagsService
    ) {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((flags) => {
                this.isPayStubPrintAvailable = this.featureFlagsService.isFeatureEnabled('purchases:pay_stub');
            });
        this.dealTemplatesSource.dealTemplates.pipe(takeUntil(this.unsubscribe)).subscribe((templates) => {
            this.dealTemplates = templates;
        });
        this.isTopBarButtonsLocked = true;
    }

    async ngOnInit() {
        this.deals = [];

        this.uiModsSource.createDealMenuOptions.pipe(takeUntil(this.unsubscribe)).subscribe((menu_options) => {
            this.isMenuOptions = menu_options.length > 0;
        });

        // subscribe on filters that are always shown
        this.frontendFilter$.pipe(debounceTime(500), takeUntil(this.unsubscribe)).subscribe((settings) => {
            // cancel previous requests
            this.unsubscribeRefresh.next();

            this.applyFrontendFilter(settings);
            this.refresh();
        });

        // subscribe on changing board
        // from deal view
        this.boardChanged$
            .pipe(
                filter((board) => !!board),
                filter((board) => board.id !== this.previousDealBoardId),
                takeUntil(this.unsubscribe)
            )
            .subscribe((board) => {
                this.previousDealBoardId = board.id;
                this.dealsBoard = board;
                this.applyFrontendFilter(this.dealsBoard.settings_frontend);
                this.refresh(true);
            });
        // from deal edit
        this.boardUpdated$
            .pipe(
                filter((board) => !!board),
                takeUntil(this.unsubscribe)
            )
            .subscribe((board) => {
                this.dealsBoard = board;
                this.applyFrontendFilter(this.dealsBoard.settings_frontend);
                this.refresh(true);
            });

        this.offsetHeight$.pipe(takeUntil(this.unsubscribe)).subscribe((height: number) => {
            if (!this.selectedDeal) {
                if (this.offsetTopToLazyLoading$) {
                    this.offsetTopToLazyLoading$.next(height + 51);
                } else {
                    this.offsetTopToLazyLoading$ = new BehaviorSubject(height + 51);
                }
                this.changeDetector.detectChanges();
            }
        });

        if (this.regexUrl()) {
            // if page is uploaded with ID in URL
            this.handleSelectDeal({id: Number(this.router.url.split('/')[2])} as Deal);
        }

        this.store.pipe(select(selectDealsIdsToRemove), takeUntil(this.unsubscribe)).subscribe((ids) => {
            if (!ids.length && this.selectedDeals.length) {
                this.selectedDeals = [];
            }
        });
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.boardsList && changes.boardsList.currentValue) {
            this.dealsBoard = this.boardsList.find((b) => b.default as boolean);
            // if was deleted default deal
            if (!this.dealsBoard) {
                this.dealsBoard = this.boardsList[0];
            }

            // load filters
            if (this.dealsBoard) {
                this.applyFrontendFilter(this.dealsBoard.settings_frontend);
            }

            if (this.dealsBoard && this.dealsBoard.deals && this.dealsBoard.deals.data) {
                this.deals = this.dealsBoard.deals.data;
                this.boardMeta = this.dealsBoard.deals._meta;
                this.showMore = this.deals.length === this.limit;
                // if was deleted default deal
            } else {
                this.deals = [];
                this.offset = 0;
                await this.refresh(true);
            }

            this.defaultBoardIndex = this.boardsList.findIndex((b) => b.default as boolean);
            // if was deleted default deal
            if (this.defaultBoardIndex === -1) {
                this.defaultBoardIndex = 0;
            }
            this.activeTabIndex = this.defaultBoardIndex;
        }
        if (changes.createBoardMode && changes.createBoardMode.currentValue === false) {
            if (this.boardsList && this.dealsBoard && this.dealsBoard.deals) {
                this.defaultBoardIndex = this.boardsList.findIndex((b) => b.default as boolean);
                this.deals = this.dealsBoard.deals.data;
                this.boardMeta = this.dealsBoard.deals._meta;
                this.showMore = this.deals.length === this.limit;
            }
        }
    }

    nextBatch(index: number) {
        if (this.showMore) {
            this.checkViewport$.next();
            this.showMore = false;
            return this.doShowMore();
        }
    }

    async doShowMore() {
        this.offset += this.limit;
        await this.refresh();
    }

    refresh(metaIsNeeded: boolean = false) {
        const offset = this.offset;
        if (typeof this.dealsBoard === 'undefined') {
            return;
        }

        // as this is not inborn observable, requests will be done but result will be not used
        return from(
            this.dealService.getDealsFilteredList(
                this.dealsBoard.id as number,
                Object.assign({offset: this.offset, limit: this.limit}, this.filters, {
                    meta_is_needed: metaIsNeeded ? 1 : 0
                })
            )
        )
            .pipe(takeUntil(this.unsubscribeRefresh))
            .subscribe((result) => {
                if (result._meta) {
                    this.boardMeta = result._meta;
                }
                if (this.offset) {
                    this.deals = this.deals.concat(result.data);
                } else {
                    this.deals = result.data;
                }

                this.showMore = result.data.length === this.limit;

                if (!offset) {
                    setTimeout(() => this.scrollToIndex$.next(0));
                    setTimeout(() => this.checkViewport$.next());
                }
            });
    }

    private async applyFrontendFilter(settings: DealsBoardFrontendFilterSettings) {
        this.filters = {
            statuses: Object.keys(settings.statuses).filter((key: string) => settings.statuses[key]),
            types: Object.keys(settings.types).filter((key) => settings.types[key]),
            disbursement_statuses: Object.keys(settings.disbursement_statuses).filter(
                (key) => settings.disbursement_statuses[key]
            ),
            property_classes: Object.keys(settings.property_classes).filter((key) => settings.property_classes[key])
        };

        this.offset = 0;
    }

    toggleDealCheckbox(data: {control: MatCheckboxChange; deal: Deal}) {
        const dealId = Number(data.control.source.value);
        const checked = data.control.checked;

        if (checked) {
            this.selectedDeals.push({id: dealId, dual_deal_exists: !!data.deal.dual_deal_id});
            this.store.dispatch(new AddDealToRemoveListAction(dealId));
        } else {
            this.selectedDeals.splice(
                this.selectedDeals.findIndex((i) => i.id === dealId),
                1
            );
            this.store.dispatch(new RmDealToRemoveListAction(dealId));
        }
    }

    downloadPayStub() {
        const dealIds = this.selectedDeals.map((item) => item.id);
        if (dealIds.length > 0) {
            this.dealService
                .orderPayStubGeneration(dealIds)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response) {
                        this.selectedDeals = [];
                        this.ntfs.success(
                            `PayStub${dealIds.length > 1 ? 's' : ''} created.`,
                            ` It can be found and opened in the top notifications bell when complete.`
                        );
                    }
                });
        }
    }

    recalculate() {
        return this.dealService.recalculateDeals();
    }

    archiveDeals() {
        return this.dealService.archiveDeals(this.selectedDeals.map((i) => i.id)).then((response) => {
            this.offset = 0;
            this.refresh();
        });
    }

    removeDeals() {
        const dialogRef = this.dialog.open(DeleteDealsWarningComponent, {
            data: {
                dualDealExists: this.selectedDeals.some((i) => i.dual_deal_exists)
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.dealService.bulkDelete(this.selectedDeals.map((i) => i.id)).then((response) => {
                        if (response) {
                            this.ntfs.success(
                                `Deletion Completed.`,
                                `
                                ${response.skipped > 0 ? `${response.skipped} ${response.skipped === 1 ? 'deal was' : 'deals were'} skipped. <br>` : ''}
                                ${response.deleted} ${response.deleted === 1 ? 'deal was' : 'deals were'} deleted.
                                `
                            );
                        }
                        this.offset = 0;
                        this.store.dispatch(new ClearDealToRemoveListAction());
                        this.refresh();
                    });
                }
            });
    }

    async checkIfEditSelectedDealAllowed(loadedViewDeal: Deal) {
        const primarySalesEntity = loadedViewDeal.sales_entities.find((se) => se.is_primary);
        this.isEditSelectedDealAllowed = await this.dealService.checkIfEditDealAllowed(
            primarySalesEntity?.owner__profile_fk_id
        );
    }

    uploadFileToBucket(filePath: string, file: File): any {
        return this.storage.upload(filePath, file).percentageChanges();
    }

    async handleSelectDeal(deal: Deal) {
        this.selectedDeal = deal;
        this.store.dispatch(new ClearDealToRemoveListAction()); // deselect deals to remove
        this.location.go('/deals/' + deal.id);

        this.sidenavStyle = {'min-width': '290px'};
        this.dealNameStyle = {'max-width': '220px'};

        if (typeof this.sidenav !== 'undefined') {
            this.sidenav.open();
        }

        this.processSelectedDeal(this.selectedDeal);

        if (this.offsetTopToLazyLoading$) {
            this.offsetTopToLazyLoading$.next(150);
        } else {
            this.offsetTopToLazyLoading$ = new BehaviorSubject(150);
        }
        this.containerCssHeight = `calc(100vh - 100px)`;
        this.changeDetector.detectChanges();
    }

    private processSelectedDeal(activeDeal: Deal): void {
        this.dealIsSelected$.next(activeDeal);
    }

    public regexUrl(): boolean {
        return RegExp('/[0-9]').test(this.location.path());
    }

    changeTopBarButtonsState(event: boolean) {
        this.isTopBarButtonsLocked = event;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeRefresh.next();
        this.unsubscribeRefresh.complete();
        this.checkViewport$.complete();
        this.scrollToIndex$.complete();
        this.frontendFilter$.complete();
        this.offsetHeight$.complete();
        if (this.offsetTopToLazyLoading$) {
            this.offsetTopToLazyLoading$.complete();
        }
        this.boardChanged$.complete();
        this.boardUpdated$.complete();
        this.dealIsSelected$.complete();
        this.sidebarOpened$.complete();
        this.createBoardModeEmitter.complete();
        this.savedActiveTabEmitter.complete();
        this.dealsBoardRemoved.complete();
        this.store.dispatch(new ClearDealToRemoveListAction());
    }
}
