<form
    mat-dialog-content
    [formGroup]="contactForm"
    class="bg-white p-3 row contact-create-dialog__form"
    (ngSubmit)="createOrUpdateContact()"
    (keydown.enter)="$event.preventDefault()"
>
    <section class="row no-gutters">
        <div class="col-md-8">
            <mat-form-field class="w-100">
                <mat-placeholder *ngIf="contactForm.controls.display_name!.value!.length > 0"
                    >Display Name</mat-placeholder
                >
                <input matInput required type="text" formControlName="display_name" />
            </mat-form-field>
        </div>

        <div class="col-md-4">
            <div class="w-100 d-flex align-items-center">
                <mat-form-field class="w-100">
                    <mat-label>Categories</mat-label>
                    <mat-select #contactTypeSelect formControlName="contact_type_ids" multiple>
                        <mat-option *ngFor="let contactType of contactTypes" [value]="contactType.id">
                            {{ contactType.title }} ({{ contactType.metatype }})
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-icon *ngIf="contact?.related__profile_fk_id" matTooltip="Company Member">people</mat-icon>
            </div>
        </div>
    </section>

    <section class="row no-gutters">
        <div class="col-md-6">
            <mat-radio-group [formControl]="isCompanyCtrl">
                <mat-radio-button [value]="false"> Individual </mat-radio-button>
                <mat-radio-button [value]="true"> Company </mat-radio-button>
            </mat-radio-group>
        </div>
    </section>

    <mat-tab-group #tabs>
        <mat-tab label="People">
            <div formArrayName="contact_persons" class="my-3">
                <ng-container *ngFor="let person of personsArrayCtrl!.controls; let ii = index">
                    <div [formGroupName]="ii" class="d-flex align-items-md-center bg-light rounded p-2 mb-2">
                        <mat-icon
                            class="mr-2"
                            *ngIf="
                                person.value.type === 'department' ||
                                (person.value.type === 'main_person' && isCompanyCtrl.value)
                            "
                            >domain</mat-icon
                        >
                        <mat-icon
                            class="mr-2"
                            *ngIf="
                                person.value.type === 'person' ||
                                (person.value.type === 'main_person' && !isCompanyCtrl.value)
                            "
                            >person_outline</mat-icon
                        >
                        <div class="row align-items-end w-100 d-nline-flex">
                            <mat-form-field class="col-md-6 col-lg-4">
                                <input
                                    matInput
                                    formControlName="first_name"
                                    type="text"
                                    [value]="person.value.first_name"
                                    [placeholder]="
                                        person.value.type === 'person' ||
                                        (person.value.type === 'main_person' && !isCompanyCtrl.value)
                                            ? 'First Name'
                                            : person.value.type === 'department'
                                              ? 'Department name'
                                              : 'Company Name'
                                    "
                                />
                            </mat-form-field>

                            <mat-form-field
                                class="col-md-6 col-lg-4"
                                *ngIf="
                                    person.value.type === 'person' ||
                                    (person.value.type === 'main_person' && !isCompanyCtrl.value)
                                "
                            >
                                <input
                                    matInput
                                    formControlName="last_name"
                                    type="text"
                                    placeholder="Last Name"
                                    [value]="person.value.last_name"
                                />
                            </mat-form-field>

                            <mat-form-field
                                class="col-md-6 col-lg-4"
                                *ngIf="
                                    person.value.type === 'person' ||
                                    (person.value.type === 'main_person' && !isCompanyCtrl.value)
                                "
                            >
                                <input matInput placeholder="Nickname" formControlName="preferred_name" />
                            </mat-form-field>

                            <div class="col-md-6 col-lg-4">
                                <mat-form-field>
                                    <input
                                        matInput
                                        formControlName="phone"
                                        type="text"
                                        placeholder="Phone"
                                        #phoneInput
                                        [maskito]="phoneMaskitoInputMask"
                                        (blur)="onBlurMethod(ii, 'phone')"
                                        (click)="onClickMethod(ii, 'phone')"
                                    />
                                </mat-form-field>
                                <!--Cannot use mat-error because contactForm.get('contact_persons') is not a FormArray-->
                                <div class="line-underline" *ngIf="errors && errors[ii] && errors[ii].phone"></div>
                                <div class="text-underline" *ngIf="errors && errors[ii] && errors[ii].phone">
                                    Phone must be 10 digits long.
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-4">
                                <mat-form-field>
                                    <input
                                        matInput
                                        formControlName="fax"
                                        type="text"
                                        placeholder="Fax"
                                        #faxInput
                                        [maskito]="phoneMaskitoInputMask"
                                        (blur)="onBlurMethod(ii, 'fax')"
                                        (click)="onClickMethod(ii, 'fax')"
                                    />
                                </mat-form-field>
                                <!--Cannot use mat-error because contactForm.get('contact_persons') is not a FormArray-->
                                <div class="line-underline" *ngIf="errors && errors[ii] && errors[ii].fax"></div>
                                <div class="text-underline" *ngIf="errors && errors[ii] && errors[ii].fax">
                                    Fax must be 10 digits long.
                                </div>
                            </div>

                            <mat-form-field class="col-md-6 col-lg-4">
                                <input
                                    matInput
                                    formControlName="email"
                                    type="email"
                                    placeholder="Email"
                                    [required]="person.get('email')!.getError('required')"
                                />
                                <mat-error>This field is required</mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-12">
                                <mat-select
                                    placeholder="Default Location (coming soon...)"
                                    formControlName="default_location"
                                    [compareWith]="comparePeriod"
                                >
                                    <mat-option (click)="createNewLocation($event)">Create new location...</mat-option>
                                    <mat-option [value]="null">None</mat-option>
                                    <mat-option *ngFor="let location of locationsArrayCtrl!.value" [value]="location">
                                        <span *ngIf="location.label">{{ location.label + ':' }} </span>
                                        <span *ngIf="location.street_number">{{ location.street_number }} </span>
                                        <span *ngIf="location.street_address">{{ location.street_address }} </span>
                                        <span *ngIf="location.unit_number">{{ location.unit_number }} </span>
                                        <span *ngIf="location.city">{{ location.city + ',' }} </span>
                                        <span *ngIf="location.state">{{ location.state }} </span>
                                        <span *ngIf="location.zip">{{ location.zip }} </span>
                                        <span *ngIf="location.country" class="text-muted"
                                            >({{ location.country }})</span
                                        >
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <button
                            mat-icon-button
                            type="button"
                            *ngIf="person.value.type !== 'main_person'"
                            (click)="removePerson(ii, person)"
                            matTooltip="Remove"
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                        <div style="width: 40px; height: 40px" *ngIf="person.value.type === 'main_person'"></div>
                    </div>
                </ng-container>

                <button type="button" mat-button (click)="addPerson()" matTooltip="Add person">
                    <mat-icon class="mr-1">add</mat-icon>
                    Person
                </button>
                <button
                    type="button"
                    mat-button
                    (click)="addPerson(undefined, true)"
                    matTooltip="Add department"
                    class="ml-1"
                >
                    <mat-icon class="mr-1">add</mat-icon>
                    Department
                </button>
            </div>
        </mat-tab>

        <mat-tab label="Locations" *ngIf="profilePermissions && profilePermissions.contacts__view_locations">
            <div class="mb-2" formArrayName="contact_locations">
                <app-contact-location
                    *ngFor="let locationCtrl of locationsArrayCtrl!.controls as any; index as idx; first as fst"
                    [locationCtrl]="$any(locationCtrl)"
                    [removeLocation]="removeLocation(idx)"
                    [isEditingMode]="idx === editLocationIdx"
                >
                </app-contact-location>

                <div class="zp-place-picker">
                    <app-gplaces-picker [locationCtrl]="locationPickerCtrl" [focused]="true"></app-gplaces-picker>
                </div>
                <button
                    type="button"
                    mat-raised-button
                    (click)="editLocationIdx = addLocation()"
                    matTooltip="Add location"
                >
                    <mat-icon class="mr-1">add_location</mat-icon>Add Location
                </button>
            </div>
        </mat-tab>

        <mat-tab
            label="Compliance"
            *ngIf="
                contact?.related__profile_fk_id && profilePermissions && profilePermissions.contacts__view_compliance
            "
        >
            <h4>Compliance Here</h4>

            <div *ngIf="profileForm" [formGroup]="profileForm" class="row w-100 d-nline-flex">
                <div class="col col-sm-6 col-md-6">
                    <mat-form-field class="w-100">
                        <input
                            matInput
                            class=""
                            placeholder="Nickname"
                            name="preferred_name"
                            formControlName="preferred_name"
                        />
                    </mat-form-field>
                </div>

                <div class="col cos-sm-6 col-md-6">
                    <app-date-picker
                        [placeholder]="'Date of Birth'"
                        [dateControl]="profileForm.controls!.date_of_birth"
                    >
                    </app-date-picker>
                </div>

                <div class="col col-sm-6">
                    <mat-form-field class="w-100">
                        <input
                            matInput
                            class=""
                            placeholder="Number"
                            name="license_number"
                            formControlName="license_number"
                        />
                    </mat-form-field>
                </div>

                <div class="col col-sm-6">
                    <mat-form-field class="w-100">
                        <input
                            matInput
                            [matDatepicker]="picker_expiration"
                            name="license_expiration"
                            formControlName="license_expiration"
                            placeholder="Expiration"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker_expiration"></mat-datepicker-toggle>
                        <mat-datepicker #picker_expiration></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col col-sm-6">
                    <app-date-picker [placeholder]="'Hire Date'" [dateControl]="profileForm.controls!.hire_date">
                    </app-date-picker>
                </div>

                <div class="col col-sm-6">
                    <app-date-picker
                        [placeholder]="'Termination Date'"
                        [dateControl]="profileForm.controls!.termination_date"
                    >
                    </app-date-picker>
                </div>

                <div class="col col-sm-6">
                    <div class="w-100">
                        <app-date-picker
                            [placeholder]="'Anniversary Date'"
                            [dateControl]="profileForm.controls!.anniversary_date"
                        >
                        </app-date-picker>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Notes" *ngIf="profilePermissions && profilePermissions.contacts__view_notes && contact">
            <mat-form-field class="w-100 mt-3">
                <textarea matInput matTextareaAutosize formControlName="notes" placeholder="Notes"> </textarea>
                <mat-hint align="end">Characters {{ contactForm.get('notes')?.value?.length }} / 5000</mat-hint>
            </mat-form-field>
        </mat-tab>
    </mat-tab-group>

    <div mat-dialog-actions>
        <div class="text-right w-100">
            <button type="submit" mat-raised-button [disabled]="isSubmitDisabled" color="primary">
                {{ data?.contact && data?.contact?.contact_id ? 'Update' : 'Create' }}
            </button>
            <button type="button" mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">Cancel</button>
        </div>
    </div>
</form>
