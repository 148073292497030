import {Component, Inject, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {of, Subject} from 'rxjs';
import {IContact, ICreditNote, IInvoice, IPaymentReceived} from '@cyberco-nodejs/zipi-typings';
import {MatTableDataSource} from '@angular/material/table';
import {PaymentsReceivedService} from '../../../../finance/services/payments-received.service';
import {catchError, takeUntil} from 'rxjs/operators';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {CreditNotesService} from 'app/services/api/finance/credit-notes.service';
import {Router} from '@angular/router';
import {FormGroupArray} from '../../../../../typings/common';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';

@Component({
    selector: 'app-apply-credit-to-invoices-dialog',
    templateUrl: 'apply-credit-to-invoices-dialog.component.html',
    styleUrls: ['apply-credit-to-invoices-dialog.component.scss']
})
export class ApplyCreditToInvoicesDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    displayedColumns = ['invoice_number', 'invoice_date', 'total_amount', 'pending_balance', 'amount_to_credit'];

    paymentReceived: IPaymentReceived | undefined;
    customerCredit: ICreditNote | undefined;
    contact: IContact | undefined;

    invoicesArray: FormGroupArray = this.fb.array([]) as FormGroupArray;
    formGroup: UntypedFormGroup = this.fb.group({invoices: this.invoicesArray});
    dataSource: MatTableDataSource<IInvoice>;

    balance: number = 0;
    amountToCredit: number = 0;
    remainingCredits: number = 0;
    currencyMaskitoMask = currencyMaskitoOptions;

    saveDisabled: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ApplyCreditToInvoicesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder,
        private changeDetector: ChangeDetectorRef,
        private paymentsReceivedService: PaymentsReceivedService,
        private invoicesService: InvoicesService,
        private creditNotesService: CreditNotesService,
        protected router: Router
    ) {
        this.dataSource = new MatTableDataSource<IInvoice>([]);
    }

    ngOnInit() {
        this.contact = this.data.contact;

        if (this.data.credit.payment_received_id) {
            this.paymentReceived = this.data.credit;
            this.balance = this.data.credit.over_payment;
        }
        if (this.data.credit.credit_note_id) {
            this.customerCredit = this.data.credit;
            this.balance = this.data.credit.balance;
        }
        this.remainingCredits = this.balance;

        if (this.contact && this.contact.contact_id) {
            this.invoicesService
                .getInvoicesForCreditsApply(this.contact.contact_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((invoices) => {
                    for (const invoice of invoices as Array<IInvoice & {amount_to_credit: number}>) {
                        const invoiceControl = this.fb.group({
                            invoice_id: [],
                            invoice_number: [],
                            invoice_date: [],
                            total_amount: [],
                            amount_to_credit: [],
                            pending_balance: []
                        });
                        const pendingBalance = invoice.pending_balance;

                        if (pendingBalance && this.remainingCredits >= pendingBalance) {
                            invoice['amount_to_credit'] = pendingBalance;
                            const remaining = this.remainingCredits - pendingBalance;
                            this.remainingCredits = Number(remaining.toFixed(2));
                        } else {
                            invoice['amount_to_credit'] = this.remainingCredits;
                            this.remainingCredits = 0;
                        }

                        invoice['pending_balance'] = pendingBalance;

                        invoiceControl.patchValue(invoice);
                        this.invoicesArray.push(invoiceControl);
                    }

                    this.amountToCredit = this.balance - this.remainingCredits;

                    this.dataSource.data = invoices;

                    this.calculateTotals();
                });
        }
    }

    onBlurInvoice(index: number) {
        if (this.invoicesArray) {
            const invoiceGroup: UntypedFormGroup = this.invoicesArray.at(index) as unknown as UntypedFormGroup;
            const amountFromInvoice = invoiceGroup.controls.amount_to_credit.value;
            const pendingBalance = invoiceGroup.controls.pending_balance.value;
            const unmaskAmount = Number(cleanCurrencyString(amountFromInvoice));

            const invoices = this.invoicesArray.getRawValue();
            const amountToCredit = invoices.reduce(
                (sum, item) => Number(cleanCurrencyString(item.amount_to_credit)) + sum,
                0
            );
            const realRemaining = Number((this.balance - amountToCredit + unmaskAmount).toFixed());

            if (unmaskAmount > realRemaining || unmaskAmount > pendingBalance) {
                const newAmount = realRemaining > pendingBalance ? pendingBalance : realRemaining;
                invoiceGroup.controls.amount_to_credit.setValue(newAmount);
            }

            this.calculateTotals();
        }
    }

    calculateTotals() {
        const invoicesNew = this.invoicesArray.getRawValue();
        const amountToCreditNew = invoicesNew.reduce(
            (sum, item) => Number(cleanCurrencyString(item.amount_to_credit)) + sum,
            0
        );

        this.amountToCredit = amountToCreditNew;
        this.remainingCredits = Number((this.balance - amountToCreditNew).toFixed());
    }

    routeToInvoice(element: any) {
        this.dialogRef.close();
        this.router.navigate(['sales/invoices', element.invoice_id]);
    }

    save() {
        const invoices = this.invoicesArray.getRawValue();
        const filteredInvoices: Array<{invoice_id: number; amount_to_credit: number}> = [];
        for (const inv of invoices) {
            const unmaskAmount = Number(cleanCurrencyString(inv.amount_to_credit));

            if (unmaskAmount !== 0) {
                filteredInvoices.push({
                    invoice_id: inv.invoice_id,
                    amount_to_credit: unmaskAmount
                });
            }
        }

        if (this.customerCredit && this.customerCredit.credit_note_id) {
            this.saveDisabled = true;

            this.creditNotesService
                .applyToInvoices(this.customerCredit.credit_note_id, filteredInvoices)
                .pipe(
                    catchError(() => {
                        this.saveDisabled = false;
                        return of(null);
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((data) => {
                    this.dialogRef.close(true);
                    this.saveDisabled = false;
                });
        }

        if (this.paymentReceived && this.paymentReceived.payment_received_id) {
            this.saveDisabled = true;

            this.paymentsReceivedService
                .applyToInvoices(this.paymentReceived.payment_received_id, filteredInvoices)
                .pipe(
                    catchError(() => {
                        this.saveDisabled = false;
                        return of(null);
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((data) => {
                    this.dialogRef.close(true);
                    this.saveDisabled = false;
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
