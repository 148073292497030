import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {NotificationsServiceZipi} from 'app/modules/notifications/notifications.service';
import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {AfterSignUpDialogComponent} from '../after-sign-up-dialog/after-sign-up-dialog.component';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    isLegal: boolean;

    constructor(
        protected authService: AuthService,
        protected usersService: UserService,
        protected notificationsService: NotificationsServiceZipi,
        private activateRoute: ActivatedRoute,
        protected router: Router,
        public dialog: MatDialog
    ) {
        this.isLegal = false;
    }

    ngOnInit() {
        this.activateRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (!params['redirect_hash'] || params['redirect_hash'] !== btoa('FromAdminPortal')) {
                window.location.replace('/auth/sign-in');
            } else {
                this.isLegal = true;
            }
        });
    }

    onSubmit(form: NgForm) {
        if (!form.valid) {
            return false;
        }

        if (form.value.password !== form.value.repeat_password) {
            this.notificationsService.addError('Passwords must be identical');
            return false;
        }

        const company = {
            title: form.value.account_name
                ? form.value.account_name
                : form.value.first_name + ' ' + form.value.last_name + ' Account'
        };

        const user = {
            first_name: form.value.first_name,
            last_name: form.value.last_name,
            email: form.value.email.toLowerCase(),
            firebase_id: ''
        };

        this.authService
            .createUserWithEmailAndPassword(user.email, form.value.password)
            .then((firebaseUserInfo) => {
                user.firebase_id = firebaseUserInfo.user!.uid;

                return this.afterRegister(user, company);
            })
            .catch((err) => {
                this.notificationsService.addError(err.message);
            });
    }

    tryGoogleAuth() {
        this.authService
            .signInWithGoogle()
            .then((firebaseUserCredential) => {
                const company = {
                    title: firebaseUserCredential.user!.displayName + ' Account'
                };

                const user = {
                    first_name: firebaseUserCredential.user!.displayName!.split(' ')[0],
                    last_name: firebaseUserCredential.user!.displayName!.split(' ')[1],
                    email: firebaseUserCredential.user!.email,
                    firebase_id: firebaseUserCredential.user!.uid
                };

                return this.afterRegister(user, company, true);
            })
            .catch((err) => {
                this.notificationsService.addError(err.message);
            });
    }

    protected afterRegister(user: any, company: any, fullPageReload = false) {
        return this.usersService.afterRegister(user, company).then(() => {
            return this.openAfterRegisterPopUp();
            // if (!fullPageReload) {
            //     this.router.navigate(['/default-page']);
            //
            //     return;
            // }
            //
            // window.location.replace('/default-page');
        });
    }

    openAfterRegisterPopUp() {
        const dialogRef = this.dialog.open(AfterSignUpDialogComponent, {
            minWidth: 320,
            data: {}
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((redirect) => {
                if (redirect) {
                    this.authService.logout().then(() => {
                        window.location.replace('/login');
                    });
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
