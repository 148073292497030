import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {QuickBooksService} from '../../../../services/api/addon/quickbooks/quickbooks.service';
import {LedgerAccountService} from '../../../../services/api/finance/ledger-accounts.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-quickbooks-connect',
    templateUrl: 'quickbooks-connect.component.html',
    styleUrls: []
})
export class QuickBooksConnectComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    redirectUrl: string | null;

    constructor(private quickBooksService: QuickBooksService) {
        this.redirectUrl = null;
    }

    public connectQB() {
        if (this.redirectUrl) {
            // Navigate to the QuickBooks OAuth portal to begin integration
            window.location.href = this.redirectUrl;
        }
    }

    ngOnInit() {
        this.quickBooksService
            .getRedirectUrl()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                if (response && response.authRedirect) {
                    this.redirectUrl = response.authRedirect;
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
