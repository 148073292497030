import {FinancialSource} from '../../../shared/components/financial-node/financial-source.model';

export class CompensationPlanModel {
    compensation_value: number = 0;
    compensation_type: string = CompensationPlanModel.compensation_type_SET.flat_fee;
    compensation_source: string = CompensationPlanModel.source_SET.gci;

    compensation_categorization: FinancialSource[] = [];
    compensation_categorization_template: FinancialSource[] = [new FinancialSource().setLabel('Flat Fee').setAmount(0)];

    static get source_SET() {
        return {
            commission: 'commission',
            gci: 'gci',
            sales_price: 'sales_price'
        };
    }

    static get compensation_type_SET() {
        return {
            deal: 'deal',
            flat_fee: 'flat_fee',
            flat_fee_sales_net: 'flat_fee_sales_net',
            percent: 'percent',
            percent_of_base_commission: 'percent_of_base_commission',
            percent_of_commission: 'percent_of_commission',
            percent_of_net_commission: 'percent_of_net_commission',
            percent_of_deal_net: 'percent_of_deal_net',
            percent_of_sales_total: 'percent_of_sales_total',
            percent_of_sales_net: 'percent_of_sales_net',
            percent_of_split: 'percent_of_split',
            percent_of_company_split: 'percent_of_company_split',
            percent_of_sales_entity_sales_split: 'percent_of_sales_entity_sales_split'
        };
    }
}
