<div class="deal-row deal-row-narrowed" *ngIf="deal">
    <div class="info-area d-flex flex-column justify-content-center h-100" (click)="selectedDealEmitter.emit(deal)">
        <div class="deal-name font-weight-bold pb-1">
            {{ deal.name }}
        </div>

        <div class="d-flex">
            <div class="deal-photo">
                <a
                    *ngIf="deal.external_deal_url"
                    target="_blank"
                    (click)="$event.stopPropagation()"
                    href="{{ deal.external_deal_url }}"
                >
                    <i class="material-icons">filter_none</i>
                    <img *ngIf="deal.image_url" [src]="deal.image_url" />
                    <ng-container
                        *ngIf="
                            !deal.image_url &&
                            deal.deal_template_id &&
                            dealTemplates &&
                            dealTemplates[deal.deal_template_id]
                        "
                    >
                        <img
                            *ngIf="!deal.image_url && dealTemplates[deal.deal_template_id]?.preset?.image_url"
                            [src]="dealTemplates[deal.deal_template_id]?.preset?.image_url || ''"
                        />
                        <img
                            *ngIf="!deal.image_url && !dealTemplates[deal.deal_template_id]?.preset?.image_url"
                            [src]="'assets/images/default-deal-image.png'"
                        />
                    </ng-container>
                    <img
                        *ngIf="
                            !deal.image_url &&
                            !(dealTemplates && deal.deal_template_id && dealTemplates[deal.deal_template_id])
                        "
                        [src]="'assets/images/default-deal-image.png'"
                    />
                </a>
                <ng-container *ngIf="!deal.external_deal_url">
                    <img *ngIf="deal.image_url" [src]="deal.image_url" />
                    <ng-container
                        *ngIf="
                            !deal.image_url &&
                            deal.deal_template_id &&
                            dealTemplates &&
                            dealTemplates[deal.deal_template_id]
                        "
                    >
                        <img
                            *ngIf="!deal.image_url && dealTemplates[deal.deal_template_id]?.preset?.image_url"
                            [src]="dealTemplates[deal.deal_template_id]?.preset?.image_url || ''"
                        />
                        <img
                            *ngIf="!deal.image_url && !dealTemplates[deal.deal_template_id]?.preset?.image_url"
                            [src]="'assets/images/default-deal-image.png'"
                        />
                    </ng-container>
                    <img
                        *ngIf="
                            !deal.image_url &&
                            !(dealTemplates && deal.deal_template_id && dealTemplates[deal.deal_template_id])
                        "
                        [src]="'assets/images/default-deal-image.png'"
                    />
                </ng-container>
            </div>

            <div class="deal-text-area">
                <div class="status-box status-box-narrowed" [ngClass]="deal.status">
                    <mat-icon class="status-icon" [ngClass]="deal.status">label</mat-icon>
                    {{ DEAL.status_LABELS[deal.status] }}
                </div>
                <div class="d-flex">
                    <div class="label">Type:</div>
                    <div class="value" *ngIf="deal.type">{{ DEAL.type_LABELS[deal.type] }}</div>
                </div>
                <div class="d-flex">
                    <div class="label">Close Date:</div>
                    <div
                        class="value"
                        *ngIf="deal.close_of_escrow"
                        [ngClass]="isDateInPast(deal.close_of_escrow) ? 'coe-overdue' : ''"
                    >
                        {{ deal.close_of_escrow | date: 'mediumDate' }}
                    </div>
                </div>
                <div class="d-flex">
                    <div class="label">Sale Price:</div>
                    <div class="value">
                        {{ deal.sales_price | currency: 'USD' : 'symbol' }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
