import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {GenericFormGroup} from '../../../../../../entites/generic.entity';
import {CompanyCalculation} from '../../../../../shared/components/calculations/calculations.model';
import {FinancialTransferEntity} from '../../../../../account-info/compensation/models/financial-transfer.entity';
import {DealProcessingService} from '../../deal-processing.service';
import {FinancialElementModel} from '../../../../../account-info/compensation/models/financial-element.model';
import {MatExpansionPanel} from '@angular/material/expansion';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-deal-expenses',
    styleUrls: ['./deal-expenses.component.scss'],
    template: `
        <mat-expansion-panel #panel class="mb-3">
            <mat-expansion-panel-header [collapsedHeight]="'64px'" (click)="clickOnPanel.emit()">
                <mat-panel-title style="align-items: center;">
                    <!--<button [disabled]="true" mat-icon-button matPrefix style="color: rgba(0, 0, 0, 0.87);">-->
                    <!--<mat-icon matTooltip="Will Show on Disbursement"-->
                    <!--style="margin-right: 2px">picture_as_pdf-->
                    <!--</mat-icon>-->
                    <!--</button>-->
                    <b class="label">Deal</b>
                </mat-panel-title>
                <!--                <mat-panel-description class="expenses-description"-->
                <!--                                       *ngIf="!panel.expanded">-->
                <!--                    <app-financial-node moneyClass="percent"-->
                <!--                                        [labelStyle]="{'font-size': '15px', 'color':'rgba(0, 0, 0, 0.57)'}"-->
                <!--                                        label="Deal NET"-->
                <!--                                        [financialNodeFG]="companyCalculationFG.controls.net_commission">-->
                <!--                    </app-financial-node>-->
                <!--                    <app-financial-node moneyClass="percent"-->
                <!--                                        [labelStyle]="{'font-size': '15px', 'color':'rgba(0, 0, 0, 0.57)'}"-->
                <!--                                        label="Sales NET"-->
                <!--                                        [financialNodeFG]="companyCalculationFG.controls.sales_net">-->
                <!--                    </app-financial-node>-->
                <!--                </mat-panel-description>-->
            </mat-expansion-panel-header>

            <div class="deal-options">
                <!--<div class="notes">-->
                <!--<mat-form-field class="w-100 spaced">-->
                <!--<input matInput placeholder="Disbursement Instructions" [formControl]="companyCalculationFG.controls.notes">-->
                <!--</mat-form-field>-->
                <!--</div>-->
                <div class="options">
                    <div class="options-body">
                        <button
                            mat-stroked-button
                            style="width: fit-content;"
                            class="mb-3"
                            [disabled]="companyCalculationFG.disabled"
                            [matMenuTriggerFor]="menu"
                        >
                            <mat-icon>add</mat-icon>
                            Add item
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="addFinancialTransfer('referral_expenses')">Referral</button>
                            <button mat-menu-item (click)="addFinancialTransfer('royalty_expenses')">Royalty</button>
                            <button mat-menu-item (click)="addFinancialTransfer('ancillary_incomes')">
                                Deal Income
                            </button>
                            <button mat-menu-item (click)="addFinancialTransfer('ancillary_expenses')">
                                Deal Expense
                            </button>
                            <!--                            <button mat-menu-item-->
                            <!--                                    (click)="addFinancialTransfer('sales_incomes')">-->
                            <!--                                Sales Income-->
                            <!--                            </button>-->
                            <button mat-menu-item (click)="addFinancialTransfer('sales_expenses')">
                                Sales Expense
                            </button>
                        </mat-menu>
                        <div
                            class="option-item"
                            *ngIf="companyCalculationFG!.controls.referral_expenses!.controls.length > 0"
                        >
                            <h3 class="option-title">Referral</h3>
                            <app-deal-transfers-collection
                                [isExpense]="true"
                                [origin]="FINANCIAL_ELEMENT_ENTITY.type_set.referral"
                                (deleteItem)="removeFinancialTransfer($event)"
                                [type]="FINANCIAL_TRANSFER_ENTITY.type_SET.referral"
                                buttonLabel="Referral"
                                [transfersFA]="companyCalculationFG!.controls.referral_expenses!"
                            ></app-deal-transfers-collection>
                        </div>
                        <div
                            class="option-item"
                            *ngIf="companyCalculationFG!.controls.royalty_expenses!.controls.length > 0"
                        >
                            <h3 class="option-title">Royalty</h3>
                            <app-deal-transfers-collection
                                [isExpense]="true"
                                [origin]="FINANCIAL_ELEMENT_ENTITY.type_set.royalty"
                                (deleteItem)="removeFinancialTransfer($event)"
                                [type]="FINANCIAL_TRANSFER_ENTITY.type_SET.royalty"
                                buttonLabel="Royalty"
                                [transfersFA]="companyCalculationFG!.controls.royalty_expenses!"
                            ></app-deal-transfers-collection>
                        </div>
                        <div
                            class="option-item"
                            *ngIf="companyCalculationFG!.controls.ancillary_incomes!.controls.length > 0"
                        >
                            <h3 class="option-title">Deal Income</h3>
                            <app-deal-transfers-collection
                                [isExpense]="false"
                                [origin]="FINANCIAL_ELEMENT_ENTITY.type_set.company"
                                (deleteItem)="removeFinancialTransfer($event)"
                                [type]="FINANCIAL_TRANSFER_ENTITY.type_SET.ancillary"
                                buttonLabel="Other Deal Income"
                                [transfersFA]="companyCalculationFG!.controls.ancillary_incomes!"
                            ></app-deal-transfers-collection>
                        </div>
                        <div
                            class="option-item"
                            *ngIf="companyCalculationFG!.controls.ancillary_expenses!.controls.length > 0"
                        >
                            <h3 class="option-title">Deal Expense</h3>
                            <app-deal-transfers-collection
                                [isExpense]="true"
                                [origin]="FINANCIAL_ELEMENT_ENTITY.type_set.company"
                                (deleteItem)="removeFinancialTransfer($event)"
                                [type]="FINANCIAL_TRANSFER_ENTITY.type_SET.ancillary"
                                buttonLabel="Other Deal Expense"
                                [transfersFA]="companyCalculationFG!.controls.ancillary_expenses!"
                            ></app-deal-transfers-collection>
                        </div>
                        <div
                            class="option-item"
                            *ngIf="companyCalculationFG!.controls.sales_incomes!.controls.length > 0"
                        >
                            <h3 class="option-title">Sales Income</h3>
                            <app-deal-transfers-collection
                                [isExpense]="false"
                                [origin]="FINANCIAL_ELEMENT_ENTITY.type_set.sales"
                                (deleteItem)="removeFinancialTransfer($event)"
                                [type]="FINANCIAL_TRANSFER_ENTITY.type_SET.sales"
                                buttonLabel="Other Sales Income"
                                [transfersFA]="companyCalculationFG!.controls.sales_incomes!"
                            ></app-deal-transfers-collection>
                        </div>
                        <div
                            class="option-item"
                            *ngIf="companyCalculationFG!.controls.sales_expenses!.controls.length > 0"
                        >
                            <h3 class="option-title">Sales Expense</h3>
                            <app-deal-transfers-collection
                                [isExpense]="true"
                                [allowPercents]="true"
                                [showOnlyAmount]="false"
                                [origin]="FINANCIAL_ELEMENT_ENTITY.type_set.sales"
                                [hidePayAtEscrowFlag]="true"
                                (deleteItem)="removeFinancialTransfer($event)"
                                [type]="FINANCIAL_TRANSFER_ENTITY.type_SET.sales"
                                buttonLabel="Other Sales Expense"
                                [transfersFA]="companyCalculationFG!.controls.sales_expenses!"
                            ></app-deal-transfers-collection>
                        </div>
                    </div>
                    <app-deal-preview
                        [companyCalculationFG]="companyCalculationFG"
                        [dealSystemStatus]="dealSystemStatus"
                    ></app-deal-preview>
                </div>
            </div>
        </mat-expansion-panel>
    `
})
export class DealExpensesComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('panel') panel: MatExpansionPanel | undefined;
    @Output() clickOnPanel = new EventEmitter();

    public FINANCIAL_TRANSFER_ENTITY = FinancialTransferEntity;
    public FINANCIAL_ELEMENT_ENTITY = FinancialElementModel;

    @Input() companyCalculationFG: GenericFormGroup<CompanyCalculation> = new GenericFormGroup(
        new CompanyCalculation(),
        'change'
    );
    @Input() dealSystemStatus: string = 'draft';

    constructor(protected dealProcessing: DealProcessingService) {}

    ngOnInit() {}

    addFinancialTransfer(fgType: string) {
        let financialTransferFG: GenericFormGroup<FinancialTransferEntity>;

        switch (fgType) {
            case 'referral_expenses':
                financialTransferFG = new GenericFormGroup(
                    new FinancialTransferEntity()
                        .setType(FinancialTransferEntity.type_SET.referral)
                        .setOrigin(FinancialElementModel.type_set.referral)
                        .setOriginIsExpense(true)
                );

                financialTransferFG.controls.sender_wildcard_id!.patchValue(3);
                this.companyCalculationFG.controls.referral_expenses!.push(financialTransferFG);

                break;
            case 'royalty_expenses':
                financialTransferFG = new GenericFormGroup(
                    new FinancialTransferEntity()
                        .setType(FinancialTransferEntity.type_SET.royalty)
                        .setOrigin(FinancialElementModel.type_set.royalty)
                        .setOriginIsExpense(true)
                );

                financialTransferFG.controls.sender_wildcard_id!.patchValue(3);
                this.companyCalculationFG.controls.royalty_expenses!.push(financialTransferFG);

                break;
            case 'ancillary_incomes':
                financialTransferFG = new GenericFormGroup(
                    new FinancialTransferEntity()
                        .setType(FinancialTransferEntity.type_SET.ancillary)
                        .setOrigin(FinancialElementModel.type_set.company)
                        .setOriginIsExpense(false)
                );
                financialTransferFG.controls.receiver_wildcard_id!.patchValue(3);
                this.companyCalculationFG.controls.ancillary_incomes!.push(financialTransferFG);

                break;
            case 'ancillary_expenses':
                financialTransferFG = new GenericFormGroup(
                    new FinancialTransferEntity()
                        .setType(FinancialTransferEntity.type_SET.ancillary)
                        .setOrigin(FinancialElementModel.type_set.company)
                        .setOriginIsExpense(true)
                );
                financialTransferFG.controls.sender_wildcard_id!.patchValue(3);
                this.companyCalculationFG.controls.ancillary_expenses!.push(financialTransferFG);

                break;
            case 'sales_incomes':
                financialTransferFG = new GenericFormGroup(
                    new FinancialTransferEntity()
                        .setType(FinancialTransferEntity.type_SET.sales)
                        .setOrigin(FinancialElementModel.type_set.sales)
                        .setOriginIsExpense(false)
                );
                financialTransferFG.controls.receiver_wildcard_id!.patchValue(3);
                this.companyCalculationFG.controls.sales_incomes!.push(financialTransferFG);

                break;
            case 'sales_expenses':
                financialTransferFG = new GenericFormGroup(
                    new FinancialTransferEntity()
                        .setType(FinancialTransferEntity.type_SET.sales)
                        .setOrigin(FinancialElementModel.type_set.sales)
                        .setOriginIsExpense(true)
                );
                financialTransferFG.controls.sender_wildcard_id!.patchValue(3);
                this.companyCalculationFG.controls.sales_expenses!.push(financialTransferFG);
                break;
        }

        // this.companyCalculationFG.controls[fgType].push(financialTransferFG);

        this.dealProcessing.addFinancialTransfer(financialTransferFG!);
    }

    removeFinancialTransfer(financialTransferFG: GenericFormGroup<FinancialTransferEntity>) {
        if (financialTransferFG) {
            if (!financialTransferFG.controls.is_enforced!.value) {
                this.dealProcessing.deleteNotEnforcedTransfer(financialTransferFG);
                this.dealProcessing.syncFinancialTransfers();
            } else {
                this.dealProcessing.deleteFinancialTransfer(financialTransferFG);
            }
        }
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.clickOnPanel.complete();
    }
}
