import {Component, Input, OnInit} from '@angular/core';
import {transactionTypeListByName} from '../../../../constants';
import {IReportsPreviewData, IReportsPreviewResultData} from '../../../../store/reports.reducer';

@Component({
    selector: 'reports-preview-commission-expense',
    templateUrl: './commission-expense.component.html',
    styleUrls: ['./commission-expense.component.scss']
})
export class CommissionExpenseReportPreviewComponent implements OnInit {
    @Input()
    /**
     * Set report data
     */
    set reportData(data: IReportsPreviewResultData[]) {
        data.forEach((item) => {
            switch (item.type) {
                case transactionTypeListByName.buyer:
                    this.buyer = {
                        entities: item.entities,
                        total: item.total && item.total.length ? item.total[0] : {}
                    };
                    break;

                case transactionTypeListByName.seller:
                    this.seller = {
                        entities: item.entities,
                        total: item.total && item.total.length ? item.total[0] : {}
                    };
                    break;

                case transactionTypeListByName.referral:
                    this.referral = {
                        entities: item.entities,
                        total: item.total && item.total.length ? item.total[0] : {}
                    };
                    break;

                case transactionTypeListByName.rental_tenant:
                    this.rentalTenant = {
                        entities: item.entities,
                        total: item.total && item.total.length ? item.total[0] : {}
                    };
                    break;

                case transactionTypeListByName.rental_owner:
                    this.rentalOwner = {
                        entities: item.entities,
                        total: item.total && item.total.length ? item.total[0] : {}
                    };
                    break;

                case 'combined':
                    this.combined = {
                        entities: item.entities,
                        total: item.total && item.total.length ? item.total[0] : {}
                    };
                    break;

                default:
            }
        });

        this._reportData = data;
    }

    /**
     * Get report data
     */
    get reportData() {
        return this._reportData;
    }

    _reportData: IReportsPreviewData['reportResult'] | [] = [];

    seller: {entities: Array<any>; total: any} = {
        entities: [],
        total: {}
    };

    buyer: {entities: Array<any>; total: any} = {
        entities: [],
        total: {}
    };

    referral: {entities: Array<any>; total: any} = {
        entities: [],
        total: {}
    };

    rentalOwner: {entities: Array<any>; total: any} = {
        entities: [],
        total: {}
    };

    rentalTenant: {entities: Array<any>; total: any} = {
        entities: [],
        total: {}
    };

    combined: {entities: Array<any>; total: any} = {
        entities: [],
        total: {}
    };

    tableStructure: Array<{
        title: string;
        value: string;
        filter?: string;
    }> = [
        {
            title: 'Agent',
            value: 'agent_name'
        },
        {
            title: 'Volume',
            value: 'sum_volume',
            filter: 'currency'
        },
        {
            title: 'Sides',
            value: 'deals_count'
        },
        {
            title: 'Commission',
            value: 'sum_commissions',
            filter: 'currency'
        }
    ];

    footerTotalColumns: Array<{
        value: string;
        filter?: string;
    }> = [
        {
            value: 'total_deals_count'
        },
        {
            value: 'total_sum_volume',
            filter: 'currency'
        },
        {
            value: 'total_sum_commissions',
            filter: 'currency'
        }
    ];

    columnsToDisplay: string[] = ['agent_name', 'sum_volume', 'deals_count', 'sum_commissions'];
    footerTotalColumnsToDisplay: string[] = ['total_sum_volume', 'total_deals_count', 'total_sum_commissions'];

    constructor() {}

    ngOnInit(): void {}

    /**
     * Get total cost
     * @param element
     * @param tableName
     */
    getTotalCost(
        element: string,
        tableName: 'buyer' | 'seller' | 'referral' | 'rentalOwner' | 'rentalTenant' | 'combined'
    ) {
        switch (element) {
            case 'total_deals_count':
                return this[tableName]['total'].deals_count;
            case 'total_sum_volume':
                return this[tableName]['total'].sum_volume;
            case 'total_sum_commissions':
                return this[tableName]['total'].sum_commissions;
            case 'total_sum_gci':
                return this[tableName]['total'].sum_gci;
            case 'total_avg_commission_percent':
                return this[tableName]['total'].avg_commission_percent;
            default:
                return '';
        }
    }
}
