import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

@Component({
    selector: 'app-report-autocomplete',
    templateUrl: './report-autocomplete.component.html',
    styleUrls: ['./report-autocomplete.component.scss']
})
export class ReportAutocompleteComponent implements OnInit, OnDestroy {
    @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete | undefined;
    @Input() list: any[] = [];
    @Input() value: Array<string | number> = [];
    @Input() titleAttribute: string = '';
    @Input() valueAttribute: string = '';
    @Output() valueChanges = new EventEmitter();
    @ViewChild('input', {static: false}) input: ElementRef<HTMLInputElement> | undefined;
    initialList: any[] = [];

    /**
     * On init
     */
    ngOnInit() {
        this.list = this.list.length
            ? this.list.sort((a, b) => {
                  return a[this.titleAttribute].localeCompare(b[this.titleAttribute]);
              })
            : [];
        this.initialList = this.list;
        this.value = this.value || [];
    }

    /**
     * Search
     * @param event
     */
    search(event: Event) {
        const text: string = (<HTMLInputElement>event.target).value;
        this.list = this.initialList.filter((item) =>
            item[this.titleAttribute].toLowerCase().includes(String(text).toLowerCase())
        );
    }

    /**
     * Select
     * @param event
     */
    select(event: MatAutocompleteSelectedEvent) {
        if (!this.value.includes(event.option.value)) {
            this.valueChanges.emit(([] as string[]).concat(this.value as string[], [event.option.value]));
        }
        this.clearInput();
    }

    /**
     * Remove
     */
    remove(id: string) {
        this.valueChanges.emit(this.value.filter((item) => item !== id));
    }

    /**
     * Clear input
     */
    clearInput() {
        if (this.input) {
            this.input.nativeElement.value = '';
            this.input.nativeElement.blur();
        }

        this.list = this.initialList;
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.valueChanges.complete();
    }
}
