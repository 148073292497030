<h1 mat-dialog-title>Report PDF Files</h1>
<div mat-dialog-content class="container">
    <p class="label-info" *ngIf="isShowRetryLaterNotification">
        Some of files was not generated yet. Please try to open window later.
    </p>

    <p>Forms for contacts:</p>

    <div *ngFor="let file of files; index as number" class="row mt-1">
        <ng-container *ngIf="file.background_task_id">
            <div class="col-md-10">
                <span>{{ number + 1 }}) {{ file.fileName }}</span>
            </div>
            <div class="col-md-2 file-info">
                <mat-spinner
                    *ngIf="!file.is_finished && !isShowRetryLaterNotification && notification.status === 'start'"
                    [diameter]="20"
                ></mat-spinner>

                <ng-container *ngIf="file.is_finished">
                    <a *ngIf="file.file_url" href="{{ file.file_url }}" target="_blank">Download</a>

                    <span class="error" *ngIf="!file.file_url">Error</span>
                </ng-container>
                <span class="error" *ngIf="!file.is_finished && notification.status === 'error'">Error</span>
            </div>
        </ng-container>
    </div>
</div>
<div mat-dialog-actions class="actions">
    <button mat-button (click)="close()">Close</button>
</div>
