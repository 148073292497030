<div class="view-transfer-container">
    <div class="transfer-content">
        <div>
            <div class="title" *ngIf="_transferFG.controls.label">{{ _transferFG.controls.label.value }}:</div>
            <div *ngIf="_transferFG.controls.amount">
                {{ _transferFG.controls.amount.value | currency: 'USD' : 'symbol' }}
                <ng-container *ngIf="isPercent">
                    (
                    <span *ngIf="_transferFG.controls.percent && _transferFG.controls.percent.value">{{
                        _transferFG.controls.percent.value
                    }}</span>
                    <span class="ml-1" *ngIf="_transferFG.controls.value_type">{{
                        FINANCIAL_TRANSFER_ENTITY.value_type_LABELS[_transferFG.controls.value_type.value]
                    }}</span>
                    )
                </ng-container>
                <ng-container *ngIf="!isPercent && _transferFG.controls.value_type">
                    <span>{{
                        FINANCIAL_TRANSFER_ENTITY.value_type_LABELS[_transferFG.controls.value_type.value]
                    }}</span>
                </ng-container>
            </div>
        </div>
        <div>
            <div>
                <div *ngIf="!isExpense">
                    Sender:
                    <span
                        class="ml-1"
                        *ngIf="_transferFG.controls.sender && $any(_transferFG.controls.sender).controls.link_title"
                    >
                        {{ $any(_transferFG.controls.sender).controls.link_title.value }}
                    </span>
                </div>
                <div *ngIf="isExpense">
                    Receiver:
                    <span
                        class="ml-1"
                        *ngIf="_transferFG.controls.receiver && $any(_transferFG.controls.receiver).controls.link_title"
                    >
                        {{ $any(_transferFG.controls.receiver).controls.link_title.value }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
