import {GenericEntity} from './generic.entity';

export class DisbursementInstructionsEntity extends GenericEntity {
    deal_information: string | null = null;
    transaction_title: string | null = null;
    transaction_footer: string | null = null;
    disbursement_footer: string | null = null;
    disbursement_title: string | null = null;
    is_payouts_shown: boolean = true;
    disbursement_fields: DisbursementFieldEntity[] = [
        {type: 'standard', system_key: 'MLS Number', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Description', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Seller', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Buyer', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Escrow Number', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Escrow Company', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Escrow Address', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Escrow Phone', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Escrow Fax', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Escrow Agent', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Escrow Agent Email', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Expires', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Contract Date', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Close Date', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Representation', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Seller Agent', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Buyer Agent', custom_field_id: null, is_shown: true, custom_key: null},
        {
            type: 'standard',
            system_key: 'Deposit Released to Brokerage',
            custom_field_id: null,
            is_shown: true,
            custom_key: null
        },
        {type: 'standard', system_key: 'Referral', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Sale Price', custom_field_id: null, is_shown: true, custom_key: null},
        {type: 'standard', system_key: 'Compensation', custom_field_id: null, is_shown: true, custom_key: null}
    ];
}

export class DisbursementFieldEntity extends GenericEntity {
    type: 'standard' | 'custom' | null = null;
    system_key: string | null = null;
    custom_field_id: number | null = null;
    is_shown: boolean = true;
    custom_key: string | null = null;
}
