import {Input, ViewContainerRef, Directive} from '@angular/core';
import {CompanyModalComponent} from '@app/modules/account-info/users-licenses/support-account-lookup-modal-dialog/company-modal.component';

@Directive({
    selector: '[dynamicCell]'
})
export class DynamicCellDirective {
    @Input() component: any;
    @Input() inputs: any;

    constructor(private vc: ViewContainerRef) {}

    ngOnInit() {
        const component = this.vc.createComponent(CompanyModalComponent);
        if (this.inputs) {
            Object.keys(this.inputs).forEach((input) => {
                // @ts-ignore
                component.instance[input] = this.inputs[input];
            });
        }
    }
}
