import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Group} from '../../../../models/group';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {AuthService} from '../../../../services/auth.service';
import {AvailableProfilesSource} from '../../../../services/sources/available-profiles.source';

@Component({
    selector: 'groups-new',
    styles: [
        `
            mat-card {
                height: 100%;
            }
        `
    ],
    template: `
        <form *ngIf="isOpenGroupArea">
            <div>
                <h3>Create Group</h3>
                <hr />

                <mat-form-field class="flex-4" style="margin-right: 16px">
                    <input
                        matInput
                        type="text"
                        required
                        placeholder="Title"
                        name="name"
                        *ngIf="newGroupFG.controls.title"
                        [formControl]="newGroupFG.controls.title"
                    />
                </mat-form-field>

                <mat-form-field class="flex-4">
                    <mat-select
                        placeholder="Subgroup Of"
                        name="type"
                        *ngIf="newGroupFG.controls.parent__group_fk_id"
                        [formControl]="newGroupFG.controls.parent__group_fk_id"
                        (selectionChange)="doSelectionChange()"
                    >
                        <mat-option [value]="0">None</mat-option>
                        <ng-container *ngFor="let company_group of groupList">
                            <mat-option [value]="company_group.id">{{ company_group.title }}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="flex-4">
                    <mat-select
                        placeholder="Copy Settings"
                        name="type"
                        [(ngModel)]="parentGroupId"
                        (selectionChange)="doSelectionChange()"
                    >
                        <mat-option [value]="0">Default</mat-option>
                        <ng-container *ngFor="let company_group of groupList">
                            <mat-option [value]="company_group.id">{{ company_group.title }}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <div class="d-flex flex-column">
                    <mat-slide-toggle
                        style="max-width: fit-content"
                        *ngIf="
                            newGroupFG.controls.permissions &&
                            newGroupFG.controls.permissions.controls &&
                            newGroupFG.controls.permissions.controls.use_in_compensation_as_group_of_entities
                        "
                        [formControl]="
                            newGroupFG.controls.permissions.controls.use_in_compensation_as_group_of_entities
                        "
                    >
                        <div class="d-flex">
                            Enable 'User Finance Group'
                            <mat-icon
                                style="margin-top:1px;width:20px;height:20px;font-size:20px;color: #9E9E9E;"
                                class="ml-2"
                                matTooltip="Activate this if you would like to make the members of this group available in the Finance module."
                                >group
                            </mat-icon>
                        </div>
                    </mat-slide-toggle>
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <button mat-raised-button (click)="hide.emit()" style="margin-right: 8px">Cancel</button>
                <button mat-raised-button class="mat-primary" [disabled]="newGroupFG.invalid" (click)="groupCreated()">
                    Create
                </button>
            </div>
        </form>
    `
})
export class NewComponent implements OnInit, OnDestroy {
    @Input() groupList: Group[] = [];

    @Input() isOpenGroupArea: boolean = true;
    @Output() hide = new EventEmitter();
    @Output() createdGroup = new EventEmitter();

    public newGroupFG: GenericFormGroup<Group> = new GenericFormGroup(new Group().setType(Group.type_SET.default));
    public parentGroup: Group | null = null;
    public subgroupOfGroup: Group | null = null;
    public parentGroupId: number = 0;

    @Input() public useInCompensation: boolean = false;
    @Input() public hideUseInCompensation: boolean = false;

    constructor(
        protected authService: AuthService,
        protected availableProfilesSource: AvailableProfilesSource
    ) {}

    ngOnInit(): void {}

    groupCreated() {
        this.newGroupFG.controls.title!.setValue(this.newGroupFG.controls.title?.value.trim());
        this.createdGroup.emit({
            newGroup: this.newGroupFG.getRawValue(),
            parent: this.parentGroup
        });
    }

    doSelectionChange() {
        const group = this.groupList.filter((company_group) => company_group.id === this.parentGroupId).shift();
        if (group) {
            this.parentGroup = group;
            this.newGroupFG.controls.title!.patchValue(this.parentGroup.title);
        }
    }

    ngOnDestroy() {
        this.hide.complete();
        this.createdGroup.complete();
    }
}
