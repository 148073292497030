import {assign} from 'lodash-es';

import {SubCompaniesActionType, SubCompaniesAction} from './subcompanies.actions';
import {ICompany} from '@cyberco-nodejs/zipi-typings';

export interface ISubCompaniesState {
    companies: ICompany[];
    companiesAll: ICompany[];
}

export const INITIAL_SUBCOMPANIES_STATE: ISubCompaniesState = {
    companies: [],
    companiesAll: []
};

export function subCompaniesReducer(
    state: ISubCompaniesState = INITIAL_SUBCOMPANIES_STATE,
    action: SubCompaniesAction
): ISubCompaniesState {
    switch (action.type) {
        case SubCompaniesActionType.GET_SUBCOMPANIES:
            return state;

        case SubCompaniesActionType.GET_SUBCOMPANIES_SUCCESS:
            return assign({}, state, {
                companies: action.payload!
            });

        case SubCompaniesActionType.GET_SUBCOMPANIES_ALL:
            return state;

        case SubCompaniesActionType.GET_SUBCOMPANIES_ALL_SUCCESS:
            return assign({}, state, {
                companiesAll: action.payload!
            });

        case SubCompaniesActionType.CREATE_SUBCOMPANY:
            return state;

        case SubCompaniesActionType.CREATE_SUBCOMPANY_SUCCESS:
            return assign({}, state, {
                companies: [action.payload!].concat(state.companies)
            });

        default:
            return state;
    }
}
