import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {timePeriods, compareToPeriodArr, dateTypesArr} from '../../../../../constants';
import {UntypedFormControl} from '@angular/forms';
import {merge as observableMerge, ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IReportSetting} from '@cyberco-nodejs/zipi-typings';

export interface IReportPeriodValue {
    time_period: IReportSetting['time_period'];
    time_period_start: string;
    time_period_end: string;
    date_type: IReportSetting['date_type'];
    compare_to_periods: {
        periods: IReportSetting['compare_to_periods']['periods'];
        display_options: {
            show_amount_change: boolean;
            show_percent_change: boolean;
        };
    };
}

@Component({
    selector: 'app-report-edit-bar-report-period',
    templateUrl: './report-period.component.html'
})
export class ReportPeriodComponent implements OnInit, OnDestroy {
    @Input() value: IReportPeriodValue | null = null;
    @Input() dateValueType: 'value_as_date' | 'value_as_int' = 'value_as_date';
    @Input() timePeriods: Array<{title: string; value: string}> = timePeriods;
    @Input() compareToPeriodArr: Array<{title: string; value: string}> = compareToPeriodArr;
    @Input() dateTypesArr: Array<{title: string; value: string}> = dateTypesArr;
    @Input() isCompareToPeriodAllowed: boolean = true;
    @Input() isCompareToPeriodPossible: boolean = false;
    @Input() isDisplayListOfPeriod: boolean = true;
    @Input() isDisplayDueDate: boolean = false;
    @Input() startDateDisable: boolean = false;
    @Input() endDateDisable: boolean = false;

    @Input() isPickingDateTypePossible: boolean = false;

    @Output() valueChanges: EventEmitter<IReportPeriodValue> = new EventEmitter<IReportPeriodValue>();

    selectedReportPeriodFC: UntypedFormControl = new UntypedFormControl();
    startDateFC: UntypedFormControl = new UntypedFormControl();
    endDateFC: UntypedFormControl = new UntypedFormControl();
    dateTypesFC: UntypedFormControl = new UntypedFormControl();
    compareToPeriodsFC: UntypedFormControl = new UntypedFormControl();
    showAmountChangeFC: UntypedFormControl = new UntypedFormControl();
    showPercentChangeFC: UntypedFormControl = new UntypedFormControl();

    private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);

    ngOnInit() {
        this.initData();
        this.initSubscription();
    }

    initData() {
        if (!this.value) return;

        this.selectedReportPeriodFC.setValue(this.value.time_period);
        if (this.value.date_type) {
            this.dateTypesFC.setValue(this.value.date_type);
        }

        if (this.value.compare_to_periods) {
            this.compareToPeriodsFC.setValue(this.value.compare_to_periods.periods);
            this.showAmountChangeFC.setValue(this.value.compare_to_periods.display_options.show_amount_change);
            this.showPercentChangeFC.setValue(this.value.compare_to_periods.display_options.show_percent_change);
        }

        if (!this.startDateDisable) {
            this.startDateFC.setValue(this.value.time_period_start);
        }

        if (!this.endDateDisable) {
            this.endDateFC.setValue(this.value.time_period_end);
        }
    }

    initSubscription() {
        observableMerge(
            this.selectedReportPeriodFC.valueChanges,
            this.dateTypesFC.valueChanges,

            this.compareToPeriodsFC.valueChanges,
            this.showAmountChangeFC.valueChanges,
            this.showPercentChangeFC.valueChanges,

            this.startDateFC.valueChanges,
            this.endDateFC.valueChanges
        )
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => {
                const newValue = {
                    time_period: this.selectedReportPeriodFC.value,
                    time_period_start: this.startDateFC.value,
                    time_period_end: this.endDateFC.value,
                    date_type: this.dateTypesFC.value,
                    compare_to_periods: {
                        periods: this.compareToPeriodsFC.value,
                        display_options: {
                            show_amount_change: this.showAmountChangeFC.value,
                            show_percent_change: this.showPercentChangeFC.value
                        }
                    }
                };
                this.valueChanges.emit(newValue);
            });
    }

    ngOnDestroy() {
        this.valueChanges.complete();
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }
}
