<mat-card class="sign-in-as-card">
    <mat-card-header>
        <div class="title">Sign In As ...</div>
    </mat-card-header>

    <mat-card-content>
        <div style="justify-content: space-between; text-align: center">
            <div *ngIf="showInProcess">
                <div class="label">Looking up user</div>
            </div>
            <div *ngIf="showError">
                <div class="label">Unable to load user.</div>
                <button mat-raised-button color="primary" (click)="goBack()">Return to Previouse Page</button>
            </div>
        </div>
    </mat-card-content>
</mat-card>
