<div>
    <mat-card class="preview-body">
        <div>
            <h3>Summary information:</h3>
            <table class="mat-table tables w-100">
                <tr class="mat-header-row">
                    <th class="header-cell">Title</th>
                    <th class="header-cell">Count of Contacts</th>
                    <th class="header-cell">Count of Payroll Forms</th>
                </tr>
                <tr class="table-row">
                    <td class="body-cell">Total</td>
                    <td class="body-cell">
                        <ng-container *ngIf="reportData">{{ reportData.count_contacts }}</ng-container>
                    </td>
                    <td class="body-cell">
                        <ng-container *ngIf="reportData">{{ reportData.count_forms }}</ng-container>
                    </td>
                </tr>
                <tr class="table-row">
                    <td class="body-cell">With 1099-NEC payroll data</td>
                    <td class="body-cell">
                        <ng-container *ngIf="reportData">{{ reportData.count_contact_with_payroll }}</ng-container>
                    </td>
                    <td class="body-cell">
                        <ng-container *ngIf="reportData">{{ reportData.count_form_with_payroll }}</ng-container>
                    </td>
                </tr>
                <tr class="table-row">
                    <td class="body-cell">Without 1099-NEC payroll data</td>
                    <td class="body-cell">
                        <ng-container *ngIf="reportData">{{ reportData.count_contact_without_payroll }}</ng-container>
                    </td>
                    <td class="body-cell">
                        <ng-container *ngIf="reportData">{{ reportData.count_form_without_payroll }}</ng-container>
                    </td>
                </tr>
            </table>
        </div>
    </mat-card>
</div>
