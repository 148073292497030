<mat-sidenav-container [hasBackdrop]="true" class="sidenav-container h-100" style="background-color: #fff">
    <mat-sidenav
        #sidenavGroupMembers
        mode="over"
        [position]="'end'"
        (closed)="close()"
        [ngClass]="{'top-sidenav1': false}"
        [autoFocus]="false"
        class="users-sidenav-content py-3 pl-3"
    >
        <div #header style="position: sticky" class="pr-3">
            <div class="d-flex justify-content-between">
                <h2>{{ groupFG.controls.title!.value }}</h2>

                <div *ngIf="!manageMembers" class="d-flex align-items-center">
                    <button
                        mat-raised-button
                        class="mat-primary"
                        color="warn"
                        *ngIf="membersToDelete.length && editDisabled"
                        (click)="removeMembersBeforeSave()"
                    >
                        Delete
                    </button>

                    <button
                        mat-raised-button
                        class="mat-primary ml-2"
                        *ngIf="!manageMembers && editDisabled"
                        (click)="manageMembers = !manageMembers; membersToDelete = []"
                    >
                        Manage
                    </button>

                    <button [disabled]="isDisabled" mat-raised-button class="ml-2" (click)="close()">Close</button>
                </div>
            </div>
            <div class="d-flex justify-content-between" *ngIf="manageMembers">
                <div
                    *ngIf="editDisabled && !groupFG.controls.isPersonalGroup!.value"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'flex'}"
                    [denyStyle]="{display: 'none'}"
                    [rule]="{company_settings__manage_groups_members: true}"
                    class="d-flex justify-content-start w-100"
                >
                    <app-company-compensation-combined-picker
                        class="w-100"
                        displayMode="double-line"
                        [bold_style]="false"
                        [title]="'Search'"
                        [availableTypes]="['individual']"
                        [nodesFA]="selectedProfilesFA"
                        [initialProfileIds]="selectedProfileIds"
                        [positionMode]="'none'"
                        [excludeMembersFromGroup]="groupId"
                        [closeAfterSelection]="false"
                    ></app-company-compensation-combined-picker>
                </div>

                <div class="d-flex align-items-baseline ml-3" style="margin-top: 4px">
                    <button
                        [disabled]="isDisabled"
                        mat-raised-button
                        class="header-button"
                        style="margin-right: 16px; margin-top: 5px"
                        (click)="manageMembers = !manageMembers"
                    >
                        Cancel
                    </button>
                    <button
                        [rule]="{company_settings__manage_groups: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        *ngIf="editDisabled"
                        [disabled]="isDisabled"
                        mat-raised-button
                        class="mat-primary header-button"
                        style="margin-top: 5px"
                        (click)="saveGroup()"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="membersFormArray && !membersFormArray.length" class="pr-3">There are no profiles yet</div>

        <div
            class="users-scroll w-100"
            [rule]="{company_settings__view_groups_members: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline'}"
            [denyStyle]="{display: 'none'}"
        >
            <app-lazy-loading
                *ngIf="offsetTopToLazyLoading$ && membersFormArray.length"
                class="deals-list-area"
                [data]="membersFormArray"
                [itemHeightPx]="40"
                [offsetTop]="offsetTopToLazyLoading$"
                [closeSubject]="false"
                (scrolledDown)="nextBatch()"
            >
                <ng-template let-member="listItem" let-mi="index">
                    <div class="d-flex align-items-center member-row pr-3" style="height: 40px">
                        <div
                            class="checkbox-area"
                            #divCheckbox
                            *ngIf="editDisabled && member.id"
                            rbac
                            [rule]="{company_settings__manage_users: true}"
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            [ngStyle]="membersToDelete.length ? {visibility: 'visible'} : {visibility: 'hidden'}"
                        >
                            <mat-checkbox
                                #checkbox
                                (click)="$event.stopPropagation()"
                                (change)="toggleDealCheckbox($event)"
                                [value]="member.id"
                                [checked]="membersToDelete.includes(member.id)"
                            ></mat-checkbox>
                        </div>
                        <div *ngIf="!member.id">
                            <button mat-icon-button *ngIf="editDisabled" (click)="removeFromList(member, mi)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>
                        <span class="mr-2">{{ mi + 1 }})</span>
                        <span class="mr-1">{{ member.profile.first_name }}</span>
                        <span>{{ member.profile.last_name }}</span>
                        <mat-icon
                            *ngIf="member.status === 'active'"
                            class="material-icons-outlined ml-2"
                            style="color: rgb(113, 113, 113)"
                            matTooltip="{{ member.profile.email }}"
                            matTooltipPosition="right"
                        >
                            info
                        </mat-icon>
                        <span *ngIf="!member.id" class="text-muted font-italic ml-3">(Not saved)</span>
                    </div>
                </ng-template>
            </app-lazy-loading>
        </div>
        <!--<h3 style="display: flex">Reporting-->
        <!--<mat-icon style="margin-left: 8px">group</mat-icon>-->
        <!--<mat-icon style="margin-left: 8px">exposure</mat-icon>-->
        <!--</h3>-->
    </mat-sidenav>
    <ng-content></ng-content>
</mat-sidenav-container>
