import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ElasticsearchService} from '../../../global-search/services/elasticsearch.service';
import {DealService} from '../../../../services/deal.service';
import {FormGroupWithFormControls} from '../../../../typings/common';

const MIN_SYMBOLS_TO_START_SEARCH = 3;

@Component({
    selector: 'app-deal-picker',
    templateUrl: './deal-picker.component.html',
    styleUrls: ['./deal-picker.component.scss']
})
export class DealPickerComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    private unsubscribe: Subject<void> = new Subject();

    @Input() dealControl: UntypedFormControl | undefined;
    @Input() placeholder: string = 'Deal';
    @Input() disabledSelect: boolean = false;
    @Input() isNeedRestrictWidth: boolean = false;
    @Input() excludeDealsIds: number[] = [];
    @Input() disableByTypes: string[] = [];

    @Output() onSelectDeal: EventEmitter<{title: string; id: number}> = new EventEmitter<{title: string; id: number}>();

    // @ViewChild('searchInput', { static: true }) searchInput: any;

    hostElementWidth: number = 0;
    globalSearchForm: FormGroupWithFormControls = this.fb.group({
        searchData: [{value: '', disabled: this.disabledSelect}, []],
        mode: ['deal', []]
    }) as FormGroupWithFormControls;
    searchResults: Array<any> = [];
    timer: any = null;

    noResults: boolean;
    selectedDeal: string | null = null;

    constructor(
        protected router: Router,
        private fb: UntypedFormBuilder,
        private elasticsearch: ElasticsearchService,
        private dealService: DealService,
        private hostElement: ElementRef
    ) {
        this.noResults = false;
    }

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        this.globalSearchForm
            .get('searchData')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                if (data && data.length > MIN_SYMBOLS_TO_START_SEARCH) {
                    clearTimeout(this.timer);
                    this.timer = null;
                    this.timer = setTimeout(() => this.search(), 600);
                }
            });

        if (typeof this.dealControl !== 'undefined' && this.dealControl.value) {
            this.dealService.getDealBaseView(this.dealControl.value).then((deal) => {
                this.selectedDeal = `${deal.street_number} ${deal.address} — ${deal.type}/${deal.status}`;
            });
        }
    }

    ngAfterViewInit() {
        this.hostElementWidth = this.hostElement.nativeElement.offsetWidth;
    }

    onSelectionChange(e: any) {
        const address = e.index_data.address ? e.index_data.address : 'No address';
        this.selectedDeal = `${address} — ${e.raw_data.type}/${e.raw_data.status}`;

        this.dealControl!.setValue(e.raw_data.id);
        this.globalSearchForm.get('searchData')!.setValue('');
        this.noResults = false;
        this.searchResults = [];

        this.onSelectDeal.emit({
            title: this.selectedDeal,
            id: e.raw_data.id
        });
    }

    removeDealFkId() {
        this.selectedDeal = null;
        this.dealControl!.setValue(null);
        this.globalSearchForm.get('searchData')!.setValue('');
        this.noResults = false;
        this.searchResults = [];
    }

    resetSearch() {
        this.globalSearchForm.get('searchData')!.setValue('');
        this.searchResults = [];
        this.noResults = false;
        this.selectedDeal = null;
    }

    search() {
        if (
            this.globalSearchForm.get('searchData')?.value &&
            this.globalSearchForm.get('searchData')?.value.length > MIN_SYMBOLS_TO_START_SEARCH
        ) {
            this.noResults = false;

            const searchRequest = this.globalSearchForm.getRawValue();
            this.elasticsearch
                .searchData(searchRequest)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((resp) => {
                    if (
                        resp.result &&
                        resp.result.hits.total.value > 0 &&
                        resp.result.hits.hits &&
                        resp.result.hits.hits.length > 0
                    ) {
                        if (this.excludeDealsIds.length) {
                            this.searchResults = resp.result.hits.hits.filter(
                                (result: any) => !this.excludeDealsIds.includes(result._source.raw_data.id)
                            );
                        } else {
                            this.searchResults = resp.result.hits.hits;
                        }
                    } else {
                        this.searchResults = [];
                        this.noResults = true;
                    }
                });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabledSelect) {
            if (changes.disabledSelect.currentValue) {
                this.globalSearchForm.get('searchData')!.disable();
            } else {
                this.globalSearchForm.get('searchData')!.enable();
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
