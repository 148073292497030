import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../../../services/service.requester';
import {IUnPostingRequestData} from '@app/modules/deals/modules/all-deals/components/payouts/types/payout.types';

@Injectable()
export class SummaryPayoutsApiService {
    private url = '/summary-payouts';

    constructor(public requester: ServiceRequester) {}

    startPosting(
        dealIds: number[],
        postingDate: number
    ): Promise<{status: string; payouts_in_processing: Array<number>}> {
        return this.requester.makeMsCall(this.url + '/start-deal-posting', 'POST', {
            deal_ids: dealIds,
            posting_date: postingDate
        });
    }

    unPostDocs(request: IUnPostingRequestData): Promise<{status: string}> {
        return this.requester.makeMsCall(this.url + '/un-post-docs', 'POST', request);
    }
}
