import {Component, OnInit, OnDestroy} from '@angular/core';

@Component({
    selector: 'app-contact-create',
    templateUrl: 'contact-create.component.html'
})
export class ContactCreateComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
