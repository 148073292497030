<div class="my-2">
    <div class="d-flex align-items-end">
        <h3>Payments</h3>
        <button mat-icon-button (click)="addDepositPayment()" [disabled]="disabled">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <div>
        <table mat-table [dataSource]="dataSourcePayments" class="mat-elevation-z1 w-100 deposit-table">
            <ng-container matColumnDef="paid_date">
                <th mat-header-cell *matHeaderCellDef style="width: 150px">Payment Date</th>
                <td mat-cell *matCellDef="let element" style="width: 150px">
                    {{
                        element.source_payment_received
                            ? (element.source_payment_received.paid_date | dateFromNumber | date: 'mediumDate')
                            : (element.paid_date | dateFromNumber | date: 'mediumDate')
                    }}
                </td>
            </ng-container>

            <ng-container matColumnDef="payment_mode">
                <th mat-header-cell *matHeaderCellDef>Mode</th>
                <td mat-cell *matCellDef="let element">
                    {{ paymentModesMap[element.source_payment_received?.payment_mode] }}
                </td>
            </ng-container>

            <ng-container matColumnDef="profile">
                <th mat-header-cell *matHeaderCellDef>Account</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.source_payment_received?.pay_to_ledger_account?.name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element" [ngStyle]="{color: statusColor[element.status]}">
                    {{ element.status | fromSnakeCase }}
                </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef>Total Amount</th>
                <td mat-cell *matCellDef="let element">{{ element.amount | currency }}</td>
            </ng-container>

            <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef>Reference</th>
                <td mat-cell *matCellDef="let element">{{ getRequestRef(element) }}</td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="text-align: end">Action</th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    style="text-align: end"
                    (click)="$event.preventDefault(); $event.stopPropagation()"
                >
                    <mat-menu #appMenu="matMenu">
                        <button mat-menu-item [disabled]="disabled" (click)="openConfirm(element)">
                            Delete Payment
                        </button>
                        <button
                            mat-menu-item
                            [disabled]="
                                !['authorize', 'zipi_pay', 'zipi_financial'].includes(
                                    element.source_payment_received?.payment_mode
                                ) ||
                                element.source_payment_received?.summary_status !== 'completed' ||
                                disabled
                            "
                            (click)="
                                $event.stopPropagation();
                                router.navigate([
                                    'sales/paymentsreceived/refund',
                                    element.source_payment_received.payment_received_id
                                ])
                            "
                        >
                            Refund
                        </button>
                    </mat-menu>

                    <button mat-icon-button [matMenuTriggerFor]="appMenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedPaymentsColumns"></tr>
            <tr
                mat-row
                class="journals-row sp-cursor-pointer"
                (click)="redirectToPaymentReceived(row)"
                *matRowDef="let row; columns: displayedPaymentsColumns"
            ></tr>
        </table>
        <div class="d-flex justify-content-end font-weight-bold mt-2">
            Total: {{ (dealDepositAmounts?.total_received | currency) || (0 | currency) }}
        </div>
    </div>
</div>
