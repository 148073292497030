import {Injectable} from '@angular/core';
import {ServiceRequester} from './service.requester';
import {Profile} from '../models/profile';
import {NotificationsServiceZipi} from '../modules/notifications/notifications.service';
import {PickerItem} from '../modules/shared/components/profiles-picker/models';
import {Company} from '../models/company';
import {AppInitResponse} from './api/app-init.api';
import {Observable} from 'rxjs';
import {ISettings, IUser, IContact} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class ProfilesService {
    private url = '/profile/';

    constructor(
        public requester: ServiceRequester,
        protected notificationsService: NotificationsServiceZipi
    ) {}

    loadProfile(id: number): Promise<AppInitResponse> {
        return this.requester.makeMsCall(this.url + 'load/' + id, 'GET').then((response) => {
            if (response === null) {
                return response;
            }
            return Object.assign(new AppInitResponse(), response);
        });
    }

    async findProfilesByName(
        companyId: number,
        searchData: {limit?: number; offset?: number; searchStr: string}
    ): Promise<{data: Profile[]; _meta: any}> {
        let url = '';
        if (searchData.limit) {
            url = `${this.url}company/${companyId}/search?limit=${searchData.limit}&offset=${searchData.offset}&searchStr=${searchData.searchStr}`;
        } else {
            url = `${this.url}company/${companyId}/search?searchStr=${searchData.searchStr}`;
        }

        return await this.requester.makeMsCall(url, 'GET', {}, {}, true);
    }

    initEmailChange(newEmail: string): Promise<boolean> {
        return this.requester.makeMsCall(this.url + 'email', 'PUT', {new_email: newEmail});
    }

    getMyCompanyProfiles(): Promise<Profile[]> {
        return this.requester
            .makeMsCall(this.url + 'in-my-company', 'GET')
            .then((list: Profile[]) => list.map((item) => Object.assign(new Profile(), item)));
    }

    setProfileInitialSetupCompleted(): Promise<boolean> {
        return this.requester.makeMsCall(this.url + 'setup', 'PUT').then((res) => {
            return true;
        });
    }

    getTeamMatesProfilesList(): Promise<Profile[]> {
        return this.requester.makeMsCall(this.url + 'team-mates-profiles', 'GET');
    }

    getCompanyProfiles(company: Company): Promise<Profile[]> {
        return this.requester
            .makeMsCallGlobalAllowed(this.url + 'company/' + company.id, 'GET')
            .then((loaded) => loaded.map((profile: Profile) => Object.assign(new Profile(), profile)));
    }

    getInvitedProfile(hash: string): Promise<{profile: Profile; existingUser: IUser | boolean}> {
        return this.requester.makeMsCall('/public/invited-profile', 'GET', {
            invite_hash: hash
        });
    }

    acceptInvite(profileId: number, hash: string, userData: object = {}): Promise<boolean> {
        return this.requester.makeMsCall('/public/accept-invite', 'PUT', {
            profile_id: profileId,
            invite_hash: hash,
            userData: userData
        });
    }

    getProfilesPickerList(): Promise<PickerItem[]> {
        return this.requester.makeMsCallGlobalAllowed(this.url + 'profiles-picker-list');
    }

    updateProfileSetOfShipmentsModes(id: number, modes: Array<string>): Observable<{result: Profile}> {
        return this.requester.makeMsCall$(this.url + id, 'PUT', 'zipi', modes);
    }

    updateProfileSettings(id: number, settings: ISettings): Observable<{result: Profile}> {
        return this.requester.makeMsCall$(this.url + id + '/settings', 'PUT', 'zipi', settings);
    }

    createContactForProfile(profileId: number): Observable<{result: IContact}> {
        return this.requester.makeMsCall$(`${this.url}profile/${profileId}/contact`, 'GET', 'zipi');
    }

    saveDashboardOrders(id: number, orders: Array<number>): Observable<{result: Profile}> {
        return this.requester.makeMsCall$(`/boards/dashboard-orders`, 'PUT', 'zipi', orders);
    }

    saveDealboardsOrder(id: number, orders: Array<number>): Observable<{result: Profile}> {
        return this.requester.makeMsCall$(`/deals/boards/dealboards-order`, 'PUT', 'zipi', orders);
    }

    getProfilesByIds(
        profileIds: number[],
        isContactNeeded: number = 0
    ): Observable<{
        result: {
            id: number;
            profile_id: number;
            first_name: string;
            last_name: string;
            company_fk_id: number;
            email: string;
            contact_id?: number;
            contact_name?: string;
        }[];
    }> {
        return this.requester.makeMsCall$(`${this.url}get-by-ids`, 'GET', 'zipi', {ids: profileIds, isContactNeeded});
    }

    changeCurrentUserProfileUser(email: string): Promise<boolean> {
        return this.requester.makeMsCall(this.url + 'update-profile-user-for-current-user', 'PUT', {
            email: email
        });
    }

    connectSkyslopeUser(payload: {profile_id: any; last_name: any; okta_uid: any; first_name: any; email: any}) {
        return this.requester.makeMsCall(this.url + 'connect-skyslope-user', 'POST', payload);
    }
}
