import {merge as observableMerge, Observable, Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AppInitSource} from './app-init.source';
import {ContactClass} from '../../models/contact-class';

interface IQBSyncEvent {
    entity: 'invoice' | 'bill';
    books_entity_id: number;
    status: 'in_progress' | 'completed' | 'error';
    message: string;
    qb_entity_id: string;
    qb_entity_link: string;
}

@Injectable()
export class QBSyncEventsSource {
    public qbSyncEvents = new Subject<IQBSyncEvent>();

    constructor() {}
}
