import {combineLatest as observableCombineLatest, of, Subject} from 'rxjs';

import {catchError, filter, map, takeUntil, tap} from 'rxjs/operators';
import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    ViewChild,
    ChangeDetectorRef,
    Inject,
    ViewChildren,
    QueryList,
    ElementRef
} from '@angular/core';

import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
    UntypedFormArray,
    UntypedFormControl,
    AbstractControl
} from '@angular/forms';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTabGroup} from '@angular/material/tabs';
import {Store} from '@ngrx/store';
import {trim, filter as filterObj, isEqual} from 'lodash-es';

import {IContact, IContactLocationTable, IContactPerson, IAvailabledForContact} from '@cyberco-nodejs/zipi-typings';

import {IContactsState} from '../../store/contacts.reducer';
import {AddContactInDialog, UpdContact} from '../../store/contacts.actions';
import {clearFormArray} from 'app/utilities';

import {NEW_CONTACT_LOCATION, extractLocationComponents} from '../../store/contact.utilities';
import {PermissionsSource} from '../../../../services/sources/permissions.source';
import {ContactType} from '../../../../models/contact-type';
import {ContactTypeService} from '../../../../services/api/contact-type.service';
import {SessionService} from '../../../../services/session.service';
import {CurrentProfileSource} from '../../../../services/sources/current-profile.source';
import {ShipperContactsService} from 'app/services/api/shipper.contacts.service';
import {NotificationsService} from 'angular2-notifications';
import {phoneMaskitoOptions} from '../../../../utilities/maskito';

interface IDialogData {
    contact: IContact;
    goToLocationTab: boolean;
    isCompany: boolean;
    category: string;
    ownerType: string;
}

@Component({
    selector: 'app-contact-create-dialog',
    templateUrl: 'contact-create-dialog.component.html',
    styleUrls: ['../../contact-edit/contact-edit.component.css']
})
export class ContactCreateDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private stopListenDisplayName: Subject<void> = new Subject();

    @Input() contact: IContact | IAvailabledForContact | undefined;

    contactForm: UntypedFormGroup;
    profileForm: UntypedFormGroup | undefined;
    individualForm: UntypedFormGroup | undefined;

    personsArrayCtrl: UntypedFormArray | undefined;
    locationsArrayCtrl: UntypedFormArray | undefined;

    @ViewChild('tabs', {static: true}) tabs: MatTabGroup | undefined;
    @ViewChildren('phoneInput') phoneInputs: QueryList<ElementRef> | undefined;
    @ViewChildren('faxInput') faxInputs: QueryList<ElementRef> | undefined;

    locationPickerCtrl = new UntypedFormControl();
    editLocationIdx: number | undefined;

    isCompanyCtrl = new UntypedFormControl();

    profilePermissions = {
        contacts__view_locations: false,
        contacts__view_payment_methods: false,
        contacts__view_compliance: false,
        contacts__view_notes: false
    };

    contactTypes: ContactType[] = [];
    public phoneMaskitoInputMask = phoneMaskitoOptions;
    errors: {[key: number]: {[key: string]: boolean}} = {};
    isSubmitDisabled: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IDialogData,
        public dialogRef: MatDialogRef<ContactCreateDialogComponent>,
        private fb: UntypedFormBuilder,
        private store: Store<IContactsState>,
        public dialog: MatDialog,
        private changeDetector: ChangeDetectorRef,
        protected permissionsSource: PermissionsSource,
        private contactTypeService: ContactTypeService,
        public sessionService: SessionService,
        public currentProfileSource: CurrentProfileSource,
        private cs: ShipperContactsService,
        private ntfs: NotificationsService
    ) {
        this.personsArrayCtrl = this.fb.array([]);
        this.locationsArrayCtrl = this.fb.array([]);
        this.contactForm = this.fb.group(
            {
                display_name: [
                    {value: '', disabled: this.contact ? false : true},
                    [Validators.required, Validators.minLength(2), Validators.maxLength(100)]
                ],
                company_name: ['', [Validators.maxLength(100)]],
                contact_persons: this.personsArrayCtrl,
                contact_locations: this.locationsArrayCtrl,
                notes: ['', [Validators.maxLength(5000)]],
                default_location: {value: null, disabled: true},
                // owner_type: 'profile',
                // When creating a contact from a company module (ie when creating an Invoice, Deal, Bill, Expense, etc.) this ownership field should be set to 'Company'
                owner_type: 'company',
                partner_company_settings: this.fb.group({
                    show_my_docs_as_source: [null, []],
                    approve_income_bills: [null, []],
                    approve_payout_income_bills: [null, []],
                    approve_income_invoices: [null, []],
                    approve_payout_income_invoices: [null, []],
                    approve_recurring_invoice_ids: [[], []]
                }),
                contact_type_ids: []
            },
            {updateOn: 'change'}
        );
    }

    addLocation(lcn: Partial<IContactLocationTable> = NEW_CONTACT_LOCATION) {
        const contact_locations = this.contactForm.get('contact_locations') as UntypedFormControl;
        if (!contact_locations) {
            return;
        }
        const display_name = this.contactForm.get('display_name') as UntypedFormControl;
        const isFirst = contact_locations.value.length === 0;
        const locationGroup = this.fb.group({
            contact_location_id: lcn.contact_location_id || null,
            contact_id: lcn.contact_id || null,
            label: {
                value: isFirst && !lcn.label && display_name ? display_name.value : lcn.label,
                disabled: isFirst
            },
            unit_number: lcn.unit_number,
            street_number: lcn.street_number,
            street_address: lcn.street_address,
            zip: lcn.zip,
            city: lcn.city,
            state: lcn.state,
            country: lcn.country,
            linked_to_contact_locations_ids: [lcn.linked_to_contact_locations_ids]
        });

        (<UntypedFormArray>this.contactForm.get('contact_locations')).push(locationGroup);

        this.changeDetector.detectChanges();

        return this.contactForm.value.contact_locations.length - 1;
    }

    private makeFormDirty() {
        if (this.contactForm.dirty) {
            return;
        }
        this.contactForm.markAsDirty();
    }

    removeLocation(ii: number) {
        return () => {
            (<UntypedFormArray>this.contactForm.get('contact_locations')).removeAt(ii);
            this.makeFormDirty();
        };
    }

    // Add new person or patch person or department
    addPerson(p?: IContactPerson, isDepartment?: true): UntypedFormGroup {
        const personGroup = this.fb.group({
            first_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(40)]],
            last_name: ['', [Validators.minLength(2), Validators.maxLength(40)]],
            full_name: '',
            preferred_name: ['', [Validators.minLength(2), Validators.maxLength(40)]],
            phone: '',
            fax: '',
            email: '',
            type: isDepartment ? 'department' : 'person',
            default_location: {value: null, disabled: true},
            linked_to_contact_persons_ids: [],
            contact_person_id: null,
            contact_id: null
        });
        p && personGroup.patchValue(p);

        (<UntypedFormArray>this.contactForm.get('contact_persons')).push(personGroup);

        return personGroup;
    }

    removePerson(ii: number, ctrl: AbstractControl) {
        if (ctrl.value.type !== 'main_person') {
            (<UntypedFormArray>this.contactForm.get('contact_persons')).removeAt(ii);
            this.makeFormDirty();
        }
    }

    private prepareContact() {
        if (!this.isCompanyCtrl.value) {
            this.resetCompany();
        } else {
            const mainPerson = this.contactForm
                .get('contact_persons')!
                .value.find((person: IContactPerson) => person.type === 'main_person');
            if (mainPerson) {
                this.contactForm.get('company_name')!.setValue(mainPerson.first_name);
            }
        }

        const contact = this.contactForm.getRawValue();

        const contactPersons = filterObj(contact.contact_persons, (p: IContactPerson) =>
            [p.first_name, p.last_name, p.email, p.phone, p.fax, p.linked_to_contact_persons_ids].some((val) => !!val)
        ).map((p: IContactPerson) => {
            if (p.phone) {
                p.phone = p.phone.replace(/[^0-9]/g, '');
            }
            if (p.fax) {
                p.fax = p.fax.replace(/[^0-9]/g, '');
            }
            return p;
        });

        return Object.assign({}, contact, {
            contact_locations: contact.contact_locations,
            contact_persons: contactPersons
        });
    }

    onBlurMethod(index: number, type: 'phone' | 'fax') {
        const tel = this.contactForm.controls.contact_persons.value[index][type];
        if (tel && tel.replace(/[^0-9]/g, '') && tel.replace(/[^0-9]/g, '').length < 10) {
            this.errors[index] = {[type]: true};
        } else {
            this.errors[index] = {[type]: false};
        }
    }

    onClickMethod(index: number, type: 'phone' | 'fax') {
        if (type === 'phone' && this.phoneInputs) {
            let length = this.phoneInputs.toArray()[index].nativeElement.value.replace(/[^0-9]/g, '').length;
            if (length >= 3) {
                length++;
            }
            if (length >= 7) {
                length++;
            }

            this.phoneInputs.toArray()[index].nativeElement.selectionStart = length;
            this.phoneInputs.toArray()[index].nativeElement.selectionEnd = length;
        } else if (type === 'fax' && this.faxInputs) {
            let length = this.faxInputs.toArray()[index].nativeElement.value.replace(/[^0-9]/g, '').length;
            if (length >= 3) {
                length++;
            }
            if (length >= 7) {
                length++;
            }

            this.faxInputs.toArray()[index].nativeElement.selectionStart = length;
            this.faxInputs.toArray()[index].nativeElement.selectionEnd = length;
        }
    }

    createOrUpdateContact() {
        if (this.isSubmitDisabled) {
            return;
        }
        this.isSubmitDisabled = true;
        const isUpdate = this.data && this.data.contact && this.data.contact.contact_id;

        if (this.contactForm && this.contactForm.invalid) {
            this.contactForm.updateValueAndValidity({onlySelf: false, emitEvent: true});
            this.isSubmitDisabled = false;
            return;
        }

        // validate persons' phone and fax
        if (
            this.errors &&
            Object.keys(this.errors).find((key) => this.errors[Number(key)]['phone'] || this.errors[Number(key)]['fax'])
        ) {
            this.isSubmitDisabled = false;
            return;
        }

        this.contact = Object.assign({}, this.prepareContact());

        if (isUpdate && this.data.contact.id) {
            this.store.dispatch(
                new UpdContact({
                    // @ts-ignore
                    data: Object.assign(this.contact, {id: this.data.contact.id}),
                    silent: true // no redirect
                })
            );
            this.isSubmitDisabled = false;
        } else if (this.contact) {
            return this.cs
                .createContact(this.contact)
                .pipe(
                    catchError((error) => {
                        this.isSubmitDisabled = false;
                        this.ntfs.error(error);
                        return of(null);
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((addedContact) => {
                    if (addedContact) {
                        this.store.dispatch(new AddContactInDialog(addedContact));
                        this.dialogRef.close({created: addedContact});
                        this.isSubmitDisabled = false;
                    }
                });
        }

        this.dialogRef.close({updated: {...this.contact, id: this.data.contact.id}});
        this.isSubmitDisabled = false;
    }

    resetCompany() {
        this.contactForm.patchValue({
            company_name: ''
        });
    }

    initIndividualForm(p?: IContactPerson) {
        this.individualForm = this.addPerson();
        this.individualForm.patchValue({type: 'main_person'});

        if (p) {
            this.individualForm.patchValue(p);
        }
    }

    private initLocations() {
        clearFormArray(<UntypedFormArray>this.contactForm.get('contact_locations'));
        if (this.contact && this.contact.contact_locations && this.contact.contact_locations.length > 0) {
            this.contact.contact_locations.forEach((lcn, idx) => this.addLocation(lcn));
            return;
        }
    }

    private initPersons() {
        clearFormArray(<UntypedFormArray>this.contactForm.get('contact_persons'));
        this.initIndividualForm();
        if (this.contact && this.contact.contact_persons) {
            this.contact.contact_persons.map((p: IContactPerson) => {
                p.type === 'main_person' && this.individualForm ? this.individualForm.patchValue(p) : this.addPerson(p);
            });
        }
    }

    initEditContact() {
        setTimeout(() => {
            if (!this.contact) {
                return;
            }
            this.contactForm.patchValue(this.contact);
            this.isCompanyCtrl.patchValue(!!this.contact.company_name);
            if (this.contact.linked_to_contacts_ids) {
                this.contactForm.addControl(
                    'linked_to_contacts_ids',
                    new UntypedFormControl(this.contact.linked_to_contacts_ids, [])
                );
            }

            this.initPersons();
            this.initLocations();
        }, 0);
    }

    createNewLocation(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        if (this.tabs) {
            this.tabs.selectedIndex = 1;
        }
    }

    initDisplayNameWatchers() {
        observableCombineLatest(this.isCompanyCtrl.valueChanges, this.individualForm!.valueChanges)
            .pipe(
                tap(([_, person]) => {
                    if (person) {
                        const name = [person.first_name, person.last_name]
                            .filter((v) => !!v)
                            .map(trim)
                            .join(' ');
                        if (!this.contact) {
                            this.contactForm.controls.display_name.patchValue(name, {
                                emitEvent: false,
                                emitModelToViewChange: true
                            });
                        }
                        if (this.isCompanyCtrl.value) {
                            this.contactForm.get('company_name')!.patchValue(name, {
                                emitEvent: false,
                                emitModelToViewChange: true
                            });
                        }
                    }
                }),
                takeUntil(this.stopListenDisplayName),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    }

    comparePeriod: (o1: UntypedFormControl, o2: UntypedFormControl) => boolean = isEqual;

    private FocusOnCompany() {
        setTimeout(() => {
            this.changeDetector.detectChanges();
        }, 0);
    }

    private focusOnIndividual() {
        setTimeout(() => {
            this.changeDetector.detectChanges();
        }, 0);
    }

    changeTabToLocations() {
        if (this.tabs && this.tabs.selectedIndex) {
            this.tabs.selectedIndex = this.tabs.selectedIndex + 1;
        }
    }

    getAndSubscribeContactTypes() {
        // get contactTypes from sessionService variable
        this.contactTypes = this.sessionService.contactTypes;

        // subscribe to contactTypes changes
        this.currentProfileSource.contactTypes.pipe(takeUntil(this.unsubscribe)).subscribe((newList) => {
            this.contactTypes = newList;
        });

        // if no contactTypes - get them from backend and save in currentProfileSource
        if (this.sessionService.contactTypes.length === 0) {
            this.contactTypeService.getContactTypeListAsPromise().then((list) => {
                this.currentProfileSource.contactTypes.next(list);
            });
        }
    }

    ngOnInit() {
        this.getAndSubscribeContactTypes();

        this.contactForm
            .get('display_name')!
            .valueChanges.pipe(
                tap((val) => {
                    if (this.stopListenDisplayName.closed) {
                        return;
                    }
                    this.stopListenDisplayName.next();
                    this.stopListenDisplayName.complete();
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.initIndividualForm();

        this.locationPickerCtrl.valueChanges
            .pipe(
                filter((l) => l),
                map(extractLocationComponents),
                map((location) => this.addLocation(location)),
                tap(() => this.locationPickerCtrl.reset(null)),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.isCompanyCtrl.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isCompany) => (isCompany ? this.FocusOnCompany() : this.focusOnIndividual()));

        this.permissionsSource.permissions.pipe(takeUntil(this.unsubscribe)).subscribe((permissions) => {
            // @ts-ignore
            this.profilePermissions = permissions;
        });

        if (this.data && this.data.goToLocationTab) {
            this.changeTabToLocations();
        }

        if (this.data && this.data.contact) {
            this.contact = this.data.contact;
            this.initEditContact();
            return;
        }

        // if not edit mode

        this.initDisplayNameWatchers();
        if (this.data && this.data.isCompany) {
            this.isCompanyCtrl.patchValue(true);
        } else {
            this.isCompanyCtrl.patchValue(false);
        }

        if (this.data && this.data.ownerType) {
            this.contactForm.get('owner_type')!.patchValue(this.data.ownerType, {emitEvent: false});
            this.changeDetector.detectChanges();
        }

        // prefill contact_type_ids (category) field depending on passed category
        if (this.data && this.data.category) {
            // @ts-ignore
            const preSetCategoryId = ContactType.mapCategoryToSystemContactTypeId[this.data.category];
            if (preSetCategoryId) {
                this.contactForm.get('contact_type_ids')!.patchValue([preSetCategoryId]);
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.stopListenDisplayName.complete();
    }
}
