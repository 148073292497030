import {GenericEntity} from '../../../../entites/generic.entity';
import {IParticipantSubTypes, ISearchEntity} from '@cyberco-nodejs/zipi-typings';

export class ContactWildcard extends GenericEntity implements ISearchEntity {
    id: number = 1;
    label: string = 'Mentor';
    type: string = 'system';

    // for using inside entity-picker
    target_id: number | null = null;
    company_fk_id: number | null = null;

    kind: string = '';
    subType: IParticipantSubTypes[] = [];
    permissions: JSON | string | null = null;
    email: string = '';
    contact_id: number | null = null;
    contact_name: string = '';
    contact_persons: {
        contact_company_name: string;
        contact_person_id: number;
        contact_person_name: string;
        contact_person_type: string;
    }[] = [];
    hidden: boolean = false;
    related_profile_and_group: boolean = false;

    setContactWildcardId(id: number) {
        this.id = id;
        return this;
    }

    setLabel(label: string) {
        this.label = label;
        return this;
    }

    setType(type: string) {
        this.type = type;
        return this;
    }
}
