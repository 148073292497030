<div class="d-flex flex-wrap flex-column justify-content-center">
    <div>
        <h2 *ngIf="!tagCategory">Create Tag Category</h2>
        <h2 *ngIf="tagCategory">Edit Tag Category</h2>
    </div>

    <mat-form-field class="w-100">
        <input matInput type="text" placeholder="Title" [(ngModel)]="categoryTitle" required />
    </mat-form-field>

    <div class="tags-list">
        <h3>Tags</h3>
        <div *ngFor="let tagGroup of tagsArray.controls; index as idx">
            <div class="w-100 d-flex align-items-center" [formGroup]="tagGroup">
                <mat-form-field class="flex-fill">
                    <input matInput type="text" formControlName="title" required />
                </mat-form-field>

                <button type="button" mat-icon-button (click)="removeTag(idx, tagGroup)" matTooltip="Delete tag">
                    <mat-icon>delete_outline</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <button class="my-3" type="button" mat-button (click)="addTag()">
        <mat-icon>add</mat-icon>
        Add tag
    </button>

    <div class="d-flex justify-content-between w-100">
        <button
            mat-raised-button
            color="warn"
            class="fix-focus-outline m-2"
            *ngIf="tagCategory"
            (click)="deleteTagCategory()"
            [disabled]="saveDisabled"
        >
            Delete
        </button>

        <span class="sp-spacer"></span>

        <button
            mat-raised-button
            color="primary"
            class="fix-focus-outline m-2"
            *ngIf="!tagCategory"
            (click)="saveTagCategory()"
            [disabled]="saveDisabled"
        >
            Save
        </button>
        <button
            mat-raised-button
            color="primary"
            class="fix-focus-outline m-2"
            *ngIf="tagCategory"
            (click)="updateTagCategory()"
            [disabled]="saveDisabled"
        >
            Update
        </button>

        <button mat-button class="fix-focus-outline m-2" (click)="close()">Cancel</button>
    </div>
</div>
