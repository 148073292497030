<div>
    <div [formGroup]="requestForm">
        <h2>Request</h2>

        <div class="d-flex w-100">
            <div class="d-flex align-items-center w-50 mr-1">
                <div class="w-80 mr-1">
                    <app-company-compensation-combined-picker
                        style="width: 100%; margin-right: 8px"
                        #combinedPicker
                        [availableTypes]="['contact']"
                        [title]="'Customer'"
                        [only_compensation_groups]="false"
                        [singleSelected]="true"
                        [bold_style]="false"
                        [nodesFA]="requestReceiverCtrlArr"
                    >
                    </app-company-compensation-combined-picker>
                </div>
                <div class="w-20 mr-1" style="margin-bottom: 20px">
                    <button
                        type="button"
                        mat-icon-button
                        matSuffix
                        (click)="contactCreate()"
                        matTooltip="Create Contact"
                    >
                        <mat-icon>person_add</mat-icon>
                    </button>
                </div>
            </div>

            <mat-form-field class="w-50 mr-1">
                <input
                    matInput
                    formControlName="request_receiver_email"
                    placeholder="Email"
                    type="text"
                    autocomplete="off"
                />
            </mat-form-field>
        </div>
        <div class="d-flex w-100">
            <div class="w-50 mr-1">
                <app-product-service-selector
                    (onProductSelectionChange)="selectItem($event)"
                    [productServiceControl]="requestForm.controls.product_fk_id"
                    [placeholder]="'Product/Service'"
                >
                </app-product-service-selector>
            </div>
            <div class="w-50 mr-1">
                <mat-form-field class="w-100">
                    <mat-label>Amount</mat-label>
                    <input
                        [maskito]="currencyMaskitoMask"
                        matInput
                        type="text"
                        formControlName="request_amount"
                        name="amount"
                        autocomplete="off"
                        matTooltipPosition="above"
                    />
                </mat-form-field>
            </div>
        </div>

        <div class="d-flex w-100">
            <div class="w-50 mr-1">
                <app-date-picker
                    class="w-50"
                    [placeholder]="'Request Date'"
                    [dateControl]="requestForm.controls.request_date"
                ></app-date-picker>
            </div>
            <div class="w-50 mr-1">
                <app-date-picker
                    class="w-50"
                    [placeholder]="'Due Date'"
                    [dateControl]="requestForm.controls.due_date"
                ></app-date-picker>
            </div>
        </div>

        <div class="d-flex w-100">
            <div class="w-50 mr-1">
                <app-select-pay-to-methods
                    [payToMethodIdsControl]="requestForm.controls.pay_to__payment_method_fk_ids"
                    [allowedGatewayTypes]="['zipi_financial_trust']"
                    (redirectEvent)="dialogRef.close(false)"
                >
                </app-select-pay-to-methods>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-end mt-3">
        <mat-menu #appMenu="matMenu">
            <button mat-menu-item (click)="continue('sent')">Create and Send</button>

            <button mat-menu-item (click)="continue('open')">Create as Open</button>

            <!--            <button mat-menu-item-->
            <!--                    (click)="continue('draft')">-->
            <!--                Create as Draft-->
            <!--            </button>-->
        </mat-menu>
        <button class="mr-2" *ngIf="!data.hideCancel" mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">
            Cancel
        </button>

        <button class="mr-2" mat-raised-button color="primary" [matMenuTriggerFor]="appMenu">Create Request</button>
    </div>
</div>
