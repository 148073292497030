<div class="bg-white">
    <!------- Loading ------->
    <div class="load-container" id="load-container" *ngIf="isLoading">
        <div class="loader">Loading...</div>
    </div>

    <div *ngIf="!isConnected && !isLoading">
        <app-quickbooks-connect></app-quickbooks-connect>
    </div>

    <div class="h-100 d-flex flex-column" *ngIf="isConnected && !isLoading">
        <div class="d-flex flex-row align-items-baseline py-3 pl-4 pr-3">
            <h3 class="mr-4 mb-0">QuickBooks Online Integration</h3>
            <div class="text-secondary" *ngIf="companySettings">{{ companySettings.qb_cred_email }}</div>
            <div class="ml-4 font-weight-bold" *ngIf="companySettings && companySettings.qb_company_title">
                {{ companySettings.qb_company_title }}
            </div>
            <div class="flex-grow-1"></div>

            <button mat-button color="warn" class="mat-stroked-button" (click)="openDisconnectConfirmationDialog()">
                Disconnect Account
            </button>
        </div>

        <mat-tab-group>
            <mat-tab label="Bill Integration">
                <app-quickbooks-ledgers [companySettings]="companySettings"></app-quickbooks-ledgers>
            </mat-tab>

            <mat-tab label="Invoice Integration">
                <app-quickbooks-products [companySettings]="companySettings"></app-quickbooks-products>
            </mat-tab>

            <mat-tab label="Integration Settings">
                <app-qb-integration-settings
                    [companySettings]="companySettings"
                    (reloadCompanySettings)="loadCompanySettings()"
                ></app-qb-integration-settings>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
