import {Injectable} from '@angular/core';
import {ServiceRequester} from '../service.requester';
import {ContactClass} from '../../models/contact-class';
import {Observable} from 'rxjs';
import {IContact} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class ContactClassService {
    private url = '/contact-class';

    constructor(public requester: ServiceRequester) {}

    getContactClassListAsPromise(): Promise<ContactClass[]> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp').toPromise();
    }

    getContactClassesByIds(contactClassIds: number[]): Observable<ContactClass[]> {
        return this.requester.makeMsCall$(this.url + '/classes', 'GET', 'shipp', {ids: contactClassIds});
    }

    findClassMembersByName(contactClassId: number, searchData: string): Observable<IContact[]> {
        return this.requester.makeMsCall$(
            `${this.url}/${contactClassId}/search-members`,
            'POST',
            'shipp',
            {searchData},
            {},
            true
        );
    }

    createContactClass(data: ContactClass): Observable<ContactClass> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    updateContactClass(contactClassId: number, data: ContactClass): Observable<ContactClass> {
        return this.requester.makeMsCall$(this.url + '/' + contactClassId, 'PUT', 'shipp', data);
    }

    deleteContactClass(contactClassId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + '/' + contactClassId, 'DELETE', 'shipp');
    }
}
