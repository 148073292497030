import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PaymentMethodsService} from '../../../profile/services/payment-methods.service';
import {IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {filter, takeUntil} from 'rxjs/operators';
import {firstValueFrom, Subject} from 'rxjs';
import {CompanyGatewayService} from '../../../../services/api/finance/company-gateway.service';
import {ZipiFinancialIframeDialogComponent} from '../../../account-info/company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-financial-iframe-dialog/zipi-financial-iframe-dialog.component';
import {NotificationsServiceZipi} from '../../../notifications/notifications.service';
import {IZipiFinancialIframeDialogData} from '../../../../typings/zipi-financial-iframe';
import {SelectOwnGatewayForReceiveOnlyDialogComponent} from '../select-own-gateway-for-receive-only-dialog/select-own-gateway-for-receive-only-dialog.component';
import {MarketplaceSource} from '../../../account-info/marketplace/marketplace.source';
import {FeatureFlagsService} from '../../../feature-flags/feature-flags.service';
import {ShipperContactsService} from '../../../../services/api/shipper.contacts.service';
import {CompanyPaymentMethodsService} from '@app/services/api/finance/company-payment-methods.service';

@Component({
    selector: 'app-add-contact-zipi-fin-receive-only-dialog',
    templateUrl: './manage-contact-payment-methods-in-current-cmpany-dialog.component.html',
    styleUrls: ['./manage-contact-payment-methods-in-current-cmpany-dialog.component.scss']
})
export class ManageContactPaymentMethodsInCurrentCmpanyDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    contactId: number | undefined;
    contactInfo: {
        company_name: string;
        email: string;
        first_name: string;
        last_name: string;
        nick_name: string;
    } | null = null;
    contactZipiFinancialReceiveGateway: IPaymentGateway | null = null;
    contactPayloadReceiveGateway: IPaymentGateway | null = null;
    contactPayloadCardGateway: IPaymentGateway | null = null;

    companyGateways: IPaymentGateway[] = [];
    isMuhneeAvailable: boolean = false;
    isPayloadAvailable: boolean = false;

    isBankGatewayLoaded: boolean = false;
    isCardGatewayLoaded: boolean = false;
    isButtonsLocked: boolean = false;

    isTppCreditCardFeatureFlagEnabled: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ManageContactPaymentMethodsInCurrentCmpanyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {contact_id: number; contactInfo: any},
        protected dialog: MatDialog,
        private companyGatewayService: CompanyGatewayService,
        private companyPaymentMethodsService: CompanyPaymentMethodsService,
        private paymentMethodsService: PaymentMethodsService,
        private notificationServiceZipi: NotificationsServiceZipi,
        protected marketplaceSource: MarketplaceSource,
        protected featureFlagsService: FeatureFlagsService,
        private contactsService: ShipperContactsService
    ) {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.isTppCreditCardFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:tpp_credit_card'
                );
            });
    }

    ngOnInit() {
        this.contactId = this.data.contact_id;
        this.contactInfo = this.data.contactInfo;
        this.getCompanyGateways();
        this.getContactGateway();

        // this.getContactPartnerCompanyZipiFinancialSettings();
    }

    getCompanyGateways() {
        this.companyGatewayService
            .getCompanyGatewaysByTypes(['zipi_financial_business'], null)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((gateways) => {
                if (gateways && gateways.length > 0) {
                    this.companyGateways = gateways;
                    this.isMuhneeAvailable = gateways.some((gateway) => gateway.driver_type === 'muhnee');
                    this.isPayloadAvailable = gateways.some((gateway) => gateway.driver_type === 'payload');
                }
            });
    }

    getContactGateway() {
        if (this.contactId) {
            this.companyGatewayService
                .getContactZipiFinancialReceiveOnlyGateway(this.contactId, 'muhnee', 'all')
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateway) => {
                    this.contactZipiFinancialReceiveGateway = gateway;
                    this.isBankGatewayLoaded = true;
                    this.isButtonsLocked = false;
                });
            this.companyGatewayService
                .getContactZipiFinancialReceiveOnlyGateway(this.contactId, 'payload', 'bank')
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateway) => {
                    this.contactPayloadReceiveGateway = gateway;
                    this.isButtonsLocked = false;
                    this.isBankGatewayLoaded = true;
                });
            this.companyGatewayService
                .getContactZipiFinancialReceiveOnlyGateway(this.contactId, 'payload', 'card')
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateway) => {
                    this.contactPayloadCardGateway = gateway;
                    this.isButtonsLocked = false;
                    this.isCardGatewayLoaded = true;
                });
        }
    }

    async setupZipiFinancialForContact(methodType: 'contact_bank' | 'contact_card', driverType: 'muhnee' | 'payload') {
        let customerRef = null;
        const addons = await firstValueFrom(this.marketplaceSource.addonsChangeEvent);
        if (!addons) {
            console.error('Cannot find addons');
            return;
        }
        const zipiFinancialInstance = addons.filter(
            (instanse) => instanse.addon && instanse.addon.slug === 'zipi_financial_integration'
        )[0];
        if (!zipiFinancialInstance) {
            console.error('Cannot find addon instance');
            return;
        }
        customerRef = zipiFinancialInstance.settings?.external_customer_ref;
        if (!customerRef) {
            console.error('Cannot find reference to Customer.');
            return;
        }

        let currentOperatingGateway = null;
        const gateway = await firstValueFrom(
            this.companyGatewayService.getContactZipiFinancialReceiveOnlyGateway(
                this.contactId as number,
                driverType,
                'all'
            )
        );

        if (driverType === 'muhnee') {
            const operatingGateways: IPaymentGateway[] = await firstValueFrom(
                this.companyGatewayService.getCompanyGatewaysByTypes(['zipi_financial_business'], null)
            );
            const muhneeGateways = operatingGateways.filter((gateway) => gateway.driver_type === 'muhnee');
            if (!muhneeGateways || muhneeGateways.length === 0) {
                return this.notificationServiceZipi.addError(
                    `First you need to activate Money Transfers (EFT) Service.`
                );
            } else if (muhneeGateways.length === 1) {
                currentOperatingGateway = muhneeGateways[0];
            } else {
                const selectGatewayDialogRef = this.dialog.open(SelectOwnGatewayForReceiveOnlyDialogComponent, {
                    maxHeight: '80vh',
                    width: '650px',
                    data: {gateways: muhneeGateways}
                });
                const res = await firstValueFrom(selectGatewayDialogRef.afterClosed());
                if (res) {
                    currentOperatingGateway = res;
                } else {
                    return null;
                }
            }
        }
        const contactData = {
            company_name: '',
            email: '',
            first_name: '',
            last_name: '',
            nick_name: ''
        };
        if (this.contactInfo) {
            contactData.company_name = this.contactInfo.company_name ? this.contactInfo.company_name : '';
            contactData.email = this.contactInfo.email ? this.contactInfo.email : '';
            contactData.first_name = this.contactInfo.first_name ? this.contactInfo.first_name : '';
            contactData.last_name = this.contactInfo.last_name ? this.contactInfo.last_name : '';
            contactData.nick_name = this.contactInfo.nick_name ? this.contactInfo.nick_name : '';
        } else {
            const contact = await firstValueFrom(this.contactsService.getContactById(this.contactId as number));

            let mainPerson = null;
            if (contact && contact.contact_persons && contact.contact_persons.length > 0) {
                mainPerson = contact.contact_persons.find((person) => person.type === 'main_person');
            }
            contactData.company_name = contact?.company_name ? contact?.company_name : '';
            contactData.email = mainPerson && mainPerson.email ? mainPerson.email : '';
            contactData.first_name = mainPerson && mainPerson.first_name ? mainPerson.first_name : '';
            contactData.last_name = mainPerson && mainPerson.last_name ? mainPerson.last_name : '';
            contactData.nick_name = mainPerson && mainPerson.preferred_name ? mainPerson.preferred_name : '';
        }

        const dialogRef = this.dialog.open<ZipiFinancialIframeDialogComponent, IZipiFinancialIframeDialogData>(
            ZipiFinancialIframeDialogComponent,
            {
                disableClose: true,
                maxHeight: '80vh',
                width: '650px',
                panelClass: 'custom-dialog-container',
                data: {
                    contactId: this.contactId ? this.contactId : null,
                    gateway: gateway,
                    ownGateway: currentOperatingGateway,
                    driverType: driverType,
                    paymentMethod: null,
                    methodType: methodType,
                    accessMethod: 'internal',
                    isUniversal: true,
                    customerId: customerRef,
                    prefillData: contactData,
                    creationSource: 'current_company',
                    storeAccountMethod: 'required'
                }
            }
        );

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result.isError) {
                    this.notificationServiceZipi.addError(result.message);
                }
                this.getContactGateway();
            });
    }

    changeVisibleMode(method: IPaymentMethod) {
        if (method && method.payment_method_id) {
            this.paymentMethodsService
                .changeMode(method.payment_method_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((res) => {
                    if (res) {
                        method.is_visible_to_connect = !method.is_visible_to_connect;
                    }
                });
        }
    }

    deletePaymentMethod(method: IPaymentMethod) {
        if (method && method.payment_method_id) {
            this.isButtonsLocked = true;
            this.companyPaymentMethodsService
                .deleteContactPaymentMethod(method.payment_method_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((res) => {
                    if (res) {
                        this.getContactGateway();
                    }
                });
        }
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
