import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {SkyslopeAuth} from '../../modules/auth/services/okta/skyslope-auth.service';
import {AuthService} from '../auth.service';
import {UserService} from 'app/services/user.service';
import {RbacService} from 'app/modules/rbac/rbac.service';
import {environment} from '../../../environments/environment';
import {getUserManager} from '../../modules/auth/services/okta/user-manager';
import {SessionService} from '../session.service';
import {OktaAuthStateService} from '@okta/okta-angular';
import {firstValueFrom} from 'rxjs';

const ACCESS_DENIED_ROUTE = 'accessdenied';

@Injectable()
export class AuthBootstrapService implements Resolve<{}> {
    constructor(
        @Inject(AuthService) protected authService: AuthService,
        @Inject(RbacService) protected rbacService: RbacService,
        protected router: Router,
        protected usersService: UserService,
        private skyslopeAuth: SkyslopeAuth,
        private sessionService: SessionService,
        private oktaAuthService: OktaAuthStateService,
        protected skyslopeAuthService: SkyslopeAuth
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.load(route, state);
    }

    async load(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isOktaAuthenticated = await this.skyslopeAuthService.isAuthenticated();
        if (!isOktaAuthenticated) {
            // If not authenticated, then update auth state to see if a session is available.
            const oktaAuth = await this.skyslopeAuthService.updateAuthState();
        }

        let isUserLoaded = await this.authService.loadAuth().catch((err) => {
            console.error(err);
        });

        const hasAccessToApp = await this.rbacService.isAllowed({general__access_app: true});
        if (!hasAccessToApp) {
            this.router.navigate([ACCESS_DENIED_ROUTE]);
        }

        return;
        // return this.rbacService.getProfilePermissions(currentProfile.id);
    }
}
