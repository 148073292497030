import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-manual-map-account-type-dialog',
    templateUrl: './manual-map-account-type-dialog.component.html'
})
export class ManualMapAccountTypeDialogComponent implements OnInit {
    accountForm: UntypedFormGroup | undefined;

    ledgerAccounts = [
        {title: 'Current Liability', slug: 'current_liability'},
        {title: 'Long Term Liability', slug: 'long_term_liability'}
    ];

    constructor(
        private fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<ManualMapAccountTypeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.accountForm = this.fb.group({
            currentAccount: [null, [Validators.required]]
        });
    }

    apply() {
        if (typeof this.accountForm === 'undefined') {
            return;
        }

        if (this.accountForm.invalid) {
            this.accountForm.markAllAsTouched();
        } else {
            const account = this.accountForm.getRawValue();
            this.dialogRef.close(account.currentAccount);
        }
    }
}
