import {Action} from '@ngrx/store';

export enum franchiseActionTypes {
    SAVE_FRANCHISE_REQUEST_PENDING = '[FRANCHISE] SAVE_FRANCHISE_REQUEST_PENDING',
    SAVE_FRANCHISE_REQUEST_SUCCESS = '[FRANCHISE] SAVE_FRANCHISE_REQUEST_SUCCESS',
    SAVE_FRANCHISE_REQUEST_ERROR = '[FRANCHISE] SAVE_FRANCHISE_REQUEST_ERROR',

    GET_FRANCHISE_REQUESTS_PENDING = '[FRANCHISE] GET_FRANCHISE_REQUESTS_PENDING',
    GET_FRANCHISE_REQUESTS_SUCCESS = '[FRANCHISE] GET_FRANCHISE_REQUESTS_SUCCESS',
    GET_FRANCHISE_REQUESTS_ERROR = '[FRANCHISE] GET_FRANCHISE_REQUESTS_ERROR',

    ACCEPT_OR_REJECT_FRANCHISE_REQUESTS_PENDING = '[FRANCHISE] ACCEPT_OR_REJECT_FRANCHISE_REQUESTS_PENDING',
    ACCEPT_OR_REJECT_FRANCHISE_REQUESTS_SUCCESS = '[FRANCHISE] ACCEPT_OR_REJECT_FRANCHISE_REQUESTS_SUCCESS',
    ACCEPT_OR_REJECT_FRANCHISE_REQUESTS_ERROR = '[FRANCHISE] ACCEPT_OR_REJECT_FRANCHISE_REQUESTS_ERROR',

    CANCEL_FRANCHISE_REQUEST_PENDING = '[FRANCHISE] CANCEL_FRANCHISE_REQUEST_PENDING',
    CANCEL_FRANCHISE_REQUEST_SUCCESS = '[FRANCHISE] CANCEL_FRANCHISE_REQUEST_SUCCESS',
    CANCEL_FRANCHISE_REQUEST_ERROR = '[FRANCHISE] CANCEL_FRANCHISE_REQUEST_ERROR',

    DISCONNECT_FRANCHISE_REQUEST_PENDING = '[FRANCHISE] DISCONNECT_FRANCHISE_REQUEST_PENDING',
    DISCONNECT_FRANCHISE_REQUEST_SUCCESS = '[FRANCHISE] DISCONNECT_FRANCHISE_REQUEST_SUCCESS',
    DISCONNECT_FRANCHISE_REQUEST_ERROR = '[FRANCHISE] DISCONNECT_FRANCHISE_REQUEST_ERROR'
}

export class SaveFranchiseRequestPendingAction implements Action {
    readonly type: string = franchiseActionTypes.SAVE_FRANCHISE_REQUEST_PENDING;
    constructor(public payload?: any) {}
}
export class SaveFranchiseRequestSuccessAction implements Action {
    readonly type: string = franchiseActionTypes.SAVE_FRANCHISE_REQUEST_SUCCESS;
    constructor(public payload?: any) {}
}
export class SaveFranchiseRequestErrorAction implements Action {
    readonly type: string = franchiseActionTypes.SAVE_FRANCHISE_REQUEST_ERROR;
    constructor(public payload?: any) {}
}

export class GetFranchiseRequestsPendingAction implements Action {
    readonly type: string = franchiseActionTypes.GET_FRANCHISE_REQUESTS_PENDING;
    constructor(public payload?: any) {}
}
export class GetFranchiseRequestsSuccessAction implements Action {
    readonly type: string = franchiseActionTypes.GET_FRANCHISE_REQUESTS_SUCCESS;
    constructor(public payload?: any) {}
}
export class GetFranchiseRequestsErrorAction implements Action {
    readonly type: string = franchiseActionTypes.GET_FRANCHISE_REQUESTS_ERROR;
    constructor(public payload?: any) {}
}

export class AcceptOrRejectFranchiseRequestPendingAction implements Action {
    readonly type: string = franchiseActionTypes.ACCEPT_OR_REJECT_FRANCHISE_REQUESTS_PENDING;
    constructor(public payload?: any) {}
}
export class AcceptOrRejectFranchiseRequestSuccessAction implements Action {
    readonly type: string = franchiseActionTypes.ACCEPT_OR_REJECT_FRANCHISE_REQUESTS_SUCCESS;
    constructor(public payload?: any) {}
}
export class AcceptOrRejectFranchiseRequestErrorAction implements Action {
    readonly type: string = franchiseActionTypes.ACCEPT_OR_REJECT_FRANCHISE_REQUESTS_ERROR;
    constructor(public payload?: any) {}
}

export class CancelFranchiseRequestPendingAction implements Action {
    readonly type: string = franchiseActionTypes.CANCEL_FRANCHISE_REQUEST_PENDING;
    constructor(public payload?: any) {}
}
export class CancelFranchiseRequestSuccessAction implements Action {
    readonly type: string = franchiseActionTypes.CANCEL_FRANCHISE_REQUEST_SUCCESS;
    constructor(public payload?: any) {}
}
export class CancelFranchiseRequestErrorAction implements Action {
    readonly type: string = franchiseActionTypes.CANCEL_FRANCHISE_REQUEST_ERROR;
    constructor(public payload?: any) {}
}

export class DisconnectFranchiseRequestPendingAction implements Action {
    readonly type: string = franchiseActionTypes.DISCONNECT_FRANCHISE_REQUEST_PENDING;
    constructor(public payload?: any) {}
}
export class DisconnectFranchiseRequestSuccessAction implements Action {
    readonly type: string = franchiseActionTypes.DISCONNECT_FRANCHISE_REQUEST_SUCCESS;
    constructor(public payload?: any) {}
}
export class DisconnectFranchiseRequestErrorAction implements Action {
    readonly type: string = franchiseActionTypes.DISCONNECT_FRANCHISE_REQUEST_ERROR;
    constructor(public payload?: any) {}
}

export type FranchiseAction =
    | SaveFranchiseRequestPendingAction
    | SaveFranchiseRequestSuccessAction
    | SaveFranchiseRequestErrorAction
    | GetFranchiseRequestsPendingAction
    | GetFranchiseRequestsSuccessAction
    | GetFranchiseRequestsErrorAction
    | AcceptOrRejectFranchiseRequestPendingAction
    | AcceptOrRejectFranchiseRequestSuccessAction
    | AcceptOrRejectFranchiseRequestErrorAction
    | CancelFranchiseRequestPendingAction
    | CancelFranchiseRequestErrorAction
    | CancelFranchiseRequestSuccessAction
    | DisconnectFranchiseRequestErrorAction
    | DisconnectFranchiseRequestPendingAction
    | DisconnectFranchiseRequestSuccessAction;
