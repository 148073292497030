import {Injectable} from '@angular/core';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import {environment} from '../../environments/environment';

export enum IdleMessages {
    IDLE_ENDED = "You've gone idle!",
    IDLE_STARTED = 'Idle Started',
    IDLE_END = 'The user has returned from being idle.',
    IDLE_INTERRUPTED = 'idle interrupted',
    IDLE_PURPOSE = 'The purpose of this code is try to explain the use of ng-idle library',
    IDLE_NO_STARTED = 'Not started.',
    IDLE_TIMEOUTPUT_MESSAGE = 'User is idle! - user should be log out',
    IDLE_TIMEOUT = 'Timeout!',
    IDLE_TIMEOUT_WARNING = 'You will time out in %time% seconds!'
}

export enum IdleStatus {
    IDLE_ENDED = 'Ended',
    IDLE_INTERRUPTED = 'Interrupted',
    IDLE_USER_ACTIVE = 'userActive',
    IDLE_STARTED = 'started',
    IDLE_STOPPED = 'stopped',
    IDLE_TIMEOUT_STARTED = 'TimeoutStarted'
}

@Injectable({
    providedIn: 'root'
})
export class IdleUserService {
    constructor(
        private _idle: Idle,
        private keepalive: Keepalive
    ) {
        this._idle.setIdle(environment.maxIdleSeconds);
        this._idle.setTimeout(environment.sessionExpireWarningSeconds);
        this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        window.addEventListener('storage', (event) => {
            if (event.key === 'idleState' && event.newValue === 'Started') {
                this._idle.watch();
            }

            if (event.key === 'idleState' && event.newValue === 'Stopped') {
                this._idle.stop();
            }
        });
    }

    get idle() {
        return this._idle;
    }

    startWatching() {
        this._idle.watch();
        localStorage.setItem('idleState', IdleStatus.IDLE_STARTED);
    }

    setInterrupts() {
        this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    }
    stopWatching() {
        this._idle.stop();
        localStorage.setItem('idleState', IdleStatus.IDLE_STOPPED);
    }
}
