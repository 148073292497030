<div class="container mt-5">
    <h3>Integrations</h3>
    <mat-tab-group (selectedIndexChange)="activeTabChanged($event)">
        <ng-container *ngIf="activatedAddonsInstances && activatedAddonsInstances.length > 0">
            <mat-tab label="Activated">
                <ng-container *ngFor="let activatedAddonInstance of activatedAddonsInstances">
                    <!--Feature Flags -->
                    <mat-card
                        class="mb-2"
                        *ngIf="
                            activatedAddonInstance &&
                            activatedAddonInstance.addon &&
                            activatedAddonInstance.addon.settings &&
                            (!activatedAddonInstance.addon.settings.is_feature_flag_needed ||
                                featureFlags[activatedAddonInstance.addon.settings.feature_flag_id])
                        "
                        rbac
                        [rule]="activatedAddonInstance.rbac"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'block'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        <mat-card-content class="col">
                            <div class="row">
                                <div class="col">
                                    <div class="col logo">
                                        <img style="width: 180px" class="my-3" [src]="activatedAddonInstance.logo" />
                                    </div>
                                    <div class="col mt-2" style="flex: auto">
                                        <div class="title">{{ activatedAddonInstance.title }}</div>
                                    </div>
                                </div>
                                <div class="col" style="max-width: max-content; min-width: 240px">
                                    <ng-container *ngIf="activatedAddonInstance.addon.is_featurable">
                                        <mat-slide-toggle
                                            [(ngModel)]="activatedAddonInstance.is_featured"
                                            (change)="onToggleChangeEvent($event, activatedAddonInstance)"
                                            color="primary"
                                            style="margin-bottom: 5px"
                                            >Display in Main Navigation</mat-slide-toggle
                                        >
                                    </ng-container>
                                    <button
                                        *ngIf="
                                            activatedAddonInstance.activated &&
                                            activatedAddonInstance.addon?.settings?.enabled_company_settings
                                        "
                                        mat-raised-button
                                        (click)="addonCompanySettings(activatedAddonInstance)"
                                    >
                                        Open
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="description">{{ activatedAddonInstance.description }}</div>
                                <i class="sp-spacer"></i>
                                <button
                                    *ngIf="activatedAddonInstance.activated"
                                    mat-button
                                    (click)="deactivateAddon(activatedAddonInstance)"
                                >
                                    Deactivate
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </ng-container>
            </mat-tab>

            <mat-tab label="All">
                <ng-container *ngFor="let addonInstance of allAddonsInstances">
                    <!--Feature Flags -->
                    <mat-card
                        class="mb-2"
                        *ngIf="
                            addonInstance &&
                            addonInstance.addon &&
                            addonInstance.addon.settings &&
                            (!addonInstance.addon.settings.is_feature_flag_needed ||
                                featureFlags[addonInstance.addon.settings.feature_flag_id])
                        "
                        rbac
                        [rule]="addonInstance.rbac"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'block'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        <mat-card-content class="row">
                            <div class="col logo">
                                <img style="width: 180px" class="my-3" [src]="addonInstance.logo" />
                            </div>
                            <div class="col mt-2" style="flex: auto">
                                <div class="title">{{ addonInstance.title }}</div>
                                <div class="description">{{ addonInstance.description }}</div>
                            </div>
                            <div class="col">
                                <button
                                    rbac
                                    [rule]="addonInstance.rbac"
                                    [denyMethod]="'style'"
                                    [allowStyle]="{display: 'block'}"
                                    [denyStyle]="{display: 'none'}"
                                    *ngIf="!addonInstance.activated"
                                    mat-button
                                    class="mat-primary"
                                    (click)="activateAddon(addonInstance)"
                                >
                                    Activate
                                </button>
                                <button
                                    rbac
                                    [rule]="addonInstance.rbac"
                                    [denyMethod]="'style'"
                                    [allowStyle]="{display: 'block'}"
                                    [denyStyle]="{display: 'none'}"
                                    *ngIf="addonInstance.activated"
                                    mat-button
                                    (click)="deactivateAddon(addonInstance)"
                                >
                                    Deactivate
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </ng-container>
            </mat-tab>
        </ng-container>

        <ng-container *ngIf="activatedAddonsInstances && activatedAddonsInstances.length === 0">
            <mat-tab label="All">
                <ng-container *ngFor="let addonInstance of allAddonsInstances">
                    <mat-card
                        class="mb-2"
                        *ngIf="
                            addonInstance &&
                            addonInstance.addon &&
                            addonInstance.addon.settings &&
                            (!addonInstance.addon.settings.is_feature_flag_needed ||
                                featureFlags[addonInstance.addon.settings.feature_flag_id])
                        "
                        rbac
                        [rule]="addonInstance.rbac"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'block'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        <mat-card-content
                            class="row"
                            *ngIf="addonInstance && addonInstance.addon && addonInstance.addon.slug"
                        >
                            <div class="col logo">
                                <img style="width: 180px" class="my-3" [src]="addonInstance.logo" />
                            </div>
                            <div class="col mt-2" style="flex: auto">
                                <div class="title">{{ addonInstance.title }}</div>
                                <div class="description">{{ addonInstance.description }}</div>
                            </div>
                            <div class="col">
                                <button
                                    rbac
                                    [rule]="addonInstance.rbac"
                                    [denyMethod]="'style'"
                                    [allowStyle]="{display: 'block'}"
                                    [denyStyle]="{display: 'none'}"
                                    *ngIf="!addonInstance.activated"
                                    mat-button
                                    class="mat-primary"
                                    (click)="activateAddon(addonInstance)"
                                >
                                    Activate
                                </button>
                                <button
                                    rbac
                                    [rule]="addonInstance.rbac"
                                    [denyMethod]="'style'"
                                    [allowStyle]="{display: 'block'}"
                                    [denyStyle]="{display: 'none'}"
                                    *ngIf="addonInstance.activated"
                                    mat-button
                                    (click)="deactivateAddon(addonInstance)"
                                >
                                    Deactivate
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </ng-container>
            </mat-tab>
        </ng-container>
    </mat-tab-group>
</div>
