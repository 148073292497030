<div class="w-100 py-2 px-3 d-flex justify-content-between border-bottom bg-white" style="height: 60px">
    <h3 *ngIf="router.url.includes('/settings/general')" class="mb-0 d-flex align-items-center">General Settings</h3>
    <h3 *ngIf="router.url.includes('/settings/purchase-settings')" class="mb-0 d-flex align-items-center">
        Purchase Settings
    </h3>
    <h3 *ngIf="router.url.includes('/settings/tags')" class="mb-0 d-flex align-items-center">Tags</h3>
    <h3 *ngIf="router.url.includes('/settings/automation')" class="mb-0 d-flex align-items-center">Automation</h3>

    <button *ngIf="router.url.includes('tags')" mat-raised-button (click)="openTagDialogEmitter.next()">
        Create Category
    </button>
    <button *ngIf="router.url.includes('automation')" mat-raised-button (click)="openCreateAutoTagDialogEmitter.next()">
        Create Auto-tag
    </button>
</div>
