import {GenericEntity} from '../../../entites/generic.entity';
import {Company} from '../../../models/company';
import {Profile} from '../../../models/profile';
import {FocusAreaEntity} from './focus-area.entity';
import {Group} from '../../../models/group';

export class GoalEntity extends GenericEntity {
    id: number | undefined = undefined;
    company_group: Group | null = null;
    company: Company | null = null;
    profile: Profile | null = null;
    dynamic: string | null = null;
    period: string | null = null;
    title: string | null = null;
    units: string | null = null;
    amount: number | null = null;
    meta_goal: GoalEntity | null = null;
    impact: number | null = null;
    type: string | null = null;
    start_tracking_date: string | null = null;
    baseline_value: number | null = null;

    constructor() {
        super();
    }

    static get helpers() {
        return Object.assign(
            {
                props: Object.getOwnPropertyNames(new this()).reduce(
                    (acc, curr) => {
                        acc[curr] = curr;
                        return acc;
                    },
                    {} as {[key: string]: any}
                ),
                build: {
                    meta_goal: (val: GoalEntity, fabric: any) => GoalEntity.fabric[fabric](val),
                    focus_area: (val: FocusAreaEntity, fabric: any) => FocusAreaEntity.fabric[fabric](val),
                    company: (val: Company, fabric = 'object.assign') => Object.assign(new Company(), val)
                }
            },
            GenericEntity.helpers
        );
    }

    static get fabric(): any {
        return Object.assign(GenericEntity.fabric, {
            hydrate: (
                input: object,
                output: GoalEntity = new this(),
                props: {[prop: string]: string} = this.helpers.props,
                transform: {[id: string]: (val: any, fabric: any) => any} = this.helpers.build
            ): GoalEntity => {
                // @ts-ignore: might be null if input is null
                const resp = <GoalEntity>GenericEntity.fabric.hydrate(input, output, props, transform);
                if (resp.meta_goal) {
                    resp.title = resp.meta_goal.title;
                    resp.period = resp.meta_goal.period;
                    resp.amount =
                        resp.meta_goal.amount && resp.impact ? (resp.meta_goal.amount * resp.impact) / 100 : 0;
                }
                return resp;
            },
            dehydrate: (
                input: GoalEntity,
                output: object = {},
                props: {[prop: string]: string} = this.helpers.props,
                transform: {[id: string]: (val: any, fabric: any) => any} = this.helpers.build
            ): object => {
                // @ts-ignore: might be null if input is null
                const resp: any = <object>GenericEntity.fabric.dehydrate(input, output, props, transform);
                if (input.meta_goal) {
                    resp['title'] = null;
                    resp['period'] = null;
                    resp['amount'] = null;
                }
                return resp;
            }
        });
    }

    static get dynamic() {
        return {
            increase: 'increase',
            decrease: 'decrease',
            attain: 'attain'
        };
    }

    static get period() {
        return {
            day: 'day',
            week: 'week',
            month: 'month',
            quarter: 'quarter',
            year: 'year'
        };
    }

    static get units() {
        return {
            dollars: 'dollars',
            percents: 'percents',
            units: 'units'
        };
    }

    static get type() {
        return {
            enumerable: '',
            free_form: ''
        };
    }
}
