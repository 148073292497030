import {merge as observableMerge, Observable, Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../../models/user';
import {AppInitSource} from './app-init.source';
import * as Sentry from '@sentry/angular-ivy';
import {environment} from '../../../environments/environment';

@Injectable()
export class SystemUserSource {
    private triggers = {
        systemUser: new Subject<User>(),
        linkToCommunity: new Subject<string>()
    };

    private events = {
        chageSystemUser: <Observable<User>>observableMerge(this.triggers.systemUser),
        changeLinkToCommunity: <Observable<string>>observableMerge(this.triggers.linkToCommunity)
    };

    private systemUser: User | null = null;
    private linkToCommunity: string | null = null;

    public get chageSystemUser(): Observable<User> {
        if (this.systemUser) {
            return this.events.chageSystemUser.pipe(startWith(this.systemUser));
        }
        return this.events.chageSystemUser;
    }

    public get changeLinkToCommunity(): Observable<string> {
        if (this.linkToCommunity) {
            return this.events.changeLinkToCommunity.pipe(startWith(this.linkToCommunity));
        }
        return this.events.changeLinkToCommunity;
    }

    constructor(
        protected appInitSource: AppInitSource,
        protected router: Router
    ) {
        this.appInitSource.appInit.subscribe((appInitResponse) => {
            this.systemUser = appInitResponse.systemUser;
            this.linkToCommunity = appInitResponse.linkToCommunity;
            this.triggers.systemUser.next(appInitResponse.systemUser!);
            this.triggers.linkToCommunity.next(appInitResponse.linkToCommunity);

            // background auth user in community (based on ZohoDesk)
            // see https://help.zoho.com/portal/en/kb/desk/for-administrators/user-access-and-security/articles/setting-up-remote-authentication
            if (appInitResponse.linkForBackgroundAuthInCommunity) {
                const iframe = document.createElement('iframe');
                iframe.id = 'zoho-desk-remote-auth';
                iframe.style.display = 'none';
                iframe.src = appInitResponse.linkForBackgroundAuthInCommunity;
                document.body.appendChild(iframe);

                // fetch(appInitResponse.linkForBackgroundAuthInCommunity, {mode: 'no-cors'})
                //     .catch(err => console.error('Problem with background auth into community portal.'));
            }
        });

        this.chageSystemUser.subscribe((systemUser) => {
            if (environment.sentryConfig) {
                Sentry.configureScope((scope) => {
                    scope.setUser({
                        id: systemUser.id!.toString(),
                        email: systemUser.email!
                    });
                });
            }

            // if (typeof window['smartlook'] === 'function') {
            //     window['smartlook'](
            //         'identify',
            //         systemUser.id.toString(),
            //         {
            //             'name': `${systemUser.first_name} ${systemUser.last_name}`,
            //             'first_name': systemUser.first_name,
            //             'last_name': systemUser.last_name,
            //             'email': systemUser.email
            //         }
            //     );
            // }
        });
    }
}
