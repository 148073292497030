<div class="global-search-container w-100" *ngIf="!dealControl || !dealControl.value" [formGroup]="globalSearchForm!">
    <mat-form-field class="w-100">
        <mat-icon matPrefix class="mr-2">content_paste</mat-icon>
        <input
            matInput
            type="text"
            placeholder="{{ placeholder }} Search"
            [formControl]="globalSearchForm.controls.searchData"
            autocomplete="doNotAutoComplete"
            [matAutocomplete]="auto"
        />

        <button
            mat-button
            *ngIf="globalSearchForm.controls.searchData.value"
            matSuffix
            style="top: -3px"
            mat-icon-button
            aria-label="Clear"
            (click)="resetSearch()"
        >
            <mat-icon>close</mat-icon>
        </button>

        <mat-autocomplete #auto="matAutocomplete">
            <mat-option
                class="deal-option"
                [ngStyle]="{'width.px': isNeedRestrictWidth ? hostElementWidth : null}"
                *ngFor="let result of searchResults; let i = index"
                [value]="globalSearchForm.controls.searchData.value"
                (onSelectionChange)="onSelectionChange(result._source)"
                [disabled]="disableByTypes.includes(result._source?.raw_data?.type)"
            >
                <div class="d-flex justify-content-between text-capitalize">
                    <span class="text-truncate" style="padding-right: 15px">{{ result._source.raw_data.name }}</span>
                    <span>{{ result._source.raw_data.type }}/{{ result._source.raw_data.status }}</span>
                </div>
                <div class="d-flex justify-content-between deal-address text-muted">
                    <span *ngIf="result._source.index_data.address">{{ result._source.index_data.full_address }}</span>
                    <span *ngIf="!result._source.index_data.address">No address</span>
                    <span>{{ result._source.index_data.id }}</span>
                </div>
            </mat-option>
        </mat-autocomplete>
        <mat-hint align="end" *ngIf="noResults">No deals</mat-hint>
    </mat-form-field>
</div>

<ng-container *ngIf="dealControl!.value">
    <mat-form-field class="w-100">
        <mat-icon matPrefix class="mr-2">content_paste</mat-icon>
        <input [(ngModel)]="selectedDeal" disabled matInput type="text" placeholder="{{ placeholder }}" />
        <button
            mat-button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            [disabled]="disabledSelect"
            (click)="removeDealFkId()"
        >
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
</ng-container>
