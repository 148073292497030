import {Component, OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {map, filter, takeUntil, tap} from 'rxjs/operators';
import {
    IFinancialTransfer,
    IInvoice,
    IInvoiceLog,
    IPayment,
    IPaymentReceivedMadeRequestObject,
    ITransaction
} from '@cyberco-nodejs/zipi-typings';
import {BILL_INVOICE_STATUS_COLOR, PAYMENT_TERMS, FINANCIAL_TRANSFER_TYPE_LABELS} from 'app/local-typings';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import * as FileSaver from 'file-saver';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ManualMarkAsPaidConfirmDialogComponent} from '../manual-mark-as-paid-confirm-dialog/manual-mark-as-paid-confirm-dialog.component';
import {ChargeCustomerDialogComponent} from '../charge-customer-dialog/charge-customer-dialog.component';
import {PaymentsReceivedService} from '../../../services/payments-received.service';
import {ApplyCreditsToInvoiceDialogComponent} from '../apply-credits-to-invoice-dialog/apply-credits-to-invoice-dialog.component';
import {SessionService} from 'app/services/session.service';
import {TransactionsService} from 'app/services/api/finance/transactions.service';
import {DealService} from 'app/services/deal.service';
import {PaymentsService} from '../../../services/payments.service';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {NotificationsServiceZipi} from '../../../../notifications/notifications.service';
import {QBSyncEventsSource} from '../../../../../services/sources/qb-sync-events.source';
import {MarketplaceSource} from '../../../../account-info/marketplace/marketplace.source';
import {IRelatedDealPayoutInfo} from '@app/modules/finance/types/financial.types';

@Component({
    selector: 'app-invoice-page',
    templateUrl: 'invoice-page.component.html',
    styleUrls: ['invoice-page.component.scss']
})
export class InvoicePageComponent implements OnInit, OnDestroy {
    @Input() dialogMode = false;
    @Input() invoiceId: number | undefined;

    @Output() needSwitchToEditInDialog = new EventEmitter();
    @Output() invoiceUpdatedInDialog = new EventEmitter();

    private unsubscribe: Subject<void> = new Subject();

    termsMap = new Map<string, string>(<any>PAYMENT_TERMS);

    invoice: IInvoice | undefined;

    invoiceQBSync: {
        sync_status: 'not_synced' | 'in_progress' | 'completed' | 'error';
        qb_invoice_ref: string | null;
        qb_link: string | null;
        is_loaded: boolean;
        is_active: boolean;
    } = {
        sync_status: 'not_synced',
        qb_invoice_ref: null,
        qb_link: null,
        is_loaded: false,
        is_active: false
    };

    dataSource: MatTableDataSource<ITransaction>;
    displayedColumns: string[] = ['account_name', 'debit', 'credit'];

    transfersDataSource: MatTableDataSource<IRelatedDealPayoutInfo>;
    transferDisplayedColumns: string[] = ['index', 'deal_address', 'product', 'connection_type', 'amount'];

    showJournals: boolean;
    showTransfers: boolean;
    showHistory: boolean = false;
    allowApply: boolean = false;
    creditsBalance: number = 0;

    invoiceLogs: IInvoiceLog[] = [];

    currentCompanyId: number | null = null;

    restrictedBasedOnDealConnection: boolean | null = null;

    statusColor = BILL_INVOICE_STATUS_COLOR;
    FINANCIAL_TRANSFER_TYPE_LABELS = FINANCIAL_TRANSFER_TYPE_LABELS;
    isCancelPaymentButtonDisabled: boolean;

    constructor(
        private route: ActivatedRoute,
        private invoiceSrv: InvoicesService,
        private paymentsReceivedService: PaymentsReceivedService,
        private paymentsService: PaymentsService,
        private transactionsService: TransactionsService,
        private sessionService: SessionService,
        private dealService: DealService,
        private router: Router,
        public dialog: MatDialog,
        private notificationServiceZipi: NotificationsServiceZipi,
        public qbSyncEventsSource: QBSyncEventsSource,
        protected marketplaceSource: MarketplaceSource
    ) {
        this.dataSource = new MatTableDataSource<ITransaction>([]);
        this.transfersDataSource = new MatTableDataSource<IRelatedDealPayoutInfo>([]);
        this.showJournals = false;
        this.showTransfers = true;
        this.isCancelPaymentButtonDisabled = false;
    }

    ngOnInit() {
        this.marketplaceSource.addonsChangeEvent.pipe(takeUntil(this.unsubscribe)).subscribe((instances) => {
            const qbAddon = instances.find(
                (instance) => instance.addon && instance.addon.slug === 'quickbooks_integration'
            );
            if (qbAddon) {
                this.invoiceQBSync.is_active = !!qbAddon.activated;
            }
        });
        this.qbSyncEventsSource.qbSyncEvents.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            if (data && data.entity === 'invoice' && data.books_entity_id === this.invoice?.invoice_id) {
                this.invoiceQBSync.qb_invoice_ref = data.qb_entity_id;
                this.invoiceQBSync.qb_link = data.qb_entity_link;
                this.invoiceQBSync.sync_status = data.status;
            }
        });

        if (this.sessionService.profile && this.sessionService.profile.company) {
            this.currentCompanyId = Number(this.sessionService.profile.company.id);
        }

        this.initPreview();
    }

    editInvoice(invoice: IInvoice) {
        this.router.navigate([`/sales/invoices/edit/${invoice.invoice_id}`]);
    }

    hasNonModifiableTransactions() {
        if (this.invoice && this.invoice.journal && this.invoice.journal.line_items) {
            return !!(
                this.invoice.journal &&
                this.invoice.journal.line_items.find(
                    (tr) => !!tr.reconciliation_fk_id || !!tr.matched__transaction_external_id
                )
            );
        } else {
            return false;
        }
    }

    voidInvoice() {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            data: {
                title: 'Void Invoice',
                message: `Invoice will be voided.`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((c) => !!c),
                takeUntil(this.unsubscribe)
            )
            .subscribe((confirm) => {
                if (confirm && this.invoice && this.invoice.invoice_id) {
                    this.invoiceSrv
                        .markAsVoid(this.invoice.invoice_id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((result) => {
                            if (result && this.invoice) {
                                this.invoice.summary_status = 'void';
                                this.invoice['journal'] = undefined;
                                this.invoice.payments = [];
                                // this.ngOnInit();
                            }
                        });
                }
            });
    }

    downloaInvoicePdf() {
        if (!this.invoice || !this.invoice.invoice_id) {
            return;
        }
        this.invoiceSrv
            .dewnloadInvoicePdf(this.invoice.invoice_id)
            .pipe(
                map((res) => new Blob([new Uint8Array(res.data)])),
                tap((result) => FileSaver.saveAs(result, `invoice_${this.invoice!.invoice_id}.pdf`)),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    }

    sendToQuickbooks() {
        if (!this.invoice || !this.invoice.invoice_id) {
            return;
        }
        this.invoiceSrv
            .syncInvoiceWithQB(this.invoice.invoice_id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                if (response) {
                    // this.notificationServiceZipi.addInfo(`Sync started`);
                }
            });
    }
    viewInQuickbooks() {
        if (!this.invoice || !this.invoiceQBSync.qb_link) {
            return;
        }
        window.open(`${this.invoiceQBSync.qb_link}`, '_blank');
    }

    loadHistory() {
        if (this.showHistory && this.invoice && this.invoice.invoice_id) {
            this.invoiceSrv
                .getInvoiceHistory(this.invoice.invoice_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((logs) => (this.invoiceLogs = logs));
        }
    }

    initPreview() {
        this.route.paramMap
            .pipe(
                map((pm) => {
                    const stringId: string | null = pm.get('id');
                    return Number(stringId);
                }),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                if (!id && this.dialogMode && this.invoiceId) {
                    id = this.invoiceId;
                }

                this.invoiceSrv
                    .loadQBSync(id)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((response) => {
                        if (response) {
                            this.invoiceQBSync.qb_invoice_ref = response.qb_entity_id;
                            this.invoiceQBSync.sync_status = response.status;
                            this.invoiceQBSync.qb_link = response.qb_entity_link;
                        } else {
                            this.invoiceQBSync.qb_invoice_ref = null;
                            this.invoiceQBSync.qb_link = null;
                            this.invoiceQBSync.sync_status = 'not_synced';
                        }
                        this.invoiceQBSync.is_loaded = true;
                    });

                this.invoiceSrv
                    .getInvoiceForViewById(id)
                    .pipe(
                        filter((invoice) => !!invoice),
                        tap((invoice) => {
                            this.invoice = invoice;

                            if (this.invoice.money_sender__contact_fk_id) {
                                this.calculateCreditsBalance();
                            }

                            this.getInvoiceFinancialTransfers();

                            let transactions: ITransaction[] = [];
                            if (invoice.journal && invoice.journal.line_items) {
                                transactions = invoice.journal.line_items;
                            }

                            this.dataSource.data = transactions;
                        }),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe(() => {
                        this.route.queryParamMap
                            .pipe(
                                map((qpm) => qpm.get('chargeFromCredits')),
                                takeUntil(this.unsubscribe)
                            )
                            .subscribe((chargeFromCredits) => {
                                if (chargeFromCredits) {
                                    this.allowApply = true;
                                    this.openApplyCreditsToInvoiceDialog();
                                }
                            });
                    });
            });
    }

    isProcessingPayment() {
        if (this.invoice && this.invoice.payments && this.invoice.payments.length > 0) {
            for (const pay of this.invoice.payments) {
                if (pay.status === 'processing' || pay.status === 'review' || pay.status === 'pending') {
                    return true;
                }
            }
        }
        return false;
    }

    calculateCreditsBalance() {
        if (this.invoice && this.invoice.money_sender__contact_fk_id) {
            this.transactionsService
                .getExcessContactTransactions(this.invoice.money_sender__contact_fk_id)
                .pipe(
                    map((excess) => {
                        this.creditsBalance = Number((excess.credit_note + excess.payment_received).toFixed(2));

                        this.allowApply =
                            !!this.invoice &&
                            !!this.invoice.pending_balance &&
                            this.invoice.pending_balance > 0 &&
                            this.creditsBalance !== 0 &&
                            this.invoice.status_of_invoice !== 'draft' &&
                            this.invoice.status_of_payment !== 'paid';
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe();
        }
    }

    getItemsRelatedDeals() {
        if (this.invoice && this.invoice.items) {
            for (const item of this.invoice.items) {
                if (item.connected__deal_fk_id) {
                    this.dealService.getDealBaseView(item.connected__deal_fk_id).then((deal) => {
                        item.related_deal = deal;
                    });
                }
            }
        }
    }

    getInvoiceFinancialTransfers() {
        if (this.invoice && this.invoice.invoice_id) {
            this.dealService.getFinancialTransfersByInvoiceId(this.invoice.invoice_id).then((transfers) => {
                this.transfersDataSource.data = transfers;
                if (transfers && transfers.length > 0) {
                    this.restrictedBasedOnDealConnection = transfers.some((t) => t.is_escrow);
                } else {
                    this.restrictedBasedOnDealConnection = false;
                }
            });
        }
    }

    getTotalFor(type: 'debit' | 'credit') {
        let total = 0;
        this.dataSource.data.forEach((transaction) => {
            if (transaction.debit_or_credit === type) {
                total += transaction.amount;
            }
        });
        return total;
    }

    recheckPaymentsStatuses(invoice: IInvoice) {
        if (invoice && invoice.invoice_id) {
            this.invoiceSrv
                .recheckInvoicePaymentsStatuses(invoice.invoice_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((status) => {
                    console.dir(status);
                    if (status) {
                        this.ngOnInit();
                    }
                });
        }
    }

    markAsOpen() {
        if (this.invoice && this.invoice.invoice_id) {
            this.invoiceSrv
                .markAsOpen(this.invoice.invoice_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (result) {
                        this.router.navigate(['/sales/invoices']);
                    }
                });
        }
    }

    resendInvoice() {
        if (this.invoice && this.invoice.invoice_id) {
            this.invoiceSrv
                .sendInvoiceMail(this.invoice.invoice_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (result) {
                        this.router.navigate(['/sales/invoices']);
                    }
                });
        }
    }

    confirmPaid() {
        const dialogRef = this.dialog.open(ManualMarkAsPaidConfirmDialogComponent, {
            data: {
                invoice: this.invoice,
                needAmount: true
                // payment_method: this.getPaymentTitleById(newBill.pay_to__payment_method_fk_ids),
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((paymentInfo) => {
                const requestObj: IPaymentReceivedMadeRequestObject = {
                    money_sender__contact_fk_id:
                        this.invoice && this.invoice.money_sender__contact_fk_id
                            ? this.invoice.money_sender__contact_fk_id
                            : null,
                    money_receiver__contact_fk_id: null,
                    paid_date: paymentInfo.paid_date,
                    scheduled_date: null,
                    amount: paymentInfo.payment_amount,
                    pay_to__ledger_account_fk_id: paymentInfo.ledger_account_id,
                    pay_to__payment_method_fk_id: paymentInfo.payment_method_id,
                    paid_by__ledger_account_fk_id: null,
                    paid_by__payment_method_fk_id: null,
                    payments: [
                        {
                            entity_id: this.invoice && this.invoice.invoice_id ? this.invoice.invoice_id : null,
                            payment_id: null,
                            amount: paymentInfo.payment_amount,
                            entity: null,
                            entity_transfer_id: null
                        }
                    ],
                    is_create_multiple_payments: true,
                    payment_mode: paymentInfo.payment_mode,
                    payment_number: null,
                    reference: null,
                    notes: paymentInfo.note,

                    matched__transaction_external_id: null,
                    check_info: paymentInfo.check_info,
                    deposit_release_id: null,
                    is_locked_for_applying: false,
                    allow_auto_apply: false,
                    source__deal_fk_id: null,
                    sender_velocity: null,
                    restrict_downgrade: false
                };

                this.paymentsReceivedService
                    .createPaymentReceived(requestObj)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((result) => {
                        if (result) {
                            this.router.navigate(['/sales/invoices']);
                        }
                    });
            });
    }

    chargeCustomer() {
        if (this.invoice) {
            const dialogRef = this.dialog.open<
                ChargeCustomerDialogComponent,
                {invoice: IInvoice; access_method: 'public' | 'internal'; title: string}
            >(ChargeCustomerDialogComponent, {
                minWidth: '600px',
                maxHeight: '80vh',
                data: {
                    invoice: this.invoice,
                    access_method: 'internal',
                    title: 'Charge Customer'
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((result) => {
                    if (!result.error) {
                        this.notificationServiceZipi.addInfo(`Invoice successfully charged.`);
                        this.initPreview();
                    } else {
                        this.notificationServiceZipi.addError(result.error);
                    }
                });
        }
    }

    redirect(journalId: number) {
        this.router.navigate([`company/journals/${journalId}`]);
    }

    cancelPayment(payment: IPayment) {
        if (!payment || !payment.payment_id) {
            return;
        }

        const dialogRef = this.dialog.open(ConfirmComponent, {
            data: {
                title: 'Remove Payment',
                message: `Payment will be removed.`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((c) => !!c),
                takeUntil(this.unsubscribe)
            )
            .subscribe((confirm) => {
                if (confirm && payment && payment.payment_id) {
                    this.isCancelPaymentButtonDisabled = true;
                    this.paymentsService
                        .cancelPayment(payment.payment_id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((result) => {
                            if (result) {
                                this.isCancelPaymentButtonDisabled = false;
                                this.initPreview();
                            }
                        });
                }
            });
    }

    openApplyCreditsToInvoiceDialog() {
        if (!this.allowApply) {
            return;
        }

        const dialogRef = this.dialog.open(ApplyCreditsToInvoiceDialogComponent, {
            minWidth: 700,
            data: {
                invoice: this.invoice
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((c) => !!c),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.initPreview();
            });
    }

    switchToEditInDialog() {
        this.needSwitchToEditInDialog.emit();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.needSwitchToEditInDialog.complete();
        this.invoiceUpdatedInDialog.complete();
    }
}
