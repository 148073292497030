import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {CompanyGatewayService} from '../../../../../../../../services/api/finance/company-gateway.service';
import {Router} from '@angular/router';
import {IPaymentGateway, IZfTransferPreference} from '@cyberco-nodejs/zipi-typings';
import {environment} from '../../../../../../../../../environments/environment';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormGroup, FormControl} from '@angular/forms';
import {
    RECEIVER_VELOCITY_TYPES,
    SENDER_VELOCITY_TYPES,
    ZIPI_FINANCIAL_BUSINESS_TYPES
} from '../../../../../../../../local-typings';
import {AddRepresentativesDialogComponent} from '../../../dialogs/add-representatives-dialog/add-representatives-dialog.component';
import {ConfirmComponent} from '../../../../../../../../layouts/confirm/confirm.component';
import {ZipiFinancialRetrySubmitGatewayDialogComponent} from '../../../dialogs/zipi-financial-retry-submit-gateway-dialog/zipi-financial-retry-submit-gateway-dialog.component';
import {ZipiFinancialRetrySubmitRepresentativeDialogComponent} from '../../../dialogs/zipi-financial-retry-submit-representative-dialog/zipi-financial-retry-submit-representative-dialog.component';
import {FeatureFlagsService} from '../../../../../../../feature-flags/feature-flags.service';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {ZipiFinFeeScheduleDialogComponent} from '../../../dialogs/zipi-fin-fee-schedule-dialog/zipi-fin-fee-schedule-dialog.component';
import {MuhneeOnboardingDialogComponent} from '../../../dialogs/muhnee-onboarding-dialog/muhnee-onboarding-dialog.component';
import {NotificationsServiceZipi} from '../../../../../../../notifications/notifications.service';

@Component({
    selector: 'app-zipi-financial-info',
    templateUrl: './zipi-financial-info.component.html',
    styleUrls: ['./zipi-financial-info.component.scss']
})
export class ZipiFinancialInfoComponent implements OnInit, OnChanges, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() zipiFinancialGateway: IPaymentGateway | undefined;
    @Input() zipiFinancialGatewayOwnerInfo:
        | ({[key: string]: any} & {representatives: Array<{[key: string]: any}>})
        | undefined;
    @Output() needReload = new EventEmitter();

    redirectToDashboard: string = '';

    businessTypesMap: Array<{title: string; slug: string}> = ZIPI_FINANCIAL_BUSINESS_TYPES;

    senderVelocityTypes = SENDER_VELOCITY_TYPES;
    receiverVelocityTypes = RECEIVER_VELOCITY_TYPES;

    senderTransferPreference: IZfTransferPreference | undefined;
    receivingTransferPreference: IZfTransferPreference | undefined;
    sendingTransferPreferenceForm: UntypedFormGroup;
    receivingTransferPreferenceForm: UntypedFormGroup;

    env = environment;
    isNeedToShowDbaUpload: boolean = false;
    isNeedDocumentsShow: boolean;

    isFeeOverridesLoaded: boolean = false;
    bankFeePayerSaving: boolean = false;
    cardFeePayerSaving: boolean = false;
    defaultMethodSaving: boolean = false;

    feeOverrides: any;
    feePayerForm: FormGroup = this.fb.group({
        default_bank_fee_payer: [null, [Validators.required]],
        default_card_fee_payer: [null, [Validators.required]]
    });
    defaultPayloadCoMethodControl: FormControl = new FormControl(null);

    accountBusinessType: string;

    summaryStatus: string | null = null;

    isVelocityFeatureFlagEnabled: boolean = false;
    isMuhneeHostedIntegrationFeatureFlagEnabled: boolean = false;
    isLinkToMuhneeFeatureFlagEnabled: boolean = false;

    payloadCoSelectedMethod: {
        is_default_payment_method: boolean;
        method_title: string;
        method_id: string;
        is_connected: boolean;
    } | null = null;

    constructor(
        protected dialog: MatDialog,
        private fb: UntypedFormBuilder,
        private companyGatewayService: CompanyGatewayService,
        public router: Router,
        protected featureFlagsService: FeatureFlagsService,
        private notificationServiceZipi: NotificationsServiceZipi
    ) {
        this.isNeedDocumentsShow = false;
        this.accountBusinessType = '';
        this.sendingTransferPreferenceForm = this.fb.group({
            velocity: [null, [Validators.required]],
            is_downgrade_restricted: [false, []],
            force_me_as_trust_fee_payer: [false, []]
        });
        this.receivingTransferPreferenceForm = this.fb.group({
            velocity: [null, [Validators.required]],
            is_downgrade_restricted: [false, []],
            force_me_as_trust_fee_payer: [false, []]
        });
    }

    ngOnInit() {
        this.summaryStatusBuild();
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.isVelocityFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:velocity'
                );
                this.isMuhneeHostedIntegrationFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:muhnee_hosted_integration'
                );
                this.isLinkToMuhneeFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:open_muhnee_by_deep_link'
                );
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('zipiFinancialGateway') && changes.zipiFinancialGateway.currentValue) {
            if (changes.zipiFinancialGateway.currentValue.driver_type === 'muhnee') {
                this.redirectToDashboard = `${this.env.services.shippCore.url}/v1/public/redirect/${changes.zipiFinancialGateway.currentValue.customer_ref}/dashboard`;
            }
        }
        if (
            changes.hasOwnProperty('zipiFinancialGatewayOwnerInfo') &&
            changes.zipiFinancialGatewayOwnerInfo.currentValue
        ) {
            if (changes.zipiFinancialGatewayOwnerInfo.currentValue.sending_velocity) {
                this.senderTransferPreference = changes.zipiFinancialGatewayOwnerInfo.currentValue.sending_velocity;
                this.sendingTransferPreferenceForm.controls.velocity.setValue(
                    changes.zipiFinancialGatewayOwnerInfo.currentValue.sending_velocity.velocity
                );
                this.sendingTransferPreferenceForm.controls.is_downgrade_restricted.setValue(
                    changes.zipiFinancialGatewayOwnerInfo.currentValue.sending_velocity.restrict_auto_downgrade
                );
                this.sendingTransferPreferenceForm.controls.force_me_as_trust_fee_payer.setValue(
                    changes.zipiFinancialGatewayOwnerInfo.currentValue.sending_velocity.force_me_as_trust_fee_payer
                );
            }
            if (changes.zipiFinancialGatewayOwnerInfo.currentValue.receiving_velocity) {
                this.receivingTransferPreference =
                    changes.zipiFinancialGatewayOwnerInfo.currentValue.receiving_velocity;
                this.receivingTransferPreferenceForm.controls.velocity.setValue(
                    changes.zipiFinancialGatewayOwnerInfo.currentValue.receiving_velocity.velocity
                );
                this.receivingTransferPreferenceForm.controls.is_downgrade_restricted.setValue(
                    changes.zipiFinancialGatewayOwnerInfo.currentValue.receiving_velocity.restrict_auto_downgrade
                );
                this.receivingTransferPreferenceForm.controls.force_me_as_trust_fee_payer.setValue(
                    changes.zipiFinancialGatewayOwnerInfo.currentValue.receiving_velocity.force_me_as_trust_fee_payer
                );
            }
            this.businessTypeBuild();
            this.summaryStatusBuild();
            this.loadOverrides();
            this.setupDefaultMethod();
            // this.checkIfNeedSynchronize();
        }
    }

    setupDefaultMethod() {
        if (
            this.zipiFinancialGatewayOwnerInfo &&
            this.zipiFinancialGatewayOwnerInfo.payload_co_payment_methods &&
            this.zipiFinancialGatewayOwnerInfo.payload_co_payment_methods.length > 0
        ) {
            const defaultMethod = this.zipiFinancialGatewayOwnerInfo.payload_co_payment_methods.find(
                (method: any) => method.is_default_payment_method
            );
            if (defaultMethod) {
                this.payloadCoSelectedMethod = defaultMethod;
                this.defaultPayloadCoMethodControl.setValue(defaultMethod.method_id);
            }
        }
    }

    loadOverrides() {
        if (this.zipiFinancialGateway && this.zipiFinancialGateway.payment_gateway_id) {
            this.companyGatewayService
                .getZipiFinancialGatewayFee(this.zipiFinancialGateway?.payment_gateway_id, ['regular'])
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((feeInfo) => {
                    if (feeInfo.hasOwnProperty('regular') && feeInfo.regular.hasOwnProperty('incoming')) {
                        this.feeOverrides = feeInfo.regular.incoming;
                        this.isFeeOverridesLoaded = true;
                        this.setupFeePayer();
                    }
                });
        }
    }

    setupFeePayer() {
        if (this.zipiFinancialGateway) {
            // 'option_4' means that SS pays all outgoing fees instead of Broker. But all incoming fees will always pay Agent
            if (
                this.feeOverrides &&
                this.feeOverrides.transfer_customization_ref &&
                this.feeOverrides.transfer_customization_ref === 'option_4'
            ) {
                this.feePayerForm.controls.default_bank_fee_payer.setValue('money_sender');
                this.feePayerForm.controls.default_card_fee_payer.setValue('money_sender');
                this.feePayerForm.disable();
            } else {
                this.feePayerForm.controls.default_bank_fee_payer.setValue(
                    this.zipiFinancialGateway.default_bank_fee_payer
                );
                this.feePayerForm.controls.default_card_fee_payer.setValue(
                    this.zipiFinancialGateway.default_card_fee_payer
                );
                this.feePayerForm.enable();
            }
            this.feePayerForm.markAsUntouched();
            this.bankFeePayerSaving = false;
            this.cardFeePayerSaving = false;
        }
    }

    saveFeePayer(value: 'money_sender' | 'money_receiver', type: 'bank' | 'card') {
        if (this.zipiFinancialGateway && this.zipiFinancialGateway.payment_gateway_id) {
            const data: any = {};
            if (type === 'bank') {
                data['default_bank_fee_payer'] = value;
                this.bankFeePayerSaving = true;
            } else if (type === 'card') {
                data['default_card_fee_payer'] = value;
                this.cardFeePayerSaving = true;
            }
            this.companyGatewayService
                .saveDefaultFeePayerForGateway(this.zipiFinancialGateway.payment_gateway_id, data)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response && response.success && this.zipiFinancialGateway) {
                        if (type === 'bank') {
                            this.zipiFinancialGateway.default_bank_fee_payer = data['default_bank_fee_payer'];
                        } else if (type === 'card') {
                            this.zipiFinancialGateway.default_card_fee_payer = data['default_card_fee_payer'];
                        }
                        this.setupFeePayer();
                    }
                });
        }
    }

    commissionPayerChanged($event: MatSlideToggleChange, itemGroup: any) {
        if ($event.checked) {
            itemGroup.controls.velocity.setValue('standard');
        } else {
            itemGroup.controls.velocity.setValue('no_fee');
        }
    }

    saveDefaultVelocity(type: 'when_sending' | 'when_receiving') {
        const currentPreferenceData =
            type === 'when_sending'
                ? this.sendingTransferPreferenceForm.getRawValue()
                : this.receivingTransferPreferenceForm.getRawValue();
        if (this.zipiFinancialGateway && this.zipiFinancialGateway.payment_gateway_id) {
            const data = {
                velocity: currentPreferenceData.velocity,
                type: type,
                auto_downgrade: currentPreferenceData.is_downgrade_restricted,
                force_me_as_trust_fee_payer: currentPreferenceData.force_me_as_trust_fee_payer
            };
            this.companyGatewayService
                .saveNewDefaultZipiFinancialGatewayVelocity(this.zipiFinancialGateway.payment_gateway_id, data)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((newPreference) => {
                    if (type === 'when_sending') {
                        this.senderTransferPreference = newPreference;
                        this.sendingTransferPreferenceForm.controls.velocity.setValue(newPreference.velocity);
                        this.sendingTransferPreferenceForm.controls.is_downgrade_restricted.setValue(
                            newPreference.restrict_auto_downgrade
                        );
                    } else {
                        this.receivingTransferPreference = newPreference;
                        this.receivingTransferPreferenceForm.controls.velocity.setValue(newPreference.velocity);
                        this.receivingTransferPreferenceForm.controls.is_downgrade_restricted.setValue(
                            newPreference.restrict_auto_downgrade
                        );
                    }
                });
        }
    }

    checkIfNeedSynchronize() {
        if (
            this.zipiFinancialGateway &&
            this.zipiFinancialGateway.status !== 'active' &&
            this.zipiFinancialGatewayOwnerInfo &&
            this.zipiFinancialGatewayOwnerInfo.status === 'verified'
        ) {
            this.synchronize();
        }
    }

    summaryStatusBuild() {
        if (this.zipiFinancialGateway) {
            let summaryStatus = '';
            if (this.zipiFinancialGateway.status === 'checking') {
                summaryStatus = 'Application Pending Review';
            }
            if (this.zipiFinancialGateway.status === 'warning') {
                summaryStatus = 'Action Required';
            }
            if (this.zipiFinancialGateway.status === 'active') {
                summaryStatus = 'Active';
            }
            if (this.zipiFinancialGateway.driver_type === 'moov' && this.zipiFinancialGatewayOwnerInfo) {
                const customerErrors = !!(
                    this.zipiFinancialGatewayOwnerInfo.errors && this.zipiFinancialGatewayOwnerInfo.errors.length > 0
                );
                const representatives = this.zipiFinancialGatewayOwnerInfo.representatives;
                const representativesErrors =
                    !!representatives && representatives.some((rep) => !!(rep.errors && rep.errors.length > 0));
                if (customerErrors || representativesErrors) {
                    summaryStatus = 'Action Required';
                }
            }
            if (this.zipiFinancialGateway.status === 'inactive') {
                summaryStatus = 'Inactive';
            }

            this.summaryStatus = summaryStatus;
        }
    }

    businessTypeBuild() {
        if (
            this.zipiFinancialGateway &&
            this.zipiFinancialGateway.driver_type === 'moov' &&
            this.zipiFinancialGatewayOwnerInfo
        ) {
            this.accountBusinessType = this.zipiFinancialGatewayOwnerInfo.customer_type
                ? this.zipiFinancialGatewayOwnerInfo.customer_type === 'individual'
                    ? 'Individual'
                    : 'Business'
                : '';
        }
    }

    openRetryDialog() {
        const dialogRef = this.dialog.open(ZipiFinancialRetrySubmitGatewayDialogComponent, {
            maxHeight: '80vh',
            width: '700px',
            data: {
                customerInfo: this.zipiFinancialGatewayOwnerInfo,
                gateway: this.zipiFinancialGateway
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.needReload.emit(true);
                }
            });
    }

    openRetryRepresentativeDialog(representative: {[key: string]: any}) {
        const dialogRef = this.dialog.open(ZipiFinancialRetrySubmitRepresentativeDialogComponent, {
            maxHeight: '80vh',
            width: '700px',
            data: {
                representativeInfo: representative,
                gateway: this.zipiFinancialGateway
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.needReload.emit(true);
                }
            });
    }

    synchronize() {
        if (this.zipiFinancialGateway && this.zipiFinancialGateway.payment_gateway_id) {
            this.companyGatewayService
                .syncZipiFinancialPaymentGateway(this.zipiFinancialGateway.payment_gateway_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response) {
                        this.needReload.emit(true);
                    }
                });
        }
    }

    addRepresentative() {
        const dialogRef = this.dialog.open(AddRepresentativesDialogComponent, {
            maxHeight: '80vh',
            maxWidth: '60vw',
            data: {
                gateway: this.zipiFinancialGateway
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.needReload.emit(true);
                }
            });
    }

    confirmRepresentatives() {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            // minHeight: 320,
            data: {
                title: `Confirm Representatives`,
                message: `To proceed with account verification please confirm that next rules are satisfied:`,
                messages: [
                    '    - at least one Controller was provided;',
                    '    - each Owner with part greater than 25% was provided;'
                ],
                buttonOkMessage: 'Confirm'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {
                if (ok && this.zipiFinancialGateway && this.zipiFinancialGateway.payment_gateway_id) {
                    this.companyGatewayService
                        .confirmRepresentatives(this.zipiFinancialGateway.payment_gateway_id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((response) => {
                            this.needReload.emit(true);
                        });
                }
            });
    }

    deleteRepresentative(representative: any) {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            // minHeight: 320,
            data: {
                title: `Deleting Representative`,
                message: `Representative will be deleted`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {
                if (ok && this.zipiFinancialGateway && this.zipiFinancialGateway.payment_gateway_id) {
                    const settings = {representativeReference: representative.representativeID};
                    this.companyGatewayService
                        .deleteRepresentativeByRef(this.zipiFinancialGateway.payment_gateway_id, settings)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((response) => {
                            this.needReload.emit(true);
                        });
                }
            });
    }

    openFee() {
        const dialogRef = this.dialog.open(ZipiFinFeeScheduleDialogComponent, {
            minWidth: '400px',
            // minHeight: 320,
            data: {
                gateway_id: this.zipiFinancialGateway?.payment_gateway_id
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {});
    }

    onboardingPopupOpen() {
        if (this.zipiFinancialGatewayOwnerInfo) {
            this.zipiFinancialGatewayOwnerInfo['is_onboarding_data_setup'] = true;
        }
        const dialogRef = this.dialog.open(MuhneeOnboardingDialogComponent, {
            minWidth: '400px',
            // minHeight: 320,
            data: {
                gateway: this.zipiFinancialGateway,
                operatingGateways: [],
                gatewayInfo: this.zipiFinancialGatewayOwnerInfo
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.needToReload();
            });
    }

    saveDefaultPayloadCoMethod(methodId: string) {
        if (
            this.zipiFinancialGatewayOwnerInfo &&
            this.zipiFinancialGatewayOwnerInfo.payload_co_payment_methods &&
            this.zipiFinancialGatewayOwnerInfo.payload_co_payment_methods.length > 0
        ) {
            const defaultMethod = this.zipiFinancialGatewayOwnerInfo.payload_co_payment_methods.find(
                (method: any) => method.method_id === methodId
            );
            if (defaultMethod && this.zipiFinancialGateway && this.zipiFinancialGateway.payment_gateway_id) {
                this.defaultMethodSaving = true;
                this.defaultPayloadCoMethodControl.disable();
                this.payloadCoSelectedMethod = defaultMethod;
                this.companyGatewayService
                    .changeDefaultPayloadCoMethod(this.zipiFinancialGateway.payment_gateway_id, defaultMethod.method_id)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe({
                        next: (response) => {
                            if (
                                response &&
                                response.success &&
                                this.zipiFinancialGatewayOwnerInfo &&
                                this.zipiFinancialGatewayOwnerInfo.payload_co_payment_methods
                            ) {
                                this.zipiFinancialGatewayOwnerInfo.payload_co_payment_methods.forEach((method: any) => {
                                    method.is_default_payment_method = method.method_id === defaultMethod.method_id;
                                });
                            }
                            this.defaultMethodSaving = false;
                            this.defaultPayloadCoMethodControl.enable();
                        },
                        error: (error) => {
                            this.defaultMethodSaving = false;
                            this.defaultPayloadCoMethodControl.enable();
                            this.setupDefaultMethod();
                        }
                    });
            }
        }
    }

    needToReload() {
        this.needReload.emit(true);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.needReload.complete();
    }
}
