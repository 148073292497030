<div class="d-flex flex-column" style="max-height: 80vh; overflow-y: initial">
    <!------- Records list ------->
    <mat-form-field>
        <mat-label>{{ data.type | fromCamelCase }}</mat-label>
        <mat-chip-list #chipList>
            <mat-chip *ngFor="let item of list">
                {{ item.label }}
            </mat-chip>
            <input
                placeholder="Add new"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="add($event)"
            />
        </mat-chip-list>
    </mat-form-field>

    <!------- Manage records list ------->
    <div class="footer py-3">
        <button mat-raised-button class="mat-primary mr-3" (click)="save()">Save</button>
        <button mat-raised-button class="mat-default" (click)="dialogRef.close()">Cancel</button>
    </div>
</div>
