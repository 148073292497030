<div class="user-row">
    <div class="user-card" [ngStyle]="{'padding-left': groupFG.controls.child_level!.value * 20 + 'px'}">
        <div class="d-flex align-items-top justify-content-between mx-4">
            <div class="d-flex">
                <span *ngIf="groupFG.controls.type!.value === GROUP.type_SET.focus_area" class="h-font"
                    ><b>
                        <ng-container *ngIf="groupFG.controls.parent_group!.value !== null">
                            {{ groupFG.controls.parent_group!.value.title }} >>
                        </ng-container>
                        {{ groupFG.controls.title!.value }}
                    </b></span
                >
                <span *ngIf="groupFG.controls.type!.value !== GROUP.type_SET.focus_area" class="h-font">
                    {{ groupFG.controls.title!.value }}
                </span>
            </div>

            <div class="d-flex justify-content-end m-0">
                <span style="width: 120px; font-size: 1rem; color: #6c757d; margin-top: 5px">
                    Members: {{ groupFG.controls.number_of_members!.value }}
                </span>

                <div class="d-flex" style="width: 64px; padding-left: 9px; margin-top: 6px">
                    <mat-icon
                        [ngClass]="{
                            'visibility-hidden':
                                !groupFG.controls!.permissions!.controls!.use_in_compensation_as_entity_group!.value
                        }"
                        matTooltip="Track Group Financials Separately"
                        style="color: #9e9e9e; margin: 0 4px"
                        >monetization_on</mat-icon
                    >
                    <mat-icon
                        [ngClass]="{
                            'visibility-hidden':
                                !groupFG.controls!.permissions!.controls!.use_in_compensation_as_group_of_entities!
                                    .value
                        }"
                        matTooltip="User Finance Group"
                        style="color: #9e9e9e; margin: 0 4px"
                        >group</mat-icon
                    >
                </div>

                <button
                    mat-icon-button
                    [rule]="{company_settings__manage_groups: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    class="justify-content-end"
                    (click)="stopPropagation($event)"
                    [matMenuTriggerFor]="menu"
                >
                    <mat-icon style="margin-bottom: 6px">more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button
                        [disabled]="groupFG.controls.id!.value === (currentProfile?.company?.company_group)!.id"
                        mat-menu-item
                        (click)="groupSettings($event, groupFG)"
                    >
                        Settings
                    </button>
                    <button mat-menu-item (click)="sharePermissions($event, groupFG.getRawValue())">Share</button>
                    <button
                        [disabled]="
                            groupFG.controls.id!.value === (currentProfile?.company?.company_group)!.id ||
                            disableDeleting
                        "
                        mat-menu-item
                        (click)="deleteActiveGroup($event)"
                    >
                        Delete
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
</div>
