import {CompensationPlanModel} from '../../../account-info/compensation/models/compensation-plan.model';
import {DealsSummary} from '../../../../models/deals-summary';

export class Intradeal {
    final: boolean = false;
    label: string | null = null;
    maxPaid: number | null = null;
    of: number | null = null;
    paid: number | null = null;
    percent: number | null = null;
    rule: object = {};
    tooltip: string | null = null;
    summaryIncrement: DealsSummary = new DealsSummary();
}

export class FinancialNode {
    label: string = '';
    amount: number = 0;
    compensation_plan: CompensationPlanModel = new CompensationPlanModel();
    type: string = 'income';
    sourceNode: FinancialNode | null = null;
    is_calculated: boolean = false;
    tooltip: string | null = null;
    intradeals: Intradeal[] = [];
    details: FinancialDetails[] = [];
    static get type_SET() {
        return {
            income: 'income',
            expense: 'expense'
        };
    }
    setType(type: string) {
        this.type = type;
        return this;
    }
    setLabel(label: string) {
        this.label = label;
        return this;
    }
    setAmount(amount: number) {
        this.amount = amount;
        return this;
    }
}

export class FinancialDetails {
    label: string = '';
    amount: number = 0;
    direction: string = 'positive';
    percent: number = 0;
    value_type: string = 'flat_fee';

    static get value_type_LABELS(): any {
        return {
            flat_fee: 'Flat Fee',
            flat_fee_sales_net: 'Flat Fee [based on deal split]',
            remaining: 'Remaining',
            percent: 'Percent',
            percent_of_base_commission: '% of Base Commission',
            percent_of_commission: '% of Gross Commission',
            percent_of_adjusted_gross: '% of Adjusted Gross Commission',
            percent_of_net_commission: '% of Net Commission',
            percent_of_deal_net: '% of Deal Net',
            percent_of_deal: '% of Deal',
            percent_of_sales_entity_sales_price: '% of Sales Entity Sales Price',
            percent_of_sales_total: '% of Sales Total',
            percent_of_sales_net: '% of Sales Net',
            percent_of_split: '% of Split',
            percent_of_company_split: '% of Company Split',
            percent_of_sales_entity_sales_split: '% of Sales Total',
            percent_of_sales_price: '% of Sales Price',
            percent_of_primary_entity_split: '% of Primary Agent Split',
            percent_of_entity_split: '% of Agent Split',
            percent_of_sales_price_by_percent_of_deal: '% of Sales Price [based on deal split]',
            percent_of_base_commission_by_percent_of_deal: '% of Base Commission [based on deal split]',
            percent_of_commission_by_percent_of_deal: '% of Gross Commission [based on deal split]',
            percent_of_adjusted_gross_by_percent_of_deal: '% of Adjusted Gross Commission [based on deal split]',
            percent_of_net_commission_by_percent_of_deal: '% of Net Commission [based on deal split]',
            percent_of_deal_net_by_percent_of_deal: '% of Deal Net By [based on deal split]'
        };
    }
}
