import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-marketplace-deactivate-confirmation-dialog',
    styles: [
        `
            .mat-dialog-actions {
                justify-content: space-between;
                display: flex;
            }

            #errors {
                color: #af1515;
                padding-left: 15px;
                line-height: 2em;
                font-size: 14px;
            }
        `
    ],
    template: `
        <div mat-dialog-title>
            <strong>Deactivate Company App</strong>
        </div>
        <div mat-dialog-content>
            <p>Deactivating this connection will disconnect App for all users.</p>
            <p>
                <strong>Note:</strong> No data will be deleted, but all configurations will be lost and nothing further
                will be synced.
            </p>
            <hr />
        </div>

        <div mat-dialog-actions>
            <button mat-button class="mat-primary" (click)="dialogRef.close(true)">Deactivate</button>
            <button mat-button (click)="dialogRef.close(false)">Cancel</button>
        </div>
    `
})
export class MarketplaceDeactivateConfirmationDialogComponent {
    constructor(public dialogRef: MatDialogRef<MarketplaceDeactivateConfirmationDialogComponent>) {}
}
