import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';

import {IndexComponent} from './pages/index/index.component';
import {EditDealComponent} from './pages/edit-deal.component';
import {ImageCropperModule} from 'ngx-img-cropper';
import {FilterPipe} from './pages/index/filter.pipe';
import {CommonListOfFieldsDialogComponent} from './common/common-list-of-fields-dialog/common-list-of-fields-dialog.component';
import {ProfileModule} from '../../../profile/profile.module';
import {BackendFilterComponent} from './pages/index/deal-board/filters/backend-filter/backend.filter.component';
import {FrontendFilterComponent} from './pages/index/deal-board/filters/frontend-filter/frontend.filter.component';
import {ListComponent} from './pages/index/list/list.component';
import {AddDealsBoardComponent} from './pages/index/add-deals-board/add-deals-board.component';
import {SharedModule} from '../../../shared/shared.module';
import {RbacModule} from '../../../rbac/rbac.module';
import {SetDualComponent} from './common/dialog/set-dual.component';
import {DealImageComponent} from './common/deal-image/deal-image.component';
import {CustomCropperComponent} from './common/deal-image/custom-cropper.component';
import {CropperControlsComponent} from './common/deal-image/cropper-controls.component';
import {AuditLogContainerComponent} from './common/audit-log/audit-log-container-component/audit-log-container.component';
import {DealAdditionalFieldsComponent} from './common/additional-fields.component';
import {OwnerSelectComponent} from './common/owner-select.dialog/owner-select.component';
import {OwnerSelectDialogComponent} from './common/owner-select.dialog/owner-select.dialog.component';
import {
    DealBoardFiltersComponent,
    DealBoardViewFiltersComponent,
    DealBoardEditFiltersComponent
} from './pages/index/deal-board/deal-board-filters.component';
import {DealBoardViewComponent} from './pages/index/deal-board/deal-board-view.component';
import {DealBoardEditComponent} from './pages/index/deal-board/deal-board-edit.component';
import {DealBoardSettingsV2Component} from './pages/index/deal-board/deal-board-settings-v2.component';
import {DealBoardSettingsGvComponent} from './pages/index/deal-board/deal-board-settings-gv.component';
import {UiModsModule} from '../../../ui-mods/ui-mods.module';
import {DealTemplatesSource} from './common/dealTemplates.service';
import {EditDealPropertyInformationComponent} from './common/property-information.component';
import {EditDealDualDealComponent} from './common/dual-deal.component';
import {EditDealTransactionComponent} from './common/transaction.component';
import {IncomeCommissionComponent} from './common/commission.component';
import {EditDealSalesEntitiesComponent, SaleEntityItemComponent} from './common/sales-entities.component';
import {CreateDealComponent} from './pages/create-deal.component';
import {DealExpensesComponent} from './common/deal-expenses/deal-expenses.component';
import {ParticipantCalculationComponent} from './common/calculations/participant-calculation.component';
import {ParticipantPreviewComponent} from './common/calculations/participant-preview.component';
import {DealDisbursementComponent} from './common/disbursement.component';
import {IntraedealExplainDialogComponent} from './common/intraedeal-explain.dialog';
import {DealApproveApiService} from './deal-approve.api.service';
import {DisbursementTextDialogComponent} from './common/disbursement-text.dialog';
import {CompanyPreviewComponent} from './common/calculations/company-preview.component';
import {DealProcessingService} from './deal-processing.service';
import {DealPayoutsProcessingService} from './deal-payouts-processing.service';
import {DealFinancialApiService} from './deal-financial.api.service';
import {dealReducer} from './store/deal.reducer';
import {CompensationModule} from '../../../account-info/compensation/compensation.module';
import {TransferPreviewComponent} from './common/calculations/transfer-preview.component';
import {CompanyExpensesComponent} from './common/company-expenses/company-expenses.component';
import {DealPreviewComponent} from './common/calculations/deal-preview.component';
import {PayoutsComponent} from './common/payouts/payouts.component';
import {EscrowPayoutsTabComponent} from './common/payouts/escrow-payouts-tab.component';
import {RemainingPayoutsTabComponent} from './common/payouts/remaining-payouts-tab.component';
import {SummaryPayoutsTabComponent} from './common/payouts/summary-payouts-tab.component';
import {GPlacesPickerModule} from '../../../gplaces-picker/gplaces-picker.module';
import {CardPayoutsComponent} from './common/payouts/card-payouts.component';
import {ProcessPayoutDialogComponent} from './common/payouts/process-payout-dialog/process-payout-dialog.component';
import {ViewDealComponent} from './pages/view/view-deal.component';
import {ViewPartiesDealComponent} from './pages/view/parties/parties-deal.component';
import {ViewTransactionDealComponent} from './pages/view/transaction/transaction-deal.component';
import {ViewDocumentsDealComponent} from './pages/view/documents/documents-deal.component';
import {ViewDisbursementsDealComponent} from './pages/view/disbursements/disbursements-deal.component';
import {DealDepositPageComponent} from './common/deal-deposit/deal-deposit-page/deal-deposit-page.component';
import {DepositRequestListComponent} from './common/deal-deposit/deposit-request/deposit-request-list/deposit-request-list.component';
import {DepositPaymentListComponent} from './common/deal-deposit/deposit-payment/deposit-payment-list/deposit-payment-list.component';
import {DepositReleaseListComponent} from './common/deal-deposit/deposit-release/deposit-release-list/deposit-release-list.component';
import {CreateRequestDialogComponent} from './common/deal-deposit/deposit-request/create-request-dialog/create-request-dialog.component';
import {CreatePaymentDialogComponent} from './common/deal-deposit/deposit-payment/create-payment-dialog/create-payment-dialog.component';
import {CreateReleaseDialogComponent} from './common/deal-deposit/deposit-release/create-release-dialog/create-release-dialog.component';
import {ResendRequestDialogComponent} from './common/deal-deposit/deposit-request/resend-request-dialog/resend-request-dialog.component';
import {AddSalesEntityComponent} from './common/add-sales-entity/add-sales-entity.component';
import {UnlockOverrideComponent} from './common/unlock-override.component';
import {CustomFieldCreationDialogComponent} from './common/custom-field-creation-dialog/custom-field-creation-dialog.component';
import {DepositRequestPublicPageComponent} from './common/deal-deposit/deposit-request-public/deposit-request-public-page/deposit-request-public-page.component';
import {DepositEnterToPortalComponent} from './common/deal-deposit/deposit-request-public/deposit-enter-to-portal/deposit-enter-to-portal.component';
import {DeleteDealsWarningComponent} from './pages/index/delete-deals-warning/delete-deals-warning.component';
import {SalesPriceAdjustmentComponent} from './common/sales-price-adjustment/sales-price-adjustment.component';
import {DealBoardComponent} from './pages/index/deal-board/deal-board.component';
import {AffectedDealsDialogComponent} from './common/affected-deals-dialog/affected-deals-dialog.component';
import {AffectedByComponent} from './common/affected-by/affected-by.component';
import {EditTransferComponent} from './common/deal-transfers-collection/edit-transfer/edit-transfer.component';
import {DealTransfersCollectionComponent} from './common/deal-transfers-collection/deal-transfers-collection.component';
import {ViewTransferComponent} from './common/deal-transfers-collection/view-transfer/view-transfer.component';
import {ExpandedDealListItemComponent} from './pages/index/list/expanded-deal-list-item/expanded-deal-list-item.component';
import {NarrowedDealListItemComponent} from './pages/index/list/narrowed-deal-list-item/narrowed-deal-list-item.component';
import {ManageSalesEntitiesDialogComponent} from './common/manage-sales-entities-dialog/manage-sales-entities-dialog.component';
import {YodataReportDialogComponent} from './common/yodata-report-dialog/yodata-report-dialog.component';
import {CalculationAdditionalIncomesComponent} from './common/deal-transfers-collection/calculation-additional-incomes.component';
import {CalculationAdditionalExpensesComponent} from './common/deal-transfers-collection/calculation-additional-expenses.component';
import {CompensationDeductionsComponent} from './common/deal-transfers-collection/compensation-deductions.component';
import {ManageSalesEntityBlockComponent} from './common/manage-sales-entity-block/manage-sales-entity-block.component';
import {EmailDisbursementPdfDialogComponent} from './common/email-disbursement-pdf-dialog/email-disbursement-pdf-dialog.component';
import {DealSelfTestingComponent} from './common/deal-self-testing/deal-self-testing.component';
import {CommissionInstructionsComponent} from './common/commission-instructions/commission-instructions.component';
import {CommissionInstructionComponent} from './common/commission-instructions/commission-instruction/commission-instruction.component';
import {DealEventsService} from './common/deal-events/deal-events.service';
import {RouterModule} from '@angular/router';
import {CommonDealsModule} from '../../modules/common-deals-module/common-deals.module';

@NgModule({
    declarations: [
        UnlockOverrideComponent,
        PayoutsComponent,
        EscrowPayoutsTabComponent,
        RemainingPayoutsTabComponent,
        SummaryPayoutsTabComponent,
        CardPayoutsComponent,
        ProcessPayoutDialogComponent,
        IndexComponent,
        AddDealsBoardComponent,
        EditDealComponent,
        ViewDealComponent,
        ViewPartiesDealComponent,
        ViewTransactionDealComponent,
        ViewDocumentsDealComponent,
        ViewDisbursementsDealComponent,
        DealImageComponent,
        CustomCropperComponent,
        CropperControlsComponent,
        DealBoardEditComponent,
        DealBoardFiltersComponent,
        DealBoardViewFiltersComponent,
        DealBoardEditFiltersComponent,
        DealBoardSettingsV2Component,
        DealBoardSettingsGvComponent,
        DealBoardViewComponent,
        DealBoardComponent,
        FilterPipe,
        CommonListOfFieldsDialogComponent,
        BackendFilterComponent,
        FrontendFilterComponent,
        ListComponent,
        ExpandedDealListItemComponent,
        NarrowedDealListItemComponent,
        AuditLogContainerComponent,
        SetDualComponent,
        DealAdditionalFieldsComponent,
        OwnerSelectComponent,
        OwnerSelectDialogComponent,
        EditDealPropertyInformationComponent,
        EditDealDualDealComponent,
        EditDealTransactionComponent,
        IncomeCommissionComponent,
        EditDealSalesEntitiesComponent,
        CreateDealComponent,
        SaleEntityItemComponent,
        DealExpensesComponent,
        CompanyExpensesComponent,
        ParticipantCalculationComponent,
        TransferPreviewComponent,
        ParticipantPreviewComponent,
        CompanyPreviewComponent,
        DealPreviewComponent,
        DealDisbursementComponent,
        AddSalesEntityComponent,
        IntraedealExplainDialogComponent,
        DisbursementTextDialogComponent,
        DealDepositPageComponent,
        DepositRequestPublicPageComponent,
        DepositEnterToPortalComponent,
        DepositRequestListComponent,
        DepositPaymentListComponent,
        DepositReleaseListComponent,
        CreateRequestDialogComponent,
        CreatePaymentDialogComponent,
        CreateReleaseDialogComponent,
        ResendRequestDialogComponent,
        DeleteDealsWarningComponent,
        CustomFieldCreationDialogComponent,
        SalesPriceAdjustmentComponent,
        AffectedDealsDialogComponent,
        AffectedByComponent,
        ManageSalesEntitiesDialogComponent,
        YodataReportDialogComponent,
        EditTransferComponent,
        DealTransfersCollectionComponent,
        ViewTransferComponent,
        CalculationAdditionalIncomesComponent,
        CalculationAdditionalExpensesComponent,
        CompensationDeductionsComponent,
        ManageSalesEntityBlockComponent,
        EmailDisbursementPdfDialogComponent,
        DealSelfTestingComponent,
        CommissionInstructionsComponent,
        CommissionInstructionComponent
    ],
    imports: [
        CommonModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        ProfileModule,
        SharedModule,
        UiModsModule,
        CompensationModule,
        RbacModule,
        ImageCropperModule,
        RouterModule,
        // routing,
        StoreModule.forFeature('deals', dealReducer),
        GPlacesPickerModule,
        CommonDealsModule
    ],
    exports: [BrowserModule],
    providers: [
        DealTemplatesSource,
        DealEventsService,
        DealApproveApiService,
        DealProcessingService,
        DealPayoutsProcessingService,
        DealFinancialApiService
    ],
    bootstrap: [IndexComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DealModule {}
