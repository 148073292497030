import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    IBill,
    ICreditNote,
    IDealDepositRelease,
    IDealDepositRequest,
    IExpense,
    IInvoice,
    IPaymentMade,
    IPaymentReceived,
    IRecurringInvoice,
    IVendorCredit
} from '@cyberco-nodejs/zipi-typings';

interface IDialogData {
    title: string;
    message: string;
    recurring_invoices: IRecurringInvoice[];
    invoices: IInvoice[];
    invoice_number: string;
    bills: IBill[];
    creditNotes: ICreditNote[];
    vendorCredits: IVendorCredit[];
    paymentMades: IPaymentMade[];
    paymentReceiveds: IPaymentReceived[];
    depositReleases: IDealDepositRelease[];
    depositRequests: IDealDepositRequest[];
    expenses: IExpense[];
}

@Component({
    selector: 'app-alert-delete-contact-dialog',
    styles: [
        `
            .item-box {
                margin: 0 0 6px 0;
            }
            .items-wrap {
                min-height: 40px;
                line-height: 18px;
            }
            .general-message {
                margin-bottom: 10px;
            }
        `
    ],
    template: `
        <h1 md-dialog-title>{{ data.title ? data.title : 'Delete Contact' }}</h1>
        <div md-dialog-content class="items-wrap">
            <div>The contact can't be deleted.</div>
            <div class="item-box general-message" [innerHtml]="data.message"></div>

            <div class="item-box" *ngFor="let item of data.recurring_invoices; index as idx">
                <div *ngIf="idx < 5">{{ item.title }}</div>
            </div>
            <div class="item-box" *ngIf="data.recurring_invoices && data.recurring_invoices.length > 5">...</div>

            <div class="item-box" *ngFor="let item of data.invoices; index as idx">
                <div *ngIf="idx < 5">{{ item.invoice_number }}</div>
            </div>
            <div class="item-box" *ngIf="data.invoices && data.invoices.length > 5">...</div>

            <div class="item-box" *ngFor="let item of data.bills; index as idx">
                <div *ngIf="idx < 5">{{ item.bill_number }}</div>
            </div>
            <div class="item-box" *ngIf="data.bills && data.bills.length > 5">...</div>

            <div class="item-box" *ngFor="let item of data.creditNotes; index as idx">
                <div *ngIf="idx < 5">{{ item.credit_note_number }}</div>
            </div>
            <div class="item-box" *ngIf="data.creditNotes && data.creditNotes.length > 5">...</div>

            <div class="item-box" *ngFor="let item of data.vendorCredits; index as idx">
                <div *ngIf="idx < 5">{{ item.vendor_credit_number }}</div>
            </div>
            <div class="item-box" *ngIf="data.vendorCredits && data.vendorCredits.length > 5">...</div>

            <div class="item-box" *ngFor="let item of data.paymentMades; index as idx">
                <div *ngIf="idx < 5">{{ item.payment_made_number }}</div>
            </div>
            <div class="item-box" *ngIf="data.paymentMades && data.paymentMades.length > 5">...</div>

            <div class="item-box" *ngFor="let item of data.paymentReceiveds; index as idx">
                <div *ngIf="idx < 5">{{ item.payment_received_number }}</div>
            </div>
            <div class="item-box" *ngIf="data.paymentReceiveds && data.paymentReceiveds.length > 5">...</div>

            <div class="item-box" *ngFor="let item of data.depositReleases; index as idx">
                <div *ngIf="idx < 5">Total Amount {{ item.release_amount | currency }}</div>
            </div>
            <div class="item-box" *ngIf="data.depositReleases && data.depositReleases.length > 5">...</div>

            <div class="item-box" *ngFor="let item of data.depositRequests; index as idx">
                <div *ngIf="idx < 5">Total Amount {{ item.request_amount | currency }}</div>
            </div>
            <div class="item-box" *ngIf="data.depositRequests && data.depositRequests.length > 5">...</div>

            <div class="item-box" *ngFor="let item of data.expenses; index as idx">
                <div *ngIf="idx < 5">Expense {{ item.expense_date | dateFromNumber | date: 'mediumDate' }}</div>
            </div>
            <div class="item-box" *ngIf="data.expenses && data.expenses.length > 5">...</div>
        </div>
        <div md-dialog-actions>
            <div style="width: 100%;">
                <button mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">Close</button>
            </div>
        </div>
    `
})
export class AlertDeleteContactDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<AlertDeleteContactDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData
    ) {}

    ngOnInit() {}
}
