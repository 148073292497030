import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-timeout-warning-dialog',
    templateUrl: 'timeout-warning-dialog.component.html',
    styles: [
        `
            .mat-dialog-content {
                overflow-wrap: break-word;
                white-space: pre-wrap;
            }
        `
    ]
})
export class TimeoutWarningDialogComponent implements OnInit {
    counter: number = 5;
    isCounterStopped: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<TimeoutWarningDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {}

    closeDialog() {
        this.dialogRef.close(true);
    }
}
