import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GenericFormGroup} from '../../../../../entites/generic.entity';
import {RuleModel} from '../../models/rule.model';

@Component({
    selector: 'app-company-overhead',
    template: `
        <div style="margin-top: 16px">
            <div
                class="card-content"
                style="display:flex; width: 50%; justify-content: space-between; flex-direction: row"
            >
                <mat-form-field class="flex-2">
                    <input
                        matInput
                        type="number"
                        pattern="^[1-9]\\d*$"
                        [formControl]="ruleFG!.controls.overhead!.controls.amount!"
                        placeholder="Amount"
                    />
                </mat-form-field>
                <span style="align-self: center">per</span>
                <mat-form-field class="half-width spaced">
                    <mat-select
                        class="ref-fee-type"
                        [formControl]="ruleFG!.controls.overhead!.controls.period!"
                        placeholder="Period"
                    >
                        <mat-option value="year">Year</mat-option>
                        <mat-option value="month">Month</mat-option>
                        <!--<mat-option value="deal">Deal</mat-option>-->
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    `
})
export class ProvidesOverheadComponent implements OnInit, OnChanges {
    @Input() ruleFG: GenericFormGroup<RuleModel> = new GenericFormGroup(new RuleModel());
    @Input() disabled: boolean = false;

    object_keys(obj: object) {
        return Object.keys(obj);
    }

    constructor() {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.disabled && changes.disabled.currentValue) {
            this.ruleFG!.controls.overhead!.controls.amount?.disable({emitEvent: false});
            this.ruleFG!.controls.overhead!.controls.period?.disable({emitEvent: false});
        } else if (changes.disabled && !changes.disabled.currentValue) {
            this.ruleFG!.controls.overhead!.controls.amount?.enable({emitEvent: false});
            this.ruleFG!.controls.overhead!.controls.period?.enable({emitEvent: false});
        }
    }
}
