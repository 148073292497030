import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {GenericFormArray, GenericFormGroup} from '../../../../../entites/generic.entity';
import {EntityCompensationProfilesListItem} from '../../models/entity-compensation-profiles-list-item';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Group} from '../../../../../models/group';
import {CompensationServiceApi} from '../../compensation.service';
import {CompensationProfileModel} from '../../models/compensation-profile.model';

@Component({
    selector: 'app-tab-3-entites',
    styles: [
        `
            .coming-soon-text {
                display: flex;
                justify-content: center;
                margin-top: 50px;
                font-size: 24px;
                font-style: italic;
                color: gray;
            }
        `
    ],
    template: `
        <div class="d-flex">
            <mat-form-field class="w-25">
                <input matInput placeholder="Search" (keyup.enter)="handleSearchValue()" [formControl]="searchString" />
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>

        <app-infinite-scroll (scrolled)="doShowMore()" *ngIf="showMore !== undefined">
            <div *ngFor="let compensationEntity of compensationEntitiesFA.controls; index as i" style="margin: 5px 0;">
                <app-company-compensation-entity
                    [compensationEntity]="compensationEntity"
                ></app-company-compensation-entity>
            </div>
        </app-infinite-scroll>
    `
})
export class Tab3EntitiesComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() public sortBy: UntypedFormControl = new UntypedFormControl('order_desc');
    @Input() public set includeDrafts(includeDrafts: UntypedFormControl) {
        this.scrollData.includeDraft = includeDrafts.value;

        includeDrafts.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((includeDraft) => {
            this.scrollData.includeDraft = includeDraft;
            this.scrollData.offset = 0;
            this.scrollData.search = this.searchString.value ? String(this.searchString.value).toLowerCase() : null;
            this.compensationEntitiesFA.patchValue([]);
            this.search();
        });
    }

    group$: Subject<Group[]> = new Subject();

    public compensationEntitiesFA: GenericFormArray<EntityCompensationProfilesListItem> =
        new GenericFormArray<EntityCompensationProfilesListItem>([]);
    public searchString: UntypedFormControl = new UntypedFormControl('');
    public MIN_SYMBOLS_TO_START_SEARCH = 2;
    showMore: boolean | undefined;
    private scrollData: {
        limit: number;
        offset: number;
        search: string | null;
        includeDraft: boolean;
    } = {
        limit: 15,
        offset: 0,
        search: null,
        includeDraft: true
    };

    constructor(private compensationServiceApi: CompensationServiceApi) {}

    async ngOnInit() {
        this.search();

        this.searchString.valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe)).subscribe((data) => {
            this.handleSearchValue();
        });
    }

    public handleSearchValue() {
        if (
            !this.searchString.value ||
            (this.searchString.value && this.searchString.value.length >= this.MIN_SYMBOLS_TO_START_SEARCH)
        ) {
            this.scrollData.offset = 0;
            this.scrollData.search = this.searchString.value ? String(this.searchString.value).toLowerCase() : null;
            this.compensationEntitiesFA.patchValue([]);
            this.search();
        }
    }

    search() {
        this.compensationServiceApi
            .getEntityCompensationProfilesListItem(this.scrollData)
            .toPromise()
            .then((entityCompensationProfilesListItems) => {
                entityCompensationProfilesListItems.result.forEach((entity: CompensationProfileModel) => {
                    this.compensationEntitiesFA.push(new GenericFormGroup(entity));
                });
                this.showMore = entityCompensationProfilesListItems.result.length >= this.scrollData.limit;
            });
    }

    async doShowMore() {
        if (this.showMore) {
            this.scrollData.offset += this.scrollData.limit;
            this.search();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.group$.complete();
    }
}
