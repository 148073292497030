import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {GenericFormArray} from '../../../../entites/generic.entity';
import {CompensationProfileModel} from '../models/compensation-profile.model';
import {UntypedFormControl} from '@angular/forms';
import {merge, Subject} from 'rxjs';
import {GroupCompensationProfilesListItem} from '../models/group-compensation-profiles-list-item';
import {startWith, takeUntil} from 'rxjs/operators';
import {GroupsApi} from '../../groups/groups.service';

@Component({
    selector: 'app-company-compensation-groups-list',
    template: `
        <div
            *ngFor="let compensationGroup of compensationGroupsList.controls; index as ci; last as last; first as first"
            style="margin-bottom: 16px"
        >
            <app-company-compensation-group
                [compensationGroup]="compensationGroup"
                [show_down]="!last && canAdjustOrder"
                [show_up]="!first && canAdjustOrder"
                [order]="compensationGroupsList.controls.length - ci"
                (up)="doMoveUp(ci)"
                (down)="doMoveDown(ci)"
            ></app-company-compensation-group>
        </div>
    `
})
export class CompensationGroupsListComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    public canAdjustOrder: boolean = false;
    @Input() public includeDrafts: UntypedFormControl = new UntypedFormControl(false);
    @Input() public sortBy: UntypedFormControl = new UntypedFormControl('order_desc');

    @Input() public compensationGroupsList: GenericFormArray<GroupCompensationProfilesListItem> =
        new GenericFormArray<GroupCompensationProfilesListItem>([]);

    @Output() saved = new EventEmitter<CompensationProfileModel>();

    doMoveUp(index: number) {
        const order = this.compensationGroupsList.controls[index].controls.company_group!.controls.sort_order!.value;
        const order1 =
            this.compensationGroupsList.controls[index - 1].controls.company_group!.controls.sort_order!.value;

        const element = this.compensationGroupsList.controls.splice(index, 1).pop();
        this.compensationGroupsList.controls.splice(index - 1, 0, element!);

        this.compensationGroupsList.controls[index].controls.company_group!.controls.sort_order!.patchValue(order);
        this.compensationGroupsList.controls[index - 1].controls.company_group!.controls.sort_order!.patchValue(order1);

        return Promise.all([
            this.groupsService.putGroupOrder(
                this.compensationGroupsList.controls[index].controls.company_group!.controls.id!.value,
                order
            ),
            this.groupsService.putGroupOrder(
                this.compensationGroupsList.controls[index - 1].controls.company_group!.controls.id!.value,
                order1
            )
        ]).then(() => {
            this.saved.emit();
        });
    }

    doMoveDown(index: number) {
        const order = this.compensationGroupsList.controls[index].controls.company_group!.controls.sort_order!.value;
        const order1 =
            this.compensationGroupsList.controls[index + 1].controls.company_group!.controls.sort_order!.value;

        const element = this.compensationGroupsList.controls.splice(index, 1).pop();
        this.compensationGroupsList.controls.splice(index + 1, 0, element!);

        this.compensationGroupsList.controls[index].controls.company_group!.controls.sort_order!.patchValue(order);
        this.compensationGroupsList.controls[index + 1].controls.company_group!.controls.sort_order!.patchValue(order1);

        return Promise.all([
            this.groupsService.putGroupOrder(
                this.compensationGroupsList.controls[index].controls.company_group!.controls.id!.value,
                order
            ),
            this.groupsService.putGroupOrder(
                this.compensationGroupsList.controls[index + 1].controls.company_group!.controls.id!.value,
                order1
            )
        ]).then(() => {
            this.saved.emit();
        });
    }

    constructor(protected groupsService: GroupsApi) {}

    ngOnInit() {
        merge(this.includeDrafts.valueChanges, this.sortBy.valueChanges)
            .pipe(startWith(true), takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.canAdjustOrder = this.sortBy.value === 'order_desc';
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.saved.complete();
    }
}
