import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {firstValueFrom, Observable, Subject} from 'rxjs';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyGatewayService} from '../../../../../../../../services/api/finance/company-gateway.service';
import {NotificationsService} from 'angular2-notifications';
import {MatDialog} from '@angular/material/dialog';
import {CompanyPaymentMethodsService} from '../../../../../../../../services/api/finance/company-payment-methods.service';
import {SessionService} from '../../../../../../../../services/session.service';
import {GatewayTypes, IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {
    US_STATE_MAPPING_LIST,
    ZIPI_FINANCIAL_BUSINESS_INDUSTRIES,
    ZIPI_FINANCIAL_BUSINESS_TYPES
} from '../../../../../../../../local-typings';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {FormGroupArrayWithGroupAsControl, FormGroupWithGroupAsControl} from '../../../../../../../../typings/common';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
    selector: 'app-zipi-financial-gateway-setup',
    templateUrl: './zipi-financial-gateway-setup.component.html',
    styleUrls: ['./zipi-financial-gateway-setup.component.scss']
})
export class ZipiFinancialGatewaySetupComponent implements OnInit, OnChanges, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() existingOperatingGateways: IPaymentGateway[] | undefined;
    @Input() driverType: 'plaid' | 'moov' | 'muhnee' | undefined;
    @Input() gatewayType: GatewayTypes | undefined;
    @Output() reloadAndShowFinalPopup = new EventEmitter();

    gatewayForm: UntypedFormGroup | undefined;
    businessInfoForm: UntypedFormGroup | undefined;
    individualInfoForm: UntypedFormGroup | undefined;
    commonInfoForm: UntypedFormGroup | undefined;
    representativesArray: FormGroupArrayWithGroupAsControl | undefined;

    businessTypesMap: Array<{title: string; slug: string}> = ZIPI_FINANCIAL_BUSINESS_TYPES;
    stateProvinceRegionList = US_STATE_MAPPING_LIST;

    industries: Array<{title: string; hash: string}> = ZIPI_FINANCIAL_BUSINESS_INDUSTRIES;
    industryTitle: UntypedFormControl | undefined;
    filteredIndustries: Observable<{title: string; hash: string}[]> | undefined;

    businessTypeTitle: string = '';

    agree: boolean;

    gatewayId: number | null = null;

    paymentMethodSettings:
        | {
              public_token: string;
              account_id: string;
              title: string;
              related_ledger_account_id: number;
          }
        | undefined;

    lockButton: boolean;

    isAbleToSetupTrust: boolean;
    startDate = new Date(1990, 0, 1);

    step: 'step1' | 'step2';

    beneficialSetup: boolean;
    certify: boolean;

    constructor(
        public router: Router,
        private activateRoute: ActivatedRoute,
        private companyGatewayService: CompanyGatewayService,
        private companyPaymentMethodsService: CompanyPaymentMethodsService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        protected dialog: MatDialog,
        public sessionService: SessionService
    ) {
        this.industryTitle = this.fb.control('Real Estate Agents, Rentals - 6513');
        this.representativesArray = this.fb.array([]) as FormGroupArrayWithGroupAsControl;
        this.agree = false;
        this.lockButton = false;
        this.isAbleToSetupTrust = false;
        this.step = 'step1';
        this.beneficialSetup = true;
        this.certify = false;
    }

    ngOnInit() {
        this.initForm(null);
        this.filteredIndustries = this.industryTitle?.valueChanges.pipe(
            startWith(''),
            map((val) => this._filterIndustries(val)),
            takeUntil(this.unsubscribe)
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('existingOperatingGateways') && changes.existingOperatingGateways.currentValue) {
            this.checkIfTrustAvailable();
        }
    }

    initForm(gateway: IPaymentGateway | null) {
        if (gateway) {
            this.gatewayForm = this.fb.group({
                gateway_type: [gateway.type, [Validators.required]],
                title: [gateway.title, [Validators.required]],
                settings: [gateway.settings, []],
                driver_type: [null, []]
            });

            if (gateway.payment_gateway_id) {
                this.gatewayId = gateway.payment_gateway_id;
            }
        } else {
            this.gatewayForm = this.fb.group({
                gateway_type: ['zipi_financial_business', [Validators.required]],
                title: ['Money Transfer', [Validators.required]],
                settings: [null, []],
                driver_type: [this.driverType, []],
                customer_type: [null, [Validators.required]]
            });
            this.businessInfoForm = this.fb.group({
                business_name: [null, [Validators.required]],
                dba: [null, []],
                business_type: ['individual', [Validators.required]],
                ein: ['', [Validators.required]],
                web: [null, [Validators.required]],
                description: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(100)]],
                industry_hash: ['531110_6531_6513', [Validators.required]],
                is_has_website: [null, [Validators.required]]
            });
            this.individualInfoForm = this.fb.group({
                first_name: [null, [Validators.required]],
                last_name: [null, [Validators.required]],
                middle_name: [null, []],
                suffix: [null, []],
                date_of_birth: [null, [Validators.required]],
                ssn: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
                itin: [null, []],
                email: [
                    null,
                    [
                        Validators.required,
                        Validators.pattern(/^([a-zA-Z0-9_\-\.+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)
                    ]
                ]
            });
            this.commonInfoForm = this.fb.group({
                phone_country_code: ['1', [Validators.required]],
                phone_number: ['', [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
                address_line_1: [null, [Validators.required, Validators.maxLength(32)]],
                address_line_2: [null, [Validators.maxLength(32)]],
                city: [null, [Validators.required]],
                state: [null, [Validators.required]],
                postalCode: [null, [Validators.required]],
                country: ['US', [Validators.required]]
            });

            // USE THIS TO PRE-FILL INDIVIDUAL FORM
            // this.individualInfoForm = this.fb.group({
            //     first_name: ['asd', [Validators.required]],
            //     last_name: ['aasdsd', [Validators.required]],
            //     middle_name: [null, []],
            //     suffix: [null, []],
            //     date_of_birth: [19900101, [Validators.required]],
            //     ssn: ['1234', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
            //     itin: [null, []],
            //     email: ['aleksey.koshel+spanner@zipi.app', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)]],
            // });
            // this.commonInfoForm = this.fb.group({
            //     phone_country_code: ['1', [Validators.required]],
            //     phone_number: ['123-123-1234', [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
            //     address_line_1: ['asdasd', [Validators.required, Validators.maxLength(32)]],
            //     address_line_2: [null, [Validators.maxLength(32)]],
            //     city: ['asdsad', [Validators.required]],
            //     state: ['CA', [Validators.required]],
            //     postalCode: ['12345', [Validators.required]],
            //     country: ['US', [Validators.required]],
            // });

            this.commonInfoForm.controls.postalCode = this._addZipMaskListener(this.commonInfoForm.controls.postalCode);
            this.commonInfoForm.controls.phone_number = this._addPhoneMaskListener(
                this.commonInfoForm.controls.phone_number
            );
            this.individualInfoForm.controls.ssn = this._addSsnMaskListener(this.individualInfoForm.controls.ssn);
            this.businessInfoForm.controls.ein = this._addEnnMaskListener(this.businessInfoForm.controls.ein);
            this.businessInfoForm.controls.is_has_website = this._addIsHasWebsiteListener(
                this.businessInfoForm.controls.is_has_website
            );

            this.initValuChangeSubscription();
        }

        this.gatewayForm.controls.customer_type.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((type) => {
            if (type === 'individual') {
                this.businessInfoForm?.patchValue({
                    business_name: null,
                    dba: null,
                    ein: '',
                    web: null,
                    description: null,
                    industry_hash: '531110_6531_6513',
                    is_has_website: null
                });
            } else {
                this.individualInfoForm?.patchValue({
                    first_name: null,
                    last_name: null,
                    middle_name: null,
                    suffix: null,
                    date_of_birth: null,
                    ssn: '',
                    itin: null,
                    email: null
                });
            }
        });

        this.gatewayForm.controls.customer_type.patchValue('individual');
        this.checkIfTrustAvailable();
    }

    addRepresentative() {
        const newBeneficialGroup = this.fb.group({
            first_name: [null, [Validators.required]],
            last_name: [null, [Validators.required]],
            middle_name: [null, []],
            suffix: [null, []],
            date_of_birth: [null, [Validators.required]],
            ssn: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
            itin: [null, []],

            email: [
                null,
                [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)]
            ],
            phone_country_code: ['1', [Validators.required]],
            phone_number: ['', [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
            address_line_1: [null, [Validators.required, Validators.maxLength(32)]],
            address_line_2: [null, [Validators.maxLength(32)]],
            city: [null, [Validators.required]],
            state: [null, [Validators.required]],
            postalCode: [null, [Validators.required]],
            country: ['US', [Validators.required]],

            is_owner: [false, []],
            is_controller: [false, []],
            ownership_percentage: [25, [Validators.min(0), Validators.max(100)]],
            job_title: ['', []]
        });
        newBeneficialGroup.controls.postalCode = this._addZipMaskListener(newBeneficialGroup.controls.postalCode);
        newBeneficialGroup.controls.phone_number = this._addPhoneMaskListener(newBeneficialGroup.controls.phone_number);
        newBeneficialGroup.controls.ssn = this._addSsnMaskListener(newBeneficialGroup.controls.ssn);
        if (this.representativesArray) {
            this.representativesArray.push(newBeneficialGroup);
        }
    }

    removeBeneficial(idx: number) {
        if (this.representativesArray) {
            this.representativesArray.removeAt(idx);
        }
    }

    checkIfTrustAvailable() {
        if (
            this.existingOperatingGateways &&
            this.existingOperatingGateways.length > 0 &&
            this.existingOperatingGateways.some((gateway) => gateway.status === 'active')
        ) {
            this.isAbleToSetupTrust = true;
        } else {
            this.isAbleToSetupTrust = false;
        }
    }

    typeChange(type: string) {
        const foundBusinessType = this.businessTypesMap.find((businessType) => businessType.slug === type);
        if (foundBusinessType) {
            this.businessTypeTitle = foundBusinessType.title;
        }
        if (type === 'individual') {
            this.gatewayForm?.controls.customer_type.setValue('individual');
        } else {
            this.gatewayForm?.controls.customer_type.setValue('business');
        }
    }

    selectIndustry($event: any, industry: any) {
        if ($event.isUserInput) {
            this.businessInfoForm?.controls.industry_hash.setValue(industry.hash);
        }
    }

    isOwnerChange($event: MatCheckboxChange, beneficial: FormGroupWithGroupAsControl) {
        if ($event.checked) {
            beneficial?.controls.ownership_percentage.setValidators([
                Validators.required,
                Validators.min(25),
                Validators.max(100)
            ]);
        } else {
            beneficial?.controls.ownership_percentage.setValidators([Validators.min(0), Validators.max(100)]);
        }

        beneficial?.controls.ownership_percentage.updateValueAndValidity();
    }

    async continue() {
        if (this.isFormValid()) {
            if (this.gatewayForm?.controls.customer_type.value === 'individual') {
                this.lockButton = true;
                const gatewayData = this.gatewayForm!.getRawValue();
                gatewayData['customer'] = Object.assign(
                    this.businessInfoForm?.getRawValue(),
                    this.individualInfoForm?.getRawValue(),
                    this.commonInfoForm?.getRawValue()
                );
                gatewayData['settings'] = {}; // {business_type_title: this.businessTypeTitle};
                const gateway = await this.createPaymentGateway(gatewayData);
                if (gateway) {
                    this.notificationsService.success(`Customer Created`);
                    this.notificationsService.success(`Settings saved`);

                    this.reloadAndShowFinalPopup.emit(gateway.payment_gateway_id);
                    this.lockButton = false;
                }
            } else {
                this.addRepresentative();
                this.step = 'step2';
            }
        }
    }

    async finish() {
        if (this.isFormValid()) {
            this.lockButton = true;
            let beneficialList = [];

            if (this.beneficialSetup && this.representativesArray) {
                if (this.representativesArray.invalid) {
                    this.representativesArray.markAllAsTouched();
                    return;
                } else {
                    beneficialList = this.representativesArray.getRawValue();
                }
            }

            const gatewayData = this.gatewayForm!.getRawValue();
            gatewayData['customer'] = Object.assign(
                this.businessInfoForm?.getRawValue(),
                this.individualInfoForm?.getRawValue(),
                this.commonInfoForm?.getRawValue()
            );
            gatewayData['customer']['representatives'] = beneficialList;
            gatewayData['settings'] = {business_type_title: this.businessTypeTitle};

            const gateway = await this.createPaymentGateway(gatewayData);

            if (gateway) {
                this.notificationsService.success(`Customer Created`);
                this.notificationsService.success(`Settings saved`);
            }

            this.step = 'step1';

            if (gateway) {
                this.reloadAndShowFinalPopup.emit(gateway.payment_gateway_id);
            }
            this.lockButton = false;
        }
    }

    stepBack() {
        if (this.representativesArray) {
            this.representativesArray.clear();
        }
        this.step = 'step1';
        this.lockButton = false;
    }

    async createPaymentGateway(customerData: Partial<IPaymentGateway>) {
        return firstValueFrom(this.companyGatewayService.createZipiFinancialGateway(customerData));
    }

    initValuChangeSubscription() {
        this.gatewayForm?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (this.lockButton) {
                this.lockButton = false;
            }
        });
        this.businessInfoForm?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (this.lockButton) {
                this.lockButton = false;
            }
        });
        this.individualInfoForm?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (this.lockButton) {
                this.lockButton = false;
            }
        });
        this.commonInfoForm?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (this.lockButton) {
                this.lockButton = false;
            }
        });
        this.representativesArray?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (this.lockButton) {
                this.lockButton = false;
            }
        });
    }

    isFormValid() {
        if (this.gatewayForm?.controls.customer_type.value === 'individual') {
            if (this.gatewayForm!.invalid || this.individualInfoForm?.invalid || this.commonInfoForm?.invalid) {
                this.gatewayForm!.markAllAsTouched();
                this.individualInfoForm!.markAllAsTouched();
                this.commonInfoForm!.markAllAsTouched();
                return false;
            }
        } else {
            if (this.gatewayForm!.invalid || this.businessInfoForm?.invalid || this.commonInfoForm?.invalid) {
                this.gatewayForm!.markAllAsTouched();
                this.businessInfoForm!.markAllAsTouched();
                this.commonInfoForm!.markAllAsTouched();
                return false;
            }
        }
        return true;
    }

    _filterIndustries(value: string): {title: string; hash: string}[] {
        const filterValue = value.toLowerCase();

        return this.industries.filter((industry) => industry.title.toLowerCase().includes(filterValue));
    }

    _addZipMaskListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            const charArr = changes.split('');
            const digitsArr: Array<string> = [];
            charArr.forEach((char: string, index: number) => {
                if (index <= 4) {
                    if (!isNaN(+char) && char !== ' ' && char !== null) {
                        digitsArr.push(char);
                    }
                }
            });
            control.setValue(digitsArr.join(''), {emitEvent: false});
        });
        return control;
    }

    _addSsnMaskListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            const charArr = changes.split('');
            const digitsArr: Array<string> = [];
            charArr.forEach((char: string, index: number) => {
                if (index <= 3) {
                    if (!isNaN(+char) && char !== ' ' && char !== null) {
                        digitsArr.push(char);
                    }
                }
            });
            control.setValue(digitsArr.join(''), {emitEvent: false});
        });
        return control;
    }

    _addEnnMaskListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (typeof changes === 'string') {
                const charArr = changes.split('');
                const digitsArr: Array<string> = [];
                const newCharArr: Array<string> = [];
                charArr.forEach((char: string, index: number) => {
                    if (!isNaN(+char) && char !== ' ' && char !== null) {
                        digitsArr.push(char);
                    }
                });
                digitsArr.forEach((digit, index) => {
                    if (index <= 8) {
                        if ([2].includes(index)) {
                            newCharArr.push('-');
                            newCharArr.push(digit);
                        } else {
                            newCharArr.push(digit);
                        }
                    }
                });

                const actualStr = newCharArr.join('');
                control.setValue(actualStr, {emitEvent: false});
            } else {
                control.setValue('', {emitEvent: false});
            }
        });

        return control;
    }

    _addIsHasWebsiteListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((is_has_website) => {
            if (this.businessInfoForm) {
                this._updateWebAndDescriptinValidators(is_has_website);
            }
        });

        return control;
    }

    _updateWebAndDescriptinValidators(is_has_website: string | null) {
        if (this.businessInfoForm) {
            if (is_has_website && is_has_website === 'yes') {
                this.businessInfoForm.controls.web.setValidators([Validators.required]);
                this.businessInfoForm.controls.description.clearValidators();
            } else if (is_has_website && is_has_website === 'no') {
                this.businessInfoForm.controls.description.setValidators([
                    Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(100)
                ]);
                this.businessInfoForm.controls.web.clearValidators();
            } else {
                this.businessInfoForm.controls.web.clearValidators();
                this.businessInfoForm.controls.description.clearValidators();
            }
            this.businessInfoForm.controls.web.updateValueAndValidity({emitEvent: false});
            this.businessInfoForm.controls.description.updateValueAndValidity({emitEvent: false});
        }
    }

    _addPhoneMaskListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (typeof changes === 'string') {
                const charArr = changes.split('');
                const digitsArr: Array<string> = [];
                const newCharArr: Array<string> = [];
                charArr.forEach((char: string, index: number) => {
                    if (!isNaN(+char) && char !== ' ' && char !== null) {
                        digitsArr.push(char);
                    }
                });
                digitsArr.forEach((digit, index) => {
                    if (index <= 9) {
                        if ([3, 6].includes(index)) {
                            newCharArr.push('-');
                            newCharArr.push(digit);
                        } else {
                            newCharArr.push(digit);
                        }
                    }
                });

                const actualStr = newCharArr.join('');
                control.setValue(actualStr, {emitEvent: false});
            } else {
                control.setValue('', {emitEvent: false});
            }
        });

        return control;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
