<mat-card class="mt-3">
    <!-------- Section Title -------->
    <mat-card-header class="header deal-header">
        <mat-card-title class="deal-header-title"> Transaction </mat-card-title>
        <div class="d-flex align-items-center">
            <app-edit-dual-deal
                style="margin-right: 8px; width: 140px"
                [dealFormGroup]="dealFormGroup"
                [canCreateDualDeal]="canCreateDualDeal"
                [afterDealSaving$]="afterDealSaving$"
                [isDealSaveInProgress]="isDealSaveInProgress"
                (saveDealChanges)="saveDealChanges.emit($event)"
            ></app-edit-dual-deal>
        </div>
    </mat-card-header>

    <mat-card-content>
        <div class="d-grid w-100">
            <!-------- Sales Price -------->
            <mat-form-field class="mx-3" *ngIf="dealFormGroup.controls.sales_price">
                <input
                    matInput
                    type="text"
                    [maskito]="currencyMaskitoMask"
                    name="sales_price"
                    matTooltip="If unknown, enter an anticipated or estimated sales price"
                    matTooltipPosition="above"
                    [formControl]="dealFormGroup.controls.sales_price"
                    placeholder="Sales Price"
                />
            </mat-form-field>

            <!-------- Type -------->
            <mat-form-field class="mx-3" *ngIf="dealFormGroup.controls.type">
                <mat-select
                    placeholder="Type"
                    name="type"
                    (selectionChange)="dealTransactionTypeChanged($event)"
                    [formControl]="dealFormGroup.controls.type"
                >
                    <mat-option [value]="type" *ngFor="let type of object_keys(DEAL.type_SET)">
                        {{ DEAL.type_LABELS[type] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-------- Close of Escrow -------->
            <mat-form-field class="mx-3" *ngIf="dealFormGroup.controls.close_of_escrow">
                <input
                    matInput
                    [matDatepicker]="picker_coe"
                    name="close_of_escrow"
                    [min]="minCloseOfEscrowDate"
                    [max]="maxCloseOfEscrowDate"
                    [formControl]="$any(dealFormGroup.controls.close_of_escrow)"
                    placeholder="Closing Date"
                />
                <mat-datepicker-toggle matSuffix [for]="picker_coe"></mat-datepicker-toggle>
                <mat-datepicker #picker_coe></mat-datepicker>
            </mat-form-field>

            <!-------- Source of Business -------->
            <div class="mx-3">
                <mat-form-field
                    *ngIf="dealFormGroup.controls.source_of_business"
                    [style.width]="isDefaultSourceOfBusinessList ? '100%' : 'calc(100% - 40px)'"
                >
                    <mat-select
                        placeholder="Source of Business"
                        name="source_of_business"
                        [formControl]="dealFormGroup.controls.source_of_business"
                    >
                        <mat-option [value]="null" class="font-italic text-muted"> None </mat-option>
                        <mat-option *ngFor="let source of sourcesOfBusiness" [value]="source.value">
                            {{ source.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <button
                    mat-icon-button
                    type="button"
                    *ngIf="!isDefaultSourceOfBusinessList"
                    rbac
                    [rule]="{deals__manage_source_of_business: true}"
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    (click)="openDialog()"
                >
                    <mat-icon>add_circle</mat-icon>
                </button>
            </div>

            <!-------- Sales Price Adjustment -------->
            <app-sales-price-adjustment
                *ngIf="isSalesPriceAdjustmentOpened"
                [ngClass]="isSalesPriceAdjustmentOpened ? 'grid-area-price-adjusted' : ''"
                [dealFG]="dealFormGroup"
                [adjustedSalesPrice]="adjustedSalesPrice"
                [isSalesPriceAdjustmentOpened]="isSalesPriceAdjustmentOpened"
            >
            </app-sales-price-adjustment>
            <!-------- Control button -------->
            <div class="ml-3 grid-area-price-adjusted-button">
                <button
                    type="button"
                    mat-stroked-button
                    color="primary"
                    [disabled]="dealFormGroup.controls.sales_price_adjustment_description?.disabled"
                    (click)="changePriceAdjustment()"
                >
                    {{ isSalesPriceAdjustmentOpened ? 'Remove Price Adjustment' : 'Add Price Adjustment' }}
                </button>
            </div>

            <!-------- Commission % -------->
            <mat-form-field class="mx-3" *ngIf="dealFormGroup.controls.income_commission_value">
                <input
                    matInput
                    type="number"
                    name="commission_value"
                    [formControl]="dealFormGroup.controls.income_commission_value"
                    placeholder="Commission (%)"
                />
            </mat-form-field>

            <!-------- Commission $ -------->
            <mat-form-field class="mx-3" *ngIf="dealFormGroup.controls.income_flat_commission_value">
                <input
                    matInput
                    type="number"
                    name="commission_value"
                    [formControl]="dealFormGroup.controls.income_flat_commission_value"
                    placeholder="Commission ($)"
                />
            </mat-form-field>

            <!-------- Commission Payer -------->
            <mat-form-field class="mx-3" *ngIf="commissionPayerFG.controls.commission_payer_id">
                <mat-select
                    placeholder="Commission Payer"
                    [formControl]="commissionPayerFG.controls.commission_payer_id"
                    (selectionChange)="selectCommissionPayer()"
                >
                    <ng-container *ngIf="sendersFromDealParticipants">
                        <mat-option
                            *ngFor="let participant of sendersFromDealParticipants"
                            [value]="participant.contact_id"
                        >
                            {{ participant.link_title }}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="row transaction-order">
            <div class="col-xl-3">
                <div class="row">
                    <div class="col-lg-12 py-3 mt-1 ml-3 transaction-total font-weight-bold">
                        <app-financial-node
                            [label]="'Gross Commission'"
                            [financialNodeFG]="grossCommission"
                        ></app-financial-node>
                    </div>
                </div>
            </div>

            <div class="col-xl-6">
                <div class="row">
                    <div class="col-lg-12">
                        <app-income-commission
                            style="width: 100%"
                            [dealFormGroup]="dealFormGroup"
                            [baseCommissionNodeFG]="baseCommission"
                            [grossCommissionNodeFG]="grossCommission"
                            [adjustedSalesPriceNodeFG]="adjustedSalesPrice"
                            [editableBaseCommissionNode]="editableBaseCommissionNode"
                            [disabled]="dealFormGroup.controls.flat_fee_commissions?.disabled"
                        ></app-income-commission>
                    </div>
                </div>
            </div>

            <div class="col-xl-3">
                <div class="row">
                    <div class="col-lg-12 font-weight-bold d-flex align-items-center">
                        <app-deal-commission-categorization-notes [dealId]="dealId">
                        </app-deal-commission-categorization-notes>
                    </div>

                    <div
                        *ngIf="
                            dealFormGroup.controls.flat_fee_commissions &&
                            dealFormGroup.controls.flat_fee_commissions.controls.length === 0
                        "
                        class="col-lg-12 py-3 transaction-total font-weight-bold"
                    >
                        <app-financial-node
                            [label]="'Base Commission'"
                            [financialNodeFG]="baseCommission"
                        ></app-financial-node>
                    </div>
                </div>
            </div>

            <div
                class="col-xl-6 offset-xl-3"
                *ngIf="
                    dealFormGroup.controls.flat_fee_commissions &&
                    dealFormGroup.controls.flat_fee_commissions.controls.length > 0
                "
            >
                <div style="display: flex; align-items: center; margin-right: 50px">
                    <mat-form-field
                        class="col-lg-6 spaced"
                        style="padding: 0; margin-right: 5px"
                        *ngIf="editableBaseCommissionNode.controls.label"
                    >
                        <input
                            matInput
                            placeholder="Label"
                            [formControl]="editableBaseCommissionNode.controls.label"
                            type="text"
                        />
                    </mat-form-field>
                    <mat-form-field
                        class="col-lg-6 spaced"
                        style="padding: 0"
                        *ngIf="editableBaseCommissionNode.controls.amount"
                    >
                        <input
                            matInput
                            [maskito]="currencyMaskitoMask"
                            placeholder="Amount"
                            [formControl]="editableBaseCommissionNode.controls.amount"
                        />
                    </mat-form-field>
                </div>
            </div>

            <div
                class="col-xl-3"
                *ngIf="
                    dealFormGroup.controls.flat_fee_commissions &&
                    dealFormGroup.controls.flat_fee_commissions.controls.length > 0
                "
            >
                <div class="row">
                    <div class="col-lg-12 py-3 mt-1 transaction-total font-weight-bold">
                        <app-financial-node
                            [label]="'Base Commission'"
                            [financialNodeFG]="baseCommission"
                        ></app-financial-node>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>

    <!-------- Additional Fields -------->
    <app-deal-additional-fields [dealFormGroup]="dealFormGroup"></app-deal-additional-fields>
</mat-card>
