import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {IContact} from '@cyberco-nodejs/zipi-typings';
import {Subject} from 'rxjs';
import {GenericFormArray} from '../../../../../entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {
    ___TYPE,
    CONTACT_METATYPE
} from '../../../../account-info/compensation/components/combined-compensation-profile-picker/combined-compensation-profile-picker.component';

@Component({
    selector: 'app-create-bill-confirm',
    templateUrl: 'associate-contact-dialog.component.html',
    styles: [
        `
            .mat-dialog-content {
                overflow-wrap: break-word;
                white-space: pre-wrap;
            }
        `
    ]
})
export class AssociateContactDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    availableContacts: IContact[] = [];
    contactsCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);

    isDefault: boolean = false;

    hideCancel: boolean = false;
    contactWithoutPartnerCompany: boolean = true;
    title: string = '';
    addonTitle: string = '';
    placeholder: string = '';
    metatype: CONTACT_METATYPE = null;
    availableTypes: Array<___TYPE> = ['contact'];

    constructor(
        public dialogRef: MatDialogRef<AssociateContactDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.hideCancel = typeof this.data.hideCancel !== 'undefined' ? this.data.hideCancel : false;
        this.contactWithoutPartnerCompany =
            typeof this.data.contactWithoutPartnerCompany !== 'undefined'
                ? this.data.contactWithoutPartnerCompany
                : true;
        this.title = this.data.title ? this.data.title : 'Create Vendor Contact';
        this.addonTitle = this.data.addonTitle ? this.data.addonTitle : 'Integration';
        this.placeholder = this.data.placeholder ? this.data.placeholder : 'Associate With Existing';
        this.metatype = this.data.metatype ? this.data.metatype : '';
        this.availableTypes = this.data.availableTypes ? this.data.availableTypes : ['contact'];
    }

    ngOnInit() {}

    apply() {
        if (this.isDefault) {
            this.dialogRef.close(null);
        } else {
            const contactsArray = this.contactsCtrlArr.getRawValue();
            if (!contactsArray || contactsArray.length === 0) {
                return;
            }

            if (this.availableTypes.length === 1 && this.availableTypes[0] === 'contact_person') {
                this.dialogRef.close(contactsArray[0].contact_persons[0]?.contact_person_id);
            } else {
                this.dialogRef.close(contactsArray[0].target_id);
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
