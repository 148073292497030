import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'reduceDoubledString'
})
export class ReduceDoubledStringPipe implements PipeTransform {
    transform(value: string): string {
        if (typeof value === 'string') {
            const splittedStrings = value.split(',').map((part) => part.trim());
            const reducedArray = Array.from(new Set(splittedStrings));

            if (splittedStrings.length > reducedArray.length) {
                return reducedArray.join(', ');
            }
            return value;
        }
        return value;
    }
}
