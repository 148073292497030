<section class="container-fluid" *ngIf="payout">
    <div class="d-flex mt-2">
        <div>
            <button
                mat-raised-button
                class="ml-2"
                [disabled]="!!payout.connected__invoice_fk_id"
                (click)="createAndConnectInvoiceFromPayout()"
            >
                Approve as Invoice
            </button>
        </div>
    </div>
    <mat-card class="mt-3 px-4 py-5">
        <header class="row">
            <div class="col-6 zp-company-info">
                <!--                <p class="zp-company-title">{{invoice?.creator_company?.title}}</p>-->
                <!--	            <div *ngFor="let location of invoice?.creator_company?.locations">-->
                <!--		            <p *ngIf="location.label === invoice?.creator_company?.settings.billing_address_label">-->
                <!--			            <span *ngIf="location.street_number">{{location.street_number}} </span>-->
                <!--			            <span *ngIf="location.street_address">{{location.street_address}} </span>-->
                <!--			            <span *ngIf="location.unit_number">{{location.unit_number}} </span>-->
                <!--			            <br>-->
                <!--			            <span *ngIf="location.city">{{location.city}}, </span>-->
                <!--			            <span *ngIf="location.state">{{location.state}} </span>-->
                <!--			            <span *ngIf="location.zip">{{location.zip}} </span>-->
                <!--			            <br>-->
                <!--			            <span *ngIf="location.country" class="text-muted">({{location.country}})</span>-->
                <!--		            </p>-->
                <!--	            </div>-->
            </div>

            <div class="col-6 text-right">
                <p class="zp-inveice_title">Payout</p>
                <p class="zp-invoice-num_value">{{ payout?.label }}</p>

                <p class="zp-refference_due_title">Amount: {{ payout?.amount | currency }}</p>
            </div>
        </header>

        <p>Bill To:</p>
        <div class="row" style="margin-bottom: 15px">
            <div class="col-6">
                <p *ngIf="payout.sender?.link_title">
                    {{ payout.sender?.link_title }}
                </p>
                <p *ngIf="payout.sender?.location_title">
                    {{ payout.sender?.location_title }}
                </p>
            </div>

            <div class="col-6 text-right">
                <p class="mb-2">
                    <span>Date:</span>
                    <span class="zp-w-150">{{ $any(payout?.created_at) | dateFromNumber | date: 'longDate' }}</span>
                </p>

                <p class="mb-2">
                    <span>Payout Status:</span>
                    <span class="zp-w-150">{{ payout?.status! | fromSnakeCase }}</span>
                </p>

                <p class="mb-2">
                    <span>Connected Invoice:</span>
                    <a
                        class="zp-w-150"
                        *ngIf="connectedInvoice?.invoice_id"
                        [routerLink]="['/sales/invoices/', connectedInvoice?.invoice_id!]"
                    >
                        {{ connectedInvoice?.invoice_number || ' N/A ' }}
                    </a>
                    <span class="zp-w-150" *ngIf="!connectedInvoice"> — </span>
                </p>

                <p class="mb-2">
                    <span>Connect Status:</span>
                    <span
                        class="zp-w-150"
                        *ngIf="
                            (payout.connected__bill_fk_id && !payout.connected__invoice_fk_id) ||
                            (!payout.connected__bill_fk_id && payout.connected__invoice_fk_id)
                        "
                    >
                        Partially Connected
                    </span>
                    <span class="zp-w-150" *ngIf="payout.connected__bill_fk_id && payout.connected__invoice_fk_id">
                        Connected
                    </span>
                    <span class="zp-w-150" *ngIf="!payout.connected__bill_fk_id && !payout.connected__invoice_fk_id">
                        Not Connected
                    </span>
                </p>
            </div>
        </div>

        <table class="table">
            <thead class="bg-dark text-white">
                <tr>
                    <td scope="col">#</td>
                    <td class="w-50" scope="col">Item</td>
                    <td scope="col">Qty</td>
                    <td scope="col">Rate</td>
                    <td scope="col" class="text-right">Amount</td>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of payout?.items; index as idx">
                    <td>{{ idx + 1 }}</td>
                    <td class="w-50">
                        {{ item?.name }}
                        <i class="text-muted">{{ item?.description }}</i>
                    </td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.rate }}</td>
                    <td class="text-right">{{ item.amount }}</td>
                </tr>
            </tbody>
        </table>

        <div class="w-100 text-right">
            <p class="mb-2">
                Sub Total: <span class="zp-total-nember">{{ payout?.amount }}</span>
            </p>
            <p class="mb-2">
                <strong
                    >Total: <span class="zp-total-nember font-weight-bold">{{ payout?.amount | currency }}</span>
                </strong>
            </p>
        </div>

        <div class="d-flex my-2 align-items-baseline" *ngIf="currentAmount.value !== 0">
            <div class="mr-3">
                <mat-form-field style="width: 100px">
                    <mat-icon matPrefix class="prefix">attach_money</mat-icon>
                    <input matInput required type="text" [formControl]="currentAmount" />
                    <mat-placeholder>Amount</mat-placeholder>
                </mat-form-field>
            </div>
        </div>
    </mat-card>
</section>
