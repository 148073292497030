<section>
    <app-settings-toolbar></app-settings-toolbar>

    <form *ngIf="licenseForm" class="purchase-form">
        <div class="mb-3" [formGroup]="licenseForm">
            <div class="row mt-4 mr-0 ml-0">
                <div class="col-6">
                    <!------- Minimum increment size ------->
                    <div
                        class="col-12"
                        rbac
                        [rule]="{company_settings__manage_purchase_settings_deals: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'block'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        <span class="font-weight-bold">Auto reload Deals in increments of</span>
                        <mat-form-field class="w-100" *ngIf="billingSettings && billingSettings.billing_settings">
                            <input
                                type="number"
                                [formControl]="licenseForm.controls.current_increment_size"
                                min="{{ billingSettings.billing_settings.min_increment_size }}"
                                matInput
                                required
                                placeholder="ie. 500, 1000, 2000..."
                            />
                        </mat-form-field>
                    </div>

                    <!------- Threshold for reload ------->
                    <div
                        class="col-12"
                        rbac
                        [rule]="{company_settings__manage_purchase_settings_deals: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'block'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        <span class="font-weight-bold">Reload when Deals are below</span>
                        <mat-form-field class="w-100">
                            <input
                                type="number"
                                [formControl]="licenseForm.controls['threshold_for_reload']"
                                matInput
                                required
                                placeholder="ie. 5, 10, 20..."
                            />
                        </mat-form-field>
                    </div>

                    <!------- Payment Methods ------->
                    <div class="col-12">
                        <span class="font-weight-bold">Payment Method</span>
                        <!------- Original ------->
                        <ng-container *ngIf="companyGateways && companyGateways.length; else fakePaymentMethods">
                            <mat-form-field class="w-100">
                                <mat-select [formControl]="licenseForm.controls['payment_method_fk_id']" required>
                                    <mat-optgroup
                                        *ngFor="let companyGateway of companyGateways"
                                        [label]="companyGateway.title"
                                    >
                                        <ng-container *ngFor="let method of companyGateway.payment_methods">
                                            <mat-option
                                                *ngIf="
                                                    method.is_available_for_payment &&
                                                    ['other', 'zipi_financial_cloud_bank'].includes(method.type)
                                                "
                                                [value]="method.id"
                                            >
                                                <span>{{ method.title }}</span>
                                            </mat-option>
                                        </ng-container>
                                    </mat-optgroup>
                                </mat-select>
                                <mat-placeholder>Payment Method</mat-placeholder>
                            </mat-form-field>
                        </ng-container>
                        <!------- Fake while loading ------->
                        <ng-template #fakePaymentMethods>
                            <mat-form-field class="w-100">
                                <mat-select></mat-select>
                                <mat-placeholder>Payment Method</mat-placeholder>
                            </mat-form-field>
                        </ng-template>
                    </div>
                </div>

                <div class="col-6">
                    <div
                        rbac
                        [rule]="{company_settings__manage_purchase_settings_deals: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'block'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        <span class="font-weight-bold">Company Status (contact us to change):</span>

                        <!------- Deal price ------->
                        <div class="col-12 my-3">
                            <span>Deal Price: </span>
                            <span class="font-weight-bold" *ngIf="billingSettings && billingSettings.billing_settings">
                                <ng-container *ngIf="billingSettings.billing_settings.license_price > 0; else noPrice">
                                    {{ billingSettings.billing_settings.license_price | currency: 'USD' : 'symbol' }}
                                </ng-container>
                                <ng-template #noPrice>
                                    {{
                                        billingSettings.billing_settings.license_price
                                            | currency: 'USD' : 'symbol' : '1.0-0'
                                    }}
                                </ng-template>
                            </span>
                        </div>

                        <!------- Deal Balance ------->
                        <div class="col-12 my-3">
                            <span>Deal Balance: </span>
                            <span class="font-weight-bold">
                                {{ licensesCount }} deal{{ licensesCount === 1 ? 's' : '' }}
                            </span>
                        </div>
                    </div>

                    <!------- Loads Processing ------->
                    <!--                    <div class="col-12 my-3 d-flex">-->
                    <!--                        <div>Loads Processing:</div>-->
                    <!--                        <div class="ml-2" *ngIf="processing && processing.length; else noPendings">-->
                    <!--                            <div *ngFor="let load of processing">-->
                    <!--                                <span class="font-weight-bold">-->
                    <!--                                    {{ load.used_billing_settings.current_increment_size !== null-->
                    <!--                                        ? load.used_billing_settings.current_increment_size-->
                    <!--                                        : load.used_billing_settings. min_increment_size}}-->
                    <!--                                </span>-->
                    <!--                                <span class="font-weight-bold">Deals </span>-->
                    <!--                                <span>({{ load.created_at | date: 'shortDate'}})</span>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                        <ng-template #noPendings>-->
                    <!--                            <span class="text-muted font-italic ml-2">No pending loads</span>-->
                    <!--                        </ng-template>-->
                    <!--                    </div>-->

                    <div class="mt-2" *ngIf="isCommissionDepositSettingsEnabled">
                        <span class="font-weight-bold">Money Transfers commission deposit:</span>
                        <div class="col-12 my-3">
                            <span>Current Balance: </span>
                            <span *ngIf="!isCommissionDepositDataUnavailable" class="font-weight-bold">
                                {{ commissionDepositSettings.balance | currency }}
                            </span>
                            <span *ngIf="isCommissionDepositDataUnavailable" style="color: #b9b9b9; font-style: italic">
                                Currently unavailable
                            </span>
                        </div>

                        <div class="col-12 my-3">
                            <span>Refill Amount: </span>
                            <span class="font-weight-bold">
                                {{ billingSettings.billing_settings.commission_deposit_refill_amount | currency }}
                            </span>
                        </div>

                        <div class="col-12 my-3">
                            <span>Refill Threshold: </span>
                            <span *ngIf="!isCommissionDepositDataUnavailable" class="font-weight-bold">
                                {{ commissionDepositSettings.threshold | currency }}
                            </span>
                            <span *ngIf="isCommissionDepositDataUnavailable" style="color: #b9b9b9; font-style: italic">
                                Currently unavailable
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <!------- Buttons ------->
            <div class="row mx-0 p-3">
                <div class="mt-3">
                    <button
                        type="button"
                        mat-raised-button
                        rbac
                        [rule]="{company_settings__manage_purchase_settings_deals: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        (click)="openViewLicenses()"
                    >
                        Purchased Deals
                    </button>

                    <button
                        type="button"
                        mat-raised-button
                        class="ml-4"
                        [matBadge]="processing.length"
                        matBadgeColor="warn"
                        [matBadgeHidden]="!processing || !processing.length"
                        (click)="showHistoryReloads()"
                    >
                        History of reloads
                    </button>
                </div>

                <span class="sp-spacer"></span>

                <div class="mt-3">
                    <button type="submit" mat-raised-button color="primary" (click)="saveCompanyBillingSettings()">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </form>
</section>
