<div
    *ngIf="contact && !contact.related__profile_fk_id && !contact?.related__company_group_fk_id"
    class="inline-block pl-2"
>
    <div
        class="menu-invite d-inline-flex align-items-center"
        [matMenuTriggerFor]="matMenu"
        *ngIf="zipiInvite?.status === 'new'"
    >
        <span class="text-muted ml-2">Invite is sent</span>
        <span class="text-muted material-icons d-flex align-self-center">arrow_drop_down</span>
    </div>
    <mat-menu #matMenu="matMenu">
        <button mat-menu-item [disabled]="!zipiInvite" (click)="cancelInvite(zipiInvite)">Cancel invite</button>
        <button mat-menu-item [disabled]="!zipiInvite" (click)="inviteContactToZipi()">Resend invite</button>
    </mat-menu>

    <!--	<span *ngIf="contact?.partner__company_fk_id"-->
    <!--	      class="font-italic" matTooltip="Accepted by '{{contact?.partner_company?.title}}'">-->
    <!--		Customer Portal Enabled-->
    <!--    </span>-->
    <span *ngIf="contact?.partner__company_fk_id" matTooltip="Customer Portal Enabled" class="contact__checkmark mt-n2">
        <mat-icon class="text-info">check</mat-icon>
    </span>

    <button
        mat-icon-button
        class="mt-n2"
        *ngIf="!zipiInvite && !contact?.partner__company_fk_id"
        (click)="inviteContactToZipi()"
        matTooltip="Invite to Customer Portal"
    >
        <mat-icon class="mr-1 text-info">contact_mail</mat-icon>
    </button>
</div>
