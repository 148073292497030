import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {firstValueFrom, Subject} from 'rxjs';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {IBill, IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {filter, takeUntil} from 'rxjs/operators';
import {PaymentMethodsService} from '../../../../profile/services/payment-methods.service';
import {CompanyGatewayService} from '../../../../../services/api/finance/company-gateway.service';
import {PAYMENT_MODES, SENDER_VELOCITY_TYPES} from '../../../../../local-typings';
import {LedgerAccountSelectorComponent} from '../../../../shared/components/ledger-account-selector/ledger-account-selector.component';
import {FormGroupWithFormControls} from '../../../../../typings/common';
import {FeatureFlagsService} from '../../../../feature-flags/feature-flags.service';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import moment from 'moment-timezone';
import {MatOptionSelectionChange} from '@angular/material/core';
import {MarketplaceSource} from '../../../../account-info/marketplace/marketplace.source';
import {NotificationsServiceZipi} from '../../../../notifications/notifications.service';
import {SelectOwnGatewayForReceiveOnlyDialogComponent} from '../../../../contacts/contact-dialogs/select-own-gateway-for-receive-only-dialog/select-own-gateway-for-receive-only-dialog.component';
import {ShipperContactsService} from '../../../../../services/api/shipper.contacts.service';
import {ZipiFinancialIframeDialogComponent} from '../../../../account-info/company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-financial-iframe-dialog/zipi-financial-iframe-dialog.component';
import {IZipiFinancialIframeDialogData} from '../../../../../typings/zipi-financial-iframe';
import {formatToDate} from '../../../../../utilities';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';
import {some} from 'lodash-es';
import {
    CreateChecksData,
    IPaymentMadeToBeProcessed,
    PrintChecksForBillsDialogComponent
} from '../check-only-dialog/print-checks-for-bills-dialog';

@Component({
    selector: 'app-pay-bill-dialog',
    templateUrl: './pay-bill-dialog.component.html',
    styleUrls: ['./pay-bill-dialog.component.scss']
})
export class PayBillDialogComponent implements OnInit, OnDestroy {
    @ViewChild('ledgerAccountPicker1', {static: true}) public ledgerAccountPicker1:
        | LedgerAccountSelectorComponent
        | undefined;
    @ViewChild('ledgerAccountPicker2', {static: true}) public ledgerAccountPicker2:
        | LedgerAccountSelectorComponent
        | undefined;
    private unsubscribe: Subject<void> = new Subject();

    unmaskCurrencyString = cleanCurrencyString;

    tabIndex: number;

    senderVelocityTypes = SENDER_VELOCITY_TYPES;
    // senderVelocityTypes = ['instant', 'standard'];

    formGroup: FormGroupWithFormControls = this.fb.group({
        paid_by__payment_method_fk_id: [null, []],
        pay_to__payment_method_fk_id: [null, []],
        paid_by__ledger_account_fk_id: [null, [Validators.required]],
        pay_to__ledger_account_fk_id: [null, []],
        scheduled_date: [null, []],
        amount: [0, []],
        note: ['', []],
        paid_date: [formatToDate(new Date()), [Validators.required]],
        payment_mode: [null, [Validators.required]],
        sender_velocity: [null, [Validators.required]],
        restrict_downgrade: [false, [Validators.required]],

        check_number: [null, []],
        memo: [null, []]
    }) as FormGroupWithFormControls;

    bill: IBill | undefined;

    // invoiceMethods: IPaymentMethod[] = [];
    invoiceBankMethod: IPaymentMethod | undefined;
    invoiceCardMethod: IPaymentMethod | undefined;
    paymentModes = PAYMENT_MODES;
    currencyMaskitoMask = currencyMaskitoOptions;

    zipiFinancialGateways: IPaymentGateway[] = [];
    isMuhneeAvailable: boolean = false;
    isPayloadCoAvailable: boolean = false;
    zipiLoading: boolean;
    isZipiFinancialRequested: boolean;
    isRequestedZipiFinancialActive: boolean = false;
    isZipiFinancialCardRequested: boolean = false;
    isRequestedZipiFinancialCardActive: boolean = false;

    authGateways: IPaymentGateway[] = [];
    authLoading: boolean;
    isAuthRequested: boolean;

    contactZipiFinancialReceiveGateway: IPaymentGateway | undefined;
    contactZipiFinancialPayloadCoReceiveGateway: IPaymentGateway | undefined;
    selectedDriver: 'plaid' | 'moov' | 'muhnee' | 'tpp' | 'payload' | null = null;
    receiveOnlyLoading: boolean;

    zipiPayCardGateway: IPaymentGateway | undefined;

    payToZipiFinancialMethod: IPaymentMethod | undefined;
    payToAuthMethod: IPaymentMethod | undefined;

    tabSlug: string | undefined;
    // feature_flags
    isMoneyTransfersFeatureFlagEnabled: boolean = false;
    isScheduledPaymentFeatureFlagEnabled: boolean = false;
    isPayloadCoFeatureFlagEnabled: boolean = false;
    isVelocityFeatureFlagEnabled: boolean = false;
    isScheduleEnabled: boolean = false;
    minScheduleDate: any;
    maxScheduleDate: any;

    selectedMethodType: string = '';

    constructor(
        private paymentMethodsService: PaymentMethodsService,
        private companyGatewayService: CompanyGatewayService,
        protected featureFlagsService: FeatureFlagsService,
        protected marketplaceSource: MarketplaceSource,
        private notificationServiceZipi: NotificationsServiceZipi,
        private contactsService: ShipperContactsService,
        protected dialog: MatDialog,
        public dialogRef: MatDialogRef<PayBillDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder
    ) {
        this.tabIndex = 0;
        this.isZipiFinancialRequested = false;
        this.isAuthRequested = false;
        this.zipiLoading = true;
        this.authLoading = true;
        this.receiveOnlyLoading = true;
    }

    ngOnInit() {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.isMoneyTransfersFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:money_transfer'
                );
                this.isScheduledPaymentFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:scheduled_transfer'
                );
                this.isVelocityFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:velocity'
                );
                this.isPayloadCoFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:payload_co'
                );
                if (!this.isVelocityFeatureFlagEnabled && this.formGroup) {
                    this.formGroup.controls.sender_velocity.clearValidators();
                    this.formGroup.controls.sender_velocity.updateValueAndValidity();
                }
            });
        this.bill = this.data.bill;

        if (this.bill && this.bill.bill_id) {
            this.paymentMethodsService
                .getInvoiceMethods([
                    this.bill.pay_to_bank__payment_method_fk_id,
                    this.bill.pay_to_card__payment_method_fk_id
                ])
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((methods) => {
                    this.invoiceBankMethod = methods.find(
                        (method) => method.payment_method_id === this.bill!.pay_to_bank__payment_method_fk_id
                    );
                    this.invoiceCardMethod = methods.find(
                        (method) => method.payment_method_id === this.bill!.pay_to_card__payment_method_fk_id
                    );
                    this.loadGateways();
                    this.loadReceiveOnlyGateway();
                });

            // this.paymentMethodsService.getAvailablePaymentMethodsForBill(this.bill.bill_id)
            //     .pipe(takeUntil(this.unsubscribe))
            //     .subscribe(methods => {
            //         this.invoiceMethods = methods;
            //         this.loadGateways();
            //     });

            this.formGroup.controls.amount.setValue(this.bill.pending_balance ? this.bill.pending_balance : 0);
        }
    }

    loadGateways() {
        this.companyGatewayService
            .getCompanyGatewaysByTypes(
                ['zipi_financial_business', 'authorize_net_customer', 'zipi_financial_card_customer'],
                this.bill?.money_receiver__contact_fk_id || null
            )
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((gateways) => {
                this.zipiFinancialGateways = gateways.filter((gateway) => gateway.type === 'zipi_financial_business');
                this.zipiFinancialGateways.forEach((gateway) => {
                    gateway.driver_type === 'muhnee'
                        ? (this.isMuhneeAvailable = true)
                        : gateway.driver_type === 'payload'
                          ? (this.isPayloadCoAvailable = true)
                          : false;
                });
                this.authGateways = gateways.filter((gateway) => gateway.type === 'authorize_net_customer');
                this.zipiPayCardGateway =
                    gateways.find((gateway) => gateway.type === 'zipi_financial_card_customer') || undefined;

                this.zipiLoading = false;
                this.authLoading = false;
            });

        // const zipiFinancialMethod = this.invoiceMethods.find(method => method.payment_gateway && ['zipi_financial_business'].includes(method.payment_gateway.type));
        // if (zipiFinancialMethod) {
        //     this.payToZipiFinancialMethod = zipiFinancialMethod;
        //     this.isZipiFinancialRequested = true;
        //     if (zipiFinancialMethod.type === 'zipi_financial_balance' && zipiFinancialMethod.is_card_acquiring_enabled) {
        //         this.isBalanceZipiFinancialMethodTarget = true;
        //     }
        //     this.isRequestedZipiFinancialActive = zipiFinancialMethod.status === 'active';
        // }

        if (this.invoiceBankMethod) {
            this.payToZipiFinancialMethod = this.invoiceBankMethod;
            this.isZipiFinancialRequested = true;
            this.isRequestedZipiFinancialActive = this.invoiceBankMethod.status === 'active';
        }

        if (this.invoiceCardMethod) {
            if (this.invoiceCardMethod.payment_gateway?.type === 'authorize_net_merchant') {
                this.payToAuthMethod = this.invoiceCardMethod;
                this.isAuthRequested = true;
            } else if (this.invoiceCardMethod.payment_gateway?.type === 'zipi_financial_business') {
                if (
                    this.invoiceCardMethod.type === 'zipi_financial_balance' &&
                    this.invoiceCardMethod.is_card_acquiring_enabled
                ) {
                    this.isZipiFinancialCardRequested = true;
                    this.isRequestedZipiFinancialCardActive = this.invoiceCardMethod.status === 'active';
                }
            } else if (this.invoiceCardMethod.payment_gateway?.type === 'zipi_financial_card_merchant') {
                if (this.invoiceCardMethod.is_card_acquiring_enabled) {
                    this.isZipiFinancialCardRequested = true;
                    this.isRequestedZipiFinancialCardActive = this.invoiceCardMethod.status === 'active';
                }
            }
        }

        // const authMethod = this.invoiceMethods.find(method => method.payment_gateway && method.payment_gateway.type === 'authorize_net_merchant');
        // if (authMethod) {
        //     this.payToAuthMethod = authMethod;
        //     this.isAuthRequested = true;
        // }
    }

    loadReceiveOnlyGateway() {
        if (this.bill && this.bill.money_receiver__contact_fk_id) {
            this.companyGatewayService
                .getContactZipiFinancialReceiveOnlyGateway(this.bill.money_receiver__contact_fk_id, 'payload', 'bank')
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateway) => {
                    this.contactZipiFinancialPayloadCoReceiveGateway = gateway;
                });
            this.companyGatewayService
                .getContactZipiFinancialReceiveOnlyGateway(this.bill.money_receiver__contact_fk_id, 'muhnee', 'all')
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateway) => {
                    this.contactZipiFinancialReceiveGateway = gateway;
                    this.receiveOnlyLoading = false;
                });
        } else {
            this.receiveOnlyLoading = false;
        }
    }

    validateAmount($event: any) {
        const inputValue = Number(cleanCurrencyString($event.target.value));
        let availableAmount = 0;
        if (this.bill && this.bill.pending_balance) {
            availableAmount = this.bill.pending_balance;
        }

        if (availableAmount < inputValue) {
            this.formGroup.controls.amount.setValidators([Validators.email]);
            this.formGroup.controls.amount.updateValueAndValidity();
            this.formGroup.controls.amount.markAsTouched();
        } else {
            this.formGroup.controls.amount.clearValidators();
            this.formGroup.controls.amount.updateValueAndValidity();
        }
        if (this.isVelocityFeatureFlagEnabled && this.tabSlug === 'zipi_financial') {
            if (inputValue >= 100000) {
                this.formGroup.controls.sender_velocity.clearValidators();
                this.formGroup.controls.sender_velocity.setValidators([
                    Validators.required,
                    Validators.pattern(/^((?!instant).)*$/)
                ]);
                this.formGroup.controls.sender_velocity.updateValueAndValidity();
            } else {
                this.formGroup.controls.sender_velocity.clearValidators();
                this.formGroup.controls.sender_velocity.setValidators([Validators.required]);
                this.formGroup.controls.sender_velocity.updateValueAndValidity();
            }
        }
    }

    methodChanged($event: MatOptionSelectionChange, gateway: IPaymentGateway) {
        if ($event.isUserInput) {
            if (gateway && gateway.sending_velocity && this.isVelocityFeatureFlagEnabled) {
                this.formGroup.controls.restrict_downgrade.setValue(gateway.sending_velocity.restrict_auto_downgrade);
                if (Number(cleanCurrencyString(this.formGroup.controls.amount.value)) >= 100000) {
                    this.formGroup.controls.sender_velocity.clearValidators();
                    this.formGroup.controls.sender_velocity.setValidators([
                        Validators.required,
                        Validators.pattern(/^((?!instant).)*$/)
                    ]);
                    this.formGroup.controls.sender_velocity.updateValueAndValidity();
                } else {
                    this.formGroup.controls.sender_velocity.clearValidators();
                    this.formGroup.controls.sender_velocity.setValidators([Validators.required]);
                    this.formGroup.controls.sender_velocity.updateValueAndValidity();
                }
                this.formGroup.controls.sender_velocity.setValue(gateway.sending_velocity.velocity);
                this.selectedMethodType = '';
                this.formGroup.controls.sender_velocity.setValue(gateway.sending_velocity.velocity);
            }
            if (this.tabSlug === 'zipi_fin_receive_only' && this.selectedDriver !== gateway.driver_type) {
                this.formGroup.controls.pay_to__payment_method_fk_id.setValue(null);
            }
            this.selectedDriver = gateway.driver_type;
            this.formGroup.controls.pay_to__payment_method_fk_id.enable();
        }
    }

    commissionPayerChanged($event: MatSlideToggleChange) {
        if ($event.checked) {
            if (this.selectedMethodType === 'balance') {
                this.formGroup.controls.sender_velocity.setValue('instant');
            } else {
                this.formGroup.controls.sender_velocity.setValue('standard');
            }
        } else {
            this.formGroup.controls.sender_velocity.setValue('no_fee');
        }
    }

    changePayMode($event: MatSlideToggleChange) {
        if ($event.checked) {
            this.isScheduleEnabled = true;
            this.minScheduleDate = moment().add(1, 'days').toDate();
            this.maxScheduleDate = moment().add(28, 'days').toDate();
        } else {
            this.isScheduleEnabled = false;
            this.formGroup.controls.scheduled_date.setValue(null);
        }
    }

    changeTab() {
        switch (this.tabSlug) {
            case 'zipi_financial': {
                if (
                    !this.zipiFinancialGateways ||
                    this.zipiFinancialGateways.length === 0 ||
                    !this.isZipiFinancialRequested ||
                    !this.isRequestedZipiFinancialActive
                ) {
                    return;
                }
                this.tabIndex = 1;
                this.resetFormGroup(formatToDate(new Date()), 'zipi_financial', true, true, true, false);
                this.formGroup.controls.pay_to__payment_method_fk_id.setValue(
                    this.payToZipiFinancialMethod?.payment_method_id
                );
                this.formGroup.controls.sender_velocity.clearValidators();
                this.formGroup.controls.sender_velocity.updateValueAndValidity();
                break;
            }
            case 'zipi_financial_card': {
                if (
                    !this.invoiceCardMethod ||
                    !this.isZipiFinancialCardRequested ||
                    !this.isRequestedZipiFinancialCardActive ||
                    !this.zipiPayCardGateway
                ) {
                    return;
                }
                this.tabIndex = 2;
                this.resetFormGroup(formatToDate(new Date()), 'zipi_financial_card', true, true, true, false);
                this.formGroup.controls.pay_to__payment_method_fk_id.setValue(this.invoiceCardMethod.payment_method_id);
                this.formGroup.controls.sender_velocity.clearValidators();
                this.formGroup.controls.sender_velocity.updateValueAndValidity();
                break;
            }
            case 'authorize': {
                if (!this.authGateways || this.authGateways.length === 0 || !this.isAuthRequested) {
                    return;
                }
                this.tabIndex = 3;
                this.resetFormGroup(formatToDate(new Date()), 'authorize', true, true, false, false);
                this.formGroup.controls.pay_to__payment_method_fk_id.setValue(this.payToAuthMethod?.payment_method_id);
                this.formGroup.controls.sender_velocity.clearValidators();
                this.formGroup.controls.sender_velocity.updateValueAndValidity();
                break;
            }
            case 'check': {
                this.tabIndex = 4;
                this.resetFormGroup(formatToDate(new Date()), 'check', true, false, false, true);
                this.formGroup.controls.sender_velocity.clearValidators();
                this.formGroup.controls.sender_velocity.updateValueAndValidity();

                // Open the Dialog to print checks
                if (this.bill) {
                    let dialogRef = this.dialog.open<PrintChecksForBillsDialogComponent, CreateChecksData>(
                        PrintChecksForBillsDialogComponent,
                        {
                            minWidth: 1272,
                            maxHeight: '80vh',
                            disableClose: true,
                            panelClass: 'no-padding-dialog',
                            autoFocus: false,
                            data: {
                                // pass the 1 bill ID
                                checks: this.bill?.bill_id ? [this.bill.bill_id] : [],
                                isCombineDisabled: false
                            }
                        }
                    );

                    dialogRef
                        .afterClosed()
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((data: IPaymentMadeToBeProcessed) => {});
                }
                this.dialogRef.close();

                break;
            }
            case 'record': {
                this.tabIndex = 5;
                this.resetFormGroup(formatToDate(new Date()), 'cash', false, false, false, true);
                this.formGroup.controls.sender_velocity.clearValidators();
                this.formGroup.controls.sender_velocity.updateValueAndValidity();
                break;
            }
            case 'zipi_fin_receive_only': {
                if (
                    !this.zipiFinancialGateways ||
                    this.zipiFinancialGateways.length === 0 ||
                    ((!this.contactZipiFinancialReceiveGateway ||
                        !this.contactZipiFinancialReceiveGateway.payment_methods ||
                        this.contactZipiFinancialReceiveGateway.payment_methods.length === 0) &&
                        (!this.contactZipiFinancialPayloadCoReceiveGateway ||
                            !this.contactZipiFinancialPayloadCoReceiveGateway.payment_methods ||
                            this.contactZipiFinancialPayloadCoReceiveGateway.payment_methods.length === 0))
                ) {
                    return;
                }
                this.tabIndex = 6;
                this.resetFormGroup(formatToDate(new Date()), 'zipi_financial', true, true, true, false);
                this.formGroup.controls.pay_to__payment_method_fk_id.disable();
                this.formGroup.controls.sender_velocity.clearValidators();
                this.formGroup.controls.sender_velocity.updateValueAndValidity();
                break;
            }
            case 'menu': {
                this.tabIndex = 0;
                this.resetFormGroup(null, null, false, false, false, false);
                this.formGroup.controls.sender_velocity.clearValidators();
                this.formGroup.controls.sender_velocity.updateValueAndValidity();
                break;
            }
        }
    }

    resetFormGroup(
        date: number | null,
        mode: string | null,
        paymentModeDisable: boolean,
        paidByValidate: boolean,
        payToValidate: boolean,
        ledgerValidate: boolean
    ) {
        this.formGroup.controls.paid_date.setValue(date);
        this.formGroup.controls.payment_mode.setValue(mode);
        this.formGroup.controls.paid_by__payment_method_fk_id.setValue(null);
        this.formGroup.controls.paid_by__ledger_account_fk_id.setValue(null);
        this.formGroup.controls.pay_to__payment_method_fk_id.enable();
        this.formGroup.controls.pay_to__payment_method_fk_id.setValue(null);
        if (paymentModeDisable) {
            this.formGroup.controls.payment_mode.disable();
        } else {
            this.formGroup.controls.payment_mode.enable();
        }
        if (paidByValidate) {
            this.formGroup.controls.paid_by__payment_method_fk_id.setValidators([Validators.required]);
            this.formGroup.controls.paid_by__payment_method_fk_id.updateValueAndValidity();
        } else {
            this.formGroup.controls.paid_by__payment_method_fk_id.clearValidators();
            this.formGroup.controls.paid_by__payment_method_fk_id.updateValueAndValidity();
        }
        if (payToValidate) {
            this.formGroup.controls.pay_to__payment_method_fk_id.setValidators([Validators.required]);
            this.formGroup.controls.pay_to__payment_method_fk_id.updateValueAndValidity();
        } else {
            this.formGroup.controls.pay_to__payment_method_fk_id.clearValidators();
            this.formGroup.controls.pay_to__payment_method_fk_id.updateValueAndValidity();
        }
        if (ledgerValidate) {
            this.formGroup.controls.paid_by__ledger_account_fk_id.setValidators([Validators.required]);
            this.formGroup.controls.paid_by__ledger_account_fk_id.updateValueAndValidity();
        } else {
            this.formGroup.controls.paid_by__ledger_account_fk_id.clearValidators();
            this.formGroup.controls.paid_by__ledger_account_fk_id.updateValueAndValidity();
        }
    }

    addCard() {
        this.dialogRef.close('add_new_card');
    }

    async setupZipiFinancialForContact(
        methodType: 'contact_bank' | 'contact_card',
        driverType: 'plaid' | 'moov' | 'muhnee' | 'tpp' | 'payload'
    ) {
        let customerRef = null;
        const existingGateway =
            methodType === 'contact_bank' ? this.contactZipiFinancialReceiveGateway : this.zipiPayCardGateway;
        if (!existingGateway) {
            const addons = await firstValueFrom(this.marketplaceSource.addonsChangeEvent);
            if (!addons) {
                console.error('Cannot find addons');
                return;
            }
            const zipiFinancialInstance = addons.filter(
                (instanse) => instanse.addon && instanse.addon.slug === 'zipi_financial_integration'
            )[0];
            if (!zipiFinancialInstance) {
                console.error('Cannot find addon instance');
                return;
            }
            customerRef = zipiFinancialInstance.settings?.external_customer_ref;
            if (!customerRef) {
                console.error('Cannot find reference to Money Transfers (EFT) Customer.');
                return;
            }
        }

        let currentOperatingGateway = null;
        const gateway = await firstValueFrom(
            this.companyGatewayService.getContactZipiFinancialReceiveOnlyGateway(
                this.bill?.money_receiver__contact_fk_id as number,
                driverType,
                'all'
            )
        );

        if (driverType === 'muhnee') {
            const operatingGateways: IPaymentGateway[] = await firstValueFrom(
                this.companyGatewayService.getCompanyGatewaysByTypes(['zipi_financial_business'], null)
            );
            const muhneeGateways = operatingGateways.filter((gateway) => gateway.driver_type === 'muhnee');
            if (!muhneeGateways || muhneeGateways.length === 0) {
                return this.notificationServiceZipi.addError(`First you need to activate Money Transfers (EFT).`);
            } else if (muhneeGateways.length === 1) {
                currentOperatingGateway = muhneeGateways[0];
            } else {
                const selectGatewayDialogRef = this.dialog.open(SelectOwnGatewayForReceiveOnlyDialogComponent, {
                    maxHeight: '80vh',
                    width: '650px',
                    data: {gateways: muhneeGateways}
                });
                const res = await firstValueFrom(selectGatewayDialogRef.afterClosed());
                if (res) {
                    currentOperatingGateway = res;
                } else {
                    return null;
                }
            }
        }

        const contact = await firstValueFrom(
            this.contactsService.getContactById(this.bill?.money_receiver__contact_fk_id as number)
        );

        let mainPerson = null;
        if (contact && contact.contact_persons && contact.contact_persons.length > 0) {
            mainPerson = contact.contact_persons.find((person) => person.type === 'main_person');
        }
        const contactData = {
            company_name: contact?.company_name ? contact?.company_name : '',
            email: mainPerson && mainPerson.email ? mainPerson.email : '',
            first_name: mainPerson && mainPerson.first_name ? mainPerson.first_name : '',
            last_name: mainPerson && mainPerson.last_name ? mainPerson.last_name : '',
            nick_name: mainPerson && mainPerson.preferred_name ? mainPerson.preferred_name : ''
        };
        const dialogRef = this.dialog.open<ZipiFinancialIframeDialogComponent, IZipiFinancialIframeDialogData>(
            ZipiFinancialIframeDialogComponent,
            {
                disableClose: true,
                maxHeight: '80vh',
                width: '650px',
                panelClass: 'custom-dialog-container',
                data: {
                    contactId: this.bill?.money_receiver__contact_fk_id
                        ? this.bill?.money_receiver__contact_fk_id
                        : null,
                    gateway: gateway,
                    ownGateway: currentOperatingGateway,
                    driverType: driverType,
                    paymentMethod: null,
                    methodType: methodType,
                    accessMethod: 'internal',
                    isUniversal: true,
                    customerId: customerRef,
                    prefillData: contactData,
                    creationSource: 'current_company',
                    storeAccountMethod: 'required'
                }
            }
        );

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result.isError) {
                    this.notificationServiceZipi.addError(result.message);
                }
                this.loadReceiveOnlyGateway();
            });
    }

    pay() {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this.ledgerAccountPicker1!.validateControl();
            this.ledgerAccountPicker2!.validateControl();
            return;
        } else {
            const payData = this.formGroup.getRawValue();
            payData.amount = Number(cleanCurrencyString(payData.amount));

            if (payData.payment_mode === 'check') {
                payData['check_info'] = {
                    memo: payData.memo,
                    check_number: payData.check_number,
                    check_status: 'uncleared',
                    print_status: 'not_printed'
                };
            } else if (payData.payment_mode === 'check_record') {
                payData['check_info'] = {
                    memo: payData.memo,
                    check_number: payData.check_number,
                    check_status: 'uncleared',
                    print_status: 'unknown'
                };
            } else {
                payData['check_info'] = null;
            }
            this.dialogRef.close(payData);
        }
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    protected readonly some = some;
}
