import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FinancialNode} from '../../../../shared/components/financial-node/financial-node.model';

@Component({
    selector: 'app-intradeal-explain-dialog',
    styles: [
        `
            .row.footer {
                justify-content: space-between;
            }

            mat-list {
                max-height: 400px;
                width: 100%;
                overflow-x: hidden;
            }
        `
    ],
    template: `
        <h3>Compensation Rule Allocations</h3>
        <div style="display: flex; flex-direction: column; min-width: 300px;">
            <div *ngFor="let intradeal of financialNode.intradeals" style="display: flex; flex-direction: row;">
                <ng-container *ngIf="intradeal.paid && intradeal.paid > 0">
                    {{ intradeal.label }}: \${{ intradeal.paid | number: '1.0-2' }}
                    <!--Paid {{intradeal.paid|number:'1.0-2'}} (as {{intradeal.percent|percent:'1.0-4'}} of {{intradeal.of|number:'1.0-2'}}) due to {{intradeal.label}} -->
                    <!--<ng-container *ngIf="['less_then', 'less_then_or_equal'].includes(intradeal.rule.operator)">with CAP {{intradeal.rule.value}} on {{intradeal.rule.type}}</ng-container> -->
                </ng-container>
            </div>
            <div style="margin-top: 20px;">
                <button style="float: right;" mat-raised-button class="mat-default" (click)="dialogRef.close()">
                    ok
                </button>
            </div>
        </div>
    `
})
export class IntraedealExplainDialogComponent {
    financialNode: FinancialNode = new FinancialNode();

    constructor(public dialogRef: MatDialogRef<IntraedealExplainDialogComponent>) {}
}
