import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ServiceRequester} from '../service.requester';

@Injectable()
export class LoggingApiService {
    private url = '/logging/';

    constructor(public requester: ServiceRequester) {}

    logPlaidAuthSession(data: any): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `plaid/auth`, 'POST', 'shipp', data);
    }

    losPlaidBankingSession(data: any): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `plaid/banking`, 'POST', 'shipp', data);
    }
}
