<div class="w-100 report-addons m-2">
    <h4>Select Addons to Display:</h4>
    <mat-button-toggle-group
        [vertical]="true"
        [multiple]="true"
        [value]="reportSettings && reportSettings.contact_addons ? reportSettings.contact_addons : []"
        (change)="onChange($event)"
    >
        <mat-button-toggle *ngFor="let item of listOfAddons" [value]="item.addon.slug">
            <div class="addon-item">
                <div class="item-info">
                    <ng-container *ngIf="item.addon && item.addon.preset">
                        <img class="logo" [src]="item.addon.preset.logo" />
                    </ng-container>
                </div>
                <div class="item-actions">
                    <div class="but-toggle-item title">
                        <span>{{ item.addon.preset.title }}</span>
                    </div>
                </div>
            </div>
        </mat-button-toggle>
    </mat-button-toggle-group>
</div>
