<section class="container-fluid mt-3" *ngIf="payment">
    <div class="d-flex w-100 justify-content-end py-2">
        <button
            mat-stroked-button
            color="primary"
            class="mr-2"
            *ngIf="isPayStubPrintAvailable"
            (click)="printPayStub()"
        >
            Print Paystub
        </button>
        <button
            mat-icon-button
            [rule]="{purchases__manage_payments_made: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            color="primary"
            matTooltip="Refresh Payment Status"
            *ngIf="
                payment.summary_status === 'processing' &&
                payment.pay_to_payment_method &&
                payment.pay_to_payment_method.payment_gateway &&
                payment.pay_to_payment_method.payment_gateway.type === 'authorize_net_merchant'
            "
            [disabled]="!payment || !payment.payment_made_id"
            (click)="$event.stopPropagation(); recheckPaymentStatus(payment.payment_made_id!)"
        >
            <mat-icon>cached</mat-icon>
        </button>
        <div
            class="ml-2"
            matTooltip="You cannot modify Payment Made associated with Deal."
            [matTooltipDisabled]="!!payment && !payment.source__deal_fk_id"
        >
            <button
                mat-raised-button
                [rule]="{purchases__manage_payments_made: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                type="button"
                [disabled]="
                    ['expired', 'declined', 'reversed', 'canceling', 'canceled', 'error'].includes(
                        payment.summary_status
                    ) ||
                    (payment && !!payment.source__deal_fk_id)
                "
                (click)="router.navigate(['purchases/payments/edit', paymentId])"
            >
                Edit Payment
            </button>
        </div>
        <button
            mat-raised-button
            color="primary"
            class="ml-2"
            *ngIf="
                payment.payment_mode === 'check' &&
                payment.check_info &&
                payment.check_info.print_status === 'not_printed'
            "
            (click)="openCustomizeDialog()"
        >
            Print Check
        </button>

        <button
            mat-stroked-button
            color="primary"
            class="ml-2"
            *ngIf="
                (payment.payment_mode === 'zipi_financial' || payment.payment_mode === 'zipi_financial_card') &&
                payment.summary_status === 'processing' &&
                isVoidAllowed
            "
            (click)="voidPayment()"
        >
            Void
        </button>

        <button
            mat-raised-button
            *ngIf="payment.summary_status === 'scheduled'"
            [rule]="{purchases__manage_payments_made: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            type="button"
            class="ml-1"
            (click)="cancelPayment()"
        >
            Cancel Payment
        </button>
    </div>

    <mat-card class="p-5">
        <div class="mb-3">
            History
            <button mat-icon-button (click)="showHistory = !showHistory; loadHistory()">
                <mat-icon *ngIf="showHistory">expand_more</mat-icon>
                <mat-icon *ngIf="!showHistory">chevron_right</mat-icon>
            </button>

            <div
                *ngIf="showHistory && logsLoaded && paymentMadeLogs.length > 0"
                style="
                    max-height: 200px;
                    overflow-y: scroll;
                    overflow-x: hidden;
                    background-color: lightgray;
                    font-size: 12px;
                    padding: 10px;
                "
            >
                <div *ngFor="let log of paymentMadeLogs" class="d-flex">
                    <div style="width: 100px; margin-right: 50px" class="font-weight-bold" *ngIf="log.action_timestamp">
                        {{ log.action_timestamp | amDateFormat: 'MM/DD/YYYY HH:mm' }}
                    </div>
                    <div
                        style="width: 100px; margin-right: 50px"
                        class="font-weight-bold"
                        *ngIf="!log.action_timestamp"
                    >
                        N/A
                    </div>

                    <div>{{ log.description }}</div>
                </div>
            </div>

            <div
                *ngIf="showHistory && logsLoaded && paymentMadeLogs.length === 0"
                class="bg-light"
                style="max-height: 200px; overflow-y: scroll; overflow-x: hidden; font-size: 12px; padding: 10px"
            >
                <div class="d-flex w-100">
                    <div class="w-15">Date</div>
                    <div class="w-15">Activity</div>
                    <div class="w-15">Status</div>
                    <div class="w-15" style="text-align: end">Total Amount</div>
                </div>

                <div class="d-flex w-100">
                    <div class="w-15">
                        <div
                            style="width: 100px; margin-right: 50px"
                            class="font-weight-bold"
                            *ngIf="payment.created_at"
                        >
                            {{ $any(payment.created_at) | amDateFormat: 'MM/DD/YYYY HH:mm' }}
                        </div>
                        <div
                            style="width: 100px; margin-right: 50px"
                            class="font-weight-bold"
                            *ngIf="!payment.created_at"
                        >
                            N/A
                        </div>
                    </div>
                    <div class="w-15">Payment</div>
                    <div class="w-15">{{ payment.summary_status | fromSnakeCase }}</div>
                    <div class="w-15" style="text-align: end">{{ payment.amount | currency }}</div>
                </div>

                <div *ngFor="let log of paymentMadeOldLogs" class="d-flex">
                    <ng-container>
                        <div class="w-15">
                            <div
                                style="width: 100px; margin-right: 50px"
                                class="font-weight-bold"
                                *ngIf="log.created_at"
                            >
                                {{ log.created_at | amDateFormat: 'MM/DD/YYYY HH:mm' }}
                            </div>
                            <div
                                style="width: 100px; margin-right: 50px"
                                class="font-weight-bold"
                                *ngIf="!log.created_at"
                            >
                                N/A
                            </div>
                        </div>
                        <div class="w-15">Refund</div>
                        <div class="w-15">
                            {{ log.status ? (log.status | fromSnakeCase) : (log.summary_status | fromSnakeCase) }}
                        </div>
                        <div class="w-15" style="color: red; text-align: end">-{{ log.amount | currency }}</div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div
            class="credit-status"
            *ngIf="
                payment &&
                payment.payment_mode === 'check' &&
                payment.check_info &&
                payment.check_info.print_status === 'not_printed'
            "
            [ngStyle]="{'background-color': '#d32f2f'}"
        >
            {{ payment.check_info?.print_status! | fromSnakeCase }}
        </div>

        <div
            class="credit-status"
            *ngIf="
                payment &&
                payment.payment_mode === 'check' &&
                payment.check_info &&
                payment.check_info.print_status !== 'not_printed'
            "
            [ngStyle]="{'background-color': statusColor[payment.check_info?.print_status!]}"
        >
            {{ payment.check_info?.print_status! | fromSnakeCase }}
        </div>

        <div
            class="credit-status"
            *ngIf="payment.payment_mode !== 'check'"
            [ngStyle]="{'background-color': statusColor[payment.summary_status]}"
        >
            {{ payment.summary_status | fromSnakeCase }}
        </div>

        <header class="d-flex justify-content-end">
            <div class="w-50 text-right">
                <p class="zp-payment_title">Payment Receipt</p>

                <p class="zp-refference_due_title">Amount</p>
                <p class="zp-refference_due_value" style="margin-bottom: 15px">
                    {{ payment?.amount! - completedRefunds! | currency }}
                </p>
                <div class="zp-refference_due_title d-flex align-items-center justify-content-end">
                    <div class="mr-1">1099 Amount</div>
                    <mat-icon
                        [matTooltip]="'Edit amount for 1099'"
                        style="font-size: 18px; width: 18px; height: 18px; cursor: pointer"
                        (click)="open1099Edit()"
                    >
                        edit
                    </mat-icon>
                </div>
                <div
                    class="zp-refference_due_value d-flex align-items-center justify-content-end"
                    style="margin-bottom: 15px"
                >
                    <div
                        *ngIf="
                            payment?.amount_1099_nec_custom !== undefined && payment?.amount_1099_nec_custom !== null;
                            else elseBlock
                        "
                    >
                        {{ payment?.amount_1099_nec_custom | currency }}
                    </div>
                    <ng-template #elseBlock>
                        {{ payment?.amount_1099_nec || 0 | currency }}
                    </ng-template>
                </div>
            </div>
        </header>

        <p>Payment Received By:</p>
        <div>
            <span *ngIf="payment.money_receiver_contact?.hidden" class="hidden-contact">
                {{ payment?.money_receiver_contact?.display_name }}<br />
            </span>

            <a
                *ngIf="!payment.money_receiver_contact?.hidden"
                routerLink="/contacts/{{ payment?.money_receiver_contact?.contact_id }}"
            >
                {{ payment?.money_receiver_contact?.display_name }}
            </a>
        </div>
        <div class="row" style="margin-bottom: 15px">
            <div class="col-3">
                <!--                <div class="pl-2" *ngFor="let location of payment.invoice?.creator_company?.locations">-->
                <!--                    <p *ngIf="location.label === payment.invoice?.creator_company?.settings.billing_address_label">-->
                <!--                        <span *ngIf="location.street_number">{{location.street_number}} </span>-->
                <!--                        <span *ngIf="location.street_address">{{location.street_address}} </span>-->
                <!--                        <span *ngIf="location.unit_number">{{location.unit_number}} </span>-->
                <!--                        <br>-->
                <!--                        <span *ngIf="location.city">{{location.city}}, </span>-->
                <!--                        <span *ngIf="location.state">{{location.state}} </span>-->
                <!--                        <span *ngIf="location.zip">{{location.zip}} </span>-->
                <!--                        <br>-->
                <!--                        <span *ngIf="location.country" class="text-muted">({{location.country}})</span>-->
                <!--                    </p>-->
                <!--                </div>-->

                <div *ngFor="let relatedPayment of payment.related_payments">
                    <ng-container *ngIf="relatedPayment.invoice">
                        <p class="text-muted m-0">{{ relatedPayment.bill!.bill_number! }}</p>

                        <div class="mb-0">
                            <!--                           <a *ngIf="contacts[relatedPayment.invoice.money_sender__contact_fk_id]?.display_name"-->
                            <!--                              routerLink="/contacts/{{relatedPayment.invoice.money_sender__contact_fk_id}}"-->
                            <!--                           >-->
                            <!--                               {{contacts[relatedPayment.invoice.money_sender__contact_fk_id].display_name}}<br>-->
                            <!--                           </a>-->
                            <!--                           <span *ngIf="moneySender?.billing_address?.street_number">{{moneySender.billing_address.street_number}}&nbsp;</span>-->
                            <!--                           <span *ngIf="moneySender?.billing_address?.street_address">{{moneySender.billing_address.street_address}}</span>-->
                            <!--                           <span *ngIf="moneySender?.billing_address?.unit_number"><br>{{moneySender.billing_address.unit_number}}</span>-->
                            <!--                           <br>-->
                            <!--                           <ng-container *ngIf="moneySender?.billing_address?.city">-->
                            <!--                               <span>{{moneySender.billing_address.city + ','}}&nbsp;</span>-->
                            <!--                           </ng-container>-->
                            <!--                           <span *ngIf="moneySender?.billing_address?.state">{{moneySender.billing_address.state }}&nbsp;</span>-->
                            <!--                           <span *ngIf="moneySender?.billing_address?.zip">{{moneySender.billing_address.zip}}&nbsp;</span>-->
                            <!--                           <span *ngIf="moneySender?.billing_address?.country"-->
                            <!--                                 class="text-muted"><br>({{moneySender.billing_address.country}})</span>-->
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-9 text-right">
                <p class="mb-2" *ngIf="payment.summary_status !== 'scheduled'">
                    <span>Payment Date:</span>
                    <span class="zp-w-300">
                        {{
                            payment.paid_date
                                ? (payment.paid_date | dateFromNumber | date: 'longDate')
                                : (payment.created_at | date: 'longDate')
                        }}
                    </span>
                </p>

                <p class="mb-2" *ngIf="payment.summary_status === 'scheduled'">
                    <span>Scheduled Date:</span>
                    <span class="zp-w-300">
                        {{
                            payment.scheduled_paid_date
                                ? (payment.scheduled_paid_date | dateFromNumber | date: 'longDate')
                                : (payment.paid_date | date: 'longDate')
                        }}
                    </span>
                </p>

                <p class="mb-2">
                    <span>Payment #:</span>
                    <span class="zp-w-300">{{ payment.payment_made_number }}</span>
                </p>

                <p class="mb-2">
                    <span>Payment Mode:</span>
                    <span class="zp-w-300">{{ paymentModesMap[payment?.payment_mode!] }}</span>
                </p>

                <p class="mb-2">
                    <span>Payment Details:</span>
                    <span class="zp-w-300">
                        <span
                            *ngIf="
                                payment?.related_payments?.length &&
                                payment?.paid_by_payment_method?.title &&
                                payment?.paid_by_payment_method?.title !== 'Cash' &&
                                payment?.paid_by_payment_method?.title !== 'Check'
                            "
                            >{{ payment?.paid_by_payment_method?.title }}</span
                        >
                        <span
                            *ngIf="
                                !payment?.related_payments?.length ||
                                !payment?.paid_by_payment_method?.title ||
                                payment?.paid_by_payment_method?.title === 'Cash' ||
                                payment?.paid_by_payment_method?.title === 'Check'
                            "
                            >N/A</span
                        >
                    </span>
                </p>
                <ng-container
                    *ngIf="
                        (payment.payment_mode === 'check' || payment.payment_mode === 'check_record') &&
                        !!payment.check_info
                    "
                >
                    <p class="mb-2">
                        <span>Check Number:</span>
                        <span class="zp-w-300">{{ payment?.check_info?.check_number }}</span>
                    </p>
                    <p class="mb-2">
                        <span>Memo:</span>
                        <span class="zp-w-300">{{ payment?.check_info?.memo }}</span>
                    </p>
                </ng-container>
            </div>
        </div>

        <div class="d-flex justify-content-end">
            <div class="w-25">
                <div style="text-align: end" *ngIf="payment.fee_amount">
                    Processing Fee: {{ payment.fee_amount | currency }}
                </div>
                <div style="text-align: end" class="font-weight-bold">
                    Applied to Bills: {{ appliedToInvoices | currency }}
                </div>
                <div
                    style="text-align: end; color: #808080ff; font-size: 14px"
                    *ngIf="appliedToInvoices !== pendingBalance"
                >
                    Pending Payment: {{ pendingBalance | currency }}
                </div>
                <div
                    style="text-align: end"
                    class="font-weight-bold"
                    *ngIf="!!unapplied || unapplied !== pendingBalanceForUnapplied"
                >
                    Unapplied (Credits): {{ unapplied | currency }}
                </div>
                <div
                    style="text-align: end; color: #808080ff; font-size: 14px"
                    *ngIf="unapplied !== pendingBalanceForUnapplied"
                >
                    Pending Payment: {{ pendingBalanceForUnapplied | currency }}
                </div>
            </div>
        </div>

        <div class="w-35">
            <div *ngFor="let relatedPayment of payment.related_payments">
                <ng-container *ngIf="relatedPayment.bill">
                    <div class="d-flex">
                        <a
                            href=""
                            class="invoice-link w-30"
                            routerLink="/purchases/bills/{{ relatedPayment.bill.bill_id }}"
                            >{{ relatedPayment.bill?.bill_number }}:
                        </a>
                        <div class="font-weight-bold w-70 ml-1">{{ relatedPayment['applied'] | currency }}</div>
                    </div>
                    <div
                        class="d-flex"
                        *ngIf="
                            relatedPayment.hasOwnProperty('applied') &&
                            relatedPayment.hasOwnProperty('pending') &&
                            relatedPayment['applied'] !== relatedPayment['pending']
                        "
                    >
                        <div class="w-30"></div>
                        <div class="w-70" style="color: #808080ff; font-size: 14px">
                            Pending Payment: {{ relatedPayment['pending'] | currency }}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </mat-card>

    <div class="my-3" style="font-size: 18px">
        <div class="d-flex align-items-center">
            <h4>More Information</h4>
            <div class="ml-2" *ngIf="!additionalInfoLoaded"><mat-spinner [diameter]="18"></mat-spinner></div>
        </div>
        <div>
            <div
                *ngIf="
                    !currentPaidThroughAccount &&
                    additionalInfoLoaded &&
                    !payment.source__deposit_release_fk_id &&
                    (!relatedDeals || !relatedDeals.length === 0)
                "
            >
                No additional information
            </div>
            <div *ngIf="currentPaidThroughAccount">
                Paid Through: {{ currentPaidThroughAccount.name | fromSnakeCase }}
            </div>
            <div *ngIf="payment.source__deposit_release_fk_id">Type: Deal Deposit Release</div>
            <div *ngFor="let relatedDeal of relatedDeals">
                <div *ngIf="relatedDeal && relatedDeal.id">
                    Deal:
                    <a [routerLink]="['/deals/edit', relatedDeal.id]">
                        <span *ngIf="!relatedDeal.address" class="text-muted">No address </span>
                        <span *ngIf="relatedDeal.address"
                            >{{ relatedDeal.street_number }} {{ relatedDeal.address }}
                        </span>
                        — {{ relatedDeal.type }}/{{ relatedDeal.status }}
                    </a>
                </div>
            </div>
            <div *ngIf="payment.bank_operation_info && payment.bank_operation_info.total_amount">
                Part of Bank Transaction for {{ payment.bank_operation_info.total_amount | currency }}
            </div>
        </div>
    </div>
</section>
