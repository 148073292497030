import {Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {combineLatest, Subject} from 'rxjs';
import {map, filter, takeUntil, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {ILedgerAccount, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {NotificationsService} from 'angular2-notifications';

import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {selectCompanySettings, selectLedgerAccountsSorted} from 'app/store/root.selectors';
import {
    CreateLedgerAccount,
    UpdateLedgerAccount,
    DeleteLedgerAccount,
    FetchLedgerAccounts
} from '../../../store/finance.actions';
import {LEDGER_TYPES_GROUPS} from 'app/local-typings';
import {IFinanceState} from '../../../store/finance.reducer';
import {LedgerAccountService} from 'app/services/api/finance/ledger-accounts.service';
import {FetchCompanySettings} from '../../../../account-info/store/settings.actions';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Group} from 'app/models/group';
import {MatOptionSelectionChange} from '@angular/material/core';
import {FormGroupWithFormControls} from '../../../../../typings/common';
import {GroupsSource} from '../../../../../services/sources/groups.source';

@Component({
    selector: 'app-edit-ledger-account',
    templateUrl: 'edit-ledger-account.component.html',
    styleUrls: ['edit-ledger-account.component.css']
})
export class EditLedgerComponent implements OnInit, OnDestroy {
    @ViewChild('groupInput') groupInput: ElementRef<HTMLInputElement> | undefined;
    @ViewChild('auto') matAutocomplete: MatAutocomplete | undefined;

    @Input() dialogMode: boolean = false;
    @Input() bankingMode: boolean = false;
    @Input() isTrustAccount: boolean = false;
    @Input() groupsList: Group[] = [];
    @Output() create: EventEmitter<ILedgerAccount> = new EventEmitter<ILedgerAccount>();
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    private unsubscribe: Subject<void> = new Subject();

    ledgerAccount: ILedgerAccount | undefined;
    ledgerAccounts: (ILedgerAccount & {level: number})[] = [];

    zipiFinancialMethods: IPaymentMethod[] | undefined;
    bankingMethod: IPaymentMethod | undefined;

    accType: any = {};
    currencyList: string[] = ['AUD', 'CAD', 'CNI', 'GBP', 'ING', 'JPY', 'USD', 'ZAR'];

    formGroup: FormGroupWithFormControls = this.fb.group({
        name: ['', Validators.required],
        bank_name: ['', []],
        code: [''],
        description: [''],
        type: ['', Validators.required],
        has_parent: [false],
        parent__ledger_account_fk_id: [null],
        number: [null],
        currency: [{value: 'USD', disabled: true}], // SkySlope Books does not currently support other listed currencies
        expense: [null],
        is_accessible_for_all: [false]
    }) as FormGroupWithFormControls;
    selectedGroupsIds: Array<number> = [];

    typeGroups = LEDGER_TYPES_GROUPS;

    has_trust_account_feature: boolean = false;

    isLedgerHasConnectedTransaction: boolean;
    isWarningShow: boolean = false;

    getAccountConfigByType(group: string, type: string): any {
        const selectedGroup = this.typeGroups.find((el: {[key: string]: string}) => el.group === group);
        return selectedGroup.list.find((el: {[key: string]: string}) => el.value === type);
    }

    optionSelected(e: MatOptionSelectionChange) {
        if (!e.isUserInput) {
            return;
        }
        const showSubAcc = this.getAccountConfigByType(e.source.group.label, e.source.value);
        this.accType = showSubAcc;
    }

    isTypeDisabled(type: {[key: string]: string}) {
        return (
            (type.value === 'trust_liability' && !this.has_trust_account_feature) ||
            ['other_current_asset_accounts_receivable', 'current_liability', 'retained_earnings'].includes(type.value)
        );
    }

    formChange() {
        // on type change - clear conditional values
        this.formGroup
            .get('type')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((type) => {
                // update accounts list
                this.setFilteredLedgerAccounts();
                if (!!this.formGroup.controls.parent__ledger_account_fk_id.value) {
                    this.isWarningShow = true;
                }

                this.formGroup.patchValue({
                    has_parent: false,
                    parent__ledger_account_fk_id: null,
                    currency: 'USD',
                    number: null,
                    expense: null,
                    bank_name: null
                });
            });
        this.formGroup
            .get('is_accessible_for_all')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => {
                // this.groupInput.nativeElement.disabled = value;
                this.selectedGroupsIds = [];
            });
        this.formGroup.controls.has_parent.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            if (value === false) {
                this.formGroup.controls.parent__ledger_account_fk_id.setValue(null);
            } else {
                if (this.isWarningShow) {
                    this.isWarningShow = false;
                }
            }
        });
    }

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store<IFinanceState>,
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private ntfs: NotificationsService,
        private ledgerAccountService: LedgerAccountService,
        private groupSrc: GroupsSource
    ) {
        this.isLedgerHasConnectedTransaction = true;
    }

    initEdit() {
        this.store.dispatch(new FetchLedgerAccounts());
        this.store.dispatch(new FetchCompanySettings());
        this.store
            .select(selectCompanySettings)
            .pipe(
                filter((cs) => !!cs),
                takeUntil(this.unsubscribe)
            )
            .subscribe((cs) => {
                if (cs) {
                    this.has_trust_account_feature = cs.has_trust_account_feature as boolean;
                }
            });

        combineLatest(
            this.route.paramMap.pipe(
                map((pm) => +(pm.get('id') as string)),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            ),
            this.store.pipe(select(selectLedgerAccountsSorted), takeUntil(this.unsubscribe))
        )
            .pipe(
                map(([id, lx]) => lx.find((la) => la.id === id)),
                filter((la) => !!la),
                tap((la) => {
                    this.ledgerAccount = la;
                    if (typeof la === 'undefined') {
                        return;
                    }

                    this.checkForConnectedTransactions(la.ledger_account_id as number);

                    if (la.connected_payment_methods?.length) {
                        const bankingMethod = la.connected_payment_methods.find(
                            (pm) => pm.payment_gateway?.type === 'banking'
                        );
                        this.zipiFinancialMethods = la.connected_payment_methods.filter(
                            (pm) =>
                                pm.payment_gateway?.type === 'zipi_financial_business' ||
                                pm.payment_gateway?.type === 'zipi_financial_trust'
                        );
                        if (bankingMethod) {
                            this.bankingMethod = bankingMethod;
                        }
                    }

                    // disable changing type in edit mode
                    if (la.system_key) {
                        this.formGroup.controls['type'].disable();
                        this.formGroup.controls['has_parent'].disable();
                        this.formGroup.controls['parent__ledger_account_fk_id'].disable();
                    }

                    this.formGroup.patchValue({...la, has_parent: !!la.parent__ledger_account_fk_id});

                    if (la.accessible_for) {
                        this.selectedGroupsIds = [...la.accessible_for];
                    }
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        // set in EDIT mode filtered ledgerAccounts by type and not same id
        combineLatest(
            this.route.paramMap.pipe(
                map((pm) => +(pm.get('id') as string)),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            ),
            this.store.pipe(select(selectLedgerAccountsSorted), takeUntil(this.unsubscribe))
        )
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([ix, lx]) => {
                const la = lx.find((acc) => acc.id === ix);
                // filter by type and not same id
                this.ledgerAccounts = <(ILedgerAccount & {level: number})[]>(
                    lx.filter((el) => la && el.id !== la.id && el.type === la.type)
                );
            });

        if (this.bankingMode) {
            const filterTypeGroups: {[key: string]: any} = [];

            LEDGER_TYPES_GROUPS.forEach((a: {[key: string]: any}) => {
                const filterList = a.list.filter(
                    (l: {[key: string]: string}) => l.value === 'credit_card' || l.value === 'bank'
                );

                if (filterList.length) {
                    filterTypeGroups.push({group: a.group, list: filterList});
                }
            });

            this.typeGroups = filterTypeGroups;
        }
    }

    checkForConnectedTransactions(ledgerAccountId: number) {
        this.ledgerAccountService
            .checkForConnectedTransactions(ledgerAccountId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                this.isLedgerHasConnectedTransaction = result;
            });
    }

    validateForm() {
        if (this.formGroup.valid) {
            return true;
        }

        this.ntfs.warn('Form is not valid');

        this.formGroup.updateValueAndValidity();

        return false;
    }

    getFormValues() {
        // get form values without has_parent prop
        const {has_parent, ...values} = this.formGroup.getRawValue();
        if (!has_parent) {
            values.parent__ledger_account_fk_id = null;
        }

        values.accessible_for = this.selectedGroupsIds;

        return values;
    }

    createLedgerAccount() {
        if (this.validateForm() === false) {
            return;
        }

        this.store.dispatch(new CreateLedgerAccount(this.getFormValues()));
        this.create.emit(this.getFormValues());

        if (this.dialogMode) {
            return;
        }

        this.router.navigate(['/company/finance/chartofaccounts']);
    }

    updateLedgerAccount() {
        if (this.validateForm() === false) {
            return;
        }

        this.store.dispatch(new UpdateLedgerAccount(Object.assign({}, this.ledgerAccount, this.getFormValues())));
    }

    deleteLedgerAccount() {
        if (typeof this.ledgerAccount === 'undefined') {
            return;
        }

        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            data: {
                title: 'Deleting Ledger Account',
                message: `Ledger Account "${this.ledgerAccount.name}" will be deleted`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {
                if (typeof this.ledgerAccount === 'undefined') {
                    return;
                }
                this.store.dispatch(new DeleteLedgerAccount(this.ledgerAccount.id as number));
            });
    }

    setFilteredLedgerAccounts() {
        this.store.pipe(select(selectLedgerAccountsSorted), takeUntil(this.unsubscribe)).subscribe((ledgerAccounts) => {
            // get only accounts within selected TYPE
            this.ledgerAccounts = <(ILedgerAccount & {level: number})[]>ledgerAccounts.filter((el) => {
                return el.type === this.formGroup.get('type')?.value;
            });
        });
    }

    selectedGroup(event: MatAutocompleteSelectedEvent) {
        this.selectedGroupsIds.push(event.option.value);

        // handle unfocus group list
        setTimeout(() => (this.groupInput ? this.groupInput.nativeElement.blur() : null), 300);
    }

    removeGroup(groupId: number) {
        const index = this.selectedGroupsIds.indexOf(groupId);

        if (index >= 0) {
            this.selectedGroupsIds.splice(index, 1);
        }
    }

    clearGroupInput() {
        if (typeof this.groupInput === 'undefined') {
            return;
        }
        this.groupInput.nativeElement.value = '';
    }

    addGroup(event: MatChipInputEvent): void {
        if (typeof this.matAutocomplete !== 'undefined' && !this.matAutocomplete.isOpen) {
            const {value} = event;

            if (value) {
                this.selectedGroupsIds.push(Number(value));
            }
        }
    }

    ngOnInit() {
        this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
            this.groupsList = list.filter((group) => group.type === Group.type_SET.division);
        });
        this.store.dispatch(new FetchLedgerAccounts());
        if (this.isTrustAccount) {
            this.formGroup.controls['type'].setValue('trust');
            this.formGroup.controls['type'].disable();
        } else {
            this.initEdit();
        }
        this.formChange();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.create.complete();
        this.cancel.complete();
    }
}
