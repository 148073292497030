<form *ngIf="formCtrl" [formGroup]="formCtrl" (ngSubmit)="saveCompany()" class="mb-3">
    <!-- <h2>Company
        <strong>{{sessionService.profile.company.title}}</strong>
    </h2> -->

    <mat-card>
        <h3 class="mb-1">Locations</h3>

        <ng-container formArrayName="locations" *ngIf="companyLocationsCtrl">
            <div
                [formGroupName]="idx"
                *ngFor="let locationCtrl of companyLocationsCtrl.controls; index as idx; last as isLast"
            >
                <div class="sp-location sp-location__preview" *ngIf="editingLocationIndex !== idx">
                    <span *ngIf="locationCtrl.value.label">{{ locationCtrl.value.label + ':' }}</span>
                    <span *ngIf="locationCtrl.value.street_number">{{ locationCtrl.value.street_number }}</span>
                    <span *ngIf="locationCtrl.value.street_address">{{ locationCtrl.value.street_address }}</span>
                    <span *ngIf="locationCtrl.value.unit_number">{{ locationCtrl.value.unit_number }}</span>
                    <span *ngIf="locationCtrl.value.city">{{ locationCtrl.value.city + ',' }}</span>
                    <span *ngIf="locationCtrl.value.state">{{ locationCtrl.value.state }}</span>
                    <span *ngIf="locationCtrl.value.zip">{{ locationCtrl.value.zip }}</span>
                    <span *ngIf="locationCtrl.value.country" class="text-muted"
                        >({{ locationCtrl.value.country }})</span
                    >

                    <!-- <span *ngIf="locationCtrl.value.abbr">{{locationCtrl.value.abbr + ','}}</span>  -->
                </div>

                <div class="sp-location sp-location__edit" *ngIf="editingLocationIndex === idx">
                    <div class="col-10 p-1">
                        <mat-form-field class="w-100">
                            <input type="text" matInput formControlName="label" placeholder="Label" required />
                        </mat-form-field>
                    </div>

                    <div class="col-2 p-1">
                        <mat-form-field class="w-100">
                            <input type="text" matInput formControlName="phone" placeholder="Phone" />
                        </mat-form-field>
                    </div>

                    <div class="col-2 p-1">
                        <mat-form-field class="w-100">
                            <input
                                type="text"
                                matInput
                                formControlName="street_number"
                                placeholder="Street #"
                                [errorStateMatcher]="errMatcher"
                                required
                            />
                            <mat-error align="end" *ngIf="locationCtrl.get('street_number')?.invalid"
                                >Required</mat-error
                            >
                        </mat-form-field>
                    </div>

                    <div class="col-8 p-1">
                        <mat-form-field class="w-100">
                            <input
                                type="text"
                                matInput
                                formControlName="street_address"
                                placeholder="Street Address"
                                [errorStateMatcher]="errMatcher"
                                required
                            />
                            <mat-error align="end" *ngIf="locationCtrl.get('street_address')?.invalid"
                                >Required</mat-error
                            >
                        </mat-form-field>
                    </div>

                    <div class="col-2 p-1">
                        <mat-form-field class="w-100">
                            <input type="text" matInput formControlName="unit_number" placeholder="Unit" />
                        </mat-form-field>
                    </div>

                    <div class="col-3 p-1">
                        <mat-form-field class="w-100">
                            <input
                                type="text"
                                matInput
                                formControlName="city"
                                placeholder="City"
                                [errorStateMatcher]="errMatcher"
                                required
                            />
                            <mat-error align="end" *ngIf="locationCtrl.get('city')?.invalid">Required</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-3 p-1">
                        <mat-form-field class="w-100">
                            <mat-select [placeholder]="'State'" formControlName="state" required>
                                <mat-option *ngFor="let state of statesAndTerritories" [value]="state.abbreviation">
                                    {{ state.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error align="end" *ngIf="locationCtrl.get('state')?.invalid">Required</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-3 p-1">
                        <mat-form-field class="w-100">
                            <input
                                type="text"
                                matInput
                                formControlName="zip"
                                placeholder="Zip"
                                [errorStateMatcher]="errMatcher"
                                required
                            />
                            <mat-error align="end" *ngIf="locationCtrl.get('zip')?.invalid"
                                >Required or Invalid</mat-error
                            >
                        </mat-form-field>
                    </div>

                    <div class="col-3 p-1">
                        <mat-form-field class="w-100">
                            <mat-select [placeholder]="'Country'" formControlName="country" required>
                                <mat-option *ngFor="let country of countryList" [value]="country.slug">
                                    {{ country.label }}
                                </mat-option>
                            </mat-select>
                            <mat-error align="end" *ngIf="locationCtrl.get('country')?.invalid">Required</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="sp-btn-group">
                    <button
                        *ngIf="editingLocationIndex !== idx"
                        mat-icon-button
                        (click)="editLocation(idx)"
                        class="sp-location-remove"
                    >
                        <mat-icon class="sp-edit-icon">edit</mat-icon>
                    </button>
                    <button
                        *ngIf="editingLocationIndex === idx"
                        mat-icon-button
                        (click)="preSaveLocation(idx)"
                        class="sp-location-remove"
                    >
                        <mat-icon class="sp-check-icon">check</mat-icon>
                    </button>
                    <button mat-icon-button (click)="removeLocation(idx)" class="sp-location-remove">
                        <mat-icon class="sp-delete-icon">delete</mat-icon>
                    </button>
                </div>
            </div>
        </ng-container>

        <div class="sp-place-picker">
            <app-gplaces-picker
                [locationCtrl]="locationPickerCtrl"
                [isClearAfterPickNeeded]="true"
                [isPickedChipNeeded]="false"
            ></app-gplaces-picker>
        </div>
        <button mat-raised-button (click)="addLocation()">
            <mat-icon>add</mat-icon>
            Location
        </button>

        <div class="row">
            <div class="col-md-6">
                <h3>Billing Address</h3>
                <mat-form-field class="w-100" *ngIf="companyLocationsCtrl">
                    <mat-select formControlName="billing_address" placeholder="Select location label">
                        <ng-container *ngFor="let locationCtrl of companyLocationsCtrl.controls">
                            <mat-option
                                *ngIf="locationCtrl.valid && locationCtrl.value.label"
                                [value]="locationCtrl.value.label"
                                >{{ locationCtrl.value.label }}</mat-option
                            >
                        </ng-container>
                    </mat-select>
                    <!-- <mat-hint align="end" *ngIf="companyLocationsCtrl.length === 0"> No Location with Label created</mat-hint> -->
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <h3>Default Disbursement Delivery Address</h3>
                <mat-form-field class="w-100" *ngIf="companyLocationsCtrl">
                    <mat-select
                        formControlName="default_disbursement_check_delivery_address"
                        placeholder="Select location label"
                    >
                        <ng-container *ngFor="let locationCtrl of companyLocationsCtrl.controls">
                            <mat-option
                                *ngIf="locationCtrl.valid && locationCtrl.value.label"
                                [value]="locationCtrl.value.label"
                                >{{ locationCtrl.value.label }}</mat-option
                            >
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <h3>Payroll Payer Address</h3>
                <mat-form-field class="w-100" *ngIf="companyLocationsCtrl">
                    <mat-select formControlName="payroll_location_id" placeholder="Select payroll address">
                        <ng-container *ngFor="let locationCtrl of companyLocationsCtrl.controls">
                            <mat-option
                                *ngIf="locationCtrl.valid && locationCtrl.value.label"
                                [value]="locationCtrl.value.company_location_id"
                            >
                                {{ locationCtrl.value.label }}</mat-option
                            >
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="text-right mt-3">
            <button type="submit" class="mr-3" mat-raised-button color="primary">Save</button>
            <!-- <button mat-raised-button color="primary" (click)="saveCompany()">Save</button> -->
            <button mat-raised-button (click)="cancelChanges()">Cancel</button>
        </div>
    </mat-card>
</form>
