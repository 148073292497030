import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../service.requester';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ZipiFinancialTransferService {
    url = '/finance/zipi-financial-transfer';

    constructor(public requester: ServiceRequester) {}

    getCompanyTransfersByGatewayIdWithParams(
        gatewayId: number,
        params: any
    ): Observable<{result: Array<any>; _meta: {total: number}}> {
        return this.requester.makeMsCall$(`${this.url}/with-params/${gatewayId}`, 'POST', 'shipp', params);
    }
    getTransfersLogByGatewayIdWithParams(
        gatewayId: number,
        params: any
    ): Observable<{result: Array<any>; _meta: {total: number}}> {
        return this.requester.makeMsCall$(`${this.url}/log-with-params/${gatewayId}`, 'POST', 'shipp', params);
    }

    initiateZipiFinancialSelfTransfer(data: any): Observable<string> {
        return this.requester.makeMsCall$(`${this.url}/self-transfer`, 'POST', 'shipp', data);
    }

    getZipiFinancialTransferInfo(transferHash: string): Observable<any> {
        return this.requester.makeMsCall$(`/public/zipi-financial/transfer-info/${transferHash}`, 'GET', 'shipp');
    }

    requestTransferSpeedUp(transferHash: string): Observable<boolean> {
        return this.requester.makeMsCall$(`/public/zipi-financial/speedup-transfer`, 'POST', 'shipp', {
            hash: transferHash
        });
    }
}
