import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';
import {ILoan} from '@cyberco-nodejs/zipi-typings';
import {takeUntil} from 'rxjs/operators';
import {assign} from 'lodash-es';
import {Router} from '@angular/router';
import {INTEREST_PERIOD, PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {RbacService} from '../../../../rbac/rbac.service';
import {LoansService} from 'app/services/api/finance/loans.service';
import {IScrollData} from 'app/models/scroll-data';
import {MatPaginator} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-garnishmets',
    templateUrl: 'garnishments.component.html',
    styleUrls: ['garnishments.component.scss', '../../../../../../assets/infinite-scroll-table.scss']
})
export class GarnishmentsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;

    interestPeriod = INTEREST_PERIOD;

    // it defines height of lazy loading list
    offsetTopToLazyLoading$: BehaviorSubject<number> | undefined;
    scrollData: IScrollData = {
        sort_column: 'date',
        sort_direction: 'desc',
        offset: 0,
        limit: 50,
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<ILoan>;
    displayedColumns = [
        'date',
        'receiver_contact_name',
        'sender_contact_name',
        'product_name',
        'amount',
        'interest_rate',
        'interest_rate_limit',
        'interest_period',
        'action'
    ];

    constructor(
        public router: Router,
        protected rbacService: RbacService,
        public loansService: LoansService
    ) {
        this.dataSource = new MatTableDataSource<ILoan>([]);
    }

    ngOnInit() {
        this.rbacService.isAllowed({lending__view_garnishments: true}).then((can) => {
            if (!can) {
                this.router.navigate(['/']);
            }
        });

        this.getLoans();

        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.getLoans();
            });
        }
    }

    editGarnishment(loan: ILoan) {
        this.router.navigate([`/lending/garnishments/edit/${loan.loan_id}`]);
    }

    deleteGarnishment(loan: ILoan) {
        if (loan && loan.loan_id) {
            this.loansService
                .deleteLoan(loan.loan_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((res) => {
                    if (res) {
                        this.ngOnInit();
                    }
                });
        }
    }

    getLoans() {
        this.unsubscribeBatch.next();

        this.loansService
            .getLoansByTypeList('garnishment', this.scrollData)
            .pipe(takeUntil(this.unsubscribeBatch))
            .subscribe((data) => {
                this.dataSource.data = data.result.map((loan) =>
                    assign({}, loan, {
                        interestPeriod: this.interestPeriod.find((period) => period.value === loan.interest_period)
                    })
                );
                this.scrollData.total = data._meta.total;
            });
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        this.paginator!.pageIndex = 0;
        this.scrollData.offset = 0;
        this.getLoans();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
    }
}
