import {of} from 'rxjs';

import {switchMap, map, tap, first, filter, catchError, mapTo} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store, select} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';

import {
    FetchInvoices,
    FinanceActionTypes,
    FetchInvoicesSuccess,
    FetchInvoicesMust,
    CreateRecurringInvoice,
    CreateRecurringInvoiceSuccess,
    UpdateRecurringInvoiceSuccess,
    UpdateRecurringInvoice,
    DeleteRecurringInvoice,
    DeleteRecurringInvoiceSuccess,
    FetchProducts,
    FetchProductsSuccess,
    FetchProductsMust,
    CreateProduct,
    CreateProductSuccess,
    UpdateProduct,
    UpdateProductSuccess,
    DeleteProductSuccess,
    DeleteProduct,
    FetchLedgerAccounts,
    FetchLedgerAccountsSuccess,
    FetchLedgerAccountsMust,
    CreateLedgerAccount,
    CreateLedgerAccountSuccess,
    UpdateLedgerAccount,
    UpdateLedgerAccountSuccess,
    DeleteLedgerAccount,
    DeleteLedgerAccountSuccess,
    FetchBills,
    FetchBillsSuccess,
    FetchBillsMust,
    CreateOpeningBalance,
    CreateOpeningBalanceSuccess,
    UpdateOpeningBalance,
    UpdateOpeningBalanceSuccess,
    DeleteOpeningBalance,
    DeleteOpeningBalanceSuccess
} from './finance.actions';
import {IFinanceState} from './finance.reducer';
import {NotificationsService} from 'angular2-notifications';
import {selectInvoices, selectProducts, selectLedgerAccounts, selectBills} from './finance.selectors';
import {RecurringInvoicesService} from 'app/services/api/finance/recurring-invoices.service';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {ProductsService} from 'app/services/api/finance/products.service';
import {LedgerAccountService} from 'app/services/api/finance/ledger-accounts.service';
import {BillsService} from 'app/services/api/finance/bills.service';
import {ExpensesService} from '../services/expenses.service';
import {ILedgerAccount, IProduct, IRecurringInvoice} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class FinanceEffectsService {
    constructor(
        protected actions$: Actions,
        protected store: Store<IFinanceState>,
        protected router: Router,
        private ntfs: NotificationsService,
        private rixService: RecurringInvoicesService,
        private invoicesSrv: InvoicesService,
        private billsSrv: BillsService,
        private productsSrv: ProductsService,
        private ledgerAccSrv: LedgerAccountService,
        private expenseService: ExpensesService
    ) {}

    @Effect()
    fetchInvoices$ = this.actions$.pipe(
        ofType<FetchInvoices>(FinanceActionTypes.FETCH_INVOICES),
        switchMap(() => this.store.pipe(select(selectInvoices), first())),
        switchMap((ix) => (ix.length === 0 ? this.invoicesSrv.getInvoices() : of(null))),
        filter((ix) => !!ix),
        map((ix) => new FetchInvoicesSuccess(ix!))
    );

    @Effect()
    fetchInvoicesMust$ = this.actions$.pipe(
        ofType<FetchInvoicesMust>(FinanceActionTypes.FETCH_INVOICES_MUST),
        switchMap(() => this.invoicesSrv.getInvoices()),
        filter((ix) => !!ix),
        map((ix) => new FetchInvoicesSuccess(ix))
    );

    @Effect()
    fetchBills$ = this.actions$.pipe(
        ofType<FetchBills>(FinanceActionTypes.FETCH_BILLS),
        switchMap(() => this.store.pipe(select(selectBills), first())),
        switchMap((ix) => (ix.length === 0 ? this.billsSrv.getBills({}) : of(null))),
        filter((ix) => !!ix),
        map((ix) => new FetchBillsSuccess(ix!.result))
    );

    @Effect()
    fetchBillsMust$ = this.actions$.pipe(
        ofType<FetchBillsMust>(FinanceActionTypes.FETCH_BILLS_MUST),
        switchMap(() => this.billsSrv.getBills({})),
        filter((ix) => !!ix),
        map((ix) => new FetchBillsSuccess(ix.result))
    );

    @Effect()
    createRecurringInvoice$ = this.actions$.pipe(
        ofType<CreateRecurringInvoice>(FinanceActionTypes.CREATE_RECURRING_INVOICE),
        switchMap(({payload}) => this.rixService.createRecurringInvoice(payload!).pipe(catchError((err) => of(null)))),
        filter((ri) => !!ri),
        tap((ri) => {
            this.router.navigate(['/sales/invoices/recurring']);
        }),
        map((ri) => new CreateRecurringInvoiceSuccess(ri!))
    );

    @Effect()
    updateRecurringInvoice$ = this.actions$.pipe(
        ofType<UpdateRecurringInvoice>(FinanceActionTypes.UPDATE_RECURRING_INVOICE),
        switchMap(({payload}) =>
            this.rixService.updateRecurringInvoice(payload!).pipe(
                map((ok) => ({ok, invoice: payload})),
                catchError((err) => of({ok: false, invoice: payload}))
            )
        ),
        filter((result) => result.ok),
        tap(({invoice}) => {
            this.router.navigate(['/sales/invoices/recurring']);
        }),
        map((r) => {
            const result: {ok: boolean; invoice: IRecurringInvoice} = r as {ok: boolean; invoice: IRecurringInvoice};
            return new UpdateRecurringInvoiceSuccess(result);
        })
    );

    @Effect()
    deleteRecurringInvoice$ = this.actions$.pipe(
        ofType<DeleteRecurringInvoice>(FinanceActionTypes.DELETE_RECURRING_INVOICE),
        switchMap(({payload}) => {
            return this.rixService.deleteRecurringInvoice(payload!).pipe(
                mapTo(payload),
                catchError((err) => of(null))
            );
        }),
        filter((id) => !!id),
        tap(() => {
            this.ntfs.warn(`Recurring Invoice deleted`);
            this.router.navigate(['/sales/invoices/recurring']);
        }),
        map((id) => new DeleteRecurringInvoiceSuccess(id!))
    );

    @Effect()
    fetchProducts$ = this.actions$.pipe(
        ofType<FetchProducts>(FinanceActionTypes.FETCH_PRODUCTS),
        switchMap(() => this.store.pipe(select(selectProducts), first())),
        switchMap((px) => (px.length === 0 ? this.productsSrv.getProducts() : of(null))),
        filter((px) => !!px),
        map((px) => new FetchProductsSuccess(px!))
    );

    @Effect()
    fetchProductsMust$ = this.actions$.pipe(
        ofType<FetchProductsMust>(FinanceActionTypes.FETCH_PRODUCTS_MUST),
        switchMap(() => this.productsSrv.getProducts()),
        filter((px) => !!px),
        map((px) => new FetchProductsSuccess(px))
    );

    @Effect()
    createProduct$ = this.actions$.pipe(
        ofType<CreateProduct>(FinanceActionTypes.CREATE_PRODUCT),
        switchMap(({payload}) => this.productsSrv.createProduct(payload!).pipe(catchError((err) => of(null)))),
        filter((pt) => !!pt),
        tap((pt) => {
            if (pt) {
                this.ntfs.info(`Product "${pt.name}" created`);
            }
        }),
        map((pt) => new CreateProductSuccess(pt!))
    );

    @Effect()
    updateProduct$ = this.actions$.pipe(
        ofType<UpdateProduct>(FinanceActionTypes.UPDATE_PRODUCT),
        switchMap(({payload}) =>
            this.productsSrv.updateProduct(payload!).pipe(
                map((ok) => ({ok, product: payload})),
                catchError((err) => of({ok: false, product: payload}))
            )
        ),
        filter((result) => result.ok),
        tap(({product}) => {
            this.ntfs.info(`Product "${product!.name}" updated`);
            this.router.navigate(['/company/finance/products']);
        }),
        map((r) => {
            const result: {ok: boolean; product: IProduct} = r as {ok: boolean; product: IProduct};
            return new UpdateProductSuccess(result);
        })
    );

    @Effect()
    deleteProduct$ = this.actions$.pipe(
        ofType<DeleteProduct>(FinanceActionTypes.DELETE_PRODUCT),
        switchMap(({payload}) => {
            return this.productsSrv.deleteProduct(payload!).pipe(
                mapTo(payload),
                catchError((err) => of(null))
            );
        }),
        filter((id) => !!id),
        tap(() => {
            this.ntfs.warn(`Product deleted`);
            this.router.navigate(['/company/finance/products']);
        }),
        map((id) => new DeleteProductSuccess(id!))
    );

    @Effect()
    fetchLedgerAccounts$ = this.actions$.pipe(
        ofType<FetchLedgerAccounts>(FinanceActionTypes.FETCH_LEDGER_ACCOUNTS),
        switchMap(() => this.store.pipe(select(selectLedgerAccounts), first())),
        switchMap((acx) => (acx.length === 0 ? this.ledgerAccSrv.getLedgerAccounts() : of(null))),
        filter((acx) => !!acx),
        map((acx) => new FetchLedgerAccountsSuccess(acx!))
    );

    @Effect()
    fetchLedgerAccountsMust$ = this.actions$.pipe(
        ofType<FetchLedgerAccountsMust>(FinanceActionTypes.FETCH_LEDGER_ACCOUNTS_MUST),
        switchMap(() => this.ledgerAccSrv.getLedgerAccounts()),
        filter((acx) => !!acx),
        map((acx) => new FetchLedgerAccountsSuccess(acx))
    );

    @Effect()
    createLedgerAccount$ = this.actions$.pipe(
        ofType<CreateLedgerAccount>(FinanceActionTypes.CREATE_LEDGER_ACCOUNT),
        switchMap(({payload}) => this.ledgerAccSrv.createLedgerAccount(payload!).pipe(catchError((err) => of(null)))),
        filter((act) => !!act),
        tap((act) => {
            if (act) {
                this.ntfs.info(`Ledger Account "${act.name}" created`);
            }
        }),
        map((act) => {
            this.store.dispatch(new FetchLedgerAccountsMust());
            return new CreateLedgerAccountSuccess(act!);
        })
    );

    @Effect()
    updateLedgerAccount$ = this.actions$.pipe(
        ofType<UpdateLedgerAccount>(FinanceActionTypes.UPDATE_LEDGER_ACCOUNT),
        switchMap(({payload}) =>
            this.ledgerAccSrv.updateLedgerAccount(payload!).pipe(
                map((ok) => ({ok, account: payload})),
                catchError((err) => of({ok: false, account: payload}))
            )
        ),
        filter((result) => result.ok),
        tap(({account}) => {
            this.ntfs.info(`Ledger Account "${account!.name}" updated`);
            this.router.navigate(['/company/finance/chartofaccounts']);
        }),
        map((r) => {
            const result: {ok: boolean; account: ILedgerAccount} = r as {ok: boolean; account: ILedgerAccount};
            this.store.dispatch(new FetchLedgerAccountsMust());
            return new UpdateLedgerAccountSuccess(result);
        })
    );

    @Effect()
    deleteLedgerAccount$ = this.actions$.pipe(
        ofType<DeleteLedgerAccount>(FinanceActionTypes.DELETE_LEDGER_ACCOUNT),
        switchMap(({payload}) => {
            return this.ledgerAccSrv.deleteLedgerAccount(payload!).pipe(
                mapTo(payload),
                catchError((err) => of(null))
            );
        }),
        filter((id) => !!id),
        tap(() => {
            this.ntfs.warn(`Ledger Account deleted`);
            if (this.router.url.includes('/chartofaccounts')) {
                this.router.navigate(['/company/finance/chartofaccounts']);
            }
        }),
        map((id) => {
            this.store.dispatch(new FetchLedgerAccountsMust());
            return new DeleteLedgerAccountSuccess(id!);
        })
    );

    @Effect()
    createOpeningBalance$ = this.actions$.pipe(
        ofType<CreateOpeningBalance>(FinanceActionTypes.CREATE_OPENING_BALANCE),
        switchMap(({payload}) => this.ledgerAccSrv.createOpeningBalance(payload!).pipe(catchError((err) => of(null)))),
        filter((act) => !!act),
        tap((act) => {
            this.ntfs.info(`Opening Balance created`);
            this.router.navigate(['/company/finance/openingbalances']);
            this.store.dispatch(new FetchLedgerAccountsMust());
        }),
        map((act) => new CreateOpeningBalanceSuccess(act))
    );

    @Effect()
    updateOpeningBalance$ = this.actions$.pipe(
        ofType<UpdateOpeningBalance>(FinanceActionTypes.UPDATE_OPENING_BALANCE),
        switchMap(({payload}) =>
            this.ledgerAccSrv.updateOpeningBalance(payload!).pipe(
                map((ok) => ({ok, account: payload})),
                catchError((err) => of({ok: false, account: payload}))
            )
        ),
        filter((result) => result.ok),
        tap(({account}) => {
            this.ntfs.info(`Opening Balance updated`);
            this.router.navigate(['/company/finance/openingbalances']);
        }),
        map((result) => new UpdateOpeningBalanceSuccess(result))
    );

    @Effect()
    deleteOpeningBalance$ = this.actions$.pipe(
        ofType<DeleteOpeningBalance>(FinanceActionTypes.DELETE_OPENING_BALANCE),
        switchMap(({}) => {
            return this.ledgerAccSrv.deleteOpeningBalance().pipe(catchError((err) => of(null)));
        }),
        filter((id) => !!id),
        tap(() => {
            this.ntfs.warn(`Opening Balance deleted`);
            this.router.navigate(['/company/finance']);
        }),
        map((id) => new DeleteOpeningBalanceSuccess())
    );
}
