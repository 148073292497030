import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {CompanyGatewayService} from '../../../../../../../services/api/finance/company-gateway.service';
import {takeUntil} from 'rxjs/operators';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector: 'muhnee-persona-redirect-dialog',
    templateUrl: './muhnee-persona-redirect-dialog.component.html',
    styleUrls: ['./muhnee-persona-redirect-dialog.component.scss']
})
export class MuhneePersonaRedirectDialogComponent implements OnInit, AfterViewInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    gateway: IPaymentGateway | undefined;
    link: string | null = null;
    sanitizedUrl: SafeResourceUrl | null = null;
    iframeInit: boolean = false;

    listener: any;

    constructor(
        private companyGatewayService: CompanyGatewayService,
        public sanitizer: DomSanitizer,
        public dialogRef: MatDialogRef<MuhneePersonaRedirectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {gateway: IPaymentGateway; link: string}
    ) {
        this.listener = (e: MessageEvent<any>) => {
            const eventData = JSON.parse(e.data);
            // possible events
            // {"eventType":"started","data":{"message":"Verification Started."}}
            // {"eventType":"cancel","data":{"message":"Canceled."}}
            // {"eventType":"error","data":{"message":error.message}}
            // {"eventType":"success","data":{"message":"Completed",”inquiryId”:”${inquiryId}”}}
            // {"eventType":"success","data":{"message":"already completed",”inquiryId”:”${inquiryId}”}}
            switch (eventData.eventType) {
                case 'cancel': {
                    this.iframeInit = false;
                    this.dialogRef.close({isError: false, success: false, message: eventData.message});
                    break;
                }
                case 'success': {
                    this.iframeInit = false;
                    this.dialogRef.close({isError: false, success: true, message: eventData.message});
                    break;
                }
                case 'error': {
                    this.iframeInit = false;
                    this.dialogRef.close({isError: true, success: false, message: eventData.message});
                    break;
                }
            }
        };
    }

    ngOnInit() {
        this.gateway = this.data.gateway;
        if (this.data.link) {
            this.link = this.data.link;
            this.sanitize(this.link);
            this.iframeInit = true;
        } else if (this.gateway && this.gateway.payment_gateway_id && this.gateway.customer_ref) {
            this.companyGatewayService
                .getVerificationLink(this.gateway.payment_gateway_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response.link) {
                        this.link = response.link;
                        this.sanitize(this.link);
                        this.iframeInit = true;
                    } else if (response.link === null) {
                        this.dialogRef.close({isError: true, success: false, message: `Already verified.`});
                    }
                });
        }
    }

    ngAfterViewInit() {
        window.addEventListener('message', this.listener);
    }

    sanitize(url: string | null) {
        if (url) {
            this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        window.removeEventListener('message', this.listener);
    }
}
