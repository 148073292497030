<section class="bg-white">
    <!------- Header ------->
    <header class="d-flex align-items-center px-3 list-header">
        <h3 class="m-0 d-flex align-items-end">Deposit Requests</h3>

        <i class="sp-spacer"></i>

        <mat-form-field class="mt-2 ml-2" floatLabel="never">
            <mat-label>Status filter</mat-label>
            <mat-select [formControl]="statusFilter" multiple>
                <mat-option
                    *ngFor="let status of statusTypes"
                    [value]="status"
                    (onSelectionChange)="changeStatusFilter($event)"
                >
                    {{ status | fromSnakeCase }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="mt-2 ml-2" floatLabel="never">
            <mat-label>Type filter</mat-label>
            <mat-select [formControl]="typeFilter">
                <mat-option *ngFor="let type of typeFilters" [value]="type" (click)="resetData()">
                    <span *ngIf="type !== 'all'">{{ type | fromSnakeCase }}</span>
                    <span *ngIf="type === 'all'">Outgoing, Incoming</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </header>

    <!------- List of requests ------->
    <div class="table-background">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Status</th>
                <td mat-cell *matCellDef="let element" class="w-10">
                    <span [ngStyle]="{color: statusColor[element.status]}">
                        <span *ngIf="!(element.type === 'incoming' && element.status === 'draft')">
                            {{ element.status | fromSnakeCase }}
                        </span>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Type</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.type | fromSnakeCase }}
                </td>
            </ng-container>

            <ng-container matColumnDef="contact_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20">Contact</th>
                <td mat-cell *matCellDef="let element" class="w-20 px-1">
                    <span *ngIf="element.type === 'outgoing'">{{ element.receiver_contact_name }}</span>
                    <span *ngIf="element.type === 'incoming'">{{
                        element.sender_contact_name || element.sender_company_title
                    }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="deal_address">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25">Deal</th>
                <td mat-cell *matCellDef="let element" class="w-25 px-1">
                    {{ element.deal_address }}
                </td>
            </ng-container>

            <ng-container matColumnDef="due_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Due Date</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.due_date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="request_amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Total Amount</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1 text-right">
                    {{ element.request_amount | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="request_balance">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Balance</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1 text-right">
                    {{ element.request_balance | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="w-5">Action</th>
                <td mat-cell *matCellDef="let element" class="w-5">
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="appMenu"
                        (click)="$event.stopPropagation()"
                        *ngIf="element.type === 'outgoing'"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #appMenu="matMenu">
                        <button mat-menu-item (click)="resendRequest(element)">
                            Resend Request
                            <mat-icon>mail</mat-icon>
                        </button>
                        <button mat-menu-item (click)="deleteRequest(element)">Delete Request</button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [routerLink]="['/escrowdeposit/', row.deposit_request_id]"
                class="zp-table-row"
            ></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted p-3 text-center">
            There are no deposit requests.
        </div>
    </div>
</section>
