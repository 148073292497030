import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
    selector: 'reports-preview-detailed',
    templateUrl: './detailed.component.html',
    styleUrls: ['./detailed.component.scss']
})
export class DetailedPreviewComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() reportData: {[key: string]: any} = {};
    @Input()
    set report(data: any) {
        this._report = data;
    }
    get report() {
        return this._report;
    }

    private _report: any = null;
    public isExpanded: Boolean = false;
    public expandTooltip: String = 'Expand All';

    ngOnInit(): void {}

    toggleSection(item: any): void {
        item.is_expanded = !item.is_expanded;
    }

    toggleExpand() {
        this.reportData = this.reportData.map((contact: any) => ({
            ...contact,
            is_expanded: !this.isExpanded
        }));
        this.isExpanded = !this.isExpanded;
        this.expandTooltip = this.isExpanded ? 'Collapse All' : 'Expand All';
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
