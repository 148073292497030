import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {filter, takeUntil} from 'rxjs/operators';
import {firstValueFrom, Subject} from 'rxjs';
import {IContact, IPaymentGateway, IPaymentMethod, IZfTransferPreference} from '@cyberco-nodejs/zipi-typings';
import {CompanyGatewayService} from '../../../../services/api/finance/company-gateway.service';
import {FeatureFlagsService} from '../../../feature-flags/feature-flags.service';
import {ZipiFinancialIframeDialogComponent} from '../../../account-info/company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-financial-iframe-dialog/zipi-financial-iframe-dialog.component';
import {MarketplaceSource} from '../../../account-info/marketplace/marketplace.source';
import {MatDialog} from '@angular/material/dialog';
import {NotificationsServiceZipi} from '../../../notifications/notifications.service';
import {ManageMyPaymentMethodsAddedByContactDialogComponent} from '../../contact-dialogs/manage-my-payment-methods-added-by-contact-dialog/manage-my-payment-methods-added-by-contact-dialog.component';
import {ConfirmComponent} from '../../../../layouts/confirm/confirm.component';
import {ShipperContactsService} from '../../../../services/api/shipper.contacts.service';
import {IZipiFinancialIframeDialogData} from '../../../../typings/zipi-financial-iframe';
import {CompanyPaymentMethodsService} from '../../../../services/api/finance/company-payment-methods.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {RECEIVER_VELOCITY_TYPES, SENDER_VELOCITY_TYPES} from '../../../../local-typings';
import {SelectOwnGatewayForReceiveOnlyDialogComponent} from '../../contact-dialogs/select-own-gateway-for-receive-only-dialog/select-own-gateway-for-receive-only-dialog.component';

@Component({
    selector: 'app-payment-methods-tab',
    templateUrl: './payment-methods-tab.component.html',
    styleUrls: ['./payment-methods-tab.component.scss']
})
export class PaymentMethodsTabComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() contact: IContact | undefined;
    @Input() mode: 'view' | 'edit' = 'view';
    @Input() isCompanyZipiFinancialActivated: boolean = false;

    contactPartnerCompanyZipiFinancialSettings:
        | {
              target_contact_id: string;
              customer_ref: string;
              target_contact_metatypes: Array<string>;
              feeds_automation: boolean;
              cards_acquiring: boolean;
          }
        | undefined;

    isTransferAvailable: boolean = false;
    isCardAcceptable: boolean = true;

    contactZipiFinancialReceiveGateway: IPaymentGateway | null = null;
    contactPayloadReceiveGateway: IPaymentGateway | null = null;
    contactPayloadCardGateway: IPaymentGateway | null = null;

    companyGateways: IPaymentGateway[] = [];
    isMuhneeAvailable: boolean = false;
    isPayloadAvailable: boolean = false;

    isCardGatewayLoaded: boolean = false;
    isBankGatewayLoaded: boolean = false;
    isSettingsLoaded: boolean = false;

    sendingTransferPreference: IZfTransferPreference | undefined;
    receivingTransferPreference: IZfTransferPreference | undefined;
    sendingTransferPreferenceForm: UntypedFormGroup;
    receivingTransferPreferenceForm: UntypedFormGroup;

    senderVelocityTypes = SENDER_VELOCITY_TYPES;
    receiverVelocityTypes = RECEIVER_VELOCITY_TYPES;

    isSendingOverrideEnabled: boolean = false;
    isReceivingOverrideEnabled: boolean = false;

    isReceiveOnlyFeatureFlagEnabled: boolean = false;
    isVelocityFeatureFlagEnabled: boolean = false;
    isPayloadCoFeatureFlagEnabled: boolean = false;

    constructor(
        private companyGatewayService: CompanyGatewayService,
        private companyPaymentMethodsService: CompanyPaymentMethodsService,
        protected featureFlagsService: FeatureFlagsService,
        protected marketplaceSource: MarketplaceSource,
        public dialog: MatDialog,
        private notificationServiceZipi: NotificationsServiceZipi,
        private contacService: ShipperContactsService,
        private fb: UntypedFormBuilder
    ) {
        this.sendingTransferPreferenceForm = this.fb.group({
            velocity: ['no_fee', [Validators.required]],
            is_downgrade_restricted: [false, []],
            force_me_as_trust_fee_payer: [false, []]
        });
        this.receivingTransferPreferenceForm = this.fb.group({
            velocity: ['standard', [Validators.required]],
            is_downgrade_restricted: [false, []],
            force_me_as_trust_fee_payer: [false, []]
        });
    }

    ngOnInit(): void {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.isReceiveOnlyFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:receive_only_accounts'
                );
                this.isVelocityFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:velocity'
                );
                this.isPayloadCoFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:payload_co'
                );
            });

        this.getCompanyGateways();

        this.getContactReceiveOnlyGateway();
        this.getContactPartnerCompanyZipiFinancialSettings();
        this.getContactVelocity();
    }

    getCompanyGateways() {
        this.companyGatewayService
            .getCompanyGatewaysByTypes(['zipi_financial_business'], null)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((gateways) => {
                if (gateways && gateways.length > 0) {
                    this.companyGateways = gateways;
                    this.isMuhneeAvailable = gateways.some((gateway) => gateway.driver_type === 'muhnee');
                    this.isPayloadAvailable = gateways.some((gateway) => gateway.driver_type === 'payload');
                }
            });
    }

    getContactReceiveOnlyGateway() {
        if (this.contact && this.contact.contact_id) {
            this.companyGatewayService
                .getContactZipiFinancialReceiveOnlyGateway(this.contact.contact_id, 'muhnee', 'all')
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateway) => {
                    this.contactZipiFinancialReceiveGateway = gateway;
                    this.isBankGatewayLoaded = true;
                });
            this.companyGatewayService
                .getContactZipiFinancialReceiveOnlyGateway(this.contact.contact_id, 'payload', 'bank')
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateway) => {
                    this.contactPayloadReceiveGateway = gateway;
                    this.isBankGatewayLoaded = true;
                });
            this.companyGatewayService
                .getContactZipiFinancialReceiveOnlyGateway(this.contact.contact_id, 'payload', 'card')
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateway) => {
                    this.contactPayloadCardGateway = gateway;
                    this.isCardGatewayLoaded = true;
                });
        }
    }

    getContactPartnerCompanyZipiFinancialSettings() {
        if (this.contact && this.contact.contact_id && this.contact.partner__company_fk_id) {
            this.contacService
                .getContactPartnerCompanyZipiFinancialSettingsByContactId(this.contact.contact_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((settings) => {
                    this.contactPartnerCompanyZipiFinancialSettings = settings;
                    this.isSettingsLoaded = true;
                });
        }
    }
    getContactVelocity() {
        if (this.contact && this.contact.contact_id) {
            this.contacService
                .getContactTransferPreference(this.contact.contact_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((preferences) => {
                    if (preferences) {
                        this.sendingTransferPreference = preferences.outgoing;
                        this.receivingTransferPreference = preferences.incoming;
                        if (preferences.outgoing) {
                            this.sendingTransferPreferenceForm.controls.velocity.setValue(
                                preferences.outgoing.velocity
                            );
                            this.sendingTransferPreferenceForm.controls.is_downgrade_restricted.setValue(
                                preferences.outgoing.restrict_auto_downgrade
                            );
                            this.sendingTransferPreferenceForm.controls.force_me_as_trust_fee_payer.setValue(
                                preferences.outgoing.force_me_as_trust_fee_payer
                            );
                            this.isSendingOverrideEnabled = true;
                        } else {
                            this.isSendingOverrideEnabled = false;
                        }
                        if (preferences.incoming) {
                            this.receivingTransferPreferenceForm.controls.velocity.setValue(preferences.incoming);
                            this.receivingTransferPreferenceForm.controls.is_downgrade_restricted.setValue(
                                preferences.incoming.restrict_auto_downgrade
                            );
                            this.receivingTransferPreferenceForm.controls.force_me_as_trust_fee_payer.setValue(
                                preferences.incoming.force_me_as_trust_fee_payer
                            );
                            this.isReceivingOverrideEnabled = true;
                        } else {
                            this.isReceivingOverrideEnabled = false;
                        }
                    }
                });
        }
    }

    commissionPayerChanged($event: MatSlideToggleChange, itemGroup: any) {
        if ($event.checked) {
            itemGroup.controls.velocity.setValue('standard');
        } else {
            itemGroup.controls.velocity.setValue('no_fee');
        }
    }

    async deletePaymentMethod(method: IPaymentMethod) {
        const confirmDialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            minHeight: 160,
            data: {
                title: `Deleting ${method.type === 'zipi_financial_credit_card' ? 'Credit Card' : 'Bank Account'}`,
                message: `${method.type === 'zipi_financial_credit_card' ? 'Credit Card' : 'Bank Account'} will be deleted`,
                buttonOkMessage: `Continue`
            }
        });

        confirmDialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((confirmed: boolean) => {
                if (confirmed && method && method.payment_method_id) {
                    this.companyPaymentMethodsService
                        .deleteContactPaymentMethod(method.payment_method_id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((res) => {
                            this.getContactReceiveOnlyGateway();
                        });
                }
            });
    }

    async setupZipiFinancialForContact(methodType: 'contact_bank' | 'contact_card', driverType: 'muhnee' | 'payload') {
        let customerRef = null;
        const addons = await firstValueFrom(this.marketplaceSource.addonsChangeEvent);
        if (!addons) {
            console.error('Cannot find addons');
            return;
        }
        const zipiFinancialInstance = addons.filter(
            (instance) => instance.addon && instance.addon.slug === 'zipi_financial_integration'
        )[0];
        if (!zipiFinancialInstance) {
            console.error('Cannot find addon instance');
            return;
        }
        customerRef = zipiFinancialInstance.settings?.external_customer_ref;
        if (!customerRef) {
            console.error('Cannot find reference to Customer.');
            return;
        }

        let currentOperatingGateway = null;

        const gateway = await firstValueFrom(
            this.companyGatewayService.getContactZipiFinancialReceiveOnlyGateway(
                this.contact?.contact_id as number,
                driverType,
                'all'
            )
        );

        if (driverType === 'muhnee') {
            const operatingGateways: IPaymentGateway[] = await firstValueFrom(
                this.companyGatewayService.getCompanyGatewaysByTypes(['zipi_financial_business'], null)
            );
            const muhneeGateways = operatingGateways.filter((gateway) => gateway.driver_type === 'muhnee');
            if (!muhneeGateways || muhneeGateways.length === 0) {
                return this.notificationServiceZipi.addError(`First you need to activate Money Transfer Service.`);
            } else if (muhneeGateways.length === 1) {
                currentOperatingGateway = muhneeGateways[0];
            } else {
                const selectGatewayDialogRef = this.dialog.open(SelectOwnGatewayForReceiveOnlyDialogComponent, {
                    maxHeight: '80vh',
                    width: '650px',
                    data: {gateways: muhneeGateways}
                });
                const res = await firstValueFrom(selectGatewayDialogRef.afterClosed());
                if (res) {
                    currentOperatingGateway = res;
                } else {
                    return null;
                }
            }
        }

        let mainPerson = null;
        if (this.contact && this.contact.contact_persons && this.contact.contact_persons.length > 0) {
            mainPerson = this.contact.contact_persons.find((person) => person.type === 'main_person');
        }
        const contactData = {
            company_name: this.contact?.company_name ? this.contact?.company_name : '',
            email: mainPerson && mainPerson.email ? mainPerson.email : '',
            first_name: mainPerson && mainPerson.first_name ? mainPerson.first_name : '',
            last_name: mainPerson && mainPerson.last_name ? mainPerson.last_name : '',
            nick_name: mainPerson && mainPerson.preferred_name ? mainPerson.preferred_name : ''
        };
        const dialogRef = this.dialog.open<ZipiFinancialIframeDialogComponent, IZipiFinancialIframeDialogData>(
            ZipiFinancialIframeDialogComponent,
            {
                disableClose: true,
                maxHeight: '80vh',
                width: '650px',
                panelClass: 'custom-dialog-container',
                data: {
                    contactId: this.contact?.contact_id ? this.contact?.contact_id : null,
                    gateway: gateway,
                    ownGateway: currentOperatingGateway,
                    driverType: driverType,
                    paymentMethod: null,
                    methodType: methodType,
                    accessMethod: 'internal',
                    isUniversal: true,
                    customerId: customerRef,
                    prefillData: contactData,
                    creationSource: 'current_company',
                    storeAccountMethod: 'required'
                }
            }
        );

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result.isError) {
                    this.notificationServiceZipi.addError(result.message);
                }
                this.getContactReceiveOnlyGateway();
            });
    }

    saveTransferPreference(type: 'when_sending' | 'when_receiving') {
        const currentPreferenceData =
            type === 'when_sending'
                ? this.sendingTransferPreferenceForm.getRawValue()
                : this.receivingTransferPreferenceForm.getRawValue();
        if (this.contact && this.contact.contact_id) {
            const data = {
                velocity: currentPreferenceData.velocity,
                type: type,
                auto_downgrade: currentPreferenceData.is_downgrade_restricted
            };
            this.contacService
                .saveContactTransferPreference(this.contact.contact_id, data)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((newPreference) => {
                    if (type === 'when_sending') {
                        this.sendingTransferPreference = newPreference;
                        this.sendingTransferPreferenceForm.controls.velocity.setValue(newPreference.velocity);
                        this.sendingTransferPreferenceForm.controls.is_downgrade_restricted.setValue(
                            newPreference.restrict_auto_downgrade
                        );
                    } else {
                        this.receivingTransferPreference = newPreference;
                        this.receivingTransferPreferenceForm.controls.velocity.setValue(newPreference.velocity);
                        this.receivingTransferPreferenceForm.controls.is_downgrade_restricted.setValue(
                            newPreference.restrict_auto_downgrade
                        );
                    }
                });
        }
    }

    removeContactTransferPreference(type: 'when_sending' | 'when_receiving') {
        if (this.contact && this.contact.contact_id) {
            this.contacService
                .removeContactTransferPreference(this.contact.contact_id, {type})
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    this.getContactVelocity();
                });
        }
    }

    openManage() {
        const dialogRef = this.dialog.open(ManageMyPaymentMethodsAddedByContactDialogComponent, {
            position: {
                top: '10px',
                right: '10px'
            },
            height: '98%',
            width: '100vw',
            maxWidth: '98vw',
            panelClass: 'full-screen-modal',
            data: {
                contact: this.contact,
                contact_settings: this.contactPartnerCompanyZipiFinancialSettings
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {});
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
