<mat-card class="compact-card mt-3">
    <mat-card-header class="header deal-header">
        <mat-card-title class="deal-header-title"> Sales Entities Configuration </mat-card-title>
    </mat-card-header>

    <mat-card-content style="margin-left: 16px">
        <!------- Entity Picker ------->
        <div class="d-flex flex-column" style="width: 25%">
            <app-company-compensation-combined-picker
                #entityPicker
                [title]="'Add Sales Entity'"
                [availableTypes]="['company_group', 'individual']"
                [nodesFA]="selectedSalesForm"
                [initialProfileIds]="initialProfileIds"
                [initialGroupIds]="initialGroupIds"
                [initialContactIds]="initialContactIds"
                [bold_style]="false"
                [displayMode]="'double-line'"
                [positionMode]="'none'"
                [isContactNeeded]="true"
                [needContactName]="true"
            ></app-company-compensation-combined-picker>
        </div>

        <!------- Errors ------->
        <div
            class="warning-block p-2 mb-3 w-100"
            *ngIf="updatedCustomEntities.length && validation(VALIDATION_TYPES.sum)"
        >
            <span class="font-weight-bold mr-2">Something is not quite right:</span>
            <span>Total % of Deal Split must equal 100%</span>
        </div>
        <div
            class="warning-block p-2 mb-3 w-100"
            *ngIf="updatedCustomEntities.length && validation(VALIDATION_TYPES.salesVolumeSum)"
        >
            <span class="font-weight-bold mr-2">Something is not quite right:</span>
            <span>% of Sales Volume must equal 100%</span>
        </div>
        <div
            class="warning-block p-2 mb-3 w-100"
            *ngIf="updatedCustomEntities.length && validation(VALIDATION_TYPES.sideCountSum)"
        >
            <span class="font-weight-bold mr-2">Something is not quite right:</span>
            <span>Total Side Count must equal 1.00</span>
        </div>
        <div
            class="warning-block p-2 mb-3 w-100"
            *ngIf="updatedCustomEntities.length && validation(VALIDATION_TYPES.negativeNumbers)"
        >
            <span class="font-weight-bold mr-2">Something is not quite right:</span>
            <span>Values cannot be negative</span>
        </div>

        <!------- Sales Entities ------->
        <div *ngFor="let item of updatedCustomEntities; let idx = index" class="d-flex flex-column w-100">
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-self-center">
                    <!------- Icon ------->
                    <div class="mr-2 d-flex align-items-center" style="width: 20px">
                        <mat-icon *ngIf="item.primary">grade</mat-icon>
                    </div>
                    <!------- Name ------->
                    <span *ngIf="!item.primary">{{ item.label }}</span>
                    <b *ngIf="item.primary">{{ item.label }}</b>
                </div>
                <div *ngIf="item.role === 'agent'" style="display: flex; align-items: center">
                    <!------- % of Deal, close icon ------->
                    <div class="mr-3">
                        <mat-form-field
                            style="width: 115px"
                            floatLabel="always"
                            class="{{
                                item.percent < 0 || (updatedCustomEntities.length && validation(VALIDATION_TYPES.sum))
                                    ? 'mat-form-field-invalid'
                                    : ''
                            }}"
                            *ngIf="
                                item.financialTransferFG.controls.value_type.value !==
                                FINANCIAL_TRANSFER_ENTITY.value_type_SET.flat_fee
                            "
                            (click)="$event.stopPropagation()"
                        >
                            <input
                                matInput
                                #dealSplitInputs
                                [ngModel]="item.percent"
                                [disabled]="item.primary"
                                placeholder="% of Deal Split"
                                name="value"
                                class="sales-entity-input"
                                [ngStyle]="
                                    item.percent < 0 ||
                                    (updatedCustomEntities.length && validation(VALIDATION_TYPES.sum))
                                        ? {color: 'red'}
                                        : {}
                                "
                                [maskito]="numberMask"
                                (input)="recalculateSumPrimaryAgent(idx, $event)"
                                type="text"
                            />
                        </mat-form-field>
                        <span class="ml-1" [ngStyle]="item.primary ? {opacity: 0.5} : {}">%</span>
                    </div>
                    <!------- Sales Volume ------->
                    <div class="mr-3" *ngIf="showSideCountAndSalesVolumeFlag">
                        <mat-form-field
                            style="width: 115px"
                            floatLabel="always"
                            class="{{
                                item.sales_volume_percent < 0 || validation(VALIDATION_TYPES.salesVolumeSum)
                                    ? 'mat-form-field-invalid'
                                    : ''
                            }}"
                            (click)="$event.stopPropagation()"
                        >
                            <input
                                matInput
                                #salesVolumeInputs
                                [ngModel]="item.sales_volume_percent"
                                [disabled]="item.primary"
                                placeholder="Sales Volume"
                                name="value"
                                class="sales-entity-input"
                                [ngStyle]="
                                    item.sales_volume_percent < 0 || validation(VALIDATION_TYPES.salesVolumeSum)
                                        ? {color: 'red'}
                                        : {}
                                "
                                [maskito]="numberMask"
                                (input)="recalculateSumSalesVolume(idx, $event)"
                                type="text"
                            />
                        </mat-form-field>
                        <span class="ml-1" [ngStyle]="item.primary ? {opacity: 0.5} : {}">%</span>
                    </div>
                    <!------- Side Count ------->
                    <div class="mr-3" *ngIf="showSideCountAndSalesVolumeFlag">
                        <!--view-->
                        <div
                            style="width: 115px"
                            class="fake-input"
                            *ngIf="!item.primary"
                            [ngStyle]="!!hideSideCountInput[idx] ? {} : {display: 'none'}"
                            (click)="toggleSideCount(idx)"
                        >
                            {{ item.side_count | number: '1.2-2' }}
                        </div>
                        <!--edit-->
                        <mat-form-field
                            style="width: 115px"
                            class="{{ item.side_count < 0 || validateSideCountSum() ? 'mat-form-field-invalid' : '' }}"
                            (click)="$event.stopPropagation()"
                            *ngIf="!item.primary"
                            [ngStyle]="!hideSideCountInput[idx] ? {} : {display: 'none'}"
                        >
                            <input
                                matInput
                                #sideCountInputs
                                [ngModel]="item.side_count"
                                [disabled]="item.primary"
                                class="sales-entity-input"
                                [ngStyle]="item.side_count < 0 || validateSideCountSum() ? {color: 'red'} : {}"
                                placeholder="Side Count"
                                name="side_count"
                                [maskito]="numberMask"
                                (input)="recalculateSumSideCount(idx, $event)"
                                (blur)="onBlurSideCount(idx)"
                                type="text"
                            />
                        </mat-form-field>
                        <!--show primary-->
                        <mat-form-field
                            style="width: 115px"
                            class="{{ item.side_count < 0 || validateSideCountSum() ? 'mat-form-field-invalid' : '' }}"
                            *ngIf="item.primary"
                            (click)="$event.stopPropagation()"
                            [ngStyle]="item.primary || !hideSideCountInput[idx] ? {} : {display: 'none'}"
                        >
                            <input
                                matInput
                                #sideCountInputs
                                [ngModel]="item.side_count | number: '1.2-2'"
                                [disabled]="item.primary"
                                class="sales-entity-input"
                                [ngStyle]="item.side_count < 0 || validateSideCountSum() ? {color: 'red'} : {}"
                                placeholder="Side Count"
                                name="side_count"
                                [maskito]="numberMask"
                                (input)="recalculateSumSideCount(idx, $event)"
                                (blur)="onBlurSideCount(idx)"
                                type="text"
                            />
                        </mat-form-field>
                    </div>

                    <!------- Make Primary ------->
                    <button
                        type="button"
                        mat-raised-button
                        class="mat-default ml-2"
                        *ngIf="!item.primary"
                        (click)="doMakePrimary(idx)"
                    >
                        Make Primary
                    </button>
                    <span *ngIf="item.primary" style="width: 126px"></span>

                    <!------- Delete button ------->
                    <button
                        mat-icon-button
                        class="mat-default ml-2"
                        [disabled]="item.primary"
                        matTooltip="Remove Entity"
                        (click)="removeOptionFromArray(idx)"
                    >
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
