import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {GenericFormArray, GenericFormGroup} from 'app/entites/generic.entity';
import {Subject} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';
import {FinancialTransferEntity} from '../../../account-info/compensation/models/financial-transfer.entity';
import {select, Store} from '@ngrx/store';
import {FetchContacts} from '../../../contacts/store/contacts.actions';
import {selectContacts} from '../../../contacts/store/contacts.selectors';
import {IContact, IProduct, IWildcard} from '@cyberco-nodejs/zipi-typings';
import {CreateProductDialogComponent} from '../../../finance/components/products/create-product-dialog/create-product-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ProductsService} from 'app/services/api/finance/products.service';
import {ChipNode} from '../../../account-info/compensation/models/chip-node';
import {ContactPartLink} from 'app/models/contact-part-link';
import {UntypedFormControl, Validators} from '@angular/forms';
import {FinancialElementModel} from '../../../account-info/compensation/models/financial-element.model';
import {Wildcard} from 'app/models/wildcard';
import {FetchProducts} from '../../../finance/store/finance.actions';
import {selectWildcards} from 'app/store/company-wide/company-wide.selectors';
import {FeatureFlagsService} from '../../../feature-flags/feature-flags.service';
import {cleanCurrencyString} from '../../../../utilities/maskito';

// const extractContactPersons = pipe(
//     _filter((c: IContact) => c.contact_persons && c.contact_persons.length > 0),
//     _filter((c: IContact) => c.owner_type === 'company'),
//     _map<any>((contact: IContact) => {
//         return contact.contact_persons.map(p => ({
//             contact_id: contact.id,
//             link_title: p.full_name + (!!contact.company_name && contact.company_name !== p.full_name ? `, ${contact.company_name}` : ''),
//             contact_person_fk_id: p.contact_person_id
//         }));
//     }),
//     flatten,
// );

@Component({
    selector: 'app-financial-transfer',
    styles: [
        `
            .flex-row {
                width: 100% !important;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }

            .full-width {
                width: 100% !important;
            }

            .half-width {
                width: 50%;
            }

            .spaced {
                /*margin-left: 5px;*/
                margin-right: 5px;
            }

            .label {
                font-weight: bolder;
                color: grey;
            }

            .remove-item {
                cursor: pointer;
            }

            .full-width {
                width: 100% !important;
            }

            .three-width {
                width: 30%;
            }

            .four-width {
                width: 25%;
                padding-right: 5px;
            }

            @media (max-width: 1100px) {
                .transfer-field {
                    width: 25% !important;
                }

                .options-wrapper {
                    flex-wrap: wrap;
                }
            }

            @media (min-width: 1100px) and (max-width: 1840px) {
                .transfer-field {
                    width: 25% !important;
                }

                .options-wrapper {
                    flex-wrap: wrap;
                }
            }

            .transfer-field {
                padding-right: 5px;
                width: fit-content;
            }

            /*::ng-deep .mat-form-field-infix {*/
            /*width: auto !important;*/
            /*}*/

            /*.transfer-field.amount {*/
            /*width: 100px;*/
            /*}*/

            .options-wrapper {
                /*justify-content: space-between;*/
                justify-content: flex-start;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
            }

            /*:host /deep/ .grey-out .mat-select-value-text{*/
            /*    color: grey !important;*/
            /*}*/

            /*:host /deep/ .fade-out .mat-select-value-text{*/
            /*opacity: 50% !important;*/
            /*}*/

            .invoice-link-popup {
                margin-bottom: 10px;
            }

            .invoice-link-popup:hover {
                cursor: pointer;
            }
            .disb-regular {
                position: absolute;
                bottom: 8px;
            }
            .disb-corrected {
                bottom: 38px !important;
            }
            .bold {
                font-weight: bold;
            }

            .rule-controls {
                display: flex;
                flex-direction: row;
                flex: 0 0 100%;
                justify-content: space-between;
            }

            button.apply-to-agents {
                border-radius: 0;
            }

            button.apply-to-agents ::ng-deep mat-icon {
                color: rgba(0, 0, 0, 0.54);
            }

            button.apply-to-agents.active ::ng-deep mat-icon {
                color: #000000;
            }

            .disbursement-block {
                display: flex;
                align-items: center;
                width: 50%;
            }
            .override-pay-at-closing {
                width: 25%;
                z-index: 2;
            }
        `
    ],
    template: `
        <ng-container
            class="d-flex flex-column"
            [class.grey-out]="
                _transferFG.controls.pay_at_escrow &&
                !_transferFG.controls.pay_at_escrow.value &&
                !_transferFG.controls.override?.controls?.pay_at_escrow?.value
            "
        >
            <div style="display: flex; flex-direction: column">
                <div *ngIf="showBoth" class="rule-controls">
                    <div
                        *ngIf="
                            _transferFG.controls.origin &&
                            _transferFG.controls.origin.value &&
                            _transferFG.controls.origin.value ===
                                FINANCIAL_ELEMENT_ENTITY.type_set.company_income_expense
                        "
                    >
                        <button
                            [ngClass]="{active: applyForPrimaryAgent}"
                            (click)="changeApplyToAgents('primary')"
                            [disabled]="disabled"
                            matTooltip="Rule will be applied to primary agent"
                            class="apply-to-agents"
                            mat-stroked-button
                        >
                            <mat-icon>grade</mat-icon>
                        </button>
                        <button
                            [ngClass]="{active: applyForOtherAgents}"
                            (click)="changeApplyToAgents('other')"
                            [disabled]="disabled"
                            matTooltip="Rule will be applied to all non-primary agents"
                            class="apply-to-agents"
                            mat-stroked-button
                        >
                            <mat-icon>account_circle</mat-icon>
                        </button>
                    </div>

                    <div *ngIf="!hideSkip" style="margin-bottom: 15px;">
                        <mat-slide-toggle
                            [formControl]="_transferFG.controls.can_be_skipped"
                            *ngIf="_transferFG.controls.can_be_skipped"
                        >
                            <span>Skip transfer, if compensation attributes can not be resolved</span>
                        </mat-slide-toggle>
                        <mat-icon
                            style="color: rgba(0, 0, 0, 0.54); margin-left: 5px; cursor: help;"
                            matTooltip="When this option is enabled, financial transfer will be skipped if attributes with the type 'contact' or 'numeric' can not be resolved,
                     otherwise, 'contact' attributes will be resolved to the Company Contact, 'numeric' attributes - to 0."
                        >
                            help_outline
                        </mat-icon>
                    </div>
                </div>

                <div class="options-wrapper">
                    <mat-form-field *ngIf="!hideLabel && _transferFG.controls.label" class="four-width transfer-field">
                        <input matInput placeholder="Label" [formControl]="_transferFG.controls.label" type="text" />
                    </mat-form-field>
                    <app-company-compensation-combined-picker
                        *ngIf="
                            !isExpense &&
                            !hideSender &&
                            _transferFG.controls.pay_at_escrow &&
                            _transferFG.controls.origin
                        "
                        [seemDisabled]="!_transferFG.controls.pay_at_escrow.value"
                        [disabled]="disabled"
                        [disableUpdate]="disabled"
                        [title]="senderLabel"
                        [availableTypes]="['contact', 'contact_wildcard', 'wildcard']"
                        class="four-width four-width"
                        [singleSelected]="true"
                        [bold_style]="false"
                        [allowDealWildcard]="
                            (_transferFG.controls.origin.value === FINANCIAL_ELEMENT_ENTITY.type_set.company ||
                                _transferFG.controls.origin.value === FINANCIAL_ELEMENT_ENTITY.type_set.referral ||
                                _transferFG.controls.origin.value === FINANCIAL_ELEMENT_ENTITY.type_set.sales) &&
                            origin !== _transferFG.controls.origin.value
                        "
                        [nodesFA]="senderApplyTo"
                        [lineClamp]="true"
                    ></app-company-compensation-combined-picker>
                    <app-company-compensation-combined-picker
                        *ngIf="
                            isExpense &&
                            !hideReceiver &&
                            _transferFG.controls.pay_at_escrow &&
                            _transferFG.controls.origin
                        "
                        [seemDisabled]="!_transferFG.controls.pay_at_escrow.value"
                        [disabled]="disabled"
                        [disableUpdate]="disabled"
                        [title]="receiverLabel"
                        [availableTypes]="['contact', 'contact_wildcard', 'wildcard']"
                        class="four-width four-width"
                        [singleSelected]="true"
                        [bold_style]="false"
                        [allowDealWildcard]="
                            (_transferFG.controls.origin.value === FINANCIAL_ELEMENT_ENTITY.type_set.company ||
                                _transferFG.controls.origin.value === FINANCIAL_ELEMENT_ENTITY.type_set.referral ||
                                _transferFG.controls.origin.value === FINANCIAL_ELEMENT_ENTITY.type_set.sales) &&
                            origin !== _transferFG.controls.origin.value
                        "
                        [nodesFA]="receiverApplyTo"
                        [lineClamp]="true"
                    ></app-company-compensation-combined-picker>

                    <app-value-picker
                        class="four-width"
                        *ngIf="
                            !hideAmount &&
                            _transferFG.controls.source_amount &&
                            _transferFG.controls.percent &&
                            _transferFG.controls.value_type &&
                            _transferFG.controls.value_wildcard_fk_id
                        "
                        [wildcardType]="$any(WILDCARD.type_SET.numeric)"
                        [valueFC]="_transferFG.controls.source_amount"
                        [percentFC]="_transferFG.controls.percent"
                        [valueTypeFC]="_transferFG.controls.value_type"
                        [valueWildcardIdFC]="_transferFG.controls.value_wildcard_fk_id"
                        [allowPercents]="isExpense || allowPercents"
                        [isPercent]="isPercent(_transferFG.controls.value_type.value)"
                        [disabled]="disabled"
                    ></app-value-picker>

                    <ng-container *ngIf="isExpense || showBoth">
                        <mat-form-field
                            class="four-width transfer-field"
                            *ngIf="
                                !hideValueType &&
                                _transferFG.controls.value_type &&
                                _transferFG.controls.origin &&
                                _transferFG.controls.origin_is_expense?.value
                            "
                            style="width: 135px;"
                        >
                            <mat-select
                                [class.grey-out]="
                                    _transferFG.controls.pay_at_escrow
                                        ? !_transferFG.controls.pay_at_escrow.value
                                        : false
                                "
                                [formControl]="_transferFG.controls.value_type"
                                name="referral_fee_type"
                            >
                                <mat-option
                                    [value]="valueType"
                                    *ngFor="let valueType of availableExpenseTypes[_transferFG.controls.origin.value]"
                                    [disabled]="
                                        !showBoth &&
                                        valueType === FINANCIAL_TRANSFER_ENTITY.value_type_SET.percent_of_entity_split
                                    "
                                >
                                    {{ FINANCIAL_TRANSFER_ENTITY.value_type_LABELS[valueType] }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field
                            class="four-width transfer-field"
                            *ngIf="
                                !hideValueType &&
                                _transferFG.controls.origin_is_expense &&
                                _transferFG.controls.value_type &&
                                !_transferFG.controls.origin_is_expense.value &&
                                _transferFG.controls.origin &&
                                _transferFG.controls.origin.value &&
                                availableIncomeTypes[_transferFG.controls.origin.value]
                            "
                            style="width: 135px;"
                        >
                            <mat-select
                                [class.grey-out]="
                                    _transferFG.controls.pay_at_escrow
                                        ? !_transferFG.controls.pay_at_escrow.value
                                        : false
                                "
                                [formControl]="_transferFG.controls.value_type"
                                name="referral_fee_type"
                            >
                                <mat-option
                                    [value]="valueType"
                                    *ngFor="let valueType of availableIncomeTypes[_transferFG.controls.origin.value]"
                                >
                                    {{ FINANCIAL_TRANSFER_ENTITY.value_type_LABELS[valueType] }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                    <app-product-service-selector
                        class="four-width"
                        *ngIf="!hideProduct"
                        [placeholder]="'Product/Service'"
                        (onProductSelectionChange)="selectItem($event)"
                        [disabled]="disabled"
                        [productServiceControl]="_transferFG.controls.product_fk_id"
                    >
                    </app-product-service-selector>

                    <ng-container *ngIf="(isExpense || showBoth) && !hidePayAtEscrowFlag">
                        <app-pay-at-closing-picker
                            style="padding-right: 5px;"
                            [class.override-pay-at-closing]="!showBoth"
                            [canOverride]="!showBoth"
                            [disabled]="_transferFG.disabled || disabled"
                            [financialTransferFG]="_transferFG"
                        >
                        </app-pay-at-closing-picker>
                    </ng-container>
                    <div
                        class="disbursement-block"
                        *ngIf="(!isExpense || showBoth) && !hideDisbursementInstructionsFlag"
                    >
                        <ng-container *ngIf="!showBoth && disbursementInstructionsFC">
                            <mat-slide-toggle
                                color="primary"
                                style="margin-right: 5px; z-index: 2;"
                                [ngClass]="{
                                    overridden: !!_transferFG.controls.override?.controls?.disbursement_instructions
                                }"
                                [checked]="
                                    (_transferFG.controls.override &&
                                    _transferFG.controls.override.controls &&
                                    !_transferFG.controls.override.controls.disbursement_instructions
                                        ? _transferFG.controls.disbursement_instructions?.value
                                        : _transferFG.controls.override?.controls?.disbursement_instructions?.value) ||
                                    false
                                "
                                [formControl]="disbursementInstructionsFC"
                            >
                                <span *ngIf="!disbursementInstructionsFC.value">Disbursement Instructions</span>
                            </mat-slide-toggle>
                            <div
                                *ngIf="
                                    _transferFG.controls.override &&
                                    _transferFG.controls.override.controls &&
                                    _transferFG.controls.override.controls.disbursement_instructions &&
                                    disbursementInstructionsFC.value &&
                                    _transferFG.controls.override.controls.notes &&
                                    _transferFG.controls.override.controls.notes_wildcard_fk_id
                                "
                                style="flex-direction: row; display: flex; align-items: center; padding-right: 5px; z-index: 2;width: 100%;"
                            >
                                <app-value-picker
                                    color="primary"
                                    [wildcardType]="$any(WILDCARD.type_SET.text)"
                                    [valueFC]="_transferFG.controls.override.controls.notes"
                                    [valueWildcardIdFC]="_transferFG.controls.override.controls.notes_wildcard_fk_id"
                                    [class.fade-out]="
                                        _transferFG.controls.override.controls.disbursement_instructions
                                            ? !_transferFG.controls.override.controls.disbursement_instructions.value
                                            : false
                                    "
                                    [disabled]="disabled"
                                    style="z-index:2; justify-content: space-between; display: flex; align-items: center; margin-right: 5px; width: 100%;"
                                >
                                </app-value-picker>
                            </div>
                            <div
                                *ngIf="
                                    !_transferFG.controls.override?.controls?.disbursement_instructions &&
                                    _transferFG.controls.disbursement_instructions?.value &&
                                    _transferFG.controls.notes &&
                                    _transferFG.controls.notes_wildcard_fk_id
                                "
                                style="flex-direction: row; display: flex; align-items: center; padding-right: 5px; z-index: 0;width: 100%;"
                            >
                                <app-value-picker
                                    color="black"
                                    [wildcardType]="$any(WILDCARD.type_SET.text)"
                                    [valueFC]="_transferFG.controls.notes"
                                    [valueWildcardIdFC]="_transferFG.controls.notes_wildcard_fk_id"
                                    [class.grey-out]="
                                        _transferFG.controls.disbursement_instructions
                                            ? !_transferFG.controls.disbursement_instructions.value
                                            : false
                                    "
                                    [disabled]="disabled"
                                    style="justify-content: space-between; display: flex; align-items: center; margin-right: 5px; z-index: 0; width: 100%;"
                                >
                                </app-value-picker>
                            </div>
                            <mat-icon
                                style="color: rgba(0, 0, 0, 0.54); margin-right: 5px; cursor: help; z-index: 2;"
                                matTooltip="Turn this on only if you would like to override any dynamically set individual or group disbursement instructions."
                            >
                                help_outline
                            </mat-icon>
                        </ng-container>
                        <ng-container *ngIf="showBoth">
                            <div style="flex-direction: row; display: flex; align-items: center; padding-right: 5px">
                                <mat-slide-toggle
                                    style="margin-right: 5px; z-index: 0;"
                                    *ngIf="_transferFG.controls.disbursement_instructions"
                                    color="primary"
                                    [formControl]="_transferFG.controls.disbursement_instructions"
                                >
                                    <span
                                        *ngIf="
                                            _transferFG.controls.disbursement_instructions &&
                                            !_transferFG.controls.disbursement_instructions.value
                                        "
                                        [class.bold]="
                                            _transferFG.controls.disbursement_instructions
                                                ? _transferFG.controls.disbursement_instructions.value
                                                : false
                                        "
                                        >Disbursement Instructions</span
                                    >
                                </mat-slide-toggle>
                                <app-value-picker
                                    color="black"
                                    *ngIf="
                                        _transferFG.controls.disbursement_instructions &&
                                        _transferFG.controls.notes &&
                                        _transferFG.controls.notes_wildcard_fk_id &&
                                        _transferFG.controls.disbursement_instructions.value
                                    "
                                    [wildcardType]="$any(WILDCARD.type_SET.text)"
                                    [valueFC]="_transferFG.controls.notes"
                                    [valueWildcardIdFC]="_transferFG.controls.notes_wildcard_fk_id"
                                    [class.grey-out]="
                                        _transferFG.controls.disbursement_instructions
                                            ? !_transferFG.controls.disbursement_instructions.value
                                            : false
                                    "
                                    [disabled]="disabled"
                                    style="justify-content: space-between; display: flex; align-items: center; margin-right: 5px; z-index: 0;"
                                >
                                </app-value-picker>
                                <mat-icon
                                    style="color: rgba(0, 0, 0, 0.54); margin-right: 5px; cursor: help; z-index: 2;"
                                    matTooltip="Turn this on only if you would like to override any dynamically set individual or group disbursement instructions."
                                >
                                    help_outline
                                </mat-icon>
                            </div>
                        </ng-container>
                    </div>
                    <div
                        class="cap-block disbursement-block"
                        *ngIf="
                            contributeToCapFlag &&
                            isExpense &&
                            availableContributeToCapsOrigins[_transferFG.controls.origin?.value]?.length
                        "
                    >
                        <div style="flex-direction: row; display: flex; align-items: center; padding-right: 5px">
                            <mat-slide-toggle
                                style="margin-right: 5px; z-index: 0; z-index: 2;"
                                color="primary"
                                [formControl]="contributeToCapFC"
                            >
                                <span *ngIf="!contributeToCapFC.value" [class.bold]="contributeToCapFC.value"
                                    >Contribute to CAP</span
                                >
                            </mat-slide-toggle>
                            <mat-form-field *ngIf="contributeToCapFC.value" style="z-index: 2;">
                                <mat-label>Contribute To Cap</mat-label>
                                <mat-select
                                    placeholder="Contribute To Cap"
                                    [formControl]="_transferFG.controls.contribute_to_cap"
                                >
                                    <mat-option
                                        [value]="contributeToCap"
                                        *ngFor="
                                            let contributeToCap of availableContributeToCapsOrigins[
                                                _transferFG.controls.origin.value
                                            ]
                                        "
                                    >
                                        {{ FINANCIAL_TRANSFER_ENTITY.contribute_to_cap_LABELS[contributeToCap] }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div
                        class="disbursement-block"
                        *ngIf="
                            !contributeToCapFlag &&
                            ((compensationExpenseEnabledFlag &&
                                _transferFG.controls.origin?.value ===
                                    FINANCIAL_TRANSFER_ENTITY.type_SET.compensation_expense) ||
                                applyAsAgentReceiverExpense)
                        "
                    >
                        <div>
                            <mat-slide-toggle
                                [formControl]="applyAsReceiverSplitFC"
                                (change)="onApplyAsReceiverSplitChange()"
                            >
                                <span>Apply as Agent 1099 Income</span>
                            </mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    `
})
export class FinancialTransferComponent implements OnInit, OnDestroy, OnChanges {
    private unsubscribe: Subject<void> = new Subject();

    WILDCARD = Wildcard;
    senderApplyTo: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    receiverApplyTo: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    wildcardList: IWildcard[] = [];
    FINANCIAL_TRANSFER_ENTITY = FinancialTransferEntity;
    FINANCIAL_ELEMENT_ENTITY = FinancialElementModel;
    _transferFG: GenericFormGroup<FinancialTransferEntity> = new GenericFormGroup(new FinancialTransferEntity());
    disbursementInstructionsFC: UntypedFormControl = new UntypedFormControl(false);
    contributeToCapFC: UntypedFormControl = new UntypedFormControl(false);
    applyAsReceiverSplitFC: UntypedFormControl = new UntypedFormControl(false);
    applyAsAgentReceiverExpense: boolean = false;

    // feature flag
    contributeToCapFlag: boolean = false;
    compensationExpenseEnabledFlag: boolean = false;
    agentReceiverExpenseEnabledFlag: boolean = false;

    @Input() hideSender: boolean = false;
    @Input() hideReceiver: boolean = false;
    @Input() hidePayAtEscrowFlag: boolean = false;
    @Input() hideDisbursementInstructionsFlag: boolean = false;
    @Input() hideProduct: boolean = false;
    @Input() hideLabel: boolean = false;
    @Input() hideAmount: boolean = false;
    @Input() hideSkip: boolean = false;
    @Input() hideValueType: boolean = false;
    @Input() hidePayAtClosing: boolean = false;

    @Input() receiverLabel: string = 'Receiver Contact';
    @Input() senderLabel: string = 'Sender Contact';

    @Input() type: string = FinancialTransferEntity.type_SET.income;
    @Input() allowPercents: boolean = false;
    @Input() showFlatFeeBasedOnPercent: boolean = false;
    @Input() isExpense: boolean = false;

    @Input() showBoth: boolean = false;

    @Input() disabled: boolean = false;
    @Input() disabledContact: boolean = false;

    @Input() origin: string = 'default';

    @Input() enableDisbursementInstructionsToggle: boolean = true;

    availableContributeToCapsOrigins: {[key: string]: string[]} = {
        sales: [FinancialTransferEntity.contribute_to_cap_SET.total_company_split],
        user_referral: [FinancialTransferEntity.contribute_to_cap_SET.total_company_split],
        disbursement_template: [
            // entity income/expense
            FinancialTransferEntity.contribute_to_cap_SET.total_company_split
        ],
        compensation_expense: [
            // entity income/expense
            FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
            FinancialTransferEntity.contribute_to_cap_SET.receiver_split
        ]
        // 'company_income_expense' commented because in Rules we need show "contribute to cap" for company_INCOME,
        // because it will be then Expense for entity. But in template we have condition "isExpense".
        // So it could be done, but need more careful implementation.
        // company_income_expense: [
        //     FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
        // ],
    };
    // availableIncomeCaps: any = {};
    // availableIncomeCaps: any = {
    //     default: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     agent: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     user_royalty: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     royalty: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     sales: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     user_referral: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     referral: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     disbursement_template: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     company_income_expense: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     company: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     participant: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     company_partner: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     company_split_individual: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ]
    // };
    // availableExpenseCaps: any = {
    //     default: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     agent: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     user_royalty: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     royalty: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     sales: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     user_referral: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     referral: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     disbursement_template: [ // entity income/expense
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     company_income_expense: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     company: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     participant: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     company_partner: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     company_split_individual: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ]
    // };

    availableExpenseTypes: any = {
        default: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        agent: [
            FinancialTransferEntity.value_type_SET.percent_of_adjusted_gross,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        user_royalty: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_sales_total,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.flat_fee
            // FinancialTransferEntity.value_type_SET.flat_fee_sales_net,
        ],
        royalty: [
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_adjusted_gross,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        sales: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_sales_entity_sales_split,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        compensation_expense: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.flat_fee,
            FinancialTransferEntity.value_type_SET.flat_fee_sales_net
        ],
        user_referral: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        referral: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        disbursement_template: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.flat_fee,
            FinancialTransferEntity.value_type_SET.flat_fee_sales_net
        ],
        company_income_expense: [
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_entity_split,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_company_split,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_primary_entity_split,
            FinancialTransferEntity.value_type_SET.percent_of_sales_total,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        company: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        participant: [FinancialTransferEntity.value_type_SET.percent_of_deal],
        company_partner: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_company_split,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_sales_total,
            FinancialTransferEntity.value_type_SET.percent_of_sales_net,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        company_split_individual: [FinancialTransferEntity.value_type_SET.remaining]
    };

    availableIncomeTypes: any = {
        default: [FinancialTransferEntity.value_type_SET.flat_fee],
        agent: [
            FinancialTransferEntity.value_type_SET.percent_of_adjusted_gross,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        user_royalty: [
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_sales_total,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        royalty: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        referral: [FinancialTransferEntity.value_type_SET.flat_fee],
        sales: [FinancialTransferEntity.value_type_SET.flat_fee],
        user_referral: [FinancialTransferEntity.value_type_SET.flat_fee],
        disbursement_template: [FinancialTransferEntity.value_type_SET.flat_fee],
        company_income_expense: [FinancialTransferEntity.value_type_SET.flat_fee],
        company: [FinancialTransferEntity.value_type_SET.flat_fee],
        participant: [FinancialTransferEntity.value_type_SET.percent_of_deal],
        company_partner: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_company_split,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_sales_total,
            FinancialTransferEntity.value_type_SET.flat_fee
        ]
    };

    previous: FinancialTransferEntity | null = null;

    applyForPrimaryAgent: boolean = false;
    applyForOtherAgents: boolean = false;

    // contactPersons: { contact_id: number, link_title: string, contact_person_fk_id: number, }[] = [];
    contacts: IContact[] = [];

    products: IProduct[] = [];

    @Input() set transferFG(fx: GenericFormGroup<FinancialTransferEntity>) {
        fx.valueChanges
            .pipe(
                tap(() => {
                    fx.controls.source_amount!.patchValue(cleanCurrencyString(fx.controls.source_amount?.value), {
                        emitEvent: false
                    });
                    fx.controls.label!.patchValue(fx.controls.label?.value, {emitEvent: false});
                    fx.controls.percent!.patchValue(fx.controls.percent?.value, {emitEvent: false});
                    fx.controls.percent_type!.patchValue(fx.controls.percent_type?.value, {emitEvent: false});
                    fx.controls.value_type!.patchValue(fx.controls.value_type?.value, {emitEvent: false});
                    fx.controls.override!.patchValue(fx.controls.override?.value, {emitEvent: false});
                    fx.controls.pay_at_escrow!.patchValue(fx.controls.pay_at_escrow?.value, {emitEvent: false});
                    fx.controls.disbursement_instructions!.patchValue(fx.controls.disbursement_instructions?.value, {
                        emitEvent: false
                    });
                    fx.controls.contribute_to_cap!.patchValue(fx.controls.contribute_to_cap?.value, {emitEvent: false});
                    fx.controls.value_wildcard_fk_id!.patchValue(fx.controls.value_wildcard_fk_id?.value, {
                        emitEvent: false
                    });
                    fx.controls.notes_wildcard_fk_id!.patchValue(fx.controls.notes_wildcard_fk_id?.value, {
                        emitEvent: false
                    });
                    fx.controls.notes!.patchValue(fx.controls.notes?.value, {emitEvent: false});
                    fx.controls.product_fk_id!.patchValue(fx.controls.product_fk_id?.value, {emitEvent: false});
                    fx.controls.product_fk_id!.setValidators([Validators.required]);
                    fx.controls.product_fk_id!.updateValueAndValidity({emitEvent: false});
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((next: FinancialTransferEntity) => {
                // if (this.previous && next.added_automatic && !next.override) {
                //     if (this.previous.pay_at_escrow !== next.pay_at_escrow) {
                //         this.onToggleOverride(this._transferFG, true);
                //         this._transferFG.controls.pay_at_escrow.patchValue(this.previous.pay_at_escrow, {emitEvent: false});
                //         this._transferFG.controls.override.controls.pay_at_escrow.patchValue(next.pay_at_escrow, {emitEvent: false});
                //     }
                //     if (this.previous.disbursement_instructions !== next.disbursement_instructions) {
                //         this.onToggleOverride(this._transferFG, true);
                //         this._transferFG.controls.disbursement_instructions.patchValue(this.previous.disbursement_instructions, {emitEvent: false});
                //         this._transferFG.controls.override.controls.disbursement_instructions.patchValue(next.disbursement_instructions, {emitEvent: false});
                //     }
                //     return;
                // }
                if (next.sender) {
                    this.senderApplyTo.patchValue(
                        [
                            new ChipNode()
                                .setTargetId(next.sender.contact_id)
                                .setLabel(next.sender.link_title || '')
                                .setType(ChipNode.type_SET.contact)
                        ],
                        {emitEvent: false}
                    );
                }
                if (next.receiver) {
                    this.receiverApplyTo.patchValue(
                        [
                            new ChipNode()
                                .setTargetId(next.receiver.contact_id)
                                .setLabel(next.receiver.link_title || '')
                                .setType(ChipNode.type_SET.contact)
                        ],
                        {emitEvent: false}
                    );
                }
                // if (next.sender_wildcard_id && next.sender === null) {
                if (next.sender_wildcard_id) {
                    const currentWildcard = this.wildcardList.find(
                        (wildcard) => wildcard.wildcard_id === next.sender_wildcard_id
                    );

                    if (typeof currentWildcard !== 'undefined') {
                        this.senderApplyTo.patchValue(
                            [
                                new ChipNode()
                                    .setTargetId(next.sender_wildcard_id)
                                    .setLabel(currentWildcard.title || '')
                                    .setType(ChipNode.type_SET.wildcard)
                            ],
                            {emitEvent: false}
                        );
                    } else {
                        let label = '';
                        if (next.sender_wildcard_id === 1) {
                            label = 'Mentor';
                        }
                        if (next.sender_wildcard_id === 2) {
                            label = 'TRX Coordinator';
                        }
                        if (next.sender_wildcard_id === 3) {
                            label = 'Deal';
                        }
                        if (next.sender_wildcard_id === 4) {
                            label = 'Mentor Team Leader';
                        }
                        if (next.sender_wildcard_id === 5) {
                            label = 'Commission Payer';
                        }
                        if (next.sender_wildcard_id === 6) {
                            label = 'Primary Agent';
                        }
                        if (next.sender_wildcard_id === 7) {
                            label = 'Company';
                        }
                        this.senderApplyTo.patchValue(
                            [
                                new ChipNode()
                                    .setTargetId(next.sender_wildcard_id)
                                    .setLabel(label)
                                    .setType(ChipNode.type_SET.contact_wildcard)
                            ],
                            {emitEvent: false}
                        );
                    }
                }
                // if (next.receiver_wildcard_id && next.receiver === null) {
                if (next.receiver_wildcard_id) {
                    const currentWildcard = this.wildcardList.find(
                        (wildcard) => wildcard.wildcard_id === next.receiver_wildcard_id
                    );

                    if (typeof currentWildcard !== 'undefined') {
                        this.receiverApplyTo.patchValue(
                            [
                                new ChipNode()
                                    .setTargetId(next.receiver_wildcard_id)
                                    .setLabel(currentWildcard.title || '')
                                    .setType(ChipNode.type_SET.wildcard)
                            ],
                            {emitEvent: false}
                        );
                    } else {
                        let label = '';
                        if (next.receiver_wildcard_id === 1) {
                            label = 'Mentor';
                        }
                        if (next.receiver_wildcard_id === 2) {
                            label = 'TRX Coordinator';
                        }
                        if (next.receiver_wildcard_id === 3) {
                            label = 'Deal';
                        }
                        if (next.receiver_wildcard_id === 4) {
                            label = 'Mentor Team Leader';
                        }
                        if (next.receiver_wildcard_id === 5) {
                            label = 'Commission Payer';
                        }
                        if (next.receiver_wildcard_id === 6) {
                            label = 'Primary Agent';
                        }
                        if (next.receiver_wildcard_id === 7) {
                            label = 'Company';
                        }
                        this.receiverApplyTo.patchValue(
                            [
                                new ChipNode()
                                    .setTargetId(next.receiver_wildcard_id)
                                    .setLabel(label)
                                    .setType(ChipNode.type_SET.contact_wildcard)
                            ],
                            {emitEvent: false}
                        );
                    }
                }
                if (this.showBoth) {
                    if (fx.controls.pay_at_escrow?.value === false) {
                        fx.controls.disbursement_instructions!.patchValue(false, {emitEvent: false});
                        fx.controls.disbursement_instructions!.disable({emitEvent: false});
                        fx.controls.notes!.patchValue(null, {emitEvent: false});
                        fx.controls.notes!.disable({emitEvent: false});
                    } else if (!this.disabled) {
                        fx.controls.disbursement_instructions!.enable({emitEvent: false});
                        fx.controls.notes!.enable({emitEvent: false});
                    }
                } else {
                    if (
                        fx.controls.override &&
                        !(fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                    ) {
                        if (fx.controls.pay_at_escrow?.value === false) {
                            this.disbursementInstructionsFC.patchValue(false, {emitEvent: false});
                            this.disbursementInstructionsFC.disable({emitEvent: false});
                        } else {
                            this.disbursementInstructionsFC.enable({emitEvent: false});
                        }
                    }
                }
                if (
                    next.override &&
                    fx.controls.override &&
                    (fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                ) {
                    if (
                        (!(fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                            ?.pay_at_escrow &&
                            !fx.controls.pay_at_escrow?.value) ||
                        ((fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls.pay_at_escrow &&
                            !(fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls.pay_at_escrow
                                ?.value)
                    ) {
                        if (
                            (!(fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                                ?.disbursement_instructions &&
                                fx.controls.disbursement_instructions?.value) ||
                            ((fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                                ?.disbursement_instructions &&
                                (fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                                    ?.disbursement_instructions?.value)
                        ) {
                            if (
                                fx.controls.disbursement_instructions?.value &&
                                !(fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                                    ?.disbursement_instructions
                            ) {
                                (
                                    fx.controls.override as GenericFormGroup<FinancialTransferEntity>
                                )?.controls.disbursement_instructions!.disable({emitEvent: false});
                                (
                                    fx.controls.override as GenericFormGroup<FinancialTransferEntity>
                                )?.controls?.notes!.disable({emitEvent: false});
                            } else if (
                                (fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                                    ?.disbursement_instructions &&
                                (fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                                    ?.disbursement_instructions?.value
                            ) {
                                (
                                    fx.controls.override as GenericFormGroup<FinancialTransferEntity>
                                )?.controls?.disbursement_instructions!.disable({emitEvent: false});
                                (
                                    fx.controls.override as GenericFormGroup<FinancialTransferEntity>
                                )?.controls?.notes!.disable({emitEvent: false});
                            }
                        }
                        this.disbursementInstructionsFC.disable({emitEvent: false});
                    } else {
                        if (
                            (fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                                ?.disbursement_instructions &&
                            !this.disabled
                        ) {
                            (
                                fx.controls.override as GenericFormGroup<FinancialTransferEntity>
                            )?.controls?.disbursement_instructions!.enable({emitEvent: false});
                            (
                                fx.controls.override as GenericFormGroup<FinancialTransferEntity>
                            )?.controls?.notes!.enable({emitEvent: false});
                        }
                        this.disbursementInstructionsFC.enable({emitEvent: false});
                    }
                }

                if (fx.controls.contribute_to_cap?.value) {
                    this.contributeToCapFC.patchValue(true, {emitEvent: false});
                } else {
                    this.contributeToCapFC.patchValue(false, {emitEvent: false});
                }

                if (
                    fx.controls.contribute_to_cap?.value &&
                    fx.controls.contribute_to_cap?.value ===
                        FinancialTransferEntity.contribute_to_cap_SET.receiver_split
                ) {
                    this.applyAsReceiverSplitFC.patchValue(true, {emitEvent: false});
                } else {
                    this.applyAsReceiverSplitFC.patchValue(false, {emitEvent: false});
                }
                this.previous = next;
            });

        if (fx.controls.contribute_to_cap?.value) {
            this.contributeToCapFC.patchValue(true, {emitEvent: false});
        } else {
            this.contributeToCapFC.patchValue(false, {emitEvent: false});
        }
        if (
            fx.controls.contribute_to_cap?.value &&
            fx.controls.contribute_to_cap?.value === FinancialTransferEntity.contribute_to_cap_SET.receiver_split
        ) {
            this.applyAsReceiverSplitFC.patchValue(true, {emitEvent: false});
        } else {
            this.applyAsReceiverSplitFC.patchValue(false, {emitEvent: false});
        }
        if (
            fx.controls.override &&
            (fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls &&
            (fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls?.pay_at_escrow
        ) {
            if (!(fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls.pay_at_escrow?.value) {
                this.disbursementInstructionsFC.disable({emitEvent: false});
            }
        } else {
            if (!fx.controls.pay_at_escrow?.value) {
                this.disbursementInstructionsFC.disable({emitEvent: false});
            }
        }
        if (
            fx.controls.override &&
            (fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls &&
            (fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls?.disbursement_instructions
        ) {
            this.disbursementInstructionsFC.patchValue(
                (fx.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls.disbursement_instructions
                    ?.value
            );
        } else {
            this.disbursementInstructionsFC.patchValue(fx.controls.disbursement_instructions?.value);
        }
        this._transferFG = fx;

        switch (this._transferFG.controls.apply_to_agents?.value) {
            case FinancialTransferEntity.agents_types_SET.primary:
                this.applyForPrimaryAgent = true;
                break;
            case FinancialTransferEntity.agents_types_SET.other:
                this.applyForOtherAgents = true;
                break;
            case FinancialTransferEntity.agents_types_SET.all:
                this.applyForPrimaryAgent = true;
                this.applyForOtherAgents = true;
                break;
            default:
                this.applyForPrimaryAgent = true;
                this.applyForOtherAgents = false;
                break;
        }

        this.previous = fx.getRawValue();
    }

    constructor(
        private store: Store<any>,
        public dialog: MatDialog,
        protected productsService: ProductsService,
        protected featureFlagsService: FeatureFlagsService
    ) {
        this.store.dispatch(new FetchContacts());
        this.store
            .pipe(
                select(selectContacts), // migrate to ContactsLite: done
                map((contacts) => {
                    // this.contactPersons = extractContactPersons(contacts);
                    this.contacts = contacts;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.contributeToCapFlag = this.featureFlagsService.isFeatureEnabled('deals:contribute_to_cap');
                this.compensationExpenseEnabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'rules:compensation_expense_element'
                );
                this.agentReceiverExpenseEnabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'rules:agent_receiver_expense_element'
                );
            });
    }

    createProduct() {
        const dialogRef = this.dialog.open(CreateProductDialogComponent, {
            // width: '90vw',
            // minHeight: '30vh'
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.store.dispatch(new FetchProducts()));
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }

    onToggleOverride(transfer: GenericFormGroup<FinancialTransferEntity>, override: boolean) {
        if (override) {
            if (transfer.controls.type?.value === FinancialTransferEntity.type_SET.company_payout) {
                return;
            }
            // transfer.controls.override.patchValue(transfer.getRawValue());
            transfer.controls.override = new GenericFormGroup<FinancialTransferEntity>(<FinancialTransferEntity>{
                pay_at_escrow: transfer.controls.pay_at_escrow?.value,
                disbursement_instructions: transfer.controls.disbursement_instructions?.value,
                notes: transfer.controls.notes?.value,
                notes_wildcard_fk_id: transfer.controls.notes_wildcard_fk_id?.value
            });
        } else {
            transfer.controls.override = <GenericFormGroup<FinancialTransferEntity>>(
                (<unknown>new UntypedFormControl(null))
            );
        }
        transfer.updateValueAndValidity({emitEvent: false});
    }

    onDisbursementInstructionsChange(isChecked: boolean) {
        const overrideObj: {[key: string]: any} = {
            disbursement_instructions: isChecked,
            notes: null,
            notes_wildcard_fk_id: null
        };

        if (
            this._transferFG.controls.override &&
            (this._transferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
        ) {
            if (isChecked === this._transferFG.controls.disbursement_instructions?.value) {
                if (
                    (((this._transferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                        .pay_at_escrow &&
                        (this._transferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                            .pay_at_escrow?.value === this._transferFG.controls.pay_at_escrow?.value) ||
                        !(this._transferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                            ?.pay_at_escrow) &&
                    (((this._transferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                        .contribute_to_cap &&
                        (this._transferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                            .contribute_to_cap?.value === this._transferFG.controls.contribute_to_cap?.value) ||
                        !(this._transferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                            .contribute_to_cap)
                ) {
                    this._transferFG.controls.override = <GenericFormGroup<FinancialTransferEntity>>(
                        (<unknown>new UntypedFormControl(null))
                    );
                } else {
                    (this._transferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)?.removeControl(
                        'disbursement_instructions'
                    );
                    (this._transferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)?.removeControl(
                        'notes'
                    );
                    (this._transferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)?.removeControl(
                        'notes_wildcard_fk_id'
                    );
                }
            } else {
                if (
                    (this._transferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                        .disbursement_instructions
                ) {
                    (
                        this._transferFG.controls.override as GenericFormGroup<FinancialTransferEntity>
                    )?.controls.disbursement_instructions!.patchValue(isChecked);
                } else {
                    if (
                        (this._transferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                            .pay_at_escrow
                    ) {
                        overrideObj['pay_at_escrow'] = (
                            this._transferFG.controls.override as GenericFormGroup<FinancialTransferEntity>
                        )?.controls.pay_at_escrow?.value;
                    }

                    if (
                        (this._transferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                            .contribute_to_cap
                    ) {
                        overrideObj['contribute_to_cap'] = (
                            this._transferFG.controls.override as GenericFormGroup<FinancialTransferEntity>
                        )?.controls.contribute_to_cap?.value;
                    }

                    this._transferFG.controls.override = new GenericFormGroup<FinancialTransferEntity>(
                        <FinancialTransferEntity>overrideObj
                    );
                }
            }
        } else {
            this._transferFG.controls.override = new GenericFormGroup<FinancialTransferEntity>(
                <FinancialTransferEntity>overrideObj
            );
        }
    }

    selectItem(product: IProduct) {
        if (
            this._transferFG.controls.source_amount?.value === null &&
            this._transferFG.controls.value_wildcard_fk_id?.value === null &&
            this._transferFG.controls.percent?.value === null &&
            FinancialTransferEntity.allowed_types_to_set_amount_from_product_price[
                this._transferFG.controls.type?.value as string
            ]
        ) {
            this._transferFG.controls.source_amount.patchValue(product.price);
        }
        if (this._transferFG.controls.label?.value === null) {
            this._transferFG.controls.label!.patchValue(product.name);
        }
    }

    get transferFG() {
        return this._transferFG;
    }

    updatedParticipants(event: any) {
        console.info(event);
    }

    // doTogglePayAtEscrow(additional: GenericFormGroup<FinancialTransferEntity>) {
    //     // event.stopPropagation();
    //     if (additional.controls.pay_at_escrow.disabled) {
    //         return;
    //     }
    //     if (additional.controls.pay_at_escrow.value) {
    //         additional.controls.pay_at_escrow.patchValue(false, { emitEvent: false });
    //     } else {
    //         additional.controls.pay_at_escrow.patchValue(true, { emitEvent: false });
    //     }
    // }

    // doToggleDisbursementInstructions(additional: GenericFormGroup<FinancialTransferEntity>) {
    //     // event.stopPropagation();
    //     if (additional.controls.disbursement_instructions.disabled) {
    //         return;
    //     }
    //     if (! this.enableDisbursementInstructionsToggle) {
    //         additional.controls.disbursement_instructions.patchValue(false, {emitEvent: false});
    //         return;
    //     }
    //     if (additional.controls.disbursement_instructions.value) {
    //         additional.controls.disbursement_instructions.patchValue(false, { emitEvent: false });
    //     } else {
    //         additional.controls.disbursement_instructions.patchValue(true, { emitEvent: false });
    //         // additional.controls.pay_at_escrow.patchValue(true, { emitEvent: true });
    //     }
    // }

    // doToggleDisbursementInstructions(additional: GenericFormGroup<FinancialTransferEntity>) {
    //     // event.stopPropagation();
    //     if (additional.controls.disbursement_instructions.disabled) {
    //         return;
    //     }
    //     if (! this.enableDisbursementInstructionsToggle) {
    //         additional.controls.disbursement_instructions.patchValue(false, {emitEvent: false});
    //         return;
    //     }
    //     if (additional.controls.disbursement_instructions.value) {
    //         additional.controls.disbursement_instructions.patchValue(false, { emitEvent: false });
    //     } else {
    //         additional.controls.disbursement_instructions.patchValue(true, { emitEvent: false });
    //         // additional.controls.pay_at_escrow.patchValue(true, { emitEvent: true });
    //     }
    // }

    ngOnInit() {
        // the transfers with type=company_split_individual is an additional transfer of the Sales Compensation element
        // and value type of this type of transfers should not be changed by users
        if (this._transferFG.controls.type?.value === FinancialTransferEntity.type_SET.company_split_individual) {
            this._transferFG.controls.value_type!.disable({emitEvent: false});
        }
        // @TODO: Need refactor to better usage of store
        this.productsService.lastLoadedProducts.pipe(takeUntil(this.unsubscribe)).subscribe((next) => {
            this.products = next;
        });

        this.store.pipe(select(selectWildcards), takeUntil(this.unsubscribe)).subscribe((wildcards) => {
            this.wildcardList = wildcards;
            if (this._transferFG.controls.sender?.value !== null) {
                this.senderApplyTo.patchValue(
                    [
                        new ChipNode()
                            .setTargetId(this._transferFG.controls.sender?.value.contact_id)
                            .setLabel(this._transferFG.controls.sender?.value.link_title)
                            .setType(ChipNode.type_SET.contact)
                    ],
                    {emitEvent: false}
                );
            }
            if (this._transferFG.controls.receiver?.value !== null) {
                this.receiverApplyTo.patchValue(
                    [
                        new ChipNode()
                            .setTargetId(this._transferFG.controls.receiver?.value.contact_id)
                            .setLabel(this._transferFG.controls.receiver?.value.link_title)
                            .setType(ChipNode.type_SET.contact)
                    ],
                    {emitEvent: false}
                );
            }
            if (this._transferFG.controls.sender_wildcard_id?.value) {
                const currentWildcard = this.wildcardList.find(
                    (wildcard) => wildcard.wildcard_id === this._transferFG.controls.sender_wildcard_id?.value
                );

                if (typeof currentWildcard !== 'undefined') {
                    this.senderApplyTo.patchValue(
                        [
                            new ChipNode()
                                .setTargetId(this._transferFG.controls.sender_wildcard_id.value)
                                .setLabel(currentWildcard?.title || '')
                                .setType(ChipNode.type_SET.wildcard)
                        ],
                        {emitEvent: false}
                    );
                } else {
                    let label = '';
                    if (this._transferFG.controls.sender_wildcard_id.value === 1) {
                        label = 'Mentor';
                    }
                    if (this._transferFG.controls.sender_wildcard_id.value === 2) {
                        label = 'TRX Coordinator';
                    }
                    if (this._transferFG.controls.sender_wildcard_id.value === 3) {
                        label = 'Deal';
                    }
                    if (this._transferFG.controls.sender_wildcard_id.value === 4) {
                        label = 'Mentor Team Leader';
                    }
                    if (this._transferFG.controls.sender_wildcard_id.value === 5) {
                        label = 'Commission Payer';
                    }
                    if (this._transferFG.controls.sender_wildcard_id.value === 6) {
                        label = 'Primary Agent';
                    }
                    if (this._transferFG.controls.sender_wildcard_id.value === 7) {
                        label = 'Company';
                    }

                    this.senderApplyTo.patchValue(
                        [
                            new ChipNode()
                                .setTargetId(this._transferFG.controls.sender_wildcard_id.value)
                                .setLabel(label)
                                .setType(ChipNode.type_SET.contact_wildcard)
                        ],
                        {emitEvent: false}
                    );
                }
            }
            if (this._transferFG.controls.receiver_wildcard_id?.value) {
                const currentWildcard = this.wildcardList.find(
                    (wildcard) => wildcard.wildcard_id === this._transferFG.controls.receiver_wildcard_id?.value
                );

                if (typeof currentWildcard !== 'undefined') {
                    this.receiverApplyTo.patchValue(
                        [
                            new ChipNode()
                                .setTargetId(this._transferFG.controls.receiver_wildcard_id.value)
                                .setLabel(currentWildcard?.title || '')
                                .setType(ChipNode.type_SET.wildcard)
                        ],
                        {emitEvent: false}
                    );
                } else {
                    let label = '';
                    if (this._transferFG.controls.receiver_wildcard_id.value === 1) {
                        label = 'Mentor';
                    }
                    if (this._transferFG.controls.receiver_wildcard_id.value === 2) {
                        label = 'TRX Coordinator';
                    }
                    if (this._transferFG.controls.receiver_wildcard_id.value === 3) {
                        label = 'Deal';
                    }
                    if (this._transferFG.controls.receiver_wildcard_id.value === 4) {
                        label = 'Mentor Team Leader';
                    }
                    if (this._transferFG.controls.receiver_wildcard_id.value === 5) {
                        label = 'Commission Payer';
                    }
                    if (this._transferFG.controls.receiver_wildcard_id.value === 6) {
                        label = 'Primary Agent';
                    }
                    if (this._transferFG.controls.receiver_wildcard_id.value === 7) {
                        label = 'Company';
                    }
                    this.receiverApplyTo.patchValue(
                        [
                            new ChipNode()
                                .setTargetId(this._transferFG.controls.receiver_wildcard_id.value)
                                .setLabel(label)
                                .setType(ChipNode.type_SET.contact_wildcard)
                        ],
                        {emitEvent: false}
                    );
                }
            }
        });

        this.receiverApplyTo.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((next) => {
            const chipNode = next.shift();
            if (chipNode && chipNode.type === 'contact') {
                const selectedContact = this.contacts.find((contact) => contact.id === chipNode.target_id);
                this._transferFG.controls.receiver_wildcard_id!.patchValue(null);
                this._transferFG.controls.receiver!.patchValue(
                    new ContactPartLink().setContactId(chipNode.target_id).setLinkTitle(chipNode.label)
                );
                if (selectedContact && selectedContact.partner__company_fk_id) {
                    this._transferFG.controls.receiver_contact__company_fk_id!.patchValue(
                        selectedContact.partner__company_fk_id
                    );
                }
            } else if (chipNode && chipNode.type === 'contact_wildcard') {
                // remove and add control are needed because value is another FormControl and we can not just set it to null
                this._transferFG.removeControl('receiver');
                this._transferFG.addControl('receiver', new UntypedFormControl(null));
                this._transferFG.controls.receiver_wildcard_id!.patchValue(chipNode.target_id);
            } else if (chipNode && chipNode.type === 'wildcard') {
                // remove and add control are needed because value is another FormControl and we can not just set it to null
                this._transferFG.removeControl('receiver');
                this._transferFG.addControl('receiver', new UntypedFormControl(null));
                this._transferFG.controls.receiver_wildcard_id!.patchValue(chipNode.target_id);
            }
        });
        this.senderApplyTo.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((next) => {
            const chipNode = next.shift();
            if (chipNode && chipNode.type === 'contact') {
                const selectedContact = this.contacts.find((contact) => contact.contact_id === chipNode.target_id);
                this._transferFG.controls.sender_wildcard_id!.patchValue(0);
                this._transferFG.controls.sender!.patchValue(
                    new ContactPartLink().setContactId(chipNode.target_id).setLinkTitle(chipNode.label)
                );
                if (selectedContact && selectedContact.partner__company_fk_id) {
                    this._transferFG.controls.sender_contact__company_fk_id!.patchValue(
                        selectedContact.partner__company_fk_id
                    );
                }
            } else if (chipNode && chipNode.type === 'contact_wildcard') {
                // remove and add control are needed because value is another FormControl and we can not just set it to null
                this._transferFG.removeControl('sender');
                this._transferFG.addControl('sender', new UntypedFormControl(null));
                this._transferFG.controls.sender_wildcard_id!.patchValue(chipNode.target_id);
            } else if (chipNode && chipNode.type === 'wildcard') {
                // remove and add control are needed because value is another FormControl and we can not just set it to null
                this._transferFG.removeControl('sender');
                this._transferFG.addControl('sender', new UntypedFormControl(null));
                this._transferFG.controls.sender_wildcard_id!.patchValue(chipNode.target_id);
            }
        });

        if (!this.showBoth) {
            this.disbursementInstructionsFC.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((newValue) => {
                this.onDisbursementInstructionsChange(newValue);
            });
        }

        this.contributeToCapFC.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((newValue) => {
            // we would like to flush dropdown value everytime when toggle switched
            this._transferFG.controls.contribute_to_cap!.patchValue(null, {emitEvent: false});
        });

        this.applyAsAgentReceiverExpense = this.getApplyAsAgentReceiverExpense();
    }

    getApplyAsAgentReceiverExpense() {
        const applicableTypes = [
            this.FINANCIAL_ELEMENT_ENTITY.type_set.user_referral,
            this.FINANCIAL_ELEMENT_ENTITY.type_set.user_royalty,
            this.FINANCIAL_ELEMENT_ENTITY.type_set.disbursement_template,
            this.FINANCIAL_ELEMENT_ENTITY.type_set.referral,
            this.FINANCIAL_ELEMENT_ENTITY.type_set.royalty
        ];

        const isCompanyExpense =
            (this._transferFG.controls.origin?.value === this.FINANCIAL_ELEMENT_ENTITY.type_set.company ||
                this._transferFG.controls.origin?.value ===
                    this.FINANCIAL_ELEMENT_ENTITY.type_set.company_income_expense) &&
            this._transferFG.controls.origin_is_expense?.value === true;

        return (
            this.agentReceiverExpenseEnabledFlag &&
            this._transferFG.controls.origin?.status !== 'DISABLED' &&
            (applicableTypes.includes(this._transferFG.controls.origin?.value) || isCompanyExpense)
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.disabled && changes.disabled.currentValue) {
            this._transferFG.controls.can_be_skipped?.disable();
            this._transferFG.controls.label?.disable();
            this._transferFG.controls.value_type?.disable();
            this._transferFG.controls.disbursement_instructions?.disable();
            this.disbursementInstructionsFC?.disable();
            this.contributeToCapFC?.disable();
            this._transferFG.controls.override?.controls?.contribute_to_cap?.disable();
            this._transferFG.controls.contribute_to_cap?.disable();
        }
    }

    object_keys(obj: object) {
        return Object.keys(obj);
    }

    changeApplyToAgents(applyTo: 'primary' | 'other') {
        let applyToValue;
        switch (applyTo) {
            case 'primary':
                this.applyForPrimaryAgent = !this.applyForPrimaryAgent;
                break;
            case 'other':
                this.applyForOtherAgents = !this.applyForOtherAgents;
                break;
        }
        if (this.applyForPrimaryAgent) {
            applyToValue = FinancialTransferEntity.agents_types_SET.primary;
        }

        if (this.applyForOtherAgents) {
            applyToValue = FinancialTransferEntity.agents_types_SET.other;
        }

        if (this.applyForPrimaryAgent && this.applyForOtherAgents) {
            applyToValue = FinancialTransferEntity.agents_types_SET.all;
        }

        if (!this.applyForPrimaryAgent && !this.applyForOtherAgents) {
            applyToValue = FinancialTransferEntity.agents_types_SET.primary;
            this.applyForPrimaryAgent = true;
        }

        this._transferFG.controls.apply_to_agents!.patchValue(applyToValue, {emitEvent: false});
    }

    isPercent(value: string): boolean {
        switch (value) {
            case FinancialTransferEntity.value_type_SET.percent:
            case FinancialTransferEntity.value_type_SET.percent_of_split:
            case FinancialTransferEntity.value_type_SET.percent_of_base_commission:
            case FinancialTransferEntity.value_type_SET.percent_of_base_commission_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_commission:
            case FinancialTransferEntity.value_type_SET.percent_of_commission_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_adjusted_gross:
            case FinancialTransferEntity.value_type_SET.percent_of_adjusted_gross_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_deal_net:
            case FinancialTransferEntity.value_type_SET.percent_of_deal_net_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_net_commission:
            case FinancialTransferEntity.value_type_SET.percent_of_net_commission_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_company_split:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_total:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_net:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_entity_sales_split:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_price:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_price_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_entity_sales_price:
            case FinancialTransferEntity.value_type_SET.percent_of_primary_entity_split:
            case FinancialTransferEntity.value_type_SET.percent_of_entity_split:
                return true;
            case FinancialTransferEntity.value_type_SET.flat_fee:
            case FinancialTransferEntity.value_type_SET.flat_fee_sales_net:
            case FinancialTransferEntity.value_type_SET.remaining:
                return false;
            default:
                throw new Error(`Unexpected value type - ${value}`);
        }
        return false;
    }

    onApplyAsReceiverSplitChange() {
        const isAppliedAsReceiverSplit = this.applyAsReceiverSplitFC.value;
        this._transferFG.controls.contribute_to_cap!.patchValue(isAppliedAsReceiverSplit ? 'receiver_split' : null, {
            emitEvent: false
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
