import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Input} from '@angular/core';
import {BaseModel} from '../../../../../../../models/base.model';
import {GenericFormArray, GenericFormGroup} from '../../../../../../../entites/generic.entity';
import {ReportsService} from '../../../../../services/reports.service';
import {AuthService} from '../../../../../../../services/auth.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IReportsPreviewResultData} from '../../../../../store/reports.reducer';

export class ResultRowModel {
    title: string = '';
    actual: number | null = null;
    target: string = '';
    percent: string = '';
}

export class ReportNodeModel extends BaseModel {
    picked: boolean | null = null;
    expanded: boolean | null = null;
    type: string = '';
    results: ResultRowModel[] = [];
    label: string = '';
    children?: ReportNodeModel[] = [];
}

export class ResultRowFormArray extends GenericFormArray<ResultRowModel> {}

export class ReportNodeFormGroup extends GenericFormGroup<ReportNodeModel> {}

export class ReportNodesFormArray extends GenericFormArray<ReportNodeModel> {}

@Component({
    selector: 'app-view-deals-report-results-table',
    styles: [
        `
            .container {
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            .row {
                display: flex;
                flex-direction: row;
            }

            .col {
                display: flex;
                flex-direction: column;
            }

            .picker-row {
                align-items: center;
                justify-content: flex-start;
                height: 40px;
                min-width: max-content;
            }

            .item-children {
                display: flex;
                flex-direction: column;
            }

            .indent {
                width: 20px;
            }

            .mat-icon-button {
                width: unset;
                height: unset;
            }

            button {
            }

            .table-header {
                font-weight: 700;
                font-size: 20px;
                margin-bottom: 12px;
            }

            .table-row {
                font-size: 16px;
            }

            .title {
                min-width: 250px;
            }

            .actual {
                min-width: 100px;
                margin-left: 4px;
            }

            .target {
                min-width: 100px;
                margin-left: 4px;
            }
        `
    ],
    template: `
        <div class="container row">
            <div class="col">
                <div class="table-header title">Goals</div>
                <div class="table-row title" *ngFor="let row of results.controls">{{ row.controls.title.value }}</div>
            </div>
            <div class="col" style="margin-left: 12px">
                <div class="table-header actual">Actual</div>
                <div class="table-row actual" *ngFor="let row of results.controls">{{ row.controls.actual.value }}</div>
            </div>
            <div class="col" style="margin-left: 12px">
                <div class="table-header target">Target</div>
                <div class="table-row target" *ngFor="let row of results.controls">{{ row.controls.target.value }}</div>
            </div>
            <div class="col" style="margin-left: 12px">
                <div class="table-header target">Percent</div>
                <div class="table-row target" *ngFor="let row of results.controls">
                    {{ row.controls.percent.value }}
                </div>
            </div>
        </div>
    `
})
export class ViewReportResultsTableComponent {
    @Input() results: ResultRowFormArray | any = [];
}

@Component({
    selector: 'app-view-deals-report-results-node',
    styles: [
        `
            .container {
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            /*.row {*/
            /*display: flex;*/
            /*flex-direction: row;*/
            /*}*/
            /*.col {*/
            /*display: flex;*/
            /*flex-direction: column;*/
            /*}*/
            .col1 {
                display: flex;
                flex-direction: column;
            }

            .picker-row {
                align-items: center;
                justify-content: flex-start;
                height: 40px;
                min-width: max-content;
            }

            /*.item-children {*/
            /*display: flex;*/
            /*flex-direction: column;*/
            /*}*/
            .indent {
                width: 50px;
            }

            .mat-icon-button {
                width: unset;
                height: unset;
            }

            button {
            }

            .label {
                font-weight: 700;
                font-size: 20px;
            }
        `
    ],
    template: `
        <div
            *ngIf="resultNode && resultNode.controls && resultNode.controls.picked && resultNode.controls.picked.value"
            class="picker-row"
            [class.picked-row]="resultNode.controls.picked.value"
        >
            <ng-container *ngIf="nestingLevel">
                <span *ngFor="let item of createRange(nestingLevel + 1)" class="indent"></span>
            </ng-container>
            <button
                class="icon-button"
                mat-icon-button
                (click)="toggleCollapse(resultNode, $event)"
                [disabled]="!isExpandable(resultNode)"
            >
                <mat-icon
                    *ngIf="
                        !resultNode ||
                        !resultNode.controls ||
                        !resultNode.controls.expanded ||
                        (!resultNode.controls.expanded.value && isExpandable(resultNode))
                    "
                    >chevron_right
                </mat-icon>
                <mat-icon
                    *ngIf="
                        resultNode &&
                        resultNode.controls &&
                        ((resultNode.controls.expanded && resultNode.controls.expanded.value) ||
                            (resultNode.controls.picked && resultNode.controls.picked.value))
                    "
                    >expand_more
                </mat-icon>
            </button>
            <span *ngIf="resultNode && resultNode.controls && resultNode.controls.label" class="label">
                {{ resultNode.controls.label.value }}
            </span>
            <span *ngFor="let item of createRange(1)" class="indent"></span>
        </div>
        <div style="margin-bottom: 4px">
            <ng-container *ngIf="nestingLevel">
                <span *ngFor="let item of createRange(nestingLevel + 2)" class="indent"></span>
            </ng-container>
            <app-view-deals-report-results-table
                *ngIf="resultNode && resultNode.controls"
                [results]="resultNode.controls.results"
            ></app-view-deals-report-results-table>
            <span *ngFor="let item of createRange(1)" class="indent"></span>
        </div>
        <div
            *ngIf="
                resultNode &&
                resultNode.controls &&
                resultNode.controls.expanded &&
                resultNode.controls.expanded.value &&
                isExpandable(resultNode)
            "
            class="col item-children"
        >
            <ng-container
                *ngIf="
                    resultNode &&
                    resultNode.controls &&
                    resultNode.controls.children &&
                    resultNode.controls.children.controls
                "
            >
                <div *ngFor="let child of resultNode.controls.children.controls" class="col1">
                    <ng-container *ngIf="nestingLevel">
                        <app-view-deals-report-results-node
                            style="width: 100%"
                            [resultNode]="child"
                            [nestingLevel]="nestingLevel + 1"
                        ></app-view-deals-report-results-node>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    `
})
export class ViewReportResultsNodeComponent {
    @Input() resultNode: ReportNodeFormGroup | any = null;
    @Input() nestingLevel: number | null = null;

    toggleCollapse(company_group: ReportNodeFormGroup | any, event: Event) {
        event.stopPropagation();
        if (company_group && company_group.controls && company_group.controls.expanded) {
            company_group.controls.expanded.patchValue(!company_group.controls.expanded.value, {emitEvent: true});
        }
    }

    isExpandable(company_group: ReportNodeFormGroup | any) {
        return company_group.controls.children.length > 0;
    }

    createRange(number: number) {
        const items: number[] = [];
        for (let i = 1; i <= number; i++) {
            items.push(i);
        }
        return items;
    }

    constructor(protected reportsService: ReportsService) {}
}

@Component({
    selector: 'app-view-progress-report',
    template: `
        <div class="deals-report-container" *ngIf="report">
            <div *ngFor="let node of reportViewFormArray.controls" class="col item">
                <app-view-deals-report-results-node
                    style="width: 100%"
                    [resultNode]="node"
                    [nestingLevel]="0"
                ></app-view-deals-report-results-node>
            </div>
        </div>
    `,
    styleUrls: ['view.deals-report.component.scss', 'deals-report.scss']
})
export class ViewReportComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() reportData: {
        found: IReportsPreviewResultData;
        results: ReportNodesFormArray[];
    } | null = null;

    types = {
        tracking: 'Tracking'
    };

    periods = {
        today: 'Today',
        yesterday: 'Yesterday',
        this_week: 'This Week',
        this_month: 'This Month',
        this_quarter: 'This Quarter',
        this_year: 'This Year',
        last_week: 'Last Week',
        last_month: 'Last Month',
        last_quarter: 'Last Quarter',
        last_year: 'Last Year',
        past_7_days: 'Past 7 days',
        past_30_days: 'Past 30 days',
        past_60_days: 'Past 60 days',
        past_90_days: 'Past 90 days',
        custom_dates: 'Custom Dates'
    };

    public report: IReportsPreviewResultData | null = null;
    public results: ReportNodesFormArray[] | [] = [];

    protected reportId: number | null = null;

    protected reportType: string = '';

    public reportViewFormArray: IReportsPreviewResultData = new ReportNodesFormArray([]);

    constructor(
        protected reportsService: ReportsService,
        protected authService: AuthService,
        protected router: Router,
        protected route: ActivatedRoute
    ) {
        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['id']) {
                this.reportId = params['id'];
            }

            if (params['report_type']) {
                this.reportType = params['report_type'];
            }
        });
    }

    ngOnInit() {
        if (this.reportData) {
            this.report = this.reportData.found;
            this.results = this.reportData.results;
            this.reportViewFormArray.patchValue(this.reportData.results);
        } else {
            this.report = null;
            this.results = [];
            this.reportViewFormArray.patchValue([]);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
