import {Component, Inject, OnDestroy, OnInit, ViewChild, ElementRef} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {phoneMaskitoOptions} from '../../../../utilities/maskito';

interface IDialogData {
    type: string;
    hideCancel: boolean;
}

@Component({
    selector: 'app-add-contact-person-dialog',
    templateUrl: 'add-contact-person-dialog.component.html',
    styles: [
        `
            .mat-dialog-content {
                overflow-wrap: break-word;
                white-space: pre-wrap;
            }
        `
    ]
})
export class AddContactPersonDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('phoneInput', {static: true}) phoneInput: ElementRef | undefined;
    @ViewChild('faxInput', {static: true}) faxInput: ElementRef | undefined;

    personGroup: UntypedFormGroup;
    public phoneMaskitoInputMask = phoneMaskitoOptions;
    errors: {phone: boolean; fax: boolean} = {phone: false, fax: false};

    constructor(
        public dialogRef: MatDialogRef<AddContactPersonDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData,
        private fb: UntypedFormBuilder
    ) {
        this.personGroup = this.fb.group({
            first_name: ['', [Validators.minLength(2), Validators.maxLength(40)]],
            last_name: ['', [Validators.minLength(2), Validators.maxLength(40)]],
            full_name: '',
            preferred_name: ['', [Validators.minLength(2), Validators.maxLength(40)]],
            phone: '',
            fax: '',
            email: '',
            type: this.data && this.data.type === 'department' ? 'department' : 'person',
            default_location: null
        });
    }

    ngOnInit() {}

    onBlurMethod(type: 'phone' | 'fax') {
        const tel = this.personGroup.controls[type].value;

        if (tel && tel.replace(/[^0-9]/g, '') && tel.replace(/[^0-9]/g, '').length < 10) {
            this.personGroup.controls[type].setErrors({incorrect: true});
        }
    }

    onClickMethod(type: 'phone' | 'fax') {
        let input: ElementRef;
        if (type === 'phone' && this.phoneInput) {
            input = this.phoneInput as ElementRef;
        } else if (type === 'fax' && this.faxInput) {
            input = this.faxInput as ElementRef;
        } else {
            return;
        }
        let length = input.nativeElement.value.replace(/[^0-9]/g, '').length;
        if (length >= 3) {
            length++;
        }
        if (length >= 7) {
            length++;
        }

        input.nativeElement.selectionStart = length;
        input.nativeElement.selectionEnd = length;
    }

    addPerson() {
        // validate person phone and fax
        if ((this.errors && this.errors.phone) || this.errors.fax) {
            return;
        }

        const person = this.personGroup.getRawValue();
        person.phone = person.phone.replace(/[^0-9]/g, '');
        person.fax = person.fax.replace(/[^0-9]/g, '');

        this.dialogRef.close(person);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
