import {BaseNotification} from './base.notification';
import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-reaction-notification-card',
    template: `
        <div class="col-xs-12" *ngIf="!notification.isViewed()">
            <mat-card class="reaction-notification {{ notification.getType() }}">
                <mat-card-header>
                    <mat-card-title>
                        {{ notification.getTitle() }}
                    </mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    {{ notification.getMessage() }}
                </mat-card-content>

                <mat-card-actions>
                    <div class="buttons-container">
                        <button
                            (click)="possibilityObj.route ? goTo(possibilityObj.route) : possibilityObj.action()"
                            mat-button
                            *ngFor="let possibilityObj of notification.getPossibilities()"
                        >
                            {{ possibilityObj.label }}
                        </button>
                        <!--<button mat-button (click)="cancel()">Cancel</button>-->
                    </div>
                </mat-card-actions>
            </mat-card>
        </div>
    `,
    styles: [
        `
            .mat-card.info {
                background-color: #f9fff7;
            }

            .mat-card.error {
                background-color: #fff7f7;
            }

            .mat-card.warning {
                background-color: #fffcf7;
            }

            .mat-card-title,
            .mat-card-header,
            .mat-card-subtitle {
                font-size: 16px;
            }

            .mat-card-content {
                font-size: 14px;
            }

            .mat-card.info mat-card-header {
                color: green;
            }

            .mat-card.warning mat-card-header {
                color: orange;
            }

            .mat-card.error mat-card-header {
                color: red;
            }

            .buttons-container {
                width: 100%;
                text-align: center;
            }
        `
    ]
})
export class ReactionNotificationCardComponent implements OnInit {
    @Input() notification: any;

    constructor(protected router: Router) {}

    ngOnInit() {}

    cancel() {
        this.notification.hide();
    }

    goTo(routeList: string[]) {
        this.notification.hide();

        this.router.navigate(routeList);
    }
}

export class ReactionPossibility {
    label: string = '';
    route?: string[];
    action?: Function;
}

export class ReactionNotification extends BaseNotification {
    protected position: 'reaction' = 'reaction';

    protected title: string = '';

    protected possibilities: ReactionPossibility[] = [];

    public hide() {
        this.viewed = true;
    }

    public isViewed() {
        return this.viewed;
    }

    public setTitle(title: string) {
        this.title = title;
    }

    public getTitle() {
        return this.title;
    }

    public addPossibility(possibility: ReactionPossibility) {
        this.possibilities.push(possibility);
    }

    public getPossibilities() {
        return this.possibilities;
    }
}
