import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {GenericFormGroup} from '../../../../../../entites/generic.entity';
import {Deal} from '../../../../../../models/deal';
import {firstValueFrom, Subject} from 'rxjs';
import {GatewayTypes, IBill, IInvoice, IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {NotificationsService} from 'angular2-notifications';
import {FinancialTransferEntity} from '../../../../../account-info/compensation/models/financial-transfer.entity';
import {ProcessRemainingPayoutsByMoneyTransferEftDialogComponent} from '../../../../modules/common-deals-module/components/process-remaining-payouts/money-transfer-eft-dialog/process-remaining-payouts-by-money-transfer-eft-dialog.component';
import {
    IDataToBeProcessed,
    IProcessByMoneyTransferEftDialogData,
    IProcessDialogData
} from '../../../../modules/remaining-payouts/types/remaining-payouts.types';
import {ProcessRemainingPayoutsRecordPaymentDialogComponent} from '../../../../modules/common-deals-module/components/process-remaining-payouts/record-payment-dialog/process-remaining-payouts-record-payment-dialog.component';
import {ProcessRemainingPayoutsCheckOnlyDialogComponent} from '../../../../modules/common-deals-module/components/process-remaining-payouts/check-only-dialog/process-remaining-payouts-check-only-dialog.component';
import {ProcessRemainingPayoutsBillCreateOnlyDialogComponent} from '../../../../modules/common-deals-module/components/process-remaining-payouts/bill-create-only-dialog/process-remaining-payouts-bill-create-only-dialog.component';
import {RemainingPayoutsApiService} from '../../../../modules/common-deals-module/services/remaining-payouts.api-service';
import {CompanyGatewayService} from '../../../../../../services/api/finance/company-gateway.service';
import {DealPayoutsSource} from '../../../../../../services/sources/deal-payouts.source';
import {WaitingForPayoutsProcessingDialogComponent} from '@app/modules/deals/modules/common-deals-module/components/waiting-for-payouts-processing-dialog/waiting-for-payouts-processing-dialog.component';
import {PrintChecksDialogComponent} from '@app/modules/finance/components/payments-made/print-checks-dialog/print-checks-dialog.component';

@Component({
    selector: 'app-deal-payouts-remaining',
    template: `
        <div>
            <div class="d-flex" style="font-size: 16px; margin: 5px; font-weight: bold">
                {{ dealTypesMap[deal!.client_type!] }}
                <div *ngIf="deal!.dual_deal_id">&nbsp;(dual agency)</div>
                <span class="sp-spacer"></span>

                <button
                    mat-stroked-button
                    color="primary"
                    rbac
                    [rule]="{deals__manage_payouts: true}"
                    [disabled]="isBatchPayoutsProcessingButtonDisabled"
                    [matMenuTriggerFor]="appMenu"
                    class="ml-3 pr-2"
                >
                    Process Batch Payouts
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #appMenu="matMenu">
                    <button
                        mat-menu-item
                        [disabled]="!zipiFinancialGateways || zipiFinancialGateways.length === 0"
                        (click)="openPayPopup({action_type: 'money_transfer', payout_id: null})"
                    >
                        Money Transfers (EFT)
                    </button>

                    <button mat-menu-item (click)="openPayPopup({action_type: 'record', payout_id: null})">
                        Record Payment
                    </button>

                    <button mat-menu-item (click)="openPayPopup({action_type: 'check', payout_id: null})">
                        Create Checks
                    </button>

                    <button mat-menu-item (click)="openPayPopup({action_type: 'skip', payout_id: null})">
                        Create Bills
                    </button>
                </mat-menu>

                <!--                <div>{{dealFormGroup.getRawValue().company_calculation.commission.amount | currency}}</div>-->
            </div>
            <div *ngIf="companyTransfersArray.length > 0" style="margin-bottom: 15px">
                <div style="font-size: 14px; margin: 5px;">Company Financials</div>
                <div *ngFor="let transfer of companyTransfersArray; let i = index">
                    <ng-container>
                        <app-deal-payouts-card
                            [transferMoneyInGroup]="transfer"
                            [dealFormGroup]="dealFormGroup"
                            [lastUsedAccount]="lastUsedAccount"
                            (updatePayouts)="updatePayouts.emit($event)"
                            (completePayout)="openPayPopup($event)"
                            [cardType]="cardType"
                            [releaseList]="releaseList"
                            [isInfoOnly]="false"
                            [createdBillId]="transfer.connected__bill_fk_id"
                            [isMoneyTransferAvailable]="zipiFinancialGateways && zipiFinancialGateways.length > 0"
                        ></app-deal-payouts-card>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="otherTransfersArray.length > 0">
                <div style="font-size: 14px; margin: 5px 5px 5px 5px;">Other Financials</div>
                <div *ngFor="let transfer of otherTransfersArray; let i = index">
                    <ng-container>
                        <app-deal-payouts-card
                            [transferMoneyInGroup]="transfer"
                            [dealFormGroup]="dealFormGroup"
                            [lastUsedAccount]="lastUsedAccount"
                            (updatePayouts)="updatePayouts.emit($event)"
                            [cardType]="cardType"
                            [releaseList]="releaseList"
                            [isInfoOnly]="false"
                        ></app-deal-payouts-card>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="companyTransfersArray.length === 0 && otherTransfersArray.length === 0" class="m-2">
                <span style="color: #808080FF">No Payouts</span>
            </div>
            <div *ngIf="associateTransfersArray.length > 0">
                <div style="font-size: 14px; margin: 5px 5px 5px 5px;">Involved Financials</div>
                <div *ngFor="let transfer of associateTransfersArray; let i = index">
                    <ng-container>
                        <app-deal-payouts-card
                            [transferMoneyInGroup]="transfer"
                            [dealFormGroup]="dealFormGroup"
                            [lastUsedAccount]="lastUsedAccount"
                            [cardType]="cardType"
                            [isInfoOnly]="true"
                        ></app-deal-payouts-card>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="mt-3" *ngIf="deal!.dual_deal_id && dealFormGroup!.controls!.dual_deal!.value">
            <div class="d-flex" style="font-size: 16px; margin: 5px; font-weight: bold">
                {{ dealTypesMap[deal!.dual_deal!.client_type!] }}
                <span class="sp-spacer"></span>
                <!--                <div>{{dealFormGroup.getRawValue().dual_deal.company_calculation.commission.amount | currency}}</div>-->
            </div>
            <div *ngIf="dualDealCompanyTransfersArray.length > 0" style="margin-bottom: 15px">
                <div style="font-size: 14px; margin: 5px;">Company Financials</div>
                <div *ngFor="let transfer of dualDealCompanyTransfersArray; let i = index">
                    <ng-container>
                        <app-deal-payouts-card
                            [transferMoneyInGroup]="transfer"
                            [dealFormGroup]="dualDealForm!"
                            [cardType]="cardType"
                            [brokerReleasesAmount]="dualDealBrokerReleasesAmount"
                            [isInfoOnly]="true"
                        ></app-deal-payouts-card>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="dualDealOtherTransfersArray.length > 0">
                <div style="font-size: 14px; margin: 5px 5px 5px 5px;">Other Financials</div>
                <div *ngFor="let transfer of dualDealOtherTransfersArray; let i = index">
                    <ng-container>
                        <app-deal-payouts-card
                            [transferMoneyInGroup]="transfer"
                            [dealFormGroup]="dualDealForm!"
                            [cardType]="cardType"
                            [isInfoOnly]="true"
                        ></app-deal-payouts-card>
                    </ng-container>
                </div>
            </div>
            <div
                *ngIf="dualDealCompanyTransfersArray.length === 0 && dualDealOtherTransfersArray.length === 0"
                class="m-2"
            >
                <span style="color: #808080FF">No Payouts</span>
            </div>
            <div *ngIf="dualDealAssociateTransfersArray.length > 0">
                <div style="font-size: 14px; margin: 5px 5px 5px 5px;">Involved Financials</div>
                <div *ngFor="let transfer of dualDealAssociateTransfersArray; let i = index">
                    <ng-container>
                        <app-deal-payouts-card
                            [transferMoneyInGroup]="transfer"
                            [dealFormGroup]="dualDealForm!"
                            [cardType]="cardType"
                            [isInfoOnly]="true"
                        ></app-deal-payouts-card>
                    </ng-container>
                </div>
            </div>
        </div>
    `
})
export class RemainingPayoutsTabComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() dealFormGroup = new GenericFormGroup(new Deal());
    @Input() lastUsedAccount: any;
    @Input() releaseList: any;
    @Input() dualDealBrokerReleasesAmount: number | undefined;

    @Output() updatePayouts = new EventEmitter();

    dualDealForm: GenericFormGroup<Deal> = new GenericFormGroup<Deal>(new Deal());

    // @ts-ignore
    dealTypesMap: {[key: string]: string} = {
        buyer: 'Buyer Side',
        seller: 'Listing Side',
        rental_tenant: 'Rental Tenant',
        rental_owner: 'Rental Owner',
        referral: 'Referral',
        broker_price_opinion: 'Broker Price Opinion'
    };

    transfersArray: FinancialTransferEntity[] = [];
    companyTransfersArray: Array<FinancialTransferEntity & {is_show_batch_button: boolean}> = [];
    dualDealCompanyTransfersArray: FinancialTransferEntity[] = [];
    otherTransfersArray: FinancialTransferEntity[] = [];
    dualDealOtherTransfersArray: FinancialTransferEntity[] = [];
    associateTransfersArray: FinancialTransferEntity[] = [];
    dualDealAssociateTransfersArray: FinancialTransferEntity[] = [];
    invoices: IInvoice[] = [];
    bills: IBill[] = [];
    showedBatchNames: string[] = [];

    cardType = 'remaining';
    escrowAgent: any;

    deal: Deal | undefined;

    zipiFinancialGateways: IPaymentGateway[] = [];

    isBatchPayoutsProcessingButtonDisabled: boolean = false;

    constructor(
        public dialog: MatDialog,
        public router: Router,
        private ntfs: NotificationsService,
        private remainingPayoutsService: RemainingPayoutsApiService,
        private companyGatewayService: CompanyGatewayService,
        public dealPayoutsSource: DealPayoutsSource
    ) {}

    async ngOnInit() {
        this.dealPayoutsSource.payoutEvents.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            for (const element of this.companyTransfersArray) {
                if (data.financial_transfer_id === element.id && element.type !== 'company_payout') {
                    element.connected__bill_fk_id = data.connected_entity_id;
                    if (data.connected_entity_id && data.status === 'completed') {
                        element.processing_status = 'open';
                        element.connected_bill = {
                            bill_id: data.connected_entity_id,
                            bill_number: data.connected_entity_number,
                            pending_balance: data.pending_balance,
                            total_amount: element.amount
                        } as any;
                    } else {
                        element.processing_status = data.status as any;
                    }
                }
            }
            const updatedFinTransfers = this.dealFormGroup.getRawValue().financial_transfers.map((transfer) => {
                if (Number(data.financial_transfer_id) === Number(transfer.id) && transfer.type !== 'company_payout') {
                    transfer.connected__bill_fk_id = data.connected_entity_id;

                    if (data.connected_entity_id && data.status === 'completed') {
                        transfer.processing_status = 'open';
                        transfer.connected_bill = {
                            bill_id: data.connected_entity_id,
                            bill_number: data.connected_entity_number,
                            pending_balance: data.pending_balance,
                            total_amount: transfer.amount
                        } as any;
                    } else {
                        transfer.processing_status = data.status as any;
                    }
                }
                return transfer;
            });
            this.isBatchPayoutsProcessingButtonDisabled =
                this.companyTransfersArray.every((payout) => !!payout.connected__bill_fk_id) ||
                !this.dealFormGroup.getRawValue().disbursement_approved;

            this.dealFormGroup.controls.financial_transfers!.patchValue(updatedFinTransfers, {emitEvent: false});
        });

        if (this.dealFormGroup.getRawValue().deal_participants.escrow_agent) {
            this.escrowAgent = this.dealFormGroup.getRawValue().deal_participants.escrow_agent;
        }

        this.getTransfersArray();
        this.dealFormGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((change) => {
            this.deal = this.dealFormGroup.getRawValue();
            this.getTransfersArray();
        });

        this.setupTab();

        this.zipiFinancialGateways = await this.getZipiGateways(['zipi_financial_business'], null);
    }

    async getZipiGateways(allowedTypes: Array<GatewayTypes>, contact_id: number | null) {
        return firstValueFrom(this.companyGatewayService.getCompanyGatewaysByTypes(allowedTypes, contact_id));
    }

    setupTab() {
        this.deal = this.dealFormGroup.getRawValue();
    }

    getTransfersArray() {
        this.showedBatchNames = [];
        this.companyTransfersArray = [];
        this.otherTransfersArray = [];
        this.dualDealCompanyTransfersArray = [];
        this.dualDealOtherTransfersArray = [];
        this.associateTransfersArray = [];
        this.dualDealAssociateTransfersArray = [];
        this.transfersArray = this.dealFormGroup.getRawValue().remaining_payouts;

        this.transfersArray.forEach((transfer) => {
            if (
                (transfer.sender &&
                    transfer.sender.contact_id === this.dealFormGroup.getRawValue().company!.main__contact_fk_id) ||
                (transfer.receiver &&
                    transfer.receiver.contact_id === this.dealFormGroup.getRawValue().company!.main__contact_fk_id)
            ) {
                const transferObj = {
                    ...transfer,
                    ...{is_show_batch_button: this.isShowBatchButton(this.transfersArray, transfer)}
                };
                // @ts-ignore
                this.companyTransfersArray.push(transferObj);
            } else {
                this.otherTransfersArray.push(transfer);
            }
        });

        this.associateTransfersArray = this.dealFormGroup.controls
            .financial_transfers!.controls.filter((transferFG) => {
                const ft = transferFG.getRawValue(FinancialTransferEntity);
                return !ft.isEscrowTransfer && ft.type && ['associate_transfer'].includes(ft.type);
            })
            .map((transferFG) => transferFG.getRawValue());

        this.associateTransfersArray.sort(compareFullFromToName);
        this.companyTransfersArray.sort(compareFullFromToName);
        this.otherTransfersArray.sort(compareFullFromToName);

        this.isBatchPayoutsProcessingButtonDisabled =
            this.companyTransfersArray.every((payout) => !!payout.connected__bill_fk_id) ||
            !this.dealFormGroup.getRawValue().disbursement_approved;

        if (this.dealFormGroup.getRawValue().dual_deal_id && this.dealFormGroup.getRawValue().dual_deal) {
            const dualDeal = this.dealFormGroup.getRawValue().dual_deal;
            if (dualDeal) {
                this.dualDealForm = new GenericFormGroup(dualDeal);
                const dualTransfers = this.dealFormGroup.getRawValue().dual_deal!.remaining_payouts;
                dualTransfers.forEach((transfer) => {
                    if (
                        (transfer.sender &&
                            transfer.sender.contact_id ===
                                this.dealFormGroup.getRawValue().company!.main__contact_fk_id) ||
                        (transfer.receiver &&
                            transfer.receiver.contact_id ===
                                this.dealFormGroup.getRawValue().company!.main__contact_fk_id)
                    ) {
                        const transferObj = {
                            ...transfer,
                            ...{is_show_batch_button: this.isShowBatchButton(this.transfersArray, transfer)}
                        };
                        // @ts-ignore
                        this.dualDealCompanyTransfersArray.push(transferObj);
                    } else {
                        this.dualDealOtherTransfersArray.push(transfer);
                    }
                });
                // @ts-ignore
                this.dualDealAssociateTransfersArray = this.dualDealForm
                    .get('financial_transfers')!
                    ['controls'].filter((transferFG: GenericFormGroup<FinancialTransferEntity>) => {
                        const ft = transferFG.getRawValue(FinancialTransferEntity);
                        return !ft.isEscrowTransfer && ft.type && ['associate_transfer'].includes(ft.type);
                    })
                    .map((transferFG: GenericFormGroup<FinancialTransferEntity>) => transferFG.getRawValue());
                this.dualDealAssociateTransfersArray.sort(compareFullFromToName);
                this.dualDealCompanyTransfersArray.sort(compareFullFromToName);
                this.dualDealOtherTransfersArray.sort(compareFullFromToName);
            }
        }
    }

    isShowBatchButton(array: FinancialTransferEntity[], item: FinancialTransferEntity) {
        if (this.showedBatchNames.indexOf(this.getFromToName(item)) === -1 && !this.isInvoiceToCreate(item)) {
            const filtered = array.filter(
                (arrayItem) =>
                    this.getFromToName(arrayItem) === this.getFromToName(item) &&
                    ((arrayItem.connected__bill_fk_id &&
                        arrayItem.connected_bill &&
                        arrayItem.connected_bill.summary_status !== 'paid') ||
                        !arrayItem.connected__bill_fk_id)
            );
            if (filtered.length > 1) {
                this.showedBatchNames.push(this.getFromToName(item));
                return true;
            }
        }
        return false;
    }

    isInvoiceToCreate(transfer: FinancialTransferEntity) {
        if (
            transfer.receiver &&
            transfer.receiver.contact_id === this.dealFormGroup.getRawValue().company!.main__contact_fk_id
        ) {
            return true;
        }
        return false;
    }

    getFromToName(transfer: FinancialTransferEntity) {
        return `${transfer.sender ? transfer.sender.link_title : ''}_${transfer.receiver ? transfer.receiver.link_title : ''}`;
    }

    openPayPopup(data: {action_type: 'money_transfer' | 'check' | 'record' | 'skip'; payout_id: number | null}) {
        let dialogRef = null;
        let selectedPayoutIds: Array<number> = [];
        if (data.payout_id) {
            selectedPayoutIds = [data.payout_id];
        } else {
            selectedPayoutIds = this.companyTransfersArray.map((payout) => payout.id) as any;
        }

        switch (data.action_type) {
            case 'money_transfer': {
                dialogRef = this.dialog.open<
                    ProcessRemainingPayoutsByMoneyTransferEftDialogComponent,
                    IProcessByMoneyTransferEftDialogData
                >(ProcessRemainingPayoutsByMoneyTransferEftDialogComponent, {
                    minWidth: 996,
                    maxHeight: '80vh',
                    disableClose: true,
                    panelClass: 'no-padding-dialog',
                    autoFocus: false,
                    data: {
                        payoutIds: selectedPayoutIds,
                        eftGateways: this.zipiFinancialGateways,
                        isCombineDisabled: true
                    }
                });
                break;
            }
            case 'record': {
                dialogRef = this.dialog.open<ProcessRemainingPayoutsRecordPaymentDialogComponent, IProcessDialogData>(
                    ProcessRemainingPayoutsRecordPaymentDialogComponent,
                    {
                        minWidth: 917,
                        maxWidth: 1272,
                        maxHeight: '80vh',
                        disableClose: true,
                        panelClass: 'no-padding-dialog',
                        autoFocus: false,
                        data: {
                            payoutIds: selectedPayoutIds,
                            isCombineDisabled: true
                        }
                    }
                );
                break;
            }
            case 'check': {
                dialogRef = this.dialog.open<ProcessRemainingPayoutsCheckOnlyDialogComponent, IProcessDialogData>(
                    ProcessRemainingPayoutsCheckOnlyDialogComponent,
                    {
                        minWidth: 1272,
                        maxHeight: '80vh',
                        disableClose: true,
                        panelClass: 'no-padding-dialog',
                        autoFocus: false,
                        data: {
                            payoutIds: selectedPayoutIds,
                            isCombineDisabled: true
                        }
                    }
                );
                break;
            }
            case 'skip': {
                dialogRef = this.dialog.open<ProcessRemainingPayoutsBillCreateOnlyDialogComponent, IProcessDialogData>(
                    ProcessRemainingPayoutsBillCreateOnlyDialogComponent,
                    {
                        minWidth: 507,
                        maxHeight: '80vh',
                        disableClose: true,
                        panelClass: 'no-padding-dialog',
                        autoFocus: false,
                        data: {
                            payoutIds: selectedPayoutIds,
                            isCombineDisabled: true
                        }
                    }
                );
                break;
            }
        }

        if (dialogRef) {
            dialogRef
                .afterClosed()
                .pipe(
                    filter((c) => !!c),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((dataToProcess: IDataToBeProcessed) => {
                    if (dataToProcess && dataToProcess.payouts && dataToProcess.payouts.length > 0) {
                        if (dataToProcess.processing_mode === 'check' && dataToProcess.after_action === 'print') {
                            const payoutIds = [];
                            for (const payout of dataToProcess.payouts) {
                                payoutIds.push(payout.financial_transfer_id);
                            }
                            this.openWaitingPopup(payoutIds);
                        }
                        this.remainingPayoutsService
                            .startPayoutsProcessing(dataToProcess)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((response) => {
                                if (
                                    response.status === 'success' &&
                                    response.payouts_in_processing &&
                                    response.payouts_in_processing.length > 0
                                ) {
                                    if (response.payouts_in_processing.length === 1) {
                                        this.ntfs.success('Payment submitted for processing');
                                    } else {
                                        this.ntfs.success('Payments submitted for processing');
                                    }
                                }
                            });
                    }
                });
        }
    }

    async openWaitingPopup(payoutIds: Array<number>) {
        const dialogRef = this.dialog.open(WaitingForPayoutsProcessingDialogComponent, {
            minWidth: 507,
            maxHeight: '80vh',
            disableClose: true,
            panelClass: 'no-padding-dialog',
            autoFocus: false,
            data: {
                payoutIds
            }
        });

        // waitingResult = undefined if dialog was closed without any result
        const waitingResult: Array<number> | undefined = await firstValueFrom(dialogRef.afterClosed());
        if (!waitingResult) {
            return null;
        }

        const printDialogRef = this.dialog.open(PrintChecksDialogComponent, {
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            maxWidth: '100%',
            panelClass: 'no-padding-dialog',
            data: {paymentMadeIds: waitingResult, paymentMadeItems: []}
        });

        printDialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {});
    }

    emitUpdatePayouts() {
        this.updatePayouts.emit();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.updatePayouts.complete();
    }
}

function compareFullFromToName(a: FinancialTransferEntity, b: FinancialTransferEntity) {
    const full_from_to_name_a = `${a.sender ? a.sender.link_title : ''}_${a.receiver ? a.receiver.link_title : ''}`;
    const full_from_to_name_b = `${b.sender ? b.sender.link_title : ''}_${b.receiver ? b.receiver.link_title : ''}`;
    if (full_from_to_name_a > full_from_to_name_b) {
        return -1;
    }
    if (full_from_to_name_a < full_from_to_name_b) {
        return 1;
    }
    return 0;
}
