<div>
    <div *ngIf="formGroup">
        <app-notifications [notification_mode]="'popup'"></app-notifications>
    </div>
    <h2>Transfer Money</h2>

    <div [formGroup]="formGroup">
        <mat-form-field class="w-100">
            <mat-label>Source</mat-label>
            <mat-select [formControl]="formGroup.controls.paid_by__payment_method_fk_id">
                <mat-optgroup
                    *ngFor="let gateway of paymentGateways"
                    [label]="gateway.title"
                    [disabled]="gateway.status !== 'active'"
                >
                    <ng-container *ngFor="let pm of gateway.payment_methods">
                        <mat-option
                            *ngIf="pm.is_available_for_payment"
                            [value]="pm.payment_method_id"
                            [disabled]="
                                pm.status !== 'active' ||
                                !pm.related__ledger_account_fk_id ||
                                pm.payment_method_id === formGroup.controls.pay_to__payment_method_fk_id.value
                            "
                        >
                            {{ pm.title }}
                        </mat-option>
                    </ng-container>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="w-100">
            <mat-label>Destination</mat-label>
            <mat-select [formControl]="formGroup.controls.pay_to__payment_method_fk_id">
                <mat-optgroup
                    *ngFor="let gateway of paymentGateways"
                    [label]="gateway.title"
                    [disabled]="gateway.status !== 'active'"
                >
                    <ng-container *ngFor="let pm of gateway.payment_methods">
                        <mat-option
                            *ngIf="pm.is_available_for_payment"
                            [value]="pm.payment_method_id"
                            [disabled]="
                                pm.status !== 'active' ||
                                !pm.related__ledger_account_fk_id ||
                                pm.payment_method_id === formGroup.controls.paid_by__payment_method_fk_id.value
                            "
                        >
                            {{ pm.title }}
                        </mat-option>
                    </ng-container>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="w-100">
            <input matInput formControlName="reference" placeholder="Reference" type="text" autocomplete="off" />
        </mat-form-field>

        <mat-form-field class="w-100">
            <input
                matInput
                [maskito]="currencyMaskitoMask"
                formControlName="amount"
                placeholder="Amount"
                type="text"
                autocomplete="off"
            />
        </mat-form-field>
    </div>

    <div class="d-flex justify-content-end mt-3">
        <button *ngIf="!data.hideCancel" mat-raised-button (click)="dialogRef.close(false)" tabindex="1">Cancel</button>
        <button
            mat-raised-button
            class="ml-2"
            color="primary"
            tabindex="-1"
            [disabled]="isProcessing"
            (click)="initiateTransfer()"
        >
            Initiate Transfer
        </button>
    </div>
</div>
