<table mat-table [dataSource]="reportData" class="mat-elevation-z8 w-100" *ngIf="reportData.length">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Agent Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="compensation_profile_title">
        <th mat-header-cell *matHeaderCellDef>Rule Name</th>
        <td mat-cell *matCellDef="let element">{{ element.compensation_profile_title }}</td>
    </ng-container>
    <ng-container matColumnDef="financial_element_title">
        <th mat-header-cell *matHeaderCellDef>Deal Element Name</th>
        <td mat-cell *matCellDef="let element">{{ element.financial_element_title }}</td>
    </ng-container>
    <ng-container matColumnDef="financial_element_type">
        <th mat-header-cell *matHeaderCellDef>Deal Element Type</th>
        <td mat-cell *matCellDef="let element">
            {{ financialElementTypeLabels[element.financial_element_type] ?? '-' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="current_rule_name">
        <th mat-header-cell *matHeaderCellDef>Tier Name</th>
        <td mat-cell *matCellDef="let element">{{ element.current_rule_name }}</td>
    </ng-container>
    <ng-container matColumnDef="rule_target">
        <th mat-header-cell *matHeaderCellDef>Tier Target</th>
        <td mat-cell *matCellDef="let element">{{ element.rule_target | reportFormatted: 'financial' }}</td>
    </ng-container>
    <ng-container matColumnDef="condition_type">
        <th mat-header-cell *matHeaderCellDef>Condition Type or Metric</th>
        <td mat-cell *matCellDef="let element">{{ element.condition_type }}</td>
    </ng-container>
    <ng-container matColumnDef="product_name">
        <th mat-header-cell *matHeaderCellDef>Product/Service</th>
        <td mat-cell *matCellDef="let element">{{ element.product_name }}</td>
    </ng-container>
    <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef>Cap Start Date</th>
        <td mat-cell *matCellDef="let element">{{ element.startDate }}</td>
    </ng-container>
    <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef>Cap End Date</th>
        <td mat-cell *matCellDef="let element">{{ element.endDate }}</td>
    </ng-container>
    <ng-container matColumnDef="current_status">
        <th mat-header-cell *matHeaderCellDef>Current Amount</th>
        <td mat-cell *matCellDef="let element">{{ element.current_status | reportFormatted: 'financial' }}</td>
    </ng-container>
</table>

<mat-card *ngIf="!reportData.length">
    <h4>
        We didn't find any data matching report criteria. So there nothing to show. Please adjust report parameters to
        get some results
    </h4>
</mat-card>
