import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IAutomation, ITag} from '@cyberco-nodejs/zipi-typings/index';
import {GenericFormArray, GenericFormGroup} from 'app/entites/generic.entity';
import {Automation, TriggerCondition} from 'app/models/automation';
import {MatRadioChange} from '@angular/material/radio';
import {takeUntil} from 'rxjs/operators';
import {AutomationService} from 'app/services/automation.service';
import {Deal} from 'app/models/deal';
import {select, Store} from '@ngrx/store';
import {ICompanyWideState} from 'app/store/company-wide/company-wide.reducer';
import {selectTagCategories} from 'app/store/company-wide/company-wide.selectors';
import {EditTagCategoryDialogComponent} from '../edit-tag-category/edit-tag-category-dialog.component';

export class CustomFormGroup extends UntypedFormGroup {
    public controls: {
        automation_id: UntypedFormControl;
        company_fk_id: UntypedFormControl;
        title: UntypedFormControl;
        action_type: UntypedFormControl;
        action_option_id: UntypedFormControl;
    };

    constructor(controls: {
        automation_id: UntypedFormControl;
        company_fk_id: UntypedFormControl;
        title: UntypedFormControl;
        action_type: UntypedFormControl;
        action_option_id: UntypedFormControl;
    }) {
        super(controls);
        this.controls = controls;
    }
}

@Component({
    selector: 'app-automation-dialog',
    templateUrl: './automation-dialog.component.html',
    styles: [
        `
            .warning-block {
                color: #ff6000;
                background-color: #f5e8c1;
                border: solid 1px #ff6000;
            }
        `
    ]
})
export class AutomationDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    dealFields = [
        {value: 'name', valueType: 'text'},
        {value: 'address', valueType: 'text'},
        {value: 'city', valueType: 'text'},
        {value: 'zip', valueType: 'text'},
        {value: 'state', valueType: 'text'},
        {value: 'status', valueType: 'text'},
        {value: 'type', valueType: 'text'}
    ];
    DEAL = Deal;

    automation: Automation | undefined = undefined;
    automationFG: CustomFormGroup = new CustomFormGroup({
        automation_id: new UntypedFormControl(null, Validators.required),
        company_fk_id: new UntypedFormControl(null, Validators.required),
        title: new UntypedFormControl('', Validators.required),
        action_type: new UntypedFormControl('add_tag_to_deal', Validators.required),
        action_option_id: new UntypedFormControl(null, Validators.required)
    });
    itemFC: UntypedFormControl = new UntypedFormControl('deal');
    conditionFG: GenericFormArray<TriggerCondition> = new GenericFormArray<TriggerCondition>([]);

    items = ['deal'];
    types = ['text', 'number'];
    textFilteredTypes = [
        {title: 'Contains', value: 'contains'},
        {title: 'Does Not Contain', value: 'not_contain'},
        {title: 'Equals', value: 'equals'},
        {title: 'Does Not Equal', value: 'not_equal'},
        // {title: 'Starts With', value: 'starts_with'},
        // {title: 'Ends With', value: 'ends_with'},
        {title: 'Empty', value: 'empty'},
        {title: 'Multiple Items', value: 'in_array'}
    ];
    numberFilteredTypes = [
        {title: 'Contains', value: 'contains_number'},
        {title: 'Does Not Contain', value: 'not_contain_number'},
        {title: 'Less Than', value: 'less_than'},
        // {title: 'Less Than Or Equals', value: 'less_than_or_equals'},
        {title: 'Greater Than', value: 'greater_than'},
        // {title: 'Greater Than Or Equals', value: 'greater_than_or_equals'},
        // {title: 'In Range', value: 'in_range'},
        {title: 'Empty', value: 'empty'},
        {title: 'Multiple Items', value: 'in_array_numbers'}
    ];

    tags: ITag[] = [];

    disableButton: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<AutomationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private store: Store<ICompanyWideState>,
        private automationService: AutomationService,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        if (this.data?.id) {
            this.automation = this.data;
            if (!this.automation) {
                return;
            }
            this.automationFG.patchValue(this.automation);
            this.conditionFG.patchValue(this.automation.trigger_condition);
        } else {
            this.addCondition();
        }

        // set array of company tags
        this.store.pipe(select(selectTagCategories), takeUntil(this.unsubscribe)).subscribe((res) => {
            if (res) {
                this.tags = res.reduce((acc, curr) => {
                    const tags = curr.tags.map((tag: ITag) => {
                        tag.category_title = curr.title;
                        return tag;
                    });
                    acc = [...acc, ...tags];
                    return acc;
                }, [] as ITag[]);
            }
        });
    }

    addCondition(condition?: TriggerCondition) {
        this.conditionFG.push(
            new GenericFormGroup({
                field: condition ? condition.field : null,
                valueType: condition ? condition.valueType : null,
                type: condition ? condition.type : 'contains',
                filter: condition ? condition.filter : null,
                operator: condition ? condition.operator : null
            })
        );
    }

    async saveAutoTag() {
        if (this.validateErrors()) {
            return;
        }
        this.disableButton = true;

        // get value
        const conditions = this.conditionFG.getRawValue();
        this.automation = this.automationFG.getRawValue();
        if (!this.automation) {
            return;
        }
        this.automation.trigger_condition = conditions;
        this.automation.is_autotag_applied = false;

        this.automationService
            .createAutomation(this.automation)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((auto) => {
                this.close(auto);
            });
    }

    async updateAutoTag() {
        if (this.validateErrors()) {
            return;
        }
        this.disableButton = true;

        // get value
        const conditions = this.conditionFG.getRawValue();
        this.automation = this.automationFG.getRawValue();
        if (!this.automation || !this.automation.automation_id) {
            return;
        }
        this.automation.trigger_condition = conditions;
        this.automation.is_autotag_applied = false;

        // firstly delete previous conditions
        await this.automationService.deleteAutogeneratedTags(this.automation.automation_id);

        // update automation
        this.automationService
            .updateAutomation(this.automation)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((auto) => {
                this.close(auto);
            });
    }

    validateErrors() {
        let errors = false;

        this.automationFG.get('action_option_id')?.markAllAsTouched();
        errors = !!this.automationFG.get('action_option_id')?.errors;

        this.conditionFG.controls.forEach((c) => {
            c.markAllAsTouched();
            if (c.get('field')?.errors || c.get('filter')?.errors) {
                errors = true;
            }
        });

        return errors;
    }

    close(result: IAutomation | boolean) {
        this.dialogRef.close(result);
    }

    object_keys(obj: Object): string[] {
        return Object.keys(obj);
    }

    selectValueType(value: string, index: number) {
        const valueType = this.dealFields.find((f) => f.value === value)?.valueType;
        this.conditionFG.controls[index]?.controls?.valueType?.setValue(valueType);
        if (value === 'status' || value === 'type') {
            this.conditionFG.controls[index]?.controls?.type?.setValue('in_array');
        }
    }

    selectRadioButton(e: MatRadioChange, index: number) {
        if (e.value === null) {
            this.conditionFG.controls[index]?.controls?.operator?.reset();
            const values = this.conditionFG.getRawValue().slice(0, index + 1);
            this.conditionFG.patchValue(values);
            return;
        }

        if (this.conditionFG.controls.length < 2) {
            this.addCondition();
        }
    }

    createNewTag() {
        const dialogRef = this.dialog.open(EditTagCategoryDialogComponent, {
            width: '500px',
            data: {
                tag_category: {}
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {});
    }

    unselectDeactivatedTag(tag: ITag) {
        // if tag exists in the selected list and is deactivated
        if (this.automationFG.controls.action_option_id.value === tag.tag_id && !tag.deactivated) {
            return;
        }

        // update selected tags
        this.automationFG.controls.action_option_id.patchValue(null);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
