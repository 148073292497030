<mat-form-field class="amount w-100" *ngIf="currentInputType === 'numeric'">
    <input
        matInput
        [placeholder]="placeholder"
        [formControl]="valueFC"
        autocomplete="off"
        [maskito]="
            type === 'currency'
                ? allowNegative
                    ? currencyWithNegativeMaskitoMask
                    : currencyMaskitoMask
                : numberMaskitoMask
        "
    />
    <button
        *ngIf="wildcardList.length"
        matSuffix
        mat-icon-button
        aria-label="Switch value type to wildcard 1"
        (click)="changeType('wildcard')"
    >
        <mat-icon>dynamic_feed</mat-icon>
    </button>
</mat-form-field>

<mat-form-field class="w-100" *ngIf="currentInputType === 'wildcard'">
    <mat-select [placeholder]="placeholderAttribute" [formControl]="valueWildcardIdFC">
        <mat-option *ngFor="let wildcard of this.wildcardList" [value]="wildcard.wildcard_id">{{
            wildcard.title
        }}</mat-option>
    </mat-select>
    <button
        *ngIf="wildcardList.length"
        matSuffix
        mat-icon-button
        aria-label="Switch value type to text"
        (click)="changeType('numeric')"
    >
        <mat-icon>calculate</mat-icon>
    </button>
</mat-form-field>
