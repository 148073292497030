import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {IndicatorsState} from '../store/indicators.reducer';
import {selectUploadedFiles} from '../../../store/root.selectors';
import {ClearUploadListAction} from '../store/indicators.actions';
import {map, takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';

@Component({
    selector: 'app-upload-progress',
    templateUrl: 'upload-progress.component.html',
    styleUrls: ['upload-progress.component.css']
})
export class UploadProgressComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    isCollapsed = false;
    isUploading$: Observable<any> | undefined;
    uploadedFile$: Observable<any> | undefined;

    constructor(private store: Store<IndicatorsState>) {}

    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    closeWidget() {
        this.store.dispatch(new ClearUploadListAction());
    }

    ngOnInit() {
        this.uploadedFile$ = this.store.pipe(select(selectUploadedFiles), takeUntil(this.unsubscribe));

        this.isUploading$ = this.uploadedFile$.pipe(
            map((ufx: any) => ufx.some((uf: any) => uf.status === 'progress')),
            takeUntil(this.unsubscribe)
        );
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
