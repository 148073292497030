import {Component, Inject, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormBuilder} from '@angular/forms';
import {combineLatest, Subject} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';
import {IContact, ICreditNote, IPaymentReceived} from '@cyberco-nodejs/zipi-typings';
import {MatTableDataSource} from '@angular/material/table';
import {PaymentsReceivedService} from '../../../finance/services/payments-received.service';
import {Router} from '@angular/router';
import {CreditNotesService} from '../../../../services/api/finance/credit-notes.service';

interface IDialogData {
    contact: IContact;
    excess: number | undefined;
}

@Component({
    selector: 'app-credit-notes-details-dialog',
    templateUrl: 'credit-notes-details-dialog.component.html'
})
export class CreditNotesDetailsDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    dataSource: MatTableDataSource<ICreditNote>;
    displayedColumns = ['credit_info', 'date', 'credit_number', 'total_amount', 'balance', 'actions'];
    contact: IContact;
    excess: number | undefined;

    constructor(
        public dialogRef: MatDialogRef<CreditNotesDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData,
        private fb: UntypedFormBuilder,
        private changeDetector: ChangeDetectorRef,
        private paymentsReceivedService: PaymentsReceivedService,
        private creditNotesService: CreditNotesService,
        protected router: Router
    ) {
        this.dataSource = new MatTableDataSource<ICreditNote>([]);
        this.contact = this.data.contact;
        this.excess = this.data.excess;
    }

    ngOnInit() {
        if (this.contact.id) {
            combineLatest(
                this.creditNotesService.getContactExcessCreditNotes(this.contact.id),
                this.paymentsReceivedService.getContactOverpaymentPaymentsReceived(this.contact.id)
            )
                .pipe(
                    map(([credits, payments]) => {
                        const paymentsReceived: Array<ICreditNote | IPaymentReceived> = payments;
                        const creditNotes: ICreditNote[] = credits;

                        return paymentsReceived.concat(creditNotes);
                    }),
                    tap((data) => {
                        // @ts-ignore
                        this.dataSource.data = data;
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe();
        }
    }

    applyToInvoice(element: IPaymentReceived | ICreditNote) {
        this.dialogRef.close(element);
    }

    routeToElement(element: IPaymentReceived | ICreditNote) {
        this.dialogRef.close();

        if ('payment_received_id' in element && element.payment_received_id) {
            this.router.navigate(['sales/paymentsreceived', element.payment_received_id]);
        } else if ('credit_note_id' in element) {
            this.router.navigate(['sales/creditnotes', element.credit_note_id]);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.dataSource.disconnect();
    }
}
