<div id="dialog" class="forgot-password-container">
    <div md-dialog-title class="header">Forgot your password?</div>

    <mat-card-content class="trackers-data" *ngIf="!dataIsSent">
        <form id="email-form" #emailForm="ngForm" (ngSubmit)="sendEmail(emailForm)">
            <mat-form-field class="email-input">
                <input
                    matInput
                    required
                    [(ngModel)]="email"
                    [email]="true"
                    name="email"
                    placeholder="Enter your Email Address"
                    type="email"
                />
            </mat-form-field>
        </form>
    </mat-card-content>

    <mat-card-content class="trackers-data" *ngIf="dataIsSent">
        <p>
            If the submitted email matches a SkySlope Books user account, a password reset will be sent to that email
            address.
        </p>
    </mat-card-content>

    <div md-dialog-actions class="action-buttons">
        <button mat-button (click)="dialogRef.close()">Close</button>
        <button mat-button class="mat-primary" *ngIf="!dataIsSent" form="email-form">Send</button>
    </div>
</div>
