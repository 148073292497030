import {BaseModel} from './base.model';
import {GroupMemberEntity} from './group-member.model';
import {GenericEntity} from '../entites/generic.entity';
import {IContact} from '@cyberco-nodejs/zipi-typings/contacts.typings';
import {ICompany, IGroup, IGroupMember, IProfile} from '@cyberco-nodejs/zipi-typings';
import {Profile} from './profile';

export class GroupPermissions extends GenericEntity {
    goals_progress_tracking: boolean = false;
    deals_pipeline: boolean = false;
    use_in_compensation_as_entity_group: boolean = false;
    use_in_compensation_as_group_of_entities: boolean = false;

    setUseInCompensationAsEntityGroup(permission: boolean) {
        this.use_in_compensation_as_entity_group = permission;
        return this;
    }

    setUseInCompensationAsGroupOfEntities(permission: boolean) {
        this.use_in_compensation_as_group_of_entities = permission;
        return this;
    }
}

export class Group extends BaseModel implements IGroup {
    id?: number | null = null;
    company: any = null;
    // @ts-ignore
    contact?: IContact | null = null;
    title: string | null = null;

    // @ts-ignore
    members: GroupMemberEntity[] = [];
    enumerable: any[] = [];
    company_fk_id: number | null = null;
    email?: string = '';

    parent__group_fk_id: number | null = null;
    parent_group: Group | null = null;
    number_of_members: number | null = null;

    sort_order: number = 0;

    type: string | null = null;

    financial__profile_fk_id: number | null = null;
    // @ts-ignore
    financial_profile?: Profile | null = null;
    permissions: GroupPermissions = new GroupPermissions();

    changed: boolean = false;

    anniversary_date: number | null = null;

    order: number | null = null;
    profile_fk_id: number | null = null;
    is_hidden: boolean | null = null;
    // @ts-ignore
    profile: IProfile | null = null;
    tag: any = null;
    contact_fk_id: number | null = null;
    child_level: number | null = null;

    isPersonalGroup: boolean = false;
    isGroupSettingsUpdate: boolean = false;

    setType(type: string): Group {
        this.type = type;
        return this;
    }

    setPermissions(permissions: GroupPermissions): Group {
        this.permissions = permissions;
        return this;
    }

    static get type_SET() {
        return {
            default: 'default',
            focus_area: 'focus_area',
            compensation: 'compensation',
            division: 'division'
        };
    }
}
