import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {IJournalBase, IJournalTemplate} from '@cyberco-nodejs/zipi-typings';
import {JournalsTemplatesService} from 'app/services/api/finance/journal-templates.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormArray, UntypedFormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotificationsService} from 'angular2-notifications';
import {GenericFormArray} from '../../../../../entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {FormGroupWithGroupAsControl} from '../../../../../typings/common';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';

export interface IMaybeValidJournalTemplate {
    valid: boolean;
    template: IJournalBase | null;
}

@Component({
    selector: 'app-journal-template',
    templateUrl: 'journal-template.component.html',
    styleUrls: ['journal-template.component.scss']
})
export class JournalTemplateComponent implements OnInit, OnChanges, OnDestroy {
    @Input() journalTemplate: IJournalBase | null = null;
    @Output() onUpdate = new EventEmitter<IMaybeValidJournalTemplate>();

    @Input() closeEvent: Subject<boolean> = new Subject();

    private unsubscribe: Subject<void> = new Subject();

    currencyMaskitoMask = currencyMaskitoOptions;
    lineItemsArray: UntypedFormArray = this.fb.array([]);
    formGroup: FormGroupWithGroupAsControl = this.fb.group({
        reference_number: [null],
        type: ['both'],
        notes: [null],
        line_items: this.lineItemsArray
    }) as FormGroupWithGroupAsControl;

    isDebitCreditEqual: boolean = true;
    hasZeroAmount: boolean = true;
    total_debit: number = 0;
    total_credit: number = 0;

    constructor(
        private journalsTemplatesService: JournalsTemplatesService,
        private route: ActivatedRoute,
        public router: Router,
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService
    ) {}

    ngOnInit() {
        this.closeEvent.pipe(takeUntil(this.unsubscribe)).subscribe((e) => {
            this.lineItemsArray.reset();
            this.formGroup.reset();
        });

        if (this.journalTemplate) {
            this.formGroup.patchValue(this.journalTemplate);

            const control = <UntypedFormArray>this.lineItemsArray;

            for (const item of this.journalTemplate.line_items) {
                const group = this.fb.group({
                    ledger_account_fk_id:
                        this.formGroup?.controls.type.value === 'cash'
                            ? item.cash__ledger_account_fk_id
                            : item.ledger_account_fk_id,
                    description: item.description,
                    debit_or_credit: item.debit_or_credit,
                    debit: item.debit_or_credit === 'debit' ? item.amount : 0,
                    credit: item.debit_or_credit === 'credit' ? item.amount : 0,
                    division__company_group_fk_id: [item ? item.division__company_group_fk_id : null, []],
                    selected_divisions: new GenericFormArray<ChipNode>([]),
                    selected_division_ids: [item ? item.division__company_group_fk_id : null],
                    selected_full_ledger: null
                });

                group.controls.selected_divisions.valueChanges
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((changes) => {
                        if (changes && changes.length > 0) {
                            group.controls.division__company_group_fk_id.setValue(changes[0].target_id);
                        } else {
                            group.controls.division__company_group_fk_id.setValue(null);
                        }
                    });
                control.push(group);
            }
        } else {
            this.addTransactions(2);
        }

        (this.lineItemsArray as UntypedFormArray).valueChanges
            .pipe(
                map((val) => {
                    let sum_debit = 0;
                    let sum_credit = 0;

                    const unMuskLineItems = val.map((item: any) => {
                        const unMuskAmount = cleanCurrencyString(item[item.debit_or_credit]);

                        if (item.debit_or_credit === 'debit') {
                            sum_debit += +(unMuskAmount || '');
                        } else {
                            sum_credit += +(unMuskAmount || '');
                        }

                        return {
                            ...item,
                            amount: unMuskAmount
                        };
                    });

                    this.total_debit = sum_debit;
                    this.total_credit = sum_credit;
                    this.isDebitCreditEqual = sum_debit === sum_credit;
                    this.hasZeroAmount = !!unMuskLineItems.find((item: any) => Number(item.amount) === 0);

                    this.onFormUpdate();

                    return unMuskLineItems;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.formGroup
            .get('notes')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.onFormUpdate());
        this.formGroup
            .get('reference_number')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.onFormUpdate());
        this.formGroup
            .get('type')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.onFormUpdate());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.journalTemplate.currentValue) {
            this.patchTemplate(changes.journalTemplate.currentValue);
        }
    }

    patchTemplate(journalTemplate: IJournalTemplate) {
        const control = this.formGroup.controls.line_items as any as UntypedFormArray;

        control.clear();

        this.formGroup.patchValue({
            notes: journalTemplate.notes,
            reference_number: journalTemplate.reference_number,
            type: journalTemplate.type
        });

        for (const item of journalTemplate.line_items) {
            const group = this.fb.group({
                ledger_account_fk_id:
                    this.formGroup?.controls.type.value === 'cash'
                        ? item.cash__ledger_account_fk_id
                        : item.ledger_account_fk_id,
                description: item.description,
                debit_or_credit: item.debit_or_credit,
                debit: item.debit_or_credit === 'debit' ? item.amount : 0,
                credit: item.debit_or_credit === 'credit' ? item.amount : 0,
                division__company_group_fk_id: [item ? item.division__company_group_fk_id : null, []],
                selected_divisions: new GenericFormArray<ChipNode>([]),
                selected_division_ids: [item ? item.division__company_group_fk_id : null],
                selected_full_ledger: null
            });

            group.controls.selected_divisions.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
                if (changes && changes.length > 0) {
                    group.controls.division__company_group_fk_id.setValue(changes[0].target_id);
                } else {
                    group.controls.division__company_group_fk_id.setValue(null);
                }
            });
            control.push(group);
        }
    }

    onFormUpdate() {
        this.onUpdate.emit({
            valid: this.formGroup.valid && !this.hasZeroAmount && this.isDebitCreditEqual,
            template: this.getFormValues()
        });
    }

    addTransactions(number = 1) {
        const control = <UntypedFormArray>this.lineItemsArray;

        const group = this.fb.group({
            ledger_account_fk_id: null,
            description: '',
            debit_or_credit: 'debit',
            debit: 0,
            credit: 0,
            division__company_group_fk_id: null,
            selected_divisions: new GenericFormArray<ChipNode>([]),
            selected_division_ids: [null],
            selected_full_ledger: null
        });

        group.controls.selected_divisions.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (changes && changes.length > 0) {
                group.controls.division__company_group_fk_id.setValue(changes[0].target_id);
            } else {
                group.controls.division__company_group_fk_id.setValue(null);
            }
        });
        control.push(group);
        number >= 1 && --number && this.addTransactions(number);
    }

    deleteTransaction(index: number) {
        if (this.lineItemsArray['controls'].length > 2) {
            const control = <UntypedFormArray>this.lineItemsArray;

            control.removeAt(index);
        }
    }

    onType(index: number, type: 'debit' | 'credit', event: any) {
        const unMuskValue = cleanCurrencyString(event.target.value);
        const isEmptyValue = unMuskValue === '0' || event.target.value.length === 0;
        const control = <UntypedFormArray>this.lineItemsArray;

        if (isEmptyValue) {
            return;
        }

        if (control.at(index).value.debit_or_credit !== type) {
            control.at(index).setValue({
                ...control.at(index).value,
                debit: 0,
                credit: 0,
                [type]: Number(unMuskValue),
                debit_or_credit: type
            });
        }
    }

    validateForm() {
        if (this.formGroup.valid) {
            return true;
        }

        this.ntfs.warn('Form is not valid');
        this.formGroup.updateValueAndValidity();

        return false;
    }

    getFormValues() {
        const {line_items, ...values} = this.formGroup.getRawValue();
        const unMuskLineItems = line_items.map((item: any) => {
            return {
                debit_or_credit: item.debit_or_credit,
                description: item.description,
                ledger_account_fk_id: this.formGroup?.controls.type.value === 'cash' ? null : item.ledger_account_fk_id,
                cash__ledger_account_fk_id: item.ledger_account_fk_id,
                amount: Number(cleanCurrencyString(item[item.debit_or_credit])),
                division__company_group_fk_id: item.division__company_group_fk_id
            };
        });

        return {
            ...values,
            line_items: unMuskLineItems,
            line_item_total: this.isDebitCreditEqual ? this.total_credit : null
        };
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.onUpdate.complete();
        this.closeEvent.complete();
    }
}
