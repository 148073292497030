import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Subject} from 'rxjs';
import {Board} from 'app/models/board';
import {Widget} from 'app/models/widget';
import {WidgetService} from '../../services/widget.service';

@Component({
    selector: 'app-board',
    templateUrl: './board.component.html',
    styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit, OnChanges, OnDestroy {
    @Input() board: Board | null = null;
    @Input() editMode: boolean = false;

    @Output() deleteBoard = new EventEmitter();
    @Output() closeEditingEmitter = new EventEmitter();

    public widgetsList: Widget[] = [];
    private widgetsInitiall: Widget[] = [];
    private widgetsToMakeChanges: {[key: string]: any} = {};

    firstColumn: Widget[] = [];
    secondColumn: Widget[] = [];
    thirdColumn: Widget[] = [];

    containers = [0, 1, 2];

    /**
     * used to trigger widgets data reloading
     */
    public filterSubj: Subject<{}> = new Subject();

    constructor(protected widgetService: WidgetService) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.board && this.board && this.board.id) {
            this.widgetService.list(this.board.id).then((widgetsList) => {
                this.widgetsList = widgetsList;
                this.widgetsList.unshift(new Widget().setType('add'));
                this.widgetsInitiall = JSON.parse(JSON.stringify(this.widgetsList));
                this.getWidgetsByContainer(this.widgetsList);
            });
        }
        if (changes.editMode) {
            this.editMode = changes.editMode.currentValue;
        }
    }

    getWidgetsByContainer(widgetsList: Widget[]) {
        // sorting
        if (widgetsList.length > 0) {
            widgetsList.sort(function (a, b) {
                return a.settings.order >= b.settings.order ? 1 : b.settings.order > a.settings.order ? -1 : 0;
            });
        }

        // adding
        this.firstColumn = [];
        this.secondColumn = [];
        this.thirdColumn = [];
        widgetsList.forEach((container: Widget) => {
            if (container.settings.container === 0) {
                this.firstColumn.push(container);
            } else if (container.settings.container === 1) {
                this.secondColumn.push(container);
            } else if (container.settings.container === 2) {
                this.thirdColumn.push(container);
            }
        });
    }

    drop(event: CdkDragDrop<Widget[]>) {
        // not allow to set widget instead AddWidget
        if (Number(event.container.id) === 0 && event.currentIndex === 0) {
            event.currentIndex = 1;
        }

        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }

        // find index
        const index = this.widgetsList.findIndex((widget) => {
            return Boolean(
                (widget.widget_id && widget.widget_id === event.container.data[event.currentIndex].widget_id) ||
                    (widget.uid_for_modify &&
                        widget.uid_for_modify === event.container.data[event.currentIndex].uid_for_modify)
            );
        });
        if (index >= 0) {
            const containerId = Number(event.container.id);
            const previousContainerId = Number(event.previousContainer.id);
            const currentIndex = containerId !== 0 ? event.currentIndex : event.currentIndex - 1;
            let tmpIndex = currentIndex;
            this.widgetsList[index].settings.order = currentIndex; // set index
            this.widgetsList[index].settings.container = containerId; // set container

            if (currentIndex < event.previousIndex || containerId !== previousContainerId) {
                this.widgetsList
                    .filter((item, widgetIndex) => {
                        return (
                            item.settings.container === containerId &&
                            item.settings.order >= currentIndex &&
                            widgetIndex !== index &&
                            item.type !== 'add'
                        );
                    })
                    .sort((a, b) => a.settings.order - b.settings.order)
                    .forEach((item) => {
                        const foundWidgetIndex = this.widgetsList.findIndex((widget) => {
                            return Boolean(
                                (widget.widget_id && widget.widget_id === item.widget_id) ||
                                    (widget.uid_for_modify && widget.uid_for_modify === item.uid_for_modify)
                            );
                        });
                        this.widgetsList[foundWidgetIndex].settings.order = ++tmpIndex;
                    });
            } else if (currentIndex > event.previousIndex) {
                this.widgetsList
                    .filter((item, widgetIndex) => {
                        return (
                            item.settings.container === containerId &&
                            item.settings.order <= currentIndex &&
                            widgetIndex !== index &&
                            item.type !== 'add'
                        );
                    })
                    .sort((a, b) => b.settings.order - a.settings.order)
                    .forEach((item) => {
                        const foundWidgetIndex = this.widgetsList.findIndex((widget) => {
                            return Boolean(
                                (widget.widget_id && widget.widget_id === item.widget_id) ||
                                    (widget.uid_for_modify && widget.uid_for_modify === item.uid_for_modify)
                            );
                        });
                        this.widgetsList[foundWidgetIndex].settings.order = --tmpIndex;
                    });
            }

            this.reorderContainer(previousContainerId);
        }
    }

    /**
     * Reorder container
     * @param containerId
     */
    reorderContainer(containerId: number) {
        let index = 0;
        this.widgetsList
            .filter((item, widgetIndex) => {
                return item.settings.container === containerId && item.type !== 'add';
            })
            .sort((a, b) => a.settings.order - b.settings.order)
            .forEach((item) => {
                const foundWidgetIndex = this.widgetsList.findIndex((widget) => {
                    return Boolean(
                        (widget.widget_id && widget.widget_id === item.widget_id) ||
                            (widget.uid_for_modify && widget.uid_for_modify === item.uid_for_modify)
                    );
                });
                this.widgetsList[foundWidgetIndex].settings.order = index++;
            });
    }

    onFilterApplied(filterObj = {}) {
        this.filterSubj.next(filterObj);
    }

    onCustomizeVisibility(customVisibility: EventEmitter<any>) {
        this.closeEditingEmitter.emit(this.editMode);

        if (!customVisibility) {
            // if cancel
            this.widgetsList = JSON.parse(JSON.stringify(this.widgetsInitiall));
            this.getWidgetsByContainer(this.widgetsList);
            this.resetWidgetToMakeChanges();
        } else {
            // if save
            this.widgetsInitiall = JSON.parse(JSON.stringify(this.widgetsList));

            this.applyChangesForWidget().then((response: any[]) => {
                const usedIds = this.widgetsInitiall.map((item) => item.id);
                const listForSave = this.widgetsInitiall.filter((item) => item.type !== 'add' && item.id);
                const createdList = this.widgetsInitiall
                    .filter((item) => item.type !== 'add' && !item.id)
                    .map((item) => {
                        const foundCreatedItem = response.find((responseItem) => {
                            return (
                                item.name === responseItem.name &&
                                item.type === responseItem.type &&
                                !usedIds.includes(responseItem.id)
                            );
                        });

                        if (foundCreatedItem) {
                            usedIds.push(foundCreatedItem.id);
                            return {
                                ...foundCreatedItem,
                                settings: {
                                    ...foundCreatedItem.settings,
                                    ...item.settings
                                }
                            };
                        }
                        return item;
                    })
                    .filter((item) => item.id);
                this.getWidgetsByContainer(this.widgetsList);
                if (!this.board || !this.board.widget_board_id) {
                    return;
                }
                return this.widgetService.batchUpdate(this.board!.widget_board_id, listForSave.concat(createdList));
            });
        }
    }

    onWidgetCrudAction(dataAction: any) {
        if (dataAction.action === 'delete') {
            const widgetIndex = this.widgetsList.findIndex((item) => {
                return Boolean(
                    (item.uid_for_modify && item.uid_for_modify === dataAction.data.uid_for_modify) ||
                        (item.widget_id && item.widget_id === dataAction.data.widget_id)
                );
            });
            if (widgetIndex > -1) {
                this.widgetsList.splice(widgetIndex, 1);
            }
        }

        if (['create', 'update'].includes(dataAction.action)) {
            dataAction.data.board_id = this.board!.id;
            const widgetIndex = this.widgetsList.findIndex((item) => {
                return Boolean(item.uid_for_modify && item.uid_for_modify === dataAction.data.uid_for_modify);
            });
            this.widgetsList.splice(widgetIndex, 1, dataAction.data);
        }

        this.widgetsToMakeChanges[dataAction.data.uid_for_modify] = {
            action: dataAction.action,
            data: dataAction.data
        };

        this.getWidgetsByContainer(this.widgetsList);
    }

    resetWidgetToMakeChanges() {
        this.widgetsToMakeChanges = {};
    }

    async applyChangesForWidget() {
        const arr = Object.values(this.widgetsToMakeChanges);
        const promises: Promise<Widget>[] = [];
        arr.forEach((item) => {
            if (item.action === 'create') {
                {
                    const promise = this.widgetService
                        .create(item.data.board_id, {
                            settings: item.data.settings,
                            type: item.data.type,
                            name: item.data.name
                        })
                        .then((response) => {
                            const findIndex = this.widgetsList.findIndex((listItem) => {
                                return listItem.uid_for_modify === item.data.uid_for_modify;
                            });
                            if (findIndex !== -1) {
                                const dataToUpdate: any = {
                                    ...this.widgetsList[findIndex],
                                    ...response
                                };
                                this.widgetsList.splice(findIndex, 1, dataToUpdate);
                            }
                            return response;
                        });
                    promises.push(promise);
                }
            } else if (item.action === 'update') {
                promises.push(
                    this.widgetService.update(item.data.board_id, item.data.widget_id, {
                        settings: item.data.settings,
                        name: item.data.name
                    })
                );
            } else if (item.action === 'delete') {
                if (!item.data.widget_id) {
                    return;
                }
                promises.push(this.widgetService.softDelete(item.data.board_id, item.data.widget_id));
            }
        });
        const promiseResult = await Promise.all(promises);
        this.resetWidgetToMakeChanges();
        return promiseResult;
    }

    onChangeWidgetList(widgetsList: Widget[]) {
        this.widgetsList = JSON.parse(JSON.stringify(widgetsList));
        this.getWidgetsByContainer(this.widgetsList);
    }

    onDeleteBoard(boardDeleted: boolean) {
        if (boardDeleted && this.board) {
            this.deleteBoard.emit(this.board);
        }
    }

    ngOnDestroy() {
        this.deleteBoard.complete();
        this.closeEditingEmitter.complete();
        this.filterSubj.complete();
    }
}
