<div class="w-100">
    <div
        *ngIf="!areCompanyOverridesLoaded"
        class="d-flex align-items-center justify-content-center"
        style="position: absolute; left: 50%; top: 20%"
    >
        <mat-spinner [diameter]="32"></mat-spinner>
    </div>

    <ng-container *ngIf="areCompanyOverridesLoaded">
        <mat-card style="margin: 32px; padding: 40px">
            <h2>Create Payload.co Account</h2>

            <div style="padding-right: 100px; margin-bottom: 32px">
                <span>
                    SkySlope Books leverages Payload.co, a third-party service, to streamline money transfers, including
                    Electronic Funds Transfers (EFTs).
                </span>
                <span *ngIf="shouldShowProcessingFeeText">
                    There is a nominal processing fee, charged by Payload.co based on the dollar amount of the EFT. The
                    charge for disbursements follows a structured model: initially, up to $75,000, the customer incurs a
                    $1.00 fee. Beyond this threshold, for every additional increment of $75,000, an extra $1.00 charge
                    applies. At the beginning of each month, Payload.co will send a monthly billing statement for all
                    transactions processed for the previous month.
                </span>
            </div>
            <button mat-raised-button color="primary" (click)="openPaForm()">Create Payload.co Account</button>
        </mat-card>

        <div style="margin-left: 32px">
            If you need any assistance with your Money Transfer account, call (833) 593-0877 or email
            <a href="mailto: support@payload.co" target="_blank">support&#64;payload.co</a>
        </div>
    </ng-container>
</div>
