<div mat-dialog-title>
    <h3>You are about to be timed out!</h3>
</div>
<mat-dialog-content>
    <div class="mb-1">
        We have detected in-activity in the application and
        <br />
        will be automatically logging out this session in 1 minute.
    </div>
</mat-dialog-content>
