import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IFinanceState} from '../../../store/finance.reducer';
import {Store} from '@ngrx/store';
import {of, Subject} from 'rxjs';
import {map, filter, takeUntil, catchError} from 'rxjs/operators';
import {IPayment, IVendorCredit} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ApplyCreditToBillsDialogComponent} from '../../../../shared/components/credit-apply/apply-credit-to-bills-dialog/apply-credit-to-bills-dialog.component';
import {CREDITS_STATUS_COLOR} from 'app/local-typings';
import {VendorCreditsService} from '../../../../../services/api/finance/vendor-credits.service';
import {NotificationsService} from 'angular2-notifications';
import {BillsService} from 'app/services/api/finance/bills.service';
import {DealService} from '../../../../../services/deal.service';
import {FeatureFlagsService} from '@app/modules/feature-flags/feature-flags.service';
import {
    Edit1099AmountsDialogComponent,
    IEdit1099AmountsDialogData
} from '@app/modules/finance/components/common/edit-1099-amounts-dialog/edit-1099-amounts-dialog.component';

@Component({
    selector: 'app-view-vendor-credit',
    templateUrl: 'view-vendor-credit.component.html',
    styles: [
        `
            .zp-company-info {
                width: 100%;
            }

            .zp-company-title {
                font-weight: bold;
                margin-bottom: 0;
            }

            .zp-inveice_title {
                font-size: 33px;
                margin-bottom: 0;
            }

            .zp-invoice-num_value {
                font-size: 14px;
                letter-spacing: 0.07rem;
                font-weight: bold;
                margin-bottom: 25px;
                margin-top: -5px;
            }

            .zp-refference_due_title {
                margin-bottom: 0;
            }

            .zp-refference_due_value {
                font-size: 14px;
                margin-top: 0px;
                font-weight: bold;
                margin-bottom: 30px;
            }

            .zp-w-150 {
                width: 150px;
                display: inline-block;
            }

            .zp-balance-due {
                font-size: 20px;
            }

            .zp-total-nember {
                display: inline-block;
                width: 150px;
                text-align: right;
                padding-right: 0.75rem;
            }

            .credit-status {
                right: 0;
                top: 0;
                position: absolute;
                color: #fff;
                font-size: 18px;
                padding: 0 10px;
                display: inline-block;
            }
        `
    ]
})
export class ViewVendorCreditComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    vendorCreditId: number | null = null;
    vendorCredit: IVendorCredit | null = null;

    dataSource: MatTableDataSource<IPayment>;
    showPayments: boolean;
    isBillsToApply: boolean;

    statusColor = CREDITS_STATUS_COLOR;
    displayedRelatedColumns = ['payment_date', 'invoice_id', 'status', 'amount'];

    // Feature flags
    isPayStubPrintAvailable: boolean = true;

    constructor(
        private store: Store<IFinanceState>,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private billsService: BillsService,
        private vendorCreditsService: VendorCreditsService,
        private router: Router,
        protected ntfs: NotificationsService,
        private dealService: DealService,
        protected featureFlagsService: FeatureFlagsService
    ) {
        this.dataSource = new MatTableDataSource<IPayment>([]);
        this.showPayments = false;
        this.isBillsToApply = false;
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.isPayStubPrintAvailable = this.featureFlagsService.isFeatureEnabled(
                    'purchases:vendor_credit_pay_stub'
                );
            });
    }

    openApplyToBillsDialog() {
        const dialogRef = this.dialog.open(ApplyCreditToBillsDialogComponent, {
            minWidth: 700,
            data: {
                credit: this.vendorCredit,
                contact: this.vendorCredit && this.vendorCredit.vendor_contact ? this.vendorCredit.vendor_contact : null
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((v) => !!v),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.getVendorCredit();
            });
    }

    getVendorCredit() {
        if (this.vendorCreditId) {
            this.vendorCreditsService
                .getVendorCredit(this.vendorCreditId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((vc) => {
                    if (!vc) {
                        return;
                    }
                    this.vendorCredit = vc;
                    if (this.vendorCredit.items && this.vendorCredit.items.length > 0) {
                        // need to get related deals on backend
                        this.getItemsRelatedDeals();
                    }
                    this.billsToApply();
                    this.dataSource.data = this.vendorCredit.payments || [];
                });
        } else {
            this.ntfs.error(`Vendor Credit id not found`);
            this.router.navigate(['/purchases/vendorcredits']);
        }
    }

    billsToApply() {
        if (this.vendorCredit) {
            this.billsService
                .getBillsForCreditsApply(this.vendorCredit.vendor__contact_fk_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((data) => {
                    this.isBillsToApply = !!data.length;
                });
        } else {
            this.ntfs.error(`Vendor Credit not loaded`);
        }
    }

    getItemsRelatedDeals() {
        if (this.vendorCredit && this.vendorCredit.items) {
            for (const item of this.vendorCredit.items) {
                if (item.connected__deal_fk_id) {
                    this.dealService.getDealBaseView(item.connected__deal_fk_id).then((deal) => {
                        item.related_deal = deal;
                    });
                }
            }
        } else {
            this.ntfs.error(`Vendor Credit not loaded`);
        }
    }

    ngOnInit() {
        this.route.paramMap
            .pipe(
                map((pm) => {
                    const id = pm.get('id');
                    return id ? +id : null;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                this.vendorCreditId = id;
                this.getVendorCredit();
            });
    }

    updateStatus(status: string) {
        if (this.vendorCreditId) {
            this.vendorCreditsService
                .updateVendorCreditStatus(this.vendorCreditId, status)
                .pipe(
                    catchError((err) => of(null)),
                    filter((act) => !!act),
                    takeUntil(this.unsubscribe)
                )
                .subscribe(() => {
                    this.ntfs.info(`Vendor Credit status updated`);
                    this.router.navigate(['/purchases/vendorcredits']);
                });
        } else {
            this.ntfs.error(`Vendor Credit not loaded`);
        }
    }

    open1099Edit() {
        if (!this.vendorCredit || !this.vendorCredit.vendor_credit_id) {
            this.ntfs.error(`Payment data is not available.`);
            return;
        }
        const dialogRef = this.dialog.open<Edit1099AmountsDialogComponent, IEdit1099AmountsDialogData>(
            Edit1099AmountsDialogComponent,
            {
                width: '500px',
                maxHeight: '80vh',
                disableClose: true,
                panelClass: 'no-padding-dialog',
                autoFocus: false,
                data: {
                    entity_type: 'vendor_credit',
                    entity_id: this.vendorCredit.vendor_credit_id,
                    amount_1099_nec:
                        this.vendorCredit.amount_1099_nec_custom ??
                        this.vendorCredit.amount_1099_nec ??
                        this.vendorCredit.total_amount
                }
            }
        );

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((success) => {
                if (success) {
                    this.ngOnInit();
                }
            });
    }

    printPayStub() {
        if (this.vendorCreditId) {
            this.vendorCreditsService
                .createPayStub(this.vendorCreditId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response.result && response.result.error) {
                        this.ntfs.error(response.result.error);
                        return;
                    }

                    const blob = new Blob([new Uint8Array(response.result.data)], {type: 'application/pdf'});
                    const blobUrl = URL.createObjectURL(blob);
                    const iframe = document.createElement('iframe');
                    iframe.id = 'print-frame';

                    iframe.style.display = 'none';
                    iframe.src = blobUrl;
                    iframe.onload = () => {
                        setTimeout(() => {
                            if (iframe.contentWindow) {
                                iframe.contentWindow.print();
                            }
                        }, 0);
                    };
                    document.body.appendChild(iframe);
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
