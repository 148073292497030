<div class="w-100 row">
    <div class="col-6 my-3">
        <div class="row">
            <div class="col-12">
                <h1>1099-NEC</h1>
            </div>

            <div class="col-6">
                <mat-form-field class="w-100" *ngIf="contactPayrollsMisc && contactPayrollsMisc.length">
                    <mat-select *ngIf="currentPayrollMiscId" [formControl]="currentPayrollMiscId" placeholder="">
                        <mat-option
                            *ngFor="let contactPayroll of contactPayrollsMisc"
                            [value]="contactPayroll.contact_payroll_id"
                        >
                            {{ contactPayroll.start_date | dateFromNumber | date: 'MM/dd/yyyy' }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-6" style="text-align: right" *ngIf="isEditMode">
                <button type="button" mat-raised-button (click)="add1099Payroll()">
                    <mat-icon>add</mat-icon>
                    Add Tax Record
                </button>
            </div>

            <div class="col-12">
                <mat-card *ngIf="currentPayrollMisc">
                    <mat-card-content>
                        <div class="d-flex align-items-center justify-content-between">
                            <h1>
                                <span *ngIf="currentPayrollMisc.contact_payroll_id === activePayrollMiscId"
                                    >Active</span
                                >
                                <span *ngIf="currentPayrollMisc.contact_payroll_id !== activePayrollMiscId"
                                    >Inactive</span
                                >
                            </h1>
                            <button
                                type="button"
                                *ngIf="isEditMode"
                                mat-icon-button
                                [matMenuTriggerFor]="menu"
                                (click)="$event.stopPropagation()"
                            >
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        </div>
                        <mat-menu #menu="matMenu">
                            <button
                                type="button"
                                *ngIf="isEditMode"
                                mat-menu-item
                                (click)="$event.stopPropagation(); edit1099Payroll(currentPayrollMisc)"
                            >
                                Edit
                            </button>
                            <button
                                type="button"
                                *ngIf="isEditMode"
                                mat-menu-item
                                (click)="$event.stopPropagation(); payrollRemove(currentPayrollMisc)"
                            >
                                Remove 1099 Record
                            </button>
                        </mat-menu>

                        <div class="row">
                            <div class="col-6">
                                <div>Name of Recipient:</div>
                                <div class="mb-2 text-muted">{{ currentPayrollMisc.name }}</div>
                                <div>Address:</div>
                                <div class="mb-2">
                                    <span class="text-muted" *ngIf="currentPayrollMisc.street_number"
                                        >{{ currentPayrollMisc.street_number }}
                                    </span>
                                    <span class="text-muted" *ngIf="currentPayrollMisc.street_address"
                                        >{{ currentPayrollMisc.street_address }}
                                    </span>
                                    <span class="text-muted" *ngIf="currentPayrollMisc.unit_number"
                                        >{{ currentPayrollMisc.unit_number }}
                                    </span>
                                    <br />
                                    <span class="text-muted" *ngIf="currentPayrollMisc.city"
                                        >{{ currentPayrollMisc.city }},
                                    </span>
                                    <span class="text-muted" *ngIf="currentPayrollMisc.state"
                                        >{{ currentPayrollMisc.state }}
                                    </span>
                                    <span class="text-muted" *ngIf="currentPayrollMisc.zip"
                                        >{{ currentPayrollMisc.zip }}
                                    </span>
                                    <br />
                                    <span *ngIf="currentPayrollMisc.country" class="text-muted"
                                        >({{ currentPayrollMisc.country }})</span
                                    >
                                </div>
                            </div>
                            <div class="col-6">
                                <div>TIN Type:</div>
                                <div class="mb-2 text-muted">{{ currentPayrollMisc.tin_type | uppercase }}</div>
                                <div>TIN:</div>
                                <div class="mb-2 text-muted">{{ getTinWithMask(currentPayrollMisc) }}</div>
                            </div>
                        </div>

                        <div *ngIf="additional1099ReportsEnabledFlag">
                            <mat-divider [inset]="true" style="margin-top: 16px; margin-bottom: 16px"></mat-divider>

                            <h1>
                                <span>Tax Record History</span>
                            </h1>

                            <table
                                mat-table
                                [dataSource]="payrollHistoryMatTableDataSource"
                                class="w-100"
                                *ngIf="
                                    payrollHistoryMatTableDataSource?.data &&
                                    payrollHistoryMatTableDataSource.data.length > 0
                                "
                            >
                                <ng-container matColumnDef="formName">
                                    <th mat-header-cell *matHeaderCellDef>Form</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.form_name }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="datePrinted">
                                    <th mat-header-cell *matHeaderCellDef>Date Printed</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.created_at | date: 'short' }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="total">
                                    <th mat-header-cell *matHeaderCellDef>Total</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.total | currency: 'USD' }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="submissionType">
                                    <th mat-header-cell *matHeaderCellDef>Submission</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.submission_type | titlecase }}
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                            </table>

                            <div
                                *ngIf="
                                    !payrollHistoryMatTableDataSource?.data ||
                                    payrollHistoryMatTableDataSource.data.length === 0
                                "
                            >
                                No tax record history found.
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <div class="col-6 my-3">
        <div class="row">
            <div class="col-12">
                <h1>W-2</h1>
            </div>

            <div class="col-6">
                <mat-form-field class="w-100" *ngIf="contactPayrollsW && contactPayrollsW.length">
                    <mat-select *ngIf="currentPayrollWId" [formControl]="currentPayrollWId" placeholder="">
                        <mat-option
                            *ngFor="let contactPayroll of contactPayrollsW"
                            [value]="contactPayroll.contact_payroll_id"
                        >
                            {{ contactPayroll.start_date | dateFromNumber | date: 'MM/dd/yyyy' }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-6" style="text-align: right" *ngIf="isEditMode">
                <button type="button" mat-raised-button (click)="addW2Payroll()">
                    <mat-icon>add</mat-icon>
                    Add Tax Record
                </button>
            </div>

            <div class="col-12">
                <mat-card *ngIf="currentPayrollW">
                    <mat-card-content>
                        <div class="d-flex align-items-center justify-content-between">
                            <h1>
                                <span *ngIf="currentPayrollW.contact_payroll_id === activePayrollWId">Active</span>
                                <span *ngIf="currentPayrollW.contact_payroll_id !== activePayrollWId">Inactive</span>
                            </h1>
                            <button
                                type="button"
                                *ngIf="isEditMode"
                                mat-icon-button
                                [matMenuTriggerFor]="menu"
                                (click)="$event.stopPropagation()"
                            >
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        </div>
                        <mat-menu #menu="matMenu">
                            <button
                                type="button"
                                *ngIf="isEditMode"
                                mat-menu-item
                                (click)="$event.stopPropagation(); editW2Payroll(currentPayrollW)"
                            >
                                Edit
                            </button>
                            <button
                                type="button"
                                *ngIf="isEditMode"
                                mat-menu-item
                                (click)="$event.stopPropagation(); payrollRemove(currentPayrollW)"
                            >
                                Remove W-2 Record
                            </button>
                        </mat-menu>

                        <div class="row">
                            <div class="col-6">
                                <div>Name of Recipient:</div>
                                <div class="mb-2 text-muted">{{ currentPayrollW.name }}</div>
                                <div>Address:</div>
                                <div class="mb-2">
                                    <span class="text-muted" *ngIf="currentPayrollW.street_number"
                                        >{{ currentPayrollW.street_number }}
                                    </span>
                                    <span class="text-muted" *ngIf="currentPayrollW.street_address"
                                        >{{ currentPayrollW.street_address }}
                                    </span>
                                    <span class="text-muted" *ngIf="currentPayrollW.unit_number"
                                        >{{ currentPayrollW.unit_number }}
                                    </span>
                                    <br />
                                    <span class="text-muted" *ngIf="currentPayrollW.city"
                                        >{{ currentPayrollW.city }},
                                    </span>
                                    <span class="text-muted" *ngIf="currentPayrollW.state"
                                        >{{ currentPayrollW.state }}
                                    </span>
                                    <span class="text-muted" *ngIf="currentPayrollW.zip"
                                        >{{ currentPayrollW.zip }}
                                    </span>
                                    <br />
                                    <span *ngIf="currentPayrollW.country" class="text-muted"
                                        >({{ currentPayrollW.country }})</span
                                    >
                                </div>
                                <div *ngIf="currentPayrollW.tin_type">TIN Type:</div>
                                <div *ngIf="currentPayrollW.tin_type">{{ currentPayrollW.tin_type | uppercase }}</div>
                                <div *ngIf="currentPayrollW">TIN:</div>
                                <div *ngIf="currentPayrollW" class="mb-2 text-muted">
                                    {{ getTinWithMask(currentPayrollW) }}
                                </div>
                                <div *ngIf="currentPayrollW.filing_status">Filing Status:</div>
                                <div *ngIf="currentPayrollW.filing_status" class="mb-2 text-muted">
                                    {{ getStatusLabel(currentPayrollW.filing_status) }}
                                </div>
                            </div>
                            <div class="col-6">
                                <div *ngIf="currentPayrollW.withholding_amount">Withholding Amount:</div>
                                <div *ngIf="currentPayrollW.withholding_amount" class="mb-2 text-muted">
                                    {{ currentPayrollW.withholding_amount | currency: 'USD' : 'symbol' }}
                                </div>
                                <div *ngIf="currentPayrollW.extra_withholding_amount">Extra Withholding Amount:</div>
                                <div *ngIf="currentPayrollW.extra_withholding_amount" class="mb-2 text-muted">
                                    {{ currentPayrollW.extra_withholding_amount | currency: 'USD' : 'symbol' }}
                                </div>
                                <div *ngIf="currentPayrollW.other_income">Other Income:</div>
                                <div *ngIf="currentPayrollW.other_income" class="mb-2 text-muted">
                                    {{ currentPayrollW.other_income | currency: 'USD' : 'symbol' }}
                                </div>
                                <div *ngIf="currentPayrollW.deductions">Deductions:</div>
                                <div *ngIf="currentPayrollW.deductions" class="mb-2 text-muted">
                                    {{ currentPayrollW.deductions }}
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>
