import {Subject} from 'rxjs';

import * as Papa from 'papaparse';
import * as FileSaver from 'file-saver';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {TransactionsExternalService} from 'app/services/api/finance/transactions-external.service';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
    selector: 'app-import-transactions',
    templateUrl: 'import-transactions.component.html',
    styleUrls: ['../../banking.component.scss']
})
export class ImportTransactionsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    importForm = new UntypedFormGroup({
        fileSource: new UntypedFormControl('')
    });

    ledgerAccountId: number | null = null;

    importedTransactions: any[] = [];
    reader = new FileReader();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private transactionsExternalService: TransactionsExternalService,
        public dialog: MatDialog
    ) {
        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['id']) {
                this.ledgerAccountId = +params['id'];
            }
        });
    }

    ngOnInit() {
        this.initFileReaderListener();
    }

    toTransacionsList() {
        this.router.navigate([`/banking/${this.ledgerAccountId}/transactions`]);
    }

    handleFileInput(files: FileList | null) {
        if (!files || files.length === 0 || !files[0]) {
            return;
        }
        const [file] = Array.from(files);

        if (file.size > 5000000) {
            throw new Error('File size too big. It must be under 5MB');
        } else {
            this.reader.readAsBinaryString(file);
        }
    }

    private initFileReaderListener() {
        this.reader.addEventListener('loadend', () => {
            const data = Papa.parse((<string>this.reader.result).trim(), {header: true}).data;

            this.importedTransactions = data;
            this.importForm.controls['fileSource'].patchValue(null);
        });
    }

    async downloadFile(filepath: string) {
        const result = await fetch(filepath);

        FileSaver.saveAs(await result.blob(), 'example.csv');
    }

    uploadTransactions() {
        this.transactionsExternalService
            .importBankTransactions(this.ledgerAccountId as number, this.prepareTransactions())
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.importedTransactions = [];
                // TODO go to import preview
            });
    }

    prepareTransactions() {
        return this.importedTransactions.map((t: {[key: string]: any}) => {
            return {
                ...t,
                amount: Number(t.amount),
                category: t.category.split(',')
            };
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
