import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {SessionService} from '../../../../services/session.service';
import {MatDialog} from '@angular/material/dialog';
import {PickerItem} from '../profiles-picker/models';
import {Profile} from '../../../../models/profile';
import {Subject} from 'rxjs';

export const VIEW_MY_DEALS = 'my';
export const VIEW_COMPANY_DEALS = 'company';
export const VIEW_ALL_DEALS = 'all';
export const VIEW_CUSTOM_DEALS = 'custom';

@Component({
    selector: 'app-deals-view-picker-select',
    styles: [
        `
            .material-icons {
                position: relative;
                top: 8px;
                left: 4px;
            }
        `
    ],
    template: `
        <mat-form-field class="w-100">
            <mat-select placeholder="Entity" [(ngModel)]="selectedMode">
                <mat-option value="{{ VIEW_MY_DEALS }}" (click)="onChange(VIEW_MY_DEALS)">
                    <b>{{ currentProfile?.email }}</b>
                </mat-option>
                <mat-option
                    value="{{ VIEW_COMPANY_DEALS }}"
                    (click)="onChange(VIEW_COMPANY_DEALS)"
                    rbac
                    [rule]="{deals__view_any: true}"
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'block'}"
                    [denyStyle]="{display: 'none'}"
                >
                    <b>{{ VIEW_COMPANY_DEALS }}</b>
                </mat-option>
                <mat-option
                    value="{{ VIEW_CUSTOM_DEALS }}"
                    (click)="onChange(VIEW_CUSTOM_DEALS)"
                    rbac
                    [rule]="{deals__view_any: true}"
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'block'}"
                    [denyStyle]="{display: 'none'}"
                >
                    <b>{{ VIEW_CUSTOM_DEALS }}</b>
                </mat-option>
            </mat-select>
        </mat-form-field>
    `
})
export class DealsViewPickerSelectComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    /**
     * Needed for using constants in template
     */
    public VIEW_MY_DEALS = VIEW_MY_DEALS;
    public VIEW_COMPANY_DEALS = VIEW_COMPANY_DEALS;
    public VIEW_ALL_DEALS = VIEW_ALL_DEALS;
    public VIEW_CUSTOM_DEALS = VIEW_CUSTOM_DEALS;

    @Input() pickedList: PickerItem[] = [];
    @Output() modeChange = new EventEmitter();

    @Input() currentProfile: Profile | undefined = undefined;

    public selectedMode = VIEW_ALL_DEALS;

    protected profilesList: PickerItem[] = [];
    protected groupsList: PickerItem[] = [];

    @Input()
    get mode() {
        return this.selectedMode;
    }

    set mode(val) {
        this.selectedMode = val;
        this.modeChange.emit(this.selectedMode);
    }

    constructor(
        public dialog: MatDialog,
        public sessionService: SessionService
    ) {}

    onChange(event: string) {
        switch (event) {
            case VIEW_CUSTOM_DEALS:
                this.mode = VIEW_CUSTOM_DEALS;
                break;
            case VIEW_MY_DEALS:
                this.mode = VIEW_MY_DEALS;
                break;
            case VIEW_ALL_DEALS:
                break;
            case VIEW_COMPANY_DEALS:
                this.mode = VIEW_COMPANY_DEALS;
                break;
        }
        this.mode = event;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.modeChange.complete();
    }
}
