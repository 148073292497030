import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {HttpClientModule} from '@angular/common/http';
import {indicatorsReducer} from './store/indicators.reducer';
import {IndicatorsEffectsService} from './store/indicators.effects';
import {ProgressBarComponent} from './progress-bar/progress-bar.component';
import {UploadProgressComponent} from './upload-progress/upload-progress.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        // HttpClientModule,

        StoreModule.forFeature('indicators', indicatorsReducer),
        EffectsModule.forFeature([IndicatorsEffectsService])
    ],
    exports: [ProgressBarComponent, UploadProgressComponent],
    declarations: [ProgressBarComponent, UploadProgressComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: []
})
export class IndicatorsModule {}
