import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    DEAL_SELF_TESTING_STATUSES,
    DEAL_SELF_TESTING_STATUSES_LABELS,
    DealSelfTestingEntity
} from '../../../../../../models/deal-self-testing';
import {DealProcessingService} from '../../deal-processing.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {DealExpensesComponent} from '../deal-expenses/deal-expenses.component';
import {SalesEntity} from '../../../../../../models/sales-entity';

@Component({
    selector: 'app-deal-self-testing',
    templateUrl: './deal-self-testing.component.html',
    styleUrls: ['./deal-self-testing.component.scss']
})
export class DealSelfTestingComponent implements OnInit, OnDestroy {
    DEAL_SELF_TESTING_STATUSES_LABELS = DEAL_SELF_TESTING_STATUSES_LABELS;
    DEAL_SELF_TESTING_STATUSES = DEAL_SELF_TESTING_STATUSES;
    DealSelfTestingEntity = DealSelfTestingEntity;
    SalesEntity = SalesEntity;
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('dealCalculationsTests') dealCalculationsTests: DealExpensesComponent | undefined;
    dealTestingEntity: DealSelfTestingEntity = new DealSelfTestingEntity();
    @Input() set selfTestingEntity(dealSelfTestingEntity: DealSelfTestingEntity) {
        if (dealSelfTestingEntity) {
            this.dealTestingEntity = dealSelfTestingEntity;
        }
    }
    get selfTestingEntity() {
        return this.dealTestingEntity;
    }

    constructor(private dealProcessingService: DealProcessingService) {
        this.dealProcessingService.dealFG.controls.self_testing?.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((selfTestingFG) => {
                const selfTesting = selfTestingFG?.value;
                if (selfTesting) {
                    this.selfTestingEntity = selfTesting;
                }
            });
    }

    ngOnInit(): void {}

    async runSelfTests() {
        this.selfTestingEntity = await this.dealProcessingService.runDealSelfTest();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    objectKeys(object: Object) {
        return Object.keys(object);
    }
}
