<div>
    <div class="w-100 text-right">
        <button mat-mini-fab aria-label="Close" (click)="dialogRef.close()" color="primary">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="mt-3">
        <!--        Credit Card Section        -->
        <div class="mb-4">
            <h3>Stored Cards</h3>
            <div
                class="mb-2"
                *ngIf="
                    (!myZipiFinancialCardGateway ||
                        !myZipiFinancialCardGateway.payment_methods ||
                        myZipiFinancialCardGateway.payment_methods.length === 0) &&
                    isGatewayLoaded
                "
            >
                No Cards
            </div>
            <div *ngIf="myZipiFinancialCardGateway">
                <div *ngFor="let method of myZipiFinancialCardGateway.payment_methods">
                    <mat-card
                        *ngIf="method.is_available_for_payment && method.type === 'zipi_financial_credit_card'"
                        class="mb-2"
                    >
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <mat-icon class="mr-1">credit_card</mat-icon>
                                <span>{{ method.title }}</span>
                                <span class="ml-1" *ngIf="method.expiration_date">({{ method.expiration_date }})</span>
                            </div>

                            <span class="sp-spacer"></span>
                            <!--                            We decided do not use this feature 02.2023 -->
                            <!--                            <div>-->
                            <!--                                <mat-checkbox [checked]="method.is_visible_to_connect" (change)="changeVisibleMode(method)">Allow card to be used by contact.</mat-checkbox>-->
                            <!--                            </div>-->
                        </div>
                        <div class="d-flex mb-2 align-items-center">
                            <span class="sp-spacer"></span>
                            <button
                                [rule]="{contacts__manage_payment_methods: true}"
                                rbac
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'inline-block'}"
                                [denyStyle]="{display: 'none'}"
                                type="button"
                                mat-raised-button
                                (click)="deleteReceiveOnly(method)"
                            >
                                Remove
                            </button>
                        </div>
                    </mat-card>
                </div>
            </div>
            <span class="mt-2" matTooltip="Information is being loaded" matTooltipDisabled="{{ isGatewayLoaded }}">
                <button
                    [rule]="{contacts__manage_payment_methods: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    class="mb-2"
                    type="button"
                    mat-raised-button
                    (click)="setupZipiFinancialForContact('contact_card', 'tpp')"
                    [disabled]="!isGatewayLoaded"
                >
                    Add Credit Card
                </button>
            </span>
        </div>
        <!--        Receive Only Bank Account Section        -->
        <div
            class="mb-4"
            *ngIf="
                contactSettings &&
                contactSettings.target_contact_metatypes &&
                contactSettings.target_contact_metatypes.length > 0 &&
                contactSettings.target_contact_metatypes.includes('vendor')
            "
        >
            <h3>Vendor Bill Pay (Bank Accounts)</h3>
            <div
                class="mb-2"
                *ngIf="
                    (!myZipiFinancialBankGateway ||
                        !myZipiFinancialBankGateway.payment_methods ||
                        myZipiFinancialBankGateway.payment_methods.length === 0) &&
                    isGatewayLoaded
                "
            >
                No Bank Accounts
            </div>
            <div *ngIf="myZipiFinancialBankGateway">
                <div *ngFor="let method of myZipiFinancialBankGateway.payment_methods">
                    <mat-card
                        *ngIf="method.is_available_for_payment && method.type !== 'zipi_financial_credit_card'"
                        class="mb-2"
                    >
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <mat-icon class="mr-1">account_balance</mat-icon>
                                <span>{{ method.title }}</span>
                                <span class="ml-1" *ngIf="method.expiration_date">({{ method.expiration_date }})</span>
                            </div>

                            <span class="sp-spacer"></span>
                            <!--                            We decided do not use this feature 02.2023-->
                            <!--                            <div>-->
                            <!--                                <mat-checkbox [checked]="method.is_visible_to_connect" (change)="changeVisibleMode(method)">Allow account to be used by contact.</mat-checkbox>-->
                            <!--                            </div>-->
                        </div>
                        <div class="d-flex mb-2 align-items-center">
                            <span>{{ myZipiFinancialBankGateway.title }}</span>
                            <span class="sp-spacer"></span>
                            <button
                                [rule]="{contacts__manage_payment_methods: true}"
                                rbac
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'inline-block'}"
                                [denyStyle]="{display: 'none'}"
                                type="button"
                                mat-raised-button
                                (click)="deleteReceiveOnly(method)"
                            >
                                Remove
                            </button>
                        </div>
                    </mat-card>
                </div>
            </div>
            <span class="mt-2" matTooltip="Information is being loaded" matTooltipDisabled="{{ isGatewayLoaded }}">
                <button
                    [rule]="{contacts__manage_payment_methods: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    class="mb-2"
                    type="button"
                    mat-raised-button
                    (click)="setupZipiFinancialForContact('contact_bank', 'muhnee')"
                    [disabled]="!isGatewayLoaded"
                >
                    Add Bank Account
                </button>
            </span>
        </div>
    </div>
</div>
