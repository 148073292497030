import {Injectable} from '@angular/core';
import {ServiceRequester} from '../service.requester';
import {ContactType} from '../../models/contact-type';
import {Observable} from 'rxjs';

@Injectable()
export class ContactTypeService {
    private url = '/contact-type';

    constructor(public requester: ServiceRequester) {}

    getContactTypeListAsPromise(): Promise<ContactType[]> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp').toPromise();
    }

    createContactType(data: ContactType): Observable<ContactType> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    updateContactType(contactTypeId: number, data: ContactType): Observable<ContactType> {
        return this.requester.makeMsCall$(this.url + '/' + contactTypeId, 'PUT', 'shipp', data);
    }

    deleteContactType(contactTypeId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + '/' + contactTypeId, 'DELETE', 'shipp');
    }
}
