<section class="bg-white">
    <!------- Header ------->
    <header class="d-flex align-items-center px-3 list-header">
        <h3 class="m-0 finance-menu">Payments Received</h3>

        <button
            mat-stroked-button
            class="ml-3"
            [rule]="{sales__manage_payments_received: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            [matMenuTriggerFor]="menu"
            color="warn"
            *ngIf="isSelectedForBulk"
        >
            Bulk Action
        </button>
        <mat-menu
            #menu="matMenu"
            [rule]="{sales__manage_payments_received: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
        >
            <button mat-menu-item (click)="bulkDelete()">Delete</button>
        </mat-menu>

        <i
            class="sp-spacer"
            [rule]="{sales__manage_payments_received: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
        ></i>

        <mat-form-field class="mt-2" [floatLabel]="'never'">
            <mat-label>Status filter</mat-label>
            <mat-select [formControl]="statusFilter" multiple>
                <mat-option
                    *ngFor="let status of statusTypes"
                    [value]="status"
                    (onSelectionChange)="changeStatusFilter($event)"
                >
                    {{ status | fromSnakeCase }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-icon
            [rule]="{sales__manage_payments_received: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            (click)="recheckPaymentStatus()"
            matTooltip="Recheck Payment Status"
            class="mx-4"
            style="cursor: pointer"
            >cached</mat-icon
        >

        <button
            type="button"
            mat-raised-button
            color="primary"
            routerLink="/sales/paymentsreceived/create"
            [rule]="{sales__manage_payments_received: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
        >
            <mat-icon>add</mat-icon>
            Create
        </button>
    </header>

    <!------- List of payments ------->
    <div class="table-background">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef class="w-5"></th>
                <td mat-cell *matCellDef="let element" class="w-5" (click)="$event.stopPropagation()">
                    <mat-checkbox
                        (change)="toggleBulkCheckbox($event, element.payment_received_id)"
                        [value]="element.payment_received_id"
                        [checked]="selection.isSelected(element.payment_received_id)"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="paid_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Payment Date</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <div class="d-flex align-items-center">
                        <span>{{
                            element.paid_date
                                ? (element.paid_date | dateFromNumber | date: 'mediumDate')
                                : (element.created_at | date: 'mediumDate')
                        }}</span>
                        <mat-icon
                            [id]="'paymentReceivedProcessingInfo'"
                            class="ml-1 processing-info-icon"
                            *ngIf="
                                ['authorize', 'zipi_financial'].includes(element.payment_mode) &&
                                element.summary_status === 'processing'
                            "
                        >
                            info
                        </mat-icon>

                        <app-custom-popover
                            *ngIf="
                                ['authorize', 'zipi_financial'].includes(element.payment_mode) &&
                                element.summary_status === 'processing'
                            "
                            [theme]="'dark'"
                            [position]="'right'"
                            [elementId]="'paymentReceivedProcessingInfo'"
                            [width]="325"
                        >
                            <div class="w-100 p-0" style="font-size: 16px">
                                <div class="d-flex my-3 p-0">
                                    <span
                                        >Payment is pending. Once funds are deposited, the payment date will be
                                        updated.</span
                                    >
                                </div>
                            </div>
                        </app-custom-popover>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="summary_status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Status</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <span *ngIf="element.summary_status" [ngStyle]="{color: statusColor[element.summary_status!]}">
                        <span>{{ element.summary_status | fromSnakeCase }}</span>
                        <mat-icon
                            class="payment-warning ml-1"
                            [matTooltip]="
                                'The Payment does not have related Ledger Account. Please setup it on the Edit Page'
                            "
                            *ngIf="!element.pay_to__ledger_account_fk_id"
                        >
                            warning
                        </mat-icon>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="payment_received_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Payment#</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">{{ element.payment_received_number }}</td>
            </ng-container>

            <ng-container matColumnDef="customer_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15 pl-1">Customer</th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    class="w-15 px-1"
                    [ngClass]="element.contactIsHidden ? 'hidden-contact' : ''"
                >
                    {{ element.customer_name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15 pl-1">Reference#</th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1 py-1">{{ element.reference }}</td>
            </ng-container>

            <ng-container matColumnDef="payment_mode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Payment Mode</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ paymentModesMap[element.payment_mode] }}
                </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 table-header-align-right">
                    Total Amount
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    class="w-10 pr-3 amount"
                    [ngStyle]="element.type === 'refund' ? {color: 'red'} : null"
                >
                    {{ element.type === 'normal' ? (element.amount | currency) : (element.amount * -1 | currency) }}
                </td>
            </ng-container>

            <ng-container matColumnDef="amount_applied">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 table-header-align-right">Applied</th>
                <td mat-cell *matCellDef="let element" class="w-10 pr-3 amount">
                    {{ element.amount_applied | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="w-5 text-right">Action</th>
                <td mat-cell *matCellDef="let element" class="w-5 text-right">
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                        [rule]="{sales__manage_payments_received: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        (click)="$event.stopPropagation()"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button
                            mat-menu-item
                            [rule]="{sales__manage_payments_received: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            (click)="
                                $event.stopPropagation();
                                router.navigate(['sales/paymentsreceived/edit', element.payment_received_id])
                            "
                        >
                            Edit
                        </button>
                        <div
                            [matTooltip]="
                                !['authorize', 'zipi_pay', 'zipi_financial'].includes(element.payment_mode)
                                    ? 'Available only for Money Transfers (EFT) and Authorize.Net.'
                                    : element.summary_status !== 'completed'
                                      ? 'Payment must be completed.'
                                      : ''
                            "
                            [matTooltipDisabled]="
                                ['authorize', 'zipi_pay', 'zipi_financial'].includes(element.payment_mode) &&
                                element.summary_status === 'completed'
                            "
                        >
                            <button
                                [rule]="{sales__manage_payments_received: true}"
                                rbac
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'inline-block'}"
                                [denyStyle]="{display: 'none'}"
                                mat-menu-item
                                [disabled]="
                                    !['authorize', 'zipi_pay', 'zipi_financial'].includes(element.payment_mode) ||
                                    element.summary_status !== 'completed'
                                "
                                (click)="
                                    $event.stopPropagation();
                                    router.navigate(['sales/paymentsreceived/refund', element.payment_received_id])
                                "
                            >
                                Refund
                            </button>
                        </div>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="row.id" class="zp-table-row"></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 text-center no-items">
            There are no payments.
        </div>
    </div>
</section>
