<ng-container>
    <h4>Selected deals</h4>
    <div *ngIf="selectedDeals && selectedDeals.length">
        <mat-form-field class="w-100 multi-picker">
            <mat-chip-list aria-label="Deals selection">
                <ng-container *ngFor="let deal of selectedDeals">
                    <mat-chip (removed)="removeDeal(deal.id)">
                        {{ deal.title }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </ng-container>
            </mat-chip-list>
        </mat-form-field>
    </div>

    <app-deal-picker
        #dealPicker
        class="w-100"
        [dealControl]="dealFormControl"
        [disabledSelect]="disabledSelect"
        [excludeDealsIds]="listOfExcludedDealsIds"
        [placeholder]="placeholder"
        (onSelectDeal)="onSelectDeal($event)"
    >
    </app-deal-picker>
</ng-container>
