const TYPE_INFO = 'info';
const TYPE_ERROR = 'error';
const TYPE_WARNING = 'warning';

export abstract class BaseNotification {
    constructor() {}

    protected viewed: boolean = false;
    protected quitable: boolean = true;
    protected objectLink: {link?: string; text?: string} = {};

    protected message: string = '';
    protected position: string = '';
    protected type: 'error' | 'info' | 'warning' = 'info';

    public state: 'active' | 'inactive' = 'inactive';

    public setType(type: 'error' | 'info' | 'warning') {
        this.type = type;
    }

    public setMessage(message: string) {
        this.message = message;
    }

    public setQuitableFlag(quitAllowed: boolean) {
        this.quitable = quitAllowed;
    }

    public setObjectLink(objectLink: {link?: string; text?: string}) {
        return (this.objectLink = objectLink);
    }

    public getMessage() {
        return this.message;
    }

    public getType() {
        return this.type;
    }

    public getObjectLink() {
        return this.objectLink;
    }

    public isQuitable() {
        return this.quitable;
    }

    public render() {}
}
