import {Injectable} from '@angular/core';
import {ServiceRequester} from '../service.requester';
import {IProfile, IUser, ICompany} from '../../../typings';
import {Observable} from 'rxjs';

@Injectable()
export class CompaniesService {
    private url = '/companies/';

    constructor(public requester: ServiceRequester) {}

    getProfiles(companyId: number): Observable<IProfile[]> {
        return this.requester.makeMsCall$(this.url + companyId + '/profiles', 'GET', 'shipp');
    }

    /**
     * @deprecated
     */
    getSubcompaniesList(companyId: number, includeNested?: boolean): Observable<ICompany[]> {
        return this.requester.makeMsCall$(this.url + companyId + '/subcompanies/', 'GET', 'shipp', {
            include_nested: !!includeNested
        });
    }

    /**
     * @deprecated
     */
    createSubcompany(companyId: number, subcompanyData: ICompany, email?: string): Observable<ICompany> {
        return this.requester.makeMsCall$(this.url + companyId + '/subcompanies/', 'POST', 'shipp', {
            company: subcompanyData,
            send_invite_to_email: email
        });
    }

    inviteNewUserToCompany(companyId: number, userData: IUser): Observable<IProfile> {
        return this.requester.makeMsCall$(this.url + companyId + '/profiles/invite', 'POST', 'shipp', userData);
    }

    suspendProfile(profileId: number, companyId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + companyId + `/profiles/${profileId}/suspend`, 'POST', 'shipp');
    }
}
