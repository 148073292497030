<div class="root paginator-spacer">
    <h3 class="title">{{ title }}</h3>

    <table mat-table [dataSource]="dataSource" [ngClass]="{'d-none': dataSource.data.length === 0}" class="w-100">
        <ng-container matColumnDef="invoice_date">
            <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
            <td mat-cell *matCellDef="let element">
                {{ element.invoice_date | dateFromNumber | date: 'mediumDate' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="invoice_number">
            <th mat-header-cell *matHeaderCellDef>Invoice #</th>
            <td mat-cell *matCellDef="let element">
                <a href="/sales/invoices/{{ element.invoice_id }}" target="_blank" class="pointer">{{
                    element.invoice_number
                }}</a>
            </td>
        </ng-container>

        <ng-container matColumnDef="summary_status">
            <th mat-header-cell *matHeaderCellDef>Invoice Status</th>
            <td mat-cell *matCellDef="let element">
                {{ element.summary_status | snakeOrKebabToTitleCase }}
            </td>
        </ng-container>

        <ng-container matColumnDef="status_of_payment">
            <th mat-header-cell *matHeaderCellDef>Paid Status</th>
            <td mat-cell *matCellDef="let element">
                {{ element.status_of_payment | snakeOrKebabToTitleCase }}
            </td>
        </ng-container>

        <ng-container matColumnDef="total_amount">
            <th mat-header-cell *matHeaderCellDef class="financial">Invoice Amount</th>
            <td mat-cell *matCellDef="let element" class="financial">
                {{ element.total_amount | currency: 'USD' : 'symbol' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="pending_balance">
            <th mat-header-cell *matHeaderCellDef class="financial">Amount Due</th>
            <td mat-cell *matCellDef="let element" class="financial">
                {{ element.pending_balance | currency: 'USD' : 'symbol' }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="table-grid"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-grid"></tr>
    </table>

    <!------- No values ------->
    <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 text-center no-items">
        There are no invoices.
    </div>

    <!------- Paginator ------->
    <div class="custom-paginator-background" *ngIf="dataSource.data?.length">
        <mat-paginator
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
            class="custom-paginator"
            (page)="handlePageChange($event)"
        >
        </mat-paginator>
    </div>
</div>
