import {merge as observableMerge, Observable, Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AppInitSource} from './app-init.source';

@Injectable()
export class PermissionsSource {
    private triggers = {
        initApp: new Subject<Object>(),
        realoadPermissions: new Subject<Object>()
    };

    private events = {
        permissions: <Observable<Object>>observableMerge(this.triggers.initApp, this.triggers.realoadPermissions)
    };

    private currentPermissions: Object | null = null;

    public get permissions(): Observable<Object> {
        if (this.currentPermissions) {
            return this.events.permissions.pipe(startWith(this.currentPermissions));
        }
        return this.events.permissions;
    }

    constructor(
        protected router: Router,
        protected appInitSource: AppInitSource
    ) {
        this.appInitSource.appInit.subscribe((appInitResponse) => {
            this.currentPermissions = appInitResponse.permissions;
            this.triggers.initApp.next(this.currentPermissions!);
        });
    }

    permissionsReloaded(permissions: Object) {
        this.triggers.realoadPermissions.next(permissions);
    }
}
