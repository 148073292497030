<!-- Deals list -->
<app-deal-list
    style="width: 100%"
    [dealsBoard]="dealsBoard"
    [createBoardMode]="createBoardMode"
    [offsetHeight$]="offsetHeight$"
    [boardsList]="boardsList"
    [activeTabIndex]="savedActiveTab"
    (createBoardModeEmitter)="createBoardMode = $event"
    (savedActiveTabEmitter)="savedActiveTab = $event"
    (dealsBoardRemoved)="boardsList = $event"
></app-deal-list>

<!-- Create Deal board -->
<app-add-deals-board
    (dealsBoardCreated)="onDealsBoardCreated($event)"
    (createBoardModeClosed)="createBoardMode = false"
    [createBoardMode]="createBoardMode"
></app-add-deals-board>
