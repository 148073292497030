import {Injectable} from '@angular/core';
import {ServiceRequester} from '../service.requester';
import {ISettings} from '../../../typings';
import {SessionService} from '../session.service';

@Injectable()
export class SettingsService {
    private url = '/profiles/';

    constructor(
        public requester: ServiceRequester,
        protected sessionService: SessionService
    ) {}

    getProfileSettings(): Promise<ISettings> {
        return this.requester.makeMsCall(this.url + `${this.sessionService.profile!.id}/settings`, 'GET');
    }

    updateProfileSettings(settings: ISettings): Promise<ISettings> {
        return this.requester.makeMsCall(this.url + `${this.sessionService.profile!.id}/settings`, 'PUT', settings);
    }

    partialUpdateProfileSettings(settings: ISettings): Promise<ISettings> {
        return this.requester.makeMsCall(
            this.url + `${this.sessionService.profile!.id}/settings/partial`,
            'PUT',
            settings
        );
    }
}
