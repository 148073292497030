<section class="container-fluid p-4">
    <header class="mb-4">
        <h1 *ngIf="!recurringCreditNote" class="">Create Recurring Credit Note</h1>
        <h1 *ngIf="recurringCreditNote" class="">Edit Recurring Credit Note</h1>
    </header>

    <div class="mb-3">
        <div [formGroup]="recurringFormGroup" class="row mb-3">
            <mat-form-field class="col col-sm-6">
                <input matInput type="text" placeholder="Title" formControlName="recurrence_title" autocomplete="off" />
                <mat-error *ngIf="recurringFormGroup.controls.recurrence_title.hasError('required')"
                    >required</mat-error
                >
            </mat-form-field>

            <mat-form-field
                class="col"
                [ngClass]="{
                    'col-sm-6':
                        !recurringFormGroup.controls.repeat.value ||
                        recurringFormGroup.controls.repeat.value?.period !== 'custom',
                    'col-sm-3': recurringFormGroup.controls.repeat.value?.period === 'custom'
                }"
            >
                <mat-select formControlName="repeat" placeholder="Repeat Every" [compareWith]="comparePeriod">
                    <mat-option *ngFor="let period of repeatPeriod" [value]="period[1]">
                        {{ period[0] | titlecase }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-sm-3" *ngIf="recurringFormGroup.controls.repeat.value?.period === 'custom'">
                <input matInput type="text" placeholder="Number of days" formControlName="repeat_custom_days" />
            </mat-form-field>

            <app-date-picker
                class="d-inline-block col-6"
                [placeholder]="'Start On'"
                [dateControl]="recurringFormGroup.controls.start_on"
            ></app-date-picker>

            <app-date-picker
                class="d-inline-block col-6"
                [isRequired]="false"
                [placeholder]="'End On'"
                [dateControl]="recurringFormGroup.controls.end_on"
            ></app-date-picker>
        </div>

        <h2>Credit Note Template</h2>
        <div class="mb-4">
            <app-credit-note-template
                (onUpdate)="onTemplateUpdate($event)"
                [creditNoteTemplate]="creditNoteBase"
            ></app-credit-note-template>
        </div>

        <footer class="text-right">
            <button
                type="submit"
                color="warn"
                mat-raised-button
                *ngIf="recurringCreditNote"
                (click)="deleteRecurringCreditNote()"
            >
                Delete
            </button>

            <span class="sp-spacer"></span>

            <button mat-raised-button class="ml-2" routerLink="/sales/creditnotes/recurring">Cancel</button>
            <button
                type="submit"
                class="ml-2"
                mat-raised-button
                color="primary"
                *ngIf="!recurringCreditNote"
                [disabled]="!recurringFormGroup.valid || !maybeValidCreditNote.valid"
                (click)="createRecurringCreditNote()"
            >
                Create
            </button>
            <button
                type="submit"
                class="ml-2"
                mat-raised-button
                color="primary"
                *ngIf="recurringCreditNote"
                [disabled]="!recurringFormGroup.valid || !maybeValidCreditNote.valid"
                (click)="updateRecurringCreditNote()"
            >
                Save
            </button>
        </footer>
    </div>
</section>
