<section class="bg-white">
    <!------- Header ------->
    <header class="d-flex align-items-center px-3 list-header">
        <h3 class="m-0 finance-menu">Payments Made</h3>

        <i class="sp-spacer"></i>

        <mat-form-field class="mt-2" [floatLabel]="'never'">
            <mat-label>Status filter</mat-label>
            <mat-select [formControl]="statusFilter" multiple>
                <mat-option
                    *ngFor="let status of statusTypes"
                    [value]="status"
                    (onSelectionChange)="changeStatusFilter($event)"
                >
                    {{ status | fromSnakeCase }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-icon
            [rule]="{purchases__manage_payments_made: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            (click)="recheckPaymentStatus()"
            matTooltip="Recheck Payment Status"
            class="mx-4"
            style="cursor: pointer"
            >cached</mat-icon
        >

        <button
            type="button"
            mat-raised-button
            color="primary"
            [rule]="{purchases__manage_payments_made: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            routerLink="/purchases/payments/create"
        >
            <mat-icon>add</mat-icon>
            Create
        </button>
    </header>

    <!------- List of payments ------->
    <div class="table-background">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef class="w-5"></th>
                <td mat-cell *matCellDef="let element" class="w-5" (click)="$event.stopPropagation()">
                    <mat-checkbox
                        (change)="toggleBulkCheckbox($event, element.payment_made_id)"
                        [value]="element.payment_made_id"
                        [checked]="selection.isSelected(element.payment_made_id)"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="paid_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Payment Date</th>
                <td mat-cell *matCellDef="let element" class="w-10 pr-1">
                    <div class="d-flex align-items-center">
                        <span>{{ element.paid_date | dateFromNumber | date: 'mediumDate' }}</span>
                        <mat-icon
                            [id]="'paymentMadeProcessingInfo'"
                            class="ml-1 processing-info-icon"
                            *ngIf="
                                ['authorize', 'zipi_financial'].includes(element.payment_mode) &&
                                element.summary_status === 'processing'
                            "
                        >
                            info
                        </mat-icon>

                        <app-custom-popover
                            *ngIf="
                                ['authorize', 'zipi_financial'].includes(element.payment_mode) &&
                                element.summary_status === 'processing'
                            "
                            [theme]="'dark'"
                            [position]="'right'"
                            [elementId]="'paymentMadeProcessingInfo'"
                            [width]="325"
                        >
                            <div class="w-100 p-0" style="font-size: 16px">
                                <div class="d-flex my-3 p-0">
                                    <span
                                        >Withdrawal is pending. Once funds leave your account, the payment date will be
                                        updated.</span
                                    >
                                </div>
                            </div>
                        </app-custom-popover>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Status</th>
                <td mat-cell *matCellDef="let element" class="w-10">
                    <span [ngStyle]="{color: statusColor[getPaymentMadeStatus(element)]}">
                        {{ getPaymentMadeStatus(element) | fromSnakeCase }}
                    </span>

                    <mat-icon
                        class="ml-1"
                        style="font-size: 20px; color: orange; margin-bottom: 2px"
                        [matTooltip]="
                            'The Payment does not have related Ledger Account. Please setup it on the Edit Page'
                        "
                        *ngIf="!element.paid_by__ledger_account_fk_id"
                    >
                        warning
                    </mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="vendor_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Vendor</th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    class="w-10"
                    [ngClass]="element.contactIsHidden ? 'hidden-contact' : ''"
                >
                    {{ element.vendor_name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="payment_made_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Payment#</th>
                <td mat-cell *matCellDef="let element" class="w-10">
                    {{ element.payment_made_number }}
                </td>
            </ng-container>

            <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15 pl-1">Reference#</th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1 py-1">
                    {{ element.reference }}
                </td>
            </ng-container>

            <ng-container matColumnDef="check_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Check#</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.check_info?.check_number }}
                </td>
            </ng-container>

            <ng-container matColumnDef="payment_mode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Payment Mode</th>
                <td mat-cell *matCellDef="let element" class="w-10">
                    <ng-container *ngIf="element.payment_mode">
                        {{ paymentModesMap[element.payment_mode] }}
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="amount_applied">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 table-header-align-right">Applied</th>
                <td mat-cell *matCellDef="let element" class="w-10 pr-3 text-right">
                    {{ element.amount_applied | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 table-header-align-right">
                    Total Amount Paid
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    class="w-10 pr-3 text-right"
                    [ngStyle]="element.type === 'refund' ? {color: 'red'} : null"
                >
                    {{ element.type === 'normal' ? (element.amount | currency) : (element.amount * -1 | currency) }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="w-5 text-right">Action</th>
                <td mat-cell *matCellDef="let element" class="w-5 text-right">
                    <button
                        mat-icon-button
                        [rule]="{purchases__manage_payments_made: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        [matMenuTriggerFor]="menu"
                        (click)="$event.stopPropagation()"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button
                            mat-menu-item
                            [rule]="{purchases__manage_payments_made: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            (click)="
                                $event.stopPropagation();
                                router.navigate(['purchases/payments/edit', element.payment_made_id])
                            "
                        >
                            Edit
                        </button>
                        <button
                            mat-menu-item
                            [rule]="{purchases__manage_payments_made: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            *ngIf="['processing'].includes(element.status) && element.external_transaction_ref"
                            (click)="$event.stopPropagation(); paymentCancel(element)"
                        >
                            Cancel Payment
                        </button>
                        <button mat-menu-item *ngIf="isPayStubPrintAvailable" (click)="printPayStub(element)">
                            Print Paystub
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="row.id" class="zp-table-row"></tr>
        </table>

        <!--Selection Toolbar-->
        <app-multi-selection-toolbar
            [numberSelected]="selection.selected.length"
            selectionName="Payment"
            *ngIf="selection.hasValue()"
            [toolbarMinWidth]="'880px'"
        >
            <button
                style="width: 105px; margin-left: 5px"
                class="mat-button"
                [rule]="{purchases__manage_payments_made: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                (click)="bulkDelete()"
            >
                <mat-icon class="action-button-icon">delete</mat-icon>
                Delete
            </button>
            <button class="mat-button" *ngIf="batchCheckPrintingEnabled" (click)="openCustomizeDialog()">
                <span style="width: 18px; height: 18px" class="material-symbols-outlined action-button-icon"
                    >ink_pen</span
                >
                Print {{ selection.selected.length > 1 ? 'Checks' : 'Check' }}
            </button>
            <button class="mat-button" (click)="downloadPayStub()" *ngIf="isPayStubPrintAvailable">
                <span style="width: 18px; height: 18px" class="material-symbols-outlined action-button-icon"
                    >download</span
                >
                Download {{ selection.selected.length > 1 ? 'PayStubs' : 'PayStub' }}
            </button>
        </app-multi-selection-toolbar>
        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 no-items">
            There are no payments.
        </div>
    </div>
</section>
