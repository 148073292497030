import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {UntypedFormArray, UntypedFormBuilder} from '@angular/forms';
import {IBill, IInvoice, IProduct} from '@cyberco-nodejs/zipi-typings';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-failed-connection-dialog',
    templateUrl: './failed-connection-dialog.component.html',
    styleUrls: ['./failed-connection-dialog.component.scss']
})
export class FailedConnectionDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    invoice: IInvoice | IBill | undefined;
    itemsArray: UntypedFormArray = this.fb.array([]);
    product$: Observable<IProduct[]> | undefined;

    constructor(
        private fb: UntypedFormBuilder,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<FailedConnectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {}

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
