import {Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {timePeriodObj, listOfTen99Forms, listOfYesNoAnswer} from '../../../../constants';
import {getNextActivePanel, isLastPanelItem} from '../../helper';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {IReport, IReportSetting, IReportSettingKeys} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-report-edit-bar-ten99-nec',
    templateUrl: './ten99-nec.component.html',
    styleUrls: ['./ten99-nec.component.scss']
})
export class Ten99NecBarComponent implements OnInit, OnDestroy {
    @Output() valueChanges: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Output() action: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Input() report: IReport | null = null;
    @Input() reportType: string | null = null;

    private defaultValues: {[key: string]: any} = {
        time_period: timePeriodObj.last_year.value,
        ten99_forms: ['copy_a'],
        count_contact_in_pdf_file: 5,
        is_hide_tin_data_in_pdf: false
    };

    public activePanel: string = 'time_period';
    public ten99FormList = {
        ten99_int: ['copy_a', 'copy_1', 'copy_b', 'recipient_instruction', 'copy_2', 'copy_c', 'payer_instruction'],
        ten99_misc: ['copy_a', 'copy_1', 'copy_b', 'recipient_instruction', 'copy_2'],
        ten99_nec: ['copy_a', 'copy_1', 'copy_b', 'recipient_instruction', 'copy_2']
    };
    public listOfTen99Forms: Array<{title: string; value: string}> = [];
    public listOfYesNoAnswer: Array<{title: string; value: boolean}> = listOfYesNoAnswer;
    public reportSettings: IReportSetting | null = null;
    public isShowNext: boolean = true;
    public ten99FormGroup: UntypedFormGroup | null = null;
    public reportOptions = [
        {
            value: 'time_period',
            title: 'Time Period'
        },
        {
            value: 'entity',
            title: 'Entity'
        },
        {
            value: 'ten99_forms',
            title: 'Form Pages'
        },
        {
            value: 'pdf_generation',
            title: 'Pdf Generation'
        }
    ];

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit() {
        this.initForm();
        this.initData();
    }

    initData() {
        if (this.report) {
            this.reportSettings = {...this.defaultValues, ...this.report.settings};
            if (this.ten99FormGroup) {
                this.ten99FormGroup.patchValue(this.reportSettings);
            }
        }
    }

    initForm() {
        // @ts-ignore
        this.listOfTen99Forms = listOfTen99Forms[this.reportType];
        this.ten99FormGroup = this.fb.group({
            count_contact_in_pdf_file: [false, []]
        });
    }

    onSelectOptions(event: {value: string}) {
        this.activePanel = event.value;
        this.isShowNext = !isLastPanelItem(this.activePanel, this.reportOptions);
    }

    onValueChanges(property: IReportSettingKeys, data: any) {
        switch (property) {
            case 'time_period':
                this.reportSettings = {
                    ...this.reportSettings,
                    ...data
                };
                break;
            case 'ten99_forms':
                if (this.reportSettings) {
                    if (data.includes('all')) {
                        // @ts-ignore
                        this.reportSettings['ten99_forms'] = this.ten99FormList[this.reportType];
                    } else {
                        this.reportSettings['ten99_forms'] = data;
                    }
                }
                break;
            default:
                if (this.reportSettings) {
                    this.reportSettings[property] = data as never;
                }
        }
    }

    onAction(event: string) {
        const response: {
            type: string;
            data: IReportSetting | null;
        } = {
            type: event,
            data: null
        };

        switch (event) {
            case 'save':
            case 'saveAndRun':
            case 'run':
                const ten99FormGroup = this.ten99FormGroup ? this.ten99FormGroup.value : {};
                response['data'] = {
                    ...this.reportSettings,
                    ...ten99FormGroup
                };
                this.action.emit(response);
                break;
            case 'cancel':
            case 'delete':
                this.action.emit(response);
                break;
            case 'next':
                const nextData = getNextActivePanel(this.activePanel, this.reportOptions);
                this.activePanel = nextData.name;
                this.isShowNext = !nextData.isLast;
                break;
            default:
        }
    }

    ngOnDestroy() {
        this.action.complete();
    }
}
