import {Routes, RouterModule} from '@angular/router';
import {SignUpComponent} from './sign-up/sign-up.component';
import {SignInComponent} from './sign-in/sign-in.component';
import {OauthComponent} from './oauth/oauth.component';
import {ConnectUserComponent} from './connect-user/connect-user.component';
import {GuestBootstrapService} from 'app/services/resolvers/guest-bootstrap.service';
import {PublicBootstrapService} from 'app/services/resolvers/public-bootstrap.service';
import {AuthBootstrapService} from 'app/services/resolvers/auth-bootstrap.service';
import {GuestLayoutComponent} from 'app/layouts/guest-layout.component';
import {InviteComponent} from './invite/invite.component';
import {EmailChangeComponent} from './email-change/email-change.component';
import {SignUpContactInviteComponent} from './sign-up-contact-invite/sign-up-contact-invite.component';
import {OuterLinkTriggerComponent} from './outer-link-trigger/outer-link-trigger.component';
import {SignUpZohoInviteComponent} from './sign-up-zoho-invite/sign-up-zoho-invite.component';
import {SignUpContactInvoiceComponent} from './sign-up-contact-invoice/sign-up-contact-invoice.component';
import {SignInContactInvoiceComponent} from './sign-in-contact-invoice/sign-in-contact-invoice.component';
import {SignUpContactDepositRequestComponent} from './sign-up-contact-deposit-request/sign-up-contact-deposit-request.component';
import {SignInAsComponent} from '@app/modules/auth/sign-in-as/sign-in-as.component';

export const routes: Routes = [
    {
        path: 'auth',
        component: GuestLayoutComponent,
        children: [
            {
                path: 'sign-up/:redirect_hash',
                component: SignUpComponent,
                resolve: {
                    guestBootstrap: GuestBootstrapService
                }
            },

            {
                path: 'sign-up-contact-invite/:invite_hash',
                component: SignUpContactInviteComponent,
                resolve: {
                    guestBootstrap: GuestBootstrapService
                }
            },

            {
                path: 'sign-up-contact-invoice/:invoice_hash',
                component: SignUpContactInvoiceComponent,
                resolve: {
                    guestBootstrap: GuestBootstrapService
                }
            },
            {
                path: 'sign-up-contact-deposit-request/:request_hash',
                component: SignUpContactDepositRequestComponent,
                resolve: {
                    guestBootstrap: GuestBootstrapService
                }
            },

            {
                path: 'oauth',
                component: OauthComponent,
                resolve: {
                    guestBootstrap: PublicBootstrapService
                }
            },

            {
                path: 'sign-in',
                component: SignInComponent,
                resolve: {
                    guestBootstrap: GuestBootstrapService
                }
            },
            {
                path: 'sign-in-contact-invoice',
                component: SignInContactInvoiceComponent,
                resolve: {
                    guestBootstrap: GuestBootstrapService
                }
            },

            {
                path: 'sign-in/as/:login_as_uid',
                component: SignInAsComponent,
                resolve: {
                    guestBootstrap: PublicBootstrapService
                }
            },

            {
                path: 'connect-user',
                component: ConnectUserComponent,
                resolve: {
                    guestBootstrap: AuthBootstrapService
                }
            },

            {
                path: 'invite/:invite_hash',
                component: InviteComponent,
                resolve: {
                    guestBootstrap: PublicBootstrapService
                }
            },

            {
                path: 'email-change/:change_hash',
                component: EmailChangeComponent,
                resolve: {
                    guestBootstrap: PublicBootstrapService
                }
            }
        ]
    },
    {
        path: 'company/:company_id/new-request/:load_id',
        component: OuterLinkTriggerComponent,
        resolve: {
            guestBootstrap: PublicBootstrapService
        }
    },
    {
        path: 'company/:company_id/new-message/:load_id',
        component: OuterLinkTriggerComponent,
        resolve: {
            guestBootstrap: PublicBootstrapService
        }
    },
    {
        path: 'registration-invite/:invite_hash',
        component: GuestLayoutComponent,
        children: [
            {
                path: '',
                component: SignUpZohoInviteComponent,
                resolve: {
                    guestBootstrap: PublicBootstrapService
                }
            }
        ]
    }
];

export const routing = RouterModule.forChild(routes);
