import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    Output,
    EventEmitter,
    Input,
    ViewChildren,
    QueryList
} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSidenav} from '@angular/material/sidenav';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {filter, map, takeUntil} from 'rxjs/operators';
import {NotificationsService} from 'angular2-notifications';
import {IFinanceState} from '../../../store/finance.reducer';
import {IJournal, IJournalTemplate, ITransaction} from '@cyberco-nodejs/zipi-typings';
import {FetchCompanySettings} from '../../../../account-info/store/settings.actions';
import {selectCompanySettings} from '../../../../account-info/store/settings.selectors';
import {ISettingsState} from '../../../../account-info/store/settings.reducer';
import {JournalsService} from 'app/services/api/finance/journals.service';
import {LedgerAccountSelectorComponent} from '../../../../shared/components/ledger-account-selector/ledger-account-selector.component';
import {GenericFormArray} from 'app/entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {FormGroupWithFormControls} from '../../../../../typings/common';
import Decimal from 'decimal.js-light';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';
import {convertNumberToMaskedStringWithRequireDecimals, formatToDate} from '../../../../../utilities';

@Component({
    selector: 'app-journal-create-bar',
    templateUrl: 'journal-create-bar.component.html',
    styleUrls: ['journal-create-bar.component.scss']
})
export class JournalCreateBarComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChildren('ledgerAccountSelector') public ledgerAccountSelector:
        | QueryList<LedgerAccountSelectorComponent>
        | undefined;
    @ViewChild('sidenavJournal', {static: true}) sidenavJournal: MatSidenav | undefined;
    @Output() onToggle = new EventEmitter<boolean>();
    @Output() delete = new EventEmitter();
    @Output() updateListEmitter = new EventEmitter();

    @Input() isOpened: boolean = false;

    isDebitCreditEqual: boolean = true;
    reader = new FileReader();
    currencyMaskitoMask = currencyMaskitoOptions;
    total_debit: number = 0;
    total_credit: number = 0;
    hasZeroAmount: boolean = true;

    lineItemsArray: UntypedFormArray = this.fb.array([]);
    journalFormGroup: FormGroupWithFormControls = this.fb.group({
        journal_number: [{value: '', disabled: true}],
        reference_number: [''],
        date: [formatToDate(new Date())],
        type: ['both'],
        status: [''],
        notes: [''],

        line_items: this.lineItemsArray
    }) as FormGroupWithFormControls;
    initJournalFormGroupSnapshot = {};

    next_journal_number: number | undefined;
    journal: IJournal | undefined;

    isThereMatchedTransaction: boolean;

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store<IFinanceState>,
        private storeSettings: Store<ISettingsState>,
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private ntfs: NotificationsService,
        private journalsService: JournalsService
    ) {
        this.isThereMatchedTransaction = false;
    }

    ngOnInit() {
        this.storeSettings.dispatch(new FetchCompanySettings());
        if (typeof this.sidenavJournal !== 'undefined') {
            this.sidenavJournal.opened = this.isOpened;
        }

        this.initSubscribe();

        this.addTransactions(2);
    }

    /**
     * Init subscribe
     */
    initSubscribe() {
        this.storeSettings
            .select(selectCompanySettings)
            .pipe(
                filter((cs) => !!cs),
                takeUntil(this.unsubscribe)
            )
            .subscribe((cs) => {
                const next_journal_number = cs && cs.last_used_journal_num ? cs.last_used_journal_num + 1 : 1;

                this.next_journal_number = next_journal_number;
                if (typeof this.sidenavJournal !== 'undefined') {
                    this.journalFormGroup.controls.journal_number!.patchValue(next_journal_number);
                }

                this.initJournalFormGroupSnapshot = this.journalFormGroup.getRawValue();
            });

        this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (typeof this.sidenavJournal !== 'undefined' && this.sidenavJournal.opened) {
                    this.close();
                }
            }
        });

        (this.journalFormGroup.get('line_items') as UntypedFormArray).valueChanges
            .pipe(
                map((val) => {
                    const itemsArr = (this.journalFormGroup.get('line_items') as UntypedFormArray).getRawValue();
                    let sum_debit: Decimal = new Decimal(0);
                    let sum_credit: Decimal = new Decimal(0);

                    const unMuskLineItems = itemsArr.map((item: any) => {
                        const unMuskAmount = cleanCurrencyString(item[item.debit_or_credit]);

                        if (!isNaN(Number(unMuskAmount))) {
                            if (item.debit_or_credit === 'debit') {
                                sum_debit = sum_debit.add(Number(unMuskAmount));
                            } else {
                                sum_credit = sum_credit.add(Number(unMuskAmount));
                            }
                        }

                        return {
                            ...item,
                            amount: unMuskAmount
                        };
                    });

                    this.total_debit = sum_debit.toDecimalPlaces(2).toNumber();
                    this.total_credit = sum_credit.toDecimalPlaces(2).toNumber();
                    this.isDebitCreditEqual =
                        sum_debit.toDecimalPlaces(2).toNumber() === sum_credit.toDecimalPlaces(2).toNumber();
                    this.hasZeroAmount = !!unMuskLineItems.find((item: any) => Number(item.amount) === 0);

                    return unMuskLineItems;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    }

    patchTemplate(journalTemplate: IJournalTemplate) {
        const control = this.journalFormGroup.controls.line_items as any as UntypedFormArray;

        control.clear();

        this.journalFormGroup.controls.journal_number!.patchValue(this.next_journal_number);
        this.journalFormGroup.controls.reference_number!.patchValue(journalTemplate.reference_number);
        this.journalFormGroup.controls.type!.patchValue(journalTemplate.type);
        this.journalFormGroup.controls.notes!.patchValue(journalTemplate.notes);

        for (const item of journalTemplate.line_items) {
            const group = this.fb.group({
                transaction_id: null,
                ledger_account_fk_id: [
                    this.journalFormGroup?.controls.type.value === 'cash'
                        ? item.cash__ledger_account_fk_id
                        : item.ledger_account_fk_id,
                    [Validators.required]
                ],
                description: item.description,
                debit_or_credit: item.debit_or_credit,
                debit:
                    item.debit_or_credit === 'debit'
                        ? convertNumberToMaskedStringWithRequireDecimals(item.amount)
                        : convertNumberToMaskedStringWithRequireDecimals(0),
                credit:
                    item.debit_or_credit === 'credit'
                        ? convertNumberToMaskedStringWithRequireDecimals(item.amount)
                        : convertNumberToMaskedStringWithRequireDecimals(0),
                division__company_group_fk_id: [item ? item.division__company_group_fk_id : null, []],
                selected_divisions: new GenericFormArray<ChipNode>([]),
                selected_division_ids: [item ? item.division__company_group_fk_id : null],
                selected_full_ledger: null,
                matched_transaction_external_id: null
            });

            group.controls.selected_divisions.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
                if (changes && changes.length > 0) {
                    group.controls.division__company_group_fk_id.setValue(changes[0].target_id);
                } else {
                    group.controls.division__company_group_fk_id.setValue(null);
                }
            });
            control.push(group);
        }
    }

    setReverseJournal(mj: IJournal) {
        const reverseJournal: IJournal = {
            ...mj,
            line_items: mj.line_items?.map((item) => ({
                ...item,
                debit_or_credit: item.debit_or_credit === 'credit' ? 'debit' : 'credit',
                debit: item.debit_or_credit === 'debit' ? 0 : item.amount,
                credit: item.debit_or_credit === 'credit' ? 0 : item.amount
            }))
        };

        while (this.lineItemsArray.length !== 0) {
            this.lineItemsArray.removeAt(0);
        }

        this.journalFormGroup.patchValue({
            journal_number: this.next_journal_number,
            reference_number: reverseJournal.reference_number,
            date: reverseJournal.date,
            type: reverseJournal.type,
            status: reverseJournal.status,
            notes: reverseJournal.notes
        });

        if (reverseJournal.line_items) {
            this.addFilledTransactions(reverseJournal.line_items);
        }
    }

    addFilledTransactions(items: ITransaction[]) {
        for (const item of items) {
            const itemFG = this.fb.group({
                transaction_id: item.transaction_id || null,
                ledger_account_fk_id: [
                    this.journalFormGroup?.controls.type.value === 'cash'
                        ? item.cash__ledger_account_fk_id
                        : item.ledger_account_fk_id,
                    [Validators.required]
                ],
                description: item.description,
                debit_or_credit: item.debit_or_credit,
                debit:
                    item.debit_or_credit === 'debit'
                        ? convertNumberToMaskedStringWithRequireDecimals(item.amount)
                        : convertNumberToMaskedStringWithRequireDecimals(0),
                credit:
                    item.debit_or_credit === 'credit'
                        ? convertNumberToMaskedStringWithRequireDecimals(item.amount)
                        : convertNumberToMaskedStringWithRequireDecimals(0),
                division__company_group_fk_id: [item ? item.division__company_group_fk_id : null, []],
                selected_divisions: new GenericFormArray<ChipNode>([]),
                selected_division_ids: [item ? item.division__company_group_fk_id : null],
                selected_full_ledger: null,
                matched_transaction_external_id: item.matched__transaction_external_id
            });
            if (item.matched__transaction_external_id) {
                this.isThereMatchedTransaction = true;
                itemFG.controls.debit.disable();
                itemFG.controls.credit.disable();
            }

            itemFG.controls.selected_divisions.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
                if (changes && changes.length > 0) {
                    itemFG.controls.division__company_group_fk_id.setValue(changes[0].target_id);
                } else {
                    itemFG.controls.division__company_group_fk_id.setValue(null);
                }
            });

            this.lineItemsArray.push(itemFG);
        }
    }

    setEditJournal(mj: IJournal) {
        const journal = mj;
        this.journal = mj;

        while (this.lineItemsArray.length !== 0) {
            this.lineItemsArray.removeAt(0);
        }

        this.journalFormGroup.patchValue({
            journal_number: journal.journal_number,
            reference_number: journal.reference_number,
            date: journal.date,
            type: journal.type,
            status: journal.status,
            notes: journal.notes
        });

        if (mj.line_items) {
            this.addFilledTransactions(mj.line_items);
        }
    }

    validateForm() {
        if (this.journalFormGroup.valid && !this.hasZeroAmount) {
            return true;
        }

        this.ntfs.warn('Form is not valid');
        this.journalFormGroup.updateValueAndValidity();

        return false;
    }

    getFormValues() {
        const {line_items, journal_number, ...values} = this.journalFormGroup.getRawValue();
        const unMuskLineItems = line_items.map(
            (item: ITransaction & {debit_or_credit: 'debit' | 'credit'; debit: number; credit: number}) => {
                return {
                    transaction_id: item.transaction_id,
                    debit_or_credit: item.debit_or_credit,
                    description: item.description,
                    ledger_account_fk_id:
                        this.journalFormGroup?.controls.type.value === 'cash' ? null : item.ledger_account_fk_id,
                    cash__ledger_account_fk_id: item.ledger_account_fk_id,
                    amount: cleanCurrencyString(String(item[item.debit_or_credit])),
                    division__company_group_fk_id: item.division__company_group_fk_id
                };
            }
        );

        return {
            ...values,
            line_items: unMuskLineItems,
            reason_type: 'manual_journal',
            journal_number: null
        };
    }

    createJournal(status: 'published' | 'draft') {
        if (!this.validateForm()) {
            this.lineItemsArray.markAllAsTouched();
            this.ledgerAccountSelector?.forEach((el) => el.markTouched());

            return;
        }

        this.journalFormGroup.controls.status.patchValue(status);

        const journalData = this.getFormValues();

        this.journalsService
            .createJournal(journalData)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.updateListEmitter.emit();
                this.storeSettings.dispatch(new FetchCompanySettings());
                this.close();
            });
    }

    updateJournal(status: 'published' | 'draft') {
        if (!this.validateForm() || this.lineItemsArray.invalid) {
            this.lineItemsArray.markAllAsTouched();
            this.ledgerAccountSelector?.forEach((el) => el.markTouched());

            return;
        }

        this.journalFormGroup.controls.status.patchValue(status);

        const editedJournal = this.getFormValues();

        if (typeof this.journal === 'undefined') {
            return;
        }

        this.journalsService
            .updateJournal({
                journal_id: this.journal.journal_id,
                ...editedJournal
            })
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.updateListEmitter.emit();
                this.close();
            });
    }

    deleteJournal() {
        if (typeof this.journal === 'undefined') {
            return;
        }

        this.journalsService
            .deleteJournal(this.journal.journal_id as number)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.updateListEmitter.emit();
                this.delete.emit();
                this.close();
            });
    }

    clearForm() {
        this.journal = undefined;

        const control = this.journalFormGroup.controls.line_items as any as UntypedFormArray;
        control.clear();

        this.journalFormGroup.patchValue(this.initJournalFormGroupSnapshot);
        this.addTransactions(2);
        this.journalFormGroup.controls.journal_number!.patchValue(this.next_journal_number);
    }

    close() {
        this.clearForm();
        if (typeof this.sidenavJournal !== 'undefined') {
            this.sidenavJournal.close();
        }
    }

    openReverse(journal: IJournal) {
        if (journal && typeof this.sidenavJournal !== 'undefined') {
            this.sidenavJournal.open();
            this.setReverseJournal(journal);
        }
    }

    openTemplate(journal: IJournalTemplate) {
        if (journal && typeof this.sidenavJournal !== 'undefined') {
            this.sidenavJournal.open();
            this.patchTemplate(journal);
        }
    }

    open(journal?: IJournal | null) {
        if (typeof this.sidenavJournal !== 'undefined') {
            this.sidenavJournal.open();
        }

        if (journal) {
            this.setEditJournal(journal);
        } else {
            this.clearForm();
        }
    }

    handleFileInput(files: File[]) {
        if (files.length === 0 || !files[0]) {
            return;
        }
        const [file] = files;

        if (file.size > 5000000) {
            throw new Error('File size too big. It must be under 5MB');
        } else {
            this.reader.readAsBinaryString(file);
        }
    }

    addTransactions(number = 1) {
        const control = this.journalFormGroup.controls.line_items as any as UntypedFormArray;

        const group = this.fb.group({
            transaction_id: null,
            ledger_account_fk_id: [null, [Validators.required]],
            description: '',
            debit_or_credit: 'debit',
            debit: convertNumberToMaskedStringWithRequireDecimals(0),
            credit: convertNumberToMaskedStringWithRequireDecimals(0),
            division__company_group_fk_id: null,
            selected_divisions: new GenericFormArray<ChipNode>([]),
            selected_division_ids: [null],
            selected_full_ledger: null
        });

        group.controls.selected_divisions.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (changes && changes.length > 0) {
                group.controls.division__company_group_fk_id.setValue(changes[0].target_id);
            } else {
                group.controls.division__company_group_fk_id.setValue(null);
            }
        });
        control.push(group);
        number >= 1 && --number && this.addTransactions(number);
    }

    deleteTransaction(index: number) {
        if ((this.journalFormGroup.get('line_items') as GenericFormArray<ITransaction>)['controls'].length > 2) {
            const control = this.journalFormGroup.controls.line_items as any as UntypedFormArray;

            control.removeAt(index);
        }
    }

    onType(index: number, type: 'debit' | 'credit', event: any) {
        const unMuskValue = cleanCurrencyString(event.target.value);
        const isEmptyValue = Number(unMuskValue) === 0 || event.target.value.length === 0;
        const control = this.journalFormGroup.controls.line_items as any as UntypedFormArray;

        if (isEmptyValue) {
            control
                .at(index)
                .get(type)!
                .setValue(convertNumberToMaskedStringWithRequireDecimals(Number(unMuskValue)));
        } else {
            control.at(index).setValue({
                ...control.at(index).value,
                debit: convertNumberToMaskedStringWithRequireDecimals(0),
                credit: convertNumberToMaskedStringWithRequireDecimals(0),
                [type]: convertNumberToMaskedStringWithRequireDecimals(Number(unMuskValue)),
                debit_or_credit: type
            });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.onToggle.complete();
        this.delete.complete();
        this.updateListEmitter.complete();
    }
}
