<mat-sidenav-container class="h-100" style="background-color: unset" (backdropClick)="close()">
    <mat-sidenav #sidebarTransactions mode="over" [position]="'end'" style="background-color: #fff">
        <app-report-sidenav *ngIf="reportData" [reportData]="reportData"></app-report-sidenav>
    </mat-sidenav>

    <div class="report-preview-wrapper">
        <router-outlet></router-outlet>
        <div *ngIf="!isLoaded" class="loader-wrapper">
            <mat-spinner></mat-spinner>
        </div>

        <div *ngIf="isLoaded">
            <mat-card class="report-preview" *ngIf="!ten99ReportTypes.includes(report.type)">
                <h1 class="report-organization-name" *ngIf="report.company">{{ report.company.title }}</h1>
                <h3 class="report-title">
                    <mat-icon
                        *ngIf="reportLevelBasedOnReportType[report.type] === 'company'"
                        matTooltip="Include Company data"
                        >business</mat-icon
                    >
                    <span>{{ report.title }}</span>
                </h3>

                <div *ngIf="report.settings" class="text-center">
                    <p *ngIf="timeTitleCategoryByReportType[report.type] === 'contact'">
                        <span>Contact Creation: </span>
                        <span *ngIf="report.displayed_time_period.startDate && report.displayed_time_period.endDate">
                            {{ report.displayed_time_period.startDate }} - {{ report.displayed_time_period.endDate }}
                        </span>
                        <span *ngIf="!report.displayed_time_period.startDate || !report.displayed_time_period.endDate">
                            Unrestricted
                        </span>
                    </p>

                    <p *ngIf="timeTitleCategoryByReportType[report.type] === 'general'">
                        <span *ngIf="report.settings.time_period">Time Period: </span>
                        <span
                            *ngIf="
                                report.displayed_time_period &&
                                report.displayed_time_period.startDate &&
                                report.displayed_time_period.endDate
                            "
                        >
                            {{ report.displayed_time_period.startDate }} - {{ report.displayed_time_period.endDate }}
                            <span *ngIf="report.settings.date_type && dateTypesObj[report.settings.date_type]"
                                >({{ dateTypesObj[report.settings.date_type].title }})</span
                            >
                        </span>
                        <span
                            *ngIf="
                                !report.displayed_time_period ||
                                !report.displayed_time_period.startDate ||
                                !report.displayed_time_period.endDate
                            "
                        >
                            Unrestricted
                        </span>
                    </p>

                    <p *ngIf="timeTitleCategoryByReportType[report.type] === 'as_of_date'">
                        <span *ngIf="report.settings.time_period && report.displayed_time_period.endDate">
                            As of {{ report.displayed_time_period.endDate }}
                        </span>
                    </p>

                    <p *ngIf="report.transactionStatusTitle">Status: {{ report.transactionStatusTitle }}</p>
                </div>

                <div class="report-actions" *ngIf="report.type">
                    <div *ngIf="!id" class="item-action save">
                        <button mat-button class="mat-default" (click)="save()">
                            <mat-icon>save</mat-icon>
                            Save
                        </button>
                    </div>

                    <div *ngIf="!id" class="item-action">
                        <button mat-button class="mat-default" (click)="toggleEditReportSidebar(true)">
                            <mat-icon>create_outline</mat-icon>
                        </button>
                    </div>

                    <div *ngIf="id" class="item-action">
                        <button mat-button class="mat-default" (click)="toggleEditReportSidebar(true, id)">
                            <mat-icon>create_outline</mat-icon>
                        </button>
                    </div>

                    <div class="item-action">
                        <button
                            *ngIf="!['tracking', 'contact', 'recurring_invoice'].includes(report.type)"
                            mat-button
                            class="mat-default"
                            (click)="exportToCSV()"
                        >
                            <mat-icon>get_app_outline</mat-icon>
                        </button>

                        <button
                            *ngIf="report.type === 'contact'"
                            mat-button
                            class="mat-default export-contact"
                            (click)="openExportOptionsCSV()"
                        >
                            <mat-icon>get_app_outline</mat-icon>
                            <mat-select #exportCSV>
                                <mat-option
                                    *ngFor="let option of listOfExportOptions"
                                    [value]="option.value"
                                    (click)="exportToCSV(option.value)"
                                >
                                    <span>{{ option.title }}</span>
                                </mat-option>
                            </mat-select>
                        </button>
                    </div>

                    <div class="item-action">
                        <button
                            *ngIf="
                                !['tracking', 'contact', 'agent-performance', 'recurring_invoice', 'ten99'].includes(
                                    report.type
                                )
                            "
                            mat-button
                            class="mat-default"
                            (click)="exportToPdf(report.type)"
                        >
                            <mat-icon>print_outline</mat-icon>
                        </button>
                        <button
                            *ngIf="report.type === 'contact' || report.type === 'agent-performance'"
                            mat-button
                            class="mat-default export-contact"
                            (click)="openExportOptionsPDF()"
                        >
                            <mat-icon>print_outline</mat-icon>
                            <mat-select #exportPDF>
                                <mat-option
                                    *ngFor="let option of listOfExportOptions"
                                    [value]="option.value"
                                    (click)="exportToPdf(report.type, option.value)"
                                >
                                    <span>{{ option.title }}</span>
                                </mat-option>
                            </mat-select>
                        </button>
                    </div>
                </div>
            </mat-card>

            <mat-card *ngIf="ten99ReportTypes.includes(report.type)">
                <h2 *ngIf="report.company">{{ report.company.title }}</h2>
                <h4 class="report-title">
                    <span>{{ report.title }}</span>
                </h4>

                <p *ngIf="timeTitleCategoryByReportType[report.type] === 'general'">
                    <span *ngIf="report.settings.time_period">Time Period: </span>
                    <span
                        *ngIf="
                            report.displayed_time_period &&
                            report.displayed_time_period.startDate &&
                            report.displayed_time_period.endDate
                        "
                    >
                        {{ report.displayed_time_period.startDate }} - {{ report.displayed_time_period.endDate }}
                    </span>
                    <span
                        *ngIf="
                            !report.displayed_time_period ||
                            !report.displayed_time_period.startDate ||
                            !report.displayed_time_period.endDate
                        "
                    >
                        Unrestricted
                    </span>
                </p>

                <div class="report-actions" *ngIf="report.type">
                    <div *ngIf="!id" class="item-action-text save">
                        <button mat-button class="mat-default" color="primary" (click)="save()">
                            <mat-icon>save</mat-icon>
                            Save
                        </button>
                    </div>

                    <mat-divider [vertical]="true" *ngIf="!id"></mat-divider>

                    <div *ngIf="!id" class="item-action-text">
                        <button mat-button class="mat-default" color="primary" (click)="toggleEditReportSidebar(true)">
                            <mat-icon>create_outline</mat-icon>
                            Edit
                        </button>
                    </div>

                    <div *ngIf="id" class="item-action-text">
                        <button
                            mat-button
                            class="mat-default"
                            color="primary"
                            (click)="toggleEditReportSidebar(true, id)"
                        >
                            <mat-icon>create_outline</mat-icon>
                            Edit
                        </button>
                    </div>

                    <mat-divider [vertical]="true" *ngIf="id"></mat-divider>

                    <div *ngIf="id" class="item-action-text">
                        <button mat-button class="mat-default" color="primary" [matMenuTriggerFor]="actionsMenu">
                            Actions
                            <mat-icon>expand_more</mat-icon>
                        </button>
                        <mat-menu #actionsMenu="matMenu">
                            <button *ngIf="csvExportOptionEnabledFlag" mat-menu-item (click)="exportToIrisCSV()">
                                Download IRIS CSV
                            </button>
                            <button *ngIf="csvExportOptionEnabledFlag" mat-menu-item (click)="exportToCSV()">
                                Download Data CSV
                            </button>
                            <button mat-menu-item (click)="exportToPdf(report.type)">Print PDF</button>
                        </mat-menu>
                    </div>
                </div>
            </mat-card>

            <mat-card class="preview-body">
                <reports-preview-transaction-summary *ngIf="report.type === 'summary'" [reportData]="reportResult">
                </reports-preview-transaction-summary>

                <reports-preview-average-sales *ngIf="report.type === 'avg-sales'" [reportData]="reportResult">
                </reports-preview-average-sales>

                <reports-preview-source-of-business *ngIf="report.type === 'sob'" [reportData]="reportResult">
                </reports-preview-source-of-business>

                <reports-preview-agent-performance
                    *ngIf="report.type === 'agent-performance'"
                    [reportData]="reportResult"
                >
                </reports-preview-agent-performance>

                <reports-preview-commission-expense *ngIf="report.type === 'commission'" [reportData]="reportResult">
                </reports-preview-commission-expense>

                <reports-preview-detailed
                    *ngIf="report.type === 'detailed'"
                    [reportData]="reportResult"
                    [report]="report"
                >
                </reports-preview-detailed>

                <reports-preview-contact
                    *ngIf="report.type === 'contact'"
                    [reportData]="reportResult"
                    [report]="report"
                >
                </reports-preview-contact>

                <reports-preview-progress *ngIf="report.type === 'tracking'" [reportData]="reportResult">
                </reports-preview-progress>

                <reports-preview-balance-sheet
                    *ngIf="report.type === 'balance_sheet'"
                    [reportData]="reportResult"
                    [report]="report"
                >
                </reports-preview-balance-sheet>

                <reports-preview-profit-loss
                    *ngIf="report.type === 'profit_loss'"
                    [reportData]="reportResult"
                    [report]="report"
                    (toggleSideNavEvent)="sidebarTransactions.toggle(); reportData = $event"
                ></reports-preview-profit-loss>

                <reports-preview-account-transaction
                    *ngIf="report.type === 'account_transactions'"
                    [reportData]="reportResult"
                >
                </reports-preview-account-transaction>

                <reports-preview-journal *ngIf="report.type === 'journal'" [reportData]="reportResult">
                </reports-preview-journal>

                <reports-preview-general-ledger *ngIf="report.type === 'general_ledger'" [reportData]="reportResult">
                </reports-preview-general-ledger>

                <reports-preview-account-type-summary
                    *ngIf="report.type === 'account_type'"
                    [reportData]="reportResult"
                >
                </reports-preview-account-type-summary>

                <reports-preview-trial-balance
                    *ngIf="report.type === 'trial_balance' || report.type === 'summary_trial_balance'"
                    [reportData]="reportResult"
                    [reportType]="report.type"
                >
                </reports-preview-trial-balance>

                <reports-preview-trust-reconciliation
                    *ngIf="report.type === 'trust_reconciliation'"
                    [report]="report"
                    [reportData]="reportResult"
                >
                </reports-preview-trust-reconciliation>

                <reports-preview-product
                    *ngIf="report.type === 'product'"
                    [reportData]="reportResult"
                    [report]="report"
                >
                </reports-preview-product>
                <reports-preview-recurring_invoice
                    *ngIf="report.type === 'recurring_invoice'"
                    [reportData]="reportResult"
                >
                </reports-preview-recurring_invoice>
                <reports-preview-cash-flow
                    *ngIf="report.type === 'cash_flow'"
                    [report]="report"
                    [reportData]="reportResult"
                >
                </reports-preview-cash-flow>
                <reports-preview-cap *ngIf="report.type === 'cap'" [reportData]="reportResult"></reports-preview-cap>

                <section *ngIf="report.type === 'ten99'">
                    <reports-preview-ten99 [reportData]="reportResult"></reports-preview-ten99>
                    <div class="generate-pdf-section">
                        <button mat-raised-button (click)="exportToPdf(report.type)">Generate 1099-NEC for PDF</button>
                    </div>
                </section>

                <reports-preview-ten99-nec-int-misc
                    *ngIf="ten99ReportTypes.includes(report.type)"
                    [report]="report"
                    [reportData]="reportResult.report_payroll_entities"
                    [page]="page"
                    [perPage]="perPage"
                    [totalRecords]="reportResult.total_contacts"
                    [getPaginatedReportData]="getReports"
                ></reports-preview-ten99-nec-int-misc>

                <reports-preview-ap-aging
                    *ngIf="report.type === 'ap_aging'"
                    [reportData]="reportResult"
                    [report]="report"
                ></reports-preview-ap-aging>

                <reports-preview-ar-aging
                    *ngIf="report.type === 'ar_aging'"
                    [reportData]="reportResult"
                    [report]="report"
                ></reports-preview-ar-aging>

                <reports-preview-transaction-user *ngIf="report.type === 'summary_user'" [reportData]="reportResult">
                </reports-preview-transaction-user>

                <section *ngIf="report.type === 'membership'">
                    <reports-preview-membership [reportData]="reportResult"></reports-preview-membership>
                </section>
            </mat-card>
        </div>
    </div>
</mat-sidenav-container>
