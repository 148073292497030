import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {ITransaction} from '@cyberco-nodejs/zipi-typings';
import {MatTableDataSource} from '@angular/material/table';
import {takeUntil} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {IScrollData} from '../../../../models/scroll-data';
import {PAGE_SIZE_OPTIONS} from '../../../../local-typings';
import {TransactionsService} from 'app/services/api/finance/transactions.service';
import {MatDialog} from '@angular/material/dialog';
import {entityRouterListByJournalType} from '../../constants';
import {InvoiceBillModalDialogComponent} from '../../../finance/components/invoice-bill-modal-dialog/invoice-bill-modal-dialog.component';

@Component({
    selector: 'app-report-sidenav',
    templateUrl: 'report-sidenav.component.html',
    styleUrls: ['../../../../../assets/infinite-scroll-table.scss']
})
export class ReportSidenavComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;

    @Input() reportData: {[key: string]: any} = {};
    transactions: ITransaction[] = [];
    public entityRouterListByJournalType = entityRouterListByJournalType;

    displayedColumns: string[] = ['date', 'transaction_type', 'number', 'name', 'amount'];
    dataSource: MatTableDataSource<ITransaction>;
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    scrollData: IScrollData = {
        offset: 0,
        limit: 50,
        total: 0
    };

    debitOrCreditBalanceByLedgerType = {
        asset: 'credit',
        liability: 'debit',
        equity: 'credit',
        income: 'credit',
        expense: 'debit'
    };

    constructor(
        private transactionsService: TransactionsService,
        public dialog: MatDialog
    ) {
        this.dataSource = new MatTableDataSource<ITransaction>([]);
    }

    ngOnInit() {
        if (this.reportData.financial_deals && this.reportData.financial_deals.length) {
            this.reportData.financial_deals = this.reportData.financial_deals
                .map((d: {title: string; id: number}) => d.id)
                .join(',');
        }
        if (this.reportData.tags && this.reportData.tags.length) {
            this.reportData.tags = this.reportData.tags.join(',');
        }
        this.nextBatch();

        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.nextBatch();
            });
        }
    }

    nextBatch() {
        this.transactionsService
            .getTransactionsForReport(
                Object.assign({}, this.reportData, {offset: this.scrollData.offset, limit: this.scrollData.limit})
            )
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.dataSource.data = data.result;
                this.scrollData.total = data._meta.total;
            });
    }

    openDialog(id: number, type: string) {
        if (!['invoice', 'bill'].includes(type)) {
            return;
        }
        const data: {invoiceId?: number; billId?: number} = {};

        if (type === 'invoice') {
            data.invoiceId = id;
        }
        if (type === 'bill') {
            data.billId = id;
        }

        const dialogRef = this.dialog.open(InvoiceBillModalDialogComponent, {
            position: {
                top: '10px',
                right: '10px'
            },
            height: '98%',
            width: '100vw',
            maxWidth: '98vw',
            panelClass: 'full-screen-modal',
            data: data
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {});
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
