import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {UntypedFormBuilder} from '@angular/forms';
import {FormGroupWithFormControls} from '../../../../typings/common';

interface IProfileFilters {
    [key: string]: any;
    statuses: string[];
    roles: [];
    divisions: [];
    groups: [];
}

@Component({
    selector: 'app-profile-filters-dialog',
    templateUrl: './profile-filters-dialog.component.html'
})
export class ProfileFiltersDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    profileFilters: IProfileFilters = {
        statuses: [],
        roles: [],
        divisions: [],
        groups: []
    };

    profileFiltersData: {[key: string]: any[]} = {
        statuses: [],
        roles: [],
        divisions: [],
        groups: []
    };

    formGroup: FormGroupWithFormControls = this.fb.group({
        statuses: [null],
        roles: [null],
        divisions: [null],
        groups: [null]
    }) as FormGroupWithFormControls;

    isInProcess = false;

    constructor(
        public dialogRef: MatDialogRef<ProfileFiltersDialogComponent>,
        private fb: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.profileFilters = {...this.data.profileFilters};
        this.profileFiltersData = this.data.profileFiltersData;
        // normalize filters values
        if (typeof this.profileFilters.statuses === 'string') {
            this.profileFilters.statuses = [this.profileFilters.statuses];
        }
        const getKeyValue =
            <U extends keyof T, T extends object>(key: U) =>
            (obj: T) =>
                obj[key];
        ['roles', 'divisions', 'groups'].map((name) => {
            const userProp = getKeyValue<keyof IProfileFilters, IProfileFilters>(name)(this.profileFilters);
            if (typeof userProp === 'string') {
                this.profileFilters[name] = [Number(userProp)];
            } else if (Array.isArray(userProp)) {
                this.profileFilters[name] = userProp.map((filter) => Number(filter));
            }
        });
        this.formGroup.patchValue(this.profileFilters);
    }

    applyFilters() {
        if (this.isInProcess) {
            return;
        }
        this.isInProcess = true;
        this.dialogRef.close(this.formGroup.getRawValue());
    }

    resetFilters() {
        this.formGroup.reset();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
