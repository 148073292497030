import {Action} from '@ngrx/store';
import {ISettings, ICompanyLocation, ICompanySettings} from '../../../../typings';

export enum SettingsActionType {
    FETCH_PROFILE_SETTINGS = '[SETTINGS] FETCH_PROFILE_SETTINGS',
    FETCH_PROFILE_SETTINGS_MUST = '[SETTINGS] FETCH_PROFILE_SETTINGS_MUST',
    FETCH_PROFILE_SETTINGS_SUCCESS = '[SETTINGS] FETCH_PROFILE_SETTINGS_SUCCESS',
    UPDATE_PROFILE_SETTINGS = '[SETTINGS] UPDATE_PROFILE_SETTINGS',
    UPDATE_PROFILE_SETTINGS_SUCCESS = '[SETTINGS] UPDATE_PROFILE_SETTINGS_SUCCESS',

    FETCH_COMPANY_LOCATIONS = '[SETTINGS] FETCH_COMPANY_LOCATIONS',
    FETCH_COMPANY_LOCATIONS_RESULT = '[SETTINGS] FETCH_COMPANY_LOCATIONS_RESULT',
    CREATE_COMPANY_LOCATION = '[SETTINGS] CREATE_COMPANY_LOCATION',
    CREATE_COMPANY_LOCATION_RESULT = '[SETTINGS] CREATE_COMPANY_LOCATION_RESULT',
    UPDATE_COMPANY_LOCATION = '[SETTINGS] UPDATE_COMPANY_LOCATION',
    UPDATE_COMPANY_LOCATION_RESULT = '[SETTINGS] UPDATE_COMPANY_LOCATION_RESULT',
    DELETE_COMPANY_LOCATION = '[SETTINGS] DELETE_COMPANY_LOCATION',
    DELETE_COMPANY_LOCATION_RESULT = '[SETTINGS] DELETE_COMPANY_LOCATION_RESULT',

    FETCH_COMPANY_SETTINGS = '[SHIPMENTS] FETCH_COMPANY_SETTINGS',
    FETCH_COMPANY_SETTINGS_SUCCESS = '[SHIPMENTS] FETCH_COMPANY_SETTINGS_SUCCESS',
    UPDATE_COMPANY_SETTINGS = '[SHIPMENTS] UPDATE_COMPANY_SETTINGS',
    UPDATE_COMPANY_SETTINGS_SUCCESS = '[SHIPMENTS] UPDATE_COMPANY_SETTINGS_SUCCESS',

    UPDATE_COMPANY_BILLINGS_SUCCESS = '[SETTINGS] UPDATE_COMPANY_BILLINGS_SUCCESS'
}

export class FetchProfileSettings implements Action {
    readonly type: string = SettingsActionType.FETCH_PROFILE_SETTINGS;
    constructor(public payload?: any) {}
}

export class FetchProfileSettingsMust implements Action {
    readonly type: string = SettingsActionType.FETCH_PROFILE_SETTINGS_MUST;
    constructor(public payload?: any) {}
}

export class FetchProfileSettingsSuccess implements Action {
    readonly type: string = SettingsActionType.FETCH_PROFILE_SETTINGS_SUCCESS;

    constructor(public payload?: ISettings) {}
}

export class UpdateProfileSettings implements Action {
    readonly type: string = SettingsActionType.UPDATE_PROFILE_SETTINGS;

    constructor(public payload?: ISettings) {}
}

export class UpdateProfileSettingsSuccess implements Action {
    readonly type: string = SettingsActionType.UPDATE_PROFILE_SETTINGS_SUCCESS;

    constructor(public payload?: boolean) {}
}

export class FetchCompanyLocations implements Action {
    readonly type: string = SettingsActionType.FETCH_COMPANY_LOCATIONS;
    constructor(public payload?: any) {}
}

export class FetchCompanyLocationsResult implements Action {
    readonly type: string = SettingsActionType.FETCH_COMPANY_LOCATIONS_RESULT;

    constructor(public payload?: ICompanyLocation[]) {}
}

export class CreateCompanyLocation implements Action {
    readonly type: string = SettingsActionType.CREATE_COMPANY_LOCATION;

    constructor(public payload?: ICompanyLocation) {}
}

export class CreateCompanyLocationResult implements Action {
    readonly type: string = SettingsActionType.CREATE_COMPANY_LOCATION_RESULT;

    constructor(public payload?: ICompanyLocation) {}
}

export class UpdateCompanyLocation implements Action {
    readonly type: string = SettingsActionType.UPDATE_COMPANY_LOCATION;

    constructor(public payload?: ICompanyLocation) {}
}

export class UpdateCompanyLocationResult implements Action {
    readonly type: string = SettingsActionType.UPDATE_COMPANY_LOCATION_RESULT;

    constructor(public payload?: boolean) {}
}

export class DeleteCompanyLocation implements Action {
    readonly type: string = SettingsActionType.DELETE_COMPANY_LOCATION;

    constructor(public payload?: number) {}
}

export class DeleteCompanyLocationResult implements Action {
    readonly type: string = SettingsActionType.DELETE_COMPANY_LOCATION_RESULT;

    constructor(public payload?: {ok: boolean; id: number}) {}
}

export class FetchCompanySettings implements Action {
    readonly type: string = SettingsActionType.FETCH_COMPANY_SETTINGS;
    constructor(public payload?: any) {}
}

export class FetchCompanySettingsSuccess implements Action {
    readonly type: string = SettingsActionType.FETCH_COMPANY_SETTINGS_SUCCESS;

    constructor(public payload?: ICompanySettings) {}
}

export class UpdateCompanySettings implements Action {
    readonly type: string = SettingsActionType.UPDATE_COMPANY_SETTINGS;

    constructor(public payload?: ICompanySettings) {}
}

export class UpdateCompanySettingsSuccess implements Action {
    readonly type: string = SettingsActionType.UPDATE_COMPANY_SETTINGS_SUCCESS;

    constructor(public payload?: boolean) {}
}

export class UpdateCompanyBillingSettings implements Action {
    readonly type: string = SettingsActionType.UPDATE_COMPANY_BILLINGS_SUCCESS;
    constructor(public payload?: any) {}
}

export type SettingsAction =
    | FetchProfileSettings
    | FetchProfileSettingsMust
    | FetchProfileSettingsSuccess
    | UpdateProfileSettings
    | UpdateProfileSettingsSuccess
    | FetchCompanyLocations
    | FetchCompanyLocationsResult
    | CreateCompanyLocation
    | CreateCompanyLocationResult
    | UpdateCompanyLocation
    | UpdateCompanyLocationResult
    | DeleteCompanyLocation
    | DeleteCompanyLocationResult
    | FetchCompanySettings
    | FetchCompanySettingsSuccess
    | UpdateCompanySettings
    | UpdateCompanySettingsSuccess
    | UpdateCompanyBillingSettings;
