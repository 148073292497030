import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../service.requester';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {ICompany, IProduct} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class ProductsService {
    private url = '/finance/products/';
    public lastLoadedProducts: BehaviorSubject<IProduct[]> = new BehaviorSubject<IProduct[]>([]);

    constructor(public requester: ServiceRequester) {}

    getProducts(): Observable<IProduct[]> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp');
    }

    getProductsByIds(contactProductIds: number[]): Observable<IProduct[]> {
        return this.requester.makeMsCall$(this.url + 'contact', 'GET', 'shipp', contactProductIds);
    }

    createProduct(data: IProduct): Observable<IProduct> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    updateProduct(data: IProduct): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + data.id, 'PUT', 'shipp', data);
    }

    archiveProduct(productId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${productId}/archive`, 'PUT', 'shipp');
    }

    unarchiveProduct(productId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${productId}/unarchive`, 'PUT', 'shipp');
    }

    deleteProduct(data: number): Observable<number> {
        return this.requester.makeMsCall$(this.url + data, 'DELETE', 'shipp');
    }

    getProductsByAvailableCompanies(): Observable<{company: ICompany; product: IProduct}[]> {
        return this.requester.makeMsCall$(`${this.url}available-by-companies`, 'GET', 'shipp');
    }
}
