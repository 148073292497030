import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'phoneMask'
})
export class PhoneMaskPipe implements PipeTransform {
    transform(value: any): string {
        if (typeof value !== 'string' || value === 'Hidden') {
            return value;
        }

        const digits = value.replace(/[^0-9]/g, '');

        if (digits.length < 10) {
            return digits;
        }

        const formattedDigits = digits.split('');
        formattedDigits.splice(-7, 0, '-');
        formattedDigits.splice(-4, 0, '-');

        return formattedDigits.join('');
    }
}
