import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {DealDepositService} from '../../../../services/deal-deposit.service';
import {map, takeUntil, tap} from 'rxjs/operators';
import {IDealDepositRelease, IPaymentMade} from '@cyberco-nodejs/zipi-typings';
import {PAGE_SIZE_OPTIONS, PAYMENT_CREDITS_STATUS_COLOR} from 'app/local-typings';
import {MatDialog} from '@angular/material/dialog';
import {PaymentsMadeService} from '../../../../services/payments-made.service';
import {DealFinancialApiService} from '../../../../../deals/components/deal/deal-financial.api.service';
import {CheckPrintConfirmDialogComponent} from '../../../../../shared/components/check-print-confirm-dialog/check-print-confirm.dialog';
import {GenericFormArray} from 'app/entites/generic.entity';
import {ChipNode} from '../../../../../account-info/compensation/models/chip-node';
import {MatSort, Sort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {IScrollData} from 'app/models/scroll-data';

@Component({
    selector: 'app-release-list',
    templateUrl: './release-list.component.html',
    styleUrls: ['./release-list.component.scss', '../../../../../../../assets/infinite-scroll-table.scss']
})
export class ReleaseListComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;

    dataSource: MatTableDataSource<IDealDepositRelease>;
    displayedColumns = [
        'type',
        'applied_to',
        'release_date',
        'deal_address',
        'contact_name',
        'payment_status',
        'rpm__payment_made_number',
        'release_amount',
        'action'
    ];

    scrollData: IScrollData = {
        offset: 0,
        limit: 50,
        sort_column: 'release_date',
        sort_direction: 'desc',
        // filter: '',
        contact_id: '',
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    // statusTypes = ['all', 'open', 'sent', 'partially_paid', 'paid'];
    // statusFilter: FormControl = new FormControl([]);
    contactCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);

    statusColor = PAYMENT_CREDITS_STATUS_COLOR;

    paymentMade: Partial<IPaymentMade> | undefined;
    blobUrl: string | null = null;

    constructor(
        private dealDepositService: DealDepositService,
        private paymentsMadeService: PaymentsMadeService,
        private dealFinancialApiService: DealFinancialApiService,
        public dialog: MatDialog
    ) {
        this.dataSource = new MatTableDataSource<IDealDepositRelease>([]);
    }

    ngOnInit() {
        this.nextBatch();

        this.contactCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            this.scrollData.contact_id = values && values.length ? values[0].target_id : '';
            this.resetData();
        });

        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.nextBatch();
            });
        }
    }

    nextBatch() {
        this.dealDepositService
            .getDepositReleasesForMe(this.scrollData)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.dataSource.data = data.result;
                this.scrollData.total = data._meta.total;
            });
    }

    resetData() {
        this.paginator!.pageIndex = 0;
        this.scrollData.offset = 0;
        this.scrollData.total = 0;

        this.nextBatch();
    }

    // changeStatusFilter(event: MatOptionSelectionChange) {
    //     if (event.isUserInput) {
    //         const {value, selected} = event.source;
    //         let statusFilter = this.statusFilter.value;
    //
    //         if (value === 'all') {
    //             statusFilter = selected ? this.statusTypes : [];
    //         } else if (value !== 'all') {
    //             if (statusFilter.includes('all') && !selected) {
    //                 statusFilter = statusFilter.filter(f => f !== 'all' && f !== value);
    //             } else {
    //                 statusFilter = this.statusTypes.filter(f =>
    //                     (f !== value && statusFilter.includes(f)) || (f === value && selected));
    //             }
    //         }
    //
    //         // update values
    //         this.statusFilter.setValue(statusFilter);
    //         this.resetData();
    //     }
    // }

    openConfirm(release: IDealDepositRelease) {
        if (release && release.deposit_release_id) {
            this.dealFinancialApiService
                .deleteDealDepositRelease(release.deposit_release_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response) {
                        this.resetData();
                    }
                });
        }
    }

    unlockReleasePayment(release: IDealDepositRelease & {rpm__payment_made_id: number}) {
        this.paymentsMadeService
            .unlockPaymentMade(release.rpm__payment_made_id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                if (response) {
                    this.resetData();
                }
            });
    }

    print() {
        const iframe = document.createElement('iframe');
        iframe.id = 'print-frame';
        iframe.style.display = 'none';
        if (this.blobUrl != null) {
            iframe.src = this.blobUrl;
        }
        iframe.onload = () => {
            setTimeout(() => {
                if (iframe.contentWindow) {
                    iframe.contentWindow.print();
                }
            }, 0);
        };
        if (this.paymentMade && this.paymentMade.check_info) {
            this.printConfirm();
        } else {
            this.resetData();
        }

        document.body.appendChild(iframe);
    }

    initBlobUrl(cb: any) {
        if (this.paymentMade && this.paymentMade.payment_made_id) {
            cb = cb.bind(this);
            this.paymentsMadeService
                .downloadCheckPdf(this.paymentMade.payment_made_id, {
                    check_margins: true,
                    show_background: false
                })
                .pipe(
                    map((res) => new Blob([new Uint8Array(res.data)], {type: 'application/pdf'})),
                    tap((result) => {
                        this.blobUrl = URL.createObjectURL(result);
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe(() => cb());
        }
    }

    printPaymentPdf(release: any) {
        this.paymentMade = {
            payment_made_id: release.rpm__payment_made_id,
            check_info: release.rpm__check_info,
            payment_made_number: release.rpm__payment_made_number
        };
        return this.initBlobUrl(this.print);
    }

    printConfirm() {
        if (!this.paymentMade || !this.paymentMade.payment_made_id || !this.paymentMade.check_info) {
            return null;
        }
        const dialogRef = this.dialog.open(CheckPrintConfirmDialogComponent, {
            minWidth: 320,
            data: {
                payment_made_id: this.paymentMade.payment_made_id,
                check_number: this.paymentMade.check_info.check_number
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.resetData();
            });
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        this.resetData();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
    }
}
