import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {Observable} from 'rxjs';
import {
    IDealDepositRequest,
    IPaymentReceivedMadeRequestObject,
    IPaymentReceived,
    IDealDepositRequestPartialResponseObject,
    IDealDepositRelease
} from '@cyberco-nodejs/zipi-typings';
import {IScrollData} from '../../../models/scroll-data';

@Injectable()
export class DealDepositService {
    private url = '/finance/deal-deposit';

    constructor(protected requester: ServiceRequester) {}

    getDepositReleasesForMe(data: IScrollData): Observable<{result: IDealDepositRelease[]; _meta: {total: number}}> {
        return this.requester.makeMsCall$(this.url + `/my-releases`, 'GET', 'shipp', data);
    }

    getDepositRequestsForMe(data: IScrollData): Observable<{result: IDealDepositRequest[]; _meta: {total: number}}> {
        return this.requester.makeMsCall$(this.url + `/my-requests`, 'GET', 'shipp', data);
    }

    getDepositRequestById(requestId: number): Observable<IDealDepositRequestPartialResponseObject> {
        return this.requester.makeMsCall$(this.url + `/request/${requestId}`, 'GET', 'shipp');
    }

    createDealDepositPayment(
        requestId: number,
        paymentData: IPaymentReceivedMadeRequestObject
    ): Observable<IPaymentReceived> {
        return this.requester.makeMsCall$(this.url + `/${requestId}/payment`, 'POST', 'shipp', paymentData);
    }

    deleteDealDepositRequests(depositRequestId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `/${depositRequestId}/request`, 'DELETE', 'shipp');
    }

    resendDealDepositRequests(depositRequestId: number, resendSettings: {email: string}): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `/${depositRequestId}/resend`, 'POST', 'shipp', resendSettings);
    }
}
