<mat-drawer-container [hasBackdrop]="false" class="h-100">
    <mat-drawer
        [opened]="isSidenavOpened"
        (openedChange)="sidenavOpenChanged($event)"
        [position]="'end'"
        class="wallet-sidenav p-3"
        [mode]="'over'"
    >
        <div class="p-1">
            <div class="d-flex mb-3 align-items-center" style="border-bottom: 1px solid">
                <div style="font-size: 25px; font-weight: bold">Payment Methods</div>
                <span class="sp-spacer"></span>
                <mat-icon
                    (click)="closeSidenav()"
                    style="cursor: pointer; font-size: 30px; width: 30px; height: 30px; margin-right: 5px"
                    >close</mat-icon
                >
            </div>

            <div class="mb-3" *ngIf="isTppCreditCardFeatureFlagEnabled">
                <div class="d-flex mb-2 align-items-center">
                    <div style="font-size: 20px; font-weight: bold">Cards</div>
                    <span class="sp-spacer"></span>
                    <button mat-raised-button [disabled]="!cardHolderGateway" (click)="addCardPaymentMethod()">
                        Add Card
                    </button>
                </div>

                <div
                    *ngIf="
                        cardHolderGateway &&
                        cardHolderGateway.payment_methods &&
                        cardHolderGateway.payment_methods.length > 0
                    "
                >
                    <div *ngFor="let method of cardHolderGateway.payment_methods" class="d-flex align-items-center">
                        <mat-icon class="mr-2 icon-size">credit_card</mat-icon>
                        {{ method.title }}
                        <span class="sp-spacer"></span>

                        <button
                            mat-icon-button
                            (click)="$event.stopPropagation()"
                            [matMenuTriggerFor]="menu"
                            *ngIf="
                                (cardHolderGateway.status !== 'inactive' && !method.plaid_refs) ||
                                !method.plaid_refs.pending_auth_status ||
                                (method.plaid_refs && method.plaid_refs.pending_auth_status && method.id)
                            "
                        >
                            <mat-icon>more_vert</mat-icon>
                        </button>

                        <mat-menu #menu="matMenu">
                            <ng-container
                                *ngIf="
                                    !method.plaid_refs ||
                                    !method.plaid_refs.pending_auth_status ||
                                    method.plaid_refs.pending_auth_status === 'manually_verified'
                                "
                            >
                                <button
                                    type="button"
                                    mat-menu-item
                                    class="mr-2"
                                    (click)="editPayment(method, cardHolderGateway)"
                                >
                                    Edit
                                </button>
                            </ng-container>

                            <button
                                *ngIf="
                                    method.id &&
                                    !['zipi_financial_balance'].includes(method.type) &&
                                    cardHolderGateway.type !== 'zipi_financial_card_merchant'
                                "
                                type="button"
                                mat-menu-item
                                color="accent"
                                (click)="deletePaymentMethod(method.payment_method_id)"
                                [disabled]="
                                    ['authorize_net_merchant', 'system'].includes(cardHolderGateway.type) ||
                                    method.is_default
                                "
                            >
                                Delete
                            </button>
                        </mat-menu>
                    </div>
                </div>
                <div
                    *ngIf="
                        !cardHolderGateway ||
                        !cardHolderGateway.payment_methods ||
                        cardHolderGateway.payment_methods.length === 0
                    "
                >
                    <span style="font-style: italic"> No Stored Cards </span>
                </div>
            </div>

            <div class="mb-3" *ngIf="operatingGateways && operatingGateways.length > 0">
                <div class="d-flex mb-2">
                    <div style="font-size: 20px; font-weight: bold">Bank Accounts</div>
                    <span class="sp-spacer"></span>
                </div>
                <div *ngFor="let gateway of operatingGateways">
                    <div *ngIf="gateway.type === 'zipi_financial_business' || gateway.type === 'zipi_financial_trust'">
                        <div *ngFor="let method of gateway.payment_methods" class="d-flex align-items-center">
                            <mat-icon class="mr-2 icon-size">account_balance</mat-icon>
                            {{ method.title }}
                            <span class="sp-spacer"></span>

                            <span
                                class="mr-3 font-weight-bold"
                                style="font-size: 13px"
                                *ngIf="method?.is_default_receiving || method.is_default_merchant"
                            >
                                <span *ngIf="gateway.type === 'zipi_financial_business'">
                                    <span *ngIf="method.is_default_receiving && !method.is_default_merchant">
                                        Operating
                                    </span>
                                    <span *ngIf="!method.is_default_receiving && method.is_default_merchant">
                                        Merchant
                                    </span>
                                    <span *ngIf="method.is_default_receiving && method.is_default_merchant">
                                        Operating & Merchant
                                    </span>
                                </span>
                            </span>

                            <button
                                mat-icon-button
                                (click)="$event.stopPropagation()"
                                [matMenuTriggerFor]="menu"
                                *ngIf="
                                    (gateway.status !== 'inactive' && !method.plaid_refs) ||
                                    !method.plaid_refs.pending_auth_status ||
                                    (method.plaid_refs && method.plaid_refs.pending_auth_status && method.id)
                                "
                            >
                                <mat-icon>more_vert</mat-icon>
                            </button>

                            <mat-menu #menu="matMenu">
                                <button
                                    type="button"
                                    mat-menu-item
                                    class="mr-2"
                                    *ngIf="['zipi_financial_business', 'zipi_financial_trust'].includes(gateway.type)"
                                    (click)="changeDefaultStatus(method.id!)"
                                    [disabled]="method.is_default_receiving"
                                >
                                    <span *ngIf="['zipi_financial_business'].includes(gateway.type)"
                                        >Set Default Operating Account</span
                                    >
                                    <span *ngIf="['zipi_financial_trust'].includes(gateway.type)"
                                        >Set Default Trust Receiving</span
                                    >
                                </button>
                                <button
                                    type="button"
                                    mat-menu-item
                                    class="mr-2"
                                    *ngIf="
                                        ['zipi_financial_business'].includes(gateway.type) &&
                                        gateway.driver_type === 'payload'
                                    "
                                    (click)="changeDefaultMerchant(method.payment_method_id)"
                                    [disabled]="method.is_default_merchant"
                                >
                                    <span>Set Default Merchant Account</span>
                                </button>
                                <ng-container
                                    *ngIf="
                                        !method.plaid_refs ||
                                        !method.plaid_refs.pending_auth_status ||
                                        method.plaid_refs.pending_auth_status === 'manually_verified'
                                    "
                                >
                                    <button
                                        type="button"
                                        mat-menu-item
                                        class="mr-2"
                                        (click)="editPayment(method, gateway)"
                                    >
                                        Edit
                                    </button>
                                </ng-container>

                                <button
                                    *ngIf="
                                        method.id &&
                                        !['zipi_financial_balance'].includes(method.type) &&
                                        gateway.type !== 'zipi_financial_card_merchant'
                                    "
                                    type="button"
                                    mat-menu-item
                                    color="accent"
                                    (click)="deletePaymentMethod(method.payment_method_id)"
                                    [disabled]="
                                        ['authorize_net_merchant', 'system'].includes(gateway.type) || method.is_default
                                    "
                                >
                                    Delete
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3" *ngIf="trustGateways && trustGateways.length > 0">
                <div class="d-flex mb-2">
                    <div style="font-size: 20px; font-weight: bold">Trust Accounts</div>
                    <span class="sp-spacer"></span>
                </div>
                <div *ngFor="let gateway of trustGateways">
                    <div *ngIf="gateway.type === 'zipi_financial_business' || gateway.type === 'zipi_financial_trust'">
                        <div *ngFor="let method of gateway.payment_methods" class="d-flex align-items-center">
                            <mat-icon class="mr-2 icon-size">account_balance</mat-icon>
                            {{ method.title }}
                            <span class="sp-spacer"></span>
                            <span
                                class="mr-3 font-weight-bold"
                                style="font-size: 13px"
                                *ngIf="method?.is_default_receiving"
                            >
                                Default Trust
                            </span>

                            <button
                                mat-icon-button
                                (click)="$event.stopPropagation()"
                                [matMenuTriggerFor]="menu"
                                *ngIf="
                                    (gateway.status !== 'inactive' && !method.plaid_refs) ||
                                    !method.plaid_refs.pending_auth_status ||
                                    (method.plaid_refs && method.plaid_refs.pending_auth_status && method.id)
                                "
                            >
                                <mat-icon>more_vert</mat-icon>
                            </button>

                            <mat-menu #menu="matMenu">
                                <button
                                    type="button"
                                    mat-menu-item
                                    class="mr-2"
                                    *ngIf="['zipi_financial_business', 'zipi_financial_trust'].includes(gateway.type)"
                                    (click)="changeDefaultStatus(method.id!)"
                                    [disabled]="method.is_default_receiving"
                                >
                                    <span *ngIf="['zipi_financial_business'].includes(gateway.type)"
                                        >Set Default Operating Account</span
                                    >
                                    <span *ngIf="['zipi_financial_trust'].includes(gateway.type)"
                                        >Set Default Trust Receiving</span
                                    >
                                </button>
                                <ng-container
                                    *ngIf="
                                        !method.plaid_refs ||
                                        !method.plaid_refs.pending_auth_status ||
                                        method.plaid_refs.pending_auth_status === 'manually_verified'
                                    "
                                >
                                    <button
                                        type="button"
                                        mat-menu-item
                                        class="mr-2"
                                        (click)="editPayment(method, gateway)"
                                    >
                                        Edit
                                    </button>
                                </ng-container>

                                <button
                                    *ngIf="
                                        method.id &&
                                        !['zipi_financial_balance'].includes(method.type) &&
                                        gateway.type !== 'zipi_financial_card_merchant'
                                    "
                                    type="button"
                                    mat-menu-item
                                    color="accent"
                                    (click)="deletePaymentMethod(method.payment_method_id)"
                                    [disabled]="
                                        ['authorize_net_merchant', 'system'].includes(gateway.type) || method.is_default
                                    "
                                >
                                    Delete
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-drawer>
    <mat-drawer-content>
        <div class="pt-3" style="margin-bottom: 120px">
            <div class="d-flex px-4">
                <div style="width: 230px" class="mr-4">
                    <h2>Money Transfers</h2>
                </div>
                <div class="w-100">
                    <div
                        class="d-flex"
                        *ngIf="zipiFinancialAddonInstance && zipiFinancialAddonInstance.addon_instance_id"
                    >
                        <div class="d-flex w-100 align-items-baseline">
                            <mat-form-field
                                class="w-30"
                                *ngIf="
                                    zipiFinancialAddonInstance &&
                                    zipiFinancialAddonInstance.settings &&
                                    zipiFinancialAddonInstance.settings.notifications_email
                                "
                            >
                                <input
                                    matInput
                                    required
                                    type="text"
                                    [value]="zipiFinancialAddonInstance.settings.notifications_email"
                                    [disabled]="true"
                                />
                                <mat-placeholder>Email for Notifications</mat-placeholder>
                            </mat-form-field>
                            <span class="sp-spacer"></span>
                        </div>
                    </div>
                    <div
                        class="d-flex align-items-center mb-3"
                        *ngIf="zipiFinancialAddonInstance && zipiFinancialAddonInstance.addon_instance_id"
                    >
                        <div class="w-100">
                            <div class="d-flex align-items-center w-100">
                                <!--                                <div class="d-flex flex-column mr-2" *ngIf="isOperatingMethodsExist">-->
                                <!--                                    <span class="text-muted" style="font-size:10px; line-height:10px;">Default Operating Account</span>-->
                                <!--                                    <button mat-raised-button-->
                                <!--                                            [matMenuTriggerFor]="menu"-->
                                <!--                                            matTooltip="{{defaultOperatingMethod?.title}}"-->
                                <!--                                            matTooltipDisabled="!defaultOperatingMethod"-->
                                <!--                                            class="default-account-button">-->
                                <!--                                        <span style="text-overflow: ellipsis; overflow: hidden; width: 165px; display: inline-block;">-->
                                <!--                                            {{defaultOperatingMethod?.title || 'Set Default'}}-->
                                <!--                                        </span>-->
                                <!--                                        <mat-icon>arrow_drop_down</mat-icon>-->
                                <!--                                    </button>-->

                                <!--                                    <mat-menu #menu="matMenu">-->
                                <!--                                        <button mat-menu-item [matMenuTriggerFor]="methods" [matMenuTriggerData]="{gateway: gateway}" *ngFor="let gateway of operatingGateways">-->
                                <!--                                            <div class="d-flex align-items-center">-->
                                <!--                                                <span>{{gateway.title}}</span>-->
                                <!--                                            </div>-->
                                <!--                                        </button>-->
                                <!--                                    </mat-menu>-->

                                <!--                                    <mat-menu #methods="matMenu">-->
                                <!--                                        <ng-template matMenuContent let-gateway="gateway">-->
                                <!--                                            <ng-container  *ngFor="let method of gateway.payment_methods">-->
                                <!--                                                <button mat-menu-item-->
                                <!--                                                        [ngClass]="{'selected': defaultTrustMethod?.payment_method_id === method.payment_method_id}"-->
                                <!--                                                        [disabled]="gateway.status === 'inactive' || method.status !== 'active' || !method.related__ledger_account_fk_id || defaultOperatingMethod?.payment_method_id === method.payment_method_id"-->
                                <!--                                                        (click)="changeDefaultStatus(method.payment_method_id)">-->
                                <!--                                                    {{method.title}}-->
                                <!--                                                </button>-->
                                <!--                                            </ng-container>-->
                                <!--                                        </ng-template>-->
                                <!--                                    </mat-menu>-->
                                <!--                                </div>-->

                                <!--                                <div class="d-flex flex-column mr-2" *ngIf="isTrustMethodsExist">-->
                                <!--                                    <span class="text-muted" style="font-size:10px; line-height: 10px;">Default Trust Receiving</span>-->

                                <!--                                    <button mat-raised-button-->
                                <!--                                            [matMenuTriggerFor]="menu"-->
                                <!--                                            matTooltip="{{defaultTrustMethod?.title}}"-->
                                <!--                                            matTooltipDisabled="!defaultTrustMethod"-->
                                <!--                                            class="default-account-button">-->
                                <!--                                        <span style="text-overflow: ellipsis; overflow: hidden; width: 165px; display: inline-block;">-->
                                <!--                                            {{defaultTrustMethod?.title || 'Set Default'}}-->
                                <!--                                        </span>-->
                                <!--                                        <mat-icon>arrow_drop_down</mat-icon>-->
                                <!--                                    </button>-->

                                <!--                                    <mat-menu #menu="matMenu">-->
                                <!--                                        <button mat-menu-item [matMenuTriggerFor]="methods" [matMenuTriggerData]="{gateway: gateway}" *ngFor="let gateway of trustGateways">-->
                                <!--                                            <div class="d-flex align-items-center">-->
                                <!--                                                <span>{{gateway.title}}</span>-->
                                <!--                                            </div>-->
                                <!--                                        </button>-->
                                <!--                                    </mat-menu>-->

                                <!--                                    <mat-menu #methods="matMenu">-->
                                <!--                                        <ng-template matMenuContent let-gateway="gateway">-->
                                <!--                                            <ng-container  *ngFor="let method of gateway.payment_methods">-->
                                <!--                                                <button mat-menu-item-->
                                <!--                                                        [disabled]="gateway.status === 'inactive' || method.status !== 'active' || !method.related__ledger_account_fk_id || defaultTrustMethod?.payment_method_id === method.payment_method_id"-->
                                <!--                                                        (click)="changeDefaultStatus(method.payment_method_id)">-->
                                <!--                                                    {{method.title}}-->
                                <!--                                                </button>-->
                                <!--                                            </ng-container>-->
                                <!--                                        </ng-template>-->
                                <!--                                    </mat-menu>-->
                                <!--                                </div>-->

                                <span class="sp-spacer"></span>

                                <button
                                    mat-button
                                    color="primary"
                                    style="margin-right: 8px"
                                    [matMenuTriggerFor]="menu"
                                    *ngIf="
                                        zipiFinancialAddonInstance &&
                                        zipiFinancialAddonInstance.settings &&
                                        zipiFinancialAddonInstance.settings.external_customer_ref &&
                                        zipiFinancialGateways &&
                                        (operatingGateways.length <
                                            zipiFinancialAddonInstance.settings.max_operating_gateways_number ||
                                            (operatingGateways.length > 0 &&
                                                trustGateways.length <
                                                    zipiFinancialAddonInstance.settings.max_trust_gateways_number) ||
                                            (operatingGateways.length > 0 &&
                                                cardsGateways.length <
                                                    zipiFinancialAddonInstance.settings.max_card_gateways_count)) &&
                                        (isMuhneeHostedIntegrationFeatureFlagEnabled || isPayloadCoFeatureFlagEnabled)
                                    "
                                >
                                    Add Account
                                </button>

                                <mat-menu #menu="matMenu">
                                    <ng-container *ngIf="operatingGateways">
                                        <button
                                            mat-menu-item
                                            class="mb-1"
                                            rbac
                                            [anyRule]="[{marketplace__manage_zipipay_acquire_credit_card: true}]"
                                            [allowStyle]="{display: 'inline-block'}"
                                            [denyStyle]="{display: 'none'}"
                                            *ngIf="
                                                operatingGateways.length > 0 &&
                                                isTppCreditCardFeatureFlagEnabled &&
                                                cardsGateways.length <
                                                    zipiFinancialAddonInstance.settings.max_card_gateways_count
                                            "
                                            (click)="addNewGateway('zipi_financial_card_merchant', 'tpp')"
                                        >
                                            Merchant Account
                                        </button>
                                        <button
                                            mat-menu-item
                                            class="mb-1"
                                            *ngIf="
                                                operatingGateways.length <
                                                    zipiFinancialAddonInstance.settings.max_operating_gateways_number &&
                                                isMuhneeHostedIntegrationFeatureFlagEnabled
                                            "
                                            (click)="addNewGateway('zipi_financial_business', 'muhnee-hosted')"
                                        >
                                            Operating {{ isPayloadCoFeatureFlagEnabled ? '(ZilMoney)' : '' }}
                                        </button>
                                        <button
                                            mat-menu-item
                                            class="mb-1"
                                            *ngIf="
                                                operatingGateways.length > 0 &&
                                                trustGateways.length <
                                                    zipiFinancialAddonInstance.settings.max_trust_gateways_number &&
                                                isMuhneeHostedIntegrationFeatureFlagEnabled
                                            "
                                            (click)="addNewGateway('zipi_financial_trust', 'muhnee-hosted')"
                                        >
                                            Trust {{ isPayloadCoFeatureFlagEnabled ? '(ZilMoney)' : '' }}
                                        </button>
                                        <button
                                            mat-menu-item
                                            class="mb-1"
                                            *ngIf="
                                                operatingGateways.length <
                                                    zipiFinancialAddonInstance.settings.max_operating_gateways_number &&
                                                isPayloadCoFeatureFlagEnabled
                                            "
                                            (click)="addNewGateway('zipi_financial_business', 'payload')"
                                        >
                                            Operating
                                            {{ isMuhneeHostedIntegrationFeatureFlagEnabled ? '(Payload.co)' : '' }}
                                        </button>
                                        <button
                                            mat-menu-item
                                            class="mb-1"
                                            *ngIf="
                                                operatingGateways.length > 0 &&
                                                trustGateways.length <
                                                    zipiFinancialAddonInstance.settings.max_trust_gateways_number &&
                                                isPayloadCoFeatureFlagEnabled
                                            "
                                            (click)="addNewGateway('zipi_financial_trust', 'payload')"
                                        >
                                            Trust
                                            {{ isMuhneeHostedIntegrationFeatureFlagEnabled ? '(Payload.co)' : '' }}
                                        </button>
                                    </ng-container>
                                </mat-menu>

                                <button
                                    mat-stroked-button
                                    color="primary"
                                    (click)="openSidenav()"
                                    *ngIf="
                                        zipiFinancialAddonInstance &&
                                        zipiFinancialAddonInstance.settings &&
                                        zipiFinancialAddonInstance.settings.external_customer_ref &&
                                        hasExistingGateway
                                    "
                                >
                                    Payment Methods
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <mat-tab-group
                [(selectedIndex)]="selectedIndex"
                *ngIf="zipiFinancialAddonInstance"
                (selectedTabChange)="changingTabSubject.next('change')"
            >
                <ng-container *ngFor="let currentGateway of zipiFinancialGateways; let i = index">
                    <mat-tab *ngIf="currentGateway.type !== 'zipi_financial_card_customer'">
                        <ng-template mat-tab-label>
                            <div class="col" style="margin-left: 20px">
                                {{ currentGateway.payment_gateway_id ? currentGateway.title : 'New Gateway' }}
                            </div>
                            <button
                                mat-icon-button
                                style="width: 20px"
                                *ngIf="!currentGateway.payment_gateway_id && filteredGateways.length > 0"
                                (click)="removeEmptyTab(i)"
                            >
                                <mat-icon style="font-size: 12px">close</mat-icon>
                            </button>
                            <button
                                mat-icon-button
                                style="width: 20px"
                                *ngIf="currentGateway && currentGateway.payment_gateway_id"
                                (click)="editGateway(currentGateway)"
                            >
                                <mat-icon style="font-size: 16px">edit</mat-icon>
                            </button>
                        </ng-template>
                        <!--            <mat-card class="mt-3" style="padding-bottom: 90px">-->
                        <div class="bg-white" [ngStyle]="{'min-height': 'calc(100vh - 220px)'}">
                            <app-zipi-fin-gateway-container
                                [currentGateway]="currentGateway"
                                [showFinalPopup]="showFinalPopup"
                                [operatingGateways]="operatingGateways"
                                [trustGateways]="trustGateways"
                                [isTabChanged]="changingTabSubject"
                                (reload)="reload($event)"
                                (showFinPopupEvent)="showFinPopup($event)"
                                (cleanFinalPopupInfo)="cleanFinalPopupInfo()"
                                (setupStarted)="isUnsavedFormExist = $event"
                            >
                            </app-zipi-fin-gateway-container>
                        </div>

                        <!--            </mat-card>-->
                    </mat-tab>
                </ng-container>
            </mat-tab-group>

            <mat-card
                style="margin: 40px; padding: 64px"
                *ngIf="
                    zipiFinancialAddonInstance &&
                    (!zipiFinancialAddonInstance.settings || !zipiFinancialAddonInstance.settings.external_customer_ref)
                "
            >
                <h2>Create a Money Transfers (EFT) account</h2>

                <div style="padding-right: 100px; margin-bottom: 32px">
                    Create a Money Transfers (EFT) account to start making instant transfers to your agent and vendors.
                    Financial Transfers are handled by third-party partners. SkySlope does not manage or store banking
                    information.
                </div>

                <button mat-raised-button color="primary" (click)="connectCustomer()">Get Started</button>
            </mat-card>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
