import {Component, Input, OnInit} from '@angular/core';
import {ReportsService} from '../../../../services/reports.service';
import {IReportsPreviewResultData} from '../../../../store/reports.reducer';
import {IReport} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'reports-preview-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.scss']
})
export class ProgressReportPreviewComponent implements OnInit {
    @Input()
    /**
     * Set report data
     */
    set reportData(data: IReportsPreviewResultData[] | any) {
        this._reportData = data;
    }

    /**
     * Get report data
     */
    get reportData() {
        return this._reportData;
    }

    public report: IReport | null = null;
    public results: IReportsPreviewResultData[] | any = [];
    public _reportData: IReportsPreviewResultData[] | any = [];

    constructor(protected reportsService: ReportsService) {}

    ngOnInit() {}
}
