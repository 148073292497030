<div>
    <mat-card *ngIf="reportData.data && reportData.data.length">
        <table class="report-table w-100">
            <thead>
                <tr>
                    <th class="sticky">Account type</th>
                    <th class="item-value sticky">Debit</th>
                    <th class="item-value sticky">Credit</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of reportData.data">
                    <tr>
                        <td colspan="3" class="group">{{ item.group }}</td>
                    </tr>
                    <tr *ngFor="let item of item.list">
                        <td>{{ item.label }}</td>
                        <td class="item-value">{{ item.debit | reportFormatted: 'financial' }}</td>
                        <td class="item-value">{{ item.credit | reportFormatted: 'financial' }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>

        <p class="m-2">*Amount is displayed in your base currency <span class="badge-secondary">USD</span></p>
    </mat-card>

    <mat-card class="preview-body" *ngIf="!reportData.data || !reportData.data.length">
        <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
    </mat-card>
</div>
