import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {pipe, Subject} from 'rxjs';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {NotificationsService} from 'angular2-notifications';
import {selectCompanySettings, selectLedgerAccountsSorted} from 'app/store/root.selectors';
import {FetchLedgerAccounts, FetchLedgerAccountsMust} from '../../../store/finance.actions';
import {IFinanceState} from '../../../store/finance.reducer';
import {MatSort} from '@angular/material/sort';
import {LedgerAccountService} from 'app/services/api/finance/ledger-accounts.service';
import {FetchCompanySettings} from '../../../../account-info/store/settings.actions';
import * as moment from 'moment';
import {ILedgerAccount, ITransaction} from '@cyberco-nodejs/zipi-typings';
import {FormGroupArray} from '../../../../../typings/common';
import {Router} from '@angular/router';

@Component({
    selector: 'app-edit-opening-balances',
    templateUrl: 'edit-opening-balances.component.html',
    styleUrls: ['edit-opening-balances.component.scss']
})
export class EditOpeningBalancesComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('accountTransactions') accountTransactions: any;
    @ViewChild(MatSort) sort: MatSort = new MatSort();

    formGroup: UntypedFormGroup | undefined;
    itemsArray: FormGroupArray = this.fb.array([]) as FormGroupArray;

    openingBalanceDate: number | undefined;
    ledgerAccounts: ILedgerAccount[] = [];

    ledgerAccountsData: {
        items: any[];
        debit_total: number;
        credit_total: number;
        debit_adjustments: number;
        credit_adjustments: number;
        debit_total_amount: number;
        credit_total_amount: number;
    } = {
        items: [],
        debit_total: 0,
        credit_total: 0,
        debit_adjustments: 0,
        credit_adjustments: 0,
        debit_total_amount: 0,
        credit_total_amount: 0
    };

    openingBalanceAdjustmentsAccount: ILedgerAccount | null = null;

    isButtonDisabled: boolean;
    isLockedItemExist: boolean;

    constructor(
        private ntfs: NotificationsService,
        private fb: UntypedFormBuilder,
        private store: Store<IFinanceState>,
        private ledgerAccountService: LedgerAccountService,
        protected router: Router
    ) {
        this.isButtonDisabled = false;
        this.isLockedItemExist = false;
    }

    ngOnInit() {
        this.store.dispatch(new FetchCompanySettings());
        this.store.dispatch(new FetchLedgerAccounts());

        this.store
            .select(selectCompanySettings)
            .pipe(
                filter((cs) => cs !== null),
                takeUntil(this.unsubscribe)
            )
            .subscribe((companySettings) => {
                if (companySettings) {
                    this.openingBalanceDate = companySettings.opening_balance_date;
                    if (this.formGroup) {
                        this.formGroup.controls.date.setValue(Number(this.openingBalanceDate));
                    }
                }
            });

        this.store.pipe(select(selectLedgerAccountsSorted), takeUntil(this.unsubscribe)).subscribe((las) => {
            this.ledgerAccounts = las;

            this.initFormsArray();
        });
    }

    initFormsArray() {
        this.formGroup = this.fb.group({
            date: [
                this.openingBalanceDate
                    ? Number(this.openingBalanceDate)
                    : Number(moment(new Date()).format('YYYYMMDD')),
                [Validators.required]
            ],
            items: this.itemsArray
        });

        this.ledgerAccountService
            .getOpeningBalanceLedgerAccountsTransactions()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.itemsArray.clear();
                this.ledgerAccountsData = data;

                const openingBalanceOffset = this.ledgerAccounts.find(
                    (la) => la.system_key === 'opening_balance_offset'
                );
                if (!openingBalanceOffset) {
                    this.itemsArray.push(
                        this.createItem({
                            ledger_account_id: null,
                            zero_balance: [false, []],
                            credit: 0,
                            debit: 0,
                            name: 'Opening Balance Offset',
                            status: 'active',
                            code: '',
                            system_key: 'opening_balance_offset',
                            is_locked: [false, []]
                        })
                    );
                }

                this.ledgerAccounts.forEach((item) => {
                    const checkItem = this.itemsArray.controls.findIndex(
                        (x) => x.value.ledger_account_id === item.ledger_account_id
                    );

                    if (item.system_key !== 'opening_balance_adjustments' && checkItem === -1) {
                        this.itemsArray.push(this.createItem(item));
                    } else if (
                        item.system_key === 'opening_balance_adjustments' &&
                        !this.openingBalanceAdjustmentsAccount
                    ) {
                        this.ledgerAccountsData.items.forEach((dataItem) => {
                            if (dataItem.ledger_account_id === item.ledger_account_id) {
                                this.openingBalanceAdjustmentsAccount = dataItem;
                            }
                        });
                        if (!this.openingBalanceAdjustmentsAccount) {
                            this.openingBalanceAdjustmentsAccount = item;
                        }
                    }
                });

                this.calculateTotals();
            });
    }

    private calculateTotals() {
        // reset amounts
        this.ledgerAccountsData.debit_total = 0;
        this.ledgerAccountsData.credit_total = 0;
        this.ledgerAccountsData.debit_adjustments = 0;
        this.ledgerAccountsData.credit_adjustments = 0;
        this.ledgerAccountsData.debit_total_amount = 0;
        this.ledgerAccountsData.credit_total_amount = 0;
        // add amounts from form
        if (typeof this.formGroup !== 'undefined') {
            this.formGroup.getRawValue().items.forEach((item: any) => {
                const debit = item.debit * 1;
                const credit = item.credit * 1;
                if (debit) {
                    this.ledgerAccountsData.debit_total += debit;
                    this.ledgerAccountsData.debit_total_amount += debit;
                } else if (credit) {
                    this.ledgerAccountsData.credit_total += credit;
                    this.ledgerAccountsData.credit_total_amount += credit;
                }
            });
        }
        // calculate adjustments
        if (this.ledgerAccountsData.debit_total > this.ledgerAccountsData.credit_total) {
            this.ledgerAccountsData.credit_adjustments =
                this.ledgerAccountsData.debit_total - this.ledgerAccountsData.credit_total;
        } else if (this.ledgerAccountsData.debit_total < this.ledgerAccountsData.credit_total) {
            this.ledgerAccountsData.debit_adjustments =
                this.ledgerAccountsData.credit_total - this.ledgerAccountsData.debit_total;
        }
        // calculate totals
        this.ledgerAccountsData.debit_total_amount += this.ledgerAccountsData.debit_adjustments;
        this.ledgerAccountsData.credit_total_amount += this.ledgerAccountsData.credit_adjustments;
    }

    private createItem(item?: any) {
        let la_data = null;

        if (item && item.ledger_account_id) {
            la_data =
                this.ledgerAccountsData.items.find(
                    (dataItem) => item.ledger_account_id === dataItem.ledger_account_id
                ) || null;
        }
        if (la_data && la_data.is_locked) {
            this.isLockedItemExist = true;
        }

        const group = this.fb.group({
            name: [item.name, []],
            status: [item.status, []],
            code: [item.code, []],
            ledger_account_id: [item.ledger_account_id, []],
            zero_balance: [false, []],
            debit: [
                {value: la_data && la_data.debit ? la_data.debit : '', disabled: la_data ? la_data.is_locked : false},
                [Validators.min(0.01)]
            ],
            credit: [
                {value: la_data && la_data.credit ? la_data.credit : '', disabled: la_data ? la_data.is_locked : false},
                [Validators.min(0.01)]
            ],
            system_key: [item.system_key, []],
            is_locked: [la_data ? la_data.is_locked : false, []]
        });

        const handleDebitValueChanges = pipe(
            takeUntil(this.unsubscribe),
            tap(() => {
                group.get('credit')!.patchValue('', {emitEvent: false});
                this.calculateTotals();
            })
        );

        const handleCreditValueChanges = pipe(
            takeUntil(this.unsubscribe),
            tap(() => {
                group.get('debit')!.patchValue('', {emitEvent: false});
                this.calculateTotals();
            })
        );

        group.get('debit')!.valueChanges.pipe(handleDebitValueChanges, takeUntil(this.unsubscribe)).subscribe();
        group.get('credit')!.valueChanges.pipe(handleCreditValueChanges, takeUntil(this.unsubscribe)).subscribe();
        if (!!(la_data && la_data.zero_balance)) {
            this.changeZeroBalance(true, group);
        }
        return group;
    }

    changeZeroBalance(isChecked: boolean, itemGroup: any) {
        if (isChecked) {
            itemGroup.controls.zero_balance.setValue(true);
            itemGroup.controls.debit.setValue('');
            itemGroup.controls.credit.setValue('');
            itemGroup.controls.debit.disable();
            itemGroup.controls.credit.disable();
        } else {
            itemGroup.controls.zero_balance.setValue(false);
            itemGroup.controls.debit.setValue('');
            itemGroup.controls.credit.setValue('');
            itemGroup.controls.debit.enable();
            itemGroup.controls.credit.enable();
        }
    }

    saveOpeningBalances() {
        if (!this.formGroup) {
            return;
        }
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this.ntfs.warn('Opening Balances is not valid');
            return false;
        }

        this.isButtonDisabled = true;
        const data = this.formGroup.getRawValue();
        if (this.openingBalanceAdjustmentsAccount) {
            data.items.push({
                name: this.openingBalanceAdjustmentsAccount.name,
                ledger_account_id: this.openingBalanceAdjustmentsAccount.ledger_account_id,
                debit: this.ledgerAccountsData.debit_adjustments,
                credit: this.ledgerAccountsData.credit_adjustments
            });
        } else {
            data.items.push({
                name: 'Opening Balance Adjustments',
                ledger_account_id: null,
                debit: this.ledgerAccountsData.debit_adjustments,
                credit: this.ledgerAccountsData.credit_adjustments,
                system_key: 'opening_balance_adjustments'
            });
        }

        this.ledgerAccountService
            .createOpeningBalance(data)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.ntfs.info(`Opening Balance created`);
                    this.router.navigate(['/company/finance/openingbalances']);
                    this.store.dispatch(new FetchLedgerAccountsMust());
                    this.isButtonDisabled = false;
                }
            });

        // this.store.dispatch(new CreateOpeningBalance(data));
    }

    updateOpeningBalances() {
        if (!this.formGroup || !this.openingBalanceAdjustmentsAccount) {
            return;
        }
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this.ntfs.warn('Opening Balances is not valid');
            return false;
        }

        this.isButtonDisabled = true;
        const data = this.formGroup.getRawValue();
        data.items.push({
            name: this.openingBalanceAdjustmentsAccount.name,
            ledger_account_id:
                this.openingBalanceAdjustmentsAccount && this.openingBalanceAdjustmentsAccount.ledger_account_id,
            debit: this.ledgerAccountsData.debit_adjustments,
            credit: this.ledgerAccountsData.credit_adjustments,
            system_key: 'opening_balance_adjustments'
        });

        this.ledgerAccountService
            .updateOpeningBalance(data)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.ntfs.info(`Opening Balance updated`);
                    this.router.navigate(['/company/finance/openingbalances']);
                    this.isButtonDisabled = false;
                }
            });

        // this.store.dispatch(new UpdateOpeningBalance(data));
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
