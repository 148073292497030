import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
// import {MaterialModule} from '@angular/material';
import {UserNotificationService} from './user-notification.service';
import {UserNotificationIconComponent} from './user-notification-icon.component';
import {UserNotificationContainerComponent} from './user-notification-container.component';
import {UserNotificationItemViewComponent} from './user-notification-item-view.component';
import {UserNotificationItemDismissComponent} from './user-notification-item-dismiss.component';
import {UserNotificationItemBaseComponent} from './user-notification-item-base.component';
import {UserNotificationItemAssociationComponent} from './user-notification-item-association.component';
import {SharedModule} from '../shared/shared.module';
import {UserNotificationItemImportJsonComponent} from './user-notification-item-import-json.component';
import {UserNotificationItemImportCsvComponent} from './user-notification-item-import-csv.component';
import {SystemNotificationSource} from './system-notification.source';

@NgModule({
    imports: [
        // MaterialModule,
        BrowserModule,
        SharedModule
    ],
    exports: [
        UserNotificationIconComponent,
        UserNotificationContainerComponent,
        UserNotificationItemViewComponent,
        UserNotificationItemDismissComponent,
        UserNotificationItemImportJsonComponent,
        UserNotificationItemImportCsvComponent
    ],
    declarations: [
        UserNotificationItemBaseComponent,
        UserNotificationIconComponent,
        UserNotificationContainerComponent,
        UserNotificationItemAssociationComponent,
        UserNotificationItemViewComponent,
        UserNotificationItemDismissComponent,
        UserNotificationItemImportJsonComponent,
        UserNotificationItemImportCsvComponent
    ],
    providers: [UserNotificationService, SystemNotificationSource]
})
export class UserNotificationModule {}
