import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {GenericFormGroup} from '../../../../../../entites/generic.entity';
import {Deal} from '../../../../../../models/deal';
import {Subject} from 'rxjs';
import {IFinancialTransfer} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {FormGroup} from '@angular/forms';
import {FinancialTransferEntity} from '../../../../../account-info/compensation/models/financial-transfer.entity';

@Component({
    selector: 'app-deal-payouts-summary',
    template: `
        <div class="m-3">
            <div class="d-flex" style="font-size: 16px; margin: 12px 5px 18px; font-weight: bold">
                <div>{{ dealTypesMap[deal!.client_type!] }}</div>
                <div *ngIf="deal!.dual_deal_id">&nbsp;(dual agency)</div>
                <span class="sp-spacer"></span>
                <div>{{ deal!.company_calculation.commission.amount | currency }}</div>
            </div>
            <div *ngIf="companyTransfersArray.length > 0" style="margin-bottom: 15px">
                <div style="font-size: 14px; margin: 5px;">Company Financials</div>
                <div *ngFor="let transfer of companyTransfersArray; let i = index">
                    <ng-container>
                        <app-deal-payouts-card
                            [transferMoneyInGroup]="transfer"
                            [dealFormGroup]="dealFormGroup"
                            [cardType]="cardType"
                            [brokerReleasesAmount]="brokerReleasesAmount"
                            [isInfoOnly]="false"
                        ></app-deal-payouts-card>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="otherTransfersArray.length > 0">
                <div style="font-size: 14px; margin: 5px 5px 5px 5px;">Other Financials</div>
                <div *ngFor="let transfer of otherTransfersArray; let i = index">
                    <ng-container>
                        <app-deal-payouts-card
                            [transferMoneyInGroup]="transfer"
                            [dealFormGroup]="dealFormGroup"
                            [cardType]="cardType"
                            [isInfoOnly]="false"
                        ></app-deal-payouts-card>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="companyTransfersArray.length === 0 && otherTransfersArray.length === 0" class="m-2">
                <span style="color: gray">No Payouts</span>
            </div>
        </div>

        <div class="mt-3" *ngIf="deal!.dual_deal_id && deal!.dual_deal">
            <div class="d-flex" style="font-size: 16px; margin: 12px 5px 6px; font-weight: bold">
                {{ dealTypesMap[deal!.dual_deal!.client_type!] }}
                <span class="sp-spacer"></span>
                <div>{{ deal!.dual_deal!.company_calculation?.commission?.amount | currency }}</div>
            </div>
            <div *ngIf="dualDealCompanyTransfersArray.length > 0" style="margin-bottom: 15px">
                <div style="font-size: 14px; margin: 5px;">Company Financials</div>
                <div *ngFor="let transfer of dualDealCompanyTransfersArray; let i = index">
                    <ng-container>
                        <app-deal-payouts-card
                            [transferMoneyInGroup]="transfer"
                            [dealFormGroup]="dualDealForm!"
                            [cardType]="cardType"
                            [brokerReleasesAmount]="dualDealBrokerReleasesAmount"
                            [isInfoOnly]="true"
                        ></app-deal-payouts-card>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="dualDealOtherTransfersArray.length > 0">
                <div style="font-size: 14px; margin: 5px 5px 5px 5px;">Other Financials</div>
                <div *ngFor="let transfer of dualDealOtherTransfersArray; let i = index">
                    <ng-container>
                        <app-deal-payouts-card
                            [transferMoneyInGroup]="transfer"
                            [dealFormGroup]="dualDealForm!"
                            [cardType]="cardType"
                            [isInfoOnly]="true"
                        ></app-deal-payouts-card>
                    </ng-container>
                </div>
            </div>
            <div
                *ngIf="dualDealCompanyTransfersArray.length === 0 && dualDealOtherTransfersArray.length === 0"
                class="m-2"
            >
                <span style="color: gray">No Payouts</span>
            </div>
        </div>
    `
})
export class SummaryPayoutsTabComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() dealFormGroup = new GenericFormGroup(new Deal());
    @Input() brokerReleasesAmount: number | undefined;
    @Input() dualDealBrokerReleasesAmount: number | undefined;

    dualDealForm: GenericFormGroup<Deal> | undefined;

    deal: Deal | undefined;

    transfersArray: FinancialTransferEntity[] = [];
    companyTransfersArray: FinancialTransferEntity[] = [];
    dualDealCompanyTransfersArray: FinancialTransferEntity[] = [];
    otherTransfersArray: FinancialTransferEntity[] = [];
    dualDealOtherTransfersArray: FinancialTransferEntity[] = [];

    cardType = 'summary';
    commissionPayer: any;

    // @ts-ignore
    dealTypesMap: {[key: string]: string} = {
        buyer: 'Buyer Side',
        seller: 'Listing Side',
        rental_tenant: 'Rental Tenant',
        rental_owner: 'Rental Owner',
        referral: 'Referral',
        broker_price_opinion: 'Broker Price Opinion'
    };

    constructor(
        public dialog: MatDialog,
        public router: Router
    ) {}

    ngOnInit() {
        this.deal = this.dealFormGroup.getRawValue();
        // set commission payer from deal_participants
        if (this.deal.deal_participants.commission_payer) {
            this.commissionPayer = this.deal.deal_participants.commission_payer;
        }

        this.getTransfersArray();
        this.dealFormGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((change) => {
            this.deal = this.dealFormGroup.getRawValue();
            this.getTransfersArray();
        });
    }

    getTransfersArray() {
        this.companyTransfersArray = [];
        this.otherTransfersArray = [];
        this.dualDealCompanyTransfersArray = [];
        this.dualDealOtherTransfersArray = [];
        const transfers = this.deal!.summary_payouts;
        this.transfersArray = transfers.map((transfer) => {
            if (!transfer.sender && this.commissionPayer) {
                transfer.sender = this.commissionPayer;
            }
            return transfer;
        });

        this.transfersArray.forEach((transfer) => {
            if (this.isCompanyPayout(transfer)) {
                this.companyTransfersArray.push(transfer);
            } else {
                this.otherTransfersArray.push(transfer);
            }
        });
        this.companyTransfersArray.sort(compareAmount);
        this.otherTransfersArray.sort(compareAmount);

        if (this.deal!.dual_deal_id && this.deal!.dual_deal) {
            const dualDeal = this.dealFormGroup.getRawValue().dual_deal;
            if (dualDeal) {
                this.dualDealForm = new GenericFormGroup(dualDeal);
                const dualTransfers = this.dealFormGroup.getRawValue().dual_deal!.summary_payouts;
                dualTransfers.forEach((transfer) => {
                    if (this.isCompanyPayout(transfer)) {
                        this.dualDealCompanyTransfersArray.push(transfer);
                    } else {
                        this.dualDealOtherTransfersArray.push(transfer);
                    }
                });
                this.dualDealCompanyTransfersArray.sort(compareAmount);
                this.dualDealOtherTransfersArray.sort(compareAmount);
            }
        }
    }

    isCompanyPayout(transfer: FinancialTransferEntity) {
        if (
            (transfer.receiver && transfer.receiver.contact_id === this.deal!.company!.main__contact_fk_id) ||
            (transfer.sender && transfer.sender.contact_id === this.deal!.company!.main__contact_fk_id)
        ) {
            return true;
        }
        return false;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

function compareAmount(a: FinancialTransferEntity, b: FinancialTransferEntity) {
    if (a.amount! > b.amount!) {
        return -1;
    }
    if (a.amount! < b.amount!) {
        return 1;
    }
    return 0;
}
