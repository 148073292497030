import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DealService} from '../../../../../../../services/deal.service';
import {Deal} from '../../../../../../../models/deal';

@Component({
    selector: 'app-add-deals-board',
    templateUrl: './add-deals-board.component.html',
    styleUrls: ['./add-deals-board.component.scss']
})
export class AddDealsBoardComponent implements OnInit, OnDestroy {
    @Input() createBoardMode: boolean = false;
    @Output() dealsBoardCreated = new EventEmitter();
    @Output() createBoardModeClosed = new EventEmitter();

    public model = {
        name: '',
        settings_frontend: {
            statuses: arrToObj(Deal.provideStatuses()),
            types: arrToObj([...Deal.provideTypes(), 'none']),
            disbursement_statuses: arrToObj(Deal.provideDisbursementStatuses()),
            property_classes: arrToObj(Deal.providePropertyClasses())
        }
    };

    createDisabled: boolean = false;

    constructor(protected dealsService: DealService) {}

    ngOnInit() {}

    create() {
        if (!this.createDisabled) {
            this.createDisabled = true;

            return this.dealsService
                .createDealsBoard(this.model)
                .then((board) => {
                    this.dealsBoardCreated.emit(board);
                    this.createDisabled = false;

                    this.model.name = '';
                })
                .catch(() => {
                    this.createDisabled = false;
                });
        }
    }

    ngOnDestroy() {
        this.dealsBoardCreated.complete();
        this.createBoardModeClosed.complete();
    }
}

function arrToObj(arr: string[]) {
    return arr.reduce((obj, el) => {
        return {...obj, [el]: true};
    }, {});
}
