import {InvoicePaymentTerms, PaymentModeTypes} from '../typings';
import {CreditStatuses, LedgerAccountTypes} from '@cyberco-nodejs/zipi-typings';

export const CONFORMITY_PLAN: {[key: string]: Array<string>} = {
    free: ['/banking', '/insights', '/analytics', '/reports', '/company/custom-fields', '/company', '/intelligence'],
    collaborator: [
        '/banking',
        '/insights',
        '/carrier-load-requests',
        '/contacts',
        '/company/custom-fields',
        '/company'
    ],
    basic: [
        '/banking',
        '/insights',
        '/customer-rater-list',
        '/tracking',
        '/analytics',
        '/contacts',
        '/reports',
        '/company/custom-fields',
        '/company',
        '/intelligence'
    ],
    premium: [
        '/banking',
        '/insights',
        '/customer-rater-list',
        '/carrier-load-requests',
        '/tracking',
        '/analytics',
        '/contacts',
        '/reports',
        '/company/custom-fields',
        '/company',
        '/intelligence'
    ],
    enterprise: [
        '/banking',
        '/insights',
        '/customer-rater-list',
        '/carrier-load-requests',
        '/tracking',
        '/analytics',
        '/contacts',
        '/reports',
        '/company/custom-fields',
        '/company',
        '/intelligence'
    ]
    // '3PL': [
    //     '/insights',
    //     '/customer-rater-list',
    //     '/carrier-load-requests',
    //     '/tracking',
    //     '/analytics',
    //     '/contacts',
    //     '/reports',
    //     '/company/custom-fields',
    //     '/company',
    //     '/intelligence'
    // ]
};

export const CONTACT_METATYPES = [
    ['customer', 'Customer'],
    ['vendor', 'Vendor'],
    ['agent', 'Agent'],
    ['other', 'Other'],
    ['delegate', 'Delegate']
];

export const DATE_RANGES = [
    ['today', 'Today'],
    ['yesterday', 'Yesterday'],
    ['this_week', 'This Week'], // from Monday (of current week) to TODAY
    ['this_month', 'This Month'], // from 1st (of current month) to TODAY
    ['this_quarter', 'This Quarter'],
    ['this_year', 'This Year'],
    ['last_week', 'Last Week'], // from Monday (of previous week) to Sunday (of previous week)
    ['last_month', 'Last Month'],
    ['last_quarter', 'Last Quarter'],
    ['last_year', 'Last Year'],
    ['past_7_days', 'Past 7 days'], // from (TODAY-7days) to TODAY
    ['past_30_days', 'Past 30 days'], // from (TODAY-7days) to TODAY
    ['past_60_days', 'Past 60 days'],
    ['past_90_days', 'Past 90 days'],
    ['custom_dates', 'Custom Dates']
];

export const SUMMARIZED_LIST = ['daily', 'weekly', 'monthly', 'quarterly', 'annually'];

export const CHART_TYPES = [
    {type: 'table', label: 'Table', icon: 'table_chart'},
    {type: 'bar', label: 'Bar Chart', icon: 'bar_chart'},
    {type: 'pie', label: 'Pie Chart', icon: 'pie_chart'}
];

export const MATERIAL_COLORS = ['#FFC107', '#f44336', '#E91E63', '#4CAF50', '#CDDC39', '#00BCD4', '#2196F3'];

export const DATE_FORMATS = ['YYYYMMDD', 'YYYY-MM-DD', 'DD-MM-YYYY', 'MM/DD/YYYY'];

export const PARTICIPANT_KINDS = [
    ['buyer_agent', 'Buyer Agent'],
    ['seller_agent', 'Listing Agent'],
    ['escrow_agent', 'Escrow Agent'],
    ['buyers', 'Buyers'],
    ['sellers', 'Sellers']
];

// export const RECURRING_INVOICE_PERIOD = [ 'daily' , 'weekly' , 'monthly', 'quarterly',  'custom' ];
export const RECURRING_INVOICE_PERIOD: Array<[string, {frequency: number; period: string; is_anniversary: boolean}]> = [
    ['daily', {frequency: 1, period: 'day', is_anniversary: false}],
    ['weekly', {frequency: 1, period: 'week', is_anniversary: false}],
    ['monthly', {frequency: 1, period: 'month', is_anniversary: false}],
    ['quarterly', {frequency: 1, period: 'quarter', is_anniversary: false}],
    ['yearly', {frequency: 1, period: 'year', is_anniversary: false}],
    ['Anniversary', {frequency: 1, period: 'day', is_anniversary: true}],
    ['custom', {frequency: 1, period: 'custom', is_anniversary: false}]
];

export const PAYMENT_TERMS: Array<[InvoicePaymentTerms, string]> = [
    ['no-terms', 'No Terms'],
    ['net-5', 'Net 5'],
    ['net-10', 'Net 10'],
    ['net-15', 'Net 15'],
    ['net-30', 'Net 30'],
    ['net-45', 'Net 45'],
    ['net-60', 'Net 60'],
    ['due-beginning-of-month', 'Due Beginning of Month'],
    ['due-end-of-month', 'Due End of the Month'],
    ['due-end-of-next-month', 'Due End of the Next Month'],
    ['due-on-receipt', 'Due On Receipt'],
    ['custom', 'Custom']
];

export const US_STATE_MAPPING_LIST: Array<{title: string; slug: string}> = [
    {title: 'Alabama', slug: 'AL'},
    {title: 'Alaska', slug: 'AK'},
    {title: 'Arizona', slug: 'AZ'},
    {title: 'Arkansas', slug: 'AR'},
    {title: 'California', slug: 'CA'},
    {title: 'Colorado', slug: 'CO'},
    {title: 'Connecticut', slug: 'CT'},
    {title: 'Delaware', slug: 'DE'},
    {title: 'Florida', slug: 'FL'},
    {title: 'Georgia', slug: 'GA'},
    {title: 'Hawaii', slug: 'HI'},
    {title: 'Idaho', slug: 'ID'},
    {title: 'Illinois', slug: 'IL'},
    {title: 'Indiana', slug: 'IN'},
    {title: 'Iowa', slug: 'IA'},
    {title: 'Kansas', slug: 'KS'},
    {title: 'Kentucky', slug: 'KY'},
    {title: 'Louisiana', slug: 'LA'},
    {title: 'Maine', slug: 'ME'},
    {title: 'Maryland', slug: 'MD'},
    {title: 'Massachusetts', slug: 'MA'},
    {title: 'Michigan', slug: 'MI'},
    {title: 'Minnesota', slug: 'MN'},
    {title: 'Mississippi', slug: 'MS'},
    {title: 'Missouri', slug: 'MO'},
    {title: 'Montana', slug: 'MT'},
    {title: 'Nebraska', slug: 'NE'},
    {title: 'Nevada', slug: 'NV'},
    {title: 'New Hampshire', slug: 'NH'},
    {title: 'New Jersey', slug: 'NJ'},
    {title: 'New Mexico', slug: 'NM'},
    {title: 'New York', slug: 'NY'},
    {title: 'North Carolina', slug: 'NC'},
    {title: 'North Dakota', slug: 'ND'},
    {title: 'Ohio', slug: 'OH'},
    {title: 'Oklahoma', slug: 'OK'},
    {title: 'Oregon', slug: 'OR'},
    {title: 'Pennsylvania', slug: 'PA'},
    {title: 'Rhode Island', slug: 'RI'},
    {title: 'South Carolina', slug: 'SC'},
    {title: 'South Dakota', slug: 'SD'},
    {title: 'Tennessee', slug: 'TN'},
    {title: 'Texas', slug: 'TX'},
    {title: 'Utah', slug: 'UT'},
    {title: 'Vermont', slug: 'VT'},
    {title: 'Virginia', slug: 'VA'},
    {title: 'Washington', slug: 'WA'},
    {title: 'West Virginia', slug: 'WV'},
    {title: 'Wisconsin', slug: 'WI'},
    {title: 'Wyoming', slug: 'WY'}
];

export const LEDGER_TYPES_MAP: {[key in LedgerAccountTypes]: string} = {
    other_asset: 'Other Asset',
    other_current_asset: 'Other Current Asset',
    other_current_asset_accounts_receivable: 'Other Current Asset (Accounts Receivable)',
    cash: 'Cash',
    bank: 'Bank',
    trust: 'Trust',
    bank_account: 'Bank Account',
    fixed_asset: 'Fixed Asset',
    investment: 'Investment',
    inventory: 'Inventory',
    payment_clearing: 'Payment Clearing',
    other_current_liability: 'Other Current Liability',
    current_liability: 'Current Liability',
    credit_card: 'Credit Card',
    long_term_liability: 'Long Term Liability',
    other_liability: 'Other Liability',
    trust_liability: 'Trust Liability',
    equity: 'Equity',
    retained_earnings: 'Retained Earnings',
    common_stock: 'Common Stock',
    preferred_stock: 'Preferred Stock',
    treasury_stock: 'Treasury Stock',
    additional_paid_in_capital: 'Additional Paid in Capital',
    partner_contributions: 'Partner Contributions',
    partner_distributions: 'Partner Distributions',
    income: 'Income',
    other_income: 'Other Income',
    expense: 'Expense',
    cost_of_goods_sold: 'Cost of Goods Sold',
    other_expense: 'Other Expense'
};

export const LEDGER_TYPES_GROUPS: any = [
    {
        group: 'Asset',
        list: [
            {
                value: 'other_asset',
                label: 'Other Asset',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'debit'
            },
            {
                value: 'other_current_asset',
                label: 'Other Current Asset',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'debit'
            },
            {
                value: 'other_current_asset_accounts_receivable',
                label: 'Other Current Asset (Accounts Receivable)',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'debit'
            },
            {
                value: 'cash',
                label: 'Cash',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: true,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'debit'
            },
            {
                value: 'bank',
                label: 'Bank',
                hasNumber: true,
                hasCurrency: true,
                hasExpense: true,
                hasBankName: true,
                canBeSubAcc: false,
                normal_balanced_type: 'debit'
            },
            {
                value: 'fixed_asset',
                label: 'Fixed Asset',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'debit'
            },
            {
                value: 'investment',
                label: 'Investment',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'debit'
            },
            {
                value: 'inventory',
                label: 'Inventory',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: false,
                normal_balanced_type: 'debit'
            },
            {
                value: 'payment_clearing',
                label: 'Payment Clearing',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: false,
                normal_balanced_type: 'debit'
            },
            {
                value: 'trust',
                label: 'Trust',
                hasNumber: true,
                hasCurrency: true,
                hasExpense: true,
                hasBankName: false,
                canBeSubAcc: false,
                normal_balanced_type: 'debit'
            }
        ]
    },
    {
        group: 'Liability',
        list: [
            {
                value: 'other_current_liability',
                label: 'Other Current Liability',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: true,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'credit'
            },
            {
                value: 'current_liability',
                label: 'Current Liability',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: true,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'credit'
            },
            {
                value: 'credit_card',
                label: 'Credit Card',
                hasNumber: false,
                hasCurrency: true,
                hasExpense: true,
                hasBankName: true,
                canBeSubAcc: false,
                normal_balanced_type: 'credit'
            },
            {
                value: 'long_term_liability',
                label: 'Long Term Liability',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'credit'
            },
            {
                value: 'other_liability',
                label: 'Other Liability',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'credit'
            },
            {
                value: 'trust_liability',
                label: 'Trust Liability',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: false,
                normal_balanced_type: 'credit'
            }
        ]
    },
    {
        group: 'Equity',
        list: [
            {
                value: 'equity',
                label: 'Equity',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'credit'
            },
            {
                value: 'retained_earnings',
                label: 'Retained Earnings',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'credit'
            },
            {
                value: 'common_stock',
                label: 'Common Stock',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'credit'
            },
            {
                value: 'preferred_stock',
                label: 'Preferred Stock',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'credit'
            },
            {
                value: 'treasury_stock',
                label: 'Treasury Stock',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'debit'
            },
            {
                value: 'additional_paid_in_capital',
                label: 'Additional Paid in Capital',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'credit'
            },
            {
                value: 'partner_contributions',
                label: 'Partner Contributions',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'credit'
            },
            {
                value: 'partner_distributions',
                label: 'Partner Distributions',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'debit'
            }
        ]
    },
    {
        group: 'Income',
        list: [
            {
                value: 'income',
                label: 'Income',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'credit'
            },
            {
                value: 'other_income',
                label: 'Other Income',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'credit'
            }
        ]
    },
    {
        group: 'Expense',
        list: [
            {
                value: 'expense',
                label: 'Expense',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: true,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'debit'
            },
            {
                value: 'cost_of_goods_sold',
                label: 'Cost of Goods Sold',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: true,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'debit'
            },
            {
                value: 'other_expense',
                label: 'Other Expense',
                hasNumber: false,
                hasCurrency: false,
                hasExpense: false,
                hasBankName: false,
                canBeSubAcc: true,
                normal_balanced_type: 'debit'
            }
        ]
    }
];

export const FINANCIAL_TRANSFER_TYPE_LABELS = {
    payout: 'Payout',
    company_payout: 'Company Payout',
    agent_payout: 'Agent Payout',
    deduct_transfer: 'Commission Deduction',
    associate_transfer: 'Associated'
};

export const INTEREST_RATE_TYPE = [
    {
        value: 'flat',
        label: 'Fixed Value'
    },
    {
        value: 'percent',
        label: 'Percent'
    }
];

export const INTEREST_RATE_LIMIT_TYPE = [
    {
        value: 'none',
        label: 'None'
    },
    {
        value: 'date',
        label: 'By Date'
    },
    {
        value: 'number_of_periods',
        label: 'By Number of Periods'
    }
];

export const INTEREST_PERIOD = [
    {
        value: 'none',
        label: 'None'
    },
    {
        value: 'daily',
        label: 'Daily'
    },
    {
        value: 'weekly',
        label: 'Weekly'
    },
    {
        value: 'monthly',
        label: 'Monthly'
    },
    {
        value: 'yearly',
        label: 'Yearly'
    }
];

// export const PAYMENT_MODES: PaymentModeTypes[] = ['bank_account', 'credit_card', 'cash', 'check'];
export const TRANSACTION_TYPES = {
    moneyOut: [
        {
            value: 'expense',
            label: 'Expense'
        },
        // {
        //     value: 'vendor_advance',
        //     label: 'Vendor Advance',
        // },
        {
            value: 'vendor_payment',
            label: 'Vendor Payment'
        },
        {
            value: 'transfer_to_another_account',
            label: 'Transfer to Another Account'
        },
        {
            value: 'card_payment',
            label: 'Card Payment'
        },
        {
            value: 'owner_drawings',
            label: 'Owner Draws'
        }
    ],
    moneyIn: [
        // {
        //     value: 'customer_advance',
        //     label: 'Customer Advance',
        // },
        {
            value: 'customer_payment',
            label: 'Customer Payment'
        },
        {
            value: 'transfer_from_another_account',
            label: 'Transfer From Another Account'
        },
        {
            value: 'other_deposit',
            label: 'Deposit From Other Account'
        },
        {
            value: 'owners_contribution',
            label: 'Owner Contribution'
        }
    ]
};

export const RECURRING_INVOICE_DRAFT_PERIOD = [
    ['None', {frequency: 0, period: 'none'}],
    ['1 Week', {frequency: 1, period: 'week'}],
    ['2 Weeks', {frequency: 2, period: 'week'}],
    ['3 Weeks', {frequency: 3, period: 'week'}],
    ['1 Month', {frequency: 1, period: 'month'}],
    ['Custom', {frequency: 0, period: 'day'}]
];

export const RECURRING_INVOICE_NOTIFICATION_PERIOD = [
    ['Issuance', {frequency: 0, period: 'issuance'}],
    ['1 Day', {frequency: 1, period: 'day'}],
    ['1 Week', {frequency: 1, period: 'week'}],
    ['2 Weeks', {frequency: 2, period: 'week'}],
    ['3 Weeks', {frequency: 3, period: 'week'}],
    ['4 Weeks', {frequency: 4, period: 'week'}]
];

export const RECURRING_INVOICE_UNPAID_REMINDER_PERIOD = [
    ['None', {frequency: 0, period: 'none'}],
    ['Weekly', {frequency: 1, period: 'week'}],
    ['Monthly', {frequency: 1, period: 'month'}]
];

export const BILL_INVOICE_STATUS_COLOR: {[key: string]: string} = {
    draft: '#D2B48C',
    void: '#29b6f6',
    partial_payment: '#29b6f6',
    sent: '#29b6f6',
    open: '#29b6f6',
    paid: '#2e7d32',
    overdue: '#d32f2f',
    write_off: '#d32f2f',
    rejected: '#d32f2f'
};

export const PAYMENT_CREDITS_STATUS_COLOR: {[key: string]: string} = {
    processing: '#007AB3',
    scheduled: '#8d8d8d',
    canceling: '#8d8d8d',
    review: '#29b6f6',
    pending: '#29b6f6',
    completed: '#2e7d32',
    expired: '#d32f2f',
    declined: '#d32f2f',
    canceled: '#d32f2f',
    reversed: '#d32f2f',
    error: '#d32f2f',

    cleared: '#2e7d32',
    uncleared: '#d32f2f',
    printed: '#2e7d32',
    not_printed: '#29b6f6'
};

export const EXTERNAL_TRANSFERS_STATUS_COLOR: {[key: string]: string} = {
    pending: '#29b6f6',
    completed: '#2e7d32',
    failed: '#d32f2f',
    canceled: '#d32f2f'
};

export const CREDITS_STATUS_COLOR: {[key: string]: string} = {
    draft: '#D2B48C',
    open: '#29b6f6',
    void: '#29b6f6',
    applied: '#2e7d32',
    closed: '#d32f2f'
};

export const DEPOSIT_REQUEST_STATUS_COLOR: {[key: string]: string} = {
    draft: '#D2B48C',
    partially_paid: '#29b6f6',
    sent: '#29b6f6',
    open: '#29b6f6',
    paid: '#2e7d32'
};
export const DEPOSIT_RELEASE_STATUS_COLOR = {
    draft: '#D2B48C',
    partially_paid: '#29b6f6',
    sent: '#29b6f6',
    open: '#29b6f6',
    paid: '#2e7d32'
};

export const PAYMENT_MODES: Array<{title: string; slug: PaymentModeTypes; is_selectable: boolean}> = [
    {title: 'Money Transfer (record)', slug: 'direct_deposit', is_selectable: true},
    {title: 'Credit Card', slug: 'credit_card', is_selectable: true},
    {title: 'Cash', slug: 'cash', is_selectable: true},
    {title: 'Check', slug: 'check', is_selectable: false},
    {title: 'Check (record)', slug: 'check_record', is_selectable: true},
    {title: 'Money Transfer', slug: 'zipi_pay', is_selectable: false},
    {title: 'Money Transfer', slug: 'zipi_financial', is_selectable: false},
    {title: 'Credit Card (auth.net)', slug: 'authorize', is_selectable: false},
    {title: 'Deduction', slug: 'deduction', is_selectable: false},
    {title: 'Deposit', slug: 'deposit', is_selectable: false}
];

export const SENDER_VELOCITY_TYPES = ['instant', 'standard', 'no_fee'];
export const RECEIVER_VELOCITY_TYPES = ['instant', 'standard'];

export const PAYMENT_MODES_MAP: {[key: string]: string} = {
    direct_deposit: 'Money Transfer (record)',
    credit_card: 'Credit Card',
    cash: 'Cash',
    check: 'Check',
    check_record: 'Check (record)',
    zipi_pay: 'Money Transfer',
    zipi_financial: 'Money Transfer',
    zipi_financial_card: 'Credit Card (ZP)',
    authorize: 'Credit Card (auth.net)',
    deduction: 'Deduction',
    deposit: 'Deposit'
};

export const PAYROLL_FILING_STATUSES = [
    {
        value: 'individual',
        label: 'Individual'
    },
    {
        value: 'joint',
        label: 'Joint'
    },
    {
        value: 'head_of_household',
        label: 'Head of Household (with dependents)'
    }
];

export const PAGE_SIZE_OPTIONS: number[] = [25, 50, 100, 200, 300];

export const ZIPI_FINANCIAL_BUSINESS_TYPES: Array<{title: string; slug: string}> = [
    {title: 'Individual', slug: 'individual'},
    {title: 'Sole Proprietorship', slug: 'soleProprietorship'},
    {title: 'Limited Liability Corporation (LLC)', slug: 'llc'},
    {title: 'Partnerships (LP, LLP)', slug: 'partnership'},
    {title: 'Private Corporation', slug: 'privateCorporation'},
    {title: 'Public Corporation (on stock exchange)', slug: 'publicCorporation'},
    {title: 'Unincorporated Association', slug: 'unincorporatedAssociation'},
    {title: 'Trust', slug: 'trust'}
];
export const ZIPI_FINANCIAL_MUHNEE_BUSINESS_TYPES: Array<{title: string; slug: string}> = [
    {title: 'Sole Proprietorship', slug: 'soleProprietor'},
    {title: 'Limited Liability Corporation (LLC)', slug: 'limitedLiabilityCompany'},
    {title: 'Partnerships (LP, LLP)', slug: 'generalPartnership'},
    {title: 'Private Corporation', slug: 'unlistedCorporation'},
    {title: 'Public Corporation (on stock exchange)', slug: 'publiclyTradedCorporation'},
    {title: 'Trust', slug: 'revocableTrust'},
    {title: 'Single Member LLC', slug: 'singleMemberLLC'},

    {title: 'Association', slug: 'association'},
    {title: 'Non Profit', slug: 'nonProfit'},
    {title: 'Government Organization', slug: 'governmentOrganization'},
    {title: 'Irrevocable Trust', slug: 'irrevocableTrust'},
    {title: 'Estate', slug: 'estate'}
];
const entityTypes = {
    soleProprietorship: 'soleProprietor'
};
export const ZIPI_FINANCIAL_BUSINESS_INDUSTRIES: Array<{title: string; hash: string}> = [
    {title: 'AC, Refrigeration Repair - 7623', hash: '811412_7623_7623'},
    {title: 'Accounting, Bookkeeping - 8931', hash: '541211_8721_8931'},
    {title: 'Advertising Services - 7311', hash: '541810_7311_7311'},
    {title: 'Agricultural Cooperative - 0763', hash: '813910_8699_0763'},
    {title: 'Airlines, Air Carriers - 4511', hash: '481111_4512_4511'},
    {title: 'Airports, Flying Fields - 4582', hash: '488119_4581_4582'},
    {title: 'Ambulance Services - 4119', hash: '621910_4119_4119'},
    {title: 'Amusement Parks, Carnivals - 7996', hash: '713110_7996_7996'},
    {title: 'Antique Reproductions - 5937', hash: '453310_5932_5937'},
    {title: 'Antiques - 5932', hash: '453310_5932_5932'},
    {title: 'Aquariums - 7998', hash: '712130_8422_7998'},
    {title: 'Architectural, Surveying - 8911', hash: '541310_8712_8911'},
    {title: 'Art Dealers, Galleries - 5971', hash: '453920_5999_5971'},
    {title: 'Artists Supply, Crafts - 5970', hash: '451120_5092_5970'},
    {title: 'Auto and Home Supplies - 5531', hash: '441310_5531_5531'},
    {title: 'Auto Body Repair - 7531', hash: '811121_7532_7531'},
    {title: 'Auto Paint - 7535', hash: '811121_7532_7535'},
    {title: 'Auto Service - 7538', hash: '811111_7538_7538'},
    {title: 'Automated Cash Disburse - 6011', hash: '522320_6099_6011'},
    {title: 'Automated Fuel Dispensers - 5542', hash: '454310_5983_5542'},
    {title: 'Automobile Associations - 8675', hash: '561599_8699_8675'},
    {title: 'Automobile Dealers (New & Used) - 5511', hash: '441110_5511_5511'},
    {title: 'Automobile Dealers (Used Only) - 5521', hash: '441120_5521_5521'},
    {title: 'Automotive Parts, Accessories - 5533', hash: '441310_5013_5533'},
    {title: 'Automotive Tire - 5532', hash: '441320_5014_5532'},
    {title: 'Bail, Bond Payments - 9223', hash: '812990_9223_9223'},
    {title: 'Bakeries - 5462', hash: '311811_5461_5462'},
    {title: 'Bands, Orchestras - 7929', hash: '711130_7929_7929'},
    {title: 'Barber, Beauty - 7230', hash: '812112_7231_7230'},
    {title: 'Betting, Casino Gambling - 7995', hash: '713210_7999_7995'},
    {title: 'Bicycle - 5940', hash: '451110_5091_5940'},
    {title: 'Billiard, Pool Establishments - 7932', hash: '713990_7999_7932'},
    {title: 'Boat Dealers - 5551', hash: '441222_5551_5551'},
    {title: 'Boat Rentals, Leases - 4457', hash: '532284_7999_4457'},
    {title: 'Books - 5942', hash: '451211_5942_5942'},
    {title: 'Books, Periodicals, Newspapers - 5192', hash: '424920_5192_5192'},
    {title: 'Bowling Alleys - 7933', hash: '713950_7933_7933'},
    {title: 'Bus Lines - 4131', hash: '485113_4131_4131'},
    {title: 'Business, Secretarial Schools - 8244', hash: '611410_8244_8244'},
    {title: 'Buying, Shopping Services - 7278', hash: '812990_7299_7278'},
    {title: 'Cable, Satellite, Pay Television and Radio - 4899', hash: '515210_4899_4899'},
    {title: 'Camera, Photographic Supply - 5946', hash: '443142_5946_5946'},
    {title: 'Candy, Nut, Confectionery - 5441', hash: '445292_5441_5441'},
    {title: 'Car Rental Agencies - 7512', hash: '532111_7514_7512'},
    {title: 'Car Washes - 7542', hash: '811192_7542_7542'},
    {title: 'Carpentry Contractors - 1750', hash: '238350_1751_1750'},
    {title: 'Carpet, Upholstery Cleaning - 7217', hash: '561740_7217_7217'},
    {title: 'Caterers - 5811', hash: '722320_5812_5811'},
    {title: 'Charitable, Social Service Organizations - 8398', hash: '813410_8641_8398'},
    {title: 'Chemical Products  - 5169', hash: '424690_5169_5169'},
    {title: 'Child Care Services - 8351', hash: '624410_8351_8351'},
    {title: 'Childrens, Infants Wear - 5641', hash: '448130_5641_5641'},
    {title: 'Chiropodists, Podiatrists - 8049', hash: '621399_8049_8049'},
    {title: 'Chiropractors - 8041', hash: '621310_8041_8041'},
    {title: 'Cigars - 5993', hash: '453991_5993_5993'},
    {title: 'Civic, Social, Fraternal Associations - 8641', hash: '813410_8641_8641'},
    {title: 'Cleaning, Maintenance - 7349', hash: '561720_7349_7349'},
    {title: 'Clothing Rental - 7296', hash: '532281_7299_7296'},
    {title: 'Colleges, Universities - 8220', hash: '611310_8221_8220'},
    {title: 'Commercial Equipment  - 5046', hash: '423440_5046_5046'},
    {title: 'Commercial Footwear - 5139', hash: '424340_5139_5139'},
    {title: 'Commercial Photography, Art, Graphics - 7333', hash: '541922_7335_7333'},
    {title: 'Commuter Transport, Ferries - 4111', hash: '483212_4111_4111'},
    {title: 'Computer Networks - 4816', hash: '518210_7374_4816'},
    {title: 'Computer Programming - 7372', hash: '511210_7372_7372'},
    {title: 'Computer Repair - 7379', hash: '541519_7379_7379'},
    {title: 'Computer Software  - 5734', hash: '443120_5734_5734'},
    {title: 'Computers, Peripherals, Software - 5045', hash: '423430_5045_5045'},
    {title: 'Concrete Work - 1771', hash: '238110_1771_1771'},
    {title: 'Construction Materials  - 5039', hash: '423390_5039_5039'},
    {title: 'Consulting, Public Relations - 7392', hash: '541820_8743_7392'},
    {title: 'Convenience Stores, Specialty Markets - 5499', hash: '445299_5499_5499'},
    {title: 'Correspondence Schools - 8241', hash: '611310_8221_8241'},
    {title: 'Cosmetics - 5977', hash: '446120_5999_5977'},
    {title: 'Counseling Services - 7277', hash: '624190_8322_7277'},
    {title: 'Courier Services - 4215', hash: '492110_4215_4215'},
    {title: 'Court Costs, Alimony, Child Support  - 9211', hash: '922110_9211_9211'},
    {title: 'Credit Reporting Agencies - 7321', hash: '561450_7323_7321'},
    {title: 'Cruise Lines - 4411', hash: '483112_4481_4411'},
    {title: 'Dairy Products - 5451', hash: '445299_5451_5451'},
    {title: 'Dance Hall, Studios, Schools - 7911', hash: '611610_7911_7911'},
    {title: 'Dating, Escort Services - 7273', hash: '812990_7299_7273'},
    {title: 'Dentists, Orthodontists - 8021', hash: '621210_8021_8021'},
    {title: 'Department Stores - 5311', hash: '452210_5311_5311'},
    {title: 'Detective Agencies - 7393', hash: '561611_7381_7393'},
    {title: 'Digital Goods - Applications - 5817', hash: '454110_5961_5817'},
    {title: 'Digital Goods – Games - 5816', hash: '454110_5961_5816'},
    {title: 'Digital Goods – Large Digital Goods Merchant - 5818', hash: '454110_5961_5818'},
    {title: 'Digital Goods Media – Books, Movies, Music - 5815', hash: '454110_5961_5815'},
    {title: 'Direct Marketing - Catalog Merchant - 5964', hash: '454390_5963_5964'},
    {title: 'Direct Marketing - Catalog, Retail - 5965', hash: '454390_5963_5965'},
    {title: 'Direct Marketing - Inbound Telemarketing - 5967', hash: '454390_5963_5967'},
    {title: 'Direct Marketing - Insurance Services - 5960', hash: '454390_5963_5960'},
    {title: 'Direct Marketing - Other - 5969', hash: '454390_5963_5969'},
    {title: 'Direct Marketing - Outbound Telemarketing - 5966', hash: '454390_5963_5966'},
    {title: 'Direct Marketing - Subscription - 5968', hash: '454390_5963_5968'},
    {title: 'Direct Marketing - Travel - 5962', hash: '454390_5962_5962'},
    {title: 'Discount Stores - 5310', hash: '452210_5311_5310'},
    {title: 'Doctors - 8011', hash: '621112_8011_8011'},
    {title: 'Door-To-Door Sales - 5963', hash: '454390_5963_5963'},
    {title: 'Drapery, Window Covering, Upholstery - 5714', hash: '442291_5714_5714'},
    {title: 'Drinking Places - 5813', hash: '722410_5813_5813'},
    {title: 'Drug Stores, Pharmacies - 5912', hash: '446110_5912_5912'},
    {title: 'Drugs, Drug Proprietaries - 5122', hash: '424210_5122_5122'},
    {title: 'Dry Cleaners - 7216', hash: '812320_7216_7216'},
    {title: 'Durable Goods  - 5099', hash: '423990_5099_5099'},
    {title: 'Duty Free - 5309', hash: '453998_5999_5309'},
    {title: 'Eating Places, Restaurants - 5812', hash: '722110_5812_5812'},
    {title: 'Educational Services - 8299', hash: '611710_8299_8299'},
    {title: 'Electric Razor - 5997', hash: '443142_5731_5997'},
    {title: 'Electrical Contractors - 1731', hash: '238210_1731_1731'},
    {title: 'Electrical Parts  - 5065', hash: '423610_5065_5065'},
    {title: 'Electronics - 5732', hash: '443142_5731_5732'},
    {title: 'Electronics Repair - 7622', hash: '811211_7622_7622'},
    {title: 'Elementary, Secondary Schools - 8211', hash: '611110_8211_8211'},
    {title: 'Employment, Temp Agencies - 7361', hash: '541612_7361_7361'},
    {title: 'Equipment Rental - 7394', hash: '532490_7359_7394'},
    {title: 'Exterminating Services - 7342', hash: '561710_7342_7342'},
    {title: 'Family Clothing - 5651', hash: '448140_5651_5651'},
    {title: 'Fast Food Restaurants - 5814', hash: '722513_5812_5814'},
    {title: 'Financial Institutions - 6012', hash: '522110_6021_6012'},
    {title: 'Fines - Government Administrative Entities - 9222', hash: '922190_9229_9222'},
    {title: 'Fireplace - 5718', hash: '423220_5023_5718'},
    {title: 'Floor Covering - 5713', hash: '442210_5713_5713'},
    {title: 'Florists - 5992', hash: '453110_5992_5992'},
    {title: 'Florists Supplies, Nursery Stock, Flowers - 5193', hash: '424930_5193_5193'},
    {title: 'Footwear - 5661', hash: '448210_5661_5661'},
    {title: 'Freezer, Locker Meat Provisioners - 5422', hash: '454390_5421_5422'},
    {title: 'Freight Carriers, Trucking  - 4214', hash: '484110_4214_4214'},
    {title: 'Fuel Dealers  - 5983', hash: '454311_5983_5983'},
    {title: 'Funeral Services, Crematories - 7261', hash: '812210_7261_7261'},
    {title: 'Furniture - 5712', hash: '442299_5712_5712'},
    {title: 'Furniture Repair, Refinishing - 7641', hash: '811420_7641_7641'},
    {title: 'Furriers, Fur - 5681', hash: '314999_2371_5681'},
    {title: 'General Contractors - 1520', hash: '236115_1521_1520'},
    {title: 'Gift, Card, Novelty, Souvenir Shops - 5947', hash: '453220_5947_5947'},
    {title: 'Glass, Paint, Wallpaper - 5231', hash: '444120_5231_5231'},
    {title: 'Glassware, Crystal - 5950', hash: '442299_5719_5950'},
    {title: 'Government Services  - 9399', hash: '921190_9199_9399'},
    {title: 'Grocery Stores, Supermarkets - 5411', hash: '445110_5411_5411'},
    {title: 'Hardware - 5251', hash: '444130_5251_5251'},
    {title: 'Hardware, Equipment - 5072', hash: '423710_5072_5072'},
    {title: 'Health, Beauty Spas - 7298', hash: '812199_7299_7298'},
    {title: 'Hearing Aids  - 5975', hash: '423450_5047_5975'},
    {title: 'Heating, Plumbing, AC - 1711', hash: '238220_1711_1711'},
    {title: 'Hobby, Toy, Games - 5945', hash: '451120_5945_5945'},
    {title: 'Home Supply Warehouse - 5200', hash: '442299_5719_5200'},
    {title: 'Hospitals - 8062', hash: '622110_8062_8062'},
    {title: 'Hotels, Motels, Resorts - 7011', hash: '721110_7011_7011'},
    {title: 'Household Appliance - 5722', hash: '443141_5722_5722'},
    {title: 'Industrial Supplies  - 5085', hash: '423840_5085_5085'},
    {title: 'Information Retrieval Services - 7375', hash: '518111_7375_7375'},
    {title: 'Insurance  - 6399', hash: '524128_6399_6399'},
    {title: 'Insurance Underwriting, Premiums - 6300', hash: '524210_6411_6300'},
    {title: 'Jewelry, Watches, Clocks, Silverware - 5944', hash: '448310_5944_5944'},
    {title: 'Landscaping Services - 0780', hash: '561730_0782_0780'},
    {title: 'Laundries - 7211', hash: '812320_7211_7211'},
    {title: 'Laundry, Cleaning Services - 7210', hash: '812320_7219_7210'},
    {title: 'Legal Services, Attorneys - 8111', hash: '541110_8111_8111'},
    {title: 'Luggage, Leather Goods - 5948', hash: '448320_5948_5948'},
    {title: 'Lumber, Building Materials - 5211', hash: '444110_5211_5211'},
    {title: 'Manual Cash Disburse - 6010', hash: '522320_6099_6010'},
    {title: 'Marinas - 4468', hash: '713930_4493_4468'},
    {title: 'Masonry, Stonework, Plaster - 1740', hash: '238140_1741_1740'},
    {title: 'Massage Parlors - 7297', hash: '812199_7299_7297'},
    {title: 'Medical Services - 8099', hash: '621999_8099_8099'},
    {title: 'Medical, Dental Labs - 8071', hash: '621511_8071_8071'},
    {title: 'Medical, Dental, Ophthalmic, Hospital Equipment - 5047', hash: '423450_5047_5047'},
    {title: 'Membership Clubs - 7997', hash: '713910_7997_7997'},
    {title: 'Membership Organizations - 8699', hash: '813990_8699_8699'},
    {title: 'Mens and Boys Clothing, Accessories - 5611', hash: '448110_5611_5611'},
    {title: 'Mens, Womens Clothing - 5691', hash: '448140_5651_5691'},
    {title: 'Metal Service Centers - 5051', hash: '423510_5051_5051'},
    {title: 'Miscellaneous Apparel, Accessories - 5699', hash: '448150_5699_5699'},
    {title: 'Miscellaneous Auto Dealers - 5599', hash: '441229_5599_5599'},
    {title: 'Miscellaneous Business Services - 7399', hash: '561499_7389_7399'},
    {title: 'Miscellaneous General Merchandise - 5399', hash: '452990_5399_5399'},
    {title: 'Miscellaneous General Services - 7299', hash: '812990_7299_7299'},
    {title: 'Miscellaneous Home Furnishing Specialty - 5719', hash: '442299_5719_5719'},
    {title: 'Miscellaneous Publishing, Printing - 2741', hash: '511199_2741_2741'},
    {title: 'Miscellaneous Recreation Services - 7999', hash: '713990_7999_7999'},
    {title: 'Miscellaneous Repair - 7699', hash: '811490_7699_7699'},
    {title: 'Miscellaneous Specialty Retail - 5999', hash: '453998_5999_5999'},
    {title: 'Mobile Home Dealers - 5271', hash: '453930_5271_5271'},
    {title: 'Motion Picture Theaters - 7832', hash: '512131_7832_7832'},
    {title: 'Motor Homes Dealers - 5592', hash: '453930_5271_5592'},
    {title: 'Motor Vehicle Supplies - 5013', hash: '423120_5013_5013'},
    {title: 'Motorcycle Dealers - 5561', hash: '441210_5561_5561'},
    {title: 'Musical Instruments, Pianos, Sheet Music - 5733', hash: '451140_5736_5733'},
    {title: 'News Dealers, Newsstands - 5994', hash: '451212_5994_5994'},
    {title: 'Non-FI, Money Orders - 6051', hash: '522390_6099_6051'},
    {title: 'Non-FI, Stored Value Card Purchase - 6540', hash: '522210_6021_6540'},
    {title: 'Nondurable Goods  - 5199', hash: '424990_5199_5199'},
    {title: 'Nurseries, Lawn, Garden Supply - 5261', hash: '444220_5261_5261'},
    {title: 'Nursing, Personal Care - 8050', hash: '621610_8082_8050'},
    {title: 'Office, Commercial Furniture - 5021', hash: '423210_5021_5021'},
    {title: 'Opticians, Eyeglasses - 8043', hash: '621320_8042_8043'},
    {title: 'Optometrists, Ophthalmologist - 8042', hash: '621320_8042_8042'},
    {title: 'Orthopedic Goods, Prosthetic Devices - 5976', hash: '446199_3842_5976'},
    {title: 'Osteopaths - 8031', hash: '621111_8031_8031'},
    {title: 'Package - Beer, Wine, Liquor - 5921', hash: '445310_5921_5921'},
    {title: 'Paints, Varnishes - 5198', hash: '424950_5198_5198'},
    {title: 'Parking Lots, Garages - 7523', hash: '812930_7521_7523'},
    {title: 'Passenger Railways - 4112', hash: '482111_4011_4112'},
    {title: 'Pawn Shops - 5933', hash: '522298_6141_5933'},
    {title: 'Pet Supplies, Food - 5995', hash: '453910_5149_5995'},
    {title: 'Petroleum Products - 5172', hash: '424720_5172_5172'},
    {title: 'Photo Developing - 7395', hash: '812921_7384_7395'},
    {title: 'Photographic Studios - 7221', hash: '541921_7221_7221'},
    {title: 'Photographic, Photocopy, Microfilm Equipment - 5044', hash: '423420_5044_5044'},
    {title: 'Piece Goods, Other Dry Goods - 5131', hash: '424310_5131_5131'},
    {title: 'Plumbing, Heating Equipment - 5074', hash: '423720_5074_5074'},
    {title: 'Political Organizations - 8651', hash: '813940_8651_8651'},
    {title: 'Postal Services  - 9402', hash: '491110_4311_9402'},
    {title: 'Precious Stones and Metals, Watches, Jewelry - 5094', hash: '423940_5094_5094'},
    {title: 'Professional Services - 8999', hash: '541990_8999_8999'},
    {title: 'Public Golf Courses  - 7992', hash: '713910_7992_7992'},
    {title: 'Public Warehousing, Storage  - 4225', hash: '493110_4225_4225'},
    {title: 'Quick Copy, Repro, Blueprint - 7338', hash: '561410_7338_7338'},
    {title: 'Railroads - 4011', hash: '482111_4011_4011'},
    {title: 'Real Estate Agents, Rentals - 6513', hash: '531110_6531_6513'},
    {title: 'Record Stores - 5735', hash: '451220_5735_5735'},
    {title: 'Recreational Vehicle Rentals - 7519', hash: '532120_7519_7519'},
    {title: 'Religious Goods - 5973', hash: '453998_5999_5973'},
    {title: 'Religious Organizations - 8661', hash: '813110_8661_8661'},
    {title: 'Roofing, Siding, Sheet Metal - 1761', hash: '238160_1761_1761'},
    {title: 'Secretarial Support Services - 7339', hash: '561110_8741_7339'},
    {title: 'Security Brokers, Dealers - 6211', hash: '523120_6211_6211'},
    {title: 'Service Stations - 5541', hash: '447110_5541_5541'},
    {title: 'Sewing, Needlework, Fabric, Piece Goods - 5949', hash: '451130_5949_5949'},
    {title: 'Shoe Repair, Hat Cleaning - 7251', hash: '811430_7251_7251'},
    {title: 'Small Appliance Repair - 7629', hash: '811219_7629_7629'},
    {title: 'Snowmobile Dealers - 5598', hash: '441228_5599_5598'},
    {title: 'Special Trade Contractors - 1799', hash: '238990_1799_1799'},
    {title: 'Specialty Cleaning - 2842', hash: '325612_2842_2842'},
    {title: 'Sporting Goods - 5941', hash: '451110_5941_5941'},
    {title: 'Sporting, Recreation Camps - 7032', hash: '721214_7032_7032'},
    {title: 'Sports Clubs, Fields - 7941', hash: '711211_7941_7941'},
    {title: 'Sports, Riding Apparel - 5655', hash: '451110_5091_5655'},
    {title: 'Stamps, Coins - 5972', hash: '453998_5999_5972'},
    {title: 'Stationary, Office Supplies, Printing, Writing Paper - 5111', hash: '424110_5111_5111'},
    {title: 'Stationery, Office, School Supplies - 5943', hash: '453210_5943_5943'},
    {title: 'Swimming Pools - 5996', hash: '423910_5999_5996'},
    {title: 'Tailors, Alterations - 5697', hash: '811490_7219_5697'},
    {title: 'Tax Payments  - 9311', hash: '921130_9311_9311'},
    {title: 'Tax Preparation Services - 7276', hash: '541213_7291_7276'},
    {title: 'Taxicabs, Limousines - 4121', hash: '485310_4121_4121'},
    {title: 'Telecommunication - 4814', hash: '517312_4899_4814'},
    {title: 'Telecommunication Equipment - 4812', hash: '443142_4812_4812'},
    {title: 'Telegraph - 4821', hash: '517311_4822_4821'},
    {title: 'Tent, Awning - 5998', hash: '424990_5199_5998'},
    {title: 'Testing Laboratories - 8734', hash: '541380_8734_8734'},
    {title: 'Theatrical Ticket Agencies - 7922', hash: '561599_7922_7922'},
    {title: 'Timeshares - 7012', hash: '531210_6531_7012'},
    {title: 'Tire Retreading, Repair - 7534', hash: '326212_7534_7534'},
    {title: 'Tolls, Bridge Fees - 4784', hash: '488490_4785_4784'},
    {title: 'Tourist Attractions, Exhibits - 7991', hash: '712110_8412_7991'},
    {title: 'Towing Services - 7549', hash: '488410_7549_7549'},
    {title: 'Trailer Parks, Campgrounds - 7033', hash: '721211_7033_7033'},
    {title: 'Transportation Services  - 4789', hash: '488999_4789_4789'},
    {title: 'Travel Agencies, Tour Operators - 4722', hash: '561510_4724_4722'},
    {title: 'Truck Stop - 7511', hash: '447190_5541_7511'},
    {title: 'Truck, Utility Trailer Rentals - 7513', hash: '532120_7513_7513'},
    {title: 'Typesetting, Plate Making - 2791', hash: '323122_2791_2791'},
    {title: 'Typewriters - 5978', hash: '423420_5044_5978'},
    {title: 'U.S. Federal Government Agencies or Departments - 9405', hash: '921190_9199_9405'},
    {title: 'Uniforms, Commercial Clothing - 5137', hash: '315280_5137_5137'},
    {title: 'Used or Secondhand Merchandise - 5931', hash: '453310_5932_5931'},
    {title: 'Utilities - 4900', hash: '926130_9631_4900'},
    {title: 'Variety Stores - 5331', hash: '452990_5331_5331'},
    {title: 'Veterinary Services - 0742', hash: '541940_0742_0742'},
    {title: 'Video Amusement Game Supplies - 7993', hash: '713120_7993_7993'},
    {title: 'Video Game Arcades - 7994', hash: '713120_7993_7994'},
    {title: 'Video Production - 7829', hash: '512110_7819_7829'},
    {title: 'Video Tape Rental - 7841', hash: '532230_7841_7841'},
    {title: 'Vocational, Trade Schools - 8249', hash: '611519_8249_8249'},
    {title: 'Watch, Jewelry Repair - 7631', hash: '811490_7631_7631'},
    {title: 'Welding Repair - 7692', hash: '811310_7692_7692'},
    {title: 'Wholesale Clubs - 5300', hash: '452210_5311_5300'},
    {title: 'Wig, Toupee - 5698', hash: '448150_5699_5698'},
    {title: 'Wires, Money Orders - 4829', hash: '522390_6099_4829'},
    {title: 'Womens Accessories - 5631', hash: '448150_5632_5631'},
    {title: 'Womens Ready-To-Wear - 5621', hash: '448120_5621_5621'},
    {title: 'Wrecking, Salvage Yards - 5935', hash: '423930_5093_5935'}
];

export const ZIPI_FINANCIAL_MUHNEE_BUSINESS_INDUSTRIES: Array<{title: string; hash: string}> = [
    {title: 'Real Estate Agents, Rentals - 6513', hash: 'realEstate'}
];

export const MUHNEE_LARGE_LOGO = 'https://storage.googleapis.com/zipi-static/addons/muhnee/a9a2_munhee.svg';
// export const ZIPI_PAY_LARGE_LOGO = 'https://storage.googleapis.com/zipi-static/addons/zipipay/logos/Zipi%20Pay%20360x140.png';
export const SKYSLOPE_BOOKS_LARGE_LOGO =
    'https://storage.googleapis.com/zipi-static/app-assets/skyslope-books-logo.svg';

export const FEE_COMMISSION_SCENARIOS = ['regular', 'commission_payout', 'escrow_deposit'];

export const FEE_TRANSFER_CUSTOMIZATION_REFS = {
    client_pays: 'option_1',
    skyslope_pays: 'option_2',
    client_pays_delay: 'option_3'
};
