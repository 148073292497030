import {Component, OnDestroy} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {UiModsSource} from '../ui-mods.source';
import {UiModsMenuOptionModel} from '../models/ui-mods.model';
import {UiModsApi} from '../api/ui-mods.api';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-ui-mod-create-deal-menu-options',
    template: `
        <button *ngFor="let menu_option of menu_options" mat-menu-item (click)="click(menu_option)">
            {{ menu_option.option.label }}
        </button>
    `
})
export class CreateDealMenuOptionsComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    public menu_options: UiModsMenuOptionModel[] = [];
    private subscription: Subscription;

    constructor(
        public uiModsSource: UiModsSource,
        public uiModsApi: UiModsApi
    ) {
        this.subscription = uiModsSource.createDealMenuOptions
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((menu_options) => {
                this.menu_options = menu_options.map((menu_option) => {
                    const preset = menu_option.uiMod ? menu_option.uiMod.preset : {};
                    const res: UiModsMenuOptionModel = Object.assign(new UiModsMenuOptionModel(), preset);
                    res.id = menu_option.id;
                    res.uiMod = menu_option.uiMod;
                    if (menu_option.option) {
                        res.option = menu_option.option;
                    }

                    return res;
                });
            });
    }

    click(menu_option: UiModsMenuOptionModel) {
        if (menu_option.option.on_click && menu_option.uiMod && menu_option.id) {
            switch (menu_option.option.on_click.action) {
                case 'redirect_user':
                    window.location.href = menu_option.uiMod.addon.addon_url + menu_option.option.on_click.path;
                    break;
                case 'open_new_tab':
                    window.open(menu_option.uiMod.addon.addon_url + menu_option.option.on_click.path);
                    // window.location.href = menu_option.uiMod.addon.addon_url + menu_option.option.on_click.path;
                    break;
                case 'request_from_backend':
                    this.uiModsApi.uiModClicked(menu_option.id);
                    break;
                default:
                    throw new Error('Unknown action');
            }
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
