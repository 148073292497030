<div class="w-100">
    <div class="d-flex justify-content-center">
        <h2>Customer Setup</h2>
    </div>

    <div class="d-flex justify-content-center">
        <mat-card style="width: 520px; text-align: center">
            <div>
                <div>To be able to pay with Cards you need to create Card Holder and add Cards.</div>
            </div>
            <div>
                <div class="d-flex justify-content-end w-100 mt-2">
                    <button mat-raised-button color="primary" [disabled]="isButtonLock" (click)="createTppGateway()">
                        Create Card Holder
                    </button>
                </div>
            </div>
        </mat-card>
    </div>
</div>
