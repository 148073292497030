import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {ILedgerAccount, IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {LedgerAccountService} from '../../../../../../../services/api/finance/ledger-accounts.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-zipi-fin-connect-to-ledger-account-dialog',
    templateUrl: './zipi-fin-connect-to-ledger-account-dialog.component.html',
    styleUrls: ['./zipi-fin-connect-to-ledger-account-dialog.component.scss']
})
export class ZipiFinConnectToLedgerAccountDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    accountId: UntypedFormControl | undefined;

    currentGateway: IPaymentGateway | undefined;
    currentPaymentMethod: IPaymentMethod | undefined;

    availableLedgerAccounts: ILedgerAccount[];

    isDefault: boolean;

    constructor(
        public dialogRef: MatDialogRef<ZipiFinConnectToLedgerAccountDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private ledgerAccountService: LedgerAccountService,
        private fb: UntypedFormBuilder
    ) {
        this.availableLedgerAccounts = [];
        this.isDefault = false;
    }

    ngOnInit() {
        this.currentGateway = this.data.gateway;
        this.currentPaymentMethod = this.data.method;
        this.accountId = this.fb.control(null, [Validators.required]);
        if (this.currentGateway) {
            this.ledgerAccountService
                .getAvailableLedgerAccountsForFundingSourceByGatewayType(this.currentGateway.type)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((accounts) => {
                    this.availableLedgerAccounts = accounts.filter((account) => {
                        if (this.currentPaymentMethod?.type === 'zipi_financial_credit_card') {
                            return account.type === 'credit_card';
                        } else {
                            return account.type !== 'credit_card';
                        }
                    });
                });
        }
    }
    apply() {
        if (!this.isDefault && this.accountId && this.accountId.invalid) {
            this.accountId.markAllAsTouched();
            return;
        }
        if (this.isDefault) {
            this.dialogRef.close(null);
        } else {
            this.dialogRef.close(this.accountId!.value);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
