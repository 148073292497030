import {SalesEntity} from './sales-entity';
import {IContactPartLink} from '../../typings';
import {GenericEntity} from '../entites/generic.entity';

export enum DEAL_SELF_TESTING_STATUSES {
    failed = 'failed',
    not_tested = 'not_tested',
    passed = 'passed',
    error = 'error'
}

export enum DEAL_SELF_TESTING_STATUSES_LABELS {
    failed = 'Failed',
    not_tested = 'Not Tested',
    passed = 'Passed',
    error = 'Error'
}

export class DealSelfTestingEntity extends GenericEntity {
    deal_calculation_amounts: DealCalculationNodesAmountsEntity = new DealCalculationNodesAmountsEntity();
    sales_entities_calculations_amounts: SalesEntityCalculationTestsResultsEntity[] = [];
    tests_results: DealSelfTestsResultsEntity = new DealSelfTestsResultsEntity();
    tests_status: DEAL_SELF_TESTING_STATUSES = DEAL_SELF_TESTING_STATUSES.not_tested;
    test_formulas: {[key: string]: string} = {};
    tested_at: Date | null = null;
    error: Object | undefined = undefined;

    static get deals_text_formulas_GET() {
        return {
            deal__gross_commission: 'Gross Commission = Base Commission + Additional Commission - Commission Expense',
            deal__adjusted_gross_commission: 'Adjusted Gross Commission = Gross Commission - Referral',
            deal__royalty: 'Deal Royalty = Sum of Royalties for Each Rule',
            deal__net_commission: 'Net Commission = Adjusted Gross Commission - Royalty',
            deal__deal_net: 'Deal Net = Net Commission + Sum of Deal Incomes - Sum of Deal Expenses',
            deal__sales_total: 'Sales Total = Sum of Compensation of all agents',
            deal__sales_net: 'Sales Net = Sum of Splits of all agents - Sum of Sales Expenses'
        };
    }

    static get agent_text_formulas_GET() {
        return {
            agent__agent_split:
                'Agent Split = Sum of Agent Splits for Each Rule + Sum of Individual Sales Incomes - Sum of Individual Sales Expenses',
            agent__agent_split_after_referral: 'Agent Split after Referral = Agent Split - Sum of Referral Expenses',
            agent__net:
                'Net = Agent Split - Sum of Referral Expenses - Royalty + Sum of Additional Incomes - Sum of Additional Expenses',
            agent__budget: 'Budget = Net + Incentive - Overhead'
        };
    }

    static get transfer_text_formulas_GET() {
        return {
            transfer__net: 'Net = Sum of Referral Incomes + Sum of Additional Incomes - Sum of Additional Expenses'
        };
    }

    static get payouts_text_formulas_GET() {
        return {
            payout__closing_payouts: 'Deal Gross Commission = Sum of Closing Payouts'
        };
    }
}

export class DealSelfTestsResultsEntity extends GenericEntity {
    deal_calculations_tests_results: DealCalculationsTestsResultsEntity = new DealCalculationsTestsResultsEntity();
    sales_entities_calculations_tests_results: SalesEntityCalculationTestsResultsEntity[] = [];
    payouts_calculations_tests_results: PayoutsTestsResultsEntity = new PayoutsTestsResultsEntity();
}

class DealCalculationsTestsResultsEntity extends GenericEntity {
    deal__gross_commission = undefined;
    deal__adjusted_gross_commission = undefined;
    deal__net_commission = undefined;
    deal__deal_net = undefined;
    deal__sales_total = undefined;
}

class SalesEntityCalculationTestsResultsEntity extends GenericEntity {
    owner__profile_fk_id = null;
    owner__company_group_fk_id = null;
    owner__contact_fk_id = null;
    contact_part_link: Partial<IContactPartLink> = {contact_id: null, link_title: null};
    role = SalesEntity.role_SET.transfer;
    type = SalesEntity.type_SET.contact;
    is_primary: boolean = false;
    agent__agent_split_after_referral = undefined;
    agent__net = undefined;
    agent__budget = undefined;
    transfer__net = undefined;
}

class PayoutsTestsResultsEntity extends GenericEntity {
    payout__closing_payouts = null;
}

export class DealCalculationNodesAmountsEntity extends GenericEntity {
    sales_price: number = 0;
    adjusted_sales_price: number = 0;
    commission: number = 0;
    base_commission: number = 0;
    commission_incomes_sum: number = 0;
    commission_expenses_sum: number = 0;
    referral: number = 0;
    commission_after_referral: number = 0;
    royalty: number = 0;
    net_commission: number = 0;
    deal_incomes_sum: number = 0;
    deal_expenses_sum: number = 0;
    deal_net: number = 0;
    sales_total: number = 0;
    sales_incomes_sum: number = 0;
    sales_expenses_sum: number = 0;
    sales_net: number = 0;
    company_split: number = 0;
    company_incomes_sum: number = 0;
    company_expenses_sum: number = 0;
    company_net: number = 0;
    deal_deposit_request_amount: number = 0;
    deal_deposit_received_amount: number = 0;
    deal_deposit_allocated_amount: number = 0;
    deal_deposit_unallocated_amount: number = 0;
}

export class SalesEntityCalculationNodesAmountsEntity extends GenericEntity {
    owner__profile_fk_id = undefined;
    owner__company_group_fk_id = undefined;
    owner__contact_fk_id = undefined;
    contact_part_link: Partial<IContactPartLink> = {contact_id: null, link_title: null};
    type = SalesEntity.type_SET.profile;
    role = SalesEntity.role_SET.transfer;
    percent_of_deal = 0;
    sales_price = 0;
    base_commission = 0;
    commission = 0;
    commission_after_referral = 0;
    net_commission = 0;
    deal_net = 0;
    sales_split = 0;
    sales_net = 0;
    sales_incomes_sum = 0;
    sales_expenses_sum = 0;
    compensation = 0;
    company_split = 0;
    agent_split = 0;
    agent_split_after_referral = 0;
    team_split = 0;
    participant_royalty = 0;
    after_step_1 = 0;
    additional_incomes_sum = 0;
    additional_expenses_sum = 0;
    referral_expenses_sum = 0;
    after_step_2 = 0;
    taxes = 0;
    biz_exp = 0;
    overhead = 0;
    incentive = 0;
    after_step_3 = 0;
    net = 0;
    budget = 0;
    referral_incomes_sum = 0;
}
