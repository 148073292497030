import {Component, Inject, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import moment from 'moment-timezone';
import {LedgerAccountSelectorComponent} from '../../../../../../shared/components/ledger-account-selector/ledger-account-selector.component';
import {PAYMENT_MODES} from '../../../../../../../local-typings';
import {FormGroupArray} from '../../../../../../../typings/common';
import {requiredNumberValidator} from '../../../../../../../forms/validators';
import {ClosingPayoutsApiService} from '../../../services/closing-payouts.api-service';
import {IClosingPayoutsWithAdditionalInfo} from '../../../../closing-payouts/types/closing-payouts.types';
import {cleanCurrencyString, currencyMaskitoOptions} from '@app/utilities/maskito';

@Component({
    selector: 'record-closing-payout-dialog',
    templateUrl: 'record-closing-payout-dialog.component.html',
    styleUrls: ['./record-closing-payout-dialog.component.scss']
})
export class RecordClosingPayoutDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @ViewChild('ledgerAccountPicker', {static: true}) public ledgerAccountPicker:
        | LedgerAccountSelectorComponent
        | undefined;
    currencyMaskitoMask = currencyMaskitoOptions;

    paymentModes = PAYMENT_MODES;

    payoutIds: number[] = [];
    fullPayoutsInfo: IClosingPayoutsWithAdditionalInfo[] = [];

    recordPaymentSettingsGroup: UntypedFormGroup;
    itemsArray: FormGroupArray = this.fb.array([]) as FormGroupArray;
    itemsArrayBuilt: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<RecordClosingPayoutDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {payoutIds: Array<number>},
        private closingPayoutsService: ClosingPayoutsApiService,
        private fb: UntypedFormBuilder
    ) {
        this.recordPaymentSettingsGroup = this.fb.group({
            payment_mode: ['cash', [Validators.required]],
            paid_date: [Number(moment().format('YYYYMMDD')), [requiredNumberValidator]],
            ledger_account_id: [null, [Validators.required]]
        });
    }

    ngOnInit() {
        this.payoutIds = this.data.payoutIds;
        if (this.payoutIds && this.payoutIds.length > 0) {
            this.loadPayoutsInfo(this.payoutIds);
        } else {
            this.close();
        }
    }

    loadPayoutsInfo(payoutIds: Array<number>) {
        this.closingPayoutsService
            .getClosingPayoutsInfo(payoutIds)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((payouts) => {
                this.fullPayoutsInfo = payouts;
                this.setupItemsArray();
            });
    }

    setupItemsArray() {
        if (this.fullPayoutsInfo && this.fullPayoutsInfo.length > 0) {
            this.itemsArray.clear();
            for (const item of this.fullPayoutsInfo) {
                this.itemsArray.push(this.createItem(item));
            }
        }
        this.itemsArrayBuilt = true;
    }

    createItem(payout: IClosingPayoutsWithAdditionalInfo) {
        const group: UntypedFormGroup = this.fb.group({
            financial_transfer_id: [payout.financial_transfer_id, [Validators.required]],
            amount: [payout.connected_invoice_id ? payout.pending_balance : payout.amount, []],
            payment_from_name: [payout.payment_from_name, []],
            contact_id: [payout.contact_id, []],
            deal_name: payout.deal_name,
            deal_id: payout.deal_id,
            is_dual_deal: [payout.is_dual_deal, []],
            check_number: ['', []],
            memo: [this.buildPayoutReference(payout), []],
            reference: [`Payment for deal# ${payout.deal_id}.\r\n${this.buildPayoutReference(payout)}`, []],
            remaining_balance: [payout.connected_invoice_id ? payout.pending_balance : payout.amount, []],
            connected_invoice_id: [payout.connected_invoice_id, []]
        });
        return group;
    }

    buildPayoutReference(payout: IClosingPayoutsWithAdditionalInfo) {
        let fullAddress = '';
        if (payout.deal_address) {
            if (payout.deal_street_number) {
                fullAddress += `${payout.deal_street_number} `;
            }
            fullAddress += `${payout.deal_address} `;
            if (payout.deal_unit) {
                fullAddress += `${payout.deal_unit}, `;
            }
            fullAddress += `${payout.deal_city}, `;
            fullAddress += `${payout.deal_state} `;
            fullAddress += payout.deal_zip;
        } else {
            fullAddress = '';
        }
        return fullAddress;
    }

    validateAmount($event: any, payoutGroup: UntypedFormGroup) {
        if (!payoutGroup) {
            return null;
        }
        const inputValue = Number(cleanCurrencyString($event.target.value));

        const availableAmount = payoutGroup.controls.remaining_balance.value;

        if (availableAmount < inputValue || (inputValue === 0 && availableAmount !== 0)) {
            payoutGroup.controls.amount.setValidators([Validators.email]);
            payoutGroup.controls.amount.updateValueAndValidity();
            payoutGroup.controls.amount.markAsTouched();
        } else {
            payoutGroup.controls.amount.clearValidators();
            payoutGroup.controls.amount.updateValueAndValidity();
        }
    }

    continue() {
        if (this.recordPaymentSettingsGroup.invalid || this.itemsArray.invalid) {
            this.itemsArray.markAllAsTouched();
            this.ledgerAccountPicker!.validateControl();
            this.recordPaymentSettingsGroup.markAllAsTouched();
            return;
        }

        const result = {
            processing_mode: 'record',
            record_payment_options: this.recordPaymentSettingsGroup.getRawValue(),
            payouts: this.itemsArray.getRawValue().map((item: any) => {
                if (isNaN(item.amount)) {
                    item.amount = Number(cleanCurrencyString(item.amount));
                }
                return item;
            })
        };

        this.dialogRef.close(result);
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
