import {filter, takeUntil} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Profile} from 'app/models/profile';
import {GenericFormArray} from 'app/entites/generic.entity';
import {Group} from 'app/models/group';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {GroupApi} from 'app/services/api/group.api';
import {MatDialog} from '@angular/material/dialog';
import {GroupsSource} from 'app/services/sources/groups.source';
import {FetchContactsMust} from '../../../contacts/store/contacts.actions';
import {Store} from '@ngrx/store';
import {AbstractControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {EditComponent} from '../common/edit/edit.component';

@Component({
    selector: 'app-company-groups-tab-1-org-chart',
    styles: [
        `
            .sidebar-body {
                width: 100%;
            }

            .sidebar-page {
                margin-top: 7px;
                height: 100%;
                width: 100%;
                overflow: auto;
            }

            .sidebar-wrapper {
                height: 100%;
                overflow-y: hidden;
                display: flex;
                flex-direction: column;
            }
        `
    ],
    templateUrl: `./tab1-org-chart.component.html`
})
export class Tab1OrgChartComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('divisionsSidebar') divisionsSidebar: EditComponent | undefined;

    isSidebarOpened: boolean = false;
    protected currentProfile: Profile = new Profile();
    public groupsList: Group[] = [];
    createMode: boolean = false;

    @Input() groupsListFA: GenericFormArray<Group> = new GenericFormArray([]);
    @Input() compensationCreateMode: boolean = false;
    @Input() enterCreationMode$: Subject<any> = new Subject<any>();
    @Output() createModeEmitter = new EventEmitter();

    constructor(
        public dialog: MatDialog,
        protected currentProfileSource: CurrentProfileSource,
        protected groupsService: GroupApi,
        protected groupSrc: GroupsSource,
        private store: Store<any>
    ) {
        this.currentProfileSource.changeProfileEvent
            .pipe(
                filter((profile) => profile.company !== null),
                takeUntil(this.unsubscribe)
            )
            .subscribe((profile) => (this.currentProfile = profile));

        this.groupSrc.source
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((list) => (this.groupsList = list.filter((group) => group.type === Group.type_SET.division)));
    }

    ngOnInit() {
        this.enterCreationMode$.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.enterCreationMode();
        });
    }

    enterCreationMode() {
        this.createMode = true;
        this.compensationCreateMode = false;
        this.createModeEmitter.emit(this.createMode);
    }

    enterCompensationCreationMode() {
        this.createMode = false;
        this.compensationCreateMode = true;
        this.createModeEmitter.emit(this.createMode);
    }

    exitCreationMode() {
        this.createMode = false;
        this.createModeEmitter.emit(this.createMode);
    }

    doCreate(newGroup: Group, parent: Group | null = null) {
        const data = {
            title: newGroup.title,
            email: newGroup.email,
            type: newGroup.type,
            permissions: newGroup.permissions,
            parent__group_fk_id: newGroup.parent__group_fk_id
        };

        // @todo: it should close create box, open newly created group and center the opened group on screen
        if (parent !== null) {
            return this.groupsService.clone({...data, parent}).then(this.handleSuccessCreation.bind(this));
        } else {
            return this.groupsService.create(data).then(this.handleSuccessCreation.bind(this));
        }
    }

    openSidenav(division: AbstractControl) {
        this.divisionsSidebar?.openGroup(division.get('id')?.value);
    }

    handleSuccessCreation(result: any) {
        this.createMode = false;
        this.createModeEmitter.emit(this.createMode);
        this.groupSrc.load(this.currentProfile);

        // update contact list after success
        this.store.dispatch(new FetchContactsMust());
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.enterCreationMode$.complete();
        this.createModeEmitter.complete();
    }
}
