<section class="container-fluid" *ngIf="garnishment">
    <mat-card class="p-5">
        <header class="row">
            <div class="col-6"></div>

            <div class="col-6 text-right">
                <p class="zp-payment_title">Garnishment</p>

                <p class="zp-refference_due_title">Amount</p>
                <p class="zp-refference_due_value">{{ garnishment?.amount | currency }}</p>

                <p class="zp-refference_due_title">Balance</p>
                <p class="zp-refference_due_value">
                    {{
                        (garnishment?.balance || garnishment?.balance === 0
                            ? garnishment?.balance
                            : garnishment?.amount
                        ) | currency
                    }}
                </p>

                <p class="mb-2">
                    <span>Issuance Date:</span>
                    <span class="zp-w-300">{{ garnishment?.date | date: 'longDate' }}</span>
                </p>

                <p class="mb-2">
                    <span>Contacts/User:</span>
                    <span class="zp-w-300">{{ garnishment?.receiver_contact?.display_name }}</span>
                </p>

                <p class="mb-2">
                    <span>Originator:</span>
                    <span class="zp-w-300">{{ garnishment?.sender_contact?.display_name }}</span>
                </p>

                <p class="mb-2">
                    <span>Product/Service:</span>
                    <span class="zp-w-300">{{ garnishment.product ? garnishment.product.name : '-' }}</span>
                </p>

                <p class="mb-2">
                    <span>Interest Rate:</span>

                    <span class="zp-w-300" *ngIf="garnishment?.interest_period === 'none'">None</span>
                    <span
                        class="zp-w-300"
                        *ngIf="garnishment?.interest_period !== 'none' && garnishment?.interest_rate_type === 'flat'"
                    >
                        {{ garnishment?.interest_rate_flat | currency }} {{ garnishment?.interestPeriod?.label }}
                        {{ garnishment?.interestLimit }}
                    </span>
                    <span
                        class="zp-w-300"
                        *ngIf="garnishment?.interest_period !== 'none' && garnishment?.interest_rate_type === 'percent'"
                    >
                        {{ garnishment?.interest_rate_percent }}% {{ garnishment?.interestPeriod?.label }}
                        {{ garnishment?.interestLimit }}
                    </span>
                </p>
            </div>
        </header>

        <!--        <div class="d-flex justify-content-between" style="margin-top: 15px;">-->
        <!--            <div class="d-flex flex-grow-1 justify-content-end">-->
        <!--                <button mat-raised-button class="ml-2" (click)="confirmPaid()">Record Payment</button>-->
        <!--                <button mat-raised-button class="ml-2" (click)="chargeCustomer()">Charge Customer</button>-->
        <!--            </div>-->
        <!--        </div>-->

        <div class="my-3">
            Related Invoices
            <button mat-icon-button (click)="showInvoices = !showInvoices">
                <mat-icon *ngIf="showInvoices">expand_more</mat-icon>
                <mat-icon *ngIf="!showInvoices">chevron_right</mat-icon>
            </button>

            <div *ngIf="showInvoices && dataSource.data && dataSource.data.length === 0">No related invoices.</div>

            <div *ngIf="showInvoices && dataSource.data && dataSource.data.length > 0">
                <table mat-table [dataSource]="dataSource" class="w-100">
                    <ng-container matColumnDef="invoice_id">
                        <th mat-header-cell *matHeaderCellDef>Invoice#</th>
                        <td mat-cell *matCellDef="let element">{{ element.invoice_number || 'N/A' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="invoice_date">
                        <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.invoice_date | dateFromNumber | date: 'mediumDate' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="summary_status">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element">{{ element.summary_status | fromSnakeCase }}</td>
                    </ng-container>

                    <ng-container matColumnDef="total_amount">
                        <th mat-header-cell *matHeaderCellDef style="text-align: end">Total Amount</th>
                        <td mat-cell *matCellDef="let element" style="text-align: end">
                            {{ element.total_amount | currency }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="balance">
                        <th mat-header-cell *matHeaderCellDef style="text-align: end">Balance</th>
                        <td mat-cell *matCellDef="let element" style="text-align: end">
                            {{ element.balance | currency }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedRelatedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedRelatedColumns" class="zp-table-row"></tr>
                </table>
            </div>
        </div>
    </mat-card>
</section>
