<div mat-dialog-title>
    <h3>Profiles</h3>
</div>

<mat-dialog-content>
    <section [formGroup]="formGroup">
        <div class="d-flex">
            <mat-form-field class="w-100 mt-2" [floatLabel]="'never'">
                <mat-label>Status</mat-label>
                <mat-select [formControl]="formGroup.controls.statuses" multiple>
                    <mat-option *ngFor="let status of profileFiltersData.statuses" [value]="status">
                        <span *ngIf="status !== 'no_access'">{{ status | fromSnakeCase }}</span>
                        <span *ngIf="status === 'no_access'">Not Invited</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="d-flex">
            <mat-form-field class="w-100 mt-2" [floatLabel]="'never'">
                <mat-label>Role</mat-label>
                <mat-select [formControl]="formGroup.controls.roles" multiple>
                    <mat-option *ngFor="let role of profileFiltersData.roles" [value]="role['roles_id']">
                        {{ role.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="d-flex">
            <mat-form-field class="w-100 mt-2" [floatLabel]="'never'">
                <mat-label>Division</mat-label>
                <mat-select [formControl]="formGroup.controls.divisions" multiple>
                    <mat-option
                        *ngFor="let division of profileFiltersData.divisions"
                        [value]="division.company_group_id"
                    >
                        {{ division.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="d-flex">
            <mat-form-field class="w-100 mt-2" [floatLabel]="'never'">
                <mat-label>Group</mat-label>
                <mat-select [formControl]="formGroup.controls.groups" multiple>
                    <mat-option *ngFor="let group of profileFiltersData.groups" [value]="group.company_group_id">
                        {{ group.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </section>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex w-100 my-3">
        <button mat-raised-button color="warn" tabindex="-1" (click)="resetFilters()">Reset</button>
        <span class="sp-spacer"></span>
        <button mat-raised-button class="mr-2" (click)="dialogRef.close(false)" tabindex="-1">Cancel</button>
        <button mat-raised-button color="primary" (click)="applyFilters()" tabindex="1">Apply</button>
    </div>
</mat-dialog-actions>
