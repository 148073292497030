import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Deal, DEAL_SYSTEM_STATUS} from '../../../../../models/deal';
import {MatDialog} from '@angular/material/dialog';
import {CommonListOfFieldsDialogComponent} from './common-list-of-fields-dialog/common-list-of-fields-dialog.component';
import {GenericFormGroup} from '../../../../../entites/generic.entity';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FinancialNode} from '../../../../shared/components/financial-node/financial-node.model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {ICompanyWideState} from '../../../../../store/company-wide/company-wide.reducer';
import {selectProcessedSOB} from '../../../../../store/company-wide/company-wide.selectors';
import {UpdateSOB} from '../../../../../store/company-wide/company-wide.actions';
import {IDealParticipants} from '../../../../../../typings';
import {IContactPartLink} from '@cyberco-nodejs/zipi-typings/contacts.typings';
import {currencyMaskitoOptions, unmaskCurrencyControlValue} from '../../../../../utilities/maskito';

export class CustomFormGroup extends UntypedFormGroup {
    public controls: {
        commission_payer_id: UntypedFormControl;
    };

    constructor(controls: {commission_payer_id: UntypedFormControl}) {
        super(controls);
        this.controls = controls;
    }
}

@Component({
    selector: 'app-edit-deal-transaction',
    styleUrls: ['transaction.component.scss', 'deal-creation.scss'],
    templateUrl: 'transaction.component.html'
})
export class EditDealTransactionComponent implements OnInit, OnDestroy, OnChanges {
    DEAL_SYSTEM_STATUS = DEAL_SYSTEM_STATUS;
    private unsubscribe: Subject<void> = new Subject();
    @Input() dealFormGroup: GenericFormGroup<Deal> = new GenericFormGroup(new Deal(), 'change');
    @Input() canCreateDualDeal = false;
    @Input() dealId: string | null = null;
    @Input() dealSnapshot: Deal | undefined;
    @Input() afterDealSaving$: Subject<string> = new Subject<string>();
    @Input() dealParticipants: IDealParticipants | undefined | null;
    @Input() checkCommissionPayer$: Subject<any> = new Subject<any>();
    @Input() isDealSaveInProgress: boolean = false;
    @Output() saveDealChanges = new EventEmitter<string>();

    adjustedSalesPrice: GenericFormGroup<FinancialNode> = new GenericFormGroup<FinancialNode>(new FinancialNode());
    baseCommission: GenericFormGroup<FinancialNode> = new GenericFormGroup<FinancialNode>(new FinancialNode());
    grossCommission: GenericFormGroup<FinancialNode> = new GenericFormGroup<FinancialNode>(new FinancialNode());
    editableBaseCommissionNode: GenericFormGroup<FinancialNode> = new GenericFormGroup<FinancialNode>(
        new FinancialNode().setLabel('Base Commission')
    );

    minCloseOfEscrowDate;
    maxCloseOfEscrowDate;
    currencyMaskitoMask = currencyMaskitoOptions;

    DEAL_CLIENT_TYPES = Deal.deal_client_type_SET;
    DEAL_TRANSACTION_TYPES = Deal.type_SET;

    DEAL = Deal;
    isSalesPriceAdjustmentOpened: boolean | undefined;
    setValidatorsOnInitMarker: boolean = true;

    sendersFromDealParticipants: IContactPartLink[] = [];
    commissionPayerFG: CustomFormGroup = new CustomFormGroup({
        commission_payer_id: new UntypedFormControl(null)
    });
    isDefaultSourceOfBusinessList: boolean = false;

    public sourcesOfBusiness: {
        label: string;
        value: string;
    }[] = [];

    ngOnInit() {
        this.dealFormGroup
            .get('sales_price')!
            .valueChanges.pipe(
                unmaskCurrencyControlValue(this.dealFormGroup.get('sales_price')!),
                takeUntil(this.unsubscribe)
            )
            .subscribe((newSalesPrice) => {
                const newAdjustedSalesPrice =
                    Number(newSalesPrice) + Number(this.dealFormGroup.getRawValue().sales_price_adjustment);
                this.adjustedSalesPrice.controls.amount!.patchValue(newAdjustedSalesPrice, {emitEvent: false});
            });

        this.dealFormGroup
            .get('sales_price_adjustment')!
            .valueChanges.pipe(
                unmaskCurrencyControlValue(this.dealFormGroup.get('sales_price_adjustment')!),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.dealFormGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((deal) => {
            if (typeof this.isSalesPriceAdjustmentOpened === 'undefined') {
                this.isSalesPriceAdjustmentOpened = !!this.dealFormGroup.getRawValue().sales_price_adjustment;
                const newAdjustedSalesPrice =
                    Number(this.dealFormGroup.getRawValue().sales_price) +
                    Number(this.dealFormGroup.getRawValue().sales_price_adjustment);
                this.adjustedSalesPrice.controls.amount!.patchValue(newAdjustedSalesPrice, {emitEvent: false});
            }

            if (this.dealFormGroup.controls.deal_participants?.disabled) {
                this.commissionPayerFG.controls.commission_payer_id.disable({emitEvent: false});
            }
        });

        this.store.pipe(select(selectProcessedSOB), takeUntil(this.unsubscribe)).subscribe((sobs) => {
            this.sourcesOfBusiness = sobs.sourceList.map((sob) => {
                return {
                    value: sob.label!,
                    label: sob.label!
                };
            });

            this.isDefaultSourceOfBusinessList = sobs.isDefault;
        });

        this.checkCommissionPayer$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.commissionPayerFG.get('commission_payer_id')!.setValidators([Validators.required]);
            this.commissionPayerFG.get('commission_payer_id')!.markAllAsTouched();
            this.commissionPayerFG.get('commission_payer_id')!.updateValueAndValidity();
        });

        // works always except initialisation on editing deal
        this.dealFormGroup.controls.deal_participants!.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.updateCommissionPayerList();
        });
    }

    constructor(
        public dialog: MatDialog,
        private store: Store<ICompanyWideState>
    ) {
        const currentYear = new Date().getFullYear();
        this.minCloseOfEscrowDate = new Date(currentYear - 20, 0, 1);
        this.maxCloseOfEscrowDate = new Date(currentYear + 10, 11, 31);
    }

    ngOnChanges(changes: SimpleChanges) {
        // set required only if deal.system_status = draft
        // set here not to emit event
        if (
            this.setValidatorsOnInitMarker &&
            changes.dealFormGroup &&
            changes.dealFormGroup.currentValue.value.system_status !== DEAL_SYSTEM_STATUS.draft
        ) {
            this.setValidatorsOnInitMarker = false;
            this.dealFormGroup.controls.sales_price!.setValidators(Validators.required);
            this.dealFormGroup.controls.type!.setValidators(Validators.required);
            this.dealFormGroup.controls.close_of_escrow!.setValidators(Validators.required);
        }

        // works on initialisation on editing deal
        if (changes.dealParticipants && this.sendersFromDealParticipants.length === 0) {
            this.updateCommissionPayerList();
        }
    }

    // @ts-ignore
    dealTransactionTypeChanged({value}) {
        const clientType = this.dealFormGroup.get('client_type')!.value;

        if (!clientType) {
            switch (value) {
                case this.DEAL_TRANSACTION_TYPES.seller: {
                    this.dealFormGroup.get('client_type')!.patchValue(this.DEAL_CLIENT_TYPES.seller);
                    this.dealFormGroup.markAsDirty();
                    break;
                }
                case this.DEAL_TRANSACTION_TYPES.buyer: {
                    this.dealFormGroup.get('client_type')!.patchValue(this.DEAL_CLIENT_TYPES.buyer);
                    this.dealFormGroup.markAsDirty();
                    break;
                }
                default:
                    break;
            }
        }
    }

    async openDialog() {
        const dialogRef = this.dialog.open(CommonListOfFieldsDialogComponent, {
            minWidth: 500,
            data: {
                type: 'SourceOfBusiness',
                list: this.sourcesOfBusiness
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((list) => {
                if (!list) {
                    // dialog was just closed, no need to save anything
                    return;
                }
                this.sourcesOfBusiness = list.reduce((acc: {label: any; value: any}[], cur: {label: any}) => {
                    acc.push({label: cur.label, value: cur.label});
                    return acc;
                }, []);

                this.store.dispatch(
                    new UpdateSOB(
                        list.reduce((acc: {label: any}[], cur: {label: any}) => {
                            acc.push({label: cur.label});
                            return acc;
                        }, [])
                    )
                );
            });
    }

    object_keys(obj: object) {
        return Object.keys(obj);
    }

    selectCommissionPayer() {
        const selectedDealParticipant = this.sendersFromDealParticipants.find(
            (p) => p.contact_id === this.commissionPayerFG.value.commission_payer_id
        );
        if (!selectedDealParticipant) {
            throw new Error('something went wrong');
        }
        this.dealFormGroup.get('deal_participants')!.get('commission_payer')!.patchValue(null);
        this.dealFormGroup.get('deal_participants')!.get('commission_payer')!.patchValue({
            contact_id: selectedDealParticipant.contact_id,
            contact_person_fk_id: selectedDealParticipant.contact_person_fk_id,
            link_title: selectedDealParticipant.link_title
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.checkCommissionPayer$.complete();
        this.afterDealSaving$.complete();
        this.saveDealChanges.complete();
    }

    updateCommissionPayerList() {
        const participants = this.dealFormGroup.controls.deal_participants!.getRawValue();
        this.sendersFromDealParticipants = [];

        if (participants.escrow_agent && participants.escrow_agent.format !== 'text') {
            this.sendersFromDealParticipants.push(participants.escrow_agent);
        }
        const sellers = participants.sellers ? participants.sellers : [];
        const buyers = participants.buyers ? participants.buyers : [];
        if (sellers.length || buyers.length) {
            [...sellers, ...buyers].forEach((seller) => {
                if (seller && seller.format === 'text') {
                    return;
                } // use only 'contact' participants

                if (!this.sendersFromDealParticipants.find((p) => p.contact_id === seller.contact_id)) {
                    this.sendersFromDealParticipants.push(seller);
                }
            });
        }

        // unset canceled value
        if (
            !this.sendersFromDealParticipants
                .map((p) => p.contact_id)
                .includes(this.commissionPayerFG.get('commission_payer_id')!.value)
        ) {
            this.commissionPayerFG.get('commission_payer_id')!.patchValue(null);
        }

        // set initial value
        if (participants['commission_payer'] && !this.commissionPayerFG.get('commission_payer_id')!.value) {
            this.commissionPayerFG.get('commission_payer_id')!.patchValue(participants['commission_payer'].contact_id);
        }

        // set commission_payer if it is empty and escrow company is not
        const escrowAgent = this.dealFormGroup.controls.deal_participants!.get('escrow_agent')!.value;
        if (
            !this.dealFormGroup.controls.deal_participants!.get('commission_payer')!.value &&
            escrowAgent &&
            escrowAgent.format !== 'text'
        ) {
            this.dealFormGroup
                .get('deal_participants')!
                .get('commission_payer')!
                .patchValue({
                    contact_id: this.dealFormGroup.controls.deal_participants!.get('escrow_agent')!.value.contact_id,
                    contact_person_fk_id:
                        this.dealFormGroup.controls.deal_participants!.get('escrow_agent')!.value.contact_person_fk_id,
                    link_title: this.dealFormGroup.controls.deal_participants!.get('escrow_agent')!.value.link_title
                });
        }
    }

    changePriceAdjustment() {
        this.isSalesPriceAdjustmentOpened = !this.isSalesPriceAdjustmentOpened;
        if (!this.isSalesPriceAdjustmentOpened) {
            this.dealFormGroup.controls.sales_price_adjustment!.patchValue(null);
            this.dealFormGroup.controls.sales_price_adjustment_description!.patchValue(null, {emitEvent: false});
        }
    }
}
