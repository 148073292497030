<div *ngIf="!selectedGroup">
    <div class="popup-header">
        <h4 style="margin-bottom: 0">Multiple Payments Required</h4>
        <span class="sp-spacer"></span>
        <mat-icon class="sp-cursor-pointer" (click)="close()">close</mat-icon>
    </div>

    <div class="popup-content">
        <div>
            {{ brokerName }} requires that this Statement to be paid via multiple payments. You may select the same bank
            to pay these different amounts.
        </div>
        <div>Please pay each item below to fully pay your statement balance.</div>

        <div class="mt-3">
            <div class="group-header">
                <div class="w-5">#</div>
                <div class="w-30">Payments</div>
                <div class="w-35">Payment Amount</div>
                <div class="w-30"></div>
            </div>
            <div>
                <div *ngFor="let group of invoiceGroups; let i = index">
                    <div class="group-item">
                        <div class="w-5">{{ i + 1 }}</div>
                        <div class="w-30">Payment #{{ i + 1 }}</div>
                        <div class="w-35">{{ group.total_pending_amount | currency }}</div>
                        <div class="w-30 text-right">
                            <button mat-raised-button color="primary" (click)="selectGroup(group)">Make Payment</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex" style="margin-top: 50px">
            <div>Remaining Balance:</div>
            <span class="sp-spacer"></span>
            <div class="font-weight-bold">{{ remainingAmount | currency }}</div>
        </div>
    </div>

    <div class="popup-actions">
        <span class="sp-spacer"></span>
        <button mat-raised-button (click)="close()">Cancel</button>
    </div>
</div>

<div *ngIf="selectedGroup">
    <div class="popup-header">
        <h4 style="margin-bottom: 0">Review & Pay</h4>
        <span class="sp-spacer"></span>
        <mat-icon class="sp-cursor-pointer" (click)="close()">close</mat-icon>
    </div>

    <div class="popup-content">
        <div>Please add how much you would like to pay at this time.</div>

        <div class="input-section">
            <mat-form-field>
                <input
                    matInput
                    type="text"
                    placeholder="Payment"
                    [formControl]="paymentAmount"
                    [maskito]="currencyMaskitoMask"
                    (keyup)="validateAmount($event)"
                />
            </mat-form-field>

            <div>
                <mat-radio-group aria-label="Select an option" (change)="changeAmount($event)">
                    <mat-radio-button value="full" [checked]="true">Full amount</mat-radio-button>
                    <mat-radio-button value="custom">Custom amount</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="d-flex mt-3">
            <div>Remaining Balance:</div>
            <span class="sp-spacer"></span>
            <div class="font-weight-bold">{{ selectedGroup.total_pending_amount | currency }}</div>
        </div>

        <div class="custom-amount-info" *ngIf="!paymentAmount.disabled">
            <mat-icon>info_outline</mat-icon>
            <span>This will automatically be applied to your oldest invoice first.</span>
        </div>
    </div>

    <div class="popup-actions">
        <button mat-raised-button *ngIf="invoiceGroups.length > 1" (click)="back()">Back</button>
        <span class="sp-spacer"></span>
        <button mat-raised-button (click)="close()">Cancel</button>
        <button class="ml-2" mat-raised-button color="primary" (click)="makePayment()">Make Payment</button>
    </div>
</div>
