import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmComponent} from '../layouts/confirm/confirm.component';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RootDialogService {
    constructor(public dialog: MatDialog) {}

    confirm(title: string = 'Leave the page?', message: string = ''): Observable<boolean> {
        // const confirmation = window.confirm(message || 'Ok');
        // return of(confirmation);
        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            data: {
                title,
                message
            }
        });
        return dialogRef.afterClosed().pipe(map((v) => !!v));
    }
}
