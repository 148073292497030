import {Injectable} from '@angular/core';
import {IReport} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class ReportsLocalService {
    reportData: IReport | null = null;
    reportEntityData: IReport | null = null;
    reportPreviewQueryParams: {[key: string]: string | number} = {};

    constructor() {}

    setReportPreviewQueryParams(data: {[key: string]: string | number}) {
        this.reportPreviewQueryParams = data;
    }

    getReportPreviewQueryParams() {
        return this.reportPreviewQueryParams;
    }

    setReportData(data: IReport) {
        this.reportData = data;
    }

    getReportData(): IReport | null {
        return this.reportData;
    }

    setReportEntityData(data: IReport) {
        this.reportEntityData = data;
    }

    getReportEntityData() {
        return this.reportEntityData;
    }
}
