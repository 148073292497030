export class DealsSummary {
    sum_of_sales_price: number = 0;
    count_of_deals: number = 0;
    total_company_split: number = 0;
    sum_of_gross_commission: number = 0;
    total_agent_split: number = 0;
    total_royalty_split: number = 0;
    total_product_values: {[keys: string]: {[keys: string]: number}} | undefined = undefined;
    agent_metrics_values: {[keys: number]: number} | undefined = undefined;
}
