import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl} from '@angular/forms';
import {Deal} from '../../../../../../models/deal';

@Component({
    selector: 'app-set-dual',
    templateUrl: 'set-dual.component.html',
    styleUrls: ['set-dual.component.scss']
})
export class SetDualComponent implements OnInit {
    DEAL = Deal;
    creationMode: string = 'new';
    dealFormControl: UntypedFormControl = new UntypedFormControl();
    addonsOptions: any[] = [];
    currentDealId: number | undefined;
    type: string | undefined;
    oppositeType: string | null = null;
    disallowedDealTypes: string[] = [];

    constructor(
        public dialogRef: MatDialogRef<SetDualComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.currentDealId = data.dealId;
        this.type = data.type;
        this.oppositeType = Deal.getOppositeDealType(this.type!);
        // @ts-ignore
        this.disallowedDealTypes = Object.keys(Deal.type_SET)
            .map((typeKey) => Deal.type_SET[typeKey])
            .filter((type) => type !== this.oppositeType);
    }

    ngOnInit() {
        if (this.data && this.data.addonsOptions && this.data.addonsOptions.length) {
            this.addonsOptions = this.data.addonsOptions;
            // console.info(this.addonsOptions);
        }
    }

    save() {
        if (!this.creationMode) {
            return;
        }
        if (this.creationMode === 'existed' && !this.dealFormControl.value) {
            return;
        }

        const data = {
            creation_mode: this.creationMode,
            deal_id: this.dealFormControl.value
        };

        this.dialogRef.close(data);
    }
}
