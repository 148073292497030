import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NotificationsService} from 'angular2-notifications';
import {ITransactionExternal} from 'typings';
import {GenericFormArray} from '../../../../../entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {ContactCreateDialogComponent} from '../../../../contacts/contact-dialogs/contact-create-dialog/contact-create-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {LedgerAccountTypes} from '@cyberco-nodejs/zipi-typings';
import * as moment from 'moment';
import {amountValidator} from '../../../../../utilities';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';

export class CustomFormGroup extends UntypedFormGroup {
    public controls: {
        expense_date: UntypedFormControl;
        expense__ledger_account_fk_id: UntypedFormControl;
        amount: UntypedFormControl;
        expense_vendor__contact_fk_id: UntypedFormControl;
        expense_note: UntypedFormControl;
    };

    constructor(controls: {
        expense_date: UntypedFormControl;
        expense__ledger_account_fk_id: UntypedFormControl;
        amount: UntypedFormControl;
        expense_vendor__contact_fk_id: UntypedFormControl;
        expense_note: UntypedFormControl;
    }) {
        super(controls);
        this.controls = controls;
    }
}

@Component({
    selector: 'app-form-expense',
    template: `
        <div [formGroup]="formGroup">
            <app-date-picker [placeholder]="'Date'" [dateControl]="formGroup.controls.expense_date"> </app-date-picker>

            <app-ledger-account-selector
                [ledgerAccountControl]="formGroup.controls.expense__ledger_account_fk_id"
                [types]="types"
                [placeholder]="'Expense Account'"
            >
            </app-ledger-account-selector>

            <mat-form-field class="w-100 align-items-baseline">
                <input
                    [maskito]="currencyMaskitoMask"
                    matInput
                    type="text"
                    formControlName="amount"
                    matTooltipPosition="above"
                    placeholder="Amount"
                    autocomplete="off"
                    required
                />
            </mat-form-field>

            <div class="d-flex align-items-baseline">
                <app-company-compensation-combined-picker
                    style="width:100%; margin-right: 8px;"
                    [availableTypes]="['contact']"
                    [title]="'Vendor Name *'"
                    [only_compensation_groups]="false"
                    [singleSelected]="true"
                    [bold_style]="false"
                    [nodesFA]="moneySenderCtrlArr"
                >
                </app-company-compensation-combined-picker>
                <button type="button" mat-icon-button matSuffix (click)="contactCreate()" matTooltip="Create Contact">
                    <mat-icon>person_add</mat-icon>
                </button>
            </div>

            <mat-form-field class="w-100">
                <textarea matInput matTextareaAutosize formControlName="expense_note" placeholder="Expense Notes">
                </textarea>
            </mat-form-field>
        </div>

        <div class="footer-row">
            <button
                mat-raised-button
                class="mat-primary"
                [disabled]="!formGroup.valid || createDisabled || !ledgerAccountId"
                (click)="save()"
            >
                Create
            </button>
            <button mat-button class="mat-primary" (click)="closeSidebar.emit()">Cancel</button>
        </div>
    `
})
export class FormExpenseComponent implements OnInit, OnDestroy {
    @Output() closeSidebar: EventEmitter<void> = new EventEmitter<void>();
    @Output() create: EventEmitter<object> = new EventEmitter<object>();
    @Input() createDisabled: boolean = false;
    @Input() bankTransaction: ITransactionExternal | undefined;
    @Input() ledgerAccountId: number | null = null;

    private unsubscribe: Subject<void> = new Subject();

    formGroup: CustomFormGroup = new CustomFormGroup({
        expense_date: new UntypedFormControl(null, Validators.required),
        expense__ledger_account_fk_id: new UntypedFormControl(null, Validators.required),
        amount: new UntypedFormControl(null, [Validators.required, amountValidator()]),
        expense_vendor__contact_fk_id: new UntypedFormControl(null, Validators.required),
        expense_note: new UntypedFormControl(null, [])
    });
    initFormGroupSnapshot = {};
    currencyMaskitoMask = currencyMaskitoOptions;

    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    types: LedgerAccountTypes[] = [
        'cost_of_goods_sold',
        'expense',
        'other_expense',
        'long_term_liability',
        'other_current_liability',
        'fixed_asset',
        'other_current_asset'
    ];

    constructor(
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        if (this.bankTransaction) {
            this.formGroup.get('amount')?.patchValue(this.bankTransaction.amount);
            this.formGroup.controls.amount.disable();
            this.formGroup.controls.expense_date.patchValue(this.bankTransaction.date);
        } else {
            this.formGroup.controls.expense_date.patchValue(moment().format('YYYYMMDD'));
        }

        this.initFormGroupSnapshot = this.formGroup.getRawValue();

        this.moneySenderCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            if (values && values.length > 0) {
                // this.selectMoneySender(values[0].target_id);
                this.formGroup.controls.expense_vendor__contact_fk_id.patchValue(values[0].target_id);
            } else {
                // this.deselectCustomer();
                this.formGroup.controls.expense_vendor__contact_fk_id.patchValue(null);
            }
        });
    }

    save() {
        if (this.formGroup.invalid || this.createDisabled) {
            return this.ntfs.warn('Bill Form is not valid');
        }

        const expense = this.formGroup.getRawValue();

        this.create.emit({
            ...expense,
            amount: Number(cleanCurrencyString(expense.amount)),
            paid_through__ledger_account_fk_id: this.ledgerAccountId
        });
    }

    contactCreate() {
        const dialogRef = this.dialog.open(ContactCreateDialogComponent, {
            autoFocus: false,
            data: {category: 'vendor'}
        });

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.closeSidebar.complete();
        this.create.complete();
    }
}
