import {Component} from '@angular/core';
import {SessionService} from '../../../services/session.service';
import {InitialSetupService} from '../../auth/welcome/initial-setup.service';
import {CompanyGoalsFormGroup} from '../common/model';

@Component({
    selector: 'goals',
    template: `
        <div class="container">
            <mat-card>
                <mat-card-content>
                    <app-company-goals [formGroup]="companyGoalsForm"></app-company-goals>
                </mat-card-content>
                <mat-card-actions>
                    <button
                        mat-button
                        class="mat-primary"
                        [disabled]="!companyGoalsLoaded"
                        (click)="saveCompanyGoals()"
                    >
                        SAVE
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
    `,
    styleUrls: ['../goals.component.scss']
})
export class IndexComponent {
    public companyGoalsForm: CompanyGoalsFormGroup = new CompanyGoalsFormGroup();
    companyGoalsLoaded: boolean = false;
    companyGoalsSaved: boolean = true;

    constructor(
        protected sessionService: SessionService,
        protected initialSetupService: InitialSetupService
    ) {
        this.loadCompanyGoals();
    }

    loadCompanyGoals() {
        return this.initialSetupService.api.getCompanyGoals().then((value) => {
            this.companyGoalsForm.patchValue(value);
            this.companyGoalsLoaded = true;
        });
    }

    saveCompanyGoals() {
        this.initialSetupService.api.postCompanyGoals(this.companyGoalsForm.getRawValue()).then((ok) => {
            this.loadCompanyGoals().then(() => {
                this.companyGoalsSaved = true;
            });
        });
    }
}
