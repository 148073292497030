import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {NotificationsServiceZipi} from 'app/modules/notifications/notifications.service';
import {MatDialog} from '@angular/material/dialog';
import {ForgotPasswordComponent} from '../forgot-password/forgot-password.component';
import {SessionService} from '../../../services/session.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SkyslopeAuth} from '../services/okta/skyslope-auth.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    private redirectParamUrl: string | null = null;
    private performOktaConversion = false;

    constructor(
        public authService: AuthService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public sessionService: SessionService,
        private notificationsService: NotificationsServiceZipi,
        private skyslopeAuthService: SkyslopeAuth,
        public dialog: MatDialog
    ) {
        this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params.url) {
                this.redirectParamUrl = params.url;
            }
        });
    }

    ngOnInit() {
        this.authService.logout();
        this.skyslopeAuthService.clearSession();
    }

    onSubmit(form: NgForm) {
        if (!form.valid) {
            return false;
        }

        return this.authService
            .signInWithEmailAndPassword(form.value.email, form.value.password)
            .then((firebaseUserInfo) => {
                return this.onSuccessfulLogin();
            })
            .then((isUserLoaded) => {
                const email = this.authService.currentFirebaseUser?.email;
                const isSkySlopeUser = /@skyslope.com\s*$/.test(`${email}`);

                // using an environment variable as a flag instead of FlagSmith, since FlagSmith isn't initialized
                // yet at this point in the application.
                if (environment.useOktaConversion && !isSkySlopeUser) {
                    window.location.href = '/login/skyslope-conversion';
                    return;
                }

                if (!isUserLoaded) {
                    this.authService.logout();
                    return;
                }

                // redirect back to invite page
                if (
                    this.sessionService.redirectParams.navigateAfterAuthTo &&
                    this.sessionService.redirectParams.navigateAfterAuthTo.length > 0
                ) {
                    this.router.navigate(this.sessionService.redirectParams.navigateAfterAuthTo);
                    return;
                }

                const zipi_redirect_path = localStorage.getItem('zipi_redirect_path');
                // if (this.redirectParamUrl && /^https:\/\/community\.zipi\.app/.test(this.redirectParamUrl)) {
                //     window.location.href = this.sessionService.linkToCommunity;
                // } else
                if (zipi_redirect_path) {
                    localStorage.removeItem('zipi_redirect_path');
                    this.router.navigate([zipi_redirect_path]);
                } else {
                    this.router.navigate(['/default-page']);
                }

                return;
            })
            .catch((err) => {
                if (typeof err === 'string') {
                    let errorMessage = err;
                    switch (err) {
                        case 'User does not exist':
                            errorMessage = 'Username/Email not found';
                            break;
                    }
                    this.notificationsService.addError(errorMessage);
                } else if (err.message) {
                    this.notificationsService.addError(err.message);
                }
            });
    }

    tryGoogleAuth() {
        return this.authService
            .signInWithGoogle()
            .then((user) => {
                const isNewUser = user.additionalUserInfo!.isNewUser;
                if (isNewUser) {
                    user.user!.delete();
                    this.notificationsService.addError(`User does not exist in SkySlope Books`);
                } else {
                    return this.onSuccessfulLogin();
                }
            })
            .then((isUserLoaded) => {
                const email = this.authService.currentFirebaseUser?.email;
                const isSkySlopeUser = /@skyslope.com\s*$/.test(`${email}`);

                // using an environment variable as a flag instead of FlagSmith, since FlagSmith isn't initialized
                // yet at this point in the application.
                if (environment.useOktaConversion && !isSkySlopeUser) {
                    window.location.href = '/login/skyslope-conversion';
                    return;
                }

                if (!isUserLoaded) {
                    this.authService.logout();
                    return;
                }

                const zipi_redirect_path = localStorage.getItem('zipi_redirect_path');

                // if (this.redirectParamUrl && /^https:\/\/community\.zipi\.app/.test(this.redirectParamUrl)) {
                //     window.location.href = this.sessionService.linkToCommunity;
                // } else
                if (zipi_redirect_path) {
                    localStorage.removeItem('zipi_redirect_path');
                    window.location.replace(zipi_redirect_path);
                } else {
                    window.location.replace('/default-page');
                }
                return;
            })
            .catch((err) => {
                if (typeof err === 'string') {
                    let errorMessage = err;
                    switch (err) {
                        case 'User does not exist':
                            errorMessage = 'Username/Email not found';
                            break;
                    }
                    this.notificationsService.addError(errorMessage);
                } else if (err.message) {
                    this.notificationsService.addError(err.message);
                }
            });
    }

    async onSuccessfulLogin() {
        if (this.activatedRoute.snapshot.params.login_as_uid) {
            this.authService.setLoginAsUID(this.activatedRoute.snapshot.params.login_as_uid);
        }

        return await this.authService.loadAuth();
    }

    signInWithSkyslope() {
        this.router.navigate(['/login/skyslope']);
    }

    forgotPassword() {
        const dialogRef = this.dialog.open(ForgotPasswordComponent);

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
