import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {LicensesService} from '../../../../services/licenses.service';

export interface IInputDialogData {
    title: string;
    placeholders: string[];
    values?: {companyId: number};
}

@Component({
    selector: 'app-reloads-history',
    templateUrl: 'reloads-history-dialog.component.html'
})
export class ReloadsHistoryDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    private unsubscribe: Subject<void> = new Subject();
    @ViewChild(MatSort, {static: true}) sort: MatSort = new MatSort();
    @ViewChild('reloadLicensesSection', {static: false}) reloadLicensesSection: ElementRef | undefined;
    reloadLicensesContentHolder: any;

    dataSource: MatTableDataSource<any>;
    displayedColumns = ['created_at', 'error_details', 'status', 'used_billing_settings'];

    pageSize = 10;
    pageIndex = 0;
    sort_column: string = 'created_at';
    sort_direction: string = 'desc';
    lastQueryFilter: object = {
        limit: 0,
        offset: 0,
        sort_column: '',
        sort_direction: ''
    };

    constructor(
        public dialogRef: MatDialogRef<ReloadsHistoryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IInputDialogData,
        private licensesService: LicensesService
    ) {
        this.dataSource = new MatTableDataSource<any>([]);
    }

    ngOnInit() {
        this.sort.active = 'created_at';
        this.sort.direction = 'desc';
        this.sort_column = this.sort.active;
        this.sort_direction = this.sort.direction;
    }

    ngAfterViewInit() {
        if (this.reloadLicensesSection) {
            this.reloadLicensesContentHolder = this.reloadLicensesSection.nativeElement;
        }
        this.initFetch();
    }

    initFetch() {
        this.pageIndex = 0;

        this.fetchNextPartOfReloadLicenses();
        this.addScrollListener();
    }

    fetchNextPartOfReloadLicenses() {
        this.lastQueryFilter = {
            offset: Number(this.pageSize * this.pageIndex),
            limit: Number(this.pageSize),
            sort_column: this.sort_column,
            sort_direction: this.sort_direction
        };

        this.licensesService
            .getLicensesReloadHistoryWithParams(this.data?.values?.companyId!, this.lastQueryFilter)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (this.pageIndex === 0) {
                    this.dataSource.data = result;
                } else {
                    this.dataSource.data = this.dataSource.data.concat(result);
                }

                if (result.length < this.pageSize) {
                    this.reloadLicensesContentHolder.removeAllListeners();
                }
            });
    }

    nextPageFetch() {
        this.pageIndex = this.pageIndex + 1;
        this.fetchNextPartOfReloadLicenses();
    }

    addScrollListener() {
        this.reloadLicensesContentHolder.removeAllListeners();
        this.reloadLicensesContentHolder.addEventListener('scroll', (event: any) => {
            // note: do "-10" correction to care about "almost on bottom" case
            if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 10) {
                this.nextPageFetch();
            }
        });
    }

    onSortChange() {
        this.sort_column = this.sort.active;
        this.sort_direction = this.sort.direction;
        this.initFetch();
    }

    makeArray(object: {[key: string]: any}) {
        return Object.entries(object);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
