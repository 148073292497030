<div>
    <div
        *ngIf="reportData.listOfRecurringInvoices?.length && reportData.userData?.length && reportData.totalInvoiceData"
        class="tables"
    >
        <table mat-table class="w-100" [dataSource]="reportData.userData">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.profile.first_name }} {{ element.profile.last_name }}
                </td>
                <td mat-footer-cell *matFooterCellDef>Total Received:</td>
            </ng-container>
            <ng-container matColumnDef="totalOutstanding">
                <td mat-footer-cell *matFooterCellDef class="table-footer-row-title">Total Outstanding:</td>
            </ng-container>
            <ng-container matColumnDef="totalReceived">
                <td mat-footer-cell *matFooterCellDef class="table-footer-row-title">Total Received:</td>
            </ng-container>
            <ng-container [matColumnDef]="column.value" *ngFor="let column of tableColumns">
                <th mat-header-cell *matHeaderCellDef>
                    {{ column.title }} {{ months[column.date.getMonth()] }} {{ column.date.getDate() }},
                    {{ column.date.getFullYear() }} {{ column.data.total_amount | currency }}
                </th>
                <td mat-cell *matCellDef="let element" class="td-cell">
                    <a [routerLink]="['/sales/invoices/', element[column.value].invoice_id]">{{
                        element[column.value].summary_status
                    }}</a>
                </td>
            </ng-container>
            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerOutstandingColumns">
                <td mat-footer-cell *matFooterCellDef>
                    {{ this.reportData.totalInvoiceData[column.id].totalOutstanding | currency }}
                </td>
            </ng-container>
            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerReceivedColumns">
                <td mat-footer-cell *matFooterCellDef>
                    {{ this.reportData.totalInvoiceData[column.id].totalReceived | currency }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="allTableColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: allTableColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="allFooterOutstandingColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="allFooterReceivedColumns"></tr>
        </table>
    </div>
    <mat-card
        class="preview-body"
        *ngIf="
            !(reportData.listOfRecurringInvoices?.length && reportData.userData?.length && reportData.totalInvoiceData)
        "
    >
        <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
    </mat-card>
</div>
