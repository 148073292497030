import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../auth.service';
import {Profile} from '../../models/profile';
import {CurrentProfileSource} from '../sources/current-profile.source';

@Injectable()
export class DenyGlobalViewResolver implements Resolve<{}> {
    routerStateSnapshot: RouterStateSnapshot | null = null;

    constructor(
        @Inject(AuthService) protected authService: AuthService,
        @Inject(CurrentProfileSource) protected profileSource: CurrentProfileSource,
        protected router: Router
    ) {
        this.profileSource.changeProfileEvent.subscribe((profile: Profile) => {
            if (profile.type === Profile.type.global) {
                this.router.navigate(['/default-page']);
            }
        });
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        this.routerStateSnapshot = state;

        return this.load();
    }

    load() {
        return this.authService.getCurrentProfile().then((currentProfile) => {
            if (!currentProfile || currentProfile.type === 'global') {
                this.router.navigate(['/default-page']);

                return;
            }

            return true;
        });
    }
}
