<div mat-dialog-title class="d-flex justify-content-between">
    <h3>Credit details for {{ contact!.display_name }}</h3>
    <button type="button" mat-icon-button (click)="dialogRef.close(false)">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content style="overflow: auto; max-height: 60vh">
    <table mat-table [dataSource]="dataSource" class="w-100">
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element?.vendor_credit_id">{{
                    element.vendor_credit_date | dateFromNumber | date: 'mediumDate'
                }}</span>
                <span *ngIf="element?.payment_made_id">{{
                    element.paid_date | dateFromNumber | date: 'mediumDate'
                }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="credit_info">
            <th mat-header-cell *matHeaderCellDef>Credit Info</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element?.vendor_credit_id">Vendor Credit</span>
                <span *ngIf="element?.payment_made_id">Excess Payment</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="credit_number">
            <th mat-header-cell *matHeaderCellDef>Credit Number</th>
            <td mat-cell *matCellDef="let element">
                <a *ngIf="element?.vendor_credit_id" (click)="routeVendorCreditToElement(element)" href="javascript:">
                    <span *ngIf="element?.vendor_credit_number">{{ element.vendor_credit_number }}</span>
                    <span *ngIf="!element?.vendor_credit_number">N/A</span>
                </a>
                <a *ngIf="element?.payment_made_id" (click)="routePaymentMadeToElement(element)" href="javascript:">
                    <span *ngIf="element?.payment_made_number">{{ element.payment_made_number }}</span>
                    <span *ngIf="!element?.payment_made_number">N/A</span>
                </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">{{ element.status }}</td>
        </ng-container>

        <ng-container matColumnDef="total_amount">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element?.vendor_credit_id"> {{ element.total_amount | currency }} </span>
                <span *ngIf="element?.payment_made_id"> {{ element.amount | currency }} </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="balance">
            <th mat-header-cell *matHeaderCellDef>Balance</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element?.vendor_credit_id"> {{ element.balance | currency }} </span>
                <span *ngIf="element?.payment_made_id"> {{ element.over_payment | currency }} </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" style="padding-right: 0; width: 140px">
                <button
                    [rule]="{purchases__manage_vendor_credits: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    mat-raised-button
                    (click)="$event.stopPropagation(); $event.preventDefault(); applyToBill(element)"
                >
                    Apply to Bills
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</mat-dialog-content>

<mat-dialog-actions class="justify-content-end">
    <div>Total Balance: {{ excess | currency }}</div>
</mat-dialog-actions>
