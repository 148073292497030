<div mat-dialog-title>
    <h3>
        Send report to Yodata
        {{ superType && SUPER_TYPES_LABELS[superType] ? 'for ' + SUPER_TYPES_LABELS[superType] + ' transaction' : '' }}
    </h3>
</div>

<mat-dialog-content>
    <form [formGroup]="entityForm" *ngIf="readyToInit">
        <div class="row">
            <!--<div class="col-md-12">-->
            <!--    <mat-form-field class="w-100">-->
            <!--        <mat-placeholder>Topic</mat-placeholder>-->
            <!--        <input matInput disabled required type="text" formControlName="topic">-->
            <!--    </mat-form-field>-->
            <!--</div>-->

            <!--RECIPIENT POD-->
            <div class="col-md-6">
                <mat-form-field class="w-100">
                    <mat-placeholder>Recipient POD URI</mat-placeholder>
                    <input matInput required type="text" formControlName="recipient" />
                    <mat-icon matSuffix matTooltip="The POD URI of the affiliate associated with the message"
                        >help_outline</mat-icon
                    >
                </mat-form-field>
            </div>
            <!--SOURCE POD-->
            <div class="col-md-6">
                <mat-form-field class="w-100">
                    <mat-placeholder>Source POD URI</mat-placeholder>
                    <input matInput required type="text" formControlName="source" />
                    <mat-icon matSuffix matTooltip="An additional POD to send event. (Like CC on an email message.)"
                        >help_outline</mat-icon
                    >
                </mat-form-field>
            </div>

            <!--DATA-->
            <div formGroupName="data" class="w-100">
                <!--<div class="col-md-12">-->
                <!--    <mat-form-field class="w-100">-->
                <!--        <mat-placeholder>type</mat-placeholder>-->
                <!--        <input matInput required type="text" formControlName="type">-->
                <!--    </mat-form-field>-->
                <!--</div>-->
                <!--OBJECT-->
                <div class="col-md-12" formGroupName="object">
                    <!--<div>-->
                    <!--    <mat-form-field appearance="standard">-->
                    <!--        <mat-label>Standard form field</mat-label>-->
                    <!--        <input matInput placeholder="Placeholder">-->
                    <!--        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>-->
                    <!--        <mat-hint>Hint</mat-hint>-->
                    <!--    </mat-form-field>-->
                    <!--</div>-->
                    <div class="row" formGroupName="identifier">
                        <div class="col-md-4">
                            <mat-form-field class="w-100">
                                <mat-placeholder>BMS Transaction ID</mat-placeholder>
                                <input matInput required type="text" formControlName="bmsTransactionId" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" formGroupName="additionalProperty">
                        <div class="col-md-4">
                            <mat-form-field class="w-100">
                                <mat-placeholder>Batch ID</mat-placeholder>
                                <input matInput required type="text" formControlName="batchId" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-2">
                            <mat-form-field class="w-100">
                                <mat-placeholder>Transaction Sequence</mat-placeholder>
                                <input matInput required type="number" formControlName="transactionSequence" />
                                <mat-icon
                                    matSuffix
                                    matTooltip="The adjustment sequence number. Must be zero if transaction is closed but not an adjustment or if transaction is open or canceled. If adjusting a closed transaction, the first adjustment must have 1 in the adjustment sequence; the second must have 2; the third, 3; etc. Sequence number cannot be equal to or less than the last adjustment sequence number reported for the transaction if the transactionStatus has not changed."
                                    >help_outline</mat-icon
                                >
                            </mat-form-field>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox formControlName="unimprovedLandFlag">
                                Unimproved Land
                                <mat-icon
                                    matSuffix
                                    matTooltip="A flag identifying whether or not the property involved in the transaction is considered unimproved land."
                                    >help_outline</mat-icon
                                >
                            </mat-checkbox>
                        </div>
                    </div>

                    <!--ADDRESS FIELDS-->
                    <div class="row bg-light mb-4" formGroupName="object">
                        <div class="col-md-12">
                            <h4 class="col-12">Address Fields</h4>
                        </div>
                        <div class="col-md-4" *ngIf="superType === SUPER_TYPES.SALE">
                            <mat-form-field class="w-100">
                                <mat-placeholder>Address Country</mat-placeholder>
                                <input matInput required type="text" formControlName="addressCountry" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-4" *ngIf="superType === SUPER_TYPES.SALE">
                            <mat-form-field class="w-100">
                                <mat-placeholder>Street Address</mat-placeholder>
                                <input matInput type="text" formControlName="streetAddress" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-4" *ngIf="superType === SUPER_TYPES.SALE">
                            <mat-form-field class="w-100">
                                <mat-placeholder>City, Township</mat-placeholder>
                                <input matInput type="text" formControlName="addressLocality" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-4" *ngIf="superType === SUPER_TYPES.SALE">
                            <mat-form-field class="w-100">
                                <mat-placeholder>State or Province</mat-placeholder>
                                <input matInput type="text" formControlName="addressRegion" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-4" *ngIf="superType === SUPER_TYPES.SALE">
                            <mat-form-field class="w-100">
                                <mat-placeholder>Zip/Post Code</mat-placeholder>
                                <input matInput type="text" formControlName="postalCode" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="w-100">
                                <mat-placeholder>Property Type</mat-placeholder>
                                <mat-select formControlName="propertyType">
                                    <mat-option value="RESI">Residential</mat-option>
                                    <mat-option value="COMS">Commercial Standard Applies</mat-option>
                                </mat-select>
                                <!--<input matInput required type="text" formControlName="propertyType">-->
                            </mat-form-field>
                        </div>
                        <!--RealEstateProperty-->
                        <!--<div class="col-md-4">-->
                        <!--    <mat-form-field class="w-100">-->
                        <!--        <mat-placeholder>Type</mat-placeholder>-->
                        <!--        <input matInput required type="text" formControlName="type">-->
                        <!--    </mat-form-field>-->
                        <!--</div>-->
                    </div>

                    <div class="row">
                        <!--<div class="col-md-4">-->
                        <!--    <mat-form-field class="w-100">-->
                        <!--        <mat-placeholder>Type</mat-placeholder>-->
                        <!--        <input matInput required type="text" formControlName="type">-->
                        <!--    </mat-form-field>-->
                        <!--</div>-->
                        <div class="col-md-2">
                            <mat-form-field class="w-100">
                                <mat-placeholder>Transaction Type</mat-placeholder>
                                <!--<input matInput required type="text" formControlName="transactionType">-->
                                <mat-select formControlName="transactionType">
                                    <mat-option value="ST">Sale</mat-option>
                                    <mat-option value="LS">Lease</mat-option>
                                    <mat-option value="RF">Referral</mat-option>
                                    <mat-option value="OI">Other Income</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="w-100">
                                <mat-placeholder>Transaction Status</mat-placeholder>
                                <!--<input matInput required type="text" formControlName="transactionStatus">-->
                                <mat-select formControlName="transactionStatus">
                                    <mat-option value="PendingTransactionStatus">Pending</mat-option>
                                    <mat-option value="ClosedTransactionStatus">Closed</mat-option>
                                    <mat-option value="CanceledTransactionStatus">Canceled</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="w-100" *ngIf="entityForm.controls && entityForm.controls.data">
                                <mat-icon
                                    style="color: orange"
                                    *ngIf="!reportingOfficeValue"
                                    class="mr-2"
                                    matPrefix
                                    matTooltip="This field was not prefilled because current division doesn't have POD URI in addon settings."
                                    >warning</mat-icon
                                >
                                <mat-placeholder>Reporting Office POD URI</mat-placeholder>
                                <input matInput required type="text" formControlName="reportingOffice" />
                                <mat-icon *ngIf="divisionTitle" matSuffix [matTooltip]="divisionTitle">home</mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6" *ngIf="superType === SUPER_TYPES.SALE && this.side === 'listing'">
                            <mat-form-field class="w-100">
                                <mat-placeholder>Listing Office POD URI</mat-placeholder>
                                <input
                                    [required]="this.side === 'listing'"
                                    matInput
                                    type="text"
                                    formControlName="listingOffice"
                                />
                            </mat-form-field>
                        </div>
                        <div class="col-md-6" *ngIf="superType === SUPER_TYPES.SALE && this.side === 'buyer'">
                            <mat-form-field class="w-100">
                                <mat-placeholder>Buyer Office POD URI</mat-placeholder>
                                <input
                                    [required]="this.side === 'buyer'"
                                    matInput
                                    type="text"
                                    formControlName="buyerOffice"
                                />
                            </mat-form-field>
                        </div>
                    </div>

                    <!--DATES-->
                    <div class="row mb-4">
                        <!--closeDate-->
                        <div class="col-3" *ngIf="superType === SUPER_TYPES.SALE">
                            <mat-form-field class="w-100">
                                <input
                                    matInput
                                    [matDatepicker]="closingDate"
                                    formControlName="closeDate"
                                    placeholder="Close Date"
                                />
                                <mat-datepicker-toggle
                                    matTooltip="With purchase the date the purchase agreement was fulfilled. With lease, the date the requirements were fulfilled, such as contract and/or deposit. If status is Open or Canceled, must be empty."
                                    matSuffix
                                    [for]="closingDate"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #closingDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <!--commissionDate-->
                        <div class="col-3" *ngIf="superType === SUPER_TYPES.MISC">
                            <mat-form-field class="w-100">
                                <input
                                    matInput
                                    required
                                    [matDatepicker]="commissionDate"
                                    formControlName="commissionDate"
                                    placeholder="Commission Date"
                                />
                                <mat-datepicker-toggle
                                    matTooltip="Date the purchase offer was presented"
                                    matSuffix
                                    [for]="commissionDate"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #commissionDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <!--purchaseContractDate-->
                        <div class="col-3" *ngIf="superType === SUPER_TYPES.SALE">
                            <mat-form-field class="w-100">
                                <input
                                    matInput
                                    required
                                    [matDatepicker]="purchaseContractDate"
                                    formControlName="purchaseContractDate"
                                    placeholder="Purchase Contract Date"
                                />
                                <mat-datepicker-toggle
                                    matTooltip="Date of purchase agreement execution (aka sale date)"
                                    matSuffix
                                    [for]="purchaseContractDate"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #purchaseContractDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <!--GROSS COMMISSION-->
                        <div class="col-md-4" formGroupName="closePrice">
                            <mat-form-field class="w-100">
                                <mat-placeholder>Close Price</mat-placeholder>
                                <input matInput required type="number" formControlName="value" />
                            </mat-form-field>
                        </div>
                        <!--GROSS COMMISSION-->
                        <div class="col-md-4" formGroupName="totalSalesProductionGCI">
                            <mat-form-field class="w-100">
                                <mat-placeholder>Sales Production GCI</mat-placeholder>
                                <input matInput required type="number" formControlName="value" />
                                <mat-icon
                                    matSuffix
                                    matTooltip="The affiliate's gross commission income before any fees or discounts have been subtracted but excluding any co-op affiliates commissions. For Sales Transactions this must not be greater than the sales price."
                                    >help_outline</mat-icon
                                >
                            </mat-form-field>
                        </div>
                        <!--UNKNOWN FIELD requires calculation (formula)-->
                        <div class="col-md-4" formGroupName="totalSalesProductionGCIDeduction">
                            <mat-form-field class="w-100">
                                <mat-placeholder>Sales Production GCI Deduction</mat-placeholder>
                                <input matInput required type="number" formControlName="value" />
                                <mat-icon
                                    matSuffix
                                    matTooltip="GCI Deductions: The total fees exempt from HSFA royalty fees based on the affiliate’s Franchise Agreement and Operations Manual. Examples of a fee that may be deducted is a referral fee paid. Required when there are deductions exempt from HSFA fees. No other deductions from GCI should be included in this field. Must not be greater than the GCI."
                                    >help_outline</mat-icon
                                >
                            </mat-form-field>
                        </div>
                    </div>

                    <!--transactionEntry (SALES ENTITIES)-->
                    <div class="row bg-light mb-4">
                        <h4 class="col-12">Transaction Entries</h4>
                        <ng-container formArrayName="transactionEntry">
                            <div class="col-12" *ngFor="let item of salesEntitiesArrayCtrl.controls; let i = index">
                                <div [formGroupName]="i" class="row w-100">
                                    <h5 class="col-12">{{ item.value.SE_NAME }}</h5>
                                    <div formGroupName="recipient" class="col-8">
                                        <div class="row">
                                            <div class="col-8">
                                                <mat-form-field
                                                    class="w-100"
                                                    *ngIf="item.controls.recipient && item.controls.recipient.controls"
                                                >
                                                    <mat-icon
                                                        style="color: orange"
                                                        *ngIf="!item.controls.recipient.controls.id.value"
                                                        class="mr-2"
                                                        matPrefix
                                                        matTooltip="This field was not prefilled because current profile doesn't have POD URI in addon settings."
                                                        >warning</mat-icon
                                                    >
                                                    <!--https://{entityid}.example.com/profile/card#me-->
                                                    <mat-placeholder>{{
                                                        'https://{entityid}.example.com/profile/card#me'
                                                    }}</mat-placeholder>
                                                    <input matInput required type="text" formControlName="id" />
                                                    <mat-icon
                                                        matSuffix
                                                        matTooltip="The HSF Contact ID of the sales associate representing the customer. The HSF Contact ID is the ID provided to the Affiliate from HSFA. Must match an existing sales associate."
                                                        >help_outline</mat-icon
                                                    >
                                                </mat-form-field>
                                            </div>
                                            <div formGroupName="identifier" class="col-4">
                                                <mat-form-field class="w-100">
                                                    <mat-placeholder>BMS Agent ID</mat-placeholder>
                                                    <input matInput required type="text" formControlName="bmsAgentId" />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div formGroupName="salesProductionGCI" class="col-2">
                                        <mat-form-field class="w-100">
                                            <mat-placeholder>Sales Production GCI</mat-placeholder>
                                            <mat-icon
                                                matSuffix
                                                matTooltip="Gross commission income credited to the recipient."
                                                >help_outline</mat-icon
                                            >
                                            <input matInput required type="number" formControlName="value" />
                                        </mat-form-field>
                                    </div>
                                    <!--salesProductionUnit-->
                                    <mat-form-field *ngIf="superType === SUPER_TYPES.SALE" class="w-100 col-2">
                                        <mat-placeholder>Sales Production Unit</mat-placeholder>
                                        <mat-icon
                                            matSuffix
                                            matTooltip="The sales production units credited to the recipient."
                                            >help_outline</mat-icon
                                        >
                                        <input matInput required type="number" formControlName="salesProductionUnit" />
                                    </mat-form-field>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <!--END transactionEntry-->

                    <!--participant (DEAL PARTICIPANTS)-->
                    <div class="row bg-light">
                        <h4 class="col-12">Participants</h4>
                        <ng-container formArrayName="participant">
                            <div class="col-12" *ngFor="let item of participantsArrayCtrl.controls; let ii = index">
                                <div [formGroupName]="ii" class="row w-100">
                                    <h5 class="col-12">
                                        {{ item.value.givenName }} {{ item.value.familyName }} ({{
                                            item.value.roleName
                                        }})
                                    </h5>
                                    <mat-form-field class="w-100 col-6">
                                        <mat-icon
                                            style="color: orange"
                                            *ngIf="!item.controls.affiliation || !item.controls.affiliation.value"
                                            class="mr-2"
                                            matPrefix
                                            matTooltip="This field was not prefilled because the primary agent doesn't have POD URI in addon settings."
                                            >warning</mat-icon
                                        >
                                        <mat-placeholder>{{
                                            'https://{entityid}.example.com/profile/card#me'
                                        }}</mat-placeholder>
                                        <mat-icon
                                            matSuffix
                                            matTooltip="The HSF Contact ID of the primary sales associate representing the customer. The HSF Contact ID is the ID provided to the Affiliate from HSFA. Must match an existing sales associate."
                                            >help_outline</mat-icon
                                        >
                                        <input matInput required type="text" formControlName="affiliation" />
                                    </mat-form-field>
                                    <mat-form-field *ngIf="superType === SUPER_TYPES.SALE" class="w-100 col-2">
                                        <mat-placeholder>Position</mat-placeholder>
                                        <mat-icon
                                            matSuffix
                                            matTooltip="Identifies whether the customer is the primary, secondary. Valid codes are: 1 = Primary Customer; 2 = Secondary Customer; 3 - 9 = Third through Ninth Customer."
                                            >help_outline</mat-icon
                                        >
                                        <input matInput required type="number" formControlName="position" />
                                    </mat-form-field>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <!--END participant-->
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
    <button class="mr-2" mat-raised-button (click)="closeForm(false)" tabindex="-1">Cancel</button>
    <button mat-raised-button class="mr-2" color="primary" (click)="closeForm(true)" tabindex="1">Send</button>
</mat-dialog-actions>
