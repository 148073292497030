import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'fromSnakeCase'
})
export class FromSnakeCasePipe implements PipeTransform {
    transform(value: any, args?: any): string {
        if (value && typeof value === 'string') {
            return value
                .replace(/virt__/g, '')
                .replace(/prog__/g, '')
                .replace(/_/g, ' ')
                .replace(/\b\w/g, (l) => l.toUpperCase());
        }
        return '';
    }
}
