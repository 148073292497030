import {Component} from '@angular/core';

@Component({
    selector: 'app-banking',
    template: `
        <mat-drawer-container class="h-100">
            <mat-drawer-content>
                <router-outlet></router-outlet>
            </mat-drawer-content>
        </mat-drawer-container>
    `
})
export class BankingComponent {}
