import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {filter, takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {SessionService} from '../../../../../../../../services/session.service';
import {ConfirmComponent} from '../../../../../../../../layouts/confirm/confirm.component';
import {CompanyPaymentMethodsService} from '../../../../../../../../services/api/finance/company-payment-methods.service';
import {NotificationsService} from 'angular2-notifications';
import {GatewayService} from '../../../../../../../profile/services/gateway.service';
import {ZipiFinancialIframeDialogComponent} from '../../../dialogs/zipi-financial-iframe-dialog/zipi-financial-iframe-dialog.component';
import {NotificationsServiceZipi} from '../../../../../../../notifications/notifications.service';
import {ZipiFinancialAfterSetupDialogComponent} from '../../../dialogs/zipi-financial-after-setup-dialog/zipi-financial-after-setup-dialog.component';
import {FeatureFlagsService} from '../../../../../../../feature-flags/feature-flags.service';
import {ZipiFinConnectToLedgerAccountDialogComponent} from '../../../dialogs/zipi-fin-connect-to-ledger-account-dialog/zipi-fin-connect-to-ledger-account-dialog.component';
import {ZipiFinPaymentMehtodEditDialogComponent} from '../../../dialogs/zipi-fin-payment-mehtod-edit-dialog/zipi-fin-payment-mehtod-edit-dialog.component';
import {IZipiFinancialIframeDialogData} from '../../../../../../../../typings/zipi-financial-iframe';
import {MuhneeVerifyBankAccountHostedDialogComponent} from '../../../dialogs/muhnee-verify-bank-account-hosted-dialog/muhnee-verify-bank-account-hosted-dialog.component';
import {ZipiFinancialTransferMoneyDialogComponent} from '../../../dialogs/zipi-financial-transfer-money-dialog/zipi-financial-transfer-money-dialog.component';

@Component({
    selector: 'app-zipi-financial-bank-accounts',
    templateUrl: './zipi-financial-bank-accounts.component.html',
    styleUrls: ['./zipi-financial-bank-accounts.component.scss']
})
export class ZipiFinancialBankAccountsComponent implements OnInit, OnChanges, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() gateway: IPaymentGateway | undefined;
    @Input() isFinalPopupDisplay: {
        gateway_id: number;
        payment_method_id: number | null;
        popup_type: 'final' | 'ledger';
    } | null = null;

    @Output() needReload = new EventEmitter();
    @Output() cleanFinalPopupInfo = new EventEmitter();
    @Output() openConnectBankEvent: EventEmitter<number> = new EventEmitter();

    linkHandler: any;

    // feature_flags
    isMoneyTransfersFeatureFlagEnabled: boolean = false;

    constructor(
        public dialog: MatDialog,
        protected sessionService: SessionService,
        private ntfs: NotificationsService,
        private companyPaymentMethodsService: CompanyPaymentMethodsService,
        private notificationServiceZipi: NotificationsServiceZipi,
        protected featureFlagsService: FeatureFlagsService
    ) {}

    ngOnInit() {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.isMoneyTransfersFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:money_transfer'
                );
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('isFinalPopupDisplay') && changes.isFinalPopupDisplay.currentValue) {
            if (
                this.gateway &&
                this.isFinalPopupDisplay &&
                this.isFinalPopupDisplay.gateway_id &&
                this.isFinalPopupDisplay.gateway_id === this.gateway.payment_gateway_id
            ) {
                switch (this.isFinalPopupDisplay.popup_type) {
                    case 'final': {
                        this.showFinalPopup();
                        break;
                    }
                    case 'ledger': {
                        this.showLedgerPopup(this.isFinalPopupDisplay.payment_method_id);
                        break;
                    }
                }
            }
        }
    }

    showLedgerPopup(paymentMethodId: number | null) {
        if (this.gateway && this.gateway.payment_methods && this.gateway.payment_methods.length > 0) {
            const method = paymentMethodId
                ? this.gateway.payment_methods.find((method) => method.payment_method_id === paymentMethodId)
                : this.gateway.payment_methods[0];
            if (!method) {
                return;
            }
            const dialogRef = this.dialog.open(ZipiFinConnectToLedgerAccountDialogComponent, {
                disableClose: true,
                data: {
                    method: method,
                    gateway: this.gateway,
                    hideCancel: true
                }
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    const settings: {
                        related_ledger_account_id: number | null;
                        needDefaultLedger: boolean | null;
                    } = {
                        related_ledger_account_id: null,
                        needDefaultLedger: null
                    };

                    if (result === false || result === undefined) {
                        return;
                    } else if (result === null) {
                        settings.needDefaultLedger = true;
                    } else {
                        settings.needDefaultLedger = false;
                        settings.related_ledger_account_id = result;
                    }
                    if (method && method.payment_method_id) {
                        this.companyPaymentMethodsService
                            .updateLedgerAccountForPaymentMethod(method.payment_method_id, settings)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((response) => {
                                this.cleanFinalPopupInfo.emit(true);
                                this.needReload.emit(true);
                            });
                    }
                });
        }
    }

    showFinalPopup() {
        const dialogRef = this.dialog.open(ZipiFinancialAfterSetupDialogComponent, {
            width: '70vw',
            data: {}
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((ok) => {
                if (ok) {
                    this.addPaymentMethod('bank');
                } else {
                    this.cleanFinalPopupInfo.emit(true);
                    this.needReload.emit(true);
                }
            });
    }

    addPaymentMethod(type: 'bank' | 'card') {
        const dialogRef = this.dialog.open<ZipiFinancialIframeDialogComponent, IZipiFinancialIframeDialogData>(
            ZipiFinancialIframeDialogComponent,
            {
                disableClose: true,
                maxHeight: '80vh',
                width: '650px',
                // height: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    gateway: this.gateway ? this.gateway : null,
                    paymentMethod: null,
                    methodType: type,
                    driverType: 'moov',
                    accessMethod: 'internal',
                    contactId: null,
                    prefillData: null,
                    isUniversal: false,
                    customerId: null,
                    creationSource: 'current_company',
                    storeAccountMethod: 'required'
                }
            }
        );

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result.isError) {
                    this.notificationServiceZipi.addError(result.message);
                }
                this.cleanFinalPopupInfo.emit(true);
                this.needReload.emit(true);
            });
    }

    editPayment(method: IPaymentMethod, gateway: IPaymentGateway) {
        const dialogRef = this.dialog.open(ZipiFinPaymentMehtodEditDialogComponent, {
            width: '600px',
            data: {
                method: method,
                gateway: gateway
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result && method && method.payment_method_id) {
                    this.companyPaymentMethodsService
                        .updatePaymentMethod(method.payment_method_id, result)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((response) => {
                            this.needReload.emit(true);
                        });
                }
            });
    }

    finishSetup(method: IPaymentMethod, gateway: IPaymentGateway) {
        const dialogRef = this.dialog.open(ZipiFinConnectToLedgerAccountDialogComponent, {
            disableClose: true,
            data: {
                method: method,
                gateway: gateway
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                const settings: {
                    related_ledger_account_id: number | null;
                    needDefaultLedger: boolean | null;
                } = {
                    related_ledger_account_id: null,
                    needDefaultLedger: null
                };

                if (result === false || result === undefined) {
                    return;
                } else if (result === null) {
                    settings.needDefaultLedger = true;
                } else {
                    settings.needDefaultLedger = false;
                    settings.related_ledger_account_id = result;
                }
                if (method && method.payment_method_id) {
                    this.companyPaymentMethodsService
                        .updateLedgerAccountForPaymentMethod(method.payment_method_id, settings)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((response) => {
                            this.needReload.emit(true);
                        });
                }
            });
    }

    async verify(method: IPaymentMethod) {
        if (this.gateway && this.gateway.driver_type === 'muhnee') {
            const dialogRef = this.dialog.open<MuhneeVerifyBankAccountHostedDialogComponent>(
                MuhneeVerifyBankAccountHostedDialogComponent,
                {
                    maxHeight: '80vh',
                    width: '675px',
                    // height: '800px',
                    data: {
                        gateway: this.gateway ? this.gateway : null,
                        payment_method_id: method.payment_method_id
                    }
                }
            );
            dialogRef.componentInstance.deleteMethodEvent
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((methodIdToDelete) => {
                    if (methodIdToDelete) {
                        this.deletePaymentMethod(methodIdToDelete, false);
                    }
                });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((result) => {
                    if (result.isError) {
                        this.notificationServiceZipi.addError(result.message);
                    }
                    if (result.success) {
                        if (result.isNeedToConnectBank) {
                            this.openConnectBankEvent.emit(this.gateway?.payment_gateway_id);
                        } else {
                            this.cleanFinalPopupInfo.emit(true);
                            this.needReload.emit(true);
                        }
                    }
                });
        } else {
            const dialogRef = this.dialog.open<ZipiFinancialIframeDialogComponent, IZipiFinancialIframeDialogData>(
                ZipiFinancialIframeDialogComponent,
                {
                    disableClose: true,
                    maxHeight: '80vh',
                    width: '650px',
                    // height: '800px',
                    panelClass: 'hide-dialog-container',
                    data: {
                        gateway: this.gateway ? this.gateway : null,
                        paymentMethod: method,
                        methodType: 'bank',
                        accessMethod: 'internal',
                        driverType: 'moov',
                        contactId: null,
                        prefillData: null,
                        isUniversal: false,
                        customerId: null,
                        creationSource: 'current_company',
                        storeAccountMethod: 'required'
                    }
                }
            );

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((result) => {
                    if (result.isError) {
                        this.notificationServiceZipi.addError(result.message);
                    }
                    this.cleanFinalPopupInfo.emit(true);
                    this.needReload.emit(true);
                });
        }

        // if (method.payment_method_id) {
        //     const linkToken = await this.companyPaymentMethodsService.getPlaidLinkToken(method.payment_method_id).toPromise();
        //
        //     await this.setupLinkObj(linkToken, method);
        //     this.linkHandler.open();
        // }
    }

    deletePaymentMethod(methodId: number, isConfirmationPopupNeeded: boolean) {
        if (isConfirmationPopupNeeded) {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                minWidth: 320,
                // minHeight: 320,
                data: {
                    title: `Deleting Payment Method`,
                    message: `Funding Source will be deleted`
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe(() => {
                    this.companyPaymentMethodsService
                        .deleteMethod(methodId)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((res) => {
                            if (res) {
                                this.removeDeletedPaymentMethodById(methodId);
                            }
                        });
                });
        } else {
            this.companyPaymentMethodsService
                .deleteMethod(methodId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((res) => {
                    if (res) {
                        this.removeDeletedPaymentMethodById(methodId);
                    }
                });
        }
    }

    removeDeletedPaymentMethodById(methodId: number) {
        if (this.gateway && this.gateway.payment_methods) {
            this.gateway.payment_methods = this.gateway.payment_methods.filter(
                (method) => method.payment_method_id !== methodId
            );
        }
    }

    refreshBalanceOfMethod(method: IPaymentMethod) {
        if (!method.payment_method_ref) {
            this.ntfs.error('Refresh Balance', 'Balance not available for current Funding Source');
        } else {
            if (method.payment_method_id) {
                this.companyPaymentMethodsService
                    .refreshBalanceByMethodId(method.payment_method_id)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((res) => {
                        if (res) {
                            method.balance = res;
                        }
                    });
            }
        }
    }

    changeDefaultStatus(methodId: number) {
        this.companyPaymentMethodsService
            .changeDefaultReceivingMethod(methodId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                if (res) {
                    this.needReload.emit(true);
                }
            });
    }

    changeDefaultMerchant(methodId: number) {
        this.companyPaymentMethodsService
            .changeDefaultMerchantMethod(methodId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                if (res) {
                    this.needReload.emit(true);
                }
            });
    }

    transferMoneyInOut(method: IPaymentMethod) {
        const dialogRef = this.dialog.open(ZipiFinancialTransferMoneyDialogComponent, {
            width: '650px',
            data: {
                method,
                gateway: this.gateway
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {
                if (ok) {
                    // this.needReload.emit(true);
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.needReload.complete();
        this.dialog.ngOnDestroy();
    }
}
