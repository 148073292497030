import {Validators} from '@angular/forms';
import {IContactLocationTable, IGooglePlaceObject} from '@cyberco-nodejs/zipi-typings';

declare var google: any;
const win: any = window;

export const NEW_CONTACT_LOCATION: Partial<IContactLocationTable> = {
    country: '',
    city: '',
    state: '',
    street_address: '',
    street_number: '',
    zip: '',
    label: ''
};

export const extractLocationComponents = (lcn?: IGooglePlaceObject) => {
    return <IContactLocationTable>lcn?.address_components.reduce((acc: any, addressComponent: any) => {
        for (const componentType of addressComponent.types) {
            switch (componentType) {
                case 'country':
                    // return {...acc, country: (<any>addressComponent).long_name};
                    return {...acc, country: (<any>addressComponent).short_name};

                case 'locality':
                    return {...acc, city: (<any>addressComponent).long_name};

                case 'administrative_area_level_1':
                    return {...acc, state: (<any>addressComponent).short_name};

                case 'route':
                    return {
                        ...acc,
                        street_address: (<any>addressComponent).short_name || (<any>addressComponent).long_name,
                        label: (<any>addressComponent).short_name || (<any>addressComponent).long_name
                    };

                case 'street_number':
                    return {...acc, street_number: (<any>addressComponent).long_name};

                case 'postal_code':
                    return {...acc, zip: (<any>addressComponent).long_name};

                default:
                    return acc;
            }
        }
    }, NEW_CONTACT_LOCATION);
};

const geocoderService = initGeocoderService();
// const placesService = new google.maps.places.PlacesService(document.createElement('div'))

function initGeocoderService() {
    // TODO find way to fix this dirty hack
    if (win && win.google && win.google.maps) {
        return new google.maps.Geocoder();
    } else {
        setTimeout(() => initGeocoderService(), 100);
    }
}

// https://developers.google.com/maps/documentation/geocoding/intro#Types
const GEO_TYPES = ['street_address', 'locality', 'political', 'postal_code'];

export const searchPlace = (address: string) =>
    new Promise((resolve, reject) => {
        if (!address || !address.trim()) {
            return reject();
        }
        geocoderService.geocode({address}, (results: any[], status: string) => {
            if (!results) {
                return reject();
            }

            results = results.filter((r) => r.types.some((gt: string) => GEO_TYPES.includes(gt)));

            if (status === 'OK' && results.length > 0) {
                resolve(results[0]);
            } else {
                reject();
            }
        });
    });

// const then = curry((f, p) => p.then(f));

// export const sortContacts = sortBy((v: IContact) => v.display_name);

// const addUniq = pipe( concat, compact, uniq );
//
// const omitEmptyProps = pipe(
//     omitBy(isEmpty),
//     omitBy((v: any) => !v),
// );

// async function listToContact(contactRow: string[]) {
//     const [
//         display_name,
//         category,
//         notes,
//         company_name,
//         person_first_name,
//         person_last_name,
//         porson_phone,
//         person_email,
//         country,
//         city,
//         state,
//         street_address,
//         street_number,
//         zip,
//         unit_number,
//         location_label,
//     ] = contactRow;
//
//     return Object.assign({
//         display_name: display_name,
//         category: category ? category.split(',').map(c => c.trim().toLowerCase()) : null,
//         notes: notes,
//         company_name,
//         persons: [omitEmptyProps({
//             first_name: person_first_name,
//             last_name: person_last_name,
//             phone: porson_phone,
//             email: person_email,
//             type: company_name ? 'person' : 'main_person', // TODO: Allow to mark and import 'department'  types
//         })].filter(person => !isEmpty(person)),
//         locations: [omitEmptyProps({
//             label: location_label,
//             country,
//             city,
//             state,
//             street_address,
//             street_number,
//             zip,
//             unit_number,
//         })].filter(lcn => !isEmpty(lcn)),
//         contact_locations: [omitEmptyProps({
//             label: location_label,
//             country,
//             city,
//             state,
//             street_address,
//             street_number,
//             zip,
//             unit_number,
//         })].filter(lcn => !isEmpty(lcn)),
//     });
// }

// const similarObjMerger = pipe(
//     // @ts-ignore
//     objx => mapObj(objx, (locations, key) => {
//         if (key === 'undefined') { return uniqWith(isEqual, locations); }
//         return mergeAll(locations);
//     }),
//     flatten,
// );

// const personsMerge = pipe(
//     groupBy((p: IContactPerson) => p.email),
//     similarObjMerger
// );

// const locationMerge = pipe(
//     groupBy((lcn: IContactLocationTable) => lcn.label),
//     similarObjMerger
// );

// @ts-ignore
// const contactMerger = (k, l, r) => {
//     switch (k) {
//         case 'category':
//         case 'reference_keys':
//         case 'persons':
//         case 'locations':
//             return addUniq(r, l);
//
//         default:
//             return r ? r : l;
//     }
// };

// const mergeContacts = pipe(
//     reduce(mergeDeepWithKey(contactMerger), {}),
//     update('persons', personsMerge),
//     update('locations', locationMerge),
// );

export const simpleEmailValidatorPattern = /\S+@\S+\.\S+/;
export const simpleEmailValidator = Validators.pattern(simpleEmailValidatorPattern);

export const phoneNumberValidator = Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
