<section class="sidebar-page">
    <app-company-inner-nav
        [groupsList]="groupsList"
        (initAccountsEmitter)="initAccounts($event)"
    ></app-company-inner-nav>

    <div class="sidebar-wrapper">
        <app-ledger-account-transactions #accountTransactions [isOpened]="isSidebarOpened" class="sidebar-container">
            <section class="sidebar-body">
                <table
                    matSort
                    (matSortChange)="sortData($event)"
                    mat-table
                    matSortDisableClear
                    [dataSource]="ledgerAccounts"
                    class="w-100 ledger-account-table mb-4"
                >
                    <ng-container matColumnDef="name">
                        <th mat-sort-header="name" class="w-55" mat-header-cell *matHeaderCellDef>Account Name</th>
                        <td mat-cell class="w-55" *matCellDef="let element">
                            <span *ngFor="let lvl of [].constructor(element.level); let i = index">
                                <span *ngIf="i !== [].constructor(element.level).length - 1"
                                    >&nbsp;&nbsp;&nbsp;&nbsp;</span
                                >
                                <span
                                    *ngIf="i === [].constructor(element.level).length - 1"
                                    class="ledger-account-table__child-tree"
                                    >&nbsp;&nbsp;&nbsp;&nbsp;</span
                                >
                            </span>
                            <span *ngIf="!!element.hasChildren" class="position-relative pl-2">
                                <mat-icon class="ledger-account-table__icon">folder</mat-icon>
                                <a (click)="openSidebar(element)" class="name-link">{{ element.name }}</a>
                            </span>
                            <a *ngIf="!element.hasChildren" (click)="openSidebar(element)" class="name-link"
                                >{{ element.name
                                }}<span *ngIf="element.status === 'inactive'" class="font-weight-bold">
                                    (Deactivated)</span
                                >
                            </a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="code">
                        <th mat-sort-header="code" class="w-20" mat-header-cell *matHeaderCellDef>Account Code</th>
                        <td class="w-20 px-1" mat-cell *matCellDef="let element">
                            {{ element.code }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <th mat-sort-header="type" class="w-20" mat-header-cell *matHeaderCellDef>Type</th>
                        <td class="w-20 px-1" mat-cell *matCellDef="let element">
                            {{ ledgerTypesMap[element.type] }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="id">
                        <th class="w-5" mat-header-cell *matHeaderCellDef>Action</th>
                        <td mat-cell class="w-5" *matCellDef="let element">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button
                                    rbac
                                    [rule]="{company_settings__manage_ledger_accounts: true}"
                                    [denyMethod]="'style'"
                                    [allowStyle]="{display: 'inline-block'}"
                                    [denyStyle]="{display: 'none'}"
                                    [routerLink]="element.id"
                                    mat-menu-item
                                >
                                    Edit
                                </button>
                                <button (click)="openSidebar(element)" mat-menu-item>Transactions</button>
                            </mat-menu>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="zp-table-row"></tr>
                </table>
            </section>
        </app-ledger-account-transactions>
    </div>
</section>
