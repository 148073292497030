<div class="wrapper">
    <mat-card>
        <div class="report-table w-100">
            <div class="sticky">
                <div class="flex-row no-margin font-weight-bold">
                    <div class="item-name">
                        <button
                            mat-icon-button
                            class="header-actions"
                            (click)="toggleExpand()"
                            [matTooltip]="expandTooltip"
                        >
                            <mat-icon>{{ isExpand ? 'unfold_less' : 'unfold_more' }}</mat-icon>
                        </button>
                        <span>Account</span>
                    </div>
                    <ng-container *ngFor="let group of reportData.groups; let idx = index">
                        <div class="item-value">{{ group.title }}</div>
                        <div
                            class="item-value"
                            *ngIf="idx > 0 && report.settings.compare_to_periods?.display_options?.show_amount_change"
                        >
                            $ Change
                        </div>
                        <div
                            class="item-value"
                            *ngIf="idx > 0 && report.settings.compare_to_periods?.display_options?.show_percent_change"
                        >
                            % Change
                        </div>
                    </ng-container>
                    <div
                        class="item-value"
                        *ngIf="
                            !report.settings.compare_to_periods ||
                            !report.settings.compare_to_periods?.periods ||
                            report.settings.compare_to_periods?.periods?.length === 0
                        "
                    >
                        Total
                    </div>
                </div>
            </div>

            <ng-container *ngIf="reportData.income.length">
                <p class="header-group">Operating Income</p>

                <mat-tree [dataSource]="incomeDataSource" [treeControl]="treeControlIncome" class="tree">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                        <li class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div class="item w-100 flex-row" [ngClass]="{'total-row': node.type === 'total'}">
                                <div class="item-name">
                                    {{ node.la_name }}
                                </div>

                                <ng-container *ngFor="let baseGroup of reportData.groups; let idx = index">
                                    <ng-container
                                        *ngTemplateOutlet="
                                            groupTemplate;
                                            context: {g: node.groups[baseGroup.entity_id], i: idx}
                                        "
                                    >
                                    </ng-container>
                                </ng-container>
                                <ng-template #groupTemplate let-group="g" let-idx="i">
                                    <div class="item-value amount-text">
                                        <div
                                            *ngIf="node.type === 'total'"
                                            [ngClass]="{'negative-value': group.total_with_children < 0}"
                                        >
                                            <span
                                                class="open-sidenav"
                                                (click)="
                                                    openSideNav(
                                                        node,
                                                        node.la_type || 'income',
                                                        incomeDataSource.data,
                                                        idx,
                                                        undefined,
                                                        group.name
                                                    )
                                                "
                                            >
                                                {{ group.total_with_children | reportFormatted: 'financial' }}
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="node.type !== 'total'"
                                            [ngClass]="{'negative-value': group.total < 0}"
                                        >
                                            <span
                                                class="open-sidenav"
                                                (click)="
                                                    openSideNav(
                                                        node,
                                                        node.la_type || 'income',
                                                        incomeDataSource.data,
                                                        idx,
                                                        undefined,
                                                        group.name
                                                    )
                                                "
                                            >
                                                {{ group.total | reportFormatted: 'financial' }}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        class="item-value"
                                        *ngIf="
                                            idx > 0 &&
                                            report.settings.compare_to_periods?.display_options?.show_amount_change
                                        "
                                    >
                                        <div
                                            *ngIf="node.type === 'total'"
                                            [ngClass]="{'negative-value': group.total_with_children_change_amount < 0}"
                                        >
                                            {{ group.total_with_children_change_amount | reportFormatted: 'financial' }}
                                        </div>
                                        <div
                                            *ngIf="node.type !== 'total'"
                                            [ngClass]="{'negative-value': group.total_change_amount < 0}"
                                        >
                                            {{ group.total_change_amount | reportFormatted: 'financial' }}
                                        </div>
                                    </div>
                                    <div
                                        class="item-value"
                                        *ngIf="
                                            idx > 0 &&
                                            report.settings.compare_to_periods?.display_options?.show_percent_change
                                        "
                                    >
                                        <div *ngIf="node.type === 'total'">
                                            {{
                                                group.total_with_children_change_percent
                                                    | reportFormatted: 'smart_percent'
                                            }}
                                        </div>
                                        <div *ngIf="node.type !== 'total'">
                                            {{ group.total_change_percent | reportFormatted: 'smart_percent' }}
                                        </div>
                                    </div>
                                </ng-template>
                                <div
                                    class="item-value amount-text"
                                    *ngIf="
                                        !report.settings.compare_to_periods ||
                                        !report.settings.compare_to_periods?.periods ||
                                        report.settings.compare_to_periods?.periods?.length === 0
                                    "
                                    [ngClass]="{'negative-value': node.total < 0}"
                                >
                                    <span
                                        class="open-sidenav"
                                        (click)="openSideNav(node, node.la_type || 'income', incomeDataSource.data)"
                                    >
                                        {{ node.total | reportFormatted: 'financial' }}
                                    </span>
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.la_name"
                                    (click)="changeState(node)"
                                >
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{ treeControlIncome.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                    </mat-icon>
                                </button>

                                <div class="item w-100 flex-row">
                                    <div class="item-name">
                                        {{ node.la_name }}
                                    </div>

                                    <ng-container *ngFor="let baseGroup of reportData.groups; let idx = index">
                                        <ng-container
                                            *ngTemplateOutlet="
                                                groupTemplate;
                                                context: {g: node.groups[baseGroup.entity_id], i: idx}
                                            "
                                        ></ng-container>
                                    </ng-container>
                                    <ng-template #groupTemplate let-group="g" let-idx="i">
                                        <div class="item-value amount-text">
                                            <div
                                                *ngIf="node.type === 'header' && !node.expanded"
                                                [ngClass]="{'negative-value': group.total_with_children < 0}"
                                            >
                                                <span
                                                    class="open-sidenav"
                                                    (click)="
                                                        openSideNav(
                                                            node,
                                                            node.la_type || 'income',
                                                            incomeDataSource.data,
                                                            idx,
                                                            node.expanded,
                                                            group.name
                                                        )
                                                    "
                                                >
                                                    {{
                                                        (node.expanded ? null : group.total_with_children)
                                                            | reportFormatted: 'financial'
                                                    }}
                                                </span>
                                            </div>

                                            <div
                                                *ngIf="node.type !== 'header'"
                                                [ngClass]="{
                                                    'negative-value': node.expanded
                                                        ? group.total < 0
                                                        : group.total_with_children < 0
                                                }"
                                            >
                                                <span
                                                    class="open-sidenav"
                                                    (click)="
                                                        openSideNav(
                                                            node,
                                                            node.la_type || 'income',
                                                            incomeDataSource.data,
                                                            idx,
                                                            node.expanded,
                                                            group.name
                                                        )
                                                    "
                                                >
                                                    {{
                                                        (node.expanded ? group.total : group.total_with_children)
                                                            | reportFormatted: 'financial'
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            class="item-value"
                                            *ngIf="
                                                idx > 0 &&
                                                report.settings.compare_to_periods?.display_options?.show_amount_change
                                            "
                                        >
                                            <div
                                                *ngIf="node.type === 'header' && !node.expanded"
                                                [ngClass]="{
                                                    'negative-value': group.total_with_children_change_amount < 0
                                                }"
                                            >
                                                {{
                                                    (node.expanded ? null : group.total_with_children_change_amount)
                                                        | reportFormatted: 'financial'
                                                }}
                                            </div>
                                            <div
                                                *ngIf="node.type !== 'header'"
                                                [ngClass]="{
                                                    'negative-value': node.expanded
                                                        ? group.total_change_amount < 0
                                                        : group.total_with_children_change_amount < 0
                                                }"
                                            >
                                                {{
                                                    (node.expanded
                                                        ? group.total_change_amount
                                                        : group.total_with_children_change_amount
                                                    ) | reportFormatted: 'financial'
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="item-value"
                                            *ngIf="
                                                idx > 0 &&
                                                report.settings.compare_to_periods?.display_options?.show_percent_change
                                            "
                                        >
                                            <div *ngIf="node.type === 'header' && !node.expanded">
                                                {{
                                                    (node.expanded ? null : group.total_with_children_change_percent)
                                                        | reportFormatted: 'smart_percent'
                                                }}
                                            </div>
                                            <div *ngIf="node.type !== 'header'">
                                                {{
                                                    (node.expanded
                                                        ? group.total_change_percent
                                                        : group.total_with_children_change_percent
                                                    ) | reportFormatted: 'smart_percent'
                                                }}
                                            </div>
                                        </div>
                                    </ng-template>

                                    <div
                                        class="item-value amount-text"
                                        *ngIf="
                                            !report.settings.compare_to_periods ||
                                            !report.settings.compare_to_periods?.periods ||
                                            report.settings.compare_to_periods?.periods?.length === 0
                                        "
                                        [ngClass]="{
                                            'negative-value': node.expanded
                                                ? node.total < 0
                                                : node.total_with_children < 0
                                        }"
                                    >
                                        <span
                                            class="open-sidenav"
                                            (click)="
                                                openSideNav(
                                                    node,
                                                    node.la_type || 'income',
                                                    incomeDataSource.data,
                                                    undefined,
                                                    node.expanded
                                                )
                                            "
                                        >
                                            {{
                                                (node.expanded ? node.total : node.total_with_children)
                                                    | reportFormatted: 'financial'
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <ul [class.tree-invisible]="!treeControlIncome.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
            </ng-container>

            <ng-container *ngIf="reportData.cost_of_goods_sold.length">
                <p class="header-group">Cost of Goods Sold</p>

                <mat-tree [dataSource]="costOfGoodsDataSource" [treeControl]="treeControlCostOfGoods" class="tree">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                        <li class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div class="item w-100 flex-row" [ngClass]="{'total-row': node.type === 'total'}">
                                <div class="item-name">
                                    {{ node.la_name }}
                                </div>

                                <ng-container *ngFor="let baseGroup of reportData.groups; let idx = index">
                                    <ng-container
                                        *ngTemplateOutlet="
                                            groupTemplate;
                                            context: {g: node.groups[baseGroup.entity_id], i: idx}
                                        "
                                    ></ng-container>
                                </ng-container>
                                <ng-template #groupTemplate let-group="g" let-idx="i">
                                    <div class="item-value amount-text">
                                        <div
                                            *ngIf="node.type === 'total'"
                                            [ngClass]="{'negative-value': group.total_with_children < 0}"
                                        >
                                            <span
                                                class="open-sidenav"
                                                (click)="
                                                    openSideNav(
                                                        node,
                                                        node.la_type || 'cost_of_goods_sold',
                                                        costOfGoodsDataSource.data,
                                                        idx,
                                                        undefined,
                                                        group.name
                                                    )
                                                "
                                            >
                                                {{ group.total_with_children | reportFormatted: 'financial' }}
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="node.type !== 'total'"
                                            [ngClass]="{'negative-value': group.total < 0}"
                                        >
                                            <span
                                                class="open-sidenav"
                                                (click)="
                                                    openSideNav(
                                                        node,
                                                        node.la_type || 'cost_of_goods_sold',
                                                        costOfGoodsDataSource.data,
                                                        idx,
                                                        undefined,
                                                        group.name
                                                    )
                                                "
                                            >
                                                {{ group.total | reportFormatted: 'financial' }}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        class="item-value"
                                        *ngIf="
                                            idx > 0 &&
                                            report.settings.compare_to_periods?.display_options?.show_amount_change
                                        "
                                    >
                                        <div
                                            *ngIf="node.type === 'total'"
                                            [ngClass]="{'negative-value': group.total_with_children_change_amount < 0}"
                                        >
                                            {{ group.total_with_children_change_amount | reportFormatted: 'financial' }}
                                        </div>
                                        <div
                                            *ngIf="node.type !== 'total'"
                                            [ngClass]="{'negative-value': group.total_change_amount < 0}"
                                        >
                                            {{ group.total_change_amount | reportFormatted: 'financial' }}
                                        </div>
                                    </div>
                                    <div
                                        class="item-value"
                                        *ngIf="
                                            idx > 0 &&
                                            report.settings.compare_to_periods?.display_options?.show_percent_change
                                        "
                                    >
                                        <div *ngIf="node.type === 'total'">
                                            {{
                                                group.total_with_children_change_percent
                                                    | reportFormatted: 'smart_percent'
                                            }}
                                        </div>
                                        <div *ngIf="node.type !== 'total'">
                                            {{ group.total_change_percent | reportFormatted: 'smart_percent' }}
                                        </div>
                                    </div>
                                </ng-template>

                                <div
                                    class="item-value amount-text"
                                    *ngIf="
                                        !report.settings.compare_to_periods ||
                                        !report.settings.compare_to_periods?.periods ||
                                        report.settings.compare_to_periods?.periods?.length === 0
                                    "
                                    [ngClass]="{'negative-value': node.total < 0}"
                                >
                                    <span
                                        class="open-sidenav"
                                        (click)="
                                            openSideNav(
                                                node,
                                                node.la_type || 'cost_of_goods_sold',
                                                costOfGoodsDataSource.data
                                            )
                                        "
                                    >
                                        {{ node.total | reportFormatted: 'financial' }}
                                    </span>
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.la_name"
                                    (click)="changeState(node)"
                                >
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{ treeControlCostOfGoods.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                    </mat-icon>
                                </button>

                                <div class="item w-100 flex-row">
                                    <div class="item-name">
                                        {{ node.la_name }}
                                    </div>

                                    <ng-container *ngFor="let baseGroup of reportData.groups; let idx = index">
                                        <ng-container
                                            *ngTemplateOutlet="
                                                groupTemplate;
                                                context: {g: node.groups[baseGroup.entity_id], i: idx}
                                            "
                                        ></ng-container>
                                    </ng-container>
                                    <ng-template #groupTemplate let-group="g" let-idx="i">
                                        <div class="item-value amount-text">
                                            <div
                                                *ngIf="node.type === 'header' && !node.expanded"
                                                [ngClass]="{'negative-value': group.total_with_children < 0}"
                                            >
                                                <span
                                                    class="open-sidenav"
                                                    (click)="
                                                        openSideNav(
                                                            node,
                                                            node.la_type || 'cost_of_goods_sold',
                                                            costOfGoodsDataSource.data,
                                                            idx,
                                                            node.expanded,
                                                            group.name
                                                        )
                                                    "
                                                >
                                                    {{
                                                        (node.expanded ? null : group.total_with_children)
                                                            | reportFormatted: 'financial'
                                                    }}
                                                </span>
                                            </div>

                                            <div
                                                *ngIf="node.type !== 'header'"
                                                [ngClass]="{
                                                    'negative-value': node.expanded
                                                        ? group.total < 0
                                                        : group.total_with_children < 0
                                                }"
                                            >
                                                <span
                                                    class="open-sidenav"
                                                    (click)="
                                                        openSideNav(
                                                            node,
                                                            node.la_type || 'cost_of_goods_sold',
                                                            costOfGoodsDataSource.data,
                                                            idx,
                                                            node.expanded,
                                                            group.name
                                                        )
                                                    "
                                                >
                                                    {{
                                                        (node.expanded ? group.total : group.total_with_children)
                                                            | reportFormatted: 'financial'
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            class="item-value"
                                            *ngIf="
                                                idx > 0 &&
                                                report.settings.compare_to_periods?.display_options?.show_amount_change
                                            "
                                        >
                                            <div
                                                *ngIf="node.type === 'header' && !node.expanded"
                                                [ngClass]="{
                                                    'negative-value': group.total_with_children_change_amount < 0
                                                }"
                                            >
                                                {{
                                                    (node.expanded ? null : group.total_with_children_change_amount)
                                                        | reportFormatted: 'financial'
                                                }}
                                            </div>
                                            <div
                                                *ngIf="node.type !== 'header'"
                                                [ngClass]="{
                                                    'negative-value': node.expanded
                                                        ? group.total_change_amount < 0
                                                        : group.total_with_children_change_amount < 0
                                                }"
                                            >
                                                {{
                                                    (node.expanded
                                                        ? group.total_change_amount
                                                        : group.total_with_children_change_amount
                                                    ) | reportFormatted: 'financial'
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="item-value"
                                            *ngIf="
                                                idx > 0 &&
                                                report.settings.compare_to_periods?.display_options?.show_percent_change
                                            "
                                        >
                                            <div *ngIf="node.type === 'header' && !node.expanded">
                                                {{
                                                    (node.expanded ? null : group.total_with_children_change_percent)
                                                        | reportFormatted: 'smart_percent'
                                                }}
                                            </div>
                                            <div *ngIf="node.type !== 'header'">
                                                {{
                                                    (node.expanded
                                                        ? group.total_change_percent
                                                        : group.total_with_children_change_percent
                                                    ) | reportFormatted: 'smart_percent'
                                                }}
                                            </div>
                                        </div>
                                    </ng-template>

                                    <div
                                        class="item-value amount-text"
                                        *ngIf="
                                            !report.settings.compare_to_periods ||
                                            !report.settings.compare_to_periods?.periods ||
                                            report.settings.compare_to_periods?.periods?.length === 0
                                        "
                                        [ngClass]="{
                                            'negative-value': node.expanded
                                                ? node.total < 0
                                                : node.total_with_children < 0
                                        }"
                                    >
                                        <span
                                            class="open-sidenav"
                                            (click)="
                                                openSideNav(
                                                    node,
                                                    node.la_type || 'cost_of_goods_sold',
                                                    costOfGoodsDataSource.data,
                                                    undefined,
                                                    node.expanded
                                                )
                                            "
                                        >
                                            {{
                                                (node.expanded ? node.total : node.total_with_children)
                                                    | reportFormatted: 'financial'
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <ul [class.tree-invisible]="!treeControlCostOfGoods.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
                <div class="flex-row font-weight-bold profit">
                    <div class="item-name profit-name">Gross Profit</div>

                    <ng-container *ngFor="let baseGroup of reportData.groups; let idx = index">
                        <ng-container
                            *ngTemplateOutlet="
                                groupTemplate;
                                context: {g: reportData.totals.groups.gross_profit[baseGroup.entity_id], i: idx}
                            "
                        ></ng-container>
                    </ng-container>
                    <ng-template #groupTemplate let-group="g" let-idx="i">
                        <div class="item-value profit-item amount-text">
                            <div [ngClass]="{'negative-value': group.total < 0}">
                                <span>{{ group.total | reportFormatted: 'financial' }}</span>
                            </div>
                        </div>

                        <div
                            class="item-value profit-item"
                            *ngIf="idx > 0 && report.settings.compare_to_periods?.display_options?.show_amount_change"
                        >
                            <div [ngClass]="{'negative-value': group.total_with_children_change_amount < 0}">
                                {{ group.total_with_children_change_amount | reportFormatted: 'financial' }}
                            </div>
                        </div>
                        <div
                            class="item-value profit-item"
                            *ngIf="idx > 0 && report.settings.compare_to_periods?.display_options?.show_percent_change"
                        >
                            <div [ngClass]="{'negative-value': group.total_with_children_change_percent < 0}">
                                {{ group.total_with_children_change_percent | reportFormatted: 'smart_percent' }}
                            </div>
                        </div>
                    </ng-template>

                    <div
                        class="item-value profit-item amount-text"
                        *ngIf="
                            !report.settings.compare_to_periods ||
                            !report.settings.compare_to_periods?.periods ||
                            report.settings.compare_to_periods?.periods?.length === 0
                        "
                        [ngClass]="{'negative-value': reportData.totals.gross_profit < 0}"
                    >
                        <span>{{ reportData.totals.gross_profit | reportFormatted: 'financial' }}</span>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="reportData.expense.length">
                <p class="header-group">Operating Expense</p>

                <mat-tree [dataSource]="expenseDataSource" [treeControl]="treeControlExpense" class="tree">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                        <li class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div class="item w-100 flex-row" [ngClass]="{'total-row': node.type === 'total'}">
                                <div class="item-name">
                                    {{ node.la_name }}
                                </div>

                                <ng-container *ngFor="let baseGroup of reportData.groups; let idx = index">
                                    <ng-container
                                        *ngTemplateOutlet="
                                            groupTemplate;
                                            context: {g: node.groups[baseGroup.entity_id], i: idx}
                                        "
                                    ></ng-container>
                                </ng-container>
                                <ng-template #groupTemplate let-group="g" let-idx="i">
                                    <div class="item-value amount-text">
                                        <div
                                            *ngIf="node.type === 'total'"
                                            [ngClass]="{'negative-value': group.total_with_children < 0}"
                                        >
                                            <span
                                                class="open-sidenav"
                                                (click)="
                                                    openSideNav(
                                                        node,
                                                        node.la_type || 'expense',
                                                        expenseDataSource.data,
                                                        idx,
                                                        undefined,
                                                        group.name
                                                    )
                                                "
                                            >
                                                {{ group.total_with_children | reportFormatted: 'financial' }}
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="node.type !== 'total'"
                                            [ngClass]="{'negative-value': group.total < 0}"
                                        >
                                            <span
                                                class="open-sidenav"
                                                (click)="
                                                    openSideNav(
                                                        node,
                                                        node.la_type || 'expense',
                                                        expenseDataSource.data,
                                                        idx,
                                                        undefined,
                                                        group.name
                                                    )
                                                "
                                            >
                                                {{ group.total | reportFormatted: 'financial' }}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        class="item-value"
                                        *ngIf="
                                            idx > 0 &&
                                            report.settings.compare_to_periods?.display_options?.show_amount_change
                                        "
                                    >
                                        <div
                                            *ngIf="node.type === 'total'"
                                            [ngClass]="{'negative-value': group.total_with_children_change_amount < 0}"
                                        >
                                            {{ group.total_with_children_change_amount | reportFormatted: 'financial' }}
                                        </div>
                                        <div
                                            *ngIf="node.type !== 'total'"
                                            [ngClass]="{'negative-value': group.total_change_amount < 0}"
                                        >
                                            {{ group.total_change_amount | reportFormatted: 'financial' }}
                                        </div>
                                    </div>
                                    <div
                                        class="item-value"
                                        *ngIf="
                                            idx > 0 &&
                                            report.settings.compare_to_periods?.display_options?.show_percent_change
                                        "
                                    >
                                        <div *ngIf="node.type === 'total'">
                                            {{
                                                group.total_with_children_change_percent
                                                    | reportFormatted: 'smart_percent'
                                            }}
                                        </div>
                                        <div *ngIf="node.type !== 'total'">
                                            {{ group.total_change_percent | reportFormatted: 'smart_percent' }}
                                        </div>
                                    </div>
                                </ng-template>

                                <div
                                    class="item-value amount-text"
                                    *ngIf="
                                        !report.settings.compare_to_periods ||
                                        !report.settings.compare_to_periods?.periods ||
                                        report.settings.compare_to_periods?.periods?.length === 0
                                    "
                                    [ngClass]="{'negative-value': node.total < 0}"
                                >
                                    <span
                                        class="open-sidenav"
                                        (click)="openSideNav(node, node.la_type || 'expense', expenseDataSource.data)"
                                    >
                                        {{ node.total | reportFormatted: 'financial' }}
                                    </span>
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.la_name"
                                    (click)="changeState(node)"
                                >
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{ treeControlExpense.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                    </mat-icon>
                                </button>

                                <div class="item w-100 flex-row">
                                    <div class="item-name">
                                        {{ node.la_name }}
                                    </div>

                                    <ng-container *ngFor="let baseGroup of reportData.groups; let idx = index">
                                        <ng-container
                                            *ngTemplateOutlet="
                                                groupTemplate;
                                                context: {g: node.groups[baseGroup.entity_id], i: idx}
                                            "
                                        ></ng-container>
                                    </ng-container>
                                    <ng-template #groupTemplate let-group="g" let-idx="i">
                                        <div class="item-value amount-text">
                                            <div
                                                *ngIf="node.type === 'header' && !node.expanded"
                                                [ngClass]="{'negative-value': group.total_with_children < 0}"
                                            >
                                                <span
                                                    class="open-sidenav"
                                                    (click)="
                                                        openSideNav(
                                                            node,
                                                            node.la_type || 'expense',
                                                            expenseDataSource.data,
                                                            idx,
                                                            node.expanded,
                                                            group.name
                                                        )
                                                    "
                                                >
                                                    {{
                                                        (node.expanded ? null : group.total_with_children)
                                                            | reportFormatted: 'financial'
                                                    }}
                                                </span>
                                            </div>

                                            <div
                                                *ngIf="node.type !== 'header'"
                                                [ngClass]="{
                                                    'negative-value': node.expanded
                                                        ? group.total < 0
                                                        : group.total_with_children < 0
                                                }"
                                            >
                                                <span
                                                    class="open-sidenav"
                                                    (click)="
                                                        openSideNav(
                                                            node,
                                                            node.la_type || 'expense',
                                                            expenseDataSource.data,
                                                            idx,
                                                            node.expanded,
                                                            group.name
                                                        )
                                                    "
                                                >
                                                    {{
                                                        (node.expanded ? group.total : group.total_with_children)
                                                            | reportFormatted: 'financial'
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            class="item-value"
                                            *ngIf="
                                                idx > 0 &&
                                                report.settings.compare_to_periods?.display_options?.show_amount_change
                                            "
                                        >
                                            <div
                                                *ngIf="node.type === 'header' && !node.expanded"
                                                [ngClass]="{
                                                    'negative-value': group.total_with_children_change_amount < 0
                                                }"
                                            >
                                                {{
                                                    (node.expanded ? null : group.total_with_children_change_amount)
                                                        | reportFormatted: 'financial'
                                                }}
                                            </div>
                                            <div
                                                *ngIf="node.type !== 'header'"
                                                [ngClass]="{
                                                    'negative-value': node.expanded
                                                        ? group.total_change_amount < 0
                                                        : group.total_with_children_change_amount < 0
                                                }"
                                            >
                                                {{
                                                    (node.expanded
                                                        ? group.total_change_amount
                                                        : group.total_with_children_change_amount
                                                    ) | reportFormatted: 'financial'
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="item-value"
                                            *ngIf="
                                                idx > 0 &&
                                                report.settings.compare_to_periods?.display_options?.show_percent_change
                                            "
                                        >
                                            <div *ngIf="node.type === 'header' && !node.expanded">
                                                {{
                                                    (node.expanded ? null : group.total_with_children_change_percent)
                                                        | reportFormatted: 'smart_percent'
                                                }}
                                            </div>
                                            <div *ngIf="node.type !== 'header'">
                                                {{
                                                    (node.expanded
                                                        ? group.total_change_percent
                                                        : group.total_with_children_change_percent
                                                    ) | reportFormatted: 'smart_percent'
                                                }}
                                            </div>
                                        </div>
                                    </ng-template>

                                    <div
                                        class="item-value amount-text"
                                        *ngIf="
                                            !report.settings.compare_to_periods ||
                                            !report.settings.compare_to_periods?.periods ||
                                            report.settings.compare_to_periods?.periods?.length === 0
                                        "
                                        [ngClass]="{
                                            'negative-value': node.expanded
                                                ? node.total < 0
                                                : node.total_with_children < 0
                                        }"
                                    >
                                        <span
                                            class="open-sidenav"
                                            (click)="
                                                openSideNav(
                                                    node,
                                                    node.la_type || 'expense',
                                                    expenseDataSource.data,
                                                    undefined,
                                                    node.expanded
                                                )
                                            "
                                        >
                                            {{
                                                (node.expanded ? node.total : node.total_with_children)
                                                    | reportFormatted: 'financial'
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <ul [class.tree-invisible]="!treeControlExpense.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
                <div class="flex-row font-weight-bold profit">
                    <div class="item-name profit-name">Operating Profit/Loss</div>

                    <ng-container *ngFor="let baseGroup of reportData.groups; let idx = index">
                        <ng-container
                            *ngTemplateOutlet="
                                groupTemplate;
                                context: {g: reportData.totals.groups.operating_profit[baseGroup.entity_id], i: idx}
                            "
                        ></ng-container>
                    </ng-container>
                    <ng-template #groupTemplate let-group="g" let-idx="i">
                        <div class="item-value profit-item amount-text">
                            <div [ngClass]="{'negative-value': group.total < 0}">
                                <span>{{ group.total | reportFormatted: 'financial' }}</span>
                            </div>
                        </div>

                        <div
                            class="item-value profit-item"
                            *ngIf="idx > 0 && report.settings.compare_to_periods?.display_options?.show_amount_change"
                        >
                            <div [ngClass]="{'negative-value': group.total_with_children_change_amount < 0}">
                                {{ group.total_with_children_change_amount | reportFormatted: 'financial' }}
                            </div>
                        </div>
                        <div
                            class="item-value profit-item"
                            *ngIf="idx > 0 && report.settings.compare_to_periods?.display_options?.show_percent_change"
                        >
                            <div [ngClass]="{'negative-value': group.total_with_children_change_percent < 0}">
                                {{ group.total_with_children_change_percent | reportFormatted: 'smart_percent' }}
                            </div>
                        </div>
                    </ng-template>

                    <div
                        class="item-value profit-item amount-text"
                        *ngIf="
                            !report.settings.compare_to_periods ||
                            !report.settings.compare_to_periods?.periods ||
                            report.settings.compare_to_periods?.periods?.length === 0
                        "
                        [ngClass]="{'negative-value': reportData.totals.operating_profit < 0}"
                    >
                        <span>{{ reportData.totals.operating_profit | reportFormatted: 'financial' }}</span>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="reportData.other_expense.length">
                <p class="header-group">Non Operating Income/Expense</p>

                <mat-tree [dataSource]="otherIncomeDataSource" [treeControl]="treeControlOtherIncome" class="tree">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                        <li class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div class="item w-100 flex-row" [ngClass]="{'total-row': node.type === 'total'}">
                                <div class="item-name">
                                    {{ node.la_name }}
                                </div>

                                <ng-container *ngFor="let baseGroup of reportData.groups; let idx = index">
                                    <ng-container
                                        *ngTemplateOutlet="
                                            groupTemplate;
                                            context: {g: node.groups[baseGroup.entity_id], i: idx}
                                        "
                                    ></ng-container>
                                </ng-container>
                                <ng-template #groupTemplate let-group="g" let-idx="i">
                                    <div class="item-value amount-text">
                                        <div
                                            *ngIf="node.type === 'total'"
                                            [ngClass]="{'negative-value': group.total_with_children < 0}"
                                        >
                                            <span
                                                class="open-sidenav"
                                                (click)="
                                                    openSideNav(
                                                        node,
                                                        node.la_type || 'other_income',
                                                        otherIncomeDataSource.data,
                                                        idx,
                                                        undefined,
                                                        group.name
                                                    )
                                                "
                                            >
                                                {{ group.total_with_children | reportFormatted: 'financial' }}
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="node.type !== 'total'"
                                            [ngClass]="{'negative-value': group.total < 0}"
                                        >
                                            <span
                                                class="open-sidenav"
                                                (click)="
                                                    openSideNav(
                                                        node,
                                                        node.la_type || 'other_income',
                                                        otherIncomeDataSource.data,
                                                        idx,
                                                        undefined,
                                                        group.name
                                                    )
                                                "
                                            >
                                                {{ group.total | reportFormatted: 'financial' }}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        class="item-value"
                                        *ngIf="
                                            idx > 0 &&
                                            report.settings.compare_to_periods?.display_options?.show_amount_change
                                        "
                                    >
                                        <div
                                            *ngIf="node.type === 'total'"
                                            [ngClass]="{'negative-value': group.total_with_children_change_amount < 0}"
                                        >
                                            {{ group.total_with_children_change_amount | reportFormatted: 'financial' }}
                                        </div>
                                        <div
                                            *ngIf="node.type !== 'total'"
                                            [ngClass]="{'negative-value': group.total_change_amount < 0}"
                                        >
                                            {{ group.total_change_amount | reportFormatted: 'financial' }}
                                        </div>
                                    </div>
                                    <div
                                        class="item-value"
                                        *ngIf="
                                            idx > 0 &&
                                            report.settings.compare_to_periods?.display_options?.show_percent_change
                                        "
                                    >
                                        <div *ngIf="node.type === 'total'">
                                            {{
                                                group.total_with_children_change_percent
                                                    | reportFormatted: 'smart_percent'
                                            }}
                                        </div>
                                        <div *ngIf="node.type !== 'total'">
                                            {{ group.total_change_percent | reportFormatted: 'smart_percent' }}
                                        </div>
                                    </div>
                                </ng-template>

                                <div
                                    class="item-value amount-text"
                                    *ngIf="
                                        !report.settings.compare_to_periods ||
                                        !report.settings.compare_to_periods?.periods ||
                                        report.settings.compare_to_periods?.periods?.length === 0
                                    "
                                    [ngClass]="{'negative-value': node.total < 0}"
                                >
                                    <span
                                        class="open-sidenav"
                                        (click)="
                                            openSideNav(
                                                node,
                                                node.la_type || 'other_income',
                                                otherIncomeDataSource.data
                                            )
                                        "
                                    >
                                        {{ node.total | reportFormatted: 'financial' }}
                                    </span>
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.la_name"
                                    (click)="changeState(node)"
                                >
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{ treeControlOtherIncome.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                    </mat-icon>
                                </button>

                                <div class="item w-100 flex-row">
                                    <div class="item-name">
                                        {{ node.la_name }}
                                    </div>

                                    <ng-container *ngFor="let baseGroup of reportData.groups; let idx = index">
                                        <ng-container
                                            *ngTemplateOutlet="
                                                groupTemplate;
                                                context: {g: node.groups[baseGroup.entity_id], i: idx}
                                            "
                                        ></ng-container>
                                    </ng-container>
                                    <ng-template #groupTemplate let-group="g" let-idx="i">
                                        <div class="item-value amount-text">
                                            <div
                                                *ngIf="node.type === 'header' && !node.expanded"
                                                [ngClass]="{'negative-value': group.total_with_children < 0}"
                                            >
                                                <span
                                                    class="open-sidenav"
                                                    (click)="
                                                        openSideNav(
                                                            node,
                                                            node.la_type || 'other_income',
                                                            otherIncomeDataSource.data,
                                                            idx,
                                                            node.expanded,
                                                            group.name
                                                        )
                                                    "
                                                >
                                                    {{
                                                        (node.expanded ? null : group.total_with_children)
                                                            | reportFormatted: 'financial'
                                                    }}
                                                </span>
                                            </div>

                                            <div
                                                *ngIf="node.type !== 'header'"
                                                [ngClass]="{
                                                    'negative-value': node.expanded
                                                        ? group.total < 0
                                                        : group.total_with_children < 0
                                                }"
                                            >
                                                <span
                                                    class="open-sidenav"
                                                    (click)="
                                                        openSideNav(
                                                            node,
                                                            node.la_type || 'other_income',
                                                            otherIncomeDataSource.data,
                                                            idx,
                                                            node.expanded,
                                                            group.name
                                                        )
                                                    "
                                                >
                                                    {{
                                                        (node.expanded ? group.total : group.total_with_children)
                                                            | reportFormatted: 'financial'
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            class="item-value"
                                            *ngIf="
                                                idx > 0 &&
                                                report.settings.compare_to_periods?.display_options?.show_amount_change
                                            "
                                        >
                                            <div
                                                *ngIf="node.type === 'header' && !node.expanded"
                                                [ngClass]="{
                                                    'negative-value': group.total_with_children_change_amount < 0
                                                }"
                                            >
                                                {{
                                                    (node.expanded ? null : group.total_with_children_change_amount)
                                                        | reportFormatted: 'financial'
                                                }}
                                            </div>
                                            <div
                                                *ngIf="node.type !== 'header'"
                                                [ngClass]="{
                                                    'negative-value': node.expanded
                                                        ? group.total_change_amount < 0
                                                        : group.total_with_children_change_amount < 0
                                                }"
                                            >
                                                {{
                                                    (node.expanded
                                                        ? group.total_change_amount
                                                        : group.total_with_children_change_amount
                                                    ) | reportFormatted: 'financial'
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="item-value"
                                            *ngIf="
                                                idx > 0 &&
                                                report.settings.compare_to_periods?.display_options?.show_percent_change
                                            "
                                        >
                                            <div *ngIf="node.type === 'header' && !node.expanded">
                                                {{
                                                    (node.expanded ? null : group.total_with_children_change_percent)
                                                        | reportFormatted: 'smart_percent'
                                                }}
                                            </div>
                                            <div *ngIf="node.type !== 'header'">
                                                {{
                                                    (node.expanded
                                                        ? group.total_change_percent
                                                        : group.total_with_children_change_percent
                                                    ) | reportFormatted: 'smart_percent'
                                                }}
                                            </div>
                                        </div>
                                    </ng-template>

                                    <div
                                        class="item-value amount-text"
                                        *ngIf="
                                            !report.settings.compare_to_periods ||
                                            !report.settings.compare_to_periods?.periods ||
                                            report.settings.compare_to_periods?.periods?.length === 0
                                        "
                                        [ngClass]="{
                                            'negative-value': node.expanded
                                                ? node.total < 0
                                                : node.total_with_children < 0
                                        }"
                                    >
                                        <span
                                            class="open-sidenav"
                                            (click)="
                                                openSideNav(
                                                    node,
                                                    node.la_type || 'other_income',
                                                    otherIncomeDataSource.data,
                                                    undefined,
                                                    node.expanded
                                                )
                                            "
                                        >
                                            {{
                                                (node.expanded ? node.total : node.total_with_children)
                                                    | reportFormatted: 'financial'
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <ul [class.tree-invisible]="!treeControlOtherIncome.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>

                <mat-tree [dataSource]="otherExpenseDataSource" [treeControl]="treeControlOtherExpense" class="tree">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                        <li class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div class="item w-100 flex-row" [ngClass]="{'total-row': node.type === 'total'}">
                                <div class="item-name">
                                    {{ node.la_name }}
                                </div>

                                <ng-container *ngFor="let baseGroup of reportData.groups; let idx = index">
                                    <ng-container
                                        *ngTemplateOutlet="
                                            groupTemplate;
                                            context: {g: node.groups[baseGroup.entity_id], i: idx}
                                        "
                                    ></ng-container>
                                </ng-container>
                                <ng-template #groupTemplate let-group="g" let-idx="i">
                                    <div class="item-value amount-text">
                                        <div
                                            *ngIf="node.type === 'total'"
                                            [ngClass]="{'negative-value': group.total_with_children < 0}"
                                        >
                                            <span
                                                class="open-sidenav"
                                                (click)="
                                                    openSideNav(
                                                        node,
                                                        node.la_type || 'non_operating_income_expense',
                                                        otherExpenseDataSource.data,
                                                        idx,
                                                        undefined,
                                                        group.name
                                                    )
                                                "
                                            >
                                                {{ group.total_with_children | reportFormatted: 'financial' }}
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="node.type !== 'total'"
                                            [ngClass]="{'negative-value': group.total < 0}"
                                        >
                                            <span
                                                class="open-sidenav"
                                                (click)="
                                                    openSideNav(
                                                        node,
                                                        node.la_type || 'non_operating_income_expense',
                                                        otherExpenseDataSource.data,
                                                        idx,
                                                        undefined,
                                                        group.name
                                                    )
                                                "
                                            >
                                                {{ group.total | reportFormatted: 'financial' }}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        class="item-value"
                                        *ngIf="
                                            idx > 0 &&
                                            report.settings.compare_to_periods?.display_options?.show_amount_change
                                        "
                                    >
                                        <div
                                            *ngIf="node.type === 'total'"
                                            [ngClass]="{'negative-value': group.total_with_children_change_amount < 0}"
                                        >
                                            {{ group.total_with_children_change_amount | reportFormatted: 'financial' }}
                                        </div>
                                        <div
                                            *ngIf="node.type !== 'total'"
                                            [ngClass]="{'negative-value': group.total_change_amount < 0}"
                                        >
                                            {{ group.total_change_amount | reportFormatted: 'financial' }}
                                        </div>
                                    </div>
                                    <div
                                        class="item-value"
                                        *ngIf="
                                            idx > 0 &&
                                            report.settings.compare_to_periods?.display_options?.show_percent_change
                                        "
                                    >
                                        <div *ngIf="node.type === 'total'">
                                            {{
                                                group.total_with_children_change_percent
                                                    | reportFormatted: 'smart_percent'
                                            }}
                                        </div>
                                        <div *ngIf="node.type !== 'total'">
                                            {{ group.total_change_percent | reportFormatted: 'smart_percent' }}
                                        </div>
                                    </div>
                                </ng-template>

                                <div
                                    class="item-value amount-text"
                                    *ngIf="
                                        !report.settings.compare_to_periods ||
                                        !report.settings.compare_to_periods?.periods ||
                                        report.settings.compare_to_periods?.periods?.length === 0
                                    "
                                    [ngClass]="{'negative-value': node.total < 0}"
                                >
                                    <span
                                        class="open-sidenav"
                                        (click)="
                                            openSideNav(
                                                node,
                                                node.la_type ||
                                                    (node.total_with_children !== undefined
                                                        ? 'other_expense'
                                                        : 'non_operating_income_expense'),
                                                otherExpenseDataSource.data
                                            )
                                        "
                                    >
                                        {{ node.total | reportFormatted: 'financial' }}
                                    </span>
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.la_name"
                                    (click)="changeState(node)"
                                >
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{ treeControlOtherExpense.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                    </mat-icon>
                                </button>

                                <div class="item w-100 flex-row">
                                    <div class="item-name">
                                        {{ node.la_name }}
                                    </div>

                                    <ng-container *ngFor="let baseGroup of reportData.groups; let idx = index">
                                        <ng-container
                                            *ngTemplateOutlet="
                                                groupTemplate;
                                                context: {g: node.groups[baseGroup.entity_id], i: idx}
                                            "
                                        ></ng-container>
                                    </ng-container>
                                    <ng-template #groupTemplate let-group="g" let-idx="i">
                                        <div class="item-value amount-text">
                                            <div
                                                *ngIf="node.type === 'header' && !node.expanded"
                                                [ngClass]="{'negative-value': group.total_with_children < 0}"
                                            >
                                                <span
                                                    class="open-sidenav"
                                                    (click)="
                                                        openSideNav(
                                                            node,
                                                            node.la_type || 'other_expense',
                                                            otherExpenseDataSource.data,
                                                            idx,
                                                            node.expanded,
                                                            group.name
                                                        )
                                                    "
                                                >
                                                    {{
                                                        (node.expanded ? null : group.total_with_children)
                                                            | reportFormatted: 'financial'
                                                    }}
                                                </span>
                                            </div>

                                            <div
                                                *ngIf="node.type !== 'header'"
                                                [ngClass]="{
                                                    'negative-value': node.expanded
                                                        ? group.total < 0
                                                        : group.total_with_children < 0
                                                }"
                                            >
                                                <span
                                                    class="open-sidenav"
                                                    (click)="
                                                        openSideNav(
                                                            node,
                                                            node.la_type || 'other_expense',
                                                            otherExpenseDataSource.data,
                                                            idx,
                                                            node.expanded,
                                                            group.name
                                                        )
                                                    "
                                                >
                                                    {{
                                                        (node.expanded ? group.total : group.total_with_children)
                                                            | reportFormatted: 'financial'
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            class="item-value"
                                            *ngIf="
                                                idx > 0 &&
                                                report.settings.compare_to_periods?.display_options?.show_amount_change
                                            "
                                        >
                                            <div
                                                *ngIf="node.type === 'header' && !node.expanded"
                                                [ngClass]="{
                                                    'negative-value': group.total_with_children_change_amount < 0
                                                }"
                                            >
                                                {{
                                                    (node.expanded ? null : group.total_with_children_change_amount)
                                                        | reportFormatted: 'financial'
                                                }}
                                            </div>
                                            <div
                                                *ngIf="node.type !== 'header'"
                                                [ngClass]="{
                                                    'negative-value': node.expanded
                                                        ? group.total_change_amount < 0
                                                        : group.total_with_children_change_amount < 0
                                                }"
                                            >
                                                {{
                                                    (node.expanded
                                                        ? group.total_change_amount
                                                        : group.total_with_children_change_amount
                                                    ) | reportFormatted: 'financial'
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="item-value"
                                            *ngIf="
                                                idx > 0 &&
                                                report.settings.compare_to_periods?.display_options?.show_percent_change
                                            "
                                        >
                                            <div *ngIf="node.type === 'header' && !node.expanded">
                                                {{
                                                    (node.expanded ? null : group.total_with_children_change_percent)
                                                        | reportFormatted: 'smart_percent'
                                                }}
                                            </div>
                                            <div *ngIf="node.type !== 'header'">
                                                {{
                                                    (node.expanded
                                                        ? group.total_change_percent
                                                        : group.total_with_children_change_percent
                                                    ) | reportFormatted: 'smart_percent'
                                                }}
                                            </div>
                                        </div>
                                    </ng-template>

                                    <div
                                        class="item-value amount-text"
                                        *ngIf="
                                            !report.settings.compare_to_periods ||
                                            !report.settings.compare_to_periods?.periods ||
                                            report.settings.compare_to_periods?.periods?.length === 0
                                        "
                                        [ngClass]="{
                                            'negative-value': node.expanded
                                                ? node.total < 0
                                                : node.total_with_children < 0
                                        }"
                                    >
                                        <span
                                            class="open-sidenav"
                                            (click)="
                                                openSideNav(
                                                    node,
                                                    node.la_type || 'other_expense',
                                                    otherExpenseDataSource.data,
                                                    undefined,
                                                    node.expanded
                                                )
                                            "
                                        >
                                            {{
                                                (node.expanded ? node.total : node.total_with_children)
                                                    | reportFormatted: 'financial'
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <ul [class.tree-invisible]="!treeControlOtherExpense.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>

                <div class="flex-row font-weight-bold profit">
                    <div class="item-name profit-name">Net Profit/Loss</div>

                    <ng-container *ngFor="let baseGroup of reportData.groups; let idx = index">
                        <ng-container
                            *ngTemplateOutlet="
                                groupTemplate;
                                context: {g: reportData.totals.groups.net_profit[baseGroup.entity_id], i: idx}
                            "
                        ></ng-container>
                    </ng-container>
                    <ng-template #groupTemplate let-group="g" let-idx="i">
                        <div class="item-value profit-item amount-text">
                            <div [ngClass]="{'negative-value': group.total < 0}">
                                <span>{{ group.total | reportFormatted: 'financial' }}</span>
                            </div>
                        </div>

                        <div
                            class="item-value profit-item"
                            *ngIf="idx > 0 && report.settings.compare_to_periods?.display_options?.show_amount_change"
                        >
                            <div [ngClass]="{'negative-value': group.total_with_children_change_amount < 0}">
                                {{ group.total_with_children_change_amount | reportFormatted: 'financial' }}
                            </div>
                        </div>
                        <div
                            class="item-value profit-item"
                            *ngIf="idx > 0 && report.settings.compare_to_periods?.display_options?.show_percent_change"
                        >
                            <div [ngClass]="{'negative-value': group.total_with_children_change_percent < 0}">
                                {{ group.total_with_children_change_percent | reportFormatted: 'smart_percent' }}
                            </div>
                        </div>
                    </ng-template>

                    <div
                        class="item-value profit-item amount-text"
                        *ngIf="
                            !report.settings.compare_to_periods ||
                            !report.settings.compare_to_periods?.periods ||
                            report.settings.compare_to_periods?.periods?.length === 0
                        "
                        [ngClass]="{'negative-value': reportData.totals.net_profit < 0}"
                    >
                        <span>
                            {{ reportData.totals.net_profit | reportFormatted: 'financial' }}
                        </span>
                    </div>
                </div>
            </ng-container>
        </div>

        <p class="m-2">*Amount is displayed in your base currency <span class="badge-secondary">USD</span></p>
        <p class="m-2" *ngIf="report && report.settings && report.settings.report_basis === 'cash'">
            **Financial Data Presented is on a Cash Basis
        </p>
    </mat-card>
</div>
