import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {Observable} from 'rxjs';
import {IPaymentMethod, IInvoice, IPayment, IPaymentGateway, IPaymentMade} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class PaymentsService {
    private url = '/finance/payments/';

    constructor(protected requester: ServiceRequester) {}

    getCompanyPayments(): Observable<IPayment[]> {
        return this.requester.makeMsCall$(this.url + 'company/', 'GET', 'shipp');
    }

    getProfilePayments(): Observable<IPayment[]> {
        return this.requester.makeMsCall$(this.url + 'profile/', 'GET', 'shipp');
    }

    cancelPayment(payment_id: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${payment_id}/cancel`, 'GET', 'shipp');
    }
}
