<mat-form-field class="w-100 report-autocomplete">
    <mat-chip-list #chipList aria-label="Tags selection">
        <ng-container *ngFor="let item of initialList">
            <mat-chip *ngIf="value.includes(item[valueAttribute])" (removed)="remove(item[valueAttribute])">
                {{ item[titleAttribute] }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </ng-container>
    </mat-chip-list>
    <input #input [matAutocomplete]="auto" [matChipInputFor]="chipList" (keyup)="search($event)" />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="select($event)">
        <ng-container *ngFor="let item of list">
            <mat-option
                [value]="item[valueAttribute]"
                [disabled]="item.deactivated"
                [matTooltip]="item.deactivated ? 'This Tag couldn\'t be selected because it\'s deactivated' : ''"
                *ngIf="!value.includes(item[valueAttribute])"
            >
                {{ item[titleAttribute] }}
            </mat-option>
        </ng-container>
    </mat-autocomplete>
</mat-form-field>
