import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../service.requester';
import {Observable} from 'rxjs';
import {IRecurringBill} from '../../../../typings';

@Injectable()
export class RecurringBillsService {
    private url = '/finance/recurring-bills/';

    constructor(public requester: ServiceRequester) {}

    getRecurringBills(params = {}): Observable<{result: IRecurringBill[]; _meta: {total: number}}> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp', params);
    }

    getRecurringBillById(recurringBillId: number): Observable<IRecurringBill> {
        return this.requester.makeMsCall$(this.url + `${recurringBillId}`, 'GET', 'shipp');
    }

    createRecurringBill(data: IRecurringBill): Observable<IRecurringBill> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    updateRecurringBill(recurringBillId: number, data: IRecurringBill): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + recurringBillId, 'PUT', 'shipp', data);
    }

    deleteRecurringBill(recurringBillId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + recurringBillId, 'DELETE', 'shipp');
    }

    bulkDeleteRecurringBills(data: number[]): Observable<number> {
        return this.requester.makeMsCall$(this.url + 'bulk/delete', 'PUT', 'shipp', data);
    }
}
