<div *ngIf="profile" class="p-3">
    <div class="header" *ngIf="!profile.id">{{ profile.id ? 'Edit' : 'Create' }} Profile</div>

    <mat-card-content class="d-flex flex-column">
        <form #userForm="ngForm" id="user-form" class="w-100">
            <mat-form-field class="w-20">
                <input
                    matInput
                    (blur)="emailCheck()"
                    placeholder="Email"
                    required
                    name="email"
                    [(ngModel)]="profile.email"
                />
                <mat-hint *ngIf="isExistingEmail" style="color: red">Profile Email Already Exists</mat-hint>
                <mat-hint *ngIf="!isValidEmail" style="color: red">Invalid Profile Email Address</mat-hint>
            </mat-form-field>

            <mat-form-field class="w-20">
                <input matInput placeholder="First Name" required name="first_name" [(ngModel)]="profile.first_name" />
            </mat-form-field>

            <mat-form-field class="w-20">
                <input matInput placeholder="Last Name" required name="last_name" [(ngModel)]="profile.last_name" />
            </mat-form-field>

            <mat-form-field class="w-20" *ngIf="!allCompanyRoles">
                <input type="tel" matInput placeholder="Profile Role(s)" [disabled]="true" />
                <mat-icon matSuffix class="icon-spinner"><mat-spinner></mat-spinner></mat-icon>
            </mat-form-field>
            <div class="edit-role-wrapper" style="display: inline-block" *ngIf="allCompanyRoles">
                <app-edit-user-roles
                    label="Profile Role(s)"
                    *ngIf="profileFG.controls.roles_ids"
                    [rolesIdsFC]="$any(profileFG.controls.roles_ids)"
                    [allRoles]="allCompanyRoles"
                    [companyPermissions]="companyPermissions"
                    (selectionChanged)="profileFG.controls.roles_ids.patchValue($event)"
                ></app-edit-user-roles>
            </div>
        </form>

        <div class="d-flex flex-row align-items-center">
            <div *ngIf="!profile.status || profile.status === 'invited'" class="d-flex">
                <mat-checkbox [(ngModel)]="profile.is_agent_company_needed">Create 'My Business' portal </mat-checkbox>
                <mat-icon
                    class="material-icons-outlined mx-2"
                    matTooltip="By checking this box, you will create a 'My Business' portal for this user. A 'My Business' portal is a financial management tool that provides the ability to receive commission payouts via electronic funds transfer, pay invoices, connect bank feeds, bookkeeping and financial reports all in one place."
                    >info</mat-icon
                >
            </div>
        </div>

        <div class="d-flex flex-row">
            <div class="roles-picker" style="margin-right: 50px">
                <h3>Divisions</h3>

                <app-company-compensation-combined-picker
                    (focusout)="closeDivisionsPanelEmitter$.next()"
                    displayMode="double-line"
                    style="margin-top: 12px"
                    [bold_style]="false"
                    [title]="''"
                    [only_compensation_groups]="false"
                    [without_company_group]="true"
                    [availableTypes]="['individuals_in_group']"
                    [nodesFA]="selectedDivisionsFA"
                    [onlyDivisions]="true"
                    [closePanelEvent$]="closeDivisionsPanelEmitter$"
                    [positionMode]="'none'"
                ></app-company-compensation-combined-picker>

                <div *ngFor="let division of selectedDivisions; index as mi" class="d-flex align-items-center">
                    <span class="mr-2 font-weight-bold"> {{ mi + 1 }}) {{ division.title }} </span>
                    <div class="d-inline-block">
                        <app-edit-user-roles
                            *ngIf="allCompanyRoles && companyPermissions"
                            label="Roles"
                            [roles_ids]="division.group_roles_ids"
                            [allRoles]="allCompanyRoles"
                            [companyPermissions]="companyPermissions"
                            [needToMoveHigher]="true"
                            (selectionChanged)="changeDivisionRoles(mi, $event)"
                        ></app-edit-user-roles>
                    </div>
                    <mat-icon class="remove-icon" (click)="removeDivision(mi)">cancel</mat-icon>
                </div>
            </div>

            <div class="roles-picker">
                <h3>Groups</h3>

                <app-company-compensation-combined-picker
                    (focusout)="closeGroupsPanelEmitter$.next()"
                    displayMode="double-line"
                    style="margin-top: 12px"
                    [bold_style]="false"
                    [title]="''"
                    [only_compensation_groups]="false"
                    [without_company_group]="true"
                    [availableTypes]="['individuals_in_group']"
                    [nodesFA]="selectedGroupsFA"
                    [onlyCommonGroups]="true"
                    [closePanelEvent$]="closeGroupsPanelEmitter$"
                    [positionMode]="'none'"
                ></app-company-compensation-combined-picker>

                <ng-container *ngFor="let group of selectedGroups; index as mi" class="d-flex flex-column">
                    <div class="mb-1 mr-2 font-weight-bold d-flex align-items-center" style="height: 40px">
                        {{ mi + 1 }}) {{ group.title }}
                        <mat-icon class="ml-1 remove-icon" (click)="removeGroup(mi)">cancel</mat-icon>
                    </div>
                </ng-container>
            </div>
        </div>
    </mat-card-content>

    <mat-card-actions class="d-flex justify-content-end align-items-baseline">
        <button mat-raised-button (click)="cancel()">Cancel</button>
        <button
            *ngIf="profile.id"
            mat-raised-button
            color="primary"
            (mousedown)="save(false)"
            [disabled]="isSubmitDisabled.value"
        >
            Save
        </button>
        <!--        <button *ngIf="!profile.id && profile.type === PROFILE_TYPE_DEFAULT" mat-raised-button color="primary" (click)="save()">Invite</button>-->
        <ng-container *ngIf="!profile.id && profile.type === PROFILE_TYPE_DEFAULT">
            <mat-menu #classMenu="matMenu">
                <button mat-menu-item (mousedown)="save(true)">Send Invite</button>
                <button mat-menu-item (mousedown)="save(false)">No Invite</button>
            </mat-menu>

            <button
                mat-raised-button
                color="primary"
                class="justify-content-end"
                [disabled]="isSubmitDisabled.value"
                [matMenuTriggerFor]="classMenu"
                #menuTrigger="matMenuTrigger"
                (mousedown)="menuTrigger.openMenu()"
                style="padding-right: 5px"
            >
                Create
                <mat-icon>arrow_drop_down</mat-icon>
            </button>
        </ng-container>
    </mat-card-actions>
</div>
