<div mat-dialog-title>
    <h3 class="w-100" style="border-bottom: solid 1px black">Connect to Ledger Account</h3>
</div>
<mat-dialog-content>
    <div class="">
        <div>Please, select what Ledger Account you want to use with Connected Bank Account:</div>
        <div class="pl-3 my-3">
            <mat-radio-group [(ngModel)]="isDefault">
                <div>
                    <mat-radio-button [value]="true">
                        Create New Account {{ isTrust ? '(Trust)' : '' }}
                    </mat-radio-button>
                </div>
                <div style="">
                    <mat-radio-button [value]="false">
                        <div class="d-flex justify-content-between align-items-center" style="height: 60px">
                            <div>Select Existing Account</div>
                            <mat-form-field
                                style="margin-left: 120px"
                                class="w-50 px-2"
                                *ngIf="!isDefault && accountId"
                                (click)="$event.stopPropagation()"
                            >
                                <mat-select [formControl]="accountId" [disabled]="isDefault">
                                    <ng-container *ngFor="let account of availableLedgerAccounts">
                                        <mat-option
                                            [disabled]="
                                                (account.type === 'credit_card' && accountType === 'bank') ||
                                                (account.type === 'bank' && accountType === 'credit card')
                                            "
                                            [value]="account.ledger_account_id"
                                            >{{ account.name }}</mat-option
                                        >
                                    </ng-container>
                                </mat-select>
                                <mat-hint *ngIf="availableLedgerAccounts.length === 0">No Accounts to Connect</mat-hint>
                                <mat-placeholder>Ledger Account</mat-placeholder>
                            </mat-form-field>
                        </div>
                    </mat-radio-button>
                </div>
            </mat-radio-group>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex justify-content-end w-100">
        <button
            mat-raised-button
            color="accent"
            [disabled]="!isDefault && !accountId?.value"
            (click)="apply()"
            tabindex="1"
        >
            Apply
        </button>
        <button mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">Cancel</button>
    </div>
</mat-dialog-actions>
