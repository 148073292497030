<div class="first-check-number-content d-flex flex-column">
    <button mat-icon-button class="close-button" mat-dialog-close="true" tabindex="-1" (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
    </button>
    <div class="title">What is the number of first check?</div>
    <div class="description">Please enter the number of the first check so we can populate checks accordingly.</div>
    <div class="first-check-number">
        <mat-form-field style="width: 60%">
            <input matInput placeholder="Check Number" [formControl]="firstCheckNumber" type="text" />
            <mat-error *ngIf="firstCheckNumber.errors?.pattern"> Last symbol must be a digit </mat-error>
            <mat-error *ngIf="firstCheckNumber.errors?.required">This field is required</mat-error>
        </mat-form-field>
    </div>
</div>
<div class="confirm-payments-footer">
    <div class="buttons-row">
        <button
            mat-raised-button
            class="mat-primary ml-2 button print-btn"
            (click)="next()"
            [disabled]="firstCheckNumber.invalid"
        >
            Next
        </button>
    </div>
</div>
