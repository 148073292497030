<div>
    <div class="closing-line">
        <span class="sp-spacer"></span>
        <mat-icon class="sp-cursor-pointer" (click)="close()">close</mat-icon>
    </div>

    <div class="popup-header">
        <!--        <h2 style="margin-bottom: 0">Edit 1099 Amount</h2>-->
    </div>

    <div class="popup-content">
        <div style="display: flex; justify-content: center">
            <img
                style="width: 80px; height: 80px"
                src="https://storage.googleapis.com/zipi-static/app-assets/SkySlopeLoading_3.gif"
            />
        </div>
        <div style="display: flex; justify-content: center">
            <div>Waiting for the checks to be prepared.</div>
        </div>
    </div>

    <div class="popup-actions"></div>
</div>
