<!--<div *ngIf="driverType === 'tpp' && cardForm">-->
<!--    <div style="padding: 20px" class="m-2" [formGroup]="cardForm">-->
<!--        <h1>Add Credit Card</h1>-->

<!--        <app-notifications [notification_mode]="'popup'"></app-notifications>-->
<!--        <div class="my-3">-->
<!--            <h3>Card Details</h3>-->
<!--            <div class="w-100 px-2 d-flex">-->
<!--                <mat-form-field class="w-50 mr-2">-->
<!--                    <input matInput required type="text" formControlName="firstName">-->
<!--                    <mat-placeholder>First Name</mat-placeholder>-->
<!--                </mat-form-field>-->

<!--                <mat-form-field class="w-50 mr-2">-->
<!--                    <input matInput required type="text" formControlName="lastName">-->
<!--                    <mat-placeholder>Last Name</mat-placeholder>-->
<!--                </mat-form-field>-->
<!--            </div>-->

<!--            <div class="w-100 px-2 d-flex">-->
<!--                <mat-form-field class="w-50 mr-2">-->
<!--                    <input matInput required type="text" formControlName="cardNumber">-->
<!--                    <mat-placeholder>Card Number</mat-placeholder>-->
<!--                </mat-form-field>-->

<!--                <mat-form-field class="w-50 mr-2">-->
<!--                    <input matInput required type="text" formControlName="cardCode">-->
<!--                    <mat-placeholder>Card Code</mat-placeholder>-->
<!--                </mat-form-field>-->
<!--            </div>-->

<!--            <div class="w-100 px-2 d-flex">-->
<!--                <mat-form-field class="w-50 mr-2">-->
<!--                    <input matInput required type="text" formControlName="expMonth">-->
<!--                    <mat-placeholder>Expiration Month(i.e 07)</mat-placeholder>-->
<!--                </mat-form-field>-->

<!--                <mat-form-field class="w-50 mr-2">-->
<!--                    <input matInput required type="text" formControlName="expYear">-->
<!--                    <mat-placeholder>Expiration Year(i.e 23)</mat-placeholder>-->
<!--                </mat-form-field>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="my-3">-->
<!--            <h3>Billing Address</h3>-->

<!--            <mat-form-field class="w-100 px-2">-->
<!--                <input matInput required type="text" formControlName="address">-->
<!--                <mat-placeholder>Address</mat-placeholder>-->
<!--            </mat-form-field>-->

<!--            <mat-form-field class="w-100 px-2">-->
<!--                <input matInput required type="text" formControlName="city">-->
<!--                <mat-placeholder>City</mat-placeholder>-->
<!--            </mat-form-field>-->

<!--            <div class="w-100 px-2 d-flex">-->
<!--                <mat-form-field class="w-50 mr-2">-->
<!--                    <input matInput required type="text" formControlName="state">-->
<!--                    <mat-placeholder>State</mat-placeholder>-->
<!--                </mat-form-field>-->

<!--                <mat-form-field class="w-50 mr-2">-->
<!--                    <input matInput required type="number" formControlName="zipCode">-->
<!--                    <mat-placeholder>Zip Code</mat-placeholder>-->
<!--                </mat-form-field>-->
<!--            </div>-->

<!--            <mat-form-field class="w-100 px-2">-->
<!--                <mat-select [placeholder]="'Country'" formControlName="country">-->
<!--                    <mat-option *ngFor="let country of countryList" [value]="country.slug">-->
<!--                        {{country.label}}-->
<!--                    </mat-option>-->

<!--                </mat-select>-->
<!--            </mat-form-field>-->
<!--        </div>-->

<!--        <div class="d-flex justify-content-end">-->
<!--            <button type="button" class="mr-2" mat-raised-button color="primary" (click)="saveCard()">-->
<!--                Continue-->
<!--            </button>-->

<!--            <button type="button" mat-raised-button (click)="close()">-->
<!--                Cancel-->
<!--            </button>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div *ngIf="!paymentMethodForVerification && this.currentGateway">
    <div
        *ngIf="
            currentStep === '2' && !['contact_bank', 'contact_card'].includes(paymentMethodType) && !disallowSetupLedger
        "
        [ngStyle]="currentStep === '2' ? {display: 'block'} : {display: 'none'}"
        class="p-4"
    >
        <h3 class="w-100" style="border-bottom: solid 1px black">Connect to Ledger Account</h3>
        <div class="">
            <div>
                Please, select what Ledger Account you want to use with
                {{ paymentMethodType === 'bank' ? 'Connected Bank Account' : 'Credit Card' }}:
            </div>
            <div class="pl-3 my-3">
                <mat-radio-group [(ngModel)]="isDefault">
                    <div>
                        <mat-radio-button [value]="true"
                            >Create New Account
                            {{
                                ['dwolla_trust', 'zipi_financial_trust'].includes(currentGateway!.type) ? '(Trust)' : ''
                            }}</mat-radio-button
                        >
                    </div>
                    <div style="">
                        <mat-radio-button [value]="false">
                            <div class="d-flex justify-content-between align-items-center" style="height: 60px">
                                <div>Select Existing Account</div>
                                <mat-form-field
                                    style="margin-left: 120px"
                                    class="w-50 px-2"
                                    *ngIf="!isDefault"
                                    (click)="$event.stopPropagation()"
                                >
                                    <mat-select [formControl]="accountId!" [disabled]="isDefault">
                                        <mat-option
                                            *ngFor="let account of availableLedgerAccounts"
                                            [value]="account.ledger_account_id"
                                        >
                                            {{ account.name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-hint *ngIf="availableLedgerAccounts.length === 0"
                                        >No Accounts to Connect</mat-hint
                                    >
                                    <mat-placeholder>Ledger Account</mat-placeholder>
                                </mat-form-field>
                            </div>
                        </mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>
        </div>
        <div class="d-flex justify-content-end w-100">
            <button mat-raised-button class="mr-1" [disabled]="applyDisabled" (click)="apply(true)" tabindex="-1">
                Skip
            </button>
            <!--            <button mat-raised-button (click)="back()" tabindex="-1">< Back</button>-->
            <button mat-raised-button color="primary" [disabled]="applyDisabled" (click)="apply(false)" tabindex="1">
                Apply
            </button>
        </div>
    </div>
</div>

<iframe
    *ngIf="url && iframeInit"
    width="100%"
    height="100%"
    style="position: fixed; inset: 0px; z-index: 2147483647; border-width: 0px; display: block; overflow: hidden auto"
    [src]="sanitizedUrl"
    frameborder="0"
    id="frame"
></iframe>
