<div mat-dialog-title>
    <h3>{{ title }}</h3>
</div>
<div class="d-flex align-items-center mt-3 mb-3" *ngIf="!authMerchantMethod">You cannot pay this Invoice.</div>

<mat-accordion [formGroup]="formGroup">
    <mat-expansion-panel
        [expanded]="true"
        *ngIf="moneySenderContact && authMerchantMethod"
        (opened)="createNewAuthCardMode = false; formGroup.controls.paid_by__payment_method_fk_id.setValue(null)"
    >
        <mat-expansion-panel-header>
            <mat-panel-title> Stored Cards </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="d-flex">
            <mat-form-field class="w-48 mr-2">
                <mat-select formControlName="paid_by__payment_method_fk_id" required>
                    <ng-container *ngIf="moneySenderContact && moneySenderContact.contact_payment_gateways">
                        <ng-container *ngFor="let gateway of moneySenderContact.contact_payment_gateways">
                            <div *ngFor="let method of gateway.payment_methods">
                                <mat-option
                                    *ngIf="
                                        method.payment_gateway &&
                                        method.payment_gateway.type === 'authorize_net_customer'
                                    "
                                    [value]="method.id"
                                >
                                    {{ method?.title }}
                                </mat-option>
                            </div>
                        </ng-container>
                    </ng-container>
                </mat-select>
                <mat-placeholder>Credit Cards</mat-placeholder>
            </mat-form-field>
            <mat-form-field class="w-48">
                <input
                    matInput
                    formControlName="amount"
                    placeholder="Amount"
                    [maskito]="currencyMaskitoMask"
                    (keyup)="validateAmount($event)"
                />
            </mat-form-field>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel
        *ngIf="moneySenderContact && authMerchantMethod"
        (opened)="createNewAuthCardMode = true"
        (closed)="createNewAuthCardMode = false"
    >
        <mat-expansion-panel-header>
            <mat-panel-title> Enter Card Info </mat-panel-title>
        </mat-expansion-panel-header>
        <div [formGroup]="newCardGroup">
            <div style="max-width: 500px; margin: auto; width: 500px" class="m-2">
                <div class="my-3">
                    <h3>Card Details</h3>
                    <div class="w-100 px-2 d-flex">
                        <mat-form-field class="w-50 mr-2">
                            <input matInput required type="text" formControlName="firstName" />
                            <mat-placeholder>First Name</mat-placeholder>
                        </mat-form-field>

                        <mat-form-field class="w-50 mr-2">
                            <input matInput required type="text" formControlName="lastName" />
                            <mat-placeholder>Last Name</mat-placeholder>
                        </mat-form-field>
                    </div>

                    <div class="w-100 px-2 d-flex">
                        <mat-form-field class="w-50 mr-2">
                            <input matInput required type="text" formControlName="cardNumber" />
                            <mat-placeholder>Card Number</mat-placeholder>
                        </mat-form-field>

                        <mat-form-field class="w-50 mr-2">
                            <input matInput required type="text" formControlName="cardCode" />
                            <mat-placeholder>Card Code</mat-placeholder>
                        </mat-form-field>
                    </div>

                    <div class="w-100 px-2 d-flex">
                        <mat-form-field class="w-50 mr-2">
                            <input matInput required type="text" formControlName="expMonth" />
                            <mat-placeholder>Expiration Month (ie 07)</mat-placeholder>
                        </mat-form-field>

                        <mat-form-field class="w-50 mr-2">
                            <input matInput required type="text" formControlName="expYear" />
                            <mat-placeholder>Expiration Year (ie 23)</mat-placeholder>
                        </mat-form-field>
                    </div>
                </div>

                <div class="my-3">
                    <h3>Billing Address</h3>

                    <mat-form-field class="w-100 px-2">
                        <input matInput required type="text" formControlName="address" />
                        <mat-placeholder>Address</mat-placeholder>
                    </mat-form-field>

                    <mat-form-field class="w-100 px-2">
                        <input matInput required type="text" formControlName="city" />
                        <mat-placeholder>City</mat-placeholder>
                    </mat-form-field>

                    <div class="w-100 px-2 d-flex">
                        <mat-form-field class="w-50 mr-2">
                            <input matInput required type="text" formControlName="state" />
                            <mat-placeholder>State</mat-placeholder>
                        </mat-form-field>

                        <mat-form-field class="w-50 mr-2">
                            <input matInput required type="number" formControlName="zipCode" />
                            <mat-placeholder>Zip Code</mat-placeholder>
                        </mat-form-field>
                    </div>

                    <mat-form-field class="w-100 px-2">
                        <mat-select [placeholder]="'Country'" formControlName="country">
                            <mat-option *ngFor="let country of countryList" [value]="country.slug">
                                {{ country.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div>
                <mat-checkbox formControlName="use_as_default">Save this Credit Card</mat-checkbox>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<div class="d-flex justify-content-end mb-2 mt-4">
    <button class="mr-2" mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">Cancel</button>
    <button
        class="mr-2"
        *ngIf="!createNewAuthCardMode && authMerchantMethod"
        mat-raised-button
        color="primary"
        [disabled]="isContinueButtonDisabled"
        (click)="chargeInvoice()"
        tabindex="1"
    >
        Continue
    </button>
    <button
        class="mr-2"
        *ngIf="createNewAuthCardMode && authMerchantMethod"
        mat-raised-button
        color="primary"
        [disabled]="!invoice || isContinueButtonDisabled"
        (click)="chargeNewCard()"
        tabindex="1"
    >
        Continue
    </button>
</div>
