<details class="root" [ngClass]="{expanded: isExpanded, preStatement: invoice?.pre_statement}" [attr.open]="isExpanded">
    <summary class="summary" (click)="onToggleExpand($event)">
        <div class="d-flex flex-column">
            <app-invoice-status-tag [status]="invoice.status_of_payment"></app-invoice-status-tag>
            <span class="summary-date">{{ invoice.invoice_date | dateFromNumber | date: 'MM/dd/yyyy' }}</span>
        </div>
        <div class="d-flex align-items-center" style="gap: 32px">
            <div class="d-flex flex-column">
                <span class="amount">{{ invoice.total_amount | currency: 'USD' }}</span>
                <span
                    >#{{ invoice.invoice_number }} Due
                    {{ invoice.due_date | dateFromNumber | date: 'MM/dd/yyyy' }}</span
                >
            </div>
            <button
                mat-icon-button
                aria-label="Expand/Collapse"
                (click)="onToggleExpand($event)"
                class="expand-collapse-btn"
            >
                <mat-icon *ngIf="!isLoadingInvoiceDetails">keyboard_arrow_down</mat-icon>
                <mat-spinner *ngIf="isLoadingInvoiceDetails" [diameter]="30"></mat-spinner>
            </button>
        </div>
    </summary>

    <div *ngIf="isExpanded && !!invoiceDetails" class="detailsSection">
        <table class="table">
            <thead>
                <tr>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of invoiceDetails.items">
                    <td class="d-flex flex-column gap-2">
                        <span>{{ item.name }}</span>
                        <span class="description">{{ item.description }}</span>
                    </td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.rate | currency: 'USD' }}</td>
                    <td>{{ item.amount | currency: 'USD' }}</td>
                </tr>
            </tbody>
        </table>

        <div class="totals">
            <!-- 
              Note: subtotal is supposed to be without taxes and total with taxes
              but Books does not yet support the calculation of taxes. The decision 
              is to leave both values here so that the UI to the user does not change
              once taxes are supported.
            -->
            <div>Sub Total</div>
            <div class="text-right">{{ invoice.total_amount | currency: 'USD' }}</div>
            <div class="font-weight-bold">Total</div>
            <div class="font-weight-bold text-right">{{ invoice.total_amount | currency: 'USD' }}</div>
            <div class="font-weight-bold">Balance</div>
            <div class="font-weight-bold text-right">{{ invoice.pending_balance | currency: 'USD' }}</div>
        </div>
    </div>
</details>
