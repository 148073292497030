import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {Subject} from 'rxjs';
import {IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {PAYMENT_MODES} from 'app/local-typings';
import {FormGroupWithFormControls} from '../../../../../typings/common';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';

@Component({
    selector: 'app-create-bill-confirm',
    templateUrl: 'manual-mark-as-paid-confirm-dialog.component.html',
    styles: [
        `
            .mat-dialog-content {
                overflow-wrap: break-word;
                white-space: pre-wrap;
            }
        `
    ]
})
export class ManualMarkAsPaidConfirmDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    currencyMaskitoMask = currencyMaskitoOptions;

    formGroup: FormGroupWithFormControls;

    invoiceMethods: IPaymentMethod[] = [];

    paymentModes = PAYMENT_MODES;

    needAmount: boolean;

    constructor(
        private fb: UntypedFormBuilder,
        private invoicesService: InvoicesService,
        public dialogRef: MatDialogRef<ManualMarkAsPaidConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.formGroup = this.fb.group({
            note: ['', []],
            paid_date: [null, [Validators.required]],
            payment_method_id: [null, []],
            ledger_account_id: [null, [Validators.required]],
            payment_mode: [null, [Validators.required]],
            payment_amount: [0, []],
            check_number: [null, []],
            memo: [null, []]
        }) as FormGroupWithFormControls;

        this.needAmount = false;
    }

    ngOnInit() {
        this.needAmount = this.data.needAmount;

        if (!this.data.currentMethod || this.data.currentMethod.payment_method_id) {
            if (this.data.invoice) {
                const methodIds = []; // TODO: dirty hack to implement new payment method fields. Should be refactored
                if (this.data.invoice.pay_to_bank__payment_method_fk_id) {
                    methodIds.push(this.data.invoice.pay_to_bank__payment_method_fk_id);
                }
                if (this.data.invoice.pay_to_card__payment_method_fk_id) {
                    methodIds.push(this.data.invoice.pay_to_card__payment_method_fk_id);
                }
                this.invoicesService
                    .getInvoiceMethods(methodIds)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((methods) => {
                        this.invoiceMethods = methods;
                    });
            }
        } else {
            this.invoiceMethods = [this.data.currentMethod];
            this.formGroup.controls.payment_method_id.setValue(this.data.currentMethod.payment_method_id);
            this.formGroup.controls.payment_method_id.disable();
            this.formGroup.controls.ledger_account_id.setValue(this.data.currentMethod.related__ledger_account_fk_id);
        }

        if (this.needAmount) {
            let paymentsAmount = 0;
            if (this.data.invoice && this.data.invoice.payments && this.data.invoice.payments.length > 0) {
                for (const pay of this.data.invoice.payments) {
                    if (!['expired', 'declined', 'canceled', 'error', 'reversed'].includes(pay.status)) {
                        paymentsAmount += pay.amount;
                    }
                }
            }
            this.formGroup.controls.payment_amount.setValue(
                Number((this.data.invoice.total_amount - paymentsAmount).toFixed(2))
            );
        }
    }

    validateAmount($event: any) {
        const inputValue = Number(cleanCurrencyString($event.target.value));

        const total = this.data.invoice.total_amount;
        let paymentsAmount = 0;

        if (this.data.invoice && this.data.invoice.payments && this.data.invoice.payments.length > 0) {
            for (const pay of this.data.invoice.payments) {
                if (!['expired', 'declined', 'canceled', 'error', 'reversed'].includes(pay.status)) {
                    paymentsAmount += pay.amount;
                }
            }
        }
        const availableAmount = Number((this.data.invoice.total_amount - paymentsAmount).toFixed(2));

        if (availableAmount < inputValue) {
            this.formGroup.controls.payment_amount.setValidators([Validators.email]);
            this.formGroup.controls.payment_amount.updateValueAndValidity();
            this.formGroup.controls.payment_amount.markAsTouched();
        } else {
            this.formGroup.controls.payment_amount.clearValidators();
            this.formGroup.controls.payment_amount.updateValueAndValidity();
        }
    }

    continue() {
        const paymentData = this.formGroup.getRawValue();
        paymentData.payment_amount = Number(cleanCurrencyString(paymentData.payment_amount));
        if (paymentData.payment_mode === 'check') {
            paymentData['check_info'] = {
                memo: paymentData.memo,
                check_number: paymentData.check_number,
                check_status: 'uncleared',
                print_status: 'not_printed'
            };
        } else if (paymentData.payment_mode === 'check_record') {
            paymentData['check_info'] = {
                memo: paymentData.memo,
                check_number: paymentData.check_number,
                check_status: 'uncleared',
                print_status: 'unknown'
            };
        } else {
            paymentData['check_info'] = null;
        }
        this.dialogRef.close(paymentData);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
