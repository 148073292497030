<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <mat-icon>apps</mat-icon>
</button>
<mat-menu #menu="matMenu" class="skyslope-menu">
    <h4>Switch to:</h4>
    <a [href]="skyslopeLink" target="_blank">
        <button mat-menu-item>
            <img [src]="'assets/images/skyslope-logo.svg'" class="menu-item-icon" />
            <span>SKYSLOPE</span>
        </button>
    </a>
</mat-menu>
