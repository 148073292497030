import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {JournalsTemplatesService} from 'app/services/api/finance/journal-templates.service';
import {IJournalTemplate} from '@cyberco-nodejs/zipi-typings';
import {IScrollData} from 'app/models/scroll-data';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';

@Component({
    selector: 'app-journal-templates',
    templateUrl: 'journal-templates.component.html',
    styleUrls: ['journal-templates.component.scss', '../../../../../../assets/infinite-scroll-table.scss']
})
export class JournalTemplatesComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator = new MatPaginator(
        new MatPaginatorIntl(),
        ChangeDetectorRef.prototype
    );

    @Input() filterHeader: any;
    @Input() updateTemplateList$: Subject<any> = new Subject<any>();

    scrollData: IScrollData = {
        sort_column: 'title',
        sort_direction: 'desc',
        total: 0
    };

    dataSource: MatTableDataSource<IJournalTemplate>;
    displayedColumns = ['title', 'line_item_total', 'notes'];

    constructor(private journalsTemplatesService: JournalsTemplatesService) {
        this.dataSource = new MatTableDataSource<IJournalTemplate>([]);
    }

    ngOnInit() {
        this.getJournalTemplates();

        this.updateTemplateList$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.getJournalTemplates();
        });
    }

    getJournalTemplates() {
        this.unsubscribeBatch.next();

        this.journalsTemplatesService
            .getJournalTemplates(this.scrollData)
            .pipe(takeUntil(this.unsubscribeBatch))
            .subscribe((data) => {
                this.dataSource.data = data.result.slice();
                this.scrollData.total = data._meta.total;
            });
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        this.getJournalTemplates();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
    }
}
