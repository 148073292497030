<div mat-dialog-title>
    <!--	<h3 class="w-100" style="border-bottom: solid 1px black">Connect to Ledger Account</h3>-->
    <h3 class="w-100">Connect to Ledger Account</h3>
</div>
<mat-dialog-content>
    <div class="">
        <div>Please select the Ledger Account you want to use with the connected bank account.</div>
        <div>You can update the connected ledger account at any time.</div>
        <div class="pl-3 my-3">
            <mat-radio-group [(ngModel)]="isDefault">
                <div style="">
                    <mat-radio-button [value]="false">
                        <div class="d-flex justify-content-between align-items-center" style="height: 60px">
                            <div>Select existing ledger account</div>
                            <mat-form-field
                                style="margin-left: 120px"
                                class="w-50 px-2"
                                *ngIf="!isDefault"
                                (click)="$event.stopPropagation()"
                            >
                                <mat-select [formControl]="accountId!" [disabled]="isDefault">
                                    <mat-option
                                        *ngFor="let account of availableLedgerAccounts"
                                        [value]="account.ledger_account_id"
                                    >
                                        {{ account.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-hint *ngIf="availableLedgerAccounts.length === 0">No Accounts to Connect</mat-hint>
                                <mat-placeholder>Ledger Account</mat-placeholder>
                            </mat-form-field>
                        </div>
                    </mat-radio-button>
                </div>
                <div>
                    <mat-radio-button [value]="true"
                        >Create new ledger account
                        {{ ['zipi_financial_trust'].includes(currentGateway!.type) ? '(Trust)' : '' }}</mat-radio-button
                    >
                </div>
            </mat-radio-group>
        </div>
    </div>
</mat-dialog-content>
<mat-divider style="margin-left: -24px; width: 110%"></mat-divider>
<mat-dialog-actions>
    <div class="d-flex justify-content-end w-100">
        <button *ngIf="!data.hideCancel" mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">
            Cancel
        </button>
        <button mat-raised-button color="primary" (click)="apply()" tabindex="1">Apply</button>
    </div>
</mat-dialog-actions>
