<div>
    <div *ngIf="retryRepresentativeForm">
        <app-notifications [notification_mode]="'popup'"></app-notifications>
    </div>
    <h2 *ngIf="errorFields && errorFields.length > 0">Retry Verification</h2>
    <h2 *ngIf="!errorFields || errorFields.length === 0">Edit Submitted Information</h2>
    <div class="m-2" style="font-size: 14px">
        <div>
            We do not store the following personally identifiable information: Date Of Birth, Last four digits of SSN.
            Please reenter this information prior to resubmitting.
        </div>
    </div>

    <div *ngIf="zipiFinancialRepresentative && retryRepresentativeForm">
        <div class="d-flex my-2">
            <mat-card class="mx-3 w-95" [formGroup]="retryRepresentativeForm">
                <div class="d-flex">
                    <mat-checkbox formControlName="is_controller">Is Controller</mat-checkbox>
                    <mat-checkbox formControlName="is_owner" (change)="isOwnerChange($event)" class="ml-2"
                        >Is Owner</mat-checkbox
                    >
                    <mat-icon
                        class="ml-2"
                        style="color: rgb(151, 150, 150); vertical-align: middle"
                        matTooltip="If this person is both a Controller and Owner (with more than 25% of the outstanding shares), please check both boxes."
                        >info_outlined</mat-icon
                    >
                </div>

                <div class="my-1 w-100">
                    <mat-form-field class="w-50 px-2">
                        <input matInput type="text" formControlName="job_title" required />
                        <mat-placeholder>Job Title</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('job_title')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Job Title'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                    <span class="w-50 px-2" *ngIf="!retryRepresentativeForm.controls.is_owner.value"></span>
                    <mat-form-field class="w-50 px-2" *ngIf="retryRepresentativeForm.controls.is_owner.value">
                        <input
                            matInput
                            type="text"
                            (keypress)="['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes($event.key)"
                            formControlName="ownership_percentage"
                        />
                        <mat-placeholder>The percentage of ownership</mat-placeholder>
                        <mat-icon
                            matSuffix
                            style="color: rgb(151, 150, 150); vertical-align: middle"
                            matTooltip="To be considered an owner, the person must have a 25% minimum of the company's outstanding shares."
                        >
                            info_outlined
                        </mat-icon>
                        <mat-hint style="color: red" *ngIf="errorFields.includes('description-or-website')"
                            >Missed/Incorrect info</mat-hint
                        >
                    </mat-form-field>
                </div>

                <div class="my-1 w-100">
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="first_name" />
                        <mat-placeholder>First Name</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('first_name')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter First Name'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="last_name" />
                        <mat-placeholder>Last Name</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('last_name')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Last Name'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                </div>

                <div class="d-flex my-1 w-100">
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="email" />
                        <mat-placeholder>Email</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('email')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Email'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                    <div class="d-flex w-50 px-2">
                        <app-date-picker
                            class="w-100"
                            [placeholder]="'Date Of Birth'"
                            [dateControl]="retryRepresentativeForm.controls.date_of_birth"
                            [startDate]="startDate"
                            [startView]="'year'"
                        ></app-date-picker>
                    </div>
                </div>

                <div class="my-1 w-100">
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="ssn" />
                        <mat-placeholder>Last four digits of SSN</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('ssn')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Last four digits of SSN'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                </div>

                <div class="my-1 w-100">
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="address_line_1" />
                        <mat-placeholder>Address Line 1 (No P.O. Box please)</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('address')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Address'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                    <mat-form-field class="w-50 px-2">
                        <input matInput type="text" formControlName="address_line_2" />
                        <mat-placeholder>Address Line 2</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('address')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Address'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                </div>

                <div class="my-1 w-100">
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="city" />
                        <mat-placeholder>City</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('address')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter City'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                    <mat-form-field class="w-50 px-2">
                        <mat-select required formControlName="state">
                            <mat-option *ngFor="let state of stateProvinceRegionList" [value]="state.slug"
                                >{{ state.title }}
                            </mat-option>
                        </mat-select>
                        <mat-placeholder>State</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('address')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter State'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                </div>

                <div class="my-1 w-100">
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="postalCode" />
                        <mat-placeholder>Zip (ex. 12345)</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('address')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Zip'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="phone_number" />
                        <mat-placeholder>Phone number</mat-placeholder>
                        <span matSuffix style="color: red" *ngIf="errorFields.includes('mobile')">
                            <mat-icon
                                [matTooltip]="'Please verify and re-enter Phone number'"
                                style="font-size: 20px; cursor: help"
                                >warning</mat-icon
                            >
                        </span>
                    </mat-form-field>
                </div>
            </mat-card>
        </div>
    </div>

    <div class="m-2 d-flex justify-content-end">
        <mat-checkbox [checked]="certify" (change)="certify = !certify">
            <span class="text-wrap d-flex" style="margin-top: 23px"
                >I, {{ sessionService?.user?.first_name }} {{ sessionService?.user?.last_name }}, hereby certify, to the
                best of my knowledge that the information provided is complete and correct.</span
            >
        </mat-checkbox>
    </div>

    <div class="d-flex justify-content-end">
        <button *ngIf="!data.hideCancel" mat-raised-button class="mr-2" (click)="dialogRef.close(false)" tabindex="-1">
            Cancel
        </button>
        <button mat-raised-button color="primary" [disabled]="!certify || lockButton" (click)="continue()" tabindex="1">
            Resubmit Details
        </button>
    </div>
</div>
