import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {GenericFormGroup} from '../../../../../../entites/generic.entity';
import {FinancialTransferEntity} from '../../../../../account-info/compensation/models/financial-transfer.entity';
import {IInvoice} from '@cyberco-nodejs/zipi-typings';
import {filter, takeUntil} from 'rxjs/operators';
import {DeductedPaymentConfirmationComponent} from '../../../../../shared/components/calculations/deducted-payment-confirmation/deducted-payment-confirmation.component';
import {InvoicesService} from '../../../../../../services/api/finance/invoices.service';
import {LedgerAccountService} from '../../../../../../services/api/finance/ledger-accounts.service';
import {Subject} from 'rxjs';
import {NotificationsServiceZipi} from '../../../../../notifications/notifications.service';
import * as moment from 'moment';
import {DealFinancialApiService} from '../../deal-financial.api.service';
import {DealProcessingService} from '../../deal-processing.service';
import {Router} from '@angular/router';
import {SalesEntity} from '../../../../../../models/sales-entity';
import {MomentInput} from 'moment-timezone';
import {InvoiceBillModalDialogComponent} from '../../../../../finance/components/invoice-bill-modal-dialog/invoice-bill-modal-dialog.component';
import {RbacService} from '../../../../../rbac/rbac.service';

@Component({
    selector: 'app-compensation-deductions',
    styles: [
        `
            .disable {
                color: gray;
                pointer-events: none;
                cursor: default;
            }

            .amount-align {
                text-align: right;
                padding-right: 5px;
            }

            .deduction-item {
                padding: 0 3px;
                display: flex;
                justify-content: space-between;
            }

            .deduction-item:hover {
                background-color: #f1f1f1;
            }
            .deduction-item div.label {
                display: table;
            }
            .deduction-item div.label span {
                display: table-cell; /* that is trick for vertical align to middle */
                vertical-align: middle;
            }
            .deduction-item mat-icon {
                cursor: pointer;
            }
        `
    ],
    template: `
        <div
            class="option-item"
            *ngIf="salesEntityFG?.controls?.commission_deductions?.controls.length || deductedInvoices?.length"
        >
            <div
                class="deduction-item"
                *ngFor="let deductedTransfer of salesEntityFG.controls.commission_deductions?.controls"
            >
                <div class="label">
                    <span>
                        {{ deductedTransfer.controls.amount.value | currency }} has been deducted from compensation for
                        "{{ deductedTransfer.controls.label.value }}" on
                        <a
                            href="javascript:"
                            (click)="
                                openViewInvoiceDialog(
                                    deductedTransfer.controls.connected__invoice_fk_id?.value,
                                    'invoice'
                                )
                            "
                            matTooltip="View Invoice"
                            >{{ deductedTransfer.controls.connected_invoice?.value?.invoice_number }}</a
                        >
                        ({{ deductedTransfer.controls.connected_invoice?.value?.summary_status | fromSnakeCase }})
                        <ng-container *ngIf="deductedTransfer.controls.connected__bill_fk_id?.value">
                            and clearing bill is
                            <a
                                href="javascript:"
                                (click)="
                                    openViewInvoiceDialog(
                                        deductedTransfer.controls.connected__bill_fk_id?.value,
                                        'bill'
                                    )
                                "
                                matTooltip="View Bill"
                                >{{ deductedTransfer.controls.connected_bill?.value?.bill_number }}</a
                            >
                            ({{ deductedTransfer.controls.connected_bill?.value?.summary_status | fromSnakeCase }})
                        </ng-container>
                    </span>
                </div>

                <mat-icon *ngIf="!disabled" mat-icon-button color="warn" (click)="cancelDeduction(deductedTransfer)"
                    >clear</mat-icon
                >
            </div>

            <div
                *ngIf="deductedInvoices && deductedInvoices.length > 0"
                class="w-100 mt-2 p-1"
                style="background-color: #efefef"
            >
                <div class="mb-2">
                    <div class="d-flex font-weight-bold">
                        <div class="mr-2 w-25">Invoice #</div>
                        <div class="mr-2 w-25">Due Date</div>
                        <div class="mr-2 w-25" style="text-align: end">Pending Balance</div>
                        <div class="mr-2 w-25" style="text-align: end">Deduct from Deal</div>
                    </div>
                    <mat-divider style="position: unset"></mat-divider>
                </div>
                <div *ngFor="let deductedInvoice of deductedInvoices">
                    <div *ngIf="deductedInvoice.pending_balance && deductedInvoice.pending_balance > 0">
                        <div class="d-flex">
                            <div class="mr-2 w-25">
                                <a
                                    href="javascript:void(0);"
                                    (click)="openViewInvoiceDialog(deductedInvoice.invoice_id, 'invoice')"
                                    >{{ deductedInvoice.invoice_number }}</a
                                >
                            </div>
                            <div class="mr-2 w-25">
                                {{ deductedInvoice.due_date | dateFromNumber | amDateFormat: 'MMM DD, YYYY' }}
                            </div>
                            <div class="mr-2 w-25 amount-align">{{ deductedInvoice.pending_balance | currency }}</div>
                            <div class="mr-2 w-25" style="text-align: end">
                                <ng-template
                                    [ngIf]="
                                        salesEntityFG.controls.id?.value &&
                                        deductedInvoice.pending_balance > 0 &&
                                        ((salesEntityFG.controls.role.value === 'agent' &&
                                            salesEntityFG.controls.calculation.value.net?.amount > 0) ||
                                            (salesEntityFG.controls.role.value === 'transfer' &&
                                                salesEntityFG.controls.transfer_calculation.value.net?.amount > 0))
                                    "
                                    [ngIfElse]="applyNotAllowed"
                                    ]
                                >
                                    <a
                                        href="#"
                                        [ngClass]="{disable: isApplyingProcess}"
                                        (click)="$event.preventDefault(); addExpenseFromInvoice(deductedInvoice)"
                                        >Deduct</a
                                    >
                                </ng-template>
                                <ng-template #applyNotAllowed>
                                    <span
                                        [matTooltip]="
                                            !salesEntityFG.controls.id?.value
                                                ? 'To apply invoices, the deal must be saved first.'
                                                : 'Insufficient income to deduct from deal.'
                                        "
                                        >Deduct</span
                                    >
                                </ng-template>
                            </div>
                        </div>
                        <mat-divider style="position: unset"></mat-divider>
                    </div>
                </div>
            </div>
            <!--            <div *ngIf="deductedLoans && deductedLoans.length > 0" class="w-100 mt-2 p-1"-->
            <!--                 style="background-color: #efefef">-->
            <!--                <div class="mb-2">-->
            <!--                    <div class="d-flex font-weight-bold">-->
            <!--                        <div class="mr-2 w-25">Loan Type</div>-->
            <!--                        <div class="mr-2 w-25">Loan Date</div>-->
            <!--                        <div class="mr-2 w-25">Total Amount</div>-->
            <!--                        <div class="mr-2 w-25" style="text-align: end">-->
            <!--                            Action-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <mat-divider style="position: unset"></mat-divider>-->
            <!--                </div>-->
            <!--                <div *ngFor="let deductedLoan of deductedLoans">-->
            <!--                    <div *ngIf="_availableLoanBalance(deductedLoan) > 0">-->
            <!--                        <div class="d-flex" [ngStyle]="isOverdue(deductedLoan) ? {backgroundColor: 'red'} : {}">-->
            <!--                            <div class="mr-2 w-25 text-capitalize">{{deductedLoan.type}}</div>-->
            <!--                            <div class="mr-2 w-25">{{deductedLoan.date | amDateFormat: 'MMM DD, YYYY'}}</div>-->
            <!--                            <div class="mr-2 w-25 amount-align">$ {{_availableLoanBalance(deductedLoan)}}</div>-->
            <!--                            <div class="mr-2 w-25" style="text-align: end" *ngIf="salesEntityFG">-->

            <!--                                <a href="#" (click)="$event.preventDefault(); addExpenseFromLoan(deductedLoan)"-->
            <!--                                    *ngIf="!(-->
            <!--                                        (-->
            <!--                                            salesEntityFG.controls.role && salesEntityFG.controls.role.value === 'agent'-->
            <!--                                            && forDeductedCalculationFG.value.net-->
            <!--                                            && forDeductedCalculationFG.value.net.amount-->
            <!--                                            && deductedLoan.balance-->
            <!--                                            && forDeductedCalculationFG.value.net.amount < deductedLoan.balance-->
            <!--                                        ) || (-->
            <!--                                            salesEntityFG.controls.role && salesEntityFG.controls.role.value === 'transfer'-->
            <!--                                            && salesEntityFG.controls.transfer_calculation-->
            <!--                                            && salesEntityFG.controls.transfer_calculation.value.net-->
            <!--                                            && salesEntityFG.controls.transfer_calculation.value.net.amount-->
            <!--                                            && deductedLoan.balance-->
            <!--                                            && salesEntityFG.controls.transfer_calculation.value.net.amount < deductedLoan.balance-->
            <!--                                        ) || (-->
            <!--                                            salesEntityFG.controls.id && !salesEntityFG.controls.id.value-->
            <!--                                        )-->
            <!--                                    )">Apply</a>-->
            <!--                                <span *ngIf="(-->
            <!--                                        salesEntityFG.controls.role && salesEntityFG.controls.role.value === 'agent'-->
            <!--                                        && forDeductedCalculationFG.value.net-->
            <!--                                        && forDeductedCalculationFG.value.net.amount-->
            <!--                                        && deductedLoan.balance-->
            <!--                                        && forDeductedCalculationFG.value.net.amount < deductedLoan.balance-->
            <!--                                    ) || (-->
            <!--                                        salesEntityFG.controls.role && salesEntityFG.controls.role.value === 'transfer'-->
            <!--                                        && salesEntityFG.controls.transfer_calculation-->
            <!--                                        && salesEntityFG.controls.transfer_calculation.value.net-->
            <!--                                        && salesEntityFG.controls.transfer_calculation.value.net.amount-->
            <!--                                        && deductedLoan.balance-->
            <!--                                        && salesEntityFG.controls.transfer_calculation.value.net.amount < deductedLoan.balance-->
            <!--                                    ) || (-->
            <!--                                        salesEntityFG.controls.id && !salesEntityFG.controls.id.value-->
            <!--                                    )"-->
            <!--                                    [matTooltip]="(-->
            <!--                                        salesEntityFG.controls.role && salesEntityFG.controls.role.value === 'agent'-->
            <!--                                        && forDeductedCalculationFG.value.net-->
            <!--                                        && forDeductedCalculationFG.value.net.amount-->
            <!--                                        && deductedLoan.balance-->
            <!--                                        && forDeductedCalculationFG.value.net.amount < deductedLoan.balance-->
            <!--                                    ) || (-->
            <!--                                        salesEntityFG.controls.role && salesEntityFG.controls.role.value === 'transfer'-->
            <!--                                        && salesEntityFG.controls.transfer_calculation-->
            <!--                                        && salesEntityFG.controls.transfer_calculation.value.net-->
            <!--                                        && salesEntityFG.controls.transfer_calculation.value.net.amount-->
            <!--                                        && deductedLoan.balance-->
            <!--                                        && salesEntityFG.controls.transfer_calculation.value.net.amount < deductedLoan.balance-->
            <!--                                    ) || (-->
            <!--                                        salesEntityFG.controls.id && !salesEntityFG.controls.id.value-->
            <!--                                    ) ? (salesEntityFG.controls.id && !salesEntityFG.controls.id.value) ? 'To apply invoices, the deal must be saved first.' : 'No funds to deduct' : ''">-->
            <!--                                    Apply-->
            <!--                                </span>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        <mat-divider style="position: unset"></mat-divider>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
    `
})
export class CompensationDeductionsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() disabled: boolean = false;

    // @Input() ledgerAccountDefaultPayment: ILedgerAccount|null = null;
    // @Input() forDeductedCalculationFG: GenericFormGroup<ParticipantCalculation> = new GenericFormGroup(new ParticipantCalculation(), 'change');
    // @Input() coeController: GenericFormGroup<Date> | FormControl | undefined;

    @Input() salesEntityFG: GenericFormGroup<SalesEntity> | undefined;

    @Input() reloadDataTriggerSubject: Subject<boolean> | null = null;

    @Output() compensationDeductionCreatedEvent = new EventEmitter();
    @Output() compensationDeductionDeletedEvent = new EventEmitter();
    // @Output() addExpense = new EventEmitter<GenericFormGroup<FinancialTransferEntity>>();
    // @Output() removeTransfer = new EventEmitter<GenericFormGroup<FinancialTransferEntity>>();
    // @Output() needRefreshDeducted = new EventEmitter<boolean>();
    // @Output() needSave = new EventEmitter<boolean>();
    // deductedLoans: ILoan[]|null = null;
    // newInvoice: IInvoice|undefined;

    deductedInvoices: IInvoice[] = [];
    isApplyingProcess: boolean = false;

    constructor(
        protected dialog: MatDialog,
        protected rbacService: RbacService,
        private invoicesService: InvoicesService,
        private ledgerAccountService: LedgerAccountService,
        private notificationServiceZipi: NotificationsServiceZipi,
        private dealFinancialApiService: DealFinancialApiService,
        private dealProcessingService: DealProcessingService,
        public router: Router
    ) {}

    async cancelDeduction(deductedTransferFG: GenericFormGroup<FinancialTransferEntity>): Promise<void> {
        this.dealProcessingService.deleteFinancialTransfer(deductedTransferFG);
        this.compensationDeductionDeletedEvent.emit();
    }

    async ngOnInit(): Promise<void> {
        // If trigger defined then we will use it. Otherwise will load data just once.
        if (this.reloadDataTriggerSubject) {
            this.reloadDataTriggerSubject?.pipe(takeUntil(this.unsubscribe)).subscribe((e) => {
                this.reloadInvoices();
            });
        } else {
            await this.reloadInvoices();
        }
    }

    async reloadInvoices() {
        let salesEntityContactId = this.salesEntityFG?.controls.contact_part_link?.controls.contact_id?.value;

        const canRequestDeductedInvoices = await this.rbacService.isAllowed({sales__view_invoices: true});

        if (!this.disabled && canRequestDeductedInvoices && salesEntityContactId) {
            this.invoicesService
                .getDeductedInvoicesByContactId(salesEntityContactId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((invoices) => {
                    this.deductedInvoices = invoices;
                });
        }
    }

    addExpenseFromInvoice(inv: IInvoice) {
        if (this.dealProcessingService.dealFG.controls.close_of_escrow!.invalid) {
            this.dealProcessingService.dealFG.controls.close_of_escrow!.markAsTouched();
            this.notificationServiceZipi.addError(`Closing Date not valid`);
        }
        let currentNet = 0;

        if (this.salesEntityFG!.controls.role?.value === 'agent') {
            currentNet = this.salesEntityFG!.controls.calculation?.value.net.amount;
        } else if (this.salesEntityFG!.controls.role?.value === 'transfer') {
            currentNet = this.salesEntityFG!.controls.transfer_calculation?.value?.net?.amount;
        }

        const dialogRef = this.dialog.open(DeductedPaymentConfirmationComponent, {
            width: '800px',
            data: {
                totalNet: currentNet,
                invoice: inv
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((allocation) => {
                const dealId = this.dealProcessingService.dealFG.controls.id!.value;
                const deal = this.dealProcessingService.dealFG.getRawValue();

                const reqObj = {
                    dealId: dealId,
                    allocation_by_product: allocation,
                    escrow_date: Number(moment(deal.close_of_escrow as MomentInput).format('YYYYMMDD'))
                };
                this.isApplyingProcess = true;
                this.dealProcessingService.saveButtonStateChange.next('disabled');
                this.dealFinancialApiService
                    .createDealDeductTransfer(inv.invoice_id!, reqObj)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((response) => {
                        this.dealProcessingService.saveButtonStateChange.next('enabled');
                        if (response && response.length > 0) {
                            for (const transfer of response) {
                                this.dealProcessingService.addFinancialTransfer(
                                    new GenericFormGroup(
                                        FinancialTransferEntity.FABRIC(FinancialTransferEntity).hydrate(transfer)
                                    )
                                );
                            }
                            this.dealProcessingService.doRefresh({});
                            this.compensationDeductionCreatedEvent.emit();
                            this.reloadInvoices();
                            this.isApplyingProcess = false;
                        }
                    });
            });
    }

    // /**
    //  * @deprecated Temporary disabled
    //  * @param loan
    //  */
    // addExpenseFromLoan(loan: ILoan) {
    //
    //     if (this.coeController && this.coeController!.invalid) {
    //         this.coeController!.markAsTouched();
    //         this.notificationServiceZipi.addError(`Closing Date not valid`);
    //     }
    //     const availableForPay = this._availableLoanBalance(loan);
    //
    //     const dialogRef = this.dialog.open(DeductedLoanPaymentConfirmationComponent, {
    //         minWidth: 320,
    //         data: {
    //             availableBalance: availableForPay,
    //         }
    //     });
    //
    //     dialogRef.afterClosed()
    //         .pipe(
    //             filter(pn => !!pn),
    //             takeUntil(this.unsubscribe)
    //         )
    //         .subscribe(amount => {
    //
    //             const dealId = this.dealProcessingService.dealFG.controls.id!.value;
    //             if (!dealId) {
    //                 this.notificationServiceZipi.addError(`To add Deductible Invoice save Deal firstly`);
    //                 return;
    //             }
    //
    //             if (!loan.product) {
    //                 this.notificationServiceZipi.addError(`Product missed in loan.`);
    //                 return;
    //             }
    //             // prepare invoice data
    //             const mainReceiverPerson = loan.receiver_contact!.contact_persons.find((person: IContactPerson) => person.type === 'main_person');
    //             const newInvoiceData: IInvoice = {
    //                 creator__company_fk_id: null,
    //                 creator__profile_fk_id: null,
    //                 division__company_group_fk_id: null,
    //                 invoice_number: null,
    //                 source__company_fk_id: null,
    //                 owner__company_fk_id: null,
    //                 connected__bill_fk_id: null,
    //                 status_of_connected_bill: null,
    //                 recurring_invoice_fk_id: null,
    //                 invoice_hash: null,
    //                 invoice_note: null,
    //                 status_of_payment: null,
    //                 status_of_notification: null,
    //                 status_of_invoice: null,
    //                 status_in_portal: null,
    //                 summary_status: null,
    //                 status_order: null,
    //                 items_preview: [],
    //                 payment_terms: 'due-on-receipt',
    //                 total_amount: amount,    // sum of items prices - check in backend
    //                 balance: null,
    //                 pending_balance: null,
    //                 due_date: null,
    //                 invoice_date: Number(moment().format('YYYYMMDD')), // current date
    //                 items: [ // product from loan
    //                     {
    //                         invoice_fk_id: null,
    //                         creator__company_fk_id:  null,
    //                         product_fk_id: loan.product.product_id!,
    //                         name: loan.product.name,
    //                         ledger_account_fk_id: loan.product.ledger_account_fk_id,
    //                         description: loan.product.description,
    //                         rate: loan.product.price,
    //                         quantity: 1,
    //                         amount: amount,
    //                         connected__deal_fk_id: null,
    //                         connected__financial_transfer_fk_id: null,
    //                         deal_connection_mode:  null,
    //                         order_index: 1,
    //                         division__company_group_fk_id: null,
    //                     }
    //                 ],
    //                 money_sender__company_fk_id: loan.receiver_contact!.partner__company_fk_id,  // loan receiver's contact.partner__company_fk_id
    //                 money_sender__contact_fk_id: loan.receiver_contact!.contact_id!,  // loan receiver
    //                 money_sender_email: mainReceiverPerson ? mainReceiverPerson.email : null,  // loan receiver
    //                 billing_address: null,
    //                 shipping_address: null,
    //                 to_be_paid__payment_method_fk_id: null,
    //                 pay_to__payment_method_fk_ids: this.ledgerAccountDefaultPayment!.connected_payment_methods!.map((payment_method: IPaymentMethod) => { return payment_method.payment_method_id!; }),
    //                 sales_person__profile_fk_id: null,
    //                 is_deduct_from_deal: true,
    //                 loan_fk_id: loan.loan_id,
    //                 source__financial_transfer_fk_id: null,
    //                 source__deal_fk_id: null,
    //                 auto_creation_info: null,
    //             };
    //
    //             const newInvoice = {...this.newInvoice, ...newInvoiceData};
    //             newInvoice.payment_terms = 'due-on-receipt';
    //
    //             // create invoice
    //             this.invoicesService.createInvoiceAsOpen({invoice: newInvoice, invoice_association: null})
    //                 .pipe(takeUntil(this.unsubscribe))
    //                 .subscribe(createdInvoice => {
    //                     if (createdInvoice) {
    //                         const reqObj = {
    //                             dealId: dealId,
    //                             amount: amount,
    //                         };
    //                         this.dealFinancialApiService.createDealDeductTransfer(createdInvoice.invoice_id!, reqObj)
    //                             .pipe(takeUntil(this.unsubscribe))
    //                             .subscribe(response => {
    //                                 this.dealProcessingService.addFinancialTransfer(new GenericFormGroup(FinancialTransferEntity.FABRIC(FinancialTransferEntity).hydrate(response)));
    //                                 this.needSave.emit(true);
    //                                 this.router.navigate(['/deals/' + dealId]);
    //                             });
    //                     } else {
    //                         this.notificationServiceZipi.addError('Invoice creating error');
    //                     }
    //                 });
    //         });
    // }

    // isOverdue(deductedInvoice: IInvoice | ILoan) {
    //     // @ts-ignore: seems that ILoan has due_date here
    //     if (deductedInvoice.due_date) {
    //         // @ts-ignore: seems that ILoan has due_date here
    //         return moment(deductedInvoice.due_date).toDate() > moment().endOf('day').toDate();
    //     }
    //
    //     return false;
    // }

    openViewInvoiceDialog(entityId: number | undefined, type: 'invoice' | 'bill') {
        const dialogRef = this.dialog.open(InvoiceBillModalDialogComponent, {
            position: {
                top: '10px',
                right: '10px'
            },
            height: '98%',
            width: '100vw',
            maxWidth: '98vw',
            panelClass: 'full-screen-modal',
            data: {
                invoiceId: type === 'invoice' ? entityId : null,
                billId: type === 'bill' ? entityId : null
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {});
    }

    // _availableLoanBalance(loan: ILoan) {
    //     const total = loan.amount;
    //     let invoicesAmount = 0;
    //
    //     if (loan.invoice_items && loan.invoice_items.length > 0) {
    //         for (const invoice of loan.invoice_items) {
    //             invoicesAmount += invoice.total_amount!;
    //         }
    //     }
    //     return total - invoicesAmount;
    // }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.compensationDeductionCreatedEvent.complete();
        this.compensationDeductionDeletedEvent.complete();

        this.reloadDataTriggerSubject?.complete();
    }
}
