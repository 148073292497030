import {assign} from 'lodash-es';
import {NavbarActionType, NavbarAction} from './navbar.actions';

export interface INavbarState {
    pageTitle: string;
}

export const INITIAL_NAVBAR_STATE: INavbarState = {
    pageTitle: ''
};

export function navbarReducer(state: INavbarState = INITIAL_NAVBAR_STATE, action: NavbarAction): INavbarState {
    switch (action.type) {
        case NavbarActionType.SET_PAGE_TITLE:
            return assign({}, state, {
                pageTitle: action.payload!
            });

        default:
            return state;
    }
}
