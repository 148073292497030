<div class="row content">
    <div class="col-xl-4 left-side">
        <mat-button-toggle-group vertical="true" [value]="activePanel" (change)="onSelectOptions($event)">
            <mat-button-toggle
                *ngFor="let item of reportOptions"
                [value]="item.value"
                (change)="onSelectOptions($event)"
            >
                {{ item.title }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="col-xl-8 right-side">
        <ng-container *ngIf="reportSettings">
            <div *ngIf="activePanel === 'time_period'">
                <app-report-edit-bar-time-period
                    [value]="{
                        time_period: reportSettings.time_period,
                        time_period_start: reportSettings.time_period_start,
                        time_period_end: reportSettings.time_period_end
                    }"
                    (valueChanges)="onValueChanges('time_period', $event)"
                >
                </app-report-edit-bar-time-period>
            </div>

            <div *ngIf="activePanel === 'entity'" class="content-item">
                <h4>Entities</h4>
                <app-report-edit-bar-entity
                    [value]="reportSettings.entities"
                    [entityTypeList]="['individual', 'individuals_in_group']"
                    (valueChanges)="onValueChanges('entities', $event)"
                >
                </app-report-edit-bar-entity>
            </div>

            <div *ngIf="activePanel === 'organization'" class="content-item">
                <h4 class="title">Organization</h4>
                <app-report-edit-bar-button-list
                    [multiple]="true"
                    [list]="membershipOrganizationsList"
                    [value]="reportSettings.organization"
                    (valueChanges)="onValueChanges('organization', $event)"
                    titleAttribute="title"
                    valueAttribute="id"
                >
                </app-report-edit-bar-button-list>
            </div>

            <div *ngIf="activePanel === 'contact_type'">
                <app-report-edit-bar-button-list
                    [multiple]="true"
                    [list]="listOfContactCategory"
                    [value]="reportSettings.contact_type"
                    (valueChanges)="onValueChanges('contact_type', $event)"
                >
                </app-report-edit-bar-button-list>
            </div>

            <div *ngIf="activePanel === 'status_in_directory'" class="content-item">
                <h4 class="title">Status In Directory</h4>
                <app-report-edit-bar-button-list
                    [multiple]="true"
                    [list]="listOfStatusInDirectoryOptions"
                    [value]="reportSettings.status_in_directory"
                    (valueChanges)="onValueChanges('status_in_directory', $event)"
                >
                </app-report-edit-bar-button-list>
            </div>
        </ng-container>

        <div *ngIf="report">
            <app-report-edit-bar-action [reportId]="report.id" [isShowNext]="isShowNext" (action)="onAction($event)">
            </app-report-edit-bar-action>
        </div>
    </div>
</div>
