import {Component, Inject, OnDestroy} from '@angular/core';
import {CustomDealField} from '../../../../../../models/custom-deal-field.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CompaniesService} from '../../../../../../services/companies.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-custom-field-creation-dialog',
    templateUrl: 'custom-field-creation-dialog.component.html'
})
export class CustomFieldCreationDialogComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    currentFieldType: CustomDealField = new CustomDealField();

    types_SET: string[] = CustomDealField.fieldTypes();
    dateValueTypes = ['value_as_date', 'value_as_int'];

    constructor(
        public dialogRef: MatDialogRef<CustomFieldCreationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {field: CustomDealField | null; companyId: number},
        private companiesService: CompaniesService
    ) {
        if (this.data.field) {
            this.currentFieldType = this.data.field;
        }
    }

    indexTracker(index: number) {
        return index;
    }

    addOption() {
        this.currentFieldType.options!.push('');
    }

    removeOptionFromArray(i: number) {
        this.currentFieldType.options!.splice(i, 1);
    }

    async saveCustomFieldType() {
        if (
            !this.currentFieldType.type ||
            !this.currentFieldType.label ||
            (this.currentFieldType.type === 'date' && !this.currentFieldType.options!.length)
        ) {
            return;
        }

        if (this.currentFieldType.id) {
            this.companiesService
                .updateCustomField(this.data.companyId, this.currentFieldType)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((newList) => {
                    this.dialogRef.close(newList.result);
                });
        } else {
            this.companiesService
                .createCustomField(this.data.companyId, this.currentFieldType)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((newList) => {
                    this.dialogRef.close(newList.result);
                });
        }
    }

    async deleteCustomFieldType() {
        if (this.currentFieldType.id) {
            this.companiesService
                .deleteCustomField(this.data.companyId, this.currentFieldType.id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((newList) => {
                    this.dialogRef.close(newList.result);
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
