import {Injectable} from '@angular/core';
import {Deal} from '../../../../models/deal';
import {GenericEntity, GenericFormArray, GenericFormGroup} from '../../../../entites/generic.entity';
import {FinancialTransferEntity} from '../../../account-info/compensation/models/financial-transfer.entity';
import {ContactPartLink} from '../../../../models/contact-part-link';
import {ProfilesSource} from '../../../../services/sources/profiles.source';
import {SalesEntity} from '../../../../models/sales-entity';
import {Profile} from '../../../../models/profile';
import {CurrentProfileSource} from '../../../../services/sources/current-profile.source';
import {GroupsSource} from '../../../../services/sources/groups.source';
import {Group} from '../../../../models/group';
import {select, Store} from '@ngrx/store';
import {IContactsState} from '../../../contacts/store/contacts.reducer';
import {FetchContacts} from '../../../contacts/store/contacts.actions';
import {selectContacts} from '../../../contacts/store/contacts.selectors';
import {IContact} from '@cyberco-nodejs/zipi-typings';

import {first, map, filter} from 'rxjs/operators';
import {UntypedFormControl, Validators} from '@angular/forms';
import {NotificationsServiceZipi} from '../../../notifications/notifications.service';
import {FinancialElementModel} from '../../../account-info/compensation/models/financial-element.model';
import {DealService} from '../../../../services/deal.service';
import {DisbursementText} from '../../../../models/disbursement-text';
import {BehaviorSubject} from 'rxjs';

import {isEmpty, isEqual, xorWith} from 'lodash-es';
import {RbacService} from '../../../rbac/rbac.service';
import {DealSelfTestingEntity} from '../../../../models/deal-self-testing';

@Injectable()
export class DealProcessingService {
    public loadedProfiles: Promise<any> | null = null;
    public loadedGroups: Promise<any> | null = null;
    public contacts: BehaviorSubject<IContact[]> = new BehaviorSubject<IContact[]>([]);
    public isDealReadyForEditing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public saveButtonStateChange: BehaviorSubject<'enabled' | 'disabled'> = new BehaviorSubject<'enabled' | 'disabled'>(
        'enabled'
    );

    public currentProfile: Profile | undefined;
    public profiles: Profile[] = [];
    public groups: Group[] = [];
    public dealFG: GenericFormGroup<Deal> = new GenericFormGroup(new Deal());
    public financialElementsBySalesEntity: {[index: number]: FinancialElementModel[]} = {};
    public financialElementsBySalesEntityFC: UntypedFormControl = new UntypedFormControl();

    public FINANCIAL_TRANSFER_ENTITY = FinancialTransferEntity;

    isAllowedContacts: boolean = false;

    public addParticipantSplit(contactPartLink: ContactPartLink) {
        const primarySEFG = this.dealFG.controls.sales_entities!.controls.find(
            (salesEntityFG) => salesEntityFG.controls.is_primary!.value
        );
        let financialTransferFG;
        if (primarySEFG) {
            financialTransferFG = this.createNewFinancialTransfer(contactPartLink, 0);
        } else {
            financialTransferFG = this.createNewFinancialTransfer(contactPartLink, 100);
        }
        this.addFinancialTransfer(financialTransferFG);

        // do apply-splits
        this.doRefresh({});
    }

    createNewFinancialTransfer(contactPartLink: ContactPartLink, percent: number) {
        return new GenericFormGroup(
            new FinancialTransferEntity()
                .setType(FinancialTransferEntity.type_SET.participant_split)
                .setOrigin(FinancialElementModel.type_set.participant)
                .setValueType(FinancialTransferEntity.value_type_SET.percent_of_deal)
                .setPercent(percent)
                .setReceiver(contactPartLink)
                .setSenderWildcardId(3)
                .setOriginIsExpense(true)
        );
    }

    removeAllTransfersForSalesEntity(salesEntity: SalesEntity) {
        this.dealFG.controls.financial_transfers!.controls = this.dealFG.controls.financial_transfers!.controls.filter(
            (transferFG) => {
                const transfer = transferFG.getRawValue(FinancialTransferEntity);
                if (transfer.receiver && transfer.receiver.contact_id === salesEntity.contact_part_link.contact_id) {
                    return false;
                }
                if (transfer.sender && transfer.sender.contact_id === salesEntity.contact_part_link.contact_id) {
                    return false;
                }
                return true;
            }
        );
    }

    isDealFormValid(): boolean {
        this.dealFG.controls
            .financial_transfers!.controls.filter(
                (trFG) => trFG.controls.type!.value === FinancialTransferEntity.type_SET.sales_individual
            )
            .forEach((trFG) => {
                const found = this.dealFG.controls.financial_transfers!.controls.find(
                    (transferFG) =>
                        transferFG.controls.type!.value === FinancialTransferEntity.type_SET.sales &&
                        transferFG.controls.unique_hash!.value === trFG.controls.unique_hash!.value
                );
                if (found) {
                    trFG.controls.product_fk_id!.patchValue(found.controls.product_fk_id!.value);
                }
            });
        this.dealFG.markAllAsTouched();

        if (this.dealFG.invalid) {
            this.notificationService.addError('Please fill all the required fields');
            return false;
        }
        return true;
    }

    constructor(
        protected profilesSource: ProfilesSource,
        protected groupsSource: GroupsSource,
        protected currentProfileSource: CurrentProfileSource,
        protected notificationService: NotificationsServiceZipi,
        private store: Store<IContactsState>,
        private dealService: DealService,
        protected rbacService: RbacService
    ) {
        this.loadedProfiles = new Promise((resolve) => {
            this.profilesSource.source.subscribe((profiles) => {
                this.profiles = profiles;
                resolve(null);
            });
        });
        this.loadedGroups = new Promise((resolve) => {
            this.groupsSource.source.subscribe((groups) => {
                this.groups = groups.filter(
                    (company_group) => company_group.permissions.use_in_compensation_as_entity_group
                );
                resolve(null);
            });
        });
        this.currentProfileSource.changeProfileEvent.subscribe((profile) =>
            profile.company ? (this.currentProfile = profile) : null
        );

        // check permissions
        this.rbacService
            .isAllowedAny([{contacts__view_limited_contacts: true}, {contacts__view_any_contacts: true}])
            .then((isAllowedContacts) => {
                this.isAllowedContacts = isAllowedContacts;
                if (this.isAllowedContacts) {
                    this.store.dispatch(new FetchContacts());
                    this.store
                        .pipe(
                            select(selectContacts), // migrate to ContactsLite: done
                            map((contacts) => {
                                this.contacts.next(contacts);
                            })
                        )
                        .subscribe();
                }
            });
    }

    setupDeal(deal: Deal) {
        this.financialElementsBySalesEntity = {};
        this.financialElementsBySalesEntityFC = new UntypedFormControl();
        this.dealFG = new GenericFormGroup(deal, 'change');
        this.dealFG.controls.close_of_escrow!.updateValueAndValidity({emitEvent: false});
        this.dealFG.controls.financial_transfers!.controls.forEach((control) => {
            control.controls.product_fk_id!.updateValueAndValidity({emitEvent: false});
        });
        this.setupEvents();
        this.isDealReadyForEditing.next(true);
    }

    syncSalesEntityTransfers(seFG: GenericFormGroup<SalesEntity>) {
        if (seFG.controls.role!.value === SalesEntity.role_SET.agent) {
            seFG.controls.participant_split = this.dealFG.controls.financial_transfers!.controls.find(
                (transferFG) =>
                    transferFG.controls.type!.value === FinancialTransferEntity.type_SET.participant_split &&
                    transferFG.controls.receiver!.value &&
                    transferFG.controls.receiver!.value.contact_id ===
                        seFG.controls.contact_part_link!.controls.contact_id!.value
            );

            if (seFG.controls.participant_split) {
                seFG.controls.participant_split.setValue(seFG.controls.participant_split.getRawValue(), {
                    emitEvent: false
                });
            }

            seFG.controls.payout = this.dealFG.controls.financial_transfers!.controls.find(
                (transferFG) =>
                    transferFG.controls.type!.value === FinancialTransferEntity.type_SET.agent_payout &&
                    transferFG.controls.receiver!.value &&
                    transferFG.controls.receiver!.value.contact_id ===
                        seFG.controls.contact_part_link!.controls.contact_id!.value
            );

            // @todo: what is this code for? looks like some trick to trigger side-effect
            if (seFG.controls.payout) {
                seFG.controls.payout.setValue(seFG.controls.payout.getRawValue(), {emitEvent: false});
            }

            if (typeof seFG.controls.payout === 'undefined') {
                seFG.controls.payout = new GenericFormGroup(new FinancialTransferEntity());
            }

            if (typeof seFG.controls.participant_split === 'undefined') {
                seFG.controls.participant_split = new GenericFormGroup(new FinancialTransferEntity());
            }

            seFG.controls.payout.controls.pay_at_escrow!.disable({emitEvent: false});
            seFG.controls.participant_split.controls.pay_at_escrow!.disable({emitEvent: false});
        } else {
            // we don't need such control for non-agent sales_entities
        }

        seFG.controls.user_royalties!.controls = this.dealFG.controls.financial_transfers!.controls.filter(
            (transferFG) =>
                transferFG.controls.type!.value === FinancialTransferEntity.type_SET.user_royalty &&
                !transferFG.controls.is_deleted_by_user!.value &&
                transferFG.controls.amount!.value > 0 &&
                transferFG.controls.sender &&
                transferFG.controls.sender.value &&
                transferFG.controls.sender.value.contact_id ===
                    seFG.controls.contact_part_link!.controls.contact_id!.value
        );

        const allAgentSplitTransfers = this.dealFG.controls.financial_transfers!.controls.filter(
            (transferFG) =>
                transferFG.controls.type!.value === FinancialTransferEntity.type_SET.agent_split &&
                !transferFG.controls.is_deleted_by_user!.value &&
                transferFG.controls.receiver!.value &&
                transferFG.controls.receiver!.value.contact_id ===
                    seFG.controls.contact_part_link!.controls.contact_id!.value
        );

        seFG.controls.agent_splits!.controls = allAgentSplitTransfers.filter(
            (transferFG) => transferFG.controls.unique_hash!.value !== 'NO-HASH'
        );

        seFG.controls.default_agent_splits!.controls = allAgentSplitTransfers.filter(
            (transferFG) => transferFG.controls.unique_hash!.value === 'NO-HASH'
        );

        seFG.controls.compensation_expenses!.controls = this.dealFG.controls.financial_transfers!.controls.filter(
            (transferFG) =>
                transferFG.controls.sender &&
                transferFG.controls.sender.value &&
                transferFG.controls.sender.value.contact_id ===
                    seFG.controls.contact_part_link!.controls.contact_id!.value &&
                seFG.controls.role!.value === SalesEntity.role_SET.agent &&
                transferFG.controls.type!.value === FinancialTransferEntity.type_SET.compensation_expense &&
                !transferFG.controls.is_deleted_by_user!.value
        );

        seFG.controls.compensation_incomes!.controls = this.dealFG.controls.financial_transfers!.controls.filter(
            (transferFG) =>
                transferFG.controls.sender &&
                transferFG.controls.sender.value &&
                transferFG.controls.sender.value.link_title &&
                transferFG.controls.receiver &&
                transferFG.controls.receiver.value &&
                transferFG.controls.receiver.value.contact_id ===
                    seFG.controls.contact_part_link!.controls.contact_id!.value &&
                seFG.controls.role!.value === SalesEntity.role_SET.agent &&
                transferFG.controls.type!.value === FinancialTransferEntity.type_SET.compensation_expense &&
                !transferFG.controls.is_deleted_by_user!.value
        );

        seFG.controls.company_splits!.controls = this.dealFG.controls.financial_transfers!.controls.filter(
            (transferFG) =>
                transferFG.controls.type!.value === FinancialTransferEntity.type_SET.company_split_individual &&
                !!transferFG.controls.amount!.value &&
                transferFG.controls.sender!.value &&
                transferFG.controls.sender!.value.contact_id ===
                    seFG.controls.contact_part_link!.controls.contact_id!.value
        );

        seFG.controls.referral_expenses!.controls = this.dealFG.controls
            .financial_transfers!.controls.filter(
                (transferFG) =>
                    transferFG.controls.sender &&
                    transferFG.controls.sender.value &&
                    transferFG.controls.sender.value.contact_id ===
                        seFG.controls.contact_part_link!.controls.contact_id!.value &&
                    seFG.controls.role!.value === SalesEntity.role_SET.agent &&
                    transferFG.controls.type!.value === FinancialTransferEntity.type_SET.referral &&
                    !transferFG.controls.is_deleted_by_user!.value
            )
            .map((item) => {
                if (item.controls.origin!.value === 'default') {
                    item.controls.origin!.patchValue(FinancialElementModel.type_set.user_referral, {emitEvent: false});
                }

                if (
                    item.controls.origin!.value === FinancialElementModel.type_set.user_referral ||
                    item.controls.origin!.value === 'default'
                ) {
                    item.controls.origin_is_expense!.patchValue(true, {emitEvent: false});
                }
                return item;
            });

        seFG.controls.commission_deductions!.controls = this.dealFG.controls.financial_transfers!.controls.filter(
            (transferFG) =>
                !transferFG.controls.is_deleted_by_user!.value &&
                transferFG.controls.sender?.value?.contact_id ===
                    seFG.controls.contact_part_link!.controls.contact_id!.value &&
                transferFG.controls.type!.value === FinancialTransferEntity.type_SET.deduct_transfer
        );

        seFG.controls.association_expenses!.controls = this.dealFG.controls.financial_transfers!.controls.filter(
            (transferFG) =>
                !transferFG.controls.is_deleted_by_user!.value &&
                transferFG.controls.sender?.value?.contact_id ===
                    seFG.controls.contact_part_link!.controls.contact_id!.value &&
                transferFG.controls.type!.value === FinancialTransferEntity.type_SET.associate_transfer
        );
        seFG.controls.additional_expenses!.controls = this.dealFG.controls
            .financial_transfers!.controls.filter(
                (transferFG) =>
                    transferFG.controls.sender &&
                    !transferFG.controls.is_deleted_by_user!.value &&
                    transferFG.controls.sender.value &&
                    transferFG.controls.sender.value.contact_id ===
                        seFG.controls.contact_part_link!.controls.contact_id!.value &&
                    (transferFG.controls.type!.value === FinancialTransferEntity.type_SET.transfer ||
                        transferFG.controls.type!.value === FinancialTransferEntity.type_SET.ancillary ||
                        // || transferFG.controls.type!.value === FinancialTransferEntity.type_SET.deduct_transfer
                        // || transferFG.controls.type!.value === FinancialTransferEntity.type_SET.associate_transfer
                        (transferFG.controls.type!.value === FinancialTransferEntity.type_SET.sales &&
                            seFG.controls.role!.value === SalesEntity.role_SET.transfer))
            )
            .map((item) => {
                if (item.controls.origin!.value === 'default') {
                    item.controls.origin!.patchValue(FinancialElementModel.type_set.disbursement_template, {
                        emitEvent: false
                    });
                }

                if (
                    item.controls.origin!.value === FinancialElementModel.type_set.disbursement_template ||
                    item.controls.origin!.value === 'default'
                ) {
                    item.controls.origin_is_expense!.patchValue(true, {emitEvent: false});
                }
                return item;
            });

        seFG.controls.association_incomes!.controls = this.dealFG.controls.financial_transfers!.controls.filter(
            (transferFG) =>
                !transferFG.controls.is_deleted_by_user!.value &&
                transferFG.controls.receiver?.value?.contact_id ===
                    seFG.controls.contact_part_link!.controls.contact_id!.value &&
                transferFG.controls.type!.value === FinancialTransferEntity.type_SET.associate_transfer
        );
        seFG.controls.additional_incomes!.controls = this.dealFG.controls
            .financial_transfers!.controls.filter(
                (transferFG) =>
                    transferFG.controls.receiver &&
                    !transferFG.controls.is_deleted_by_user!.value &&
                    transferFG.controls.receiver.value &&
                    transferFG.controls.receiver.value.contact_id ===
                        seFG.controls.contact_part_link!.controls.contact_id!.value &&
                    (transferFG.controls.type!.value === FinancialTransferEntity.type_SET.transfer ||
                        transferFG.controls.type!.value === FinancialTransferEntity.type_SET.user_royalty ||
                        // || transferFG.controls.type!.value === FinancialTransferEntity.type_SET.associate_transfer
                        // || transferFG.controls.type!.value === FinancialTransferEntity.type_SET.deduct_transfer
                        transferFG.controls.type!.value === FinancialTransferEntity.type_SET.royalty ||
                        transferFG.controls.type!.value === FinancialTransferEntity.type_SET.ancillary ||
                        transferFG.controls.type!.value === FinancialTransferEntity.type_SET.referral ||
                        (transferFG.controls.type!.value === FinancialTransferEntity.type_SET.sales &&
                            seFG.controls.role!.value === SalesEntity.role_SET.transfer))
            )
            .map((item) => {
                if (item.controls.origin!.value === 'default') {
                    item.controls.origin!.patchValue(FinancialElementModel.type_set.disbursement_template, {
                        emitEvent: false
                    });
                }

                return item;
            });
    }

    disableAddedAutomaticTransfers(array: GenericFormArray<FinancialTransferEntity>) {
        array.controls.forEach((item, id) => {
            if (item.controls.finance_connection_mode!.value === 'from_finance') {
                if (!item.disabled) {
                    item.disable({emitEvent: false});
                }
            }
        });
    }

    syncCompanyFinancialTransfers() {
        this.dealFG.controls.company_calculation!.controls.referral_expenses!.controls = this.dealFG.controls
            .financial_transfers!.controls.filter(
                (transferFG) =>
                    transferFG.controls.type!.value === FinancialTransferEntity.type_SET.referral &&
                    ((transferFG.controls.sender?.value &&
                        transferFG.controls.sender?.value.contact_id ===
                            this.currentProfile?.company?.company_group?.contact!.id) ||
                        transferFG.controls.sender_wildcard_id!.value === 3)
            )
            .map((item) => {
                if (item.controls.origin!.value === 'default') {
                    item.controls.origin!.patchValue(FinancialElementModel.type_set.referral, {emitEvent: false});
                }
                if (
                    item.controls.origin!.value === FinancialElementModel.type_set.referral ||
                    item.controls.origin!.value === 'default'
                ) {
                    item.controls.origin_is_expense!.patchValue(true, {emitEvent: false});
                }
                return item;
            });

        this.dealFG.controls.company_calculation!.controls.royalty_expenses!.controls = this.dealFG.controls
            .financial_transfers!.controls.filter(
                (transferFG) =>
                    transferFG.controls.type!.value === FinancialTransferEntity.type_SET.royalty &&
                    ((transferFG.controls.sender?.value &&
                        transferFG.controls.sender?.value.contact_id ===
                            this.currentProfile?.company?.company_group?.contact?.id) ||
                        transferFG.controls.sender_wildcard_id?.value === 3) &&
                    transferFG.controls.unique_hash &&
                    transferFG.controls.unique_hash.value !== 'NO-HASH'
            )
            .map((item) => {
                if (item.controls.origin!.value === 'default') {
                    item.controls.origin!.patchValue(FinancialElementModel.type_set.royalty, {emitEvent: false});
                }
                if (
                    item.controls.origin!.value === FinancialElementModel.type_set.royalty ||
                    item.controls.origin!.value === 'default'
                ) {
                    item.controls.origin_is_expense!.patchValue(true, {emitEvent: false});
                }
                return item;
            });

        this.dealFG.controls.company_calculation!.controls.ancillary_incomes!.controls = this.dealFG.controls
            .financial_transfers!.controls.filter(
                (transferFG) =>
                    transferFG.controls.type!.value === FinancialTransferEntity.type_SET.ancillary &&
                    transferFG.controls.sender_wildcard_id!.value !== 3 &&
                    ((transferFG.controls.receiver?.value &&
                        transferFG.controls.receiver?.value.contact_id ===
                            this.currentProfile?.company?.company_group?.contact?.id &&
                        ((transferFG.controls.sender?.value &&
                            // && transferFG.controls.receiver.value.contact_id !== this.currentProfile.company.company_group.contact.id
                            transferFG.controls.sender?.value.contact_id !==
                                this.currentProfile?.company?.company_group?.contact?.id) ||
                            !transferFG.controls.sender?.value)) ||
                        transferFG.controls.receiver_wildcard_id!.value === 3)
            )
            .map((item) => {
                if (item.controls.origin!.value === 'default') {
                    item.controls.origin!.patchValue(FinancialElementModel.type_set.company, {emitEvent: false});
                }
                return item;
            });

        this.dealFG.controls.company_calculation!.controls.ancillary_expenses!.controls = this.dealFG.controls
            .financial_transfers!.controls.filter(
                (transferFG) =>
                    transferFG.controls.type?.value === FinancialTransferEntity.type_SET.ancillary &&
                    ((transferFG.controls.sender?.value &&
                        transferFG.controls.sender?.value.contact_id ===
                            this.currentProfile?.company?.company_group?.contact!.id) ||
                        transferFG.controls.sender_wildcard_id!.value === 3)
            )
            .map((item) => {
                if (item.controls.origin!.value === 'default') {
                    item.controls.origin!.patchValue(FinancialElementModel.type_set.company, {emitEvent: false});
                }
                if (
                    item.controls.origin!.value === FinancialElementModel.type_set.company ||
                    item.controls.origin!.value === 'default'
                ) {
                    item.controls.origin_is_expense!.patchValue(true, {emitEvent: false});
                }
                return item;
            });

        this.dealFG.controls.company_calculation!.controls.sales_incomes!.controls =
            this.dealFG.controls.financial_transfers!.controls.filter(
                (transferFG) =>
                    transferFG.controls.type?.value === FinancialTransferEntity.type_SET.sales &&
                    !transferFG.controls.origin_is_expense!.value &&
                    ((transferFG.controls.receiver?.value &&
                        transferFG.controls.receiver?.value.contact_id ===
                            this.currentProfile?.company?.company_group?.contact!.id) ||
                        transferFG.controls.receiver_wildcard_id?.value === 3)
            );

        this.dealFG.controls.company_calculation!.controls.sales_expenses!.controls =
            this.dealFG.controls.financial_transfers!.controls.filter(
                (transferFG) =>
                    transferFG.controls.type?.value === FinancialTransferEntity.type_SET.sales &&
                    transferFG.controls.origin_is_expense?.value &&
                    ((transferFG.controls.sender?.value &&
                        transferFG.controls.sender?.value.contact_id ===
                            this.currentProfile?.company?.company_group?.contact?.id) ||
                        transferFG.controls.sender_wildcard_id?.value === 3)
            );

        this.dealFG.controls.sales_entities!.controls.forEach((seFG) => {
            this.syncSalesEntityTransfers(seFG);
        });

        this.dealFG.controls.company_calculation!.controls.commission_deductions!.controls =
            this.dealFG.controls.financial_transfers!.controls.filter((transferFG) => {
                return [FinancialTransferEntity.type_SET.deduct_transfer].includes(transferFG.controls.type!.value);
            });

        this.dealFG.controls.company_calculation!.controls.company_association_expenses!.controls =
            this.dealFG.controls.financial_transfers!.controls.filter((transferFG) => {
                return (
                    [FinancialTransferEntity.type_SET.associate_transfer].includes(transferFG.controls.type!.value) &&
                    transferFG.controls.sender?.value?.contact_id ===
                        this.currentProfile!.company!.company_group!.contact!.id
                );
            });

        this.dealFG.controls.company_calculation!.controls.company_association_incomes!.controls =
            this.dealFG.controls.financial_transfers!.controls.filter((transferFG) => {
                return (
                    [FinancialTransferEntity.type_SET.associate_transfer].includes(transferFG.controls.type!.value) &&
                    transferFG.controls.receiver?.value?.contact_id ===
                        this.currentProfile!.company!.company_group!.contact!.id
                );
            });

        this.dealFG.controls.company_calculation!.controls.company_incomes!.controls = this.dealFG.controls
            .financial_transfers!.controls.filter((transferFG) => {
                return (
                    (transferFG.controls.type!.value === FinancialTransferEntity.type_SET.referral &&
                        transferFG.controls.receiver &&
                        transferFG.controls.receiver.value &&
                        transferFG.controls.receiver.value.contact_id ===
                            this.currentProfile!.company!.company_group!.contact!.id) ||
                    (transferFG.controls.type!.value === FinancialTransferEntity.type_SET.transfer &&
                        transferFG.controls.receiver &&
                        transferFG.controls.receiver.value &&
                        transferFG.controls.receiver.value.contact_id ===
                            this.currentProfile!.company!.company_group!.contact!.id) ||
                    (transferFG.controls.type!.value === FinancialTransferEntity.type_SET.royalty &&
                        transferFG.controls.receiver &&
                        transferFG.controls.receiver.value &&
                        transferFG.controls.receiver.value.contact_id ===
                            this.currentProfile!.company!.company_group!.contact!.id) ||
                    (transferFG.controls.type!.value === FinancialTransferEntity.type_SET.ancillary &&
                        transferFG.controls.receiver &&
                        transferFG.controls.receiver.value &&
                        transferFG.controls.receiver.value.contact_id ===
                            this.currentProfile!.company!.company_group!.contact!.id &&
                        ((transferFG.controls.sender &&
                            transferFG.controls.sender.value &&
                            transferFG.controls.sender.value.contact_id ===
                                this.currentProfile!.company!.company_group!.contact!.id) ||
                            transferFG.controls.sender_wildcard_id!.value === 3)) ||
                    (transferFG.controls.type!.value === FinancialTransferEntity.type_SET.sales &&
                        transferFG.controls.receiver &&
                        transferFG.controls.receiver.value &&
                        transferFG.controls.receiver.value.contact_id ===
                            this.currentProfile!.company!.company_group!.contact!.id) ||
                    (transferFG.controls.type!.value === FinancialTransferEntity.type_SET.user_royalty &&
                        transferFG.controls.receiver &&
                        transferFG.controls.receiver.value &&
                        transferFG.controls.receiver.value.contact_id ===
                            this.currentProfile!.company!.company_group!.contact!.id)
                );
            })
            .map((item) => {
                if (item.controls.origin!.value === 'default') {
                    item.controls.origin!.patchValue(FinancialElementModel.type_set.company_income_expense, {
                        emitEvent: false
                    });
                }
                return item;
            });

        this.dealFG.controls.company_calculation!.controls.company_expenses!.controls = this.dealFG.controls
            .financial_transfers!.controls.filter(
                (transferFG) =>
                    (transferFG.controls.type!.value === FinancialTransferEntity.type_SET.transfer ||
                        transferFG.controls.type!.value === FinancialTransferEntity.type_SET.partner) &&
                    // || transferFG.controls.type!.value === FinancialTransferEntity.type_SET.deduct_transfer
                    // || transferFG.controls.type!.value === FinancialTransferEntity.type_SET.associate_transfer
                    transferFG.controls.sender &&
                    transferFG.controls.sender.value &&
                    transferFG.controls.sender.value.contact_id ===
                        this.currentProfile!.company!.company_group!.contact!.id
            )
            .map((item) => {
                if (item.controls.origin!.value === 'default') {
                    item.controls.origin!.patchValue(FinancialElementModel.type_set.company_income_expense, {
                        emitEvent: false
                    });
                }
                if (
                    item.controls.origin!.value === FinancialElementModel.type_set.company_income_expense ||
                    item.controls.origin!.value === 'default'
                ) {
                    item.controls.origin_is_expense!.patchValue(true, {emitEvent: false});
                }
                return item;
            });

        this.dealFG.controls.company_calculation!.controls.company_splits!.controls =
            this.dealFG.controls.financial_transfers!.controls.filter(
                (transferFG) =>
                    transferFG.controls.type!.value === FinancialTransferEntity.type_SET.company_split_individual &&
                    !!transferFG.controls.amount!.value &&
                    transferFG.controls.receiver!.value &&
                    transferFG.controls.receiver!.value.contact_id ===
                        this.currentProfile!.company!.company_group!.contact!.id
            );

        this.dealFG.controls.company_calculation!.controls.compensation_incomes!.controls =
            this.dealFG.controls.financial_transfers!.controls.filter(
                (transferFG) =>
                    transferFG.controls.type!.value === FinancialTransferEntity.type_SET.compensation_expense &&
                    !!transferFG.controls.amount!.value &&
                    transferFG.controls.receiver!.value &&
                    transferFG.controls.receiver!.value.contact_id ===
                        this.currentProfile!.company!.company_group!.contact!.id
            );
    }

    syncPayouts() {
        const dealRawValue = this.dealFG.getRawValue(Deal);
        this.dealFG.controls.escrow_payouts!.controls = this.dealFG.controls.financial_transfers!.controls.filter(
            (transferFG) => {
                const ft = transferFG.getRawValue(FinancialTransferEntity);

                return (
                    (ft.amount! > 0 ||
                        (ft.amount === 0 &&
                            !!ft.connected__invoice_fk_id &&
                            FinancialTransferEntity.type_SET.company_payout === ft.type) ||
                        (ft.amount === 0 &&
                            !!ft.connected__bill_fk_id &&
                            FinancialTransferEntity.type_SET.agent_payout === ft.type)) &&
                    !ft.is_deleted_by_user &&
                    (ft.isEscrowPayout || (ft.isDisbursementItem && !ft.payout_to_hash)) // needed only for backward compatibility
                );
            }
        );
        this.dealFG.controls.remaining_payouts!.controls = this.dealFG.controls.financial_transfers!.controls.filter(
            (transferFG) => {
                const ft = transferFG.getRawValue(FinancialTransferEntity);
                const companyContact = this.currentProfile!.company!.company_group!.contact;
                const senderAndReceiverEqualsCompany =
                    ft.sender &&
                    ft.receiver &&
                    ft.sender.contact_id === companyContact!.id &&
                    ft.receiver.contact_id === companyContact!.id;
                return (
                    ft.isRemaining &&
                    !ft.is_deleted_by_user &&
                    ft.amount! > 0 &&
                    !senderAndReceiverEqualsCompany &&
                    ft.isPayout
                );
            }
        );

        this.dealFG.controls.summary_payouts!.controls = new Array<GenericFormGroup<FinancialTransferEntity>>()
            .concat(this.dealFG.controls.escrow_payouts!.controls)
            .concat(this.dealFG.controls.remaining_payouts!.controls);

        if (dealRawValue.dual_deal_id && dealRawValue.dual_deal) {
            const dualDeal = this.dealFG.get('dual_deal')!.value;
            const dualDealTransfers = dualDeal.financial_transfers;
            dualDeal.escrow_payouts = dualDeal.financial_transfers.filter(
                (transferFG: GenericFormGroup<FinancialTransferEntity>) => {
                    const ft = GenericEntity.FABRIC(FinancialTransferEntity).hydrate(transferFG);

                    return (
                        ft.amount! > 0 && (ft.isEscrowPayout || (ft.isDisbursementItem && !ft.payout_to_hash)) // needed only for backward compatibility
                    );
                }
            );
            dualDeal.remaining_payouts = dualDeal.financial_transfers.filter(
                (transferFG: GenericFormGroup<FinancialTransferEntity>) => {
                    const ft = GenericEntity.FABRIC(FinancialTransferEntity).hydrate(transferFG);
                    const companyContact = this.currentProfile!.company!.company_group!.contact;
                    const senderAndReceiverEqualsCompany =
                        ft.sender &&
                        ft.receiver &&
                        ft.sender.contact_id === companyContact!.id &&
                        ft.receiver.contact_id === companyContact!.id;
                    return (
                        ft.isRemaining &&
                        ft.amount! > 0 &&
                        !ft.is_deleted_by_user &&
                        !senderAndReceiverEqualsCompany &&
                        ft.isPayout &&
                        dualDealTransfers
                            .filter((transfer: FinancialTransferEntity) => transfer.payout_to_hash === ft.unique_hash)
                            .every((transfer: FinancialTransferEntity) => transfer.type !== 'deduct_transfer')
                    );
                }
            );
            dualDeal.summary_payouts = [].concat(dualDeal.escrow_payouts).concat(dualDeal.remaining_payouts);

            this.dealFG.get('dual_deal')!.setValue(dualDeal);
        }
    }

    syncFinancialTransfers() {
        const addAsSalesEntity: {cpl: ContactPartLink; type: string}[] = [];
        this.dealFG.controls.financial_transfers!.controls.forEach((ftFG, idx) => {
            ftFG.controls._id!.patchValue(idx, {emitEvent: false});
            const sender = this.dealFG.controls.sales_entities!.controls.find(
                (seFG) =>
                    ftFG.controls.sender &&
                    ftFG.controls.sender.value &&
                    ftFG.controls.sender.value.contact_id ===
                        seFG.controls.contact_part_link!.controls.contact_id!.value
            );
            const receiver = this.dealFG.controls.sales_entities!.controls.find(
                (seFG) =>
                    ftFG.controls.receiver &&
                    ftFG.controls.receiver.value &&
                    ftFG.controls.receiver.value.contact_id ===
                        seFG.controls.contact_part_link!.controls.contact_id!.value
            );

            if (!sender && ftFG.controls.sender && ftFG.controls.sender.value) {
                const found = addAsSalesEntity.find(
                    (item) =>
                        item.cpl &&
                        item.cpl.contact_id === ftFG.controls.sender!.value &&
                        ftFG.controls.sender!.value.contact_id &&
                        item.type === ftFG.controls.type!.value
                );
                if (!found) {
                    if (
                        ftFG.controls.sender.value.contact_id !==
                        this.currentProfile!.company!.company_group!.contact!.id
                    ) {
                        addAsSalesEntity.push({cpl: ftFG.controls.sender.value, type: ftFG.controls.type!.value});
                    }
                }
            }

            if (!receiver && ftFG.controls.receiver && ftFG.controls.receiver.value) {
                const found = addAsSalesEntity.find(
                    (item) =>
                        item.cpl &&
                        item.cpl.contact_id === ftFG.controls.receiver!.value &&
                        ftFG.controls.receiver!.value.contact_id &&
                        item.type! === ftFG.controls.type!.value
                );
                if (!found) {
                    if (
                        ftFG.controls.receiver.value.contact_id !==
                        this.currentProfile!.company!.company_group!.contact!.id
                    ) {
                        addAsSalesEntity.push({cpl: ftFG.controls.receiver.value, type: ftFG.controls.type!.value});
                    }
                }
            }
        });

        this.syncCompanyFinancialTransfers();
        this.syncPayouts();

        addAsSalesEntity.forEach((cplFG) => this.addAsSalesEntity(cplFG.cpl, cplFG.type));

        this.disableAddedAutomaticTransfers(this.dealFG.controls.financial_transfers!);
    }

    setupEvents() {
        this.dealFG.controls.financial_transfers!.valueChanges.subscribe(() => {
            this.syncFinancialTransfers();
        });
        this.dealFG.valueChanges.subscribe(() => {
            this.dealFG.controls.financial_transfers!.controls.forEach((control) => {
                control.controls.product_fk_id!.updateValueAndValidity({emitEvent: false});
            });
        });
        this.dealFG.controls.disbursement_approved!.valueChanges.subscribe((approved) => {
            if (approved) {
                this.dealFG.disable({emitEvent: false});
            } else {
                this.dealFG.enable({emitEvent: false});
            }
        });
    }

    addFinancialTransfer(financialTransferFG: GenericFormGroup<FinancialTransferEntity>) {
        this.dealFG.controls.financial_transfers!.push(financialTransferFG);
        financialTransferFG.controls._id!.patchValue(this.dealFG.controls.financial_transfers!.controls.length - 1, {
            emitEvent: false
        });
        financialTransferFG.controls.creator__company_fk_id!.patchValue(this.currentProfile!.company_fk_id);

        // check permission
        if (this.isAllowedContacts) {
            this.contacts
                .pipe(
                    filter((contacts) => Array.isArray(contacts) && contacts.length !== 0),
                    first() // get first non-empty value and unsubscribe
                )
                .subscribe((contacts) => {
                    if (financialTransferFG.controls.sender!.value) {
                        const selectedContact = contacts.find(
                            (contact) => contact.contact_id === financialTransferFG.controls.sender!.value.contact_id
                        );

                        if (selectedContact && selectedContact.partner__company_fk_id) {
                            financialTransferFG.controls.sender_contact__company_fk_id!.patchValue(
                                selectedContact.partner__company_fk_id
                            );
                        }
                    } else if (financialTransferFG.controls.receiver!.value) {
                        const selectedContact = contacts.find(
                            (contact) => contact.contact_id === financialTransferFG.controls.receiver!.value.contact_id
                        );

                        if (selectedContact && selectedContact.partner__company_fk_id) {
                            financialTransferFG.controls.receiver_contact__company_fk_id!.patchValue(
                                selectedContact.partner__company_fk_id
                            );
                        }
                    }
                });
        }
    }

    deleteFinancialTransfer(financialTransferFG: GenericFormGroup<FinancialTransferEntity>) {
        this.dealFG.controls.financial_transfers!.removeAt(financialTransferFG.controls._id!.value);
    }

    deleteNotEnforcedTransfer(financialTransferFG: GenericFormGroup<FinancialTransferEntity>) {
        financialTransferFG.controls.is_modified_by_user!.patchValue(true, {emitEvent: false});
        financialTransferFG.controls.is_deleted_by_user!.patchValue(true);
        if (financialTransferFG.controls.type!.value !== FinancialTransferEntity.type_SET.company_split_individual) {
            financialTransferFG.controls.amount!.patchValue(0, {emitEvent: false});
            financialTransferFG.controls.percent!.patchValue(0, {emitEvent: false});
        }
    }

    addAsSalesEntity(contactPartLink: ContactPartLink, type: string) {
        // can become null
        if (!contactPartLink) {
            return;
        }
        if (contactPartLink.contact_id === this.currentProfile!.company!.company_group!.contact!.id) {
            return;
        }

        if (this.dealFG.controls.deal_participants!.controls.commission_payer!.value) {
            if (
                contactPartLink.contact_id ===
                this.dealFG.controls.deal_participants!.controls.commission_payer!.value.contact_id
            ) {
                return;
            }
        }

        // Probably unresolved wildcard
        if (contactPartLink.contact_id === null) {
            return;
        }

        const newSE = new SalesEntity().setRole(SalesEntity.role_SET.transfer);

        // check permission
        if (this.isAllowedContacts) {
            this.contacts
                .pipe(
                    filter((contacts) => Array.isArray(contacts)),
                    first() // get first non-empty value and unsubscribe
                )
                .subscribe((contacts) => {
                    let foundContact = contacts.find((contact) => contact.id === contactPartLink.contact_id);

                    if (!foundContact && this.currentProfile!.contact!.contact_id === contactPartLink.contact_id) {
                        foundContact = this.currentProfile!.contact;
                    }

                    if (foundContact) {
                        this.addSEToDeal(type, contactPartLink, newSE, foundContact);
                    } else {
                        throw new Error('Smth went wrong - Contact was not found');
                    }
                });
        }
    }

    addSEToDeal(type: string, contactPartLink: ContactPartLink, newSE: SalesEntity, contact: IContact) {
        if (contact.related__company_group_fk_id) {
            const foundGroup = this.groups.find(
                (company_group) => company_group.id === contact.related__company_group_fk_id
            );
            newSE.setGroup(foundGroup!).setContactPartLink(contactPartLink);
        } else if (contact.related__profile_fk_id) {
            const foundProfile = this.profiles.find(
                (company_group) => company_group.id === contact.related__profile_fk_id
            );
            if (foundProfile) {
                newSE.setProfile(foundProfile).setContactPartLink(contactPartLink);
            } else {
                throw new Error("Smth might went wrong (if it wasn't changing company) - Profile was not found");
            }
        } else {
            newSE.setContact(contact).setContactPartLink(contactPartLink);
        }

        // not add existing sales entity
        if (
            !this.dealFG.controls
                .sales_entities!.getRawValue()
                .find((se) => se.contact_part_link.contact_id === newSE.contact_part_link.contact_id)
        ) {
            if (type === FinancialTransferEntity.type_SET.participant_split) {
                newSE.setRole(SalesEntity.role_SET.agent);
                if (this.dealFG.controls.sales_entities!.controls.length === 0) {
                    newSE.setIsPrimary(true).setSalesVolume(100).setSideCount(1);
                }
            }

            const seFG = new GenericFormGroup(newSE, 'change');
            this.dealFG.controls.sales_entities!.push(seFG);
            this.syncSalesEntityTransfers(seFG);
        }
    }

    doRefresh(event: any, doNotRefresh = false) {
        this.isDealReadyForEditing.next(false);
        this.syncFinancialTransfers();
        const deal = this.dealFG.getRawValue();

        // we do not run recalculations if there empty close_of_escrow
        if (!deal.close_of_escrow) {
            this.isDealReadyForEditing.next(true);
            return;
        }

        // only for edit deal
        if (!this.dealFG.getRawValue().id) {
            this.isDealReadyForEditing.next(true);
            return;
        }

        this.dealFG.disable({emitEvent: false});

        // reset applied
        const withoutApplied = deal.sales_entities.map((se) => ({...se, applied: []}));

        this.dealFG.controls.disbursement_text_template!.reset(new DisbursementText(), {emitEvent: false});

        this.dealService
            .applySplitsToDeal({...deal, sales_entities: withoutApplied} as any)
            .then((modified) => {
                // remove duplicate conditions in applied element rules
                modified.sales_entities = (modified.sales_entities as any).map((se: SalesEntity) => ({
                    ...se,
                    applied: se.applied.map((finEl: {element: FinancialElementModel}) => ({
                        ...finEl,
                        element: {
                            ...finEl.element,
                            rules: finEl.element.rules.map((rule) => ({
                                ...rule,
                                and_conditions:
                                    // if type, value, operator are identical - remove first condition
                                    rule.and_conditions[0].type === rule.type &&
                                    rule.and_conditions[0].value === rule.value &&
                                    rule.and_conditions[0].operator === rule.operator
                                        ? rule.and_conditions.slice(1)
                                        : rule.and_conditions
                            }))
                        }
                    }))
                }));

                if (!modified.disbursement_approved) {
                    this.dealFG.enable({emitEvent: false});
                }
                this.updateCommissionCategorization(modified);

                if (modified.disbursement_text_template) {
                    this.dealFG.controls.disbursement_text_template!.patchValue(modified.disbursement_text_template);
                    this.dealFG.controls.disbursement_text_template!.disable({emitEvent: false});
                }

                if (!doNotRefresh) {
                    this.dealFG.controls.income_flat_commission_value!.patchValue(
                        modified.income_flat_commission_value,
                        {emitEvent: false}
                    );
                    this.dealFG.controls.company_calculation!.patchValue(modified.company_calculation, {
                        emitEvent: false
                    });
                    this.dealFG.controls.sales_entities!.patchValue([], {emitEvent: false});
                    this.dealFG.controls.sales_entities!.patchValue(modified.sales_entities, {emitEvent: false});

                    this.dealFG.controls.financial_transfers!.patchValue([], {emitEvent: false});
                    this.dealFG.controls.financial_transfers!.patchValue(modified.financial_transfers, {
                        emitEvent: true
                    });
                }
                this.syncFinancialTransfers();
                this.dealFG.controls.sales_entities!.controls.forEach((salesEntityFG) => {
                    if (salesEntityFG.controls.is_primary!.value) {
                        salesEntityFG.controls.participant_split!.controls.percent!.disable({emitEvent: false});
                        salesEntityFG.controls.participant_split!.controls.value_type!.disable({emitEvent: false});
                    }
                    salesEntityFG.controls.contact!.controls.disbursement_instructions!.disable({emitEvent: false});
                });
                this.dealFG.controls.self_testing?.patchValue(modified.self_testing);
                this.isDealReadyForEditing.next(true);
            })
            .catch((err) => {
                console.error('ERROR', err);

                this.isDealReadyForEditing.next(true);
                this.dealFG.enable({emitEvent: false});
            });
        // needed to let other components that deal data probably was updated
        this.dealFG.markAsDirty({onlySelf: true});
    }

    doReset(event: any, doNotRefresh = false) {
        this.isDealReadyForEditing.next(false);
        this.syncFinancialTransfers();
        const deal = this.dealFG.getRawValue();

        // we do not run recalculations if there empty close_of_escrow
        if (!deal.close_of_escrow) {
            this.isDealReadyForEditing.next(true);
            return;
        }

        // only for edit deal
        if (!this.dealFG.getRawValue().id) {
            this.isDealReadyForEditing.next(true);
            return;
        }

        this.dealFG.disable({emitEvent: false});

        // reset applied
        const withoutApplied = deal.sales_entities.map((se) => ({...se, applied: []}));

        this.dealFG.controls.disbursement_text_template!.reset(new DisbursementText(), {emitEvent: false});

        this.dealService
            .resetDealToDefaults({...deal, sales_entities: withoutApplied} as any)
            .then((modified) => {
                // remove duplicate conditions in applied element rules
                modified.sales_entities = (modified.sales_entities as any).map((se: SalesEntity) => ({
                    ...se,
                    applied: se.applied.map((finEl: {element: FinancialElementModel}) => ({
                        ...finEl,
                        element: {
                            ...finEl.element,
                            rules: finEl.element.rules.map((rule) => ({
                                ...rule,
                                and_conditions:
                                    // if type, value, operator are identical - remove first condition
                                    rule.and_conditions[0].type === rule.type &&
                                    rule.and_conditions[0].value === rule.value &&
                                    rule.and_conditions[0].operator === rule.operator
                                        ? rule.and_conditions.slice(1)
                                        : rule.and_conditions
                            }))
                        }
                    }))
                }));

                modified.sales_entities.forEach((salesEntity) => {
                    this.financialElementsBySalesEntity[salesEntity.id!] = salesEntity.applied;
                });

                this.financialElementsBySalesEntityFC.patchValue(this.financialElementsBySalesEntity);

                if (!modified.disbursement_approved) {
                    this.dealFG.enable({emitEvent: false});
                }

                if (modified.disbursement_text_template) {
                    this.dealFG.controls.disbursement_text_template!.patchValue(modified.disbursement_text_template);
                    this.dealFG.controls.disbursement_text_template!.disable({emitEvent: false});
                }

                if (!doNotRefresh) {
                    this.dealFG.controls.income_flat_commission_value!.patchValue(
                        modified.income_flat_commission_value,
                        {emitEvent: false}
                    );
                    this.dealFG.controls.company_calculation!.patchValue(modified.company_calculation, {
                        emitEvent: false
                    });
                    this.dealFG.controls.sales_entities!.patchValue([], {emitEvent: false});
                    this.dealFG.controls.sales_entities!.patchValue(modified.sales_entities, {emitEvent: false});

                    this.dealFG.controls.financial_transfers!.patchValue([], {emitEvent: false});
                    this.dealFG.controls.financial_transfers!.patchValue(modified.financial_transfers, {
                        emitEvent: true
                    });
                }
                this.syncFinancialTransfers();
                this.dealFG.controls.sales_entities!.controls.forEach((salesEntityFG) => {
                    if (salesEntityFG.controls.is_primary!.value) {
                        salesEntityFG.controls.participant_split!.controls.percent!.disable({emitEvent: false});
                        salesEntityFG.controls.participant_split!.controls.value_type!.disable({emitEvent: false});
                    }
                    salesEntityFG.controls.contact!.controls.disbursement_instructions!.disable({emitEvent: false});
                });
                this.isDealReadyForEditing.next(true);
            })
            .catch((err) => {
                console.error('ERROR', err);

                this.isDealReadyForEditing.next(true);
                this.dealFG.enable({emitEvent: false});
            });
    }

    // isDealObjectsEqual(dealA: Deal, dealB: Deal) {
    //     return isEmpty(xorWith([dealA], [dealB], isEqual));
    // }

    setValidatorsToTransfers(isDealApproving: boolean = false) {
        this.dealFG.controls.financial_transfers!.controls.forEach((trFG) => {
            if (
                trFG.controls.type?.value &&
                [
                    // entities (and deal, and company)
                    this.FINANCIAL_TRANSFER_ENTITY.type_SET.referral,
                    this.FINANCIAL_TRANSFER_ENTITY.type_SET.transfer,
                    this.FINANCIAL_TRANSFER_ENTITY.type_SET.user_royalty,
                    this.FINANCIAL_TRANSFER_ENTITY.type_SET.agent_split,
                    this.FINANCIAL_TRANSFER_ENTITY.type_SET.deduct_transfer,
                    // deal
                    this.FINANCIAL_TRANSFER_ENTITY.type_SET.royalty,
                    this.FINANCIAL_TRANSFER_ENTITY.type_SET.ancillary,
                    this.FINANCIAL_TRANSFER_ENTITY.type_SET.sales,
                    // company
                    this.FINANCIAL_TRANSFER_ENTITY.type_SET.company_split_individual
                ].includes(trFG.controls.type?.value)
            ) {
                // workaround to not throw errors for hidden transfers
                if (
                    trFG.controls.unique_hash?.value === 'NO-HASH' &&
                    [
                        this.FINANCIAL_TRANSFER_ENTITY.type_SET.agent_split,
                        this.FINANCIAL_TRANSFER_ENTITY.type_SET.company_split_individual,
                        this.FINANCIAL_TRANSFER_ENTITY.type_SET.user_royalty,
                        this.FINANCIAL_TRANSFER_ENTITY.type_SET.royalty
                    ].includes(trFG.controls.type.value) &&
                    trFG.controls.amount?.value === 0
                ) {
                    return;
                }

                trFG.controls.product_fk_id?.setValidators(Validators.required);
                trFG.controls.product_fk_id?.updateValueAndValidity({emitEvent: false});

                if (isDealApproving && trFG.controls.product_fk_id?.value === -1) {
                    trFG.controls.product_fk_id.patchValue(null);
                }

                trFG.controls.label?.setValidators(Validators.required);
                trFG.controls.label?.updateValueAndValidity({emitEvent: false});

                // receiver and sender is obligatory
                // receiver
                if (!trFG.controls.receiver?.value && !trFG.controls.receiver_wildcard_id?.value) {
                    trFG.controls.receiver_wildcard_id?.setValidators(Validators.required);
                    trFG.controls.receiver_wildcard_id?.updateValueAndValidity({emitEvent: false});
                } else {
                    trFG.controls.receiver_wildcard_id?.clearValidators();
                    trFG.controls.receiver_wildcard_id?.updateValueAndValidity({emitEvent: false});
                }
                // sender
                if (!trFG.controls.sender?.value && !trFG.controls.sender_wildcard_id?.value) {
                    trFG.controls.sender_wildcard_id?.setValidators(Validators.required);
                    trFG.controls.sender_wildcard_id?.updateValueAndValidity({emitEvent: false});
                } else {
                    trFG.controls.sender_wildcard_id?.clearValidators();
                    trFG.controls.sender_wildcard_id?.updateValueAndValidity({emitEvent: false});
                }
            }
            return trFG;
        });
    }

    runDealSelfTest(): Promise<DealSelfTestingEntity> {
        return this.dealService.runSelfTests(this.dealFG.getRawValue()).then((testsResults) => {
            this.dealFG.controls.self_testing?.patchValue(testsResults);
            return testsResults;
        });
    }

    updateCommissionCategorization(modified: Deal) {
        this.dealFG.controls.income_flat_commission_value!.patchValue(modified.income_flat_commission_value, {
            emitEvent: false
        });
        this.dealFG.controls.commission_categorization_template!.patchValue(
            modified.commission_categorization_template,
            {emitEvent: false}
        );
        if (modified.commission_categorization_template.money_list.length >= 0) {
            this.dealFG.controls.flat_fee_commissions!.patchValue(
                modified.commission_categorization_template.money_list.map((item) => ({
                    name: item.label,
                    value: item.amount
                })),
                {emitEvent: false}
            );
            this.dealFG.controls.flat_fee_commissions?.controls.forEach((item) => item.disable({emitEvent: false}));
            this.dealFG.controls.flat_fee_commissions!.patchValue(modified.flat_fee_commissions, {emitEvent: false});
        }
        this.dealFG.controls.flat_fee_commissions!.updateValueAndValidity();
    }
}
