import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Profile} from '../../../models/profile';
import {PickProfileFourmGroup} from './add-existing-company.forms';

@Component({
    selector: 'app-add-existing-company-step-3',
    styles: [
        `
            mat-card {
                /*margin: 70px auto;*/
                margin-right: 70px;
                margin-left: 70px;
                margin-top: 10px;
                margin-bottom: 10px;
                max-width: 350px;
            }

            mat-card-header {
                display: block;
                text-align: center;
                font-size: 22px;
                color: #676161;
            }

            img {
                width: 280px;
            }

            mat-card-header .title {
                padding: 15px 0;
                font-size: 14px;
            }

            mat-card-content .title {
                padding: 15px 0;
                text-align: center;
                font-size: 18px;
                border-top: 1px solid #ccc;
            }

            mat-form-field {
                width: 100%;
            }

            button {
                padding-left: 40px;
                padding-right: 40px;
                margin: 20px auto;
            }
            mat-radio-group {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }
        `
    ],
    template: `
        <mat-card class="card">
            <mat-card-header>
                <div class="title">Please Pick Profile</div>
            </mat-card-header>
            <mat-card-content *ngIf="thirdFormGroup">
                <form [formGroup]="thirdFormGroup">
                    <mat-radio-group [formControlName]="'pickedProfile'">
                        <ng-container *ngFor="let profile of availableProfiles">
                            <mat-radio-button
                                *ngIf="profile.company"
                                [value]="profile.id"
                                [disabled]="isConnected(profile)"
                                >{{ profile.company.title }}</mat-radio-button
                            >
                        </ng-container>
                    </mat-radio-group>
                    <div class="flex-row">
                        <button mat-button class="mat-primary" (click)="onConfirmSubmit()">Pick Profile</button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    `
})
export class AddExistingCompanyStep3Component implements OnDestroy {
    @Input() thirdFormGroup: PickProfileFourmGroup | undefined;
    @Input() availableProfiles: Profile[] = [];
    @Input() connected: number[] = [];
    @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

    onConfirmSubmit() {
        if (typeof this.thirdFormGroup === 'undefined') {
            return;
        }
        this.thirdFormGroup.controls['submit'].setValue(true);
        this.confirm.emit();
    }

    isConnected(profile: Profile) {
        if (this.connected.findIndex((el) => el === profile.id) !== -1) {
            return true;
        }
        return false;
    }

    ngOnDestroy() {
        this.confirm.complete();
    }
}
