import {Component, OnDestroy, Input} from '@angular/core';
import {Subject} from 'rxjs';
import {FinancialDetails} from './financial-node.model';
import {GenericFormArray} from '../../../../entites/generic.entity';
import {FinancialTransferEntity} from '../../../account-info/compensation/models/financial-transfer.entity';

@Component({
    selector: 'app-financial-details',
    styles: [
        `
            .bg-class {
                background-color: #f3efef;
            }

            .money {
                color: black;
            }

            .label {
                color: grey;
            }

            .detail-element {
                display: flex;
                align-items: baseline;
                flex-direction: row;
                justify-content: space-between;
            }

            .details-box {
                display: flex;
                flex-direction: column;
            }

            .w-50 {
                width: 50%;
            }

            .divider {
                margin-bottom: 5px;
            }

            .pr-26 {
                padding-right: 26.8px;
            }

            .w-55 {
                width: 55%;
            }

            .w-45 {
                width: 45%;
            }

            .text-right {
                text-align: right;
            }

            .negative {
                color: darkred;
            }
        `
    ],
    template: `
        <div class="details-box">
            <span *ngFor="let detailFG of detailsFA.controls" class="details-box">
                <div class="bg-class divider">
                    <div class="detail-element">
                        <span class="label w-55" *ngIf="detailFG.controls.label">
                            {{ detailFG.controls.label.value }}
                        </span>
                        <div class="w-45 text-right">
                            <span [class]="'money pr-26 ' + detailFG.controls.direction?.value">
                                {{
                                    detailFG.controls.amount && detailFG.controls.amount.value
                                        ? (detailFG.controls.amount.value | currency: 'USD' : 'symbol')
                                        : 'n/a'
                                }}
                            </span>
                        </div>
                    </div>
                    <div>
                        <span
                            *ngIf="detailFG.controls.value_type?.value === FINANCIAL_TRANSFER.value_type_SET.flat_fee"
                        >
                            Flat fee
                        </span>
                        <span
                            *ngIf="
                                detailFG.controls.value_type?.value ===
                                FINANCIAL_TRANSFER.value_type_SET.flat_fee_sales_net
                            "
                        >
                            Flat fee sales net
                        </span>
                        <!--<span *ngIf="detailFG.controls.value_type?.value === FINANCIAL_TRANSFER.value_type_SET.remaining">
                            Remaining
                        </span>-->
                        <span
                            *ngIf="
                                detailFG.controls.value_type?.value !== FINANCIAL_TRANSFER.value_type_SET.flat_fee &&
                                detailFG.controls.value_type?.value !==
                                    FINANCIAL_TRANSFER.value_type_SET.flat_fee_sales_net &&
                                detailFG.controls.value_type?.value !== FINANCIAL_TRANSFER.value_type_SET.remaining
                            "
                        >
                            {{ detailFG.controls.percent?.value
                            }}{{ FINANCIAL_DETAILS.value_type_LABELS[detailFG.controls.value_type?.value] }}
                        </span>
                    </div>
                </div>
            </span>
        </div>
    `
})
export class FinancialDetailsComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    FINANCIAL_DETAILS = FinancialDetails;
    FINANCIAL_TRANSFER = FinancialTransferEntity;

    @Input() detailsFA: GenericFormArray<FinancialDetails> = new GenericFormArray<FinancialDetails>([]);

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
