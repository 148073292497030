import {Component, Input} from '@angular/core';
import {FocusAreaFormGroup, GoalFormGroup} from '../../goals/common/model';
import {GenericFormGroup} from '../../../entites/generic.entity';
import {Group} from '../../../models/group';

@Component({
    selector: 'app-groups-goals',
    styles: [
        `
            form {
                flex: 1;
                padding: 20px;
            }

            mat-card {
                height: 100%;
            }
        `
    ],
    template: `
        <div style="width:100%" *ngIf="goalsFormGroup">
            <div
                style="display: flex; width:100%"
                *ngFor="let enumerable of goalsFormGroup.controls.enumerable?.controls; index as ei"
            >
                <app-goal-progress
                    *ngIf="enumerable.value.type === 'progress'"
                    style="width:100%"
                    [goal]="enumerable"
                    [index]="ei"
                ></app-goal-progress>
                <app-goal-general
                    *ngIf="enumerable.value.type === 'general'"
                    style="width:100%"
                    [goal]="enumerable"
                    [index]="ei"
                ></app-goal-general>
                <app-goal-mandatory
                    [goal]="enumerable"
                    (deleteGoal)="deleteGoal(goalsFormGroup, ei)"
                ></app-goal-mandatory>
            </div>
            <app-goal-create [focus_area]="goalsFormGroup"></app-goal-create>
        </div>
    `
})
export class GroupsGoalsComponent {
    @Input() goalsFormGroup: GenericFormGroup<Group> = new GenericFormGroup<Group>(new Group());

    public isLoaded = false;

    deleteGoal(focusArea: GenericFormGroup<Group>, goalIndex: number) {
        focusArea.controls.enumerable?.controls.splice(goalIndex, 1);
    }

    constructor() {}
}
