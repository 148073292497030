import {Component, ElementRef, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild} from '@angular/core';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import * as _ from 'lodash-es';

// Initial content for this component was copied from:
// src/app/components/compensation-profile-picker

export type ComboBoxOption = {
    label: string;
    value: string;
    groupId?: string | number;
    groupLabel?: string;
    disabled?: boolean;
};

type ComboBoxGroup<T extends ComboBoxOption = ComboBoxOption> = {
    id: string;
    label?: string | null;
    options: T[];
};

@Component({
    selector: 'app-multi-select-combo-box',
    templateUrl: './multi-select-combo-box.component.html',
    styleUrls: ['./multi-select-combo-box.component.scss']
})
export class MultiSelectComboBoxComponent<T extends ComboBoxOption> implements OnDestroy {
    @Input() label: string = '';
    /** How many items may be selected? 1 or multiple? */
    @Input() selectionMode: 'single' | 'multiple' = 'multiple';
    @Input() classes: {label?: string; input?: string} = {};

    private _options: T[] = [];
    /** All options that may be selected from */
    @Input()
    get options() {
        return this._options;
    }
    set options(options: T[]) {
        this._options = options;
        this.determineDisplayedOptions();
    }

    private _selectedOptions: T[] = [];
    /** Values of options that are currently selected */
    @Input()
    get selectedOptions() {
        return this._selectedOptions;
    }
    set selectedOptions(selectedOptions: T[]) {
        this._selectedOptions = selectedOptions;
        this.selectedOptionsChange.emit(selectedOptions);
        this.determineDisplayedOptions();
    }
    @Output() selectedOptionsChange = new EventEmitter<T[]>();

    @ViewChild('input', {static: false}) input: ElementRef<HTMLInputElement> | undefined;
    @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete | undefined;

    /** Options that are available to be selected, grouped by group */
    groupedDisplayedOptions: ComboBoxGroup<T>[] = [];
    determineDisplayedOptions(searchText?: string) {
        if (!this.options) return;
        let displayedOptions = this.options.filter((o1) => !this.selectedOptions.find((o2) => o1.value === o2.value));

        if (searchText) {
            displayedOptions = this.options.filter(
                (option) =>
                    option.label.toLowerCase().includes(searchText) ||
                    option.groupLabel?.toLowerCase().includes(searchText)
            );
        }

        this.groupedDisplayedOptions = groupOptions(displayedOptions);
    }

    search(event: Event) {
        const searchText = (<HTMLInputElement>event.target).value.toString().toLowerCase();
        this.determineDisplayedOptions(searchText);
    }

    handleSelect(event: MatAutocompleteSelectedEvent) {
        const selectedItem = this.options.find((o) => o.value === event.option.value);
        if (!selectedItem) return;
        this.selectedOptions = [...this.selectedOptions, selectedItem];
        this.clearInput();
    }

    handleRemove(removedItem: T) {
        this.selectedOptions = this.selectedOptions.filter((o) => o.value !== removedItem.value);
        this.clearInput();
    }

    clearInput() {
        if (!this.input) return;
        this.input.nativeElement.value = '';
        this.input.nativeElement.blur();
    }

    checkAndPreventInput(event: Event) {
        if (this.selectionMode === 'single' && this.selectedOptions?.length) {
            event.stopPropagation();
            return false;
        }
    }

    ngOnDestroy() {
        this.selectedOptionsChange.complete();
    }
}

function groupOptions<T extends ComboBoxOption>(options: T[]): ComboBoxGroup<T>[] {
    const indexedGroups = options.reduce(
        (acc, option) => {
            const groupId = option.groupId || option.groupLabel || 'ungrouped';
            if (acc[groupId]) {
                acc[groupId].options.push(option);
            } else {
                acc[groupId] = {
                    id: groupId.toString(),
                    label: option.groupLabel ?? null,
                    options: [option]
                };
            }
            return acc;
        },
        {} as Record<string, ComboBoxGroup<T>>
    );

    // Put the ungrouped items first
    return Object.values(indexedGroups).sort((g1, g2) => {
        if (!g1.label) return -1;
        if (!g2.label) return 1;
        return 0;
    });
}
