<div>
    <mat-card *ngIf="reportData && reportData.journals && reportData.journals.length">
        <table class="report-table w-100" *ngFor="let item of reportData.journals">
            <thead>
                <tr>
                    <th>
                        <div class="flex-row">
                            <span *ngIf="entityRouterListByJournalType[item.journal.type]">
                                <a
                                    [routerLink]="[
                                        entityRouterListByJournalType[item.journal.type],
                                        item.journal.related_entity_id
                                    ]"
                                >
                                    {{ item.journal.date }}
                                    {{ item.journal.type || item.journal.number ? '-' : '' }} ({{
                                        item.journal.type_title
                                    }}) {{ item.journal.number }}
                                </a>
                            </span>
                            <span *ngIf="!entityRouterListByJournalType[item.journal.type]">
                                {{ item.journal.date }} {{ item.journal.type || item.journal.number ? '-' : '' }} ({{
                                    item.journal.type_title ? item.journal.type_title : item.journal.reason_type
                                }}) {{ item.journal.number }}
                            </span>
                            <span
                                class="mx-xl-1"
                                *ngIf="
                                    ['payment_received_reverted', 'payment_made_reverted'].includes(
                                        item.journal.reason_type
                                    )
                                "
                            >
                                (Canceled)
                            </span>
                            <span
                                [matTooltip]="'Reason Type: ' + item.journal.reason_type_title"
                                class="material-icons-outlined icon-info"
                                >info</span
                            >
                        </div>
                    </th>
                    <th class="item-value">Debit</th>
                    <th class="item-value">Credit</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let transaction of item.transactions">
                    <td>{{ transaction.name }}</td>
                    <td class="item-value">{{ transaction.debit | reportFormatted: 'financial' }}</td>
                    <td class="item-value">{{ transaction.credit | reportFormatted: 'financial' }}</td>
                </tr>
                <tr class="total">
                    <td></td>
                    <td class="item-value">{{ item.journal.total_debit | reportFormatted: 'financial' }}</td>
                    <td class="item-value">{{ item.journal.total_credit | reportFormatted: 'financial' }}</td>
                </tr>
            </tbody>
        </table>

        <p class="m-2">*Amount is displayed in your base currency <span class="badge-secondary">USD</span></p>
    </mat-card>

    <mat-card class="preview-body" *ngIf="!reportData || !reportData.journals || !reportData.journals.length">
        <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
    </mat-card>
</div>
