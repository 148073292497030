<div style="min-width: 800px" class="table-background">
    <table mat-table [ngClass]="{'d-none': dataSource.data.length === 0}" [dataSource]="dataSource" class="w-100">
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef style="width: 150px">Date</th>
            <td mat-cell *matCellDef="let element" style="width: 150px">
                {{ element.date | dateFromNumber | date: 'mediumDate' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="transaction_type">
            <th mat-header-cell *matHeaderCellDef style="width: 150px">Transaction Type</th>
            <td mat-cell *matCellDef="let element" style="width: 150px" class="px-1">
                <ng-container
                    *ngIf="
                        element.item_id &&
                            [
                                'invoice',
                                'bill',
                                'payment_received',
                                'payment_made',
                                'credit_note',
                                'vendor_credit',
                                'expense'
                            ].includes(element.reason_type) &&
                            element.related_entity_type !== 'payment';
                        else noIdOrType
                    "
                >
                    <span
                        class="open-sidenav"
                        *ngIf="['invoice', 'bill'].includes(element.reason_type)"
                        (click)="openDialog(element.item_id, element.reason_type)"
                    >
                        {{ element.reason_type | fromSnakeCase | titlecase }}
                    </span>
                    <a
                        class="open-sidenav"
                        *ngIf="
                            ['payment_received', 'payment_made', 'credit_note', 'vendor_credit', 'expense'].includes(
                                element.reason_type
                            )
                        "
                        [routerLink]="[entityRouterListByJournalType[element.reason_type], element.item_id]"
                    >
                        <span>{{ element.reason_type | fromSnakeCase | titlecase }}</span>
                        <span class="material-icons ml-1" style="font-size: 1.3em; vertical-align: sub"
                            >open_in_new</span
                        >
                    </a>
                </ng-container>
                <ng-template #noIdOrType>
                    {{ element.reason_type | fromSnakeCase | titlecase }}
                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef style="width: 150px">Number</th>
            <td mat-cell *matCellDef="let element" style="width: 150px" class="px-1">
                <ng-container
                    *ngIf="
                        element.item_id &&
                            [
                                'invoice',
                                'bill',
                                'payment_received',
                                'payment_made',
                                'credit_note',
                                'vendor_credit',
                                'expense'
                            ].includes(element.reason_type) &&
                            element.related_entity_type !== 'payment';
                        else noIdOrType
                    "
                >
                    <span
                        class="open-sidenav"
                        *ngIf="['invoice', 'bill'].includes(element.reason_type)"
                        (click)="openDialog(element.item_id, element.reason_type)"
                    >
                        {{ element.number }}
                    </span>
                    <a
                        class="open-sidenav"
                        *ngIf="
                            ['payment_received', 'payment_made', 'credit_note', 'vendor_credit', 'expense'].includes(
                                element.reason_type
                            )
                        "
                        [routerLink]="[entityRouterListByJournalType[element.reason_type], element.item_id]"
                    >
                        <span>{{ element.number }}</span>
                        <span
                            class="material-icons ml-1"
                            style="font-size: 1.3em; vertical-align: sub"
                            *ngIf="element.number"
                            >open_in_new</span
                        >
                    </a>
                </ng-container>
                <ng-template #noIdOrType>
                    {{ element.number }}
                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef style="width: auto">Name</th>
            <td mat-cell *matCellDef="let element" style="width: auto" class="px-1">
                {{ element.display_name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef style="width: 150px" class="text-right">Amount</th>
            <td mat-cell *matCellDef="let element" style="width: 150px" class="text-right">
                <span
                    *ngIf="
                        debitOrCreditBalanceByLedgerType[element.type] &&
                            debitOrCreditBalanceByLedgerType[element.type] !== element.debit_or_credit;
                        else positiveValue
                    "
                >
                    ({{ element.amount | currency: 'USD' : 'symbol' }})
                </span>
                <ng-template #positiveValue>
                    <span *ngIf="element.amount">{{ element.amount | currency: 'USD' : 'symbol' }}</span>
                </ng-template>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row class="journals-row sp-cursor-pointer" *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <!------- Paginator ------->
    <div class="custom-paginator-background" style="width: 600px"></div>
    <mat-paginator
        class="custom-paginator"
        [length]="scrollData.total"
        [pageSize]="scrollData.limit"
        [pageSizeOptions]="pageSizeOptions"
    >
    </mat-paginator>
    <!------- No values ------->
    <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-center p-4 text-muted">
        There are no transactions.
    </div>
    <!------- Loading ------->
    <div class="load-container" id="load-container" *ngIf="!dataSource.data">
        <div class="loader">Loading...</div>
    </div>
</div>
