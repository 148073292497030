import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {MessagesService} from '../../messages.service';
import {takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Subject} from 'rxjs';
import {
    SpannerNotificationInterface,
    SpannerNotificationRefValueInterface,
    UserNotification
} from '../../../../models/user-notification';
import {SessionService} from '../../../../services/session.service';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {SystemNotificationSource} from '../../../user-notification/system-notification.source';
import {UserNotificationService} from '../../../user-notification/user-notification.service';
import {ProfilesService} from '../../../../services/profiles.service';
import {CurrentProfileSource} from '../../../../services/sources/current-profile.source';
import {AppInitSource} from '../../../../services/sources/app-init.source';
import {listOfActions} from '../../constants';
import {listOfReportTypeByPermissions} from '../../../reports/constants';
import {RbacService} from '../../../rbac/rbac.service';

@Component({
    selector: 'app-shipp-notifications',
    templateUrl: './shipp-notifications.component.html',
    styleUrls: ['./shipp-notifications.component.css']
})
export class ShippNotificationsComponent implements OnInit, AfterViewInit, OnDestroy {
    showCustomProgressBar = false;
    markAsFlaggedPending = false;
    markAsUnseenPending = false;
    markAsSeenPending = false;
    markAsWarningPending = false;
    markAsReadBtnDisabled = false;
    filterDisabled = false;
    offset = 0;
    NOTIFICATIONS_LIMIT = this.userNotificationService.NOTIFICATIONS_LIMIT;
    @Input() filterNotifications: string = 'all';
    // eslint-disable-next-line
    @Input('unread_notifications') unreadNotifications: UserNotification[] = [];
    @Output() dismiss = new EventEmitter();
    @ViewChild('asyncNotificationsSection') asyncNotificationsSection: ElementRef | null = null;

    public typeAssociation = UserNotification.provideTypes().association;
    public typeImportJson = UserNotification.provideTypes().importJson;
    public typeImportCsv = UserNotification.provideTypes().importCsv;

    filterNotificationsTypes = UserNotification.provideFilterTypes();
    filterNotificationsTypesLabels = UserNotification.provideFilterTypes().reduce(
        (accumulator: {[key: string]: any}, currentValue) => {
            accumulator[currentValue.value] = currentValue.label;
            return accumulator;
        },
        {}
    );

    private unsubscribe: Subject<void> = new Subject();
    private asyncNotificationsCounterUnsubscribe: Subject<void> = new Subject();
    notifications: Array<SpannerNotificationRefValueInterface> = [];
    counterNotifications: Array<any> = [];

    actions = ['update_references', 'import_shipments_from_csv'];

    canLoadMore: boolean = false;
    mutedCategories: string[] = [];
    mutedCategoriesObj: {[key: string]: any} = {};
    muteCategoriesFetching = false;

    // it defines height of lazy loading list
    offsetTopToLazyLoading: BehaviorSubject<number> = new BehaviorSubject<number>(106);

    constructor(
        protected currentProfileSource: CurrentProfileSource,
        protected profilesService: ProfilesService,
        protected messagesService: MessagesService,
        protected sessionService: SessionService,
        protected fireDb: AngularFireDatabase,
        protected systemNotificationSource: SystemNotificationSource,
        protected changeDetector: ChangeDetectorRef,
        protected userNotificationService: UserNotificationService,
        protected appInitSource: AppInitSource,
        protected rbacService: RbacService
    ) {}

    ngOnInit() {
        this.mutedCategories =
            this.sessionService.profile && this.sessionService.profile.settings
                ? this.sessionService.profile.settings.muted_notifications_categories
                : [];
        this.mutedCategoriesObj = this.mutedCategories.reduce((accumulator: {[key: string]: any}, currentValue) => {
            accumulator[currentValue] = true;
            return accumulator;
        }, {});
    }

    // transformNotificationList(list) {
    //     // console.info(list);
    //     const notificationList = [];
    //     list.forEach(item => {
    //         notificationList.push({ref: item.key, value: item.payload.val()});
    //     });
    //
    //     this.counterNotifications = notificationList.filter(notify => notify.value.timestamp);
    // }

    noScroll() {
        if (!this.asyncNotificationsSection) {
            return false;
        }
        const parentEl = this.asyncNotificationsSection.nativeElement.parentElement.parentElement;
        // return parentEl.offsetHeight < parentEl.scrollHeight;
        return parentEl.offsetHeight === parentEl.scrollHeight;
    }

    getAsyncNotificationsSpannerRequest(limit: number, offset: number = 0, filter: string = 'all') {
        this.showCustomProgressBar = true;
        return this.messagesService
            .getAsyncNotificationsSpanner(limit, offset, filter)
            .then((data) => {
                this.showCustomProgressBar = false;
                return data;
            })
            .catch((err) => {
                this.showCustomProgressBar = false;
            });
    }

    nextBatch() {
        if (this.canLoadMore) {
            this.loadMore();
        }
    }

    loadMore(): Promise<any> {
        if (!this.notifications.length) {
            return Promise.resolve();
        }

        this.canLoadMore = false;
        // this.offset = this.offset + this.NOTIFICATIONS_LIMIT;
        this.offset = this.notifications.length;

        // return this.messagesService.getAsyncNotificationsSpanner(this.LIMIT, this.offset, this.filterNotifications)
        return this.getAsyncNotificationsSpannerRequest(
            this.NOTIFICATIONS_LIMIT,
            this.offset,
            this.filterNotifications
        ).then((data) => {
            // const payload = this._newTransformNotificationObject(data);
            //
            // this.notifications = this.notifications.concat(payload);
            // this.canLoadMore = !!payload.length;
            //
            //
            // // if it is the SECOND batch - we need to add it to initial list.
            // // used for PREVENT doing request second time (NOTE: works only for 2nd batch)
            // // NOTE: the first batch loaded initially
            // // So, when we open notifications sidebar second time - it should not be loaded 2nd batch again
            // if (this.offset === this.NOTIFICATIONS_LIMIT && this.filterNotifications === 'all') {
            //     this.userNotificationService.addItemsToSpannerNotificationsList(data);
            // }

            this.userNotificationService.addItemsToSpannerNotificationsList(data);
        });
    }

    nextPage() {
        // prevents infinite fetching if component was destroyed
        if (this.unsubscribe.isStopped) {
            return;
        }

        this.loadMore().then(() => {
            setTimeout(() => {
                if (this.canLoadMore && this.noScroll()) {
                    this.nextPage();
                }
            });
        });
    }

    async ngAfterViewInit() {
        // this needs for switching between app pages (on INIT APP load these will be empty)
        // because every time we change app page this component will be re-initialized
        const notifications = await this.filterNotificationByPermissions(
            this.userNotificationService.spannerNotifications
        );
        this.notifications = this._newTransformNotificationObject(notifications);
        this.canLoadMore = this.userNotificationService.spannerNotifications.length >= this.NOTIFICATIONS_LIMIT;

        // this needs for INIT APP load, because this component will be rendered BEFORE get notifications request responded
        this.userNotificationService.spannerNotificationsChange.pipe(takeUntil(this.unsubscribe)).subscribe((msgs) => {
            this.filterNotificationByPermissions(msgs).then((items) => {
                this.notifications = this._newTransformNotificationObject(items);
                this.canLoadMore = msgs.length >= this.NOTIFICATIONS_LIMIT;
            });
        });

        // commented this because IT IS ALREADY SUBSCRIBED IN userNotificationsService
        // this.subscribeToAsyncNotificationsAddingAndChanges();

        // UPD: no need init request anymore - first batch will be loaded by appInit method
        // this.messagesService.getAsyncNotificationsSpanner(this.LIMIT, this.offset).then(data => {
        // this.getAsyncNotificationsSpannerRequest(this.LIMIT, this.offset).then(data => {
        //     this.notifications = this._newTransformNotificationObject(data);
        //
        // const heightOfParent = this.asyncNotificationsSection.nativeElement.parentElement.parentElement.offsetHeight;
        // const heightOfChild = this.asyncNotificationsSection.nativeElement.offsetHeight;
        // if (this.notifications.length === this.LIMIT && (heightOfChild <= heightOfParent)) {
        //     this.canLoadMore = true;
        //     this.nextPage();
        // } else {
        //     this.canLoadMore = false;
        // }
        //
        // this.subscribeToAsyncNotificationsAddingAndChanges();
        //
        // this.addScrollListener();

        //     this.canLoadMore = this.notifications.length === this.LIMIT;
        // });
    }

    async filterNotificationByPermissions(messages: SpannerNotificationInterface[]) {
        if (!messages.length) {
            return messages;
        }

        const result = [];
        for await (const message of messages) {
            switch (message.action) {
                case listOfActions.ACTION_EXPORT_REPORT_PDF:
                case listOfActions.ACTION_EXPORT_REPORT_CSV: {
                    const payload = message.payload;
                    const reportType = payload && payload.report_type;
                    const permission = reportType && listOfReportTypeByPermissions[reportType];

                    if (!permission || !(await this.rbacService.isAllowed({[permission]: true}))) {
                        continue;
                    }
                    break;
                }
                default:
            }
            result.push(message);
        }
        return result;
    }

    onDismiss(index: number) {
        this.dismiss.emit(index);
    }

    // handleChildChanged(actions) {
    //     actions
    //         .filter(action => action.type === 'child_added' || action.type === 'child_changed')
    //         .forEach(action => {
    //             switch (action.type) {
    //                 case 'child_added':
    //                     const newNotification = {ref: action.key, value: action.payload!.val()};
    //                     this.notifications = [newNotification, ...this.notifications];
    //                     break;
    //                 case 'child_changed':
    //                     const updNotification = {ref: action.key, value: action.payload!.val()};
    //                     this.notifications = this.notifications.map(n => n.ref === action.key ? updNotification : n);
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         });
    // }

    // subscribeToAsyncNotificationsAddingAndChanges() {
    //     this.systemNotificationSource.reloadNotificationsNotificationEvent.pipe(
    //         takeUntil(this.unsubscribe)
    //     ).subscribe(notification => {
    //
    //         // in some cases getting just 'null'. need investigate why.
    //         if (!notification) {
    //             return;
    //         }
    //         this.actionReaction(notification);
    //     });
    // }

    // async actionReaction(notification) {
    //     switch (notification.action_with_notification) {
    //         case 'created':
    //             // prevent duplication the same notification
    //             const alreadyHasThisNotification = this.notifications.find(n => n.value.notification_id === notification.notification_id);
    //             if (!!alreadyHasThisNotification) { return; }
    //
    //             const newNotification = await this.messagesService.getAsyncNotification(notification.notification_id);
    //             if (!newNotification) { return; }
    //
    //             // check if filter affords to add this new notification
    //             if (this.filterNotifications === 'all') {
    //                 this.notifications = [{ref: newNotification.notification_id, value: newNotification}].concat(this.notifications);
    //                 this.offset++;
    //             }
    //             // else if (this.filterNotifications === 'unread' && !newNotification.seen_by.some(id => id === this.sessionService.profile.id)) {
    //             //     this.notifications.unshift({ref: newNotification.notification_id, value: newNotification});
    //             // }
    //             // else if (this.filterNotifications === 'flagged' && !newNotification.seen_by.some(id => id === this.sessionService.profile.id)) {
    //             //     this.notifications.unshift({ref: newNotification.notification_id, value: newNotification});
    //             // }
    //             // else if (this.filterNotifications === newNotification.category) {
    //             //     this.notifications = [{ref: newNotification.notification_id, value: newNotification}].concat(this.notifications);
    //             //     this.offset++;
    //             // }
    //
    //             this.changeDetector.detectChanges();
    //             break;
    //         case 'updated':
    //             const notifIndex = this.notifications.findIndex(n => n.value.notification_id === notification.notification_id);
    //             if (notifIndex > -1) {
    //                 const updNotification = await this.messagesService.getAsyncNotification(notification.notification_id);
    //                 if (!updNotification) { return; }
    //
    //                 // this.notifications[notifIndex] = {ref: updNotification.notification_id, value: updNotification};
    //                 this.notifications = this.notifications.map(el => {
    //                     if (el.value.notification_id !== notification.notification_id) { return el; }
    //                     return {ref: updNotification.notification_id, value: updNotification};
    //                 });
    //                 this.changeDetector.detectChanges();
    //             }
    //             break;
    //         default:
    //             break;
    //     }
    // }

    // separateByActions(notifications) {
    //     const lastNotifications = [];
    //
    //     this.actions.forEach(action => {
    //         const actionNotifications = [];
    //
    //         notifications.forEach(notification => {
    //             if (notification.action === action) {
    //                 actionNotifications.push(notification);
    //             }
    //         });
    //
    //         if (actionNotifications.length > 0) {
    //             lastNotifications.push(actionNotifications.sort((a, b) => {
    //                 if (+new Date(a.timestamp) < +new Date(b.timestamp)) {
    //                     return 1;
    //                 }
    //                 if (+new Date(a.timestamp) > +new Date(b.timestamp)) {
    //                     return -1;
    //                 }
    //             })[0]);
    //         }
    //
    //     });
    //
    //     return lastNotifications.sort((a, b) => {
    //         if (+new Date(a.timestamp) < +new Date(b.timestamp)) {
    //             return 1;
    //         }
    //         if (+new Date(a.timestamp) > +new Date(b.timestamp)) {
    //             return -1;
    //         }
    //     });
    // }

    markAllAsSeen() {
        this.markAsReadBtnDisabled = true;
        this.showCustomProgressBar = true;

        const notifications = [...this.userNotificationService.spannerNotifications];

        // update UI before request
        for (let i = 0; i < notifications.length; i++) {
            const seenBySense = notifications[i].seen_by_sense;
            const seenBy = notifications[i].seen_by;

            if (!this.sessionService.profile || !this.sessionService.profile.id) {
                break;
            }
            const profileId = this.sessionService.profile.id;
            const hasSeen = seenBy.some((id) => id === profileId);
            if (seenBySense === UserNotification.provideSeenBySenseTypes().who_marked_as_viewed) {
                if (!hasSeen) {
                    if (!this.sessionService.profile.id) {
                        return;
                    }
                    notifications[i].seen_by.push(profileId);
                }
            } else {
                if (hasSeen) {
                    notifications[i]['seen_by'] = notifications[i]['seen_by'].filter(
                        (id) => Number(id) !== Number(profileId)
                    );
                }
            }
        }

        // update notifications list
        this.userNotificationService.updateSpannerNotificationsList(notifications);

        // update counter UI
        this.userNotificationService.asyncNotificationsUnreadCountChange.next(0);

        this.messagesService
            .markAllAsyncNotificationsRead()
            .then((success) => {
                this.showCustomProgressBar = false;
                this.markAsReadBtnDisabled = false;
            })
            .catch(() => {
                this.showCustomProgressBar = false;
                this.markAsReadBtnDisabled = false;
            });
    }

    markAsWarning(notificationToMark: {ref: number; value: SpannerNotificationInterface}) {
        // prevent multiple clicking
        if (this.markAsWarningPending) {
            return;
        }
        this.markAsWarningPending = true;

        // update button UI
        const notifToUpdateIndex = this.notifications.findIndex((el) => el.ref === notificationToMark.ref);
        if (notifToUpdateIndex > -1) {
            // this.notifications[notifToUpdateIndex].value.status = 'warning';

            const nextList = this.userNotificationService.spannerNotifications.map((n) => {
                if (n.notification_id === notificationToMark.ref) {
                    return {
                        ...n,
                        status: 'warning'
                    };
                }
                return n;
            });
            this.userNotificationService.updateSpannerNotificationsList(nextList);
        }

        this.messagesService
            .updateAsyncNotification(notificationToMark.ref, {fieldToUpdate: 'status', value: 'warning'})
            .then((success) => {
                this.markAsWarningPending = false;
            })
            .catch(() => {
                this.markAsWarningPending = false;
            });
    }

    isCategoryMuted(category: string) {
        return this.mutedCategories.some((c) => c === category);
    }

    muteNotificationCategory(ev: MouseEvent, notifCategory: string) {
        ev.stopPropagation();

        // if already fetching - ignore
        if (this.muteCategoriesFetching || !this.sessionService.profile || !this.sessionService.profile.settings) {
            return;
        }
        this.muteCategoriesFetching = true;

        const prevCategories = this.sessionService.profile.settings.muted_notifications_categories;
        const mute = prevCategories.find((category) => category === notifCategory);

        const nextMutedCategories = mute
            ? prevCategories.filter((category) => category !== notifCategory)
            : [...prevCategories, notifCategory];
        // console.info('nextMutedCategories', nextMutedCategories);

        this.mutedCategories = nextMutedCategories;
        this.mutedCategoriesObj = nextMutedCategories.reduce(
            (accumulator: {[key: string]: any}, currentValue) => {
                accumulator[currentValue] = true;
                return accumulator;
            },
            {} as {[key: string]: any}
        );

        const nextSettings = {
            ...this.sessionService.profile.settings,
            muted_notifications_categories: nextMutedCategories
        };

        if (!this.sessionService.profile || !this.sessionService.profile.id) {
            console.error('Profile ID is not defined.');
            return;
        }

        this.profilesService
            .updateProfileSettings(this.sessionService.profile.id, nextSettings)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                this.muteCategoriesFetching = false;
                if (response) {
                    // this.currentProfileSource.triggers.changeRequesterProfile.next(response.result);
                    this.currentProfileSource.triggers.updateProfileEvent.next({
                        settings: nextSettings
                    });

                    // UPDATE NOTIFICATION COUNTER
                    this.userNotificationService.getNotificationsCounterAndSpinner();
                }
            });
    }

    filterNotificationsChanged(value: string) {
        if (this.filterNotifications === value) {
            return;
        }

        this.filterNotifications = value;
        this.userNotificationService.updateNotificationsCategoryFilter(value);

        // this.canLoadMore = true;
        this.offset = 0;
        // this.filterDisabled = true;

        // this.messagesService.getAsyncNotificationsSpanner(this.LIMIT, this.offset, this.filterNotifications).then(data => {
        this.getAsyncNotificationsSpannerRequest(this.NOTIFICATIONS_LIMIT, this.offset, this.filterNotifications).then(
            (data) => {
                // this.notifications = this._newTransformNotificationObject(data);
                // this.canLoadMore = this.notifications.length === this.NOTIFICATIONS_LIMIT;

                this.userNotificationService.updateSpannerNotificationsList(data);

                // const heightOfParent = this.asyncNotificationsSection.nativeElement.parentElement.parentElement.offsetHeight;
                // const heightOfChild = this.asyncNotificationsSection.nativeElement.offsetHeight;
                // if (this.notifications.length === this.LIMIT && (heightOfChild <= heightOfParent)) {
                //     this.canLoadMore = true;
                //     this.nextPage();
                // }

                // this.subscribeToAsyncNotificationsAddingAndChanges();

                // this.addScrollListener();
            }
        );
    }

    // addScrollListener() {
    //     this.asyncNotificationsSection.nativeElement.parentElement.parentElement.removeAllListeners();
    //     this.asyncNotificationsSection.nativeElement.parentElement.parentElement.addEventListener('scroll', lodash.debounce((event) => {
    //         // note: do "-10" correction to care about "almost on bottom" case
    //         if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - 10)) {
    //             if (!this.canLoadMore) { return; }
    //             this.loadMore();
    //         }
    //     }, 500));
    // }

    isSeen = (notification: SpannerNotificationInterface) => {
        const hasInSeenBy = notification.seen_by.some(
            (id) => this.sessionService.profile && id === this.sessionService.profile.id
        );
        // NOTE: notification.seen_by contains profileIds who marks READ or UNREAD notification depends on notification.seen_by_sense field
        // if notification.seen_by_sense === 'who_marked_as_not_viewed' - seen_by field will have those who mark notification as UNREAD
        // if notification.seen_by_sense === 'who_marked_as_viewed' - seen_by field will have those who mark notification as READ
        if (notification.seen_by_sense === UserNotification.provideSeenBySenseTypes().who_marked_as_not_viewed) {
            return !hasInSeenBy;
        }

        return hasInSeenBy;
    };

    _newTransformNotificationObject(list: SpannerNotificationInterface[]): Array<SpannerNotificationRefValueInterface> {
        return list.map((el) => ({ref: el.notification_id, value: {...el, IS_SEEN: this.isSeen(el)}}));
    }

    /*
     * @deprecated
     * */
    // _transformNotificationObject(list) {
    //     const notificationList = [];
    //     Object.keys(list).forEach(key => {
    //         notificationList.push({ref: key, value: list[key]});
    //     });
    //
    //     const freshNotifications = notificationList.filter(notify => notify.value.timestamp);
    //
    //     return freshNotifications.sort((a, b) => {
    //         if (+new Date(a.value.timestamp) < +new Date(b.value.timestamp)) {
    //             return 1;
    //         }
    //         if (+new Date(a.value.timestamp) > +new Date(b.value.timestamp)) {
    //             return -1;
    //         }
    //     });
    // }

    // filterLabel(filterValue) {
    //     return this.filterNotificationsTypes.find(el => el.value === filterValue).label;
    // }

    markAsSeen(notificationToMark: {ref: number; value: SpannerNotificationInterface}) {
        if (this.markAsSeenPending || !this.sessionService.profile) {
            return;
        }
        this.markAsSeenPending = true;
        this.showCustomProgressBar = true;

        let isRemove = false;
        if (
            notificationToMark.value.seen_by_sense ===
            UserNotification.provideSeenBySenseTypes().who_marked_as_not_viewed
        ) {
            isRemove = true;
        }

        this.updateNotificationUI(notificationToMark.ref, 'seen_by', isRemove);

        this.messagesService
            .updateAsyncNotification(notificationToMark.ref, {
                fieldToUpdate: 'seen_by',
                value: this.sessionService.profile.id
            })
            .then(() => {
                this.showCustomProgressBar = false;
                this.markAsSeenPending = false;
            })
            .catch(() => {
                this.showCustomProgressBar = false;
                this.markAsSeenPending = false;

                this.updateNotificationUI(notificationToMark.ref, 'seen_by', isRemove);
            });
    }

    markAsUnseen(notificationToMark: {ref: number; value: SpannerNotificationInterface}) {
        if (this.markAsUnseenPending || !this.sessionService.profile) {
            return;
        }
        this.markAsUnseenPending = true;
        this.showCustomProgressBar = true;

        let isRemove = true;
        if (
            notificationToMark.value.seen_by_sense ===
            UserNotification.provideSeenBySenseTypes().who_marked_as_not_viewed
        ) {
            isRemove = false;
        }

        this.updateNotificationUI(notificationToMark.ref, 'seen_by', isRemove);

        // there is no such field as 'unseen_by', but it used to detect seen or unseen action fired for backend
        this.messagesService
            .updateAsyncNotification(notificationToMark.ref, {
                fieldToUpdate: 'unseen_by',
                value: this.sessionService.profile.id
            })
            .then(() => {
                this.showCustomProgressBar = false;
                this.markAsUnseenPending = false;
            })
            .catch(() => {
                this.showCustomProgressBar = false;
                this.markAsUnseenPending = false;

                // if failed - return as it was before
                this.updateNotificationUI(notificationToMark.ref, 'seen_by', !isRemove);
            });
    }

    updateNotificationUI(notificationId: number, field: 'flagged_by' | 'seen_by', isRemove: boolean) {
        const indexOfUpdatingNotification = this.notifications.findIndex((el) => el.ref === notificationId);

        // update notification flagged_by/seen_by list
        if (indexOfUpdatingNotification > -1) {
            // if (isRemove) {
            // this.notifications[indexOfUpdatingNotification].value[field] =
            //     this.notifications[indexOfUpdatingNotification].value[field]
            //         .filter(id => Number(id) !== Number(this.sessionService.profile.id));
            // } else {
            // this.notifications[indexOfUpdatingNotification].value[field].push(this.sessionService.profile.id);
            // }
            // this.notifications[indexOfUpdatingNotification].value.IS_SEEN = this.isSeen(this.notifications[indexOfUpdatingNotification].value);

            const nextList = this.userNotificationService.spannerNotifications.map((n) => {
                if (!this.sessionService.profile || !this.sessionService.profile.id) {
                    return n;
                }
                if (n.notification_id === notificationId) {
                    let nextArr = [];
                    if (isRemove) {
                        const profileId = this.sessionService.profile.id;
                        nextArr = n[field].filter((id) => Number(id) !== Number(profileId));
                    } else {
                        nextArr = n[field].concat([this.sessionService.profile.id]);
                    }
                    return {
                        ...n,
                        [field]: nextArr,
                        IS_SEEN: this.isSeen(n)
                    };
                }
                return n;
            });
            this.userNotificationService.updateSpannerNotificationsList(nextList);

            // this.changeDetector.detectChanges();
        } else {
            console.warn('notification not found');
        }

        // updating unread messages counter UI
        if (field === 'seen_by' && indexOfUpdatingNotification > -1) {
            // if category of this notification is muted - ignore
            if (
                this.mutedCategories.some(
                    (mutedCategory) => mutedCategory === this.notifications[indexOfUpdatingNotification].value.category
                )
            ) {
                return;
            }

            const counter = this.userNotificationService.asyncNotificationsUnreadCount;
            if (isRemove) {
                if (
                    this.notifications[indexOfUpdatingNotification].value.seen_by_sense ===
                    UserNotification.provideSeenBySenseTypes().who_marked_as_viewed
                ) {
                    this.userNotificationService.asyncNotificationsUnreadCountChange.next(counter + 1);
                } else {
                    if (counter > 0) {
                        this.userNotificationService.asyncNotificationsUnreadCountChange.next(counter - 1);
                    }
                }
            } else {
                if (
                    this.notifications[indexOfUpdatingNotification].value.seen_by_sense ===
                    UserNotification.provideSeenBySenseTypes().who_marked_as_viewed
                ) {
                    if (counter > 0) {
                        this.userNotificationService.asyncNotificationsUnreadCountChange.next(counter - 1);
                    }
                } else {
                    this.userNotificationService.asyncNotificationsUnreadCountChange.next(counter + 1);
                }
            }
        }
    }

    markAsFlagged(ev: {notification: {ref: number; value: SpannerNotificationInterface}; alreadyFlagged: boolean}) {
        if (this.markAsFlaggedPending || !this.sessionService.profile) {
            return;
        }
        this.markAsFlaggedPending = true;
        this.showCustomProgressBar = true;

        const {notification, alreadyFlagged} = ev;
        const direction = alreadyFlagged ? 'unflagged_by' : 'flagged_by';

        // update UI before request sended
        this.updateNotificationUI(notification.ref, 'flagged_by', alreadyFlagged);

        this.messagesService
            .updateAsyncNotification(notification.ref, {
                fieldToUpdate: direction,
                value: this.sessionService.profile.id
            })
            .then(() => {
                this.showCustomProgressBar = false;
                this.markAsFlaggedPending = false;
            })
            .catch(() => {
                this.showCustomProgressBar = false;
                this.markAsFlaggedPending = false;

                // if failed - return as it was before
                this.updateNotificationUI(notification.ref, 'flagged_by', !alreadyFlagged);
            });
    }

    ngOnDestroy() {
        if (this.asyncNotificationsSection) {
            this.asyncNotificationsSection.nativeElement.parentElement.parentElement.removeAllListeners();
        }
        this.asyncNotificationsCounterUnsubscribe.next();
        this.asyncNotificationsCounterUnsubscribe.complete();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
