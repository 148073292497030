import {TitleCasePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    standalone: true,
    name: 'snakeOrKebabToTitleCase'
})
export class SnakeOrKebabToTitleCasePipe implements PipeTransform {
    transform(value: string): string {
        return new TitleCasePipe().transform(value).replace(/[-_]/g, ' ');
    }
}
