<div class="gplaces-picker-container">
    <mat-form-field
        class="query-container"
        [class.hidden]="isPickedChipNeeded && locationCtrl?.value?.formatted_address"
    >
        <!-- [class.hidden]="!this.multiplePlacesMode && (this.placesSub | async)?.length === 1"> -->

        <!-- to disable addresses autofill added 'type' and 'name' props -->
        <input
            #query
            matInput
            type="search"
            name="saddgsdfgsfgsagethty"
            autocomplete="off"
            [autofocus]="focused"
            id="autocomplete"
            (focus)="geolocate()"
            [placeholder]="query.value.length < 3 ? placeholder : 'Select location from the list'"
        />
    </mat-form-field>
    <mat-chip-list class="places-list-container" *ngIf="isPickedChipNeeded && locationCtrl?.value?.formatted_address">
        <mat-chip class="place-chip" color="primary">
            {{ locationCtrl?.value?.formatted_address }}
            <span class="sp-spacer"></span>
            <mat-icon matChipRemove (click)="removePlace()">cancel</mat-icon>
        </mat-chip>
    </mat-chip-list>
</div>
