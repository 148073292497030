import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {RbacService} from '../../../../../../../../rbac/rbac.service';
import {MatPaginator} from '@angular/material/paginator';
import {IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {MatTabGroup} from '@angular/material/tabs';

@Component({
    selector: 'app-zipi-fin-transfers-page',
    templateUrl: './zipi-fin-transfers-log-page.component.html',
    styleUrls: [
        './zipi-fin-transfers-log-page.component.scss',
        '../../../../../../../../../../assets/infinite-scroll-table.scss'
    ]
})
export class ZipiFinTransfersLogPageComponent implements OnInit, OnChanges, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() gateway: IPaymentGateway | undefined;
    @Input() isVisible: boolean | undefined;
    @Input() isTabChanged: Subject<string> | undefined;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;
    @ViewChild('tabs', {static: false}) tabs: MatTabGroup | undefined;

    completedTransfers: {result: Array<any>; _meta: {total: number}} | undefined;
    processingTransfers: {result: Array<any>; _meta: {total: number}} | undefined;
    scheduledTransfers: {result: Array<any>; _meta: {total: number}} | undefined;
    canceledTransfers: {result: Array<any>; _meta: {total: number}} | undefined;

    processingTabTag: 'processing' | undefined = 'processing';
    completedTabTag: 'completed' | undefined = 'completed';
    scheduledTabTag: 'scheduled' | undefined;
    canceledTabTag: 'canceled' | undefined;

    constructor(
        public router: Router,
        public dialog: MatDialog,
        protected rbacService: RbacService
    ) {}

    ngOnInit() {
        this.rbacService.isAllowed({marketplace__manage_zipipay: true}).then((can) => {
            if (!can) {
                this.router.navigate(['/']);
            }
        });
        if (this.isTabChanged) {
            this.isTabChanged.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
                if (event === 'change' && this.tabs) {
                    this.tabs.realignInkBar();
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.hasOwnProperty('isVisible') && changes.isVisible.currentValue) {
            if (this.tabs) {
                this.tabs.realignInkBar();
            }
        }
    }

    tabChanged($event: any) {
        switch ($event.index) {
            case 1: {
                if (!this.scheduledTabTag) {
                    this.scheduledTabTag = 'scheduled';
                }
                break;
            }
            case 2: {
                if (!this.canceledTabTag) {
                    this.canceledTabTag = 'canceled';
                }
                break;
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
