import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Deal} from '../../../../../../../models/deal';
import {select, Store} from '@ngrx/store';
import {IContactsState} from '../../../../../../contacts/store/contacts.reducer';
import {selectContacts} from '../../../../../../contacts/store/contacts.selectors';
import {IContact, IContactPartLink} from '@cyberco-nodejs/zipi-typings';
import {FetchContacts} from '../../../../../../contacts/store/contacts.actions';
import {shareReplay, takeUntil} from 'rxjs/operators';
import {isEmpty} from 'lodash-es';
import {Subject} from 'rxjs';
import {RbacService} from '../../../../../../rbac/rbac.service';

@Component({
    selector: 'app-view-parties-deal',
    styleUrls: ['../view-deal.component.scss'],
    templateUrl: 'parties-deal.component.html'
})
export class ViewPartiesDealComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() deal: Deal | undefined = undefined;

    contacts: IContact[] = [];
    isAllowedContacts: boolean = false;

    constructor(
        private store: Store<IContactsState>,
        protected rbacService: RbacService
    ) {}

    async ngOnInit() {
        this.isAllowedContacts = await this.rbacService.isAllowedAny([
            {contacts__view_limited_contacts: true},
            {contacts__view_any_contacts: true}
        ]);
        if (this.isAllowedContacts) {
            this.initContacts();
        }
    }

    initContacts() {
        this.store.dispatch(new FetchContacts());
        this.store
            .pipe(
                select(selectContacts), // migrate to ContactsLite: done
                shareReplay(1),
                takeUntil(this.unsubscribe)
            )
            .subscribe((data) => {
                this.contacts = data;
            });
    }

    getCompanyName(contact: IContact | null | undefined) {
        if (!contact) {
            return;
        }
        const subject = this.contacts.find((item) => item.contact_id === contact.id);
        if (!subject) {
            return;
        }
        return subject.company_name;
    }

    getCompanyPhone(contact: IContact | null | undefined) {
        if (!contact) {
            return;
        }
        const subject = this.contacts.find((item) => item.contact_id === contact.id);
        if (!subject) {
            return;
        }
        return subject.contact_persons && subject.contact_persons.length && subject.contact_persons[0].phone
            ? subject.contact_persons[0].phone
            : null;
    }

    isObjectNotEmpty(obj: IContactPartLink[] | IContactPartLink | null): boolean {
        return !isEmpty(obj);
    }

    setAttribute(id: string, type: 'none' | 'block') {
        if (document.getElementById(id)) {
            document.getElementById(id)?.setAttribute('style', `display: ${type};`);
        }
    }

    checkIfAllItemsInTextFormatIsIgnored(dealParticipants: IContactPartLink[] | null): boolean {
        if (!dealParticipants) {
            return true;
        }
        return dealParticipants.every((dp) => dp.format === 'text' && dp.is_ignored);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
