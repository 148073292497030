import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class WidgetSource {
    private dataSource: {[key: string]: any} = {};
    public dataSubject: Subject<any> = new Subject<any>();

    /**
     * Update subject data
     * @param data
     */
    updateSubjectData(data: {[key: string]: any}): void {
        this.dataSource[data.uid] = data;
        this.dataSubject.next(this.dataSource[data.uid]);
    }

    /**
     * Is data exist for widget
     * @param uid
     */
    isDataExistForWidget(uid: number) {
        return !!this.dataSource[uid];
    }

    /**
     * Trigger widget data by id
     * @param uid
     */
    triggerWidgetDataById(uid: number) {
        this.dataSubject.next(this.dataSource[uid]);
    }
}
