import {RouterStateSnapshot} from '@angular/router';
import {MetaReducer, ActionReducer} from '@ngrx/store';
import {RouterReducerState, routerReducer} from '@ngrx/router-store';

import {IndicatorsState, indicatorsReducer} from '../modules/indicators/store/indicators.reducer';
import {IContactsState, contactsReducer} from '../modules/contacts/store/contacts.reducer';
import {ISubCompaniesState, subCompaniesReducer} from '../modules/sub-companies/store/subcompanies.reducer';
import {ISettingsState, settingsReducer} from '../modules/account-info/store/settings.reducer';
import {INavbarState, navbarReducer} from './navbar.store/navbar.reducer';
import {IFinanceState, financeReducer} from 'app/modules/finance/store/finance.reducer';
import {companyWideReducer, ICompanyWideState} from './company-wide/company-wide.reducer';

export interface AppState {
    contacts: IContactsState;
    indicators: IndicatorsState;
    navbar: INavbarState;
    routerReducer: RouterReducerState<RouterStateSnapshot>;
    settings: ISettingsState;
    subcompanies: ISubCompaniesState;
    finance: IFinanceState;
    companyWide: ICompanyWideState;
}

const RESET_ACTIONS = new Set(['LOGOUT', 'RESET']);

export function resetAllState(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        if (RESET_ACTIONS.has(action.type)) {
            state = undefined;
        }
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<AppState>[] = [resetAllState];

// Alex: this is old version of metaReducers. StoreFreeze was excluded because it's break Ivy compilation
// export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [ resetAllState, storeFreeze ] : [ resetAllState ];

export const reducers = {
    contacts: contactsReducer,
    indicators: indicatorsReducer,
    navbar: navbarReducer,
    routerReducer: routerReducer,
    settings: settingsReducer,
    subcompanies: subCompaniesReducer,
    finance: financeReducer,
    companyWide: companyWideReducer
};
