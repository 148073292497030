import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IFinanceState} from '../../../store/finance.reducer';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {map, filter, takeUntil, tap} from 'rxjs/operators';
import {assign} from 'lodash-es';
import {IContact, IInvoice, ILoan} from '@cyberco-nodejs/zipi-typings';
import {FetchContacts} from 'app/modules/contacts/store/contacts.actions';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {INTEREST_PERIOD} from 'app/local-typings';
import * as moment from 'moment';
import {LoansService} from '../../../../../services/api/finance/loans.service';

@Component({
    selector: 'app-garnishment-page',
    templateUrl: 'garnishment-page.component.html',
    styleUrls: ['garnishment-page.component.css']
})
export class GarnishmentPageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    dataSource: MatTableDataSource<IInvoice>;

    displayedRelatedColumns = ['invoice_id', 'invoice_date', 'summary_status', 'total_amount', 'balance'];

    garnishment:
        | (ILoan & {contacts: IContact[]; interestPeriod: {value: string; label: string}; interestLimit: number})
        | undefined;
    interestPeriod = INTEREST_PERIOD;
    showInvoices: boolean;

    constructor(
        private store: Store<IFinanceState>,
        private route: ActivatedRoute,
        public loansService: LoansService,
        public dialog: MatDialog
    ) {
        this.dataSource = new MatTableDataSource<IInvoice>([]);
        this.showInvoices = false;
    }

    initPreview() {
        this.route.paramMap
            .pipe(
                map((pm) => {
                    const stringId: string | null = pm.get('id');
                    return Number(stringId);
                }),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                if (id) {
                    this.loansService
                        .getLoanById(id)
                        .pipe(
                            tap((loan: ILoan) => {
                                const interestPeriod = this.interestPeriod.find(
                                    (period) => period.value === loan.interest_period
                                );
                                let interestLimit = '';
                                if (loan.interest_rate_limit_type === 'number_of_periods') {
                                    interestLimit = `(${loan.interest_rate_limit_number} periods)`;
                                } else if (loan.interest_rate_limit_type === 'date') {
                                    interestLimit = `(until ${moment(loan.interest_rate_limit_date).format('ll')})`;
                                }

                                return assign({}, loan, {
                                    interestPeriod: interestPeriod,
                                    interestLimit: interestLimit
                                });
                            }),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe((loan: any) => {
                            this.garnishment = loan;
                            this.dataSource.data = loan.invoice_items;
                        });
                }
            });
    }

    confirmPaid() {}

    chargeCustomer() {}

    ngOnInit() {
        this.store.dispatch(new FetchContacts());

        this.initPreview();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
