import {Component, Input, OnInit} from '@angular/core';
import {AuditLog, AuditLogChange, ReadableAuditLogChange} from '../../../../../../../models/audit';
import {
    AuditLogVocabulary,
    TYPE_CALLBACK,
    TYPE_LIST,
    TYPE_NUMBER,
    TYPE_PERCENTAGE,
    TYPE_STRING
} from '../audit-log.vocaulary';
import {FinancialElementModel} from '../../../../../../account-info/compensation/models/financial-element.model';

@Component({
    selector: 'app-audit-log-row',
    templateUrl: './audit-log-row.component.html',
    styleUrls: ['./audit-log-row.component.scss']
})
export class AuditLogRowComponent implements OnInit {
    @Input() auditLog: AuditLog | null = null;

    public detailsVisible = false;
    public readableChanges: ReadableAuditLogChange[] = [];

    protected vocabularyData: {[index: string]: any} = new AuditLogVocabulary().data;

    ngOnInit() {}

    /**
     * Converts stored at Datastore changes to readable changes.
     * Always returns a list of objects.
     *
     * @param {AuditLogChange} change
     * @return {ReadableAuditLogChange[]}
     */
    convertToReadableChanges(change: AuditLogChange): ReadableAuditLogChange[] {
        if (!change.ancestors) {
            change.ancestors = [];
        }

        // if changes are objects
        if (
            !Array.isArray(change.after) &&
            !Array.isArray(change.before) &&
            typeof change.after === 'object' &&
            typeof change.before === 'object'
        ) {
            return this.getReadableChangesFromObjects(
                change.before,
                change.after,
                change.property || '',
                change.ancestors
            );
        }

        // todo check arrays
        // if change is a string, number, or date
        const readableChange = new ReadableAuditLogChange();

        const params = this.getPropertyParams(change.property || '', change.ancestors);
        const ancestorsString = change.ancestors.length > 0 ? change.ancestors.join('.') + '.' : ' ';

        readableChange.label = params.label || `${ancestorsString}${change.property}`;
        readableChange.type = params.type || TYPE_STRING;

        readableChange.before = this.getPropertyReadableValue(change.before, params);
        readableChange.after = this.getPropertyReadableValue(change.after, params);

        return [readableChange];
    }

    private getReadableChangesFromObjects(
        beforeObj: {[index: string]: any},
        afterObj: {[index: string]: any},
        parentProperty: string = '',
        parentAncestors: string[] = []
    ): ReadableAuditLogChange[] {
        let readableChangesList: ReadableAuditLogChange[] = [];

        for (const prop in afterObj) {
            if (afterObj.hasOwnProperty(prop)) {
                const after = afterObj[prop];

                // before is null if audit log event was "create"
                const before = beforeObj !== null ? beforeObj[prop] : null;

                if (after === before) {
                    continue;
                }

                const change = new AuditLogChange();

                change.after = after;
                change.before = before;
                change.property = prop;

                change.ancestors = change.ancestors.concat(parentAncestors);
                change.ancestors.push(parentProperty);

                readableChangesList = readableChangesList.concat(this.convertToReadableChanges(change));
            }
        }

        return readableChangesList;
    }

    private getPropertyParams(property: string, ancestors: string[] = []): {label: string; type: string} {
        let path = this.vocabularyData[this.auditLog!.target!];

        ancestors.forEach((ancestor) => {
            if (!path) {
                return false;
            }

            path = path[ancestor];
        });

        return path && path[property] ? path[property] : {};
    }

    private getPropertyReadableValue(propertyValue: any, params: {type?: string; label?: string; callback?: any}) {
        if (!params.type) {
            params.type = TYPE_STRING;
        }

        if (!params.callback) {
            params.callback = (v: any) => v;
        }

        switch (params.type) {
            case TYPE_LIST: {
                if (!propertyValue) {
                    propertyValue = [];
                }

                return propertyValue.join(', ');
            }

            case TYPE_NUMBER: {
                return Number(propertyValue);
            }

            case TYPE_PERCENTAGE: {
                return String(propertyValue ? propertyValue : '0') + ' %';
            }

            case TYPE_CALLBACK: {
                return params.callback(propertyValue);
            }

            // case TYPE_DATE: {
            //     return new Date(propertyValue).toLocaleDateString('%d-%b-%Y')
            // }

            default: {
                return propertyValue;
            }
        }
    }

    public triggerDetailsVisibility() {
        this.detailsVisible = !this.detailsVisible;

        if (this.detailsVisible) {
            this.readableChanges = [];

            this.auditLog!.changes_list.forEach((change) => {
                const readableList = this.convertToReadableChanges(change);

                this.readableChanges = this.readableChanges.concat(readableList);
            });
        }
    }
}
