import {filter, delay, takeUntil} from 'rxjs/operators';
import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    UntypedFormArray,
    UntypedFormControl,
    Validators,
    ValidatorFn,
    AbstractControl
} from '@angular/forms';
import {Store} from '@ngrx/store';
import {flow as pipe, last, assign, mapValues} from 'lodash-es';
import {Subject} from 'rxjs';
import {IGooglePlaceObject, ICompanyLocation, ICompanySettings} from '@cyberco-nodejs/zipi-typings';
import {ISettingsState} from '../store/settings.reducer';
import {
    FetchCompanyLocations,
    UpdateCompanyLocation,
    CreateCompanyLocation,
    DeleteCompanyLocation,
    FetchCompanySettings,
    UpdateCompanySettings
} from '../store/settings.actions';
import {selectCompanyLocations} from '../store/settings.selectors';
import {clearFormArray, countryList, statesAndTerritories} from '../../../utilities';
import {selectCompanySettings} from '../store/settings.selectors';
import {CurrentProfileSource} from '../../../services/sources/current-profile.source';

const requiredLocationProps = new Set(['country', 'city', 'state', 'street_address', 'street_number', 'label']);

const NEW_COMPANY_LOCATION = <ICompanyLocation>{
    country: '',
    city: '',
    state: '',
    street_address: '',
    street_number: '',
    zip: '',
    unit_number: '',
    label: '',
    phone: ''
};

// extractLocationComponents (lcn?: IGooglePlaceObject)
const extractLocationComponents = (lcn?: IGooglePlaceObject) => {
    return <ICompanyLocation>lcn?.address_components.reduce((acc: any, addressComponent: any) => {
        for (const componentType of addressComponent.types) {
            switch (componentType) {
                case 'country':
                    return {...acc, country: addressComponent.long_name};

                case 'locality':
                    return {...acc, city: addressComponent.long_name};

                case 'administrative_area_level_1':
                    return {...acc, state: addressComponent.short_name};

                case 'route':
                    return {
                        ...acc,
                        street_address: addressComponent.short_name || addressComponent.long_name,
                        label: addressComponent.short_name || addressComponent.long_name
                    };

                case 'street_number':
                    return {...acc, street_number: addressComponent.long_name};

                case 'postal_code':
                    return {...acc, zip: addressComponent.long_name};

                default:
                    return acc;
            }
        }
    }, NEW_COMPANY_LOCATION);
};

function zipCodeValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        const zipCode = control.value;
        if (!zipCode) {
            return null; // Don't validate empty values
        }

        const isValid = /(^\d{5}-\d{4}?$)|(^\d{5}?$)|(^\d{9}?$)|(^\d{12}?$)/.test(zipCode);
        return isValid ? null : {invalidZipCode: true};
    };
}

@Component({
    selector: 'app-company-settings',
    templateUrl: 'company-settings.component.html',
    styleUrls: ['company-settings.component.css'],
    // changeDetection: 1,
    preserveWhitespaces: false
})
export class CompanySettingsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    companySettings: ICompanySettings | undefined = undefined;
    companyLocations: ICompanyLocation[] = [];
    locationsToRemove = new Set<number>();
    editingLocationIndex: number | null = null;

    formCtrl: UntypedFormGroup | undefined = undefined;
    companyLocationsCtrl: UntypedFormArray | undefined = undefined;
    locationPickerCtrl = new UntypedFormControl();
    public showErrors = false;
    errMatcher = {
        isErrorState: (control: UntypedFormControl) =>
            this.showErrors && (control.hasError('required') || control.errors?.invalidZipCode)
    };

    constructor(
        private fb: UntypedFormBuilder,
        private cdRef: ChangeDetectorRef,
        private store: Store<ISettingsState>,
        protected profileSource: CurrentProfileSource
    ) {
        // fetch new address on profile change
        this.profileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.store.dispatch(new FetchCompanyLocations());
            this.store.dispatch(new FetchCompanySettings());
            // this.ngOnInit();
        });
    }

    // makeNewLocationCtrl -> ICompanyLocation -> FormGroup
    makeNewLocationCtrl = pipe(
        (lcn: ICompanyLocation) =>
            mapValues(lcn, (value: string) => [value, requiredLocationProps.has(value) ? Validators.required : []]),
        (lcn: ICompanyLocation) => (lcn.phone ? lcn : assign({}, lcn, {phone: ''})),
        (lcn: ICompanyLocation) => this.fb.group(lcn, {updateOn: 'blur'})
    );

    initCompanyLocations(lcnx: ICompanyLocation[]) {
        this.companyLocations = lcnx;

        if (this.companyLocationsCtrl) {
            clearFormArray(this.companyLocationsCtrl);
        }

        lcnx.forEach(this.addLocation.bind(this));
    }

    saveCompanySettings() {
        if (typeof this.formCtrl === 'undefined') {
            return;
        }

        const updCompanySettings = {
            billing_address_label: this.formCtrl.get('billing_address')?.value,
            default_disbursement_check_delivery_address: this.formCtrl.get(
                'default_disbursement_check_delivery_address'
            )?.value,
            payroll_location_id: this.formCtrl.get('payroll_location_id')?.value
        };

        if (Object.keys(updCompanySettings).length === 0) {
            return;
        }
        this.store.dispatch(new UpdateCompanySettings(updCompanySettings));
    }

    saveCompanyLocations() {
        if (typeof this.companyLocationsCtrl === 'undefined') {
            return;
        }

        this.companyLocationsCtrl.controls.forEach((ctrl) => {
            if (ctrl.invalid) {
                return;
            }

            if (ctrl.value.id && !ctrl.pristine) {
                this.store.dispatch(new UpdateCompanyLocation(ctrl.value));
            }

            if (!ctrl.value.id) {
                this.store.dispatch(new CreateCompanyLocation(ctrl.value));
            }
        });

        [...this.locationsToRemove]
            .filter((v) => v)
            .forEach((id) => this.store.dispatch(new DeleteCompanyLocation(id)));

        this.editingLocationIndex = null;
        this.locationsToRemove.clear();
        this.companyLocationsCtrl.markAsPristine();
    }

    saveCompany() {
        if (typeof this.formCtrl === 'undefined') {
            return;
        }

        if (this.formCtrl.invalid) {
            this.formCtrl.updateValueAndValidity({onlySelf: false, emitEvent: true});
            return;
        }

        if (!this.formCtrl.pristine) {
            this.saveCompanySettings();
        }
    }

    cancelChanges() {
        this.initCompanyLocations(this.companyLocations);
        if (this.companySettings) {
            this.initCompanySettings(this.companySettings);
        }

        this.locationsToRemove.clear();
        if (typeof this.formCtrl !== 'undefined') {
            this.formCtrl.markAsPristine();
        }
        if (typeof this.companyLocationsCtrl !== 'undefined') {
            this.companyLocationsCtrl.markAsPristine();
        }
    }

    removeLocation(idx: number) {
        if (typeof this.companyLocationsCtrl === 'undefined') {
            return;
        }

        if (this.companyLocationsCtrl.at(idx).value.id !== undefined) {
            this.locationsToRemove.add(this.companyLocationsCtrl.at(idx).value.id);
        }

        this.showErrors = false;

        let isCompanySettingAffected = false;
        const removedLocation = this.companyLocationsCtrl.at(idx).value;

        if (this.formCtrl?.get('billing_address')?.value === removedLocation.label) {
            this.formCtrl?.get('billing_address')?.patchValue('');
            isCompanySettingAffected = true;
        }
        if (this.formCtrl?.get('default_disbursement_check_delivery_address')?.value === removedLocation.label) {
            this.formCtrl?.get('default_disbursement_check_delivery_address')?.patchValue('');
            isCompanySettingAffected = true;
        }
        if (this.formCtrl?.get('payroll_location_id')?.value === removedLocation.id) {
            this.formCtrl?.get('payroll_location_id')?.patchValue(null);
            isCompanySettingAffected = true;
        }

        if (isCompanySettingAffected) {
            this.saveCompanySettings();
        }

        // save locations
        this.companyLocationsCtrl.removeAt(idx);
        this.saveCompanyLocations();

        // this.cdRef.detectChanges();
    }

    editLocation(idx: number) {
        this.editingLocationIndex = idx;
    }

    preSaveLocation(idx: number) {
        // this.cdRef.detectChanges();
        if (typeof this.companyLocationsCtrl !== 'undefined' && this.companyLocationsCtrl.at(idx).invalid) {
            this.showErrors = true;
            return;
        }

        this.companyLocationsCtrl?.markAsDirty();
        this.showErrors = false;
        this.editingLocationIndex = null;

        // save locations
        if (!this.companyLocationsCtrl?.pristine) {
            this.saveCompanyLocations();
        }
    }

    addLocation(lcn: ICompanyLocation = NEW_COMPANY_LOCATION) {
        if (typeof this.companyLocationsCtrl === 'undefined') {
            return;
        }

        const newControl = this.makeNewLocationCtrl(lcn);
        Object.keys(newControl.controls).forEach((key: string) => {
            if ('zip' in newControl.controls) {
                newControl.controls['zip'].setValidators([Validators.required, zipCodeValidator()]);
            }
        });

        this.companyLocationsCtrl.push(newControl);
        if (!lcn.id) {
            this.editLocation(this.companyLocationsCtrl.length - 1);
        }
        this.cdRef.detectChanges();
    }

    initCompanySettings(cs: ICompanySettings) {
        if (typeof this.formCtrl === 'undefined') {
            return;
        }

        this.companySettings = cs;
        this.formCtrl.get('billing_address')?.patchValue(cs.billing_address_label || '');
        this.formCtrl
            .get('default_disbursement_check_delivery_address')
            ?.patchValue(cs.default_disbursement_check_delivery_address || '');
        this.formCtrl.get('payroll_location_id')?.patchValue(cs.payroll_location_id || null);
    }

    ngOnInit() {
        this.store.dispatch(new FetchCompanyLocations());
        this.store.dispatch(new FetchCompanySettings());

        this.store
            .select(selectCompanyLocations)
            .pipe(delay(100), takeUntil(this.unsubscribe))
            // filter(lx => lx.length > 0))
            .subscribe(this.initCompanyLocations.bind(this));

        this.companyLocationsCtrl = this.fb.array([]);
        this.formCtrl = this.fb.group({
            locations: this.companyLocationsCtrl,
            billing_address: [{value: '', disabled: false}],
            default_disbursement_check_delivery_address: [{value: '', disabled: false}],
            payroll_location_id: [{value: null, disabled: false}]
        });

        this.store
            .select(selectCompanySettings)
            .pipe(
                filter((cs) => cs !== null),
                takeUntil(this.unsubscribe)
            )
            // @ts-ignore
            .subscribe(this.initCompanySettings.bind(this));

        this.locationPickerCtrl.valueChanges
            .pipe(
                filter((l) => l),
                takeUntil(this.unsubscribe)
            )
            .subscribe((glcn) => {
                this.addLocation(extractLocationComponents(glcn));
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    protected readonly countryList = countryList;
    protected readonly statesAndTerritories = statesAndTerritories;
}
