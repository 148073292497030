<div class="row">
    <!------- Seller Agent ------->
    <div class="col-lg-6 pb-3">
        <app-combined-entity-picker
            [title]="'Seller Agent'"
            [availableTypes]="['contact_person']"
            [initialContactProfileIds]="selectedSellerAgentIds"
            [nodesFA]="selectedSellerAgentForm"
            [contactMetatype]="'agent'"
            [displayMode]="'double-line'"
            [positionMode]="'bottom'"
            [disableUpdate]="disableSellerAgents || dealFormGroup.controls.deal_participants?.disabled"
            [disabled]="dealFormGroup.controls.deal_participants?.disabled"
            [textParticipants]="textParticipantsSellerAgents"
            (contactCreateEmitter)="contactCreated($event, 'seller_agents')"
            (isLoadedEmitter)="disableSellerAgents = false"
            (toggleVisibilityEmitter)="toggleVisibility($event, 'seller_agents')"
            (contactCreateFromTextEmitter)="contactCreateFromText($event, 'seller_agents', 'agent')"
        ></app-combined-entity-picker>
    </div>

    <!------- Buyer Agent ------->
    <div class="col-lg-6 pb-3">
        <app-combined-entity-picker
            [title]="'Buyer Agent'"
            [availableTypes]="['contact_person']"
            [initialContactProfileIds]="selectedBuyerAgentIds"
            [nodesFA]="selectedBuyerAgentForm"
            [contactMetatype]="'agent'"
            [displayMode]="'double-line'"
            [positionMode]="'bottom'"
            [disableUpdate]="disableBuyerAgents || dealFormGroup.controls.deal_participants?.disabled"
            [disabled]="dealFormGroup.controls.deal_participants?.disabled"
            [textParticipants]="textParticipantsBuyerAgents"
            (contactCreateEmitter)="contactCreated($event, 'buyer_agents')"
            (isLoadedEmitter)="disableBuyerAgents = false"
            (toggleVisibilityEmitter)="toggleVisibility($event, 'buyer_agents')"
            (contactCreateFromTextEmitter)="contactCreateFromText($event, 'buyer_agents', 'agent')"
        ></app-combined-entity-picker>
    </div>

    <!------- Referring From Agent ------->
    <div class="col-lg-6 pb-3">
        <app-combined-entity-picker
            [title]="'Referring From Agent'"
            [bold_style]="false"
            [availableTypes]="['contact_person']"
            [initialContactProfileIds]="selectedReferringFromAgentIds"
            [nodesFA]="selectedReferringFromAgentForm"
            [singleSelected]="true"
            [contactMetatype]="'agent'"
            [disableUpdate]="disableFromAgent || dealFormGroup.controls.deal_participants?.disabled"
            [disabled]="dealFormGroup.controls.deal_participants?.disabled"
            [textParticipants]="textParticipantsReferringFromAgent"
            (contactCreateEmitter)="contactCreated($event, 'referring_from_agent')"
            (isLoadedEmitter)="disableFromAgent = false"
            (toggleVisibilityEmitter)="toggleVisibility($event, 'referring_from_agent')"
            (contactCreateFromTextEmitter)="contactCreateFromText($event, 'referring_from_agent', 'agent')"
        ></app-combined-entity-picker>
    </div>

    <!------- Referring To Agent ------->
    <div class="col-lg-6 pb-3">
        <app-combined-entity-picker
            [title]="'Referring To Agent'"
            [bold_style]="false"
            [availableTypes]="['contact_person']"
            [initialContactProfileIds]="selectedReferringToAgentIds"
            [nodesFA]="selectedReferringToAgentForm"
            [singleSelected]="true"
            [contactMetatype]="'agent'"
            [disableUpdate]="disableToAgent || dealFormGroup.controls.deal_participants?.disabled"
            [disabled]="dealFormGroup.controls.deal_participants?.disabled"
            [textParticipants]="textParticipantsReferringToAgent"
            (contactCreateEmitter)="contactCreated($event, 'referring_to_agent')"
            (isLoadedEmitter)="disableToAgent = false"
            (toggleVisibilityEmitter)="toggleVisibility($event, 'referring_to_agent')"
            (contactCreateFromTextEmitter)="contactCreateFromText($event, 'referring_to_agent', 'agent')"
        ></app-combined-entity-picker>
    </div>

    <!------- Closing Company ------->
    <div class="col-6 pb-3">
        <div class="thin-title">Closing Company</div>
        <app-combined-entity-picker
            [title]="''"
            [bold_style]="false"
            [availableTypes]="['contact']"
            [initialContactIds]="selectedEscrowAgentIds"
            [nodesFA]="selectedEscrowAgentForm"
            [singleSelected]="true"
            [contactMetatype]="'vendor'"
            [contactPersonMain]="true"
            [disableUpdate]="disableClosingCompany || dealFormGroup.controls.deal_participants?.disabled"
            [disabled]="dealFormGroup.controls.deal_participants?.disabled"
            [textParticipants]="textParticipantsClothingCompany"
            (contactCreateEmitter)="contactCreated($event, 'escrow_agent')"
            (isLoadedEmitter)="disableClosingCompany = false"
            (toggleVisibilityEmitter)="toggleVisibility($event, 'escrow_agent')"
            (contactCreateFromTextEmitter)="contactCreateFromText($event, 'escrow_agent', 'vendor')"
        ></app-combined-entity-picker>
    </div>

    <!------- Representative ------->
    <div class="col-6 pb-3">
        <!-- if escrow company selected -->
        <mat-form-field
            class="w-100"
            *ngIf="dealParticipants.escrow_agent"
            [ngClass]="disableClosingCompany ? 'picker-form-input' : ''"
        >
            <mat-chip-list #chipList>
                <mat-chip
                    class="p-0 px-1 m-0 mr-2 mt-1 chip-item"
                    [class.hide]="!dealParticipants.escrow_agent?.contact_person_fk_id"
                    [value]="dealParticipants.escrow_agent"
                    [disabled]="dealFormGroup.controls.deal_participants?.disabled"
                    (removed)="doRemoveRepresentative()"
                >
                    <span class="ml-1" [ngClass]="dealParticipants.escrow_agent?.hidden ? 'hidden-contact' : ''">
                        {{ dealParticipants.escrow_agent?.link_title }}
                    </span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                    [class.hide]="dealParticipants.escrow_agent?.contact_person_fk_id"
                    placeholder="Representative"
                    #filter
                    [formControl]="filterFC"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputAddOnBlur]="true"
                />
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                    *ngFor="let contactPerson of contactPersonsMapped"
                    [value]="contactPerson.contact_person_id"
                    (onSelectionChange)="updateEscrowPerson(contactPerson)"
                >
                    <span [ngClass]="dealParticipants.escrow_agent?.hidden ? 'hidden-contact' : ''">{{
                        contactPerson.full_name
                    }}</span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <!-- if not -->
        <mat-form-field
            class="w-100"
            *ngIf="!dealParticipants.escrow_agent && escrowContacts"
            [ngClass]="disableClosingCompany ? 'picker-form-input' : ''"
        >
            <mat-chip-list #chipList>
                <input
                    placeholder="Representative"
                    #filter
                    [formControl]="filterFC"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputAddOnBlur]="true"
                />
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                    *ngFor="let contactPerson of contactPersons"
                    [value]="contactPerson.contact_person_id"
                    (onSelectionChange)="updateEscrowPersonFirst(contactPerson)"
                >
                    <span>{{ contactPerson.link_title }}</span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <button
            type="button"
            *ngIf="dealParticipants.escrow_agent?.contact_id"
            (click)="escrowAgentCreate($event)"
            [disabled]="
                !(dealParticipants.escrow_agent?.contact_id || (!dealParticipants.escrow_agent && escrowContacts)) ||
                dealFormGroup.controls.deal_participants?.disabled
            "
            class="escrow-company-btn"
            mat-icon-button
            matTooltip="Create Representative"
        >
            <mat-icon style="font-size: 14px; line-height: 14px">person_add</mat-icon>
        </button>
    </div>

    <!------- Closing Company Location ------->
    <div class="col-12 pb-3">
        <mat-form-field
            class="w-100"
            *ngIf="escrowContacts"
            [ngClass]="disableClosingCompany ? 'picker-form-input' : ''"
        >
            <mat-select
                [disabled]="disableClosingCompany || dealFormGroup.controls.deal_participants?.disabled"
                [value]="dealParticipants.escrow_agent?.contact_location_fk_id"
                [placeholder]="'Closing Company Location'"
            >
                <app-infinite-scroll (scrolled)="nextBatch()" *ngIf="showMore !== undefined">
                    <mat-option
                        [value]="location.contact_location_id"
                        *ngFor="let location of contactLocations.get(dealParticipants.escrow_agent?.contact_id!)"
                        (click)="updateEscrowLocation(location)"
                    >
                        <span *ngIf="location.street_number">{{ location.street_number + ' ' }}</span>
                        <span *ngIf="location.street_address">{{ location.street_address + ' ' }}</span>
                        <span *ngIf="location.unit_number">{{ location.unit_number + ' ' }}</span>
                        <span *ngIf="location.city">{{ location.city + ', ' }}</span>
                        <span *ngIf="location.state">{{ location.state + ' ' }}</span>
                        <span *ngIf="location.zip">{{ location.zip }}</span>
                    </mat-option>
                </app-infinite-scroll>
            </mat-select>
        </mat-form-field>
        <button
            *ngIf="dealParticipants.escrow_agent?.contact_id"
            type="button"
            (click)="escrowLocationCreate($event)"
            [disabled]="dealFormGroup.controls.deal_participants?.disabled"
            class="escrow-company-btn"
            mat-icon-button
            matTooltip="Create Location"
        >
            <mat-icon style="font-size: 14px; line-height: 14px">person_add</mat-icon>
        </button>
    </div>

    <!------- Seller ------->
    <div class="col-lg-6 pb-4">
        <div class="make-client">
            <h4>Seller</h4>
            <span class="make-client__btn" (click)="changeClientType(DEAL_CLIENT_TYPES.seller)">
                {{ dealClientType === DEAL_CLIENT_TYPES.seller ? '(Client)' : 'Make Client' }}
            </span>
        </div>

        <app-combined-entity-picker
            [title]="'Seller'"
            [availableTypes]="['contact_person']"
            [initialContactProfileIds]="selectedSellersIds"
            [nodesFA]="selectedSellersForm"
            [contactMetatype]="'customer'"
            [displayMode]="'double-line'"
            [positionMode]="'bottom'"
            [disableUpdate]="disableSellers || dealFormGroup.controls.deal_participants?.disabled"
            [disabled]="dealFormGroup.controls.deal_participants?.disabled"
            [textParticipants]="textParticipantsSellers"
            (contactCreateEmitter)="contactCreated($event, 'sellers')"
            (isLoadedEmitter)="disableSellers = false"
            (toggleVisibilityEmitter)="toggleVisibility($event, 'sellers')"
            (contactCreateFromTextEmitter)="contactCreateFromText($event, 'sellers', 'customer')"
        ></app-combined-entity-picker>
    </div>

    <!------- Buyer ------->
    <div class="col-lg-6 pb-4">
        <div class="make-client">
            <h4>Buyer</h4>
            <span class="make-client__btn" (click)="changeClientType(DEAL_CLIENT_TYPES.buyer)">
                {{ dealClientType === DEAL_CLIENT_TYPES.buyer ? '(Client)' : 'Make Client' }}
            </span>
        </div>

        <app-combined-entity-picker
            [title]="'Buyer'"
            [availableTypes]="['contact_person']"
            [initialContactProfileIds]="selectedBuyersIds"
            [nodesFA]="selectedBuyersForm"
            [contactMetatype]="'customer'"
            [displayMode]="'double-line'"
            [positionMode]="'bottom'"
            [disableUpdate]="disableBuyers || dealFormGroup.controls.deal_participants?.disabled"
            [disabled]="dealFormGroup.controls.deal_participants?.disabled"
            [textParticipants]="textParticipantsBuyers"
            (contactCreateEmitter)="contactCreated($event, 'buyers')"
            (isLoadedEmitter)="disableBuyers = false"
            (toggleVisibilityEmitter)="toggleVisibility($event, 'buyers')"
            (contactCreateFromTextEmitter)="contactCreateFromText($event, 'buyers', 'customer')"
        ></app-combined-entity-picker>
    </div>

    <!------- Other ------->
    <div class="col-lg-6">
        <app-combined-entity-picker
            [title]="'Other'"
            [availableTypes]="['contact_person']"
            [initialContactProfileIds]="selectedOthersIds"
            [nodesFA]="selectedOthersForm"
            [displayMode]="'double-line'"
            [positionMode]="'bottom'"
            [dealParticipantsWithSubType]="dealParticipants?.others?.slice() || []"
            [disableUpdate]="disableOthers || dealFormGroup.controls.deal_participants?.disabled"
            [disabled]="dealFormGroup.controls.deal_participants?.disabled"
            [textParticipants]="textParticipantsOthers"
            (contactCreateEmitter)="contactCreated($event, 'others')"
            (isLoadedEmitter)="disableOthers = false"
            (toggleVisibilityEmitter)="toggleVisibility($event, 'others')"
            (contactCreateFromTextEmitter)="contactCreateFromText($event, 'others', null)"
        ></app-combined-entity-picker>
    </div>
</div>
