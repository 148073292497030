import {debounceTime, filter, takeUntil} from 'rxjs/operators';
import {
    Component,
    OnInit,
    ViewChild,
    Input,
    OnDestroy,
    ViewChildren,
    QueryList,
    ElementRef,
    AfterViewChecked
} from '@angular/core';
import {Profile} from 'app/models/profile';
import {Group} from 'app/models/group';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {GenericFormArray, GenericFormGroup} from 'app/entites/generic.entity';
import {AuthService} from 'app/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs';
import {GroupsSource} from 'app/services/sources/groups.source';
import {GroupApi} from 'app/services/api/group.api';
import {AppInitSource} from 'app/services/sources/app-init.source';
import {GroupMemberEntity} from 'app/models/group-member.model';
import {MatSidenav} from '@angular/material/sidenav';
import {ChipNode} from '../../../compensation/models/chip-node';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {IChipNode} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-group-edit-sidenav',
    styleUrls: ['./edit.component.scss'],
    templateUrl: 'edit.component.html'
})
export class EditComponent implements OnInit, OnDestroy, AfterViewChecked {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('sidenavGroupMembers', {static: true}) sidenavGroupMembers: MatSidenav | undefined;
    @ViewChild('header', {static: true}) header: ElementRef | undefined;
    @ViewChildren('divCheckbox') divCheckboxes: QueryList<ElementRef> | undefined;
    @ViewChildren('checkbox') checkboxes: QueryList<MatCheckbox> | undefined;

    @Input() isOpened: boolean = false;

    @Input() groupId: number | null = null;

    public groupFG: GenericFormGroup<Group> = new GenericFormGroup<Group>(new Group());

    membersFormArray: GroupMemberEntity[] = [];

    membersToDelete: number[] = [];

    public emails: string = '';

    public isLoaded: Promise<any>;
    public isDisabled: boolean = false;
    public currentProfile: Profile | null = null;
    private selectedGroup: Group | null = null;
    editDisabled: boolean = false;

    protected groupsList: Group[] = [];

    offsetTopToLazyLoading$: BehaviorSubject<number> | undefined;
    showMore: boolean = true;
    scrollData: {limit: number; offset: number; id: number | null} = {
        limit: 30,
        offset: 0,
        id: null
    };

    selectedProfilesFA: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    selectedProfileIds: number[] = [];
    manageMembers: boolean = false;
    headerOffset: number | null = null;

    saveGroup() {
        this.isDisabled = true;
        const company_group: Group = this.groupFG.getRawValue();
        company_group.enumerable = company_group.enumerable.filter(
            (item) => item.period || item.amount || item.title || item.units
        );
        const members: GroupMemberEntity[] = this.membersFormArray;
        company_group.members = members;
        company_group.number_of_members = members.length;

        if (company_group && company_group.id) {
            this.groupService.updateMembers(company_group.id, company_group, this.membersToDelete).then(() => {
                if (this.currentProfile) {
                    this.groupSrc.load(this.currentProfile);
                }
                this.close();
                this.isDisabled = false;
            });
        } else {
            this.isDisabled = false;
        }
    }

    constructor(
        protected currentProfileSource: CurrentProfileSource,
        public authService: AuthService,
        protected router: Router,
        private route: ActivatedRoute,
        private groupSrc: GroupsSource,
        private groupService: GroupApi,
        protected appInitSource: AppInitSource
    ) {
        this.isLoaded = new Promise((resolve) => {
            this.currentProfileSource.changeProfileEvent
                .pipe(
                    filter((profile) => profile.company !== null),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((profile) => {
                    this.currentProfile = profile;
                    resolve(null);
                });
        }).then(() => {
            this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((groups) => {
                this.groupsList = groups.filter((group) => group.type !== Group.type_SET.division);
            });
        });
    }

    ngOnInit() {
        this.groupFG.controls.members!.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.groupFG.controls.changed!.patchValue(true);
        });

        this.selectedProfilesFA.valueChanges
            .pipe(debounceTime(100), takeUntil(this.unsubscribe))
            .subscribe((chipNodes: IChipNode[]) => {
                if (chipNodes.length) {
                    const selectedProfile = chipNodes[chipNodes.length - 1];
                    this.addProfileToGroup({
                        profile_id: selectedProfile.target_id as number,
                        id: selectedProfile.target_id as number,
                        email: selectedProfile.email,
                        first_name: selectedProfile.label?.split(' ')[0],
                        last_name: selectedProfile.label?.split(' ')[1]
                    });
                }

                this.selectedProfileIds = chipNodes.map((p) => p.target_id as number);
            });
    }

    ngAfterViewChecked() {
        if (
            this.headerOffset &&
            typeof this.header !== 'undefined' &&
            this.headerOffset !== this.header.nativeElement.offsetHeight
        ) {
            this.headerOffset = this.header.nativeElement.offsetHeight;
            if (this.offsetTopToLazyLoading$) {
                this.offsetTopToLazyLoading$.next(this.header.nativeElement.offsetTop + 100);
            } else {
                this.offsetTopToLazyLoading$ = new BehaviorSubject<number>(this.header.nativeElement.offsetTop + 100);
            }
        }
    }

    openGroup(id: number) {
        if (typeof this.sidenavGroupMembers !== 'undefined') {
            this.sidenavGroupMembers.open();
        }

        this.scrollData.offset = 0;
        this.showMore = true;
        this.groupId = id;
        this.selectedGroup = this.groupsList.find((group) => group.id === this.groupId) || null;
        if (this.selectedGroup) {
            this.groupFG.patchValue(this.selectedGroup);
        }
        if (
            this.groupFG.controls.id &&
            this.groupFG.controls.id.value !== this.currentProfile?.company?.company_group?.id
        ) {
            this.editDisabled = true;
        }
        this.membersToDelete = []; // set initial state

        this.nextBatch();

        this.headerOffset = this.header?.nativeElement?.offsetHeight || null;
        if (this.offsetTopToLazyLoading$) {
            this.offsetTopToLazyLoading$.next(115);
        } else {
            this.offsetTopToLazyLoading$ = new BehaviorSubject<number>(100);
        }
    }

    close() {
        this.selectedGroup = null;
        this.groupId = null;
        this.scrollData.id = null;

        this.membersFormArray = [];
        this.selectedProfileIds = [];
        this.manageMembers = false;
        this.headerOffset = null;
        this.selectedProfilesFA.clear();
        this.membersToDelete = [];

        if (typeof this.sidenavGroupMembers !== 'undefined') {
            this.sidenavGroupMembers.close();
        }
        this.editDisabled = false;
    }

    removeFromList(member: GroupMemberEntity, memberIndex: number) {
        this.membersFormArray.splice(memberIndex, 1);
        this.membersFormArray = [...this.membersFormArray];

        this.selectedProfileIds.splice(memberIndex, 1);

        const indexToDeleteFromForm = this.selectedProfilesFA
            .getRawValue()
            .findIndex((p) => member.profile && p.target_id === member.profile.id);
        this.selectedProfilesFA.removeAt(indexToDeleteFromForm);
    }

    /*removeMember(mi: number, member: GroupMemberEntity) {
        const removingIndex = this.membersFormArray.findIndex(m => m.id === member.id);
        if (removingIndex !== -1) {
            this.membersFormArray.splice(removingIndex, 1);
            this.membersFormArray = [...this.membersFormArray];
        }

        const memberId = member.id;
        if (memberId) {
            this.membersToDelete.push(memberId);
        }
    }*/

    addProfileToGroup(profile: {profile_id: number; id: number; email: string; first_name: string; last_name: string}) {
        const foundMember = this.membersFormArray.find((member) => member.profile && member.profile.id === profile.id);
        if (!foundMember) {
            this.membersFormArray = [
                new GroupMemberEntity()
                    .setProfile(Object.assign(new Profile(), profile))
                    .setRole(GroupMemberEntity.role_SET.default)
                    .setStatus(GroupMemberEntity.status_SET.active)
                    .setEmail(profile.email),
                ...this.membersFormArray
            ];
        }
    }

    nextBatch() {
        if (!this.showMore || !this.groupId) {
            return;
        }

        this.showMore = false;
        if (this.scrollData.id !== null) {
            this.scrollData.offset += this.scrollData.limit;
        }
        this.scrollData.id = Number(this.groupId);

        this.groupService.getMemberPageForGroup(this.scrollData).then((groupMembers) => {
            if (this.scrollData.offset === 0) {
                this.membersFormArray = groupMembers;
            } else {
                this.membersFormArray = [...this.membersFormArray, ...groupMembers];
            }

            this.showMore = groupMembers.length >= this.scrollData.limit;
        });
    }

    toggleDealCheckbox(control: MatCheckboxChange) {
        const memberId = Number(control.source.value);
        if (this.manageMembers) {
            this.manageMembers = false;
        }

        if (control.checked) {
            this.membersToDelete.push(memberId);
        } else {
            this.membersToDelete.splice(this.membersToDelete.indexOf(memberId), 1);
        }
    }

    removeMembersBeforeSave() {
        this.membersFormArray = [...this.membersFormArray.filter((m) => m.id)];
        this.saveGroup();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        if (this.offsetTopToLazyLoading$) {
            this.offsetTopToLazyLoading$.complete();
        }
    }
}
