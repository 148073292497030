import {filter, takeUntil} from 'rxjs/operators';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Group} from '../../../../models/group';
import {Profile} from '../../../../models/profile';
import {CurrentProfileSource} from '../../../../services/sources/current-profile.source';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {ShareDialogComponent} from './share-dialog.component';
import {GroupApi} from '../../../../services/api/group.api';
import {GroupsSource} from '../../../../services/sources/groups.source';
import {NotificationsServiceZipi} from '../../../notifications/notifications.service';
import {SettingsDialogComponent} from './settings-dialog.component';
import {Subject} from 'rxjs';
import {EventsService} from '../../../../services/events.service';
import {AuthService} from '../../../../services/auth.service';
import {AppInitSource} from '../../../../services/sources/app-init.source';

@Component({
    selector: 'app-groups-list-item-all',
    styles: [
        `
            .form {
                flex: 1;
                padding: 20px;
            }

            .visibility-hidden {
                visibility: hidden;
            }

            .h-font {
                font-size: 25px;
                font-weight: 400;
                margin-right: 30px;
                margin-bottom: 6px;
            }
            .user-card {
                margin-bottom: 3px;
                padding: 16px 0;
                height: 64px;

                max-width: 900px;
                margin-right: auto;
                margin-left: auto;
                border-bottom: 1px #e8e8e8 solid;
            }
            .user-row {
                cursor: pointer;
            }
            .user-row:hover .user-card {
                box-shadow: 0 0 5px #ccc;
            }
        `
    ],
    templateUrl: './all-list-item.component.html'
})
export class GroupsListItemAllComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    protected groupsList: Group[] = [];
    public disableDeleting: boolean = false;

    // @ViewChild('panel', { static: true }) panel: MatExpansionPanel;
    public GROUP = Group;
    @Input() groupFG: GenericFormGroup<Group> = new GenericFormGroup(new Group());
    isOpened: boolean = false;
    public currentProfile: Profile | null = null;

    public isLoaded: Promise<any>;

    groupsSeachSubject: Subject<any> = new Subject();

    constructor(
        private groupService: GroupApi,
        public dialog: MatDialog,
        protected currentProfileSource: CurrentProfileSource,
        private notificationsService: NotificationsServiceZipi,
        private eventsService: EventsService,
        private groupSrc: GroupsSource,
        public authService: AuthService,
        protected appInitSource: AppInitSource
    ) {
        this.isLoaded = new Promise((resolve) => {
            this.currentProfileSource.changeProfileEvent
                .pipe(
                    filter((profile) => profile.company !== null),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((profile) => {
                    this.currentProfile = profile;
                    resolve(null);
                });
        });

        this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
            this.groupsList = list;
            this.disableDeleting = this.groupsList.some((group) => {
                return group.parent_group && group.parent_group.id === this.groupFG.get('id')?.value;
            });
        });
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }

    groupSettings(event: Event, groupFG: GenericFormGroup<Group>) {
        event.stopPropagation();
        const dialogRef = this.dialog.open(SettingsDialogComponent);
        dialogRef.componentInstance.groupFG.patchValue(groupFG.getRawValue());

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                if (!res) {
                    // dialog was just closed, no need to save anything
                    return;
                }
                res.isGroupSettingsUpdate = true;
                groupFG.patchValue(res);
                this.groupService.updateSettings(res.id, res).then(() => {
                    if (this.currentProfile) {
                        this.groupSrc.load(this.currentProfile);
                    }
                });
            });
    }

    sharePermissions(event: Event, company_group: Group) {
        event.stopPropagation();
        const dialogRef = this.dialog.open(ShareDialogComponent);
        dialogRef.componentInstance.sharePermissions = Object.assign({}, company_group.permissions);
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((permissions) => {
                if (!permissions) {
                    // dialog was just closed, no need to save anything
                    return;
                }
                company_group.permissions = permissions;
                company_group.isGroupSettingsUpdate = true;
                this.groupService.updateSettings(company_group.id as number, company_group);
            });
    }

    deleteActiveGroup(event: Event) {
        event.stopPropagation();
        this.notificationsService
            .addConfirm('Are you sure that you want to delete the group?')!
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.groupService.deleteGroup(this.groupFG.controls.id?.value).then(() => {
                        if (this.currentProfile) {
                            this.groupSrc.load(this.currentProfile);
                        }
                    });
                }
            });
    }

    ngOnInit() {
        this.isLoaded.then(() => {
            if (
                this.groupFG.controls.id &&
                this.groupFG.controls.id.value === this.currentProfile?.company?.company_group?.id
            ) {
                const currentGroup = this.groupFG.getRawValue();
                currentGroup.isPersonalGroup = true;
                this.groupFG.patchValue(currentGroup);
            }
        });

        this.groupsSeachSubject = this.eventsService.getGroupEventObject();
        this.groupsSeachSubject.pipe(takeUntil(this.unsubscribe)).subscribe((ids) => {
            if (ids && ids.length === 1) {
                if (Number(ids[0]) === this.groupFG.getRawValue().id) {
                    // this.panel.open();
                }
            }
        });

        this.eventsService.initCurrentGroupEventValue();
    }

    ngOnDestroy() {
        this.eventsService.updateGroupEventValue([]);
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.groupsSeachSubject.complete();
    }
}
