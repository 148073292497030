import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {from as observableFrom, Observable, of, throwError, firstValueFrom} from 'rxjs';
import {switchMap, catchError, tap, retryWhen, delay, mergeMap, map} from 'rxjs/operators';
import {environment} from 'environments/environment';
import {NotificationsServiceZipi} from '../modules/notifications/notifications.service';
import {PendingRequestsService} from './pending-requests.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {Profile} from '../models/profile';
import {CurrentProfileSource} from './sources/current-profile.source';
import * as Sentry from '@sentry/angular-ivy';
import {SessionService} from './session.service';

type RestVerb = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

const win: {[key: string]: any} = window;

const IGNORE_SENTRY_STATUS_LIST = [400, 401, 404];

@Injectable()
export class ServiceRequester {
    url = environment.services.pwCore.url + '/v1';
    shippUrl = environment.services.shippCore.url + '/v1';

    loggedAsUserUid: string = '';

    protected authToken: string | null = null;

    protected globalPrefix: string = '';

    refreshTimestamp: number | null = null;

    private retrySettings = {
        retryLimit: 5,
        retryDelay: 1000,
        retryErrorCodes: [0, 500, 502],
        retryMethods: ['GET'],
        isRetryEnabled: false
    };

    constructor(
        protected httpClient: HttpClient,
        protected router: Router,
        protected route: ActivatedRoute,
        protected notificationsService: NotificationsServiceZipi,
        public afAuth: AngularFireAuth,
        // public authService: AuthService,
        protected pendingRequestsService: PendingRequestsService,
        protected currentProfileSource: CurrentProfileSource,
        private sessionService: SessionService
    ) {
        this.currentProfileSource.changeProfileEvent.subscribe((profile) => {
            if (profile.type === Profile.type.global) {
                this.globalPrefix = '/global';
            } else {
                this.globalPrefix = '';
            }
        });
    }

    /**
     * todo refactor this method to get firebaseUser and token from sessionService
     *
     * @return {Promise<string>}
     */
    getAuthToken(): Promise<string | null> {
        return new Promise((resolve, reject) => {
            return this.afAuth.currentUser
                .then((user) => {
                    if (!user) {
                        return null;
                    } else {
                        return user.getIdToken();
                    }
                })
                .then((token) => {
                    this.authToken = token;

                    return resolve(this.authToken);
                });
        });
    }

    public makeMsCallGlobalAllowed(
        action: string,
        method: RestVerb = 'GET',
        data: {} = {},
        customHeadersObj = {}
    ): Promise<any> {
        return this.makeMsCall(this.globalPrefix + action, method, data, customHeadersObj);
    }

    /** @deprecated - Use makeMsCallPromise instead */
    public async makeMsCall<T = any>(
        action: string,
        method: RestVerb = 'GET',
        data?: Record<string, any>,
        customHeadersObj?: Record<string, string>,
        hideProgressBar?: boolean
    ): Promise<T> {
        return this.makeMsCallPromise<{result: T}>({url: action, method, data, customHeadersObj, hideProgressBar}).then(
            (res) => res.result
        );
    }

    public async makeMsCallPromise<T = any>(args: {
        method: RestVerb;
        url: string;
        data?: Record<string, any>;
        backend?: 'core' | 'shipp';
        customHeadersObj?: Record<string, string>;
        hideProgressBar?: boolean;
    }): Promise<T> {
        const {method, url, backend = 'core', data = {}, customHeadersObj = {}, hideProgressBar = false} = args;
        const mappedBackend = backend === 'core' ? 'zipi' : 'shipp';

        const observable = this.makeMsCall$(url, method, mappedBackend, data, customHeadersObj, hideProgressBar);
        const promise = firstValueFrom(observable);
        return await promise;
    }

    /** @deprecated - Use makeMsCallPromise instead */
    public makeMsCall$<T = any>(
        action: string,
        method: RestVerb = 'GET',
        backend: string = 'zipi',
        data: {} = {},
        customHeadersObj = {},
        hideProgressBar = false
    ): Observable<T> {
        let body: any;

        const currentUrl = backend === 'zipi' ? this.url : this.shippUrl;

        return observableFrom(this.prepareRequestHeaders(customHeadersObj)).pipe(
            switchMap((headers) => {
                if (['POST', 'PUT', 'PATCH'].indexOf(method) !== -1) {
                    body = JSON.stringify(data);
                }

                const requestOptions = {
                    headers,
                    body: body ? body : null,
                    withCredentials: true,
                    observe: 'response' as 'response'
                };

                if (method === 'GET' || (method === 'DELETE' && data)) {
                    const params = new HttpParams({fromObject: data});
                    Object.assign(requestOptions, {params});
                }
                if (!hideProgressBar) {
                    this.pendingRequestsService.hasPendingRequest = true;
                }
                return this.httpClient
                    .request(method, currentUrl + action, requestOptions)
                    .pipe(this.delayedRetry(method));
            }),
            map((response: any) => {
                const refreshToken = response.headers.get('token-for-refresh');
                if (refreshToken) {
                    this.refreshSession(refreshToken);
                }

                return response.body;
            }),
            tap((resp) => {
                this.pendingRequestsService.hasPendingRequest = false;
                return resp;
            }),
            // eslint-disable-next-line rxjs/no-implicit-any-catch
            catchError((response: any) => {
                const responseBody = response.body || response;
                const doNotNeedToRunSentry =
                    responseBody &&
                    typeof responseBody === 'object' &&
                    responseBody.status &&
                    IGNORE_SENTRY_STATUS_LIST.includes(responseBody.status);
                if (!doNotNeedToRunSentry) {
                    Sentry.captureException(responseBody);
                }
                return this.handleError(responseBody);
            })
        ) as Observable<T>;
    }

    public makeMsShippCall$(
        action: string,
        method: string = 'GET',
        data: {} = {},
        customHeadersObj = {},
        hideProgressBar = false
    ): Observable<any> {
        let body: any;

        return observableFrom(this.prepareRequestHeaders(customHeadersObj)).pipe(
            switchMap((headers) => {
                if (['POST', 'PUT', 'PATCH'].indexOf(method) !== -1) {
                    body = JSON.stringify(data);
                }

                const requestOptions = {
                    headers,
                    body: body ? body : null,
                    withCredentials: true,
                    observe: 'response' as 'response'
                };

                if (method === 'GET' || (method === 'DELETE' && data)) {
                    const params = new HttpParams({fromObject: data});
                    Object.assign(requestOptions, {params});
                }
                if (!hideProgressBar) {
                    this.pendingRequestsService.hasPendingRequest = true;
                }
                return this.httpClient
                    .request(method, this.shippUrl + action, requestOptions)
                    .pipe(this.delayedRetry(method));
            }),
            tap((resp) => {
                this.pendingRequestsService.hasPendingRequest = false;
                return resp;
            }),
            // eslint-disable-next-line rxjs/no-implicit-any-catch
            catchError((response: any) => {
                const responseBody = response.body || response;
                const doNotNeedToRunSentry =
                    responseBody &&
                    typeof responseBody === 'object' &&
                    responseBody.status &&
                    IGNORE_SENTRY_STATUS_LIST.includes(responseBody.status);
                if (!doNotNeedToRunSentry) {
                    Sentry.captureException(responseBody);
                }
                return this.handleError(responseBody);
            })
        );
    }

    submitFormData$(action: string, method: string, backend: string = 'zipi', data: FormData): Observable<any> {
        const currentUrl = backend === 'zipi' ? this.url : this.shippUrl;

        return observableFrom(this.prepareRequestHeaders([])).pipe(
            switchMap((headers) => {
                // remove not needed header for this request
                headers = headers.delete('Content-Type');

                const requestOptions = {
                    method,
                    headers,
                    withCredentials: true,
                    body: data
                };

                return this.httpClient.request(method, currentUrl + action, requestOptions);
            }),
            catchError((response: unknown) => {
                Sentry.captureException(response);
                return this.handleError(response);
            })
        );
    }

    private async prepareRequestHeaders(customHeadersObj: {[key: string]: any}): Promise<HttpHeaders> {
        // const authToken = await this.getAuthToken();

        const headersObj: {[key: string]: string} = {
            'Content-Type': 'application/json'
        };

        // if (authToken) {
        //     headersObj['Authorization'] = 'Bearer ' + authToken;
        // }

        if (this.sessionService.profile && this.sessionService.profile.id) {
            const currentProfileId = localStorage.getItem('current_profile_id');
            if (currentProfileId && Number(currentProfileId) === this.sessionService.profile.id) {
                headersObj['zipi-current-profile-id'] = currentProfileId;
            } else {
                win.location.replace('/default-page');
            }
        } else {
            const currentProfileId = localStorage.getItem('current_profile_id');
            if (currentProfileId) {
                headersObj['zipi-current-profile-id'] = currentProfileId;
            }
        }

        const currentDivisionId = localStorage.getItem('current_company_group_id');
        if (currentDivisionId) {
            headersObj['zipi-current-division-id'] = currentDivisionId;
        }

        const desiredCompanyId = localStorage.getItem('desired_company_id');
        if (desiredCompanyId) {
            headersObj['zipi-desired-company-id'] = desiredCompanyId;
            localStorage.removeItem('desired_company_id');
        }

        for (const customHeader in customHeadersObj) {
            if (customHeadersObj.hasOwnProperty(customHeader)) {
                headersObj[customHeader] = customHeadersObj[customHeader];
            }
        }

        const pwLoggedAsUid = localStorage.getItem('pw_logged_as_uid');
        if (pwLoggedAsUid) {
            headersObj['zipi-logged-as-uid'] = pwLoggedAsUid.toString();
        }

        return new HttpHeaders(headersObj);
    }

    protected async processKnownErrors(errorCode: string, message: string = '') {
        switch (errorCode) {
            case 'subscription-inactive':
                return this.router.navigate(['/subscription-inactive']);
            case 'global-view-error':
                localStorage.setItem('user_route', window.location.href);
                return this.router.navigate(['/select-profile']);
            case 'fail-allocate-zipi-deal':
                this.notificationsService.addAdvancedError({
                    message: message,
                    objectLink: {
                        link: '/company/settings/purchase-settings',
                        text: 'Company > Settings > Purchases'
                    }
                });
                return;
            case 'fail-payment-method-verification-during-allocate-deal':
                this.notificationsService.addAdvancedError({
                    message: message,
                    objectLink: {
                        link: '/marketplace/setup/moneytransfers',
                        text: 'Money Transfers (EFT)'
                    }
                });
                return;
            case 'no-auth-identification':
                await this.afAuth.signOut();
                window.location.replace(environment.loginPageURL);
        }
    }

    protected handleError(response: any): any {
        let message = response.statusText;
        let errorCode;
        this.pendingRequestsService.hasPendingRequest = false;

        // Process 503 Service Unavailable error. Our backend throw such error in MAINTENANCE MODE.
        if (response.status === 503) {
            return this.afAuth.signOut().then(() => {
                window.location.replace(environment.loginPageURL + '?system_mode=maintenance');

                return Promise.reject('503_Error');
            });
        }

        if (response['error']) {
            const jsonBody = response['error'];

            if (jsonBody.result) {
                errorCode = jsonBody.result.code;
            }

            if (jsonBody.message) {
                message = jsonBody.message;
                if (jsonBody.code) {
                    errorCode = jsonBody.code;
                }
            } else {
                message =
                    'Please retry action or contact support. (Error while parsing response from Server. Service unavailable.)';
            }
        }

        if (errorCode) {
            return this.processKnownErrors(errorCode, message);
        }

        if (response['retryText']) {
            message += response['retryText'];
        }

        this.notificationsService.addError(message);

        return Promise.reject(message);
    }
    async refreshSession(token: string) {
        const currentTimestamp = new Date().getTime();
        if (!this.refreshTimestamp || currentTimestamp - this.refreshTimestamp > 5 * 60 * 1000) {
            this.refreshTimestamp = currentTimestamp;
            const firebaseUserInfo = await this.afAuth.signInWithCustomToken(token);
            if (firebaseUserInfo.user) {
                const idToken = await firebaseUserInfo.user.getIdToken();
                this.makeMsCall(`/public/session/session-login`, 'POST', {id_token: idToken});
                firstValueFrom(this.makeMsCall$(`/public/session/session-login`, 'POST', 'shipp', {id_token: idToken}));
            }
        }
    }

    protected delayedRetry(method: string) {
        let leftRetries = this.retrySettings.retryLimit;

        return (response: Observable<any>) => {
            return response.pipe(
                retryWhen((errors: Observable<any>) => {
                    return errors.pipe(
                        delay(this.retrySettings.retryDelay),
                        mergeMap((error) => {
                            if (
                                this.retrySettings.isRetryEnabled &&
                                this.retrySettings.retryErrorCodes.includes(error.status) &&
                                this.retrySettings.retryMethods.includes(method)
                            ) {
                                if (leftRetries-- > 0) {
                                    return of(error);
                                } else {
                                    // error.statusText += ` (after ${this.retrySettings.retryLimit} retries)`;
                                    error['retryText'] = ` (after ${this.retrySettings.retryLimit} retries)`;
                                    return throwError(error);
                                }
                            } else {
                                return throwError(error);
                            }
                        })
                    );
                })
            );
        };
    }
}
