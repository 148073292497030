export class UiModsModel {
    dealBadgesInList: UiModsDealBadgeInListModel[] | undefined;
    dealBadgesOnPage: UiModsDealBadgeOnPageModel[] | undefined;
    createDealMenuOptions: UiModsMenuOptionModel[] | undefined;
    dealCommissionCategorizationNote: UiModsDealCommissionCategorizationNoteModel[] | undefined;
}

export class UiModModel {
    addon: any;
    id: number | null = null;
    preset:
        | UiModsDealsButtonModel
        | UiModsDealsBoardsButtonModel
        | UiModsDealBadgeInListModel
        | UiModsDealBadgeOnPageModel
        | null = null;
    status: string | null = null;
    type: string | null = null;
}

export class UiModMenuOptionModel {
    addon: any;
    id: number | null = null;
    preset: UiModsMenuOptionModel | null = null;
    status: string | null = null;
    type: string | null = null;
}

export class UiModsDealsBoardsButtonModel {
    id: number | null = null;
    clickable: boolean | null = null;
    on_click: any;
    uiMod: UiModModel | null = null;
    style: object | null = null;

    title: string | null = null;
    hint?: string;
}
export class UiModsDealsButtonModel {
    id: number | null = null;
    clickable: boolean | null = null;
    uiMod: UiModModel | null = null;
    on_click: any;
    style: object | null = null;

    title: string | null = null;
    hint?: string;
}

export class UiModsDealBadgeInListModel {
    id: number | null = null;
    ui_mod_instance_id: number | null = null;
    clickable: boolean | null = null;
    uiMod: UiModModel | null = null;
    ui_mod_cache: string | null = null;
    on_click: any;
    style: object | null = null;

    deal_id: number | null = null;
    hint: string | null = null;
    icon: string | null = null;
    state: string | null = null;

    disabled?: boolean;
    label?: string;
    created_by__profile_fk_id?: string;
    showSpinner?: boolean;
    isPermissionChecked?: boolean;
}

export class UiModsDealCommissionCategorizationNoteModel {
    id: number | null = null;
    clickable: boolean | null = null;
    uiMod: UiModModel | null = null;
    on_click: any;
    style: object | null = null;

    deal_id: number | null = null;
    hint: string | null = null;
    icon: string | null = null;

    disabled?: boolean;
    label?: string;
    created_by__profile_fk_id?: string;
    hidden: boolean | null = null;
}

export class UiModsDealBadgeOnPageModel {
    id: number | null = null;
    clickable: boolean | null = null;
    uiMod: UiModModel | null = null;
    on_click: any;
    style: object | null = null;

    deal_id: number | null = null;
    hint: string | null = null;
    icon: string | null = null;
}

export class UiModsMenuOptionModel {
    id: number | null = null;
    uiMod: UiModMenuOptionModel | null = null;
    on_click: any;
    style: object | null = null;

    option: any;
}

export enum UI_MOD_STATE {
    normal = 'normal',
    error = 'error',
    warning = 'warning'
}
