import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-addon-profile-creation-dialog',
    styles: [
        `
            .item-box {
                margin: 10px 0 0 0;
            }
            .items-wrap {
                min-height: 40px;
                line-height: 18px;
            }
        `
    ],
    template: `
        <div>
            <h2 md-dialog-title>{{ data.addonTitle }}</h2>
            <div md-dialog-content class="items-wrap pt-2">
                <!--<p>This app will create a special profile in your company with email <i>{{data.systemProfileEmail}}</i>.</p>-->
                <p style="line-height: 22px;">
                    Please note, this App requires SkySlope Books to create a unique Company profile. This profile will
                    grant the following permissions: manage any deals, view trade sheet, manage disbursement documents,
                    manage division assignment, manage source of business, manage custom fields, manage disbursement
                    cards, manage payouts, manage deal communications, view deal activity, manage deal override,
                    view/create contacts, view/create profiles, and view products.
                </p>
                <p>Please click "Continue" to agree and proceed.</p>
            </div>
            <div md-dialog-actions>
                <div class="w-100 mt-4" style="text-align: right;">
                    <button
                        type="button"
                        mat-raised-button
                        style="margin-right: 10px;"
                        tabindex="-1"
                        (click)="dialogRef.close(false)"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        mat-raised-button
                        tabindex="-1"
                        color="primary"
                        (click)="dialogRef.close(true)"
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>
    `
})
export class ConfirmAddonProfileCreationDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmAddonProfileCreationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {addonTitle: string; systemProfileEmail: string}
    ) {}
}
