<section class="bg-white">
    <!------- Filters ------->
    <header class="d-flex align-items-center px-3 list-header">
        <h3 class="m-0 mr-1 d-flex align-items-end">Invoices</h3>

        <button mat-stroked-button class="ml-3" [matMenuTriggerFor]="menu" color="warn" *ngIf="isSelectedForBulk">
            Bulk Action
        </button>
        <mat-menu #menu="matMenu">
            <button
                mat-menu-item
                (click)="bulkDelete()"
                [rule]="{sales__manage_invoices: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
            >
                Delete
            </button>
        </mat-menu>

        <i class="sp-spacer"></i>

        <app-date-range-picker
            class="mr-2 mt-2"
            [floatLabel]="'never'"
            (click)="$event.stopPropagation()"
            (dateEmitter)="setRange($event)"
            [maxDate]="range.controls.end_date.value"
            [minDate]="range.controls.start_date.value"
        ></app-date-range-picker>

        <app-company-compensation-combined-picker
            class="mr-2 mt-2"
            style="width: 180px"
            [availableTypes]="['contact']"
            [floatLabel]="'never'"
            [title]="'Customer Name'"
            [only_compensation_groups]="false"
            [singleSelected]="true"
            [bold_style]="false"
            [nodesFA]="moneySenderCtrlArr"
            [initialContactIds]="contactIds"
        >
        </app-company-compensation-combined-picker>

        <mat-form-field class="mt-2" [floatLabel]="'never'">
            <mat-label>Filter</mat-label>
            <mat-select [formControl]="statusFilter" multiple>
                <mat-optgroup [label]="'Invoice Status'">
                    <mat-option
                        *ngFor="let status of getInvoiceStatuses()"
                        [value]="status"
                        (onSelectionChange)="changeStatusFilter($event)"
                    >
                        {{ getInvoiceStatusValue(status) | fromSnakeCase }}
                    </mat-option>
                </mat-optgroup>
                <mat-optgroup [label]="'Payment Status'">
                    <mat-option
                        *ngFor="let status of getPaymentStatuses()"
                        [value]="status"
                        (onSelectionChange)="changeStatusFilter($event)"
                    >
                        {{ getPaymentStatusValue(status) | fromSnakeCase }}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>

        <button
            class="ml-2"
            type="button"
            mat-raised-button
            color="primary"
            routerLink="/sales/invoices/create"
            [rule]="{sales__manage_invoices: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
        >
            <mat-icon>add</mat-icon>
            Create
        </button>
    </header>

    <!------- Table of invoices ------->
    <div class="table-background">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef class="w-5"></th>
                <td mat-cell *matCellDef="let element" class="w-5" (click)="$event.stopPropagation()">
                    <mat-checkbox
                        (change)="toggleBulkCheckbox($event, element.invoice_id)"
                        [value]="element.invoice_id"
                        [checked]="selection.isSelected(element.invoice_id)"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="invoice_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Invoice Date</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.invoice_date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="invoice_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Invoice#</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.invoice_number }}
                </td>
            </ng-container>

            <ng-container matColumnDef="customer">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Customer</th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    class="w-10 px-1"
                    [ngClass]="element.contactIsHidden ? 'hidden-contact' : ''"
                >
                    {{ element.customer }}
                </td>
            </ng-container>

            <ng-container matColumnDef="connected_bill_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Reference</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.connected_bill_number || 'N/A' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="summary_status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Status</th>
                <td mat-cell class="w-10 px-1" *matCellDef="let element">
                    <ng-container *ngIf="element.summary_status">
                        <span [ngStyle]="{color: statusColor[element.summary_status!]}">
                            {{ element.summary_status | fromSnakeCase }}
                        </span>
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="due_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Due Date</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.due_date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status_of_payment">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Payments Status</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.status_of_payment | fromSnakeCase }}
                </td>
            </ng-container>

            <ng-container matColumnDef="total_amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 table-header-align-right">
                    Total Amount
                </th>
                <td mat-cell *matCellDef="let element" class="w-10 pr-3 text-right">
                    {{ element.total_amount | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="balance">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 table-header-align-right">Balance</th>
                <td mat-cell *matCellDef="let element" class="w-10 pr-3 text-right">
                    {{ element.balance | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="w-5 text-right">Action</th>
                <td mat-cell *matCellDef="let element" class="w-5 text-right">
                    <button
                        mat-icon-button
                        [rule]="{sales__manage_invoices: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        [matMenuTriggerFor]="menu"
                        (click)="$event.stopPropagation()"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button
                            mat-menu-item
                            [rule]="{sales__manage_invoices: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            [disabled]="
                                ['write_off', 'void', 'rejected'].includes(element.summary_status) ||
                                (element.creator__company_fk_id !== currentCompanyId &&
                                    element.owner__company_fk_id !== currentCompanyId)
                            "
                            (click)="$event.stopPropagation(); editInvoice(element)"
                        >
                            Edit
                        </button>
                        <button
                            mat-menu-item
                            [rule]="{sales__manage_invoices: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            [disabled]="
                                !['draft', 'open', 'sent', 'overdue'].includes(element.summary_status) ||
                                !element.connected_bill_number
                            "
                            (click)="$event.stopPropagation(); disconnectInvoice(element)"
                        >
                            Disconnect
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="row.id" class="zp-table-row"></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 text-center no-items">
            There are no invoices.
        </div>
    </div>
</section>
