import {Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {timePeriodObj, listOfYesNoAnswer, listOfReportBasis} from '../../../../constants';
import {getNextActivePanel, isLastPanelItem} from '../../helper';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {LedgerAccountService} from '../../../../../../services/api/finance/ledger-accounts.service';
import {ILedgerAccount, ITag} from '@cyberco-nodejs/zipi-typings/index';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {IReport, IReportSetting, IReportSettingKeys} from '@cyberco-nodejs/zipi-typings';
import {TagsService} from '../../../../../../services/api/tags.service';

@Component({
    selector: 'app-report-edit-bar-trust-reconciliation',
    templateUrl: './trust-reconciliation.component.html',
    styleUrls: ['./trust-reconciliation.component.scss']
})
export class TrustReconciliationBarComponent implements OnInit, OnDestroy {
    @Output() valueChanges: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Output() action: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Input() report: IReport | null = null;

    private unsubscribe: Subject<void> = new Subject();
    private defaultValues: {[key: string]: any} = {
        time_period: timePeriodObj.this_year.value,
        is_include_zero_balance: true,
        is_display_individual_balance: true
    };

    public isDisabledStatementBankDate: boolean = false;
    public activePanel: string = 'time_period';
    public dateValueType: 'value_as_date' | 'value_as_int' = 'value_as_int';
    public allTags: ITag[] = [];
    public listOfLedgerAccounts: ILedgerAccount[] = [];
    public listOfYesNoAnswer: Array<{title: string; value: boolean}> = listOfYesNoAnswer;
    public listOfReportBasis: Array<{title: string; value: string}> = listOfReportBasis;
    public reportSettings: IReportSetting | null = null;
    public isShowNext: boolean = true;
    public advancedFormGroup: UntypedFormGroup | null = null;
    public reportOptions = [
        {
            value: 'time_period',
            title: 'Time Period'
        },
        {
            value: 'report_tagging',
            title: 'Report Tagging'
        },
        {
            value: 'ledger_accounts',
            title: 'Ledger Accounts'
        },
        {
            value: 'display_individual_balance',
            title: 'Display Individual Balance'
        },
        {
            value: 'include_zero_balance',
            title: 'Include $0.00 Balance'
        },
        {
            value: 'trust_reconciliation_advanced',
            title: 'Advanced'
        }
    ];

    constructor(
        private tagsService: TagsService,
        private ledgerAccountService: LedgerAccountService,
        private fb: UntypedFormBuilder
    ) {}

    ngOnInit() {
        this.initForm();
        this.initSubscription();
        this.initData();
    }

    initData() {
        if (this.report) {
            this.reportSettings = {...this.defaultValues, ...this.report.settings};
            if (this.advancedFormGroup) {
                this.advancedFormGroup.patchValue(this.reportSettings);
            }
        }
    }

    initSubscription() {
        this.ledgerAccountService
            .getLedgerAccounts()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => {
                this.listOfLedgerAccounts = value;
            });

        this.tagsService
            .getCompanyTags()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.allTags = data.result;
            });

        if (this.advancedFormGroup) {
            this.advancedFormGroup.controls['is_override_bank_balance'].valueChanges
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((val) => {
                    if (this.advancedFormGroup) {
                        if (val) {
                            this.advancedFormGroup.controls['statement_bank_balance'].enable();
                            this.advancedFormGroup.controls['deposit_bank_balance'].enable();
                            this.advancedFormGroup.controls['outstanding_bank_balance'].enable();
                            this.isDisabledStatementBankDate = false;
                        } else {
                            this.advancedFormGroup.controls['statement_bank_balance'].setValue(null);
                            this.advancedFormGroup.controls['deposit_bank_balance'].setValue(null);
                            this.advancedFormGroup.controls['outstanding_bank_balance'].setValue(null);
                            this.advancedFormGroup.controls['statement_bank_date'].setValue(null);

                            this.advancedFormGroup.controls['statement_bank_balance'].disable();
                            this.advancedFormGroup.controls['deposit_bank_balance'].disable();
                            this.advancedFormGroup.controls['outstanding_bank_balance'].disable();
                            this.isDisabledStatementBankDate = true;
                        }
                    }
                });
        }
    }

    initForm() {
        this.advancedFormGroup = this.fb.group({
            is_override_bank_balance: [false, []],
            statement_bank_balance: [{value: '', disabled: true}, []],
            deposit_bank_balance: [{value: '', disabled: true}, []],
            outstanding_bank_balance: [{value: '', disabled: true}, []],
            statement_bank_date: [null, []]
        });
    }

    onSelectOptions(event: {value: string}) {
        this.activePanel = event.value;
        this.isShowNext = !isLastPanelItem(this.activePanel, this.reportOptions);
    }

    onValueChanges(property: IReportSettingKeys, data: any) {
        switch (property) {
            case 'time_period':
                this.reportSettings = {
                    ...this.reportSettings,
                    ...data
                };
                break;
            default:
                if (this.reportSettings) {
                    this.reportSettings[property] = data as never;
                }
        }
    }

    onAction(event: string) {
        const response: {
            type: string;
            data: IReportSetting | null;
        } = {
            type: event,
            data: null
        };

        switch (event) {
            case 'save':
            case 'run':
                const advancedFormGroup = this.advancedFormGroup ? this.advancedFormGroup.value : {};
                response['data'] = {
                    ...this.reportSettings,
                    ...advancedFormGroup
                };
                this.action.emit(response);
                break;
            case 'cancel':
            case 'delete':
                this.action.emit(response);
                break;
            case 'next':
                const nextData = getNextActivePanel(this.activePanel, this.reportOptions);
                this.activePanel = nextData.name;
                this.isShowNext = !nextData.isLast;
                break;
            default:
        }
    }

    ngOnDestroy() {
        this.action.complete();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
