import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {ChipNode} from '../../modules/account-info/compensation/models/chip-node';
import {GenericFormArray, GenericFormGroup} from '../../entites/generic.entity';
import {BehaviorSubject, Subject} from 'rxjs';
import {isEqual} from 'lodash-es';
import {Company} from '../../models/company';
import {ISearchEntity, IParticipantSubTypes, IContactPartLink} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-entity-picker-chips',
    templateUrl: './entity-picker-chips.component.html'
})
export class EntityPickerChipsComponent implements AfterViewInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    CHIP_NODE = ChipNode;

    @ViewChild('chipText', {static: true}) chipText: ElementRef<HTMLElement> | undefined = undefined;

    @Input() commonList: BehaviorSubject<ISearchEntity[]> = new BehaviorSubject<ISearchEntity[]>([]);
    @Input() nodeFG: GenericFormGroup<ChipNode> = new GenericFormGroup<ChipNode>(new ChipNode());
    @Input() ni: number | undefined = undefined;
    @Input() hideControls: boolean = false;
    @Input() disabled: boolean = false;
    @Input() seemDisabled: boolean = false;
    @Input() subTypesArray: any[] = [];
    @Input() dealParticipantsWithSubType: IContactPartLink[] | null = null;
    @Input() lineClamp: boolean = false; // show text reduced and in 2 rows if it's too long

    @Input() allowSubCompanySearch: boolean = false;
    @Input() chosenSubCompanies: BehaviorSubject<Company[]> = new BehaviorSubject<Company[]>([]);

    @Output() doRemoveNodeFAEmitter: EventEmitter<number> = new EventEmitter<number>();

    ngAfterViewInit() {
        // reduce font-size for text in chip
        if (
            typeof this.chipText !== 'undefined' &&
            this.chipText.nativeElement?.scrollHeight &&
            this.chipText.nativeElement?.offsetHeight &&
            this.chipText.nativeElement.scrollHeight > this.chipText.nativeElement.offsetHeight
        ) {
            this.chipText.nativeElement.style.fontSize = '0.8rem';
        }
    }

    public getCompanyNameById(companyId: number | undefined): string {
        const findCompany = this.chosenSubCompanies.value.find((c) => c.id === companyId);
        if (findCompany) {
            return findCompany.title || '';
        }
        return '';
    }

    getContactIcon(contactId: number): string {
        const contactFound = this.commonList.value.find((contact) => contact.id === contactId);

        if (typeof contactFound === 'undefined') {
            return 'person_outline';
        }
        switch (true) {
            case contactFound.related_profile_and_group:
                return 'domain';
            default:
                return 'person_outline';
        }
    }

    compareObjects(o1: {}, o2: {}) {
        return isEqual(o1, o2);
    }

    trackByLabel(idx: number, item: IParticipantSubTypes) {
        return item.label;
    }

    onSubTypeChange(value: IParticipantSubTypes[], subType: GenericFormArray<IParticipantSubTypes> | undefined) {
        if (subType) {
            subType.patchValue(value);
            subType.updateValueAndValidity();
        }
    }

    handleSubTypes(subType: GenericFormArray<IParticipantSubTypes> | undefined) {
        if (subType && subType.getRawValue() && subType.getRawValue().length) {
            return subType
                .getRawValue()
                .map((t) => {
                    let label = '';
                    if (t.scope === 'skyslope_integration') {
                        label += '(ss) ';
                    } else if (t.scope === 'dotloop_integration') {
                        label += '(dl) ';
                    }
                    label += t.value || t.label;
                    return label
                        .split(/(?=[A-Z])/)
                        .join(' ') // came case
                        .split(' ')
                        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(' '); // capitalize first letter
                })
                .join('\n');
        } else {
            return 'No sub-types';
        }
    }

    subTypeAlreadySelected(types: IParticipantSubTypes[] | undefined, scope: string, label: string): boolean {
        return !!types?.find((subType) => subType.scope === scope && subType.label !== label);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
