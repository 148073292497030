import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'app-contact-finance',
    templateUrl: 'contact-finance.component.html'
})
export class ContactFinanceComponent implements OnInit {
    @Input() contactId: number | null = null;

    constructor() {}

    async ngOnInit() {}
}
