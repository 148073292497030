import {Component, OnDestroy, OnInit} from '@angular/core';
import {QuickBooksService} from '../../../../services/api/addon/quickbooks/quickbooks.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Location} from '@angular/common';
import {QbCompanySettingsModel} from '../../../../models/addon/qb-company-settings-model';
import {MatDialog} from '@angular/material/dialog';
import {QbDisconnectDialogComponent} from './quickbooks-disconnect-dialog.component';

@Component({
    selector: 'app-quickbooks-addon-settings',
    templateUrl: 'quickbooks-settings.component.html',
    styleUrls: []
})
export class QuickBooksAddonSettingsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    isConnected: boolean = false;
    isLoading: boolean = true;
    queryString: string;
    companySettings: QbCompanySettingsModel | null = null;

    constructor(
        private quickBooksService: QuickBooksService,
        private location: Location,
        public dialog: MatDialog
    ) {
        this.queryString = this.location.path().split('?')[1];
    }

    openDisconnectConfirmationDialog() {
        const dialogRef = this.dialog.open(QbDisconnectDialogComponent, {
            maxWidth: '600px'
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.disconnect();
                }
            });
    }

    disconnect() {
        this.quickBooksService
            .disableQbCompanySettings()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                this.isConnected = false;
                this.companySettings = null;
            });
    }

    ngOnInit() {
        // QBO OAuth flow will redirect back to this page with their query string appended to the uri.
        // If there is a query string, pass it to the backend to fetch and save the access tokens.
        if (this.queryString) {
            this.quickBooksService
                .getAccessToken(this.queryString)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    // After we get the access tokens, redirect back to the QuickBooks addon page
                    window.location.href = 'marketplace/setup/quickbooks';
                });
        }

        this.loadCompanySettings();
    }

    loadCompanySettings() {
        // If a qb_company_settings record exists for this profile's company ID, then QBO is connected
        this.quickBooksService
            .getQbCompanySettings()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                if (response) {
                    this.companySettings = response;
                    this.isConnected = true;
                }
                this.isLoading = false;
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
