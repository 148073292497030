export class MarketplaceAddonUrlsModel {
    redirect_url: string | null = null;
    redirect_settings: string | null = null;
    redirect_company_settings_url: string | null = null;
    redirect_profile_activated_addon_url: string | null = null;
    personal_settings_url: string | null = null;
    webhook_url: string | null = null;

    webhook_deal_deleted: string | null = null;
    webhook_deal_saved: string | null = null;
    webhook_deal_created: string | null = null;
    webhook_deal_approved: string | null = null;

    webhook_profile_activated: string | null = null;
    webhook_profile_deactivated: string | null = null;
    webhook_profile_email_changed: string | null = null;
    webhook_profiles_permissions_changed_url: string | null = null;

    webhook_company_activated_url: string | null = null;
    webhook_company_deactivated_url: string | null = null;
}

export class MarketplaceAddonSettingsModel {
    urls: MarketplaceAddonUrlsModel | null = null;
    enabled_personal_settings: boolean | null = null;
    enabled_company_settings: boolean | null = null;
    enabled_personal_instances: boolean | null = null;
    is_navigate_to_settings_after_activate: boolean | null = null;
    is_feature_flag_needed: boolean | null = null;
    feature_flag_id: string | null = null;
    enabled_system_profile?: boolean | null = null;
}

export class MarketplaceAddonModel {
    id: number | null = null;
    addon_url: string | null = null;
    preset: MarketplaceAddonInstanceModel | null = null;
    settings: MarketplaceAddonSettingsModel | null = null;
    slug: string | null = null;
    is_featurable: boolean | null = null;
}

export class MarketplaceAddonInstanceModel {
    id: number | null = null;
    addon_instance_id: number | null = null;
    company_fk_id?: number;
    logo: string | null = null;
    icon: string | null = null;
    title: string | null = null;
    description: string | null = null;
    activated: boolean | null = null;
    addon: MarketplaceAddonModel | null = null;
    rbac?: {[key: string]: boolean};
    is_featured: boolean | null = null;
    settings: {
        env: 'sandbox' | 'prod';
        external_customer_ref: string;
        max_card_gateways_count: number;
        max_operating_gateways_number: number;
        max_trust_gateways_number: number;
        notifications_email: string;
    } | null = null;
}
