import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Company} from '../../../../../models/company';
import {CompaniesService} from '../../../../../services/companies.service';
import {SessionService} from '../../../../../services/session.service';
import {MatExpansionPanel} from '@angular/material/expansion';
import {CompanySetupFormGroup} from '../../company-setup.component';
// import {CompanyGoalsFormGroup} from './company-goals.component';
import {InitialSetupService} from '../../../../auth/welcome/initial-setup.service';
import {FetchContactsMust} from '../../../../contacts/store/contacts.actions';
import {Store} from '@ngrx/store';
import {ISettingsState} from '../../../store/settings.reducer';
import {NotificationsService} from 'angular2-notifications';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CurrentProfileSource} from '../../../../../services/sources/current-profile.source';
import {RbacService} from 'app/modules/rbac/rbac.service';

@Component({
    selector: 'app-general-settings',
    templateUrl: 'general-settings.component.html',
    styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    public company: Company | undefined = undefined;

    public companySetupForm: CompanySetupFormGroup = new CompanySetupFormGroup();
    // public companyGoalsForm: CompanyGoalsFormGroup = new CompanyGoalsFormGroup();

    companySetupLoaded: boolean = false;
    // companyGoalsLoaded: boolean = false;

    companySetupSaved: boolean = true;
    // companyGoalsSaved: boolean = true;

    @ViewChild('companySetupPanel') companySetupPanel: MatExpansionPanel | undefined = undefined;

    // @ViewChild('companyGoalsPanel') companyGoalsPanel: MatExpansionPanel;

    saveDisabled: boolean = false;

    constructor(
        protected sessionService: SessionService,
        private companiesService: CompaniesService,
        protected store: Store<ISettingsState>,
        private ntfs: NotificationsService,
        protected initialSetupService: InitialSetupService,
        protected profileSource: CurrentProfileSource,
        protected rbacService: RbacService
    ) {
        // fetch new settings on profile change
        this.profileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.ngOnInit();
        });
    }

    loadCompanySetup() {
        // this.companyGoalsPanel.close();
        // this.userSetupPanel.close();

        return this.initialSetupService.api.getCompanySetup().then((value) => {
            this.companySetupForm.patchValue(value);
            this.companySetupLoaded = true;
        });
    }

    saveCompanySetup() {
        if (!this.saveDisabled) {
            this.saveDisabled = true;

            const dataToSave = this.companySetupForm.getRawValue();
            dataToSave.tin = dataToSave.tin ? dataToSave.tin.replace(/[^0-9]/g, '') : null;
            this.initialSetupService.api
                .postCompanySetup(dataToSave)
                .then((ok) => {
                    this.saveDisabled = false;

                    Promise.all([
                        // this.loadCompanyGoals(),
                        this.loadCompanySetup()
                    ]);
                    this.ntfs.info('Settings successfully updated');
                })
                .then(async () => {
                    if (
                        await this.rbacService.isAllowedAny([
                            {contacts__view_limited_contacts: true},
                            {contacts__view_any_contacts: true}
                        ])
                    ) {
                        this.store.dispatch(new FetchContactsMust());
                    }
                })
                .catch(() => {
                    this.saveDisabled = false;
                });
        }
    }

    // loadCompanyGoals() {
    //     // this.companySetupPanel.close();
    //     // this.userSetupPanel.close();
    //
    //     return this.initialSetupService.api.getCompanyGoals()
    //         .then(value => {
    //             this.companyGoalsForm.patchValue(value);
    //             this.companyGoalsLoaded = true;
    //         })
    //     ;
    // }

    // saveCompanyGoals() {
    //
    //     this.initialSetupService.api.postCompanyGoals(this.companyGoalsForm.getRawValue())
    //         .then(ok => {
    //             this.loadCompanyGoals()
    //                 .then(() => {
    //                     this.companyGoalsSaved = true;
    //                 })
    //         })
    //     ;
    // }

    // loadUserSetup() {
    //     // this.companySetupPanel.close();
    //     // this.companyGoalsPanel.close();
    //
    //     return this.initialSetupService.api.getUserSetup()
    //         .then(value => {
    //             this.userSetupForm.patchValue(value);
    //             this.userSetupLoaded = true;
    //         })
    //     ;
    // }
    //
    // saveUserSetup() {
    //     this.initialSetupService.api.postUserSetup(this.userSetupForm.getRawValue())
    //         .then(ok => {
    //             this.loadUserSetup()
    //                 .then(() => {
    //                     this.userSetupSaved = true;
    //                 })
    //         })
    //     ;
    // }

    ngOnInit(): void {
        this.loadCompanySetup();
        // this.loadCompanyGoals()

        if (
            this.sessionService.profile &&
            this.sessionService.profile.company &&
            this.sessionService.profile.company.id
        ) {
            this.companiesService.getById(this.sessionService.profile.company.id).then((company) => {
                this.company = company;
            });
        }
    }

    public save() {
        if (this.company && this.company.id) {
            this.companiesService.update(this.company.id, this.company);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
