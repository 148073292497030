import {Component, Input} from '@angular/core';
import {IReportsPreviewResultData} from '../../../../store/reports.reducer';

@Component({
    selector: 'reports-preview-ten99',
    templateUrl: './ten99.component.html',
    styleUrls: ['./ten99.component.scss']
})
export class Ten99PreviewComponent {
    @Input() page: number = 0;
    @Input() perPage: number = 25;
    @Input() reportData: IReportsPreviewResultData | null = null;

    constructor() {}
}
