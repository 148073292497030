import {Component, OnInit} from '@angular/core';
import {UserManager} from '@skyslope/auth-js';
import {getUserManager} from '../services/okta/user-manager';

@Component({
    selector: 'app-login',
    templateUrl: './sign-in-prime-callback.component.html',
    styleUrls: ['./sign-in-prime-callback.component.scss']
})
export class SignInPrimeCallbackComponent implements OnInit {
    userManager: UserManager | undefined;
    isLoading: boolean = true;

    constructor() {}

    async ngOnInit(): Promise<void> {
        this.userManager = getUserManager();
        await this.userManager?.primeAuth?.endBackgroundLogin();
    }
}
