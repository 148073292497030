import {Pipe, PipeTransform} from '@angular/core';
import {Widget} from '../../../models/widget';

@Pipe({
    name: 'noWidgetsBoard'
})
export class NoWidgetsBoardPipe implements PipeTransform {
    transform(widgetsList: Widget[]): boolean {
        return widgetsList.filter((widget) => widget.type !== 'add').length === 0;
    }
}
