<mat-card class="compact-card" *ngIf="expandGroupFC">
    <mat-card-header class="header deal-header">
        <mat-card-title class="deal-header-title">
            Disbursement

            <div>
                <button
                    *ngIf="expandGroupFC.value !== 'expand'"
                    type="button"
                    mat-icon-button
                    (click)="setExpand('expand')"
                >
                    <mat-icon [ngStyle]="{color: isExpandActive ? 'currentColor' : '#AFBECD'}">unfold_more</mat-icon>
                </button>

                <button
                    *ngIf="expandGroupFC.value !== 'collapse'"
                    type="button"
                    mat-icon-button
                    (click)="setExpand('collapse')"
                >
                    <mat-icon [ngStyle]="{color: isExpandActive ? 'currentColor' : '#AFBECD'}">unfold_less</mat-icon>
                </button>
            </div>

            <button
                *ngIf="showRestoreButton()"
                mat-raised-button
                class="mat-warn"
                (click)="doResetToDefaults()"
                [disabled]="dealFormGroup.controls.sales_entities?.disabled"
            >
                Restore to Rules
            </button>
        </mat-card-title>
        <div
            class="disbursement-options"
            *ngIf="
                dealFormGroup.controls.id &&
                dealFormGroup.controls.id.value &&
                dealFormGroup.controls.system_status &&
                dealFormGroup.controls.system_status.value !== DEAL_SYSTEM_STATUS.draft
            "
        >
            <!------- View Disbursement button ------->
            <ng-container>
                <button
                    (click)="doRefresh($event)"
                    *ngIf="showRefreshButton"
                    mat-icon-button
                    [disabled]="dealFormGroup.controls.sales_entities?.disabled"
                >
                    <mat-icon>autorenew</mat-icon>
                </button>
                <button *ngIf="!showRefreshButton" disabled="true" mat-icon-button>
                    <mat-icon>done_all</mat-icon>
                </button>
                <!-- <a target="_blank" style="display: inline-block;" href="https://storage.googleapis.com/zipi-static/Zipi-Deal-Disbursement-Authorization-Form.pdf"><button mat-raised-button>View Disbursement</button></a>     -->
                <!--<button mat-icon-button matPrefix (click)="openDisbursementTextEdit($event)">-->
                <!--<mat-icon style="margin-right: 2px;" class="option-icon">subject</mat-icon>-->
                <!--</button>-->
                <!-- <button mat-raised-button (click)="viewDisbursement()">View Disbursement</button> -->
                <!-- download="Disbursement.pdf" -->
                <button
                    mat-raised-button
                    [ngClass]="{'pdf-outdated': pdfOutdated}"
                    *ngIf="dealFormGroup.controls.dual_deal_id && dealFormGroup.controls.dual_deal_id.value"
                    [rule]="{deals__view_disbursement_documents: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    target="_blank"
                    [matTooltip]="'Dual Deal Disbursement PDF is outdated'"
                    [matTooltipDisabled]="!pdfOutdated"
                    (click)="openDualDealDisbursementPDFinNewTab()"
                >
                    Dual Deal Disbursement
                </button>
            </ng-container>

            <button mat-raised-button class="mx-3" [matMenuTriggerFor]="menu">
                Disbursement <mat-icon>expand_more</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button
                    mat-menu-item
                    [rule]="{deals__view_disbursement_documents: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'block'}"
                    [denyStyle]="{display: 'none'}"
                    (click)="openDisbursementPDFinNewTab()"
                    [matTooltip]="'Disbursement PDF is outdated'"
                    [matTooltipDisabled]="!pdfOutdated"
                >
                    View
                </button>
                <button
                    mat-menu-item
                    *ngIf="emailPDFenabledFlag"
                    [rule]="{deals__manage_disbursement_documents: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'block'}"
                    [denyStyle]="{display: 'none'}"
                    (click)="emailDisbursementPDF()"
                    [matTooltip]="'Disbursement PDF is outdated'"
                    [matTooltipDisabled]="!pdfOutdated"
                >
                    Email
                </button>
                <button
                    mat-menu-item
                    *ngIf="isDealConnectedToSkyslope"
                    [rule]="{deals__manage_disbursement_documents: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'block'}"
                    [denyStyle]="{display: 'none'}"
                    (click)="sendDisbursementToSkyslope()"
                    [matTooltip]="'Disbursement PDF is outdated'"
                    [matTooltipDisabled]="!pdfOutdated"
                >
                    Send to Skyslope
                </button>
                <button
                    mat-menu-item
                    *ngIf="downloadPDFenabledFlag"
                    [rule]="{deals__view_disbursement_documents: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'block'}"
                    [denyStyle]="{display: 'none'}"
                    (click)="downloadDisbursementPDF()"
                    [matTooltip]="'Disbursement PDF is outdated'"
                    [matTooltipDisabled]="!pdfOutdated"
                >
                    Download
                </button>
            </mat-menu>

            <!------- Approval button ------->
            <ng-container
                *ngIf="
                    dealFormGroup.controls.disbursement_approve_requested &&
                    dealFormGroup.controls.disbursement_approve_requested.value &&
                    dealFormGroup.controls.disbursement_approved &&
                    !dealFormGroup.controls.disbursement_approved.value
                "
            >
                <span class="ml-2">Approval Requested</span>
                <button
                    class="ml-2"
                    mat-raised-button
                    (click)="requestDisbursementApproval()"
                    [disabled]="requestApprovalDisabled"
                >
                    Request Approval Again
                </button>
            </ng-container>
            <ng-container
                *ngIf="
                    dealFormGroup.controls.disbursement_approved && !dealFormGroup.controls.disbursement_approved.value
                "
            >
                <button
                    (click)="approveDisbursement()"
                    mat-stroked-button
                    color="primary"
                    [disabled]="approveDisabled || isDealSaveInProgress"
                    [rule]="{deals__manage_disbursement_documents: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                >
                    Approve
                </button>
                <button
                    *ngIf="
                        dealFormGroup.controls.disbursement_approve_requested &&
                        !dealFormGroup.controls.disbursement_approve_requested.value
                    "
                    (click)="requestDisbursementApproval()"
                    mat-raised-button
                    [disabled]="requestApprovalDisabled"
                    [rule]="{deals__manage_disbursement_documents: false}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                >
                    Request Approval
                </button>
                <!--<button (click)="approveDisbursement()" mat-raised-button>-->
                <!--TEST Approve-->
                <!--</button>-->
                <!--<button (click)="requestDisbursementApproval()" mat-raised-button>-->
                <!--TEST Request Approval-->
                <!--</button>    -->
            </ng-container>
            <ng-container
                *ngIf="
                    dealFormGroup.controls.disbursement_approved && dealFormGroup.controls.disbursement_approved.value
                "
            >
                <span class="ml-2">Disbursement Approved</span>
                <button
                    mat-stroked-button
                    color="warn"
                    [rule]="{deals__manage_disbursement_documents: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    matTooltip="Revert to Draft"
                    [matTooltipDisabled]="
                        (dealFormGroup.controls.is_escrow_paid && dealFormGroup.controls.is_escrow_paid.value) ||
                        isConnectedFinEntitiesExist
                    "
                    [disabled]="
                        (dealFormGroup.controls.is_escrow_paid && dealFormGroup.controls.is_escrow_paid.value) ||
                        isConnectedFinEntitiesExist
                    "
                    (click)="revertDisbursementApproval()"
                    class="ml-3"
                >
                    <span
                        [matTooltip]="
                            dealFormGroup.controls.is_escrow_paid && dealFormGroup.controls.is_escrow_paid.value
                                ? 'Deal has already been paid at closing. To unapprove you must first revert the closing payment.'
                                : isConnectedFinEntitiesExist
                                  ? 'Deal Payouts have Connected Financial Entities. To unapprove you must disconnect Bills / Invoices from Payouts.'
                                  : ''
                        "
                        [matTooltipDisabled]="
                            dealFormGroup.controls.is_escrow_paid &&
                            !dealFormGroup.controls.is_escrow_paid.value &&
                            !isConnectedFinEntitiesExist
                        "
                    >
                        Unapprove
                    </span>
                </button>
            </ng-container>
        </div>
    </mat-card-header>
    <mat-card-content style="margin-left: 16px">
        <app-deal-expenses
            #dealExpenses
            (clickOnPanel)="deselectExpand()"
            *ngIf="(canEditAnyDeals || canCreateAnyDeals) && dealFormGroup.controls.company_calculation"
            [companyCalculationFG]="dealFormGroup.controls.company_calculation"
            [dealSystemStatus]="
                dealFormGroup.controls.system_status ? dealFormGroup.controls.system_status.value : 'draft'
            "
        ></app-deal-expenses>
        <app-company-expenses
            #companyExpenses
            (clickOnPanel)="deselectExpand()"
            [dealId]="dealId"
            [dealFormGroup]="dealFormGroup"
            *ngIf="(canEditAnyDeals || canCreateAnyDeals) && dealFormGroup.controls.company_calculation"
            [companyCalculationFG]="dealFormGroup.controls.company_calculation"
        ></app-company-expenses>
        <app-sale-entities
            #salesEntities
            *ngIf="dealFormGroup.controls.sales_entities"
            (clickOnPanel)="deselectExpand()"
            style="margin-top: 22px; width: 100%"
            [dealFormGroup]="dealFormGroup"
            [isPrimarySalesEntity]="isPrimarySalesEntity"
            [salesEntitiesFormArray]="dealFormGroup.controls.sales_entities"
            [coeController]="dealFormGroup.controls.close_of_escrow"
            [showSideCountAndSalesVolumeFlag]="showSideCountAndSalesVolumeFlag"
            (needSave)="saveWithReload.emit()"
            (openSideBar)="handleOpenSideBar()"
        ></app-sale-entities>

        <!------- Sales Entities search ------->
        <div class="d-flex align-items-end mt-4">
            <div
                class="w-33 mb-3"
                *ngIf="
                    canEditAnyDeals ||
                    (isPrimarySalesEntity && canEditOwnDeals) ||
                    canCreateAnyDeals ||
                    (isPrimarySalesEntity && canCreateOwnDeals)
                "
            >
                <button
                    mat-raised-button
                    (click)="manageSalesEntities()"
                    [disabled]="
                        (dealProcessing.isDealReadyForEditing | async) === false ||
                        dealFormGroup.controls.sales_entities?.disabled
                    "
                >
                    Manage Sales Entities
                </button>
                <!--<app-add-sales-entity
                    *ngIf="canViewAnyContacts"
                    [dealFormGroup]="dealFormGroup"
                ></app-add-sales-entity>-->
            </div>

            <div class="d-flex mb-3 w-33 justify-content-center">
                <button
                    mat-raised-button
                    class="collect-deposit _track_deal_collect_a_deposit"
                    *ngIf="dealId && showDepositButtonNeed"
                    (click)="showDeposit()"
                >
                    Collect a Deposit
                </button>
            </div>

            <div
                class="d-flex mb-3 w-33 justify-content-end"
                *ngIf="dealId"
                [rule]="{deals__view_trade_sheet: true}"
                rbac
            >
                <button mat-raised-button [matMenuTriggerFor]="menu">Trade Sheet</button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="exportToPdf('condensed')">Condensed</button>
                    <button mat-menu-item (click)="exportToPdf('expanded')">Expanded</button>
                    <button *ngIf="isBrokerTradesheetEnabled" mat-menu-item (click)="exportToPdf('broker')">
                        Broker
                    </button>
                    <!--                    <button mat-menu-item (click)="exportToPdf('expanded-with-notes')">-->
                    <!--                        Expanded with notes-->
                    <!--                    </button>-->
                </mat-menu>
            </div>
        </div>
    </mat-card-content>
</mat-card>
