import * as _ from 'lodash-es';
import {CONTACT_INVITES_ACTION_TYPES} from './contact-invites.actions';
import {IContactInvite} from '../../../../typings';

export interface IContactInvitesState {
    contactInvite: IContactInvite | undefined;
    loading: boolean;
}

export const INITIAL_CONTACT_INVITES_STATE: IContactInvitesState = {
    contactInvite: undefined,
    loading: false
};

export function contactInvitesReducer(
    state: IContactInvitesState = INITIAL_CONTACT_INVITES_STATE,
    action: any
): IContactInvitesState {
    const newState = _.cloneDeep(state);

    switch (action.type) {
        case CONTACT_INVITES_ACTION_TYPES.CONTACT_INVITE_RECEIVED: {
            newState.contactInvite = action.payload!;
            newState.loading = false;

            return newState;
        }

        case CONTACT_INVITES_ACTION_TYPES.FETCH_CONTACT_INVITE: {
            newState.loading = true;

            return newState;
        }

        case CONTACT_INVITES_ACTION_TYPES.SUBMIT_ACCEPT_INVITE_FORM: {
            newState.loading = true;

            return newState;
        }

        case CONTACT_INVITES_ACTION_TYPES.INVITE_ACCEPT_ENDED: {
            newState.loading = action.payload!;

            return newState;
        }

        default:
            return newState;
    }
}
