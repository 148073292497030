import {GenericEntity} from '../entites/generic.entity';
import {IWildcard} from '@cyberco-nodejs/zipi-typings';

export class Wildcard extends GenericEntity implements IWildcard {
    wildcard_id?: number;
    creator__company_fk_id?: number;
    title?: string;
    wildcard_type_sk?: string;
    default?: string;

    static get type_SET() {
        return {
            contact: 'contact',
            text: 'text',
            numeric: 'numeric'
        };
    }
}
