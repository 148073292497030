import {Component, Input, OnDestroy} from '@angular/core';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {FinancialNode} from './financial-node.model';
import {MatDialog} from '@angular/material/dialog';
import {IntraedealExplainDialogComponent} from '../../../deals/components/deal/common/intraedeal-explain.dialog';
import {CurrencyPipe} from '@angular/common';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-financial-node',
    styles: [
        `
            .money {
                color: rgba(0, 0, 0, 0.87);
            }

            .fee-value {
                font-size: 14px;
                color: #0a2642;
                font-weight: bold;
            }

            .positive {
                color: darkgreen;
            }

            .negative {
                color: darkred;
            }

            .percent {
                color: rgba(0, 0, 0, 0.57);
            }

            .label {
                color: rgba(0, 0, 0, 0.87);
            }

            .come-from {
                height: 18px;
                font-size: 18px;
                align-self: center;
                color: #3498db;
            }

            .intradeal-icon {
                width: 18px;
                height: 18px;
                font-size: 18px;
                align-self: center;
                color: rgba(0, 0, 0, 0.57);
            }

            .clickable {
                cursor: pointer;
            }
        `
    ],
    template: `
        <span style="display: inline-flex; align-items: baseline">
            <span
                class="label"
                [ngStyle]="labelStyle"
                *ngIf="!label && financialNodeFG.controls.label && financialNodeFG.controls.label.value"
            >
                {{ financialNodeFG.controls.label.value }}
            </span>
            <span class="label" [ngStyle]="labelStyle" *ngIf="label">{{ label }}:</span>
            <span
                [class]="
                    moneyClass +
                    ((financialNodeFG.controls.amount?.value || 0) < 0 && highlightIfNegative ? ' negative' : '')
                "
                style="margin-left: 4px"
                [matTooltipClass]="moneyClass"
                [matTooltip]="
                    financialNodeFG.controls.compensation_plan?.controls?.compensation_type?.value === 'percent' &&
                    financialNodeFG.controls.compensation_plan?.controls?.compensation_value?.value !== 0
                        ? getTooltipValue()
                        : ''
                "
            >
                {{ financialNodeFG.controls.amount?.value || 0 | currency: 'USD' : 'symbol' }}
            </span>
            <span></span>
            <span *ngIf="getFinalIntradeals().length > 0">
                <mat-icon
                    class="material-icons-outlined intradeal-icon clickable"
                    style="margin-left:4px"
                    [style]="{height: iconSize, 'font-size': iconSize, 'margin-left': '4px'}"
                    (click)="explainIntradeals()"
                    >assessment</mat-icon
                >
            </span>
            <mat-icon
                *ngIf="financialNodeFG.controls.tooltip?.value"
                class="come-from clickable"
                style="margin-left:4px"
                [style]="{height: iconSize, 'font-size': iconSize, 'margin-left': '4px'}"
                [matTooltip]="financialNodeFG.controls.tooltip?.value || ''"
                >help_outline</mat-icon
            >
        </span>
    `
})
export class FinancialNodeComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() public labelStyle: {[key: string]: string} = {};
    @Input() moneyClass: string = 'money';
    @Input() label: string | null = null;
    @Input() nodeType: string | null = null;
    @Input() financialNodeFG: GenericFormGroup<FinancialNode> = new GenericFormGroup(new FinancialNode());
    @Input() iconSize: string = '16px';
    @Input() highlightIfNegative: boolean = false;

    getFinalIntradeals() {
        if (this.financialNodeFG.controls.intradeals) {
            return this.financialNodeFG.controls.intradeals.controls.filter((i) => i.value.final);
        } else {
            return [];
        }
    }

    getTooltipValue() {
        return `Based on ${this.currency.transform(this.financialNodeFG.controls.sourceNode?.value.amount, 'USD', 'symbol')}`;
    }

    explainIntradeals() {
        const dialogRef = this.dialog.open(IntraedealExplainDialogComponent);
        dialogRef.componentInstance.financialNode = this.financialNodeFG.getRawValue();
        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    constructor(
        protected dialog: MatDialog,
        protected currency: CurrencyPipe
    ) {}

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
