import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {environment} from '../../../../../../../environments/environment';
import {SessionService} from '../../../../../../services/session.service';

import firebase from 'firebase/compat/app';
import DataSnapshot = firebase.database.DataSnapshot;

@Injectable()
export class DealEventsService {
    private _singleDealSubject: Subject<any>;
    // public allDealsSubject:Subject<any>;

    constructor(
        private db: AngularFireDatabase,
        private sessionService: SessionService
    ) {
        this._singleDealSubject = new Subject();

        // NOTE: that is how we can implement "Watch ALL deals in company".
        // const refPath = `/notification-events/${(<any>environment).namespace}/company/${this.sessionService.profile.company_fk_id}/deal-events`;
        // this.db.database.ref(refPath)
        //     .limitToLast(1) // if old notifications fired - limit them to only one
        //     .orderByChild('timestamp')
        //     .startAt(Date.now()) // skip old notifications
        //     .on('child_added', (childSnapshot: DataSnapshot) => {
        //         const item = childSnapshot.toJSON();
        //         this.allDealsSubject.next(item);
        //     });
    }

    public watchDealEvents(dealId: number) {
        if (!this.sessionService.profile) return this._singleDealSubject;

        const refPath = `/notification-events/${(<any>environment).namespace}/company/${this.sessionService.profile.company_fk_id}/deal-events/${dealId}`;

        this.db.database
            .ref(refPath)
            .limitToLast(1) // if old notifications fired - limit them to only one
            .orderByChild('timestamp')
            .startAt(Date.now()) // skip old notifications
            .on('child_added', (childSnapshot: DataSnapshot) => {
                const item = childSnapshot.toJSON();
                this._singleDealSubject.next(item);
            });

        return this._singleDealSubject;
    }

    public unwatchDealEvents(dealId: number) {
        if (!this.sessionService.profile) return this._singleDealSubject;
        const refPath = `/notification-events/${(<any>environment).namespace}/company/${this.sessionService.profile.company_fk_id}/deal-events/${dealId}`;
        this.db.database.ref(refPath).off('child_added');
    }
}
