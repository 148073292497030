import {Injectable} from '@angular/core';
import {ServiceRequester} from './service.requester';
import {Observable} from 'rxjs';
import {ILicense, ILicenseReload} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class LicensesService {
    private url = '/companies';

    constructor(public requester: ServiceRequester) {}

    getAllLicenses(companyId: number): Observable<ILicense[]> {
        return this.requester.makeMsCall$(`${this.url}/${companyId}/licenses/all`, 'GET', 'shipp');
    }

    getAvailableLicensesCount(companyId: number): Observable<{licensescount: number}> {
        return this.requester.makeMsCall$(`${this.url}/${companyId}/licenses/count`, 'GET', 'shipp');
    }

    getLicensesReloadHistoryWithParams(companyId: number, params: any = {}): Observable<ILicenseReload[]> {
        return this.requester.makeMsCall$(
            `${this.url}/${companyId}/license-reload/history-params`,
            'GET',
            'shipp',
            params
        );
    }
}
