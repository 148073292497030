<mat-sidenav-container
    [hasBackdrop]="true"
    (backdropClick)="close()"
    class="sidenav-container"
    style="background-color: #fff"
>
    <mat-sidenav
        #sidenav
        [disableClose]="false"
        (closedStart)="onClose()"
        [position]="'end'"
        [ngStyle]="sidenav.opened ? {height: 'calc(100vh - 50px)'} : null"
    >
        <div class="form" [formGroup]="commonReportForm" *ngIf="commonReportForm">
            <div class="form-body">
                <div *ngIf="!reportTypeTitle">
                    <h4 class="mt-3 mb-3">Create Report</h4>
                </div>
                <div *ngIf="reportTypeTitle">
                    <div *ngIf="!isEditTitle" class="w-100 report-type-btn">
                        <div class="row">
                            <div class="col-xl-4 text-left">
                                <span class="report-label">{{ reportTypeTitle }}</span>
                            </div>
                            <div class="col-xl-8 text-left report-title">
                                <ng-container
                                    *ngIf="id && commonReportForm.controls && commonReportForm.controls.title"
                                >
                                    <span class="edit-icon-section"
                                        >{{ commonReportForm.controls.title.value }}
                                        <i
                                            class="material-icons icon-btn edit-icon"
                                            (click)="showEditReportTitle($event)"
                                            >edit</i
                                        >
                                    </span>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <div class="form-header" *ngIf="isEditTitle">
                        <div class="row">
                            <div class="col-xl-10">
                                <mat-form-field class="w-100">
                                    <input matInput placeholder="Report Name" formControlName="title" required />
                                </mat-form-field>
                            </div>
                            <div class="col-xl-2 edit-actions">
                                <i class="material-icons icon-btn" (click)="applyReportTitle()">check</i>
                                <i class="material-icons icon-btn" (click)="cancelEditReportTitle()">cancel</i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row types-content" *ngIf="!reportType">
                    <div class="col-xl-4 left-side">
                        <!-- Company Level -->
                        <ng-container *ngIf="companyReportTypes.length">
                            <h4 class="type-header">Company Level</h4>
                            <mat-button-toggle-group
                                name="fontStyle"
                                aria-label="Font Style"
                                vertical="true"
                                [value]="activePanel"
                                (change)="selectReportOptions($event)"
                            >
                                <mat-button-toggle
                                    *ngFor="let groupReport of companyReportTypes"
                                    rbac
                                    [rule]="groupReport.permissionRule"
                                    [denyMethod]="'style'"
                                    [allowStyle]="{display: 'block'}"
                                    [denyStyle]="{display: 'none'}"
                                    [value]="groupReport.value"
                                    >{{ groupReport.name }}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </ng-container>
                        <!-- End Company Level -->

                        <!-- User Level -->
                        <ng-container *ngIf="userReportTypes.length">
                            <h4 class="type-header">User Level</h4>
                            <mat-button-toggle-group
                                name="fontStyle"
                                aria-label="Font Style"
                                vertical="true"
                                [value]="activePanel"
                                (change)="selectReportOptions($event)"
                            >
                                <mat-button-toggle
                                    *ngFor="let groupReport of userReportTypes"
                                    rbac
                                    [rule]="groupReport.permissionRule"
                                    [denyMethod]="'style'"
                                    [allowStyle]="{display: 'block'}"
                                    [denyStyle]="{display: 'none'}"
                                    [value]="groupReport.value"
                                    >{{ groupReport.name }}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </ng-container>
                        <!-- End User Level -->
                    </div>
                    <div class="col-xl-8 right-side">
                        <div class="content">
                            <ng-container *ngFor="let groupReport of groupReportsTypes">
                                <mat-button-toggle-group
                                    *ngIf="activePanel === groupReport.value"
                                    formControlName="type"
                                    aria-label="Font Style"
                                    vertical="true"
                                >
                                    <ng-container *ngFor="let type of groupReport.reports">
                                        <mat-button-toggle
                                            *ngIf="
                                                (additional1099ReportsEnabledFlag &&
                                                    type.value !== availableReportType.ten99) ||
                                                (!additional1099ReportsEnabledFlag &&
                                                    ![
                                                        availableReportType.ten99_nec,
                                                        availableReportType.ten99_int,
                                                        availableReportType.ten99_misc,
                                                        availableReportType.ten99
                                                    ].includes(type.value))
                                            "
                                            [value]="type.value"
                                        >
                                            {{ type.title }}
                                            <mat-icon
                                                *ngIf="type.tooltip"
                                                [matTooltip]="type.tooltip"
                                                matTooltipPosition="above"
                                                matTooltipClass="card-tooltip"
                                                >info_outline</mat-icon
                                            >
                                        </mat-button-toggle>
                                    </ng-container>
                                </mat-button-toggle-group>
                            </ng-container>
                            <div>
                                <div class="row modal-actions">
                                    <div class="col-xl-5"></div>
                                    <div class="col-xl-7">
                                        <div class="action-row m-3 pr-3">
                                            <button mat-raised-button class="fix-focus-outline mr-2" (click)="close()">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="reportType" class="h-100">
                    <app-report-edit-bar-transaction-summary
                        *ngIf="reportType === availableReportType.summary"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-transaction-summary>
                    <app-report-edit-bar-detailed
                        *ngIf="reportType === availableReportType.detailed"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-detailed>
                    <app-report-edit-bar-source-of-business
                        *ngIf="reportType === availableReportType.sob"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-source-of-business>
                    <app-report-edit-bar-commission-expense
                        *ngIf="reportType === availableReportType.commission"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-commission-expense>
                    <app-report-edit-bar-average-sales
                        *ngIf="reportType === availableReportType['avg-sales']"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-average-sales>
                    <app-report-edit-bar-agent-performance
                        *ngIf="reportType === availableReportType['agent-performance']"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-agent-performance>
                    <app-report-edit-bar-product
                        *ngIf="reportType === availableReportType.product"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-product>
                    <app-report-edit-bar-contact
                        *ngIf="reportType === availableReportType.contact"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-contact>
                    <app-report-edit-bar-cap
                        *ngIf="reportType === availableReportType.cap"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-cap>
                    <app-report-edit-bar-membership
                        *ngIf="reportType === availableReportType.membership"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-membership>
                    <app-report-edit-bar-progress
                        *ngIf="reportType === availableReportType.tracking"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-progress>
                    <app-report-edit-bar-income-statement
                        *ngIf="reportType === availableReportType.profit_loss"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-income-statement>
                    <app-report-edit-bar-balance-sheet
                        *ngIf="reportType === availableReportType.balance_sheet"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-balance-sheet>
                    <app-report-edit-bar-cash-flow
                        *ngIf="reportType === availableReportType.cash_flow"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-cash-flow>
                    <app-report-edit-bar-account-transaction
                        *ngIf="reportType === availableReportType.account_transactions"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-account-transaction>
                    <app-report-edit-bar-journal
                        *ngIf="reportType === availableReportType.journal"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-journal>
                    <app-report-edit-bar-general-ledger
                        *ngIf="reportType === availableReportType.general_ledger"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-general-ledger>
                    <app-report-edit-bar-account-type
                        *ngIf="reportType === availableReportType.account_type"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-account-type>
                    <app-report-edit-bar-trial-balance
                        *ngIf="
                            reportType === availableReportType.trial_balance ||
                            reportType === availableReportType.summary_trial_balance
                        "
                        [report]="report"
                        [trialBalanceType]="reportType"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-trial-balance>
                    <app-report-edit-bar-trust-reconciliation
                        *ngIf="reportType === availableReportType.trust_reconciliation"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-trust-reconciliation>
                    <app-report-edit-bar-recurring-invoice
                        *ngIf="reportType === availableReportType.recurring_invoice"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-recurring-invoice>
                    <app-report-edit-bar-ten99-nec
                        *ngIf="
                            reportType === availableReportType.ten99 ||
                            reportType === availableReportType.ten99_nec ||
                            reportType === availableReportType.ten99_int ||
                            reportType === availableReportType.ten99_misc
                        "
                        [report]="report"
                        [reportType]="reportType"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-ten99-nec>

                    <app-report-edit-bar-ap-aging
                        *ngIf="reportType === availableReportType.ap_aging && apArAgingEnabledFlag"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-ap-aging>

                    <app-report-edit-bar-ar-aging
                        *ngIf="reportType === availableReportType.ar_aging && apArAgingEnabledFlag"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-ar-aging>

                    <app-report-edit-bar-transaction-summary-user
                        *ngIf="reportType === availableReportType.summary_user"
                        [report]="report"
                        (action)="onAction($event)"
                    >
                    </app-report-edit-bar-transaction-summary-user>
                </div>
            </div>
        </div>
    </mat-sidenav>

    <ng-content></ng-content>
</mat-sidenav-container>
