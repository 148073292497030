<div mat-dialog-title>
    <h3>{{ data.title }}</h3>
</div>

<mat-dialog-content>
    <div class="mb-3">
        <p *ngIf="data.message">
            {{ data.message }}
        </p>

        <p *ngFor="let msg of data.messages" class="m-0">
            {{ msg }}
        </p>
    </div>

    <div *ngIf="data.showAddonsFlatCommission">
        <app-deal-commission-categorization-notes
            [labelStyle]="{'font-size': '20px'}"
            [withTitle]="true"
            [dealId]="data.dealId"
        >
        </app-deal-commission-categorization-notes>
    </div>

    <dl *ngFor="let msg of data.definitions" class="row">
        <dl class="col-9">{{ msg[0] }}</dl>
        <dd class="col-3">{{ msg[1] }}</dd>
    </dl>

    <mat-checkbox *ngIf="!data.hideCheckboxShowMessageAgain" [formControl]="showMessageAgainControl"
        >Don't show this message again</mat-checkbox
    >
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
    <button
        mat-raised-button
        class="mr-2"
        color="primary"
        (click)="dialogRef.close(showMessageAgainControl.value)"
        tabindex="1"
    >
        {{ data.buttonOkMessage || 'Ok' }}
    </button>
</mat-dialog-actions>
