import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {GroupCompensationProfilesListItem} from '../models/group-compensation-profiles-list-item';

@Component({
    selector: 'app-company-compensation-group',
    styles: [
        `
            .label {
                margin-right: 16px;
            }

            .card-content {
                align-items: center;
                display: flex;
                width: 100%;
            }
        `
    ],
    template: `
        <mat-expansion-panel #panel>
            <mat-expansion-panel-header>
                <div class="card-content">
                    <div class="flex-row" style="align-items: center; justify-content: space-around; width: 100%">
                        <div class="label">
                            <mat-icon>people_outline</mat-icon>
                            {{ compensationGroup!.controls.company_group!.controls.title?.value }}
                        </div>

                        <app-company-compensation-profiles-chip-list
                            *ngIf="!panel.expanded"
                            [maxChips]="6"
                            [compensationProfilesList]="compensationGroup.controls.compensationProfiles"
                        >
                        </app-company-compensation-profiles-chip-list>

                        <div class="remaining-space"></div>
                        <div style="margin-right: 64px">
                            <button [disabled]="disabled" mat-icon-button *ngIf="show_up" (click)="up.emit()">
                                <mat-icon>arrow_upward</mat-icon>
                            </button>
                            <button [disabled]="disabled" mat-icon-button *ngIf="show_down" (click)="down.emit()">
                                <mat-icon>arrow_downward</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel-header>

            <app-company-compensation-profiles-chip-list
                *ngIf="panel.expanded"
                [compensationProfilesList]="compensationGroup.controls.compensationProfiles"
            >
            </app-company-compensation-profiles-chip-list>
        </mat-expansion-panel>
    `
})
export class CompensationGroupComponent implements OnInit {
    @Input() compensationGroup: GenericFormGroup<GroupCompensationProfilesListItem> =
        new GenericFormGroup<GroupCompensationProfilesListItem>(new GroupCompensationProfilesListItem());

    @Input() disabled: boolean = false;
    @Input() order: number = 0;

    @Input() show_up: boolean = false;
    @Input() show_down: boolean = false;

    @Output() up = new EventEmitter();
    @Output() down = new EventEmitter();

    object_keys(obj: object) {
        return Object.keys(obj);
    }

    ngOnInit() {
        this.compensationGroup.controls.company_group!.controls.sort_order!.patchValue(this.order);
    }

    constructor() {}
}
