import {TokenDomain, createUserManager, Environment, UserManager} from '@skyslope/auth-js';
import {environment} from 'environments/environment';

let userManager: UserManager;

export const getOktaEnv = () => {
    switch (environment.env) {
        case 'production':
            return Environment.Prod;
        case 'stage':
            return Environment.Staging;
        case 'dev':
            return Environment.Integ;
        case 'local':
            return Environment.Integ;
        default:
            throw new Error('Unknown Okta environment');
    }
};

function create(): UserManager {
    userManager = createUserManager({
        environment: getOktaEnv(),
        tokenDomain: TokenDomain.Global,
        providers: {
            okta: {
                clientId: environment.okta.clientId,
                redirectPath: environment.okta.callbackRedirectPath,
                postLogoutRedirectPath: environment.okta.postLogoutRedirectPath,
                scopes: ['openid', 'email', 'profile', 'com.skyslope.groups']
            }
        },
        onAuthRequired: async (userManager: UserManager) => {
            const lastIdp = userManager.idps.last;
            if (lastIdp === userManager.idps.primeAuth) {
                await userManager.login({idp: lastIdp});
            } else {
                window.location.href = '/login';
            }
        }
    });
    return userManager;
}

//singleton
function getUserManager(): UserManager {
    if (typeof window === 'undefined') {
        return {} as UserManager;
    }
    if (userManager) return userManager;
    return create();
}

export {userManager, getUserManager};
