import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {ToggleEditReportSidebarStatus} from '../../store/reports.action';

@Component({
    selector: 'app-reports-layout',
    templateUrl: './report-layout.component.html',
    styleUrls: ['./report-layout.component.scss']
})
export class ReportLayoutComponent implements OnInit {
    public menuTitle = '';

    constructor(
        public router: Router,
        private store: Store<any>
    ) {}

    ngOnInit() {
        this.prepareMenuTitle();
    }

    prepareMenuTitle() {
        if (this.router.url === '/reports') {
            this.menuTitle = 'Detailed Reports';
        } else if (this.router.url.includes('personal')) {
            this.menuTitle = 'My Reports';
        } else if (this.router.url.includes('recommended')) {
            this.menuTitle = 'Recommended';
        } else if (this.router.url.includes('preview')) {
            this.menuTitle = 'View Report';
        } else if (this.router.url.includes('company')) {
            this.menuTitle = 'Company';
        }
    }

    toggleEditReportSidebar(status: boolean, reportId: number | null = null) {
        this.store.dispatch(
            new ToggleEditReportSidebarStatus({
                isOpened: status,
                isEditMode: !!reportId,
                editId: reportId
            })
        );
    }
}
