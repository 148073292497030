import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ServiceRequester} from '../service.requester';
import {ICustomTokenResponse} from '../../typings/custom-token';

@Injectable()
export class ApiSessionService {
    constructor(public requester: ServiceRequester) {}

    async sessionCoreLogin(data: {id_token: string | null; okta_access_token: string | null}) {
        return this.requester.makeMsCall(`/public/session/session-login`, 'POST', data);
    }
    async sessionCoreLogout() {
        return this.requester.makeMsCall(`/public/session/session-logout`, 'POST', {});
    }

    sessionShippLogin(data: {id_token: string | null; okta_access_token: string | null}): Observable<any> {
        return this.requester.makeMsCall$(`/public/session/session-login`, 'POST', 'shipp', data);
    }
    sessionShippLogout(): Observable<any> {
        return this.requester.makeMsCall$(`/public/session/session-logout`, 'POST', 'shipp', {});
    }

    sessionShippCustomToken(token: string): Promise<ICustomTokenResponse> {
        return this.requester
            .makeMsCall$(
                `/public/session/custom-token`,
                'GET',
                'shipp',
                {},
                {
                    Authorization: `Bearer ${token}`
                }
            )
            .toPromise();
    }
}
