import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {filter, takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {CreateRequestDialogComponent} from '../create-request-dialog/create-request-dialog.component';
import {DealProcessingService} from '../../../../deal-processing.service';
import {NotificationsServiceZipi} from '../../../../../../../notifications/notifications.service';
import {DealFinancialApiService} from '../../../../deal-financial.api.service';
import {IDealDeposit, IDealDepositRequest} from '@cyberco-nodejs/zipi-typings';
import {ResendRequestDialogComponent} from '../resend-request-dialog/resend-request-dialog.component';
import {ConfirmComponent} from '../../../../../../../../layouts/confirm/confirm.component';

@Component({
    selector: 'app-deposit-request-list',
    templateUrl: './deposit-request-list.component.html',
    styleUrls: ['./deposit-request-list.component.scss']
})
export class DepositRequestListComponent implements OnInit, OnDestroy, OnChanges {
    private unsubscribe: Subject<void> = new Subject();

    @Input() requestsList: IDealDepositRequest[] = [];
    @Input() dealId: number | undefined;
    @Input() dealDepositAmounts: IDealDeposit | undefined;
    @Input() disabled: boolean = false;
    @Input() division_id: number | undefined;
    @Output() refreshRequests = new EventEmitter();

    dataSourceRequest: MatTableDataSource<any>;
    displayedRequestColumns: string[] = [
        'request_date',
        'email',
        'product',
        'amount',
        'balance',
        'due_date',
        'status',
        'action'
    ];

    constructor(
        public dialog: MatDialog,
        private notificationServiceZipi: NotificationsServiceZipi,
        private dealFinancialApiService: DealFinancialApiService,
        public dealProcessingService: DealProcessingService
    ) {
        this.dataSourceRequest = new MatTableDataSource<any>([]);
    }

    ngOnInit() {
        this.dataSourceRequest.data = this.requestsList;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('requestsList')) {
            this.dataSourceRequest.data = changes.requestsList.currentValue;
        }
    }

    addDepositRequest() {
        if (!this.dealId) {
            this.notificationServiceZipi.addError(`To add Deposit Request save Deal firstly.`);
            return;
        }
        const dialogRef = this.dialog.open(CreateRequestDialogComponent, {
            minWidth: '700px',
            data: {}
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((requestData) => {
                requestData.deal_fk_id = this.dealId;
                requestData.division__company_group_fk_id = this.division_id || null;

                this.dealFinancialApiService
                    .createDealDepositRequest(this.dealId!, requestData)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((response) => {
                        if (response) {
                            this.refreshRequests.emit(true);
                        }
                    });
            });
    }

    resendRequest(depositRequest: IDealDepositRequest) {
        const dialogRef = this.dialog.open(ResendRequestDialogComponent, {
            minWidth: '500px',
            // minHeight: '400px',
            data: {
                depositRequest: depositRequest
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((resendSettings) => {
                this.dealFinancialApiService
                    .resendDealDepositRequests(depositRequest.deposit_request_id!, resendSettings)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((response) => {
                        if (response) {
                            this.refreshRequests.emit(true);
                        }
                    });
            });
    }

    deleteRequest(depositRequest: IDealDepositRequest) {
        if (depositRequest.payments && depositRequest.payments.length > 0) {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                width: '400px',
                // minHeight: '400px',
                data: {
                    title: 'Deleting Deposit Request',
                    message: `The Deposit Request has associated Payments. To delete Deposit Request you must first delete all Payments.`,
                    hideCancel: true
                }
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((ok) => {});
        } else {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                minWidth: '300px',
                // minHeight: '400px',
                data: {
                    title: 'Deleting Deposit Request',
                    message: 'Deposit Request will be deleted'
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((ok) => {
                    if (ok) {
                        this.dealFinancialApiService
                            .deleteDealDepositRequests(depositRequest.deposit_request_id!)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((response) => {
                                if (response) {
                                    this.refreshRequests.emit(true);
                                }
                            });
                    }
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.refreshRequests.complete();
        this.dataSourceRequest.disconnect();
    }
}
