import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../../../services/service.requester';
import {from, Observable} from 'rxjs';
import {IScrollData} from '../../../../../models/scroll-data';
import {
    IDataToBeProcessed,
    IRemainingPayoutsListResponseData,
    IRemainingPayoutsWithAdditionalInfo
} from '../../remaining-payouts/types/remaining-payouts.types';

@Injectable()
export class RemainingPayoutsApiService {
    private url = '/remaining-payouts';

    constructor(public requester: ServiceRequester) {}

    getRemainingPayouts(params: IScrollData): Observable<IRemainingPayoutsListResponseData> {
        return from(this.requester.makeMsCall(this.url, 'GET', params));
    }

    getRemainingPayoutsInfo(ids: Array<number>): Observable<IRemainingPayoutsWithAdditionalInfo[]> {
        return from(this.requester.makeMsCall(this.url + '/info', 'GET', {ids: ids}));
    }

    startPayoutsProcessing(
        data: IDataToBeProcessed
    ): Observable<{status: string; payouts_in_processing: Array<number>}> {
        return from(this.requester.makeMsCall(this.url + '/start-processing-bills', 'POST', data));
    }
}
