import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Deal} from '../../../../../../../models/deal';

@Component({
    selector: 'app-view-transaction-deal',
    styleUrls: ['../view-deal.component.scss'],
    templateUrl: 'transaction-deal.component.html'
})
export class ViewTransactionDealComponent implements OnInit, OnDestroy {
    @Input() isUiModsPending = false;
    @Input() deal: Deal | undefined = undefined;
    DEAL = Deal;

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
