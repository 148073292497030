import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';

import {RbacService} from 'app/modules/rbac/rbac.service';
import {AuthService} from '../auth.service';
import {SkyslopeAuth} from '../../modules/auth/services/okta/skyslope-auth.service';

const NOT_FOUND_PAGE_URL = '/default-page';

@Injectable()
export class AccessGuardService implements CanActivate {
    constructor(
        private rbacService: RbacService,
        private authService: AuthService,
        private router: Router,
        private skyslopeAuthService: SkyslopeAuth
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        const isOktaAuthenticated = await this.skyslopeAuthService.isAuthenticated();
        if (!isOktaAuthenticated) {
            // If not authenticated, then update auth state to see if a session is available.
            const oktaAuth = await this.skyslopeAuthService.updateAuthState();
        }

        return this.authService
            .loadAuth()
            .then(async (isUserLoaded) => {
                if (isUserLoaded) {
                    if (route.data && route.data.access) {
                        if (route.data.access.rule) {
                            if (!(await this.rbacService.isAllowed(route.data.access.rule))) {
                                this.router.navigate([NOT_FOUND_PAGE_URL]);
                            }
                        } else if (route.data.access.anyRule) {
                            if (!(await this.rbacService.isAllowedAny(route.data.access.anyRule))) {
                                this.router.navigate([NOT_FOUND_PAGE_URL]);
                            }
                        }
                    }
                }
                return true;
            })
            .catch((err) => {
                console.error(err);
            });
    }
}
