<div class="mat-tab-background">
    <p
        *ngIf="dataSource.data.length === 0"
        class="text-muted h-50 d-flex flex-column align-items-center justify-content-around"
    >
        No internal transactions.
    </p>

    <table
        mat-table
        [dataSource]="dataSource"
        [ngClass]="{'d-none': dataSource.data.length === 0}"
        matSort
        matSortDisableClear
        [matSortActive]="scrollData.sort_column"
        [matSortDirection]="scrollData.sort_direction"
        (matSortChange)="sortData($event)"
        class="mat-elevation-z8 transactions-table w-100"
    >
        <ng-container matColumnDef="journal_date">
            <th mat-header-cell *matHeaderCellDef disableClear mat-sort-header>Date</th>
            <td mat-cell *matCellDef="let element" class="col-date">
                <span *ngIf="element?.journal_date">{{
                    element?.journal_date | dateFromNumber | date: 'mediumDate'
                }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="reason_type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let element" class="col-type">
                {{ element?.journal_reason_type | fromSnakeCase }}
            </td>
        </ng-container>

        <ng-container matColumnDef="contact_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact</th>
            <td mat-cell *matCellDef="let element" class="col-type" (click)="$event.preventDefault()">
                <a routerLink="/contacts/{{ element?.contact_fk_id }}">{{ element?.contact_name }}</a>
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element" class="col-description">{{ element?.description }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element" class="col-status">
                <mat-icon
                    *ngIf="element?.reconciliation_fk_id"
                    style="font-size: 1em; height: 1em; width: 1em; margin-right: 0.5em"
                    matTooltip="Reconciled"
                >
                    done
                </mat-icon>
                {{ element?.matched__transaction_external_id ? 'Matched' : 'Not Matched' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="debit">
            <th mat-header-cell *matHeaderCellDef class="col-debit-header">
                {{ ledgerAccount && ledgerAccount.type === 'credit_card' ? 'Payments/Credits' : 'Deposit' }}
            </th>
            <td mat-cell *matCellDef="let element" class="col-debit">
                <span *ngIf="element?.debit_or_credit === 'debit'">
                    {{ element?.amount | currency: 'USD' : 'symbol' }}
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="credit">
            <th mat-header-cell *matHeaderCellDef class="col-debit-header">
                {{ ledgerAccount && ledgerAccount.type === 'credit_card' ? 'Purchases' : 'Withdrawal' }}
            </th>
            <td mat-cell *matCellDef="let element" class="col-credit">
                <span *ngIf="element?.debit_or_credit === 'credit'">
                    {{ element?.amount | currency: 'USD' : 'symbol' }}
                </span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
            mat-row
            *matRowDef="let element; columns: displayedColumns"
            [ngClass]="{selected: selectedTransaction?.transaction_id === element.transaction_id}"
            (click)="toggleTransaction.emit(element)"
            class="banking-row"
        ></tr>
    </table>
    <mat-paginator
        class="mat-paginator-sticky"
        [ngClass]="{'d-none': dataSource.data.length === 0}"
        [length]="listLength"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        showFirstLastButtons="false"
    ></mat-paginator>
</div>
