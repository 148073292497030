import {Company} from '../../../../models/company';
import {Group} from '../../../../models/group';
import {Profile} from '../../../../models/profile';

export class OverheadModel {
    id: number | null = null;
    company: Company | null = null;
    company_group: Group | null = null;
    profile: Profile | null = null;
    title: string = '';

    type: string = OverheadModel.type_SET.overhead;

    amount: number | null = null;
    period: string | null = null;

    static get period_set() {
        return {
            year: 'year',
            month: 'month',
            deal: 'deal'
        };
    }

    static get type_SET() {
        return {
            overhead: 'overhead',
            incentive: 'incentive'
        };
    }
}
