<div class="row content">
    <div class="col-xl-4 left-side">
        <mat-button-toggle-group [vertical]="true" [value]="activePanel" (change)="onSelectOptions($event)">
            <mat-button-toggle
                *ngFor="let item of reportOptions"
                [value]="item.value"
                (change)="onSelectOptions($event)"
            >
                {{ item.title }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="col-xl-8 right-side">
        <ng-container *ngIf="reportSettings">
            <div *ngIf="activePanel === 'time_period'">
                <app-report-edit-bar-time-period
                    [value]="{
                        time_period: reportSettings.time_period,
                        time_period_start: reportSettings.time_period_start,
                        time_period_end: reportSettings.time_period_end
                    }"
                    (valueChanges)="onValueChanges('time_period', $event)"
                >
                </app-report-edit-bar-time-period>
            </div>

            <div *ngIf="activePanel === 'entity'" class="content-item">
                <h4>Participants</h4>
                <app-report-edit-bar-entity
                    [value]="reportSettings.entities"
                    [entityTypeList]="['contact', 'contact_class', 'individuals_in_group']"
                    (valueChanges)="onValueChanges('entities', $event)"
                >
                </app-report-edit-bar-entity>
            </div>

            <div *ngIf="activePanel === 'ten99_forms'">
                <app-report-edit-bar-button-list
                    [multiple]="true"
                    [list]="listOfTen99Forms"
                    [value]="reportSettings.ten99_forms"
                    (valueChanges)="onValueChanges('ten99_forms', $event)"
                >
                </app-report-edit-bar-button-list>
            </div>

            <div *ngIf="activePanel === 'pdf_generation'" class="content-item">
                <div *ngIf="ten99FormGroup" class="m-2 pr-4" [formGroup]="ten99FormGroup">
                    <h4 class="title">Pdf Generation Options</h4>
                    <mat-form-field class="w-100 mt-2">
                        <input
                            matInput
                            type="number"
                            min="1"
                            max="20"
                            placeholder="Count Contact In Pdf File"
                            formControlName="count_contact_in_pdf_file"
                        />
                        <ng-container
                            *ngIf="
                                ten99FormGroup.controls &&
                                ten99FormGroup.controls.count_contact_in_pdf_file &&
                                ten99FormGroup.controls.count_contact_in_pdf_file.errors
                            "
                        >
                            <mat-error
                                align="start"
                                *ngIf="ten99FormGroup.controls.count_contact_in_pdf_file.errors.max"
                            >
                                Maximum value can be 20
                            </mat-error>
                            <mat-error
                                align="start"
                                *ngIf="ten99FormGroup.controls.count_contact_in_pdf_file.errors.min"
                            >
                                Minimum value can be 1
                            </mat-error>
                        </ng-container>
                    </mat-form-field>
                </div>

                <div class="m-2 mt-3">
                    <h5 class="title">Hide TIN data in Pdf page</h5>
                    <app-report-edit-bar-button-list
                        [list]="listOfYesNoAnswer"
                        [value]="reportSettings.is_hide_tin_data_in_pdf"
                        (valueChanges)="onValueChanges('is_hide_tin_data_in_pdf', $event)"
                    >
                    </app-report-edit-bar-button-list>
                </div>
            </div>
        </ng-container>

        <div *ngIf="report">
            <app-report-edit-bar-action
                [reportId]="report.id"
                [isShowNext]="isShowNext"
                [enableSaveAndRun]="true"
                (action)="onAction($event)"
            >
            </app-report-edit-bar-action>
        </div>
    </div>
</div>
