<table class="report-table" *ngIf="reportData.length">
    <thead>
        <tr>
            <th>
                <button
                    class="icon-button"
                    [matTooltip]="isExpanded ? 'Collapse All' : 'Expand All'"
                    (click)="toggleExpandAllSections()"
                >
                    <mat-icon>{{ isExpanded ? 'unfold_less' : 'unfold_more' }}</mat-icon>
                </button>
            </th>
            <th>Ranking</th>
            <th>Agent</th>
            <th>Anniversary Year</th>
            <th>Office</th>
            <th>Address</th>
            <th>Total Count</th>
            <th class="amount-text"><span>Agent Volume</span></th>
            <th class="amount-text"><span>Company Split</span></th>
            <th class="amount-text"><span>Agent Split</span></th>
            <th class="amount-text"><span>Agent Net</span></th>
            <th class="amount-text"><span>Avg Comm %</span></th>
        </tr>
    </thead>

    <tbody>
        <ng-container *ngFor="let agent of reportData">
            <tr class="summary-row" (click)="toggleExpandSection(agent)">
                <td>
                    <button class="material-icons icon-button" (click)="toggleExpandSection(agent, $event)">
                        <ng-container>
                            {{ agent.is_expanded ? 'expand_more' : 'chevron_right' }}
                        </ng-container>
                    </button>
                </td>
                <td>
                    <!-- Ranking -->
                    {{ agent.rank }}
                </td>
                <td>{{ agent.first_name }} {{ agent.last_name }}</td>
                <td>{{ agent.anniversary_date }}</td>
                <td>
                    <!-- Office Name -->
                </td>
                <td>
                    <!-- Address -->
                </td>

                <td>
                    <!-- Total Count -->
                    <div class="count-val">
                        {{ agent.total_count | number: '1.0-2' }}
                    </div>
                </td>

                <td class="amount-text" [ngClass]="{'negative-value': agent.sum_volume < 0}">
                    <!-- Agent Volume -->
                    <span>${{ agent.sum_volume | reportFormatted: 'financial' }}</span>
                </td>
                <td class="amount-text">
                    <!-- Company Split -->
                    <span>${{ agent.company_split | reportFormatted: 'financial' }}</span>
                </td>
                <td class="amount-text" [ngClass]="{'negative-value': agent.compensation_split_sum < 0}">
                    <!-- Agent Split -->
                    <span>${{ agent.compensation_split_sum | reportFormatted: 'financial' }}</span>
                </td>
                <td class="amount-text">
                    <!-- Agent Net -->
                    <span>${{ agent.agent_net | reportFormatted: 'financial' }}</span>
                </td>
                <td class="amount-text" [ngClass]="{'negative-value': agent.avg_commission_percent < 0}">
                    <!-- Avg Comm % -->
                    <span>{{ agent.avg_commission_percent | reportFormatted: 'financial' }}%</span>
                </td>
            </tr>

            <ng-container *ngIf="agent.is_expanded">
                <tr *ngFor="let deal of agent.deals">
                    <td>
                        <!-- Expand/collapse -->
                    </td>
                    <td>
                        <!-- Ranking -->
                    </td>
                    <td>
                        <!-- Agent Name -->
                    </td>
                    <td>
                        <!-- Anniversary -->
                    </td>
                    <td>
                        {{ deal.agent_office_name }}
                    </td>
                    <td>{{ deal.address }}</td>

                    <!-- Total count -->
                    <td class="d-flex flex-wrap">
                        <div *ngIf="deal.count_seller > 0">
                            <span class="count-val">{{ deal.count_seller }}</span>
                            <span class="text-sm">Listing (Sale)</span>
                        </div>
                        <div *ngIf="deal.count_buyer > 0">
                            <span class="count-val">{{ deal.count_buyer }}</span>
                            <span class="text-sm">Buyer (Sale)</span>
                        </div>
                        <div *ngIf="deal.count_rental_tenant > 0">
                            <span class="count-val">{{ deal.count_rental_tenant }}</span>
                            <span class="text-sm">Tenant (Rental)</span>
                        </div>
                        <div *ngIf="deal.count_rental_owner > 0">
                            <span class="count-val">{{ deal.count_rental_owner }}</span>
                            <span class="text-sm">Landlord (Rental)</span>
                        </div>
                        <div *ngIf="deal.count_referral > 0">
                            <span class="count-val">{{ deal.count_referral }}</span>
                            <span class="text-sm">Referral</span>
                        </div>
                        <div *ngIf="deal.count_broker_price_opinion > 0">
                            <span class="count-val">{{ deal.count_broker_price_opinion }}</span>
                            <span class="text-sm">Broker Price Opinion</span>
                        </div>
                    </td>

                    <td class="amount-text" [ngClass]="{'negative-value': deal.sum_volume < 0}">
                        <!-- Agent Volume -->
                        <span>${{ deal.sum_volume | reportFormatted: 'financial' }}</span>
                    </td>
                    <td class="amount-text" [ngClass]="{'negative-value': deal.company_split < 0}">
                        <!-- Company Split -->
                        <span>${{ deal.company_split | reportFormatted: 'financial' }}</span>
                    </td>
                    <td class="amount-text" [ngClass]="{'negative-value': deal.compensation_split_sum < 0}">
                        <!-- Agent Split -->
                        <span>${{ deal.compensation_split_sum | reportFormatted: 'financial' }}</span>
                    </td>
                    <td class="amount-text" [ngClass]="{'negative-value': deal.agent_net < 0}">
                        <!-- Agent Net -->
                        <span>${{ deal.agent_net | reportFormatted: 'financial' }}</span>
                    </td>
                    <td class="amount-text" [ngClass]="{'negative-value': deal.avg_commission_percent < 0}">
                        <!-- Avg Comm % -->
                        <span>{{ deal.avg_commission_percent | reportFormatted: 'financial' }}%</span>
                    </td>
                </tr>
            </ng-container>
        </ng-container>
    </tbody>
</table>

<div *ngIf="!reportData.length">
    <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
</div>
