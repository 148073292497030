import {Injectable} from '@angular/core';
import {ServiceRequester} from '../service.requester';
import {Observable} from 'rxjs';
import {ITag, ITagCategory} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class TagsService {
    private url = '/tagging/';

    constructor(protected requester: ServiceRequester) {}

    /** Use store */
    getTagCategories(): Observable<{result: ITagCategory[]}> {
        return this.requester.makeMsCall$(this.url + '', 'GET', 'zipi');
    }

    /** Use store */
    createTagCategory(data: ITagCategory): Observable<{result: ITagCategory}> {
        return this.requester.makeMsCall$(this.url + 'category', 'POST', 'zipi', data);
    }

    /** Use store */
    updateTagCategory(data: {tag_category: ITagCategory; removed_tags: number[]}): Observable<{
        result: {
            category: ITagCategory;
            errorMessage: string[];
            deactivatedIds: string[];
        };
    }> {
        return this.requester.makeMsCall$(this.url + 'category', 'PUT', 'zipi', data);
    }

    /** Use store */
    deleteTagCategory(tagCategoryId: number): Observable<{result: number}> {
        return this.requester.makeMsCall$(this.url + `category/${tagCategoryId}`, 'DELETE', 'zipi');
    }

    getInvoiceTagsIds(invoice_id: number): Observable<{result: number[]}> {
        return this.requester.makeMsCall$(this.url + `invoice/${invoice_id}`, 'GET', 'zipi');
    }

    getBillTagsIds(bill_id: number): Observable<{result: number[]}> {
        return this.requester.makeMsCall$(this.url + `bill/${bill_id}`, 'GET', 'zipi');
    }

    getDealTagsIds(deal_id: number): Observable<{result: number[]}> {
        return this.requester.makeMsCall$(this.url + `deal/${deal_id}`, 'GET', 'zipi');
    }

    createTagInvoiceRelation(tag_ids: any[], invoice_id: number): Observable<{result: boolean}> {
        return this.requester.makeMsCall$(this.url + `invoice/relate/${invoice_id}`, 'POST', 'zipi', tag_ids);
    }

    createTagBillRelation(tag_ids: any[], bill_id: number): Observable<{result: boolean}> {
        return this.requester.makeMsCall$(this.url + `bill/relate/${bill_id}`, 'POST', 'zipi', tag_ids);
    }

    createTagDealRelation(tag_ids: any[], deal_id: number | null): Observable<{result: boolean}> {
        return this.requester.makeMsCall$(this.url + `deal/relate/${deal_id}`, 'POST', 'zipi', tag_ids);
    }

    deleteTaggingFromEntity(tag_id: number, deal_id: number): Observable<{result: boolean}> {
        return this.requester.makeMsCall$(
            this.url + `deal/relate?deal_id=${deal_id}&tag_id=${tag_id}`,
            'DELETE',
            'zipi'
        );
    }

    resetAutoTags(deal_id: number): Observable<{result: (ITag & {category_title: string; tagging_id: number})[]}> {
        return this.requester.makeMsCall$(this.url + `deal/${deal_id}/reset`, 'POST', 'zipi', {});
    }

    deleteAutoTags(deal_id: number): Observable<{result: boolean}> {
        return this.requester.makeMsCall$(this.url + `deal/${deal_id}`, 'DELETE', 'zipi');
    }

    getCompanyTags(): Observable<{result: ITag[]}> {
        return this.requester.makeMsCall$(this.url + '/company-tags', 'GET', 'zipi');
    }

    deactivateTag(deactivated_tags: string[], tag_category_id: number): Observable<{result: ITagCategory}> {
        return this.requester.makeMsCall$(this.url + 'deactivate', 'PUT', 'zipi', {deactivated_tags, tag_category_id});
    }
}
