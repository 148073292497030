import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {MarketplaceSource} from '../../../../../../account-info/marketplace/marketplace.source';
import {Subject} from 'rxjs';
import {MarketplaceAddonInstanceModel} from '../../../../../../account-info/marketplace/marketplace.models';
import {UntypedFormBuilder} from '@angular/forms';
import {IReportSetting} from '@cyberco-nodejs/zipi-typings/index';

@Component({
    selector: 'app-report-addon',
    templateUrl: './report-addon.component.html',
    styleUrls: ['./report-addon.component.scss']
})
export class ReportAddonComponent implements OnInit, OnDestroy {
    @Output() valueChanges: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Input() reportSettings: IReportSetting | null = null;
    private unsubscribe: Subject<void> = new Subject();
    public listOfAddons: MarketplaceAddonInstanceModel[] = [];
    public listOfAddonsOptions: Array<{title: string; value: string}> = [];

    constructor(
        protected marketplaceSource: MarketplaceSource,
        private fb: UntypedFormBuilder
    ) {}

    /**
     * On init
     */
    ngOnInit() {
        this.marketplaceSource.addonsChangeEvent.pipe(takeUntil(this.unsubscribe)).subscribe((addons) => {
            this.listOfAddons = addons
                .filter((item) => {
                    const slug = item!.addon!.slug || '';
                    return ['dotloop_integration', 'skyslope_integration', 'zipi_financial_integration'].includes(slug);
                })
                .sort((a, b) => {
                    const compareTitle = b!.addon!.preset!.title || '';
                    return a!.addon!.preset!.title!.localeCompare(compareTitle);
                });
        });
    }

    onChange(event: any) {
        if (this.reportSettings) {
            this.reportSettings['contact_addons'] = event.value;
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.valueChanges.complete();
    }
}
