import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../../services/service.requester';
import {NotificationsServiceZipi} from '../../../notifications/notifications.service';
// import {MarketplaceAddonModel} from './marketplace.models';
import {CurrentProfileSource} from '../../../../services/sources/current-profile.source';
import {AddonInstanceModel} from '../../../profile/addons/addons.models';
// import {Group} from '../../../models/group';
// import {Profile} from '../../../models/profile';

export interface DashCompanySettingsResponse {
    error_on_fetch_company: string;
    dash_company_settings: {
        dash_company_id: string;
        dash_company_settings_id: number;
        divisions_offices_mapping: Array<{
            dash_office_guid: string;
            zipi_division_id: number;
        }>;
        id: number;
        zipi_company_id: number;
    };
    dash_company_with_offices: {
        companyGuid: string;
        companyId: string;
        companyName: string;
        brandCode: string;
        candoCommercialBusiness: boolean;
        providerIdentifier: string;
        isNrt: boolean;
        lastUpdateOn: string;
        lastUpdatedBy: string;
        offices: Array<{
            officeGuid: string;
            officeId: string;
            officeName: string;
            doingBusinessAs: string;
            isOpen: boolean;
            designationCode: string;
            designationDescription: string;
            physicalAddress: {
                district: string;
                latitude: number;
                longitude: number;
                addressLine1: string;
                city: string;
                postalCode: string;
                stateProvinceCode: string;
                stateProvinceName: string;
                countryCode: string;
                countryName: string;
            };
            primaryEmailAddress: string;
            primaryPhoneNumber: string;
            primaryWebsite: string;
            faxNumber: string;
        }>;
    };
    addon_instance: AddonInstanceModel;
}

@Injectable()
export class DashAddonApi {
    constructor(
        public requester: ServiceRequester,
        protected notificationsService: NotificationsServiceZipi,
        protected currentProfileSource: CurrentProfileSource
    ) {}

    loadDashAddonCompanySettings(): Promise<DashCompanySettingsResponse> {
        return this.requester.makeMsCall('/dash-addon/company-settings', 'GET');
    }

    updateDashAddonCompanySettings(data: any): Promise<boolean> {
        return this.requester.makeMsCall('/dash-addon/company-settings', 'PUT', data);
    }
}
