import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {Observable} from 'rxjs';
import {IBill, ICompany, IContact, IInvoice, IPaymentMethod, IProfile, IUser} from '@cyberco-nodejs/zipi-typings';
import {IPublicAuthorizePaymentObj} from '../../../typings/public-invoice';

export type PublicInvoice = IInvoice & {pre_statement?: boolean};

@Injectable()
export class InvoicePublicService {
    constructor(protected requester: ServiceRequester) {}

    getInvoiceByHash(invoiceHash: string): Observable<IInvoice> {
        return this.requester.makeMsCall$(`/public/invoice/${invoiceHash}`, 'GET', 'shipp');
    }

    getInfoForPublicInvoicePageByInvoiceHash(
        invoiceHash: string
    ): Observable<{profile: IProfile; invoice: IInvoice & {is_escrow: boolean}; zipi_fin_customer_ref: string}> {
        return this.requester.makeMsCall$(`/public/invoice/init/${invoiceHash}`, 'GET', 'shipp');
    }

    getInfoForPublicStatementPageByHash(statementHash: string): Observable<{
        profile: IProfile;
        invoices: PublicInvoice[];
        zipi_fin_customer_ref: string;
        statement: Record<string, any>;
        company_title: string;
        previous_balance: number | null;
    }> {
        return this.requester.makeMsCall$(`/public/invoice-statement/init/${statementHash}`, 'GET', 'shipp');
    }

    getBillByHash(invoiceHash: string): Observable<IBill> {
        return this.requester.makeMsCall$(`/public/bill/${invoiceHash}`, 'GET', 'shipp');
    }

    getInvoiceMethods(methodsIds: Array<number | null>): Observable<IPaymentMethod[]> {
        return this.requester.makeMsCall$(`/public/invoice/methods`, 'POST', 'shipp', methodsIds);
    }

    getInvoiceMethod(methodId: number): Observable<IPaymentMethod> {
        return this.requester.makeMsCall$(`/public/invoice/method/${methodId}`, 'GET', 'shipp');
    }

    getPublicKey(companyGatewayId: number): Observable<string> {
        return this.requester.makeMsCall$(`/public/invoice/public-key/${companyGatewayId}`, 'GET', 'shipp');
    }

    getPayloadSettings(receiverMethodId: number, invoiceId: number): Observable<{token: string}> {
        return this.requester.makeMsCall$(
            `/public/invoice/${invoiceId}/payload-settings/${receiverMethodId}`,
            'GET',
            'shipp'
        );
    }

    getPayloadSettingsForStatement(
        paymentData: {[key: string]: any},
        statementHash: string
    ): Observable<{token: string}> {
        return this.requester.makeMsCall$(
            `/public/invoice-statement/${statementHash}/payload-settings`,
            'POST',
            'shipp',
            paymentData
        );
    }

    getPublicContact(contactId: number): Observable<IContact> {
        return this.requester.makeMsCall$(`/public/invoice/public-contact/${contactId}`, 'GET', 'shipp');
    }

    getPublicOwnerContactZipiFinancialSettingsByContactId(
        contactId: number
    ): Observable<{target_contact_id: number; customer_ref: string}> {
        return this.requester.makeMsCall$(`/public/invoice/public-owner-contact-settings/${contactId}`, 'GET', 'shipp');
    }

    getContactPaymentMethods(contactId: number): Observable<IPaymentMethod[]> {
        return this.requester.makeMsCall$(`/public/invoice/contactmethods/${contactId}`, 'GET', 'shipp');
    }

    createBill(invoice_id: number): Observable<IBill> {
        return this.requester.makeMsCall$(`/public/invoice/${invoice_id}/bill`, 'POST', 'shipp');
    }

    checkExistCompanies(email: string): Observable<ICompany[]> {
        return this.requester.makeMsCall$(`/public/invoice/check-contact`, 'POST', 'shipp', {email: email});
    }

    isAbleToAssociate(invoiceId: number, companyIds: Array<number>): Observable<{[key: number]: number}> {
        return this.requester.makeMsCall$(`/public/invoice/${invoiceId}/able-associate`, 'POST', 'shipp', companyIds);
    }

    getUserByEmail(email: string): Observable<IUser> {
        return this.requester.makeMsCall$(`/public/invoice/check-user`, 'POST', 'shipp', {email: email});
    }

    payInvoice(
        invoiceId: number,
        payData: {note: string; amount: number; paid_by__payment_method_fk_id: number}
    ): Observable<boolean> {
        return this.requester.makeMsCall$(`/public/invoice/pay/${invoiceId}`, 'POST', 'shipp', payData);
    }

    publicAuthorizeJustPay(payObj: IPublicAuthorizePaymentObj): Observable<{error: string}> {
        return this.requester.makeMsCall$(`/public/invoice/pay/authorize/just`, 'POST', 'shipp', payObj);
    }

    publicAuthorizePayWithSave(payObj: IPublicAuthorizePaymentObj): Observable<{error: string}> {
        return this.requester.makeMsCall$(`/public/invoice/pay/authorize/save`, 'POST', 'shipp', payObj);
    }

    publicPayloadCoPay(payObj: any): Observable<{error: string}> {
        return this.requester.makeMsCall$(`/public/invoice/pay/payload-co`, 'POST', 'shipp', payObj);
    }
    publicPayloadCoInvoiceStatementPay(payObj: any): Observable<{error: string}> {
        return this.requester.makeMsCall$(`/public/invoice-statement/pay/payload-co`, 'POST', 'shipp', payObj);
    }
}
