<mat-sidenav-container [hasBackdrop]="true" class="sidenav-container" (backdropClick)="close()">
    <mat-sidenav
        #sidenavGoals
        mode="over"
        disableClose
        [position]="'end'"
        style="width: 1100px; max-width: 90%; padding: 0"
    >
        <mat-tab-group style="height: 100%">
            <mat-tab label="My goals">
                <app-profile-goals (closeSidebar)="close()"></app-profile-goals>
            </mat-tab>
            <mat-tab label="Groups" *ngIf="haveAccessManageGroups">
                <app-goals-manage (closeSidebar)="close()"></app-goals-manage>
            </mat-tab>
        </mat-tab-group>
    </mat-sidenav>
    <ng-content></ng-content>
</mat-sidenav-container>
