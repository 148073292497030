<section class="bg-white">
    <!------- Header ------->
    <header class="d-flex align-items-center px-3 list-header">
        <h3 class="m-0">Expenses</h3>

        <i class="sp-spacer"></i>

        <button
            type="button"
            [rule]="{purchases__manage_expenses: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            mat-raised-button
            color="primary"
            routerLink="/purchases/expenses/create"
        >
            <mat-icon>add</mat-icon>
            Create
        </button>
    </header>

    <!------- List of expenses ------->
    <div class="table-background">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="expense_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Expense Date</th>
                <td mat-cell *matCellDef="let element" class="w-10">
                    {{ element.expense_date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="expense_account_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-30 pl-1">Expense Account</th>
                <td mat-cell *matCellDef="let element" class="w-30 px-1">
                    {{ element.expense_account_name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="vendor_contact_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-30 pl-1">Vendor Name</th>
                <td mat-cell *matCellDef="let element" class="w-30 px-1">
                    {{ element.vendor_contact_name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="paid_through_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20 pl-1">Paid Through</th>
                <td mat-cell *matCellDef="let element" class="w-20 px-1">
                    {{ element.paid_through_name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 table-header-align-right">Amount</th>
                <td mat-cell *matCellDef="let element" class="w-10 text-right" style="padding-right: 40px">
                    {{ element.amount | currency }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="row.id" class="zp-table-row"></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 text-center no-items">
            There are no expenses.
        </div>
    </div>
</section>
