<mat-sidenav-container (backdropClick)="close()" class="h-100" style="background-color: unset">
    <mat-sidenav #sidenavJournal mode="over" [position]="'end'" [autoFocus]="false" style="height: auto">
        <mat-card [formGroup]="journalFormGroup" class="m-3">
            <div class="row m-0 w-100" style="overflow-x: hidden">
                <div class="col-12">
                    <h3 *ngIf="journal">Edit Journal</h3>
                </div>

                <div class="col-sm-6">
                    <mat-form-field class="w-50">
                        <mat-label>Journal#</mat-label>
                        <input matInput type="text" formControlName="journal_number" required autocomplete="off" />
                    </mat-form-field>

                    <app-date-picker
                        class="w-50 d-inline-block"
                        [placeholder]="'Date'"
                        [dateControl]="journalFormGroup.controls.date"
                        [disabled]="isThereMatchedTransaction"
                    ></app-date-picker>
                </div>
                <div class="col-sm-6 d-flex align-items-center">
                    <div class="w-100 d-flex justify-content-between">
                        <label>Journal Type:</label>
                        <mat-radio-group formControlName="type" [disabled]="isThereMatchedTransaction">
                            <mat-radio-button value="cash">Cash</mat-radio-button>
                            <mat-radio-button value="both">Both</mat-radio-button>
                        </mat-radio-group>
                        <mat-icon
                            matTooltip="Choose the 'Cash' option to make this journal entry appear only in the reports
                        that are generated on cash basis. If not, this transaction will appear in both the accrual
                         and cash basis reports."
                            >error_outline
                        </mat-icon>
                    </div>
                </div>
            </div>
            <div class="row m-0 w-100" style="overflow-x: hidden">
                <div class="col-sm-6">
                    <mat-form-field class="w-100">
                        <mat-label>Reference #</mat-label>
                        <input type="text" matInput formControlName="reference_number" autocomplete="off" />
                    </mat-form-field>

                    <!--                    <div class="w-100">-->
                    <!--                        <input #journalFromCsv type="file" accept=".csv"-->
                    <!--                               (change)="handleFileInput(journalFromCsv.files)"-->
                    <!--                               hidden/>-->
                    <!--                        <button mat-raised-button disabled (click)="journalFromCsv.click()">-->
                    <!--                            <mat-icon>attach_file</mat-icon>-->
                    <!--                            Attach file-->
                    <!--                        </button>-->
                    <!--                    </div>-->
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="w-100">
                        <mat-label>Notes</mat-label>
                        <textarea type="text" matInput formControlName="notes" [rows]="1"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="w-100 transactions-wrapper">
                <div class="items-header__row">
                    <div class="items-header__col account">Account</div>
                    <div class="items-header__col description">Description</div>
                    <div class="items-header__col division">Division</div>
                    <div class="items-header__col amount">Debit</div>
                    <div class="items-header__col amount">Credit</div>
                </div>

                <div formArrayName="line_items">
                    <div *ngFor="let item of $any(journalFormGroup.controls.line_items).controls; let i = index">
                        {{ item.controls?.matched_transaction_external_id?.value }}
                        <div class="items__row" [formGroupName]="i">
                            <div class="items__col account">
                                <app-ledger-account-selector
                                    #ledgerAccountSelector
                                    class="w-100"
                                    [required]="true"
                                    [disabled]="!!item.controls?.matched_transaction_external_id?.value"
                                    [disabledReselect]="!!item.controls?.matched_transaction_external_id?.value"
                                    [floatLabel]="'never'"
                                    [ledgerAccountFullControl]="item.get('selected_full_ledger')"
                                    [ledgerAccountControl]="item.get('ledger_account_fk_id')"
                                    [isInactiveSelectable]="true"
                                    [disabledAccountSystemKeys]="[]"
                                >
                                </app-ledger-account-selector>
                            </div>
                            <div class="items__col description">
                                <mat-form-field class="w-100">
                                    <textarea type="text" formControlName="description" matInput [rows]="1"> </textarea>
                                </mat-form-field>
                            </div>
                            <div class="items__col division">
                                <div class="w-100">
                                    <app-division-picker
                                        [isNeedToBeFiltered]="
                                            item.get('selected_full_ledger').value
                                                ? !item.get('selected_full_ledger').value.is_accessible_for_all
                                                : false
                                        "
                                        [filterById]="
                                            item.get('selected_full_ledger').value
                                                ? item.get('selected_full_ledger').value.accessible_for
                                                : []
                                        "
                                        [nodesFA]="item.get('selected_divisions')"
                                        [initialGroupId]="item.get('selected_division_ids').value"
                                        [isTotallyDisabled]="!item.get('ledger_account_fk_id').value"
                                        [placeholder]="''"
                                    >
                                    </app-division-picker>
                                </div>
                            </div>
                            <div class="items__col amount">
                                <mat-form-field class="w-100">
                                    <input
                                        (blur)="onType(i, 'debit', $event)"
                                        [disabled]="!!item.controls?.matched_transaction_external_id?.value"
                                        [maskito]="currencyMaskitoMask"
                                        matInput
                                        type="text"
                                        formControlName="debit"
                                        autocomplete="off"
                                    />
                                </mat-form-field>
                            </div>
                            <div class="items__col amount">
                                <mat-form-field class="w-100">
                                    <input
                                        (blur)="onType(i, 'credit', $event)"
                                        [disabled]="!!item.controls?.matched_transaction_external_id?.value"
                                        [maskito]="currencyMaskitoMask"
                                        matInput
                                        type="text"
                                        formControlName="credit"
                                        autocomplete="off"
                                    />
                                </mat-form-field>
                            </div>
                            <div class="items__col edit">
                                <button
                                    type="button"
                                    mat-icon-button
                                    (click)="deleteTransaction(i)"
                                    [disabled]="
                                        $any(journalFormGroup.controls.line_items).controls.length <= 2 ||
                                        !!item.controls?.matched_transaction_external_id?.value
                                    "
                                >
                                    <mat-icon>remove_circle_outline</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="items__row footer">
                    <div class="items__col add-line">
                        <button mat-raised-button class="fix-focus-outline" (click)="addTransactions()">
                            Add line
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                    <div class="items__col total align-items-center">Total</div>
                    <div class="items__col align-items-center amount">
                        {{ total_debit | currency: 'USD' : 'symbol' }}
                    </div>
                    <div class="items__col align-items-center amount">
                        {{ total_credit | currency: 'USD' : 'symbol' }}
                    </div>
                    <div class="items__col edit"></div>
                </div>
            </div>
        </mat-card>

        <div class="w-100 d-flex px-3" style="margin-bottom: 110px">
            <button mat-raised-button color="warn" *ngIf="journal?.journal_id" (click)="deleteJournal()">Delete</button>
            <i class="sp-spacer"></i>
            <button mat-raised-button class="ml-2" (click)="close()">Cancel</button>

            <div *ngIf="!journal" class="d-flex">
                <button mat-raised-button class="ml-2" (click)="createJournal('draft')">Save draft</button>
                <div
                    class="ml-2"
                    matTooltip="Debits and Credits must be equal or not zero amount expected"
                    [matTooltipDisabled]="isDebitCreditEqual"
                >
                    <button
                        mat-raised-button
                        color="primary"
                        [disabled]="!isDebitCreditEqual"
                        (click)="createJournal('published')"
                    >
                        Create
                    </button>
                </div>
            </div>
            <div *ngIf="journal?.journal_id" class="d-flex">
                <button
                    mat-raised-button
                    class="ml-2"
                    *ngIf="journal?.status !== 'published'"
                    (click)="updateJournal('draft')"
                >
                    Save draft
                </button>
                <div
                    class="ml-2"
                    matTooltip="Debits and Credits must be equal"
                    [matTooltipDisabled]="isDebitCreditEqual"
                >
                    <button
                        mat-raised-button
                        color="primary"
                        [disabled]="!isDebitCreditEqual"
                        (click)="updateJournal('published')"
                    >
                        Save <span *ngIf="journal?.status !== 'published'">& Publish</span>
                    </button>
                </div>
            </div>
        </div>
    </mat-sidenav>

    <ng-content></ng-content>
</mat-sidenav-container>
