import {Component, Input, OnInit} from '@angular/core';
import {transactionTypeListByName} from '../../../../constants';
import {IReportsPreviewData, IReportsPreviewResultData} from '../../../../store/reports.reducer';

@Component({
    selector: 'reports-preview-average-sales',
    templateUrl: './average-sales.component.html',
    styleUrls: ['./average-sales.component.scss']
})
export class AverageSalesReportPreviewComponent implements OnInit {
    @Input()
    /**
     * Set report data
     */
    set reportData(data: IReportsPreviewResultData[]) {
        this.overallTable = [];
        if (data && data.length) {
            data.forEach((item) => {
                switch (item.type) {
                    case transactionTypeListByName.buyer:
                        this.buyer = {
                            entities: item.entities,
                            average: item.average && item.average.length ? item.average[0] : {},
                            total: item.total && item.total.length ? item.total[0] : {}
                        };
                        break;

                    case transactionTypeListByName.seller:
                        this.seller = {
                            entities: item.entities,
                            average: item.average && item.average.length ? item.average[0] : {},
                            total: item.total && item.total.length ? item.total[0] : {}
                        };
                        break;

                    case transactionTypeListByName.referral:
                        this.referral = {
                            entities: item.entities,
                            average: item.average && item.average.length ? item.average[0] : {},
                            total: item.total && item.total.length ? item.total[0] : {}
                        };
                        break;

                    case transactionTypeListByName.rental_tenant:
                        this.rentalTenant = {
                            entities: item.entities,
                            average: item.average && item.average.length ? item.average[0] : {},
                            total: item.total && item.total.length ? item.total[0] : {}
                        };
                        break;

                    case transactionTypeListByName.rental_owner:
                        this.rentalOwner = {
                            entities: item.entities,
                            average: item.average && item.average.length ? item.average[0] : {},
                            total: item.total && item.total.length ? item.total[0] : {}
                        };
                        break;

                    case 'overallTotal':
                        if (item.entities && item.entities.length) {
                            this.overallTable.push({
                                ...item.entities[0],
                                label: 'Total',
                                order: 2
                            });
                        }
                        break;

                    case 'overallAverage':
                        if (item.entities && item.entities.length) {
                            this.overallTable.push({
                                ...item.entities[0],
                                label: 'Average',
                                order: 1
                            });
                        }
                        break;
                    default:
                }
            });

            this._reportData = data;
        }

        this.overallTable = this.overallTable.sort((a, b) => a.order - b.order);
    }

    /**
     * Get report data
     */
    get reportData() {
        return this._reportData;
    }

    _reportData: IReportsPreviewResultData[] | [] = [];

    seller: IEntity = {
        entities: [],
        total: {},
        average: {}
    };

    buyer: IEntity = {
        entities: [],
        total: {},
        average: {}
    };

    referral: {entities: Array<any>; total: any; average: any} = {
        entities: [],
        total: {},
        average: {}
    };

    rentalOwner: {entities: Array<any>; total: any; average: any} = {
        entities: [],
        total: {},
        average: {}
    };

    rentalTenant: {entities: Array<any>; total: any; average: any} = {
        entities: [],
        total: {},
        average: {}
    };
    overallTable: Array<{[key: string]: any}> = [];

    tableStructure: Array<{
        title: string;
        value: string;
        filter?: string;
    }> = [
        {
            title: 'Month',
            value: 'month'
        },
        {
            title: 'Sides',
            value: 'deals_count'
        },
        {
            title: 'Volume',
            value: 'volume',
            filter: 'currency'
        },
        {
            title: 'Comm. Exp.',
            value: 'gross_commission',
            filter: 'currency'
        },
        {
            title: 'Company Net',
            value: 'company_gross',
            filter: 'currency'
        }
    ];

    footerAverageColumns: Array<{
        value: string;
        filter?: string;
    }> = [
        {
            value: 'avg_count_deals'
        },
        {
            value: 'avg_volume',
            filter: 'currency'
        },
        {
            value: 'avg_gross_commission',
            filter: 'currency'
        },
        {
            value: 'avg_company_gross',
            filter: 'currency'
        }
    ];

    footerTotalColumns: Array<{
        value: string;
        filter?: string;
    }> = [
        {
            value: 'total_count_deals'
        },
        {
            value: 'total_volume',
            filter: 'currency'
        },
        {
            value: 'total_gross_commission',
            filter: 'currency'
        },
        {
            value: 'total_company_gross',
            filter: 'currency'
        }
    ];

    overallStructure: Array<{
        title: string;
        value: string;
        filter?: string;
    }> = [
        {
            title: 'Sides',
            value: 'deals_count'
        },
        {
            title: 'Volume Comm.',
            value: 'volume',
            filter: 'currency'
        },
        {
            title: 'Comm. Exp.',
            value: 'gross_commission',
            filter: 'currency'
        },
        {
            title: 'Company Net',
            value: 'company_gross',
            filter: 'currency'
        }
    ];

    columnsToDisplay: string[] = ['month', 'deals_count', 'volume', 'gross_commission', 'company_gross'];
    footerAverageColumnsToDisplay: string[] = [
        'avg_count_deals',
        'avg_volume',
        'avg_gross_commission',
        'avg_company_gross'
    ];
    footerTotalColumnsToDisplay: string[] = [
        'total_count_deals',
        'total_volume',
        'total_gross_commission',
        'total_company_gross'
    ];

    overallColumnsToDisplay: string[] = ['deals_count', 'volume', 'gross_commission', 'company_gross'];

    constructor() {}

    ngOnInit(): void {}

    /**
     * Get average
     * @param element
     * @param tableName
     */
    getAverage(element: string, tableName: 'buyer' | 'seller' | 'referral' | 'rentalOwner' | 'rentalTenant') {
        switch (element) {
            case 'avg_count_deals':
                return this[tableName]['average'].deals_count;
            case 'avg_volume':
                return this[tableName]['average'].volume;
            case 'avg_gross_commission':
                return this[tableName]['average'].gross_commission;
            case 'avg_company_gross':
                return this[tableName]['average'].company_gross;
            default:
                return '';
        }
    }

    /**
     * Get total cost
     * @param element
     * @param tableName
     */
    getTotalCost(element: string, tableName: 'buyer' | 'seller' | 'referral' | 'rentalOwner' | 'rentalTenant') {
        switch (element) {
            case 'total_count_deals':
                return this[tableName]['total'].deals_count;
            case 'total_volume':
                return this[tableName]['total'].volume;
            case 'total_gross_commission':
                return this[tableName]['total'].gross_commission;
            case 'total_company_gross':
                return this[tableName]['total'].company_gross;
            default:
                return '';
        }
    }
}

interface IEntity {
    entities: IReportsPreviewResultData[];
    average: IReportsPreviewResultData;
    total: IReportsPreviewResultData;
}
