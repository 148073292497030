import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {GenericFormArray, GenericFormGroup} from '../../../../../../../entites/generic.entity';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {IContact, IProduct, IWildcard} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';
import {ProductsService} from '../../../../../../../services/api/finance/products.service';
import {ContactPartLink} from '../../../../../../../models/contact-part-link';
import {UntypedFormControl, Validators} from '@angular/forms';
import {Wildcard} from '../../../../../../../models/wildcard';
import {selectWildcards} from '../../../../../../../store/company-wide/company-wide.selectors';
import {ChipNode} from '../../../../../../account-info/compensation/models/chip-node';
import {FinancialTransferEntity} from '../../../../../../account-info/compensation/models/financial-transfer.entity';
import {FinancialElementModel} from '../../../../../../account-info/compensation/models/financial-element.model';
import {Router} from '@angular/router';
import {CreateProductDialogComponent} from '../../../../../../finance/components/products/create-product-dialog/create-product-dialog.component';
import {FetchContacts} from '../../../../../../contacts/store/contacts.actions';
import {FetchProducts} from '../../../../../../finance/store/finance.actions';
import {InvoiceBillModalDialogComponent} from '../../../../../../finance/components/invoice-bill-modal-dialog/invoice-bill-modal-dialog.component';
import {selectContacts} from '../../../../../../contacts/store/contacts.selectors';
import {RbacService} from '../../../../../../rbac/rbac.service';
import {FeatureFlagsService} from '../../../../../../feature-flags/feature-flags.service';
import {cleanCurrencyString} from '../../../../../../../utilities/maskito';

// const extractContactPersons = pipe(
//     _filter((c: IContact) => c.contact_persons && c.contact_persons.length > 0),
//     _filter((c: IContact) => c.owner_type === 'company'),
//     _map<any>((contact: IContact) => {
//         return contact.contact_persons.map(p => ({
//             contact_id: contact.id,
//             link_title: p.full_name + (!!contact.company_name && contact.company_name !== p.full_name ? `, ${contact.company_name}` : ''),
//             contact_person_fk_id: p.contact_person_id
//         }));
//     }),
//     flatten,
// );

@Component({
    selector: 'app-edit-transfer',
    templateUrl: './edit-transfer.component.html',
    styleUrls: ['./edit-transfer.component.scss']
})
export class EditTransferComponent implements OnInit, OnDestroy, OnChanges {
    private unsubscribe: Subject<void> = new Subject();

    WILDCARD = Wildcard;
    senderApplyTo: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    receiverApplyTo: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    wildcardList: IWildcard[] = [];
    FINANCIAL_TRANSFER_ENTITY = FinancialTransferEntity;
    FINANCIAL_ELEMENT_ENTITY = FinancialElementModel;
    _transferFG: GenericFormGroup<FinancialTransferEntity> = new GenericFormGroup(new FinancialTransferEntity());
    disbursementInstructionsFC: UntypedFormControl = new UntypedFormControl(false);
    contributeToCapFC: UntypedFormControl = new UntypedFormControl(false);
    applyAsReceiverSplitFC: UntypedFormControl = new UntypedFormControl(false);

    // feature flag
    contributeToCapFlag: boolean = false;
    compensationExpenseEnabledFlag: boolean = false;

    @Input() hideSender: boolean = false;
    @Input() hideReceiver: boolean = false;
    @Input() hidePayAtEscrowFlag: boolean = false;
    @Input() hideDisbursementInstructionsFlag: boolean = false;
    @Input() hideProduct: boolean = false;
    @Input() hideLabel: boolean = false;
    @Input() hideAmount: boolean = false;
    @Input() hideSkip: boolean = false;
    @Input() hideValueType: boolean = false;
    @Input() hidePayAtClosing: boolean = false;
    @Input() isSalesEntityAgentCardContext: boolean = false;

    @Input() receiverLabel: string = 'Receiver Contact';
    @Input() senderLabel: string = 'Sender Contact';

    @Input() type: string = FinancialTransferEntity.type_SET.income;
    @Input() allowPercents: boolean = false;
    @Input() showFlatFeeBasedOnPercent: boolean = false;
    @Input() isExpense: boolean = false;

    @Input() disabled: boolean = false;
    @Input() disabledContact: boolean = false;

    @Input() origin: string = 'default';

    @Input() enableDisbursementInstructionsToggle: boolean = true;

    availableContributeToCapsOrigins: {[key: string]: string[]} = {
        sales: [FinancialTransferEntity.contribute_to_cap_SET.total_company_split],
        user_referral: [FinancialTransferEntity.contribute_to_cap_SET.total_company_split],
        disbursement_template: [
            // entity income/expense
            FinancialTransferEntity.contribute_to_cap_SET.total_company_split
        ],
        company_income_expense: [FinancialTransferEntity.contribute_to_cap_SET.total_company_split],
        compensation_expense: [
            FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
            FinancialTransferEntity.contribute_to_cap_SET.receiver_split
        ]
    };
    // availableIncomeCaps: {[key: string]: string[]} = {
    //     // default: [
    //     //     FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     // ],
    //     // agent: [
    //     //     FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     // ],
    //     // user_royalty: [
    //     //     FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     // ],
    //     // royalty: [
    //     //     FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     // ],
    //     sales: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     // user_referral: [
    //     //     FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     // ],
    //     // referral: [
    //     //     FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     // ],
    //     // disbursement_template: [
    //     //     FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     // ],
    //     // company_income_expense: [
    //     //     FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     // ],
    //     // company: [
    //     //     FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     // ],
    //     // participant: [
    //     //     FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     // ],
    //     // company_partner: [
    //     //     FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     // ],
    //     // company_split_individual: [
    //     //     FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     // ]
    // };
    // availableExpenseCaps: {[key: string]: string[]} = {
    //     default: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     agent: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     user_royalty: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     royalty: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     sales: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     user_referral: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     referral: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     disbursement_template: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     company_income_expense: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     company: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     participant: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     company_partner: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ],
    //     company_split_individual: [
    //         FinancialTransferEntity.contribute_to_cap_SET.total_company_split,
    //     ]
    // };

    availableExpenseTypes: {[key: string]: string[]} = {
        default: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        agent: [
            FinancialTransferEntity.value_type_SET.percent_of_adjusted_gross,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        compensation_expense: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.flat_fee,
            FinancialTransferEntity.value_type_SET.flat_fee_sales_net
        ],
        user_royalty: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_sales_total,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.flat_fee,
            FinancialTransferEntity.value_type_SET.flat_fee_sales_net
        ],
        royalty: [
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_adjusted_gross,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        sales: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_sales_entity_sales_split,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        user_referral: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        referral: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        disbursement_template: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price_by_percent_of_deal,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.flat_fee,
            FinancialTransferEntity.value_type_SET.flat_fee_sales_net
        ],
        company_income_expense: [
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_entity_split,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_company_split,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_primary_entity_split,
            FinancialTransferEntity.value_type_SET.percent_of_sales_total,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        company: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        participant: [FinancialTransferEntity.value_type_SET.percent_of_deal],
        company_partner: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_company_split,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_sales_total,
            FinancialTransferEntity.value_type_SET.percent_of_sales_net,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        company_split_individual: [FinancialTransferEntity.value_type_SET.remaining]
    };

    availableIncomeTypes: {[key: string]: string[]} = {
        default: [FinancialTransferEntity.value_type_SET.flat_fee],
        agent: [
            FinancialTransferEntity.value_type_SET.percent_of_adjusted_gross,
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        user_royalty: [
            FinancialTransferEntity.value_type_SET.percent_of_sales_price,
            FinancialTransferEntity.value_type_SET.percent_of_sales_total,
            FinancialTransferEntity.value_type_SET.percent_of_split,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        royalty: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.flat_fee
        ],
        referral: [FinancialTransferEntity.value_type_SET.flat_fee],
        sales: [FinancialTransferEntity.value_type_SET.flat_fee],
        user_referral: [FinancialTransferEntity.value_type_SET.flat_fee],
        disbursement_template: [FinancialTransferEntity.value_type_SET.flat_fee],
        company_income_expense: [FinancialTransferEntity.value_type_SET.flat_fee],
        company: [FinancialTransferEntity.value_type_SET.flat_fee],
        participant: [FinancialTransferEntity.value_type_SET.percent_of_deal],
        company_partner: [
            FinancialTransferEntity.value_type_SET.percent_of_base_commission,
            FinancialTransferEntity.value_type_SET.percent_of_deal_net,
            FinancialTransferEntity.value_type_SET.percent_of_company_split,
            FinancialTransferEntity.value_type_SET.percent_of_commission,
            FinancialTransferEntity.value_type_SET.percent_of_net_commission,
            FinancialTransferEntity.value_type_SET.percent_of_sales_total,
            FinancialTransferEntity.value_type_SET.flat_fee
        ]
    };

    previous: FinancialTransferEntity | null = null;

    applyForPrimaryAgent = false;
    applyForOtherAgents = false;

    // contactPersons: any[] = [];
    contacts: BehaviorSubject<IContact[]> = new BehaviorSubject<IContact[]>([]);

    products: IProduct[] = [];

    @Input() set transferFG(fx: GenericFormGroup<FinancialTransferEntity>) {
        fx.valueChanges
            .pipe(
                tap(() => {
                    fx.controls.source_amount!.patchValue(cleanCurrencyString(fx.controls.source_amount!.value), {
                        emitEvent: false
                    });
                    fx.controls.label!.patchValue(fx.controls.label!.value, {emitEvent: false});
                    fx.controls.percent!.patchValue(fx.controls.percent!.value, {emitEvent: false});
                    fx.controls.percent_type!.patchValue(fx.controls.percent_type!.value, {emitEvent: false});
                    fx.controls.value_type!.patchValue(fx.controls.value_type!.value, {emitEvent: false});
                    fx.controls.override!.patchValue(fx.controls.override!.value, {emitEvent: false});
                    fx.controls.pay_at_escrow!.patchValue(fx.controls.pay_at_escrow!.value, {emitEvent: false});
                    fx.controls.disbursement_instructions!.patchValue(fx.controls.disbursement_instructions!.value, {
                        emitEvent: false
                    });
                    fx.controls.contribute_to_cap!.patchValue(fx.controls.contribute_to_cap!.value, {emitEvent: false});
                    fx.controls.value_wildcard_fk_id!.patchValue(fx.controls.value_wildcard_fk_id!.value, {
                        emitEvent: false
                    });
                    fx.controls.notes_wildcard_fk_id!.patchValue(fx.controls.notes_wildcard_fk_id!.value, {
                        emitEvent: false
                    });
                    fx.controls.notes!.patchValue(fx.controls.notes!.value, {emitEvent: false});
                    fx.controls.product_fk_id!.patchValue(fx.controls.product_fk_id!.value, {emitEvent: false});
                    fx.controls.product_fk_id!.setValidators([Validators.required]);
                    fx.controls.product_fk_id!.updateValueAndValidity({emitEvent: false});
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((next: FinancialTransferEntity) => {
                // if (this.previous && next.added_automatic && !next.override) {
                //     if (this.previous.pay_at_escrow !== next.pay_at_escrow) {
                //         this.onToggleOverride(this._transferFG, true);
                //         this._transferFG.controls.pay_at_escrow.patchValue(this.previous.pay_at_escrow, {emitEvent: false});
                //         this._transferFG.controls.override.controls.pay_at_escrow.patchValue(next.pay_at_escrow, {emitEvent: false});
                //     }
                //     if (this.previous.disbursement_instructions !== next.disbursement_instructions) {
                //         this.onToggleOverride(this._transferFG, true);
                //         this._transferFG.controls.disbursement_instructions.patchValue(this.previous.disbursement_instructions, {emitEvent: false});
                //         this._transferFG.controls.override.controls.disbursement_instructions.patchValue(next.disbursement_instructions, {emitEvent: false});
                //     }
                //     return;
                // }
                if (next.sender) {
                    this.senderApplyTo.patchValue(
                        [
                            new ChipNode()
                                .setTargetId(next.sender.contact_id)
                                .setLabel(next.sender.link_title!)
                                .setType(ChipNode.type_SET.contact)
                        ],
                        {emitEvent: false}
                    );
                }
                if (next.receiver) {
                    this.receiverApplyTo.patchValue(
                        [
                            new ChipNode()
                                .setTargetId(next.receiver.contact_id)
                                .setLabel(next.receiver.link_title!)
                                .setType(ChipNode.type_SET.contact)
                        ],
                        {emitEvent: false}
                    );
                }
                // if (next.sender_wildcard_id && next.sender === null) {
                if (next.sender_wildcard_id) {
                    const currentWildcard = this.wildcardList.find(
                        (wildcard) => wildcard.wildcard_id === next.sender_wildcard_id
                    );

                    if (typeof currentWildcard !== 'undefined') {
                        this.senderApplyTo.patchValue(
                            [
                                new ChipNode()
                                    .setTargetId(next.sender_wildcard_id)
                                    .setLabel(currentWildcard.title!)
                                    .setType(ChipNode.type_SET.wildcard)
                            ],
                            {emitEvent: false}
                        );
                    } else {
                        let label = '';
                        if (next.sender_wildcard_id === 1) {
                            label = 'Mentor';
                        }
                        if (next.sender_wildcard_id === 2) {
                            label = 'TRX Coordinator';
                        }
                        if (next.sender_wildcard_id === 3) {
                            label = 'Deal';
                        }
                        if (next.sender_wildcard_id === 4) {
                            label = 'Mentor Team Leader';
                        }
                        if (next.sender_wildcard_id === 5) {
                            label = 'Commission Payer';
                        }
                        if (next.sender_wildcard_id === 6) {
                            label = 'Primary Agent';
                        }
                        if (next.sender_wildcard_id === 7) {
                            label = 'Company';
                        }
                        this.senderApplyTo.patchValue(
                            [
                                new ChipNode()
                                    .setTargetId(next.sender_wildcard_id)
                                    .setLabel(label)
                                    .setType(ChipNode.type_SET.contact_wildcard)
                            ],
                            {emitEvent: false}
                        );
                    }
                }
                // if (next.receiver_wildcard_id && next.receiver === null) {
                if (next.receiver_wildcard_id) {
                    const currentWildcard = this.wildcardList.find(
                        (wildcard) => wildcard.wildcard_id === next.receiver_wildcard_id
                    );

                    if (typeof currentWildcard !== 'undefined') {
                        this.receiverApplyTo.patchValue(
                            [
                                new ChipNode()
                                    .setTargetId(next.receiver_wildcard_id)
                                    .setLabel(currentWildcard.title!)
                                    .setType(ChipNode.type_SET.wildcard)
                            ],
                            {emitEvent: false}
                        );
                    } else {
                        let label = '';
                        if (next.receiver_wildcard_id === 1) {
                            label = 'Mentor';
                        }
                        if (next.receiver_wildcard_id === 2) {
                            label = 'TRX Coordinator';
                        }
                        if (next.receiver_wildcard_id === 3) {
                            label = 'Deal';
                        }
                        if (next.receiver_wildcard_id === 4) {
                            label = 'Mentor Team Leader';
                        }
                        if (next.receiver_wildcard_id === 5) {
                            label = 'Commission Payer';
                        }
                        if (next.receiver_wildcard_id === 6) {
                            label = 'Primary Agent';
                        }
                        if (next.receiver_wildcard_id === 7) {
                            label = 'Company';
                        }
                        this.receiverApplyTo.patchValue(
                            [
                                new ChipNode()
                                    .setTargetId(next.receiver_wildcard_id)
                                    .setLabel(label)
                                    .setType(ChipNode.type_SET.contact_wildcard)
                            ],
                            {emitEvent: false}
                        );
                    }
                }

                if (fx.controls.override && !fx.controls.override.controls) {
                    if (fx.controls.pay_at_escrow!.value === false) {
                        this.disbursementInstructionsFC.patchValue(false, {emitEvent: false});
                        this.disbursementInstructionsFC.disable({emitEvent: false});
                    } else if (fx.enabled) {
                        this.disbursementInstructionsFC.enable({emitEvent: false});
                    }
                }

                if (next.override && fx.controls.override && fx.controls.override.controls) {
                    if (
                        (!fx.controls.override.controls.pay_at_escrow && !fx.controls.pay_at_escrow!.value) ||
                        (fx.controls.override.controls.pay_at_escrow &&
                            !fx.controls.override.controls.pay_at_escrow.value)
                    ) {
                        if (
                            (!fx.controls.override.controls.disbursement_instructions &&
                                fx.controls.disbursement_instructions!.value) ||
                            (fx.controls.override.controls.disbursement_instructions &&
                                fx.controls.override.controls.disbursement_instructions.value)
                        ) {
                            if (
                                fx.controls.disbursement_instructions!.value &&
                                !fx.controls.override.controls.disbursement_instructions
                            ) {
                                fx.controls.override.controls.disbursement_instructions!.disable({emitEvent: false});
                                fx.controls.override.controls.notes!.disable({emitEvent: false});
                            } else if (
                                fx.controls.override.controls.disbursement_instructions &&
                                fx.controls.override.controls.disbursement_instructions.value
                            ) {
                                fx.controls.override.controls.disbursement_instructions.disable({emitEvent: false});
                                fx.controls.override.controls.notes!.disable({emitEvent: false});
                            }
                        }
                        this.disbursementInstructionsFC.disable({emitEvent: false});
                    } else {
                        if (fx.controls.override.controls.disbursement_instructions) {
                            fx.controls.override.controls.disbursement_instructions.enable({emitEvent: false});
                            fx.controls.override.controls.notes!.enable({emitEvent: false});
                        } else if (fx.enabled) {
                            this.disbursementInstructionsFC.enable({emitEvent: false});
                        }
                    }
                }
                if (
                    fx.controls.override &&
                    fx.controls.override.controls &&
                    fx.controls.override.controls.contribute_to_cap
                ) {
                    if (fx.controls.override.controls.contribute_to_cap.value) {
                        this.contributeToCapFC.patchValue(true, {emitEvent: false});
                    } else {
                        this.contributeToCapFC.patchValue(false, {emitEvent: false});
                    }
                } else {
                    if (fx.controls.contribute_to_cap!.value) {
                        this.contributeToCapFC.patchValue(true, {emitEvent: false});
                    } else {
                        this.contributeToCapFC.patchValue(false, {emitEvent: false});
                    }
                }
                if (
                    fx.controls.contribute_to_cap?.value &&
                    fx.controls.contribute_to_cap?.value ===
                        FinancialTransferEntity.contribute_to_cap_SET.receiver_split
                ) {
                    this.applyAsReceiverSplitFC.patchValue(true, {emitEvent: false});
                } else {
                    this.applyAsReceiverSplitFC.patchValue(false, {emitEvent: false});
                }
                this.previous = next;
            });

        if (fx.controls.override && fx.controls.override.controls && fx.controls.override.controls.contribute_to_cap) {
            if (fx.controls.override.controls.contribute_to_cap.value) {
                this.contributeToCapFC.patchValue(true, {emitEvent: false});
            } else {
                this.contributeToCapFC.patchValue(false, {emitEvent: false});
            }
        } else {
            if (fx.controls.contribute_to_cap!.value) {
                this.contributeToCapFC.patchValue(true, {emitEvent: false});
            } else {
                this.contributeToCapFC.patchValue(false, {emitEvent: false});
            }
        }
        if (
            fx.controls.contribute_to_cap?.value &&
            fx.controls.contribute_to_cap?.value === FinancialTransferEntity.contribute_to_cap_SET.receiver_split
        ) {
            this.applyAsReceiverSplitFC.patchValue(true, {emitEvent: false});
        } else {
            this.applyAsReceiverSplitFC.patchValue(false, {emitEvent: false});
        }

        if (fx.controls.override && fx.controls.override.controls && fx.controls.override.controls.pay_at_escrow) {
            if (!fx.controls.override.controls.pay_at_escrow.value) {
                this.disbursementInstructionsFC.disable({emitEvent: false});
            }
        } else {
            if (!fx.controls.pay_at_escrow!.value) {
                this.disbursementInstructionsFC.disable({emitEvent: false});
            }
        }
        if (
            fx.controls.override &&
            fx.controls.override.controls &&
            fx.controls.override.controls.disbursement_instructions
        ) {
            this.disbursementInstructionsFC.patchValue(fx.controls.override.controls.disbursement_instructions.value);
        } else {
            this.disbursementInstructionsFC.patchValue(fx.controls.disbursement_instructions!.value);
        }
        this._transferFG = fx;

        switch (this._transferFG.controls.apply_to_agents!.value) {
            case FinancialTransferEntity.agents_types_SET.primary:
                this.applyForPrimaryAgent = true;
                break;
            case FinancialTransferEntity.agents_types_SET.other:
                this.applyForOtherAgents = true;
                break;
            case FinancialTransferEntity.agents_types_SET.all:
                this.applyForPrimaryAgent = true;
                this.applyForOtherAgents = true;
                break;
            default:
                this.applyForPrimaryAgent = true;
                this.applyForOtherAgents = false;
                break;
        }

        this.previous = fx.getRawValue();
    }

    canShowLinkToInvoice: boolean = false;
    canShowLinkToBill: boolean = false;

    isAllowedContacts: boolean = false;

    constructor(
        private store: Store<any>,
        public dialog: MatDialog,
        public router: Router,
        protected productsService: ProductsService,
        protected rbacService: RbacService,
        protected featureFlagsService: FeatureFlagsService
    ) {
        // check permissions
        this.rbacService
            .isAllowedAny([{contacts__view_limited_contacts: true}, {contacts__view_any_contacts: true}])
            .then((isAllowedContacts) => {
                this.isAllowedContacts = isAllowedContacts;
                if (this.isAllowedContacts) {
                    this.store.dispatch(new FetchContacts());
                    this.store
                        .pipe(
                            select(selectContacts), // migrate to ContactsLite: done
                            map((contacts) => {
                                // this.contactPersons = extractContactPersons(contacts);
                                this.contacts.next(contacts);
                            }),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe();
                }
            });

        this.rbacService.isAllowed({sales__view_invoices: true}).then((can) => (this.canShowLinkToInvoice = can));
        this.rbacService.isAllowed({purchases__view_bills: true}).then((can) => (this.canShowLinkToBill = can));

        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.contributeToCapFlag = this.featureFlagsService.isFeatureEnabled('deals:contribute_to_cap');
                this.compensationExpenseEnabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'rules:compensation_expense_element'
                );
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.disabled && changes.disabled.currentValue) {
            this.contributeToCapFC.disable({emitEvent: false});
            this.disbursementInstructionsFC.disable();
        }
    }

    createProduct() {
        const dialogRef = this.dialog.open(CreateProductDialogComponent, {
            // width: '90vw',
            // minHeight: '30vh'
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.store.dispatch(new FetchProducts()));
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }

    onToggleOverride(transfer: GenericFormGroup<FinancialTransferEntity>, override: boolean) {
        if (override) {
            if (transfer.controls.type!.value === FinancialTransferEntity.type_SET.company_payout) {
                return;
            }
            // transfer.controls.override.patchValue(transfer.getRawValue());
            transfer.controls.override = new GenericFormGroup<FinancialTransferEntity>(<FinancialTransferEntity>{
                pay_at_escrow: transfer.controls.pay_at_escrow!.value,
                disbursement_instructions: transfer.controls.disbursement_instructions!.value,
                notes: transfer.controls.notes!.value,
                notes_wildcard_fk_id: transfer.controls.notes_wildcard_fk_id!.value
            });
        } else {
            transfer.controls.override = <GenericFormGroup<FinancialTransferEntity>>(
                (<unknown>new UntypedFormControl(null))
            );
        }
        transfer.updateValueAndValidity({emitEvent: false});
    }

    onDisbursementInstructionsChange(isChecked: boolean) {
        const overrideObj = {
            disbursement_instructions: isChecked,
            notes: null,
            notes_wildcard_fk_id: null
        };

        if (this._transferFG.controls.override && this._transferFG.controls.override.controls) {
            if (isChecked === this._transferFG.controls.disbursement_instructions!.value) {
                if (
                    ((this._transferFG.controls.override.controls.pay_at_escrow &&
                        this._transferFG.controls.override.controls.pay_at_escrow.value ===
                            this._transferFG.controls.pay_at_escrow!.value) ||
                        !this._transferFG.controls.override.controls.pay_at_escrow) &&
                    ((this._transferFG.controls.override.controls.contribute_to_cap &&
                        this._transferFG.controls.override.controls.contribute_to_cap.value ===
                            this._transferFG.controls.contribute_to_cap!.value) ||
                        !this._transferFG.controls.override.controls.contribute_to_cap)
                ) {
                    this._transferFG.controls.override = <GenericFormGroup<FinancialTransferEntity>>(
                        (<unknown>new UntypedFormControl(null))
                    );
                } else {
                    this._transferFG.controls.override.removeControl('disbursement_instructions');
                    this._transferFG.controls.override.removeControl('notes');
                    this._transferFG.controls.override.removeControl('notes_wildcard_fk_id');
                }
            } else {
                if (this._transferFG.controls.override.controls.disbursement_instructions) {
                    this._transferFG.controls.override.controls.disbursement_instructions.patchValue(isChecked);
                } else {
                    if (this._transferFG.controls.override.controls.pay_at_escrow) {
                        // @ts-ignore
                        overrideObj['pay_at_escrow'] = this._transferFG.controls.override.controls.pay_at_escrow.value;
                    }

                    if (this._transferFG.controls.override.controls.contribute_to_cap) {
                        // @ts-ignore
                        overrideObj['contribute_to_cap'] =
                            this._transferFG.controls.override.controls.contribute_to_cap.value;
                    }

                    this._transferFG.controls.override = new GenericFormGroup<FinancialTransferEntity>(
                        <FinancialTransferEntity>overrideObj
                    );
                }
            }
        } else {
            this._transferFG.controls.override = new GenericFormGroup<FinancialTransferEntity>(
                <FinancialTransferEntity>overrideObj
            );
        }
    }

    openInvoiceDialog(invoiceId: number | undefined) {
        const dialogRef = this.dialog.open(InvoiceBillModalDialogComponent, {
            position: {
                top: '10px',
                right: '10px'
            },
            height: '98%',
            width: '100vw',
            maxWidth: '98vw',
            panelClass: 'full-screen-modal',
            data: {
                invoiceId: invoiceId
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {});
    }

    openBillDialog(billId: number) {
        const dialogRef = this.dialog.open(InvoiceBillModalDialogComponent, {
            position: {
                top: '10px',
                right: '10px'
            },
            height: '98%',
            width: '100vw',
            maxWidth: '98vw',
            panelClass: 'full-screen-modal',
            data: {
                billId: billId
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {});
    }

    selectItem(product: IProduct) {
        if (
            this._transferFG.controls.source_amount!.value === null &&
            this._transferFG.controls.value_wildcard_fk_id!.value === null &&
            this._transferFG.controls.percent!.value === null &&
            // @ts-ignore
            FinancialTransferEntity.allowed_types_to_set_amount_from_product_price[this._transferFG.controls.type.value]
        ) {
            this._transferFG.controls.source_amount!.patchValue(product.price);
        }
        if (this._transferFG.controls.label!.value === null) {
            this._transferFG.controls.label!.patchValue(product.name);
        }
    }

    onApplyAsReceiverSplitChange() {
        const isAppliedAsReceiverSplit = this.applyAsReceiverSplitFC.value;
        this._transferFG.controls.contribute_to_cap!.patchValue(isAppliedAsReceiverSplit ? 'receiver_split' : null, {
            emitEvent: false
        });
    }

    get transferFG() {
        return this._transferFG;
    }

    updatedParticipants(event: Event) {
        console.info(event);
    }

    // doTogglePayAtEscrow(additional: GenericFormGroup<FinancialTransferEntity>) {
    //     // event.stopPropagation();
    //     if (additional.controls.pay_at_escrow.disabled) {
    //         return;
    //     }
    //     if (additional.controls.pay_at_escrow.value) {
    //         additional.controls.pay_at_escrow.patchValue(false, { emitEvent: false });
    //     } else {
    //         additional.controls.pay_at_escrow.patchValue(true, { emitEvent: false });
    //     }
    // }

    // doToggleDisbursementInstructions(additional: GenericFormGroup<FinancialTransferEntity>) {
    //     // event.stopPropagation();
    //     if (additional.controls.disbursement_instructions.disabled) {
    //         return;
    //     }
    //     if (! this.enableDisbursementInstructionsToggle) {
    //         additional.controls.disbursement_instructions.patchValue(false, {emitEvent: false});
    //         return;
    //     }
    //     if (additional.controls.disbursement_instructions.value) {
    //         additional.controls.disbursement_instructions.patchValue(false, { emitEvent: false });
    //     } else {
    //         additional.controls.disbursement_instructions.patchValue(true, { emitEvent: false });
    //         // additional.controls.pay_at_escrow.patchValue(true, { emitEvent: true });
    //     }
    // }

    // doToggleDisbursementInstructions(additional: GenericFormGroup<FinancialTransferEntity>) {
    //     // event.stopPropagation();
    //     if (additional.controls.disbursement_instructions.disabled) {
    //         return;
    //     }
    //     if (! this.enableDisbursementInstructionsToggle) {
    //         additional.controls.disbursement_instructions.patchValue(false, {emitEvent: false});
    //         return;
    //     }
    //     if (additional.controls.disbursement_instructions.value) {
    //         additional.controls.disbursement_instructions.patchValue(false, { emitEvent: false });
    //     } else {
    //         additional.controls.disbursement_instructions.patchValue(true, { emitEvent: false });
    //         // additional.controls.pay_at_escrow.patchValue(true, { emitEvent: true });
    //     }
    // }

    ngOnInit() {
        // the transfers with type=company_split_individual is an additional transfer of the Sales Compensation element
        // and value type of this type of transfers should not be changed by users
        if (this._transferFG.controls.type!.value === FinancialTransferEntity.type_SET.company_split_individual) {
            this._transferFG.controls.value_type!.disable({emitEvent: false});
        }
        // @TODO: Need refactor to better usage of store
        this.productsService.lastLoadedProducts.pipe(takeUntil(this.unsubscribe)).subscribe((next) => {
            this.products = next;
        });

        this.store.pipe(select(selectWildcards), takeUntil(this.unsubscribe)).subscribe((wildcards) => {
            this.wildcardList = wildcards;
            if (this._transferFG.controls.sender!.value !== null) {
                this.senderApplyTo.patchValue(
                    [
                        new ChipNode()
                            .setTargetId(this._transferFG.controls.sender!.value.contact_id)
                            .setLabel(this._transferFG.controls.sender!.value.link_title)
                            .setType(ChipNode.type_SET.contact)
                    ],
                    {emitEvent: false}
                );
            }
            if (this._transferFG.controls.receiver!.value !== null) {
                this.receiverApplyTo.patchValue(
                    [
                        new ChipNode()
                            .setTargetId(this._transferFG.controls.receiver!.value.contact_id)
                            .setLabel(this._transferFG.controls.receiver!.value.link_title)
                            .setType(ChipNode.type_SET.contact)
                    ],
                    {emitEvent: false}
                );
            }
            if (this._transferFG.controls.sender_wildcard_id!.value) {
                const currentWildcard = this.wildcardList.find(
                    (wildcard) => wildcard.wildcard_id === this._transferFG.controls.sender_wildcard_id!.value
                );

                if (typeof currentWildcard !== 'undefined') {
                    this.senderApplyTo.patchValue(
                        [
                            new ChipNode()
                                .setTargetId(this._transferFG.controls.sender_wildcard_id!.value)
                                .setLabel(currentWildcard.title!)
                                .setType(ChipNode.type_SET.wildcard)
                        ],
                        {emitEvent: false}
                    );
                } else {
                    let label = '';
                    if (this._transferFG.controls.sender_wildcard_id!.value === 1) {
                        label = 'Mentor';
                    }
                    if (this._transferFG.controls.sender_wildcard_id!.value === 2) {
                        label = 'TRX Coordinator';
                    }
                    if (this._transferFG.controls.sender_wildcard_id!.value === 3) {
                        label = 'Deal';
                    }
                    if (this._transferFG.controls.sender_wildcard_id!.value === 4) {
                        label = 'Mentor Team Leader';
                    }
                    if (this._transferFG.controls.sender_wildcard_id!.value === 5) {
                        label = 'Commission Payer';
                    }
                    if (this._transferFG.controls.sender_wildcard_id!.value === 6) {
                        label = 'Primary Agent';
                    }
                    if (this._transferFG.controls.sender_wildcard_id!.value === 7) {
                        label = 'Company';
                    }

                    this.senderApplyTo.patchValue(
                        [
                            new ChipNode()
                                .setTargetId(this._transferFG.controls.sender_wildcard_id!.value)
                                .setLabel(label)
                                .setType(ChipNode.type_SET.contact_wildcard)
                        ],
                        {emitEvent: false}
                    );
                }
            }
            if (this._transferFG.controls.receiver_wildcard_id!.value) {
                const currentWildcard = this.wildcardList.find(
                    (wildcard) => wildcard.wildcard_id === this._transferFG.controls.receiver_wildcard_id!.value
                );

                if (typeof currentWildcard !== 'undefined') {
                    this.receiverApplyTo.patchValue(
                        [
                            new ChipNode()
                                .setTargetId(this._transferFG.controls.receiver_wildcard_id!.value)
                                .setLabel(currentWildcard.title!)
                                .setType(ChipNode.type_SET.wildcard)
                        ],
                        {emitEvent: false}
                    );
                } else {
                    let label = '';
                    if (this._transferFG.controls.receiver_wildcard_id!.value === 1) {
                        label = 'Mentor';
                    }
                    if (this._transferFG.controls.receiver_wildcard_id!.value === 2) {
                        label = 'TRX Coordinator';
                    }
                    if (this._transferFG.controls.receiver_wildcard_id!.value === 3) {
                        label = 'Deal';
                    }
                    if (this._transferFG.controls.receiver_wildcard_id!.value === 4) {
                        label = 'Mentor Team Leader';
                    }
                    if (this._transferFG.controls.receiver_wildcard_id!.value === 5) {
                        label = 'Commission Payer';
                    }
                    if (this._transferFG.controls.receiver_wildcard_id!.value === 6) {
                        label = 'Primary Agent';
                    }
                    if (this._transferFG.controls.receiver_wildcard_id!.value === 7) {
                        label = 'Company';
                    }
                    this.receiverApplyTo.patchValue(
                        [
                            new ChipNode()
                                .setTargetId(this._transferFG.controls.receiver_wildcard_id!.value)
                                .setLabel(label)
                                .setType(ChipNode.type_SET.contact_wildcard)
                        ],
                        {emitEvent: false}
                    );
                }
            }
        });

        combineLatest([
            this.receiverApplyTo.valueChanges,
            this.contacts.pipe(filter((contacts) => Array.isArray(contacts) && contacts.length !== 0))
        ])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([next, contacts]) => {
                const chipNode = next.shift();
                if (chipNode && chipNode.type === 'contact') {
                    const selectedContact = contacts.find((contact) => contact.id === chipNode.target_id);
                    this._transferFG.controls.receiver_wildcard_id!.patchValue(null);
                    this._transferFG.controls.receiver!.patchValue(
                        new ContactPartLink().setContactId(chipNode.target_id).setLinkTitle(chipNode.label)
                    );
                    if (selectedContact && selectedContact.partner__company_fk_id) {
                        this._transferFG.controls.receiver_contact__company_fk_id!.patchValue(
                            selectedContact.partner__company_fk_id
                        );
                    }
                } else if (chipNode && chipNode.type === 'contact_wildcard') {
                    // remove and add control are needed because value is another FormControl and we can not just set it to null
                    this._transferFG.removeControl('receiver');
                    this._transferFG.addControl('receiver', new UntypedFormControl(null));
                    this._transferFG.controls.receiver_wildcard_id!.patchValue(chipNode.target_id);
                } else if (chipNode && chipNode.type === 'wildcard') {
                    // remove and add control are needed because value is another FormControl and we can not just set it to null
                    this._transferFG.removeControl('receiver');
                    this._transferFG.addControl('receiver', new UntypedFormControl(null));
                    this._transferFG.controls.receiver_wildcard_id!.patchValue(chipNode.target_id);
                }
            });

        combineLatest([
            this.senderApplyTo.valueChanges,
            this.contacts.pipe(filter((contacts) => Array.isArray(contacts) && contacts.length !== 0))
        ])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([next, contacts]) => {
                const chipNode = next.shift();
                if (chipNode && chipNode.type === 'contact') {
                    const selectedContact = contacts.find((contact) => contact.contact_id === chipNode.target_id);
                    this._transferFG.controls.sender_wildcard_id!.patchValue(0);
                    this._transferFG.controls.sender!.patchValue(
                        new ContactPartLink().setContactId(chipNode.target_id).setLinkTitle(chipNode.label)
                    );
                    if (selectedContact && selectedContact.partner__company_fk_id) {
                        this._transferFG.controls.sender_contact__company_fk_id!.patchValue(
                            selectedContact.partner__company_fk_id
                        );
                    }
                } else if (chipNode && chipNode.type === 'contact_wildcard') {
                    // remove and add control are needed because value is another FormControl and we can not just set it to null
                    this._transferFG.removeControl('sender');
                    this._transferFG.addControl('sender', new UntypedFormControl(null));
                    this._transferFG.controls.sender_wildcard_id!.patchValue(chipNode.target_id);
                } else if (chipNode && chipNode.type === 'wildcard') {
                    // remove and add control are needed because value is another FormControl and we can not just set it to null
                    this._transferFG.removeControl('sender');
                    this._transferFG.addControl('sender', new UntypedFormControl(null));
                    this._transferFG.controls.sender_wildcard_id!.patchValue(chipNode.target_id);
                }
            });

        this.disbursementInstructionsFC.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((newValue) => {
            this.onDisbursementInstructionsChange(newValue);
        });

        this.contributeToCapFC.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((isFeatureWantedByUser) => {
            // transfer is subject of override
            if (this._transferFG.controls.added_automatic!.value) {
                // define override object if needed
                if (!this._transferFG.controls.override || !this._transferFG.controls.override.controls) {
                    this._transferFG.controls.override = new GenericFormGroup<FinancialTransferEntity>(
                        <FinancialTransferEntity>{}
                    );
                }

                if (!this._transferFG.controls.override.controls.contribute_to_cap) {
                    this._transferFG.controls.override.controls.contribute_to_cap = new UntypedFormControl(null);
                } else {
                    if (!isFeatureWantedByUser) {
                        this._transferFG.controls.override.controls.contribute_to_cap.patchValue(null, {
                            emitEvent: false
                        });
                    }
                }
            } else {
                if (!isFeatureWantedByUser) {
                    this._transferFG.controls.contribute_to_cap!.patchValue(null, {emitEvent: false});
                }
            }
        });
    }

    object_keys(obj: object) {
        return Object.keys(obj);
    }

    changeApplyToAgents(applyTo: 'primary' | 'other') {
        let applyToValue;
        switch (applyTo) {
            case 'primary':
                this.applyForPrimaryAgent = !this.applyForPrimaryAgent;
                break;
            case 'other':
                this.applyForOtherAgents = !this.applyForOtherAgents;
                break;
        }
        if (this.applyForPrimaryAgent) {
            applyToValue = FinancialTransferEntity.agents_types_SET.primary;
        }

        if (this.applyForOtherAgents) {
            applyToValue = FinancialTransferEntity.agents_types_SET.other;
        }

        if (this.applyForPrimaryAgent && this.applyForOtherAgents) {
            applyToValue = FinancialTransferEntity.agents_types_SET.all;
        }

        if (!this.applyForPrimaryAgent && !this.applyForOtherAgents) {
            applyToValue = FinancialTransferEntity.agents_types_SET.primary;
            this.applyForPrimaryAgent = true;
        }

        this._transferFG.controls.apply_to_agents!.patchValue(applyToValue, {emitEvent: false});
    }

    isPercent(value: string): boolean {
        switch (value) {
            case FinancialTransferEntity.value_type_SET.percent:
            case FinancialTransferEntity.value_type_SET.percent_of_split:
            case FinancialTransferEntity.value_type_SET.percent_of_base_commission:
            case FinancialTransferEntity.value_type_SET.percent_of_base_commission_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_commission:
            case FinancialTransferEntity.value_type_SET.percent_of_commission_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_adjusted_gross:
            case FinancialTransferEntity.value_type_SET.percent_of_adjusted_gross_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_deal_net:
            case FinancialTransferEntity.value_type_SET.percent_of_deal_net_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_net_commission:
            case FinancialTransferEntity.value_type_SET.percent_of_net_commission_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_company_split:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_total:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_net:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_entity_sales_split:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_price:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_price_by_percent_of_deal:
            case FinancialTransferEntity.value_type_SET.percent_of_sales_entity_sales_price:
            case FinancialTransferEntity.value_type_SET.percent_of_primary_entity_split:
            case FinancialTransferEntity.value_type_SET.percent_of_entity_split:
                return true;
            case FinancialTransferEntity.value_type_SET.flat_fee:
            case FinancialTransferEntity.value_type_SET.flat_fee_sales_net:
            case FinancialTransferEntity.value_type_SET.remaining:
                return false;
            default:
                throw new Error(`Unexpected value type - ${value}`);
        }
        return false;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
