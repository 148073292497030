<section class="bg-white">
    <!------- Header ------->
    <header class="d-flex align-items-center px-3 list-header">
        <h3 class="m-0 mr-1 d-flex align-items-end">Recurring Credits</h3>

        <mat-menu #matMenu="matMenu">
            <button
                mat-menu-item
                routerLink="/sales/creditnotes"
                [rule]="{sales__view_customer_credits: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'flex'}"
                [denyStyle]="{display: 'none'}"
            >
                Credit Notes
            </button>
            <button
                mat-menu-item
                routerLink="/sales/sourcecredits"
                [rule]="{sales__view_source_documents: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'flex'}"
                [denyStyle]="{display: 'none'}"
            >
                Source Documents
            </button>
        </mat-menu>

        <i class="sp-spacer"></i>

        <button
            class="ml-2"
            type="button"
            mat-raised-button
            color="primary"
            routerLink="/sales/creditnotes/recurring/create"
        >
            <mat-icon>add</mat-icon>
            Create
        </button>
    </header>

    <!------- List of credit notes ------->
    <div class="table-background">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="recurrence_title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">Title</th>
                <td mat-cell *matCellDef="let element" class="w-15">
                    {{ element.recurrence_title }}
                </td>
            </ng-container>

            <ng-container matColumnDef="period">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Frequency</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <span *ngIf="element.repeat && element.repeat?.period === 'custom'">
                        Every {{ element?.repeat?.frequency === 1 ? 'day' : element.repeat?.frequency + ' days' }}
                    </span>
                    <span *ngIf="element.repeat && element.repeat?.period !== 'custom'">
                        {{ element?.repeat?.frequency === 1 ? '' : element.repeat?.frequency }}
                        {{
                            element?.repeat?.frequency === 1
                                ? (element.repeat?.period | fromSnakeCase)
                                : (element.repeat?.period + 's' | fromSnakeCase)
                        }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="last_credit_note_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25 pl-1">Last Credit Note</th>
                <td mat-cell *matCellDef="let element" class="w-25 px-1">
                    {{ element.last_credit_note_date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="next_credit_note_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25 pl-1">Next Credit Note</th>
                <td mat-cell *matCellDef="let element" class="w-25 px-1">
                    {{ element.next_credit_note_date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Status</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.status | fromSnakeCase }}
                </td>
            </ng-container>

            <ng-container matColumnDef="total_amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 table-header-align-right">Amount</th>
                <td mat-cell *matCellDef="let element" class="w-10 pr-3 text-right">
                    {{ element?.credit_note_template?.total_amount | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="w-5 text-right">Action</th>
                <td mat-cell *matCellDef="let element" class="w-5 text-right">
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                        *ngIf="element.status !== 'expired'"
                        (click)="$event.stopPropagation()"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button
                            mat-menu-item
                            *ngIf="element.status !== 'active'"
                            (click)="$event.stopPropagation(); activateRecurring(element.recurring_credit_note_id)"
                        >
                            Activate
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="element.status !== 'stopped'"
                            (click)="$event.stopPropagation(); stopRecurring(element.recurring_credit_note_id)"
                        >
                            Stop
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [routerLink]="['/sales/creditnotes/recurring', row.id, 'edit']"
                class="zp-table-row"
            ></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div class="text-muted m-3 text-center no-items" *ngIf="dataSource.data && dataSource.data.length === 0">
            There are no recurring credit notes.
        </div>
    </div>
</section>
