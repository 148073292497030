import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {IJournalBase, IRecurringJournal} from '@cyberco-nodejs/zipi-typings';
import {JournalsTemplatesService} from 'app/services/api/finance/journal-templates.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationsService} from 'angular2-notifications';
import {RecurringJournalsService} from 'app/services/api/finance/recurring-journals.service';
import {isEqual, assign} from 'lodash-es';
import {RECURRING_INVOICE_PERIOD} from 'app/local-typings';
import {IMaybeValidJournalTemplate} from '../../journal-templates/journal-template/journal-template.component';

@Component({
    selector: 'app-edit-recurring-journal',
    templateUrl: 'edit-recurring-journal.component.html',
    styleUrls: ['edit-recurring-journal.component.scss']
})
export class EditRecurringJournalComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    closeEvent: Subject<boolean> = new Subject();

    journalTemplateBase: IJournalBase | null = null;
    recurringJournal: IRecurringJournal | null = null;

    recurringFormGroup: UntypedFormGroup = this.fb.group({
        recurrence_title: [null, Validators.required],

        start_on: [null, Validators.required],
        repeat: [{frequency: 1, period: 'month', is_anniversary: false}],
        repeat_custom_days: [1, []],
        end_on: [null, []],
        status: ['active', Validators.required]
    });

    repeat_custom: {frequency: number; period: 'custom'} | null = null;

    comparePeriod: (a: any, b: any) => boolean = isEqual;
    repeatPeriod = RECURRING_INVOICE_PERIOD;

    maybeValidJournal: IMaybeValidJournalTemplate = {valid: false, template: null};

    constructor(
        private journalsTemplatesService: JournalsTemplatesService,
        private recurringJournalsService: RecurringJournalsService,
        private route: ActivatedRoute,
        public router: Router,
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService
    ) {}

    ngOnInit() {
        this.route.paramMap
            .pipe(
                map((pm) => +pm.get('id')!),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                if (id) {
                    this.recurringJournalsService
                        .getRecurringJournalById(id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((rj) => {
                            this.recurringJournal = rj;
                            this.journalTemplateBase = rj.journal_template;
                            this.recurringFormGroup.patchValue(rj);

                            if (rj.repeat.period === 'custom') {
                                this.recurringFormGroup.get('repeat')!.patchValue({frequency: 1, period: 'custom'});
                                this.recurringFormGroup.get('repeat_custom_days')!.patchValue(rj.repeat.frequency);
                            }
                        });
                }
            });

        this.recurringFormGroup
            .get('repeat')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => {
                if (value && value.period === 'custom') {
                    this.repeat_custom = {
                        frequency: this.recurringFormGroup.get('repeat_custom_days')!.value,
                        period: 'custom'
                    };
                } else {
                    this.repeat_custom = null;
                }
            });

        this.recurringFormGroup
            .get('repeat_custom_days')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => {
                this.repeat_custom = {
                    frequency: value,
                    period: 'custom'
                };
            });
    }

    onTemplateUpdate(event: IMaybeValidJournalTemplate) {
        this.maybeValidJournal = event;
    }

    validateForm() {
        if (this.recurringFormGroup.valid && this.maybeValidJournal.valid) {
            return true;
        }

        this.ntfs.warn('Form is not valid');
        this.recurringFormGroup.updateValueAndValidity();

        return false;
    }

    createRecurringJournal() {
        if (!this.validateForm()) {
            return;
        }

        const newRecurringJournal = assign({}, this.recurringFormGroup.getRawValue(), {
            journal_template: this.maybeValidJournal.template
        });

        if (this.repeat_custom) {
            newRecurringJournal.repeat = this.repeat_custom;
        }

        this.recurringJournalsService
            .createRecurringJournal(newRecurringJournal)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                this.router.navigate(['/company/finance/journals']);
            });
    }

    updateRecurringJournal() {
        if (!this.validateForm() || !this.recurringJournal) {
            return;
        }

        const recurringJournal = assign({}, this.recurringFormGroup.getRawValue(), {
            recurring_journal_id: this.recurringJournal.recurring_journal_id,
            journal_template: this.maybeValidJournal.template
        });

        if (this.repeat_custom) {
            recurringJournal.repeat = this.repeat_custom;
        }

        this.recurringJournalsService
            .updateRecurringJournal(recurringJournal)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                this.router.navigate(['/company/finance/journals']);
            });
    }

    deleteRecurringJournal() {
        if (!this.recurringJournal || !this.recurringJournal.recurring_journal_id) {
            return;
        }
        this.recurringJournalsService
            .deleteRecurringJournal(this.recurringJournal.recurring_journal_id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                this.router.navigate([`/company/finance/journals`]);
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.closeEvent.complete();
    }
}
