import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {ILedgerAccount} from '@cyberco-nodejs/zipi-typings';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {LedgerAccountService} from 'app/services/api/finance/ledger-accounts.service';

@Component({
    selector: 'app-dwolla-connect-to-ledger-account-dialog',
    templateUrl: './banking-connect-to-ledger-account-dialog.component.html',
    styleUrls: ['./banking-connect-to-ledger-account-dialog.component.scss']
})
export class BankingConnectToLedgerAccountDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    accountId: UntypedFormControl | undefined;

    availableLedgerAccounts: ILedgerAccount[];
    accountType: string | undefined;

    isDefault: boolean;
    isTrust: boolean;

    constructor(
        public dialogRef: MatDialogRef<BankingConnectToLedgerAccountDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {is_trust: boolean; accountType: string},
        private ledgerAccountService: LedgerAccountService,
        private fb: UntypedFormBuilder
    ) {
        this.availableLedgerAccounts = [];
        this.isDefault = true;
        this.isTrust = this.data.is_trust;
    }

    ngOnInit() {
        this.accountType = this.data.accountType;
        this.accountId = this.fb.control(null, []);
        this.ledgerAccountService
            .getAvailableForBankingLedgerAccounts(this.isTrust ? 'trust' : 'bank')
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((accounts) => {
                this.availableLedgerAccounts = accounts;
            });
    }

    apply() {
        if (this.isDefault) {
            this.dialogRef.close(null);
        } else {
            this.dialogRef.close(this.accountId?.value);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
