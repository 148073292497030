<div class="container">
    <!------- Header with routing ------->
    <app-account-info-toolbar
        [profileIdsForDetailsLength]="profileIdsForDetails.length"
        [profileIdsToAdd]="profileIdsToAdd"
        (createUserEmitter)="createUser()"
        (changeAllDetailsEmitter)="changeAllDetails()"
        (bulkActionEmitter)="handleBulkAction($event)"
    ></app-account-info-toolbar>

    <div [ngStyle]="isExistSubCompanies ? {'margin-top': '15px'} : null">
        <!-- showed in users and roles pages -->
        <app-sub-companies-panel
            permission="company_settings__manage_users"
            (currentProfileChanged)="currentProfileChanged($event)"
            (availableProfilesEmitter)="availableProfilesHandler($event)"
        ></app-sub-companies-panel>
        <!------- No values ------->
        <div *ngIf="!isLoading && profiles.getValue().length === 0" class="text-muted m-3 text-center no-items">
            There are no profiles.
        </div>

        <app-lazy-loading
            *ngIf="!newUser && offsetTopToLazyLoading$"
            [data]="profiles.value"
            [itemHeightPx]="60"
            [minBufferPx]="500"
            [maxBufferPx]="1000"
            [offsetTop]="offsetTopToLazyLoading$"
            [closeSubject]="false"
            (scrolledDown)="nextBatch($event)"
            (scrolledUp)="doShowMoreTop($event)"
            [checkViewport]="checkViewport$"
            [scrollToIndex]="scrollToIndex$"
        >
            <ng-template let-profile="listItem" let-idx="index">
                <app-users-licenses-card
                    #userCard
                    [profile]="profile"
                    [index]="idx"
                    [profileIdsForDetails]="profileIdsForDetails"
                    [companyPermissions]="companyPermissions"
                    [allRoles]="allRoles"
                    [profileIdsToAdd]="profileIdsToAdd"
                    (onUpdateProfile)="updateProfileAfterAction($event)"
                    (onUpdateRoles)="updateAllRoles($event)"
                    (onUpdatePermissions)="updateCompanyPermissions($event)"
                    (onDeleteProfile)="deleteProfileAfterAction($event)"
                    (profileIdsToAddEmitter)="profileIdsToAdd$.next($event)"
                    (onProfileConnected)="reloadProfiles()"
                ></app-users-licenses-card>
            </ng-template>
        </app-lazy-loading>

        <app-edit-user
            *ngIf="newUser"
            [profile]="newUser"
            [allRoles]="allRoles"
            [companyPermissions]="companyPermissions"
            (userUpdated)="updateUserList($event)"
            (onDestroy)="destroyEditUserComponent()"
        >
        </app-edit-user>
    </div>
</div>
