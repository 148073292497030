import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

export class GoalFormModel {
    id?: number;
    dynamic?: string;
    period?: string;
    title?: string;
    units?: string;
    amount?: number;
    baseline_value?: number;
    type?: string;
    mandatory_type?: string;
    is_archived?: boolean;
    start_tracking_date?: Date;
}

export class GoalFormGroup extends UntypedFormGroup {
    public controls: {
        id: UntypedFormControl;
        dynamic: UntypedFormControl;
        period: UntypedFormControl;
        title: UntypedFormControl;
        units: UntypedFormControl;
        amount: UntypedFormControl;
        start_tracking_date: UntypedFormControl;
        baseline_value: UntypedFormControl;
        mandatory_type: UntypedFormControl;
        is_archived: UntypedFormControl;
        type: UntypedFormControl;
    };

    constructor() {
        const controls = {
            id: new UntypedFormControl(),
            dynamic: new UntypedFormControl('increase'),
            period: new UntypedFormControl(),
            title: new UntypedFormControl(),
            units: new UntypedFormControl(),
            amount: new UntypedFormControl(),
            start_tracking_date: new UntypedFormControl(),
            baseline_value: new UntypedFormControl(),
            mandatory_type: new UntypedFormControl('mandatory'),
            is_archived: new UntypedFormControl(false),
            type: new UntypedFormControl('progress')
        };
        super(controls);
        this.controls = controls;
        this.controls.id.disable({emitEvent: false});
        this.applyEvents();
    }

    applyEvents() {
        this.controls.dynamic.valueChanges.subscribe((newValue) => {
            if (newValue === 'attain') {
                this.controls.dynamic.disable({emitEvent: false});
            }
        });
    }

    getRawValue(): GoalFormModel {
        return <GoalFormModel>super.getRawValue();
    }

    patchValue(value: GoalFormModel) {
        super.patchValue(value);
    }
}

export class GoalFormGroupArray extends UntypedFormArray {
    public controls: GoalFormGroup[];

    constructor(goalForm?: GoalFormGroup[]) {
        super(goalForm || []);
        this.controls = goalForm || [];
    }
}

export class FocusAreasFormArray extends UntypedFormArray {
    public controls: FocusAreaFormGroup[];

    constructor(focusAreas?: FocusAreaFormGroup[]) {
        super(focusAreas || []);
        this.controls = focusAreas || [];
    }
}

export class FocusAreaFormModel {
    id?: number;
    title?: string;
    enumerable?: GoalFormModel[];
}

export class FocusAreaFormGroup extends UntypedFormGroup {
    public controls: {
        id: UntypedFormControl;
        title: UntypedFormControl;
        enumerable: GoalFormGroupArray;
    };

    applyEvents() {}

    constructor() {
        const controls = {
            id: new UntypedFormControl(),
            title: new UntypedFormControl(),
            enumerable: new GoalFormGroupArray()
        };
        super(controls);
        this.controls = controls;
        this.applyEvents();
    }

    getRawValue(): FocusAreaFormModel {
        const resp = <FocusAreaFormModel>super.getRawValue();
        resp.enumerable = resp.enumerable?.filter(
            (item) => item.period || item.amount || item.title || item.units || item.dynamic
        );
        return resp;
    }

    patchValue(value: FocusAreaFormModel) {
        if (value.enumerable && Array.isArray(value.enumerable)) {
            this.controls.enumerable = new GoalFormGroupArray(
                value.enumerable.map((item) => {
                    const resp = new GoalFormGroup();
                    resp.patchValue(item);
                    return resp;
                })
            );
        }
        this.applyEvents();
        super.patchValue(value);
    }
}

export class CompanyGoalsFormModel {
    focus_areas?: FocusAreaFormModel[];
    submit?: boolean;
}

export class CompanyGoalsFormGroup extends UntypedFormGroup {
    public controls: {
        focus_areas: FocusAreasFormArray;
        submit: UntypedFormControl;
    };

    constructor() {
        const controls = {
            focus_areas: new FocusAreasFormArray(),
            submit: new UntypedFormControl(false, Validators.requiredTrue)
        };
        super(controls);
        this.controls = controls;
        this.applyEvents();
    }

    applyEvents() {}

    getRawValue(): CompanyGoalsFormModel {
        return <CompanyGoalsFormModel>super.getRawValue();
    }

    patchValue(value: CompanyGoalsFormModel) {
        if (value.focus_areas && Array.isArray(value.focus_areas)) {
            this.controls.focus_areas = new FocusAreasFormArray(
                value.focus_areas.map((item) => {
                    const resp = new FocusAreaFormGroup();
                    resp.patchValue(item);
                    return resp;
                })
            );
        }
        this.applyEvents();
        super.patchValue(value);
    }
}
