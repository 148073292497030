import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {GatewayTypes, IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {firstValueFrom, Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {CompanyGatewayService} from '../../../../../../services/api/finance/company-gateway.service';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {CompanyPaymentMethodsService} from '../../../../../../services/api/finance/company-payment-methods.service';
import {MatDialog} from '@angular/material/dialog';
import {RbacService} from '../../../../../rbac/rbac.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ZipiFinancialIframeDialogComponent} from '../dialogs/zipi-financial-iframe-dialog/zipi-financial-iframe-dialog.component';
import {NotificationsServiceZipi} from '../../../../../notifications/notifications.service';
import {MarketplaceSource} from '../../../../marketplace/marketplace.source';
import {MarketplaceAddonInstanceModel} from '../../../../marketplace/marketplace.models';
import {MarketplaceApi} from '../../../../marketplace/marketplace.api';
import {ZipiFinGatewayEditDialogComponent} from '../dialogs/zipi-fin-gateway-edit-dialog/zipi-fin-gateway-edit-dialog.component';
import {IZipiFinancialIframeDialogData} from '../../../../../../typings/zipi-financial-iframe';
import {FeatureFlagsService} from '../../../../../feature-flags/feature-flags.service';
import {ZipiFinPaymentMehtodEditDialogComponent} from '../dialogs/zipi-fin-payment-mehtod-edit-dialog/zipi-fin-payment-mehtod-edit-dialog.component';
import {ConfirmComponent} from '../../../../../../layouts/confirm/confirm.component';
import {ComponentCanDeactivate} from '../../../../../../services/resolvers/pending-changes-guard.service';
import {UnsavedChangesNewDialogComponent} from '../../../../../../layouts/unsaved-changes-new-dialog/unsaved-changes-new-dialog.component';
import {SessionService} from '../../../../../../services/session.service';

@Component({
    selector: 'app-zipi-financial-page',
    templateUrl: './zipi-financial-page.component.html',
    styleUrls: ['./zipi-financial-page.component.scss']
})
export class ZipiFinancialPageComponent implements ComponentCanDeactivate, OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isUnsavedFormExist;
    }
    changingTabSubject: Subject<string> = new Subject<string>();

    isUnsavedFormExist: boolean = false;

    zipiFinancialGateways: Array<IPaymentGateway & {is_suspended: boolean}> | undefined;
    filteredGateways: Array<IPaymentGateway & {is_suspended: boolean}> | undefined;
    operatingGateways: IPaymentGateway[] = [];
    trustGateways: IPaymentGateway[] = [];
    cardsGateways: IPaymentGateway[] = [];
    cardHolderGateway: IPaymentGateway | null = null;
    defaultOperatingMethod: IPaymentMethod | undefined;
    defaultTrustMethod: IPaymentMethod | undefined;

    showFinalPopup: {gateway_id: number; payment_method_id: number | null; popup_type: 'final' | 'ledger'} | null;

    selectedIndex: number;

    isOperatingMethodsExist: boolean = false;
    isTrustMethodsExist: boolean = false;

    isOperatingMuhneeMethodsExist: boolean = false;
    isTrustMuhneeMethodsExist: boolean = false;

    isClose: UntypedFormControl;

    zipiFinancialAddonInstance: MarketplaceAddonInstanceModel | undefined;

    isSidenavOpened: boolean = false;

    timerId: any = null;

    hasExistingGateway: boolean = false;

    // feature_flags
    isMuhneeIntegrationFeatureFlagEnabled: boolean = false;
    isTppCreditCardFeatureFlagEnabled: boolean = false;
    isMuhneeHostedIntegrationFeatureFlagEnabled: boolean = false;
    isPayloadCoFeatureFlagEnabled: boolean = false;

    constructor(
        private companyGatewayService: CompanyGatewayService,
        private companyPaymentMethodsService: CompanyPaymentMethodsService,
        protected dialog: MatDialog,
        protected rbacService: RbacService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        protected fb: UntypedFormBuilder,
        private notificationServiceZipi: NotificationsServiceZipi,
        protected marketplaceSource: MarketplaceSource,
        public marketplaceApi: MarketplaceApi,
        protected featureFlagsService: FeatureFlagsService,
        public sessionService: SessionService
    ) {
        this.showFinalPopup = null;
        this.selectedIndex = 0;

        this.isClose = this.fb.control(false);
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.isMuhneeIntegrationFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:muhnee_integration'
                );
                this.isTppCreditCardFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:tpp_credit_card'
                );
                this.isMuhneeHostedIntegrationFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:muhnee_hosted_integration'
                );
                this.isPayloadCoFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:payload_co'
                );
            });
    }

    ngOnInit() {
        this.isClose.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((cha) => console.dir(cha));
        this.rbacService.isAllowed({marketplace__manage_zipipay: true}).then((can) => {
            if (!can) {
                this.router.navigate(['/']);
            }
        });

        this.loadGateways();

        this.marketplaceSource.addonsChangeEvent.pipe(takeUntil(this.unsubscribe)).subscribe((instances) => {
            this.zipiFinancialAddonInstance = instances.filter(
                (instanse) => instanse.addon && instanse.addon.slug === 'zipi_financial_integration'
            )[0];
            this.addDefaultGateway();
            if (!this.zipiFinancialAddonInstance) {
                console.error('Cannot find addon instance');
            }
        });
    }

    connectCustomer() {
        if (this.zipiFinancialAddonInstance) {
            this.companyGatewayService
                .connectAddonInstanceToZipiFinancialCustomer(this.zipiFinancialAddonInstance.addon_instance_id!)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response && response.success) {
                        this.marketplaceApi.loadAddons();
                    }
                });
        }
    }

    async loadGateways() {
        this.zipiFinancialGateways = (await firstValueFrom(
            this.companyGatewayService.getCompanyZipiFinancialGateways()
        )) as Array<IPaymentGateway & {is_suspended: boolean}>;
        this.setupDefaultMethods();
        this.addDefaultGateway();
        this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['oauth_state_id']) {
                const gatewayId = Number(localStorage.getItem('zipi_pg_redirect_id'));
                if (!gatewayId) {
                    // this.notificationServiceZipi.addError(`No Gateway Id`);
                    return;
                }
                let currentGateway = null;
                if (this.zipiFinancialGateways) {
                    currentGateway = this.zipiFinancialGateways.find(
                        (gateway) => gateway.payment_gateway_id === gatewayId
                    );
                }
                if (!currentGateway) {
                    this.notificationServiceZipi.addError(`Can not find Gateway #${gatewayId}`);
                    return;
                }
                this.addPaymentMethod(currentGateway, true, 'bank');
            }
        });
        this.hasExistingGateway = this.zipiFinancialGateways.some((gateway) => gateway.payment_gateway_id);
    }

    async addDefaultGateway() {
        // this is need to give a chance (5 sec) to load feature flags. if they are not loaded after 5 sec, we will no show default gateway
        if (!this.featureFlagsService.isFlagsLoaded && !this.timerId) {
            this.timerId = setInterval(() => this.addDefaultGateway(), 5000);
            return;
        } else if (this.timerId) {
            clearInterval(this.timerId);
            this.timerId = null;
        }
        this.filteredGateways = this.zipiFinancialGateways?.filter(
            (gateway) => gateway.type !== 'zipi_financial_card_customer'
        );
        if (
            this.filteredGateways &&
            this.filteredGateways.length === 0 &&
            this.zipiFinancialAddonInstance &&
            this.zipiFinancialAddonInstance.settings &&
            this.zipiFinancialAddonInstance.settings.external_customer_ref
        ) {
            if (this.isPayloadCoFeatureFlagEnabled) {
                this.addNewGateway('zipi_financial_business', 'payload');
            } else if (this.isMuhneeHostedIntegrationFeatureFlagEnabled) {
                this.addNewGateway('zipi_financial_business', 'muhnee-hosted');
            }
        }
    }

    setupDefaultMethods() {
        if (this.zipiFinancialGateways) {
            this.operatingGateways = this.zipiFinancialGateways.filter((gateway) =>
                ['zipi_financial_business'].includes(gateway.type)
            );
            if (
                this.operatingGateways.length > 0 &&
                this.operatingGateways.some((gateway) => gateway.payment_methods && gateway.payment_methods.length > 0)
            ) {
                this.isOperatingMethodsExist = true;
            }
            this.trustGateways = this.zipiFinancialGateways.filter((gateway) =>
                ['zipi_financial_trust'].includes(gateway.type)
            );
            if (
                this.trustGateways.length > 0 &&
                this.trustGateways.some((gateway) => gateway.payment_methods && gateway.payment_methods.length > 0)
            ) {
                this.isTrustMethodsExist = true;
            }

            this.cardHolderGateway =
                this.zipiFinancialGateways.find((gateway) => ['zipi_financial_card_customer'].includes(gateway.type)) ||
                null;
            this.cardsGateways = this.zipiFinancialGateways.filter((gateway) =>
                ['zipi_financial_card_merchant'].includes(gateway.type)
            );

            for (const gateway of this.zipiFinancialGateways) {
                if (gateway.payment_methods && gateway.payment_methods.length > 0) {
                    for (const paymentMethod of gateway.payment_methods) {
                        if (paymentMethod.is_default_receiving) {
                            if (['zipi_financial_business'].includes(gateway.type)) {
                                this.defaultOperatingMethod = paymentMethod;
                            } else if (['zipi_financial_trust'].includes(gateway.type)) {
                                this.defaultTrustMethod = paymentMethod;
                            }
                        }
                    }
                }
            }
        }
    }

    changeDefaultStatus(methodId: number) {
        if (methodId) {
            this.companyPaymentMethodsService
                .changeDefaultReceivingMethod(methodId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((res) => {
                    if (res) {
                        this.reload(true);
                    }
                });
        }
    }

    changeDefaultMerchant(methodId: number) {
        this.companyPaymentMethodsService
            .changeDefaultMerchantMethod(methodId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                if (res) {
                    this.reload(true);
                }
            });
    }

    addNewGateway(
        gatewayType: GatewayTypes,
        driverType: 'plaid' | 'moov' | 'muhnee' | 'tpp' | 'muhnee-hosted' | 'payload'
    ) {
        if (this.zipiFinancialGateways) {
            const newGateway: any = {type: gatewayType, driver_type: driverType};
            this.zipiFinancialGateways.push(newGateway);
            this.selectedIndex = this.zipiFinancialGateways.length;
        }
    }

    removeEmptyTab(index: number) {
        if (this.zipiFinancialGateways) {
            if (this.isUnsavedFormExist) {
                const confirmDialogRef = this.dialog.open(UnsavedChangesNewDialogComponent, {
                    minWidth: 320,
                    minHeight: 160,
                    disableClose: true,
                    data: {
                        title: 'Are you sure?',
                        message: `If you leave account creation at this time, you will lose all progress from this session.`
                    }
                });

                confirmDialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((result) => {
                        if (result === 'continue') {
                            this.zipiFinancialGateways?.splice(index, 1);
                        }
                    });
            } else {
                this.zipiFinancialGateways.splice(index, 1);
            }
        }
    }

    async reload($event: boolean) {
        if (!this.showFinalPopup) {
            await this.loadGateways();
        }
    }

    async showFinPopup(event: {gateway_id: number; payment_method_id: number | null; popup_type: 'final' | 'ledger'}) {
        await this.reload(true);
        this.showFinalPopup = event;
        if (this.showFinalPopup && this.zipiFinancialGateways && this.zipiFinancialGateways.length > 0) {
            const index = this.zipiFinancialGateways?.findIndex(
                (gateway) => gateway.payment_gateway_id === this.showFinalPopup?.gateway_id
            );
            this.selectedIndex = index !== -1 ? index : 0;
        }
    }

    cleanFinalPopupInfo() {
        this.showFinalPopup = null;
    }

    addPaymentMethod(gateway: IPaymentGateway, fromRedirect: boolean, type: 'bank' | 'card') {
        let redirectURL = null;
        if (fromRedirect) {
            redirectURL = window.location.href;
        }
        const dialogRef = this.dialog.open<ZipiFinancialIframeDialogComponent, IZipiFinancialIframeDialogData>(
            ZipiFinancialIframeDialogComponent,
            {
                disableClose: true,
                maxHeight: '80vh',
                width: '650px',
                // height: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    contactId: null,
                    gateway: gateway,
                    paymentMethod: null,
                    accessMethod: 'internal',
                    driverType: 'moov',
                    prefillData: null,
                    methodType: type,
                    isUniversal: true,
                    customerId: null,
                    creationSource: 'current_company',
                    storeAccountMethod: 'required'
                }
            }
        );

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result.isError) {
                    this.notificationServiceZipi.addError(result.message);
                } else if (result.success) {
                    this.reload(true);
                }
            });
    }

    editGateway(currentGateway: IPaymentGateway) {
        const dialogRef = this.dialog.open(ZipiFinGatewayEditDialogComponent, {
            // width: '650px',
            minWidth: '400px',
            data: {
                gateway: currentGateway
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result && currentGateway && currentGateway.payment_gateway_id) {
                    this.companyGatewayService
                        .updateCompanyGatewayById(currentGateway.payment_gateway_id, result)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((response) => {
                            this.reload(true);
                        });
                }
            });
    }

    async openSidenav() {
        if (this.cardHolderGateway) {
            this.isSidenavOpened = true;
        } else {
            const gatewayData = {
                customer: null,
                customer_type: null,
                title: 'Credit Card',
                driver_type: 'tpp',
                gateway_type: 'zipi_financial_card_customer',
                connect_to_gateway: null,
                connect_to_cloud_bank: null
            } as any;
            const gateway: any = await firstValueFrom(
                this.companyGatewayService.createZipiFinancialGateway(gatewayData)
            );
            if (gateway) {
                this.zipiFinancialGateways?.push(gateway);
                this.setupDefaultMethods();
                this.isSidenavOpened = true;
            }
        }
    }
    closeSidenav() {
        this.isSidenavOpened = false;
    }

    sidenavOpenChanged(isOpen: boolean) {
        this.isSidenavOpened = isOpen;
    }

    addCardPaymentMethod() {
        if (this.cardHolderGateway) {
            const dialogRef = this.dialog.open<ZipiFinancialIframeDialogComponent, IZipiFinancialIframeDialogData>(
                ZipiFinancialIframeDialogComponent,
                {
                    disableClose: true,
                    maxHeight: '80vh',
                    width: '650px',
                    // height: '800px',
                    panelClass: 'custom-dialog-container',
                    data: {
                        contactId: null,
                        gateway: this.cardHolderGateway,
                        accessMethod: 'internal',
                        prefillData: null,
                        paymentMethod: null,
                        driverType: 'tpp',
                        methodType: 'card',
                        isUniversal: true,
                        customerId: null,
                        creationSource: 'current_company',
                        storeAccountMethod: 'required'
                    }
                }
            );

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((result) => {
                    if (result.isError) {
                        this.notificationServiceZipi.addError(result.message);
                    } else if (result.success) {
                        this.reload(true);
                    }
                });
        }
    }

    editPayment(method: IPaymentMethod, gateway: IPaymentGateway) {
        const dialogRef = this.dialog.open(ZipiFinPaymentMehtodEditDialogComponent, {
            width: '600px',
            data: {
                method: method,
                gateway: gateway
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result && method && method.payment_method_id) {
                    this.companyPaymentMethodsService
                        .updatePaymentMethod(method.payment_method_id, result)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((response) => {
                            this.reload(true);
                        });
                }
            });
    }

    deletePaymentMethod(methodId: number) {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            // minHeight: 320,
            data: {
                title: `Deleting Payment Method`,
                message: `Funding Source will be deleted`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.companyPaymentMethodsService
                    .deleteMethod(methodId)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((res) => {
                        if (res) {
                            this.reload(true);
                        }
                    });
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.changingTabSubject.complete();
    }
}
