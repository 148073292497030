import {NgModule} from '@angular/core';
import {AccountInfoToolbarComponent} from './account-info-toolbar.component';
import {RbacModule} from '../../rbac/rbac.module';
import {SharedModule} from '../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';

@NgModule({
    declarations: [AccountInfoToolbarComponent],
    imports: [RbacModule, SharedModule, RouterModule],
    exports: [BrowserModule, AccountInfoToolbarComponent]
})
export class AccountInfoToolbarModule {}
