import {GenericEntity} from '../entites/generic.entity';

export class MembershipOrganization extends GenericEntity {
    public static provideTypes() {
        return ['association', 'MLS', 'board', 'other'];
    }

    id?: number | null = null;
    company_fk_id: number | null = null;
    title: string | null = null;
    membership_types: string[] = [];
    type: string | null = null;

    public static get type_SET() {
        return {
            association: 'association',
            MLS: 'MLS',
            board: 'board',
            other: 'other'
        };
    }

    public static get type_LABELS() {
        return {
            association: 'Association',
            MLS: 'MLS',
            board: 'Board',
            other: 'Other'
        };
    }

    setType(type: string) {
        this.type = type;
        return this;
    }

    setTitle(title: string) {
        this.title = title;
        return this;
    }

    setMembershipTypes(membershipTypes: string[]) {
        this.membership_types = membershipTypes;
        return this;
    }
}
