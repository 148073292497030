<div
    class="audit-container p-2"
    [class.selected]="detailsVisible"
    (click)="triggerDetailsVisibility()"
    *ngIf="auditLog"
>
    <div class="d-flex">
        <div class="date">{{ auditLog.created_at | date: 'medium' }}</div>
        <div class="type">{{ auditLog.target }}</div>
        <div class="type">{{ auditLog.event }}</div>
        <div class="expand-col text-right">
            <mat-icon *ngIf="!detailsVisible">expand_more</mat-icon>
            <mat-icon *ngIf="detailsVisible">expand_less</mat-icon>
        </div>
    </div>

    <div class="w-100">
        <div *ngIf="auditLog.profile" class="user-data">
            by <b>{{ auditLog.profile.email }}</b>
        </div>
        <div *ngIf="auditLog.initiator__profile_fk_id && !auditLog.profile" class="user-data">by <b>(DELETED)</b></div>
        <div *ngIf="!auditLog.initiator__profile_fk_id && !auditLog.profile" class="user-data">by <b>(SYSTEM)</b></div>
    </div>
</div>

<div *ngIf="detailsVisible" class="audit-data-container">
    <div *ngFor="let change of readableChanges">
        <div>
            <div class="audit-label">{{ change.label }}</div>

            <div *ngIf="change.type === 'image'">
                <div *ngIf="change.before !== null && change.after !== null">was changed</div>
                <div *ngIf="change.before === null && change.after !== null">was uploaded</div>
                <div *ngIf="change.before !== null && change.after === null">was removed</div>
            </div>

            <div *ngIf="change.type !== 'image'">
                <div class="d-flex">
                    <div>set to</div>

                    <div class="changed-data" *ngIf="change.type === 'date'">
                        {{ change.after | date: 'yMMMd' }}
                    </div>
                    <div class="changed-data" *ngIf="change.type === 'currency'">
                        {{ change.after | currency: 'USD' : 'symbol' }}
                    </div>
                    <div
                        class="changed-data"
                        *ngIf="
                            change.type && ['string', 'number', 'percentage', 'callback'].indexOf(change.type) !== -1
                        "
                    >
                        {{ change.after }}
                    </div>
                </div>

                <div *ngIf="auditLog && auditLog.event === 'update'" class="d-flex">
                    <div>previous value was</div>
                    <div class="changed-data" *ngIf="change.type === 'date'">
                        {{ change.before | date: 'yMMMd' }}
                    </div>
                    <div class="changed-data" *ngIf="change.type === 'currency'">
                        {{ change.before | currency: 'USD' : 'symbol' }}
                    </div>
                    <div
                        class="changed-data"
                        *ngIf="
                            change.type && ['string', 'number', 'percentage', 'callback'].indexOf(change.type) !== -1
                        "
                    >
                        {{ change.before }}
                    </div>
                </div>
            </div>
        </div>

        <!--<span *ngIf="getFieldData(data.target + '.' + data.field) && data.after !== 0">-->
        <!--<div *ngIf="fieldSettings" class="history">-->
        <!--<b>{{fieldSettings? fieldSettings.label : data.target + '.' + data.field}}</b>-->
        <!--<span *ngIf="audit.event !== 'delete' && fieldSettings?.type !== 'changed'">-->
        <!--set to-->
        <!--</span>-->
        <!--<span *ngIf="audit.event === 'delete'">-->
        <!--was-->
        <!--</span>-->
        <!--<span *ngIf="fieldSettings.type === 'currency'">-->
        <!--<span class="changed-data" >-->
        <!--{{data.after | currency: 'USD': 'symbol'}}-->
        <!--</span>-->
        <!--<span *ngIf="audit.event === 'edit'">-->
        <!--, previous value was <span class="changed-data">{{data.before | currency: 'USD': 'symbol'}}</span>-->
        <!--</span>-->
        <!--</span>-->
        <!--<span *ngIf="fieldSettings.type === 'date'">-->
        <!--<span class="changed-data" >-->
        <!--{{data.after | date: 'yMMMd'}}-->
        <!--</span>-->
        <!--<span *ngIf="audit.event === 'edit' && data.before">-->
        <!--, previous value was <span class="changed-data">{{data.before | date: 'yMMMd'}}</span>-->
        <!--</span>-->
        <!--</span>-->
        <!--<span *ngIf="!fieldSettings || (fieldSettings.type === 'number' || fieldSettings.type === 'string')">-->
        <!--<span class="changed-data" >-->
        <!--{{data.after}}-->
        <!--</span>-->
        <!--<span *ngIf="audit.event === 'edit'">-->
        <!--, previous value was <span class="changed-data">{{data.before}}</span>-->
        <!--</span>-->
        <!--</span>-->
        <!--<span *ngIf="fieldSettings.type === 'json'">-->
        <!--<span class="changed-data" >-->
        <!--{{getStringFromArray(data.after)}}-->
        <!--</span>-->
        <!--<span *ngIf="audit.event === 'edit'">-->
        <!--, previous value was <span class="changed-data">{{getStringFromArray(data.before)}}</span>-->
        <!--</span>-->
        <!--</span>-->
        <!--<span *ngIf="fieldSettings.type === 'boolean'">-->
        <!--<span class="changed-data" >-->
        <!--{{data.after? 'true' : 'false'}}-->
        <!--</span>-->
        <!--</span>-->
        <!--<span *ngIf="fieldSettings.type === 'changed'">-->
        <!--<span class="changed-data" >-->
        <!--{{data.after? 'was changed' : ''}}-->
        <!--</span>-->
        <!--</span>-->
        <!--</div>-->
        <!--</span>-->
        <!--<div *ngIf="!fieldSettings" class="history">-->
        <!--<b>{{data.target + '.' + data.field}}</b>-->
        <!--set to-->
        <!--<span class="changed-data" >-->
        <!--{{data.after}}-->
        <!--</span>-->
        <!--<span *ngIf="data.before">-->
        <!--, previous value was-->
        <!--<span class="changed-data" >-->
        <!--{{data.before}}-->
        <!--</span>-->
        <!--</span>-->
        <!--</div>-->
    </div>
</div>
