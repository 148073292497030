import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {IBill, IContact, IFinancialTransfer, IInvoice, IPaymentMade} from '@cyberco-nodejs/zipi-typings';
import {select, Store} from '@ngrx/store';
import {IContactsState} from '../../../../../contacts/store/contacts.reducer';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {NotificationsService} from 'angular2-notifications';
import {DealFinancialApiService} from '../../deal-financial.api.service';
import {map, takeUntil, tap} from 'rxjs/operators';
import {ProcessPayoutDialogComponent} from './process-payout-dialog/process-payout-dialog.component';
import {DealPayoutsProcessingService} from '../../deal-payouts-processing.service';
import {CheckPrintConfirmDialogComponent} from '../../../../../shared/components/check-print-confirm-dialog/check-print-confirm.dialog';
import {PaymentsMadeService} from '../../../../../finance/services/payments-made.service';
import {selectContacts} from '../../../../../contacts/store/contacts.selectors';
import {InvoicesService} from '../../../../../../services/api/finance/invoices.service';
import {BillsService} from '../../../../../../services/api/finance/bills.service';
import {Deal} from '../../../../../../models/deal';
import {GenericFormGroup} from '../../../../../../entites/generic.entity';
import {FinancialTransferEntity} from '../../../../../account-info/compensation/models/financial-transfer.entity';
import {DealProcessingService} from '../../deal-processing.service';
import {SalesEntity} from '../../../../../../models/sales-entity';
import {FINANCIAL_TRANSFER_TYPE_LABELS} from '../../../../../../local-typings';

@Component({
    selector: 'app-deal-payouts-card',
    template: `
        <mat-card style="margin: 8px 5px;">
            <mat-card-content>
                <div class="row">
                    <div class="col-6" style="font-size: 16px;">
                        {{ transferMoneyInGroup!.label ? transferMoneyInGroup!.label : 'N/A' }}
                    </div>
                    <div class="col-6" style="text-align: right; font-size: 16px;" *ngIf="transferMoneyInGroup!.type">
                        {{ FINANCIAL_TRANSFER_TYPE_LABELS[transferMoneyInGroup!.type] }}
                    </div>
                </div>

                <div class="d-flex justify-content-between w-100 my-2">
                    <div>
                        <span class="font-weight-bold">From: </span>
                        {{ transferMoneyInGroup!.sender ? senderContactName : 'N/A' }}
                    </div>
                    <div>
                        <span class="font-weight-bold">To: </span>
                        {{ transferMoneyInGroup!.receiver ? receiverContactName : 'N/A' }}
                    </div>
                </div>

                <div
                    class="d-flex justify-content-between w-100 my-2"
                    *ngIf="
                        (transferMoneyInGroup!.override && transferMoneyInGroup!.override.notes) ||
                        transferMoneyInGroup!.notes ||
                        salesEntity?.contact?.disbursement_instructions ||
                        salesEntity?.notes
                    "
                >
                    <div>
                        <span class="font-weight-bold">Notes: </span>
                        <ng-container
                            [ngTemplateOutlet]="
                                transferMoneyInGroup!.override?.notes !== null
                                    ? transferOverrideNotes
                                    : transferMoneyInGroup!.notes !== null
                                      ? transferNotes
                                      : salesEntity?.notes !== null
                                        ? seNotes
                                        : salesEntity?.contact?.disbursement_instructions !== null
                                          ? seContactInstructions
                                          : na
                            "
                        ></ng-container>
                        <ng-template #transferOverrideNotes>{{ transferMoneyInGroup!.override.notes }}</ng-template>
                        <ng-template #transferNotes>{{ transferMoneyInGroup!.notes }}</ng-template>
                        <ng-template #seNotes>{{ salesEntity?.notes }}</ng-template>
                        <ng-template #seContactInstructions>{{
                            salesEntity?.contact?.disbursement_instructions
                        }}</ng-template>
                        <ng-template #na>N/A</ng-template>
                    </div>
                    <div
                        *ngIf="
                            cardType !== 'remaining' ||
                            !transferMoneyInGroup.connected__bill_fk_id ||
                            !fullBill ||
                            !fullBill.payments ||
                            fullBill.payments.length !== 1
                        "
                    >
                        <span class="font-weight-bold">{{
                            formatAmount(transferMoneyInGroup.amount) | currency: 'USD' : 'symbol'
                        }}</span>
                    </div>
                </div>

                <ng-container
                    *ngIf="
                        !(
                            (transferMoneyInGroup!.override && transferMoneyInGroup!.override.notes) ||
                            transferMoneyInGroup!.notes ||
                            salesEntity?.contact?.disbursement_instructions ||
                            salesEntity?.notes
                        )
                    "
                >
                    <div
                        class="d-flex justify-content-end"
                        *ngIf="
                            (cardType !== 'remaining' ||
                                !transferMoneyInGroup.connected__bill_fk_id ||
                                !fullBill ||
                                !fullBill.payments ||
                                fullBill.payments.length !== 1) &&
                            !(
                                transferMoneyInGroup.type === 'deduct_transfer' &&
                                transferMoneyInGroup.connected__bill_fk_id
                            )
                        "
                    >
                        <span class="font-weight-bold">{{ currentAmount | currency: 'USD' : 'symbol' }}</span>
                    </div>
                </ng-container>

                <div class="d-flex justify-content-between w-100 my-2" *ngIf="isInvoiceLineNeeded">
                    <!--   *ngIf="isInvoiceToCreateValue && isCompanyPayoutValue"-->
                    <div>
                        <span class="font-weight-bold">Invoice: </span>
                        <span *ngIf="!transferMoneyInGroup || !transferMoneyInGroup.connected__invoice_fk_id"
                            >(not created)</span
                        >
                        <span
                            *ngIf="
                                transferMoneyInGroup!.connected__invoice_fk_id &&
                                transferMoneyInGroup!.connected_invoice
                            "
                        >
                            <a [routerLink]="['/sales/invoices/', transferMoneyInGroup.connected_invoice.invoice_id]">{{
                                transferMoneyInGroup.connected_invoice.invoice_number
                            }}</a>
                            (Status:
                            {{
                                fullInvoice
                                    ? (fullInvoice.summary_status | fromSnakeCase)
                                    : (transferMoneyInGroup.connected_invoice.summary_status | fromSnakeCase)
                            }})
                        </span>
                    </div>
                    <div *ngIf="transferMoneyInGroup!.connected_invoice">
                        {{ transferMoneyInGroup.connected_invoice.total_amount | currency }}
                    </div>
                </div>
                <div class="d-flex justify-content-between w-100 my-2" *ngIf="isBillLineNeeded">
                    <!--   *ngIf="!isInvoiceToCreateValue"-->
                    <div>
                        <span class="font-weight-bold">Bill: </span>
                        <span *ngIf="!transferMoneyInGroup || !transferMoneyInGroup.connected__bill_fk_id"
                            >(not created)</span
                        >
                        <span
                            *ngIf="transferMoneyInGroup!.connected__bill_fk_id && transferMoneyInGroup!.connected_bill"
                        >
                            <a [routerLink]="['/purchases/bills/', transferMoneyInGroup.connected_bill.bill_id]">{{
                                transferMoneyInGroup.connected_bill.bill_number
                            }}</a>
                            (Status: {{ transferMoneyInGroup.connected_bill.summary_status | fromSnakeCase }})
                        </span>
                    </div>
                    <div *ngIf="transferMoneyInGroup!.connected_bill">
                        {{ transferMoneyInGroup.connected_bill.total_amount | currency }}
                    </div>
                </div>

                <div
                    *ngIf="
                        cardType === 'remaining' &&
                        transferMoneyInGroup!.connected__bill_fk_id &&
                        fullBill &&
                        fullBill.payments &&
                        fullBill.payments.length === 1 &&
                        fullBill.payments[0]!.source_payment_made
                    "
                    class="d-flex justify-content-end"
                >
                    <a
                        [routerLink]="['/purchases/payments', fullBill.payments[0].source_payment_made.payment_made_id]"
                        href=""
                        >{{ currentAmount | currency: 'USD' : 'symbol' }}</a
                    >
                </div>

                <div>
                    <div *ngFor="let income of cardOtherIncomes">
                        <div
                            class="d-flex justify-content-between w-100 my-2"
                            *ngIf="!['sales_individual'].includes(income.type)"
                        >
                            <div>
                                <span
                                    >Transaction Settled on Behalf of {{ income.receiver.link_title }} &
                                    {{ income.sender.link_title }}</span
                                >
                            </div>
                            <div *ngIf="income">
                                {{ income.amount | currency }}
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div *ngFor="let expense of cardOtherExpenses">
                        <div
                            class="d-flex justify-content-between w-100 my-2"
                            *ngIf="!['sales_individual'].includes(expense.type)"
                        >
                            <div>
                                <span
                                    >Transaction Settled on Behalf of {{ expense.sender.link_title }} &
                                    {{ expense.receiver.link_title }}</span
                                >
                            </div>
                            <div *ngIf="expense">
                                {{ expense.amount | currency }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex" *ngIf="cardType === 'remaining' && !isInfoOnly" style="margin-top: 5px;">
                    <button
                        mat-raised-button
                        [matMenuTriggerFor]="billMenu"
                        *ngIf="
                            transferMoneyInGroup.connected__bill_fk_id &&
                            transferMoneyInGroup!.type !== 'deduct_transfer'
                        "
                        class="mr-2"
                        rbac
                        [rule]="{deals__manage_payouts: true}"
                    >
                        Disconnect Bill
                    </button>
                    <mat-menu #billMenu="matMenu">
                        <button
                            mat-menu-item
                            (click)="
                                disconnectFinEntity(
                                    transferMoneyInGroup!,
                                    transferMoneyInGroup!.connected__bill_fk_id!,
                                    'bill',
                                    false
                                )
                            "
                        >
                            Disconnect
                        </button>

                        <div
                            [matTooltip]="'You cannot Void Bill that has Payments'"
                            [matTooltipDisabled]="!fullBill || !fullBill.payments || fullBill.payments.length === 0"
                            *ngIf="fullBill"
                        >
                            <button
                                mat-menu-item
                                [disabled]="fullBill && fullBill.payments && fullBill.payments.length > 0"
                                (click)="
                                    disconnectFinEntity(
                                        transferMoneyInGroup!,
                                        transferMoneyInGroup!.connected__bill_fk_id!,
                                        'bill',
                                        true
                                    )
                                "
                            >
                                Disconnect and Void
                            </button>
                        </div>
                    </mat-menu>

                    <button
                        mat-raised-button
                        [matMenuTriggerFor]="invoiceMenu"
                        *ngIf="
                            transferMoneyInGroup!.connected__invoice_fk_id &&
                            transferMoneyInGroup!.type !== 'deduct_transfer'
                        "
                    >
                        Disconnect Invoice
                    </button>
                    <mat-menu #invoiceMenu="matMenu">
                        <button
                            mat-menu-item
                            (click)="
                                disconnectFinEntity(
                                    transferMoneyInGroup!,
                                    transferMoneyInGroup!.connected__invoice_fk_id!,
                                    'invoice',
                                    false
                                )
                            "
                        >
                            Disconnect
                        </button>

                        <div
                            [matTooltip]="'You cannot Void Invoice that has Payments'"
                            [matTooltipDisabled]="
                                !fullInvoice || !fullInvoice.payments || fullInvoice.payments.length === 0
                            "
                            *ngIf="fullInvoice"
                        >
                            <button
                                mat-menu-item
                                [disabled]="fullInvoice && fullInvoice.payments && fullInvoice.payments.length > 0"
                                (click)="
                                    disconnectFinEntity(
                                        transferMoneyInGroup!,
                                        transferMoneyInGroup!.connected__invoice_fk_id!,
                                        'invoice',
                                        true
                                    )
                                "
                            >
                                Disconnect and Void
                            </button>
                        </div>
                    </mat-menu>

                    <span class="sp-spacer"></span>
                    <button
                        mat-raised-button
                        class="ml-2"
                        *ngIf="
                            transferMoneyInGroup.connected__bill_fk_id &&
                            fullBill &&
                            fullBill.payments &&
                            fullBill.payments.length === 1 &&
                            fullBill.payments[0].source_payment_made &&
                            fullBill.payments[0].source_payment_made.check_info &&
                            fullBill.payments[0].source_payment_made.check_info.print_status === 'not_printed'
                        "
                        rbac
                        [rule]="{deals__manage_payouts: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        [disabled]="isProcessing"
                        (click)="printPaymentPdf(fullBill!.payments[0]!.source_payment_made!)"
                    >
                        Print Check
                    </button>

                    <span
                        style="color: red; text-align: center; margin: 8px 0 0 10px; font-size: 16px"
                        class="mr-1"
                        *ngIf="
                            !deal!.disbursement_approved &&
                            (!transferMoneyInGroup || !transferMoneyInGroup!.connected__bill_fk_id) &&
                            (!transferMoneyInGroup || !transferMoneyInGroup!.connected__invoice_fk_id)
                        "
                    >
                        Please approve disbursements
                    </span>

                    <button
                        mat-stroked-button
                        color="primary"
                        class="ml-1"
                        rbac
                        [rule]="{deals__manage_payouts: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        [disabled]="isProcessing || !deal!.disbursement_approved"
                        (click)="createInvoiceOrBill(transferMoneyInGroup!)"
                        *ngIf="
                            isInvoiceToCreateValue &&
                            isTransferValidValue &&
                            isCompanyPayoutValue &&
                            transferMoneyInGroup.type !== 'deduct_transfer'
                        "
                    >
                        Create Invoice
                    </button>

                    <button
                        mat-stroked-button
                        color="primary"
                        [matMenuTriggerFor]="appMenu"
                        class="ml-3 pr-2"
                        rbac
                        [rule]="{deals__manage_payouts: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        [disabled]="
                            isProcessing ||
                            !dealFormGroup ||
                            !dealFormGroup!.controls ||
                            !dealFormGroup!.controls!.disbursement_approved ||
                            !dealFormGroup!.controls!.disbursement_approved!.value ||
                            transferMoneyInGroup.processing_status === 'locked'
                        "
                        *ngIf="
                            !isInvoiceToCreateValue &&
                            isTransferValidValue &&
                            isCompanyPayoutValue &&
                            transferMoneyInGroup!.type !== 'deduct_transfer'
                        "
                    >
                        {{ transferMoneyInGroup.processing_status === 'locked' ? 'Processing...' : 'Process Payout' }}
                        <mat-icon *ngIf="transferMoneyInGroup.processing_status !== 'locked'">arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #appMenu="matMenu">
                        <button
                            mat-menu-item
                            [disabled]="!isMoneyTransferAvailable"
                            (click)="
                                completePayout.emit({action_type: 'money_transfer', payout_id: transferMoneyInGroup.id})
                            "
                        >
                            Money Transfers (EFT)
                        </button>

                        <button
                            mat-menu-item
                            (click)="completePayout.emit({action_type: 'record', payout_id: transferMoneyInGroup.id})"
                        >
                            Record Payment
                        </button>

                        <button
                            mat-menu-item
                            (click)="completePayout.emit({action_type: 'check', payout_id: transferMoneyInGroup.id})"
                        >
                            Create Check
                        </button>

                        <button
                            mat-menu-item
                            (click)="completePayout.emit({action_type: 'skip', payout_id: transferMoneyInGroup.id})"
                        >
                            Create Bill
                        </button>
                    </mat-menu>

                    <button
                        mat-stroked-button
                        color="primary"
                        class="ml-1 _track_pay_single_payout"
                        [disabled]="isProcessing"
                        (click)="payBillRedirect(transferMoneyInGroup!.connected__bill_fk_id)"
                        *ngIf="
                            isCompanyTransferBillValue &&
                            transferMoneyInGroup!.connected__bill_fk_id &&
                            transferMoneyInGroup!.connected_bill &&
                            transferMoneyInGroup!.connected_bill.status_of_payment === 'unpaid' &&
                            transferMoneyInGroup!.type !== 'deduct_transfer'
                        "
                    >
                        Pay
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    `
})
export class CardPayoutsComponent implements OnInit, OnDestroy, OnChanges {
    private unsubscribe: Subject<void> = new Subject();
    @Input() transferMoneyInGroup: FinancialTransferEntity | undefined;
    @Input() paymentTerms: any;
    @Input() cardType: any;
    @Input() dealFormGroup: GenericFormGroup<Deal> = new GenericFormGroup<Deal>(new Deal());
    @Input() lastUsedAccount: any;
    @Input() brokerReleasesAmount: number | undefined;
    @Input() releaseList: any;
    @Input() isInfoOnly: boolean | undefined;
    @Input() isMoneyTransferAvailable: boolean | undefined;
    @Input() createdBillId: any;

    @Output() updatePayouts = new EventEmitter();
    @Output() completePayout = new EventEmitter();

    invoices: IInvoice[] = [];
    bills: IBill[] = [];
    contacts: IContact[] = [];

    fullInvoice: IInvoice | undefined;
    fullBill: IBill | undefined;

    timer: NodeJS.Timeout | undefined;

    FINANCIAL_TRANSFER_TYPE_LABELS = FINANCIAL_TRANSFER_TYPE_LABELS;

    transfer: FinancialTransferEntity | null = null;
    blobUrl: Blob | null = null;
    paymentInfo: any = null;
    paymentMade: IPaymentMade | undefined;
    isProcessing = false;

    isInvoiceToCreateValue: boolean | undefined;
    isCompanyPayoutValue: boolean | undefined;
    isTransferValidValue: boolean | undefined;
    isCompanyTransferBillValue: boolean | undefined;
    isInvoiceLineNeeded: boolean | undefined;
    isBillLineNeeded: boolean | undefined;
    senderContactName: string | null = null;
    receiverContactName: string | null = null;
    salesEntity: SalesEntity | null = null;
    currentAmount: number | undefined;
    deal: Deal | undefined;

    cardOtherExpenses: IFinancialTransfer[] = [];
    cardOtherIncomes: IFinancialTransfer[] = [];

    constructor(
        private store: Store<IContactsState>,
        public dialog: MatDialog,
        public router: Router,
        private ntfs: NotificationsService,
        private dealFinancialApiService: DealFinancialApiService,
        public dealPayoutsProcessing: DealPayoutsProcessingService,
        private paymentsMadeService: PaymentsMadeService,
        private invoicesService: InvoicesService,
        private billsService: BillsService,
        public dealProcessing: DealProcessingService
    ) {}

    ngOnInit() {
        this.timer = setTimeout(() => this.loadFullFinanceEntities(), 1000);

        this.store
            .pipe(
                select(selectContacts), // migrate to ContactsLite: done
                takeUntil(this.unsubscribe)
            )
            .subscribe((contacts) => {
                this.contacts = contacts;
            });

        this.setupCard();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('brokerReleasesAmount') && changes.brokerReleasesAmount.currentValue) {
            this.currentAmount = this.formatAmount(this.transferMoneyInGroup!.amount!);
        }
        if (
            changes.hasOwnProperty('createdBillId') &&
            changes.createdBillId.currentValue &&
            !changes.createdBillId.firstChange
        ) {
            if (!changes.createdBillId.previousValue && changes.createdBillId.currentValue) {
                this.loadFullFinanceEntities();
                this.setupCard();
            }
        }
    }

    setupCard() {
        this.deal = this.dealFormGroup.getRawValue();
        this.isInvoiceToCreateValue = this.isInvoiceToCreate(this.transferMoneyInGroup!);
        this.isCompanyPayoutValue = this.isCompanyPayout(this.transferMoneyInGroup!);
        this.isTransferValidValue = this.isTransferValid(this.transferMoneyInGroup!);
        this.isCompanyTransferBillValue = this.isCompanyTransferBill(this.transferMoneyInGroup!);
        this.isInvoiceLineNeeded = !(
            this.transferMoneyInGroup?.type === FinancialTransferEntity.type_SET.associate_transfer &&
            !this.transferMoneyInGroup?.connected_invoice
        );
        this.isBillLineNeeded = !(
            this.transferMoneyInGroup?.type === FinancialTransferEntity.type_SET.associate_transfer &&
            !this.transferMoneyInGroup?.connected_bill
        );

        this.senderContactName = this.transferMoneyInGroup!.sender
            ? this.getContactName(this.transferMoneyInGroup!.sender.contact_id!)
            : null;
        this.receiverContactName = this.transferMoneyInGroup!.receiver
            ? this.getContactName(this.transferMoneyInGroup!.receiver.contact_id!)
            : null;
        this.currentAmount = this.formatAmount(this.transferMoneyInGroup!.amount!);

        if (this.deal.is_escrow_paid && this.transferMoneyInGroup!.type !== 'company_payout') {
            this.deal.financial_transfers.forEach((ft) => {
                if (
                    this.transferMoneyInGroup!.unique_hash === ft.payout_to_hash &&
                    ft.sender &&
                    this.transferMoneyInGroup!.sender!.contact_id !== ft.sender.contact_id &&
                    ft.sender.contact_id !== this.deal!.company!.main__contact_fk_id
                ) {
                    this.cardOtherIncomes.push(ft);
                }
                if (
                    this.transferMoneyInGroup!.unique_hash === ft.payout_from_hash &&
                    ft.receiver &&
                    this.transferMoneyInGroup!.receiver!.contact_id !== ft.receiver.contact_id &&
                    ft.receiver.contact_id !== this.deal!.company!.main__contact_fk_id
                ) {
                    this.cardOtherExpenses.push(ft);
                }
            });
        }

        this.salesEntity =
            this.dealProcessing.dealFG
                .getRawValue()
                .sales_entities.find((se) => se.contact.id === this.transferMoneyInGroup?.receiver?.contact_id) || null;
    }

    loadFullFinanceEntities() {
        if (this.transferMoneyInGroup!.connected__invoice_fk_id && this.cardType === 'remaining') {
            this.invoicesService
                .getInvoiceById(this.transferMoneyInGroup!.connected__invoice_fk_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((invoice) => {
                    if (invoice) {
                        this.fullInvoice = invoice;
                    }
                });
        }
        if (this.transferMoneyInGroup!.connected__bill_fk_id && this.cardType === 'remaining') {
            this.billsService
                .getBillById(this.transferMoneyInGroup!.connected__bill_fk_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((bill) => {
                    if (bill) {
                        if (this.transferMoneyInGroup) {
                            this.transferMoneyInGroup.connected_bill = bill;
                        }
                        this.fullBill = bill;
                    }
                });
        }
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    formatAmount(amount: number) {
        if (
            this.transferMoneyInGroup!.type === 'company_payout' &&
            this.transferMoneyInGroup!.receiver &&
            this.transferMoneyInGroup!.receiver.contact_id ===
                this.dealFormGroup.getRawValue().company!.main__contact_fk_id &&
            this.brokerReleasesAmount
        ) {
            return Number(amount) - Number(this.brokerReleasesAmount);
        } else {
            return Number(amount);
        }
    }

    emitUpdatePayouts() {
        this.updatePayouts.emit();
    }

    isTransferValid(transfer: FinancialTransferEntity) {
        return (
            transfer.amount !== null &&
            transfer.receiver !== null &&
            transfer.sender !== null &&
            !transfer.connected__invoice_fk_id &&
            !transfer.connected__bill_fk_id
        );
    }

    createInvoiceOrBill(transfer: FinancialTransferEntity) {
        if (this.isProcessing) {
            return;
        }
        this.isProcessing = true;

        const dialogRef = this.dialog.open(ProcessPayoutDialogComponent, {
            minWidth: 550,
            data: {
                transfers: [transfer],
                dealFormGroup: this.dealFormGroup,
                cardType: this.cardType,
                lastUsedAccount: this.lastUsedAccount,
                companyMainContactId: this.dealFormGroup.getRawValue().company!.main__contact_fk_id,
                releaseList: this.releaseList
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((paymentsInfo) => {
                if (!paymentsInfo) {
                    this.isProcessing = false;
                } else {
                    if (this.isInvoiceToCreate(transfer)) {
                        this.createInvoice(paymentsInfo[0], transfer);
                    }
                }
            });
    }

    payBillRedirect(bill_id: number) {
        this.router.navigate([`/purchases/bills/${bill_id}`]);
    }

    isInvoiceToCreate(transfer: FinancialTransferEntity) {
        if (
            transfer.receiver &&
            transfer.receiver.contact_id === this.dealFormGroup.getRawValue().company!.main__contact_fk_id
        ) {
            return true;
        }
        return false;
    }

    isCompanyPayout(transfer: FinancialTransferEntity) {
        if (
            (transfer.receiver &&
                transfer.receiver.contact_id === this.dealFormGroup.getRawValue().company!.main__contact_fk_id) ||
            (transfer.sender &&
                transfer.sender.contact_id === this.dealFormGroup.getRawValue().company!.main__contact_fk_id)
        ) {
            return true;
        }
        return false;
    }

    isCompanyTransferBill(transfer: FinancialTransferEntity) {
        if (
            transfer.sender &&
            transfer.sender.contact_id === this.dealFormGroup.getRawValue().company!.main__contact_fk_id
        ) {
            return true;
        }
        return false;
    }

    createInvoice(paymentInfo: any, transfer: FinancialTransferEntity) {
        const requestData = {
            financial_entity: this.dealPayoutsProcessing.getNewInvoiceObjectForPaymentProcessing(paymentInfo, transfer),
            payment_info: this.dealPayoutsProcessing.getNewInvoicePaymentObjectForPaymentProcessing(
                paymentInfo,
                transfer
            ),
            apply_releases: paymentInfo.apply_releases
        };

        // create invoice
        this.dealFinancialApiService
            .createDealInvoiceTransfer(requestData, transfer.id!)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                transfer.connected__invoice_fk_id = response.invoice_id!;
                transfer.connected_invoice = response;
                this.transfer = transfer;

                this.ntfs.success(`Payout processed successfully.`);
                this.isProcessing = false;
                this.updatePage();
            });
    }

    updatePage() {
        this.emitUpdatePayouts();

        const transfer = this.transfer;
        if (transfer) {
            const updatedFinTransfers = this.dealFormGroup.getRawValue().financial_transfers.map(function (item) {
                return item.id === transfer.id ? transfer : item;
            });

            this.dealFormGroup.controls.financial_transfers!.patchValue(updatedFinTransfers, {emitEvent: false});
            this.dealFormGroup.updateValueAndValidity();
        }
    }

    printPaymentPdf(paymentMade: IPaymentMade) {
        this.paymentMade = paymentMade;
        return this.initBlobUrl(this.print);
    }

    initBlobUrl(cb: any) {
        cb = cb.bind(this);
        this.paymentsMadeService
            .downloadCheckPdf(this.paymentMade!.payment_made_id!, {
                check_margins: true,
                show_background: false
            })
            .pipe(
                map((res) => new Blob([new Uint8Array(res.data)], {type: 'application/pdf'})),
                tap((result) => {
                    this.blobUrl = result;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => cb());
    }

    print() {
        const iframe = document.createElement('iframe');
        iframe.id = 'print-frame';
        iframe.style.display = 'none';
        if (this.blobUrl != null) {
            iframe.src = URL.createObjectURL(this.blobUrl);
        }
        iframe.onload = () => {
            setTimeout(() => {
                iframe.contentWindow!.print();
            }, 0);
        };
        if (this.paymentMade!.check_info) {
            this.printConfirm();
        }

        document.body.appendChild(iframe);
    }

    printConfirm() {
        const dialogRef = this.dialog.open(CheckPrintConfirmDialogComponent, {
            minWidth: 320,
            data: {
                payment_made_id: this.paymentMade!.payment_made_id,
                check_number: this.paymentMade!.check_info.check_number
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.loadFullFinanceEntities();
            });
    }

    getContactName(contact_id: number) {
        const transferContact: IContact | undefined = this.contacts.find(
            (contact) => contact.contact_id === contact_id
        );
        return typeof transferContact !== 'undefined' ? transferContact.display_name : 'N/A';
    }

    disconnectFinEntity(
        transfer: FinancialTransferEntity,
        finEntityId: number,
        finEntityType: 'bill' | 'invoice',
        isNeedToBeVoided: boolean
    ) {
        const requestData = {
            financial_entity_type: finEntityType,
            financial_entity_id: finEntityId,
            is_need_to_be_voided: isNeedToBeVoided
        };
        this.dealFinancialApiService
            .disconnectFinancialEntity(transfer.id!, requestData)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                transfer.connected__bill_fk_id = null;
                transfer.connected__invoice_fk_id = null;
                transfer.connected_bill = null;
                transfer.connected_invoice = null;
                this.transfer = transfer;
                this.updatePage();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.updatePayouts.complete();
    }
}
