import {Routes, RouterModule} from '@angular/router';

import {AuthBootstrapService} from 'app/services/resolvers/auth-bootstrap.service';
import {UserLayoutComponent} from 'app/layouts/user-layout.component';
import {DenyGlobalViewResolver} from '../../services/resolvers/deny-global-view.resolver';
import {BankingAccountsPageComponent} from './components/banking-accounts-page/banking-accounts-page.component';
import {BankingComponent} from './banking.component';
import {ImportTransactionsComponent} from './components/external-transactions/import-transactions/import-transactions.component';
import {BankTransactionsComponent} from './components/bank-transactions/bank-transactions.component';
import {CreateReconciliationComponent} from './components/reconciliations/create-reconciliation/create-reconciliation.component';
import {ReconciliationPageComponent} from './components/reconciliations/reconciliation-page/reconciliation-page.component';
import {AccessGuardService} from '../../services/resolvers/access-guard.service';

export const routes: Routes = [
    {
        path: 'banking',
        component: UserLayoutComponent,
        resolve: {
            denyGv: DenyGlobalViewResolver,
            authBootstrap: AuthBootstrapService
        },
        canActivate: [AccessGuardService],
        data: {
            title: 'Banking',
            access: {rule: {banking__access: true}}
        },
        children: [
            {
                path: '',
                component: BankingComponent,
                children: [
                    {
                        path: '',
                        component: BankingAccountsPageComponent
                    },
                    {
                        path: ':id/transactions',
                        component: BankTransactionsComponent,
                        canActivate: [AccessGuardService],
                        data: {
                            access: {rule: {banking__view_transactions: true}}
                        }
                    },
                    {
                        path: ':id/import',
                        component: ImportTransactionsComponent,
                        canActivate: [AccessGuardService],
                        data: {
                            access: {rule: {banking__view_import: true}}
                        }
                    },
                    {
                        path: ':id/reconciliations/create',
                        component: CreateReconciliationComponent,
                        canActivate: [AccessGuardService],
                        data: {
                            access: {rule: {banking__manage_reconciliation: true}}
                        }
                    },
                    {
                        path: ':id/reconciliations/:reconciliation-id',
                        component: ReconciliationPageComponent,
                        canActivate: [AccessGuardService],
                        data: {
                            access: {rule: {banking__view_reconciliation: true}}
                        }
                    },
                    {
                        path: ':id/reconciliations/:reconciliation-id/edit',
                        component: CreateReconciliationComponent,
                        canActivate: [AccessGuardService],
                        data: {
                            access: {rule: {banking__manage_reconciliation: true}}
                        }
                    }
                ]
            }
        ]
    }
];

export const routing = RouterModule.forChild(routes);
