import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {NotificationsServiceZipi} from '../../../../../notifications/notifications.service';
import {IReportsPreviewResultData} from '../../../../store/reports.reducer';
import {Subject} from 'rxjs';
import * as moment from 'moment';
import {isEqual} from 'lodash-es';
import {ChipNode} from '../../../../../account-info/compensation/models/chip-node';

@Component({
    selector: 'reports-preview-profit-loss',
    templateUrl: './profit-loss.component.html',
    styleUrls: ['./profit-loss.component.scss']
})
export class ProfitLossReportPreviewComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    // We will use it as a trick to be able to call Object.keys() or Object.values() in template
    protected readonly object = Object;

    @Input() report: any;
    @Input()
    /**
     * Set report data
     */
    set reportData(data: any) {
        const income = this.prepareTotalForChild(data.income);
        income.push({
            la_name: 'Total Operating Income',
            type: 'total',
            total: data.totals.income,
            groups: data.totals.groups.income
        });

        const cost_of_goods_sold = this.prepareTotalForChild(data.cost_of_goods_sold);
        cost_of_goods_sold.push({
            la_name: 'Total Cost of Goods Sold',
            type: 'total',
            total: data.totals.cost_of_goods_sold,
            groups: data.totals.groups.cost_of_goods_sold
        });

        const expense = this.prepareTotalForChild(data.expense);
        expense.push({
            la_name: 'Total Operating Expense',
            type: 'total',
            total: data.totals.expense,
            groups: data.totals.groups.expense
        });

        const otherIncome = this.prepareTotalForChild(data.other_income);
        const otherExpense = this.prepareTotalForChild(data.other_expense);
        otherExpense.push({
            la_name: 'Total Non Operating Income/Expense',
            type: 'total',
            total: data.totals.non_operating_income_expense,
            groups: data.totals.groups.non_operating_income_expense
        });

        this._reportData = {
            ...data,
            income: income,
            cost_of_goods_sold: cost_of_goods_sold,
            expense: expense,
            other_expense: otherExpense,
            other_income: otherIncome
        };

        this.incomeDataSource.data = this._reportData!.income;
        this.costOfGoodsDataSource.data = this._reportData!.cost_of_goods_sold;
        this.expenseDataSource.data = this._reportData!.expense;
        this.otherExpenseDataSource.data = this._reportData!.other_expense;
        this.otherIncomeDataSource.data = this._reportData!.other_income;

        this.treeControlIncome.dataNodes = this._reportData!.income;
        this.treeControlCostOfGoods.dataNodes = this._reportData!.cost_of_goods_sold;
        this.treeControlExpense.dataNodes = this._reportData!.expense;
        this.treeControlOtherExpense.dataNodes = this._reportData!.other_expense;
        this.treeControlOtherIncome.dataNodes = this._reportData!.other_income;

        if (data.isMaxGroups) {
            this.notificationsZipiService.addWarning(
                `Report too wide. Currently we support up to ${data.maxCompareColumnCount} comparisons.`
            );
        }
    }

    /**
     * Get report data
     */
    get reportData() {
        return this._reportData;
    }

    @Output() toggleSideNavEvent: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: any}>();

    _reportData: {
        cost_of_goods_sold: Array<any>;
        expense: Array<any>;
        income: Array<any>;
        other_expense: Array<any>;
        other_income: Array<any>;
        groups: Array<any>;
        totals: {
            income: number;
            cost_of_goods_sold: number;
            expense: number;
            other_expense: number;
            gross_profit: number;
            operating_profit: number;
            net_profit: number;
        };
    } | null = null;

    incomeDataSource = new MatTreeNestedDataSource<any>();
    costOfGoodsDataSource = new MatTreeNestedDataSource<any>();
    expenseDataSource = new MatTreeNestedDataSource<any>();
    otherExpenseDataSource = new MatTreeNestedDataSource<any>();
    otherIncomeDataSource = new MatTreeNestedDataSource<any>();
    treeControlIncome = new NestedTreeControl<any>((node) => node.children);
    treeControlCostOfGoods = new NestedTreeControl<any>((node) => node.children);
    treeControlExpense = new NestedTreeControl<any>((node) => node.children);
    treeControlOtherExpense = new NestedTreeControl<any>((node) => node.children);
    treeControlOtherIncome = new NestedTreeControl<any>((node) => node.children);
    isExpand: boolean = false;
    expandTooltip: string = 'Expand All';

    constructor(private notificationsZipiService: NotificationsServiceZipi) {}

    /**
     * Check is tree has child item
     * @param _
     * @param node
     */
    hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

    /**
     * Prepare total for child
     * @param data
     */
    prepareTotalForChild = (data: Array<any>) => {
        const result: IReportsPreviewResultData[] = [];
        data.forEach((item) => {
            if (item.children && item.children.length) {
                const children = item.children.slice(0);
                children.push({
                    la_name: `Total ${item.la_name}`,
                    type: 'total',
                    total: item.total_with_children,
                    total_with_children: item.total_with_children,
                    groups: item.groups
                });
                result.push({
                    ...item,
                    children: this.prepareTotalForChild(children)
                });
            } else {
                result.push(item);
            }
        });

        return result;
    };

    /**
     * Change state node
     * @param node
     */
    changeState(node: {expanded: boolean}) {
        node.expanded = !node.expanded;
    }

    /**
     * Expand All
     */
    toggleExpand() {
        if (this.isExpand) {
            this.treeControlIncome.collapseAll();
            this.treeControlCostOfGoods.collapseAll();
            this.treeControlExpense.collapseAll();
            this.treeControlOtherExpense.collapseAll();
            this.treeControlOtherIncome.collapseAll();
            this.expandTooltip = 'Expand All';
        } else {
            this.treeControlIncome.expandAll();
            this.treeControlCostOfGoods.expandAll();
            this.treeControlExpense.expandAll();
            this.treeControlOtherExpense.expandAll();
            this.treeControlOtherIncome.expandAll();
            this.expandTooltip = 'Collapse All';
        }
        this.isExpand = !this.isExpand;
    }

    keyValueCompareFn() {
        return 0;
    }

    openSideNav(
        node: any,
        la_type: string | undefined,
        dataSource: any,
        compareSliceIndex: number | undefined = undefined,
        isExpanded: boolean | undefined = undefined,
        groupName: string | undefined = undefined
    ) {
        let la_array = [];
        if (node.type === 'total') {
            la_array = this.findLedgerAccountIdsForTotal(dataSource, node);
        } else {
            la_array = isExpanded ? (node.la_id ? [node.la_id] : []) : this._extractLedgerAccountIdsFromNode(node);
        }

        const ledgerAccountData = {
            la_array: la_array ? la_array.join(',') : ''
        };

        const targetDates = {
            startDate: 0,
            endDate: 0
        };

        if (groupName && this._reportData && this.report.settings.compare_to_periods?.periods.length) {
            const currentGroupInfo = this._reportData.groups.find((g) => g.entity_id === groupName);
            targetDates.startDate = Number(currentGroupInfo.startDate);
            targetDates.endDate = Number(currentGroupInfo.endDate);
        } else {
            const date = this._getTimePeriodByPeriodType(
                this.report.settings.time_period,
                'YYYY-MM-DD',
                this.report.settings.time_period_start,
                this.report.settings.time_period_end,
                this.report.settings.compare_results,
                compareSliceIndex
            );
            targetDates.startDate = Number(date.startDate.split('-').join(''));
            targetDates.endDate = Number(date.endDate.split('-').join(''));
        }

        let divisions;
        if (this.report.settings.compare_results === 'division' && typeof compareSliceIndex === 'number') {
            const divisionFromGroups = Object.keys(node.groups)[compareSliceIndex];
            if (divisionFromGroups) {
                divisions = [{target_id: divisionFromGroups}];
            }
        } else if (this.report.settings.compare_results === 'division' && typeof compareSliceIndex === 'undefined') {
            divisions = [...this.report.settings.divisions, {target_id: 'no_group'}];
        }

        const settings = {
            compare_results: this.report.settings.compare_results || '',
            divisions: divisions && divisions.length ? divisions.map((d: ChipNode) => d.target_id).join(',') : '',
            financial_deals: this.report.settings.financial_deals || '',
            is_include_no_division: (this.report.settings.is_include_no_division ? 1 : 0) || '',
            report_basis: this.report.settings.report_basis || '',
            tags: this.report.settings.tags || '',
            start_date: targetDates.startDate,
            end_date: targetDates.endDate
        };

        this.toggleSideNavEvent.next(Object.assign(ledgerAccountData, settings));
    }

    get _timePeriodListOfType(): {[key: string]: string} {
        return {
            today: 'today',
            yesterday: 'yesterday',
            this_week: 'this_week',
            this_month: 'this_month',
            this_quarter: 'this_quarter',
            this_year: 'this_year',
            next_week: 'next_week',
            next_month: 'next_month',
            next_quarter: 'next_quarter',
            next_year: 'next_year',
            last_week: 'last_week',
            last_month: 'last_month',
            last_quarter: 'last_quarter',
            last_year: 'last_year',
            date_range: 'date_range'
        };
    }

    _getTimePeriodByPeriodType(
        time_period: string,
        dateFormat: string = 'YYYY-MM-DD',
        startPeriod: string,
        endPeriod: string,
        compareResults: string,
        idx: number | undefined
    ): {startDate: any; endDate: any} {
        let startDate = moment();
        let endDate = moment();
        const timePeriodTypes = this._timePeriodListOfType;

        switch (time_period) {
            case timePeriodTypes.today:
                startDate = moment().startOf('date');
                endDate = moment().endOf('date');
                break;
            case timePeriodTypes.yesterday:
                startDate = moment().subtract(1, 'days').startOf('date');
                endDate = moment().subtract(1, 'days').endOf('date');
                break;
            case timePeriodTypes.this_week:
                startDate = moment().startOf('week');
                endDate = moment().endOf('week');
                break;
            case timePeriodTypes.this_month:
                startDate = moment().startOf('month');
                endDate = moment().endOf('month');
                break;
            case timePeriodTypes.this_quarter:
                if (['not_compare', 'division'].includes(compareResults) || typeof idx === 'undefined') {
                    startDate = moment().startOf('quarter');
                    endDate = moment().endOf('quarter');
                } else if (compareResults === 'month') {
                    switch (idx) {
                        case 0:
                            startDate = moment().startOf('quarter');
                            endDate = moment().startOf('quarter').endOf('month');
                            break;
                        case 1:
                            startDate = moment().startOf('quarter').add(1, 'M');
                            endDate = moment().startOf('quarter').add(1, 'M').endOf('month');
                            break;
                        case 2:
                            startDate = moment().endOf('quarter').startOf('month');
                            endDate = moment().endOf('quarter');
                            break;
                        default:
                            startDate = moment().startOf('quarter');
                            endDate = moment().endOf('quarter');
                            break;
                    }
                } else {
                    startDate = moment().startOf('quarter');
                    endDate = moment().endOf('quarter');
                }
                break;
            case timePeriodTypes.this_year:
                if (['not_compare', 'division'].includes(compareResults) || typeof idx === 'undefined') {
                    startDate = moment().startOf('year');
                    endDate = moment().endOf('year');
                } else if (compareResults === 'month') {
                    switch (idx) {
                        case 0:
                            startDate = moment().startOf('year');
                            endDate = moment().startOf('year').endOf('month');
                            break;
                        case 1:
                            startDate = moment().startOf('year').add(1, 'M');
                            endDate = moment().startOf('year').add(1, 'M').endOf('month');
                            break;
                        case 2:
                            startDate = moment().startOf('year').add(2, 'M');
                            endDate = moment().startOf('year').add(2, 'M').endOf('month');
                            break;
                        case 3:
                            startDate = moment().startOf('year').add(3, 'M');
                            endDate = moment().startOf('year').add(3, 'M').endOf('month');
                            break;
                        case 4:
                            startDate = moment().startOf('year').add(4, 'M');
                            endDate = moment().startOf('year').add(4, 'M').endOf('month');
                            break;
                        case 5:
                            startDate = moment().startOf('year').add(5, 'M');
                            endDate = moment().startOf('year').add(5, 'M').endOf('month');
                            break;
                        case 6:
                            startDate = moment().startOf('year').add(6, 'M');
                            endDate = moment().startOf('year').add(6, 'M').endOf('month');
                            break;
                        case 7:
                            startDate = moment().startOf('year').add(7, 'M');
                            endDate = moment().startOf('year').add(7, 'M').endOf('month');
                            break;
                        case 8:
                            startDate = moment().startOf('year').add(8, 'M');
                            endDate = moment().startOf('year').add(8, 'M').endOf('month');
                            break;
                        case 9:
                            startDate = moment().startOf('year').add(9, 'M');
                            endDate = moment().startOf('year').add(9, 'M').endOf('month');
                            break;
                        case 10:
                            startDate = moment().startOf('year').add(10, 'M');
                            endDate = moment().startOf('year').add(10, 'M').endOf('month');
                            break;
                        case 11:
                            startDate = moment().endOf('year').startOf('month');
                            endDate = moment().endOf('year');
                            break;
                        default:
                            startDate = moment().startOf('year');
                            endDate = moment().endOf('year');
                            break;
                    }
                } else if (compareResults === 'quarter') {
                    switch (idx) {
                        case 0:
                            startDate = moment().startOf('year');
                            endDate = moment().startOf('year').endOf('quarter');
                            break;
                        case 1:
                            startDate = moment().startOf('year').add(1, 'Q');
                            endDate = moment().startOf('year').add(1, 'Q').endOf('quarter');
                            break;
                        case 2:
                            startDate = moment().startOf('year').add(2, 'Q');
                            endDate = moment().startOf('year').add(2, 'Q').endOf('quarter');
                            break;
                        case 3:
                            startDate = moment().endOf('year').startOf('quarter');
                            endDate = moment().endOf('year');
                            break;
                        default:
                            startDate = moment().startOf('year');
                            endDate = moment().endOf('year');
                            break;
                    }
                } else {
                    startDate = moment().startOf('year');
                    endDate = moment().endOf('year');
                }
                break;
            case timePeriodTypes.last_week:
                startDate = moment().subtract(1, 'weeks').startOf('week');
                endDate = moment().subtract(1, 'weeks').endOf('week');
                break;
            case timePeriodTypes.last_month:
                startDate = moment().subtract(1, 'months').startOf('month');
                endDate = moment().subtract(1, 'months').endOf('month');
                break;
            case timePeriodTypes.last_quarter:
                if (['not_compare', 'division'].includes(compareResults) || typeof idx === 'undefined') {
                    startDate = moment().subtract(1, 'quarters').startOf('quarter');
                    endDate = moment().subtract(1, 'quarters').endOf('quarter');
                } else if (compareResults === 'month') {
                    switch (idx) {
                        case 0:
                            startDate = moment().subtract(1, 'quarters').startOf('quarter');
                            endDate = moment().subtract(1, 'quarters').startOf('quarter').endOf('month');
                            break;
                        case 1:
                            startDate = moment().subtract(1, 'quarters').startOf('quarter').add(1, 'M');
                            endDate = moment().subtract(1, 'quarters').startOf('quarter').add(1, 'M').endOf('month');
                            break;
                        case 2:
                            startDate = moment().subtract(1, 'quarters').endOf('quarter').startOf('month');
                            endDate = moment().subtract(1, 'quarters').endOf('quarter');
                            break;
                        default:
                            startDate = moment().subtract(1, 'quarters').startOf('quarter');
                            endDate = moment().subtract(1, 'quarters').endOf('quarter');
                            break;
                    }
                } else {
                    startDate = moment().subtract(1, 'quarters').startOf('quarter');
                    endDate = moment().subtract(1, 'quarters').endOf('quarter');
                }
                break;
            case timePeriodTypes.last_year:
                if (['not_compare', 'division'].includes(compareResults) || typeof idx === 'undefined') {
                    startDate = moment().subtract(1, 'years').startOf('year');
                    endDate = moment().subtract(1, 'years').endOf('year');
                } else if (compareResults === 'month') {
                    switch (idx) {
                        case 0:
                            startDate = moment().subtract(1, 'years').startOf('year');
                            endDate = moment().subtract(1, 'years').startOf('year').endOf('month');
                            break;
                        case 1:
                            startDate = moment().subtract(1, 'years').startOf('year').add(1, 'M');
                            endDate = moment().subtract(1, 'years').startOf('year').add(1, 'M').endOf('month');
                            break;
                        case 2:
                            startDate = moment().subtract(1, 'years').startOf('year').add(2, 'M');
                            endDate = moment().subtract(1, 'years').startOf('year').add(2, 'M').endOf('month');
                            break;
                        case 3:
                            startDate = moment().subtract(1, 'years').startOf('year').add(3, 'M');
                            endDate = moment().subtract(1, 'years').startOf('year').add(3, 'M').endOf('month');
                            break;
                        case 4:
                            startDate = moment().subtract(1, 'years').startOf('year').add(4, 'M');
                            endDate = moment().subtract(1, 'years').startOf('year').add(4, 'M').endOf('month');
                            break;
                        case 5:
                            startDate = moment().subtract(1, 'years').startOf('year').add(5, 'M');
                            endDate = moment().subtract(1, 'years').startOf('year').add(5, 'M').endOf('month');
                            break;
                        case 6:
                            startDate = moment().subtract(1, 'years').startOf('year').add(6, 'M');
                            endDate = moment().subtract(1, 'years').startOf('year').add(6, 'M').endOf('month');
                            break;
                        case 7:
                            startDate = moment().subtract(1, 'years').startOf('year').add(7, 'M');
                            endDate = moment().subtract(1, 'years').startOf('year').add(7, 'M').endOf('month');
                            break;
                        case 8:
                            startDate = moment().subtract(1, 'years').startOf('year').add(8, 'M');
                            endDate = moment().subtract(1, 'years').startOf('year').add(8, 'M').endOf('month');
                            break;
                        case 9:
                            startDate = moment().subtract(1, 'years').startOf('year').add(9, 'M');
                            endDate = moment().subtract(1, 'years').startOf('year').add(9, 'M').endOf('month');
                            break;
                        case 10:
                            startDate = moment().subtract(1, 'years').startOf('year').add(10, 'M');
                            endDate = moment().subtract(1, 'years').startOf('year').add(10, 'M').endOf('month');
                            break;
                        case 11:
                            startDate = moment().subtract(1, 'years').endOf('year').startOf('month');
                            endDate = moment().subtract(1, 'years').endOf('year');
                            break;
                        default:
                            startDate = moment().subtract(1, 'years').startOf('year');
                            endDate = moment().subtract(1, 'years').endOf('year');
                            break;
                    }
                } else if (compareResults === 'quarter') {
                    switch (idx) {
                        case 0:
                            startDate = moment().subtract(1, 'years').startOf('year');
                            endDate = moment().subtract(1, 'years').startOf('year').endOf('quarter');
                            break;
                        case 1:
                            startDate = moment().subtract(1, 'years').startOf('year').add(1, 'Q');
                            endDate = moment().subtract(1, 'years').startOf('year').add(1, 'Q').endOf('quarter');
                            break;
                        case 2:
                            startDate = moment().subtract(1, 'years').startOf('year').add(2, 'Q');
                            endDate = moment().subtract(1, 'years').startOf('year').add(2, 'Q').endOf('quarter');
                            break;
                        case 3:
                            startDate = moment().subtract(1, 'years').endOf('year').startOf('quarter');
                            endDate = moment().subtract(1, 'years').endOf('year');
                            break;
                        default:
                            startDate = moment().subtract(1, 'years').startOf('year');
                            endDate = moment().subtract(1, 'years').endOf('year');
                            break;
                    }
                } else {
                    startDate = moment().subtract(1, 'years').startOf('year');
                    endDate = moment().subtract(1, 'years').endOf('year');
                }
                break;
            case timePeriodTypes.date_range:
                if (!startPeriod) {
                    startDate = moment();
                }
                if (!endPeriod) {
                    endDate = moment();
                }
                let startPeriodMoment = moment(startPeriod, dateFormat);
                let endDateMoment = moment(endPeriod, dateFormat);
                if (!startPeriodMoment.isValid()) {
                    startPeriodMoment = moment(moment(), dateFormat);
                }
                if (!endDateMoment.isValid()) {
                    endDateMoment = moment(moment(), dateFormat);
                }

                if (['not_compare', 'division'].includes(compareResults) || typeof idx === 'undefined') {
                    startDate = startPeriodMoment.clone().startOf('date');
                    endDate = endDateMoment.clone().endOf('date');
                } else if (compareResults === 'month') {
                    const startMonth = moment(startPeriod, dateFormat).startOf('month');
                    const endMonth = moment(endPeriod, dateFormat).startOf('month');

                    for (let i = 0; i <= endMonth.diff(startMonth, 'month'); i++) {
                        if (i === idx) {
                            startDate =
                                idx === 0
                                    ? startPeriodMoment.clone().startOf('date')
                                    : startPeriodMoment.clone().add(i, 'month').startOf('month');
                            endDate =
                                endMonth.diff(startMonth, 'month') === idx
                                    ? endDateMoment.clone().endOf('date')
                                    : startPeriodMoment.clone().add(i, 'month').endOf('month');
                        }
                    }
                } else if (compareResults === 'quarter') {
                    const startQuarter = moment(startPeriod, dateFormat).startOf('quarter');
                    const endQuarter = moment(endPeriod, dateFormat).startOf('quarter');

                    for (let i = 0; i <= endQuarter.diff(startQuarter, 'quarter'); i++) {
                        if (i === idx) {
                            startDate =
                                idx === 0
                                    ? startPeriodMoment.clone().startOf('date')
                                    : startPeriodMoment.clone().add(i, 'quarter').startOf('quarter');
                            endDate =
                                endQuarter.diff(startQuarter, 'quarter') === idx
                                    ? endDateMoment.clone().endOf('date')
                                    : startPeriodMoment.clone().add(i, 'quarter').endOf('quarter');
                        }
                    }
                } else if (compareResults === 'year') {
                    const startYear = Number(moment(startPeriod, dateFormat).startOf('year').format('YYYY'));
                    const endYear = Number(moment(endPeriod, dateFormat).startOf('year').format('YYYY'));

                    for (let i = 0; i <= endYear - startYear; i++) {
                        if (i === idx) {
                            startDate =
                                idx === 0
                                    ? startPeriodMoment.clone().startOf('date')
                                    : startPeriodMoment.clone().add(i, 'year').startOf('year');
                            endDate =
                                idx + startYear === endYear
                                    ? endDateMoment.clone().endOf('date')
                                    : startPeriodMoment.clone().add(i, 'year').endOf('year');
                        }
                    }
                } else {
                    startDate = startPeriodMoment.clone().startOf('date');
                    endDate = endDateMoment.clone().endOf('date');
                }
                break;
            default:
        }

        return {
            startDate: startDate ? startDate.format(dateFormat) : null,
            endDate: endDate ? endDate.format(dateFormat) : null
        };
    }

    _extractLedgerAccountIdsFromNode(node: any) {
        const array = [];
        if (node.type !== 'total') {
            if (node.children && node.children.length) {
                array.push(node.la_id);
                node.children.forEach((cur: any) => {
                    if (cur.children && cur.children.length) {
                        array.push(cur.la_id);
                        for (const child of cur.children) {
                            if (child.type !== 'total') {
                                array.push(...this._extractLedgerAccountIdsFromNode(child));
                            }
                        }
                    } else if (cur.type !== 'total') {
                        array.push(cur.la_id);
                    }
                });
            } else {
                array.push(node.la_id);
            }
        }

        return Array.from(new Set(array.filter((id) => id)));
    }

    findLedgerAccountIdsForTotal(node: any[], current_node: any, upper_node: any = undefined): any[] {
        // find nodes which create this total
        if (node.some((n) => isEqual(current_node, n))) {
            const other = node.filter((n) => !isEqual(current_node, n));
            other.push(upper_node);
            return Array.from(
                new Set(
                    other
                        .filter((n) => n)
                        .map((n) => this._extractLedgerAccountIdsFromNode(n))
                        .flat()
                )
            );
        } else {
            return node
                .map((n) => {
                    if (n.children && n.children.length) {
                        const {children, ...current_node_without_children} = current_node;
                        return this.findLedgerAccountIdsForTotal(n.children, current_node_without_children, n);
                    }
                })
                .flat()
                .filter((n) => n);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.toggleSideNavEvent.complete();
    }
}
