import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ContactClass} from '../../../../models/contact-class';
import {ContactClassService} from '../../../../services/api/contact-class.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NotificationsService} from 'angular2-notifications';
import {Profile} from 'app/models/profile';

@Component({
    selector: 'app-contact-class-creation-dialog',
    template: `
        <div>
            <mat-dialog-content>
                <div class="d-flex flex-row align-items-baseline">
                    <h1 mat-dialog-title style="margin-right: 15px">Create/Edit Class</h1>
                    <mat-form-field>
                        <mat-select
                            [(ngModel)]="currentContactClass"
                            (selectionChange)="selectedContactClassChange($event.value)"
                            placeholder="Select Class"
                        >
                            <mat-option class="text-muted font-italic" [value]="newContactClass">Create New</mat-option>
                            <mat-option *ngFor="let contactClass of contactClasses" [value]="contactClass">
                                {{ contactClass.title }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="d-flex flex-column">
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field ngDefaultControl class="w-100">
                                <input
                                    [(ngModel)]="contactClassTitle"
                                    matInput
                                    type="text"
                                    placeholder="Title"
                                    minlength="3"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-dialog-content>
            <mat-dialog-actions class="text-right">
                <div class="d-flex justify-content-between w-100 py-2">
                    <button
                        type="button"
                        color="warn"
                        mat-raised-button
                        tabindex="-1"
                        (click)="deleteContactClass()"
                        [disabled]="!isEditMode(currentContactClass)"
                    >
                        Delete
                    </button>

                    <div>
                        <button type="button" mat-raised-button tabindex="-1" (click)="dialogRef.close()">
                            Cancel
                        </button>
                        <button
                            type="button"
                            mat-raised-button
                            color="primary"
                            tabindex="1"
                            (click)="saveContactClass()"
                        >
                            {{ isEditMode(currentContactClass) ? 'Update' : 'Create' }}
                        </button>
                    </div>
                </div>
            </mat-dialog-actions>
        </div>
    `
})
export class ContactClassCreationDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    contactClass = ContactClass;
    contactClasses: ContactClass[] = [];
    profiles: Profile[] = [];

    newContactClass: ContactClass = new ContactClass();
    currentContactClass: ContactClass = new ContactClass();
    contactClassTitle: string | null = '';

    constructor(
        // private fb: FormBuilder,
        private ntfs: NotificationsService,
        public dialogRef: MatDialogRef<ContactClassCreationDialogComponent>,
        private contactClassService: ContactClassService,
        @Inject(MAT_DIALOG_DATA) public data: {contactClasses: ContactClass[]; profiles: Profile[]}
    ) {}

    selectedContactClassChange(value: string) {
        if (value) {
            this.contactClassTitle = this.currentContactClass.title;
        } else {
            this.contactClassTitle = null;
        }
    }

    saveContactClass() {
        if (!this.contactClassTitle || this.contactClassTitle.trim().length < 3) {
            return;
        }

        this.currentContactClass.title = this.contactClassTitle.trim();

        // update
        if (this.currentContactClass.id) {
            this.contactClassService
                .updateContactClass(this.currentContactClass.id, this.currentContactClass)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((success) => {
                    if (success) {
                        this.dialogRef.close({contactClass: this.currentContactClass, action: 'PUT'});
                    }
                }, this._handleError.bind(this));
        } else {
            // create
            this.contactClassService
                .createContactClass(this.currentContactClass)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((createdContactClass) => {
                    if (createdContactClass) {
                        this.dialogRef.close({contactClass: createdContactClass, action: 'POST'});
                    }
                }, this._handleError.bind(this));
        }
    }

    _handleError(err: string) {
        this.dialogRef.close();
        this.ntfs.error(err || 'Something went wrong. Please try later.');
    }

    deleteContactClass() {
        if (!this.currentContactClass.id) {
            return;
        }

        this.contactClassService
            .deleteContactClass(this.currentContactClass.id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.dialogRef.close({contactClass: this.currentContactClass, action: 'DELETE'});
            }, this._handleError.bind(this));
    }

    isEditMode(currentContactClass: ContactClass) {
        return !!(currentContactClass && currentContactClass.id);
    }

    ngOnInit(): void {
        this.contactClasses = this.data.contactClasses;
        this.profiles = this.data.profiles;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
