import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PaymentMethodsService} from '../../../profile/services/payment-methods.service';
import {IContact, IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CompanyGatewayService} from '../../../../services/api/finance/company-gateway.service';
import {ZipiFinancialIframeDialogComponent} from '../../../account-info/company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-financial-iframe-dialog/zipi-financial-iframe-dialog.component';
import {SessionService} from '../../../../services/session.service';
import {NotificationsServiceZipi} from '../../../notifications/notifications.service';
import {IZipiFinancialIframeDialogData} from '../../../../typings/zipi-financial-iframe';

@Component({
    selector: 'app-add-contact-zipi-fin-receive-only-dialog',
    templateUrl: './manage-my-payment-methods-added-by-contact-dialog.component.html',
    styleUrls: ['./manage-my-payment-methods-added-by-contact-dialog.component.scss']
})
export class ManageMyPaymentMethodsAddedByContactDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    contact: IContact | undefined;
    contactSettings:
        | {target_contact_id: number; customer_ref: string; target_contact_metatypes: Array<string>}
        | undefined;

    myZipiFinancialCardGateway: IPaymentGateway | null = null;
    myZipiFinancialBankGateway: IPaymentGateway | null = null;

    isGatewayLoaded: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ManageMyPaymentMethodsAddedByContactDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            contact: IContact;
            contact_settings: {
                target_contact_id: number;
                customer_ref: string;
                target_contact_metatypes: Array<string>;
            };
        },
        protected dialog: MatDialog,
        private companyGatewayService: CompanyGatewayService,
        private paymentMethodsService: PaymentMethodsService,
        private sessionService: SessionService,
        private notificationServiceZipi: NotificationsServiceZipi
    ) {}

    ngOnInit() {
        this.contact = this.data.contact;
        this.contactSettings = this.data.contact_settings;
        this.getMyGatewaysOnContact();
    }

    getMyGatewaysOnContact() {
        if (this.contact && this.contact.contact_id) {
            this.companyGatewayService
                .getMyZipiFinancialGateways(this.contact.contact_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateways) => {
                    this.myZipiFinancialBankGateway =
                        gateways.find((gateway) => gateway.type === 'zipi_financial_receive_only') || null;
                    this.myZipiFinancialCardGateway =
                        gateways.find((gateway) => gateway.type === 'zipi_financial_card_customer_shared') || null;
                    this.isGatewayLoaded = true;
                });
        }
    }

    setupZipiFinancialForContact(
        methodType: 'contact_bank' | 'contact_card',
        driverType: 'plaid' | 'moov' | 'muhnee' | 'tpp'
    ) {
        if (!this.contactSettings?.customer_ref || !this.contactSettings.target_contact_id) {
            return;
        }
        const currentGateway =
            methodType === 'contact_bank' ? this.myZipiFinancialBankGateway : this.myZipiFinancialCardGateway;
        const profile = this.sessionService.profile;
        const profileData = {
            company_name: profile && profile.company && profile.company.title ? profile.company.title : '',
            email: profile && profile.email ? profile.email : '',
            first_name: profile && profile.first_name ? profile.first_name : '',
            last_name: profile && profile.last_name ? profile.last_name : ''
        };
        const dialogRef = this.dialog.open<ZipiFinancialIframeDialogComponent, IZipiFinancialIframeDialogData>(
            ZipiFinancialIframeDialogComponent,
            {
                disableClose: true,
                maxHeight: '80vh',
                width: '650px',
                panelClass: 'custom-dialog-container',
                data: {
                    contactId: this.contactSettings.target_contact_id,
                    gateway: currentGateway,
                    paymentMethod: null,
                    methodType: methodType,
                    driverType: driverType,
                    accessMethod: 'internal',
                    isUniversal: true,
                    customerId: this.contactSettings.customer_ref,
                    prefillData: profileData,
                    creationSource: 'partner_company',
                    storeAccountMethod: 'required'
                }
            }
        );

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result.isError) {
                    this.notificationServiceZipi.addError(result.message);
                } else if (result.success) {
                    this.getMyGatewaysOnContact();
                }
            });
    }

    changeVisibleMode(method: IPaymentMethod) {
        if (method && method.payment_method_id) {
            this.paymentMethodsService
                .changeMode(method.payment_method_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((res) => {
                    if (res) {
                        method.is_visible_to_connect = !method.is_visible_to_connect;
                    }
                });
        }
    }

    deleteReceiveOnly(method: IPaymentMethod) {
        if (method && method.payment_method_id) {
            this.paymentMethodsService
                .deleteMethod(method.payment_method_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((res) => {
                    if (res) {
                        this.getMyGatewaysOnContact();
                    }
                });
        }
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
