import {Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {
    timePeriodObj,
    listOfInDirectoryOptions,
    listOfStatusInDirectoryOptions,
    listOfZipiPayOptions,
    listOfBooleanCondition,
    listOfStatusInDirectoryOptionsInactive
} from '../../../../constants';
import {getNextActivePanel, isLastPanelItem} from '../../helper';
import {CONTACT_METATYPES} from '../../../../../../local-typings';
import {IReport, IReportSetting, IReportSettingKeys} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-report-edit-bar-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactBarComponent implements OnInit, OnDestroy {
    @Output() valueChanges: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Output() action: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Input() report: IReport | null = null;

    private defaultValues: {[key: string]: any} = {
        time_period: timePeriodObj.this_year.value,
        contact_type: ['agent'],
        in_directory: 'any',
        status_in_directory: ['active'],
        zipi_pay_status: ['yes', 'no'],
        is_include_contact_payroll: false
    };

    public activePanel: string = 'time_period';
    public reportSettings: IReportSetting | null = null;
    public isShowNext: boolean = true;
    public listOfInDirectoryOptions: Array<{title: string; value: string}> = listOfInDirectoryOptions;
    public listOfStatusInDirectoryOptions: Array<{title: string; value: string}> = listOfStatusInDirectoryOptions;
    public listOfZipiPayOptions: Array<{title: string; value: string}> = listOfZipiPayOptions;
    public listOfBooleanCondition: Array<{title: string; value: boolean}> = listOfBooleanCondition;
    public listOfContactCategory: Array<{title: string; value: string}> = CONTACT_METATYPES.filter(
        (item) => item[0] !== 'delegate'
    ).map((item) => ({
        title: item[1],
        value: item[0]
    }));

    public reportOptions = [
        {
            value: 'time_period',
            title: 'Time Period'
        },
        {
            value: 'contact_type',
            title: 'Contact Type'
        },
        {
            title: 'In Directory',
            value: 'in_directory'
        },
        {
            title: 'Attributes',
            value: 'attributes'
        },
        {
            title: 'Addons',
            value: 'addons'
        },
        {
            value: 'is_include_contact_payroll',
            title: 'Include Payroll'
        }
    ];

    ngOnInit() {
        this.initData();
    }

    initData() {
        if (this.report) {
            this.reportSettings = {...this.defaultValues, ...this.report.settings};
        }
    }

    onSelectOptions(event: {value: string}) {
        this.activePanel = event.value;
        this.isShowNext = !isLastPanelItem(this.activePanel, this.reportOptions);
    }

    onValueChanges(property: IReportSettingKeys, data: any) {
        switch (property) {
            case 'time_period':
                this.reportSettings = {
                    ...this.reportSettings,
                    ...data
                };
                break;
            case 'in_directory':
                if (this.reportSettings) {
                    if (data === 'contacts_only') {
                        this.reportSettings['status_in_directory'] = [];
                        this.listOfStatusInDirectoryOptions = listOfStatusInDirectoryOptionsInactive;
                    } else {
                        this.listOfStatusInDirectoryOptions = listOfStatusInDirectoryOptions;
                    }
                    this.reportSettings[property] = data;
                }
                break;
            case 'contact_addons':
                if (data === 'contacts_only') {
                    if (this.reportSettings) {
                        this.reportSettings['status_in_directory'] = [];
                    }
                }
                this.reportSettings = {
                    ...this.reportSettings,
                    ...data
                };
                break;
            default:
                if (this.reportSettings) {
                    this.reportSettings[property] = data as never;
                }
        }
    }

    onAction(event: string) {
        const response: {
            type: string;
            data: IReportSetting | null;
        } = {
            type: event,
            data: null
        };

        switch (event) {
            case 'save':
            case 'run':
                response['data'] = this.reportSettings;
                this.action.emit(response);
                break;
            case 'cancel':
            case 'delete':
                this.action.emit(response);
                break;
            case 'next':
                const nextData = getNextActivePanel(this.activePanel, this.reportOptions);
                this.activePanel = nextData.name;
                this.isShowNext = !nextData.isLast;
                break;
            default:
        }
    }

    ngOnDestroy() {
        this.action.complete();
    }
}
