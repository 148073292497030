import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {firstValueFrom, Subject} from 'rxjs';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CompanyGatewayService} from '../../../../../../../../services/api/finance/company-gateway.service';
import {NotificationsService} from 'angular2-notifications';
import {SessionService} from '../../../../../../../../services/session.service';
import {GatewayTypes, IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {takeUntil} from 'rxjs/operators';
import {MUHNEE_LARGE_LOGO} from '../../../../../../../../local-typings';
import {MatDialog} from '@angular/material/dialog';
import {MuhneeCompaniesSelectDialogComponent} from '../../../dialogs/muhnee-companies-select-dialog/muhnee-companies-select-dialog.component';
import {MuhneeKycRedirectDialogComponent} from '../../../dialogs/muhnee-kyc-redirect-dialog/muhnee-kyc-redirect-dialog.component';

@Component({
    selector: 'app-zipi-financial-muhnee-setup',
    templateUrl: './zipi-financial-muhnee-setup.component.html',
    styleUrls: ['./zipi-financial-muhnee-setup.component.scss']
})
export class ZipiFinancialMuhneeSetupComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() gatewayType: GatewayTypes | undefined;
    @Output() reloadAndShowFinalPopup = new EventEmitter();

    muhneeLogo = MUHNEE_LARGE_LOGO;

    companyTitle: string | null | undefined;

    activeSection: 'login' | 'register' | null = null;

    lockButton: boolean;

    apiKey: UntypedFormControl;
    muhneeLoginForm: UntypedFormGroup;
    muhneeRegisterForm: UntypedFormGroup;

    constructor(
        private companyGatewayService: CompanyGatewayService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        public sessionService: SessionService,
        public dialog: MatDialog
    ) {
        this.apiKey = this.fb.control(null, [Validators.required]);
        this.lockButton = false;

        this.muhneeLoginForm = this.fb.group({
            login: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });
        this.muhneeRegisterForm = this.fb.group({
            company_name: ['', [Validators.required]],
            first_name: ['', [Validators.required]],
            last_name: ['', [Validators.required]],
            login: ['', [Validators.required]],
            password: ['', [Validators.required]],
            phone: ['', [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]]
        });
        this.muhneeRegisterForm.controls.phone = this._addPhoneMaskListener(this.muhneeRegisterForm.controls.phone);
    }

    ngOnInit() {
        const currentProfile = this.sessionService.profile;
        if (currentProfile) {
            this.companyTitle = currentProfile.company?.title;
            this.muhneeRegisterForm.controls.login.setValue(currentProfile.email);
            this.muhneeRegisterForm.controls.login.disable();
            this.muhneeRegisterForm.controls.first_name.setValue(currentProfile.first_name);
            this.muhneeRegisterForm.controls.last_name.setValue(currentProfile.last_name);
            if (currentProfile.company) {
                if (['agent', 'colaborator'].includes(currentProfile.company.access_plan)) {
                    this.muhneeRegisterForm.controls.company_name.setValue(
                        `${currentProfile.first_name} ${currentProfile.last_name}`
                    );
                } else {
                    this.muhneeRegisterForm.controls.company_name.setValue(this.companyTitle);
                }
            }
        }

        this.muhneeLoginForm?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (this.lockButton) {
                this.lockButton = false;
            }
        });
        this.muhneeRegisterForm?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (this.lockButton) {
                this.lockButton = false;
            }
        });
        this.apiKey?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (this.lockButton) {
                this.lockButton = false;
            }
        });
    }

    async createMuhneeGateway(muhneeConnectionType: 'login' | 'register') {
        if (!this.validateMuhneeSetup(muhneeConnectionType)) {
            return;
        }

        this.lockButton = true;

        const gatewayData = {
            customer: null,
            customer_type: null,
            muhnee_api_key: null,
            muhnee_login_data: {},
            muhnee_register_data: {},
            muhnee_connection_type: muhneeConnectionType,
            title: this.gatewayType === 'zipi_financial_business' ? 'Muhnee Operating' : 'Muhnee Trust',
            driver_type: 'muhnee',
            gateway_type: this.gatewayType
        } as any;

        switch (muhneeConnectionType) {
            case 'login':
                gatewayData.muhnee_login_data = this.muhneeLoginForm.getRawValue();
                break;
            case 'register':
                gatewayData.muhnee_register_data = this.muhneeRegisterForm.getRawValue();
                break;
        }
        if (muhneeConnectionType === 'login') {
            const companies = await firstValueFrom(
                this.companyGatewayService.preSetupZipiFinancialGateway(gatewayData)
            );
            const dialogRef = this.dialog.open(MuhneeCompaniesSelectDialogComponent, {
                minWidth: '600px',
                data: {
                    companies: companies.data
                }
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (result) {
                        gatewayData.muhnee_login_data['muhnee_company_id'] = result;
                        this.createPaymentGateway(gatewayData);
                    } else {
                        return;
                    }
                });
        } else {
            this.createPaymentGateway(gatewayData);
        }
    }

    validateMuhneeSetup(muhneeConnectionType: 'login' | 'register') {
        switch (muhneeConnectionType) {
            case 'login': {
                if (this.muhneeLoginForm.invalid) {
                    this.muhneeLoginForm.markAllAsTouched();
                    return false;
                }
                break;
            }
            case 'register': {
                if (this.muhneeRegisterForm.invalid) {
                    this.muhneeRegisterForm.markAllAsTouched();
                    return false;
                }
                break;
            }
        }
        return true;
    }

    async createPaymentGateway(customerData: Partial<IPaymentGateway>) {
        const gateway = await firstValueFrom(this.companyGatewayService.createZipiFinancialGateway(customerData));

        if (gateway) {
            this.notificationsService.success(`Customer Created`);
            this.notificationsService.success(`Settings saved`);

            if (gateway.deep_link_redirect) {
                const dialogRef = this.dialog.open(MuhneeKycRedirectDialogComponent, {
                    width: '630px',
                    disableClose: true,
                    data: {
                        link: gateway.deep_link_redirect
                    }
                });

                dialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((result) => {
                        this.reloadAndShowFinalPopup.emit(null);
                    });
            } else {
                this.reloadAndShowFinalPopup.emit(null);
            }
        }

        this.lockButton = false;
    }

    _addPhoneMaskListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (typeof changes === 'string') {
                const charArr = changes.split('');
                const digitsArr: Array<string> = [];
                const newCharArr: Array<string> = [];
                charArr.forEach((char: string, index: number) => {
                    if (!isNaN(+char) && char !== ' ' && char !== null) {
                        digitsArr.push(char);
                    }
                });
                digitsArr.forEach((digit, index) => {
                    if (index <= 9) {
                        if ([3, 6].includes(index)) {
                            newCharArr.push('-');
                            newCharArr.push(digit);
                        } else {
                            newCharArr.push(digit);
                        }
                    }
                });

                const actualStr = newCharArr.join('');
                control.setValue(actualStr, {emitEvent: false});
            } else {
                control.setValue('', {emitEvent: false});
            }
        });

        return control;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
