import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {Profile} from 'app/models/profile';
import {UsersAndLicencesApiService} from '../users-and-licences.api.service';
import {UserInfoDialogComponent} from '../user-info.dialog';
import {IProfile, IRole} from '@cyberco-nodejs/zipi-typings/index';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {filter, takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {NotificationsServiceZipi} from '../../../notifications/notifications.service';
import {SessionService} from 'app/services/session.service';
import {Subject} from 'rxjs';
import {UserDeleteDialogComponent} from '../user-delete.dialog';
import {UserRecurringDeleteDialogComponent} from '../user-recurring-delete.dialog';
import {UserService} from 'app/services/user.service';
import {ProfilesService} from 'app/services/profiles.service';
import {Store} from '@ngrx/store';
import {IContactsState} from '../../../contacts/store/contacts.reducer';
import {FetchContactsMust} from '../../../contacts/store/contacts.actions';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {CompanyPermissionsSource} from 'app/services/sources/companyPermissions.source';
import {AvailableRolesSource} from 'app/services/sources/available-roles.source';
import {IGroup} from '@cyberco-nodejs/zipi-typings';
import {NotificationsService} from 'angular2-notifications';
import {AuthService} from 'app/services/auth.service';

@Component({
    selector: 'app-users-licenses-card',
    templateUrl: './users-licenses-card.component.html',
    styleUrls: ['../users-licenses.component.scss']
})
export class UsersLicensesCardComponent implements OnInit, OnChanges, OnDestroy {
    @Input() profile: Profile | undefined;
    @Input() index: number | undefined;
    @Input() profileIdsForDetails: number[] = [];
    @Input() allRoles:
        | {
              systemRoles: IRole[];
              companyRoles: IRole[];
          }
        | undefined;
    @Input() profileIdsToAdd: number[] = [];
    @Input() companyPermissions: Object | undefined;

    @Output() onUpdateProfile = new EventEmitter();
    @Output() onUpdateRoles = new EventEmitter();
    @Output() onUpdatePermissions = new EventEmitter();
    @Output() onDeleteProfile = new EventEmitter();
    @Output() profileIdsToAddEmitter = new EventEmitter();
    @Output() onProfileConnected = new EventEmitter();

    @ViewChild('editComponent') editComponent:
        | {
              save: () => any;
          }
        | undefined;
    @ViewChild('checkbox') checkbox: MatCheckbox | undefined;
    @ViewChild('divCheckbox') divCheckbox: ElementRef | undefined;

    private unsubscribe: Subject<void> = new Subject();

    PROFILE_STATUS_SUSPENDED = Profile.status.suspended;
    // @ts-ignore
    PROFILE_STATUS_DISCONNECTED = Profile.status.disconnected;
    PROFILE_STATUS_INVITED = Profile.status.invited;
    PROFILE_STATUS_ACTIVE = Profile.status.active;
    PROFILE_STATUS_NO_ACCESS = Profile.status.no_access;

    PROFILE_TYPE_DEFAULT = Profile.type.default;

    editUser: Profile | undefined | null;
    isUserInfoShow: {[key: number]: boolean} = {};
    profileCompanyGroups: IGroup[] = [];

    constructor(
        private usersAndLicencesApiService: UsersAndLicencesApiService,
        protected dialog: MatDialog,
        private notificationsService: NotificationsServiceZipi,
        public sessionService: SessionService,
        private userService: UserService,
        private profileService: ProfilesService,
        private store: Store<IContactsState>,
        private companyPermissionsSource: CompanyPermissionsSource,
        private availableRolesSource: AvailableRolesSource,
        public authService: AuthService
    ) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.profile) {
            this.sortProfileGroups(changes.profile.currentValue);
        }
    }

    toggleDealCheckbox(control: MatCheckboxChange) {
        const profileId = Number(control.source.value);

        if (control.checked) {
            this.profileIdsToAddEmitter.next({add: profileId});
        } else {
            this.profileIdsToAddEmitter.next({remove: profileId});
        }
    }

    sortProfileGroups(profile: Profile) {
        this.profileCompanyGroups = profile.company_group!.sort(
            (a: {type: string; title: number}, b: {type: any; title: number}) => {
                if (a.type === b.type) {
                    if (a.title === b.title) {
                        return 0;
                    }
                    if (a.title > b.title) {
                        return 1;
                    } else {
                        return -1;
                    }
                } else {
                    if (a.type === 'default') {
                        return 1;
                    } else {
                        return -1;
                    }
                }
            }
        );
    }

    isDetailsShown(profile: Profile | undefined) {
        return profile && this.profileIdsForDetails.indexOf(Number(profile.id)) >= 0;
    }

    changeDetailsState(profile: Profile | undefined) {
        if (profile) {
            if (this.profileIdsForDetails.indexOf(Number(profile.id)) >= 0) {
                this.profileIdsForDetails.splice(this.profileIdsForDetails.indexOf(Number(profile.id)), 1);
            } else {
                this.profileIdsForDetails.push(Number(profile.id));
            }
        }
    }

    updateUser(profile: Profile) {
        this.editUser = Object.assign({}, profile);
        if (this.profileIdsForDetails.indexOf(Number(profile.id)) >= 0) {
            this.profileIdsForDetails.splice(this.profileIdsForDetails.indexOf(Number(profile.id)), 1);
        }
    }

    async resendInvitation(profile: Profile) {
        if (this.editComponent && !!this.editUser && !!this.editUser.id && this.editUser.id === profile.id) {
            await this.editComponent.save();
        }

        await this.usersAndLicencesApiService
            .resendInvitation(profile)
            .then(async (response) => {
                if (response) {
                    this.notificationsService.addInfo('Invitation resent successfully');
                    // update only current profile
                    this.onUpdateProfile.emit(response);
                }
            })
            .catch((err) => {
                this.updateProfileInList(Number(profile.id));
            });
    }

    async revokeInvitation(profile: Profile) {
        if (this.editComponent && !!this.editUser && !!this.editUser.id && this.editUser.id === profile.id) {
            await this.editComponent.save();
        }

        await this.usersAndLicencesApiService.revokeInvitation(profile).catch((err) => {
            this.updateProfileInList(Number(profile.id));
        });
    }

    suspend(profile: Profile) {
        const dialogRef = this.dialog.open(UserInfoDialogComponent);
        dialogRef.componentInstance.action = 'Suspend';
        dialogRef.componentInstance.name = `${profile.first_name} ${profile.last_name}`;
        dialogRef.componentInstance.additionalMessage = 'User will be deleted from all groups.';

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.usersAndLicencesApiService
                        .suspend(Number(profile.id))
                        .then(async (response) => {
                            if (response) {
                                this.notificationsService.addInfo('Profile suspended successfully');
                                // update only current profile
                                this.onUpdateProfile.emit(response);
                            }
                        })
                        .catch((err) => {
                            this.updateProfileInList(Number(profile.id));
                        });
                }
            });
    }

    reactivate(profile: Profile) {
        this.usersAndLicencesApiService
            .reactivate(Number(profile.id))
            .then(async (response) => {
                if (response) {
                    this.notificationsService.addInfo('Profile reactivated successfully');
                    // update only current profile
                    this.onUpdateProfile.emit(response);
                }
            })
            .catch((err) => {
                this.updateProfileInList(Number(profile.id));
            });
    }

    disconnect(profile: IProfile | null | Profile) {
        if (profile) {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                minWidth: 320,
                maxWidth: 600,
                data: {
                    title: 'Revoke Access',
                    message: `You're about revoke user (${profile.user!.first_name} ${profile.user!.last_name}) access.
            Are you sure you want to revoke access?`
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((ok) => {
                    this.usersAndLicencesApiService
                        .disconnectProfile(Number(profile.id))
                        .toPromise()
                        .then((response) => {
                            if (response) {
                                this.notificationsService.addInfo('Access Revoked successfully');
                                // update only current profile
                                this.updateProfileInList(Number(profile.id));
                            }
                        })
                        .catch((err) => {
                            this.updateProfileInList(Number(profile.id));
                        });
                });
        }
    }

    deleteProfile(profile: Profile) {
        const dialogRef = this.dialog.open(UserInfoDialogComponent);
        dialogRef.componentInstance.action = 'Delete';
        dialogRef.componentInstance.name = `${profile.first_name} ${profile.last_name}`;

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.usersAndLicencesApiService
                        .deleteProfile(Number(profile.id))
                        .then(async (response) => {
                            if (response.unpaid_deals) {
                                const dialogRefDeals = this.dialog.open(UserDeleteDialogComponent);
                                dialogRefDeals.componentInstance.deals = response.unpaid_deals;
                                dialogRefDeals
                                    .afterClosed()
                                    .pipe(takeUntil(this.unsubscribe))
                                    .subscribe((resAfterClosed) => {
                                        if (resAfterClosed) {
                                            // update only current profile
                                            this.onDeleteProfile.emit(profile.id);
                                            // update contacts page after successfully delete
                                            this.store.dispatch(new FetchContactsMust());
                                        }
                                    });
                            } else if (response.related_recurring_invoices) {
                                const dialogRefRecurring = this.dialog.open(UserRecurringDeleteDialogComponent, {
                                    minWidth: 220,
                                    data: {
                                        profile: profile,
                                        invoices: response.related_recurring_invoices
                                    }
                                });

                                dialogRefRecurring
                                    .afterClosed()
                                    .pipe(
                                        filter((pn) => !!pn),
                                        takeUntil(this.unsubscribe)
                                    )
                                    .subscribe((isConfirmed) => {
                                        if (isConfirmed) {
                                            this.usersAndLicencesApiService
                                                .deleteProfile(Number(profile.id), 'forced')
                                                .then(async (responseAfterDelete) => {
                                                    if (responseAfterDelete) {
                                                        this.notificationsService.addInfo(
                                                            'Profile deleted successfully'
                                                        );
                                                        // update only current profile
                                                        this.onDeleteProfile.emit(profile.id);
                                                        // update contacts page after successfully delete
                                                        this.store.dispatch(new FetchContactsMust());
                                                    }
                                                });
                                        }
                                    });
                            } else {
                                if (response) {
                                    this.notificationsService.addInfo('Profile deleted successfully');
                                    // update only current profile
                                    this.onDeleteProfile.emit(profile.id);
                                    // update contacts page after successfully delete
                                    this.store.dispatch(new FetchContactsMust());
                                }
                            }
                        })
                        .catch((err) => {
                            this.updateProfileInList(Number(profile.id));
                        });
                }
            });
    }

    async connectSkyslopeUser(profile: Profile) {
        if (profile && profile.email) {
            const oktaUser = await this.userService.getOktaUserByEmail(profile.email);

            if (oktaUser.userId) {
                try {
                    await this.profileService.connectSkyslopeUser({
                        profile_id: profile.id!,
                        email: profile.email,
                        first_name: this.userService.getOktaMetadataByKey('FirstName', oktaUser.metadata),
                        last_name: this.userService.getOktaMetadataByKey('LastName', oktaUser.metadata),
                        okta_uid: oktaUser.userId
                    });
                    this.onProfileConnected.emit();
                    this.notificationsService.addInfo('Books and Skyslope OKTA connected successfully');
                } catch (e) {
                    this.notificationsService.addError('Error while connecting Skyslope user to profile!');
                }
            }
        }
    }
    createContact(profile: Profile) {
        this.profileService
            .createContactForProfile(Number(profile.id))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                if (response) {
                    // update contacts page after successfully contact creation
                    this.store.dispatch(new FetchContactsMust());
                }
            });
    }

    async createFinancialsCompany(profile: Profile) {
        this.userService.createFinancialsForProfile(Number(profile.id)).then(async (res) => {
            // update only current profile
            this.onUpdateProfile.emit(res);

            // update roles
            this.availableRolesSource.load().then(() => {
                this.availableRolesSource.source.pipe(takeUntil(this.unsubscribe)).subscribe((roles) => {
                    if (roles) {
                        this.onUpdateRoles.emit(roles);
                    }
                });
            });

            // update permissions
            this.companyPermissionsSource.load().then(() => {
                this.companyPermissionsSource.source.pipe(takeUntil(this.unsubscribe)).subscribe((permissions) => {
                    if (permissions) {
                        this.onUpdatePermissions.emit(permissions);
                    }
                });
            });
        });
    }

    async updateUserList(event: {action: string; result: Profile}) {
        // update only current contact
        this.onUpdateProfile.emit(event.result);
        this.editUser = null;
        this.sortProfileGroups(event.result);
    }

    destroyEditUserComponent() {
        this.editUser = null;
    }

    updateProfileInList(profile_id: number) {
        this.usersAndLicencesApiService.getUpdatedProfile(profile_id).then((prof) => {
            this.onUpdateProfile.emit(prof);
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.onUpdateProfile.complete();
        this.onUpdateRoles.complete();
        this.onUpdatePermissions.complete();
        this.onDeleteProfile.complete();
        this.profileIdsToAddEmitter.complete();
    }
}
