import {NgModule} from '@angular/core';

import {ReportLayoutComponent} from './components/report-layout/report-layout.component';
import {ReportsPageComponent} from './components/reports-page/reports-page.component';
import {RecommendedComponent} from './components/recommended/recommended.component';
import {routing} from './reports.routing';
import {SharedModule} from '../shared/shared.module';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ReportsEffectsService} from './store/reports.effects';
import {reportsReducer} from './store/reports.reducer';
import {CommonModule} from '@angular/common';
import {ReportCategoryComponent} from './components/report-category/report-category.component';
import {ReportCategoriesService} from './services/report-categories.service';
import {AddCategoryDialogComponent} from './components/add-category-dialog/add-category-dialog.component';
import {EditCategoryDialogComponent} from './components/edit-category-dialog/edit-category-dialog.component';
import {ReportsLocalService} from './services/reports-local.service';
import {ReportsService} from './services/reports.service';
import {ReportEditBarComponent} from './components/report-edit-bar/report-edit-bar.component';
import {ReportsPreviewComponent} from './components/reports-preview/reports-preview.component';
import {TransactionSummaryReportPreviewComponent} from './components/reports-preview/components/transaction-summary/transaction-summary.component';
import {MembershipReportPreviewComponent} from './components/reports-preview/components/membership/membership.component';
import {AverageSalesReportPreviewComponent} from './components/reports-preview/components/average-sales/average-sales.component';
import {SourceOfBusinessPreviewComponent} from './components/reports-preview/components/source-of-business/source-of-business.component';
import {AgentPerformancePreviewComponent} from './components/reports-preview/components/agent-performance/agent-performance.component';
import {CommissionExpenseReportPreviewComponent} from './components/reports-preview/components/commission-expense/commission-expense.component';
import {DetailedPreviewComponent} from './components/reports-preview/components/detailed/detailed.component';
import {CapPreviewComponent} from './components/reports-preview/components/cap/cap.component';
import {Ten99PreviewComponent} from './components/reports-preview/components/ten99/ten99.component';
import {ProgressReportPreviewComponent} from './components/reports-preview/components/progress/progress.component';
import {ReportFormattedPipe} from './components/reports-preview/pipes/report-formatted.pipe';
import {DecimalPipe, CurrencyPipe, PercentPipe} from '@angular/common';
import {
    ViewReportComponent,
    ViewReportResultsNodeComponent,
    ViewReportResultsTableComponent
} from './components/reports-preview/components/progress/components/view.component';
import {SaveReportDialogComponent} from './components/reports-preview/components/save-report-dialog/save-report-dialog.component';
import {ReportDropdownComponent} from './components/report-edit-bar/components/sub-component/report-dropdown/report-dropdown.component';
import {ReportAutocompleteComponent} from './components/report-edit-bar/components/sub-component/report-autocomplete/report-autocomplete.component';
import {ReportAddonComponent} from './components/report-edit-bar/components/sub-component/report-addon/report-addon.component';
import {BalanceSheetReportPreviewComponent} from './components/reports-preview/components/balance-sheet/balance-sheet.component';
import {ProfitLossReportPreviewComponent} from './components/reports-preview/components/profit-loss/profit-loss.component';
import {AccountTransactionPreviewComponent} from './components/reports-preview/components/account-transaction/account-transaction.component';
import {JournalReportPreviewComponent} from './components/reports-preview/components/journal/journal.component';
import {GeneralLedgerPreviewComponent} from './components/reports-preview/components/general-ledger/general-ledger.component';
import {AccountTypeSummaryPreviewComponent} from './components/reports-preview/components/account-type-summary/account-type-summary.component';
import {TrialBalancePreviewComponent} from './components/reports-preview/components/trial-balance/trial-balance.component';
import {MatTreeModule} from '@angular/material/tree';
import {TrustReconciliationPreviewComponent} from './components/reports-preview/components/trust-reconciliation/trust-reconciliation.component';
import {ProductPreviewComponent} from './components/reports-preview/components/product/product.component';
import {ContactPreviewComponent} from './components/reports-preview/components/contact/contact.component';
import {RecurringInvoicePreviewComponent} from './components/reports-preview/components/recurring_invoice/recurring_invoice.component';
import {CashFlowStatementReportPreviewComponent} from './components/reports-preview/components/cash-flow/cash-flow.component';
import {APAgingPreviewComponent} from './components/reports-preview/components/ap-aging/ap-aging.component';
import {ARAgingPreviewComponent} from './components/reports-preview/components/ar-aging/ar-aging.component';

// components in edit bar
import {TransactionSummaryBarComponent} from './components/report-edit-bar/components/transaction-summary/transaction-summary.component';
import {TransactionSummaryUserBarComponent} from './components/report-edit-bar/components/transaction-summary-user/transaction-summary-user.component';
import {DetailedBarComponent} from './components/report-edit-bar/components/detailed/detailed.component';
import {SourceOfBusinessBarComponent} from './components/report-edit-bar/components/source-of-business/source-of-business.component';
import {CommissionExpenseBarComponent} from './components/report-edit-bar/components/commission-expense/commission-expense.component';
import {AverageSalesBarComponent} from './components/report-edit-bar/components/average-sales/average-sales.component';
import {AgentPerformanceBarComponent} from './components/report-edit-bar/components/agent-performance/agent-performance.component';
import {ProductBarComponent} from './components/report-edit-bar/components/product/product.component';
import {ContactBarComponent} from './components/report-edit-bar/components/contact/contact.component';
import {CapBarComponent} from './components/report-edit-bar/components/cap/cap.component';
import {MembershipBarComponent} from './components/report-edit-bar/components/membership/membership.component';
import {ProgressBarComponent} from './components/report-edit-bar/components/progress/progress.component';
import {IncomeStatementBarComponent} from './components/report-edit-bar/components/income-statement/income-statement.component';
import {BalanceSheetBarComponent} from './components/report-edit-bar/components/balance-sheet/balance-sheet.component';
import {CashFlowBarComponent} from './components/report-edit-bar/components/cash-flow/cash-flow.component';
import {AccountTransactionBarComponent} from './components/report-edit-bar/components/account-transaction/account-transaction.component';
import {JournalBarComponent} from './components/report-edit-bar/components/journal/journal.component';
import {GeneralLedgerBarComponent} from './components/report-edit-bar/components/general-ledger/general-ledger.component';
import {AccountTypeBarComponent} from './components/report-edit-bar/components/account-type/account-type.component';
import {TrialBalanceBarComponent} from './components/report-edit-bar/components/trial-balance/trial-balance.component';
import {TrustReconciliationBarComponent} from './components/report-edit-bar/components/trust-reconciliation/trust-reconciliation.component';
import {RecurringInvoiceBarComponent} from './components/report-edit-bar/components/recurring-invoice/recurring-invoice.component';
import {Ten99NecBarComponent} from './components/report-edit-bar/components/ten99-nec/ten99-nec.component';
import {ApAgingBarComponent} from './components/report-edit-bar/components/ap-aging/ap-aging.component';
import {ArAgingBarComponent} from './components/report-edit-bar/components/ar-aging/ar-aging.component';

// sub components in edit bar
import {TimePeriodComponent} from './components/report-edit-bar/components/sub-component/time-period/time-period.component';
import {LayoutComponent} from './components/report-edit-bar/components/sub-component/layout/layout.component';
import {EntityComponent} from './components/report-edit-bar/components/sub-component/entity/entity.component';
import {ActionComponent} from './components/report-edit-bar/components/sub-component/action/action.component';
import {ButtonListComponent} from './components/report-edit-bar/components/sub-component/button-list/button-list.component';
import {DealPickerComponent} from './components/report-edit-bar/components/sub-component/deal-picker/deal-picker.component';
import {ReportLedgerAccountTypeComponent} from './components/report-edit-bar/components/sub-component/report-ledger-account-type/report-ledger-account-type.component';
import {TransactionUserReportPreviewComponent} from './components/reports-preview/components/transaction-user/transaction-user.component';
import {ReportSidenavComponent} from './components/report-sidenav/report-sidenav.component';
import {FinanceModule} from '../finance/finance.module';
import {ReportPeriodComponent} from './components/report-edit-bar/components/sub-component/report-period/report-period.component';
import {Ten99NecIntMiscPreviewComponent} from './components/reports-preview/components/ten99-nec-int-misc/ten99-nec-int-misc.component';
import {Payroll1099TaxRecordErrorsDialogComponent} from '@app/modules/contacts/contact-dialogs/payroll-1099-tax-record-errors-dialog/payroll-1099-tax-record-errors-dialog.component';

@NgModule({
    declarations: [
        ReportLayoutComponent,
        RecommendedComponent,
        CapPreviewComponent,
        Ten99PreviewComponent,
        ReportsPageComponent,
        ReportCategoryComponent,
        AddCategoryDialogComponent,
        EditCategoryDialogComponent,
        ReportsPreviewComponent,
        ReportEditBarComponent,
        ReportDropdownComponent,
        ReportAutocompleteComponent,
        ReportAddonComponent,
        TransactionSummaryReportPreviewComponent,
        TransactionUserReportPreviewComponent,
        MembershipReportPreviewComponent,
        AverageSalesReportPreviewComponent,
        SourceOfBusinessPreviewComponent,
        AgentPerformancePreviewComponent,
        CommissionExpenseReportPreviewComponent,
        DetailedPreviewComponent,
        BalanceSheetReportPreviewComponent,
        ProfitLossReportPreviewComponent,
        ReportFormattedPipe,
        ProgressReportPreviewComponent,
        AccountTransactionPreviewComponent,
        ViewReportComponent,
        ViewReportResultsNodeComponent,
        ViewReportResultsTableComponent,
        SaveReportDialogComponent,
        JournalReportPreviewComponent,
        GeneralLedgerPreviewComponent,
        AccountTypeSummaryPreviewComponent,
        TrialBalancePreviewComponent,
        TrustReconciliationPreviewComponent,
        ProductPreviewComponent,
        ContactPreviewComponent,
        RecurringInvoicePreviewComponent,
        CashFlowStatementReportPreviewComponent,
        APAgingPreviewComponent,
        ARAgingPreviewComponent,
        AccountTransactionBarComponent,
        TransactionSummaryBarComponent,
        TransactionSummaryUserBarComponent,
        DetailedBarComponent,
        SourceOfBusinessBarComponent,
        CommissionExpenseBarComponent,
        AverageSalesBarComponent,
        AgentPerformanceBarComponent,
        ProductBarComponent,
        ContactBarComponent,
        CapBarComponent,
        MembershipBarComponent,
        ProgressBarComponent,
        IncomeStatementBarComponent,
        BalanceSheetBarComponent,
        CashFlowBarComponent,
        JournalBarComponent,
        GeneralLedgerBarComponent,
        AccountTypeBarComponent,
        TrialBalanceBarComponent,
        TrustReconciliationBarComponent,
        RecurringInvoiceBarComponent,
        Ten99NecBarComponent,
        ApAgingBarComponent,
        ArAgingBarComponent,
        ActionComponent,
        TimePeriodComponent,
        ReportPeriodComponent,
        LayoutComponent,
        EntityComponent,
        ButtonListComponent,
        DealPickerComponent,
        ReportLedgerAccountTypeComponent,
        ReportSidenavComponent,
        Ten99NecIntMiscPreviewComponent,
        Payroll1099TaxRecordErrorsDialogComponent
    ],
    imports: [
        CommonModule,
        StoreModule.forFeature('reports', reportsReducer),
        EffectsModule.forFeature([ReportsEffectsService]),
        SharedModule,
        routing,
        MatTreeModule,
        FinanceModule
    ],
    providers: [ReportCategoriesService, ReportsLocalService, ReportsService, DecimalPipe, PercentPipe, CurrencyPipe],
    exports: [ReportFormattedPipe],
    bootstrap: []
})
export class ReportsModule {}
