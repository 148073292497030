import {Subject} from 'rxjs';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {IFinanceState} from '../../../../finance/store/finance.reducer';
import {ReconciliationsService} from '../../../services/reconciliations.service';
import {ILedgerAccount, IReconciliation} from '@cyberco-nodejs/zipi-typings';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-account-reconciliations',
    templateUrl: './reconciliations-list.component.html',
    styleUrls: ['../../banking.component.scss']
})
export class ReconciliationsListComponent implements OnInit, OnDestroy {
    @Output() loadLastReconcile: EventEmitter<void> = new EventEmitter<void>();
    @Output() loadInternalTransactions: EventEmitter<void> = new EventEmitter<void>();
    private unsubscribe: Subject<void> = new Subject();

    ledgerAccountId: number | null = null;
    @Input() ledgerAccount: ILedgerAccount | undefined;

    dataSource: MatTableDataSource<IReconciliation>;
    displayedColumns: string[] = ['reconciled_date', 'period', 'closing_balance', 'status', 'actions'];

    pageIndex: number = 0;
    listLength: number = 0;
    pageSize: number = 10;
    pageSizeOptions: number[] = [10, 20, 50];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private store: Store<IFinanceState>,
        private reconciliationsService: ReconciliationsService,
        public dialog: MatDialog
    ) {
        this.dataSource = new MatTableDataSource<IReconciliation>([]);

        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['id']) {
                this.ledgerAccountId = Number(params['id']);
            }
        });
    }

    ngOnInit() {
        this.loadReconciliations();
    }

    loadReconciliations() {
        this.loadLastReconcile.emit();
        this.reconciliationsService
            .getAccountReconciliations(this.ledgerAccountId as number)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((recList) => {
                this.dataSource.data = recList;
                this.listLength = recList.length;
            });
    }

    navigateToRow(row: IReconciliation) {
        if (row.status === 'in_progress') {
            this.router.navigate(['/banking', this.ledgerAccountId, 'reconciliations', row.reconciliation_id, 'edit']);
        } else {
            this.router.navigate(['/banking', this.ledgerAccountId, 'reconciliations', row.reconciliation_id]);
        }
    }

    undoReconciliation(id: number) {
        this.reconciliationsService
            .undoReconciliation(id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                this.loadReconciliations();
                this.loadInternalTransactions.emit();
            });
    }

    deleteReconciliation(id: number) {
        this.reconciliationsService
            .deleteReconciliation(id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                this.loadReconciliations();
                this.loadInternalTransactions.emit();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.loadLastReconcile.complete();
        this.loadInternalTransactions.complete();
    }
}
