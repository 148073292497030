<section class="container-fluid" *ngIf="bill">
    <div class="w-100 d-flex align-items-center mt-3">
        <a
            rbac
            [rule]="{purchases__manage_source_documents: true}"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'block'}"
            [denyStyle]="{display: 'none'}"
            [routerLink]="['/purchases/sourcedocuments/approve', bill.connected__invoice_fk_id]"
            *ngIf="bill.connected__invoice_fk_id"
            class=""
            style="font-size: 15px; margin-bottom: -5px"
        >
            {{ $any(bill.status_in_portal) | fromSnakeCase }}: {{ bill.connected_invoice?.invoice_number }}
        </a>
        <span class="sp-spacer"></span>

        <div
            class="d-flex align-items-center"
            *ngIf="
                billQBSync &&
                billQBSync.is_active &&
                bill &&
                !['draft', 'write_off', 'void', 'rejected', null].includes(bill.summary_status)
            "
        >
            <mat-icon
                class="error-icon"
                *ngIf="billQBSync.sync_status === 'error'"
                matTooltip="The previous sync attempt failed."
                >error</mat-icon
            >
            <button
                mat-button
                color="primary"
                (click)="sendToQuickbooks()"
                *ngIf="!dialogMode && billQBSync.is_loaded && billQBSync.sync_status !== 'completed'"
                [disabled]="billQBSync.sync_status === 'in_progress'"
            >
                <mat-icon>sync</mat-icon>
                <span *ngIf="billQBSync.sync_status !== 'in_progress'">Sync with QuickBooks</span>
                <span *ngIf="billQBSync.sync_status === 'in_progress'">Sending to Quickbooks...</span>
            </button>

            <button
                mat-button
                color="primary"
                (click)="viewInQuickbooks()"
                *ngIf="!dialogMode && billQBSync.is_loaded && billQBSync.sync_status === 'completed'"
            >
                <span *ngIf="billQBSync.sync_status !== 'in_progress'" class="mr-2">View in QuickBooks</span>
                <mat-icon style="font-size: 18px; height: 18px; width: 18px">open_in_new</mat-icon>
            </button>

            <mat-divider vertical style="height: 20px"></mat-divider>
        </div>

        <button mat-button color="primary" [matMenuTriggerFor]="appMenu" *ngIf="!dialogMode">
            <span>Actions</span>
            <mat-icon>expand_more</mat-icon>
        </button>

        <mat-menu #appMenu="matMenu">
            <div
                class="mr-2"
                matTooltip="You cannot void Bill associated with Deal."
                [matTooltipDisabled]="!!bill && !bill.source__deal_fk_id"
            >
                <button
                    mat-menu-item
                    *ngIf="!dialogMode && bill.summary_status !== 'void'"
                    [disabled]="
                        ['paid', 'partial_payment'].includes(bill.summary_status) ||
                        bill.status_of_payment !== 'unpaid' ||
                        hasNonModifiableTransactions() ||
                        (bill && !!bill.source__deal_fk_id)
                    "
                    (click)="$event.stopPropagation(); voidBill()"
                >
                    Void
                </button>
            </div>

            <button
                mat-menu-item
                rbac
                [rule]="{purchases__manage_bills: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                *ngIf="!dialogMode"
                [disabled]="
                    ['write_off', 'void', 'rejected'].includes(bill.summary_status) ||
                    (bill.creator__company_fk_id !== currentCompany.id &&
                        bill.owner__company_fk_id !== currentCompany.id) ||
                    restrictedBasedOnDealConnection === null
                "
                (click)="$event.stopPropagation(); editBill(bill.bill_id)"
            >
                Edit
            </button>
        </mat-menu>
    </div>

    <div class="mb-1" *ngIf="!dialogMode">
        History
        <button mat-icon-button (click)="showHistory = !showHistory; loadHistory()">
            <mat-icon *ngIf="showHistory">expand_more</mat-icon>
            <mat-icon *ngIf="!showHistory">chevron_right</mat-icon>
        </button>

        <div
            *ngIf="showHistory"
            style="max-height: 200px; overflow-y: scroll; overflow-x: hidden; font-size: 12px; padding: 10px"
        >
            <div *ngFor="let log of invoiceLogs" class="d-flex">
                <div style="width: 100px; margin-right: 50px" class="font-weight-bold" *ngIf="log.action_timestamp">
                    {{ log.action_timestamp | amDateFormat: 'MM/DD/YYYY HH:mm' }}
                </div>
                <div style="width: 100px; margin-right: 50px" class="font-weight-bold" *ngIf="!log.action_timestamp">
                    N/A
                </div>

                <div>{{ log.description }}</div>
            </div>
        </div>
    </div>
    <mat-card class="p-5">
        <div class="invoice-header">
            <div
                class="errormessage"
                *ngIf="
                    bill.connected_invoice &&
                    bill.connected_invoice.status_in_portal === 'linked' &&
                    bill.status_in_portal === 'connected'
                "
            >
                Warning! Total Amount of Bill different from the Total Amount of Source Document
            </div>
            <div class="invoice-status" [ngStyle]="{'background-color': statusColor[$any(bill.summary_status)]}">
                {{ $any(bill.summary_status) | fromSnakeCase }}
            </div>
        </div>

        <!-- <div class="mb-3" *ngIf="!dialogMode">
            History
            <button mat-icon-button (click)="showHistory = !showHistory; loadHistory()">
                <mat-icon *ngIf="showHistory">expand_more</mat-icon>
                <mat-icon *ngIf="!showHistory">chevron_right</mat-icon>
            </button>

            <div *ngIf="showHistory" style="max-height: 200px; overflow-y: scroll; overflow-x: hidden; background-color: lightgray; font-size: 12px; padding: 10px">
                <div *ngFor="let log of invoiceLogs" class="d-flex">
                    <div style="width: 100px; margin-right: 50px" class="font-weight-bold" *ngIf="log.action_timestamp">{{log.action_timestamp | amDateFormat:'MM/DD/YYYY HH:mm'}}</div>
                    <div style="width: 100px; margin-right: 50px" class="font-weight-bold" *ngIf="!log.action_timestamp">N/A</div>

                    <div>{{log.description}}</div>
                </div>
            </div>
        </div> -->
        <header class="row">
            <div class="col-9 zp-company-info">
                <!-- <p>Company Logo</p> -->
                <p class="zp-company-title">{{ currentCompany?.title }}</p>
            </div>

            <div class="col-3 text-right">
                <p class="zp-inveice_title">Bill</p>
                <p class="zp-invoice-num_value">#{{ bill?.bill_number || 'N/A' }}</p>

                <p class="zp-refference_due_title">Amount: {{ bill?.total_amount | currency }}</p>
            </div>
        </header>

        <p>
            Bill From:
            <span *ngIf="vendor_contact?.display_name && vendor_contact?.hidden" class="hidden-contact">
                {{ vendor_contact?.display_name }}
            </span>

            <a
                *ngIf="vendor_contact?.display_name && !vendor_contact?.hidden"
                [routerLink]="['/contacts', vendor_contact?.contact_id]"
            >
                {{ vendor_contact?.display_name }}
            </a>
        </p>

        <div class="row">
            <div class="col-6">
                <div
                    *ngIf="
                        vendor_contact &&
                        vendor_contact.contact_locations &&
                        vendor_contact.contact_locations.length > 0 &&
                        vendor_contact?.hidden
                    "
                    class="hidden-contact"
                >
                    Hidden
                </div>

                <div
                    *ngIf="
                        vendor_contact &&
                        vendor_contact.contact_locations &&
                        vendor_contact.contact_locations.length > 0 &&
                        !vendor_contact?.hidden
                    "
                >
                    <div class="pl-2" *ngFor="let location of vendor_contact.contact_locations">
                        <p>
                            <span *ngIf="location.street_number">{{ location.street_number }} </span>
                            <span *ngIf="location.street_address">{{ location.street_address }} </span>
                            <span *ngIf="location.unit_number">{{ location.unit_number }} </span>
                            <br />
                            <span *ngIf="location.city">{{ location.city }}, </span>
                            <span *ngIf="location.state">{{ location.state }} </span>
                            <span *ngIf="location.zip">{{ location.zip }} </span>
                            <br />
                            <span *ngIf="location.country" class="text-muted">({{ location.country }})</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-6 text-right">
                <p class="mb-0">
                    <span>Date:</span>
                    <span class="zp-w-150">{{ $any(bill?.invoice_date) | dateFromNumber | date: 'shortDate' }}</span>
                </p>

                <p class="mb-0">
                    <span *ngIf="bill.status_in_portal !== 'connected' && bill.status_in_portal !== 'linked'"
                        >Reference:</span
                    >
                    <span *ngIf="bill.status_in_portal === 'connected'">Reference (Connected):</span>
                    <span *ngIf="bill.status_in_portal === 'linked'">Reference (Linked):</span>

                    <span class="zp-w-150" *ngIf="bill.connected_invoice?.invoice_number">{{
                        bill.connected_invoice?.invoice_number
                    }}</span>
                    <span class="zp-w-150" *ngIf="!bill.connected_invoice?.invoice_number">N/A</span>
                </p>

                <p class="mb-0">
                    <span>Terms:</span>
                    <span class="zp-w-150" *ngIf="bill?.payment_terms && bill?.payment_terms !== 'custom'">{{
                        termsMap.get($any(bill?.payment_terms))
                    }}</span>
                    <span class="zp-w-150" *ngIf="bill?.payment_terms && bill?.payment_terms === 'custom'"
                        >Net {{ bill?.payment_terms_custom }}</span
                    >
                </p>

                <p>
                    <span>Due Date:</span>
                    <span class="zp-w-150">{{ $any(bill?.due_date) | dateFromNumber | date: 'shortDate' }}</span>
                </p>
            </div>
        </div>

        <table class="table" *ngIf="bill.items && bill.items.length > 0">
            <thead class="bg-dark text-white">
                <tr>
                    <td scope="col">#</td>
                    <td class="w-30" scope="col">Item</td>
                    <td scope="col" class="w-30">Description</td>
                    <td scope="col">Qty</td>
                    <td scope="col" class="text-right">Rate</td>
                    <td scope="col" class="text-right">Amount</td>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of bill?.items; index as idx">
                    <td>{{ idx + 1 }}</td>
                    <td>
                        {{ item?.name }}
                        <i class="text-muted">{{ item?.description }}</i>
                    </td>
                    <td>
                        <i class="text-muted">{{ item?.description }}</i>
                    </td>
                    <td>{{ item.quantity }}</td>
                    <td class="text-right">{{ item.rate | currency: '' : '' : '0.2-2' }}</td>
                    <td class="text-right">{{ item.amount | currency: '' : '' : '0.2-2' }}</td>
                </tr>
            </tbody>
        </table>

        <div *ngIf="bill.items && bill.items.length === 0">
            <h3>Items Preview</h3>
            <table class="table">
                <thead class="bg-secondary text-white">
                    <tr>
                        <td class="w-5" scope="col">#</td>
                        <td class="w-35" scope="col">Item</td>
                        <td class="w-10" scope="col">Qty</td>
                        <td class="w-10" scope="col">Rate</td>
                        <td class="w-10 text-right" scope="col">Amount</td>
                        <!--				    <td class="w-30 text-right" scope="col">Associate</td>-->
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of bill?.items_preview; index as idx">
                        <td class="w-10">{{ idx + 1 }}</td>
                        <td class="w-40">
                            {{ item?.name }}
                            <i class="text-muted">{{ item?.description }}</i>
                        </td>
                        <td class="w-15">{{ item?.quantity }}</td>
                        <td class="w-15">{{ item?.rate | currency: '' : '' : '0.2-2' }}</td>
                        <td class="w-20 text-right">{{ item?.amount | currency: '' : '' : '0.2-2' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="w-100 text-right">
            <p class="mb-0">
                Sub Total: <span class="zp-total-nember">{{ bill?.total_amount | currency: '' : '' : '0.2-2' }}</span>
            </p>
            <p class="mb-0">
                Total: <span class="zp-total-nember">{{ bill?.total_amount | currency }}</span>
            </p>
            <div *ngIf="bill.payments && bill.payments.length > 0">
                <ng-container *ngFor="let payment of bill.payments; let i = index">
                    <div *ngIf="['completed', 'processing', 'pending', 'scheduled'].includes(payment.status)">
                        <div
                            class="py-2"
                            [ngStyle]="{
                                color:
                                    $any(payment.status) === 'completed'
                                        ? 'black'
                                        : $any(payment.status) === 'pending'
                                          ? 'orange'
                                          : $any(payment.status) === 'canceled' || $any(payment.status) === 'error'
                                            ? '#f94242'
                                            : 'black'
                            }"
                        >
                            <div class="remove-payment-container">
                                <span class="zp-w-150">
                                    <a
                                        href=""
                                        *ngIf="payment.status !== 'pending' && !bill.source__deal_fk_id"
                                        class="remove-payment-container"
                                        (keyup.enter)="cancelPayment(payment)"
                                        (click)="$event.stopPropagation(); $event.preventDefault()"
                                    >
                                        <mat-icon
                                            class="remove-payment-icon"
                                            [matTooltip]="'Remove'"
                                            (click)="cancelPayment(payment)"
                                            *ngIf="
                                                [
                                                    'deduction',
                                                    'payment_made',
                                                    'payment_received',
                                                    'vendor_credit'
                                                ].includes(payment.source_type) && payment.type === 'normal'
                                            "
                                        >
                                            close
                                        </mat-icon>
                                        <mat-icon
                                            class="remove-payment-icon"
                                            [matTooltip]="'Can\'t Remove. Initiated by another company.'"
                                            *ngIf="
                                                ['based_on_vendor_credit', 'based_on_customer_credit'].includes(
                                                    payment.source_type
                                                )
                                            "
                                        >
                                            close
                                        </mat-icon>
                                    </a>
                                    <span
                                        *ngIf="
                                            payment.source_type === 'vendor_credit' ||
                                            payment.source_type === 'based_on_vendor_credit' ||
                                            payment.source_type === 'based_on_customer_credit'
                                        "
                                        >Credits</span
                                    >
                                    <span
                                        *ngIf="
                                            (payment.source_type === 'payment_made' ||
                                                payment.source_type === 'payment_received' ||
                                                payment.source_type === 'deduction') &&
                                            payment.type === 'normal'
                                        "
                                        >Payment</span
                                    >
                                    <span
                                        *ngIf="
                                            (payment.source_type === 'payment_made' ||
                                                payment.source_type === 'payment_received' ||
                                                payment.source_type === 'deduction') &&
                                            payment.type === 'refund'
                                        "
                                        >Refund</span
                                    >
                                </span>

                                <span class="zp-total-nember">
                                    <a
                                        [ngStyle]="payment.type === 'refund' ? {color: 'red'} : null"
                                        *ngIf="payment.source__payment_made_fk_id"
                                        [routerLink]="'/purchases/payments/' + payment.source__payment_made_fk_id"
                                        >{{
                                            payment.type === 'normal'
                                                ? (payment.amount | currency)
                                                : (payment.amount * -1 | currency)
                                        }}</a
                                    >
                                    <a
                                        *ngIf="payment.source__vendor_credit_fk_id"
                                        [routerLink]="'/purchases/vendorcredits/' + payment.source__vendor_credit_fk_id"
                                        >{{ payment.amount | currency }}</a
                                    >

                                    <span
                                        *ngIf="
                                            !payment.source__payment_made_fk_id && !payment.source__vendor_credit_fk_id
                                        "
                                        >{{ payment.amount | currency }}</span
                                    >
                                </span>
                            </div>

                            <div *ngIf="payment.status === 'processing'" style="line-height: 2px">
                                <span class="zp-w-150"></span>

                                <span class="zp-total-nember" style="color: grey; font-size: 14px"> (processing) </span>
                            </div>
                            <div *ngIf="payment.status === 'scheduled'" style="line-height: 2px">
                                <span class="zp-w-150"></span>

                                <span class="zp-total-nember" style="color: grey; font-size: 14px"> (scheduled) </span>
                            </div>

                            <div *ngIf="payment.status === 'pending'" style="line-height: 2px">
                                <span class="zp-w-150"></span>

                                <span class="zp-total-nember" style="color: grey; font-size: 14px"> (Deducted) </span>
                            </div>

                            <div
                                *ngIf="$any(payment.status) === 'canceled' || $any(payment.status) === 'error'"
                                style="line-height: 2px"
                            >
                                <span class="zp-w-150"></span>

                                <span class="zp-total-nember" style="color: grey; font-size: 14px">
                                    ({{ payment.status }})
                                </span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <p>
                <strong class="bg-light py-2">
                    <span class="zp-w-150">&nbsp;</span>
                    Balance:
                    <strong class="zp-total-nember">{{ bill.balance | currency }}</strong>
                </strong>
            </p>
            <p>
                <span class="bg-light py-2" *ngIf="isProcessingPayment()">
                    <span class="zp-w-150">&nbsp;</span>
                    Pending Balance:
                    <span class="zp-total-nember">{{ bill.pending_balance | currency }}</span>
                </span>
            </p>
        </div>

        <div class="d-flex justify-content-end">
            <!--            <button mat-raised-button class="ml-2" (click)="resendBill()">Send Email Notification</button>-->
            <button
                mat-raised-button
                rbac
                [rule]="{purchases__manage_bills: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                class="ml-2"
                *ngIf="bill.summary_status === 'draft'"
                (click)="markAsOpen()"
            >
                Set as Open
            </button>
        </div>
    </mat-card>
    <div
        class="mt-3"
        rbac
        [rule]="{purchases__manage_bills: true}"
        [denyMethod]="'style'"
        [allowStyle]="{display: 'block'}"
        [denyStyle]="{display: 'none'}"
        *ngIf="!['write_off', 'void', 'paid', 'draft', 'rejected'].includes(bill.summary_status)"
    >
        <div
            class="d-flex align-items-end justify-content-end"
            *ngIf="currentCompany.access_plan === 'broker' || currentCompany.access_plan === 'organization'"
        >
            <div
                matTooltip="You cannot apply Credits to Escrow Financial Document."
                [matTooltipDisabled]="!restrictedBasedOnDealConnection"
            >
                <button
                    mat-raised-button
                    class="mr-2"
                    (click)="openApplyCreditsToBillDialog()"
                    [disabled]="
                        !allowApply || restrictedBasedOnDealConnection || restrictedBasedOnDealConnection === null
                    "
                >
                    Apply Credits <span class="text-muted">({{ creditsBalance | currency }})</span>
                </button>
            </div>

            <div
                matTooltip="You cannot make payment for Escrow Financial Document."
                [matTooltipDisabled]="!restrictedBasedOnDealConnection"
            >
                <button
                    mat-raised-button
                    [disabled]="
                        bill.pending_balance <= 0 ||
                        bill.summary_status === 'draft' ||
                        restrictedBasedOnDealConnection ||
                        restrictedBasedOnDealConnection === null
                    "
                    color="primary"
                    (click)="paymentOptions()"
                    class="_track_bill_payment_options"
                >
                    Payment Options
                </button>
            </div>
        </div>
    </div>

    <div class="my-3">
        Related Deals
        <button mat-icon-button (click)="showTransfers = !showTransfers">
            <mat-icon *ngIf="showTransfers">expand_more</mat-icon>
            <mat-icon *ngIf="!showTransfers">chevron_right</mat-icon>
        </button>

        <div *ngIf="showTransfers">
            <table
                mat-table
                *ngIf="transfersDataSource.data && transfersDataSource.data.length > 0"
                [dataSource]="transfersDataSource"
                class="mat-elevation-z1 w-100 mx-3 journals-table"
            >
                <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef disableClear class="w-5">#</th>
                    <td mat-cell *matCellDef="let element; let i = index" class="w-5">{{ i + 1 }}</td>
                </ng-container>

                <ng-container matColumnDef="deal_address">
                    <th mat-header-cell *matHeaderCellDef class="w-45">Deal Address</th>
                    <td mat-cell *matCellDef="let element" class="w-45">
                        <a *ngIf="element.deal_id" [routerLink]="['/deals/edit', element.deal_id]">
                            <span *ngIf="!element.deal_address" class="text-muted">No address </span>
                            <span *ngIf="element.deal_address"
                                >{{ element.deal_street_number }} {{ element.deal_address }} {{ element.deal_city }},
                                {{ element.deal_state }} {{ element.deal_zip }}
                            </span>
                            — {{ element.deal_type }}/{{ element.deal_status }}
                        </a>
                        <ng-container *ngIf="!element.deal_id">
                            <span *ngIf="!element.deal_address" class="text-muted">No address </span>
                            <span *ngIf="element.deal_address"
                                >{{ element.deal_street_number }}&nbsp;{{ element.deal_address }} &nbsp;{{
                                    element.deal_city
                                }},&nbsp;{{ element.deal_state }}&nbsp;{{ element.deal_zip }}
                            </span>
                            — {{ element.deal_type }}/{{ element.deal_status }}
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef class="w-25">Product</th>
                    <td mat-cell *matCellDef="let element" class="w-25">
                        <span *ngIf="element.product_id; else elseBlock">
                            {{ element.product_name }}
                            <i class="text-muted">{{ element.product_description }}</i>
                        </span>
                        <ng-template #elseBlock>--</ng-template>
                    </td>
                </ng-container>
                <ng-container matColumnDef="connection_type">
                    <th mat-header-cell *matHeaderCellDef class="w-15">Connection Type</th>
                    <td mat-cell *matCellDef="let element" class="w-15">
                        {{ FINANCIAL_TRANSFER_TYPE_LABELS[element.type]! }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef style="text-align: right" class="w-10 justify-content-end">
                        Amount
                    </th>
                    <td mat-cell *matCellDef="let element" style="text-align: right" class="w-10 justify-content-end">
                        {{ element.amount | currency }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="transferDisplayedColumns"></tr>
                <tr
                    mat-row
                    class="journals-row"
                    *matRowDef="let row; let i = index; columns: transferDisplayedColumns"
                ></tr>
            </table>
        </div>
    </div>

    <div class="my-3" *ngIf="!dialogMode">
        Related Journal Records
        <button mat-icon-button (click)="showJournals = !showJournals">
            <mat-icon *ngIf="showJournals">expand_more</mat-icon>
            <mat-icon *ngIf="!showJournals">chevron_right</mat-icon>
        </button>

        <div *ngIf="showJournals">
            <table
                mat-table
                *ngIf="dataSource.data && dataSource.data.length > 0"
                [dataSource]="dataSource"
                class="mat-elevation-z1 w-100 journals-table"
            >
                <ng-container matColumnDef="account_name">
                    <th mat-header-cell *matHeaderCellDef disableClear>Account</th>
                    <td mat-cell *matCellDef="let element">{{ element.ledger_account.name }}</td>
                    <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
                </ng-container>

                <ng-container matColumnDef="debit">
                    <th mat-header-cell *matHeaderCellDef>Debit</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.debit_or_credit === 'debit'">{{ element.amount | currency }}</div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <b>{{ getTotalFor('debit') | currency }}</b>
                    </td>
                </ng-container>

                <ng-container matColumnDef="credit">
                    <th mat-header-cell *matHeaderCellDef>Credit</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.debit_or_credit === 'credit'">{{ element.amount | currency }}</div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <b>{{ getTotalFor('credit') | currency }}</b>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row class="journals-row" *matRowDef="let row; columns: displayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </table>
        </div>
    </div>
</section>
