import {GenericModel} from './helpers/genericModel';

export interface SystemNotificationInterface {
    sender_user_id: number | null;
    status: string | null;
    text: string | null;
    type: string | null;
    $key?: string | null;
}

export interface UserNotificationInterface {
    sender_user_id: number | null;
    status: string | null;
    text: string | null;
    type: string | null;
    timestamp: number | null;
    payload?: any;
    system?: boolean;
    $key?: string | null;
}

export interface SpannerNotificationInterface {
    id: number;
    notification_id: number;
    profile_fk_id: number;
    company_fk_id: number;
    category: string;
    metadata: any;
    payload: any;
    message: any;
    status: string;
    action: string;
    link: string;
    seen: boolean;
    seen_by: number[];
    flagged_by: number[];
    seen_by_sense: string;
    created_at: string;
}
export interface SpannerNotificationRefValueInterface {
    ref: number | string;
    value: SpannerNotificationInterface & {IS_SEEN: boolean};
}

export class UserNotification extends GenericModel implements UserNotificationInterface {
    public static provideStatuses() {
        return {
            new: 'new',
            seen: 'seen',
            all: function () {
                const keys: string[] = [];
                const types: {[key: string]: any} = this;
                Object.keys(this).forEach((key) => {
                    if (typeof types[key] === 'string') {
                        if (types.hasOwnProperty(key)) {
                            keys.push(types[key]);
                        }
                    }
                });
                return keys;
            }
        };
    }
    public static provideTypes() {
        return {
            association: 'association',
            importJson: 'import-json',
            importCsv: 'import-csv',
            all: function () {
                const keys: string[] = [];
                const types: {[key: string]: any} = this;
                Object.keys(this).forEach((key) => {
                    if (typeof types[key] === 'string') {
                        if (types.hasOwnProperty(key)) {
                            keys.push(types[key]);
                        }
                    }
                });
                return keys;
            }
        };
    }

    public static provideFilterTypes() {
        return [
            {
                value: 'all',
                label: 'All',
                divider: false,
                mutable: false
            },
            {
                value: 'unread',
                label: 'Unread',
                divider: false,
                mutable: false
            },
            {
                value: 'flagged',
                label: 'Flagged',
                divider: true,
                mutable: false
            },
            {
                value: 'deals_calculation',
                label: 'Deals Calculation',
                divider: false,
                mutable: true
            },
            {
                value: 'addon_event',
                label: 'Addon Event',
                divider: false,
                mutable: true
            },
            {
                value: 'import',
                label: 'Import',
                divider: false,
                mutable: true
            }
        ];
    }

    public static provideSeenBySenseTypes() {
        return {
            who_marked_as_viewed: 'who_marked_as_viewed',
            who_marked_as_not_viewed: 'who_marked_as_not_viewed'
        };
    }

    text: string | null = null;
    message: string | null = null;
    type: string | null = null;
    sender_user_id: number | null = null;
    status: string | null = null;
    key: string | null = null;
    timestamp: number | null = null;
    id: number | null = null;
    seen_by_sense: string | null = null;
    $key?: string | null = null;
}

export class SystemNotification extends GenericModel implements SystemNotificationInterface {
    public static provideStatuses() {
        return {
            new: 'new',
            seen: 'seen',
            all: function () {
                const keys: string[] = [];
                const types: {[key: string]: any} = this;
                Object.keys(this).forEach((key) => {
                    if (typeof types[key] === 'string') {
                        if (types.hasOwnProperty(key)) {
                            keys.push(types[key]);
                        }
                    }
                });
                return keys;
            }
        };
    }

    public static provideTypes() {
        return {
            systemReloadUiMods: 'system-reload-ui-mods',
            all: function () {
                const keys: string[] = [];
                const types: {[key: string]: any} = this;
                Object.keys(this).forEach((key) => {
                    if (typeof types[key] === 'string') {
                        if (types.hasOwnProperty(key)) {
                            keys.push(types[key]);
                        }
                    }
                });
                return keys;
            }
        };
    }

    text: string | null = null;
    type: string | null = null;
    sender_user_id: number | null = null;
    status: string | null = null;
    key: string | null = null;
    id: number | null = null;
    $key?: string | null = null;
}
