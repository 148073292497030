import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ICreditNote} from '@cyberco-nodejs/zipi-typings';
import {Subject} from 'rxjs';
import {CREDITS_STATUS_COLOR, PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {takeUntil} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {MatOptionSelectionChange} from '@angular/material/core';
import {CreditNotesService} from 'app/services/api/finance/credit-notes.service';
import {IScrollData} from 'app/models/scroll-data';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-credit-notes',
    templateUrl: 'credit-notes.component.html',
    styleUrls: ['credit-notes.component.scss', '../../../../../assets/infinite-scroll-table.scss']
})
export class CreditNotesComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;

    statusColor = CREDITS_STATUS_COLOR;
    statusTypes = ['all', 'open', 'closed', 'applied', 'void', 'draft'];
    statusFilter: UntypedFormControl = new UntypedFormControl([]);

    scrollData: IScrollData = {
        sort_column: 'credit_note_date',
        sort_direction: 'desc',
        filter: null,
        offset: 0,
        limit: 50,
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<ICreditNote>;
    displayedColumns = [
        'credit_note_date',
        'customer_contact_name',
        'credit_note_number',
        'reference',
        'status',
        'total_amount'
    ];

    creditsMenuOpened: boolean = false;

    constructor(public creditNotesService: CreditNotesService) {
        this.dataSource = new MatTableDataSource<ICreditNote>([]);
    }

    ngOnInit() {
        this.getCreditNotes();

        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.getCreditNotes();
            });
        }
    }

    getCreditNotes() {
        // cancel previous batch requests
        this.unsubscribeBatch.next();

        if (this.statusFilter.value && !this.statusFilter.value.includes('all')) {
            this.scrollData.filter = this.statusFilter.value;
        } else {
            this.scrollData.filter = '';
        }

        this.creditNotesService
            .getCreditNotes(this.scrollData)
            .pipe(takeUntil(this.unsubscribeBatch))
            .subscribe((data) => {
                this.dataSource.data = data.result;
                this.scrollData.total = data._meta.total;
            });
    }

    changeStatusFilter(event: MatOptionSelectionChange) {
        if (event.isUserInput) {
            const {value, selected} = event.source;
            let statusFilter = this.statusFilter.value;

            if (value === 'all') {
                statusFilter = selected ? this.statusTypes : [];
            } else if (value !== 'all') {
                if (statusFilter.includes('all') && !selected) {
                    statusFilter = statusFilter.filter((f: string) => f !== 'all' && f !== value);
                } else {
                    statusFilter = this.statusTypes.filter(
                        (f) => (f !== value && statusFilter.includes(f)) || (f === value && selected)
                    );
                }
            }

            // update values
            this.scrollData.total = 0;
            this.scrollData.offset = 0;
            this.paginator!.pageIndex = 0;
            this.statusFilter.setValue(statusFilter);
            this.getCreditNotes();
        }
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        this.scrollData.offset = 0;
        this.paginator!.pageIndex = 0;
        this.getCreditNotes();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
    }
}
