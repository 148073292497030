<div>
    <div
        style="border: 1px darkorange solid; border-radius: 5px; background-color: #fff4e0; padding: 5px"
        *ngIf="invoice?.money_sender__company_fk_id"
    >
        This Invoice already associated with specific company. It can be opened only there.<br />
        If you think this association is incorrect, please contact support.<br />
    </div>
    <div *ngIf="invoiceEmailCompanies && invoiceEmailCompanies.length > 0">
        <h3>Your Companies</h3>
        <mat-card *ngFor="let comp of invoiceEmailCompanies" class="d-flex align-items-center" style="margin: 2px">
            <div class="w-60">
                {{ comp.title }}
            </div>
            <div class="w-40 d-flex justify-content-end" *ngIf="invoice && currentContact">
                <mat-icon
                    *ngIf="
                        !isUserCanAssociateInvoiceToCompany(comp, invoice) && comp.id !== invoice.owner__company_fk_id
                    "
                    [matTooltip]="
                        'You don\'t have permission to associate invoice with company \'' +
                        comp?.title +
                        '\'. If you would like to proceed with connecting this document in the selected company, please ask the company administrator.'
                    "
                    style="color: red"
                    >warning</mat-icon
                >

                <mat-icon
                    *ngIf="
                        !isUserCanAssociateInvoiceToCompany(comp, invoice) && comp.id === invoice.owner__company_fk_id
                    "
                    [matTooltip]="'Company Initiator.'"
                    style="color: red"
                    >warning</mat-icon
                >

                <button
                    type="button"
                    mat-raised-button
                    color="primary"
                    *ngIf="
                        loggedProfile &&
                        isUserCanAssociateInvoiceToCompany(comp, invoice) &&
                        !invoice.money_sender__company_fk_id
                    "
                    (click)="associate(comp)"
                >
                    Associate
                </button>
                <button
                    type="button"
                    mat-raised-button
                    color="primary"
                    *ngIf="
                        loggedProfile &&
                        isUserCanAssociateInvoiceToCompany(comp, invoice) &&
                        comp.company_id === invoice.money_sender__company_fk_id
                    "
                    (click)="switchToCompanyAndNavigate(comp)"
                >
                    View in Portal
                </button>
                <button
                    type="button"
                    mat-raised-button
                    color="primary"
                    *ngIf="
                        !loggedProfile &&
                        isUserCanAssociateInvoiceToCompany(comp, invoice) &&
                        !invoice.money_sender__company_fk_id
                    "
                    (click)="loginAssociate(comp)"
                >
                    Login & Associate
                </button>
            </div>
            <!--			<div class="w-40 d-flex justify-content-end" *ngIf="invoice && currentContact && (canConnectToOtherAccounts || comp.company_id === loggedProfile.user.finance__company_fk_id)">-->
            <!--				<button type="button"-->
            <!--				        mat-raised-button-->
            <!--				        color="primary"-->
            <!--				        *ngIf="loggedProfile-->
            <!--				        && loggedProfile.company-->
            <!--				        && loggedProfile.company.id !== invoice.owner__company_fk_id-->
            <!--				        && (!currentContact || !currentContact.partner__company_fk_id)-->
            <!--						&& comp && loggedProfile.company.id === comp.company_id"-->
            <!--				        (click)="associate(comp)">-->
            <!--					Associate-->
            <!--				</button>-->

            <!--				<button type="button"-->
            <!--				        mat-raised-button-->
            <!--				        color="primary"-->
            <!--				        *ngIf="(!currentContact || !currentContact.partner__company_fk_id)-->
            <!--						&& (!loggedProfile || (loggedProfile.company && comp && loggedProfile.company.id !== comp.company_id))"-->
            <!--				        (click)="loginAssociate(comp)">-->
            <!--					Login-->
            <!--				</button>-->

            <!--				<button type="button"-->
            <!--				        mat-raised-button-->
            <!--				        color="primary"-->
            <!--				        *ngIf="currentContact.partner__company_fk_id-->
            <!--				        && currentContact.partner__company_fk_id === comp.company_id"-->
            <!--				        (click)="login(comp)">-->
            <!--					Login-->
            <!--				</button>-->
            <!--			</div>-->
        </mat-card>
    </div>
    <div
        *ngIf="
            loggedUserCompanies &&
            loggedUserCompanies.length > 0 &&
            (!invoice || !invoice.money_sender__company_fk_id) &&
            (!currentContact || !currentContact.partner__company_fk_id)
        "
    >
        <mat-card *ngFor="let comp of loggedUserCompanies" class="d-flex align-items-center">
            <div class="w-60">
                {{ comp.title }}
            </div>
            <mat-icon
                *ngIf="comp && invoice && comp.company_id === invoice.owner__company_fk_id"
                [matTooltip]="'Company Initiator'"
                style="color: red"
                >warning</mat-icon
            >
            <mat-icon
                *ngIf="
                    existingConnectedContact[comp?.company_id!] && comp?.company_id !== invoice?.owner__company_fk_id
                "
                [matTooltip]="
                    'A different connection between contacts in ' +
                    comp?.title +
                    ' and ' +
                    invoice?.owner_company?.title +
                    ' was already established. If you would like to proceed with connecting this document in the selected company, please ask the originating company to issue the invoice under the existing contact record (Contact Record Number: ' +
                    existingConnectedContact[comp?.company_id!] +
                    ')'
                "
                style="color: red"
                >warning</mat-icon
            >

            <div class="w-40 d-flex justify-content-end" *ngIf="invoice && currentContact">
                <button
                    type="button"
                    mat-raised-button
                    color="primary"
                    [disabled]="
                        !!existingConnectedContact[comp?.company_id!] ||
                        comp?.company_id === invoice?.owner__company_fk_id ||
                        !isUserCanAssociateInvoiceToCompany(comp, invoice)
                    "
                    (click)="$event.stopPropagation(); associate(comp)"
                >
                    Associate
                </button>
            </div>
        </mat-card>
    </div>
    <div class="my-3" *ngIf="!invoice || !invoice.money_sender__company_fk_id">
        <mat-card
            class="d-flex align-items-center"
            *ngIf="
                (!loggedProfile || !loggedProfile.user || !loggedProfile.user.finance__company_fk_id) &&
                !userFinancialCompanyId
            "
        >
            <div class="w-60">Register new Company</div>
            <div class="w-40 d-flex justify-content-end">
                <button type="button" mat-raised-button color="primary" (click)="registration()">Register</button>
            </div>
        </mat-card>

        <!--        <mat-card class="d-flex align-items-center" *ngIf="!loggedProfile">-->
        <!--            <div class="w-60">-->
        <!--                Login-->
        <!--            </div>-->
        <!--            <div class="w-40 d-flex justify-content-end">-->
        <!--                <button type="button"-->
        <!--                        mat-raised-button-->
        <!--                        color="primary"-->
        <!--                        (click)="justLogin()">-->
        <!--                    Login-->
        <!--                </button>-->
        <!--            </div>-->
        <!--        </mat-card>-->
    </div>
</div>
