import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {GenericFormGroup} from '@app/entites/generic.entity';
import {OpeningPerformanceValuesEntity} from '@app/entites/opening-performance-values.entity';
import {IMetric} from '@app/typings/calculation-settings.typings';
import {CalculationSettingsService as NewCalculationSettingsService} from '@app/services/new-calculation-settings.service';
import {Subject} from 'rxjs';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-opening-performance-values-metric-item',
    template: `
        <section>
            <div class="d-flex align-items-md-center bg-light rounded p-2 mb-3">
                <div class="row align-items-end w-100 d-nline-flex">
                    <mat-form-field class="col-md-6 col-lg-4">
                        <input
                            *ngIf="openingValuesFG.controls.closing_date"
                            [formControl]="$any(openingValuesFG.controls.closing_date)"
                            matInput
                            [matDatepicker]="picker"
                            placeholder="Apply Date"
                            required
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <strong class="col-md-9 col-lg-12" style="margin-bottom: 10px;">Virtual Values:</strong>

                    <div class="d-flex flex-column w-100 m-3">
                        <div class="d-flex">
                            <button
                                mat-raised-button
                                style="width: fit-content;"
                                class="mb-3"
                                [matMenuTriggerFor]="productValueMenu"
                            >
                                Add Metric Value
                            </button>
                        </div>

                        <mat-menu #productValueMenu="matMenu">
                            <button *ngIf="!metricsAvailableToAdd.length" class="font-italic" mat-menu-item disabled>
                                No Available Metrics
                            </button>
                            <button
                                mat-menu-item
                                *ngFor="let metric of metricsAvailableToAdd"
                                [value]="metric.metric_id"
                                (click)="addModifier(metric.metric_id)"
                            >
                                {{ metric.title }}
                            </button>
                        </mat-menu>

                        <div class="d-flex flex-row align-items-center flex-wrap">
                            <div class="d-flex flex-row align-items-center" *ngFor="let metricType of addedMetrics">
                                <mat-form-field>
                                    <input
                                        [formControl]="
                                            this.openingValuesFG.controls.virtual_values!.controls.agent_metrics_values!
                                                .controls[metricType]
                                        "
                                        matInput
                                        [placeholder]="getMetricTitle(metricType)"
                                        type="number"
                                    />
                                </mat-form-field>
                                <button
                                    mat-icon-button
                                    type="button"
                                    matTooltip="Remove value"
                                    (click)="removeValue(metricType)"
                                >
                                    <mat-icon>close</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <button (click)="delete.emit()" mat-icon-button type="button" matTooltip="Remove Modifier">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </section>
    `,
    styleUrls: ['../contact-edit.component.css']
})
export class OpeningPerformanceValuesMetricItemComponent implements OnInit, OnDestroy, OnChanges {
    private unsubscribe: Subject<void> = new Subject();
    metrics: IMetric[] = [];
    metricsAvailableToAdd: {metric_id: number; title: string}[] = [];
    addedMetrics: number[] = [];
    @Input() openingValuesFG: GenericFormGroup<OpeningPerformanceValuesEntity> =
        new GenericFormGroup<OpeningPerformanceValuesEntity>(
            new OpeningPerformanceValuesEntity().setType(OpeningPerformanceValuesEntity.TYPE_set.metric_value_modifier)
        );
    @Output() delete = new EventEmitter();

    constructor(protected newCalculationSettingsService: NewCalculationSettingsService) {
        this.metrics = this.newCalculationSettingsService.metrics;
        this.syncMetricsAvailableToAdd();
    }

    syncMetricsAvailableToAdd() {
        this.metricsAvailableToAdd = this.metrics
            .filter((metric) => !this.addedMetrics.includes(metric.metric_id!))
            .map((metric) => ({
                metric_id: metric.metric_id!,
                title: metric.title!
            }));
    }

    ngOnInit(): void {}

    object_keys(obj: {[key: string]: any}): string[] {
        if (!obj) {
            return [];
        }
        return Object.keys(obj);
    }

    addModifier(metricType: number) {
        if (this.openingValuesFG.controls.virtual_values?.controls.agent_metrics_values!.value === null) {
            this.openingValuesFG.controls.virtual_values!.controls.agent_metrics_values = new GenericFormGroup({
                [metricType]: 0
            });
        }
        if (
            this.openingValuesFG.controls.virtual_values!.controls.agent_metrics_values! instanceof GenericFormGroup &&
            !this.openingValuesFG.controls.virtual_values!.controls.agent_metrics_values!.controls[metricType]
        ) {
            this.openingValuesFG.controls.virtual_values!.controls.agent_metrics_values!.addControl(
                String(metricType),
                new FormControl(0)
            );
        }

        this.addedMetrics.push(metricType);
        this.syncMetricsAvailableToAdd();
    }

    getMetricTitle(metricId: number) {
        return (this.metrics.find((metric) => metric.metric_id === metricId) || {}).title;
    }

    removeValue(metricType: number) {
        this.addedMetrics.splice(this.addedMetrics.indexOf(metricType), 1);
        this.syncMetricsAvailableToAdd();
        delete (
            this.openingValuesFG.controls.virtual_values!.controls.agent_metrics_values! as GenericFormGroup<{
                [keys: number]: number;
            }>
        ).controls[metricType];
    }

    ngOnChanges() {
        Object.keys(this.openingValuesFG.controls.virtual_values!.controls).forEach((metricType) => {
            if (
                metricType === 'agent_metrics_values' &&
                this.openingValuesFG.controls.virtual_values!.controls.agent_metrics_values &&
                this.openingValuesFG.controls.virtual_values!.controls.agent_metrics_values.value
            ) {
                Object.keys(
                    (
                        this.openingValuesFG.controls.virtual_values!.controls
                            .agent_metrics_values! as GenericFormGroup<{
                            [keys: number]: number;
                        }>
                    ).controls
                ).forEach((metricId) => {
                    this.addedMetrics.push(Number(metricId));
                    this.syncMetricsAvailableToAdd();
                });
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
