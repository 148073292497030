<div #asyncNotificationsSection class="shipp-notifications-container">
    <mat-progress-bar
        style="position: fixed; z-index: 1"
        *ngIf="showCustomProgressBar"
        mode="indeterminate"
        color="warn"
        class="pw-progress-bar"
    ></mat-progress-bar>
    <div class="shipp-notifications-filter">
        <!--        <mat-form-field style="max-width: 135px;">-->
        <!--            <mat-select-->
        <!--                disabled-->
        <!--                (selectionChange)="filterNotificationsChanged($event)"-->
        <!--                [(ngModel)]="filterNotifications"-->
        <!--                name="filter_notifications">-->
        <!--                <mat-option *ngFor="let filterNotificationsType of filterNotificationsTypes" [value]="filterNotificationsType.value">-->
        <!--                    {{ filterNotificationsType.label }}-->
        <!--                </mat-option>-->
        <!--            </mat-select>-->
        <!--        </mat-form-field>-->

        <button [disabled]="filterDisabled" type="button" mat-button [matMenuTriggerFor]="notificationsfilterMenu">
            {{ filterNotificationsTypesLabels[filterNotifications] }}
            <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #notificationsfilterMenu="matMenu">
            <div *ngFor="let fnType of filterNotificationsTypes">
                <button
                    class="d-flex align-items-center justify-content-between"
                    (click)="filterNotificationsChanged(fnType.value)"
                    type="button"
                    mat-menu-item
                >
                    <span>{{ fnType.label }}</span>
                    <button
                        *ngIf="fnType.mutable"
                        (click)="muteNotificationCategory($event, fnType.value)"
                        mat-icon-button
                    >
                        <mat-icon
                            *ngIf="!mutedCategoriesObj[fnType.value]"
                            matTooltip="Enabled. Click to Disable"
                            class="mx-1"
                            >notifications_none</mat-icon
                        >
                        <mat-icon
                            *ngIf="mutedCategoriesObj[fnType.value]"
                            matTooltip="Disabled. Click to Enable"
                            class="mx-1"
                            >notifications_off</mat-icon
                        >
                    </button>
                </button>
                <mat-divider *ngIf="fnType.divider"></mat-divider>
            </div>
        </mat-menu>

        <button
            type="button"
            mat-button
            color="primary"
            [disabled]="markAsReadBtnDisabled"
            (click)="markAllAsSeen()"
            *ngIf="notifications.length > 0"
        >
            Mark all as read
        </button>
    </div>

    <mat-card class="p-2" *ngIf="notifications.length === 0 && unreadNotifications.length === 0"
        >No Notifications</mat-card
    >
    <div *ngIf="unreadNotifications.length > 0">
        <mat-card class="p-2 d-flex w-100" *ngFor="let zipiNotification of unreadNotifications; let i = index">
            <!--            <app-user-notification-item-association-->
            <!--                class="w-100"-->
            <!--                *ngIf="zipiNotification.type === typeAssociation"-->
            <!--                [notification]="zipiNotification"-->
            <!--                (dismiss)="onDismiss(i)"></app-user-notification-item-association>-->
            <app-user-notification-item-import-json
                class="w-100"
                *ngIf="zipiNotification.type === typeImportJson"
                [notification]="zipiNotification"
                (dismiss)="onDismiss(i)"
            ></app-user-notification-item-import-json>
            <app-user-notification-item-import-csv
                class="w-100"
                *ngIf="zipiNotification.type === typeImportCsv"
                [notification]="zipiNotification"
                (dismiss)="onDismiss(i)"
            ></app-user-notification-item-import-csv>
        </mat-card>
    </div>

    <app-lazy-loading
        [useInfiniteScrollTop]="false"
        [data]="notifications"
        [itemHeightPx]="60"
        [minBufferPx]="1200"
        [maxBufferPx]="2400"
        [offsetTop]="offsetTopToLazyLoading"
        (scrolledDown)="nextBatch()"
    >
        <ng-template let-notification="listItem" let-idx="index">
            <app-notification-badge
                (markAsFlaggedEvent)="markAsFlagged($event)"
                (markAsUnseenEvent)="markAsUnseen($event)"
                (markAsSeenEvent)="markAsSeen($event)"
                (markAsWarningEvent)="markAsWarning($event)"
                [notification]="notification"
            >
            </app-notification-badge>
        </ng-template>
    </app-lazy-loading>
</div>
