<section class="container-fluid">
    <header class="my-2">
        <h1 *ngIf="!garnishment">Create Garnishment</h1>
        <h1 *ngIf="garnishment">Edit Garnishment</h1>
    </header>

    <div [formGroup]="formGroup">
        <div class="row mr-0">
            <div class="col col-md-6" style="height: 65.5px">
                <!--                <mat-form-field class="w-100">-->
                <!--                    <mat-select formControlName="receiver__contact_fk_id" required placeholder="Contacts/User">-->
                <!--                        <ng-container *ngFor="let contact of contact$ | async">-->
                <!--                            <mat-option *ngIf="contact.owner_type === 'company'" [value]="contact.id">-->
                <!--                                {{contact.display_name}}-->
                <!--                            </mat-option>-->
                <!--                        </ng-container>-->
                <!--                    </mat-select>-->
                <!--                </mat-form-field>-->

                <app-company-compensation-combined-picker
                    style="width: 100%; margin-right: 8px"
                    [availableTypes]="['contact']"
                    [title]="'Contacts/User'"
                    [only_compensation_groups]="false"
                    [singleSelected]="true"
                    [bold_style]="false"
                    [nodesFA]="moneySenderCtrlArr"
                    [initialContactIds]="savedContacts"
                >
                </app-company-compensation-combined-picker>
            </div>

            <div class="col col-md-6" style="height: 65.5px">
                <mat-form-field>
                    <mat-select
                        formControlName="interest_rate_type"
                        class="w-100"
                        placeholder="Interest Rate Type"
                        required
                    >
                        <mat-option *ngFor="let interest_type of interestRateType" [value]="interest_type.value">{{
                            interest_type.label
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field
                    *ngIf="formGroup.controls.interest_rate_type.value === 'flat'"
                    class="w-100 align-items-baseline"
                    class="col col-md-3"
                >
                    <input
                        matInput
                        formControlName="interest_rate_flat"
                        placeholder="Value"
                        type="text"
                        [maskito]="currencyMaskitoMask"
                    />
                </mat-form-field>
                <mat-form-field
                    *ngIf="formGroup.controls.interest_rate_type.value === 'percent'"
                    class="w-100 align-items-baseline"
                    class="col col-md-3"
                >
                    <input matInput formControlName="interest_rate_percent" placeholder="Percent" type="number" />
                    <!--                    <mat-icon matPrefix class="prefix">attach_percent</mat-icon>-->
                </mat-form-field>
            </div>
        </div>

        <div class="row mr-0">
            <div class="col col-md-6">
                <div class="d-flex align-items-baseline">
                    <!--                    <mat-form-field class="w-100">-->
                    <!--                        <mat-select formControlName="sender__contact_fk_id" required placeholder="Originator">-->
                    <!--                            <ng-container *ngFor="let contact of contact$ | async">-->
                    <!--                                <mat-option *ngIf="contact.owner_type === 'company'" [value]="contact.id">-->
                    <!--                                    {{contact.display_name}}-->
                    <!--                                </mat-option>-->
                    <!--                            </ng-container>-->
                    <!--                        </mat-select>-->
                    <!--                    </mat-form-field>-->

                    <app-company-compensation-combined-picker
                        style="width: 100%; margin-right: 8px"
                        [availableTypes]="['contact']"
                        [title]="'Originator'"
                        [only_compensation_groups]="false"
                        [singleSelected]="true"
                        [bold_style]="false"
                        [nodesFA]="originatorCtrlArr"
                        [initialContactIds]="originatorSavedContacts"
                    >
                    </app-company-compensation-combined-picker>
                    <button
                        type="button"
                        mat-icon-button
                        matSuffix
                        (click)="contactCreate()"
                        [anyRule]="[{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]"
                        rbac
                        matTooltip="Create Contact"
                    >
                        <mat-icon>person_add</mat-icon>
                    </button>
                </div>
            </div>

            <div class="col col-md-6">
                <mat-form-field>
                    <mat-select
                        formControlName="interest_rate_limit_type"
                        class="w-100"
                        placeholder="Interest Rate Limit"
                        required
                    >
                        <mat-option *ngFor="let interest_type of interestRateLimitType" [value]="interest_type.value">{{
                            interest_type.label
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field
                    *ngIf="formGroup.controls.interest_rate_limit_type.value === 'date'"
                    class="col col-md-3"
                >
                    <input
                        matInput
                        type="text"
                        required
                        placeholder="Date"
                        [matDatepicker]="interest_rate_limit_date"
                        formControlName="interest_rate_limit_date"
                        (click)="interest_rate_limit_date.open()"
                        (focus)="interest_rate_limit_date.open()"
                    />
                    <mat-datepicker-toggle matSuffix [for]="interest_rate_limit_date"></mat-datepicker-toggle>
                    <mat-datepicker #interest_rate_limit_date></mat-datepicker>
                </mat-form-field>
                <mat-form-field
                    *ngIf="formGroup.controls.interest_rate_limit_type.value === 'number_of_periods'"
                    class="w-100 align-items-baseline"
                    class="col col-md-3"
                >
                    <input
                        matInput
                        required
                        formControlName="interest_rate_limit_number"
                        placeholder="Number of Periods"
                        type="number"
                    />
                </mat-form-field>
            </div>
        </div>

        <div class="row mr-0">
            <div class="col col-md-6">
                <app-product-service-selector
                    (onProductSelectionChange)="selectProduct($event)"
                    [productServiceControl]="formGroup.controls.product_fk_id"
                    [placeholder]="'Product/Service'"
                >
                </app-product-service-selector>
            </div>

            <div class="col col-md-6">
                <mat-form-field>
                    <mat-select formControlName="interest_period" class="w-100" placeholder="Interest Period" required>
                        <mat-option *ngFor="let interest_period of interestPeriod" [value]="interest_period.value">{{
                            interest_period.label
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row mr-0">
            <div class="col col-md-6">
                <mat-form-field>
                    <input
                        matInput
                        type="text"
                        required
                        placeholder="Date"
                        [matDatepicker]="date"
                        formControlName="date"
                        (click)="date.open()"
                        (focus)="date.open()"
                    />
                    <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                    <mat-datepicker #date></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="w-100 align-items-baseline" class="col col-md-3">
                    <input
                        matInput
                        required
                        formControlName="amount"
                        [maskito]="currencyMaskitoMask"
                        placeholder="Amount"
                        type="text"
                    />
                </mat-form-field>

                <div class="col col-md-6">
                    <mat-checkbox formControlName="is_deduct_from_deals">Deduct from future deals</mat-checkbox>
                </div>
            </div>
        </div>

        <div class="d-flex mt-2">
            <div
                class="ml-1"
                matTooltip="Garnishment with payments cannot be deleted."
                [matTooltipDisabled]="!hasPayments"
            >
                <button
                    type="!submit"
                    mat-raised-button
                    color="warn"
                    *ngIf="garnishment"
                    [disabled]="hasPayments"
                    (click)="deleteGarnishment()"
                >
                    Delete
                </button>
            </div>

            <span class="sp-spacer"></span>

            <button mat-raised-button class="ml-2" routerLink="/lending/garnishments">Cancel</button>

            <button
                type="!submit"
                class="ml-2"
                mat-raised-button
                color="primary"
                *ngIf="!garnishment"
                (click)="createGarnishment()"
            >
                Create
            </button>

            <div
                class="ml-2"
                matTooltip="The amount of the Garnishment with payments can not be less than the amount of payments"
                [matTooltipDisabled]="!hasPayments || garnishment!.amount < formGroup.controls.amount.value"
            >
                <button
                    type="!submit"
                    class="ml-2"
                    mat-raised-button
                    color="primary"
                    *ngIf="garnishment"
                    [disabled]="hasPayments && garnishment.amount > formGroup.controls.amount.value"
                    (click)="updateGarnishment()"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</section>
