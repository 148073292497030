import * as moment from 'moment';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {Deal} from '../../../../models/deal';
import {NotificationsServiceZipi} from '../../../notifications/notifications.service';
import {FinancialElementModel} from '../models/financial-element.model';
import {CompensationService} from '../compensation.service';
import {CompensationProfileModel} from '../models/compensation-profile.model';
import {SalesEntity} from '../../../../models/sales-entity';
import {ConfirmComponent} from '../../../../layouts/confirm/confirm.component';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {DealProcessingService} from '../../../deals/components/deal/deal-processing.service';

@Component({
    selector: 'app-company-compensation-profile-override',
    styles: [
        `
            .label {
                margin-right: 16px;
            }

            .card-content {
                align-items: center;
                display: flex;
            }

            .section-margin {
                margin-bottom: 0px;
            }

            .rules-disabled-message {
                color: rgba(0, 0, 0, 0.5);
                padding: 5%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-style: italic;
            }
        `
    ],
    template: `
        <div class="p-4">
            <div class="apply-rules">
                <div
                    class="justify-content-between align-items-center"
                    [rule]="{deals__manage_override: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'flex'}"
                    [denyStyle]="{display: 'none'}"
                >
                    <mat-slide-toggle
                        *ngIf="dealProcessingService?.dealFG?.controls?.apply_rules"
                        [formControl]="dealProcessingService!.dealFG!.controls.apply_rules!"
                    >
                        Apply Rules
                    </mat-slide-toggle>

                    <button
                        *ngIf="!readonly && !hasOverride"
                        [disabled]="
                            disabled ||
                            compensationForm?.controls?.status?.value === COMPENSATION_PROFILE.status_set.imported
                        "
                        mat-raised-button
                        class="mat-warn"
                        (click)="doResetToDefaults()"
                    >
                        Restore to Initial State
                    </button>
                </div>
                <hr />
            </div>

            <ng-container *ngIf="dealProcessingService?.dealFG?.controls?.apply_rules?.value">
                <div *ngIf="hasOverride" class="mb-4">Applied Compensation Rules (already overriden):</div>
                <div
                    *ngIf="
                        !hasOverride &&
                        !editMode &&
                        !dealProcessingService?.dealFG?.controls?.disbursement_approved?.value
                    "
                    class="mb-4"
                    style="width: 100%;"
                    rbac
                    [rule]="{deals__manage_override: true}"
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                >
                    <div
                        style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;"
                    >
                        Applied Compensation Rules:
                        <button
                            [disabled]="
                                disabled ||
                                (!editMode && viewMode) ||
                                compensationForm?.controls?.status?.value === COMPENSATION_PROFILE.status_set.imported
                            "
                            mat-raised-button
                            class="mat-warn"
                            (click)="doEditMode()"
                        >
                            Customize Rules
                        </button>
                    </div>
                </div>
                <div *ngIf="dealProcessingService?.dealFG?.controls?.disbursement_approved?.value" class="mb-4">
                    To Override, the Disbursement must be unapproved.
                </div>

                <mat-form-field>
                    <mat-select #disbursementSelect [(value)]="selectedDisbursement">
                        <mat-option [disabled]="!item.id" *ngFor="let item of salesEntities" [value]="item.id">
                            {{ item.contact_part_link?.link_title }}
                        </mat-option>
                    </mat-select>
                    <mat-placeholder>Sales Entity</mat-placeholder>
                </mat-form-field>
                <!--            <app-sale-entities [isPrimarySalesEntity]="isPrimarySalesEntity" style="margin-top: 22px; width: 100%;"-->
                <!--                               [salesEntitiesFormArray]="dealFormGroup.controls.sales_entities"></app-sale-entities>-->
                <div
                    class="section-margin"
                    *ngFor="
                        let financialElementForm of compensationForm?.controls?.financial_elements?.controls;
                        index as pci;
                        last as last;
                        first as first
                    "
                >
                    <app-company-compensation-financial-element
                        [isOverride]="true"
                        [financialElementFG]="financialElementForm"
                        [disabled]="!editMode || disabled"
                        [show_down]="!last"
                        [show_up]="!first"
                        (delete)="doDeletePerformanceCompensationProfile(pci)"
                        (up)="doMovePerformanceCompensationProfileUp(pci)"
                        (down)="doMovePerformanceCompensationProfileDown(pci)"
                    >
                    </app-company-compensation-financial-element>
                </div>

                <div
                    *ngIf="editMode && !readonly"
                    class="flex-row"
                    style="align-items: center; justify-content: space-between; width: 100%"
                >
                    <button
                        mat-raised-button
                        style="width:100%"
                        [disabled]="disabled"
                        (click)="doAddPerformanceCompensationProfile()"
                    >
                        Add New Element
                    </button>
                </div>

                <div
                    *ngIf="compensationForm?.controls?.note?.value"
                    class="flex-row w-100"
                    style="align-items: center; justify-content: space-between;"
                >
                    {{ compensationForm?.controls?.note?.value }}
                </div>

                <div *ngIf="editMode && !readonly" style="margin-top: 8px">
                    <div class="card-content">
                        <div class="flex-row" style="align-items: center; justify-content: space-between; width: 100%">
                            <button
                                [disabled]="disabled"
                                mat-raised-button
                                class="mat-warn"
                                (click)="doDeleteCompensationProfile()"
                            >
                                Remove Custom Rules
                            </button>

                            <div>
                                <button mat-raised-button class="mr-2" (click)="doCancelEditMode()">Cancel</button>
                                <button
                                    [disabled]="
                                        disabled ||
                                        compensationForm?.controls?.status?.value ===
                                            COMPENSATION_PROFILE.status_set.imported
                                    "
                                    mat-raised-button
                                    class="mat-primary mr-2"
                                    (click)="doSaveCompensationProfile()"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="rules-disabled-message" *ngIf="!dealProcessingService?.dealFG?.controls?.apply_rules?.value">
                Applying Rules is disabled for this deal
            </div>
        </div>
    `
})
export class CompensationProfileOverrideComponent implements OnInit, OnDestroy {
    @ViewChild('disbursementSelect', {static: true}) disbursementSelect: any;
    private unsubscribe: Subject<void> = new Subject();
    public statusFC = new UntypedFormControl(false);
    public COMPENSATION_PROFILE = CompensationProfileModel;
    public DEAL = Deal;

    public status_LABELS = {
        draft: 'Draft',
        active: 'Active'
    };

    public selectedDisbursement: number | null = null;
    public get hasOverride(): boolean {
        if (
            this.salesEntities.some((se) => !!se.overriden__compensation_profile_fk_id) &&
            !this.salesEntities.every((se) => !!se.overriden__compensation_profile_fk_id)
        ) {
            console.debug(
                `Compensation profile hav not been created for sales entities: ${this.salesEntities.map((se) => !se.overriden__compensation_profile_fk_id).join(',')}`
            );
        }
        const currentSE = this.salesEntities.find((se) => se.id === this.selectedDisbursement);
        if (!currentSE) {
            return false;
        }
        return !!currentSE.overriden__compensation_profile_fk_id;
    }

    public moment = moment;

    @Input() disabled: boolean = false;
    @Input() order: number = 0;
    @Input() editMode: boolean = false;
    @Input() viewMode: boolean = false;
    @Input() readonly: boolean = false;
    @Input() isSortBlacked: boolean = false;

    salesEntitiesCompensationProfiles: {
        salesEntity: SalesEntity;
        compensationForm: GenericFormGroup<CompensationProfileModel>;
    }[] = [];
    _salesEntities: SalesEntity[] = [];
    @Input() set salesEntities(salesEntities: SalesEntity[]) {
        this._salesEntities = salesEntities;
        this.salesEntities.forEach((salesEntity) => {
            const newFinancialElements = this.financialElementsBySalesEntity![salesEntity.id!];
            const filteredElements = newFinancialElements
                ? newFinancialElements.filter((a) => !!a.element.id).map((a) => a.element)
                : [];

            const newCompensationProfileForm = new GenericFormGroup<CompensationProfileModel>(
                new CompensationProfileModel()
            );
            filteredElements.forEach((elem) => {
                const feFG = new GenericFormGroup<FinancialElementModel>(new FinancialElementModel());
                feFG.patchValue(elem);
                newCompensationProfileForm.controls.financial_elements!.controls.push(feFG);
            });

            this.salesEntitiesCompensationProfiles.push({
                salesEntity: salesEntity,
                compensationForm: newCompensationProfileForm
            });
        });
    }
    get salesEntities(): SalesEntity[] {
        return this._salesEntities;
    }

    get compensationForm(): GenericFormGroup<CompensationProfileModel> | undefined {
        const foundCompensationProfile = this.salesEntitiesCompensationProfiles.find(
            (secp) => secp.salesEntity.id === this.selectedDisbursement
        );
        if (foundCompensationProfile) {
            return foundCompensationProfile.compensationForm;
        }
    }

    @Input() financialElementsBySalesEntityFC: UntypedFormControl = new UntypedFormControl();

    @Input() financialElementsBySalesEntity: {[key: string]: any[]} | undefined;

    @Output() save = new EventEmitter();
    @Output() saveDeal = new EventEmitter();
    @Output() cancel = new EventEmitter();
    @Output() delete = new EventEmitter();
    @Output() override = new EventEmitter<CompensationProfileModel>();
    @Output() overrideChange = new EventEmitter<{isOverridden: boolean}>();
    // @Output() copy = new EventEmitter();
    @Output() setFinanceElements = new EventEmitter<FinancialElementModel>();

    @Input() show_up: boolean = false;
    @Input() show_down: boolean = false;
    @Input() canOverride: boolean = false;

    @Output() up = new EventEmitter();
    @Output() down = new EventEmitter();

    protected compensationProfileSnapshot: CompensationProfileModel | null = null;

    // public COMPENSATION_PLAN = CompensationPlanModel;

    // protected lastValue: MultipleTargetsDealsQueryModel = new MultipleTargetsDealsQueryModel();

    // onStatusChange(event: MatSlideToggleChange) {
    //     if (event.checked) {
    //         this.compensationForm.controls.status.patchValue(this.COMPENSATION_PROFILE.status_set.active);
    //     } else {
    //         this.compensationForm.controls.status.patchValue(this.COMPENSATION_PROFILE.status_set.draft);
    //     }
    // }
    //
    // doOverride(commProfile: CompensationProfileModel) {
    //     const dialogRef = this.dialog.open(OverrideDialog);
    //     dialogRef.afterClosed().subscribe(result => {
    //         if (result) {
    //             this.override.emit(commProfile);
    //         }
    //     });
    // }
    //
    // doEditCompensationProfile() {
    //     this.compensationProfileSnapshot = this.compensationForm.getRawValue();
    //     this.editMode = true;
    // }

    setFinancialElements(elems: FinancialElementModel[]) {
        this.compensationForm!.controls.financial_elements!.controls = [];
        elems.forEach((elem) => {
            const feFG = new GenericFormGroup<FinancialElementModel>(new FinancialElementModel());
            feFG.patchValue(elem);
            this.compensationForm!.controls.financial_elements!.controls.push(feFG);
        });
        // this.compensationForm.controls.financial_elements.markAsDirty();
    }

    doCancelEditMode() {
        this.compensationForm!.patchValue(this.compensationProfileSnapshot!);
        this.editMode = false;
        this.viewMode = false;
        this.cancel.emit();
    }

    doDeleteCompensationProfile() {
        this.delete.emit(this.salesEntitiesCompensationProfiles);
    }

    // doCopyCompensationProfile() {
    //     // this.updateOrder();
    //     if (!this.compensationForm.valid) {
    //         return;
    //     }
    //     this.editMode = false;
    //     this.copy.emit();
    // }

    doSaveCompensationProfile() {
        this.updateOrder();
        this.salesEntitiesCompensationProfiles.forEach((secp) => {
            this.compensationService.setupCompensationProfileFormValidators(secp.compensationForm);
        });
        if (
            !this.salesEntitiesCompensationProfiles.every((secp) =>
                this.compensationService.isCompensationProfileFormValid(secp.compensationForm)
            )
        ) {
            return;
        }
        this.editMode = false;
        this.save.emit(this.salesEntitiesCompensationProfiles);
    }

    doAddPerformanceCompensationProfile() {
        const form = new GenericFormGroup(new FinancialElementModel());
        this.compensationForm!.controls.financial_elements!.controls.push(form);
    }

    doMovePerformanceCompensationProfileUp(index: number) {
        const element = this.compensationForm!.controls.financial_elements!.controls.splice(index, 1).pop();
        this.compensationForm!.controls.financial_elements!.controls.splice(index - 1, 0, element!);
        this.updateOrder();
    }

    doMovePerformanceCompensationProfileDown(index: number) {
        const element = this.compensationForm!.controls.financial_elements!.controls.splice(index, 1).pop();
        this.compensationForm!.controls.financial_elements!.controls.splice(index + 1, 0, element!);
        this.updateOrder();
    }

    doDeletePerformanceCompensationProfile(index: number) {
        this.compensationForm!.controls.financial_elements!.controls.splice(index, 1);
    }

    object_keys(obj: Object) {
        return Object.keys(obj);
    }

    equals(x: any, y: any) {
        if (x === y) {
            return true;
        }
        // if both x and y are null or undefined and exactly the same

        if (!(x instanceof Object) || !(y instanceof Object)) {
            return false;
        }
        // if they are not strictly equal, they both need to be Objects

        // if ( x.constructor !== y.constructor ){
        //     console.info('constructor');
        //     return false;
        // }
        // // they must have the exact same prototype chain, the closest we can do is
        // // test there constructor.

        for (const p in x) {
            if (!x.hasOwnProperty(p)) {
                continue;
            }
            // other properties were tested using x.constructor === y.constructor

            if (!y.hasOwnProperty(p)) {
                return false;
            }
            // allows to compare x[ p ] and y[ p ] when set to undefined

            if (x[p] === y[p]) {
                continue;
            }
            // if they have the same strict value or identity then they are equal

            if (typeof x[p] !== 'object') {
                return false;
            }
            // Numbers, Strings, Functions, Booleans must be strictly equal

            if (!this.equals(x[p], y[p])) {
                return false;
            }
            // Objects and Arrays must be tested recursively
        }

        for (const p in y) {
            if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
                return false;
            }
            // allows x[ p ] to be set to undefined
        }
        return true;
    }

    updateOrder() {
        this.compensationForm?.controls.financial_elements?.controls.forEach((element, i, array) => {
            element.controls.sort_order?.patchValue(array.length - i);
        });
    }

    isDirty() {
        const isDirty = this.compensationForm!.controls.financial_elements!.controls.some((el) => el.pristine !== true);

        const hasDifferentElements =
            this.compensationForm!.controls.financial_elements!.length !==
            this.financialElementsBySalesEntity![this.selectedDisbursement!].filter((a) => !!a.element.id).map(
                (a) => a.element
            ).length;

        return isDirty || hasDifferentElements;
    }

    doEditMode() {
        this.editMode = true;
        this.compensationForm?.controls?.financial_elements?.controls.forEach((fe) => {
            fe.enable({emitEvent: false});
            fe.controls.type!.disable({emitEvent: false});
            fe?.controls?.rules?.controls.forEach((r) => {
                r.controls.operator?.disable({emitEvent: false});
                r?.controls?.and_conditions?.controls.forEach((c) => c.controls.operator?.disable({emitEvent: false}));
            });
            fe?.controls?.rules?.controls.forEach((r) =>
                r.controls.disbursement_template?.controls.additional_incomes?.controls.forEach((ai) =>
                    ai.controls.value_type?.disable({emitEvent: false})
                )
            );
            fe?.controls?.rules?.controls.forEach((r) =>
                r.controls.disbursement_template?.controls.additional_expenses?.controls.forEach((ae) =>
                    ae.controls.value_type?.disable({emitEvent: false})
                )
            );
        });
    }

    doResetToDefaults() {
        const confirmDialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            minHeight: 160,
            data: {
                title: `Restore the deal to Initial State`,
                buttonOkMessage: `Restore`,
                messages: [
                    `All manually added financial transfers and modified not enforced financial transfers will be removed,`,
                    `compensation templates and profiles will be applied to this deal`,
                    `Are you sure you want to restore this deal to initial state?`
                ]
            }
        });

        confirmDialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((confirmed: boolean) => {
                if (confirmed) {
                    this.dealProcessingService.doReset({});
                    return;
                }
            });
    }

    checkValidity(ev: Event) {
        ev.stopPropagation();
        if (!this.isDirty()) {
            return;
        }

        const confirmDialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            minHeight: 160,
            data: {
                title: `Confirm changing sales entity`,
                message: `Please, confirm resetting unsaved changes`
            }
        });

        confirmDialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((confirmed: boolean) => {
                if (!confirmed) {
                    // CLOSE SELECTION
                    this.disbursementSelect.close();
                    return;
                }
            });
    }

    ngOnInit() {
        this.financialElementsBySalesEntityFC.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((val) => {
            this.financialElementsBySalesEntity = val;
            Object.keys(this.financialElementsBySalesEntity!).forEach((key: string) => {
                const found = this.salesEntitiesCompensationProfiles.find(
                    (item) => item.salesEntity.id === Number(key)
                );
                if (found) {
                    this.setFinancialElements(
                        this.financialElementsBySalesEntity![key].map((item: any) => item.element).filter(
                            (element: FinancialElementModel) => !!element.id
                        )
                    );
                }
            });
        });
        // set primary selected disbursement
        const primarySE = this.salesEntities
            // .find(el => el.compensation_plan.compensation_type === this.COMPENSATION_PLAN.compensation_type_SET.deal);
            .find((el) => el.is_primary === true);

        if (primarySE) {
            this.selectedDisbursement = primarySE.id;

            // run edit mode
            if (primarySE.overriden__compensation_profile_fk_id) {
                this.editMode = true;
            } else {
                this.editMode = false;
            }
        }

        this.updateOrder();

        this.compensationForm?.controls.financial_elements?.controls.forEach((feFg) => {
            if (!feFg.controls.is_enforced!.value) {
                feFg.controls.commonRule?.controls.is_prorated!.patchValue(false, {emitEvent: true});
                feFg.controls.commonRule?.controls.is_prorated!.disable();

                feFg.controls.rules?.controls.forEach((rule) => {
                    rule.controls.disbursement_template!.controls.additional_incomes?.controls.forEach((ftFg) => {
                        ftFg.controls.is_enforced!.patchValue(false, {emitEvent: false});
                    });
                    rule.controls.disbursement_template!.controls.additional_expenses?.controls.forEach((ftFg) => {
                        ftFg.controls.is_enforced!.patchValue(false, {emitEvent: false});
                    });
                });
            }
        });
    }

    constructor(
        protected compensationService: CompensationService,
        protected dialog: MatDialog,
        protected notificationService: NotificationsServiceZipi,
        public dealProcessingService: DealProcessingService
    ) {}

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
