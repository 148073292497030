import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {IPaymentGateway} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-muhnee-onboarding-dialog',
    templateUrl: './muhnee-onboarding-dialog.component.html',
    styleUrls: ['./muhnee-onboarding-dialog.component.scss']
})
export class MuhneeOnboardingDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    currentGateway: IPaymentGateway | undefined;
    operatingGateways: IPaymentGateway[] = [];
    currentGatewayInfo: ({[key: string]: any} & {representatives: Array<{[key: string]: any}>}) | undefined;

    constructor(
        public dialogRef: MatDialogRef<MuhneeOnboardingDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            gateway: IPaymentGateway;
            operatingGateways: IPaymentGateway[];
            gatewayInfo: {[key: string]: any} & {representatives: Array<{[key: string]: any}>};
        }
    ) {}

    ngOnInit() {
        this.currentGateway = this.data.gateway;
        this.operatingGateways = this.data.operatingGateways;
        this.currentGatewayInfo = this.data.gatewayInfo;
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
