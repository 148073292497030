import {Component, Input, EventEmitter, Output, OnDestroy, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {DealService} from '../../../../../../../services/deal.service';
import {
    DealsBoard,
    DealsBoardBackendFilterSettings,
    DealsBoardFrontendFilterSettings,
    ORDER_BY_STATUS
} from '../../../common/deal.models';
import {NotificationsServiceZipi} from '../../../../../../notifications/notifications.service';
import {SessionService} from '../../../../../../../services/session.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Deal} from '../../../../../../../models/deal';

@Component({
    selector: 'app-deal-board-edit',
    styles: [
        `
            .d-grid-outer {
                display: grid;
                grid-template-columns: 20% calc(20% - 1.3rem) calc(20% + 1.3rem) 20% 20%;
            }
        `
    ],
    template: `
        <div class="p-3" *ngIf="isCustomizeMode && dealsBoard">
            <div class="d-grid-outer">
                <mat-form-field style="grid-column-start: 2;">
                    <input matInput [(ngModel)]="dealsBoard.name" placeholder="Tab Title" />
                </mat-form-field>

                <app-deal-board-settings-v2
                    style="grid-row-start:2; grid-column-start: 2; grid-column-end: 5;"
                    *ngIf="sessionService.profile?.type === 'default'"
                    [filters]="filters"
                    [settings]="settings"
                ></app-deal-board-settings-v2>
            </div>

            <app-deal-board-settings-gv
                *ngIf="sessionService.profile?.type === 'global'"
                [filters]="filters"
                [settings]="settings"
                (needSave)="save()"
            ></app-deal-board-settings-gv>

            <div class="d-flex justify-content-between">
                <button *ngIf="dealsBoard?.id" mat-raised-button class="mat-warn" (click)="removeDealBoard()">
                    Remove dashboard
                </button>
                <div>
                    <button mat-raised-button (click)="view.emit()">Cancel</button>
                    <button mat-raised-button class="ml-3 mat-primary" (click)="save(); view.emit()">Save</button>
                </div>
            </div>
        </div>
    `
})
export class DealBoardEditComponent implements OnDestroy, OnInit, OnChanges {
    private unsubscribe: Subject<void> = new Subject();

    @Input() dealsBoard: DealsBoard | undefined = undefined;
    @Input() isCustomizeMode: boolean = false;

    @Output() dealsBoardUpdated = new EventEmitter();
    @Output() dealsBoardRemoved = new EventEmitter();
    @Output() view = new EventEmitter();

    filters: DealsBoardFrontendFilterSettings = {
        statuses: {},
        types: {},
        disbursement_statuses: {},
        property_classes: {},
        search: '',
        tags: null,
        divisions: null
    };
    settings: DealsBoardBackendFilterSettings = {
        source_of_business: [],
        close_of_escrow_after: null,
        close_of_escrow_before: null,
        is_archived: '',
        is_approved: '',
        filter_by_deal_members: [],
        by_company: {},
        show_net_by: 'user',
        show_only_owner_deals: false,
        deals_view_mode: 'company',
        order_by: ORDER_BY_STATUS,
        deal_stage: ['draft', 'open', 'approved', ''],
        closing_date_type: Deal.closing_date_LABELS.all_dated
    };

    constructor(
        protected dealsService: DealService,
        protected notificationsService: NotificationsServiceZipi,
        public sessionService: SessionService
    ) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dealsBoard && changes.dealsBoard.currentValue) {
            // set initial settings
            // JSON.parse & JSON.stringify needed for creating independent filters object
            this.dealsBoard = changes.dealsBoard.currentValue;
            if (this.dealsBoard) {
                this.filters = JSON.parse(JSON.stringify(this.dealsBoard.settings_frontend));
                this.settings = JSON.parse(JSON.stringify(this.dealsBoard.settings_backend));
            }
        }
    }

    async save() {
        // reload deals list through event emitter, in list component
        if (this.dealsBoard && this.dealsBoard.id) {
            await this.dealsService.updateDealsBoard(this.dealsBoard.id, {
                settings_frontend: this.filters,
                settings_backend: this.settings,
                name: this.dealsBoard.name
            });
        }

        // send event that deal is updated
        this.dealsBoardUpdated.emit(
            Object.assign(this.dealsBoard || {}, {
                settings_frontend: this.filters,
                settings_backend: this.settings
            })
        );

        // JSON.parse & JSON.stringify needed for creating independent filters object
        if (this.dealsBoard) {
            this.filters = JSON.parse(JSON.stringify(this.dealsBoard.settings_frontend));
            this.settings = JSON.parse(JSON.stringify(this.dealsBoard.settings_backend));
        }
    }

    removeDealBoard() {
        this.notificationsService
            .addConfirm('Are you sure you want to delete this Deal`s board?')!
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((confirmed) => {
                if (!confirmed || !this.dealsBoard || !this.dealsBoard.id) {
                    return false;
                }

                return this.dealsService.deleteDealsBoard(this.dealsBoard.id).then(() => {
                    this.dealsBoardRemoved.emit(this.dealsBoard);
                });
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.dealsBoardUpdated.complete();
        this.dealsBoardRemoved.complete();
        this.view.complete();
    }
}
