import {Injectable} from '@angular/core';
import {ICompanyCalculationSettings} from '../models/company';
import {ServiceRequester} from './service.requester';
import {SessionService} from './session.service';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CalculationSettingsService {
    private url = '/company/';
    public calculationSettings: ICompanyCalculationSettings = {capable_metrics_by_product_ids: []};
    public calculationSettings$ = new Subject<ICompanyCalculationSettings>();

    constructor(
        private requester: ServiceRequester,
        private sessionService: SessionService
    ) {
        this.calculationSettings$.next({capable_metrics_by_product_ids: []});
    }

    getCalculationSettingsFromApi(companyId: number): Promise<ICompanyCalculationSettings> {
        return this.requester.makeMsCall(this.url + companyId + '/calculation-settings', 'GET');
    }

    getCalculationSettings(needRefresh: boolean = false): Promise<ICompanyCalculationSettings> {
        if (!needRefresh) {
            this.calculationSettings$.next(this.calculationSettings);
            return Promise.resolve(this.calculationSettings);
        }
        return this.getCalculationSettingsFromApi(this.sessionService.profile!.company_fk_id!).then((newSettings) => {
            this.calculationSettings = newSettings;
            this.calculationSettings$.next(newSettings);
            return this.calculationSettings;
        });
    }

    updateCalculationSettings(
        companyId: number,
        calculationSettings: ICompanyCalculationSettings
    ): Promise<{success: boolean; calculation_settings?: ICompanyCalculationSettings; products_in_use?: number[]}> {
        return this.requester.makeMsCall(this.url + companyId + '/calculation-settings', 'PUT', {
            calculation_settings: calculationSettings
        });
    }
}
