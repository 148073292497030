import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import {Widget} from 'app/models/widget';
import {Subject} from 'rxjs';
import {Board} from '../../../../../../models/board';

@Component({
    selector: 'app-decide-widget',
    templateUrl: `decide-widget.component.html`,
    styleUrls: ['../widgets.scss']
})
export class DecideWidgetComponent implements OnInit, OnDestroy {
    @Input() inputFilter: {} = {};
    // eslint-disable-next-line
    @Input('getDataSubj') filterSubj: Subject<{}> | null = null;
    @Input() widget: Widget | null = null;
    @Input() board: Board | null = null;
    @Input() widgetsList: Widget[] = [];
    @Input() customizeVisibility: boolean = false;
    @Output() changeWidgetList = new EventEmitter();
    @Output() onCrudAction = new EventEmitter();

    ngOnInit() {}

    onChangeWidgetList(event: EventEmitter<any>) {
        this.changeWidgetList.emit(this.widgetsList);
    }

    ngOnDestroy(): void {
        this.changeWidgetList.complete();
        this.onCrudAction.complete();
        this.filterSubj!.complete();
    }
}
