import {Directive, ElementRef, Input, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import {RbacService} from './rbac.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Directive({
    selector: '[rbac]'
})
export class RbacDirective implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    // TODO for "anyRule" set Array<{[key: string]: any}> instead Array<{[key: string]: boolean}> but it should be refactored
    @Input() rule: {[key: string]: boolean} | undefined;
    @Input() groupRule: {[key: string]: boolean} | undefined;
    @Input() anyGroupRule: Array<{[key: string]: boolean}> | undefined;
    @Input() anyRule: Array<{[key: string]: any}> | undefined;
    @Input() groupId: number | undefined;
    @Input() denyMethod: 'disable' | 'display-none' | 'visibility-hidden' | 'style' = 'visibility-hidden';

    // must be both set to valid `nativeElement.style` to be used
    @Input() denyStyle: {[key: string]: string} | null = null;
    @Input() allowStyle: {[key: string]: string} | null = null;
    @Output() rbacIsAllowed = new EventEmitter();

    constructor(
        private el: ElementRef,
        protected rbacService: RbacService
    ) {
        this.rbacService.onPermissionsUpdate.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.checkIfAllowed().then((allowed) => {
                if (allowed) {
                    this.allowElement();
                } else {
                    this.disallowElement();
                }
            });
        });
    }

    async checkIfAllowed() {
        let allowCompany = true;
        // let allowGroup = true;
        if (this.rule) {
            allowCompany = await this.rbacService.isAllowed(this.rule);
        } else if (this.anyRule) {
            allowCompany = await this.rbacService.isAllowedAny(this.anyRule);
        }
        // if (this.groupRule) {
        //     allowGroup = await this.rbacService.isAllowedGroup(this.groupRule, this.groupId);
        // } else if (this.anyGroupRule) {
        //     allowGroup = await this.rbacService.isAllowedGroupAny(this.anyGroupRule, this.groupId);
        // }
        return allowCompany;
    }

    ngOnInit() {
        if (this.rule && this.anyRule) {
            throw new Error(`Rbac: must be specified only rule OR anyRule for element ${this.el.nativeElement}`);
        }

        // if (this.groupRule && this.anyGroupRule) {
        //     throw new Error(`Rbac: must be specified only groupRule OR anyGroupRule for element ${this.el.nativeElement}`);
        // }
        //
        // if ((this.groupRule || this.anyGroupRule) && !this.groupId) {
        //     throw new Error(`Rbac: groupId must be specified for groupRule OR anyGroupRule for element ${this.el.nativeElement}`);
        // }

        if (!this.rule && !this.anyRule && !this.groupRule && !this.anyGroupRule) {
            // if (!this.rule && !this.anyRule) {
            throw new Error(`Rbac rules not specified for element ${this.el.nativeElement}`);
        }

        if (this.denyStyle !== null && this.allowStyle !== null) {
            if (!(this.denyStyle instanceof Object && this.allowStyle instanceof Object)) {
                throw new Error('denyStyle and allowStylemust be both set to valid `nativeElement.style` to be used');
            }
            this.denyMethod = 'style';
        }

        if (this.denyMethod === 'style' && (this.denyStyle === null || this.allowStyle === null)) {
            throw new Error('denyStyle and allowStyle must not be null to use denyMethod="style"');
        }
        this.checkIfAllowed().then((allowed) => {
            if (allowed) {
                this.allowElement();
            } else {
                this.disallowElement();
            }
        });
    }

    disallowElement() {
        switch (this.denyMethod) {
            case 'disable':

            case 'display-none':
                this.el.nativeElement.style.display = 'none';
                break;

            case 'style':
                if (!this.denyStyle) {
                    break;
                }
                Object.keys(this.denyStyle).forEach(
                    (key) => (this.el.nativeElement.style[key] = (this.denyStyle as any)[key])
                );
                break;

            case 'visibility-hidden':
            default:
                this.el.nativeElement.style.visibility = 'hidden';
                break;
        }
        this.rbacIsAllowed.emit(false);
    }

    allowElement() {
        switch (this.denyMethod) {
            case 'disable':
                break;

            case 'style':
                if (!this.denyStyle) {
                    break;
                }
                Object.keys(this.denyStyle).forEach(
                    (key) => (this.el.nativeElement.style[key] = (this.allowStyle as any)[key])
                );
                break;

            case 'display-none':
                this.el.nativeElement.style.display = 'initial';
                break;

            case 'visibility-hidden':
            default:
                this.el.nativeElement.style.visibility = 'visible';
                break;
        }
        this.rbacIsAllowed.emit(true);
    }

    ngOnDestroy() {
        this.rbacIsAllowed.complete();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
