import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-not-found-404',
    styles: [
        `
            .base-card {
                margin-top: 10vh;
                width: 75vw;
                margin-left: 15vw;
                display: flex;
                flex-direction: row;
            }
            .error-content {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            .not-found-image {
                margin: 10%;
            }
            .message {
                font-size: 1.5em;
                padding: 5%;
            }
        `
    ],
    template: `
        <mat-card class="base-card">
            <mat-card-content class="error-content">
                <img [src]="'assets/ico/404.svg'" class="not-found-image" alt="404" />
                <div class="message">
                    Either this page doesn’t exist or you've tried accessing the wrong link. Make sure to check this URL
                    for typos or head to your inbox and click the link in the email we provided.
                </div>
            </mat-card-content>
        </mat-card>
    `
})
export class NotFound404Component {
    constructor(protected router: Router) {}
}
