import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NotificationsServiceZipi} from 'app/modules/notifications/notifications.service';

@Component({
    selector: 'app-qb-disconnect-dialog',
    template: `
        <div mat-dialog-title>
            <h4>Disconnecting QuickBooks Online Integration</h4>
        </div>

        <div>
            Are you sure you want to disconnect Quickbooks Online? Data will no longer be synced from SkySlope Books to
            Quickbooks Online but data that was already synced in the past will not be changed.
        </div>

        <div class="d-flex justify-content-end pt-3">
            <button class="mat-primary mat-raised-button mat-button-base" (click)="dialogRef.close(false)">
                Stay Connected
            </button>
            <button class="mat-warn mat-raised-button mat-button-base ml-2" (click)="disconnect()">Disconnect</button>
        </div>
    `
})
export class QbDisconnectDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<QbDisconnectDialogComponent>,
        protected notificationsService: NotificationsServiceZipi,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {}

    disconnect() {
        this.dialogRef.close(true);
    }
}
