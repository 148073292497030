import {Action} from '@ngrx/store';
import {IContact, IContactInvite, IContactInviteParams} from '@cyberco-nodejs/zipi-typings';

export enum contactsActionTypes {
    FETCH_CONTACTS = '[CONTACTS] FETCH_CONTACTS',
    FETCH_CONTACTS_MUST = '[CONTACTS] FETCH_CONTACTS_MUST',
    FETCH_CONTACTS_SUCCESS = '[CONTACTS] FETCH_CONTACTS_SUCCESS',
    ADD_CONTACT = '[CONTACTS] ADD_CONTACT',
    ADD_CONTACT_IN_DIALOG = '[CONTACTS] ADD_CONTACT_IN_DIALOG',
    ADD_CONTACT_SUCCESS = '[CONTACTS] ADD_CONTACT_SUCCESS',
    UPD_CONTACT = '[CONTACTS] UPD_CONTACT',
    UPD_CONTACT_SUCCESS = '[CONTACTS] UPD_CONTACT_SUCCESS',
    DELETE_CONTACT = '[CONTACTS] DELETE_CONTACT',
    DELETE_CONTACT_SUCCESS = '[CONTACTS] DELETE_CONTACT_SUCCESS',
    CLEAN_CONTACTS = '[CONTACTS] CLEAN_CONTACTS',

    SELECT_CONTACT = '[CONTACTS] SELECT_CONTACT',

    FETCH_API_CALLABLE_CONFIGURATIONS_FIELDS = '[CONTACTS FETCH_API_CALLABLE_CONFIGURATIONS_FIELDS',
    FETCH_API_CALLABLE_CONFIGURATIONS_FIELDS_RESULT = '[CONTACTS FETCH_API_CALLABLE_CONFIGURATIONS_FIELDS_RESULT',

    CREATE_CONTACT_INVITE = '[CONTACTS] CREATE_CONTACT_INVITE',
    CREATE_CONTACT_INVITE_RESULT = '[CONTACTS] CREATE_CONTACT_INVITE_RESULT',
    CANCEL_CONTACT_INVITE = '[CONTACTS] CANCEL_CONTACT_INVITE',
    CANCEL_CONTACT_INVITE_RESULT = '[CONTACTS] CANCEL_CONTACT_INVITE_RESULT'
}

export class FetchContacts implements Action {
    readonly type: string = contactsActionTypes.FETCH_CONTACTS;

    constructor(public payload?: any) {}
}

export class FetchContactsMust implements Action {
    readonly type: string = contactsActionTypes.FETCH_CONTACTS_MUST;
    constructor(public payload?: any) {}
}

export class FetchContactsSuccess implements Action {
    readonly type: string = contactsActionTypes.FETCH_CONTACTS_SUCCESS;

    constructor(public payload?: IContact[] | null) {}
}

export class AddContact implements Action {
    readonly type: string = contactsActionTypes.ADD_CONTACT;

    constructor(public payload?: IContact) {}
}

export class AddContactInDialog implements Action {
    readonly type: string = contactsActionTypes.ADD_CONTACT_IN_DIALOG;

    constructor(public payload?: IContact) {}
}

export class AddContactSuccess implements Action {
    readonly type: string = contactsActionTypes.ADD_CONTACT_SUCCESS;

    constructor(public payload?: IContact) {}
}

export class UpdContact implements Action {
    readonly type: string = contactsActionTypes.UPD_CONTACT;

    constructor(public payload?: {data: IContact; silent?: boolean}) {}
}

export class UpdContactSuccess implements Action {
    readonly type: string = contactsActionTypes.UPD_CONTACT_SUCCESS;

    constructor(public payload?: IContact) {}
}

export class DeleteContact implements Action {
    readonly type: string = contactsActionTypes.DELETE_CONTACT;

    constructor(public payload?: number) {}
}

export class DeleteContactSuccess implements Action {
    readonly type: string = contactsActionTypes.DELETE_CONTACT_SUCCESS;

    constructor(public payload?: boolean) {}
}

export class SelectContact implements Action {
    readonly type: string = contactsActionTypes.SELECT_CONTACT;

    constructor(public payload?: IContact) {}
}

export class FetchApiCallableConfigurationsFields implements Action {
    readonly type: string = contactsActionTypes.FETCH_API_CALLABLE_CONFIGURATIONS_FIELDS;
    constructor(public payload?: any) {}
}

export class CreateContactInvite implements Action {
    readonly type: string = contactsActionTypes.CREATE_CONTACT_INVITE;

    constructor(public payload: {contactId: number; data: IContactInviteParams}) {}
}

export class CreateContactInviteResult implements Action {
    readonly type = contactsActionTypes.CREATE_CONTACT_INVITE_RESULT;

    constructor(public payload: IContactInvite | null) {}
}

export class CancelContactInvite implements Action {
    readonly type: string = contactsActionTypes.CANCEL_CONTACT_INVITE;

    constructor(public payload: IContactInvite) {}
}

export class CancelContactInviteResult implements Action {
    readonly type = contactsActionTypes.CANCEL_CONTACT_INVITE_RESULT;

    constructor(public payload: boolean) {}
}

export class CleanContacts implements Action {
    readonly type: string = contactsActionTypes.CLEAN_CONTACTS;

    constructor(public payload: void) {}
}

export type ContactsAction =
    | FetchContacts
    | FetchContactsMust
    | FetchContactsSuccess
    | AddContact
    | AddContactInDialog
    | AddContactSuccess
    | UpdContact
    | UpdContactSuccess
    | DeleteContact
    | DeleteContactSuccess
    | SelectContact
    | FetchApiCallableConfigurationsFields
    | CreateContactInvite
    | CancelContactInvite
    | CreateContactInviteResult
    | CancelContactInviteResult
    | CleanContacts;
