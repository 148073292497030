<div class="deal-row" *ngIf="deal">
    <div class="flex-row expanded-deal-card">
        <div
            class="checkbox-area"
            #checkbox
            rbac
            [anyRule]="[{deals__delete_own: true}, {deals__delete_any: true}]"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
        >
            <mat-checkbox
                (change)="toggleDealCheckboxEmitter.emit({control: $event, deal: deal})"
                *ngIf="deal.id"
                [value]="deal.id + ''"
                [checked]="checkIfDealChecked()"
            ></mat-checkbox>
        </div>

        <div class="info-area" (click)="handleClickOnDeal(deal)">
            <div class="d-flex flex-wrap align-items-center deal-name-container">
                <div class="value font-weight-bold deal-name">
                    {{ deal.name }}
                </div>
                <span class="material-icons edit-deal-icon ml-2" (click)="selectedDealEmitter.emit(deal)">
                    visibility
                </span>
            </div>
            <div class="info-container">
                <div class="deal-photo">
                    <a
                        *ngIf="deal.external_deal_url"
                        target="_blank"
                        (click)="$event.stopPropagation()"
                        href="{{ deal.external_deal_url }}"
                    >
                        <i class="material-icons">filter_none</i>
                        <img *ngIf="deal.image_url" [src]="deal.image_url" />
                        <ng-container
                            *ngIf="
                                !deal.image_url &&
                                deal.deal_template_id &&
                                dealTemplates &&
                                dealTemplates[deal.deal_template_id]
                            "
                        >
                            <img
                                *ngIf="!deal.image_url && dealTemplates[deal.deal_template_id]?.preset?.image_url"
                                [src]="dealTemplates[deal.deal_template_id]?.preset?.image_url || ''"
                            />
                            <img
                                *ngIf="!deal.image_url && !dealTemplates[deal.deal_template_id]?.preset?.image_url"
                                [src]="'assets/images/default-deal-image.png'"
                            />
                        </ng-container>
                        <img
                            *ngIf="
                                !deal.image_url &&
                                !(dealTemplates && deal.deal_template_id && dealTemplates[deal.deal_template_id])
                            "
                            [src]="'assets/images/default-deal-image.png'"
                        />
                    </a>
                    <ng-container *ngIf="!deal.external_deal_url">
                        <img *ngIf="deal.image_url" [src]="deal.image_url" />
                        <ng-container *ngIf="!deal.image_url && deal.deal_template_id && dealTemplates">
                            <img
                                *ngIf="!deal.image_url && dealTemplates[deal.deal_template_id]?.preset?.image_url"
                                [src]="dealTemplates[deal.deal_template_id]?.preset?.image_url || ''"
                            />
                            <img
                                *ngIf="!deal.image_url && !dealTemplates[deal.deal_template_id]?.preset?.image_url"
                                [src]="'assets/images/default-deal-image.png'"
                            />
                        </ng-container>
                        <img
                            *ngIf="
                                !deal.image_url &&
                                !(dealTemplates && deal.deal_template_id && dealTemplates[deal.deal_template_id])
                            "
                            [src]="'assets/images/default-deal-image.png'"
                        />
                    </ng-container>
                </div>

                <div class="deal-info">
                    <div class="general-info">
                        <div class="d-flex address">
                            <div class="label">Address:</div>
                            <div class="value" [ngClass]="{'no-address': !deal.address}">
                                <span *ngIf="deal.address">
                                    <span *ngIf="deal.street_number">{{ deal.street_number }}&nbsp;</span>
                                    <span>{{ deal.address }}&nbsp;</span>
                                    <span *ngIf="deal.unit">{{ deal.unit }},&nbsp;</span>
                                    <span *ngIf="deal.city">{{ deal.city }},&nbsp;</span>
                                    <span *ngIf="deal.state">{{ deal.state }}&nbsp;</span>
                                    <span *ngIf="deal.zip">{{ deal.zip }}</span>
                                </span>
                                <span *ngIf="!deal.address">Property address</span>
                            </div>
                        </div>
                        <div class="d-flex" *ngIf="deal.type && deal.close_of_escrow">
                            <div class="label">Type / Close Date:</div>
                            <div class="value">
                                {{ DEAL.type_LABELS[deal.type] }}
                                <span *ngIf="deal.type && deal.close_of_escrow">/</span>
                                <span [ngClass]="isDateInPast(deal.close_of_escrow) ? 'coe-overdue' : ''">
                                    {{ deal.close_of_escrow | date: 'mediumDate' }}
                                </span>
                            </div>
                        </div>
                        <!--<div class="d-flex">
                            <div class="label">Client:</div>
                            &lt;!&ndash;<div class="value" *ngIf="deal.type === DEAL.type_SET.buyer">&ndash;&gt;
                            &lt;!&ndash;render clients in deal card based on client_type field. If client_type=NULL, leave it blank&ndash;&gt;
                            <div class="value" *ngIf="deal.client_type === DEAL.deal_client_type_SET.buyer">
                                <span *ngFor="let client of deal.deal_participants.buyers; last as isLast"
                                      [ngClass]="client.hidden ? 'hidden-contact' : ''">
                                    {{client.link_title}}<span *ngIf="!isLast">, </span>
                                </span>
                            </div>
                            &lt;!&ndash;<div class="value" *ngIf="deal.type === DEAL.type_SET.seller">&ndash;&gt;
                            <div class="value" *ngIf="deal.client_type === DEAL.deal_client_type_SET.seller">
                                <span *ngFor="let client of deal.deal_participants.sellers; last as isLast"
                                      [ngClass]="client.hidden ? 'hidden-contact' : ''">
                                    {{client.link_title}}<span *ngIf="!isLast">, </span>
                                </span>
                            </div>
                        </div>-->
                        <div class="d-flex">
                            <div class="label">Agent:</div>
                            <div class="value">
                                <ng-container *ngIf="deal.primary_sales_entity.type === SALES_ENTITY.type_SET.profile">
                                    <ng-container *ngIf="!deal.primary_sales_entity.profile?.deleted_at">
                                        <span
                                            >{{ deal.primary_sales_entity.profile?.first_name }}
                                            {{ deal.primary_sales_entity.profile?.last_name }}</span
                                        >
                                    </ng-container>
                                    <ng-container *ngIf="deal.primary_sales_entity.profile?.deleted_at">
                                        <span
                                            >{{ deal.primary_sales_entity.profile?.first_name }}
                                            {{ deal.primary_sales_entity.profile?.last_name }}</span
                                        >
                                        (deleted)
                                    </ng-container>
                                </ng-container>
                                <ng-container
                                    *ngIf="deal.primary_sales_entity.type === SALES_ENTITY.type_SET.company_group"
                                >
                                    <ng-container *ngIf="!deal.primary_sales_entity.company_group?.deleted_at">
                                        <span>{{ deal.primary_sales_entity.company_group?.title }}</span>
                                    </ng-container>
                                    <ng-container *ngIf="deal.primary_sales_entity.company_group?.deleted_at">
                                        <span>{{ deal.primary_sales_entity.company_group?.title }}</span>
                                        (deleted)
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="label">Sale Price:</div>
                            <div class="value">
                                {{ deal.sales_price | currency: 'USD' : 'symbol' }}
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="label">Commission:</div>
                            <div class="value">
                                {{ deal.company_calculation.commission.amount | currency: 'USD' : 'symbol' }}
                            </div>
                        </div>
                    </div>
                    <div class="disbursement-status">
                        <span *ngIf="deal.is_historical_imported">Imported as Historical</span>

                        <span *ngIf="deal.disbursement_status" style="display: flex; align-items: center">
                            <span style="margin-left: 2px; padding-right: 8px">{{
                                getDisbursementStatus(deal.disbursement_status)
                            }}</span>

                            <mat-icon [matTooltip]="getDisbursementStatusToolTip(deal.disbursement_status)">
                                info_outline
                            </mat-icon>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="status-area d-flex flex-column align-items-end">
            <div class="d-flex align-items-center">
                <!------- Deal dual link item ------->
                <mat-icon
                    *ngIf="deal.dual_deal_id"
                    class="mr-3 dual-agency icon-trigger"
                    matTooltip="Dual Agency"
                    matTooltipPosition="before"
                    [matMenuTriggerFor]="dualMenu"
                >
                    link
                </mat-icon>
                <mat-menu #dualMenu="matMenu" xPosition="before">
                    <button mat-menu-item (click)="router.navigate(['/deals', deal.dual_deal_id])">
                        Open Dual Deal
                    </button>
                </mat-menu>

                <!------- Deal status ------->
                <div
                    class="status-box"
                    rbac
                    [rule]="{deals__edit_any: false, deals__edit_own: false}"
                    [allowStyle]="{display: 'flex'}"
                    [denyStyle]="{display: 'none'}"
                    [ngClass]="deal.status"
                >
                    <mat-icon class="status-icon" [ngClass]="deal.status">label</mat-icon>
                    {{ DEAL.status_LABELS[deal.status] }}
                </div>
                <!------- Change deal status ------->
                <button
                    mat-button
                    class="status-box"
                    rbac
                    [anyRule]="[{deals__edit_any: true}, {deals__edit_own: true}]"
                    [allowStyle]="{display: 'flex'}"
                    [denyStyle]="{display: 'none'}"
                    [ngClass]="deal.status"
                    [matMenuTriggerFor]="menu"
                >
                    <mat-icon class="status-icon" [ngClass]="deal.status">label</mat-icon>
                    {{ DEAL.status_LABELS[deal.status] }}
                </button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button
                        mat-menu-item
                        *ngFor="let status of object_keys(DEAL.status_SET)"
                        (click)="updateDealStatus(deal, status)"
                    >
                        <mat-icon class="status-icon" [ngClass]="status">label</mat-icon>
                        {{ DEAL.status_LABELS[status] }}
                    </button>
                </mat-menu>
            </div>

            <app-ui-mod-deal-badges-in-list
                *ngIf="deal.ui_mods && deal.ui_mods.length"
                [renderMode]="'vertical'"
                [dealId]="deal.id"
                [initialUiMods]="deal.ui_mods"
            ></app-ui-mod-deal-badges-in-list>
        </div>
    </div>
</div>
