import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Group} from '../../../../models/group';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {AuthService} from '../../../../services/auth.service';
import {AvailableProfilesSource} from '../../../../services/sources/available-profiles.source';
import {Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {simpleEmailValidator} from '../../../contacts/store/contact.utilities';

@Component({
    selector: 'org-chart-new',
    styles: [
        `
            mat-card {
                height: 100%;
            }
        `
    ],
    template: `
        <form *ngIf="isOpenGroupArea">
            <div>
                <h3>Create Division</h3>
                <hr />

                <mat-form-field class="flex-4" style="margin-right: 16px">
                    <input
                        matInput
                        type="text"
                        required
                        placeholder="Title"
                        name="name"
                        *ngIf="newGroupFG.controls.title"
                        [formControl]="newGroupFG.controls.title"
                    />
                </mat-form-field>

                <mat-form-field class="flex-4" style="margin-right: 16px">
                    <mat-select
                        placeholder="Subdivision Of"
                        name="type"
                        *ngIf="newGroupFG.controls.parent__group_fk_id"
                        [formControl]="newGroupFG.controls.parent__group_fk_id"
                        (selectionChange)="doSelectionChange()"
                    >
                        <mat-option [value]="0">None</mat-option>
                        <ng-container *ngFor="let company_group of groupList">
                            <mat-option [value]="company_group.id">{{ company_group.title }}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <div style="display: flex; flex-direction: column">
                    <mat-slide-toggle
                        style="max-width: fit-content"
                        *ngIf="
                            newGroupFG.controls.permissions &&
                            newGroupFG.controls.permissions.controls &&
                            newGroupFG.controls.permissions.controls.use_in_compensation_as_entity_group
                        "
                        [formControl]="newGroupFG.controls.permissions.controls.use_in_compensation_as_entity_group"
                    >
                        <div class="d-flex">
                            Allow this entity to Conduct Transactions
                            <mat-icon
                                style="margin-top:1px;width:20px;height:20px;font-size:20px;color: #9E9E9E;"
                                class="ml-2"
                                matTooltip="This will create a separate company to allow users whom have been granted permission
                                to login and track the group's finances as a separate legal entity."
                                >monetization_on
                            </mat-icon>
                        </div>
                    </mat-slide-toggle>
                </div>

                <div
                    *ngIf="newGroupFG.controls.permissions!.controls!.use_in_compensation_as_entity_group!.value"
                    style="display: flex; align-items: center"
                >
                    <mat-form-field class="flex-4">
                        <input
                            matInput
                            type="text"
                            [required]="isEmailRequired"
                            placeholder="Email"
                            name="email"
                            *ngIf="newGroupFG.controls.email"
                            [formControl]="newGroupFG.controls.email"
                        />
                        <mat-error>Invalid email address.</mat-error>
                    </mat-form-field>
                    <mat-icon
                        style="margin-top:1px;width:20px;height:20px;font-size:20px;color: #9E9E9E;"
                        class="ml-2"
                        matTooltip="A unique email address must be entered to use division in compensation profiles (ie Sales Entity)"
                        >email
                    </mat-icon>
                </div>

                <div class="d-flex flex-column">
                    <mat-slide-toggle
                        style="max-width: fit-content"
                        *ngIf="
                            newGroupFG.controls.permissions &&
                            newGroupFG.controls.permissions.controls &&
                            newGroupFG.controls.permissions.controls.use_in_compensation_as_group_of_entities
                        "
                        [formControl]="
                            newGroupFG.controls.permissions.controls.use_in_compensation_as_group_of_entities
                        "
                    >
                        <div style="display: flex;">
                            Enable 'User Group' for Division
                            <mat-icon
                                style="margin-top:1px;width:20px;height:20px;font-size:20px;color: #9E9E9E;"
                                class="ml-2"
                                matTooltip="Activate this if you would like to make the members of this group available in the Finance module."
                                >group
                            </mat-icon>
                        </div>
                    </mat-slide-toggle>
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <button mat-raised-button (click)="hide.emit()" style="margin-right: 8px">Cancel</button>
                <button mat-raised-button class="mat-primary" [disabled]="newGroupFG.invalid" (click)="groupCreated()">
                    Create
                </button>
            </div>
        </form>
    `
})
export class NewComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() groupList: Group[] = [];

    @Input() isOpenGroupArea: boolean = true;
    @Output() hide = new EventEmitter();
    @Output() createdGroup = new EventEmitter();

    public newGroupFG: GenericFormGroup<Group> = new GenericFormGroup(
        new Group().setType(Group.type_SET.division),
        'change'
    );
    public parentGroup: Group | null = null;
    public parentGroupId: number = 0;
    public isEmailRequired: boolean = false;

    @Input() public useInCompensation: boolean = false;
    @Input() public hideUseInCompensation: boolean = false;

    constructor(
        protected authService: AuthService,
        protected availableProfilesSource: AvailableProfilesSource
    ) {
        this.newGroupFG.controls.email?.setValidators([simpleEmailValidator]);

        this.newGroupFG
            .get('permissions')
            ?.get('use_in_compensation_as_entity_group')
            ?.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => {
                this.isEmailRequired = !!value;
                if (this.isEmailRequired) {
                    this.newGroupFG.get('email')?.setValidators([Validators.required, simpleEmailValidator]);
                } else {
                    this.newGroupFG.get('email')?.setValidators([simpleEmailValidator]);
                }
                this.newGroupFG.get('email')?.updateValueAndValidity({emitEvent: false});
            });
    }

    ngOnInit(): void {}

    groupCreated() {
        this.newGroupFG.controls.title?.setValue(this.newGroupFG.controls.title.value.trim());
        this.createdGroup.emit({
            newGroup: this.newGroupFG.getRawValue(),
            parent: this.parentGroup
        });
    }

    doSelectionChange() {
        const groups = this.groupList.filter((company_group) => company_group.id === this.parentGroupId).shift();
        if (groups) {
            this.parentGroup = groups;
            this.newGroupFG.controls.title?.patchValue(this.parentGroup.title);
        }
    }

    ngOnDestroy() {
        this.hide.complete();
        this.createdGroup.complete();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
