import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
    RECURRING_INVOICE_PERIOD,
    RECURRING_INVOICE_UNPAID_REMINDER_PERIOD,
    RECURRING_INVOICE_DRAFT_PERIOD,
    RECURRING_INVOICE_NOTIFICATION_PERIOD
} from 'app/local-typings';
import {
    IInvoice,
    IRecurringInvoice,
    IRecurringInvoiceDraftPeriod,
    IRecurringInvoicePeriod
} from '@cyberco-nodejs/zipi-typings';
import {Subject} from 'rxjs';
import {NotificationsService} from 'angular2-notifications';
import {Store} from '@ngrx/store';
import {MatDialog} from '@angular/material/dialog';
import {isEqual, assign} from 'lodash-es';

import {IFinanceState} from '../../../store/finance.reducer';
import {CreateRecurringInvoice, UpdateRecurringInvoice, DeleteRecurringInvoice} from '../../../store/finance.actions';
import {filter, takeUntil} from 'rxjs/operators';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import * as moment from 'moment';
import {RecurringInvoicesService} from 'app/services/api/finance/recurring-invoices.service';

export interface IMaybeValidInvoiceTemplate {
    valid: boolean;
    template: IInvoice | null;
    association: {
        to_create: Array<{
            transfer_id: number;
            product_id: number;
            amount: number;
            connected__deal_fk_id: number;
        }>;
        to_delete: Array<{
            transfer_id: number;
            product_id: number;
            amount: number;
            connected__deal_fk_id: number;
        }>;
    } | null;
}

@Component({
    selector: 'app-edit-recurring-invoice',
    templateUrl: 'edit-recurring-invoice.component.html',
    styleUrls: ['edit-recurring-invoice.component.css']
})
export class EditRecurringInvoiceComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    recurringInvoiceId: number | null = null;
    recurringInvoice: IRecurringInvoice | undefined;
    repeatPeriod = RECURRING_INVOICE_PERIOD;
    recurringInvoiceDraftPeriod = RECURRING_INVOICE_DRAFT_PERIOD;
    recurringInvoiceNotificationPeriod = RECURRING_INVOICE_NOTIFICATION_PERIOD;
    recurringInvoiceUnpaidReminderPeriod = RECURRING_INVOICE_UNPAID_REMINDER_PERIOD;

    private maybeValidInvoice: IMaybeValidInvoiceTemplate = {valid: false, template: null, association: null};

    formGroup = this.fb.group({
        title: ['', Validators.required],
        status: ['active', Validators.required],
        start_on: [null, Validators.required],
        repeat: [{frequency: 1, period: 'month', is_anniversary: false}],
        repeat_custom_days: [1],
        end_on: null,
        end_after: [null, []],
        create_invoice_for_current_period: [false, []],
        draft_period: [{frequency: 0, period: 'none'}, Validators.required],
        draft_period_custom: [],
        notification_period: [[{frequency: 0, period: 'issuance'}], Validators.required],
        unpaid_reminder_period: [{frequency: 0, period: 'none'}, Validators.required],
        auto_recharge_1: [],
        auto_recharge_2: [],
        auto_recharge_3: []
    });
    repeat_custom: IRecurringInvoicePeriod | null = null;
    draft_period_custom: IRecurringInvoiceDraftPeriod | null = null;

    endsGroup: UntypedFormGroup | undefined;

    today: number | null = null;
    isCreateInvoiceForCurrent: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService,
        private store: Store<IFinanceState>,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        protected activatedRoute: ActivatedRoute,
        private recurringInvoicesService: RecurringInvoicesService
    ) {}

    comparePeriod: (o1: any, o2: any) => boolean = isEqual;

    handleInvoiceTemplateUpdate(maybeValidInvoice: IMaybeValidInvoiceTemplate) {
        this.maybeValidInvoice = maybeValidInvoice;
    }

    private validateRecurringInvoice() {
        if (!this.maybeValidInvoice.valid || !this.maybeValidInvoice.template) {
            this.ntfs.warn('Invoice Template is not valid');
            return false;
        }
        if (!this.formGroup.valid) {
            this.formGroup.markAllAsTouched();
            this.ntfs.warn('Recurring invoice form is not valid');
            return false;
        }
        return true;
    }

    changeStatus(status: 'active' | 'stopped') {
        this.formGroup.controls.status.setValue(status);
    }

    createRecurringInvoice() {
        if (this.validateRecurringInvoice() === false) {
            return;
        }

        const newInvoice = assign({}, this.formGroup.getRawValue(), this.maybeValidInvoice.template);

        if (this.draft_period_custom) {
            newInvoice.draft_period = this.draft_period_custom;
        }

        if (this.repeat_custom) {
            newInvoice.repeat = this.repeat_custom;
        }

        this.store.dispatch(new CreateRecurringInvoice(newInvoice));
    }

    updateRecurringInvoice() {
        if (this.validateRecurringInvoice() === false) {
            return;
        }

        const updatedInvoice: IRecurringInvoice = Object.assign(
            {},
            this.recurringInvoice,
            this.formGroup.getRawValue(),
            this.maybeValidInvoice.template
        );

        if (this.draft_period_custom) {
            updatedInvoice.draft_period = this.draft_period_custom;
        }

        if (this.repeat_custom) {
            updatedInvoice.repeat = this.repeat_custom;
        }

        this.store.dispatch(new UpdateRecurringInvoice(updatedInvoice));
    }

    deleteRecurringInvoice() {
        if (!this.recurringInvoice) {
            return;
        }
        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            data: {
                title: 'Deleting Recurring Invoice',
                message: `Recurring Invoice "${this.recurringInvoice.title}" will be deleted`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {
                if (this.recurringInvoice && this.recurringInvoice.id) {
                    this.store.dispatch(new DeleteRecurringInvoice(this.recurringInvoice.id));
                }
            });
    }

    initEdit() {
        this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (!isNaN(params['id'])) {
                this.recurringInvoiceId = params['id'];

                if (this.recurringInvoiceId) {
                    this.recurringInvoicesService
                        .getRecurringInvoiceById(this.recurringInvoiceId)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((recurringInvoice) => {
                            this.recurringInvoice = recurringInvoice;
                            this.formGroup.patchValue(recurringInvoice);
                            if (recurringInvoice.draft_period && recurringInvoice.draft_period.period === 'day') {
                                this.formGroup.controls.draft_period.patchValue({frequency: 0, period: 'day'});
                                this.formGroup.controls.draft_period_custom.patchValue(
                                    recurringInvoice.draft_period.frequency
                                );
                            }
                            if (recurringInvoice.repeat.period === 'custom') {
                                this.formGroup.controls.repeat.patchValue({frequency: 1, period: 'custom'});
                                this.formGroup.controls.repeat_custom_days.patchValue(
                                    recurringInvoice.repeat.frequency
                                );
                            }
                            if (this.endsGroup) {
                                if (this.formGroup.controls.end_on.value) {
                                    this.endsGroup.controls.endsOptionControl.setValue('by');
                                } else if (this.formGroup.controls.end_after.value) {
                                    this.endsGroup.controls.endsOptionControl.setValue('after');
                                } else {
                                    this.endsGroup.controls.endsOptionControl.setValue('none');
                                }
                            }

                            this.checkDraftPeriod();
                        });
                }
            }
        });

        this.formGroup.controls.start_on.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            if (Number.isInteger(value) && this.today) {
                this.isCreateInvoiceForCurrent = value < this.today;
            }
            // check and enable create_invoice_for_current_period field
            this.checkDraftPeriod();
        });

        this.formGroup.controls.draft_period.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            // show days field for Custom
            if (value && value.period === 'day') {
                this.draft_period_custom = {
                    frequency: this.formGroup.controls.draft_period_custom.value,
                    period: 'day'
                };
            } else {
                this.draft_period_custom = null;
            }
            // check and enable create_invoice_for_current_period field
            this.checkDraftPeriod();
        });

        this.formGroup.controls.draft_period_custom.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => {
                const draft_period = this.formGroup.controls.draft_period.value;
                if (draft_period && draft_period.period === 'day') {
                    this.draft_period_custom = {
                        frequency: value,
                        period: 'day'
                    };
                    // check and enable create_invoice_for_current_period field
                    this.checkDraftPeriod();
                }
            });

        this.formGroup.controls.repeat.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            if (value && value.period === 'custom') {
                this.repeat_custom = {
                    frequency: this.formGroup.controls.repeat_custom_days.value,
                    period: 'custom',
                    is_anniversary: false
                };
            } else {
                this.repeat_custom = null;
            }
        });

        this.formGroup.controls.repeat_custom_days.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            this.repeat_custom = {
                frequency: value,
                period: 'custom',
                is_anniversary: false
            };
        });
    }

    ngOnInit() {
        this.today = Number(moment().startOf('day').format('YYYYMMDD'));

        this.endsGroup = this.fb.group({
            endsOptionControl: ['none', Validators.required]
        });

        this.endsGroup.controls.endsOptionControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            if (value) {
                switch (value) {
                    case 'none': {
                        this.formGroup.controls.end_after.setValue(null);
                        this.formGroup.controls.end_on.setValue(null);
                        this.formGroup.controls.end_after.clearValidators();
                        this.formGroup.controls.end_on.clearValidators();
                        this.formGroup.controls.end_on.updateValueAndValidity();
                        this.formGroup.controls.end_after.updateValueAndValidity();
                        break;
                    }
                    case 'by': {
                        this.formGroup.controls.end_after.setValue(null);
                        this.formGroup.controls.end_after.clearValidators();
                        this.formGroup.controls.end_on.setValidators([Validators.required]);
                        this.formGroup.controls.end_on.updateValueAndValidity();
                        this.formGroup.controls.end_after.updateValueAndValidity();
                        break;
                    }
                    case 'after': {
                        this.formGroup.controls.end_on.setValue(null);
                        this.formGroup.controls.end_on.clearValidators();
                        this.formGroup.controls.end_after.setValidators([Validators.required, Validators.min(1)]);
                        this.formGroup.controls.end_after.updateValueAndValidity();
                        this.formGroup.controls.end_on.updateValueAndValidity();
                        break;
                    }
                }
            }
        });

        this.initEdit();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    checkDraftPeriod() {
        let draftPeriod = this.formGroup.controls.draft_period.value;
        const startOn = this.formGroup.controls.start_on.value;
        if (draftPeriod && draftPeriod.period !== 'none' && Number.isInteger(startOn)) {
            if (draftPeriod.period === 'day') {
                draftPeriod = this.draft_period_custom;
            }
            // calculate draft period
            const draftDate = Number(
                moment(startOn, 'YYYYMMDD')
                    .subtract(draftPeriod.frequency, <moment.unitOfTime.DurationConstructor>`${draftPeriod.period}s`)
                    .format('YYYYMMDD')
            );
            if (this.today) {
                this.isCreateInvoiceForCurrent =
                    startOn < this.today || (startOn >= this.today && draftDate < this.today);
            }
        }
    }
}
