import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {of, Subject} from 'rxjs';
import {catchError, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {IInvoice, IPaymentReceived, IPaymentReceivedMadeRequestObject, IProfile} from '@cyberco-nodejs/zipi-typings';
import {NotificationsService} from 'angular2-notifications';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {IFinanceState} from '../../../store/finance.reducer';
import {BankingService} from 'app/services/api/finance/banking.service';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {ContactCreateDialogComponent} from '../../../../contacts/contact-dialogs/contact-create-dialog/contact-create-dialog.component';
import {PaymentsReceivedService} from '../../../services/payments-received.service';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {PAYMENT_MODES} from 'app/local-typings';
import {GenericFormArray} from 'app/entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {LedgerAccountSelectorComponent} from '../../../../shared/components/ledger-account-selector/ledger-account-selector.component';
import {FormGroupArray, FormGroupWithFormControls} from '../../../../../typings/common';
import Decimal from 'decimal.js-light';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';
import {formatToDate} from '../../../../../utilities';

@Component({
    selector: 'app-create-payment-received',
    templateUrl: 'create-payment-received.component.html',
    styleUrls: ['create-payment-received.component.scss']
})
export class CreatePaymentReceivedComponent implements OnInit, OnDestroy {
    @ViewChild('ledgerAccountSelector') public ledgerAccountSelector: LedgerAccountSelectorComponent | undefined;

    private unsubscribe: Subject<void> = new Subject();

    invoicesArray: FormGroupArray = this.fb.array([]) as FormGroupArray;
    formGroup: FormGroupWithFormControls = this.fb.group({
        money_sender__contact_fk_id: [null, [Validators.required]],
        paid_date: [new Date(), [Validators.required]],
        pay_to__ledger_account_fk_id: [null, [Validators.required]],
        amount: [null, [Validators.required]],

        sales_person__profile_fk_id: [null],

        payment_mode: [null, [Validators.required]],
        payment_number: [null, []],
        notes: [null, []],
        reference: [null, []],
        allow_auto_apply: [false, []],
        check_number: [null, []],
        memo: [null, []]
    }) as FormGroupWithFormControls;
    currencyMaskitoMask = currencyMaskitoOptions;

    invoices: IInvoice[] = [];

    amountReceived: number = 0;
    paymentsAmount: number = 0;
    excessAmount: number = 0;
    refundedAmount: number = 0;

    paymentModes = PAYMENT_MODES;
    createDisabled: boolean = false;
    isPaymentMatched: boolean = false;

    paymentReceivedId: number | null = null;
    paymentReceived: IPaymentReceived | undefined;

    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    savedContacts: Array<number> = [];

    iaDepositRequestPayment: boolean;
    isDepositReleasePayment: boolean;

    isReconciled: boolean;

    constructor(
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        public router: Router,
        private store: Store<IFinanceState>,
        private bankingService: BankingService,
        private invoicesService: InvoicesService,
        private paymentsReceivedService: PaymentsReceivedService
    ) {
        this.iaDepositRequestPayment = false;
        this.isDepositReleasePayment = false;
        this.isReconciled = false;
    }

    ngOnInit() {
        this.formGroup.controls.paid_date.patchValue(formatToDate(new Date()));

        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            const id = params['id'];

            if (id) {
                this.paymentReceivedId = Number(id);
                this.initEditPayment();
            } else {
                this.initVendorContactSubscribe();
            }
        });

        this.moneySenderCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            if (values && values.length > 0) {
                // this.selectMoneySender(values[0].target_id);
                this.formGroup.controls.money_sender__contact_fk_id.patchValue(values[0].target_id);
            } else {
                // this.deselectCustomer();
                this.formGroup.controls.money_sender__contact_fk_id.patchValue(null);
            }
        });
        this.formGroup.controls.amount.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => this.amountValidation());
    }

    initVendorContactSubscribe() {
        this.formGroup.controls.money_sender__contact_fk_id.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((id) => {
                this.invoicesArray.clear();

                if (!id) {
                    return;
                }

                this.invoicesService
                    .getInvoicesForCreditsApply(id)
                    .pipe(
                        tap((invoices) =>
                            invoices.map((invoice: IInvoice) => {
                                const arrItem = this.fb.group({
                                    invoice_id: invoice.invoice_id,
                                    invoice_number: invoice.invoice_number,
                                    due_date: invoice.due_date,
                                    payment: 0,
                                    balance: invoice.pending_balance,
                                    refund: [0, []]
                                });

                                arrItem.controls.payment.valueChanges
                                    .pipe(takeUntil(this.unsubscribe))
                                    .subscribe(() => this.amountValidation());

                                this.invoicesArray.push(arrItem);
                            })
                        ),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe();
            });
    }

    initEditPayment() {
        if (this.paymentReceivedId) {
            this.paymentsReceivedService
                .getPaymentReceivedById(this.paymentReceivedId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((payment: IPaymentReceived) => {
                    this.paymentReceived = payment;

                    if (
                        this.paymentReceived &&
                        this.paymentReceived.related_payments &&
                        this.paymentReceived.related_payments.some((pay) => !!pay.deposit_request_fk_id)
                    ) {
                        this.iaDepositRequestPayment = true;
                    }

                    if (
                        this.paymentReceived &&
                        this.paymentReceived.related_payments &&
                        this.paymentReceived.related_payments.some((pay) => !!pay.deposit_release_fk_id)
                    ) {
                        this.isDepositReleasePayment = true;
                    }

                    if (payment.journal && payment.journal.line_items) {
                        this.isPaymentMatched = !!payment.journal.line_items.find(
                            (t) => !!t.matched__transaction_external_id
                        );
                        this.isReconciled = !!payment.journal.line_items.find((t) => !!t.reconciliation_fk_id);
                    }

                    if (payment.related_payments && payment.related_payments.length !== 0) {
                        for (const rp of payment.related_payments) {
                            if (rp.invoice) {
                                const arrItem = this.fb.group({
                                    invoice_id: rp.invoice.invoice_id,
                                    payment_id: rp.payment_id,
                                    invoice_number: rp.invoice.invoice_number,
                                    due_date: rp.invoice.due_date,
                                    payment: rp.amount,
                                    balance: rp.invoice.pending_balance
                                        ? new Decimal(rp.invoice.pending_balance)
                                              .add(rp.amount)
                                              .toDecimalPlaces(2)
                                              .toNumber()
                                        : rp.amount,
                                    refund: [rp.refunded_amount, []]
                                });

                                arrItem.controls.payment.valueChanges
                                    .pipe(takeUntil(this.unsubscribe))
                                    .subscribe(() => this.amountValidation());

                                if (rp.status === 'pending') {
                                    arrItem.disable();
                                }
                                this.invoicesArray.push(arrItem);
                            }
                        }
                    }

                    this.invoicesService
                        .getInvoicesForCreditsApply(payment.money_sender__contact_fk_id)
                        .pipe(
                            tap((invoices) =>
                                invoices.map((invoice: IInvoice) => {
                                    const paymentExist = this.invoicesArray
                                        .getRawValue()
                                        .find((p) => p.invoice_id === invoice.invoice_id);

                                    if (!paymentExist) {
                                        const arrItem = this.fb.group({
                                            invoice_id: invoice.invoice_id,
                                            payment_id: null,
                                            invoice_number: invoice.invoice_number,
                                            due_date: invoice.due_date,
                                            payment: 0,
                                            balance: invoice.pending_balance,
                                            refund: [0, []]
                                        });

                                        arrItem.controls.payment.valueChanges
                                            .pipe(takeUntil(this.unsubscribe))
                                            .subscribe(() => this.amountValidation());

                                        this.invoicesArray.push(arrItem);
                                    }
                                })
                            ),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe();

                    this.formGroup.patchValue({
                        pay_to__ledger_account_fk_id: payment.pay_to__ledger_account_fk_id,
                        money_receiver__contact_fk_id: null,
                        money_sender__contact_fk_id: payment.money_sender__contact_fk_id,
                        paid_date: payment.paid_date,
                        amount: payment.amount,
                        payment_mode: payment.payment_mode,
                        payment_number: payment.payment_received_number,
                        reference: payment.reference,
                        notes: payment.notes,
                        allow_auto_apply: payment.allow_auto_apply,
                        check_number: payment.check_info ? payment.check_info.check_number : null,
                        memo: payment.check_info ? payment.check_info.memo : null
                    });

                    this.formGroup.controls.money_sender__contact_fk_id.disable();
                    this.formGroup.controls.payment_number.setValidators([Validators.required]);
                    this.formGroup.controls.payment_number.updateValueAndValidity();

                    if (
                        this.isPaymentMatched ||
                        this.paymentReceived.external_transaction_ref ||
                        this.iaDepositRequestPayment ||
                        this.isDepositReleasePayment ||
                        this.paymentReceived.payment_mode === 'deduction'
                    ) {
                        this.formGroup.controls.amount.disable();
                    }
                    if (payment.money_sender__contact_fk_id) {
                        this.savedContacts = [payment.money_sender__contact_fk_id];
                    }
                });
        }
    }

    amountValidation() {
        const rawValue = this.formGroup.getRawValue();
        const value = Number(cleanCurrencyString(rawValue.amount));
        const invoicesArray = this.invoicesArray.getRawValue();

        let sumOfPayments = 0;
        let prRefund = 0;
        if (this.paymentReceived) {
            prRefund = this.paymentReceived.refunded_amount;
        }

        const sumOfPaymentsDecimal: Decimal = invoicesArray.reduce(
            (acc, invoice) => acc.add(Number(cleanCurrencyString(invoice.payment))).sub(invoice.refund),
            new Decimal(0)
        );
        sumOfPayments = sumOfPaymentsDecimal.toDecimalPlaces(2).toNumber();

        this.amountReceived = value;
        this.paymentsAmount = sumOfPayments;
        this.refundedAmount = prRefund;
        this.excessAmount = new Decimal(value)
            .sub(sumOfPayments)
            .sub(this.refundedAmount)
            .toDecimalPlaces(2)
            .toNumber();

        if (value >= sumOfPayments) {
            this.formGroup.controls.amount.setErrors(null);
        } else {
            this.formGroup.controls.amount.setErrors({message: `The total applied payment has exceeded this amount.`});
        }

        this.validatePayments();
    }

    validatePayments() {
        let unappliedAmount = new Decimal(this.amountReceived);
        for (const control of this.invoicesArray.controls) {
            const group: UntypedFormGroup = control as UntypedFormGroup;
            const payment = Number(cleanCurrencyString(group.controls.payment.value));

            if (payment > group.controls.balance.value) {
                group.controls.payment.setErrors({message: 'Amount cannot exceed the balance.'});
                group.controls.payment.markAllAsTouched();
                this.formGroup.controls.amount.markAllAsTouched();
                unappliedAmount = unappliedAmount.sub(payment);
                continue;
            }

            if (payment > 0 && payment > unappliedAmount.toDecimalPlaces(2).toNumber()) {
                group.controls.payment.setErrors({
                    message: `The total amount of $${this.amountReceived} has been exceeded.`
                });
                group.controls.payment.markAllAsTouched();
                this.formGroup.controls.amount.markAllAsTouched();
                unappliedAmount = unappliedAmount.sub(payment);
                continue;
            }
            group.controls.payment.setErrors(null);
            unappliedAmount = unappliedAmount.sub(payment);
        }
    }

    showPaymentMode() {
        if (this.paymentReceived && this.paymentReceivedId) {
            let paymentModeTitle = '';
            for (const mode of this.paymentModes) {
                if (mode.slug === this.paymentReceived.payment_mode) {
                    paymentModeTitle = mode.title;
                }
            }
            return paymentModeTitle;
        }
    }

    private validateForm() {
        this.formGroup.controls.amount.updateValueAndValidity();
        if (isNaN(this.formGroup.controls.paid_date.value)) {
            this.formGroup.controls.paid_date.setValue(null);
        }
        if (this.ledgerAccountSelector) {
            this.ledgerAccountSelector.markTouched();
        }

        if (this.formGroup.invalid || this.invoicesArray.invalid) {
            this.formGroup.markAllAsTouched();
            this.invoicesArray.markAllAsTouched();
            this.ntfs.warn('Information could not be saved. Please resolve the highlighted errors.');
            return false;
        }
        return true;
    }

    payInFull(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        if (itemGroup.disabled) {
            return;
        }
        const amount = cleanCurrencyString(itemGroup.controls.balance.value);

        itemGroup.controls.payment.patchValue(amount);
    }

    editPayment() {
        if (!this.validateForm() || this.createDisabled) {
            return;
        }
        const dataRaw = this.formGroup.getRawValue();
        const invoicesRaw = this.invoicesArray
            .getRawValue()
            .map((invoice) => {
                return {
                    entity_id: invoice.invoice_id,
                    payment_id: invoice.payment_id,
                    amount: Number(cleanCurrencyString(invoice.payment)),
                    entity: null,
                    entity_transfer_id: null
                };
            })
            .filter((item) => item.amount > 0 || item.payment_id);

        this.createDisabled = true;

        const checkInfo =
            this.paymentReceived && this.paymentReceived.check_info
                ? {
                      check_status: this.paymentReceived.check_info.check_status,
                      print_status: this.paymentReceived.check_info.print_status,
                      memo: dataRaw.memo,
                      check_number: dataRaw.check_number
                  }
                : null;

        const requestObj: IPaymentReceivedMadeRequestObject = {
            money_sender__contact_fk_id: this.paymentReceived!.money_sender__contact_fk_id,
            money_receiver__contact_fk_id: null,
            paid_date: dataRaw.paid_date,
            scheduled_date: null,
            amount: Number(cleanCurrencyString(dataRaw.amount)),
            pay_to__ledger_account_fk_id: dataRaw.pay_to__ledger_account_fk_id,
            paid_by__ledger_account_fk_id: null,
            pay_to__payment_method_fk_id: null,
            paid_by__payment_method_fk_id: null,
            payments: invoicesRaw,
            is_create_multiple_payments: true,
            payment_mode: dataRaw.payment_mode,
            payment_number: dataRaw.payment_number,
            reference: dataRaw.reference,
            notes: dataRaw.notes,

            matched__transaction_external_id: null,
            check_info: checkInfo,
            deposit_release_id: null,
            is_locked_for_applying: false,
            allow_auto_apply: dataRaw.allow_auto_apply,
            source__deal_fk_id: null,
            sender_velocity: null,
            restrict_downgrade: false
        };

        if (this.paymentReceivedId) {
            this.paymentsReceivedService
                .editPaymentReceived(this.paymentReceivedId, requestObj)
                .pipe(
                    catchError(() => {
                        this.createDisabled = false;
                        return of(null);
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((res) => {
                    if (res) {
                        this.router.navigate(['/sales/paymentsreceived/']);
                    }
                });
        }
    }

    create() {
        if (!this.validateForm() || this.createDisabled) {
            return;
        }
        const dataRaw = this.formGroup.getRawValue();
        const invoicesRaw = this.invoicesArray
            .getRawValue()
            .map((invoice) => {
                return {
                    entity_id: invoice.invoice_id,
                    payment_id: null,
                    amount: Number(cleanCurrencyString(invoice.payment)),
                    entity: null,
                    entity_transfer_id: null
                };
            })
            .filter((item) => item.amount > 0);

        this.createDisabled = true;

        const requestObj: IPaymentReceivedMadeRequestObject = {
            money_sender__contact_fk_id: dataRaw.money_sender__contact_fk_id,
            money_receiver__contact_fk_id: null,
            paid_date: dataRaw.paid_date,
            scheduled_date: null,
            amount: Number(cleanCurrencyString(dataRaw.amount)),
            pay_to__ledger_account_fk_id: dataRaw.pay_to__ledger_account_fk_id,
            paid_by__ledger_account_fk_id: null,
            pay_to__payment_method_fk_id: null,
            paid_by__payment_method_fk_id: null,
            payments: invoicesRaw,
            is_create_multiple_payments: true,
            payment_mode: dataRaw.payment_mode,
            payment_number: dataRaw.payment_number,
            reference: dataRaw.reference,
            notes: dataRaw.notes,

            matched__transaction_external_id: null,
            check_info: null,
            deposit_release_id: null,
            is_locked_for_applying: false,
            allow_auto_apply: dataRaw.allow_auto_apply,
            source__deal_fk_id: null,
            sender_velocity: null,
            restrict_downgrade: false
        };

        if (dataRaw.payment_mode === 'check_record') {
            requestObj.check_info = {
                memo: dataRaw.memo,
                check_number: dataRaw.check_number,
                print_status: 'unknown',
                check_status: 'uncleared'
            };
        }

        this.paymentsReceivedService
            .createPaymentReceived(requestObj)
            .pipe(
                catchError(() => {
                    this.createDisabled = false;
                    return of(null);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((res) => {
                if (res) {
                    this.router.navigate(['/sales/paymentsreceived/']);
                }
            });
    }

    contactCreate() {
        const dialogRef = this.dialog.open(ContactCreateDialogComponent, {
            autoFocus: false,
            data: {
                category: 'customer'
            }
        });

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    isAbleToDelete() {
        if (this.paymentReceived) {
            const payments = this.paymentReceived.related_payments;
            const areThereUndeletablePayments =
                payments &&
                payments.length > 0 &&
                payments.some(
                    (pay) =>
                        ['completed', 'review', 'pending', 'processing'].includes(pay.status) ||
                        !!pay.deposit_request_fk_id ||
                        !!pay.deposit_release_fk_id
                );

            const isExternalPaySystemMode =
                this.paymentReceived.payment_mode &&
                ['zipi_pay', 'zipi_financial', 'authorize'].includes(this.paymentReceived.payment_mode);

            if (areThereUndeletablePayments || this.isPaymentMatched || this.isReconciled || isExternalPaySystemMode) {
                return false;
            }
        }
        return true;
    }

    getDeleteTooltip() {
        if (
            this.paymentReceived &&
            this.paymentReceived.related_payments &&
            this.paymentReceived.related_payments.some((pay) => !!pay.deposit_request_fk_id)
        ) {
            return 'Payment Received was created as part of the Deal Deposit and should be deleted from Deal Page.';
        }

        if (
            this.paymentReceived &&
            this.paymentReceived.related_payments &&
            this.paymentReceived.related_payments.some((pay) => !!pay.deposit_release_fk_id)
        ) {
            return 'Payment Received was created as part of the Deal Deposit and should be deleted from Deal Page.';
        }

        if (
            this.paymentReceived &&
            this.paymentReceived.payment_mode &&
            ['zipi_pay', 'zipi_financial', 'authorize'].includes(this.paymentReceived.payment_mode)
        ) {
            return 'Payment Received has reference to external Pay System';
        }

        if (this.isPaymentMatched) {
            return 'Payment Received was matched with external transaction';
        }
        if (this.paymentReceived && !!this.paymentReceived.source__deal_fk_id) {
            return 'You cannot modify Payment Received associated with Deal.';
        }

        return !this.isAbleToDelete() ? 'Payment Received has Payment(s) that cannot be deleted' : '';
    }

    deletePayment() {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            data: {
                title: `Confirm deleting Payment Received`,
                message: `Please, confirm deleting`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((confirmed: boolean) => {
                if (!confirmed) {
                    return;
                }
                if (this.paymentReceived && this.paymentReceived.payment_received_id) {
                    this.paymentsReceivedService
                        .deletePaymentById(this.paymentReceived.payment_received_id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((res) => {
                            if (res) {
                                this.router.navigate(['/sales/paymentsreceived/']);
                            }
                        });
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
