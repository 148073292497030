import {Injectable} from '@angular/core';
import {ServiceRequester} from './service.requester';
import {Automation} from '../models/automation';
import {Observable} from 'rxjs';
import {IAutomation} from '@cyberco-nodejs/zipi-typings';
import {IScrollData} from '../models/scroll-data';

@Injectable()
export class AutomationService {
    private url = '/automation/';

    constructor(public requester: ServiceRequester) {}

    getAllAutomations(scrollData: IScrollData): Observable<{result: IAutomation[]; _meta: {total: number}}> {
        return this.requester.makeMsCall$(`${this.url}`, 'GET', 'ship', scrollData);
    }

    createAutomation(data: Automation): Observable<IAutomation> {
        return this.requester.makeMsCall$(this.url, 'POST', 'ship', data);
    }

    updateAutomation(data: Automation): Observable<IAutomation> {
        return this.requester.makeMsCall$(this.url, 'PUT', 'ship', data);
    }

    deleteAutomation(automationId: number, confirmDeleteTags: boolean = false): Observable<boolean> {
        return this.requester.makeMsCall$(
            `${this.url}${automationId}?confirm_delete_tags=${confirmDeleteTags ? 1 : 0}`,
            'DELETE',
            'ship'
        );
    }

    applyTagToAllDeals(automationId: number): Promise<number> {
        return this.requester.makeMsCall(`/deals/apply-tag-deals/${automationId}`, 'POST', {});
    }

    deleteAutogeneratedTags(automationId: number): Promise<boolean> {
        return this.requester.makeMsCall(`/tagging/deal/all-auto-tags/${automationId}`, 'DELETE');
    }
}
