import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {
    ICreditNoteBase,
    ICreditNoteItem,
    IRecurringCreditNote,
    IRecurringCreditPeriod
} from '@cyberco-nodejs/zipi-typings';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationsService} from 'angular2-notifications';
import {isEqual, assign} from 'lodash-es';
import {RECURRING_INVOICE_PERIOD} from 'app/local-typings';
import {RecurringCreditNotesService} from '../../../services/recurring-credit-notes.service';

export interface IMaybeValidCreditNoteTemplate {
    valid: boolean;
    template: ICreditNoteBase | null;
}

@Component({
    selector: 'app-edit-recurring-credit-note',
    templateUrl: 'edit-recurring-credit-note.component.html'
})
export class EditRecurringCreditNoteComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    recurringCreditNote: IRecurringCreditNote | null = null;

    recurringFormGroup: UntypedFormGroup = this.fb.group({
        recurrence_title: [null, Validators.required],

        start_on: [null, Validators.required],
        repeat: [{frequency: 1, period: 'month', is_anniversary: false}],
        repeat_custom_days: [1],
        end_on: null
    });

    repeat_custom: IRecurringCreditPeriod | null = null;

    creditNoteBase: ICreditNoteBase | null = null;

    comparePeriod: (o1: any, o2: any) => boolean = isEqual;
    repeatPeriod = RECURRING_INVOICE_PERIOD;

    maybeValidCreditNote: IMaybeValidCreditNoteTemplate = {valid: false, template: null};

    constructor(
        private recurringCreditNotesService: RecurringCreditNotesService,
        private route: ActivatedRoute,
        public router: Router,
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService
    ) {}

    ngOnInit() {
        this.route.paramMap
            .pipe(
                map((pm) => {
                    const stringId: string | null = pm.get('id');
                    return Number(stringId);
                }),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                if (id) {
                    this.recurringCreditNotesService
                        .getRecurringCreditNoteById(id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((rj) => {
                            this.recurringCreditNote = rj;
                            this.creditNoteBase = rj.credit_note_template;
                            this.recurringFormGroup.patchValue(rj);

                            if (rj.repeat.period === 'custom') {
                                this.recurringFormGroup.controls.repeat.patchValue({frequency: 1, period: 'custom'});
                                this.recurringFormGroup.controls.repeat_custom_days.patchValue(rj.repeat.frequency);
                                this.recurringFormGroup.controls.is_anniversary.patchValue(rj.repeat.is_anniversary);
                            }
                        });
                }
            });

        this.recurringFormGroup.controls.repeat.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            if (value && value.period === 'custom') {
                this.repeat_custom = {
                    frequency: this.recurringFormGroup.controls.repeat_custom_days.value,
                    period: 'custom',
                    is_anniversary: false
                };
            } else {
                this.repeat_custom = null;
            }
        });

        this.recurringFormGroup.controls.repeat_custom_days.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => {
                this.repeat_custom = {
                    frequency: value,
                    period: 'custom',
                    is_anniversary: false
                };
            });
    }

    onTemplateUpdate(event: IMaybeValidCreditNoteTemplate) {
        this.maybeValidCreditNote = event;
    }

    validateForm() {
        if (this.recurringFormGroup.valid && this.maybeValidCreditNote.valid) {
            return true;
        }

        this.ntfs.warn('Form is not valid');
        this.recurringFormGroup.updateValueAndValidity();

        return false;
    }

    createRecurringCreditNote() {
        if (!this.validateForm()) {
            return;
        }

        const newRecurringCreditNote = assign({}, this.recurringFormGroup.getRawValue(), {
            credit_note_template: this.maybeValidCreditNote.template
        });

        if (this.repeat_custom) {
            newRecurringCreditNote.repeat = this.repeat_custom;
        }
        newRecurringCreditNote.credit_note_template.items = newRecurringCreditNote.credit_note_template.items.map(
            (item: ICreditNoteItem, idx: number) => {
                return {...item, rate: Number(item.rate), order_index: idx + 1};
            }
        );

        this.recurringCreditNotesService
            .createRecurringCreditNote(newRecurringCreditNote)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                this.router.navigate([`/sales/creditnotes/recurring`]);
            });
    }

    updateRecurringCreditNote() {
        if (!this.validateForm()) {
            return;
        }

        const recurringCreditNote = assign({}, this.recurringFormGroup.getRawValue(), {
            recurring_credit_note_id: this.recurringCreditNote!.recurring_credit_note_id,
            credit_note_template: this.maybeValidCreditNote.template
        });

        if (this.repeat_custom) {
            recurringCreditNote.repeat = this.repeat_custom;
        }

        this.recurringCreditNotesService
            .updateRecurringCreditNote(recurringCreditNote)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                this.router.navigate([`/sales/creditnotes/recurring`]);
            });
    }

    deleteRecurringCreditNote() {
        if (this.recurringCreditNote && this.recurringCreditNote.recurring_credit_note_id) {
            this.recurringCreditNotesService
                .deleteRecurringCreditNote(this.recurringCreditNote.recurring_credit_note_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((res) => {
                    this.router.navigate([`/sales/creditnotes/recurring`]);
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
