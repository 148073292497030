import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IReportsState} from './reports.reducer';

export const selectReportListFeature = createFeatureSelector<IReportsState>('reports');
export const selectReportList = createSelector(selectReportListFeature, (state: IReportsState) => state.reports);
export const selectRecommendedReportsPresetsList = createSelector(
    selectReportListFeature,
    (state: IReportsState) => state.recommendedReportsPresetsList
);
export const selectReportsCategoriesList = createSelector(
    selectReportListFeature,
    (state: IReportsState) => state.reportsCategoriesList
);
export const selectReportData = createSelector(selectReportListFeature, (state: IReportsState) => state.reportsData);
export const selectReportsEditSibedar = createSelector(
    selectReportListFeature,
    (state: IReportsState) => state.reportEditSidebar
);

export const selectReportsPreviewData = createSelector(
    selectReportListFeature,
    (state: IReportsState) => state.reportPreviewData
);
