import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../service.requester';
import {Observable, of} from 'rxjs';
import {IJournal} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class JournalsService {
    private url = '/finance/journals/';

    constructor(public requester: ServiceRequester) {}

    getJournals(params = {}): Observable<{result: IJournal[]; _meta: {total: number}}> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp', params);
    }

    getJournalById(id: number): Observable<IJournal> {
        return this.requester.makeMsCall$(this.url + id, 'GET', 'shipp');
    }

    createJournal(data: any): Observable<IJournal> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    updateJournal(data: IJournal): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + data.journal_id, 'PUT', 'shipp', data);
    }

    deleteJournal(id: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + id, 'DELETE', 'shipp');
    }

    downloadJournalPdf(data: number): Observable<any> {
        return this.requester.makeMsCall$(this.url + data + '/pdf', 'GET', 'shipp');
    }
}
