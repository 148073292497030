import {BaseModel} from '../../../../../models/base.model';
import {PickerItem} from '../../../../shared/components/profiles-picker/models';
import {Profile} from '../../../../../models/profile';
import {Deal} from '../../../../../models/deal';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {GenericControl, GenericFormArray} from '../../../../../entites/generic.entity';
import {UntypedFormControl} from '@angular/forms';

export class DealsBoard extends BaseModel {
    name: string = '';
    version: string = '';
    settings_frontend: DealsBoardFrontendFilterSettings = {
        statuses: {},
        types: {},
        disbursement_statuses: {},
        property_classes: {},
        search: '',
        tags: null,
        divisions: null
    };
    settings_backend: DealsBoardBackendFilterSettings = {
        source_of_business: [],
        close_of_escrow_after: null,
        close_of_escrow_before: null,
        is_archived: '',
        is_approved: '',
        filter_by_deal_members: [],
        by_company: {},
        show_net_by: 'user',
        show_only_owner_deals: false,
        deals_view_mode: 'company',
        order_by: ORDER_BY_STATUS,
        deal_stage: ['draft', 'open', 'approved', ''],
        closing_date_type: Deal.closing_date_LABELS.all_dated
    };
    preview: Deal[] = [];
    preview_updated_at: Date | null = null;
    default?: boolean;
    deals?: {data: Deal[]; _meta: {}} = {data: [], _meta: {}};

    setupBackendSettings(profiles: Profile[]): DealsBoard {
        if (typeof this.settings_backend.by_company === 'undefined') {
            this.settings_backend.by_company = {};
        }
        profiles.forEach((profile) => {
            if (profile.id && typeof this.settings_backend.by_company[profile.id] === 'undefined') {
                this.settings_backend.by_company[profile.id] = new DealsBoardBackendFilterSettingsByCompany();
                this.settings_backend.by_company[profile.id].enabled = true;
                this.settings_backend.by_company[profile.id].deals_view_mode = this.settings_backend.deals_view_mode;
                this.settings_backend.by_company[profile.id].filter_by_deal_members = [];
            }
        });
        if (!this.settings_backend.show_net_by) {
            this.settings_backend.show_net_by = 'user';
        }
        return this;
    }
}

export const ORDER_BY_HIGHEST_COE_ON_TOP = 'highest_coe_on_top';
export const ORDER_BY_LOWEST_COE_ON_TOP = 'lowest_coe_on_top';
export const ORDER_BY_STATUS = 'by_status';

export const DEAL_ORDER_BY_OPTIONS = [
    {
        label: 'Latest COE (on top)',
        value: ORDER_BY_HIGHEST_COE_ON_TOP
    },

    {
        label: 'Earliest COE (on top)',
        value: ORDER_BY_LOWEST_COE_ON_TOP
    },

    // in this case deals should be shown in such order by statuses: "Pending, Active, Lead, Closed, Cancelled, Processed"
    {
        label: 'Status',
        value: ORDER_BY_STATUS
    }
];

export class DealsBoardBackendFilterSettingsByCompany {
    filter_by_deal_members: PickerItem[] = [];
    deals_view_mode: string = 'company';
    enabled: boolean = false;
}

export class DealsBoardBackendFilterSettings {
    source_of_business: string[] = [];
    close_of_escrow_after: Date | null = null;
    close_of_escrow_before: Date | null = null;
    is_archived: string = '';
    is_approved: string = '';
    filter_by_deal_members: PickerItem[] = [];
    by_company: {[id: number]: DealsBoardBackendFilterSettingsByCompany} = {};
    show_net_by: string = 'user';
    show_only_owner_deals: boolean = false;
    deals_view_mode = 'company';
    order_by: 'highest_coe_on_top' | 'lowest_coe_on_top' | 'by_status' = ORDER_BY_STATUS;
    source_of_business_id?: number;
    deal_stage: string[] = ['draft', 'open', 'approved', ''];
    closing_date_type: string = Deal.closing_date_LABELS.all_dated;
}

export class DealsBoardFrontendFilterSettings {
    statuses: {[key: string]: boolean} = {};
    types: {[key: string]: boolean} = {};
    disbursement_statuses: {[key: string]: boolean} = {};
    property_classes: {[key: string]: boolean} = {};
    search: string = '';
    tags: number[] | null = null;
    divisions: number[] | null = null;
}

export class DealTemplateEntity {
    id: number | null = null;
    title: string | null = null;
    preset: Deal | null = null;
}

export class SingleTargetDealsQueryModel {
    deal_view: string | null = null;
    status: string | null = null;
    type: string | null = null;
    property_class: string | null = null;

    deal_date: string | null = null;
    frequency: string | null = null;
    override_user_anniversary: string | null = null;

    close_of_escrow_from: string | null = null;
    close_of_escrow_till: string | null = null;
    sales_price_from: number | null = null;
    sales_price_till: number | null = null;
    total_commission_from: number | null = null;
    total_commission_till: number | null = null;
    adjusted_gross_from: number | null = null;
    adjusted_gross_till: number | null = null;
    // net_commission_from: number = null;
    // net_commission_till: number = null;
    profile: Profile | null = null;
    show_net_by: string = 'user';
    show_only_owner_deals: boolean = false;
    order_by: string | null = null;
    group_by: string = 'by_deal';
    used_default_values: boolean | undefined = true;

    setStatus(status: string) {
        this.status = status;
        return this;
    }

    static get frequency_SET() {
        return {
            annually: 'annually',
            monthly: 'monthly',
            weekly: 'weekly',
            daily: 'daily'
        };
    }

    static get frequency_LABELS() {
        return {
            annually: 'annually',
            monthly: 'monthly',
            weekly: 'weekly',
            daily: 'daily'
        };
    }

    static get deal_date_SET() {
        return {
            specific_dates: 'specific_dates',
            dynamic_dates: 'dynamic_dates'
        };
    }

    static get group_by() {
        return {
            by_deal: 'by_deal',
            by_calculation: 'by_calculation'
        };
    }

    static get show_net_by() {
        return {
            user: 'user',
            company: 'company'
        };
    }

    static get deal_view() {
        return {
            my: 'my',
            company: 'company',
            custom: 'custom'
        };
    }

    static get order_by() {
        return {
            by_status: 'by_status',
            highest_coe_on_top: 'highest_coe_on_top',
            lowest_coe_on_top: 'lowest_coe_on_top'
        };
    }
}

export class MultipleTargetsDealsQueryModel extends SingleTargetDealsQueryModel {
    profiles: Profile[] = [];
    combined_picker: {[id: number]: DealsBoardBackendFilterSettingsByCompany} = {};
    apply_to: ChipNode[] = [];
    apply_to_deals: ChipNode[] = [];
    source_of_business: string[] | null = null;
    mls_organisations: number[] | null = null;
    dual_deals: boolean | null = null;
    division: number | null = null;
    // this is for GenericFormGroup created a FormControl but not FormArray

    tag: number[] | null = null;
    // this is for GenericFormGroup created a FormControl but not FormArray
    statuses: string[] | null = null;
    // this is for GenericFormGroup created a FormControl but not FormArray
    types: string[] | null = null;
    disbursement_statuses: string[] | null = null;
    property_classes: string[] | null = null;
    office_lead: boolean | null = null;
}

export enum AvailableNamesForCompensationProfileFilter {
    adjusted_gross_from = 'adjusted_gross_from',
    adjusted_gross_till = 'adjusted_gross_till',
    close_of_escrow_from = 'close_of_escrow_from',
    close_of_escrow_till = 'close_of_escrow_till',
    division = 'division',
    dual_deals = 'dual_deals',
    total_commission_from = 'total_commission_from',
    total_commission_till = 'total_commission_till',
    mls_organisations = 'mls_organisations',
    apply_to = 'apply_to',
    property_classes = 'property_classes',
    sales_price_from = 'sales_price_from',
    sales_price_till = 'sales_price_till',
    source_of_business = 'source_of_business',
    tag = 'tag',
    types = 'types',
    statuses = 'statuses',
    frequency = 'frequency',
    override_user_anniversary = 'override_user_anniversary',
    deal_date_dynamic_dates = 'deal_date_dynamic_dates',
    deal_date_specific_dates = 'deal_date_specific_dates',
    office_lead = 'office_lead'
}

export class CompensationProfileFilter {
    name: AvailableNamesForCompensationProfileFilter | null = null;
    type:
        | 'text'
        | 'number'
        | 'date'
        | 'dropdown'
        | 'contact-picker'
        | 'advanced-filter'
        | 'division-dropdown'
        | 'tags-dropdown'
        | null = null;
    isMultiple?: boolean = false;
    placeholder: string | null = null;
    order?: number | null = null;
    options?: {
        title: string;
        value: any;
    }[] = [];
    childrenFilters?: CompensationProfileFilter[] = [];

    static get available_deals_filters_SET(): CompensationProfileFilter[] {
        return [
            {
                name: AvailableNamesForCompensationProfileFilter.adjusted_gross_from,
                type: 'number',
                placeholder: 'Adjusted Gross Min',
                order: 1
            },
            {
                name: AvailableNamesForCompensationProfileFilter.adjusted_gross_till,
                type: 'number',
                placeholder: 'Adjusted Gross Max',
                order: 2
            },
            {
                name: AvailableNamesForCompensationProfileFilter.close_of_escrow_from,
                type: 'date',
                placeholder: 'Deals Closed: Start',
                order: 3
            },
            {
                name: AvailableNamesForCompensationProfileFilter.close_of_escrow_till,
                type: 'date',
                placeholder: 'Deals Closed: End',
                order: 4
            },
            {
                name: AvailableNamesForCompensationProfileFilter.division,
                type: 'division-dropdown',
                isMultiple: false,
                placeholder: 'Division',
                order: 5
            },
            {
                name: AvailableNamesForCompensationProfileFilter.dual_deals,
                type: 'dropdown',
                isMultiple: false,
                placeholder: 'Dual Deals',
                options: [
                    {
                        title: 'Yes',
                        value: true
                    },
                    {
                        title: 'No',
                        value: false
                    }
                ],
                order: 6
            },
            {
                name: AvailableNamesForCompensationProfileFilter.total_commission_from,
                type: 'number',
                placeholder: 'Gross Commission Min',
                order: 7
            },
            {
                name: AvailableNamesForCompensationProfileFilter.total_commission_till,
                type: 'number',
                placeholder: 'Gross Commission Max',
                order: 8
            },
            {
                name: AvailableNamesForCompensationProfileFilter.office_lead,
                type: 'dropdown',
                isMultiple: false,
                placeholder: 'Office Lead',
                options: [
                    {title: 'Yes', value: true},
                    {title: 'No', value: false}
                ],
                order: 9
            },
            {
                name: AvailableNamesForCompensationProfileFilter.mls_organisations,
                type: 'dropdown',
                isMultiple: true,
                placeholder: 'Organizations',
                options: [],
                order: 10
            },
            {
                name: AvailableNamesForCompensationProfileFilter.apply_to,
                type: 'contact-picker',
                placeholder: 'Participants',
                order: 11
            },
            {
                name: AvailableNamesForCompensationProfileFilter.property_classes,
                type: 'dropdown',
                isMultiple: true,
                placeholder: 'Property Class',
                options: Object.keys(Deal.property_class_SET).map((property_class) => {
                    return {
                        value: property_class,
                        // @ts-ignore
                        title: Deal.property_class_LABELS[property_class]
                    };
                }),
                order: 12
            },
            {
                name: AvailableNamesForCompensationProfileFilter.sales_price_from,
                type: 'text',
                placeholder: 'Sales Price Min',
                order: 13
            },
            {
                name: AvailableNamesForCompensationProfileFilter.sales_price_till,
                type: 'text',
                placeholder: 'Sales Price Max',
                order: 14
            },
            {
                name: AvailableNamesForCompensationProfileFilter.source_of_business,
                type: 'dropdown',
                isMultiple: true,
                placeholder: 'Source of Business',
                options: [],
                order: 15
            },
            {
                name: AvailableNamesForCompensationProfileFilter.tag,
                type: 'tags-dropdown',
                isMultiple: true,
                placeholder: 'Tags',
                order: 16
            },
            {
                name: AvailableNamesForCompensationProfileFilter.types,
                type: 'dropdown',
                isMultiple: true,
                placeholder: 'Type',
                options: Object.keys(Deal.type_SET).map((type) => {
                    return {
                        value: type,
                        // @ts-ignore
                        title: Deal.type_LABELS[type]
                    };
                }),
                order: 17
            }
        ];
    }

    static get available_compensation_profiles_filters_SET(): CompensationProfileFilter[] {
        return [
            {
                name: AvailableNamesForCompensationProfileFilter.deal_date_dynamic_dates,
                type: 'advanced-filter',
                placeholder: 'Deal Date: Dynamic Dates',
                order: 1,
                childrenFilters: [
                    {
                        name: AvailableNamesForCompensationProfileFilter.frequency,
                        type: 'dropdown',
                        isMultiple: false,
                        placeholder: 'Frequency',
                        options: Object.keys(SingleTargetDealsQueryModel.frequency_SET).map((frequency) => {
                            return {
                                value: frequency,
                                // @ts-ignore
                                title: SingleTargetDealsQueryModel.frequency_LABELS[frequency]
                            };
                        })
                    },
                    {
                        name: AvailableNamesForCompensationProfileFilter.override_user_anniversary,
                        type: 'date',
                        placeholder: 'Override Anniversary'
                    }
                ]
            },
            {
                name: AvailableNamesForCompensationProfileFilter.deal_date_specific_dates,
                type: 'advanced-filter',
                placeholder: 'Deal Date: Specific Dates',
                order: 2,
                childrenFilters: [
                    {
                        name: AvailableNamesForCompensationProfileFilter.close_of_escrow_from,
                        type: 'date',
                        placeholder: 'Deals Closed: Start'
                    },
                    {
                        name: AvailableNamesForCompensationProfileFilter.close_of_escrow_till,
                        type: 'date',
                        placeholder: 'Deals Closed: End'
                    }
                ]
            },
            {
                name: AvailableNamesForCompensationProfileFilter.mls_organisations,
                type: 'dropdown',
                isMultiple: true,
                placeholder: 'Organizations',
                options: [],
                order: 3
            },
            {
                name: AvailableNamesForCompensationProfileFilter.property_classes,
                type: 'dropdown',
                isMultiple: true,
                placeholder: 'Property Class',
                order: 4,
                options: Object.keys(Deal.property_class_SET).map((property_class) => {
                    return {
                        value: property_class,
                        // @ts-ignore
                        title: Deal.property_class_LABELS[property_class]
                    };
                })
            },
            {
                name: AvailableNamesForCompensationProfileFilter.source_of_business,
                type: 'dropdown',
                isMultiple: true,
                placeholder: 'Source of Business',
                options: [],
                order: 5
            },
            {
                name: AvailableNamesForCompensationProfileFilter.statuses,
                type: 'dropdown',
                isMultiple: true,
                placeholder: 'Status',
                order: 6,
                options: Object.keys(Deal.status_SET).map((status) => {
                    return {
                        value: status,
                        // @ts-ignore
                        title: Deal.status_LABELS[status]
                    };
                })
            },
            {
                name: AvailableNamesForCompensationProfileFilter.types,
                type: 'dropdown',
                isMultiple: true,
                placeholder: 'Types',
                order: 7,
                options: Object.keys(Deal.type_SET).map((type) => {
                    return {
                        value: type,
                        // @ts-ignore
                        title: Deal.type_LABELS[type]
                    };
                })
            }
        ];
    }

    static compareFiltersByOrder(a: {[key: string]: any}, b: {[key: string]: any}) {
        if (a.order === null) {
            return 1;
        }
        if (a.order < b.order) {
            return -1;
        }
        if (a.order > b.order) {
            return 1;
        }
        return 0;
    }

    static getFilterFormControl(
        controls: {[key in keyof MultipleTargetsDealsQueryModel]?: GenericControl<MultipleTargetsDealsQueryModel[key]>},
        filterName: AvailableNamesForCompensationProfileFilter | null
    ): UntypedFormControl | null {
        if (
            filterName &&
            filterName !== AvailableNamesForCompensationProfileFilter.deal_date_dynamic_dates &&
            filterName !== AvailableNamesForCompensationProfileFilter.deal_date_specific_dates
        ) {
            return controls[filterName] as UntypedFormControl;
        }
        return null;
    }

    static getFilterFormArray(
        controls: {[key in keyof MultipleTargetsDealsQueryModel]?: GenericControl<MultipleTargetsDealsQueryModel[key]>},
        filterName: AvailableNamesForCompensationProfileFilter
    ): GenericFormArray<ChipNode> | null {
        if (
            filterName !== AvailableNamesForCompensationProfileFilter.deal_date_dynamic_dates &&
            filterName !== AvailableNamesForCompensationProfileFilter.deal_date_specific_dates
        ) {
            return controls[filterName] as GenericFormArray<ChipNode>;
        }
        return null;
    }
}
