import {Component, Input} from '@angular/core';
import {GenericFormGroup} from '../../../../../../../entites/generic.entity';
import {CommissionInstruction} from '../../../../../../../models/commission-instruction';

@Component({
    selector: 'app-commission-instruction',
    template: ` <div class="flex-column single-note">
        <div class="flex-row note-header">
            <span
                class="username"
                *ngIf="
                    $any(instructionFG.controls.profile)?.controls?.first_name?.value &&
                    $any(instructionFG.controls.profile)?.controls?.last_name?.value
                "
            >
                {{ instructionFG.controls.profile?.controls?.first_name?.value }}
                {{ instructionFG.controls.profile?.controls?.last_name?.value }}
            </span>
            <span class="remaining-space"></span>
            <span class="timestamp" *ngIf="instructionFG.controls.created_at">{{
                instructionFG.controls.created_at.value | date: 'medium'
            }}</span>
        </div>

        <div class="flex-row">
            <span *ngIf="instructionFG.controls.text" [innerHTML]="findLinks(instructionFG.controls.text.value)"></span>
        </div>
    </div>`,
    styleUrls: ['./commission-instruction.component.scss']
})
export class CommissionInstructionComponent {
    @Input() instructionFG: GenericFormGroup<CommissionInstruction> = new GenericFormGroup(
        new CommissionInstruction(),
        'change'
    );

    constructor() {}

    findLinks(text: string) {
        return text.replace(/(https?:\/\/[^\s]+)/g, '<a href="$&" target="_blank">$&</a>');
    }
}
