import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {MatSort, Sort} from '@angular/material/sort';

import {IFinanceState} from '../../../store/finance.reducer';
import {FetchLedgerAccountsMust} from '../../../store/finance.actions';
import {selectLedgerAccounts} from 'app/store/root.selectors';
import {flattenChildrenDeep, nestedElementsByParent} from 'app/utilities';
import {LEDGER_TYPES_MAP} from 'app/local-typings';
import {Group} from 'app/models/group';
import {GroupsSource} from 'app/services/sources/groups.source';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {RbacService} from '../../../../rbac/rbac.service';
import {Router} from '@angular/router';
import {IScrollData} from 'app/models/scroll-data';
import {LedgerAccount} from 'app/models/ledger-account';
import {ILedgerAccount} from '@cyberco-nodejs/zipi-typings';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-ledger-account-page',
    templateUrl: 'ledger-account-page.component.html',
    styleUrls: ['ledger-account-page.component.scss']
})
export class LedgerAccountPageComponent implements OnInit, OnDestroy, AfterViewInit {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('accountTransactions') accountTransactions: any;
    @ViewChild(MatSort) sort: MatSort = new MatSort();

    scrollData: IScrollData = {
        total: 0
    };

    isSidebarOpened: boolean = false;

    ledgerAccounts: MatTableDataSource<ILedgerAccount>;
    displayedColumns: string[] = ['name', 'code', 'type', 'id'];
    sortFields = ['type', 'code', 'name'];

    ledgerTypesMap: {[key: string]: string} = LEDGER_TYPES_MAP;

    divisionModel: number | null = null;
    groupsList: Group[] = [];

    constructor(
        private store: Store<IFinanceState>,
        private groupSrc: GroupsSource,
        protected rbacService: RbacService,
        public router: Router
    ) {
        this.ledgerAccounts = new MatTableDataSource<ILedgerAccount>([]);
    }

    ngOnInit() {
        this.store.dispatch(new FetchLedgerAccountsMust());
        this.initAccounts(null);

        this.groupSrc.source
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((list) => (this.groupsList = list.filter((group) => group.type === Group.type_SET.division)));
    }

    ngAfterViewInit() {
        this.ledgerAccounts.sort = this.sort;
    }

    initAccounts(divisionModel: number | null = null) {
        this.divisionModel = divisionModel;
        this.store
            .pipe(select(selectLedgerAccounts), takeUntil(this.unsubscribe))
            .subscribe((las: ILedgerAccount[]) => {
                if (!!this.divisionModel) {
                    las = las.filter(
                        (la) => la.accessible_for?.includes(this.divisionModel as number) || la.is_accessible_for_all
                    );
                }

                this.ledgerAccounts.data = flattenChildrenDeep(nestedElementsByParent(las));
                this.scrollData.total = this.ledgerAccounts.data.length;
            });
    }

    sortData(sort: Sort) {
        // problem is we can sort only elements which don't have a parent (parent__ledger_account_fk_id)
        // because we cannot destroy a tree of subaccounts
        this.store.pipe(select(selectLedgerAccounts), takeUntil(this.unsubscribe)).subscribe((las) => {
            if (!!this.divisionModel) {
                las = las.filter(
                    (la) => la.accessible_for.includes(this.divisionModel as number) || la.is_accessible_for_all
                );
            }
            const data = nestedElementsByParent(las);
            if (!sort.active || sort.direction === '') {
                this.ledgerAccounts.data = flattenChildrenDeep(data);
                return;
            }

            const isAsc = sort.direction === 'asc';

            this.ledgerAccounts.data = data.sort((a, b) => {
                return this._recursiveSorting(
                    [sort.active, ...this.sortFields.filter((f) => f !== sort.active)],
                    a,
                    b,
                    0,
                    isAsc
                );
            });
        });
    }

    openSidebar(element: ILedgerAccount) {
        this.accountTransactions.open(element);
    }

    private _recursiveSorting(
        order: string[],
        a: ILedgerAccount,
        b: ILedgerAccount,
        orderIndex: number = 0,
        isAsc: boolean = true
    ): number {
        const sortingFieldA =
            order[orderIndex] === 'type'
                ? LedgerAccount.types_order.indexOf(a.type)
                : a[order[orderIndex] as keyof ILedgerAccount];
        const sortingFieldB =
            order[orderIndex] === 'type'
                ? LedgerAccount.types_order.indexOf(b.type)
                : b[order[orderIndex] as keyof ILedgerAccount];

        if (sortingFieldA && sortingFieldB && sortingFieldA < sortingFieldB) {
            return isAsc ? -1 : 1;
        } else if (sortingFieldA && sortingFieldB && sortingFieldA > sortingFieldB) {
            return isAsc ? 1 : -1;
        } else {
            if (orderIndex === order.length - 1) {
                return 0;
            } else {
                orderIndex++;
                return this._recursiveSorting(order, a, b, orderIndex);
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
