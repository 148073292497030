import {Subject} from 'rxjs';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {IReportsState} from '../../store/reports.reducer';
import {FetchReportList, ToggleEditReportSidebarStatus} from '../../store/reports.action';
import {Router} from '@angular/router';
import {ReportsLocalService} from '../../services/reports-local.service';
import {MatSidenav} from '@angular/material/sidenav';
import {selectReportList} from '../../store/reports.selectors';
import {takeUntil} from 'rxjs/operators';
import {IReport} from '@cyberco-nodejs/zipi-typings';
import {reportLevelBasedOnReportType} from '../../constants';

@Component({
    selector: 'app-reports-page',
    templateUrl: './reports-page.component.html',
    styleUrls: ['./reports-page.component.scss']
})
export class ReportsPageComponent implements OnInit, OnDestroy {
    @ViewChild('reportSave') reportSave: MatSidenav | undefined;

    private unsubscribe: Subject<void> = new Subject();
    private page: number = 1;
    private perPage: number = 25;
    private isDownloadMoreReports: boolean = true;

    public reports: Array<IReport & {typeTitle: string}> = [];
    public isLoaded: boolean = false;
    public reportLevelBasedOnReportType: {[key: string]: string} = reportLevelBasedOnReportType;

    constructor(
        private store: Store<IReportsState>,
        public router: Router,
        private reportsLocalService: ReportsLocalService
    ) {}

    ngOnInit() {
        const reports$ = this.store.select(selectReportList);
        reports$.pipe(takeUntil(this.unsubscribe)).subscribe((reportObj) => {
            this.reports = reportObj.data.map((item) => ({
                ...item,
                typeTitle: item.type === 'profit_loss' ? 'Income Statement' : item.type
            }));

            // check we able to download more reports
            const maxReportItems = this.page * this.perPage;
            this.isDownloadMoreReports = maxReportItems === this.reports.length;
            this.isLoaded = reportObj.isLoaded;
        });

        this.store.dispatch(
            new FetchReportList({
                query: {
                    page: this.page,
                    per_page: this.perPage
                }
            })
        );
    }

    loadReportData(event: MouseEvent, report: IReport) {
        event.stopPropagation();
        this.router.navigate(['reports/preview', report.id]);
    }

    run(preset: IReport) {
        this.reportsLocalService.setReportData(preset);

        this.router.navigate(['/reports/data']);
    }

    doShowMore() {
        if (!this.isDownloadMoreReports) {
            return;
        }

        this.store.dispatch(
            new FetchReportList({
                query: {
                    page: ++this.page,
                    per_page: this.perPage
                },
                isAppend: true
            })
        );
    }

    toggleEditReportSidebar(status: boolean, reportId: number | null = null) {
        this.store.dispatch(
            new ToggleEditReportSidebarStatus({
                isOpened: status,
                isEditMode: !!reportId,
                editId: reportId
            })
        );
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
