import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {IFinanceState} from '../../../../finance/store/finance.reducer';
import {NotificationsService} from 'angular2-notifications';
import {IAdjustmentTransaction} from '@cyberco-nodejs/zipi-typings';
import {takeUntil} from 'rxjs/operators';
import Decimal from 'decimal.js-light';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';
import {amountValidator, formatToDate} from '../../../../../utilities';

export class CustomFormGroup extends UntypedFormGroup {
    public controls: {
        date: UntypedFormControl;
        debit_or_credit: UntypedFormControl;
        ledger_account_fk_id: UntypedFormControl;
        amount: UntypedFormControl;
    };

    constructor(controls: {
        date: UntypedFormControl;
        debit_or_credit: UntypedFormControl;
        ledger_account_fk_id: UntypedFormControl;
        amount: UntypedFormControl;
    }) {
        super(controls);
        this.controls = controls;
    }
}

@Component({
    selector: 'app-add-adjustment-dialog',
    styles: [
        `
            .row.footer {
                justify-content: space-between;
            }

            mat-list {
                max-height: 700px;
                width: 100%;
                overflow-x: hidden;
            }
        `
    ],
    template: `
        <div class="col" style="justify-content: space-between; flex-direction: column; display: flex">
            <h3>Add Adjustment</h3>

            <div [formGroup]="formGroup">
                <div class="d-flex align-content-center">
                    <label class="mr-2">Apply To:</label>
                    <mat-radio-group formControlName="debit_or_credit">
                        <mat-radio-button value="debit">Debit</mat-radio-button>
                        <mat-radio-button value="credit">Credit</mat-radio-button>
                    </mat-radio-group>
                </div>

                <app-date-picker
                    class="w-100"
                    [placeholder]="'Journal Date'"
                    [dateControl]="formGroup.get('date')"
                ></app-date-picker>

                <app-ledger-account-selector
                    [ledgerAccountControl]="formGroup.controls.ledger_account_fk_id"
                    [placeholder]="'Account'"
                    [normalBalanceType]="formGroup.get('debit_or_credit')?.value === 'debit' ? 'credit' : 'debit'"
                >
                </app-ledger-account-selector>

                <mat-form-field style="width: 100%;">
                    <mat-label>Amount</mat-label>
                    <input
                        [maskito]="currencyMaskitoMask"
                        matInput
                        type="text"
                        formControlName="amount"
                        matTooltipPosition="above"
                        placeholder="Amount"
                        autocomplete="off"
                        required
                    />
                </mat-form-field>

                <div class="mb-3" *ngIf="showPendingBalance">
                    Balance:&nbsp;<span>{{ balance | currency: 'USD' : 'symbol' }}</span>
                </div>
            </div>
            <div class="row footer">
                <button
                    mat-raised-button
                    class="mat-primary"
                    [disabled]="formGroup.controls.amount.value === 0"
                    (click)="save()"
                >
                    OK
                </button>
                <button mat-raised-button class="mat-default" (click)="close()">Cancel</button>
            </div>
        </div>
    `
})
export class AdjustmentDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    pending: number = 0;
    balance: number = 0;
    transaction: IAdjustmentTransaction | undefined;

    formGroup: CustomFormGroup = new CustomFormGroup({
        date: new UntypedFormControl(null),
        debit_or_credit: new UntypedFormControl(null),
        ledger_account_fk_id: new UntypedFormControl(null, [Validators.required]),
        amount: new UntypedFormControl(null, [Validators.required, amountValidator()])
    });
    initFormGroupSnapshot = {};
    currencyMaskitoMask = currencyMaskitoOptions;
    minDate: Date | undefined;

    showPendingBalance: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<AdjustmentDialogComponent>,
        private fb: UntypedFormBuilder,
        private store: Store<IFinanceState>,
        private ntfs: NotificationsService
    ) {}

    ngOnInit() {
        this.initSubscribe();

        if (this.pending) {
            this.formGroup.controls.amount.setValue(Math.abs(this.pending));
            this.showPendingBalance = true;
        }

        this.formGroup.controls.date.setValue(formatToDate(new Date()));

        if (this.pending > 0) {
            this.formGroup.controls.debit_or_credit.setValue('debit');
        } else {
            this.formGroup.controls.debit_or_credit.setValue('credit');
        }

        if (this.transaction) {
            this.formGroup.patchValue(this.transaction);
        }

        this.initFormGroupSnapshot = this.formGroup.getRawValue();
    }

    save() {
        if (this.validateForm() === false) {
            return;
        }

        const adjustmentRaw = this.formGroup.getRawValue();
        const adjustment = {
            date: adjustmentRaw.date,
            ledger_account_fk_id: adjustmentRaw.ledger_account_fk_id,
            debit_or_credit: adjustmentRaw.debit_or_credit,
            amount: Number(cleanCurrencyString(adjustmentRaw.amount))
        };

        this.dialogRef.close(adjustment);
    }

    close() {
        this.dialogRef.close();
    }

    initSubscribe() {
        this.dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.formGroup.patchValue(this.initFormGroupSnapshot));

        this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (changes.debit_or_credit === 'debit') {
                this.balance = new Decimal(this.pending)
                    .sub(Number(cleanCurrencyString(changes.amount)))
                    .toDecimalPlaces(2)
                    .toNumber();
            } else {
                this.balance = new Decimal(this.pending)
                    .add(Number(cleanCurrencyString(changes.amount)))
                    .toDecimalPlaces(2)
                    .toNumber();
            }
        });
    }

    validateForm() {
        if (this.formGroup.valid && (this.balance >= 0 || this.pending === 0)) {
            return true;
        }

        this.ntfs.warn('Form is not valid');
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();

        return false;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
