import {GenericEntity} from './generic.entity';
import {DealsSummary} from '../models/deals-summary';
import {MultipleTargetsDealsQueryModel} from '../modules/deals/components/deal/common/deal.models';

export class OpeningPerformanceValuesEntity extends GenericEntity {
    closing_date: Date | null = null;
    type: string | null = null;
    virtual_values: DealsSummary = new DealsSummary();
    /**
     * @deprecated The property will be removed when users fully migrate to Metrics.
     */
    parameters: MultipleTargetsDealsQueryModel = new MultipleTargetsDealsQueryModel();

    static get TYPE_set() {
        return {
            metric_value_modifier: 'metric_value_modifier'
        };
    }

    setType(type: string) {
        this.type = type;
        return this;
    }

    setParameters(parameters: MultipleTargetsDealsQueryModel): OpeningPerformanceValuesEntity {
        this.parameters = parameters;
        return this;
    }
}
