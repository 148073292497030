import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {RbacModule} from '../../rbac/rbac.module';
import {SharedModule} from '../../shared/shared.module';
import {IndexComponent} from './pages/index.component';
import {CompensationProfilesComponent} from '../rules/profiles.component';
import {Tab1ProfilesComponent} from './tabs/tab-1-profiles.component';
import {Tab1AllComponent} from './tabs/tab-1/tab-1-all.component';
import {Tab2GroupsComponent} from './tabs/tab-1/tab-2-groups.component';
import {Tab3EntitiesComponent} from './tabs/tab-1/tab-3-entities.component';
import {ToolbarComponent} from './tabs/tab-1/toolbar.component';
import {CompensationProfileComponent} from './components/compensation-profile.component';
import {CompensationProfilesListComponent} from './components/compensation-profiles-list.component';
import {FinancialElementComponent} from './components/financial-element.component';
import {RuleComponent} from './components/rule.component';
import {FiltersComponent} from './components/filters.component';
import {ProvidesCommissionCategorizeTemplateComponent} from './components/provides/provides-commission-categorize-template.component';
import {ProvidesCompensationPlanComponent} from './components/provides/provides-compensation-plan.component';
import {ProvidesDisbursementTemplateComponent} from './components/provides/provides-disbursement-template.component';
import {ProvidesIncentiveComponent} from './components/provides/provides-incentive.component';
import {ProvidesOverheadComponent} from './components/provides/provides-overhead.component';
import {ProvidesParticipantComponent} from './components/provides/provides-participant.component';
import {ProvidesSplitPlanComponent} from './components/provides/provides-split-plan.component';
import {CompensationService, CompensationServiceApi} from './compensation.service';
import {CompensationGroupsListComponent} from './components/compensation-groups-list.component';
import {CompensationGroupComponent} from './components/compensation-group.component';
import {CompensationEntityComponent} from './components/compensation-entity.component';
import {CompensationEntitiesListComponent} from './components/compensation-entities-list.component';
import {CompensationProfilesChipListComponent} from './components/compensation-profiles-chip-list.component';
import {CategorizationComponent} from './components/categorization.component';
import {OverrideDialogComponent} from './components/override.dialog';
import {ProvidesCompanyPreCompensationComponent} from './components/provides/provides-company-pre-compensation.component';
import {ProvidesRoyaltyPlanComponent} from './components/provides/provides-royalty-plan.component';
import {ProvidesCompanyAncillaryComponent} from './components/provides/provides-company-ancillary.component';
import {ProvidesUserRoyaltyPlanComponent} from './components/provides/provides-user-royalty-plan.component';
import {CompensationProfileOverrideComponent} from './components/compensation-profile-override.component';
import {ConditionComponent} from './components/condition.component';
import {ProvidesDisbursementInstructionsComponent} from './components/provides/provides-disbursement-instructions.component';

@NgModule({
    declarations: [
        // tabs
        Tab1ProfilesComponent,
        Tab1AllComponent,
        Tab2GroupsComponent,
        Tab3EntitiesComponent,
        // components
        ToolbarComponent,
        CompensationProfileComponent,
        CompensationProfileOverrideComponent,
        CompensationProfilesListComponent,
        FinancialElementComponent,
        RuleComponent,
        FiltersComponent,
        ProvidesCommissionCategorizeTemplateComponent,
        ProvidesCompensationPlanComponent,
        ProvidesDisbursementTemplateComponent,
        ProvidesDisbursementInstructionsComponent,
        ProvidesIncentiveComponent,
        ProvidesOverheadComponent,
        ProvidesParticipantComponent,
        ProvidesSplitPlanComponent,
        ProvidesRoyaltyPlanComponent,
        ProvidesCompanyPreCompensationComponent,
        ProvidesCompanyAncillaryComponent,
        ProvidesUserRoyaltyPlanComponent,
        CompensationGroupsListComponent,
        CompensationGroupComponent,
        CompensationEntitiesListComponent,
        CompensationEntityComponent,
        CompensationProfilesChipListComponent,
        CategorizationComponent,
        OverrideDialogComponent,
        ConditionComponent,
        // pages
        IndexComponent,
        CompensationProfilesComponent
    ],
    imports: [RbacModule, SharedModule, RouterModule],
    providers: [CompensationServiceApi, CompensationService],
    exports: [CompensationProfileComponent, CompensationProfileOverrideComponent, FinancialElementComponent],
    bootstrap: [IndexComponent]
})
export class CompensationModule {}
