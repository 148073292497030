import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {GenericFormGroup} from '../../../../../../entites/generic.entity';
import {Deal} from '../../../../../../models/deal';
import {FinancialNode} from '../../../../../shared/components/financial-node/financial-node.model';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {currencyWithNegativeMaskitoOptions} from '../../../../../../utilities/maskito';

@Component({
    selector: 'app-sales-price-adjustment',
    templateUrl: './sales-price-adjustment.component.html',
    styles: [
        `
            .d-grid {
                display: grid;
            }
            .area-adjusted {
                align-self: center;
            }
            @media (min-width: 576px) {
                .d-grid {
                    grid-template-columns: 100%;
                }
            }
            @media (min-width: 768px) {
                .d-grid {
                    grid-template-columns: 50% 50%;
                    grid-template-rows: 50% 50px;
                }
            }
            @media (min-width: 992px) {
                .d-grid {
                    grid-template-columns: 50% 50%;
                    grid-template-rows: 50% 50px;
                }
            }
            @media (min-width: 1200px) {
                .d-grid {
                    grid-template-columns: 25% 25% 25% 25%;
                    grid-template-areas: 'price desc . adjusted';
                }
                .area-price {
                    grid-area: price;
                }
                .area-description {
                    grid-area: desc;
                }
                .area-adjusted {
                    grid-template-columns: 50%;
                    grid-area: adjusted;
                }
            }
        `
    ]
})
export class SalesPriceAdjustmentComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() dealFG: GenericFormGroup<Deal> = new GenericFormGroup(new Deal(), 'change');
    @Input() adjustedSalesPrice: GenericFormGroup<FinancialNode> = new GenericFormGroup<FinancialNode>(
        new FinancialNode()
    );
    @Input() isSalesPriceAdjustmentOpened: boolean = false;

    currencyWithNegativeMaskitoMask = currencyWithNegativeMaskitoOptions;

    constructor() {}

    ngOnInit() {
        this.dealFG.controls.sales_price_adjustment!.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.setAdjustedSalesPrice();
        });
    }

    setAdjustedSalesPrice() {
        const newAdjustedSalesPrice =
            Number(this.dealFG.controls.sales_price!.value) +
            Number(this.dealFG.controls.sales_price_adjustment!.value);
        this.adjustedSalesPrice.controls.amount!.patchValue(newAdjustedSalesPrice, {emitEvent: false});
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
