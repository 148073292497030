import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {firstValueFrom, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {IContact, IBill, ICompanyLocation, IFinancialTransfer} from '@cyberco-nodejs/zipi-typings';
import {FINANCIAL_TRANSFER_TYPE_LABELS, PAYMENT_TERMS} from 'app/local-typings';
import {BillsService} from 'app/services/api/finance/bills.service';
import {MatDialog} from '@angular/material/dialog';
import {ProductMatchingDialogComponent} from '../../common/product-matching-dialog/product-matching-dialog.component';
import {SessionService} from 'app/services/session.service';
import {ShipperContactsService} from '../../../../../services/api/shipper.contacts.service';
import {MatTableDataSource} from '@angular/material/table';
import {DealService} from '@app/services/deal.service';
import {IRelatedDealPayoutInfo} from '@app/modules/finance/types/financial.types';

@Component({
    selector: 'app-bill-for-approve-page',
    templateUrl: 'bill-for-approve-page.component.html',
    styleUrls: ['bill-for-approve-page.component.css']
})
export class BillForApprovePageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    termsMap = new Map<string, string>(<any>PAYMENT_TERMS);

    bill: IBill | undefined;
    companyTitle: string | null = null;
    // set it to boolean(false) only here. if it won't fetch from back - then it will be 'undefined'
    vendorContact: IContact | undefined | boolean = false;

    paymentSettings: boolean;

    billing_address: ICompanyLocation | undefined;

    showJournals: boolean;

    showTransfers: boolean = true;
    transfersDataSource: MatTableDataSource<IRelatedDealPayoutInfo>;
    transferDisplayedColumns: string[] = ['index', 'deal_address', 'product', 'connection_type', 'amount'];
    FINANCIAL_TRANSFER_TYPE_LABELS = FINANCIAL_TRANSFER_TYPE_LABELS;

    constructor(
        private activatedRoute: ActivatedRoute,
        public sessionService: SessionService,
        private billsService: BillsService,
        public dialog: MatDialog,
        public router: Router,
        private contactService: ShipperContactsService,
        private dealService: DealService
    ) {
        this.paymentSettings = false;
        this.showJournals = false;
        this.transfersDataSource = new MatTableDataSource<IRelatedDealPayoutInfo>([]);
    }

    getBillFinancialTransfers() {
        if (this.bill && this.bill.bill_id) {
            this.dealService.getFinancialTransfersByBillId(this.bill.bill_id).then((transfers) => {
                this.transfersDataSource.data = transfers;
            });
        }
    }

    isProcessingPayment() {
        if (this.bill && this.bill.payments && this.bill.payments.length > 0) {
            for (const pay of this.bill.payments) {
                if (pay.status === 'processing') {
                    return true;
                }
            }
        }
        return false;
    }

    createAndConnectInvoiceFromBill() {
        const billsForBulkArray = [];
        if (this.bill && this.bill.payments && this.bill.payments.length > 0) {
            const billsForBulk: {[key: number]: any} = {};
            for (const pay of this.bill.payments) {
                if (pay.source_payment_made && pay.source_payment_made.related_payments) {
                    for (const relatedPay of pay.source_payment_made.related_payments) {
                        if (relatedPay.bill && relatedPay.bill.bill_id) {
                            billsForBulk[relatedPay.bill.bill_id] = relatedPay.bill;
                        }
                    }
                }
            }
            for (const key in billsForBulk) {
                if (billsForBulk.hasOwnProperty(key)) {
                    billsForBulkArray.push(billsForBulk[key]);
                }
            }
        }

        const dialogRef = this.dialog.open(ProductMatchingDialogComponent, {
            maxHeight: '80vh',
            autoFocus: false,
            data: {
                source_document: this.bill,
                source_documents: billsForBulkArray,
                contact: this.bill?.money_receiver_contact
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((matchResult) => {
                this.billsService
                    .approveBills({
                        ...matchResult,
                        source_bill_id: this.bill ? this.bill.bill_id : null
                    })
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((result) => {
                        if (result) {
                            this.router.navigate(['/sales/invoices']);
                        }
                    });
            });
    }

    createAndLinkInvoiceFromBill() {
        const billsForBulkArray = [];
        if (this.bill && this.bill.payments && this.bill.payments.length > 0) {
            const billsForBulk: {[key: number]: any} = {};
            for (const pay of this.bill.payments) {
                if (pay.source_payment_made && pay.source_payment_made.related_payments) {
                    for (const relatedPay of pay.source_payment_made.related_payments) {
                        if (relatedPay.bill && relatedPay.bill.bill_id) {
                            billsForBulk[relatedPay.bill.bill_id] = relatedPay.bill;
                        }
                    }
                }
            }
            for (const key in billsForBulk) {
                if (billsForBulk.hasOwnProperty(key)) {
                    billsForBulkArray.push(billsForBulk[key]);
                }
            }
        }

        const dialogRef = this.dialog.open(ProductMatchingDialogComponent, {
            maxHeight: '80vh',
            autoFocus: false,
            data: {
                source_document: this.bill,
                source_documents: billsForBulkArray,
                contact: this.bill?.money_receiver_contact
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((matchResult) => {
                this.billsService
                    .linkBills({
                        ...matchResult,
                        source_bill_id: this.bill ? this.bill.bill_id : null
                    })
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((result) => {
                        if (result) {
                            this.router.navigate(['/sales/invoices']);
                        }
                    });
            });
    }

    archiveBill() {
        if (this.bill && this.bill.bill_id) {
            this.billsService
                .archiveBill(this.bill.bill_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (result) {
                        this.router.navigate(['/sales/sourcedocuments']);
                    }
                });
        }
    }

    ngOnInit() {
        this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['id']) {
                this.billsService
                    .getBillForApproveById(params['id'])
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((bill) => {
                        this.bill = bill;
                        this.loadContact();
                        this.getBillFinancialTransfers();
                    });
            }
        });

        if (
            this.sessionService.profile &&
            this.sessionService.profile.company &&
            this.sessionService.profile.company.title
        ) {
            this.companyTitle = this.sessionService.profile.company.title;
        }
    }

    async loadContact() {
        if (this.bill && this.bill.owner__company_fk_id) {
            this.vendorContact = await firstValueFrom(
                this.contactService.getContactInMyCompanyByPartnerCompanyId(this.bill.owner__company_fk_id)
            );
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
