import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UsersLicensesComponent} from '../users-licenses.component';
import {Profile} from 'app/models/profile';
import {UsersAndLicencesApiService} from '../users-and-licences.api.service';
import {IGroup, IRole} from '../../../../../typings';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, filter, take, takeUntil} from 'rxjs/operators';
import {trim} from 'lodash-es';
import {GenericFormArray, GenericFormGroup} from '../../../../entites/generic.entity';
import {FetchContactsMust} from '../../../contacts/store/contacts.actions';
import {Store} from '@ngrx/store';
import {ChipNode} from '../../compensation/models/chip-node';
import {GroupApi} from 'app/services/api/group.api';
import {UpdateContactConfirmComponent} from './update-contact-confirm/update-contact-confirm.component';
import {MatDialog} from '@angular/material/dialog';
import {ShipperContactsService} from 'app/services/api/shipper.contacts.service';
import {IContactsState} from '../../../contacts/store/contacts.reducer';
import {ConfirmCreateProfileDialogComponent} from '../../dialogs/confirm-create-profile-dialog.component';
import {IContact} from '@cyberco-nodejs/zipi-typings/contacts.typings';
import {NotificationsService} from 'angular2-notifications';
import {simpleEmailValidatorPattern} from '../../../contacts/store/contact.utilities';

@Component({
    selector: 'app-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy, OnChanges {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeSave: Subject<void> = new Subject();

    PROFILE_TYPE_DEFAULT = Profile.type.default;

    isExistingEmail: boolean = false;
    isValidEmail: boolean = true;
    isSubmitDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    @Input() profile: Profile | undefined | null;
    @Input() allRoles:
        | {
              systemRoles: IRole[];
              companyRoles: IRole[];
          }
        | undefined;
    @Input() companyPermissions: any;
    @Output() userUpdated = new EventEmitter();
    @Output() onDestroy = new EventEmitter();

    allCompanyRoles:
        | {
              systemRoles: IRole[];
              companyRoles: IRole[];
          }
        | undefined;
    allGroupRoles:
        | {
              systemRoles: IRole[];
              companyRoles: IRole[];
          }
        | undefined;

    profileInvisibleRolesIds: number[] = []; // this array contains all invisible roles that profile has
    availableRoles: IRole[] = [];
    availableGroupRoles: IRole[] = [];

    profileFG: GenericFormGroup<Profile> = new GenericFormGroup<Profile>(new Profile());

    selectedGroups: any[] = [];
    selectedGroupsCN: ChipNode[] = [];
    selectedGroupsFA: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);

    selectedDivisions: any[] = [];
    selectedDivisionsCN: ChipNode[] = [];
    selectedDivisionsFA: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);

    closeDivisionsPanelEmitter$: Subject<any> = new Subject();
    closeGroupsPanelEmitter$: Subject<any> = new Subject();

    constructor(
        protected usersAndLicencesApiService: UsersAndLicencesApiService,
        private parentComponent: UsersLicensesComponent,
        private store: Store<IContactsState>,
        protected groupApi: GroupApi,
        public dialog: MatDialog,
        private cs: ShipperContactsService,
        private ntfs: NotificationsService
    ) {}

    changeDivisionRoles(divisionIndex: number, roles: []) {
        this.selectedDivisions[divisionIndex].group_roles_ids = roles;
    }

    ngOnInit(): void {
        if (!this.profile) {
            return;
        }

        if (!this.profile.id) {
            this.profile.is_agent_company_needed = true; // all profiles by default need My Financial company when created
        }

        this.profileFG.patchValue(this.profile);

        // show selected groups
        if (typeof this.profile.company_group === 'undefined') {
            Object.assign(this.profile, {company_group: []});
        }
        this.profile.company_group?.forEach((group: IGroup) => {
            if (group && group.type === 'division') {
                this.selectedDivisions.push(group);
                this.selectedDivisionsCN.push(
                    new ChipNode()
                        .setLabel(group.title!)
                        .setTargetId(group.company_group_id!)
                        .setType('individuals_in_group')
                );
                this.selectedDivisionsFA.patchValue(this.selectedDivisionsCN);
                this.selectedDivisionsFA.updateValueAndValidity();
            } else {
                this.selectedGroups.push(group);
                this.selectedGroupsCN.push(
                    new ChipNode()
                        .setLabel(group.title!)
                        .setTargetId(group.company_group_id!)
                        .setType('individuals_in_group')
                );
                this.selectedGroupsFA.patchValue(this.selectedGroupsCN);
                this.selectedGroupsFA.updateValueAndValidity();
            }
        });

        this.selectedGroupsFA.valueChanges
            .pipe(debounceTime(100), takeUntil(this.unsubscribe))
            .subscribe((chipNodes) => {
                const chipNodesLength = chipNodes.length;
                const groupLength = this.selectedGroups.length;

                if (chipNodesLength < groupLength) {
                    // removing
                    const itemToRemoveIndex = this.selectedGroups.findIndex(
                        (el) => !chipNodes.some((ell: {target_id: number}) => ell.target_id === el.company_group_id)
                    );
                    this.selectedGroups.splice(itemToRemoveIndex, 1);
                } else {
                    // adding
                    this.selectedGroups = [
                        ...this.selectedGroups,
                        {
                            company_group_id: chipNodes[chipNodes.length - 1].target_id,
                            title: chipNodes[chipNodes.length - 1].label
                        }
                    ];
                    this.selectedGroupsCN.push(
                        new ChipNode()
                            .setLabel(chipNodes[chipNodes.length - 1].label)
                            .setTargetId(chipNodes[chipNodes.length - 1].target_id)
                            .setType('individuals_in_group')
                    );
                }
            });

        this.selectedDivisionsFA.valueChanges
            .pipe(debounceTime(100), takeUntil(this.unsubscribe))
            .subscribe((chipNodes) => {
                const chipNodesLength = chipNodes.length;
                const divisionLength = this.selectedDivisions.length;

                if (chipNodesLength < divisionLength) {
                    // removing
                    const itemToRemoveIndex = this.selectedDivisions.findIndex(
                        (el) => !chipNodes.some((ell: {target_id: number}) => ell.target_id === el.company_group_id)
                    );
                    this.selectedDivisions.splice(itemToRemoveIndex, 1);
                } else {
                    // adding
                    this.selectedDivisions = [
                        ...this.selectedDivisions,
                        {
                            company_group_id: chipNodes[chipNodes.length - 1].target_id,
                            title: chipNodes[chipNodes.length - 1].label,
                            group_roles_ids: []
                        }
                    ];
                    this.selectedDivisionsCN.push(
                        new ChipNode()
                            .setLabel(chipNodes[chipNodes.length - 1].label)
                            .setTargetId(chipNodes[chipNodes.length - 1].target_id)
                            .setType('individuals_in_group')
                    );
                }
            });
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('allRoles') && changes.allRoles.currentValue) {
            if (this.allRoles) {
                this.allCompanyRoles = {
                    systemRoles: this.allRoles.systemRoles.filter((role) => role.scope === 'company'),
                    companyRoles: this.allRoles.companyRoles.filter((role) => role.scope === 'company')
                };
                this.availableRoles = this.allCompanyRoles.systemRoles.concat(this.allCompanyRoles.companyRoles);
                this.allGroupRoles = {
                    systemRoles: this.allRoles.systemRoles.filter((role) => role.scope === 'group'),
                    companyRoles: this.allRoles.companyRoles.filter((role) => role.scope === 'group')
                };
                this.availableGroupRoles = this.allGroupRoles.systemRoles.concat(this.allGroupRoles.companyRoles);
                this.allRoles.systemRoles.forEach((role) => {
                    if (!role.visible_for_companies) {
                        if (
                            this.profile &&
                            Array.isArray(this.profile.roles_ids) &&
                            this.profile.roles_ids.includes(Number(role.id))
                        ) {
                            this.profileInvisibleRolesIds.push(Number(role.id));
                        }
                    }
                });
            }
        }
    }

    emailCheck() {
        // check email
        if (this.profile) {
            this.isValidEmail = true;
            if (!this.profile.email || !trim(this.profile.email) || !trim(this.profile.email).length) {
                this.isExistingEmail = false;
            } else if (!simpleEmailValidatorPattern.test(this.profile.email)) {
                this.isValidEmail = false;
                this.isSubmitDisabled.next(true);
            } else {
                // check persons in all contacts
                this.isSubmitDisabled.next(true);
                this.usersAndLicencesApiService
                    .checkProfileEmail({
                        email: trim(this.profile.email),
                        profileId: Number(this.profile.id)
                    })
                    .then((response) => {
                        if (response.result) {
                            this.isExistingEmail = true;
                        } else {
                            this.isExistingEmail = false;
                            this.isSubmitDisabled.next(false);
                        }
                    });
            }
        }
    }

    patchGroupsWithMember(profile: Profile, isCreationMode = false) {
        const allGroups = [...this.selectedDivisions, ...this.selectedGroups].map((group) => {
            group.profile = JSON.parse(JSON.stringify(profile));
            group.email = profile.email;

            return group;
        });

        this.groupApi.patchGroupsWithMember(Number(profile.id), allGroups).then(() => {
            this.usersAndLicencesApiService.getUpdatedProfile(Number(profile.id)).then((updProfile) => {
                this.userUpdated.emit({
                    action: this.parentComponent.EMIT_ACTION_UPDATED,
                    result: updProfile
                });
            });
        });
    }

    save(isSendInvitation: boolean) {
        // close previous observable
        this.unsubscribeSave.next();

        const profile = this.profile;
        if (profile) {
            this.isSubmitDisabled
                .pipe(
                    debounceTime(100), // wait sending emailCheck method
                    take(2), // to avoid unexpected creating or updating contact
                    filter((e) => e === false), // accept only suitable email
                    takeUntil(this.unsubscribeSave)
                )
                .subscribe((res) => {
                    if (profile && profile.email && trim(profile.email) && trim(profile.email).length) {
                        profile.email = trim(profile.email);
                        profile.roles_ids = this.profileInvisibleRolesIds;
                        if (this.profileFG.controls.roles_ids && this.profileFG.controls.roles_ids.value) {
                            profile.roles_ids = profile.roles_ids.concat(this.profileFG.controls.roles_ids.value);
                        }
                        profile.roles =
                            profile.roles_ids.length > 0
                                ? this.availableRoles.filter(
                                      (role) =>
                                          this.profileFG.controls.roles_ids &&
                                          this.profileFG.controls.roles_ids.value &&
                                          this.profileFG.controls.roles_ids.value.includes(role.id)
                                  )
                                : [];

                        const data = profile;
                        // We have to exclude these data from updating, because we do not touch these fields
                        // in this part of app AND we don't want to override them with empty values.
                        delete data.opening_performance_values;
                        delete data.profile_memberships;

                        if (profile.id) {
                            let needContactUpdate: boolean = false;
                            const confirmDialogRef = this.dialog.open(UpdateContactConfirmComponent, {
                                minWidth: 320,
                                minHeight: 160
                            });
                            confirmDialogRef
                                .afterClosed()
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((result) => {
                                    if (!result) {
                                        return;
                                    }
                                    if (result.needContactUpdate) {
                                        needContactUpdate = result.needContactUpdate;
                                    }

                                    // update profile
                                    return this.usersAndLicencesApiService
                                        .update(Number(profile.id), data)
                                        .then(async (profileData) => {
                                            if (profileData) {
                                                if (needContactUpdate) {
                                                    await this.cs
                                                        .updateProfileContactPersonById(Number(profile.id), data)
                                                        .toPromise();
                                                }
                                                // update contacts page after success
                                                this.store.dispatch(new FetchContactsMust());

                                                // patch member groups on update
                                                this.patchGroupsWithMember(profile);
                                            }
                                        });
                                });
                        } else {
                            return this.usersAndLicencesApiService
                                .checkContactProfile(data)
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((response) => {
                                    if (isSendInvitation) {
                                        data.status = 'invited';
                                    } else {
                                        data.status = 'no_access';
                                    }
                                    if (!response.success) {
                                        const confirmDialogRef = this.dialog.open(ConfirmCreateProfileDialogComponent, {
                                            minWidth: 420,
                                            minHeight: 160,
                                            data: {
                                                title: 'Duplicate Record Detected',
                                                message: response.message,
                                                error_details: response.error_details,
                                                existing_contacts: response.existing_contacts,
                                                profile: profile
                                            }
                                        });

                                        confirmDialogRef
                                            .afterClosed()
                                            .pipe(takeUntil(this.unsubscribe))
                                            .subscribe((result) => {
                                                if (!result) {
                                                    return;
                                                } else if (result.email) {
                                                    data.email = result.email;
                                                } else if (result.first_name) {
                                                    data.first_name = result.first_name;
                                                    data.last_name = result.last_name;
                                                } else if (result.contact) {
                                                    if (response.error_details === 'email') {
                                                        data.email = this.getContactData(result.contact, 'email');
                                                    } else if (response.error_details === 'name') {
                                                        data.first_name = this.getContactData(
                                                            result.contact,
                                                            'first_name'
                                                        );
                                                        data.last_name = this.getContactData(
                                                            result.contact,
                                                            'last_name'
                                                        );
                                                    }
                                                    data.contact_fk_id = result.contact.contact_id;
                                                }
                                                this.createProfile(data);
                                            });
                                    } else {
                                        this.createProfile(data);
                                    }
                                });
                        }
                    } else {
                        this.ntfs.warn('Set email');
                        return;
                    }
                });
        }
    }

    createProfile(data: Profile) {
        return this.usersAndLicencesApiService.createProfile(data).then((profile) => {
            // update contacts page after success
            this.store.dispatch(new FetchContactsMust());

            // patch member groups on create
            this.patchGroupsWithMember(profile, true);
        });
    }

    getContactData(contact: IContact, field: string) {
        const person = contact.contact_persons.find((contact_person) => contact_person.type === 'main_person');
        if (person) {
            // @ts-ignore
            return person[field];
        } else if (contact.contact_persons.length) {
            // @ts-ignore
            return contact.contact_persons[0][field];
        }
        return '';
    }

    cancel() {
        this.profile = null;

        this.onDestroy.emit();
    }

    removeDivision(index: number) {
        this.selectedDivisionsCN.splice(index, 1);
        this.selectedDivisionsFA.patchValue(this.selectedDivisionsCN);
        this.selectedDivisionsFA.updateValueAndValidity();
    }

    removeGroup(index: number) {
        this.selectedGroupsCN.splice(index, 1);
        this.selectedGroupsFA.patchValue(this.selectedGroupsCN);
        this.selectedGroupsFA.updateValueAndValidity();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeSave.next();
        this.unsubscribeSave.complete();
        this.userUpdated.complete();
        this.onDestroy.complete();
        this.closeDivisionsPanelEmitter$.complete();
        this.closeGroupsPanelEmitter$.complete();
    }
}
