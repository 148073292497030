import {GenericEntity} from '../../../../entites/generic.entity';
import {MultipleTargetsDealsQueryModel} from '../../../deals/components/deal/common/deal.models';
import {Profile} from '../../../../models/profile';
import {Group} from '../../../../models/group';
import {Company} from '../../../../models/company';
import {DateRangeModel} from './date-range.model';
import {ChipNode} from './chip-node';
import {FinancialElementModel} from './financial-element.model';

export class CompensationProfileModel extends GenericEntity {
    id: number | null = null;
    sort_order: number = 0;
    effective_date_range: DateRangeModel = new DateRangeModel();
    financial_elements: FinancialElementModel[] = [];
    apply_to: ChipNode[] = [];
    title: string | null = null;
    public_title: string | null = null;
    target: string | null = null;
    status: string = CompensationProfileModel.status_set.draft;
    single_profile: boolean = true;
    is_enforced: boolean = true;
    note: string | null = null;
    type: string = CompensationProfileModel.type_set.default;

    deal_filters: MultipleTargetsDealsQueryModel = new MultipleTargetsDealsQueryModel();
    deal_parameters: MultipleTargetsDealsQueryModel = new MultipleTargetsDealsQueryModel();
    calculations_filters: MultipleTargetsDealsQueryModel = new MultipleTargetsDealsQueryModel();

    setType(type: string) {
        this.type = type;
        return this;
    }

    static get helpers() {
        return Object.assign(
            {
                props: Object.getOwnPropertyNames(new this()).reduce((acc, curr) => {
                    // @ts-ignore
                    acc[curr] = curr;
                    return acc;
                }, {}),
                build: {
                    profile: (val: object, fabric: string) => Object.assign(new Profile(), val),
                    company_group: (val: object, fabric: string) => Object.assign(new Group(), val),
                    company: (val: object, fabric: string) => Object.assign(new Company(), val),
                    effective_date_range: (val: object, fabric: string) => Object.assign(new DateRangeModel(), val),
                    financial_elements: (val: object[], fabric: string) =>
                        // @ts-ignore
                        val.map((item) => GenericEntity.FABRIC(FinancialElementModel)[fabric](item)),
                    deal_filters: (val: object, fabric: string) =>
                        Object.assign(new MultipleTargetsDealsQueryModel(), val),
                    calculations_filters: (val: object, fabric: string) =>
                        Object.assign(new MultipleTargetsDealsQueryModel(), val)
                }
            },
            GenericEntity.helpers
        );
    }

    static get target_set() {
        return {
            company: 'company',
            profiles_in_company: 'profiles_in_company',
            groups_in_company: 'groups_in_company',
            company_group: 'company_group',
            profiles_in_group: 'profiles_in_group',
            profile: 'profile'
        };
    }

    static get status_set() {
        return {
            draft: 'draft',
            active: 'active',
            imported: 'imported'
        };
    }

    static get type_set() {
        return {
            default: 'default',
            template: 'template',
            override: 'override'
        };
    }

    /**
     *
     * @returns {number}
     */
    static get override_sort_order_set() {
        return 40000;
    }
}
