<section class="sidebar-body">
    <!------- Header ------->
    <header class="d-flex align-items-center justify-content-between px-3 list-header">
        <!------- Header ------->
        <h3 class="m-0">Reconciliation</h3>

        <div>
            <!------- Status filter ------->
            <mat-form-field class="mt-2">
                <!--                        <mat-label>Status filter</mat-label>-->
                <mat-select [formControl]="statusTypeFC">
                    <mat-option *ngFor="let status of statusTypes" [value]="status.value">
                        {{ status.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!------- Add account ------->
            <button
                mat-raised-button
                class="ml-2"
                rbac
                [rule]="{banking__access: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                [matMenuTriggerFor]="menu"
            >
                Add Account
            </button>
            <mat-menu #menu="matMenu">
                <button
                    mat-menu-item
                    (click)="open(false)"
                    [anyRule]="[{banking__manage_bank_feed_automation: true}]"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                >
                    Bank Account
                </button>
                <button
                    mat-menu-item
                    (click)="open(true)"
                    [anyRule]="[{banking__manage_bank_feed_automation: true}]"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                >
                    Trust Account
                </button>
                <button mat-menu-item (click)="createAccount()">Create Manually</button>
            </mat-menu>
        </div>
    </header>

    <!------- List of banking ------->
    <div class="table-background">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            [matSortActive]="scrollData.sort_column"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="icon">
                <th mat-header-cell *matHeaderCellDef class="w-5"></th>
                <td mat-cell *matCellDef="let element" class="w-5">
                    <mat-icon
                        *ngIf="!element?.activate_feed_from && isAvailableForConnect(element)"
                        class="activate-feed text-muted"
                        matTooltip="Account not connected"
                    >
                        wifi_off
                    </mat-icon>
                    <mat-icon
                        *ngIf="!element?.activate_feed_from && isAvailableForFeed(element)"
                        class="activate-feed feed-activated"
                        matTooltip="{{ getFeedTooltip(element) }}"
                    >
                        wifi_off
                    </mat-icon>
                    <mat-icon
                        *ngIf="element?.activate_feed_from && !element?.isNeedToReLogin && !element?.isNeedToReconnect"
                        class="activate-feed feed-activated"
                        matTooltip="{{ getFeedTooltip(element) }}"
                    >
                        rss_feed
                    </mat-icon>
                    <mat-icon
                        *ngIf="element?.activate_feed_from && (element?.isNeedToReLogin || element?.isNeedToReconnect)"
                        class="activate-feed"
                        style="color: red"
                        matTooltip="{{ getFeedTooltip(element) }}"
                    >
                        rss_feed
                    </mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-30">Name</th>
                <td mat-cell *matCellDef="let element" class="w-30 px-1">
                    <span>{{ element.name }}</span>
                    <span *ngIf="element.status === 'inactive'" class="ml-2 font-weight-bold"> (Deactivated)</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef class="w-35">Description</th>
                <td mat-cell *matCellDef="let element" class="w-35 px-1">
                    {{ element.description }}
                </td>
            </ng-container>

            <ng-container matColumnDef="logo">
                <th mat-header-cell *matHeaderCellDef class="w-15"></th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1">
                    <div
                        class="d-inline-flex justify-content-around align-items-center"
                        [matTooltip]="zipiPayTooltip(element)"
                        matTooltipClass="my-tooltip"
                    >
                        <div *ngIf="element.isZipiFinancialAccount">Money Transfers Enabled</div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef class="w-10">Type</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <ng-container *ngIf="element.type">
                        {{ getType(element.type) }}
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="w-5">Action</th>
                <td mat-cell *matCellDef="let element" class="w-5">
                    <button
                        mat-icon-button
                        (click)="$event.stopPropagation(); $event.preventDefault()"
                        [matMenuTriggerFor]="menu"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button
                            mat-menu-item
                            *ngIf="
                                element.status === 'active' &&
                                (isAvailableForConnect(element) ||
                                    (!element.activate_feed_from && isAvailableForFeed(element)))
                            "
                            (click)="open(element.type === 'trust', element)"
                            [anyRule]="[{banking__manage_bank_feed_automation: true}]"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                        >
                            Start Feed
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="element.activate_feed_from && element.connected_payment_methods.length !== 0"
                            (click)="deactivateFeed(element)"
                            [anyRule]="[{banking__manage_bank_feed_automation: true}]"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                        >
                            Stop Feed
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="element?.status === 'active' && element?.isNeedToReLogin"
                            (click)="reLoginInBankAccount(element)"
                            [anyRule]="[{banking__manage_bank_feed_automation: true}]"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                        >
                            Update Authentication
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="element?.status === 'active' && element?.isNeedToReconnect"
                            (click)="reconnectBankAccount(element)"
                            [anyRule]="[{banking__manage_bank_feed_automation: true}]"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                        >
                            Reconnect Bank Account
                        </button>
                        <button
                            mat-menu-item
                            [disabled]="true"
                            *ngIf="isAvailableForDisconnect(element)"
                            (click)="disconnectFeed(element)"
                            [anyRule]="[{banking__manage_bank_feed_automation: true}]"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                        >
                            Disconnect Feed
                        </button>
                        <button mat-menu-item *ngIf="element.status === 'active'" (click)="deactivateAccount(element)">
                            Deactivate
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="element.status === 'inactive'"
                            (click)="reactivateAccount(element)"
                        >
                            Reactivate
                        </button>
                        <button mat-menu-item *ngIf="element.status === 'inactive'" (click)="deleteAccount(element)">
                            Delete
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [routerLink]="['/banking', row.id, 'transactions']"
                class="zp-table-row"
            ></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator class="custom-paginator" [length]="scrollData.total" [pageSize]="scrollData.total">
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted p-3 mt-5 text-center no-items">
            There are no banking accounts.
        </div>
    </div>
</section>
