import {of, Subject} from 'rxjs';
import {catchError, filter, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CompanyPaymentMethodsService} from 'app/services/api/finance/company-payment-methods.service';
import {TRANSACTION_TYPES} from 'app/local-typings';
import {
    ILedgerAccount,
    IReconciliation,
    ISettings,
    ITransactionExternal,
    ITransactionListItem
} from '@cyberco-nodejs/zipi-typings';
import {TransactionsExternalService} from 'app/services/api/finance/transactions-external.service';
import {UntypedFormControl} from '@angular/forms';
import {ReconciliationsService} from '../../services/reconciliations.service';
import {LedgerAccountService} from 'app/services/api/finance/ledger-accounts.service';
import {ActivateFeedDialogComponent} from '../common/activate-feed-dialog/activate-feed-dialog.component';
import {ConfirmComponent} from '../../../../layouts/confirm/confirm.component';
import {SessionService} from 'app/services/session.service';
import {ProfilesService} from 'app/services/profiles.service';
import * as moment from 'moment';
import {UpdatePlaidCredsByLinkDialogComponent} from '../common/update-plaid-creds-by-link-dialog/update-plaid-creds-by-link-dialog.component';
import {InstitutionStatusDialogComponent} from '../common/institution-status-dialog/institution-status-dialog.component';
import {CurrentProfileSource} from '../../../../services/sources/current-profile.source';
import {BankingSidebarComponent} from '../sidebar/banking-sidebar/banking-sidebar.component';
import {ExternalTransactionsComponent} from '../external-transactions/external-transactions-list/external-transactions.component';
import {InternalTransactionsComponent} from '../internal-transactions/internal-transactions.component';
import {Profile} from 'app/models/profile';
import {AddTransactionDialogComponent} from '../sidebar/add-transaction-dialog/add-transaction-dialog.component';
import {ExpensesService} from '../../../finance/services/expenses.service';
import {PaymentsMadeService} from '../../../finance/services/payments-made.service';
import {PaymentsReceivedService} from '../../../finance/services/payments-received.service';
import {BankingService} from '../../../../services/api/finance/banking.service';
import {FeatureFlagsService} from '../../../feature-flags/feature-flags.service';

export type TransactionFilters = 'not_matched' | 'unreconciled' | 'matched' | 'reconciled' | 'all';
export type ExternalTransactionFilters = 'deleted' | 'not_deleted';

@Component({
    selector: 'app-bank-transactions',
    templateUrl: 'bank-transactions.component.html',
    styleUrls: ['../banking.component.scss', './bank-transactions.component.scss']
})
export class BankTransactionsComponent implements OnInit, OnDestroy {
    @ViewChild('sidebar') sidebar: BankingSidebarComponent | undefined;
    @ViewChild('externalTransactions') externalTransactions: ExternalTransactionsComponent | undefined;
    @ViewChild('internalTransactions') internalTransactions: InternalTransactionsComponent | undefined;

    private unsubscribe: Subject<void> = new Subject();
    public isSidebarOpened: boolean = false;

    ledgerAccountId: number | null = null;
    ledgerAccount: ILedgerAccount | undefined;
    isAvailableForFeed: boolean = false;
    isAvailableForDisconnect: boolean = false;

    selectedTransaction: any = null;
    selectedExternalTransactions: ITransactionExternal[] = [];
    transactionTypes: any = TRANSACTION_TYPES;

    selected = new UntypedFormControl(0);
    externalTransactionIdsForBulk: number[] = [];
    isAllSelected: boolean = false;

    lastReconciliation: IReconciliation | undefined;

    zipiBalance: number | undefined;

    accounts: {ledger_account_id: number; name: string}[] = [];

    isNeedToReLogin: boolean;
    isNeedToReconnect: boolean;

    filterInternalTransactions: {value: TransactionFilters; label: string} = {
        value: 'not_matched',
        label: 'Not Matched'
    };
    filterExternalTransactions: {value: ExternalTransactionFilters; label: string} = {
        value: 'not_deleted',
        label: 'Bank Feed'
    };
    defaultTransactionsFilter: TransactionFilters | undefined = 'not_matched';
    transactionsFilters: {value: TransactionFilters; label: string}[] = [
        {value: 'not_matched', label: 'Not Matched'},
        {value: 'unreconciled', label: 'Not Reconciled'},
        {value: 'matched', label: 'Matched'},
        {value: 'reconciled', label: 'Reconciled'},
        {value: 'all', label: 'All'}
    ];
    externalTransactionsFilters: {value: ExternalTransactionFilters; label: string}[] = [
        {value: 'deleted', label: 'Deleted'},
        {value: 'not_deleted', label: 'Bank Feed'}
    ];

    createNewItemEnabledFlag: boolean = false;

    constructor(
        public router: Router,
        private route: ActivatedRoute,
        private ledgerAccountService: LedgerAccountService,
        private transactionsExternalService: TransactionsExternalService,
        private companyPaymentMethodsService: CompanyPaymentMethodsService,
        private reconciliationsService: ReconciliationsService,
        public dialog: MatDialog,
        private sessionService: SessionService,
        private profilesService: ProfilesService,
        public currentProfileSource: CurrentProfileSource,
        private bankingService: BankingService,
        private expensesService: ExpensesService,
        private paymentsMadeService: PaymentsMadeService,
        private paymentsReceivedService: PaymentsReceivedService,
        protected featureFlagsService: FeatureFlagsService
    ) {
        this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params.tab) {
                switch (params.tab) {
                    case 'bank-feed':
                        this.selected.setValue(0);
                        break;
                    case 'transactions':
                        this.selected.setValue(1);
                        break;
                    case 'reconciliations':
                        this.selected.setValue(2);
                        break;
                    default:
                        this.selected.setValue(0);
                }
            }
        });
        this.isNeedToReLogin = false;
        this.isNeedToReconnect = false;

        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.createNewItemEnabledFlag = this.featureFlagsService.isFeatureEnabled('platform:create_new_item');
            });
    }

    ngOnInit() {
        this.filterInternalTransactions = this.transactionsFilters.find(
            (trFilter) => trFilter.value === this.sessionService.profile?.settings?.internal_transactions_filter
        ) || {value: 'not_matched', label: 'Not Matched'};
        this.filterExternalTransactions = {value: 'not_deleted', label: 'Bank Feed'};
        this.defaultTransactionsFilter = this.sessionService.profile?.settings?.internal_transactions_filter;

        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['id']) {
                this.ledgerAccountId = +params['id'];
                this.getAccount();
            }
        });
    }

    getAccount() {
        this.ledgerAccountService
            .getLedgerAccountById(this.ledgerAccountId as number)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((la) => {
                this.isNeedToReLogin = false;
                this.isNeedToReconnect = false;
                this.ledgerAccount = la;
                this.isAvailableForFeed =
                    (['bank', 'credit_card', 'trust'].includes(la.type) &&
                        la.connected_payment_methods &&
                        la.connected_payment_methods.some(
                            (method) =>
                                method.is_available_for_banking ||
                                ['zipi_financial_balance', 'zipi_financial_cloud_bank'].includes(method.type)
                        )) ||
                    la.system_key === 'zipi_pay';
                this.isAvailableForDisconnect =
                    (la.connected_payment_methods &&
                        la.connected_payment_methods.some(
                            (method) => method.is_available_for_banking || method.type === 'zipi_financial_balance'
                        )) ||
                    false;

                if (
                    this.isAvailableForFeed &&
                    typeof this.ledgerAccount !== 'undefined' &&
                    typeof this.ledgerAccount.connected_payment_methods !== 'undefined'
                ) {
                    const bankingMethod = this.ledgerAccount.connected_payment_methods.find(
                        (method) => method.payment_gateway && method.payment_gateway.type === 'banking'
                    );
                    if (bankingMethod && bankingMethod.plaid_refs.pending_auth_status === 'need_relogin') {
                        this.isNeedToReLogin = true;
                    }
                    if (bankingMethod && bankingMethod.plaid_refs.pending_auth_status === 'need_reconnect') {
                        this.isNeedToReconnect = true;
                    }
                }

                this.setAccountToListOfSettings();
            });

        this.ledgerAccountService
            .getLedgerAccountBalanceById(this.ledgerAccountId as number)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.zipiBalance = data.zipi_balance;
            });
    }

    activateFeed() {
        const dialogRef = this.dialog.open(ActivateFeedDialogComponent, {
            width: '500px',
            autoFocus: false
        });

        if (typeof this.ledgerAccount === 'undefined' || typeof this.ledgerAccount.ledger_account_id === 'undefined') {
            return;
        }

        dialogRef.componentInstance.ledgerAccount = this.ledgerAccount;

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((date) => {
                if (
                    date &&
                    typeof this.ledgerAccount !== 'undefined' &&
                    typeof this.ledgerAccount.ledger_account_id !== 'undefined'
                ) {
                    this.ledgerAccountService
                        .updateActivateFeedDate(this.ledgerAccount.ledger_account_id, {
                            activate_feed_from: date
                        })
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((res) => this.getAccount());
                }
            });
    }

    disconnectFeed() {
        const messages = ['This action will disconnect any currently connected feeds.'];

        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            data: {
                title: 'Disconnect Feed',
                messages: messages,
                buttonOkMessage: 'Confirm'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() =>
                this.ledgerAccountService
                    .disconnectFeed(this.ledgerAccountId as number)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(() => {
                        this.getAccount();
                        // if (typeof this.externalTransactions !== 'undefined') {
                        //     this.externalTransactions.reset();
                        // }
                    })
            );
    }

    deactivateFeed() {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            data: {
                title: 'Deactivate Account Feed',
                message: 'Account feed will be deactivated'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {
                if (
                    typeof this.ledgerAccount === 'undefined' ||
                    typeof this.ledgerAccount.ledger_account_id === 'undefined'
                ) {
                    return;
                }

                this.ledgerAccountService
                    .updateActivateFeedDate(this.ledgerAccount.ledger_account_id, {
                        activate_feed_from: null
                    })
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((res) => {
                        this.getAccount();
                    });
            });
    }

    loadLastReconcile() {
        this.reconciliationsService
            .getLastAccountReconciliation(this.ledgerAccountId as number)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.lastReconciliation = data;
            });
    }

    redirectToImport() {
        this.router.navigate([`/banking/${this.ledgerAccountId}/import`]);
    }

    reLoginInBankAccount() {
        if (
            typeof this.ledgerAccount === 'undefined' ||
            typeof this.ledgerAccount.connected_payment_methods === 'undefined'
        ) {
            return;
        }

        const bankingMethod = this.ledgerAccount.connected_payment_methods.find(
            (method) => method.payment_gateway && method.payment_gateway.type === 'banking'
        );
        if (!bankingMethod) {
            return;
        }
        const dialogRef = this.dialog.open(UpdatePlaidCredsByLinkDialogComponent, {
            panelClass: 'hide-dialog-container',
            data: {
                method: bankingMethod
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {
                if (ok) {
                    this.companyPaymentMethodsService
                        .resetPlaidStatus(bankingMethod.payment_method_id as number)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((result) => {
                            this.getAccount();
                        });
                }
            });
    }

    showInstitutionInfo(ledgerAccount: ILedgerAccount) {
        if (
            typeof this.ledgerAccount === 'undefined' ||
            typeof this.ledgerAccount.connected_payment_methods === 'undefined'
        ) {
            return;
        }

        const bankingMethod = this.ledgerAccount.connected_payment_methods.find(
            (method) => method.payment_gateway && method.payment_gateway.type === 'banking'
        );
        if (typeof bankingMethod === 'undefined') {
            return;
        }

        this.companyPaymentMethodsService
            .getInstitutionInfo(bankingMethod.payment_method_id as number)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                // if (result && result.name) {
                const dialogRef = this.dialog.open(InstitutionStatusDialogComponent, {
                    data: {
                        institutionInfo: result
                    }
                });
                // }
            });
    }

    fetchBankTransactions() {
        this.transactionsExternalService
            .fetchBankTransactions(this.ledgerAccountId as number)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                if (typeof this.externalTransactions !== 'undefined') {
                    this.externalTransactions.reset();
                }
                this.getAccount();
            });
    }

    addTransaction(type: {value: string; label: string}) {
        this.deselectTransaction();

        if (typeof this.sidebar !== 'undefined' && !this.createNewItemEnabledFlag) {
            this.sidebar.openAddTransactionSidebar(type);
        } else if (this.createNewItemEnabledFlag) {
            const dialogRef = this.dialog.open(AddTransactionDialogComponent, {
                minWidth: 320,
                data: {
                    transactionType: type,
                    ledgerAccountId: this.ledgerAccountId
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((d) => !!d),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((data) => {
                    switch (type.value) {
                        case 'expense':
                            this.expensesService
                                .createExpense({
                                    expense: data,
                                    vendor_credit: null
                                })
                                .pipe(
                                    catchError(() => of(null)),
                                    takeUntil(this.unsubscribe)
                                )
                                .subscribe();

                            break;
                        case 'vendor_payment':
                            this.paymentsMadeService
                                .createPaymentMade(data)
                                .pipe(
                                    catchError(() => of(null)),
                                    takeUntil(this.unsubscribe)
                                )
                                .subscribe();

                            break;
                        case 'customer_payment':
                            this.paymentsReceivedService
                                .createPaymentReceived(data)
                                .pipe(
                                    catchError(() => of(null)),
                                    takeUntil(this.unsubscribe)
                                )
                                .subscribe();

                            break;
                        default:
                            this.bankingService
                                .createAdditionalTransaction({
                                    transaction: data,
                                    type: type.value
                                })
                                .pipe(
                                    catchError(() => of(null)),
                                    takeUntil(this.unsubscribe)
                                )
                                .subscribe();
                    }
                });
        }
    }

    toggleExternalTransaction(transaction: ITransactionExternal) {
        // we shouldn't open sidebar for deleted items
        if (transaction.deleted_at) {
            return;
        }
        const isAlreadySelected = this.selectedExternalTransactions.findIndex(
            (selectedTransaction) => selectedTransaction.transaction_external_id === transaction.transaction_external_id
        );
        if (isAlreadySelected === -1) {
            this.selectedExternalTransactions.push(transaction);
        } else {
            this.selectedExternalTransactions.splice(isAlreadySelected, 1);
        }
        if (this.selectedExternalTransactions.length === 0 && typeof this.sidebar !== 'undefined') {
            return this.sidebar.close();
        }

        if (typeof this.sidebar !== 'undefined') {
            this.sidebar.openMatchingSidebar(this.selectedExternalTransactions);
        }
    }

    toggleInternalTransaction(transaction: ITransactionListItem) {
        if (
            this.selectedTransaction &&
            this.selectedTransaction.transaction_id &&
            this.selectedTransaction.transaction_id === transaction.transaction_id &&
            typeof this.sidebar !== 'undefined'
        ) {
            return this.sidebar.close();
        }

        this.selectedTransaction = transaction;
        if (typeof this.sidebar !== 'undefined') {
            this.sidebar.openInternalTransactionSidebar(transaction);
        }
    }

    deselectTransaction() {
        this.selectedTransaction = null;
        this.selectedExternalTransactions = [];
    }

    reconcileAccount() {
        if (this.lastReconciliation && this.lastReconciliation.status === 'in_progress') {
            return;
        }

        this.router.navigate(['/banking', this.ledgerAccountId, 'reconciliations', 'create']);
    }

    changeTab(event: number) {
        this.selected.setValue(event);
        if (typeof this.sidebar !== 'undefined') {
            this.sidebar.close();
        }
    }

    filterTransactions(trFilter: {value: TransactionFilters; label: string}) {
        this.filterInternalTransactions = trFilter;
    }

    filterTransactionsExternal(trFilter: {value: ExternalTransactionFilters; label: string}) {
        this.filterExternalTransactions = trFilter;
    }

    setDefaultFilter(defaultFilter: TransactionFilters) {
        if (this.sessionService.profile && this.sessionService.profile.settings) {
            this.sessionService.profile.settings.internal_transactions_filter = defaultFilter;
        }
        this.defaultTransactionsFilter = defaultFilter;

        const id = this.sessionService.profile?.id as number;
        const settings = this.sessionService.profile?.settings;
        this.profilesService
            .updateProfileSettings(id, settings as ISettings)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response: {result: Profile}) => {
                if (response) {
                    this.currentProfileSource.triggers.changeRequesterProfile.next(response.result);
                }
            });
    }

    setAccountToListOfSettings() {
        // check if account is active and not in the list already
        if (
            typeof this.ledgerAccount !== 'undefined' &&
            (this.ledgerAccount.status !== 'active' ||
                typeof this.sessionService.profile?.settings?.bank_accounts_list?.find(
                    (account) => account.ledger_account_id === this.ledgerAccountId
                ) !== 'undefined')
        ) {
            this.accounts = this.sessionService.profile?.settings?.bank_accounts_list || [];
            return;
        }

        // get last 10 elements
        const list = this.sessionService.profile?.settings?.bank_accounts_list || [];
        list.push({ledger_account_id: this.ledgerAccountId as number, name: this.ledgerAccount?.name || ''});
        const newList = list.slice(Math.max(list.length - 10, 0));
        const settings = Object.assign({}, this.sessionService.profile?.settings, {
            bank_accounts_list: newList
        });

        // update profile in DB
        this.profilesService
            .updateProfileSettings(this.sessionService.profile?.id as number, settings)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                // update current profile
                this.sessionService.setSettings = {
                    bank_accounts_list: settings.bank_accounts_list
                };
            });

        this.accounts = this.sessionService.profile?.settings?.bank_accounts_list || [];
    }

    getFeedTooltip(element: ILedgerAccount) {
        if (this.isNeedToReLogin) {
            return `You must update your Authentication Credentials.`;
        }
        if (this.isNeedToReconnect) {
            return `You must reconnect Bank Account.`;
        }
        if (element.activate_feed_from) {
            return `Feed activated from ${moment(element.activate_feed_from, 'YYYYMMDD').format('MMM DD, YYYY')}`;
        }

        return 'Feed is inactive.';
    }

    bulkSelect(ids: number[] = []) {
        this.externalTransactionIdsForBulk = ids;
        this.isAllSelected = false;
    }
    bulkSelectAll() {
        this.externalTransactionIdsForBulk = [];
        this.isAllSelected = true;
    }

    bulkDelete() {
        if (typeof this.sidebar !== 'undefined') {
            this.sidebar.close();
        }
        this.selectedTransaction = null;
        if (typeof this.externalTransactions !== 'undefined') {
            this.externalTransactions.bulkDeleteTransactions(false);
        }
    }

    bulkRestore() {
        if (typeof this.sidebar !== 'undefined') {
            this.sidebar.close();
        }
        this.selectedTransaction = null;
        if (typeof this.externalTransactions !== 'undefined') {
            this.externalTransactions.bulkRestoreTransactions(false);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
