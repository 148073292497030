import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {IFinanceState} from '../../../store/finance.reducer';
import {selectLedgerAccounts, selectProducts} from 'app/store/root.selectors';
import {FetchLedgerAccounts, FetchProducts} from '../../../store/finance.actions';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ILedgerAccount, IProduct} from '@cyberco-nodejs/zipi-typings';
import {RbacService} from '../../../../rbac/rbac.service';
import {Router} from '@angular/router';
import {IScrollData} from 'app/models/scroll-data';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-products-page',
    templateUrl: 'products-page.component.html',
    styleUrls: ['products-page.component.scss', '../../../../../../assets/infinite-scroll-table.scss']
})
export class ProductsPageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    ledgerAccounts: ILedgerAccount[] = [];

    scrollData: IScrollData = {
        total: 0
    };

    dataSource: MatTableDataSource<IProduct>;
    displayedColumns = ['name', 'price', 'description', 'ledger_account_name', 'action'];

    constructor(
        private store: Store<IFinanceState>,
        protected rbacService: RbacService,
        public router: Router
    ) {
        this.dataSource = new MatTableDataSource<IProduct>([]);
    }

    ngOnInit() {
        this.rbacService.isAllowed({company_settings__manage_products: true}).then((can) => {
            if (!can) {
                this.router.navigate(['/company', 'finance', 'journals']);
            }
        });
        this.store.dispatch(new FetchProducts());
        this.store.dispatch(new FetchLedgerAccounts());
        this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe)).subscribe((products) => {
            this.dataSource.data = [...products];
            this.scrollData.total = products.length;
        });
        this.store
            .pipe(select(selectLedgerAccounts))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.ledgerAccounts = data;
            });
    }

    getLedgerAccountName(ledgerAccountId: number): string {
        const account = this.ledgerAccounts.find((a) => a.ledger_account_id === ledgerAccountId);

        if (account) {
            return account.name;
        }
        return '';
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
