import {Action} from '@ngrx/store';
import {IInvoice, IRecurringInvoice, IProduct, ILedgerAccount, IExpense, IBill} from '@cyberco-nodejs/zipi-typings';

export enum FinanceActionTypes {
    FETCH_INVOICES = '[FINANCE_ACTION] FETCH_INVOICES',
    FETCH_INVOICES_MUST = '[FINANCE_ACTION] FETCH_INVOICES_MUST',
    FETCH_INVOICES_SUCCESS = '[FINANCE_ACTION] FETCH_INVOICES_SUCCESS',

    FETCH_BILLS = '[FINANCE_ACTION] FETCH_BILLS',
    FETCH_BILLS_MUST = '[FINANCE_ACTION] FETCH_BILLS_MUST',
    FETCH_BILLS_SUCCESS = '[FINANCE_ACTION] FETCH_BILLS_SUCCESS',

    CREATE_RECURRING_INVOICE = '[FINANCE_ACTION] CREATE_RECURRING_INVOICE',
    CREATE_RECURRING_INVOICE_SUCCESS = '[FINANCE_ACTION] CREATE_RECURRING_INVOICE_SUCCESS',

    CREATE_TRANSFER = '[FINANCE_ACTION] CREATE_TRANSFER',
    CREATE_TRANSFER_SUCCESS = '[FINANCE_ACTION] CREATE_TRANSFER_SUCCESS',
    UPDATE_RECURRING_INVOICE = '[FINANCE_ACTION] UPDATE_RECURRING_INVOICE',
    UPDATE_RECURRING_INVOICE_SUCCESS = '[FINANCE_ACTION] UPDATE_RECURRING_INVOICE_SUCCESS',
    DELETE_RECURRING_INVOICE = '[FINANCE_ACTION] DELETE_RECURRING_INVOICE',
    DELETE_RECURRING_INVOICE_SUCCESS = '[FINANCE_ACTION] DELETE_RECURRING_INVOICE_SUCCESS',

    FETCH_EXPENSES = '[FINANCE_ACTION] FETCH_EXPENSES',
    FETCH_EXPENSES_MUST = '[FINANCE_ACTION] FETCH_EXPENSES_MUST',
    FETCH_EXPENSES_SUCCESS = '[FINANCE_ACTION] FETCH_EXPENSES_SUCCESS',
    CREATE_EXPENSES = '[FINANCE_ACTION] CREATE_EXPENSES',
    CREATE_EXPENSES_SUCCESS = '[FINANCE_ACTION] CREATE_EXPENSES_SUCCESS',
    DELETE_EXPENSES = '[FINANCE_ACTION] DELETE_EXPENSES',
    DELETE_EXPENSES_SUCCESS = '[FINANCE_ACTION] DELETE_EXPENSES_SUCCESS',

    FETCH_PRODUCTS = '[FINANCE_ACTION] FETCH_PRODUCTS',
    FETCH_PRODUCTS_MUST = '[FINANCE_ACTION] FETCH_PRODUCTS_MUST',
    FETCH_PRODUCTS_SUCCESS = '[FINANCE_ACTION] FETCH_PRODUCTS_SUCCESS',
    CREATE_PRODUCT = '[FINANCE_ACTION] CREATE_PRODUCT',
    CREATE_PRODUCT_SUCCESS = '[FINANCE_ACTION] CREATE_PRODUCT_SUCCESS',
    UPDATE_PRODUCT = '[FINANCE_ACTION] UPDATE_PRODUCT',
    UPDATE_PRODUCT_SUCCESS = '[FINANCE_ACTION] UPDATE_PRODUCT_SUCCESS',
    DELETE_PRODUCT = '[FINANCE_ACTION] DELETE_PRODUCT',
    DELETE_PRODUCT_SUCCESS = '[FINANCE_ACTION] DELETE_PRODUCT_SUCCESS',

    FETCH_LEDGER_ACCOUNTS = '[FINANCE_ACTION] FETCH_LEDGER_ACCOUNTS',
    FETCH_LEDGER_ACCOUNTS_MUST = '[FINANCE_ACTION] FETCH_LEDGER_ACCOUNTS_MUST',
    FETCH_LEDGER_ACCOUNTS_SUCCESS = '[FINANCE_ACTION] FETCH_LEDGER_ACCOUNTS_SUCCESS',
    CREATE_LEDGER_ACCOUNT = '[FINANCE_ACTION] CREATE_LEDGER_ACCOUNT',
    CREATE_LEDGER_ACCOUNT_SUCCESS = '[FINANCE_ACTION] CREATE_LEDGER_ACCOUNT_SUCCESS',
    UPDATE_LEDGER_ACCOUNT = '[FINANCE_ACTION] UPDATE_LEDGER_ACCOUNT',
    UPDATE_LEDGER_ACCOUNT_SUCCESS = '[FINANCE_ACTION] UPDATE_LEDGER_ACCOUNT_SUCCESS',
    DELETE_LEDGER_ACCOUNT = '[FINANCE_ACTION] DELETE_LEDGER_ACCOUNT',
    DELETE_LEDGER_ACCOUNT_SUCCESS = '[FINANCE_ACTION] DELETE_LEDGER_ACCOUNT_SUCCESS',

    CREATE_OPENING_BALANCE = '[FINANCE_ACTION] CREATE_OPENING_BALANCE',
    CREATE_OPENING_BALANCE_SUCCESS = '[FINANCE_ACTION] CREATE_OPENING_BALANCE_SUCCESS',
    UPDATE_OPENING_BALANCE = '[FINANCE_ACTION] UPDATE_OPENING_BALANCE',
    UPDATE_OPENING_BALANCE_SUCCESS = '[FINANCE_ACTION] UPDATE_OPENING_BALANCE_SUCCESS',
    DELETE_OPENING_BALANCE = '[FINANCE_ACTION] DELETE_OPENING_BALANCE',
    DELETE_OPENING_BALANCE_SUCCESS = '[FINANCE_ACTION] DELETE_OPENING_BALANCE_SUCCESS'
}

export class FetchInvoices implements Action {
    readonly type: string = FinanceActionTypes.FETCH_INVOICES;
    constructor(public payload?: any) {}
}

export class FetchInvoicesMust implements Action {
    readonly type: string = FinanceActionTypes.FETCH_INVOICES_MUST;
    constructor(public payload?: any) {}
}

export class FetchInvoicesSuccess implements Action {
    readonly type: string = FinanceActionTypes.FETCH_INVOICES_SUCCESS;

    constructor(public payload?: IInvoice[]) {}
}

export class FetchBills implements Action {
    readonly type: string = FinanceActionTypes.FETCH_BILLS;
    constructor(public payload?: any) {}
}

export class FetchBillsMust implements Action {
    readonly type: string = FinanceActionTypes.FETCH_BILLS_MUST;
    constructor(public payload?: any) {}
}

export class FetchBillsSuccess implements Action {
    readonly type: string = FinanceActionTypes.FETCH_BILLS_SUCCESS;

    constructor(public payload?: IBill[]) {}
}

export class CreateRecurringInvoice implements Action {
    readonly type: string = FinanceActionTypes.CREATE_RECURRING_INVOICE;

    constructor(public payload?: IRecurringInvoice) {}
}

export class CreateRecurringInvoiceSuccess implements Action {
    readonly type: string = FinanceActionTypes.CREATE_RECURRING_INVOICE_SUCCESS;

    constructor(public payload?: IRecurringInvoice) {}
}

export class CreateTransfer implements Action {
    readonly type: string = FinanceActionTypes.CREATE_TRANSFER;

    constructor(public payload?: IInvoice) {}
}

export class CreateTransferSuccess implements Action {
    readonly type: string = FinanceActionTypes.CREATE_TRANSFER_SUCCESS;

    constructor(public payload?: IInvoice) {}
}

export class UpdateRecurringInvoice implements Action {
    readonly type: string = FinanceActionTypes.UPDATE_RECURRING_INVOICE;

    constructor(public payload?: IRecurringInvoice) {}
}

export class UpdateRecurringInvoiceSuccess implements Action {
    readonly type: string = FinanceActionTypes.UPDATE_RECURRING_INVOICE_SUCCESS;

    constructor(public payload?: {ok: boolean; invoice: IRecurringInvoice}) {}
}

export class DeleteRecurringInvoice implements Action {
    readonly type: string = FinanceActionTypes.DELETE_RECURRING_INVOICE;

    constructor(public payload?: number) {}
}

export class DeleteRecurringInvoiceSuccess implements Action {
    readonly type: string = FinanceActionTypes.DELETE_RECURRING_INVOICE_SUCCESS;

    constructor(public payload?: number) {}
}

export class FetchProducts implements Action {
    readonly type: string = FinanceActionTypes.FETCH_PRODUCTS;
    constructor(public payload?: any) {}
}

export class FetchProductsMust implements Action {
    readonly type: string = FinanceActionTypes.FETCH_PRODUCTS_MUST;
    constructor(public payload?: any) {}
}

export class FetchProductsSuccess implements Action {
    readonly type: string = FinanceActionTypes.FETCH_PRODUCTS_SUCCESS;

    constructor(public payload?: IProduct[]) {}
}

export class CreateProduct implements Action {
    readonly type: string = FinanceActionTypes.CREATE_PRODUCT;

    constructor(public payload?: IProduct) {}
}

export class CreateProductSuccess implements Action {
    readonly type: string = FinanceActionTypes.CREATE_PRODUCT_SUCCESS;

    constructor(public payload?: IProduct) {}
}

export class UpdateProduct implements Action {
    readonly type: string = FinanceActionTypes.UPDATE_PRODUCT;

    constructor(public payload?: IProduct) {}
}

export class UpdateProductSuccess implements Action {
    readonly type: string = FinanceActionTypes.UPDATE_PRODUCT_SUCCESS;

    constructor(public payload?: {ok: boolean; product: IProduct}) {}
}

export class DeleteProduct implements Action {
    readonly type: string = FinanceActionTypes.DELETE_PRODUCT;

    constructor(public payload?: number) {}
}

export class DeleteProductSuccess implements Action {
    readonly type: string = FinanceActionTypes.DELETE_PRODUCT_SUCCESS;

    constructor(public payload?: number) {}
}

export class FetchLedgerAccounts implements Action {
    readonly type: string = FinanceActionTypes.FETCH_LEDGER_ACCOUNTS;
    constructor(public payload?: any) {}
}

export class FetchLedgerAccountsMust implements Action {
    readonly type: string = FinanceActionTypes.FETCH_LEDGER_ACCOUNTS_MUST;
    constructor(public payload?: any) {}
}

export class FetchLedgerAccountsSuccess implements Action {
    readonly type: string = FinanceActionTypes.FETCH_LEDGER_ACCOUNTS_SUCCESS;

    constructor(public payload?: ILedgerAccount[]) {}
}

export class CreateLedgerAccount implements Action {
    readonly type: string = FinanceActionTypes.CREATE_LEDGER_ACCOUNT;

    constructor(public payload?: ILedgerAccount) {}
}

export class CreateLedgerAccountSuccess implements Action {
    readonly type: string = FinanceActionTypes.CREATE_LEDGER_ACCOUNT_SUCCESS;

    constructor(public payload?: ILedgerAccount) {}
}

export class UpdateLedgerAccount implements Action {
    readonly type: string = FinanceActionTypes.UPDATE_LEDGER_ACCOUNT;

    constructor(public payload?: ILedgerAccount) {}
}

export class UpdateLedgerAccountSuccess implements Action {
    readonly type: string = FinanceActionTypes.UPDATE_LEDGER_ACCOUNT_SUCCESS;

    constructor(public payload?: {ok: boolean; account: ILedgerAccount}) {}
}

export class DeleteLedgerAccount implements Action {
    readonly type: string = FinanceActionTypes.DELETE_LEDGER_ACCOUNT;

    constructor(public payload?: number) {}
}

export class DeleteLedgerAccountSuccess implements Action {
    readonly type: string = FinanceActionTypes.DELETE_LEDGER_ACCOUNT_SUCCESS;

    constructor(public payload?: number) {}
}

export class CreateOpeningBalance implements Action {
    readonly type: string = FinanceActionTypes.CREATE_OPENING_BALANCE;

    constructor(public payload?: {date: number; items: any}) {}
}

export class CreateOpeningBalanceSuccess implements Action {
    readonly type: string = FinanceActionTypes.CREATE_OPENING_BALANCE_SUCCESS;

    constructor(public payload?: any) {}
}

export class UpdateOpeningBalance implements Action {
    readonly type: string = FinanceActionTypes.UPDATE_OPENING_BALANCE;

    constructor(public payload?: {date: number; items: any}) {}
}

export class UpdateOpeningBalanceSuccess implements Action {
    readonly type: string = FinanceActionTypes.UPDATE_OPENING_BALANCE_SUCCESS;

    constructor(public payload?: any) {}
}

export class DeleteOpeningBalance implements Action {
    readonly type: string = FinanceActionTypes.DELETE_OPENING_BALANCE;
    constructor(public payload?: any) {}
}

export class DeleteOpeningBalanceSuccess implements Action {
    readonly type: string = FinanceActionTypes.DELETE_OPENING_BALANCE_SUCCESS;
    constructor(public payload?: any) {}
}

export type FinanceActions =
    | FetchInvoices
    | FetchInvoicesSuccess
    | FetchBills
    | FetchBillsSuccess
    | CreateRecurringInvoice
    | CreateRecurringInvoiceSuccess
    | UpdateRecurringInvoice
    | UpdateRecurringInvoiceSuccess
    | DeleteRecurringInvoice
    | DeleteRecurringInvoiceSuccess
    | CreateTransfer
    | CreateTransferSuccess
    | FetchProducts
    | FetchProductsMust
    | FetchProductsSuccess
    | CreateProduct
    | CreateProductSuccess
    | UpdateProduct
    | UpdateProductSuccess
    | DeleteProduct
    | DeleteProductSuccess
    | FetchLedgerAccounts
    | FetchLedgerAccountsMust
    | FetchLedgerAccountsSuccess
    | CreateLedgerAccount
    | CreateLedgerAccountSuccess
    | UpdateLedgerAccount
    | UpdateLedgerAccountSuccess
    | DeleteLedgerAccount
    | DeleteLedgerAccountSuccess
    | CreateOpeningBalance
    | CreateOpeningBalanceSuccess
    | UpdateOpeningBalance
    | UpdateOpeningBalanceSuccess
    | DeleteOpeningBalance
    | DeleteOpeningBalanceSuccess;
