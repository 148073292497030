<div *ngIf="!isLoaded" class="loader-wrapper">
    <mat-spinner></mat-spinner>
</div>
<div *ngIf="isLoaded" class="pt-3 pl-3">
    <div *ngIf="reports.length === 0" class="text-center mt-5">
        No reports yet. Use the Create Report button to get started.
    </div>
    <app-infinite-scroll *ngIf="reports.length" (scrolled)="doShowMore()" class="d-flex flex-wrap">
        <mat-card *ngFor="let report of reports" class="m-3 p-4 card-container">
            <div>
                <span class="report-type">{{ report.typeTitle | fromSnakeCase }}</span>
            </div>
            <h4 class="report-title mb-3">
                {{ report.title }}
            </h4>
            <div>
                <span class="report-caption">Created on: {{ report.created_at | date: 'longDate' }}</span>
            </div>
            <div>
                <span class="report-caption">Last updated: {{ report.updated_at | date: 'longDate' }}</span>
            </div>

            <div class="d-flex justify-content-between align-items-center mt-4">
                <a class="bold" href="#" (click)="$event.preventDefault(); toggleEditReportSidebar(true, report.id)"
                    >Edit Report</a
                >
                <button type="button" mat-stroked-button color="primary" (click)="loadReportData($event, report)">
                    Run
                </button>
            </div>
        </mat-card>
    </app-infinite-scroll>
</div>
