import {merge as observableMerge, Observable, Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AppInitSource} from './app-init.source';
import {Profile} from '../../models/profile';

@Injectable()
export class AvailableProfilesSource {
    private triggers = {
        initApp: new Subject<Profile[]>(),
        loadAvailableProfiles: new Subject<Profile[]>()
    };

    private events = {
        availableProfiles: <Observable<Profile[]>>(
            observableMerge(this.triggers.initApp, this.triggers.loadAvailableProfiles)
        )
    };

    private currentAvailableProfiles: Profile[] | null = null;

    public get availableProfiles(): Observable<Profile[]> {
        if (this.currentAvailableProfiles) {
            return this.events.availableProfiles.pipe(startWith(this.currentAvailableProfiles));
        }
        return this.events.availableProfiles;
    }

    constructor(
        protected router: Router,
        protected appInitSource: AppInitSource
        // protected userService: UserService
    ) {
        this.availableProfiles.subscribe((profiles) => {
            this.currentAvailableProfiles = profiles;
        });
        this.appInitSource.appInit.subscribe((appInitResponse) => {
            this.triggers.initApp.next(appInitResponse.availableProfiles);
        });
    }

    // loadAvailableProfiles() {
    //     this.userService.getUserProfiles()
    //         .then(profiles => {
    //             this.triggers.loadAvailableProfiles.next(profiles);
    //         });
    // }
}
