import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-multi-selection-toolbar',
    templateUrl: './multi-selection-toolbar.component.html',
    styleUrls: ['./multi-selection-toolbar.component.scss']
})
export class MultiSelectionToolbarComponent implements OnInit {
    @Input() numberSelected: number = 0;
    @Input() selectionName: string = '';
    @Input() toolbarMinWidth: string = '60%';
    @Input() hideSelectionDivider: boolean = false;

    constructor() {}

    ngOnInit(): void {}
}
