import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Deal} from '../../../../../models/deal';
import {GenericFormGroup} from '../../../../../entites/generic.entity';
import {SubType} from '../../../../../models/sub-type';
import {CommonListOfFieldsDialogComponent} from './common-list-of-fields-dialog/common-list-of-fields-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ProfilesService} from '../../../../../services/profiles.service';
import {SubSource} from '../../../../../models/sub-source';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MembershipOrganization} from '../../../../../models/membership-organization';
import {ICustomDealField, ICustomDealFieldValue} from '@cyberco-nodejs/zipi-typings/index';
import {CustomFieldCreationDialogComponent} from './custom-field-creation-dialog/custom-field-creation-dialog.component';
import {SessionService} from '../../../../../services/session.service';
import {select, Store} from '@ngrx/store';
import {ICompanyWideState} from '../../../../../store/company-wide/company-wide.reducer';
import {
    selectMembershipOrganizations,
    selectSubSources,
    selectSubTypes
} from '../../../../../store/company-wide/company-wide.selectors';
import {UpdateSubSources, UpdateSubTypes} from '../../../../../store/company-wide/company-wide.actions';
import {SourceOfBusiness} from '../../../../../models/source-of-business';
import {
    numberWithNegativeMaskitoOptions,
    numberWithNegativeWithoutDecimalMaskitoOptions
} from '../../../../../utilities/maskito';

@Component({
    selector: 'app-deal-additional-fields',
    styleUrls: ['additional-fields.component.scss'],
    templateUrl: 'additional-fields.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DealAdditionalFieldsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() dealFormGroup: GenericFormGroup<Deal> = new GenericFormGroup(new Deal(), 'change');

    public DEAL = Deal;

    subTypes: SubType[] = [];
    subSources: SubSource[] = [];
    membershipOrganizations: MembershipOrganization[] = [];

    selectedCustomFields: Array<number | null> = [];
    listOfCustomFields: {value: number; label: string; field: any}[] = [];
    hiddenCustomFieldValues: ICustomDealFieldValue[] = [];
    booleanOptions = [
        {label: 'Select', value: null},
        {label: 'Yes', value: true},
        {label: 'No', value: false}
    ];

    numberMask = numberWithNegativeWithoutDecimalMaskitoOptions;

    floatMask = numberWithNegativeMaskitoOptions;

    object_keys(obj: object) {
        return Object.keys(obj);
    }

    constructor(
        private dialog: MatDialog,
        protected profileService: ProfilesService,
        private sessionService: SessionService,
        private store: Store<ICompanyWideState>
    ) {}

    ngOnInit(): void {
        this.store.pipe(select(selectSubTypes), takeUntil(this.unsubscribe)).subscribe((subTypes) => {
            this.subTypes = subTypes;
        });

        this.store.pipe(select(selectSubSources), takeUntil(this.unsubscribe)).subscribe((subSources) => {
            this.subSources = subSources;
        });

        this.store
            .pipe(select(selectMembershipOrganizations), takeUntil(this.unsubscribe))
            .subscribe((organizations) => {
                if (organizations) {
                    this.membershipOrganizations = organizations.filter(
                        (org) => org.type === MembershipOrganization.type_SET.MLS
                    );
                }
            });

        this.dealFormGroup.controls
            .custom_deal_fields!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((changes: ICustomDealField[]) => {
                this.transformCustomDeals(changes);
            });

        this.dealFormGroup.controls
            .source_of_business!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((value: string | undefined) => {
                if (value?.toLowerCase() !== 'other') {
                    this.dealFormGroup.controls.source_other!.setValue(null, {emitEvent: false});
                }
            });
    }

    openDialog(type: string) {
        let initialList: Array<SourceOfBusiness | SubType | SubSource> = [];
        if (type === 'SubType') {
            initialList = this.subTypes;
        } else if (type === 'SubSource') {
            initialList = this.subSources;
        }

        const dialogRef = this.dialog.open(CommonListOfFieldsDialogComponent, {
            minWidth: 500,
            data: {
                type: type,
                list: initialList
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((list) => {
                if (!list) {
                    // dialog was just closed, no need to save anything
                    return;
                }

                if (type === 'SubType') {
                    this.store.dispatch(new UpdateSubTypes(list));
                } else if (type === 'SubSource') {
                    this.store.dispatch(new UpdateSubSources(list));
                }
            });
    }

    openCustomDialog(idx: number | null = null) {
        let field = null;
        if (typeof idx === 'number') {
            // @ts-ignore
            field = this.dealFormGroup.controls.custom_deal_fields!.getRawValue()[idx];
        }

        const dialogRef = this.dialog.open(CustomFieldCreationDialogComponent, {
            data: {
                field: field,
                companyId: this.sessionService.profile!.company_fk_id
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((list: ICustomDealField[]) => {
                if (!list) {
                    // dialog was just closed, no need to save anything
                    return;
                }

                // Update custom fields array
                this.dealFormGroup.controls.custom_deal_fields!.patchValue(list);
                this.dealFormGroup.controls.custom_deal_fields!.updateValueAndValidity();
                this.transformCustomDeals(list);
                this.hiddenCustomFieldValues = [];
            });
    }

    transformLabel(label: string): string {
        return label[0].toUpperCase() + label.split('_').join(' ').slice(1);
    }

    toggleCustomField(field: ICustomDealField) {
        // Get custom field values list
        const values = this.dealFormGroup.controls.custom_deal_field_values!.getRawValue();

        // Check if field already on page - return
        if (values.find((f) => f.custom_deal_field_fk_id === field.custom_deal_field_id)) {
            return;
        }

        // Try to find in hidden values
        const foundValue = this.hiddenCustomFieldValues.find(
            (f) => f.custom_deal_field_fk_id! === field.custom_deal_field_id
        );

        // Set value in selected custom field
        if (foundValue) {
            values.push(foundValue);
        } else {
            values.push({
                id: null,
                custom_deal_field_value_id: null,
                custom_deal_field_fk_id: field.custom_deal_field_id!,
                owner__company_fk_id: null,
                deal_fk_id: null,
                value: null,
                custom_deal_field: field
            });
        }

        // Update custom field values list
        this.dealFormGroup.controls.custom_deal_field_values!.patchValue(values);
    }

    /**
     * Delete and save deleted value
     *
     * @param fieldFG {FormGroup}
     */
    delete(fieldFG: GenericFormGroup<ICustomDealField> | undefined) {
        if (typeof fieldFG === 'undefined') {
            return;
        }

        // Get custom field values list
        const values = this.dealFormGroup.controls.custom_deal_field_values!.getRawValue();
        const field = fieldFG.getRawValue();

        // Find index removing value
        const i = values
            .map((value, index) => {
                if (value.custom_deal_field_fk_id === field.custom_deal_field_id) {
                    return index;
                }
            })
            .filter((index) => typeof index !== 'undefined')[0];

        // Get find value
        const foundValue = this.hiddenCustomFieldValues.find(
            (f) => f.custom_deal_field_fk_id === field.custom_deal_field_id
        );

        // Save or update deleted value in hidden group
        if (foundValue) {
            foundValue.value = values[i!].value;
        } else {
            this.hiddenCustomFieldValues.push(Object.assign({}, values[i!]));
        }

        // Remove from custom values
        values.splice(i!, 1);

        // Update custom field values list
        this.dealFormGroup.controls.custom_deal_field_values!.patchValue(values);
    }

    transformCustomDeals(customFields: ICustomDealField[]) {
        // Fields list
        this.listOfCustomFields = customFields.reduce((acc: {value: number; label: string; field: any}[], curr) => {
            acc.push({
                value: curr.custom_deal_field_id!,
                label: this.transformLabel(curr.label)!,
                field: curr
            });

            return acc;
        }, []);

        // List with selected values
        this.selectedCustomFields = this.dealFormGroup.controls
            .custom_deal_field_values!.getRawValue()
            .map((value) => value.custom_deal_field_fk_id);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
