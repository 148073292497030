import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {AuthService} from 'app/services/auth.service';
import {Router} from '@angular/router';
import {UserService} from 'app/services/user.service';
import {SessionService} from 'app/services/session.service';
import {AddCompanyDialogComponent, AddCompanyDialogPayload} from './add-company.dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {CompaniesService} from 'app/services/companies.service';
import {AddExistingCompanyDialogComponent} from './add-existing-company/add-existing-company.dialog.component';
import {ProfilesService} from 'app/services/profiles.service';
import {Profile} from 'app/models/profile';
import {AvailableProfilesSource} from 'app/services/sources/available-profiles.source';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {Store} from '@ngrx/store';
import {RbacService} from 'app/modules/rbac/rbac.service';
import {GroupsSource} from 'app/services/sources/groups.source';
import {Group} from 'app/models/group';
import {MatMenuTrigger} from '@angular/material/menu';
import {takeUntil} from 'rxjs/operators';
import {AppInitSource} from 'app/services/sources/app-init.source';
import {Subject} from 'rxjs';
import {
    FetchMembershipOrganizations,
    FetchSubEntities,
    FetchTagCategories,
    FetchWildcards
} from 'app/store/company-wide/company-wide.actions';
import {CompanyPermissionsSource} from 'app/services/sources/companyPermissions.source';
import {AvailableRolesSource} from 'app/services/sources/available-roles.source';
import {CleanContacts, FetchContactsMust} from '../../modules/contacts/store/contacts.actions';

class ExtendedProfile extends Profile {
    allowSubMenus?: boolean;
    child_level?: number;
}

@Component({
    selector: 'app-profiles-sub-menu',
    styles: [
        `
            .app-profiles-sub-menu-btn-selected {
                background-color: lightgray;
            }
        `
    ],
    template: `
        <mat-menu #childMenu="matMenu">
            <span *ngFor="let child of items">
                <span *ngIf="child.children && child.children.length > 0">
                    <button
                        mat-menu-item
                        *ngIf="menu.childMenu"
                        (click)="selectCompanyGroupId(child.id)"
                        [ngClass]="
                            selectedCompanyGroupId && +selectedCompanyGroupId === child?.id
                                ? 'app-profiles-sub-menu-btn-selected'
                                : ''
                        "
                        [matMenuTriggerFor]="menu.childMenu"
                    >
                        <span>
                            {{ child.title }}
                        </span>
                    </button>
                    <app-profiles-sub-menu
                        #menu
                        (selectCompanyGroupIdEvent)="selectCompanyGroupId($event)"
                        [selectedCompanyGroupId]="selectedCompanyGroupId"
                        [items]="child.children"
                    >
                    </app-profiles-sub-menu>
                </span>
                <span *ngIf="!child.children || child.children.length === 0">
                    <button
                        mat-menu-item
                        [ngClass]="
                            selectedCompanyGroupId && +selectedCompanyGroupId === child.id
                                ? 'app-profiles-sub-menu-btn-selected'
                                : ''
                        "
                        (click)="selectCompanyGroupId(child.id)"
                    >
                        <span>
                            {{ child.title }}
                        </span>
                    </button>
                </span>
            </span>
        </mat-menu>
    `
})
export class ProfilesSubMenuComponent implements OnDestroy {
    @Input() items: any[] = [];
    @Input() selectedCompanyGroupId: number | undefined;
    @ViewChild('childMenu', {static: true}) public childMenu: any;
    @Output() selectCompanyGroupIdEvent: EventEmitter<number | void> = new EventEmitter<number | void>();
    public GROUP = Group;
    constructor() {}

    selectCompanyGroupId(id: number | void) {
        this.selectCompanyGroupIdEvent.emit(id);
    }

    ngOnDestroy(): void {
        this.selectCompanyGroupIdEvent.complete();
    }
}

@Component({
    selector: 'app-profiles-menu',
    styles: [
        `
            .twoLineMenuItem {
                line-height: 20px;
            }
            .bigMenuSubItem {
                display: inline-block;
            }
            .smallMenuSubItem {
                opacity: 0.7;
                font-size: 12px;
            }
            .selectProfile {
                display: block;
                padding: 17px;
                line-height: 14px;
            }
            .groupTitle {
                position: absolute;
                left: 0;
                width: 100%;
                font-size: 10px;
            }
            .btn__trial {
                color: #fff;
                background-color: #039be5;
                margin-right: 10px;
            }
            ::ng-deep .profiles-menu.mat-menu-panel {
                max-width: fit-content;
            }
        `
    ],
    template: `
        <button class="selectProfile" mat-button [matMenuTriggerFor]="appMenu">
            <span>{{ currProfileTitle }}</span>
            <span class="material-icons">arrow_drop_down</span><br />
            <span class="groupTitle">{{ currentGroup?.title }}</span>
        </button>
        <!--<span rbac [rule]="{manage_account: false}" [denyMethod]="'display-none'"-->
        <!--*ngIf="sessionService.profile?.company?.subscription_status === 'trial'">-->
        <!--<button class="btn__trial" mat-raised-button disabled>TRIAL</button>-->
        <!--</span>-->
        <!--<span rbac [rule]="{manage_account: true}" [denyMethod]="'display-none'">-->
        <!--<span>{{getSubscriptionTitle(sessionService.profile?.company)}}</span>-->
        <!--</span>-->
        <!--<span rbac [rule]="{manage_account: true}" [denyMethod]="'display-none'"-->
        <!--*ngIf="sessionService.profile?.company?.subscription_status === 'trial'">-->
        <!--<button class="btn__trial" mat-raised-button [routerLink]="['/company', 'subscription']">TRIAL</button>-->
        <!--</span>-->
        <!--<span rbac [rule]="{manage_account: true}" [denyMethod]="'display-none'"-->
        <!--*ngIf="!sessionService.profile?.company?.subscription_status">-->
        <!--<button color="warn" mat-raised-button [routerLink]="['/company', 'subscription']">SETUP BILLING</button>-->
        <!--</span>-->
        <mat-menu #appMenu="matMenu" class="profiles-menu">
            <ng-container *ngFor="let profile of availableProfiles">
                <!-- WITH SUB-MENU GROUPS-->
                <div
                    class="twoLineMenuItem"
                    mat-menu-item
                    [ngStyle]="{'padding-left': (profile?.child_level || 0) * 16 + 'px'}"
                    [matMenuTriggerFor]="menu.childMenu"
                    *ngIf="profile?.company && profile.allowSubMenus"
                    (click)="setCurrentProfile(profile)"
                >
                    <div class="bigMenuSubItem">
                        <span>{{ profile?.company?.title }}</span>
                        <mat-icon
                            *ngIf="isSubscriptionActive(profile?.company?.zoho_subscription)"
                            style="font-size: 14px; width: 14px; height: 14px; margin-right: 3px; color: #FF5722;"
                            >warning</mat-icon
                        >
                        <mat-icon
                            *ngIf="isProfileLinked(profile?.user?.id)"
                            title="Linked Profile"
                            style="font-size: 14px; width: 14px; height: 14px; margin-right: 3px; margin-left: 3px;"
                            >link</mat-icon
                        >
                    </div>
                    <br />
                    <div class="smallMenuSubItem">
                        <span>{{ profile.email }}</span>
                    </div>

                    <app-profiles-sub-menu
                        #menu
                        (selectCompanyGroupIdEvent)="selectCompanyGroupId($event)"
                        [selectedCompanyGroupId]="$any(selectedCompanyGroupId)"
                        [items]="profile?.groups || []"
                    >
                    </app-profiles-sub-menu>
                </div>

                <!-- WITHOUT SUB-MENU GROUPS-->
                <div
                    class="twoLineMenuItem"
                    mat-menu-item
                    [ngStyle]="{'padding-left': (profile.child_level || 0) * 16 + 'px'}"
                    *ngIf="profile?.company && !profile.allowSubMenus"
                    (click)="setCurrentProfile(profile)"
                >
                    <div class="bigMenuSubItem">
                        <span>{{ profile?.company?.title }}</span>
                        <mat-icon
                            *ngIf="isSubscriptionActive(profile?.company?.zoho_subscription)"
                            style="font-size: 14px; width: 14px; height: 14px; margin-right: 3px; color: #FF5722;"
                            >warning</mat-icon
                        >
                        <mat-icon
                            *ngIf="isProfileLinked(profile?.user?.id)"
                            title="Linked Profile"
                            style="font-size: 14px; width: 14px; height: 14px; margin-right: 3px; margin-left: 3px;"
                            >link</mat-icon
                        >
                    </div>
                    <br />
                    <div class="smallMenuSubItem">
                        <span>{{ profile.email }}</span>
                    </div>
                </div>

                <div mat-menu-item *ngIf="isGlobalViewVisible && !profile.company" (click)="setCurrentProfile(profile)">
                    Global View
                </div>
            </ng-container>
            <!--<div mat-menu-item [matMenuTriggerFor]="addCompany">+ Add Company</div>-->
            <div mat-menu-item class="text-right" (click)="addExistingCompany()">
                <mat-icon class="mr-1">link</mat-icon>Link Company
            </div>
        </mat-menu>
        <!--<mat-menu #addCompany>-->
        <!--<div mat-menu-item (click)="addNewCompany()">New</div>-->
        <!--<div mat-menu-item (click)="addExistingCompany()">Existing</div>-->
        <!--</mat-menu>-->
    `
})
export class ProfilesMenuComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChildren(MatMenuTrigger) public triggers: QueryList<MatMenuTrigger> | undefined;
    public isGlobalViewVisible: boolean = false;
    public availableProfiles: ExtendedProfile[] = [];
    public currProfileTitle: string = 'Global View';
    public divisionTitle: string | undefined = '';
    public currentGroup: Group | undefined;
    public currentProfile: Profile | null = null;

    public selectedCompanyGroupId: string | null | undefined = '';

    private availableGroups: any[] = [];
    private profiles: ExtendedProfile[] = [];

    constructor(
        public authService: AuthService,
        protected usersService: UserService,
        public dialog: MatDialog,
        public sessionService: SessionService,
        protected router: Router,
        public companiesService: CompaniesService,
        protected profilesService: ProfilesService,
        protected availableProfilesSource: AvailableProfilesSource,
        protected groupsSource: GroupsSource,
        protected currentProfileSource: CurrentProfileSource,
        private store: Store<any>,
        protected rbacService: RbacService,
        protected appInitSource: AppInitSource,
        private companyPermissionsSource: CompanyPermissionsSource,
        private availableRolesSource: AvailableRolesSource
    ) {}

    async ngOnInit() {
        this.currentProfileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe((currProfile) => {
            this.currentProfile = currProfile;
            if (currProfile && currProfile.company) {
                this.currProfileTitle = this.currentProfile.company?.title || 'Global View';
            } else {
                this.currProfileTitle = 'Global View';
            }
        });

        this.groupsSource.source.pipe(takeUntil(this.unsubscribe)).subscribe((groups) => {
            this.availableGroups = groups;
            const lastGroupId = localStorage.getItem('current_company_group_id');
            if (lastGroupId) {
                this.currentGroup = this.availableGroups.find((group) => group.id === parseInt(lastGroupId, 10));
                this.selectedCompanyGroupId = lastGroupId;
            }
            this.currProfileTitle = this.currentProfile?.company?.title || 'Global View';
        });

        this.availableProfilesSource.availableProfiles
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((profilesList: ExtendedProfile[]) => {
                this.availableProfiles = [];
                this.profiles = profilesList;

                // fill 1st level using companies without parent
                profilesList.forEach((profile) => {
                    if (profile.type === 'global') {
                        this.availableProfiles.push(profile);
                    } else {
                        if (profile.company && !profile.company.parent__company_fk_id) {
                            profile.child_level = 1;
                            this.availableProfiles.push(profile);
                            this.addChildProfile(profile.company.id, profile.child_level);
                        }
                    }
                });

                // do 2nd loop to fill the rest
                profilesList.forEach((profile) => {
                    const isAlreadyadded = this.availableProfiles.find(
                        (availableProfile) => availableProfile.id === profile.id
                    );
                    if (!isAlreadyadded) {
                        profile.child_level = 1;
                        this.availableProfiles.push(profile);
                        this.addChildProfile(profile.company?.id, profile.child_level);
                    }
                });

                this.availableProfiles.sort((a: ExtendedProfile, b: ExtendedProfile) => {
                    if (a.type === 'global' && b.type !== 'global') {
                        return -1;
                    }
                    if (a.type !== 'global' && b.type === 'global') {
                        return 1;
                    }
                    return 0;
                });

                if (this.availableProfiles.length < 2) {
                    console.info('need logout!');
                    // this.authService.logout();
                }
                // don't show profile if user leaved company
                this.availableProfiles = this.availableProfiles.filter((profile) => profile.status !== 'no_access');

                // Comment: we should show "Global View" only if person have access to several 'zipi_access_broker' companies
                // Comment from Alex: Global view is disabled because this feature has not been tested enough.
                // this.isGlobalViewVisible = this.availableProfiles
                //     .filter(profile => (profile.company
                //         && profile.company.zoho_subscription.some(subscription => subscription.plan === 'zipi_access_broker')
                //         )
                //     ).length > 2;

                this.isGlobalViewVisible = false;

                this.availableProfiles.map((profile) => {
                    if (this.currentProfile && profile.id === this.currentProfile.id) {
                        profile.groups = this.currentProfile.groups;
                        profile.groups?.forEach((group) => {
                            if (profile.company?.company_group?.id === group.id) {
                                profile.groups = group.children;
                            }
                        });
                        profile.allowSubMenus = !!profile.groups?.length;
                    } else {
                        profile.groups = [];
                        profile.allowSubMenus = false;
                    }
                    return profile;
                });
            });
    }

    addChildProfile(parentCompanyId: number | undefined, childLevel: number = 1) {
        const childProfiles = this.profiles.filter((profile) => {
            return (
                profile.company &&
                profile.company.parent__company_fk_id === parentCompanyId &&
                !this.availableProfiles.find((availableProfile) => availableProfile.id === profile.id)
            );
        });

        childProfiles.forEach((childProfile) => {
            const newChildLevel = childLevel + 1;
            childProfile.child_level = newChildLevel;
            this.availableProfiles.push(childProfile);
            // if (childProfile.company.parent__company_fk_id) {
            //     this.addChildProfile(childProfile.company.parent__company_fk_id, newChildLevel);
            // }
        });
    }

    async selectCompanyGroupId(id: number | string | void) {
        if (Number(localStorage.getItem('current_company_group_id')) !== id) {
            this.currentGroup = this.availableGroups.find((group) => group.id === parseInt(String(id), 10));
            localStorage.setItem('current_company_group_id', String(id));
            this.selectedCompanyGroupId = String(id);
            this.closeMenus();
            this.appInitSource.resetLastAppInitResponse();
            await this.authService.initApp();
            await this.router.navigate(['/default-page']);
        } else {
            this.closeMenus();
        }
    }

    addNewCompany() {
        const dialogRef = this.dialog.open(AddCompanyDialogComponent);
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((closeEvent: AddCompanyDialogPayload) => {
                if (!closeEvent) {
                    // dialog was just closed, no need to save anything
                    return;
                }
                if (closeEvent && closeEvent.company && !closeEvent.company.id) {
                    this.companiesService
                        .create(closeEvent.company, closeEvent.is_paid_by_parent)
                        .then((newProfile: Profile) => {
                            this.setCurrentProfile(newProfile);
                        });
                }
            });
    }

    addExistingCompany() {
        this.dialog.open(AddExistingCompanyDialogComponent);
    }

    async setCurrentProfile(profile: Profile) {
        if (this.currentProfile && profile.id !== this.currentProfile.id) {
            this.store.dispatch({type: 'RESET'});
            await this.authService.loadProfile(profile.id as number);
            await this.router.navigate(['/default-page']);
            // // reload page to prevent bugs
            // setTimeout(() => location.reload());

            // load once
            this.store.dispatch(new FetchSubEntities());
            this.store.dispatch(new FetchMembershipOrganizations());
            this.store.dispatch(new FetchTagCategories());
            this.store.dispatch(new FetchWildcards());
            // load contacts only if user has permissions
            if (profile.permissions?.contacts__access) {
                this.store.dispatch(new FetchContactsMust());
            } else {
                this.store.dispatch(new CleanContacts());
            }
            this.availableRolesSource.load();
            this.companyPermissionsSource.load();
        } else {
            localStorage.removeItem('current_company_group_id');
            this.currentGroup = undefined;
            this.selectedCompanyGroupId = undefined;
            this.currProfileTitle = this.currentProfile?.company?.title || 'Global View';
            this.divisionTitle = undefined;
            this.closeMenus();
            this.appInitSource.resetLastAppInitResponse();
            await this.authService.initApp();
            await this.router.navigate(['/default-page']);
        }
    }

    closeMenus() {
        if (typeof this.triggers !== 'undefined') {
            this.triggers.forEach((trigger) => {
                trigger.closeMenu();
            });
        }
    }

    isSubscriptionActive(subscriptions: any[] | undefined) {
        return subscriptions?.every((sub) => !['live', 'trial'].includes(sub.zoho_subscription_status));
    }

    isProfileLinked(userId: number | undefined) {
        return !this.availableProfiles.find(
            (profile) => userId && profile.type === 'global' && profile?.user?.id === userId
        );
    }

    // getSubscriptionTitle (company) {
    //     switch (company.subscription_plan) {
    //         case 'plan_essential-monthly':
    //             return 'Essential (monthly)';
    //         case 'plan_essential-annual':
    //             return 'Essential (annual)';
    //         case 'plan_business-monthly':
    //             return 'Business (monthly)';
    //         case 'plan_business-annual':
    //             return 'Business (annual)';
    //         case 'plan_enterprise':
    //             return 'Enterprise';
    //     }
    // }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
