import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {Widget} from '../../../models/widget';
import {SessionService} from '../../../services/session.service';
import {Profile} from '../../../models/profile';

const BOARDS_URL_PREFIX = '/boards/';
const WIDGETS_URL_PREFIX = '/widgets/';

@Injectable()
export class WidgetService {
    constructor(
        protected requester: ServiceRequester,
        public sessionService: SessionService
    ) {}

    public getData(boardId: number, widgetId: number) {
        return this.requester.makeMsCallGlobalAllowed(
            BOARDS_URL_PREFIX + boardId + WIDGETS_URL_PREFIX + widgetId + '/data',
            'GET'
        );
    }

    public getPreviewData(boardId: number, data: any) {
        return this.requester.makeMsCallGlobalAllowed(
            BOARDS_URL_PREFIX + boardId + WIDGETS_URL_PREFIX + 'data',
            'POST',
            data
        );
    }

    public updateData(boardId: number, widgetId: number) {
        return this.requester.makeMsCallGlobalAllowed('/widgets-update-cache/' + boardId + '/' + widgetId, 'PUT');
    }

    public list(boardId: number): Promise<Widget[]> {
        return this.requester.makeMsCallGlobalAllowed(BOARDS_URL_PREFIX + boardId + '/widgets/', 'GET').then((list) => {
            let profiles: any[] = [];
            if (this.sessionService.profile) {
                if (this.sessionService.profile.type === Profile.type.global) {
                    profiles = this.sessionService.availableProfiles.filter(
                        (profile) => profile.type === Profile.type.default
                    );
                } else {
                    profiles.push(this.sessionService.profile);
                }
            }
            return list.map((item: any) => Object.assign(new Widget(), item).setupBackendSettings(profiles));
        });
    }

    public create(boardId: number, data: object = {}): Promise<Widget> {
        return this.requester.makeMsCallGlobalAllowed(BOARDS_URL_PREFIX + boardId + '/widgets/', 'POST', data);
    }

    public update(boardId: number, widgetId: number, data: any): Promise<Widget> {
        return this.requester.makeMsCallGlobalAllowed(
            BOARDS_URL_PREFIX + boardId + '/widgets/' + widgetId,
            'PUT',
            data
        );
    }

    public batchUpdate(boardId: number, widgets: Widget[]) {
        return this.requester.makeMsCallGlobalAllowed(BOARDS_URL_PREFIX + boardId + '/widgets/batch', 'PUT', {
            widgets: widgets
        });
    }

    public softDelete(boardId: number, widgetId: number) {
        return this.requester.makeMsCallGlobalAllowed(BOARDS_URL_PREFIX + boardId + '/widgets/' + widgetId, 'DELETE');
    }
}
