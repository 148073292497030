import {merge as observableMerge, Observable, Subject} from 'rxjs';

import {startWith} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SystemNotification} from '../../models/user-notification';

@Injectable()
export class SystemNotificationSource {
    public triggers = {
        reloadNotificationsNotificationEvent: new Subject<any>(),
        reloadUiModsSystemNotification: new Subject<SystemNotification>(),
        dismissSystemNotification: new Subject<SystemNotification>(),
        reloadDataListNotification: new Subject<SystemNotification>()
    };

    private events = {
        reloadNotificationsNotificationEvent: <Observable<any>>(
            observableMerge(this.triggers.reloadNotificationsNotificationEvent)
        ),

        reloadUiModsSystemNotification: <Observable<SystemNotification>>(
            observableMerge(this.triggers.reloadUiModsSystemNotification)
        ),

        newSystemNotification: <Observable<SystemNotification>>(
            observableMerge(this.triggers.reloadUiModsSystemNotification)
        ),

        dismissSystemNotification: <Observable<SystemNotification>>(
            observableMerge(this.triggers.dismissSystemNotification)
        ),

        reloadDataListNotification: <Observable<SystemNotification>>(
            observableMerge(this.triggers.reloadDataListNotification)
        )
    };

    public currentReloadNotificationsNotificationEvent: SystemNotification | null = null;
    public currentDismissSystemNotification: SystemNotification | null = null;
    public currentReloadUiModsSystemNotification: SystemNotification | null = null;
    public currentReloadDataListNotification: SystemNotification | null = null;

    public get dismissSystemNotification(): Observable<SystemNotification> {
        if (this.currentDismissSystemNotification) {
            return this.events.dismissSystemNotification.pipe(startWith(this.currentDismissSystemNotification));
        }
        return this.events.dismissSystemNotification;
    }

    public get reloadNotificationsNotificationEvent(): Observable<any> {
        if (this.currentReloadUiModsSystemNotification) {
            return this.events.reloadNotificationsNotificationEvent.pipe(
                startWith(this.currentReloadNotificationsNotificationEvent)
            );
        }
        if (this.currentReloadDataListNotification) {
            return this.events.reloadNotificationsNotificationEvent.pipe(
                startWith(this.currentReloadDataListNotification)
            );
        }
        return this.events.reloadNotificationsNotificationEvent;
    }

    public get reloadUiModsSystemNotification(): Observable<SystemNotification> {
        if (this.currentReloadUiModsSystemNotification) {
            return this.events.reloadUiModsSystemNotification.pipe(
                startWith(this.currentReloadUiModsSystemNotification)
            );
        }
        return this.events.reloadUiModsSystemNotification;
    }

    public get reloadDataListNotification(): Observable<SystemNotification> {
        if (this.currentReloadDataListNotification) {
            return this.events.reloadDataListNotification.pipe(startWith(this.currentReloadDataListNotification));
        }
        return this.events.reloadDataListNotification;
    }

    constructor(protected router: Router) {
        this.reloadNotificationsNotificationEvent.subscribe((next) => {
            this.currentReloadNotificationsNotificationEvent = next;
        });
        this.dismissSystemNotification.subscribe((next) => {
            this.currentDismissSystemNotification = next;
        });
        this.reloadUiModsSystemNotification.subscribe((next) => {
            this.currentReloadUiModsSystemNotification = next;
        });
        this.reloadDataListNotification.subscribe((next) => {
            this.currentReloadDataListNotification = next;
        });
    }
}
