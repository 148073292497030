<div>
    <mat-card *ngIf="reportData.length">
        <div class="report-table w-100">
            <div class="sticky">
                <div class="item-row header-row">
                    <div class="item-date">Date</div>
                    <div class="item-name">Transaction Type</div>
                    <div class="item-name">Number</div>
                    <div class="item-name">Name</div>
                    <div class="item-name">Office</div>
                    <div class="item-value text-right">Amount</div>
                    <div class="item-value text-right">Balance</div>
                </div>
            </div>
            <ng-container>
                <div *ngFor="let journal of reportData">
                    <div>
                        <strong>
                            <i>{{ journal.la_name }}</i>
                        </strong>
                    </div>
                    <div class="journal-data">
                        <div class="item-row">
                            <div class="beginning-balance-item">Beginning Balance</div>
                            <div class="item-name"></div>
                            <div class="item-name"></div>
                            <div class="item-value"></div>
                            <div
                                class="item-value amount-text"
                                [ngClass]="{'negative-value': journal.beginning_balance < 0}"
                            >
                                <span>{{ journal.beginning_balance | reportFormatted: 'financial' }}</span>
                            </div>
                        </div>
                        <ng-container *ngIf="journal.transactions && journal.transactions.length">
                            <div class="item-row" *ngFor="let transaction of journal.transactions">
                                <div class="item-date">
                                    {{ transaction.journal_date }}
                                    <span *ngIf="transaction.is_reverted">**</span>
                                </div>
                                <div class="item-name">
                                    <span *ngIf="entityRouterListByJournalType[transaction.payment_type]">
                                        <a
                                            [routerLink]="[
                                                entityRouterListByJournalType[transaction.payment_type],
                                                transaction.payment_entity_id
                                            ]"
                                        >
                                            {{ transaction.type_title }}
                                        </a>
                                    </span>
                                    <span *ngIf="!entityRouterListByJournalType[transaction.payment_type]">
                                        {{ transaction.type_title }}
                                    </span>
                                </div>
                                <div class="item-name">
                                    <span *ngIf="entityRouterListByJournalType[transaction.payment_type]">
                                        <a
                                            [routerLink]="[
                                                entityRouterListByJournalType[transaction.payment_type],
                                                transaction.payment_entity_id
                                            ]"
                                        >
                                            {{ transaction.number }}
                                        </a>
                                    </span>
                                </div>
                                <div class="item-name">{{ transaction.contact_name }}</div>
                                <div class="item-name">{{ transaction.office_name }}</div>
                                <div
                                    class="item-value amount-text"
                                    [ngClass]="{'negative-value': transaction.amount < 0}"
                                >
                                    <span>{{ transaction.amount | reportFormatted: 'financial' }}</span>
                                </div>
                                <div
                                    class="item-value amount-text"
                                    [ngClass]="{'negative-value': transaction.balance < 0}"
                                >
                                    <span>{{ transaction.balance | reportFormatted: 'financial' }}</span>
                                </div>
                            </div>
                        </ng-container>
                        <hr />
                        <div class="item-row total-section">
                            <div class="item-total">Total for {{ journal.la_name }}</div>
                            <div class="item-value amount-text" [ngClass]="{'negative-value': journal.total < 0}">
                                <span>{{ journal.total | reportFormatted: 'financial' }}</span>
                            </div>
                            <div class="item-value"></div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <p class="m-2">*Amount is displayed in your base currency <span class="badge-secondary">USD</span></p>
        <p class="m-2">**Display reverted transaction</p>
    </mat-card>

    <mat-card class="preview-body" *ngIf="!reportData.length">
        <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
    </mat-card>
</div>
