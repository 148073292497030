import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-finance-page',
    templateUrl: 'finance-page.component.html',
    styleUrls: ['finance-page.component.css']
})
export class FinancePageComponent implements OnInit {
    constructor(public router: Router) {}

    ngOnInit() {}
}
