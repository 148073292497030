import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {map, filter, takeUntil, tap} from 'rxjs/operators';
import {assign} from 'lodash-es';
import * as moment from 'moment';
import {PaymentsReceivedService} from '../../../services/payments-received.service';
import {IContact, IInvoice, ILoan, IPaymentReceivedMadeRequestObject} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {INTEREST_PERIOD} from 'app/local-typings';
import {ManualMarkAsPaidConfirmDialogComponent} from '../../invoice/manual-mark-as-paid-confirm-dialog/manual-mark-as-paid-confirm-dialog.component';
import {NotificationsServiceZipi} from '../../../../notifications/notifications.service';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {ChargeLoanCustomerDialogComponent} from '../charge-loan-customer-dialog/charge-loan-customer-dialog.component';
import {CompanyGatewayService} from 'app/services/api/finance/company-gateway.service';
import {PaymentMethodsService} from '../../../../profile/services/payment-methods.service';
import {LoansService} from '../../../../../services/api/finance/loans.service';

@Component({
    selector: 'app-advance-page',
    templateUrl: 'advance-page.component.html',
    styleUrls: ['advance-page.component.css']
})
export class AdvancePageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    dataSource: MatTableDataSource<IInvoice>;

    displayedRelatedColumns = ['invoice_id', 'invoice_date', 'summary_status', 'total_amount', 'balance'];

    advance:
        | (ILoan & {contacts: IContact[]; interestPeriod: {value: string; label: string}; interestLimit: number})
        | undefined;
    interestPeriod = INTEREST_PERIOD;
    showInvoices: boolean;
    payToPaymentMethodIds: Array<number> = [];

    newInvoice: IInvoice | undefined;

    constructor(
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private paymentsReceivedService: PaymentsReceivedService,
        public router: Router,
        private notificationServiceZipi: NotificationsServiceZipi,
        private invoicesService: InvoicesService,
        private companyGatewayService: CompanyGatewayService,
        private paymentMethodsService: PaymentMethodsService,
        public loansService: LoansService
    ) {
        this.dataSource = new MatTableDataSource<IInvoice>([]);
        this.showInvoices = false;
    }

    initPreview() {
        this.route.paramMap
            .pipe(
                map((pm) => {
                    const stringId: string | null = pm.get('id');
                    return Number(stringId);
                }),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                if (id) {
                    this.loansService
                        .getLoanById(id)
                        .pipe(
                            tap((loan: ILoan) => {
                                const interestPeriod = this.interestPeriod.find(
                                    (period) => period.value === loan.interest_period
                                );
                                let interestLimit = '';
                                if (loan.interest_rate_limit_type === 'number_of_periods') {
                                    interestLimit = `(${loan.interest_rate_limit_number} periods)`;
                                } else if (loan.interest_rate_limit_type === 'date') {
                                    interestLimit = `(until ${moment(loan.interest_rate_limit_date).format('ll')})`;
                                }

                                return assign({}, loan, {
                                    interestPeriod: interestPeriod,
                                    interestLimit: interestLimit
                                });
                            }),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe((loan: any) => {
                            this.advance = loan;
                            this.dataSource.data = loan.invoice_items;
                        });
                }
            });

        this.paymentMethodsService
            .getCompanyGateways()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((gateways) => {
                gateways
                    .filter((gateway) =>
                        [
                            'system',
                            'authorize_net_merchant'
                            // 'dwolla_business'
                        ].includes(gateway.type)
                    )
                    .map((gateway) => {
                        if (gateway.payment_methods) {
                            return gateway.payment_methods.map((method) => {
                                if (method.payment_method_id) {
                                    this.payToPaymentMethodIds.push(method.payment_method_id);
                                }
                            });
                        }
                    });
            });
    }

    prepareInvoice(advance: ILoan) {
        const mainReceiverPerson = advance.receiver_contact!.contact_persons.find(
            (person) => person.type === 'main_person'
        );

        const newInvoiceData: IInvoice = {
            creator__company_fk_id: null,
            creator__profile_fk_id: null,
            division__company_group_fk_id: null,
            invoice_number: null,
            source__company_fk_id: null,
            owner__company_fk_id: null,
            connected__bill_fk_id: null,
            status_of_connected_bill: null,
            recurring_invoice_fk_id: null,
            invoice_hash: null,
            invoice_note: null,
            status_of_payment: null,
            status_of_notification: null,
            status_of_invoice: null,
            status_in_portal: null,
            summary_status: null,
            status_order: null,
            items_preview: [],
            payment_terms: 'due-on-receipt',
            total_amount: advance.balance || advance.balance === 0 ? advance.balance : advance.amount,
            balance: advance.balance || advance.balance === 0 ? advance.balance : advance.amount,
            pending_balance: advance.balance || advance.balance === 0 ? advance.balance : advance.amount,
            due_date: Number(moment().format('YYYYMMDD')), // current date
            invoice_date: Number(moment().format('YYYYMMDD')), // current date
            items: [
                // product from loan
                {
                    amount: advance.balance || advance.balance === 0 ? advance.balance : advance.amount,
                    description: advance.product ? advance.product.description : null,
                    name: advance.product ? advance.product.name : null,
                    product_fk_id: advance.product && advance.product.product_id ? advance.product.product_id : null,
                    quantity: 1,
                    rate: advance.product ? advance.product.price : null,
                    ledger_account_fk_id: advance.product ? advance.product.ledger_account_fk_id : null,
                    invoice_fk_id: null,
                    connected__deal_fk_id: null,
                    connected__financial_transfer_fk_id: null,
                    deal_connection_mode: null,
                    creator__company_fk_id: null,
                    order_index: 1,
                    division__company_group_fk_id: null
                }
            ],
            money_sender__contact_fk_id:
                advance.receiver_contact && advance.receiver_contact.contact_id
                    ? advance.receiver_contact.contact_id
                    : null, // loan receiver
            money_sender__company_fk_id: advance.receiver_contact
                ? advance.receiver_contact.partner__company_fk_id
                : null, // loan receiver's contact.partner__company_fk_id
            money_sender_email: mainReceiverPerson ? mainReceiverPerson.email : null, // loan receiver
            billing_address: null,
            shipping_address: null,
            to_be_paid__payment_method_fk_id: null,
            pay_to__payment_method_fk_ids: [], // this.payToPaymentMethodIds,
            pay_to_card__payment_method_fk_id: null,
            pay_to_bank__payment_method_fk_id: null,
            sales_person__profile_fk_id: null,
            is_deduct_from_deal: true,
            loan_fk_id: advance.loan_id,
            source__financial_transfer_fk_id: null,
            auto_creation_info: null,
            source__deal_fk_id: null,
            scenario: 'regular',
            bank_fee_payer: 'money_sender',
            card_fee_payer: 'money_sender'
        };

        return {...this.newInvoice, ...newInvoiceData};
    }

    confirmPaid() {
        const dialogRef = this.dialog.open(ManualMarkAsPaidConfirmDialogComponent, {
            data: {}
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((paymentInfo) => {
                // create invoice

                if (!this.advance || !this.advance.product) {
                    this.notificationServiceZipi.addError(`Product missed in loan.`);
                    return;
                }
                // prepare invoice data
                const newInvoice = this.prepareInvoice(this.advance);

                // create invoice
                this.invoicesService
                    .createInvoiceAsOpen({invoice: newInvoice, invoice_association: null})
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((result) => {
                        if (result) {
                            const createdInvoice = result;

                            const requestObj: IPaymentReceivedMadeRequestObject = {
                                money_sender__contact_fk_id: createdInvoice.money_sender__contact_fk_id,
                                money_receiver__contact_fk_id: null,
                                paid_date: paymentInfo.paid_date,
                                scheduled_date: null,
                                amount: createdInvoice.total_amount || 0,
                                pay_to__ledger_account_fk_id: paymentInfo.ledger_account_id,
                                pay_to__payment_method_fk_id: paymentInfo.payment_method_id,
                                paid_by__ledger_account_fk_id: null,
                                paid_by__payment_method_fk_id: null,
                                payments: [
                                    {
                                        entity_id: Number(createdInvoice.invoice_id),
                                        payment_id: null,
                                        amount: createdInvoice.total_amount || 0,
                                        entity: null,
                                        entity_transfer_id: null
                                    }
                                ],
                                is_create_multiple_payments: true,
                                payment_mode: paymentInfo.payment_mode,
                                payment_number: null,
                                reference: null,
                                notes: paymentInfo.note,

                                matched__transaction_external_id: null,
                                check_info: null,
                                deposit_release_id: null,
                                is_locked_for_applying: false,
                                allow_auto_apply: false,
                                source__deal_fk_id: null,
                                sender_velocity: null,
                                restrict_downgrade: false
                            };

                            this.paymentsReceivedService
                                .createPaymentReceived(requestObj)
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((sendResult) => {
                                    if (sendResult) {
                                        this.router.navigate(['/lending/advances']);
                                    }
                                });
                        } else {
                            this.notificationServiceZipi.addError('Invoice creating error');
                        }
                    });
            });
    }

    chargeCustomer() {
        if (this.advance && this.advance.receiver_contact && this.advance.receiver_contact.contact_id) {
            this.companyGatewayService
                .getContactAuthGateways(this.advance.receiver_contact.contact_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateways) => {
                    const dialogRef = this.dialog.open(ChargeLoanCustomerDialogComponent, {
                        width: '320px',
                        data: {
                            gateways: gateways,
                            invoice: this.prepareInvoice(this.advance as ILoan)
                        }
                    });

                    dialogRef
                        .afterClosed()
                        .pipe(
                            filter((pn) => !!pn),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe((invoice) => {
                            // create invoice
                            this.invoicesService
                                .createInvoiceAsOpen({invoice: invoice, invoice_association: null})
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((createResult) => {
                                    if (createResult) {
                                        const createdInvoice = createResult;

                                        if (createdInvoice.invoice_id) {
                                            const data: any = {
                                                note: createdInvoice.invoice_note,
                                                amount: createdInvoice.pending_balance,
                                                paid_by__payment_method_fk_id:
                                                    createdInvoice.to_be_paid__payment_method_fk_id
                                            };
                                            this.invoicesService
                                                .updateAndChargeCustomer(createdInvoice.invoice_id, data)
                                                .pipe(takeUntil(this.unsubscribe))
                                                .subscribe((result) => {
                                                    if (!result.error) {
                                                        this.router.navigate(['/lending/advances']);
                                                    } else {
                                                        this.notificationServiceZipi.addError(result.error);
                                                    }
                                                });
                                        }
                                    } else {
                                        this.notificationServiceZipi.addError('Invoice creating error');
                                    }
                                });
                        });
                });
        }
    }

    ngOnInit() {
        this.initPreview();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
