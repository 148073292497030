import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {GenericFormGroup} from '../../../../../entites/generic.entity';
import {DisbursementText} from '../../../../../models/disbursement-text';

@Component({
    selector: 'app-disbursement-text-dialog',
    styles: [
        `
            .row.footer {
                justify-content: space-between;
            }

            mat-list {
                max-height: 400px;
                width: 100%;
                overflow-x: hidden;
            }
        `
    ],
    template: `
        <h3>Disbursement Text</h3>
        <div style="display: flex; flex-direction: column; min-width: 300px;">
            <mat-form-field class="w-100" *ngIf="disbursementTextFG.controls.message_1">
                <textarea
                    matInput
                    type="text"
                    name="message_1"
                    placeholder="Deal Information"
                    [formControl]="disbursementTextFG.controls.message_1"
                ></textarea>
            </mat-form-field>
            <mat-form-field class="w-100" *ngIf="disbursementTextFG.controls.notification_1">
                <textarea
                    matInput
                    type="text"
                    name="notification_1"
                    placeholder="Transaction Title"
                    [formControl]="disbursementTextFG.controls.notification_1"
                ></textarea>
            </mat-form-field>
            <mat-form-field class="w-100" *ngIf="disbursementTextFG.controls.message_2">
                <textarea
                    matInput
                    type="text"
                    name="message_2"
                    placeholder="Transaction Footer"
                    [formControl]="disbursementTextFG.controls.message_2"
                ></textarea>
            </mat-form-field>
            <mat-form-field class="w-100" *ngIf="disbursementTextFG.controls.footer_message">
                <textarea
                    matInput
                    type="text"
                    name="footer_message"
                    placeholder="Disbursement Footer"
                    [formControl]="disbursementTextFG.controls.footer_message"
                ></textarea>
            </mat-form-field>
            <div style="margin-top: 20px;">
                <button style="float: right;" mat-raised-button class="mat-default" (click)="dialogRef.close()">
                    ok
                </button>
            </div>
        </div>
    `
})
export class DisbursementTextDialogComponent {
    disbursementTextFG: GenericFormGroup<DisbursementText> = new GenericFormGroup<DisbursementText>(
        new DisbursementText(),
        'change'
    );

    constructor(public dialogRef: MatDialogRef<DisbursementTextDialogComponent>) {}
}
