import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserManager} from '@skyslope/auth-js';
import {SkyslopeAuth} from '../services/okta/skyslope-auth.service';
import {OktaAuthStateService, OKTA_AUTH} from '@okta/okta-angular';
import {AuthService} from 'app/services/auth.service';
import {Subject, takeUntil} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from 'app/services/session.service';
import {UserService} from 'app/services/user.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-login',
    templateUrl: './sign-in-skyslope.component.html',
    styleUrls: ['./sign-in-skyslope.component.scss']
})
export class SignInSkySlopeComponent implements OnInit, OnDestroy {
    title = 'Login';
    user: undefined;
    oktaSignIn: any;
    userManager: UserManager | undefined;
    private unsubscribe: Subject<void> = new Subject();
    private redirectParamUrl: string | null = null;
    isLoading: boolean = false;

    constructor(
        private skyslopeAuth: SkyslopeAuth,
        private authStateService: OktaAuthStateService,
        private authService: AuthService,
        protected usersService: UserService,
        private activatedRoute: ActivatedRoute,
        public sessionService: SessionService,
        private skyslopeAuthService: SkyslopeAuth,
        public dialog: MatDialog,
        public router: Router
    ) {
        this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params.url) {
                this.redirectParamUrl = params.url;
            }
        });
    }

    async showLogin(): Promise<void> {
        this.oktaSignIn = await this.skyslopeAuth.createWidget('okta-login-container', false);
    }

    ngOnInit() {
        this.authService.logout();
    }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngAfterViewInit(): void {
        //If query param iss exists, then don't show widget and let the okta auth service handle the redirect
        if (!this.activatedRoute.snapshot.queryParams.iss) {
            setTimeout(() => {
                this.showLogin();
            }, 100);
        } else {
            this.isLoading = true;
            this.router.navigate(['/default-page']);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
