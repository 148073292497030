<div>
    <h2 *ngIf="!errorMessage">Micro Deposit Verification</h2>
    <h2 *ngIf="errorMessage">
        <mat-icon style="color: #bb000a; margin-right: 14px; font-size: 20px; width: 20px; height: 20px">error</mat-icon
        >Incorrect Amount
    </h2>
    <div *ngIf="!errorMessage" class="mb-3">
        Enter the amount of the two micro deposits that were sent to your connected account. Please note that it may
        take 1-3 days for the deposits to appear in your account.
    </div>
    <div *ngIf="errorMessage">
        <div>
            <span class="mr-2">One or more of the values you’ve entered are incorrect.</span>
            <span *ngIf="!noMoreAttempts">Confirm the amounts in your bank account activity and try re-entering.</span>
        </div>
        <span *ngIf="noMoreAttempts">You will need to re-enter your bank details.</span>
        <div style="font-weight: bold" *ngIf="!noMoreAttempts">{{ errorMessage }}</div>
    </div>
    <div style="text-align: center; margin-top: 30px" *ngIf="!noMoreAttempts">
        <mat-form-field class="px-2 w-50">
            <input matInput required type="text" [formControl]="amountOne" [maskito]="currencyMaskitoMask" />
            <mat-label>Amount</mat-label>
        </mat-form-field>
        <mat-form-field class="px-2 w-50">
            <input matInput required type="text" [formControl]="amountTwo" [maskito]="currencyMaskitoMask" />
            <mat-label>Amount</mat-label>
        </mat-form-field>
    </div>

    <mat-divider class="mt-2"></mat-divider>

    <div class="d-flex justify-content-end w-100 mt-4">
        <button mat-flat-button class="mr-1" [disabled]="isLoading" (click)="close()">Close</button>
        <button mat-flat-button color="primary" *ngIf="!noMoreAttempts" [disabled]="isLoading" (click)="verify()">
            Confirm
        </button>
        <button
            mat-flat-button
            color="primary"
            *ngIf="noMoreAttempts"
            [disabled]="isLoading"
            (click)="dialogRef.close({isError: false, success: true, message: '', isNeedToConnectBank: true})"
        >
            Reconnect Account
        </button>
    </div>
</div>
