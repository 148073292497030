import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {Observable} from 'rxjs';
import {IPaymentGateway} from '@cyberco-nodejs/zipi-typings';

@Injectable({
    providedIn: 'root'
})
export class GatewayService {
    url = '/finance/gateway';

    constructor(public requester: ServiceRequester) {}

    getCompanyGateways(): Observable<IPaymentGateway[]> {
        return this.requester.makeMsCall$(`${this.url}`, 'GET', 'shipp');
    }

    getCompanyGatewaysByCompanyId(companyId: number): Observable<IPaymentGateway[]> {
        return this.requester.makeMsCall$(`${this.url}/company/${companyId}`, 'GET', 'shipp');
    }

    getCompanySystemGateway(): Observable<IPaymentGateway> {
        return this.requester.makeMsCall$(`${this.url}/system`, 'GET', 'shipp');
    }

    getPlaidLinkToken(
        gatewayId: number,
        data: {products: Array<string>; linkCustomizationName: string; account_type: 'trust' | 'operating' | null}
    ): Observable<string> {
        return this.requester.makeMsCall$(`${this.url}/${gatewayId}/plaid/link-token`, 'POST', 'shipp', data);
    }
}
