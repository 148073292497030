import {Injectable} from '@angular/core';

import {AngularFireAuth} from '@angular/fire/compat/auth';
import {Observable, firstValueFrom} from 'rxjs';
// Do not import from 'firebase' as you'd lose the tree shaking benefits
import firebase from 'firebase/compat/app';
import {UserService} from './user.service';
import {Profile} from '../models/profile';
import {Router} from '@angular/router';
import {SessionService} from './session.service';
import {ProfilesService} from './profiles.service';
import {SystemUserSource} from './sources/system-user.source';
import {CurrentProfileSource} from './sources/current-profile.source';
import {AppInitApi, AppInitResponse} from './api/app-init.api';
import {AppInitSource} from './sources/app-init.source';
import Cookies from 'js-cookie';
import {environment} from '../../environments/environment';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {Store} from '@ngrx/store';
import {ICompanyWideState} from '../store/company-wide/company-wide.reducer';
import {
    FetchMembershipOrganizations,
    FetchSubEntities,
    FetchTagCategories,
    FetchWildcards
} from '../store/company-wide/company-wide.actions';
import {FetchLedgerAccountsMust} from '../modules/finance/store/finance.actions';
import {ApiSessionService} from './api/api-session.service';
import {getUserManager} from '../modules/auth/services/okta/user-manager';
import {SkyslopeAuth} from '../modules/auth/services/okta/skyslope-auth.service';
import {UserContext} from '@skyslope/auth-js';

declare var window: any;
const CURRENT_PROFILE_ID = 'current_profile_id';

@Injectable()
export class AuthService {
    authStateObservable: Observable<firebase.User> | any; // @todo: avoid using 'any' here

    public currentFirebaseUser: firebase.User | null = null;

    public currentOktaUser: UserContext | undefined | null = null;

    private redirectUrl: string = '';

    public redirectToAfterSSO: string = '';

    constructor(
        public db: AngularFireDatabase,
        public afAuth: AngularFireAuth,
        protected skyslopeAuthService: SkyslopeAuth,
        protected usersService: UserService,
        protected sessionService: SessionService,
        protected currentProfileSource: CurrentProfileSource,
        protected systemUserSource: SystemUserSource,
        protected profilesService: ProfilesService,
        protected appInitApi: AppInitApi,
        protected appInitSource: AppInitSource,
        protected apiSessionService: ApiSessionService,
        protected router: Router,
        private store: Store<ICompanyWideState>
    ) {
        this.authStateObservable = afAuth.authState;
        // this.afAuth.setPersistence(firebase.auth.Auth.Persistence.NONE);

        currentProfileSource.changeProfileEvent.subscribe((profile: Profile) => {
            localStorage.setItem(CURRENT_PROFILE_ID, String(profile.id));
            localStorage.setItem('zipi_last_profile_id', String(profile.id));
        });

        this.appInitSource.appInit.subscribe((appInitResponse: AppInitResponse) => {
            // authorize user in our own addons
            if (environment.addonHashCookieDomain && environment.addonHashCookieDomain === '.books.skyslope.com') {
                const t = new Date().getTime();
                const iframeSS = document.createElement('iframe');
                iframeSS.id = 'auth-in-addon-skyslope';
                iframeSS.style.display = 'none';
                iframeSS.src = `https://books-to-ss.skyslope.com/set-user-cookie?addon_hash=${appInitResponse.zipiAddonHash}&t=${t}`;
                document.body.appendChild(iframeSS);

                const iframeDL = document.createElement('iframe');
                iframeDL.id = 'auth-in-addon-dotloop';
                iframeDL.style.display = 'none';
                iframeDL.src = `https://dotloop.zipi.app/set-user-cookie.html?addon_hash=${appInitResponse.zipiAddonHash}&t=${t}`;
                document.body.appendChild(iframeDL);
            }
            if (typeof window !== 'undefined') {
                window.inlineManualTracking = {
                    uid: appInitResponse!.currentProfile!.user!.id,
                    email: appInitResponse!.currentProfile!.user!.email,
                    name:
                        appInitResponse!.currentProfile!.user!.first_name +
                        ' ' +
                        appInitResponse!.currentProfile!.user!.last_name
                };
            }

            const currentGroupIdToCheck = parseInt(String(localStorage.getItem('current_company_group_id')), 10);
            const isGroupExist = appInitResponse!.currentProfile!.company_groups_member!.some((group_member) => {
                return group_member.company_group !== null && group_member.company_group.id === currentGroupIdToCheck;
            });
            if (!isGroupExist) {
                localStorage.removeItem('current_company_group_id');
            }
        });

        this.systemUserSource.chageSystemUser.subscribe((systemUser) => {
            // commented by AnotnV. I don't see reason why we need this clearLoginAsUID function here
            // if (!this.isLoggedAsMode()) {
            //     this.clearLoginAsUID();
            // }
        });
    }

    initApp() {
        return Promise.resolve().then(() => {
            if (this.appInitSource.lastAppInitResponse === null) {
                // restore profile_id from localStorage
                const zipi_last_profile_id = localStorage.getItem('zipi_last_profile_id');
                const zipi_actual_profile_id = localStorage.getItem('zipi_actual_profile_id');
                if (zipi_actual_profile_id) {
                    localStorage.removeItem('zipi_actual_profile_id');
                    localStorage.setItem(CURRENT_PROFILE_ID, zipi_actual_profile_id);
                } else if (zipi_last_profile_id) {
                    localStorage.removeItem('zipi_last_profile_id');
                    localStorage.setItem(CURRENT_PROFILE_ID, zipi_last_profile_id);
                }
                return this.appInitApi.appInit(this.redirectUrl).then(async (result) => {
                    if (result.currentProfile!.type === 'global') {
                        this.router.navigate(['/default-page']);
                    }
                    this.appInitSource.triggers.initApp.next(result);

                    this.redirectToAfterSSO = result.linkToZendesk;

                    // clear notification-event on appInit
                    const pathAsyncNotify = `/notification-events/${(<any>environment).namespace}/company/${this.sessionService.profile!.company_fk_id}/events`;
                    const d = new Date();
                    const olderThan1Hour = d.setHours(d.getHours() - 1);
                    this.db
                        .list(pathAsyncNotify)
                        .query.orderByChild('timestamp')
                        .endAt(olderThan1Hour)
                        .once('value')
                        .then((a) => a.val())
                        .then((notifications) => {
                            if (notifications) {
                                Object.keys(notifications).forEach((ref) => {
                                    this.db.list(pathAsyncNotify).remove(ref);
                                });
                            }
                        });

                    // load once
                    this.store.dispatch(new FetchLedgerAccountsMust());
                    this.store.dispatch(new FetchSubEntities());
                    this.store.dispatch(new FetchMembershipOrganizations());
                    this.store.dispatch(new FetchTagCategories());
                    this.store.dispatch(new FetchWildcards());

                    return true;
                });
            } else {
                return true;
            }
        });
    }

    loadProfile(profileId: number) {
        return this.appInitApi.loadProfile(profileId).then((result) => {
            this.appInitSource.triggers.loadProfile.next(result);
        });
    }

    public generateCredentials(email: string, password: string): firebase.auth.AuthCredential {
        return firebase.auth.EmailAuthProvider.credential(email, password);
    }

    public getSystemUser() {
        return new Promise((resolve, reject) => {
            if (this.isLoggedIn()) {
                return resolve(this.sessionService.user);
            }

            this.systemUserSource.chageSystemUser.subscribe((systemUser) => {
                return resolve(systemUser);
            });
        });
    }

    public getCurrentFirebaseUser() {
        return new Promise((resolve, reject) => {
            return this.authStateObservable.subscribe((event: firebase.User) => {
                if (!event) {
                    this.currentFirebaseUser = null;
                } else {
                    this.currentFirebaseUser = event;
                }

                return resolve(this.currentFirebaseUser);
            });
        }).then((firebaseUser) => {
            if (firebaseUser !== null) {
                return this.initApp().then((result) => {
                    if (result) {
                        return firebaseUser;
                    }
                    return false;
                });
            }
        });
    }

    public async getCurrentOktaUser() {
        return new Promise(async (resolve, reject) => {
            if (this.currentOktaUser !== null) {
                resolve(this.currentOktaUser);
            } else {
                await this.skyslopeAuthService.getUser().then((user) => {
                    this.currentOktaUser = user;
                    if (user !== null) {
                        resolve(user);
                    } else {
                        resolve(false);
                    }
                });
            }
        }).then((oktaUser) => {
            if (oktaUser !== null) {
                return this.initApp().then((result) => {
                    if (result) {
                        return oktaUser;
                    }
                    return false;
                });
            }
        });
    }

    public getCurrentProfile(): Promise<Profile> {
        return new Promise(async (resolve, reject) => {
            if (this.sessionService.profile) {
                resolve(this.sessionService.profile);
            } else {
                this.currentProfileSource.changeProfileEvent.subscribe((profile) => {
                    if (profile) {
                        resolve(profile);
                    }
                });
            }
        });
    }

    public async getCurrentUserAvailableProfiles(): Promise<Profile[]> {
        if (!this.sessionService.availableProfiles || this.sessionService.availableProfiles.length === 0) {
            // this.sessionService.availableProfiles = await this.usersService.getUserProfiles();
        }

        return this.sessionService.availableProfiles!;
    }

    public setCurrentProfile(profile: Profile | null, reloadList = false) {
        if (!profile) {
            return null;
        }
        return this.loadProfile(Number(profile.id));
    }

    public setRedirectUrl(url: string) {
        this.redirectUrl = url;
    }

    createUserWithEmailAndPassword(email: string, password: string) {
        return this.afAuth.createUserWithEmailAndPassword(email, password);
    }

    async signInWithEmailAndPassword(email: string, password: string) {
        const firebaseUserInfo = await this.afAuth.signInWithEmailAndPassword(email, password);
        if (firebaseUserInfo.user) {
            const idToken = await firebaseUserInfo.user.getIdToken();
            await this.apiSessionService.sessionCoreLogin({id_token: idToken, okta_access_token: null});
            await firstValueFrom(
                this.apiSessionService.sessionShippLogin({id_token: idToken, okta_access_token: null})
            );
        }
        return firebaseUserInfo;
    }

    signInWithCredential(credential: firebase.auth.AuthCredential): Promise<any> {
        return this.afAuth.signInWithCredential(credential);
    }

    updateEmail(email: string) {
        return this.afAuth.currentUser.then((user) => user!.updateEmail(email));
    }

    updatePassword(password: string) {
        return this.afAuth.currentUser.then((user) => user!.updatePassword(password));
    }

    async signInWithGoogle(loginHint: string = '') {
        const googleProvider = new firebase.auth.GoogleAuthProvider();

        // let providerParams: {
        //     display?: string;
        //     prompt?: string;
        //     login_hint?: string;
        // } = {'display': 'popup', 'prompt': 'select_account'};

        if (loginHint !== '') {
            googleProvider.setCustomParameters({login_hint: loginHint});
        }
        const firebaseUserInfo = await this.afAuth.signInWithPopup(googleProvider);
        if (firebaseUserInfo.user) {
            const idToken = await firebaseUserInfo.user.getIdToken();
            await this.apiSessionService.sessionCoreLogin({id_token: idToken, okta_access_token: null});
            await firstValueFrom(
                this.apiSessionService.sessionShippLogin({id_token: idToken, okta_access_token: null})
            );
        }
        return firebaseUserInfo;
    }

    async loadAuth(): Promise<boolean> {
        // First check Okta Session
        const isOktaAuthenticated = await this.skyslopeAuthService.isAuthenticated();

        if (isOktaAuthenticated) {
            return await this.getCurrentOktaUser().then((user) => {
                if (!user) {
                    return false;
                }
                Cookies.set('okta_user', 'true');
                return this.getSystemUser().then((systemUser) => {
                    if (!systemUser) {
                        return false;
                    }
                    return true;
                });
            });
        } else {
            // If Okta Auth not present, check firebase.
            return this.getCurrentFirebaseUser().then((firebaseUser) => {
                if (!firebaseUser) {
                    return false;
                }
                Cookies.set('firebase_user', 'true');
                return this.getSystemUser().then((systemUser) => {
                    if (!systemUser) {
                        return false;
                    }
                    return true;
                });
            });
        }
    }

    setLoginAsUID(loginAsUID: string) {
        localStorage.setItem('pw_logged_as_uid', loginAsUID);
    }

    async setLoginAsOktaUid(oktaUid: string) {
        await this.usersService.checkIfOktaUserExists(oktaUid).then((response) => {
            if (response) {
                localStorage.setItem('pw_logged_as_uid', response.user_id);
            }
        });
    }

    clearLoginAsUID() {
        localStorage.removeItem('pw_logged_as_uid');
    }

    isLoggedAsMode() {
        const localStorageUID = localStorage.getItem('pw_logged_as_uid');
        const sessionUserID = this.sessionService.user ? this.sessionService.user.id : null;

        // if (localStorageUID && sessionUserID && localStorageUID.toString() === sessionUserID.toString()) {
        //     return true;
        // }

        // lets don't care about sessionUserId. Just check if there variable in localStorage
        if (localStorageUID) {
            return true;
        }

        return false;
    }

    isLoggedIn() {
        return this.sessionService.user;
    }

    logout(): Promise<any> {
        return this.afAuth.signOut().then(async () => {
            await this.apiSessionService.sessionCoreLogout();
            await firstValueFrom(this.apiSessionService.sessionShippLogout());
            return new Promise((resolve, reject) => {
                localStorage.removeItem(CURRENT_PROFILE_ID);
                localStorage.removeItem('current_company_group_id');
                localStorage.removeItem('login_as_uid');
                localStorage.removeItem('DEFAULT_QP_ID');

                localStorage.removeItem('pw_logged_as_uid');
                Cookies.remove('zipi_addon_hash');
                Cookies.remove('firebase_user');

                this.sessionService.clearSessionState();
                this.appInitSource.resetLastAppInitResponse();

                this.currentFirebaseUser = null;
                this.currentOktaUser = null;
                resolve(null);
            });
        });
    }

    async quietLogout(): Promise<any> {
        // await this.apiSessionService.sessionCoreLogout();
        // await firstValueFrom(this.apiSessionService.sessionShippLogout());
        return new Promise((resolve, reject) => {
            localStorage.removeItem(CURRENT_PROFILE_ID);
            localStorage.removeItem('current_company_group_id');
            localStorage.removeItem('login_as_uid');
            localStorage.removeItem('DEFAULT_QP_ID');
            localStorage.removeItem('pw_logged_as_uid');
            localStorage.removeItem('idp');
            Cookies.remove('zipi_addon_hash');
            Cookies.remove('firebase_user');
            this.sessionService.clearSessionState();
            this.appInitSource.resetLastAppInitResponse();
            this.currentFirebaseUser = null;
            resolve(null);
        });
    }

    async signInWithOkta(token: string | undefined) {
        if (token) {
            const customToken = (await this.apiSessionService.sessionShippCustomToken(token)).customToken;
            // Creating a custom firebase auth session on client side to help support other firebase services.
            // eg. Firebase Storage, etc.
            const firebaseUserInfo = await this.afAuth.signInWithCustomToken(customToken);

            if (!firebaseUserInfo) {
                console.error('Firebase session not created.');
            }

            await this.apiSessionService.sessionCoreLogin({id_token: null, okta_access_token: token});
            await firstValueFrom(this.apiSessionService.sessionShippLogin({id_token: null, okta_access_token: token}));
        }
    }

    async cleanupOktaSession() {
        const userManager = getUserManager();
        const sessionExists = await userManager._oktaAuth.oktaAuth.session.exists();
        if (!sessionExists) {
            return;
        }

        const oktaAuthState = await userManager.getAuthState();

        if (oktaAuthState && oktaAuthState.isAuthenticated) {
            return await userManager.startLogout();
        }
    }

    async handleOktaLogin() {
        const userManager = getUserManager();

        // See if there is an established Okta session
        const sessionExists = await userManager._oktaAuth.oktaAuth.session.exists();
        if (!sessionExists) {
            return await userManager.login();
        }

        const isAuthenticated = await userManager._oktaAuth.oktaAuth.isAuthenticated();

        if (!isAuthenticated) {
            return await userManager.login();
        }

        const oktaAuthState = await userManager.getAuthState();

        const isUserExists = await this.usersService.checkIfOktaUserExists(
            oktaAuthState.accessToken!.claims.uid as string
        );

        if (isUserExists) {
            await this.signInWithOkta(oktaAuthState.accessToken?.accessToken!);
            const isUserLoaded = await this.loadAuth();

            this.redirectLogic(isUserLoaded);
        } else {
            window.location.href = '/login-error';
        }
    }

    protected checkIfUserExists(
        firebaseUserCredential: any
    ): Promise<{userExists: boolean; firebaseUserCredential: any}> {
        return this.usersService.checkIfUserExists(firebaseUserCredential.user!.uid).then((userExists) => {
            return {userExists, firebaseUserCredential: firebaseUserCredential};
        });
    }

    redirectLogic(isUserLoaded: boolean) {
        if (!isUserLoaded) {
            this.logout();
            return;
        }

        const zipi_redirect_path = localStorage.getItem('zipi_redirect_path');
        // if (this.redirectParamUrl && /^https:\/\/community\.zipi\.app/.test(this.redirectParamUrl)) {
        //     window.location.href = this.sessionService.linkToCommunity;
        // } else
        if (zipi_redirect_path) {
            localStorage.removeItem('zipi_redirect_path');
            this.router.navigate([zipi_redirect_path]);
        } else {
            this.router.navigate(['/default-page']);
        }

        return;
    }
}
