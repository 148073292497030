import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
    selector: 'app-delete-deals-warning',
    templateUrl: './delete-deals-warning.component.html',
    styleUrls: ['./delete-deals-warning.component.scss']
})
export class DeleteDealsWarningComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<DeleteDealsWarningComponent>,
        public router: Router,
        @Inject(MAT_DIALOG_DATA) public data: {dualDealExists: boolean}
    ) {}

    ngOnInit() {}
}
