import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-create-product-dialog',
    templateUrl: 'create-product-dialog.component.html'
})
export class CreateProductDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<CreateProductDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
}
