import {IndicatorsState} from './indicators.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const selectIndicatorsFeature = createFeatureSelector<IndicatorsState>('indicators');

export const selectHttpRequestIsActive = createSelector(
    selectIndicatorsFeature,
    (state: IndicatorsState) => state.httpRequestIsActive
);

export const selectUploadedFiles = createSelector(
    selectIndicatorsFeature,
    (state: IndicatorsState) => state.uploadedFiles
);
