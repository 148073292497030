<div mat-dialog-title class="clearfix">
    <div class="page-header">Support Access</div>
    <button
        mat-mini-fab
        aria-label="Close"
        (click)="dialogRef.close()"
        color="primary"
        style="float: right"
        class="close-button"
    >
        <mat-icon>close</mat-icon>
    </button>
    <div class="user-display">
        <div style="font-weight: bold; text-underline: #0a2642">Current Administrative Account:</div>
        <div class="name">{{ currentUser?.firstName }} {{ currentUser?.lastName }}</div>
        <div class="email" style="width: 100%">{{ currentUser?.email }}</div>
    </div>
    <div class="user-display" *ngIf="authService.isLoggedAsMode()">
        <div style="font-weight: bold; text-underline: #0a2642">Impersonated User:</div>
        <div class="name">{{ currentSystemUser?.first_name }} {{ currentSystemUser?.last_name }}</div>
        <div class="email" style="width: 100%">{{ currentSystemUser?.email }}</div>
        <div class="remove-btn-div">
            <button
                mat-raised-button
                aria-label="Remove"
                (click)="clearLoginAs()"
                color="secondary"
                class="remove-btn mat-small"
            >
                Remove
            </button>
        </div>
    </div>
</div>
<mat-dialog-content>
    <section class="no-access" *ngIf="showError">You have not been granted access to this feature.</section>
    <section #usersSection class="user-section">
        <div class="p-4">
            <mat-form-field>
                <input
                    matInput
                    (keyup)="applyFilter($event.target.value)"
                    (keyup.enter)="forceFilterSearch($event.target.value)"
                    placeholder="Search..."
                />
            </mat-form-field>

            <div class="viewport">
                <mat-table
                    [dataSource]="dataSource"
                    matSort
                    matSortDisableClear
                    matSortActive="{{ sort_column }}"
                    matSortDirection="{{ sort_direction }}"
                    (matSortChange)="sortChange()"
                >
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.name }} {{ element.last_name }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.email }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="company">
                        <mat-header-cell *matHeaderCellDef> Companies </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <ng-container
                                dynamicCell
                                [component]="CompanyModalComponent"
                                [inputs]="{companies: element.company_alt}"
                            >
                            </ng-container>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="roles">
                        <mat-header-cell *matHeaderCellDef> Roles </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.roles }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="userAccess">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> User Access </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.userAccess | date: 'short' }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="adminAccess">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Admin Access </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.adminAccess | date: 'short' }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="login">
                        <mat-header-cell *matHeaderCellDef> &nbsp; </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <button
                                mat-raised-button
                                class="login-btn mat-small"
                                type="button"
                                color="primary"
                                (click)="loginAs(element, $event)"
                            >
                                Login as
                            </button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
            </div>
        </div>
    </section>
</mat-dialog-content>
