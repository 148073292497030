<div>
    <div *ngIf="currentStep === '0'" [ngStyle]="currentStep === '0' ? {display: 'block'} : {display: 'none'}">
        <!--        <div class="d-flex w-100 justify-content-center" style="padding: 40px" *ngIf="isLoading">-->
        <!--            <mat-spinner diameter="36"></mat-spinner>-->
        <!--        </div>-->

        <div
            *ngIf="allAvailableBankAccounts && allAvailableBankAccounts.length > 0 && currentStep === '0' && !isLoading"
        >
            <div style="padding: 40px">
                <h3>Connect bank account</h3>
                <div class="mb-3">
                    The following accounts have not been set up completely. Please select an existing account to
                    continue setup or add a new bank account.
                </div>
                <ng-container *ngFor="let account of allAvailableBankAccounts">
                    <mat-card *ngIf="!alreadyConnectedBankIds.includes(account.id)">
                        <div class="d-flex w-100 align-items-center">
                            <div>{{ account.bankName }} ({{ account.lastFour }})</div>
                            <mat-icon class="ml-2" *ngIf="account.isCloudBank">cloud</mat-icon>
                            <span class="sp-spacer"></span>
                            <mat-icon
                                *ngIf="!account.verified"
                                style="color: orange; font-size: 27px"
                                class="mr-3"
                                matTooltip="Account not verified."
                                >warning</mat-icon
                            >
                            <mat-icon
                                *ngIf="account.verified && !account.achApproved"
                                style="color: red; font-size: 27px"
                                class="mr-3"
                                matTooltip="Pending for Verification."
                                >watch_later</mat-icon
                            >
                            <button
                                type="button"
                                mat-stroked-button
                                color="primary"
                                *ngIf="!alreadyConnectedBankIds.includes(account.id)"
                                (click)="connectBank(account)"
                            >
                                Connect
                            </button>
                            <button
                                mat-icon-button
                                color="primary"
                                *ngIf="!alreadyConnectedBankIds.includes(account.id)"
                                matTooltip="Remove from this list."
                                (click)="removeExternalAccount(account.id)"
                            >
                                <mat-icon>delete</mat-icon>
                            </button>
                            <!--                        <div class="font-weight-bold" *ngIf="alreadyConnectedBankIds.includes(account.id)">Connected</div>-->
                        </div>
                    </mat-card>
                </ng-container>
            </div>

            <mat-divider></mat-divider>

            <div class="d-flex" style="padding: 20px" *ngIf="currentStep === '0'">
                <button type="button" mat-flat-button style="color: #0059da" (click)="loadLink()">
                    <mat-icon>add</mat-icon>
                    Add new Bank Account
                </button>
                <span class="sp-spacer"></span>
                <button type="button" mat-raised-button color="primary" (click)="close()">Close</button>
            </div>
        </div>
    </div>

    <div
        *ngIf="currentStep === '1'"
        style="
            position: fixed;
            top: 50%;
            left: 50%;
            z-index: 2147483647;
            transform: translate(-50%, -50%);
            background-color: #ffffff;
            padding-bottom: 39px;
        "
    >
        <div *ngIf="link && iframeInit" class="d-flex w-100 justify-content-end">
            <mat-icon style="cursor: pointer; margin-top: 15px; margin-right: 15px" (click)="close()">close</mat-icon>
        </div>
        <iframe
            *ngIf="link && iframeInit"
            [ngClass]="
                iframeStep === 'choice'
                    ? 'iframe-size-choice'
                    : iframeStep === 'instantly'
                      ? 'iframe-size-instantly'
                      : iframeStep === 'manually'
                        ? 'iframe-size-manually'
                        : ''
            "
            style="border-width: 0px; display: block; overflow: hidden auto"
            [src]="sanitizedUrl"
            frameborder="0"
            id="frame"
        >
        </iframe>
    </div>

    <div *ngIf="currentStep === '2'" [ngStyle]="currentStep === '2' ? {display: 'block'} : {display: 'none'}">
        <div style="padding: 40px">
            <h3 class="w-100">Connect to Ledger Account</h3>
            <div class="mt-2">
                <div>
                    Please select the Ledger Account you want to use with the connected bank account. You can update the
                    connected ledger account at any time.
                </div>
                <div class="pl-3 my-3">
                    <mat-radio-group [(ngModel)]="isDefault">
                        <div style="">
                            <mat-radio-button [value]="false">
                                <div class="d-flex justify-content-between align-items-center" style="height: 60px">
                                    <div>Select existing ledger account</div>
                                    <mat-form-field
                                        style="margin-left: 120px"
                                        class="w-50 px-2"
                                        *ngIf="!isDefault"
                                        (click)="$event.stopPropagation()"
                                    >
                                        <mat-select [formControl]="accountId!" [disabled]="isDefault">
                                            <mat-option
                                                *ngFor="let account of availableLedgerAccounts"
                                                [value]="account.ledger_account_id"
                                            >
                                                {{ account.name }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-hint *ngIf="availableLedgerAccounts.length === 0"
                                            >No Accounts to Connect</mat-hint
                                        >
                                        <mat-error *ngIf="!isDefault && accountId.invalid"
                                            >Please select one.</mat-error
                                        >
                                        <mat-placeholder>Ledger Account</mat-placeholder>
                                    </mat-form-field>
                                </div>
                            </mat-radio-button>
                        </div>
                        <div>
                            <mat-radio-button [value]="true"
                                >Create new ledger account
                                {{
                                    ['zipi_financial_trust'].includes(paymentGateway!.type) ? '(Trust)' : ''
                                }}</mat-radio-button
                            >
                        </div>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div style="padding: 20px" class="d-flex justify-content-between w-100">
            <span class="sp-spacer"></span>
            <!--            <button mat-raised-button class="mr-1" [disabled]="applyDisabled" (click)="close()" tabindex="-1">Close</button>-->
            <button mat-flat-button color="primary" [disabled]="applyDisabled" (click)="apply(false)" tabindex="1">
                Apply
            </button>
        </div>
    </div>

    <div *ngIf="currentStep === '3'" [ngStyle]="currentStep === '3' ? {display: 'block'} : {display: 'none'}">
        <div style="padding: 40px">
            <h3 class="w-100">Bank Account Added</h3>
            <div class="mt-2">
                <p>
                    Your bank details have been added. You will receive two small deposits in the next 1-3 days into
                    your account.
                </p>

                <div class="mt-4">
                    Once you have both deposits, you can return to Money Transfers to verify your bank account.
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div style="padding: 20px" class="d-flex justify-content-between w-100">
            <span class="sp-spacer"></span>
            <button
                mat-flat-button
                color="primary"
                [disabled]="applyDisabled"
                (click)="dialogRef.close({isError: false, success: true, message: ''})"
                tabindex="1"
            >
                Next
            </button>
        </div>
    </div>
</div>
