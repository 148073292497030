import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IInvoice, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {Subject} from 'rxjs';
import Decimal from 'decimal.js-light';
import {FormControl, Validators} from '@angular/forms';
import {cleanCurrencyString, currencyMaskitoOptions} from '@app/utilities/maskito';
import {MatRadioChange} from '@angular/material/radio';
import {IInvoiceGroup} from '@app/modules/finance/components/statement-public/statement-public-local-typings/statement.typings';

@Component({
    selector: 'app-setup-payment-info-dialog',
    templateUrl: './setup-payment-info-dialog.component.html',
    styleUrls: ['./setup-payment-info-dialog.component.scss']
})
export class SetupPaymentInfoDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    currencyMaskitoMask = currencyMaskitoOptions;

    methods: IPaymentMethod[] = [];
    paymentType: 'payload_card' | 'payload_bank' | 'authorize_net' | null = null;
    unpaidInvoices: IInvoice[] = [];
    invoiceGroups: IInvoiceGroup[] = [];

    brokerName: string = '';

    selectedGroup: IInvoiceGroup | null = null;

    remainingAmount: number = 0;

    paymentAmount: FormControl = new FormControl(0, [Validators.required]);

    constructor(
        public dialogRef: MatDialogRef<SetupPaymentInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.brokerName = this.data.brokerName;
        this.methods = this.data.allowedMethods;
        this.paymentType = this.data.type;
        this.unpaidInvoices = this.data.invoices;
        if (this.unpaidInvoices && this.unpaidInvoices.length > 0) {
            this.setupInvoiceGroups();
        }
    }

    setupInvoiceGroups() {
        if (this.methods && this.methods.length > 0) {
            this.methods.forEach((method: IPaymentMethod) => {
                const groupWithFee: IInvoiceGroup = {
                    pay_to__payment_method_fk_id: method.payment_method_id!,
                    total_pending_amount: 0,
                    fee_payer: 'money_sender',
                    invoice_ids: [],
                    payment_amount: 0,
                    payment_type: this.paymentType
                };
                const groupWithoutFee: IInvoiceGroup = {
                    pay_to__payment_method_fk_id: method.payment_method_id!,
                    total_pending_amount: 0,
                    fee_payer: 'money_receiver',
                    invoice_ids: [],
                    payment_amount: 0,
                    payment_type: this.paymentType
                };
                for (const invoice of this.unpaidInvoices) {
                    switch (this.paymentType) {
                        case 'payload_bank': {
                            if (invoice.pay_to_bank__payment_method_fk_id === method.payment_method_id) {
                                if (invoice.pending_balance && invoice.invoice_id) {
                                    if (invoice.bank_fee_payer === 'money_sender') {
                                        groupWithFee.total_pending_amount = new Decimal(
                                            groupWithFee.total_pending_amount
                                        )
                                            .add(invoice.pending_balance)
                                            .toDecimalPlaces(2)
                                            .toNumber();
                                        groupWithFee.invoice_ids.push(invoice.invoice_id);
                                    } else {
                                        groupWithoutFee.total_pending_amount = new Decimal(
                                            groupWithoutFee.total_pending_amount
                                        )
                                            .add(invoice.pending_balance)
                                            .toDecimalPlaces(2)
                                            .toNumber();
                                        groupWithoutFee.invoice_ids.push(invoice.invoice_id);
                                    }
                                }
                            }
                            break;
                        }

                        case 'payload_card':
                        case 'authorize_net': {
                            if (invoice.pay_to_card__payment_method_fk_id === method.payment_method_id) {
                                if (invoice.pending_balance && invoice.invoice_id) {
                                    if (invoice.bank_fee_payer === 'money_sender') {
                                        groupWithFee.total_pending_amount = new Decimal(
                                            groupWithFee.total_pending_amount
                                        )
                                            .add(invoice.pending_balance)
                                            .toDecimalPlaces(2)
                                            .toNumber();
                                        groupWithFee.invoice_ids.push(invoice.invoice_id);
                                    } else {
                                        groupWithoutFee.total_pending_amount = new Decimal(
                                            groupWithoutFee.total_pending_amount
                                        )
                                            .add(invoice.pending_balance)
                                            .toDecimalPlaces(2)
                                            .toNumber();
                                        groupWithoutFee.invoice_ids.push(invoice.invoice_id);
                                    }
                                }
                            }
                            break;
                        }
                    }
                }
                if (groupWithFee.invoice_ids.length > 0) {
                    this.invoiceGroups.push(groupWithFee);
                }
                if (groupWithoutFee.invoice_ids.length > 0) {
                    this.invoiceGroups.push(groupWithoutFee);
                }
            });
            this.remainingAmount = this.invoiceGroups
                .reduce((acc: Decimal, group: IInvoiceGroup) => acc.add(group.total_pending_amount), new Decimal(0))
                .toDecimalPlaces(2)
                .toNumber();
        }
        if (this.invoiceGroups.length === 0) {
            this.close();
        }
        if (this.methods.length === 1 && this.invoiceGroups.length === 1) {
            this.selectGroup(this.invoiceGroups[0]);
        }
    }

    selectGroup(group: IInvoiceGroup) {
        this.selectedGroup = group;
        this.paymentAmount.patchValue(group.total_pending_amount);
        this.paymentAmount.disable();
    }

    changeAmount($event: MatRadioChange) {
        if ($event.value === 'full') {
            this.paymentAmount.patchValue(this.selectedGroup?.total_pending_amount);
            this.paymentAmount.disable();
        } else {
            this.paymentAmount.enable();
        }
    }

    validateAmount($event: any) {
        const inputValue = Number(cleanCurrencyString($event.target.value));
        if (this.selectedGroup && this.selectedGroup.total_pending_amount) {
            if (inputValue > this.selectedGroup?.total_pending_amount || inputValue === 0) {
                this.paymentAmount.setValidators([Validators.email]);
                this.paymentAmount.updateValueAndValidity();
                this.paymentAmount.markAsTouched();
            } else {
                this.paymentAmount.clearValidators();
                this.paymentAmount.updateValueAndValidity();
            }
        }
    }

    makePayment() {
        if (this.paymentAmount.invalid) {
            this.paymentAmount.markAsTouched();
            return;
        }
        if (this.selectedGroup) {
            let amount = 0;
            if (this.paymentAmount.disabled) {
                amount = Number(this.selectedGroup.total_pending_amount);
            } else {
                amount = Number(cleanCurrencyString(this.paymentAmount.value));
            }
            this.selectedGroup.payment_amount = amount;
            this.dialogRef.close(this.selectedGroup);
        }
    }

    back() {
        this.selectedGroup = null;
    }

    close() {
        this.dialogRef.close(null);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
