import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {CompensationProfileModel} from './models/compensation-profile.model';
import {GenericFormGroup} from '../../../entites/generic.entity';
import {NotificationsServiceZipi} from '../../notifications/notifications.service';
import {Validators} from '@angular/forms';
import {FinancialElementModel} from './models/financial-element.model';

@Injectable()
export class CompensationServiceApi {
    constructor(protected serviceRequester: ServiceRequester) {}

    putCompensationProfile(cpData: any) {
        return this.serviceRequester.makeMsCall('/compensation-profile', 'PUT', cpData);
    }

    putCompensationProfiles(cpData: any) {
        return this.serviceRequester.makeMsCall('/compensation-profile/items', 'PUT', cpData);
    }

    putCompensationProfileOrder(cpId: number, cpOrder: number) {
        return this.serviceRequester.makeMsCall('/compensation-profile/order/' + cpId, 'PUT', {order: cpOrder});
    }

    putBulkCompensationProfileOrder(cpArray: any[]) {
        return this.serviceRequester.makeMsCall('/compensation-profile/order/bulk', 'PUT', cpArray);
    }

    putBulkCompensationProfileOrderWithoutRecalculate(cpArray: any[]) {
        return this.serviceRequester.makeMsCall('/compensation-profile/order/bulk-only', 'PUT', cpArray);
    }

    deleteCompensationProfile(cpId: number) {
        return this.serviceRequester.makeMsCall('/compensation-profile/' + cpId, 'DELETE');
    }

    postCompensationProfile(cpData: any) {
        return this.serviceRequester.makeMsCall('/compensation-profile/', 'POST', cpData);
    }

    getCompensationProfiles() {
        return this.serviceRequester
            .makeMsCallPromise<{result: CompensationProfileModel[]}>({
                method: 'GET',
                url: '/compensation-profile/list'
            })
            .then((res) => res.result);
    }

    getFinancialElementsForCompensationProfiles(compensationProfileIds: number[]) {
        return this.serviceRequester
            .makeMsCallPromise<{result: Record<number, FinancialElementModel[]>}>({
                method: 'POST',
                url: '/compensation-profile/financial-elements/list',
                data: {compensation_profile_ids: compensationProfileIds}
            })
            .then((res) => res.result);
    }

    getEntityCompensationProfilesListItem(scrollData: {
        limit: number;
        offset: number;
        search: string | null | undefined;
    }) {
        if (!scrollData.search) {
            delete scrollData.search;
        }
        return this.serviceRequester.makeMsCall$(
            '/compensation-profile/compensation-list-items',
            'GET',
            'zipi',
            scrollData
        );
    }

    createOverrideCompensationProfile(salesEntityId: number, cpData: any): Promise<any> {
        return this.serviceRequester.makeMsCall('/compensation-profile/override/' + salesEntityId, 'POST', cpData);
    }

    batchCreateOverriddenCompensationProfile(cpData: any): Promise<any> {
        return this.serviceRequester.makeMsCall('/compensation-profile/override/', 'POST', cpData);
    }

    batchDeleteOverriddenCompensationProfile(cpData: any): Promise<any> {
        return this.serviceRequester.makeMsCall('/compensation-profile/delete-overridden/', 'POST', cpData);
    }

    deleteOverrideCompensationProfile(cpId: number): Promise<any> {
        return this.serviceRequester.makeMsCall('/compensation-profile/override/' + cpId, 'DELETE');
    }
}

@Injectable()
export class CompensationService {
    constructor(
        public api: CompensationServiceApi,
        protected notificationService: NotificationsServiceZipi
    ) {}

    updateCompensationProfile(cpdata: CompensationProfileModel): Promise<CompensationProfileModel> {
        return this.api.putCompensationProfile(cpdata);
    }

    updateCompensationProfiles(cpdata: any): Promise<{success: boolean; products_in_use?: number[]}> {
        return this.api.putCompensationProfiles(cpdata);
    }

    isCompensationProfileFormValid(compensationProfileForm: GenericFormGroup<CompensationProfileModel>): boolean {
        compensationProfileForm.markAllAsTouched();
        compensationProfileForm.updateValueAndValidity({emitEvent: false});
        if (compensationProfileForm.invalid) {
            this.notificationService.addError('Compensation: Please fill all the required fields');
            return false;
        }
        return true;
    }

    setupCompensationProfileFormValidators(compensationProfileForm: GenericFormGroup<CompensationProfileModel>) {
        compensationProfileForm.controls.financial_elements!.controls.forEach((element) => {
            element.controls.rules!.controls.forEach((rule) => {
                if (rule.controls.disbursement_template && rule.controls.disbursement_template.controls) {
                    rule.controls.disbursement_template.controls.additional_incomes!.controls.forEach((control) => {
                        control.controls.product_fk_id!.setValidators([Validators.required]);
                        control.controls.product_fk_id!.updateValueAndValidity({emitEvent: false});
                    });

                    rule.controls.disbursement_template.controls.additional_expenses!.controls.forEach((control) => {
                        control.controls.product_fk_id!.setValidators([Validators.required]);
                        control.controls.product_fk_id!.updateValueAndValidity({emitEvent: false});
                    });
                }
            });
        });
    }
}
