import {Component, Input} from '@angular/core';
import {entityRouterListByJournalType} from '../../../../constants';

@Component({
    selector: 'reports-preview-ap-aging',
    templateUrl: './ap-aging.component.html',
    styleUrls: ['./ap-aging.component.scss']
})
export class APAgingPreviewComponent {
    @Input() reportData: {[key: string]: any} = {};
    @Input()
    set report(data: any) {
        this._report = data;
        this.checkExpandStateDependOnReportSettings();
    }
    get report() {
        return this._report;
    }

    private _report: any = null;
    public entityRouterListByJournalType = entityRouterListByJournalType;
    public isExpanded: Boolean = true;
    public expandTooltip: String = 'Collapse All';

    checkExpandStateDependOnReportSettings() {
        const reportView = this.report.settings.report_view;
        this.isExpanded = reportView && reportView === 'detailed';
        this.expandTooltip = this.isExpanded ? 'Collapse All' : 'Expand All';
    }

    toggleSection(item: any): void {
        item.is_expanded = !item.is_expanded;
    }

    toggleExpand() {
        this.reportData.contacts = this.reportData.contacts.map((contact: any) => ({
            ...contact,
            is_expanded: !this.isExpanded
        }));
        this.isExpanded = !this.isExpanded;
        this.expandTooltip = this.isExpanded ? 'Collapse All' : 'Expand All';
    }
}
