import {Component, Input} from '@angular/core';
import {IReportsPreviewResultData} from '../../../../store/reports.reducer';

@Component({
    selector: 'reports-preview-account-type-summary',
    templateUrl: './account-type-summary.component.html',
    styleUrls: ['./account-type-summary.component.scss']
})
export class AccountTypeSummaryPreviewComponent {
    @Input() reportData: IReportsPreviewResultData = {
        data: []
    };

    constructor() {}
}
