<mat-toolbar class="company-users-toolbar mat-elevation-z2"></mat-toolbar>

<div class="header-buttons ml-4 d-flex justify-content-between">
    <div class="left-buttons d-flex">
        <h3 *ngIf="router.url.includes('/directory/users')" class="mb-0 d-flex align-items-center">User Profiles</h3>
        <h3 *ngIf="router.url.includes('/directory/divisions')" class="mb-0 d-flex align-items-center">Offices</h3>
        <h3 *ngIf="router.url.includes('/directory/groups')" class="mb-0 d-flex align-items-center">Groups</h3>
        <h3 *ngIf="router.url.includes('/directory/roles')" class="mb-0 d-flex align-items-center">Roles</h3>

        <!------- Filters ------->
        <button
            mat-raised-button
            class="ml-3"
            (click)="bulkActionEmitter.emit('profile_filters')"
            *ngIf="section === 'users-licenses' && filterSelectResendEnabledFlag"
        >
            Filter
        </button>
        <div class="ml-3 mt-2" *ngIf="section === 'users-licenses' && filterSelectResendEnabledFlag">
            <mat-checkbox
                #checkbox
                (click)="$event.stopPropagation()"
                (change)="toggleSelectAllCheckbox($event)"
            ></mat-checkbox>
        </div>
        <!------- Add profiles to group ------->
        <button
            mat-raised-button
            class="ml-3"
            color="warn"
            *ngIf="profileIdsToAdd.length && section === 'users-licenses'"
            [matMenuTriggerFor]="bulkActions"
        >
            Bulk actions
        </button>
        <mat-menu #bulkActions="matMenu">
            <div
                mat-menu-item
                *ngIf="profileIdsToAdd.length && filterSelectResendEnabledFlag"
                rbac
                [rule]="{company_settings__manage_users: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                (click)="bulkActionEmitter.emit('send_invite')"
            >
                Send Invite
            </div>
            <div
                mat-menu-item
                *ngIf="profileIdsToAdd.length"
                rbac
                [rule]="{company_settings__manage_users: true, company_settings__manage_groups: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                (click)="bulkActionEmitter.emit('group')"
            >
                Add to group
            </div>
            <div
                mat-menu-item
                *ngIf="profileIdsToAdd.length"
                rbac
                [rule]="{company_settings__manage_users: true, company_settings__manage_groups: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                (click)="bulkActionEmitter.emit('division')"
            >
                Add to division
            </div>
        </mat-menu>
    </div>

    <i class="sp-spacer"></i>

    <!------- For section users-licenses ------->
    <div class="right-buttons" *ngIf="section === 'users-licenses'">
        <!------- Create new Profile ------->
        <button
            mat-raised-button
            class="mat-primary ml-3"
            style="top: -2px"
            rbac
            [rule]="{company_settings__manage_users: true}"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            [disabled]="profileIdsToAdd && profileIdsToAdd.length > 1"
            (click)="createUserEmitter.emit()"
        >
            Create New Profile
        </button>
        <!------- Button expand/collapse profiles ------->
        <button mat-icon-button class="mx-2" (click)="changeAllDetailsEmitter.emit()">
            <mat-icon *ngIf="!this.profileIdsForDetailsLength">fullscreen</mat-icon>
            <mat-icon *ngIf="this.profileIdsForDetailsLength">fullscreen_exit</mat-icon>
        </button>
    </div>

    <!------- For section divisions ------->
    <div class="right-buttons mr-4" *ngIf="section === 'divisions'">
        <button
            mat-raised-button
            class="mat-primary header-button"
            rbac
            [rule]="{company_settings__manage_divisions: true}"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            [disabled]="createModeDivision"
            (click)="enterCreationModeDivisionEmitter.emit()"
        >
            Create Division
        </button>
    </div>

    <!------- For section groups ------->
    <div class="right-buttons mr-4" *ngIf="section === 'groups'">
        <button
            mat-raised-button
            class="mat-primary header-button"
            [rule]="{company_settings__manage_groups: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            [disabled]="createModeGroup"
            (click)="enterCreationModeGroupEmitter.emit()"
        >
            Create Group
        </button>
    </div>

    <!------- For section roles ------->
    <div class="right-buttons mr-4 mb-3" *ngIf="section === 'roles'">
        <!------- Button create role mb-3 ------->
        <button
            mat-raised-button
            class="mat-primary ml-3"
            [rule]="{company_settings__manage_roles: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            (click)="createCompanyRoleEmitter.emit($event)"
            *ngIf="!isEditMode"
        >
            Create New Role
        </button>
    </div>
</div>
