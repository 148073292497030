import {Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {listOfCompensationProfileType, listOfBooleanCondition} from '../../../../constants';
import {getNextActivePanel, isLastPanelItem} from '../../helper';
import {IReport, IReportSetting} from '@cyberco-nodejs/zipi-typings';
import {ComboBoxOption} from '@app/modules/shared/components/multi-select-combo-box/multi-select-combo-box.component';
import {CompensationServiceApi} from '@app/modules/account-info/compensation/compensation.service';
import {FinancialElementModel} from '@app/modules/account-info/compensation/models/financial-element.model';
import {GenericEntity} from '@app/entites/generic.entity';

type CapReportSettings = Pick<
    IReportSetting,
    | 'entities'
    | 'compensation_profile_type'
    | 'compensation_profile_ids'
    | 'compensation_profile_financial_element_ids'
    | 'is_include_cap_condition'
>;

@Component({
    selector: 'app-report-edit-bar-cap',
    templateUrl: './cap.component.html',
    styleUrls: ['./cap.component.scss']
})
export class CapBarComponent implements OnInit, OnDestroy {
    @Output() valueChanges = new EventEmitter<CapReportSettings>();
    @Output() action = new EventEmitter<{
        type: string;
        data: Partial<CapReportSettings> | null;
    }>();

    @Input() report: IReport | null = null;

    private defaultSettings: Partial<CapReportSettings> = {is_include_cap_condition: true};
    public reportSettings: Partial<CapReportSettings> = this.defaultSettings;

    public listOfCompensationProfileType = listOfCompensationProfileType;
    public listOfBooleanCondition = listOfBooleanCondition;
    public activePanel: keyof CapReportSettings = 'entities';
    public isShowNext: boolean = true;

    public readonly reportOptionPanels: Array<{value: keyof CapReportSettings; title: string}> = [
        {value: 'entities', title: 'Customers'},
        {value: 'compensation_profile_type', title: 'Deal Element Type'},
        {value: 'compensation_profile_ids', title: 'Rules'},
        {value: 'is_include_cap_condition', title: 'Include Condition'}
    ];

    public selectedCompensationProfiles: ComboBoxOption[] = [];
    public availableCompensationProfiles: ComboBoxOption[] = [];

    private dealElementsByProfile: Record<number, FinancialElementModel[]> = {};
    public availableDealElements: ComboBoxOption[] = [];
    public selectedDealElements: ComboBoxOption[] = [];
    public isDealElementLoading: boolean = false;

    constructor(protected compensationServiceApi: CompensationServiceApi) {}

    async ngOnInit() {
        if (this.report) {
            this.reportSettings = {...this.defaultSettings, ...this.report.settings};
        }

        const profiles = await this.compensationServiceApi.getCompensationProfiles();
        this.availableCompensationProfiles = profiles
            .filter((profile) => profile.title && profile.id && profile.status === 'active')
            .map((profile) => ({
                value: String(profile.id),
                label: profile.title!
            }));

        const profileIds = this.reportSettings.compensation_profile_ids;
        if (profileIds?.length) {
            this.selectedCompensationProfiles = this.availableCompensationProfiles.filter((profile) =>
                profileIds?.includes(Number(profile.value))
            );

            await this.displayDealElementNamesFromSelectedProfiles();
            this.selectedDealElements = this.availableDealElements.filter((dealElement) =>
                this.reportSettings.compensation_profile_financial_element_ids?.includes(Number(dealElement.value))
            );
        }
    }

    async onCompProfileChange(event: ComboBoxOption[]) {
        const currentIds = this.selectedCompensationProfiles.map((p) => p.value);
        const nextIds = event.map((p) => p.value);
        if (areArraysDifferent(currentIds, nextIds)) {
            this.selectedCompensationProfiles = event;
            await this.displayDealElementNamesFromSelectedProfiles();
        } else {
            this.selectedCompensationProfiles = event;
        }
    }

    async displayDealElementNamesFromSelectedProfiles() {
        this.availableDealElements = [];
        this.selectedDealElements = [];

        const selectedProfileIds = this.selectedCompensationProfiles.map((profile) => Number(profile.value));
        const profilesMissingDealElements = selectedProfileIds.filter((id) => !this.dealElementsByProfile[id]);

        if (profilesMissingDealElements.length > 0) {
            this.isDealElementLoading = true;
            const dealElementsByProfile =
                await this.compensationServiceApi.getFinancialElementsForCompensationProfiles(
                    profilesMissingDealElements
                );
            this.isDealElementLoading = false;

            for (const profileId in dealElementsByProfile) {
                this.dealElementsByProfile[profileId] = dealElementsByProfile[profileId].map((finEl) =>
                    GenericEntity.FABRIC(FinancialElementModel).hydrate(finEl)
                );
            }
        }

        this.availableDealElements = this.selectedCompensationProfiles.flatMap((profile) => {
            const profileId = Number(profile.value);
            const dealElements = this.dealElementsByProfile[profileId];
            const options: ComboBoxOption[] = dealElements.map((dealElement) => ({
                value: String(dealElement.id),
                label: dealElement.title,
                groupId: profileId,
                groupLabel: profile.label
            }));
            return options;
        });
    }

    onSelectOptions(event: {value: keyof CapReportSettings}) {
        this.activePanel = event.value;
        this.isShowNext = !isLastPanelItem(this.activePanel, this.reportOptionPanels);
    }

    onValueChanges<P extends keyof CapReportSettings>(property: P, data: CapReportSettings[P]) {
        this.reportSettings[property] = data;
    }

    onAction(event: string) {
        this.reportSettings.compensation_profile_ids = this.selectedCompensationProfiles.map((rule) =>
            Number(rule.value)
        );
        this.reportSettings.compensation_profile_financial_element_ids = this.selectedDealElements.map((finEl) =>
            Number(finEl.value)
        );

        const response: {
            type: string;
            data: Partial<CapReportSettings> | null;
        } = {type: event, data: null};

        switch (event) {
            case 'save':
            case 'run':
                response['data'] = this.reportSettings;
                this.action.emit(response);
                break;
            case 'cancel':
            case 'delete':
                this.action.emit(response);
                break;
            case 'next':
                const nextData = getNextActivePanel(this.activePanel, this.reportOptionPanels);
                if (nextData.name === '') break;
                this.activePanel = nextData.name;
                this.isShowNext = !nextData.isLast;
                break;
            default:
        }
    }

    ngOnDestroy() {
        this.action.complete();
    }
}

function areArraysDifferent(a: unknown[], b: unknown[]) {
    return a.length !== b.length || symmetricDifference(a, b).length > 0;
}

function symmetricDifference(a: unknown[], b: unknown[]) {
    return a.filter((x) => !b.includes(x)).concat(b.filter((x) => !a.includes(x)));
}
