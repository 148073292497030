import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateFromNumber'
})
export class DateFromNumberPipe implements PipeTransform {
    transform(value: any, args?: any): string {
        if (value && typeof value === 'number') {
            return moment(value, 'YYYYMMDD').format('YYYY-MM-DD');
        }
        return '';
    }
}
