import {Component, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {BaseWidgetComponent} from '../base.widget.component';
import {Deal} from '../../../../../../models/deal';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {Widget} from '../../../../../../models/widget';

@Component({
    selector: 'app-production-widget',
    templateUrl: './production.widget.component.html',
    styleUrls: ['../widgets.scss']
})
export class ProductionWidgetComponent extends BaseWidgetComponent implements OnDestroy, OnChanges {
    pieChartColorScheme = {
        domain: ['#3DC765', '#e5e5e5']
    };

    slug = 'income';
    DEAL = Deal;

    private unsubscribeSub: Subject<void> = new Subject();
    public isDataExist = true;
    public pie_ChartDataNgx1: {name: string; value: number}[] = [];
    public pie_ChartDataNgx2: {name: string; value: number}[] = [];
    public idFormName = 'income-form-' + this.randomNum;
    public startDateControl: UntypedFormControl = new UntypedFormControl();
    public endDateControl: UntypedFormControl = new UntypedFormControl();

    // public pie_ChartOptions = {
    //     pieHole: 0.7,
    //     width: 150,
    //     height: 150,
    //     pieSliceText: 'none',
    //     legend: 'none',
    //     tooltip: { trigger: 'none' },
    //     backgroundColor: { fill: 'transparent' },
    //     chartArea: {left: 10, top: 10, width: '130', height: '130'},
    //     slices: {
    //         0: { color: '#3DC765' },
    //         1: { color: '#e5e5e5' }
    //     }
    // };

    /**
     * On init
     */
    onInit() {
        this.entitiesControl.valueChanges.pipe(takeUntil(this.unsubscribeSub)).subscribe((changes) => {
            this.handleEntityPicker(changes);
        });
    }

    initSubscription() {
        super.initSubscription();

        this.startDateControl.valueChanges.pipe(takeUntil(this.unsubscribeSub)).subscribe((val) => {
            this.widget!.settings.period_start_date = val;
        });

        this.endDateControl.valueChanges.pipe(takeUntil(this.unsubscribeSub)).subscribe((val) => {
            this.widget!.settings.period_end_date = val;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.customizeVisibility && !changes.customizeVisibility.currentValue && this.configPanelVisible) {
            this.hideConfigPanel();
        }
    }

    processData(widget: Widget) {
        this.data = widget.data;

        const settings = this.data.settings || {};
        this.startDateControl.setValue(settings.period_start_date);
        this.endDateControl.setValue(settings.period_end_date);

        if (Object.keys(this.data['widget_data']).length === 0) {
            return (this.isDataExist = false);
        }

        let leftData1 = 0;
        let leftData2 = 0;

        if (1 - widget.data.widget_data.units / widget.data.settings.units_goal > 0) {
            leftData1 = 1 - widget.data.widget_data.units / widget.data.settings.units_goal;
        } else {
            leftData1 = 0;
        }

        if (1 - widget.data.widget_data.volume / widget.data.settings.volume_goal > 0) {
            leftData2 = 1 - widget.data.widget_data.volume / widget.data.settings.volume_goal;
        } else {
            leftData2 = 0;
        }

        // this.pie_ChartData1 = [
        //     ['Task', 'Hours per Day'],
        //     ['Progress', widget.data.widget_data.units / widget.data.settings.units_goal],
        //     ['Left', leftData1]
        // ];
        this.pie_ChartDataNgx1 = [
            {name: 'green', value: widget.data.widget_data.units / widget.data.settings.units_goal},
            {name: 'grey', value: leftData1}
        ];

        // this.pie_ChartData2 = [
        //     ['Task', 'Hours per Day'],
        //     ['Progress', widget.data.widget_data.volume / widget.data.settings.volume_goal],
        //     ['Left', leftData2]
        // ];
        this.pie_ChartDataNgx2 = [
            {name: 'green', value: widget.data.widget_data.volume / widget.data.settings.volume_goal},
            {name: 'grey', value: leftData2}
        ];
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.unsubscribeSub.next();
        this.unsubscribeSub.complete();
        super.ngOnDestroy();
    }
}
