<div mat-dialog-title>
    <h3>Create/Edit Yodata Profile/Division POD URI</h3>
</div>

<mat-dialog-content>
    <form [formGroup]="entityForm">
        <div class="row no-gutters">
            <div class="col-md-12">
                <mat-form-field class="w-100">
                    <mat-placeholder>Name</mat-placeholder>
                    <input matInput [disabled]="true" required type="text" formControlName="name" />
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field class="w-100">
                    <mat-placeholder>Agent/Office POD</mat-placeholder>
                    <mat-icon
                        matSuffix
                        [matTooltip]="
                            'Example: https://{entityid}.example.com/profile/card#me for profile or https://CA706-001.bhhs.dev.yodata.io/profile/card#me for division (office)'
                        "
                        >help_outline</mat-icon
                    >
                    <input matInput required type="text" formControlName="agent_office_pod" />
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
    <div class="d-flex justify-content-start">
        <button
            type="button"
            class="mr-2"
            *ngIf="isAllowedNewMapping"
            mat-raised-button
            (click)="closeForm(false, true)"
            tabindex="-1"
        >
            Create New Contact
        </button>
    </div>
    <div class="d-flex justify-content-end">
        <button
            type="button"
            class="mr-2"
            *ngIf="!data.hideCancel"
            mat-raised-button
            (click)="closeForm(false)"
            tabindex="-1"
        >
            Cancel
        </button>
        <button mat-raised-button type="button" class="mr-2" color="primary" (click)="closeForm(true)" tabindex="1">
            {{ data.buttonOkMessage || 'Save' }}
        </button>
    </div>
</mat-dialog-actions>
