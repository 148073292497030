import * as flagsmith from 'flagsmith';
import {Injectable} from '@angular/core';
import {AppInitSource} from '../../services/sources/app-init.source';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, filter, firstValueFrom, Observable, Subject} from 'rxjs';
import {AppInitResponse} from '../../services/api/app-init.api';

interface Flags {
    [key: string]: {id: number; enabled: boolean; value: any};
}

@Injectable()
export class FeatureFlagsService {
    public isFlagsLoadedSubject = new Subject<boolean>();
    public isFlagsLoaded: boolean = false;
    private flagsSubject = new BehaviorSubject<Flags | null>(null);
    private defaultFlagsmith: any;

    constructor(protected initAppSource: AppInitSource) {
        this.initAppSource.appInit.subscribe((appInitResponse) => {
            this.defaultFlagsmith = (flagsmith as any).default;

            const userId = this._buildUserIdForFlagsmith(appInitResponse);
            const traits = this._buildTraitsForFlagsmith(appInitResponse);
            if (!userId || !traits) {
                return;
            }
            // this will get flags only for current user
            // if this user not exist, it will take default flags

            this.defaultFlagsmith.init({
                environmentID: environment.flagsmithKey,
                cacheFlags: false,
                enableAnalytics: true,
                onChange: (
                    flags: Flags,
                    params: {isFromServer: boolean; flagsChanged: boolean; traitsChanged: boolean}
                ) => {
                    // Occurs whenever flags are changed
                    // update flags subscription

                    if (flags && Object.keys(flags).length > 0) {
                        this.setFlags(flags);
                        this.isFlagsLoadedSubject.next(true);
                        this.isFlagsLoaded = true;
                    }

                    // const {isFromServer} = params; // determines if the update came from the server or local cached storage

                    // Check for a feature
                    // if (flagsmith.hasFeature("myCoolFeature")){
                    //     // myCoolFeature();
                    // }
                    //
                    // //Or, use the value of a feature
                    // const bannerSize = flagsmith.getValue("bannerSize");

                    // Check whether value has changed
                    // const bannerSizeOld = oldFlags["bannerSize"] && oldFlags["bannerSize"].value;
                    // if (bannerSize !== bannerSizeOld) {
                    //
                    // }
                },
                onError: (err: {message: string}) => {
                    console.error('Flagsmith failure on retrieve flags: ', err);

                    // if smth happen - reset all feature flags to disable all features which are disabled by default
                    this.setFlags({});
                }
            });
            this.defaultFlagsmith.identify(userId);

            // this will do request to flagsmith api and get flags and fire onChange event
            this.defaultFlagsmith.setTraits(traits);

            // NOTE: for some reason the first request to flagsmith shows empty flags, so getFlags() will do another request to flagsmith
            // it will do request to flagsmith api and get flags and fire onChange event
            // UPD: no need this, because defaultFlagsmith.setTraits will trigger getFlags
            // defaultFlagsmith.getFlags();
        });
    }

    onFlagsChange(): Observable<Flags | null> {
        return this.flagsSubject.asObservable();
    }

    getCurrentFlags(): Flags | null {
        return this.flagsSubject.getValue();
    }

    setFlags(flags: any) {
        this.flagsSubject.next(flags);
    }

    isFeatureEnabled(feature: string): boolean {
        const featureFlags = this.getCurrentFlags();
        return (featureFlags && featureFlags[feature] && featureFlags[feature].enabled) || false;
    }

    getFlagsByApiCall() {
        return this.defaultFlagsmith.getAllFlags();
    }

    _sanitizeSymbols(str: string) {
        // replace all symbols to '*' except Alphanumeric
        return str.replace(/[^0-9a-zA-Z]+/g, '*');
    }

    _buildUserIdForFlagsmith(appInitResponse: AppInitResponse) {
        if (!appInitResponse.currentProfile) {
            return null;
        }
        // return profileId + '_' + profileFname + '_' + profileLname;
        // return profileId + '_' + profileEmail + '_' + companyTitle;
        return appInitResponse.currentProfile.id + '';
    }

    _calcDaysTillNow(dateString: string) {
        if (!dateString) {
            return 'N/A';
        }

        const date1 = new Date(dateString);
        const date2 = new Date();

        const differenceMS = date2.getTime() - date1.getTime();

        const differenceInDays = Math.round(differenceMS / (1000 * 3600 * 24));
        return differenceInDays + '';
    }

    _buildTraitsForFlagsmith(appInitResponse: AppInitResponse) {
        if (!appInitResponse.currentProfile) {
            return null;
        }
        const {
            company,
            user,
            // permissions: permissionsObj,
            first_name,
            last_name,
            email: profileEmail
        } = appInitResponse.currentProfile;
        // const availablePermissionsString = Object.keys(permissionsObj)
        //     .filter(permKey => permissionsObj[permKey])
        //     .join(', ');
        const subscr = company?.access_plan || '';
        // const subscr = company && Array.isArray(company.zoho_subscription)
        //     ? company.zoho_subscription
        //         .filter(zs => ['live', 'trial'].includes(zs.zoho_subscription_status))
        //         .map(zs => zs.plan)
        //         .join(', ')
        //     : '';

        return {
            company_id: company ? company.id + '' : '',
            company_title: company ? company.title : '',
            company_subscription_plan: subscr,
            // permissions: availablePermissionsString.slice(0, 1999), // TODO must be < 2000 symbols
            // profile_addons: '', // TODO
            // user_addons: '', // TODO
            user_email: user ? user.email : '',
            user_last_accessed_days:
                user && user.last_login // Days Since User Last Accessed System
                    ? this._calcDaysTillNow(user.last_login)
                    : '',
            user_id: user ? user.id + '' : '',
            profile_email: profileEmail,
            profile_full_name: first_name + ' ' + last_name
        };
    }
}
