import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {LineNotification} from './kinds/line.notification';
import {BaseNotification} from './kinds/base.notification';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmNotification, ConfirmNotificationDialogComponent} from './kinds/confirm.notification';
import {ReactionNotification, ReactionPossibility} from './kinds/reaction.notification';
import {ReloadNotification} from './kinds/reload.notification';

const TYPE_INFO = 'info';
const TYPE_ERROR = 'error';
const TYPE_WARNING = 'warning';

@Injectable()
export class NotificationsServiceZipi {
    constructor(public matDialog: MatDialog) {
        this.notifications = new Subject();
    }

    public notifications: Subject<BaseNotification>;

    private pushNotification(
        notification: LineNotification | ReloadNotification | ConfirmNotification | ReactionNotification
    ) {
        this.notifications.next(notification);
    }

    public currentModeInline() {
        if (this.matDialog.openDialogs.length === 0) {
            return true;
        }
    }

    public currentModePopup() {
        if (this.matDialog.openDialogs.length !== 0) {
            return true;
        }
    }

    public addInfo(text: string, isQuitable: boolean = true) {
        const notification = new LineNotification();

        notification.setMessage(text);
        notification.setType(TYPE_INFO);
        notification.setQuitableFlag(isQuitable);

        this.pushNotification(notification);
    }

    public addError(text: string, isQuitable: boolean = true) {
        const notification = new LineNotification();

        notification.setMessage(text);
        notification.setType(TYPE_ERROR);
        notification.setQuitableFlag(isQuitable);

        this.pushNotification(notification);
    }

    public addAdvancedError(messageObj: {message: string; objectLink: {}}, isQuitable: boolean = true) {
        const notification = new LineNotification();

        notification.setMessage(messageObj.message);
        notification.setType(TYPE_ERROR);
        notification.setQuitableFlag(isQuitable);
        notification.setObjectLink(messageObj.objectLink);

        this.pushNotification(notification);
    }

    public addWarning(text: string, isQuitable: boolean = true) {
        const notification = new LineNotification();

        notification.setMessage(text);
        notification.setType(TYPE_WARNING);
        notification.setQuitableFlag(isQuitable);

        this.pushNotification(notification);
    }

    public addConfirm(text: string): MatDialogRef<ConfirmNotificationDialogComponent> | null {
        const notification = new ConfirmNotification(this.matDialog);

        notification.setMessage(text);
        notification.setType(TYPE_WARNING);

        this.pushNotification(notification);

        return notification.getDialogRef();
    }

    public addReload() {
        const notification = new ReloadNotification();

        notification.setType(TYPE_WARNING);

        this.pushNotification(notification);
    }

    public addReaction(
        message: string,
        type: 'error' | 'info' | 'warning',
        title = '',
        possibilitiesList: ReactionPossibility[] = []
    ) {
        const notification = new ReactionNotification();

        notification.setMessage(message);
        notification.setType(type);
        notification.setTitle(title);

        possibilitiesList.forEach((poss) => {
            notification.addPossibility(poss);
        });

        this.pushNotification(notification);
    }

    public addCustom() {
        // todo implement later
    }
}
