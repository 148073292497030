<div mat-dialog-title class="d-flex justify-content-between">
    <h3>Apply Credits to Bill</h3>
    <button type="button" mat-icon-button (click)="dialogRef.close(false)">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div class="apply-calculations">
        <p>
            Bill Balance: <span class="apply-amount">{{ pendingBalance | currency }}</span>
        </p>
    </div>

    <form [formGroup]="formGroup" class="invoices-form my-3">
        <table mat-table [dataSource]="dataSource" class="w-100" matSort>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                <td mat-cell *matCellDef="let element">{{ element.date | dateFromNumber | date: 'mediumDate' }}</td>
            </ng-container>

            <ng-container matColumnDef="credit_info">
                <th mat-header-cell *matHeaderCellDef>Credit Info</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element?.vendor_credit_id">Vendor Credit</span>
                    <span *ngIf="element?.payment_made_id">Excess Payment</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="credit_number">
                <th mat-header-cell *matHeaderCellDef>Credit Number</th>
                <td mat-cell *matCellDef="let element">
                    <a *ngIf="element?.vendor_credit_id" (click)="routeToElement(element)" href="javascript:">
                        <span *ngIf="element?.vendor_credit_number">{{ element.vendor_credit_number }}</span>
                        <span *ngIf="!element?.vendor_credit_number">N/A</span>
                    </a>
                    <a *ngIf="element?.payment_made_id" (click)="routeToElement(element)" href="javascript:">
                        <span *ngIf="element?.payment_made_number">{{ element.payment_made_number }}</span>
                        <span *ngIf="!element?.payment_made_number">N/A</span>
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">{{ element.status }}</td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef>Balance</th>
                <td mat-cell *matCellDef="let element">{{ element.balance | currency }}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Amount To Bill</th>
                <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroup]="creditsArray.controls[i]"
                    style="width: 140px"
                >
                    <mat-form-field>
                        <input
                            matInput
                            type="text"
                            [maskito]="currencyMaskitoMask"
                            autocomplete="off"
                            formControlName="amount_to_bill"
                            (blur)="onBlurCredit(i)"
                        />
                    </mat-form-field>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="zp-table-row"></tr>
        </table>
    </form>
    <div *ngIf="dataSource.data.length === 0" class="m-3 text-center text-muted">No credits for apply</div>
    <div class="apply-calculations">
        <p>
            Amount: <span class="apply-amount">{{ amountFromCredits | currency }}</span>
        </p>
        <p>
            Remaining: <span class="apply-amount">{{ remaining | currency }}</span>
        </p>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end align-items-end">
    <button
        mat-raised-button
        class="mr-3"
        color="primary"
        (click)="save()"
        tabindex="1"
        [disabled]="remaining < 0 || dataSource.data.length === 0 || amountFromCredits === 0"
    >
        Save
    </button>
    <button mat-raised-button (click)="dialogRef.close()" tabindex="-1">Cancel</button>
</mat-dialog-actions>
