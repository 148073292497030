<div *ngIf="locationCtrl" [formGroup]="locationCtrl" class="d-flex align-items-md-center bg-light rounded p-2 mb-2">
    <div *ngIf="isEditingMode === false" class="sp-location sp-location__preview">
        <span *ngIf="locationCtrl.value.label">{{ locationCtrl.value.label + ':' }}</span>
        <span *ngIf="locationCtrl.value.street_number">{{ locationCtrl.value.street_number }}</span>
        <span *ngIf="locationCtrl.value.street_address">{{ locationCtrl.value.street_address }}</span>
        <span *ngIf="locationCtrl.value.unit_number">{{ locationCtrl.value.unit_number }}</span>
        <span *ngIf="locationCtrl.value.city">{{ locationCtrl.value.city + ',' }}</span>
        <span *ngIf="locationCtrl.value.state">{{ locationCtrl.value.state }}</span>
        <span *ngIf="locationCtrl.value.zip">{{ locationCtrl.value.zip }}</span>
        <span *ngIf="locationCtrl.value.country" class="text-muted">({{ locationCtrl.value.country }})</span>

        <!-- <span *ngIf="locationCtrl.value.abbr">{{locationCtrl.value.abbr + ','}}</span>  -->
    </div>

    <div *ngIf="isEditingMode" class="row sp-location sp-location__edit">
        <div class="col">
            <mat-form-field class="w-100">
                <input type="text" matInput formControlName="label" placeholder="Location Name" />
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field class="w-100">
                <input
                    type="text"
                    matInput
                    formControlName="street_number"
                    placeholder="Street #"
                    [errorStateMatcher]="errMatcher"
                    required
                />
                <mat-error align="end" *ngIf="locationCtrl.get('street_number')!.invalid">required</mat-error>
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field class="w-100">
                <input
                    type="text"
                    matInput
                    formControlName="street_address"
                    placeholder="Street Address"
                    [errorStateMatcher]="errMatcher"
                    required
                />
                <mat-error align="end" *ngIf="locationCtrl.get('street_address')!.invalid">required</mat-error>
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field class="w-100">
                <input type="text" matInput formControlName="unit_number" placeholder="Unit" />
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field class="w-100">
                <input
                    type="text"
                    matInput
                    formControlName="city"
                    placeholder="City"
                    [errorStateMatcher]="errMatcher"
                    required
                />
                <mat-error align="end" *ngIf="locationCtrl.get('city')!.invalid">required</mat-error>
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field class="w-100">
                <input
                    type="text"
                    matInput
                    formControlName="state"
                    placeholder="State"
                    [errorStateMatcher]="errMatcher"
                    required
                />
                <mat-error align="end" *ngIf="locationCtrl.get('state')!.invalid">required</mat-error>
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field class="w-100">
                <input
                    type="text"
                    matInput
                    formControlName="zip"
                    placeholder="Zip"
                    [errorStateMatcher]="errMatcher"
                    required
                />
                <mat-error align="end" *ngIf="locationCtrl.get('zip')!.invalid">required</mat-error>
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field class="w-100">
                <input
                    type="text"
                    matInput
                    formControlName="country"
                    placeholder="Country"
                    [errorStateMatcher]="errMatcher"
                    required
                />
                <mat-error align="end" *ngIf="locationCtrl.get('country')!.invalid">required</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="zp-btn-group" *ngIf="isAbleToEdit">
        <button
            type="button"
            *ngIf="isEditingMode === false"
            mat-icon-button
            (click)="editLocation()"
            class="sp-location-remove"
        >
            <mat-icon class="sp-edit-icon">edit</mat-icon>
        </button>
        <button
            type="button"
            *ngIf="isEditingMode"
            mat-icon-button
            (click)="preSaveLocation()"
            class="sp-location-remove"
        >
            <mat-icon class="sp-check-icon">check</mat-icon>
        </button>
        <button
            type="button"
            *ngIf="removeLocation"
            mat-icon-button
            (click)="removeLocation()"
            class="sp-location-remove"
        >
            <mat-icon class="sp-delete-icon">delete</mat-icon>
        </button>
    </div>
    <!-- <button type="button" mat-icon-button (click)="removeLocation()" matTooltip="Remove person">
        <mat-icon>close</mat-icon>
    </button> -->
</div>
