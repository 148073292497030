<div class="user-row" [ngClass]="index === 0 ? 'pt-3' : ''">
    <div
        class="user-card"
        *ngIf="profile"
        (click)="changeDetailsState(profile)"
        [ngStyle]="{
            height:
                (editUser && editUser.id === profile!.id) || isDetailsShown(profile) || (index && isUserInfoShow[index])
                    ? 'auto'
                    : '100px'
        }"
    >
        <div class="d-flex align-items-center" style="height: 50px">
            <!------- Checkbox ------->
            <div style="width: 35px">
                <mat-checkbox
                    #checkbox
                    style="margin-top: 9px"
                    rbac
                    [rule]="{company_settings__manage_users: true}"
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    (click)="$event.stopPropagation()"
                    (change)="toggleDealCheckbox($event)"
                    [value]="profile!.profile_id!.toString()"
                    [checked]="profile.profile_id ? profileIdsToAdd.includes(profile.profile_id) : false"
                ></mat-checkbox>
            </div>

            <!------- Name, email, icon ------->
            <div>
                <div class="d-flex">
                    <div style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden; max-width: 430px">
                        {{ profile.first_name }} {{ profile.last_name }}
                    </div>
                    <mat-icon
                        *ngIf="profile.user && !profile.user.okta_uid"
                        style="color: rgb(113, 113, 113); vertical-align: middle; margin-left: 8px"
                        matTooltip="{{ profile.user.first_name }} {{ profile.user.last_name }} ({{
                            profile.user.email
                        }})"
                        matTooltipPosition="right"
                    >
                        info
                    </mat-icon>
                    <mat-icon
                        *ngIf="!profile.user"
                        [matTooltip]="'User Info not available'"
                        style="color: rgb(113, 113, 113); vertical-align: middle; margin-left: 8px"
                    >
                        info
                    </mat-icon>
                    <img
                        src="assets/images/skyslope-circle-gray-logo.svg"
                        *ngIf="profile.user && profile.user.okta_uid"
                        style="width: 20px; height: 20px; margin-left: 8px; vertical-align: middle"
                        matTooltip="{{ profile.user.first_name }} {{ profile.user.last_name }} ({{
                            profile.user.email
                        }})"
                        matTooltipPosition="right"
                    />
                </div>
                <div style="color: #6e757c; word-break: break-word">{{ profile.email }}</div>
            </div>

            <span class="sp-spacer"></span>

            <!------- Profile status ------->
            <div style="width: 150px">
                <div>
                    <ng-container *ngIf="profile.type === PROFILE_TYPE_DEFAULT">
                        Status:
                        <b
                            *ngIf="profile.status !== PROFILE_STATUS_NO_ACCESS"
                            [ngClass]="{
                                'active-status': profile.status === PROFILE_STATUS_ACTIVE,
                                'inactive-status': profile.status !== PROFILE_STATUS_ACTIVE
                            }"
                        >
                            <span *ngIf="profile.status === 'disconnected'">No Access</span>
                            <span *ngIf="profile.status && profile.status !== 'disconnected'">{{
                                profile.status | titlecase
                            }}</span>
                        </b>
                        <b class="inactive-status" *ngIf="profile.status === PROFILE_STATUS_NO_ACCESS"> Not Invited </b>
                    </ng-container>
                </div>
            </div>

            <div style="width: 150px">
                <!------- Icons ------->
                <div class="d-flex justify-content-end align-items-center">
                    <button
                        mat-icon-button
                        (click)="$event.stopPropagation()"
                        [routerLink]="['/contacts', profile.contact_fk_id]"
                        fragment="payment-methods"
                        *ngIf="profile.has_zipi_pay || profile.has_receive_only"
                    >
                        <mat-icon [matTooltip]="'Direct deposits enabled'"> monetization_on </mat-icon>
                    </button>

                    <mat-icon
                        class="mr-1"
                        matTooltip="Business Account Created"
                        [ngClass]="{'visibility-hidden': !(profile.contact && profile.contact.partner__company_fk_id)}"
                    >
                        web_asset
                    </mat-icon>
                    <a
                        mat-icon-button
                        class="contact-icon"
                        [rule]="{contacts__access: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        [routerLink]="['/contacts', profile.contact_fk_id]"
                        *ngIf="profile.contact_fk_id"
                        (click)="$event.stopPropagation()"
                    >
                        <mat-icon>contacts</mat-icon>
                    </a>

                    <button
                        mat-icon-button
                        (click)="$event.stopPropagation()"
                        [matMenuTriggerFor]="menu"
                        rbac
                        [rule]="{manage_users: true}"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>

                    <!------- Menu ------->
                    <mat-menu
                        #menu="matMenu"
                        [rule]="{company_settings__manage_users: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        style="position: absolute"
                    >
                        <button mat-menu-item (click)="updateUser(profile)" rbac [rule]="{manage_users: true}">
                            <span>Edit</span>
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="
                                authService.isLoggedAsMode() &&
                                (!profile.user || (profile.user && !profile.user.okta_uid))
                            "
                            (click)="connectSkyslopeUser(profile)"
                        >
                            <span>Connect Skyslope</span>
                        </button>
                        <button
                            mat-menu-item
                            (click)="resendInvitation(profile)"
                            rbac
                            [rule]="{manage_users: true}"
                            *ngIf="
                                (profile.status === PROFILE_STATUS_INVITED ||
                                    profile.status === PROFILE_STATUS_DISCONNECTED ||
                                    profile.status === PROFILE_STATUS_NO_ACCESS ||
                                    (profile.status === PROFILE_STATUS_SUSPENDED && !profile.user)) &&
                                profile.type === PROFILE_TYPE_DEFAULT
                            "
                        >
                            <span
                                *ngIf="
                                    profile.status === PROFILE_STATUS_INVITED ||
                                    profile.status === PROFILE_STATUS_DISCONNECTED ||
                                    (profile.status === PROFILE_STATUS_SUSPENDED && !profile.user)
                                "
                                >Resend Invitation</span
                            >
                            <span *ngIf="profile.status === PROFILE_STATUS_NO_ACCESS">Send Invitation</span>
                        </button>

                        <button
                            mat-menu-item
                            (click)="revokeInvitation(profile)"
                            rbac
                            [rule]="{manage_users: true}"
                            *ngIf="profile.status === PROFILE_STATUS_INVITED && profile.type === PROFILE_TYPE_DEFAULT"
                        >
                            <span>Revoke Invitation</span>
                        </button>

                        <button
                            mat-menu-item
                            (click)="reactivate(profile)"
                            rbac
                            [rule]="{manage_users: true}"
                            [disabled]="!profile.user"
                            *ngIf="profile.status === PROFILE_STATUS_SUSPENDED"
                        >
                            <span>Reactivate</span>
                        </button>

                        <button
                            mat-menu-item
                            (click)="disconnect(profile)"
                            rbac
                            [rule]="{manage_users: true}"
                            [disabled]="
                                profile.id === sessionService.profile?.id ||
                                (profile.status !== PROFILE_STATUS_ACTIVE &&
                                    profile.status !== PROFILE_STATUS_NO_ACCESS)
                            "
                        >
                            <span>Revoke Access</span>
                        </button>

                        <button
                            mat-menu-item
                            (click)="suspend(profile)"
                            rbac
                            [rule]="{manage_users: true}"
                            [disabled]="
                                profile.status === PROFILE_STATUS_SUSPENDED || profile.id === sessionService.profile?.id
                            "
                        >
                            <span>Suspend</span>
                        </button>

                        <button
                            mat-menu-item
                            (click)="deleteProfile(profile)"
                            rbac
                            [rule]="{manage_users: true}"
                            [disabled]="profile.id === sessionService.profile?.id"
                        >
                            <span>Delete</span>
                        </button>

                        <button
                            mat-menu-item
                            (click)="createContact(profile)"
                            rbac
                            [rule]="{manage_users: true}"
                            *ngIf="!profile.contact_fk_id"
                        >
                            <span>Create Contact</span>
                        </button>
                        <button
                            mat-menu-item
                            (click)="createFinancialsCompany(profile)"
                            rbac
                            [rule]="{manage_users: true}"
                            *ngIf="
                                profile.user &&
                                !profile.user.finance__company_fk_id &&
                                profile.status === PROFILE_STATUS_ACTIVE
                            "
                        >
                            <span>Create Business Account</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <!------- Open card ------->
        <div *ngIf="isDetailsShown(profile)" style="margin-bottom: 5px; margin-top: 5px; margin-left: 30px">
            <mat-chip-list>
                <mat-chip *ngFor="let group of profileCompanyGroups">
                    <mat-icon *ngIf="group.type === 'division'" class="mr-1">domain</mat-icon>
                    <mat-icon *ngIf="group.type === 'default'" class="mr-1">people</mat-icon>
                    {{ group.title }}
                </mat-chip>
            </mat-chip-list>
        </div>
        <app-edit-user
            *ngIf="editUser && editUser.id === profile.id"
            #editComponent
            сlass="edit-user"
            [profile]="editUser"
            [allRoles]="allRoles"
            [companyPermissions]="companyPermissions"
            (click)="$event.stopPropagation()"
            (userUpdated)="updateUserList($event)"
            (onDestroy)="destroyEditUserComponent(); changeDetailsState(profile)"
        >
        </app-edit-user>
    </div>
</div>
