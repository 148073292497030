import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {IWildcard, IWildcardInstance} from '@cyberco-nodejs/zipi-typings';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {WildcardsService} from 'app/services/api/wildcards.service';
import {select, Store} from '@ngrx/store';
import {ICompanyWideState} from 'app/store/company-wide/company-wide.reducer';
import {selectWildcards} from 'app/store/company-wide/company-wide.selectors';
import {
    currencyMaskitoOptions,
    currencyWithNegativeMaskitoOptions,
    numberMaskitoOptions
} from '../../../../utilities/maskito';

@Component({
    selector: 'app-wildcard-picker',
    templateUrl: './wildcard-picker.component.html',
    styleUrls: ['./wildcard-picker.component.scss']
})
export class WildcardPickerComponent implements OnInit, OnDestroy {
    unsubscribe: Subject<void> = new Subject<void>();

    @Input() type: 'currency' | 'quantity' = 'currency';
    @Input() placeholder: string = 'Amount';
    @Input() placeholderAttribute: string = 'Custom Attribute';

    @Input() valueFC: UntypedFormControl = new UntypedFormControl(null);
    @Input() valueWildcardIdFC: UntypedFormControl = new UntypedFormControl(null);
    @Input() contactFc: UntypedFormControl = new UntypedFormControl(null);
    @Input() productFC: UntypedFormControl = new UntypedFormControl(null);
    @Input() allowNegative: boolean = false;

    currentInputType: 'numeric' | 'wildcard' = 'numeric';
    currencyMaskitoMask = currencyMaskitoOptions;
    currencyWithNegativeMaskitoMask = currencyWithNegativeMaskitoOptions;
    numberMaskitoMask = numberMaskitoOptions;
    wildcardList: IWildcard[] = [];
    lastContactWildcardInstances: IWildcardInstance[] = [];
    lastContactId: number | undefined;

    constructor(
        private wildcardsService: WildcardsService,
        private store: Store<ICompanyWideState>
    ) {}

    ngOnInit() {
        this.loadContactWildcardInstances();

        this.store.pipe(select(selectWildcards), takeUntil(this.unsubscribe)).subscribe((wildcards) => {
            if (wildcards) {
                this.wildcardList = wildcards.filter((wildcard) => wildcard.wildcard_type_sk === 'numeric');

                if (this.valueWildcardIdFC.value) {
                    const currentWildcard = this.wildcardList.find(
                        (wildcard) => wildcard.wildcard_id === this.valueWildcardIdFC.value
                    );
                    if (typeof currentWildcard !== 'undefined') {
                        this.currentInputType = 'wildcard';
                        this.valueWildcardIdFC.patchValue(currentWildcard.wildcard_id);
                    }
                }
            }
        });

        this.valueWildcardIdFC.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((wildcardId) => {
            if (wildcardId && this.contactFc.value) {
                this.setWildcardValue();
            }
        });
        this.productFC.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            if (!!value) {
                this.currentInputType = 'numeric';
            }
        });

        this.contactFc.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            if (value) {
                this.lastContactId = value;
                this.loadContactWildcardInstances();
            } else if (this.currentInputType === 'wildcard') {
                this.valueFC.patchValue(null);
            }
        });
    }

    changeType(toType: 'numeric' | 'wildcard') {
        this.valueFC.patchValue(null);
        this.valueWildcardIdFC.setValue(null);

        this.currentInputType = toType;
    }

    loadContactWildcardInstances() {
        if (this.contactFc.value && this.currentInputType === 'wildcard') {
            this.wildcardsService
                .getWildcardInstances(this.contactFc.value)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((res) => {
                    this.lastContactWildcardInstances = res.result;

                    if (res.result && this.currentInputType === 'wildcard') {
                        this.setWildcardValue();
                    }
                });
        }
    }

    setWildcardValue() {
        const instance = this.lastContactWildcardInstances.find(
            (inst) => inst.wildcard_fk_id === this.valueWildcardIdFC.value
        );

        if (instance) {
            this.valueFC.patchValue(Number(instance.value));
        } else {
            this.valueFC.patchValue(null);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
