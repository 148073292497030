import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, NgForm, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {NotificationsServiceZipi} from 'app/modules/notifications/notifications.service';
import {MatDialog} from '@angular/material/dialog';
import {ForgotPasswordComponent} from '../forgot-password/forgot-password.component';
import {SessionService} from '../../../services/session.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in-contact-invoice.component.html',
    styleUrls: ['./sign-in-contact-invoice.component.scss']
})
export class SignInContactInvoiceComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    form: UntypedFormGroup | null = null;

    constructor(
        public authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public sessionService: SessionService,
        private notificationsService: NotificationsServiceZipi,
        protected fb: UntypedFormBuilder,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        this.authService.logout();

        this.form = this.fb.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });

        if (this.form && this.sessionService.redirectParams.emailPrefilled) {
            this.form.get('email')!.setValue(this.sessionService.redirectParams.emailPrefilled);
            this.form.get('email')!.disable();
        }
    }

    onSubmit(form: any) {
        if (!form.valid) {
            return false;
        }

        const formObj = form.getRawValue();

        return this.authService
            .signInWithEmailAndPassword(formObj.email, formObj.password)
            .then((firebaseUserInfo) => {
                return this.onSuccessfulLogin();
            })
            .then((isUserLoaded) => {
                if (!isUserLoaded) {
                    this.authService.logout();
                    return;
                }

                // redirect back to invite page
                if (
                    this.sessionService.redirectParams.navigateAfterAuthTo &&
                    this.sessionService.redirectParams.navigateAfterAuthTo.length > 0
                ) {
                    this.router.navigate(this.sessionService.redirectParams.navigateAfterAuthTo);
                    return;
                }

                const zipi_redirect_path = localStorage.getItem('zipi_redirect_path');
                if (zipi_redirect_path) {
                    localStorage.removeItem('zipi_redirect_path');
                    this.router.navigate([zipi_redirect_path]);
                } else {
                    this.router.navigate(['/default-page']);
                }

                return;
            })
            .catch((err) => {
                if (typeof err === 'string') {
                    let errorMessage = err;
                    switch (err) {
                        case 'User does not exist':
                            errorMessage = 'Username/Email not found';
                            break;
                    }
                    this.notificationsService.addError(errorMessage);
                } else if (err.message) {
                    this.notificationsService.addError(err.message);
                }
            });
    }

    tryGoogleAuth() {
        return this.authService
            .signInWithGoogle()
            .then((user) => {
                const isNewUser = user.additionalUserInfo!.isNewUser;
                if (isNewUser) {
                    user.user!.delete();
                    this.notificationsService.addError(`User does not exist in SkySlope Books`);
                } else {
                    return this.onSuccessfulLogin();
                }
            })
            .then((isUserLoaded) => {
                if (!isUserLoaded) {
                    this.authService.logout();
                    return;
                }

                const zipi_redirect_path = localStorage.getItem('zipi_redirect_path');
                if (zipi_redirect_path) {
                    localStorage.removeItem('zipi_redirect_path');
                    window.location.replace(zipi_redirect_path);
                } else {
                    window.location.replace('/default-page');
                }
                return;
            })
            .catch((err) => {
                if (typeof err === 'string') {
                    let errorMessage = err;
                    switch (err) {
                        case 'User does not exist':
                            errorMessage = 'Username/Email not found';
                            break;
                    }
                    this.notificationsService.addError(errorMessage);
                } else if (err.message) {
                    this.notificationsService.addError(err.message);
                }
            });
    }

    onSuccessfulLogin() {
        if (this.activatedRoute.snapshot.params.login_as_uid) {
            this.authService.setLoginAsUID(this.activatedRoute.snapshot.params.login_as_uid);
        }

        return this.authService.loadAuth();
    }

    forgotPassword() {
        const dialogRef = this.dialog.open(ForgotPasswordComponent);

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
