<h3 *ngIf="heading">{{ heading }}</h3>

<ng-container *ngIf="allowSubCompanySearch">
    <ng-content></ng-content>
</ng-container>

<div
    class="d-flex flex-column"
    [class.full-width]="fullWidth"
    *ngIf="displayMode === 'single-line'"
    [class.grey-out]="seemDisabled"
>
    <mat-chip-list #chipList class="d-flex mb-2" *ngIf="hideControls">
        <ng-container *ngFor="let nodeFG of nodesFA.controls; index as ni">
            <app-entity-picker-chips
                [commonList]="commonList"
                [nodeFG]="nodeFG"
                [ni]="ni"
                [hideControls]="hideControls"
                [disabled]="disabled"
                [seemDisabled]="seemDisabled"
                [allowSubCompanySearch]="allowSubCompanySearch"
                [chosenSubCompanies]="chosenSubCompanies"
                [lineClamp]="lineClamp"
                (doRemoveNodeFAEmitter)="doRemoveNodeFA($event)"
            ></app-entity-picker-chips>
        </ng-container>
    </mat-chip-list>

    <mat-form-field
        class="{{ required && !nodesFA.controls.length ? 'mat-form-field-invalid' : '' }}"
        [ngClass]="disableUpdate && firstLoad ? 'picker-form-input' : ''"
        [id]="bold_style ? 'participants' : null"
        *ngIf="!hideControls"
        [floatLabel]="
            !textParticipants.length &&
            !nodesFA?.controls.length &&
            !initialContactIds.length &&
            !initialContactProfileIds?.length
                ? floatLabel
                : 'always'
        "
        [class.grey-out]="seemDisabled"
    >
        <mat-placeholder [ngClass]="nodesFA.controls.length && floatLabel === 'never' ? 'd-none' : ''">
            {{ required && !hideRequiredMarker ? title + ' *' : title }}
        </mat-placeholder>

        <mat-chip-list #chipList class="chiplist">
            <!------- Fake placeholders ------->
            <ng-container
                *ngIf="
                    !nodesFA.controls?.length &&
                    (initialContactProfileIds?.length || (initialContactIds?.length && contactPersonMain))
                "
            >
                <ng-container *ngIf="initialContactProfileIds?.length">
                    <mat-chip
                        class="p-0 px-2 m-0 mr-2 mb-1 chip-item"
                        *ngFor="let fakePerson of initialContactProfileIds"
                    >
                        <span class="ml-1 grey-out">{{ fakePerson.fake_title }}</span>
                    </mat-chip>
                </ng-container>
                <ng-container *ngIf="initialContactIds && initialContactIds.length">
                    <mat-chip class="p-0 px-2 m-0 mr-2 mb-1 chip-item" *ngFor="let fakeContact of initialContactIds">
                        <span class="ml-1 grey-out" *ngIf="fakeContact && fakeContact.fake_title">
                            {{ fakeContact.fake_title }}
                        </span>
                    </mat-chip>
                </ng-container>
            </ng-container>

            <!------- Usual items ------->
            <ng-container *ngIf="nodesFA?.controls?.length">
                <app-entity-picker-chips
                    *ngFor="let nodeFG of nodesFA.controls; index as ni"
                    [commonList]="commonList"
                    [nodeFG]="nodeFG"
                    [ni]="ni"
                    [hideControls]="hideControls"
                    [disabled]="disabled"
                    [seemDisabled]="seemDisabled"
                    [allowSubCompanySearch]="allowSubCompanySearch"
                    [chosenSubCompanies]="chosenSubCompanies"
                    [lineClamp]="lineClamp"
                    (doRemoveNodeFAEmitter)="doRemoveNodeFA($event)"
                ></app-entity-picker-chips>
            </ng-container>

            <!------- Text Deal Participants ------->
            <ng-container *ngIf="textParticipants?.length">
                <mat-chip
                    class="p-0 px-2 m-0 mr-2 mt-1 chip-item text-particcipant"
                    *ngFor="let fakePerson of textParticipants"
                    (click)="
                        fakePerson.deal_participant_id
                            ? contactCreateFromTextEmitter.emit({
                                  id: fakePerson.deal_participant_id,
                                  addon_title: textParticipantIconByAddonId[fakePerson.added_by__addon_id + ''].title
                              })
                            : null
                    "
                >
                    <span class="grey-out d-flex align-items-center">
                        <ng-container
                            *ngIf="
                                fakePerson.format === 'text' &&
                                fakePerson.added_by__addon_id &&
                                textParticipantIconByAddonId[fakePerson.added_by__addon_id + ''].imgUrl
                            "
                        >
                            <span
                                class="text-participant__icon"
                                [ngStyle]="{
                                    'background-image':
                                        'url(' +
                                        textParticipantIconByAddonId[fakePerson.added_by__addon_id + ''].imgUrl +
                                        ')'
                                }"
                            ></span>
                        </ng-container>
                        <span
                            [matTooltip]="
                                'Name Displayed (from ' +
                                textParticipantIconByAddonId[fakePerson.added_by__addon_id + ''].title +
                                '), click here to hide or learn more'
                            "
                            >{{ fakePerson.fake_title }}</span
                        >
                        <ng-container *ngIf="fakePerson.format === 'text'">
                            <mat-icon
                                class="info-visibility-icon ml-1"
                                [matTooltip]="
                                    fakePerson.is_ignored ? 'Ignored, click to display' : 'Displayed, click to ignore'
                                "
                                *ngIf="fakePerson.deal_participant_id"
                                (click)="
                                    $event.stopPropagation();
                                    toggleVisibilityEmitter.emit({
                                        is_ignored: !fakePerson.is_ignored,
                                        id: fakePerson.deal_participant_id
                                    })
                                "
                            >
                                {{ fakePerson.is_ignored ? 'visibility_off' : 'visibility' }}
                            </mat-icon>
                        </ng-container>
                    </span>
                </mat-chip>
            </ng-container>

            <input
                matInput
                autocomplete="off"
                [class.hide]="
                    filterFC.disabled || (singleSelected && (nodesFA.controls.length || textParticipants?.length))
                "
                [class.grey-out]="seemDisabled"
                #filter
                #trigger="matAutocompleteTrigger"
                [formControl]="filterFC"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputAddOnBlur]="addOnBlur"
            />
            <span class="required-error-msg" *ngIf="required && !nodesFA.controls.length">Required field</span>
        </mat-chip-list>

        <button
            *ngIf="contactMetatype"
            type="button"
            mat-icon-button
            matSuffix
            (click)="contactCreate($event, contactMetatype)"
            [disabled]="disabled"
            [anyRule]="[{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]"
            rbac
            matTooltip="Create Contact"
        >
            <mat-icon>person_add</mat-icon>
        </button>

        <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="selected($event)"
            [panelWidth]="panelWidth"
            [class.grey-out]="seemDisabled"
        >
            <app-infinite-scroll (scrolled)="nextBatch()">
                <ng-container *ngFor="let node of commonList.value">
                    <mat-option [value]="node" (click)="!closeAfterSelection ? trigger.openPanel() : null">
                        <!------- Icon ------->
                        <mat-icon *ngIf="node.type === CHIP_NODE.type_SET.individuals_in_group"> people </mat-icon>
                        <mat-icon *ngIf="node.type === CHIP_NODE.type_SET.company_group"> domain </mat-icon>
                        <mat-icon *ngIf="node.type === CHIP_NODE.type_SET.individual"> person </mat-icon>
                        <mat-icon *ngIf="node.type === CHIP_NODE.type_SET.contact">
                            {{ getContactIcon(node.id) }}
                        </mat-icon>
                        <mat-icon *ngIf="node.type === CHIP_NODE.type_SET.contact_wildcard"> recent_actors </mat-icon>
                        <mat-icon *ngIf="node.type === CHIP_NODE.type_SET.wildcard"> recent_actors </mat-icon>
                        <mat-icon
                            class="material-icons-outlined"
                            *ngIf="node.type === CHIP_NODE.type_SET.contact_class"
                        >
                            people_alt
                        </mat-icon>

                        <!------- Title or Name ------->
                        {{ node.label }}

                        <!------- If we searching through the sub-companies ------->
                        <ng-container
                            *ngIf="
                                allowSubCompanySearch &&
                                chosenSubCompanies.value.length > 0 &&
                                !(node.type === CHIP_NODE.type_SET.contact_wildcard)
                            "
                        >
                            <span class="font-italic ml-1" style="color: darkgray" *ngIf="node.company_fk_id">
                                ({{ getCompanyNameById(node.company_fk_id) }})
                            </span>
                        </ng-container>
                    </mat-option>
                </ng-container>
            </app-infinite-scroll>
        </mat-autocomplete>
    </mat-form-field>
</div>

<div class="d-flex flex-column" *ngIf="displayMode === 'double-line'" [class.grey-out]="seemDisabled">
    <mat-chip-list #chipList class="d-flex mb-2" *ngIf="positionMode === 'top'">
        <ng-container *ngFor="let nodeFG of nodesFA.controls; index as ni">
            <app-entity-picker-chips
                [commonList]="commonList"
                [nodeFG]="nodeFG"
                [ni]="ni"
                [hideControls]="hideControls"
                [disabled]="disabled"
                [seemDisabled]="seemDisabled"
                [allowSubCompanySearch]="allowSubCompanySearch"
                [chosenSubCompanies]="chosenSubCompanies"
                [subTypesArray]="subTypesArray"
                [dealParticipantsWithSubType]="dealParticipantsWithSubType"
                [lineClamp]="lineClamp"
                (doRemoveNodeFAEmitter)="doRemoveNodeFA($event)"
            ></app-entity-picker-chips>
        </ng-container>
    </mat-chip-list>

    <mat-form-field
        [floatLabel]="floatLabel"
        [class.grey-out]="seemDisabled"
        [ngClass]="disableUpdate && firstLoad ? 'picker-form-input' : ''"
    >
        <mat-placeholder [ngClass]="nodesFA.controls.length && floatLabel === 'never' ? 'd-none' : ''">{{
            title
        }}</mat-placeholder>

        <input
            matInput
            autocomplete="off"
            #filter
            #trigger="matAutocompleteTrigger"
            [formControl]="filterFC"
            [matAutocomplete]="auto"
        />

        <button
            *ngIf="contactMetatype"
            type="button"
            mat-icon-button
            matSuffix
            (click)="contactCreate($event, contactMetatype)"
            [disabled]="disabled"
            [anyRule]="[{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]"
            rbac
            matTooltip="Create Contact"
        >
            <mat-icon>person_add</mat-icon>
        </button>
    </mat-form-field>

    <mat-chip-list #chipList class="d-flex mb-2" *ngIf="positionMode === 'bottom'">
        <!------- Fake placeholders ------->
        <ng-container *ngIf="!nodesFA.controls?.length && initialContactProfileIds?.length">
            <mat-chip class="p-0 px-2 m-0 mr-2 mb-1 chip-item" *ngFor="let fakePerson of initialContactProfileIds">
                <span class="ml-1 grey-out">{{ fakePerson.fake_title }}</span>
            </mat-chip>
        </ng-container>

        <!------- Usual items ------->
        <ng-container *ngFor="let nodeFG of nodesFA.controls; index as ni">
            <app-entity-picker-chips
                [commonList]="commonList"
                [nodeFG]="nodeFG"
                [ni]="ni"
                [hideControls]="hideControls"
                [disabled]="disabled"
                [seemDisabled]="seemDisabled"
                [allowSubCompanySearch]="allowSubCompanySearch"
                [chosenSubCompanies]="chosenSubCompanies"
                [subTypesArray]="subTypesArray"
                [dealParticipantsWithSubType]="dealParticipantsWithSubType"
                [lineClamp]="lineClamp"
                (doRemoveNodeFAEmitter)="doRemoveNodeFA($event)"
            ></app-entity-picker-chips>
        </ng-container>

        <!------- Text Deal Participants ------->
        <ng-container *ngIf="textParticipants && textParticipants.length">
            <mat-chip
                class="p-0 px-2 m-0 mr-2 mt-1 chip-item text-participant"
                *ngFor="let fakePerson of textParticipants"
                (click)="
                    fakePerson.deal_participant_id
                        ? contactCreateFromTextEmitter.emit({
                              id: fakePerson.deal_participant_id,
                              addon_title: textParticipantIconByAddonId[fakePerson.added_by__addon_id + ''].title
                          })
                        : null
                "
            >
                <span class="grey-out d-flex align-items-center">
                    <ng-container
                        *ngIf="
                            fakePerson.format === 'text' &&
                            fakePerson.added_by__addon_id &&
                            textParticipantIconByAddonId[fakePerson.added_by__addon_id + ''].imgUrl
                        "
                    >
                        <span
                            class="text-participant__icon"
                            [ngStyle]="{
                                'background-image':
                                    'url(' +
                                    textParticipantIconByAddonId[fakePerson.added_by__addon_id + ''].imgUrl +
                                    ')'
                            }"
                        ></span>
                    </ng-container>
                    <span
                        [matTooltip]="
                            'Name Displayed (from ' +
                            textParticipantIconByAddonId[fakePerson.added_by__addon_id + ''].title +
                            '), click here to hide or learn more'
                        "
                        >{{ fakePerson.fake_title }}</span
                    >
                    <ng-container *ngIf="fakePerson.format === 'text'">
                        <mat-icon
                            class="info-visibility-icon ml-1"
                            *ngIf="fakePerson.deal_participant_id"
                            [matTooltip]="
                                fakePerson.is_ignored ? 'Ignored, click to display' : 'Displayed, click to ignore'
                            "
                            (click)="
                                $event.stopPropagation();
                                toggleVisibilityEmitter.emit({
                                    is_ignored: !fakePerson.is_ignored,
                                    id: fakePerson.deal_participant_id
                                })
                            "
                        >
                            {{ fakePerson.is_ignored ? 'visibility_off' : 'visibility' }}
                        </mat-icon>
                    </ng-container>
                </span>
            </mat-chip>
        </ng-container>
    </mat-chip-list>

    <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selected($event)"
        [panelWidth]="panelWidth"
        [class.grey-out]="seemDisabled"
    >
        <app-infinite-scroll (scrolled)="nextBatch()">
            <ng-container *ngFor="let node of commonList.value">
                <ng-container *ngIf="!node.hidden">
                    <mat-option [value]="node" (click)="!closeAfterSelection ? trigger.openPanel() : null">
                        <!------- Icon ------->
                        <mat-icon *ngIf="node.type === CHIP_NODE.type_SET.individuals_in_group"> people </mat-icon>
                        <mat-icon *ngIf="node.type === CHIP_NODE.type_SET.company_group"> domain </mat-icon>
                        <mat-icon *ngIf="node.type === CHIP_NODE.type_SET.individual"> person </mat-icon>
                        <mat-icon *ngIf="node.type === CHIP_NODE.type_SET.contact">
                            {{ getContactIcon(node.id) }}
                        </mat-icon>
                        <mat-icon *ngIf="node.type === CHIP_NODE.type_SET.contact_wildcard"> recent_actors </mat-icon>
                        <mat-icon *ngIf="node.type === CHIP_NODE.type_SET.wildcard"> recent_actors </mat-icon>
                        <mat-icon
                            class="material-icons-outlined"
                            *ngIf="node.type === CHIP_NODE.type_SET.contact_class"
                        >
                            people_alt
                        </mat-icon>

                        <!------- Title or Name ------->
                        {{ node.label }}

                        <!------- If we searching through the sub-companies ------->
                        <ng-container
                            *ngIf="
                                allowSubCompanySearch &&
                                chosenSubCompanies.value.length > 0 &&
                                !(node.type === CHIP_NODE.type_SET.contact_wildcard)
                            "
                        >
                            <span class="font-italic ml-1" style="color: darkgray" *ngIf="node.company_fk_id">
                                ({{ getCompanyNameById(node.company_fk_id) }})
                            </span>
                        </ng-container>
                    </mat-option>
                </ng-container>
            </ng-container>
        </app-infinite-scroll>
    </mat-autocomplete>
</div>
