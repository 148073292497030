import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
    selector: 'app-future-deals-dialog',
    templateUrl: './affected-deals-dialog.component.html',
    styleUrls: ['./affected-deals-dialog.component.scss']
})
export class AffectedDealsDialogComponent implements OnInit {
    closeOfEscrow: Date | null = null;
    approvedDeals: {
        id: string;
        name: string;
        close_of_escrow: string;
        hasConnectedBillsOrInvoices: boolean;
    }[] = [];
    unapprovedDealsInPast: {
        id: string;
        close_of_escrow: string;
        name: string;
    }[] = [];
    currentDeal: {
        id: string;
        name: string;
        close_of_escrow: string;
    } = {
        id: '',
        close_of_escrow: '',
        name: ''
    };
    notShowedUnapprovedDeals: number = 0;
    notShowedApprovedDeals: number = 0;

    constructor(
        public dialogRef: MatDialogRef<AffectedDealsDialogComponent>,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.unapprovedDealsInPast = this.data.unapprovedDealsInPast;
        this.notShowedUnapprovedDeals = Number(this.data.unapprovedDealsCount) - this.unapprovedDealsInPast.length;
        this.currentDeal = this.data.currentDeal;
        this.approvedDeals = this.data.approvedDeals;
        this.notShowedApprovedDeals = Number(this.data.approvedDealsCount) - this.approvedDeals.length;
    }

    ngOnInit(): void {}
}
