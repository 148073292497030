<div class="sidebar-page">
    <app-company-inner-nav
        [section]="section"
        (openSidebarEmitter)="journalCreate?.open(null)"
        (openTemplateEmitter)="journalCreate?.openTemplate($event)"
    ></app-company-inner-nav>

    <!-------- Tab group -------->
    <div #header></div>
    <mat-tab-group class="w-100" id="deal-tabs">
        <!-------- Journal -------->
        <mat-tab label="Entries">
            <ng-template mat-tab-label>
                <span (click)="changeSection('journals')" class="tab-text"> Entries </span>
            </ng-template>

            <ng-template matTabContent>
                <app-journal-create-bar
                    #journalCreate
                    [isOpened]="isSidebarOpened"
                    (updateListEmitter)="updateList$.next()"
                    class="sidebar-container"
                >
                    <app-journals
                        *ngIf="filterHeader"
                        [filterHeader]="filterHeader"
                        [updateList$]="updateList$"
                        (journalCreateEmitter)="editManualJournal($event)"
                    ></app-journals>
                </app-journal-create-bar>
            </ng-template>
        </mat-tab>

        <!-------- Recurring Journal -------->
        <mat-tab label="Recurring">
            <ng-template mat-tab-label>
                <span (click)="changeSection('recurring-journals')" class="tab-text"> Recurring </span>
            </ng-template>

            <ng-template matTabContent>
                <app-recurring-journals *ngIf="filterHeader" [filterHeader]="filterHeader"></app-recurring-journals>
            </ng-template>
        </mat-tab>

        <!-------- Templates -------->
        <mat-tab label="Templates">
            <ng-template mat-tab-label>
                <span (click)="changeSection('journal-templates')" class="tab-text"> Templates </span>
            </ng-template>

            <ng-template matTabContent>
                <app-journal-templates *ngIf="filterHeader" [filterHeader]="filterHeader"></app-journal-templates>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
