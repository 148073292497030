import {Component, OnInit, OnDestroy, ViewChild, EventEmitter} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ReportsService} from '../../services/reports.service';
import {ReportsLocalService} from '../../services/reports-local.service';
import {
    reportsTypes,
    timePeriods,
    listOfTransactionStatus,
    financialReportsTypes,
    availableReportType,
    reportLevelBasedOnReportType,
    dateTypesObj
} from '../../constants';
import * as _ from 'lodash-es';
import * as moment from 'moment';
import {
    ClearReportsPreviewData,
    CreateReport,
    FetchReportsPreviewData,
    ToggleEditReportSidebarStatus
} from '../../store/reports.action';
import {Store} from '@ngrx/store';
import {IReportsState, IReportsPreviewData, IReportsPreviewResultData} from '../../store/reports.reducer';
import {SessionService} from '../../../../services/session.service';
import {CompaniesService} from '../../../../services/companies.service';
import {MatDialog} from '@angular/material/dialog';
import {SaveReportDialogComponent} from './components/save-report-dialog/save-report-dialog.component';
import {NotificationsService} from 'angular2-notifications';
import {selectReportsPreviewData} from '../../store/reports.selectors';
import {NotificationsServiceZipi} from '../../../notifications/notifications.service';
import {IReport} from '@cyberco-nodejs/zipi-typings';
import {listOfExportOptions} from '../../constants';
import {MatSelect} from '@angular/material/select';
import {FeatureFlagsService} from '../../../feature-flags/feature-flags.service';

@Component({
    selector: 'app-reports-preview',
    templateUrl: './reports-preview.component.html',
    styleUrls: ['./reports-preview.component.scss']
})
export class ReportsPreviewComponent implements OnInit, OnDestroy {
    @ViewChild('exportCSV') exportCSV: MatSelect | undefined;
    @ViewChild('exportPDF') exportPDF: MatSelect | undefined;
    private unsubscribe: Subject<void> = new Subject();
    public reportLevelBasedOnReportType: {[key: string]: string} = reportLevelBasedOnReportType;
    id?: number;
    report: any = {};
    reportResult: IReportsPreviewData['reportResult'] = [];
    moment = moment;
    page: number = 0;
    perPage: number = 25;
    listOfExportOptions = listOfExportOptions;
    public dateTypesObj: {[key: string]: object} = dateTypesObj;
    isLoaded: boolean = false;
    availableReportType: {[key: string]: string} = availableReportType;
    ten99ReportTypes: string[] = [
        availableReportType.ten99_nec,
        availableReportType.ten99_int,
        availableReportType.ten99_misc
    ];
    timeTitleCategoryByReportType: {[key: string]: string} = timeTitleCategoryByReportType;
    reportData: {[key: string]: any} | undefined;
    public csvExportOptionEnabledFlag = false;

    constructor(
        public router: Router,
        private activateRoute: ActivatedRoute,
        private reportsService: ReportsService,
        private reportsLocalService: ReportsLocalService,
        private store: Store<IReportsState>,
        protected sessionService: SessionService,
        private companiesService: CompaniesService,
        public dialog: MatDialog,
        private notificationsService: NotificationsService,
        private notificationsZipiService: NotificationsServiceZipi,
        protected featureFlagsService: FeatureFlagsService
    ) {}

    ngOnInit(): void {
        this.activateRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            this.isLoaded = false;
            this.reportsLocalService.setReportPreviewQueryParams({
                page: this.page,
                perPage: this.perPage
            });

            if (params['id']) {
                this.id = params['id'];
                this.store.dispatch(new FetchReportsPreviewData({id: this.id}));
            } else {
                this.store.dispatch(new FetchReportsPreviewData());
            }
        });

        const reportData$ = this.store.select(selectReportsPreviewData);
        combineLatest(reportData$)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([reportData]) => {
                if (reportData) {
                    if (reportData.isError) {
                        this.notificationsZipiService.addError(reportData.error);
                    }
                    this.prepareReportData(reportData);
                    this.isLoaded = true;
                    if (reportData.reportEntity) {
                        this.reportsService.checkIsAllowReport(reportData.reportEntity).then((isAllow) => {
                            if (!isAllow) {
                                this.router.navigate(['/reports']);
                            }
                        });
                    }
                }
            });

        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.csvExportOptionEnabledFlag = this.featureFlagsService.isFeatureEnabled('reports:1099_csv_export');
            });
    }

    /**
     * Prepare report data
     * @param response
     */
    prepareReportData(response: IReportsPreviewData) {
        const reportEntity = response.reportEntity;
        const allReportTypes = reportsTypes.concat(financialReportsTypes);
        let reportTitle = '';
        let timePeriodTitle = null;
        let transactionStatusTitle = null;
        const endReportTitlePart = 'Report';

        const findReport = _.find(allReportTypes, {value: reportEntity.type});
        if (findReport) {
            reportTitle = `${findReport.title} ${endReportTitlePart}`;

            if (['balance_sheet', 'profit_loss', 'ap_aging', 'ar_aging'].includes(reportEntity.type)) {
                reportTitle = findReport.title;
            }
        }

        if (reportEntity.settings) {
            const findTimePeriodTitle = _.find(timePeriods, {value: reportEntity.settings.time_period});
            if (findTimePeriodTitle) {
                timePeriodTitle = findTimePeriodTitle.title;
            }

            if (reportEntity.settings.transaction_status && reportEntity.settings.transaction_status.length) {
                const tmpTransactionStatusTitle: string[] = [];
                reportEntity.settings.transaction_status.forEach((item) => {
                    const findTransactionStatus = _.find(listOfTransactionStatus, {value: item});
                    if (findTransactionStatus) {
                        tmpTransactionStatusTitle.push(findTransactionStatus.title);
                    }

                    if (tmpTransactionStatusTitle.length) {
                        transactionStatusTitle = tmpTransactionStatusTitle.join(', ');
                    }
                });
            }
        }

        this.reportsLocalService.setReportEntityData(reportEntity);
        this.report = {
            ...reportEntity,
            title: reportTitle,
            timePeriodTitle: timePeriodTitle,
            transactionStatusTitle: transactionStatusTitle
        };
        this.reportResult = response.reportResult;
    }

    /**
     * Toggle edit report sidebar
     * @param status
     * @param reportId
     */
    toggleEditReportSidebar(status: boolean, reportId: number | null = null) {
        this.store.dispatch(
            new ToggleEditReportSidebarStatus({
                isOpened: status,
                isEditMode: true,
                editId: reportId
            })
        );
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.store.dispatch(new ClearReportsPreviewData());
    }

    /**
     * Save
     */
    save() {
        const dialogRef = this.dialog.open(SaveReportDialogComponent, {
            width: '450px'
        });
        const reportLocalData = this.reportsLocalService.getReportData();

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                if (data) {
                    this.store.dispatch(new CreateReport({...reportLocalData, ...data}));
                }
            });
    }

    /**
     * Export tot CSV
     */
    exportToCSV(params = {}) {
        let additionalParams = {};
        if (params) {
            additionalParams = {
                options: params
            };
        }

        const bodyData = this.getBodyDataForExport();

        this.reportsService
            .exportReportToCSV(bodyData, {
                ...this.reportsLocalService.getReportPreviewQueryParams(),
                ...additionalParams
            })
            .then((response) => {
                if (response) {
                    this.notificationsService.success(
                        'Report has been created.\n' +
                            'It can be found and opened in the top notifications bell when complete.'
                    );
                }
            });
    }

    exportToIrisCSV(params = {}) {
        if (this.csvExportOptionEnabledFlag) {
            let additionalParams = {};
            if (params) {
                additionalParams = {
                    options: params
                };
            }

            const bodyData = this.getBodyDataForExport();

            this.reportsService
                .exportReportToIrisCSV(bodyData, {
                    ...this.reportsLocalService.getReportPreviewQueryParams(),
                    ...additionalParams
                })
                .then((response) => {
                    if (response) {
                        this.notificationsService.success(
                            'Report has been created.\n' +
                                'It can be found and opened in the top notifications bell when complete.'
                        );
                    }
                });
        } else {
            this.notificationsService.error(`This export feature is not enabled`);
        }
    }

    /**
     * Open export options CSV
     * @param element
     */
    openExportOptionsCSV() {
        this.exportCSV!.open();
    }

    /**
     * Open export options PDF
     */
    openExportOptionsPDF() {
        this.exportPDF!.open();
    }

    /**
     * Export to PDF
     * @param reportType
     * @param params
     */
    exportToPdf(reportType: IReport['type'], params = {}) {
        let additionalParams = {};
        if (Object.keys(params).length) {
            additionalParams = {
                options: params
            };
        }
        if (reportType === availableReportType.ten99 || this.ten99ReportTypes.includes(reportType)) {
            return this.generateTen99Pdf(additionalParams);
        } else {
            return this.generateCommonPdf(additionalParams);
        }
    }

    /**
     * Generate ten 99 pdf
     */
    generateTen99Pdf(params: {[key: string]: string}) {
        const bodyData = this.getBodyDataForExport();

        return this.reportsService
            .exportReportToPdf(bodyData, {
                ...this.reportsLocalService.getReportPreviewQueryParams(),
                ...params
            })
            .then((response) => {
                if (response.status) {
                    if (response.status === 'success') {
                        this.notificationsService.success(
                            'Report has been created.\n' +
                                'It can be found and opened in the top notifications bell when complete.'
                        );
                    }
                    if (response.status === 'warning') {
                        this.notificationsService.info(`Report has not been created. Reason: ${response.message}`);
                    }
                }
            })
            .catch((error: Error) => {
                this.notificationsService.success(`An error occurred. Report has not been created.
                Error: ${JSON.stringify(error)}`);
            });
    }

    getBodyDataForExport() {
        let bodyData = {};
        if (this.id) {
            bodyData = {report_id: this.id};
        } else {
            bodyData = {...this.reportsLocalService.getReportData()};
        }
        return bodyData;
    }

    /**
     * Generate common pdf
     * @param params
     */
    generateCommonPdf(params = {}) {
        const windowId = window.open('about:blank');
        if (windowId) {
            windowId.document.body.innerText += 'Please wait, document is generating...';

            const bodyData = this.getBodyDataForExport();

            return this.reportsService
                .exportReportToPdf(bodyData, {
                    ...this.reportsLocalService.getReportPreviewQueryParams(),
                    ...params
                })
                .then((response) => {
                    if (response && response.data) {
                        const fileBlob = new Blob([new Uint8Array(response.data)], {type: 'application/pdf'});
                        windowId.location.href = URL.createObjectURL(fileBlob);
                        this.notificationsService.success(`Report has successfully exported`);
                    } else {
                        windowId.close();
                        this.notificationsService.error(`An error occurred while report exported`);
                    }
                });
        }
    }

    /**
     * Get reports by event
     * @param event
     */
    getReports = (event?: {page: number; perPage: number}) => {
        if (event) {
            this.page = event.page;
            this.perPage = event.perPage;

            this.reportsLocalService.setReportPreviewQueryParams({
                page: this.page,
                perPage: this.perPage
            });
        }

        if (this.id) {
            this.store.dispatch(new FetchReportsPreviewData({id: this.id}));
        } else {
            this.store.dispatch(new FetchReportsPreviewData());
        }
    };

    close() {
        this.reportData = undefined;
    }
}

const timeTitleCategoryByReportType: {[key: string]: string} = {
    [availableReportType.contact]: 'contact',

    [availableReportType.tracking]: 'general',
    [availableReportType.product]: 'general',
    [availableReportType.ten99]: 'general',
    [availableReportType.ten99_nec]: 'general',
    [availableReportType.ten99_int]: 'general',
    [availableReportType.ten99_misc]: 'general',
    [availableReportType.summary]: 'general',
    [availableReportType.summary_user]: 'general',
    [availableReportType.detailed]: 'general',
    [availableReportType.sob]: 'general',
    [availableReportType.commission]: 'general',
    [availableReportType.profit_loss]: 'general',
    [availableReportType.account_transactions]: 'general',
    [availableReportType.journal]: 'general',
    [availableReportType.general_ledger]: 'general',
    [availableReportType.account_type]: 'general',
    [availableReportType.cash_flow]: 'general',
    [availableReportType.trust_reconciliation]: 'general',
    [availableReportType.recurring_invoice]: 'general',
    [availableReportType['avg-sales']]: 'general',
    [availableReportType['agent-performance']]: 'general',

    [availableReportType.balance_sheet]: 'as_of_date',
    [availableReportType.trial_balance]: 'as_of_date',
    [availableReportType.summary_trial_balance]: 'as_of_date',
    [availableReportType.ap_aging]: 'as_of_date',
    [availableReportType.ar_aging]: 'as_of_date'
};
