import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {GenericFormGroup} from 'app/entites/generic.entity';
import {ProfileMembership} from 'app/models/profile-membership';
import {MatDialog} from '@angular/material/dialog';
import {filter, map, takeUntil} from 'rxjs/operators';
import {CreateOrganizationDialogComponent} from './create-organization-dialog.component';
import {MembershipOrganization} from 'app/models/membership-organization';

@Component({
    selector: 'app-contact-edit-compliance-membership-item',
    template: `
        <section>
            <div class="d-flex align-items-md-center bg-light rounded p-2 mb-2">
                <div class="w-100 d-flex flex-column">
                    <mat-form-field class="w-45">
                        <mat-label>Organization</mat-label>
                        <mat-select
                            *ngIf="profileMembershipFG.controls.membership_organization_fk_id"
                            [formControl]="profileMembershipFG.controls.membership_organization_fk_id"
                            placeholder="Organization name"
                            (selectionChange)="organizationSelected($event.value)"
                        >
                            <mat-option (click)="$event.stopPropagation(); createOrganization()">
                                <strong> + Create/Update Organization </strong>
                            </mat-option>
                            <mat-option *ngFor="let organization of membershipOrganizations" [value]="organization.id">
                                {{ organization.title }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="d-flex flex-row" style="margin-left: -15px">
                        <mat-form-field class="col-md-4 col-lg-3">
                            <input
                                type="text"
                                *ngIf="profileMembershipFG.controls.member_license_number"
                                [formControl]="profileMembershipFG.controls.member_license_number"
                                matInput
                                placeholder="Number"
                                name="license_number"
                            />
                        </mat-form-field>

                        <ng-container class="col-md-3 col-lg-3">
                            <app-date-picker
                                [placeholder]="'Expiration'"
                                [dateControl]="profileMembershipFG.controls.expiration_date"
                            ></app-date-picker>
                        </ng-container>

                        <mat-form-field class="col-md-4 col-lg-3">
                            <mat-label>Dues Responsibility</mat-label>
                            <mat-select
                                *ngIf="profileMembershipFG.controls.dues_responsibility"
                                [formControl]="profileMembershipFG.controls.dues_responsibility"
                            >
                                <mat-option
                                    *ngFor="let type of profileMembership.provideDuesResponsibility()"
                                    [value]="type"
                                >
                                    {{ profileMembership.dues_responsibility_LABELS[type] }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="col-md-4 col-lg-3">
                            <mat-label>Membership Type</mat-label>
                            <mat-select
                                *ngIf="profileMembershipFG.controls.membership_type"
                                [formControl]="profileMembershipFG.controls.membership_type"
                            >
                                <mat-option *ngFor="let type of selectedOrganization!.membership_types" [value]="type">
                                    {{ type }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <button (click)="delete.emit()" mat-icon-button type="button" matTooltip="Remove value">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </section>
    `,
    styleUrls: ['../contact-edit.component.css']
})
export class MembershipItemComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    selectedOrganization: MembershipOrganization | undefined = new MembershipOrganization();

    @Input() membershipOrganizations: MembershipOrganization[] = [];
    @Input() profileMembershipFG: GenericFormGroup<ProfileMembership> = new GenericFormGroup<ProfileMembership>(
        new ProfileMembership()
    );
    profileMembership: any = ProfileMembership;

    @Output() delete = new EventEmitter();

    constructor(public dialog: MatDialog) {}

    createOrganization() {
        const dialogRef = this.dialog.open(CreateOrganizationDialogComponent, {
            width: '600px',
            data: {
                organizations: this.membershipOrganizations
            }
        });
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                // do not auto select created membership_organization as it was moved to store
            });
    }

    organizationSelected(value: number) {
        if (value) {
            this.selectedOrganization = this.membershipOrganizations.find((el) => el.id === value);
        }
    }

    ngOnInit() {
        const organizationField = this.profileMembershipFG.controls.membership_organization_fk_id;
        if (organizationField) {
            organizationField.valueChanges
                .pipe(
                    filter((val) => val),
                    map((val) => this.organizationSelected(val)),
                    takeUntil(this.unsubscribe)
                )
                .subscribe();

            if (organizationField.value) {
                this.organizationSelected(organizationField.value);
            }
        }
    }

    ngOnDestroy() {
        // todo what is it?
        // this.profileMembershipFG.controls.organization.patchValue(this.selectedOrganization);
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.delete.complete();
    }
}
