import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    OnChanges,
    SimpleChanges,
    ViewChild,
    Output,
    EventEmitter
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import * as moment from 'moment';
import {MatInput} from '@angular/material/input';

@Component({
    selector: 'app-date-range-picker',
    templateUrl: 'date-range-picker.component.html',
    styleUrls: ['date-range-picker.component.css']
})
export class DateRangePickerComponent implements OnInit, OnDestroy, OnChanges {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('fromInput', {read: MatInput}) fromInput: MatInput | undefined;
    @ViewChild('toInput', {read: MatInput}) toInput: MatInput | undefined;

    @Input() marginBetween = 'Date To';
    @Input() placeholderFrom = 'Date From';
    @Input() placeholderTo = 'Date To';
    @Input() floatLabel: 'never' | 'always' = 'always';

    @Input()
    get minDate(): string {
        return this._minDate;
    }
    set minDate(date: string) {
        this._minDate = moment(date, 'YYYYMMDD').startOf('day').toDate();
    }

    private _minDate: any;
    @Input()
    get maxDate(): string {
        return this._maxDate;
    }
    set maxDate(date: string) {
        this._maxDate = moment(date, 'YYYYMMDD').startOf('day').toDate();
    }
    private _maxDate: any;

    @Output() dateEmitter = new EventEmitter();

    @Input() initialDateFrom: Date | null = null;
    @Input() initialDateTo: Date | null = null;
    dateFromCtrl: UntypedFormControl | null = null;
    dateToCtrl: UntypedFormControl | null = null;

    public dateFrom: string = '';
    public dateTo: string = '';

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit() {
        this.dateFromCtrl = this.fb.control(this.initialDateFrom, []);
        this.dateToCtrl = this.fb.control(this.initialDateTo, []);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dateFromCtrl && this.dateFromCtrl) {
            this.dateFromCtrl.disable();
        }

        if (changes.dateToCtrl && this.dateToCtrl) {
            this.dateToCtrl.disable();
        }
    }

    onFromChange(event: any) {
        if (event.value) {
            this.dateFrom = moment(event.value).format('YYYYMMDD');
        } else {
            this.dateFrom = '';
        }
        this.dateEmitter.emit({dateFrom: this.dateFrom, dateTo: this.dateTo});
    }
    onToChange(event: any) {
        if (event.value) {
            this.dateTo = moment(event.value).format('YYYYMMDD');
        } else {
            this.dateTo = '';
        }
        this.dateEmitter.emit({dateFrom: this.dateFrom, dateTo: this.dateTo});
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.dateEmitter.complete();
    }
}
