import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {Board} from 'app/models/board';
import {BoardService} from '../../services/board.service';
import {CurrentProfileSource} from '../../../../services/sources/current-profile.source';
import {SessionService} from '../../../../services/session.service';
import {ProfilesService} from '../../../../services/profiles.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-dashboard-index',
    templateUrl: './index.component.html',
    styleUrls: ['index.component.scss']
})
export class IndexComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    public boardsList: Board[] = [];
    public busy = true;
    public isExtraBoardsAllowed = true;

    public activeTabIndex: number | null = null;
    public activeTabId: number | null | undefined = null;
    public defaultBoardIndex: number | null = null;

    public editMode: boolean = false;
    public saveDisabled: boolean = false;

    constructor(
        protected boardService: BoardService,
        protected profileSource: CurrentProfileSource,
        protected router: Router,
        protected sessionService: SessionService,
        protected profilesService: ProfilesService
    ) {}

    ngOnInit() {
        // fetch new boards on profile change
        this.profileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.reloadBoardList();
        });
    }

    goToNewBoardTab() {
        this.activeTabIndex = null;
    }

    handleTabClick(boardId: number, index: number) {
        this.activeTabId = this.boardsList[index] ? this.boardsList[index].id : null;
        this.activeTabIndex = this.boardsList.findIndex((el) => el.id === boardId);
    }

    reloadBoardList() {
        this.busy = true;
        this.boardService.list().then((boardsList) => {
            this.busy = false;
            // ordering by name asc
            this.boardsList = boardsList;

            // check if boardsList is not empty
            if (this.boardsList.length && this.sessionService.profile) {
                // check default board
                // set default board index if it exists
                if (
                    this.sessionService.profile.settings &&
                    this.sessionService.profile.settings.widget_boards_settings &&
                    this.sessionService.profile.settings.widget_boards_settings.default_board_id
                ) {
                    this.defaultBoardIndex = this.boardsList.findIndex(
                        (board) =>
                            board.id === this.sessionService.profile!.settings!.widget_boards_settings!.default_board_id
                    );
                    // if default board is missing
                    if (this.defaultBoardIndex === -1) {
                        this.defaultBoardIndex = 0;
                        if (this.boardsList[0].id !== undefined) {
                            this.setBoardAsDefault(this.boardsList[0].id as number);
                        }
                    }
                    // set first board as default
                } else {
                    this.defaultBoardIndex = 0;
                }
                this.activeTabIndex = this.defaultBoardIndex;
                this.activeTabId = this.boardsList[this.activeTabIndex]
                    ? this.boardsList[this.activeTabIndex].id
                    : null;
            } else {
                this.activeTabIndex = null;
                this.defaultBoardIndex = 0;
            }
        });
    }

    onBoardCreated(board: any) {
        this.boardsList.push(board);

        // saving new board order to profile
        // this.profilesService.saveDashboardOrders(
        //     this.sessionService.profile.id,
        //     this.boardsListDnD.map((el) => el.id)
        // ).subscribe(res => {
        //     this.editMode = this.appboard.boardSettings.customizeIsVisible;
        // });

        // this.activeTabIndex = this.boardsList.length;
        // setTimeout(() => {
        this.activeTabIndex = this.boardsList.length - 1;
        this.activeTabId = this.boardsList[this.activeTabIndex] ? this.boardsList[this.activeTabIndex].id : null;
        // });
    }

    onCloseBoard() {
        const newIndex = this.boardsList.findIndex((el) => el.id === this.activeTabId);

        // set create board view
        if (newIndex > -1) {
            this.activeTabIndex = newIndex;
            this.editMode = false;
        }
    }

    onDeleteBoard(board: any) {
        const boardIndex = this.boardsList.indexOf(board);
        this.editMode = false;

        if (boardIndex > -1) {
            this.boardsList.splice(boardIndex, 1);

            // set create board view
            if (this.boardsList.length === 0) {
                this.activeTabIndex = null;
                return;
            }

            // removing deleted board order from profile
            // this.profilesService.saveDashboardOrders(
            //     this.sessionService.profile.id,
            //     this.boardsListDnD.map((el) => el.id)
            // ).subscribe(res => {
            //     this.editMode = false;
            // });

            // set default tab as first
            this.activeTabIndex = 0;
            this.activeTabId = this.boardsList[this.activeTabIndex] ? this.boardsList[this.activeTabIndex].id : null;
        }
    }

    setBoardAsDefault(boardId: number) {
        // check if selected default deal board is the same
        if (
            this.sessionService.profile &&
            this.sessionService.profile.settings &&
            this.sessionService.profile.settings.widget_boards_settings &&
            this.sessionService.profile.settings.widget_boards_settings.default_board_id &&
            this.sessionService.profile.settings.widget_boards_settings.default_board_id === boardId
        ) {
            return;
        }

        const settings = Object.assign({}, this.sessionService.profile!.settings, {
            widget_boards_settings: {default_board_id: boardId}
        });

        // update profile in DB
        if (this.sessionService.profile && this.sessionService.profile.id) {
            this.profilesService
                .updateProfileSettings(this.sessionService.profile!.id, settings)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    this.defaultBoardIndex = this.boardsList.findIndex((board) => board.id === boardId);
                    // update current profile
                    this.sessionService.setSettings = {
                        widget_boards_settings: {
                            default_board_id: this.boardsList[this.defaultBoardIndex].id
                        }
                    };
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
