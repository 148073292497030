import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Board, BoardSettings} from 'app/models/board';
import {BoardService} from '../../../services/board.service';
import {AuthService} from 'app/services/auth.service';
import {NotificationsService} from 'angular2-notifications';
import {UserService} from 'app/services/user.service';
import {SessionService} from 'app/services/session.service';

import {NotificationsServiceZipi} from '../../../../notifications/notifications.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-board-settings',
    template: `
        <div class="px-3 edit-mode d-flex align-items-baseline justify-content-between" *ngIf="editMode">
            <!------- Tab Title ------->
            <div class="d-flex align-items-baseline mt-2">
                <mat-form-field *ngIf="board">
                    <input matInput [(ngModel)]="board.name" placeholder="Tab Title" />
                </mat-form-field>
                <button mat-stroked-button color="warn" class="ml-4 remove-dashboard-button" (click)="removeBoard()">
                    Remove Dashboard
                </button>
            </div>

            <!------- Buttons ------->
            <div class="d-flex">
                <div>
                    <button mat-button color="primary" (click)="clickCustomize(false)">Cancel</button>
                    <button mat-raised-button class="ml-2 mat-primary" (click)="clickCustomize(true)">Save</button>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./board-settings.component.scss']
})
export class BoardSettingsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Output() filterApplied = new EventEmitter();
    @Output() customizeVisibility = new EventEmitter();
    @Output() deleteBoard = new EventEmitter();

    @Input() board: Board | null = null;
    @Input() widgetsList: any[] = [];
    @Input() editMode: boolean = false;
    public model: any;

    constructor(
        protected boardService: BoardService,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected notificationsService: NotificationsServiceZipi,
        protected angularNotificationsService: NotificationsService,
        protected userService: UserService
    ) {}

    ngOnInit() {
        if (this.board && this.board.settings) {
            if (Object.keys(this.board.settings).length === 0) {
                this.board.settings = new BoardSettings();
            }
        }
    }

    saveBoardSettings() {
        if (this.board) {
            if (this.board.settings && this.board.settings.data_specified_by_current_user === true) {
                this.board.settings.filter_by_deal_members = [];
            }

            return this.boardService
                .update(Number(this.board.id), {
                    name: this.board.name,
                    settings: this.board.settings
                })
                .then(() => {
                    this.angularNotificationsService.success(`Board was successfully saved.`);
                });
        }
    }

    clickCustomize(boolean = false) {
        this.editMode = !this.editMode;
        this.customizeVisibility.emit(boolean);

        if (!this.editMode && boolean) {
            return this.saveBoardSettings();
        }
    }

    removeBoard() {
        this.notificationsService
            .addConfirm('Are you sure you want to delete this board?')!
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((confirmed) => {
                if (!confirmed) {
                    return false;
                }

                return this.boardService.softDelete(Number(this.board!.id)).then((result: any) => {
                    if (result) {
                        this.deleteBoard.emit(true);
                        // update order list after removing
                        this.sessionService.setNewOrderList =
                            this.sessionService.profile && this.sessionService.profile.dashboard_order
                                ? this.sessionService.profile.dashboard_order.filter((id) => id !== this.board!.id)
                                : [];
                    }
                });
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.filterApplied.complete();
        this.customizeVisibility.complete();
        this.deleteBoard.complete();
    }
}
