import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {SessionService} from '../../../services/session.service';
import {UserService} from '../../../services/user.service';

import {environment} from 'environments/environment';
import {OAuthApi} from './oauth.service.api';
import {MatDialog} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {AddonsApi} from '../../profile/addons/addons.api';
import {MarketplaceAddonModel} from '../../account-info/marketplace/marketplace.models';
import {Profile} from '../../../models/profile';
import {SKYSLOPE_BOOKS_LARGE_LOGO} from '../../../local-typings';

@Component({
    selector: 'app-oauth',
    template: `
        <!-- ERROR STATE -->
        <mat-card class="sign-in-card" *ngIf="criticalError">
            <mat-card-header>
                <img class="books-logo" [src]="skyslopeLogo" />
                <div [ngClass]="isWarning ? 'not-critical-error' : 'critical-error'">{{ criticalError }}</div>
                <div *ngIf="currentProfile" style="font-size: 15px; padding-bottom: 20px;">
                    <div class="text-overflow-ellipsis" [matTooltip]="currentProfile.email ? currentProfile.email : ''">
                        {{ currentProfile.email }}
                    </div>
                    <div *ngIf="currentProfile.company && showCompanyTitle">({{ currentProfile?.company?.title }})</div>
                </div>
            </mat-card-header>
        </mat-card>
        <!-- DISALLOW STATE -->
        <mat-card class="sign-in-card" *ngIf="isConsentScreenDisallowed">
            <mat-card-header>
                <img class="books-logo" [src]="skyslopeLogo" />
                <div class="title">The authorization request has been denied, no data has been shared.</div>
                <div class="bottom">To continue, please close this window.</div>
            </mat-card-header>
        </mat-card>
        <!-- NEED ALLOW STATE -->
        <mat-card class="sign-in-card" *ngIf="isConsentScreen && !isConsentScreenDisallowed">
            <mat-card-header>
                <img class="books-logo" [src]="skyslopeLogo" />
                <div class="consent-screen">
                    {{ addon!.preset!.title }} is requesting access to data in your SkySlope Books account.
                </div>
                <div *ngIf="currentProfile" style="font-size: 15px; padding-bottom: 20px;">
                    <div class="text-overflow-ellipsis" [matTooltip]="currentProfile.email ? currentProfile.email : ''">
                        {{ currentProfile.email }}
                    </div>
                    <div *ngIf="currentProfile.company && showCompanyTitle">({{ currentProfile?.company?.title }})</div>
                </div>
            </mat-card-header>
            <mat-card-actions class="d-flex justify-content-center">
                <button mat-button color="primary" (click)="cancelConsent()">Cancel</button>
                <button mat-raised-button color="primary" (click)="allowConsent()">Allow</button>
            </mat-card-actions>
        </mat-card>
        <div class="load-container-text" *ngIf="!criticalError && !isConsentScreen && !isConsentScreenDisallowed">
            Validating Credentials and Authorization...
        </div>
    `,
    styles: [
        `
            .text-overflow-ellipsis {
                white-space: nowrap;
                text-overflow: ellipsis;
                display: block;
                overflow: hidden;
            }
            mat-card.sign-in-card {
                margin: 10% auto;
                max-width: 350px;
            }
            mat-card-header {
                display: block;
                text-align: center;
                font-size: 16px;
                color: #3f5b77;
            }
            .consent-screen {
                padding: 38px;
            }

            mat-card-header .title {
                padding: 32px;
            }
            mat-card-header .bottom {
                font-size: 14px;
                padding: 15px;
            }
            mat-card-header .critical-error {
                padding: 32px;
                color: #a83333;
            }
            mat-card-header .not-critical-error {
                padding: 32px;
            }

            mat-form-field {
                width: 100%;
            }

            mat-card-actions {
                border-top: 1px solid #dee5ed;
                margin-left: -16px;
                margin-right: -16px;
                padding: 16px;
                padding-bottom: 8px !important;
                text-align: center;
            }

            button {
                padding-left: 40px;
                padding-right: 40px;
                margin: 20px auto;
            }

            .books-logo {
                width: 120px;
                margin-top: 30px;
            }
        `
    ]
})
export class OauthComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    public isWarning = false;
    public criticalError: string | null = null;
    public isConsentScreen: boolean | null = null;
    public isConsentScreenDisallowed = false;
    public addon:
        | (MarketplaceAddonModel & {
              addonInstanceForCompany: any;
              addonInstanceForProfile: any;
              addonInstanceForUser: any;
          })
        | null = null;
    public currentProfile: Profile | null = null;
    public AUTH_SCOPE_PROFILE = 'profile_access';
    public AUTH_SCOPE_USER = 'user_access';
    public showCompanyTitle = false;
    skyslopeLogo = SKYSLOPE_BOOKS_LARGE_LOGO;

    constructor(
        public authService: AuthService,
        public sessionService: SessionService,
        public userService: UserService,
        public dialog: MatDialog,
        private oAuthApiService: OAuthApi,
        private activatedRoute: ActivatedRoute,
        protected addonsApi: AddonsApi
    ) {}

    async ngOnInit() {
        if (!this.authService.isLoggedIn()) {
            let url = environment.loginPageURL;
            if (Object.keys(this.activatedRoute.snapshot.queryParams).length > 0) {
                const queryParamsString = Object.keys(this.activatedRoute.snapshot.queryParams)
                    .map((key) => {
                        return key + '=' + this.activatedRoute.snapshot.queryParams[key];
                    })
                    .join('&');
                url = `${url}?original_path=${encodeURIComponent(`/auth/oauth?${queryParamsString}`)}`;
            }
            window.location.href = url;
            return;
        }

        // by default scope should be AUTH_SCOPE_PROFILE
        const scope = this.activatedRoute.snapshot.queryParams['scope'] || this.AUTH_SCOPE_PROFILE;

        // show company title only for profile scope
        this.showCompanyTitle = scope !== this.AUTH_SCOPE_USER;

        if (scope !== this.AUTH_SCOPE_PROFILE && scope !== this.AUTH_SCOPE_USER) {
            this.criticalError = `Invalid scope param. Must be ${this.AUTH_SCOPE_PROFILE} or ${this.AUTH_SCOPE_USER}`;
            return;
        }

        if (!this.activatedRoute.snapshot.queryParams['client_id']) {
            this.criticalError = 'Invalid request. client_id param required';
            return;
        }
        this.addon = await this.addonsApi.getAddonByClientId(this.activatedRoute.snapshot.queryParams['client_id']);
        if (!this.addon) {
            this.criticalError = 'Invalid request. Addon not found.';
            return;
        }

        this.currentProfile = this.sessionService.profile;

        // addons can be splitted by access scope to profile-addons and user-addons
        // if addon has no settings.enabled_user_instances (profile scope) this.addon must have addonInstanceForCompany and addonInstanceForProfile
        // else if addon has settings.enabled_user_instances (user scope) this.addon must have addonInstanceForUser

        const addonTitle = this.addon.preset ? this.addon.preset.title : 'Unknown addon';

        // if PROFILE SCOPE
        if (scope === this.AUTH_SCOPE_PROFILE) {
            // check if COMPANY addon instance existing and activated
            if (!this.addon.addonInstanceForCompany || !this.addon.addonInstanceForCompany.activated) {
                this.isWarning = true; // this is not critical error, so it should not be red
                this.criticalError = `To begin using ${addonTitle}, please activate this addon in your Marketplace`;
                return;
            }

            // check if PROFILE addon instance existing and activated
            if (this.addon && this.addon.settings && this.addon.settings.enabled_personal_instances) {
                const isProfileInstanceActivated =
                    this.addon.addonInstanceForProfile && this.addon.addonInstanceForProfile.activated;
                if (!isProfileInstanceActivated) {
                    this.isWarning = true; // this is not critical error, so it should NOT be red
                    this.criticalError = `To begin using ${addonTitle}, please activate this addon in your Profile > Apps > Company Addons`;
                    return;
                }
            }
        } else if (scope === this.AUTH_SCOPE_USER) {
            // if USER SCOPE
            // check if COMPANY addon instance existing and activated
            if (!this.addon.addonInstanceForUser || !this.addon.addonInstanceForUser.activated) {
                this.isWarning = true; // this is not critical error, so it should NOT be red
                this.criticalError = `To begin using ${addonTitle}, please activate this addon in Profile > Apps > User Addons`;
                return;
            }
        }

        // todo add checking in db that user already allowed consent
        if (
            this.activatedRoute.snapshot.queryParams['ignore_consent'] &&
            this.activatedRoute.snapshot.queryParams['ignore_consent'] === 'yes'
        ) {
            await this.generateCode();
        } else {
            this.isConsentScreen = true;
        }
    }

    async generateCode() {
        const oAuthClientId = this.activatedRoute.snapshot.queryParams['client_id'];
        const oAuthState = this.activatedRoute.snapshot.queryParams['state'];
        const oAuthScope = this.activatedRoute.snapshot.queryParams['scope'] || this.AUTH_SCOPE_PROFILE;
        const oAuthRedirectUri = this.activatedRoute.snapshot.queryParams['redirect_uri'];
        // UPD: we do not use TYK anymore and "api_system" param is not used in Apigee
        // this param need to identify to which exactly API management system we need to make request - TYK or Apigee
        // const isApigee = this.activatedRoute.snapshot.queryParams['api_system'] && this.activatedRoute.snapshot.queryParams['api_system'] === 'apigee';

        if (!this.sessionService || !this.sessionService.user || !this.sessionService.profile) {
            this.criticalError = 'Unauthorized.';
            return;
        }

        // for Apigee
        // if (isApigee && this.sessionService) {
        let redirectToURL =
            environment.oAuthCallbackURL +
            '?client_id=' +
            oAuthClientId +
            '&scope=' +
            oAuthScope +
            '&response_type=' +
            this.activatedRoute.snapshot.queryParams['response_type'] +
            (oAuthState ? '&state=' + oAuthState : '') +
            (oAuthRedirectUri ? '&redirect_uri=' + oAuthRedirectUri : '');

        redirectToURL =
            redirectToURL +
            '&zipi_user_id=' +
            this.sessionService.user.id +
            '&zipi_profile_id=' +
            this.sessionService.profile.id;

        // console.info('redirectToURL', redirectToURL);
        window.location.href = redirectToURL;
        return;
        // }

        // for TYK
        // return await this.oAuthApiService
        //     .generateAuthorizationCode(oAuthClientId, oAuthState, oAuthScope)
        //     .then(authorizationCodeResponse => {
        //         if (authorizationCodeResponse.redirect_to) {
        //             window.location.href = authorizationCodeResponse.redirect_to;
        //         } else {
        //             this.criticalError = 'Error on getting oauth code.';
        //         }
        //     })
        //     .catch(err => {
        //         this.criticalError = err;
        //     });
    }

    async allowConsent() {
        this.isConsentScreen = false;
        await this.generateCode();
    }

    cancelConsent() {
        this.isConsentScreenDisallowed = true;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
