<mat-card style="box-shadow: none">
    <div class="row">
        <div class="col-md-6">
            <mat-form-field class="w-100" *ngIf="formGroup && formGroup.controls.name">
                <input matInput type="text" placeholder="Company name" [formControl]="formGroup.controls.name" />
            </mat-form-field>
        </div>

        <div class="col-md-6">
            <mat-form-field class="w-100" *ngIf="formGroup && formGroup.controls.industry">
                <mat-select name="industry" placeholder="Industry" [formControl]="formGroup.controls.industry">
                    <mat-option value="real_estate">Real Estate</mat-option>
                    <mat-option value="other">Other</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <!--    <mat-form-field class="w-100" rbac [rule]="{manage_groups : true}">-->
    <!--        <input matInput name="royalty_value"-->
    <!--                placeholder="Vision"-->
    <!--                type="text"-->
    <!--                [formControl]="formGroup.controls.vision">-->
    <!--    </mat-form-field>-->

    <div class="row">
        <div class="col-md-4">
            <mat-form-field class="w-100" *ngIf="formGroup && formGroup.controls.tin">
                <input
                    matInput
                    name="tin"
                    placeholder="TIN"
                    type="text"
                    #tinInput
                    [maskito]="tinInputMask"
                    [formControl]="formGroup.controls.tin"
                    (blur)="onBlurMethod()"
                    (click)="onClickMethod()"
                />
                <mat-error *ngIf="formGroup.controls.tin.hasError('incorrect')"> TIN must be 9 digits long. </mat-error>
            </mat-form-field>
        </div>
    </div>

    <!--<mat-slide-toggle class="my-3" [formControl]="formGroup.controls.is_external_groups_allowed">-->
    <!--Allow your company  users take part in external groups-->
    <!--</mat-slide-toggle>-->

    <!--    <div rbac [rule]="{manage_groups : true}" [denyMethod]="'style'"-->
    <!--            [allowStyle]="{display : 'block'}" [denyStyle]="{display : 'none'}">-->

    <!--        <h3>Values</h3>-->
    <!--        <ng-container *ngFor="let value of formGroup.controls.company_values.controls; index as i">-->
    <!--            <mat-form-field class="">-->
    <!--                <span matPrefix class="prefix">#{{i+1}}</span>-->
    <!--                <input matInput name="values"-->
    <!--                        type="text"-->
    <!--                        [formControl]="value">-->
    <!--            </mat-form-field>-->
    <!--        </ng-container>-->
    <!--<div class="col field-in-row" >-->
    <!--<h3>Focus Areas</h3>-->
    <!--<ng-container *ngFor="let focus_area of formGroup.controls.focus_areas.controls; index as i">-->
    <!--<mat-form-field  class="field">-->
    <!--<span matPrefix class="prefix">#{{i+1}}</span>-->
    <!--<input matInput name="focus_areas"-->
    <!--type="text"-->
    <!--[formControl]="focus_area.controls.title">-->
    <!--</mat-form-field>-->
    <!--</ng-container>-->
    <!--</div>-->
    <!--    </div>-->
</mat-card>
