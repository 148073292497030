import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from '../../../services/session.service';
import {AuthService} from '../../../services/auth.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-outer-link-trigger',
    templateUrl: './outer-link-trigger.component.html',
    styleUrls: ['./outer-link-trigger.component.scss']
})
export class OuterLinkTriggerComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    isReloginNeed: boolean;
    isLoginNeed: boolean;

    companyId: number | null = null;
    loadId: number | null = null;

    constructor(
        private router: Router,
        private activateRoute: ActivatedRoute,
        public sessionService: SessionService,
        protected authService: AuthService
    ) {
        this.isReloginNeed = false;
        this.isLoginNeed = false;
    }

    ngOnInit() {
        this.activateRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.companyId = Number(data['company_id']);
            this.loadId = Number(data['load_id']);

            if (data['company_id'] && this.sessionService.profile && this.sessionService.profile.company) {
                if (this.companyId === this.sessionService.profile.company.id) {
                    if (this.router.url.includes('new-request')) {
                        this.router.navigate([`/carrier-load-requests`, this.loadId]);
                    }
                    if (this.router.url.includes('new-message')) {
                        this.router.navigate([`/carrier-load-requests/message`, this.loadId]);
                    }
                } else {
                    this.isReloginNeed = true;
                }
            } else {
                this.isLoginNeed = true;
            }
        });
    }

    async logoutAndGoTo() {
        await this.authService.logout();

        this.sessionService.setRedirectParams([`company/${this.companyId}/new-request/${this.loadId}`], '', '');
        this.router.navigate([`login`]);
    }

    async loginAndGoTo() {
        await this.authService.logout();

        this.sessionService.setRedirectParams([`company/${this.companyId}/new-request/${this.loadId}`], '', '');
        this.router.navigate([`login`]);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
