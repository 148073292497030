import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {Observable} from 'rxjs';
import {IRecurringCreditNote} from '@cyberco-nodejs/zipi-typings';
import {IScrollData} from '../../../models/scroll-data';

@Injectable()
export class RecurringCreditNotesService {
    private url = '/finance/recurringcreditnotes/';

    constructor(protected requester: ServiceRequester) {}

    getRecurringCreditNotes(
        scrollData: IScrollData
    ): Observable<{_meta: {total: number}; result: IRecurringCreditNote[]}> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp', scrollData);
    }

    getRecurringCreditNoteById(recurringCreditNoteId: number): Observable<IRecurringCreditNote> {
        return this.requester.makeMsCall$(this.url + `${recurringCreditNoteId}`, 'GET', 'shipp');
    }

    createRecurringCreditNote(data: Partial<IRecurringCreditNote>): Observable<IRecurringCreditNote> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    updateRecurringCreditNote(data: IRecurringCreditNote): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + data.recurring_credit_note_id, 'PUT', 'shipp', data);
    }

    activateRecurringCreditNote(recurringCreditNoteId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${recurringCreditNoteId}/activate`, 'PUT', 'shipp');
    }

    stopRecurringCreditNote(recurringCreditNoteId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${recurringCreditNoteId}/stop`, 'PUT', 'shipp');
    }

    deleteRecurringCreditNote(recurringCreditNoteId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + recurringCreditNoteId, 'DELETE', 'shipp');
    }
}
