import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {timePeriods} from '../../../../../constants';
import {UntypedFormControl} from '@angular/forms';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatButtonToggleChange} from '@angular/material/button-toggle';

interface IValue {
    time_period: string | null;
    time_period_start: string | null;
    time_period_end: string | null;
}

@Component({
    selector: 'app-report-edit-bar-time-period',
    templateUrl: './time-period.component.html',
    styleUrls: ['./time-period.component.scss']
})
export class TimePeriodComponent implements OnInit, OnDestroy {
    @Output() valueChanges: EventEmitter<IValue> = new EventEmitter<IValue>();
    @Input() value: IValue = {
        time_period: null,
        time_period_start: null,
        time_period_end: null
    };
    @Input() dateValueType: 'value_as_date' | 'value_as_int' = 'value_as_date';
    @Input() timePeriods: Array<{title: string; value: string}> = timePeriods;
    @Input() startDateControl: UntypedFormControl = new UntypedFormControl();
    @Input() endDateControl: UntypedFormControl = new UntypedFormControl();
    @Input() isDisplayListOfPeriod: boolean = true;
    @Input() isDisplayDueDate: boolean = false;
    @Input() startDateDisable: boolean = false;
    @Input() endDateDisable: boolean = false;

    private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);

    ngOnInit() {
        this.initData();
        this.initSubscription();
    }

    initData() {
        if (!this.startDateDisable) {
            this.startDateControl.setValue(this.value.time_period_start);
        }

        if (!this.endDateDisable) {
            this.endDateControl.setValue(this.value.time_period_end);
        }
    }

    initSubscription() {
        this.startDateControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            this.value['time_period_start'] = value;
            this.valueChanges.emit(this.value);
        });
        this.endDateControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            this.value['time_period_end'] = value;
            this.valueChanges.emit(this.value);
        });
    }

    onChange(event: MatButtonToggleChange) {
        this.value['time_period'] = event.value;
        this.valueChanges.emit(this.value);
    }

    ngOnDestroy() {
        this.valueChanges.complete();
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }
}
