import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';

@Component({
    selector: 'app-custom-select-bank-account-dialog',
    templateUrl: './custom-select-bank-account-dialog.component.html',
    styleUrls: ['./custom-select-bank-account-dialog.component.scss']
})
export class CustomSelectBankAccountDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    allBankAccounts: Array<{[key: string]: any}>;
    filteredBankAccounts: Array<{[key: string]: any}>;
    institutionName: string | undefined;
    selectedAccount: {[key: string]: any} | undefined;
    isTrustAccount: boolean;

    constructor(
        public dialogRef: MatDialogRef<CustomSelectBankAccountDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            metadata: {accounts: Array<{[key: string]: any}>; institution: {name: string}};
            isTrustAccount: boolean;
        }
    ) {
        this.allBankAccounts = [];
        this.filteredBankAccounts = [];
        this.isTrustAccount = false;
    }

    ngOnInit() {
        this.allBankAccounts = this.data.metadata.accounts;
        this.isTrustAccount = this.data.isTrustAccount;
        this.filteredBankAccounts = this.allBankAccounts.filter((acc) => {
            return (
                ['depository', 'credit'].includes(acc.type) &&
                ['checking', 'savings', 'credit card'].includes(acc.subtype)
            );
        });
        this.institutionName = this.data.metadata.institution.name;
    }

    selectAccount(account: {[key: string]: any}) {
        this.selectedAccount = account;
    }

    continue() {
        this.dialogRef.close(this.selectedAccount);
    }

    close() {
        this.dialogRef.close(null);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
