import {Component, OnInit, Input, OnDestroy, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IBill} from '@cyberco-nodejs/zipi-typings/finance/bill-invoice.typings';
import {BillsService} from 'app/services/api/finance/bills.service';
import {MatPaginator} from '@angular/material/paginator';
import {IScrollData} from 'app/models/scroll-data';
import {PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-contact-bills',
    templateUrl: 'contact-bills.component.html',
    styleUrls: ['../../../../../../assets/infinite-scroll-table.scss']
})
export class ContactBillsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBills: Subject<void> = new Subject();

    @Input() contactId: number | undefined | null;

    @ViewChild(MatSort, {static: false}) sort: MatSort | null = null;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | null = null;

    isLoaded: boolean = false;

    scrollData: IScrollData = {
        offset: 0,
        limit: 50,
        sort_direction: 'asc',
        sort_column: 'invoice_date',
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<IBill>;
    displayedColumns = ['invoice_date', 'bill_number', 'total_amount', 'summary_status'];

    constructor(private billsService: BillsService) {
        this.dataSource = new MatTableDataSource<IBill>([]);
    }

    ngOnInit() {
        this.dataSource.sort = this.sort;

        this.nextBatch();

        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.nextBatch();
            });
        }
    }

    nextBatch() {
        this.unsubscribeBills.next();

        this.billsService
            .getBillsByReceiverContactId(Number(this.contactId), this.scrollData)
            .pipe(takeUntil(this.unsubscribeBills))
            .subscribe((data) => {
                this.dataSource.data = data.result;
                this.scrollData.total = data._meta.total;
                this.isLoaded = true;
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBills.next();
        this.unsubscribeBills.complete();
    }
}
