<div>
    <div [formGroup]="paymentForm">
        <h2>Payment</h2>

        <div class="d-flex w-100">
            <div class="w-100">
                <mat-form-field class="w-100">
                    <mat-select formControlName="request_id">
                        <ng-container *ngFor="let request of listOfRequests">
                            <mat-option [value]="request.deposit_request_id" *ngIf="request.request_balance > 0.01">
                                <span
                                    >{{ $any(request.request_date) | dateFromNumber | date: 'mediumDate' }} (${{
                                        request.request_amount
                                    }}) From: {{ request.request_receiver_contact!.display_name }}</span
                                >
                            </mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-placeholder>Deposit Request</mat-placeholder>
                </mat-form-field>
            </div>
        </div>
        <div class="d-flex w-100">
            <div class="w-50 mr-2">
                <app-date-picker [placeholder]="'Payment Date'" [dateControl]="paymentForm.controls.paid_date">
                </app-date-picker>
            </div>

            <mat-form-field class="w-50 mr-1">
                <mat-label>Amount</mat-label>
                <input
                    [maskito]="currencyMaskitoMask"
                    matInput
                    type="text"
                    formControlName="amount"
                    name="amount"
                    autocomplete="off"
                    matTooltipPosition="above"
                    (keyup)="validateAmount($event)"
                />
            </mat-form-field>
        </div>
        <div class="d-flex w-100">
            <div class="w-50 mr-2">
                <mat-form-field class="w-100">
                    <mat-select formControlName="payment_mode">
                        <ng-container *ngFor="let mode of paymentModes">
                            <mat-option [value]="mode.slug" *ngIf="mode.is_selectable">
                                <span>{{ mode.title }}</span>
                            </mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-placeholder>Payment Mode</mat-placeholder>
                </mat-form-field>
            </div>

            <div class="w-50">
                <app-ledger-account-selector
                    class="w-100"
                    [preferredTypes]="['trust']"
                    [ledgerAccountControl]="paymentForm.controls.pay_to__ledger_account_fk_id"
                    [placeholder]="'Deposit to'"
                >
                </app-ledger-account-selector>
            </div>
        </div>

        <div class="d-flex w-100" *ngIf="paymentForm.controls.payment_mode.value === 'check_record'">
            <mat-form-field class="w-50 mr-2">
                <input matInput type="text" formControlName="check_number" placeholder="Check#" />
            </mat-form-field>
            <mat-form-field class="w-50">
                <input matInput type="text" formControlName="memo" placeholder="Memo" />
            </mat-form-field>
        </div>

        <div class="d-flex w-100">
            <mat-form-field class="w-50">
                <input matInput type="text" formControlName="note" placeholder="Notes" />
            </mat-form-field>
        </div>

        <div class="d-flex justify-content-end mt-3">
            <button
                class="mr-2"
                *ngIf="!data.hideCancel"
                mat-raised-button
                (click)="dialogRef.close(false)"
                tabindex="-1"
            >
                Cancel
            </button>
            <button class="mr-2" mat-raised-button color="primary" (click)="continue()">Create</button>
        </div>
    </div>
</div>
