<section class="container-fluid" #listSection>
    <div *ngIf="isLoaded" class="mt-4">
        <div class="d-flex justify-content-between">
            <h4>Connected users:</h4>
            <div class="d-flex">
                <div class="mr-1" *ngIf="showManageUsersBtn">
                    <a
                        [rule]="manageUsersBtnRules"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'block'}"
                        [denyStyle]="{display: 'none'}"
                        [disabled]="!manageUsersBtnUrl"
                        mat-raised-button
                        [href]="manageUsersBtnUrl"
                        target="_blank"
                    >
                        Manage Users
                    </a>
                </div>
                <div *ngIf="!companySettingsBtnHidden">
                    <!-- disabled if addon is not connected for this user -->
                    <a
                        [matTooltip]="
                            addonCompanySettingsUrl
                                ? ''
                                : 'To access \'Company Settings\', please first connect to the addon through the Profile Menu > Apps option (located in top right corner of screen).'
                        "
                        [disabled]="!addonCompanySettingsUrl"
                        mat-raised-button
                        [href]="addonCompanySettingsUrl"
                        target="_blank"
                    >
                        Company Settings
                    </a>
                </div>
            </div>
        </div>

        <mat-form-field>
            <mat-label>Connected status</mat-label>
            <mat-select [(ngModel)]="connectedStatus" name="connectionStatus" (ngModelChange)="applyFilter()">
                <mat-option *ngFor="let status of listOfConnectionStatus" [value]="status.value">
                    {{ status.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="search">
            <input
                matInput
                placeholder="Search by Profile"
                [(ngModel)]="searchProfile"
                (ngModelChange)="searchByProfile()"
            />
        </mat-form-field>

        <table
            mat-table
            *ngIf="dataSource.length"
            [dataSource]="dataSource"
            class="mt-3"
            (matSortChange)="onSortChange($event)"
            matSort
        >
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element">{{ element.first_name }} {{ element.last_name }}</td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let element">{{ element.email }}</td>
            </ng-container>
            <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Connected</th>
                <td mat-cell *matCellDef="let element">{{ element.created_at | date: 'medium' }}</td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let element" [innerHTML]="element.description"></td>
            </ng-container>

            <ng-container matColumnDef="connected_status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">{{ element.connected_status }}</td>
            </ng-container>

            <ng-container *ngIf="showActionsRow" matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let element">
                    <button
                        *ngIf="element.connected_status === 'Connected'"
                        [rule]="disconnectUserBtnRules"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'block'}"
                        [denyStyle]="{display: 'none'}"
                        mat-button
                        color="warn"
                        (click)="disconnectAddonForUser(element)"
                    >
                        Disconnect
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <div *ngIf="total" class="text-muted font-weight-bold mt-3">Total: {{ total }}</div>

        <mat-card *ngIf="!dataSource.length">
            <h4>No data</h4>
        </mat-card>
    </div>
</section>
