import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AccessToken, IDToken} from '@okta/okta-auth-js';
import {Environment, UserManager, getEnvironmentSettings, TokenDomain} from '@skyslope/auth-js';
import {AuthConfig, ViewOptions} from '@skyslope/auth-sign-in-widget/dist/types/types/config';
import {environment} from 'environments/environment';
import {firstValueFrom, Observable, Observer} from 'rxjs';
import {getOktaEnv, getUserManager} from './user-manager';
import {ApiSessionService} from '../../../../services/api/api-session.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';

@Injectable({
    providedIn: 'root'
})
export class SkyslopeAuth {
    userManager: UserManager | undefined;
    primeToken: string | null = '';
    widget: any;
    dynamicBaseUrl = environment.barePageURL;
    standardRedirectPath = `${this.dynamicBaseUrl}`;
    getTermsAndConditionsPath = `${this.dynamicBaseUrl}/terms-and-conditions`;

    overrideConfig = {
        okta: {
            issuer: environment.okta.issuer,
            clientId: environment.okta.clientId
        }
    };

    $isAuthenticated: Observable<boolean>;
    private observer?: Observer<boolean>;

    constructor(
        protected apiSessionService: ApiSessionService,
        public afAuth: AngularFireAuth
    ) {
        this.$isAuthenticated = new Observable((observer: Observer<boolean>) => {
            this.observer = observer;
            this.isAuthenticated().then((val) => {
                observer.next(val);
            });
        });

        this.userManager = getUserManager();

        const thisService = this;

        this.userManager?._oktaAuth.oktaAuth.tokenManager.on('renewed', async function (key, newToken, oldToken) {
            console.info('OktaToken Renewal Completed!');
            console.info(`key : ${key}`);
            const userManager = getUserManager();

            if (key === 'idToken') {
                console.info('updating idToken');
                userManager?._oktaAuth.oktaAuth.tokenManager.remove(key);
                userManager?._oktaAuth.oktaAuth.tokenManager.add('idToken', newToken as IDToken);
            }
            if (key === 'accessToken') {
                console.info('updating accessToken');
                userManager?._oktaAuth.oktaAuth.tokenManager.remove(key);
                userManager?._oktaAuth.oktaAuth.tokenManager.add('accessToken', newToken as AccessToken);
                userManager?._oktaAuth.oktaAuth.tokenManager.add('token', newToken as AccessToken);

                //update session cookies with new tokens
                const token = (newToken as AccessToken).accessToken;
                await thisService.updateTokens(token);
            }
        });
    }

    async getUser() {
        this.userManager = getUserManager();
        const token = this.userManager?._oktaAuth.oktaAuth.getAccessToken();
        if (token) {
            return this.userManager.getUser();
        } else {
            return null;
        }
    }

    getIdToken() {
        this.userManager = getUserManager();
        return this.userManager?._oktaAuth.oktaAuth.getIdToken();
    }

    async getAccessToken() {
        this.userManager = getUserManager();
        return this.userManager?._oktaAuth.oktaAuth.getAccessToken();
    }

    updateAuthState() {
        this.userManager = getUserManager();
        return this.userManager?._oktaAuth.oktaAuth.authStateManager.updateAuthState();
    }

    async clearSession() {
        this.userManager = getUserManager();
        await this.userManager?._oktaAuth.oktaAuth.revokeAccessToken();
        this.userManager?._oktaAuth.oktaAuth.tokenManager.clear();
    }

    async closeSession() {
        this.userManager = getUserManager();
        await this.userManager?._oktaAuth.oktaAuth.closeSession();
    }

    async isAuthenticated() {
        this.userManager = getUserManager();
        // Checks if there is a current accessToken in the TokenManger.
        return !!this.userManager?._oktaAuth.oktaAuth.getAccessToken();
    }

    getAuthConfig = (env: Environment) => {
        const envSettings = getEnvironmentSettings(env);
        return {
            okta: Object.assign({}, envSettings.okta, this.overrideConfig.okta)
        };
    };

    async createWidget(element: string, startOnRegistration: boolean) {
        let anchorEl: HTMLDivElement = document.getElementById(element) as HTMLDivElement;
        if (anchorEl != null) {
            const env = getOktaEnv();
            if (env && Object.values(Environment).includes(env)) {
                const authConfig = this.getAuthConfig(env);
                this.userManager = getUserManager();

                let authConfigOptions: AuthConfig = {
                    userManager: this.userManager,
                    idpDiscovery: authConfig.okta.idpDiscovery,
                    redirectAfterPasswordResetUrl: this.standardRedirectPath
                };
                let viewOptions: ViewOptions = {
                    applicationNameOverride: 'SkySlope Books',
                    startOnRegistrationView: startOnRegistration,
                    showOpenTermsBtn: true,
                    showSignInWithSkySlopeBtn: false,
                    showSignInWithGoogleBtn: false,
                    onOpenTermsClick: () => {
                        window.open(this.getTermsAndConditionsPath);
                    }
                };

                let skyslopeSignInWidget = await import('@skyslope/auth-sign-in-widget');
                this.widget = skyslopeSignInWidget.default({
                    anchorEl,
                    authConfigOptions,
                    viewOptions
                });
            }
        }
        return this.widget;
    }

    logout = async (includeOktaLogout: boolean = true) => {
        await this.clearSession();
        await this.closeSession();
        if (includeOktaLogout) {
            await this.userManager?.startLogout().then(() => console.info('Auth JS Logout'));
        }
    };

    async handlePreAuthentication() {
        this.userManager = getUserManager();
        await this.userManager.login();
    }

    async handleTokenAuthentication() {
        this.userManager = getUserManager();

        const tokenResponse = await this.userManager._oktaAuth.oktaAuth.token
            .parseFromUrl({responseMode: 'query'})
            .then((resp) => {
                this.userManager?._oktaAuth.oktaAuth.tokenManager.add('idToken', resp.tokens.idToken as IDToken);
                this.userManager?._oktaAuth.oktaAuth.tokenManager.add(
                    'accessToken',
                    resp.tokens.accessToken as AccessToken
                );
                this.userManager?._oktaAuth.oktaAuth.tokenManager.add('token', resp.tokens.accessToken as AccessToken);
            });

        if (await this.isAuthenticated()) {
            this.observer?.next(true);
        }
    }

    async refreshAccessToken() {
        this.userManager = getUserManager();
        this.userManager?._oktaAuth.oktaAuth.tokenManager.renew('accessToken');
    }

    async updateTokens(token: string | undefined) {
        if (token) {
            const customToken = (await this.apiSessionService.sessionShippCustomToken(token)).customToken;
            // Creating a custom firebase auth session on client side to help support other firebase services.
            // eg. Firebase Storage, etc.
            const firebaseUserInfo = await this.afAuth.signInWithCustomToken(customToken);

            if (!firebaseUserInfo) {
                console.error('Firebase session not created.');
            }

            await this.apiSessionService.sessionCoreLogin({id_token: null, okta_access_token: token});
            await firstValueFrom(this.apiSessionService.sessionShippLogin({id_token: null, okta_access_token: token}));
        }
    }
}
