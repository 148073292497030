import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as _ from 'lodash-es';
import {MessagesService} from '../../messages.service';
import {SpannerNotificationInterface} from '../../../../models/user-notification';

@Component({
    selector: 'report-pdf-files-modal',
    templateUrl: './report-pdf-files-modal.component.html',
    styleUrls: ['./report-pdf-files-modal.component.css']
})
export class ReportPdfFilesModalComponent implements OnInit, OnDestroy {
    files: Array<any> = [];
    isFinishedAllFiles: boolean = false;
    intervalId: any = null;
    notification: any = null;
    interval: number = 5000; // 5 sec
    maxRetry: number = 20;
    isShowRetryLaterNotification = false;

    constructor(
        public dialogRef: MatDialogRef<ReportPdfFilesModalComponent>,
        protected messageService: MessagesService,
        @Inject(MAT_DIALOG_DATA) public data: {notification: any}
    ) {
        this.setNotification(data.notification);
    }

    async ngOnInit() {
        // check notification data comes from UI
        let isAllFilesFinished = this.checkIsAllFilesFinished();
        if (!isAllFilesFinished) {
            // check notification from API
            const notificationFromApi = await this.messageService.getAsyncNotification(
                this.notification.notification_id
            );
            this.setNotification(notificationFromApi);
            isAllFilesFinished = this.checkIsAllFilesFinished();
            if (!isAllFilesFinished) {
                // start using interval
                this.startIntervalForCheckFilesStatus();
            }
        }
    }

    startIntervalForCheckFilesStatus() {
        let retry = 0;
        let promiseRetry = 0;
        let isUsedMaxRetry = false;
        this.intervalId = setInterval(() => {
            retry++;
            if (retry > this.maxRetry) {
                this.clearInterval();
                isUsedMaxRetry = true;
            }
            this.messageService
                .getAsyncNotification(this.notification.notification_id)
                .then((notification) => {
                    promiseRetry++;
                    this.setNotification(notification);
                    const isAllFilesFinished = this.checkIsAllFilesFinished();

                    if (isAllFilesFinished) {
                        this.clearInterval();
                    } else if (isUsedMaxRetry && retry === promiseRetry) {
                        this.isShowRetryLaterNotification = true;
                    }
                })
                .catch(() => {
                    promiseRetry++;
                    this.clearInterval();
                    this.isShowRetryLaterNotification = true;
                });
        }, this.interval);
    }

    checkIsAllFilesFinished() {
        if (this.notification.status === 'error') {
            return true;
        }
        return !_.some(this.files, (item: any) => !item.is_finished);
    }

    setNotification(notification: SpannerNotificationInterface) {
        const tmpNotification = {...notification};

        this.notification = tmpNotification;
        this.files = tmpNotification.payload ? Object.values(tmpNotification.payload) : [];
    }

    close() {
        this.dialogRef.close();
    }

    clearInterval() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    ngOnDestroy() {
        this.clearInterval();
    }
}
