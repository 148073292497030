import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ILedgerAccount, IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {takeUntil} from 'rxjs/operators';
import {LedgerAccountService} from '../../../../../../../services/api/finance/ledger-accounts.service';

@Component({
    selector: 'app-dwolla-payment-mehtod-edit-dialog',
    templateUrl: './zipi-fin-payment-mehtod-edit-dialog.component.html',
    styleUrls: ['./zipi-fin-payment-mehtod-edit-dialog.component.scss']
})
export class ZipiFinPaymentMehtodEditDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    currentPaymentMethod: IPaymentMethod | undefined;
    currentPaymentGateway: IPaymentGateway | undefined;

    availableLedgerAccounts: ILedgerAccount[];

    methodForm: UntypedFormGroup | undefined;

    constructor(
        public dialogRef: MatDialogRef<ZipiFinPaymentMehtodEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder,
        private ledgerAccountService: LedgerAccountService
    ) {
        this.availableLedgerAccounts = [];
    }

    ngOnInit() {
        this.currentPaymentMethod = this.data.method;
        this.currentPaymentGateway = this.data.gateway;
        if (this.currentPaymentGateway) {
            this.ledgerAccountService
                .getAvailableLedgerAccountsForFundingSourceByGatewayType(this.currentPaymentGateway.type)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((accounts) => {
                    this.availableLedgerAccounts = accounts.filter((account) => {
                        if (this.currentPaymentMethod?.type === 'zipi_financial_credit_card') {
                            return account.type === 'credit_card';
                        } else {
                            return account.type !== 'credit_card';
                        }
                    });
                });
            this.initForm();
        }
    }

    initForm() {
        if (this.currentPaymentMethod) {
            this.methodForm = this.fb.group({
                title: [this.currentPaymentMethod.title, [Validators.required]],
                related__ledger_account_fk_id: [
                    this.currentPaymentMethod.related__ledger_account_fk_id,
                    [Validators.required]
                ]
            });
        }
    }

    continue() {
        if (this.methodForm) {
            if (this.methodForm.invalid) {
                this.methodForm.markAllAsTouched();
                return;
            } else {
                const methodModifiedData = this.methodForm.getRawValue();

                this.dialogRef.close(methodModifiedData);
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
