import {Injectable} from '@angular/core';
import {ServiceRequester} from './service.requester';
import {Observable} from 'rxjs';
import {ILicenseReload} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class LicenseReloadService {
    private url = '/companies';

    constructor(public requester: ServiceRequester) {}

    getPendingLicenseReload(companyId: number): Observable<ILicenseReload[]> {
        return this.requester.makeMsCall$(`${this.url}/${companyId}/license-reload/pending`, 'GET', 'shipp');
    }
}
