import {filter, switchMap, tap, catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';

import {
    SubCompaniesActionType,
    GetSubcompanies,
    GetSubcompaniesSuccess,
    CreateSubCompany,
    CreateSubCompanySuccess,
    GetSubcompaniesAll,
    GetSubcompaniesAllSuccess
} from './subcompanies.actions';
import {ISubCompaniesState} from './subcompanies.reducer';
import {CompaniesService} from '../../../services/api/companies.service';

import {NotificationsService} from 'angular2-notifications';
import {SessionService} from '../../../services/session.service';

@Injectable()
export class SubCompaniesEffectsService {
    constructor(
        protected actions$: Actions,
        private comSrv: CompaniesService,
        private ss: SessionService,
        protected store: Store<ISubCompaniesState>,
        protected router: Router,
        private ntfs: NotificationsService
    ) {}

    @Effect({dispatch: false})
    getSubcompanie$ = this.actions$.pipe(
        ofType<GetSubcompanies>(SubCompaniesActionType.GET_SUBCOMPANIES),
        switchMap(() => {
            if (!this.ss.profile || !this.ss.profile.company || !this.ss.profile.company.id) {
                return of(null);
            }
            return this.comSrv.getSubcompaniesList(this.ss.profile.company.id);
        }),
        tap((data) => {
            if (!data) {
                return;
            }
            this.store.dispatch(new GetSubcompaniesSuccess(data));
        })
    );

    @Effect({dispatch: false})
    getSubcompanieAll$ = this.actions$.pipe(
        ofType<GetSubcompaniesAll>(SubCompaniesActionType.GET_SUBCOMPANIES_ALL),
        switchMap(() => {
            if (!this.ss.profile || !this.ss.profile.company || !this.ss.profile.company.id) {
                return of(null);
            }
            return this.comSrv.getSubcompaniesList(this.ss.profile.company.id, true);
        }),
        tap((data) => {
            if (!data) {
                return;
            }
            this.store.dispatch(new GetSubcompaniesAllSuccess(data));
        })
    );

    @Effect({dispatch: false})
    createSubCompany$ = this.actions$.pipe(
        ofType<CreateSubCompany>(SubCompaniesActionType.CREATE_SUBCOMPANY),
        switchMap((action) => {
            if (!this.ss.profile || !this.ss.profile.company || !this.ss.profile.company.id) {
                return of(null);
            }
            return this.comSrv
                .createSubcompany(this.ss.profile.company.id, action.payload!)
                .pipe(catchError(() => of(null)));
        }),
        filter((data) => !!data),
        tap((data) => {
            if (!data) {
                return;
            }
            this.store.dispatch(new CreateSubCompanySuccess(data));
            this.ntfs.success('Subcompany successfully created');
        })
    );
}
