import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {ILedgerAccount, ITransactionListItem} from '@cyberco-nodejs/zipi-typings';

import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {IFinanceState} from '../../../finance/store/finance.reducer';
import {TransactionsService} from 'app/services/api/finance/transactions.service';
import {takeUntil} from 'rxjs/operators';
import {MatSort, Sort, SortDirection} from '@angular/material/sort';

interface IScrollData {
    offset: number;
    limit: number;
    sort_column: string;
    sort_direction: 'asc' | 'desc' | '';
}

@Component({
    selector: 'app-internal-transactions',
    templateUrl: 'internal-transactions.component.html',
    styleUrls: ['../banking.component.scss', './internal-transactions.component.scss']
})
export class InternalTransactionsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator = new MatPaginator(
        new MatPaginatorIntl(),
        ChangeDetectorRef.prototype
    );
    @ViewChild(MatSort, {static: false}) sort: MatSort = new MatSort();

    @Input() sidebar: any;
    @Input() selectedTransaction: ITransactionListItem | null = null;
    @Input() filterType: string | null = null;
    @Input() ledgerAccount: ILedgerAccount | undefined;
    @Output() toggleTransaction: EventEmitter<ITransactionListItem> = new EventEmitter();

    private unsubscribe: Subject<void> = new Subject();

    dataSource: MatTableDataSource<ITransactionListItem>;
    displayedColumns: string[] = [
        'journal_date',
        'reason_type',
        'contact_name',
        'description',
        'status',
        'debit',
        'credit'
    ];

    scrollData: IScrollData = {
        offset: 0,
        limit: 10,
        sort_column: 'journal_date',
        sort_direction: 'desc' as SortDirection
    };

    pageIndex: number = 0;
    listLength: number = 0;
    pageSize: number = 10;
    pageSizeOptions: number[] = [10, 20, 50];

    ledgerAccountId: number | undefined;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private store: Store<IFinanceState>,
        private transactionsService: TransactionsService,
        public dialog: MatDialog
    ) {
        this.dataSource = new MatTableDataSource<ITransactionListItem>([]);
        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['id']) {
                this.ledgerAccountId = +params['id'];
            }
        });
    }

    ngOnInit() {
        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['id']) {
                this.ledgerAccountId = +params['id'];
                // this.ledgerAccountService.getLedgerAccountById(this.ledgerAccountId)
                //     .pipe(takeUntil(this.unsubscribe))
                //     .subscribe(data => {
                //         this.ledgerAccount = data;
                //     });
            }
        });

        this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.pageSize = data.pageSize;
            this.pageIndex = data.pageIndex;

            this.loadTransactions();
        });
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filterType) {
            this.loadInitTransactions();
        }
    }

    loadInitTransactions() {
        this.pageIndex = 0;
        this.loadTransactions();
    }

    reset() {
        this.paginator.pageIndex = 0;
        this.scrollData.offset = 0;
        this.loadTransactions();
    }

    loadTransactions() {
        this.transactionsService
            .getPageTransactionsByLedgerAccountId(this.ledgerAccountId as number, {
                basis: 'both',
                offset: Number(this.pageSize * this.pageIndex),
                limit: Number(this.pageSize),
                type: this.filterType,
                sort_column: this.scrollData.sort_column,
                sort_direction: this.scrollData.sort_direction
            })
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.listLength = data.count;
                this.dataSource.data = data.transactions;
            });
    }

    sortData(sort: Sort) {
        this.scrollData.sort_direction = sort.direction;
        this.scrollData.sort_column = sort.active;

        this.reset();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.toggleTransaction.complete();
    }
}
