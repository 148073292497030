<section class="container-fluid" *ngIf="creditNote">
    <div class="w-100 text-right py-2 d-flex align-items-center">
        <!--        <div>Status: {{creditNote.status | fromSnakeCase}}</div>-->
        <i class="sp-spacer"></i>
        <div
            class="mr-2"
            matTooltip="You cannot modify Credit Note associated with Deal."
            [matTooltipDisabled]="creditNote && !creditNote.source__deal_fk_id"
        >
            <button
                [routerLink]="['edit']"
                mat-raised-button
                [rule]="{sales__manage_customer_credits: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                [disabled]="
                    (creditNote && !!creditNote.source__deal_fk_id) || ['closed', 'void'].includes(creditNote.status)
                "
            >
                Edit
            </button>
        </div>
        <button mat-raised-button type="button" disabled="true">Download PDF</button>
    </div>
    <mat-card class="p-5">
        <div class="credit-status" [ngStyle]="{'background-color': statusColor[creditNote.status]}">
            {{ creditNote.status | fromSnakeCase }}
        </div>

        <header class="row">
            <div class="col-6 zp-company-info">
                <!-- <p>Company Logo</p> -->
                <p class="zp-company-title">{{ creditNote?.creator_company?.title }}</p>
                <p class="text-uppercase">
                    {{ creditNote?.creator_profile?.first_name }} {{ creditNote?.creator_profile?.last_name }}
                </p>
                <!--                <p *ngFor="let location of invoice?.creator_company?.locations">-->
                <!--                    <span *ngIf="location.street_number">{{location.street_number}} </span>-->
                <!--                    <span *ngIf="location.street_address">{{location.street_address}} </span>-->
                <!--                    <span *ngIf="location.unit_number">{{location.unit_number}} </span>-->
                <!--                    <br>-->
                <!--                    <span *ngIf="location.city">{{location.city}}, </span>-->
                <!--                    <span *ngIf="location.state">{{location.state}} </span>-->
                <!--                    <span *ngIf="location.zip">{{location.zip}} </span>-->
                <!--                    <br>-->
                <!--                    <span *ngIf="location.country" class="text-muted">({{location.country}})</span>-->
                <!--                </p>-->
            </div>

            <div class="col-6 text-right">
                <p class="zp-inveice_title">Credit Note</p>
                <p class="zp-invoice-num_value">#{{ creditNote?.credit_note_number }}</p>

                <p class="mb-2">
                    <span>Amount :</span>
                    <span class="zp-w-150">{{ creditNote?.total_amount | currency }}</span>
                </p>
            </div>
        </header>

        <p>Bill To</p>
        <div class="row">
            <div class="col-6">
                <p>
                    <a [routerLink]="['/contacts/', creditNote?.customer_contact?.contact_id]">{{
                        creditNote?.customer_contact?.display_name
                    }}</a>
                </p>
            </div>

            <div class="col-6 text-right">
                <p class="mb-2">
                    <span>Credit Date :</span>
                    <span class="zp-w-150">{{
                        $any(creditNote?.credit_note_date) | dateFromNumber | date: 'longDate'
                    }}</span>
                </p>

                <p class="mb-2 d-flex justify-content-end">
                    <span>Reference:</span>
                    <span class="zp-w-150">{{ creditNote?.reference }}</span>
                </p>
            </div>
        </div>

        <table class="table">
            <thead class="bg-dark text-white">
                <tr>
                    <td scope="col">#</td>
                    <td scope="col">Item</td>
                    <td scope="col">Related Deal</td>
                    <td scope="col">Qty</td>
                    <td scope="col">Rate</td>
                    <td scope="col" class="text-right">Amount</td>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of creditNote?.items; index as idx">
                    <td>{{ idx + 1 }}</td>
                    <td>
                        {{ item?.name }}
                        <i class="text-muted">{{ item?.description }}</i>
                    </td>
                    <td>
                        <a
                            *ngIf="item.related_deal && item.related_deal.id"
                            [routerLink]="['/deals/edit', item.related_deal.id]"
                        >
                            <span *ngIf="!item.related_deal?.address" class="text-muted">No address </span>
                            <span *ngIf="item.related_deal?.address"
                                >{{ item.related_deal?.street_number }} {{ item.related_deal?.address }}
                                {{ item.related_deal?.city }} {{ item.related_deal?.city }},
                                {{ item.related_deal?.state }} {{ item.related_deal?.zip }}
                            </span>
                            — {{ item.related_deal?.type }}/{{ item.related_deal?.status }}
                        </a>
                        <ng-container *ngIf="item.related_deal && !item.related_deal.id">
                            <span *ngIf="!item.related_deal?.address" class="text-muted">No address </span>
                            <span *ngIf="item.related_deal?.address"
                                >{{ item.related_deal?.street_number }}&nbsp;{{ item.related_deal?.address }} &nbsp;{{
                                    item.related_deal?.city
                                }}&nbsp;{{ item.related_deal?.city }},&nbsp;{{ item.related_deal?.state }}&nbsp;{{
                                    item.related_deal?.zip
                                }}
                            </span>
                            — {{ item.related_deal?.type }}/{{ item.related_deal?.status }}
                        </ng-container>
                    </td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.rate | currency }}</td>
                    <td class="text-right">{{ item.amount | currency }}</td>
                </tr>
            </tbody>
        </table>

        <div class="w-100 text-right">
            <p class="mb-2">
                <strong
                    >Total:
                    <span class="zp-total-nember font-weight-bold">{{ creditNote?.total_amount | currency }}</span>
                </strong>
            </p>
            <p class="mb-2">
                <strong class="bg-light py-2">
                    <span class="zp-w-150">&nbsp;</span>
                    Credits Applied:
                    <strong class="zp-total-nember">{{
                        creditNote?.total_amount! - creditNote?.balance! | currency
                    }}</strong>
                </strong>
            </p>
            <p class="mb-2">
                <strong
                    >Balance: <strong class="zp-total-nember">{{ creditNote?.balance | currency }}</strong></strong
                >
            </p>
        </div>

        <div class="my-3">
            Related Records
            <button mat-icon-button (click)="showPayments = !showPayments">
                <mat-icon *ngIf="showPayments">expand_more</mat-icon>
                <mat-icon *ngIf="!showPayments">chevron_right</mat-icon>
            </button>

            <div *ngIf="showPayments && dataSource.data && dataSource.data.length === 0">No related payments.</div>

            <div *ngIf="showPayments && dataSource.data && dataSource.data.length > 0">
                <table mat-table [dataSource]="dataSource" class="w-100">
                    <ng-container matColumnDef="payment_date">
                        <th mat-header-cell *matHeaderCellDef>Payment Date</th>
                        <td mat-cell *matCellDef="let element">
                            {{
                                element.paid_date
                                    ? (element.paid_date | dateFromNumber | date: 'mediumDate')
                                    : (element.created_at | date: 'mediumDate')
                            }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="invoice_id">
                        <th mat-header-cell *matHeaderCellDef>Invoice#</th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.invoice_fk_id && element.invoice && element.invoice.invoice_number">{{
                                element.invoice?.invoice_number || 'N/A'
                            }}</span>
                            <span *ngIf="element.invoice_fk_id && !element.invoice">Invoice has been removed</span>
                            <span *ngIf="!element.invoice_fk_id && !element.invoice">{{ 'N/A' }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef style="text-align: end">Total Amount</th>
                        <td mat-cell *matCellDef="let element" style="text-align: end">
                            {{ element.amount | currency }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedRelatedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedRelatedColumns" class="zp-table-row"></tr>
                </table>
            </div>
        </div>

        <!--        <mat-form-field class="w-100" *ngIf="invoice.type === 'bill'">-->
        <!--				<textarea matInput-->
        <!--                          disabled-->
        <!--                          cdkTextareaAutosize-->
        <!--                          [value]="invoice.invoice_note"-->
        <!--                          placeholder="Additional Info">-->
        <!--                </textarea>-->
        <!--        </mat-form-field>-->
    </mat-card>

    <div class="d-flex justify-content-end py-2">
        <button
            class="mr-2"
            mat-raised-button
            type="button"
            [rule]="{sales__manage_customer_credits: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            *ngIf="isInvoicesToApply && creditNote.status === 'open'"
            (click)="openApplyToInvoicesDialog()"
        >
            Apply to Invoice
        </button>
        <button
            [rule]="{sales__manage_customer_credits: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            *ngIf="creditNote.status === 'draft'"
            mat-raised-button
            (click)="updateStatus('open')"
            class="mr-2"
        >
            Open
        </button>
        <button
            [rule]="{sales__manage_customer_credits: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            *ngIf="creditNote.status === 'draft' || creditNote.status === 'open'"
            mat-raised-button
            (click)="updateStatus('void')"
        >
            Void
        </button>
    </div>
</section>
