<div class="w-100">
    <div class="d-flex justify-content-center">
        <h2>Merchant Setup</h2>
    </div>

    <div class="d-flex justify-content-center">
        <mat-card style="width: 690px">
            <div *ngIf="currentStep === 1">
                <div *ngIf="availableOperatingGateways.length === 0" style="color: red">
                    You do not have any Operating Gateway
                </div>

                <div [formGroup]="connectToForm">
                    <div *ngIf="availableOperatingGateways.length > 1">
                        <mat-form-field class="w-100 px-2">
                            <mat-select [placeholder]="'Select Gateway'" formControlName="selected_gateway_id">
                                <mat-option
                                    *ngFor="let gateway of availableOperatingGateways"
                                    [value]="gateway.customer_ref"
                                >
                                    {{ gateway.title }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div
                        *ngIf="connectToForm.controls.selected_gateway_id.value && availableCloudBanks.length === 0"
                        style="color: red"
                        class="m-1"
                    >
                        Operating Gateway do not contain any Cloud Bank Account
                    </div>

                    <div *ngIf="availableCloudBanks.length > 1">
                        <mat-form-field class="w-100 px-2">
                            <mat-select [placeholder]="'Select Cloud Bank'" formControlName="selected_cloud_bank_id">
                                <mat-option
                                    *ngFor="let method of availableCloudBanks"
                                    [value]="method.payment_method_ref"
                                >
                                    {{ method.title }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="d-flex justify-content-end w-100 mt-2">
                    <button mat-raised-button color="primary" [disabled]="connectToForm.invalid" (click)="continue()">
                        Continue
                    </button>
                </div>
            </div>
            <div *ngIf="currentStep === 2 && onboardingInfo">
                <div class="mb-3">
                    <div class="mb-1">We will use this data to create Merchant Account</div>

                    <div class="d-flex" style="text-align: left">
                        <div class="w-50">Legal Name: {{ onboardingInfo.public_info.legal_name }}</div>
                        <div class="w-50">DBA Name: {{ onboardingInfo.public_info.dba_name }}</div>
                    </div>
                    <div class="d-flex" style="text-align: left">
                        <div class="w-50">Email: {{ onboardingInfo.public_info.email }}</div>
                        <div class="w-50">Phone Number: {{ onboardingInfo.public_info.business_phone_number }}</div>
                    </div>
                </div>
                <div *ngIf="onboardingInfo.problem_fields.length === 0; else elseBlock">
                    All data provided. Gateway can be created.
                </div>
                <ng-template #elseBlock>
                    <div>
                        The following information is missing on your application, please contact support to update:
                    </div>
                    <div *ngFor="let field of onboardingInfo.problem_fields" style="color: red">
                        <ng-container *ngIf="!['business_address_2', 'principle_address_line_2'].includes(field)">
                            {{ field | fromSnakeCase }}
                        </ng-container>
                    </div>
                </ng-template>

                <div class="mt-3" *ngIf="onboardingInfo && onboardingInfo.problem_fields.length === 0">
                    <mat-checkbox [checked]="agree" (change)="agree = !agree" class="policy-check">
                        <span class="w-100 text-wrap policy">
                            I have fully read and agree to the Terms of Use, Platform Agreement, E-Sign Disclosure and
                            Privacy and Cookie Policy set forth at:
                            <a href="https://www.muhnee.com/legal">https://www.muhnee.com/legal</a>
                        </span>
                    </mat-checkbox>
                </div>

                <div class="d-flex justify-content-end w-100 mt-2">
                    <button
                        mat-raised-button
                        color="primary"
                        [disabled]="
                            connectToForm.invalid ||
                            !onboardingInfo ||
                            onboardingInfo.problem_fields.length > 0 ||
                            isButtonLock ||
                            !agree
                        "
                        (click)="createTppGateway()"
                    >
                        Create Gateway
                    </button>
                </div>
            </div>
            <!--               <div class="mt-2">-->
            <!--                   <mat-form-field class="mr-3" style="width: 300px">-->
            <!--                       <input matInput required type="text">-->
            <!--                       <mat-placeholder>Full Legal Name / Company Name</mat-placeholder>-->
            <!--                   </mat-form-field>-->
            <!--               </div>-->
        </mat-card>
    </div>
</div>
