import {Injectable} from '@angular/core';

import {ServiceRequester} from '../../../services/service.requester';
import {Group} from '../../../models/group';
import {FocusAreaFormModel} from '../../goals/common/model';

@Injectable()
export class GroupsApi {
    constructor(public requester: ServiceRequester) {}

    putResendGroupInvitation(memberId: number): Promise<any> {
        return this.requester.makeMsCall('/resend-group-invitation/' + memberId, 'PUT');
    }

    getGroupGoals(company_group: Group): Promise<FocusAreaFormModel> {
        return this.requester
            .makeMsCall('/groups/' + company_group.id + '/goals', 'GET')
            .then((res) => Object.assign(new FocusAreaFormModel(), res));
    }

    putGroupGoals(company_group: Group): Promise<any> {
        return this.requester.makeMsCall('/groups/' + company_group.id + '/goals', 'PUT', company_group);
    }

    putGroupOrder(id: number, order: number) {
        return this.requester.makeMsCall('/groups/' + id + '/order', 'PUT', {order: order});
    }
}
