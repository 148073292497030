import {Component, OnDestroy, OnInit} from '@angular/core';
import {DealService} from '../../../../../../services/deal.service';
import {SessionService} from '../../../../../../services/session.service';
import {Profile} from '../../../../../../models/profile';
import {ProfilesService} from '../../../../../../services/profiles.service';
import {Store} from '@ngrx/store';
import {IDealState} from '../../store/deal.reducer';
import {DealsBoard} from '../../common/deal.models';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-deal-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    public createBoardMode: boolean = false;
    offsetHeight$: Subject<number> = new Subject<number>();
    dealsBoard: DealsBoard | undefined = undefined;

    boardsList: DealsBoard[] = [];
    savedActiveTab: number = 0;

    viewDealMode: boolean = false;

    constructor(
        public sessionService: SessionService,
        public profilesService: ProfilesService,
        public dealService: DealService,
        public store: Store<IDealState>
    ) {}

    ngOnInit() {
        this.dealService.getDealsBoardsList().then((boardsList) => {
            this.boardsList = [...boardsList];

            if (this.boardsList.length === 0) {
                this.createBoardMode = true;
            }
        });
    }

    onDealsBoardCreated(board: DealsBoard) {
        let profiles: Profile[] = [];
        if (this.sessionService.profile!.type === Profile.type.global) {
            profiles = this.sessionService.availableProfiles.filter((profile) => profile.type === Profile.type.default);
        } else {
            profiles.push(this.sessionService.profile!);
        }
        Object.assign(new DealsBoard(), board).setupBackendSettings(profiles);
        this.dealsBoard = board;

        // add new board to others
        this.boardsList.push(board);
        // sort boards alphabetically
        this.boardsList.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        // switch on new tab
        this.savedActiveTab = this.boardsList.findIndex((b) => b.id === board.id);

        this.createBoardMode = false;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.offsetHeight$.complete();
    }
}
