import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GenericFormArray, GenericFormGroup} from '../../../../../entites/generic.entity';
import {CompensationService} from '../../compensation.service';
import {CompensationProfileModel} from '../../models/compensation-profile.model';
import {UntypedFormControl} from '@angular/forms';
import {RbacService} from '../../../../rbac/rbac.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-tab-1-all',
    template: `
        <div style="display: flex; justify-content: space-between; width:100%; margin-bottom: 8px; margin-top: 12px;">
            <button
                mat-raised-button
                [rule]="{company_settings__manage_compensation: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                [matMenuTriggerFor]="menu"
                class="mat-primary"
            >
                Create Profile
                <mat-icon>add</mat-icon>
            </button>

            <mat-menu #menu="matMenu" [class]="'add-item-menu'">
                <button
                    mat-menu-item
                    *ngFor="let type of typesAvailableForCreation"
                    [value]="type"
                    (click)="enterCreationMode(type)"
                >
                    {{ typesLabels[type] }}
                </button>
            </mat-menu>
        </div>
        <div style="margin-bottom: 16px" *ngIf="createMode">
            <app-company-compensation-profile
                [order]="0"
                [editMode]="createMode"
                [createMode]="createMode"
                [compensationForm]="newCompensationProfileFG"
                (save)="doCreate(newCompensationProfileFG.getRawValue())"
                (cancel)="exitCreationMode()"
                (delete)="exitCreationMode()"
            ></app-company-compensation-profile>
        </div>

        <app-company-compensation-profiles-list
            (saved)="update.emit()"
            [includeDrafts]="includeDrafts"
            [sortBy]="sortBy"
            [compensationProfilesList]="compensationProfilesList"
        >
        </app-company-compensation-profiles-list>
    `
})
export class Tab1AllComponent implements OnInit {
    @Input() public compensationProfilesList: GenericFormArray<CompensationProfileModel> =
        new GenericFormArray<CompensationProfileModel>([]);
    @Input() public includeDrafts: UntypedFormControl = new UntypedFormControl(false);
    @Input() public sortBy: UntypedFormControl = new UntypedFormControl('order_desc');

    @Output() update = new EventEmitter();

    typesAvailableForCreation: string[] = [
        CompensationProfileModel.type_set.default,
        CompensationProfileModel.type_set.template
    ];

    typesLabels = {
        [CompensationProfileModel.type_set.default]: 'Dynamic Plan',
        [CompensationProfileModel.type_set.template]: 'Template'
    };

    object_keys(obj: object) {
        return Object.keys(obj);
    }

    public newCompensationProfileFG: GenericFormGroup<CompensationProfileModel> = new GenericFormGroup(
        new CompensationProfileModel()
    );
    public createMode: boolean = false;

    constructor(
        protected compensationService: CompensationService,
        protected rbacService: RbacService,
        public router: Router
    ) {}

    enterCreationMode(type = CompensationProfileModel.type_set.default) {
        this.newCompensationProfileFG.patchValue(new CompensationProfileModel().setType(type));
        this.createMode = true;
    }

    exitCreationMode() {
        this.newCompensationProfileFG.patchValue(new CompensationProfileModel());
        this.createMode = false;
    }

    doCreate(newCompensationProfileData: CompensationProfileModel) {
        const formToAdd = new GenericFormGroup(new CompensationProfileModel());
        formToAdd.patchValue(newCompensationProfileData);
        this.compensationProfilesList.push(formToAdd);
        this.exitCreationMode();
    }

    ngOnInit() {
        this.rbacService.isAllowed({company_settings__manage_compensation: true}).then((can) => {
            if (!can) {
                this.router.navigate(['/company', 'finance', 'products']);
            }
        });
    }
}
