<div class="company-toolbar-container buttons-container w-100">
    <div class="d-flex justify-content-between align-items-center w-100 px-4" style="height: 60px">
        <!------- Header list ------->
        <h3 *ngIf="router.url.includes('journal')" class="mb-0 d-flex align-items-center">Journals</h3>
        <h3 *ngIf="router.url.includes('products')" class="mb-0 d-flex align-items-center">Product/Services</h3>
        <h3 *ngIf="router.url.includes('chartofaccounts')" class="mb-0 d-flex align-items-center">Chart of Accounts</h3>
        <h3 *ngIf="router.url.includes('openingbalances')" class="mb-0 d-flex align-items-center">Opening Balance</h3>

        <div class="d-flex">
            <!------- Button Save Changes ------->
            <div *ngIf="isButtonsShowed && onSaveProfiles">
                <a
                    mat-raised-button
                    class="mat-button"
                    color="warn"
                    *ngIf="!hasChanges"
                    (click)="onSaveProfiles.emit()"
                >
                    Save Changes
                </a>
            </div>

            <!------- Button Create Product (product section) ------->
            <button
                *ngIf="router.url.includes('products')"
                [rule]="{company_settings__manage_products: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                class="ml-3"
                style="height: fit-content"
                type="button"
                mat-raised-button
                routerLink="/company/finance/products/create"
            >
                <mat-icon>add</mat-icon>
                <span>Create</span>
            </button>

            <!------- Journal section ------->
            <ng-container *ngIf="router.url.includes('journal')">
                <!------- For section journals ------->
                <ng-container *ngIf="section === 'journals'">
                    <div
                        class="header-buttons"
                        [rule]="{company_settings__manage_journals: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        <button
                            type="button"
                            mat-raised-button
                            style="border-top-right-radius: 0; border-bottom-right-radius: 0"
                            (click)="openSidebarEmitter.next()"
                        >
                            <mat-icon>add</mat-icon>
                            Create Entry
                        </button>

                        <button
                            mat-button
                            mat-raised-button
                            style="
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                                padding: 0 !important;
                                min-width: initial;
                            "
                            [matMenuTriggerFor]="menu"
                            (click)="$event.stopPropagation(); $event.preventDefault()"
                        >
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>

                        <mat-menu #menu="matMenu">
                            <span class="text-muted m-3" *ngIf="!templates || templates?.length === 0">
                                No Templates
                            </span>
                            <ng-container *ngFor="let template of templates">
                                <button mat-menu-item (click)="openTemplateEmitter.next(template)">
                                    {{ template.title }}
                                </button>
                            </ng-container>
                        </mat-menu>
                    </div>
                </ng-container>

                <!------- For section recurring journals ------->
                <ng-container *ngIf="section === 'recurring-journals'">
                    <button
                        type="button"
                        class="header-buttons"
                        [rule]="{company_settings__manage_journals: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        mat-raised-button
                        style="height: fit-content"
                        (click)="router.navigate(['company/finance/recurringjournals/create'])"
                    >
                        <mat-icon>add</mat-icon> Create Recurring Journal
                    </button>
                </ng-container>

                <!------- For section recurring journals ------->
                <ng-container *ngIf="section === 'journal-templates'">
                    <button
                        type="button"
                        class="header-buttons"
                        [rule]="{company_settings__manage_journals: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        mat-raised-button
                        style="height: fit-content"
                        (click)="router.navigate(['company/finance/journaltemplates/create'])"
                    >
                        <mat-icon>add</mat-icon>
                        Create Template
                    </button>
                </ng-container>
            </ng-container>

            <!------- Chart of Accounts section ------->
            <div *ngIf="router.url.includes('chartofaccounts')" class="d-flex align-items-center">
                <mat-form-field class="mr-3 group-filter" floatLabel="never">
                    <mat-label>Group filter</mat-label>
                    <mat-select [formControl]="divisionModel">
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let group of groupsList" [value]="group.id">
                            {{ group.title }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <button
                    rbac
                    [rule]="{company_settings__manage_ledger_accounts: true}"
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    type="button"
                    mat-raised-button
                    routerLink="/company/finance/chartofaccounts/create"
                >
                    <mat-icon>add</mat-icon>
                    Create
                </button>
            </div>

            <!------- Opening Balances section ------->
            <ng-container *ngIf="router.url.includes('openingbalances')">
                <div class="mr-3 d-flex align-items-center" style="height: 40px">
                    <div class="row-inner" *ngIf="openingBalanceDate">
                        Last Modified Date : {{ openingBalanceDate | dateFromNumber | amDateFormat: 'MM/DD/YY' }}
                    </div>
                </div>
                <div>
                    <button
                        type="button"
                        mat-raised-button
                        rbac
                        [rule]="{company_settings__manage_opening_balances: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        routerLink="/company/finance/openingbalances/create"
                    >
                        <mat-icon *ngIf="openingBalanceDate">edit</mat-icon>
                        <span *ngIf="openingBalanceDate">Edit</span>
                        <span *ngIf="!openingBalanceDate">Create</span>
                    </button>
                    <button
                        type="button"
                        mat-raised-button
                        color="warn"
                        class="ml-3"
                        *ngIf="openingBalanceDate"
                        rbac
                        [rule]="{company_settings__manage_opening_balances: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        (click)="deleteOpeningBalancesEmitter.next()"
                    >
                        <mat-icon>close</mat-icon>
                        <span>Delete</span>
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</div>
