<div *ngIf="data.invoiceId">
    <div class="w-100 text-right">
        <button mat-mini-fab aria-label="Close" (click)="dialogRef.close()" color="primary">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div *ngIf="!isEditMode">
        <app-invoice-page (needSwitchToEditInDialog)="toggleMode()" [invoiceId]="data.invoiceId" [dialogMode]="true">
        </app-invoice-page>
    </div>
    <div *ngIf="isEditMode">
        <app-edit-invoice-page
            (invoiceUpdatedInDialog)="toggleMode()"
            [dialogInvoiceId]="data.invoiceId"
            [dialogMode]="true"
        >
        </app-edit-invoice-page>
    </div>
</div>
<div *ngIf="data.billId">
    <div class="w-100 text-right">
        <button mat-mini-fab aria-label="Close" (click)="dialogRef.close()" color="primary">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <app-bill-page [billId]="data.billId" [dialogMode]="true"> </app-bill-page>
</div>
