<section class="sidebar-page">
    <app-company-inner-nav></app-company-inner-nav>

    <!------- List of payments ------->
    <div class="table-background">
        <table mat-table [dataSource]="dataSource" [ngClass]="{'d-none': dataSource.data.length === 0}" class="w-100">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="w-25">Product Name</th>
                <td mat-cell *matCellDef="let element" class="w-25">
                    {{ element.name }}
                    <span *ngIf="element.status === 'archived'" class="font-weight-bold">(Archived)</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef class="w-15">Product Price</th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1">
                    {{ element.price | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef class="w-35">Product Description</th>
                <td mat-cell *matCellDef="let element" class="w-35 px-1">
                    {{ element.description }}
                </td>
            </ng-container>

            <ng-container matColumnDef="ledger_account_name">
                <th mat-header-cell *matHeaderCellDef class="w-20">Ledger Account Name</th>
                <td mat-cell *matCellDef="let element" class="w-20 px-1">
                    {{ getLedgerAccountName(element.ledger_account_fk_id) }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="w-5">Action</th>
                <td mat-cell *matCellDef="let element" class="w-5">
                    <button
                        mat-icon-button
                        [rule]="{company_settings__manage_products: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        [matMenuTriggerFor]="menu"
                        (click)="$event.stopPropagation()"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button
                            mat-menu-item
                            [rule]="{company_settings__manage_products: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            (click)="
                                $event.stopPropagation(); router.navigate(['company/finance/products', element.id])
                            "
                        >
                            Edit
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="zp-table-row"></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator class="custom-paginator" [length]="scrollData.total" [pageSize]="scrollData.total">
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 no-items">
            There are no Products/Services.
        </div>
    </div>
</section>
