<div>
    <div *ngIf="reportData.data?.length" class="tables">
        <table class="mat-table w-100">
            <thead>
                <tr class="mat-header-row">
                    <th class="mat-header-cell sticky">Contact</th>
                    <th class="mat-header-cell sticky">Deal</th>
                    <th *ngFor="let product of reportData.products" class="mat-header-cell sticky">
                        {{ product.name }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let deal of reportData.data" class="mat-row">
                    <td class="mat-cell">{{ deal.contact_name }}</td>
                    <td class="mat-cell">
                        <a [routerLink]="['/deals', deal.deal_id]">{{ deal.deal_name }}</a>
                    </td>
                    <td *ngFor="let prod of deal.prods" class="mat-cell">
                        {{ prod.amount ? (prod.amount | reportFormatted: 'currency') : '-' }}
                    </td>
                </tr>
                <tr class="mat-row total">
                    <td class="mat-cell"></td>
                    <td class="mat-cell">Total Value</td>
                    <td *ngFor="let item of reportData.total" class="mat-cell">
                        {{ item.amount ? (item.amount | reportFormatted: 'currency') : '-' }}
                    </td>
                </tr>
                <tr class="mat-row total">
                    <td class="mat-cell"></td>
                    <td class="mat-cell">Deal Count</td>
                    <td *ngFor="let item of reportData.dealCount" class="mat-cell">
                        {{ item.count ? item.count : '-' }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <mat-card class="preview-body" *ngIf="!reportData.data?.length">
        <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
    </mat-card>
</div>
