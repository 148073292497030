import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {GenericFormArray, GenericFormGroup} from '../../../../../../entites/generic.entity';
import {FinancialTransferEntity} from '../../../../../account-info/compensation/models/financial-transfer.entity';
import {SalesEntity} from '../../../../../../models/sales-entity';
import {FinancialElementModel} from '../../../../../account-info/compensation/models/financial-element.model';
import {InvoiceBillModalDialogComponent} from '../../../../../finance/components/invoice-bill-modal-dialog/invoice-bill-modal-dialog.component';

@Component({
    selector: 'app-calculation-additional-incomes',
    styles: [
        `
            .association-item {
                padding: 0 3px;
                display: flex;
                justify-content: space-between;
            }
            .association-item:hover {
                background-color: #f1f1f1;
            }
            .association-item mat-icon {
                cursor: pointer;
            }
            .association-item div.label {
                display: table;
            }
            .association-item div.label span {
                display: table-cell; /* that is trick for vertical align to middle */
                vertical-align: middle;
            }
        `
    ],
    template: `
        <div class="option-item">
            <h3
                class="option-title"
                *ngIf="
                    salesEntityFG.controls.role && salesEntityFG.controls.role.value !== SALES_ENTITY.role_SET.referral
                "
            >
                Income
            </h3>
            <app-deal-transfers-collection
                [origin]="FINANCIAL_ELEMENT_ENTITY.type_set.disbursement_template"
                buttonLabel="Add income"
                [allowPercents]="true"
                [showOnlyAmount]="false"
                [availableValueTypes]="availableValueTypes"
                [transfersFA]="financialNodesFA"
                [disabled]="disabled"
                [type]="FINANCIAL_TRANSFER_ENTITY.type_SET.transfer"
                (addItem)="addIncome.emit($event)"
                (deleteItem)="removeTransfer.emit($event)"
            ></app-deal-transfers-collection>

            <div
                class="association-item"
                *ngFor="let associatedTransfer of salesEntityFG.controls.association_incomes?.controls"
            >
                <div class="label">
                    <span>
                        {{ associatedTransfer.controls.amount.value | currency }} has been associated to this deal for
                        reporting purposes, reference "{{ associatedTransfer.controls.label.value }}" on
                        <a
                            href="javascript:"
                            (click)="openViewDialog(associatedTransfer.controls.connected__bill_fk_id?.value, 'bill')"
                            matTooltip="View Bill"
                            >{{ associatedTransfer.controls.connected_bill?.value?.bill_number }}</a
                        >
                        ({{ associatedTransfer.controls.connected_bill?.value?.summary_status | fromSnakeCase }})
                    </span>
                </div>

                <mat-icon *ngIf="!salesEntityFG.disabled" color="warn" (click)="removeTransfer.emit(associatedTransfer)"
                    >clear</mat-icon
                >
            </div>
        </div>
    `
})
export class CalculationAdditionalIncomesComponent implements OnDestroy {
    FINANCIAL_TRANSFER_ENTITY = FinancialTransferEntity;
    FINANCIAL_ELEMENT_ENTITY = FinancialElementModel;
    SALES_ENTITY = SalesEntity;
    @Input() availableValueTypes = ['percent', 'flat_fee', 'flat_fee_sales_net'];
    @Input() salesEntityFG: GenericFormGroup<SalesEntity> = new GenericFormGroup(new SalesEntity());
    @Input() financialNodesFA: GenericFormArray<FinancialTransferEntity> = new GenericFormArray([]);
    @Input() disabled: boolean = false;
    @Output() addIncome = new EventEmitter<GenericFormGroup<FinancialTransferEntity>>();
    @Output() removeTransfer = new EventEmitter<GenericFormGroup<FinancialTransferEntity>>();

    constructor(protected dialog: MatDialog) {}

    openViewDialog(entityId: number, type: string) {
        if (!['invoice', 'bill'].includes(type)) {
            return;
        }

        const data: {invoiceId?: number; billId?: number} = {};
        if (type === 'invoice') {
            data.invoiceId = entityId;
        }
        if (type === 'bill') {
            data.billId = entityId;
        }
        const dialogRef = this.dialog.open(InvoiceBillModalDialogComponent, {
            position: {
                top: '10px',
                right: '10px'
            },
            height: '98%',
            width: '100vw',
            maxWidth: '98vw',
            panelClass: 'full-screen-modal',
            data: data
        });
    }

    ngOnDestroy() {
        this.addIncome.complete();
        this.removeTransfer.complete();
    }
}
