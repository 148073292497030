<div [style]="{width: recordPaymentSettingsGroup.controls.payment_mode.value === 'check' ? '1272px' : '917px'}">
    <!--Content Container-->
    <div style="padding: 24px 24px 0 24px">
        <!--Header-->
        <div class="d-flex">
            <h2>Create Check</h2>
            <span class="sp-spacer"></span>
            <mat-icon class="sp-cursor-pointer" (click)="close()">close</mat-icon>
        </div>

        <!--Payment Settings-->
        <div class="d-flex" [formGroup]="recordPaymentSettingsGroup">
            <div class="w-50 mr-2">
                <app-date-picker
                    [placeholder]="'Payment Date'"
                    [dateControl]="recordPaymentSettingsGroup.controls.paid_date"
                >
                </app-date-picker>
            </div>

            <app-ledger-account-selector
                #ledgerAccountPicker
                class="w-50"
                [ledgerAccountControl]="recordPaymentSettingsGroup.controls.ledger_account_id"
                [placeholder]="'Paid Through'"
            >
            </app-ledger-account-selector>
        </div>

        <!--Payouts List-->
        <div>
            <!--Table Header-->
            <div class="table-header">
                <div class="w-25">Payment To</div>
                <div class="w-20">Amount Paid</div>
                <div class="w-20">Remaining Amount</div>
                <div class="w-30">Memo</div>
            </div>

            <!--Items Section-->
            <div style="height: 400px; max-height: 45vh; overflow-y: auto">
                <div *ngIf="checkFormControls && checkFormControls.length > 0 && itemsArrayBuilt">
                    <!--Item-->
                    <div *ngFor="let payoutGroup of checkFormControls.controls; index as idx">
                        <div [formGroup]="payoutGroup" class="list-item">
                            <div class="w-25">{{ payoutGroup.controls.pay_to_name.value }}</div>

                            <div class="w-20">
                                <div class="amount-label d-flex p-0">
                                    <mat-form-field class="w-80 mr-2">
                                        <input
                                            matInput
                                            formControlName="amount"
                                            [maskito]="currencyMaskitoMask"
                                            (keyup)="validateAmount($event, payoutGroup)"
                                        />
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="w-20">
                                <span>{{ payoutGroup.controls.bill_pending_balance.value | currency }}</span>
                            </div>

                            <div class="w-30">
                                <mat-form-field class="w-90">
                                    <input matInput autocomplete="off" type="search" formControlName="reference" />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!itemsArrayBuilt" class="w-100 text-center mt-2">Data Loading...</div>
                <div
                    *ngIf="!checkFormControls || (checkFormControls.length === 0 && itemsArrayBuilt)"
                    class="w-100 text-center mt-2"
                >
                    Nothing to Display
                </div>
            </div>
        </div>
    </div>
</div>

<div>
    <div class="action-section">
        <!-- TODO: implement combining payments for Bills -->
        <!-- <div class="d-flex w-50 mr-2" [formGroup]="recordPaymentSettingsGroup" *ngIf="!isCombineFeatureDisabled">
            <mat-slide-toggle formControlName="is_need_to_combine_payments" (change)="setupItemsArray()"
                >Combine Payments</mat-slide-toggle
            >
        </div> -->
        <span class="sp-spacer"></span>
        <button mat-button color="primary" class="ml-2" (click)="dialogRef.close(false)" tabindex="-1">Cancel</button>

        <button mat-stroked-button color="primary" class="ml-2" (click)="create('print')" tabindex="-1">
            Create & Print
        </button>

        <button
            mat-raised-button
            class="ml-1"
            [disabled]="!itemsArrayBuilt || !checkFormControls || (checkFormControls.length === 0 && itemsArrayBuilt)"
            color="primary"
            (click)="create()"
            tabindex="1"
        >
            Create
        </button>
    </div>
</div>
