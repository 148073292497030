<h5 mat-dialog-title>{{data.title}}</h5>
<div mat-dialog-content>
    <p style="white-space: pre-line">{{data.text}}</p>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{data.cancel ? data.cancel : 'No'}}</button>
    <button mat-button class="ml-2" (click)="onYesClick()" cdkFocusInitial>
        {{data.confirmation ? data.confirmation : 'Yes'}}
    </button>
</div>
