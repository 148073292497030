import {Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SalesEntity} from '../../../../../../models/sales-entity';
import {BehaviorSubject, Subject} from 'rxjs';
import {GenericFormArray, GenericFormGroup} from '../../../../../../entites/generic.entity';
import {ChipNode} from '../../../../../account-info/compensation/models/chip-node';
import {skip, takeUntil} from 'rxjs/operators';
import {ContactPartLink} from '../../../../../../models/contact-part-link';
import {EntityPickerAdvancedComponent} from '../../../../../../components/entity-picker-advanced/entity-picker-advanced.component';
import {FinancialTransferEntity} from '../../../../../account-info/compensation/models/financial-transfer.entity';
import {DealProcessingService} from '../../deal-processing.service';
import {IChipNode} from '@cyberco-nodejs/zipi-typings';
import {RbacService} from '../../../../../rbac/rbac.service';
import {CurrentProfileSource} from '../../../../../../services/sources/current-profile.source';
import {numberWithNegativeMaskitoOptions} from '../../../../../../utilities/maskito';

interface ICustomEntity {
    role: 'agent' | 'transfer' | 'referral' | string;
    label: string | null;
    target_id: number;
    percent: number;
    primary: boolean;
    sales_entity_type: string;
    added_automatic: boolean;
    financialTransferFG: GenericFormGroup<FinancialTransferEntity> | null;
    salesEntityFD: GenericFormGroup<SalesEntity> | null;
    sales_volume_percent: number;
    side_count: number;
}

@Component({
    selector: 'app-manage-sales-entities-dialog',
    templateUrl: 'manage-sales-entities-dialog.component.html',
    styles: [
        `
            .dialog-container {
                width: 85vh;
            }
            .mat-autocomplete-panel {
                min-width: fit-content;
            }
            .role-icon {
                cursor: pointer;
                color: grey;
            }
            .d-grid {
                display: grid;
                grid-template-columns: 5% 30% 60% 5%;
                align-items: center;
            }
            .d-grid-detailed {
                display: grid;
                grid-template-columns: 34% 33% 33%;
                align-items: center;
            }
            .grid-header {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .justify-grid-center {
                justify-self: center;
            }
            .sales-entity-input {
                text-align: right;
            }
            .warning-block {
                color: #ff6000;
                background-color: #f5e8c1;
                border: solid 1px #ff6000;
            }
            .fake-input {
                padding-bottom: 0.3rem;
                border-bottom: 1px solid #0000006b;
                margin-top: 0.7rem;
                text-align: end;
            }
        `
    ]
})
export class ManageSalesEntitiesDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('entityPicker') entityPicker: EntityPickerAdvancedComponent | undefined = undefined;
    @ViewChildren('dealSplitInputs') dealSplitInputs: QueryList<ElementRef> | undefined;
    @ViewChildren('salesVolumeInputs') salesVolumeInputs: QueryList<ElementRef> | undefined;
    @ViewChildren('sideCountInputs') sideCountInputs: QueryList<ElementRef> | undefined;

    initialSalesEntities: GenericFormGroup<SalesEntity>[] = [];
    updatedCustomEntities: ICustomEntity[] = [];
    deletedEntities: ICustomEntity[] = [];

    selectedSalesForm: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    initialProfileIds: number[] = [];
    initialGroupIds: number[] = [];
    initialContactIds: number[] = [];

    public FINANCIAL_TRANSFER_ENTITY = FinancialTransferEntity;
    public SALES_ENTITY = SalesEntity;
    disableButtons: boolean = false;
    numberMask = numberWithNegativeMaskitoOptions;

    checkPermissions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    hideSideCountInput: boolean[] = [];

    constructor(
        public dialogRef: MatDialogRef<ManageSalesEntitiesDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            salesEntities: GenericFormGroup<SalesEntity>[];
            showSideCountAndSalesVolumeFlag: boolean;
        },
        public dealProcessing: DealProcessingService,
        protected rbacService: RbacService,
        protected currentProfileSource: CurrentProfileSource
    ) {
        this.initialSalesEntities = [...this.data.salesEntities];
        this.updatedCustomEntities = this.initialSalesEntities.map((se: GenericFormGroup<SalesEntity>) => {
            // set label
            let label: string | null = null;
            if (
                typeof se.controls?.type === 'undefined' ||
                typeof se.controls?.is_primary === 'undefined' ||
                typeof se.controls?.role === 'undefined' ||
                typeof se.controls?.contact_part_link === 'undefined' ||
                typeof se.controls?.contact_part_link.controls.contact_id === 'undefined' ||
                typeof se.controls?.added_automatic === 'undefined'
            ) {
                throw new Error('error');
            }

            label = se.getRawValue(this.SALES_ENTITY).contact.display_name;
            if (
                se.controls?.type.value === this.SALES_ENTITY.type_SET.profile &&
                !se.controls?.is_primary.value &&
                this.hasReferralTransfers(se)
            ) {
                label += ' (REFERRAL)';
            }
            if (
                (se.controls?.type.value === this.SALES_ENTITY.type_SET.profile &&
                    se.getRawValue().profile?.deleted_at) ||
                (se.controls?.type.value === this.SALES_ENTITY.type_SET.company_group &&
                    se.getRawValue().company_group?.deleted_at) ||
                (se.controls?.type.value === this.SALES_ENTITY.type_SET.contact && se.getRawValue().contact?.deleted_at)
            ) {
                label += ' (deleted)';
            }
            // set financial transfer
            let financialTransferFG: GenericFormGroup<FinancialTransferEntity> | null = null;
            if (se.controls?.role.value === this.SALES_ENTITY.role_SET.agent) {
                financialTransferFG =
                    this.dealProcessing.dealFG.controls.financial_transfers?.controls
                        .filter((ft) => ft.controls?.type?.value === FinancialTransferEntity.type_SET.participant_split)
                        .find(
                            (ft) =>
                                // @ts-ignore
                                ft.controls?.receiver?.controls?.contact_id?.value ===
                                se.controls?.contact_part_link?.controls?.contact_id?.value
                        ) || null;
            }

            this.hideSideCountInput.push(true);

            return {
                role: se.controls?.role.value as string,
                label: label,
                target_id:
                    se.getRawValue().profile?.id ||
                    se.getRawValue().company_group?.id ||
                    (se.getRawValue().contact?.id as number),
                percent: (se.controls?.participant_split?.controls?.percent?.value as number) || 0,
                primary: se.controls?.is_primary.value as boolean,
                sales_entity_type: se.controls?.type.value,
                added_automatic: se.controls?.added_automatic.value as boolean,
                financialTransferFG: financialTransferFG,
                salesEntityFD: se,
                sales_volume_percent: se.controls?.sales_volume_percent?.value
                    ? se.controls?.sales_volume_percent?.value
                    : se.controls?.is_primary.value
                      ? 100
                      : 0,
                side_count: se.controls?.side_count?.value
                    ? se.controls?.side_count?.value
                    : se.controls?.is_primary.value
                      ? 1
                      : 0
            };
        });

        this.initialGroupIds = this.data.salesEntities
            .filter((entity) => entity.controls?.type?.value === 'company_group')
            .reduce((acc, curr) => {
                acc.push(curr.controls?.company_group?.controls?.id?.value);
                return acc;
            }, [] as number[]);
        this.initialProfileIds = this.data.salesEntities
            .filter((entity) => entity.controls?.type?.value === 'profile')
            .reduce((acc, curr) => {
                acc.push(curr.controls?.profile?.controls?.id?.value);
                return acc;
            }, [] as number[]);
        this.initialContactIds = this.data.salesEntities
            .filter((entity) => entity.controls?.type?.value === 'contact')
            .reduce((acc, curr) => {
                acc.push(curr.controls?.contact?.controls?.id?.value);
                return acc;
            }, [] as number[]);

        this.checkPermissions.pipe(skip(1), takeUntil(this.unsubscribe)).subscribe((value) => {
            if (!value) {
                this.dialogRef.close(true);
            }
        });

        // check permissions with help of current profile
        this.currentProfileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe((currentProfile) => {
            const isPrimarySalesEntity = this.initialSalesEntities.find(
                (se) =>
                    se.controls.is_primary?.value &&
                    ((se.controls.type?.value === SalesEntity.type_SET.company_group &&
                        se.controls.company_group?.controls.id?.value === currentProfile?.company_group?.id) ||
                        (se.controls.type?.value === SalesEntity.type_SET.profile &&
                            se.controls.profile?.controls.id?.value === currentProfile?.id))
            );

            Promise.all([
                this.rbacService.isAllowed({deals__edit_own: true}),
                this.rbacService.isAllowed({deals__edit_any: true}),
                this.rbacService.isAllowed({deals__create_own: true}),
                this.rbacService.isAllowed({deals__create_any: true})
            ]).then((result) => {
                const [canEditOwnDeals, canEditAnyDeals, canCreateOwnDeals, canCreateAnyDeals] = result;
                if (
                    canEditAnyDeals ||
                    (isPrimarySalesEntity && canEditOwnDeals) ||
                    canCreateAnyDeals ||
                    (isPrimarySalesEntity && canCreateOwnDeals)
                ) {
                    this.checkPermissions.next(true);
                } else {
                    this.checkPermissions.next(false);
                }
            });
        });
    }

    ngOnInit() {
        this.selectedSalesForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((chipNodes: IChipNode[]) => {
            // add entity and create financial transfer for it
            const added = chipNodes.find(
                (n) => !this.updatedCustomEntities.map((i) => i.target_id).includes(n.target_id as number)
            );
            if (!added) {
                return;
            }

            const contactPartLink = new ContactPartLink()
                .setContactId(added.contact_id as number)
                .setLinkTitle(added.contact_name);
            const primaryExists = this.updatedCustomEntities.find((e) => e.primary);
            let financialTransferFG;
            if (primaryExists) {
                financialTransferFG = this.dealProcessing.createNewFinancialTransfer(contactPartLink, 0);
            } else {
                financialTransferFG = this.dealProcessing.createNewFinancialTransfer(contactPartLink, 100);
            }

            this.updatedCustomEntities.push({
                role: 'agent',
                label: added.label,
                target_id: added.target_id as number,
                percent: primaryExists ? 0 : 100,
                primary: !primaryExists,
                sales_entity_type:
                    added.type === ChipNode.type_SET.individual ? this.SALES_ENTITY.type_SET.profile : added.type,
                added_automatic: false,
                financialTransferFG: financialTransferFG,
                salesEntityFD: null,
                sales_volume_percent: primaryExists ? 0 : 100,
                side_count: primaryExists ? 0 : 1
            });
        });
    }

    save() {
        if (
            this.validateSum() ||
            this.validateNegativeNumbers() ||
            this.validateSalesVolumeSum() ||
            this.validateSideCountSum()
        ) {
            return;
        }
        this.disableButtons = true;

        this.updatedCustomEntities.forEach((se, idx) => {
            // replace percentage in real financial transfers
            if (se.role === this.SALES_ENTITY.role_SET.agent) {
                se?.financialTransferFG?.controls?.percent?.setValue(this.updatedCustomEntities[idx].percent);
            }
            // find agent transforming into transfer, by having participant_split
            if (
                se.role === this.SALES_ENTITY.role_SET.transfer &&
                se?.salesEntityFD?.controls?.participant_split?.controls?.id?.value
            ) {
                // delete transfer
                const deletingFT = this.dealProcessing.dealFG.controls.financial_transfers?.controls?.find(
                    (ft: GenericFormGroup<FinancialTransferEntity>) =>
                        se.salesEntityFD &&
                        ft.controls?.id?.value === se.salesEntityFD.controls?.participant_split?.controls?.id?.value
                );
                if (typeof deletingFT !== 'undefined') {
                    this.dealProcessing.deleteFinancialTransfer(deletingFT);
                } else {
                    throw new Error('Deleting financial transfer should be found');
                }
            }
        });

        // add
        // if in deal-processing contacts list is empty - here will be strange errors
        if (this.updatedCustomEntities.length) {
            for (const entity of this.updatedCustomEntities.filter(
                (i) => i.role === this.SALES_ENTITY.role_SET.agent
            )) {
                if (entity.financialTransferFG && !entity.financialTransferFG.controls.id!.value) {
                    this.dealProcessing.addFinancialTransfer(entity.financialTransferFG);
                }
            }
        }
        // update primary sales entity, if needed
        this.checkPrimarySalesEntity();

        // update additional inputs
        this.updatedCustomEntities.forEach((se, idx) => {
            if (se.role === this.SALES_ENTITY.role_SET.agent) {
                const currentSE = this.dealProcessing.dealFG.controls.sales_entities?.controls.find(
                    (ise) =>
                        (ise.controls.type?.value === SalesEntity.type_SET.profile &&
                            ise.controls.profile?.controls.id?.value === se.target_id) ||
                        (ise.controls.type?.value === SalesEntity.type_SET.company_group &&
                            ise.controls.company_group?.controls.id?.value === se.target_id)
                );
                if (currentSE) {
                    // update side count
                    currentSE.controls.side_count?.patchValue(se.side_count);
                    // update sales volume
                    currentSE.controls.sales_volume_percent?.patchValue(se.sales_volume_percent);
                }
            }
        });

        // remove
        for (const entity of this.deletedEntities) {
            // user may remove just added entity
            if (entity.salesEntityFD) {
                // check if it exists duplicated Sales Entities
                const countDuplicates = this.dealProcessing.dealFG.controls.sales_entities?.controls.filter(
                    (i: GenericFormGroup<SalesEntity>) => this.findCompared(i, entity)
                )?.length;
                if (typeof countDuplicates === 'number' && countDuplicates === 1) {
                    this.dealProcessing.removeAllTransfersForSalesEntity(entity.salesEntityFD.getRawValue());
                }

                const idx = this.dealProcessing.dealFG.controls.sales_entities?.controls.findIndex(
                    (i: GenericFormGroup<SalesEntity>) => this.findCompared(i, entity)
                );
                if (typeof idx === 'number' && idx > -1) {
                    this.dealProcessing.dealFG?.controls.sales_entities?.removeAt(idx);
                }
            }
        }

        // do apply-splits
        // TODO: process error - now errors during processing applySplit aren't showing
        this.dealProcessing.doRefresh({});

        // wait for finishing applySplit
        this.dealProcessing.isDealReadyForEditing.pipe(takeUntil(this.unsubscribe)).subscribe((finished) => {
            if (finished) {
                this.dialogRef.close(true);
            }
        });
    }

    validateSum(): boolean {
        const amount = this.updatedCustomEntities.reduce((acc, cur, idx) => {
            return acc + (cur.role === this.SALES_ENTITY.role_SET.agent ? Number(cur.percent?.toFixed(2)) : 0);
        }, 0);
        return Number(amount.toFixed(2)) !== 100;
    }
    validateNegativeNumbers(): boolean {
        // side count values and sales volume values should be positive
        return !this.updatedCustomEntities.every(
            (e) => e.percent >= 0 && e.sales_volume_percent >= 0 && e.side_count >= 0
        );
    }
    validateSalesVolumeSum(): boolean {
        const amount = this.updatedCustomEntities.reduce((acc, cur, idx) => {
            return (
                acc + (cur.role === this.SALES_ENTITY.role_SET.agent ? Number(cur.sales_volume_percent?.toFixed(2)) : 0)
            );
        }, 0);
        return Number(amount.toFixed(2)) !== 100;
    }
    validateSideCountSum(): boolean {
        const amount = this.updatedCustomEntities.reduce((acc, cur, idx) => {
            return acc + (cur.role === this.SALES_ENTITY.role_SET.agent ? Number(cur.side_count?.toFixed(2)) : 0);
        }, 0);
        return Number(amount.toFixed(2)) !== 1;
    }

    removeOptionFromArray(index: number) {
        this.deletedEntities.push(this.updatedCustomEntities[index]);
        const pickerIndex = this.selectedSalesForm.controls.findIndex(
            (chip) => chip.controls.target_id?.value === this.updatedCustomEntities[index].target_id
        );
        if (pickerIndex > -1 && typeof this.entityPicker !== 'undefined') {
            this.entityPicker.nodesFA.removeAt(pickerIndex);
        }
        this.updatedCustomEntities.splice(index, 1);
        this.recalculateSumPrimaryAgent();
        this.recalculateSumSalesVolume();
        this.recalculateSumSideCount();
    }

    recalculateSumPrimaryAgent(i: number | undefined = undefined, event: any | undefined = undefined) {
        // set new number to proper agent
        if (typeof i !== 'undefined' && typeof event !== 'undefined' && event !== null && this.dealSplitInputs) {
            if (event.target.value === '0._') {
                this.updatedCustomEntities[i].percent = 0;
                this.dealSplitInputs.toArray()[i].nativeElement.selectionStart = 2;
                this.dealSplitInputs.toArray()[i].nativeElement.selectionEnd = 2;
            } else {
                this.updatedCustomEntities[i].percent = Number(event.target.value);
            }
        }
        // find primary agent
        let primaryAgentIndex = this.updatedCustomEntities.findIndex((se) => se.primary);
        if (primaryAgentIndex === -1) {
            primaryAgentIndex = 0;
        }
        // sum of all non-primary agents
        const amount = this.updatedCustomEntities.reduce((acc, cur, idx) => {
            return (
                acc +
                (cur.role === this.SALES_ENTITY.role_SET.agent && idx !== primaryAgentIndex
                    ? Number(cur.percent?.toFixed(2))
                    : 0)
            );
        }, 0);
        // update number of primary agent
        this.updatedCustomEntities[primaryAgentIndex].percent = Number((100 - amount).toFixed(2));
    }
    recalculateSumSalesVolume(i: number | undefined = undefined, event: any | undefined = undefined) {
        // set new number to proper agent
        if (typeof i !== 'undefined' && typeof event !== 'undefined' && event !== null && this.salesVolumeInputs) {
            if (event.target.value === '0._') {
                this.updatedCustomEntities[i].sales_volume_percent = 0;
                this.salesVolumeInputs.toArray()[i].nativeElement.selectionStart = 2;
                this.salesVolumeInputs.toArray()[i].nativeElement.selectionEnd = 2;
            } else {
                this.updatedCustomEntities[i].sales_volume_percent = Number(event.target.value);
            }
        }
        // find primary agent
        let primaryAgentIndex = this.updatedCustomEntities.findIndex((se) => se.primary);
        if (primaryAgentIndex === -1) {
            primaryAgentIndex = 0;
        }
        // sum of all non-primary agents
        const amount = this.updatedCustomEntities.reduce((acc, cur, idx) => {
            return (
                acc +
                (cur.role === this.SALES_ENTITY.role_SET.agent && idx !== primaryAgentIndex
                    ? Number(cur.sales_volume_percent?.toFixed(2))
                    : 0)
            );
        }, 0);
        // update number of primary agent
        this.updatedCustomEntities[primaryAgentIndex].sales_volume_percent = Number((100 - amount).toFixed(2));
    }
    recalculateSumSideCount(i: number | undefined = undefined, event: any | undefined = undefined) {
        // set new number to proper agent
        if (typeof i !== 'undefined' && typeof event !== 'undefined' && event !== null && this.sideCountInputs) {
            if (event.target.value === '0._') {
                this.updatedCustomEntities[i].side_count = 0;
                this.sideCountInputs.toArray()[i].nativeElement.selectionStart = 2;
                this.sideCountInputs.toArray()[i].nativeElement.selectionEnd = 2;
            } else {
                this.updatedCustomEntities[i].side_count = Number(event.target.value);
            }
        }
        // find primary agent
        let primaryAgentIndex = this.updatedCustomEntities.findIndex((se) => se.primary);
        if (primaryAgentIndex === -1) {
            primaryAgentIndex = 0;
        }
        // sum of all non-primary agents
        const amount = this.updatedCustomEntities.reduce((acc, cur, idx) => {
            return (
                acc +
                (cur.role === this.SALES_ENTITY.role_SET.agent && idx !== primaryAgentIndex
                    ? Number(cur.side_count?.toFixed(2))
                    : 0)
            );
        }, 0);
        // update number of primary agent
        this.updatedCustomEntities[primaryAgentIndex].side_count = Number((1 - amount).toFixed(2));
    }

    doMakePrimary(index: number) {
        this.updatedCustomEntities.forEach((ft, idx) => {
            // remove primary from previous owner
            if (ft.primary) {
                ft.primary = false;
            }
            // add primary to selected agent
            if (idx === index) {
                ft.primary = true;
            }
        });
    }

    doMakeAgent(index: number) {
        const added = this.selectedSalesForm.controls.find(
            (chip) => chip.controls?.target_id?.value === this.updatedCustomEntities[index].target_id
        );
        if (typeof added === 'undefined') {
            throw new Error("Person you tried to add is deleted or doesn't exist");
        }

        const financialTransferFG = this.dealProcessing.createNewFinancialTransfer(
            new ContactPartLink()
                .setContactId(added.controls.contact_id?.value)
                .setLinkTitle(added.controls.contact_name?.value),
            0
        );

        this.updatedCustomEntities[index].role = 'agent';
        this.updatedCustomEntities[index].percent = 0;
        this.updatedCustomEntities[index].financialTransferFG = financialTransferFG;
    }

    checkPrimarySalesEntity() {
        const newPrimary = this.updatedCustomEntities.find((ft) => ft.primary);
        const oldPrimaryEntity = this.dealProcessing.dealFG.controls.sales_entities?.controls.find(
            (se) => se.controls?.is_primary?.value
        );
        if (!newPrimary || typeof oldPrimaryEntity === 'undefined') {
            throw new Error('Primary entity should be defined');
        }

        const currentPrimaryEntityForUpdate = this.dealProcessing.dealFG.controls.sales_entities?.controls.find(
            (se: GenericFormGroup<SalesEntity>) =>
                se.controls?.profile?.controls?.id?.value === newPrimary.target_id ||
                se.controls?.company_group?.controls?.id?.value === newPrimary.target_id ||
                se.controls?.contact?.controls?.id?.value === newPrimary.target_id
        );

        // check if need to update primary sales entity
        const checkPrimaryIsTheSame =
            oldPrimaryEntity.controls?.profile?.controls?.id?.value === newPrimary.target_id ||
            oldPrimaryEntity.controls.company_group?.controls?.id?.value === newPrimary.target_id ||
            oldPrimaryEntity.controls?.contact?.controls?.id?.value === newPrimary.target_id;

        // update primary sales entity
        if (currentPrimaryEntityForUpdate && !checkPrimaryIsTheSame) {
            // update new primary se
            currentPrimaryEntityForUpdate.controls.is_primary?.patchValue(true, {emitEvent: false});
            currentPrimaryEntityForUpdate.controls.added_automatic?.patchValue(false, {emitEvent: false});
            currentPrimaryEntityForUpdate.controls.show_in_disbursement_pdf?.patchValue(true, {emitEvent: false});
            // it's for case when sales entity was added by compensation profile with type Participant;
            // Such sales entity has financial transfer with type 'participant_split' and added_automatic=true
            // If we make this sales entity primary, compensation profile can stop to apply and transfer will be remove
            // That's way we need to change flag 'added_automatic' to false
            const primarySEParticipantSplit = this.dealProcessing.dealFG.controls.financial_transfers?.controls.find(
                (transferFG) =>
                    // @ts-ignore
                    transferFG.controls?.receiver?.controls &&
                    currentPrimaryEntityForUpdate.controls.contact?.controls &&
                    currentPrimaryEntityForUpdate.controls.contact.controls?.id &&
                    // @ts-ignore
                    transferFG.controls?.receiver?.controls.contact_id?.value ===
                        currentPrimaryEntityForUpdate.controls.contact.controls?.id.value &&
                    transferFG.controls?.type?.value === FinancialTransferEntity.type_SET.participant_split
            );
            if (primarySEParticipantSplit && primarySEParticipantSplit.controls.added_automatic?.value === true) {
                primarySEParticipantSplit.controls.added_automatic.patchValue(false, {emitEvent: false});
            }

            // update old primary se
            oldPrimaryEntity.controls.is_primary?.patchValue(false, {emitEvent: false});
            oldPrimaryEntity.controls.show_in_disbursement_pdf?.patchValue(false, {emitEvent: false});
            oldPrimaryEntity.controls.added_automatic?.patchValue(false, {emitEvent: false});
        }
    }

    hasReferralTransfers(salesEntityFG: GenericFormGroup<SalesEntity>): boolean {
        return !!salesEntityFG.controls.additional_incomes?.controls?.some(
            (transferFG) => transferFG.controls.type?.value === FinancialTransferEntity.type_SET.referral
        );
    }

    findCompared(item: GenericFormGroup<SalesEntity>, entity: ICustomEntity) {
        return (
            (entity.sales_entity_type === this.SALES_ENTITY.type_SET.profile &&
                item.value.type === this.SALES_ENTITY.type_SET.profile &&
                item.value.profile.id === entity.salesEntityFD?.value.profile.id) ||
            (entity.sales_entity_type === this.SALES_ENTITY.type_SET.company_group &&
                item.value.type === this.SALES_ENTITY.type_SET.company_group &&
                item.value.company_group.id === entity.salesEntityFD?.value.company_group.id) ||
            (entity.sales_entity_type === this.SALES_ENTITY.type_SET.contact &&
                item.value.type === this.SALES_ENTITY.type_SET.contact &&
                item.value.contact.id === entity.salesEntityFD?.value.contact.id)
        );
    }

    doMakePrimaryFromTransfer(index: number) {
        this.doMakeAgent(index);
        this.doMakePrimary(index);
    }

    doMakeTransfer(index: number) {
        // add percentage to primary agent
        this.updatedCustomEntities.forEach((e, idx) => {
            if (e.primary && typeof this.updatedCustomEntities[index].percent === 'number') {
                if (typeof e.percent !== 'number') {
                    e.percent = 0;
                }
                e.percent += Number(this.updatedCustomEntities[index].percent);
            }
        });

        this.updatedCustomEntities[index].role = 'transfer';
        this.updatedCustomEntities[index].percent = 0;
    }

    toggleSideCount(i: number) {
        this.hideSideCountInput[i] = !this.hideSideCountInput[i];
        setTimeout(() => {
            if (
                typeof this.sideCountInputs !== 'undefined' &&
                typeof this.sideCountInputs.toArray()[i] !== 'undefined'
            ) {
                this.sideCountInputs.toArray()[i].nativeElement.focus();
            }
        });
    }

    onBlurSideCount(i: number) {
        this.hideSideCountInput[i] = !this.hideSideCountInput[i];
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
