<div class="d-grid">
    <!-------- Sales Price Adjustment -------->
    <mat-form-field class="mx-3 area-price">
        <input
            matInput
            type="text"
            [maskito]="currencyWithNegativeMaskitoMask"
            name="sales_price_adjustment"
            *ngIf="dealFG && dealFG.controls && dealFG.controls.sales_price_adjustment"
            [formControl]="dealFG.controls.sales_price_adjustment"
            placeholder="Sales Price Adjustment"
        />
    </mat-form-field>

    <!-------- Description -------->
    <mat-form-field class="mx-3 area-description">
        <mat-label>Description</mat-label>
        <textarea
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            *ngIf="dealFG && dealFG.controls && dealFG.controls.sales_price_adjustment_description"
            [formControl]="dealFG.controls.sales_price_adjustment_description"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            style="margin: 0; padding: 0"
        ></textarea>
    </mat-form-field>

    <!-------- Adjusted Price -------->
    <div class="mx-3 font-weight-bold area-adjusted">
        <app-financial-node [label]="'Adjusted Price'" [financialNodeFG]="adjustedSalesPrice"></app-financial-node>
    </div>
</div>
