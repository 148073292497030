import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-muhnee-companies-select-dialog',
    templateUrl: './muhnee-companies-select-dialog.component.html',
    styleUrls: ['./muhnee-companies-select-dialog.component.scss']
})
export class MuhneeCompaniesSelectDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    muhneeCompanies: {own_companies: Array<any>; invited_companies: Array<any>} | undefined;
    selectedCompanyId: number | undefined;

    constructor(
        public dialogRef: MatDialogRef<MuhneeCompaniesSelectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {companies: {own_companies: Array<any>; invited_companies: Array<any>}}
    ) {}

    ngOnInit() {
        this.muhneeCompanies = this.data.companies;
    }

    connectCompany(companyId: number) {
        this.dialogRef.close(companyId);
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
