import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GroupsSource} from 'app/services/sources/groups.source';
import {Profile} from 'app/models/profile';
import {Group} from 'app/models/group';
import {GenericFormArray} from 'app/entites/generic.entity';
import {Tab1AllComponent} from '../tabs/tab1-all.component';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {filter, takeUntil} from 'rxjs/operators';
import {IGroup} from '@cyberco-nodejs/zipi-typings';
import {Subject} from 'rxjs';
import {AvailableRolesSource} from 'app/services/sources/available-roles.source';
import {CompanyPermissionsSource} from 'app/services/sources/companyPermissions.source';

@Component({
    selector: 'app-company-groups-list',
    styles: [
        `
            form {
                flex: 1;
                padding: 20px;
            }

            mat-card {
                height: 100%;
            }
            .container-h {
                width: 100%;
                height: calc(100vh - 70px);
                background-color: #fff;
            }
            .height-with-subcompanies {
                height: calc(100vh - 158px);
            }
            .height-without-subcompanies {
                height: calc(100vh - 94px);
            }
        `
    ],
    template: `
        <div class="container-h">
            <!------- Header with routing ------->
            <app-account-info-toolbar
                [section]="'groups'"
                [createModeGroup]="createMode"
                (enterCreationModeGroupEmitter)="enterCreationMode$.next($event)"
            ></app-account-info-toolbar>

            <div
                [ngStyle]="isExistSubCompanies ? {'margin-top': '20px'} : null"
                [ngClass]="{
                    'height-with-subcompanies': isExistSubCompanies,
                    'height-without-subcompanies': !isExistSubCompanies
                }"
            >
                <app-sub-companies-panel
                    [permission]="'company_settings__view_groups'"
                    (currentProfileChanged)="currentProfileChanged($event)"
                    (availableProfilesEmitter)="availableProfilesHandler($event)"
                ></app-sub-companies-panel>

                <app-company-groups-tab-1-all
                    #all
                    [isExistSubCompanies]="isExistSubCompanies"
                    [groupsListFA]="groupsListAllFA"
                    [enterCreationMode$]="enterCreationMode$"
                    (createModeEmitter)="createMode = $event"
                ></app-company-groups-tab-1-all>
            </div>
        </div>
    `
})
export class IndexComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('all', {static: true}) all: Tab1AllComponent | undefined;

    public currentProfile: Profile = new Profile();
    public groupsListAllFA: GenericFormArray<Group> = new GenericFormArray<Group>([]);
    public availableProfiles: Profile[] = [];
    public isExistSubCompanies: boolean = false;

    protected sortedGroupsList: any[] = [];

    public createMode: boolean = false;
    enterCreationMode$: Subject<any> = new Subject();

    constructor(
        private groupSrc: GroupsSource,
        protected currentProfileSource: CurrentProfileSource,
        private companyPermissionsSource: CompanyPermissionsSource,
        private availableRolesSource: AvailableRolesSource
    ) {}

    ngOnInit() {
        this.initGroups();

        this.currentProfileSource.changeProfileEvent
            .pipe(
                filter((profile) => profile.company !== null),
                takeUntil(this.unsubscribe)
            )
            .subscribe((profile) => {
                this.currentProfile = profile;

                this.groupSrc.load(this.currentProfile).then(() => {
                    // set all variables to initial values
                    this.resetComponentState();

                    this.initGroups();
                });
            });
    }

    initGroups() {
        this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((groups) => {
            this.sortedGroupsList = [];

            const filteredGroups = groups
                .filter((group) => group.type !== Group.type_SET.division)
                .map((group) => {
                    return {...group, children: this.withChildren(groups, group.id as number)};
                });

            const groupsList = filteredGroups.filter((group) => !group.parent__group_fk_id);

            groupsList.forEach((group) => {
                if (!group.parent__group_fk_id && !group.parent_group) {
                    const currentGroup = groups.find((g) => g.id === group.id);
                    if (currentGroup) {
                        currentGroup.child_level = 0;
                        this.sortedGroupsList.push(currentGroup);
                        this.addChildren(groups, group, 0);
                    }
                }
            });
            this.groupsListAllFA.patchValue(this.sortedGroupsList);
        });
    }

    resetComponentState() {
        this.currentProfile = new Profile();
        this.groupsListAllFA = new GenericFormArray<Group>([]);
        this.availableProfiles = [];

        this.sortedGroupsList = [];
    }

    addChildren(groups: Group[], group: any, childLevel: number) {
        if (group.children && group.children.length) {
            group.children.forEach((childGroup: any) => {
                const child = groups.find((g) => g.id === childGroup.id);
                if (child) {
                    child.child_level = childLevel + 1;
                    this.sortedGroupsList.push(child);
                    this.addChildren(groups, childGroup, childLevel + 1);
                }
            });
        }
    }

    withChildren(allGroups: Group[], groupId: number): any[] {
        const children = allGroups.filter((group) => group.parent_group && group.parent_group.id === groupId);

        if (children.length) {
            return children.map((group) => {
                return {
                    ...group,
                    children: this.withChildren(allGroups, group.id as number)
                };
            });
        }
        return [];
    }

    currentProfileChanged(nextProfile: Profile) {
        this.companyPermissionsSource.load();
        this.availableRolesSource.load();
    }

    availableProfilesHandler(availableProfiles: Profile[]) {
        availableProfiles.length > 1 ? (this.isExistSubCompanies = true) : (this.isExistSubCompanies = false);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.enterCreationMode$.complete();
    }
}
