<section class="bg-white">
    <!------- Header ------->
    <header class="d-flex align-items-center px-3 list-header">
        <h3 class="m-0 mr-1 d-flex align-items-end">Vendor Credits</h3>

        <i class="sp-spacer"></i>

        <mat-form-field class="mt-2 mr-2" floatLabel="never">
            <mat-label>Status filter</mat-label>
            <mat-select [formControl]="statusFilter" multiple>
                <mat-option
                    *ngFor="let status of statusTypes"
                    [value]="status"
                    (onSelectionChange)="changeStatusFilter($event)"
                >
                    {{ status | fromSnakeCase }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button
            type="button"
            mat-raised-button
            color="primary"
            [rule]="{purchases__manage_vendor_credits: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            routerLink="/purchases/vendorcredits/create"
        >
            <mat-icon>add</mat-icon>
            Create
        </button>
    </header>

    <!------- List of vendor credits ------->
    <div class="table-background">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef class="w-5">
                    <!--for now we don't want to allow "select all"-->
                    <!--                    <mat-checkbox #bulkSelect-->
                    <!--                                  class="checkbox-area"-->
                    <!--                                  rbac [anyRule]="[{purchases__manage_vendor_credits : true}]"-->
                    <!--                                  [ngClass]="{'checked': isAllSelected}"-->
                    <!--                                  (change)="$event.checked ? selectAll() : clearSelection()"-->
                    <!--                                  [checked]="isAllSelected">-->
                    <!--                    </mat-checkbox>-->
                </th>
                <td mat-cell *matCellDef="let element" class="w-5" (click)="$event.stopPropagation()">
                    <mat-checkbox
                        (change)="toggleBulkCheckbox($event, element.vendor_credit_id)"
                        [value]="element.vendor_credit_id"
                        rbac
                        [anyRule]="[{purchases__manage_vendor_credits: true}]"
                        [checked]="selection.isSelected(element.vendor_credit_id)"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="vendor_credit_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Date</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.vendor_credit_date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="vendor_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25 pl-1">Vendor</th>
                <td mat-cell *matCellDef="let element" class="w-25 px-1">
                    {{ element.vendor_name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="vendor_credit_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15 pl-1">Vendor Credit#</th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1">
                    {{ element.vendor_credit_number }}
                </td>
            </ng-container>

            <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20 pl-1">Reference</th>
                <td mat-cell *matCellDef="let element" class="w-20 px-1">
                    {{ element.reference }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Status</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <span *ngIf="element.status" [ngStyle]="{color: statusColor[element.status]}">
                        {{ element.status | titlecase }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="total_amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 table-header-align-right">Amount</th>
                <td mat-cell *matCellDef="let element" class="w-10 text-right" style="padding-right: 17px">
                    {{ element.total_amount | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="w-5 text-right">Action</th>
                <td mat-cell *matCellDef="let element" class="w-5 text-right">
                    <button
                        mat-icon-button
                        *ngIf="isPayStubPrintAvailable"
                        [rule]="{purchases__manage_vendor_credits: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        [matMenuTriggerFor]="menu"
                        (click)="$event.stopPropagation()"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngIf="isPayStubPrintAvailable" (click)="printPayStub(element)">
                            Print Paystub
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="row.id" class="zp-table-row"></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 text-center no-items">
            There are no vendor credits.
        </div>
    </div>

    <app-multi-selection-toolbar
        [numberSelected]="selection.selected.length"
        [toolbarMinWidth]="'880px'"
        selectionName="Vendor Credit"
        *ngIf="selection.hasValue()"
    >
        <button class="mat-button" (click)="downloadPayStub()" *ngIf="isPayStubPrintAvailable">
            <span style="width: 18px; height: 18px" class="material-symbols-outlined action-button-icon">download</span>
            Download {{ selection.selected.length > 1 ? 'PayStubs' : 'PayStub' }}
        </button>
    </app-multi-selection-toolbar>
</section>
