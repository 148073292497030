<mat-card class="widget-container" *ngIf="widget" [ngClass]="widget.widget_id && customizeVisibility ? 'editable' : ''">
    <div class="widget-header">
        <mat-card-title style="word-break: break-word">{{ widget.name ? widget.name : ' ' }}</mat-card-title>
        <span class="d-flex align-items-center">
            <i class="d-flex align-items-center icon-minutes-ago mr-1">
                <button *ngIf="widget.widget_id" mat-icon-button (click)="reloadWidgetData()">
                    <mat-icon>cached</mat-icon>
                </button>
                <span style="white-space: nowrap; margin-top: 4px">{{ getMinutesAgo() }} min</span>
            </i>
            <span *ngIf="customizeVisibility && !configPanelVisible" class="d-flex">
                <i class="material-icons icon-size" style="color: #ff8566" (click)="deleteWidget()">delete</i>
                <i class="material-icons icon-size" (click)="showConfigPanel()">settings</i>
            </span>
        </span>
    </div>

    <mat-card-content *ngIf="!configPanelVisible && data.widget_data && isDataExist" class="w-data">
        <p>for Current {{ data.settings?.period }}</p>

        <div class="space-around">
            <div class="view-area-content">
                <div class="specify-data">Units</div>
                <!--<div appGoogleChart id="chart-widget-{{widget.id}}-1" class="chart pie_chart" [chartData]="pie_ChartData1" [chartOptions] = "pie_ChartOptions" chartType="PieChart" ></div>-->
                <ngx-charts-pie-chart
                    [view]="[150, 150]"
                    [scheme]="pieChartColorScheme"
                    [results]="pie_ChartDataNgx1"
                    [doughnut]="true"
                    [animations]="false"
                    [tooltipDisabled]="true"
                ></ngx-charts-pie-chart>
                <div class="number-in-donut" style="top: 42%">
                    <span>{{ getPercentageValue(data.settings.units_goal, data.widget_data.units) }}%</span>
                </div>
                <div class="specify-data">
                    <span>{{ data.widget_data.units | number: '1.0' }}</span>
                    <hr />
                    <span>{{ data.settings.units_goal | number: '1.0' }}</span>
                </div>
            </div>
            <div class="view-area-content">
                <div class="specify-data">Volume</div>
                <!--<div appGoogleChart id="chart-widget-{{widget.id}}-2" class="chart pie_chart" [chartData]="pie_ChartData2" [chartOptions] = "pie_ChartOptions" chartType="PieChart" ></div>-->
                <ngx-charts-pie-chart
                    [view]="[150, 150]"
                    [scheme]="pieChartColorScheme"
                    [results]="pie_ChartDataNgx2"
                    [doughnut]="true"
                    [animations]="false"
                    [tooltipDisabled]="true"
                ></ngx-charts-pie-chart>
                <div class="number-in-donut" style="top: 42%">
                    <span>{{ getPercentageValue(data.settings.volume_goal, data.widget_data.volume) }}%</span>
                </div>

                <div class="specify-data">
                    <span>{{ data.widget_data.volume | currency: 'USD' : 'symbol' }}</span>
                    <hr />
                    <span>{{ data.settings.volume_goal | currency: 'USD' : 'symbol' }}</span>
                </div>
            </div>
        </div>
    </mat-card-content>

    <mat-card-content *ngIf="!configPanelVisible && data.widget_data && !isDataExist" class="w-data">
        <div class="n-a">
            <h3>N/A</h3>
        </div>
    </mat-card-content>

    <mat-card-content *ngIf="configPanelVisible">
        <form [id]="idFormName" #productionForm="ngForm" (ngSubmit)="saveConfig(productionForm)">
            <div class="mb-3">
                <mat-form-field class="mr-2">
                    <input matInput [(ngModel)]="widget.name" required name="name" placeholder="Title" />
                </mat-form-field>
                <mat-form-field class="mr-2">
                    <input matInput name="type" disabled placeholder="Type" value="Production" />
                </mat-form-field>
            </div>
            <mat-form-field class="mr-2">
                <mat-select
                    placeholder="Widget period"
                    [(ngModel)]="widget.settings.period"
                    name="period"
                    required
                    class="select-period"
                >
                    <mat-option [value]="'week'"> Current week </mat-option>
                    <mat-option [value]="'month'"> Current month </mat-option>
                    <mat-option [value]="'quarter'"> Current quarter </mat-option>
                    <mat-option [value]="'year'"> Current year </mat-option>
                    <mat-option [value]="'custom_date'"> Custom date </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="row mr-2" *ngIf="widget.settings.period === 'custom_date'">
                <div class="col-xl-6">
                    <app-date-picker
                        class="spaced-at-right w-100"
                        [placeholder]="'Start date'"
                        [valueType]="dateValueType"
                        [dateControl]="startDateControl"
                    ></app-date-picker>
                </div>
                <div class="col-xl-6">
                    <app-date-picker
                        class="spaced-at-right w-100"
                        [placeholder]="'End date'"
                        [valueType]="dateValueType"
                        [dateControl]="endDateControl"
                    ></app-date-picker>
                </div>
            </div>

            <app-deals-view-picker-select
                *ngIf="
                    sessionService.profile &&
                    sessionService.profile.id &&
                    widget.settings.by_company &&
                    sessionService.profile.type === 'default'
                "
                [currentProfile]="sessionService.profile"
                [pickedList]="widget.settings.by_company[sessionService.profile.id].filter_by_deal_members"
                [mode]="widget.settings.by_company[sessionService.profile.id].deals_view_mode"
                (modeChange)="widget.settings.by_company[sessionService.profile.id].deals_view_mode = $event"
            >
            </app-deals-view-picker-select>

            <app-combined-entity-picker
                *ngIf="
                    sessionService.profile &&
                    sessionService.profile.id &&
                    widget.settings.by_company &&
                    widget.settings.by_company[sessionService.profile.id].deals_view_mode === 'custom'
                "
                class="mr-2"
                [title]="'Groups and Profiles'"
                [additionalTitle]="''"
                [positionMode]="'bottom'"
                [availableTypes]="['individuals_in_group', 'individual']"
                [nodesFA]="entitiesControl"
                displayMode="double-line"
            ></app-combined-entity-picker>

            <div *ngIf="sessionService.profile && sessionService.profile.type === 'global'">
                View Mode:
                <button mat-button (click)="includeDealsFromCompanies()">Include deals from companies</button>
            </div>
            <mat-form-field class="mr-2">
                <mat-select
                    multiple
                    placeholder="Deals status"
                    [(ngModel)]="widget.settings.deal_status"
                    name="deal_status"
                    required
                    class="select-deal"
                >
                    <mat-option *ngFor="let status of object_keys(DEAL.status_SET)" [value]="status">
                        {{ DEAL.status_LABELS[status] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="mr-2">
                <mat-select
                    multiple
                    placeholder="Deals type"
                    [(ngModel)]="widget.settings.deal_type"
                    name="deal_type"
                    required
                    class="select-deal"
                >
                    <mat-option *ngFor="let type of DEAL.provideTypes()" [value]="type">
                        {{ DEAL.type_LABELS[type] || type }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="mt-3">
                <mat-form-field class="mr-2">
                    <input
                        matInput
                        [(ngModel)]="widget.settings.units_goal"
                        required
                        name="net_goal"
                        type="number"
                        placeholder="Units goal"
                    />
                </mat-form-field>
                <mat-form-field class="mr-2">
                    <input
                        matInput
                        [(ngModel)]="widget.settings.volume_goal"
                        required
                        name="gci_goal"
                        type="number"
                        placeholder="Volume goal"
                    />
                </mat-form-field>
            </div>

            <mat-card-actions *ngIf="configPanelVisible" class="action-buttons">
                <button mat-raised-button class="mat-primary" [disabled]="!productionForm.valid">Save</button>
                <button mat-raised-button class="mat-active" (click)="hideConfigPanel()">Cancel</button>
            </mat-card-actions>
        </form>
    </mat-card-content>
</mat-card>
