<div class="page-container" *ngIf="componentReady">
    <form style="width: 100%" id="deal-form" #dealForm="ngForm">
        <app-deal-property-information
            class="section"
            [dealFormGroup]="dealFormGroup"
            (selectedTagsIdsUpdated)="updateSelectedTagsIds($event)"
        ></app-deal-property-information>
        <app-edit-deal-transaction
            class="section"
            [dealFormGroup]="dealFormGroup"
            [canCreateDualDeal]="false"
        ></app-edit-deal-transaction>
    </form>

    <!--    <app-deal-disbursement class="section" style="margin-top: 22px; width: 100%" [dealFormGroup]="dealFormGroup"-->
    <!--                           [showRefreshButton]="showRefreshButton"-->
    <!--                           (onRefresh)="doRefresh($event)"></app-deal-disbursement>-->

    <!--    <app-deal-communication #communication class="section" style="margin-top: 22px; width: 100%" [dealFormGroup]="dealFormGroup"></app-deal-communication>-->

    <app-manage-sales-entity-block
        class="section"
        *ngIf="
            dealFormGroup.controls.sales_entities &&
            dealFormGroup.controls.sales_entities.controls &&
            dealFormGroup.controls.sales_entities.controls.length
        "
        [salesEntities]="dealFormGroup.controls.sales_entities.controls"
        [showSideCountAndSalesVolumeFlag]="showSideCountAndSalesVolumeFlag"
        (validateSalesManager)="saveDisabled = !$event"
    ></app-manage-sales-entity-block>

    <div class="d-flex w-100 mt-3">
        <ng-container *ngIf="dealFormGroup.controls.apply_rules">
            <div
                class="apply-rules align-items-center"
                [rule]="{deals__manage_override: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'flex'}"
                [denyStyle]="{display: 'none'}"
            >
                <mat-slide-toggle [formControl]="dealFormGroup.controls.apply_rules">Apply Rules</mat-slide-toggle>
            </div>
        </ng-container>

        <span class="sp-spacer"></span>

        <button mat-button color="primary" [routerLink]="['/deals']">Cancel</button>

        <button
            mat-stroked-button
            color="primary"
            class="ml-2"
            rbac
            [rule]="{edit_deal: true}"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'flex'}"
            [denyStyle]="{display: 'none'}"
            [disabled]="saveDisabled"
            (click)="save(true)"
        >
            Create
        </button>

        <button
            mat-flat-button
            color="primary"
            class="ml-3"
            rbac
            [rule]="{edit_deal: true}"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'flex'}"
            [denyStyle]="{display: 'none'}"
            [disabled]="saveDisabled"
            (click)="save(false)"
        >
            Create and Close
        </button>
    </div>
</div>
