import {Component, Input, OnInit} from '@angular/core';
import {IReportsPreviewResultData} from '../../../../store/reports.reducer';

interface ISobResponse {
    entities: IReportsPreviewResultData[];
    totals: {
        data: IReportsPreviewResultData[];
        summary: IReportsPreviewResultData;
    };
    types: IReportsPreviewResultData;
}

@Component({
    selector: 'reports-preview-source-of-business',
    templateUrl: './source-of-business.component.html',
    styleUrls: ['./source-of-business.component.scss']
})
export class SourceOfBusinessPreviewComponent implements OnInit {
    @Input()
    /**
     * Set report data
     */
    // set reportData(data: ISobResponse) {
    //     // data.forEach(item => {
    //     //     switch (item.type) {
    //     //         case transactionTypeListByName.buyer:
    //     //             this.buyer = {
    //     //                 entities: item.entities,
    //     //                 total: item.total,
    //     //             };
    //     //             break;
    //     //
    //     //         case transactionTypeListByName.seller:
    //     //             this.seller = {
    //     //                 entities: item.entities,
    //     //                 total: item.total,
    //     //             };
    //     //             break;
    //     //
    //     //         case transactionTypeListByName.referral:
    //     //             this.referral = {
    //     //                 entities: item.entities,
    //     //                 total: item.total,
    //     //             };
    //     //             break;
    //     //
    //     //         case transactionTypeListByName.rental_tenant:
    //     //             this.rentalTenant = {
    //     //                 entities: item.entities,
    //     //                 total: item.total,
    //     //             };
    //     //             break;
    //     //
    //     //         case transactionTypeListByName.rental_owner:
    //     //             this.rentalOwner = {
    //     //                 entities: item.entities,
    //     //                 total: item.total,
    //     //             };
    //     //             break;
    //     //
    //     //         case 'combined':
    //     //             this.combined = {
    //     //                 entities: item.entities,
    //     //                 total: item.total,
    //     //             };
    //     //             break;
    //     //
    //     //         default:
    //     //     }
    //     // });
    //
    //     this._reportData = data;
    // }
    @Input()
    reportData: ISobResponse = {
        entities: [],
        totals: {
            data: [],
            summary: {}
        },
        types: []
    };

    seller: {entities: Array<any>; total: any} = {
        entities: [],
        total: {}
    };

    buyer: {entities: Array<any>; total: any} = {
        entities: [],
        total: {}
    };

    referral: {entities: Array<any>; total: any} = {
        entities: [],
        total: {}
    };

    rentalOwner: {entities: Array<any>; total: any} = {
        entities: [],
        total: {}
    };

    rentalTenant: {entities: Array<any>; total: any} = {
        entities: [],
        total: {}
    };

    combined: {entities: Array<any>; total: any} = {
        entities: [],
        total: {}
    };

    tableStructure: Array<{
        title: string;
        value: string;
        filter?: string;
    }> = [
        {
            title: 'Description',
            value: 'source_of_description'
        },
        {
            title: '#',
            value: 'deals_count'
        },
        {
            title: 'Value',
            value: 'sum_volume',
            filter: 'currency'
        },
        {
            title: '%Total',
            value: 'percent_of_total',
            filter: 'percent'
        }
    ];

    footerTotalColumns: Array<{
        value: string;
        filter?: string;
    }> = [
        {
            value: 'total_count_deals'
        },
        {
            value: 'total_sum_volume',
            filter: 'currency'
        },
        {
            value: 'total_percent_of_total',
            filter: 'percent'
        }
    ];

    columnsToDisplay: string[] = ['source_of_description', 'deals_count', 'sum_volume', 'percent_of_total'];
    footerTotalColumnsToDisplay: string[] = ['total_count_deals', 'total_sum_volume', 'total_percent_of_total'];

    constructor() {}

    ngOnInit(): void {}

    /**
     * Get total cost
     * @param element
     * @param tableName
     */
    getTotalCost(
        element: string,
        tableName: 'buyer' | 'seller' | 'referral' | 'rentalOwner' | 'rentalTenant' | 'combined'
    ) {
        switch (element) {
            case 'total_count_deals':
                return this[tableName]['total'].total_deals;
            case 'total_sum_volume':
                return this[tableName]['total'].sum_volume;
            case 'total_percent_of_total':
                return '100';
            default:
                return '';
        }
    }
}
