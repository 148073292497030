import {IReport, IReportCategory} from '@cyberco-nodejs/zipi-typings';
import {ReportsAction, ReportsActionType} from './reports.action';

export function reportsReducer(state: IReportsState = INIT_REPORTS_STATE, action: ReportsAction): IReportsState {
    switch (action.type) {
        case ReportsActionType.FETCH_REPORT_LIST_SUCCESS:
            return {
                ...state,
                reports: {
                    data: action.payload!.isAppend
                        ? state.reports.data.concat(action.payload!.data)
                        : action.payload!.data,
                    isLoaded: action.payload!.isLoaded
                }
            };

        case ReportsActionType.FETCH_RECOMMENDED_REPORTS_PRESETS_LIST_SUCCESS:
            return {
                ...state,
                recommendedReportsPresetsList: action.payload!
            };

        case ReportsActionType.FETCH_REPORTS_CATEGORIES_LIST_SUCCESS:
            return {
                ...state,
                reportsCategoriesList: action.payload!
            };

        case ReportsActionType.TOGGLE_EDIT_REPORT_SIDEBAR_STATUS: {
            return {
                ...state,
                reportEditSidebar: {
                    isOpened: action.payload!.isOpened || false,
                    isEditMode: action.payload!.isEditMode || false,
                    editId: action.payload!.editId || null
                }
            };
        }

        case ReportsActionType.FETCH_REPORT_PREVIEW_DATA_SUCCESS: {
            return {
                ...state,
                reportPreviewData: action.payload!
            };
        }

        case ReportsActionType.CLEAR_REPORT_PREVIEW_DATA: {
            return {
                ...state,
                reportPreviewData: null
            };
        }

        default:
            return state;
    }
}

export interface IReportsPreviewData {
    reportEntity: IReport;
    reportResult: IReportsPreviewResultData[];
    error: string;
    isError: boolean;
}

export interface IReportsPreviewResultData {
    [key: string]: any;
}

export interface IReportsState {
    reports: {
        data: IReport[];
        isLoaded: boolean;
    };
    recommendedReportsPresetsList: IReport[];
    reportsCategoriesList: IReportCategory[];
    reportsData: IReport | null;
    reportEditSidebar: {
        isOpened: boolean;
        editId: number | null;
        isEditMode: boolean;
    };
    reportPreviewData: IReportsPreviewData | null;
}

export const INIT_REPORTS_STATE: IReportsState = {
    reportsCategoriesList: [],
    reports: {
        data: [],
        isLoaded: false
    },
    recommendedReportsPresetsList: [],
    reportsData: null,
    reportEditSidebar: {
        isOpened: false,
        editId: null,
        isEditMode: false
    },
    reportPreviewData: null
};
