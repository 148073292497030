import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-unsaved-changes',
    styles: [
        `
            .close-button {
                float: right;
                margin: -20px;
            }
        `
    ],
    templateUrl: './unsaved-changes.component.html'
})
export class UnsavedChangesComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<UnsavedChangesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {}
}
