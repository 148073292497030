import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ServiceRequester} from '../service.requester';
import {IUser, IProfile, ICompany, InviteHash} from '../../../typings';

@Injectable()
export class UsersService {
    private url = '/users/';

    constructor(public requester: ServiceRequester) {}

    getMe(): Observable<IUser> {
        return this.requester.makeMsCall$(this.url + 'me', 'GET', 'shipp');
    }

    afterRegister(user: IUser, company: ICompany) {
        return this.requester.makeMsCall$(`/public/after-register`, 'POST', 'shipp', {
            user,
            company
        });
    }

    /**
     * New user to company invitation system
     */
    getInvitedToCompanyProfile(inviteHash: InviteHash): Observable<IProfile> {
        return this.requester.makeMsCall$(`/public/invited-to-company-profile?hash=${inviteHash}`, 'GET', 'shipp');
    }
}
