import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {Store} from '@ngrx/store';
import {IFinanceState} from '../../../store/finance.reducer';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-charge-loan-customer-dialog',
    templateUrl: './charge-loan-customer-dialog.component.html',
    styleUrls: ['./charge-loan-customer-dialog.component.scss']
})
export class ChargeLoanCustomerDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    formGroup: UntypedFormGroup;

    invoiceMethods: IPaymentMethod[] = [];

    constructor(
        private fb: UntypedFormBuilder,
        private invoicesService: InvoicesService,
        private store: Store<IFinanceState>,
        public dialogRef: MatDialogRef<ChargeLoanCustomerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.formGroup = this.fb.group({
            note: ['', []],
            paid_date: [null, [Validators.required]],
            pay_to__payment_method_fk_id: [null, [Validators.required]],
            pay_to__ledger_account_fk_id: [null, [Validators.required]]
        });
    }

    ngOnInit() {
        const methodIds = []; // TODO: dirty hack to implement new payment method fields. Should be refactored
        if (this.data.invoice.pay_to_bank__payment_method_fk_id) {
            methodIds.push(this.data.invoice.pay_to_bank__payment_method_fk_id);
        }
        if (this.data.invoice.pay_to_card__payment_method_fk_id) {
            methodIds.push(this.data.invoice.pay_to_card__payment_method_fk_id);
        }
        this.invoicesService
            .getInvoiceMethods(methodIds)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((methods) => {
                this.invoiceMethods = methods;
            });
    }

    isAbleToDisplay(method: IPaymentMethod) {
        if (!this.invoiceMethods || this.invoiceMethods.length === 0) {
            return false;
        } else {
            return (
                method.payment_gateway!.type === 'authorize_net_customer' &&
                this.invoiceMethods.some(
                    (invoiceMethod) => invoiceMethod.payment_gateway!.type === 'authorize_net_merchant'
                )
            );
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
