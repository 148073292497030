import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationsServiceZipi} from '../../notifications/notifications.service';

@Component({
    selector: 'app-login-form-dialog',
    template: `
        <div mat-dialog-title>
            <h4>
                The email address is already in use.<br />
                To continue, please login:
            </h4>
        </div>
        <mat-dialog-content *ngIf="formGroup" [formGroup]="formGroup">
            <mat-form-field class="w-100">
                <input matInput formControlName="email" type="text" placeholder="Email" required />
            </mat-form-field>
            <mat-form-field class="w-100">
                <input
                    matInput
                    formControlName="password"
                    type="password"
                    placeholder="Password"
                    required
                    cdkFocusInitial
                />
            </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="text-right w-100">
                <button mat-raised-button class="mat-default" (click)="dialogRef.close(false)" tabindex="-1">
                    Cancel
                </button>
                <button
                    mat-raised-button
                    class="mat-primary"
                    mat-raised-button
                    color="primary"
                    (click)="submitForm()"
                    tabindex="1"
                >
                    Submit
                </button>
            </div>
        </mat-dialog-actions>
    `
})
export class LoginFormDialogComponent implements OnInit {
    formGroup: UntypedFormGroup | null = null;

    constructor(
        public dialogRef: MatDialogRef<LoginFormDialogComponent>,
        protected notificationsService: NotificationsServiceZipi,
        private fb: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.formGroup = this.fb.group({
            email: [{value: this.data.email, disabled: true}, Validators.required],
            password: ['', [Validators.required]]
        });
    }

    submitForm() {
        if (!this.formGroup) {
            return;
        }
        if (this.formGroup.invalid) {
            this.notificationsService.addError('Form is not valid');
        } else {
            this.dialogRef.close(this.formGroup.value);
        }
    }
}
