<div mat-dialog-title>
    <h3>{{ data.title }}</h3>
</div>

<mat-dialog-content>
    <div class="mb-3">
        <p *ngIf="data.message">
            {{ data.message }}
        </p>

        <p *ngFor="let msg of data.messages" class="m-0">
            {{ msg }}
        </p>
    </div>

    <mat-form-field class="w-100">
        <mat-label *ngIf="data.input_label">{{ data.input_label }}</mat-label>
        <input matInput [placeholder]="data.placeholder" type="text" name="name" required [formControl]="input" />
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
    <button class="mr-2" *ngIf="!data.hideCancel" mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">
        {{ data.buttonCancelMessage || 'Cancel' }}
    </button>
    <button mat-raised-button class="mr-2" color="primary" (click)="dialogRef.close(input.value)" tabindex="1">
        {{ data.buttonOkMessage || 'Ok' }}
    </button>
</mat-dialog-actions>
