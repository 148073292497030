import {Injectable} from '@angular/core';
import {AppInitSource} from '../../../services/sources/app-init.source';
import {ServiceRequester} from '../../../services/service.requester';
import {NotificationsServiceZipi} from '../../notifications/notifications.service';
import {AddonsSource} from './addons.source';
import {AddonModel} from './addons.models';
import {MarketplaceSource} from '../../account-info/marketplace/marketplace.source';
import {Observable} from 'rxjs';

export interface AddonConnectedProfile {
    profile_id: number;
    first_name: string;
    last_name: string;
    email: string;
    description: string | null;
    created_at: string | null;
    connected_status: string;
}

@Injectable()
export class AddonsApi {
    constructor(
        protected addonsSource: AddonsSource,
        protected initAppSource: AppInitSource,
        protected marketplaceSource: MarketplaceSource,
        public requester: ServiceRequester,
        protected notificationsService: NotificationsServiceZipi
    ) {
        this.initAppSource.appInit.subscribe(() => {
            this.loadAddons();
        });

        this.marketplaceSource.addonsChangeEvent.subscribe(() => {
            this.loadAddons();
        });
    }

    loadAddons(): Promise<any> {
        return this.requester.makeMsCall('/profile-addons/load', 'GET').then((addons) => {
            this.addonsSource.setLoadedAddons(addons);
            return addons;
        });
    }

    loadUserAddons(): Promise<any> {
        return this.requester.makeMsCall('/user-addons/load', 'GET');
    }

    getAddonByClientId(addonClientId: string): Promise<any> {
        return this.requester.makeMsCall(`/profile-addons/get-addon-by-client-id/${addonClientId}`, 'GET');
    }

    setupAddon(addon: AddonModel): Promise<any> {
        return this.requester.makeMsCall('/profile-addons/' + addon.id + '/setup', 'PUT');
    }

    setupUserAddon(addon: AddonModel): Promise<any> {
        return this.requester.makeMsCall('/user-addons/' + addon.id + '/setup', 'PUT');
    }

    deactivateAddon(addon: AddonModel, targetProfileId: number | null = null): Promise<any> {
        const targetProfileParam = targetProfileId ? `?targetProfileId=${targetProfileId}` : '';
        return this.requester.makeMsCall('/profile-addons/' + addon.id + '/deactivate' + targetProfileParam, 'PUT');
    }

    deactivateUserAddonInstance(addonId: number, addonInstanceId: number): Promise<any> {
        return this.requester.makeMsCall('/user-addons/' + addonId + '/deactivate/' + addonInstanceId, 'DELETE');
    }

    /**
     * Get company addons instance
     */
    getCompanyAddonsInstances(addonSlug: string, params: any): Promise<AddonConnectedProfile[]> {
        return this.requester.makeMsCall(`/profile-addons/instances/${addonSlug}`, 'GET', params);
    }
}
