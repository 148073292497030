<div>
    <h2>Edit Account</h2>
    <div *ngIf="gatewayForm" [formGroup]="gatewayForm">
        <mat-form-field class="w-50 px-2">
            <input matInput required type="text" formControlName="title" />
            <mat-placeholder>Title</mat-placeholder>
        </mat-form-field>
    </div>
    <div class="d-flex justify-content-end">
        <button class="mr-2" *ngIf="!data.hideCancel" mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">
            Cancel
        </button>
        <button mat-raised-button color="primary" (click)="continue()" tabindex="1">Save</button>
    </div>
</div>
