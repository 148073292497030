import {Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {timePeriodObj, listOfSummarized} from '../../../../constants';
import {getNextActivePanel, isLastPanelItem} from '../../helper';
import {ReportsService} from '../../../../services/reports.service';
import {IReport, IReportNodeEntity, IReportSetting, IReportSettingKeys} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-report-edit-bar-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.scss']
})
export class ProgressBarComponent implements OnInit, OnDestroy {
    @Output() valueChanges: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Output() action: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Input() report: IReport | null = null;

    private defaultValues: {[key: string]: any} = {
        time_period: timePeriodObj.this_year.value,
        layout: ['table'],
        summarized: 'quarterly'
    };

    public activePanel: string = 'time_period';
    public listOfGoals: Array<{title: string; value: string}> = [];
    public selectedGoals: Array<number | undefined | null> = [];
    public reportSettings: IReportSetting | null = null;
    public listOfSummarized: Array<{title: string; value: string}> = listOfSummarized;
    public isShowNext: boolean = true;
    public reportOptions = [
        {
            value: 'time_period',
            title: 'Time Period'
            // }, {
            //     value: 'layout',
            //     title: 'Layout'
        },
        {
            value: 'aggregation',
            title: 'Aggregation'
        },
        {
            value: 'entity',
            title: 'Entity'
        },
        {
            value: 'goals',
            title: 'Goals'
        },
        {
            value: 'ratios',
            title: 'Ratios'
        }
    ];

    constructor(protected reportsService: ReportsService) {}

    ngOnInit() {
        this.initData();
        this.fetchData();
    }

    initData() {
        if (this.report) {
            this.reportSettings = {...this.defaultValues, ...this.report.settings};
            this.selectedGoals =
                this.report.settings && this.report.settings.goals
                    ? this.report.settings.goals.map((item) => item.id)
                    : [];
        }
    }

    fetchData() {
        this.reportsService.getCreateReportContext().then((context) => {
            this.listOfGoals = context.goals;
        });
    }

    onSelectOptions(event: {value: string}) {
        this.activePanel = event.value;
        this.isShowNext = !isLastPanelItem(this.activePanel, this.reportOptions);
    }

    onValueChanges(property: IReportSettingKeys, data: any) {
        switch (property) {
            case 'time_period':
                this.reportSettings = {
                    ...this.reportSettings,
                    ...data
                };
                break;
            case 'goals':
                this.selectedGoals = data;
                if (this.reportSettings) {
                    this.reportSettings['goals'] = data.map((item: IReportNodeEntity) => ({id: item}));
                }
                break;
            default:
                if (this.reportSettings) {
                    this.reportSettings[property] = data as never;
                }
        }
    }

    onAction(event: string) {
        const response: {
            type: string;
            data: IReportSetting | null;
        } = {
            type: event,
            data: null
        };

        switch (event) {
            case 'save':
            case 'run':
                response['data'] = this.reportSettings;
                this.action.emit(response);
                break;
            case 'cancel':
            case 'delete':
                this.action.emit(response);
                break;
            case 'next':
                const nextData = getNextActivePanel(this.activePanel, this.reportOptions);
                this.activePanel = nextData.name;
                this.isShowNext = !nextData.isLast;
                break;
            default:
        }
    }

    ngOnDestroy() {
        this.action.complete();
    }
}
