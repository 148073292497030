import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../service.requester';
import {Observable} from 'rxjs';
import {IRecurringJournal} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class RecurringJournalsService {
    private url = '/finance/recurringjournals/';

    constructor(public requester: ServiceRequester) {}

    getRecurringJournals(params = {}): Observable<{result: IRecurringJournal[]; _meta: {total: number}}> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp', params);
    }

    getRecurringJournalById(journalTemplateId: number): Observable<IRecurringJournal> {
        return this.requester.makeMsCall$(this.url + `${journalTemplateId}`, 'GET', 'shipp');
    }

    createRecurringJournal(data: any): Observable<IRecurringJournal> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    updateRecurringJournal(data: IRecurringJournal): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + data.recurring_journal_id, 'PUT', 'shipp', data);
    }

    activateRecurringJournal(recurring_journal_id: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${recurring_journal_id}/activate`, 'PUT', 'shipp');
    }

    stopRecurringJournal(recurring_journal_id: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${recurring_journal_id}/stop`, 'PUT', 'shipp');
    }

    deleteRecurringJournal(id: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + id, 'DELETE', 'shipp');
    }
}
