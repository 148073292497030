import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import {WidgetService} from '../../../services/widget.service';
import {Subject} from 'rxjs';
import {NgForm} from '@angular/forms';
import {Widget} from '../../../../../models/widget';
import {SessionService} from '../../../../../services/session.service';
import {DealsViewPickerContainerDialogComponent} from '../../../../shared/components/deals-view-picker-container/deals-view-picker-container.dialog';
import {Profile} from '../../../../../models/profile';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {DialogConfirmationComponent} from '../../../../shared/components/dialog-confirmation/dialog-confirmation.dialog';
import {AuthService} from '../../../../../services/auth.service';
import {RbacService} from '../../../../rbac/rbac.service';
import {GenericFormArray} from '../../../../../entites/generic.entity';
import {WidgetSource} from '../../../source/widget.source';

@Component({
    selector: 'app-base-widget',
    template: '',
    styleUrls: ['./widgets.scss']
})
export class BaseWidgetComponent implements OnInit, OnDestroy {
    protected unsubscribe: Subject<void> = new Subject();

    @Input() inputFilter: {} | null = null;
    @Input() getDataSubj: Subject<{}> | null = null;
    @Input() widget: Widget | null = null;
    @Input() widgetsList: Widget[] | [] = [];
    @Input() customizeVisibility: boolean = false;
    @Output() changeWidgetList = new EventEmitter();
    @Output() onCrudAction = new EventEmitter();

    public slug = 'base';

    public showDownload: boolean = false;

    public configPanelVisible = false;

    public data: any = {};
    protected randomNum = Math.floor(Math.random() * 99);
    public entitiesControl = new GenericFormArray<any>([], null);
    public dateValueType: 'value_as_date' | 'value_as_int' = 'value_as_date';

    constructor(
        protected widgetService: WidgetService,
        public sessionService: SessionService,
        protected dialog: MatDialog,
        protected router: Router,
        protected authService: AuthService,
        protected rbacService: RbacService,
        protected widgetSource: WidgetSource
    ) {}

    getMinutesAgo() {
        const now: Date = new Date();
        const timestamp: Date = new Date(this.data.data_updated);
        const diff = new Date(now.getTime() - timestamp.getTime());
        return diff.getMinutes();
    }

    reloadWidgetData() {
        if (this.widget && this.widget.board_id && this.widget.widget_id) {
            this.widgetService.updateData(this.widget.board_id, this.widget.widget_id).then((updatedData: Widget) => {
                this.processData(updatedData);
                const widgetData = updatedData.data;
                if (widgetData) {
                    const uid = widgetData.uid_for_modify || widgetData.widget_id;
                    this.widgetSource.updateSubjectData({
                        uid: uid,
                        data: widgetData
                    });
                }
            });
        }
    }

    includeDealsFromCompanies() {
        const actualProfiles = this.sessionService.availableProfiles.filter(
            (profile) => profile.type === Profile.type.default
        );
        this.widget!.setupBackendSettings(actualProfiles);
        const dialogRef = this.dialog.open(DealsViewPickerContainerDialogComponent);
        dialogRef.componentInstance.init(actualProfiles, this.widget!.settings.by_company as any);
    }

    async ngOnInit() {
        if (this.widget && (this.widget.widget_id || this.widget.uid_for_modify)) {
            this.configPanelVisible = false;
        } else {
            this.configPanelVisible = true;
        }

        this.initSubscription();
        if (
            this.widget &&
            this.widget.hasOwnProperty('widget_id') &&
            (this.widget.widget_id || this.widget.uid_for_modify)
        ) {
            await this.checkNewWidgetData();
        }

        this.onInit();
    }

    initSubscription() {
        this.widgetSource.dataSubject.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            const uid = this.getUniqueWidgetId();
            if (data.uid === uid) {
                return this.applyData(data);
            }
        });

        this.getDataSubj!.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            if (
                this.widget &&
                this.widget.hasOwnProperty('widget_id') &&
                this.widgetsList.indexOf(this.widget as never) > -1
            ) {
                return this.checkNewWidgetData();
            }
        });
    }

    /**
     * Check widget data
     */
    async checkNewWidgetData() {
        const uid = this.getUniqueWidgetId();
        if (uid && this.widgetSource.isDataExistForWidget(uid)) {
            this.widgetSource.triggerWidgetDataById(uid);
        } else {
            await this.fetchData();
        }
    }

    onInit() {}

    /**
     * Fetch data
     */
    async fetchData() {
        if (!this.widget!.board_id || !this.widget!.widget_id) {
            throw new Error(`Required fields for widget are empty`);
        }

        let data;
        if (this.widget!.uid_for_modify) {
            data = await this.widgetService.getPreviewData(this.widget!.board_id, this.widget);
        } else {
            data = await this.widgetService.getData(this.widget!.board_id, this.widget!.widget_id);
        }

        const uid = this.getUniqueWidgetId();
        this.widgetSource.updateSubjectData({
            uid: uid,
            ...data
        });
    }

    async applyData(data: any) {
        this.populateEntityControl(data);
        this.processData(data);
        if (data && data.data && this.widget!.widget_id && !this.widget!.uid_for_modify) {
            const now: Date = new Date();
            const timestamp: Date = new Date(data.data.data_updated);
            if (now.getTime() - timestamp.getTime() > 1000 * 60 * 10) {
                // 10 minutes
                this.reloadWidgetData();
            }
        }
        this.configPanelVisible = false;
    }

    /**
     * Populate entity control
     * @param widget
     */
    populateEntityControl(widget: Widget) {
        const actingProfileId = Number(this.sessionService.profile!.id);

        if (
            actingProfileId &&
            widget.data &&
            widget.data.settings &&
            widget.data.settings.by_company &&
            widget.data.settings.by_company[actingProfileId] &&
            widget.data.settings.by_company[actingProfileId].filter_by_deal_members &&
            Array.isArray(widget.data.settings.by_company[actingProfileId].filter_by_deal_members)
        ) {
            const filterByDealMembers = this.prepareFilterByDealMembersDataForEntityPicker(
                widget.data.settings.by_company[actingProfileId].filter_by_deal_members
            );
            this.entitiesControl.patchValue(filterByDealMembers);
        }
    }

    prepareFilterByDealMembersDataForEntityPicker<T>(data: T[]) {
        if (data && data.length) {
            return data.map((item: any) => {
                let type = '';
                switch (item.type) {
                    case 'profile':
                        type = 'individual';
                        break;
                    case 'company_group':
                        type = 'individuals_in_group';
                        break;
                    default:
                }
                const id = item.target_id || item.id;
                return {
                    id: id,
                    target_id: id,
                    label: item.label,
                    pick: true,
                    type: type
                };
            });
        }
        return [];
    }

    processData(widget: Widget) {
        this.data = widget.data;
        this.data.uid_for_modify = this.widget!.uid_for_modify;
    }

    getPercentageValue(totalNum: number, alreadyDoneNum: number) {
        return Math.round((alreadyDoneNum * 100) / totalNum);
    }

    create() {
        if (!this.widget || !this.widget!.board_id) {
            throw new Error(`Required fields for widget are empty`);
        }

        return this.widgetService.create(this.widget.board_id, {
            settings: this.widget.settings,
            type: this.widget.type,
            name: this.widget.name
        });
    }

    getPreviewData() {
        if (!this.widget || !this.widget!.board_id) {
            throw new Error(`Required fields for widget are empty`);
        }

        return this.widgetService.getPreviewData(this.widget.board_id, {
            settings: this.widget.settings,
            type: this.widget.type,
            name: this.widget.name
        });
    }

    /**
     * Get unique widget id
     */
    getUniqueWidgetId() {
        return this.widget!.uid_for_modify || this.widget!.widget_id;
    }

    deleteWidget() {
        const dialogRef = this.dialog.open(DialogConfirmationComponent, {
            width: '320px',
            data: {
                title: 'Confirmation',
                text: 'Are you sure, you want to remove this widget?'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    const uid = this.getUniqueWidgetId();
                    this.onCrudAction.emit({
                        data: {
                            ...this.widget,
                            uid_for_modify: uid
                        },
                        action: 'delete'
                    });
                }
            });
    }

    saveConfig(form: NgForm) {
        if (!form.valid) {
            return false;
        }
        if (!this.widget!.uid_for_modify) {
            this.widget!['uid_for_modify'] = new Date().getTime();
        }

        const widgetSettings = this.widget!.settings;
        if (widgetSettings && widgetSettings.period !== 'custom_date') {
            this.widget!.settings.period_start_date = null;
            this.widget!.settings.period_end_date = null;
        }

        return this.getPreviewData().then((widget) => {
            const uid = this.getUniqueWidgetId();
            this.widgetSource.updateSubjectData({
                uid: uid,
                ...widget
            });

            this.onCrudAction.emit({
                data: {
                    ...widget.data,
                    widget_id: this.widget!.widget_id,
                    uid_for_modify: uid
                },
                action: this.widget!['widget_id'] ? 'update' : 'create'
            });
            return (this.configPanelVisible = false);
        });
    }

    showConfigPanel() {
        this.configPanelVisible = true;
    }

    hideConfigPanel() {
        if (!this.widget!.hasOwnProperty('widget_id') || !this.widget!.widget_id) {
            const widgetIndex = this.widgetsList.indexOf(this.widget as never);
            if (widgetIndex > -1) {
                this.widgetsList.splice(widgetIndex, 1);
                this.changeWidgetList.emit(this.widgetsList);
            }
            return this.widgetsList;
        }
        return (this.configPanelVisible = false);
    }

    object_keys(obj: any) {
        return Object.keys(obj);
    }

    /**
     * Handle Entity picker
     * @param changes
     */
    handleEntityPicker(changes: any) {
        this.widget!.settings!.by_company![Number(this.sessionService.profile!.id)].filter_by_deal_members =
            changes.map((item: any) => {
                let type = '';
                switch (item.type) {
                    case 'individual':
                        type = 'profile';
                        break;
                    case 'individuals_in_group':
                        type = 'company_group';
                        break;
                    case 'company_group':
                        type = 'group';
                        break;
                }
                return {
                    id: item.target_id,
                    target_id: item.target_id,
                    label: item.label,
                    pick: true,
                    type: type
                };
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.getDataSubj!.complete();
        this.changeWidgetList.complete();
        this.onCrudAction.complete();
    }
}
