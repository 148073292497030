<!-- Original layout -->
<div class="row mt-3">
    <div class="col-xl-6">
        <custom-cropper #cropper (change)="cropperOnChange($event)"></custom-cropper>
    </div>
    <div class="col-xl-6">
        <cropper-controls
            *ngIf="customCropper"
            #controls
            (file-changed)="fileChanged($event)"
            (file-clear)="customCropper.clear()"
            [disabled]="dealFormGroup.controls.image_url.disabled"
        ></cropper-controls>
    </div>
</div>
