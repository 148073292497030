import {Injectable} from '@angular/core';
import {ServiceRequester} from '../service.requester';
import {BehaviorSubject, merge as observableMerge, Observable} from 'rxjs';
import {DataSource} from '@angular/cdk/collections';
import {IEntitiesPack} from '../../models/entity-load';
import * as moment from 'moment';
import {ISearchEntity} from '@cyberco-nodejs/zipi-typings';
import {CurrentProfileSource} from './current-profile.source';

@Injectable()
export class SearchEntitiesSource extends DataSource<IEntitiesPack> {
    private url = '/search-entities';

    public sources: {[key: string]: BehaviorSubject<{params: string; entities: ISearchEntity[]; load: boolean}[]>} = {
        load: new BehaviorSubject<{params: string; entities: ISearchEntity[]; load: boolean}[]>([])
    };

    private _source: Observable<IEntitiesPack[]> = observableMerge(
        ...Object.keys(this.sources).map((key) => this.sources[key])
    );

    constructor(
        public requester: ServiceRequester,
        public currentProfileSource: CurrentProfileSource
    ) {
        super();
        this.currentProfileSource.changeProfileEvent.subscribe((currProfile) => {
            this.sources.load.next([]);
        });
    }

    connect() {
        return this.source;
    }

    disconnect() {}

    public get source(): Observable<IEntitiesPack[]> {
        return this._source;
    }

    public clearCache() {
        this.sources.load.next([]);
    }

    public async load(
        paramString: string
    ): Promise<BehaviorSubject<{params: string; entities: ISearchEntity[]; load: boolean}[]>> {
        // check time expiration
        const currentParams = paramString.slice(0, -6);
        const currentTime = moment(new Date()).format('DDHHmm');
        const currentTimeMinus1Minute = moment(currentTime, 'DDHHmm').subtract(1, 'm').format('DDHHmm');

        // filter entities from data older 2 min
        const currentTimeMinus2Minute = moment(currentTime, 'DDHHmm').subtract(2, 'm').format('DDHHmm');
        this.sources.load.next(
            this.sources.load.value
                .filter((entitiesPack) => {
                    const data = entitiesPack.params.substring(entitiesPack.params.length - 6);
                    return data === currentTime || data === currentTimeMinus1Minute || data === currentTimeMinus2Minute
                        ? entitiesPack
                        : null;
                })
                .filter((e) => e)
        );

        // search from existed values
        const findAppropriateParams = this.sources.load.value.find(
            (entitiesPack) =>
                entitiesPack.params === currentParams + currentTime ||
                entitiesPack.params === currentParams + currentTimeMinus1Minute
        );

        if (findAppropriateParams) {
            return this.sources.load;
        }

        // when entities is absent or params timestamp is expired
        // save default options
        this.sources.load.next(this.sources.load.value.concat({params: paramString, entities: [], load: true}));

        // if not - upload
        const newEntities = await this.searchEntities(
            // delete everything after last '}'
            JSON.parse(paramString.replace(/[^}]*[\d]+$/, ''))
        );

        // add or update values to source
        const findDefaultPayloadVariantIndex = this.sources.load.value.findIndex(
            (payload) => payload.params === paramString
        );

        const newState: IEntitiesPack[] = Object.assign([], this.sources.load.value);
        if (findDefaultPayloadVariantIndex > -1) {
            // update
            newState[findDefaultPayloadVariantIndex] = {params: paramString, entities: newEntities, load: false};
            this.sources.load.next(newState);
        } else {
            // add
            this.sources.load.next(
                this.sources.load.value.concat({params: paramString, entities: newEntities, load: false})
            );
        }

        return this.sources.load;
    }

    searchEntities(data: {
        filters: {
            contact_metatype: 'agent' | 'customer' | 'vendor' | null;
            contact_person_main: number;
            contact_with_partner_company: number;
            contact_without_partner_company: number;
            excludeMembersFromGroup: number | string;
            isContactNeeded: number;
            query_string: string;
            sub_company_ids: number[];
            types: string[];
        };
        params: {limit: number; offset: number};
    }): Promise<ISearchEntity[]> {
        return this.requester.makeMsCall$(`${this.url}`, 'POST', 'shipp', data, {}, true).toPromise();
    }
}
