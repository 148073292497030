import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {RoutingHistoryService} from './services/routing-history.service';
import {Meta, Title} from '@angular/platform-browser';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {environment} from 'environments/environment';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {TimeoutWarningDialogComponent} from './modules/auth/timeout-warning-dialog/timeout-warning-dialog.component';
import {AuthService} from './services/auth.service';
import {SkyslopeAuth} from './modules/auth/services/okta/skyslope-auth.service';
import {jwtDecode} from 'jwt-decode';
import {IdleUserService, IdleMessages, IdleStatus} from './services/idle-user.service';
import {Keepalive} from '@ng-idle/keepalive';

const closeIcon = '<i class="material-icons">close</i>';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    protected secondsBeforeTokenExpiration: number = 600;
    protected sessionCheckIntervalSeconds: number = 60;

    private IS_DEBUG_MODE = false;
    private unsubscribe: Subject<void> = new Subject();
    options = {
        timeOut: 15000,
        pauseOnHover: true,
        clickIconToClose: true,
        icons: {
            alert: closeIcon,
            error: closeIcon,
            info: closeIcon,
            warn: closeIcon,
            success: closeIcon
        }
    };

    constructor(
        private router: Router,
        protected routingHistoryService: RoutingHistoryService,
        protected titleService: Title,
        private meta: Meta,
        private dialog: MatDialog,
        private authService: AuthService,
        private skyslopeAuthService: SkyslopeAuth,
        private idleUserService: IdleUserService,
        private keepalive: Keepalive
    ) {}

    async ngOnInit() {
        // Check domain for legacy domainf and reroute if necessary
        if (
            environment.legacyHostname !== null &&
            window.location.hostname !== 'localhost' &&
            window.location.hostname === environment.legacyHostname
        ) {
            window.location.href = `${environment.barePageURL}${window.location.pathname}${window.location.search}`;
        }

        // temporary code for backward compatibility
        // needed to reload route on same url navigation
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };

        const loader = document.querySelector('#load-container') as HTMLElement;
        if (loader) {
            loader.style.display = 'none';
        }

        // temporary code for backward compatibility
        // needed to redirect "/#/something" to "/something"
        this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (!!event.url && event.url.match(/^\/#/)) {
                    this.router.navigate([event.url.replace('/#', '')]);
                }
            }

            if (event instanceof NavigationEnd) {
                const title = this.getTitle(this.router.routerState, this.router.routerState.root);

                if (title) {
                    this.titleService.setTitle(`${title} | SkySlope Books`);

                    this.meta.updateTag({property: 'og:url', content: `${environment.barePageURL}${this.router.url}`});
                    this.meta.updateTag({property: 'og:title', content: `${title} | SkySlope Books`});
                    this.meta.updateTag({property: 'og:description', content: title});
                } else {
                    this.titleService.setTitle(`SkySlope Books`);
                }
            }

            if (event instanceof NavigationError) {
                console.error(event.error);
            }
        });

        this.routingHistoryService.startSavingHistory();

        if (!window.location.pathname.includes('login')) {
            this.setupSessionWatching();

            this.skyslopeAuthService.isAuthenticated().then(async (isAuthenticated) => {
                if (isAuthenticated) {
                    const intervalID = setInterval(
                        this.evaluateOktaSession,
                        this.sessionCheckIntervalSeconds * 1000,
                        this.skyslopeAuthService,
                        this.authService,
                        this.secondsBeforeTokenExpiration
                    );
                }
            });
        }
    }

    // use to find undestroyed components
    // go() {
    //     this.router.navigate(['**']);
    // }

    setupSessionWatching() {
        this.idleUserService.idle.onIdleStart.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            if (this.authService.isLoggedIn()) {
                this.openTimeoutWarningDialog();
            }
            this.IS_DEBUG_MODE && console.info('@@' + IdleMessages.IDLE_STARTED);
        });

        this.idleUserService.idle.onInterrupt.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            localStorage.setItem('idleState', IdleStatus.IDLE_INTERRUPTED);
            this.IS_DEBUG_MODE && console.info('@@' + IdleMessages.IDLE_INTERRUPTED);
        });

        this.idleUserService.idle.onIdleEnd.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.closeTimeoutWarningDialog();
            this.IS_DEBUG_MODE && console.info('@@@' + IdleMessages.IDLE_END);
            localStorage.setItem('idleState', IdleStatus.IDLE_USER_ACTIVE);
        });

        this.idleUserService.idle.onTimeoutWarning.pipe(takeUntil(this.unsubscribe)).subscribe((countdown) => {
            const message = IdleMessages.IDLE_TIMEOUT_WARNING.replace('%time%', countdown.toString());
            this.IS_DEBUG_MODE && console.info('@@' + message);
            localStorage.setItem('idleState', IdleStatus.IDLE_TIMEOUT_STARTED);
        });

        this.idleUserService.idle.onTimeout.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            if (this.authService.isLoggedIn()) {
                this.logoutUser();
            }
        });

        // Set keepalive interval to 15 seconds
        this.keepalive.interval(15);

        this.idleUserService.startWatching();
    }

    getTitle(state: any, parent: any) {
        let title = '';

        if (parent && parent.snapshot.data && parent.snapshot.data.title) {
            title = parent.snapshot.data.title;
        }

        if (state && parent) {
            const childTitle = this.getTitle(state, state.firstChild(parent));

            if (childTitle) {
                title = childTitle;
            }
        }

        return title;
    }

    async evaluateOktaSession(
        skyslopeAuthService: SkyslopeAuth,
        authService: AuthService,
        secondsBeforeTokenExpiration: number
    ) {
        const token = await skyslopeAuthService.getAccessToken();
        if (!token) {
            return null;
        }
        const decodedToken = jwtDecode(token!);
        const currentTime = Math.floor(Date.now() / 1000);
        const checkTime: number =
            decodedToken && decodedToken.exp ? decodedToken.exp - secondsBeforeTokenExpiration : 0;
        if (decodedToken && decodedToken.exp && currentTime >= checkTime) {
            console.info('refreshing okta token...');
            await skyslopeAuthService.refreshAccessToken();
        }
    }

    openTimeoutWarningDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        this.dialog.open(TimeoutWarningDialogComponent, dialogConfig);
    }

    closeTimeoutWarningDialog() {
        this.dialog.closeAll();
    }

    async logoutUser() {
        await this.authService.logout();
        await this.skyslopeAuthService.logout();
        if (environment.env === 'production') {
            window.location.replace(environment.marketingPageLogin);
        } else {
            window.location.replace(`${window.location.origin}${environment.unauthorizedUserRedirectPath}`);
        }
    }

    ngOnDestroy() {
        this.idleUserService.stopWatching();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
