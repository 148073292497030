import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {UiModsSource} from '../ui-mods.source';
import {UiModsDealBadgeOnPageModel, UiModsDealBadgeInListModel} from '../models/ui-mods.model';
import {UiModsApi} from '../api/ui-mods.api';
import {takeUntil} from 'rxjs/operators';
import {NotificationsServiceZipi} from '../../notifications/notifications.service';
import {MatMenuPanel} from '@angular/material/menu';

@Component({
    selector: 'app-ui-mod-deal-badges-on-page',
    styles: [
        `
            .ui-mod-deal-badge-container {
                padding: 3px 7px;
                display: flex;
                justify-content: flex-end;
            }
            .ui-mod-deal-badge {
                height: 20px;
                width: 20px;
                margin-right: 3px;
            }
        `
    ],
    template: `
        <div class="ui-mod-deal-badge-container" *ngIf="badges.length">
            <ng-container *ngFor="let badge of badges">
                <ng-container *ngIf="badge.on_click.action === 'dropdown_menu'">
                    <mat-menu #appMenu="matMenu">
                        <button
                            type="button"
                            mat-menu-item
                            *ngFor="let menu_option of badge.on_click.dropdownMenu.menu_options"
                            (click)="menuClick(menu_option, badge)"
                        >
                            {{ menu_option.label }}
                        </button>
                    </mat-menu>
                    <button
                        type="button"
                        mat-icon-button
                        class="ui-mod-deal-badge"
                        [matTooltip]="badge.hint || ''"
                        [ngStyle]="badge.style"
                        [matMenuTriggerFor]="appMenu"
                    ></button>
                </ng-container>
                <ng-container *ngIf="badge.on_click.action !== 'dropdown_menu'">
                    <button
                        type="button"
                        mat-icon-button
                        class="ui-mod-deal-badge"
                        [matTooltip]="badge.hint || ''"
                        [ngStyle]="badge.style"
                        (click)="click(badge)"
                    ></button>
                </ng-container>
            </ng-container>
        </div>
    `
})
export class DealBadgesOnPageComponent implements OnDestroy, OnInit {
    private unsubscribe: Subject<void> = new Subject();

    @Input() dealId: number | null = null;
    @Input() onlySpecificAddons: string[] = [];

    public badges: UiModsDealBadgeOnPageModel[] = [];
    public listBadges: UiModsDealBadgeInListModel[] = [];
    public appMenu: MatMenuPanel | undefined = undefined; // workaround for TSLint;
    private subscriptionPageBadges: Subscription | null = null;
    private subscriptionListBadges: Subscription | null = null;

    constructor(
        public uiModsSource: UiModsSource,
        public uiModsApi: UiModsApi,
        public notificationService: NotificationsServiceZipi
    ) {}

    ngOnInit() {
        this.subscriptionListBadges = this.uiModsSource.dealBadgesInList
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((badges) => {
                this.listBadges = badges
                    .filter((badge) => badge.deal_id === this.dealId)
                    .filter(
                        (badge) =>
                            this.onlySpecificAddons.length === 0 ||
                            this.onlySpecificAddons.includes(badge.uiMod?.addon.slug)
                    );

                // if we have LIST BADGE - do not show PAGE BADGE
                this.badges = this.badges.filter((badge) => {
                    const hasListBadge = this.hasListBadge(badge);
                    return !hasListBadge;
                });
            });

        this.subscriptionPageBadges = this.uiModsSource.dealBadgesOnPage
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((badges) => {
                this.badges = badges
                    // if we have LIST BADGE - do not show PAGE BADGE
                    .filter((badge) => {
                        const hasListBadge = this.hasListBadge(badge);
                        return !hasListBadge;
                    })
                    .filter(
                        (badge) =>
                            this.onlySpecificAddons.length === 0 ||
                            this.onlySpecificAddons.includes(badge.uiMod?.addon.slug)
                    )
                    .map((badge) => {
                        const preset = badge.uiMod ? badge.uiMod.preset : {};
                        const res: UiModsDealBadgeOnPageModel = Object.assign(new UiModsDealBadgeOnPageModel(), preset);
                        res.id = badge.id;
                        res.uiMod = badge.uiMod;
                        if (badge.style) {
                            res.style = badge.style;
                        }
                        if (badge.on_click) {
                            res.on_click = badge.on_click;
                        }
                        return res;
                    });
            });
    }

    ngOnDestroy() {
        this.subscriptionPageBadges!.unsubscribe();
        this.subscriptionListBadges!.unsubscribe();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    click(badge: UiModsDealBadgeOnPageModel) {
        if (badge.clickable && badge.id) {
            this.uiModsApi.uiModClicked(badge.id);
        }
    }

    menuClick(menu_option: any, badge: UiModsDealBadgeOnPageModel) {
        if (this.dealId && badge.clickable && badge.uiMod && badge.id) {
            const redirectParams: {[key: string]: any} = {
                deal_id: this.dealId
            };
            switch (menu_option.on_click.action) {
                case 'open_new_tab':
                    window.open(
                        badge.uiMod.addon.addon_url +
                            menu_option.on_click.path +
                            '?' +
                            Object.keys(redirectParams)
                                .map((key) => key + '=' + encodeURIComponent(redirectParams[key]))
                                .join('&')
                    );

                    break;
                case 'redirect_user':
                    window.location.href =
                        badge.uiMod.addon.addon_url +
                        menu_option.on_click.path +
                        '?' +
                        Object.keys(redirectParams)
                            .map((key) => key + '=' + encodeURIComponent(redirectParams[key]))
                            .join('&');

                    break;
                case 'request_from_backend':
                    this.uiModsApi.uiModOptionClicked(badge.id, menu_option.on_click);
                    break;
                case 'inner_request':
                    return this.uiModsApi
                        .uiModsInnerRequest(badge.id, menu_option.on_click.path, this.dealId)
                        .then(() => {
                            this.notificationService.addInfo('Success');
                        });
                default:
                    throw new Error('Unknown action');
            }
        }
    }

    hasListBadge(badge: UiModsDealBadgeOnPageModel) {
        return this.listBadges.some(
            (b) => b.uiMod && badge.uiMod && b.uiMod.addon.slug === badge.uiMod.addon.slug && b.deal_id === this.dealId
        );
    }
}
