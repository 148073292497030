<div mat-dialog-title>
    <h3>Specify Account</h3>
</div>

<mat-dialog-content *ngIf="accountForm" [formGroup]="accountForm">
    <p>Please specify Ledger Account type.</p>
    <div class="d-flex w-100">
        <mat-form-field class="">
            <mat-select formControlName="currentAccount" class="w-100" placeholder="Ledger Account">
                <mat-option *ngFor="let acc of ledgerAccounts" [value]="acc.slug">{{ acc.title }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div>
        <button mat-raised-button color="accent" (click)="apply()" tabindex="1">Apply</button>
        <button mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">Cancel</button>
    </div>
</mat-dialog-actions>
