import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {NotificationsService} from 'angular2-notifications';
import {takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {UsersAndLicencesApiService} from '../../../../account-info/users-licenses/users-and-licences.api.service';
import {GenericFormArray} from '../../../../../entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {ITransactionExternal, LedgerAccountTypes} from '@cyberco-nodejs/zipi-typings';
import {ContactCreateDialogComponent} from '../../../../contacts/contact-dialogs/contact-create-dialog/contact-create-dialog.component';
import * as moment from 'moment';
import {amountValidator} from '../../../../../utilities';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';

export class CustomFormGroup extends UntypedFormGroup {
    public controls: {
        reference: UntypedFormControl;
        date: UntypedFormControl;
        amount: UntypedFormControl;
        paid_by__ledger_account_fk_id: UntypedFormControl;
        customer__contact_fk_id: UntypedFormControl;
    };

    constructor(controls: {
        reference: UntypedFormControl;
        date: UntypedFormControl;
        amount: UntypedFormControl;
        paid_by__ledger_account_fk_id: UntypedFormControl;
        customer__contact_fk_id: UntypedFormControl;
    }) {
        super(controls);
        this.controls = controls;
    }
}

@Component({
    selector: 'app-form-other-deposit',
    template: `
        <div [formGroup]="formGroup">
            <app-ledger-account-selector
                [ledgerAccountControl]="formGroup.controls.paid_by__ledger_account_fk_id"
                [placeholder]="'From Account'"
                [types]="types"
            >
            </app-ledger-account-selector>

            <app-date-picker [placeholder]="'Date'" [dateControl]="formGroup.controls.date"></app-date-picker>

            <mat-form-field class="w-100">
                <input matInput formControlName="reference" placeholder="Reference#" type="text" autocomplete="off" />
            </mat-form-field>

            <mat-form-field class="w-100">
                <mat-label>Amount</mat-label>
                <input
                    [maskito]="currencyMaskitoMask"
                    matInput
                    type="text"
                    formControlName="amount"
                    matTooltipPosition="above"
                    placeholder="Amount"
                    autocomplete="off"
                    required
                />
            </mat-form-field>

            <div class="d-flex align-items-baseline">
                <app-company-compensation-combined-picker
                    style="width:100%; margin-right: 8px;"
                    [availableTypes]="['contact']"
                    [title]="'Select Customer'"
                    [only_compensation_groups]="false"
                    [singleSelected]="true"
                    [bold_style]="false"
                    [nodesFA]="moneySenderCtrlArr"
                >
                </app-company-compensation-combined-picker>
                <button type="button" mat-icon-button matSuffix (click)="contactCreate()" matTooltip="Create Contact">
                    <mat-icon>person_add</mat-icon>
                </button>
            </div>
        </div>
        <div class="footer-row">
            <button
                mat-raised-button
                class="mat-primary"
                [disabled]="!formGroup.valid || createDisabled || !ledgerAccountId"
                (click)="save()"
            >
                Create
            </button>
            <button mat-button class="mat-primary" (click)="closeSidebar.emit()">Cancel</button>
        </div>
    `
})
export class FormOtherDepositComponent implements OnInit, OnDestroy {
    @Output() closeSidebar: EventEmitter<void> = new EventEmitter<void>();
    @Output() create: EventEmitter<object> = new EventEmitter<object>();
    @Input() createDisabled: boolean = false;
    @Input() bankTransaction: ITransactionExternal | undefined;
    @Input() ledgerAccountId: number | null = null;

    private unsubscribe: Subject<void> = new Subject();

    formGroup: CustomFormGroup = new CustomFormGroup({
        reference: new UntypedFormControl(null, []),
        date: new UntypedFormControl(null, Validators.required),
        amount: new UntypedFormControl(null, [Validators.required, amountValidator()]),

        paid_by__ledger_account_fk_id: new UntypedFormControl(null, Validators.required),
        customer__contact_fk_id: new UntypedFormControl(null)
    });
    initFormGroupSnapshot = {};
    currencyMaskitoMask = currencyMaskitoOptions;

    profiles = [];
    types: LedgerAccountTypes[] = [
        'other_current_asset',
        'cash',
        'fixed_asset',
        'common_stock',
        'other_current_liability',
        'long_term_liability',
        'equity',
        'income'
    ];

    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);

    constructor(
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService,
        public dialog: MatDialog,
        public usersAndLicencesApiService: UsersAndLicencesApiService
    ) {}

    ngOnInit() {
        if (this.bankTransaction) {
            this.formGroup.controls.amount.patchValue(this.bankTransaction.amount);
            this.formGroup.controls.amount.disable();
            this.formGroup.controls.date.patchValue(this.bankTransaction.date);
        } else {
            this.formGroup.controls.date.patchValue(moment().format('YYYYMMDD'));
        }

        this.initFormGroupSnapshot = this.formGroup.getRawValue();

        this.moneySenderCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            if (values && values.length > 0) {
                // this.selectMoneySender(values[0].target_id);
                this.formGroup.controls.customer__contact_fk_id.patchValue(values[0].target_id);
            } else {
                // this.deselectCustomer();
                this.formGroup.controls.customer__contact_fk_id.patchValue(null);
            }
        });
    }

    private validateForm() {
        if (!this.formGroup.valid) {
            this.ntfs.warn('Other deposit template is not valid');
            return false;
        }

        return true;
    }

    save() {
        if (!this.validateForm() || this.createDisabled) {
            return;
        }
        const dataRaw = this.formGroup.getRawValue();

        this.create.emit({
            date: dataRaw.date,
            reference: dataRaw.reference,
            amount: Number(cleanCurrencyString(dataRaw.amount)),

            customer__contact_fk_id: dataRaw.customer__contact_fk_id,
            paid_by__ledger_account_fk_id: dataRaw.paid_by__ledger_account_fk_id,
            bank_ledger_account_id: this.ledgerAccountId
        });
    }

    contactCreate() {
        const dialogRef = this.dialog.open(ContactCreateDialogComponent, {
            autoFocus: false,
            data: {category: 'customer'}
        });

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.closeSidebar.complete();
        this.create.complete();
    }
}
