import {Component, OnInit, Input, OnDestroy, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {IExpense} from '@cyberco-nodejs/zipi-typings';
import {takeUntil} from 'rxjs/operators';
import {ExpensesService} from '../../../../finance/services/expenses.service';
import {MatPaginator} from '@angular/material/paginator';
import {IScrollData} from 'app/models/scroll-data';
import {PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

@Component({
    selector: 'app-contact-expenses',
    templateUrl: 'contact-expenses.component.html',
    styleUrls: ['../../../../../../assets/infinite-scroll-table.scss']
})
export class ContactExpensesComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeExpenses: Subject<void> = new Subject();

    @Input() contactId: number | undefined | null;

    @ViewChild(MatSort, {static: false}) sort: MatSort | null = null;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | null = null;

    expenses: IExpense[] = [];
    showMore: boolean = true;
    isLoaded: boolean = false;

    scrollData: IScrollData = {
        offset: 0,
        limit: 50,
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<IExpense>;
    displayedColumns = ['expense_date', 'expense_account', 'paid_through', 'amount'];

    constructor(private expensesService: ExpensesService) {
        this.dataSource = new MatTableDataSource<IExpense>([]);
    }

    ngOnInit() {
        this.dataSource.sort = this.sort;

        this.nextBatch();

        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.nextBatch();
            });
        }
    }

    nextBatch() {
        this.unsubscribeExpenses.next();

        this.expensesService
            .getContactExpenses(Number(this.contactId), this.scrollData)
            .pipe(takeUntil(this.unsubscribeExpenses))
            .subscribe((data) => {
                this.expenses = data.result;
                this.dataSource.data = data.result;
                this.scrollData.total = data._meta.total;
                this.isLoaded = true;
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeExpenses.next();
        this.unsubscribeExpenses.complete();
    }
}
