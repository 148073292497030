import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GenericFormArray} from '../../../../entites/generic.entity';
import {ChipNode} from '../../compensation/models/chip-node';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

interface IDialogData {
    addToDivision: boolean;
}

@Component({
    selector: 'app-add-to-group-dialog',
    templateUrl: './add-to-group-dialog.component.html'
})
export class AddToGroupDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    addToDivision: boolean = false;
    selectedGroupsFA: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    groupId: number | null = null;

    constructor(
        public dialogRef: MatDialogRef<AddToGroupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData
    ) {}

    ngOnInit() {
        this.addToDivision = this.data.addToDivision;

        this.selectedGroupsFA.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((chipNodes) => {
            if (chipNodes.length) {
                this.groupId = chipNodes[0].target_id;
            } else {
                this.groupId = null;
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
