<section class="container-fluid mt-3" *ngIf="payment">
    <div class="d-flex w-100 justify-content-end py-2">
        <button
            mat-icon-button
            color="primary"
            matTooltip="Refresh Payment Status"
            [rule]="{sales__manage_payments_received: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            *ngIf="
                payment.summary_status === 'processing' &&
                payment.pay_to_payment_method &&
                payment.pay_to_payment_method.payment_gateway &&
                payment.pay_to_payment_method.payment_gateway.type === 'authorize_net_merchant'
            "
            [disabled]="!payment || !payment.payment_received_id"
            (click)="$event.stopPropagation(); recheckPaymentStatus(payment.payment_received_id!)"
        >
            <mat-icon>cached</mat-icon>
        </button>

        <div
            class="ml-2"
            matTooltip="You cannot modify Payment Received associated with Deal."
            [matTooltipDisabled]="!!payment && !payment.source__deal_fk_id"
        >
            <button
                mat-raised-button
                type="button"
                [rule]="{sales__manage_payments_received: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                (click)="router.navigate(['sales/paymentsreceived/edit', payment.payment_received_id])"
                [disabled]="
                    ['expired', 'declined', 'reversed', 'canceled', 'error'].includes(payment.summary_status) ||
                    (payment && !!payment.source__deal_fk_id)
                "
            >
                Edit Payment
            </button>
        </div>

        <div
            [matTooltip]="
                !['authorize', 'zipi_pay', 'zipi_financial', 'zipi_financial_card'].includes($any(payment.payment_mode))
                    ? 'Available only for Money Transfers (EFT) and Authorize.Net'
                    : payment.summary_status !== 'completed'
                      ? 'Payment must be completed.'
                      : ['authorize'].includes($any(payment.payment_mode)) &&
                          daysFromCreation &&
                          daysFromCreation >= 120
                        ? 'Cannot be refunded. The original transaction was created over 120 days ago.'
                        : ['zipi_financial_card'].includes($any(payment.payment_mode)) &&
                            daysFromCreation &&
                            daysFromCreation >= 45
                          ? 'Cannot be refunded. The original transaction was created over 45 days ago.'
                          : ''
            "
            [matTooltipDisabled]="
                ['authorize', 'zipi_pay', 'zipi_financial', 'zipi_financial_card'].includes(
                    $any(payment.payment_mode)
                ) &&
                payment.summary_status === 'completed' &&
                ((['authorize'].includes($any(payment.payment_mode)) && !!daysFromCreation && daysFromCreation < 120) ||
                    (['zipi_financial_card'].includes($any(payment.payment_mode)) &&
                        !!daysFromCreation &&
                        daysFromCreation < 45))
            "
        >
            <button
                mat-raised-button
                [rule]="{sales__manage_payments_received: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                type="button"
                [disabled]="
                    !['authorize', 'zipi_pay', 'zipi_financial', 'zipi_financial_card'].includes(
                        $any(payment.payment_mode)
                    ) ||
                    payment.summary_status !== 'completed' ||
                    (['authorize'].includes($any(payment.payment_mode)) &&
                        !!daysFromCreation &&
                        daysFromCreation >= 120) ||
                    (['zipi_financial_card'].includes($any(payment.payment_mode)) &&
                        !!daysFromCreation &&
                        daysFromCreation >= 45)
                "
                (click)="refundPayment()"
                class="ml-2"
            >
                Refund Payment
            </button>
        </div>

        <button
            mat-stroked-button
            color="primary"
            class="ml-2"
            *ngIf="
                (payment.payment_mode === 'zipi_financial' || payment.payment_mode === 'zipi_financial_card') &&
                payment.summary_status === 'processing' &&
                isVoidAllowed
            "
            (click)="voidPayment()"
        >
            Void
        </button>

        <!--        <button mat-raised-button type="button"-->
        <!--                (click)="refundPayment()" class="ml-2">-->
        <!--            Refund Payment-->
        <!--        </button>-->

        <!--        <button mat-raised-button type="button" (click)="downloadPaymentPdf()">-->
        <!--            Download PDF-->
        <!--        </button>-->
    </div>
    <mat-card class="p-5">
        <div class="mb-3">
            History
            <button mat-icon-button (click)="showHistory = !showHistory; loadHistory()">
                <mat-icon *ngIf="showHistory">expand_more</mat-icon>
                <mat-icon *ngIf="!showHistory">chevron_right</mat-icon>
            </button>

            <div
                *ngIf="showHistory && logsLoaded && paymentReceivedLogs.length > 0"
                style="
                    max-height: 200px;
                    overflow-y: scroll;
                    overflow-x: hidden;
                    background-color: lightgray;
                    font-size: 12px;
                    padding: 10px;
                "
            >
                <div *ngFor="let log of paymentReceivedLogs" class="d-flex">
                    <div style="width: 100px; margin-right: 50px" class="font-weight-bold" *ngIf="log.action_timestamp">
                        {{ log.action_timestamp | amDateFormat: 'MM/DD/YYYY HH:mm' }}
                    </div>
                    <div
                        style="width: 100px; margin-right: 50px"
                        class="font-weight-bold"
                        *ngIf="!log.action_timestamp"
                    >
                        N/A
                    </div>

                    <div>{{ log.description }}</div>
                </div>
            </div>

            <div
                *ngIf="showHistory && logsLoaded && paymentReceivedLogs.length === 0"
                style="
                    max-height: 200px;
                    overflow-y: scroll;
                    overflow-x: hidden;
                    background-color: lightgray;
                    font-size: 12px;
                    padding: 10px;
                "
            >
                <div class="d-flex w-100">
                    <div class="w-15">Date</div>
                    <div class="w-15">Activity</div>
                    <div class="w-15">Status</div>
                    <div class="w-15" style="text-align: end">Total Amount</div>
                </div>

                <div class="d-flex w-100">
                    <div class="w-15">
                        <div
                            style="width: 100px; margin-right: 50px"
                            class="font-weight-bold"
                            *ngIf="payment.created_at"
                        >
                            {{ $any(payment.created_at) | amDateFormat: 'MM/DD/YYYY HH:mm' }}
                        </div>
                        <div
                            style="width: 100px; margin-right: 50px"
                            class="font-weight-bold"
                            *ngIf="!payment.created_at"
                        >
                            N/A
                        </div>
                    </div>
                    <div class="w-15">Payment</div>
                    <div class="w-15">{{ payment.summary_status | fromSnakeCase }}</div>
                    <div class="w-15" style="text-align: end">{{ payment.amount | currency }}</div>
                </div>

                <div *ngFor="let log of paymentReceivedOldLogs" class="d-flex">
                    <ng-container>
                        <div class="w-15">
                            <div
                                style="width: 100px; margin-right: 50px"
                                class="font-weight-bold"
                                *ngIf="log.created_at"
                            >
                                {{ log.created_at | amDateFormat: 'MM/DD/YYYY HH:mm' }}
                            </div>
                            <div
                                style="width: 100px; margin-right: 50px"
                                class="font-weight-bold"
                                *ngIf="!log.created_at"
                            >
                                N/A
                            </div>
                        </div>
                        <div class="w-15">Refund</div>
                        <div class="w-15">
                            {{ log.status ? (log.status | fromSnakeCase) : (log.summary_status | fromSnakeCase) }}
                        </div>
                        <div class="w-15" style="color: red; text-align: end">-{{ log.amount | currency }}</div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="d-flex status-area justify-content-end flex-wrap">
            <div class="status" [ngStyle]="{'background-color': statusColor[payment.summary_status]}">
                {{ payment.summary_status | fromSnakeCase }}
            </div>
            <div class="flex-row">
                <a
                    [routerLink]="['/deals/edit', deductDealId]"
                    *ngIf="payment?.payment_mode === 'deduction' && deductDealId"
                    class="mr-1"
                    style="margin-top: 2px"
                >
                    Payment is Pending Closing Payout from Deal
                </a>
            </div>
        </div>

        <header class="d-flex justify-content-end">
            <!--            <div class="col-6 zp-company-info">-->
            <!--                <p class="zp-company-title">{{payment.money_sender_company?.title}}</p>-->
            <!--                <div *ngFor="let location of payment.money_sender_company?.locations">-->
            <!--                    <p *ngIf="location.label === payment.money_sender_company?.settings?.billing_address_label">-->
            <!--                        <span *ngIf="location.street_number">{{location.street_number}} </span>-->
            <!--                        <span *ngIf="location.street_address">{{location.street_address}} </span>-->
            <!--                        <span *ngIf="location.unit_number">{{location.unit_number}} </span>-->
            <!--                        <br>-->
            <!--                        <span *ngIf="location.city">{{location.city}}, </span>-->
            <!--                        <span *ngIf="location.state">{{location.state}} </span>-->
            <!--                        <span *ngIf="location.zip">{{location.zip}} </span>-->
            <!--                        <br>-->
            <!--                        <span *ngIf="location.country" class="text-muted">({{location.country}})</span>-->
            <!--                    </p>-->
            <!--                </div>-->
            <!--            </div>-->

            <div class="w-50 text-right">
                <p class="zp-payment_title">Payment Receipt</p>

                <p class="zp-refference_due_title">Amount</p>
                <p class="zp-refference_due_value" style="margin-bottom: 15px">
                    {{ payment?.amount! - completedRefunds! | currency }}
                </p>
            </div>
        </header>

        <p *ngIf="payment.money_sender_contact || payment.related_payments?.length !== 0">Payment Made By:</p>
        <div class="row" style="margin-bottom: 15px">
            <div class="col-3">
                <div class="mb-0">
                    <span
                        *ngIf="payment.money_sender_contact?.display_name && payment.money_sender_contact?.hidden"
                        class="hidden-contact"
                    >
                        {{ payment.money_sender_contact?.display_name }}<br />
                    </span>

                    <a
                        *ngIf="payment.money_sender_contact?.display_name && !payment.money_sender_contact?.hidden"
                        routerLink="/contacts/{{ payment.money_sender_contact?.contact_id }}"
                    >
                        {{ payment.money_sender_contact?.display_name }}<br />
                    </a>
                </div>
            </div>
            <div class="col-9 text-right">
                <p class="mb-2">
                    <span>Payment Date:</span>
                    <span class="zp-w-300">
                        {{
                            payment.paid_date
                                ? (payment.paid_date | dateFromNumber | date: 'longDate')
                                : (payment.created_at | date: 'longDate')
                        }}
                    </span>
                </p>

                <p class="mb-2">
                    <span>Payment #:</span>
                    <span class="zp-w-300">{{ payment.payment_received_number }}</span>
                </p>

                <p class="mb-2">
                    <span>Payment Mode:</span>
                    <span class="zp-w-300">{{ paymentModesMap[payment?.payment_mode!] }}</span>
                </p>

                <!--                <p class="mb-2">-->
                <!--                    <span>Payment Details:</span>-->
                <!--                    <span class="zp-w-300"-->
                <!--                          *ngIf="payment?.related_payments.length-->
                <!--                          && payment?.paid_by_payment_method?.title-->
                <!--                          && payment?.paid_by_payment_method?.title !== 'Cash'-->
                <!--                          && payment?.paid_by_payment_method?.title !== 'Check'">-->
                <!--                        {{payment?.paid_by_payment_method?.title}}-->
                <!--                    </span>-->
                <!--                    <span class="zp-w-300"-->
                <!--                          *ngIf="(!payment?.related_payments.length-->
                <!--                          || !payment?.paid_by_payment_method?.title-->
                <!--                          || payment?.paid_by_payment_method?.title === 'Cash'-->
                <!--                          || payment?.paid_by_payment_method?.title === 'Check')-->
                <!--                          && !relatedDeal">-->
                <!--                        N/A-->
                <!--                    </span>-->
                <!--                </p>-->
                <ng-container
                    *ngIf="
                        (payment.payment_mode === 'check' || payment.payment_mode === 'check_record') &&
                        !!payment.check_info
                    "
                >
                    <p class="mb-2">
                        <span>Check Number:</span>
                        <span class="zp-w-300">{{ payment?.check_info?.check_number }}</span>
                    </p>
                    <p class="mb-2">
                        <span>Memo:</span>
                        <span class="zp-w-300">{{ payment?.check_info?.memo }}</span>
                    </p>
                </ng-container>
                <p class="mb-2" *ngIf="payment.notes">
                    <span>Notes:</span>
                    <span class="zp-w-300">
                        {{ payment?.notes }}
                    </span>
                </p>
                <p class="mb-2" *ngIf="payment.reference">
                    <span>Reference:</span>
                    <span class="zp-w-300">
                        {{ payment?.reference }}
                    </span>
                </p>
                <ng-container *ngIf="payment.payment_mode === 'check' && !!payment.check_info">
                    <p class="mb-2" *ngIf="payment.check_info.check_number">
                        <span>Check Number:</span>
                        <span class="zp-w-300">{{ payment?.check_info?.check_number }}</span>
                    </p>
                    <p class="mb-2" *ngIf="payment.check_info.memo">
                        <span>Memo:</span>
                        <span class="zp-w-300">{{ payment?.check_info?.memo }}</span>
                    </p>
                </ng-container>
            </div>
        </div>

        <div class="d-flex justify-content-end">
            <div class="w-50">
                <div style="text-align: end" *ngIf="payment.fee_amount">
                    Processing Fee: {{ payment.fee_amount | currency }}
                </div>
                <div style="text-align: end" class="font-weight-bold">
                    Applied to Invoices: {{ appliedToInvoices | currency }}
                </div>
                <div
                    style="text-align: end; color: #808080ff; font-size: 14px"
                    *ngIf="appliedToInvoices !== pendingBalance"
                >
                    Pending Payment: {{ pendingBalance | currency }}
                </div>
                <div
                    style="text-align: end"
                    class="font-weight-bold"
                    *ngIf="!!unapplied || unapplied !== pendingBalanceForUnapplied"
                >
                    Unapplied (Credits): {{ unapplied | currency }}
                </div>
                <div
                    style="text-align: end; color: #808080ff; font-size: 14px"
                    *ngIf="unapplied !== pendingBalanceForUnapplied"
                >
                    Pending Payment: {{ pendingBalanceForUnapplied | currency }}
                </div>
            </div>
        </div>

        <div class="w-35">
            <div *ngFor="let relatedPayment of payment.related_payments">
                <ng-container *ngIf="relatedPayment.invoice">
                    <div class="d-flex">
                        <a
                            href="/sales/invoices/{{ relatedPayment.invoice.invoice_id }}"
                            class="invoice-link w-30"
                            routerLink="/sales/invoices/{{ relatedPayment.invoice.invoice_id }}"
                            >{{ relatedPayment.invoice.invoice_number }}:
                        </a>
                        <div class="font-weight-bold w-70">{{ relatedPayment!.applied | currency }}</div>
                    </div>
                    <div
                        class="d-flex"
                        *ngIf="
                            relatedPayment.hasOwnProperty('applied') &&
                            relatedPayment.hasOwnProperty('pending') &&
                            relatedPayment['applied'] !== relatedPayment['pending']
                        "
                    >
                        <div class="w-30"></div>
                        <div class="w-70" style="color: #808080ff; font-size: 14px">
                            Pending Payment: {{ relatedPayment['pending'] | currency }}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </mat-card>

    <div class="my-3" style="font-size: 18px">
        <div class="d-flex align-items-center">
            <div>More Information</div>
            <div class="ml-2" *ngIf="!additionalInfoLoaded"><mat-spinner [diameter]="18"></mat-spinner></div>
        </div>
        <div class="ml-4">
            <div
                *ngIf="
                    !currentDepositToAccount &&
                    additionalInfoLoaded &&
                    !payment.source__deposit_release_fk_id &&
                    (!relatedDeals || !relatedDeals.length === 0)
                "
            >
                No additional information
            </div>
            <div *ngIf="currentDepositToAccount">Deposit To: {{ currentDepositToAccount.name | fromSnakeCase }}</div>
            <div *ngIf="payment.source__deposit_release_fk_id">Type: Deal Deposit Release</div>
            <div *ngFor="let relatedDeal of relatedDeals">
                <div *ngIf="relatedDeal && relatedDeal.id">
                    Deal:
                    <a [routerLink]="['/deals/edit', relatedDeal.id]">
                        <span *ngIf="!relatedDeal.address" class="text-muted">No address </span>
                        <span *ngIf="relatedDeal.address"
                            >{{ relatedDeal.street_number }} {{ relatedDeal.address }}
                        </span>
                        — {{ relatedDeal.type }}/{{ relatedDeal.status }}
                    </a>
                </div>
            </div>
            <div *ngIf="payment.bank_operation_info && payment.bank_operation_info.total_amount">
                Part of Bank Transaction for {{ payment.bank_operation_info.total_amount | currency }}
            </div>
        </div>
    </div>
</section>
