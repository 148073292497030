import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {RecurringJournalsService} from 'app/services/api/finance/recurring-journals.service';
import {IRecurringJournal} from '@cyberco-nodejs/zipi-typings';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {IScrollData} from 'app/models/scroll-data';
import {PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-recurring-journals',
    templateUrl: 'recurring-journals.component.html',
    styleUrls: ['recurring-journals.component.scss', '../../../../../../assets/infinite-scroll-table.scss']
})
export class RecurringJournalsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator = new MatPaginator(
        new MatPaginatorIntl(),
        ChangeDetectorRef.prototype
    );

    @Input() filterHeader: number = 0;

    scrollData: IScrollData = {
        sort_column: 'recurrence_title',
        sort_direction: 'desc',
        limit: 50,
        offset: 0,
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<IRecurringJournal>;
    displayedColumns = [
        'recurrence_title',
        'frequency',
        'last_journal_date',
        'next_journal_date',
        'status',
        'amount',
        'action'
    ];

    constructor(private recurringJournalsService: RecurringJournalsService) {
        this.dataSource = new MatTableDataSource<IRecurringJournal>([]);
    }

    ngOnInit() {
        this.getJournals();
        this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.scrollData.limit = data.pageSize;
            this.scrollData.offset = data.pageSize * data.pageIndex;

            this.getJournals();
        });
    }

    getJournals() {
        this.unsubscribeBatch.next();

        this.recurringJournalsService
            .getRecurringJournals(this.scrollData)
            .pipe(takeUntil(this.unsubscribeBatch))
            .subscribe((data) => {
                this.dataSource.data = data.result.slice();
                this.scrollData.total = data._meta.total;
            });
    }

    activateRecurring(recurringJournalId: number) {
        this.recurringJournalsService
            .activateRecurringJournal(recurringJournalId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.getJournals();
            });
    }

    stopRecurring(recurringJournalId: number) {
        this.recurringJournalsService
            .stopRecurringJournal(recurringJournalId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.getJournals();
            });
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        this.paginator.pageIndex = 0;
        this.scrollData.offset = 0;
        this.getJournals();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
    }
}
