import {Company} from '../../../../models/company';
import {Group} from '../../../../models/group';
import {Profile} from '../../../../models/profile';
import {FinancialTransferEntity} from './financial-transfer.entity';

export class CommissionCategorizationModel {
    id: number | null = null;
    company: Company | null = null;
    company_group: Group | null = null;
    profile: Profile | null = null;
    title: string = '';

    money_list: FinancialTransferEntity[] = [];
}
