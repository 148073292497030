<section class="bg-white">
    <!------- Header ------->
    <header class="d-flex align-items-center px-3 list-header">
        <h3 class="m-0 mr-1 d-flex align-items-end">Credit Notes</h3>

        <mat-menu #matMenu="matMenu">
            <button
                mat-menu-item
                routerLink="/sales/sourcecredits"
                [rule]="{sales__view_source_documents: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'flex'}"
                [denyStyle]="{display: 'none'}"
            >
                Source Documents
            </button>
            <button
                mat-menu-item
                routerLink="/sales/creditnotes/recurring"
                [rule]="{sales__view_customer_credits: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'flex'}"
                [denyStyle]="{display: 'none'}"
            >
                Recurring Credits
            </button>
        </mat-menu>

        <i class="sp-spacer"></i>

        <mat-form-field class="mt-2 mr-2" [floatLabel]="'never'">
            <mat-label>Status filter</mat-label>
            <mat-select [formControl]="statusFilter" multiple>
                <mat-option
                    *ngFor="let status of statusTypes"
                    [value]="status"
                    (onSelectionChange)="changeStatusFilter($event)"
                >
                    {{ status | fromSnakeCase }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button
            class="ml-2"
            type="button"
            mat-raised-button
            color="primary"
            routerLink="/sales/creditnotes/create"
            [rule]="{sales__manage_customer_credits: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
        >
            <mat-icon>add</mat-icon>
            Create
        </button>
    </header>

    <!------- List of credit notes ------->
    <div class="table-background">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="credit_note_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Date</th>
                <td mat-cell *matCellDef="let element" class="w-10">
                    {{ element.credit_note_date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="customer_contact_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-30 pl-1">Customer</th>
                <td mat-cell *matCellDef="let element" class="w-30 px-1">
                    {{ element.customer_contact_name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="credit_note_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20 pl-1">Credit Note#</th>
                <td mat-cell *matCellDef="let element" class="w-20 px-1">
                    {{ element.credit_note_number }}
                </td>
            </ng-container>

            <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20 pl-1">Reference</th>
                <td mat-cell *matCellDef="let element" class="w-20 px-1">
                    {{ element.reference }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Status</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <span *ngIf="element.status" [ngStyle]="{color: statusColor[element.status]}">
                        {{ element.status | titlecase }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="total_amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20 table-header-align-right">Amount</th>
                <td mat-cell *matCellDef="let element" class="w-20 amount" style="padding-right: 40px">
                    {{ element.total_amount | currency }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="row.id" class="zp-table-row"></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 text-center no-items">
            There are no credit notes.
        </div>
    </div>
</section>
