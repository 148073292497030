export class User {
    constructor(firstName?: string, lastName?: string) {
        this.first_name = firstName;
        this.last_name = lastName;
    }

    id: number | undefined;
    first_name: string | undefined;
    last_name: string | undefined;
    firebase_id: string | undefined | null = null;
    connected_profiles_ids?: number[];
    email: string | null = null;
    password: string | null = null;
    is_help_button_shown: boolean | null = null;
    zipi_last_profile_id: number | null = null;
    finance__company_fk_id: number | null = null;
    last_login: string | null = null;
    okta_uid: string | null = null;
}
