import {Component, Inject, OnInit, ChangeDetectorRef, OnDestroy, ElementRef} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormBuilder} from '@angular/forms';
import {combineLatest, Subject} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';
import {IContact, IPaymentMade, IVendorCredit} from '@cyberco-nodejs/zipi-typings';
import {MatTableDataSource} from '@angular/material/table';
import {PaymentsMadeService} from '../../../finance/services/payments-made.service';
import {Router} from '@angular/router';
import {VendorCreditsService} from '../../../../services/api/finance/vendor-credits.service';

interface IDialogData {
    contact: IContact;
    excess: number;
}

@Component({
    selector: 'app-vendor-credits-details-dialog',
    templateUrl: 'vendor-credits-details-dialog.component.html'
})
export class VendorCreditsDetailsDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    dataSource: MatTableDataSource<Array<IVendorCredit | IPaymentMade>>;
    displayedColumns = ['credit_info', 'date', 'credit_number', 'total_amount', 'balance', 'actions'];
    contact: IContact | undefined;
    excess: number | undefined;

    constructor(
        public dialogRef: MatDialogRef<VendorCreditsDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData,
        private fb: UntypedFormBuilder,
        private changeDetector: ChangeDetectorRef,
        private vendorCreditsService: VendorCreditsService,
        private paymentsMadeService: PaymentsMadeService,
        protected router: Router
    ) {
        this.dataSource = new MatTableDataSource<Array<IVendorCredit | IPaymentMade>>([]);
    }

    ngOnInit() {
        this.contact = this.data.contact;
        this.excess = this.data.excess;

        if (this.contact && this.contact.id) {
            combineLatest(
                this.vendorCreditsService.getContactExcessVendorCredits(this.contact.id),
                this.paymentsMadeService.getContactOverpaymentPaymentsMade(this.contact.id)
            )
                .pipe(
                    map(([credits, payments]) => {
                        const paymentsMade: Array<IVendorCredit | IPaymentMade> = payments;
                        const vendorCredits: IVendorCredit[] = credits;

                        return paymentsMade.concat(vendorCredits);
                    }),
                    tap((data) => {
                        // @ts-ignore
                        this.dataSource.data = data;
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe();
        }
    }

    applyToBill(element: ElementRef) {
        this.dialogRef.close(element);
    }

    routePaymentMadeToElement(element: IPaymentMade) {
        this.dialogRef.close();
        this.router.navigate(['purchases/payments', element.payment_made_id]);
    }

    routeVendorCreditToElement(element: IVendorCredit) {
        this.dialogRef.close();
        this.router.navigate(['purchases/vendorcredits', element.vendor_credit_id]);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.dataSource.disconnect();
    }
}
