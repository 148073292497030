import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-user-recurring-delete-dialog',
    styles: [
        `
            .mat-dialog-actions {
                justify-content: space-between;
                display: flex;
            }
            #errors {
                color: #af1515;
                padding-left: 15px;
                line-height: 2em;
                font-size: 14px;
            }
        `
    ],
    template: `
        <div mat-dialog-content>
            <p>Delete {{ data.profile.first_name }} {{ data.profile.last_name }}</p>
            <hr />
            <p>
                The profile will be removed from the following recurring invoices. <br />This will not effect any
                existing invoices that have already been issued.
            </p>

            <div style="margin-bottom: 15px;">
                <div *ngFor="let ri of data.invoices" class="d-flex">
                    <div class="w-60">
                        {{ ri.title }}
                    </div>
                </div>
            </div>
        </div>

        <div class="text-right">
            <button mat-button class="mat-primary" (click)="dialogRef.close()" style="margin-right: 5px;">
                CANCEL
            </button>
            <button mat-raised-button class="mat-primary" (click)="dialogRef.close(true)">Delete</button>
        </div>
    `
})
export class UserRecurringDeleteDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<UserRecurringDeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {}
}
