import * as moment from 'moment';
import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';

import {ProgressService} from '../progress.service';
import {FocusAreaEntity} from '../entites/focus-area.entity';
import {GoalInstanceEntity} from '../entites/goal-instance.entity';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {GoalValueEntity} from '../entites/goal-value.entity';

@Component({
    selector: 'app-goals',
    templateUrl: 'goals.component.html',
    styleUrls: ['goals.component.scss']
})
export class GoalsComponent implements OnInit, OnChanges, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() public focusArea: FocusAreaEntity | null = null;

    public goalsInstancesListByFocusArea: {[id: number]: GoalInstanceEntity[]} = {};

    public weekStart: Date = new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1));
    public currentWeek: number = 0;
    public moment = moment;
    public weekValues: {
        [index: number]: {[id: number]: string | 0};
    } = {
        1: {},
        2: {},
        3: {},
        4: {},
        5: {},
        6: {},
        7: {}
    };

    constructor(protected progressService: ProgressService) {
        this.progressService.source.onInstancesAssocChange.pipe(takeUntil(this.unsubscribe)).subscribe((last) => {
            this.goalsInstancesListByFocusArea = last;
        });
    }

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.focusArea) {
            this.focusArea = changes.focusArea.currentValue;
            this.loadWeek();
        }
    }

    getCorrespondingFontClass(value: string | 0) {
        value = String(value);
        if (!value) {
            return 'digits-5-font';
        }
        if (value.length <= 5) {
            return 'digits-5-font';
        }

        if (value.length <= 7) {
            return 'digits-7-font';
        }

        if (value.length <= 9) {
            return 'digits-9-font';
        }
        return 'digits-11-font';
    }

    getWeeklyStatus(instance: GoalInstanceEntity) {
        let sum = 0;
        for (let i = 1; i <= 7; i++) {
            sum += Number(this.weekValues[i][instance.id as number]);
        }
        if (isNaN(sum)) {
            return '';
        } else {
            return sum + '/' + this.getWeeklyTotal(instance);
        }
    }

    getProgressBarValue(instance: GoalInstanceEntity) {
        let sum = 0;
        for (let i = 1; i <= 7; i++) {
            sum += Number(this.weekValues[i][instance.id as number]);
        }
        return Math.round(100 - (sum / this.getWeeklyTotal(instance)) * 100);
    }

    getWeeklyTotal(instance: GoalInstanceEntity) {
        return Math.round(
            instance.values.reduce((acc, actual: GoalValueEntity) => {
                let baseline_value = 0;
                if (
                    actual &&
                    actual.goal_instance &&
                    actual.goal_instance.goal &&
                    actual.goal_instance.goal.baseline_value
                ) {
                    baseline_value = actual.goal_instance.goal.baseline_value;
                }
                return acc + baseline_value;
            }, 0)
        );
    }

    onFocus(day: number, instanceId: number) {
        if (this.weekValues[day][instanceId] === 0) {
            this.weekValues[day][instanceId] = '';
        }
    }

    saveWeek() {
        return Promise.all(
            // @ts-ignore: this.focusArea.id should always have value
            this.goalsInstancesListByFocusArea[this.focusArea.id].map((instance) => {
                return this.progressService.saveWeek(instance, this.weekStart);
            })
        );
    }

    focusOut(instance: GoalInstanceEntity, day: number | undefined) {
        if (typeof day === 'undefined') {
            return;
        }

        const date = new Date(this.weekStart);
        date.setDate(this.weekStart.getDate() + day - 1);
        this.progressService.saveValue(instance, date, Number(this.weekValues[day][instance.id as number]));
        if (this.weekValues[day][instance.id as number] === '') {
            this.weekValues[day][instance.id as number] = 0;
        }
    }

    nextWeek() {
        this.saveWeek().then(() => {
            this.weekStart = this.weekDay(8);
            this.currentWeek++;
            this.loadWeek();
        });
    }

    prevWeek() {
        this.saveWeek().then(() => {
            this.weekStart = this.weekDay(-6);
            this.currentWeek--;
            this.loadWeek();
        });
    }

    weekDay(day: number): Date {
        return new Date(new Date(this.weekStart).setDate(this.weekStart.getDate() + day - 1));
    }

    loadWeek(): Promise<any> {
        this.weekValues = {
            1: {},
            2: {},
            3: {},
            4: {},
            5: {},
            6: {},
            7: {}
        };

        return Promise.all(
            // @ts-ignore: this.focusArea.id should always have value
            this.goalsInstancesListByFocusArea[this.focusArea.id].map((instance) => {
                return this.progressService
                    .loadWeek(instance, this.weekStart)
                    .then((res) => {
                        instance.setValues(res.values);
                    })
                    .then(() => {
                        for (let day = 1; day <= 7; day++) {
                            this.weekValues[day][instance.id] = String(this.getDayValue(instance, day, '0'));
                        }
                        return true;
                    });
            })
        );
    }

    editableTime(instance: GoalInstanceEntity, day: number) {
        if (this.currentWeek > 0 || !instance || !instance.goal || !instance.goal.start_tracking_date) {
            return false;
        }

        const startTrackingDate = moment(new Date(instance.goal.start_tracking_date));
        const questionDate = moment(this.weekDay(day));

        if (this.currentWeek === 0) {
            return new Date().getDay() >= day && moment(questionDate).isSameOrAfter(startTrackingDate);
        }

        if (this.currentWeek < 0 && moment(questionDate).isSameOrAfter(startTrackingDate)) {
            return true;
        }
    }

    beforeTOday(day: number) {
        if (this.currentWeek === 0) {
            return new Date().getDay() >= day;
        }

        if (this.currentWeek < 0) {
            return true;
        }

        if (this.currentWeek > 0) {
            return false;
        }
    }

    isToday(day: number): boolean {
        return moment().format('MMM DD YYYY') === moment(this.weekDay(day)).format('MMM DD YYYY');
    }

    getDayValue(instance: GoalInstanceEntity, day: number, def: string = '-') {
        const value = instance.getValueForDate(this.weekDay(day));
        if (value && value.value) {
            return value.value;
        } else {
            return def;
        }
    }

    showNoGoalsBoard() {
        if (!this.focusArea || !this.focusArea.id) {
            return true;
        }
        return this.goalsInstancesListByFocusArea[this.focusArea.id].length === 0;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
