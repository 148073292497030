import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {ILedgerAccount, ITransactionExternal} from '@cyberco-nodejs/zipi-typings';

import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

import {TransactionsExternalService} from 'app/services/api/finance/transactions-external.service';
import {IFinanceState} from '../../../../finance/store/finance.reducer';
import {MatSort, Sort, SortDirection} from '@angular/material/sort';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmComponent} from '../../../../../layouts/confirm/confirm.component';
import {NotificationsService} from 'angular2-notifications';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatCheckboxChange} from '@angular/material/checkbox';

interface IScrollData {
    offset: number;
    limit: number;
    is_deleted: boolean;
    sort_column: string;
    sort_direction: 'asc' | 'desc' | '';
}

@Component({
    selector: 'app-external-transactions',
    templateUrl: 'external-transactions.component.html',
    styleUrls: ['../../banking.component.scss', './external-transactions.component.scss']
})
export class ExternalTransactionsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @ViewChild(MatSort, {static: false}) sort: MatSort = new MatSort();
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator = new MatPaginator(
        new MatPaginatorIntl(),
        ChangeDetectorRef.prototype
    );
    @ViewChild('bulkSelect') bulkSelectCheckbox: MatCheckbox | undefined;

    @Input() sidebar: any;
    @Input() ledgerAccount: ILedgerAccount | undefined;
    @Input() selectedTransactions: ITransactionExternal[] = [];
    @Output() toggleTransaction: EventEmitter<ITransactionExternal> = new EventEmitter();
    @Input() filterType: string | null = null;
    @Output() fetchTransactions: EventEmitter<void> = new EventEmitter();
    @Output() activateFeed: EventEmitter<void> = new EventEmitter();
    @Output() bulkSelect: EventEmitter<any[]> = new EventEmitter();
    @Output() bulkSelectAll: EventEmitter<void> = new EventEmitter();

    private unsubscribe: Subject<void> = new Subject();

    selection: SelectionModel<number> = new SelectionModel<number>(true, []);
    dataSource: MatTableDataSource<ITransactionExternal>;
    displayedColumns: string[] = ['bulkselect', 'date', 'name', 'match_status', 'debit', 'credit', 'action'];

    ledgerAccountId: number | null = null;
    isAvailableForFeed: boolean = false;

    listLength: number | null = null;
    pageSizeOptions: number[] = [10, 20, 50];
    scrollData: IScrollData = {
        offset: 0,
        limit: 10,
        is_deleted: false,
        sort_column: 'date',
        sort_direction: 'desc' as SortDirection
    };

    isAllSelected: boolean = false;
    isMasterToggleChecked: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private store: Store<IFinanceState>,
        private transactionsExternalService: TransactionsExternalService,
        public dialog: MatDialog,
        protected ntfs: NotificationsService,
        protected cd: ChangeDetectorRef
    ) {
        this.dataSource = new MatTableDataSource<ITransactionExternal>([]);
    }

    ngOnInit() {
        if (this.ledgerAccount && this.ledgerAccount.ledger_account_id) {
            this.ledgerAccountId = this.ledgerAccount.ledger_account_id;
            this.loadTransactions();
        }
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;

        this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.scrollData.offset = data.pageSize * data.pageIndex;
            this.scrollData.limit = data.pageSize;

            this.isAllSelected = false;
            this.isMasterToggleChecked = false;
            this.bulkSelect.emit(this.selection.selected);

            this.loadTransactions();
        });
    }

    loadTransactions() {
        if (!this.ledgerAccountId) {
            return;
        }
        switch (this.filterType) {
            case 'not_deleted':
                this.scrollData.is_deleted = false;
                break;
            case 'deleted':
                this.scrollData.is_deleted = true;
                break;
        }
        this.transactionsExternalService
            .getPageTransactions(Number(this.ledgerAccountId), this.scrollData)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.listLength = data.count;
                this.dataSource.data = data.transactions;
                this.cd.detectChanges();
            });
    }

    loadInitTransactions() {
        this.paginator.pageIndex = 0;
        this.loadTransactions();
    }

    reset() {
        this.selection.clear();
        this.bulkSelect.emit([]);
        this.isAllSelected = false;
        this.paginator.pageIndex = 0;
        this.scrollData.offset = 0;
        this.scrollData.is_deleted = false;
        this.loadTransactions();
    }

    sortData(sort: Sort) {
        this.scrollData.sort_direction = sort.direction;
        this.scrollData.sort_column = sort.active;

        this.reset();
    }

    checkIsAvailableForFeed() {
        if (typeof this.ledgerAccount === 'undefined') {
            return;
        }

        this.isAvailableForFeed =
            (['bank', 'credit_card', 'trust'].includes(this.ledgerAccount.type) &&
                this.ledgerAccount.connected_payment_methods &&
                this.ledgerAccount.connected_payment_methods.some(
                    (method) =>
                        method.is_available_for_banking ||
                        ['zipi_financial_balance', 'zipi_financial_cloud_bank'].includes(method.type)
                )) ||
            this.ledgerAccount.system_key === 'zipi_pay';
    }

    isEntirePageSelected() {
        const selectedIds = this.selection.selected;
        const transactionsIds = this.dataSource.data.map((tr) => tr.transaction_external_id);

        return !!transactionsIds.length && transactionsIds.every((r) => selectedIds.indexOf(r) >= 0);
    }

    toggleBulkCheckbox(event: MatCheckboxChange | MouseEvent, transaction: ITransactionExternal) {
        if (event) {
            this.selection.toggle(transaction.transaction_external_id);
            this.bulkSelect.emit(this.selection.selected);

            this.isAllSelected = false;
            this.isMasterToggleChecked = false;
        }
    }

    toggleRowTransaction(event: MouseEvent, transaction: ITransactionExternal) {
        if (!transaction.is_pending) {
            this.toggleTransaction.emit(transaction);
            this.selection.clear();
            this.bulkSelect.emit(this.selection.selected);
            this.isMasterToggleChecked = false;
            // if (!this.selection.isSelected(transaction.transaction_external_id)) {
            //     this.toggleBulkCheckbox(event, transaction);
            // }
        }
    }

    masterToggle() {
        this.isAllSelected = false;

        if (this.isEntirePageSelected()) {
            this.isMasterToggleChecked = false;
            this.selection.clear();
            this.bulkSelect.emit([]);
        } else {
            this.isMasterToggleChecked = true;
            this.dataSource.data.forEach((row) => {
                if (row.transaction_external_id && !this.selection.selected.includes(row.transaction_external_id)) {
                    this.selection.select(row.transaction_external_id);
                }
            });
            this.bulkSelect.emit(this.selection.selected);
        }
    }

    selectAll() {
        this.isAllSelected = true;
        this.isMasterToggleChecked = false;
        this.dataSource.data.forEach((row) => {
            if (row.transaction_external_id && !this.selection.selected.includes(row.transaction_external_id)) {
                this.selection.select(row.transaction_external_id);
            }
        });
        this.bulkSelectAll.emit();
    }

    clearSelection() {
        this.isAllSelected = false;
        this.isMasterToggleChecked = false;
        this.selection.clear();
        this.bulkSelect.emit([]);
    }

    bulkDeleteTransactions(listOfIds: number[] | boolean) {
        let ids: number[] = [];
        if (listOfIds && Array.isArray(listOfIds)) {
            ids = listOfIds;
        } else {
            ids = this.selection.selected;
        }

        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            data: {
                title: 'Deleting External Transactions',
                message: this.isAllSelected
                    ? `All External Transactions will be deleted.`
                    : `${ids.length} External Transaction${ids.length > 1 ? 's' : ''} will be deleted.`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {
                if (ok) {
                    if (this.isAllSelected) {
                        this.transactionsExternalService
                            .bulkDeleteAllTransactions(this.ledgerAccountId as number)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe(() => {
                                this.ntfs.success(`All external transactions were successfully deleted.`);
                                this.reset();
                            });
                    } else {
                        this.transactionsExternalService
                            .bulkDeleteTransactions(ids)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe(() => {
                                this.ntfs.success(
                                    `External transaction${ids.length > 1 ? 's were' : 'was'} successfully deleted.`
                                );
                                this.reset();
                            });
                    }
                }
            });
    }

    bulkRestoreTransactions(listOfIds: number[] | boolean) {
        let ids: number[] = [];
        if (listOfIds && Array.isArray(listOfIds)) {
            ids = listOfIds;
        } else {
            ids = this.selection.selected;
        }

        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            data: {
                title: 'Restore External Transactions',
                message: this.isAllSelected
                    ? `All External Transactions will be restored.`
                    : `${ids.length} External Transaction${ids.length > 1 ? 's' : ''} will be restored.`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {
                if (ok) {
                    if (this.isAllSelected) {
                        this.transactionsExternalService
                            .bulkRestoreAllTransactions(this.ledgerAccountId as number)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe(() => {
                                this.ntfs.success(`All external transactions were successfully restored.`);
                                this.reset();
                            });
                    } else {
                        this.transactionsExternalService
                            .bulkRestoreTransactions(ids)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe(() => {
                                this.ntfs.success(
                                    `External transaction${ids.length > 1 ? 's were' : 'was'} successfully restored.`
                                );
                                this.reset();
                            });
                    }
                }
            });
    }

    deleteTransaction(transaction: ITransactionExternal) {
        const listOfIds = [];
        listOfIds.push(Number(transaction.transaction_external_id));
        this.bulkDeleteTransactions(listOfIds);
    }

    restoreTransaction(transaction: ITransactionExternal) {
        const listOfIds = [];
        listOfIds.push(Number(transaction.transaction_external_id));
        this.bulkRestoreTransactions(listOfIds);
    }

    isTransactionSelected(transaction: ITransactionExternal) {
        return this.selectedTransactions.some(
            (tr) => tr.transaction_external_id === transaction.transaction_external_id
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.ledgerAccount) {
            this.checkIsAvailableForFeed();
        }
        if (changes.filterType) {
            this.loadInitTransactions();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.toggleTransaction.complete();
        this.fetchTransactions.complete();
        this.activateFeed.complete();
        this.bulkSelect.complete();
        this.bulkSelectAll.complete();
    }
}
