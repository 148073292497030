import {GenericEntity} from '../../../../entites/generic.entity';
import {Group} from '../../../../models/group';
import {CompensationProfileModel} from './compensation-profile.model';

export class GroupCompensationProfilesListItem extends GenericEntity {
    company_group: Group = new Group();
    compensationProfiles: CompensationProfileModel[] = [];

    static get helpers() {
        return Object.assign(
            {
                props: Object.getOwnPropertyNames(new this()).reduce((acc, curr) => {
                    // @ts-ignore
                    acc[curr] = curr;
                    return acc;
                }, {}),
                build: {
                    company_group: (val: object, fabric: string) => Object.assign(new Group(), val),
                    compensationProfiles: (val: object[], fabric: string) =>
                        // @ts-ignore
                        val.map((item) => this.FABRIC(CompensationProfileModel)[fabric](item))
                }
            },
            GenericEntity.helpers
        );
    }

    setCompensationProfiles(compensationProfiles: CompensationProfileModel[]): GroupCompensationProfilesListItem {
        this.compensationProfiles = compensationProfiles;
        return this;
    }

    setGroup(company_group: Group): GroupCompensationProfilesListItem {
        this.company_group = company_group;
        return this;
    }
}
