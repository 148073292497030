import {Component, Input, OnInit} from '@angular/core';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';

@Component({
    selector: 'reports-preview-trial-balance',
    templateUrl: './trial-balance.component.html',
    styleUrls: ['./trial-balance.component.scss']
})
export class TrialBalancePreviewComponent implements OnInit {
    @Input()
    /**
     * Set report data
     */
    set reportData(value: any) {
        this._reportData = {
            ...value.data,
            totals: {
                opening_balance: value.total.opening_balance,
                credit: value.total.credit,
                debit: value.total.debit,
                closing_balance: value.total.closing_balance
            }
        };

        this.assetDataSource.data = this._reportData!.asset;
        this.equityDataSource.data = this._reportData!.equity;
        this.expenseDataSource.data = this._reportData!.expense;
        this.incomeDataSource.data = this._reportData!.income;
        this.liabilityDataSource.data = this._reportData!.liability;
        this.costOfGoodsSoldDataSource.data = this._reportData!.cost_of_goods_sold;
        this.otherIncomeDataSource.data = this._reportData!.other_income;
        this.otherExpenseDataSource.data = this._reportData!.other_expense;

        this.treeControlAsset.dataNodes = this._reportData!.asset;
        this.treeControlEquity.dataNodes = this._reportData!.equity;
        this.treeControlExpense.dataNodes = this._reportData!.expense;
        this.treeControlIncome.dataNodes = this._reportData!.income;
        this.treeControlLiability.dataNodes = this._reportData!.liability;
        this.treeControlCostOfGoodsSold.dataNodes = this._reportData!.cost_of_goods_sold;
        this.treeControlOtherIncome.dataNodes = this._reportData!.other_income;
        this.treeControlOtherExpense.dataNodes = this._reportData!.other_expense;
    }

    @Input() reportType: string | null = null;

    /**
     * Get report data
     */
    get reportData() {
        return this._reportData;
    }

    _reportData: {
        asset: Array<any>;
        equity: Array<any>;
        expense: Array<any>;
        income: Array<any>;
        liability: Array<any>;
        cost_of_goods_sold: Array<any>;
        other_income: Array<any>;
        other_expense: Array<any>;
        totals: {
            opening_balance: number;
            debit: number;
            credit: number;
            closing_balance: number;
        };
    } | null = null;

    assetDataSource = new MatTreeNestedDataSource<any>();
    equityDataSource = new MatTreeNestedDataSource<any>();
    expenseDataSource = new MatTreeNestedDataSource<any>();
    incomeDataSource = new MatTreeNestedDataSource<any>();
    liabilityDataSource = new MatTreeNestedDataSource<any>();
    costOfGoodsSoldDataSource = new MatTreeNestedDataSource<any>();
    otherIncomeDataSource = new MatTreeNestedDataSource<any>();
    otherExpenseDataSource = new MatTreeNestedDataSource<any>();
    treeControlAsset = new NestedTreeControl<any>((node) => node.children);
    treeControlEquity = new NestedTreeControl<any>((node) => node.children);
    treeControlExpense = new NestedTreeControl<any>((node) => node.children);
    treeControlIncome = new NestedTreeControl<any>((node) => node.children);
    treeControlLiability = new NestedTreeControl<any>((node) => node.children);
    treeControlCostOfGoodsSold = new NestedTreeControl<any>((node) => node.children);
    treeControlOtherIncome = new NestedTreeControl<any>((node) => node.children);
    treeControlOtherExpense = new NestedTreeControl<any>((node) => node.children);
    isExpand: boolean = false;
    expandTooltip: string = 'Expand All';

    ngOnInit(): void {
        this.toggleExpand();
    }
    /**
     * Check is tree has child item
     * @param _
     * @param node
     */
    hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

    /**
     * Change state node
     * @param node
     */
    changeState(node: {expanded: boolean}) {
        node.expanded = !node.expanded;
    }

    /**
     * Expand All
     */
    toggleExpand() {
        if (this.isExpand) {
            this.treeControlAsset.collapseAll();
            this.treeControlEquity.collapseAll();
            this.treeControlExpense.collapseAll();
            this.treeControlIncome.collapseAll();
            this.treeControlLiability.collapseAll();
            this.treeControlCostOfGoodsSold.collapseAll();
            this.treeControlOtherIncome.collapseAll();
            this.treeControlOtherExpense.collapseAll();
            this.expandTooltip = 'Expand All';
        } else {
            this.treeControlAsset.expandAll();
            this.treeControlEquity.expandAll();
            this.treeControlExpense.expandAll();
            this.treeControlIncome.expandAll();
            this.treeControlLiability.expandAll();
            this.treeControlCostOfGoodsSold.expandAll();
            this.treeControlOtherIncome.expandAll();
            this.treeControlOtherExpense.expandAll();
            this.expandTooltip = 'Collapse All';
        }
        this.isExpand = !this.isExpand;
    }
}
