import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ISettingsState} from './settings.reducer';

export const selectSettingsFeature = createFeatureSelector<ISettingsState>('settings');

export const selectProfileSettings = createSelector(
    selectSettingsFeature,
    (state: ISettingsState) => state.profileSettings
);

export const selectCompanyLocations = createSelector(
    selectSettingsFeature,
    (state: ISettingsState) => state.companyLocations
);

export const selectCompanySettings = createSelector(
    selectSettingsFeature,
    (state: ISettingsState) => state.companySettings
);
