import {Component, OnInit, Input, EventEmitter, Output, OnDestroy, ViewChild} from '@angular/core';
import {SourceOfBusiness} from '../../../../../../../models/source-of-business';
import {
    DealsBoardBackendFilterSettings,
    DEAL_ORDER_BY_OPTIONS,
    ORDER_BY_STATUS,
    DealsBoardFrontendFilterSettings
} from '../../../common/deal.models';
import {ProfilesService} from '../../../../../../../services/profiles.service';
import {SessionService} from '../../../../../../../services/session.service';
import {ITag} from '@cyberco-nodejs/zipi-typings';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {GenericFormArray} from '../../../../../../../entites/generic.entity';
import {ChipNode} from '../../../../../../account-info/compensation/models/chip-node';
import {GroupsSource} from '../../../../../../../services/sources/groups.source';
import {Deal, DEAL_SYSTEM_STATUS} from '../../../../../../../models/deal';
import {select, Store} from '@ngrx/store';
import {selectProcessedSOB, selectTagCategories} from '../../../../../../../store/company-wide/company-wide.selectors';
import {ICompanyWideState} from '../../../../../../../store/company-wide/company-wide.reducer';
import {PickerItem} from '../../../../../../shared/components/profiles-picker/models';
import {Group} from '../../../../../../../models/group';
import {UntypedFormControl} from '@angular/forms';
import * as moment from 'moment';
import {MatSelect} from '@angular/material/select';

@Component({
    selector: 'app-deal-board-settings-v2',
    styles: [
        `
            .d-grid {
                display: grid;
                grid-template-columns: calc((100% - 3rem) / 100 * 33) calc((100% - 3rem) / 100 * 34) calc(
                        (100% - 3rem) / 100 * 33
                    );
                column-gap: 1.5rem;
            }

            .show-more-or-less-filters {
                font-size: smaller;
                text-decoration: underline;
                grid-column-start: 3;
                justify-self: end;
                color: #537483;
                cursor: pointer;
                transition: 0.1s;
            }
            .show-more-or-less-filters:hover {
                color: #6d91a1;
            }
            .clear-button {
                cursor: pointer;
                align-self: center;
                font-size: 18px;
            }
        `
    ],
    template: `
        <div class="d-grid">
            <!------- Status ------->
            <mat-form-field floatLabel="never">
                <mat-select placeholder="Status" multiple [formControl]="statusesFC">
                    <mat-select-trigger>Status</mat-select-trigger>
                    <mat-option
                        *ngFor="let status of object_keys(filters.statuses)"
                        [value]="status"
                        (click)="selectStatus(status)"
                    >
                        <span>{{ DEAL.status_LABELS[status] | titlecase }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!------- Type ------->
            <mat-form-field floatLabel="never">
                <mat-select placeholder="Type" multiple [formControl]="typesFC">
                    <mat-select-trigger>Type</mat-select-trigger>
                    <mat-option
                        *ngFor="let type of object_keys(filters.types)"
                        [value]="type"
                        (click)="selectType(type)"
                    >
                        <ng-container *ngIf="type !== 'none'; else noneType">
                            {{ DEAL.type_LABELS[type] | titlecase }}
                        </ng-container>
                        <ng-template #noneType> None </ng-template>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!------- Property Class ------->
            <mat-form-field floatLabel="never">
                <mat-select placeholder="Property Class" multiple [formControl]="propertyClassFC">
                    <mat-select-trigger>Property Class</mat-select-trigger>
                    <mat-option
                        *ngFor="let pClass of object_keys(filters.property_classes)"
                        [value]="pClass"
                        (click)="selectPropertyClass(pClass)"
                    >
                        {{ DEAL.property_class_LABELS[pClass] | titlecase }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf="showAdvancedFilters">
                <!------- Entities ------->
                <app-deals-view-picker-select
                    *ngIf="sessionService.profile && sessionService.profile.id"
                    [currentProfile]="sessionService.profile"
                    [pickedList]="settings.by_company[sessionService.profile.id].filter_by_deal_members"
                    [mode]="settings.by_company[sessionService.profile.id].deals_view_mode"
                    (modeChange)="settings.by_company[sessionService.profile.id].deals_view_mode = $event"
                >
                </app-deals-view-picker-select>

                <!------- Custom Entities ------->
                <app-combined-entity-picker
                    [title]="'Groups and Profiles'"
                    [additionalTitle]="''"
                    [positionMode]="'bottom'"
                    [availableTypes]="['individuals_in_group', 'individual', 'company_group']"
                    [nodesFA]="entitiesForm"
                    [initialProfileIds]="savedProfiles"
                    [initialGroupIds]="savedGroups"
                    [initialProfilesInGroupIds]="savedProfilesInGroups"
                    [disabled]="
                        !(
                            sessionService.profile &&
                            sessionService.profile.id &&
                            settings.by_company[sessionService.profile.id].deals_view_mode === 'custom'
                        )
                    "
                    [disableUpdate]="
                        !(
                            sessionService.profile &&
                            sessionService.profile.id &&
                            settings.by_company[sessionService.profile.id].deals_view_mode === 'custom'
                        )
                    "
                    displayMode="double-line"
                ></app-combined-entity-picker>

                <!------- Divisions ------->
                <mat-form-field floatLabel="never">
                    <mat-select [(ngModel)]="filters.divisions" placeholder="Divisions" multiple>
                        <mat-select-trigger>Divisions</mat-select-trigger>
                        <mat-option *ngFor="let division of divisions" [value]="division.id">
                            {{ division.title }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!------- Tags ------->
                <mat-form-field floatLabel="never">
                    <mat-select [(ngModel)]="filters.tags" placeholder="Tags" multiple>
                        <mat-select-trigger>Tags</mat-select-trigger>
                        <mat-option
                            *ngFor="let tag of tags"
                            [value]="tag.id"
                            (click)="unselectDeactivatedTag(tag)"
                            [disabled]="tag.deactivated"
                            [matTooltip]="
                                tag.deactivated ? 'This Tag couldn\\'t be selected because it\\'s deactivated' : ''
                            "
                        >
                            {{ tag.title }}
                            <span class="font-italic" style="color:grey">(category - {{ tag.category_title }})</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!------- Source ------->
                <mat-form-field floatLabel="never">
                    <mat-select
                        placeholder="Source of Business"
                        [multiple]="true"
                        [(ngModel)]="settings.source_of_business"
                    >
                        <mat-select-trigger>Source of Business</mat-select-trigger>
                        <!--<mat-option [value]="">Any</mat-option>-->
                        <mat-option class="font-italic text-muted" [value]="'0'">None</mat-option>
                        <mat-option *ngFor="let sob of sourceOfBusinessList" [value]="sob.label">{{
                            sob.label
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <!------- Show Archived ------->
                <!--<mat-form-field>
                    <mat-select placeholder="Show Archived" [(ngModel)]="settings.is_archived">
                        <mat-option *ngFor="let item of archived" [value]="item.value">{{ item.label }}</mat-option>
                    </mat-select>
                </mat-form-field>-->

                <!------- Deal Stage ------->
                <mat-form-field floatLabel="never">
                    <mat-select placeholder="Deal Stage" [(ngModel)]="settings.deal_stage" multiple>
                        <mat-select-trigger>Deal Stage</mat-select-trigger>
                        <mat-option #allSelected (click)="toggleAllDealsStage(allSelected.selected)" [value]="''"
                            >Any</mat-option
                        >
                        <mat-option *ngFor="let item of object_keys(DEAL_SYSTEM_STATUS)" [value]="item">
                            {{ DEAL_SYSTEM_STATUS[item] | titlecase }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!------- Closing Date ------->
                <mat-form-field>
                    <mat-select
                        placeholder="Closing Date"
                        [(ngModel)]="settings.closing_date_type"
                        #closingDateSelect
                        (selectionChange)="clearDates()"
                    >
                        <mat-select-trigger>{{
                            DEAL.closing_date_LABELS[settings.closing_date_type]
                        }}</mat-select-trigger>
                        <mat-option *ngFor="let dateType of object_keys(DEAL.closing_date_LABELS)" [value]="dateType">
                            {{ DEAL.closing_date_LABELS[dateType] }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!------- Closing Date from ------->
                <mat-form-field>
                    <input
                        matInput
                        [matDatepicker]="closeOfEscrowAfter"
                        [(ngModel)]="settings.close_of_escrow_after"
                        placeholder="Closing Date From"
                        [disabled]="settings.closing_date_type !== 'custom'"
                    />
                    <mat-datepicker-toggle matSuffix [for]="closeOfEscrowAfter"></mat-datepicker-toggle>
                    <mat-datepicker #closeOfEscrowAfter></mat-datepicker>
                    <mat-icon matSuffix (click)="settings.close_of_escrow_after = null" class="clear-button">
                        close
                    </mat-icon>
                </mat-form-field>
                <!------- Closing Date to ------->
                <mat-form-field>
                    <input
                        matInput
                        [matDatepicker]="closeOfEscrowBefore"
                        [(ngModel)]="settings.close_of_escrow_before"
                        placeholder="Closing Date To"
                        [disabled]="settings.closing_date_type !== 'custom'"
                    />
                    <mat-datepicker-toggle matSuffix [for]="closeOfEscrowBefore"></mat-datepicker-toggle>
                    <mat-datepicker #closeOfEscrowBefore></mat-datepicker>
                    <mat-icon matSuffix (click)="settings.close_of_escrow_before = null" class="clear-button">
                        close
                    </mat-icon>
                </mat-form-field>

                <!------- Keyword ------->
                <mat-form-field>
                    <input matInput placeholder="Filter by Keyword" [(ngModel)]="filters.search" />
                </mat-form-field>

                <!------- Order by ------->
                <mat-form-field>
                    <mat-select placeholder="Order by" [(ngModel)]="settings.order_by">
                        <mat-option *ngFor="let orderObj of orderByOptions" [value]="orderObj.value"
                            >{{ orderObj.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
        </div>

        <div class="d-grid">
            <div class="show-more-or-less-filters" (click)="showAdvancedFilters = !showAdvancedFilters">
                {{ showAdvancedFilters ? 'Hide advanced filters' : 'Show advanced filters' }}
            </div>
        </div>
    `
})
export class DealBoardSettingsV2Component implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('closingDateSelect') closingDateMS: MatSelect | undefined;
    @Input() filters: DealsBoardFrontendFilterSettings = {
        statuses: {},
        types: {},
        disbursement_statuses: {},
        property_classes: {},
        search: '',
        tags: null,
        divisions: null
    };
    @Input() settings: DealsBoardBackendFilterSettings = {
        source_of_business: [],
        close_of_escrow_after: null,
        close_of_escrow_before: null,
        is_archived: '',
        is_approved: '',
        filter_by_deal_members: [],
        by_company: {},
        show_net_by: 'user',
        show_only_owner_deals: false,
        deals_view_mode: 'company',
        order_by: ORDER_BY_STATUS,
        deal_stage: ['draft', 'open', 'approved', ''],
        closing_date_type: Deal.closing_date_LABELS.all_dated
    };
    @Output() frontendFilterChanged = new EventEmitter();

    entitiesForm: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    savedProfiles: number[] = [];
    savedProfilesInGroups: number[] = [];
    savedGroups: number[] = [];

    DEAL_SYSTEM_STATUS: {[key: string]: string} = DEAL_SYSTEM_STATUS;
    public DEAL = Deal;

    public archived = [
        {value: 'yes', label: 'Show all'},
        {value: 'no', label: 'Hide Archived'},
        {value: 'only', label: 'Show only Archived'}
    ];

    public netBy = [
        {value: 'user', label: 'User'},
        {value: 'company', label: 'Company'}
    ];

    public sourceOfBusinessList: SourceOfBusiness[] = [];

    orderByOptions = DEAL_ORDER_BY_OPTIONS;

    public tags: ITag[] = [];
    public divisions: Group[] = [];

    statusesFC: UntypedFormControl = new UntypedFormControl([]);
    typesFC: UntypedFormControl = new UntypedFormControl([]);
    propertyClassFC: UntypedFormControl = new UntypedFormControl([]);

    showAdvancedFilters: boolean = false;

    constructor(
        protected profilesService: ProfilesService,
        public sessionService: SessionService,
        protected groupsSource: GroupsSource,
        private store: Store<ICompanyWideState>
    ) {}

    async ngOnInit() {
        if (!this.settings) {
            this.settings = new DealsBoardBackendFilterSettings();
        }

        if (!this.settings.filter_by_deal_members) {
            this.settings.filter_by_deal_members = [];
        }

        if (!this.settings.deals_view_mode) {
            this.settings.deals_view_mode = 'company';
        }

        if (!this.settings.order_by) {
            this.settings.order_by = ORDER_BY_STATUS;
        }

        if (this.settings.close_of_escrow_after) {
            this.settings.close_of_escrow_after = new Date(this.settings.close_of_escrow_after);
        }

        if (this.settings.close_of_escrow_before) {
            this.settings.close_of_escrow_before = new Date(this.settings.close_of_escrow_before);
        }

        if (
            this.settings.deal_stage.includes('draft') &&
            this.settings.deal_stage.includes('open') &&
            this.settings.deal_stage.includes('approved')
        ) {
            this.settings.deal_stage.push('');
        }

        if (!this.filters.tags) {
            this.filters.tags = [];
        }

        if (!this.filters.divisions) {
            this.filters.divisions = null;
        }

        // if type = custom, create component entity-picker with initial values
        if (
            this.sessionService.profile!.id &&
            this.settings.by_company[this.sessionService.profile!.id].deals_view_mode === 'custom'
        ) {
            // set profiles
            this.savedProfiles = this.settings.by_company[this.sessionService.profile!.id].filter_by_deal_members
                .filter((item: PickerItem) => item.type === 'profile')
                .map((contact: PickerItem) => contact.id as number);
            // set profiles of groups
            this.savedProfilesInGroups = this.settings.by_company[
                this.sessionService.profile!.id
            ].filter_by_deal_members
                .filter((item: PickerItem) => item.type === 'company_group')
                .map((group: PickerItem) => group.id as number);
            // set groups
            this.savedGroups = this.settings.by_company[this.sessionService.profile!.id].filter_by_deal_members
                .filter((item: PickerItem) => item.type === 'group')
                .map((group: PickerItem) => group.id as number);
        }

        // watch on changes from custom entity-picker
        this.entitiesForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (this.sessionService.profile!.id) {
                this.settings.by_company[this.sessionService.profile!.id].filter_by_deal_members = changes.map(
                    (item: ChipNode) => {
                        let type = '';
                        switch (item.type) {
                            case 'individual':
                                type = 'profile';
                                break;
                            case 'individuals_in_group':
                                type = 'company_group';
                                break;
                            case 'company_group':
                                type = 'group';
                                break;
                        }
                        return {
                            id: item.target_id,
                            label: item.label,
                            pick: true,
                            type: type
                        };
                    }
                );
            }
        });

        this.store.pipe(select(selectProcessedSOB), takeUntil(this.unsubscribe)).subscribe((sob) => {
            this.sourceOfBusinessList = sob.sourceList;
        });

        // load tags
        this.store.pipe(select(selectTagCategories), takeUntil(this.unsubscribe)).subscribe((cats) => {
            cats.forEach((cat) => {
                const tags = cat.tags.map((tag) => {
                    tag.category_title = cat.title;
                    return tag;
                });
                this.tags = [...this.tags, ...tags];
            });
        });

        // load divisions
        this.groupsSource.source.pipe(takeUntil(this.unsubscribe)).subscribe((groups) => {
            if (groups && groups.length > 0) {
                this.divisions = groups.filter((group) => group.type === 'division');
            } else {
                this.divisions = [];
            }
        });

        this.statusesFC.patchValue(
            Object.keys(this.filters.statuses)
                .filter((key) => this.filters.statuses[key])
                .map((key) => key)
        );
        this.typesFC.patchValue(
            Object.keys(this.filters.types)
                .filter((key) => this.filters.types[key])
                .map((key) => key)
        );
        this.propertyClassFC.patchValue(
            Object.keys(this.filters.property_classes)
                .filter((key) => this.filters.property_classes[key])
                .map((key) => key)
        );
    }

    toggleAllDealsStage(selectedAll: boolean) {
        if (selectedAll) {
            this.settings.deal_stage = ['draft', 'open', 'approved', ''];
        } else {
            this.settings.deal_stage = [];
        }
    }

    object_keys(obj: {[key: string]: any}): string[] {
        return Object.keys(obj);
    }

    unselectDeactivatedTag(tag: ITag) {
        // if tag exists in the selected list and is deactivated
        if (!this.filters.tags?.find((tagId) => tagId === tag.tag_id && tag.deactivated)) {
            return;
        }

        // update selected tags
        this.filters.tags = this.filters.tags?.filter((tagId) => tagId !== tag.tag_id) || [];
    }

    public selectStatus(status: string) {
        this.filters.statuses[status] = !this.filters.statuses[status];
    }

    public selectType(type: string) {
        this.filters.types[type] = !this.filters.types[type];
    }

    public selectPropertyClass(property_class: string) {
        this.filters.property_classes[property_class] = !this.filters.property_classes[property_class];
    }

    setRange(range: {dateFrom: string; dateTo: string}) {
        this.settings.close_of_escrow_after = moment(range.dateFrom, 'YYYYMMDD').toDate();
        this.settings.close_of_escrow_before = moment(range.dateTo, 'YYYYMMDD').toDate();
    }

    clearDates() {
        if (this.settings.closing_date_type !== 'custom') {
            this.settings.close_of_escrow_after = null;
            this.settings.close_of_escrow_before = null;
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.frontendFilterChanged.complete();
    }
}
