import {Component, OnDestroy} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {UserService} from 'app/services/user.service';
import {NotificationsServiceZipi} from 'app/modules/notifications/notifications.service';
import {MatDialog} from '@angular/material/dialog';
import {ForgotPasswordComponent} from '../forgot-password/forgot-password.component';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-connect-user',
    templateUrl: './connect-user.component.html',
    styleUrls: ['./connect-user.component.scss']
})
export class ConnectUserComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public authService: AuthService,
        private userService: UserService,
        private router: Router,
        private notificationsService: NotificationsServiceZipi,
        public dialog: MatDialog
    ) {}

    onSubmit(form: NgForm) {
        if (!form.valid) {
            return false;
        }

        return this.authService
            .signInWithEmailAndPassword(form.value.email, form.value.password)
            .then((firebaseUserInfo) => {
                return this.afterAuth();
            })
            .catch((err) => {
                this.notificationsService.addError(err.message);
            });
    }

    tryGoogleAuth() {
        return this.authService
            .signInWithGoogle()
            .then(() => {
                return this.afterAuth();
            })
            .catch((err) => {
                this.notificationsService.addError(err.message);
            });
    }

    private afterAuth() {
        return this.userService
            .getCheckMe()
            .then((systemUserInfo) => {
                if (systemUserInfo && systemUserInfo.id) {
                    window.location.replace('#/auth/switch-account');
                    return;
                } else {
                    return this.notificationsService.addError('USER NOT FOUND');
                }
            })
            .catch((err) => {
                console.info(err);
            });
    }

    forgotPassword() {
        const dialogRef = this.dialog.open(ForgotPasswordComponent);

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
