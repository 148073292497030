import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {DealDepositService} from '../../../../services/deal-deposit.service';
import {
    IPaymentGateway,
    IPaymentMethod,
    IPaymentReceivedMadeRequestObject,
    IDealDepositRequestPartialResponseObject,
    IPayment
} from '@cyberco-nodejs/zipi-typings';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AddCreditCardDialogComponent} from 'app/layouts/cards-dialogs/add-credit-card-dialog/add-credit-card-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {SessionService} from 'app/services/session.service';
import {PaymentMethodsService} from '../../../../../profile/services/payment-methods.service';
import * as moment from 'moment';
import {MatTableDataSource} from '@angular/material/table';
import {DEPOSIT_REQUEST_STATUS_COLOR} from 'app/local-typings';
import {ConfirmComponent} from '../../../../../../layouts/confirm/confirm.component';
import {PaymentsService} from '../../../../services/payments.service';
import {CompanyGatewayService} from '../../../../../../services/api/finance/company-gateway.service';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../../utilities/maskito';

@Component({
    selector: 'app-request-page',
    templateUrl: './request-page.component.html',
    styleUrls: ['./request-page.component.scss']
})
export class RequestPageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    currencyMaskitoMask = currencyMaskitoOptions;

    depositRequest: IDealDepositRequestPartialResponseObject | undefined;

    allAvailableGateways: IPaymentGateway[] = [];
    payDataForm: UntypedFormGroup = this.fb.group({
        current_method: [null, [Validators.required]],
        payment_amount: [0, []]
    });

    requestMethods: IPaymentMethod[] = [];

    isCancelPaymentButtonDisabled: boolean;
    showPayments: boolean;
    dataSource: MatTableDataSource<IPayment>;
    displayedRelatedColumns = ['payment_date', 'source', 'status', 'amount'];

    statusColor = DEPOSIT_REQUEST_STATUS_COLOR;

    constructor(
        private dealDepositService: DealDepositService,
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        public dialog: MatDialog,
        public sessionService: SessionService,
        private paymentMethodsService: PaymentMethodsService,
        private paymentsService: PaymentsService,
        private companyGatewayService: CompanyGatewayService
    ) {
        this.dataSource = new MatTableDataSource<IPayment>([]);
        this.showPayments = false;
        this.isCancelPaymentButtonDisabled = false;
    }

    ngOnInit() {
        this.route.paramMap
            .pipe(
                map((pm) => {
                    const stringId: string | null = pm.get('id');
                    return Number(stringId);
                }),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                this.dealDepositService
                    .getDepositRequestById(id)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((request) => {
                        this.depositRequest = request;
                        this.dataSource.data = request.payments;

                        this.init();
                    });
            });
    }

    init() {
        if (this.depositRequest) {
            this.payDataForm = this.fb.group({
                current_method: [null, [Validators.required]],
                payment_amount: [
                    this.depositRequest.request_pending_balance,
                    [Validators.max(this.depositRequest.request_pending_balance)]
                ]
            });

            if (this.depositRequest.pay_to__payment_method_fk_ids) {
                this.paymentMethodsService
                    .getInvoiceMethods(this.depositRequest.pay_to__payment_method_fk_ids)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((methods) => {
                        this.requestMethods = methods;
                    });
            }

            this.companyGatewayService
                .getCompanyGatewaysByTypes(['zipi_financial_business', 'authorize_net_customer'], null)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateways) => {
                    this.allAvailableGateways = gateways;
                });
        }
    }

    addCard() {
        if (!this.depositRequest) {
            return null;
        }
        const dialogRef = this.dialog.open(AddCreditCardDialogComponent, {
            minWidth: 320,
            maxHeight: '80vh',
            data: {
                moneySenderProfileId: this.sessionService.profile!.id,
                invoice: this.depositRequest,
                contactId: this.depositRequest.request_receiver__contact_fk_id,
                ownerCompany: true
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {
                if (ok) {
                    this.ngOnInit();
                }
            });
    }

    isAbleToDisplayAuthCustomer(gateway: IPaymentGateway) {
        if (this.requestMethods) {
            if (gateway.type === 'authorize_net_customer') {
                return this.requestMethods.some(
                    (im) =>
                        im.payment_gateway &&
                        im.payment_gateway.payment_gateway_id === gateway.related_merchant__payment_gateway_fk_id
                );
            } else {
                return true;
            }
        }
    }

    isAbleToDisplay(method: IPaymentMethod) {
        if (this.requestMethods && method.payment_gateway && method.payment_gateway.type) {
            const fullMethodType = method.payment_gateway.type;

            if (fullMethodType === 'system') {
                return true;
            }

            return this.requestMethods.some((im) => {
                let gatewayType = null;
                let methodType = null;
                if (im.payment_gateway) {
                    const gatewayTypes = /[a-z,A-Z]+/.exec(im.payment_gateway.type);
                    const methodTypes = /[a-z,A-Z]+/.exec(fullMethodType);

                    gatewayType = gatewayTypes && gatewayTypes[0] ? gatewayTypes[0] : null;
                    methodType = methodTypes && methodTypes[0] ? methodTypes[0] : null;
                }
                return gatewayType === methodType;
            });
        }
    }

    payDepositRequest() {
        if (this.payDataForm.invalid || !this.depositRequest) {
            this.payDataForm.markAllAsTouched();
            return;
        }
        const payData: {current_method: IPaymentMethod; payment_amount: any} = this.payDataForm.getRawValue();

        payData.payment_amount = Number(cleanCurrencyString(payData.payment_amount));

        const paymentRequestObject: IPaymentReceivedMadeRequestObject = {
            money_receiver__contact_fk_id: null,
            money_sender__contact_fk_id: this.depositRequest
                ? this.depositRequest.request_receiver__contact_fk_id
                : null,
            paid_date: Number(moment().format('YYYYMMDD')),
            scheduled_date: null,
            amount: payData.payment_amount,
            paid_by__ledger_account_fk_id: null,
            paid_by__payment_method_fk_id:
                payData.current_method && payData.current_method.payment_method_id
                    ? payData.current_method.payment_method_id
                    : null,
            pay_to__ledger_account_fk_id: null,
            pay_to__payment_method_fk_id: null,
            payments: [
                {
                    entity_id: this.depositRequest.deposit_request_id,
                    payment_id: null,
                    entity_transfer_id: null,
                    entity: null,
                    amount: payData.payment_amount
                }
            ],
            is_create_multiple_payments: false,
            payment_mode: null,
            payment_number: null,
            reference: null,
            notes: null,
            matched__transaction_external_id: null,
            check_info: null,
            deposit_release_id: null,
            is_locked_for_applying: false,
            allow_auto_apply: false,
            source__deal_fk_id: null,
            sender_velocity: null,
            restrict_downgrade: false
        };

        this.dealDepositService
            .createDealDepositPayment(this.depositRequest.deposit_request_id, paymentRequestObject)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                this.ngOnInit();
            });
    }

    validateAmount($event: any) {
        if (!this.depositRequest) {
            return null;
        }
        const inputValue = Number(cleanCurrencyString($event.target.value));

        const total = this.depositRequest.request_amount;
        let paymentsAmount = 0;

        if (this.depositRequest && this.depositRequest.payments && this.depositRequest.payments.length > 0) {
            for (const pay of this.depositRequest.payments) {
                if (!['expired', 'declined', 'canceled', 'error', 'reversed'].includes(pay.status)) {
                    paymentsAmount += pay.amount;
                }
            }
        }
        const availableAmount = total - paymentsAmount;

        if (availableAmount < inputValue) {
            this.payDataForm.controls.payment_amount.setValidators([Validators.email]);
            this.payDataForm.controls.payment_amount.updateValueAndValidity();
            this.payDataForm.controls.payment_amount.markAsTouched();
        } else {
            this.payDataForm.controls.payment_amount.clearValidators();
            this.payDataForm.controls.payment_amount.updateValueAndValidity();
        }
    }

    cancelPayment(payment: IPayment) {
        if (!payment || !payment.payment_id) {
            return;
        }

        const dialogRef = this.dialog.open(ConfirmComponent, {
            data: {
                title: 'Remove Payment',
                message: `Payment will be removed.`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((c) => !!c),
                takeUntil(this.unsubscribe)
            )
            .subscribe((confirm) => {
                if (confirm && payment && payment.payment_id) {
                    this.isCancelPaymentButtonDisabled = true;
                    this.paymentsService
                        .cancelPayment(payment.payment_id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((result) => {
                            if (result) {
                                this.isCancelPaymentButtonDisabled = false;
                                this.ngOnInit();
                            }
                        });
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
