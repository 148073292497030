<div style="height: 200px; padding-top: 30px">
    <div>
        <div style="display: flex; justify-content: center">
            <img
                style="width: 80px; height: 80px"
                src="https://storage.googleapis.com/zipi-static/app-assets/SkySlopeLoading_3.gif"
            />
        </div>
        <div style="display: flex; justify-content: center">
            <div>
                Sending payment <span *ngIf="currentDriver">to </span>
                <span class="font-weight-bold">{{ driverMap[currentDriver] }}</span>
            </div>
        </div>
    </div>
</div>
