import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Deal} from '../../../models/deal';

@Component({
    selector: 'app-user-suspend-dialog',
    styles: [
        `
            .mat-dialog-actions {
                justify-content: space-between;
                display: flex;
            }
            #errors {
                color: #af1515;
                padding-left: 15px;
                line-height: 2em;
                font-size: 14px;
            }
        `
    ],
    template: `
        <div mat-dialog-content>
            <p>{{ data && data.title ? data.title : 'Delete Profile' }}</p>
            <hr />
            <p>
                {{ data && data.descriptionPrefix ? data.descriptionPrefix : 'This profile' }} is associated with the
                following objects,<br />
                to delete, it must first be removed from each.<br />
                Alternatively, you may Suspend the profile without making any changes.
            </p>

            <mat-grid-list cols="3" rowHeight="30px">
                <mat-grid-tile [colspan]="1" [rowspan]="1"
                    ><div style="text-align: left; width: 100%;"><h3>Deals:</h3></div></mat-grid-tile
                >
                <mat-grid-tile [colspan]="2" [rowspan]="1"></mat-grid-tile>
                <mat-grid-tile [colspan]="3" [rowspan]="1" *ngFor="let deal of deals">
                    <a [routerLink]="['/deals', 'edit', deal.id]">{{ deal.name }}</a>
                </mat-grid-tile>
            </mat-grid-list>
        </div>

        <div mat-dialog-actions style="float: right">
            <button mat-raised-button class="mat-primary" (click)="dialogRef.close()">OK</button>
        </div>
    `
})
export class UserDeleteDialogComponent {
    deals: Deal[] = [];
    constructor(
        public dialogRef: MatDialogRef<UserDeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
}
