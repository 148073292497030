import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {GenericFormGroup} from '../../../../../entites/generic.entity';
import {IRole} from '@cyberco-nodejs/zipi-typings/index';

@Component({
    selector: 'app-company-role-feature',
    templateUrl: 'company-role-feature.component.html',
    styleUrls: ['company-role-feature.component.scss']
})
export class CompanyRoleFeatureComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() module: any;
    @Input() companyRoleForm: GenericFormGroup<IRole> | undefined;
    @Input() rawCompanyPermissionsObj: any;
    @Input() availableRolesPermissions: any;
    @Input() viewMode = false;

    allPermission: any;
    moduleAccessPermission: any;
    permissions:
        | [
              {
                  [key: string]: any;
              }
          ][]
        | undefined;
    isShowModuleDetails: boolean = false;

    ngOnInit() {
        this.allPermission = this.getAllPermission(this.module);
        this.moduleAccessPermission = this.getModuleAccessPermission(this.module);
        this.permissions = this.permissionsCategorizeByFeature(this.module);
        this.isShowModuleDetails = this.isChecked(this.moduleAccessPermission);
    }

    getFeatureInfo(
        feature: [
            {
                [key: string]: any;
            }
        ]
    ) {
        let featureInfo;
        feature.forEach((permission) => {
            if (permission.feature_info) {
                featureInfo = permission.feature_info;
            }
        });
        return featureInfo;
    }

    getFeaturePermissionByType(
        feature: [
            {
                [key: string]: any;
            }
        ],
        type: string
    ): {[key: string]: any} | undefined {
        return feature.find((permission) => permission.key.includes(`__${type}`));
    }

    getAnyPermission(
        feature: [
            {
                [key: string]: any;
            }
        ]
    ) {
        return feature.find((permission) => permission.key);
    }

    permissionsCategorizeByFeature(
        permissionsRawArray: [
            {
                [key: string]: any;
            }
        ],
        modules: string[] = []
    ): [{[key: string]: any}][] {
        const permObj = {};
        const features = [];
        for (const key in permissionsRawArray) {
            if (!permissionsRawArray.hasOwnProperty(key) || !permissionsRawArray[key].feature) {
                continue;
            }

            if (modules.length > 0) {
                if (!modules.includes(key)) {
                    continue;
                }
            }

            if (!permObj.hasOwnProperty(permissionsRawArray[key].feature)) {
                // @ts-ignore
                permObj[permissionsRawArray[key].feature] = [];
            }
            // @ts-ignore
            permObj[permissionsRawArray[key].feature].push(permissionsRawArray[key]);
        }
        for (const prop in permObj) {
            if (permObj.hasOwnProperty(prop)) {
                // @ts-ignore
                features.push(permObj[prop]);
            }
        }
        return features;
    }

    getAllPermission(permissions: any[]) {
        return permissions.find((permission) => permission.key.includes('__all__'));
    }

    getModuleAccessPermission(permissions: any[]) {
        return permissions.find((permission) => permission.key.includes('__access'));
    }

    isModuleAccessFeature(
        feature: [
            {
                [key: string]: any;
            }
        ]
    ) {
        return feature.find((permission) => permission.key.includes(`__access`));
    }

    isChecked(permission: {[key: string]: any} | undefined): boolean {
        if (typeof permission === 'undefined') {
            return false;
        }

        let permissionArray;
        if (this.companyRoleForm && this.companyRoleForm.controls.permissions) {
            permissionArray = this.companyRoleForm.controls.permissions.value;
        } else if (this.availableRolesPermissions) {
            permissionArray = this.availableRolesPermissions;
        }
        return permissionArray.some(
            (permObj: {key: string}) =>
                permObj.key === permission.key || permObj.key === `${permission.module}____all__`
        );
    }

    changePermissionSet(permission: {[key: string]: any} | undefined, isChecked: boolean) {
        if (typeof permission === 'undefined') {
            return;
        }

        if (this.companyRoleForm && this.companyRoleForm.controls.permissions) {
            let rolePermArr: [any] = this.companyRoleForm.controls.permissions.value;

            if (isChecked) {
                if (permission.key.includes('__access')) {
                    this.isShowModuleDetails = true;
                }

                if (permission.key.includes('__all__')) {
                    rolePermArr = this.addAllPermission(rolePermArr, permission);
                } else {
                    if (
                        !permission.key.includes('__all__') &&
                        permission.required_permissions_keys &&
                        permission.required_permissions_keys.length > 0
                    ) {
                        rolePermArr = this.addPermissionWithRequired(rolePermArr, permission);
                    } else {
                        rolePermArr.push(permission);
                    }
                }
            } else {
                if (permission.key.includes('__access')) {
                    this.isShowModuleDetails = false;
                }

                rolePermArr = this.deletePermissionWithRequired(rolePermArr, permission);
            }

            this.companyRoleForm.controls.permissions.setValue(rolePermArr);
        }
    }

    private addAllPermission(rolePermArr: [any], currentPermission: {[key: string]: any}) {
        for (const modulePerm of this.module) {
            const permissionIndex = rolePermArr.findIndex((perm) => perm.key === modulePerm.key);
            if (permissionIndex !== -1) {
                rolePermArr.splice(permissionIndex, 1);
            }
        }

        rolePermArr.push(currentPermission);

        return rolePermArr;
    }

    private addPermissionWithRequired(rolePermArr: [any], currentPermission: {[key: string]: any}) {
        if (this.companyRoleForm && this.companyRoleForm.controls.scope) {
            for (const reqKey of currentPermission.required_permissions_keys) {
                if (!rolePermArr.find((perm) => perm.key === reqKey)) {
                    rolePermArr.push(this.getRawPermissionsObject(this.companyRoleForm.controls.scope.value)[reqKey]);
                }
            }

            rolePermArr.push(currentPermission);
        }
        return rolePermArr;
    }

    private deletePermissionWithRequired(rolePermArr: [any], currentPermission: {[key: string]: any}) {
        const index = rolePermArr.findIndex((perm) => perm.key === currentPermission.key);

        if (index !== -1) {
            rolePermArr.splice(index, 1);
        } else {
            const allPermissionIndex = rolePermArr.findIndex(
                (perm) => perm.key === `${currentPermission.module}____all__`
            );
            if (allPermissionIndex !== -1) {
                rolePermArr.splice(allPermissionIndex, 1);
            }
        }

        for (const modulePerm of this.module) {
            if (modulePerm.required_permissions_keys.includes(currentPermission.key)) {
                const parentPermissionIndex = rolePermArr.findIndex((perm) => perm.key === modulePerm.key);
                if (parentPermissionIndex !== -1) {
                    rolePermArr.splice(parentPermissionIndex, 1);
                }
            }
        }

        return rolePermArr;
    }

    getRawPermissionsObject(scope: any) {
        switch (scope) {
            case 'company':
                return this.rawCompanyPermissionsObj;
            default:
                throw new Error(`Unknown scope ${scope}`);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
