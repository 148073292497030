import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {Observable} from 'rxjs';
import {IContactInvite, IProfile, IAuthAcceptInviteData} from '../../../../typings';
import {Company} from '../../../models/company';
import {Profile} from '../../../models/profile';
import {AvailableProfilesSource} from '../../../services/sources/available-profiles.source';

@Injectable()
export class ContactInvitesService {
    constructor(
        protected requester: ServiceRequester,
        public availableProfilesSource: AvailableProfilesSource
    ) {}

    getContactInviteByHash(inviteHash: string): Observable<IContactInvite> {
        return this.requester.makeMsCall$(`/public/contact-invites/${inviteHash}`, 'GET', 'ship');
    }

    submitAcceptInvite(inviteHash: string, data: IAuthAcceptInviteData): Observable<IProfile> {
        return this.requester.makeMsCall$(`/contact-invites/${inviteHash}/accept`, 'POST', 'ship', data);
    }

    createCompany(companyData: Company, is_paid_by_parent: boolean = false): Promise<Profile> {
        return this.requester
            .makeMsCall('/contact-invites/create-company', 'POST', {
                company: companyData,
                is_paid_by_parent: is_paid_by_parent
            })
            .then((newProfile) => {
                const newProfileEntity = Object.assign(new Profile(), newProfile);
                return newProfileEntity;
            });
    }
}
