<h1 mat-dialog-title>Exported Files</h1>
<div mat-dialog-content class="container">
    <div *ngFor="let file of files; index as number" class="row mt-1">
        <div class="col-md-10">
            <span
                >{{ number + 1 }}) <a href="{{ file }}">{{ file.split('/').pop() }}</a></span
            >
        </div>
    </div>
</div>
<div mat-dialog-actions class="actions">
    <button mat-button (click)="close()">Close</button>
</div>
