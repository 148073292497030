<div>
    <div>
        <h2>Request Resend</h2>

        <div class="my-2">Request will be resend on Email Address:</div>

        <mat-form-field class="w-100 mr-1">
            <input matInput [formControl]="requestReceiverEmail" placeholder="Email" type="text" autocomplete="off" />
        </mat-form-field>
    </div>

    <div class="d-flex justify-content-end mt-3">
        <button
            class="mr-2"
            *ngIf="!data.hideCancel"
            mat-raised-button
            color="accent"
            (click)="continue()"
            tabindex="-1"
        >
            Send Request
        </button>
        <button class="mr-2" *ngIf="!data.hideCancel" mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">
            Cancel
        </button>
    </div>
</div>
