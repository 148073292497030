<section class="container-fluid" *ngIf="reconciliation">
    <header class="my-2">
        <button type="button" mat-raised-button (click)="back()">
            <mat-icon>arrow_back</mat-icon>
            Back
        </button>
        <h1>Reconciliation Details for {{ reconciliation?.account?.name }}</h1>
    </header>
    <div>
        <div style="display: flex; justify-content: space-between">
            <div class="text-muted">
                Reconciliation Period:
                <ng-container *ngIf="reconciliation.start_date && reconciliation.end_date">
                    {{ reconciliation.start_date | dateFromNumber | date: 'MMM dd, YYYY' }} &ndash;
                    {{ reconciliation.end_date | dateFromNumber | date: 'MMM dd, YYYY' }}
                </ng-container>
                <br />
                Reconciled on:&nbsp;
                <ng-container *ngIf="reconciliation.reconciled_date">
                    {{ reconciliation.reconciled_date | dateFromNumber | date: 'MMM dd, YYYY' }}
                </ng-container>
            </div>
            <div>
                <table class="reconciliation-calculation" *ngIf="reconciliation">
                    <tr>
                        <td>
                            Opening Balance:<br />
                            <span class="text-muted" *ngIf="reconciliation.start_date">{{
                                reconciliation.start_date | dateFromNumber | date: 'MMM dd, YYYY'
                            }}</span>
                        </td>
                        <td>
                            <span
                                *ngIf="
                                    reconciliation.opening_balance !== null &&
                                    reconciliation.opening_balance !== undefined
                                "
                            >
                                {{ reconciliation.opening_balance | currency: 'USD' : 'symbol' }}
                            </span>
                            <br />
                            <span class="text-muted" *ngIf="reconciliation.opening_balance_debit_or_credit">
                                {{ reconciliation.opening_balance_debit_or_credit | fromSnakeCase }}
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 match-table w-100">
            <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef disableClear>Date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.journal_date | dateFromNumber | date: 'MMM dd, YYYY' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="contact">
                <th mat-header-cell *matHeaderCellDef>Payor / Payee</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element?.contact_name && element?.contact_fk_id">
                        <a routerLink="/contacts/{{ element?.contact_fk_id }}">{{ element.contact_name }}</a>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element">{{ element.journal_reason_type | fromSnakeCase }}</td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="!element?.description">{{ element?.description }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="deposits">
                <th mat-header-cell *matHeaderCellDef style="text-align: right">
                    {{ ledgerAccount && ledgerAccount.type === 'credit_card' ? 'Payments/Credits' : 'Deposit' }}
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: right">
                    <span *ngIf="element?.debit_or_credit === 'debit'">
                        {{ element?.amount | currency: 'USD' : 'symbol' }}
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="withdrawals">
                <th mat-header-cell *matHeaderCellDef style="text-align: right">
                    {{ ledgerAccount && ledgerAccount.type === 'credit_card' ? 'Purchases' : 'Withdrawal' }}
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: right">
                    <span *ngIf="element?.debit_or_credit === 'credit'">
                        {{ element?.amount | currency: 'USD' : 'symbol' }}
                    </span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="banking-row"></tr>
        </table>

        <div style="display: flex; justify-content: end">
            <table class="reconciliation-calculation" *ngIf="reconciliation">
                <tr>
                    <td>
                        Closing Balance:<br />
                        <span class="text-muted" *ngIf="reconciliation.end_date">{{
                            reconciliation.end_date | dateFromNumber | date: 'MMM dd, YYYY'
                        }}</span>
                    </td>
                    <td>
                        <span
                            *ngIf="
                                reconciliation.closing_balance !== null && reconciliation.closing_balance !== undefined
                            "
                        >
                            {{ reconciliation.closing_balance | currency: 'USD' : 'symbol' }}
                        </span>
                        <br />
                        <span class="text-muted" *ngIf="reconciliation.closing_balance_debit_or_credit">
                            {{ reconciliation.closing_balance_debit_or_credit | fromSnakeCase }}
                        </span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</section>
