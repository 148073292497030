<div style="width: 800px">
    <div mat-dialog-title>
        <h3>Confirm Products Mapping</h3>
    </div>

    <div *ngIf="recurringInvoices.controls.length > 0 && source_documents.length > 0" class="w-100 mb-3">
        <ng-container *ngFor="let inv of recurringInvoices.controls">
            <div [formGroup]="inv" class="w-100 zp-items row no-gutters justify-content-between">
                <h3 class="px-1 d-flex justify-content-start" style="padding-top: 4px">
                    Invoice: {{ inv.controls.invoice_number.value }}
                </h3>
                <div class="px-1 recurring-label">
                    <mat-checkbox [formControl]="inv.controls.auto_create_from_recurring" class="w-100">
                        Auto-Create future bills from this recurring invoice
                    </mat-checkbox>

                    <mat-form-field class="w-100" *ngIf="inv.controls.auto_create_from_recurring.value">
                        <label class="d-flex text-muted" style="font-size: 12px; margin-bottom: 4px">
                            Label Recurring Invoice
                            <mat-icon
                                class="ml-1"
                                style="font-size: 1rem; height: 1rem; width: 1rem"
                                matTooltip="This will be saved in the Contacts > 'Approvals' tab"
                                >info</mat-icon
                            >
                        </label>
                        <input matInput [formControl]="inv.controls.title" type="text" />
                    </mat-form-field>
                </div>
            </div>
        </ng-container>

        <mat-divider class="mt-3"></mat-divider>
    </div>

    <div class="zp-items row no-gutters" style="padding-left: 3%">
        <h4 class="col-4">
            Line Items from

            <ng-container *ngIf="data.payout"> Payout </ng-container>

            <ng-container *ngIf="source_documents.length === 1">
                <span *ngIf="source_document?.invoice_id!"> Invoice </span>
                <span *ngIf="source_document?.bill_id"> Bill </span>
            </ng-container>
            <ng-container *ngIf="source_documents.length > 1">
                <span *ngIf="source_document?.invoice_id"> Invoices </span>
                <span *ngIf="source_document?.bill_id"> Bills </span>
            </ng-container>
        </h4>

        <h4 class="col-6">Select Corresponding Product or Category:</h4>
    </div>
    <mat-card *ngFor="let productGroup of prodArray.controls; index as idx; first as first" class="mb-1">
        <div *ngIf="productGroup && productGroup.controls.match_product.value" class="d-flex flex-wrap">
            <div class="text-muted" style="padding-top: 4px; width: 3%">{{ idx + 1 }}</div>
            <div style="width: 97%" [formGroup]="productGroup">
                <div class="zp-items row no-gutters">
                    <div class="col-4 px-1" style="padding-top: 2px">
                        <div
                            class="d-flex align-items-center"
                            (click)="expandGroup(productGroup)"
                            style="cursor: pointer; font-size: 18px"
                        >
                            {{ productGroup.controls.name.value }}
                            <mat-icon *ngIf="!productGroup.controls.is_expanded.value">chevron_right</mat-icon>
                            <mat-icon *ngIf="productGroup.controls.is_expanded.value">expand_more</mat-icon>
                        </div>
                    </div>

                    <!-- Product mapping-->
                    <div
                        class="col-4 px-1"
                        [formGroup]="productGroup.controls.match_product"
                        [matTooltip]="'Item has no product and cannot be mapped'"
                        [matTooltipDisabled]="productGroup.controls.product_fk_id.value"
                    >
                        <ng-container *ngIf="!productGroup.controls.match_product.controls.product_fk_id.value">
                            <mat-form-field class="w-100" [floatLabel]="'never'">
                                <input
                                    formControlName="name"
                                    type="text"
                                    matInput
                                    placeholder="Product"
                                    [matAutocomplete]="auto"
                                />
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option (click)="$event.stopPropagation(); createProduct()">
                                        Create New Product
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let product of product$ | async"
                                        [value]="product.name"
                                        (onSelectionChange)="
                                            selectItem(
                                                productGroup.controls.match_product,
                                                product,
                                                $any(productGroup).controls.items.controls
                                            )
                                        "
                                    >
                                        {{ product.name }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="productGroup.controls.match_product.controls.product_fk_id.value">
                            <mat-form-field class="w-100" [floatLabel]="'never'">
                                <input formControlName="name" matInput type="text" />
                                <button
                                    mat-button
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Clear"
                                    (click)="removeProductFkId(productGroup.controls.match_product)"
                                >
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                        </ng-container>
                    </div>

                    <div
                        class="col-4 px-1"
                        [matTooltip]="'Item has no product and cannot be mapped'"
                        [matTooltipDisabled]="productGroup.controls.product_fk_id.value"
                    >
                        <app-ledger-account-selector
                            class="w-100"
                            [disabledReselect]="productGroup.controls.match_product.controls.product_fk_id.value"
                            [ledgerAccountControl]="productGroup.controls.match_product.controls.ledger_account_fk_id"
                            [disabled]="!productGroup.controls.product_fk_id.value"
                            [required]="false"
                            [placeholder]="'Account'"
                            [floatLabel]="'never'"
                        >
                        </app-ledger-account-selector>
                    </div>
                </div>

                <ng-container *ngIf="productGroup.controls.is_expanded.value">
                    <div class="zp-items row no-gutters" style="background: #e0e0e0">
                        <div
                            class="col-3 px-1 d-flex justify-content-start"
                            style="font-size: 12px; word-break: break-all"
                        >
                            Description
                        </div>
                        <div
                            class="col-1 px-1 d-flex justify-content-end"
                            style="font-size: 12px; word-break: break-all"
                        >
                            Amount
                        </div>
                    </div>
                    <div
                        class="zp-items row no-gutters"
                        *ngFor="let itemGroup of $any(productGroup).controls.items.controls"
                    >
                        <ng-container [formGroup]="itemGroup">
                            <div class="col-3 px-1 d-flex justify-content-start text-muted" style="padding-top: 4px">
                                {{ itemGroup.controls.invoice_item_description.value }}
                                <span *ngIf="itemGroup.controls.entity_number.value"
                                    >({{ itemGroup.controls.entity_number.value }})</span
                                >
                            </div>
                            <div class="col-1 px-1 d-flex justify-content-end text-muted" style="padding-top: 4px">
                                {{ itemGroup.controls.amount.value | currency }}
                            </div>
                            <div class="col-8 px-1 d-flex">
                                <div class="w-50 px-1" [formGroup]="itemGroup.controls.match_product">
                                    <ng-container
                                        *ngIf="!itemGroup.controls.match_product.controls.product_fk_id.value"
                                    >
                                        <mat-form-field class="w-100" [floatLabel]="'never'">
                                            <input
                                                formControlName="name"
                                                type="text"
                                                matInput
                                                placeholder="Product"
                                                [matAutocomplete]="auto"
                                            />
                                            <mat-autocomplete #auto="matAutocomplete">
                                                <ng-container *ngIf="itemGroup.controls.product_fk_id.value">
                                                    <mat-option (click)="$event.stopPropagation(); createProduct()">
                                                        Create New Product
                                                    </mat-option>
                                                    <mat-option
                                                        *ngFor="let product of product$ | async"
                                                        [value]="product.name"
                                                        (onSelectionChange)="
                                                            selectItem(itemGroup.controls.match_product, product)
                                                        "
                                                    >
                                                        {{ product.name }}
                                                    </mat-option>
                                                </ng-container>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngIf="itemGroup.controls.match_product.controls.product_fk_id.value">
                                        <mat-form-field class="w-100" [floatLabel]="'never'">
                                            <input formControlName="name" matInput type="text" />
                                            <button
                                                mat-button
                                                matSuffix
                                                mat-icon-button
                                                aria-label="Clear"
                                                (click)="removeProductFkId(itemGroup.controls.match_product)"
                                            >
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </ng-container>
                                </div>

                                <div class="w-50 px-1">
                                    <app-ledger-account-selector
                                        class="w-100"
                                        [disabledReselect]="
                                            itemGroup.controls.match_product.controls.product_fk_id.value
                                        "
                                        [ledgerAccountControl]="
                                            itemGroup.controls.match_product.controls.ledger_account_fk_id
                                        "
                                        [placeholder]="'Account'"
                                        [floatLabel]="'never'"
                                    >
                                    </app-ledger-account-selector>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </mat-card>

    <mat-dialog-actions class="d-flex my-2 justify-content-end align-items-end">
        <div class="d-flex align-items-center mt-1" *ngIf="hasProductForMapping && this.contact">
            <mat-checkbox class="no-margin-label" [(ngModel)]="need_save_map">Save Products Mapping</mat-checkbox>
        </div>
        <button mat-raised-button *ngIf="!data.hideCancel" (click)="dialogRef.close(false)" tabindex="-1" class="ml-2">
            Cancel
        </button>
        <button mat-raised-button color="primary" (click)="continue()" tabindex="1" class="ml-2">Continue</button>
    </mat-dialog-actions>
</div>
