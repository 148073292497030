<mat-sidenav-container class="sidenav-group-details-container w-100">
    <mat-sidenav
        position="end"
        mode="over"
        [(opened)]="showGroupDetails"
        class="sidenav-group-content pt-5 p-2"
        [fixedInViewport]="true"
        [autoFocus]="false"
    >
        <app-money-senders-details
            *ngIf="showGroupDetails"
            [showGroupDetails]="showGroupDetails"
            [contactId]="formGroup.controls.money_sender__contact_fk_id.value"
            [groupId]="formGroup.controls.money_senders__company_group_fk_id.value"
            [contactClassId]="formGroup.controls.money_sender__contact_class_fk_id.value"
            [recurringInvoiceId]="_invoice ? _invoice.recurring_invoice_id : null"
            [recurringInvoiceForm]="formGroup"
            [companyGateways]="companyGateways!"
        >
        </app-money-senders-details>
    </mat-sidenav>
    <mat-sidenav-content style="overflow: visible">
        <section [formGroup]="formGroup">
            <div class="row mr-0 mb-3">
                <div class="col col-md-6">
                    <div class="d-flex align-items-baseline">
                        <app-company-compensation-combined-picker
                            style="width: 100%; margin-right: 8px"
                            [availableTypes]="['individuals_in_group', 'contact', 'contact_class']"
                            [title]="'Customer'"
                            [only_compensation_groups]="false"
                            [singleSelected]="true"
                            [bold_style]="false"
                            [nodesFA]="moneySenderCtrlArr"
                            [initialContactIds]="savedContacts"
                            [initialProfilesInGroupIds]="savedGroups"
                            [initialContactClassIds]="savedContactClasses"
                        >
                        </app-company-compensation-combined-picker>

                        <button
                            type="button"
                            mat-icon-button
                            matSuffix
                            (click)="contactCreate()"
                            [anyRule]="[
                                {contacts__manage_limited_contacts: true},
                                {contacts__manage_any_contacts: true}
                            ]"
                            rbac
                            matTooltip="Create Contact"
                        >
                            <mat-icon>person_add</mat-icon>
                        </button>

                        <div
                            matTooltip="You must first create a Recurring Invoice."
                            [matTooltipDisabled]="!!_invoice && !!_invoice.recurring_invoice_id"
                        >
                            <button
                                type="button"
                                class="ml-2"
                                mat-raised-button
                                style="min-width: 80px"
                                [disabled]="
                                    (!formGroup.controls.money_sender__contact_fk_id.value &&
                                        !formGroup.controls.money_senders__company_group_fk_id.value &&
                                        !formGroup.controls.money_sender__contact_class_fk_id.value) ||
                                    !_invoice ||
                                    !_invoice.recurring_invoice_id
                                "
                                (click)="showGroupDetails = !showGroupDetails"
                            >
                                Auto-Charge Settings
                            </button>
                        </div>
                    </div>
                </div>

                <mat-form-field
                    class="col"
                    [ngClass]="{
                        'col-md-6':
                            !formGroup.controls.auto_charge_terms.value ||
                            formGroup.controls.auto_charge_terms.value !== 'custom',
                        'col-md-3': formGroup.controls.auto_charge_terms.value === 'custom'
                    }"
                >
                    <mat-select formControlName="auto_charge_terms" placeholder="Auto-Charging Date">
                        <mat-option *ngFor="let pt of paymentTerms" [value]="pt[0]">{{ pt[1] }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col col-md-3" *ngIf="formGroup.controls.auto_charge_terms.value === 'custom'">
                    <input
                        matInput
                        type="number"
                        min="1"
                        placeholder="Number of days"
                        formControlName="auto_charge_terms_custom"
                    />
                </mat-form-field>

                <div class="col col-md-6">
                    <mat-checkbox formControlName="is_deduct_from_deal">Allow to deduct from deal</mat-checkbox>
                </div>

                <div class="col col-md-6">
                    <app-company-compensation-combined-picker
                        style="width: 100%; margin-right: 8px"
                        [availableTypes]="['individual']"
                        [title]="'Sales Person'"
                        [singleSelected]="true"
                        [bold_style]="false"
                        [nodesFA]="selectedProfileArr"
                        [initialProfileIds]="selectedProfileIds"
                    ></app-company-compensation-combined-picker>
                </div>

                <mat-form-field
                    class="col"
                    [ngClass]="{
                        'col-md-6':
                            !formGroup.controls.payment_terms.value ||
                            formGroup.controls.payment_terms.value !== 'custom',
                        'col-md-3': formGroup.controls.payment_terms.value === 'custom'
                    }"
                >
                    <mat-select formControlName="payment_terms" placeholder="Payment Terms">
                        <mat-option *ngFor="let pt of paymentTerms" [value]="pt[0]">{{ pt[1] }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col col-md-3" *ngIf="formGroup.controls.payment_terms.value === 'custom'">
                    <input
                        matInput
                        type="number"
                        min="1"
                        placeholder="Number of days"
                        formControlName="payment_terms_custom"
                    />
                </mat-form-field>

                <div class="col col-md-3">
                    <mat-form-field class="w-100">
                        <input
                            matInput
                            type="text"
                            placeholder="Late fee"
                            [maskito]="currencyMaskitoMask"
                            formControlName="late_fee"
                        />
                    </mat-form-field>
                </div>

                <div class="col col-md-3">
                    <app-product-service-selector
                        [productServiceControl]="formGroup.controls.late_fee_product_fk_id"
                        [placeholder]="'Product/Service'"
                    >
                    </app-product-service-selector>
                </div>
            </div>

            <!------- Accepted Forms of Payment ------->
            <app-select-pay-to-methods
                *ngIf="!recurring_invoice_id || (!!recurring_invoice_id && !!_invoice)"
                [payToCardControl]="formGroup.controls.pay_to_card__payment_method_fk_id"
                [payToBankControl]="formGroup.controls.pay_to_bank__payment_method_fk_id"
                [isNeedToPresetDefault]="!_invoice || !_invoice.recurring_invoice_id"
                [convenienceFeeBankPayerControl]="formGroup.controls.bank_fee_payer"
                [convenienceFeeCardPayerControl]="formGroup.controls.card_fee_payer"
                [allowedGatewayTypes]="[
                    'authorize_net_merchant',
                    'zipi_financial_card_merchant',
                    'zipi_financial_business'
                ]"
                [pickerType]="'separated'"
            >
            </app-select-pay-to-methods>

            <!------- Items Header ------->
            <h3 class="d-flex align-items-center mt-3">
                Items
                <button
                    type="button"
                    mat-raised-button
                    (click)="addItem()"
                    matTooltip="Add item"
                    class="short-button ml-2"
                >
                    <mat-icon>add</mat-icon>
                </button>
            </h3>
            <!------- Items ------->
            <div class="overflow-auto">
                <div
                    *ngFor="let itemGroup of itemsArray.controls; index as idx; first as first"
                    class="d-grid-items-container"
                    [ngClass]="groupList.length ? 'with-groups' : 'no-groups'"
                >
                    <div class="d-grid-items" [formGroup]="itemGroup">
                        <!------- Product/Service ------->
                        <div class="mx-2">
                            <ng-container *ngIf="!itemGroup.controls.product_fk_id.value">
                                <mat-form-field class="w-100">
                                    <input
                                        formControlName="name"
                                        type="text"
                                        matInput
                                        placeholder="Product/Service"
                                        (blur)="trimOnBlur(itemGroup.controls.name)"
                                        [matAutocomplete]="auto"
                                    />
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <mat-option (click)="$event.stopPropagation(); createProduct()"
                                            >Create New Product
                                        </mat-option>
                                        <mat-option
                                            *ngFor="let product of getProducts(itemGroup)"
                                            [value]="product.name"
                                            [disabled]="
                                                ['closing_debit', 'closing_credit'].includes(product.system_key)
                                            "
                                            (onSelectionChange)="selectItem(itemGroup, product)"
                                        >
                                            {{ product.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="itemGroup.controls.product_fk_id.value">
                                <mat-form-field class="w-100">
                                    <input formControlName="name" matInput type="text" />
                                    <button
                                        mat-button
                                        matSuffix
                                        mat-icon-button
                                        aria-label="Clear"
                                        (click)="removeProductFkId(itemGroup)"
                                    >
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                            </ng-container>
                        </div>
                        <!------- Description ------->
                        <div class="mx-2">
                            <mat-form-field class="w-100">
                                <input
                                    matInput
                                    formControlName="description"
                                    placeholder="Description"
                                    type="text"
                                    (blur)="trimOnBlur(itemGroup.controls.description)"
                                    hasWildcardSelected
                                />
                            </mat-form-field>
                        </div>
                        <!------- Quantity ------->
                        <div class="mx-2">
                            <app-wildcard-picker
                                [type]="'quantity'"
                                [placeholder]="'Quantity'"
                                [placeholderAttribute]="'Custom Attribute Quantity'"
                                [productFC]="itemGroup.controls.product_fk_id"
                                [valueFC]="itemGroup.controls.quantity"
                                [valueWildcardIdFC]="itemGroup.controls.quantity__wildcard_fk_id"
                                style="
                                    z-index: 2;
                                    justify-content: space-between;
                                    display: flex;
                                    align-items: center;
                                    margin-right: 5px;
                                "
                            >
                            </app-wildcard-picker>
                        </div>
                        <!------- Rate ------->
                        <div class="mx-2">
                            <app-wildcard-picker
                                [placeholder]="'Rate'"
                                [placeholderAttribute]="'Custom Attribute Price'"
                                [productFC]="itemGroup.controls.product_fk_id"
                                [valueFC]="itemGroup.controls.rate"
                                [valueWildcardIdFC]="itemGroup.controls.rate__wildcard_fk_id"
                                [type]="rateWithCurrencyMask[idx] ? 'currency' : 'quantity'"
                                style="
                                    z-index: 2;
                                    justify-content: space-between;
                                    display: flex;
                                    align-items: center;
                                    margin-right: 5px;
                                "
                            >
                            </app-wildcard-picker>
                        </div>
                        <!------- Amount ------->
                        <div class="mx-2">
                            <mat-form-field
                                class="w-100"
                                *ngIf="
                                    !itemGroup.controls.rate__wildcard_fk_id.value &&
                                    !itemGroup.controls.quantity__wildcard_fk_id.value
                                "
                            >
                                <input
                                    matInput
                                    formControlName="amount"
                                    placeholder="Amount"
                                    type="text"
                                    [maskito]="amountWithCurrencyMask[idx] ? currencyMaskitoMask : numberMaskitoMask"
                                />
                            </mat-form-field>
                            <mat-form-field
                                class="w-100"
                                *ngIf="
                                    itemGroup.controls.rate__wildcard_fk_id.value ||
                                    itemGroup.controls.quantity__wildcard_fk_id.value
                                "
                            >
                                <input matInput type="text" value="N/A" placeholder="Amount" disabled />
                                <button matSuffix mat-icon-button style="cursor: default">
                                    <mat-icon matTooltip="Item amount will be calculated on invoice creation.">
                                        error_outline
                                    </mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                        <!------- Ledger Account ------->
                        <div class="mx-2">
                            <app-ledger-account-selector
                                class="w-100"
                                [ledgerAccountControl]="itemGroup.controls.ledger_account_fk_id"
                                [ledgerAccountFullControl]="itemGroup.controls.selected_full_ledger"
                                [disabledReselect]="!!itemGroup.controls.product_fk_id.value"
                                [placeholder]="'Account'"
                            >
                            </app-ledger-account-selector>
                        </div>
                        <!------- Assign to Division ------->
                        <div class="mx-2" *ngIf="groupList.length">
                            <app-division-picker
                                [isNeedToBeFiltered]="
                                    itemGroup.controls.selected_full_ledger.value
                                        ? !itemGroup.controls.selected_full_ledger.value.is_accessible_for_all
                                        : false
                                "
                                [filterById]="
                                    itemGroup.controls.selected_full_ledger.value
                                        ? itemGroup.controls.selected_full_ledger.value.accessible_for
                                        : []
                                "
                                [nodesFA]="itemGroup.controls.selected_divisions"
                                [initialGroupId]="itemGroup.controls.selected_division_ids.value"
                                [isTotallyDisabled]="!itemGroup.controls.ledger_account_fk_id.value"
                                [placeholder]="'Assign to Division'"
                            >
                            </app-division-picker>
                        </div>
                    </div>
                    <!------- Actions ------->
                    <div class="actions">
                        <button
                            type="button"
                            mat-icon-button
                            matTooltip="Clear Item"
                            *ngIf="itemsArray.length === 1"
                            (click)="resetItem(itemGroup)"
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                        <button
                            type="button"
                            mat-icon-button
                            matTooltip="Remove Item"
                            *ngIf="itemsArray.length > 1"
                            (click)="itemsArray.removeAt(idx)"
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end align-items-baseline">
                <div *ngIf="hasWildcardSelected">
                    <mat-radio-group [formControl]="formGroup.controls.wildcard_usage_mode">
                        <label class="w-100">If Contact does not have Custom Attribute:</label>
                        <mat-radio-button value="product" class="w-100">
                            <div class="d-flex align-items-center">
                                Use Product's Default Price
                                <mat-icon matTooltip="If product didn't selected, the line will be skipped.">
                                    error_outline
                                </mat-icon>
                            </div>
                        </mat-radio-button>
                        <mat-radio-button value="skip" class="w-100"> Skip Line Item </mat-radio-button>
                    </mat-radio-group>
                </div>
                <span class="sp-spacer"></span>

                <span class="mr-2"> Total amount: &nbsp; </span>
                <strong class="zp-total-amount" *ngIf="!hasWildcardSelected">
                    {{ formGroup.controls.total_amount.value | currency }}
                </strong>
                <strong class="zp-total-amount d-inline-flex" *ngIf="hasWildcardSelected">
                    N/A
                    <mat-icon matTooltip="Total amount will be calculated on invoice creation.">
                        error_outline
                    </mat-icon>
                </strong>
            </div>
        </section>
    </mat-sidenav-content>
</mat-sidenav-container>
