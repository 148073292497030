import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';

interface IDialogData {
    message: string;
}

@Component({
    selector: 'app-delete-role-confirm-dialog',
    template: `
        <div mat-dialog-content>
            <p>Delete Role</p>
            <hr />
            <p>{{ data.message }}<br /></p>
        </div>

        <div class="text-right">
            <button mat-button class="mat-primary" (click)="dialogRef.close()" style="margin-right: 5px;">
                CANCEL
            </button>
            <button mat-raised-button class="mat-primary" (click)="dialogRef.close(true)">Confirm</button>
        </div>
    `
})
export class DeleteRoleConfirmDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() message: string | undefined;
    constructor(
        public dialogRef: MatDialogRef<DeleteRoleConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData
    ) {}
    ngOnInit() {}

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
