<!--Single Ledger Account picker-->
<ng-container *ngIf="mode === 'single'">
    <ng-container *ngIf="!ledgerAccountControl.value" [formGroup]="formGroup">
        <mat-form-field class="w-100" [floatLabel]="floatLabel">
            <input
                type="search"
                matInput
                [required]="required"
                [formControl]="filterControl"
                placeholder="{{ placeholder }}"
                autocomplete="off"
                [matAutocomplete]="autoGroup"
            />
            <mat-autocomplete #autoGroup="matAutocomplete" #parentSelect [panelWidth]="'auto'">
                <ng-container *ngIf="preferredTypes.length > 0">
                    <mat-optgroup
                        *ngFor="let typeGroup of preferredLedgerAccountsByType | keyvalue"
                        [label]="ledgerTypesMap[typeGroup.key]"
                    >
                        <ng-container *ngFor="let la of typeGroup.value">
                            <mat-option
                                *ngIf="la.ledger_account_id"
                                [value]="la.name"
                                [disabled]="
                                    disabledAccountSystemKeys.includes(la.system_key) ||
                                    disabledAccountIds.includes(la.ledger_account_id) ||
                                    (la.status === 'inactive' && !isInactiveSelectable)
                                "
                                (onSelectionChange)="selectItem(la)"
                            >
                                <ng-container *ngIf="la.parent__ledger_account_fk_id && la.level">
                                    <span
                                        style="padding-left: 1em"
                                        *ngFor="let lvl of [].constructor(la.level); let i = index"
                                    >
                                        <span *ngIf="i === [].constructor(la.level).length - 1 && parentSelect.isOpen"
                                            >&#8226;</span
                                        >
                                    </span>
                                </ng-container>
                                {{ la.name }} <span *ngIf="la.code">({{ la.code }})</span>
                                <span *ngIf="la.status === 'inactive'">(Deactivated)</span>
                            </mat-option>
                        </ng-container>
                    </mat-optgroup>

                    <mat-divider></mat-divider>
                </ng-container>

                <mat-optgroup
                    *ngFor="let typeGroup of ledgerAccountsByType | keyvalue"
                    [label]="ledgerTypesMap[typeGroup.key]"
                >
                    <ng-container *ngFor="let la of typeGroup.value">
                        <mat-option
                            *ngIf="la.ledger_account_id"
                            [value]="la.name"
                            [disabled]="
                                disabledAccountSystemKeys.includes(la.system_key) ||
                                disabledAccountIds.includes(la.ledger_account_id) ||
                                (la.status === 'inactive' && !isInactiveSelectable)
                            "
                            (onSelectionChange)="selectItem(la)"
                        >
                            <ng-container *ngIf="la.parent__ledger_account_fk_id">
                                <span
                                    style="padding-left: 1em"
                                    *ngFor="let lvl of [].constructor(la.level); let i = index"
                                >
                                    <span *ngIf="i === [].constructor(la.level).length - 1 && parentSelect.isOpen"
                                        >&#8226;</span
                                    >
                                </span>
                            </ng-container>
                            {{ la.name }} <span *ngIf="la.code">({{ la.code }})</span>
                            <span *ngIf="la.status === 'inactive'">(Deactivated)</span>
                        </mat-option>
                    </ng-container>
                </mat-optgroup>
            </mat-autocomplete>
            <mat-hint align="end" *ngIf="noAccounts">No ledger accounts</mat-hint>
        </mat-form-field>
    </ng-container>

    <ng-container *ngIf="ledgerAccountControl.value">
        <mat-form-field class="w-100" [floatLabel]="floatLabel">
            <input [formControl]="filterControl" matInput type="text" placeholder="{{ placeholder }}" />
            <div matSuffix class="d-flex">
                <div class="mr-1">
                    <mat-icon
                        style="font-size: 20px"
                        *ngIf="singleSelectedLedgerAccount && singleSelectedLedgerAccount.status === 'inactive'"
                        matTooltip="Account Deactivated"
                    >
                        report_problem
                    </mat-icon>
                </div>
                <button
                    mat-button
                    mat-icon-button
                    aria-label="Clear"
                    [disabled]="disabledReselect"
                    (click)="removeAccountFkId()"
                >
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </mat-form-field>
    </ng-container>
</ng-container>

<!--Array of Ledger Accounts-->

<ng-container *ngIf="mode === 'multi'">
    <mat-form-field class="w-100">
        <mat-chip-list #chipLedgerAccountList aria-label="Ledger account selection">
            <ng-container *ngFor="let account of accounts">
                <mat-chip
                    *ngIf="
                        ledgerAccountsArrControl.value &&
                        ledgerAccountsArrControl.value.length &&
                        account.ledger_account_id &&
                        ledgerAccountsArrControl.value.includes(account.ledger_account_id)
                    "
                    (removed)="removeLedgerAccount(account.ledger_account_id)"
                >
                    {{ account.name }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </ng-container>

            <input
                #accountInput
                placeholder="{{ placeholder }}"
                autocomplete="off"
                [formControl]="filterControl"
                [matAutocomplete]="autoLedgerAccount"
                [matChipInputFor]="chipLedgerAccountList"
            />
        </mat-chip-list>

        <mat-autocomplete #autoLedgerAccount="matAutocomplete" #parentSelect [panelWidth]="'auto'">
            <mat-optgroup
                *ngFor="let typeGroup of ledgerAccountsByType | keyvalue"
                [label]="ledgerTypesMap[typeGroup.key]"
            >
                <ng-container *ngFor="let la of typeGroup.value">
                    <mat-option
                        *ngIf="
                            la.ledger_account_id &&
                            (!ledgerAccountsArrControl.value ||
                                !ledgerAccountsArrControl.value.length ||
                                !ledgerAccountsArrControl.value.includes(la.ledger_account_id))
                        "
                        [value]="la.name"
                        [disabled]="
                            disabledAccountIds.includes(la.ledger_account_id) ||
                            (la.status === 'inactive' && !isInactiveSelectable)
                        "
                        (onSelectionChange)="multiSelectItem(la)"
                    >
                        <ng-container *ngIf="la.parent__ledger_account_fk_id">
                            <span style="padding-left: 1em" *ngFor="let lvl of [].constructor(la.level); let i = index">
                                <span *ngIf="i === [].constructor(la.level).length - 1 && parentSelect.isOpen"
                                    >&#8226;</span
                                >
                            </span>
                        </ng-container>
                        {{ la.name }} <span *ngIf="la.code">({{ la.code }})</span>
                        <span *ngIf="la.status === 'inactive'">(Deactivated)</span>
                    </mat-option>
                </ng-container>
            </mat-optgroup>
        </mat-autocomplete>
        <mat-hint align="start" *ngIf="noAccounts">No ledger accounts</mat-hint>
    </mat-form-field>
</ng-container>
