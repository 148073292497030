import {GenericEntity} from '../entites/generic.entity';
import {IContactTypeMetatype} from '@cyberco-nodejs/zipi-typings/contacts.typings';

export class ContactType extends GenericEntity {
    id?: number = undefined;
    contact_type_id?: number = undefined;
    company_fk_id: number | null = null;
    title: string | null = null;
    metatype: IContactTypeMetatype | null = null;
    is_system: boolean = false;
    delegated_to_companies_ids: Array<number> = [];

    static get provideSystemContactTypesIds() {
        return [1000, 2000, 3000];
    }

    static get provideMetatypes() {
        return ['agent', 'customer', 'vendor', 'other'];
    }

    static get metatype_SET() {
        return {
            agent: 'agent',
            customer: 'customer',
            vendor: 'vendor',
            other: 'other'
        };
    }

    static get metatype_LABELS(): {[index: string]: string} {
        return {
            agent: 'Agent',
            customer: 'Customer',
            vendor: 'Vendor',
            other: 'Other'
        };
    }

    static get mapCategoryToSystemContactTypeId() {
        return {
            agent: this.provideSystemContactTypesIds[0],
            customer: this.provideSystemContactTypesIds[1],
            vendor: this.provideSystemContactTypesIds[2],
            other: undefined
        };
    }

    // setType(type) {
    //     this.type = type;
    //     return this;
    // }
}
