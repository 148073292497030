<mat-chip
    class="p-0 px-1 m-0 mr-2 mt-1 chip-item"
    *ngIf="ni !== undefined"
    [disabled]="disabled"
    [selectable]="!hideControls"
    [removable]="!hideControls"
    (removed)="doRemoveNodeFAEmitter.next(ni)"
>
    <!------- Icon ------->
    <mat-icon class="chip-icon" *ngIf="nodeFG.controls?.type?.value === CHIP_NODE.type_SET.individual">
        person
    </mat-icon>
    <mat-icon class="chip-icon" *ngIf="nodeFG.controls?.type?.value === CHIP_NODE.type_SET.individuals_in_group">
        people
    </mat-icon>
    <mat-icon class="chip-icon" *ngIf="nodeFG.controls?.type?.value === CHIP_NODE.type_SET.company_group">
        domain
    </mat-icon>
    <mat-icon class="chip-icon" *ngIf="nodeFG.controls?.type?.value === CHIP_NODE.type_SET.contact">
        {{ getContactIcon(nodeFG.controls.target_id?.value) }}
    </mat-icon>
    <mat-icon class="chip-icon" *ngIf="nodeFG.controls?.type?.value === CHIP_NODE.type_SET.contact_wildcard">
        recent_actors
    </mat-icon>
    <mat-icon class="chip-icon" *ngIf="nodeFG.controls?.type?.value === CHIP_NODE.type_SET.wildcard">
        recent_actors
    </mat-icon>
    <mat-icon
        class="material-icons-outlined chip-icon"
        *ngIf="nodeFG.controls?.type?.value === CHIP_NODE.type_SET.contact_class"
    >
        people_alt
    </mat-icon>

    <!------- Sub-type Icon ------->
    <mat-icon
        class="m-1 info-sub-icon material-icons-outlined"
        *ngIf="
            dealParticipantsWithSubType &&
            subTypesArray.length &&
            ((nodeFG.controls.permissions &&
                nodeFG.controls.permissions.value &&
                !nodeFG.controls.permissions.value.hidden) ||
                !nodeFG.controls.permissions ||
                !nodeFG.controls.permissions.value)
        "
        matTooltipClass="custom-sub-tooltip"
        [matTooltip]="handleSubTypes(nodeFG.controls.subType)"
        matTooltipPosition="before"
        [matMenuTriggerFor]="infoSubMenu"
    >
        {{ nodeFG.controls?.subType?.getRawValue()?.length ? 'label' : 'label_off' }}
    </mat-icon>

    <!------- Sub-type select ------->
    <mat-menu #infoSubMenu="matMenu">
        <mat-form-field class="mx-2" *ngIf="subTypesArray.length">
            <mat-label>Select Sub-types</mat-label>
            <mat-select
                [(ngModel)]="nodeFG.value.subType"
                [compareWith]="compareObjects"
                multiple
                (valueChange)="onSubTypeChange($event, nodeFG.controls.subType)"
            >
                <mat-optgroup
                    *ngFor="let group of subTypesArray; let i = index; trackBy: trackByLabel"
                    [label]="$any(group)?.title || '' | fromSnakeCase | titlecase"
                >
                    <mat-option
                        *ngFor="let type of $any(group).values; let idx = index; trackBy: trackByLabel"
                        [value]="type"
                        [disabled]="
                            subTypeAlreadySelected(
                                nodeFG.controls?.subType?.getRawValue(),
                                $any(group).title,
                                type.label
                            )
                        "
                    >
                        {{ type?.value || type.label | fromCamelCase | titlecase }}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
    </mat-menu>

    <!------- Label ------->
    <span
        [class.grey-out]="seemDisabled"
        class="ml-1 {{ lineClamp ? 'chip-item-text' : '' }}"
        #chipText
        [ngClass]="
            nodeFG.controls.permissions && nodeFG.controls.permissions.value && nodeFG.controls.permissions.value.hidden
                ? 'hidden-contact'
                : ''
        "
        [matTooltip]="
            chipText.offsetHeight < chipText.scrollHeight ? (nodeFG.controls?.label?.value | reduceDoubledString) : ''
        "
    >
        {{ nodeFG.controls?.label?.value | reduceDoubledString }}
    </span>

    <!------- If we searching through the sub-companies ------->
    <ng-container
        *ngIf="
            allowSubCompanySearch &&
            chosenSubCompanies.value.length > 0 &&
            !(nodeFG.controls.type?.value === CHIP_NODE.type_SET.contact_wildcard)
        "
    >
        <span class="font-italic ml-1" style="color: darkgray">
            ({{ getCompanyNameById(nodeFG.controls?.company_fk_id?.value) }})
        </span>
    </ng-container>

    <!------- Icon ------->
    <mat-icon class="ml-1" *ngIf="!hideControls" matChipRemove>cancel</mat-icon>
</mat-chip>
