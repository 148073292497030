<div class="w-100">
    <div class="d-flex justify-content-center">
        <img style="width: 220px; margin: 50px 0" [src]="muhneeLogo" />
    </div>

    <div class="d-flex justify-content-center mb-3">
        <div style="width: 520px; text-align: center">
            SkySlope Books is requesting permission to connect the company {{ companyTitle }}
            (current logged in) to it's corresponding Muhnee account.
        </div>
    </div>

    <div class="d-flex justify-content-center mb-3">
        <mat-button-toggle-group [(ngModel)]="activeSection">
            <mat-button-toggle value="register">Create New Account</mat-button-toggle>
            <!--            <mat-button-toggle value="login">Connect Existing Account</mat-button-toggle>-->
        </mat-button-toggle-group>
    </div>

    <div class="d-flex justify-content-center">
        <mat-card style="width: 520px; text-align: center" *ngIf="activeSection !== null">
            <div *ngIf="activeSection === 'login'" [formGroup]="muhneeLoginForm">
                <div class="mt-2">
                    <mat-form-field class="mr-3" style="width: 300px">
                        <input matInput required type="text" formControlName="login" />
                        <mat-placeholder>Email Address (used to log into Muhnee)</mat-placeholder>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="mr-3" style="width: 300px">
                        <input matInput required type="password" formControlName="password" />
                        <mat-placeholder>Password (used to log into Muhnee)</mat-placeholder>
                    </mat-form-field>
                </div>

                <div class="d-flex justify-content-end w-100 mt-2">
                    <button
                        mat-raised-button
                        [disabled]="lockButton"
                        color="primary"
                        (click)="createMuhneeGateway('login')"
                    >
                        Login
                    </button>
                </div>
            </div>

            <div *ngIf="activeSection === 'register'" [formGroup]="muhneeRegisterForm">
                <div class="mt-2">
                    <mat-form-field class="mr-3" style="width: 300px">
                        <input matInput required type="text" formControlName="company_name" />
                        <mat-placeholder>Full Legal Name / Company Name</mat-placeholder>
                    </mat-form-field>
                    <mat-form-field class="mr-3" style="width: 300px">
                        <input matInput required type="text" formControlName="login" />
                        <mat-placeholder>Email</mat-placeholder>
                    </mat-form-field>
                </div>
                <div class="">
                    <mat-form-field class="mr-3" style="width: 300px">
                        <input matInput required type="text" formControlName="first_name" />
                        <mat-placeholder>First Name</mat-placeholder>
                    </mat-form-field>
                    <mat-form-field class="mr-3" style="width: 300px">
                        <input matInput required type="text" formControlName="last_name" />
                        <mat-placeholder>Last Name</mat-placeholder>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="mr-3" style="width: 300px">
                        <input matInput required type="text" formControlName="phone" />
                        <mat-placeholder>Phone number (10 digits)</mat-placeholder>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="mr-3" style="width: 300px">
                        <input matInput required type="password" formControlName="password" />
                        <mat-placeholder>Password</mat-placeholder>
                    </mat-form-field>
                </div>

                <div class="d-flex justify-content-end w-100 mt-2">
                    <button
                        mat-raised-button
                        [disabled]="lockButton"
                        color="primary"
                        (click)="createMuhneeGateway('register')"
                    >
                        Sign up
                    </button>
                </div>
            </div>
        </mat-card>
    </div>
</div>
