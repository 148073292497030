import {Component, OnDestroy, OnInit} from '@angular/core';
import {IRole} from '@cyberco-nodejs/zipi-typings';
import {RolesService} from '../../services/roles.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {GenericFormGroup} from 'app/entites/generic.entity';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {AppInitSource} from 'app/services/sources/app-init.source';
import {MatDialog} from '@angular/material/dialog';
import {DeleteRoleConfirmDialogComponent} from './delete-role-confirm.dialog';
import {CompanyPermissionsSource} from 'app/services/sources/companyPermissions.source';
import {AvailableRolesSource} from 'app/services/sources/available-roles.source';
import {Profile} from 'app/models/profile';

@Component({
    selector: 'app-company-roles-management',
    templateUrl: './company-roles-management.component.html',
    styleUrls: ['./company-roles-management.component.scss']
})
export class CompanyRolesManagementComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    companyRoles: IRole[] = [];
    availableCompanyFeaturePermissions: {[key: string]: Object} = {};
    orderedCompanyFeaturePermissions: string[] = [];
    rawCompanyPermissionsObj: any;

    companyRoleForm: GenericFormGroup<IRole> | undefined;

    editRoleId: number | undefined;

    roleForEdit: IRole | undefined;

    SCOPE_LABELS = {
        company: 'Company'
    };

    roleType: string = 'company';
    offsetTop: string = 'calc(100vh - 126px)';
    isLoaded: boolean = true;

    constructor(
        private rolesService: RolesService,
        private fb: UntypedFormBuilder,
        protected currentProfileSource: CurrentProfileSource,
        protected appInitSource: AppInitSource,
        protected dialog: MatDialog,
        private companyPermissionsSource: CompanyPermissionsSource,
        private availableRolesSource: AvailableRolesSource
    ) {}

    ngOnInit() {
        this.currentProfileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe((currProfile) => {
            this.initCompanyRoles();

            this.companyPermissionsSource.source.pipe(takeUntil(this.unsubscribe)).subscribe((permissions) => {
                if (permissions) {
                    this.rawCompanyPermissionsObj = permissions;
                }
            });
        });
    }

    initCompanyRoles() {
        // clear previous roles
        this.companyRoles = [];
        this.isLoaded = true;

        // wait for changes from backend
        this.availableRolesSource.source.pipe(takeUntil(this.unsubscribe)).subscribe((roles) => {
            if (roles) {
                this.companyRoles = roles.companyRoles;
            }
            this.isLoaded = false;
        });
    }

    getRawPermissionsObject(scope: any) {
        switch (scope) {
            case 'company':
                return this.rawCompanyPermissionsObj;
            default:
                throw new Error(`Unknown scope ${scope}`);
        }
    }

    setupPermissionsList(scope: string) {
        this.availableCompanyFeaturePermissions = this.permissionsCategorise(this.getRawPermissionsObject(scope));
    }

    uncheckExcessPermissions() {
        if (this.companyRoleForm && this.companyRoleForm.controls.permissions) {
            const getRawPermissionsObject = this.getRawPermissionsObject(this.companyRoleForm.controls.scope!.value);
            if (getRawPermissionsObject) {
                const availablePermissionKeys = Object.keys(getRawPermissionsObject);
                this.companyRoleForm.controls.permissions.patchValue(
                    this.companyRoleForm.controls.permissions.value.filter((permission: {key: string}) =>
                        availablePermissionKeys.includes(permission.key)
                    )
                );
            }
        }
    }

    initRoleForm(companyRole: IRole | null) {
        if (companyRole) {
            this.companyRoleForm = <GenericFormGroup<IRole>>this.fb.group({
                title: [companyRole.title, [Validators.required]],
                type: [companyRole.type, []],
                status: [companyRole.status, []],
                scope: [companyRole.scope, [Validators.required]],
                permissions: [companyRole.permissions, []]
            });
        } else {
            this.companyRoleForm = <GenericFormGroup<IRole>>this.fb.group({
                title: ['', [Validators.required]],
                type: ['company', []],
                status: ['active', []],
                scope: ['company', [Validators.required]],
                permissions: [[], []]
            });
        }
        this.setupPermissionsList(this.companyRoleForm.controls.scope!.value);
        this.companyRoleForm.controls.scope!.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((scope) => {
            this.setupPermissionsList(scope);
            this.uncheckExcessPermissions();
        });
    }

    createCompanyRole() {
        this.initRoleForm(null);
    }

    editCompanyRole(role: IRole) {
        this.editRoleId = Number(role.id);
        this.initRoleForm(role);
    }

    permissionsCategorise(permissionsRawArray: any[], modules: string[] = []): {[key: string]: Object} {
        const permObj = {};
        this.orderedCompanyFeaturePermissions = [];

        for (const key in permissionsRawArray) {
            if (!permissionsRawArray.hasOwnProperty(key)) {
                continue;
            }

            if (modules.length > 0) {
                if (!modules.includes(key)) {
                    continue;
                }
            }

            if (!permObj.hasOwnProperty(permissionsRawArray[key].module)) {
                // @ts-ignore
                permObj[permissionsRawArray[key].module] = [];
                this.orderedCompanyFeaturePermissions.push(permissionsRawArray[key].module);
            }
            // @ts-ignore
            permObj[permissionsRawArray[key].module].push(permissionsRawArray[key]);
        }
        const comparatorOrder: any = {
            dashboards: 1,
            deals: 2,
            progress: 3,
            sales: 4,
            purchases: 5,
            lending: 6,
            escrow_deposits: 7,
            reports: 8,
            company_settings: 9,
            contacts: 10,
            marketplace: 11,
            directory: 12
        };
        this.orderedCompanyFeaturePermissions.sort((a, b) => {
            return comparatorOrder[a] - comparatorOrder[b];
        });
        return permObj;
    }

    saveCompanyRole() {
        if (this.companyRoleForm) {
            if (this.companyRoleForm.invalid) {
                this.companyRoleForm.get('title')!.markAsTouched();
            } else {
                const role = this.companyRoleForm.getRawValue();

                // if title is empty or full of spaces - reset this field and it will be red
                if (!role.title || !role.title.trim()) {
                    this.companyRoleForm.get('title')!.patchValue('');
                    return;
                }

                // trim title before save
                role.title = role.title.trim();

                if (this.editRoleId) {
                    this.rolesService
                        .updateCompanyRole(this.editRoleId, role)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((response) => {
                            if (response) {
                                this.cancel();
                                this.appInitSource.resetLastAppInitResponse();
                            }
                        });
                } else {
                    this.rolesService
                        .createCompanyRole(role)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((response) => {
                            if (response) {
                                this.companyRoleForm = undefined;
                                this.appInitSource.resetLastAppInitResponse();
                            }
                        });
                }
            }
        }
    }

    deleteCompanyRole() {
        if (this.editRoleId) {
            const dialog = this.dialog.open(DeleteRoleConfirmDialogComponent, {
                data: {
                    message: `Are you sure to delete this role?`
                }
            });
            dialog
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (result) {
                        this.rolesService
                            .deleteCompanyRole(Number(this.editRoleId), false)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((response) => {
                                if (response) {
                                    if (response.result.success) {
                                        this.cancel();
                                    } else {
                                        const dialog2 = this.dialog.open(DeleteRoleConfirmDialogComponent, {
                                            data: {
                                                message: response.result.error
                                            }
                                        });
                                        dialog2
                                            .afterClosed()
                                            .pipe(takeUntil(this.unsubscribe))
                                            .subscribe((result2) => {
                                                if (result2) {
                                                    this.rolesService
                                                        .deleteCompanyRole(Number(this.editRoleId), true)
                                                        .pipe(takeUntil(this.unsubscribe))
                                                        .subscribe((response2) => {
                                                            this.cancel();
                                                        });
                                                }
                                            });
                                    }
                                }
                            });
                    }
                });
        } else {
            this.cancel();
        }
    }

    cancel() {
        this.companyRoleForm = undefined;
        this.editRoleId = undefined;
    }

    currentProfileChanged(newProfile: Profile) {
        this.companyPermissionsSource.load();
        this.availableRolesSource.load();
    }

    availableProfilesHandler(availableProfiles: Profile[]) {
        if (availableProfiles.length > 1) {
            this.offsetTop = 'calc(100vh - 186px)';
        } else {
            this.offsetTop = 'calc(100vh - 126px)';
        }
    }

    object_keys(obj: Object) {
        return Object.keys(obj);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
