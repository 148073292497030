import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../service.requester';
import {Observable} from 'rxjs';

@Injectable()
export class BankingService {
    private url = '/finance/banking/';

    constructor(public requester: ServiceRequester) {}

    createAdditionalTransaction(data: any): Observable<any> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    createMatchedTransaction(matchTransactionId: number, data: any): Observable<any> {
        return this.requester.makeMsCall$(this.url + `${matchTransactionId}`, 'POST', 'shipp', data);
    }
}
