<mat-drawer-container class="h-100" style="background-color: #fff">
    <mat-drawer-content>
        <!--        <nav mat-tab-nav-bar *ngIf="router.url.includes('/bills') || router.url.includes('/sourcedocuments')">-->
        <!--            <a mat-tab-link-->
        <!--               *ngFor="let link of links"-->
        <!--               class="finance-page-tabs"-->
        <!--               [routerLink]="link.path"-->
        <!--               routerLinkActive #rla="routerLinkActive"-->
        <!--               [matTooltip]="link.tooltip"-->
        <!--               [active]="rla.router.url.includes(link.matchForActive)">-->
        <!--                {{link.label}}-->
        <!--            </a>-->
        <!--        </nav>-->
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>
