import {of as observableOf, Observable, of} from 'rxjs';
import {catchError, switchMap, tap, filter, first, map, mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store, select} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {get as getProp} from 'lodash-es';

import {
    contactsActionTypes,
    FetchContacts,
    FetchContactsSuccess,
    AddContact,
    AddContactSuccess,
    UpdContact,
    UpdContactSuccess,
    DeleteContact,
    DeleteContactSuccess,
    FetchContactsMust,
    AddContactInDialog,
    CancelContactInvite,
    CreateContactInvite,
    CreateContactInviteResult,
    CancelContactInviteResult
} from './contacts.actions';
import {IContactsState} from './contacts.reducer';
import {ShipperContactsService} from '../../../services/api/shipper.contacts.service';
import {NotificationsService} from 'angular2-notifications';
import {selectContacts} from './contacts.selectors';

@Injectable()
export class ContactsEffectsService {
    constructor(
        protected actions$: Actions,
        private cs: ShipperContactsService,
        protected store: Store<IContactsState>,
        protected router: Router,
        private ntfs: NotificationsService
    ) {}

    @Effect()
    fetchContact$ = this.actions$.pipe(
        ofType<FetchContacts>(contactsActionTypes.FETCH_CONTACTS),
        switchMap(() => this.store.pipe(select(selectContacts), first())),
        switchMap((contacts) => {
            if (contacts.length === 0) {
                return this.cs.getContactsList().pipe(catchError((err) => observableOf(null)));
            }
            return observableOf(null);
        }),
        filter((contacts) => !!contacts),
        map((contacts) => new FetchContactsSuccess(contacts))
    );

    @Effect()
    fetchContactMust$ = this.actions$.pipe(
        ofType<FetchContactsMust>(contactsActionTypes.FETCH_CONTACTS_MUST),
        switchMap((action) => this.cs.getContactsList()),
        map((contacts) => new FetchContactsSuccess(contacts))
    );

    @Effect({dispatch: false})
    addContact$: Observable<any> = this.actions$.pipe(
        ofType<AddContact>(contactsActionTypes.ADD_CONTACT),
        switchMap((action) => this.cs.createContact(action.payload!).pipe(catchError((err) => of(null)))),
        filter((contact) => !!contact),
        tap((contact) => {
            if (contact) {
                this.store.dispatch(new FetchContactsMust());
                this.store.dispatch(new AddContactSuccess(contact));
                this.ntfs.success(`Contact "${contact.display_name}" successfully created`);
                this.router.navigate(['/contacts']);
            }
        })
    );

    @Effect({dispatch: false})
    addContactInDialog$: Observable<any> = this.actions$.pipe(
        ofType<AddContactInDialog>(contactsActionTypes.ADD_CONTACT_IN_DIALOG),
        // switchMap(action =>
        //     this.cs.createContact(action.payload!).pipe(
        //         catchError(err => null)
        //     )
        // ),
        filter((action) => !!action.payload!),
        tap((action) => {
            this.store.dispatch(new FetchContactsMust());
            this.store.dispatch(new AddContactSuccess(action.payload!));
            this.ntfs.success(`Contact "${action.payload!.display_name}" successfully created`);
        })
    );

    @Effect({dispatch: true})
    updateContact$ = this.actions$.pipe(
        ofType<UpdContact>(contactsActionTypes.UPD_CONTACT),
        // @ts-ignore
        mergeMap((action) => {
            const silent = getProp(action, 'payload.silent', false);
            return this.cs.updateContactById(Number(action.payload!.data.id), action.payload!.data).pipe(
                map((result) => ({result, silent})),
                catchError((err) => observableOf({result: null, silent}))
            );
        }),
        filter(({result}) => !!result),
        map(({result, silent}) => {
            if (result && !silent) {
                // this.ntfs.info(`Contact successfully updated`);
                this.router.navigate([`/contacts/${result.contact_id}`]);
            }
            // to update view mode after success
            this.store.dispatch(new FetchContactsMust());
            return new UpdContactSuccess(result!);
        })
    );

    @Effect({dispatch: false})
    deleteContact$: Observable<any> = this.actions$.pipe(
        ofType<DeleteContact>(contactsActionTypes.DELETE_CONTACT),
        tap(() => this.router.navigate(['contacts'])),
        switchMap((action) => {
            return this.cs.deleteContact(action.payload!).pipe(catchError((err) => observableOf(false)));
        }),
        tap((ok) => {
            if (ok) {
                this.store.dispatch(new FetchContactsMust());
                this.ntfs.warn(`Contact successfully deleted`);
            }
            this.store.dispatch(new DeleteContactSuccess(ok));
        })
    );

    @Effect({dispatch: false})
    createContactsInvitesMust$: Observable<any> = this.actions$.pipe(
        ofType<CreateContactInvite>(contactsActionTypes.CREATE_CONTACT_INVITE),
        switchMap((action) =>
            this.cs
                .createContactInvite(action.payload.contactId, action.payload.data)
                .pipe(catchError((err) => observableOf(null)))
        ),
        filter((data) => !!data),
        tap((contInv) => {
            this.ntfs.success('Contact invited');
            this.store.dispatch(new CreateContactInviteResult(contInv));
        })
    );

    @Effect({dispatch: false})
    cancelContactsInvitesMust$: Observable<any> = this.actions$.pipe(
        ofType<CancelContactInvite>(contactsActionTypes.CANCEL_CONTACT_INVITE),
        switchMap((action) =>
            this.cs.cancelContactInvite(action.payload.id as number).pipe(catchError((err) => observableOf(null)))
        ),
        filter((data) => !!data),
        tap((ok) => {
            if (ok) {
                this.ntfs.info('Contact invite canceled');
            }
            this.store.dispatch(new CancelContactInviteResult(Boolean(ok)));
        })
    );
}
