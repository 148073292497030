import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {UntypedFormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CompanyGatewayService} from '../../../../../services/api/finance/company-gateway.service';
import {NotificationsService} from 'angular2-notifications';
import {takeUntil} from 'rxjs/operators';
import {ILedgerAccount, IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {RbacService} from '../../../../rbac/rbac.service';
import {FormGroupWithFormControls} from '../../../../../typings/common';

@Component({
    selector: 'app-authorize-gateway-edit',
    templateUrl: './authorize-gateway-edit.component.html',
    styleUrls: ['./authorize-gateway-edit.component.scss']
})
export class AuthorizeGatewayEditComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    gatewayId: number | null = null;
    gatewayForm: FormGroupWithFormControls = this.fb.group({
        type: [null, [Validators.required]],
        title: [null, [Validators.required]],
        settings: [null, []],
        related_ledger_account_id: [null, [Validators.required]]
    }) as FormGroupWithFormControls;
    gateway: IPaymentGateway | undefined;

    authorizeSettingsForm: FormGroupWithFormControls = this.fb.group({
        api_login_edit_mode: [true],
        existed_api_login: [{value: null, disabled: true}],
        api_login: null,

        key_edit_mode: [true],
        existed_transaction_key: [{value: null, disabled: true}],
        transaction_key: null,

        is_credentials_invalid: [false, []]
    }) as FormGroupWithFormControls;

    currentType: string | null = null;

    currentTitle: string | null = null;

    accounts: ILedgerAccount[] = [];

    constructor(
        public router: Router,
        private companyGatewayService: CompanyGatewayService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        protected rbacService: RbacService
    ) {}

    ngOnInit() {
        this.rbacService.isAllowed({marketplace__manage_authorizenet: true}).then((can) => {
            if (!can) {
                this.router.navigate(['/']);
            }
        });
        this.currentTitle = 'Authorize.Net';
        this.currentType = 'authorize_net_merchant';

        this.companyGatewayService
            .getCompanyGatewayByType(this.currentType)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((gateway) => {
                this.initForm(gateway);
            });
    }

    initForm(gateway: IPaymentGateway) {
        if (gateway) {
            this.gateway = gateway;
            let paymentMethod = null;
            if (gateway.payment_methods && gateway.payment_methods[0]) {
                paymentMethod = gateway.payment_methods[0];
            }
            this.gatewayForm.patchValue({
                type: gateway.type,
                title: this.currentType,
                settings: null,
                related_ledger_account_id: paymentMethod ? paymentMethod.related__ledger_account_fk_id : null
            });
            this.authorizeSettingsForm.patchValue({
                api_login_edit_mode: false,
                existed_api_login: gateway.settings.api_login,
                api_login: null,

                key_edit_mode: false,
                existed_transaction_key: gateway.settings.transaction_key,
                transaction_key: null,

                is_credentials_invalid: gateway.settings.is_credentials_invalid
            });
            // if (paymentMethod && paymentMethod.related__ledger_account_fk_id) {
            //     this.gatewayForm.controls.related_ledger_account_id.disable();
            // }

            if (gateway.payment_gateway_id) {
                this.gatewayId = gateway.payment_gateway_id;
            }
        } else {
            this.gatewayForm = this.fb.group({
                type: [this.currentType, [Validators.required]],
                title: [this.currentTitle, [Validators.required]],
                settings: [null, []],
                related_ledger_account_id: [null, [Validators.required]]
            }) as FormGroupWithFormControls;
        }

        this.gatewayForm.controls.title.disable();
    }

    editApiLogin() {
        this.authorizeSettingsForm.controls.api_login_edit_mode.patchValue(
            !this.authorizeSettingsForm.controls.api_login_edit_mode.value
        );
        this.authorizeSettingsForm.controls.api_login.patchValue(null);

        if (this.authorizeSettingsForm.controls.api_login_edit_mode.value) {
            this.authorizeSettingsForm.controls.api_login.setValidators([Validators.required]);
            this.authorizeSettingsForm.controls.api_login.updateValueAndValidity();
        } else {
            this.authorizeSettingsForm.controls.api_login.clearValidators();
            this.authorizeSettingsForm.controls.api_login.updateValueAndValidity();
        }
    }
    editTransactionKey() {
        this.authorizeSettingsForm.controls.key_edit_mode.patchValue(
            !this.authorizeSettingsForm.controls.key_edit_mode.value
        );
        this.authorizeSettingsForm.controls.transaction_key.patchValue(null);

        if (this.authorizeSettingsForm.controls.key_edit_mode.value) {
            this.authorizeSettingsForm.controls.transaction_key.setValidators([Validators.required]);
            this.authorizeSettingsForm.controls.transaction_key.updateValueAndValidity();
        } else {
            this.authorizeSettingsForm.controls.transaction_key.clearValidators();
            this.authorizeSettingsForm.controls.transaction_key.updateValueAndValidity();
        }
    }

    saveGateway() {
        if (this.validAllForm()) {
            const payment_gateway = this.gatewayForm.getRawValue();
            const authSettings = this.authorizeSettingsForm.getRawValue();
            payment_gateway.settings = {};
            if (authSettings.api_login_edit_mode) {
                payment_gateway.settings.api_login = authSettings.api_login.trim();
            }
            if (authSettings.key_edit_mode) {
                payment_gateway.settings.transaction_key = authSettings.transaction_key.trim();
            }

            if (this.gatewayId) {
                this.companyGatewayService
                    .updateAuthorizeCompanyGateway(this.gatewayId, payment_gateway)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((response) => {
                        if (response) {
                            this.notificationsService.success(`Settings saved`);
                            this.router.navigate(['/marketplace']);
                        }
                    });
            } else {
                this.companyGatewayService
                    .createAuthorizeCompanyGateway(payment_gateway)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((response) => {
                        if (response) {
                            this.notificationsService.success(`Settings saved`);
                            this.router.navigate(['/marketplace']);
                        }
                    });
            }
        }
    }

    validAllForm() {
        if (this.gatewayForm.invalid) {
            this.gatewayForm.markAllAsTouched();
            return false;
        }

        const settings = this.authorizeSettingsForm.getRawValue();

        if (settings.api_login_edit_mode) {
            if (!settings.api_login || !settings.api_login.trim().length) {
                this.authorizeSettingsForm.markAllAsTouched();
                return false;
            }
        }
        if (settings.key_edit_mode) {
            if (!settings.transaction_key || !settings.transaction_key.trim().length) {
                this.authorizeSettingsForm.markAllAsTouched();
                return false;
            }
        }

        if (this.authorizeSettingsForm.invalid) {
            this.authorizeSettingsForm.markAllAsTouched();
            return false;
        } else {
            return true;
        }
    }

    // deleteGateway() {
    //     if (this.gatewayId) {
    //         this.companyGatewayService.deleteCompanyGateway(this.gatewayId)
    //             .pipe(takeUntil(this.unsubscribe))
    //             .subscribe(response => {
    //                 if (response) {
    //                     this.router.navigate(['/dashboard']);
    //                 }
    //             });
    //     }
    // }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
