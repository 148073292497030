import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {PlacesPickerComponent} from './components/google-places-picker/places-picker.component';

@NgModule({
    imports: [CommonModule, SharedModule],
    declarations: [PlacesPickerComponent],
    exports: [PlacesPickerComponent]
})
export class GPlacesPickerModule {}
