<section class="container-fluid p-4">
    <header class="mb-2 d-flex align-items-center">
        <h1 *ngIf="!invoiceId" class="">Create Invoice</h1>
        <h1 *ngIf="invoiceId" class="">Edit Invoice</h1>

        <span class="sp-spacer"></span>
        <button
            *ngIf="invoice && invoice.invoice_id && invoice.connected__bill_fk_id"
            type="submit"
            class="ml-1"
            mat-raised-button
            (click)="disconnectInvoice()"
            [disabled]="
                (!!invoice && !!invoice!.payments && invoice!.payments!.length > 0 && isPaymentsCanceled(invoice!)) ||
                deleteDisabled
            "
        >
            Disconnect
        </button>
    </header>

    <div class="mb-3">
        <h2>Invoice</h2>

        <div class="row mr-0">
            <app-date-picker
                class="col col-sm-6"
                [placeholder]="'Invoice date'"
                [dateControl]="invoiceDateControl"
                [disabled]="editType === 'blocked'"
            ></app-date-picker>
        </div>

        <div class="mb-4">
            <app-invoice-template
                (onUpdate)="handleInvoiceTemplateUpdate($event)"
                [invoice]="invoice"
                [invoice_id]="invoiceId"
                [makeInvoiceFormTouch]="makeInvoiceFormTouch"
                [editType]="editType"
            >
            </app-invoice-template>
        </div>

        <div class="tag-section">
            <div *ngIf="false">
                <span>Applied Tags: </span>
                <mat-chip-list [disabled]="disableTags">
                    <ng-container *ngFor="let tag of allTags">
                        <mat-chip *ngIf="selectedTagsIds.includes(tag.tag_id)">
                            {{ tag.title }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>
        </div>

        <div class="m-2">Available Credits ({{ creditsBalance | currency }})</div>

        <div *ngIf="dialogMode" class="w-100 text-right">
            <button
                *ngIf="invoice && invoice.invoice_id"
                type="submit"
                mat-raised-button
                color="warn"
                (click)="deleteInvoice()"
                [disabled]="
                    (!!invoice!.payments && invoice!.payments!.length > 0 && isPaymentsCanceled(invoice!)) ||
                    deleteDisabled
                "
            >
                Delete
            </button>
            <span class="sp-spacer"></span>

            <button
                *ngIf="invoice && invoice.invoice_id"
                type="submit"
                class="ml-1"
                mat-raised-button
                (click)="updateInvoice()"
                [disabled]="updateDisabled"
                color="primary"
            >
                Save
            </button>
        </div>

        <div class="d-flex" *ngIf="!dialogMode">
            <div class="w-80" *ngIf="!invoiceId">
                <mat-checkbox [(ngModel)]="chargeFromCredits"> Apply Credits </mat-checkbox>
            </div>
            <div
                class="ml-1"
                matTooltip="You cannot delete Invoice associated with Deal."
                [matTooltipDisabled]="invoice && !invoice.source__deal_fk_id"
            >
                <button
                    *ngIf="invoice && invoice.invoice_id"
                    type="submit"
                    mat-raised-button
                    color="warn"
                    (click)="deleteInvoice()"
                    [disabled]="editType !== 'full'"
                >
                    Delete
                </button>
            </div>

            <span class="sp-spacer"></span>
            <button mat-raised-button class="ml-2" routerLink="/sales/invoices">Cancel</button>

            <mat-menu #appMenu="matMenu">
                <button *ngIf="!invoice || !invoice.invoice_id" mat-menu-item (click)="createInvoice('send')">
                    Create and Send
                </button>

                <button *ngIf="!invoice || !invoice.invoice_id" mat-menu-item (click)="createInvoice('draft')">
                    Create as Draft
                </button>

                <button *ngIf="!invoice || !invoice.invoice_id" mat-menu-item (click)="createInvoice('open')">
                    Create as Open
                </button>
            </mat-menu>

            <button
                mat-raised-button
                color="primary"
                *ngIf="!invoice"
                [disabled]="createDisabled"
                [matMenuTriggerFor]="appMenu"
                class="ml-3 pr-2"
            >
                Create<mat-icon>arrow_drop_down</mat-icon>
            </button>

            <button
                class="ml-1"
                *ngIf="invoice && invoice.invoice_id"
                type="submit"
                mat-raised-button
                (click)="updateInvoice()"
                [disabled]="updateDisabled || editType === 'blocked'"
                color="primary"
            >
                Save
            </button>
        </div>
    </div>
</section>
