import {Routes, RouterModule} from '@angular/router';
import {SubscriptionInactiveComponent} from './components/subscription-inactive.component';
import {AccessDeniedComponent} from './components/access-denied.component';
import {SignInComponent} from './modules/auth/sign-in/sign-in.component';
import {GuestBootstrapService} from './services/resolvers/guest-bootstrap.service';
import {GuestLayoutComponent} from './layouts/guest-layout.component';
import {PublicBootstrapService} from './services/resolvers/public-bootstrap.service';
import {NotFound404Component} from './components/not-found-404.component';
import {SelectProfileComponent} from './components/select-profile.component';
import {DefaultPageResolverService} from './services/resolvers/default-page-resolver.service';
import {SignInSkySlopeComponent} from './modules/auth/sign-in-skyslope/sign-in-skyslope.component';
import {SignInPrimeCallbackComponent} from './modules/auth/sign-in-prime-callback/sign-in-prime-callback.component';
import {LogOutSkyslopeComponent} from './modules/auth/log-out-skyslope/log-out-skyslope.component';
import {SignInSkyslopeCallbackComponent} from './modules/auth/sign-in-skyslope-callback/sign-in-skyslope-callback.component';
import {SkyslopePostLogoutComponent} from './modules/auth/skyslope-post-logout/skyslope-post-logout.component';
import {SignInSkyslopeConversionComponent} from './modules/auth/sign-in-skyslope-conversion/sign-in-skyslope-conversion.component';
import {NoAuthBootstrapService} from './services/resolvers/no-auth-bootstrap.service';

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/default-page'
    },
    {
        path: 'subscription-inactive',
        component: SubscriptionInactiveComponent,
        resolve: {
            guestBootstrap: PublicBootstrapService
        }
    },
    {
        path: 'accessdenied',
        component: AccessDeniedComponent,
        resolve: {
            guestBootstrap: PublicBootstrapService
        }
    },
    {
        path: 'select-profile',
        component: SelectProfileComponent,
        resolve: {
            guestBootstrap: PublicBootstrapService
        }
    },
    {
        path: 'login',
        component: GuestLayoutComponent,
        children: [
            {
                path: '',
                component: SignInComponent,
                resolve: {
                    guestBootstrap: NoAuthBootstrapService
                },
                data: {
                    title: 'Login'
                }
            },
            {
                path: 'skyslope',
                component: SignInSkySlopeComponent,
                resolve: {
                    guestBootstrap: NoAuthBootstrapService
                },
                data: {
                    title: 'Login - SkySlope'
                }
            },
            {
                path: 'skyslope-callback',
                pathMatch: 'full',
                component: SignInSkyslopeCallbackComponent,
                resolve: {
                    guestBootstrap: NoAuthBootstrapService
                }
            },
            {
                path: 'skyslope-conversion',
                pathMatch: 'full',
                component: SignInSkyslopeConversionComponent,
                resolve: {
                    guestBootstrap: NoAuthBootstrapService
                }
            },
            {
                path: 'prime-callback',
                pathMatch: 'full',
                component: SignInPrimeCallbackComponent
            }
        ]
    },
    {
        path: 'skyslope-logout',
        pathMatch: 'full',
        component: LogOutSkyslopeComponent
    },
    {
        path: 'skyslope-post-logout',
        pathMatch: 'full',
        component: SkyslopePostLogoutComponent
    },
    {
        path: 'account-info',
        redirectTo: 'company'
    },
    {
        // the main goal of this route is
        // to redirect on different main page by user
        path: 'default-page',
        component: GuestLayoutComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
            defaultPageBootstrap: DefaultPageResolverService
        }
    },
    {
        path: '**',
        component: NotFound404Component
    }
];

export const routing = RouterModule.forRoot(routes, {useHash: false});
