import {merge as observableMerge, Observable, Subject} from 'rxjs';

import {startWith} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {AppInitSource} from '../../services/sources/app-init.source';
import {
    UiModsDealBadgeInListModel,
    UiModsDealBadgeOnPageModel,
    UiModsDealCommissionCategorizationNoteModel,
    UiModsDealsBoardsButtonModel,
    UiModsDealsButtonModel,
    UiModsMenuOptionModel,
    UiModsModel,
    UI_MOD_STATE
} from './models/ui-mods.model';

@Injectable()
export class UiModsSource {
    public triggers = {
        initApp: new Subject<UiModsModel>(),
        dealsBoardsButtons: new Subject<UiModsDealsBoardsButtonModel[]>(),
        dealsButtons: new Subject<UiModsDealsButtonModel[]>(),
        createDealMenuOptions: new Subject<UiModsMenuOptionModel[]>(),
        dealBadgesInList: new Subject<UiModsDealBadgeInListModel[]>(),
        dealBadgesOnPage: new Subject<UiModsDealBadgeOnPageModel[]>(),
        dealCommissionCategorizationNote: new Subject<UiModsDealCommissionCategorizationNoteModel[]>()
    };

    private events = {
        uiMods: <Observable<UiModsModel>>observableMerge(this.triggers.initApp),
        dealsBoardsButtons: <Observable<UiModsDealsBoardsButtonModel[]>>(
            observableMerge(this.triggers.dealsBoardsButtons)
        ),
        dealsButtons: <Observable<UiModsDealsButtonModel[]>>observableMerge(this.triggers.dealsButtons),
        createDealMenuOptions: <Observable<UiModsMenuOptionModel[]>>(
            observableMerge(this.triggers.createDealMenuOptions)
        ),
        dealBadgesInList: <Observable<UiModsDealBadgeInListModel[]>>observableMerge(this.triggers.dealBadgesInList),
        dealBadgesOnPage: <Observable<UiModsDealBadgeOnPageModel[]>>observableMerge(this.triggers.dealBadgesOnPage),
        dealCommissionCategorizationNote: <Observable<UiModsDealCommissionCategorizationNoteModel[]>>(
            observableMerge(this.triggers.dealCommissionCategorizationNote)
        )
    };

    private currentUiMods: UiModsModel | null = null;
    // private currentDealsBoardsButtons: UiModsDealsBoardsButtonModel[] = null;
    // private currentDealsButtons: UiModsDealsButtonModel[] = null;
    private currentCreateDealMenuOptions: UiModsMenuOptionModel[] | null = null;
    private currentDealBadgesInList: UiModsDealBadgeInListModel[] | null = [];
    private currentDealBadgesOnPage: UiModsDealBadgeOnPageModel[] | null = null;
    private currentDealCommissionCategorizationNote: UiModsDealCommissionCategorizationNoteModel[] | null = null;

    public get uiMods(): Observable<UiModsModel> {
        if (this.currentUiMods) {
            return this.events.uiMods.pipe(startWith(this.currentUiMods));
        }
        return this.events.uiMods;
    }

    public get createDealMenuOptions(): Observable<UiModsMenuOptionModel[]> {
        if (this.currentCreateDealMenuOptions) {
            return this.events.createDealMenuOptions.pipe(startWith(this.currentCreateDealMenuOptions));
        }
        return this.events.createDealMenuOptions;
    }
    public get dealBadgesInList(): Observable<UiModsDealBadgeInListModel[]> {
        if (this.currentDealBadgesInList) {
            return this.events.dealBadgesInList.pipe(startWith(this.currentDealBadgesInList));
        }
        return this.events.dealBadgesInList;
    }
    public get dealBadgesOnPage(): Observable<UiModsDealBadgeOnPageModel[]> {
        if (this.currentDealBadgesOnPage) {
            return this.events.dealBadgesOnPage.pipe(startWith(this.currentDealBadgesOnPage));
        }
        return this.events.dealBadgesOnPage;
    }
    public get dealCommissionCategorizationNote(): Observable<UiModsDealCommissionCategorizationNoteModel[]> {
        if (this.currentDealCommissionCategorizationNote) {
            return this.events.dealCommissionCategorizationNote.pipe(
                startWith(this.currentDealCommissionCategorizationNote)
            );
        }
        return this.events.dealCommissionCategorizationNote;
    }

    constructor(protected appInitSource: AppInitSource) {
        this.uiMods.subscribe((mods: UiModsModel) => {
            this.currentUiMods = mods;
            // if (mods.dealBadgesInList) {
            //     this.triggers.dealBadgesInList.next(mods.dealBadgesInList);
            // }
            if (mods.dealBadgesOnPage) {
                this.triggers.dealBadgesOnPage.next(mods.dealBadgesOnPage);
            }
            if (mods.createDealMenuOptions) {
                this.triggers.createDealMenuOptions.next(mods.createDealMenuOptions);
            }
            if (mods.dealCommissionCategorizationNote) {
                this.triggers.dealCommissionCategorizationNote.next(mods.dealCommissionCategorizationNote);
            }
        });

        this.createDealMenuOptions.subscribe((menu_options) => {
            this.currentCreateDealMenuOptions = menu_options;
        });
        // this.dealBadgesInList.subscribe(badges => {
        //     this.currentDealBadgesInList = badges;
        // });
        this.dealBadgesOnPage.subscribe((badges) => {
            this.currentDealBadgesOnPage = badges;
        });
        this.dealCommissionCategorizationNote.subscribe((badges) => {
            this.currentDealCommissionCategorizationNote = badges;
        });

        this.appInitSource.appInit.subscribe((appInitResponse) => {
            if (appInitResponse.uiMods) {
                this.triggers.initApp.next(appInitResponse.uiMods);
            }
        });
    }

    public refreshDealBadgesOnPage() {
        if (!this.currentDealBadgesOnPage) {
            return;
        }
        this.triggers.dealBadgesOnPage.next(this.currentDealBadgesOnPage);
    }

    public updateDealBadgesInList(updatedBadges: UiModsDealBadgeInListModel[]) {
        if (!this.currentDealBadgesInList) {
            return;
        }

        for (let i = 0; i < updatedBadges.length; i++) {
            const exisingBadgeIndex = this.currentDealBadgesInList.findIndex(
                (b) => b.ui_mod_instance_id === updatedBadges[i].ui_mod_instance_id
            );
            if (exisingBadgeIndex > -1) {
                this.currentDealBadgesInList[exisingBadgeIndex] = updatedBadges[i];
            } else {
                this.currentDealBadgesInList.push(updatedBadges[i]);
            }
        }
        this.triggers.dealBadgesInList.next(this.currentDealBadgesInList);

        // TEMPORARY LEAVE OLD CODE. SHOULD BE REMOVED LATER.
        // if (!this.currentDealBadgesInList) { return; }
        // this.triggers.dealBadgesInList.next(this.currentDealBadgesInList.map(badge => {
        //     const updBadge = updatedBadges.find(b => b.id === badge.id);
        //     if (updBadge && updBadge.uiMod && updBadge.uiMod.preset) {
        //         // save old tooltip (created by) if exists
        //         if (badge.uiMod && badge.uiMod.preset && badge.uiMod.preset.hint) {
        //             updBadge.uiMod.preset.hint = badge.uiMod.preset.hint;
        //         }
        //         return updBadge;
        //     }
        //     return badge;
        // }));
        //
        // // if not existing badge (yodata) - add it
        // const newBadges = updatedBadges.filter(updBadge => {
        //     if (!this.currentDealBadgesInList) { return true; }
        //     return !this.currentDealBadgesInList.some(badge => updBadge.id === badge.id);
        // });
        // if (newBadges.length) {
        //     this.triggers.dealBadgesInList.next(this.currentDealBadgesInList.concat(newBadges));
        // }
    }

    public mutateWithUiModCache(badge: UiModsDealBadgeInListModel) {
        if (badge.ui_mod_cache && !badge.uiMod) {
            try {
                badge.uiMod = JSON.parse(badge.ui_mod_cache);
            } catch (e) {
                console.error(`Failed to parse ui_mod_cache for ui_mod_instance_id: ${badge.ui_mod_instance_id}`);
            }
        }
        return badge;
    }

    public buildUiModDealBadgeInList(badge: UiModsDealBadgeInListModel, forceDisabling = false) {
        const preset = badge.uiMod ? badge.uiMod.preset : {};
        const res: UiModsDealBadgeInListModel = Object.assign(new UiModsDealBadgeInListModel(), preset);
        res.id = badge.id;
        res.ui_mod_instance_id = badge.ui_mod_instance_id;
        res.uiMod = badge.uiMod;

        if (forceDisabling) {
            res.clickable = false;
            res.disabled = true;
        }

        if (badge.deal_id) {
            res.deal_id = badge.deal_id;
        }
        if (badge.style) {
            res.style = badge.style;
        }
        if (badge.on_click) {
            res.on_click = badge.on_click;
        }

        if (badge.disabled !== undefined) {
            res.disabled = badge.disabled;
        }
        if (badge.label) {
            res.label = badge.label;
        }
        if (badge.created_by__profile_fk_id) {
            res.created_by__profile_fk_id = badge.created_by__profile_fk_id;
        }
        if (badge.hint) {
            res.hint = badge.hint;
        }

        res.state = badge.state || UI_MOD_STATE.normal;

        return res;
    }
}
