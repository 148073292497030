import {Injectable} from '@angular/core';
import {ServiceRequester} from './service.requester';
import {SkyslopeAuth} from '@app/modules/auth/services/okta/skyslope-auth.service';

@Injectable()
export class UserAdminService {
    constructor(
        public requester: ServiceRequester,
        private skyslopeAuthService: SkyslopeAuth
    ) {}

    getHeaders() {
        return this.skyslopeAuthService.getAccessToken().then((accessToken) => {
            const headersObj: {[key: string]: string} = {
                Authorization: 'Bearer ' + accessToken
            };
            return headersObj;
        });
    }

    getUsers() {
        return this.requester.makeMsShippCall$(`/admin/users`, 'GET');
    }

    async getAdminMe() {
        const headers = await this.getHeaders();
        return this.requester.makeMsShippCall$(`/admin/users/me`, 'GET', {}, headers);
    }

    async checkSupportAccess(oktaId: string) {
        const headers = await this.getHeaders();
        return this.requester.makeMsShippCall$(`/support/check/${oktaId}`, 'GET', {}, headers);
    }

    async getPartOfUsers(params: any = {}) {
        const headers = await this.getHeaders();
        return this.requester.makeMsShippCall$(`/admin/users/params`, 'GET', params, headers);
    }
}
