<div class="mx-5 mt-4">
    <div *ngFor="let category of categories" class="my-3">
        <div class="mb-2 category-title" *ngIf="presetInCategory(category.id)">
            <span class="un-title">{{ category.title }}</span>
            <button mat-icon-button (click)="editCategory(category.id, category.title)">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteCategory(category.id)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>

        <ng-container *ngFor="let preset of presets">
            <mat-card *ngIf="preset.category && preset.category.id === category.id" class="m-2 report-item">
                <div class="flex-row justify-content-between">
                    <div class="report-info" (click)="loadReportData(preset)">
                        <div class="flex-row">
                            <div>
                                <img src="/assets/images/report-image.jpg" alt="" class="preset-img" />
                            </div>
                            <div class="flex-column ml-1">
                                <div class="category-header">
                                    <h3>{{ preset.title }}</h3>
                                    <h5 class="text-secondary">Type: {{ preset.type | fromSnakeCase }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex-column">
                        <div class="flex-row">
                            <mat-form-field class="category-header category-select">
                                <mat-select
                                    placeholder="Category"
                                    [ngModel]="preset.category.id"
                                    (selectionChange)="changeCategory(preset, $event)"
                                >
                                    <mat-select-trigger>{{ preset.category.title }}</mat-select-trigger>
                                    <mat-option (click)="addCategory()" [value]="0">
                                        <mat-icon>add</mat-icon>
                                        <span>Add category</span></mat-option
                                    >
                                    <mat-option *ngFor="let category of categories" [value]="category.id">
                                        {{ category.title }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <button mat-icon-button [matMenuTriggerFor]="moreMenu" class="fix-focus-outline btn-shared">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #moreMenu="matMenu">
                                <button
                                    mat-menu-item
                                    class="fix-focus-outline"
                                    (click)="toggleEditReportSidebar(true, preset.id)"
                                >
                                    <mat-icon class="edit-icon">edit</mat-icon>
                                    Edit
                                </button>
                                <button
                                    mat-menu-item
                                    *ngIf="router.url !== '/reports/categories/company'"
                                    [matMenuTriggerFor]="shared"
                                    class="fix-focus-outline btn-shared"
                                >
                                    <mat-icon>share</mat-icon>
                                    Share
                                </button>
                                <button mat-menu-item class="fix-focus-outline" (click)="deleteReport(preset.id)">
                                    <mat-icon class="edit-icon">delete</mat-icon>
                                    Delete
                                </button>

                                <mat-menu #shared="matMenu">
                                    <button
                                        disabled
                                        mat-menu-item
                                        class="fix-focus-outline"
                                        (click)="sharedTo(preset, 'company')"
                                    >
                                        Company
                                    </button>
                                    <button disabled mat-menu-item class="fix-focus-outline">Client</button>
                                    <button disabled mat-menu-item class="fix-focus-outline">Custom</button>
                                </mat-menu>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </mat-card>
        </ng-container>
    </div>
    <div class="my-3" *ngIf="presets.length">
        <div class="mb-2">
            <h2 class="un-title my-0">
                Uncategorized
                <button mat-icon-button class="fix-focus-outline" (click)="addCategory()">
                    <mat-icon style="margin-top: -7px">add</mat-icon>
                </button>
            </h2>
        </div>
        <ng-container *ngFor="let preset of presets">
            <mat-card *ngIf="isUncategorized(preset)" class="m-2 report-item">
                <div class="flex-row justify-content-between">
                    <div class="report-info" (click)="loadReportData(preset)">
                        <div class="flex-row">
                            <div class="report-thumbnail">
                                <img src="/assets/images/report-image.jpg" alt="" class="preset-img" />
                            </div>
                            <div class="flex-column">
                                <div class="category-header">
                                    <h3>{{ preset.title }}</h3>
                                    <h5 class="category-type text-secondary">
                                        Type: {{ preset.type | fromSnakeCase }}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex-column">
                        <div class="flex-row">
                            <mat-form-field class="category-header category-select">
                                <mat-select
                                    placeholder="Uncategorized"
                                    (selectionChange)="changeCategory(preset, $event)"
                                >
                                    <mat-select-trigger></mat-select-trigger>
                                    <mat-option (click)="addCategory()" [value]="null">
                                        <mat-icon>add</mat-icon>
                                        <span>Add category</span></mat-option
                                    >
                                    <mat-option *ngFor="let category of categories" [value]="category.id">
                                        {{ category.title }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button mat-icon-button [matMenuTriggerFor]="moreMenu" class="fix-focus-outline btn-shared">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #moreMenu="matMenu">
                                <button
                                    mat-menu-item
                                    class="fix-focus-outline"
                                    (click)="toggleEditReportSidebar(true, preset.id)"
                                >
                                    <mat-icon class="edit-icon">edit</mat-icon>
                                    Edit
                                </button>
                                <button
                                    mat-menu-item
                                    *ngIf="router.url !== '/reports/categories/company'"
                                    [matMenuTriggerFor]="shared"
                                    class="fix-focus-outline btn-shared"
                                >
                                    <mat-icon>share</mat-icon>
                                    Share
                                </button>
                                <button mat-menu-item class="fix-focus-outline" (click)="deleteReport(preset.id)">
                                    <mat-icon class="edit-icon">delete</mat-icon>
                                    Delete
                                </button>

                                <mat-menu #shared="matMenu">
                                    <button
                                        disabled
                                        mat-menu-item
                                        class="fix-focus-outline"
                                        (click)="sharedTo(preset, 'company')"
                                    >
                                        Company
                                    </button>
                                    <button disabled mat-menu-item class="fix-focus-outline">Client</button>
                                    <button disabled mat-menu-item class="fix-focus-outline">Custom</button>
                                </mat-menu>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </mat-card>
        </ng-container>
    </div>
</div>
