import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {FinancialElementModel} from '../models/financial-element.model';
import {ConditionEntity} from '../../../../models/condition.entity';
import {CalculationSettingsService} from '../../../../services/calculation-settings.service';
import {CalculationSettingsService as NewCalculationSettingsService} from '../../../../services/new-calculation-settings.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IMetric, METRIC_TYPES} from '@app/typings/calculation-settings.typings';
import {FeatureFlagsService} from '@app/modules/feature-flags/feature-flags.service';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-company-compensation-condition',
    template: `
        <div>
            <mat-form-field style="margin-right: 4px">
                <mat-select placeholder="Condition Type" [formControl]="selectedMetricFG">
                    <ng-container *ngIf="isNewSettingsEnabled">
                        <mat-optgroup label="Default Metrics">
                            <mat-option *ngFor="let type of object_keys(typeOptions)" [value]="type">
                                {{ typeOptions[type] }}
                            </mat-option>
                        </mat-optgroup>
                        <mat-optgroup label="Deal Metrics">
                            <mat-option *ngFor="let type of object_keys(DEAL_METRICS)" [value]="type">
                                {{ DEAL_METRICS[type] }}
                            </mat-option>
                        </mat-optgroup>
                        <!--                    Alex: disabled the ability to select metric as and_condition for MVP-->
                        <mat-optgroup label="Agent Metrics">
                            <mat-option
                                *ngFor="let metric of metrics"
                                [value]="metric.metric_id"
                                disabled
                                matTooltip="Not available for now"
                            >
                                {{ metric.title }}
                            </mat-option>
                        </mat-optgroup>
                    </ng-container>
                    <ng-container *ngIf="!isNewSettingsEnabled">
                        <mat-option *ngFor="let type of object_keys(typeOptions)" [value]="type">
                            {{ typeOptions[type] }}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <app-product-service-selector
                class="four-width"
                *ngIf="
                    condForm!.controls.type!.value === 'total_product_income' ||
                    condForm!.controls.type!.value === 'total_product_expense'
                "
                [placeholder]="'Product/Service'"
                [productServiceControl]="condForm!.controls.product_fk_id!"
                [validators]="null"
                [disabled]="disabled"
                [disabledReselect]="disabledProductReselect"
                [allowedProductsIdsOnly]="true"
                [allowedProductsIdsToSelect]="capableMetricsByProductIds"
                style="display: inline-block; position: relative; text-align: left; margin-right: 4px;"
            >
            </app-product-service-selector>

            <mat-form-field style="margin-right: 4px">
                <mat-select placeholder="Condition Operator" [formControl]="condForm!.controls.operator!">
                    <mat-option *ngFor="let operator of object_keys(operatorOptions)" [value]="operator">
                        {{ operatorOptions[operator] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-right: 4px">
                <input matInput placeholder="Value" [formControl]="condForm!.controls.value!" />
            </mat-form-field>
        </div>
    `
})
export class ConditionComponent implements OnInit, OnChanges {
    private unsubscribe: Subject<void> = new Subject();
    @Input() typeOptions: {[key: string]: any} = {};
    @Input() operatorOptions: {[key: string]: any} = {};
    @Input() disabled: boolean = false;
    @Input() disabledProductReselect: boolean = false;
    @Input() condForm: GenericFormGroup<ConditionEntity> = new GenericFormGroup(new ConditionEntity());
    @Input() performanceCompensationProvides: string = FinancialElementModel.provides_set.compensation_plan;
    @Input() compensationType: string | null = null;

    @Input() show_up: boolean = false;
    @Input() show_down: boolean = false;

    @Output() up = new EventEmitter();
    @Output() down = new EventEmitter();
    @Output() delete = new EventEmitter();
    capableMetricsByProductIds: number[] = [];
    isNewSettingsEnabled = false;
    metrics: IMetric[] = [];

    DEAL_METRICS = {
        per_deal_sales_price: 'Deal Sales Price',
        per_deal_gross_commission: 'Deal Gross Commission',
        per_deal_adjusted_gross_commission: 'Deal Adjusted Gross Commission'
    };

    selectedMetricFG: FormControl<string | number | null> = new FormControl(null);

    constructor(
        protected featureFlagsService: FeatureFlagsService,
        protected calculationSettingsService: CalculationSettingsService,
        protected newCalculationSettingsService: NewCalculationSettingsService
    ) {
        this.metrics = this.newCalculationSettingsService.metrics;
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.isNewSettingsEnabled = this.featureFlagsService.isFeatureEnabled('rules:new_calculation_settings');
            });
    }

    object_keys(obj: {[key: string]: any}): string[] {
        if (!obj) {
            return [];
        }
        return Object.keys(obj);
    }

    ngOnInit() {
        this.calculationSettingsService.calculationSettings$.pipe(takeUntil(this.unsubscribe)).subscribe((settings) => {
            this.capableMetricsByProductIds = settings.capable_metrics_by_product_ids!;
        });
        this.calculationSettingsService.getCalculationSettings();

        this.condForm.controls.type!.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((newValue) => {
            if (this.condForm.controls.type!.disabled) {
                this.selectedMetricFG.disable({emitEvent: false});
            }
            if (
                this.condForm.controls.type!.value &&
                !this.condForm.controls.metric_id!.value &&
                this.condForm.controls.type!.value !== this.selectedMetricFG.value
            ) {
                this.selectedMetricFG.patchValue(this.condForm.controls.type!.value, {emitEvent: false});
            }
        });

        this.condForm.controls.metric_id!.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((newValue) => {
            if (this.condForm.controls.metric_id!.disabled) {
                this.selectedMetricFG.disable({emitEvent: false});
            }
            if (
                !this.condForm.controls.type!.value &&
                this.condForm.controls.metric_id!.value &&
                this.condForm.controls.metric_id!.value !== this.selectedMetricFG.value
            ) {
                this.selectedMetricFG.patchValue(this.condForm.controls.metric_id!.value, {emitEvent: false});
            }
        });

        this.selectedMetricFG.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((newValue) => {
            if (typeof newValue === 'string' && Object.keys(this.typeOptions).includes(newValue)) {
                this.condForm.controls.type!.patchValue(newValue);
                this.condForm.controls.metric_id!.patchValue(null);
            }

            if (typeof newValue === 'number') {
                const selectedMetric = this.metrics.find((metric) => metric.metric_id === newValue);
                if (typeof selectedMetric !== 'undefined') {
                    this.condForm.controls.type!.patchValue(null);
                    this.condForm.controls.metric_id!.patchValue(selectedMetric.metric_id);
                }
            }

            if (newValue === null) {
                this.condForm.controls.type!.patchValue(null);
                this.condForm.controls.metric_id!.patchValue(null);
                this.condForm.controls.operator!.patchValue(null);
            } else {
                if (
                    newValue !== ConditionEntity.type_SET.total_product_income &&
                    newValue !== ConditionEntity.type_SET.total_product_expense
                ) {
                    this.condForm.controls.product_fk_id!.patchValue(null);
                }
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.disabled && changes.disabled.currentValue) {
            this.condForm.controls.type?.disable({emitEvent: false});
            this.condForm.controls.metric_id?.disable({emitEvent: false});
            this.condForm.controls.operator?.disable({emitEvent: false});
            this.condForm.controls.value?.disable({emitEvent: false});
            this.condForm.controls.product_fk_id?.disable({emitEvent: false});
        }
        if (
            changes.condForm?.currentValue?.controls?.type?.disabled ||
            changes.condForm?.currentValue?.controls?.metric_id?.disabled
        ) {
            this.selectedMetricFG.disable({emitEvent: false});
        }
        if (this.condForm.controls.type!.value && !this.condForm.controls.metric_id!.value) {
            this.selectedMetricFG.patchValue(this.condForm.controls.type!.value, {
                emitEvent: false
            });
        } else if (!this.condForm.controls.type!.value && this.condForm.controls.metric_id!.value) {
            this.selectedMetricFG.patchValue(this.condForm.controls.metric_id!.value, {
                emitEvent: false
            });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
