import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserContext, UserManager} from '@skyslope/auth-js';
import {Subject, firstValueFrom} from 'rxjs';
import {UserService} from 'app/services/user.service';
import {AuthService} from 'app/services/auth.service';
import {ProfilesService} from '../../../services/profiles.service';
import {ActivatedRoute} from '@angular/router';
import {SkyslopeAuth} from '../services/okta/skyslope-auth.service';
import {ZohoInviteService} from '../services/zoho-invite.service';
import {takeUntil} from 'rxjs/operators';
import {IUser} from '@cyberco-nodejs/zipi-typings';
import {jwtDecode} from 'jwt-decode';
import {NotificationsServiceZipi} from '../../notifications/notifications.service';
import {getBrowserName} from '@app/utilities';

type Company = {
    title: string;
    plan: string;
};

interface TokenPayload {
    sub: string;
    first_name: string;
    last_name: string;
    uid: string;
}
@Component({
    selector: 'app-sign-in-skyslope-callback',
    templateUrl: './sign-in-skyslope-callback.component.html'
})
export class SignInSkyslopeCallbackComponent implements OnInit, OnDestroy {
    userManager: UserManager | undefined;
    isLoading: boolean = true;
    private unsubscribe: Subject<void> = new Subject();
    isAuthenticated: boolean = false;
    private inviteHash: string | null = null;
    private zohoInviteHash: string | null = null;
    existingUser: IUser | null = null;
    private zohoInvite: any;
    private currentOktaUser: UserContext | null = null;

    constructor(
        private usersService: UserService,
        private authService: AuthService,
        private profilesService: ProfilesService,
        private skyslopeAuthService: SkyslopeAuth,
        private route: ActivatedRoute,
        protected zohoInviteService: ZohoInviteService,
        protected notificationsService: NotificationsServiceZipi
    ) {}

    async ngOnInit(): Promise<void> {
        const error: string | null = this.route.snapshot.queryParamMap.get('error');

        if (error) {
            this.notificationsService.addError(error);
            throw new Error(error);
        }

        await this.handleAuthentication();
        if (this.isAuthenticated) {
            try {
                await this.authService.signInWithOkta(await this.skyslopeAuthService.getAccessToken());

                this.inviteHash = sessionStorage.getItem('inviteHash');
                this.zohoInviteHash = sessionStorage.getItem('zohoInviteHash');

                if (!this.inviteHash && !this.zohoInviteHash) {
                    const browser = getBrowserName();
                    if (browser === 'firefox') {
                        // If browser is firefox, then skip okta conversion check process, as the me-by-email is
                        // causing a NS_BINDING_ABORT error that is interrupting the sign-on process.  Since this
                        // flow will soon be deprecated, we will exclude it from the FireFox browser.
                        setTimeout(() => {
                            window.location.href = '/default-page';
                        }, 0);
                    } else {
                        this.handleOktaConversionCheck();
                    }
                } else {
                    await this.handleInvite();
                }
            } catch (error) {
                this.clearInviteStorage();
                window.location.href = '/login-error';
            }
        } else {
            this.clearInviteStorage();
            window.location.href = '/login-error';
        }
    }

    private async handleAuthentication() {
        const code: string | null = this.route.snapshot.queryParamMap.get('code');
        if (code && code != '') {
            // Okta callback included code parameter, process it.
            await this.skyslopeAuthService.handleTokenAuthentication();
        } else {
            // Okta callback didn't include a code parameter, however we will call
            // method to handle situation where we are pre-authenticated.
            await this.skyslopeAuthService.handlePreAuthentication();
        }

        this.isAuthenticated = await this.skyslopeAuthService.isAuthenticated();
    }

    private async handleInvite() {
        const token = await this.skyslopeAuthService.getAccessToken();
        const decodedToken = jwtDecode(token!) as TokenPayload;
        const currentOktaUser = {
            email: decodedToken.sub,
            firstName: decodedToken.first_name,
            lastName: decodedToken.last_name,
            id: decodedToken.uid
        };

        if (this.zohoInviteHash) {
            await this.handleZohoInvite(currentOktaUser);
        } else if (this.inviteHash) {
            await this.handleUserInvite(currentOktaUser);
        }

        this.clearInviteStorage();
        setTimeout(() => {
            window.location.href = '/default-page';
        }, 0);
    }

    private async handleZohoInvite(currentOktaUser: UserContext) {
        const decodedHash = JSON.parse(atob(this.zohoInviteHash!));
        if (currentOktaUser.email !== decodedHash.invite_email) {
            throw new Error('Email from Okta user and invite email do not match');
        }

        const invite = await firstValueFrom(
            this.zohoInviteService.getZipiInvite(decodedHash.zoho_invite_hash).pipe(takeUntil(this.unsubscribe))
        );

        this.zohoInvite = invite.result;
        this.existingUser = await this.getExistingUser(decodedHash, currentOktaUser);

        if (!this.existingUser) {
            throw new Error('User not found');
        }
        const company = this.getCompany(this.zohoInvite);
        sessionStorage.removeItem('zohoInviteHash');

        this.zohoInviteService
            .afterRegister(this.existingUser, company, this.zohoInvite.invite_hash)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe({
                error: (err) => console.error('Error accepting company invite', err)
            });
    }

    private async handleUserInvite(currentOktaUser: UserContext) {
        await this.profilesService.getInvitedProfile(this.inviteHash!).then(async (invitedProfileInfo) => {
            if (currentOktaUser.email !== invitedProfileInfo.profile.email) {
                throw new Error('Email from Okta user and invite email do not match');
            }
            const source =
                invitedProfileInfo.existingUser && typeof invitedProfileInfo.existingUser === 'object'
                    ? invitedProfileInfo.existingUser
                    : invitedProfileInfo.profile;

            const user = {
                email: source.email ?? '',
                first_name: source.first_name ?? '',
                last_name: source.last_name ?? ''
            };
            if (invitedProfileInfo.profile.id) {
                await this.profilesService.acceptInvite(invitedProfileInfo.profile.id, this.inviteHash!, user);
            }
        });
    }

    private async handleOktaConversionCheck() {
        const currentProfile = await this.usersService.getMeByEmail();

        if (currentProfile) {
            const OktaIdToken = this.skyslopeAuthService.getIdToken();
            if (OktaIdToken) {
                if (!currentProfile.okta_uid) {
                    await this.usersService.convertSkySlopeUser();
                }
            }
        }
        setTimeout(() => {
            window.location.href = '/default-page';
        }, 0);
    }

    private async getExistingUser(decodedHash: any, currentOktaUser: UserContext) {
        const user = await firstValueFrom(
            this.zohoInviteService
                .getExistingUserByEmail(decodedHash.invite_email.toLowerCase())
                .pipe(takeUntil(this.unsubscribe))
        );

        return (
            user.result || {
                first_name: currentOktaUser.firstName,
                last_name: currentOktaUser.lastName,
                email: currentOktaUser.email!.toLowerCase(),
                okta_uid: currentOktaUser.id
            }
        );
    }

    private getCompany(zohoInvite: any) {
        const company: Company = {
            title: zohoInvite.receiver_settings?.receiver_company_title || '',
            plan: zohoInvite.receiver_settings?.receiver_company_plan || ''
        };
        return company;
    }

    private clearInviteStorage() {
        sessionStorage.removeItem('zohoInviteHash');
        sessionStorage.removeItem('inviteHash');
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
