<section class="container-fluid" *ngIf="depositRequest">
    <mat-card class="my-3">
        <div class="status-label" [ngStyle]="{'background-color': statusColor[depositRequest.status]}">
            Status: {{ depositRequest.status | fromSnakeCase }}
        </div>

        <header class="row mt-3">
            <div class="col-6 zp-company-info" *ngIf="depositRequest.type === 'incoming'">
                <p class="zp-company-title">{{ depositRequest?.sender_company_title }}</p>
            </div>

            <i class="sp-spacer"></i>

            <div class="col-6 text-right">
                <p class="zp-inveice_title">Deposit Request</p>
                <p class="zp-invoice-num_value">
                    {{ $any(depositRequest?.request_date) | dateFromNumber | date: 'mediumDate' }}
                </p>
            </div>
        </header>

        <div class="row m-3">
            <h4 class="w-100 m-0" *ngIf="depositRequest?.type === 'outgoing'">Payer</h4>
            <h4 class="w-100 m-0" *ngIf="depositRequest?.type === 'incoming'">Requester</h4>
            <div class="col-6 pl-2">
                <div>
                    <span>Name: </span>
                    <a
                        [routerLink]="['/contacts', depositRequest.request_receiver__contact_fk_id]"
                        *ngIf="depositRequest?.type === 'outgoing'"
                    >
                        {{ depositRequest?.receiver_contact_name }}
                    </a>
                    <a
                        *ngIf="depositRequest?.type === 'incoming' && depositRequest?.request_sender__contact_fk_id"
                        [routerLink]="['/contacts', depositRequest?.request_sender__contact_fk_id]"
                    >
                        {{ depositRequest?.sender_contact_name }}
                    </a>
                    <span *ngIf="depositRequest?.type === 'incoming' && !depositRequest?.request_sender__contact_fk_id">
                        {{ depositRequest?.sender_company_title }}
                    </span>
                </div>
                <div>
                    <span>Email: </span>
                    <span>{{ depositRequest?.request_receiver_email || '&mdash;' }}</span>
                </div>
                <div *ngIf="depositRequest?.type === 'outgoing'">
                    <span>Deal Address: </span>
                    <a [routerLink]="['/deals', depositRequest.deal_fk_id]">
                        {{ depositRequest.deal_address || '&mdash;' }}
                    </a>
                </div>
            </div>
            <div class="col-6 text-right">
                <p class="zp-refference_due_title" *ngIf="depositRequest.type === 'incoming'">
                    Date: {{ $any(depositRequest?.request_date) | dateFromNumber | date: 'mediumDate' }}
                </p>
                <p class="zp-refference_due_title">
                    Due Date: {{ $any(depositRequest?.due_date) | dateFromNumber | date: 'mediumDate' }}
                    <span *ngIf="!depositRequest.due_date">&mdash;</span>
                </p>
                <!--                <p class="zp-refference_due_title">Amount: {{depositRequest?.request_amount | currency}}</p>-->
                <!--                <p class="zp-refference_due_title">Balance: {{depositRequest?.request_balance | currency}}</p>-->
                <!--                <p class="zp-refference_due_title">Pending Balance: {{depositRequest?.request_pending_balance | currency}}</p>-->
            </div>
        </div>

        <table class="table">
            <thead class="bg-dark text-white">
                <tr>
                    <td scope="col">#</td>
                    <td scope="col" class="w-30">Item</td>
                    <td scope="col" class="w-30">Related Deal</td>
                    <td scope="col">Qty</td>
                    <td scope="col">Rate</td>
                    <td scope="col">Amount</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>
                        {{ depositRequest?.product_name }}
                        <i class="text-muted">{{ depositRequest?.product_description }}</i>
                    </td>
                    <td>
                        <a
                            *ngIf="depositRequest.type === 'outgoing'"
                            [routerLink]="['/deals/edit', depositRequest.deal_fk_id]"
                        >
                            <span *ngIf="!depositRequest.deal_address" class="text-muted">No address</span>
                            <span *ngIf="depositRequest.deal_address">{{ depositRequest.deal_address }}</span>
                        </a>
                        <ng-container *ngIf="depositRequest.type === 'incoming'">
                            {{ depositRequest.deal_address }}
                        </ng-container>
                    </td>
                    <td>1</td>
                    <td class="text-right">{{ depositRequest?.request_amount | currency: '' : '' : '0.2-2' }}</td>
                    <td class="text-right">{{ depositRequest?.request_amount | currency: '' : '' : '0.2-2' }}</td>
                </tr>
            </tbody>
        </table>

        <div class="w-100 text-right">
            <p class="mb-2">
                <strong
                    >Total:
                    <span class="zp-total-number font-weight-bold">{{ depositRequest.request_amount | currency }}</span>
                </strong>
            </p>
            <div *ngIf="depositRequest.payments && depositRequest.payments.length > 0">
                <ng-container *ngFor="let payment of depositRequest.payments; let i = index">
                    <div *ngIf="['completed', 'processing', 'pending'].includes(payment.status)">
                        <div
                            class="py-2"
                            [ngStyle]="{
                                color:
                                    payment.status === 'completed'
                                        ? 'black'
                                        : payment.status === 'pending'
                                          ? 'orange'
                                          : payment.status === 'canceled' || payment.status === 'error'
                                            ? '#f94242'
                                            : 'black'
                            }"
                        >
                            <div>
                                <span class="zp-w-150">
                                    <a
                                        class="remove-payment-container"
                                        *ngIf="depositRequest.type === 'incoming'"
                                        (keyup.enter)="cancelPayment(payment)"
                                        (click)="$event.stopPropagation(); $event.preventDefault()"
                                    >
                                        <mat-icon
                                            class="remove-payment-icon"
                                            [matTooltip]="'Remove'"
                                            (click)="cancelPayment(payment)"
                                            *ngIf="
                                                [
                                                    'deduction',
                                                    'vendor_credit',
                                                    'customer_credit',
                                                    'payment_made',
                                                    'payment_received'
                                                ].includes(payment.source_type) &&
                                                payment.type === 'normal' &&
                                                !isCancelPaymentButtonDisabled
                                            "
                                        >
                                            close
                                        </mat-icon>
                                    </a>

                                    <span>Payment</span>
                                </span>

                                <span class="zp-total-number">
                                    <a
                                        [ngStyle]="payment.type === 'refund' ? {color: 'red'} : null"
                                        *ngIf="payment.source__payment_made_fk_id && depositRequest.type === 'incoming'"
                                        [routerLink]="'/purchases/payments/' + payment.source__payment_received_fk_id"
                                    >
                                        {{
                                            payment.type === 'normal'
                                                ? (payment.amount | currency)
                                                : (payment.amount * -1 | currency)
                                        }}
                                    </a>

                                    <a
                                        [ngStyle]="payment.type === 'refund' ? {color: 'red'} : null"
                                        *ngIf="
                                            payment.source__payment_received_fk_id && depositRequest.type === 'outgoing'
                                        "
                                        [routerLink]="
                                            '/sales/paymentsreceived/' + payment.source__payment_received_fk_id
                                        "
                                    >
                                        {{
                                            payment.type === 'normal'
                                                ? (payment.amount | currency)
                                                : (payment.amount * -1 | currency)
                                        }}
                                    </a>
                                    <span
                                        *ngIf="
                                            !payment.source__payment_received_fk_id &&
                                            !payment.source__payment_made_fk_id
                                        "
                                        >{{ payment.amount | currency }}</span
                                    >
                                </span>
                            </div>

                            <div *ngIf="payment.status === 'processing'" style="line-height: 2px">
                                <span class="zp-w-150"></span>

                                <span class="zp-total-number" style="color: grey; font-size: 14px"> (processing) </span>
                            </div>

                            <div *ngIf="payment.status === 'pending'" style="line-height: 13px">
                                <span class="zp-w-150"></span>

                                <span
                                    class="zp-total-number text-wrap"
                                    style="color: grey; font-size: 14px; width: 65px; text-align: center; padding: 0"
                                >
                                    Pending Deduction
                                </span>
                            </div>

                            <div
                                *ngIf="payment.status === 'canceled' || payment.status === 'error'"
                                style="line-height: 2px"
                            >
                                <span class="zp-w-150"></span>

                                <span class="zp-total-number" style="color: grey; font-size: 14px">
                                    ({{ payment.status }})
                                </span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <p>
                <strong class="bg-light py-2">
                    <span class="zp-w-150">Balance:</span>
                    <strong class="zp-total-number">{{ depositRequest.request_balance | currency }}</strong>
                </strong>
            </p>
            <p *ngIf="depositRequest.request_balance !== depositRequest.request_pending_balance">
                <span class="bg-light py-2">
                    <span class="zp-w-150">Pending Balance:</span>
                    <span class="zp-total-number">{{ depositRequest.request_pending_balance | currency }}</span>
                </span>
            </p>
        </div>

        <div class="d-flex">
            <div
                *ngIf="!['paid', 'draft'].includes(depositRequest.status) && depositRequest.type === 'incoming'"
                [formGroup]="payDataForm"
                class="w-50"
            >
                <div>
                    <mat-form-field class="w-60 mr-2">
                        <mat-select [placeholder]="'Payment Method'" formControlName="current_method">
                            <ng-container *ngFor="let gateway of allAvailableGateways">
                                <ng-container *ngIf="isAbleToDisplayAuthCustomer(gateway)">
                                    <div *ngFor="let method of gateway.payment_methods">
                                        <mat-option
                                            *ngIf="isAbleToDisplay(method) && method.is_available_for_payment"
                                            [value]="method"
                                            [disabled]="
                                                method.status !== 'active' ||
                                                !method.related__ledger_account_fk_id ||
                                                gateway.status !== 'active' ||
                                                method.title === 'ZipiPay'
                                            "
                                        >
                                            {{ method?.title }}
                                        </mat-option>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="w-20">
                        <input
                            matInput
                            formControlName="payment_amount"
                            placeholder="Amount"
                            [maskito]="currencyMaskitoMask"
                            autocomplete="off"
                            (keyup)="validateAmount($event)"
                        />
                    </mat-form-field>
                </div>
                <button
                    mat-raised-button
                    [anyRule]="[{escrow_deposits__access: true}, {deals__manage_escrow_deposits: true}]"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    [disabled]="!payDataForm || !payDataForm.value || !payDataForm.value.current_method"
                    (click)="payDepositRequest()"
                >
                    Pay
                </button>
            </div>

            <i class="sp-spacer"></i>

            <!-- <div class="w-50">
                <div class="d-flex align-items-center justify-content-end">
                    Related Payments
                    <button mat-icon-button (click)="showPayments = !showPayments">
                        <mat-icon *ngIf="showPayments">expand_more</mat-icon>
                        <mat-icon *ngIf="!showPayments">chevron_right</mat-icon>
                    </button>
                </div>

                <div *ngIf="showPayments && dataSource.data && dataSource.data.length === 0 && dataConnectedSource.data && dataConnectedSource.data.length === 0">
                    No related payments.
                </div>

                <div *ngIf="showPayments && dataSource.data && dataSource.data.length > 0">
                    <table mat-table [dataSource]="dataSource" class="w-100">
                        <ng-container matColumnDef="payment_date">
                            <th mat-header-cell *matHeaderCellDef> Payment Date</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.paid_date ? (element.paid_date | dateFromNumber | date: 'mediumDate')
                                : (element.created_at | date: 'mediumDate')}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="source">
                            <th mat-header-cell *matHeaderCellDef> Payment#</th>
                            <td mat-cell *matCellDef="let element">
                                <a *ngIf="element.source__payment_received_fk_id && element.source_payment_received"
                                   routerLink="/sales/paymentsreceived/{{element.source_payment_received.payment_received_id}}">
                                    {{element.source_payment_received?.payment_received_number || 'N/A'}}
                                </a>
                                <span *ngIf="element.source__payment_received_fk_id && !element.source_payment_received">Payment has been removed</span>
                                <span *ngIf="!element.source__payment_received_fk_id && !element.source_payment_received">{{'N/A'}}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let element"> {{element.status | fromSnakeCase }} </td>
                        </ng-container>

                        <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef style="text-align: end"> Total Amount</th>
                            <td mat-cell *matCellDef="let element" style="text-align: end"> {{element.amount | currency}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedRelatedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedRelatedColumns;"
                            class="zp-table-row"></tr>
                    </table>
                </div>
            </div>-->
        </div>
    </mat-card>
</section>
