import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'app-prompt',
    templateUrl: './prompt.component.html',
    styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit {
    input: UntypedFormControl = new UntypedFormControl('');
    constructor(
        public dialogRef: MatDialogRef<PromptComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data.default_input_value) {
            this.input.patchValue(data.default_input_value);
        }
    }

    ngOnInit() {}
}
