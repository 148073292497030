<mat-tab-group mat-stretch-tabs>
    <mat-tab label="Bank & Credit Card">
        <app-edit-ledger-account
            (create)="dialogRef.close($event)"
            (cancel)="dialogRef.close(false)"
            [bankingMode]="data.banking_mode"
            [isTrustAccount]="false"
            [dialogMode]="true"
            [groupsList]="groupsList"
        ></app-edit-ledger-account>
    </mat-tab>
    <mat-tab label="Trust Account">
        <app-edit-ledger-account
            (create)="dialogRef.close($event)"
            (cancel)="dialogRef.close(false)"
            [bankingMode]="data.banking_mode"
            [isTrustAccount]="true"
            [dialogMode]="true"
            [groupsList]="groupsList"
        ></app-edit-ledger-account>
    </mat-tab>
</mat-tab-group>
