import {GenericEntity} from '../../../../entites/generic.entity';
import {IChipNode, IParticipantSubTypes} from '@cyberco-nodejs/zipi-typings';

export class ChipNode extends GenericEntity implements IChipNode {
    type: string = '';
    target_id: number | null = null;
    company_fk_id: number | null = null;
    label: string = '';

    kind: string = ''; // for 'individuals_in_group': company_group.type
    subType: IParticipantSubTypes[] = []; // for 'contact_person': deal_participants.others
    permissions: JSON | string | null = null; // for 'individuals_in_group' & 'company_group': company_group.permissions
    email: string = ''; // for 'individual': profile.email
    contact_id: number | null = null; // for 'individual' & 'company_group': deal.sales_persons
    contact_name: string = ''; // for 'individual' & 'company_group': deal.sales_persons
    contact_persons: {
        contact_company_name: string;
        contact_person_id: number;
        contact_person_name: string;
        contact_person_type: string;
    }[] = []; // for 'contact' & 'contact_person': deal_participants
    hidden: boolean = false;
    related_profile_and_group: boolean = false;

    setType(type: string): ChipNode {
        this.type = type;
        return this;
    }

    setTargetId(targetId: number | null): ChipNode {
        this.target_id = targetId;
        return this;
    }

    setCompanyFkId(companyFkId: number): ChipNode {
        this.company_fk_id = companyFkId;
        return this;
    }

    setLabel(label: string): ChipNode {
        this.label = label;
        return this;
    }

    setSubType(subType: IParticipantSubTypes[]): ChipNode {
        this.subType = subType;
        return this;
    }

    setPermissions(permissions: JSON | string | null): ChipNode {
        this.permissions = permissions;
        return this;
    }

    setEmail(email: string): ChipNode {
        this.email = email;
        return this;
    }

    setContactId(contact_id: number | null): ChipNode {
        this.contact_id = contact_id;
        return this;
    }

    setContactName(contact_name: string): ChipNode {
        this.contact_name = contact_name;
        return this;
    }

    setContactPerson(
        contact_persons:
            | {
                  contact_company_name: string;
                  contact_person_id: number;
                  contact_person_name: string;
                  contact_person_type: string;
              }[]
            | null
    ): ChipNode {
        this.contact_persons = contact_persons ? contact_persons : [];
        return this;
    }

    setRelatedProfileAndGroup(related_profile_and_group: boolean): ChipNode {
        this.related_profile_and_group = related_profile_and_group;
        return this;
    }

    static get type_SET() {
        return {
            company_group: 'company_group',
            contact: 'contact',
            deal: 'deal',
            contact_wildcard: 'contact_wildcard',
            individuals_in_group: 'individuals_in_group',
            individual: 'individual',
            wildcard: 'wildcard',
            contact_class: 'contact_class',
            contact_person: 'contact_person'
        };
    }
}
