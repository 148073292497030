// export {GroupMemberEntity as GroupEntityModel} from '../entites/group-member.entity.js'

import {BaseModel} from './base.model';
import {Group} from './group';
import {Profile} from './profile';
import {User} from './user';
import {IContact, IGroup, IGroupMember, IProfile, IUser} from '@cyberco-nodejs/zipi-typings';

export class GroupMemberEntity extends BaseModel implements IGroupMember {
    public id?: number;
    public role: any = GroupMemberEntity.role_SET.admin;
    public company_group: IGroup | null = null;
    // @ts-ignore
    public profile: Profile | null = null;
    // @ts-ignore
    public user: User = new User();
    public status: any = GroupMemberEntity.status_SET.new;
    public email: string | null = null;
    public group_roles_ids: Array<number> | null = null;

    invite_hash: string | null = null;
    company_group_fk_id: number | null = null;
    profile_fk_id: number | null = null;
    contact_fk_id: number | null = null;
    user_fk_id: number | null = null;

    members?: IGroupMember[];
    contact?: IContact;

    setStatus(status: string) {
        this.status = status;
        return this;
    }

    setUser(user: User) {
        this.user = user;
        return this;
    }

    unsetId() {
        this.id = undefined;
        return this;
    }

    setGroup(company_group: IGroup) {
        this.company_group = company_group;
        return this;
    }

    setRole(role: string) {
        this.role = role;
        return this;
    }

    setProfile(profile: Profile) {
        this.profile = profile;
        return this;
    }

    setEmail(email: string) {
        this.email = email;
        return this;
    }

    setProfileFkId(profileId: number) {
        this.profile_fk_id = profileId;
        return this;
    }

    setCompanyGroupFkId(profileId: number) {
        this.company_group_fk_id = profileId;
        return this;
    }

    static get status_SET() {
        return {
            new: 'new',
            active: 'active',
            invited: 'invited',
            suspended: 'suspended'
        };
    }

    static get role_SET() {
        return {
            default: 'default',
            admin: 'admin'
        };
    }
}
