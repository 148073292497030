import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {PAYMENT_CREDITS_STATUS_COLOR, PAYMENT_MODES_MAP} from '../../../../../../../../local-typings';
import {Subject} from 'rxjs';
import {
    IDealDeposit,
    IDealDepositRequest,
    IPayment,
    IPaymentReceivedMadeRequestObject
} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';
import {filter, takeUntil} from 'rxjs/operators';
import {NotificationsServiceZipi} from '../../../../../../../notifications/notifications.service';
import {CreatePaymentDialogComponent} from '../create-payment-dialog/create-payment-dialog.component';
import {DealFinancialApiService} from '../../../../deal-financial.api.service';
import * as moment from 'moment';
import {DealProcessingService} from '../../../../deal-processing.service';
import {Router} from '@angular/router';
import {ConfirmComponent} from '../../../../../../../../layouts/confirm/confirm.component';

@Component({
    selector: 'app-deposit-payment-list',
    templateUrl: './deposit-payment-list.component.html',
    styleUrls: ['./deposit-payment-list.component.scss']
})
export class DepositPaymentListComponent implements OnInit, OnChanges, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() requestsList: IDealDepositRequest[] = [];
    @Input() paymentsList: IPayment[] = [];
    @Input() dealId: number | null = null;
    @Input() dealAddress: string | null = null;
    @Input() dealDepositAmounts: IDealDeposit | null = null;
    @Input() disabled: boolean = false;
    @Output() refreshPayments = new EventEmitter();

    statusColor = PAYMENT_CREDITS_STATUS_COLOR;
    paymentModesMap = PAYMENT_MODES_MAP;

    dataSourcePayments: MatTableDataSource<any>;
    displayedPaymentsColumns: string[] = [
        'paid_date',
        'payment_mode',
        'profile',
        'status',
        'amount',
        'reference',
        'action'
    ];

    constructor(
        public dialog: MatDialog,
        private notificationServiceZipi: NotificationsServiceZipi,
        private dealFinancialApiService: DealFinancialApiService,
        public dealProcessingService: DealProcessingService,
        public router: Router
    ) {
        this.dataSourcePayments = new MatTableDataSource<any>([]);
    }

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('paymentsList')) {
            this.dataSourcePayments.data = changes.paymentsList.currentValue;
        }
    }

    addDepositPayment() {
        if (!this.dealId) {
            this.notificationServiceZipi.addError(`To add Deposit Payment save Deal firstly.`);
            return;
        }
        const dialogRef = this.dialog.open(CreatePaymentDialogComponent, {
            minWidth: '600px',
            data: {
                requests: this.requestsList
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((payData) => {
                const paymentRequestObject: IPaymentReceivedMadeRequestObject = {
                    money_receiver__contact_fk_id: null,
                    money_sender__contact_fk_id: payData.money_sender__contact_fk_id,
                    paid_date: payData.paid_date,
                    scheduled_date: null,
                    amount: payData.amount,
                    paid_by__ledger_account_fk_id: null,
                    paid_by__payment_method_fk_id: null,
                    pay_to__ledger_account_fk_id: payData.pay_to__ledger_account_fk_id,
                    pay_to__payment_method_fk_id: null,
                    payments: [
                        {
                            entity_id: payData.request_id,
                            payment_id: null,
                            entity_transfer_id: null,
                            entity: null,
                            amount: payData.amount
                        }
                    ],
                    is_create_multiple_payments: false,
                    payment_mode: payData.payment_mode,
                    payment_number: null,
                    reference: `Payment for deal# ${this.dealId}. \r\n ${this.dealAddress}`,
                    notes: payData.note,
                    matched__transaction_external_id: null,
                    check_info: null,
                    deposit_release_id: null,
                    is_locked_for_applying: false,
                    allow_auto_apply: false,
                    source__deal_fk_id: null,
                    sender_velocity: null,
                    restrict_downgrade: false
                };
                if (payData.payment_mode === 'check_record') {
                    paymentRequestObject.check_info = {
                        memo: payData.memo,
                        check_number: payData.check_number,
                        check_status: 'uncleared',
                        print_status: 'unknown'
                    };
                }

                this.dealFinancialApiService
                    .createDealDepositPayment(payData.request_id, paymentRequestObject)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((response) => {
                        if (response) {
                            this.refreshPayments.emit(true);
                        }
                    });
            });
    }

    getRequestRef(payment: IPayment) {
        let referenceString = '';
        const request = this.requestsList.find((req) => req.deposit_request_id === payment.deposit_request_fk_id);

        referenceString = `${moment(request!.request_date, 'YYYYMMDD').format('ll')} ($${request!.request_amount})`;
        return referenceString;
    }

    redirectToPaymentReceived(payment: IPayment) {
        if (payment.source__payment_received_fk_id) {
            this.router.navigate([`/sales/paymentsreceived/${payment.source__payment_received_fk_id}`]);
        }
    }

    openConfirm(payment: IPayment) {
        if (payment.amount > this.dealDepositAmounts!.unreleased_balance) {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                width: '400px',
                // minHeight: '400px',
                data: {
                    title: 'Deleting Deposit Request Payment',
                    messages: [
                        `The amount of this Deposit Request Payment already in use.`,
                        `To delete this payment you need first delete Releases.`
                    ],
                    hideCancel: true
                }
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((ok) => {});
        } else if (
            payment.source_payment_received &&
            !['zipi_pay', 'zipi_financial', 'authorize', 'deduction'].includes(
                payment.source_payment_received!.payment_mode!
            )
        ) {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                width: '400px',
                // minHeight: '400px',
                data: {
                    title: 'Deleting Deposit Request Payment',
                    message: 'Deposit Request Payment will be deleted'
                }
            });
            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((ok) => {
                    if (ok) {
                        this.deletePayment(payment);
                    }
                });
        } else {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                width: '400px',
                // minHeight: '400px',
                data: {
                    title: 'Deleting Deposit Request Payment',
                    messages: [
                        `The Deposit Request Payment was made through ${this.paymentModesMap[payment.source_payment_received!.payment_mode!]}.`,
                        `To delete this payment you need first initiate refund.`
                    ],
                    hideCancel: true
                }
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((ok) => {});
        }
    }

    deletePayment(payment: IPayment) {
        this.dealFinancialApiService
            .deleteDealDepositPayment(payment.payment_id!)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                if (response) {
                    this.refreshPayments.emit(true);
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.refreshPayments.complete();
        this.dataSourcePayments.disconnect();
    }
}
