import {Injectable} from '@angular/core';

import {ServiceRequester} from '../../services/service.requester';
import {AuthService} from 'app/services/auth.service';
import {SessionService} from '../../services/session.service';
import {Subject} from 'rxjs';
import {CurrentProfileSource} from '../../services/sources/current-profile.source';
import {PermissionsSource} from '../../services/sources/permissions.source';
import {SystemNotificationSource} from '../user-notification/system-notification.source';

@Injectable()
export class RbacService {
    protected permissions: {[key: string]: any} = {};
    // protected groupPermissions = {};

    public onPermissionsUpdate: Subject<void> = new Subject();
    // public onGroupPermissionsUpdate = new Subject();

    // protected promise: Promise<any>;

    constructor(
        protected requester: ServiceRequester,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected profileSource: CurrentProfileSource,
        protected permissionsSource: PermissionsSource,
        protected systemNotifications: SystemNotificationSource
    ) {}

    getProfilePermissions(profileId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.permissions[profileId]) {
                return resolve(this.permissions[profileId]);
            }

            this.permissionsSource.permissions.subscribe((permissions) => {
                if (!this.sessionService.profile || !this.sessionService.profile.id) {
                    return;
                }
                this.permissions[this.sessionService.profile.id] = permissions;
                this.onPermissionsUpdate.next();
                return resolve(this.permissions[profileId]);
            });
        });
    }

    isAllowed(ruleObj: {[key: string]: boolean}): Promise<boolean> {
        if (!this.sessionService.profile || !this.sessionService.profile.id) {
            return new Promise((resolve) => resolve(false));
        }
        return this.getProfilePermissions(this.sessionService.profile.id).then((userPermissions) =>
            this.checkPermissions(userPermissions, ruleObj)
        );
    }

    isAllowedAny(ruleArr: Array<{[key: string]: boolean}>): Promise<boolean> {
        if (!this.sessionService.profile || !this.sessionService.profile.id) {
            return new Promise((resolve) => resolve(false));
        }
        return this.getProfilePermissions(this.sessionService.profile.id).then((userPermissions) => {
            const permList: boolean[] = ruleArr
                // run checkPermissions for each ruleObject
                .map((ruleObj) => this.checkPermissions(userPermissions, ruleObj))
                // filter truthy items
                .filter((bool) => bool);

            return permList.length > 0;
        });
    }

    isAllowedFirst(
        arr: Array<{path: string; rules: Array<{[key: string]: boolean}>}>
    ): Promise<Array<{path: string; rules: Array<{[key: string]: boolean}>; isAllowed: boolean}>> {
        if (!this.sessionService.profile || !this.sessionService.profile.id) {
            return new Promise((resolve) => resolve([]));
        }

        return this.getProfilePermissions(this.sessionService.profile.id).then((userPermissions) => {
            const allowedList = arr.map((el) => {
                return {
                    ...el,
                    isAllowed: !!el.rules
                        .map((ruleObj) => this.checkPermissions(userPermissions, ruleObj))
                        .find((val) => !!val)
                };
            });

            return allowedList;
        });
    }

    checkPermissions(userPermissions: any, ruleObj: {[key: string]: boolean}): boolean {
        let allow = true;

        for (const rule in ruleObj) {
            if (ruleObj.hasOwnProperty(rule)) {
                const savedPermissionValue = !!userPermissions ? userPermissions[rule] : undefined;
                const specifiedRuleValue = ruleObj[rule];

                if (savedPermissionValue === undefined && specifiedRuleValue === false) {
                    allow = true;
                } else if (savedPermissionValue !== specifiedRuleValue) {
                    allow = false;

                    break;
                }
            }
        }

        return allow;
    }

    /**
     * @todo find why edit-deal component throws types errors
     * @param ruleObj
     * @return {any}
     */
    isDenied(ruleObj: {[key: string]: boolean}): Promise<boolean> {
        return this.isAllowed(ruleObj).then((allow) => {
            return !allow;
        });
    }
}
