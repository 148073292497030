import {
    Component,
    EventEmitter,
    Output,
    OnInit,
    OnDestroy,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ElementRef,
    AfterViewChecked,
    ChangeDetectorRef
} from '@angular/core';
import {Subject} from 'rxjs';

import {DealsBoard, DealsBoardFrontendFilterSettings} from '../../../common/deal.models';
import {UiModsSource} from '../../../../../../ui-mods/ui-mods.source';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-deal-board-view',
    styleUrls: ['./deal-board-view.component.scss'],
    templateUrl: './deal-board-view.component.html'
})
export class DealBoardViewComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('boardHeader', {static: false}) boardHeader: ElementRef | undefined = undefined;

    public isMenuOptions: boolean = false;

    public search: string = '';
    public isExtraBoardsAllowed = true;

    @Output() edit = new EventEmitter();
    @Output() frontendFilterChanged: EventEmitter<DealsBoardFrontendFilterSettings> = new EventEmitter();
    @Output() activeTabIndexChanged = new EventEmitter();
    @Output() setBoardAsDefaultEmitter = new EventEmitter();
    @Output() createBoardModeEmitter = new EventEmitter();
    @Output() savedActiveTabEmitter = new EventEmitter();

    @Input() isCustomizeMode: boolean = false;
    @Input() boardsList: DealsBoard[] = [];
    @Input() activeTabIndex: number = 0;
    @Input() activeTabIndex$: Subject<number> = new Subject<number>();
    @Input() offsetWidth: number = 0;
    @Input() selectedDeal: boolean = false;
    @Input() boardMeta: {[key: string]: number} = {};
    @Input() defaultBoardIndex: number | undefined = 0;
    @Input() dealsBoard: DealsBoard | undefined = undefined;

    toggleFiltersView: boolean = false;

    filters: DealsBoardFrontendFilterSettings = {
        statuses: {},
        types: {},
        disbursement_statuses: {},
        property_classes: {},
        search: '',
        tags: null,
        divisions: null
    };

    constructor(
        private uiModsSource: UiModsSource,
        private changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.uiModsSource.createDealMenuOptions.pipe(takeUntil(this.unsubscribe)).subscribe((menu_options) => {
            this.isMenuOptions = menu_options.length > 0;
        });

        this.activeTabIndex$.pipe(takeUntil(this.unsubscribe)).subscribe((index) => {
            this.activeTabIndex = index;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        // initial set
        // JSON.parse & JSON.stringify needed for creating independent filters object
        if (changes.boardMeta && changes.boardMeta.currentValue && this.dealsBoard) {
            this.filters = this._assembleFiltersForViewFilters(
                JSON.parse(JSON.stringify(this.dealsBoard.settings_frontend))
            );
        }
    }

    ngAfterViewChecked() {
        if (
            this.boardHeader &&
            this.boardHeader.nativeElement.offsetWidth &&
            this.offsetWidth - this.boardHeader.nativeElement.offsetWidth <= 891
        ) {
            this.toggleFiltersView = true;
            this.changeDetector.detectChanges();
        } else if (
            this.boardHeader &&
            this.boardHeader.nativeElement.offsetWidth &&
            this.offsetWidth - this.boardHeader.nativeElement.offsetWidth > 891
        ) {
            this.toggleFiltersView = false;
            this.changeDetector.detectChanges();
        }
    }

    handleBoardClick(boardId: number) {
        this.activeTabIndex = this.boardsList.findIndex((board) => board.id === boardId);

        this.dealsBoard = this.boardsList.find((board) => board.id === boardId);
        if (this.dealsBoard) {
            this.filters = this._assembleFiltersForViewFilters(
                JSON.parse(JSON.stringify(this.dealsBoard.settings_frontend))
            );
            this.activeTabIndexChanged.emit(this.dealsBoard);
        }
    }

    goToNewBoardTab() {
        this.createBoardModeEmitter.emit(true);
    }

    /**
     * For View board filters we create copy of DealsBoardFrontendFilterSettings
     * but without statuses and types that are switched off
     */
    _assembleFiltersForViewFilters(filters: any) {
        return Object.keys(filters).reduce(
            (acc, cur) => {
                if (typeof filters[cur] === 'object' && filters[cur] !== null) {
                    return Object.assign(acc, {
                        [cur]: Object.keys(filters[cur]).reduce((result, currentValue) => {
                            if (filters[cur][currentValue]) {
                                result = Object.assign(result, {[currentValue]: filters[cur][currentValue]});
                            }
                            return result;
                        }, {})
                    });
                } else {
                    return Object.assign(acc, {[cur]: filters[cur]});
                }
            },
            {
                statuses: {},
                types: {},
                disbursement_statuses: {},
                property_classes: {},
                search: '',
                tags: null,
                divisions: null
            } as DealsBoardFrontendFilterSettings
        );
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.edit.complete();
        this.frontendFilterChanged.complete();
        this.activeTabIndexChanged.complete();
        this.setBoardAsDefaultEmitter.complete();
        this.createBoardModeEmitter.complete();
        this.savedActiveTabEmitter.emit(this.activeTabIndex);
        this.savedActiveTabEmitter.complete();
        this.activeTabIndex$.complete();
    }
}
