import {Component, OnDestroy} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {ILedgerAccount} from '@cyberco-nodejs/zipi-typings';
import * as moment from 'moment';

@Component({
    selector: 'app-add-adjustment-dialog',
    styles: [
        `
            .row.footer {
                justify-content: space-between;
            }

            mat-list {
                max-height: 700px;
                width: 100%;
                overflow-x: hidden;
            }
        `
    ],
    template: `
        <div class="col" style="justify-content: space-between; flex-direction: column; display: flex">
            <h3>Activate {{ ledgerAccount?.name }} account feed from</h3>

            <app-date-picker [placeholder]="'Date'" [dateControl]="activateFeedFromControl"></app-date-picker>
            <div class="row footer">
                <i class="sp-spacer"></i>
                <button mat-raised-button class="mat-default ml-1" (click)="close()">
                    {{ isEndOfConnectToPlaid ? 'Skip & Activate Later' : 'Cancel' }}
                </button>
                <button mat-raised-button class="mat-primary ml-1" (click)="save()">OK</button>
            </div>
        </div>
    `
})
export class ActivateFeedDialogComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    formGroup: UntypedFormGroup = this.fb.group({});
    activateFeedFromControl = this.fb.control(moment().format('YYYYMMDD'), [Validators.required]);
    initFormGroupSnapshot = {};
    ledgerAccount: ILedgerAccount | undefined;
    isEndOfConnectToPlaid: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ActivateFeedDialogComponent>,
        private fb: UntypedFormBuilder
    ) {}

    save() {
        this.dialogRef.close(this.activateFeedFromControl.value);
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
