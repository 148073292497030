<div>
    <h2 class="mr-2">Add Bank Account</h2>

    <div class="d-flex w-100 justify-content-center">
        <mat-spinner *ngIf="isLoading" diameter="36"></mat-spinner>
    </div>

    <div *ngIf="allAvailableBankAccounts && allAvailableBankAccounts.length > 0 && currentStep === '1'">
        <mat-card *ngFor="let account of allAvailableBankAccounts">
            <div class="d-flex w-100 align-items-center">
                <div [ngStyle]="!account.verified ? {color: '#aaaaaa'} : {}">
                    {{ account.accountName }} ({{ account.lastFour }})
                </div>
                <mat-icon *ngIf="account.isCloudBank" style="font-size: 24px" class="ml-3" matTooltip="Cloud Bank"
                    >cloud</mat-icon
                >
                <span class="sp-spacer"></span>
                <mat-icon
                    *ngIf="!account.verified"
                    style="color: orange; font-size: 27px"
                    class="mr-3"
                    matTooltip="Account not verified."
                    >warning</mat-icon
                >
                <mat-icon
                    *ngIf="account.verified && !account.achApproved"
                    style="color: orange; font-size: 27px"
                    class="mr-3"
                    matTooltip="Pending for Verification."
                    >warning</mat-icon
                >
                <button
                    type="button"
                    mat-raised-button
                    color="primary"
                    *ngIf="!alreadyConnectedBankIds.includes(account.id) && account.verified && account.achApproved"
                    (click)="connectBank(account)"
                >
                    Connect
                </button>
                <a [href]="redirectToBankManage" target="_blank" *ngIf="redirectToBankManage">
                    <button type="button" mat-raised-button *ngIf="!account.verified">Verify</button>
                </a>
                <div class="font-weight-bold" *ngIf="alreadyConnectedBankIds.includes(account.id)">Connected</div>
            </div>
        </mat-card>
    </div>

    <div *ngIf="currentStep === '2'" [ngStyle]="currentStep === '2' ? {display: 'block'} : {display: 'none'}">
        <h3 class="w-100" style="border-bottom: solid 1px black">Connect to Ledger Account</h3>
        <div class="">
            <div>Please, select what Ledger Account you want to use with Connected Bank Account:</div>
            <div class="pl-3 my-3">
                <mat-radio-group [(ngModel)]="isDefault">
                    <div>
                        <mat-radio-button [value]="true"
                            >Create Default Account
                            {{
                                ['zipi_financial_trust'].includes(paymentGateway!.type) ? '(Trust)' : ''
                            }}</mat-radio-button
                        >
                    </div>
                    <div style="">
                        <mat-radio-button [value]="false">
                            <div class="d-flex justify-content-between align-items-center" style="height: 60px">
                                <div>Select Existing Account</div>
                                <mat-form-field
                                    style="margin-left: 120px"
                                    class="w-50 px-2"
                                    *ngIf="!isDefault"
                                    (click)="$event.stopPropagation()"
                                >
                                    <mat-select [formControl]="accountId!" [disabled]="isDefault">
                                        <mat-option
                                            *ngFor="let account of availableLedgerAccounts"
                                            [value]="account.ledger_account_id"
                                        >
                                            {{ account.name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-hint *ngIf="availableLedgerAccounts.length === 0"
                                        >No Accounts to Connect</mat-hint
                                    >
                                    <mat-error *ngIf="!isDefault && accountId.invalid">Please select one.</mat-error>
                                    <mat-placeholder>Ledger Account</mat-placeholder>
                                </mat-form-field>
                            </div>
                        </mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>
        </div>
        <div class="d-flex justify-content-between w-100">
            <button mat-raised-button class="mr-1" [disabled]="applyDisabled" (click)="close()" tabindex="-1">
                Close
            </button>
            <button mat-raised-button color="primary" [disabled]="applyDisabled" (click)="apply(false)" tabindex="1">
                Apply
            </button>
        </div>
    </div>

    <div class="d-flex mt-3" *ngIf="currentStep === '1'">
        <a *ngIf="redirectToBankManage" [href]="redirectToBankManage" target="_blank" class="d-flex align-items-center">
            <span>Manage Banks in Muhnee</span> <mat-icon>open_in_new</mat-icon>
        </a>
        <span class="sp-spacer"></span>
        <button type="button" mat-raised-button (click)="close()">Cancel</button>
    </div>
</div>
