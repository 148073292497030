<div
    *ngIf="currentGateway && currentGateway.payment_gateway_id && !zipiFinancialGatewayOwnerInfo"
    class="text-muted p-3 text-center"
>
    Loading Data...
</div>

<div *ngIf="currentGateway && !currentGateway.payment_gateway_id" class="p-2">
    <app-zipi-financial-gateway-setup
        *ngIf="currentGateway.driver_type === 'moov'"
        (reloadAndShowFinalPopup)="showFinPopupEvent.emit($event)"
        [existingOperatingGateways]="operatingGateways"
        [driverType]="currentGateway.driver_type"
        [gatewayType]="currentGateway.type"
    >
    </app-zipi-financial-gateway-setup>

    <app-zipi-financial-muhnee-setup
        *ngIf="currentGateway.driver_type === 'muhnee'"
        (reloadAndShowFinalPopup)="showFinPopupEvent.emit($event)"
        [gatewayType]="currentGateway.type"
    >
    </app-zipi-financial-muhnee-setup>

    <app-zipi-financial-tpp-merchant-setup
        *ngIf="currentGateway.driver_type === 'tpp' && currentGateway.type === 'zipi_financial_card_merchant'"
        (reloadAndShowFinalPopup)="showFinPopupEvent.emit($event)"
        [gatewayType]="currentGateway.type"
        [operatingGateways]="operatingGateways"
    >
    </app-zipi-financial-tpp-merchant-setup>

    <app-zipi-financial-tpp-customer-setup
        *ngIf="currentGateway.driver_type === 'tpp' && currentGateway.type === 'zipi_financial_card_customer'"
        (reloadAndShowFinalPopup)="showFinPopupEvent.emit($event)"
        [gatewayType]="currentGateway.type"
        [operatingGateways]="operatingGateways"
    >
    </app-zipi-financial-tpp-customer-setup>

    <app-zipi-financial-payload-setup
        *ngIf="currentGateway.driver_type === 'payload'"
        (reloadAndShowFinalPopup)="showFinPopupEvent.emit($event)"
        [gatewayType]="currentGateway.type"
    >
    </app-zipi-financial-payload-setup>
</div>

<div *ngIf="currentGateway" class="p-2">
    <app-zipi-financial-muhnee-hosted-setup
        *ngIf="
            (currentGateway.driver_type === 'muhnee-hosted' && !currentGateway.payment_gateway_id) ||
            (currentGateway.driver_type === 'muhnee' &&
                currentGateway.status !== 'active' &&
                zipiFinancialGatewayOwnerInfo &&
                (!zipiFinancialGatewayOwnerInfo.is_onboarding_data_setup ||
                    !zipiFinancialGatewayOwnerInfo.is_persona_verified))
        "
        (reloadAndShowFinalPopup)="showFinPopupEvent.emit($event)"
        (setupStarted)="setupStarted.emit($event)"
        [gatewayType]="currentGateway.type"
        [existingOperatingGateways]="operatingGateways"
        [existingGateway]="currentGateway"
        [existingGatewayInfo]="zipiFinancialGatewayOwnerInfo"
    >
    </app-zipi-financial-muhnee-hosted-setup>
</div>

<div
    *ngIf="
        currentGateway &&
        currentGateway.payment_gateway_id &&
        zipiFinancialGatewayOwnerInfo &&
        (currentGateway.driver_type !== 'muhnee' ||
            (zipiFinancialGatewayOwnerInfo.is_onboarding_data_setup &&
                zipiFinancialGatewayOwnerInfo.is_persona_verified) ||
            currentGateway.status === 'active')
    "
    class="p-4"
>
    <mat-card>
        <div class="d-flex justify-content-between p-2">
            <button
                class="mr-2"
                type="button"
                mat-stroked-button
                color="primary"
                matTooltipPosition="above"
                matTooltip="Connect a bank account to your Money Transfer (EFT). Once your account is approved, you can start making transfers."
                *ngIf="
                    activeSection === 'info' &&
                    currentGateway.status !== 'inactive' &&
                    !['zipi_financial_card_merchant', 'zipi_financial_card_customer'].includes(currentGateway.type) &&
                    ((isMuhneeHostedBankAccountConnectionFeatureFlagEnabled &&
                        isMuhneeHostedIntegrationFeatureFlagEnabled) ||
                        (!isMuhneeHostedBankAccountConnectionFeatureFlagEnabled &&
                            !isMuhneeHostedIntegrationFeatureFlagEnabled))
                "
                [disabled]="currentGateway.is_suspended"
                (click)="addPaymentMethod(currentGateway, false, 'bank')"
            >
                <mat-icon>add</mat-icon>
                Connect Bank Account
            </button>
            <button
                class="mr-2"
                type="button"
                mat-stroked-button
                color="primary"
                *ngIf="
                    activeSection === 'info' &&
                    currentGateway.status !== 'inactive' &&
                    currentGateway.type === 'zipi_financial_card_customer'
                "
                [disabled]="currentGateway.is_suspended"
                (click)="addPaymentMethod(currentGateway, false, 'card')"
            >
                <mat-icon>add</mat-icon>
                Add Credit Card
            </button>

            <span class="sp-spacer"></span>
            <mat-button-toggle-group
                appearance="standard"
                [(ngModel)]="activeSection"
                *ngIf="isActivityGroupBasedFeatureFlagEnabled"
            >
                <mat-button-toggle value="log" [disabled]="logButtonDisabled" style="font-weight: bold"
                    >Activity</mat-button-toggle
                >
                <mat-button-toggle value="info" [disabled]="logButtonDisabled" style="font-weight: bold"
                    >Account Info</mat-button-toggle
                >
            </mat-button-toggle-group>
        </div>

        <div [ngStyle]="activeSection === 'log' ? {} : {display: 'none'}">
            <app-zipi-fin-transfers-page
                [gateway]="currentGateway"
                [isVisible]="activeSection === 'log'"
                [isTabChanged]="isTabChanged"
            >
            </app-zipi-fin-transfers-page>
        </div>

        <div [ngStyle]="activeSection === 'info' ? {} : {display: 'none'}" class="p-2">
            <app-zipi-financial-info
                *ngIf="
                    currentGateway.payment_gateway_id &&
                    [
                        'zipi_financial_business',
                        'zipi_financial_trust',
                        'zipi_financial_card_merchant',
                        'zipi_financial_card_customer'
                    ].includes(currentGateway.type)
                "
                [zipiFinancialGateway]="currentGateway"
                [zipiFinancialGatewayOwnerInfo]="zipiFinancialGatewayOwnerInfo"
                (needReload)="reload.emit($event)"
            >
            </app-zipi-financial-info>

            <app-zipi-financial-bank-accounts
                [gateway]="currentGateway"
                (needReload)="reload.emit($event)"
                (cleanFinalPopupInfo)="cleanFinalPopupInfo.emit()"
                (openConnectBankEvent)="addPaymentMethod(currentGateway, false, 'bank')"
                [isFinalPopupDisplay]="showFinalPopup"
            >
            </app-zipi-financial-bank-accounts>

            <div style="margin-top: 24px" *ngIf="currentGateway && currentGateway.payment_gateway_id">
                <button
                    mat-button
                    color="primary"
                    [disabled]="!isGatewayCanBeDeleted && currentGateway.driver_type !== 'muhnee'"
                    (click)="disconnectGateway(currentGateway)"
                >
                    Delete{{ currentGateway.driver_type === 'payload' ? ' Payload.co ' : ' ' }}Account
                </button>
            </div>
        </div>
        <div
            *ngIf="currentGateway && currentGateway.customer_ref"
            class="d-flex"
            style="
                color: lightgray;
                font-weight: bold;
                font-size: 11px;
                position: absolute;
                bottom: 0;
                right: 5px;
                visibility: hidden;
            "
        >
            <span class="sp-spacer"></span>
            <div>{{ currentGateway.customer_ref }}</div>
        </div>
    </mat-card>
    <div class="my-4" *ngIf="currentGateway && currentGateway.driver_type === 'payload'">
        If you need any assistance with your Money Transfer account, call (833) 593-0877 or email
        <a href="mailto: support@payload.co" target="_blank">support&#64;payload.co</a>
    </div>
</div>
