import {Component, Input} from '@angular/core';
import {GenericFormArray} from '../../../../entites/generic.entity';
import {CompensationProfileModel} from '../models/compensation-profile.model';

@Component({
    selector: 'app-company-compensation-profiles-chip-list',
    template: `
        <mat-chip-list style="display: flex; flex-direction: row" *ngIf="compensationProfilesList">
            <ng-container
                *ngFor="
                    let compensationFrom of compensationProfilesList?.controls;
                    index as ci;
                    last as last;
                    first as first
                "
            >
                <mat-chip *ngIf="ci < maxChips + 1 || maxChips === 0">{{
                    compensationFrom?.controls?.title?.value
                }}</mat-chip>
            </ng-container>
        </mat-chip-list>
    `
})
export class CompensationProfilesChipListComponent {
    @Input() maxChips: number = 0;
    @Input() compensationProfilesList: GenericFormArray<CompensationProfileModel> | undefined;
}
