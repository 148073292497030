<section class="container-fluid">
    <header class="my-2">
        <h1 *ngIf="!journalTemplateId" class="">Create Journal Template</h1>
        <h1 *ngIf="journalTemplateId" class="">Edit Journal Template</h1>
    </header>
    <div [formGroup]="formGroup">
        <mat-form-field class="col-6">
            <mat-label>Template Title</mat-label>
            <input type="text" matInput formControlName="title" autocomplete="off" />
        </mat-form-field>

        <mat-divider></mat-divider>

        <app-journal-template
            (onUpdate)="onTemplateUpdate($event)"
            [journalTemplate]="journalTemplateBase"
            [closeEvent]="closeEvent"
        ></app-journal-template>

        <footer class="text-right my-2">
            <button
                type="submit"
                mat-raised-button
                color="warn"
                *ngIf="journalTemplateId"
                (click)="deleteJournalTemplate()"
            >
                Delete
            </button>

            <span class="sp-spacer"></span>

            <button mat-raised-button class="ml-2" (click)="router.navigate(['/company/finance/journals'])">
                Cancel
            </button>

            <button
                type="!submit"
                class="ml-2"
                mat-raised-button
                color="primary"
                *ngIf="!journalTemplateId"
                [disabled]="!formGroup.valid || !maybeValidJournal.valid"
                (click)="createJournalTemplate()"
            >
                Create
            </button>
            <button
                type="submit"
                class="ml-2"
                mat-raised-button
                color="primary"
                *ngIf="journalTemplateId"
                [disabled]="!formGroup.valid || !maybeValidJournal.valid"
                (click)="updateJournalTemplate()"
            >
                Save
            </button>
        </footer>
    </div>
</section>

<ng-content></ng-content>
