import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {IRole} from '@cyberco-nodejs/zipi-typings/index';

interface IDialogData {
    label: string;
    availableCompanyPermissions: any;
    availableRolesPermissions: any;
}

@Component({
    selector: 'app-permission-preview-dialog',
    template: `
        <h1 mat-dialog-title>{{ label }} Permission Preview</h1>
        <div mat-dialog-content>
            <div *ngIf="availableCompanyFeaturePermissions">
                <div *ngFor="let moduleName of ObjectClass.keys(availableCompanyFeaturePermissions); let i = index">
                    <app-company-role-feature
                        [module]="availableCompanyFeaturePermissions[moduleName]"
                        [availableRolesPermissions]="availableRolesPermissions"
                        [viewMode]="true"
                    ></app-company-role-feature>
                </div>
            </div>
        </div>
        <div mat-dialog-actions class="d-flex justify-content-end align-items-baseline">
            <button class="ml-2" (click)="dialogRef.close()" mat-raised-button>Ok</button>
        </div>
    `,
    styles: [
        `
            mat-dialog-actions {
                display: flex;
                justify-content: space-around;
            }
        `
    ]
})
export class PermissionPreviewDialogComponent implements OnInit, OnDestroy {
    public ObjectClass = Object;

    availableCompanyPermissions: any = {};
    availableRolesPermissions: any = [];
    label: string = 'Roles';
    availableCompanyFeaturePermissions: {[key: string]: any} = {};
    companyRoleForm: GenericFormGroup<IRole> | undefined;

    constructor(
        public dialogRef: MatDialogRef<PermissionPreviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: IDialogData
    ) {
        this.availableCompanyPermissions = this.data.availableCompanyPermissions;
        this.label = this.data.label;
        this.availableRolesPermissions = this.data.availableRolesPermissions;
    }
    ngOnInit() {
        this.availableCompanyFeaturePermissions = this.permissionsCategorise(this.availableCompanyPermissions);
    }

    permissionsCategorise(permissionsRawArray: any, modules: string[] = []): {[key: string]: any} {
        const permObj = {};

        for (const key in permissionsRawArray) {
            if (!permissionsRawArray.hasOwnProperty(key)) {
                continue;
            }

            if (modules.length > 0) {
                if (!modules.includes(key)) {
                    continue;
                }
            }
            // @ts-ignore
            permObj[key] = permissionsRawArray[key];
        }

        return permObj;
    }

    ngOnDestroy() {}
}
