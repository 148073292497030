import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UiModsSource} from '../ui-mods.source';
import {UiModsDealBadgeInListModel, UI_MOD_STATE} from '../models/ui-mods.model';
import {UiModsApi} from '../api/ui-mods.api';
import {MatMenuPanel} from '@angular/material/menu';
import {MatDialog} from '@angular/material/dialog';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {YodataReportDialogComponent} from '../../deals/components/deal/common/yodata-report-dialog/yodata-report-dialog.component';
import {MarketplaceApi} from '../../account-info/marketplace/marketplace.api';
import {NotificationsService} from 'angular2-notifications';

@Component({
    selector: 'app-ui-mod-deal-badge-in-list',
    styles: [
        `
            .ui-mod-deal-badge-container {
                padding: 3px 0;
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                align-items: flex-end;
            }
            .ui-mod-deal-badge-container--dir-row {
                flex-direction: row;
            }
            .ui-mod-deal-badge-box {
                display: flex;
                align-items: center;
                margin-right: 3px;
                margin-bottom: 2px;
                cursor: pointer;
            }
            .ui-mod-deal-badge-box--normal {
            }
            .ui-mod-deal-badge-box--warning {
                background: #ffc107 !important;
            }
            .ui-mod-deal-badge-box--error {
                background: red !important;
                color: #fff;
            }

            .ui-mod-deal-badge-box--labeled {
                background: #eaeef2;
                /*padding: 3px 2px;*/
                border-radius: 10px;
            }
            .ui-mod-deal-badge-label {
                font-size: 12px;
                padding: 0 6px;
            }
            .ui-mod-deal-badge {
                height: 20px;
                width: 20px;
            }
            .badge-left-side {
                display: flex;
                background: #fff;
                border-radius: 20px;
                border: 2px solid #fff;
                margin: -1px;
            }
            .badge-right-side {
                display: flex;
                padding: 2px 0;
            }
        `
    ],
    template: `
        <ng-container *ngIf="badge && badge.on_click && badge.on_click.action === 'dropdown_menu'">
            <!--if badge disabled - no matMenuTriggerFor-->
            <div
                *ngIf="badge.disabled"
                (click)="getMenuOptions($event, badge)"
                [matTooltip]="badge.hint || ''"
                [ngClass]="!!badge.label ? 'ui-mod-deal-badge-box--labeled' : ''"
                [class]="'ui-mod-deal-badge-box ' + (badge.state ? uiModStateToCssClass[badge.state] : '')"
            >
                <div class="badge-left-side">
                    <button
                        type="button"
                        mat-icon-button
                        class="ui-mod-deal-badge"
                        [ngStyle]="badge.showSpinner ? null : badge.style"
                    >
                        <mat-spinner [diameter]="20" *ngIf="badge.showSpinner"></mat-spinner>
                    </button>
                </div>
                <!--[matMenuTriggerFor]="appMenu">-->
                <div class="badge-right-side">
                    <span *ngIf="!!badge.label" class="ui-mod-deal-badge-label">
                        {{ badge.label }}
                    </span>
                </div>
            </div>

            <!--else if badge not disabled - show matMenuTriggerFor-->
            <div
                *ngIf="!badge.disabled"
                [matTooltip]="badge.hint || ''"
                [ngClass]="!!badge.label ? 'ui-mod-deal-badge-box--labeled' : ''"
                style="cursor:pointer;"
                [matMenuTriggerFor]="appMenu"
                [class]="'ui-mod-deal-badge-box ' + (badge.state ? uiModStateToCssClass[badge.state] : '')"
            >
                <mat-menu #appMenu="matMenu" backdropClass="voidedMenu" panelClass="voidedMenu">
                    <span *ngFor="let menu_option of badge.on_click.dropdownMenu.menu_options">
                        <button
                            *ngIf="menu_option.action !== 'void'"
                            type="button"
                            mat-menu-item
                            (click)="menuClick(menu_option, badge)"
                        >
                            {{ menu_option.label }}
                        </button>
                        <dutton *ngIf="menu_option.action === 'void'" mat-menu-item>
                            {{ menu_option.label }}
                        </dutton>
                    </span>
                </mat-menu>
                <div class="badge-left-side">
                    <button type="button" mat-icon-button class="ui-mod-deal-badge" [ngStyle]="badge.style"></button>
                </div>
                <!--[matMenuTriggerFor]="appMenu">-->
                <div class="badge-right-side">
                    <span *ngIf="!!badge.label" class="ui-mod-deal-badge-label">
                        {{ badge.label }}
                    </span>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="badge && badge.on_click && badge.on_click.action !== 'dropdown_menu'">
            <div
                [ngClass]="!!badge.label ? 'ui-mod-deal-badge-box--labeled' : ''"
                [class]="'ui-mod-deal-badge-box ' + (badge.state ? uiModStateToCssClass[badge.state] : '')"
            >
                <div class="badge-left-side">
                    <button
                        type="button"
                        mat-icon-button
                        class="ui-mod-deal-badge"
                        [matTooltip]="badge.hint || ''"
                        [ngStyle]="badge.style"
                        (click)="click(badge)"
                    ></button>
                </div>
                <div class="badge-right-side">
                    <span *ngIf="!!badge.label" class="ui-mod-deal-badge-label">
                        {{ badge.label }}
                    </span>
                </div>
            </div>
        </ng-container>
    `
})
export class DealBadgeInListComponent implements OnDestroy, OnInit {
    private unsubscribe: Subject<void> = new Subject();
    @Input() dealId: number | null = null;
    @Input() badge: UiModsDealBadgeInListModel | null = null;
    public appMenu: MatMenuPanel | undefined = undefined; // workaround for TSLint;
    public getMenuOptionsPending: boolean = false;
    public uiModStateToCssClass: {[key: string]: string} = {
        [UI_MOD_STATE.normal]: 'ui-mod-deal-badge-box ui-mod-deal-badge-box--normal',
        [UI_MOD_STATE.error]: 'ui-mod-deal-badge-box ui-mod-deal-badge-box--error',
        [UI_MOD_STATE.warning]: 'ui-mod-deal-badge-box ui-mod-deal-badge-box--warning'
    };

    constructor(
        public dialog: MatDialog,
        public uiModsSource: UiModsSource,
        public uiModsApi: UiModsApi,
        public marketplaceApi: MarketplaceApi,
        public changeDetector: ChangeDetectorRef,
        protected ntfs: NotificationsService
    ) {}

    ngOnInit() {}

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    // on 'Deals List' page all DealBadgesInList (Skyslope/Dotloop) disabled by default (we do not check permissions if a user can manage the deal)
    // to enable this badge we need to send request to backend.
    // This request will check if user has permission to manage this badge (and deal)
    // If the user has permission - open dropdown menu
    // If the user has NO permission - show tooltip/message - "To Access Options 'Edit' Deal Permission is Required."
    // on request pending we need to show spinner in the badge
    getMenuOptions(ev: MouseEvent, badge: UiModsDealBadgeInListModel) {
        if (badge.isPermissionChecked || this.getMenuOptionsPending) {
            return;
        }

        this.getMenuOptionsPending = true;
        badge.showSpinner = true; // used to show spinner in the badge

        this.uiModsApi
            .loadUiModsByDeal(Number(this.dealId))
            .then((res) => {
                badge.showSpinner = false;
                this.getMenuOptionsPending = false;

                if (res && res.length) {
                    this.uiModsSource.updateDealBadgesInList(
                        res.map((badge) => this.uiModsSource.buildUiModDealBadgeInList(badge, false))
                    );
                }
            })
            .catch(() => {
                badge.showSpinner = false;
                this.getMenuOptionsPending = false;
            });
    }

    click(badge: UiModsDealBadgeInListModel) {
        if (badge.clickable && badge.ui_mod_instance_id) {
            this.uiModsApi.uiModClicked(badge.ui_mod_instance_id);
        }
    }

    menuClick(menu_option: any, badge: UiModsDealBadgeInListModel) {
        if (badge.clickable && badge.uiMod && badge.ui_mod_instance_id && this.dealId) {
            const redirectParams: {[key: string]: number} = {
                deal_id: this.dealId as number
            };
            switch (menu_option.on_click.action) {
                case 'open_new_tab':
                    const paramsTab = Object.keys(redirectParams)
                        .map((key: string) => key + '=' + encodeURIComponent(String(redirectParams[key])))
                        .join('&');
                    window.open(badge.uiMod.addon.addon_url + menu_option.on_click.path + '?' + paramsTab);

                    break;
                case 'redirect_user':
                    const paramsUser = Object.keys(redirectParams)
                        .map((key) => key + '=' + encodeURIComponent(String(redirectParams[key])))
                        .join('&');
                    window.location.href = badge.uiMod.addon.addon_url + menu_option.on_click.path + '?' + paramsUser;

                    break;
                case 'request_from_backend':
                    this.uiModsApi.uiModOptionClicked(badge.ui_mod_instance_id, menu_option.on_click);
                    break;
                case 'inner_request':
                    return this.uiModsApi
                        .uiModsInnerRequest(badge.ui_mod_instance_id, menu_option.on_click.path, this.dealId)
                        .then((res) => {
                            const dialogRef = this.dialog.open(YodataReportDialogComponent, {
                                minWidth: 320,
                                data: res
                            });

                            dialogRef
                                .afterClosed()
                                .pipe(
                                    filter((pn) => !!pn),
                                    takeUntil(this.unsubscribe)
                                )
                                .subscribe((data: {formValues: Object}) => {
                                    if (data && this.dealId && badge.ui_mod_instance_id) {
                                        // console.info(data);
                                        this.marketplaceApi
                                            .pushYodataReport(
                                                this.dealId,
                                                {report: data.formValues},
                                                badge.ui_mod_instance_id
                                            )
                                            .then((resp: {sendReportResponse: any; hint: string}) => {
                                                this.ntfs.success(resp.hint);
                                            });
                                    }
                                });
                        });
                case 'void':
                    break;
                default:
                    throw new Error('Unknown action');
            }
        }
    }
}
