export class AddonModel {
    id: number | null = null;
    addon_url: string | null = null;
    preset: AddonInstanceModel | null = null;
    settings: any;
    slug: string | null = null;
}

export class AddonInstanceModel {
    id: number | null = null;
    logo: string | null = null;
    title: string | null = null;
    description: string | null = null;
    activated: boolean | null = null;
    addon: AddonModel | null = null;
    is_featured: boolean | null = null;
    profile_fk_id: number | null = null;
}
