<section class="sidebar-body">
    <!------- Table of Recurring Journals ------->
    <div class="table-background" style="height: calc(100vh - 180px)">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="recurrence_title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20">Title</th>
                <td mat-cell *matCellDef="let element" class="w-20">
                    {{ element.recurrence_title }}
                </td>
            </ng-container>

            <ng-container matColumnDef="frequency">
                <th mat-header-cell *matHeaderCellDef class="w-15">Frequency</th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1">
                    <span *ngIf="element.repeat?.period === 'custom'">
                        Every {{ element?.repeat?.frequency === 1 ? 'day' : element.repeat?.frequency + ' days' }}
                    </span>
                    <span *ngIf="element.repeat.period !== 'custom'">
                        {{ element?.repeat?.frequency === 1 ? '' : element.repeat.frequency }}
                        {{
                            element?.repeat?.frequency === 1
                                ? (element.repeat?.period | fromSnakeCase)
                                : (element.repeat?.period + 's' | fromSnakeCase)
                        }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="last_journal_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">Last Journal</th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1">
                    {{
                        element.last_journal_date
                            ? (element.last_journal_date | dateFromNumber | date: 'mediumDate')
                            : 'N/A'
                    }}
                </td>
            </ng-container>

            <ng-container matColumnDef="next_journal_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">Next Journal</th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1">
                    {{ element.next_journal_date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">Status</th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1">
                    {{ element.status | fromSnakeCase }}
                </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">Amount</th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1 text-right">
                    <ng-container *ngIf="element.journal_template; else noTotal">
                        {{ element.journal_template?.line_item_total | currency }}
                    </ng-container>
                    <ng-template #noTotal> - </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="w-5">Action</th>
                <td mat-cell *matCellDef="let element" class="w-5">
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                        *ngIf="element.status !== 'expired'"
                        (click)="$event.stopPropagation()"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button
                            mat-menu-item
                            *ngIf="element.status !== 'active'"
                            (click)="activateRecurring(element.recurring_journal_id)"
                        >
                            Activate
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="element.status !== 'stopped'"
                            (click)="stopRecurring(element.recurring_journal_id)"
                        >
                            Stop
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [routerLink]="['/company/finance/recurringjournals', row.id]"
                class="zp-table-row"
            ></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 text-center no-items">
            There are no Recurring Journals.
        </div>
    </div>
</section>
