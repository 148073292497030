<div mat-dialog-title>
    <h3>Charge Customer</h3>
</div>

<mat-dialog-content>
    <div class="d-flex">
        <mat-form-field class="w-100 mr-2">
            <mat-select [(ngModel)]="data.invoice.to_be_paid__payment_method_fk_id">
                <ng-container *ngFor="let gateway of data.gateways">
                    <div *ngFor="let method of gateway.payment_methods">
                        <mat-option *ngIf="isAbleToDisplay(method)" [value]="method.id">
                            {{ method?.title }}
                        </mat-option>
                    </div>
                </ng-container>
            </mat-select>
            <mat-placeholder>Credit Cards</mat-placeholder>
        </mat-form-field>
    </div>

    <div class="py-3">
        <mat-form-field class="w-100">
            <textarea
                matInput
                type="text"
                [(ngModel)]="data.invoice.invoice_note"
                placeholder="Invoice Note (optional)"
            >
            </textarea>
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="mb-2">
    <button mat-raised-button color="accent" (click)="dialogRef.close(data.invoice)" tabindex="1">Continue</button>
    <button mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">Cancel</button>
</mat-dialog-actions>
