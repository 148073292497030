<div>
    <ng-container *ngIf="reportData && reportData.groups">
        <mat-card class="preview-body" *ngFor="let group of reportData.groups">
            <div class="tables">
                <div class="table-wrapper">
                    <h2 class="table-header sticky">{{ group.title }}</h2>
                    <table mat-table [dataSource]="group.data" class="w-100">
                        <ng-container [matColumnDef]="column.value" *ngFor="let column of tableStructure">
                            <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                            <td mat-cell *matCellDef="let element" class="td-cell">
                                {{ element[column.value] | reportFormatted: column.filter }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="averageName">
                            <td mat-footer-cell *matFooterCellDef colspan="3" class="table-footer-row-title">
                                Average:
                            </td>
                        </ng-container>

                        <ng-container [matColumnDef]="column.value" *ngFor="let column of footerAverageColumns">
                            <td mat-footer-cell *matFooterCellDef>
                                {{ getAverage(column.value, group.average) | reportFormatted: column.filter }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="totalsName">
                            <td mat-footer-cell *matFooterCellDef colspan="3" class="table-footer-row-title">
                                Totals:
                            </td>
                        </ng-container>

                        <ng-container [matColumnDef]="column.value" *ngFor="let column of footerTotalColumns">
                            <td mat-footer-cell *matFooterCellDef>
                                {{ getTotalCost(column.value, group.total) | reportFormatted: column.filter }}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                        <tr
                            mat-footer-row
                            *matFooterRowDef="['averageName'].concat(footerAverageColumnsToDisplay)"
                        ></tr>
                        <tr mat-footer-row *matFooterRowDef="['totalsName'].concat(footerTotalColumnsToDisplay)"></tr>
                    </table>
                </div>
            </div>
        </mat-card>
    </ng-container>

    <ng-container *ngIf="overallTable">
        <mat-card class="preview-body" *ngIf="overallTable.length">
            <div class="tables">
                <div class="table-wrapper">
                    <h2 class="table-header">OVERALL TOTALS</h2>
                    <table mat-table [dataSource]="overallTable" class="w-100">
                        <ng-container [matColumnDef]="column.value" *ngFor="let column of overallStructure">
                            <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element[column.value] | reportFormatted: column.filter }}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="overallColumnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let row; columns: overallColumnsToDisplay"></tr>
                    </table>
                </div>
            </div>
        </mat-card>
    </ng-container>

    <mat-card class="preview-body" *ngIf="!reportData || !reportData.groups || !reportData.groups.length">
        <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
    </mat-card>
</div>
