import {Injectable} from '@angular/core';
import {ServiceRequester} from '../service.requester';
import {Group} from '../../models/group';
import {Company} from '../../models/company';
import {Profile} from '../../models/profile';
import {Observable} from 'rxjs';
import {IContact} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class GroupApi {
    private url = '/group/';

    constructor(public requester: ServiceRequester) {}

    get(id?: number, data = {}) {
        let url = this.url;
        if (id) {
            url += id;
        }

        return this.requester.makeMsCall(url);
    }

    create(data: any) {
        return this.requester.makeMsCall(this.url, 'POST', data);
    }

    clone(data: any) {
        return this.requester.makeMsCall('/groups/' + data.parent.id + '/clone', 'POST', data);
    }

    createBusinessCompanyForGroup(groupId: number) {
        return this.requester.makeMsCall(this.url + groupId + '/business', 'POST');
    }

    updateSettings(id: number, data: any) {
        return this.requester.makeMsCall(this.url + id + '/settings', 'PUT', data);
    }

    updateMembers(id: number, group: Group, membersToDelete: number[]) {
        return this.requester.makeMsCall(this.url + id + '/members', 'PUT', {
            groupData: group,
            membersToDelete: membersToDelete
        });
    }

    deleteGroup(groupId: number) {
        return this.requester.makeMsCall(this.url + groupId, 'DELETE');
    }

    /**
     * Deprecated
     */
    getForCompany(companyId: number) {
        return this.requester.makeMsCallGlobalAllowed(this.url + 'company/' + companyId).then((loaded) =>
            loaded
                .map((company_group: Group) => Object.assign(new Group(), company_group))
                .map((company_group: Group) => {
                    const members = company_group.members.map((member) => {
                        const profile = Object.assign(new Profile(), member.profile);
                        member.profile = profile;
                        return member;
                    });
                    company_group.members = members;
                    return company_group;
                })
        );
    }

    getDivisionsForMember(companyId: number, profileId: number, selectedDivision: number) {
        return this.requester.makeMsCall(this.url + 'company/' + companyId + '/divisions', 'GET', {
            member: profileId,
            selected_division: selectedDivision ? selectedDivision : ''
        });
    }

    getGroupInfoForCompany(company: Company) {
        return this.requester.makeMsCallGlobalAllowed(this.url + 'groupInfo/company/' + company.id);
    }

    getMemberPageForGroup(data: any) {
        return this.requester.makeMsCall(this.url + 'member-page', 'POST', data);
    }

    patchGroupsWithMember(profileId: number, groups: any[]) {
        return this.requester.makeMsCall(`/groups/member/${profileId}`, 'POST', groups);
    }

    async findGroupsByName(
        companyId: number,
        searchData: {limit?: number; offset?: number; searchStr: string},
        useDivision: boolean = false
    ): Promise<Profile[]> {
        let url = '';
        if (searchData.limit) {
            url = `${this.url}company/${companyId}/search?limit=${searchData.limit}&offset=${searchData.offset}&searchStr=${searchData.searchStr}&useDivision=${useDivision ? '1' : '0'}`;
        } else {
            url = `${this.url}company/${companyId}/search?searchStr=${searchData.searchStr}&useDivision=${useDivision ? '1' : '0'}`;
        }

        return await this.requester.makeMsCall(url, 'GET', {}, {}, true);
    }

    findGroupMembersByName(groupId: number, searchData: string): Observable<{result: IContact[]}> {
        return this.requester.makeMsCall$(this.url + groupId + '/search', 'POST', 'zipi', {searchData}, {}, true);
    }

    findGroupMembersByQuery(
        groupId: number,
        recurringInvoiceId: number,
        searchData: {[key: string]: any}
    ): Observable<{result: IContact[]}> {
        return this.requester.makeMsCall$(
            `${this.url}${groupId}/recurring/${recurringInvoiceId}/search/query`,
            'GET',
            'zipi',
            searchData,
            {},
            true
        );
    }

    getGroupsByIds(groupIds: number[], isContactNeeded: number = 0): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}get-by-ids`, 'GET', 'zipi', {ids: groupIds, isContactNeeded});
    }

    bulkAddProfilesToGroup(groupId: number, data: any): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}${groupId}/bulk-add-profiles`, 'POST', 'zipi', data);
    }
}
