import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IVendorCredit} from '@cyberco-nodejs/zipi-typings';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CREDITS_STATUS_COLOR, PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {MatOptionSelectionChange} from '@angular/material/core';
import {UntypedFormControl} from '@angular/forms';
import {VendorCreditsService} from 'app/services/api/finance/vendor-credits.service';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {IScrollData} from 'app/models/scroll-data';
import {MatTableDataSource} from '@angular/material/table';
import {Sort} from '@angular/material/sort';
import {NotificationsService} from 'angular2-notifications';
import {FeatureFlagsService} from '@app/modules/feature-flags/feature-flags.service';
import {SelectionModel} from '@angular/cdk/collections';

type statusFilterTypes = Array<'all' | 'open' | 'closed' | 'applied' | 'void' | 'draft'>;
@Component({
    selector: 'app-vendor-credits',
    templateUrl: 'vendor-credits.component.html',
    styleUrls: ['vendor-credits.component.scss', '../../../../../assets/infinite-scroll-table.scss']
})
export class VendorCreditsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator = new MatPaginator(
        new MatPaginatorIntl(),
        ChangeDetectorRef.prototype
    );

    statusColor = CREDITS_STATUS_COLOR;
    statusTypes: statusFilterTypes = ['all', 'open', 'closed', 'applied', 'void', 'draft'];
    statusFilter: UntypedFormControl = new UntypedFormControl([]);

    selection: SelectionModel<number> = new SelectionModel<number>(true, []);
    isAllSelected: boolean = false;
    isMasterToggleChecked: boolean = false;

    scrollData: IScrollData = {
        sort_column: 'vendor_credit_date',
        sort_direction: 'desc',
        filter: null,
        limit: 50,
        offset: 0,
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<IVendorCredit>;
    displayedColumns = [
        'checkbox',
        'vendor_credit_date',
        'vendor_name',
        'vendor_credit_number',
        'reference',
        'status',
        'total_amount',
        'action'
    ];

    creditsMenuOpened: boolean = false;

    // Feature flags
    isPayStubPrintAvailable: boolean = false;

    constructor(
        private vendorCreditsService: VendorCreditsService,
        private ntfs: NotificationsService,
        protected featureFlagsService: FeatureFlagsService
    ) {
        this.dataSource = new MatTableDataSource<IVendorCredit>([]);
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.isPayStubPrintAvailable = this.featureFlagsService.isFeatureEnabled(
                    'purchases:vendor_credit_pay_stub'
                );
            });
    }

    ngOnInit() {
        this.getVendorCredits();

        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.getVendorCredits();
            });
        }
    }

    getVendorCredits() {
        // cancel previous batch requests
        this.unsubscribeBatch.next();

        if (this.statusFilter.value && !this.statusFilter.value.includes('all')) {
            this.scrollData.filter = this.statusFilter.value;
        } else {
            this.scrollData.filter = '';
        }

        this.vendorCreditsService
            .getVendorCredits(this.scrollData)
            .pipe(takeUntil(this.unsubscribeBatch))
            .subscribe((data) => {
                this.dataSource.data = data.result;
                this.scrollData.total = data._meta.total;
            });
    }

    changeStatusFilter(event: MatOptionSelectionChange) {
        if (event.isUserInput) {
            const {value, selected} = event.source;
            let statusFilter: statusFilterTypes = this.statusFilter.value;

            if (value === 'all') {
                statusFilter = selected ? this.statusTypes : [];
            } else if (value !== 'all') {
                if (statusFilter.includes('all') && !selected) {
                    statusFilter = statusFilter.filter((f) => f !== 'all' && f !== value);
                } else {
                    statusFilter = this.statusTypes.filter(
                        (f) => (f !== value && statusFilter.includes(f)) || (f === value && selected)
                    );
                }
            }

            // update values
            this.paginator!.pageIndex = 0;
            this.scrollData.offset = 0;
            this.scrollData.total = 0;
            this.statusFilter.setValue(statusFilter);
            this.getVendorCredits();
        }
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        // update values
        this.paginator!.pageIndex = 0;
        this.scrollData.offset = 0;
        this.getVendorCredits();
    }

    toggleBulkCheckbox($event: any, id: number) {
        if ($event) {
            this.selection.toggle(id as never);
            this.isAllSelected = false;
            this.isMasterToggleChecked = false;
        }
    }

    selectAll() {
        this.isAllSelected = true;
        this.isMasterToggleChecked = true;
        this.dataSource.data.forEach((row) => {
            if (row.vendor_credit_id && !this.selection.selected.includes(row.vendor_credit_id as never)) {
                this.selection.select(row.vendor_credit_id as never);
            }
        });
    }

    clearSelection() {
        this.isAllSelected = false;
        this.isMasterToggleChecked = false;
        this.selection.clear();
    }

    printPayStub(vendorCredit: IVendorCredit) {
        if (vendorCredit && vendorCredit.vendor_credit_id) {
            this.vendorCreditsService
                .createPayStub(vendorCredit.vendor_credit_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response.result && response.result.error) {
                        this.ntfs.error(response.result.error);
                        return;
                    }

                    const blob = new Blob([new Uint8Array(response.result.data)], {type: 'application/pdf'});
                    const blobUrl = URL.createObjectURL(blob);
                    const iframe = document.createElement('iframe');
                    iframe.id = 'print-frame';

                    iframe.style.display = 'none';
                    iframe.src = blobUrl;
                    iframe.onload = () => {
                        setTimeout(() => {
                            if (iframe.contentWindow) {
                                iframe.contentWindow.print();
                            }
                        }, 0);
                    };
                    document.body.appendChild(iframe);
                });
        }
    }

    downloadPayStub() {
        const vendorCreditIds = this.selection.selected;
        if (vendorCreditIds.length > 0) {
            this.vendorCreditsService
                .orderPayStubGeneration(vendorCreditIds)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response) {
                        this.selection.clear();
                        this.ntfs.success(
                            `PayStub${vendorCreditIds.length > 1 ? 's' : ''} created.`,
                            ` It can be found and opened in the top notifications bell when complete.`
                        );
                    }
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
    }
}
