import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FocusAreaEntity} from '../../progress/entites/focus-area.entity';
import {tinMaskitoOptions} from '../../../utilities/maskito';

type IntersectedFormControl = UntypedFormArray & UntypedFormControl & UntypedFormGroup;

export class CustomFormArray<C extends AbstractControl> extends UntypedFormArray {
    controls: C[] = [];
}

export class CustomFormGroup<M> extends UntypedFormGroup {
    // @ts-ignore
    controls: {[key in keyof M]?: IntersectedFormControl};

    getRawValue(): M {
        const resp = super.getRawValue(); // M
        Object.keys(resp).forEach((key) => {
            if (Array.isArray(resp[key])) {
                resp[key] = resp[key].filter((item: any) => item);
            }
        });
        return resp;
    }

    patchValue(value: {[key: string]: any}) {
        super.patchValue(value);
    }
}

export class CompanySetupFocusAreaFormGroup extends CustomFormGroup<FocusAreaEntity> {
    constructor() {
        super({
            id: new UntypedFormControl(''),
            title: new UntypedFormControl('')
        });
    }
}

export class CompanySetupFormModel {
    name?: string;
    vision?: string;
    company_values?: string[];
    industry?: string;
    is_external_groups_allowed?: boolean;
    focus_areas?: FocusAreaEntity[];
    submit?: boolean;
    tin?: string | null;
}

export class CompanySetupFormGroup extends CustomFormGroup<CompanySetupFormModel> {
    // sub : Subscription;
    applyEvents() {
        if (typeof this.controls === 'undefined') {
            return;
        }
        this.controls.company_values?.valueChanges.subscribe((what) => {
            if (what[what.length - 1]) {
                this.controls.company_values?.push(new UntypedFormControl());
            } else if (!what[what.length - 2] && what.length > 1) {
                this.controls.company_values?.removeAt(-1);
            }
        });
        this.controls.focus_areas?.valueChanges.subscribe((what) => {
            if (what[what.length - 1].title) {
                this.controls.focus_areas?.push(new CompanySetupFocusAreaFormGroup());
            } else if (what[what.length - 2] && !what[what.length - 2].title && what.length > 1) {
                this.controls.focus_areas?.removeAt(-1);
            }
        });
    }

    constructor() {
        const controls = {
            name: new UntypedFormControl(''),
            vision: new UntypedFormControl(''),
            company_values: new UntypedFormArray([]),
            industry: new UntypedFormControl('real_estate'),
            is_external_groups_allowed: new UntypedFormControl(true),
            focus_areas: new UntypedFormArray([]),
            submit: new UntypedFormControl(false, Validators.requiredTrue),
            tin: new UntypedFormControl('')
        };
        super(controls);
        this.applyEvents();
    }

    getRawValue(): CompanySetupFormModel {
        const resp = <CompanySetupFormModel>super.getRawValue();
        return resp;
    }

    patchValue(value: CompanySetupFormModel) {
        if (value.company_values && Array.isArray(value.company_values)) {
            this.controls.company_values = <IntersectedFormControl>(
                new UntypedFormArray(value.company_values.map((item) => new UntypedFormControl(item)))
            );
            this.controls.company_values.push(new UntypedFormControl(''));
        }

        if (value.focus_areas && Array.isArray(value.focus_areas)) {
            this.controls.focus_areas = <IntersectedFormControl>new UntypedFormArray(
                value.focus_areas.map((item, i) => {
                    const resp = new CompanySetupFocusAreaFormGroup();
                    resp.patchValue(item);
                    if (i === 0) {
                        resp.disable({emitEvent: false});
                    }
                    return resp;
                })
            );
            this.controls.focus_areas.push(new CompanySetupFocusAreaFormGroup());
        }
        this.applyEvents();
        super.patchValue(value);
    }
}

@Component({
    selector: 'app-company-setup',
    templateUrl: 'company-setup.component.html'
})
export class CompanySetupComponent {
    public tinInputMask = tinMaskitoOptions;

    @ViewChild('tinInput') tinInput: ElementRef | undefined = undefined;

    @Input() formGroup: CompanySetupFormGroup | undefined = undefined;

    onBlurMethod() {
        if (typeof this.formGroup === 'undefined') {
            return;
        }

        const numbers = this.formGroup.controls.tin?.value;
        if (numbers && numbers.replace(/[^0-9]/g, '') && numbers.replace(/[^0-9]/g, '').length < 9) {
            this.formGroup.get('tin')?.setErrors({incorrect: true});
        }
    }

    onClickMethod() {
        if (typeof this.tinInput === 'undefined') {
            return;
        }

        let length = this.tinInput.nativeElement.value.replace(/[^0-9]/g, '').length;
        if (length >= 3) {
            length++;
        }

        this.tinInput.nativeElement.selectionStart = length;
        this.tinInput.nativeElement.selectionEnd = length;
    }
}
