<div>
    <mat-card>
        <table class="report-table w-100">
            <thead>
                <tr class="table-header">
                    <th class="sticky">Account</th>
                    <th class="item-value sticky">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><strong>Beginning Cash Balance</strong></td>
                    <td>
                        <strong>{{ reportData.start_balance | reportFormatted: 'financial' }}</strong>
                    </td>
                </tr>
                <tr class="no-line">
                    <td colspan="2"><br /></td>
                </tr>
                <tr>
                    <td colspan="2"><i>Cash flow from operating Activities</i></td>
                </tr>

                <!-- Operating -->
                <tr *ngFor="let item of reportData.operating">
                    <td>{{ item.la_name }}</td>
                    <td>{{ item.total | reportFormatted: 'financial' }}</td>
                </tr>
                <tr>
                    <td><strong>Net cash provided by Operating Activities</strong></td>
                    <td>
                        <strong>{{ reportData.totals.operating | reportFormatted: 'financial' }}</strong>
                    </td>
                </tr>
                <tr class="no-line">
                    <td colspan="2"><br /></td>
                </tr>
                <!-- End Operating -->

                <!-- Investing -->
                <tr>
                    <td colspan="2"><i>Cash Flow from Investing Activities</i></td>
                </tr>
                <tr *ngFor="let item of reportData.investing">
                    <td>{{ item.la_name }}</td>
                    <td>{{ item.total | reportFormatted: 'financial' }}</td>
                </tr>

                <tr>
                    <td><strong>Net cash provided by Investing Activities</strong></td>
                    <td>
                        <strong>{{ reportData.totals.investing | reportFormatted: 'financial' }}</strong>
                    </td>
                </tr>
                <tr class="no-line">
                    <td colspan="2"><br /></td>
                </tr>
                <!-- End Investing -->

                <!-- Financing -->
                <tr>
                    <td colspan="2"><i>Cash Flow from Financing Activities</i></td>
                </tr>
                <tr *ngFor="let item of reportData.financing">
                    <td>{{ item.la_name }}</td>
                    <td>{{ item.total | reportFormatted: 'financial' }}</td>
                </tr>
                <tr>
                    <td><strong>Net cash provided by Financing Activities</strong></td>
                    <td>
                        <strong>{{ reportData.totals.financing | reportFormatted: 'financial' }}</strong>
                    </td>
                </tr>
                <tr class="no-line">
                    <td colspan="2"><br /></td>
                </tr>
                <!-- End Financing -->

                <tr>
                    <td><strong>Net Change in cash</strong></td>
                    <td>
                        <strong>{{ reportData.totals.net_change | reportFormatted: 'financial' }}</strong>
                    </td>
                </tr>
                <tr class="no-line">
                    <td colspan="2"><br /></td>
                </tr>

                <tr>
                    <td><strong>Ending Cash Balance</strong></td>
                    <td>
                        <strong>{{ reportData.totals.end_balance | reportFormatted: 'financial' }}</strong>
                    </td>
                </tr>
            </tbody>
        </table>

        <p class="m-2">*Amount is displayed in your base currency <span class="badge-secondary">USD</span></p>
        <p class="m-2" *ngIf="report && report.settings && report.settings.report_basis === 'cash'">
            **Financial Data Presented is on a Cash Basis
        </p>
    </mat-card>
</div>
