import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {IAccountingLog, ILedgerAccount, IPayment, IPaymentReceived} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {PaymentsReceivedService} from '../../../services/payments-received.service';
import {NotificationsService} from 'angular2-notifications';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {PAYMENT_CREDITS_STATUS_COLOR, PAYMENT_MODES_MAP} from 'app/local-typings';
import {DealService} from 'app/services/deal.service';
import {LedgerAccountService} from '../../../../../services/api/finance/ledger-accounts.service';
import * as moment from 'moment';
import {Deal} from '../../../../../models/deal';

@Component({
    selector: 'app-payment-received-page',
    templateUrl: 'payment-received-page.component.html',
    styleUrls: ['payment-received-page.component.scss']
})
export class PaymentReceivedPageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    payment: (IPaymentReceived & {related_payments: Array<IPayment & {applied: number; pending: number}>}) | undefined;
    statusColor = PAYMENT_CREDITS_STATUS_COLOR;
    dataSource: MatTableDataSource<IPayment>;
    dataConnectedSource: MatTableDataSource<IPayment>;
    dataRefundedPaymentsSource: MatTableDataSource<IPayment | IPaymentReceived>;

    displayedRelatedColumns: Array<string> = [
        'payment_date',
        'invoice_id',
        'source_document_title',
        'status',
        'amount'
    ];

    displayedRefundedColumns: Array<string> = ['payment_date', 'invoice_id', 'status', 'amount'];

    showPayments: boolean;
    showRefundedPayments: boolean;
    isRefundsLoading: boolean;

    paymentModesMap = PAYMENT_MODES_MAP;

    relatedDeals: Deal[] = [];
    deductDealId: number | null = null;
    showHistory: boolean;

    paymentReceivedLogs: IAccountingLog[] = [];
    paymentReceivedOldLogs: Array<any> = [];
    logsLoaded: boolean = false;

    appliedToInvoices: number = 0;
    pendingForInvoices: number = 0;
    pendingBalance: number = 0;
    unapplied: number = 0;
    pendingBalanceForUnapplied: number = 0;
    completedRefunds: number = 0;

    currentDepositToAccount: ILedgerAccount | undefined;
    additionalInfoLoaded: boolean;

    daysFromCreation: number | null = null;

    isVoidAllowed: boolean = false;

    constructor(
        private route: ActivatedRoute,
        public router: Router,
        private ntfs: NotificationsService,
        private paymentsReceivedService: PaymentsReceivedService,
        public dialog: MatDialog,
        private dealService: DealService,
        private ledgerAccountService: LedgerAccountService
    ) {
        this.dataSource = new MatTableDataSource<IPayment>([]);
        this.dataConnectedSource = new MatTableDataSource<IPayment>([]);
        this.dataRefundedPaymentsSource = new MatTableDataSource<IPayment | IPaymentReceived>([]);
        this.showPayments = true;
        this.showRefundedPayments = false;
        this.isRefundsLoading = false;
        this.showHistory = false;
        this.additionalInfoLoaded = false;
    }

    initPreview() {
        this.route.paramMap
            .pipe(
                map((pm) => {
                    const stringId: string | null = pm.get('id');
                    return Number(stringId);
                }),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                this.paymentsReceivedService
                    .getPaymentReceivedById(id)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((payment: IPaymentReceived) => {
                        this.payment = payment as IPaymentReceived & {
                            related_payments: Array<IPayment & {applied: number; pending: number}>;
                        };
                        this.loadLedgerAccount();
                        this.calculateDaysFromCreation();
                        this.checkAbilityToVoidRefund();
                        if (
                            this.payment.refunded_payments_received &&
                            this.payment.refunded_payments_received.length > 0
                        ) {
                            let payments: Array<IPayment | IPaymentReceived> = [];
                            this.payment.refunded_payments_received.forEach((refundedPayment) => {
                                if (refundedPayment.related_payments && refundedPayment.related_payments.length > 0) {
                                    payments = payments.concat(refundedPayment.related_payments);
                                } else {
                                    payments.push(refundedPayment);
                                }
                            });
                            payments.sort((a, b) => {
                                const dateA: string = a.created_at as unknown as string;
                                const dateB: string = a.created_at as unknown as string;
                                if (+new Date(dateA) > +new Date(dateB)) {
                                    return 1;
                                }
                                if (+new Date(dateA) < +new Date(dateB)) {
                                    return -1;
                                }
                                return 0;
                            });
                            this.paymentReceivedOldLogs = payments;
                        }
                        if (this.payment.related_payments) {
                            this.dataSource.data = this.payment.related_payments;
                        }
                        if (
                            this.payment.connected_payment_made &&
                            this.payment.connected_payment_made.related_payments
                        ) {
                            this.dataConnectedSource.data = this.payment.connected_payment_made.related_payments;
                        }
                        this.calculateAmounts();
                        this.getItemsRelatedDeals();
                    });
            });
    }

    checkAbilityToVoidRefund() {
        if (this.payment && this.payment.created_at) {
            // hours UTC when PayloadCo creates and sends instructions for banks
            const payloadCoDeadlineHours = 23;

            let payloadCoDeadlineTime = null;
            const hoursOfPaymentCreation = moment(this.payment.created_at as any)
                .utc()
                .hours();
            if (hoursOfPaymentCreation < payloadCoDeadlineHours) {
                // we have time to void till 23:00 UTC
                payloadCoDeadlineTime = moment(this.payment.created_at as any)
                    .utc()
                    .set('hour', payloadCoDeadlineHours)
                    .set('minute', 0)
                    .set('second', 0);
            } else {
                // we have time to void till the next day 23:00 UTC
                payloadCoDeadlineTime = moment(this.payment.created_at as any)
                    .utc()
                    .add(1, 'days')
                    .set('hour', payloadCoDeadlineHours)
                    .set('minute', 0)
                    .set('second', 0);
            }
            const now = moment().utc();

            this.isVoidAllowed = now.isBefore(payloadCoDeadlineTime);
        }
    }

    loadLedgerAccount() {
        if (this.payment && this.payment.pay_to__ledger_account_fk_id) {
            this.ledgerAccountService
                .getLedgerAccountById(this.payment.pay_to__ledger_account_fk_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((ledgerAccount: ILedgerAccount) => {
                    if (ledgerAccount && ledgerAccount.ledger_account_id) {
                        this.currentDepositToAccount = ledgerAccount;
                    }
                    this.additionalInfoLoaded = true;
                });
        } else {
            this.additionalInfoLoaded = true;
        }
    }

    calculateDaysFromCreation() {
        if (this.payment && this.payment.payment_mode === 'authorize' && this.payment.created_at) {
            this.daysFromCreation = moment.duration(moment().diff(moment(this.payment.created_at.toString()))).asDays();
        }
    }

    calculateAmounts() {
        if (!this.payment) {
            return null;
        }
        this.appliedToInvoices = 0;
        this.pendingForInvoices = 0;
        this.pendingBalance = 0;
        this.unapplied = 0;
        this.pendingBalanceForUnapplied = 0;
        this.completedRefunds = 0;

        let invoiceRefunds: IPayment[] = [];
        const excessRefunds: IPaymentReceived[] = [];
        if (this.payment.refunded_payments_received && this.payment.refunded_payments_received.length > 0) {
            for (const refundPayment of this.payment.refunded_payments_received) {
                if (refundPayment.summary_status === 'completed') {
                    this.completedRefunds += refundPayment.amount;
                }
                if (refundPayment.related_payments && refundPayment.related_payments.length > 0) {
                    invoiceRefunds = invoiceRefunds.concat(refundPayment.related_payments);
                } else {
                    excessRefunds.push(refundPayment);
                }
            }
        }

        if (this.payment.related_payments && this.payment.related_payments.length > 0) {
            for (const payment of this.payment.related_payments as unknown as Array<
                IPayment & {applied: number; pending: number}
            >) {
                let applied = 0;
                let appliedPending = 0;
                let refunded = 0;
                let refundedPending = 0;

                if (payment.invoice_fk_id) {
                    if (payment.status === 'completed') {
                        applied = payment.amount;
                    }
                    if (['processing', 'scheduled'].includes(payment.status)) {
                        appliedPending = payment.amount;
                    }
                    if (payment.refunded_amount > 0) {
                        const refundPayment = invoiceRefunds.find(
                            (ref) => ref.refund_source__payment_fk_id === payment.payment_id
                        );
                        if (refundPayment) {
                            if (refundPayment.status === 'completed') {
                                refunded = refundPayment.amount;
                            }
                            if (refundPayment.status === 'processing') {
                                refundedPending = refundPayment.amount;
                            }
                        }
                    }
                }
                payment['applied'] = applied - refunded;
                payment['pending'] = payment['applied'] + (appliedPending - refundedPending);

                this.appliedToInvoices += applied - refunded;
                this.pendingForInvoices += appliedPending - refundedPending;
            }

            this.pendingBalance = this.appliedToInvoices + this.pendingForInvoices;

            const excessPendingRefundAmount = excessRefunds.reduce(
                (acc, pay) => (pay.summary_status === 'processing' ? pay.amount + acc : acc),
                0
            );
            const excessCompletedRefundAmount = excessRefunds.reduce(
                (acc, pay) => (pay.summary_status === 'completed' ? pay.amount + acc : acc),
                0
            );
            const completedAmount = this.payment.related_payments.reduce(
                (acc, pay) => (pay.status === 'completed' ? pay.amount + acc : acc),
                0
            );

            this.unapplied = this.payment.amount - completedAmount - excessCompletedRefundAmount;
            this.pendingBalanceForUnapplied = this.unapplied - excessPendingRefundAmount;
        }
    }

    loadRefundedPayments() {
        this.showRefundedPayments = !this.showRefundedPayments;
        if (this.dataRefundedPaymentsSource.data.length === 0 && this.payment && this.payment.payment_received_id) {
            this.isRefundsLoading = true;
            this.paymentsReceivedService
                .getRefundedPaymentsByPaymentReceivedId(this.payment.payment_received_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((refundedPayments: IPaymentReceived[]) => {
                    if (refundedPayments.length > 0) {
                        let payments: Array<IPayment | IPaymentReceived> = [];
                        refundedPayments.forEach((refundedPayment) => {
                            if (refundedPayment.related_payments && refundedPayment.related_payments.length > 0) {
                                payments = payments.concat(refundedPayment.related_payments);
                            } else {
                                payments.push(refundedPayment);
                            }
                        });
                        payments.sort((a, b) => {
                            const dateA: string = a.created_at as unknown as string;
                            const dateB: string = a.created_at as unknown as string;
                            if (+new Date(dateA) > +new Date(dateB)) {
                                return 1;
                            }
                            if (+new Date(dateA) < +new Date(dateB)) {
                                return -1;
                            }
                            return 0;
                        });
                        this.dataRefundedPaymentsSource.data = payments;
                        this.paymentReceivedOldLogs = payments;
                    }
                    this.isRefundsLoading = false;
                });
        }
    }

    loadHistory() {
        this.logsLoaded = false;
        this.showRefundedPayments = !this.showRefundedPayments;
        if (this.showHistory && this.payment && this.payment.payment_received_id) {
            this.paymentsReceivedService
                .getPaymentReceivedHistory(this.payment.payment_received_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((logs) => {
                    this.paymentReceivedLogs = logs;
                    this.logsLoaded = true;
                });
        }
    }

    recheckPaymentStatus(paymentReceivedId: number) {
        this.paymentsReceivedService
            .recheckPaymentReceivedStatusById(paymentReceivedId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((message) => {
                if (message) {
                    this.ntfs.info(`External Status`, message);
                    this.initPreview();
                }
            });
    }

    refundPayment() {
        if (this.payment) {
            this.router.navigate(['sales/paymentsreceived/refund', this.payment.payment_received_id]);
        }
        // const dialogRef = this.dialog.open(RefundDialogComponent, {
        //     minWidth: 320,
        //     data: {
        //        amount: this.payment.amount
        //     }
        // });
        //
        // dialogRef.afterClosed().pipe(
        //     filter(pn => !!pn))
        //     .subscribe(amount => {
        //         if (amount) {
        //             console.dir(amount);
        //             this.paymentsReceivedService.refundPayment(this.payment.payment_received_id, {amount})
        //                 .pipe(takeUntil(this.unsubscribe))
        //                 .subscribe(message => {
        //                     if (message) {
        //                         // this.ntfs.info(`External Status`, message);
        //                         // this.initPreview();
        //                     }
        //                 });
        //         }
        //     });
    }

    getItemsRelatedDeals() {
        if (this.payment) {
            const uniqueDealIds: Set<number> = new Set();
            if (this.payment.payment_mode === 'deduction' && this.payment.payment_received_id) {
                this.dealService
                    .getFinancialTransfersByPaymentReceivedId(this.payment.payment_received_id)
                    .then((transfers) => {
                        uniqueDealIds.add(transfers[0].deal.id);
                        this.deductDealId = transfers[0].deal.id;
                    });
            }

            if (this.payment.related_payments) {
                for (const relatedPayment of this.payment.related_payments) {
                    if (relatedPayment.deposit_request && relatedPayment.deposit_request.deal_fk_id) {
                        uniqueDealIds.add(relatedPayment.deposit_request.deal_fk_id);
                    }

                    if (
                        relatedPayment.invoice &&
                        relatedPayment.invoice.items &&
                        relatedPayment.invoice.items.length > 0
                    ) {
                        for (const invoiceItem of relatedPayment.invoice.items) {
                            if (invoiceItem.connected__deal_fk_id) {
                                uniqueDealIds.add(invoiceItem.connected__deal_fk_id);
                            }
                        }
                    }
                }
            }

            if (uniqueDealIds.size > 0) {
                this.relatedDeals = [];
                for (const dealId of uniqueDealIds) {
                    this.dealService.getDealBaseView(dealId).then((deal) => {
                        this.relatedDeals.push(deal);
                    });
                }
            }
        }
    }

    voidPayment() {
        if (this.payment && this.payment.external_transaction_ref && this.payment.payment_received_id) {
            this.paymentsReceivedService
                .voidPaymentReceived(this.payment.payment_received_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((message) => {
                    if (message) {
                        this.ntfs.info(`The voiding process has begun.`);
                        this.initPreview();
                    }
                });
        }
    }

    ngOnInit() {
        this.initPreview();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
