<label class="w-100 root">
    <span [class]="classes?.label">{{ label }}</span>
    <mat-form-field class="w-100 mt-0">
        <mat-chip-list #chipList aria-label="Tags selection">
            <mat-chip *ngFor="let option of selectedOptions" (removed)="handleRemove(option)">
                <ng-container *ngIf="option.groupLabel"> {{ option.groupLabel }} / </ng-container>
                {{ option.label }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
                #input
                [ngClass]="{'d-none': selectionMode === 'single' && selectedOptions?.length > 0}"
                [class]="classes?.input"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                (keydown)="checkAndPreventInput($event)"
                (keyup)="search($event)"
            />
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="handleSelect($event)">
            <!-- <ng-container *ngIf="selectionMode === 'multiple' || selectedOptions?.length === 0"> -->
            <ng-container *ngFor="let group of groupedDisplayedOptions">
                <ng-container *ngIf="!group.label">
                    <mat-option
                        *ngFor="let option of group.options"
                        [value]="option.value"
                        [disabled]="option.disabled"
                    >
                        {{ option.label }}
                    </mat-option>
                </ng-container>

                <mat-optgroup *ngIf="group.label" [label]="group.label">
                    <ng-container *ngFor="let option of group.options">
                        <mat-option [value]="option.value" [disabled]="option.disabled">
                            {{ option.label }}
                        </mat-option>
                    </ng-container>
                </mat-optgroup>
            </ng-container>
            <!-- </ng-container> -->
        </mat-autocomplete>
    </mat-form-field>
</label>
