import {Component, EventEmitter, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {IFinanceState} from '../../finance/store/finance.reducer';
import {FetchProducts} from '../../finance/store/finance.actions';
import {ComponentCanDeactivate} from '../../../services/resolvers/pending-changes-guard.service';
import {Observable, Subject} from 'rxjs';
import * as _ from 'lodash-es';
import {GenericFormArray} from 'app/entites/generic.entity';
import {CompensationProfileModel} from '../compensation/models/compensation-profile.model';
import {MatDialog} from '@angular/material/dialog';
import {CalculationSettingsComponent} from '../calculation-settings/calculation-settings.component';
import {SessionService} from '../../../services/session.service';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {CalculationSettingsService} from '../../../services/calculation-settings.service';
import {CalculationSettingsService as NewCalculationSettingsService} from '../../../services/new-calculation-settings.service';
import {FeatureFlagsService} from '@app/modules/feature-flags/feature-flags.service';

@Component({
    selector: 'app-company-compensation-profiles',
    template: `
        <div>
            <div
                class="d-flex buttons-container w-100 bg-white border-bottom py-2 px-3 justify-content-between"
                style="height: 60px"
            >
                <!------- Button board list ------->
                <div class="d-flex">
                    <h3 class="mb-0 d-flex align-items-center">Commission Plans</h3>

                    <div *ngIf="showButtons">
                        <a
                            mat-raised-button
                            class="mat-button"
                            color="warn"
                            *ngIf="!hasChanges()"
                            (click)="saveChanges()"
                        >
                            Save Changes
                        </a>
                    </div>
                </div>

                <div>
                    <a
                        mat-raised-button
                        class="mat-button"
                        *ngIf="isNewSettingsEnabled"
                        [routerLink]="'/company/calculation-settings'"
                    >
                        Deals Calculations Settings
                    </a>
                    <a
                        mat-raised-button
                        class="mat-button"
                        *ngIf="!isNewSettingsEnabled"
                        (click)="openSettingsDialog()"
                    >
                        Deals Calculations Settings
                    </a>
                </div>
            </div>

            <div class="sidebar-wrapper pt-4 px-4">
                <ng-template mat-tab-label>
                    <h3>Profiles</h3>
                </ng-template>
                <app-tab-1-profiles
                    (tabChanged)="showButtons = $event.tabId === 0"
                    (form)="form = $event.currentForm; snapshot = $event.startedValues"
                    [onSaveProfiles]="onSaveProfiles"
                ></app-tab-1-profiles>
            </div>
        </div>
    `,
    styles: [
        `
            .sidebar-wrapper {
                position: relative;
            }

            .action-icon {
                font-weight: bold;
                height: 36px;
            }

            .buttons-container {
                width: 100%;
                position: sticky;
                z-index: 2;
                top: 0;
            }
        `
    ]
})
export class CompensationProfilesComponent implements ComponentCanDeactivate, OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    public showButtons: boolean = true;
    public onSaveProfiles: EventEmitter<void> = new EventEmitter<void>();

    public form: GenericFormArray<CompensationProfileModel> | undefined;
    public snapshot: CompensationProfileModel[] = [];
    public isNewSettingsEnabled: boolean = false;

    constructor(
        protected sessionService: SessionService,
        protected featureFlagsService: FeatureFlagsService,
        protected calculationSettingsService: CalculationSettingsService,
        protected newCalculationSettingsService: NewCalculationSettingsService,
        private store: Store<IFinanceState>,
        public dialog: MatDialog
    ) {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.isNewSettingsEnabled = this.featureFlagsService.isFeatureEnabled('rules:new_calculation_settings');
            });
    }

    ngOnInit(): void {
        this.store.dispatch(new FetchProducts());
        this.calculationSettingsService.getCalculationSettings(true).then((calculationSettings) => {
            this.calculationSettingsService.calculationSettings = calculationSettings;
        });
        this.newCalculationSettingsService.getAllMetrics(true);
    }

    hasChanges(): boolean {
        if (this.form && this.snapshot) {
            return _.isEmpty(_.xorWith(this.snapshot, this.form.getRawValue(), _.isEqual));
        }
        return false;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return this.hasChanges();
    }

    saveChanges() {
        this.onSaveProfiles.emit();
    }

    openSettingsDialog() {
        const dialogRef = this.dialog.open(CalculationSettingsComponent, {
            minWidth: 600,
            maxWidth: 600
        });

        dialogRef
            .afterClosed()
            .pipe(debounceTime(200), takeUntil(this.unsubscribe))
            .subscribe((needRefreshSettings) => {
                this.calculationSettingsService.getCalculationSettings(needRefreshSettings);
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
