import {Component, Input} from '@angular/core';
import {IReportsPreviewResultData} from '../../../../store/reports.reducer';

@Component({
    selector: 'reports-preview-account-transaction',
    templateUrl: './account-transaction.component.html',
    styleUrls: ['./account-transaction.component.scss']
})
export class AccountTransactionPreviewComponent {
    @Input()
    /**
     * Set report data
     */
    set reportData(data: IReportsPreviewResultData | null) {
        this.tableData = data ? data.transactions : null;
        this._reportData = data;
    }

    /**
     * Get report data
     */
    get reportData() {
        return this._reportData;
    }

    _reportData: IReportsPreviewResultData | null = null;
    tableData: IReportResult['transactions'] | null = null;

    tableStructure: Array<object> = [
        {
            title: 'Date',
            value: 'date'
        },
        {
            title: 'Account',
            value: 'name'
        },
        {
            title: 'Transaction Details',
            value: 't_details'
        },
        {
            title: 'Account Type',
            value: 'account_type'
        },
        {
            title: 'Journal Id',
            value: 'j_id'
        },
        {
            title: 'Reference',
            value: 'reference_number'
        },
        {
            title: 'Debit',
            value: 'debit',
            filter: 'financial'
        },
        {
            title: 'Credit',
            value: 'credit',
            filter: 'financial'
        },
        {
            title: 'Amount',
            value: 'amount',
            filter: 'financial'
        }
    ];

    columnsToDisplay: string[] = [
        'date',
        'name',
        't_details',
        'transaction_type',
        'j_id',
        'reference_number',
        'debit',
        'credit',
        'amount'
    ];

    constructor() {}
}

interface IReportResult {
    transactions: IReportsPreviewResultData[];
    total_count: number;
}
