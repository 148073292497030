import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {IGooglePlaceObject} from '../../../../../typings';

/**
 * https://developers.google.com/maps/documentation/javascript/places
 * https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
 * https://developers.google.com/maps/documentation/javascript/reference#Autocomplete
 */
@Component({
    selector: 'app-gplaces-picker',
    templateUrl: './places-picker.component.html',
    styleUrls: ['./places-picker.component.css'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class PlacesPickerComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() placeholder = 'Start typing address'; // 'Enter address part to get available variants'
    @Input() locationCtrl: UntypedFormControl | null = null; /* IGooglePlaceObject */
    @Input() focused = false;
    @Input() isPickedChipNeeded: boolean = true;
    @Input() isClearAfterPickNeeded: boolean = false;

    @ViewChild('query', {static: true}) queryInput: ElementRef | undefined | null = undefined;

    protected window: any = window;

    protected googleMaps = this.window.google.maps;
    protected autocomplete: any = undefined;

    protected placeChangedListener: undefined | null = undefined;

    constructor(private cd: ChangeDetectorRef | null) {}

    ngOnInit() {}

    ngOnDestroy() {
        this.removeListeners();
        this.window = null;
        this.googleMaps = null;
        this.placeChangedListener = null;
        this.cd = null;
        this.autocomplete = null;
        this.queryInput = null;
    }

    ngAfterViewInit() {
        this.autocomplete = new this.googleMaps.places.Autocomplete(this.queryInput?.nativeElement, {type: 'address'});

        this.initListeners();
    }

    protected initListeners() {
        if (!this.autocomplete) {
            return;
        }
        this.placeChangedListener = this.autocomplete.addListener('place_changed', () => {
            const place = this.autocomplete.getPlace();

            if (!place) {
                return;
            }

            this.addPlace(place);
        });
    }

    protected removeListeners() {
        if (!this.queryInput) {
            return;
        }
        this.queryInput.nativeElement.removeAllListeners();
    }

    geolocate() {
        if (navigator.geolocation && this.googleMaps) {
            navigator.geolocation.getCurrentPosition((position) => {
                const geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

                const circle = new this.googleMaps.Circle({
                    center: geolocation,
                    radius: position.coords.accuracy
                });

                this.autocomplete.setBounds(circle.getBounds());
            });
        }
    }

    removePlace() {
        if (this.locationCtrl) {
            this.locationCtrl.reset(null);
        }
        if (this.cd) {
            this.cd.detectChanges();
        }
        this.clearAutocomplete();
    }

    protected clearAutocomplete() {
        this.autocomplete.setBounds(null);
        if (this.queryInput) {
            this.queryInput.nativeElement.value = '';
        }
    }

    public addPlace(place: IGooglePlaceObject) {
        if (!this.locationCtrl) {
            return;
        }
        this.locationCtrl.patchValue(place);
        if (this.isClearAfterPickNeeded) {
            this.clearAutocomplete();
        }
    }
}
