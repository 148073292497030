import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MarketplaceAddonInstanceModel} from './marketplace.models';
import {MarketplaceSource} from './marketplace.source';
import {MarketplaceApi} from './marketplace.api';
import {MarketplaceDeactivateConfirmationDialogComponent} from './marketplace-deactivate-confirmation.dialog';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {CurrentProfileSource} from '../../../services/sources/current-profile.source';
import {CompanyGatewayService} from '../../../services/api/finance/company-gateway.service';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {Subject} from 'rxjs';
import {FeatureFlagsService} from '../../feature-flags/feature-flags.service';
import {ConfirmAddonProfileCreationDialogComponent} from '../dialogs/confirm-addon-profile-creation-dialog.component';
import {Profile} from '../../../models/profile';
import {ConfirmQuickbooksDialogComponent} from '../dialogs/confirm-quickbooks-dialog.component';
import {QuickBooksService} from '../../../services/api/addon/quickbooks/quickbooks.service';

@Component({
    selector: 'app-marketplace',
    templateUrl: 'marketplace.component.html',
    styles: [
        `
            .col {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }
            .row {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
            }
            .title {
                font-size: 20px;
                font-weight: bold;
                min-height: 30px;
            }
            .description {
                margin-top: 8px;
                margin-left: 30px;
                font-size: 14px;
                font-weight: normal;
            }
            .logo {
                margin-right: 8px;
                flex-basis: auto;
            }
            mat-card-actions {
                margin-left: 0px;
            }
        `
    ]
})
export class MarketplaceComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    public currentProfile: Profile = new Profile();
    // public company: Company;

    protected activeTabIndex = 0;

    public isBusy = true;
    public isExtraBoardsAllowed = true;

    public allAddonsInstances: MarketplaceAddonInstanceModel[] = [];
    public activatedAddonsInstances: MarketplaceAddonInstanceModel[] = [];

    // feature flag
    public featureFlags: {[key: string]: boolean} = {};

    isFinalEnabledFlag = false;

    constructor(
        protected featureFlagsService: FeatureFlagsService,
        protected marketplaceSource: MarketplaceSource,
        protected marketplaceApi: MarketplaceApi,
        protected dialog: MatDialog,
        protected router: Router,
        private companyGatewayService: CompanyGatewayService,
        protected currentProfileSource: CurrentProfileSource,
        private quickBooksService: QuickBooksService
    ) {
        this.marketplaceSource.addonsChangeEvent.pipe(takeUntil(this.unsubscribe)).subscribe((instances) => {
            this.allAddonsInstances = instances.map((instance) => {
                if (!instance.addon) {
                    return instance;
                }
                const res: MarketplaceAddonInstanceModel = Object.assign(
                    new MarketplaceAddonInstanceModel(),
                    instance.addon.preset
                );
                res.addon = instance.addon;
                res.id = instance.id;
                res.activated = instance.activated;
                res.is_featured = instance.is_featured;
                if (instance.title) {
                    res.title = instance.title;
                }
                if (instance.description) {
                    res.description = instance.description;
                }
                if (instance.logo) {
                    res.logo = instance.logo;
                }

                // attach rbac depends on addon slug
                res.rbac = this.marketplaceApi._getAddonAccessRuleByAddonSlug(res.addon.slug);

                return res;
            });
            this.activatedAddonsInstances = this.allAddonsInstances
                .filter((instance) => instance.activated)
                .map((instance) => {
                    if (!instance.addon) {
                        return instance;
                    }

                    // attach rbac depends on addon slug
                    instance.rbac = this.marketplaceApi._getAddonAccessRuleByAddonSlug(instance.addon.slug);

                    return instance;
                });
        });

        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                if (allFlags) {
                    Object.keys(allFlags).forEach((flagId) => {
                        this.featureFlags[flagId] = allFlags[flagId].enabled;
                    });
                }
            });
    }

    ngOnInit() {
        this.currentProfileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe((profile) => {
            if (profile.company !== null) {
                this.currentProfile = profile;
                return;
            }

            localStorage.setItem('user_route', window.location.href);
            this.router.navigate(['/select-profile']);
        });
    }

    activeTabChanged(activeTabIndex: number) {
        this.activeTabIndex = activeTabIndex;
    }

    confirmAddonActivation(instance: MarketplaceAddonInstanceModel) {
        if (!instance.addon) {
            return;
        }
        const confirmDialogRef = this.dialog.open(ConfirmAddonProfileCreationDialogComponent, {
            minWidth: 420,
            maxWidth: 1040,
            minHeight: 160,
            data: {
                addonTitle: instance.addon.preset?.title || '',
                systemProfileEmail:
                    this.currentProfile.company && this.currentProfile.company.id && instance.addon.slug
                        ? this.__buildSystemProfileEmailForAddon(this.currentProfile.company.id, instance.addon.slug)
                        : ''
            }
        });

        confirmDialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isConfirmed: boolean) => {
                if (!isConfirmed) {
                    return;
                }

                this.activateAddon(instance, true);
            });
    }

    confirmQuickBooksActivation(instance: MarketplaceAddonInstanceModel) {
        if (!instance.addon) {
            return;
        }

        const confirmDialogRef = this.dialog.open(ConfirmQuickbooksDialogComponent, {
            minWidth: 420,
            maxWidth: 696,
            minHeight: 160
        });

        confirmDialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isConfirmed: boolean) => {
                if (!isConfirmed) {
                    return;
                }

                this.activateAddon(instance, true);
            });
    }

    activateAddon(instance: MarketplaceAddonInstanceModel, activationConfirmed: boolean = false) {
        if (!instance.addon) {
            return;
        }

        // show confirmation popup if system (addon) profile will be created for this addon
        const isNeedUserConfirmation =
            instance.addon.settings && instance.addon.settings.enabled_system_profile === true;

        if (isNeedUserConfirmation && !activationConfirmed) {
            if (instance.addon.slug === 'quickbooks_integration') {
                this.confirmQuickBooksActivation(instance);
                return;
            } else {
                this.confirmAddonActivation(instance);
                return;
            }
        }

        this.marketplaceApi.activateAddon(instance.addon!).then(() => {
            this.marketplaceApi.loadAddons();

            if (
                instance.addon &&
                instance.addon.slug &&
                instance.addon.settings &&
                instance.addon.settings.urls &&
                instance.addon.settings.is_navigate_to_settings_after_activate
            ) {
                this.router.navigate([instance.addon.settings.urls.redirect_settings]);
            }
        });
    }

    __buildSystemProfileEmailForAddon(zipiCompanyId: number, addonSlug: string) {
        return `${addonSlug}+${zipiCompanyId}@zipi.app`;
    }

    addonCompanySettings(instance: MarketplaceAddonInstanceModel) {
        let settingsPath = '/integration/settings';
        if (
            instance.addon &&
            instance.addon.settings &&
            instance.addon.settings.urls &&
            instance.addon.settings.enabled_company_settings
        ) {
            if (instance.addon.settings.urls.redirect_settings) {
                settingsPath = instance.addon.settings.urls.redirect_settings;
            }
        }

        // if this addon integrated in app no need reload page
        // if (instance.addon && instance.addon.slug
        //     && ['authorize_net_integration', 'dwolla_integration', 'skyslope_integration', 'dotloop_integration', 'yodata_integration', 'dash_integration'].includes(instance.addon.slug)) {
        //     this.router.navigate([settingsPath]);
        // }

        // if (instance.addon && instance.addon.slug
        //     && ['authorize_net_integration',
        //         'dwolla_integration',
        //         'zipi_financial_integration',
        //         'skyslope_integration',
        //         'dotloop_integration',
        //         'yodata_integration',
        //         'dash_integration'].includes(instance.addon.slug)) {
        //     this.router.navigate([settingsPath]);

        if (instance.activated && instance.addon?.settings?.enabled_company_settings) {
            this.router.navigate([settingsPath]);
        }
    }

    deactivateAddon(instance: MarketplaceAddonInstanceModel) {
        const dialogRef = this.dialog.open(MarketplaceDeactivateConfirmationDialogComponent);
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((reallyDeactivate) => {
                if (reallyDeactivate) {
                    if (!instance.addon) {
                        return;
                    }
                    this.marketplaceApi.deactivateAddon(instance.addon).then(async () => {
                        if (
                            instance.addon &&
                            instance.addon.slug &&
                            [
                                'authorize_net_integration',
                                'dwolla_integration',
                                'zipi_financial_integration',
                                'muhnee_integration'
                            ].includes(instance.addon.slug)
                        ) {
                            return this.companyGatewayService
                                .deleteCompanyGateway(instance.addon.slug)
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe(() => {
                                    this.afterGatewayDeleting();
                                });
                        }

                        // If the QB addon is being deactivated, we will also disable it's corresponding
                        // qb_company_settings record for this profile's company in spanner.
                        if (instance.addon && instance.addon.slug && instance.addon.slug === 'quickbooks_integration') {
                            this.quickBooksService
                                .disableQbCompanySettings()
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((response) => {});
                        }

                        await this.marketplaceApi.loadAddons();
                    });
                } else {
                    // dialog was just closed, no need to save anything
                    return;
                }
            });
    }

    async afterGatewayDeleting() {
        await this.marketplaceApi.loadAddons();
    }

    settingsAddon(instance: MarketplaceAddonInstanceModel) {
        if (!instance.addon) {
            return;
        }
        this.marketplaceApi.activateAddonForProfile(instance.addon).then(() => {
            if (!instance.addon || !instance.addon.addon_url) {
                return;
            }
            window.location.href = instance.addon.addon_url;
        });
    }

    onToggleChangeEvent($event: MatSlideToggleChange, activatedAddonInstance: MarketplaceAddonInstanceModel) {
        this.marketplaceApi.updateAddonInstanceFeatureState(activatedAddonInstance);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
