import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../../../services/service.requester';
import {from, Observable} from 'rxjs';
import {IScrollData} from '../../../../../models/scroll-data';
import {
    IClosingPayoutsListResponseData,
    IClosingPayoutsWithAdditionalInfo
} from '../../closing-payouts/types/closing-payouts.types';
import {IDataToBeProcessed} from '../../remaining-payouts/types/remaining-payouts.types';

@Injectable()
export class ClosingPayoutsApiService {
    private url = '/closing-payouts';

    constructor(public requester: ServiceRequester) {}

    getClosingPayouts(params: IScrollData): Observable<IClosingPayoutsListResponseData> {
        return from(this.requester.makeMsCall(this.url, 'GET', params));
    }

    getClosingPayoutsInfo(ids: Array<number>): Observable<IClosingPayoutsWithAdditionalInfo[]> {
        return from(this.requester.makeMsCall(this.url + '/info', 'GET', {ids: ids}));
    }

    startPayoutsProcessing(
        data: IDataToBeProcessed
    ): Observable<{status: string; payouts_in_processing: Array<number>}> {
        return from(this.requester.makeMsCall(this.url + '/start-processing-escrow-invoice', 'POST', data));
    }
}
