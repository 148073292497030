import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {takeUntil, filter} from 'rxjs/operators';
import {IFinanceState} from '../../../store/finance.reducer';
import {CreateRecurringInvoice} from '../../../store/finance.actions';
import {IInvoice, IRecurringInvoice} from '@cyberco-nodejs/zipi-typings';
import {RecurringInvoicesService} from 'app/services/api/finance/recurring-invoices.service';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {MatDialog} from '@angular/material/dialog';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {IScrollData} from 'app/models/scroll-data';
import {PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
    selector: 'app-recurring-invoices',
    templateUrl: 'recurring-invoices.component.html',
    styleUrls: ['recurring-invoices.component.scss', '../../../../../../assets/infinite-scroll-table.scss']
})
export class RecurringInvoicesComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeInvoices: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;

    selection: SelectionModel<number> = new SelectionModel<number>(true, []);

    scrollData: IScrollData = {
        offset: 0,
        limit: 50,
        sort_column: 'last_invoice_date',
        sort_direction: 'desc',
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<IRecurringInvoice>;
    displayedColumns = [
        'checkbox',
        'title',
        'customer_name',
        'frequency',
        'last_invoice_date',
        'next_invoice_date',
        'total_amount',
        'action'
    ];

    isSelectedForBulk: boolean;

    constructor(
        private store: Store<IFinanceState>,
        private recurringInvoicesService: RecurringInvoicesService,
        public dialog: MatDialog
    ) {
        this.isSelectedForBulk = false;
        this.dataSource = new MatTableDataSource<IRecurringInvoice>([]);
    }

    runScheduler() {
        this.recurringInvoicesService
            .runScheduler()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => res);
    }

    ngOnInit() {
        this.loadRecurringInvoices();

        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.loadRecurringInvoices();
            });
        }
    }

    loadRecurringInvoices() {
        this.recurringInvoicesService
            .getRecurringInvoices(this.scrollData)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.dataSource.data = data.result;
                this.scrollData.total = data._meta.total;
            });
    }

    toggleBulkCheckbox($event: MatCheckboxChange, id: number) {
        if ($event) {
            this.selection.toggle(id as never); // dirty hack
        }
        this.isSelectedForBulk = this.selection.selected.length !== 0;
    }

    cloneInvoice(invoice: IRecurringInvoice) {
        const {recurring_invoice_id, id, created_at, updated_at, ...newInvoice} = invoice;

        newInvoice.title = `Copy of ${invoice.title}`;
        this.store.dispatch(new CreateRecurringInvoice(newInvoice as IRecurringInvoice));
    }

    bulkDelete() {
        const recurringIdsForBulk = this.selection.selected;
        if (recurringIdsForBulk.length > 0) {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                minWidth: 320,
                data: {
                    title: 'Deleting Recurring Invoices',
                    message: `${recurringIdsForBulk.length} Recurring Invoices will be deleted`
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((ok) => {
                    if (ok) {
                        this.recurringInvoicesService
                            .bulkDeleteRecurringInvoices(recurringIdsForBulk)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((res) => {
                                this.selection.clear();
                                this.isSelectedForBulk = false;
                                this.ngOnInit();
                            });
                    }
                });
        }
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }
        this.paginator!.pageIndex = 0;
        this.scrollData.offset = 0;

        this.loadRecurringInvoices();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeInvoices.next();
        this.unsubscribeInvoices.complete();
    }
}
