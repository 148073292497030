import {AfterViewChecked, Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {CropperSettings, ImageCropperComponent} from 'ngx-img-cropper';
import {Subject} from 'rxjs';

@Component({
    selector: 'custom-cropper',
    template: `
        <img-cropper
            #cropper
            [image]="image"
            [settings]="cropperSettings"
            (cropPositionChange)="cropPositionChange()"
            (onCrop)="onCrop()"
        ></img-cropper>
    `,
    styles: [
        `
            ::ng-deep canvas {
                max-width: 100%;
            }
        `
    ]
})
export class CustomCropperComponent implements AfterViewChecked, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    // eslint-disable-next-line
    @Input('init-url') initUrl: string | null = null;

    @Output() ready = new EventEmitter();
    @Output() change = new EventEmitter();

    @ViewChild('cropper', {static: true}) cropper: ImageCropperComponent | undefined;

    public image: HTMLImageElement = document.createElement('img');

    public cropperSettings: CropperSettings = new CropperSettings();
    private becameReady = false;

    private cropPosChanged: boolean = false;

    constructor() {
        this.initCropper();
    }

    cropPositionChange() {
        this.cropPosChanged = true;
    }

    onCrop() {
        if (this.cropPosChanged) {
            this.change.emit(this.cropper!.image.image);
        }
    }

    initCropper() {
        this.cropperSettings.width = 280;
        this.cropperSettings.height = 200;
        this.cropperSettings.canvasWidth = 280;
        this.cropperSettings.canvasHeight = 200;
        this.cropperSettings.croppedWidth = 280;
        this.cropperSettings.croppedHeight = 200;
        this.cropperSettings.noFileInput = true; // hides the file input element from cropper canvas.
    }

    public setImageFromFile(file: Blob): Promise<boolean> {
        return new Promise((resolve) => {
            const reader = new FileReader();
            this.image.crossOrigin = 'Anonymous';
            reader.onloadend = (loadEvent: any) => {
                if (loadEvent.target.result.startsWith('data:image')) {
                    this.image.src = loadEvent.target.result;
                    resolve(this.image);
                }
            };
            reader.readAsDataURL(file);
        })
            .then((image: any) => {
                this.cropper!.setImage(image);
                return true;
            })
            .then(() => {
                setTimeout(() => {
                    this.onCrop();
                }, 300);
                return true;
            });
    }

    ngAfterViewChecked() {
        if (typeof this.cropper !== 'undefined' && this.becameReady === false) {
            this.ready.emit();
            this.initCropper();
            this.becameReady = true;
        }
    }

    public clear() {
        this.cropper!.reset();
        this.change.emit('');
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.ready.complete();
        this.change.complete();
    }
}
