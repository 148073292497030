import {Component, OnInit, Input} from '@angular/core';
import {DealService} from 'app/services/deal.service';

@Component({
    selector: 'app-contact-activities',
    templateUrl: 'contact-activities.component.html',
    styleUrls: ['contact-activities.component.css']
})
export class ContactActivitiesComponent implements OnInit {
    @Input() set profileId(id: number | null) {
        if (!id) {
            return;
        }
        this.get0ProfileDealsAuditLogs(id);
    }

    auditLogs = [];

    constructor(private dealsService: DealService) {}

    protected async get0ProfileDealsAuditLogs(id: number) {
        this.auditLogs = await this.dealsService.getProfileAudits(id);
    }

    ngOnInit() {}
}
