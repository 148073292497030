import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {GenericFormGroup} from '../../../entites/generic.entity';

@Component({
    selector: 'app-goal-mandatory',
    styles: [
        `
            mat-card-header {
                display: block;
                text-align: center;
                font-size: 20px;
                margin: 5%;
                color: #676161;
            }

            button {
                padding-left: 40px;
                padding-right: 40px;
                margin: 20px auto;
            }

            .prefix {
                font-size: 12px;
                margin-right: 4px;
                font-weight: 300;
            }

            button {
                height: 40px;
                margin: 4px;
            }

            mat-form-field {
                width: 100%;
            }
        `
    ],
    template: `
        <div style="display: flex" *ngIf="goal">
            <button [disabled]="goal.disabled" mat-icon-button [matMenuTriggerFor]="menu" *ngIf="goal">
                <ng-container *ngIf="goal.controls.is_archived?.value !== true">
                    <mat-icon *ngIf="goal.controls.mandatory_type?.value === 'optional'">add_circle_outline</mat-icon>
                    <mat-icon *ngIf="goal.controls.mandatory_type?.value === 'automatic'">spellcheck</mat-icon>
                    <mat-icon *ngIf="goal.controls.mandatory_type?.value === 'mandatory'">done_all</mat-icon>
                    <mat-icon *ngIf="goal.controls.mandatory_type?.value === 'personal_template'">person_pin</mat-icon>
                </ng-container>
                <mat-icon *ngIf="goal.controls.is_archived?.value === true">archive</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <ng-container *ngIf="goal && goal.controls.is_archived?.value !== true">
                    <button (click)="setMandatoryType(goal, 'optional')" mat-menu-item>
                        <mat-icon>add_circle_outline</mat-icon>
                        Optional
                    </button>
                    <button (click)="setMandatoryType(goal, 'automatic')" mat-menu-item>
                        <mat-icon>spellcheck</mat-icon>
                        Automatic
                    </button>
                    <button (click)="setMandatoryType(goal, 'mandatory')" mat-menu-item>
                        <mat-icon>done_all</mat-icon>
                        Mandatory
                    </button>
                    <button (click)="setMandatoryType(goal, 'personal_template')" mat-menu-item>
                        <mat-icon>person_pin</mat-icon>
                        Personal Template
                    </button>
                    <button (click)="doArchiveGoal(goal)" color="warn" mat-menu-item>
                        <mat-icon>archive</mat-icon>
                        Archive Goal
                    </button>
                </ng-container>
                <button (click)="doDeleteGoal()" color="warn" mat-menu-item>
                    <mat-icon>close</mat-icon>
                    Delete Goal
                </button>
            </mat-menu>
        </div>
    `
})
export class GoalMandatoryComponent implements OnDestroy {
    @Input() goal: GenericFormGroup<any> | undefined;
    @Input() index: number | null = null;
    @Output() deleteGoal: EventEmitter<boolean> = new EventEmitter();

    setMandatoryType(goal: GenericFormGroup<any>, mandatoryType: string) {
        goal.controls.mandatory_type!.patchValue(mandatoryType);
    }

    doDeleteGoal() {
        this.deleteGoal.emit(true);
    }

    doArchiveGoal(goal: GenericFormGroup<any>) {
        goal.controls.is_archived!.patchValue(true);
    }

    ngOnDestroy() {
        this.deleteGoal.complete();
    }
}
