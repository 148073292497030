<section class="container-fluid mt-3" *ngIf="advance">
    <mat-card class="p-5">
        <header class="row">
            <div class="col-6"></div>

            <div class="col-6 text-right">
                <p class="zp-payment_title">Advance</p>

                <p class="zp-refference_due_title">Amount</p>
                <p class="zp-refference_due_value">{{ advance?.amount | currency }}</p>

                <p class="zp-refference_due_title">Balance</p>
                <p class="zp-refference_due_value">
                    {{ (advance?.balance || advance?.balance === 0 ? advance?.balance : advance?.amount) | currency }}
                </p>

                <p class="mb-2">
                    <span>Issuance Date:</span>
                    <span class="zp-w-300">{{ advance?.date | date: 'longDate' }}</span>
                </p>

                <p class="mb-2">
                    <span>Contacts/User:</span>
                    <span class="zp-w-300">{{ advance?.receiver_contact?.display_name }}</span>
                </p>

                <p class="mb-2">
                    <span>Product/Service:</span>
                    <span class="zp-w-300">{{ advance.product ? advance.product.name : '-' }}</span>
                </p>

                <p class="mb-2">
                    <span>Interest Rate:</span>

                    <span class="zp-w-300" *ngIf="advance?.interest_period === 'none'">None</span>
                    <span
                        class="zp-w-300"
                        *ngIf="advance?.interest_period !== 'none' && advance?.interest_rate_type === 'flat'"
                    >
                        {{ advance?.interest_rate_flat | currency }} {{ advance?.interestPeriod?.label }}
                        {{ advance?.interestLimit }}
                    </span>
                    <span
                        class="zp-w-300"
                        *ngIf="advance?.interest_period !== 'none' && advance?.interest_rate_type === 'percent'"
                    >
                        {{ advance?.interest_rate_percent }}% {{ advance?.interestPeriod?.label }}
                        {{ advance?.interestLimit }}
                    </span>
                </p>
            </div>
        </header>

        <div class="d-flex justify-content-between" style="margin-top: 15px">
            <div class="d-flex flex-grow-1 justify-content-end">
                <button
                    [rule]="{lending__manage_advances: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    [disabled]="advance?.balance === 0"
                    mat-raised-button
                    class="ml-2"
                    (click)="confirmPaid()"
                >
                    Record Payment
                </button>
                <button
                    [rule]="{lending__manage_advances: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    [disabled]="advance?.balance === 0"
                    mat-raised-button
                    class="ml-2"
                    (click)="chargeCustomer()"
                >
                    Charge Customer
                </button>
            </div>
        </div>

        <div class="my-3">
            Related Invoices
            <button mat-icon-button (click)="showInvoices = !showInvoices">
                <mat-icon *ngIf="showInvoices">expand_more</mat-icon>
                <mat-icon *ngIf="!showInvoices">chevron_right</mat-icon>
            </button>

            <div *ngIf="showInvoices && dataSource.data && dataSource.data.length === 0">No related invoices.</div>

            <div *ngIf="showInvoices && dataSource.data && dataSource.data.length > 0">
                <table mat-table [dataSource]="dataSource" class="w-100">
                    <ng-container matColumnDef="invoice_id">
                        <th mat-header-cell *matHeaderCellDef>Invoice#</th>
                        <td mat-cell *matCellDef="let element">{{ element.invoice_number || 'N/A' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="invoice_date">
                        <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.invoice_date | dateFromNumber | date: 'mediumDate' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="summary_status">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element">{{ element.summary_status | fromSnakeCase }}</td>
                    </ng-container>

                    <ng-container matColumnDef="total_amount">
                        <th mat-header-cell *matHeaderCellDef style="text-align: end">Total Amount</th>
                        <td mat-cell *matCellDef="let element" style="text-align: end">
                            {{ element.total_amount | currency }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="balance">
                        <th mat-header-cell *matHeaderCellDef style="text-align: end">Balance</th>
                        <td mat-cell *matCellDef="let element" style="text-align: end">
                            {{ element.balance | currency }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedRelatedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedRelatedColumns" class="zp-table-row"></tr>
                </table>
            </div>
        </div>
    </mat-card>
</section>
