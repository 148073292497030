import {merge as observableMerge, Observable, Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {DataSource} from '@angular/cdk/collections';
import {IWildcard} from '@cyberco-nodejs/zipi-typings';
import {RolesService} from '../../modules/account-info/services/roles.service';

@Injectable()
export class CompanyPermissionsSource extends DataSource<IWildcard> {
    public sources: {[key: string]: Subject<{[key: string]: any}[]>} = {
        load: new Subject<{[key: string]: any}[]>()
    };

    private _source: Observable<{[key: string]: any}[]> = observableMerge(
        ...Object.keys(this.sources).map((key) => this.sources[key])
    );
    private _tmp: {[key: string]: any}[] = [];
    constructor(public rolesService: RolesService) {
        super();
        this.load();
    }

    connect() {
        return this.source;
    }

    disconnect() {}

    public load(): Promise<{[key: string]: any}[]> {
        return this.rolesService
            .getAvailableCompanyPermissions()
            .toPromise()
            .then((res) => {
                this._tmp = res.result;
                this.sources.load.next(this._tmp);
                return res;
            });
    }

    public get source(): Observable<{[key: string]: any}[]> {
        if (this._tmp) {
            return this._source.pipe(startWith(this._tmp));
        }
        return this._source;
    }
}
