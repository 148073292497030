import {IProfile} from '@cyberco-nodejs/zipi-typings';
import {GenericEntity} from '../entites/generic.entity';
import {MembershipOrganization} from './membership-organization';

export class ProfileMembership extends GenericEntity {
    public static provideDuesResponsibility() {
        return ['company', 'group', 'contact'];
    }
    id: number | null = null;
    profile_membership_id: number | null = null;
    company_fk_id: number | null = null;
    profile_fk_id: number | null = null;
    membership_organization_fk_id: number | null = null;

    membership_type: string | null = null;
    member_license_number: string | null = null;
    expiration_date: number | null = null;
    dues_responsibility: string | null = null;

    profile: IProfile | null = null;

    public static get dues_responsibility_SET() {
        return {
            company: 'company',
            group: 'group',
            contact: 'contact'
        };
    }

    public static get dues_responsibility_LABELS() {
        return {
            company: 'Company',
            group: 'Group',
            contact: 'Contact'
        };
    }
}
