import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {GroupsSource} from '../../../services/sources/groups.source';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {FeatureFlagsService} from '../../feature-flags/feature-flags.service';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-account-info-toolbar',
    styles: [
        `
            .company-users-toolbar {
                position: fixed;
                z-index: 2;
                left: 0;
                top: 64px;
                height: 60px;
                box-shadow: none;
                border-radius: unset;
                border-bottom: 1px lightgrey solid;
                background-color: white;
            }
            .header-buttons {
                height: 60px;
                position: relative;
                z-index: 2;
                align-items: center;
            }
            .right-buttons,
            .left-buttons {
                height: fit-content;
            }
            .action-icon {
                cursor: pointer;
                color: grey;
                font-weight: bold;
                height: 36px;
            }
            .action-icon:hover {
                color: #afafb3;
            }
            .directory-mat-menu .mat-menu-content {
                max-height: 250px;
            }
        `
    ],
    templateUrl: './account-info-toolbar.component.html'
})
export class AccountInfoToolbarComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() section: string = 'users-licenses';

    // users-licenses
    @Input() profileIdsForDetailsLength: number | undefined;
    @Input() profileIdsToAdd: number[] = [];
    @Output() createUserEmitter = new EventEmitter();
    @Output() changeAllDetailsEmitter = new EventEmitter();
    @Output() bulkActionEmitter = new EventEmitter<string>();
    // divisions
    @Input() createModeDivision: boolean = false;
    @Output() enterCreationModeDivisionEmitter = new EventEmitter();
    // group
    @Input() createModeGroup: boolean = false;
    @Output() enterCreationModeGroupEmitter: EventEmitter<void> = new EventEmitter();
    // roles
    @Output() createCompanyRoleEmitter = new EventEmitter();
    @Input() isEditMode: boolean = false;

    // profiles
    public searchString: UntypedFormControl | undefined;
    public MIN_SYMBOLS_TO_START_SEARCH = 2;

    menuTitle: string | undefined;

    public filterSelectResendEnabledFlag = false;

    constructor(
        public router: Router,
        protected groupSrc: GroupsSource,
        protected featureFlagsService: FeatureFlagsService
    ) {}

    ngOnInit() {
        if (this.router.url.includes('users')) {
            this.menuTitle = 'Profiles';
        } else if (this.router.url.includes('divisions')) {
            this.menuTitle = 'Divisions';
        } else if (this.router.url.includes('groups')) {
            this.menuTitle = 'Groups';
        } else if (this.router.url.includes('roles')) {
            this.menuTitle = 'Roles';
        }

        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.filterSelectResendEnabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'directory:profiles:filter_selectall_resend'
                );
            });
    }

    toggleSelectAllCheckbox(control: MatCheckboxChange) {
        if (control.checked) {
            this.bulkActionEmitter.emit('select_all');
        } else {
            this.bulkActionEmitter.emit('unselect_all');
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.createUserEmitter.complete();
        this.changeAllDetailsEmitter.complete();
        this.bulkActionEmitter.complete();
        this.enterCreationModeDivisionEmitter.complete();
        this.enterCreationModeGroupEmitter.complete();
        this.createCompanyRoleEmitter.complete();
    }
}
