import {Component, Inject, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import moment from 'moment-timezone';
import {FormGroupArray} from '../../../../../../../typings/common';
import {
    IRemainingPayoutsWithAdditionalInfo,
    IDataToBeProcessed,
    IProcessDialogData
} from '../../../../remaining-payouts/types/remaining-payouts.types';
import {PAYMENT_MODES} from '../../../../../../../local-typings';
import {LedgerAccountSelectorComponent} from '../../../../../../shared/components/ledger-account-selector/ledger-account-selector.component';
import {requiredNumberValidator} from '../../../../../../../forms/validators';
import {RemainingPayoutsApiService} from '../../../services/remaining-payouts.api-service';
import {RemainingPayoutsDataService} from '../../../services/remaining-payouts.data-service';
import {cleanCurrencyString, currencyMaskitoOptions} from '@app/utilities/maskito';

@Component({
    selector: 'pay-remaining-payouts-check-only-dialog',
    templateUrl: 'process-remaining-payouts-check-only-dialog.component.html',
    styleUrls: ['./process-remaining-payouts-check-only-dialog.component.scss']
})
export class ProcessRemainingPayoutsCheckOnlyDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @ViewChild('ledgerAccountPicker', {static: true}) public ledgerAccountPicker:
        | LedgerAccountSelectorComponent
        | undefined;

    currencyMaskitoMask = currencyMaskitoOptions;

    paymentModes = PAYMENT_MODES;

    isCombineFeatureDisabled: boolean = false;

    payoutIds: number[] = [];
    fullPayoutsInfo: IRemainingPayoutsWithAdditionalInfo[] = [];

    recordPaymentSettingsGroup: UntypedFormGroup;
    itemsArray: FormGroupArray = this.fb.array([]) as FormGroupArray;
    itemsArrayBuilt: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ProcessRemainingPayoutsCheckOnlyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IProcessDialogData,
        private remainingPayoutsService: RemainingPayoutsApiService,
        private remainingPayoutsDataService: RemainingPayoutsDataService,
        private fb: UntypedFormBuilder
    ) {
        this.recordPaymentSettingsGroup = this.fb.group({
            payment_mode: ['check', [Validators.required]],
            paid_date: [Number(moment().format('YYYYMMDD')), [requiredNumberValidator]],
            ledger_account_id: [null, [Validators.required]],
            is_need_to_combine_payments: [true, []]
        });
    }

    ngOnInit() {
        this.payoutIds = this.data.payoutIds;
        if (this.data.isCombineDisabled) {
            this.isCombineFeatureDisabled = this.data.isCombineDisabled;
            this.recordPaymentSettingsGroup.controls.is_need_to_combine_payments.setValue(false);
        }
        if (this.payoutIds && this.payoutIds.length > 0) {
            this.loadPayoutsInfo(this.payoutIds);
        } else {
            this.close();
        }
    }

    loadPayoutsInfo(payoutIds: Array<number>) {
        this.remainingPayoutsService
            .getRemainingPayoutsInfo(payoutIds)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((payouts) => {
                this.fullPayoutsInfo = payouts;
                this.setupItemsArray();
            });
    }

    setupItemsArray() {
        if (this.fullPayoutsInfo && this.fullPayoutsInfo.length > 0) {
            let preparedItemsForArray: any[] = [];
            if (this.recordPaymentSettingsGroup.controls.is_need_to_combine_payments.value) {
                preparedItemsForArray = this.remainingPayoutsDataService.combinePayouts(this.fullPayoutsInfo);
            } else {
                preparedItemsForArray = this.fullPayoutsInfo;
            }
            this.itemsArray.clear();
            for (const item of preparedItemsForArray) {
                this.itemsArray.push(
                    this.remainingPayoutsDataService.createItem(
                        item,
                        this.recordPaymentSettingsGroup.controls.is_need_to_combine_payments.value
                    )
                );
            }
        }

        this.itemsArrayBuilt = true;
    }

    validateAmount($event: any, payoutGroup: UntypedFormGroup) {
        if (!payoutGroup) {
            return null;
        }
        const inputValue = Number(cleanCurrencyString($event.target.value));
        const availableAmount = payoutGroup.controls.bill_pending_balance.value;

        if (availableAmount < inputValue || (inputValue === 0 && availableAmount !== 0)) {
            payoutGroup.controls.amount.setValidators([Validators.email]);
            payoutGroup.controls.amount.updateValueAndValidity();
            payoutGroup.controls.amount.markAsTouched();
        } else {
            payoutGroup.controls.amount.clearValidators();
            payoutGroup.controls.amount.updateValueAndValidity();
        }
    }

    continue(mode: 'print' | 'create_only') {
        if (this.recordPaymentSettingsGroup.invalid || this.itemsArray.invalid) {
            this.itemsArray.markAllAsTouched();
            this.ledgerAccountPicker!.validateControl();
            this.recordPaymentSettingsGroup.markAllAsTouched();
            return;
        }

        const result: IDataToBeProcessed = {
            processing_mode: 'check',
            after_action: null,
            record_payment_options: this.recordPaymentSettingsGroup.getRawValue(),
            payouts: this.remainingPayoutsDataService.separatePayouts(
                this.itemsArray.getRawValue().map((item: any) => {
                    if (isNaN(item.amount)) {
                        item.amount = Number(cleanCurrencyString(item.amount));
                    }
                    return item;
                })
            )
        };
        if (mode === 'print') {
            result.after_action = 'print';
        }

        this.dialogRef.close(result);
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
