<div class="row content">
    <div class="col-xl-4 left-side">
        <mat-button-toggle-group vertical="true" [value]="activePanel" (change)="onSelectOptions($event)">
            <mat-button-toggle
                *ngFor="let item of reportOptionPanels"
                [value]="item.value"
                (change)="onSelectOptions($event)"
            >
                {{ item.title }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="col-xl-8 right-side">
        <ng-container *ngIf="reportSettings">
            <div *ngIf="activePanel === 'time_period'">
                <app-report-edit-bar-time-period
                    [value]="{
                        time_period: reportSettings.time_period,
                        time_period_start: reportSettings.time_period_start,
                        time_period_end: reportSettings.time_period_end
                    }"
                    (valueChanges)="onValueChanges('time_period', $event)"
                >
                </app-report-edit-bar-time-period>
            </div>

            <div *ngIf="activePanel === 'report_basis'">
                <app-report-edit-bar-button-list
                    [list]="listOfReportBasis"
                    [value]="reportSettings.report_basis"
                    (valueChanges)="onValueChanges('report_basis', $event)"
                >
                </app-report-edit-bar-button-list>
            </div>

            <div *ngIf="activePanel === 'tags'" class="content-item">
                <h4>Select tags</h4>
                <app-report-autocomplete
                    [list]="allTags"
                    [value]="reportSettings.tags"
                    (valueChanges)="onValueChanges('tags', $event)"
                    titleAttribute="title"
                    valueAttribute="tag_id"
                >
                </app-report-autocomplete>
            </div>

            <div *ngIf="activePanel === 'ledger_account_type'" class="content-item">
                <h4>Select Account type</h4>
                <app-report-ledger-account-type
                    [list]="ledgerTypesGroups"
                    [value]="reportSettings.ledger_account_type"
                    (valueChanges)="onValueChanges('ledger_account_type', $event)"
                    titleAttribute="label"
                    groupNameAttribute="group"
                    valueAttribute="value"
                >
                </app-report-ledger-account-type>
            </div>

            <div [hidden]="activePanel !== 'divisions'" class="content-item">
                <h4>Offices</h4>

                <div class="d-flex align-items-center" style="gap: 16px">
                    <span class="title">Include Non-Office Entries:</span>
                    <div class="element">
                        <mat-button-toggle-group
                            [formControl]="includeDivisionFormControl"
                            aria-label="Include Non-Office Entries:"
                        >
                            <mat-button-toggle *ngFor="let item of listOfYesNoAnswer" [value]="item.value">
                                {{ item.title }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>

                <h4 class="mt-2">Select Offices</h4>
                <app-report-edit-bar-entity
                    [value]="reportSettings.divisions"
                    [entityTypeList]="['company_group']"
                    [onlyDivisions]="true"
                    [initialGroupIds]="initialGroupIds"
                    (valueChanges)="onValueChanges('divisions', $event)"
                >
                </app-report-edit-bar-entity>
            </div>

            <div *ngIf="activePanel === 'is_include_reverted_data'" class="content-item">
                <h4>Include reverted data</h4>
                <app-report-edit-bar-button-list
                    [list]="listOfYesNoAnswer"
                    [value]="reportSettings.is_include_reverted_data"
                    (valueChanges)="onValueChanges('is_include_reverted_data', $event)"
                >
                </app-report-edit-bar-button-list>
            </div>
        </ng-container>

        <div *ngIf="report">
            <app-report-edit-bar-action [reportId]="report.id" [isShowNext]="isShowNext" (action)="onAction($event)">
            </app-report-edit-bar-action>
        </div>
    </div>
</div>
