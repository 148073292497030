<section class="bg-white">
    <div class="d-flex flex-wrap w-100" style="padding-top: 20px">
        <div class="d-flex">
            <!------- Search string ------->
            <mat-form-field class="ml-2" style="min-width: 400px">
                <input
                    matInput
                    [formControl]="filterForm!.controls.search"
                    placeholder="Search by 'To/From'"
                    type="text"
                />
            </mat-form-field>
        </div>
        <div class="d-flex align-items-baseline">
            <!------- Date range ------->
            <div class="d-flex">
                <div class="ml-2" style="top: -1px; position: relative; width: 150px">
                    <app-date-picker
                        [placeholder]="'Start Date'"
                        [dateControl]="filterForm!.controls.startDate"
                        [valueType]="'value_as_date'"
                        [isRequired]="false"
                    ></app-date-picker>
                </div>
                <div class="ml-2" style="top: -1px; position: relative; width: 150px">
                    <app-date-picker
                        [placeholder]="'End Date'"
                        [dateControl]="filterForm!.controls.endDate"
                        [valueType]="'value_as_date'"
                        [isRequired]="false"
                    ></app-date-picker>
                </div>
            </div>
            <button type="button" class="ml-2" mat-raised-button (click)="resetData()">Apply Filter</button>
            <button type="button" class="ml-2" mat-raised-button (click)="resetFilters()">Reset</button>
        </div>
    </div>

    <!--    Additional Data Table-->

    <div [ngClass]="{'d-none': !additionalSectionCollapsed}">
        <div class="d-flex pl-2">
            <div
                class="d-flex"
                style="border-bottom: solid 1px #a2a2a2"
                (click)="additionalSectionCollapsed = !additionalSectionCollapsed"
            >
                <span class="sp-cursor-pointer">Processing Transfers</span>
                <span class="ml-2">{{ additionalScrollData.total }}</span>
                <span class="pr-2"><mat-icon class="sp-cursor-pointer">navigate_next</mat-icon></span>
            </div>
        </div>
    </div>

    <div [ngClass]="{'d-none': additionalDataSource.data.length === 0 || additionalSectionCollapsed}">
        <div *ngIf="tabTag === 'completed'" class="d-flex w-100 pl-2" style="border-bottom: solid 1px #a2a2a2">
            <span class="sp-cursor-pointer" (click)="additionalSectionCollapsed = !additionalSectionCollapsed"
                >Processing Transfers</span
            >
            <span class="sp-spacer"></span>
            <span class="pr-2"
                ><mat-icon class="sp-cursor-pointer" (click)="additionalSectionCollapsed = !additionalSectionCollapsed"
                    >close</mat-icon
                ></span
            >
        </div>
        <table
            mat-table
            [dataSource]="additionalDataSource"
            [ngClass]="{'d-none': additionalDataSource.data.length === 0}"
            class="w-100"
        >
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef class="w-15">Date</th>
                <td mat-cell *matCellDef="let element" class="w-15 pr-1" style="padding-left: 24px">
                    <span *ngIf="element.status !== 'completed'">{{
                        element.transfer_creation_date | dateFromNumber | date: 'mediumDate'
                    }}</span>
                    <span *ngIf="element.status === 'completed'">{{
                        element.transaction_completed_date | dateFromNumber | date: 'mediumDate'
                    }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="my_bank">
                <th mat-header-cell *matHeaderCellDef style="width: 28%">My Bank</th>
                <td mat-cell *matCellDef="let element" style="width: 28%" class="px-1">
                    {{ element.self_title }}
                </td>
            </ng-container>

            <ng-container matColumnDef="vendor_title">
                <th mat-header-cell *matHeaderCellDef style="width: 28%">To/From</th>
                <td mat-cell *matCellDef="let element" style="width: 28%" class="px-1">
                    {{ element.vendor_title }}
                </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef class="w-15" style="direction: rtl">
                    <div style="padding-right: 8px; text-align: right">Amount</div>
                </th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1 pr-2">
                    <div style="text-align: right; color: green" *ngIf="element.direction === 'in'; else debitBlock">
                        +{{ element.amount | currency }}
                    </div>
                    <ng-template #debitBlock>
                        <div style="text-align: right; color: darkgray">-{{ element.amount | currency }}</div>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="balance">
                <th mat-header-cell *matHeaderCellDef class="w-15">
                    <div style="text-align: right" class="pl-1">Balance</div>
                </th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1 pr-2">
                    <div style="text-align: right; padding-right: 24px">
                        {{ element.fbo_balance | currency }}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="w-15">
                    <div style="text-align: right" class="pl-1">Status</div>
                </th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1 pr-2">
                    <div style="text-align: right; padding-right: 24px">
                        {{ element.status | fromSnakeCase }}
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="additionalDisplayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: additionalDisplayedColumns"></tr>
        </table>
        <mat-paginator
            #additionalPaginator
            hidePageSize="true"
            [length]="additionalScrollData.total"
            [pageSizeOptions]="additionalPageSizeOptions"
        >
        </mat-paginator>
    </div>

    <!--    [ngStyle]="{height: 'calc(100vh - 230px)'}"-->
    <div *ngIf="additionalDataSource.data.length > 0" style="height: 10px"></div>

    <!--    Main Data Table    -->
    <div [ngClass]="{'d-none': mainDataSource.data.length === 0}" style="margin-bottom: 25px">
        <div *ngIf="tabTag === 'completed'" class="pl-2" style="border-bottom: solid 1px #a2a2a2">
            Completed Transfers
        </div>
        <table
            mat-table
            [dataSource]="mainDataSource"
            [ngClass]="{'d-none': mainDataSource.data.length === 0}"
            class="w-100"
        >
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef class="w-15">Date</th>
                <td mat-cell *matCellDef="let element" class="w-15 pr-1" style="padding-left: 24px">
                    <span *ngIf="element.status !== 'completed'">{{
                        element.transfer_creation_date | dateFromNumber | date: 'mediumDate'
                    }}</span>
                    <span *ngIf="element.status === 'completed'">{{
                        element.transaction_completed_date | dateFromNumber | date: 'mediumDate'
                    }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="my_bank">
                <th mat-header-cell *matHeaderCellDef style="width: 20%">My Bank</th>
                <td mat-cell *matCellDef="let element" style="width: 28%" class="px-1">
                    {{ element.self_title }}
                </td>
            </ng-container>

            <ng-container matColumnDef="vendor_title">
                <th mat-header-cell *matHeaderCellDef style="width: 28%">To/From</th>
                <td mat-cell *matCellDef="let element" style="width: 28%" class="px-1">
                    {{ element.vendor_title }}
                </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef class="w-15" style="direction: rtl">
                    <div style="padding-right: 8px; text-align: right">Amount</div>
                </th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1 pr-2">
                    <div style="text-align: right; color: green" *ngIf="element.direction === 'in'; else debitBlock">
                        +{{ element.amount | currency }}
                    </div>
                    <ng-template #debitBlock>
                        <div style="text-align: right; color: darkgray">-{{ element.amount | currency }}</div>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="balance">
                <th mat-header-cell *matHeaderCellDef class="w-15">
                    <div style="text-align: right" class="pl-1">Balance</div>
                </th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1 pr-2">
                    <div style="text-align: right; padding-right: 24px">
                        {{ element.fbo_balance | currency }}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="w-15">
                    <div style="text-align: right" class="pl-1">Status</div>
                </th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1 pr-2">
                    <div style="text-align: right; padding-right: 24px">
                        {{ element.status | fromSnakeCase }}
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="mainDisplayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: mainDisplayedColumns"></tr>
        </table>
        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            #mainPaginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="mainPageSizeOptions"
        >
        </mat-paginator>
    </div>

    <!------- No values ------->
    <div
        *ngIf="mainDataSource.data.length === 0 && additionalDataSource.data.length === 0"
        class="text-muted m-3 text-center no-items"
    >
        There are no transfers.
    </div>
</section>
