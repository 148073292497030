<div mat-dialog-title>
    <h3>Process{{ closingForDual.value ? '&nbsp;Dual Agency' : '' }} Payout</h3>
    <div class="align-middle" *ngIf="!itemsArray.length">Please wait..</div>
</div>

<mat-dialog-content>
    <div class="d-flex align-items-center">
        <mat-slide-toggle
            class="m-2"
            *ngIf="data.cardType !== 'remaining' && dualDealForm && dualDealForm.getRawValue()"
            [formControl]="closingForDual"
            [disabled]="disallowDualClosing"
        >
            Confirm Payment Received for both sides.
        </mat-slide-toggle>
        <mat-icon
            *ngIf="dualClosingErrors.length > 0"
            style="color: orange"
            matTooltip="{{ dualClosingErrors.join('\n') }}"
            matTooltipClass="multiline-tooltip"
        >
            info
        </mat-icon>
    </div>

    <div *ngFor="let itemGroup of itemsArray.controls; index as idx; first as first">
        <mat-card class="" style="margin: 8px 5px; width: 515px">
            <mat-card-content>
                <div class="w-100" [formGroup]="itemGroup">
                    <div
                        class="d-flex justify-content-between w-100 my-2"
                        style="margin-bottom: 15px"
                        *ngIf="itemGroup.controls.is_invoice.value && itemGroup.controls.connected_number.value"
                    >
                        <div>
                            <span class="font-weight-bold">Invoice#: </span>
                            {{ itemGroup.controls.connected_number.value }}
                        </div>
                        <div>
                            <span class="font-weight-bold">Status: </span>
                            {{ itemGroup.controls.connected_status.value | fromSnakeCase }}
                        </div>
                    </div>

                    <div
                        class="d-flex justify-content-between w-100 my-2"
                        style="margin-bottom: 15px"
                        *ngIf="!itemGroup.controls.is_invoice.value && itemGroup.controls.connected_number.value"
                    >
                        <div>
                            <span class="font-weight-bold">Bill#: </span>
                            {{ itemGroup.controls.connected_number.value }}
                        </div>
                        <div>
                            <span class="font-weight-bold">Status: </span>
                            {{ itemGroup.controls.connected_status.value | fromSnakeCase }}
                        </div>
                    </div>

                    <div class="d-flex" style="margin-bottom: 15px">
                        <div class="w-50" style="text-align: left">
                            <span class="font-weight-bold">From: </span>
                            {{ itemGroup.controls.sender_link_title.value }}
                        </div>
                        <div class="w-50" style="text-align: right">
                            <span class="font-weight-bold">To: </span>
                            {{ itemGroup.controls.receiver_link_title.value }}
                        </div>
                    </div>

                    <div class="d-flex" *ngIf="!itemGroup.controls.connected_number.value">
                        <div class="w-50 mr-2">
                            <app-product-service-selector
                                [productServiceControl]="itemGroup.controls.product_fk_id"
                                [placeholder]="'Product/Service'"
                            >
                            </app-product-service-selector>
                        </div>
                        <div class="w-50 mr-2" *ngIf="itemGroup.get('total_amount').value > 0">
                            <app-date-picker
                                [placeholder]="'Payment Date'"
                                [dateControl]="itemGroup.controls.invoice_date"
                            >
                            </app-date-picker>
                        </div>
                    </div>

                    <div
                        class="d-flex"
                        style="margin-bottom: 10px"
                        *ngIf="itemGroup.controls.is_invoice.value && data.cardType === 'remaining'"
                    >
                        <app-select-pay-to-methods
                            [payToCardControl]="itemGroup.controls.pay_to_card__payment_method_fk_id"
                            [payToBankControl]="itemGroup.controls.pay_to_bank__payment_method_fk_id"
                            [allowedGatewayTypes]="['authorize_net_merchant', 'zipi_financial_business']"
                            [pickerType]="'separated'"
                        >
                        </app-select-pay-to-methods>
                    </div>

                    <!-- record tabs are available for bills -->
                    <div *ngIf="!itemGroup.controls.is_invoice.value">
                        <div
                            *ngIf="!itemGroup.controls.is_pay_later.value && itemGroup.controls.total_amount.value > 0"
                        >
                            <mat-tab-group (selectedTabChange)="tabChanged($event, itemGroup)">
                                <mat-tab label="Money Transfers (EFT)" *ngIf="isMoneyTransfersFeatureFlagEnabled">
                                    <div>
                                        <div
                                            class="d-flex"
                                            style="margin: 15px; color: red"
                                            *ngIf="itemGroup.controls.zipi_financial_disabled"
                                        >
                                            Money Transfers (EFT) is not available.
                                        </div>

                                        <div
                                            style="margin: 15px"
                                            *ngIf="
                                                itemGroup.controls.paid_by__payment_method_fk_id &&
                                                !itemGroup.controls.zipi_financial_disabled
                                            "
                                        >
                                            <div>
                                                <mat-form-field class="w-100">
                                                    <mat-select
                                                        [placeholder]="'Payment Method'"
                                                        formControlName="paid_by__payment_method_fk_id"
                                                    >
                                                        <ng-container *ngFor="let gateway of zipiFinancialGateways">
                                                            <mat-optgroup
                                                                *ngIf="
                                                                    contactAvailableGatewayTypes &&
                                                                    ((gateway.type === 'zipi_financial_business' &&
                                                                        contactAvailableGatewayTypes.zipi_financial_business) ||
                                                                        (gateway.type === 'zipi_financial_business' &&
                                                                            contactAvailableGatewayTypes.zipi_financial_receive_only))
                                                                "
                                                                [label]="gateway.title"
                                                            >
                                                                <ng-container
                                                                    *ngFor="let method of gateway.payment_methods"
                                                                >
                                                                    <mat-option
                                                                        *ngIf="
                                                                            method.type !== 'zipi_financial_credit_card'
                                                                        "
                                                                        [value]="method.payment_method_id"
                                                                        (onSelectionChange)="
                                                                            methodChanged(
                                                                                $event,
                                                                                gateway,
                                                                                method,
                                                                                itemGroup
                                                                            )
                                                                        "
                                                                        [disabled]="
                                                                            gateway.status !== 'active' ||
                                                                            method.status !== 'active' ||
                                                                            !method.related__ledger_account_fk_id
                                                                        "
                                                                    >
                                                                        <span>{{ method.title }}</span>
                                                                    </mat-option>
                                                                </ng-container>
                                                            </mat-optgroup>
                                                        </ng-container>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                            <div
                                                *ngIf="
                                                    itemGroup &&
                                                    itemGroup.controls.paid_by__payment_method_fk_id &&
                                                    itemGroup.controls.paid_by__payment_method_fk_id.value &&
                                                    isVelocityFeatureFlagEnabled
                                                "
                                            >
                                                <div class="d-flex" style="min-height: 66px">
                                                    <div
                                                        class="w-48"
                                                        *ngIf="itemGroup && itemGroup.controls.sender_velocity"
                                                    >
                                                        <mat-form-field class="w-100">
                                                            <mat-select
                                                                [placeholder]="'Service Level'"
                                                                formControlName="sender_velocity"
                                                            >
                                                                <ng-container *ngFor="let type of senderVelocityTypes">
                                                                    <mat-option
                                                                        [value]="type"
                                                                        [disabled]="
                                                                            itemGroup.controls.total_amount.value >=
                                                                                100000 && type === 'instant'
                                                                        "
                                                                    >
                                                                        {{ type | fromSnakeCase }}
                                                                    </mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="d-flex mr-2 align-items-center"
                                                style="min-height: 65.5px"
                                                *ngIf="isScheduledPaymentFeatureFlagEnabled"
                                            >
                                                <div class="d-flex w-50">
                                                    <mat-slide-toggle
                                                        [checked]="isScheduleEnabled"
                                                        (change)="changePayMode($event, itemGroup)"
                                                        >Schedule Payment</mat-slide-toggle
                                                    >
                                                </div>
                                                <div class="w-50" *ngIf="isScheduleEnabled">
                                                    <app-date-picker
                                                        [inputDisabled]="true"
                                                        [minDate]="minScheduleDate"
                                                        [maxDate]="maxScheduleDate"
                                                        [placeholder]="'Pay Date'"
                                                        [dateControl]="itemGroup.controls.scheduled_date"
                                                    >
                                                    </app-date-picker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-tab>
                                <mat-tab label="Print Check">
                                    <div style="margin: 15px">
                                        <app-ledger-account-selector
                                            [types]="['bank']"
                                            [ledgerAccountControl]="itemGroup.controls.ledger_account_id"
                                            [placeholder]="
                                                isCompanySender(itemGroup.controls.sender_contact_id.value)
                                                    ? 'Paid through'
                                                    : 'Deposit to'
                                            "
                                        >
                                        </app-ledger-account-selector>
                                        <mat-form-field class="w-100">
                                            <input
                                                matInput
                                                type="text"
                                                formControlName="check_number"
                                                placeholder="Check#"
                                            />
                                        </mat-form-field>
                                        <mat-form-field class="w-100">
                                            <textarea matInput type="text" formControlName="memo" placeholder="Memo">
                                            </textarea>
                                        </mat-form-field>
                                    </div>
                                </mat-tab>
                                <mat-tab label="Record Payment">
                                    <div style="margin: 15px">
                                        <div class="d-flex">
                                            <mat-form-field class="w-50 mr-2">
                                                <mat-select formControlName="payment_mode">
                                                    <ng-container *ngFor="let mode of paymentModes">
                                                        <mat-option [value]="mode.slug" *ngIf="mode.is_selectable">
                                                            <span>{{ mode.title }}</span>
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                                <mat-placeholder>Payment Mode</mat-placeholder>
                                            </mat-form-field>

                                            <app-ledger-account-selector
                                                class="w-50"
                                                [ledgerAccountControl]="itemGroup.controls.ledger_account_id"
                                                [placeholder]="
                                                    isCompanySender(itemGroup.controls.sender_contact_id.value)
                                                        ? 'Paid through'
                                                        : 'Deposit to'
                                                "
                                            >
                                            </app-ledger-account-selector>
                                        </div>

                                        <div *ngIf="itemGroup.controls.payment_mode.value === 'check'">
                                            <mat-form-field class="w-100">
                                                <input
                                                    matInput
                                                    type="text"
                                                    formControlName="check_number"
                                                    placeholder="Check#"
                                                />
                                            </mat-form-field>
                                            <mat-form-field class="w-100">
                                                <textarea
                                                    matInput
                                                    type="text"
                                                    formControlName="memo"
                                                    placeholder="Memo"
                                                >
                                                </textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>

                    <!-- record payment checkbox is available for remaining invoices -->
                    <div
                        class="d-flex"
                        *ngIf="itemGroup.controls.is_invoice.value && itemGroup.controls.is_record_payment"
                    >
                        <div class="checkbox-area">
                            <mat-checkbox formControlName="is_record_payment">Also record payment</mat-checkbox>
                        </div>
                    </div>

                    <!-- payment form is available for invoices -->
                    <div
                        *ngIf="
                            itemGroup.controls.is_invoice.value &&
                            ((itemGroup.controls.is_record_payment && itemGroup.controls.is_record_payment.value) ||
                                !itemGroup.controls.is_record_payment)
                        "
                    >
                        <div class="d-flex" *ngIf="itemGroup.get('total_amount').value > 0">
                            <mat-form-field class="w-50 mr-2">
                                <mat-select formControlName="payment_mode">
                                    <ng-container *ngFor="let mode of paymentModes">
                                        <mat-option [value]="mode.slug" *ngIf="mode.is_selectable">
                                            <span>{{ mode.title }}</span>
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                                <mat-placeholder>Payment Mode</mat-placeholder>
                            </mat-form-field>
                            <mat-form-field class="w-50" *ngIf="itemGroup.controls.payment_mode.value === 'check'">
                                <input matInput type="text" formControlName="check_number" placeholder="Check#" />
                            </mat-form-field>
                            <div class="w-50" *ngIf="itemGroup.controls.payment_mode.value !== 'check'">
                                <app-ledger-account-selector
                                    class="w-100"
                                    [ledgerAccountControl]="itemGroup.controls.ledger_account_id"
                                    [placeholder]="
                                        isCompanySender(itemGroup.controls.sender_contact_id.value)
                                            ? 'Paid through'
                                            : 'Deposit to'
                                    "
                                >
                                </app-ledger-account-selector>
                            </div>
                        </div>
                        <div class="d-flex" *ngIf="itemGroup.get('total_amount').value > 0">
                            <div class="w-50 mr-2" *ngIf="itemGroup.get('payment_mode').value === 'check'">
                                <app-ledger-account-selector
                                    class="w-100"
                                    [ledgerAccountControl]="itemGroup.controls.ledger_account_id"
                                    [placeholder]="
                                        isCompanySender(itemGroup.controls.sender_contact_id.value)
                                            ? 'Paid through'
                                            : 'Deposit to'
                                    "
                                >
                                </app-ledger-account-selector>
                            </div>
                        </div>

                        <div class="d-flex">
                            <mat-form-field class="w-100">
                                <textarea matInput type="text" formControlName="reference" placeholder="Reference">
                                </textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div *ngIf="data.cardType !== 'remaining' && !closingForDual.value">
                        <div class="pt-3" style="text-align: right">
                            Total:
                            {{ data.dealFormGroup.getRawValue().company_calculation.commission.amount | currency }}
                        </div>
                        <div class="" style="text-align: right" *ngIf="brokerReleasesAmount">
                            Deposits Released: {{ brokerReleasesAmount | currency }}
                        </div>
                        <div class="" style="text-align: right">
                            Payments to Others:
                            {{
                                data.dealFormGroup.getRawValue().company_calculation.commission.amount -
                                    itemGroup.controls.amount.value | currency
                            }}
                        </div>
                        <div class="pb-3" style="text-align: right">
                            Payments Received: {{ itemGroup.controls.amount.value - brokerReleasesAmount | currency }}
                        </div>
                    </div>
                    <div *ngIf="data.cardType !== 'remaining' && closingForDual.value">
                        <div class="d-flex">
                            <div>
                                <h3>{{ dealTypesMap[dealForm!.getRawValue()!.client_type] }}</h3>
                                <div class="" style="text-align: right">
                                    Total:
                                    {{
                                        data.dealFormGroup.getRawValue().company_calculation.commission.amount
                                            | currency
                                    }}
                                </div>
                                <div class="" style="text-align: right" *ngIf="brokerReleasesAmount">
                                    Deposits Released: {{ brokerReleasesAmount | currency }}
                                </div>
                                <div class="" style="text-align: right">
                                    Payments to Others:
                                    {{
                                        data.dealFormGroup.getRawValue().company_calculation.commission.amount -
                                            itemGroup.controls.amount.value | currency
                                    }}
                                </div>
                                <div class="pb-3" style="text-align: right">
                                    Payments Received:
                                    {{ itemGroup.controls.amount.value - brokerReleasesAmount | currency }}
                                </div>
                            </div>
                            <span class="sp-spacer"></span>
                            <div>
                                <h3>{{ dealTypesMap[dualDealForm!.getRawValue()!.client_type] }}</h3>
                                <div class="" style="text-align: right">
                                    Total:
                                    {{
                                        dualDealForm!.getRawValue()!.company_calculation!.commission!.amount | currency
                                    }}
                                </div>
                                <div class="" style="text-align: right" *ngIf="dualDealBrokerReleasesAmount">
                                    Deposits Released: {{ dualDealBrokerReleasesAmount | currency }}
                                </div>
                                <div class="" style="text-align: right">
                                    Payments to Others:
                                    {{
                                        dualDealForm!.getRawValue()!.company_calculation!.commission!.amount! -
                                            dualTransfers![0]!.amount! | currency
                                    }}
                                </div>
                                <div class="pb-3" style="text-align: right">
                                    Payments Received:
                                    {{ dualTransfers![0]!.amount! - dualDealBrokerReleasesAmount! | currency }}
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <span class="sp-spacer"></span>
                            <div>
                                <h3>Total</h3>
                                <div class="" style="text-align: right">
                                    Total:
                                    {{
                                        data!.dealFormGroup!.getRawValue()!.company_calculation!.commission!.amount +
                                            dualDealForm!.getRawValue()!.company_calculation!.commission!.amount
                                            | currency
                                    }}
                                </div>
                                <div
                                    class=""
                                    style="text-align: right"
                                    *ngIf="brokerReleasesAmount || dualDealBrokerReleasesAmount"
                                >
                                    Deposits Released:
                                    {{ brokerReleasesAmount + dualDealBrokerReleasesAmount | currency }}
                                </div>
                                <div class="" style="text-align: right">
                                    Payments to Others:
                                    {{
                                        data!.dealFormGroup!.getRawValue()!.company_calculation!.commission!.amount! -
                                            itemGroup!.controls.amount!.value! +
                                            (dualDealForm!.getRawValue()!.company_calculation!.commission!.amount! -
                                                dualTransfers![0]!.amount!) | currency
                                    }}
                                </div>
                                <div class="pb-3" style="text-align: right">
                                    Payments Received:
                                    {{
                                        itemGroup!.controls.amount!.value! -
                                            brokerReleasesAmount! +
                                            (dualTransfers![0]!.amount! - dualDealBrokerReleasesAmount!) | currency
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <div [formGroup]="itemGroup" *ngIf="!itemGroup.controls.is_invoice.value">
            <div class="d-flex" style="margin: 15px">
                <mat-form-field class="w-100">
                    <textarea matInput type="text" formControlName="reference" placeholder="Reference"> </textarea>
                </mat-form-field>
            </div>

            <div class="d-flex" *ngIf="data.cardType === 'remaining' && itemGroup.controls.releases_amount.value > 0">
                <span class="sp-spacer"></span>
                <div class="" style="text-align: right">
                    <mat-checkbox formControlName="apply_releases">Apply Deposit Releases Amount:</mat-checkbox>
                    {{ itemGroup.controls.releases_amount.value | currency }}
                </div>
            </div>

            <div class="d-flex">
                <div class="checkbox-area" *ngIf="itemGroup.controls.total_amount.value > 0">
                    <mat-checkbox formControlName="is_pay_later">Skip or Pay Later</mat-checkbox>
                </div>

                <span class="sp-spacer"></span>
                <div class="" style="text-align: right" *ngIf="data.cardType === 'remaining'">
                    Amount: {{ itemGroup.controls.total_amount.value | currency }}
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex mb-2" *ngIf="itemsArray.length">
    <span class="sp-spacer"></span>
    <button mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="itemsArray.invalid" (click)="saveForm()" tabindex="1">
        Save & Proceed
    </button>
</mat-dialog-actions>
