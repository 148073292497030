import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {ILedgerAccount, IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {ZipiFinancialTransferService} from '../../../../../../../services/api/finance/zipi-financia-transfer.service';
import {CompanyGatewayService} from '../../../../../../../services/api/finance/company-gateway.service';
import {takeUntil} from 'rxjs/operators';
import {LedgerAccountService} from '../../../../../../../services/api/finance/ledger-accounts.service';
import {PaymentMethodsService} from '../../../../../../profile/services/payment-methods.service';
import {FEE_COMMISSION_SCENARIOS} from '../../../../../../../local-typings';

@Component({
    selector: 'app-zipi-fin-fee-schedule-dialog',
    templateUrl: './zipi-fin-fee-schedule-dialog.component.html',
    styleUrls: ['./zipi-fin-fee-schedule-dialog.component.scss']
})
export class ZipiFinFeeScheduleDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    feeScenarios = FEE_COMMISSION_SCENARIOS;

    paymentGatewayId: number | undefined;
    overrides: any;
    incomingFeeOverrides:
        | {
              standard_fee_percentage: number | null;
              standard_fee_cap: number | null;
              instant_fee_percentage: number | null;
              instant_fee_cap: number | null;
              book_transfer_fee_percentage: number | null;
              book_transfer_fee_cap: number | null;
              credit_card_fee_percentage: number | null;
          }
        | undefined;
    outgoingFeeOverrides:
        | {
              standard_fee_percentage: number | null;
              standard_fee_cap: number | null;
              instant_fee_percentage: number | null;
              instant_fee_cap: number | null;
              book_transfer_fee_percentage: number | null;
              book_transfer_fee_cap: number | null;
              credit_card_fee_percentage: number | null;
          }
        | undefined;

    isLoading: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ZipiFinFeeScheduleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {gateway_id: number},
        private fb: UntypedFormBuilder,
        private zipiFinancialTransferService: ZipiFinancialTransferService,
        private companyGatewayService: CompanyGatewayService
    ) {}

    ngOnInit() {
        this.paymentGatewayId = this.data.gateway_id;

        if (this.paymentGatewayId) {
            this.companyGatewayService
                .getZipiFinancialGatewayFee(this.paymentGatewayId, this.feeScenarios)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((feeInfo) => {
                    this.overrides = this._mutateOverrides(feeInfo);
                    this.isLoading = true;
                    // this.incomingFeeOverrides = this._mutateOverrideValues(feeInfo.incoming, 'view');
                    // this.outgoingFeeOverrides = this._mutateOverrideValues(feeInfo.outgoing, 'view');
                });
        }
    }

    _mutateOverrides(overrides: any) {
        for (const scenario of this.feeScenarios) {
            if (overrides[scenario]) {
                if (overrides[scenario].incoming) {
                    overrides[scenario].incoming = this._mutateOverrideValues(overrides[scenario].incoming, 'view');
                }
                if (overrides[scenario].outgoing) {
                    overrides[scenario].outgoing = this._mutateOverrideValues(overrides[scenario].outgoing, 'view');
                }
            }
        }
        return overrides;
    }

    _mutateOverrideValues(override: {[key: string]: any}, type: 'view' | 'store') {
        const result: {
            standard_fee_percentage: number | null;
            standard_fee_cap: number | null;
            instant_fee_percentage: number | null;
            instant_fee_cap: number | null;
            book_transfer_fee_percentage: number | null;
            book_transfer_fee_cap: number | null;
            credit_card_fee_percentage: number | null;
        } = {
            standard_fee_percentage: null,
            standard_fee_cap: null,
            instant_fee_percentage: null,
            instant_fee_cap: null,
            book_transfer_fee_percentage: null,
            book_transfer_fee_cap: null,
            credit_card_fee_percentage: null
        };
        if (type === 'view') {
            result.standard_fee_percentage = this._modifyValueForView(override.standard_fee_percentage, 3);
            result.standard_fee_cap = this._modifyValueForView(override.standard_fee_cap, 2);
            result.instant_fee_percentage = this._modifyValueForView(override.instant_fee_percentage, 3);
            result.instant_fee_cap = this._modifyValueForView(override.instant_fee_cap, 2);
            result.book_transfer_fee_percentage = this._modifyValueForView(override.book_transfer_fee_percentage, 3);
            result.book_transfer_fee_cap = this._modifyValueForView(override.book_transfer_fee_cap, 2);
            result.credit_card_fee_percentage = this._modifyValueForView(override.credit_card_fee_percentage, 3);
        } else {
            result.standard_fee_percentage = this._modifyValueForStore(override.standard_fee_percentage, 3);
            result.standard_fee_cap = this._modifyValueForStore(override.standard_fee_cap, 2);
            result.instant_fee_percentage = this._modifyValueForStore(override.instant_fee_percentage, 3);
            result.instant_fee_cap = this._modifyValueForStore(override.instant_fee_cap, 2);
            result.book_transfer_fee_percentage = this._modifyValueForStore(override.book_transfer_fee_percentage, 3);
            result.book_transfer_fee_cap = this._modifyValueForStore(override.book_transfer_fee_cap, 2);
            result.credit_card_fee_percentage = this._modifyValueForStore(override.credit_card_fee_percentage, 3);
        }
        return result;
    }

    _modifyValueForView(value: number, position: number) {
        let stringAmount = String(value);
        const parts = stringAmount.split('');
        if (position === 2) {
            if (parts.length === 1) {
                parts.splice(-position, 0, '.0');
            } else {
                parts.splice(-position, 0, '.');
            }
        } else {
            if (parts.length === 1) {
                // 1 => .001
                parts.splice(-position, 0, '.00');
            } else if (parts.length === 2) {
                // 12 => .012
                parts.splice(-position, 0, '.0');
            } else {
                // 123 => .123, 1234 => 1.234
                parts.splice(-position, 0, '.');
            }
        }
        stringAmount = parts.join('');
        return Number(stringAmount);
    }

    _modifyValueForStore(value: number, position: number) {
        let stringAmount = String(value);
        if (stringAmount.match(/\./)) {
            const parts = stringAmount.split(/\./);
            stringAmount = parts[0];
            if (position === 2) {
                if (parts[1].length === 1) {
                    stringAmount += parts[1] + '0';
                } else if (parts[1].length === 2) {
                    stringAmount += parts[1];
                } else {
                    return null;
                }
            } else {
                if (parts[1].length === 1) {
                    stringAmount += parts[1] + '00';
                } else if (parts[1].length === 2) {
                    stringAmount += parts[1] + '0';
                } else if (parts[1].length === 3) {
                    stringAmount += parts[1];
                } else {
                    return null;
                }
            }
        } else {
            if (position === 2) {
                stringAmount += '00';
            } else {
                stringAmount += '000';
            }
        }

        return Number(stringAmount);
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
