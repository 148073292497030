import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {firstValueFrom, Subject, takeUntil} from 'rxjs';
import {UntypedFormBuilder, FormGroup, Validators} from '@angular/forms';
import {CompanyGatewayService} from '../../../../../../../../../services/api/finance/company-gateway.service';
import {NotificationsService} from 'angular2-notifications';
import {SessionService} from '../../../../../../../../../services/session.service';
import {GatewayTypes, IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-zipi-financial-tpp-customer-setup',
    templateUrl: './zipi-financial-tpp-customer-setup.component.html',
    styleUrls: ['./zipi-financial-tpp-customer-setup.component.scss']
})
export class ZipiFinancialTppCustomerSetupComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() gatewayType: GatewayTypes | undefined;
    @Input() operatingGateways: IPaymentGateway[] | undefined = [];
    @Output() reloadAndShowFinalPopup = new EventEmitter();

    availableOperatingGateways: IPaymentGateway[] = [];

    isButtonLock: boolean = false;

    constructor(
        private companyGatewayService: CompanyGatewayService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        public sessionService: SessionService,
        public dialog: MatDialog
    ) {}

    ngOnInit() {}

    async createTppGateway() {
        this.isButtonLock = true;

        const gatewayData = {
            customer: null,
            customer_type: null,
            title: 'Credit Card',
            driver_type: 'tpp',
            gateway_type: this.gatewayType,
            connect_to_gateway: null,
            connect_to_cloud_bank: null
        } as any;
        this.createPaymentGateway(gatewayData);
    }

    async createPaymentGateway(customerData: Partial<IPaymentGateway>) {
        const gateway = await firstValueFrom(this.companyGatewayService.createZipiFinancialGateway(customerData));

        if (gateway) {
            this.notificationsService.success(`Customer Created`);
            this.notificationsService.success(`Settings saved`);
            this.reloadAndShowFinalPopup.emit(null);
        } else {
            this.isButtonLock = false;
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
