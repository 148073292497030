<button mat-icon-button [matMenuTriggerFor]="userMenu">
    <img
        *ngIf="authService.currentFirebaseUser?.photoURL"
        class="user-avatar"
        id="user-menu-photo"
        src="{{ authService.currentFirebaseUser?.photoURL }}"
    />

    <mat-icon *ngIf="!authService.currentFirebaseUser?.photoURL" class="nav-icon" id="user-menu-icon"
        >account_circle
    </mat-icon>
</button>

<mat-menu #userMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="menu mat-elevation-z3">
    <div class="menu-panel">
        <a href="https://bookssupport.skyslope.com/hc/en-us" mat-menu-item target="_blank">Help Center</a>
        <a *ngIf="!isProduction" mat-menu-item (click)="showAddonHash()">*Show my hash*</a>
        <a mat-menu-item (click)="showAccount()">Login / Profile</a>
        <a [disabled]="!sessionService.profile?.company" mat-menu-item [routerLink]="['/profile/addons']">Apps</a>
        <a href="https://skyslope.com/privacy-policy/" mat-menu-item target="_blank">Privacy Policy</a>
        <a href="https://skyslope.com/terms-conditions/" mat-menu-item target="_blank">Terms of Use</a>
        <a mat-menu-item (click)="logout()">Logout</a>
    </div>
</mat-menu>
