import {GenericEntity} from '../../../../entites/generic.entity';
import {Group} from '../../../../models/group';
import {CompensationProfileModel} from './compensation-profile.model';
import {Profile} from '../../../../models/profile';

export interface IEntityCompensationProfilesListItem {
    title: string | null;
    type: 'profile' | 'company_group' | null;
    compensationProfiles: CompensationProfileModel[];
}

export class EntityCompensationProfilesListItem extends GenericEntity implements IEntityCompensationProfilesListItem {
    title: string | null = null;
    type: 'profile' | 'company_group' | null = null;
    compensationProfiles: CompensationProfileModel[] = [];

    static get type_SET() {
        return {
            profile: 'profile',
            company_group: 'company_group'
        };
    }
}
