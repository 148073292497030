import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-confirm',
    templateUrl: 'after-sign-up-dialog.component.html',
    styles: [
        `
            .mat-dialog-content {
                overflow-wrap: break-word;
                white-space: pre-wrap;
            }
        `
    ]
})
export class AfterSignUpDialogComponent implements OnInit {
    counter: number = 5;
    isCounterStopped: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<AfterSignUpDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.startCountDown();
    }

    async startCountDown() {
        while (this.counter > 0 && !this.isCounterStopped) {
            await this.delay(1000);
            this.counter--;
        }

        this.goToLogin();
    }

    goToLogin() {
        this.isCounterStopped = true;
        this.dialogRef.close(true);
    }

    async delay(ms: number) {
        return new Promise((resolve) => setTimeout(() => resolve(true), ms));
    }
}
