import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
    IDealDeposit,
    IDealDepositRelease,
    IDealDepositRequest,
    IFinancialTransfer,
    IInvoice,
    IPaymentReceived,
    IPaymentReceivedMadeRequestObject
} from '@cyberco-nodejs/zipi-typings';
import {ServiceRequester} from '../../../../services/service.requester';
import {IContextConnectedBill, IContextConnectedInvoice} from '@app/modules/deals/modules/all-deals/types/deals.types';

@Injectable()
export class DealFinancialApiService {
    private url = '/finance/transfer/';
    private depositUrl = '/finance/deal-deposit/';

    constructor(protected requester: ServiceRequester) {}

    createDealInvoiceTransfer(data: any, id: number): Observable<IInvoice> {
        return this.requester.makeMsCall$(this.url + id + '/invoice', 'POST', 'shipp', data);
    }

    createDealCompanyEscrowInvoiceTransfer(data: any, transferId: number): Observable<IInvoice> {
        return this.requester.makeMsCall$(this.url + transferId + '/escrow-invoice', 'POST', 'shipp', data);
    }

    revertFinanceEntityForTransfers(transfers: {
        companyTransfers: Array<number>;
        agentTransfers: Array<number>;
    }): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + 'revert', 'POST', 'shipp', transfers);
    }

    createDealDeductTransfer(invoiceId: number, data: any): Observable<Array<any>> {
        return this.requester.makeMsCall$(this.url + invoiceId + '/deduct', 'POST', 'shipp', data);
    }

    disconnectFinancialEntity(transferId: number, requestData: any) {
        return this.requester.makeMsCall$(
            this.url + `${transferId}/disconnect-fin-entity`,
            'PUT',
            'shipp',
            requestData
        );
    }

    getDealDepositRequests(dealId: number): Observable<IDealDepositRequest[]> {
        return this.requester.makeMsCall$(this.depositUrl + `${dealId}/request`, 'GET', 'shipp');
    }

    getRequestByHash(requestHash: string): Observable<IDealDepositRequest> {
        return this.requester.makeMsCall$(`/public/deal-deposit/deposit-request/${requestHash}`, 'GET', 'shipp');
    }

    getPayloadSettings(receiverMethodId: number, depositRequestId: number): Observable<{token: string}> {
        return this.requester.makeMsCall$(
            `/public/deal-deposit/deposit-request/${depositRequestId}/payload-settings/${receiverMethodId}`,
            'GET',
            'shipp'
        );
    }

    publicReleasePayloadCoPay(payObj: any): Observable<{error: string}> {
        return this.requester.makeMsCall$(
            `/public/deal-deposit/deposit-request/pay/payload-co`,
            'POST',
            'shipp',
            payObj
        );
    }

    getDealDepositReleases(dealId: number): Observable<IDealDepositRelease[]> {
        return this.requester.makeMsCall$(this.depositUrl + `${dealId}/release`, 'GET', 'shipp');
    }

    getDealDepositRelease(releaseId: number): Observable<IDealDepositRelease> {
        return this.requester.makeMsCall$(this.depositUrl + `release/${releaseId}`, 'GET', 'shipp');
    }

    getDealDepositAmounts(dealId: number): Observable<IDealDeposit> {
        return this.requester.makeMsCall$(this.depositUrl + `${dealId}/amounts`, 'GET', 'shipp');
    }

    resendDealDepositRequests(depositRequestId: number, resendSettings: any): Observable<boolean> {
        return this.requester.makeMsCall$(
            this.depositUrl + `${depositRequestId}/resend`,
            'POST',
            'shipp',
            resendSettings
        );
    }

    createDealDepositRequest(dealId: number, requestData: any): Observable<IDealDepositRequest> {
        return this.requester.makeMsCall$(this.depositUrl + dealId + '/request', 'POST', 'shipp', requestData);
    }

    deleteDealDepositRequests(depositRequestId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.depositUrl + depositRequestId + '/request', 'DELETE', 'shipp');
    }

    createDealDepositPayment(
        requestId: number,
        paymentData: IPaymentReceivedMadeRequestObject
    ): Observable<IPaymentReceived> {
        return this.requester.makeMsCall$(this.depositUrl + requestId + '/payment', 'POST', 'shipp', paymentData);
    }

    deleteDealDepositPayment(depositPaymentId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.depositUrl + depositPaymentId + '/payment', 'DELETE', 'shipp');
    }

    createRelease(releaseData: any): Observable<{release: IDealDepositRelease; transfer: IFinancialTransfer}> {
        return this.requester.makeMsCall$(this.depositUrl + 'release', 'POST', 'shipp', releaseData);
    }

    deleteDealDepositRelease(DepositReleaseId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.depositUrl + DepositReleaseId + '/release', 'DELETE', 'shipp');
    }

    createPaymentMadeForRelease(releaseId: number, paymentData: any): Observable<boolean> {
        return this.requester.makeMsCall$(this.depositUrl + releaseId + '/payment-made', 'POST', 'shipp', paymentData);
    }

    createJournalForRelease(releaseId: number, paymentData: any): Observable<boolean> {
        return this.requester.makeMsCall$(this.depositUrl + releaseId + '/journal', 'POST', 'shipp', paymentData);
    }

    calculateBalances(dealId: number): Observable<any> {
        return this.requester.makeMsCall$('/deal-deposit/' + dealId + '/calculate-amounts', 'GET', 'zipi');
    }

    getDealsFinancialEntities(data: {
        bill_ids: Array<number>;
        invoice_ids: Array<number>;
    }): Observable<{bills: IContextConnectedBill[]; invoices: IContextConnectedInvoice[]}> {
        return this.requester.makeMsCall$(this.url + 'bills-invoices', 'POST', 'shipp', data);
    }
}
