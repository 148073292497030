<div class="row content">
    <div class="col-xl-4 left-side">
        <mat-button-toggle-group vertical="true" [value]="activePanel" (change)="onSelectOptions($event)">
            <mat-button-toggle
                *ngFor="let item of reportOptions"
                [value]="item.value"
                (change)="onSelectOptions($event)"
            >
                {{ item.title }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="col-xl-8 right-side">
        <ng-container *ngIf="reportSettings">
            <div *ngIf="activePanel === 'user_groups'" class="m-2">
                <h4>Participants</h4>
                <app-report-edit-bar-entity
                    [value]="reportSettings.user_in_group"
                    [entityTypeList]="['individual', 'individuals_in_group']"
                    (valueChanges)="onValueChanges('user_in_group', $event)"
                >
                </app-report-edit-bar-entity>
            </div>

            <div *ngIf="activePanel === 'recurring_invoices'" class="m-2">
                <ng-container *ngIf="listOfRecurringInvoices.length">
                    <button mat-stroked-button style="width: fit-content; margin: 10px" [matMenuTriggerFor]="menu">
                        <mat-icon>add</mat-icon>
                        Add Recurring Invoice
                    </button>
                    <mat-menu #menu="matMenu" yPosition="below">
                        <ng-container *ngFor="let recurringInvoice of listOfRecurringInvoices">
                            <button mat-menu-item (click)="addRecurringInvoice(recurringInvoice)">
                                {{ recurringInvoice.title }} {{ recurringInvoice.total_amount | currency }} ({{
                                    recurringInvoice.frequency
                                }}
                                {{ recurringInvoice.period }})
                            </button>
                        </ng-container>
                    </mat-menu>
                </ng-container>

                <div *ngIf="!listOfRecurringInvoices.length">
                    <span
                        >You don't have any recurring invoice. Please
                        <a [routerLink]="['/sales/invoices/recurring']"> add</a></span
                    >
                </div>
                <div style="min-height: 50px">
                    <div *ngFor="let recurringInvoice of listOfPickedRecurringInvoices" style="display: flex">
                        <span style="padding-top: 20px; min-width: fit-content">
                            {{ recurringInvoice.recurringInvoice.title }}
                            {{ recurringInvoice.recurringInvoice.total_amount | currency }}
                        </span>
                        <div style="display: flex; justify-content: flex-end; width: 100%">
                            <div class="col-xl-6" style="max-width: 150px">
                                <app-date-picker
                                    class="spaced-at-right w-100"
                                    [dateControl]="dateControlObj[recurringInvoice.id]"
                                    [floatLabel]="'never'"
                                    [minDate]="formatDateFromIntToDate(recurringInvoice.recurringInvoice.start_on)"
                                    [maxDate]="formatDateFromIntToDate(recurringInvoice.recurringInvoice.end_on)"
                                    [disableDateRules]="{
                                        frequency: recurringInvoice.recurringInvoice.frequency,
                                        start_on: moment(
                                            formatDateFromIntToDate(recurringInvoice.recurringInvoice.start_on)
                                        ),
                                        end_on: moment(
                                            formatDateFromIntToDate(recurringInvoice.recurringInvoice.end_on)
                                        ),
                                        period: recurringInvoice.recurringInvoice.period
                                    }"
                                    [dateClassRules]="{
                                        frequency: recurringInvoice.recurringInvoice.frequency,
                                        start_on: moment(
                                            formatDateFromIntToDate(recurringInvoice.recurringInvoice.start_on)
                                        ),
                                        end_on: moment(
                                            formatDateFromIntToDate(recurringInvoice.recurringInvoice.end_on)
                                        ),
                                        period: recurringInvoice.recurringInvoice.period
                                    }"
                                    (dateChangeEvent)="datePickerChange(recurringInvoice, $event)"
                                ></app-date-picker>
                            </div>
                            <div style="padding-top: 12px; padding-right: 10px">
                                <button mat-icon-button (click)="deleteRecurringInvoice(recurringInvoice)">
                                    <mat-icon style="margin-top: 14px; width: 30px; height: 30px; color: #9e9e9e">
                                        clear
                                    </mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <div *ngIf="report">
            <app-report-edit-bar-action [reportId]="report.id" [isShowNext]="isShowNext" (action)="onAction($event)">
            </app-report-edit-bar-action>
        </div>
    </div>
</div>
