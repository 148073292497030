<section class="bg-white">
    <!------- Header ------->
    <header class="d-flex align-items-center px-3 list-header">
        <h3 class="m-0 mr-1 d-flex align-items-end">Reference Documents</h3>
    </header>

    <!------- List of customer credits ------->
    <div class="table-background">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="credit_note_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Date</th>
                <td mat-cell *matCellDef="let element" class="w-10">
                    {{ element.credit_note_date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="customer_contact">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25 pl-1">Vendor</th>
                <td mat-cell *matCellDef="let element" class="w-25 px-1">
                    {{ element.customer_contact?.display_name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="credit_note_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25 pl-1">Vendor Credit#</th>
                <td mat-cell *matCellDef="let element" class="w-25 px-1">
                    {{ element.credit_note_number || 'N/A' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Reference Number</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.reference }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Status</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <span *ngIf="element.status" [ngStyle]="{color: statusColor[element.status]}">
                        {{ element.status | titlecase }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="total_amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 table-header-align-right">Amount</th>
                <td mat-cell *matCellDef="let element" class="w-10 pr-3 text-right">
                    {{ element.total_amount | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="balance">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 table-header-align-right">Balance</th>
                <td mat-cell *matCellDef="let element" class="w-10 text-right" style="padding-right: 40px">
                    {{ element.balance | currency }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [routerLink]="row.credit_note_id"
                class="zp-table-row"
            ></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div class="text-muted m-3 text-center no-items" *ngIf="dataSource.data && dataSource.data.length === 0">
            There are no source documents.
        </div>
    </div>
</section>
