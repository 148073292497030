import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {routing} from './account-info.routing';
import {IndexComponent} from './index/index.component';
import {UsersLicensesComponent} from './users-licenses/users-licenses.component';
import {FilterPipe} from './users-licenses/filter.pipe';
import {GeneralSettingsComponent} from './settings/tabs/tab1-general/general-settings.component';
import {EditUserComponent} from './users-licenses/edit-user/edit-user.component';
import {RadioListComponent} from './users-licenses/edit-user/radio-list.component/radio-list.component';
import {RadioListItemComponent} from './users-licenses/edit-user/radio-list.component/radio-list-item.component/radio-list-item.component';
import {RbacModule} from '../rbac/rbac.module';
import {SharedModule} from '../shared/shared.module';
import {UserDeleteDialogComponent} from './users-licenses/user-delete.dialog';
import {DeleteRoleConfirmDialogComponent} from './users-licenses/company-roles-management/delete-role-confirm.dialog';
import {UserInfoDialogComponent} from './users-licenses/user-info.dialog';
import {ChangePlanDialogComponent} from './dialogs/change-plan.dialog.component';
import {ChangeMaxUsersDialogComponent} from './dialogs/change-max-users.dialog.component';
import {UsersAndLicencesApiService} from './users-licenses/users-and-licences.api.service';
import {EditUserLicenseComponent} from './users-licenses/edit-user/edit-user-license.component';
import {SubCompaniesComponent} from './sub-companies/sub-companies.component';
import {SubCompaniesListComponent} from './sub-companies/sub-copmanies-list.component';
import {MarketplaceComponent} from './marketplace/marketplace.component';
import {MarketplaceSource} from './marketplace/marketplace.source';
import {MarketplaceApi} from './marketplace/marketplace.api';
import {DashAddonApi} from './marketplace/dash-addon/dash-addon.api';
import {YodataAddonApi} from './marketplace/yodata-addon/yodata-addon.api';
import {MarketplaceDeactivateConfirmationDialogComponent} from './marketplace/marketplace-deactivate-confirmation.dialog';
import {InitialSetupApi, InitialSetupService, InitialSetupSource} from '../auth/welcome/initial-setup.service';
import {ConfirmCreateProfileDialogComponent} from './dialogs/confirm-create-profile-dialog.component';
import {ConfirmAddonProfileCreationDialogComponent} from './dialogs/confirm-addon-profile-creation-dialog.component';
import {CompanyRoleFeatureComponent} from './users-licenses/company-roles-management/company-role-feature/company-role-feature.component';

import {CompanySetupComponent} from './settings/company-setup.component';

import {CompanySettingsComponent} from './company-settings/company-settings.component';

import {settingsReducer} from './store/settings.reducer';
import {SettingsEffectsService} from './store/settings.effects';
import {GPlacesPickerModule} from '../gplaces-picker/gplaces-picker.module';
import {GoalsModule} from '../goals/goals.module';
import {RolesService} from './services/roles.service';
import {CompanyRolesManagementComponent} from './users-licenses/company-roles-management/company-roles-management.component';
import {CompensationModule} from './compensation/compensation.module';
import {UploadService} from './services/upload.service';
import {CompanyDataUploadsComponent} from './company-data-uploads/company-data-uploads.component';
import {UserRecurringDeleteDialogComponent} from './users-licenses/user-recurring-delete.dialog';
import {AuthorizeGatewayEditComponent} from './company-gateway/company-gateway-edit/authorize-gateway-edit/authorize-gateway-edit.component';
import {RedirectGuard} from '../../services/resolvers/redirect-guard.service';
import {PermissionPreviewDialogComponent} from './users-licenses/edit-user/permission-preview-dialog.component';
import {AddonConnectionComponent} from './company-gateway/company-gateway-edit/addon-connection/addon-connection.component';
import {SettingsComponent} from './settings/settings.component';
import {PurchasedDealsDialogComponent} from './purchase-settings/purchased-deals-dialog/purchased-deals-dialog.component';
import {UpdateContactConfirmComponent} from './users-licenses/edit-user/update-contact-confirm/update-contact-confirm.component';
import {UsersLicensesCardComponent} from './users-licenses/users-licenses-card/users-licenses-card.component';
import {ZipiFinancialTransferService} from '../../services/api/finance/zipi-financia-transfer.service';
import {AccountInfoToolbarModule} from './account-info-toolbar/account-info-toolbar.module';
import {PurchaseSettingsComponent} from './purchase-settings/purchase-settings.component';
import {AddToGroupDialogComponent} from './users-licenses/add-to-group-dialog/add-to-group-dialog.component';
import {InsertionInfoDialogComponent} from './compensation/components/insertion-info-dialog.component';
import {ZipiFinancialInfoComponent} from './company-gateway/company-gateway-edit/zipi-finacial/zipi-fin-page/zipi-fin-gateway-container/zipi-fin-info/zipi-financial-info.component';
import {ZipiFinancialGatewaySetupComponent} from './company-gateway/company-gateway-edit/zipi-finacial/zipi-fin-page/zipi-fin-gateway-setup/moov/zipi-financial-gateway-setup.component';
import {ZipiFinancialIframeDialogComponent} from './company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-financial-iframe-dialog/zipi-financial-iframe-dialog.component';
import {AutomationListComponent} from './settings/automation/automation-list/automation-list.component';
import {AutomationDialogComponent} from './settings/tabs/tab2-tags/automation-dialog/automation-dialog.component';
import {YodataSettingsComponent} from './marketplace/yodata-addon/yodata-settings.component';
import {YodataSettingsTableComponent} from './marketplace/yodata-addon/yodata-settings-table.component';
import {YodataEditEntityDialogComponent} from './marketplace/yodata-addon/edit-entity-dialog/edit-entity-dialog.component';
import {YodataCreateContactDialogComponent} from './marketplace/yodata-addon/create-contact-dialog/create-contact-dialog.component';
import {ProfileFiltersDialogComponent} from './users-licenses/profile-filters-dialog/profile-filters-dialog.component';
import {DashAddonSettingsComponent} from './marketplace/dash-addon/dash-settings.component';
import {ImportDataButtonComponent} from './company-data-uploads/import-data-button.component';
import {ZipiFinancialPageComponent} from './company-gateway/company-gateway-edit/zipi-finacial/zipi-fin-page/zipi-financial-page.component';
import {AddRepresentativesDialogComponent} from './company-gateway/company-gateway-edit/zipi-finacial/dialogs/add-representatives-dialog/add-representatives-dialog.component';
import {ZipiFinancialBankAccountsComponent} from './company-gateway/company-gateway-edit/zipi-finacial/zipi-fin-page/zipi-fin-gateway-container/zipi-fin-bank-accounts/zipi-financial-bank-accounts.component';
import {ZipiFinancialAfterSetupDialogComponent} from './company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-financial-after-setup-dialog/zipi-financial-after-setup-dialog.component';
import {ZipiFinConnectToLedgerAccountDialogComponent} from './company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-fin-connect-to-ledger-account-dialog/zipi-fin-connect-to-ledger-account-dialog.component';
import {ZipiFinancialTransferMoneyDialogComponent} from './company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-financial-transfer-money-dialog/zipi-financial-transfer-money-dialog.component';
import {ZipiFinancialRetrySubmitGatewayDialogComponent} from './company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-financial-retry-submit-gateway-dialog/zipi-financial-retry-submit-gateway-dialog.component';
import {ZipiFinancialRetrySubmitRepresentativeDialogComponent} from './company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-financial-retry-submit-representative-dialog/zipi-financial-retry-submit-representative-dialog.component';
import {ZipiFinPaymentMehtodEditDialogComponent} from './company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-fin-payment-mehtod-edit-dialog/zipi-fin-payment-mehtod-edit-dialog.component';
import {ExportDataFormDialogComponent} from './company-data-uploads/expot-data-form.dialog.component';
import {ZipiFinGatewayEditDialogComponent} from './company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-fin-gateway-edit-dialog/zipi-fin-gateway-edit-dialog.component';
import {ZipiFinTransfersLogPageComponent} from './company-gateway/company-gateway-edit/zipi-finacial/zipi-fin-page/zipi-fin-gateway-container/zipi-fin-transfers-log/zipi-fin-transfers-log-page/zipi-fin-transfers-log-page.component';
import {CommonModule} from '@angular/common';
import {ZipiFinTransfersComponent} from './company-gateway/company-gateway-edit/zipi-finacial/zipi-fin-transfers/zipi-fin-transfers.component';
import {ZipiFinTransfersLogDataTableComponent} from './company-gateway/company-gateway-edit/zipi-finacial/zipi-fin-page/zipi-fin-gateway-container/zipi-fin-transfers-log/zipi-fin-transfers-log-data-table/zipi-fin-transfers-log-data-table.component';
import {ZipiFinGatewayContainerComponent} from './company-gateway/company-gateway-edit/zipi-finacial/zipi-fin-page/zipi-fin-gateway-container/zipi-fin-gateway-container.component';
import {ReloadsHistoryDialogComponent} from './purchase-settings/reloads-history-dialog/reloads-history-dialog.component';
import {MuhneeAddBankAccountDialogComponent} from './company-gateway/company-gateway-edit/zipi-finacial/dialogs/muhnee-add-bank-account-dialog/muhnee-add-bank-account-dialog.component';
import {ZipiFinancialMuhneeSetupComponent} from './company-gateway/company-gateway-edit/zipi-finacial/zipi-fin-page/zipi-fin-gateway-setup/muhnee/zipi-financial-muhnee-setup.component';
import {ZipiFinFeeScheduleDialogComponent} from './company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-fin-fee-schedule-dialog/zipi-fin-fee-schedule-dialog.component';
import {MuhneeCompaniesSelectDialogComponent} from './company-gateway/company-gateway-edit/zipi-finacial/dialogs/muhnee-companies-select-dialog/muhnee-companies-select-dialog.component';
import {MuhneeKycRedirectDialogComponent} from './company-gateway/company-gateway-edit/zipi-finacial/dialogs/muhnee-kyc-redirect-dialog/muhnee-kyc-redirect-dialog.component';
import {ZipiFinancialTppMerchantSetupComponent} from './company-gateway/company-gateway-edit/zipi-finacial/zipi-fin-page/zipi-fin-gateway-setup/tpp/merchant/zipi-financial-tpp-merchant-setup.component';
import {ZipiFinancialTppCustomerSetupComponent} from './company-gateway/company-gateway-edit/zipi-finacial/zipi-fin-page/zipi-fin-gateway-setup/tpp/customer/zipi-financial-tpp-customer-setup.component';
import {ZipiFinancialMuhneeHostedSetupComponent} from './company-gateway/company-gateway-edit/zipi-finacial/zipi-fin-page/zipi-fin-gateway-setup/muhnee-hosted/zipi-financial-muhnee-hosted-setup.component';
import {MuhneeOnboardingDialogComponent} from './company-gateway/company-gateway-edit/zipi-finacial/dialogs/muhnee-onboarding-dialog/muhnee-onboarding-dialog.component';
import {MuhneePersonaRedirectDialogComponent} from './company-gateway/company-gateway-edit/zipi-finacial/dialogs/muhnee-persona-redirect-dialog/muhnee-persona-redirect-dialog.component';
import {MuhneeAddBankAccountHostedDialogComponent} from './company-gateway/company-gateway-edit/zipi-finacial/dialogs/muhnee-add-bank-account-hosted-dialog/muhnee-add-bank-account-hosted-dialog.component';
import {MuhneeVerifyBankAccountHostedDialogComponent} from './company-gateway/company-gateway-edit/zipi-finacial/dialogs/muhnee-verify-bank-account-hosted-dialog/muhnee-verify-bank-account-hosted-dialog.component';
import {ConfirmQuickbooksDialogComponent} from './dialogs/confirm-quickbooks-dialog.component';
import {QuickBooksAddonSettingsComponent} from './marketplace/quickbooks-addon/quickbooks-settings.component';
import {QuickBooksLedgersComponent} from './marketplace/quickbooks-addon/quickbooks-ledgers/quickbooks-ledgers.component';
import {QuickBooksProductsComponent} from './marketplace/quickbooks-addon/quickbooks-products/quickbooks-products.component';
import {QuickBooksConnectComponent} from './marketplace/quickbooks-addon/quickbooks-connect.component';
import {QbIntegrationSettingsComponent} from './marketplace/quickbooks-addon/qb-integration-settings/qb-integration-settings.component';
import {ZipiFinancialPayloadSetupComponent} from './company-gateway/company-gateway-edit/zipi-finacial/zipi-fin-page/zipi-fin-gateway-setup/payload/zipi-financial-payload-setup.component';
import {CalculationSettingsComponent} from './calculation-settings/calculation-settings.component';
import {SupportAccountLookupModalComponent} from '@app/modules/account-info/users-licenses/support-account-lookup-modal-dialog/support-account-lookup-modal.component';
import {DynamicCellDirective} from '@app/modules/account-info/users-licenses/support-account-lookup-modal-dialog/dynamic-cell.directive';
import {CompanyModalComponent} from '@app/modules/account-info/users-licenses/support-account-lookup-modal-dialog/company-modal.component';
import {CalculationSettingsService} from '@app/services/new-calculation-settings.service';

@NgModule({
    declarations: [
        IndexComponent,
        UsersLicensesComponent,
        UsersLicensesCardComponent,
        EditUserComponent,
        FilterPipe,
        RadioListComponent,
        RadioListItemComponent,
        UserDeleteDialogComponent,
        DeleteRoleConfirmDialogComponent,
        ConfirmCreateProfileDialogComponent,
        ConfirmAddonProfileCreationDialogComponent,
        ConfirmQuickbooksDialogComponent,
        UserInfoDialogComponent,
        UserRecurringDeleteDialogComponent,
        ChangePlanDialogComponent,
        ChangeMaxUsersDialogComponent,
        GeneralSettingsComponent,
        EditUserLicenseComponent,
        CompanyRoleFeatureComponent,
        SubCompaniesComponent,
        SubCompaniesListComponent,
        MarketplaceComponent,
        MarketplaceDeactivateConfirmationDialogComponent,
        CompanySetupComponent,
        CompanySettingsComponent,
        PurchasedDealsDialogComponent,
        ReloadsHistoryDialogComponent,
        AddToGroupDialogComponent,
        InsertionInfoDialogComponent,
        ProfileFiltersDialogComponent,
        PermissionPreviewDialogComponent,
        CompanyRolesManagementComponent,
        CompanyDataUploadsComponent,
        AuthorizeGatewayEditComponent,
        SettingsComponent,
        PurchaseSettingsComponent,
        AddonConnectionComponent,
        UpdateContactConfirmComponent,
        ZipiFinancialPageComponent,
        ZipiFinancialInfoComponent,
        ZipiFinancialGatewaySetupComponent,
        ZipiFinancialMuhneeSetupComponent,
        ZipiFinancialMuhneeHostedSetupComponent,
        ZipiFinancialTppMerchantSetupComponent,
        ZipiFinancialTppCustomerSetupComponent,
        ZipiFinancialIframeDialogComponent,
        AddRepresentativesDialogComponent,
        ZipiFinancialBankAccountsComponent,
        ZipiFinTransfersLogPageComponent,
        ZipiFinTransfersLogDataTableComponent,
        ZipiFinGatewayContainerComponent,
        ZipiFinTransfersComponent,
        ZipiFinancialAfterSetupDialogComponent,
        ZipiFinConnectToLedgerAccountDialogComponent,
        ZipiFinancialTransferMoneyDialogComponent,
        ZipiFinancialRetrySubmitGatewayDialogComponent,
        ZipiFinancialRetrySubmitRepresentativeDialogComponent,
        ZipiFinPaymentMehtodEditDialogComponent,
        ZipiFinGatewayEditDialogComponent,
        ZipiFinancialPayloadSetupComponent,
        AutomationListComponent,
        AutomationDialogComponent,
        YodataSettingsComponent,
        YodataSettingsTableComponent,
        YodataEditEntityDialogComponent,
        YodataCreateContactDialogComponent,
        DashAddonSettingsComponent,
        QuickBooksAddonSettingsComponent,
        QuickBooksLedgersComponent,
        QuickBooksProductsComponent,
        QuickBooksConnectComponent,
        QbIntegrationSettingsComponent,
        ImportDataButtonComponent,
        ExportDataFormDialogComponent,
        MuhneeAddBankAccountDialogComponent,
        MuhneeAddBankAccountHostedDialogComponent,
        MuhneeVerifyBankAccountHostedDialogComponent,
        MuhneeCompaniesSelectDialogComponent,
        MuhneeKycRedirectDialogComponent,
        MuhneeOnboardingDialogComponent,
        MuhneePersonaRedirectDialogComponent,
        ZipiFinFeeScheduleDialogComponent,
        CalculationSettingsComponent,
        SupportAccountLookupModalComponent,
        DynamicCellDirective,
        CompanyModalComponent
    ],
    imports: [
        CommonModule,
        RbacModule,
        SharedModule,
        GPlacesPickerModule,
        CompensationModule,
        GoalsModule,
        routing,
        StoreModule.forFeature('settings', settingsReducer),
        EffectsModule.forFeature([SettingsEffectsService]),
        AccountInfoToolbarModule
    ],
    providers: [
        UsersAndLicencesApiService,
        MarketplaceSource,
        MarketplaceApi,
        RolesService,
        UploadService,
        InitialSetupApi,
        InitialSetupService,
        InitialSetupSource,
        RedirectGuard,
        ZipiFinancialTransferService,
        DashAddonApi,
        YodataAddonApi,
        CalculationSettingsService
    ],
    exports: [BrowserModule, CompanySetupComponent],
    bootstrap: [IndexComponent]
})
export class AccountInfoModule {}
