import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IFinanceState} from '../../../store/finance.reducer';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {map, filter, takeUntil} from 'rxjs/operators';
import {IVendorCredit} from '@cyberco-nodejs/zipi-typings';
import {CreditsService} from '../../../services/credits.service';

@Component({
    selector: 'app-view-vendor-credit',
    templateUrl: 'source-vendor-credit.component.html',
    styles: [
        `
            .zp-company-info {
                width: 100%;
            }

            .zp-company-title {
                font-weight: bold;
                margin-bottom: 0;
            }

            .zp-inveice_title {
                font-size: 33px;
                margin-bottom: 0;
            }

            .zp-invoice-num_value {
                font-size: 14px;
                letter-spacing: 0.07rem;
                font-weight: bold;
                margin-bottom: 25px;
                margin-top: -5px;
            }

            .zp-refference_due_title {
                margin-bottom: 0;
            }

            .zp-refference_due_value {
                font-size: 14px;
                margin-top: 0px;
                font-weight: bold;
                margin-bottom: 30px;
            }

            .zp-w-150 {
                width: 150px;
                display: inline-block;
            }

            .zp-balance-due {
                font-size: 20px;
            }

            .zp-total-nember {
                display: inline-block;
                width: 150px;
                text-align: right;
                padding-right: 0.75rem;
            }
        `
    ]
})
export class SourceVendorCreditComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    vendorCreditId: number | null = null;
    vendorCredit: IVendorCredit | undefined;

    constructor(
        private store: Store<IFinanceState>,
        private route: ActivatedRoute,
        private creditsService: CreditsService
    ) {}

    ngOnInit() {
        this.route.paramMap
            .pipe(
                map((pm) => {
                    const stringId: string | null = pm.get('id');
                    return Number(stringId);
                }),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                this.vendorCreditId = id;
                this.creditsService
                    .getSourceVendorCreditById(id)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((vendorCredit) => {
                        this.vendorCredit = vendorCredit;
                    });
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
