import {BaseNotification} from './base.notification';
import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'modal-notification-dialog',
    template: `
        <h1 style="width: 400px;" md-dialog-title>Confirm</h1>
        <div md-dialog-content style="min-height: 40px;line-height: 70px;">
            <div [innerHtml]="message"></div>
        </div>
        <div md-dialog-actions>
            <div style="width: 100%">
                <button mat-button class="pull-left" (click)="close(true)">Yes</button>
                <button mat-button class="pull-right" (click)="close(false)">No</button>
            </div>
        </div>
    `
})
export class ConfirmNotificationDialogComponent implements OnInit {
    constructor(public MdDialogRef: MatDialogRef<ConfirmNotificationDialogComponent>) {}

    ngOnInit() {}

    close(result: any) {
        return this.MdDialogRef.close(result);
    }

    public message: string = '';
}

export class ConfirmNotification extends BaseNotification {
    protected position: 'confirm' = 'confirm';

    constructor(mdDialog: MatDialog) {
        super();

        this.mdDialog = mdDialog;
    }

    protected dialogRef: MatDialogRef<ConfirmNotificationDialogComponent> | null = null;
    protected mdDialog: MatDialog | null = null;

    render() {
        if (!this.mdDialog) {
            return;
        }
        this.dialogRef = this.mdDialog.open(ConfirmNotificationDialogComponent);

        this.dialogRef.componentInstance.message = this.message;
    }

    getDialogRef() {
        return this.dialogRef;
    }
}
