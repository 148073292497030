import {Injectable} from '@angular/core';
import {ServiceRequester} from './service.requester';
import {SkyslopeAuth} from '@app/modules/auth/services/okta/skyslope-auth.service';

@Injectable()
export class CompanyAdminService {
    constructor(
        public requester: ServiceRequester,
        private skyslopeAuthService: SkyslopeAuth
    ) {}

    getHeaders() {
        return this.skyslopeAuthService.getAccessToken().then((accessToken) => {
            const headersObj: {[key: string]: string} = {
                Authorization: 'Bearer ' + accessToken
            };
            return headersObj;
        });
    }

    async getCompanyBillingSettings(companyId: number) {
        const headers = await this.getHeaders();
        return this.requester.makeMsShippCall$(`/admin/companies/${companyId}/billing-settings`, 'GET', {}, headers);
    }
}
