<div class="global-search-container w-100" *ngIf="globalSearchForm" [formGroup]="globalSearchForm">
    <!------- Button shows search mode ------->
    <button mat-icon-button [matMenuTriggerFor]="searchMenu" class="mx-3" style="opacity: 0.7">
        <mat-icon *ngIf="globalSearchForm.get('mode')?.value === 'global'">language</mat-icon>
        <mat-icon *ngIf="globalSearchForm.get('mode')?.value === 'contact'">contacts</mat-icon>
        <mat-icon *ngIf="globalSearchForm.get('mode')?.value === 'division'">domain</mat-icon>
        <mat-icon *ngIf="globalSearchForm.get('mode')?.value === 'group'">people</mat-icon>
        <mat-icon *ngIf="globalSearchForm.get('mode')?.value === 'goal'">ballot</mat-icon>
        <mat-icon *ngIf="globalSearchForm.get('mode')?.value === 'deal'" class="material-icons-outlined"
            >home_work</mat-icon
        >
        <mat-icon *ngIf="globalSearchForm.get('mode')?.value === 'invoice'">local_atm</mat-icon>
        <mat-icon *ngIf="globalSearchForm.get('mode')?.value === 'expense'">account_balance_wallet</mat-icon>
        <mat-icon *ngIf="globalSearchForm.get('mode')?.value === 'bill'">receipt</mat-icon>
        <mat-icon *ngIf="globalSearchForm.get('mode')?.value === 'profile'">person</mat-icon>
        <mat-icon *ngIf="globalSearchForm.get('mode')?.value === 'payment_received'">payments</mat-icon>
        <mat-icon *ngIf="globalSearchForm.get('mode')?.value === 'payment_made'" class="material-icons-outlined"
            >payments</mat-icon
        >
        <mat-icon>arrow_drop_down</mat-icon>
    </button>

    <!------- Menu modes search ------->
    <mat-menu #searchMenu="matMenu" [overlapTrigger]="false" xPosition="before">
        <ng-container *ngFor="let mode of modes">
            <button
                mat-menu-item
                type="button"
                *ngIf="globalSearchForm.get('mode')?.value !== mode && mode === 'global'"
                (click)="changeSearchMode(mode)"
            >
                <mat-icon>language</mat-icon> Global
            </button>
            <!------- Contact search string ------->
            <button
                mat-menu-item
                type="button"
                (click)="changeSearchMode(mode)"
                *ngIf="globalSearchForm.get('mode')?.value !== mode && mode === 'contact'"
                [rule]="{contacts__access: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
            >
                <mat-icon>contacts</mat-icon> {{ mode | fromSnakeCase }}s
            </button>
            <!------- Division search string ------->
            <button
                mat-menu-item
                type="button"
                (click)="changeSearchMode(mode)"
                *ngIf="globalSearchForm.get('mode')?.value !== mode && mode === 'division'"
                [rule]="{company_settings__manage_groups: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
            >
                <mat-icon>domain</mat-icon> {{ mode | fromSnakeCase }}s
            </button>
            <!------- Group search string ------->
            <button
                mat-menu-item
                type="button"
                (click)="changeSearchMode(mode)"
                *ngIf="globalSearchForm.get('mode')?.value !== mode && mode === 'group'"
                [rule]="{company_settings__manage_groups: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
            >
                <mat-icon>people</mat-icon> {{ mode | fromSnakeCase }}s
            </button>
            <!------- Task search string ------->
            <button
                mat-menu-item
                type="button"
                (click)="changeSearchMode(mode)"
                *ngIf="globalSearchForm.get('mode')?.value !== mode && mode === 'goal'"
                [rule]="{progress__access: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
            >
                <mat-icon>ballot</mat-icon> Tasks
            </button>
            <!------- Deal search string ------->
            <button
                mat-menu-item
                type="button"
                (click)="changeSearchMode(mode)"
                *ngIf="globalSearchForm.get('mode')?.value !== mode && mode === 'deal'"
                [rule]="{deals__access: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
            >
                <mat-icon class="material-icons-outlined">home_work</mat-icon> {{ mode | fromSnakeCase }}s
            </button>
            <!------- Invoice search string ------->
            <button
                mat-menu-item
                type="button"
                (click)="changeSearchMode(mode)"
                *ngIf="globalSearchForm.get('mode')?.value !== mode && mode === 'invoice'"
                [rule]="{sales__view_invoices: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
            >
                <mat-icon>local_atm</mat-icon> {{ mode | fromSnakeCase }}s
            </button>
            <!------- Expense search string ------->
            <button
                mat-menu-item
                type="button"
                (click)="changeSearchMode(mode)"
                *ngIf="globalSearchForm.get('mode')?.value !== mode && mode === 'expense'"
                [rule]="{purchases__access: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
            >
                <mat-icon>account_balance_wallet</mat-icon> {{ mode | fromSnakeCase }}s
            </button>
            <!------- Bill search string ------->
            <button
                mat-menu-item
                type="button"
                (click)="changeSearchMode(mode)"
                *ngIf="globalSearchForm.get('mode')?.value !== mode && mode === 'bill'"
                [rule]="{purchases__view_bills: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
            >
                <mat-icon>receipt</mat-icon> {{ mode | fromSnakeCase }}s
            </button>
            <!------- Profile search string ------->
            <button
                mat-menu-item
                type="button"
                (click)="changeSearchMode(mode)"
                *ngIf="globalSearchForm.get('mode')?.value !== mode && mode === 'profile'"
                [rule]="{company_settings__manage_users: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
            >
                <mat-icon>person</mat-icon> {{ mode | fromSnakeCase }}s
            </button>
            <!------- Payment Received search string ------->
            <button
                mat-menu-item
                type="button"
                (click)="changeSearchMode(mode)"
                *ngIf="globalSearchForm.get('mode')?.value !== mode && mode === 'payment_received'"
                [rule]="{sales__view_payments_received: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
            >
                <mat-icon>payments</mat-icon> Payments Received
            </button>
            <!------- Payment Made search string ------->
            <button
                mat-menu-item
                type="button"
                (click)="changeSearchMode(mode)"
                *ngIf="globalSearchForm.get('mode')?.value !== mode && mode === 'payment_made'"
                [rule]="{purchases__view_payments_made: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
            >
                <mat-icon class="material-icons-outlined">payments</mat-icon> Payments Made
            </button>
        </ng-container>
    </mat-menu>

    <!------- Input search string ------->
    <mat-form-field class="w-100 mr-3 global-search-input" floatLabel="never">
        <input
            matInput
            type="text"
            placeholder="Search"
            #searchInput
            formControlName="searchData"
            [matAutocomplete]="auto"
            (keyup.enter)="handleSearchValue()"
        />

        <button
            mat-button
            *ngIf="globalSearchForm.get('searchData')?.value"
            matSuffix
            style="top: -3px"
            mat-icon-button
            aria-label="Clear"
            (click)="resetSearch()"
        >
            <mat-icon>close</mat-icon>
        </button>

        <!------- Results ------->
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option
                class="result-option"
                *ngFor="let result of searchResults; let i = index"
                [value]="globalSearchForm.get('searchData')?.value"
                (onSelectionChange)="searchResultRedirect(result._source)"
            >
                <!------- Contact result string ------->
                <div *ngIf="result._source.type === 'contact'" class="d-flex justify-content-between">
                    <div>
                        <mat-icon class="result-icon">contacts</mat-icon>
                        {{ result._source.index_data.display_name }}
                    </div>
                    <button
                        mat-button
                        *ngIf="result._source.index_data.related__profile_fk_id"
                        (click)="
                            $event.stopPropagation();
                            router.navigate([
                                'company/directory/users',
                                result._source.index_data.related__profile_fk_id
                            ])
                        "
                    >
                        <span class="additional-link">Profile</span>
                    </button>
                </div>
                <!------- Contact person result string ------->
                <div *ngIf="result._source.type === 'contact_person'" class="d-flex justify-content-between">
                    <div>
                        <mat-icon class="result-icon material-icons-outlined">contacts</mat-icon>
                        <span class="ml-1">{{ result._source.index_data.person_name }}</span>
                        <span class="font-italic ml-1" *ngIf="result._source.index_data.contact_fk_display_name">
                            ({{ result._source.index_data.contact_fk_display_name }})
                        </span>
                    </div>
                </div>

                <!------- Division result string ------->
                <div *ngIf="result._source.type === 'division'">
                    <mat-icon class="result-icon">domain</mat-icon>
                    {{ result._source.index_data.title }}
                </div>

                <!------- Group result string ------->
                <div *ngIf="result._source.type === 'group'">
                    <mat-icon class="result-icon">people</mat-icon>
                    {{ result._source.index_data.title }}
                </div>

                <!------- Task result string ------->
                <div *ngIf="result._source.type === 'goal'">
                    <mat-icon class="result-icon">ballot</mat-icon>
                    {{ result._source.index_data.title }}
                </div>

                <!------- Deal result string ------->
                <div *ngIf="result._source.type === 'deal'" class="d-flex align-items-center">
                    <!------- Icon ------->
                    <mat-icon class="material-icons-outlined result-icon">home_work</mat-icon>
                    <!------- Address ------->
                    <ng-container *ngIf="result._source.index_data.full_address || result._source.index_data.address">
                        <div class="mr-2">
                            <ng-container *ngIf="result._source.index_data.full_address; else unindexed">
                                <span>
                                    {{ result._source.index_data.full_address }}
                                </span>
                            </ng-container>
                            <ng-template #unindexed>
                                <span>
                                    <!--street_number concatenated with address on elastic-->
                                    {{ result._source.index_data.address }}
                                </span>
                            </ng-template>
                        </div>
                    </ng-container>
                    <!------- Type and Date ------->
                    <div style="font-size: small" class="mr-2">
                        <ng-container
                            *ngIf="result._source.index_data.type || result._source.index_data.close_of_escrow"
                        >
                            <span>[</span>
                        </ng-container>
                        <ng-container *ngIf="result._source.index_data.type">
                            <span>{{ result._source.index_data.type }}</span>
                        </ng-container>
                        <ng-container
                            *ngIf="result._source.index_data.type && result._source.index_data.close_of_escrow"
                        >
                            <span>::</span>
                        </ng-container>
                        <ng-container *ngIf="result._source.index_data.close_of_escrow">
                            <span>{{ result._source.index_data.close_of_escrow | uppercase }}</span>
                        </ng-container>
                        <ng-container
                            *ngIf="result._source.index_data.type || result._source.index_data.close_of_escrow"
                        >
                            <span>]</span>
                        </ng-container>
                    </div>
                    <!------- Status ------->
                    <div style="font-size: small">
                        <ng-container *ngIf="result._source.index_data.status">
                            <span>{{ result._source.index_data.status | uppercase }}</span>
                        </ng-container>
                    </div>
                </div>

                <!------- Invoice result string ------->
                <div *ngIf="result._source.type === 'invoice'">
                    <mat-icon class="result-icon">local_atm</mat-icon>
                    {{ result._source.index_data.invoice }}
                </div>

                <!------- Expense result string ------->
                <div *ngIf="result._source.type === 'expense'">
                    <mat-icon class="result-icon">account_balance_wallet</mat-icon>
                    <span>Expense</span>
                    <span *ngIf="result._source.index_data.vendor">, {{ result._source.index_data.vendor }}</span>
                </div>

                <!------- Bill result string ------->
                <div *ngIf="result._source.type === 'bill'">
                    <mat-icon class="result-icon">receipt</mat-icon>
                    {{ result._source.index_data.bill }}
                </div>

                <!------- Profile result string ------->
                <div *ngIf="result._source.type === 'profile'">
                    <mat-icon class="result-icon">person</mat-icon>
                    {{ result._source.index_data.display_name }}
                </div>

                <!------- Payment Received result string ------->
                <div *ngIf="result._source.type === 'payment_received'">
                    <mat-icon class="result-icon">payments</mat-icon>
                    <span>{{ result._source.index_data.payment_received }}</span>
                    <span
                        *ngIf="
                            result._source.index_data.payment_received &&
                            (result._source.index_data.payment_date || result._source.index_data.vedor)
                        "
                        class="mx-1"
                        >-</span
                    >
                    <span>{{
                        result._source.index_data.payment_date | dateFromNumber | amDateFormat: 'MMM DD, YYYY'
                    }}</span>
                    <span
                        *ngIf="result._source.index_data.payment_date && result._source.index_data.vendor"
                        class="mr-1"
                        >,</span
                    >
                    <span>{{ result._source.index_data.vendor }}</span>
                </div>

                <!------- Payment Made result string ------->
                <div *ngIf="result._source.type === 'payment_made'">
                    <mat-icon class="result-icon material-icons-outlined">payments</mat-icon>
                    <span>{{ result._source.index_data.payment_made }}</span>
                    <span
                        *ngIf="
                            result._source.index_data.payment_made &&
                            (result._source.index_data.payment_date || result._source.index_data.vedor)
                        "
                        class="mx-1"
                        >-</span
                    >
                    <span>{{
                        result._source.index_data.payment_date | dateFromNumber | amDateFormat: 'MMM DD, YYYY'
                    }}</span>
                    <span
                        *ngIf="result._source.index_data.payment_date && result._source.index_data.vendor"
                        class="mr-1"
                        >,</span
                    >
                    <span>{{ result._source.index_data.vendor }}</span>
                </div>
            </mat-option>

            <!------- If showed results is less than total (type mode) ------->
            <mat-option
                class="result-option"
                *ngIf="
                    searchResults.length &&
                    totalResults &&
                    totalResults > searchResults.length &&
                    globalSearchForm.get('mode')?.value !== 'global'
                "
                [value]="globalSearchForm.get('searchData')?.value"
            >
                <a href="#" (click)="$event.preventDefault(); resetSearch(false); searchInput.focus()">
                    More Results (please refine your search query)
                </a>
            </mat-option>

            <!------- If showed results is less than total (global mode) ------->
            <mat-option
                class="result-option"
                *ngIf="
                    searchResults.length &&
                    totalResults &&
                    totalResults > searchResults.length &&
                    globalSearchForm.get('mode')?.value === 'global'
                "
                [value]="globalSearchForm.get('searchData')?.value"
            >
                <a
                    href="#"
                    class="font-italic"
                    (click)="$event.preventDefault(); resetSearch(false); searchInput.focus()"
                >
                    More Results (please refine your search query)
                </a>
            </mat-option>

            <!------- No results in global search ------->
            <mat-option
                class="result-option"
                *ngIf="globalSearchForm.get('mode')?.value === 'global' && noResults"
                [value]="globalSearchForm.get('searchData')?.value"
                (onSelectionChange)="modeToGlobal()"
            >
                <span class="font-italic"> No Results </span>
            </mat-option>

            <!------- No results in type search ------->
            <mat-option
                class="result-option"
                *ngIf="globalSearchForm.get('mode')?.value !== 'global' && noResults"
                [value]="globalSearchForm.get('searchData')?.value"
                (onSelectionChange)="modeToGlobal()"
            >
                <div>
                    <mat-icon>search</mat-icon>
                    <a href="#" (click)="$event.preventDefault()">Search All Modules</a>
                </div>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <!--Date range picker for payment received OR payment made -->
    <button
        mat-icon-button
        [matMenuTriggerFor]="menuDateRange"
        *ngIf="
            globalSearchForm.get('mode')?.value === 'payment_received' ||
            globalSearchForm.get('mode')?.value === 'payment_made'
        "
    >
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menuDateRange="matMenu" class="custom-data-picker-menu">
        <app-date-range-picker
            (click)="$event.stopPropagation()"
            (dateEmitter)="handleDateBetween = $event"
        ></app-date-range-picker>
        <button mat-raised-button color="primary" (click)="searchByDate()">Search</button>
    </mat-menu>
</div>
