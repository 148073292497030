<div class="my-2">
    <div class="d-flex align-items-end">
        <h3>Release</h3>
        <button mat-icon-button (click)="addDepositRelease()" [disabled]="disabled">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <div>
        <table mat-table [dataSource]="dataSourceRelease" class="mat-elevation-z1 w-100 deposit-table">
            <ng-container matColumnDef="release_date">
                <th mat-header-cell *matHeaderCellDef style="width: 150px">Release Date</th>
                <td mat-cell *matCellDef="let element" style="width: 150px">
                    {{ element.release_date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="receiver">
                <th mat-header-cell *matHeaderCellDef>Receiver</th>
                <td mat-cell *matCellDef="let element">{{ element.release_receiver_contact?.display_name }}</td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef>Amount</th>
                <td mat-cell *matCellDef="let element">{{ element.release_amount | currency }}</td>
            </ng-container>

            <ng-container matColumnDef="applied_to">
                <th mat-header-cell *matHeaderCellDef>Applied To</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.related_payment_made && element.related_payment_made.is_locked_for_applying">{{
                        dealName
                    }}</span>
                    <span
                        *ngIf="
                            element.related_payment_made &&
                            !element.related_payment_made.is_locked_for_applying &&
                            (!element.related_payment_made.related_payments ||
                                element.related_payment_made.related_payments.length === 0)
                        "
                        >Unapplied</span
                    >
                    <span
                        *ngIf="
                            element.related_payment_made &&
                            !element.related_payment_made.is_locked_for_applying &&
                            element.related_payment_made.related_payments &&
                            element.related_payment_made.related_payments.length > 0
                        "
                    >
                        <span *ngFor="let payment of element.related_payment_made.related_payments">
                            <a
                                *ngIf="payment.bill && payment.bill.bill_id"
                                routerLink="/purchases/bills/{{ payment.bill.bill_id }}"
                                href=""
                                >{{ payment.bill?.bill_number }}
                            </a>
                        </span>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="payment_status">
                <th mat-header-cell *matHeaderCellDef>Payment Status</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.related_payment_made">
                        <span
                            *ngIf="
                                element.related_payment_made.pament_mode === 'check' &&
                                element.related_payment_made.check_info &&
                                element.related_payment_made.check_info.print_status === 'not_printed'
                            "
                            [ngStyle]="{color: statusColor[element.related_payment_made.check_info?.print_status]}"
                        >
                            {{ element.related_payment_made.check_info?.print_status | fromSnakeCase }}
                        </span>
                        <span
                            *ngIf="
                                element.related_payment_made.pament_mode === 'check' &&
                                element.related_payment_made.check_info &&
                                element.related_payment_made.check_info.print_status === 'printed'
                            "
                            [ngStyle]="{color: statusColor[element.related_payment_made.check_info?.check_status]}"
                        >
                            {{ element.related_payment_made.check_info?.check_status | fromSnakeCase }}
                        </span>
                        <span
                            *ngIf="element.related_payment_made.pament_mode !== 'check'"
                            [ngStyle]="{color: statusColor[element.related_payment_made.summary_status]}"
                        >
                            {{ element.related_payment_made.summary_status | fromSnakeCase }}
                        </span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="payment_number">
                <th mat-header-cell *matHeaderCellDef>Payment Number</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="!element.related_payment_made || !element.related_payment_made.payment_made_number"
                        >'N/A'</span
                    >
                    <a
                        [routerLink]="['/purchases/payments', element.related_payment_made.payment_made_id]"
                        *ngIf="element.related_payment_made && element.related_payment_made.payment_made_number"
                        href=""
                    >
                        {{ element.related_payment_made.payment_made_number }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="text-align: end">Action</th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    style="text-align: end"
                    (click)="$event.preventDefault(); $event.stopPropagation()"
                >
                    <mat-menu #appMenu="matMenu">
                        <button
                            mat-menu-item
                            *ngIf="
                                element.related_payment_made?.payment_mode === 'zipi_financial' &&
                                element.related_payment_made?.summary_status === 'declined'
                            "
                            (click)="retryPayment(element.related_payment_made)"
                        >
                            Retry
                        </button>
                        <button
                            mat-menu-item
                            [disabled]="
                                disabled ||
                                ['authorize', 'zipi_pay', 'zipi_financial'].includes(
                                    element.related_payment_made?.payment_mode
                                )
                            "
                            (click)="openConfirm(element)"
                        >
                            Delete Release
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="
                                element.related_payment_made &&
                                element.related_payment_made.check_info &&
                                element.related_payment_made.check_info.print_status === 'not_printed'
                            "
                            (click)="printPaymentPdf(element.related_payment_made)"
                        >
                            Print Check
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="element.related_payment_made && element.related_payment_made.is_locked_for_applying"
                            [disabled]="disabled"
                            (click)="unlockReleasePayment(element.related_payment_made)"
                        >
                            Unapply
                        </button>
                    </mat-menu>

                    <button mat-icon-button [matMenuTriggerFor]="appMenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedReleaseColumns"></tr>
            <tr mat-row class="journals-row" *matRowDef="let row; columns: displayedReleaseColumns"></tr>
        </table>
        <div class="d-flex justify-content-end font-weight-bold mt-2">
            Total: {{ (dealDepositAmounts?.total_released | currency) || (0 | currency) }}
        </div>
    </div>
</div>
