import {Component, EventEmitter, Input, Output, OnInit, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {GenericFormArray, GenericFormGroup} from '../../../../entites/generic.entity';
import {Subject} from 'rxjs';
import {FinancialTransferEntity} from '../../../account-info/compensation/models/financial-transfer.entity';
import {ContactPartLink} from '../../../../models/contact-part-link';
import {OverrideDialogComponent} from './override.dialog.component';

@Component({
    selector: 'app-transfers-collection',
    styles: [
        `
            .transfer-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                box-sizing: border-box;
                flex-wrap: wrap;
                margin-top: 16px;
            }

            .transfer-container {
                display: flex;
                flex-direction: column;
                max-width: 100%;
            }

            .transfer-collection {
                justify-content: space-between;
                display: flex;
                align-items: center;
                flex-direction: row;
                padding: 16px 8px;
                padding-top: 8px;
            }

            .transfer-collection:first-child {
                border: none;
            }

            .flex-row {
                width: 100% !important;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }

            .full-width {
                width: 100% !important;
            }

            @media (max-width: 1370px) {
                .transfer-collection {
                    width: 100% !important;
                }
            }

            .disabled-transfer-overlay {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
            }

            .transfer-indicator {
                margin-right: 10px;
                cursor: help;
            }

            .transfer-indicator mat-icon {
                color: gray;
            }

            .disabled-transfer-overlay-left {
                left: 34px;
            }

            ::ng-deep .transfer-skipped-tooltip {
                white-space: pre-line;
            }
        `
    ],
    template: `
        <div class="transfer-wrapper">
            <div
                class="transfer-container"
                style="position: relative"
                *ngFor="let additional of transfersFA.controls; index as ai"
            >
                <span
                    *ngIf="
                        additional.controls.type &&
                        additional.controls.type.value === FINANCIAL_TRANSFER.type_SET.company_split_individual
                    "
                >
                    Company Compensation
                </span>
                <div class="transfer-collection">
                    <div class="transfer-indicator" *ngIf="!showBoth && additional.controls.is_skipped?.value">
                        <mat-icon
                            disabled="true"
                            [matTooltipClass]="'transfer-skipped-tooltip'"
                            [matTooltip]="
                                'Skipped&#13;This line item was not applied to the deal because the required compensation attribute (ie. mentor, transaction coordinator or similar custom field) was left blank.'
                            "
                            >info_outline</mat-icon
                        >
                    </div>
                    <div
                        *ngIf="additional.controls.added_automatic?.value && !showBoth"
                        (click)="onClick()"
                        [ngClass]="
                            !showBoth && additional.controls.is_skipped?.value ? 'disabled-transfer-overlay-left' : ''
                        "
                        class="disabled-transfer-overlay"
                    ></div>

                    <app-financial-transfer
                        *ngIf="additional.controls.type?.value === FINANCIAL_TRANSFER.type_SET.company_split_individual"
                        [receiverLabel]="receiverLabel"
                        [senderLabel]="senderLabel"
                        [isExpense]="isExpense"
                        [origin]="origin"
                        [showBoth]="showBoth"
                        [hideSender]="hideSender"
                        [hideReceiver]="true"
                        [allowPercents]="allowPercents && !showOnlyAmount"
                        [hideProduct]="false"
                        [hideLabel]="false"
                        style="width:100%"
                        [disabled]="disabled"
                        [enableDisbursementInstructionsToggle]="enableDisbursementInstructionsToggle"
                        [hidePayAtEscrowFlag]="hidePayAtEscrowFlag"
                        [hideDisbursementInstructionsFlag]="hideDisbursementInstructionsFlag"
                        [transferFG]="additional"
                        [hideAmount]="true"
                        [hideSkip]="true"
                        [hideValueType]="true"
                        [hidePayAtClosing]="true"
                    ></app-financial-transfer>

                    <app-financial-transfer
                        *ngIf="additional.controls.type?.value !== FINANCIAL_TRANSFER.type_SET.company_split_individual"
                        [receiverLabel]="receiverLabel"
                        [senderLabel]="senderLabel"
                        [isExpense]="isExpense"
                        [origin]="origin"
                        [showBoth]="showBoth"
                        [hideSender]="hideSender"
                        [hideReceiver]="hideReceiver"
                        [allowPercents]="allowPercents && !showOnlyAmount"
                        [hideProduct]="hideProduct"
                        [hideLabel]="hideLabel"
                        style="width:100%"
                        [disabled]="disabled"
                        [enableDisbursementInstructionsToggle]="enableDisbursementInstructionsToggle"
                        [hidePayAtEscrowFlag]="hidePayAtEscrowFlag"
                        [hideDisbursementInstructionsFlag]="hideDisbursementInstructionsFlag"
                        [transferFG]="additional"
                        [hideAmount]="hideAmount"
                    ></app-financial-transfer>

                    <ng-container
                        *ngIf="
                            (limit === 0 || limit > 1) && (isOverride || !additional.controls.added_automatic?.value)
                        "
                    >
                        <button
                            [disabled]="isDisabledDelete(additional, disabled)"
                            mat-icon-button
                            color="warn"
                            (click)="removeAdditional(ai)"
                        >
                            <mat-icon>clear</mat-icon>
                        </button>
                    </ng-container>
                </div>
            </div>

            <ng-container *ngIf="showAddButton && (limit === 0 || (limit > 0 && transfersFA.controls.length < limit))">
                <ng-content class="flex-row full-width"></ng-content>

                <div *ngIf="buttonLabel" style="justify-content: space-between; margin-top: 8px; width: 100%;">
                    <button
                        [disabled]="disabled"
                        mat-stroked-button
                        class="full-width w-100 add-transfer"
                        (click)="addAdditional()"
                    >
                        <mat-icon>add</mat-icon>
                        {{ buttonLabel }}
                    </button>
                </div>
            </ng-container>
        </div>
    `
})
export class TransfersCollectionComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() isOverride: boolean = false;
    @Input() defaultSender: ContactPartLink | null = null;
    @Input() defaultReceiver: ContactPartLink | null = null;
    @Input() defaultSenderWildcardId: number | null = null;
    @Input() defaultReceiverWildcardId: number | null = null;
    @Input() defaultValueType: string = FinancialTransferEntity.value_type_SET.flat_fee;
    @Input() hideSender: boolean = false;
    @Input() hideReceiver: boolean = false;
    @Input() hidePayAtEscrowFlag: boolean = false;
    @Input() hideDisbursementInstructionsFlag: boolean = false;
    @Input() hideProduct: boolean = false;
    @Input() hideLabel: boolean = false;
    @Input() hideAmount: boolean = false;
    @Input() receiverLabel: string = 'Receiver Contact';
    @Input() senderLabel: string = 'Sender Contact';
    @Input() limit: number = 0;
    @Input() showAddButton: boolean = false;
    @Input() exclude: ContactPartLink | null = null;
    @Input() type: string = FinancialTransferEntity.type_SET.income;
    @Input() isExpense: boolean = false;
    @Input() allowPercents: boolean = true;
    @Input() silent: boolean = false;
    @Input() title: string | null = null;
    @Input() buttonLabel: string | null = null;
    @Input() transfersFA: GenericFormArray<FinancialTransferEntity> = new GenericFormArray<FinancialTransferEntity>([]);
    @Input() disabled: boolean = false;
    @Input() showOnlyAmount: boolean = false;
    @Input() availableValueTypes: string[] = ['percent', 'flat_fee'];
    @Input() origin: string = 'default';
    @Input() showBoth: boolean = false;
    @Input() enableDisbursementInstructionsToggle: boolean = true;

    @Output() addItem = new EventEmitter<GenericFormGroup<FinancialTransferEntity>>();
    @Output() deleteItem = new EventEmitter<GenericFormGroup<FinancialTransferEntity>>();
    FINANCIAL_TRANSFER = FinancialTransferEntity;

    constructor(protected dialog: MatDialog) {}

    onClick() {
        const dialogRef = this.dialog.open(OverrideDialogComponent);
    }

    addAdditional() {
        const newFT = new FinancialTransferEntity()
            .setType(this.type)
            .setOrigin(this.origin)
            .setOriginIsExpense(this.isExpense);
        if (this.defaultSender) {
            newFT.setSender(this.defaultSender);
        }
        if (this.defaultSenderWildcardId) {
            newFT.setSenderWildcardId(this.defaultSenderWildcardId);
        }
        if (this.defaultReceiver) {
            newFT.setReceiver(this.defaultReceiver);
        }
        if (this.defaultReceiverWildcardId) {
            newFT.setReceiverWildcardId(this.defaultReceiverWildcardId);
        }
        if (this.defaultValueType) {
            newFT.setValueType(this.defaultValueType);
        }

        const newFG = new GenericFormGroup(newFT);

        if (!this.silent) {
            this.transfersFA.push(newFG);
        }

        this.addItem.emit(newFG);
    }

    removeAdditional(index: number) {
        this.deleteItem.emit(<GenericFormGroup<FinancialTransferEntity>>this.transfersFA.at(index));
    }

    ngOnInit() {
        if (this.limit === 1 && this.transfersFA.controls.length < 1) {
            this.addAdditional();
        }
    }

    isDisabledDelete(financialTransfer: GenericFormGroup<FinancialTransferEntity>, disabled: boolean) {
        if (financialTransfer.value.connected__invoice_fk_id || financialTransfer.value.connected__bill_fk_id) {
            return false;
        }
        return (financialTransfer.disabled || disabled) && !financialTransfer.value.payment_fk_id;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.addItem.complete();
        this.deleteItem.complete();
    }
}
