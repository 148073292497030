import {Profile} from '../../../models/profile';
import {GenericEntity} from '../../../entites/generic.entity';

export class GoalPeriodEntity extends GenericEntity {
    id: number | undefined = undefined;
    profile: Profile | null = null;
    goal_instance_fk_id: number | null = null;
    type: string | null = null;
    start_date: string | null = null;
    actual_total: number | null = null;
    expected_total: number | null = null;

    constructor() {
        super();
    }

    static get helpers() {
        return Object.assign(
            {
                props: Object.getOwnPropertyNames(new this()).reduce(
                    (acc, curr) => {
                        acc[curr] = curr;
                        return acc;
                    },
                    {} as {[key: string]: any}
                ),
                build: {
                    // values : (val, fabric) => val.map(item => GoalValueEntity.fabric[fabric](item)),
                    profile: (val: Profile, fabric = 'object.assign') => Object.assign(new Profile(), val)
                }
            },
            GenericEntity.helpers
        );
    }

    static get fabric() {
        return Object.assign(GenericEntity.fabric, {
            hydrate: (
                input: object,
                output: GoalPeriodEntity = new this(),
                props: {[prop: string]: string} = this.helpers.props,
                transform: {[id: string]: (val: any, fabric: any) => any} = this.helpers.build
            ): GoalPeriodEntity => {
                // @ts-ignore: might be null if input is null
                return <GoalPeriodEntity>GenericEntity.fabric.hydrate(input, output, props, transform);
            },
            dehydrate: (
                input: object | GoalPeriodEntity,
                output: object = {},
                props: {[prop: string]: string} = this.helpers.props,
                transform: {[id: string]: (val: any, fabric: any) => any} = this.helpers.build
            ): object => {
                // @ts-ignore: might be null if input is null
                return <object>GenericEntity.fabric.dehydrate(input, output, props, transform);
            }
        });
    }

    static get type() {
        return {
            day: 'day',
            week: 'week',
            month: 'month',
            quarter: 'quarter',
            year: 'year'
        };
    }
}
