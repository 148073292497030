<div class="mb-1">
    <div *ngIf="representativesArray">
        <app-notifications [notification_mode]="'popup'"></app-notifications>
    </div>

    <h3>Representatives</h3>
    <div class="px-3">
        <div class="mb-2">
            List the owner details for each individual person that holds at-least a twenty-five percent (25%) stake in
            the company. Also you have to define at least one of them as Controller.
        </div>
    </div>
    <div *ngIf="representativesArray">
        <div *ngFor="let representative of representativesArray.controls; let idx = index">
            <div class="d-flex my-2">
                <mat-card class="mx-3 w-95" [formGroup]="representative">
                    <div class="d-flex align-items-center">
                        <mat-checkbox formControlName="is_controller">Is Controller</mat-checkbox>
                        <mat-checkbox
                            formControlName="is_owner"
                            (change)="isOwnerChange($event, representative)"
                            class="ml-2"
                            >Is Owner</mat-checkbox
                        >
                        <mat-icon
                            class="ml-2"
                            style="color: rgb(151, 150, 150); vertical-align: middle"
                            matTooltip="If this person is both a Controller and Owner (with more than 25% of the outstanding shares), please check both boxes."
                            >info_outlined</mat-icon
                        >
                    </div>

                    <mat-form-field class="w-50 px-2">
                        <input matInput type="text" formControlName="job_title" required />
                        <mat-placeholder>Job Title</mat-placeholder>
                    </mat-form-field>
                    <span class="w-50 px-2" *ngIf="!representative.controls.is_owner.value"></span>
                    <mat-form-field class="w-50 px-2" *ngIf="representative.controls.is_owner.value">
                        <input
                            matInput
                            type="text"
                            (keypress)="['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes($event.key)"
                            step="1"
                            formControlName="ownership_percentage"
                        />
                        <mat-placeholder>The percentage of ownership</mat-placeholder>
                        <mat-icon
                            matSuffix
                            style="color: rgb(151, 150, 150); vertical-align: middle"
                            matTooltip="To be considered an owner, the person must have a 25% minimum of the company's outstanding shares."
                        >
                            info_outlined
                        </mat-icon>
                    </mat-form-field>

                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="first_name" />
                        <mat-placeholder>First Name</mat-placeholder>
                    </mat-form-field>
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="last_name" />
                        <mat-placeholder>Last Name</mat-placeholder>
                    </mat-form-field>

                    <mat-form-field class="w-50 px-2">
                        <input matInput type="text" formControlName="middle_name" />
                        <mat-placeholder>Middle Name</mat-placeholder>
                    </mat-form-field>
                    <mat-form-field class="w-50 px-2">
                        <input matInput type="text" formControlName="suffix" />
                        <mat-placeholder>Suffix</mat-placeholder>
                    </mat-form-field>

                    <div class="d-flex">
                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="email" />
                            <mat-placeholder>Email</mat-placeholder>
                        </mat-form-field>
                        <div class="d-flex w-50 px-2">
                            <app-date-picker
                                class="w-100"
                                [placeholder]="'Date Of Birth'"
                                [dateControl]="representative.controls.date_of_birth"
                                [startDate]="startDate"
                                [startView]="'year'"
                            ></app-date-picker>
                        </div>
                    </div>

                    <div>
                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="ssn" />
                            <mat-placeholder>Last four digits of SSN</mat-placeholder>
                        </mat-form-field>
                    </div>

                    <!--                    <mat-form-field class="w-50 px-2">-->
                    <!--                        <input matInput type="text" formControlName="itin">-->
                    <!--                        <mat-placeholder>itin</mat-placeholder>-->
                    <!--                    </mat-form-field>-->

                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="address_line_1" />
                        <mat-placeholder>Address Line 1 (No P.O. Box please)</mat-placeholder>
                    </mat-form-field>
                    <mat-form-field class="w-50 px-2">
                        <input matInput type="text" formControlName="address_line_2" />
                        <mat-placeholder>Address Line 2</mat-placeholder>
                    </mat-form-field>

                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="city" />
                        <mat-placeholder>City</mat-placeholder>
                    </mat-form-field>
                    <mat-form-field class="w-50 px-2">
                        <mat-select required formControlName="state">
                            <mat-option *ngFor="let state of stateProvinceRegionList" [value]="state.slug">{{
                                state.title
                            }}</mat-option>
                        </mat-select>
                        <mat-placeholder>State</mat-placeholder>
                    </mat-form-field>

                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="postalCode" />
                        <mat-placeholder>Zip (ex. 12345)</mat-placeholder>
                    </mat-form-field>
                    <mat-form-field class="w-50 px-2">
                        <input matInput required type="text" formControlName="phone_number" />
                        <mat-placeholder>Phone number</mat-placeholder>
                    </mat-form-field>
                </mat-card>

                <div>
                    <button
                        class=""
                        mat-icon-button
                        *ngIf="representativesArray.controls.length > 1"
                        (click)="removeRepresentative(idx)"
                    >
                        <mat-icon>remove</mat-icon>
                    </button>
                </div>
            </div>
            <div class="ml-3 mt-2">
                <button
                    class=""
                    *ngIf="idx + 1 === representativesArray.controls.length"
                    mat-raised-button
                    (click)="addRepresentative()"
                >
                    <mat-icon>add</mat-icon>
                    Representatives
                </button>
            </div>
        </div>
    </div>

    <div class="m-2 d-flex justify-content-end">
        <mat-checkbox [checked]="certify" (change)="certify = !certify">
            <span class="text-wrap d-flex" style="margin-top: 23px"
                >I, {{ sessionService?.user?.first_name }} {{ sessionService?.user?.last_name }}, hereby certify, to the
                best of my knowledge that the information provided is complete and correct.</span
            >
        </mat-checkbox>
    </div>
    <div class="d-flex justify-content-end">
        <button class="ml-2" type="button" mat-raised-button (click)="cancel()">Cancel</button>

        <button
            class="ml-2"
            [disabled]="!certify || lockButton"
            type="button"
            color="primary"
            mat-raised-button
            (click)="finish()"
        >
            Continue
        </button>
    </div>
</div>
