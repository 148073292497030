import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-checks-ledger-error-popup',
    templateUrl: './checks-ledger-error-popup.component.html',
    styleUrls: ['./checks-ledger-error-popup.component.scss']
})
export class ChecksLedgerErrorPopupComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<ChecksLedgerErrorPopupComponent>) {}

    ngOnInit(): void {}
}
