<section class="bg-white">
    <mat-tab-group mat-align-tabs="start" animationDuration="0ms" (selectedTabChange)="tabChanged($event)" #tabs>
        <mat-tab label="Completed">
            <app-zipi-fin-transfers-data-table
                [gatewayId]="gateway?.payment_gateway_id"
                [driverType]="gateway?.driver_type"
                [tabTag]="completedTabTag"
            >
            </app-zipi-fin-transfers-data-table>
        </mat-tab>
        <mat-tab label="Scheduled">
            <app-zipi-fin-transfers-data-table
                [gatewayId]="gateway?.payment_gateway_id"
                [driverType]="gateway?.driver_type"
                [tabTag]="scheduledTabTag"
            >
            </app-zipi-fin-transfers-data-table>
        </mat-tab>
        <mat-tab label="Canceled">
            <app-zipi-fin-transfers-data-table
                [gatewayId]="gateway?.payment_gateway_id"
                [driverType]="gateway?.driver_type"
                [tabTag]="canceledTabTag"
            >
            </app-zipi-fin-transfers-data-table>
        </mat-tab>
    </mat-tab-group>
</section>
