<div class="container">
    <!------- Header with routing ------->
    <app-account-info-toolbar
        [section]="'roles'"
        (createCompanyRoleEmitter)="createCompanyRole()"
        [isEditMode]="!!editRoleId"
    ></app-account-info-toolbar>

    <!-- showed in users and roles pages -->
    <app-sub-companies-panel
        permission="company_settings__manage_users"
        (currentProfileChanged)="currentProfileChanged($event)"
        (availableProfilesEmitter)="availableProfilesHandler($event)"
    ></app-sub-companies-panel>

    <!------- Role form with permissions ------->
    <div *ngIf="companyRoleForm" class="role-form px-3 mt-3" [ngStyle]="{height: offsetTop}">
        <!------- Role form ------->
        <div [formGroup]="$any(companyRoleForm)">
            <!------- Role type select ------->
            <div class="d-flex align-items-baseline">
                <mat-form-field>
                    <input matInput type="text" required formControlName="title" />
                    <mat-placeholder id="placeholder-name-from">Title</mat-placeholder>
                </mat-form-field>
            </div>

            <div *ngIf="availableCompanyFeaturePermissions" class="w-80">
                <div *ngFor="let moduleName of this.orderedCompanyFeaturePermissions; let i = index">
                    <app-company-role-feature
                        [module]="availableCompanyFeaturePermissions[moduleName]"
                        [companyRoleForm]="companyRoleForm"
                        [rawCompanyPermissionsObj]="rawCompanyPermissionsObj"
                    ></app-company-role-feature>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-between" style="margin-bottom: 50px; margin-top: 20px">
            <!------- Button delete role ------->
            <button
                type="button"
                mat-raised-button
                class="mat-warn"
                (click)="deleteCompanyRole()"
                matTooltip="Delete this Role"
            >
                Delete
            </button>
            <div class="d-flex">
                <!------- Button close editing/creating role ------->
                <button type="button" mat-raised-button (click)="cancel()">Cancel</button>
                <!------- Button save role ------->
                <button type="button" mat-raised-button class="ml-3 mat-primary" (click)="saveCompanyRole()">
                    Save Role
                </button>
            </div>
        </div>
    </div>

    <!------- List of roles ------->
    <div *ngIf="!companyRoleForm" class="roles-list mt-3" [ngStyle]="{height: offsetTop}">
        <div class="deals-list-area" *ngIf="companyRoles.length > 0">
            <ng-container *ngFor="let role of companyRoles">
                <div class="role-row mb-1">
                    <div class="role-card d-flex justify-content-between">
                        <div>{{ role.title }}</div>

                        <i class="sp-spacer"></i>
                        <button
                            mat-raised-button
                            [rule]="{company_settings__manage_roles: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            (click)="editCompanyRole(role)"
                        >
                            Edit
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
        <!------- No values ------->
        <div *ngIf="!isLoaded && companyRoles.length === 0" class="text-center p-4 text-muted">
            No company roles found
        </div>
    </div>

    <!------- Loading ------->
    <div class="load-container d-flex align-items-center justify-content-center" id="load-container" *ngIf="isLoaded">
        <mat-spinner></mat-spinner>
    </div>
</div>
