<section class="container-fluid" *ngIf="invoice">
    <!--    <div class="d-flex mt-2">-->
    <!--        <div  *ngIf="invoice.status_in_portal !== 'connected'">-->
    <!--            <button mat-raised-button-->
    <!--                    class="ml-2"-->
    <!--                    [disabled]="invoice.status_in_portal === 'archived'"-->
    <!--                    (click)="createAndConnectBillFromInvoice()"-->
    <!--                    [rule]="{purchases__view_bills: true}" rbac [denyMethod]="'style'" [allowStyle]="{display : 'inline-block'}" [denyStyle]="{display : 'none'}">-->
    <!--                Approve as Connected Bill-->
    <!--            </button>-->
    <!--        </div>-->
    <!--        <div  *ngIf="invoice.status_in_portal !== 'linked'">-->
    <!--            <button mat-raised-button-->
    <!--                    class="ml-2"-->
    <!--                    [disabled]="invoice.status_in_portal === 'archived' || invoice.status_in_portal === 'connected'"-->
    <!--                    (click)="createAndLinkBillFromInvoice()"-->
    <!--                    [rule]="{purchases__view_bills: true}" rbac [denyMethod]="'style'" [allowStyle]="{display : 'inline-block'}" [denyStyle]="{display : 'none'}">-->
    <!--                Approve as Linked Bill-->
    <!--            </button>-->
    <!--        </div>-->
    <!--        <div  *ngIf="invoice.status_in_portal !== 'archived'">-->
    <!--            <button mat-raised-button class="ml-2" [disabled]="invoice.status_in_portal === 'connected' || !invoice.money_sender_contact!.partner__company_fk_id" (click)="archiveInvoice()">Archive</button>-->
    <!--        </div>-->
    <!--    </div>-->
    <mat-card class="mt-3 px-4 py-5">
        <header class="row">
            <div class="col-6 zp-company-info">
                <!-- <p>Company Logo</p> -->
                <p class="zp-company-title">{{ invoice?.creator_company?.title }}</p>
                <div *ngFor="let location of invoice?.creator_company?.locations">
                    <p *ngIf="location.label === (invoice?.creator_company?.settings)!.billing_address_label">
                        <span *ngIf="location.street_number">{{ location.street_number }} </span>
                        <span *ngIf="location.street_address">{{ location.street_address }} </span>
                        <span *ngIf="location.unit_number">{{ location.unit_number }} </span>
                        <br />
                        <span *ngIf="location.city">{{ location.city }}, </span>
                        <span *ngIf="location.state">{{ location.state }} </span>
                        <span *ngIf="location.zip">{{ location.zip }} </span>
                        <br />
                        <span *ngIf="location.country" class="text-muted">({{ location.country }})</span>
                    </p>
                </div>
            </div>

            <div class="col-6 text-right">
                <p class="zp-inveice_title">Invoice</p>
                <p class="zp-invoice-num_value">#{{ invoice?.invoice_number }}</p>

                <p class="zp-refference_due_title">Amount: {{ invoice?.total_amount | currency }}</p>
            </div>
        </header>

        <p>Bill To</p>
        <div class="row" style="margin-bottom: 15px">
            <div class="col-6">
                <!--                <div class="mb-0" *ngFor="let moneySender of invoice.money_senders">-->
                <!-- {{moneySender?.billing_address?.location?.formatted_address}} -->
                <span *ngIf="invoice.money_sender_contact && invoice.money_sender_contact.display_name">
                    {{ invoice.money_sender_contact!.display_name }}<br />
                </span>
                <span *ngIf="invoice?.billing_address?.street_number"
                    >{{ invoice.billing_address!.street_number }}&nbsp;</span
                >
                <span *ngIf="invoice?.billing_address?.street_address">{{
                    invoice.billing_address!.street_address
                }}</span>
                <span *ngIf="invoice?.billing_address?.unit_number"
                    ><br />{{ invoice.billing_address!.unit_number }}</span
                >
                <br />
                <ng-container *ngIf="invoice?.billing_address?.city">
                    <span>{{ invoice.billing_address!.city + ',' }}&nbsp;</span>
                </ng-container>
                <span *ngIf="invoice?.billing_address?.state">{{ invoice.billing_address!.state }}&nbsp;</span>
                <span *ngIf="invoice?.billing_address?.zip">{{ invoice.billing_address!.zip }}&nbsp;</span>
                <span *ngIf="invoice?.billing_address?.country" class="text-muted"
                    ><br />({{ invoice.billing_address!.country }})</span
                >
                <!--                </div>-->
            </div>

            <div class="col-6 text-right">
                <p class="mb-2">
                    <span>Date:</span>
                    <span class="zp-w-150">{{ $any(invoice?.invoice_date) | dateFromNumber | date: 'longDate' }}</span>
                </p>

                <p class="mb-2">
                    <span>Terms:</span>
                    <span class="zp-w-150" *ngIf="invoice?.payment_terms && invoice?.payment_terms !== 'custom'">{{
                        termsMap.get($any(invoice?.payment_terms))
                    }}</span>
                    <span class="zp-w-150" *ngIf="invoice?.payment_terms && invoice?.payment_terms === 'custom'"
                        >Net {{ invoice?.payment_terms_custom }}</span
                    >
                </p>

                <p class="mb-2">
                    <span>Due Date:</span>
                    <span class="zp-w-150">{{ $any(invoice?.due_date) | dateFromNumber | date: 'longDate' }}</span>
                </p>
            </div>
        </div>

        <table class="table">
            <thead class="bg-dark text-white">
                <tr>
                    <td scope="col">#</td>
                    <td class="w-50" scope="col">Item</td>
                    <td scope="col">Qty</td>
                    <td scope="col">Rate</td>
                    <td scope="col" class="text-right">Amount</td>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of invoice?.items; index as idx">
                    <td>{{ idx + 1 }}</td>
                    <td class="w-50">
                        {{ item?.name }}
                        <i class="text-muted">{{ item?.description }}</i>
                    </td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.rate }}</td>
                    <td class="text-right">{{ item.amount }}</td>
                </tr>
            </tbody>
        </table>

        <div class="w-100 text-right">
            <p class="mb-2">
                Sub Total: <span class="zp-total-nember">{{ invoice?.total_amount }}</span>
            </p>
            <p class="mb-2">
                <strong
                    >Total: <span class="zp-total-nember font-weight-bold">{{ invoice?.total_amount | currency }}</span>
                </strong>
            </p>
            <div *ngIf="invoice.payments && invoice.payments.length > 0">
                <div *ngFor="let payment of invoice.payments; let i = index">
                    <strong
                        class="py-2"
                        *ngIf="['completed', 'processing', 'pending', 'review'].includes(payment.status)"
                        [ngStyle]="{
                            color:
                                payment.status === 'completed'
                                    ? 'black'
                                    : payment.status === 'pending'
                                      ? 'orange'
                                      : payment.status === 'canceled' || payment.status === 'error'
                                        ? '#f94242'
                                        : 'black'
                        }"
                    >
                        <span class="zp-w-150">&nbsp;</span>
                        <!--				        <mat-icon *ngIf="(payment.paid_by_payment_method?.payment_gateway?.type === 'authorize_net_customer' || (!payment.paid_by__payment_method_fk_id && payment.pay_to_payment_method?.payment_gateway?.type === 'authorize_net_merchant')) && payment.status === 'processing'"-->
                        <!--				                  (click)="recheckPaymentStatus(payment)"-->
                        <!--				                  matTooltip="Recheck Payment Status" class="mr-2">cached</mat-icon>-->
                        <span>
                            <span
                                *ngIf="
                                    payment.source_type === 'based_on_vendor_credit' ||
                                    payment.source_type === 'customer_credit'
                                "
                                >Credits</span
                            >
                            <span
                                *ngIf="
                                    payment.source_type === 'payment_made' ||
                                    payment.source_type === 'payment_received' ||
                                    payment.source_type === 'deduction'
                                "
                                >Payment</span
                            >
                        </span>
                        <strong class="zp-total-nember">{{ payment.amount | currency }}</strong>

                        <div *ngIf="payment.status === 'processing'" style="line-height: 2px">
                            <span class="zp-w-150"></span>

                            <span class="zp-total-nember" style="color: grey; font-size: 14px"> (processing) </span>
                        </div>

                        <div *ngIf="payment.status === 'pending'" style="line-height: 2px">
                            <span class="zp-w-150"></span>

                            <span class="zp-total-nember" style="color: grey; font-size: 14px"> (Deducted) </span>
                        </div>

                        <div
                            *ngIf="payment.status === 'canceled' || payment.status === 'error'"
                            style="line-height: 2px"
                        >
                            <span class="zp-w-150"></span>

                            <span class="zp-total-nember" style="color: grey; font-size: 14px">
                                ({{ payment.status }})
                            </span>
                        </div>
                    </strong>
                </div>
            </div>

            <p>
                <strong class="bg-light py-2">
                    <span class="zp-w-150">&nbsp;</span>
                    Balance:
                    <strong class="zp-total-nember">{{ invoice?.balance | currency }}</strong>
                </strong>
            </p>
            <p>
                <span class="bg-light py-2" *ngIf="isProcessingPayment()">
                    <span class="zp-w-150">&nbsp;</span>
                    Pending Balance:
                    <span class="zp-total-nember">{{ invoice?.pending_balance | currency }}</span>
                </span>
            </p>
        </div>

        <mat-form-field class="w-100" *ngIf="invoice.source__company_fk_id">
            <textarea
                matInput
                disabled
                cdkTextareaAutosize
                [value]="invoice.invoice_note"
                placeholder="Additional Info"
            >
            </textarea>
        </mat-form-field>

        <div class="d-flex my-2 align-items-baseline">
            <button
                mat-raised-button
                color="primary"
                [disabled]="invoice.pending_balance === 0"
                class="ml-2"
                (click)="payInvoice()"
            >
                Pay Invoice
            </button>
        </div>

        <div class="my-3">
            Related Deals
            <button mat-icon-button (click)="showTransfers = !showTransfers">
                <mat-icon *ngIf="showTransfers">expand_more</mat-icon>
                <mat-icon *ngIf="!showTransfers">chevron_right</mat-icon>
            </button>

            <div *ngIf="showTransfers">
                <table
                    mat-table
                    *ngIf="transfersDataSource.data && transfersDataSource.data.length > 0"
                    [dataSource]="transfersDataSource"
                    class="mat-elevation-z1 w-100 mx-3 journals-table"
                >
                    <ng-container matColumnDef="index">
                        <th mat-header-cell *matHeaderCellDef disableClear class="w-5">#</th>
                        <td mat-cell *matCellDef="let element; let i = index" class="w-5">{{ i + 1 }}</td>
                    </ng-container>

                    <ng-container matColumnDef="deal_address">
                        <th mat-header-cell *matHeaderCellDef class="w-45">Deal Address</th>
                        <td mat-cell *matCellDef="let element" class="w-45">
                            <span *ngIf="!element.deal_address" class="text-muted">No address </span>
                            <span *ngIf="element.deal_address"
                                >{{ element.deal_street_number }} {{ element.deal_address }} {{ element.deal_city }},
                                {{ element.deal_state }} {{ element.deal_zip }}
                            </span>
                            — {{ element.deal_type }}/{{ element.deal_status }}
                            <ng-container *ngIf="!element.deal_id">
                                <span *ngIf="!element.deal_address" class="text-muted">No address </span>
                                <span *ngIf="element.deal_address"
                                    >{{ element.deal_street_number }}&nbsp;{{ element.deal_address }} &nbsp;{{
                                        element.deal_city
                                    }},&nbsp;{{ element.deal_state }}&nbsp;{{ element.deal_zip }}
                                </span>
                                — {{ element.deal_type }}/{{ element.deal_status }}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="product">
                        <th mat-header-cell *matHeaderCellDef class="w-25">Product</th>
                        <td mat-cell *matCellDef="let element" class="w-25">
                            <span *ngIf="element.product_id; else elseBlock">
                                {{ element.product_name }}
                                <i class="text-muted">{{ element.product_description }}</i>
                            </span>
                            <ng-template #elseBlock>--</ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="connection_type">
                        <th mat-header-cell *matHeaderCellDef class="w-15">Connection Type</th>
                        <td mat-cell *matCellDef="let element" class="w-15">
                            {{ FINANCIAL_TRANSFER_TYPE_LABELS[element.type]! }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            style="text-align: right"
                            class="w-10 justify-content-end"
                        >
                            Amount
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            style="text-align: right"
                            class="w-10 justify-content-end"
                        >
                            {{ element.amount | currency }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="transferDisplayedColumns"></tr>
                    <tr
                        mat-row
                        class="journals-row"
                        *matRowDef="let row; let i = index; columns: transferDisplayedColumns"
                    ></tr>
                </table>
            </div>
        </div>
    </mat-card>
</section>
