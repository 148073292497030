import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../service.requester';
import {Observable} from 'rxjs';
import {QbCompanySettingsModel} from '../../../../models/addon/qb-company-settings-model';
import {QbProductMappingModel} from '../../../../models/addon/qb-product-mapping-model';
import {QbItemModel} from '../../../../models/addon/qb-item-model';
import {QbLedgerAccountMappingModel} from '../../../../models/addon/qb-ledger-account-mapping-model';

export interface AuthResponse {
    authRedirect: string;
}

@Injectable()
export class QuickBooksService {
    private url = '/quickbooks-addon/';

    constructor(public requester: ServiceRequester) {}

    getQbCompanySettings(): Observable<QbCompanySettingsModel> {
        return this.requester.makeMsCall$(this.url + 'company-settings', 'GET', 'shipp');
    }

    disableQbCompanySettings(): Observable<any> {
        return this.requester.makeMsCall$(this.url + 'company-settings', 'DELETE', 'shipp');
    }

    getRedirectUrl(): Observable<AuthResponse> {
        return this.requester.makeMsCall$(this.url + 'auth-redirect', 'GET', 'shipp');
    }

    getAccessToken(authUri: string): Observable<any> {
        return this.requester.makeMsCall$(this.url + 'access-token?' + authUri, 'GET', 'shipp');
    }

    getQBProductsServicesItems(): Observable<QbItemModel[]> {
        return this.requester.makeMsCall$(this.url + 'product-services-items', 'GET', 'shipp');
    }

    getProductMappings(): Observable<QbProductMappingModel[]> {
        return this.requester.makeMsCall$(this.url + 'mapped-products', 'GET', 'shipp');
    }

    createProductMappings(mappings: {books_product_ref: number; qb_item_ref: string}[]): Observable<void> {
        return this.requester.makeMsCall$(this.url + 'mapped-products', 'POST', 'shipp', mappings);
    }

    updateAutoSyncSettings(
        companySettingsId: number,
        settings: {auto_sync_invoices_enabled: boolean; auto_sync_bills_enabled: boolean}
    ): Observable<boolean> {
        return this.requester.makeMsCall$(
            this.url + `company-settings/${companySettingsId}/auto-sync`,
            'PUT',
            'shipp',
            settings
        );
    }

    getQbAccounts(): Observable<any[]> {
        return this.requester.makeMsCall$(this.url + 'qb-accounts', 'GET', 'shipp');
    }

    getLedgerMappings(): Observable<QbLedgerAccountMappingModel[]> {
        return this.requester.makeMsCall$(this.url + 'mapped-ledgers', 'GET', 'shipp');
    }

    createLedgerMappings(mappings: {books_ledger_account_ref: number; qb_account_ref: string}[]): Observable<void> {
        return this.requester.makeMsCall$(this.url + 'mapped-ledgers', 'POST', 'shipp', mappings);
    }

    setDefaultLedger(qbAccountRef: string | null) {
        return this.requester.makeMsCall$(this.url + 'default-ledger', 'POST', 'shipp', {qb_account_ref: qbAccountRef});
    }

    setDefaultProduct(qbProductRef: string | null) {
        return this.requester.makeMsCall$(this.url + 'default-product', 'POST', 'shipp', {
            qb_product_ref: qbProductRef
        });
    }
}
