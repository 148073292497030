import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {DealAffectedBy} from '../../../../../../models/deal-affected-by';
import {DealService} from '../../../../../../services/deal.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {GenericFormGroup} from '../../../../../../entites/generic.entity';
import {Deal} from '../../../../../../models/deal';

interface IBaseDeal {
    id: number;
    type: string;
    address: string;
    street_number: string;
    status: string;
    name: string;
}

@Component({
    selector: 'app-affected-by',
    templateUrl: './affected-by.component.html',
    styleUrls: ['./affected-by.component.scss']
})
export class AffectedByComponent implements OnInit, OnDestroy {
    moment = moment;
    private unsubscribe: Subject<void> = new Subject();
    affectedByActions: DealAffectedBy[] = [];
    isAllDismissed: boolean = true;
    hasDismissed: boolean = true;
    showDismissed: boolean = false;
    showDismissWarnings: boolean = false;
    showDetails: boolean = false;
    isBaseDealsLoaded: boolean = false;

    @Input() showDismissWarningEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() dealFormGroup: GenericFormGroup<Deal> = new GenericFormGroup(new Deal(), 'change');

    action_types_LABELS = {
        deal_approval: 'approved',
        deal_disapproval: 'disapproved'
    };

    constructor(private dealService: DealService) {}

    ngOnInit(): void {
        this.dealFormGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            const affectedBy = this.dealFormGroup.getRawValue().affected_by;
            this.isAllDismissed = affectedBy.every((warning) => warning.is_dismissed);
            this.hasDismissed = affectedBy.some((warning) => warning.is_dismissed);
        });

        this.showDismissWarningEventEmitter.pipe(takeUntil(this.unsubscribe)).subscribe((showDismissWarnings) => {
            this.showDismissWarnings = showDismissWarnings;
            this.getBaseDeals();
        });
    }

    getBaseDeals() {
        if ((this.showDetails || this.showDismissWarnings) && !this.isBaseDealsLoaded) {
            const dealAffectedBy = this.dealFormGroup.controls.affected_by!.getRawValue();
            this.dealService
                .getBaseDealsByIds(dealAffectedBy.map((affectedBy) => affectedBy.entity_id!))
                .then((baseDeals: IBaseDeal[]) => {
                    const newAffectedByActions: DealAffectedBy[] = [];
                    dealAffectedBy.forEach((affectedByAction) => {
                        const currentEntity = baseDeals.find((baseDeal) => baseDeal.id === affectedByAction.entity_id);
                        if (currentEntity) {
                            affectedByAction.entity_name = currentEntity.name;
                        }
                        newAffectedByActions.push(affectedByAction);
                    });
                    this.affectedByActions = newAffectedByActions;
                    this.isBaseDealsLoaded = true;
                });
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    dismissWarnings() {
        return this.dealService.dismissAffectedBy(this.dealFormGroup.controls.id!.value).then((result) => {
            this.dealFormGroup.controls.affected_by!.patchValue(result);
        });
    }
}
