import {Component, Input, ViewChild} from '@angular/core';
import {MatPaginator, type PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {PAGE_SIZE_OPTIONS} from '@app/local-typings';
import {IScrollData} from '@app/models/scroll-data';
import {IInvoice} from 'typings';

@Component({
    selector: 'app-statement-invoices-sidenav',
    templateUrl: './statement-invoices-sidenav.component.html',
    styleUrls: ['./statement-invoices-sidenav.component.scss']
})
export class StatementInvoicesSidenavComponent {
    dataSource: MatTableDataSource<any>;
    private _invoices: IInvoice[] = [];
    @Input()
    set invoices(invoices: IInvoice[]) {
        this._invoices = invoices ?? [];
        this.scrollData.total = invoices?.length ?? 0;
        this.scrollData.offset = 0;
        this.handlePageChange();
    }

    @Input() title = '';

    displayedColumns: Array<keyof IInvoice> = [
        'invoice_date',
        'invoice_number',
        'summary_status',
        'status_of_payment',
        'total_amount',
        'pending_balance'
    ];

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;
    pageSizeOptions = PAGE_SIZE_OPTIONS;
    private static defaultPageSize = PAGE_SIZE_OPTIONS[1];
    scrollData: IScrollData = {
        sort_column: 'invoice_date',
        sort_direction: 'asc',
        offset: 0,
        limit: StatementInvoicesSidenavComponent.defaultPageSize,
        total: 1
    };

    constructor() {
        this.dataSource = new MatTableDataSource<any>([]);
    }

    private handlePageChange(e?: PageEvent): void {
        if (e) {
            this.scrollData.limit = e.pageSize;
            this.scrollData.offset = e.pageSize * e.pageIndex;
        }

        const offset = this.scrollData.offset ?? 0;
        const limit = this.scrollData.limit ?? StatementInvoicesSidenavComponent.defaultPageSize;

        this.dataSource.data = this._invoices.slice(offset, offset + limit);
    }
}
