import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {ZipiFinancialTransferService} from '../../../../services/api/finance/zipi-financia-transfer.service';
import {ConfirmComponent} from '../../../../layouts/confirm/confirm.component';

@Component({
    selector: 'app-speedup-transfer-public-page',
    templateUrl: './speedup-transfer-public-page.component.html',
    styleUrls: ['./speedup-transfer-public-page.component.scss']
})
export class SpeedupTransferPublicPageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    transferHash: string | undefined;

    transferInfo:
        | {
              recipient: 'sender' | 'receiver';
              transfer_amount: number;
              is_allow_to_speedup: boolean;
              current_commission: number;
              new_commission: number;
          }
        | undefined;

    isResponseReceived: boolean = false;
    isSpeedUpSuccess: boolean = false;

    constructor(
        protected activatedRoute: ActivatedRoute,
        public dialog: MatDialog,
        protected zipiFinancialTransferService: ZipiFinancialTransferService
    ) {}

    ngOnInit() {
        this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            this.transferHash = params['transfer_hash'];

            if (this.transferHash) {
                this.zipiFinancialTransferService
                    .getZipiFinancialTransferInfo(this.transferHash)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe({
                        next: (result) => {
                            if (result) {
                                this.transferInfo = result;
                            }
                            this.isResponseReceived = true;
                        },
                        error: (error) => {
                            this.isResponseReceived = true;
                        }
                    });
            }
        });
    }

    speedup() {
        if (this.transferHash && this.transferInfo && this.transferInfo.is_allow_to_speedup) {
            this.zipiFinancialTransferService
                .requestTransferSpeedUp(this.transferHash!)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (result) {
                        this.isSpeedUpSuccess = true;
                    }
                });
            // const dialogRef = this.dialog.open(ConfirmComponent, {
            //     width: '400px',
            //     // minHeight: '400px',
            //     data: {
            //         title: 'Confirm Speed Up',
            //         message: 'Payment will be expedited.',
            //         buttonOkMessage: 'Confirm'
            //     }
            // });
            // dialogRef.afterClosed()
            //     .pipe(takeUntil(this.unsubscribe))
            //     .subscribe(ok => {
            //         if (ok) {
            //
            //         }
            //     });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
