import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RbacService} from '../../../rbac/rbac.service';
import {SessionService} from '../../../../services/session.service';

export const VIEW_MY_DEALS = 'my';
export const VIEW_SHARED_DEALS = 'shared';
export const VIEW_COMPANY_DEALS = 'company';

@Component({
    selector: 'app-deals-view-picker',
    templateUrl: 'deals-view-picker.component.html',
    styleUrls: ['deals-view-picker.component.scss']
})
export class DealsViewPickerComponent implements OnInit {
    public viewModesList = [VIEW_MY_DEALS, VIEW_COMPANY_DEALS, VIEW_SHARED_DEALS];

    @Output() modeChange = new EventEmitter();

    public selectedMode: string = 'my';

    protected viewCompanyModeDenied = false;

    @Input()
    get mode() {
        return this.selectedMode;
    }

    set mode(val) {
        this.selectedMode = val;
        this.modeChange.emit(this.selectedMode);
    }

    constructor(
        protected rbacService: RbacService,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {
        return this.rbacService.isAllowed({view_account_deals: true}).then((isAllowed) => {
            this.viewCompanyModeDenied = this.sessionService.profile?.type !== 'global' && !isAllowed;
        });
    }

    onChange(event: string) {
        this.mode = event;
    }

    isOptionsDisabled(modeOption: string) {
        if (modeOption === VIEW_COMPANY_DEALS && this.viewCompanyModeDenied) {
            return true;
        }

        return false;
    }
}
