<table
    mat-table
    *ngIf="recommendedDataSource && recommendedDataSource.data && recommendedDataSource.data.length > 0"
    [dataSource]="recommendedDataSource"
    class="mat-elevation-z8 match-table w-100 mb-4"
>
    <ng-container matColumnDef="match">
        <th mat-header-cell *matHeaderCellDef disableClear>
            <div class="d-flex justify-content-between align-items-center">
                <span>Recommended Matches</span>

                <!--                <mat-form-field class="">-->
                <!--                    <input matInput type="text" autocomplete="off"-->
                <!--                           placeholder="Search"-->
                <!--                           [formControl]="searchFilter"-->
                <!--                    >-->
                <!--                </mat-form-field>-->
            </div>
        </th>
        <td mat-cell *matCellDef="let transaction" class="d-flex align-items-center">
            <div class="d-inline-flex flex-wrap" style="width: calc(100% - 20px)">
                <div class="w-50">
                    <p *ngIf="transaction?.amount || transaction?.amount === 0">
                        <span class="text-capitalize">{{ transaction?.debit_or_credit }}</span
                        >: <span>{{ transaction?.amount | currency: 'USD' : 'symbol' }}</span>
                    </p>
                    <p>Dated: {{ transaction?.journal_date | dateFromNumber | date: 'mediumDate' }}</p>
                </div>
                <div class="w-50">
                    <p>
                        Reference#:
                        <a
                            *ngIf="
                                transaction.related_entity_id ||
                                ['manual_journal', 'opening_balance'].includes(transaction.reason_type)
                            "
                            [routerLink]="getReferenceLink(transaction)"
                        >
                            {{ transaction?.reference_number || (transaction.reason_type | fromSnakeCase) }}</a
                        >
                        <span
                            *ngIf="
                                !(
                                    transaction.related_entity_id ||
                                    ['manual_journal', 'opening_balance'].includes(transaction.reason_type)
                                )
                            "
                        >
                            {{ transaction?.reference_number || (transaction.reason_type | fromSnakeCase) }}</span
                        >
                    </p>
                    <p *ngIf="transaction?.check_number">Check#: {{ transaction?.check_number }}</p>
                </div>

                <p class="w-100" *ngIf="transaction?.contact_name">
                    Contact:
                    <a routerLink="/contacts/{{ transaction?.contact_fk_id }}">{{ transaction?.contact_name }}</a>
                </p>
            </div>

            <mat-checkbox
                style="width: 20px"
                *ngIf="transaction?.id"
                (click)="$event.stopPropagation()"
                (change)="$event.checked ? checked(transaction) : unchecked(transaction)"
                [checked]="isSelected(transaction)"
                [aria-label]="checkboxLabel(transaction)"
            >
            </mat-checkbox>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="banking-row"
        (click)="isSelected(row) ? unchecked(row) : checked(row)"
    ></tr>
</table>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 match-table w-100">
    <ng-container matColumnDef="match">
        <th mat-header-cell *matHeaderCellDef disableClear>
            <div class="d-flex justify-content-between align-items-center">
                <span>Possible Matches</span>

                <mat-form-field class="">
                    <input matInput type="text" autocomplete="off" placeholder="Search" [formControl]="searchFilter" />
                </mat-form-field>
            </div>
        </th>
        <td mat-cell *matCellDef="let transaction" class="d-flex align-items-center">
            <div class="d-inline-flex flex-wrap" style="width: calc(100% - 20px)">
                <div class="w-50">
                    <p *ngIf="transaction?.amount || transaction?.amount === 0">
                        <span class="text-capitalize">{{ transaction?.debit_or_credit }}</span
                        >: <span>{{ transaction?.amount | currency: 'USD' : 'symbol' }}</span>
                    </p>
                    <p>Dated: {{ transaction?.journal_date | dateFromNumber | date: 'mediumDate' }}</p>
                </div>
                <div class="w-50">
                    <p>
                        Reference#:
                        <a
                            *ngIf="
                                transaction.related_entity_id ||
                                ['manual_journal', 'opening_balance'].includes(transaction.reason_type)
                            "
                            [routerLink]="getReferenceLink(transaction)"
                        >
                            {{ transaction?.reference_number || (transaction.reason_type | fromSnakeCase) }}</a
                        >
                        <span
                            *ngIf="
                                !(
                                    transaction.related_entity_id ||
                                    ['manual_journal', 'opening_balance'].includes(transaction.reason_type)
                                )
                            "
                        >
                            {{ transaction?.reference_number || (transaction.reason_type | fromSnakeCase) }}</span
                        >
                    </p>
                    <p *ngIf="transaction?.check_number">Check#: {{ transaction?.check_number }}</p>
                </div>

                <p class="w-100" *ngIf="transaction?.contact_name">
                    Contact:
                    <a routerLink="/contacts/{{ transaction?.contact_fk_id }}">{{ transaction?.contact_name }}</a>
                </p>
            </div>

            <mat-checkbox
                style="width: 20px"
                *ngIf="transaction?.id"
                (click)="$event.stopPropagation()"
                (change)="$event.checked ? checked(transaction) : unchecked(transaction)"
                [checked]="isSelected(transaction)"
                [aria-label]="checkboxLabel(transaction)"
            >
            </mat-checkbox>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="banking-row"
        (click)="isSelected(row) ? unchecked(row) : checked(row)"
    ></tr>
</table>
<mat-paginator
    [length]="listLength"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    showFirstLastButtons="false"
></mat-paginator>

<mat-card class="adjustment">
    <p style="font-size: 0.8em" *ngIf="adjustmentTransactions.length">
        <b>Adjustments</b> will become transactions only after they are matched.
    </p>
    <ul class="match-transactions" *ngIf="adjustmentTransactions.length">
        <li *ngFor="let match of adjustmentTransactions; let i = index">
            <div>
                <p *ngIf="match.amount || match.amount === 0">
                    <span class="text-capitalize">{{ match.debit_or_credit }}</span> for:
                    <span>{{ match.amount | currency: 'USD' : 'symbol' }}</span>
                </p>
                <p *ngIf="match.date">Dated: {{ match.date | dateFromNumber | date: 'mediumDate' }}</p>
            </div>
            <button mat-icon-button color="warn" (click)="removeAdjustment(i)">
                <mat-icon>clear</mat-icon>
            </button>
        </li>
    </ul>

    <button mat-button class="mat-primary" (click)="addAdjustment()">
        <mat-icon>add</mat-icon>
        Add adjustment
    </button>
    <div class="d-flex align-items-center">
        Pending:&nbsp;<span>{{ pending | currency: 'USD' : 'symbol' }}</span>
    </div>
</mat-card>
<div class="footer-row">
    <button #matchButton mat-raised-button class="mat-primary" [disabled]="pending !== 0">Match</button>
    <button mat-button class="mat-primary" (click)="closeSidebar.emit()">Cancel</button>
</div>
