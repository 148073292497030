<div mat-dialog-title class="d-flex justify-content-between">
    <h3 *ngIf="paymentReceived">Apply Credits from Customer Advance Payment</h3>
    <h3 *ngIf="customerCredit">Apply Credits from {{ customerCredit.credit_note_number }}</h3>
    <button type="button" mat-icon-button (click)="dialogRef.close(false)">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div class="apply-calculations">
        <p>
            Balance: <span class="apply-amount">{{ balance | currency }}</span>
        </p>
    </div>

    <form [formGroup]="formGroup" class="invoices-form my-3">
        <table mat-table [dataSource]="dataSource" class="w-100">
            <ng-container matColumnDef="invoice_number">
                <th mat-header-cell *matHeaderCellDef>Invoice#</th>
                <td mat-cell *matCellDef="let element">
                    <a (click)="routeToInvoice(element)" href="javascript:">
                        {{ element.invoice_number || 'N/A' }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="invoice_date">
                <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.invoice_date | dateFromNumber | date: 'mediumDate' }}
                </td>
                <!--                <td mat-cell *matCellDef="let element"> {{element.controls['invoice_date'] | dateFromNumber | date: 'mediumDate'}} </td>-->
            </ng-container>

            <ng-container matColumnDef="total_amount">
                <th mat-header-cell *matHeaderCellDef>Total Amount</th>
                <td mat-cell *matCellDef="let element">{{ element.total_amount | currency }}</td>
            </ng-container>

            <ng-container matColumnDef="pending_balance">
                <th mat-header-cell *matHeaderCellDef>Balance</th>
                <td mat-cell *matCellDef="let element">{{ element.pending_balance | currency }}</td>
            </ng-container>

            <ng-container matColumnDef="amount_to_credit">
                <th mat-header-cell *matHeaderCellDef>Amount To Credit</th>
                <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroup]="invoicesArray.controls[i]"
                    style="width: 140px"
                >
                    <mat-form-field>
                        <input
                            matInput
                            type="text"
                            [maskito]="currencyMaskitoMask"
                            formControlName="amount_to_credit"
                            autocomplete="off"
                            (blur)="onBlurInvoice(i)"
                        />
                    </mat-form-field>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </form>
    <div *ngIf="dataSource.data.length === 0" class="m-3 text-center text-muted">No invoices for apply</div>
    <div class="apply-calculations">
        <p>
            Amount To Credit: <span class="apply-amount">{{ amountToCredit | currency }}</span>
        </p>
        <p>
            Remaining Credits: <span class="apply-amount">{{ remainingCredits | currency }}</span>
        </p>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-between align-items-end">
    <div class="text-muted" style="font-size: 12px">
        *Customer Credits can be applied only to not connected invoices
    </div>
    <div>
        <button
            mat-raised-button
            class="mr-3"
            color="primary"
            (click)="save()"
            tabindex="1"
            [disabled]="saveDisabled || remainingCredits < 0 || dataSource.data.length === 0 || amountToCredit === 0"
        >
            Save
        </button>
        <button mat-raised-button (click)="dialogRef.close()" tabindex="-1">Cancel</button>
    </div>
</mat-dialog-actions>
