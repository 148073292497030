import {UntypedFormGroup} from '@angular/forms';
import {Profile} from '../../../models/profile';

export * from '../../../models/forms/sign-in.form';

export class PickProfileModel {
    pickedProfile: Profile | undefined;
    submit: boolean | undefined;
}

export class PickProfileFourmGroup extends UntypedFormGroup {
    getRawValue(): PickProfileModel {
        return <PickProfileModel>super.getRawValue();
    }
}
