import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {SessionService} from '../../services/session.service';
import {environment} from '../../../environments/environment';
import {AppInitSource} from '../../services/sources/app-init.source';
import {SkyslopeAuth} from '../../modules/auth/services/okta/skyslope-auth.service';

declare var window: any;

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnDestroy {
    @Output() showAccountMenu = new EventEmitter();
    public isProduction = false;
    $event: any;

    // private authenticatedUsers = [];

    constructor(
        public authService: AuthService,
        protected appInitSource: AppInitSource,
        public sessionService: SessionService,
        public userService: UserService,
        public skyslopeAuthService: SkyslopeAuth,
        protected router: Router
    ) {
        this.isProduction = environment.env === 'production';
    }

    logout() {
        return this.authService.logout().then(async () => {
            await this.skyslopeAuthService.logout();
            if (environment.env === 'production') {
                window.location.replace(environment.marketingPageLogin);
            } else {
                window.location.replace(`${window.location.origin}${environment.unauthorizedUserRedirectPath}`);
            }
        });
    }

    showAccount() {
        this.showAccountMenu.emit();
    }

    redirectToCommunity() {
        return this.userService.getCommunityUrl().then((result) => {
            if (result && result.url) {
                window.location.replace(result.url);
            }
        });
    }

    showAddonHash() {
        alert(this.appInitSource.lastAppInitResponse?.zipiAddonHash);
    }

    ngOnDestroy(): void {
        this.showAccountMenu.complete();
    }
}
