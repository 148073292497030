import {Component, Input, OnInit} from '@angular/core';
import {Deal} from '../../../../../../../models/deal';
import {DealService} from '../../../../../../../services/deal.service';
import {GenericFormGroup} from '../../../../../../../entites/generic.entity';
import {AuditLog} from '../../../../../../../models/audit';

@Component({
    selector: 'app-audit-log-container',
    styleUrls: ['./audit-log-container.component.scss'],
    templateUrl: 'audit-log-container.component.html'
})
export class AuditLogContainerComponent implements OnInit {
    @Input() dealFormGroup: GenericFormGroup<Deal> = new GenericFormGroup(new Deal(), 'change');

    public auditLogs: AuditLog[] = [];

    public componentIsVisible = false;

    public isLoading = false;

    constructor(protected dealsService: DealService) {}

    ngOnInit() {
        return this.getDealAuditLogs();
    }

    triggerAuditComponentVisibility() {
        this.componentIsVisible = !this.componentIsVisible;

        if (!this.componentIsVisible) {
            return;
        }

        return this.getDealAuditLogs();
    }

    protected getDealAuditLogs() {
        const dealId = this.dealFormGroup.controls.id!.value;
        if (dealId) {
            this.isLoading = true;
            this.dealsService.getAudits(dealId).then((auditLogs) => {
                this.auditLogs = auditLogs;
                this.isLoading = false;
            });
        }
    }
}
