import {Injectable} from '@angular/core';
import {merge as observableMerge, Observable, Subject} from 'rxjs';
import {RolesService} from '../../modules/account-info/services/roles.service';
import {startWith} from 'rxjs/operators';
import {IRole} from '@cyberco-nodejs/zipi-typings/index';

@Injectable()
export class AvailableRolesSource {
    public sources: {[key: string]: Subject<{systemRoles: IRole[]; companyRoles: IRole[]}>} = {
        load: new Subject<{systemRoles: IRole[]; companyRoles: IRole[]}>()
    };

    private _source: Observable<{systemRoles: IRole[]; companyRoles: IRole[]}> = observableMerge(
        ...Object.keys(this.sources).map((key) => this.sources[key])
    );
    private _tmp: {systemRoles: IRole[]; companyRoles: IRole[]} | null = null;
    constructor(public rolesService: RolesService) {
        this.load();
    }

    connect() {
        return this.source;
    }

    disconnect() {}

    public load(): Promise<{systemRoles: IRole[]; companyRoles: IRole[]}> {
        return this.rolesService
            .getAvailableRoles()
            .toPromise()
            .then((res) => {
                this._tmp = res.result;
                this.sources.load.next(this._tmp!);
                return res;
            });
    }

    public get source(): Observable<{systemRoles: IRole[]; companyRoles: IRole[]}> {
        if (this._tmp) {
            return this._source.pipe(startWith(this._tmp));
        }
        return this._source;
    }
}
