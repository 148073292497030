import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'app-report-edit-bar-deal-picker',
    templateUrl: './deal-picker.component.html',
    styleUrls: ['./deal-picker.component.scss']
})
export class DealPickerComponent implements OnInit, OnDestroy {
    @Output() valueChanges: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
    @Input() value: Array<string> = [];
    @Input() placeholder: string = 'Associated Deal';

    private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
    public formControl: UntypedFormControl = new UntypedFormControl([]);

    ngOnInit() {
        this.initData();
        this.initSubscription();
    }

    initData() {
        const value = this.value || [];
        this.formControl.patchValue(value);
    }

    initSubscription() {
        this.formControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            this.valueChanges.emit(value);
        });
    }

    ngOnDestroy() {
        this.valueChanges.complete();
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }
}
