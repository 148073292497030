import {merge as observableMerge, Observable, Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AppInitResponse} from '../api/app-init.api';

@Injectable()
export class AppInitSource {
    public triggers = {
        initApp: new Subject<AppInitResponse>(),
        loadProfile: new Subject<AppInitResponse>()
    };

    private events = {
        appInit: <Observable<AppInitResponse>>observableMerge(this.triggers.initApp, this.triggers.loadProfile)
    };

    public lastAppInitResponse: AppInitResponse | null = null;

    public get appInit(): Observable<AppInitResponse> {
        if (this.lastAppInitResponse) {
            return this.events.appInit.pipe(startWith(this.lastAppInitResponse));
        }
        return this.events.appInit;
    }

    constructor(protected router: Router) {
        this.appInit.subscribe((last) => {
            this.lastAppInitResponse = last;
        });
    }

    resetLastAppInitResponse() {
        this.lastAppInitResponse = null;

        // TODO refactor this
        // this.triggers.initApp.next(new AppInitResponse());
    }
}
