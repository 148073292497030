import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {DealProcessingService} from '../../../deal-processing.service';
import {DealFinancialApiService} from '../../../deal-financial.api.service';
import {IDealDeposit, IDealDepositRelease, IDealDepositRequest, IPayment} from '@cyberco-nodejs/zipi-typings';
import {Deal} from '../../../../../../../models/deal';
import {GenericFormGroup} from '../../../../../../../entites/generic.entity';

@Component({
    selector: 'app-deal-deposit-page',
    templateUrl: './deal-deposit-page.component.html',
    styleUrls: ['./deal-deposit-page.component.scss']
})
export class DealDepositPageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() dealFG: GenericFormGroup<Deal> = new GenericFormGroup(new Deal(), 'change');
    @Input() isDepositShow: boolean;
    @Output() needDepositClose = new EventEmitter();
    @Output() isDepositButtonNeed = new EventEmitter();

    @Output() needSave = new EventEmitter();

    emptyRequests: boolean;

    dealId: number | undefined;
    dealAddress: string | undefined;
    dealName: string | undefined | null;

    requestsList: IDealDepositRequest[] = [];
    paymentsList: IPayment[] = [];
    releaseList: IDealDepositRelease[] = [];

    dealDeposit: IDealDeposit | undefined;

    constructor(
        public dealProcessingService: DealProcessingService,
        private dealFinancialApiService: DealFinancialApiService
    ) {
        this.isDepositShow = false;
        this.emptyRequests = false;
    }

    ngOnInit() {
        this.dealProcessingService.dealFG.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((deal) => {
            if (deal.id && !this.dealId) {
                this.dealId = deal.id;
                this.dealAddress = this.getDealFullAddress(this.dealProcessingService.dealFG.getRawValue());
                this.dealName = this.dealProcessingService.dealFG.getRawValue().name;
                this._getDepositData();
            }
        });
        // if (!this.dealId) {
        //     this.dealId = this.dealProcessingService.dealFG.controls.id.value;
        //     console.dir(this.dealId)
        //
        //     if (!this.dealId) {
        //         return;
        //     }
        //     this._getDepositData();
        // } else if (!this.requestsList) {
        //     this.isDepositShow = !this.isDepositShow;
        //     this._getDepositData();
        // } else {
        //     this.isDepositShow = !this.isDepositShow;
        // }
    }

    closeDeposit() {
        this.isDepositButtonNeed.emit(true);
        this.needDepositClose.emit(true);
    }

    showDeposit() {
        if (!this.dealId) {
            this.dealId = this.dealProcessingService.dealFG.controls.id!.value;
            if (!this.dealId) {
                return;
            }
            this.isDepositShow = !this.isDepositShow;
            this._getDepositData();
        } else if (!this.requestsList) {
            this._getDepositData();
        }
    }

    refreshRequestsList() {
        this._getDepositData();
    }

    // refreshPayments() {
    //     this._getPaymentsList();
    // }

    _getDepositData() {
        this.dealFinancialApiService
            .getDealDepositRequests(this.dealId!)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((depositRequests) => {
                if (depositRequests) {
                    if (depositRequests.length > 0) {
                        this.isDepositShow = true;
                        this.isDepositButtonNeed.emit(false);
                    } else {
                        this.emptyRequests = true;
                        this.isDepositButtonNeed.emit(true);
                    }
                    this.requestsList = depositRequests;
                    this.paymentsList = [];
                    depositRequests.forEach((request) => {
                        this.paymentsList = this.paymentsList.concat(request.payments!);
                    });
                }
            });

        this.dealFinancialApiService
            .getDealDepositReleases(this.dealId!)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((depositRleases) => {
                if (depositRleases) {
                    this.releaseList = depositRleases;
                }
            });

        this.dealFinancialApiService
            .getDealDepositAmounts(this.dealId!)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((dealDeposit) => {
                if (dealDeposit) {
                    this.dealDeposit = dealDeposit;
                }
            });
    }
    //
    // _getPaymentsList() {
    //     this.dealFinancialApiService.getDealDepositRequests(this.dealId)
    //         .pipe(takeUntil(this.unsubscribe))
    //         .subscribe(depositRequests => {
    //             if (depositRequests) {
    //                 this.requestsList = depositRequests;
    //             }
    //         });
    // }

    getDealFullAddress(deal: Deal) {
        let fullAddress = '';
        if (deal.address) {
            if (deal.street_number) {
                fullAddress += `${deal.street_number} `;
            }
            fullAddress += `${deal.address} `;
            if (deal.unit) {
                fullAddress += `${deal.unit}, `;
            }
            fullAddress += `${deal.city}, `;
            fullAddress += `${deal.state} `;
            fullAddress += deal.zip;
        } else {
            fullAddress = 'Property address';
        }
        return fullAddress;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.needDepositClose.complete();
        this.isDepositButtonNeed.complete();
        this.needSave.complete();
    }
}
