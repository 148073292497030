<div mat-dialog-title>
    <h3>Create a Contact in Yodata</h3>
</div>

<mat-dialog-content>
    <form [formGroup]="createContactForm">
        <!--"givenName": "Yodata",-->
        <!--"additionalName": "Test3", OPTIONAL-->
        <!--"familyName": "Smith",-->
        <!--"email": "yodatatest3@example.com.fake",-->
        <!--"telephone": "(000) 000-0000",-->
        <!--"branchCode": "AK702-001",-->
        <!--"jobTitle": "Sales Associate", OPTIONAL: default = Sales Associate-->
        <!--"roleName": "Part Time Sales Professional",-->
        <div class="row">
            <div class="col-6">
                <mat-form-field class="w-100">
                    <mat-placeholder>Given Name</mat-placeholder>
                    <input matInput required type="text" formControlName="givenName" />
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="w-100">
                    <mat-placeholder>Additional Name</mat-placeholder>
                    <input matInput type="text" formControlName="additionalName" />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <mat-form-field class="w-100">
                    <mat-placeholder>Family Name</mat-placeholder>
                    <input matInput required type="text" formControlName="familyName" />
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="w-100">
                    <mat-placeholder>Email</mat-placeholder>
                    <input matInput required type="text" formControlName="email" />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <mat-form-field class="w-100">
                    <input
                        matInput
                        formControlName="telephone"
                        type="text"
                        placeholder="Telephone"
                        [maskito]="phoneMaskitoInputMask"
                    />
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="w-100">
                    <!--                    <mat-label>Office ID (branch code)</mat-label>-->
                    <!--                    <mat-icon-->
                    <!--                        matSuffix-->
                    <!--                        matTooltip="Office's POD URI is https://zz123-001.bhhs.hsfaffiliates.com/profile/card#me where 'zz123-001' is Office ID"-->
                    <!--                    >help_outline</mat-icon>-->
                    <!--                    <input matInput required type="text" placeholder="zz123-001" formControlName="branchCode">-->

                    <!--yodata_mapping.yodata_pod_mapping_id-->
                    <mat-icon
                        matSuffix
                        matTooltip="This contact will be created in the office with this selected POD URI. Valid POD URI format: https://zz123-001.bhhs.hsfaffiliates.com/profile/card"
                        >help_outline</mat-icon
                    >
                    <mat-select formControlName="branchCode">
                        <mat-option
                            [matTooltip]="
                                division.yodata_mapping && division.yodata_mapping.pod_uri
                                    ? division.yodata_mapping.pod_uri
                                    : 'No POD URI for this division. Please use import to get it from Yodata or add it manually at Office Locations tab'
                            "
                            [disabled]="!division.yodata_mapping || !division.yodata_mapping.pod_uri"
                            *ngFor="let division of divisions"
                            [value]="division.yodata_mapping?.pod_uri"
                        >
                            {{ division.title }}
                        </mat-option>
                    </mat-select>
                    <mat-placeholder>Office POD URI (branch code)</mat-placeholder>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <mat-form-field class="w-100">
                    <mat-placeholder>Job Title</mat-placeholder>
                    <input matInput type="text" formControlName="jobTitle" />
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="w-100">
                    <mat-placeholder>Role</mat-placeholder>
                    <input matInput required type="text" formControlName="roleName" />
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
    <button type="button" class="mr-2" mat-raised-button (click)="closeForm(true)" tabindex="-1">Cancel</button>
    <button mat-raised-button type="button" class="mr-2" color="primary" (click)="closeForm(false)" tabindex="1">
        Create
    </button>
</mat-dialog-actions>
