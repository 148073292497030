import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {DealTemplateEntity} from '../../../../common/deal.models';
import {Deal, DEAL_DISBURSEMENT_STATUS, DEAL_SYSTEM_STATUS} from '../../../../../../../../models/deal';
import {SalesEntity} from '../../../../../../../../models/sales-entity';
import {Router} from '@angular/router';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {NotificationsService} from 'angular2-notifications';
import {DealService} from '../../../../../../../../services/deal.service';
import {RbacService} from '../../../../../../../rbac/rbac.service';
import {AuthService} from '../../../../../../../../services/auth.service';

@Component({
    selector: 'app-expanded-deal-list-item',
    templateUrl: './expanded-deal-list-item.component.html',
    styleUrls: ['./expanded-deal-list-item.component.scss']
})
export class ExpandedDealListItemComponent implements OnInit, OnDestroy {
    DEAL_SYSTEM_STATUS = DEAL_SYSTEM_STATUS;
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('checkbox') checkbox: ElementRef | undefined = undefined;

    @Input() deal: Deal | undefined = undefined;
    @Input() idx: number | undefined = undefined;
    @Input() dealTemplates: {[id: number]: DealTemplateEntity} | null = null;
    @Input() dealsIdsToRemove: {id: number; dual_deal_exists: boolean}[] = [];

    @Output() selectedDealEmitter: EventEmitter<Deal> = new EventEmitter<Deal>();
    @Output() toggleDealCheckboxEmitter: EventEmitter<{control: MatCheckboxChange; deal: Deal}> = new EventEmitter();

    SALES_ENTITY = SalesEntity;
    DEAL = Deal;
    checked: boolean = false;

    constructor(
        public router: Router,
        public dealService: DealService
    ) {}

    ngOnInit() {}

    getDisbursementStatus(status: keyof typeof DEAL_DISBURSEMENT_STATUS) {
        return DEAL_DISBURSEMENT_STATUS[status];
    }

    getDisbursementStatusToolTip(status: keyof typeof DEAL_DISBURSEMENT_STATUS) {
        switch (DEAL_DISBURSEMENT_STATUS[status]) {
            case DEAL_DISBURSEMENT_STATUS.incomplete_deal:
                return 'Some required information is still missing from this deal';
            case DEAL_DISBURSEMENT_STATUS.pending_approval:
                return 'Deal is ready for review and approval';
            case DEAL_DISBURSEMENT_STATUS.pending_payment_confirmation:
                return 'There are closing payments awaiting confirmation';
            case DEAL_DISBURSEMENT_STATUS.pending_remaining_payout:
                return 'There are incomplete remaining payouts';
            case DEAL_DISBURSEMENT_STATUS.payments_processed:
                return 'All payments and confirmations are complete';
        }
    }

    isDateInPast(date: number | string | Date) {
        const currentDate = new Date();
        const targetDate = new Date(date);

        return currentDate > targetDate;
    }

    async handleClickOnDeal(deal: Deal) {
        if (await this.dealService.checkIfEditDealAllowed(deal.primary_sales_entity?.owner__profile_fk_id)) {
            return await this.router.navigate(['/deals', 'edit', deal.deal_id]);
        } else {
            this.selectedDealEmitter.emit(deal);
        }
    }

    public async updateDealStatus(deal: Deal, status: string) {
        // update only status
        if (typeof deal.id === 'number') {
            await this.dealService.updateDealStatus(deal.id, {status});
            deal.status = status;
        }
    }

    public object_keys(obj: {[key: string]: any}) {
        return Object.keys(obj);
    }

    /** Need function, not pipe, because DOM is changing and pipe doesn't check it */
    checkIfDealChecked(): boolean {
        const deal = this.dealsIdsToRemove.find((d) => typeof this.deal !== 'undefined' && d.id === this.deal.id);
        return !!(deal && deal.id);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.selectedDealEmitter.complete();
        this.toggleDealCheckboxEmitter.complete();
    }
}
