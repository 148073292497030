import {Component, Inject, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormGroup, UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil, filter, map, tap} from 'rxjs/operators';
import {IGooglePlaceObject} from '@cyberco-nodejs/zipi-typings';
import {extractLocationComponents} from '../../store/contact.utilities';

@Component({
    selector: 'app-add-location-dialog',
    templateUrl: 'add-location-dialog.component.html'
})
export class AddLocationDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    locationGroup: UntypedFormGroup | null;
    locationPickerCtrl: UntypedFormControl | null = new UntypedFormControl();

    constructor(
        public dialogRef: MatDialogRef<AddLocationDialogComponent>,
        private fb: UntypedFormBuilder,
        private changeDetector: ChangeDetectorRef | null
    ) {
        this.locationGroup = this.fb.group({
            label: '',
            abbr: '',
            unit_number: '',
            street_number: '',
            street_address: '',
            zip: '',
            city: '',
            state: '',
            country: ''
        });
    }

    ngOnInit() {
        if (this.locationPickerCtrl) {
            this.locationPickerCtrl.valueChanges
                .pipe(
                    filter((l) => l),
                    map((lcn: IGooglePlaceObject) => extractLocationComponents(lcn)),
                    tap((location) => this.locationGroup!.patchValue(location)),
                    tap(() => this.changeDetector!.detectChanges()),
                    tap(() => this.locationPickerCtrl!.reset(null)),
                    takeUntil(this.unsubscribe)
                )
                .subscribe();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.changeDetector = null;
        this.locationPickerCtrl = null;
        this.locationGroup = null;
    }
}
