import {Component, Input} from '@angular/core';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';
import {IReportsPreviewResultData} from '../../../../store/reports.reducer';
import {entityRouterListByJournalType} from '../../../../constants';

@Component({
    selector: 'reports-preview-general-ledger',
    templateUrl: './general-ledger.component.html',
    styleUrls: ['./general-ledger.component.scss']
})
export class GeneralLedgerPreviewComponent {
    @Input() reportData: Array<any> = [];
    public entityRouterListByJournalType = entityRouterListByJournalType;

    _reportData: IReportsPreviewResultData[] = [];
    dataSource = new MatTreeNestedDataSource<any>();
    treeControl = new NestedTreeControl<any>((node) => node.children);
    isExpand: boolean = false;
    expandTooltip: string = 'Expand All';

    /**
     * Check is tree has child item
     * @param _
     * @param node
     */
    hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

    constructor() {}

    /**
     * Prepare total for child
     * @param data
     */
    prepareTotalForChild = (data: Array<any>) => {
        const result: IReportsPreviewResultData[] = [];
        data.forEach((item) => {
            if (item.children && item.children.length) {
                const children = item.children.slice(0);
                children.push({
                    la_name: `Total ${item.la_name}`,
                    type: 'total',
                    debit: item.debit,
                    debit_with_children: item.debit_with_children,
                    credit: item.credit,
                    credit_with_children: item.credit_with_children,
                    total: item.total,
                    total_with_children: item.total_with_children
                });
                result.push({
                    ...item,
                    children: this.prepareTotalForChild(children)
                });
            } else {
                result.push(item);
            }
        });

        return result;
    };

    /**
     * Change state node
     * @param node
     */
    changeState(node: {expanded: boolean}) {
        node.expanded = !node.expanded;
    }

    /**
     * Expand All
     */
    toggleExpand() {
        if (this.isExpand) {
            this.treeControl.collapseAll();
            this.expandTooltip = 'Expand All';
        } else {
            this.treeControl.expandAll();
            this.expandTooltip = 'Collapse All';
        }
        this.isExpand = !this.isExpand;
    }
}
