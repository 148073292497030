<div class="filter-items-container">
    <mat-list class="flex-item">
        <span matSubheader class="subtitle">Disbursement Status</span>
        <mat-divider class="horizontal-divider"></mat-divider>
        <mat-list-item *ngFor="let disbursement_status of object_keys(filters.disbursement_statuses)">
            <mat-checkbox
                (click)="$event.stopPropagation()"
                [checked]="tempFilters.disbursement_statuses[disbursement_status]"
                (change)="selectDisbursementStatus(disbursement_status)"
            >
                <span>{{ DEAL.disbursement_statuses_LABELS[disbursement_status] }}</span>
            </mat-checkbox>
        </mat-list-item>
    </mat-list>

    <mat-divider class="vertical-divider"></mat-divider>

    <mat-list class="flex-item">
        <span matSubheader class="subtitle">Deal Status</span>
        <mat-divider class="horizontal-divider"></mat-divider>
        <mat-list-item *ngFor="let status of object_keys(filters.statuses)">
            <mat-checkbox
                (click)="$event.stopPropagation()"
                [checked]="tempFilters.statuses[status]"
                (change)="selectStatus(status)"
            >
                <span>{{ DEAL.status_LABELS[status] }}</span>
                <span class="ml-1" *ngIf="boardMeta && hasOwnProperty(boardMeta, status)">
                    ({{ boardMeta[status] ? boardMeta[status] : 0 }})
                </span>
            </mat-checkbox>
        </mat-list-item>
    </mat-list>

    <mat-divider class="vertical-divider"></mat-divider>

    <mat-list class="flex-item">
        <span matSubheader class="subtitle">Deal Types</span>
        <mat-divider class="horizontal-divider"></mat-divider>
        <mat-list-item *ngFor="let type of object_keys(filters.types)">
            <mat-checkbox
                (click)="$event.stopPropagation()"
                [checked]="tempFilters.types[type]"
                (change)="selectType(type)"
            >
                <span>{{ DEAL.type_LABELS[type] ? DEAL.type_LABELS[type] : 'None' }}</span>
            </mat-checkbox>
        </mat-list-item>
    </mat-list>
</div>
<mat-divider></mat-divider>
<div class="footer">
    <button mat-raised-button class="clear-filters" (click)="clearFilters()">Clear Filters</button>
    <button mat-raised-button color="primary" class="apply" (click)="applyFilters()">Apply</button>
</div>
