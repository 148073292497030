import {Subject} from 'rxjs';
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder} from '@angular/forms';
import {NotificationsService} from 'angular2-notifications';
import {ReconciliationsService} from '../../../services/reconciliations.service';
import {filter, map, takeUntil} from 'rxjs/operators';
import {TransactionsService} from 'app/services/api/finance/transactions.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {AdjustmentDialogComponent} from '../../sidebar/adjustment-dialog/adjustment-dialog.component';
import {SelectionModel} from '@angular/cdk/collections';
import {
    IAdjustmentTransaction,
    ILedgerAccount,
    IReconciliation,
    ITransactionListItem
} from '@cyberco-nodejs/zipi-typings';
import {LedgerAccountService} from 'app/services/api/finance/ledger-accounts.service';
import Decimal from 'decimal.js-light';

interface IBaseReconciliationData {
    start_date: number | null;
    end_date: number | null;
    closing_balance: number | null;
    closing_balance_debit_or_credit: 'debit' | 'credit' | null;
    opening_balance: number | null;
    opening_balance_debit_or_credit: 'debit' | 'credit' | null;
}

@Component({
    selector: 'app-create-reconciliation',
    styleUrls: ['../../banking.component.scss', './create-reconciliation.component.scss'],
    templateUrl: 'create-reconciliation.component.html'
})
export class CreateReconciliationComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator = new MatPaginator(
        new MatPaginatorIntl(),
        ChangeDetectorRef.prototype
    );

    dataSource: MatTableDataSource<ITransactionListItem>;
    displayedColumns: string[] = [
        'created_at',
        'contact_name',
        'description',
        'type',
        'deposits',
        'withdrawals',
        'select'
    ];
    selection = new SelectionModel<any>(true, []);
    adjustmentTransactions: IAdjustmentTransaction[] = [];

    createDisabled: boolean = false;

    reconciliationId: number | null = null;
    reconciliation: IReconciliation | undefined;
    lastReconciliation: IReconciliation | null = null;
    ledgerAccount: ILedgerAccount | undefined;
    ledgerAccountId: number | null = null;

    baseData: IBaseReconciliationData | null = null;
    openingBalance: number | null = null;
    closingBalance: number | null = null;
    accountOpeningBalance: {
        opening_balance_date: number;
        debit_or_credit: 'debit' | 'credit';
        debit: number;
        credit: number;
        total: number;
    } | null = null;

    transactions: (ITransactionListItem & {check_info: any})[] = [];

    pageIndex: number = 0;
    listLength: number = 0;
    pageSize: number = 10;
    pageSizeOptions: number[] = [10, 20, 50];

    clearedAmount: number = 0;
    differAmount: number = 0;

    transactionLimit: number = 10000;

    matchedTransactionCounter: number = 0;

    isLoaded: boolean;

    constructor(
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService,
        private route: ActivatedRoute,
        private router: Router,
        private reconciliationsService: ReconciliationsService,
        private transactionsService: TransactionsService,
        private ledgerAccountService: LedgerAccountService,
        public dialog: MatDialog
    ) {
        this.dataSource = new MatTableDataSource<ITransactionListItem>([]);
        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['id']) {
                this.ledgerAccountId = Number(params['id']);
            }
            if (params['reconciliation-id']) {
                this.reconciliationId = Number(params['reconciliation-id']);
            }
        });
        this.isLoaded = false;
    }

    ngOnInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.pageSize = data.pageSize;
            this.pageIndex = data.pageIndex;

            this.loadTransactions();
        });

        this.selection.changed.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.countPending();
            this.countMatchedOnPage();
        });
        this.ledgerAccountService
            .getLedgerAccountById(this.ledgerAccountId as number)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((la) => {
                if (la) {
                    this.ledgerAccount = la;
                }
            });

        this.route.paramMap
            .pipe(
                map((pm) => +(pm.get('reconciliation-id') as string)),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                if (id) {
                    this.reconciliationsService
                        .getAccountReconciliationById(id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((data) => {
                            this.reconciliation = data;

                            this.startReconciliation(
                                {
                                    start_date: data.start_date,
                                    end_date: data.end_date,
                                    closing_balance: data.closing_balance,
                                    closing_balance_debit_or_credit: data.closing_balance_debit_or_credit,
                                    opening_balance: data.opening_balance,
                                    opening_balance_debit_or_credit: data.opening_balance_debit_or_credit
                                },
                                true
                            );

                            data.transactions?.map((tr) => this.selection.select(tr));
                            data.draft_transactions?.map((tr) => this.selection.select(tr));

                            this.adjustmentTransactions = data.adjustment_transactions;
                            delete this.reconciliation.draft_transactions;
                            this.countPending();

                            this.loadTransactions();
                        });
                } else {
                    this.reconciliationsService
                        .getLastAccountReconciliation(this.ledgerAccountId as number)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((data) => {
                            if (data) {
                                this.lastReconciliation = data;
                                this.isLoaded = true;
                            } else {
                                this.ledgerAccountService
                                    .getOpeningBalanceLedgerAccountTransactionsById(this.ledgerAccountId as number)
                                    .pipe(takeUntil(this.unsubscribe))
                                    .subscribe((openingBalance) => {
                                        this.isLoaded = true;
                                        if (openingBalance) {
                                            this.accountOpeningBalance = openingBalance;
                                        }
                                    });
                            }
                        });
                }
            });
    }

    startReconciliation(data: IBaseReconciliationData, isExistingReconciliation: boolean = false) {
        this.baseData = Object.assign(
            {
                start_date: null,
                end_date: null,
                closing_balance: null,
                closing_balance_debit_or_credit: null,
                opening_balance: null,
                opening_balance_debit_or_credit: 'debit'
            },
            data
        );

        if (!isExistingReconciliation) {
            if (typeof data.closing_balance === 'number') {
                if (this.ledgerAccount?.normal_balance_type === 'credit') {
                    // for credit cards
                    this.baseData.closing_balance_debit_or_credit = data.closing_balance < 0 ? 'debit' : 'credit';
                } else {
                    // for banks
                    this.baseData.closing_balance_debit_or_credit = data.closing_balance < 0 ? 'credit' : 'debit';
                }
                this.baseData.closing_balance = Math.abs(data.closing_balance);
            }

            if (this.accountOpeningBalance) {
                this.baseData.opening_balance = this.accountOpeningBalance.total;
                this.baseData.opening_balance_debit_or_credit = this.accountOpeningBalance.debit_or_credit;
            }

            if (this.lastReconciliation) {
                this.baseData.opening_balance = this.lastReconciliation.closing_balance;
                this.baseData.opening_balance_debit_or_credit = this.lastReconciliation.closing_balance_debit_or_credit;
            }
        }

        this.closingBalance = typeof this.baseData.closing_balance === 'number' ? this.baseData.closing_balance : 0;
        this.openingBalance = typeof this.baseData.opening_balance === 'number' ? this.baseData.opening_balance : null;

        this._countDifferAmount();

        if (this.baseData.start_date && typeof this.baseData.start_date === 'string') {
            this.baseData.start_date = Number(this.baseData.start_date);
        }

        this.loadTransactions();
    }

    _countDifferAmount() {
        this.differAmount = 0;

        if (!this.baseData || this.baseData.closing_balance === null || this.baseData.opening_balance === null) {
            return;
        }

        const closingBalanceMathematical =
            this.baseData.closing_balance_debit_or_credit === this.ledgerAccount?.normal_balance_type
                ? this.baseData.closing_balance
                : this.baseData.closing_balance * -1;
        const openingBalanceMathematical =
            this.baseData.opening_balance_debit_or_credit === this.ledgerAccount?.normal_balance_type
                ? this.baseData.opening_balance
                : this.baseData.opening_balance * -1;

        this.differAmount = new Decimal(closingBalanceMathematical)
            .sub(openingBalanceMathematical)
            .sub(this.clearedAmount)
            .toDecimalPlaces(2)
            .toNumber();
    }

    fillData() {
        const data = [];
        const length = this.listLength;
        const fillStart = this.pageIndex * this.pageSize;
        const fillEnd = fillStart + this.pageSize;

        for (let i = 0; i < length; i++) {
            data.push({} as ITransactionListItem);
        }

        for (let i = fillStart, j = 0; i < fillEnd; i++, j++) {
            if (i < length) {
                data[i] = this.transactions[j];
            }
        }

        this.dataSource.data = data;
        this.countMatchedOnPage();
    }

    countMatchedOnPage() {
        if (this.transactions && this.transactions.length > 0) {
            this.matchedTransactionCounter = this.transactions.filter(
                (t) =>
                    t.matched__transaction_external_id &&
                    !this.selection.selected.map((s) => s.transaction_id).includes(t.transaction_id)
            ).length;
        }
    }

    addMatchedOnPage() {
        this.transactions
            .filter(
                (t) =>
                    t.matched__transaction_external_id &&
                    !this.selection.selected.map((s) => s.transaction_id).includes(t.transaction_id)
            )
            .forEach((t) => this.selection.select(t));
    }

    save(status: 'in_progress' | 'reconciled') {
        if (this.createDisabled || (status === 'reconciled' && this.differAmount !== 0)) {
            return;
        }

        const transactionsIds = this.selection.selected
            .filter((jt) => !jt.adjustment_temp_id)
            .map((jt) => jt.transaction_id);
        const adjustmentTransactions = this.adjustmentTransactions.map((at) => {
            return {
                debit_or_credit: at.debit_or_credit,
                amount: at.amount,
                date: at.date,
                ledger_account_fk_id: at.ledger_account_fk_id
            };
        });

        if (this.reconciliation) {
            this.reconciliationsService
                .editReconciliation(this.reconciliationId as number, {
                    transactions_ids: transactionsIds,
                    reconciliation: {
                        ...this.reconciliation,
                        status: status,
                        adjustment_transactions: adjustmentTransactions
                    }
                })
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    this.router.navigate(['/banking', this.ledgerAccountId, 'transactions'], {
                        queryParams: {tab: 'reconciliations'}
                    });
                });
        } else {
            const reconciliation: IReconciliation = {
                creator__company_fk_id: null,
                creator__profile_fk_id: null,
                ledger_account_fk_id: this.ledgerAccountId,
                reconciled_date: null,
                start_date: this.baseData?.start_date ? Number(this.baseData?.start_date) : null,
                end_date: this.baseData?.end_date ? Number(this.baseData?.end_date) : null,
                status: status,
                closing_balance_debit_or_credit: this.baseData?.closing_balance_debit_or_credit || null,
                opening_balance_debit_or_credit: this.baseData?.opening_balance_debit_or_credit || null,
                closing_balance: this.baseData?.closing_balance || 0,
                opening_balance:
                    typeof this.baseData?.opening_balance === 'number' ? this.baseData?.opening_balance : (null as any),
                is_last_reconcile: true,
                adjustment_transactions: this.adjustmentTransactions
            };

            this.reconciliationsService
                .createReconciliation({
                    transactions_ids: transactionsIds,
                    reconciliation
                })
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    this.router.navigate(['/banking', this.ledgerAccountId, 'transactions'], {
                        queryParams: {tab: 'reconciliations'}
                    });
                });
        }
    }

    cancel() {
        this.router.navigate(['/banking', this.ledgerAccountId, 'transactions'], {
            queryParams: {tab: 'reconciliations'}
        });
    }

    loadTransactions() {
        this.transactionsService
            .getReconciliationTransactions(this.ledgerAccountId as number, {
                reconciliation_id: this.reconciliationId as number,
                offset: Number(this.pageSize * this.pageIndex),
                limit: Number(this.pageSize),
                start_date: '',
                end_date: this.baseData?.end_date ? Number(this.baseData?.end_date) : ''
            })
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((transactions) => {
                this.listLength = transactions.count;
                this.transactions = transactions.transactions.map((tr) => {
                    if (tr.check_info) {
                        tr.check_info = JSON.parse(tr.check_info);
                    }
                    return tr;
                });

                this.fillData();
            });
    }

    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    isSelected(row: any) {
        return this.selection.selected.find((sel) => sel.id === row.id);
    }

    checked(row: any) {
        const found = this.selection.selected.find((x) => x.id === row.id);

        if (!found) {
            this.selection.select(row);
        }
    }

    unchecked(row: any) {
        const found = this.selection.selected.find((x) => x.id === row.id);

        if (found) {
            found.checked = false;
        }

        this.selection.deselect(found);
    }

    removeAdjustment(index: number) {
        this.adjustmentTransactions.splice(index, 1);

        return this.countPending();
    }

    countPending() {
        let sumOfDebits: Decimal = new Decimal(0);
        let sumOfCredits: Decimal = new Decimal(0);

        const transactionToReconcile = this.selection.selected.concat(this.adjustmentTransactions);

        transactionToReconcile.map((transaction) => {
            if (transaction.debit_or_credit === 'debit') {
                sumOfDebits = sumOfDebits.add(transaction.amount);
            } else {
                sumOfCredits = sumOfCredits.add(transaction.amount);
            }
        });

        if (this.ledgerAccount?.normal_balance_type === 'credit') {
            // for credit cards
            this.clearedAmount = sumOfCredits.sub(sumOfDebits).toDecimalPlaces(2).toNumber();
        } else {
            // for banks
            this.clearedAmount = sumOfDebits.sub(sumOfCredits).toDecimalPlaces(2).toNumber();
        }

        this._countDifferAmount();
    }

    addAdjustment() {
        const dialogRef = this.dialog.open(AdjustmentDialogComponent, {
            width: '500px'
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((newAdjustment) => {
                if (!newAdjustment) {
                    return;
                }

                this.adjustmentTransactions.push(newAdjustment);
                this.countPending();
            });
    }

    editAdjustment(transaction: IAdjustmentTransaction, i: number) {
        const dialogRef = this.dialog.open(AdjustmentDialogComponent, {
            width: '500px'
        });
        dialogRef.componentInstance.transaction = transaction;

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((adjustment) => {
                if (!adjustment) {
                    return;
                }

                this.adjustmentTransactions[i] = adjustment;
                this.countPending();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
