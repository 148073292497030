import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../service.requester';
import {Observable} from 'rxjs';
import {IPaymentMethod} from '@cyberco-nodejs/zipi-typings';

@Injectable({
    providedIn: 'root'
})
export class CompanyPaymentMethodsService {
    methodUrl = '/finance/payment-methods';

    constructor(public requester: ServiceRequester) {}

    createDwollaPaymentMethod(gatewayId: number, settings: any): Observable<IPaymentMethod> {
        return this.requester.makeMsCall$(`${this.methodUrl}/${gatewayId}/dwolla`, 'POST', 'shipp', settings);
    }

    createBankingPaymentMethod(
        gatewayId: number,
        settings: {
            public_token: string;
            account_id: any;
            title: string;
            type: any;
            subtype: any;
            manual_account_type: string | null;
            related_ledger_account_id: any;
            trust_account: boolean;
        }
    ): Observable<IPaymentMethod> {
        return this.requester.makeMsCall$(`${this.methodUrl}/${gatewayId}/banking`, 'POST', 'shipp', settings);
    }

    getMethodByLedgerAccountId(ledgerAccountId: number): Observable<IPaymentMethod> {
        return this.requester.makeMsCall$(`${this.methodUrl}/${ledgerAccountId}/banking/ledger`, 'GET', 'shipp');
    }

    getPlaidPublicToken(paymentMethodId: number): Observable<string> {
        return this.requester.makeMsCall$(`${this.methodUrl}/${paymentMethodId}/public-token/dwolla`, 'GET', 'shipp');
    }

    getPlaidLinkToken(paymentMethodId: number): Observable<string> {
        return this.requester.makeMsCall$(`${this.methodUrl}/${paymentMethodId}/link-token/dwolla`, 'GET', 'shipp');
    }

    refreshBalanceByMethodId(paymentMethodId: number): Observable<number> {
        return this.requester.makeMsCall$(`${this.methodUrl}/${paymentMethodId}/balance`, 'GET', 'shipp');
    }

    changeDefaultReceivingMethod(paymentMethodId: number): Observable<boolean> {
        return this.requester.makeMsCall$(`${this.methodUrl}/${paymentMethodId}/default-receiving`, 'GET', 'shipp');
    }

    changeDefaultMerchantMethod(paymentMethodId: number): Observable<boolean> {
        return this.requester.makeMsCall$(`${this.methodUrl}/${paymentMethodId}/default-merchant`, 'GET', 'shipp');
    }

    resetPlaidStatus(paymentMethodId: number): Observable<boolean> {
        return this.requester.makeMsCall$(`${this.methodUrl}/${paymentMethodId}/plaid-status`, 'PUT', 'shipp');
    }

    getInstitutionInfo(paymentMethodId: number): Observable<any> {
        return this.requester.makeMsCall$(
            `${this.methodUrl}/${paymentMethodId}/plaid-institution-status`,
            'GET',
            'shipp'
        );
    }

    updatePaymentMethod(paymentMethodId: number, methodData: IPaymentMethod): Observable<boolean> {
        return this.requester.makeMsCall$(`${this.methodUrl}/${paymentMethodId}`, 'PUT', 'shipp', methodData);
    }

    updateLedgerAccountForPaymentMethod(
        paymentMethodId: number,
        methodData: {
            related_ledger_account_id: number | null;
            needDefaultLedger: boolean | null;
        }
    ): Observable<boolean> {
        return this.requester.makeMsCall$(`${this.methodUrl}/${paymentMethodId}/ledger`, 'PUT', 'shipp', methodData);
    }

    deleteContactPaymentMethod(methodId: number): Observable<boolean> {
        return this.requester.makeMsCall$(`${this.methodUrl}/${methodId}/contact`, 'DELETE', 'shipp');
    }

    deleteMethod(methodId: number): Observable<boolean> {
        return this.requester.makeMsCall$(`${this.methodUrl}/${methodId}`, 'DELETE', 'shipp');
    }
}
