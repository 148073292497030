import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {GenericFormArray, GenericFormGroup} from 'app/entites/generic.entity';
import {ProfileMembership} from 'app/models/profile-membership';
import {MembershipOrganization} from 'app/models/membership-organization';
import {Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {ICompanyWideState} from 'app/store/company-wide/company-wide.reducer';
import {selectMembershipOrganizations} from 'app/store/company-wide/company-wide.selectors';

@Component({
    selector: 'app-contact-edit-compliance-membership',
    template: `
        <button type="button" (click)="doAdd()" mat-button matTooltip="Add membership">
            <mat-icon class="mr-1">add</mat-icon>
            Membership
        </button>

        <div *ngIf="organizationsFetched" class="zp-pd-15">
            <div *ngFor="let item of profileMembershipsFA.controls; index as ii">
                <app-contact-edit-compliance-membership-item
                    [membershipOrganizations]="membershipOrganizations"
                    [profileMembershipFG]="item"
                    (delete)="doDelete(ii)"
                >
                </app-contact-edit-compliance-membership-item>
            </div>
        </div>
    `,
    styleUrls: ['../contact-edit.component.css']
})
export class MembershipComponent implements OnDestroy, OnInit {
    public membershipOrganizations: MembershipOrganization[] = [];
    private unsubscribe: Subject<void> = new Subject();
    @Input() profileMembershipsFA: GenericFormArray<ProfileMembership> = <GenericFormArray<ProfileMembership>>(
        new GenericFormArray([])
    );

    organizationsFetched: boolean = false;
    addMode: boolean = false;
    selectedOrganization: MembershipOrganization = new MembershipOrganization();

    constructor(private store: Store<ICompanyWideState>) {}

    doDelete(ii: number) {
        this.profileMembershipsFA.removeAt(ii);
    }

    doAdd() {
        this.addMode = true;
        if (this.selectedOrganization !== new MembershipOrganization()) {
            this.profileMembershipsFA.push(new GenericFormGroup(new ProfileMembership()));
            this.selectedOrganization = new MembershipOrganization();
            this.updRequiredFields();
        }
    }

    updRequiredFields() {
        if (this.profileMembershipsFA.controls) {
            this.profileMembershipsFA.controls.forEach((ctrl) => {
                // ctrl.controls.expiration_date.setValidators([Validators.required]);
                ctrl.controls.membership_organization_fk_id!.setValidators([Validators.required]);
            });
        }
    }

    ngOnInit(): void {
        this.store
            .pipe(select(selectMembershipOrganizations), takeUntil(this.unsubscribe))
            .subscribe((organizations) => {
                this.organizationsFetched = true;
                if (organizations) {
                    this.membershipOrganizations = organizations;
                }
            });
        this.updRequiredFields();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
