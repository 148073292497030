<div>
    <h2 class="mr-2">Select Gateway</h2>

    <div class="mb-2">You have more than one Operating Gateway.</div>

    <mat-card *ngFor="let gateway of paymentGateways">
        <div class="d-flex w-100 align-items-center">
            <div>{{ gateway.title }}</div>
            <span class="sp-spacer"></span>
            <button type="button" mat-raised-button color="primary" [disabled]="true" (click)="select(gateway)">
                Connect
            </button>
        </div>
    </mat-card>

    <div class="d-flex mt-3">
        <span class="sp-spacer"></span>
        <button type="button" mat-raised-button (click)="close()">Cancel</button>
    </div>
</div>
