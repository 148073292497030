import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ServiceRequester} from '../../service.requester';
import {ICompanyLocation} from '../../../../typings';

@Injectable()
export class CompanyLocationsService {
    url = '/companies/locations';

    constructor(public requester: ServiceRequester) {}

    fetchCompanyLocations(): Observable<ICompanyLocation[]> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp');
    }

    createCompanyLocation(data: ICompanyLocation): Observable<ICompanyLocation> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    updateCompanyLocation(locationId: number, data: Partial<ICompanyLocation>): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + '/' + locationId, 'PUT', 'shipp', data);
    }

    deleteCompanyLocation(locationId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + '/' + locationId, 'DELETE', 'shipp');
    }
}
