import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {filter, last, takeUntil} from 'rxjs/operators';
import {reverse, sortBy} from 'lodash-es';
import {MatDialog} from '@angular/material/dialog';
import {AngularFireStorage, BUCKET} from '@angular/fire/compat/storage';

import {ConfirmComponent} from '../../../layouts/confirm/confirm.component';
import {UploadService} from '../services/upload.service';
import {SessionService} from '../../../services/session.service';
import {environment} from '../../../../environments/environment';
import {makeBucketName} from '../../deals/components/deal/pages/index/list/list.component';
import {UsersAndLicencesApiService} from '../users-licenses/users-and-licences.api.service';
import {NotificationsService} from 'angular2-notifications';
import * as FileSaver from 'file-saver';
import {SystemNotificationSource} from '../../user-notification/system-notification.source';
import {FeatureFlagsService} from '../../feature-flags/feature-flags.service';
import {LedgerAccountService} from '../../../services/api/finance/ledger-accounts.service';
import {ILedgerAccount} from '@cyberco-nodejs/zipi-typings';
import {ExportDataFormDialogComponent} from './expot-data-form.dialog.component';

interface IUploadsData {
    file_name: string;
    file_path: string;
    status: string;
    id: number;
    created_at: string;
    data_type: string;
    type: string;
    summary: {
        errors: Array<{
            uniquie_id: string;
            valid: boolean;
            existing: boolean;
            rowNumber: number;
            errorMessage: string;
            invoiceUniqueKey?: string;
        }>;
        successes: Array<{
            uniquie_id: string;
            valid: boolean;
            existing: boolean;
            rowNumber: number;
            errorMessage: string;
        }>;
        existing: Array<{
            uniquie_id: string;
            valid: boolean;
            existing: boolean;
            rowNumber: number;
            errorMessage: string;
        }>;
        need_updates: Array<{
            uniquie_id: string;
            valid: boolean;
            existing: boolean;
            rowNumber: number;
            errorMessage: string;
        }>;
    };
}

@Component({
    selector: 'app-company-data-uploads',
    templateUrl: './company-data-uploads.component.html',
    styleUrls: ['./company-data-uploads.component.scss'],
    providers: [{provide: BUCKET, useValue: makeBucketName(environment)}, AngularFireStorage]
})
export class CompanyDataUploadsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('dealFromCsv') dealFromCsv: {nativeElement: {value: null}} | undefined;
    @ViewChild('profileFromCsv') profileFromCsv: {nativeElement: {value: null}} | undefined;
    @ViewChild('invoiceFromCsv') invoiceFromCsv: {nativeElement: {value: null}} | undefined;
    @ViewChild('contactFromCsv') contactFromCsv: {nativeElement: {value: null}} | undefined;
    @ViewChild('ledgerAccountFromCsv') ledgerAccountFromCsv: {nativeElement: {value: null}} | undefined;

    currentUploadId: number | undefined | null;
    uploads: IUploadsData[] = [];
    backgroundTasks: [] = [];
    backgroundTasksList: any[] = [];

    sourceFileDeals: File | undefined;
    readerDeals = new FileReader();
    sourceFileInvoices: File | undefined;
    readerInvoices = new FileReader();
    // sourceFileContacts: File | undefined;
    readerContacts = new FileReader();
    isImportInProcess: boolean = false;
    importProductsEnabledFlag: boolean = false;
    importCOAEnabledFlag: boolean = false;
    importProfilesEnabledFlag: boolean = false;
    importContactsEnabledFlag: boolean = false;
    exportJournalsEnabledFlag: boolean = false;

    listOfLedgerAccounts: ILedgerAccount[] | null = null;

    scrollData = {
        sort_column: 'created_at',
        sort_direction: 'desc'
    };

    constructor(
        public usersAndLicencesApiService: UsersAndLicencesApiService,
        private activateRoute: ActivatedRoute,
        private uploadService: UploadService,
        protected dialog: MatDialog,
        private storage: AngularFireStorage,
        protected sessionService: SessionService,
        private notificationsService: NotificationsService,
        protected systemNotifications: SystemNotificationSource,
        protected featureFlagsService: FeatureFlagsService,
        private ledgerAccountService: LedgerAccountService
    ) {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.importProfilesEnabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'directory:data:import_profiles'
                );
                this.importProductsEnabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'directory:data:import_products'
                );
                this.importCOAEnabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'directory:data:import_chart_of_accounts'
                );
                this.importContactsEnabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'directory:data:import_contacts'
                );
                this.exportJournalsEnabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'directory:data:export_journals'
                );
            });
        this.ledgerAccountService
            .getLedgerAccounts()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((items) => {
                this.listOfLedgerAccounts = items;
            });
    }

    ngOnInit() {
        this.initFileReaderListenerDeals();
        this.initFileReaderListenerInvoices();

        this.activateRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['id']) {
                this.currentUploadId = Number(params['id']);
            }
        });

        this.initUploadsList();
        this.initBackgroundTasksList();

        this.systemNotifications.reloadDataListNotification.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.initUploadsList();
            this.initBackgroundTasksList();
        });
    }

    initUploadsList() {
        this.uploads = [];
        this.uploadService
            .getUploadList()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                this.uploads = response.result;
                this.sortUploads();
            });
    }

    initBackgroundTasksList() {
        this.backgroundTasks = [];
        this.uploadService
            .getBackgroundTasksList()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response: any) => {
                this.backgroundTasks = response.result;
                // group tasks by session_hash
                this.backgroundTasksList = this.groupDataBySessionHash(response.result);
            });
    }

    groupDataBySessionHash(data: any) {
        const groupedData: any[] = [];
        data.forEach((item: any) => {
            if (!groupedData[item.session_hash]) {
                groupedData[item.session_hash] = [];
            }
            groupedData[item.session_hash].push(item);
        });
        return Object.getOwnPropertyNames(groupedData)
            .map((prop: any) => groupedData[prop])
            .filter((i) => !!i);
    }

    sortUploads(sortDirection = 'desc', sortColumn = 'created_at') {
        const sorted = sortBy(this.uploads, [sortColumn]);
        if (sortDirection === 'desc') {
            this.uploads = reverse(sorted);
        } else {
            this.uploads = sorted;
        }
    }

    createEntities(upload: IUploadsData) {
        switch (upload.data_type) {
            case 'deal':
                {
                    this.uploadService
                        .createDealsFromImportData(upload.id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((response) => {
                            if (response && response.message === 'ok') {
                                this.notificationsService.success(
                                    `The import is processing and you will receive a message in the top notifications bell when complete.`
                                );
                            } else {
                                this.notificationsService.success(`Import error. Please reload page and try again.`);
                            }
                        });
                }
                break;
            case 'invoice':
                {
                    this.uploadService
                        .createInvoicesFromImportData(upload.id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((response) => {
                            if (response && response.message === 'ok') {
                                this.notificationsService.success(
                                    `The import is processing and you will receive a message in the top notifications bell when complete.`
                                );
                            } else {
                                this.notificationsService.success(`Import error. Please reload page and try again.`);
                            }
                        });
                }
                break;
        }
    }

    // Import Deals Section

    initFileReaderListenerDeals() {
        this.readerDeals.addEventListener('loadend', () => {
            const sourceFileDeals = this.sourceFileDeals;
            const dealFromCsv = this.dealFromCsv;
            if (sourceFileDeals && dealFromCsv) {
                const dialogRef = this.dialog.open(ConfirmComponent, {
                    data: {
                        title: 'Upload File',
                        message: `File ${sourceFileDeals.name} will be uploaded`
                    }
                });

                dialogRef
                    .afterClosed()
                    .pipe(
                        filter((pn) => !!pn),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe((ok) => {
                        if (ok) {
                            const filePath = this.makeFileUploadName(sourceFileDeals.name);
                            if (filePath) {
                                this._uploadFileToBucket(filePath, sourceFileDeals)
                                    .pipe(
                                        // @ts-ignore
                                        last(),
                                        takeUntil(this.unsubscribe)
                                    )
                                    .subscribe((res) => {
                                        dealFromCsv.nativeElement.value = null;
                                        this.uploadService
                                            .uploadDeals({path: filePath, name: sourceFileDeals.name})
                                            .pipe(takeUntil(this.unsubscribe))
                                            .subscribe((response) => {
                                                if (response && response.message === 'ok') {
                                                    this.uploads.push(response.result);
                                                    this.sortUploads();
                                                    this.notificationsService.success(`Upload completed successfully.`);
                                                } else {
                                                    this.notificationsService.success(
                                                        `Upload error. Please try again.`
                                                    );
                                                }
                                            });
                                    });
                            }
                        }
                    });
            }
        });
    }

    makeFileUploadName(fileName: string) {
        if (this.sessionService.profile && this.sessionService.profile.company) {
            return `deals/${this.sessionService.profile.company.title}/profile_${this.sessionService.profile.id}/${Date.now()}_${fileName}`;
        }
        return false;
    }

    handleFileInputDeals(files: FileList | null) {
        if (!files || files.length === 0 || !files[0]) {
            return;
        }

        if (files[0].size > 5000000) {
            throw new Error('File size too big. It must be under 5MB');
        } else {
            this.sourceFileDeals = files[0];
            this.readerDeals.readAsBinaryString(files[0]);
        }
    }

    deleteUpload(upload: IUploadsData) {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            data: {
                title: 'Deleting',
                message: `Are you sure to delete?`
            }
        });
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.uploadService
                        .deleteImportedData(upload.id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((res) => {
                            if (res.result === true) {
                                this.uploads = this.uploads.filter((u) => u.id !== upload.id);
                            }
                        });
                }
            });
    }

    // Import Invoices Section

    initFileReaderListenerInvoices() {
        this.readerInvoices.addEventListener('loadend', () => {
            const sourceFileInvoices = this.sourceFileInvoices;
            const invoiceFromCsv = this.invoiceFromCsv;
            if (sourceFileInvoices && invoiceFromCsv) {
                const dialogRef = this.dialog.open(ConfirmComponent, {
                    data: {
                        title: 'Upload File',
                        message: `File ${sourceFileInvoices.name} will be uploaded`
                    }
                });

                dialogRef
                    .afterClosed()
                    .pipe(
                        filter((pn) => !!pn),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe((ok) => {
                        if (ok) {
                            const filePath = this.makeFileUploadNameInvoices(sourceFileInvoices.name);
                            if (filePath) {
                                this._uploadFileToBucket(filePath, sourceFileInvoices)
                                    .pipe(
                                        // @ts-ignore
                                        last(),
                                        takeUntil(this.unsubscribe)
                                    )
                                    .subscribe((res) => {
                                        invoiceFromCsv.nativeElement.value = null;
                                        this.uploadService
                                            .uploadInvoice({
                                                path: filePath,
                                                name: sourceFileInvoices.name
                                            })
                                            .pipe(takeUntil(this.unsubscribe))
                                            .subscribe((response) => {
                                                if (response && response.message === 'ok') {
                                                    this.uploads.push(response.result);
                                                    this.sortUploads();
                                                    this.notificationsService.success(`Upload completed successfully.`);
                                                } else {
                                                    this.notificationsService.success(
                                                        `Upload error. Please try again.`
                                                    );
                                                }
                                            });
                                    });
                            }
                        }
                    });
            }
        });
    }

    handleFileInputInvoice(files: FileList | null) {
        if (!files || files.length === 0 || !files[0]) {
            return;
        }

        if (files[0].size > 5000000) {
            throw new Error('File size too big. It must be under 5MB');
        } else {
            this.sourceFileInvoices = files[0];
            this.readerInvoices.readAsBinaryString(files[0]);
        }
    }

    makeFileUploadNameInvoices(fileNme: string) {
        if (this.sessionService.profile && this.sessionService.profile.company) {
            return `invoices/${this.sessionService.profile.company.title}/profile_${this.sessionService.profile.id}/${Date.now()}_${fileNme}`;
        }
        return false;
    }

    // Downloads
    async downloadDeals(filepath: string) {
        const result = await fetch(filepath);
        FileSaver.saveAs(await result.blob(), 'deals_import_example.csv');
    }

    async downloadUsers(filepath: string) {
        const result = await fetch(filepath);
        FileSaver.saveAs(await result.blob(), 'profiles_import_template.csv');
    }

    async downloadInvoices(filepath: string) {
        const result = await fetch(filepath);
        FileSaver.saveAs(await result.blob(), 'invoices_import_example.csv');
    }

    async downloadContacts(filepath: string) {
        const result = await fetch(filepath);
        FileSaver.saveAs(await result.blob(), 'contacts_import_template.csv');
    }

    async downloadAccounts(filepath: string) {
        const result = await fetch(filepath);
        FileSaver.saveAs(await result.blob(), 'ledger_accounts_import_template.csv');
    }

    _uploadFileToBucket(filePath: string, file: File) {
        return this.storage.upload(filePath, file).percentageChanges();
    }

    exportProfiles() {
        this.uploadService
            .exportProfiles()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                if (response && response.message === 'ok') {
                    this.notificationsService.success(
                        `The export is processing and you will receive a message in the top notifications bell when complete.`
                    );
                } else {
                    this.notificationsService.success(`Export error. Please reload page and try again.`);
                }
            });
    }

    exportProductsServices() {
        this.uploadService
            .exportProductsServices()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                if (response && response.message === 'ok') {
                    this.notificationsService.success(
                        `The export is processing and you will receive a message in the top notifications bell when complete.`
                    );
                } else {
                    this.notificationsService.success(`Export error. Please reload page and try again.`);
                }
            });
    }

    async exportJournalEntries() {
        const stateData = await this.uploadService.getLatestExportJournalsState();

        const dialogRef = this.dialog.open(ExportDataFormDialogComponent, {data: stateData});

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result) {
                    this.uploadService
                        .exportJournalsServices({
                            basis_type: result.basis_type,
                            start_date: result.start_date,
                            end_date: result.end_date
                        })
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((response) => {
                            if (response && response.message === 'ok') {
                                this.notificationsService.success(
                                    `The export is processing and you will receive a message in the top notifications bell when complete.`
                                );
                            } else {
                                this.notificationsService.success(`Export error. Please reload page and try again.`);
                            }
                        });
                }
            });
    }

    changeSort(sortColumn: string) {
        if (this.scrollData.sort_column === sortColumn) {
            // change direction
            this.scrollData.sort_direction = this.scrollData.sort_direction === 'desc' ? 'asc' : 'desc';
        } else {
            // change column
            this.scrollData.sort_column = sortColumn;
        }

        this.sortUploads(this.scrollData.sort_direction, this.scrollData.sort_column);
    }

    getUploadFileName(upload: {file_path: string; file_name: string}) {
        if (upload.file_path.indexOf('profile-data-') !== -1) {
            return upload.file_path.substr(upload.file_path.indexOf('profile-data-'), upload.file_path.length);
        } else if (upload.file_path.indexOf('products-services-data-') !== -1) {
            return upload.file_path.substr(
                upload.file_path.indexOf('products-services-data-'),
                upload.file_path.length
            );
        } else if (upload.file_name) {
            return upload.file_name;
        } else {
            return '-';
        }
    }

    getFileUploadName(fileName: string, type: string) {
        if (this.sessionService.profile && this.sessionService.profile.company) {
            return `${type}/${this.sessionService.profile.company.title}/profile_${this.sessionService.profile.id}/${fileName}`;
        }
        return false;
    }

    handleProcessData(data: {data: any; type: any}) {
        const fileName = `${Date.now()}_${data.type}.json`;
        const filePath = this.getFileUploadName(fileName, data.type);
        if (filePath) {
            // The data passed here is from Flatfile and it treats blank values as null.
            // We want to treat blank values as empty strings.
            const stringifyData = JSON.stringify(data.data, (key, value) => (value === null ? '' : value));
            const blob = new Blob([stringifyData], {type: 'application/json'});
            const file = new File([blob], fileName, {lastModified: new Date().getTime()});
            this._uploadFileToBucket(filePath, file)
                .pipe(
                    // @ts-ignore
                    last(),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((res) => {
                    this.uploadService
                        .createItemsFromImport({path: filePath, name: fileName, type: data.type})
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe(() => {});
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
