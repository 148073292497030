import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {Observable} from 'rxjs';
import {IContact, IMoneySenderCustomisation} from '@cyberco-nodejs/zipi-typings';
import {IScrollData} from '../../../models/scroll-data';

@Injectable()
export class MoneySendersCustomisationService {
    private url = '/finance/money-senders/';

    constructor(protected requester: ServiceRequester) {}

    getMoneySendersCustomisationsByInvoiceId(invoiceId: number): Observable<IMoneySenderCustomisation[]> {
        return this.requester.makeMsCall$(
            this.url + `invoice/${invoiceId}/customisation`,
            'GET',
            'shipp',
            undefined,
            {},
            true
        );
    }

    getMoneySenderWithCustomisationByContactId(
        contactId: number,
        recurringInvoiceId: number,
        filterQuery: Partial<IScrollData>
    ): Observable<IContact[]> {
        return this.requester.makeMsCall$(
            this.url + `recurring/${recurringInvoiceId}/contact/${contactId}/contact-with-customisation`,
            'GET',
            'shipp',
            filterQuery,
            {},
            true
        );
    }

    getMoneySenderWithCustomisationByContactClassId(
        contactClassId: number,
        recurringInvoiceId: number,
        filterQuery: Partial<IScrollData>
    ): Observable<IContact[]> {
        return this.requester.makeMsCall$(
            this.url + `recurring/${recurringInvoiceId}/contact-class/${contactClassId}/contact-with-customisation`,
            'GET',
            'shipp',
            filterQuery,
            {},
            true
        );
    }

    getMoneySenderCustomisationByContactIdForRecurring(
        recurringInvoiceId: number,
        contactId: number
    ): Observable<IMoneySenderCustomisation> {
        return this.requester.makeMsCall$(
            this.url + `recurring/${recurringInvoiceId}/contact/${contactId}/customisation`,
            'GET',
            'shipp',
            undefined,
            {},
            true
        );
    }

    getMoneySenderCustomisationByContactIdForInvoice(
        invoiceId: number,
        contactId: number
    ): Observable<IMoneySenderCustomisation> {
        return this.requester.makeMsCall$(
            this.url + `invoice/${invoiceId}/contact/${contactId}/customisation`,
            'GET',
            'shipp',
            undefined,
            {},
            true
        );
    }

    createMoneySenderCustomisation(custObj: IMoneySenderCustomisation): Observable<IMoneySenderCustomisation> {
        return this.requester.makeMsCall$(this.url + 'customisation', 'POST', 'shipp', custObj);
    }

    updateMoneySenderCustomisation(custObj: IMoneySenderCustomisation): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + 'customisation', 'PUT', 'shipp', custObj);
    }

    deleteMoneySenderCustomisation(customisationId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + customisationId + '/customisation', 'DELETE', 'shipp');
    }

    deleteAllMoneySendersCustomisationsForRecurring(recurringInvoiceId: number): Observable<boolean> {
        return this.requester.makeMsCall$(
            this.url + `recurring/${recurringInvoiceId}/customisation`,
            'DELETE',
            'shipp'
        );
    }
}
