<mat-sidenav-container [hasBackdrop]="true" class="sidenav-container h-100" style="background-color: #fff">
    <mat-sidenav
        #sidenavGroupMembers
        mode="over"
        [position]="'end'"
        (closed)="close()"
        [ngClass]="{'top-sidenav1': false}"
        [autoFocus]="false"
        class="users-sidenav-content py-3 pl-3"
    >
        <div #header style="position: sticky" class="pr-3">
            <div class="d-flex justify-content-between">
                <h2>{{ groupFG.controls.title!.value }}</h2>

                <div *ngIf="!manageMembers" class="d-flex align-items-center">
                    <button
                        mat-raised-button
                        class="mat-primary"
                        color="warn"
                        *ngIf="membersToDelete.length && editDisabled"
                        (click)="removeMembersBeforeSave()"
                    >
                        Delete
                    </button>

                    <button
                        mat-raised-button
                        class="mat-primary ml-2"
                        *ngIf="!manageMembers && editDisabled"
                        (click)="manageMembers = !manageMembers; membersToDelete = []"
                    >
                        Manage
                    </button>

                    <button [disabled]="isDisabled" mat-raised-button class="ml-2" (click)="close()">Close</button>
                </div>
            </div>
            <div class="d-flex justify-content-between" *ngIf="manageMembers">
                <div
                    *ngIf="editDisabled && !groupFG.controls.isPersonalGroup!.value"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'flex'}"
                    [denyStyle]="{display: 'none'}"
                    [rule]="{company_settings__manage_divisions_members: true}"
                    [groupId]="groupFG.controls.id!.value"
                    class="d-flex justify-content-start w-100"
                >
                    <app-company-compensation-combined-picker
                        class="w-100"
                        displayMode="double-line"
                        [bold_style]="false"
                        [title]="'Search'"
                        [availableTypes]="['individual']"
                        [nodesFA]="selectedProfilesFA"
                        [initialProfileIds]="selectedProfileIds"
                        [positionMode]="'none'"
                        [excludeMembersFromGroup]="groupId"
                        [closeAfterSelection]="false"
                    ></app-company-compensation-combined-picker>
                </div>

                <div class="d-flex align-items-baseline ml-3" style="margin-top: 4px">
                    <button
                        [disabled]="isDisabled"
                        mat-raised-button
                        class="header-button"
                        style="margin-right: 16px; margin-top: 5px"
                        (click)="manageMembers = !manageMembers"
                    >
                        Cancel
                    </button>
                    <button
                        *ngIf="editDisabled"
                        [disabled]="isDisabled"
                        [rule]="{company_settings__manage_divisions: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        mat-raised-button
                        class="mat-primary header-button"
                        style="margin-top: 5px; margin-right: 5px"
                        (click)="saveGroup()"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="members && !members.length" class="pr-3">There are no users yet</div>

        <div
            class="users-scroll w-100"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline'}"
            [denyStyle]="{display: 'none'}"
            [rule]="{company_settings__view_divisions_members: true}"
        >
            <app-lazy-loading
                *ngIf="offsetTopToLazyLoading$ && members.length"
                class="deals-list-area"
                [data]="members"
                [itemHeightPx]="48"
                [offsetTop]="offsetTopToLazyLoading$"
                [closeSubject]="false"
                (scrolledDown)="nextBatch()"
            >
                <ng-template let-member="listItem" let-mi="index">
                    <div
                        class="d-flex justify-content-between pr-3"
                        style="height: 48px"
                        [ngClass]="manageMembers ? 'align-items-end' : 'align-items-center'"
                    >
                        <div class="d-flex align-items-center member-row" style="height: 30px">
                            <div
                                class="checkbox-area"
                                #divCheckbox
                                *ngIf="editDisabled && member.id"
                                rbac
                                [rule]="{company_settings__manage_users: true}"
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'inline-block'}"
                                [denyStyle]="{display: 'none'}"
                                [ngStyle]="membersToDelete.length ? {visibility: 'visible'} : {visibility: 'hidden'}"
                            >
                                <mat-checkbox
                                    #checkbox
                                    (click)="$event.stopPropagation()"
                                    (change)="toggleDealCheckbox($event)"
                                    [value]="member.id"
                                    [checked]="membersToDelete.includes(member.id)"
                                ></mat-checkbox>
                            </div>
                            <div *ngIf="!member.id">
                                <button mat-icon-button *ngIf="editDisabled" (click)="removeFromList(member, mi)">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </div>
                            <span class="mr-2">{{ mi + 1 }})</span>
                            <span class="mr-1">{{ member.profile.first_name }}</span>
                            <span>{{ member.profile.last_name }}</span>
                            <mat-icon
                                *ngIf="member.status === 'active' || member.status === 'new'"
                                class="material-icons-outlined ml-2"
                                style="color: rgb(113, 113, 113)"
                                matTooltip="{{ member.profile.email }}"
                                matTooltipPosition="right"
                            >
                                info
                            </mat-icon>
                            <span *ngIf="!member.id" class="text-muted font-italic ml-3">(Not saved)</span>
                        </div>

                        <div
                            class="d-flex"
                            *ngIf="(member.status === 'active' || member.status === 'new') && manageMembers"
                        >
                            <div
                                rbac
                                [rule]="{company_settings__manage_divisions: true}"
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                            >
                                <app-edit-user-roles
                                    *ngIf="allRoles && companyPermissions"
                                    label="Member Roles"
                                    [roles_ids]="member.group_roles_ids"
                                    [allRoles]="allRoles"
                                    [companyPermissions]="companyPermissions"
                                    [needToMoveHigher]="true"
                                    (selectionChanged)="member.group_roles_ids = $event"
                                ></app-edit-user-roles>
                            </div>
                        </div>

                        <div
                            class="d-flex flex-wrap justify-content-end ml-3"
                            *ngIf="(member.status === 'active' || member.status === 'new') && !manageMembers"
                        >
                            <ng-container *ngFor="let roleId of member.group_roles_ids; last as last">
                                <span class="mr-2" style="white-space: nowrap">
                                    <span>{{
                                        (allRoles.systemRoles | filterNgFor: {field: 'id', value: roleId} : true)[0]
                                            ?.title | titlecase
                                    }}</span>
                                    <span>{{
                                        (allRoles.companyRoles | filterNgFor: {field: 'id', value: roleId} : true)[0]
                                            ?.title | titlecase
                                    }}</span>
                                    <span *ngIf="!last">,</span>
                                </span>
                            </ng-container>
                        </div>
                    </div>
                </ng-template>
            </app-lazy-loading>
        </div>
    </mat-sidenav>
    <ng-content></ng-content>
</mat-sidenav-container>
