<mat-form-field class="w-100 report-dropdown">
    <mat-chip-list #chipList aria-label="Tags selection">
        <ng-container *ngFor="let item of initialList">
            <mat-chip *ngIf="value === item[valueAttribute]" (removed)="remove()">
                {{ item[titleAttribute] }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </ng-container>
        <input
            #input
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            (keydown)="checkAndPreventInput($event)"
            (keyup)="search($event)"
        />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="select($event)">
        <ng-container *ngIf="!value">
            <ng-container *ngFor="let item of list">
                <mat-option [value]="item[valueAttribute]">
                    {{ item[titleAttribute] }}
                </mat-option>
            </ng-container>
        </ng-container>
    </mat-autocomplete>
</mat-form-field>
