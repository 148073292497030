import {Subject} from 'rxjs';
import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {ITransactionExternal} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-add-transaction',
    templateUrl: 'add-transaction.component.html',
    styleUrls: ['../../banking.component.scss']
})
export class AddTransactionComponent implements OnDestroy {
    @Output() closeSidebar: EventEmitter<void> = new EventEmitter<void>();
    @Output() loadExternalTransactions: EventEmitter<void> = new EventEmitter<void>();
    @Output() create: EventEmitter<object> = new EventEmitter<object>();

    @Input() bankTransaction: ITransactionExternal | undefined;
    @Input() transactionType: any;
    @Input() createDisabled: boolean = false;
    @Input() ledgerAccountId: number | null = null;

    private unsubscribe: Subject<void> = new Subject();

    constructor() {}

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.closeSidebar.complete();
        this.loadExternalTransactions.complete();
        this.create.complete();
    }
}
