import {merge as observableMerge, Observable, Subject} from 'rxjs';
import {debounceTime, filter, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

import {Client} from '../../../../../models/client';
import {UserService} from '../../../../../services/user.service';
import {DealService} from '../../../../../services/deal.service';
import {GenericFormGroup} from '../../../../../entites/generic.entity';
import {CurrentProfileSource} from '../../../../../services/sources/current-profile.source';
import {ListItem} from '../../../../../models/calculation';
import {Profile} from '../../../../../models/profile';
import {AuthService} from '../../../../../services/auth.service';
import {Deal, DEAL_SYSTEM_STATUS} from '../../../../../models/deal';
import {ProfilesService} from '../../../../../services/profiles.service';
import {NotificationsServiceZipi} from '../../../../notifications/notifications.service';
import {RbacService} from '../../../../rbac/rbac.service';
import {DealProcessingService} from '../deal-processing.service';
import {TagsService} from '../../../../../services/api/tags.service';
import {SessionService} from '../../../../../services/session.service';
import {FinancialTransferEntity} from '../../../../account-info/compensation/models/financial-transfer.entity';
import {FinancialElementModel} from '../../../../account-info/compensation/models/financial-element.model';
import {ContactPartLink} from '../../../../../models/contact-part-link';
import {select, Store} from '@ngrx/store';
import {IFinanceState} from '../../../../finance/store/finance.reducer';
import {FetchProducts} from '../../../../finance/store/finance.actions';
import {selectProducts} from '../../../../finance/store/finance.selectors';
import {IProduct} from '@cyberco-nodejs/zipi-typings';
import {CompaniesService} from '../../../../../services/companies.service';
import {PromptComponent} from '../../../../../layouts/prompt/prompt.component';
import {ICustomDealField} from '@cyberco-nodejs/zipi-typings/index';
import {FeatureFlagsService} from '../../../../feature-flags/feature-flags.service';

@Component({
    selector: 'app-create-deal',
    styleUrls: ['../common/edit-deal.component.scss'],
    templateUrl: 'create-deal.component.html'
})
export class CreateDealComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    // @ViewChild('communication', { static: false }) communication: EditDealCommunicationComponent;
    @Output() dealCreatedOrSaved = new EventEmitter();

    dealFormGroup: GenericFormGroup<Deal> = new GenericFormGroup(new Deal(), 'change');
    public componentReady = false;
    public currentProfile: Profile = new Profile();

    showRefreshButton: boolean = false;
    saveDisabled: boolean = false;
    isPreviousNameMatched: boolean = true;

    selectedTagsIds: Array<any> = [];

    product$: Observable<IProduct[]> = new Observable<IProduct[]>();

    DEAL = Deal;
    showSideCountAndSalesVolumeFlag: boolean = false;

    updateCommissionCategorization(modified: Deal) {
        this.dealFormGroup.controls.commission_categorization_template!.patchValue(
            modified.commission_categorization_template,
            {emitEvent: false}
        );
        if (modified.commission_categorization_template.money_list.length > 0) {
            this.dealFormGroup.controls.flat_fee_commissions!.controls = new Array<GenericFormGroup<ListItem>>().concat(
                modified.commission_categorization_template.money_list
                    .map(
                        (item) =>
                            new GenericFormGroup<ListItem>(
                                {
                                    name: item.label,
                                    value: item.amount
                                },
                                'change'
                            )
                    )
                    .map((control) => {
                        control.disable({emitEvent: false});
                        return control;
                    }),
                this.dealFormGroup.controls.flat_fee_commissions!.controls.filter((control) => !control.disabled)
            );
            this.dealFormGroup.controls.flat_fee_commissions!.updateValueAndValidity();
        }
    }

    doRefresh() {
        const rawValue = this.dealFormGroup.getRawValue();
        rawValue.company = this.currentProfile.company!;

        // we do not run recalculations if there empty close_of_escrow
        if (!rawValue.close_of_escrow) {
            return;
        }

        this.dealFormGroup.disable({emitEvent: false});
        this.dealService.applySplitsToDeal(rawValue).then((modified) => {
            this.showRefreshButton = false;
            this.dealFormGroup.enable({emitEvent: false});
            this.updateCommissionCategorization(modified);

            this.dealFormGroup.controls.company_calculation!.patchValue(modified.company_calculation, {
                emitEvent: false
            });

            this.dealFormGroup.controls.sales_entities!.controls.forEach((salesEntityFG) => {
                if (salesEntityFG.controls.is_primary!.value) {
                    salesEntityFG.controls.compensation_plan!.disable({emitEvent: false});
                }
            });

            this.dealFormGroup.controls.sales_entities!.patchValue(modified.sales_entities, {emitEvent: false});

            this.dealFormGroup.controls.financial_transfers!.patchValue(modified.financial_transfers);
            this.dealProcessing.syncFinancialTransfers();
            this.dealFormGroup.updateValueAndValidity({emitEvent: false});
        });
    }

    constructor(
        public dealService: DealService,
        public authService: AuthService,
        public userService: UserService,
        protected profilesService: ProfilesService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        protected notificationService: NotificationsServiceZipi,
        protected rbacService: RbacService,
        protected currentProfileSource: CurrentProfileSource,
        private router: Router,
        public dealProcessing: DealProcessingService,
        private tagsService: TagsService,
        private sessionService: SessionService,
        private store: Store<IFinanceState>,
        private companiesService: CompaniesService,
        private featureFlagsService: FeatureFlagsService
    ) {
        this.dealProcessing.setupDeal(new Deal());
        this.dealFormGroup = this.dealProcessing.dealFG;
        this.currentProfileSource.changeProfileEvent
            .pipe(
                filter((profile) => profile !== null),
                takeUntil(this.unsubscribe)
            )
            .subscribe((profile) => {
                this.currentProfile = profile;
                this.dealFormGroup.controls.timezone!.patchValue(this.currentProfile.settings!.default_timezone);
                this.dealProcessing.loadedProfiles!.then(() => {
                    const primarySEParticipantSplit = new GenericFormGroup(
                        new FinancialTransferEntity()
                            .setType(FinancialTransferEntity.type_SET.participant_split)
                            .setOrigin(FinancialElementModel.type_set.participant)
                            .setValueType(FinancialTransferEntity.value_type_SET.percent_of_deal)
                            .setPercent(100)
                            .setReceiver(
                                new ContactPartLink()
                                    .setContactId(this.currentProfile.contact!.id!)
                                    .setLinkTitle(this.sessionService.profile!.contact!.display_name!)
                            )
                            .setSenderWildcardId(3)
                            .setOriginIsExpense(true)
                    );
                    this.dealProcessing.addFinancialTransfer(primarySEParticipantSplit);
                    this.dealProcessing.syncFinancialTransfers();
                });
            });

        observableMerge(
            this.dealFormGroup.controls.close_of_escrow!.valueChanges,
            this.dealFormGroup.controls.type!.valueChanges,
            this.dealFormGroup.controls.status!.valueChanges,
            this.dealFormGroup.controls.sales_price!.valueChanges,
            this.dealFormGroup.controls.income_commission_value!.valueChanges,
            this.dealFormGroup.controls.income_flat_commission_value!.valueChanges,
            this.dealFormGroup.controls.income_commission_type!.valueChanges,
            this.dealFormGroup.controls.referral_fee_value!.valueChanges,
            this.dealFormGroup.controls.referral_fee_type!.valueChanges,
            this.dealFormGroup.controls.sales_entities!.valueChanges.pipe(
                filter((value) => value.length > 0),
                takeUntil(this.unsubscribe)
            ),
            this.dealFormGroup.controls.company_calculation!.valueChanges
        )
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.showRefreshButton = true;
            });

        // Alex: we added ability to create draft deals without sales entities, so we don't need this check

        // this.dealFormGroup.controls.sales_entities.valueChanges
        //     .pipe(takeUntil(this.unsubscribe))
        //     .subscribe(salesEntites => {
        //         if (salesEntites.length === 0) {
        //             const primarySEParticipantSplit = new GenericFormGroup(new FinancialTransferEntity()
        //                 .setType(FinancialTransferEntity.type_SET.participant_split)
        //                 .setOrigin(FinancialElementModel.type_set.participant)
        //                 .setValueType(FinancialTransferEntity.value_type_SET.percent_of_deal).setPercent(100)
        //                 .setReceiver(new ContactPartLink().setContactId(this.currentProfile.contact.id).setLinkTitle(this.sessionService.profile.contact.display_name))
        //                 .setSenderWildcardId(3)
        //                 .setOriginIsExpense(true)
        //             );
        //
        //             this.dealProcessing.addFinancialTransfer(primarySEParticipantSplit);
        //             this.dealProcessing.syncFinancialTransfers();
        //         }
        //     });

        // auto-build deal name and watch changes
        observableMerge(
            this.dealFormGroup.controls.address!.valueChanges,
            this.dealFormGroup.controls.client_type!.valueChanges,
            this.dealFormGroup.controls.deal_participants!.valueChanges
        )
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((changes) => {
                if (this.isPreviousNameMatched) {
                    const address = this.dealFormGroup.controls.address!.value
                        ? this.dealFormGroup.controls.address!.value
                        : '';
                    const client = this._createParticipantsForDealName();

                    this.dealFormGroup.controls.name!.patchValue((address + (client ? ` ${client}` : '')).trim());
                }
            });

        this.dealFormGroup.controls.name!.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            this._checkIfDealNameMatchedRule();
        });

        // set custom fields
        this.companiesService
            .getCustomFields(this.currentProfile.company_fk_id!)
            .pipe(debounceTime(1000), takeUntil(this.unsubscribe))
            .subscribe((fields: {message: string; result: ICustomDealField[]}) => {
                this.dealFormGroup.controls.custom_deal_fields!.patchValue(fields.result, {emitEvent: true});
                this.dealFormGroup.controls.custom_deal_fields!.updateValueAndValidity();
            });

        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.showSideCountAndSalesVolumeFlag = this.featureFlagsService.isFeatureEnabled(
                    'deals:show_side_count_and_sales_volume'
                );
            });
    }

    async ngOnInit() {
        const canCreateAny = await this.rbacService.isDenied({deals__create_any: true});
        const canCreateOwn = await this.rbacService.isDenied({deals__create_own: true});
        const dealCreationDenied = canCreateAny && canCreateOwn;
        if (dealCreationDenied) {
            this.router.navigate(['/dashboard']);
            return;
        }
        const currentGroupId = Number(localStorage.getItem('current_company_group_id'));
        if (this.sessionService.profile!.company_groups_member) {
            const currentGroupMember = this.sessionService.profile!.company_groups_member.find((gm) => {
                if (gm.company_group) {
                    return gm.company_group.id === currentGroupId;
                } else {
                    return false;
                }
            });
            if (
                currentGroupMember &&
                currentGroupMember.company_group!.tag &&
                currentGroupMember.company_group!.tag.tag_id
            ) {
                this.selectedTagsIds.push(currentGroupMember.company_group!.tag.tag_id);
            }
        }

        this.componentReady = true;

        this.store.dispatch(new FetchProducts());
        this.product$ = this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe));
    }

    save(shouldStayOnPage: boolean = false) {
        // trim tne name
        if (this.dealFormGroup.controls.name!.value) {
            this.dealFormGroup.controls.name!.patchValue(this.dealFormGroup.controls.name!.value.trim());
        }

        // set required fields for transfers - product
        this.dealProcessing.setValidatorsToTransfers(false);
        // check required fields
        if (!this.dealProcessing.isDealFormValid() || this.saveDisabled) {
            return false;
        }

        const deal = this.dealFormGroup.getRawValue();
        deal.flat_fee_commissions = this.dealFormGroup.controls.flat_fee_commissions!.getEnabledValues();

        this.saveDisabled = true;

        if (
            !this.dealFormGroup.controls.type!.value ||
            !this.dealFormGroup.controls.close_of_escrow!.value ||
            !this.dealFormGroup.controls.sales_price!.value ||
            !this.dealFormGroup.controls.sales_entities!.controls.length ||
            typeof this.dealFormGroup.controls.sales_entities!.controls.find(
                (seFg) => seFg.controls.is_primary!.value
            ) === 'undefined'
        ) {
            const dialogRef = this.dialog.open(PromptComponent, {
                minWidth: 320,
                data: {
                    title: 'Draft',
                    input_label: `Deal Name:`,
                    default_input_value: deal.name,
                    buttonOkMessage: 'Create',
                    buttonCancelMessage: 'Return to Deal'
                }
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((dealName) => {
                    if (!dealName) {
                        this.saveDisabled = false;
                    } else {
                        // check after confirm to create draft if deal has closed/processed status
                        if (
                            this.dealFormGroup.controls.status!.value &&
                            (this.dealFormGroup.controls.status!.value === Deal.status_SET['closed'] ||
                                this.dealFormGroup.controls.status!.value === Deal.status_SET['processed'])
                        ) {
                            this.notificationService.addError(
                                "Status of Draft Deal should not be 'Closed' or 'Processed'"
                            );
                            this.saveDisabled = false;
                            return;
                        }

                        deal.system_status = DEAL_SYSTEM_STATUS.draft;
                        deal.name = dealName;
                        this.createDeal(deal, shouldStayOnPage);
                        this.saveDisabled = true;
                    }
                });
        } else {
            deal.system_status = DEAL_SYSTEM_STATUS.open;
            this.saveDisabled = true;
            return this.createDeal(deal, shouldStayOnPage);
        }
    }

    createDeal(dealToCreate: Deal, shouldStayOnPage = false) {
        let _createdDeal: Deal;
        return this.dealService
            .create(dealToCreate)
            .then(async (result: Deal) => {
                this.dealFormGroup.controls.id!.patchValue(result.id, {emitEvent: false});

                await this._createConnectionWithTag(result);

                _createdDeal = result;
            })
            .then(() => {
                if (shouldStayOnPage) {
                    this.dealFormGroup.markAsPristine();
                    return this.router.navigate([`/deals/edit/${_createdDeal.id}`], {
                        fragment: (+new Date()).toString(32)
                    });
                }
                return this.router.navigate(['/deals', _createdDeal.id]);
            })
            .catch(() => {
                this.saveDisabled = false;
            });
    }

    // setOwner(owner) {
    //     this.deal.profile = owner;
    // }
    //
    // changeOwner() {
    //     const dialogRef = this.dialog.open(OwnerSelectDialogComponent);
    //     dialogRef.afterClosed()
    //         .subscribe(owner => {
    //             if (!owner) {
    //                 // dialog was just closed, no need to save anything
    //                 return;
    //             }
    //             this.dealService.changeDealOwner(this.deal, owner)
    //                 .then(response => {
    //                     this.ngOnInit();
    //                 })
    //             ;
    //         });
    // }

    addClient() {
        this.dealFormGroup.controls.clients!.push(new GenericFormGroup(new Client(), 'change'));
    }

    updateSelectedTagsIds($event: number[]) {
        this.selectedTagsIds = $event;
    }

    async _createConnectionWithTag(deal: Deal) {
        return await this.tagsService.createTagDealRelation(this.selectedTagsIds, deal.id).toPromise();
    }

    public navigateToDeal(id: number) {
        this.router.navigate(['/deals', 'edit', id]).then(() => {
            // todo find better way to reload component
            // todo subscribe to router changing params and call some kind of init func

            window.location.reload();
        });
    }

    private _checkIfDealNameMatchedRule(): void {
        const address = this.dealFormGroup.controls.address!.value ? this.dealFormGroup.controls.address!.value : '';
        const client = this._createParticipantsForDealName();

        this.isPreviousNameMatched =
            this.dealFormGroup.controls.name!.value === (address + (client ? ` ${client}` : '')).trim();
    }
    private _createParticipantsForDealName(): string {
        const client_type = this.dealFormGroup.controls.client_type!.value;
        const participants = this.dealFormGroup.controls.deal_participants!.value;
        let client;
        if (client_type === this.DEAL.deal_client_type_SET.buyer) {
            client = participants.buyers?.map((b: ContactPartLink) => `(${b.link_title})`).join(', ');
        } else if (client_type === this.DEAL.deal_client_type_SET.seller) {
            client = participants.sellers?.map((b: ContactPartLink) => `(${b.link_title})`).join(', ');
        }

        return client;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.dealCreatedOrSaved.complete();
    }
}
