import {Component, OnInit, Input, EventEmitter, Output, OnDestroy} from '@angular/core';
import {SourceOfBusiness} from '../../../../../../../models/source-of-business';
import {
    DealsBoardBackendFilterSettings,
    DEAL_ORDER_BY_OPTIONS,
    ORDER_BY_STATUS,
    DealsBoardFrontendFilterSettings
} from '../../../common/deal.models';
import {ProfilesService} from '../../../../../../../services/profiles.service';
import {Profile} from '../../../../../../../models/profile';
import {AvailableProfilesSource} from '../../../../../../../services/sources/available-profiles.source';
import {MatDialog} from '@angular/material/dialog';
import {DealsViewPickerContainerDialogComponent} from '../../../../../../shared/components/deals-view-picker-container/deals-view-picker-container.dialog';
import {SessionService} from '../../../../../../../services/session.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Deal} from '../../../../../../../models/deal';

@Component({
    selector: 'app-deal-board-settings-gv',
    styles: [
        `
            .row {
                display: flex;
                justify-content: flex-start;
                flex-direction: row;
            }
            .col {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
            }
            mat-form-field {
                margin: 4px;
                margin-bottom: 0;
            }
        `
    ],
    template: `
        <div class="col">
            View Mode:
            <button mat-button (click)="includeDealsFromCompanies()">Include deals from companies</button>
            <br />
            <div class="row">
                <!--<mat-form-field>-->
                <!--<mat-select placeholder="Show NET by" [(ngModel)]="settings.show_net_by">-->
                <!--<mat-option *ngFor="let net of netBy" [value]="net.value">{{ net.label }}</mat-option>-->
                <!--</mat-select>-->
                <!--</mat-form-field>-->
                <!--<mat-checkbox [(ngModel)]="settings.show_only_owner_deals">Show only owner deals</mat-checkbox>-->
            </div>
            <div class="row">
                <mat-form-field>
                    <mat-select placeholder="Source" [(ngModel)]="settings.source_of_business">
                        <mat-option [value]="">Any</mat-option>
                        <mat-option *ngFor="let sob of sourceOfBusinessList" [value]="sob.label">{{
                            sob.label
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-select placeholder="Show Archived" [(ngModel)]="settings.is_archived">
                        <mat-option *ngFor="let item of archived" [value]="item.value">{{ item.label }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-select placeholder="Order by" [(ngModel)]="settings.order_by">
                        <mat-option *ngFor="let orderObj of orderByOptions" [value]="orderObj.value"
                            >{{ orderObj.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field>
                    <input
                        matInput
                        [matDatepicker]="closeOfEscrowAfter"
                        [(ngModel)]="settings.close_of_escrow_after"
                        placeholder="Closing Date (start date)"
                    />
                    <mat-datepicker-toggle matSuffix [for]="closeOfEscrowAfter"></mat-datepicker-toggle>
                    <mat-datepicker #closeOfEscrowAfter></mat-datepicker>
                </mat-form-field>

                <mat-form-field>
                    <input
                        matInput
                        [matDatepicker]="closeOfEscrowBefore"
                        [(ngModel)]="settings.close_of_escrow_before"
                        placeholder="Closing Date (end date)"
                    />
                    <mat-datepicker-toggle matSuffix [for]="closeOfEscrowBefore"></mat-datepicker-toggle>
                    <mat-datepicker #closeOfEscrowBefore></mat-datepicker>
                </mat-form-field>
            </div>
            <mat-form-field>
                <input matInput placeholder="Filter by Keyword" [(ngModel)]="filters.search" />
            </mat-form-field>
            <app-deal-board-filters (filterChanged)="frontendFilterChanged.emit($event)" [filters]="filters">
            </app-deal-board-filters>
        </div>
    `
})
export class DealBoardSettingsGvComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() filters: DealsBoardFrontendFilterSettings = {
        statuses: {},
        types: {},
        disbursement_statuses: {},
        property_classes: {},
        search: '',
        tags: null,
        divisions: null
    };
    @Input() settings: DealsBoardBackendFilterSettings = {
        source_of_business: [],
        close_of_escrow_after: null,
        close_of_escrow_before: null,
        is_archived: '',
        is_approved: '',
        filter_by_deal_members: [],
        by_company: {},
        show_net_by: 'user',
        show_only_owner_deals: false,
        deals_view_mode: 'company',
        order_by: ORDER_BY_STATUS,
        deal_stage: ['draft', 'open', 'approved', ''],
        closing_date_type: Deal.closing_date_LABELS.all_dated
    };
    @Output() frontendFilterChanged = new EventEmitter();
    @Output() needSave = new EventEmitter();

    public availableProfiles: Profile[] = [];

    public archived = [
        {value: 'yes', label: 'Yes'},
        {value: 'no', label: 'No'},
        {value: 'only', label: 'Only Archived'}
    ];

    public netBy = [
        {value: 'user', label: 'User'},
        {value: 'company', label: 'Company'}
    ];

    public sourceOfBusinessList: SourceOfBusiness[] = [];

    orderByOptions = DEAL_ORDER_BY_OPTIONS;

    constructor(
        protected profilesService: ProfilesService,
        protected availableProfilesSource: AvailableProfilesSource,
        public sessionService: SessionService,
        protected dialog: MatDialog
    ) {}

    includeDealsFromCompanies() {
        const dialogRef = this.dialog.open(DealsViewPickerContainerDialogComponent);
        dialogRef.componentInstance.init(
            this.sessionService.availableProfiles.filter((profile) => profile.type === Profile.type.default),
            this.settings.by_company
        );
    }

    async ngOnInit() {
        if (!this.settings) {
            this.settings = new DealsBoardBackendFilterSettings();
        }

        if (!this.settings.filter_by_deal_members) {
            this.settings.filter_by_deal_members = [];
        }

        if (!this.settings.deals_view_mode) {
            this.settings.deals_view_mode = 'company';
        }

        if (!this.settings.order_by) {
            this.settings.order_by = ORDER_BY_STATUS;
        }

        if (this.settings.close_of_escrow_after) {
            this.settings.close_of_escrow_after = new Date(this.settings.close_of_escrow_after);
        }

        if (this.settings.close_of_escrow_before) {
            this.settings.close_of_escrow_before = new Date(this.settings.close_of_escrow_before);
        }

        // temporary comment out because it does not work correctly
        // this.sourceOfBusinessList = await this.profilesService.getAvailableSOBsList();

        this.availableProfilesSource.availableProfiles
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((profiles: Profile[]) => {
                this.availableProfiles = profiles.filter((profile) => profile.type === Profile.type.default);
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.needSave.complete();
    }
}
