import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {IndicatorsState} from './indicators.reducer';

// import { SOMETHING_HAPPENED_ACTION, SomethingHappenedAction } from './indicators.actions';

@Injectable()
export class IndicatorsEffectsService {
    constructor(
        private actions$: Actions,
        protected store: Store<IndicatorsState>,
        private router: Router
    ) {}

    // @Effect({dispatch: false})
    // loginRedirect$ = this.actions$
    //     .ofType<SomethingHappenedAction>(SOMETHING_HAPPENED_ACTION)
    //     .do(data => {
    //         console.info('effect catched action occured', data);
    //
    //         // return this.router.navigate(['/auth/login']);
    //     });
}
