<mat-card class="sign-in-card">
    <mat-card-header>
        <div class="title">Real Estate Sales & Management Platform</div>
        <p class="auth-info-message" *ngIf="sessionService.redirectParams.authInfoMessage">
            {{ sessionService.redirectParams.authInfoMessage }}
        </p>
    </mat-card-header>

    <mat-card-content>
        <div class="flex-row" style="justify-content: space-between; text-align: center">
            <div class="flex-column" [ngStyle]="!router.url.includes('/sign-in/as') ? {width: '42%'} : {width: '100%'}">
                <div style="font-size: 14px">Log in using your Google Account</div>
                <button mat-raised-button color="primary" class="social-auth" (click)="tryGoogleAuth()">
                    <img class="google-icon" src="/assets/images/google-1015752_960_720.png" />Log in with Google
                </button>
            </div>
            <div class="flex-column" [ngStyle]="!router.url.includes('/sign-in/as') ? {width: '42%'} : {width: '100%'}">
                <div style="font-size: 14px">Log in using your Okta Account</div>
                <button mat-raised-button color="primary" class="social-auth" (click)="signInWithSkyslope()">
                    <img style="margin-top: -2px" class="google-icon" src="/assets/images/skyslope-logo.svg" />Log in
                    with Skyslope
                </button>
            </div>
            <ng-container *ngIf="!router.url.includes('/sign-in/as')">
                <div class="flex-column" style="text-align: center; width: 8%">
                    <div style="font-size: 14px; color: #7a7a7a">OR</div>
                    <div style="border-left: 1px solid #bdbdbd; height: 70%; width: 0; margin: 10px auto"></div>
                </div>
                <div class="flex-column mr-2" style="width: 45%">
                    <div style="font-size: 14px">Log in using your email address</div>
                    <form #form="ngForm" style="margin-top: 20px" method="post">
                        <div class="flex-row">
                            <mat-form-field>
                                <input matInput required type="email" ngModel name="email" placeholder="Email" />
                            </mat-form-field>
                        </div>

                        <div class="flex-row">
                            <mat-form-field>
                                <input
                                    matInput
                                    required
                                    type="password"
                                    ngModel
                                    name="password"
                                    placeholder="Password"
                                    autocomplete="off"
                                />
                            </mat-form-field>
                        </div>

                        <div class="flex-row" style="justify-content: space-between">
                            <div style="height: 100%; text-align: left">
                                <a href="javascript:" (click)="forgotPassword()">Forgot Password?</a><br />
                                <!--<a [routerLink]="['/auth', 'sign-up']">Don't have an account? Sign Up...</a>-->
                            </div>
                            <button mat-raised-button color="primary" (click)="onSubmit(form)">Log In</button>
                        </div>

                        <div style="margin-top: 30px; text-align: left"></div>
                    </form>
                </div>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>
