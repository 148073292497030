<div class="content">
    <div mat-dialog-title>
        <h3>Tax Record Errors</h3>
        <p class="sub-title">
            Please correct the following errors regarding this tax record profile. This will help ensure you have all
            information necessary to submit 1099s to the IRS.
        </p>
    </div>

    <mat-dialog-content>
        <ul>
            <li *ngIf="taxRecordNotFound">
                <h3>Payroll Tax Record has not been created for this contact.</h3>
            </li>
            <li *ngIf="emptyBox1">
                <h3>Box 1 amount needs to be > $0.</h3>
            </li>
        </ul>
    </mat-dialog-content>
</div>

<mat-divider></mat-divider>

<div class="footer">
    <mat-dialog-actions>
        <div class="text-right w-100">
            <button mat-raised-button class="ml-2" (click)="dialogRef.close(false)" tabindex="-1">Close</button>
        </div>
    </mat-dialog-actions>
</div>
