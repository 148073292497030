<mat-sidenav-container [hasBackdrop]="false" class="sidenav-container">
    <mat-sidenav #bankingSidebar mode="side" disableClose [position]="'end'" class="banking-sidebar-sidenav">
        <mat-tab-group *ngIf="bankExternalTransactions && bankExternalTransactions.length > 0">
            <mat-tab label="Match transactions">
                <app-banking-match
                    [bankTransactions]="bankExternalTransactions"
                    (closeSidebar)="close()"
                    (loadExternalTransactions)="loadExternalTransactions.emit()"
                    (loadInternalTransactions)="loadInternalTransactions.emit()"
                    class="d-block m-3"
                    style="margin-bottom: 110px !important"
                >
                </app-banking-match>
            </mat-tab>
            <mat-tab label="Categorize manually">
                <mat-card class="m-3" *ngIf="!bankExternalTransaction">
                    Manual categorization for multiple External transactions is not available
                </mat-card>
                <mat-card class="m-3" *ngIf="bankExternalTransaction">
                    <mat-form-field class="w-100">
                        <mat-label>Category</mat-label>
                        <mat-select [formControl]="categoryControl">
                            <ng-container *ngIf="bankExternalTransaction.debit_or_credit === 'debit'">
                                <mat-option *ngFor="let type of transactionTypes.moneyIn" [value]="type">
                                    {{ type.label }}
                                </mat-option>
                            </ng-container>

                            <ng-container *ngIf="bankExternalTransaction.debit_or_credit === 'credit'">
                                <mat-option *ngFor="let type of transactionTypes.moneyOut" [value]="type">
                                    {{ type.label }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <app-add-transaction
                        *ngIf="categoryControl.value"
                        (closeSidebar)="close()"
                        (create)="manualMatch($event)"
                        [bankTransaction]="bankExternalTransaction"
                        [createDisabled]="createDisabled"
                        [transactionType]="categoryControl.value"
                        [ledgerAccountId]="ledgerAccountId"
                    ></app-add-transaction>

                    <button mat-button class="mat-primary" (click)="close()" *ngIf="!categoryControl.value">
                        Cancel
                    </button>
                </mat-card>
            </mat-tab>
        </mat-tab-group>

        <div style="margin-bottom: 110px !important" *ngIf="transactionType">
            <mat-card class="m-3">
                <h3 class="text-muted">{{ transactionType.label }}</h3>

                <app-add-transaction
                    (closeSidebar)="close($event)"
                    (create)="addTransaction($event)"
                    [createDisabled]="createDisabled"
                    [transactionType]="transactionType"
                    [ledgerAccountId]="ledgerAccountId"
                ></app-add-transaction>
            </mat-card>
        </div>

        <app-internal-transaction
            style="margin-bottom: 110px !important"
            *ngIf="internalTransaction"
            [internalTransaction]="internalTransaction"
            (closeSidebar)="close($event)"
        ></app-internal-transaction>
    </mat-sidenav>
    <ng-content></ng-content>
</mat-sidenav-container>
