import {debounceTime, filter, takeUntil} from 'rxjs/operators';
import {
    AfterViewChecked,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {Profile} from 'app/models/profile';
import {Group} from 'app/models/group';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {GenericFormArray, GenericFormGroup} from 'app/entites/generic.entity';
import {AuthService} from 'app/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs';
import {GroupsSource} from 'app/services/sources/groups.source';
import {GroupMemberEntity} from 'app/models/group-member.model';
import {GroupApi} from 'app/services/api/group.api';
import {AppInitSource} from 'app/services/sources/app-init.source';
import {IRole} from '@cyberco-nodejs/zipi-typings/companies.typings';
import {MatSidenav} from '@angular/material/sidenav';
import {ChipNode} from '../../../compensation/models/chip-node';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {CompanyPermissionsSource} from 'app/services/sources/companyPermissions.source';
import {AvailableRolesSource} from 'app/services/sources/available-roles.source';

@Component({
    selector: 'app-division-edit-sidenav',
    styleUrls: ['./edit.component.css'],
    templateUrl: 'edit.component.html'
})
export class EditComponent implements OnInit, OnDestroy, AfterViewChecked {
    private unsubscribe: Subject<void> = new Subject();

    public groupFG: GenericFormGroup<Group> = new GenericFormGroup<Group>(new Group());

    @ViewChild('sidenavGroupMembers', {static: true}) sidenavGroupMembers: MatSidenav | undefined;
    @ViewChild('header', {static: true}) header: ElementRef | undefined;
    @ViewChildren('divCheckbox') divCheckboxes: QueryList<ElementRef> | undefined;
    @ViewChildren('checkbox') checkboxes: QueryList<MatCheckbox> | undefined;

    @Input() isOpened: boolean = false;

    members: GroupMemberEntity[] = [];
    public isLoaded: Promise<any>;
    public isDisabled: boolean = false;
    public currentProfile: Profile | null = null;
    @Input() groupId: number | null = null;
    editDisabled: boolean = false;

    protected groupsList: Group[] = [];
    private selectedGroup: Group | null = null;

    membersToDelete: number[] = [];

    public emails: string = '';

    allRoles: {systemRoles: IRole[]; companyRoles: IRole[]} = {systemRoles: [], companyRoles: []};
    companyPermissions: any;

    offsetTopToLazyLoading$: BehaviorSubject<number> | undefined;
    showMore: boolean = true;
    scrollData: {
        limit: number;
        offset: number;
        id: null | number;
    } = {
        limit: 30,
        offset: 0,
        id: null
    };

    selectedProfilesFA: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    selectedProfileIds: number[] = [];
    manageMembers: boolean = false;
    headerOffset: number | null = null;

    constructor(
        protected currentProfileSource: CurrentProfileSource,
        public authService: AuthService,
        protected router: Router,
        private route: ActivatedRoute,
        private groupSrc: GroupsSource,
        private groupService: GroupApi,
        protected appInitSource: AppInitSource,
        private companyPermissionsSource: CompanyPermissionsSource,
        private availableRolesSource: AvailableRolesSource
    ) {
        this.isLoaded = new Promise((resolve) => {
            this.currentProfileSource.changeProfileEvent
                .pipe(
                    filter((profile) => profile.company !== null),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((profile) => {
                    this.currentProfile = profile;
                    resolve(null);
                });
        }).then(() => {
            this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((groups) => {
                this.groupsList = groups.filter((group) => group.type === Group.type_SET.division);
            });
        });
    }

    ngOnInit() {
        this.groupFG.controls.members!.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.groupFG.controls.changed!.patchValue(true);
        });

        this.companyPermissionsSource.source.pipe(takeUntil(this.unsubscribe)).subscribe((permissions) => {
            if (permissions) {
                this.companyPermissions = permissions;
            }
        });

        this.availableRolesSource.source.pipe(takeUntil(this.unsubscribe)).subscribe((roles) => {
            if (roles) {
                this.allRoles.systemRoles = roles.systemRoles.filter((role) => role.scope === 'company');

                this.allRoles.companyRoles = roles.companyRoles.filter((role) => role.scope === 'company');
            }
        });

        this.selectedProfilesFA.valueChanges
            .pipe(debounceTime(100), takeUntil(this.unsubscribe))
            .subscribe((chipNodes) => {
                if (chipNodes.length) {
                    const selectedProfile = chipNodes[chipNodes.length - 1];
                    this.addProfileToGroup({
                        profile_id: selectedProfile.target_id,
                        id: selectedProfile.target_id,
                        email: selectedProfile.email,
                        first_name: selectedProfile.label?.split(' ')[0],
                        last_name: selectedProfile.label?.split(' ')[1]
                    });
                }

                this.selectedProfileIds = chipNodes.map((p: {target_id: any}) => p.target_id);
            });
    }

    ngAfterViewChecked() {
        if (
            this.headerOffset &&
            this.header &&
            this.offsetTopToLazyLoading$ &&
            this.headerOffset !== this.header.nativeElement.offsetHeight
        ) {
            this.headerOffset = this.header.nativeElement.offsetHeight;
            this.offsetTopToLazyLoading$.next(this.header.nativeElement.offsetTop + 100);
        }
    }

    saveGroup() {
        const currentProfile = this.currentProfile;
        if (currentProfile) {
            this.isDisabled = true;
            const company_group: Group = this.groupFG.getRawValue();
            company_group.enumerable = company_group.enumerable.filter(
                (item) => item.period || item.amount || item.title || item.units
            );
            const members: GroupMemberEntity[] = this.members;
            company_group.members = members;
            company_group.number_of_members = members.length;
            if (company_group && company_group.id) {
                this.groupService.updateMembers(company_group.id, company_group, this.membersToDelete).then(() => {
                    this.groupSrc.load(currentProfile);
                    this.close();
                    this.isDisabled = false;
                });
            } else {
                this.isDisabled = false;
            }
        }
    }

    openGroup(id: number) {
        if (this.sidenavGroupMembers && this.header) {
            this.sidenavGroupMembers.open();

            this.scrollData.offset = 0;
            this.showMore = true;
            this.groupId = id;
            this.selectedGroup = this.groupsList.find((group) => group.id === this.groupId) || null;
            if (this.selectedGroup) {
                this.groupFG.patchValue(this.selectedGroup);
            }
            if (
                this.groupFG.controls.id &&
                this.groupFG.controls.id.value !== this.currentProfile!.company!.company_group!.id
            ) {
                this.editDisabled = true;
            }
            this.membersToDelete = []; // set initial state

            this.nextBatch();

            this.headerOffset = this.header.nativeElement.offsetHeight;
            if (this.offsetTopToLazyLoading$) {
                this.offsetTopToLazyLoading$.next(115);
            } else {
                this.offsetTopToLazyLoading$ = new BehaviorSubject<number>(100);
            }
        }
    }

    close() {
        this.selectedGroup = null;
        this.groupId = null;
        this.scrollData.id = null;

        this.members = [];
        this.selectedProfileIds = [];
        this.manageMembers = false;
        this.headerOffset = null;
        this.selectedProfilesFA.clear();
        this.membersToDelete = [];

        if (this.sidenavGroupMembers) {
            this.sidenavGroupMembers.close();
        }
        this.editDisabled = false;
    }

    removeFromList(member: GroupMemberEntity, memberIndex: number) {
        this.members.splice(memberIndex, 1);
        this.members = [...this.members];

        this.selectedProfileIds.splice(memberIndex, 1);

        if (member.profile && member.profile.id) {
            const indexToDeleteFromForm = this.selectedProfilesFA
                .getRawValue()
                .findIndex((p) => p.target_id === member?.profile?.id);
            this.selectedProfilesFA.removeAt(indexToDeleteFromForm);
        }
    }

    /*removeMember(mi: number, member: GroupMemberEntity) {
        const removingIndex = this.members.findIndex(m => m.id === member.id);
        if (removingIndex !== -1) {
            this.members.splice(removingIndex, 1);
            this.members = [...this.members];
        }

        const memberId = member.id;
        if (memberId) {
            this.membersToDelete.push(memberId);
        }
    }*/

    addProfileToGroup(profile: Partial<Profile>) {
        const foundMember = this.members.find((member) => member.profile && member.profile.id === profile.id);
        if (!foundMember && profile.email) {
            const newMember = new GroupMemberEntity()
                .setProfile(Object.assign(new Profile(), profile))
                .setRole(GroupMemberEntity.role_SET.default)
                .setStatus(GroupMemberEntity.status_SET.active)
                .setEmail(profile.email);
            this.members = [newMember, ...this.members];
        }
    }

    nextBatch() {
        if (!this.showMore || !this.groupId) {
            return;
        }

        this.showMore = false;
        if (this.scrollData.id !== null) {
            this.scrollData.offset += this.scrollData.limit;
        }
        this.scrollData.id = Number(this.groupId);

        this.groupService.getMemberPageForGroup(this.scrollData).then((groupMembers) => {
            if (this.scrollData.offset === 0) {
                this.members = groupMembers;
            } else {
                this.members = [...this.members, ...groupMembers];
            }

            this.showMore = groupMembers.length >= this.scrollData.limit;
        });
    }

    toggleDealCheckbox(control: MatCheckboxChange) {
        const memberId = Number(control.source.value);
        if (this.manageMembers) {
            this.manageMembers = false;
        }

        if (control.checked) {
            this.membersToDelete.push(memberId);
        } else {
            this.membersToDelete.splice(this.membersToDelete.indexOf(memberId), 1);
        }
    }

    removeMembersBeforeSave() {
        this.members = [...this.members.filter((m) => m.id)];
        this.saveGroup();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        if (this.offsetTopToLazyLoading$) {
            this.offsetTopToLazyLoading$.complete();
        }
    }
}
