<div class="confirm-payments-content d-flex flex-column">
    <div class="title">
        <span>Unable to process selected items</span>
        <span class="sp-spacer"></span>
        <mat-icon class="sp-cursor-pointer" (click)="dialogRef.close()">close</mat-icon>
    </div>
    <div class="description">
        Some of your selections are unable to be processed together. Please ensure all of your selected items were paid
        through the same account using one payment mode.
    </div>
</div>
<div class="confirm-payments-footer">
    <div class="buttons-row">
        <button mat-raised-button class="mat-primary ml-2 button print-btn" (click)="dialogRef.close()">Close</button>
    </div>
</div>
