import {Injectable} from '@angular/core';
import {ServiceRequester} from '../service.requester';
import {NotificationsServiceZipi} from '../../modules/notifications/notifications.service';

@Injectable()
export class ChangeEmailApi {
    constructor(
        public requester: ServiceRequester,
        protected notificationsService: NotificationsServiceZipi
    ) {}

    getChangeEmailProfileInfo(changeEmailHash: string): Promise<any> {
        return this.requester.makeMsCall('/public/change-email/profile', 'GET', {
            change_email_hash: changeEmailHash
        });
    }

    completeEmailChange(profileId: number, hash: string, userData: object = {}) {
        return this.requester.makeMsCall('/public/change-email/complete', 'POST', {
            profile_id: profileId,
            change_email_hash: hash,
            userData: userData
        });
    }

    initEmailChange(newEmail: string): Promise<any> {
        return this.requester.makeMsCall('/change-email/start', 'PUT', {new_email: newEmail});
    }
}
