<!------- Top menu ------->
<mat-toolbar class="main-toolbar mat-elevation-z3">
    <mat-icon class="nav-icons menu-icon" (click)="toggleSidenav(sidenavContainer)">menu</mat-icon>

    <app-profiles-menu></app-profiles-menu>

    <span class="remaining-space"></span>

    <app-search-bar class="global-search-container"></app-search-bar>

    <span class="remaining-space"></span>

    <span
        *ngIf="isAdminSupportUser"
        (click)="openSupportAccountLookup()"
        style="font-size: 16px; font-weight: normal; text-align: right; margin-right: 20px; line-height: 18px"
    >
        <div class="clearfix supportBox">
            <div style="float: left; padding-top: 5px">
                <mat-icon>person</mat-icon>
            </div>
            <div style="float: right">
                <span style="font-size: 12px; font-weight: bold; line-height: 10px">Support<br />Access</span>
            </div>
        </div>
    </span>

    <span style="font-size: 16px; font-weight: normal; text-align: right; margin-right: 20px; line-height: 18px">
        <span> {{ sessionService?.user?.first_name }} {{ sessionService?.user?.last_name }}</span>
        <br /><span style="font-size: 12px">{{ sessionService?.user?.email }}</span>
    </span>

    <app-skyslope-apps-menu *ngIf="isSkyslopeAppsMenuFlagEnabled"></app-skyslope-apps-menu>

    <button
        type="button"
        mat-icon-button
        style="margin-right: 10px"
        (click)="toggleNotificationsSidenav()"
        class="d-flex justify-content-center notifications-bell-button align-items-center"
    >
        <mat-icon
            [matBadge]="unseenMessagesCounter + ''"
            [matBadgeHidden]="unseenMessagesCounter < 1"
            matBadgePosition="before"
            style="font-size: 30px"
            matBadgeColor="warn"
            *ngIf="!spinnerNeeded"
        >
            notifications
        </mat-icon>
        <div
            *ngIf="spinnerNeeded"
            [matBadge]="unseenMessagesCounter + ''"
            [matBadgeHidden]="unseenMessagesCounter < 1"
            matBadgePosition="before"
            matBadgeColor="warn"
        >
            <mat-spinner [diameter]="26" id="top" [strokeWidth]="3"> </mat-spinner>
        </div>
    </button>

    <app-create-menu></app-create-menu>

    <app-user-menu (showAccountMenu)="closeNotificationsSidenav(); openAccountsSidenav()" #appUserMenu></app-user-menu>
</mat-toolbar>

<mat-progress-bar
    mode="indeterminate"
    [class.hide]="!pendingRequestsService.hasPendingRequest"
    class="pw-progress-bar"
></mat-progress-bar>

<app-notifications [notification_mode]="'header'"></app-notifications>

<mat-sidenav-container #sidenavContainer class="main-sidenav-container">
    <mat-sidenav
        #sidenav
        class="main-sidenav"
        [class.main-sidenav--transition]="sidenavTransition"
        [class.main-sidenav--minimized]="!sidenavOpened"
        [opened]="true"
        mode="side"
        position="start"
        (mouseenter)="hoverer$.next()"
    >
        <mat-accordion class="mat-accordion-menu">
            <img
                *ngIf="sidenavOpened"
                class="books-logo"
                src="https://storage.googleapis.com/zipi-static/app-assets/skyslope-books-logo.svg"
                alt="SkySlope Books"
            />
            <img
                *ngIf="!sidenavOpened"
                class="books-icon-only"
                src="https://storage.googleapis.com/zipi-static/app-assets/skyslope-books-icon-blue.svg"
                alt="SkySlope Books"
            />
            <mat-divider class="side-menu-divider"></mat-divider>

            <!------- Deals ------->
            <mat-expansion-panel
                #deals
                hideToggle="true"
                [rule]="{deals__access: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                routerLinkActive="router-link-parent"
                class="mat-expansion-panel"
                [expanded]="needOpenSubmenuItems.deals"
                (afterExpand)="router.navigate(['/deals'])"
            >
                <mat-expansion-panel-header
                    [ngClass]="{'is-active': router.url.includes('/deals') || router.url.includes('/escrowdeposit')}"
                    class="router-link-child expansion-panel-header"
                    (click)="onSidenavSubmenuToggle('deals')"
                    [collapsedHeight]="submenuHeaderHeight"
                    [expandedHeight]="submenuHeaderHeight"
                >
                    <mat-panel-title class="panel-title" *ngIf="sidenavOpened">
                        <mat-icon class="nav-icon">home_work</mat-icon>
                        Deals
                        <span class="sp-spacer"></span>
                        <mat-icon class="nav-icon-caret" *ngIf="!deals.expanded && !sidenavTransition">
                            arrow_right
                        </mat-icon>
                        <mat-icon class="nav-icon-caret" *ngIf="deals.expanded && !sidenavTransition">
                            arrow_drop_down
                        </mat-icon>
                    </mat-panel-title>
                    <mat-panel-title *ngIf="!sidenavOpened">
                        <mat-icon
                            matTooltip="Deals"
                            matTooltipPosition="right"
                            [routerLink]="['/deals']"
                            class="nav-icon"
                        >
                            home_work
                        </mat-icon>
                        Deals
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-nav-list
                    *ngIf="sidenavOpened"
                    class="nav-list mat-expansion-panel-list mat-expansion-panel-list-menu"
                >
                    <!------- Deals ------->
                    <a
                        *ngIf="deals.expanded"
                        class="mat-button dropdown-link-item"
                        mat-button
                        [routerLink]="['/deals']"
                        [ngClass]="
                            router.url &&
                            router.url.includes('deals') &&
                            !['/deals/remainingpayouts', '/deals/closingpayouts'].includes(router.url)
                                ? 'is-active'
                                : ''
                        "
                        routerLinkActive="is-active"
                        [routerLinkActiveOptions]="{exact: true}"
                        [fragment]="randomFragment"
                        [rule]="{deals__access: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        All Deals
                    </a>

                    <!-------  Escrow Deposit ------->
                    <app-auto-open-menu *ngIf="deals.expanded">
                        <a
                            class="mat-button dropdown-link-item user-layout-button"
                            mat-button
                            trigger
                            [anyRule]="[{escrow_deposits__access: true}, {deals__manage_escrow_deposits: true}]"
                            routerLinkActive="is-active"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'flex'}"
                            [denyStyle]="{display: 'none'}"
                            [ngClass]="{'is-active': router.url.includes('/escrowdeposit')}"
                            (click)="escrowDepositsNavigateByClick()"
                        >
                            Manage Earnest Money
                        </a>
                        <div content>
                            <a
                                mat-menu-item
                                [routerLink]="['/escrowdeposit']"
                                rbac
                                [anyRule]="[{escrow_deposits__access: true}, {deals__manage_escrow_deposits: true}]"
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                            >
                                Deposit Requests
                            </a>
                            <a
                                mat-menu-item
                                [routerLink]="['/escrowdeposit/releases']"
                                rbac
                                [anyRule]="[{escrow_deposits__access: true}, {deals__manage_escrow_deposits: true}]"
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                            >
                                Deposit Releases
                            </a>
                        </div>
                    </app-auto-open-menu>

                    <!------- Closing Payouts(Confirm Payments) ------->
                    <a
                        class="mat-button dropdown-link-item"
                        mat-button
                        *ngIf="deals.expanded"
                        [anyRule]="[{deals__manage_payouts: true}, {deals__manage_confirm_payments: true}]"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                        [routerLink]="'/deals/closingpayouts'"
                        routerLinkActive="is-active"
                    >
                        Confirm Payments
                    </a>

                    <!------- Remaining Payouts ------->
                    <a
                        *ngIf="deals.expanded"
                        class="mat-button dropdown-link-item"
                        mat-button
                        [anyRule]="[{deals__manage_payouts: true}, {deals__manage_remaining_payouts: true}]"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                        [routerLink]="'/deals/remainingpayouts'"
                        routerLinkActive="is-active"
                    >
                        Remaining Payouts
                    </a>
                </mat-nav-list>
            </mat-expansion-panel>

            <!------- Sales - A/R ------->
            <mat-expansion-panel
                #sales
                hideToggle="true"
                [rule]="{sales__access: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                routerLinkActive="router-link-parent"
                class="mat-expansion-panel"
                [expanded]="needOpenSubmenuItems.sales"
                (afterExpand)="router.navigate(['/sales'])"
            >
                <mat-expansion-panel-header
                    [ngClass]="{'is-active': router.url.includes('/sales/')}"
                    class="router-link-child expansion-panel-header"
                    (click)="onSidenavSubmenuToggle('sales')"
                    [collapsedHeight]="submenuHeaderHeight"
                    [expandedHeight]="submenuHeaderHeight"
                >
                    <mat-panel-title class="no-wrap-title panel-title" *ngIf="sidenavOpened">
                        <mat-icon class="nav-icon material-icons-round">local_atm</mat-icon>
                        Sales (A/R)
                        <span class="sp-spacer"></span>
                        <mat-icon class="nav-icon-caret" *ngIf="!sales.expanded && !sidenavTransition">
                            arrow_right
                        </mat-icon>
                        <mat-icon class="nav-icon-caret" *ngIf="sales.expanded && !sidenavTransition">
                            arrow_drop_down
                        </mat-icon>
                    </mat-panel-title>
                    <mat-panel-title class="no-wrap-title" *ngIf="!sidenavOpened">
                        <mat-icon
                            matTooltip="Sales (A/R)"
                            matTooltipPosition="right"
                            [routerLink]="['/sales']"
                            class="nav-icon material-icons-round"
                            >local_atm
                        </mat-icon>
                        Sales (A/R)
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-nav-list
                    *ngIf="sidenavOpened"
                    class="nav-list mat-expansion-panel-list mat-expansion-panel-list-menu"
                >
                    <!------- Invoices ------->
                    <app-auto-open-menu *ngIf="sales.expanded">
                        <a
                            class="mat-button dropdown-link-item user-layout-button"
                            mat-button
                            trigger
                            [anyRule]="[
                                {sales__view_invoices: true},
                                {sales__view_recurring_invoices: true},
                                {sales__view_source_documents: true}
                            ]"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'flex'}"
                            [denyStyle]="{display: 'none'}"
                            [ngClass]="{
                                'is-active':
                                    router.url.includes('/sales/invoices') ||
                                    router.url.includes('/sales/sourcedocuments')
                            }"
                            (click)="invoicesNavigateByClick()"
                        >
                            Invoices
                        </a>
                        <div content>
                            <a
                                mat-menu-item
                                [routerLink]="['/sales/invoices/recurring']"
                                [rule]="{sales__view_recurring_invoices: true}"
                                rbac
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                                >Recurring Invoices</a
                            >
                            <a
                                mat-menu-item
                                [routerLink]="['/sales/sourcedocuments']"
                                [rule]="{sales__view_source_documents: true}"
                                rbac
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                                >Reference Documents</a
                            >
                        </div>
                    </app-auto-open-menu>

                    <!------- Payments Received ------->
                    <a
                        *ngIf="sales.expanded"
                        class="mat-button dropdown-link-item"
                        mat-button
                        [rule]="{sales__view_payments_received: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                        [routerLink]="'/sales/paymentsreceived'"
                        routerLinkActive="is-active"
                    >
                        Payments Received
                    </a>

                    <!------- Credit Notes ------->
                    <app-auto-open-menu *ngIf="sales.expanded">
                        <a
                            class="mat-button dropdown-link-item user-layout-button"
                            mat-button
                            trigger
                            [anyRule]="[{sales__view_customer_credits: true}, {sales__view_source_documents: true}]"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'flex'}"
                            [denyStyle]="{display: 'none'}"
                            [ngClass]="{
                                'is-active':
                                    router.url.includes('/sales/creditnotes') ||
                                    router.url.includes('/sales/sourcecredits')
                            }"
                            (click)="creditNotesNavigateByClick()"
                        >
                            Credit Notes
                        </a>
                        <div content>
                            <a
                                mat-menu-item
                                [routerLink]="['/sales/creditnotes/recurring']"
                                [rule]="{sales__view_customer_credits: true}"
                                rbac
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                                >Recurring Credits</a
                            >
                            <a
                                mat-menu-item
                                [routerLink]="['/sales/sourcecredits']"
                                [rule]="{sales__view_source_documents: true}"
                                rbac
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                                >Reference Documents</a
                            >
                        </div>
                    </app-auto-open-menu>

                    <!------- Statement ------->
                    <a
                        *ngIf="sales.expanded && enableInvoiceStatements"
                        class="mat-button dropdown-link-item"
                        mat-button
                        [rule]="{sales__manage_statements: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                        [routerLink]="'/sales/statements'"
                        routerLinkActive="is-active"
                    >
                        Statements
                    </a>
                </mat-nav-list>
            </mat-expansion-panel>

            <!------- Purchases - A/P ------->
            <mat-expansion-panel
                #purchases
                hideToggle="true"
                [rule]="{purchases__access: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                routerLinkActive="router-link-parent"
                class="mat-expansion-panel"
                [expanded]="needOpenSubmenuItems.purchases"
                (afterExpand)="router.navigate(['/purchases'])"
            >
                <mat-expansion-panel-header
                    [ngClass]="{'is-active': router.url.includes('/purchases')}"
                    class="router-link-child expansion-panel-header"
                    (click)="onSidenavSubmenuToggle('purchases')"
                    [collapsedHeight]="submenuHeaderHeight"
                    [expandedHeight]="submenuHeaderHeight"
                >
                    <mat-panel-title class="no-wrap-title panel-title" *ngIf="sidenavOpened">
                        <mat-icon class="nav-icon material-icons-round">receipt</mat-icon>
                        Purchases (A/P)
                        <span class="sp-spacer"></span>
                        <mat-icon class="nav-icon-caret" *ngIf="!purchases.expanded && !sidenavTransition">
                            arrow_right
                        </mat-icon>
                        <mat-icon class="nav-icon-caret" *ngIf="purchases.expanded && !sidenavTransition">
                            arrow_drop_down
                        </mat-icon>
                    </mat-panel-title>
                    <mat-panel-title class="no-wrap-title" *ngIf="!sidenavOpened">
                        <mat-icon
                            matTooltip="Purchases (A/P)"
                            matTooltipPosition="right"
                            [routerLink]="['/purchases']"
                            class="nav-icon material-icons-round"
                            >receipt
                        </mat-icon>
                        Purchases (A/P)
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-nav-list
                    *ngIf="sidenavOpened"
                    class="nav-list mat-expansion-panel-list mat-expansion-panel-list-menu"
                >
                    <!------- Bills ------->
                    <app-auto-open-menu *ngIf="purchases.expanded">
                        <a
                            class="mat-button dropdown-link-item user-layout-button"
                            mat-button
                            trigger
                            [anyRule]="[
                                {purchases__view_bills: true},
                                {purchases__view_recurring_bills: true},
                                {purchases__view_source_documents: true}
                            ]"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'flex'}"
                            [denyStyle]="{display: 'none'}"
                            [ngClass]="{
                                'is-active':
                                    router.url.includes('/purchases/bills') ||
                                    router.url.includes('/purchases/sourcedocuments')
                            }"
                            (click)="billsNavigateByClick()"
                        >
                            Bills
                        </a>
                        <div content>
                            <a
                                mat-menu-item
                                [routerLink]="['/purchases/bills/recurring']"
                                [rule]="{purchases__view_recurring_bills: true}"
                                rbac
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                                >Recurring Bills</a
                            >
                            <a
                                mat-menu-item
                                [routerLink]="['/purchases/sourcedocuments']"
                                [rule]="{purchases__view_source_documents: true}"
                                rbac
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                                >Reference Documents</a
                            >
                        </div>
                    </app-auto-open-menu>

                    <!------- Payments Made ------->
                    <a
                        *ngIf="purchases.expanded"
                        class="mat-button dropdown-link-item"
                        mat-button
                        [rule]="{purchases__view_payments_made: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                        [routerLink]="'/purchases/payments'"
                        routerLinkActive="is-active"
                    >
                        Payments Made
                    </a>

                    <!------- Expenses ------->
                    <a
                        *ngIf="purchases.expanded"
                        class="mat-button dropdown-link-item"
                        mat-button
                        [rule]="{purchases__view_expenses: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                        [routerLink]="'/purchases/expenses'"
                        routerLinkActive="is-active"
                    >
                        Expenses
                    </a>

                    <!------- Vendor Credits ------->
                    <app-auto-open-menu *ngIf="purchases.expanded">
                        <a
                            class="mat-button dropdown-link-item user-layout-button"
                            mat-button
                            trigger
                            [anyRule]="[
                                {purchases__view_vendor_credits: true},
                                {purchases__view_source_documents: true}
                            ]"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'flex'}"
                            [denyStyle]="{display: 'none'}"
                            [ngClass]="{
                                'is-active':
                                    router.url.includes('/purchases/vendorcredits') ||
                                    router.url.includes('/purchases/sourcecredits')
                            }"
                            (click)="vendorCreditsNavigateByClick()"
                        >
                            Vendor Credits
                        </a>
                        <div content>
                            <a
                                mat-menu-item
                                [routerLink]="['/purchases/sourcecredits']"
                                [rule]="{purchases__view_source_documents: true}"
                                rbac
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                                >Reference Documents</a
                            >
                        </div>
                    </app-auto-open-menu>
                </mat-nav-list>
            </mat-expansion-panel>

            <!------- Liabilities (Lending) ------->
            <mat-expansion-panel
                #lending
                hideToggle="true"
                [rule]="{lending__access: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                routerLinkActive="router-link-parent"
                class="mat-expansion-panel"
                [expanded]="needOpenSubmenuItems.lending"
                (afterExpand)="router.navigate(['/lending/advances'])"
            >
                <mat-expansion-panel-header
                    [ngClass]="{'is-active': router.url.includes('/lending/')}"
                    class="router-link-child expansion-panel-header"
                    (click)="onSidenavSubmenuToggle('lending')"
                    [collapsedHeight]="submenuHeaderHeight"
                    [expandedHeight]="submenuHeaderHeight"
                >
                    <mat-panel-title class="panel-title" *ngIf="sidenavOpened">
                        <mat-icon class="nav-icon">description</mat-icon>
                        Liabilities
                        <span class="sp-spacer"></span>
                        <mat-icon class="nav-icon-caret" *ngIf="!lending.expanded && !sidenavTransition">
                            arrow_right
                        </mat-icon>
                        <mat-icon class="nav-icon-caret" *ngIf="lending.expanded && !sidenavTransition">
                            arrow_drop_down
                        </mat-icon>
                    </mat-panel-title>

                    <mat-panel-title *ngIf="!sidenavOpened">
                        <mat-icon
                            matTooltip="Liabilities"
                            matTooltipPosition="right"
                            [routerLink]="['/lending']"
                            class="nav-icon"
                            >description
                        </mat-icon>
                        Liabilities
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-nav-list
                    *ngIf="sidenavOpened"
                    class="nav-list mat-expansion-panel-list mat-expansion-panel-list-menu"
                >
                    <a
                        *ngIf="lending.expanded"
                        mat-button
                        class="mat-button dropdown-link-item"
                        routerLink="/lending/advances"
                        routerLinkActive="is-active"
                        [rule]="{lending__view_advances: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        Advances
                    </a>
                    <a
                        *ngIf="lending.expanded"
                        mat-button
                        class="mat-button dropdown-link-item"
                        routerLink="/lending/garnishments"
                        routerLinkActive="is-active"
                        [rule]="{lending__view_garnishments: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        Garnishments
                    </a>
                    <a
                        *ngIf="lending.expanded"
                        mat-button
                        class="mat-button dropdown-link-item"
                        [rule]="{lending__view_loans: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                        routerLink="/lending/my-loans"
                        routerLinkActive="is-active"
                    >
                        Loans
                    </a>
                </mat-nav-list>
            </mat-expansion-panel>

            <!------- Accounting ------->
            <mat-expansion-panel
                #accounting
                hideToggle="true"
                routerLinkActive="router-link-parent"
                class="mat-expansion-panel"
                [expanded]="needOpenSubmenuItems.accounting"
                (afterExpand)="router.navigate(['/banking'])"
            >
                <mat-expansion-panel-header
                    [ngClass]="{
                        'is-active':
                            router.url.includes('/banking') ||
                            router.url.includes('/company/finance/journals') ||
                            router.url.includes('/company/finance/recurringjournals') ||
                            router.url.includes('/company/finance/journaltemplates')
                    }"
                    class="router-link-child expansion-panel-header"
                    (click)="onSidenavSubmenuToggle('accounting')"
                    [collapsedHeight]="submenuHeaderHeight"
                    [expandedHeight]="submenuHeaderHeight"
                >
                    <mat-panel-title class="no-wrap-title panel-title" *ngIf="sidenavOpened">
                        <mat-icon class="nav-icon material-icons-round">account_balance</mat-icon>
                        Accounting
                        <span class="sp-spacer"></span>
                        <mat-icon class="nav-icon-caret" *ngIf="!accounting.expanded && !sidenavTransition">
                            arrow_right
                        </mat-icon>
                        <mat-icon class="nav-icon-caret" *ngIf="accounting.expanded && !sidenavTransition">
                            arrow_drop_down
                        </mat-icon>
                    </mat-panel-title>
                    <mat-panel-title class="no-wrap-title" *ngIf="!sidenavOpened">
                        <mat-icon
                            matTooltip="Accounting"
                            matTooltipPosition="right"
                            [routerLink]="['/banking']"
                            class="nav-icon material-icons-round"
                            >account_balance
                        </mat-icon>
                        Accounting
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-nav-list
                    *ngIf="sidenavOpened"
                    class="nav-list mat-expansion-panel-list mat-expansion-panel-list-menu"
                >
                    <!------- Reconciliation (Banking) ------->
                    <a
                        *ngIf="accounting.expanded"
                        class="mat-button dropdown-link-item"
                        mat-button
                        [rule]="{banking__access: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                        [routerLink]="'/banking'"
                        routerLinkActive="is-active"
                    >
                        Reconciliation
                    </a>

                    <!------- Journals ------->
                    <a
                        *ngIf="accounting.expanded"
                        class="mat-button dropdown-link-item"
                        mat-button
                        [rule]="{company_settings__view_journals: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                        [routerLink]="'/company/finance/journals'"
                        routerLinkActive="is-active"
                        [ngClass]="{
                            'is-active':
                                router.url.includes('/company/finance/recurringjournals') ||
                                router.url.includes('/company/finance/journaltemplates')
                        }"
                    >
                        Journals
                    </a>
                </mat-nav-list>
            </mat-expansion-panel>

            <!------- Reporting ------->
            <mat-expansion-panel
                #reporting
                hideToggle="true"
                routerLinkActive="router-link-parent"
                class="mat-expansion-panel"
                [expanded]="needOpenSubmenuItems.reporting"
                (afterExpand)="router.navigate(['/insights'])"
            >
                <mat-expansion-panel-header
                    [ngClass]="{'is-active': router.url.includes('/reports') || router.url.includes('/insights')}"
                    class="router-link-child expansion-panel-header"
                    (click)="onSidenavSubmenuToggle('reporting')"
                    [collapsedHeight]="submenuHeaderHeight"
                    [expandedHeight]="submenuHeaderHeight"
                >
                    <mat-panel-title class="no-wrap-title panel-title" *ngIf="sidenavOpened">
                        <mat-icon class="nav-icon material-icons-round">library_books</mat-icon>
                        Reporting
                        <span class="sp-spacer"></span>
                        <mat-icon class="nav-icon-caret" *ngIf="!reporting.expanded && !sidenavTransition">
                            arrow_right
                        </mat-icon>
                        <mat-icon class="nav-icon-caret" *ngIf="reporting.expanded && !sidenavTransition">
                            arrow_drop_down
                        </mat-icon>
                    </mat-panel-title>
                    <mat-panel-title class="no-wrap-title" *ngIf="!sidenavOpened">
                        <mat-icon
                            matTooltip="Reporting"
                            matTooltipPosition="right"
                            [routerLink]="['/insights']"
                            class="nav-icon material-icons-round"
                            >library_books
                        </mat-icon>
                        Reporting
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-nav-list
                    *ngIf="sidenavOpened"
                    class="nav-list mat-expansion-panel-list mat-expansion-panel-list-menu"
                >
                    <!------- Reconciliation (Banking) ------->
                    <a
                        *ngIf="reporting.expanded"
                        class="mat-button dropdown-link-item"
                        mat-button
                        [anyRule]="[{dashboards__access: true}, {reports__view_dashboard: true}]"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                        [routerLink]="'/insights'"
                        routerLinkActive="is-active"
                    >
                        Dashboard
                    </a>

                    <!------- Reporting ------->
                    <app-auto-open-menu *ngIf="reporting.expanded">
                        <a
                            class="mat-button dropdown-link-item user-layout-button"
                            mat-button
                            trigger
                            [rule]="{reports__access: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'flex'}"
                            [denyStyle]="{display: 'none'}"
                            [ngClass]="{'is-active': router.url.includes('/reports')}"
                            (click)="router.navigate(['/reports'])"
                        >
                            Detailed Reports
                        </a>
                        <div content>
                            <a mat-menu-item [routerLink]="['/reports/categories/personal']">My Reports</a>
                            <a mat-menu-item [routerLink]="['/reports/recommended']">Recommended</a>
                            <a mat-menu-item [routerLink]="['/reports/categories/company']">Company</a>
                        </div>
                    </app-auto-open-menu>
                </mat-nav-list>
            </mat-expansion-panel>

            <!------- Settings ------->
            <mat-expansion-panel
                #settings
                hideToggle="true"
                routerLinkActive="router-link-parent"
                class="mat-expansion-panel"
                [expanded]="needOpenSubmenuItems.settings"
                (afterExpand)="router.navigate(['/company/settings'])"
            >
                <mat-expansion-panel-header
                    [ngClass]="{
                        'is-active':
                            router.url.includes('/company/settings') ||
                            router.url.includes('/company/import') ||
                            router.url.includes('/company/profiles') ||
                            router.url.includes('/franchise') ||
                            router.url.includes('/marketplace') ||
                            router.url.includes('/company/directory/roles')
                    }"
                    class="router-link-child expansion-panel-header"
                    (click)="onSidenavSubmenuToggle('settings')"
                    [collapsedHeight]="submenuHeaderHeight"
                    [expandedHeight]="submenuHeaderHeight"
                >
                    <mat-panel-title class="no-wrap-title panel-title" *ngIf="sidenavOpened">
                        <mat-icon class="nav-icon material-icons-round">settings</mat-icon>
                        Settings
                        <span class="sp-spacer"></span>
                        <mat-icon class="nav-icon-caret" *ngIf="!settings.expanded && !sidenavTransition">
                            arrow_right
                        </mat-icon>
                        <mat-icon class="nav-icon-caret" *ngIf="settings.expanded && !sidenavTransition">
                            arrow_drop_down
                        </mat-icon>
                    </mat-panel-title>
                    <mat-panel-title class="no-wrap-title" *ngIf="!sidenavOpened">
                        <mat-icon
                            matTooltip="Settings"
                            matTooltipPosition="right"
                            [routerLink]="['/company/settings']"
                            class="nav-icon material-icons-round"
                            >settings
                        </mat-icon>
                        Settings
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-nav-list
                    *ngIf="sidenavOpened"
                    class="nav-list mat-expansion-panel-list mat-expansion-panel-list-menu"
                >
                    <!------- Company ------->
                    <app-auto-open-menu *ngIf="settings.expanded">
                        <a
                            class="mat-button dropdown-link-item user-layout-button"
                            mat-button
                            trigger
                            [rule]="{company_settings__access: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'flex'}"
                            [denyStyle]="{display: 'none'}"
                            [ngClass]="{
                                'is-active':
                                    router.url.includes('/company/settings') ||
                                    router.url.includes('/company/profiles') ||
                                    router.url.includes('/franchise') ||
                                    router.url.includes('/company/directory/roles')
                            }"
                            (click)="companyNavigateByClick()"
                        >
                            Company
                        </a>
                        <div content>
                            <a
                                mat-menu-item
                                [routerLink]="['/company/settings']"
                                rbac
                                [rule]="{company_settings__manage_general_settings: true}"
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                            >
                                General Settings
                            </a>
                            <a
                                mat-menu-item
                                [routerLink]="['/company/profiles']"
                                rbac
                                [rule]="{company_settings__manage_compensation: true}"
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                            >
                                Commission Plans
                            </a>
                            <a
                                mat-menu-item
                                [routerLink]="['/company/settings/purchase-settings']"
                                rbac
                                [rule]="{company_settings__manage_purchase_settings: true}"
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                            >
                                Purchase Settings
                            </a>
                            <a
                                mat-menu-item
                                [routerLink]="['/company/settings/tags']"
                                rbac
                                [rule]="{company_settings__manage_tags: true}"
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                            >
                                Tags
                            </a>
                            <a
                                mat-menu-item
                                [routerLink]="['/company/settings/automation']"
                                rbac
                                [rule]="{company_settings__manage_tags: true}"
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                                *ngIf="this.autoTagsEnabledFlag"
                            >
                                Automation
                            </a>
                            <a
                                mat-menu-item
                                [routerLink]="['/franchise']"
                                rbac
                                [rule]="{company_settings__manage_franchise: true}"
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                                *ngIf="this.autoTagsEnabledFlag"
                            >
                                Franchise
                            </a>
                            <a
                                mat-menu-item
                                [routerLink]="['/company/directory/roles']"
                                rbac
                                [rule]="{company_settings__view_roles: true}"
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                            >
                                Roles
                            </a>
                        </div>
                    </app-auto-open-menu>

                    <!------- Finance ------->
                    <app-auto-open-menu *ngIf="settings.expanded">
                        <a
                            mat-button
                            rbac
                            trigger
                            [anyRule]="[
                                {company_settings__manage_compensation: true},
                                {company_settings__view_products: true},
                                {company_settings__view_journals: true},
                                {company_settings__view_ledger_accounts: true},
                                {company_settings__view_opening_balances: true}
                            ]"
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'flex'}"
                            [denyStyle]="{display: 'none'}"
                            class="mat-button dropdown-link-item user-layout-button"
                            [ngClass]="{'is-active': router.url.includes('/company/finance')}"
                            (click)="financeNewNavigateByClick()"
                        >
                            Finance
                        </a>
                        <div content>
                            <a
                                mat-menu-item
                                [routerLink]="['/company/finance/chartofaccounts']"
                                rbac
                                [rule]="{company_settings__view_ledger_accounts: true}"
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                                >Chart of Accounts</a
                            >
                            <a
                                mat-menu-item
                                [routerLink]="['/company/finance/products']"
                                rbac
                                [rule]="{company_settings__view_products: true}"
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                                >Product/Services</a
                            >
                            <a
                                mat-menu-item
                                [routerLink]="['/company/finance/openingbalances']"
                                rbac
                                [rule]="{company_settings__view_opening_balances: true}"
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'block'}"
                                [denyStyle]="{display: 'none'}"
                                >Opening Balance</a
                            >
                        </div>
                    </app-auto-open-menu>

                    <!------- System Data (Import) ------->
                    <a
                        *ngIf="settings.expanded"
                        rbac
                        [rule]="{company_settings__manage_import_data: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                        class="mat-button dropdown-link-item user-layout-button"
                        mat-button
                        [routerLink]="['/company', 'import']"
                        routerLinkActive="is-active"
                        [routerLinkActiveOptions]="{exact: true}"
                    >
                        System Data
                    </a>

                    <!------- Integration (Marketplace) ------->
                    <a
                        *ngIf="settings.expanded"
                        rbac
                        [rule]="{marketplace__access: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                        class="mat-button dropdown-link-item user-layout-button"
                        mat-button
                        [routerLink]="['/marketplace']"
                        routerLinkActive="is-active"
                        [ngClass]="{'is-active': isMarketplaceHighlightedInNavigation()}"
                        [routerLinkActiveOptions]="{exact: true}"
                    >
                        Integrations
                    </a>
                </mat-nav-list>
            </mat-expansion-panel>

            <!------- Directory ------->
            <mat-expansion-panel
                #directory
                hideToggle="true"
                routerLinkActive="router-link-parent"
                class="mat-expansion-panel"
                [expanded]="needOpenSubmenuItems.directory"
                (afterExpand)="router.navigate(['/contacts'])"
            >
                <mat-expansion-panel-header
                    [ngClass]="{
                        'is-active':
                            router.url.includes('/contacts') ||
                            router.url.includes('/company/directory/users') ||
                            router.url.includes('/company/directory/groups') ||
                            router.url.includes('/company/directory/divisions')
                    }"
                    class="router-link-child expansion-panel-header"
                    (click)="onSidenavSubmenuToggle('directory')"
                    [collapsedHeight]="submenuHeaderHeight"
                    [expandedHeight]="submenuHeaderHeight"
                >
                    <mat-panel-title class="no-wrap-title panel-title" *ngIf="sidenavOpened">
                        <mat-icon class="nav-icon material-icons-round">contacts</mat-icon>
                        Directory
                        <span class="sp-spacer"></span>
                        <mat-icon class="nav-icon-caret" *ngIf="!directory.expanded && !sidenavTransition">
                            arrow_right
                        </mat-icon>
                        <mat-icon class="nav-icon-caret" *ngIf="directory.expanded && !sidenavTransition">
                            arrow_drop_down
                        </mat-icon>
                    </mat-panel-title>
                    <mat-panel-title class="no-wrap-title" *ngIf="!sidenavOpened">
                        <mat-icon
                            matTooltip="Directory"
                            matTooltipPosition="right"
                            [routerLink]="['/contacts']"
                            class="nav-icon material-icons-round"
                            >contacts
                        </mat-icon>
                        Directory
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-nav-list
                    *ngIf="sidenavOpened"
                    class="nav-list mat-expansion-panel-list mat-expansion-panel-list-menu"
                >
                    <!------- Contacts ------->
                    <a
                        *ngIf="directory.expanded"
                        class="mat-button dropdown-link-item"
                        mat-button
                        (click)="clearContactSearch()"
                        [fragment]="randomFragment"
                        [anyRule]="[{contacts__access: true}, {directory__access: true}]"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                        [routerLink]="['/contacts']"
                        routerLinkActive="is-active"
                    >
                        Contacts
                    </a>

                    <!------- User Profiles (Profiles) ------->
                    <a
                        *ngIf="directory.expanded"
                        class="mat-button dropdown-link-item"
                        mat-button
                        [routerLink]="['/company/directory/users']"
                        routerLinkActive="is-active"
                        rbac
                        [rule]="{company_settings__view_users: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        User Profiles
                    </a>

                    <!------- Groups ------->
                    <a
                        *ngIf="directory.expanded"
                        class="mat-button dropdown-link-item"
                        mat-button
                        [routerLink]="['/company/directory/groups']"
                        routerLinkActive="is-active"
                        rbac
                        [rule]="{company_settings__view_groups: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        Groups
                    </a>

                    <!------- Offices (Divisions) ------->
                    <a
                        *ngIf="directory.expanded"
                        class="mat-button dropdown-link-item"
                        mat-button
                        [routerLink]="['/company/directory/divisions']"
                        routerLinkActive="is-active"
                        rbac
                        [rule]="{company_settings__view_divisions: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        Offices
                    </a>
                </mat-nav-list>
            </mat-expansion-panel>

            <mat-divider class="side-menu-divider"></mat-divider>

            <!------- Quick Access to Integrations ------->
            <div *ngFor="let activatedAddonInstance of activatedAndFeaturedAddonsInstances" class="integrations">
                <a
                    mat-button
                    rbac
                    [rule]="getAddonAccessRule(activatedAddonInstance)"
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'flex'}"
                    [denyStyle]="{display: 'none'}"
                    class="mat-button user-layout-button integrations"
                    [ngClass]="{
                        'is-active': router.url.includes(
                            activatedAddonInstance.addon?.settings?.urls?.redirect_settings || ''
                        )
                    }"
                    (click)="addonCompanySettings(activatedAddonInstance)"
                >
                    <img
                        *ngIf="activatedAddonInstance.addon.slug !== 'zipi_financial_integration'"
                        style="padding-right: 9px; padding-left: 15px; margin-top: -3px; height: 18px"
                        [src]="activatedAddonInstance.addon?.preset?.icon"
                    />
                    <mat-icon
                        *ngIf="activatedAddonInstance.addon.slug === 'zipi_financial_integration'"
                        class="nav-list-icon"
                        >currency_exchange</mat-icon
                    >
                    <span *ngIf="sidenavOpened">{{ activatedAddonInstance.title }}</span>
                </a>
            </div>

            <mat-divider class="side-menu-divider"></mat-divider>
        </mat-accordion>
    </mat-sidenav>

    <!--  Accounts Sidenav  -->
    <mat-sidenav
        *ngIf="accountsSidenavOpened"
        [opened]="accountsSidenavOpened"
        (openedChange)="accountsSidenavOpenChanged($event)"
        class="account-sidenav"
        mode="over"
        position="end"
    >
        <app-account-form (onSave)="closeAccountsSidenav()" (onCancel)="closeAccountsSidenav()"> </app-account-form>
    </mat-sidenav>

    <!--  User Notifications Sidenav  -->
    <mat-sidenav
        (openedChange)="notificationsSidenavOpenChanged($event)"
        [opened]="notificationsSidenavOpened"
        mode="over"
        position="end"
    >
        <app-shipp-notifications
            [filterNotifications]="userNotificationService.notificationsCategoryFilter"
            [unread_notifications]="userNotificationService.unreadNotifications"
            (dismiss)="userNotificationService.doDismissNotification($event)"
        >
        </app-shipp-notifications>
    </mat-sidenav>

    <!--<app-notifications></app-notifications>-->
    <div class="zp-content" [class.content__expanded]="!sidenav.opened">
        <router-outlet></router-outlet>
    </div>
</mat-sidenav-container>
