<div class="sidebar-page h-100" *ngIf="journal">
    <div class="sidebar-wrapper h-100">
        <app-journal-create-bar
            #journalCreate
            [isOpened]="isSidebarOpened"
            (delete)="router.navigate(['/company/finance/journals'])"
            (updateListEmitter)="initPreview()"
            class="sidebar-container"
        >
            <section class="sidebar-body">
                <header class="d-flex align-items-center mt-2 mb-3">
                    <button mat-raised-button type="button" class="" [routerLink]="['/company/finance/journals']">
                        Back
                    </button>

                    <i class="sp-spacer"></i>

                    <div class="w-100 text-right py-2">
                        <button
                            mat-raised-button
                            type="button"
                            [rule]="{company_settings__manage_journals: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            class="ml-2"
                            *ngIf="journal?.status === 'published'"
                            (click)="reverseJournal()"
                        >
                            Create Reverse Journal
                        </button>
                        <button
                            mat-raised-button
                            type="button"
                            class="ml-2"
                            [rule]="{company_settings__manage_journals: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            *ngIf="journal.status === 'draft'"
                            [disabled]="!journal.line_item_total || journal.line_item_total === 0"
                            (click)="publishJournal()"
                        >
                            Publish
                        </button>
                        <button
                            mat-raised-button
                            type="button"
                            class="ml-2"
                            [rule]="{company_settings__manage_journals: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            (click)="editJournal()"
                        >
                            Edit Journal
                        </button>
                        <button mat-raised-button type="button" class="ml-2" (click)="downloadJournalPdf()">
                            Download PDF
                        </button>
                    </div>
                </header>

                <mat-card class="p-5" *ngIf="journal">
                    <header class="w-100 d-flex">
                        <div class="w-50"></div>
                        <div class="w-50">
                            <div class="text-right">
                                <p class="zp-invoice_title mb-0">Journal</p>
                                <p class="zp-invoice-num_value">#{{ journal?.journal_number }}</p>

                                <p class="mb-0">Total amount</p>
                                <p class="zp-reference_due_value">{{ journal?.line_item_total | currency }}</p>
                            </div>

                            <div class="text-right">
                                <p class="mb-2">
                                    <span>Status:</span>
                                    <span class="zp-w-150">{{ journal?.status }}</span>
                                </p>
                                <p class="mb-2">
                                    <span>Reference #:</span>
                                    <span class="zp-w-150">{{ journal?.reference_number }}</span>
                                </p>
                                <p class="mb-2">
                                    <span>Journal Date:</span>
                                    <span class="zp-w-150">{{
                                        journal?.date | dateFromNumber | date: 'longDate'
                                    }}</span>
                                </p>
                                <p class="mb-2">
                                    <span>Notes:</span>
                                    <span class="zp-w-150">{{ journal?.notes }}</span>
                                </p>
                                <p class="mb-2">
                                    <span>Journal type:</span>
                                    <span class="zp-w-150">{{ journal?.type }}</span>
                                </p>
                            </div>
                        </div>
                    </header>

                    <table class="table">
                        <thead class="bg-dark text-white">
                            <tr>
                                <td scope="col">#</td>
                                <td scope="col">Account</td>
                                <td scope="col">Description</td>
                                <td scope="col">Type</td>
                                <td scope="col" class="text-right zp-total-col">Debit</td>
                                <td scope="col" class="text-right zp-total-col">Credit</td>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let item of journal?.line_items; index as idx">
                                <td>{{ idx + 1 }}</td>
                                <td>
                                    {{
                                        journal.type === 'cash'
                                            ? item?.cash_ledger_account?.name
                                            : item?.ledger_account?.name
                                    }}
                                </td>
                                <td>{{ item?.description }}</td>
                                <td>{{ item?.debit_or_credit }}</td>
                                <td class="text-right amount-col">
                                    <span *ngIf="item?.debit_or_credit === 'debit'">{{
                                        item.amount | currency: 'USD'
                                    }}</span>
                                </td>
                                <td class="text-right amount-col">
                                    <span *ngIf="item?.debit_or_credit === 'credit'">{{
                                        item.amount | currency: 'USD'
                                    }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="w-100 total-row">
                        <div class="total-col">Total:</div>
                        <div class="total-col">{{ sumOfDebit | currency }}</div>
                        <div class="total-col">{{ sumOfCredit | currency }}</div>
                    </div>
                </mat-card>
            </section>
        </app-journal-create-bar>
    </div>
</div>
