<div [ngClass]="notificationClasses">
    <div class="message" *ngFor="let notification of lineNotifications">
        <div
            class="alert-dismissible"
            [ngClass]="notificationClasses['popup-notification-container'] ? 'alert-popup' : 'alert'"
            [class.alert-success]="notification.getType() === 'info'"
            [class.alert-danger]="notification.getType() === 'error'"
            [class.alert-warning]="notification.getType() === 'warning'"
            [@appear]="notification.state"
            *ngIf="!notification.isViewed()"
        >
            <div style="padding: 8px">
                <span>{{ notification.getMessage() }}</span>
                <ng-container *ngIf="notification.getObjectLink()">
                    <span
                        class="ml-2 notification-custom-link"
                        (click)="router.navigate([notification.getObjectLink().link])"
                    >
                        {{ notification.getObjectLink().text }}
                    </span>
                </ng-container>
            </div>

            <button mat-icon-button *ngIf="notification.isQuitable()" class="close-icon" (click)="notification.hide()">
                <mat-icon aria-label="Close">clear</mat-icon>
            </button>
        </div>
    </div>
</div>

<!--<div class="container-fluid reaction-notification-container" *ngIf="reactionNotifications.length">-->
<!--<div class="row" *ngFor="let notification of reactionNotifications">-->
<!--<app-reaction-notification-card [notification]="notification"></app-reaction-notification-card>-->
<!--</div>-->
<!--</div>-->

<ng-container *ngFor="let notification of reloadNotifications">
    <app-reload-notification [notification]="notification"></app-reload-notification>
</ng-container>
