<div class="transfer-wrapper" *ngIf="mode === 'edit'">
    <div
        class="transfer-container"
        style="position: relative"
        *ngFor="let additional of transfersFA.controls; index as ai"
    >
        <div
            class="transfer-collection"
            *ngIf="
                (additional.controls.is_deleted_by_user &&
                    !additional.controls.is_deleted_by_user.value &&
                    additional.controls.type &&
                    additional.controls.type.value !== FINANCIAL_TRANSFER.type_SET.company_split_individual) ||
                (additional.controls.type &&
                    additional.controls.type.value === FINANCIAL_TRANSFER.type_SET.company_split_individual)
            "
        >
            <div
                class="transfer-indicator"
                *ngIf="additional.controls.is_skipped && additional.controls.is_skipped.value"
            >
                <mat-icon
                    disabled="true"
                    [matTooltipClass]="'transfer-skipped-tooltip'"
                    [matTooltip]="
                        'Skipped&#13;This line item was not applied to the deal because the required compensation attribute (ie. mentor, transaction coordinator or similar custom field) was left blank.'
                    "
                    >info_outline
                </mat-icon>
            </div>
            <div
                *ngIf="
                    additional.controls.added_automatic &&
                    additional.controls.added_automatic.value &&
                    additional.controls.is_enforced &&
                    additional.controls.is_enforced.value &&
                    additional.controls.unique_hash &&
                    additional.controls.unique_hash.value !== 'NO-HASH'
                "
                (click)="onClick()"
                [ngClass]="
                    additional.controls.is_skipped && additional.controls.is_skipped.value
                        ? 'disabled-transfer-overlay-left'
                        : ''
                "
                class="disabled-transfer-overlay"
            ></div>
            <div
                *ngIf="
                    additional.controls.is_modified_by_user &&
                    !additional.controls.is_modified_by_user.value &&
                    ((additional.controls.added_automatic &&
                        additional.controls.added_automatic.value &&
                        additional.controls.is_enforced &&
                        !additional.controls.is_enforced.value) ||
                        (additional.controls.unique_hash &&
                            additional.controls.unique_hash.value === 'NO-HASH' &&
                            ((additional.controls.type &&
                                additional.controls.type.value === FINANCIAL_TRANSFER.type_SET.agent_split) ||
                                (additional.controls.type &&
                                    additional.controls.type.value ===
                                        FINANCIAL_TRANSFER.type_SET.company_split_individual))))
                "
                (click)="onNotEnforcedOverlayClick(additional)"
                class="disabled-transfer-overlay"
            ></div>

            <app-edit-transfer
                [receiverLabel]="receiverLabel"
                [senderLabel]="senderLabel"
                [isExpense]="isExpense"
                [origin]="origin"
                [hideSender]="hideSender"
                [isSalesEntityAgentCardContext]="isSalesEntityAgentCardContext"
                [hideReceiver]="hideReceiver"
                [allowPercents]="allowPercents && !showOnlyAmount"
                [hideProduct]="hideProduct"
                [hideLabel]="hideLabel"
                [hideValueType]="hideValueType"
                style="width: 100%"
                [disabled]="disabled || transfersFA.disabled || additional.disabled"
                [enableDisbursementInstructionsToggle]="enableDisbursementInstructionsToggle"
                [hidePayAtEscrowFlag]="hidePayAtEscrowFlag"
                [hideDisbursementInstructionsFlag]="hideDisbursementInstructionsFlag"
                [transferFG]="additional"
                [hideAmount]="hideAmount"
            ></app-edit-transfer>

            <ng-container
                *ngIf="
                    !hideRemoveButton &&
                    (isOverride ||
                        (additional.controls.added_automatic && !additional.controls.added_automatic.value) ||
                        (additional.controls.is_enforced &&
                            !additional.controls.is_enforced.value &&
                            additional.controls.type &&
                            additional.controls.type.value !== FINANCIAL_TRANSFER.type_SET.company_split_individual) ||
                        (additional.controls.unique_hash &&
                            additional.controls.unique_hash.value === 'NO-HASH' &&
                            additional.controls.type &&
                            additional.controls.type.value === FINANCIAL_TRANSFER.type_SET.agent_split))
                "
            >
                <button
                    [disabled]="isDisabledDelete(additional, disabled) || transfersFA.disabled"
                    mat-icon-button
                    color="warn"
                    (click)="removeAdditional(ai)"
                >
                    <mat-icon>clear</mat-icon>
                </button>
            </ng-container>
        </div>
    </div>

    <ng-container *ngIf="showAddButton && (limit === 0 || (limit > 0 && transfersFA.controls.length < limit))">
        <ng-content class="flex-row full-width"></ng-content>

        <div *ngIf="buttonLabel" style="justify-content: space-between; margin-top: 8px; width: 100%">
            <button
                [disabled]="disabled"
                mat-stroked-button
                class="full-width w-100 add-transfer"
                (click)="addAdditional()"
            >
                <mat-icon>add</mat-icon>
                {{ buttonLabel }}
            </button>
        </div>
    </ng-container>
</div>

<div *ngIf="mode === 'view'">
    <div class="view-transfer-container" *ngFor="let additional of transfersFA.controls; index as ai">
        <div *ngIf="additional.controls.is_deleted_by_user && !additional.controls.is_deleted_by_user.value">
            <app-view-transfer
                [receiverLabel]="receiverLabel"
                [senderLabel]="senderLabel"
                [isExpense]="isExpense"
                [origin]="origin"
                [hideSender]="hideSender"
                [hideReceiver]="hideReceiver"
                [allowPercents]="allowPercents && !showOnlyAmount"
                [hideProduct]="hideProduct"
                [hideLabel]="hideLabel"
                [hideValueType]="hideValueType"
                style="width: 100%"
                [disabled]="disabled"
                [enableDisbursementInstructionsToggle]="enableDisbursementInstructionsToggle"
                [hidePayAtEscrowFlag]="hidePayAtEscrowFlag"
                [hideDisbursementInstructionsFlag]="hideDisbursementInstructionsFlag"
                [transferFG]="additional"
                [hideAmount]="hideAmount"
            ></app-view-transfer>
        </div>
    </div>
</div>
