import {Component, OnInit, OnDestroy, ViewChild, Input} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {MatSidenav} from '@angular/material/sidenav';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {Profile} from '../../../models/profile';
import {CurrentProfileSource} from '../../../services/sources/current-profile.source';
import {PermissionsSource} from '../../../services/sources/permissions.source';

@Component({
    selector: 'app-goals-create-bar',
    templateUrl: 'goals-create-bar.component.html',
    styleUrls: ['goals-bar.component.scss']
})
export class GoalsCreateBarComponent implements OnInit, OnDestroy {
    @ViewChild('sidenavGoals') sidenavGoals: MatSidenav | undefined = undefined;
    @Input() isOpened: boolean = false;

    private unsubscribe: Subject<void> = new Subject();
    protected currentProfile: Profile = new Profile();

    haveAccessManageGroups: boolean = false;

    constructor(
        private router: Router,
        protected currentProfileSource: CurrentProfileSource,
        protected permissionsSource: PermissionsSource
    ) {
        this.currentProfileSource.changeProfileEvent
            .pipe(filter((profile) => profile.company !== null))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((profile) => (this.currentProfile = profile));
    }

    ngOnInit() {
        this.initSubscribe();
    }

    initSubscribe() {
        if (!!this.sidenavGoals) {
            this.sidenavGoals.opened = this.isOpened;
        }

        this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (this.sidenavGoals && this.sidenavGoals.opened) {
                    this.close();
                }
            }
        });

        this.permissionsSource.permissions
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((permissions: {[key: string]: any}) => {
                this.haveAccessManageGroups = permissions.manage_groups;
            });
    }

    close() {
        if (this.sidenavGoals) {
            this.sidenavGoals.close();
        }
    }

    openManageSidebar() {
        if (this.sidenavGoals) {
            this.sidenavGoals.open();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
