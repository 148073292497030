<div class="my-2">
    <div class="d-flex align-items-end">
        <h3>Requests</h3>
        <button
            class="_track_deal_create_deposit_request"
            mat-icon-button
            (click)="addDepositRequest()"
            [disabled]="disabled"
        >
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <div>
        <table mat-table [dataSource]="dataSourceRequest" class="mat-elevation-z2 w-100 deposit-table">
            <ng-container matColumnDef="request_date">
                <th mat-header-cell *matHeaderCellDef style="width: 150px">Request Date</th>
                <td mat-cell *matCellDef="let element" style="width: 150px">
                    {{ element.request_date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.request_receiver_email }}
                </td>
            </ng-container>

            <ng-container matColumnDef="product">
                <th mat-header-cell *matHeaderCellDef>Product</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.product">{{ element.product.name }}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef>Amount</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.request_amount | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="balance">
                <th mat-header-cell *matHeaderCellDef>Balance</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.request_balance | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="due_date">
                <th mat-header-cell *matHeaderCellDef>Due Date</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.due_date">{{ element.due_date | dateFromNumber | date: 'mediumDate' }}</span>
                    <span *ngIf="!element.due_date">No Terms</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.status | fromSnakeCase }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="text-align: end">Action</th>
                <td mat-cell *matCellDef="let element" style="text-align: end">
                    <mat-menu #appMenu="matMenu">
                        <button mat-menu-item (click)="resendRequest(element)">
                            Send Request
                            <mat-icon>mail</mat-icon>
                        </button>
                        <button mat-menu-item [disabled]="disabled" (click)="deleteRequest(element)">
                            Delete Request
                        </button>
                    </mat-menu>

                    <button mat-icon-button [matMenuTriggerFor]="appMenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedRequestColumns"></tr>
            <tr mat-row class="journals-row" *matRowDef="let row; columns: displayedRequestColumns"></tr>
        </table>
        <div class="d-flex justify-content-end font-weight-bold mt-2">
            Total: {{ (dealDepositAmounts?.total_requested | currency) || (0 | currency) }}
        </div>
    </div>
</div>
