<div class="w-100">
    <mat-stepper
        orientation="horizontal"
        #stepper
        [@.disabled]="true"
        class="gateway-setup-stepper"
        labelPosition="bottom"
        [selectedIndex]="currentStep"
    >
        <mat-step [hasError]="muhneeBusinessDetailsForm.invalid">
            <ng-template matStepLabel>Business Details</ng-template>
            <ng-template matStepperIcon="edit">
                <mat-icon>check</mat-icon>
            </ng-template>

            <div *ngIf="muhneeBusinessDetailsForm" style="min-height: 517px">
                <h2>Business Details</h2>
                <mat-divider></mat-divider>
                <div [formGroup]="muhneeBusinessDetailsForm" class="mt-3">
                    <!--                    first line-->
                    <div class="d-flex justify-content-between">
                        <mat-form-field class="w-33 px-2">
                            <mat-select formControlName="business_type" (valueChange)="typeChange($event)">
                                <ng-container *ngFor="let type of businessTypesMap">
                                    <mat-option *ngIf="type.slug !== 'individual'" [value]="type.slug">
                                        {{ type.title }}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-label>Type of Entity</mat-label>
                        </mat-form-field>

                        <mat-form-field class="w-33 px-2">
                            <input matInput required trimWhitespace type="text" formControlName="business_name" />
                            <mat-label>Legal Business Name</mat-label>
                        </mat-form-field>

                        <mat-form-field class="w-33 px-2">
                            <input matInput required trimWhitespace type="text" formControlName="business_email" />
                            <mat-label>Business Email</mat-label>
                        </mat-form-field>
                    </div>

                    <!--second line-->
                    <div
                        *ngIf="
                            muhneeBusinessDetailsForm.controls.business_type.value &&
                            muhneeBusinessDetailsForm.controls.business_type.value !== 'soleProprietor'
                        "
                        class="d-flex"
                    >
                        <div class="d-flex w-50 px-2">
                            <app-date-picker
                                class="w-100"
                                [placeholder]="'Formation Date'"
                                [isRequired]="true"
                                [dateControl]="muhneeBusinessDetailsForm.controls.formation_date"
                                [startDate]="startDate"
                                [startView]="'year'"
                            ></app-date-picker>
                        </div>

                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="ein" />
                            <mat-label>Employer Identification Number (EIN)</mat-label>
                            <mat-error *ngIf="muhneeBusinessDetailsForm.controls.ein.invalid"
                                >EIN must be 9 digits.</mat-error
                            >
                        </mat-form-field>
                    </div>
                    <div
                        *ngIf="
                            muhneeBusinessDetailsForm.controls.business_type.value &&
                            muhneeBusinessDetailsForm.controls.business_type.value === 'soleProprietor'
                        "
                        class="d-flex"
                    >
                        <div class="d-flex w-50 px-2">
                            <app-date-picker
                                class="w-100"
                                [placeholder]="'Date Started as Agent'"
                                [isRequired]="true"
                                [dateControl]="muhneeBusinessDetailsForm.controls.formation_date"
                                [startDate]="startDate"
                                [startView]="'year'"
                            ></app-date-picker>
                        </div>

                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="ssn" />
                            <mat-label>Social Security Number (SSN)</mat-label>
                            <mat-error *ngIf="muhneeBusinessDetailsForm.controls.ssn.invalid"
                                >SSN must be 9 digits.</mat-error
                            >
                        </mat-form-field>
                    </div>

                    <!--third line-->
                    <div *ngIf="muhneeBusinessDetailsForm.controls.business_type.value">
                        <div class="d-flex w-100 align-items-center">
                            <div class="w-33 px-2">
                                <div class="d-flex align-items-center">
                                    <div
                                        [ngClass]="{
                                            invalid:
                                                muhneeBusinessDetailsForm.controls.is_has_dba.touched &&
                                                muhneeBusinessDetailsForm.controls.is_has_dba.invalid
                                        }"
                                    >
                                        Have you filed a Doing Business As form? *
                                    </div>
                                    <mat-icon
                                        style="font-size: 18px; width: 18px; height: 18px"
                                        class="ml-2"
                                        matTooltip="I have registered an alternative name with the secretary of state for the purposes of doing business (commonly referred to as a DBA) and have already received confirmation that is was accepted and registered."
                                    >
                                        info
                                    </mat-icon>
                                </div>

                                <mat-radio-group
                                    class="w-50 text-right"
                                    color="primary"
                                    formControlName="is_has_dba"
                                    style="
                                        display: flex;
                                        flex-direction: column;
                                        margin: 5px 0;
                                        align-items: flex-start;
                                    "
                                    [ngClass]="{
                                        invalid:
                                            muhneeBusinessDetailsForm.controls.is_has_dba.touched &&
                                            muhneeBusinessDetailsForm.controls.is_has_dba.invalid
                                    }"
                                >
                                    <mat-radio-button value="yes">Yes</mat-radio-button>
                                    <mat-radio-button value="no">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="d-flex w-65 px-2">
                                <mat-form-field
                                    class="w-100"
                                    *ngIf="muhneeBusinessDetailsForm.controls.is_has_dba.value === 'yes'"
                                >
                                    <input matInput type="text" trimWhitespace formControlName="dba" />
                                    <mat-label>Doing Business As</mat-label>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <!--fourth line-->
                    <div *ngIf="muhneeBusinessDetailsForm.controls.business_type.value">
                        <div class="d-flex w-100 align-items-center">
                            <div class="w-33 px-2">
                                <div
                                    [ngClass]="{
                                        invalid:
                                            muhneeBusinessDetailsForm.controls.is_has_website.touched &&
                                            muhneeBusinessDetailsForm.controls.is_has_website.invalid
                                    }"
                                >
                                    Do you have a business website? *
                                </div>
                                <mat-radio-group
                                    class="w-50 text-right"
                                    color="primary"
                                    formControlName="is_has_website"
                                    style="
                                        display: flex;
                                        flex-direction: column;
                                        margin: 5px 0;
                                        align-items: flex-start;
                                    "
                                    [ngClass]="{
                                        invalid:
                                            muhneeBusinessDetailsForm.controls.is_has_website.touched &&
                                            muhneeBusinessDetailsForm.controls.is_has_website.invalid
                                    }"
                                >
                                    <mat-radio-button value="yes">Yes</mat-radio-button>
                                    <mat-radio-button value="no">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="d-flex w-65 px-2">
                                <mat-form-field
                                    class="w-100"
                                    *ngIf="muhneeBusinessDetailsForm.controls.is_has_website.value === 'yes'"
                                >
                                    <input matInput trimWhitespace type="text" formControlName="web" required />
                                    <mat-label
                                        >Business Website (If you do not have your own, please enter your brokerage's
                                        website)</mat-label
                                    >
                                </mat-form-field>
                                <mat-form-field
                                    class="w-100"
                                    *ngIf="muhneeBusinessDetailsForm.controls.is_has_website.value === 'no'"
                                >
                                    <input matInput trimWhitespace type="text" formControlName="description" required />
                                    <mat-label>Description of Business</mat-label>
                                    <mat-error *ngIf="muhneeBusinessDetailsForm.controls.description.invalid"
                                        >Must be more than 10 and less than 100 characters</mat-error
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="muhneeBusinessDetailsForm.controls.business_type.value">
                        <div>
                            <mat-form-field class="w-100 px-2">
                                <input matInput required trimWhitespace type="text" formControlName="address_line_1" />
                                <mat-label>Address</mat-label>
                            </mat-form-field>
                        </div>

                        <div class="d-flex justify-content-between">
                            <mat-form-field class="w-33 px-2">
                                <input matInput required trimWhitespace type="text" formControlName="city" />
                                <mat-label>City</mat-label>
                            </mat-form-field>
                            <mat-form-field class="w-33 px-2">
                                <mat-select required formControlName="state">
                                    <mat-option *ngFor="let state of stateProvinceRegionList" [value]="state.slug">{{
                                        state.title
                                    }}</mat-option>
                                </mat-select>
                                <mat-label>State</mat-label>
                            </mat-form-field>

                            <mat-form-field class="w-33 px-2">
                                <input matInput required type="text" formControlName="postalCode" />
                                <mat-label>Zip</mat-label>
                                <mat-error *ngIf="muhneeBusinessDetailsForm.controls.postalCode.invalid"
                                    >Zip must be 5 digits.</mat-error
                                >
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-2 d-flex">
                <span class="sp-spacer"></span>

                <button mat-raised-button color="primary" (click)="validateAndChangeStep(0, stepper)">Next</button>
            </div>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Business Documents</ng-template>

            <ng-template matStepperIcon="edit">
                <mat-icon>check</mat-icon>
            </ng-template>

            <div style="min-height: 517px">
                <h2>Business Documents</h2>
                <div
                    class="mb-3"
                    *ngIf="
                        muhneeBusinessDetailsForm.controls.business_type.value &&
                        muhneeBusinessDetailsForm.controls.business_type.value === 'soleProprietor'
                    "
                >
                    Please provide Bank Statement Document and document to support proof of your business (i.e. a
                    utility or phone bill from the last two months, or a social security card), and any optional
                    documentation you can share. The total size of the documents should not exceed 5 MB.
                </div>
                <div
                    class="mb-3"
                    *ngIf="
                        muhneeBusinessDetailsForm.controls.business_type.value &&
                        muhneeBusinessDetailsForm.controls.business_type.value !== 'soleProprietor'
                    "
                >
                    Please provide your required formation document, Employer Identification Number confirmation letter
                    (CP575), and any optional documentation to support proof of your business. The total size of the
                    documents should not exceed 5 MB.
                </div>
                <mat-divider></mat-divider>

                <!--Documents for soleProprietor-->
                <div
                    *ngIf="
                        muhneeBusinessDetailsForm.controls.business_type.value &&
                        muhneeBusinessDetailsForm.controls.business_type.value === 'soleProprietor'
                    "
                    class="mt-3"
                >
                    <div class="d-flex justify-content-between">
                        <div class="w-33">
                            <div style="height: 50px; border: #ebf1f6 solid 1px" class="d-flex align-items-center pl-3">
                                <div>
                                    <span style="font-weight: bold">Bank Statement Document</span>
                                </div>
                            </div>
                            <input
                                #documentBankStatement
                                type="file"
                                (input)="handleFileInput(documentBankStatement.files, 'bankStatement')"
                                hidden
                            />
                            <div
                                style="min-height: 200px; border: #ebf1f6 solid 1px; background-color: #ebf1f6"
                                class="d-flex justify-content-center"
                                *ngIf="!muhneeNonBusinessDocumentsForm.controls.bankStatement.value"
                            >
                                <img src="/assets/images/file-upload-img.svg" alt="" />
                            </div>
                            <div
                                style="min-height: 200px; border: #ebf1f6 solid 1px; background-color: #ebf1f6"
                                *ngIf="muhneeNonBusinessDocumentsForm.controls.bankStatement.value"
                            >
                                <div
                                    class="d-flex align-items-center m-2"
                                    style="
                                        border: #dee5ed solid 1px;
                                        background-color: #ffffff;
                                        border-radius: 4px;
                                        padding: 24px;
                                    "
                                >
                                    <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{
                                        muhneeNonBusinessDocumentsForm.controls.bankStatement.value.name
                                    }}</span>
                                    <span class="sp-spacer"></span>
                                    <mat-icon (click)="removeFile('bankStatement', null)" class="ml-1">close</mat-icon>
                                </div>
                            </div>
                            <div
                                style="height: 50px; border: #ebf1f6 solid 1px"
                                class="d-flex justify-content-center align-items-center"
                            >
                                <button
                                    style="width: 100%; height: 100%"
                                    [ngStyle]="
                                        muhneeNonBusinessDocumentsForm.controls.bankStatement.value
                                            ? {color: 'gray'}
                                            : {color: '#0059DA'}
                                    "
                                    mat-flat-button
                                    (click)="documentBankStatement.click()"
                                    [disabled]="muhneeNonBusinessDocumentsForm.controls.bankStatement.value"
                                >
                                    <mat-icon>add</mat-icon>
                                    Upload
                                </button>
                            </div>
                        </div>
                        <div class="w-33">
                            <div style="height: 50px; border: #ebf1f6 solid 1px" class="d-flex align-items-center pl-3">
                                <div>
                                    <span style="font-weight: bold">Proof of Business</span>
                                </div>
                            </div>
                            <input
                                #documentOtherDoc
                                type="file"
                                (input)="handleFileInput(documentOtherDoc.files, 'otherDoc')"
                                hidden
                            />
                            <div
                                style="min-height: 200px; border: #ebf1f6 solid 1px; background-color: #ebf1f6"
                                class="d-flex justify-content-center"
                                *ngIf="!muhneeNonBusinessDocumentsForm.controls.otherDoc.value"
                            >
                                <img src="/assets/images/file-upload-img.svg" alt="" />
                            </div>
                            <div
                                style="min-height: 200px; border: #ebf1f6 solid 1px; background-color: #ebf1f6"
                                *ngIf="muhneeNonBusinessDocumentsForm.controls.otherDoc.value"
                            >
                                <div
                                    class="d-flex align-items-center m-2"
                                    style="
                                        border: #dee5ed solid 1px;
                                        background-color: #ffffff;
                                        border-radius: 4px;
                                        padding: 24px;
                                    "
                                >
                                    <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{
                                        muhneeNonBusinessDocumentsForm.controls.otherDoc.value.name
                                    }}</span>
                                    <span class="sp-spacer"></span>
                                    <mat-icon (click)="removeFile('cp575', null)" class="ml-1">close</mat-icon>
                                </div>
                            </div>
                            <div
                                style="height: 50px; border: #ebf1f6 solid 1px"
                                class="d-flex justify-content-center align-items-center"
                            >
                                <button
                                    style="width: 100%; height: 100%"
                                    [ngStyle]="
                                        muhneeNonBusinessDocumentsForm.controls.otherDoc.value
                                            ? {color: 'gray'}
                                            : {color: '#0059DA'}
                                    "
                                    mat-flat-button
                                    (click)="documentOtherDoc.click()"
                                    [disabled]="muhneeNonBusinessDocumentsForm.controls.otherDoc.value"
                                >
                                    <mat-icon>add</mat-icon>
                                    Upload
                                </button>
                            </div>
                        </div>
                        <div class="w-33">
                            <div style="height: 50px; border: #ebf1f6 solid 1px" class="d-flex pl-3 align-items-center">
                                <div><span style="font-weight: bold">Supporting Documents</span> (optional)</div>
                            </div>
                            <input
                                #documentSupporting
                                type="file"
                                (input)="handleFileInput(documentSupporting.files, 'supporting')"
                                hidden
                            />
                            <div
                                style="min-height: 200px; border: #ebf1f6 solid 1px; background-color: #ebf1f6"
                                class="d-flex justify-content-center"
                                *ngIf="muhneeSupportingDocumentsArray.controls.length === 0"
                            >
                                <img src="/assets/images/file-upload-img.svg" alt="" />
                            </div>
                            <div
                                style="min-height: 200px; border: #ebf1f6 solid 1px; background-color: #ebf1f6"
                                *ngIf="muhneeSupportingDocumentsArray.controls.length > 0"
                            >
                                <div
                                    *ngFor="let supportDoc of muhneeSupportingDocumentsArray.controls; let idx = index"
                                >
                                    <div
                                        *ngIf="supportDoc"
                                        class="d-flex align-items-center m-2"
                                        style="
                                            border: #dee5ed solid 1px;
                                            background-color: #ffffff;
                                            border-radius: 4px;
                                            padding: 24px;
                                        "
                                    >
                                        <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{
                                            supportDoc.value.name
                                        }}</span>
                                        <span class="sp-spacer"></span>
                                        <mat-icon (click)="removeFile('supporting', idx)" class="ml-1">close</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div
                                style="height: 50px; border: #ebf1f6 solid 1px"
                                class="d-flex justify-content-center align-items-center"
                            >
                                <button
                                    style="color: #0059da; width: 100%; height: 100%"
                                    mat-flat-button
                                    (click)="documentSupporting.click()"
                                >
                                    <mat-icon>add</mat-icon>
                                    Upload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Documents for NON soleProprietor-->
                <div
                    *ngIf="
                        muhneeBusinessDetailsForm.controls.business_type.value &&
                        muhneeBusinessDetailsForm.controls.business_type.value !== 'soleProprietor'
                    "
                    class="mt-3"
                >
                    <div class="d-flex justify-content-between">
                        <div class="w-33">
                            <div style="height: 50px; border: #ebf1f6 solid 1px" class="d-flex align-items-center pl-3">
                                <div>
                                    <span style="font-weight: bold">Formation Document</span>
                                </div>
                            </div>
                            <input
                                #documentFormation
                                type="file"
                                (input)="handleFileInput(documentFormation.files, 'formation')"
                                hidden
                            />
                            <div
                                style="min-height: 200px; border: #ebf1f6 solid 1px; background-color: #ebf1f6"
                                class="d-flex justify-content-center"
                                *ngIf="!muhneeBusinessDocumentsForm.controls.formation.value"
                            >
                                <img src="/assets/images/file-upload-img.svg" alt="" />
                            </div>
                            <div
                                style="min-height: 200px; border: #ebf1f6 solid 1px; background-color: #ebf1f6"
                                *ngIf="muhneeBusinessDocumentsForm.controls.formation.value"
                            >
                                <div
                                    class="d-flex align-items-center m-2"
                                    style="
                                        border: #dee5ed solid 1px;
                                        background-color: #ffffff;
                                        border-radius: 4px;
                                        padding: 24px;
                                    "
                                >
                                    <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{
                                        muhneeBusinessDocumentsForm.controls.formation.value.name
                                    }}</span>
                                    <span class="sp-spacer"></span>
                                    <mat-icon (click)="removeFile('formation', null)" class="ml-1">close</mat-icon>
                                </div>
                            </div>
                            <div
                                style="height: 50px; border: #ebf1f6 solid 1px"
                                class="d-flex justify-content-center align-items-center"
                            >
                                <button
                                    style="width: 100%; height: 100%"
                                    [ngStyle]="
                                        muhneeBusinessDocumentsForm.controls.formation.value
                                            ? {color: 'gray'}
                                            : {color: '#0059DA'}
                                    "
                                    mat-flat-button
                                    (click)="documentFormation.click()"
                                    [disabled]="muhneeBusinessDocumentsForm.controls.formation.value"
                                >
                                    <mat-icon>add</mat-icon>
                                    Upload
                                </button>
                            </div>
                        </div>
                        <div class="w-33">
                            <div style="height: 50px; border: #ebf1f6 solid 1px" class="d-flex align-items-center pl-3">
                                <div>
                                    <span style="font-weight: bold">CP575</span>
                                </div>
                            </div>
                            <input
                                #documentCp575
                                type="file"
                                (input)="handleFileInput(documentCp575.files, 'cp575')"
                                hidden
                            />
                            <div
                                style="min-height: 200px; border: #ebf1f6 solid 1px; background-color: #ebf1f6"
                                class="d-flex justify-content-center"
                                *ngIf="!muhneeBusinessDocumentsForm.controls.cp575.value"
                            >
                                <img src="/assets/images/file-upload-img.svg" alt="" />
                            </div>
                            <div
                                style="min-height: 200px; border: #ebf1f6 solid 1px; background-color: #ebf1f6"
                                *ngIf="muhneeBusinessDocumentsForm.controls.cp575.value"
                            >
                                <div
                                    class="d-flex align-items-center m-2"
                                    style="
                                        border: #dee5ed solid 1px;
                                        background-color: #ffffff;
                                        border-radius: 4px;
                                        padding: 24px;
                                    "
                                >
                                    <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{
                                        muhneeBusinessDocumentsForm.controls.cp575.value.name
                                    }}</span>
                                    <span class="sp-spacer"></span>
                                    <mat-icon (click)="removeFile('cp575', null)" class="ml-1">close</mat-icon>
                                </div>
                            </div>
                            <div
                                style="height: 50px; border: #ebf1f6 solid 1px"
                                class="d-flex justify-content-center align-items-center"
                            >
                                <button
                                    style="width: 100%; height: 100%"
                                    [ngStyle]="
                                        muhneeBusinessDocumentsForm.controls.cp575.value
                                            ? {color: 'gray'}
                                            : {color: '#0059DA'}
                                    "
                                    mat-flat-button
                                    (click)="documentCp575.click()"
                                    [disabled]="muhneeBusinessDocumentsForm.controls.cp575.value"
                                >
                                    <mat-icon>add</mat-icon>
                                    Upload
                                </button>
                            </div>
                        </div>
                        <div class="w-33">
                            <div style="height: 50px; border: #ebf1f6 solid 1px" class="d-flex pl-3 align-items-center">
                                <div><span style="font-weight: bold">Supporting Documents</span> (optional)</div>
                            </div>
                            <input
                                #documentSupporting
                                type="file"
                                (input)="handleFileInput(documentSupporting.files, 'supporting')"
                                hidden
                            />
                            <div
                                style="min-height: 200px; border: #ebf1f6 solid 1px; background-color: #ebf1f6"
                                class="d-flex justify-content-center"
                                *ngIf="muhneeSupportingDocumentsArray.controls.length === 0"
                            >
                                <img src="/assets/images/file-upload-img.svg" alt="" />
                            </div>
                            <div
                                style="min-height: 200px; border: #ebf1f6 solid 1px; background-color: #ebf1f6"
                                *ngIf="muhneeSupportingDocumentsArray.controls.length > 0"
                            >
                                <div
                                    *ngFor="let supportDoc of muhneeSupportingDocumentsArray.controls; let idx = index"
                                >
                                    <div
                                        *ngIf="supportDoc"
                                        class="d-flex align-items-center m-2"
                                        style="
                                            border: #dee5ed solid 1px;
                                            background-color: #ffffff;
                                            border-radius: 4px;
                                            padding: 24px;
                                        "
                                    >
                                        <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{
                                            supportDoc.value.name
                                        }}</span>
                                        <span class="sp-spacer"></span>
                                        <mat-icon (click)="removeFile('supporting', idx)" class="ml-1">close</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div
                                style="height: 50px; border: #ebf1f6 solid 1px"
                                class="d-flex justify-content-center align-items-center"
                            >
                                <button
                                    style="color: #0059da; width: 100%; height: 100%"
                                    mat-flat-button
                                    (click)="documentSupporting.click()"
                                >
                                    <mat-icon>add</mat-icon>
                                    Upload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-2 d-flex">
                <button mat-raised-button (click)="back(stepper)">Previous</button>
                <span class="sp-spacer"></span>
                <button mat-raised-button color="primary" (click)="validateAndChangeStep(1, stepper)">Next</button>
            </div>
        </mat-step>

        <mat-step
            [completed]="!muhneeControllerDetailsForm.invalid && !muhneeRepresentativesArray.invalid"
            [hasError]="
                (muhneeControllerDetailsForm.touched && muhneeControllerDetailsForm.invalid) ||
                (muhneeRepresentativesArray.touched && muhneeRepresentativesArray.invalid)
            "
        >
            <ng-template matStepLabel>{{
                muhneeBusinessDetailsForm.controls.business_type.value &&
                muhneeBusinessDetailsForm.controls.business_type.value !== 'soleProprietor'
                    ? 'Representatives'
                    : 'Personal Details'
            }}</ng-template>

            <ng-template matStepperIcon="edit">
                <mat-icon>check</mat-icon>
            </ng-template>

            <div
                style="min-height: 517px"
                *ngIf="
                    muhneeBusinessDetailsForm.controls.business_type.value &&
                    muhneeBusinessDetailsForm.controls.business_type.value !== 'soleProprietor'
                "
            >
                <h2>Representatives</h2>
                <!--                <div class="mb-3">Please provide below information about Controller person.</div>-->
                <div class="mb-3">
                    List the controller of this account below. List all owners holding at least 25% stake in the
                    company, controllers who are owners do not need to be listed twice.
                </div>
                <mat-divider></mat-divider>

                <div [formGroup]="muhneeControllerDetailsForm">
                    <h4 class="mt-2">Controller</h4>
                    <div class="d-flex justify-content-between">
                        <mat-form-field class="w-33 px-2">
                            <input matInput required trimWhitespace type="text" formControlName="first_name" />
                            <mat-label>First Name</mat-label>
                        </mat-form-field>

                        <mat-form-field class="w-33 px-2">
                            <input matInput required trimWhitespace type="text" formControlName="last_name" />
                            <mat-label>Last Name</mat-label>
                        </mat-form-field>

                        <mat-form-field class="w-33 px-2">
                            <input matInput required type="text" formControlName="ssn" />
                            <mat-label>Social Security Number (SSN)</mat-label>
                            <mat-error *ngIf="muhneeControllerDetailsForm.controls.ssn.invalid"
                                >SSN must be 9 digits.</mat-error
                            >
                        </mat-form-field>
                    </div>
                    <div class="d-flex">
                        <div class="d-flex w-50 px-2">
                            <app-date-picker
                                class="w-100"
                                [placeholder]="'Date Of Birth'"
                                [dateControl]="muhneeControllerDetailsForm.controls.date_of_birth"
                                [startDate]="startDate"
                                [startView]="'year'"
                            ></app-date-picker>
                        </div>

                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="phone_number" />
                            <mat-label>Phone number</mat-label>
                        </mat-form-field>
                    </div>
                    <div>
                        <div>
                            <mat-form-field class="w-100 px-2">
                                <input matInput required trimWhitespace type="text" formControlName="address_line_1" />
                                <mat-label>Address</mat-label>
                            </mat-form-field>
                        </div>

                        <div class="d-flex justify-content-between">
                            <mat-form-field class="w-33 px-2">
                                <input matInput required trimWhitespace type="text" formControlName="city" />
                                <mat-label>City</mat-label>
                            </mat-form-field>
                            <mat-form-field class="w-33 px-2">
                                <mat-select required formControlName="state">
                                    <mat-option *ngFor="let state of stateProvinceRegionList" [value]="state.slug">{{
                                        state.title
                                    }}</mat-option>
                                </mat-select>
                                <mat-label>State</mat-label>
                            </mat-form-field>

                            <mat-form-field class="w-33 px-2">
                                <input matInput required type="text" formControlName="postalCode" />
                                <mat-label>Zip</mat-label>
                                <mat-error *ngIf="muhneeControllerDetailsForm.controls.postalCode.invalid"
                                    >Zip must be 5 digits.</mat-error
                                >
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <mat-divider *ngIf="muhneeRepresentativesArray.length > 0"></mat-divider>
                <div>
                    <div *ngIf="muhneeRepresentativesArray.length > 0">
                        <h4 class="mt-3">Owners</h4>
                        <!--                    <div class="mb-3">Also list below all other persons who have 25% or more of ownership.</div>-->

                        <div *ngFor="let beneficial of muhneeRepresentativesArray.controls; let idx = index">
                            <div class="d-flex my-2">
                                <mat-card class="d-flex w-100 align-items-center" [formGroup]="beneficial">
                                    <mat-form-field class="w-33 px-2">
                                        <input
                                            matInput
                                            required
                                            trimWhitespace
                                            type="text"
                                            formControlName="first_name"
                                        />
                                        <mat-label>First Name</mat-label>
                                    </mat-form-field>

                                    <mat-form-field class="w-33 px-2">
                                        <input
                                            matInput
                                            required
                                            trimWhitespace
                                            type="text"
                                            formControlName="last_name"
                                        />
                                        <mat-label>Last Name</mat-label>
                                    </mat-form-field>

                                    <div class="d-flex w-33 px-2">
                                        <app-date-picker
                                            class="w-100"
                                            [placeholder]="'Date Of Birth'"
                                            [dateControl]="beneficial.controls.date_of_birth"
                                            [startDate]="startDate"
                                            [startView]="'year'"
                                        ></app-date-picker>
                                    </div>

                                    <button
                                        class=""
                                        mat-icon-button
                                        *ngIf="muhneeRepresentativesArray.controls.length > 0"
                                        (click)="removeBeneficial(idx)"
                                    >
                                        <mat-icon>remove_circle_outline</mat-icon>
                                    </button>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="mt-2 mb-2 d-flex justify-content-center">
                        <button mat-flat-button style="color: #0059da" (click)="addRepresentative()">
                            <mat-icon>add</mat-icon>
                            Add Owner
                        </button>
                    </div>
                    <mat-divider></mat-divider>
                </div>

                <div class="mt-4 mb-3">
                    <mat-checkbox
                        [ngClass]="{invalid: !certify && certifyValidated}"
                        [checked]="certify"
                        (change)="certify = !certify"
                    >
                        I certify that all people who own 25% or more of the company have been identified as
                        representatives.
                    </mat-checkbox>
                </div>
            </div>

            <div
                style="min-height: 517px"
                *ngIf="
                    muhneeBusinessDetailsForm.controls.business_type.value &&
                    muhneeBusinessDetailsForm.controls.business_type.value === 'soleProprietor'
                "
            >
                <h2>Personal Details</h2>
                <div class="mb-3">Please provide more information as the operator of this account.</div>
                <mat-divider></mat-divider>

                <div [formGroup]="muhneeControllerDetailsForm">
                    <div class="d-flex justify-content-between mt-2">
                        <mat-form-field class="w-50 px-2">
                            <input matInput required trimWhitespace type="text" formControlName="first_name" />
                            <mat-label>First Name</mat-label>
                        </mat-form-field>

                        <mat-form-field class="w-50 px-2">
                            <input matInput required trimWhitespace type="text" formControlName="last_name" />
                            <mat-label>Last Name</mat-label>
                        </mat-form-field>
                    </div>
                    <div class="d-flex">
                        <div class="d-flex w-50 px-2">
                            <app-date-picker
                                class="w-100"
                                [placeholder]="'Date Of Birth'"
                                [dateControl]="muhneeControllerDetailsForm.controls.date_of_birth"
                                [startDate]="startDate"
                                [startView]="'year'"
                            ></app-date-picker>
                        </div>

                        <mat-form-field class="w-50 px-2">
                            <input matInput required type="text" formControlName="phone_number" />
                            <mat-label>Phone number</mat-label>
                        </mat-form-field>
                    </div>
                    <h4>Home Address</h4>
                    <mat-checkbox (change)="copyAddress($event)"> Same as business address. </mat-checkbox>
                    <div>
                        <div>
                            <mat-form-field class="w-100 px-2">
                                <input matInput required trimWhitespace type="text" formControlName="address_line_1" />
                                <mat-label>Address</mat-label>
                            </mat-form-field>
                        </div>

                        <div class="d-flex justify-content-between">
                            <mat-form-field class="w-33 px-2">
                                <input matInput required trimWhitespace type="text" formControlName="city" />
                                <mat-label>City</mat-label>
                            </mat-form-field>
                            <mat-form-field class="w-33 px-2">
                                <mat-select required formControlName="state">
                                    <mat-option *ngFor="let state of stateProvinceRegionList" [value]="state.slug">{{
                                        state.title
                                    }}</mat-option>
                                </mat-select>
                                <mat-label>State</mat-label>
                            </mat-form-field>

                            <mat-form-field class="w-33 px-2">
                                <input matInput required type="text" formControlName="postalCode" />
                                <mat-label>Zip</mat-label>
                                <mat-error *ngIf="muhneeControllerDetailsForm.controls.postalCode.invalid"
                                    >Zip must be 5 digits.</mat-error
                                >
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-2 d-flex">
                <button mat-raised-button matStepperPrevious>Previous</button>

                <span class="sp-spacer"></span>
                <button
                    mat-raised-button
                    color="primary"
                    (click)="validateAndChangeStep(2, stepper)"
                    [disabled]="lockButton"
                >
                    Next
                </button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Verify ID</ng-template>

            <div class="mt-3" style="min-height: 577px">
                <h2 *ngIf="!verified && (!this.existingGatewayInfo || !this.existingGatewayInfo.is_persona_verified)">
                    Verify ID
                </h2>
                <div
                    class="mb-3"
                    *ngIf="!verified && (!this.existingGatewayInfo || !this.existingGatewayInfo.is_persona_verified)"
                >
                    <div
                        *ngIf="
                            muhneeBusinessDetailsForm.controls.business_type.value &&
                            muhneeBusinessDetailsForm.controls.business_type.value === 'soleProprietor'
                        "
                    >
                        Make sure you have a government ID on hand (Valid Driver's License, Birth Certificate,
                        State-issued Identification Card, Student Identification Card, Social Security Card, Military
                        Identification Card, Passport, or Passport Card) and a working camera on this device.
                    </div>
                    <div
                        *ngIf="
                            muhneeBusinessDetailsForm.controls.business_type.value &&
                            muhneeBusinessDetailsForm.controls.business_type.value !== 'soleProprietor'
                        "
                    >
                        Please verify the identity of the control person. Make sure they have a government ID on hand
                        (Valid Driver's License, Birth Certificate, State-issued Identification Card, Student
                        Identification Card, Social Security Card, Military Identification Card, Passport, or Passport
                        Card) and a working camera on this device.
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div
                    class="d-flex justify-content-center mt-3"
                    *ngIf="verified || (this.existingGatewayInfo && this.existingGatewayInfo.is_persona_verified)"
                >
                    <mat-icon class="mr-2">check_circle</mat-icon> Identity Verified
                </div>
                <div
                    class="d-flex justify-content-center mt-3"
                    *ngIf="!verified && (!this.existingGatewayInfo || !this.existingGatewayInfo.is_persona_verified)"
                >
                    <button
                        mat-raised-button
                        color="primary"
                        (click)="verifyId()"
                        [disabled]="
                            (!verifyIdLink && !existingGateway) ||
                            (this.existingGatewayInfo && this.existingGatewayInfo.is_persona_verified)
                        "
                    >
                        Get Started
                    </button>
                </div>
            </div>

            <div class="mt-2 d-flex">
                <span class="sp-spacer"></span>
                <button
                    mat-raised-button
                    matStepperNext
                    color="primary"
                    (click)="finish()"
                    [disabled]="
                        !verified || (this.existingGatewayInfo && !this.existingGatewayInfo.is_persona_verified)
                    "
                >
                    Next
                </button>
            </div>
        </mat-step>
    </mat-stepper>
</div>
