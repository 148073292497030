<div mat-dialog-title>
    <h3>Connect a Bank Account</h3>
</div>

<mat-dialog-content>
    <p>Your personal details are now being verified, you can expect the following:</p>
    <div>
        <div style="text-decoration: underline">Verified</div>

        <p>You will receive an email confirming your verification was successful titled 'Account Verification'.</p>
    </div>
    <p>-OR-</p>
    <div>
        <div style="text-decoration: underline">Next Steps</div>

        <p>
            The automated process will flag the application for security team review and may require additional
            information. In this case, you will receive an email titled 'Additional Information Needed' highlighting any
            additional steps required.
        </p>
    </div>
    <div>While that is processing, let's get your bank connected.</div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex w-100 my-3">
        <span class="sp-spacer"></span>
        <button mat-raised-button class="mr-2" (click)="dialogRef.close(false)" tabindex="-1">Skip</button>
        <button mat-raised-button color="primary" (click)="dialogRef.close(true)" tabindex="1">
            Connect my Bank Account
        </button>
    </div>
</mat-dialog-actions>
