import {filter, takeUntil} from 'rxjs/operators';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NotificationsServiceZipi} from './notifications.service';
import {Router, NavigationStart} from '@angular/router';

import {LineNotification} from './kinds/line.notification';
import {ReactionNotification} from './kinds/reaction.notification';
import {trigger, state, style, animate, transition} from '@angular/animations';
import {ReloadNotification} from './kinds/reload.notification';
import {Subject, Subscription} from 'rxjs';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.css'],
    animations: [
        trigger('appear', [
            state(
                'active',
                style({
                    opacity: 1
                })
            ),
            state(
                'inactive',
                style({
                    opacity: 0
                })
            ),
            transition('inactive => active', animate('300ms ease-in')),
            transition('active => inactive', animate('300ms ease-out'))
        ])
    ]
})
export class NotificationsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() public notification_mode: 'header' | 'popup' = 'header';

    protected notifySubscription: Subscription | null = null;
    protected routerSubscription: Subscription | null = null;
    public lineNotifications: LineNotification[] = [];
    public reactionNotifications: ReactionNotification[] = [];
    public reloadNotifications: ReloadNotification[] = [];

    constructor(
        private notificationsService: NotificationsServiceZipi,
        public router: Router
    ) {}
    notificationClasses = {
        'notifications-container': this.notificationsService.currentModeInline,
        'popup-notification-container': this.notificationsService.currentModePopup()
    };
    ngOnInit() {
        this.notifySubscription = this.notificationsService.notifications
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((n) => {
                if (
                    (this.notificationsService.currentModeInline() && this.notification_mode !== 'header') ||
                    (this.notificationsService.currentModePopup() && this.notification_mode !== 'popup')
                ) {
                    return;
                }

                if (n instanceof LineNotification) {
                    this.renderLine(n);
                }

                if (n instanceof ReactionNotification) {
                    this.renderReaction(n);
                }

                if (n instanceof ReloadNotification) {
                    this.renderReloadNotification(n);
                }

                n.render();
            });

        this.routerSubscription = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                takeUntil(this.unsubscribe)
            )
            .subscribe((e) => {
                this.lineNotifications.forEach((notification) => {
                    notification.hide();
                });
            });
    }

    ngOnDestroy() {
        this.notifySubscription!.unsubscribe();
        this.routerSubscription!.unsubscribe();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    protected renderLine(notification: LineNotification) {
        this.lineNotifications.splice(0, 1, notification);
        this.lineNotifications.forEach((lineNotification) => {
            lineNotification.state = 'active';
        });
        // observableTimer(100).pipe(
        //     take(1))
        //     .subscribe(() => {
        //         this.lineNotifications.forEach(lineNotification => { lineNotification.state = 'active'; });
        //     });
    }

    protected renderReaction(notification: ReactionNotification) {
        this.reactionNotifications.push(notification);
    }

    protected renderReloadNotification(notification: ReloadNotification) {
        this.reloadNotifications.push(notification);
    }
}
