<section class="container-fluid">
    <header class="my-2">
        <h1>Refund</h1>
    </header>
    <mat-card [formGroup]="formGroup" class="my-3">
        <h2>Payment Info</h2>

        <div class="row mr-0">
            <div class="col col-sm-4">
                <div class="d-flex align-items-baseline">
                    <app-company-compensation-combined-picker
                        style="width: 100%; margin-right: 8px"
                        [availableTypes]="['contact']"
                        [title]="'Customer'"
                        [only_compensation_groups]="false"
                        [singleSelected]="true"
                        [bold_style]="false"
                        [nodesFA]="moneySenderCtrlArr"
                        [initialContactIds]="savedContacts"
                        [disabled]="true"
                    >
                    </app-company-compensation-combined-picker>
                </div>
            </div>

            <!--            <mat-form-field class="col col-sm-4">-->
            <!--                <input matInput formControlName="reference" placeholder="Reference#" type="text" autocomplete="off">-->
            <!--            </mat-form-field>-->

            <mat-form-field class="col col-sm-4">
                <input
                    matInput
                    [value]="formGroup.controls.paid_date.value | dateFromNumber | date: 'mediumDate'"
                    placeholder="Refund Date"
                    type="text"
                    disabled
                />
            </mat-form-field>
            <mat-form-field class="col col-sm-4">
                <mat-label>Payment#</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="payment_number"
                    autocomplete="off"
                    matTooltipPosition="above"
                />
            </mat-form-field>

            <!--            <app-date-picker-->
            <!--                class="col col-sm-4"-->
            <!--                [placeholder]="'Credit Note Date'"-->
            <!--                [dateControl]="formGroup.controls.paid_date"-->
            <!--                [disabled]="true"-->
            <!--            ></app-date-picker>-->
        </div>
        <div class="row mr-0">
            <mat-form-field class="col col-sm-4">
                <mat-label>Received Amount</mat-label>
                <input
                    [maskito]="currencyMaskitoMask"
                    matInput
                    type="text"
                    formControlName="amount"
                    name="amount"
                    autocomplete="off"
                    matTooltipPosition="above"
                />
            </mat-form-field>

            <mat-form-field class="col col-sm-4" *ngIf="!basePaymentReceivedId">
                <mat-select formControlName="payment_mode">
                    <ng-container *ngFor="let mode of paymentModes">
                        <mat-option [value]="mode.slug" *ngIf="mode.is_selectable">
                            <span>{{ mode.title }}</span>
                        </mat-option>
                    </ng-container>
                </mat-select>
                <mat-placeholder>Payment Mode</mat-placeholder>
            </mat-form-field>

            <mat-form-field class="col col-sm-4" *ngIf="basePaymentReceivedId">
                <input
                    matInput
                    type="text"
                    autocomplete="off"
                    placeholder="Payment Mode"
                    disabled
                    [value]="showPaymentMode()"
                />
            </mat-form-field>

            <app-ledger-account-selector
                class="col-sm-4"
                [ledgerAccountControl]="formGroup.controls.pay_to__ledger_account_fk_id"
                [placeholder]="'Deposit From'"
                [disabled]="true"
                [disabledReselect]="true"
            >
            </app-ledger-account-selector>
        </div>

        <div *ngIf="formGroup.controls.money_sender__contact_fk_id.value" class="invoices-list mb-3">
            <div class="d-flex">
                <span class="text-muted w-33">Invoice Details</span>
                <span class="text-muted w-33">Available for Refund</span>
                <span class="text-muted w-33">Payment</span>
            </div>

            <div class="w-100 text-center p-5" *ngIf="invoicesArray.length === 0">
                There are no unpaid Invoices, that can be applied for this payment.
            </div>

            <ng-container *ngFor="let invoice of invoicesArray.controls; index as idx; first as first">
                <div class="invoice-item d-flex" [formGroup]="invoice">
                    <div class="invoice-details w-33">
                        <p class="m-0 font-weight-bold">
                            <a
                                (click)="router.navigate(['sales/invoices', invoice.value.invoice_id])"
                                href="javascript:"
                            >
                                {{ invoice.value.invoice_number || 'N/A' }}
                            </a>
                        </p>
                        <p class="m-0">Due: {{ invoice.value.due_date | dateFromNumber | date: 'mediumDate' }}</p>
                        <p class="m-0">Balance: {{ invoice.controls.balance.value | currency }}</p>
                    </div>

                    <div class="w-33">
                        <div class="">{{ invoice.controls.payment.value | currency }}</div>
                    </div>

                    <div class="payment w-33">
                        <div>
                            <a class="pay-full" (click)="fullRefund(invoice)">Full Refund</a>
                            <mat-form-field class="w-100">
                                <input
                                    [maskito]="currencyMaskitoMask"
                                    matInput
                                    type="text"
                                    formControlName="refund_amount"
                                    autocomplete="off"
                                    name="payment"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="invoice-item d-flex">
            <div class="w-33">
                <div class="d-flex">
                    <div class="w-70">Available for Refund ( excess )</div>
                </div>
            </div>

            <div class="w-33">
                <div class="">{{ basePaymentReceived?.over_payment | currency }}</div>
            </div>

            <div class="w-33">
                <mat-form-field class="w-100">
                    <mat-label>Refund Amount</mat-label>
                    <input
                        [maskito]="currencyMaskitoMask"
                        matInput
                        type="text"
                        formControlName="refund_amount"
                        name="amount"
                        autocomplete="off"
                    />
                </mat-form-field>
            </div>
        </div>

        <div class="d-flex justify-content-end">
            <div class="payment-summary mb-3 p-3 text-right">
                <div class="row">
                    <div class="col-8">Refunded Amount:</div>
                    <div class="col-4">{{ basePaymentReceived?.refunded_amount | currency: 'USD' : 'symbol' }}</div>
                </div>
                <div class="row">
                    <div class="col-8">Total for Refund:</div>
                    <div class="col-4">{{ totalForRefund | currency: 'USD' : 'symbol' }}</div>
                </div>
                <div class="row">
                    <div class="col-8">Pending Refunded Amount:</div>
                    <div class="col-4">{{ basePaymentReceived?.refunded_amount! + totalForRefund! | currency }}</div>
                </div>
            </div>
        </div>

        <div class="d-flex">
            <span class="sp-spacer"></span>
            <button mat-raised-button class="mat-primary" *ngIf="basePaymentReceivedId" (click)="create()">
                Refund
            </button>
            <!--            <button mat-raised-button class="mat-primary"-->
            <!--                    *ngIf="paymentReceivedId"-->
            <!--                    (click)="editPayment()">-->
            <!--                Edit-->
            <!--            </button>-->
            <!--            <div class="ml-2"-->
            <!--                 *ngIf="paymentReceived"-->
            <!--                 [matTooltip]="getDeleteTooltip()">-->
            <!--                <button mat-raised-button class="mat-primary"-->
            <!--                        [disabled]=" !isAbleToDelete()"-->
            <!--                        *ngIf="paymentReceivedId"-->
            <!--                        (click)="deletePayment()">-->
            <!--                    Delete-->
            <!--                </button>-->
            <!--            </div>-->
            <button mat-raised-button class="ml-2" routerLink="/sales/paymentsreceived">Cancel</button>
        </div>
    </mat-card>
</section>
