import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {DealPickerComponent} from '../deal-picker/deal-picker.component';

@Component({
    selector: 'app-deal-multi-picker',
    templateUrl: './deal-multi-picker.component.html',
    styleUrls: ['./deal-multi-picker.component.scss']
})
export class DealMultiPickerComponent implements OnInit {
    @Input() dealControl: UntypedFormControl | undefined;
    @Input() placeholder: string = 'Deal';
    @Input() disabledSelect: boolean = false;
    @Input() excludeDealsIds: number[] = [];
    @ViewChild('dealInput') dealInput: ElementRef<HTMLInputElement> | undefined;
    @ViewChild('auto') matAutocomplete: MatAutocomplete | undefined;
    @ViewChild('dealPicker', {static: true}) dealPicker: DealPickerComponent | undefined;
    dealFormControl: UntypedFormControl | undefined;
    listOfExcludedDealsIds: number[] = [];
    selectedDeals: any[] = [];

    /**
     * Constructor
     */
    constructor() {}

    /**
     * On init
     */
    ngOnInit() {
        this.initForm();
    }

    /**
     * Init form
     */
    initForm() {
        this.dealFormControl = new UntypedFormControl('');

        this.selectedDeals = this.dealControl?.value || [];
        this.listOfExcludedDealsIds = this.selectedDeals.map((item) => item.id).concat(this.excludeDealsIds);
    }

    /**
     * On select deal
     * @param data
     */
    onSelectDeal(data: {title: string; id: number}) {
        if (!this.selectedDeals.find((item) => item.id === data.id)) {
            this.selectedDeals.push(data);
            if (typeof this.dealPicker !== 'undefined') {
                this.dealPicker.removeDealFkId();
            }
            if (typeof this.dealControl !== 'undefined') {
                this.dealControl.setValue(this.selectedDeals);
            }
            this.listOfExcludedDealsIds = this.selectedDeals.map((item) => item.id).concat(this.excludeDealsIds);
        }
    }

    /**
     * Remove deal
     * @param id
     */
    removeDeal(id: number) {
        this.selectedDeals = this.selectedDeals.filter((item) => item.id !== id);
        if (typeof this.dealControl !== 'undefined') {
            this.dealControl.setValue(this.selectedDeals);
        }
        this.listOfExcludedDealsIds = this.selectedDeals.map((item) => item.id).concat(this.excludeDealsIds);
    }
}
