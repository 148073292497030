import {Component, OnInit, ViewChild, OnDestroy, AfterViewChecked, Input} from '@angular/core';
import {BehaviorSubject, merge, Subject} from 'rxjs';
import {IAvailabledForContact, IContact} from '@cyberco-nodejs/zipi-typings';
import {takeUntil, debounceTime, take} from 'rxjs/operators';
import {CONTACT_METATYPES} from 'app/local-typings';
import {UntypedFormControl} from '@angular/forms';
import {MatDrawer, MatSidenav} from '@angular/material/sidenav';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {ShipperContactsService} from 'app/services/api/shipper.contacts.service';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {SessionService} from 'app/services/session.service';
import {ContactType} from 'app/models/contact-type';
import {ContactTypeService} from 'app/services/api/contact-type.service';
import {NotificationsServiceZipi} from '../../notifications/notifications.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MergeContactsDialogComponent} from '../contact-dialogs/merge-contacts-dialog/merge-contacts-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {NotificationsService} from 'angular2-notifications';
import {FeatureFlagsService} from '../../feature-flags/feature-flags.service';
import {Profile} from '../../../models/profile';
import {ProfilesService} from '../../../services/profiles.service';

@Component({
    selector: 'app-contacts-root',
    templateUrl: 'contacts-root.component.html',
    styleUrls: ['contacts-root.component.scss']
})
export class ContactsRootComponent implements OnInit, OnDestroy, AfterViewChecked {
    private unsubscribe: Subject<void> = new Subject();

    contactCategories = CONTACT_METATYPES.map(([slug]) => slug);
    metatypeSlugToLabel = new Map(<any>CONTACT_METATYPES);
    contactSelectCtr = new UntypedFormControl(this.contactCategories);
    contacts: IContact[] = [];
    sideBarContent: 'deals' | 'activities' | 'transfer-ownership' | 'share-contacts' | 'finance' | 'records' | null =
        null;
    selectedContact: IContact | undefined;
    contactTypes: ContactType[] = [];
    statusFilters = ['In Directory'];
    statusFilter: UntypedFormControl = new UntypedFormControl([]);

    sidenavStyle = {'min-width': '100%'};
    contactNameStyle = {'max-width': 'none'};

    sortOrder = false; /* false == ASC, true = DESC*/
    sortOrder$ = new Subject();

    showMoreToTop: boolean | undefined; // need initial value 'undefined' because of code in method setScrollingData()
    showMoreToBottom: boolean = false;

    scrolledToTopOffset: number = 0;
    numberOfContactsAddToTop: number | undefined; // need initial value 'undefined' because of code in method setScrollingData()
    scrollData = {
        offset: 0,
        limit: 35,
        total: 0,
        order: {
            display_name: 'asc'
        },
        types: [],
        filters: [],
        is_payment_methods_info_needed: true
    };

    scrollToTop: boolean = false;

    checkViewport$: Subject<any> = new Subject<any>();
    scrollToIndex$: Subject<number> = new Subject<number>();
    offsetTopToLazyLoading$: BehaviorSubject<number> | undefined = undefined;
    currentProfile: Profile = new Profile();
    isSelectedContactDefaultCompanyContact: boolean = false;

    contactIsSelected$: Subject<IContact> = new Subject();

    @Input() contactsIdsToBulkAction: number[] = [];

    @ViewChild('sidenav', {static: true}) sidenav: MatDrawer | undefined = undefined;
    @ViewChild('leftSidebar', {static: true}) leftSidebar: MatSidenav | undefined = undefined;

    mergeContactsEnabledFlag: boolean = false;
    isLoading: boolean = false;

    constructor(
        public router: Router,
        public location: Location,
        private cs: ShipperContactsService,
        public currentProfileSource: CurrentProfileSource,
        public sessionService: SessionService,
        private contactTypeService: ContactTypeService,
        private ntfs: NotificationsServiceZipi,
        private notificationService: NotificationsService,
        protected dialog: MatDialog,
        protected featureFlagsService: FeatureFlagsService,
        private profilesService: ProfilesService
    ) {}

    ngOnInit() {
        this.getAndSubscribeContactTypes();
        // set filters from db
        const contacts_filters = this.sessionService.profile?.settings?.contacts_filters || {
            types: null,
            in_directory: null
        };
        if (contacts_filters.types !== null && contacts_filters.types.length) {
            this.contactSelectCtr.patchValue(contacts_filters.types);
        }
        if (contacts_filters.in_directory !== null && contacts_filters.in_directory) {
            this.statusFilter.patchValue(['In Directory']);
        }

        // watch on contact categories and sort direction changes
        merge(this.contactSelectCtr.valueChanges, this.statusFilter.valueChanges, this.sortOrder$)
            .pipe(debounceTime(300), takeUntil(this.unsubscribe))
            .subscribe(() => {
                if (this.isLoading) {
                    return;
                }
                this.isLoading = true;
                // update params
                this.scrollData = Object.assign(this.scrollData, {
                    offset: 0,
                    order: {
                        display_name: this.sortOrder ? 'desc' : 'asc'
                    },
                    types: this.contactSelectCtr.value,
                    filters: this.statusFilter.value
                });

                // update profile in DB
                const settings = Object.assign({}, this.sessionService.profile?.settings, {
                    contacts_filters: {
                        types: this.contactSelectCtr.value,
                        in_directory: this.statusFilter.value.indexOf('In Directory') !== -1 ? true : false
                    }
                });
                this.profilesService
                    .updateProfileSettings(this.sessionService.profile?.id as number, settings)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(() => {
                        // update current profile
                        this.sessionService.setSettings = {
                            contacts_filters: settings.contacts_filters
                        };
                    });

                return this.initContacts(false);
            });

        // watch on top sidebar
        if (this.leftSidebar) {
            this.leftSidebar.openedChange.pipe(takeUntil(this.unsubscribe)).subscribe((isOpened) => {
                if (isOpened && this.sidenav) {
                    this.sidenav.close();
                    return;
                }
                this.sideBarContent = null;
            });
        }

        // initial upload contacts
        this.initialUploadContacts();

        this.currentProfileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe((profile) => {
            this.currentProfile = profile;
        });

        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.mergeContactsEnabledFlag = this.featureFlagsService.isFeatureEnabled('contacts:merge_contacts');
            });
    }

    ngAfterViewChecked() {
        if (!this.offsetTopToLazyLoading$) {
            this.offsetTopToLazyLoading$ = new BehaviorSubject<number>(0);
        }
    }

    getAndSubscribeContactTypes() {
        // get contactTypes from sessionService variable
        this.contactTypes = this.sessionService.contactTypes;

        // subscribe to contactTypes changes
        this.currentProfileSource.contactTypes.pipe(takeUntil(this.unsubscribe)).subscribe((newList) => {
            this.contactTypes = newList;
        });

        // if no contactTypes - get them from backend and save in currentProfileSource
        if (this.sessionService.contactTypes.length === 0) {
            this.contactTypeService.getContactTypeListAsPromise().then((list) => {
                this.currentProfileSource.contactTypes.next(list);
            });
        }
    }

    getTypesForContact(contact: IContact) {
        if (contact && contact.contact_type_ids && contact.contact_type_ids.length > 0) {
            return this.contactTypes.filter((contactType) =>
                contact.contact_type_ids.some((contactTypeId) => contactTypeId === contactType.contact_type_id)
            );
        }
        return [];
    }

    /**
     * Manage initial upload contacts list
     */
    private initialUploadContacts(): void {
        // update params
        this.scrollData = Object.assign(this.scrollData, {
            offset: 0,
            order: {display_name: this.sortOrder ? 'desc' : 'asc'},
            types: this.contactSelectCtr.value,
            filters: this.statusFilter.value
        });

        if (this.regexUrl()) {
            // if page is uploaded with ID in URL
            return this.initContactsWithContextContactId();
        } else {
            // without ID in URL
            this.showMoreToTop = false;
            return this.initContacts(true);
        }
    }

    /**
     * Upload contacts from init, if in URL is contact ID
     */
    private initContactsWithContextContactId(): void {
        const urlTree = this.router.parseUrl(this.router.url);
        const contactId = urlTree.root.children.primary.segments[1].path;
        this.cs
            .getContactsListWithDelegatedAndParams(
                {
                    limit: this.scrollData.limit,
                    order: {display_name: this.sortOrder ? 'desc' : 'asc'},
                    is_payment_methods_info_needed: true
                },
                Number(contactId)
            )
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((response) => {
                if (!response.success) {
                    this.notificationService.error(response.message);
                    this.router.navigateByUrl('/contacts');
                } else {
                    this.initContact(response.result);
                }
            });
    }

    async initContact(response: any) {
        // update contacts from start
        await this.handleUploadContacts(response.data, response._meta, false);

        const urlTree = this.router.parseUrl(this.router.url);
        const contactId = urlTree.root.children.primary.segments[1].path;

        // open contact card
        this.selectedContact = this.contacts.find((contact) => contact.contact_id === Number(contactId));

        // scroll to selected
        if (this.selectedContact) {
            this.scrollToSelectedContact(this.selectedContact);
            this.processSelectedContact(this.selectedContact);
            this.sidenavStyle = {'min-width': '255px'};
            this.contactNameStyle = {'max-width': '220px'};
            if (this.sidenav) {
                this.sidenav.open();
            }
        } else {
            this.ntfs.addError('Contact not found');
        }

        // set scrolledToTopOffset
        if (response._meta.offset !== 0) {
            this.scrolledToTopOffset = response._meta.offset;
        }
    }

    /**
     * Upload contacts from init or during scrolling to bottom
     */
    private initContacts(isInitial: boolean): void {
        // reset contacts list
        this.contacts = [];
        this.cs
            .getContactsListWithDelegatedAndParams(this.scrollData)
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((response) => {
                // update contacts from start
                this.handleUploadContacts(response.result.data, response.result._meta, false);
                if (isInitial) {
                    this.doShowMoreDown({});
                }
                this.isLoading = false;
            });
    }

    /**
     * Manage adding new contacts to list, update meta data
     *
     * @param contacts {IContact[]}
     * @param meta {offset: number, limit: number, total: number}
     * @param addToStart {boolean}
     */
    private async handleUploadContacts(
        contacts: IContact[],
        meta: {offset: number; limit: number; total: number},
        addToStart: boolean = false
    ) {
        let linkedContactIds: number[] = [];
        contacts.forEach((contact) => {
            if (contact.linked_to_contacts_ids && contact.linked_to_contacts_ids.length) {
                linkedContactIds = [...linkedContactIds, ...contact.linked_to_contacts_ids];
            }
        });

        // add new contacts to the end of the list
        if (!addToStart) {
            this.contacts = [
                ...this.contacts,
                ...contacts.filter((contact) => linkedContactIds.indexOf(Number(contact.contact_id)) === -1)
            ];
        }

        // add new contacts to the beginning of the list
        if (addToStart) {
            // if get the top of the ALL contacts list
            if (this.scrolledToTopOffset === 0) {
                // get unique values
                this.contacts = [
                    ...contacts
                        .filter((contact) => linkedContactIds.indexOf(Number(contact.contact_id)) === -1)
                        .slice(0, this.numberOfContactsAddToTop),
                    ...this.contacts
                ];

                // scroll to current position
                // numberOfContactsAddToTop can be negative
                setTimeout(() =>
                    this.scrollToIndex$.next(
                        this.numberOfContactsAddToTop && this.numberOfContactsAddToTop > 0
                            ? this.numberOfContactsAddToTop + 1
                            : this.scrollData.limit + (this.numberOfContactsAddToTop || 0) + 1
                    )
                );
            } else {
                this.contacts = [
                    ...contacts.filter((contact) => linkedContactIds.indexOf(Number(contact.contact_id)) === -1),
                    ...this.contacts
                ];

                // scroll to current position
                setTimeout(() => this.scrollToIndex$.next(this.scrollData.limit + 1));
            }
        }

        // set different scrolling values
        this.setScrollingData(meta, addToStart);
    }

    /**
     * Manage all occasions if user clicked on contact
     *
     * @param contact {IContact}
     */
    public handleSelectedContact(contact: IContact) {
        this.selectedContact = contact;
        this.location.go('/contacts/' + contact.id);
        this.sidenavStyle = {'min-width': '255px'};
        this.contactNameStyle = {'max-width': '220px'};
        if (this.sidenav) {
            this.sidenav.open();
        }
        // for now - do not scroll to clicked contact
        // this.scrollToSelectedContact(this.selectedContact);
        this.processSelectedContact(this.selectedContact);
    }

    public trackByContactID(idx: number, contact: IContact | IAvailabledForContact) {
        return contact.display_name;
    }

    public openSideBar(
        content: 'deals' | 'activities' | 'transfer-ownership' | 'share-contacts' | 'finance' | 'records'
    ) {
        if (content === this.sideBarContent) {
            this.sideBarContent = null;
            if (this.leftSidebar) {
                this.leftSidebar.close();
            }
            return;
        }
        this.sideBarContent = content;
        if (this.leftSidebar) {
            this.leftSidebar.open();
        }
    }

    public regexUrl(): boolean {
        return RegExp('/[0-9]').test(this.location.path());
    }

    public changeSortOrder() {
        this.sortOrder = !this.sortOrder;
        this.sortOrder$.next(this.sortOrder);
    }

    private scrollToSelectedContact(activeContact: IContact): void {
        if (activeContact) {
            const selectedIndex = this.contacts.findIndex((elem) => elem.contact_id === activeContact.contact_id);
            if (selectedIndex > -1) {
                setTimeout(() => {
                    this.scrollToIndex$.next(selectedIndex);
                });
            }
        }
    }

    private processSelectedContact(activeContact: IContact): void {
        this.isSelectedContactDefaultCompanyContact = this.getIsDefaultCompanyContact(this.selectedContact);
        if (activeContact) {
            this.contactIsSelected$.next(activeContact);
        }
    }

    getIsDefaultCompanyContact(contact: IContact | undefined) {
        if (
            contact &&
            this.currentProfile &&
            this.currentProfile.company &&
            this.currentProfile.company.company_group &&
            this.currentProfile.company.company_group.contact_fk_id &&
            this.currentProfile.company.company_group.contact_fk_id === contact.contact_id
        ) {
            return true;
        }
        return false;
    }

    /**
     * Scroll down
     */
    public doShowMoreDown(data: any): void {
        // scroll to bottom and has available contacts
        if (!this.showMoreToBottom) {
            return;
        }

        // set scrolling direction
        this.scrollToTop = false;
        // to prevent sending several requests
        this.showMoreToBottom = false;
        // load next batch of contacts
        this.cs
            .getContactsListWithDelegatedAndParams(this.scrollData)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                this.handleUploadContacts(response.result.data, response.result._meta, false);
            });
    }

    /**
     * Scroll to top
     */
    public doShowMoreTop(data: any): void {
        // scroll to top and has available contacts
        if (!this.showMoreToTop) {
            return;
        }

        // set scrolling direction
        this.scrollToTop = true;
        // get offset for scrolling to top
        this.scrolledToTopOffset -= this.scrollData.limit * 2;

        // set if contacts reached the beginning of the list
        if (this.scrolledToTopOffset < 0) {
            this.numberOfContactsAddToTop = this.scrolledToTopOffset + this.scrollData.limit;
            this.scrolledToTopOffset = 0;
            this.showMoreToTop = false;
        }

        // no need to upload smth
        if (this.numberOfContactsAddToTop === 0) {
            return;
        }

        // load next batch of contacts
        this.cs
            .getContactsListWithDelegatedAndParams(
                Object.assign({}, this.scrollData, {offset: this.scrolledToTopOffset})
            )
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                this.handleUploadContacts(response.result.data, response.result._meta, true);
            });
    }

    private setScrollingData(meta: {offset: number; limit: number; total: number}, addToStart: boolean): void {
        // check if contacts reached the end of the list
        if (!this.scrollToTop) {
            this.showMoreToBottom = Number(meta.offset) < Number(meta.total);
        }

        // set initial value showMoreToTop
        if (typeof this.showMoreToTop === 'undefined') {
            this.showMoreToTop = true;
        }

        // do not update offset if data was added to top
        if (!addToStart) {
            this.scrollData.offset = meta.offset;
        }

        this.scrollData.total = meta.total;
    }

    handleBulkAction(action: string) {
        if (action === 'merge_contacts') {
            const dialogRef = this.dialog.open(MergeContactsDialogComponent, {
                maxWidth: 700,
                data: {
                    primaryApplicantsIds: this.contactsIdsToBulkAction
                }
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response) {
                        this.notificationService.success(`Contacts merge is in process. Please wait..`);
                    }
                });
        }
    }

    toggleDealCheckbox(control: MatCheckboxChange) {
        const contactId = Number(control.source.value);
        if (control.checked) {
            this.contactsIdsToBulkAction.push(contactId);
        } else {
            this.contactsIdsToBulkAction.splice(this.contactsIdsToBulkAction.indexOf(contactId), 1);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.sortOrder$.complete();
        this.checkViewport$.complete();
        this.scrollToIndex$.complete();
        if (this.offsetTopToLazyLoading$) {
            this.offsetTopToLazyLoading$.complete();
        }
        this.contactIsSelected$.complete();
    }
}
