<div *ngIf="cardForm" class="">
    <div
        *ngIf="
            (!authorizeGateway || !authorizeGateway.value) &&
            !invoiceAuthorizeAvailable &&
            !invoiceDwollaAvailable &&
            isLoading
        "
    >
        No Payment Gateways has been detected, please activate your preferred Gateway:
        <a [routerLink]="'/marketplace'" (click)="close()">Company > Marketplace</a>
    </div>

    <mat-accordion *ngIf="showAccordion">
        <div
            *ngIf="
                (!authorizeGateway || !authorizeGateway.value) &&
                !invoiceAuthorizeAvailable &&
                invoiceDwollaAvailable &&
                !data.justZipiFinancial
            "
        >
            The Authorize.Net Payment Gateway has not been detected, please activate:
            <a [routerLink]="'/marketplace'" (click)="close()">Company > Marketplace</a>
        </div>
        <mat-expansion-panel
            *ngIf="!((!authorizeGateway || !authorizeGateway.value || !contactId) && !invoiceAuthorizeAvailable)"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="logo">
                        <img
                            style="width: 80px"
                            src="https://storage.googleapis.com/zipi-static/addons/authnet/logos/authnet%20360x140.png"
                        />
                    </div>
                    <!--<mat-icon>credit_card</mat-icon>-->
                    <!--<span>{{authorizeGateway.value.title}}</span>-->
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div [formGroup]="cardForm">
                <h1>Add Credit Card</h1>

                <mat-progress-bar
                    mode="indeterminate"
                    color="warn"
                    [class.hide]="!hasPendingRequest"
                    class="pw-progress-bar"
                ></mat-progress-bar>
                <app-notifications [notification_mode]="'popup'"></app-notifications>
                <div style="max-width: 500px; margin: auto; width: 500px" class="m-2">
                    <div class="my-3">
                        <h3>Card Details</h3>
                        <div class="w-100 px-2 d-flex">
                            <mat-form-field class="w-50 mr-2">
                                <input matInput required type="text" formControlName="firstName" />
                                <mat-placeholder>First Name</mat-placeholder>
                            </mat-form-field>

                            <mat-form-field class="w-50 mr-2">
                                <input matInput required type="text" formControlName="lastName" />
                                <mat-placeholder>Last Name</mat-placeholder>
                            </mat-form-field>
                        </div>

                        <div class="w-100 px-2 d-flex">
                            <mat-form-field class="w-50 mr-2">
                                <input matInput required type="text" formControlName="cardNumber" />
                                <mat-placeholder>Card Number</mat-placeholder>
                            </mat-form-field>

                            <mat-form-field class="w-50 mr-2">
                                <input matInput required type="text" formControlName="cardCode" />
                                <mat-placeholder>Card Code</mat-placeholder>
                            </mat-form-field>
                        </div>

                        <div class="w-100 px-2 d-flex">
                            <mat-form-field class="w-50 mr-2">
                                <input matInput required type="text" formControlName="expMonth" />
                                <mat-placeholder>Expiration Month(i.e 07)</mat-placeholder>
                            </mat-form-field>

                            <mat-form-field class="w-50 mr-2">
                                <input matInput required type="text" formControlName="expYear" />
                                <mat-placeholder>Expiration Year(i.e 23)</mat-placeholder>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="my-3">
                        <h3>Billing Address</h3>

                        <mat-form-field class="w-100 px-2">
                            <input matInput required type="text" formControlName="address" />
                            <mat-placeholder>Address</mat-placeholder>
                        </mat-form-field>

                        <mat-form-field class="w-100 px-2">
                            <input matInput required type="text" formControlName="city" />
                            <mat-placeholder>City</mat-placeholder>
                        </mat-form-field>

                        <div class="w-100 px-2 d-flex">
                            <mat-form-field class="w-50 mr-2">
                                <input matInput required type="text" formControlName="state" />
                                <mat-placeholder>State</mat-placeholder>
                            </mat-form-field>

                            <mat-form-field class="w-50 mr-2">
                                <input matInput required type="number" formControlName="zipCode" />
                                <mat-placeholder>Zip Code</mat-placeholder>
                            </mat-form-field>
                        </div>

                        <!--<mat-form-field class="w-100 px-2">-->
                        <!--<input matInput required type="text" formControlName="country">-->
                        <!--<mat-placeholder>Country</mat-placeholder>-->
                        <!--</mat-form-field>-->

                        <mat-form-field class="w-100 px-2">
                            <mat-select [placeholder]="'Country'" formControlName="country">
                                <mat-option *ngFor="let country of countryList" [value]="country.slug">
                                    {{ country.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <span>Based on Authorize.Net gateway</span>
                    <!--<mat-form-field class="px-2">-->
                    <!--<mat-select [formControl]="currentGateway">-->
                    <!--<mat-option *ngFor="let gateway of companyGateways" [value]="gateway">{{gateway.title}}</mat-option>-->
                    <!--</mat-select>-->
                    <!--<mat-placeholder>Payment Method</mat-placeholder>-->
                    <!--</mat-form-field>-->
                </div>
                <div *ngIf="invoice && isPublicPage">
                    <mat-checkbox formControlName="use_as_default">Always pay with this card</mat-checkbox>
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <button type="button" class="mr-2" mat-raised-button color="primary" (click)="saveCard()">
                    Continue
                </button>

                <button type="button" mat-raised-button (click)="close()">Cancel</button>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel
            *ngIf="!(contactId || (invoice && !data.contact && !invoiceDwollaAvailable))"
            [disabled]="invoice && !data.canViewInApp"
            [expanded]="data.justZipiFinancial"
        >
            <mat-expansion-panel-header>
                <div class="d-flex align-items-center">
                    <mat-spinner class="mx-2" [diameter]="22" *ngIf="!onDemMessage"></mat-spinner>
                    <span *ngIf="!invoice && isLoading" class="ml-2" style="color: red"
                        >To use "Money Transfers (EFT)" you must activate Money Transfer Service.</span
                    >

                    <span *ngIf="invoice && !data.canViewInApp" class="ml-2" style="color: red"
                        >To use "Money Transfers (EFT)" you must activate Money Transfer Service.</span
                    >
                </div>
            </mat-expansion-panel-header>

            <div>Connect Bank Account</div>

            <div class="my-3">
                <mat-checkbox [(ngModel)]="defaultReceiving" class="policy-check">
                    <span class="w-100 text-wrap policy"> Make this my Default Deposit Account </span>
                </mat-checkbox>
            </div>

            <div class="my-3" *ngIf="onDemMessage">
                <mat-checkbox [checked]="agree" (change)="agree = !agree" class="policy-check">
                    <span class="w-100 text-wrap policy">
                        {{ onDemMessage }}
                    </span>
                </mat-checkbox>
            </div>

            <div class="d-flex">
                <i class="sp-spacer"></i>

                <button type="button" class="mr-2" mat-raised-button (click)="close()">Cancel</button>

                <!--                <button class="mr-2" type="button" mat-raised-button color="primary" [disabled]="!agree || agreeContinueButtonDisabled" (click)="linkOpen()">-->
                <!--                    Agree & Continue-->
                <!--                </button>-->
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
