import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filterNgFor'
})
export class FilterForNgForPipe implements PipeTransform {
    transform(items: Array<{[key: string]: any}>, filter: {field: string; value: any}, exactly: boolean): any {
        if (!items || !filter) {
            return items;
        }

        if (exactly) {
            return items.filter((item) => item[filter.field] === filter.value);
        } else {
            return items.filter((item) => item[filter.field].includes(filter.value));
        }
    }
}
