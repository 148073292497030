import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ITag, ITagCategory} from '@cyberco-nodejs/zipi-typings';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {ICompanyWideState} from 'app/store/company-wide/company-wide.reducer';
import {CreateTagCategory, DeleteTagCategory, UpdateTagCategory} from 'app/store/company-wide/company-wide.actions';

interface IDialogData {
    tag_category: ITagCategory;
}

export class CustomFormArray extends UntypedFormArray {
    public controls: UntypedFormGroup[];

    constructor(inputs?: UntypedFormGroup[]) {
        super(inputs || []);
        this.controls = inputs || [];
    }
}

@Component({
    selector: 'app-edit-tag-category-dialog',
    templateUrl: './edit-tag-category-dialog.component.html',
    styleUrls: ['./edit-tag-category-dialog.component.scss']
})
export class EditTagCategoryDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    tagCategory: ITagCategory | undefined = undefined;
    categoryTitle: string = '';

    tagsArray: CustomFormArray = new CustomFormArray([]);
    removedTags: number[] = [];
    saveDisabled: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<EditTagCategoryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData,
        private store: Store<ICompanyWideState>
    ) {}

    ngOnInit() {
        if (this.data.tag_category.id) {
            this.tagCategory = this.data.tag_category;
            this.categoryTitle = this.data.tag_category.title;
            this.tagsArray.patchValue(this.data.tag_category.tags);
            for (const tag of this.data.tag_category.tags) {
                this.addTag(tag);
            }
        } else {
            this.addTag();
        }
    }

    saveTagCategory() {
        const tagsArray = this.tagsArray.getRawValue();
        if (this.validateTags(tagsArray)) {
            return;
        }

        this.saveDisabled = true;

        this.store.dispatch(
            new CreateTagCategory({
                title: this.categoryTitle.trim(),
                tags: tagsArray,
                id: null,
                tag_category_id: null,
                system_key: null,
                company_fk_id: null
            })
        );

        this.dialogRef.close(true);
        this.saveDisabled = false;
    }

    updateTagCategory() {
        const tagsArray: ITag[] = this.tagsArray.getRawValue();
        if (this.validateTags(tagsArray)) {
            return;
        }

        this.saveDisabled = true;

        this.store.dispatch(
            new UpdateTagCategory({
                tag_category: {
                    title: this.categoryTitle.trim(),
                    tags: tagsArray,
                    id: this.tagCategory?.id,
                    tag_category_id: this.tagCategory?.id || null,
                    system_key: this.tagCategory?.system_key || null,
                    company_fk_id: this.tagCategory?.company_fk_id || null
                },
                removed_tags: this.removedTags
            })
        );

        this.dialogRef.close(true);
        this.saveDisabled = false;
    }

    deleteTagCategory() {
        this.saveDisabled = true;

        if (typeof this.tagCategory !== 'undefined' && this.tagCategory.id) {
            this.store.dispatch(new DeleteTagCategory(this.tagCategory.id));
        }

        this.dialogRef.close(true);
        this.saveDisabled = false;
    }

    addTag(tag?: ITag) {
        const tagGroup = this.fb.group({
            title: [tag ? tag.title : null, Validators.required],
            id: tag ? tag.id : null,
            parent__tag_fk_id: tag ? tag.parent__tag_fk_id : null,
            deactivated: tag ? tag.deactivated : false
        });

        if (tag && tag.deactivated) {
            tagGroup.get('title')?.disable();
        }

        this.tagsArray.push(tagGroup);
    }

    removeTag(idx: number, tagGroup: any) {
        this.tagsArray.removeAt(idx);

        const tag = tagGroup.getRawValue();

        this.removedTags.push(tag.id);
    }

    close() {
        this.dialogRef.close(false);
    }

    validateTags(tags: ITag[]): boolean {
        if (tags.some((t) => !t.title || !t.title.trim())) {
            // @ts-ignore: FormGroup is not an AbstractControl
            this.tagsArray.controls.forEach((c: UntypedFormGroup) => {
                c.controls.title.markAsTouched();
            });
            return true;
        }
        if (!this.categoryTitle.trim()) {
            return true;
        }
        return false;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
