<div>
    <h2 class="mr-2">Select Company</h2>

    <div *ngIf="muhneeCompanies">
        <div *ngIf="muhneeCompanies.own_companies && muhneeCompanies.own_companies.length > 0">
            <mat-card *ngFor="let company of muhneeCompanies.own_companies">
                <div class="d-flex w-100 align-items-center">
                    <div class="mr-2">{{ company.companyName }}</div>
                    <div *ngIf="company.isItDefault === 1" style="color: #aaaaaa">(Default Company)</div>
                    <span class="sp-spacer"></span>
                    <button type="button" mat-raised-button color="primary" (click)="connectCompany(company.id)">
                        Connect
                    </button>
                </div>
            </mat-card>
        </div>
        <div *ngIf="muhneeCompanies.invited_companies && muhneeCompanies.invited_companies.length > 0">
            <mat-card *ngFor="let company of muhneeCompanies.invited_companies">
                <div class="d-flex w-100 align-items-center">
                    <div class="mr-2">{{ company.company_name }}</div>
                    <span class="sp-spacer"></span>
                    <button
                        type="button"
                        mat-raised-button
                        color="primary"
                        (click)="connectCompany(company.company_id)"
                    >
                        Connect
                    </button>
                </div>
            </mat-card>
        </div>
    </div>

    <div class="d-flex mt-3">
        <span class="sp-spacer"></span>
        <button type="button" mat-raised-button (click)="close()">Cancel</button>
    </div>
</div>
