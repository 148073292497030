<mat-card>
    <mat-card-header class="deal-header pr-3 mb-4">
        <mat-card-title class="deal-header-title">
            Deal Information
            <div
                class="system-status ml-5 font-weight-normal"
                *ngIf="
                    dealFormGroup.controls.id &&
                    dealFormGroup.controls.id.value &&
                    dealFormGroup.controls.disbursement_status &&
                    dealFormGroup.controls.disbursement_status.value
                "
            >
                {{ DEAL.disbursement_statuses_LABELS[dealFormGroup.controls.disbursement_status.value] }}
            </div>
            <div
                class="ml-3"
                *ngIf="
                    dealFormGroup.controls.id &&
                    dealFormGroup.controls.id.value &&
                    dealFormGroup.controls.system_status &&
                    (dealFormGroup.controls.system_status.value === DEAL_SYSTEM_STATUS.draft || canBeRevertedToDraft)
                "
            >
                <button
                    *ngIf="dealUnsavedStatus === DEAL_SYSTEM_STATUS.draft"
                    type="button"
                    mat-raised-button
                    (click)="makeOpen()"
                >
                    Mark as Completed
                </button>
                <button *ngIf="canBeRevertedToDraft" type="button" mat-raised-button (click)="revertToDraft()">
                    Revert to Uncompleted
                </button>
            </div>
            <span *ngIf="dealFormGroup.getRawValue().is_archived">&nbsp;[Archived]</span>
            <div
                *ngIf="hasWarnings && isAllWarningsDismissed"
                style="display: flex; margin-left: 5px"
                (click)="
                    showDismissedWarnings = !showDismissedWarnings;
                    showDismissWarningEventEmitter.emit(showDismissedWarnings)
                "
            >
                <mat-icon
                    [ngStyle]="{color: showDismissedWarnings ? 'grey' : '#ff6000'}"
                    [matTooltip]="
                        showDismissedWarnings
                            ? 'This deal has dismissed warnings. Click to hide them'
                            : 'This deal has dismissed warnings. Click to show them'
                    "
                >
                    error_outline
                </mat-icon>
            </div>
            <div *ngIf="isBackgroundCalculationFailed" style="display: flex; margin-left: 5px">
                <mat-icon
                    style="cursor: default; color: #ff0000"
                    [matTooltip]="'Deal currently contains calculation failure.'"
                >
                    error_outline
                </mat-icon>
            </div>
        </mat-card-title>

        <span class="remaining-space"></span>

        <app-ui-mod-deal-badges-in-list
            *ngIf="dealFormGroup.controls.id"
            [dealId]="dealFormGroup.controls.id.value"
        ></app-ui-mod-deal-badges-in-list>

        <app-ui-mod-deal-badges-on-page
            *ngIf="dealFormGroup.controls.id"
            [dealId]="dealFormGroup.controls.id.value"
        ></app-ui-mod-deal-badges-on-page>

        <div class="col-6 pr-0 pl-4" *ngIf="!dealFormGroup.controls.id || !dealFormGroup.controls.id.value">
            <div class="d-flex justify-content-end row">
                <mat-form-field class="col-6" *ngIf="dealFormGroup.controls.name">
                    <input
                        matInput
                        placeholder="Deal name"
                        type="text"
                        required
                        autocomplete="off"
                        [formControl]="dealFormGroup.controls.name"
                    />
                </mat-form-field>

                <mat-form-field class="col-6" *ngIf="dealFormGroup.controls.status">
                    <mat-select
                        placeholder="Status"
                        required
                        name="status"
                        [formControl]="dealFormGroup.controls.status"
                    >
                        <mat-option *ngFor="let status of object_keys(DEAL.status_SET)" [value]="status">
                            {{ DEAL.status_LABELS[status] }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div
            class="ml-2"
            *ngIf="dealFormGroup.controls.id && dealFormGroup.controls.id.value && dealFormGroup.controls.status"
        >
            <button mat-raised-button class="pr-2 font-weight-bold status-dropdown" [matMenuTriggerFor]="menu">
                <mat-icon class="status-icon" [ngClass]="dealFormGroup.controls.status.value">label</mat-icon>
                {{ DEAL.status_LABELS[dealFormGroup.controls.status.value] }}
                <mat-icon>expand_more</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before" style="position: absolute">
                <button
                    mat-menu-item
                    *ngFor="let status of object_keys(DEAL.status_SET)"
                    (click)="updateDealStatus(status)"
                >
                    <mat-icon class="status-icon" [ngClass]="status">label</mat-icon>
                    {{ DEAL.status_LABELS[status] }}
                </button>
            </mat-menu>
        </div>
    </mat-card-header>

    <mat-card-content class="w-100 px-3">
        <div class="row">
            <div class="col-md-6">
                <!------- Address rows ------->
                <!--show location picker only if all address fields are empty-->
                <div
                    class="row"
                    *ngIf="
                        dealFormGroup.controls.city &&
                        !dealFormGroup.controls.city.value &&
                        dealFormGroup.controls.address &&
                        !dealFormGroup.controls.address.value &&
                        dealFormGroup.controls.country &&
                        !dealFormGroup.controls.country.value &&
                        dealFormGroup.controls.street_number &&
                        !dealFormGroup.controls.street_number.value &&
                        dealFormGroup.controls.zip &&
                        !dealFormGroup.controls.zip.value &&
                        dealFormGroup.controls.state &&
                        !dealFormGroup.controls.state.value &&
                        dealFormGroup.controls.unit &&
                        !dealFormGroup.controls.unit.value
                    "
                >
                    <div class="col-12" style="margin-top: -16px">
                        <app-gplaces-picker [locationCtrl]="locationPickerCtrl"></app-gplaces-picker>
                    </div>
                </div>

                <!------- Address rows ------->
                <!--show address fields if at least one of them is not empty-->
                <ng-container
                    *ngIf="
                        (dealFormGroup.controls.city && dealFormGroup.controls.city.value) ||
                        (dealFormGroup.controls.address && dealFormGroup.controls.address.value) ||
                        (dealFormGroup.controls.country && dealFormGroup.controls.country.value) ||
                        (dealFormGroup.controls.street_number && dealFormGroup.controls.street_number.value) ||
                        (dealFormGroup.controls.zip && dealFormGroup.controls.zip.value) ||
                        (dealFormGroup.controls.state && dealFormGroup.controls.state.value) ||
                        (dealFormGroup.controls.unit && dealFormGroup.controls.unit.value)
                    "
                >
                    <div class="d-grid-1-line">
                        <!-- Street -->
                        <form autocomplete="off" class="mr-2">
                            <span class="thin-title">Street #</span>
                            <mat-form-field class="w-100" *ngIf="dealFormGroup.controls.street_number">
                                <input
                                    matInput
                                    aria-label=""
                                    autocomplete="off"
                                    autocorrect="off"
                                    autocapitalize="none"
                                    spellcheck="false"
                                    [formControl]="dealFormGroup.controls.street_number"
                                />
                            </mat-form-field>
                        </form>
                        <!-- Address -->
                        <form autocomplete="off" class="mx-2">
                            <span class="thin-title">Address</span>
                            <mat-form-field class="w-100" *ngIf="dealFormGroup.controls.address">
                                <input
                                    matInput
                                    aria-label=""
                                    autocomplete="off"
                                    autocorrect="off"
                                    autocapitalize="none"
                                    spellcheck="false"
                                    [formControl]="dealFormGroup.controls.address"
                                />
                            </mat-form-field>
                        </form>
                        <!-- Unit -->
                        <form autocomplete="off" class="ml-2">
                            <span class="thin-title">Unit</span>
                            <mat-form-field class="w-100" *ngIf="dealFormGroup.controls.unit">
                                <input
                                    matInput
                                    aria-label=""
                                    autocomplete="off"
                                    autocorrect="off"
                                    autocapitalize="none"
                                    spellcheck="false"
                                    [formControl]="dealFormGroup.controls.unit"
                                />
                            </mat-form-field>
                        </form>
                    </div>

                    <div class="d-grid-2-line">
                        <!-- City -->
                        <form autocomplete="off" class="mr-2">
                            <span class="thin-title">City</span>
                            <mat-form-field class="w-100" *ngIf="dealFormGroup.controls.city">
                                <input
                                    matInput
                                    aria-label=""
                                    autocomplete="off"
                                    autocorrect="off"
                                    autocapitalize="none"
                                    spellcheck="false"
                                    [formControl]="dealFormGroup.controls.city"
                                />
                            </mat-form-field>
                        </form>
                        <!-- State -->
                        <div class="mx-2">
                            <mat-form-field class="w-100" *ngIf="dealFormGroup.controls.state">
                                <!-- placeholder bug fix - it is not updated on patchValue -->
                                <input
                                    matInput
                                    [placeholder]="
                                        dealFormGroup.controls.state && dealFormGroup.controls.state.value
                                            ? ''
                                            : 'State'
                                    "
                                    aria-label="State"
                                    [matAutocomplete]="auto"
                                    autocomplete="doNotAutoComplete"
                                    [formControl]="dealFormGroup.controls.state"
                                />
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let state of filteredStates | async" [value]="state">{{
                                        state
                                    }}</mat-option>
                                </mat-autocomplete>

                                <!-- added fake placeholder because it is not updated on patchValue -->
                                <span
                                    *ngIf="dealFormGroup.controls.state && dealFormGroup.controls.state.value"
                                    class="fake-state-placeholder"
                                    >State</span
                                >
                            </mat-form-field>
                        </div>
                        <!-- Zip -->
                        <div class="mx-2">
                            <span class="thin-title">Zip</span>
                            <mat-form-field class="w-100" *ngIf="dealFormGroup.controls.zip">
                                <input
                                    matInput
                                    autocomplete="doNotAutoComplete"
                                    aria-label=""
                                    autocorrect="off"
                                    autocapitalize="none"
                                    spellcheck="false"
                                    [formControl]="dealFormGroup.controls.zip"
                                />
                            </mat-form-field>
                        </div>
                        <!-- Country -->
                        <form autocomplete="off" class="ml-2">
                            <span class="thin-title">Country</span>
                            <mat-form-field class="w-100" *ngIf="dealFormGroup.controls.country">
                                <input
                                    matInput
                                    aria-label=""
                                    autocomplete="off"
                                    autocorrect="off"
                                    autocapitalize="none"
                                    spellcheck="false"
                                    [formControl]="dealFormGroup.controls.country"
                                />
                            </mat-form-field>
                        </form>
                    </div>
                </ng-container>

                <!------- Image ------->
                <div class="w-100">
                    <!-- mb-xl-n5 -->
                    <app-deal-image [dealFormGroup]="dealFormGroup"></app-deal-image>

                    <div class="zp-external_deal_url">
                        <mat-form-field
                            class="zp-external_deal_url__field"
                            *ngIf="dealFormGroup.controls.external_deal_url"
                        >
                            <input
                                matInput
                                type="url"
                                placeholder="External Deal Url"
                                autocomplete="off"
                                [formControl]="dealFormGroup.controls.external_deal_url"
                            />
                        </mat-form-field>
                    </div>
                </div>

                <div class="zp-external_deal_url" style="margin: unset">
                    <!------- Division (deny edit) ------->
                    <mat-form-field
                        class="zp-external_deal_url__mat-form-field"
                        rbac
                        [rule]="{deals__manage_division_assignment: false}"
                        [denyMethod]="'style'"
                        [denyStyle]="{display: 'none'}"
                        [allowStyle]="{display: 'inline-block'}"
                        *ngIf="dealFormGroup.controls.division__company_group_fk_id"
                    >
                        <mat-select
                            [formControl]="dealFormGroup.controls.division__company_group_fk_id"
                            placeholder="Division"
                        >
                            <!------- Option (none) ------->
                            <mat-option
                                [value]="null"
                                [disabled]="dealFormGroup.controls.id && dealFormGroup.controls.id.value"
                            >
                                <span class="text-muted font-italic">None</span>
                            </mat-option>
                            <!------- Option (division out of range) ------->
                            <mat-select-trigger
                                class="d-flex align-items-center"
                                *ngIf="
                                    dealFormGroup.controls.division__company_group_fk_id &&
                                    !(
                                        allDivisions
                                        | findItemByIdInArray
                                            : dealFormGroup.controls.division__company_group_fk_id.value
                                    )?.added_to_division_at
                                "
                            >
                                <mat-icon class="mr-3" matTooltip="Primary Agent is not a member of this Division"
                                    >error_outline</mat-icon
                                >
                                <span>{{
                                    (
                                        allDivisions
                                        | findItemByIdInArray
                                            : dealFormGroup.controls.division__company_group_fk_id.value
                                    )?.title
                                }}</span>
                            </mat-select-trigger>
                            <!------- Options primary SE ------->
                            <mat-option
                                *ngFor="let group of allDivisions"
                                [value]="group.id"
                                [disabled]="dealFormGroup.controls.id && dealFormGroup.controls.id.value"
                            >
                                <span
                                    *ngIf="!group.added_to_division_at; else notParticipate"
                                    class="d-flex align-items-center"
                                >
                                    <mat-icon matTooltip="Primary Agent is not a member of this Division"
                                        >error_outline</mat-icon
                                    >
                                    <span>{{ group.title }}</span>
                                </span>
                                <ng-template #notParticipate>{{ group.title }}</ng-template>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!------- Division (allow edit) ------->
                    <mat-form-field
                        class="zp-external_deal_url__mat-form-field"
                        rbac
                        [rule]="{deals__manage_division_assignment: true}"
                        [denyMethod]="'style'"
                        [denyStyle]="{display: 'none'}"
                        [allowStyle]="{display: 'inline-block'}"
                        *ngIf="dealFormGroup.controls.division__company_group_fk_id"
                    >
                        <mat-select
                            [formControl]="dealFormGroup.controls.division__company_group_fk_id"
                            placeholder="Division"
                        >
                            <!------- Option (none) ------->
                            <mat-option [value]="null">
                                <span class="text-muted font-italic">None</span>
                            </mat-option>
                            <!------- Shown value ------->
                            <mat-select-trigger
                                class="d-flex align-items-center"
                                *ngIf="
                                    dealFormGroup.controls.division__company_group_fk_id &&
                                    !(
                                        allDivisions
                                        | findItemByIdInArray
                                            : dealFormGroup.controls.division__company_group_fk_id.value
                                    )?.added_to_division_at
                                "
                            >
                                <mat-icon class="mr-3" matTooltip="Primary Agent is not a member of this Division"
                                    >error_outline</mat-icon
                                >
                                <span>{{
                                    (
                                        allDivisions
                                        | findItemByIdInArray
                                            : dealFormGroup.controls.division__company_group_fk_id.value
                                    )?.title
                                }}</span>
                            </mat-select-trigger>
                            <!------- Option value ------->
                            <mat-option *ngFor="let group of allDivisions" [value]="group.id">
                                <span *ngIf="!group.added_to_division_at; else move" class="d-flex align-items-center">
                                    <mat-icon matTooltip="Primary Agent is not a member of this Division"
                                        >error_outline</mat-icon
                                    >
                                    <span>{{ group.title }}</span>
                                </span>
                                <ng-template #move>
                                    <span>{{ group.title }}</span>
                                </ng-template>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!------- Tags ------->
                    <mat-form-field *ngIf="tags" class="zp-external_deal_url__mat-form-field">
                        <mat-select
                            [(ngModel)]="selectedTags"
                            placeholder="Tags"
                            multiple
                            (selectionChange)="setTags($event.value)"
                            [disabled]="dealFormGroup.controls.taggings?.disabled"
                        >
                            <mat-option
                                *ngFor="let tag of tags"
                                [value]="tag"
                                (click)="unselectDeactivatedTag(tag)"
                                [disabled]="tag.deactivated"
                                [matTooltip]="
                                    tag.deactivated ? 'This Tag couldn\'t be selected because it\'s deactivated' : ''
                                "
                            >
                                <span>{{ tag.title }}</span>
                                <span class="font-italic ml-1 text-muted">(category - {{ tag.category_title }})</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!------- Auto-Tags ------->
                <div *ngIf="autoTagsEnabledFlag">
                    <span>Auto-generated Tags: </span>
                    <button
                        mat-icon-button
                        matTooltip="Restore auto-tags for this deal"
                        matTooltipPosition="after"
                        [disabled]="dealFormGroup.controls.taggings?.disabled"
                        (click)="resetAutoTags()"
                    >
                        <mat-icon>restore</mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        matTooltip="Delete auto-tags for this deal"
                        matTooltipPosition="after"
                        [disabled]="dealFormGroup.controls.taggings?.disabled"
                        (click)="deleteAutoTags()"
                    >
                        <mat-icon>delete_outline</mat-icon>
                    </button>

                    <mat-chip-list *ngIf="autoTags.length">
                        <ng-container *ngFor="let tag of autoTags; let idx = index">
                            <mat-chip (removed)="doRemoveAutoTag(idx, tag)">
                                <span>{{ tag.title }}</span>
                                <span class="font-italic ml-1 text-muted">(category - {{ tag.category_title }})</span>
                                <mat-icon class="mx-1" matChipRemove *ngIf="!dealFormGroup.controls.taggings.disabled"
                                    >cancel</mat-icon
                                >
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>

                    <ng-container *ngIf="!autoTags.length">
                        <div class="text-muted font-italic">No applied Auto-tags</div>
                    </ng-container>
                </div>
            </div>

            <div class="col-md-6">
                <app-deal-participants
                    [transactionType]="dealFormGroup.get('type')?.value || undefined"
                    [dealClientType]="dealFormGroup.get('client_type')?.value || undefined"
                    [dealParticipants]="
                        dealFormGroup.get('deal_participants')?.value || {
                            buyer_agents: null,
                            seller_agents: null,
                            escrow_agent: null,
                            buyers: null,
                            sellers: null,
                            referring_from_agent: null,
                            referring_to_agent: null,
                            others: null,
                            commission_payer: null
                        }
                    "
                    [dealId]="dealFormGroup.get('id')?.value || undefined"
                    [dealFormGroup]="dealFormGroup"
                    (updateDealTransactionTypeEvent)="updateDealTransactionType($event)"
                    (updateParticipants)="updateParticipants($event)"
                    (updateDealClientTypeEvent)="updateDealClientType($event)"
                >
                </app-deal-participants>
            </div>
        </div>
    </mat-card-content>
</mat-card>
