import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, FormArray, Validators, AbstractControl} from '@angular/forms';
import {ICreditNote, ICreditNoteItem, ICreditItem, IProduct, IGroup} from '@cyberco-nodejs/zipi-typings';
import {of, pipe, Subject} from 'rxjs';
import {NotificationsService} from 'angular2-notifications';
import {select, Store} from '@ngrx/store';
import {catchError, filter, map, takeUntil, tap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {IFinanceState} from '../../../store/finance.reducer';
import {FetchProducts} from '../../../store/finance.actions';
import {selectProducts} from '../../../store/finance.selectors';
import {CreateProductDialogComponent} from '../../products/create-product-dialog/create-product-dialog.component';
import {CreditNotesService} from 'app/services/api/finance/credit-notes.service';
import {GenericFormArray} from 'app/entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {GroupsSource} from 'app/services/sources/groups.source';
import {FormGroupArray, FormGroupWithFormControls} from '../../../../../typings/common';
import {currencyMaskitoOptions, unmaskCurrencyControlValue} from '../../../../../utilities/maskito';
import {formatToDate} from '../../../../../utilities';

@Component({
    selector: 'app-edit-credit-note',
    templateUrl: 'edit-credit-note.component.html',
    styleUrls: ['edit-credit-note.component.scss']
})
export class EditCreditNoteComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    itemsArray: FormGroupArray = this.fb.array([this.createItem()]) as FormGroupArray;
    formGroup: FormGroupWithFormControls = this.fb.group({
        customer__contact_fk_id: [null, [Validators.required]],
        credit_note_number: [null],
        reference: [null],
        credit_note_date: [new Date(), Validators.required],
        sales_person__profile_fk_id: null, // Sales Person

        total_amount: [{value: 0, disabled: true}],
        items: this.itemsArray,

        allow_auto_apply: [false, Validators.required]
    }) as FormGroupWithFormControls;
    emptyItem: ICreditItem = {
        rate: 0,
        quantity: 1,
        amount: 0,
        product_fk_id: null,
        name: null,
        description: null,
        ledger_account_fk_id: null,
        connected__deal_fk_id: null,
        connected__financial_transfer_fk_id: null,
        deal_connection_mode: null,
        order_index: null,
        division__company_group_fk_id: null
    };
    currencyMaskitoMask = currencyMaskitoOptions;

    amountWithCurrencyMask: boolean[] = [];
    rateWithCurrencyMask: boolean[] = [];

    creditNote: ICreditNote | undefined;
    disableEditItems: boolean = false;

    products: IProduct[] = [];

    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    savedContacts: Array<number> = [];
    selectedProfileArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    selectedProfileIds: number[] = [];

    groupList: IGroup[] = [];

    constructor(
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private store: Store<IFinanceState>,
        private creditNotesService: CreditNotesService,
        private groupSrc: GroupsSource
    ) {}

    private validateForm() {
        if (!this.formGroup.valid) {
            this.ntfs.warn('Credit note is not valid');
            return false;
        }

        return true;
    }

    private createItem(item?: ICreditNoteItem, itemIndex: number = 0) {
        const group = this.fb.group({
            credit_note_item_id: [item ? item.credit_note_item_id : null],
            product_fk_id: [item ? item.product_fk_id : null],
            name: [{value: item ? item.name : null, disabled: item && item.product_fk_id}, Validators.required],
            description: item ? item.description : null,
            quantity: [1, Validators.required],
            rate: [0, Validators.required],
            amount: [{value: 0, disabled: true}],
            ledger_account_fk_id: [
                {
                    value: item ? item.ledger_account_fk_id : null,
                    disabled: item && item.product_fk_id
                },
                Validators.required
            ],
            connected__deal_fk_id: [item ? item.connected__deal_fk_id : null, []],
            connected__financial_transfer_fk_id: [item ? item.connected__financial_transfer_fk_id : null, []],
            deal_connection_mode: [item ? item.deal_connection_mode : null, []],
            order_index: [item && item.order_index],
            division__company_group_fk_id: [item ? item.division__company_group_fk_id : null, []],
            selected_divisions: new GenericFormArray<ChipNode>([]),
            selected_division_ids: [item ? item.division__company_group_fk_id : null],
            selected_full_ledger: null
        });

        group.controls.selected_divisions.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (changes && changes.length > 0) {
                group.controls.division__company_group_fk_id.setValue(changes[0].target_id);
            } else {
                group.controls.division__company_group_fk_id.setValue(null);
            }
        });
        const handleValueChanges = pipe(
            takeUntil(this.unsubscribe),
            tap(() => group.controls.amount.patchValue(group.controls.quantity.value * group.controls.rate.value)),
            tap(() => {
                this.amountWithCurrencyMask[itemIndex] = String(group.controls.amount.value).length < 13;
            })
        );

        const unmaskRateFn = unmaskCurrencyControlValue(group.controls.rate);

        group.controls.quantity.valueChanges.pipe(handleValueChanges, takeUntil(this.unsubscribe)).subscribe();
        group.controls.rate.valueChanges
            .pipe(
                unmaskRateFn,
                handleValueChanges,
                tap(() => {
                    this.rateWithCurrencyMask[itemIndex] = String(group.controls.rate.value).length < 13;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
        if (item) {
            group.patchValue(item);
        }
        return group;
    }

    addItem(item?: ICreditNoteItem) {
        this.itemsArray.push(this.createItem(item, this.itemsArray.length));
    }

    selectItem(item: AbstractControl, product: IProduct) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.controls.product_fk_id.patchValue(product.product_id);
        itemGroup.controls.rate.patchValue(product.price);
        itemGroup.controls.description.patchValue(product.description);
        itemGroup.controls.ledger_account_fk_id.patchValue(product.ledger_account_fk_id);
        itemGroup.controls.quantity.patchValue(1);
        itemGroup.controls.name.patchValue(product.name);

        itemGroup.controls.ledger_account_fk_id.disable();
        itemGroup.controls.name.disable();
    }

    removeProductFkId(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.controls.ledger_account_fk_id.enable();
        itemGroup.controls.name.enable();
        itemGroup.controls.name.patchValue('');
        itemGroup.controls.product_fk_id.patchValue(null);
    }

    resetItem(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.reset(this.emptyItem);
        itemGroup.controls.name.enable();
    }

    private computeTotal = () => {
        const total = this.itemsArray.controls.reduce<number>((acc: number, item: AbstractControl) => {
            const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
            return itemGroup.getRawValue().amount + acc;
        }, 0);
        this.formGroup.controls.total_amount.patchValue(total);
    };

    getProducts(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        if (itemGroup.getRawValue().name) {
            const filteredValue = itemGroup.getRawValue().name.toLowerCase().trim();
            return this.products.filter((pr) => pr.name.toLowerCase().trim().includes(filteredValue));
        }

        return this.products;
    }

    createProduct() {
        const dialogRef = this.dialog.open(CreateProductDialogComponent);

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    createCreditNoteAsDraft() {
        if (this.validateForm() !== true) {
            return;
        }

        const creditNote: ICreditNote = this.formGroup.getRawValue();
        creditNote.items = this.itemsArray.getRawValue().map((item, idx) => {
            return {...item, rate: Number(item.rate), order_index: idx + 1};
        });

        this.creditNotesService
            .createCreditNoteAsDraft(creditNote)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                if (res) {
                    this.ntfs.info(`Credit Note created`);
                    this.router.navigate(['/sales/creditnotes']);
                }
            });
    }

    createCreditNoteAsOpen() {
        if (this.validateForm() !== true) {
            return;
        }

        const creditNote: ICreditNote = this.formGroup.getRawValue();
        creditNote.items = this.itemsArray.getRawValue().map((item, idx) => {
            return {...item, rate: Number(item.rate), order_index: idx + 1};
        });

        this.creditNotesService
            .createCreditNoteAsOpen(creditNote)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                if (res) {
                    this.ntfs.info(`Credit Note created`);
                    this.router.navigate(['/sales/creditnotes']);
                }
            });
    }

    updateCreditNote() {
        if (this.validateForm() !== true) {
            return;
        }
        const creditNote = this.formGroup.getRawValue();

        if (
            this.creditNote &&
            this.creditNote.total_amount !== creditNote.total_amount &&
            this.creditNote.payments &&
            this.creditNote.payments.length > 0
        ) {
            this.ntfs.warn(`Amount of Credit Note with payments cannot be changed.`);
            return;
        }

        const data: any = {
            customer__contact_fk_id: creditNote.customer__contact_fk_id,
            credit_note_number: creditNote.credit_note_number,
            reference: creditNote.reference,
            credit_note_date: creditNote.credit_note_date,
            sales_person__profile_fk_id: creditNote.sales_person__profile_fk_id,
            items: creditNote.items.map((item: ICreditNoteItem, idx: number) => {
                return {...item, rate: Number(item.rate), order_index: idx + 1};
            }),
            total_amount: creditNote.total_amount,
            allow_auto_apply: creditNote.allow_auto_apply,
            credit_note_id: this.creditNote ? this.creditNote.credit_note_id : null
        };

        this.creditNotesService
            .updateCreditNote(data)
            .pipe(
                catchError((err) => of(null)),
                filter((act) => !!act),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.ntfs.info(`Credit Note updated`);
                this.router.navigate(['/sales/creditnotes']);
            });
    }

    deleteCreditNote() {
        if (this.creditNote && this.creditNote.credit_note_id) {
            this.creditNotesService
                .deleteCreditNote(this.creditNote.credit_note_id)
                .pipe(
                    catchError((err) => of(null)),
                    filter((act) => !!act),
                    takeUntil(this.unsubscribe)
                )
                .subscribe(() => {
                    this.ntfs.warn(`Credit Note deleted`);
                    this.router.navigate(['/sales/creditnotes']);
                });
        }
    }

    initEdit() {
        this.route.paramMap
            .pipe(
                map((pm) => {
                    const stringId: string | null = pm.get('id');
                    return Number(stringId);
                }),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                this.creditNotesService
                    .getCreditNote(id)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((cn) => {
                        if (!cn) {
                            return;
                        }
                        this.creditNote = cn;
                        this.formGroup.patchValue(this.creditNote);
                        this.itemsArray.removeAt(0);
                        if (this.creditNote.items) {
                            this.creditNote.items.forEach((item) => this.addItem(item));
                        }

                        if (this.creditNote.customer__contact_fk_id) {
                            this.savedContacts = [this.creditNote.customer__contact_fk_id];
                        }
                        if (this.creditNote && this.creditNote.sales_person__profile_fk_id) {
                            this.selectedProfileIds = [this.creditNote.sales_person__profile_fk_id];
                        }

                        if (this.creditNote.journal && this.creditNote.journal.line_items) {
                            for (const tr of this.creditNote.journal.line_items) {
                                if (!!tr.reconciliation_fk_id || !!tr.matched__transaction_external_id) {
                                    this.disableEditItems = true;
                                    this.itemsArray.disable();
                                    break;
                                }
                            }
                        }
                    });
            });
    }

    ngOnInit() {
        this.store.dispatch(new FetchProducts());
        this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe)).subscribe((data) => {
            this.products = data;
        });

        this.initEdit();

        this.formGroup.controls.credit_note_date.patchValue(
            formatToDate(this.formGroup.controls.credit_note_date.value)
        );

        this.itemsArray.valueChanges
            .pipe(
                tap(() => this.computeTotal()),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.moneySenderCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            if (values && values.length > 0) {
                // this.selectMoneySender(values[0].target_id);
                this.formGroup.controls.customer__contact_fk_id.patchValue(values[0].target_id);
            } else {
                // this.deselectCustomer();
                this.formGroup.controls.customer__contact_fk_id.patchValue(null);
            }
        });

        this.selectedProfileArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: any[]) => {
            if (values && values.length > 0) {
                if (values[0].type === 'individual') {
                    this.formGroup.controls.sales_person__profile_fk_id.setValue(values[0].target_id);
                }
            } else {
                this.formGroup.controls.sales_person__profile_fk_id.reset(null, {emitEvent: false});
            }
        });

        this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
            this.groupList = list.filter((group) => group.type === 'division') as IGroup[];
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
