import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {JournalsService} from 'app/services/api/finance/journals.service';
import {NotificationsService} from 'angular2-notifications';
import {IScrollData} from 'app/models/scroll-data';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {IJournal} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-journals',
    templateUrl: 'journals.component.html',
    styleUrls: ['./journals.component.scss', '../../../../../../assets/infinite-scroll-table.scss']
})
export class JournalsComponent implements OnInit, OnDestroy, AfterViewInit {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();

    @ViewChild(MatSort, {static: false}) sort: MatSort = new MatSort();
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator = new MatPaginator(
        new MatPaginatorIntl(),
        ChangeDetectorRef.prototype
    );

    @Input() filterHeader: any;
    @Input() updateList$: Subject<any> = new Subject<any>();
    @Output() journalCreateEmitter: EventEmitter<IJournal> = new EventEmitter<IJournal>();

    scrollData: IScrollData = {
        sort_column: 'date',
        sort_direction: 'desc',
        limit: 50,
        offset: 0,
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<IJournal>;
    displayedColumns = ['date', 'journal_number', 'reference_number', 'status', 'line_item_total', 'notes'];

    constructor(
        private journalsService: JournalsService,
        private ntfs: NotificationsService
    ) {
        this.dataSource = new MatTableDataSource<IJournal>([]);
    }

    ngOnInit() {
        this.getJournals();

        this.updateList$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.getJournals();
        });
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.scrollData.limit = data.pageSize;
            this.scrollData.offset = data.pageSize * data.pageIndex;

            this.getJournals();
        });
    }

    getJournals() {
        this.unsubscribeBatch.next();

        this.journalsService
            .getJournals(this.scrollData)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.dataSource.data = data.result.slice();
                this.scrollData.total = data._meta.total;
            });
    }

    publishManualJournal(mj: IJournal) {
        this.journalsService
            .updateJournal({
                ...mj,
                status: 'published'
            })
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.ntfs.info(`Journal published`);
            });
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        this.paginator.pageIndex = 0;
        this.scrollData.offset = 0;
        this.getJournals();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
        this.updateList$.complete();
        this.journalCreateEmitter.complete();
    }
}
