import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {Observable} from 'rxjs';
import {IReconciliation} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class ReconciliationsService {
    private url = '/finance/reconciliations/';

    constructor(public requester: ServiceRequester) {}

    getAccountReconciliations(ledgerAccountId: number): Observable<IReconciliation[]> {
        return this.requester.makeMsCall$(this.url + `${ledgerAccountId}/account`, 'GET', 'shipp');
    }

    getLastAccountReconciliation(ledgerAccountId: number): Observable<IReconciliation> {
        return this.requester.makeMsCall$(this.url + `${ledgerAccountId}/last`, 'GET', 'shipp');
    }

    getAccountReconciliationById(reconciliationId: number): Observable<IReconciliation> {
        return this.requester.makeMsCall$(this.url + `${reconciliationId}`, 'GET', 'shipp');
    }

    createReconciliation(data: {
        transactions_ids: number[];
        reconciliation: IReconciliation;
    }): Observable<IReconciliation> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    editReconciliation(
        reconciliation_id: number,
        data: {transactions_ids: number[]; reconciliation: IReconciliation}
    ): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + reconciliation_id, 'PUT', 'shipp', data);
    }

    undoReconciliation(reconciliation_id: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${reconciliation_id}/unreconciled`, 'PUT', 'shipp');
    }

    deleteReconciliation(reconciliation_id: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + reconciliation_id, 'DELETE', 'shipp');
    }
}
