import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IValuesGroup} from '@cyberco-nodejs/zipi-typings';
import {ServiceRequester} from '../../service.requester';

@Injectable()
export class ValuesGroupsService {
    constructor(public requester: ServiceRequester) {}

    private url = '/analytics/values-groups/';

    getCompanyValuesGroups(): Observable<IValuesGroup[]> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp');
    }

    createValuesGroup(data: IValuesGroup): Observable<IValuesGroup> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    getValuesGroupById(id: number): Observable<IValuesGroup | null> {
        return this.requester.makeMsCall$(this.url + id, 'GET', 'shipp');
    }

    updateValuesGroupById(id: number, data: Partial<IValuesGroup>): Observable<IValuesGroup> {
        return this.requester.makeMsCall$(this.url + id, 'PUT', 'shipp', data);
    }

    deleteValuesGroupById(id: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + id, 'DELETE', 'shipp');
    }
}
