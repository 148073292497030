import {Injectable} from '@angular/core';
import {ServiceRequester} from './service.requester';
import {Deal} from '../models/deal';
import {DealsBoard} from '../modules/deals/components/deal/common/deal.models';
import {Profile} from '../models/profile';
import {SessionService} from './session.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {IFinancialTransfer} from '@cyberco-nodejs/zipi-typings';
import {IScrollData} from '../models/scroll-data';
import {DealAffectedBy} from '../models/deal-affected-by';
import {DealSelfTestingEntity} from '../models/deal-self-testing';
import {CommissionInstruction} from '../models/commission-instruction';
import {AuthService} from './auth.service';
import {RbacService} from '../modules/rbac/rbac.service';
import {IRelatedDealPayoutInfo} from '@app/modules/finance/types/financial.types';

@Injectable()
export class DealService {
    private url = '/deals/';

    dealCache: BehaviorSubject<Deal | null> = new BehaviorSubject<Deal | null>(null);
    constructor(
        private authService: AuthService,
        private rbacService: RbacService,
        public requester: ServiceRequester,
        public sessionService: SessionService
    ) {}

    resetDealToDefaults(deal: Deal): Promise<Deal> {
        return this.requester.makeMsCall('/deals/reset', 'POST', deal).then((foundDeal: Deal) => {
            foundDeal = Object.assign(new Deal(), foundDeal);
            if (foundDeal.close_of_escrow) {
                foundDeal.close_of_escrow = new Date(foundDeal.close_of_escrow);
            }

            if (foundDeal.first_contacted) {
                foundDeal.first_contacted = new Date(foundDeal.first_contacted);
            } else {
                foundDeal.first_contacted = null;
            }

            if (foundDeal.last_contacted) {
                foundDeal.last_contacted = new Date(foundDeal.last_contacted);
            } else {
                foundDeal.last_contacted = null;
            }
            const __deal = Deal.FABRIC(Deal).hydrate!(foundDeal);
            return __deal;
        });
    }

    applySplitsToDeal(deal: Deal): Promise<Deal> {
        return this.requester.makeMsCall('/deals/apply-splits', 'POST', deal).then((foundDeal) => {
            foundDeal = Object.assign(new Deal(), foundDeal);
            if (foundDeal.close_of_escrow) {
                foundDeal.close_of_escrow = new Date(foundDeal.close_of_escrow);
            }

            if (foundDeal.first_contacted) {
                foundDeal.first_contacted = new Date(foundDeal.first_contacted);
            } else {
                foundDeal.first_contacted = null;
            }

            if (foundDeal.last_contacted) {
                foundDeal.last_contacted = new Date(foundDeal.last_contacted);
            } else {
                foundDeal.last_contacted = null;
            }
            const __deal = Deal.FABRIC(Deal).hydrate!(foundDeal);
            return __deal;
        });
    }

    runSelfTests(deal: Deal): Promise<DealSelfTestingEntity> {
        return this.requester.makeMsCall('/deals/run-self-tests', 'POST', deal).then((rawTestsResult) => {
            rawTestsResult = Object.assign(new Deal(), rawTestsResult);
            return rawTestsResult;
        });
    }

    getOne(id: number): Promise<Deal> {
        return this.requester.makeMsCall(this.url + id).then((foundDeal) => {
            foundDeal = Object.assign(new Deal(), foundDeal);
            if (foundDeal.close_of_escrow) {
                foundDeal.close_of_escrow = new Date(foundDeal.close_of_escrow);
            }

            if (foundDeal.first_contacted) {
                foundDeal.first_contacted = new Date(foundDeal.first_contacted);
            } else {
                foundDeal.first_contacted = null;
            }

            if (foundDeal.last_contacted) {
                foundDeal.last_contacted = new Date(foundDeal.last_contacted);
            } else {
                foundDeal.last_contacted = null;
            }
            return Deal.FABRIC(Deal).hydrate!(foundDeal);
        });
    }

    getViewDeal(id: number): Promise<Deal> {
        return this.requester.makeMsCall(this.url + id + '/view').then((foundDeal) => {
            foundDeal = Object.assign(new Deal(), foundDeal);
            if (foundDeal.close_of_escrow) {
                foundDeal.close_of_escrow = new Date(foundDeal.close_of_escrow);
            }

            if (foundDeal.first_contacted) {
                foundDeal.first_contacted = new Date(foundDeal.first_contacted);
            } else {
                foundDeal.first_contacted = null;
            }

            if (foundDeal.last_contacted) {
                foundDeal.last_contacted = new Date(foundDeal.last_contacted);
            } else {
                foundDeal.last_contacted = null;
            }
            return Deal.FABRIC(Deal).hydrate!(foundDeal);
        });
    }

    getDealBaseView(id: number): Promise<Deal> {
        return this.requester.makeMsCall(this.url + id + '/base-view');
    }

    getFinancialTransfersByInvoiceId(invoiceId: number): Promise<IRelatedDealPayoutInfo[]> {
        return this.requester.makeMsCall(`/financial-transfer/invoice/${invoiceId}`, 'GET');
    }

    getFinancialTransfersByBillId(billId: number): Promise<IRelatedDealPayoutInfo[]> {
        return this.requester.makeMsCall(`/financial-transfer/bill/${billId}`, 'GET');
    }

    getFinancialTransfersByPaymentReceivedId(paymentReceivedId: number): Promise<IFinancialTransfer[]> {
        return this.requester.makeMsCall(`/financial-transfer/payment/${paymentReceivedId}`, 'GET');
    }

    async checkIfEditDealAllowed(primarySalesEntityProfileId: number | null | undefined) {
        const currentProfile = await this.authService.getCurrentProfile();

        if (!currentProfile) {
            return false;
        }

        if (
            (await this.rbacService.isAllowed({deals__edit_any: true})) ||
            (primarySalesEntityProfileId === currentProfile.profile_id &&
                (await this.rbacService.isAllowed({deals__edit_own: true})))
        ) {
            return true;
        } else {
            return false;
        }
    }

    getDealsFilteredList(
        dealsBoardId: number,
        data: {
            offset: number;
            limit: number;
            statuses: string[];
            types: string[];
            property_classes: string[];
            disbursement_statuses: string[];
            meta_is_needed: number;
        }
    ): Promise<{data: Deal[]; _meta: {[key: string]: number} | null}> {
        return this.requester.makeMsCallGlobalAllowed(
            this.url + 'boards/' + dealsBoardId + '/filtered-list/',
            'POST',
            data
        );
    }

    create(data: Deal): Promise<Deal> {
        return this.requester.makeMsCall(this.url, 'POST', {
            deal_data: data
            // calculation_data: calculationData,
        });
    }

    update(id: number, data: Deal): Promise<boolean> {
        return this.requester.makeMsCall(this.url + id, 'PUT', {
            deal_data: data,
            calculation_data: null
        });
    }

    createDualRelationship(targetDealId: number, data: {creation_mode: string; deal_id: number}) {
        return this.requester.makeMsCall(this.url + 'dual-deal/' + targetDealId, 'POST', data);
    }

    divideDuals(id: number, dualDealId: number) {
        return this.requester.makeMsCall(this.url + id + '/divide-dual', 'PUT', {
            dual_deal_id: dualDealId
        });
    }

    bulkDelete(ids: number[]) {
        return this.requester.makeMsCall(this.url + 'bulk-delete', 'POST', {
            id: ids
        });
    }

    // TODO: create endpoint at backend-
    // getContactDeals(contactId: number): Observable<Deal[]> {
    //     return this.requester.makeMsCall$(this.url + 'contact/' + contactId, 'GET');
    // }

    recalculateDeals(): Promise<boolean> {
        return this.requester.makeMsCall('/calculations/recalculate-deals', 'POST');
    }

    archiveDeals(ids: number[]): Promise<boolean> {
        return this.requester.makeMsCall('/deals/archive', 'POST', {
            id: ids
        });
    }

    getDealsBoardsList(): Promise<DealsBoard[]> {
        return this.requester.makeMsCallGlobalAllowed(this.url + 'boards', 'GET').then((list) =>
            list.map((dealsBoard: DealsBoard) => {
                let profiles = [];
                if (this.sessionService.profile!.type === Profile.type.global) {
                    profiles = this.sessionService.availableProfiles!.filter(
                        (profile) => profile.type === Profile.type.default
                    );
                } else {
                    profiles.push(this.sessionService.profile);
                }
                return Object.assign(new DealsBoard(), dealsBoard).setupBackendSettings(profiles as Profile[]);
            })
        );
    }

    createDealsBoard(data: object): Promise<DealsBoard> {
        return this.requester.makeMsCallGlobalAllowed(this.url + 'boards', 'POST', data);
    }

    updateDealsBoard(dealsBoardId: number, data: object): Promise<DealsBoard> {
        return this.requester.makeMsCallGlobalAllowed(this.url + 'boards/' + dealsBoardId, 'PUT', data);
    }

    deleteDealsBoard(dealsBoardId: number): Promise<boolean> {
        return this.requester.makeMsCallGlobalAllowed(this.url + 'boards/' + dealsBoardId, 'DELETE');
    }

    getAudits(dealId: number) {
        return this.requester.makeMsCall(this.url + dealId + '/audit/', 'GET');
    }

    getProfileAudits(profileId: number) {
        return this.requester.makeMsCall(this.url + 'audit-profile/' + profileId, 'GET');
    }

    getDealsByContact(contactId: number, data: IScrollData): Promise<{_meta: {total: number}; result: Deal[]}> {
        return this.requester.makeMsCall(this.url + contactId + '/list/', 'GET', data);
    }

    // Alex: functionality of this endpoint was moved to CompensationServiceApi.createOverrideCompensationProfile and
    // CompensationServiceApi.deleteOverrideCompensationProfile endpoints
    updateOverrideSalesEntity(
        salesEntityId: number,
        data: {overriden__compensation_profile_fk_id: number | null}
    ): Promise<boolean> {
        return this.requester.makeMsCall('/deals/sales-entity/' + salesEntityId + '/override', 'PUT', data);
    }

    updateDealStatus(id: number, data: {status: string}): Promise<boolean> {
        return this.requester.makeMsCall(`${this.url}${id}/update-status`, 'PUT', data);
    }

    getBaseDealsByIds(ids: number[]): Promise<
        {
            id: number;
            type: string;
            address: string;
            street_number: string;
            status: string;
            name: string;
        }[]
    > {
        return this.requester.makeMsCall(`${this.url}base-deals`, 'POST', ids);
    }

    dismissAffectedBy(dealId: number): Promise<DealAffectedBy[]> {
        return this.requester.makeMsCall(`${this.url}dismiss-affected-by/${dealId}`, 'PUT');
    }

    dismissSystemNote(dealId: number, data: {system_key: string; ids: number[]}): Promise<any> {
        return this.requester.makeMsCall(`${this.url}dismiss-system-note/${dealId}`, 'PUT', data);
    }

    toggleTextDealParticipantVisibility(
        dealId: number,
        deapParticipantId: number,
        is_ignored: boolean
    ): Promise<boolean> {
        return this.requester.makeMsCall(
            `${this.url}${dealId}/deal-participant-visibility/${deapParticipantId}?is_ignored=${Number(is_ignored)}`,
            'PUT'
        );
    }

    saveApprovedDeal(dealId: string, data: {[key: string]: any}): Promise<boolean> {
        return this.requester.makeMsCall(`${this.url}${dealId}/update-approved-deal`, 'PUT', {data});
    }

    getCommissionInstructions(dealId: number, token: string | undefined): Promise<CommissionInstruction[]> {
        let body: any = {};
        if (token) {
            body.token = token;
        }
        return this.requester.makeMsCall(`${this.url}${dealId}/commission-instructions/list`, 'GET', body);
    }

    addCommissionInstruction(
        dealId: number,
        data: {[key: string]: any},
        token: string
    ): Promise<CommissionInstruction> {
        return this.requester.makeMsCall(`${this.url}${dealId}/commission-instructions/add`, 'POST', {...data, token});
    }

    orderPayStubGeneration(dealIds: Array<number>): Observable<boolean> {
        return this.requester.makeMsCall$(`/pay-stub/order-generation/deals`, 'POST', 'zipi', {deal_ids: dealIds});
    }
}
