import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {ContactPartLink} from '../../../../../../models/contact-part-link';
import {FinancialTransferEntity} from '../../../../../account-info/compensation/models/financial-transfer.entity';
import {GenericFormArray, GenericFormGroup} from '../../../../../../entites/generic.entity';
import {MatDialog} from '@angular/material/dialog';
import {OverrideDialogComponent} from '../../../../../shared/components/money-collection/override.dialog.component';
import {ConfirmComponent} from '../../../../../../layouts/confirm/confirm.component';
import {takeUntil} from 'rxjs/operators';
import {IContactPartLink} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-deal-transfers-collection',
    templateUrl: './deal-transfers-collection.component.html',
    styleUrls: ['./deal-transfers-collection.component.scss']
})
export class DealTransfersCollectionComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() mode: 'view' | 'edit' = 'edit';
    @Input() isOverride: boolean = false;
    @Input() defaultSender: IContactPartLink | null = null;
    @Input() defaultReceiver: IContactPartLink | null = null;
    @Input() defaultSenderWildcardId: number | null = null;
    @Input() defaultReceiverWildcardId: number | null = null;
    @Input() defaultValueType: string = FinancialTransferEntity.value_type_SET.flat_fee;
    @Input() hideSender: boolean = false;
    @Input() hideReceiver: boolean = false;
    @Input() hidePayAtEscrowFlag: boolean = false;
    @Input() hideDisbursementInstructionsFlag: boolean = false;
    @Input() isSalesEntityAgentCardContext: boolean = false;
    @Input() hideProduct: boolean = false;
    @Input() hideLabel: boolean = false;
    @Input() hideAmount: boolean = false;
    @Input() hideValueType: boolean = false;
    @Input() hideRemoveButton: boolean = false;
    @Input() receiverLabel: string = 'Receiver Contact';
    @Input() senderLabel: string = 'Sender Contact';
    @Input() limit: number = 0;
    @Input() showAddButton: boolean = false;
    @Input() exclude: ContactPartLink | null = null;
    @Input() type: string = FinancialTransferEntity.type_SET.income;
    @Input() isExpense: boolean = false;
    @Input() allowPercents: boolean = true;
    @Input() silent: boolean = false;
    @Input() title: string | null = null;
    @Input() buttonLabel: string | null = null;
    @Input() transfersFA: GenericFormArray<FinancialTransferEntity> = new GenericFormArray<FinancialTransferEntity>([]);
    @Input() disabled: boolean = false;
    @Input() showOnlyAmount: boolean = false;
    @Input() availableValueTypes: string[] = ['percent', 'flat_fee'];
    @Input() origin: string = 'default';
    @Input() enableDisbursementInstructionsToggle: boolean = true;

    @Output() addItem = new EventEmitter<GenericFormGroup<FinancialTransferEntity>>();
    @Output() deleteItem = new EventEmitter<GenericFormGroup<FinancialTransferEntity>>();
    FINANCIAL_TRANSFER = FinancialTransferEntity;

    constructor(protected dialog: MatDialog) {}

    onClick() {
        const dialogRef = this.dialog.open(OverrideDialogComponent);
    }

    onNotEnforcedOverlayClick(transferFG: GenericFormGroup<FinancialTransferEntity>) {
        if (
            transferFG.controls.unique_hash!.value === 'NO-HASH' &&
            [
                FinancialTransferEntity.type_SET.agent_split,
                FinancialTransferEntity.type_SET.company_split_individual
            ].includes(transferFG.controls.type!.value)
        ) {
            transferFG.controls.is_modified_by_user!.patchValue(true);
        } else {
            const confirmDialogRef = this.dialog.open(ConfirmComponent, {
                minWidth: 320,
                minHeight: 160,
                data: {
                    title: `Edit Financial Transfer`,
                    messages: [
                        `This financial transfer was added from the Not Enforced compensation profile and can be edited.`,
                        `Do you want to unlock and update it?`
                    ],
                    buttonOkMessage: `Unlock`
                }
            });

            confirmDialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (result) {
                        transferFG.controls.is_modified_by_user!.patchValue(true);
                    }
                });
        }
    }

    addAdditional() {
        const newFT = new FinancialTransferEntity()
            .setType(this.type)
            .setOrigin(this.origin)
            .setOriginIsExpense(this.isExpense);
        if (this.defaultSender) {
            newFT.setSender(this.defaultSender);
        }
        if (this.defaultSenderWildcardId) {
            newFT.setSenderWildcardId(this.defaultSenderWildcardId);
        }
        if (this.defaultReceiver) {
            newFT.setReceiver(this.defaultReceiver);
        }
        if (this.defaultReceiverWildcardId) {
            newFT.setReceiverWildcardId(this.defaultReceiverWildcardId);
        }
        if (this.defaultValueType) {
            newFT.setValueType(this.defaultValueType);
        }

        const newFG = new GenericFormGroup(newFT);

        if (!this.silent) {
            this.transfersFA.push(newFG);
        }

        this.addItem.emit(newFG);
    }

    removeAdditional(index: number) {
        this.deleteItem.emit(<GenericFormGroup<FinancialTransferEntity>>this.transfersFA.at(index));
    }

    ngOnInit() {
        if (this.limit === 1 && this.transfersFA.controls.length < 1) {
            this.addAdditional();
        }
    }

    isDisabledDelete(financialTransfer: GenericFormGroup<FinancialTransferEntity>, disabled: boolean) {
        if (financialTransfer.value.connected__invoice_fk_id || financialTransfer.value.connected__bill_fk_id) {
            return false;
        }
        return (financialTransfer.disabled || disabled) && !financialTransfer.value.payment_fk_id;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.addItem.complete();
        this.deleteItem.complete();
    }
}
