<div *ngIf="uploads">
    <div class="d-flex align-items-center w-100 justify-content-between buttons" style="height: 60px">
        <!------- Button board list ------->
        <h3 class="mb-0">System Data</h3>

        <div class="mr-2 d-flex align-items-center" style="justify-content: end">
            <button
                mat-raised-button
                color="primary"
                class="fix-focus-outline"
                [matMenuTriggerFor]="menu"
                style="margin-right: 20px"
            >
                Download Templates
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="downloadUsers('/assets/example-files/profiles_import_template.csv')">
                    Profiles
                    <mat-icon [matTooltip]="'Agents and Staff'" class="info-icon">info_outline</mat-icon>
                </button>
                <button mat-menu-item (click)="downloadContacts('/assets/example-files/contacts_import_template.csv')">
                    Contacts
                    <mat-icon [matTooltip]="'Customers, Vendors and Partners'" class="info-icon">info_outline</mat-icon>
                </button>
                <button
                    mat-menu-item
                    (click)="downloadDeals('/assets/example-files/historical_deals_import_example.csv')"
                >
                    Processed Deals
                </button>
                <button mat-menu-item (click)="downloadInvoices('/assets/example-files/InvoicesImportExampleForm.csv')">
                    Invoices
                </button>
                <button
                    mat-menu-item
                    (click)="downloadAccounts('/assets/example-files/ledger_accounts_import_template.csv')"
                >
                    Chart of Accounts
                </button>
                <button
                    mat-menu-item
                    (click)="downloadAccounts('/assets/example-files/products_services_import_template.csv')"
                >
                    Products/Services
                </button>
            </mat-menu>
            <h3 class="mb-0 d-flex align-items-center action-icon" [matMenuTriggerFor]="menuUploads">
                <span>Import/Export</span>
                <span class="material-icons d-flex align-self-center">arrow_drop_down</span>
            </h3>
            <mat-menu #menuUploads="matMenu">
                <button mat-menu-item (click)="$event.stopPropagation()" [matMenuTriggerFor]="importMenu">
                    Import
                    <mat-menu #importMenu="matMenu" [class]="'add-item-menu'">
                        <button mat-menu-item *ngIf="importProfilesEnabledFlag">
                            <app-import-data-button
                                [type]="'profiles'"
                                [buttonTitle]="'Profiles'"
                                (returnDataEmitter)="handleProcessData($event)"
                            >
                            </app-import-data-button>
                        </button>

                        <button mat-menu-item *ngIf="importContactsEnabledFlag">
                            <app-import-data-button
                                [type]="'contacts'"
                                [buttonTitle]="'Contacts'"
                                (returnDataEmitter)="handleProcessData($event)"
                            >
                            </app-import-data-button>
                        </button>

                        <button
                            rbac
                            mat-menu-item
                            [rule]="{deals__create_any: true}"
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            (click)="$event.stopPropagation()"
                            [matMenuTriggerFor]="dateRangeMenu"
                        >
                            <input
                                #dealFromCsv
                                type="file"
                                accept=".csv"
                                (input)="handleFileInputDeals(dealFromCsv.files)"
                                hidden
                            />
                            Deals
                            <mat-menu #dateRangeMenu="matMenu" [class]="'add-item-menu'">
                                <button mat-menu-item disabled>Active</button>
                                <button
                                    mat-menu-item
                                    (click)="dealFromCsv.click()"
                                    rbac
                                    [rule]="{deals__manage_disbursement_documents: true}"
                                    [denyMethod]="'style'"
                                    [allowStyle]="{display: 'inline-block'}"
                                    [denyStyle]="{display: 'none'}"
                                >
                                    Processed
                                </button>
                            </mat-menu>
                        </button>

                        <button
                            rbac
                            mat-menu-item
                            [rule]="{import_data: true}"
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            (click)="invoiceFromCsv.click()"
                        >
                            <input
                                #invoiceFromCsv
                                type="file"
                                accept=".csv"
                                (input)="handleFileInputInvoice(invoiceFromCsv.files)"
                                hidden
                            />
                            Invoices
                        </button>

                        <button mat-menu-item *ngIf="importCOAEnabledFlag && listOfLedgerAccounts">
                            <app-import-data-button
                                [type]="'chart_of_accounts'"
                                [buttonTitle]="'Chart of Accounts'"
                                [ledgerAccounts]="listOfLedgerAccounts"
                                (returnDataEmitter)="handleProcessData($event)"
                            >
                            </app-import-data-button>
                        </button>

                        <button mat-menu-item *ngIf="importProductsEnabledFlag && listOfLedgerAccounts">
                            <app-import-data-button
                                [type]="'products_services'"
                                [buttonTitle]="'Products/Services'"
                                [ledgerAccounts]="listOfLedgerAccounts"
                                (returnDataEmitter)="handleProcessData($event)"
                            >
                            </app-import-data-button>
                        </button>
                    </mat-menu>
                </button>

                <button
                    mat-menu-item
                    rbac
                    [rule]="{import_data: true}"
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    (click)="$event.stopPropagation()"
                    [matMenuTriggerFor]="exportMenu"
                >
                    Export
                    <mat-menu #exportMenu="matMenu" [class]="'add-item-menu'">
                        <button mat-menu-item (click)="exportProfiles()">Profiles</button>
                        <button mat-menu-item (click)="exportProductsServices()">Products/Services</button>
                        <button mat-menu-item *ngIf="exportJournalsEnabledFlag" (click)="exportJournalEntries()">
                            Journal Entries
                        </button>
                    </mat-menu>
                </button>
            </mat-menu>
        </div>
    </div>

    <!-- <div class="import-toolbar-container">
		<mat-toolbar class="import-toolbar mat-elevation-z2"></mat-toolbar>
	</div> -->

    <mat-tab-group class="px-4">
        <mat-tab label="General">
            <div class="d-flex d-sm-flex mb-2 px-3 w-100 data-table-header mt-3">
                <div class="w-20 header-text" (click)="changeSort('type')">
                    <span class="font-weight-bold">Process</span>
                    <span
                        class="material-icons arrow ml-2"
                        [ngClass]="scrollData.sort_column === 'type' ? 'd-inline' : 'd-none'"
                    >
                        <span *ngIf="scrollData.sort_direction === 'desc'" class="material-icons">arrow_downward</span>
                        <span *ngIf="scrollData.sort_direction === 'asc'" class="material-icons">arrow_upward</span>
                    </span>
                </div>
                <div class="w-20 header-text" (click)="changeSort('created_at')">
                    <span class="font-weight-bold">Executed At</span>
                    <span
                        class="material-icons arrow ml-2"
                        [ngClass]="scrollData.sort_column === 'created_at' ? 'd-inline' : 'd-none'"
                    >
                        <span *ngIf="scrollData.sort_direction === 'desc'" class="material-icons">arrow_downward</span>
                        <span *ngIf="scrollData.sort_direction === 'asc'" class="material-icons">arrow_upward</span>
                    </span>
                </div>
                <div class="w-30 header-text" (click)="changeSort('file_name')">
                    <span class="font-weight-bold">File Name</span>
                    <span
                        class="material-icons arrow ml-2"
                        [ngClass]="scrollData.sort_column === 'file_name' ? 'd-inline' : 'd-none'"
                    >
                        <span *ngIf="scrollData.sort_direction === 'desc'" class="material-icons">arrow_downward</span>
                        <span *ngIf="scrollData.sort_direction === 'asc'" class="material-icons">arrow_upward</span>
                    </span>
                </div>
                <div class="w-10 header-text" (click)="changeSort('status')">
                    <span class="font-weight-bold">Status</span>
                    <span
                        class="material-icons arrow ml-2"
                        [ngClass]="scrollData.sort_column === 'status' ? 'd-inline' : 'd-none'"
                    >
                        <span *ngIf="scrollData.sort_direction === 'desc'" class="material-icons">arrow_downward</span>
                        <span *ngIf="scrollData.sort_direction === 'asc'" class="material-icons">arrow_upward</span>
                    </span>
                </div>
                <div class="w-20 text-right">
                    <span class="font-weight-bold mr-4">Action</span>
                </div>
            </div>

            <div *ngFor="let upload of uploads; let i = index">
                <mat-card [class.cursor-pointer]="upload.status === 'prepared'" class="px-3 pt-3 pb-2 mt-2 bg-light">
                    <div
                        (click)="
                            currentUploadId === upload.id ? (currentUploadId = null) : (currentUploadId = upload.id)
                        "
                        class="d-flex align-items-baseline w-100 mb-2"
                    >
                        <div class="w-20">
                            <p *ngIf="upload.type && upload.type === 'export'">
                                Export {{ upload.data_type | fromSnakeCase }}
                            </p>
                            <p *ngIf="(upload.type && upload.type === 'import') || !upload.type">Import</p>
                        </div>

                        <div class="w-20">
                            <p>{{ upload.created_at | date: 'short' }}</p>
                        </div>

                        <div class="w-30">
                            <p
                                style="word-break: break-word; padding: 0 10px 0 0"
                                *ngIf="upload.file_path && upload.file_name"
                            >
                                {{ getUploadFileName(upload) }}
                            </p>
                            <p>&nbsp;</p>
                        </div>

                        <div class="w-10">
                            <p [ngClass]="upload.status === 'prepared' ? 'status-prepared' : 'status-imported'">
                                {{ upload.status | fromSnakeCase }}
                            </p>
                        </div>

                        <div class="w-20 text-right">
                            <a
                                mat-raised-button
                                *ngIf="upload.type === 'export'"
                                [disabled]="upload.status !== 'exported'"
                                href="{{ upload.file_path }}"
                            >
                                Download File
                            </a>

                            <div *ngIf="upload.summary">
                                <button
                                    mat-raised-button
                                    rbac
                                    [rule]="{deals__create_any: true, deals__manage_disbursement_documents: true}"
                                    [denyMethod]="'style'"
                                    [allowStyle]="{display: 'inline-block'}"
                                    [denyStyle]="{display: 'none'}"
                                    [disabled]="isImportInProcess || upload.summary.successes.length === 0"
                                    *ngIf="upload.data_type === 'deal' && upload.status === 'prepared'"
                                    (click)="
                                        $event.stopPropagation(); createEntities(upload); upload.status = 'uploading'
                                    "
                                >
                                    Create Deals
                                </button>

                                <button
                                    mat-raised-button
                                    [disabled]="
                                        upload.summary.successes.length === 0 &&
                                        upload.summary.need_updates.length === 0
                                    "
                                    *ngIf="upload.data_type === 'invoice' && upload.status === 'prepared'"
                                    (click)="$event.stopPropagation(); createEntities(upload)"
                                >
                                    Create Invoices
                                </button>

                                <button
                                    mat-raised-button
                                    class="ml-2 mt-2"
                                    (click)="$event.stopPropagation(); deleteUpload(upload)"
                                >
                                    Dismiss
                                </button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="upload.id === currentUploadId && upload.summary" class="w-100">
                        <mat-divider></mat-divider>
                        <div *ngIf="upload.data_type === 'deal'" class="d-flex align-items-baseline">
                            <div class="w-25 mt-2">
                                <strong class="d-block">Total Deals: </strong>
                                <p>
                                    {{
                                        upload.summary.successes.length +
                                            upload.summary.errors.length +
                                            upload.summary.existing.length
                                    }}
                                </p>
                            </div>

                            <div class="w-25">
                                <strong class="d-block">Valid Deals: </strong>
                                <p>{{ upload.summary.successes.length }}</p>
                            </div>

                            <div class="w-25">
                                <strong class="d-block">Invalid Deals: </strong>
                                <p>{{ upload.summary.errors.length }}</p>
                            </div>
                        </div>
                        <div *ngIf="upload.data_type === 'profile'" class="d-flex align-items-baseline">
                            <div class="w-25 mt-2">
                                <strong class="d-block">Total Rows: </strong>
                                <p>
                                    {{
                                        upload.summary.successes.length +
                                            upload.summary.errors.length +
                                            upload.summary.existing.length +
                                            upload.summary.need_updates.length
                                    }}
                                </p>
                            </div>

                            <div class="w-25">
                                <strong class="d-block">Valid Rows: </strong>
                                <p>{{ upload.summary.successes.length }}</p>
                            </div>

                            <div class="w-25">
                                <strong class="d-block">Invalid Rows: </strong>
                                <p>{{ upload.summary.errors.length }}</p>
                            </div>

                            <div class="w-25">
                                <div>
                                    <strong>Existing: </strong>
                                    <span>{{ upload.summary.existing.length }}</span>
                                </div>
                                <div>
                                    <strong>Update: </strong>
                                    <span>{{ upload.summary.need_updates?.length }}</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="upload.data_type === 'contact'" class="d-flex align-items-baseline">
                            <div class="w-25 mt-2">
                                <strong class="d-block">Total Contacts: </strong>
                                <p>
                                    {{
                                        upload.summary.successes.length +
                                            upload.summary.errors.length +
                                            upload.summary.existing.length
                                    }}
                                </p>
                            </div>

                            <div class="w-25">
                                <strong class="d-block">Valid Contacts: </strong>
                                <p>{{ upload.summary.successes.length }}</p>
                            </div>

                            <div class="w-25">
                                <strong class="d-block">Invalid Contacts: </strong>
                                <p>{{ upload.summary.errors.length }}</p>
                            </div>

                            <div class="w-25">
                                <strong class="d-block">Existing: </strong>
                                <p>{{ upload.summary.existing.length }}</p>
                            </div>

                            <div class="w-25">
                                <strong class="d-block">Need updates: </strong>
                                <p>{{ upload.summary.need_updates.length }}</p>
                            </div>
                        </div>
                        <div *ngIf="upload.data_type === 'ledger_account'" class="d-flex align-items-baseline">
                            <div class="w-25 mt-2">
                                <strong class="d-block">Total Rows: </strong>
                                <p>
                                    {{
                                        upload.summary.successes.length +
                                            upload.summary.errors.length +
                                            upload.summary.existing.length +
                                            upload.summary.need_updates.length
                                    }}
                                </p>
                            </div>

                            <div class="w-25">
                                <strong class="d-block">Valid Rows: </strong>
                                <p>{{ upload.summary.successes.length }}</p>
                            </div>

                            <div class="w-25">
                                <strong class="d-block">Invalid Rows: </strong>
                                <p>{{ upload.summary.errors.length }}</p>
                            </div>

                            <div class="w-25">
                                <div>
                                    <strong>Existing: </strong>
                                    <span>{{ upload.summary.existing.length }}</span>
                                </div>
                                <div>
                                    <strong>Update: </strong>
                                    <span>{{ upload.summary.need_updates?.length }}</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="upload.data_type === 'invoice'" class="d-flex align-items-baseline">
                            <div class="w-25 mt-2">
                                <strong class="d-block">Total Invoices: </strong>
                                <p>
                                    {{
                                        upload.summary.successes.length +
                                            upload.summary.errors.length +
                                            upload.summary.existing.length +
                                            upload.summary.need_updates.length
                                    }}
                                </p>
                            </div>

                            <div class="w-25">
                                <strong class="d-block">Valid Invoices: </strong>
                                <p>{{ upload.summary.successes.length }}</p>
                            </div>

                            <div class="w-25">
                                <strong class="d-block">Invalid Invoices: </strong>
                                <p>{{ upload.summary.errors.length }}</p>
                            </div>

                            <div class="w-25">
                                <div>
                                    <strong>Existing: </strong>
                                    <span>{{ upload.summary.existing.length }}</span>
                                </div>
                                <div>
                                    <strong>Update: </strong>
                                    <span>{{ upload.summary.need_updates?.length }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        *ngIf="
                            upload.summary &&
                            upload.id === currentUploadId &&
                            upload.summary.errors &&
                            upload.summary.errors.length > 0
                        "
                        class=""
                    >
                        <div>
                            <span class="font-weight-bold">Errors:</span> ({{
                                upload.summary.errors.length > 1000 ? 1000 : upload.summary.errors.length
                            }}
                            of {{ upload.summary.errors.length }})
                        </div>

                        <div *ngFor="let error of upload.summary.errors; let i = index">
                            <div *ngIf="i < 1000" class="d-flex">
                                <div
                                    class="mr-2"
                                    style="width: 180px"
                                    *ngIf="error.rowNumber && upload.data_type === 'deal'"
                                >
                                    Number of Deal in File: {{ error.rowNumber }}
                                </div>
                                <div
                                    class="mr-2"
                                    style="width: 180px"
                                    *ngIf="error.rowNumber && upload.data_type === 'profile'"
                                >
                                    Unique Id: {{ error.uniquie_id }}
                                </div>
                                <div
                                    class="mr-2"
                                    style="width: 300px"
                                    *ngIf="error.rowNumber && upload.data_type === 'contact'"
                                >
                                    Unique Id: {{ error.uniquie_id }}
                                </div>
                                <div
                                    class="mr-2"
                                    style="width: 180px"
                                    *ngIf="error.rowNumber && upload.data_type === 'ledger_account'"
                                >
                                    Number of Row in File: {{ error.rowNumber }}
                                </div>
                                <!--                                <div class="mr-2" style="width: 165px" *ngIf="error.rowNumber && upload.data_type === 'invoice'">Number of Row in File: {{error.rowNumber}}</div>-->
                                <div *ngIf="upload.data_type === 'invoice'">
                                    Error Message
                                    <span *ngIf="error.invoiceUniqueKey"
                                        >(unique key - {{ error.invoiceUniqueKey }})</span
                                    >: {{ error.errorMessage }}
                                </div>

                                <div *ngIf="upload.data_type !== 'invoice'">
                                    Error Message: {{ error.errorMessage }}
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </mat-tab>
        <mat-tab label="Journals">
            <div *ngIf="backgroundTasksList && backgroundTasksList.length > 0">
                <div class="d-flex d-sm-flex mb-2 px-3 w-100 data-table-header mt-3">
                    <div class="w-20 header-text">
                        <span class="font-weight-bold">Process</span>
                    </div>
                    <div class="w-20 header-text">
                        <span class="font-weight-bold">Executed At</span>
                    </div>
                    <div class="w-20 header-text">
                        <span class="font-weight-bold">Status</span>
                    </div>
                    <div class="w-40 header-text">
                        <span class="font-weight-bold">Files</span>
                    </div>
                </div>

                <div *ngFor="let backgroundTask of backgroundTasksList; let i = index">
                    <mat-card class="px-3 pt-3 pb-2 mt-2 bg-light">
                        <div class="d-flex align-items-baseline w-100 mb-2">
                            <div class="w-20">
                                <p>{{ backgroundTask[0].type | fromSnakeCase }}</p>
                            </div>

                            <div class="w-20">
                                <p>{{ backgroundTask[0].created_at | date: 'short' }}</p>
                            </div>

                            <div class="w-20">
                                <p>{{ backgroundTask[0].status | fromSnakeCase }}</p>
                            </div>

                            <div class="w-40">
                                <p *ngFor="let backgroundTaskItem of backgroundTask; let j = index">
                                    <a target="_blank" href="{{ backgroundTaskItem.options.file_url }}">
                                        {{ backgroundTaskItem.options.file_name }}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <h4 *ngIf="!backgroundTasksList?.length > 0" class="mt-4 text-center">We didn't find any data.</h4>
        </mat-tab>
    </mat-tab-group>
</div>
