<div class="sidebar-page">
    <app-company-inner-nav
        [openingBalanceDate]="openingBalanceDate"
        (deleteOpeningBalancesEmitter)="deleteOpeningBalances()"
    ></app-company-inner-nav>

    <div class="px-4 pt-2 h-100">
        <app-ledger-account-transactions
            #accountTransactions
            [isOpened]="isSidebarOpened"
            class="sidebar-wrapper h-100"
        >
            <section class="sidebar-container">
                <div class="col-12 card-opening-balances">
                    <div class="row opening-header-block">
                        <div class="row-inner-warning">
                            <mat-icon>warning</mat-icon>
                            <span>
                                All your invoices, payments received, credit notes, bills and its payments that were
                                recorded before the opening balance date will be adjusted with the amount you have
                                entered.
                            </span>
                        </div>
                    </div>

                    <table class="table" *ngIf="openingBalanceDate">
                        <thead class="bg-dark text-white">
                            <tr>
                                <td style="width: 5%" scope="col">Code</td>
                                <td style="width: 50%" scope="col">Account</td>
                                <td style="width: 15%; text-align: center" scope="col">Zero Balance</td>
                                <td style="width: 15%" scope="col">Debit(USD)</td>
                                <td style="width: 15%" scope="col">Credit(USD)</td>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let item of ledgerAccountsData.items; index as idx">
                                <td>{{ item.code }}</td>
                                <td>
                                    {{ item.ledger_account_name }}
                                    <span *ngIf="item.status === 'inactive'" style="font-weight: bold">
                                        (Deactivated)</span
                                    >
                                </td>
                                <td style="text-align: center">
                                    <mat-checkbox [checked]="item.zero_balance" disabled="true"></mat-checkbox>
                                </td>
                                <td>{{ item.total > 0 ? item.total.toFixed(2) : '' }}</td>
                                <td>{{ item.total < 0 ? item.total.toFixed(2) * -1 : '' }}</td>
                            </tr>
                            <tr>
                                <td style="text-align: right; font-weight: bold" colspan="3">Total:</td>
                                <td style="font-weight: bold">{{ ledgerAccountsData.debit_total.toFixed(2) }}</td>
                                <td style="font-weight: bold">{{ ledgerAccountsData.credit_total.toFixed(2) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </app-ledger-account-transactions>
    </div>
</div>
