import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ServiceRequester} from '../../service.requester';
import {ICreditNote} from '../../../../typings';

@Injectable()
export class CreditNotesService {
    private url = '/finance/credit-notes/';

    constructor(public requester: ServiceRequester) {}

    getCreditNote(id: number): Observable<ICreditNote> {
        return this.requester.makeMsCall$(this.url + id, 'GET', 'shipp');
    }

    getCreditNotes(params: any = {}): Observable<{result: ICreditNote[]; _meta: {total: number}}> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp', params);
    }

    getContactExcessCreditNotes(contactId: number): Observable<ICreditNote[]> {
        return this.requester.makeMsCall$(this.url + `${contactId}/excess`, 'GET', 'shipp');
    }

    createCreditNoteAsDraft(data: ICreditNote): Observable<ICreditNote> {
        return this.requester.makeMsCall$(this.url + 'draft', 'POST', 'shipp', data);
    }

    createCreditNoteAsOpen(data: ICreditNote): Observable<ICreditNote> {
        return this.requester.makeMsCall$(this.url + 'open', 'POST', 'shipp', data);
    }

    updateCreditNote(data: ICreditNote): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + data.credit_note_id, 'PUT', 'shipp', data);
    }

    updateCreditNoteStatus(creditNoteId: number, status: string): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + creditNoteId + '/status', 'PUT', 'shipp', {status});
    }

    applyToInvoices(
        creditNoteId: number,
        data: Array<{invoice_id: number; amount_to_credit: number}>
    ): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + creditNoteId + '/apply', 'PUT', 'shipp', data);
    }

    deleteCreditNote(noteId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + noteId, 'DELETE', 'shipp');
    }
}
