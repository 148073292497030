import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {IContactsState} from '../../contacts/store/contacts.reducer';
import {selectProducts} from '../../finance/store/finance.selectors';
import {IProduct} from '@cyberco-nodejs/zipi-typings';
import {switchMap, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {GenericFormGroup} from '../../../entites/generic.entity';
import {SessionService} from '../../../services/session.service';
import {ICompanyCalculationSettings} from '../../../models/company';
import {NotificationsService} from 'angular2-notifications';
import {CalculationSettingsService} from '../../../services/calculation-settings.service';
import {ConfirmComponent} from '../../../layouts/confirm/confirm.component';

@Component({
    selector: 'app-calculation-settings',
    templateUrl: './calculation-settings.component.html',
    styleUrls: ['./calculation-settings.component.scss']
})
export class CalculationSettingsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    products: IProduct[] = [];
    productsToAdd: IProduct[] = [];
    addedProducts: IProduct[] = [];
    calculationSettingsFG: GenericFormGroup<ICompanyCalculationSettings> =
        new GenericFormGroup<ICompanyCalculationSettings>({
            capable_metrics_by_product_ids: []
        });

    constructor(
        protected sessionService: SessionService,
        private store: Store<IContactsState>,
        private calculationSettingsService: CalculationSettingsService,
        private notificationsService: NotificationsService,
        public dialogRef: MatDialogRef<CalculationSettingsComponent>,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.store
            .pipe(
                select(selectProducts),
                switchMap((data) => {
                    this.products = data;
                    return this.getCalculationSettings();
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.setAddedProducts();
                this.setProductsToAdd();
            });
    }

    getCalculationSettings() {
        return this.calculationSettingsService.getCalculationSettings(true).then((calculationSettings) => {
            this.calculationSettingsFG = new GenericFormGroup<ICompanyCalculationSettings>(calculationSettings);
        });
    }

    setProductsToAdd() {
        this.productsToAdd = this.products
            .filter(
                (product) =>
                    !this.calculationSettingsFG.controls.capable_metrics_by_product_ids?.value.includes(
                        product.product_id
                    )
            )
            .sort((productA, productB) => (productA.name > productB.name ? 1 : -1));
    }

    setAddedProducts() {
        this.addedProducts = [];
        this.calculationSettingsFG.controls.capable_metrics_by_product_ids?.value.forEach((productId: number) => {
            const productToShow = this.products.find((product) => product.product_id === productId);
            if (typeof productToShow !== 'undefined') {
                this.addedProducts.push(productToShow);
            }
        });
    }

    saveChanges() {
        const calculationSettings: ICompanyCalculationSettings = this.calculationSettingsFG.value;
        return this.calculationSettingsService
            .updateCalculationSettings(this.sessionService.profile!.company_fk_id!, calculationSettings)
            .then((companyCalculationSettingsRes) => {
                this.closeDialog(true);
                if (!companyCalculationSettingsRes.success && companyCalculationSettingsRes.products_in_use) {
                    const productsInUse = this.products.filter((product) =>
                        companyCalculationSettingsRes.products_in_use?.includes(product.product_id!)
                    );
                    this.dialog.open(ConfirmComponent, {
                        maxWidth: 600,
                        data: {
                            title: `Product${productsInUse.length > 1 ? 's' : ''} can not be removed.`,
                            messages: [
                                `Products can not be removed from Capable Metrics by Products because product${productsInUse.length > 1 ? 's' : ''} ${productsInUse
                                    .map((product) => product.name)
                                    .join(', ')} currently used in some rules conditions`
                            ],
                            hideCancel: true
                        }
                    });
                }
            })
            .catch((err) => {
                this.notificationsService.error(err);
            });
    }

    removeProduct(product: IProduct) {
        let productsIds: number[] = this.calculationSettingsFG.controls.capable_metrics_by_product_ids?.value;
        const productIndex = productsIds.findIndex((productId) => product.product_id === productId);
        if (productIndex > -1) {
            productsIds.splice(productIndex, 1);
            this.calculationSettingsFG.controls.capable_metrics_by_product_ids?.patchValue(productsIds);
            this.setProductsToAdd();
            this.setAddedProducts();
        }
    }

    productSelected(product: IProduct) {
        const productsIds: number[] = this.calculationSettingsFG.controls.capable_metrics_by_product_ids?.value;
        productsIds.push(product.product_id!);
        this.calculationSettingsFG.controls.capable_metrics_by_product_ids?.patchValue(productsIds);
        this.setProductsToAdd();
        this.setAddedProducts();
    }

    closeDialog(needRefreshSettings = true) {
        this.dialogRef.close(needRefreshSettings);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
