import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

export class EmailChangeFormModel {
    email?: string;
    first_name?: string;
    last_name?: string;
    password?: string;
    password_confirm?: string;
}

export class EmailChangeFormGroup extends UntypedFormGroup {
    public static passwordConfirmValidator() {
        return (control: EmailChangeFormGroup): {[key: string]: any} | null => {
            const forbidden = control.value.password !== control.value.password_confirm;
            return forbidden ? {passwordsNotEqual: 'Passwords not equal'} : null;
        };
    }

    // @ts-ignore
    public controls: {
        email: UntypedFormControl;
        first_name: UntypedFormControl;
        last_name: UntypedFormControl;
        password: UntypedFormControl;
        password_confirm: UntypedFormControl;
    };

    constructor() {
        const controls = {
            email: new UntypedFormControl({value: ''}, Validators.required),
            first_name: new UntypedFormControl('', Validators.required),
            last_name: new UntypedFormControl('', Validators.required),
            password: new UntypedFormControl('', Validators.required),
            password_confirm: new UntypedFormControl('', Validators.required)
        };
        super(controls);
        this.setValidators([EmailChangeFormGroup.passwordConfirmValidator() as any]);
    }

    getRawValue(): EmailChangeFormModel {
        return <EmailChangeFormModel>super.getRawValue();
    }

    patchValue(value: EmailChangeFormModel) {
        super.patchValue(value);
    }
}
