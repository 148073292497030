import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {GenericFormArray, GenericFormGroup} from '../../../../entites/generic.entity';
import {CompensationService, CompensationServiceApi} from '../compensation.service';
import {CompensationProfileModel} from '../models/compensation-profile.model';
import {UntypedFormControl} from '@angular/forms';
import {merge, Subject} from 'rxjs';
import {startWith, takeUntil} from 'rxjs/operators';
import {FinancialElementModel} from '../models/financial-element.model';

@Component({
    selector: 'app-company-compensation-profiles-list',
    template: `
        <div
            *ngFor="
                let compensationFrom of compensationProfilesList.controls;
                index as ci;
                last as last;
                first as first
            "
            style="margin-bottom: 16px"
        >
            <app-company-compensation-profile
                [canOverride]="canOverride"
                [disabled]="canOverride"
                (override)="override.emit($event)"
                [show_down]="!last && canAdjustOrder"
                [show_up]="!first && canAdjustOrder"
                [order]="compensationProfilesList.controls.length - ci"
                [compensationForm]="compensationFrom"
                [isSortBlacked]="isSortBlacked"
                (save)="doSaveCompensationProfile(compensationFrom.getRawValue())"
                (copyCompensationProfile)="doCopyCompensationProfile($event, compensationFrom.getRawValue())"
                (delete)="doDeleteCompensationProfile(compensationFrom.getRawValue(), ci)"
                (up)="doMoveUp(ci)"
                (down)="doMoveDown(ci)"
            ></app-company-compensation-profile>
        </div>
    `
})
export class CompensationProfilesListComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    public canAdjustOrder: boolean = false;
    isSortBlacked: boolean;
    @Input() public includeDrafts: UntypedFormControl = new UntypedFormControl(false);
    @Input() public sortBy: UntypedFormControl = new UntypedFormControl('order_desc');

    @Input() compensationProfilesList: GenericFormArray<CompensationProfileModel> = new GenericFormArray([]);
    @Input() canOverride: boolean = false;
    @Output() override = new EventEmitter<CompensationProfileModel>();
    @Output() saved = new EventEmitter<CompensationProfileModel>();

    doMoveUp(index: number) {
        const order = this.compensationProfilesList.controls[index].controls.sort_order!.value;
        const order1 = this.compensationProfilesList.controls[index - 1].controls.sort_order!.value;

        const element = this.compensationProfilesList.controls.splice(index, 1).pop();
        this.compensationProfilesList.controls.splice(index - 1, 0, element!);

        this.compensationProfilesList.controls[index].controls.sort_order!.patchValue(order);
        this.compensationProfilesList.controls[index - 1].controls.sort_order!.patchValue(order1);
    }

    doMoveDown(index: number) {
        const order = this.compensationProfilesList.controls[index].controls.sort_order!.value;
        const order1 = this.compensationProfilesList.controls[index + 1].controls.sort_order!.value;

        const element = this.compensationProfilesList.controls.splice(index, 1).pop();
        this.compensationProfilesList.controls.splice(index + 1, 0, element!);

        this.compensationProfilesList.controls[index].controls.sort_order!.patchValue(order);
        this.compensationProfilesList.controls[index + 1].controls.sort_order!.patchValue(order1);
    }

    doSaveCompensationProfile(commProfile: CompensationProfileModel) {
        commProfile.financial_elements = commProfile.financial_elements.filter((element) => element.type !== null);
        return this.compensationService.updateCompensationProfile(commProfile).then(() => {
            this.saved.emit();
        });
    }

    doCopyCompensationProfile(type: string, commProfile: CompensationProfileModel) {
        const {id, ...cpWithoutId} = commProfile;

        cpWithoutId.type = type;
        cpWithoutId.status = CompensationProfileModel.status_set.draft;
        cpWithoutId.title = 'Copy of ' + cpWithoutId.title;
        cpWithoutId.financial_elements = (cpWithoutId.financial_elements as any)
            .filter((element: FinancialElementModel) => element.type !== null)
            .map((element: FinancialElementModel) => ({
                ...element,
                id: null,
                rules: element.rules.map((rule) => ({
                    ...rule,
                    disbursement_template: {
                        ...rule.disbursement_template,
                        additional_incomes: rule.disbursement_template.additional_incomes.map((income) => ({
                            ...income,
                            unique_hash: null
                        })),
                        additional_expenses: rule.disbursement_template.additional_expenses.map((expense) => ({
                            ...expense,
                            unique_hash: null
                        }))
                    },
                    commission_categorization: {
                        ...rule.commission_categorization,
                        money_list: rule.commission_categorization.money_list.map((ft) => ({
                            ...ft,
                            unique_hash: null
                        }))
                    }
                }))
            }));

        switch (cpWithoutId.type) {
            case CompensationProfileModel.type_set.template:
                cpWithoutId.is_enforced = true;
                cpWithoutId.financial_elements.forEach((element) => {
                    element.is_enforced = true;
                    element.is_template = true;
                    element.rules.forEach((rule) => {
                        rule.disbursement_template.additional_incomes.forEach((income) => {
                            income.added_automatic = false;
                            income.is_enforced = true;
                        });
                        rule.disbursement_template.additional_expenses.forEach((expense) => {
                            expense.added_automatic = false;
                            expense.is_enforced = true;
                        });
                    });
                });
                break;
            case CompensationProfileModel.type_set.default:
                cpWithoutId.financial_elements.forEach((element) => {
                    element.is_template = false;
                    element.rules.forEach((rule) => {
                        rule.disbursement_template.additional_incomes.forEach((income) => {
                            income.added_automatic = true;
                        });
                        rule.disbursement_template.additional_expenses.forEach((expense) => {
                            expense.added_automatic = true;
                        });
                    });
                });
                break;
        }

        this.compensationProfilesList.push(new GenericFormGroup(cpWithoutId));
    }

    doDeleteCompensationProfile(compensationProfile: CompensationProfileModel, index: number) {
        this.compensationProfilesList.controls.splice(index, 1);
    }

    constructor(
        protected compensationService: CompensationService,
        protected compensationServiceApi: CompensationServiceApi
    ) {
        this.isSortBlacked = false;
    }

    ngOnInit() {
        merge(this.includeDrafts.valueChanges, this.sortBy.valueChanges)
            .pipe(startWith(true), takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.canAdjustOrder = this.includeDrafts.value === true && this.sortBy.value === 'order_desc';
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
