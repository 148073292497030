import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {ZohoInviteService} from '../services/zoho-invite.service';
import {IUser} from '@cyberco-nodejs/zipi-typings';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SkyslopeAuth} from '../services/okta/skyslope-auth.service';

@Component({
    selector: 'app-sign-up-zoho-invite',
    templateUrl: './sign-up-zoho-invite.component.html',
    styleUrls: ['./sign-up-zoho-invite.component.scss']
})
export class SignUpZohoInviteComponent implements AfterViewInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    protected inviteHash = '';
    zohoInvite: any;
    existingUser: IUser | null = null;
    isLinkInValid: boolean = false;
    private oktaSignIn: any;

    constructor(
        protected zohoInviteService: ZohoInviteService,
        protected route: ActivatedRoute,
        private skyslopeAuth: SkyslopeAuth
    ) {
        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['invite_hash']) {
                this.inviteHash = params['invite_hash'];
            }
        });
    }

    async showLogin(): Promise<void> {
        this.oktaSignIn = await this.skyslopeAuth.createWidget('okta-login-container', !this.existingUser);

        const decodedHash = JSON.parse(atob(this.inviteHash));

        this.oktaSignIn.on('afterRender', async (context: {controller: string}) => {
            this.zohoInviteService
                .getExistingUserByEmail(decodedHash.invite_email.toLowerCase())
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((user) => {
                    if (user.result) {
                        this.existingUser = user.result;
                    }
                    if (decodedHash.invite_email) {
                        const fieldIds = ['input80', 'idp-discovery-username'];

                        for (const fieldId of fieldIds) {
                            let field: any = document.getElementById(fieldId);
                            if (field) {
                                field.value = decodedHash.invite_email;
                                field.dispatchEvent(new Event('input', {bubbles: true}));
                                field.disabled = true;
                            }
                        }
                    }

                    const firstNameField: any = document.getElementById('input101');
                    const lastNameField: any = document.getElementById('input108');
                    if (firstNameField) {
                        firstNameField.value = this.existingUser
                            ? this.existingUser!.first_name
                            : this.zohoInvite.receiver_settings.receiver_first_name;
                        firstNameField.dispatchEvent(new Event('input', {bubbles: true}));
                        firstNameField.disabled = !!this.existingUser;
                    }

                    if (lastNameField) {
                        lastNameField.value = this.existingUser
                            ? this.existingUser!.last_name
                            : this.zohoInvite.receiver_settings.receiver_last_name;
                        lastNameField.dispatchEvent(new Event('input', {bubbles: true}));
                        lastNameField.disabled = !!this.existingUser;
                    }
                });
            await this.skyslopeAuth.closeSession();
        });
    }

    ngAfterViewInit(): void {
        const decodedHash = JSON.parse(atob(this.inviteHash));

        this.zohoInviteService
            .getZipiInvite(decodedHash.zoho_invite_hash)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((invite) => {
                if (invite.result.error) {
                    this.isLinkInValid = true;
                } else {
                    this.zohoInvite = invite.result;
                    sessionStorage.setItem('zohoInviteHash', this.inviteHash);
                    setTimeout(() => {
                        this.showLogin();
                    }, 1000);
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
