import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {Board} from '../../../models/board';

@Injectable()
export class BoardService {
    private url = '/boards/';

    constructor(protected serviceRequester: ServiceRequester) {}

    list() {
        return this.serviceRequester.makeMsCallGlobalAllowed(this.url, 'GET');
    }

    create(data: any): Promise<Board> {
        return this.serviceRequester.makeMsCallGlobalAllowed(this.url, 'POST', data);
    }

    update(boardId: number, data: any): Promise<Board> {
        return this.serviceRequester.makeMsCallGlobalAllowed(this.url + boardId, 'PUT', data);
    }

    softDelete(boardId: number) {
        return this.serviceRequester.makeMsCallGlobalAllowed(this.url + boardId, 'DELETE');
    }
}
