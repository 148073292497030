import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {Observable} from 'rxjs';
import {IUser} from '@cyberco-nodejs/zipi-typings';

@Injectable({
    providedIn: 'root'
})
export class ZohoInviteService {
    private url = '/zoho-invites';

    constructor(public requester: ServiceRequester) {}

    getZipiInvite(hash: string): Observable<any> {
        return this.requester.makeMsCall$(`/public${this.url}/${hash}`, 'GET', 'zipi');
    }

    getExistingUserByEmail(email: string) {
        return this.requester.makeMsCall$(`/public${this.url}/user/${email}`, 'GET', 'zipi');
    }

    afterRegister(user: IUser, company: any, hash: string): Observable<any> {
        return this.requester.makeMsCall$(`/public${this.url}/after-register/${hash}`, 'POST', 'zipi', {
            user,
            company
        });
    }
}
