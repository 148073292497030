import {Component, Input} from '@angular/core';
import {IReport} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'reports-preview-cash-flow',
    templateUrl: './cash-flow.component.html',
    styleUrls: ['./cash-flow.component.scss']
})
export class CashFlowStatementReportPreviewComponent {
    @Input() report: IReport | null = null;
    @Input() reportData: {[key: string]: any} = {};
}
