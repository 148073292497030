import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../service.requester';
import {Observable} from 'rxjs';
import {IJournalBase, IJournalTemplate} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class JournalsTemplatesService {
    private url = '/finance/journaltemplates/';

    constructor(public requester: ServiceRequester) {}

    getJournalTemplates(params = {}): Observable<{result: IJournalTemplate[]; _meta: {total: number}}> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp', params);
    }

    getJournalTemplateById(journalTemplateId: number): Observable<IJournalTemplate> {
        return this.requester.makeMsCall$(this.url + `${journalTemplateId}`, 'GET', 'shipp');
    }

    createJournalTemplate(data: {title: any} & IJournalBase): Observable<IJournalTemplate> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    updateJournalTemplate(data: {journal_template_id: number; title: any} & IJournalBase): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + data.journal_template_id, 'PUT', 'shipp', data);
    }

    deleteJournalTemplate(id: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + id, 'DELETE', 'shipp');
    }
}
