<div class="container" *ngIf="zohoInvite && zohoInvite.status !== 'accepted'">
    <mat-card class="card-override">
        <mat-card-content class="col card-component">
            <div class="row">
                <div class="col-6 intro-section">
                    <div class="mat-typography">
                        <div class="books-logo">
                            <img class="books-img" src="/assets/images/skyslope-books-white.png" alt="" />
                        </div>
                        <H1 class="books-title">Create Your Account</H1>
                        <p class="books-subtitle">
                            You are invited to join SkySlope
                            <span style="white-space: nowrap">Books&#8212;the</span> smart way to build a real estate
                            business.
                        </p>
                        <p class="description">Please confirm your name and password to get started.</p>
                        <p class="description">
                            Company: <b>{{ zohoInvite?.receiver_settings?.receiver_company_title }}</b>
                        </p>
                    </div>
                </div>
                <div class="col-6 mt-2" style="flex: auto">
                    <div style="margin-top: 30px" id="okta-login-container"></div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<mat-card class="sign-up-card-accepted" *ngIf="zohoInvite && zohoInvite.status === 'accepted'">
    <mat-card-header class="mb-3">
        <div class="title">
            This Invite already accepted by
            <span class="dynamic-info"
                >{{ zohoInvite.accepted_by.first_name }} {{ zohoInvite.accepted_by.last_name }}</span
            >
        </div>
        <div class="title">
            With email: <span class="dynamic-info">{{ zohoInvite.accepted_by.email }}</span>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div style="text-align: center">
            <a routerLink="/login">Already have account? Sign In ...</a>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="card" *ngIf="isLinkInValid">
    <mat-card-content>
        <p>This invite is not valid anymore</p>
    </mat-card-content>
</mat-card>
