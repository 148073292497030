import {Component, OnInit, EventEmitter, Output, Input, OnDestroy, OnChanges, SimpleChanges} from '@angular/core';
import {DealsBoardFrontendFilterSettings} from '../../../common/deal.models';
import {Deal} from '../../../../../../../models/deal';
import {Subject} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';

interface TempFilterSettings {
    statuses: {[key: string]: boolean};
    disbursement_statuses: {[key: string]: boolean};
    types: {[key: string]: boolean};
}

@Component({
    selector: 'app-deal-board-filters',
    styleUrls: ['./deal-board-filters.component.scss'],
    templateUrl: './deal-board-filters.component.html'
})
export class DealBoardFiltersComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() filters: DealsBoardFrontendFilterSettings = {
        statuses: {},
        types: {},
        disbursement_statuses: {},
        property_classes: {},
        search: '',
        tags: null,
        divisions: null
    };
    @Input() toggleFiltersView: boolean = false;
    @Input() selectedDeal: boolean = false;
    @Input() boardMeta: {[key: string]: number} = {};

    @Output() filterChanged: EventEmitter<DealsBoardFrontendFilterSettings> = new EventEmitter();

    // TODO: need to get statuses & types from backend
    public statuses = Deal.provideStatuses();
    public types = Deal.provideTypes();
    public disbursement_statuses = Deal.provideDisbursementStatuses();
    public property_classes: string[] = Deal.providePropertyClasses();

    public DEAL = Deal;

    toReducedView: boolean = false;

    statusesFC: UntypedFormControl = new UntypedFormControl([]);
    typesFC: UntypedFormControl = new UntypedFormControl([]);
    disbursementStatusesFC: UntypedFormControl = new UntypedFormControl([]);
    alignRight = false;
    toggle = false;
    public tempFilters: TempFilterSettings = {
        statuses: {},
        types: {},
        disbursement_statuses: {}
    };

    ngOnInit() {
        if (!this.filters.statuses) {
            this.filters.statuses = {};
        }
        if (!this.filters.types) {
            this.filters.types = {};
        }
        if (!this.filters.property_classes) {
            this.filters.property_classes = {};
        }
        if (!this.filters.disbursement_statuses) {
            this.filters.disbursement_statuses = {};
        }

        // Map filters to false so UI can show all filters as unchecked
        this.tempFilters = {
            statuses: this.mapFilterToFalse(this.filters.statuses),
            types: this.mapFilterToFalse(this.filters.types),
            disbursement_statuses: this.mapFilterToFalse(this.filters.disbursement_statuses)
        };

        this.filters = {
            ...this.filters,
            statuses: this.mapFilterToFalse(this.filters.statuses),
            types: this.mapFilterToFalse(this.filters.types),
            disbursement_statuses: this.mapFilterToFalse(this.filters.disbursement_statuses)
        };
    }

    public mapFilterToFalse(filter: {[key: string]: boolean}): {[key: string]: boolean} {
        return Object.keys(filter).reduce((obj, key) => ({...obj, [key]: false}), {});
    }

    public selectStatus(status: string) {
        this.tempFilters.statuses[status] = !this.tempFilters.statuses[status];
    }

    public selectType(type: string) {
        this.tempFilters.types[type] = !this.tempFilters.types[type];
    }

    public selectDisbursementStatus(disbursement_status: string) {
        this.tempFilters.disbursement_statuses[disbursement_status] =
            !this.tempFilters.disbursement_statuses[disbursement_status];
    }

    public applyFilters() {
        this.filters = {
            ...this.filters,
            statuses: {...this.tempFilters.statuses},
            types: {...this.tempFilters.types},
            disbursement_statuses: {...this.tempFilters.disbursement_statuses}
        };
        this.filterChanged.emit(this.filters);
    }

    clearFilters() {
        // Reset tempFilters to its initial state
        this.tempFilters = {
            statuses: this.mapFilterToFalse(this.filters.statuses),
            types: this.mapFilterToFalse(this.filters.types),
            disbursement_statuses: this.mapFilterToFalse(this.filters.disbursement_statuses)
        };

        this.applyFilters();
    }

    object_keys(obj: {}): string[] {
        return Object.keys(obj);
    }

    hasOwnProperty(object: {[key: string]: any}, property: string) {
        return object.hasOwnProperty(property);
    }

    isBoolean(value: any): boolean {
        return typeof value === 'boolean';
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.filterChanged.complete();
    }
}

@Component({
    selector: 'app-deal-board-view-filters',
    styleUrls: ['./deal-board-filters.component.scss'],
    templateUrl: './deal-board-filters.component.html'
})
export class DealBoardViewFiltersComponent extends DealBoardFiltersComponent implements OnChanges, OnInit, OnDestroy {
    private unsubscribeView: Subject<void> = new Subject();
    toggle = true;
    alignRight = true;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.toggleFiltersView && changes.toggleFiltersView.currentValue !== this.toReducedView) {
            this.toReducedView = changes.toggleFiltersView.currentValue;
        }

        if (changes.filters && changes.filters.currentValue) {
            // for small screen
            if (this.statusesFC) {
                this.statusesFC.patchValue(
                    Object.keys(this.filters.statuses)
                        .filter((key) => this.filters.statuses[key])
                        .map((key) => key)
                );
            } else {
                this.statusesFC = new UntypedFormControl(
                    Object.keys(this.filters.statuses)
                        .filter((key) => this.filters.statuses[key])
                        .map((key) => key)
                );
            }
            if (this.typesFC) {
                this.typesFC.patchValue(
                    Object.keys(this.filters.types)
                        .filter((key) => this.filters.types[key])
                        .map((key) => key)
                );
            } else {
                this.typesFC = new UntypedFormControl(
                    Object.keys(this.filters.types)
                        .filter((key) => this.filters.types[key])
                        .map((key) => key)
                );
            }
            if (this.disbursementStatusesFC) {
                this.disbursementStatusesFC.patchValue(
                    Object.keys(this.filters.disbursement_statuses)
                        .filter((key) => this.filters.disbursement_statuses[key])
                        .map((key) => key)
                );
            } else {
                this.disbursementStatusesFC = new UntypedFormControl(
                    Object.keys(this.filters.disbursement_statuses)
                        .filter((key) => this.filters.disbursement_statuses[key])
                        .map((key) => key)
                );
            }
        }

        this.tempFilters = {
            statuses: this.mapFilterToFalse(this.filters.statuses),
            types: this.mapFilterToFalse(this.filters.types),
            disbursement_statuses: this.mapFilterToFalse(this.filters.disbursement_statuses)
        };
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.unsubscribeView.next();
        this.unsubscribeView.complete();
    }
}

@Component({
    selector: 'app-deal-board-edit-filters',
    styleUrls: ['./deal-board-filters.component.scss'],
    templateUrl: './deal-board-filters.component.html'
})
export class DealBoardEditFiltersComponent extends DealBoardFiltersComponent {
    toggle = true;
    alignRight = false;
}
