import {
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {IReportsPreviewResultData} from '../../../../store/reports.reducer';
import {MatTableDataSource} from '@angular/material/table';
import {IScrollData} from '../../../../../../models/scroll-data';
import {PAGE_SIZE_OPTIONS} from '../../../../../../local-typings';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {firstValueFrom, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AddContactPayroll1099DialogComponent} from '../../../../../contacts/contact-dialogs/add-contact-payroll-1099-dialog/add-contact-payroll-1099-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {IContact, IReport} from '@cyberco-nodejs/zipi-typings';
import {contactsActionTypes, UpdContact} from '../../../../../contacts/store/contacts.actions';
import {assign, debounce} from 'lodash-es';
import {Store} from '@ngrx/store';
import {ShipperContactsService} from '../../../../../../services/api/shipper.contacts.service';
import {IContactsState} from '../../../../../contacts/store/contacts.reducer';
import {Actions, ofType} from '@ngrx/effects';
import {ConfirmComponent} from '../../../../../../layouts/confirm/confirm.component';
import {ReportsService} from '../../../../services/reports.service';
import {NotificationsService} from 'angular2-notifications';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../../utilities/maskito';
import {Payroll1099TaxRecordErrorsDialogComponent} from '@app/modules/contacts/contact-dialogs/payroll-1099-tax-record-errors-dialog/payroll-1099-tax-record-errors-dialog.component';

@Component({
    selector: 'reports-preview-ten99-nec-int-misc',
    templateUrl: './ten99-nec-int-misc.component.html',
    styleUrls: ['./ten99-nec-int-misc.component.scss']
})
export class Ten99NecIntMiscPreviewComponent implements OnInit, OnDestroy, OnChanges {
    @Input() report: IReport | null = null;
    @Input() reportData: IReportsPreviewResultData[] = [];
    @Input() page: number = 0;
    @Input() perPage: number = 25;
    @Input() totalRecords: number = 0;
    @Input() getPaginatedReportData: ((event?: {page: number; perPage: number}) => void) | undefined;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator = new MatPaginator(
        new MatPaginatorIntl(),
        ChangeDetectorRef.prototype
    );

    dataSource: MatTableDataSource<IReportsPreviewResultData>;
    displayedColumns = ['name', 'nonEmployeeCompensation', 'submissionType'];
    scrollData: IScrollData = {
        offset: 1,
        limit: 1,
        total: 2
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    currencyMaskitoMask = currencyMaskitoOptions;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public dialog: MatDialog,
        private contactsService: ShipperContactsService,
        private store: Store<IContactsState>,
        protected actions$: Actions,
        private reportsService: ReportsService,
        private notificationsService: NotificationsService
    ) {
        this.dataSource = new MatTableDataSource<IReportsPreviewResultData>();

        actions$.pipe(ofType(contactsActionTypes.UPD_CONTACT_SUCCESS), takeUntil(this.unsubscribe)).subscribe(() => {
            if (this.getPaginatedReportData) {
                this.getPaginatedReportData();
            }
        });
    }

    ngOnInit() {
        this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.scrollData.limit = data.pageSize;
            this.scrollData.offset = data.pageSize * data.pageIndex;

            if (this.getPaginatedReportData) {
                this.getPaginatedReportData({page: data.pageIndex, perPage: this.scrollData.limit});
            }
        });
        this.dataSource.data = this.reportData;
        this.scrollData = {
            offset: this.page,
            limit: this.perPage,
            total: this.totalRecords
        };
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('reportData')) {
            this.dataSource.data = changes.reportData.currentValue;
        }
        if (changes.hasOwnProperty('totalRecords')) {
            this.scrollData.total = changes.totalRecords.currentValue;
        }
    }

    async openContactPayrollDialog(contact_id: number, contact_payroll_id: number, contact_fk_id: any) {
        const contactFromService = await firstValueFrom(this.contactsService.getContactById(contact_id));
        const payrollToUpdate = contactFromService.contact_payrolls?.find(
            (p) => p.contact_payroll_id === contact_payroll_id
        );

        const dialogRef = this.dialog.open(AddContactPayroll1099DialogComponent, {
            panelClass: 'no-padding-dialog',
            minWidth: 320,
            maxWidth: 750,
            data: {
                contact: contactFromService,
                payroll: payrollToUpdate,
                type: '1099_misc'
            }
        });
        this.onSubmitPayrollChanges(dialogRef, contactFromService);
    }

    async showTaxRecordErrors(element: any) {
        const dialogRef = this.dialog.open(Payroll1099TaxRecordErrorsDialogComponent, {
            panelClass: 'no-padding-dialog',
            minWidth: 320,
            maxWidth: 750,
            data: {
                taxRecord: element
            }
        });
    }

    onSubmitPayrollChanges(dialogRef: MatDialogRef<AddContactPayroll1099DialogComponent>, contact: IContact) {
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((contact_payroll) => {
                if (contact_payroll) {
                    this.openConfirmationDialog(contact, contact_payroll);
                }
            });
    }

    openConfirmationDialog(contact: IContact, contactPayroll: any) {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            data: {
                title: 'Are you sure you want to continue?',
                message: 'Saving these changes will update the tax record for your contacts.'
            }
        });
        this.onConfirmSavePayrollChanges(dialogRef, contact, contactPayroll);
    }

    onConfirmSavePayrollChanges(dialogRef: MatDialogRef<ConfirmComponent>, contact: IContact, contactPayroll: any) {
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((ok) => {
                if (ok) {
                    let contactPayrolls = [];
                    if (contact.contact_payrolls && contact.contact_payrolls.length) {
                        contactPayrolls = [...contact.contact_payrolls, ...[contactPayroll]];
                    } else {
                        contactPayrolls = [contactPayroll];
                    }
                    this.store.dispatch(
                        new UpdContact({
                            data: assign({}, contact, {
                                contact_payrolls: contactPayrolls
                            }),
                            silent: true
                        })
                    );
                }
            });
    }

    onSubmissionTypeChange(submission_type: string, contact_payroll_fk_id: any, contact_fk_id: any) {
        const body = {
            contact_payroll_fk_id,
            contact_fk_id,
            submission_type
        };
        // @todo: this ts-ignore should be removed and code refactored similar to onCompensationChange() below
        // @ts-ignore
        debounce(this.updateOverrides(body), 300);
    }

    onCompensationChange(event: {target: HTMLInputElement}, contact_payroll_fk_id: any, contact_fk_id: any) {
        const other_income = cleanCurrencyString(event.target?.value ? event.target.value : 0);

        const updateOverridesFunc = () => {
            return this.updateOverrides({
                contact_payroll_fk_id,
                contact_fk_id,
                other_income
            });
        };

        debounce(updateOverridesFunc, 300)();
    }

    updateOverrides(body: object) {
        if (this.report) {
            this.reportsService
                .updateContactPayrollOverride(body, this.report.id)
                .then((response) => {
                    if (response.status) {
                        if (response.status === 'success') {
                            this.notificationsService.success(`Overrides updated`);
                        }
                        if (response.status === 'warning') {
                            this.notificationsService.info(`Override warning. Reason: ${response.message}`);
                        }
                    }
                })
                .catch((error: Error) => {
                    this.notificationsService.success(`An error occurred. Unable to update override values.
                Error: ${JSON.stringify(error)}`);
                });
        }
    }

    isValidTaxRecord(element: any) {
        // Confirm Payroll Record Exists
        if (!element.contact_payroll_fk_id) {
            return false;
        }
        // Ensure Box 1 > $0
        if (element.other_income <= 0) {
            return false;
        }
        return true;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
