<div class="sidebar-body">
    <!------- Table of invoices ------->
    <div class="table-background" style="height: calc(100vh - 180px)">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">Date</th>
                <td mat-cell *matCellDef="let element" class="w-15">
                    {{ element.date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="journal_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">Journal#</th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1">
                    {{ element.journal_number }}
                </td>
            </ng-container>

            <ng-container matColumnDef="reference_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">Reference#</th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1">
                    {{ element.reference_number }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">Status</th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1">
                    {{ element.status | titlecase }}
                </td>
            </ng-container>

            <ng-container matColumnDef="line_item_total">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">Amount</th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1 text-right">
                    <div>
                        {{
                            element.line_item_total === null
                                ? '–'
                                : (element.line_item_total | currency: 'USD' : 'symbol')
                        }}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="notes">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20">Notes</th>
                <td mat-cell *matCellDef="let element" class="w-20 px-1">
                    {{ element.notes }}
                </td>
            </ng-container>

            <!--            <ng-container matColumnDef="action">-->
            <!--                <th mat-header-cell *matHeaderCellDef class="w-5">Action</th>-->
            <!--                <td mat-cell *matCellDef="let element" class="w-5">-->
            <!--                    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation();">-->
            <!--                        <mat-icon>more_vert</mat-icon>-->
            <!--                    </button>-->
            <!--                    <mat-menu #menu="matMenu">-->
            <!--                        <button mat-menu-item-->
            <!--                                [rule]="{company_settings__manage_journals: true}" rbac [denyMethod]="'style'"-->
            <!--                                [allowStyle]="{display : 'inline-block'}" [denyStyle]="{display : 'none'}"-->
            <!--                                (click)="journalCreateEmitter.next(element)">-->
            <!--                            Edit-->
            <!--                        </button>-->
            <!--                        <button mat-menu-item-->
            <!--                                [rule]="{company_settings__manage_journals: true}" rbac [denyMethod]="'style'"-->
            <!--                                [allowStyle]="{display : 'inline-block'}" [denyStyle]="{display : 'none'}"-->
            <!--                                *ngIf="element.status === 'draft'"-->
            <!--                                [disabled]="!element.line_item_total || element.line_item_total === 0"-->
            <!--                                (click)="publishManualJournal(element)">-->
            <!--                            Publish-->
            <!--                        </button>-->
            <!--                    </mat-menu>-->
            <!--                </td>-->
            <!--            </ng-container>-->

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="row.id" class="zp-table-row"></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 text-center no-items">
            There are no journals.
        </div>
    </div>
</div>
