import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {NotificationsServiceZipi} from 'app/modules/notifications/notifications.service';
import {MatDialog} from '@angular/material/dialog';
import {SessionService} from '../../../services/session.service';
import {Subject} from 'rxjs';
import {SkyslopeAuth} from '../services/okta/skyslope-auth.service';
import {Location} from '@angular/common';

@Component({
    selector: 'app-sign-in-as',
    templateUrl: './sign-in-as.component.html',
    styleUrls: ['./sign-in-as.component.scss']
})
export class SignInAsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    public showInProcess: boolean = true;
    public showError: boolean = false;

    constructor(
        public authService: AuthService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public sessionService: SessionService,
        private notificationsService: NotificationsServiceZipi,
        private skyslopeAuthService: SkyslopeAuth,
        public dialog: MatDialog,
        private location: Location
    ) {}

    async ngOnInit() {
        const isAuthenticated = await this.skyslopeAuthService.isAuthenticated();
        if (isAuthenticated) {
            if (this.activatedRoute.snapshot.params.login_as_uid) {
                this.authService.setLoginAsUID(this.activatedRoute.snapshot.params.login_as_uid);
                this.authService.loadAuth().then((isLoaded) => {
                    if (isLoaded) {
                        setTimeout(() => {
                            window.location.href = '/default-page';
                            // this.router.navigate(['/default-page']);
                        }, 1000);
                    } else {
                        this.showInProcess = false;
                        this.showError = true;
                    }
                });
            }
        } else {
            this.showInProcess = false;
            this.showError = true;
        }
    }

    goBack() {
        this.location.back();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
