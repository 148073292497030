import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {PaymentMethodsService} from '../../../../profile/services/payment-methods.service';
import {GatewayTypes, IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {Subject} from 'rxjs';
import {AbstractControl, UntypedFormControl} from '@angular/forms';
import {CompanyGatewayService} from '../../../../../services/api/finance/company-gateway.service';
import {FeatureFlagsService} from '../../../../feature-flags/feature-flags.service';

@Component({
    selector: 'app-select-pay-to-methods',
    templateUrl: './select-pay-to-methods.component.html',
    styleUrls: ['./select-pay-to-methods.component.scss']
})
export class SelectPayToMethodsComponent implements OnInit, OnChanges, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() payToMethodIdsControl: UntypedFormControl | AbstractControl | undefined;
    @Input() payToCardControl: UntypedFormControl | AbstractControl | undefined;
    @Input() payToBankControl: UntypedFormControl | AbstractControl | undefined;
    @Input() convenienceFeeBankPayerControl: UntypedFormControl | AbstractControl | undefined;
    @Input() convenienceFeeCardPayerControl: UntypedFormControl | AbstractControl | undefined;

    @Input() allowedGatewayTypes: Array<GatewayTypes> = [];
    @Input() pickerType: 'united' | 'separated' = 'united';

    @Input() titleLabel: string = 'Accepted Forms of Payment';
    @Input() isNeedToPresetDefault: boolean = true;

    @Input() disabled: boolean = false;

    @Output() redirectEvent = new EventEmitter();

    companyZipiFinancialGateway: IPaymentGateway[] = [];
    companyAuthGateway: IPaymentGateway[] = [];

    cardGateways: Array<IPaymentGateway & {transfer_customization_ref: string}> = [];
    bankGateways: Array<IPaymentGateway & {transfer_customization_ref: string}> = [];

    allCompanyMethods: IPaymentMethod[] = [];

    companyGateways: Array<IPaymentGateway & {transfer_customization_ref: string}> = [];

    currentGatewayId: number | undefined | null;

    selectedMethodsForChips: IPaymentMethod[];
    selectedCardMethodsForChips: IPaymentMethod[];
    selectedBankMethodsForChips: IPaymentMethod[];

    // feature_flags
    isMoneyTransfersFeatureFlagEnabled: boolean = false;

    isBankConvenienceFeeAllowed: boolean = false;
    isCardConvenienceFeeAllowed: boolean = false;

    constructor(
        private paymentMethodsService: PaymentMethodsService,
        private companyGatewayService: CompanyGatewayService,
        protected featureFlagsService: FeatureFlagsService
    ) {
        this.selectedMethodsForChips = [];
        this.selectedCardMethodsForChips = [];
        this.selectedBankMethodsForChips = [];
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                const flags = this.featureFlagsService.getFlagsByApiCall();
                if (flags) {
                    this.isMoneyTransfersFeatureFlagEnabled =
                        flags &&
                        flags['marketplace:addons:zipi_financial:money_transfer'] &&
                        flags['marketplace:addons:zipi_financial:money_transfer'].enabled;
                }
            });
    }

    async ngOnInit() {
        this.companyGatewayService
            .getCompanyGatewaysByTypes(this.allowedGatewayTypes, null)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((gateways) => {
                this.companyGateways = gateways as any;
                if (this.pickerType === 'united') {
                    this.setupUnitedPicker();
                } else {
                    this.setupSeparatedPicker();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {}

    setupUnitedPicker() {
        if (!this.isMoneyTransfersFeatureFlagEnabled) {
            this.companyGateways = this.companyGateways.filter(
                (gateway) =>
                    !['zipi_financial_business', 'zipi_financial_trust', 'zipi_financial_receive_only'].includes(
                        gateway.type
                    )
            );
        }
        this.companyAuthGateway = this.companyGateways.filter((gateway) =>
            ['authorize_net_merchant'].includes(gateway.type)
        );
        this.companyZipiFinancialGateway = this.companyGateways.filter((gateway) =>
            ['zipi_financial_business'].includes(gateway.type)
        );

        this.allCompanyMethods = this.paymentMethodsService.getAllCompanyPaymentMethodsFromGateways(
            this.companyGateways
        );

        if (this.payToMethodIdsControl && this.payToMethodIdsControl.value) {
            this.payToMethodIdsControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((cha) => {
                this.selectedMethodsForChips = this.allCompanyMethods.filter((method) =>
                    this.payToMethodIdsControl?.value.includes(method.payment_method_id)
                );
            });

            if (this.payToMethodIdsControl.value.length === 0 && this.isNeedToPresetDefault) {
                const defaultMethods: IPaymentMethod[] = [];

                for (const gateway of this.companyGateways) {
                    if (gateway.payment_methods && gateway.payment_methods.length > 0) {
                        if (gateway.type !== 'authorize_net_merchant' && gateway.status === 'active') {
                            const zipiFinancialDefault = gateway.payment_methods.find(
                                (method) => method.is_default_receiving && method.status === 'active'
                            );
                            if (zipiFinancialDefault) {
                                defaultMethods.push(zipiFinancialDefault);
                            }
                        }
                    }
                }

                if (
                    this.companyAuthGateway[0] &&
                    this.companyAuthGateway[0].payment_methods &&
                    this.companyAuthGateway[0].payment_methods.length > 0
                ) {
                    defaultMethods.push(this.companyAuthGateway[0].payment_methods[0]);
                }

                this.payToMethodIdsControl.setValue(
                    defaultMethods.map((method) => method.payment_method_id),
                    {emitEvent: false}
                );
                this.selectedMethodsForChips = defaultMethods;
            } else {
                this.selectedMethodsForChips = this.allCompanyMethods.filter((method) =>
                    this.payToMethodIdsControl?.value.includes(method.payment_method_id)
                );
            }
        }
    }

    setupSeparatedPicker() {
        this.cardGateways = this.companyGateways.filter((gateway) => {
            return gateway.type === 'authorize_net_merchant' || gateway.driver_type === 'payload';
        });
        this.bankGateways = this.companyGateways.filter((gateway) =>
            ['zipi_financial_business'].includes(gateway.type)
        );

        if (this.isNeedToPresetDefault) {
            let defaultBankMethod: IPaymentMethod | null = null;
            for (const gateway of this.bankGateways) {
                if (gateway.payment_methods && gateway.payment_methods.length > 0) {
                    if (gateway.type === 'zipi_financial_business' && gateway.status === 'active') {
                        const zipiFinancialDefault = gateway.payment_methods.find(
                            (method) => method.is_default_receiving && method.status === 'active'
                        );
                        if (zipiFinancialDefault) {
                            defaultBankMethod = zipiFinancialDefault;
                            this.setDefaultFeePayer(gateway, 'bank');
                            break;
                        }
                    }
                }
            }
            if (this.payToBankControl && defaultBankMethod) {
                this.payToBankControl.setValue(defaultBankMethod.payment_method_id);
                this.selectedBankMethodsForChips.push(defaultBankMethod);
                this.isBankConvenienceFeeAllowed = defaultBankMethod.payment_gateway?.driver_type === 'payload';
            }
            this.selectDefaultCardMethod();
        } else {
            if (this.payToCardControl && this.payToCardControl.value) {
                for (const gateway of this.cardGateways) {
                    if (gateway.payment_methods && gateway.payment_methods.length > 0) {
                        if (gateway.status === 'active') {
                            const selectedMethod = gateway.payment_methods.find(
                                (method) => method.payment_method_id === this.payToCardControl?.value
                            );
                            if (selectedMethod) {
                                this.isCardConvenienceFeeAllowed =
                                    selectedMethod.payment_gateway?.driver_type === 'payload';
                                this.selectedCardMethodsForChips.push(selectedMethod);
                                break;
                            }
                        }
                    }
                }
            }
            if (this.payToBankControl && this.payToBankControl.value) {
                for (const gateway of this.bankGateways) {
                    if (gateway.payment_methods && gateway.payment_methods.length > 0) {
                        if (gateway.status === 'active') {
                            const selectedMethod = gateway.payment_methods.find(
                                (method) => method.payment_method_id === this.payToBankControl?.value
                            );
                            if (selectedMethod) {
                                this.selectedBankMethodsForChips.push(selectedMethod);
                                this.isBankConvenienceFeeAllowed =
                                    selectedMethod.payment_gateway?.driver_type === 'payload';
                                break;
                            }
                        }
                    }
                }
            }
        }
    }

    selectDefaultCardMethod() {
        let defaultCardMethod: IPaymentMethod | null = null;
        const zipiFinCardGateways = this.cardGateways.filter((gateway) => gateway.driver_type === 'payload');
        for (const gateway of this.cardGateways) {
            if (gateway.payment_methods && gateway.payment_methods.length > 0) {
                if (gateway.type === 'authorize_net_merchant' && gateway.status === 'active') {
                    const authDefault = gateway.payment_methods[0];
                    if (authDefault) {
                        defaultCardMethod = authDefault;
                        break;
                    }
                }
            }
        }
        if (!defaultCardMethod) {
            for (const gateway of zipiFinCardGateways) {
                if (gateway.status === 'active' && gateway.payment_methods && gateway.payment_methods.length > 0) {
                    const zipiFinancialCardDefault = gateway.payment_methods.find(
                        (method) => method.is_default_merchant && method.status === 'active'
                    );
                    if (zipiFinancialCardDefault) {
                        defaultCardMethod = zipiFinancialCardDefault;
                        this.setDefaultFeePayer(gateway, 'card');
                        break;
                    }
                }
            }
        }

        if (this.payToCardControl && defaultCardMethod) {
            this.payToCardControl.setValue(defaultCardMethod.payment_method_id);
            this.selectedCardMethodsForChips = [];
            this.isCardConvenienceFeeAllowed = defaultCardMethod.payment_gateway?.driver_type === 'payload';
            this.selectedCardMethodsForChips.push(defaultCardMethod);
        }
    }

    async selectMethod(method: IPaymentMethod) {
        const allGatewaysByType = this.companyGateways.filter(
            (gateway) => gateway.type === method.payment_gateway?.type
        );

        const allGatewaysMethods: IPaymentMethod[] = [];
        for (const gateway of allGatewaysByType) {
            if (gateway.payment_methods && gateway.payment_methods.length > 0) {
                gateway.payment_methods.forEach((gatewayMethod) => allGatewaysMethods.push(gatewayMethod));
            }
        }

        let currentArrayOfIds: Array<number> = this.payToMethodIdsControl?.value;

        const selectedMethod = allGatewaysMethods.find((gatewayMethod) =>
            currentArrayOfIds.includes(gatewayMethod.payment_method_id as number)
        );
        if (selectedMethod) {
            await this.removeMethod(selectedMethod.payment_method_id as number);
            currentArrayOfIds = this.payToMethodIdsControl?.value;
        }

        currentArrayOfIds.push(method.payment_method_id as number);
        this.payToMethodIdsControl!.setValue(currentArrayOfIds, {emitEvent: false});
        this.selectedMethodsForChips.push(method);
    }

    async selectAuthMethod() {
        if (this.payToCardControl?.value) {
            await this.removeMethodSeparated(this.payToCardControl?.value, 'card');
        }
        for (const gateway of this.cardGateways) {
            if (gateway.payment_methods && gateway.payment_methods.length > 0) {
                if (gateway.type === 'authorize_net_merchant' && gateway.status === 'active') {
                    const authDefault = gateway.payment_methods[0];
                    if (authDefault) {
                        this.payToCardControl?.setValue(authDefault.payment_method_id as number);
                        this.selectedCardMethodsForChips.push(authDefault);
                        this.isCardConvenienceFeeAllowed = false;
                        break;
                    }
                }
            }
        }
    }

    async selectCardMethod(method: IPaymentMethod, gateway: IPaymentGateway & {transfer_customization_ref: string}) {
        if (this.payToCardControl?.value) {
            await this.removeMethodSeparated(this.payToCardControl?.value, 'card');
        }
        this.payToCardControl?.setValue(method.payment_method_id as number);
        this.selectedCardMethodsForChips.push(method);
        this.isCardConvenienceFeeAllowed = method.payment_gateway?.driver_type === 'payload';
        this.setDefaultFeePayer(gateway, 'card');
    }

    async selectBankMethod(method: IPaymentMethod, gateway: IPaymentGateway & {transfer_customization_ref: string}) {
        const selectedMethodId = this.payToBankControl?.value;
        if (selectedMethodId) {
            await this.removeMethodSeparated(selectedMethodId, 'bank');
        }

        this.payToBankControl?.setValue(method.payment_method_id as number);
        this.selectedBankMethodsForChips.push(method);
        this.isBankConvenienceFeeAllowed = method.payment_gateway?.driver_type === 'payload';
        this.setDefaultFeePayer(gateway, 'bank');
    }

    removeMethod(methodId: number) {
        this.selectedMethodsForChips = this.selectedMethodsForChips.filter(
            (method) => method.payment_method_id !== methodId
        );

        const currentArrayOfIds: Array<number> = this.payToMethodIdsControl?.value;

        const index = currentArrayOfIds.indexOf(methodId);

        if (index >= 0) {
            currentArrayOfIds.splice(index, 1);
        }
        this.payToMethodIdsControl!.setValue(currentArrayOfIds, {emitEvent: false});
    }

    removeMethodSeparated(methodId: number, type: 'card' | 'bank') {
        switch (type) {
            case 'card': {
                this.selectedCardMethodsForChips = this.selectedCardMethodsForChips.filter(
                    (method) => method.payment_method_id !== methodId
                );
                this.payToCardControl!.setValue(null);
                this.isCardConvenienceFeeAllowed = false;
                if (this.convenienceFeeCardPayerControl) {
                    this.convenienceFeeCardPayerControl.setValue('money_sender');
                }
                break;
            }
            case 'bank': {
                this.selectedBankMethodsForChips = this.selectedBankMethodsForChips.filter(
                    (method) => method.payment_method_id !== methodId
                );
                this.payToBankControl!.setValue(null);
                this.isBankConvenienceFeeAllowed = false;
                if (this.convenienceFeeBankPayerControl) {
                    this.convenienceFeeBankPayerControl.setValue('money_sender');
                }
                break;
            }
        }
    }

    setDefaultFeePayer(gateway: IPaymentGateway & {transfer_customization_ref: string}, type: 'card' | 'bank') {
        if (gateway.driver_type === 'payload' && gateway.transfer_customization_ref) {
            // 'option_4' means that SS pays all outgoing fees instead of Broker. But all incoming fees will always pay Agent
            if (gateway.transfer_customization_ref === 'option_4') {
                if (type === 'bank') {
                    this.convenienceFeeBankPayerControl?.setValue('money_sender');
                    this.convenienceFeeBankPayerControl?.disable();
                } else {
                    this.convenienceFeeCardPayerControl?.setValue('money_sender');
                    this.convenienceFeeCardPayerControl?.disable();
                }
            } else {
                if (type === 'bank') {
                    this.convenienceFeeBankPayerControl?.setValue(gateway.default_bank_fee_payer);
                    this.convenienceFeeBankPayerControl?.enable();
                } else {
                    this.convenienceFeeCardPayerControl?.setValue(gateway.default_card_fee_payer);
                    this.convenienceFeeCardPayerControl?.enable();
                }
            }
        }
    }

    startRedirectEvent() {
        this.redirectEvent.emit(true);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.redirectEvent.complete();
    }
}
