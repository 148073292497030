import {merge as observableMerge, Observable, Subject} from 'rxjs';
import {startWith, takeUntil} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {DealTemplateEntity} from './deal.models';
import {AppInitSource} from '../../../../../services/sources/app-init.source';

export type DealTemplatesAssoc = {[id: number]: DealTemplateEntity};

@Injectable()
export class DealTemplatesSource implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    public currentDealTemplates: DealTemplatesAssoc | null = null;

    public triggers = {
        loaded: new Subject<DealTemplatesAssoc>()
    };

    private events = {
        changeDealTemplates: <Observable<DealTemplatesAssoc>>observableMerge(this.triggers.loaded)
    };

    constructor(protected appInitSource: AppInitSource) {
        this.dealTemplates.pipe(takeUntil(this.unsubscribe)).subscribe((last) => {
            this.currentDealTemplates = last;
        });

        this.appInitSource.appInit.pipe(takeUntil(this.unsubscribe)).subscribe((last) => {
            this.triggers.loaded.next(last.dealTemplates!);
        });
    }

    public get dealTemplates(): Observable<DealTemplatesAssoc> {
        if (this.currentDealTemplates) {
            return this.events.changeDealTemplates.pipe(
                startWith(this.currentDealTemplates),
                takeUntil(this.unsubscribe)
            );
        }
        return this.events.changeDealTemplates;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
