import {assign} from 'lodash-es';
import {ISettings, ICompanyLocation, ICompanySettings} from '@cyberco-nodejs/zipi-typings';
import {SettingsActionType, SettingsAction} from './settings.actions';

export interface ISettingsState {
    profileSettings: ISettings | null;
    companyLocations: ICompanyLocation[];
    companySettings: ICompanySettings | null;
}

export const INITIAL_SETTINGS_STATE: ISettingsState = {
    profileSettings: null,
    companyLocations: [],
    companySettings: null
};

export function settingsReducer(
    state: ISettingsState = INITIAL_SETTINGS_STATE,
    action: SettingsAction
): ISettingsState {
    switch (action.type) {
        case SettingsActionType.FETCH_PROFILE_SETTINGS_SUCCESS:
            return assign({}, state, {
                profileSettings: action.payload!
            });

        case SettingsActionType.UPDATE_PROFILE_SETTINGS:
            return assign({}, state, {
                profileSettings: action.payload!
            });

        case SettingsActionType.FETCH_COMPANY_LOCATIONS_RESULT:
            return assign({}, state, {
                companyLocations: action.payload!
            });

        case SettingsActionType.CREATE_COMPANY_LOCATION_RESULT:
            return assign({}, state, {
                companyLocations: state.companyLocations.concat(action.payload!)
            });

        case SettingsActionType.UPDATE_COMPANY_LOCATION:
            return assign({}, state, {
                companyLocations: state.companyLocations.map((cl) =>
                    cl.id === action.payload!.id ? action.payload! : cl
                )
            });

        case SettingsActionType.DELETE_COMPANY_LOCATION:
            return assign({}, state, {
                companyLocations: state.companyLocations.filter((cl) => cl.id !== action.payload!)
            });

        // case SettingsActionType.DELETE_COMPANY_LOCATION_RESULT:
        //     if (!action.payload!.ok) { return state; }
        //     // @TODO: handle failed case
        //     return assign(state, {
        //         companyLocations:
        //             state.companyLocations.filter(cl => cl.id !== action.payload!.id)
        //     });

        case SettingsActionType.FETCH_COMPANY_SETTINGS_SUCCESS:
            return assign({}, state, {
                companySettings: action.payload!
            });

        case SettingsActionType.UPDATE_COMPANY_SETTINGS:
            return assign({}, state, {
                companySettings: action.payload!
            });

        default:
            return state;
    }
}
