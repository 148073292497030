<section class="zp-date-range-picker">
    <mat-form-field
        class="zp-input__date mr-2"
        [ngClass]="marginBetween"
        [floatLabel]="floatLabel"
        *ngIf="dateFromCtrl"
    >
        <mat-datepicker-toggle matPrefix [for]="pickerFrom"></mat-datepicker-toggle>
        <input
            matInput
            type="text"
            #fromInput
            [placeholder]="placeholderFrom"
            [max]="maxDate"
            [matDatepicker]="pickerFrom"
            [formControl]="dateFromCtrl"
            (click)="pickerFrom.open()"
            (dateChange)="onFromChange($event)"
        />
        <mat-datepicker #pickerFrom [disabled]="false"></mat-datepicker>
        <mat-icon matSuffix (click)="dateFromCtrl.reset(); onFromChange({value: ''})" class="zp-range-clear-button">
            cancel
        </mat-icon>
    </mat-form-field>

    <mat-form-field class="zp-input__date" [floatLabel]="floatLabel" *ngIf="dateToCtrl">
        <mat-datepicker-toggle matPrefix [for]="pickerTo"></mat-datepicker-toggle>
        <input
            matInput
            type="text"
            #toInput
            [placeholder]="placeholderTo"
            [min]="minDate"
            [matDatepicker]="pickerTo"
            [formControl]="dateToCtrl"
            (click)="pickerTo.open()"
            (dateChange)="onToChange($event)"
        />
        <mat-datepicker #pickerTo [disabled]="false"></mat-datepicker>
        <mat-icon matSuffix (click)="dateToCtrl.reset(); onToChange({value: ''})" class="zp-range-clear-button">
            cancel
        </mat-icon>
    </mat-form-field>
</section>
