import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {IContactInvitesState} from '../../store/contact-invites.reducers';
import {
    FetchContactInviteAction,
    LogoutAndGoToAction,
    SubmitAcceptInviteFormAction
} from '../../store/contact-invites.actions';
import {Observable, Subject} from 'rxjs';
import {IContactInvite, IProfile} from '../../../../../typings';
import {selectContactInvite, selectLoadingState} from '../../store/contact-invites.selectors';
import {UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {SessionService} from '../../../../services/session.service';
import {NotificationsService} from 'angular2-notifications';
import {AuthService} from '../../../../services/auth.service';
import {CompaniesService} from '../../../../services/companies.service';
import {Company} from '../../../../models/company';
import {ContactInvitesService} from '../../services/contact-invites.service';
import {takeUntil} from 'rxjs/operators';
import {Profile} from '../../../../models/profile';

@Component({
    selector: 'app-accept-invite',
    templateUrl: './accept-invite.component.html',
    styleUrls: ['./accept-invite.component.scss']
})
export class AcceptInviteComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    protected inviteHash: string = '';

    contactInvite$: Observable<IContactInvite | undefined> = this.store.select(selectContactInvite);
    contactInvite: IContactInvite | undefined;

    formIsLoading$: Observable<boolean> = this.store.select(selectLoadingState);
    currentProfile: Profile | undefined;

    acceptInviteForm: UntypedFormGroup;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected store: Store<IContactInvitesState>,
        protected fb: UntypedFormBuilder,
        protected authService: AuthService,
        private companiesService: CompaniesService,
        private contactInvitesService: ContactInvitesService,
        public sessionService: SessionService,
        protected notificationsService: NotificationsService,
        protected router: Router
    ) {
        this.acceptInviteForm = this.fb.group({
            accept_method: ['', Validators.required]
        });
    }

    async ngOnInit() {
        this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['contact_invite_hash']) {
                this.inviteHash = params['contact_invite_hash'];
            }
        });

        this.store.dispatch(new FetchContactInviteAction(this.inviteHash));

        this.contactInvite$.pipe(takeUntil(this.unsubscribe)).subscribe((invite) => {
            this.contactInvite = invite;
        });

        if (this.sessionService.profile) {
            this.currentProfile = await this.authService.getCurrentProfile();
        }
    }

    submitAcceptInviteForm() {
        if (!this.acceptInviteForm.valid) {
            return this.notificationsService.error(
                `Form is not valid - ${this.getFormValidationErrors(this.acceptInviteForm)}`
            );
        }

        if (this.contactInvite && this.acceptInviteForm.controls.accept_method.value === 'create_new_company') {
            const company = new Company();
            company.title = `${this.contactInvite.invited_email}_${Date.now()}`;

            if (this.contactInvite.company_type === 'zipi_contact') {
                this.contactInvitesService
                    .createCompany(company)
                    .then((res) => {
                        this.store.dispatch(
                            new SubmitAcceptInviteFormAction({
                                accept_method: this.acceptInviteForm.controls.accept_method.value
                            })
                        );

                        return res;
                    })
                    .then((res) => {
                        // @ts-ignore
                        this.authService.setCurrentProfile(res, true).then(() => {
                            this.router.navigate(['/default-page']);
                        });
                    });
            }
        } else {
            return this.store.dispatch(
                new SubmitAcceptInviteFormAction({
                    accept_method: this.acceptInviteForm.controls.accept_method.value
                })
            );
        }
    }

    getFormValidationErrors(formGroup: UntypedFormGroup): string {
        const errors: [] = [];

        Object.keys(formGroup.controls).forEach((key) => {
            // @ts-ignore
            const controlErrors: ValidationErrors = formGroup.get(key).errors;

            if (controlErrors !== null) {
                Object.keys(controlErrors).forEach((keyError) => {
                    // @ts-ignore
                    errors.push(`${key}: ${keyError}`);
                });
            }
        });

        return errors.join(', ');
    }

    async logoutAndGoTo(goTo: string[], authInfoMessage?: string) {
        return this.store.dispatch(new LogoutAndGoToAction({goTo, authInfoMessage}));
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
