<div class="mat-tab-background">
    <div
        *ngIf="
            isMasterToggleChecked &&
            listLength &&
            listLength > scrollData.limit &&
            selection.selected.length !== listLength
        "
        class="select-all-banner"
    >
        All {{ dataSource.data.length }} transactions on this page are selected.&nbsp;
        <a class="select-all-link" (click)="selectAll()">Select all {{ listLength }} transactions?</a>
    </div>
    <div *ngIf="isAllSelected" class="select-all-banner">
        All {{ listLength }} transactions are selected.&nbsp;
        <a class="select-all-link" (click)="clearSelection()">Clear selections?</a>
    </div>
    <table
        mat-table
        [dataSource]="dataSource"
        [ngClass]="{'d-none': dataSource.data.length === 0}"
        matSort
        matSortDisableClear
        [matSortActive]="scrollData.sort_column"
        [matSortDirection]="scrollData.sort_direction"
        (matSortChange)="sortData($event)"
        class="mat-elevation-z8 w-100"
    >
        <ng-container matColumnDef="bulkselect">
            <th mat-header-cell *matHeaderCellDef disableClear>
                <mat-checkbox
                    #bulkSelect
                    *ngIf="selectedTransactions.length === 0"
                    class="checkbox-area"
                    [ngClass]="{checked: isAllSelected || selection.hasValue()}"
                    (change)="$event ? masterToggle() : null"
                    [checked]="isAllSelected || isEntirePageSelected()"
                >
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()" class="col-select">
                <mat-checkbox
                    class="checkbox-area"
                    *ngIf="selectedTransactions.length === 0"
                    [ngClass]="{checked: selection.isSelected(element.transaction_external_id) || isAllSelected}"
                    (change)="toggleBulkCheckbox($event, element)"
                    [value]="element.transaction_external_id"
                    [checked]="selection.isSelected(element.transaction_external_id) || isAllSelected"
                >
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef disableClear mat-sort-header class="col-date">Date</th>
            <td mat-cell *matCellDef="let element" class="col-date">
                {{ element?.date | dateFromNumber | date: 'mediumDate' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell class="col-name" *matCellDef="let element">{{ element?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="match_status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell class="col-status" style="text-transform: capitalize" *matCellDef="let element">
                <div *ngIf="element.is_pending">Pre Auth</div>
                <div *ngIf="!element.is_pending">{{ element?.match_status }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="debit">
            <th mat-header-cell *matHeaderCellDef class="col-debit-header">
                {{ ledgerAccount && ledgerAccount.type === 'credit_card' ? 'Payments/Credits' : 'Deposit' }}
            </th>
            <td mat-cell *matCellDef="let element" class="col-debit">
                <span *ngIf="element?.debit_or_credit === 'debit'">
                    {{ element?.amount | currency: element?.iso_currency_code : 'symbol' }}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="credit">
            <th mat-header-cell *matHeaderCellDef class="col-debit-header">
                {{ ledgerAccount && ledgerAccount.type === 'credit_card' ? 'Purchases' : 'Withdrawal' }}
            </th>
            <td mat-cell *matCellDef="let element" class="col-debit">
                <span *ngIf="element?.debit_or_credit === 'credit'">
                    {{ element?.amount | currency: element?.iso_currency_code : 'symbol' }}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="w-5">Action</th>
            <td mat-cell *matCellDef="let element" class="w-5">
                <button
                    mat-icon-button
                    (click)="$event.stopPropagation(); $event.preventDefault()"
                    [matMenuTriggerFor]="menu"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button
                        mat-menu-item
                        *ngIf="
                            !element.is_pending &&
                            element?.match_status.toLowerCase() === 'unmatched' &&
                            !element.deleted_at
                        "
                        (click)="deleteTransaction(element)"
                    >
                        Delete
                    </button>
                    <button mat-menu-item *ngIf="element.deleted_at" (click)="restoreTransaction(element)">
                        Restore
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="header-row"></tr>
        <tr
            mat-row
            *matRowDef="let element; columns: displayedColumns"
            (click)="toggleRowTransaction($event, element)"
            [ngClass]="{selected: isTransactionSelected(element)}"
            class="banking-row"
        ></tr>
    </table>
    <mat-paginator
        class="mat-paginator-sticky"
        [ngClass]="{'d-none': dataSource.data.length === 0}"
        [length]="listLength"
        [pageSize]="scrollData.limit"
        [pageSizeOptions]="pageSizeOptions"
        showFirstLastButtons="false"
    ></mat-paginator>

    <div class="p-3 h-50 d-flex flex-column align-items-center justify-content-around" *ngIf="listLength === 0">
        <p
            class="text-muted"
            *ngIf="!(ledgerAccount?.status === 'active' && ledgerAccount?.activate_feed_from) && !isAvailableForFeed"
        >
            No transactions.
        </p>
        <p
            class="d-flex align-items-center"
            style="color: #14aaf5; cursor: pointer"
            (click)="activateFeed.emit()"
            *ngIf="ledgerAccount?.status === 'active' && !ledgerAccount?.activate_feed_from && isAvailableForFeed"
        >
            <span style="text-decoration: underline">Click here to activate bank feed</span>
            <mat-icon class="mx-1" style="text-decoration: none"> wifi_off </mat-icon>
        </p>
        <p
            class="text-muted"
            *ngIf="ledgerAccount?.status === 'active' && ledgerAccount?.activate_feed_from && filterType !== 'deleted'"
        >
            Feeds activated, but transactions not fetched yet.
        </p>
        <button
            mat-raised-button
            *ngIf="ledgerAccount?.status === 'active' && ledgerAccount?.activate_feed_from && filterType !== 'deleted'"
            (click)="fetchTransactions.emit()"
        >
            Fetch Now
        </button>
    </div>
</div>
