<!------- Table of invoices ------->
<div class="table-background">
    <table
        mat-table
        [dataSource]="dataSource"
        [ngClass]="{'d-none': dataSource.data.length === 0}"
        matSort
        matSortDisableClear
        matSortActive="{{ scrollData.sort_column }}"
        [matSortDirection]="scrollData.sort_direction"
        (matSortChange)="changeSort($event)"
        class="w-100"
    >
        <ng-container matColumnDef="deal_owner">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25">Deal Owner</th>
            <td mat-cell *matCellDef="let element" class="w-25">
                {{ element?.profile?.first_name }} {{ element?.profile?.last_name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-30">Address</th>
            <td mat-cell *matCellDef="let element" class="w-30 px-1">{{ element?.address }} {{ element?.city }}</td>
        </ng-container>

        <ng-container matColumnDef="sales_price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">Sales Price</th>
            <td mat-cell *matCellDef="let element" class="w-15 px-1 text-right">
                {{ element?.sales_price | currency: 'USD' : 'symbol' : '3.0' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="close_of_escrow">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20">Close of Escrow</th>
            <td mat-cell *matCellDef="let element" class="w-20 px-1">
                {{ element?.close_of_escrow | date: 'mediumDate' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Status</th>
            <td mat-cell *matCellDef="let element" class="w-10 status-box">
                <span [ngClass]="element.status">{{ element.status | titlecase }}</span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [routerLink]="['/deals/', row.id]"
            class="zp-table-row"
        ></tr>
    </table>

    <!------- Paginator ------->
    <div class="custom-paginator-background" style="width: 638px"></div>
    <mat-paginator
        class="custom-paginator"
        [length]="scrollData.total"
        [pageSize]="scrollData.limit"
        [pageSizeOptions]="pageSizeOptions"
    >
    </mat-paginator>
    <!------- No values ------->
    <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-center p-4 text-muted">No deals found</div>
    <!------- Loading ------->
    <div class="load-container" id="load-container" *ngIf="!dataSource.data">
        <div class="loader">Loading...</div>
    </div>
</div>
