<mat-card class="sign-up-card" *ngIf="isLegal">
    <mat-card-header>
        <div class="title">Create Account</div>
    </mat-card-header>

    <mat-card-content>
        <form #form="ngForm" method="post">
            <div class="flex-row">
                <mat-form-field>
                    <input matInput required type="text" ngModel name="first_name" placeholder="First Name" />
                </mat-form-field>

                <mat-form-field>
                    <input matInput required type="text" ngModel name="last_name" placeholder="Last Name" />
                </mat-form-field>
            </div>

            <div class="flex-row">
                <mat-form-field>
                    <input matInput type="text" ngModel name="account_name" placeholder="Account Name (optional)" />
                </mat-form-field>
            </div>

            <div class="flex-row">
                <mat-form-field>
                    <input matInput required type="email" ngModel name="email" placeholder="Email" />
                </mat-form-field>
            </div>

            <div class="flex-row">
                <mat-form-field>
                    <input
                        matInput
                        required
                        type="password"
                        ngModel
                        name="password"
                        placeholder="Password"
                        autocomplete="new-password"
                    />
                </mat-form-field>
            </div>

            <div class="flex-row">
                <mat-form-field>
                    <input
                        matInput
                        required
                        type="password"
                        ngModel
                        name="repeat_password"
                        placeholder="Repeat Password"
                        autocomplete="new-password"
                    />
                </mat-form-field>
            </div>

            <div style="text-align: center">
                <button mat-button class="mat-primary" (click)="onSubmit(form)">continue</button>
                <br />
                <a [routerLink]="['/auth', 'sign-in']">Already have account? Sign In ...</a>
            </div>
        </form>
    </mat-card-content>
</mat-card>
