<div>
    <mat-form-field [ngClass]="{'main-filter': showRemoveButton}" *ngIf="control && dealFilter!.type === 'text'">
        <input matInput [formControl]="control" [placeholder]="dealFilter?.placeholder || ''" />
    </mat-form-field>

    <mat-form-field [ngClass]="{'main-filter': showRemoveButton}" *ngIf="control && dealFilter!.type === 'date'">
        <input
            matInput
            [matDatepicker]="closeOfEscrowAfter"
            (dateChange)="changeElementDate($event)"
            [formControl]="control"
            [placeholder]="dealFilter?.placeholder || ''"
        />
        <mat-datepicker-toggle matSuffix [for]="closeOfEscrowAfter"></mat-datepicker-toggle>
        <mat-datepicker #closeOfEscrowAfter></mat-datepicker>
    </mat-form-field>

    <mat-form-field [ngClass]="{'main-filter': showRemoveButton}" *ngIf="dealFilter!.type === 'dropdown'">
        <mat-select
            [placeholder]="dealFilter!.placeholder!"
            [multiple]="dealFilter!.isMultiple"
            [formControl]="control!"
        >
            <mat-option
                *ngFor="let option of dealFilter!.options"
                [value]="option.value"
                [ngClass]="{'font-italic text-muted': option.value === 'null'}"
            >
                {{ option.title }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field [ngClass]="{'main-filter': showRemoveButton}" *ngIf="dealFilter!.type === 'division-dropdown'">
        <mat-select
            [placeholder]="dealFilter!.placeholder!"
            [multiple]="dealFilter!.isMultiple"
            [formControl]="control!"
        >
            <mat-option *ngFor="let option of divisionDropdownOptions" [value]="option.value">
                {{ option.title }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field [ngClass]="{'main-filter': showRemoveButton}" *ngIf="dealFilter!.type === 'tags-dropdown'">
        <mat-select
            [placeholder]="dealFilter!.placeholder!"
            [multiple]="dealFilter!.isMultiple"
            [formControl]="control!"
        >
            <mat-option
                *ngFor="let option of tagsDropdownOptions"
                [value]="option.value"
                (click)="unselectDeactivatedTag(option)"
                [disabled]="option.deactivated"
                [matTooltip]="option.deactivated ? 'This Tag couldn\'t be selected because it\'s deactivated' : ''"
            >
                {{ option.title }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field [ngClass]="{'main-filter': showRemoveButton}" *ngIf="control && dealFilter!.type === 'number'">
        <input matInput type="number" [formControl]="control" [placeholder]="dealFilter?.placeholder || ''" />
    </mat-form-field>

    <div class="contact-picker" *ngIf="dealFilter!.type === 'contact-picker'">
        <app-company-compensation-combined-picker
            style="width: 78%"
            [singleSelected]="true"
            [bold_style]="false"
            [panelWidth]="'250px'"
            [nodesFA]="nodesFA!"
        >
        </app-company-compensation-combined-picker>

        <button
            style="min-width: 40px"
            (click)="removeFilter.emit()"
            class="col-1 wildcard-remove-button"
            mat-icon-button
            type="button"
            matTooltip="Remove"
        >
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div
        *ngIf="dealFilter!.type === 'advanced-filter'"
        style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center"
    >
        <app-deal-filter
            style="display: flex"
            *ngFor="let filter of dealFilter!.childrenFilters; index as filterIndex"
            [dealFilter]="filter"
            [showRemoveButton]="false"
            [control]="$any(advancedFilterFormGroup!.controls[filter!.name!])"
            (removeFilter)="removeFilter.emit()"
        >
        </app-deal-filter>
        <button
            style="max-width: 40px"
            (click)="removeFilter.emit()"
            class="col-1 wildcard-remove-button"
            mat-icon-button
            type="button"
            matTooltip="Remove"
        >
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <button
        *ngIf="showRemoveButton && dealFilter!.type !== 'contact-picker' && dealFilter!.type !== 'advanced-filter'"
        style="max-width: 40px"
        (click)="removeFilter.emit()"
        class="col-1 wildcard-remove-button"
        mat-icon-button
        type="button"
        matTooltip="Remove"
    >
        <mat-icon>close</mat-icon>
    </button>
</div>
