import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {AuthService} from '../../../services/auth.service';
import {SessionService} from '../../../services/session.service';
import {CurrentProfileSource} from '../../../services/sources/current-profile.source';
import {Observable} from 'rxjs';
import {listOfReportTypeByPermissions} from '../constants';
import {RbacService} from '../../rbac/rbac.service';
import {IReport} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class ReportsService {
    protected baseUrl = '/reports';

    constructor(
        public requester: ServiceRequester,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected currentProfileSource: CurrentProfileSource,
        private rbacService: RbacService
    ) {}

    /**
     * Get report
     * @param id
     */
    getReport(id: string | number) {
        return this.requester.makeMsCall$(`${this.baseUrl}/${id}`, 'GET');
    }

    /**
     * Get report create context
     */
    getCreateReportContext(): Promise<any> {
        return this.requester.makeMsCallGlobalAllowed(this.baseUrl + '/context', 'GET');
    }

    /**
     * Save to csv and get link
     * @param presetId
     * @param reportType
     */
    saveToCsvAndGetLink(presetId: number, reportType: string): Promise<string> {
        return this.requester.makeMsCallGlobalAllowed(this.baseUrl + '/deals/get-as-csv', 'GET', {
            preset_id: presetId,
            report_type: reportType
        });
    }

    getReports(params = {}): Observable<{result: IReport[]}> {
        return this.requester.makeMsCall$(this.baseUrl, 'GET', 'zipi', params);
    }

    getRecommendedReportsPresetsList(): Observable<{result: IReport[]}> {
        return this.requester.makeMsCall$(`${this.baseUrl}/recommended`, 'GET', 'zipi');
    }

    createReport(data: object): Observable<object | any> {
        return this.requester.makeMsCall$(this.baseUrl, 'POST', 'zipi', data);
    }

    /**
     * Get report data by id
     * @param reportId
     * @param params
     */
    getReportDataById(reportId: number, params: {[key: string]: string | number} = {}): Observable<object | any> {
        const queryParams = this.prepareQueryParams(params);
        let url = this.baseUrl + '/data/' + reportId;
        if (queryParams.length) {
            url += `?${queryParams}`;
        }

        return this.requester.makeMsCall$(url, 'GET', 'zipi');
    }

    /**
     * Get report data by params
     * @param data
     * @param params
     */
    getReportDataByParams(data: IReport | null, params: {[key: string]: string | number}): Observable<object | any> {
        const queryParams = this.prepareQueryParams(params);
        let url = this.baseUrl + '/data';
        if (queryParams.length) {
            url += `?${queryParams}`;
        }

        return this.requester.makeMsCall$(url, 'POST', 'zipi', data as {});
    }

    /**
     * Prepare query params
     * @param queryParams
     */
    prepareQueryParams(queryParams: {[key: string]: string | number}): string {
        const params = [];
        for (const props in queryParams) {
            if (queryParams[props] !== undefined) {
                params.push(`${props}=${queryParams[props]}`);
            }
        }
        return params.length ? params.join('&') : '';
    }

    /**
     * Update report
     * @param id
     * @param data
     */
    updateReport(id: number, data: object): Observable<boolean> {
        return this.requester.makeMsCall$(this.baseUrl + '/' + id, 'PUT', 'zipi', data);
    }

    deleteReportPreset(id: number): Observable<boolean> {
        return this.requester.makeMsCall$(`${this.baseUrl}/${id}`, 'DELETE');
    }

    exportReportToCSV(body: object, params: {[key: string]: string | number}): Promise<any> {
        const queryParams = this.prepareQueryParams(params);
        return this.requester.makeMsCall(
            `${this.baseUrl}/export-csv${queryParams ? `?${queryParams}` : ``}`,
            'POST',
            body
        );
    }

    exportReportToIrisCSV(body: object, params: {[key: string]: string | number}): Promise<any> {
        const queryParams = this.prepareQueryParams(params);
        return this.requester.makeMsCall(
            `${this.baseUrl}/export-iris-csv${queryParams ? `?${queryParams}` : ``}`,
            'POST',
            body
        );
    }

    exportReportToPdf(body: object, params: {[key: string]: string | number}): Promise<any> {
        const queryParams = this.prepareQueryParams(params);
        return this.requester.makeMsCall(
            `${this.baseUrl}/export-pdf${queryParams ? `?${queryParams}` : ``}`,
            'POST',
            body
        );
    }

    checkIsAllowReport(report: IReport): Promise<boolean> {
        if (!report || !report.type) {
            return Promise.resolve(false);
        }
        const permission = listOfReportTypeByPermissions[report.type];
        return this.rbacService.isAllowed({[permission]: true});
    }

    updateContactPayrollOverride(body: object, reportId: number | null | undefined): Promise<any> {
        return this.requester.makeMsCall(`${this.baseUrl}/${reportId}/payroll-override`, 'PATCH', body);
    }
}
