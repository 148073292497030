<!--<mat-sidenav-container class="pw-sidenav-container">-->
<!--<mat-sidenav mode="side" opened="true" class="pw-sidenav mat-elevation-z2">-->

<!--<div class="menu-line" [routerLink]="['/profile']" >-->
<!--<div>Profile</div>-->
<!--</div>-->
<!--<div class="menu-line" [routerLinkActive]="['is-active']">-->
<!--<button mat-button [routerLink]="['access']">Access</button>-->
<!--</div>-->
<!--&lt;!&ndash;<div class="menu-line" [routerLinkActive]="['is-active']">&ndash;&gt;-->
<!--&lt;!&ndash;<button mat-button [routerLink]="['reminder']">Reminders</button>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--<div class="menu-line" [routerLinkActive]="['is-active']">-->
<!--<button mat-button [routerLink]="['commission']">Commission</button>-->
<!--</div>-->
<!--<div class="menu-line" [routerLinkActive]="['is-active']">-->
<!--<button mat-button [routerLink]="['association']">Association</button>-->
<!--</div>-->
<!--<div class="menu-line" [routerLinkActive]="['is-active']">-->
<!--<button mat-button [routerLink]="['addons']">Addons</button>-->
<!--</div>-->
<!--</mat-sidenav>-->

<div class="page-content">
    <router-outlet></router-outlet>
</div>
<!--</mat-sidenav-container>-->
