import {Subject} from 'rxjs';
import {filter, startWith, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AfterViewInit, ChangeDetectorRef, Component, ErrorHandler, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DeleteLedgerAccount} from '../../../finance/store/finance.actions';
import {LEDGER_TYPES_MAP, PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {NotificationsService} from 'angular2-notifications';
import {CompanyGatewayService} from 'app/services/api/finance/company-gateway.service';
import {CompanyPaymentMethodsService} from 'app/services/api/finance/company-payment-methods.service';
import {CreateLedgerAccountDialogComponent} from '../../../finance/components/ledger-accounts/create-ledger-account-dialog/create-ledger-account-dialog.component';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {IFinanceState} from '../../../finance/store/finance.reducer';
import {LedgerAccountService} from 'app/services/api/finance/ledger-accounts.service';
import {ILedgerAccount, IPaymentGateway, LedgerAccountTypes} from '@cyberco-nodejs/zipi-typings';
import {ManualMapAccountTypeDialogComponent} from '../common/manual-map-account-type-dialog/manual-map-account-type-dialog.component';
import {BankingConnectToLedgerAccountDialogComponent} from '../common/banking-connect-to-ledger-account-dialog/banking-connect-to-ledger-account-dialog.component';
import {ActivateFeedDialogComponent} from '../common/activate-feed-dialog/activate-feed-dialog.component';
import * as moment from 'moment';
import {LoggingApiService} from 'app/services/api/logging-api.service';
import {CustomSelectBankAccountDialogComponent} from '../common/custom-select-bank-account-dialog/custom-select-bank-account-dialog.component';
import {UntypedFormControl} from '@angular/forms';
import {GatewayService} from '../../../profile/services/gateway.service';
import {UpdatePlaidCredsByLinkDialogComponent} from '../common/update-plaid-creds-by-link-dialog/update-plaid-creds-by-link-dialog.component';
import {IScrollData} from 'app/models/scroll-data';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort, SortDirection} from '@angular/material/sort';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {ActivatedRoute} from '@angular/router';

const win: {[key: string]: any} = window;

@Component({
    selector: 'app-banking-accounts-page',
    templateUrl: 'banking-accounts-page.component.html',
    styleUrls: [
        '../banking.component.scss',
        'banking-accounts-page.component.scss',
        '../../../../../assets/infinite-scroll-table.scss'
    ]
})
export class BankingAccountsPageComponent implements OnInit, OnDestroy, AfterViewInit {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();

    @ViewChild(MatSort, {static: false}) sort: MatSort = new MatSort();
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator = new MatPaginator(
        new MatPaginatorIntl(),
        ChangeDetectorRef.prototype
    );

    ledgerTypesMap: {[key in LedgerAccountTypes]: string} = LEDGER_TYPES_MAP;

    linkHandler: any;

    statusTypeFC = new UntypedFormControl('active');
    statusTypes = [
        {
            value: 'all',
            label: 'All'
        },
        {
            value: 'active',
            label: 'Active'
        },
        {
            value: 'inactive',
            label: 'Deactivated'
        }
    ];

    scrollData: IScrollData = {
        // offset: 0,
        // limit: 50,
        sort_column: 'name',
        sort_direction: 'asc' as SortDirection,
        filter: this.statusTypeFC.value,
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<ILedgerAccount & {isNeedToReLogin: boolean}>;
    displayedColumns = ['icon', 'name', 'description', 'logo', 'type', 'action'];

    gateway: IPaymentGateway | undefined;

    redirect: string | undefined | null;

    constructor(
        private financeStore: Store<IFinanceState>,
        private notificationsService: NotificationsService,
        private ledgerAccountService: LedgerAccountService,
        private companyGatewayService: CompanyGatewayService,
        private gatewayService: GatewayService,
        private companyPaymentMethodsService: CompanyPaymentMethodsService,
        protected dialog: MatDialog,
        private errorHandler: ErrorHandler,
        private loggingApiService: LoggingApiService,
        protected activatedRoute: ActivatedRoute
    ) {
        this.dataSource = new MatTableDataSource<ILedgerAccount & {isNeedToReLogin: boolean}>([]);
    }

    ngOnInit() {
        this.statusTypeFC.valueChanges
            .pipe(startWith('active'), takeUntil(this.unsubscribe))
            .subscribe((statusType) => {
                this.scrollData.filter = statusType;

                this.resetData();
            });
        this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['oauth_state_id']) {
                this.redirect = window.location.href;
                const type = localStorage.getItem('zipi_banking_redirect_account_type');
                if (!type) {
                    return;
                }
                this.open(type === 'trust', null);
            }
        });
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    nextBatch() {
        // cancel previous batch requests
        this.unsubscribeBatch.next();

        this.ledgerAccountService
            .getBankingLedgerAccounts(this.scrollData)
            .pipe(takeUntil(this.unsubscribeBatch))
            .subscribe((data) => {
                this.dataSource.data = data.result.map((ledger: ILedgerAccount) => {
                    const la: ILedgerAccount & {
                        isNeedToReLogin: boolean;
                        isNeedToReconnect: boolean;
                        isZipiPayAccount: boolean;
                        isZipiFinancialAccount: boolean;
                    } = Object.assign(ledger, {
                        isNeedToReLogin: false,
                        isNeedToReconnect: false,
                        isZipiPayAccount: false,
                        isZipiFinancialAccount: false
                    });

                    const bankingMethod = ledger.connected_payment_methods?.find(
                        (method) => method.payment_gateway && method.payment_gateway.type === 'banking'
                    );
                    if (bankingMethod && bankingMethod.plaid_refs.pending_auth_status === 'need_relogin') {
                        la.isNeedToReLogin = true;
                    }
                    if (bankingMethod && bankingMethod.plaid_refs.pending_auth_status === 'need_reconnect') {
                        la.isNeedToReconnect = true;
                    }
                    if (
                        ledger.connected_payment_methods?.length &&
                        ledger.connected_payment_methods.some(
                            (method) =>
                                method.payment_gateway &&
                                ['zipi_financial_business', 'zipi_financial_trust'].includes(
                                    method.payment_gateway.type
                                )
                        )
                    ) {
                        la.isZipiFinancialAccount = true;
                    }

                    return la;
                });

                this.scrollData.total = data._meta.total;
            });
    }

    resetData() {
        this.scrollData.total = 0;

        this.nextBatch();
    }

    async open(isTrustAccount: boolean, account: (ILedgerAccount & {isNeedToReLogin: boolean}) | null = null) {
        if (account && !account.activate_feed_from && this.isAvailableForFeed(account)) {
            this.activateFeed(account, false);
            return;
        }

        this.gateway = await this.createOrGetBankingGateway();
        let token = null;
        if (this.redirect) {
            token = localStorage.getItem('zipi_pl_link_token');
        } else if (this.gateway) {
            token = await this.getPlaidLinkToken(this.gateway.payment_gateway_id as number, {
                products: ['transactions'],
                linkCustomizationName: '',
                account_type: isTrustAccount ? 'trust' : 'operating'
            });
            localStorage.setItem('zipi_pl_link_token', token || '');
            localStorage.setItem('zipi_banking_redirect_account_type', isTrustAccount ? 'trust' : 'operating');
        }

        this.linkHandler = win.Plaid.create({
            token: token,
            receivedRedirectUri: this.redirect,
            onLoad: function () {
                // The Link module finished loading.
            },
            onSuccess: async (public_token: any, metadata: any) => {
                this.connectBankAccount(public_token, metadata, isTrustAccount, account);
            },
            onExit: (err: any, metadata: any) => {
                if (err !== null) {
                    if (err.hasOwnProperty('error_message')) {
                        this.notificationsService.error(err.error_message);
                    } else {
                        this.notificationsService.error(err);
                    }
                }
            },
            onEvent: (eventName: string, metadata: any) => {
                switch (eventName) {
                    case 'ERROR': {
                        this.errorHandler.handleError(`Plaid Error: ${JSON.stringify(metadata)}`);
                        localStorage.removeItem('zipi_pl_link_token');
                        localStorage.removeItem('zipi_banking_redirect_account_type');
                        this.redirect = null;
                        break;
                    }
                    case 'OPEN': {
                        this.loggingApiService
                            .losPlaidBankingSession(metadata)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe();

                        break;
                    }
                    case 'HANDOFF': {
                        this.loggingApiService
                            .losPlaidBankingSession(metadata)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe();

                        break;
                    }
                }
            }
        });
        this.linkHandler.open();
    }

    async connectBankAccount(
        public_token: string,
        metadata: any,
        isTrustAccount: boolean,
        existingLedgerAccount: (ILedgerAccount & {isNeedToReLogin: boolean}) | null = null
    ) {
        let selectedBankAccount = null;
        if (metadata.accounts && metadata.accounts.length > 1) {
            const dialogRef = this.dialog.open(CustomSelectBankAccountDialogComponent, {
                width: '350px',
                height: '500px',
                data: {
                    metadata: metadata,
                    isTrustAccount: isTrustAccount
                }
            });

            selectedBankAccount = await dialogRef.afterClosed().toPromise();
        } else if (metadata.accounts && metadata.accounts.length === 1) {
            selectedBankAccount = metadata.accounts[0];
        } else {
            this.notificationsService.error(`No Bank Accounts`);
        }

        if (!selectedBankAccount) {
            this.notificationsService.error(`No Bank Accounts Selected`);
            localStorage.removeItem('zipi_pl_link_token');
            localStorage.removeItem('zipi_banking_redirect_account_type');
            this.redirect = null;
            return;
        }

        const title = `${metadata.institution.name} (${selectedBankAccount.subtype.charAt(0).toUpperCase() + selectedBankAccount.subtype.slice(1)}, ${selectedBankAccount.mask})`;

        let ledgerAccountId = null;

        if (existingLedgerAccount) {
            ledgerAccountId = existingLedgerAccount.ledger_account_id;
        } else {
            const dialogRef = this.dialog.open(BankingConnectToLedgerAccountDialogComponent, {
                data: {
                    is_trust: isTrustAccount,
                    accountType: selectedBankAccount.subtype
                }
            });

            ledgerAccountId = await dialogRef.afterClosed().toPromise();

            if (ledgerAccountId === false) {
                return;
            }
        }

        const paymentMethodSettings: {
            public_token: string;
            account_id: any;
            title: string;
            type: any;
            subtype: any;
            manual_account_type: string | null;
            related_ledger_account_id: any;
            trust_account: boolean;
        } = {
            public_token: public_token,
            account_id: selectedBankAccount.id,
            title: title,
            type: selectedBankAccount.type,
            subtype: selectedBankAccount.subtype,
            manual_account_type: null,
            related_ledger_account_id: ledgerAccountId,
            trust_account: isTrustAccount
        };

        if (isTrustAccount) {
            paymentMethodSettings.manual_account_type = 'trust';
        } else if (
            [
                'loan account',
                'auto',
                'commercial',
                'construction',
                'consumer',
                'home',
                'home equity',
                'loan',
                'mortgage',
                'overdraft',
                'line of credit',
                'student'
            ].includes(selectedBankAccount.subtype)
        ) {
            paymentMethodSettings.manual_account_type = await this.manualAccountTypeSelect();
        }

        if (typeof this.gateway !== 'undefined') {
            this.createPaymentMethod(
                this.gateway.payment_gateway_id as number,
                paymentMethodSettings,
                existingLedgerAccount
            );
        }
    }

    createAccount() {
        const dialogRef = this.dialog.open(CreateLedgerAccountDialogComponent, {
            width: '800px',
            panelClass: 'no-padding-dialog',
            minHeight: 320,
            maxHeight: '80vh',
            data: {
                banking_mode: true
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((la) => !!la),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.resetData();
            });
    }

    async manualAccountTypeSelect() {
        const dialogRef = this.dialog.open(ManualMapAccountTypeDialogComponent, {
            minWidth: 320,
            data: {}
        });

        return await dialogRef.afterClosed().toPromise();
    }

    isAvailableForFeed(element: ILedgerAccount) {
        return (
            (['bank', 'credit_card', 'trust'].includes(element.type) &&
                element.connected_payment_methods &&
                element.connected_payment_methods.some(
                    (method) =>
                        method.is_available_for_banking ||
                        ['zipi_financial_balance', 'zipi_financial_cloud_bank'].includes(method.type)
                )) ||
            element.system_key === 'zipi_pay'
        );
    }

    isAvailableForConnect(element: ILedgerAccount) {
        return (
            ['bank', 'credit_card', 'trust'].includes(element.type) &&
            element.status === 'active' &&
            (!element.connected_payment_methods?.length ||
                !element.connected_payment_methods.some(
                    (method) =>
                        ['zipi_financial_balance', 'zipi_financial_cloud_bank'].includes(method.type) ||
                        (method.payment_gateway && ['banking'].includes(method.payment_gateway.type))
                ))
        );
    }

    isAvailableForDisconnect(element: ILedgerAccount) {
        return (
            element.connected_payment_methods &&
            element.connected_payment_methods.some(
                (method) =>
                    method.is_available_for_banking ||
                    ['zipi_financial_balance', 'zipi_financial_cloud_bank'].includes(method.type)
            )
        );
    }

    getFeedTooltip(element: ILedgerAccount & {isNeedToReLogin: boolean; isNeedToReconnect: boolean}) {
        if (element.isNeedToReLogin) {
            return `You must update your Authentication Credentials.`;
        }
        if (element.isNeedToReconnect) {
            return `You must reconnect Bank Account.`;
        }
        if (element.activate_feed_from) {
            return `Feed activated from ${moment(element.activate_feed_from, 'YYYYMMDD').format('MMM DD, YYYY')}`;
        }

        return 'Feed is inactive.';
    }

    createPaymentMethod(
        gatewayId: number,
        settings: {
            public_token: string;
            account_id: any;
            title: string;
            type: any;
            subtype: any;
            manual_account_type: string | null;
            related_ledger_account_id: any;
            trust_account: boolean;
        },
        accountToActivateFeed: (ILedgerAccount & {isNeedToReLogin: boolean}) | null = null
    ) {
        localStorage.removeItem('zipi_pl_link_token');
        localStorage.removeItem('zipi_banking_redirect_account_type');
        this.redirect = null;
        this.companyPaymentMethodsService
            .createBankingPaymentMethod(gatewayId, settings)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                if (response) {
                    this.notificationsService.success(`Settings saved`);
                    this.ngOnInit();
                    if (accountToActivateFeed) {
                        this.activateFeed(accountToActivateFeed, true);
                    }
                }
            });
    }

    async createOrGetBankingGateway() {
        return await this.companyGatewayService.createOrGetBankingGateway().toPromise();
    }

    deleteAccount(account: ILedgerAccount & {isNeedToReLogin: boolean}) {
        if (!account) {
            return;
        }

        this.ledgerAccountService
            .getLedgerAccountById(account.ledger_account_id as number)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                let dialogMessage = '';
                let isNeedHideCancelButton = false;
                if (!res.has_matched_transactions) {
                    dialogMessage = `Bank Account "${account.name}" will be deleted`;
                } else {
                    dialogMessage = `Account "${account.name}" has matched transactions, then the bank account can not be deleted.`;
                    isNeedHideCancelButton = true;
                }

                const dialogRef = this.dialog.open(ConfirmComponent, {
                    minWidth: 320,
                    data: {
                        title: 'Deleting Bank Account',
                        message: dialogMessage,
                        hideCancel: isNeedHideCancelButton
                    }
                });

                dialogRef
                    .afterClosed()
                    .pipe(
                        filter((pn) => !!pn),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe((ok) => {
                        if (ok && !res.has_matched_transactions) {
                            this.financeStore.dispatch(new DeleteLedgerAccount(account.id as number));
                            this.resetData();
                        }
                    });
            });
    }

    activateFeed(account: ILedgerAccount & {isNeedToReLogin: boolean}, isEndOfConnectToPlaid = false) {
        const dialogRef = this.dialog.open(ActivateFeedDialogComponent, {
            width: '500px',
            autoFocus: false
        });

        dialogRef.componentInstance.ledgerAccount = account;
        dialogRef.componentInstance.isEndOfConnectToPlaid = isEndOfConnectToPlaid;

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((date) => {
                if (date) {
                    this.ledgerAccountService
                        .updateActivateFeedDate(account.ledger_account_id as number, {activate_feed_from: date})
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((res) => {
                            this.resetData();
                        });
                }
            });
    }

    reLoginInBankAccount(ledger: ILedgerAccount & {isNeedToReLogin: boolean}) {
        const bankingMethod = ledger.connected_payment_methods?.find(
            (method) => method.payment_gateway && method.payment_gateway.type === 'banking'
        );
        if (!bankingMethod) {
            return;
        }
        const dialogRef = this.dialog.open(UpdatePlaidCredsByLinkDialogComponent, {
            panelClass: 'hide-dialog-container',
            data: {
                method: bankingMethod
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {
                if (ok) {
                    this.companyPaymentMethodsService
                        .resetPlaidStatus(bankingMethod.payment_method_id as number)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((result) => {
                            this.resetData();
                        });
                }
            });
    }

    reconnectBankAccount(account: ILedgerAccount & {isNeedToReLogin: boolean}) {
        this.ledgerAccountService
            .disconnectFeed(account.ledger_account_id as number)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.open(account.type === 'trust', account);
            });
    }

    deactivateFeed(account: ILedgerAccount & {isNeedToReLogin: boolean}) {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            data: {
                title: 'Deactivate Account Feed',
                message: 'Account feed will be deactivated'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {
                this.ledgerAccountService
                    .updateActivateFeedDate(account.ledger_account_id as number, {
                        activate_feed_from: null
                    })
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((res) => {
                        this.resetData();
                    });
            });
    }

    deactivateAccount(account: ILedgerAccount & {isNeedToReLogin: boolean}) {
        const messages = [
            'This action will deactivate the selected account',
            'and stop any currently connected feeds.'
        ];

        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            data: {
                title: 'Deactivate Account',
                messages: messages,
                buttonOkMessage: 'Confirm'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {
                this.ledgerAccountService
                    .deactivateLedgerAccount(account.ledger_account_id as number)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((res) => {
                        this.resetData();
                    });
            });
    }

    reactivateAccount(account: ILedgerAccount & {isNeedToReLogin: boolean}) {
        this.ledgerAccountService
            .reactivateLedgerAccount(account.ledger_account_id as number)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                this.resetData();
            });
    }

    disconnectFeed(account: ILedgerAccount & {isNeedToReLogin: boolean}) {
        const messages = ['This action will disconnect any currently connected feeds.'];

        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            data: {
                title: 'Disconnect Feed',
                messages: messages,
                buttonOkMessage: 'Confirm'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() =>
                this.ledgerAccountService
                    .disconnectFeed(account.ledger_account_id as number)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(() => this.resetData())
            );
    }

    zipiPayTooltip(account: ILedgerAccount & {isNeedToReLogin: boolean}) {
        if (account) {
            const method = account.connected_payment_methods?.find(
                (pm) =>
                    pm.payment_gateway &&
                    ['dwolla_business', 'dwolla_trust', 'zipi_financial_business', 'zipi_financial_trust'].includes(
                        pm.payment_gateway.type
                    )
            );
            if (method) {
                return `${method.payment_gateway?.title} | ${method.title}`;
            }
        }
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        this.resetData();
    }

    async getPlaidLinkToken(
        gatewayId: number,
        config: {products: Array<string>; linkCustomizationName: string; account_type: 'trust' | 'operating' | null}
    ) {
        return await this.gatewayService.getPlaidLinkToken(gatewayId, config).toPromise();
    }

    getType(type: LedgerAccountTypes): string {
        return this.ledgerTypesMap[type];
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
    }
}
