<button mat-button [matMenuTriggerFor]="menuAdd" class="add-button">
    <span class="mr-1">New</span>
    <mat-icon>add</mat-icon>
</button>

<mat-menu #menuAdd="matMenu" [overlapTrigger]="false" xPosition="before" class="create-smth-new-menu">
    <div class="sp-bb" *ngIf="createNewItemEnabledFlag">
        <div
            rbac
            [anyRule]="[
                {create_deal: true},
                {contacts__manage_limited_contacts: true},
                {contacts__manage_any_contacts: true},
                {progress__access: true}
            ]"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'block'}"
            [denyStyle]="{display: 'none'}"
            class="create-smth-new-menu-column"
        >
            <span class="sp-menu-add__title">Deals</span>
            <a
                rbac
                [anyRule]="[{deals__create_own: true}, {deals__create_any: true}]"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                mat-menu-item
                routerLink="/deals/create"
            >
                <span>Deal</span>
            </a>
            <a
                [anyRule]="[{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                routerLink="/contacts/create"
                mat-menu-item
            >
                <span>Contact</span>
            </a>
            <a
                [rule]="{progress__access: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                [routerLink]="['/tasks']"
                [state]="{open: true}"
                mat-menu-item
            >
                <span>Task</span>
            </a>
        </div>

        <div
            rbac
            [anyRule]="[
                {sales__manage_invoices: true},
                {sales__manage_payments_received: true},
                {sales__manage_customer_credits: true},
                {sales__manage_recurring_invoices: true}
            ]"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'block'}"
            [denyStyle]="{display: 'none'}"
            class="create-smth-new-menu-column"
        >
            <span class="sp-menu-add__title">Agents / Customers</span>
            <a
                rbac
                [rule]="{sales__manage_invoices: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                routerLink="/sales/invoices/create"
                mat-menu-item
            >
                <span>Invoice</span>
            </a>
            <a
                rbac
                [rule]="{sales__manage_payments_received: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                routerLink="/sales/paymentsreceived/create"
                mat-menu-item
            >
                <span>Receive a Payment</span>
            </a>
            <a
                rbac
                [rule]="{sales__manage_customer_credits: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                routerLink="/sales/creditnotes/create"
                mat-menu-item
            >
                <span>Issue a Credit</span>
            </a>
            <a
                rbac
                [rule]="{sales__manage_recurring_invoices: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                routerLink="/sales/invoices/recurring/create"
                mat-menu-item
            >
                <span>Recurring Invoice</span>
            </a>
            <a
                rbac
                [rule]="{sales__manage_customer_credits: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                routerLink="/sales/creditnotes/recurring/create"
                mat-menu-item
            >
                <span>Recurring Credit</span>
            </a>
        </div>

        <div
            rbac
            [anyRule]="[
                {purchases__manage_bills: true},
                {purchases__manage_expenses: true},
                {purchases__manage_payments_made: true},
                {purchases__manage_vendor_credits: true}
            ]"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'block'}"
            [denyStyle]="{display: 'none'}"
            class="create-smth-new-menu-column"
        >
            <span class="sp-menu-add__title">Vendors</span>
            <a
                rbac
                [rule]="{purchases__manage_bills: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                routerLink="/purchases/bills/create"
                mat-menu-item
            >
                <span>Bill</span>
            </a>
            <a
                [rule]="{purchases__manage_expenses: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                routerLink="/purchases/expenses/create"
                mat-menu-item
            >
                <span>Expense</span>
            </a>
            <a
                [rule]="{purchases__manage_payments_made: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                routerLink="/purchases/payments/create"
                mat-menu-item
            >
                <span>Pay a Bill</span>
            </a>
            <a
                rbac
                [rule]="{purchases__manage_vendor_credits: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                routerLink="/purchases/vendorcredits/create"
                mat-menu-item
            >
                <span>Vendor Credit</span>
            </a>
        </div>

        <div
            rbac
            [anyRule]="[{company_settings__manage_journals: true}, {banking__manage_transactions: true}]"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'block'}"
            [denyStyle]="{display: 'none'}"
            class="create-smth-new-menu-column"
        >
            <span class="sp-menu-add__title">Other</span>
            <button
                rbac
                [rule]="{banking__manage_transactions: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                mat-menu-item
                (click)="openDialog({label: 'Deposit From Other Account', value: 'other_deposit'})"
            >
                <span>Bank Deposit</span>
            </button>
            <button
                rbac
                [rule]="{banking__manage_transactions: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                mat-menu-item
                (click)="openDialog({label: 'Transfer From Another Account', value: 'transfer_from_another_account'})"
            >
                <span>Transfer</span>
            </button>
            <a
                routerLink="/company/finance/journals"
                [state]="{open: true}"
                mat-menu-item
                rbac
                [rule]="{company_settings__manage_journals: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
            >
                <span>Journal Entry</span>
            </a>
            <a
                routerLink="/company/finance/recurringjournals/create"
                mat-menu-item
                rbac
                [rule]="{company_settings__manage_journals: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
            >
                <span>Recurring Journal Entry</span>
            </a>
            <button
                rbac
                [rule]="{banking__manage_transactions: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                mat-menu-item
                (click)="openDialog({label: 'Owner Draws', value: 'owner_drawings'})"
            >
                <span>Owner Draw</span>
            </button>
            <button
                rbac
                [rule]="{banking__manage_transactions: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
                mat-menu-item
                (click)="openDialog({label: 'Owner Contribution', value: 'owners_contribution'})"
            >
                <span>Owner Contribution</span>
            </button>
        </div>
    </div>

    <div
        rbac
        [anyRule]="[
            {create_deal: true},
            {contacts__manage_limited_contacts: true},
            {contacts__manage_any_contacts: true}
        ]"
        [denyMethod]="'style'"
        [allowStyle]="{display: 'block'}"
        [denyStyle]="{display: 'none'}"
        class="sp-bb-old"
        *ngIf="!createNewItemEnabledFlag"
    >
        <span class="sp-menu-add__title">Create</span>
        <a
            rbac
            [anyRule]="[{deals__create_own: true}, {deals__create_any: true}]"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'block'}"
            [denyStyle]="{display: 'none'}"
            mat-menu-item
            routerLink="/deals/create"
        >
            <mat-icon>open_in_new</mat-icon>
            <span>Deal</span>
        </a>
        <a
            [anyRule]="[{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'block'}"
            [denyStyle]="{display: 'none'}"
            routerLink="/contacts/create"
            mat-menu-item
        >
            <mat-icon>open_in_new</mat-icon>
            <span>Contact</span>
        </a>
    </div>

    <span
        class="text-muted font-italic mt-3 mx-4"
        rbac
        [denyMethod]="'style'"
        [allowStyle]="{display: 'block'}"
        [denyStyle]="{display: 'none'}"
        [anyRule]="[
            {
                create_deal: false,
                contacts__manage_limited_contacts: false,
                contacts__manage_any_contacts: false,
                progress__access: false,
                sales__manage_invoices: false,
                sales__manage_payments_received: false,
                sales__manage_customer_credits: false,
                sales__manage_recurring_invoices: false,
                purchases__manage_bills: false,
                purchases__manage_expenses: false,
                purchases__manage_payments_made: false,
                purchases__manage_vendor_credits: false,
                company_settings__manage_journals: false,
                banking__manage_transactions: false
            },
            {create_deal: false, contacts__manage_limited_contacts: false, contacts__manage_any_contacts: false}
        ]"
    >
        You have no permissions to create any entities
    </span>

    <!--<div class="sp-bb w-100">
			<span class="sp-menu-add__title" *ngIf="isExistFavoritePreset$ | async">
				Favorites
			</span>
			<div *ngFor="let preset of preset$ | async">
				<button mat-menu-item
						*ngIf="preset.is_favorite"
						(click)="goToPreset(preset.id)"
						type="button">
					<mat-icon>star_rate</mat-icon>
					{{preset.title}}
				</button>
			</div>

		</div>
		<a routerLink="/company/import" mat-menu-item>
			<span>Import Data</span>
		</a>-->
</mat-menu>
