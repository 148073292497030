import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IContactsState} from './contacts.reducer';

export const selectContactsFeature = createFeatureSelector<IContactsState>('contacts');

export const selectContacts = createSelector(selectContactsFeature, (state: IContactsState) => state.contacts);

// export const selectOneContact = (id: number | null | undefined) => createSelector(
//     selectContactsFeature,
//     (state: IContactsState) => {
//         return state.contacts.find(contact => contact.id === id);
//     }
// );

// export const selectSelectedContact = createSelector(
//     selectContactsFeature,
//     (state: IContactsState) => state.selectedContact
// );
//
// export const selectClients = createSelector(
//     selectContactsFeature,
//     (state: IContactsState) => state.clients
// );
//
// export const selectTpls = createSelector(
//     selectContactsFeature,
//     (state: IContactsState) => state.tpls
// );

// export const selectContactsInvites = createSelector(
//     selectContactsFeature,
//     (state: IContactsState) => state.consactsInvites
// );

// export const selectUpdatedContacts = createSelector(
//     selectContactsFeature,
//     (state: IContactsState) => state.updatedContacts
// );
