<div>
    <mat-card class="report-table" *ngIf="reportData.length" [ngStyle]="{'min-width': tableWidth + 'px'}">
        <div class="w-100">
            <div class="flex-row font-weight-bold title header">
                <div class="item w-250">Display Name</div>
                <div class="item w-250">Company Name</div>
                <div class="item w-200">First Name</div>
                <div class="item w-200">Last Name</div>
                <div class="item w-250">Email</div>
                <div class="item w-100">Country Code</div>
                <div class="item w-150">Phone</div>
                <div class="item w-100">Street Number</div>
                <div class="item w-250">Street Address</div>
                <div class="item w-100">Unit</div>
                <div class="item w-200">City</div>
                <div class="item w-150">State</div>
                <div class="item w-150">Zip Code</div>
                <div class="item w-150">Country</div>
                <div class="item w-100">Contact Type</div>
                <div class="item w-100">Directory Status</div>
                <ng-container *ngIf="report.settings.is_include_contact_payroll">
                    <div class="item w-100">Payroll Type</div>
                    <div class="item w-200">Recipient Name</div>
                    <div class="item w-100">Recipient Tin Type</div>
                    <div class="item w-100">Recipient Tin</div>
                    <div class="item w-100">Recipient Start Date</div>
                    <div class="item w-100">Recipient Filing Status</div>
                    <div class="item w-150">Recipient Withholding Amount</div>
                    <div class="item w-150">Recipient Extra Withholding Amount</div>
                    <div class="item w-150">Recipient Other Income</div>
                    <div class="item w-150">Recipient Deductions</div>
                    <div class="item w-100">Recipient Label</div>
                    <div class="item w-100">Recipient Street Number</div>
                    <div class="item w-250">Recipient Street Address</div>
                    <div class="item w-100">Recipient Unit</div>
                    <div class="item w-100">Recipient City</div>
                    <div class="item w-100">Recipient State</div>
                    <div class="item w-100">Recipient Zip</div>
                    <div class="item w-100">Recipient Country</div>
                </ng-container>

                <div
                    class="item w-120"
                    *ngIf="
                        report.settings.contact_addons &&
                        report.settings.contact_addons.indexOf('dotloop_integration') > -1
                    "
                >
                    Dotloop Integration
                </div>
                <div
                    class="item w-120"
                    *ngIf="
                        report.settings.contact_addons &&
                        report.settings.contact_addons.indexOf('skyslope_integration') > -1
                    "
                >
                    Skyslope Integration
                </div>
                <div
                    class="item w-120"
                    *ngIf="
                        report.settings.contact_addons &&
                        report.settings.contact_addons.indexOf('zipi_financial_integration') > -1
                    "
                >
                    Money Transfers Integration
                </div>
            </div>

            <mat-tree [dataSource]="dataSource" [treeControl]="treeDataControl" class="tree">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                    <li class="mat-tree-node">
                        <button mat-icon-button disabled></button>
                        <div class="item w-100 flex-row" [ngClass]="{'font-weight-bold': node.type === 'total'}">
                            <div class="item w-250">{{ node.display_name }}</div>
                            <div class="item w-250">{{ node.company_name }}</div>
                            <div class="item w-200">{{ node.first_name }}</div>
                            <div class="item w-200">{{ node.last_name }}</div>
                            <div class="item w-250">{{ node.email }}</div>
                            <div class="item w-100">{{ node.country_code }}</div>
                            <div class="item w-150">{{ node.phone }}</div>
                            <div class="item w-100">{{ node.street_number }}</div>
                            <div class="item w-250">{{ node.street_address }}</div>
                            <div class="item w-100">{{ node.unit_number }}</div>
                            <div class="item w-200">{{ node.city }}</div>
                            <div class="item w-150">{{ node.state }}</div>
                            <div class="item w-150">{{ node.zip }}</div>
                            <div class="item w-150">{{ node.country }}</div>
                            <div class="item w-100">{{ node.contact_type }}</div>
                            <div class="item w-100">{{ node.directory_status }}</div>

                            <ng-container *ngIf="report.settings.is_include_contact_payroll">
                                <div class="item w-100">{{ node.payroll_type }}</div>
                                <div class="item w-200">{{ node.payroll_name }}</div>
                                <div class="item w-100">{{ node.payroll_tin_type }}</div>
                                <div class="item w-100">{{ node.payroll_tin }}</div>
                                <div class="item w-100">{{ node.payroll_start_date }}</div>
                                <div class="item w-100">{{ node.payroll_filing_status }}</div>
                                <div class="item w-150">{{ node.payroll_withholding_amount }}</div>
                                <div class="item w-150">{{ node.payroll_extra_withholding_amount }}</div>
                                <div class="item w-150">{{ node.payroll_other_income }}</div>
                                <div class="item w-150">{{ node.payroll_deductions }}</div>
                                <div class="item w-100">{{ node.payroll_label }}</div>
                                <div class="item w-100">{{ node.payroll_street_number }}</div>
                                <div class="item w-250">{{ node.payroll_street_address }}</div>
                                <div class="item w-100">{{ node.payroll_unit_number }}</div>
                                <div class="item w-100">{{ node.payroll_city }}</div>
                                <div class="item w-100">{{ node.payroll_state }}</div>
                                <div class="item w-100">{{ node.payroll_zip }}</div>
                                <div class="item w-100">{{ node.payroll_country }}</div>
                            </ng-container>

                            <div
                                class="item w-120"
                                *ngIf="
                                    report.settings.contact_addons &&
                                    report.settings.contact_addons.indexOf('dotloop_integration') > -1
                                "
                            >
                                {{ node.dotloop_integration }}
                            </div>
                            <div
                                class="item w-120"
                                *ngIf="
                                    report.settings.contact_addons &&
                                    report.settings.contact_addons.indexOf('skyslope_integration') > -1
                                "
                            >
                                {{ node.skyslope_integration }}
                            </div>
                            <div
                                class="item w-120"
                                *ngIf="
                                    report.settings.contact_addons &&
                                    report.settings.contact_addons.indexOf('zipi_financial_integration') > -1
                                "
                            >
                                {{ node.zipi_financial_integration }}
                            </div>
                        </div>
                    </li>
                </mat-tree-node>
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                    <li>
                        <div class="mat-tree-node">
                            <button
                                mat-icon-button
                                matTreeNodeToggle
                                [attr.aria-label]="'toggle ' + node.la_name"
                                (click)="changeState(node)"
                            >
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{ treeDataControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                </mat-icon>
                            </button>

                            <div class="item w-100 flex-row">
                                <div class="item w-250">{{ node.display_name }}</div>
                                <div class="item w-250">{{ node.company_name }}</div>
                                <div class="item w-200">{{ node.first_name }}</div>
                                <div class="item w-200">{{ node.last_name }}</div>
                                <div class="item w-250">{{ node.email }}</div>
                                <div class="item w-100">{{ node.country_code }}</div>
                                <div class="item w-150">{{ node.phone }}</div>
                                <div class="item w-100">{{ node.street_number }}</div>
                                <div class="item w-250">{{ node.street_address }}</div>
                                <div class="item w-100">{{ node.unit_number }}</div>
                                <div class="item w-200">{{ node.city }}</div>
                                <div class="item w-150">{{ node.state }}</div>
                                <div class="item w-150">{{ node.zip }}</div>
                                <div class="item w-150">{{ node.country }}</div>
                                <div class="item w-100">{{ node.contact_type }}</div>
                                <div class="item w-100">{{ node.directory_status }}</div>
                                <div class="item w-100">
                                    <span *ngFor="let addon of node.addons">
                                        <img class="logo" [src]="addon.logo" />
                                    </span>
                                </div>
                                <ng-container *ngIf="report.settings.is_include_contact_payroll">
                                    <div class="item w-100">{{ node.payroll_type }}</div>
                                    <div class="item w-200">{{ node.payroll_name }}</div>
                                    <div class="item w-100">{{ node.payroll_tin_type }}</div>
                                    <div class="item w-100">{{ node.payroll_tin }}</div>
                                    <div class="item w-100">{{ node.payroll_start_date }}</div>
                                    <div class="item w-100">{{ node.payroll_filing_status }}</div>
                                    <div class="item w-150">{{ node.payroll_withholding_amount }}</div>
                                    <div class="item w-150">{{ node.payroll_extra_withholding_amount }}</div>
                                    <div class="item w-150">{{ node.payroll_other_income }}</div>
                                    <div class="item w-150">{{ node.payroll_deductions }}</div>
                                    <div class="item w-100">{{ node.payroll_label }}</div>
                                    <div class="item w-100">{{ node.payroll_street_number }}</div>
                                    <div class="item w-250">{{ node.payroll_street_address }}</div>
                                    <div class="item w-100">{{ node.payroll_unit_number }}</div>
                                    <div class="item w-100">{{ node.payroll_city }}</div>
                                    <div class="item w-100">{{ node.payroll_state }}</div>
                                    <div class="item w-100">{{ node.payroll_zip }}</div>
                                    <div class="item w-100">{{ node.payroll_country }}</div>
                                </ng-container>
                            </div>
                        </div>
                        <ul [class.tree-invisible]="!treeDataControl.isExpanded(node)">
                            <ng-container matTreeNodeOutlet></ng-container>
                        </ul>
                    </li>
                </mat-nested-tree-node>
            </mat-tree>
        </div>
    </mat-card>
    <mat-card class="preview-body" *ngIf="!reportData.length">
        <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
    </mat-card>
</div>
