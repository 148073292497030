import {Component, Input} from '@angular/core';
import {IReportsPreviewResultData} from '../../../../store/reports.reducer';
import {FinancialElementModel} from '@app/modules/account-info/compensation/models/financial-element.model';

export type CapReportData = {
    name: string;
    compensation_profile_id: number;
    compensation_profile_title: string;
    financial_element_id: number;
    financial_element_title: string;
    financial_element_type: string;
    current_rule_name: string;
    rule_target: number;
    condition_type: string;
    current_status: number;
    startDate: string;
    endDate: string;
    total_agent_split: number;
    total_company_split: number;
    product_name: null;
};

@Component({
    selector: 'reports-preview-cap',
    templateUrl: './cap.component.html',
    styleUrls: ['./cap.component.scss']
})
export class CapPreviewComponent {
    @Input() page: number = 0;
    @Input() perPage: number = 25;
    @Input() reportData: IReportsPreviewResultData[] = [];

    financialElementTypeLabels = FinancialElementModel.type_LABELS;

    displayedColumns: Array<keyof CapReportData> = [
        'name',
        'compensation_profile_title',
        'financial_element_title',
        'financial_element_type',
        'current_rule_name',
        'rule_target',
        'condition_type',
        'product_name',
        'startDate',
        'endDate',
        'current_status'
    ];
}
