<ng-container>
    <mat-card class="accept-invite-card">
        <mat-card-content>
            <p class="auth-warning text-center" *ngIf="isReloginNeed">
                You are currently logged in <i>{{ sessionService.profile!.company!.title }}</i> company
            </p>
            <p class="auth-warning text-center" *ngIf="isLoginNeed">You are currently not logged in.</p>
        </mat-card-content>

        <mat-card-actions>
            <button *ngIf="isReloginNeed" mat-raised-button color="primary" (click)="logoutAndGoTo()">
                LOGOUT AND LOGIN LIKE ANOTHER COMPANY
            </button>

            <button *ngIf="isLoginNeed" mat-raised-button color="primary" (click)="loginAndGoTo()">
                LOGIN TO SEE THE REQUEST
            </button>
        </mat-card-actions>
    </mat-card>
</ng-container>
