import {Routes, RouterModule} from '@angular/router';
import {ProductsPageComponent} from '../../finance/components/products/products-page/products-page.component';
import {EditProductComponent} from '../../finance/components/products/edit-product/edit-product.component';
import {LedgerAccountPageComponent} from '../../finance/components/ledger-accounts/ledger-account-page/ledger-account-page.component';
import {EditLedgerComponent} from '../../finance/components/ledger-accounts/edit-ledger-account/edit-ledger-account.component';
import {JournalPageComponent} from '../../finance/components/journals/journal-page/journal-page.component';
import {OpeningBalancesPageComponent} from '../../finance/components/opening-balances/opening-balances-page/opening-balances-page.component';
import {EditOpeningBalancesComponent} from '../../finance/components/opening-balances/edit-opening-balances/edit-opening-balances.component';
import {CreateJournalTemplateComponent} from '../../finance/components/journal-templates/create-journal-template/create-journal-template.component';
import {EditRecurringJournalComponent} from '../../finance/components/recurring-journals/edit-recurring-journal/edit-recurring-journal.component';
import {AccessGuardService} from '../../../services/resolvers/access-guard.service';
import {JournalListsComponent} from '../../finance/components/journals/journal-lists/journal-lists.component';

export const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'journals',
                canActivate: [AccessGuardService],
                children: [
                    {path: '', component: JournalListsComponent},
                    {path: ':id', component: JournalPageComponent}
                ],
                data: {
                    title: 'Journals',
                    access: {rule: {company_settings__view_journals: true}}
                }
            },
            {
                path: 'products',
                canActivate: [AccessGuardService],
                children: [
                    {path: '', component: ProductsPageComponent},
                    {
                        path: ':id',
                        component: EditProductComponent,
                        canActivate: [AccessGuardService],
                        data: {
                            access: {rule: {company_settings__manage_products: true}}
                        }
                    },
                    {
                        path: 'create',
                        component: EditProductComponent,
                        canActivate: [AccessGuardService],
                        data: {
                            access: {rule: {company_settings__manage_products: true}}
                        }
                    }
                ],
                data: {
                    title: 'Products',
                    access: {rule: {company_settings__view_products: true}}
                }
            },
            {
                path: 'journaltemplates',
                canActivate: [AccessGuardService],
                children: [
                    {path: ':id', component: CreateJournalTemplateComponent},
                    {path: 'create', component: CreateJournalTemplateComponent}
                ],
                data: {
                    title: 'Journals Templates',
                    access: {rule: {company_settings__manage_journals: true}}
                }
            },
            {
                path: 'recurringjournals',
                canActivate: [AccessGuardService],
                children: [
                    {path: ':id', component: EditRecurringJournalComponent},
                    {path: 'create', component: EditRecurringJournalComponent}
                ],
                data: {
                    title: 'Recurring Journals',
                    access: {rule: {company_settings__manage_journals: true}}
                }
            },
            {
                path: 'chartofaccounts',
                canActivate: [AccessGuardService],
                children: [
                    {path: '', component: LedgerAccountPageComponent},
                    {
                        path: ':id',
                        canActivate: [AccessGuardService],
                        data: {
                            access: {rule: {company_settings__manage_ledger_accounts: true}}
                        },
                        component: EditLedgerComponent
                    },
                    {
                        path: 'create',
                        canActivate: [AccessGuardService],
                        data: {
                            access: {rule: {company_settings__manage_ledger_accounts: true}}
                        },
                        component: EditLedgerComponent
                    }
                ],
                data: {
                    title: 'Chart of Accounts',
                    access: {rule: {company_settings__view_ledger_accounts: true}}
                }
            },
            {
                path: 'openingbalances',
                canActivate: [AccessGuardService],
                children: [
                    {path: '', component: OpeningBalancesPageComponent},
                    {
                        path: 'create',
                        canActivate: [AccessGuardService],
                        data: {
                            access: {rule: {company_settings__manage_opening_balances: true}}
                        },
                        component: EditOpeningBalancesComponent
                    }
                ],
                data: {
                    title: 'Opening Balances',
                    access: {rule: {company_settings__view_opening_balances: true}}
                }
            }
        ]
    }
];

export const compensationRouting = RouterModule.forChild(routes);
