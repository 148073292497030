import {merge as observableMerge, Observable, Subject} from 'rxjs';

import {startWith} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {MarketplaceAddonInstanceModel} from './marketplace.models';

@Injectable()
export class MarketplaceSource {
    private triggers = {
        loadAddons: new Subject<MarketplaceAddonInstanceModel[]>()
    };

    private events = {
        addonsChangeEvent: <Observable<MarketplaceAddonInstanceModel[]>>observableMerge(this.triggers.loadAddons)
    };

    private currentAddons: MarketplaceAddonInstanceModel[] | null = null;

    public get addonsChangeEvent(): Observable<MarketplaceAddonInstanceModel[]> {
        if (this.currentAddons) {
            return this.events.addonsChangeEvent.pipe(startWith(this.currentAddons));
        }
        return this.events.addonsChangeEvent;
    }

    public setLoadedAddons(addons: MarketplaceAddonInstanceModel[]) {
        this.triggers.loadAddons.next(addons);
    }

    constructor() {
        this.addonsChangeEvent.subscribe((addons: MarketplaceAddonInstanceModel[]) => {
            this.currentAddons = addons;
        });
    }
}
