import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-unsaved-changes',
    styles: [
        `
            .close-button {
                float: right;
                margin: -20px;
            }
        `
    ],
    templateUrl: './unsaved-changes-new-dialog.component.html'
})

// this popup created at May 30, 2023. In future should be reused across system
export class UnsavedChangesNewDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<UnsavedChangesNewDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            message: string;
            isExistSaveChangesFunction: any;
            isBackButtonNeeded: boolean;
            discardButtonText: string;
            messages: string[];
            definitions: string[];
        }
    ) {}

    ngOnInit() {}
}
