<div>
    <mat-dialog-content class="mt-3">
        <!------- Header ------->
        <div class="d-flex flex-row align-items-baseline">
            <h1 mat-dialog-title class="mr-3">Manage Custom Fields</h1>
        </div>

        <!------- Body ------->
        <div class="d-flex flex-column">
            <!------- Label ------->
            <mat-form-field class="mr-3">
                <input
                    matInput
                    type="text"
                    [(ngModel)]="currentFieldType.label"
                    placeholder="New Record"
                    name="label"
                />
            </mat-form-field>

            <!------- CustomField: type ------->
            <mat-form-field>
                <mat-select
                    name="type"
                    placeholder="Field Type"
                    [(ngModel)]="currentFieldType.type"
                    (click)="currentFieldType.options = []"
                    [disabled]="!!currentFieldType.id"
                >
                    <mat-option *ngFor="let type of types_SET" [value]="type">
                        {{ type }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!------- CustomField: options (type: select) ------->
            <div class="mb-3" *ngIf="currentFieldType.type === 'select'">
                <!------- Options list ------->
                <mat-list>
                    <mat-list-item
                        style="height: 66px"
                        *ngFor="let type of currentFieldType.options; trackBy: indexTracker; index as mi"
                    >
                        <mat-form-field *ngIf="currentFieldType.options">
                            <input
                                matInput
                                required
                                [(ngModel)]="currentFieldType.options[mi]"
                                [disabled]="!!currentFieldType.id"
                            />
                        </mat-form-field>

                        <button
                            mat-icon-button
                            class="mat-default"
                            (click)="removeOptionFromArray(mi)"
                            [disabled]="!!currentFieldType.id"
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-list-item>
                </mat-list>

                <button mat-raised-button class="mat-default" (click)="addOption()" [disabled]="!!currentFieldType.id">
                    Add new option
                </button>
            </div>

            <!------- CustomField: options (type: date) ------->
            <mat-card class="row summary-card compact-card mx-0 mb-3" *ngIf="currentFieldType.type === 'date'">
                <mat-select
                    name="type"
                    placeholder="Date Value Type"
                    *ngIf="currentFieldType.options"
                    [(ngModel)]="currentFieldType.options[0]"
                    [disabled]="!!currentFieldType.id"
                >
                    <mat-option *ngFor="let type of dateValueTypes" [value]="type">
                        {{ type }}
                    </mat-option>
                </mat-select>
            </mat-card>
        </div>
    </mat-dialog-content>

    <!------- Buttons ------->
    <mat-dialog-actions class="text-right">
        <div class="d-flex justify-content-between w-100 py-2">
            <div>
                <button
                    type="button"
                    color="warn"
                    mat-raised-button
                    tabindex="-1"
                    (click)="deleteCustomFieldType()"
                    *ngIf="!!currentFieldType.id"
                >
                    Delete
                </button>
            </div>

            <div>
                <button type="button" mat-raised-button tabindex="-1" (click)="dialogRef.close()">Cancel</button>
                <button type="button" mat-raised-button color="primary" tabindex="1" (click)="saveCustomFieldType()">
                    Save
                </button>
            </div>
        </div>
    </mat-dialog-actions>
</div>
