<div mat-dialog-title>
    <h3>Deduct from Deal</h3>
</div>

<mat-dialog-content>
    <div *ngIf="isIncorrectInvoice">
        <div>One or more line items of this invoice are not associated with a Product / Service.</div>
        <div>
            In order to use the Deduct from Deal functionality, please edit this invoice and make sure that all line
            items have been assigned a Product / Service.
        </div>
        <div class="mt-2">
            Go to
            <a [routerLink]="['/sales/invoices/edit/' + invoice!.invoice_id]" (click)="dialogRef.close(false)"
                >Invoice Edit Page</a
            >
        </div>
    </div>
    <div *ngIf="!isIncorrectInvoice">
        <p>Agent Net: {{ totalNet | currency }}</p>

        <div class="table">
            <div>
                <div class="d-flex bg-dark text-white px-2">
                    <div class="w-20">Product</div>
                    <div class="w-40">Product Description</div>
                    <div class="w-20">Available Amount</div>
                    <div class="w-20 text-right">Deducted Amount</div>
                </div>
                <div *ngFor="let di of productDeductArray.controls" style="vertical-align: middle; height: 40px">
                    <div
                        class="d-flex align-items-center px-2"
                        [formGroup]="di"
                        *ngIf="di.controls.max_amount.value > 0"
                    >
                        <div class="w-20">
                            {{ di.controls.product.value.name }}
                        </div>
                        <div class="w-40">
                            <i class="text-muted">{{ di.controls.product.value.description }}</i>
                        </div>
                        <div class="w-20">{{ di.controls.max_amount.value | currency }}</div>
                        <div class="w-20 d-flex justify-content-end">
                            <mat-form-field class="w-70">
                                <input
                                    matInput
                                    type="text"
                                    [maskito]="currencyMaskitoMask"
                                    (keyup)="validateAmount($event, di)"
                                    [formControl]="di.controls.amount"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height: 21px" class="mt-2">
                <div *ngIf="warning" style="font-size: 14px; color: orange; font-style: italic; padding-left: 10px">
                    {{ warning }}
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex justify-content-end">
        <button class="mr-1" mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="isIncorrectInvoice" (click)="apply()" tabindex="1">
            Confirm
        </button>
    </div>
</mat-dialog-actions>
