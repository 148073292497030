<div class="mt-4 mx-4">
    <div class="d-flex justify-content-between align-items-center" *ngIf="depositRequest && !requestPaid">
        <h1 class="m-0">Deal Deposit Request</h1>
    </div>
    <mat-card *ngIf="depositRequest && !requestPaid" class="m-4">
        <h1 class="m-0">{{ depositRequest.creator_company?.title }}</h1>

        <table mat-table [dataSource]="dataSourceRequest" class="mat-elevation-z1 w-100 deposit-table">
            <ng-container matColumnDef="request_date">
                <th mat-header-cell *matHeaderCellDef>Date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.request_date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="product">
                <th mat-header-cell *matHeaderCellDef>Product</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.product">{{ element.product.name }}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef>Amount</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.request_amount | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="balance">
                <th mat-header-cell *matHeaderCellDef>Balance</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.request_balance | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="pending_balance">
                <th mat-header-cell *matHeaderCellDef>Pending Balance</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.request_pending_balance | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="due_date">
                <th mat-header-cell *matHeaderCellDef>Due Date</th>
                <td mat-cell *matCellDef="let element">{{ element.due_date | dateFromNumber | date: 'mediumDate' }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedRequestColumns"></tr>
            <tr mat-row class="journals-row" *matRowDef="let row; columns: displayedRequestColumns"></tr>
        </table>

        <mat-card>
            <div></div>
            <div class="d-flex align-items-end">
                <div
                    class="d-flex my-2 align-items-baseline"
                    *ngIf="depositRequestPayloadBankMethod && depositRequest.request_pending_balance > 0"
                >
                    <button mat-raised-button color="primary" class="ml-2 _track_public_invoice_pay" (click)="pay()">
                        Pay via Payload
                    </button>
                </div>
                <span class="sp-spacer"></span>
                <button type="button" mat-raised-button color="primary" *ngIf="canViewInApp" (click)="viewInApp()">
                    View in App
                </button>
                <button
                    type="button"
                    mat-raised-button
                    color="primary"
                    class="_track_public_deposit_request_open_in_portal"
                    *ngIf="
                        !currentProfile ||
                        currentProfile.company!.id === depositRequest!.creator__company_fk_id ||
                        depositRequest!.request_receiver_contact!.partner__company_fk_id !== currentProfile.company!.id
                    "
                    (click)="openInPortal()"
                >
                    Open in Portal
                </button>
            </div>
        </mat-card>
    </mat-card>

    <mat-card *ngIf="depositRequest && requestPaid" class="m-4">
        <h3 class="m-0">Deposit Request paid</h3>
    </mat-card>
</div>
