import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Profile} from '../../../../models/profile';

@Component({
    selector: 'app-override-dialog',
    styles: [
        `
            .row.footer {
                justify-content: space-between;
            }

            mat-list {
                max-height: 400px;
                width: 100%;
                overflow-x: hidden;
            }
        `
    ],
    template: `
        <div class="column">
            <h3>Inherited from Compensation Profile</h3>
            <div>Use "Rules" -> "Customize Rules" on top right corner to change this item.</div>

            <div class="row footer">
                <button mat-button class="mat-button" style="width:100%" (click)="dialogRef.close()">OK</button>
            </div>
        </div>
    `
})
export class OverrideDialogComponent {
    public newDealOwner: Profile | null = null;

    constructor(public dialogRef: MatDialogRef<OverrideDialogComponent>) {}
}
