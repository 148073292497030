import {debounceTime, filter, first, map, startWith, takeUntil, tap} from 'rxjs/operators';
import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Deal, DEAL_SYSTEM_STATUS} from '../../../../../models/deal';
import {Observable, Subject} from 'rxjs';
import {GenericFormGroup} from '../../../../../entites/generic.entity';
import {ICompanyLocation, IContactLocationTable, IDealParticipants} from '../../../../../../typings';
import {extractLocationComponents} from '../../../../contacts/store/contact.utilities';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {SessionService} from '../../../../../services/session.service';
import {ITag} from '@cyberco-nodejs/zipi-typings';
import {DealService} from '../../../../../services/deal.service';
import {GroupApi} from '../../../../../services/api/group.api';
import {NotificationsServiceZipi} from '../../../../notifications/notifications.service';
import {select, Store} from '@ngrx/store';
import {ICompanyWideState} from '../../../../../store/company-wide/company-wide.reducer';
import {selectTagCategories} from '../../../../../store/company-wide/company-wide.selectors';
import {DialogConfirmationComponent} from '../../../../shared/components/dialog-confirmation/dialog-confirmation.dialog';
import {MatDialog} from '@angular/material/dialog';
import {TagsService} from '../../../../../services/api/tags.service';
import {FeatureFlagsService} from '../../../../feature-flags/feature-flags.service';
import {ContactPartLink} from '../../../../../models/contact-part-link';
import {SalesEntity} from '../../../../../models/sales-entity';
import {DealSystemNote} from '../../../../../models/deal-system-note';

@Component({
    selector: 'app-deal-property-information',
    styleUrls: ['./edit-deal.component.scss', 'deal-creation.scss', './property-information.component.scss'],
    templateUrl: 'property-information.component.html'
})
export class EditDealPropertyInformationComponent implements OnInit, OnDestroy {
    DEAL_SYSTEM_STATUS = DEAL_SYSTEM_STATUS;
    private unsubscribe: Subject<void> = new Subject();

    @Input() dealFormGroup = new GenericFormGroup(new Deal(), 'change');
    @Input() showDismissWarningEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() selectedTagsIdsUpdated = new EventEmitter();
    @Output() dealUnsavedStatusEmitter = new EventEmitter();
    @ViewChild('auto') matAutocomplete: MatAutocomplete | undefined;

    DEAL = Deal;

    states = Deal.provideStates();
    statuses = Deal.provideStatuses();
    types = Deal.provideTypes();
    deal: Deal = new Deal();

    dealUnsavedStatus: DEAL_SYSTEM_STATUS | undefined;

    filteredStates: Observable<any[]> | undefined;

    locationPickerCtrl = new UntypedFormControl();

    allDivisions: {id: number; company_group_id: number; title: string; added_to_division_at: Date}[] = [];
    primarySalesEntity_ProfileId: number | undefined;

    selectedTags: ITag[] = [];
    tags: ITag[] = [];
    showDismissedWarnings: boolean = false;
    hasWarnings: boolean = false;
    isAllWarningsDismissed: boolean = false;
    canBeRevertedToDraft: boolean = false;

    autoTags: ITag[] = [];

    get isBackgroundCalculationFailed() {
        return (
            typeof this.dealFormGroup.controls.system_notes?.controls.find(
                (systemNoteFG) =>
                    systemNoteFG.controls.system_key?.value ===
                    DealSystemNote.system_keys_SET.background_recalculation_failed
            ) !== 'undefined'
        );
    }

    // feature flag
    public autoTagsEnabledFlag = false;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private sessionService: SessionService,
        private dealService: DealService,
        private notificationService: NotificationsServiceZipi,
        private groupsService: GroupApi,
        private store: Store<ICompanyWideState>,
        public dialog: MatDialog,
        private tagsService: TagsService,
        protected featureFlagsService: FeatureFlagsService
    ) {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.autoTagsEnabledFlag = this.featureFlagsService.isFeatureEnabled('company:deals_auto_tagging');
            });
    }

    filterStates(name: string) {
        return this.states.filter((state) => state.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    updateParticipants(deal_participants: IDealParticipants) {
        this.dealFormGroup.get('deal_participants')!.patchValue(deal_participants);

        // check if commission_payer should be unset
        const commissionPayerContactId =
            this.dealFormGroup.controls.deal_participants!.controls.commission_payer?.value?.contact_id;
        const escrowCompanyContactId =
            this.dealFormGroup.controls.deal_participants!.controls.escrow_agent?.value?.contact_id;
        let buyerContactIds = this.dealFormGroup.controls.deal_participants!.controls.buyers?.value?.map(
            (b: ContactPartLink) => b.contact_id
        );
        if (typeof buyerContactIds === 'undefined') {
            buyerContactIds = [];
        }
        let sellerContactIds = this.dealFormGroup.controls.deal_participants!.controls.sellers?.value?.map(
            (s: ContactPartLink) => s.contact_id
        );
        if (typeof sellerContactIds === 'undefined') {
            sellerContactIds = [];
        }
        if (
            commissionPayerContactId !== escrowCompanyContactId &&
            !buyerContactIds.includes(commissionPayerContactId) &&
            !sellerContactIds.includes(commissionPayerContactId)
        ) {
            // unset
            this.dealFormGroup.controls.deal_participants!.controls.commission_payer!.patchValue(null);
        }

        this.dealFormGroup.markAsDirty();
    }

    updateDealClientType(type: null | string) {
        this.dealFormGroup.get('client_type')!.patchValue(type);
        this.dealFormGroup.markAsDirty();
    }

    updateDealTransactionType(type: string) {
        this.dealFormGroup.get('type')!.patchValue(type);
        this.dealFormGroup.markAsDirty();
    }

    ngOnInit() {
        const currentGroupId = Number(localStorage.getItem('current_company_group_id'));

        if (this.sessionService.profile!.company_groups_member) {
            const currentGroupMember = this.sessionService.profile!.company_groups_member.find((gm) => {
                if (gm.company_group) {
                    return gm.company_group.id === currentGroupId;
                } else {
                    return false;
                }
            });
        }

        this.filteredStates = this.dealFormGroup.controls.state!.valueChanges.pipe(
            startWith(<string>(<unknown>null)),
            map((state) => (state ? this.filterStates(state) : this.states.slice())),
            takeUntil(this.unsubscribe)
        );

        this.locationPickerCtrl.valueChanges
            .pipe(
                filter((l) => l),
                map(extractLocationComponents),
                map((location) => this.addLocation(location)),
                tap(() => this.locationPickerCtrl.reset(null)),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        // waiting for filled deal
        this.dealFormGroup.valueChanges
            .pipe(
                filter(
                    (d: Deal) =>
                        !!this.dealFormGroup.controls.sales_entities!.controls.find((se) => se.value.is_primary)
                ),
                first(),
                takeUntil(this.unsubscribe)
            )
            .subscribe((changes) => {
                // set primary Sales Entity Profile Id
                this.primarySalesEntity_ProfileId = this.dealFormGroup.controls.sales_entities!.controls.find(
                    (se) => se.value.is_primary
                )?.value.profile?.id;

                if (this.primarySalesEntity_ProfileId) {
                    // get divisions list
                    this.groupsService
                        .getDivisionsForMember(
                            this.sessionService.profile!.company_fk_id!,
                            this.primarySalesEntity_ProfileId,
                            this.dealFormGroup.controls.division__company_group_fk_id!.value
                        )
                        .then((groups) => {
                            this.allDivisions = groups;

                            this.uploadTaggings();

                            if (!changes.id) {
                                this._prefillDivision();
                            }
                        });
                }
            });

        this.dealFormGroup.valueChanges
            .pipe(
                debounceTime(1000),
                filter((deal) => deal.id),
                takeUntil(this.unsubscribe)
            )
            .subscribe((changes) => {
                if (changes && changes.system_status && !this.dealUnsavedStatus) {
                    if (changes.system_status === DEAL_SYSTEM_STATUS.draft) {
                        this.dealUnsavedStatus = DEAL_SYSTEM_STATUS.draft;
                        this.dealUnsavedStatusEmitter.emit(this.dealUnsavedStatus);
                    } else if (changes.system_status === DEAL_SYSTEM_STATUS.open) {
                        this.dealUnsavedStatus = DEAL_SYSTEM_STATUS.open;
                        this.dealUnsavedStatusEmitter.emit(this.dealUnsavedStatus);
                    }
                }
                const affectedBy = this.dealFormGroup.controls.affected_by!.getRawValue();
                this.isAllWarningsDismissed = affectedBy.every((warning) => warning.is_dismissed);
                this.hasWarnings = !!affectedBy.length;
            });

        // watch on changes in sales entities
        this.dealFormGroup.controls
            .sales_entities!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((salesEntities) => {
                // find primary Sales Entity Profile Id
                const newPSEProfileId = salesEntities.find((se: SalesEntity) => se.is_primary)?.profile?.id;
                if (!newPSEProfileId || newPSEProfileId !== this.primarySalesEntity_ProfileId) {
                    this.dealFormGroup.controls.division__company_group_fk_id!.patchValue(null);
                }
                if (newPSEProfileId === this.primarySalesEntity_ProfileId) {
                    return;
                }
                this.primarySalesEntity_ProfileId = newPSEProfileId;

                if (this.primarySalesEntity_ProfileId) {
                    // get divisions list
                    this.groupsService
                        .getDivisionsForMember(
                            this.sessionService.profile!.company_fk_id!,
                            this.primarySalesEntity_ProfileId,
                            this.dealFormGroup.controls.division__company_group_fk_id!.value
                        )
                        .then((groups) => {
                            this.allDivisions = groups;
                            this._prefillDivision();
                        });
                }
            });
    }

    addLocation(l: IContactLocationTable) {
        // this.dealFormGroup.get('client_type').patchValue(type)
        this.dealFormGroup.get('city')!.patchValue(l.city);
        this.dealFormGroup.get('address')!.patchValue(l.street_address);
        this.dealFormGroup.get('zip')!.patchValue(l.zip);
        this.dealFormGroup.get('state')!.patchValue(l.state);
        this.dealFormGroup.get('street_number')!.patchValue(l.street_number);
        this.dealFormGroup.get('country')!.patchValue(l.country);
        // this.dealFormGroup.get('unit').patchValue();
        this.changeDetector.detectChanges();
    }

    object_keys(obj: Object) {
        return Object.keys(obj);
    }

    uploadTaggings() {
        // set array of company tags
        this.store.pipe(select(selectTagCategories), takeUntil(this.unsubscribe)).subscribe((cats) => {
            cats.forEach((cat) => {
                const tags = cat.tags.map((tag) => {
                    tag.category_title = cat.title;
                    return tag;
                });
                this.tags = [...this.tags, ...tags];

                // select selected tags
                this.selectedTags = this.tags.filter((tag) => {
                    // @ts-ignore
                    return this.dealFormGroup.controls.taggings?.controls.find(
                        (selectedTag: UntypedFormGroup) =>
                            selectedTag.value.tag_fk_id === tag.tag_id && !selectedTag.value.auto_created
                    );
                });

                // set auto-tags
                this.autoTags = this.tags.filter((tag) => {
                    // @ts-ignore
                    return this.dealFormGroup.controls.taggings?.controls.find(
                        (selectedTag: UntypedFormGroup) =>
                            selectedTag.value.tag_fk_id === tag.tag_id && selectedTag.value.auto_created
                    );
                });
            });
        });
    }

    setTags(list: ITag[]) {
        const taggings = list.map((tag) => {
            // @ts-ignore
            const id = this.dealFormGroup.controls.taggings?.controls.find(
                (t: UntypedFormGroup) => t.controls.tag_fk_id.value === tag.tag_id && !t.controls.auto_created.value
            )?.controls?.tagging_id.value;
            return {
                company_fk_id: this.dealFormGroup.controls.creator__company_fk_id!.value,
                entity_id: this.dealFormGroup.controls.id!.value,
                entity_type: 'deal',
                tag_fk_id: tag.tag_id,
                auto_created: false,
                tagging_id: id ? id : null
            };
        });

        // @ts-ignore
        const autoTaggings = this.dealFormGroup.controls.taggings?.controls
            .filter((t: UntypedFormGroup) => t.controls.auto_created.value)
            .map((t: UntypedFormGroup) => t.value);

        // Update taggings array
        this.dealFormGroup.controls.taggings?.patchValue([...taggings, ...autoTaggings]);
        this.dealFormGroup.controls.taggings?.updateValueAndValidity();
    }

    doRemoveAutoTag(index: number, tag: ITag) {
        if (this.dealFormGroup.controls.taggings?.disabled) {
            return;
        }

        const dialogRef = this.dialog.open(DialogConfirmationComponent, {
            width: '320px',
            data: {
                title: 'Confirmation',
                text: 'Are you sure, you want to delete this autogenerated Tag?'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.tagsService
                        .deleteTaggingFromEntity(tag.tag_id, this.dealFormGroup.controls.id!.value)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe(() => {
                            this.autoTags.splice(index, 1);

                            // @ts-ignore
                            const taggings = this.dealFormGroup.controls.taggings?.controls
                                .filter((t: UntypedFormGroup) => !t.controls.auto_created.value)
                                .map((t: UntypedFormGroup) => t.value);
                            // @ts-ignore
                            const autoTaggings = this.dealFormGroup.controls.taggings?.controls
                                .filter(
                                    (t: UntypedFormGroup) =>
                                        t.controls.auto_created.value && t.controls.tag_fk_id.value !== tag.tag_id
                                )
                                .map((t: UntypedFormGroup) => t.value);

                            // Update taggings array
                            this.dealFormGroup.controls.taggings?.patchValue([...taggings, ...autoTaggings]);
                            this.dealFormGroup.controls.taggings?.updateValueAndValidity();

                            this.notificationService.addInfo('Autogenerated tag was successfully deleted');
                        });
                }
            });
    }

    resetAutoTags() {
        const dialogRef = this.dialog.open(DialogConfirmationComponent, {
            width: '320px',
            data: {
                title: 'Confirmation',
                text: 'Are you sure, you want to reset Autogenerated Tags?'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.tagsService
                        .resetAutoTags(this.dealFormGroup.controls.id!.value)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((tags) => {
                            this.autoTags = tags.result;

                            // @ts-ignore
                            const taggings = this.dealFormGroup.controls.taggings?.controls
                                .filter((t: UntypedFormGroup) => !t.controls.auto_created.value)
                                .map((t: UntypedFormGroup) => t.value);
                            const autoTaggings = tags.result.map((tag) => {
                                return {
                                    company_fk_id: this.dealFormGroup.controls.creator__company_fk_id!.value,
                                    entity_id: this.dealFormGroup.controls.id!.value,
                                    entity_type: 'deal',
                                    tag_fk_id: tag.tag_id,
                                    auto_created: true,
                                    tagging_id: tag.tagging_id
                                };
                            });

                            // Update taggings array
                            this.dealFormGroup.controls.taggings?.patchValue([...taggings, ...autoTaggings]);
                            this.dealFormGroup.controls.taggings?.updateValueAndValidity();

                            this.notificationService.addInfo('Autogenerated tags were successfully reset');
                        });
                }
            });
    }

    deleteAutoTags() {
        const dialogRef = this.dialog.open(DialogConfirmationComponent, {
            width: '320px',
            data: {
                title: 'Confirmation',
                text: 'Are you sure, you want to delete all Autogenerated Tags for this Deal?'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.tagsService
                        .deleteAutoTags(this.dealFormGroup.controls.id!.value)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe(() => {
                            this.autoTags = [];

                            // @ts-ignore
                            const taggings = this.dealFormGroup.controls.taggings?.controls
                                .filter((t: UntypedFormGroup) => !t.controls.auto_created.value)
                                .map((t: UntypedFormGroup) => t.value);

                            // Update taggings array
                            this.dealFormGroup.controls.taggings?.patchValue(taggings);
                            this.dealFormGroup.controls.taggings?.updateValueAndValidity();

                            this.notificationService.addInfo('Autogenerated tags were successfully deleted');
                        });
                }
            });
    }

    public async updateDealStatus(status: string) {
        // check if draft deal has closed/processed status
        if (
            this.dealFormGroup.controls.system_status!.value === DEAL_SYSTEM_STATUS.draft &&
            status &&
            (status === Deal.status_SET['closed'] || status === Deal.status_SET['processed'])
        ) {
            this.notificationService.addError("Status of Draft Deal should not be 'Closed' or 'Processed'");
            return;
        }

        // update only status
        await this.dealService.updateDealStatus(this.dealFormGroup.controls.id!.value, {status});

        this.dealFormGroup.controls.status!.setValue(status);
        this.dealFormGroup.updateValueAndValidity();
    }

    makeOpen() {
        Deal.required_fields.forEach((requiredField) => {
            if (this.dealFormGroup.get(requiredField)) {
                this.dealFormGroup.get(requiredField)!.setValidators(Validators.required);
                this.dealFormGroup.get(requiredField)!.updateValueAndValidity();
                this.dealFormGroup.get(requiredField)!.markAllAsTouched();
            }
        });
        this.dealFormGroup.updateValueAndValidity();
        this.dealFormGroup.markAllAsTouched();
        this.dealUnsavedStatus = DEAL_SYSTEM_STATUS.open;
        this.dealUnsavedStatusEmitter.emit(this.dealUnsavedStatus);
        this.canBeRevertedToDraft = true;
    }

    revertToDraft() {
        Deal.required_fields.forEach((requiredField) => {
            if (this.dealFormGroup.get(requiredField)) {
                this.dealFormGroup.get(requiredField)!.clearValidators();
                this.dealFormGroup.get(requiredField)!.updateValueAndValidity();
            }
        });
        this.dealUnsavedStatus = DEAL_SYSTEM_STATUS.draft;
        this.dealUnsavedStatusEmitter.emit(this.dealUnsavedStatus);
        this.dealFormGroup.updateValueAndValidity();
        this.canBeRevertedToDraft = false;
    }

    _prefillDivision() {
        const arrayOfSEPrimaryMembers = this.allDivisions
            .map((d) => new Date(d.added_to_division_at?.toString()).getTime())
            .filter((i) => i);
        // select division in which Primary Sales Entity was added firstly
        const earliestAddedMemberToGroupIndex = arrayOfSEPrimaryMembers.indexOf(
            // @ts-ignore
            Math.min.call(null, arrayOfSEPrimaryMembers)
        );

        if (earliestAddedMemberToGroupIndex > -1) {
            this.dealFormGroup.controls.division__company_group_fk_id!.patchValue(
                this.allDivisions[earliestAddedMemberToGroupIndex].id
            );
        }
    }

    unselectDeactivatedTag(tag: ITag) {
        // if tag exists in the selected list and is deactivated
        if (!this.selectedTags.find((t) => t.tag_id === tag.tag_id && t.deactivated)) {
            return;
        }

        // update selected tags
        this.selectedTags = this.selectedTags.filter((t) => t.tag_id !== tag.tag_id);
        // update taggings array
        // @ts-ignore
        const taggings = this.dealFormGroup.controls.taggings?.getRawValue().filter((t) => t.tag_fk_id !== tag.tag_id);
        this.dealFormGroup.controls.taggings?.patchValue(taggings);
        this.dealFormGroup.controls.taggings?.updateValueAndValidity();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.selectedTagsIdsUpdated.complete();
        this.showDismissWarningEventEmitter.complete();
    }
}
