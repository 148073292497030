<mat-card class="sign-up-card" *ngIf="contactInvite">
    <mat-card-header>
        <div class="title">Create Account</div>
    </mat-card-header>

    <mat-card-content>
        <form *ngIf="signUpForm" [formGroup]="signUpForm" method="post">
            <div class="flex-row">
                <mat-form-field>
                    <input matInput name="account_name" placeholder="Account Name" formControlName="account_name" />
                </mat-form-field>
            </div>

            <div class="flex-row">
                <mat-form-field>
                    <input matInput name="first_name" placeholder="First Name" formControlName="first_name" />
                </mat-form-field>
            </div>

            <div class="flex-row">
                <mat-form-field>
                    <input matInput name="last_name" placeholder="Last Name" formControlName="last_name" />
                </mat-form-field>
            </div>

            <div class="flex-row">
                <mat-form-field>
                    <input matInput type="email" name="email" placeholder="Email" formControlName="email" />
                </mat-form-field>
            </div>

            <div class="flex-row">
                <mat-form-field>
                    <input
                        matInput
                        type="password"
                        name="password"
                        formControlName="password"
                        placeholder="Password"
                        autocomplete="new-password"
                    />
                </mat-form-field>
            </div>

            <div class="flex-row" *ngIf="!inviteHash">
                <mat-form-field>
                    <mat-select placeholder="Company Plan" formControlName="company_plan">
                        <mat-option *ngFor="let plan of allPlans" [value]="plan">{{ plan | fromSnakeCase }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div style="text-align: center">
                <button mat-button class="mat-primary" [disabled]="isProcessing" (click)="submitSignUpForm()">
                    Continue
                </button>
                <br />
                <a routerLink="/login">Already have account? Sign In ...</a>
            </div>
        </form>
    </mat-card-content>
</mat-card>
