import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {GenericFormArray} from '../../../../../../../entites/generic.entity';
import {IReportNodeEntity} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-report-edit-bar-entity',
    templateUrl: './entity.component.html',
    styleUrls: ['./entity.component.scss']
})
export class EntityComponent implements OnInit, OnDestroy {
    @Output() valueChanges: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
    @Input() value: Array<IReportNodeEntity> = [];
    @Input() entityTypeList: Array<any> = ['individual', 'individuals_in_group', 'company_group'];
    @Input() title: string = '';
    @Input() onlyDivisions: boolean = false;
    @Input() initialGroupIds: number[] = [];

    private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
    public formControl: GenericFormArray<any> = new GenericFormArray<any>([]);

    ngOnInit() {
        this.initData();
        this.initSubscription();
    }

    initData() {
        const value = this.value || [];
        this.formControl.patchValue(value);
    }

    initSubscription() {
        this.formControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            this.valueChanges.emit(
                value.map((item: any) => {
                    item.id = item.target_id;
                    return item;
                })
            );
        });
    }

    ngOnDestroy() {
        this.valueChanges.complete();
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }
}
