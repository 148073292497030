import {merge as observableMerge, Observable, Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {DataSource} from '@angular/cdk/collections';
import {Company} from '../../models/company';
import {Profile} from '../../models/profile';
import {CompaniesService} from 'app/services/companies.service';
import {CurrentProfileSource} from './current-profile.source';

@Injectable()
export class SubCompaniesSource extends DataSource<Company> {
    public triggers = {
        load: new Subject<Company[]>()
    };

    private _events = {
        changeSubCompaniesEvent: <Observable<Company[]>>observableMerge(this.triggers.load)
    };

    private _currentSubCompanies: Company[] = [];

    constructor(
        protected companyApi: CompaniesService,
        protected profileSource: CurrentProfileSource
    ) {
        super();
        profileSource.changeProfileEvent.subscribe((profile: Profile) => {
            if (profile.type === Profile.type.default) {
                this.load(profile);
            }
        });
    }

    connect() {
        return this.changeSubCompaniesEvent;
    }

    disconnect() {}

    public load(profile: Profile): Promise<Company[]> {
        return this.companyApi.getSubCompanies(Number(profile.company!.id)).then((list: Company[]) => {
            this._currentSubCompanies = list.slice(); // clone
            this.triggers.load.next(this._currentSubCompanies);
            return list;
        });
    }

    public get changeSubCompaniesEvent() {
        if (this._currentSubCompanies) {
            return this._events.changeSubCompaniesEvent.pipe(startWith(this._currentSubCompanies));
        }
        return this._events.changeSubCompaniesEvent;
    }
}
