import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {IContact} from '@cyberco-nodejs/zipi-typings/contacts.typings';
import {Profile} from '../../../models/profile';

@Component({
    selector: 'app-confirm-create-profile-dialog',
    styles: [
        `
            .item-box {
                margin: 10px 0 0 0;
            }
            .items-wrap {
                min-height: 40px;
                line-height: 18px;
            }
        `
    ],
    template: `
        <h2 md-dialog-title>{{ data.title }}</h2>
        <div md-dialog-content class="items-wrap">
            <div class="item-box" style="max-width: 600px; margin-bottom: 20px;" [innerHtml]="data.message"></div>
            <div class="item-box">
                <h4>Existing Contact Record</h4>
            </div>
            <div style="margin: 10px 0 15px;">
                <div class="item-box d-flex justify-content-between">
                    <div class="col-6">
                        {{ contact!.display_name }} <span *ngIf="contactEmail.length">({{ contactEmail }})</span>
                    </div>
                    <span class="sp-spacer"></span>
                    <div class="col-6" style="text-align: right; margin: 0; padding: 0;">
                        <button mat-raised-button (click)="dialogRef.close({contact: contact})">Use existing</button>
                    </div>
                </div>
            </div>
            <div class="item-box">
                <h4>Create New Contact Record</h4>
            </div>
            <div style="margin: 10px 0 0 15px;">
                <div class="item-box d-flex justify-content-between">
                    <div class="col-6" style="padding: 0;">
                        {{ profile!.first_name }} {{ profile!.last_name }} ({{ profile!.email }})
                    </div>
                    <span class="sp-spacer"></span>
                    <div
                        class="col-6"
                        style="text-align: right; margin: 0; padding: 0;"
                        *ngIf="data.error_details === 'email'"
                    >
                        <mat-form-field class="w-100">
                            <input
                                matInput
                                placeholder="Updated Email Address (Required)"
                                name="updated_email"
                                [(ngModel)]="profile!.email"
                            />
                            <mat-hint
                                *ngIf="profile!.email!.toLowerCase() === contactEmail.toLowerCase()"
                                style="color: red"
                                >Enter a Different Email Address</mat-hint
                            >
                        </mat-form-field>
                    </div>
                    <div
                        class="col-6"
                        style="text-align: right; margin: 0; padding: 0;"
                        *ngIf="data.error_details === 'name'"
                    >
                        <mat-form-field class="w-50">
                            <input
                                matInput
                                placeholder="Updated First Name"
                                name="updated_first_name"
                                [(ngModel)]="profile!.first_name"
                            />
                            <mat-hint
                                *ngIf="
                                    profile!.first_name === contactFirstName && profile!.last_name === contactLastName
                                "
                                style="color: red"
                                >Enter a Different First Name</mat-hint
                            >
                        </mat-form-field>
                        <mat-form-field class="w-50">
                            <input
                                matInput
                                placeholder="Updated Last Name"
                                name="updated_last_name"
                                [(ngModel)]="profile!.last_name"
                            />
                            <mat-hint
                                *ngIf="
                                    profile!.first_name === contactFirstName && profile!.last_name === contactLastName
                                "
                                style="color: red"
                                >Enter a Different Last Name</mat-hint
                            >
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div md-dialog-actions>
            <div style="width: 100%; text-align: right; margin-top: 40px;">
                <button style="margin-right: 10px;" mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">
                    Cancel
                </button>
                <button
                    *ngIf="data.error_details === 'email'"
                    mat-raised-button
                    (click)="dialogRef.close({email: profile!.email})"
                    tabindex="-1"
                    color="primary"
                    [disabled]="profile!.email!.toLowerCase() === contactEmail.toLowerCase()"
                >
                    Submit
                </button>
                <button
                    *ngIf="data.error_details === 'name'"
                    mat-raised-button
                    (click)="dialogRef.close({first_name: profile!.first_name, last_name: profile!.last_name})"
                    tabindex="-1"
                    color="primary"
                    [disabled]="profile!.first_name === contactFirstName && profile!.last_name === contactLastName"
                >
                    Submit
                </button>
            </div>
        </div>
    `
})
export class ConfirmCreateProfileDialogComponent implements OnInit {
    profile: Profile | undefined;
    contact: IContact | undefined;
    contactEmail: string = '';
    contactFirstName: string = '';
    contactLastName: string = '';

    constructor(
        public dialogRef: MatDialogRef<ConfirmCreateProfileDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.profile = this.data.profile;
        this.contact =
            this.data.existing_contacts && this.data.existing_contacts.length ? this.data.existing_contacts[0] : null;
        if (!this.contact) {
            this.dialogRef.close(false);
        } else {
            let mainPerson = this.contact.contact_persons.find(
                (contact_person) => contact_person.type === 'main_person'
            );
            if (!mainPerson && this.contact.contact_persons.length) {
                mainPerson = this.contact.contact_persons[0];
            }
            if (mainPerson) {
                this.contactEmail = mainPerson.email;
                this.contactFirstName = mainPerson.first_name;
                this.contactLastName = mainPerson.last_name;
            }
        }
    }
}
