import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GenericEntity, GenericFormArray, GenericFormGroup} from '../../../../../../entites/generic.entity';
import {ChipNode} from '../../../../../account-info/compensation/models/chip-node';
import {map, takeUntil} from 'rxjs/operators';
import {FetchContacts} from '../../../../../contacts/store/contacts.actions';
import {select, Store} from '@ngrx/store';
import {selectContacts} from '../../../../../contacts/store/contacts.selectors';
import {IContact} from '@cyberco-nodejs/zipi-typings/index';
import {IContactsState} from '../../../../../contacts/store/contacts.reducer';
import {UntypedFormControl} from '@angular/forms';
import {FeatureFlagsService} from '../../../../../feature-flags/feature-flags.service';

class Recipient extends GenericEntity {
    name: ChipNode[] = [];
    email: string = '';
}

@Component({
    selector: 'app-email-disbursement-pdf-dialog',
    templateUrl: 'email-disbursement-pdf-dialog.component.html'
})
export class EmailDisbursementPdfDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    contactsList: IContact[] = [];
    pdfSelectionFC: UntypedFormControl = new UntypedFormControl('deal_disbursement_only');
    recipientFA: GenericFormArray<Recipient> = new GenericFormArray<Recipient>([]);

    multipleEmailPDFenabledFlag: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<EmailDisbursementPdfDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            dual: number | null;
        },
        protected store: Store<IContactsState>,
        protected featureFlagsService: FeatureFlagsService
    ) {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.multipleEmailPDFenabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'deals:send_da_pdf_by_multiple_email'
                );
            });
    }

    ngOnInit() {
        this.store.dispatch(new FetchContacts());
        this.store
            .pipe(
                select(selectContacts), // migrate to ContactsLite: done
                map((contacts) => {
                    this.contactsList = contacts;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.recipientFA.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<Recipient>) => {
            // replace last email string '' with email from contact
            if (values && values.length && values[values.length - 1].name && values[values.length - 1].name.length) {
                const last = values[values.length - 1].name[0];
                if (last && last.target_id) {
                    const email = this._getContactEmail(last.target_id);
                    if (
                        email.length !== 0 &&
                        this.recipientFA.controls[values.length - 1].controls.email?.value.length === 0
                    ) {
                        this.recipientFA.controls[values.length - 1].controls.email?.patchValue(email, {
                            emitEvent: false
                        });
                    }
                }
            }
        });

        this.addAdditionalRecipient();
    }

    _getContactEmail(contactId: number) {
        const selectedContact = this.contactsList.find((contact) => contact.contact_id === contactId);
        let selectedContactEmail = '';
        if (selectedContact) {
            if (!selectedContactEmail) {
                const mainPerson = selectedContact.contact_persons.find((person) => person.type === 'main_person');
                selectedContactEmail = mainPerson ? mainPerson.email : '';

                if (!selectedContactEmail) {
                    const anyPersonWithEmail = selectedContact.contact_persons.find((person) => !!person.email);
                    selectedContactEmail = anyPersonWithEmail ? anyPersonWithEmail.email : '';
                }
            }
        }

        return selectedContactEmail;
    }

    validateEmails(): boolean {
        return this.recipientFA.getRawValue().every((recipient: Recipient) => {
            return this.validateEmailSingle(recipient.email);
        });
    }
    validateEmailSingle(value: string) {
        return !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value);
    }

    save() {
        const emails = this.recipientFA
            .getRawValue()
            .filter((r) => r.email.trim().length && !this.validateEmailSingle(r.email))
            .map((r) => r.email.trim());

        this.dialogRef.close({
            emails: emails,
            pdfSelection: this.pdfSelectionFC.value
        });
    }

    addAdditionalRecipient() {
        this.recipientFA.push(new GenericFormGroup(new Recipient()));
    }

    removeAdditionalRecipient(idx: number) {
        this.recipientFA.removeAt(idx);

        if (!this.recipientFA.getRawValue().length) {
            this.addAdditionalRecipient();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
