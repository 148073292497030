import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {Deal, DEAL_SYSTEM_STATUS} from '../../../../../../models/deal';
import {DealService} from '../../../../../../services/deal.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GenericFormGroup} from '../../../../../../entites/generic.entity';
import {SalesEntity} from '../../../../../../models/sales-entity';
import {CompensationProfileModel} from '../../../../../account-info/compensation/models/compensation-profile.model';
import {CompensationService} from '../../../../../account-info/compensation/compensation.service';
import {DealProcessingService} from '../../deal-processing.service';
import {DealApproveApiService} from '../../deal-approve.api.service';
import {NotificationsService} from 'angular2-notifications';
import {BehaviorSubject, from, Subject} from 'rxjs';
import {UiModsApi} from '../../../../../ui-mods/api/ui-mods.api';
import {UiModsSource} from '../../../../../ui-mods/ui-mods.source';
import {takeUntil} from 'rxjs/operators';
import {AbstractControl, UntypedFormControl} from '@angular/forms';
import {UiModsDealBadgeInListModel} from '../../../../../ui-mods/models/ui-mods.model';
import {FeatureFlagsService} from '@app/modules/feature-flags/feature-flags.service';

@Component({
    selector: 'app-view-deal',
    styleUrls: ['./view-deal.component.scss'],
    templateUrl: 'view-deal.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ViewDealComponent implements OnInit, OnDestroy {
    DEAL_SYSTEM_STATUS = DEAL_SYSTEM_STATUS;
    private unsubscribe: Subject<void> = new Subject();

    @Input() dealIsSelected$: BehaviorSubject<Deal | null> = new BehaviorSubject<Deal | null>(null);
    @Input() sidebarOpened$: Subject<string> = new Subject<string>();

    @Output() viewDealLoadedEmitter: EventEmitter<Deal> = new EventEmitter<Deal>();
    @Output() isDealOverriddenEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() currentSidebarTabEmitter: EventEmitter<string | null> = new EventEmitter<string | null>();
    @Output() topBarButtonsState: EventEmitter<boolean> = new EventEmitter<boolean>();

    public dealIdInUrl: number | undefined = undefined;
    public deal: Deal | undefined = undefined;
    DEAL = Deal;

    isUiModsPending: boolean = false;
    isDealConnectedToSkyslope: boolean = false;
    isBrokerTradesheetEnabled: boolean = false;

    isSidebarOpen: boolean = false;
    currentSidebarTab: string | null = '';
    financialElementsBySalesEntity: {[key: number]: any[]} = {};
    dealFormGroup: GenericFormGroup<Deal> = new GenericFormGroup(new Deal(), 'change');
    salesEntities: SalesEntity[] = [];
    overriddenCompensationFG: GenericFormGroup<CompensationProfileModel> =
        new GenericFormGroup<CompensationProfileModel>(new CompensationProfileModel());

    public addressShowToggle: boolean = true;

    public financialElementsBySalesEntityFC: UntypedFormControl = new UntypedFormControl();

    constructor(
        public dealService: DealService,
        protected dealApproval: DealApproveApiService,
        private notificationsService: NotificationsService,
        public router: Router,
        private route: ActivatedRoute,
        protected compensationService: CompensationService,
        private dealProcessing: DealProcessingService,
        private uiModsApi: UiModsApi,
        private uiModsSource: UiModsSource,
        private featureFlagsService: FeatureFlagsService
    ) {
        this.compensationService.setupCompensationProfileFormValidators(this.overriddenCompensationFG);
        this.dealProcessing.setupDeal(new Deal());
        this.financialElementsBySalesEntityFC = this.dealProcessing.financialElementsBySalesEntityFC;
        this.dealFormGroup = this.dealProcessing.dealFG;
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.isBrokerTradesheetEnabled = this.featureFlagsService.isFeatureEnabled('trade_sheet:broker');
            });
    }

    updateUiMods(dealId: number, res: UiModsDealBadgeInListModel[]) {
        if (Number(dealId) === Number(this.dealIdInUrl)) {
            this.uiModsSource.updateDealBadgesInList(
                res.map((badge) => this.uiModsSource.buildUiModDealBadgeInList(badge, false))
            );
            this.uiModsSource.refreshDealBadgesOnPage(); // update GREY badges also
            this.isUiModsPending = false;
        }
    }

    async ngOnInit() {
        this.dealIsSelected$.pipe(takeUntil(this.unsubscribe)).subscribe((deal: Deal | null) => {
            if (deal) {
                this.topBarButtonsState.emit(true);
                if (!this.deal || this.deal.id !== deal.id) {
                    if (Object.keys(deal).length > 1) {
                        this.deal = deal;
                    }
                    this.dealIdInUrl = deal.id as number;
                    this.initDeal();

                    // by default all badges are disabled, to enable badges for this deal we need to run this request
                    // after all badges list was updated it needs to do this request AGAIN
                    this.uiModsSource.uiMods.pipe(takeUntil(this.unsubscribe)).subscribe((uiMods) => {
                        this.isUiModsPending = true;
                        this.uiModsApi.loadUiModsByDeal(Number(deal.id)).then((res) => {
                            if (res && res.length) {
                                for (const uiModInstance of res) {
                                    if (
                                        uiModInstance.uiMod &&
                                        uiModInstance.uiMod.addon &&
                                        uiModInstance.uiMod.addon.slug === 'skyslope_integration'
                                    ) {
                                        this.isDealConnectedToSkyslope = true;
                                        break;
                                    }
                                }
                                this.updateUiMods(Number(deal.id), res);
                            }
                        });
                    });
                }
            }
        });

        this.sidebarOpened$.pipe(takeUntil(this.unsubscribe)).subscribe((tab) => {
            if (tab) {
                this.openSideBar(tab);
            } else {
                this.isSidebarOpen = false;
                this.closeSideBar();
            }
        });
    }

    private async initDeal() {
        // Here we need such construction to prevent filling deal form in the dealProcessingService after the component is destroyed.
        // For example, when user clicks the "Create" a new deal button BEFORE the request to get the deal from the API is complete
        from(this.dealService.getViewDeal(Number(this.dealIdInUrl)))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((deal) => {
                this.deal = deal;
                this.initForm(this.deal);

                this.viewDealLoadedEmitter.emit(this.deal);
                if (this.dealFormGroup.controls.disbursement_approved?.value) {
                    this.disableDealFieldsIfApproved(this.dealFormGroup.controls.disbursement_approved?.value);
                }
            });
    }

    openSideBar(tab: string): void {
        const els: HTMLCollectionOf<Element> = document.getElementsByClassName('mat-sidenav-content');

        if (this.isSidebarOpen && this.currentSidebarTab !== tab) {
            this.currentSidebarTab = tab;
        } else if (this.isSidebarOpen && this.currentSidebarTab === tab) {
            // for showing overflow left menu
            Array.prototype.forEach.call(els, (el: any) => {
                el.style.overflow = 'auto';
            });
            this.isSidebarOpen = false;
        } else if (!this.isSidebarOpen) {
            // for hiding overflow left menu
            Array.prototype.forEach.call(els, (el: any) => {
                el.style.overflow = 'hidden';
            });
            this.currentSidebarTab = tab;
            this.isSidebarOpen = true;
        }
    }

    closeSideBar() {
        this.currentSidebarTab = null;
        this.currentSidebarTabEmitter.emit(null);

        // for showing overflow left menu
        const els = document.getElementsByClassName('mat-sidenav-content');
        Array.prototype.forEach.call(els, (el: any) => {
            el.style.overflow = 'auto';
        });
    }

    initForm(deal: Deal) {
        if (typeof this.deal === 'undefined') {
            return;
        }

        // remove duplicate conditions in applied element rules
        this.deal.sales_entities = (this.deal.sales_entities as any).map((se: any) => ({
            ...se,
            applied: se.applied.map((finEl: any) => ({
                ...finEl,
                element: {
                    ...finEl.element,
                    rules: finEl.element.rules.map((rule: any) => ({
                        ...rule,
                        and_conditions:
                            // if type, value, operator are identical - remove first condition
                            rule.and_conditions[0].type === rule.type &&
                            rule.and_conditions[0].value === rule.value &&
                            rule.and_conditions[0].operator === rule.operator
                                ? rule.and_conditions.slice(1)
                                : rule.and_conditions
                    }))
                }
            }))
        }));
        this.dealProcessing.dealFG.patchValue(this.deal, {emitEvent: false});
        this.dealProcessing.syncFinancialTransfers();

        deal.sales_entities.forEach((salesEntity) => {
            this.financialElementsBySalesEntity[salesEntity.id!] = salesEntity.applied;
        });
        this.salesEntities = deal.sales_entities.filter((se) => se.role === SalesEntity.role_SET.agent);

        const primarySE = this.salesEntities.find((el) => el.is_primary === true);
        if (primarySE) {
            const currentSE = this.salesEntities.find((el) => el.id === primarySE.id);
            if (currentSE) {
                this.isDealOverriddenEmitter.emit(!!currentSE.overriden__compensation_profile_fk_id);
            }
        }
        this.topBarButtonsState.emit(false);
    }

    onOverrideChange(event: {isOverridden: boolean}) {
        this.isDealOverriddenEmitter.emit(event.isOverridden);
    }

    public object_keys(obj: {[key: string]: any}) {
        return Object.keys(obj);
    }

    public async updateDealStatus(status: string) {
        if (typeof this.deal === 'undefined' || this.deal.id === null) {
            return;
        }
        // update only status
        await this.dealService.updateDealStatus(this.deal.id, {status});
        this.deal.status = status;
    }

    downloadTradeSheetPDF(format: 'condensed' | 'expanded' | 'broker') {
        if (!this.isBrokerTradesheetEnabled && format === 'broker') {
            this.notificationsService.error('Broker Trade Sheet feature is not yet available');
            return;
        }
        if (!this.dealFormGroup) {
            return;
        }
        const idControl: AbstractControl | null = this.dealFormGroup.get('id');
        if (!idControl || !idControl.value) {
            return;
        }

        this.dealApproval.getTradeSheetPdf(idControl.value, format).then((response) => {
            if (response.error) {
                this.notificationsService.error(response.error);
                return;
            }

            if (response.data) {
                const fileBlob = new Blob([new Uint8Array(response.data)], {type: 'application/pdf'});
                window.open(URL.createObjectURL(fileBlob), '_blank');
                return;
            }
        });
    }

    disableDealFieldsIfApproved(approved: boolean) {
        if (approved) {
            Object.keys(this.dealFormGroup.controls).forEach((v) => {
                // @ts-ignore
                this.dealFormGroup.controls[v]?.disable();
            });
            this.dealFormGroup.disable();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.dealIsSelected$.complete();
        this.sidebarOpened$.complete();
        this.viewDealLoadedEmitter.complete();
        this.isDealOverriddenEmitter.complete();
        this.currentSidebarTabEmitter.complete();
    }
}
