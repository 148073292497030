import {Component, Input} from '@angular/core';
import {IReport} from '@cyberco-nodejs/zipi-typings';
import {IReportsPreviewResultData} from '../../../../store/reports.reducer';

@Component({
    selector: 'reports-preview-trust-reconciliation',
    templateUrl: './trust-reconciliation.component.html',
    styleUrls: ['./trust-reconciliation.component.scss']
})
export class TrustReconciliationPreviewComponent {
    @Input() reportData: IReportsPreviewResultData | null = null;

    @Input()
    set report(data: IReport | null) {
        if (data) {
            this._report = data;
            this.bankBalanceTooltip = data.settings.is_override_bank_balance
                ? 'This value was entered by user'
                : 'This value was calculated by system';
        }
    }

    get report() {
        return this._report;
    }

    private _report: IReport | null = null;
    public bankBalanceTooltip: string = '';

    constructor() {}
}

interface IReportResult {
    bankBalance: {
        statementBankDate: string;
        begin: string;
        credit: number;
        debit: number;
        total: number;
    };
    bookBalance: {
        begin: string;
        credit: number;
        debit: number;
        total: number;
        endDate: string;
    };
    periodData: {
        startDate: string;
        endDate: string;
    };
    contacts: {
        amount_sum: number;
        address: string;
        cleared_amount: number;
        contact_fk_id: number;
        context_entity_id: number;
        contact: string;
        uncleared_amount: number;
    }[];
    contactTotal: number;
    ledgerAccount: IReportsPreviewResultData;
}
