export const FLATFILE_IMPORT_SETTINGS_BY_TYPE: {[index: string]: any} = {
    products_services: {
        title: 'Import Products/Services',
        type: 'products_services',
        fields: [
            {
                label: 'Product Unique Key',
                key: 'product_id'
            },
            {
                label: 'Name',
                key: 'name',
                validators: [{validate: 'required'}, {validate: 'unique'}]
            },
            {
                label: 'Price',
                key: 'price',
                validators: [
                    {validate: 'required'},
                    {
                        validate: 'regex_matches',
                        regex: '^\\d{1,10}(\\.\\d{1,4})?$',
                        error: 'Only can be number.'
                    }
                ]
            },
            {label: 'Description', key: 'description'},
            {
                label: 'Ledger Account',
                key: 'ledger_account_name',
                validators: [{validate: 'required'}],
                // @ts-ignore
                type: 'select',
                options: []
            }
        ]
    },
    chart_of_accounts: {
        title: 'Import Chart Of Accounts',
        type: 'chart_of_accounts',
        fields: [
            {
                label: 'Account Name',
                key: 'name',
                validators: [
                    {
                        validate: 'required',
                        error: 'Field cannot be blank.'
                    }
                ]
            },
            {
                label: 'Account Type',
                key: 'type',
                validators: [
                    {
                        validate: 'required',
                        error: 'Field cannot be blank.'
                    }
                ],
                // @ts-ignore
                type: 'select',
                options: []
            },
            {
                label: 'Ledger Code',
                key: 'code'
            },
            {
                label: 'Description',
                key: 'description'
            },
            {
                label: 'Parent Name',
                key: 'parentName'
            },
            {
                label: 'Bank Account Number',
                key: 'number'
            },
            {
                label: 'Currency',
                key: 'currency',
                // @ts-ignore
                type: 'select',
                options: [{label: 'USD', value: 'USD'}]
            },
            {
                label: 'Show in Expenses?',
                key: 'expense',
                // @ts-ignore
                type: 'select',
                options: [
                    {value: 'Yes', label: 'Yes'},
                    {value: 'No', label: 'No'}
                ],
                validators: [
                    {
                        validate: 'required',
                        error: 'Field cannot be blank.'
                    }
                ]
            },
            {
                label: 'Accessible for All Divisions?',
                key: 'is_accessible_for_all',
                // @ts-ignore
                type: 'select',
                options: [
                    {value: 'Yes', label: 'Yes'},
                    {value: 'No', label: 'No'}
                ],
                validators: [
                    {
                        validate: 'required',
                        error: 'Field cannot be blank.'
                    }
                ]
            },
            {
                label: 'Accessible for named Company Divisions',
                key: 'accessible_for'
            }
        ]
    },
    contacts: {
        title: 'Import Contacts',
        type: 'contacts',
        fields: [
            {label: 'Contact Unique Key', key: 'contact_id'},
            {
                label: 'Display Name',
                key: 'display_name',
                validators: [
                    {validate: 'required'},
                    {
                        validate: 'regex_matches',
                        regex: '^[a-zA-Z 0-9,.&")(\'-]+$',
                        error: 'The Display Name is not valid.'
                    }
                ]
            },
            {label: 'Company Name', key: 'company_name'},
            {label: 'First Name', key: 'first_name'},
            {label: 'Last Name', key: 'last_name'},
            {label: 'Preferred Name', key: 'preferred_name'},
            {
                label: 'Email Address',
                key: 'email',
                validators: [
                    {
                        validate: 'regex_matches',
                        regex: '^[a-zA-Z0-9\\.\\+\\-_]+@[a-zA-Z0-9\\.\\-]+$',
                        error: 'The Email is not valid.'
                    }
                ]
            },
            {label: 'Country Code', key: 'country_code'},
            {
                label: 'Phone',
                key: 'phone',
                validators: [
                    {
                        validate: 'regex_matches',
                        regex: '^\\d{1,10}$',
                        error: 'Invalid phone format. Use "xxxxxxxxxx".'
                    }
                ]
            },
            {
                label: 'Fax',
                key: 'fax',
                validators: [
                    {
                        validate: 'regex_matches',
                        regex: '^\\d{1,10}$',
                        error: 'Invalid Fax format. Use "xxxxxxxxxx".'
                    }
                ]
            },
            // location
            {label: 'Location Name', key: 'location_name'},
            {label: 'Street Number', key: 'street_number'},
            {label: 'Street Address', key: 'street_address'},
            {label: 'Unit Number', key: 'unit_number'},
            {label: 'City', key: 'city'},
            {label: 'State', key: 'state'},
            {label: 'Zip', key: 'zip'},
            {label: 'Country', key: 'country'},
            {label: 'Category', key: 'category'},
            // we don't use this param in import, but we should add it, because it's a part of detailed contacts report file
            {label: 'Directory Status', key: 'directory_status'},
            {label: 'Class', key: 'class'},
            // unique keys for updating
            {label: 'Contact Person Unique Key', key: 'unique_person_key'},
            {label: 'Contact Location Unique Key', key: 'unique_location_key'},
            // payroll
            {label: 'Payroll Type', key: 'type'},
            {label: 'Payroll Name of Recipient', key: 'name'},
            {label: 'Payroll TIN Type', key: 'tin_type'},
            {label: 'Payroll TIN', key: 'tin'},
            {label: 'Payroll Start Date', key: 'start_date'},
            {label: 'Payroll Filing Status', key: 'filing_status'},
            {label: 'Payroll Withholding Amount', key: 'withholding_amount'},
            {label: 'Payroll Extra Withholding Amount', key: 'extra_withholding_amount'},
            {label: 'Payroll Other Income', key: 'other_income'},
            {label: 'Payroll Deductions', key: 'deductions'},
            // payroll location
            {label: 'Payroll Location Name', key: 'payroll_location_name'},
            {label: 'Payroll Street Number', key: 'payroll_street_number'},
            {label: 'Payroll Street Name', key: 'payroll_street_address'},
            {label: 'Payroll Unit Number', key: 'payroll_unit_number'},
            {label: 'Payroll City', key: 'payroll_city'},
            {label: 'Payroll State', key: 'payroll_state'},
            {label: 'Payroll Postal Code', key: 'payroll_zip'},
            {label: 'Payroll Country Code', key: 'payroll_country'},
            {label: 'Contact Payroll Unique Key', key: 'contact_payroll_id'} // payroll unique keys for updating
        ]
    },
    profiles: {
        title: 'Import Profiles',
        type: 'profiles',
        fields: [
            {label: 'Profile Unique Key', key: 'profile_id'},
            {
                label: 'Email Address',
                key: 'email',
                validators: [
                    {validate: 'required'},
                    {
                        validate: 'regex_matches',
                        regex: '^[a-zA-Z0-9\\.\\+\\-_]+@[a-zA-Z0-9\\.\\-]+$',
                        error: 'The Email is not valid.'
                    }
                ]
            },
            {label: 'First Name', key: 'first_name'},
            {label: 'Last Name', key: 'last_name'},
            {label: 'Preferred Name', key: 'preferred_name'},
            {label: 'Groups', key: 'groups'},
            {label: 'Divisions', key: 'divisions'},
            {
                label: 'Roles',
                key: 'roles',
                validators: [{validate: 'required'}]
            },
            {label: 'Address Street Number', key: 'street_number'},
            {label: 'Address Street Name', key: 'street_address'},
            {label: 'Address Unit Number', key: 'unit_number'},
            {label: 'City', key: 'city'},
            {label: 'State', key: 'state'},
            {
                label: 'Postal Code',
                key: 'zip',
                validators: [
                    {
                        validate: 'regex_matches',
                        regex: '^\\d{1,5}$',
                        error: 'Invalid format. Use "xxxxx".'
                    }
                ]
            },
            {label: 'Country Code', key: 'country'},
            {label: 'Telephone Country Code', key: 'phone_country_code'},
            {
                label: 'Phone Number',
                key: 'phone',
                validators: [
                    {
                        validate: 'regex_matches',
                        regex: '^\\d{1,10}$',
                        error: 'Invalid phone format. Use "xxxxxxxxxx".'
                    }
                ]
            },
            {
                label: 'Start Date',
                key: 'hire_date',
                validators: [{validate: 'required'}]
            },
            {label: 'End Date', key: 'termination_date'},
            {label: 'Anniversary Date', key: 'anniversary_date'},
            {label: 'Date of Birth', key: 'date_of_birth'},
            {label: 'License number', key: 'license_number'},
            {label: 'License Expiration Date', key: 'license_expiration'},
            {
                label: 'Create My Business Account?',
                key: 'is_agent_company_needed',
                // @ts-ignore
                type: 'select',
                options: [
                    {value: 'Yes', label: 'Yes'},
                    {value: 'No', label: 'No'}
                ]
            },
            // payroll
            {label: 'Payroll Type', key: 'type'},
            {label: 'Payroll Name of Recipient', key: 'name'},
            {label: 'Payroll TIN Type', key: 'tin_type'},
            {label: 'Payroll TIN', key: 'tin'},
            {label: 'Payroll Start Date', key: 'start_date'},
            {label: 'Payroll Filing Status', key: 'filing_status'},
            {label: 'Payroll Withholding Amount', key: 'withholding_amount'},
            {label: 'Payroll Extra Withholding Amount', key: 'extra_withholding_amount'},
            {label: 'Payroll Other Income', key: 'other_income'},
            {label: 'Payroll Deductions', key: 'deductions'},
            // payroll location
            {label: 'Payroll Location Name', key: 'payroll_location_name'},
            {label: 'Payroll Street Number', key: 'payroll_street_number'},
            {label: 'Payroll Street Name', key: 'payroll_street_address'},
            {label: 'Payroll Unit Number', key: 'payroll_unit_number'},
            {label: 'Payroll City', key: 'payroll_city'},
            {label: 'Payroll State', key: 'payroll_state'},
            {label: 'Payroll Postal Code', key: 'payroll_zip'},
            {label: 'Payroll Country Code', key: 'payroll_country'},
            {label: 'Contact Payroll Unique Key', key: 'contact_payroll_id'} // payroll unique keys for updating
        ]
    }
};
