import {Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {
    timePeriodObj,
    transactionTypeList,
    listOfTransactionStatus,
    listOfDealApproval,
    listOfPropertyClass
} from '../../../../constants';
import {getNextActivePanel, isLastPanelItem} from '../../helper';
import {IReport, IReportSetting, IReportSettingKeys, ITag} from '@cyberco-nodejs/zipi-typings';
import {ReplaySubject} from 'rxjs';
import {TagsService} from '../../../../../../services/api/tags.service';
import {takeUntil} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {selectProcessedSOB, selectSubTypes} from '../../../../../../store/company-wide/company-wide.selectors';
import {ICompanyWideState} from '../../../../../../store/company-wide/company-wide.reducer';
import {IReportPeriodValue} from '@app/modules/reports/components/report-edit-bar/components/sub-component/report-period/report-period.component';

@Component({
    selector: 'app-report-edit-bar-detailed',
    templateUrl: './detailed.component.html',
    styleUrls: ['./detailed.component.scss']
})
export class DetailedBarComponent implements OnInit, OnDestroy {
    @Output() valueChanges: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Output() action: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Input() report: IReport | null = null;

    private defaultValues: {[key: string]: any} = {
        time_period: timePeriodObj.this_year.value,
        date_type: 'closing_date',
        transaction_type: ['buyer', 'seller'],
        transaction_status: ['opportunity', 'active', 'pending', 'processed'],
        property_class: ['residential', 'commercial'],
        deal_approval: ['approved', 'unapproved'],
        grouping: 'type'
    };
    private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
    public activePanel: string = 'report_period';
    public transactionTypeList: Array<{title: string; value: string}> = transactionTypeList;
    public listOfTransactionStatus: Array<{title: string; value: string}> = listOfTransactionStatus;
    public listOfPropertyClass: Array<{title: string; value: string}> = listOfPropertyClass;
    public listOfDealApproval: Array<{title: string; value: string}> = listOfDealApproval;
    public allTags: ITag[] = [];
    public reportSettings: IReportSetting | null = null;
    public isShowNext: boolean = true;
    public sourcesOfBusiness: {title: string; value: string}[] = [];
    public reportOptions = [
        {
            value: 'report_period',
            title: 'Report Period'
        },
        {
            value: 'deal_type',
            title: 'Transaction Type'
        },
        {
            value: 'deal_property_class',
            title: 'Property Class'
        },
        {
            value: 'deal_status',
            title: 'Status'
        },
        {
            value: 'deal_approval',
            title: 'Deal Approval'
        },
        {
            value: 'report_tagging',
            title: 'Report Tagging'
        },
        {
            value: 'entity',
            title: 'Entity'
        },
        {
            value: 'division',
            title: 'Division'
        },
        {
            value: 'source_of_business',
            title: 'Source Of Business'
        }
    ];
    public subTypes: Array<{title: string | null; value: string | null}> = [];

    constructor(
        private tagsService: TagsService,
        private store: Store<ICompanyWideState>
    ) {}

    ngOnInit() {
        this.initData();
        this.initSubscription();
    }

    initData() {
        if (this.report) {
            this.reportSettings = {...this.defaultValues, ...this.report.settings};
        }
    }

    initSubscription() {
        this.tagsService
            .getCompanyTags()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.allTags = data.result;
            });

        this.store.pipe(select(selectProcessedSOB), takeUntil(this.unsubscribe)).subscribe((sob) => {
            this.sourcesOfBusiness = sob.sourceList.map((sob) => ({title: sob.label!, value: sob.label!}));
        });

        this.store.pipe(select(selectSubTypes), takeUntil(this.unsubscribe)).subscribe((subTypes) => {
            if (subTypes) {
                this.subTypes = subTypes.map((subType) => {
                    return {title: subType.label, value: subType.label};
                });
            }
        });
    }

    onReportPeriodValueChanges(data: IReportPeriodValue) {
        if (!this.reportSettings) return;
        this.reportSettings.time_period = data.time_period;
        this.reportSettings.time_period_start = data.time_period_start;
        this.reportSettings.time_period_end = data.time_period_end;
        this.reportSettings.date_type = data.date_type;
    }

    onSelectOptions(event: {value: string}) {
        this.activePanel = event.value;
        this.isShowNext = !isLastPanelItem(this.activePanel, this.reportOptions);
    }

    toggleIncludeSubItemsCheckbox() {
        if (
            this.reportSettings &&
            !this.reportSettings.is_include_sub_types &&
            this.reportSettings.transaction_sub_type &&
            this.reportSettings.transaction_sub_type.length
        ) {
            this.reportSettings.transaction_sub_type = [];
        }
    }

    onValueChanges(property: IReportSettingKeys, data: any) {
        switch (property) {
            default:
                if (this.reportSettings) {
                    this.reportSettings[property] = data as never;
                }
        }
    }

    onAction(event: string) {
        const response: {
            type: string;
            data: IReportSetting | null;
        } = {
            type: event,
            data: null
        };

        switch (event) {
            case 'save':
            case 'run':
                response['data'] = this.reportSettings;
                this.action.emit(response);
                break;
            case 'cancel':
            case 'delete':
                this.action.emit(response);
                break;
            case 'next':
                const nextData = getNextActivePanel(this.activePanel, this.reportOptions);
                this.activePanel = nextData.name;
                this.isShowNext = !nextData.isLast;
                break;
            default:
        }
    }

    ngOnDestroy() {
        this.action.complete();
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }
}
