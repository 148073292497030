<section>
    <app-settings-toolbar (openCreateAutoTagDialogEmitter)="openCreateAutoTagDialog($event)"></app-settings-toolbar>

    <!------- List of automations ------->
    <div class="table-background">
        <table
            mat-table
            *ngIf="this.autoTagsEnabledFlag"
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            [matSortActive]="scrollData.sort_column"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">Created Date</th>
                <td mat-cell *matCellDef="let element" class="w-15">
                    {{ element?.created_at | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-30">Title</th>
                <td mat-cell *matCellDef="let element" class="w-30 px-1">
                    {{ element.title | titlecase }}
                </td>
            </ng-container>

            <ng-container matColumnDef="tag_category_title">
                <th mat-header-cell *matHeaderCellDef class="w-25">Category Connected Tag</th>
                <td mat-cell *matCellDef="let element" class="w-25 px-1">
                    {{ element.tag?.tag_category?.title | titlecase }}
                </td>
            </ng-container>

            <ng-container matColumnDef="tag_title">
                <th mat-header-cell *matHeaderCellDef class="w-25">Connected Tag</th>
                <td mat-cell *matCellDef="let element" class="w-25 px-1">
                    {{ element.tag?.title | titlecase }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="w-5">Actions</th>
                <td mat-cell *matCellDef="let element; let idx = index" class="w-5">
                    <button
                        mat-icon-button
                        (click)="$event.stopPropagation(); $event.preventDefault()"
                        [matMenuTriggerFor]="menu"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openCreateAutoTagDialog(element)">Edit</button>
                        <button
                            mat-menu-item
                            rbac
                            [rule]="{company_settings__manage_tags: true}"
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'block'}"
                            [denyStyle]="{display: 'none'}"
                            *ngIf="element.action_type === 'add_tag_to_deal'"
                            [disabled]="element.is_autotag_applied"
                            (click)="dialogApplySavedTagToAllDeals(element)"
                        >
                            Apply to All Deals
                        </button>
                        <button
                            mat-menu-item
                            (click)="deleteAutogeneratedTags(element)"
                            [disabled]="!element.is_autotag_applied"
                        >
                            Unapply from all deals
                        </button>
                        <button mat-menu-item (click)="deleteAutoTag(element.automation_id, idx)">Delete</button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="zp-table-row"></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div
            *ngIf="!isLoading && dataSource && dataSource.data.length === 0"
            class="text-muted p-3 text-center no-items"
        >
            There are no automations.
        </div>
    </div>
</section>
