<div>
    <div style="padding: 24px">
        <h3 class="d-flex align-items-center">
            <span>Automatic Syncing</span>
        </h3>

        <div *ngIf="formGroup">
            <div class="setting-option">
                <div>
                    <div class="font-weight-bold">Bills</div>
                    <div>Sync all bills automatically from Books to QuickBooks.</div>
                </div>
                <div>
                    <mat-slide-toggle (click)="$event.stopPropagation()" [formControl]="formGroup.controls.bill">
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="setting-option">
                <div>
                    <div class="font-weight-bold">Invoices</div>
                    <div>Sync all invoices automatically from Books to QuickBooks.</div>
                </div>
                <div>
                    <mat-slide-toggle (click)="$event.stopPropagation()" [formControl]="formGroup.controls.invoice">
                    </mat-slide-toggle>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-end p-4">
        <button mat-raised-button class="mat-primary ml-2 button print-btn" (click)="saveSettings()">Save</button>
    </div>
</div>
