import {Routes, RouterModule} from '@angular/router';
import {IndexComponent} from './index/index.component';
import {UserLayoutComponent} from '../../layouts/user-layout.component';
import {AuthBootstrapService} from 'app/services/resolvers/auth-bootstrap.service';
import {DenyGlobalViewResolver} from '../../services/resolvers/deny-global-view.resolver';
import {AddonsComponent} from './addons/addons.component';

export const routes: Routes = [
    {
        path: 'profile',
        component: UserLayoutComponent,
        resolve: {
            denyGv: DenyGlobalViewResolver,
            authBootstrap: AuthBootstrapService
        },
        children: [
            {
                path: '',
                component: IndexComponent,
                children: [
                    {
                        path: '',
                        redirectTo: 'addons',
                        pathMatch: 'full'
                    },
                    {
                        path: 'addons',
                        component: AddonsComponent,
                        data: {
                            title: 'Addons'
                        }
                    }
                ]
            }
        ]
    }
];

export const routing = RouterModule.forChild(routes);
