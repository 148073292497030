import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ReportCategoriesService} from '../../services/report-categories.service';

@Component({
    selector: 'app-edit-category-dialog',
    templateUrl: './edit-category-dialog.component.html',
    styleUrls: ['./edit-category-dialog.component.css']
})
export class EditCategoryDialogComponent implements OnInit {
    categoryName: string = '';
    categoryType: string = '';
    isShowCategoryType: boolean = false;
    categoryTypes: Array<{title: string; value: string}> = [
        {
            title: 'Personal',
            value: 'personal'
        },
        {
            title: 'Company',
            value: 'company'
        }
    ];

    constructor(
        public dialogRef: MatDialogRef<EditCategoryDialogComponent>,
        private reportCategoryService: ReportCategoriesService,
        @Inject(MAT_DIALOG_DATA) public modeParams: any
    ) {}

    ngOnInit() {
        this.categoryName = this.modeParams.title;
    }

    saveCategory() {
        if (!this.modeParams.categoryId) {
            throw new Error(`Category Id was not found`);
        }

        this.reportCategoryService
            .updateReportsCategory(this.modeParams.categoryId, {
                title: this.categoryName
            })
            .toPromise()
            .then(() => {
                this.dialogRef.close(true);
            });
    }

    close() {
        this.dialogRef.close();
    }
}
