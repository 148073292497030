<!------- Widgets filters header ------->
<app-board-settings
    #filter_widget
    [board]="board"
    [editMode]="editMode"
    [widgetsList]="widgetsList"
    (customizeVisibility)="onCustomizeVisibility($event)"
    (filterApplied)="onFilterApplied($event)"
    (deleteBoard)="onDeleteBoard($event)"
>
</app-board-settings>

<!------- List of Widgets ------->
<div *ngIf="widgetsList.length > 0" class="w-container" cdkDropListGroup>
    <!------- First column ------->
    <div
        class="widgets-column"
        cdkDropList
        #firstList="cdkDropList"
        id="0"
        [cdkDropListData]="firstColumn"
        (cdkDropListDropped)="drop($event)"
    >
        <div *ngFor="let widget of firstColumn" cdkDrag [cdkDragDisabled]="!editMode || widget.type === 'add'">
            <app-decide-widget
                class="widget-box"
                [getDataSubj]="filterSubj"
                [customizeVisibility]="editMode"
                [widget]="widget"
                [board]="board"
                [widgetsList]="widgetsList"
                [inputFilter]="filter_widget?.model"
                (changeWidgetList)="onChangeWidgetList($event)"
                (onCrudAction)="onWidgetCrudAction($event)"
            >
            </app-decide-widget>
        </div>
    </div>
    <!------- Second column ------->
    <div
        class="widgets-column"
        cdkDropList
        #secondList="cdkDropList"
        id="1"
        [cdkDropListData]="secondColumn"
        (cdkDropListDropped)="drop($event)"
    >
        <div *ngFor="let widget of secondColumn" cdkDrag [cdkDragDisabled]="!editMode">
            <app-decide-widget
                class="widget-box"
                [getDataSubj]="filterSubj"
                [customizeVisibility]="editMode"
                [widget]="widget"
                [board]="board"
                [widgetsList]="widgetsList"
                [inputFilter]="filter_widget?.model"
                (changeWidgetList)="onChangeWidgetList($event)"
                (onCrudAction)="onWidgetCrudAction($event)"
            >
            </app-decide-widget>
        </div>
    </div>
    <!------- Third column ------->
    <div
        class="widgets-column"
        cdkDropList
        #thirdList="cdkDropList"
        id="2"
        [cdkDropListData]="thirdColumn"
        (cdkDropListDropped)="drop($event)"
    >
        <div *ngFor="let widget of thirdColumn" cdkDrag [cdkDragDisabled]="!editMode">
            <app-decide-widget
                class="widget-box"
                [getDataSubj]="filterSubj"
                [customizeVisibility]="editMode"
                [widget]="widget"
                [board]="board"
                [widgetsList]="widgetsList"
                [inputFilter]="filter_widget?.model"
                (changeWidgetList)="onChangeWidgetList($event)"
                (onCrudAction)="onWidgetCrudAction($event)"
            >
            </app-decide-widget>
        </div>
    </div>
</div>

<!------- No widgets ------->
<div *ngIf="widgetsList | noWidgetsBoard" class="no-widgets-board p-5 mt-3">
    <div>
        No widgets on this board.<br />
        Please use the edit button above to add content.
    </div>
</div>
