import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';

import {RbacService} from '../../modules/rbac/rbac.service';

const NOT_FOUND_PAGE_URL = '/default-page';

@Injectable()
export class RedirectGuard implements CanActivate {
    constructor(
        private rbac: RbacService,
        private _router: Router
    ) {}

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        // because it works on childrens also we need to check exact url
        // and if it not equals - do nothing
        if (state.url !== next.data.url) {
            return true;
        }

        const result = await this.rbac.isAllowedFirst(next.data.redirectGuardData);

        if (!result) {
            this._router.navigate([NOT_FOUND_PAGE_URL]);
            return false;
        }

        const firstAllowedRoute = result.find((el) => el.isAllowed);
        if (!firstAllowedRoute) {
            this._router.navigate([NOT_FOUND_PAGE_URL]);
            return false;
        }

        if (next.data.absolutePath) {
            this._router.navigate(firstAllowedRoute.path.split('/'));
            return false;
        }

        this._router.navigate([state.url, firstAllowedRoute.path]);
        return false;
    }
}
