import {Note} from '../../../../../../models/note';
import {ListItem} from '../../../../../../models/calculation';
import {Client} from '../../../../../../models/client';

export const TYPE_NUMBER = 'number';
export const TYPE_STRING = 'string';
export const TYPE_CURRENCY = 'currency';
export const TYPE_DATE = 'date';
export const TYPE_BOOLEAN = 'boolean';
export const TYPE_LIST = 'list';
export const TYPE_IMAGE = 'image';
export const TYPE_PERCENTAGE = 'percentage';
export const TYPE_CALLBACK = 'callback';

export class AuditLogVocabulary {
    public data = {
        calculation: {
            input: {
                coSplit: {
                    cap_reached_value: {
                        label: 'Commission Split CAP reached value',
                        type: TYPE_CURRENCY
                    }
                }
            },
            output: {
                gci: {
                    value: {
                        label: 'Gci amount',
                        type: TYPE_CURRENCY
                    },
                    percentage: {
                        label: 'Gci percentage',
                        type: TYPE_PERCENTAGE
                    }
                },
                gciAfterRefFee: {
                    value: {
                        label: 'Gci after fee amount',
                        type: TYPE_CURRENCY
                    }
                },
                gciAfterRoyaltyAndCoSplit: {
                    value: {
                        label: 'Gci after royalty and CoSplit amount',
                        type: TYPE_CURRENCY
                    }
                },
                gciAfterIncomesOutcomes: {
                    value: {
                        label: 'Gci after incomes/expenses amount',
                        type: TYPE_CURRENCY
                    }
                },
                gciAfterTaxesBizExp: {
                    value: {
                        label: 'Gci after taxes biz exp amount',
                        type: TYPE_CURRENCY
                    }
                },
                gciAfterTeamMemberSplit: {
                    value: {
                        label: 'Gci after team member split amount',
                        type: TYPE_CURRENCY
                    }
                },
                coSplit: {
                    value: {
                        label: 'Commission split amount',
                        type: TYPE_CURRENCY
                    },
                    percentage: {
                        label: 'Referral fee percentage',
                        type: TYPE_PERCENTAGE
                    }
                },
                taxes: {
                    value: {
                        label: 'Taxes amount',
                        type: TYPE_CURRENCY
                    },
                    percentage: {
                        label: 'Taxes percentage',
                        type: TYPE_PERCENTAGE
                    }
                },
                bizExp: {
                    value: {
                        label: 'BizExp amount',
                        type: TYPE_CURRENCY
                    },
                    percentage: {
                        label: 'BizExp percentage',
                        type: TYPE_PERCENTAGE
                    }
                },
                net: {
                    value: {
                        label: 'Net amount',
                        type: TYPE_CURRENCY
                    }
                },
                referralFee: {
                    value: {
                        label: 'Referral fee value',
                        type: TYPE_CURRENCY
                    },
                    percentage: {
                        label: 'Referral fee percentage',
                        type: TYPE_PERCENTAGE
                    }
                },
                teamMemberSplit: {
                    value: {
                        label: 'Team Member Split',
                        type: TYPE_CURRENCY
                    },
                    percentage: {
                        label: 'Team Member Split percentage sum',
                        type: TYPE_PERCENTAGE
                    }
                },
                royalty: {
                    value: {
                        label: 'Royalty',
                        type: TYPE_CURRENCY
                    },
                    percentage: {
                        label: 'Royalty percentage',
                        type: TYPE_PERCENTAGE
                    }
                },
                incomes: {
                    value: {
                        label: 'Income value',
                        type: TYPE_NUMBER
                    }
                },
                outcomes: {
                    value: {
                        label: 'Expenses value',
                        type: TYPE_NUMBER
                    }
                }
            },
            net: {
                value: {
                    label: 'Net amount',
                    type: TYPE_CURRENCY
                }
            },
            commission_value: {
                label: 'Commission value',
                type: TYPE_NUMBER
            },
            commission_type: {
                label: 'Commission type',
                type: TYPE_STRING
            },
            referral_fee_value: {
                label: 'Referral fee value',
                type: TYPE_NUMBER
            },
            referral_fee_type: {
                label: 'Referral fee type',
                type: TYPE_STRING
            },

            incomes: {
                label: 'Incomes',
                type: TYPE_CALLBACK,
                callback: (list: ListItem[]) => {
                    if (!list) {
                        return '';
                    }

                    return list.map(
                        (incomeObj) =>
                            `${incomeObj.name}: ${incomeObj.value} ${incomeObj.type === 'flat_fee' ? '$' : '%'}`
                    );
                }
            },
            outcomes: {
                label: 'Expenses',
                type: TYPE_CALLBACK,
                callback: (list: ListItem[]) => {
                    if (!list) {
                        return '';
                    }

                    return list.map(
                        (incomeObj) =>
                            `${incomeObj.name}: ${incomeObj.value} ${incomeObj.type === 'flat_fee' ? '$' : '%'}`
                    );
                }
            }
        },
        deal: {
            address: {
                label: 'Address',
                type: TYPE_STRING
            },
            city: {
                label: 'City',
                type: TYPE_STRING
            },
            state: {
                label: 'State',
                type: TYPE_STRING
            },
            type: {
                label: 'Type',
                type: TYPE_STRING
            },
            sales_price: {
                label: 'Sales price',
                type: TYPE_CURRENCY
            },
            status: {
                label: 'Deal status',
                type: TYPE_STRING
            },
            close_of_escrow: {
                label: 'Closing Date',
                type: TYPE_DATE
            },
            income_commission_value: {
                label: 'Commission',
                type: TYPE_NUMBER
            },
            income_commission_type: {
                label: 'Commission type',
                type: TYPE_STRING
            },
            referral_fee_value: {
                label: 'Referral Fee',
                type: TYPE_NUMBER
            },
            referral_fee_type: {
                label: 'Referral Fee type',
                type: TYPE_STRING
            },
            zip: {
                label: 'zip',
                type: TYPE_NUMBER
            },
            first_contacted: {
                label: 'First contacted',
                type: TYPE_DATE
            },
            last_contacted: {
                label: 'Last contacted',
                type: TYPE_DATE
            },
            clients: {
                label: 'Clients list',
                type: TYPE_CALLBACK,
                callback: (list: Client[]) => {
                    if (!list) {
                        return '';
                    }

                    const textList = list.map((client) => {
                        const res: string[] = [];

                        if (client.name) {
                            res.push(client.name);
                        }

                        if (client.email) {
                            res.push('<' + client.email + '>');
                        }
                        if (client.phone) {
                            res.push('(' + client.phone + ')');
                        }

                        return res.join(' ');
                    });

                    return textList.join(', ');
                }
            },
            dual_deal_id: {
                label: 'Dual agency',
                type: TYPE_BOOLEAN
            },
            image_url: {
                label: 'Image',
                type: TYPE_IMAGE
            },
            notes: {
                label: 'Notes',
                type: TYPE_CALLBACK,
                callback: (list: Note[]) => {
                    if (!list) {
                        return '';
                    }

                    const textList = list.map((note) => note.text);

                    return textList.join(', ');
                }
            },

            source_of_business: {
                label: 'Source of Business',
                type: TYPE_STRING
            }
        }
    };
}
