import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {InvoicePublicService, type PublicInvoice} from '@app/modules/finance/services/invoice-public.service';
import {firstValueFrom, Subject} from 'rxjs';
import type {IInvoice} from 'typings';

@Component({
    selector: 'app-statement-public-invoice[invoice]',
    templateUrl: './statement-public-invoice.component.html',
    styleUrls: ['./statement-public-invoice.component.scss']
})
export class StatementPublicInvoiceComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() invoice!: PublicInvoice;

    isExpanded = false;
    isLoadingInvoiceDetails = false;

    invoiceDetails: IInvoice | undefined;
    balance: number = 0;

    constructor(private invoicePublicService: InvoicePublicService) {}

    ngOnInit(): void {}

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    async onToggleExpand(e: Event) {
        e.preventDefault();
        e.stopPropagation();

        if (this.isExpanded || this.invoiceDetails) {
            this.isExpanded = !this.isExpanded;
            return;
        }

        if (this.invoiceDetails || this.isLoadingInvoiceDetails || !this.invoice.invoice_hash) return;

        this.isLoadingInvoiceDetails = true;
        this.invoiceDetails = await this.getInvoiceDetailsByHash(this.invoice.invoice_hash);
        this.isLoadingInvoiceDetails = false;
        this.isExpanded = true;
    }

    async getInvoiceDetailsByHash(invoiceHash: string) {
        const observable = this.invoicePublicService.getInvoiceByHash(invoiceHash);
        const invoiceDetails = await firstValueFrom(observable);
        return invoiceDetails;
    }
}
