import {Component, Inject, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {Subject, takeUntil} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import moment from 'moment-timezone';
import {cleanCurrencyString, currencyMaskitoOptions} from '@app/utilities/maskito';
import {LedgerAccountSelectorComponent} from '@app/modules/shared/components/ledger-account-selector/ledger-account-selector.component';
import {FormGroupArray} from '@app/typings/common';
import {requiredNumberValidator} from '@app/forms/validators';
import {BillsService} from '@app/services/api/finance/bills.service';
import {FinancialTransferService} from '@app/modules/finance/services/financial-transfer.service';
import {WaitingForPayoutsProcessingDialogComponent} from '@app/modules/deals/modules/common-deals-module/components/waiting-for-payouts-processing-dialog/waiting-for-payouts-processing-dialog.component';
import {PrintChecksDialogComponent} from '../../payments-made/print-checks-dialog/print-checks-dialog.component';
import {PaymentsMadeService} from '@app/modules/finance/services/payments-made.service';

export interface CheckData {
    payment_to: string;
    money_receiver__contact_fk_id: number;
    amount: number;
    reference: string;
    bill_pending_balance: number;
    bill_id: number;
}

export interface CreateChecksData {
    checks: number[];
    isCombineDisabled: boolean;
}

export interface IPaymentMadeToBeProcessed {
    check_payment_options: {
        paid_date: number;
        ledger_account_id: number;
    };
    check_data: CheckData[];
    after_action?: 'print' | null;
}

@Component({
    selector: 'print-checks-for-bills-dialog',
    templateUrl: 'print-checks-for-bills-dialog.html',
    styleUrls: ['./print-checks-for-bills-dialog.scss']
})
export class PrintChecksForBillsDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @ViewChild('ledgerAccountPicker', {static: true}) public ledgerAccountPicker:
        | LedgerAccountSelectorComponent
        | undefined;

    currencyMaskitoMask = currencyMaskitoOptions;
    isCombineFeatureDisabled: boolean = false;
    recordPaymentSettingsGroup: UntypedFormGroup;
    checkFormControls: FormGroupArray = this.fb.array([]) as FormGroupArray;
    itemsArrayBuilt: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<PrintChecksForBillsDialogComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: CreateChecksData,
        private fb: UntypedFormBuilder,
        private billsService: BillsService,
        private financialTransferService: FinancialTransferService,
        private paymentsMadeService: PaymentsMadeService
    ) {
        this.recordPaymentSettingsGroup = this.fb.group({
            payment_mode: ['check', [Validators.required]],
            paid_date: [Number(moment().format('YYYYMMDD')), [requiredNumberValidator]],
            ledger_account_id: [null, [Validators.required]],
            is_need_to_combine_payments: [true, []]
        });
    }

    async ngOnInit() {
        // set up the form controls
        if (this.data.isCombineDisabled) {
            this.isCombineFeatureDisabled = this.data.isCombineDisabled;
            this.recordPaymentSettingsGroup.controls.is_need_to_combine_payments.setValue(false);
        }

        await this.setupItemsArray();
    }

    async setupItemsArray() {
        const bills = await this.billsService.getBillsByIds(this.data.checks);
        const filteredBills = bills.filter((bill) => bill.pending_balance !== null && bill.pending_balance > 0);

        // Forcing bill_id! because it's a bill object and should not be null
        const billIds = filteredBills.map((bill) => bill.bill_id!);
        const financialTransfers = (await this.financialTransferService.getFinancialTransfersByBillIds(billIds)).result;

        filteredBills.forEach((bill) => {
            const ft = financialTransfers.find((transfer) => transfer.connected__bill_fk_id === bill.bill_id);
            const address = ft
                ? `${ft.deal_street_number} ${ft.deal_address} ${ft.deal_city}, ${ft.deal_state} ${ft.deal_zip}`
                : '';

            const group: UntypedFormGroup = this.fb.group({
                bill_id: bill.bill_id,
                money_receiver__contact_fk_id: bill.money_receiver__contact_fk_id,
                pay_to_name: bill.money_receiver_contact?.display_name,
                amount: bill.pending_balance,
                reference: address,
                bill_pending_balance: bill.pending_balance
            });

            this.checkFormControls.push(group);
        });

        this.itemsArrayBuilt = true;
    }

    validateAmount($event: any, payoutGroup: UntypedFormGroup) {
        if (!payoutGroup) {
            return null;
        }

        // Validate that check amount is not greater than the pending balance
        const inputValue = Number(cleanCurrencyString($event.target.value));
        const availableAmount = payoutGroup.controls.bill_pending_balance.value;

        if (availableAmount < inputValue || (inputValue === 0 && availableAmount !== 0)) {
            payoutGroup.controls.amount.setValidators([Validators.email]);
            payoutGroup.controls.amount.updateValueAndValidity();
            payoutGroup.controls.amount.markAsTouched();
        } else {
            payoutGroup.controls.amount.clearValidators();
            payoutGroup.controls.amount.updateValueAndValidity();
        }
    }

    async create(action: 'print' | null = null) {
        if (this.recordPaymentSettingsGroup.invalid || this.checkFormControls.invalid) {
            this.checkFormControls.markAllAsTouched();
            this.ledgerAccountPicker!.validateControl();
            this.recordPaymentSettingsGroup.markAllAsTouched();
            return;
        }

        const result: IPaymentMadeToBeProcessed = {
            check_payment_options: {
                paid_date: this.recordPaymentSettingsGroup.getRawValue().paid_date,
                ledger_account_id: this.recordPaymentSettingsGroup.getRawValue().ledger_account_id
            },
            check_data: this.checkFormControls.getRawValue().map((item: any) => {
                if (isNaN(item.amount)) {
                    item.amount = Number(cleanCurrencyString(item.amount));
                }
                return item;
            }),
            after_action: action
        };

        this.payBills(result);
        this.dialogRef.close();
    }

    async payBills(data: IPaymentMadeToBeProcessed) {
        // Show a loading screen
        const waitingDialogRef = this.dialog.open(WaitingForPayoutsProcessingDialogComponent, {
            minWidth: 507,
            maxHeight: '80vh',
            disableClose: true,
            panelClass: 'no-padding-dialog',
            autoFocus: false,
            data: {
                payoutIds: []
            }
        });

        const payBillsRequest = data.check_data.map((check) => {
            return {
                bill_id: check.bill_id,
                paymentInfo: {
                    paid_by__payment_method_fk_id: null,
                    pay_to__payment_method_fk_id: null,
                    paid_by__ledger_account_fk_id: data.check_payment_options.ledger_account_id,
                    pay_to__ledger_account_fk_id: null,
                    scheduled_date: null,
                    amount: Number(cleanCurrencyString(check.amount)),
                    note: '',
                    paid_date: data.check_payment_options.paid_date,
                    payment_mode: 'check',
                    sender_velocity: null,
                    restrict_downgrade: false,
                    check_number: null,
                    memo: '',
                    check_info: {
                        memo: check.reference,
                        check_number: null,
                        check_status: 'uncleared',
                        print_status: 'not_printed'
                    }
                }
            };
        });

        // @ts-ignore IPayRequestObject doesn't allow nulls, but should be okay because print check does it like this.
        const paymentsMade = await this.billsService.payBillsPromise(payBillsRequest);
        const paymentMadeIds = paymentsMade.map((p) => p.payment_made_id).filter((p): p is number => p !== undefined);
        // Get the payments made object that we can pass to PrintChecksDialogComponent
        const paymentsMadeForPrintCheck = await this.paymentsMadeService.getPaymentsMadeForPrintCheck(paymentMadeIds);

        // close loading screen when we're done creating payments made
        waitingDialogRef.close();

        // open print checks dialog
        if (paymentsMadeForPrintCheck && paymentsMadeForPrintCheck.length && data.after_action === 'print') {
            const dialogRef = this.dialog.open(PrintChecksDialogComponent, {
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                maxWidth: '100%',
                panelClass: 'no-padding-dialog',
                data: {paymentMadeIds: [], paymentMadeItems: paymentsMadeForPrintCheck}
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {});
        }
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
