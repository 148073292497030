import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {US_STATE_MAPPING_LIST} from '../../../../../../../local-typings';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {SessionService} from '../../../../../../../services/session.service';
import {IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {CompanyGatewayService} from '../../../../../../../services/api/finance/company-gateway.service';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
    selector: 'app-zipi-financial-retry-submit-representative-dialog',
    templateUrl: './zipi-financial-retry-submit-representative-dialog.component.html',
    styleUrls: ['./zipi-financial-retry-submit-representative-dialog.component.scss']
})
export class ZipiFinancialRetrySubmitRepresentativeDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    startDate = new Date(1990, 0, 1);

    currentPaymentGateway: IPaymentGateway | undefined;

    zipiFinancialRepresentative: any;
    errorFields: Array<string> = [];
    retryRepresentativeForm: UntypedFormGroup | undefined;

    stateProvinceRegionList = US_STATE_MAPPING_LIST;
    certify: boolean = false;
    lockButton: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ZipiFinancialRetrySubmitRepresentativeDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {representativeInfo: {[key: string]: any}; hideCancel?: boolean; gateway: IPaymentGateway},
        private fb: UntypedFormBuilder,
        public sessionService: SessionService,
        private companyGatewayService: CompanyGatewayService
    ) {}

    async ngOnInit() {
        this.currentPaymentGateway = this.data.gateway;
        this.zipiFinancialRepresentative = this.data.representativeInfo;
        this.errorFields = this.zipiFinancialRepresentative.errors || [];

        this.retryRepresentativeForm = this.fb.group({
            first_name: [this.zipiFinancialRepresentative.name.firstName, [Validators.required]],
            last_name: [this.zipiFinancialRepresentative.name.lastName, [Validators.required]],
            date_of_birth: [null, [Validators.required]],
            ssn: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
            email: [
                this.zipiFinancialRepresentative.email,
                [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)]
            ],
            phone_number: [null, [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
            address_line_1: [
                this.zipiFinancialRepresentative.address.addressLine1,
                [Validators.required, Validators.maxLength(32)]
            ],
            address_line_2: [this.zipiFinancialRepresentative.address.addressLine2, [Validators.maxLength(32)]],
            city: [this.zipiFinancialRepresentative.address.city, [Validators.required]],
            state: [this.zipiFinancialRepresentative.address.stateOrProvince, [Validators.required]],
            postalCode: [this.zipiFinancialRepresentative.address.postalCode, [Validators.required]],
            country: [this.zipiFinancialRepresentative.address.country, [Validators.required]],

            is_owner: [this.zipiFinancialRepresentative.responsibilities.isOwner, []],
            is_controller: [this.zipiFinancialRepresentative.responsibilities.isController, []],
            ownership_percentage: [
                this.zipiFinancialRepresentative.responsibilities.ownershipPercentage
                    ? this.zipiFinancialRepresentative.responsibilities.ownershipPercentage
                    : 0,
                [Validators.required, Validators.min(25), Validators.max(100)]
            ],
            job_title: [this.zipiFinancialRepresentative.responsibilities.jobTitle, [Validators.required]]
        });
        this.retryRepresentativeForm.controls.postalCode = this._addZipMaskListener(
            this.retryRepresentativeForm.controls.postalCode
        );
        this.retryRepresentativeForm.controls.phone_number = this._addPhoneMaskListener(
            this.retryRepresentativeForm.controls.phone_number
        );
        this.retryRepresentativeForm.controls.ssn = this._addSsnMaskListener(this.retryRepresentativeForm.controls.ssn);
        this.retryRepresentativeForm.controls.phone_number.setValue(this.zipiFinancialRepresentative.phone.number);

        this.retryRepresentativeForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (this.lockButton) {
                this.lockButton = false;
            }
        });
    }

    isOwnerChange($event: MatCheckboxChange) {
        if ($event.checked) {
            this.retryRepresentativeForm?.controls.ownership_percentage.setValidators([
                Validators.required,
                Validators.min(25),
                Validators.max(100)
            ]);
        } else {
            this.retryRepresentativeForm?.controls.ownership_percentage.setValidators([
                Validators.min(0),
                Validators.max(100)
            ]);
        }

        this.retryRepresentativeForm?.controls.ownership_percentage.updateValueAndValidity();
    }

    continue() {
        if (this.retryRepresentativeForm?.invalid) {
            this.retryRepresentativeForm.markAllAsTouched();
            return;
        }
        this.lockButton = true;
        const newRepresentativeData = this.retryRepresentativeForm?.getRawValue();

        if (!newRepresentativeData) {
            return;
        }

        newRepresentativeData['representativeID'] = this.zipiFinancialRepresentative.representativeID;

        this.companyGatewayService
            .doRetryForZipiFinancialRepresentative(
                this.currentPaymentGateway?.payment_gateway_id!,
                newRepresentativeData
            )
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((gateway) => {
                this.lockButton = false;
                this.dialogRef.close(true);
            });
    }

    _addZipMaskListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            const charArr = changes.split('');
            const digitsArr: Array<string> = [];
            charArr.forEach((char: string, index: number) => {
                if (index <= 4) {
                    if (!isNaN(+char) && char !== ' ' && char !== null) {
                        digitsArr.push(char);
                    }
                }
            });
            control.setValue(digitsArr.join(''), {emitEvent: false});
        });
        return control;
    }

    _addSsnMaskListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            const charArr = changes.split('');
            const digitsArr: Array<string> = [];
            charArr.forEach((char: string, index: number) => {
                if (index <= 3) {
                    if (!isNaN(+char) && char !== ' ' && char !== null) {
                        digitsArr.push(char);
                    }
                }
            });
            control.setValue(digitsArr.join(''), {emitEvent: false});
        });
        return control;
    }

    _addPhoneMaskListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (typeof changes === 'string') {
                const charArr = changes.split('');
                const digitsArr: Array<string> = [];
                const newCharArr: Array<string> = [];
                charArr.forEach((char: string, index: number) => {
                    if (!isNaN(+char) && char !== ' ' && char !== null) {
                        digitsArr.push(char);
                    }
                });
                digitsArr.forEach((digit, index) => {
                    if (index <= 9) {
                        if ([3, 6].includes(index)) {
                            newCharArr.push('-');
                            newCharArr.push(digit);
                        } else {
                            newCharArr.push(digit);
                        }
                    }
                });

                const actualStr = newCharArr.join('');
                control.setValue(actualStr, {emitEvent: false});
            } else {
                control.setValue('', {emitEvent: false});
            }
        });

        return control;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
