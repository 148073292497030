import {Routes, RouterModule} from '@angular/router';
import {GuestLayoutComponent} from '../../layouts/guest-layout.component';
import {AcceptInviteComponent} from './components/accept-invite/accept-invite.component';
import {PublicBootstrapService} from '../../services/resolvers/public-bootstrap.service';

export const routes: Routes = [
    {
        path: 'contact-invites',
        component: GuestLayoutComponent,
        resolve: {
            guestBootstrap: PublicBootstrapService
        },
        children: [
            {
                path: ':contact_invite_hash/accept',
                component: AcceptInviteComponent
            }
        ]
    }
];

export const routing = RouterModule.forChild(routes);
