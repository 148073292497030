import {Component, Inject, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {of, Subject} from 'rxjs';
import {IBill, IContact, IPaymentMade, IVendorCredit} from '@cyberco-nodejs/zipi-typings';
import {MatTableDataSource} from '@angular/material/table';
import {catchError, takeUntil} from 'rxjs/operators';
import {BillsService} from 'app/services/api/finance/bills.service';
import {VendorCreditsService} from 'app/services/api/finance/vendor-credits.service';
import {PaymentsMadeService} from '../../../../finance/services/payments-made.service';
import {Router} from '@angular/router';
import {FormGroupArray} from '../../../../../typings/common';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';

@Component({
    selector: 'app-apply-credit-to-bills-dialog',
    templateUrl: 'apply-credit-to-bills-dialog.component.html',
    styleUrls: ['apply-credit-to-bills-dialog.component.scss']
})
export class ApplyCreditToBillsDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    displayedColumns = ['bill_number', 'invoice_date', 'total_amount', 'pending_balance', 'amount_to_credit'];

    paymentMade: IPaymentMade | undefined;
    vendorCredit: IVendorCredit | undefined;
    contact: IContact | undefined;

    billsArray: FormGroupArray = this.fb.array([]) as FormGroupArray;
    formGroup: UntypedFormGroup = this.fb.group({bills: this.billsArray});
    dataSource: MatTableDataSource<IBill>;

    balance: number = 0;
    amountToCredit: number = 0;
    remainingCredits: number = 0;
    currencyMaskitoMask = currencyMaskitoOptions;

    saveDisabled: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ApplyCreditToBillsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder,
        private changeDetector: ChangeDetectorRef,
        private paymentsMadeService: PaymentsMadeService,
        private vendorCreditsService: VendorCreditsService,
        private billsService: BillsService,
        protected router: Router
    ) {
        this.dataSource = new MatTableDataSource<IBill>([]);
    }

    ngOnInit() {
        this.contact = this.data.contact;

        if (this.data.credit.payment_made_id) {
            this.paymentMade = this.data.credit;
            this.balance = this.data.credit.over_payment;
        }
        if (this.data.credit.vendor_credit_id) {
            this.vendorCredit = this.data.credit;
            this.balance = this.data.credit.balance;
        }
        this.remainingCredits = this.balance;

        if (this.contact && this.contact.contact_id) {
            this.billsService
                .getBillsForCreditsApply(this.contact.contact_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((bills) => {
                    for (const bill of bills as Array<IBill & {amount_to_credit: number}>) {
                        const billControl = this.fb.group({
                            bill_id: [],
                            bill_number: [],
                            invoice_date: [],
                            total_amount: [],
                            amount_to_credit: [],
                            pending_balance: []
                        });
                        const pendingBalance = bill.pending_balance;

                        if (pendingBalance && this.remainingCredits >= pendingBalance) {
                            bill['amount_to_credit'] = pendingBalance;
                            const remaining = this.remainingCredits - pendingBalance;
                            this.remainingCredits = Number(remaining.toFixed(2));
                        } else {
                            bill['amount_to_credit'] = this.remainingCredits;
                            this.remainingCredits = 0;
                        }

                        bill['pending_balance'] = pendingBalance;

                        billControl.patchValue(bill);
                        this.billsArray.push(billControl);
                    }

                    this.dataSource.data = bills;

                    this.calculateTotals();
                });
        }
    }

    onBlurBill(index: number) {
        if (this.billsArray) {
            const billGroup: UntypedFormGroup = this.billsArray.at(index) as unknown as UntypedFormGroup;
            const amountFromBill = billGroup.controls.amount_to_credit.value;
            const pendingBalance = billGroup.controls.pending_balance.value;
            const unmaskAmount = Number(cleanCurrencyString(amountFromBill));

            const bills = this.billsArray.getRawValue();
            const amountToCredit = bills.reduce(
                (sum, item) => Number(cleanCurrencyString(item.amount_to_credit)) + sum,
                0
            );
            const realRemaining = Number((this.balance - amountToCredit + unmaskAmount).toFixed(2));

            if (unmaskAmount > realRemaining || unmaskAmount > pendingBalance) {
                const newAmount = realRemaining > pendingBalance ? pendingBalance : realRemaining;
                billGroup.controls.amount_to_credit.setValue(newAmount);
            }

            this.calculateTotals();
        }
    }

    calculateTotals() {
        const billsNew = this.billsArray.getRawValue();
        const amountToCreditNew = billsNew.reduce(
            (sum, item) => Number(cleanCurrencyString(item.amount_to_credit)) + sum,
            0
        );

        this.amountToCredit = amountToCreditNew;
        this.remainingCredits = Number((this.balance - amountToCreditNew).toFixed(2));
    }

    routeToBill(element: any) {
        this.dialogRef.close();
        this.router.navigate(['purchases/bills', element.bill_id]);
    }

    save() {
        const bills = this.billsArray.getRawValue();
        const filteredBills: Array<{bill_id: number; amount_to_credit: number}> = [];
        for (const bill of bills) {
            const unmaskAmount = Number(cleanCurrencyString(bill.amount_to_credit));

            if (unmaskAmount !== 0) {
                filteredBills.push({
                    bill_id: bill.bill_id,
                    amount_to_credit: unmaskAmount
                });
            }
        }

        if (this.vendorCredit && this.vendorCredit.vendor_credit_id) {
            this.saveDisabled = true;

            this.vendorCreditsService
                .applyToBills(this.vendorCredit.vendor_credit_id, filteredBills)
                .pipe(
                    catchError(() => {
                        this.saveDisabled = false;
                        return of(null);
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((data) => {
                    this.dialogRef.close(true);
                    this.saveDisabled = false;
                });
        }

        if (this.paymentMade && this.paymentMade.payment_made_id) {
            this.saveDisabled = true;

            this.paymentsMadeService
                .applyToBills(this.paymentMade.payment_made_id, filteredBills)
                .pipe(
                    catchError(() => {
                        this.saveDisabled = false;
                        return of(null);
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((data) => {
                    this.dialogRef.close(true);
                    this.saveDisabled = false;
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
