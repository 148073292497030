<mat-card class="widget-container mat-card" *ngIf="customizeVisibility">
    <div class="widget-header"><mat-card-title>Add Widget</mat-card-title></div>

    <mat-card-content class="mat-card-content add-button-content" *ngIf="addWidgetMode === 'button'">
        <button mat-icon-button class="add-button" (click)="changeMode()">
            <mat-icon class="mat-icon material-icons add-button-icon" role="img" aria-hidden="true"
                >add_circle</mat-icon
            >
        </button>
    </mat-card-content>

    <mat-card-content class="mat-card-content add-form-content" *ngIf="addWidgetMode === 'form'">
        <mat-form-field>
            <mat-select placeholder="Widget type" [(ngModel)]="model.type" name="type">
                <mat-option [value]="'avg-commission'"> Average Commission </mat-option>
                <mat-option [value]="'avg-price'"> Average Price </mat-option>
                <mat-option [value]="'sob'"> Source </mat-option>
                <mat-option [value]="'production'"> Production </mat-option>
                <mat-option [value]="'income'"> Income </mat-option>
                <mat-option [value]="'status'"> Deals by Status </mat-option>
                <mat-option [value]="'cap_commission_structure'"> CAP - Commission Structure </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="action-buttons">
            <button
                mat-raised-button
                class="mat-primary add-button-form"
                (click)="addPseudoWidget()"
                [disabled]="!model.type"
            >
                Add
            </button>
            <button mat-raised-button class="add-button-form" (click)="changeMode()">Cancel</button>
        </div>
    </mat-card-content>
</mat-card>
