import {Action} from '@ngrx/store';
import {IReport, IReportCategory} from '@cyberco-nodejs/zipi-typings';
import {IReportsPreviewData} from './reports.reducer';

export enum ReportsActionType {
    FETCH_REPORT_LIST = '[REPORT API] FETCH_REPORT_LIST',
    FETCH_REPORT_LIST_SUCCESS = '[REPORT API] FETCH_REPORT_LIST_SUCCESS',
    FETCH_RECOMMENDED_REPORTS_PRESETS_LIST = '[REPORT API] FETCH_RECOMMENDED_REPORTS_PRESETS_LIST',
    FETCH_RECOMMENDED_REPORTS_PRESETS_LIST_SUCCESS = '[REPORT API] FETCH_RECOMMENDED_REPORTS_PRESETS_LIST_SUCCESS',
    CREATE_REPORT = '[REPORT API] CREATE_REPORT',
    FETCH_REPORTS_CATEGORIES_LIST = '[REPORT API] FETCH_REPORTS_CATEGORIES_LIST',
    FETCH_REPORTS_CATEGORIES_LIST_SUCCESS = '[REPORT API] FETCH_REPORTS_CATEGORIES_LIST_SUCCESS',
    UPDATE_REPORT = '[REPORT API] UPDATE_REPORT',
    DELETE_REPORT = '[REPORT API] DELETE_REPORT',
    CREATE_REPORTS_CATEGORY = '[REPORT API] CREATE_REPORTS_CATEGORY',
    DELETE_REPORTS_CATEGORY = '[REPORT API] DELETE_REPORTS_CATEGORY',
    TOGGLE_EDIT_REPORT_SIDEBAR_STATUS = '[REPORT API] TOGGLE_EDIT_REPORT_SIDEBAR_STATUS',
    FETCH_REPORT_PREVIEW_DATA = '[REPORT API] FETCH_REPORT_PREVIEW_DATA',
    FETCH_REPORT_PREVIEW_DATA_SUCCESS = '[REPORT API] FETCH_REPORT_PREVIEW_DATA_SUCCESS',
    CLEAR_REPORT_PREVIEW_DATA = '[REPORT API] CLEAR_REPORT_PREVIEW_DATA'
}
export class FetchReportList implements Action {
    readonly type: string = ReportsActionType.FETCH_REPORT_LIST;

    constructor(public payload?: {query?: {}; isAppend?: boolean}) {}
}

export class FetchReportListSuccess implements Action {
    readonly type: string = ReportsActionType.FETCH_REPORT_LIST_SUCCESS;

    constructor(public payload?: {data: IReport[]; isAppend: boolean; isLoaded: boolean}) {}
}

export class FetchRecommendedReportsPresetsList implements Action {
    readonly type: string = ReportsActionType.FETCH_RECOMMENDED_REPORTS_PRESETS_LIST;
    constructor(public payload?: any) {}
}

export class FetchRecommendedReportsPresetsListSuccess implements Action {
    readonly type: string = ReportsActionType.FETCH_RECOMMENDED_REPORTS_PRESETS_LIST_SUCCESS;

    constructor(public payload?: IReport[]) {}
}

export class CreateReport implements Action {
    readonly type: string = ReportsActionType.CREATE_REPORT;

    constructor(public payload?: object) {}
}

export class FetchReportsCategoriesList implements Action {
    readonly type: string = ReportsActionType.FETCH_REPORTS_CATEGORIES_LIST;
    constructor(public payload?: any) {}
}

export class FetchReportsCategoriesListSuccess implements Action {
    readonly type: string = ReportsActionType.FETCH_REPORTS_CATEGORIES_LIST_SUCCESS;

    constructor(public payload?: IReportCategory[]) {}
}

export class UpdateReport implements Action {
    readonly type: string = ReportsActionType.UPDATE_REPORT;

    constructor(public payload?: object | any) {}
}

export class DeleteReport implements Action {
    readonly type: string = ReportsActionType.DELETE_REPORT;

    constructor(public payload?: object | any) {}
}

export class CreateReportsCategory implements Action {
    readonly type: string = ReportsActionType.CREATE_REPORTS_CATEGORY;

    constructor(public payload?: IReportCategory) {}
}

export class DeleteReportsCategory implements Action {
    readonly type: string = ReportsActionType.DELETE_REPORTS_CATEGORY;

    constructor(public payload?: number) {}
}

export class ToggleEditReportSidebarStatus implements Action {
    readonly type: string = ReportsActionType.TOGGLE_EDIT_REPORT_SIDEBAR_STATUS;

    constructor(public payload?: {isOpened?: boolean; editId?: number | null; isEditMode?: boolean}) {}
}

export class FetchReportsPreviewData implements Action {
    readonly type: string = ReportsActionType.FETCH_REPORT_PREVIEW_DATA;

    constructor(public payload?: {id?: number; page?: number; perPage?: number}) {}
}

export class ClearReportsPreviewData implements Action {
    readonly type: string = ReportsActionType.CLEAR_REPORT_PREVIEW_DATA;
    constructor(public payload?: any) {}
}

export class FetchReportsPreviewDataSuccess implements Action {
    readonly type: string = ReportsActionType.FETCH_REPORT_PREVIEW_DATA_SUCCESS;

    constructor(public payload?: IReportsPreviewData) {}
}

export type ReportsAction =
    | FetchReportList
    | FetchReportListSuccess
    | FetchRecommendedReportsPresetsList
    | FetchRecommendedReportsPresetsListSuccess
    | CreateReport
    | FetchReportsCategoriesList
    | FetchReportsCategoriesListSuccess
    | UpdateReport
    | DeleteReport
    | CreateReportsCategory
    | DeleteReportsCategory
    | ToggleEditReportSidebarStatus
    | FetchReportsPreviewData
    | FetchReportsPreviewDataSuccess
    | ClearReportsPreviewData;
