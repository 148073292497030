import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-update-contact-confirm',
    templateUrl: './update-contact-confirm.component.html',
    styleUrls: ['./update-contact-confirm.component.scss']
})
export class UpdateContactConfirmComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<UpdateContactConfirmComponent>) {}

    ngOnInit() {}

    continueWithContactUpdate() {
        this.dialogRef.close({needContactUpdate: true});
    }

    continueWithoutContactUpdate() {
        this.dialogRef.close({needContactUpdate: false});
    }
}
