import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {GoalFormGroup, GoalFormModel} from './model';
import {GenericFormGroup} from '../../../entites/generic.entity';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PickableGoalFormGroup} from '../../progress/user-goals/user-goals.component';

@Component({
    selector: 'app-goal-progress',
    styles: [
        `
            mat-card-header {
                display: block;
                text-align: center;
                font-size: 20px;
                margin: 5%;
                color: #676161;
            }

            button {
                padding-left: 40px;
                padding-right: 40px;
                margin: 20px auto;
            }

            .prefix {
                font-size: 12px;
                margin-right: 4px;
                font-weight: 300;
            }

            .enum-row {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }

            button {
                height: 40px;
                margin: 4px;
            }

            mat-form-field {
                width: 100%;
            }
        `
    ],
    template: `
        <div class="enum-row" id="width-fix">
            <mat-form-field *ngIf="goal && goal.controls.dynamic">
                <span matPrefix class="prefix" *ngIf="index">#{{ index + 1 }}</span>
                <mat-select [formControl]="$any(goal.controls.dynamic)">
                    <mat-option [value]="'increase'">Increase</mat-option>
                    <mat-option [value]="'decrease'">Decrease</mat-option>
                    <mat-option [value]="'attain'" *ngIf="goal.controls.dynamic.value === 'attain'">Attain</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="goal && goal.controls.period">
                <mat-select [formControl]="$any(goal.controls.period)" [placeholder]="'Time Period'">
                    <mat-option [value]="'day'">Daily</mat-option>
                    <mat-option [value]="'week'">Weekly</mat-option>
                    <mat-option [value]="'month'">Monthly</mat-option>
                    <mat-option [value]="'quarter'">Quarterly</mat-option>
                    <mat-option [value]="'year'">Annually</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="goal && goal.controls.title">
                <input
                    matInput
                    name="title"
                    [placeholder]="'Title'"
                    type="text"
                    [formControl]="$any(goal.controls.title)"
                />
            </mat-form-field>
            <mat-form-field *ngIf="goal && goal.controls.amount">
                <span matPrefix class="prefix">By</span>
                <input
                    matInput
                    name="amount"
                    [placeholder]="'Amount'"
                    type="text"
                    [formControl]="$any(goal.controls.amount)"
                />
            </mat-form-field>
            <mat-form-field
                *ngIf="goal && goal.controls.dynamic && goal.controls.units && goal.controls.dynamic.value !== 'attain'"
            >
                <mat-select [formControl]="$any(goal.controls.units)" [placeholder]="'Units'">
                    <mat-option [value]="'dollars'">$ Dollars</mat-option>
                    <mat-option [value]="'percents'">% Percents</mat-option>
                    <mat-option [value]="'units'">Units</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field
                *ngIf="
                    goal &&
                    goal.controls.dynamic &&
                    goal.controls.baseline_value &&
                    goal.controls.dynamic.value !== 'attain'
                "
            >
                <span matPrefix class="prefix">By</span>
                <input
                    matInput
                    name="amount"
                    [placeholder]="'Baseline Value'"
                    type="text"
                    [formControl]="$any(goal.controls.baseline_value)"
                />
            </mat-form-field>
            <mat-form-field *ngIf="goal && goal.controls.start_tracking_date">
                <input
                    matInput
                    [matDatepicker]="picker_start_tracking_date"
                    [formControl]="$any(goal.controls.start_tracking_date)"
                    [placeholder]="'Start Tracking Date'"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [disabled]="goal.controls.start_tracking_date.disabled"
                    [for]="picker_start_tracking_date"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker_start_tracking_date></mat-datepicker>
            </mat-form-field>
        </div>
    `
})
export class GoalProgressComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() goal: PickableGoalFormGroup | GoalFormGroup | GenericFormGroup<any> | undefined;
    @Input() index: number | null = null;
    @Output() deleteGoal = new EventEmitter();
    @Output() makeGeneralGoal = new EventEmitter();

    ngOnInit() {
        if (this.goal && this.goal.controls.dynamic) {
            this.goal.controls.dynamic.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((newValue) => {
                if (newValue === 'attain' && this.goal) {
                    this.goal.controls.dynamic?.disable({emitEvent: false});
                }
            });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.deleteGoal.complete();
        this.makeGeneralGoal.complete();
    }
}
