import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {NotificationsServiceZipi} from '../../notifications/notifications.service';
import {Profile} from '../../../models/profile';
import {Observable} from 'rxjs';
import {IContact} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class UsersAndLicencesApiService {
    private url = '/users-and-licences/';

    constructor(
        public requester: ServiceRequester,
        protected notificationsService: NotificationsServiceZipi
    ) {}

    createProfile(data: Profile): Promise<Profile> {
        return this.requester.makeMsCall(this.url + 'create', 'POST', data);
    }

    suspend(id: number): Promise<Profile> {
        return this.requester
            .makeMsCall(this.url + 'suspend/' + id, 'PUT')
            .then((item) => Object.assign(new Profile(), item));
    }

    reactivate(id: number): Promise<Profile> {
        return this.requester
            .makeMsCall(this.url + 'reactivate/' + id, 'PUT')
            .then((item) => Object.assign(new Profile(), item));
    }

    resendInvitation(profile: Profile): Promise<Profile> {
        return this.requester
            .makeMsCall(this.url + 'resend/' + profile.id, 'PUT')
            .then((item) => Object.assign(new Profile(), item));
    }

    revokeInvitation(profile: Profile) {
        return this.requester.makeMsCall(this.url + 'revoke-invitation/' + profile.id, 'PUT').then(() => {
            this.notificationsService.addInfo('Invitation revoked successfully');
        });
    }

    disconnectProfile(profileId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + 'disconnect/' + profileId, 'DELETE');
    }

    deleteProfile(id: number, mode: string = 'normal') {
        return this.requester.makeMsCall(this.url + 'delete/' + id, 'DELETE', {mode: mode});
    }

    getMyCompanyProfiles(
        data: {offset: number; limit: number},
        queryParams = ''
    ): Promise<{data: Profile[]; _meta: {limit: number; offset: number; total: number}}> {
        return this.requester.makeMsCall(this.url + 'in-my-company' + queryParams, 'POST', data).then((response) => {
            return {
                _meta: response._meta,
                data: response.data.map((item: Profile) => Object.assign(new Profile(), item))
            };
        });
    }

    update(id: number, data: Profile) {
        return this.requester.makeMsCall(this.url + 'save/' + id, 'PUT', data);
    }

    getUpdatedProfile(id: number) {
        return this.requester.makeMsCall(this.url + 'updated-profile/' + id, 'GET');
    }

    checkProfileEmail(data: {email: string; profileId: number}): Promise<{result: boolean}> {
        return this.requester.makeMsCall(this.url + 'check-if-email-in-use/', 'POST', data);
    }

    checkContactProfile(
        data: Profile
    ): Observable<{success: boolean; message: string; error_details: string; existing_contacts: IContact[]}> {
        return this.requester.makeMsCall$('/contacts/check-if-exist', 'POST', 'shipp', data);
    }

    checkContactEmail(data: {email: string; contact_id: number}): Observable<{result: boolean}> {
        return this.requester.makeMsCall$('/contacts/check-if-email-in-use', 'POST', 'shipp', data);
    }

    getProfileFilters() {
        return this.requester.makeMsCall(this.url + 'get-profile-filters', 'GET');
    }

    bulkSendInvite(sendData: {
        is_selected_all: boolean;
        profile_filters: any;
        profile_ids: number[];
        force: boolean;
    }): Promise<{message: string; success: boolean}> {
        return this.requester.makeMsCall(this.url + 'bulk-send-invite/', 'POST', sendData);
    }

    getContactsLite(): Observable<[IContact]> {
        return this.requester.makeMsCall$('/contacts/lite', 'GET', 'shipp');
    }
}
