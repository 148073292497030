import {filter, takeUntil} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Profile} from '../../../models/profile';
import {Group} from '../../../models/group';
import {CurrentProfileSource} from '../../../services/sources/current-profile.source';
import {GroupApi} from '../../../services/api/group.api';
import {GenericFormArray, GenericFormGroup} from '../../../entites/generic.entity';
import {GroupsSource} from '../../../services/sources/groups.source';
import {Subject} from 'rxjs';
import {NotificationsServiceZipi} from '../../notifications/notifications.service';
import {ShareDialogComponent} from '../../account-info/groups/common/share-dialog.component';
import {ChipNode} from '../../account-info/compensation/models/chip-node';
import {GroupsApi} from '../../account-info/groups/groups.service';
import {CombinedCompensationProfilePickerComponent} from '../../account-info/compensation/components/combined-compensation-profile-picker/combined-compensation-profile-picker.component';

@Component({
    selector: 'app-goals-manage',
    template: `
        <div class="p-4">
            <mat-card>
                <mat-card-content>
                    <app-company-compensation-combined-picker
                        #picker
                        [singleSelected]="true"
                        [title]="'Groups'"
                        [only_compensation_groups]="false"
                        [availableTypes]="['company_group']"
                        [nodesFA]="selectedGroups"
                    ></app-company-compensation-combined-picker>
                    <app-groups-goals
                        *ngIf="selectedGroups.length > 0"
                        #goals
                        [goalsFormGroup]="groupFG"
                    ></app-groups-goals>
                </mat-card-content>
                <mat-card-actions>
                    <div class="p-2 text-right">
                        <button mat-button class="mat-primary" (click)="cancel()">Cancel</button>
                        <button
                            mat-raised-button
                            class="mat-primary"
                            (click)="saveGroup()"
                            [disabled]="saveDisabled"
                            *ngIf="selectedGroups.length > 0"
                        >
                            Save
                        </button>
                    </div>
                </mat-card-actions>
            </mat-card>
        </div>
    `
})
export class GoalsManageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('picker', {static: true}) picker: CombinedCompensationProfilePickerComponent | undefined;

    @Input() groupFG: GenericFormGroup<Group> = new GenericFormGroup(new Group());
    @Input() selectedGroups: GenericFormArray<ChipNode> = new GenericFormArray([]);
    @Output() closeSidebar = new EventEmitter<void>();

    groupSnapshot: Group = new Group();
    groupsList: Array<Group> = [];

    public currentProfile: Profile | null = null;
    public isLoaded: Promise<any>;
    public saveDisabled: boolean = false;

    constructor(
        private groupApi: GroupApi,
        protected groupsService: GroupsApi,
        public dialog: MatDialog,
        protected currentProfileSource: CurrentProfileSource,
        private notificationsService: NotificationsServiceZipi,
        private groupSrc: GroupsSource
    ) {
        this.isLoaded = new Promise((resolve) => {
            this.currentProfileSource.changeProfileEvent
                .pipe(filter((profile) => profile.company !== null))
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((profile) => {
                    this.currentProfile = profile;
                    resolve(null);
                });
        });
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }

    saveGroup() {
        if (!this.saveDisabled) {
            this.saveDisabled = true;
            return Promise.resolve(this.groupsService.putGroupGoals(this.groupFG.getRawValue()))
                .then(() => {
                    const company_group: Group = this.groupFG.getRawValue();

                    company_group.enumerable = company_group.enumerable.filter(
                        (item) => item.period || item.amount || item.title || item.units
                    );

                    if (company_group && company_group.id) {
                        this.groupApi.updateSettings(company_group.id, company_group).then(() => {
                            if (this.currentProfile) {
                                this.groupSrc.load(this.currentProfile);
                            }
                        });
                    }

                    this.closeSidebar.emit();
                    this.saveDisabled = false;
                })
                .catch(() => {
                    this.saveDisabled = false;
                });
        }
    }

    sharePermissions(event: Event, company_group: Group) {
        event.stopPropagation();
        const dialogRef = this.dialog.open(ShareDialogComponent);
        dialogRef.componentInstance.sharePermissions = Object.assign({}, company_group.permissions);
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((permissions) => {
                if (!permissions || !company_group.id) {
                    // dialog was just closed, no need to save anything
                    return;
                }
                company_group.permissions = permissions;
                this.groupApi.updateSettings(company_group.id, company_group);
            });
    }

    cancel() {
        this.closeSidebar.emit();
        if (this.picker) {
            this.picker.doRemoveAllNodes();
        }
    }

    ngOnInit() {
        this.isLoaded.then(() => {
            if (
                this.groupFG &&
                this.groupFG.controls.id?.value &&
                this.currentProfile &&
                this.groupFG.controls.id.value === this.currentProfile.company?.company_group?.id
            ) {
                this.groupFG.controls.members?.disable();
            }
        });
        this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => (this.groupsList = list));
        this.groupSnapshot = this.groupFG.getRawValue();

        this.selectedGroups.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((chipNodes) => {
            if (chipNodes.length > 0) {
                const foundGroup = this.groupsList.find((group) => group.id === chipNodes[0].target_id);
                if (foundGroup) {
                    this.groupFG.patchValue(foundGroup);
                }
            } else {
                this.groupFG.patchValue(new Group());
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
