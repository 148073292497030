import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {CreditsService} from '../../../services/credits.service';
import {takeUntil} from 'rxjs/operators';
import {CREDITS_STATUS_COLOR, PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {IScrollData} from 'app/models/scroll-data';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ICreditNote} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-source-credit-notes',
    templateUrl: 'source-credit-notes-list.component.html',
    styleUrls: ['source-credit-notes-list.component.scss', '../../../../../../assets/infinite-scroll-table.scss']
})
export class SourceCreditNotesListComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeCredits: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator = new MatPaginator(
        new MatPaginatorIntl(),
        ChangeDetectorRef.prototype
    );

    statusColor = CREDITS_STATUS_COLOR;
    creditsMenuOpened: boolean = false;

    scrollData: IScrollData = {
        offset: 0,
        limit: 50,
        sort_column: 'credit_note_date',
        sort_direction: 'desc',
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<ICreditNote>;
    displayedColumns = [
        'credit_note_date',
        'customer_contact',
        'credit_note_number',
        'reference',
        'status',
        'total_amount',
        'balance'
    ];

    constructor(private creditsService: CreditsService) {
        this.dataSource = new MatTableDataSource<ICreditNote>([]);
    }

    ngOnInit() {
        this.getCustomerCredits();

        this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.scrollData.limit = data.pageSize;
            this.scrollData.offset = data.pageSize * data.pageIndex;

            this.getCustomerCredits();
        });
    }

    getCustomerCredits() {
        this.unsubscribeCredits.next();

        this.creditsService
            .getSourceCustomerCredits(this.scrollData)
            .pipe(takeUntil(this.unsubscribeCredits))
            .subscribe((result) => {
                this.dataSource.data = result.result;
                this.scrollData.total = result._meta.total;
            });
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        this.scrollData.offset = 0;
        this.paginator.pageIndex = 0;
        this.getCustomerCredits();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeCredits.next();
        this.unsubscribeCredits.complete();
    }
}
