import {Component, Input} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {SourceOfBusiness} from '../../../../../../models/source-of-business';
import {SubType} from '../../../../../../models/sub-type';
import {SubSource} from '../../../../../../models/sub-source';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Inject} from '@angular/core';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

@Component({
    selector: 'app-common-list-of-fields-dialog',
    templateUrl: 'common-list-of-fields-dialog.component.html',
    styleUrls: ['./common-list-of-fields-dialog.component.scss']
})
export class CommonListOfFieldsDialogComponent {
    list: Array<SourceOfBusiness | SubType | SubSource> = [];
    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    constructor(
        public dialogRef: MatDialogRef<CommonListOfFieldsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.list = this.data.list && Array.isArray(this.data.list) ? this.data.list.slice() : [];
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        // Add new value
        if (value) {
            let record;
            if (this.data.type === 'SourceOfBusiness') {
                record = new SourceOfBusiness();
            } else if (this.data.type === 'SubType') {
                record = new SubType();
            } else if (this.data.type === 'SubSource') {
                record = new SubSource();
            } else {
                return;
            }

            record.label = value;
            this.list.push(record);
        }

        // Clear the input value
        event.chipInput!.clear();
    }

    remove(label: string | null) {
        let itemIndexToRemove = null;

        this.list.forEach((obj, i) => {
            if (obj.label === label) {
                itemIndexToRemove = i;
            }
        });

        if (itemIndexToRemove === null) {
            return;
        }

        this.list.splice(itemIndexToRemove, 1);
    }

    save() {
        this.dialogRef.close(this.list);
    }
}
