import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ISubCompaniesState} from './subcompanies.reducer';

export const selectSubCompaniesFeature = createFeatureSelector<ISubCompaniesState>('subcompanies');

export const selecSubCompanies = createSelector(
    selectSubCompaniesFeature,
    (state: ISubCompaniesState) => state.companies
);

export const selectAllSubCompanies = createSelector(
    selectSubCompaniesFeature,
    (state: ISubCompaniesState) => state.companiesAll
);
