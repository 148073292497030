import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {listOfLayout} from '../../../../../constants';

@Component({
    selector: 'app-report-edit-bar-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnDestroy {
    @Output() valueChanges: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
    @Input() value: Array<string> = [];

    public list = listOfLayout;

    onChange(event: {value: Array<string>}) {
        this.value = event.value;
        this.valueChanges.emit(event.value);
    }

    ngOnDestroy() {
        this.valueChanges.complete();
    }
}
