import {Component, OnInit, Input, OnDestroy, ViewChild} from '@angular/core';
import {Deal} from 'app/models/deal';
import {DealService} from 'app/services/deal.service';
import {Subject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {takeUntil} from 'rxjs/operators';
import {IScrollData} from 'app/models/scroll-data';
import {PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-contact-deals',
    templateUrl: 'contact-deals.component.html',
    styleUrls: ['contact-deals.component.scss', '../../../../assets/infinite-scroll-table.scss']
})
export class ContactDealsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild(MatSort, {static: false}) sort: MatSort | null = null;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | null = null;

    @Input() contactId: number | null = null;

    DEAL = Deal;

    scrollData: IScrollData = {
        offset: 0,
        limit: 50,
        sort_column: 'status',
        sort_direction: 'asc',
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<Deal>;
    displayedColumns = ['deal_owner', 'address', 'sales_price', 'close_of_escrow', 'status'];

    constructor(public dealService: DealService) {
        this.dataSource = new MatTableDataSource<Deal>([]);
    }

    async ngOnInit() {
        this.dataSource.sort = this.sort;

        await this.getDeals();

        this.paginator!.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.scrollData.limit = data.pageSize;
            this.scrollData.offset = data.pageSize * data.pageIndex;

            this.getDeals();
        });
    }

    async getDeals() {
        if (this.contactId) {
            const result = await this.dealService.getDealsByContact(this.contactId, this.scrollData);

            this.dataSource.data = result.result;
            this.scrollData.total = result._meta.total;
        }
    }

    changeSort(sort: Sort) {
        if (this.sort) {
            if (this.scrollData.sort_column === this.sort.active) {
                // change direction
                this.scrollData.sort_direction = this.sort.direction;
            } else {
                // change column
                this.scrollData.sort_column = this.sort.active;
                // change direction
                this.scrollData.sort_direction = this.sort.direction;
            }
        }
        this.paginator!.pageIndex = 0;
        this.scrollData.offset = 0;
        this.getDeals();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
