import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-groups-share-dialog',
    styles: [
        `
            .row.footer {
                justify-content: space-between;
            }

            mat-list {
                max-height: 400px;
                width: 100%;
                overflow-x: hidden;
            }
        `
    ],
    template: `
        <div class="col" style="justify-content: space-between; flex-direction: column; display: flex">
            <h3>Sharing Options</h3>
            <br />
            <mat-slide-toggle [(ngModel)]="sharePermissions.goals_progress_tracking"
                >Goals Progress Tracking
            </mat-slide-toggle>
            <mat-slide-toggle [(ngModel)]="sharePermissions.deals_pipeline" [disabled]="true"
                >Deals Pipeline (coming soon)
            </mat-slide-toggle>
            <br />
            <div class="row footer">
                <button mat-raised-button class="mat-primary" (click)="save()">save</button>
                <button mat-raised-button class="mat-default" (click)="dialogRef.close()">cancel</button>
            </div>
        </div>
    `
})
export class ShareDialogComponent {
    public sharePermissions: {
        goals_progress_tracking: boolean;
        deals_pipeline: boolean;
    } = {
        goals_progress_tracking: false,
        deals_pipeline: false
    };

    constructor(public dialogRef: MatDialogRef<ShareDialogComponent>) {}

    save() {
        this.dialogRef.close(this.sharePermissions);
    }
}
