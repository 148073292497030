import {assign} from 'lodash-es';
import {FranchiseAction, franchiseActionTypes} from './franchise.actions';

export interface IFranchiseState {
    requestsFromMe: any[];
    requestsToMe: any[];
}

const initDealState: IFranchiseState = {
    requestsFromMe: [],
    requestsToMe: []
};

export function franchiseReducer(state: IFranchiseState = initDealState, action: FranchiseAction): IFranchiseState {
    switch (action.type) {
        case franchiseActionTypes.GET_FRANCHISE_REQUESTS_SUCCESS:
            return assign({}, state, {
                requestsFromMe: action.payload!.result.requestsFromMe,
                requestsToMe: action.payload!.result.requestsToMe
            });

        case franchiseActionTypes.SAVE_FRANCHISE_REQUEST_SUCCESS:
            return assign({}, state, {
                requestsFromMe: [...state.requestsFromMe, action.payload!.result]
            });

        case franchiseActionTypes.ACCEPT_OR_REJECT_FRANCHISE_REQUESTS_SUCCESS: {
            return assign({}, state, {
                requestsToMe: state.requestsToMe.map((req) => {
                    if (req.id === +action.payload!.result.franchise_request_id) {
                        return assign({}, req, action.payload!.result);
                    }
                    return req;
                })
            });
        }
        case franchiseActionTypes.CANCEL_FRANCHISE_REQUEST_SUCCESS: {
            const {reqId} = action.payload!.result;

            return assign({}, state, {
                requestsToMe: state.requestsToMe.filter((el) => el.id !== reqId),
                requestsFromMe: state.requestsFromMe.filter((el) => el.id !== reqId)
            });
        }

        case franchiseActionTypes.DISCONNECT_FRANCHISE_REQUEST_SUCCESS: {
            const {reqId} = action.payload!.result;
            return assign({}, state, {
                requestsToMe: state.requestsToMe.filter((el) => el.id !== reqId),
                requestsFromMe: state.requestsFromMe.filter((el) => el.id !== reqId)
            });
        }

        default:
            return state;
    }
}
