<div class="table-background" style="isolation: isolate">
    <table
        mat-table
        [dataSource]="dataSource"
        [ngClass]="{'d-none': dataSource.data.length === 0}"
        matSort
        matSortDisableClear
        (matSortChange)="changeSort($event)"
        class="w-100"
    >
        <!-- Checkbox -->
        <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
                <mat-checkbox
                    (change)="onCheckboxChange(element.contact_id, $event.checked)"
                    [checked]="selection.isSelected(element.contact_id)"
                    [disabled]="isCheckBoxDisabled(element.contact_id)"
                >
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="contact_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
            <td mat-cell *matCellDef="let element">
                <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 200px">
                    {{ element.contact_name }}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="prev_balance_due">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Previous Balance</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.prev_balance_due !== null && element.prev_invoice_count !== null">
                    <div>{{ element.prev_balance_due | currency: 'USD' : 'symbol' }}</div>
                    <div style="font-size: 0.8em">
                        {{ element.prev_invoice_count }}
                        {{ element.prev_invoice_count === 1 ? 'invoice' : 'invoices' }}
                    </div>
                </div>
                <div *ngIf="element.prev_balance_due === null || element.prev_invoice_count === null">
                    <div>No previous balances</div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="total_amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Amount</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.total_amount !== null && element.invoice_count !== null">
                    <div>{{ element.total_amount | currency: 'USD' : 'symbol' }}</div>
                    <div style="font-size: 0.8em">
                        {{ element.invoice_count }}
                        {{ element.invoice_count === 1 ? 'invoice' : 'invoices' }}
                    </div>
                </div>
                <div *ngIf="element.total_amount === null || element.invoice_count === null">
                    <div>No invoices</div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="payments_amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Received</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.payments_amount !== null && element.payment_received_count !== null">
                    <div>{{ element.payments_amount | currency: 'USD' : 'symbol' }}</div>
                    <div style="font-size: 0.8em">
                        {{ element.payment_received_count }}
                        {{ element.payment_received_count === 1 ? 'payment' : 'payments' }}
                    </div>
                </div>
                <div *ngIf="element.payments_amount === null || element.payment_received_count === null">
                    <div>No payments received</div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="credit_notes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Credit Notes</th>
            <td mat-cell *matCellDef="let element">
                <div
                    *ngIf="
                        element.credit_notes !== null &&
                        element.credit_notes_count !== null &&
                        element.credit_notes_count > 0
                    "
                >
                    <div>{{ element.credit_notes | currency: 'USD' : 'symbol' }}</div>
                    <div style="font-size: 0.8em">
                        {{ element.credit_notes_count }}
                        {{ element.credit_notes_count === 1 ? 'credit note' : 'credit notes' }}
                    </div>
                </div>
                <div *ngIf="element.credit_notes === null || element.credit_notes_count === null">
                    <div>No credit notes</div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="last_sent">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Sent</th>
            <td mat-cell *matCellDef="let element">
                {{ element.last_sent | date: 'longDate' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="balance_due">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="financial">Amount Due</th>
            <td mat-cell *matCellDef="let element" class="financial">
                <div *ngIf="element.balance_due !== null">
                    <div>{{ element.balance_due | currency: 'USD' : 'symbol' }}</div>
                </div>
                <div *ngIf="element.balance_due === null">
                    <div>$0.00</div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="table-grid"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="handleClickRow(row)"
            [routerLink]="row.id"
            class="table-grid interactive-row"
        ></tr>
    </table>

    <!------- No values ------->
    <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 text-center no-items">
        There are no invoices.
    </div>
</div>
