import {
    AfterViewChecked,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Profile} from 'app/models/profile';
import {UsersAndLicencesApiService} from './users-and-licences.api.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {first, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {IRole} from '@cyberco-nodejs/zipi-typings';
import {AngularFireStorage, BUCKET} from '@angular/fire/compat/storage';
import {environment} from '../../../../environments/environment';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {GroupApi} from '../../../services/api/group.api';
import {AddToGroupDialogComponent} from './add-to-group-dialog/add-to-group-dialog.component';
import {CompanyPermissionsSource} from 'app/services/sources/companyPermissions.source';
import {AvailableRolesSource} from 'app/services/sources/available-roles.source';
import {ProfileFiltersDialogComponent} from './profile-filters-dialog/profile-filters-dialog.component';
import {ConfirmComponent} from '../../../layouts/confirm/confirm.component';
import {NotificationsService} from 'angular2-notifications';

interface IProfileFilters {
    [key: string]: any;
    statuses: string[];
    roles: [];
    divisions: [];
    groups: [];
}

export function makeBucketName(env: any) {
    return [env.namespace, env.firebase.projectId, env.firestoreDataCSVUploadBucket].join('_');
}

@Component({
    selector: 'app-users-licenses',
    templateUrl: './users-licenses.component.html',
    styleUrls: ['./users-licenses.component.scss'],
    providers: [{provide: BUCKET, useValue: makeBucketName(environment)}, AngularFireStorage]
})
export class UsersLicensesComponent implements OnInit, OnDestroy, AfterViewChecked {
    private unsubscribe: Subject<void> = new Subject();

    users = [];
    profileIdsForDetails: number[] = [];
    fullProfileIdsForDetails: number[] = [];
    profiles: BehaviorSubject<Profile[]> = new BehaviorSubject<Profile[]>([]);

    currentProfile: Profile | undefined;
    newUser: Profile | undefined | null;

    EMIT_ACTION_CREATED = 'created';
    EMIT_ACTION_UPDATED = 'updated';

    allRoles:
        | {
              systemRoles: IRole[];
              companyRoles: IRole[];
          }
        | undefined;
    companyPermissions: Object | undefined;

    scrollData = {
        offset: 0,
        limit: 25
    };

    showMoreToTop: boolean | undefined; // need initial value 'undefined' because of code in method setScrollingData();
    showMoreToBottom: boolean = false;
    scrollToTop: boolean = false;

    scrolledToTopOffset: number = 0;
    numberOfProfilesAddToTop: number | undefined; // need initial value 'undefined' because of code in method setScrollingData();

    // it defines height of lazy loading list
    offsetTopToLazyLoading$: BehaviorSubject<number> | undefined;
    checkViewport$: Subject<any> = new Subject<any>();
    scrollToIndex$: Subject<number> = new Subject<number>();

    public isExistSubCompanies: boolean = false;

    @ViewChildren('userCard') userCards: any[] = [];
    profileIdsToAdd$ = new Subject<{[key: string]: number}>();
    profileIdsToAdd: number[] = [];

    profileFiltersData = [];
    profileFilters: IProfileFilters = {
        statuses: [],
        roles: [],
        divisions: [],
        groups: []
    };
    isLoading: boolean = false;
    isSelectedAll: boolean = false;
    profileFiltersDialogRef: MatDialogRef<any> | null = null;

    constructor(
        private usersAndLicencesApiService: UsersAndLicencesApiService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        protected dialog: MatDialog,
        protected currentProfileSource: CurrentProfileSource,
        private groupService: GroupApi,
        private companyPermissionsSource: CompanyPermissionsSource,
        private availableRolesSource: AvailableRolesSource,
        private ntfs: NotificationsService
    ) {}

    ngOnInit(): void {
        this.currentProfileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe((currProfile) => {
            this.currentProfile = currProfile;

            // refresh roles list on current profile change
            this.availableRolesSource.source.pipe(takeUntil(this.unsubscribe)).subscribe((roles) => {
                if (roles) {
                    this.allRoles = roles;
                } else {
                    // if no roles - clear previous roles list
                    this.allRoles = {systemRoles: [], companyRoles: []};
                }
            });
        });

        this.companyPermissionsSource.source.pipe(takeUntil(this.unsubscribe)).subscribe((permissions) => {
            if (permissions) {
                this.companyPermissions = permissions;
            }
        });

        this.handleSearch();

        this.profileIdsToAdd$.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            if (data.hasOwnProperty('add') && !this.profileIdsToAdd.includes(data.add)) {
                this.profileIdsToAdd.push(data.add);
            } else if (data.hasOwnProperty('remove')) {
                this.profileIdsToAdd.splice(this.profileIdsToAdd.indexOf(data.remove), 1);
            }
        });

        // load profiles filters
        this.usersAndLicencesApiService.getProfileFilters().then((response) => {
            this.profileFiltersData = response;
        });
    }

    handleBulkAction(action: string) {
        if (action === 'group') {
            const dialogRef = this.dialog.open(AddToGroupDialogComponent, {
                minWidth: 700,
                data: {
                    addToDivision: false
                }
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response) {
                        this.bulkAddProfilesToGroup(response);
                    }
                });
        } else if (action === 'division') {
            const dialogRef = this.dialog.open(AddToGroupDialogComponent, {
                minWidth: 700,
                data: {
                    addToDivision: true
                }
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response) {
                        this.bulkAddProfilesToGroup(response);
                    }
                });
        } else if (action === 'profile_filters') {
            if (!this.profileFiltersDialogRef) {
                this.profileFiltersDialogRef = this.dialog.open(ProfileFiltersDialogComponent, {
                    minWidth: 400,
                    data: {
                        profileFiltersData: this.profileFiltersData,
                        profileFilters: this.profileFilters
                    },
                    backdropClass: 'profile-filters-dialog-backdrop'
                });

                this.profileFiltersDialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((response) => {
                        if (response) {
                            this.profileFilters = response;
                            this.router.navigate(['/company/directory/users'], {
                                queryParams: this.normalizeFiltersValues(this.profileFilters)
                            });
                        }
                        this.profileFiltersDialogRef = null;
                    });
            }
        } else if (action === 'select_all') {
            this.profileIdsToAdd = [];
            this.profiles.getValue().forEach((profile) => {
                if (profile) {
                    this.profileIdsToAdd.push(Number(profile.profile_id));
                }
            });
            this.isSelectedAll = true;
        } else if (action === 'unselect_all') {
            this.profileIdsToAdd = [];
            this.isSelectedAll = false;
        } else if (action === 'send_invite') {
            const sendData = {
                is_selected_all: this.isSelectedAll,
                profile_filters: this.profileFilters,
                profile_ids: this.profileIdsToAdd.filter((id) => id),
                force: false
            };
            this.usersAndLicencesApiService.bulkSendInvite(sendData).then((response) => {
                if (!response.success) {
                    const confirmDialogRef = this.dialog.open(ConfirmComponent, {
                        minWidth: 320,
                        minHeight: 160,
                        data: {
                            title: `Confirm bulk action`,
                            message: response.message
                        }
                    });

                    confirmDialogRef
                        .afterClosed()
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((confirmed: boolean) => {
                            if (confirmed) {
                                sendData.force = true;
                                this.usersAndLicencesApiService.bulkSendInvite(sendData).then((result) => {
                                    if (result.success) {
                                        this.ntfs.success(result.message);
                                        this.reloadProfiles();
                                    } else {
                                        this.ntfs.error(result.message);
                                    }
                                });
                            } else {
                                this.reloadProfiles();
                            }
                        });
                }
            });
        }
    }

    reloadProfiles() {
        this.profiles.next([]);
        this.profileIdsToAdd = [];
        this.scrollData = {
            offset: 0,
            limit: 25
        };
        this.scrollToTop = false;
        this.showMoreToBottom = false;
        this.handleSearch();
    }

    bulkAddProfilesToGroup(groupId: number) {
        const sendData = {
            is_selected_all: this.isSelectedAll,
            profile_filters: this.profileFilters,
            profile_ids: this.profileIdsToAdd
        };
        if (!groupId) {
            return;
        }

        this.groupService
            .bulkAddProfilesToGroup(groupId, sendData)
            .pipe(first())
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                this.userCards
                    .map((card) => card.divCheckbox)
                    .forEach((checkbox) => {
                        checkbox.nativeElement.style.visibility = null;
                    });
                this.userCards
                    .map((card) => card.checkbox)
                    .forEach((checkbox) => {
                        checkbox.checked = false;
                    });
                this.reloadProfiles();
                this.ntfs.success(`Profiles added successfully.`);
            });
    }

    ngAfterViewChecked() {
        if (!this.offsetTopToLazyLoading$) {
            this.offsetTopToLazyLoading$ = new BehaviorSubject<number>(50);
        }
    }

    normalizeFiltersValues(profileFilters: any) {
        // normalize filters values
        const filtersToReturn: IProfileFilters = {
            statuses: [],
            roles: [],
            divisions: [],
            groups: []
        };
        ['statuses', 'roles', 'divisions', 'groups'].map((name) => {
            if (typeof profileFilters[name] === 'string' && profileFilters[name].length) {
                filtersToReturn[name] = [name === 'statuses' ? profileFilters[name] : Number(profileFilters[name])];
            } else if (Array.isArray(profileFilters[name]) && profileFilters[name].length) {
                filtersToReturn[name] = profileFilters[name].map((filter: any) =>
                    name === 'statuses' ? filter : Number(filter)
                );
            }
        });
        return filtersToReturn;
    }

    handleSearch() {
        this.isLoading = true;
        // update search data
        const contextProfileId = this.router.url.split('/')[4] ? Number(this.router.url.split('/')[4]) : '';

        if (Object.keys(this.activatedRoute.snapshot.queryParams).length !== 0) {
            this.profileFilters = this.normalizeFiltersValues(this.activatedRoute.snapshot.queryParams);
        }

        const initialScrollData = Object.assign(
            {},
            this.scrollData,
            {
                contextProfileId: contextProfileId
            },
            this.profileFilters
        );

        // load profiles
        this.usersAndLicencesApiService.getMyCompanyProfiles(initialScrollData).then((response) => {
            // set scrolledToTopOffset
            if (response._meta.offset !== 0) {
                this.scrolledToTopOffset = response._meta.offset;
            }

            response.data.forEach((profile) => {
                this.fullProfileIdsForDetails.push(Number(profile.id));
            });
            // add new profiles to the end of the list
            this.profiles.next(this.profiles.value.concat(response.data));

            // if context profile
            if (contextProfileId) {
                // scroll to context profile
                const selectedIndex = this.profiles
                    .getValue()
                    .findIndex((elem) => elem.profile_id === contextProfileId);
                if (selectedIndex > -1) {
                    setTimeout(() => {
                        this.scrollToIndex$.next(selectedIndex);
                    });
                }

                // close all and open context profile
                this.profileIdsForDetails = [];
                this.profileIdsForDetails.push(contextProfileId);
            }

            // set different scrolling values
            this.setScrollingData(response._meta, false);
            this.isLoading = false;
        });
    }

    nextBatch(renderedRange: MouseEvent) {
        if (this.showMoreToBottom) {
            this.doShowMoreBottom();
        }
    }

    doShowMoreBottom() {
        // to prevent several requests that are going one after another
        this.showMoreToBottom = false;
        // set scrolling direction
        this.scrollToTop = false;

        // upload profiles when user get the bottom of page
        this.usersAndLicencesApiService
            .getMyCompanyProfiles(Object.assign({}, this.scrollData, this.profileFilters))
            .then((response) => {
                this.handleUploadContacts(response.data, response._meta, false);
            });
    }

    async currentProfileChanged(newProfile: Profile) {
        // reload profiles list from init
        this.handleSearch();

        this.companyPermissionsSource.load();
        this.availableRolesSource.load();
    }

    createUser() {
        this.newUser = new Profile();
    }

    async updateUserList(event: {action: string; result: Profile}) {
        // find index in profile array, where we could insert created profile
        const profileIndexToInsert = this.profiles.value.findIndex(
            (profile) =>
                `${profile.first_name} ${profile.last_name}`.toLowerCase() >
                `${event.result.first_name} ${event.result.last_name}`.toLowerCase()
        );
        // add user to list, if name is higher than last profile from loaded list
        if (profileIndexToInsert >= 0) {
            this.profiles.value.splice(profileIndexToInsert, 0, event.result);
        } else {
            this.profiles.value.push(event.result);
        }
        this.profiles.next(this.profiles.value);
        ++this.scrollData.offset;

        this.newUser = null;
    }

    destroyEditUserComponent() {
        this.newUser = null;
    }

    changeAllDetails() {
        if (!this.profileIdsForDetails.length) {
            this.profileIdsForDetails = this.fullProfileIdsForDetails.slice(0);
        } else {
            this.profileIdsForDetails = [];
        }
    }

    availableProfilesHandler(availableProfiles: Profile[]) {
        this.isExistSubCompanies = availableProfiles.length > 1;
    }

    updateProfileAfterAction(profile: Profile) {
        const oldUserIndex = this.profiles.value.findIndex((e) => e.id === profile.id);
        Object.assign(this.profiles.value[oldUserIndex], profile);
    }

    updateAllRoles(roles: {systemRoles: IRole[]; companyRoles: IRole[]}) {
        this.allRoles = roles;
    }

    updateCompanyPermissions(permissions: any) {
        this.companyPermissions = permissions;
    }

    deleteProfileAfterAction(profileId: number) {
        const deletedProfileIndex = this.profiles.value.findIndex((profile) => profile.id === profileId);
        this.profiles.value.splice(deletedProfileIndex, 1);
        this.profiles.next(this.profiles.value);
        --this.scrollData.offset;
    }

    public doShowMoreTop(event: MouseEvent): void {
        // scroll to top and has available contacts
        if (!this.showMoreToTop) {
            return;
        }

        // set scrolling direction
        this.scrollToTop = true;
        // get offset for scrolling to top
        this.scrolledToTopOffset -= this.scrollData.limit * 2;

        // set if contacts reached the beginning of the list
        if (this.scrolledToTopOffset < 0) {
            this.numberOfProfilesAddToTop = this.scrolledToTopOffset + this.scrollData.limit;
            this.scrolledToTopOffset = 0;
            this.showMoreToTop = false;
        }

        // no need to upload smth
        if (this.numberOfProfilesAddToTop === 0) {
            return;
        }

        // load next batch of contacts
        this.usersAndLicencesApiService
            .getMyCompanyProfiles(
                Object.assign({}, this.scrollData, {offset: this.scrolledToTopOffset}, this.profileFilters)
            )
            .then((response) => {
                this.handleUploadContacts(response.data, response._meta, true);
            });
    }

    private async handleUploadContacts(
        contacts: Profile[],
        meta: {offset: number; limit: number; total: number},
        addToStart: boolean = false
    ) {
        contacts.forEach((profile) => {
            this.fullProfileIdsForDetails.push(Number(profile.id));
        });
        // add new profiles to the end of the list
        if (!addToStart) {
            this.profiles.next(this.profiles.value.concat(contacts));
        }

        // add new contacts to the beginning of the list
        if (addToStart) {
            // if get the top of the ALL contacts list
            if (this.scrolledToTopOffset === 0) {
                // get unique values
                this.profiles.next([...contacts.slice(0, this.numberOfProfilesAddToTop), ...this.profiles.getValue()]);

                // scroll to current position
                // numberOfContactsAddToTop can be negative
                setTimeout(() =>
                    this.scrollToIndex$.next(
                        this.numberOfProfilesAddToTop && this.numberOfProfilesAddToTop > 0
                            ? this.numberOfProfilesAddToTop + 1
                            : this.scrollData.limit + (this.numberOfProfilesAddToTop || 0) + 1
                    )
                );
            } else {
                this.profiles.next([...contacts, ...this.profiles.getValue()]);

                // scroll to current position
                setTimeout(() => this.scrollToIndex$.next(this.scrollData.limit + 1));
            }
        }

        // set different scrolling values
        this.setScrollingData(meta, addToStart);

        if (this.isSelectedAll) {
            this.profileIdsToAdd = [];
            this.profiles.getValue().forEach((profile) => {
                if (profile) {
                    this.profileIdsToAdd.push(Number(profile.profile_id));
                }
            });
        }
    }

    private setScrollingData(meta: {limit: number; offset: number; total: number}, addToStart: boolean): void {
        // check if contacts reached the end of the list
        if (!this.scrollToTop) {
            this.showMoreToBottom = Number(meta.offset) < Number(meta.total);
        }

        // set initial value showMoreToTop
        if (typeof this.showMoreToTop === 'undefined') {
            this.showMoreToTop = true;
        }

        // do not update offset if data was added to top
        if (!addToStart) {
            this.scrollData.offset = meta.offset;
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        if (this.offsetTopToLazyLoading$) {
            this.offsetTopToLazyLoading$.complete();
        }
        this.checkViewport$.complete();
        this.scrollToIndex$.complete();
        this.profiles.complete();
        this.profileIdsToAdd$.complete();
    }
}
