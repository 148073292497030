import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {UiModsSource} from '../ui-mods.source';
import {UiModsDealBadgeInListModel} from '../models/ui-mods.model';
import {UiModsApi} from '../api/ui-mods.api';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-ui-mod-deal-badges-in-list',
    styles: [
        `
            .ui-mod-deal-badge-container {
                padding: 3px 0;
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                align-items: flex-end;
            }
            .ui-mod-deal-badge-container--dir-row {
                flex-direction: row;
            }
        `
    ],
    template: `
        <div
            class="ui-mod-deal-badge-container"
            *ngIf="badges.length"
            [ngClass]="renderMode === 'horizontal' ? 'ui-mod-deal-badge-container--dir-row' : ''"
        >
            <ng-container *ngFor="let badge of badges">
                <app-ui-mod-deal-badge-in-list [badge]="badge" [dealId]="dealId"></app-ui-mod-deal-badge-in-list>
            </ng-container>
        </div>
    `
})
export class DealBadgesInListComponent implements OnDestroy, OnInit {
    private unsubscribe: Subject<void> = new Subject();
    @Input() renderMode: 'vertical' | 'horizontal' = 'horizontal';
    @Input() dealId: number | null = null;
    @Input() initialUiMods: UiModsDealBadgeInListModel[] = [];
    @Input() onlySpecificAddons: string[] = [];

    public badges: UiModsDealBadgeInListModel[] = [];

    constructor(
        public uiModsSource: UiModsSource,
        public uiModsApi: UiModsApi,
        public changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit() {
        if (this.initialUiMods && this.initialUiMods.length) {
            this.badges = this.initialUiMods
                .map(this.uiModsSource.mutateWithUiModCache)
                .filter((badge) => badge.uiMod && badge.uiMod.type === 'dealBadgeInList')
                .map((badge) => this.uiModsSource.buildUiModDealBadgeInList(badge, true));
        }

        this.uiModsSource.dealBadgesInList.pipe(takeUntil(this.unsubscribe)).subscribe((newBadges) => {
            const currentDealBadges = newBadges
                .filter((badge) => badge.deal_id === this.dealId)
                .filter(
                    (badge) =>
                        this.onlySpecificAddons.length === 0 ||
                        this.onlySpecificAddons.includes(badge.uiMod?.addon.slug)
                );
            for (let i = 0; i < currentDealBadges.length; i++) {
                const exisingBadgeIndex = this.badges.findIndex(
                    (b) => b && b.ui_mod_instance_id === currentDealBadges[i].ui_mod_instance_id
                );
                if (exisingBadgeIndex > -1) {
                    this.badges[exisingBadgeIndex] = currentDealBadges[i];
                } else {
                    this.badges.push(currentDealBadges[i]);
                }
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
