import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {GenericFormGroup} from '../../../../../../entites/generic.entity';
import {Deal} from '../../../../../../models/deal';
import {SessionService} from '../../../../../../services/session.service';
import {CommissionInstruction} from '../../../../../../models/commission-instruction';
import {DealService} from '../../../../../../services/deal.service';
import {getUserManager} from 'app/modules/auth/services/okta/user-manager';

@Component({
    selector: 'app-commission-instructions',
    template: `
        <div class="p-4">
            <h3>Commission Instructions</h3>

            <div>
                <div rbac [rule]="{deals__manage_communication: true}" [denyMethod]="'display-none'" *ngIf="editMode">
                    <mat-form-field
                        style="width:calc(100% - 70px);"
                        *ngIf="currentInstructionFG && currentInstructionFG.controls.text"
                    >
                        <textarea
                            matInput
                            placeholder="Commission instructions text"
                            [formControl]="currentInstructionFG.controls.text"
                        ></textarea>
                    </mat-form-field>

                    <div
                        class="icons"
                        *ngIf="
                            !isLoading &&
                            currentInstructionFG &&
                            currentInstructionFG.controls.text &&
                            currentInstructionFG.controls.text.value
                        "
                    >
                        <mat-icon class="success" (click)="saveNote()">done</mat-icon>
                        <mat-icon class="default" (click)="refreshCurrentNote()">clear</mat-icon>
                        <div class="icons-overlay" *ngIf="currentInstructionFG.controls.text.disabled"></div>
                    </div>
                </div>

                <ng-container *ngIf="!isLoading && instructions.length > 0">
                    <div *ngFor="let instruction of instructions; index as ni">
                        <app-commission-instruction [instructionFG]="instruction"></app-commission-instruction>
                    </div>
                </ng-container>

                <!------- No values ------->
                <div *ngIf="!isLoading && instructions.length === 0" class="text-center p-4 text-muted">
                    No commission instructions found
                </div>

                <div class="commission-instructions-load" *ngIf="isLoading">
                    <!------- Loading ------->
                    <div class="load-container" id="load-container">
                        <div class="loader">Loading...</div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./commission-instructions.component.scss']
})
export class CommissionInstructionsComponent implements OnInit {
    @Input() dealFormGroup: GenericFormGroup<Deal> = new GenericFormGroup(new Deal(), 'change');
    @Input() editMode: boolean = false;
    @Input() isDealConnectedToSkyslope: boolean = false;

    public currentInstructionFG: GenericFormGroup<CommissionInstruction> = new GenericFormGroup(
        new CommissionInstruction(),
        'change'
    );
    public instructions: GenericFormGroup<CommissionInstruction>[] = [];
    public isLoading = false;
    private dealId: null | number = null;

    constructor(
        protected sessionService: SessionService,
        protected dealService: DealService
    ) {}

    async ngOnInit() {
        this.currentInstructionFG.controls.profile!.patchValue(this.sessionService.profile!);
        this.dealId = this.dealFormGroup.getRawValue()?.id;

        if (this.dealId) {
            this.isLoading = true;
            this.currentInstructionFG.controls.text?.disable();
            const userManager = getUserManager();
            const oktaAuthState = await userManager.getAuthState();
            const oktaToken = oktaAuthState.accessToken?.accessToken;
            this.getCommissionInstructions(this.dealId, oktaToken);
        }
    }

    public async getCommissionInstructions(dealId: number, oktaToken?: string) {
        this.dealService
            .getCommissionInstructions(dealId, oktaToken!)
            .then((commissionInstructions) => {
                this.instructions = commissionInstructions.map((instruction) => {
                    const instructionFG = new GenericFormGroup(instruction, 'change');
                    instructionFG.controls?.profile?.patchValue(this.sessionService.profile!);
                    return instructionFG;
                });
                this.isLoading = false;
                this.currentInstructionFG.controls.text?.enable();
            })
            .catch((err) => {
                this.isLoading = false;
                this.currentInstructionFG.controls.text?.enable();
                throw err;
            });
    }

    public refreshCurrentNote() {
        this.currentInstructionFG = new GenericFormGroup(
            new CommissionInstruction(this.sessionService.profile!),
            'change'
        );
    }

    async saveNote() {
        this.dealId = this.dealFormGroup.getRawValue()?.id;
        if (this.dealId) {
            this.currentInstructionFG.controls.text?.disable();
            const userManager = getUserManager();
            const oktaAuthState = await userManager.getAuthState();
            const oktaToken = oktaAuthState.accessToken?.accessToken;
            return this.dealService
                .addCommissionInstruction(this.dealId, this.currentInstructionFG.getRawValue(), oktaToken!)
                .then(async (result) => {
                    if (this.isDealConnectedToSkyslope && oktaToken) {
                        // Need to refresh full list of instructions when deal source is from Skyslope
                        await this.getCommissionInstructions(this.dealId!, oktaToken);
                        this.refreshCurrentNote();
                        this.currentInstructionFG.controls.profile!.patchValue(this.sessionService.profile!);
                        this.currentInstructionFG.controls.text?.enable();
                    } else {
                        this.instructions.unshift(new GenericFormGroup(result, 'change'));
                        this.refreshCurrentNote();
                        this.currentInstructionFG.controls.profile!.patchValue(this.sessionService.profile!);
                        this.currentInstructionFG.controls.text?.enable();
                    }
                })
                .catch((err) => {
                    this.currentInstructionFG.controls.text?.enable();
                    throw err;
                });
        }
    }
}
