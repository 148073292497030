// import { SOMETHING_HAPPENED_ACTION } from './indicators.actions';
import {assign} from 'lodash-es';
import {
    HTTP_REQUEST_END,
    HTTP_REQUEST_START,
    UPLOAD_FILE_START,
    UPLOAD_FILE_END,
    CLEAR_UPLOAD_LIST
} from './indicators.actions';

export interface IUploadedFile {
    id: string;
    name: string;
    status: 'progress' | 'success' | 'failure';
}

export function makeUploadedFileObj(name: string): IUploadedFile {
    return {
        id: (+new Date()).toString(32),
        name,
        status: 'progress'
    };
}

export interface IndicatorsState {
    httpRequestIsActive: boolean;
    uploadedFiles: IUploadedFile[];
}

export const InitialIndicatorsState: IndicatorsState = {
    httpRequestIsActive: false,
    uploadedFiles: []
};

export function indicatorsReducer(state: IndicatorsState = InitialIndicatorsState, action: any): IndicatorsState {
    switch (action.type) {
        case HTTP_REQUEST_START:
            return assign({}, state, {httpRequestIsActive: true});

        case HTTP_REQUEST_END:
            return assign({}, state, {httpRequestIsActive: false});

        case UPLOAD_FILE_START:
            return assign({}, state, {
                uploadedFiles: [action.payload!, ...state.uploadedFiles]
            });

        case UPLOAD_FILE_END:
            return assign({}, state, {
                uploadedFiles: state.uploadedFiles.map((uf) => (uf.id === action.payload!.id ? action.payload! : uf))
            });

        case CLEAR_UPLOAD_LIST:
            return assign({}, state, {
                uploadedFiles: []
            });

        default:
            return state;
    }
}
