import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CurrencyPipe} from '@angular/common';
import {ILedgerAccount, LedgerAccountTypes} from '@cyberco-nodejs/zipi-typings';
import {select, Store} from '@ngrx/store';
import {selectLedgerAccounts} from '../../../finance/store/finance.selectors';
import {flattenChildrenDeep, nestedElementsByParent} from '../../../../utilities';
import {FetchLedgerAccounts} from '../../../finance/store/finance.actions';
import {IFinanceState} from '../../../finance/store/finance.reducer';
import {LEDGER_TYPES_GROUPS, LEDGER_TYPES_MAP} from '../../../../local-typings';
import {Subject} from 'rxjs';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {isEmpty} from 'lodash-es';

@Component({
    selector: 'app-ledger-account-selector',
    templateUrl: './ledger-account-selector.component.html'
})
export class LedgerAccountSelectorComponent implements OnInit, OnChanges, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @ViewChild('accountInput', {static: false}) accountInput: ElementRef<HTMLInputElement> | undefined;

    @Input() disabledAccountIds: number[] = []; // disabled accounts are still on the list
    @Input() disabledAccountSystemKeys: Array<string> = ['closing_clearing']; // disabled accounts are still on the list
    @Input() disabled: boolean = false;
    @Input() preferredTypes: LedgerAccountTypes[] = [];
    @Input() types: LedgerAccountTypes[] = [];
    @Input() normalBalanceType: 'debit' | 'credit' | null = null;
    @Input() disabledReselect: boolean = false;
    @Input() required: boolean = true;
    @Input() ledgerAccountControl: UntypedFormControl | AbstractControl = new UntypedFormControl(null);
    @Input() ledgerAccountFullControl: UntypedFormControl = new UntypedFormControl(null);
    @Input() ledgerAccountsArrControl: UntypedFormControl = new UntypedFormControl([]);
    @Input() placeholder: string = 'Ledger Account';
    @Input() floatLabel: 'auto' | 'never' | 'always' = 'auto';
    @Input() mode: 'single' | 'multi' = 'single';
    @Input() isInactiveShows: boolean = true;
    @Input() isInactiveSelectable: boolean = false;
    @Input() excludedOptions: Array<any> = [];

    ledgerAccountsByType: {[key: string]: ILedgerAccount[]} = {};
    preferredLedgerAccountsByType: {[key: string]: ILedgerAccount[]} = {};
    ledgerTypesMap: any = LEDGER_TYPES_MAP;
    ledgerTypesGroups: any = LEDGER_TYPES_GROUPS;
    accounts: ILedgerAccount[] = [];
    filterControl: UntypedFormControl = new UntypedFormControl(
        {
            value: '',
            disabled: this.disabled
        },
        []
    );
    formGroup: UntypedFormGroup = this.fb.group({
        filterControl: this.filterControl
    });
    noAccounts: boolean = false;
    singleSelectedLedgerAccount: ILedgerAccount | null = null;

    constructor(
        protected dialog: MatDialog,
        protected currency: CurrencyPipe,
        protected fb: UntypedFormBuilder,
        private store: Store<IFinanceState>
    ) {}

    ngOnInit() {
        this.store.dispatch(new FetchLedgerAccounts());
        this.selectAccounts();

        if (this.required) {
            this.filterControl.setValidators([Validators.required]);
            this.filterControl.updateValueAndValidity();
        }

        this.filterControl.valueChanges
            .pipe(
                startWith(''),
                map((val) => this.accountsToGroups(val)),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.ledgerAccountControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            this.setFilterControl();
        });
    }

    markTouched() {
        this.formGroup.markAllAsTouched();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.normalBalanceType && changes.normalBalanceType.currentValue) {
            this.preferredTypes = [];

            for (const group of this.ledgerTypesGroups) {
                for (const type of group.list) {
                    if (type.normal_balanced_type === changes.normalBalanceType.currentValue) {
                        this.preferredTypes.push(type.value);
                    }
                }
            }

            this.selectAccounts();
        }

        if (changes.disabled) {
            if (changes.disabled.currentValue) {
                this.filterControl.disable();
            } else {
                this.filterControl.enable();
            }
        }

        if (changes.types) {
            if (changes.types.currentValue) {
                this.selectAccounts();
            }
        }
    }

    selectAccounts() {
        this.store
            .pipe(
                select(selectLedgerAccounts),
                map((accounts) => {
                    let ledgerAccounts = accounts;
                    if (this.types.length > 0) {
                        ledgerAccounts = ledgerAccounts.filter((account) =>
                            this.types.some((type) => type === account.type)
                        );
                    }

                    return ledgerAccounts;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((accounts) => {
                this.accounts = this.isInactiveShows ? accounts : accounts.filter((la) => la.status === 'active');

                if (this.excludedOptions.length > 0) {
                    this.accounts = accounts.filter((a) => !this.excludedOptions.includes(a.system_key));
                }

                this.accountsToGroups(this.filterControl.value);
                if (this.ledgerAccountControl.value) {
                    this.setFilterControl();
                }
            });
    }

    setFilterControl() {
        const account = this.accounts.find((ac) => ac.ledger_account_id === this.ledgerAccountControl.value);

        if (account) {
            this.ledgerAccountFullControl.setValue(account);
            this.filterControl.patchValue(account.name);
            this.filterControl.disable();
            this.singleSelectedLedgerAccount = account;
        } else {
            this.ledgerAccountFullControl.setValue(null);
            this.filterControl.patchValue(null);
            this.filterControl.enable();
            this.singleSelectedLedgerAccount = null;
        }
    }

    accountsToGroups(inputFilter?: any) {
        const accounts = this.accounts;
        const ledgerAccountsByType: {[key: string]: any} = {};
        const preferredLedgerAccountsByType: {[key: string]: any} = {};
        let filterValue: string | null = null;
        if (inputFilter) {
            filterValue = inputFilter.trim().toLowerCase();
        }

        accounts.map((account) => {
            if (filterValue) {
                const hasValue =
                    (account.name && account.name.toLowerCase().indexOf(filterValue) !== -1) ||
                    (account.code && account.code.toLowerCase().indexOf(filterValue) !== -1);

                if (!hasValue) {
                    return;
                }
            }

            if (!ledgerAccountsByType[account.type]) {
                ledgerAccountsByType[account.type] = [];
            }

            ledgerAccountsByType[account.type].push(account);

            if (this.preferredTypes.includes(account.type)) {
                if (!preferredLedgerAccountsByType[account.type]) {
                    preferredLedgerAccountsByType[account.type] = [];
                }

                preferredLedgerAccountsByType[account.type].push(account);
            }
        });

        for (const key in ledgerAccountsByType) {
            if (ledgerAccountsByType[key].length > 1) {
                ledgerAccountsByType[key] = flattenChildrenDeep(nestedElementsByParent(ledgerAccountsByType[key]));
            }
        }

        // sort alphabetically
        Object.keys(ledgerAccountsByType).forEach((key) =>
            ledgerAccountsByType[key].sort((a: any, b: any) => (a.name >= b.name ? 1 : -1))
        );

        for (const key in preferredLedgerAccountsByType) {
            if (preferredLedgerAccountsByType[key].length > 1) {
                preferredLedgerAccountsByType[key] = flattenChildrenDeep(
                    nestedElementsByParent(preferredLedgerAccountsByType[key])
                );
            }
        }

        // sort alphabetically
        Object.keys(preferredLedgerAccountsByType).forEach((key) =>
            preferredLedgerAccountsByType[key].sort((a: any, b: any) => (a.name >= b.name ? 1 : -1))
        );

        this.ledgerAccountsByType = ledgerAccountsByType;
        this.preferredLedgerAccountsByType = preferredLedgerAccountsByType;

        this.noAccounts = isEmpty(ledgerAccountsByType);
    }

    removeAccountFkId() {
        this.ledgerAccountControl.patchValue(null);
        this.filterControl.patchValue(null);
        this.filterControl.enable();
        this.singleSelectedLedgerAccount = null;
    }

    selectItem(la: ILedgerAccount) {
        this.ledgerAccountControl.patchValue(la.ledger_account_id);
        this.singleSelectedLedgerAccount = la;
    }

    validateControl() {
        if (this.ledgerAccountControl.invalid) {
            this.filterControl.markAsTouched();
        }
    }

    /**
     * Selected ledger account
     * @param ledgerAccount
     */
    multiSelectItem(ledgerAccount: ILedgerAccount) {
        if (this.ledgerAccountsArrControl.value) {
            this.ledgerAccountsArrControl.patchValue([
                ...this.ledgerAccountsArrControl.value,
                ledgerAccount.ledger_account_id
            ]);
        } else {
            this.ledgerAccountsArrControl.patchValue([ledgerAccount.ledger_account_id]);
        }
        setTimeout(() => {
            this.filterControl.setValue(null);
            if (this.accountInput) {
                this.accountInput.nativeElement.value = '';
                this.accountInput.nativeElement.blur();
            }
        }, 0);
    }

    removeLedgerAccount(id: number) {
        const selected = this.ledgerAccountsArrControl.value;
        const index = selected.indexOf(Number(id));

        if (index >= 0) {
            selected.splice(index, 1);
        }

        this.ledgerAccountsArrControl.patchValue(selected);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
