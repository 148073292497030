<div style="position: relative">
    <!-------- Top menu -------->
    <nav class="top-menu d-flex justify-content-between flex-wrap align-items-center flex-nowrap w-100">
        <h2 class="d-flex align-items-center m-0 header-deal-name">
            <mat-form-field floatLabel="never" class="w-100 form-deal-name" *ngIf="dealFormGroup.controls.name">
                <input
                    matInput
                    placeholder="Deal name"
                    type="text"
                    name="name"
                    required
                    [formControl]="dealFormGroup.controls.name"
                />
            </mat-form-field>
        </h2>

        <!-------- Buttons menu -------->
        <div class="my-2 ml-4 d-flex flex-wrap justify-content-end flex-nowrap">
            <button
                mat-button
                rbac
                [rule]="{deals__view_payouts: true}"
                [denyMethod]="'display-none'"
                [disabled]="payoutsBtnDisabled"
                class="ml-1 rounded-0"
                (click)="openSideBar('finance')"
                [ngClass]="{'active-tab': isSidebarOpen && currentSidebarTab === 'finance'}"
            >
                Payouts
            </button>

            <button
                mat-button
                rbac
                class="ml-1 rounded-0"
                [anyRule]="[{deals__view_own: true}, {deals__view_any: true}]"
                [denyMethod]="'display-none'"
                (click)="openSideBar('commission_instructions')"
                [ngClass]="{'active-tab': isSidebarOpen && currentSidebarTab === 'commission_instructions'}"
            >
                Commission Instructions
            </button>

            <button
                mat-button
                rbac
                [rule]="{deals__view_activity: true}"
                [denyMethod]="'display-none'"
                class="ml-1 rounded-0"
                (click)="openSideBar('activity')"
                [ngClass]="{'active-tab': isSidebarOpen && currentSidebarTab === 'activity'}"
            >
                Activity
            </button>
            <button
                mat-button
                rbac
                [rule]="{deals__view_override: true}"
                [denyMethod]="'display-none'"
                [disabled]="overrideBtnDisabled"
                class="ml-1 rounded-0"
                (click)="openSideBar('override')"
                [ngClass]="{'active-tab': isSidebarOpen && currentSidebarTab === 'override'}"
            >
                Rules
                <mat-icon
                    *ngIf="isDealOverridden && dealFormGroup.controls.apply_rules?.value"
                    color="warn"
                    class="ml-1"
                    >error</mat-icon
                >
            </button>
        </div>
    </nav>

    <div
        class="d-flex message-bar-process justify-content-between align-items-center"
        *ngIf="!isProcessBarClosed && recalculate"
    >
        <span>Financial Verification and Profile Checks in Progress</span>
        <span (click)="closeBar()"><mat-icon>close</mat-icon></span>
    </div>
    <div class="d-flex message-bar-success justify-content-between align-items-center" *ngIf="!isSuccessBarClosed">
        <span>Updated</span>
        <span (click)="closeSuccessBar()"><mat-icon>close</mat-icon></span>
    </div>

    <mat-sidenav-container class="w-100">
        <!-------- Menu nav right -------->
        <mat-sidenav
            position="end"
            mode="over"
            #rightSideNav
            [(opened)]="isSidebarOpen"
            (closed)="closeSideBar()"
            style="width: 800px; margin-top: 136px"
            [fixedInViewport]="true"
        >
            <div class="p-2">
                <div *ngIf="currentSidebarTab === 'finance'">
                    <!--<app-financial-transfers [dealFormGroup]="dealFormGroup"></app-financial-transfers>-->
                    <app-deal-payouts (needReloadDeaL)="reloadDeaL()" [dealFG]="dealFormGroup"></app-deal-payouts>
                </div>

                <div *ngIf="currentSidebarTab === 'commission_instructions'">
                    <app-commission-instructions
                        class="section"
                        style="margin-top: 22px; width: 100%"
                        [editMode]="true"
                        [isDealConnectedToSkyslope]="isDealConnectedToSkyslope"
                        [dealFormGroup]="dealFormGroup"
                    ></app-commission-instructions>
                </div>

                <div *ngIf="currentSidebarTab === 'activity'">
                    <app-audit-log-container
                        class="section"
                        style="padding-top: 20px"
                        [dealFormGroup]="dealFormGroup"
                    ></app-audit-log-container>
                </div>

                <div *ngIf="currentSidebarTab === 'override'">
                    <app-company-compensation-profile-override
                        [financialElementsBySalesEntity]="financialElementsBySalesEntity"
                        [financialElementsBySalesEntityFC]="financialElementsBySalesEntityFC"
                        [salesEntities]="salesEntities"
                        [order]="0"
                        [editMode]="true"
                        [disabled]="dealFormGroup.controls.apply_rules?.disabled"
                        (save)="doSaveOverriddenCompensation($event)"
                        (saveDeal)="save(true)"
                        (cancel)="doCloseOverriddenCompensation()"
                        (delete)="doDeleteOverriddenCompensation($event)"
                        (overrideChange)="onOverrideChange($event)"
                    ></app-company-compensation-profile-override>
                    <!--<app-audit-log-container class="section" style="padding-top: 20px"-->
                    <!--[dealFormGroup]="dealFormGroup"></app-audit-log-container>-->
                </div>
            </div>
        </mat-sidenav>

        <!-------- EDIT DEAL -------->
        <mat-sidenav-content>
            <div class="page-container">
                <app-affected-by
                    [dealFormGroup]="dealFormGroup"
                    [showDismissWarningEventEmitter]="showDismissWarningEventEmitter"
                ></app-affected-by>

                <button
                    *ngIf="isNewEditDealPageAllowed"
                    type="button"
                    mat-raised-button
                    (click)="router.navigate(['/deals/v2/edit', dealIdInUrl])"
                >
                    New Edit Deal page
                </button>

                <form id="deal-form" style="width: 100%" #dealForm="ngForm">
                    <app-deal-property-information
                        class="section"
                        [dealFormGroup]="dealFormGroup"
                        [showDismissWarningEventEmitter]="showDismissWarningEventEmitter"
                        (dealUnsavedStatusEmitter)="changeUnsavedStatus($event)"
                    ></app-deal-property-information>
                    <app-edit-deal-transaction
                        class="section"
                        [dealFormGroup]="dealFormGroup"
                        [dealParticipants]="dealFormGroup.get('deal_participants')?.value || null"
                        [dealId]="dealIdInUrl"
                        [canCreateDualDeal]="canCreateDualDeal"
                        [dealSnapshot]="dealSnapshot"
                        [afterDealSaving$]="afterDealSaving$"
                        [checkCommissionPayer$]="checkCommissionPayer$"
                        [isDealSaveInProgress]="saveDisabled"
                        (saveDealChanges)="save(true, false, $event)"
                    >
                    </app-edit-deal-transaction>
                </form>

                <div class="w-100 mt-3">
                    <app-deal-disbursement
                        #disbursement
                        [showRefreshButton]="showRefreshButton"
                        [pdfOutdated]="pdfOutdated"
                        (onRefresh)="doRefresh($event)"
                        [dealFormGroup]="dealFormGroup"
                        [dealId]="dealIdInUrl"
                        [showDepositButtonNeed]="showDepositButtonNeed"
                        [dealSnapshot]="dealSnapshot"
                        [afterDealSaving$]="afterDealSaving$"
                        [isDealSaveInProgress]="saveDisabled"
                        [showSideCountAndSalesVolumeFlag]="showSideCountAndSalesVolumeFlag"
                        [isDealConnectedToSkyslope]="isDealConnectedToSkyslope"
                        (saveWithReload)="save(false, true)"
                        (saveDealChanges)="save(true, false, $event)"
                        (isDepositButtonNeed)="showDepositButtonNeed = $event"
                        (openDepositSection)="openDepositFunc($event)"
                        (checkCommissionPayerEmitter)="checkCommissionPayer$.next()"
                        (openSideBar)="openSideBar('commission_instructions')"
                    >
                    </app-deal-disbursement>
                </div>

                <div class="w-100 mt-3">
                    <app-deal-deposit-page
                        class="mt-2"
                        [dealFG]="dealFormGroup"
                        [isDepositShow]="openDeposit"
                        (needDepositClose)="closeDepositFunc($event)"
                        (isDepositButtonNeed)="showDepositButtonNeed = $event"
                    ></app-deal-deposit-page>
                </div>

                <div class="w-100 mt-3" *ngIf="showDealSelfTestingInterface && dealProcessing.dealFG">
                    <app-deal-self-testing class="mt-2" [selfTestingEntity]="dealFormGroup.controls.self_testing.value">
                    </app-deal-self-testing>
                </div>

                <!------- Zipi Deal Id ------->
                <p class="text-muted mt-1" *ngIf="dealFormGroup.controls.id">
                    Deal #: {{ dealFormGroup.controls.id.value }}
                </p>

                <!------- Buttons ------->
                <div class="d-flex w-100">
                    <button
                        type="button"
                        mat-stroked-button
                        class="qa-delete-deal-button"
                        rbac
                        [anyRule]="[{deals__delete_own: true}, {deals__delete_any: true}]"
                        (click)="deleteDeal()"
                        [disabled]="
                            dealFormGroup.controls.disbursement_approved &&
                            dealFormGroup.controls.disbursement_approved.value
                        "
                        [ngClass]="
                            dealFormGroup.controls.disbursement_approved &&
                            dealFormGroup.controls.disbursement_approved.value
                                ? ''
                                : 'text-danger'
                        "
                    >
                        <span
                            [matTooltip]="
                                dealFormGroup.controls.disbursement_approved &&
                                dealFormGroup.controls.disbursement_approved.value
                                    ? 'Deal must be Unapproved prior to deletion.'
                                    : '222'
                            "
                            [matTooltipDisabled]="
                                dealFormGroup.controls.disbursement_approved &&
                                !dealFormGroup.controls.disbursement_approved.value
                            "
                            >Delete</span
                        >
                    </button>

                    <span class="sp-spacer"></span>

                    <button
                        type="button"
                        mat-button
                        color="primary"
                        *ngIf="dealFormGroup.controls.id"
                        [routerLink]="['/deals', dealFormGroup.controls.id.value]"
                    >
                        Cancel
                    </button>

                    <div
                        rbac
                        [anyRule]="[{deals__edit_any: true}, {deals__edit_own: true}]"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        <button
                            mat-stroked-button
                            color="primary"
                            class="btn-save-close ml-2"
                            *ngIf="componentReady"
                            [disabled]="saveDisabled"
                            (click)="save(false)"
                        >
                            Save and Close
                        </button>

                        <button
                            mat-flat-button
                            color="primary"
                            class="btn-save-close ml-3"
                            style="min-width: 120px"
                            *ngIf="componentReady"
                            [disabled]="saveDisabled"
                            (click)="save(true)"
                        >
                            Save
                        </button>

                        <!-- <mat-select class="mat-raised-button btn-select"
                                *ngIf="componentReady"
                                [disabled]="saveDisabled">
                        <mat-option value="save" (onSelectionChange)="save(true)">Save</mat-option>
                    </mat-select> -->
                    </div>
                </div>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
