<div class="p-2">
    <div class="mb-3">
        <app-company-compensation-combined-picker
            #sharePicker
            heading="Who Has Access"
            title="+ Add"
            [availableTypes]="['individuals_in_group', 'individual']"
            [only_compensation_groups]="false"
            [initialGroupIds]="savedGroups"
            [initialProfileIds]="savedProfiles"
            displayMode="double-line"
        >
        </app-company-compensation-combined-picker>
        <div class="my-2 text-right">
            <button
                type="button"
                mat-raised-button
                [rule]="{contacts__manage_sharing: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                color="primary"
                (click)="share()"
            >
                Save
            </button>
        </div>
    </div>
</div>
