import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'roundToDecimal'
})
export class RoundToDecimalPipe implements PipeTransform {
    transform(value: number): number {
        if (typeof value !== 'number') {
            return value;
        }

        return Math.round(value * 100) / 100;
    }
}
