<!------- Header ------->
<mat-toolbar class="zp-contact-toolbar d-flex align-items-center" *ngIf="selectedContact">
    <button type="button" mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
    </button>

    <span *ngIf="selectedContact && selectedContact.display_name">
        {{ selectedContact.display_name }}
    </span>

    <span class="sp-spacer"></span>

    <!------- Top menu ------->
    <ng-container *ngIf="selectedContact && !selectedContact.is_delegated">
        <button
            mat-button
            [rule]="{contacts__view_sharing: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            [class.mat-stroked-button]="sideBarContent === 'share-contacts'"
            (click)="openSideBar('share-contacts')"
        >
            Share
        </button>
        <button
            mat-button
            [anyRule]="[{deals__view_own: true}, {deals__view_any: true}]"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            [class.mat-stroked-button]="sideBarContent === 'deals'"
            (click)="openSideBar('deals')"
        >
            Deals
        </button>
        <button
            mat-button
            [anyRule]="[
                {sales__view_invoices: true},
                {sales__view_recurring_invoices: true},
                {purchases__view_bills: true},
                {purchases__view_expenses: true}
            ]"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            [class.mat-stroked-button]="sideBarContent === 'finance'"
            (click)="openSideBar('finance')"
        >
            Finance
        </button>
        <button
            mat-button
            [rule]="{contacts__view_activities: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            *ngIf="selectedContact.related__profile_fk_id"
            [class.mat-stroked-button]="sideBarContent === 'activities'"
            (click)="openSideBar('activities')"
        >
            Activities
        </button>
        <button
            mat-button
            rbac
            [rule]="{contacts__view_personnel_records: true}"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'block'}"
            [denyStyle]="{display: 'none'}"
            [class.mat-stroked-button]="sideBarContent === 'records'"
            (click)="openSideBar('records')"
        >
            Records
        </button>
    </ng-container>
</mat-toolbar>

<mat-drawer-container class="zp-h-100" [ngClass]="{'container-sidebar-mode': selectedContact}">
    <!------- Left side bar ------->
    <mat-drawer #sidenav mode="side" opened class="zp-sidenav" [ngStyle]="sidenavStyle">
        <mat-nav-list class="zp-nav-list pt-0">
            <div class="d-flex justify-content-between align-items-center pl-3 contact-list-header">
                <!------- Select Contact types ------->
                <div>
                    <mat-form-field floatLabel="never" class="w-100">
                        <mat-select
                            placeholder="Contact categories"
                            [formControl]="contactSelectCtr"
                            multiple
                            [disabled]="isLoading"
                        >
                            <mat-select-trigger>
                                {{
                                    contactSelectCtr.value.length === contactCategories?.length
                                        ? 'All contacts'
                                        : contactSelectCtr.value.join(', ')
                                }}
                            </mat-select-trigger>
                            <mat-option *ngFor="let cc of contactCategories" [value]="cc" [disabled]="isLoading">
                                {{ metatypeSlugToLabel.get(cc) }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="d-flex align-items-center">
                    <!------- Bulk actions ------->
                    <button
                        mat-raised-button
                        class="ml-3"
                        color="warn"
                        style="top: -2px"
                        *ngIf="contactsIdsToBulkAction.length && mergeContactsEnabledFlag"
                        [matMenuTriggerFor]="bulkActions"
                    >
                        Bulk actions
                    </button>
                    <mat-menu #bulkActions="matMenu">
                        <div
                            mat-menu-item
                            *ngIf="contactsIdsToBulkAction.length"
                            [disabled]="contactsIdsToBulkAction.length <= 1"
                            rbac
                            [anyRule]="[
                                {contacts__manage_limited_contacts: true},
                                {contacts__manage_any_contacts: true}
                            ]"
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'block'}"
                            [denyStyle]="{display: 'none'}"
                            (click)="handleBulkAction('merge_contacts')"
                        >
                            Merge Contacts
                        </div>
                    </mat-menu>
                    <mat-form-field class="ml-3" floatLabel="never">
                        <mat-label>Filter</mat-label>
                        <mat-select [formControl]="statusFilter" multiple [disabled]="isLoading">
                            <mat-optgroup [label]="'Directory'">
                                <mat-option
                                    *ngFor="let filter of statusFilters"
                                    [value]="filter"
                                    [disabled]="isLoading"
                                >
                                    {{ filter }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                    <!------- Create contact Button ------->
                    <button
                        type="button"
                        mat-flat-button
                        color="primary"
                        class="ml-3"
                        [anyRule]="[{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'block'}"
                        [denyStyle]="{display: 'none'}"
                        (click)="router.navigate(['../contacts/create'])"
                    >
                        + Contact
                    </button>
                    <!------- Sorting order ------->
                    <button
                        mat-icon-button
                        (click)="changeSortOrder()"
                        class="fix-focus-outline ml-1"
                        color="primary"
                        [disabled]="isLoading"
                    >
                        <mat-icon class="mr-1">sort_by_alpha</mat-icon>
                    </button>
                </div>
            </div>

            <!------- Left Contact list ------->
            <app-lazy-loading
                *ngIf="offsetTopToLazyLoading$"
                class="sp-virtual-contacts-list"
                [data]="contacts"
                [itemHeightPx]="48"
                [numberOfItemsTillTheListEnd]="50"
                [offsetTop]="offsetTopToLazyLoading$"
                [minBufferPx]="1200"
                [maxBufferPx]="1200"
                [heightMeasure]="'%'"
                (scrolledDown)="doShowMoreDown($event)"
                (scrolledUp)="doShowMoreTop($event)"
                [checkViewport]="checkViewport$"
                [scrollToIndex]="scrollToIndex$"
            >
                <ng-template let-contact="listItem" let-idx="index">
                    <!-- there is a bug with routerLinkActive, so ngClass used instead-->
                    <mat-list-item
                        class="contact-row"
                        (click)="handleSelectedContact(contact)"
                        [ngClass]="selectedContact && selectedContact.id === contact.id ? 'active' : ''"
                    >
                        <div class="flex-row d-flex align-items-center w-100">
                            <!------- Checkbox ------->
                            <div
                                class="checkbox-area"
                                #divCheckbox
                                *ngIf="mergeContactsEnabledFlag"
                                rbac
                                [rule]="{company_settings__manage_users: true}"
                                [denyMethod]="'style'"
                                [allowStyle]="{display: 'inline-block'}"
                                [denyStyle]="{display: 'none'}"
                                [ngStyle]="
                                    contactsIdsToBulkAction.length ? {visibility: 'visible'} : {visibility: 'hidden'}
                                "
                            >
                                <mat-checkbox
                                    #checkbox
                                    (click)="$event.stopPropagation()"
                                    (change)="toggleDealCheckbox($event)"
                                    [value]="contact.contact_id.toString()"
                                    [checked]="contactsIdsToBulkAction.includes(contact.contact_id)"
                                ></mat-checkbox>
                            </div>
                            <span class="zp-contact-item" [ngStyle]="contactNameStyle">{{ contact.display_name }}</span>

                            <span class="sp-spacer"></span>

                            <button
                                mat-icon-button
                                (click)="handleSelectedContact(contact)"
                                *ngIf="contact?.has_zipi_pay || contact?.has_receive_only"
                            >
                                <mat-icon [matTooltip]="'Direct deposits enabled'" [matTooltipClass]="'my-tooltip'">
                                    monetization_on
                                </mat-icon>
                            </button>

                            <ng-container *ngIf="!regexUrl() && !getIsDefaultCompanyContact(contact)">
                                <mat-chip-list
                                    class="zp-contact-category"
                                    *ngIf="contact.contact_type_ids && contact.contact_type_ids.length > 0"
                                >
                                    <mat-chip class="mr-2" *ngFor="let contactType of getTypesForContact(contact)">
                                        {{ contactType.title }}
                                    </mat-chip>
                                </mat-chip-list>
                            </ng-container>

                            <div style="min-width: 30px" class="d-flex align-items-center">
                                <a
                                    mat-icon-button
                                    [rule]="{company_settings__view_users: true}"
                                    rbac
                                    [denyMethod]="'style'"
                                    [allowStyle]="{display: 'flex'}"
                                    [denyStyle]="{display: 'none'}"
                                    (click)="$event.stopPropagation()"
                                    [routerLink]="['/company/directory/users/', contact.related_profile.profile_id]"
                                    *ngIf="contact?.related_profile && !contact?.related__company_group_fk_id"
                                >
                                    <mat-icon
                                        *ngIf="contact?.related_profile && !contact?.related__company_group_fk_id"
                                        class="ml-2 profile-icon"
                                        [matTooltip]="'Directory Email &#13;' + contact?.related_profile.email"
                                        [matTooltipClass]="'my-tooltip'"
                                    >
                                        person
                                    </mat-icon>
                                </a>
                                <mat-icon
                                    [rule]="{company_settings__view_users: true}"
                                    rbac
                                    [denyMethod]="'style'"
                                    [denyStyle]="{display: 'inline-block'}"
                                    [allowStyle]="{display: 'none'}"
                                    *ngIf="contact?.related_profile && !contact?.related__company_group_fk_id"
                                    class="ml-2"
                                    [matTooltip]="'Directory Email &#13;' + contact?.related_profile.email"
                                    [matTooltipClass]="'my-tooltip'"
                                >
                                    person
                                </mat-icon>
                                <mat-icon
                                    class="mr-1"
                                    matTooltip="Directory entity"
                                    *ngIf="
                                        contact.related__company_group_fk_id &&
                                        contact.related_group &&
                                        contact.related_group.type !== 'division'
                                    "
                                >
                                    apartment
                                </mat-icon>
                                <mat-icon
                                    class="mr-1"
                                    matTooltip="Directory entity"
                                    *ngIf="
                                        contact.related__company_group_fk_id &&
                                        contact.related_group &&
                                        contact.related_group.type === 'division'
                                    "
                                >
                                    domain
                                </mat-icon>
                                <mat-icon
                                    class="mr-1"
                                    matTooltip="Linked Contact"
                                    *ngIf="contact.linked_to_contacts_ids && contact.linked_to_contacts_ids.length"
                                >
                                    insert_link
                                </mat-icon>
                            </div>
                        </div>
                    </mat-list-item>
                </ng-template>
            </app-lazy-loading>
        </mat-nav-list>
    </mat-drawer>

    <!------- Top side bar ------->
    <mat-drawer #leftSidebar mode="over" position="end" [opened]="false" fixedTopGap="50" style="width: 645px">
        <ng-container *ngIf="selectedContact && !selectedContact.is_delegated">
            <ng-container *ngIf="'share-contacts' === sideBarContent">
                <app-contact-share
                    [selectedContact]="selectedContact"
                    (closeSidebar)="leftSidebar.toggle(false)"
                ></app-contact-share>
            </ng-container>

            <ng-container *ngIf="'activities' === sideBarContent">
                <app-contact-activities
                    [profileId]="
                        selectedContact && selectedContact.related__profile_fk_id
                            ? selectedContact.related__profile_fk_id
                            : null
                    "
                ></app-contact-activities>
            </ng-container>

            <ng-container *ngIf="'deals' === sideBarContent">
                <app-contact-deals
                    [contactId]="selectedContact && selectedContact.id ? selectedContact.id : null"
                ></app-contact-deals>
            </ng-container>

            <ng-container *ngIf="'finance' === sideBarContent">
                <app-contact-finance
                    [contactId]="selectedContact && selectedContact.id ? selectedContact.id : null"
                ></app-contact-finance>
            </ng-container>

            <ng-container *ngIf="'records' === sideBarContent">
                <app-contact-notes
                    [contactId]="selectedContact && selectedContact.id ? selectedContact.id : null"
                    [companyId]="selectedContact.creator__company_fk_id"
                ></app-contact-notes>
            </ng-container>
        </ng-container>
    </mat-drawer>

    <!------- Right side bar ------->
    <mat-drawer-content style="transition: none">
        <div class="container-fluid pt-3">
            <app-contact-card
                [isDefaultCompanyContact]="isSelectedContactDefaultCompanyContact"
                [contactIsSelected$]="contactIsSelected$"
            ></app-contact-card>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
