<iframe
    *ngIf="link && iframeInit"
    width="100%"
    allow="camera"
    height="100%"
    style="position: fixed; inset: 0px; z-index: 2147483647; border-width: 0px; display: block; overflow: hidden auto"
    [src]="sanitizedUrl"
    frameborder="0"
    id="frame"
>
</iframe>
