import {Component, ElementRef, OnChanges, OnDestroy, SimpleChanges, ViewChild} from '@angular/core';
import {BaseWidgetComponent} from '../base.widget.component';
import {Deal} from '../../../../../../models/deal';
import {GoogleChartDirective} from '../../../../../../components/GoogleChart.directive';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {Widget} from '../../../../../../models/widget';

@Component({
    selector: 'app-sob-widget',
    templateUrl: './sob.widget.component.html',
    styleUrls: ['../widgets.scss']
})
export class SourceOfBusinessWidgetComponent extends BaseWidgetComponent implements OnDestroy, OnChanges {
    slug = 'sob';
    DEAL = Deal;

    private unsubscribeSub: Subject<void> = new Subject();
    public isDataExist = true;
    public pie_ChartData: string[][] = [];
    public pie_ChartDataNgx: {name: string; value: number}[] = [];
    public idFormName = 'sob-form-' + this.randomNum;
    public startDateControl: UntypedFormControl = new UntypedFormControl();
    public endDateControl: UntypedFormControl = new UntypedFormControl();

    @ViewChild('googleChart') googleChart: GoogleChartDirective | undefined;
    @ViewChild('chart') chart: ElementRef | undefined;

    // public pie_ChartOptions = {
    //     width: 340,
    //     height: 220,
    //     pieSliceText: 'none',
    //     legend: {position: 'right', textStyle: {fontSize: 14}},
    //     tooltip: { trigger: 'none' },
    //     backgroundColor: { fill: 'transparent' },
    //     chartArea: {left: 10, top: 10, width: '270', height: '200'},
    // };

    /**
     * On init
     */
    onInit() {
        this.entitiesControl.valueChanges.pipe(takeUntil(this.unsubscribeSub)).subscribe((changes) => {
            this.handleEntityPicker(changes);
        });
    }

    initSubscription() {
        super.initSubscription();

        this.startDateControl.valueChanges.pipe(takeUntil(this.unsubscribeSub)).subscribe((val) => {
            this.widget!.settings.period_start_date = val;
        });

        this.endDateControl.valueChanges.pipe(takeUntil(this.unsubscribeSub)).subscribe((val) => {
            this.widget!.settings.period_end_date = val;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.customizeVisibility && !changes.customizeVisibility.currentValue && this.configPanelVisible) {
            this.hideConfigPanel();
        }
    }

    formatTooltip(tooltip: any) {
        const {name, value} = tooltip.data;
        return `<span class="px-2 py-2 d-block">${name} - ${Math.round(value)}%</span>`;
    }

    processData(widget: Widget) {
        this.data = widget.data;
        const dataForWidget = [['Sob', 'Sob in deals']];
        const dataForWidgetNgx: {name: string; value: number}[] = [];

        const settings = this.data.settings || {};
        this.startDateControl.setValue(settings.period_start_date);
        this.endDateControl.setValue(settings.period_end_date);

        if (Object.keys(this.data['widget_data']).length === 0) {
            this.isDataExist = false;
        }

        if (this.data['widget_data'].sob_data && this.data['widget_data'].sob_data.length > 0) {
            this.data['widget_data'].sob_data.forEach((sob: any) => {
                dataForWidget.push([sob.percentage + '%' + ' - ' + sob.label, sob.percentage]);
                dataForWidgetNgx.push({
                    name: sob.label,
                    value: sob.percentage
                });
            });
        }
        this.pie_ChartData = dataForWidget;
        this.pie_ChartDataNgx = dataForWidgetNgx;
        if (this.googleChart) {
            this.googleChart.drawGraph(
                this.googleChart.chartOptions,
                this.googleChart.chartType,
                this.pie_ChartData,
                this.chart!.nativeElement
            );
        } else {
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.unsubscribeSub.next();
        this.unsubscribeSub.complete();
        super.ngOnDestroy();
    }
}
