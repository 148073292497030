import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {forkJoin, Subject} from 'rxjs';
import {QuickBooksService} from '../../../../../services/api/addon/quickbooks/quickbooks.service';
import {ProductsService} from '../../../../../services/api/finance/products.service';
import {ILedgerAccount, IProduct} from '@cyberco-nodejs/zipi-typings';
import {LedgerAccountService} from '../../../../../services/api/finance/ledger-accounts.service';
import {LEDGER_TYPES_MAP} from '../../../../../local-typings';
import {MatSelectChange} from '@angular/material/select';
import {QbProductMappingModel} from '../../../../../models/addon/qb-product-mapping-model';
import {QbItemModel} from '../../../../../models/addon/qb-item-model';
import {QbCompanySettingsModel} from '../../../../../models/addon/qb-company-settings-model';

interface LedgerAccountProductMapping {
    product_id: number | null | undefined;
    product_name: string;
    product_description: string;
    ledger_account_type: string | null;
    mapped_quickbooks_item: string | null;
}

@Component({
    selector: 'app-quickbooks-products',
    templateUrl: 'quickbooks-products.component.html',
    styleUrls: ['../quickbooks-ledgers-products.component.scss']
})
export class QuickBooksProductsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    dataSource: LedgerAccountProductMapping[] = [];
    displayedColumns: string[] = ['product_name', 'product_description', 'ledger_account', 'mapped_quickbooks_item'];
    products: IProduct[] = [];
    qbItems: QbItemModel[] = [];
    mappedProducts: QbProductMappingModel[] = [];
    listOfLedgerAccounts: ILedgerAccount[] = [];
    mappingsToUpdate: LedgerAccountProductMapping[] = [];
    defaultProductToUpdate: string | null = null;
    @Input() companySettings: QbCompanySettingsModel | null = null;

    constructor(
        private quickBooksService: QuickBooksService,
        protected productsService: ProductsService,
        private ledgerAccountService: LedgerAccountService
    ) {}

    onSave() {
        const mappingPayload = this.mappingsToUpdate.map((d) => ({
            books_product_ref: d.product_id as number,
            qb_item_ref: d.mapped_quickbooks_item as string
        }));

        if (this.mappingsToUpdate.length > 0) {
            this.quickBooksService
                .createProductMappings(mappingPayload)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((next) => {
                    // After successfully updating, reset mappingsToUpdate
                    this.mappingsToUpdate = [];
                });
        }

        // If default product was changed, save it
        if (this.companySettings && this.companySettings.default_invoice_product_ref !== this.defaultProductToUpdate) {
            this.quickBooksService
                .setDefaultProduct(this.defaultProductToUpdate)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {});
        }
    }

    ngOnInit() {
        if (this.companySettings?.default_invoice_product_ref) {
            this.defaultProductToUpdate = this.companySettings.default_invoice_product_ref;
        }

        const products$ = this.productsService.getProducts();
        const mappings$ = this.quickBooksService.getProductMappings();
        const qbItems$ = this.quickBooksService.getQBProductsServicesItems();
        const ledgerAccounts$ = this.ledgerAccountService.getLedgerAccounts();

        // Wait until all the required data is available, then set the dataSource.
        forkJoin([products$, mappings$, qbItems$, ledgerAccounts$])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                ([products, mappedProducts, qbItems, ledgerAccounts]) => {
                    this.products = products;
                    this.mappedProducts = mappedProducts;
                    this.qbItems = qbItems;
                    this.listOfLedgerAccounts = ledgerAccounts;

                    this.setDataSource();
                },
                (error) => {
                    console.error('Error fetching QB product/services data:', error);
                }
            );
    }

    setDataSource() {
        this.dataSource = this.products.map((p) => {
            const ledgerAcc = this.listOfLedgerAccounts.find((l) => l.id === p.ledger_account_fk_id);

            // Find a mapping that already exists for this Product.
            const mapped = this.mappedProducts.find((mp) => mp.books_product_ref === p.id);

            // Get the QB item for this mapped Product
            let qbItem = null;
            if (mapped) {
                qbItem = this.qbItems.find((qbi) => qbi.Id === mapped.qb_item_ref);
            }

            return {
                product_id: p.id,
                product_name: p.name,
                product_description: p.description,
                ledger_account_type: ledgerAcc ? LEDGER_TYPES_MAP[ledgerAcc?.type] : null,
                mapped_quickbooks_item: qbItem ? qbItem.Id : null
            };
        });
    }

    onQuickBooksItemChange(event: MatSelectChange, element: any): void {
        // On change, add the updated mapping to a list to be updated onSave.
        const mapping = this.dataSource.find((d) => d.product_id === element.product_id);

        if (mapping) {
            // If this product mapping was already marked for update, remove the old updated value.
            // i.e. the user changed the select option multiple times
            const foundIndex = this.mappingsToUpdate.findIndex((m) => m.product_id === mapping.product_id);
            if (foundIndex !== -1) {
                this.mappingsToUpdate.splice(foundIndex, 1);
            }

            mapping.mapped_quickbooks_item = event.value;
            this.mappingsToUpdate.push(mapping);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
