<section class="container-fluid" *ngIf="invoice">
    <div class="w-100 d-flex py-2 align-items-center">
        <a
            [routerLink]="['/sales/sourcedocuments/approve', invoice.connected__bill_fk_id]"
            *ngIf="invoice.connected__bill_fk_id"
            class=""
            style="font-size: 15px; margin-bottom: -5px"
        >
            {{ invoice.status_in_portal! | fromSnakeCase }}: {{ invoice.connected_bill?.bill_number }}
        </a>
        <span class="sp-spacer"></span>

        <div
            class="d-flex align-items-center"
            *ngIf="
                invoiceQBSync &&
                invoiceQBSync.is_active &&
                invoice &&
                !['draft', 'write_off', 'void', 'rejected', null].includes(invoice.summary_status)
            "
        >
            <mat-icon
                class="error-icon"
                *ngIf="invoiceQBSync.sync_status === 'error'"
                matTooltip="The previous sync attempt failed."
                >error</mat-icon
            >
            <button
                mat-button
                color="primary"
                (click)="sendToQuickbooks()"
                *ngIf="!dialogMode && invoiceQBSync.is_loaded && invoiceQBSync.sync_status !== 'completed'"
                [disabled]="invoiceQBSync.sync_status === 'in_progress'"
            >
                <mat-icon>sync</mat-icon>
                <span *ngIf="invoiceQBSync.sync_status !== 'in_progress'">Sync with QuickBooks</span>
                <span *ngIf="invoiceQBSync.sync_status === 'in_progress'">Sending to Quickbooks...</span>
            </button>

            <button
                mat-button
                color="primary"
                (click)="viewInQuickbooks()"
                *ngIf="!dialogMode && invoiceQBSync.is_loaded && invoiceQBSync.sync_status === 'completed'"
            >
                <span *ngIf="invoiceQBSync.sync_status !== 'in_progress'" class="mr-2">View in QuickBooks</span>
                <mat-icon style="font-size: 18px; height: 18px; width: 18px">open_in_new</mat-icon>
            </button>

            <mat-divider vertical style="height: 20px"></mat-divider>
        </div>

        <button mat-button color="primary" [matMenuTriggerFor]="appMenu" *ngIf="!dialogMode">
            <span>Actions</span>
            <mat-icon>expand_more</mat-icon>
        </button>

        <mat-menu #appMenu="matMenu">
            <div
                matTooltip="You cannot void Invoice associated with Deal."
                [matTooltipDisabled]="!!invoice && !invoice.source__deal_fk_id"
            >
                <button
                    mat-menu-item
                    *ngIf="!dialogMode && invoice.summary_status !== 'void'"
                    [disabled]="
                        ['paid', 'partial_payment'].includes(invoice.summary_status) ||
                        invoice.status_of_payment !== 'unpaid' ||
                        hasNonModifiableTransactions() ||
                        (invoice && !!invoice.source__deal_fk_id) ||
                        restrictedBasedOnDealConnection ||
                        restrictedBasedOnDealConnection === null
                    "
                    (click)="$event.stopPropagation(); voidInvoice()"
                >
                    Void
                </button>
            </div>

            <button
                mat-menu-item
                [rule]="{sales__manage_invoices: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                *ngIf="!dialogMode"
                [disabled]="
                    ['write_off', 'void', 'rejected'].includes(invoice.summary_status) ||
                    (invoice.creator__company_fk_id !== currentCompanyId &&
                        invoice.owner__company_fk_id !== currentCompanyId) ||
                    restrictedBasedOnDealConnection === null
                "
                (click)="$event.stopPropagation(); editInvoice(invoice)"
            >
                Edit
            </button>

            <button mat-menu-item (click)="downloaInvoicePdf()" *ngIf="!dialogMode">Download PDF</button>

            <button
                mat-menu-item
                [rule]="{sales__manage_invoices: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                *ngIf="!dialogMode"
                [disabled]="!invoice.payments || invoice.payments.length === 0"
                (click)="$event.stopPropagation(); recheckPaymentsStatuses(invoice)"
            >
                Refresh Payments Statuses
            </button>
        </mat-menu>
    </div>

    <mat-card class="p-5">
        <div class="invoice-header">
            <div
                class="errormessage"
                *ngIf="
                    invoice.source__company_fk_id &&
                    invoice.connected_bill &&
                    invoice.total_amount !== invoice.connected_bill.total_amount
                "
            >
                Warning! Total Amount of Invoice different from the Total Amount of Source Document
            </div>
            <div class="invoice-status" [ngStyle]="{'background-color': statusColor[invoice.summary_status!]}">
                {{ invoice.summary_status! | fromSnakeCase }}
            </div>
        </div>
        <div
            class="d-flex justify-content-end px-2"
            style="position: absolute; top: 25px; left: 0; text-align: right; width: 100%; font-size: 18px"
        >
            <div class="">Payment Status: {{ invoice.status_of_payment! | fromSnakeCase }}</div>
        </div>

        <div class="mb-3" *ngIf="!dialogMode">
            History
            <button mat-icon-button (click)="showHistory = !showHistory; loadHistory()">
                <mat-icon *ngIf="showHistory">expand_more</mat-icon>
                <mat-icon *ngIf="!showHistory">chevron_right</mat-icon>
            </button>

            <div
                *ngIf="showHistory"
                style="
                    max-height: 200px;
                    overflow-y: scroll;
                    overflow-x: hidden;
                    background-color: lightgray;
                    font-size: 12px;
                    padding: 10px;
                "
            >
                <div *ngFor="let log of invoiceLogs" class="d-flex">
                    <div style="width: 100px; margin-right: 50px" class="font-weight-bold" *ngIf="log.action_timestamp">
                        {{ log.action_timestamp | amDateFormat: 'MM/DD/YYYY HH:mm' }}
                    </div>
                    <div
                        style="width: 100px; margin-right: 50px"
                        class="font-weight-bold"
                        *ngIf="!log.action_timestamp"
                    >
                        N/A
                    </div>

                    <div>{{ log.description }}</div>
                </div>
            </div>
        </div>

        <header class="row">
            <div class="col-6 zp-company-info">
                <!-- <p>Company Logo</p> -->
                <p class="zp-company-title">{{ invoice?.owner_company?.title }}</p>
                <div *ngFor="let location of invoice?.owner_company?.locations">
                    <p *ngIf="location.label === invoice?.owner_company?.settings?.billing_address_label">
                        <span *ngIf="location.street_number">{{ location.street_number }} </span>
                        <span *ngIf="location.street_address">{{ location.street_address }} </span>
                        <span *ngIf="location.unit_number">{{ location.unit_number }} </span>
                        <br />
                        <span *ngIf="location.city">{{ location.city }}, </span>
                        <span *ngIf="location.state">{{ location.state }} </span>
                        <span *ngIf="location.zip">{{ location.zip }} </span>
                        <br />
                        <span *ngIf="location.country" class="text-muted">({{ location.country }})</span>
                    </p>
                </div>
            </div>

            <div class="col-6 text-right">
                <p class="zp-inveice_title">Invoice</p>
                <p class="zp-invoice-num_value">#{{ invoice?.invoice_number }}</p>

                <p class="zp-refference_due_title">Amount: {{ invoice?.total_amount | currency }}</p>
            </div>
        </header>

        <p>Bill To</p>
        <div class="row" style="margin-bottom: 15px">
            <div class="col-6">
                <span
                    *ngIf="invoice.money_sender_contact?.display_name && invoice.money_sender_contact?.hidden"
                    class="hidden-contact"
                >
                    {{ invoice.money_sender_contact?.display_name }}<br />
                </span>

                <a
                    *ngIf="invoice.money_sender_contact?.display_name && !invoice.money_sender_contact?.hidden"
                    [routerLink]="['/contacts', invoice.money_sender_contact?.contact_id]"
                >
                    {{ invoice.money_sender_contact?.display_name }}<br />
                </a>

                <div
                    *ngIf="
                        invoice.money_sender_contact &&
                        invoice.money_sender_contact.contact_locations &&
                        invoice.money_sender_contact.contact_locations.length > 0 &&
                        invoice.money_sender_contact?.hidden
                    "
                    class="hidden-contact"
                >
                    Hidden
                </div>

                <div
                    *ngIf="
                        invoice.money_sender_contact &&
                        invoice.money_sender_contact.contact_locations &&
                        invoice.money_sender_contact.contact_locations.length > 0 &&
                        !invoice.money_sender_contact?.hidden
                    "
                >
                    <span *ngIf="invoice.money_sender_contact.contact_locations[0].street_number"
                        >{{ invoice.money_sender_contact.contact_locations[0].street_number }}&nbsp;</span
                    >
                    <span *ngIf="invoice.money_sender_contact.contact_locations[0].street_address">{{
                        invoice.money_sender_contact.contact_locations[0].street_address
                    }}</span>
                    <span *ngIf="invoice.money_sender_contact.contact_locations[0].unit_number"
                        ><br />{{ invoice.money_sender_contact.contact_locations[0].unit_number }}</span
                    >
                    <br />
                    <ng-container *ngIf="invoice.money_sender_contact.contact_locations[0].city">
                        <span>{{ invoice.money_sender_contact.contact_locations[0].city + ',' }}&nbsp;</span>
                    </ng-container>
                    <span *ngIf="invoice.money_sender_contact.contact_locations[0].state"
                        >{{ invoice.money_sender_contact.contact_locations[0].state }}&nbsp;</span
                    >
                    <span *ngIf="invoice.money_sender_contact.contact_locations[0].zip"
                        >{{ invoice.money_sender_contact.contact_locations[0].zip }}&nbsp;</span
                    >
                    <span *ngIf="invoice.money_sender_contact.contact_locations[0].country" class="text-muted"
                        ><br />({{ invoice.money_sender_contact.contact_locations[0].country }})</span
                    >
                </div>
            </div>

            <div class="col-6 text-right">
                <p class="mb-2">
                    <span>Date:</span>
                    <span class="zp-w-150">{{ $any(invoice?.invoice_date) | dateFromNumber | date: 'longDate' }}</span>
                </p>

                <p class="mb-2">
                    <span>Terms:</span>
                    <span class="zp-w-150" *ngIf="invoice?.payment_terms && invoice?.payment_terms !== 'custom'">{{
                        termsMap.get(invoice?.payment_terms!)
                    }}</span>
                    <span class="zp-w-150" *ngIf="invoice?.payment_terms && invoice?.payment_terms === 'custom'"
                        >Net {{ invoice?.payment_terms_custom }}</span
                    >
                </p>

                <p class="mb-2">
                    <span>Due Date:</span>
                    <span class="zp-w-150">{{ $any(invoice?.due_date) | dateFromNumber | date: 'longDate' }}</span>
                </p>

                <p class="mb-2" *ngIf="invoice?.next_auto_recharge_date! > 0">
                    <span>Next Auto Recharge Date:</span>
                    <span class="zp-w-150">{{
                        $any(invoice?.next_auto_recharge_date) | dateFromNumber | date: 'longDate'
                    }}</span>
                </p>
            </div>
        </div>

        <table class="table">
            <thead class="bg-dark text-white">
                <tr>
                    <td scope="col">#</td>
                    <td scope="col" class="w-30">Item</td>
                    <td scope="col" class="w-30">Description</td>
                    <td scope="col">Qty</td>
                    <td scope="col" class="text-right">Rate</td>
                    <td scope="col" class="text-right">Amount</td>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of invoice?.items; index as idx">
                    <td>{{ idx + 1 }}</td>
                    <td>{{ item?.name }}</td>
                    <td>
                        <i class="text-muted">{{ item?.description }}</i>
                    </td>
                    <td>{{ item.quantity }}</td>
                    <td class="text-right">{{ item.rate | currency: '' : '' : '0.2-2' }}</td>
                    <td class="text-right">{{ item.amount | currency: '' : '' : '0.2-2' }}</td>
                </tr>
            </tbody>
        </table>

        <div class="w-100 text-right">
            <p class="mb-2">
                Sub Total:
                <span class="zp-total-nember">{{ invoice?.total_amount | currency: '' : '' : '0.2-2' }}</span>
            </p>
            <p class="mb-2">
                <strong
                    >Total: <span class="zp-total-nember font-weight-bold">{{ invoice?.total_amount | currency }}</span>
                </strong>
            </p>
            <div *ngIf="invoice.payments && invoice.payments.length > 0">
                <ng-container *ngFor="let payment of invoice.payments; let i = index">
                    <div *ngIf="['completed', 'processing', 'pending', 'scheduled', 'review'].includes(payment.status)">
                        <div
                            class="py-2"
                            [ngStyle]="{
                                color:
                                    $any(payment.status) === 'canceled' || $any(payment.status) === 'error'
                                        ? '#f94242'
                                        : 'black'
                            }"
                        >
                            <div class="remove-payment-container">
                                <span class="zp-w-150 remove-payment-container">
                                    <a
                                        href=""
                                        *ngIf="payment.status !== 'pending' && !invoice.source__deal_fk_id"
                                        class="remove-payment-container"
                                        (keyup.enter)="cancelPayment(payment)"
                                        (click)="$event.stopPropagation(); $event.preventDefault()"
                                    >
                                        <mat-icon
                                            class="remove-payment-icon"
                                            [matTooltip]="'Remove'"
                                            (click)="cancelPayment(payment)"
                                            *ngIf="
                                                [
                                                    'deduction',
                                                    'vendor_credit',
                                                    'customer_credit',
                                                    'payment_made',
                                                    'payment_received'
                                                ].includes(payment.source_type) &&
                                                payment.type === 'normal' &&
                                                !isCancelPaymentButtonDisabled
                                            "
                                        >
                                            close
                                        </mat-icon>
                                    </a>

                                    <span
                                        *ngIf="
                                            payment.source_type === 'vendor_credit' ||
                                            payment.source_type === 'customer_credit' ||
                                            payment.source_type === 'based_on_vendor_credit' ||
                                            payment.source_type === 'based_on_customer_credit'
                                        "
                                        >Credits</span
                                    >
                                    <span
                                        *ngIf="
                                            (payment.source_type === 'payment_made' ||
                                                payment.source_type === 'payment_received' ||
                                                payment.source_type === 'deduction') &&
                                            payment.type === 'normal'
                                        "
                                        >Payment</span
                                    >
                                    <span
                                        *ngIf="
                                            (payment.source_type === 'payment_made' ||
                                                payment.source_type === 'payment_received' ||
                                                payment.source_type === 'deduction') &&
                                            payment.type === 'refund'
                                        "
                                        >Refund</span
                                    >
                                </span>

                                <span class="zp-total-nember">
                                    <a
                                        [ngStyle]="payment.type === 'refund' ? {color: 'red'} : null"
                                        *ngIf="payment.source__payment_received_fk_id"
                                        [routerLink]="
                                            '/sales/paymentsreceived/' + payment.source__payment_received_fk_id
                                        "
                                        >{{
                                            payment.type === 'normal'
                                                ? (payment.amount | currency)
                                                : (payment.amount * -1 | currency)
                                        }}</a
                                    >
                                    <a
                                        *ngIf="payment.source__customer_credit_fk_id"
                                        [routerLink]="'/sales/creditnotes/' + payment.source__customer_credit_fk_id"
                                        >{{ payment.amount | currency }}</a
                                    >
                                    <span
                                        *ngIf="
                                            !payment.source__payment_received_fk_id &&
                                            !payment.source__customer_credit_fk_id
                                        "
                                        >{{ payment.amount | currency }}</span
                                    >
                                </span>
                            </div>

                            <div
                                *ngIf="payment.status === 'processing' || payment.status === 'review'"
                                style="line-height: 2px"
                            >
                                <span class="zp-w-150"></span>

                                <span class="zp-total-nember" style="color: grey; font-size: 14px"> (processing) </span>
                            </div>
                            <div *ngIf="payment.status === 'scheduled'" style="line-height: 2px">
                                <span class="zp-w-150"></span>

                                <span class="zp-total-nember" style="color: grey; font-size: 14px"> (scheduled) </span>
                            </div>

                            <div *ngIf="payment.status === 'pending'" style="line-height: 13px">
                                <span class="zp-w-150"></span>

                                <span
                                    class="zp-total-nember text-wrap"
                                    style="color: grey; font-size: 14px; width: 65px; text-align: center; padding: 0"
                                >
                                    Pending Deduction
                                </span>
                            </div>

                            <div
                                *ngIf="$any(payment.status) === 'canceled' || $any(payment.status) === 'error'"
                                style="line-height: 2px"
                            >
                                <span class="zp-w-150"></span>

                                <span class="zp-total-nember" style="color: grey; font-size: 14px">
                                    ({{ payment.status }})
                                </span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <p>
                <strong class="bg-light py-2">
                    <span class="zp-w-150">&nbsp;</span>
                    Balance:
                    <strong class="zp-total-nember">{{ invoice.balance | currency }}</strong>
                </strong>
            </p>
            <p>
                <span class="bg-light py-2" *ngIf="isProcessingPayment()">
                    <span class="zp-w-150">&nbsp;</span>
                    Pending Balance:
                    <span class="zp-total-nember">{{ invoice.pending_balance | currency }}</span>
                </span>
            </p>
        </div>

        <mat-form-field class="w-100" *ngIf="invoice.source__company_fk_id">
            <textarea
                matInput
                disabled
                cdkTextareaAutosize
                [value]="invoice.invoice_note"
                placeholder="Additional Info"
            >
            </textarea>
        </mat-form-field>

        <div
            *ngIf="!dialogMode && !['write_off', 'void', 'paid', 'draft', 'rejected'].includes(invoice.summary_status)"
            class="d-flex justify-content-between"
        >
            <div
                matTooltip="You cannot apply Credits to Escrow Financial Document."
                [matTooltipDisabled]="!restrictedBasedOnDealConnection"
            >
                <button
                    mat-raised-button
                    (click)="openApplyCreditsToInvoiceDialog()"
                    [disabled]="
                        !allowApply || restrictedBasedOnDealConnection || restrictedBasedOnDealConnection === null
                    "
                    [rule]="{sales__manage_invoices: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                >
                    Apply Credits <span class="text-muted">({{ creditsBalance | currency }})</span>
                </button>
            </div>

            <div
                *ngIf="['draft', 'open', 'sent', 'overdue', 'partial_payment'].includes(invoice.summary_status)"
                class="d-flex flex-grow-1 justify-content-end"
            >
                <button
                    mat-raised-button
                    class="ml-2"
                    (click)="resendInvoice()"
                    [rule]="{sales__manage_invoices: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                >
                    Send Invoice
                </button>
                <button
                    mat-raised-button
                    class="ml-2"
                    *ngIf="invoice.summary_status === 'draft'"
                    (click)="markAsOpen()"
                    [rule]="{sales__manage_invoices: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                >
                    Set as Open
                </button>

                <div
                    matTooltip="You cannot record payment for Escrow Financial Document."
                    [matTooltipDisabled]="!restrictedBasedOnDealConnection"
                >
                    <button
                        mat-raised-button
                        class="ml-2"
                        (click)="confirmPaid()"
                        *ngIf="invoice.pending_balance > 0"
                        [disabled]="restrictedBasedOnDealConnection || restrictedBasedOnDealConnection === null"
                        [rule]="{sales__manage_invoices: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        Record Payment
                    </button>
                </div>

                <div
                    matTooltip="You cannot charge Customer for Escrow Financial Document."
                    [matTooltipDisabled]="!restrictedBasedOnDealConnection"
                >
                    <button
                        mat-raised-button
                        class="ml-2"
                        (click)="chargeCustomer()"
                        *ngIf="invoice.pending_balance > 0"
                        [disabled]="restrictedBasedOnDealConnection || restrictedBasedOnDealConnection === null"
                        [rule]="{sales__manage_invoices: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        Charge Customer
                    </button>
                </div>
            </div>
        </div>

        <div
            [rule]="{sales__manage_invoices: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            *ngIf="dialogMode"
            class="w-100 text-right"
        >
            <button mat-raised-button (click)="switchToEditInDialog()">Edit</button>
        </div>

        <div class="my-3">
            Related Deals
            <button mat-icon-button (click)="showTransfers = !showTransfers">
                <mat-icon *ngIf="showTransfers">expand_more</mat-icon>
                <mat-icon *ngIf="!showTransfers">chevron_right</mat-icon>
            </button>

            <div *ngIf="showTransfers">
                <table
                    mat-table
                    *ngIf="transfersDataSource.data && transfersDataSource.data.length > 0"
                    [dataSource]="transfersDataSource"
                    class="mat-elevation-z1 w-100 mx-3 journals-table"
                >
                    <ng-container matColumnDef="index">
                        <th mat-header-cell *matHeaderCellDef disableClear class="w-5">#</th>
                        <td mat-cell *matCellDef="let element; let i = index" class="w-5">{{ i + 1 }}</td>
                    </ng-container>

                    <ng-container matColumnDef="deal_address">
                        <th mat-header-cell *matHeaderCellDef class="w-45">Deal Address</th>
                        <td mat-cell *matCellDef="let element" class="w-45">
                            <a *ngIf="element.deal_id" [routerLink]="['/deals/edit', element.deal_id]">
                                <span *ngIf="!element.deal_address" class="text-muted">No address </span>
                                <span *ngIf="element.deal_address"
                                    >{{ element.deal_street_number }} {{ element.deal_address }}
                                    {{ element.deal_city }}, {{ element.deal_state }} {{ element.deal_zip }}
                                </span>
                                — {{ element.deal_type }}/{{ element.deal_status }}
                            </a>
                            <ng-container *ngIf="!element.deal_id">
                                <span *ngIf="!element.deal_address" class="text-muted">No address </span>
                                <span *ngIf="element.deal_address"
                                    >{{ element.deal_street_number }}&nbsp;{{ element.deal_address }} &nbsp;{{
                                        element.deal_city
                                    }},&nbsp;{{ element.deal_state }}&nbsp;{{ element.deal_zip }}
                                </span>
                                — {{ element.deal_type }}/{{ element.deal_status }}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="product">
                        <th mat-header-cell *matHeaderCellDef class="w-25">Product</th>
                        <td mat-cell *matCellDef="let element" class="w-25">
                            <span *ngIf="element.product_id; else elseBlock">
                                {{ element.product_name }}
                                <i class="text-muted">{{ element.product_description }}</i>
                            </span>
                            <ng-template #elseBlock>--</ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="connection_type">
                        <th mat-header-cell *matHeaderCellDef class="w-15">Connection Type</th>
                        <td mat-cell *matCellDef="let element" class="w-15">
                            {{ FINANCIAL_TRANSFER_TYPE_LABELS[element.type]! }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            style="text-align: right"
                            class="w-10 justify-content-end"
                        >
                            Amount
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            style="text-align: right"
                            class="w-10 justify-content-end"
                        >
                            {{ element.amount | currency }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="transferDisplayedColumns"></tr>
                    <tr
                        mat-row
                        class="journals-row"
                        *matRowDef="let row; let i = index; columns: transferDisplayedColumns"
                    ></tr>
                </table>
            </div>
        </div>

        <div class="my-3" *ngIf="!dialogMode">
            Related Journal Records
            <button mat-icon-button (click)="showJournals = !showJournals">
                <mat-icon *ngIf="showJournals">expand_more</mat-icon>
                <mat-icon *ngIf="!showJournals">chevron_right</mat-icon>
            </button>

            <div *ngIf="showJournals">
                <table
                    mat-table
                    *ngIf="dataSource.data && dataSource.data.length > 0"
                    [dataSource]="dataSource"
                    class="mat-elevation-z1 w-100 mx-3 journals-table"
                >
                    <ng-container matColumnDef="account_name">
                        <th mat-header-cell *matHeaderCellDef disableClear>Account</th>
                        <td mat-cell *matCellDef="let element">{{ element.ledger_account?.name }}</td>
                        <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
                    </ng-container>

                    <ng-container matColumnDef="debit">
                        <th mat-header-cell *matHeaderCellDef>Debit</th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.debit_or_credit === 'debit'">{{ element.amount | currency }}</div>
                        </td>
                        <td mat-footer-cell *matFooterCellDef>
                            <b>{{ getTotalFor('debit') | currency }}</b>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="credit">
                        <th mat-header-cell *matHeaderCellDef>Credit</th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.debit_or_credit === 'credit'">{{ element.amount | currency }}</div>
                        </td>
                        <td mat-footer-cell *matFooterCellDef>
                            <b>{{ getTotalFor('credit') | currency }}</b>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row class="journals-row" *matRowDef="let row; columns: displayedColumns"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                </table>
            </div>
        </div>
    </mat-card>
</section>
