import {Component, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {BaseWidgetComponent} from '../base.widget.component';
import {Deal} from '../../../../../../models/deal';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {Widget} from '../../../../../../models/widget';

@Component({
    selector: 'app-avg-commission-widget',
    templateUrl: './avg-commission.widget.component.html',
    styleUrls: ['../widgets.scss']
})
export class AvgCommissionWidgetComponent extends BaseWidgetComponent implements OnDestroy, OnChanges {
    slug = 'avg';
    avgTypes = ['gci', 'percentage', 'all'];
    DEAL = Deal;

    private unsubscribeSub: Subject<void> = new Subject();
    public isDataExist = true;
    public idFormName = 'avg-form-' + this.randomNum;
    public startDateControl: UntypedFormControl = new UntypedFormControl();
    public endDateControl: UntypedFormControl = new UntypedFormControl();

    /**
     * On init
     */
    onInit() {
        this.entitiesControl.valueChanges.pipe(takeUntil(this.unsubscribeSub)).subscribe((changes) => {
            this.handleEntityPicker(changes);
        });
    }

    initSubscription() {
        super.initSubscription();

        this.startDateControl.valueChanges.pipe(takeUntil(this.unsubscribeSub)).subscribe((val) => {
            this.widget!.settings.period_start_date = val;
        });

        this.endDateControl.valueChanges.pipe(takeUntil(this.unsubscribeSub)).subscribe((val) => {
            this.widget!.settings.period_end_date = val;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.customizeVisibility && !changes.customizeVisibility.currentValue && this.configPanelVisible) {
            this.hideConfigPanel();
        }
    }

    processData(widget: Widget) {
        this.data = widget.data;

        const settings = this.data.settings || {};
        this.startDateControl.setValue(settings.period_start_date);
        this.endDateControl.setValue(settings.period_end_date);

        if (Object.keys(this.data['widget_data']).length === 0) {
            this.isDataExist = false;
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.unsubscribeSub.next();
        this.unsubscribeSub.complete();
        super.ngOnDestroy();
    }
}
