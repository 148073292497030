import {of, of as observableOf} from 'rxjs';

import {map, switchMap, filter, tap, catchError, first, mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store, select} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';

import {NotificationsService} from 'angular2-notifications';
import {ISettingsState} from './settings.reducer';
import {
    SettingsActionType,
    FetchProfileSettings,
    FetchProfileSettingsSuccess,
    UpdateProfileSettings,
    UpdateProfileSettingsSuccess,
    FetchProfileSettingsMust,
    FetchCompanyLocations,
    FetchCompanyLocationsResult,
    CreateCompanyLocation,
    CreateCompanyLocationResult,
    UpdateCompanyLocationResult,
    DeleteCompanyLocationResult,
    UpdateCompanyLocation,
    DeleteCompanyLocation,
    FetchCompanySettings,
    FetchCompanySettingsSuccess,
    UpdateCompanySettings,
    UpdateCompanySettingsSuccess,
    UpdateCompanyBillingSettings
} from './settings.actions';

import {SettingsService} from '../../../services/api/settings.service';
import {selectProfileSettings, selectCompanyLocations} from './settings.selectors';
import {CompanyLocationsService} from '../../../services/api/companies/company-locations.service';
import {CompanySettingsService} from '../../../services/api/companies/company-settings.service';
import {SessionService} from '../../../services/session.service';

@Injectable()
export class SettingsEffectsService {
    constructor(
        protected actions$: Actions,
        protected store: Store<ISettingsState>,
        protected router: Router,
        private ntfs: NotificationsService,
        private ss: SessionService,
        private settingsSrv: SettingsService,
        private companyLocationsSrv: CompanyLocationsService,
        private companySettingsSrv: CompanySettingsService
    ) {}

    @Effect()
    fetchProfileSetting$ = this.actions$.pipe(
        ofType<FetchProfileSettings>(SettingsActionType.FETCH_PROFILE_SETTINGS),
        switchMap(() => this.store.pipe(select(selectProfileSettings), first())),
        switchMap((profileSettings) => {
            if (profileSettings === null) {
                return this.settingsSrv.getProfileSettings().catch((err) => null);
            }
            return of(profileSettings);
        }),
        filter((ps) => !!ps),
        map((ps) => {
            if (ps) {
                return new FetchProfileSettingsSuccess(ps);
            }
        })
    );

    @Effect()
    fetchProfileSettingMust$ = this.actions$.pipe(
        ofType<FetchProfileSettingsMust>(SettingsActionType.FETCH_PROFILE_SETTINGS_MUST),
        switchMap((action) => this.settingsSrv.getProfileSettings()),
        map((ps) => new FetchProfileSettingsSuccess(ps))
    );

    @Effect({dispatch: false})
    updateProfileSetting$ = this.actions$.pipe(
        ofType<UpdateProfileSettings>(SettingsActionType.UPDATE_PROFILE_SETTINGS),
        switchMap((action) => this.settingsSrv.updateProfileSettings(action.payload!)),
        tap((ps) => {
            if (ps) {
                this.ntfs.info('Settings successfully updated');
            }
            this.store.dispatch(new UpdateProfileSettingsSuccess(true));
        }),
        // @ts-expect-error
        catchError((err: unknown) => err)
    );

    @Effect()
    fetchCompanyLocations$ = this.actions$.pipe(
        ofType<FetchCompanyLocations>(SettingsActionType.FETCH_COMPANY_LOCATIONS),
        switchMap(() => this.store.pipe(select(selectCompanyLocations), first())),
        switchMap((companyLocations) => {
            if (companyLocations.length === 0) {
                return this.companyLocationsSrv.fetchCompanyLocations().pipe(catchError((err: unknown) => of(null)));
            }
            return of(companyLocations);
        }),
        filter((ps) => !!ps),
        map((result) => {
            if (result) {
                return new FetchCompanyLocationsResult(result);
            }
        })
    );

    @Effect({dispatch: false})
    createCompanyLocation$ = this.actions$.pipe(
        ofType<CreateCompanyLocation>(SettingsActionType.CREATE_COMPANY_LOCATION),
        mergeMap(({payload}) =>
            this.companyLocationsSrv.createCompanyLocation(payload!).pipe(catchError((err: unknown) => of(null)))
        ),
        filter((ps) => !!ps),
        tap((result) => {
            if (result) {
                this.ntfs.success('Company Location created');
                this.store.dispatch(new CreateCompanyLocationResult(result));
            }
        })
    );

    @Effect({dispatch: false})
    updateCompanyLocation$ = this.actions$.pipe(
        ofType<UpdateCompanyLocation>(SettingsActionType.UPDATE_COMPANY_LOCATION),
        mergeMap(({payload}) =>
            this.companyLocationsSrv
                .updateCompanyLocation(Number(payload!.id), payload!)
                .pipe(catchError((err: unknown) => of(null)))
        ),
        filter((ps) => !!ps),
        tap((result) => {
            if (result) {
                this.ntfs.info('Company Location updated');
                this.store.dispatch(new UpdateCompanyLocationResult(result));
            }
        })
    );

    @Effect({dispatch: false})
    deleteCompanyLocation$ = this.actions$.pipe(
        ofType<DeleteCompanyLocation>(SettingsActionType.DELETE_COMPANY_LOCATION),
        mergeMap(({payload: id}) =>
            this.companyLocationsSrv.deleteCompanyLocation(id!).pipe(
                map((ok) => ({ok, id})),
                catchError((err: unknown) => of({ok: false, id}))
            )
        ),
        tap((r) => {
            const result: {ok: boolean; id: number} = r as {ok: boolean; id: number};
            if (result.ok) {
                this.ntfs.warn('Company Location deleted');
            }
            this.store.dispatch(new DeleteCompanyLocationResult(result));
        })
    );

    @Effect({dispatch: false})
    fetchCompanySetting$ = this.actions$.pipe(
        ofType<FetchCompanySettings>(SettingsActionType.FETCH_COMPANY_SETTINGS),
        switchMap((action) => {
            return (
                this.companySettingsSrv
                    // @ts-ignore
                    .getCompanySettings(this.ss.profile.company.id)
                    .pipe(catchError((err: unknown) => observableOf(null)))
            );
        }),
        filter((data) => data !== null),
        tap((data: any) => {
            if (data) {
                this.store.dispatch(new FetchCompanySettingsSuccess(data));
            }
        })
    );

    @Effect({dispatch: false})
    updateCompanySetting$ = this.actions$.pipe(
        ofType<UpdateCompanySettings>(SettingsActionType.UPDATE_COMPANY_SETTINGS),
        switchMap((action) => {
            return (
                this.companySettingsSrv
                    // @ts-ignore
                    .updateCompanySettings(this.ss.profile.company.id, action.payload!)
                    .pipe(catchError((err: unknown) => observableOf(false)))
            );
        }),
        tap((ok) => {
            if (ok) {
                this.ntfs.info('Settings successfully updated');
            }
            this.store.dispatch(new UpdateCompanySettingsSuccess(true));
        })
    );

    @Effect({dispatch: false})
    updateCompanyBillingSetting$ = this.actions$.pipe(
        ofType<UpdateCompanyBillingSettings>(SettingsActionType.UPDATE_COMPANY_BILLINGS_SUCCESS),
        tap((ok) => {
            this.ntfs.success(`Billing settings successfully updated`);
        })
    );
}
