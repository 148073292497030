import {BaseModel} from './base.model';
import {Profile} from './profile';

export class AuditLog extends BaseModel {
    profile: Profile | null = null;
    initiator__profile_fk_id: number | null = null;
    deal_fk_id: number | null = null;
    changes_list: AuditLogChange[] = [];
    event: 'create' | 'update' | null = null;
    target: 'deal' | 'calculation' | null = null;
    target_id: number | null = null;
}

export class AuditLogChange {
    property: string | null = null;
    before: any;
    after: any;

    // todo used only for getting readable values, do not use this property at backend!
    ancestors: string[] = [];
}

export class ReadableAuditLogChange {
    label: string | null = null;
    type: string | null = null;
    before: any;
    after: any;
}
