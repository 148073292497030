export class SourceList {
    static get default_sources() {
        return [
            {value: 'Sign', label: 'Sign'},
            {value: 'Advertisement', label: 'Advertisement'},
            {value: 'Open House', label: 'Open House'},
            {value: 'Personal Referral', label: 'Personal Referral'},
            {value: 'Corporate Referral', label: 'Corporate Referral'},
            {value: 'Prospecting', label: 'Prospecting'},
            {value: 'Previous Customer', label: 'Previous Customer'},
            {value: 'REO Account', label: 'REO Account'},
            {value: 'New Home Account', label: 'New Home Account'},
            {value: 'Farming', label: 'Farming'},
            {value: 'Duty Desk', label: 'Duty Desk'},
            {value: 'MLS', label: 'MLS'},
            {value: 'Office Lead', label: 'Office Lead'},
            {value: 'SOI', label: 'SOI'},
            {value: 'Expired/Withdrawn', label: 'Expired/Withdrawn'},
            {value: 'Social Marketing', label: 'Social Marketing'},
            {value: 'Direct Mail', label: 'Direct Mail'},
            {value: 'Floor Time', label: 'Floor Time'},
            {value: 'Door Knocking', label: 'Door Knocking'},
            {value: 'Relocation Department', label: 'Relocation Department'},
            {value: 'Drip Marketing', label: 'Drip Marketing'},
            {value: 'Call Capture', label: 'Call Capture'},
            {value: 'Website', label: 'Website'},
            {value: 'Other', label: 'Other'},
            {value: 'Home Partners of America', label: 'Home Partners of America'}
        ];
    }
}
