import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {ProgressService} from '../progress.service';
import {FocusAreaEntity} from '../entites/focus-area.entity';
import {GoalsComponent} from '../goals/goals.component';
import {SessionService} from '../../../services/session.service';
import {map, takeUntil} from 'rxjs/operators';
import {ProfilesService} from '../../../services/profiles.service';
import {Observable, Subject} from 'rxjs';
import {GoalsCreateBarComponent} from '../goals-create-bar/goals-create-bar.component';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-focus-areas',
    templateUrl: 'focus-areas.component.html',
    styleUrls: ['focus-areas.component.scss']
})
export class FocusAreasComponent implements OnDestroy, AfterViewInit {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('goals') goals: GoalsComponent | undefined = undefined;
    @ViewChild('goalsCreate') goalsCreate: GoalsCreateBarComponent | undefined = undefined;

    public focusAreas: FocusAreaEntity[] = [];

    public activeTabIndex: number = 0;
    public defaultBoardIndex: number | undefined;

    public editMode: boolean = false;
    public isSidebarOpened: boolean = false;

    state$: Observable<{[key: string]: string | number | boolean}> | undefined;

    constructor(
        protected progressService: ProgressService,
        public sessionService: SessionService,
        private profilesService: ProfilesService,
        private route: ActivatedRoute
    ) {
        this.progressService.source.onFocusAreasChange.pipe(takeUntil(this.unsubscribe)).subscribe((last) => {
            last.sort((focusArea1: FocusAreaEntity, focusArea2: FocusAreaEntity) => {
                if (focusArea1.id === focusArea2.id) {
                    return 0;
                }
                if (focusArea1.title === 'Personal') {
                    return -1;
                }
                if (focusArea2.title === 'Personal') {
                    return 1;
                }

                return 0;
            });

            this.focusAreas = last;

            // if list is not empty
            if (this.focusAreas.length) {
                // set default board index if it exists
                if (
                    this.sessionService.profile &&
                    this.sessionService.profile.settings &&
                    this.sessionService.profile.settings.goal_boards_settings &&
                    this.sessionService.profile.settings.goal_boards_settings.default_board_id
                ) {
                    this.defaultBoardIndex = this.focusAreas.findIndex(
                        (board) =>
                            board.id === this.sessionService.profile?.settings?.goal_boards_settings?.default_board_id
                    );
                    // if default board is missing
                    if (this.defaultBoardIndex === -1) {
                        this.defaultBoardIndex = 0;
                        this.setBoardAsDefault(this.focusAreas[0].id as number);
                    }
                    // set first board as default
                } else {
                    this.defaultBoardIndex = 0;
                }

                // set active board
                this.activeTabIndex = this.defaultBoardIndex;
            }
        });
    }

    ngAfterViewInit() {
        this.state$ = this.route.paramMap.pipe(
            map(() => window.history.state),
            takeUntil(this.unsubscribe)
        );
        this.state$.pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
            if (data.open && this.goalsCreate) {
                this.goalsCreate.openManageSidebar();
            }
        });
    }

    activeTabChanged(index: number) {
        if (typeof this.goals === 'undefined') {
            return;
        }
        this.goals.saveWeek();
        this.activeTabIndex = index;
    }

    setBoardAsDefault(boardId: number | undefined) {
        if (typeof boardId === 'undefined') {
            return;
        }

        const settings = Object.assign({}, this.sessionService.profile?.settings, {
            goal_boards_settings: {default_board_id: boardId}
        });

        // update profile in DB
        if (!this.sessionService.profile?.id) {
            return;
        }
        this.profilesService
            .updateProfileSettings(this.sessionService.profile.id, settings)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                this.defaultBoardIndex = this.focusAreas.findIndex((board) => board.id === boardId);
                // update current profile
                this.sessionService.setSettings = {
                    goal_boards_settings: {
                        default_board_id: this.focusAreas[this.defaultBoardIndex].id
                    }
                };
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
