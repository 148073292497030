<section [formGroup]="formGroup">
    <div class="d-grid-select">
        <!------- Customer ------->
        <div class="mr-3">
            <div class="d-flex align-items-baseline">
                <app-company-compensation-combined-picker
                    #combinedPicker
                    style="width: 100%; margin-right: 8px"
                    [availableTypes]="['contact']"
                    [title]="'Customer'"
                    [only_compensation_groups]="false"
                    [singleSelected]="true"
                    [bold_style]="false"
                    [nodesFA]="moneySenderCtrlArr"
                    [initialContactIds]="selectedContactIds"
                    [disabled]="(!!_invoice && !!_invoice.money_sender__company_fk_id) || editType !== 'full'"
                    [required]="true"
                ></app-company-compensation-combined-picker>
                <button
                    type="button"
                    mat-icon-button
                    matSuffix
                    (click)="contactCreate()"
                    [disabled]="editType !== 'full'"
                    [anyRule]="[{contacts__manage_limited_contacts: true}, {contacts__manage_any_contacts: true}]"
                    rbac
                    matTooltip="Create Contact"
                >
                    <mat-icon>person_add</mat-icon>
                </button>
            </div>
        </div>
        <!------- Sales Person ------->
        <div class="mx-2">
            <app-company-compensation-combined-picker
                *ngIf="editType === 'full'"
                class="w-100"
                [availableTypes]="['individual']"
                [title]="'Sales Person'"
                [singleSelected]="true"
                [bold_style]="false"
                [nodesFA]="selectedProfileArr"
                [initialProfileIds]="selectedProfileIds"
            ></app-company-compensation-combined-picker>
        </div>
        <!------- Allow to deduct from deal ------->
        <div class="mt-2">
            <mat-checkbox formControlName="is_deduct_from_deal">Allow to deduct from deal</mat-checkbox>
        </div>
        <!------- Payment Terms ------->
        <div class="mx-2">
            <mat-form-field
                [ngClass]="{
                    'w-100':
                        !formGroup.controls.payment_terms.value || formGroup.controls.payment_terms.value !== 'custom',
                    'w-50': formGroup.controls.payment_terms.value === 'custom'
                }"
            >
                <mat-select formControlName="payment_terms" placeholder="Payment Terms">
                    <mat-option *ngFor="let pt of paymentTerms" [value]="pt[0]">{{ pt[1] }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="pl-3 w-50" *ngIf="formGroup.controls.payment_terms.value === 'custom'">
                <input
                    matInput
                    type="number"
                    min="1"
                    placeholder="Number of days"
                    formControlName="payment_terms_custom"
                />
            </mat-form-field>
        </div>
    </div>

    <!------- Accepted Forms of Payment ------->
    <app-select-pay-to-methods
        *ngIf="!invoice_id || (!!invoice_id && !!_invoice)"
        [payToCardControl]="formGroup.controls.pay_to_card__payment_method_fk_id"
        [payToBankControl]="formGroup.controls.pay_to_bank__payment_method_fk_id"
        [convenienceFeeBankPayerControl]="formGroup.controls.bank_fee_payer"
        [convenienceFeeCardPayerControl]="formGroup.controls.card_fee_payer"
        [isNeedToPresetDefault]="!invoice_id"
        [allowedGatewayTypes]="['authorize_net_merchant', 'zipi_financial_card_merchant', 'zipi_financial_business']"
        [pickerType]="'separated'"
        [disabled]="editType !== 'full'"
    >
    </app-select-pay-to-methods>
    <!------- Items Header ------->
    <h3 class="d-flex align-items-center mt-3">
        Items
        <button
            [disabled]="editType !== 'full'"
            type="button"
            mat-raised-button
            (click)="addItem()"
            matTooltip="Add item"
            class="short-button ml-2"
        >
            <mat-icon>add</mat-icon>
        </button>
    </h3>
    <!------- Items ------->
    <div class="overflow-auto">
        <div
            *ngFor="let itemGroup of itemsArray.controls; index as idx; first as first"
            class="d-grid-items-container"
            [ngClass]="groupList.length ? 'with-groups' : 'no-groups'"
        >
            <div class="d-grid-items" [formGroup]="itemGroup">
                <!------- Product/Service ------->
                <div class="mr-2">
                    <ng-container
                        *ngIf="!itemGroup.controls.product_fk_id.value && !itemGroup.controls.item_has_transfer.value"
                    >
                        <mat-form-field class="w-100">
                            <input
                                formControlName="name"
                                type="text"
                                matInput
                                required
                                placeholder="Product/Service"
                                (blur)="trimOnBlur(itemGroup.controls.name)"
                                [matAutocomplete]="auto"
                                autocomplete="off"
                            />
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option (click)="$event.stopPropagation(); createProduct()"
                                    >Create New Product</mat-option
                                >
                                <ng-container *ngFor="let product of getProducts(itemGroup)">
                                    <mat-option
                                        *ngIf="
                                            product.status === 'active' ||
                                            itemGroup.controls.product_fk_id.value === product.product_id
                                        "
                                        [value]="product.name"
                                        [disabled]="['closing_debit', 'closing_credit'].includes(product.system_key)"
                                        (onSelectionChange)="selectItem(itemGroup, product)"
                                    >
                                        {{ product.name }}
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </ng-container>
                    <ng-container
                        *ngIf="itemGroup.controls.product_fk_id.value || itemGroup.controls.item_has_transfer.value"
                    >
                        <mat-form-field class="w-100">
                            <input formControlName="name" matInput type="text" />
                            <button
                                mat-button
                                matSuffix
                                mat-icon-button
                                aria-label="Clear"
                                [disabled]="editType !== 'full'"
                                *ngIf="!itemGroup.controls.item_has_transfer.value"
                                (click)="removeProductFkId(itemGroup)"
                            >
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </ng-container>
                </div>
                <!------- Description ------->
                <div class="mx-2">
                    <mat-form-field class="w-100">
                        <input
                            matInput
                            formControlName="description"
                            placeholder="Description"
                            type="text"
                            (blur)="trimOnBlur(itemGroup.controls.description)"
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
                <!------- Quantity ------->
                <div class="mx-2">
                    <app-wildcard-picker
                        [type]="'quantity'"
                        [placeholder]="'Quantity'"
                        [placeholderAttribute]="'Custom Attribute Quantity'"
                        [contactFc]="formGroup.controls.money_sender__contact_fk_id"
                        [productFC]="itemGroup.controls.product_fk_id"
                        [valueFC]="itemGroup.controls.quantity"
                        [valueWildcardIdFC]="itemGroup.controls.quantity__wildcard_fk_id"
                        style="
                            z-index: 2;
                            justify-content: space-between;
                            display: flex;
                            align-items: center;
                            margin-right: 5px;
                        "
                    >
                    </app-wildcard-picker>
                </div>
                <!------- Rate ------->
                <div class="mx-2">
                    <app-wildcard-picker
                        [placeholder]="'Rate'"
                        [placeholderAttribute]="'Custom Attribute Price'"
                        [contactFc]="formGroup.controls.money_sender__contact_fk_id"
                        [productFC]="itemGroup.controls.product_fk_id"
                        [valueFC]="itemGroup.controls.rate"
                        [valueWildcardIdFC]="itemGroup.controls.rate__wildcard_fk_id"
                        [type]="'currency'"
                        [allowNegative]="true"
                        style="
                            z-index: 2;
                            justify-content: space-between;
                            display: flex;
                            align-items: center;
                            margin-right: 5px;
                        "
                    >
                    </app-wildcard-picker>
                </div>
                <!------- Amount ------->
                <div class="mx-2">
                    <mat-form-field class="w-100">
                        <input
                            matInput
                            formControlName="amount"
                            placeholder="Amount"
                            type="text"
                            [maskito]="currencyWithNegativeMaskitoMask"
                        />
                    </mat-form-field>
                </div>
                <!------- Ledger Account ------->
                <div class="mx-2">
                    <app-ledger-account-selector
                        class="w-100"
                        [ledgerAccountControl]="itemGroup.controls.ledger_account_fk_id"
                        [ledgerAccountFullControl]="itemGroup.controls.selected_full_ledger"
                        [disabledReselect]="
                            !!itemGroup.controls.product_fk_id.value || itemGroup.controls.product_fk_id.disabled
                        "
                        [placeholder]="'Account'"
                        [excludedOptions]="['accounts_receivable']"
                    >
                    </app-ledger-account-selector>
                </div>
                <!------- Assign to Division ------->
                <div class="mx-2" *ngIf="groupList.length">
                    <app-division-picker
                        [isNeedToBeFiltered]="
                            itemGroup.controls.selected_full_ledger.value
                                ? !itemGroup.controls.selected_full_ledger.value.is_accessible_for_all
                                : false
                        "
                        [filterById]="
                            itemGroup.controls.selected_full_ledger.value
                                ? itemGroup.controls.selected_full_ledger.value.accessible_for
                                : []
                        "
                        [nodesFA]="itemGroup.controls.selected_divisions"
                        [initialGroupId]="itemGroup.controls.selected_division_ids.value"
                        [isTotallyDisabled]="!itemGroup.controls.ledger_account_fk_id.value"
                        [placeholder]="'Assign to Division'"
                    >
                    </app-division-picker>
                </div>
            </div>
            <!------- Actions ------->
            <div
                class="actions"
                *ngIf="
                    !invoice || invoice?.status_of_payment === 'unpaid' || invoice?.status_of_payment === 'need_confirm'
                "
            >
                <button
                    type="button"
                    mat-icon-button
                    matTooltip="Clear Item"
                    [disabled]="editType !== 'full'"
                    *ngIf="itemsArray.length === 1"
                    (click)="resetItem(itemGroup)"
                >
                    <mat-icon>close</mat-icon>
                </button>
                <button
                    type="button"
                    mat-icon-button
                    matTooltip="Remove Item"
                    *ngIf="itemsArray.length > 1"
                    [disabled]="editType !== 'full'"
                    (click)="itemsArray.removeAt(idx)"
                >
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <!------- Total amount ------->
    <div class="d-flex justify-content-end align-items-baseline my-2">
        <span class="mr-2">Total amount: &nbsp;</span>
        <strong class="zp-total-amount">
            {{ formGroup.controls.total_amount.value | currency }}
        </strong>
    </div>

    <!------- Deal Association Header ------->
    <h3 class="d-flex align-items-center">
        Deal Association
        <button
            [disabled]="editType !== 'full'"
            type="button"
            mat-raised-button
            (click)="addDealAssociation()"
            matTooltip="Add item"
            class="short-button ml-2"
        >
            <mat-icon>add</mat-icon>
        </button>
    </h3>
    <!------- Deal Association ------->
    <div
        *ngFor="let associationGroup of associationItemArray.controls; index as idx; first as first"
        class="d-flex align-items-center"
    >
        <div class="d-flex align-items-center w-100" [formGroup]="associationGroup">
            <div class="w-30 px-1">
                <app-deal-picker
                    class="w-100"
                    [disabledSelect]="associationGroup.controls.connected__deal_fk_id.disabled"
                    [dealControl]="associationGroup.controls.connected__deal_fk_id"
                    [placeholder]="'Associated Deal'"
                >
                </app-deal-picker>
            </div>
            <div class="w-30 px-1">
                <mat-form-field *ngIf="!associationGroup.controls.transfer_id.value">
                    <mat-label>Select Product</mat-label>
                    <mat-select
                        formControlName="product_id"
                        (selectionChange)="
                            validateAmount(
                                {target: associationGroup.controls.amount.value},
                                associationGroup.controls.product_id.value
                            );
                            setProductName($event, associationGroup)
                        "
                    >
                        <ng-container *ngFor="let prodId of object.keys(prodSet)">
                            <mat-option [value]="prodId" *ngIf="prodSet[$any(prodId)]?.max_amount > 0">
                                {{ prodSet[$any(prodId)]?.product?.name }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <div
                    class="pl-2"
                    *ngIf="
                        associationGroup.controls.transfer_id.value &&
                        prodSet[$any(associationGroup.controls.product_id.value)]?.product
                    "
                >
                    {{ prodSet[$any(associationGroup.controls.product_id.value)]?.product?.name }}
                </div>
            </div>
            <div class="w-20 px-1">
                <div
                    *ngIf="
                        associationGroup.controls.product_id.value &&
                        prodSet[$any(associationGroup.controls.product_id.value)] &&
                        prodSet[$any(associationGroup.controls.product_id.value)]?.product
                    "
                >
                    <i class="text-muted">{{
                        prodSet[$any(associationGroup.controls.product_id.value)]?.product?.description
                    }}</i>
                </div>
            </div>
            <div class="w-10 px-1">
                <div
                    *ngIf="
                        associationGroup.controls.product_id.value &&
                        prodSet[$any(associationGroup.controls.product_id.value)]
                    "
                >
                    {{ prodSet[$any(associationGroup.controls.product_id.value)]!.max_amount | currency }}
                </div>
            </div>
            <div class="w-10 px-1 d-flex justify-content-end">
                <mat-form-field class="w-70">
                    <input
                        matInput
                        type="text"
                        [maskito]="currencyMaskitoMask"
                        (keyup)="validateAmount($event, associationGroup.controls.product_id.value)"
                        [formControl]="associationGroup.controls.amount"
                    />
                </mat-form-field>
            </div>
        </div>
        <div class="actions">
            <button
                type="button"
                [disabled]="editType !== 'full'"
                mat-icon-button
                matTooltip="Remove Association"
                (click)="removeDealAssociation(idx)"
            >
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</section>
