import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {firstValueFrom, Subject} from 'rxjs';
import {CompanyGatewayService} from '../../../../../../../../services/api/finance/company-gateway.service';
import {SessionService} from '../../../../../../../../services/session.service';
import {GatewayTypes, IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {takeUntil} from 'rxjs/operators';
import {FEE_COMMISSION_SCENARIOS, FEE_TRANSFER_CUSTOMIZATION_REFS} from '../../../../../../../../local-typings';
import {MatDialog} from '@angular/material/dialog';

const win: any = window;
const Payload: any = win.Payload;

@Component({
    selector: 'app-zipi-financial-payload-setup',
    templateUrl: './zipi-financial-payload-setup.component.html',
    styleUrls: ['./zipi-financial-payload-setup.component.scss']
})
export class ZipiFinancialPayloadSetupComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() gatewayType: GatewayTypes | undefined;
    @Output() reloadAndShowFinalPopup: EventEmitter<{
        gateway_id: number;
        payment_method_id: number | null;
        popup_type: 'final' | 'ledger';
    }> = new EventEmitter();

    lockButton: boolean = false;

    areCompanyOverridesLoaded: boolean = false;
    shouldShowProcessingFeeText: boolean = false;

    constructor(
        private companyGatewayService: CompanyGatewayService,
        public sessionService: SessionService,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        if (this.sessionService.profile?.company_fk_id) {
            this.companyGatewayService
                .getCompanyOverrides(this.sessionService.profile.company_fk_id.toString(), FEE_COMMISSION_SCENARIOS)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe({
                    next: (overrides) => {
                        if (
                            overrides?.commission_payout?.outgoing?.transfer_customization_ref ===
                                FEE_TRANSFER_CUSTOMIZATION_REFS.client_pays ||
                            overrides?.commission_payout?.outgoing?.transfer_customization_ref ===
                                FEE_TRANSFER_CUSTOMIZATION_REFS.client_pays_delay
                        ) {
                            this.shouldShowProcessingFeeText = true;
                        }
                    },
                    complete: () => {
                        this.areCompanyOverridesLoaded = true;
                    }
                });
        }
    }

    async openPaForm() {
        const presetupData: Partial<IPaymentGateway> = {
            driver_type: 'payload'
        };
        const response = await firstValueFrom(this.companyGatewayService.preSetupZipiFinancialGateway(presetupData));

        this.lockButton = true;
        Payload(response.payload_ui_token);
        new Payload.ProcessingAccount().on('account_created', (event: any) => {
            const payloadCustomerData = {
                title: event.account.name,
                gateway_type: this.gatewayType,
                driver_type: 'payload',
                external_ref: event.account.id,
                payment_methods: event.account.payment_methods
            } as any;
            this.createPaymentGateway(payloadCustomerData);
        });
    }

    async createPaymentGateway(customerData: Partial<IPaymentGateway>) {
        const gateway = await firstValueFrom(this.companyGatewayService.createZipiFinancialGateway(customerData));
        this.lockButton = false;
        if (gateway && gateway.payment_gateway_id) {
            this.reloadAndShowFinalPopup.emit({
                gateway_id: gateway.payment_gateway_id,
                payment_method_id: null,
                popup_type: 'ledger'
            });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
