import {Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {Deal} from '../../../../../../models/deal';
import {Subject} from 'rxjs';

@Component({
    selector: 'cropper-controls',
    templateUrl: 'cropper-controls-template-2.component.html',
    styles: [
        `
            .none {
                display: none;
            }
            .upload {
                margin-bottom: 8px;
            }
        `
    ]
})
export class CropperControlsComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() deal: Deal = new Deal();
    @Input() disabled: boolean = false;
    // eslint-disable-next-line
    @Output('file-changed') fileChanged = new EventEmitter();
    // eslint-disable-next-line
    @Output('file-clear') fileClear = new EventEmitter();

    @ViewChild('file_input_text') private fileInputText: ElementRef | undefined;
    @ViewChild('file_input', {static: true}) private fileInput: ElementRef | undefined;
    @ViewChild('file_input_text_div') private fileInputTextDiv: ElementRef | undefined;

    public _inputText: string = '';
    public set inputText(path) {
        if (path.lastIndexOf('\\')) {
            this._inputText = path.slice(path.lastIndexOf('\\') + 1, path.length);
        } else if (path.lastIndexOf('/')) {
            this._inputText = path.slice(path.lastIndexOf('\\') + 1, path.length);
        }
    }

    public get inputText(): string {
        return this._inputText;
    }

    fileChangeListener($event: any) {
        this.inputText = $event.target.value;
        this.fileChanged.emit({
            file: $event.target.files[0],
            path: $event.target.value
        });
    }

    fileClearListener($event: Event) {
        this.inputText = '';
        this.fileInput!.nativeElement.value = '';
        this.fileClear.emit($event);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.fileChanged.complete();
        this.fileClear.complete();
    }
}
