import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ICompanyWideState} from './company-wide.reducer';
import {SourceOfBusinessInfo} from 'app/models/source-of-business-info';
import {SourceList} from 'app/models/source-list';

export const selectCompanyWideFeature = createFeatureSelector<ICompanyWideState>('companyWide');

export const selectSOB = createSelector(selectCompanyWideFeature, (state: ICompanyWideState) => {
    return state.sob;
});
export const selectProcessedSOB = createSelector(
    selectCompanyWideFeature,
    (state: ICompanyWideState): SourceOfBusinessInfo => {
        if (state.sob && state.sob.length > 0) {
            return {
                sourceList: state.sob.map((sob) => ({
                    value: sob.label!,
                    label: sob.label!
                })),
                isDefault: false
            };
        } else {
            return {
                sourceList: SourceList.default_sources,
                isDefault: true
            };
        }
    }
);

export const selectSubTypes = createSelector(selectCompanyWideFeature, (state: ICompanyWideState) => {
    return state.subTypes;
});

export const selectSubSources = createSelector(selectCompanyWideFeature, (state: ICompanyWideState) => {
    return state.subSources;
});

export const selectMembershipOrganizations = createSelector(selectCompanyWideFeature, (state: ICompanyWideState) => {
    return state.membershipOrganizations;
});

export const selectTagCategories = createSelector(selectCompanyWideFeature, (state: ICompanyWideState) => {
    return state.tagCategories;
});

export const selectWildcards = createSelector(selectCompanyWideFeature, (state: ICompanyWideState) => {
    return state.wildcards;
});
