import {GenericEntity} from '../../../entites/generic.entity';
import {GoalInstanceEntity} from './goal-instance.entity';

export class GoalValueEntity extends GenericEntity {
    date: string | null = null;
    date_key: number | null = null;
    value: number | null = null;
    updated_at: string | null = null;
    goal_instance: GoalInstanceEntity | null = null;

    constructor() {
        super();
    }

    static get helpers() {
        return Object.assign(
            {
                props: Object.getOwnPropertyNames(new this()).reduce(
                    (acc, curr) => {
                        acc[curr] = curr;
                        return acc;
                    },
                    {} as {[key: string]: any}
                ),
                build: {}
            },
            GenericEntity.helpers
        );
    }

    static get fabric() {
        return Object.assign(GenericEntity.fabric, {
            hydrate: (
                input: object,
                output: GoalValueEntity = new this(),
                props: {[prop: string]: string} = this.helpers.props,
                transform: {[id: string]: (val: any, fabric: any) => any} = this.helpers.build
            ): GoalValueEntity => {
                // @ts-ignore: might be null if input is null
                return <GoalValueEntity>GenericEntity.fabric.hydrate(input, output, props, transform);
            },
            dehydrate: (
                input: object | GoalValueEntity,
                output: object = {},
                props: {[prop: string]: string} = this.helpers.props,
                transform: {[id: string]: (val: any, fabric: any) => any} = this.helpers.build
            ): object => {
                // @ts-ignore: might be null if input is null
                return <object>GenericEntity.fabric.dehydrate(input, output, props, transform);
            }
        });
    }
}
