import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {IFinanceState} from '../../../../finance/store/finance.reducer';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder} from '@angular/forms';
import {NotificationsService} from 'angular2-notifications';
import {ReconciliationsService} from '../../../services/reconciliations.service';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ILedgerAccount, IReconciliation, ITransaction} from '@cyberco-nodejs/zipi-typings';
import {takeUntil} from 'rxjs/operators';
import {LedgerAccountService} from '../../../../../services/api/finance/ledger-accounts.service';

@Component({
    selector: 'app-reconciliation-page',
    styleUrls: ['../../banking.component.scss'],
    templateUrl: 'reconciliation-page.component.html'
})
export class ReconciliationPageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ['created_at', 'contact', 'type', 'description', 'deposits', 'withdrawals'];

    reconciliation: IReconciliation | undefined;
    ledgerAccountId: number | undefined;
    ledgerAccount: ILedgerAccount | undefined;
    reconciliationId: number | undefined;

    constructor(
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService,
        private store: Store<IFinanceState>,
        private route: ActivatedRoute,
        private router: Router,
        private reconciliationsService: ReconciliationsService,
        private ledgerAccountService: LedgerAccountService,
        public dialog: MatDialog
    ) {
        this.dataSource = new MatTableDataSource<any>([]);
        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['id']) {
                this.ledgerAccountId = Number(params['id']);
            }
            if (params['reconciliation-id']) {
                this.reconciliationId = Number(params['reconciliation-id']);
            }
        });
    }

    ngOnInit() {
        this.ledgerAccountService
            .getLedgerAccountById(this.ledgerAccountId as number)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((la) => {
                if (la) {
                    this.ledgerAccount = la;
                }
            });
        this.reconciliationsService
            .getAccountReconciliationById(this.reconciliationId as number)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                if (data) {
                    this.reconciliation = data;

                    const reconciliationTransactions: ITransaction[] = data.transactions ? data.transactions : [];

                    this.dataSource.data = reconciliationTransactions;
                }
            });
    }

    back() {
        this.router.navigate(['/banking', this.ledgerAccountId, 'transactions'], {
            queryParams: {tab: 'reconciliations'}
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
