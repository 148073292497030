import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'app-company-compensation-toolbar',
    styles: [
        `
            .toolbar-card {
                margin-bottom: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
            }
            .subcontainer {
                flex-direction: row;
                display: flex;
                align-items: center;
                height: 64px;
                align-self: flex-start;
            }
            .mat-card {
                border: 1px lightgrey solid;
            }
        `
    ],
    template: `
        <mat-card class="toolbar-card">
            <div class="subcontainer">
                <div
                    style="justify-content: flex-start; margin-right: 16px; margin-bottom: 8px; display: flex; flex-direction: column; align-items: flex-end"
                >
                    <mat-button-toggle-group
                        id="toggle-button-fix"
                        style="height: 36px"
                        (change)="tabChanged.emit({tabId: $event.value})"
                        [(ngModel)]="show"
                    >
                        <mat-button-toggle style="height: 36px" [value]="0"> All </mat-button-toggle>
                        <mat-button-toggle style="height: 36px" [value]="1"> Groups </mat-button-toggle>
                        <mat-button-toggle style="height: 36px" [value]="2"> Entities </mat-button-toggle>
                    </mat-button-toggle-group>
                    <div style="margin-top: 2px;height: 28px;line-height: 20px;font-size: 12px;">
                        <span style="margin-right: 8px">Display Inactive</span>
                        <mat-button-toggle-group id="toggle-button-fix2" style="height: 28px" [formControl]="drafts">
                            <mat-button-toggle style="height: 28px" [value]="true"> Yes </mat-button-toggle>
                            <mat-button-toggle style="height: 28px" [value]="false"> No </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
            </div>
            <div class="subcontainer">
                <ng-container *ngIf="show === 0">
                    <mat-form-field style="margin-left: 8px; margin-top: 32px">
                        <mat-select placeholder="Order" [formControl]="sortBy">
                            <mat-option [value]="'alpha_asc'">Alphabetical(A>Z)</mat-option>
                            <mat-option [value]="'order_desc'">Profile Priority</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="show === 1">
                    <mat-form-field style="margin-left: 8px; margin-top: 32px">
                        <mat-select placeholder="Order" [formControl]="sortBy">
                            <mat-option [value]="'alpha_asc'">Alphabetical(A>Z)</mat-option>
                            <mat-option [value]="'order_desc'">Profile Priority</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="show === 2">
                    <mat-form-field style="margin-left: 8px; margin-top: 32px">
                        <mat-select placeholder="Order" [formControl]="sortBy">
                            <mat-option [value]="'alpha_asc'">Alphabetical(A>Z)</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>
        </mat-card>
    `
})
export class ToolbarComponent {
    @Input() public show: number = 0;
    @Input() public order: string = 'alpha_asc';
    @Input() public drafts: UntypedFormControl = new UntypedFormControl(false);
    @Input() public sortBy: UntypedFormControl = new UntypedFormControl('order_desc');
    @Output() public tabChanged: EventEmitter<{tabId: number}> = new EventEmitter<{tabId: number}>();
}
