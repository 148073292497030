import {Calculation, ListItem, TeamMemberCalculation} from './calculation';
import {Profile} from './profile';
import {Note} from './note';
import {SalesEntity} from './sales-entity';
import {CompanyCalculation} from '../modules/shared/components/calculations/calculations.model';
import {GenericEntity} from '../entites/generic.entity';
import {CommissionCategorizationModel} from '../modules/account-info/compensation/models/commission-categorization.model';
import {ICustomDealField, ICustomDealFieldValue, IDealParticipants} from 'typings';
import {DisbursementText} from './disbursement-text';
import {FinancialTransferEntity} from '../modules/account-info/compensation/models/financial-transfer.entity';
import {Company} from './company';
import * as MomentTimezone from 'moment-timezone';
import {DealAffectedBy} from './deal-affected-by';
import {DealSystemNote} from './deal-system-note';
import {DealSelfTestingEntity} from './deal-self-testing';

export enum DEAL_SYSTEM_STATUS {
    draft = 'draft',
    open = 'open',
    approved = 'approved'
}

export enum DEAL_DISBURSEMENT_STATUS {
    incomplete_deal = 'Incomplete Deal',
    pending_approval = 'Pending Approval',
    pending_remaining_payout = 'Pending Remaining Payouts',
    pending_payment_confirmation = 'Pending Payment Confirmation',
    payments_processed = 'Payments Processed'
}

export class Deal extends GenericEntity {
    public static provideStatuses(): string[] {
        return ['opportunity', 'active', 'pending', 'closed', 'processed', 'cancelled'];
    }

    public static provideTypes() {
        return ['buyer', 'seller', 'rental_tenant', 'rental_owner', 'referral', 'broker_price_opinion'];
    }

    public static provideDisbursementStatuses() {
        return [
            'incomplete_deal',
            'pending_approval',
            'pending_remaining_payout',
            'pending_payment_confirmation',
            'payments_processed'
        ];
    }

    public static providePropertyClasses(): string[] {
        return [Deal.property_class_SET.residential, Deal.property_class_SET.commercial];
    }

    public static provideStates() {
        return [
            'AL',
            'AK',
            'AZ',
            'AR',
            'CA',
            'CO',
            'CT',
            'DE',
            'FL',
            'GA',
            'HI',
            'ID',
            'IL',
            'IN',
            'IA',
            'KS',
            'KY',
            'LA',
            'ME',
            'MD',
            'MA',
            'MI',
            'MN',
            'MS',
            'MO',
            'MT',
            'NE',
            'NV',
            'NH',
            'NJ',
            'NM',
            'NY',
            'NC',
            'ND',
            'OH',
            'OK',
            'OR',
            'PA',
            'RI',
            'SC',
            'SD',
            'TN',
            'TX',
            'UT',
            'VT',
            'VA',
            'WA',
            'WV',
            'WI',
            'WY'
        ];
    }

    public static dualSameFields() {
        return ['address', 'city', 'zip', 'state', 'image_url', 'sales_price', 'close_of_escrow', 'status'];
    }

    public static get required_fields() {
        return ['name', 'sales_price', 'close_of_escrow', 'type'];
    }

    /**
     * @deprecated - use "deal_id" instead
     */
    id: number | null = null;

    deal_id: number | null = null;
    creator__profile_fk_id?: number | null = null;
    creator__company_fk_id?: number | null = null;
    division__company_group_fk_id: number | null = null;
    source_of_business: string | null = null;
    source_other: string | null = null;
    address: string | null = null;
    apply_rules: boolean = true;
    sales_price = 0;
    sales_price_adjustment = 0;
    sales_price_adjustment_description = null;
    image_url: string | null = null;
    city: string | null = null;
    zip: string | null = null;
    state: string | null = null;
    status: string = 'opportunity';
    type: 'buyer' | 'seller' | 'rental_tenant' | 'rental_owner' | 'referral' | 'broker_price_opinion' | null = null;
    dual_deal_id: number | null = null;
    clients: object[] = [];
    notes: Note[] = [];
    system_notes: DealSystemNote[] = [];
    close_of_escrow: Date | null = null;
    first_contacted: Date | null = null;
    last_contacted: Date | null = null;
    is_archived: boolean = false;
    is_historical_imported: boolean = false;
    disbursement_approved: boolean = false;
    disbursement_approved_by: Profile | null = null;
    disbursement_approved_timestamp: Date | null = null;
    disbursement_approve_requested: boolean = false;
    disbursement_approve_requested_by: Profile | null = null;
    disbursement_approve_requested_timestamp: Date | null = null;
    disbursement_text: DisbursementText = new DisbursementText();
    disbursement_text_template: DisbursementText = new DisbursementText();
    disbursement_status: keyof typeof DEAL_DISBURSEMENT_STATUS | null = null;
    sort_key: number | null = null;

    external_deal_url: string | null = null;
    deal_participants: IDealParticipants = {
        buyer_agents: null,
        seller_agents: null,
        escrow_agent: null,
        buyers: null,
        sellers: null,
        referring_from_agent: null,
        referring_to_agent: null,
        others: null,
        commission_payer: null
    };

    is_escrow_paid = false;
    archived_at: Date | null = null;

    income_commission_value = 0;
    income_flat_commission_value = 0;
    base_commission_margin_value = 0;
    flat_fee_commissions: ListItem[] = [];
    income_commission_type: 'percents' | 'flat_fee' = 'percents';

    referral_fee_value = 0;
    referral_fee_type: 'percents' | 'flat_fee' = 'percents';

    financial_transfers: FinancialTransferEntity[] = [];
    /**
     * @deprecated - use "financial_transfers" instead
     */
    payouts: FinancialTransferEntity[] = [];
    /**
     * @deprecated - use "financial_transfers" instead
     */
    summary_payouts: FinancialTransferEntity[] = [];
    /**
     * @deprecated - use "financial_transfers" instead
     */
    escrow_payouts: FinancialTransferEntity[] = [];
    /**
     * @deprecated - use "financial_transfers" instead
     */
    remaining_payouts: FinancialTransferEntity[] = [];
    /**
     * @deprecated - use "financial_transfers" instead
     */
    deduction_payouts: FinancialTransferEntity[] = [];

    sales_entities: SalesEntity[] = [];
    primary_sales_entity: SalesEntity = new SalesEntity();

    /**
     * @deprecated - discuss with AntonV if you think it is needed
     */
    calculation?: Calculation | null = null;
    team_members_calculations?: TeamMemberCalculation[] = [];
    user_calculation?: Calculation = new Calculation();
    summary_calculation?: Calculation | null = null;
    profile?: Profile = new Profile();
    affected_by: DealAffectedBy[] = [];

    // additional fields

    contract_date: Date | null = null;
    original_price: number | null = null;
    expiration_date: Date | null = null;
    listing_date: Date | null = null;
    acceptance_date: Date | null = null;
    mls_id: string | null = null;
    mls_organisation_fk_id: string | null = null;
    escrow_number: string | null = null;

    sub_type: string | null = null;
    sub_source: string | null = null;

    timezone: string | null = null;
    property_class: string = Deal.property_class_SET.residential;

    company: Company | null = null;

    company_calculation: CompanyCalculation = new CompanyCalculation();
    commission_categorization_template: CommissionCategorizationModel = new CommissionCategorizationModel();
    client_type: string | null = null;

    street_number: string | null = null;
    unit: string | null = null;
    country: string | null = null;

    name: string | null = null;
    system_status: DEAL_SYSTEM_STATUS = DEAL_SYSTEM_STATUS.draft;

    custom_deal_field_values: ICustomDealFieldValue[] = [];
    custom_deal_fields?: ICustomDealField[] = [];
    taggings?: any[] = [];

    deal_template_id?: number;

    dual_deal: Deal | null = null;
    self_testing: DealSelfTestingEntity | null = null;

    ui_mods: any[] = [];

    office_lead: boolean | null = null;

    static get helpers() {
        return Object.assign(
            {
                props: Object.getOwnPropertyNames(new this()).reduce((acc: {[key: string]: any}, curr) => {
                    acc[curr] = curr;
                    return acc;
                }, {}),
                build: {
                    profile: (val: any) => Object.assign(new Profile(), val),
                    sales_entities: (val: any[], fabric: any) =>
                        val.map((item) => (this.FABRIC as any)(SalesEntity)[fabric](item)),
                    financial_transfers: (val: any[], fabric: any) =>
                        val.map((item) => (this.FABRIC as any)(FinancialTransferEntity)[fabric](item)),
                    payouts: (val: any[], fabric: any) =>
                        val.map((item) => (this.FABRIC as any)(FinancialTransferEntity)[fabric](item)),
                    summary_payouts: (val: any[], fabric: any) =>
                        val.map((item) => (this.FABRIC as any)(FinancialTransferEntity)[fabric](item)),
                    escrow_payouts: (val: any[], fabric: any) =>
                        val.map((item) => (this.FABRIC as any)(FinancialTransferEntity)[fabric](item)),
                    remaining_payouts: (val: any[], fabric: any) =>
                        val.map((item) => (this.FABRIC as any)(FinancialTransferEntity)[fabric](item))
                }
            },
            GenericEntity.helpers
        );
    }

    public static get status_SET() {
        return {
            opportunity: 'opportunity',
            active: 'active',
            pending: 'pending',
            closed: 'closed',
            processed: 'processed',
            cancelled: 'cancelled'
        };
    }

    public static get status_LABELS(): {[key: string]: string} {
        return {
            opportunity: 'Opportunity',
            active: 'Active',
            pending: 'Pending',
            closed: 'Closed',
            processed: 'Processed',
            cancelled: 'Cancelled'
        };
    }

    public static get type_SET(): {[key: string]: string} {
        return {
            seller: 'seller',
            buyer: 'buyer',
            rental_owner: 'rental_owner',
            rental_tenant: 'rental_tenant',
            referral: 'referral',
            broker_price_opinion: 'broker_price_opinion'
        };
    }

    public static get type_LABELS(): {[key: string]: string} {
        return {
            seller: 'Listing (sale)',
            buyer: 'Buyer (sale)',
            rental_tenant: 'Tenant (rental)',
            rental_owner: 'Landlord (rental)',
            referral: 'Referral',
            broker_price_opinion: 'Broker Price Opinion'
        };
    }

    public static get disbursement_statuses_SET(): {[key: string]: string} {
        return {
            incomplete_deal: 'incomplete_deal',
            pending_approval: 'pending_approval',
            pending_remaining_payout: 'pending_remaining_payout',
            pending_payment_confirmation: 'pending_payment_confirmation',
            payments_processed: 'payments_processed'
        };
    }

    public static get disbursement_statuses_LABELS(): {[key: string]: string} {
        return {
            incomplete_deal: 'Incomplete Deal',
            pending_approval: 'Pending Approval',
            pending_remaining_payout: 'Pending Remaining Payouts',
            pending_payment_confirmation: 'Pending Payment Confirmation',
            payments_processed: 'Payments Processed'
        };
    }

    public static get timezone_SET() {
        return {
            'Pacific/Pago_Pago': 'Pacific/Pago_Pago',
            'Pacific/Honolulu': 'Pacific/Honolulu',
            'America/Adak': 'America/Adak',
            'America/Anchorage': 'America/Anchorage',
            'America/Los_Angeles': 'America/Los_Angeles',
            'America/Denver': 'America/Denver',
            'America/Chicago': 'America/Chicago',
            'America/New_York': 'America/New_York',
            'America/Buenos_Aires': 'America/Buenos_Aires',
            'America/Sao_Paulo': 'America/Sao_Paulo',
            'Atlantic/Cape_Verde': 'Atlantic/Cape_Verde',
            'Atlantic/Reykjavik': 'Atlantic/Reykjavik',
            'Europe/London': 'Europe/London',
            'Europe/Berlin': 'Europe/Berlin',
            'Europe/Kiev': 'Europe/Kiev',
            'Asia/Dubai': 'Asia/Dubai',
            'Asia/Ashgabat': 'Asia/Ashgabat',
            'Asia/Urumqi': 'Asia/Urumqi',
            'Asia/Bangkok': 'Asia/Bangkok',
            'Asia/Shanghai': 'Asia/Shanghai',
            'Asia/Tokyo': 'Asia/Tokyo',
            'Australia/Brisbane': 'Australia/Brisbane',
            'Australia/Sydney': 'Australia/Sydney',
            'Asia/Kamchatka': 'Asia/Kamchatka'
        };
    }

    public static getTimezoneLabel(timezone: string) {
        const mtz = MomentTimezone.tz(timezone);
        return `(${mtz.format('Z')}) ${timezone} ${mtz.format('z')}`;
    }

    public static get deal_client_type_SET() {
        return {
            buyer: 'buyer',
            seller: 'seller'
        };
    }

    static get property_class_SET() {
        return {
            residential: 'residential',
            commercial: 'commercial'
        };
    }

    static get property_class_LABELS(): {[key: string]: string} {
        return {
            residential: 'Residential',
            commercial: 'Commercial'
        };
    }

    public static get custom_deal_field_LABELS() {
        return {
            date: 'date',
            time: 'time',
            datetime: 'datetime',
            float: 'float',
            number: 'number',
            text: 'text',
            select: 'select',
            boolean: 'boolean'
        };
    }

    public static get closing_date_LABELS() {
        return {
            all_dated: 'All Dates',
            custom: 'Custom'
        };
    }

    public static getOppositeDealType(dealType: string) {
        switch (dealType) {
            case Deal.type_SET.seller:
                return Deal.type_SET.buyer;
            case Deal.type_SET.buyer:
                return Deal.type_SET.seller;
            case Deal.type_SET.rental_owner:
                return Deal.type_SET.rental_tenant;
            case Deal.type_SET.rental_tenant:
                return Deal.type_SET.rental_owner;
            default:
                return null;
        }
    }
}
