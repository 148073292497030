import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Deal} from '../../../../../../../models/deal';
import {SalesEntity} from '../../../../../../../models/sales-entity';
import {SessionService} from '../../../../../../../services/session.service';

@Component({
    selector: 'app-view-disbursements-deal',
    styleUrls: ['../view-deal.component.scss'],
    templateUrl: 'disbursements-deal.component.html'
})
export class ViewDisbursementsDealComponent implements OnInit, OnDestroy, OnChanges {
    @Input() deal: Deal | undefined = undefined;
    public SALES_ENTITY = SalesEntity;

    public collapsed = true;

    dealFields: {name: string; value: number}[] = [];
    companyFields: {name: string; value: number}[] = [];
    agentFields: {
        name: string;
        value:
            | 'percent_of_deal'
            | 'compensation'
            | 'sales_split'
            | 'sales_net'
            | 'sales_incomes_sum'
            | 'sales_expenses_sum'
            | 'agent_split'
            | 'team_split'
            | 'participant_royalty'
            | 'after_step_1'
            | 'additional'
            | 'additional_incomes_sum'
            | 'additional_expenses_sum'
            | 'referral_expenses_sum'
            | 'agent_split_after_referral'
            | 'after_step_2'
            | 'taxes'
            | 'biz_exp'
            | 'overhead'
            | 'incentive'
            | 'after_step_3'
            | 'net'
            | 'budget';
    }[] = [];
    transferFields: {
        name: string;
        value: 'additional_incomes_sum' | 'referral_incomes_sum' | 'additional_expenses_sum' | 'net';
    }[] = [];

    constructor(public sessionService: SessionService) {}

    ngOnInit(): void {
        if (typeof this.deal !== 'undefined') {
            this.dealFields = [
                {name: 'Base Commission', value: this.deal.company_calculation.base_commission.amount},
                {name: 'Additional Commission', value: this.deal.company_calculation.commission_incomes_sum.amount},
                {name: 'Commission Expense', value: this.deal.company_calculation.commission_expenses_sum.amount},
                {name: 'Gross Commission', value: this.deal.company_calculation.commission.amount},
                {name: 'Referral', value: this.deal.company_calculation.referral.amount},
                {name: 'Adjusted Gross', value: this.deal.company_calculation.commission_after_referral.amount},
                {name: 'Royalty', value: this.deal.company_calculation.royalty.amount},
                {name: 'Net Commission', value: this.deal.company_calculation.net_commission.amount},
                {name: 'Deal Income', value: this.deal.company_calculation.deal_incomes_sum.amount},
                {name: 'Deal Expense', value: this.deal.company_calculation.deal_expenses_sum.amount},
                {name: 'Deal Net', value: this.deal.company_calculation.deal_net.amount},
                {name: 'Sales Total', value: this.deal.company_calculation.sales_total.amount},
                {name: 'Sales Income', value: this.deal.company_calculation.sales_incomes_sum.amount},
                {name: 'Sales Expense', value: this.deal.company_calculation.sales_expenses_sum.amount},
                {name: 'Sales Net', value: this.deal.company_calculation.sales_net.amount}
            ];

            this.companyFields = [
                {name: 'Company Split', value: this.deal.company_calculation.company_split.amount},
                {name: 'Company Income', value: this.deal.company_calculation.company_incomes_sum.amount},
                {name: 'Company Expense', value: this.deal.company_calculation.company_expenses_sum.amount},
                {name: 'Company Net', value: this.deal.company_calculation.company_net.amount}
            ];
        }

        this.agentFields = [
            {name: 'Split', value: 'agent_split'},
            {name: 'Referral', value: 'referral_expenses_sum'},
            {name: 'Royalty', value: 'participant_royalty'},
            {name: 'Incomes', value: 'additional_incomes_sum'},
            {name: 'Expenses', value: 'additional_expenses_sum'},
            {name: 'NET', value: 'net'},
            {name: 'Taxes', value: 'taxes'},
            {name: 'Incentive', value: 'incentive'},
            {name: 'Overhead', value: 'overhead'},
            {name: 'Budget', value: 'budget'}
        ];

        this.transferFields = [
            {name: 'Sales Net', value: 'net'},
            {name: 'Referral incomes', value: 'referral_incomes_sum'},
            {name: 'Incomes', value: 'additional_incomes_sum'},
            {name: 'Expenses', value: 'additional_expenses_sum'}
        ];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.deal.currentValue) {
            this.ngOnInit();
        }
    }

    ngOnDestroy(): void {}
}
