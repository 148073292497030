import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {
    IBill,
    IContact,
    IDealDepositRelease,
    IDealDepositRequest,
    IFinancialTransfer,
    IInvoice,
    ILedgerAccount,
    IPayment,
    IPaymentGateway,
    IPaymentMethod,
    IPaymentReceivedMadeRequestObject
} from '@cyberco-nodejs/zipi-typings';
import * as moment from 'moment';
import {DealProcessingService} from '../../../../deal-processing.service';
import {CurrentProfileSource} from '../../../../../../../../services/sources/current-profile.source';
import {PAYMENT_MODES} from '../../../../../../../../local-typings';
import {CompanyGatewayService} from '../../../../../../../../services/api/finance/company-gateway.service';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {ShipperContactsService} from '../../../../../../../../services/api/shipper.contacts.service';
import {SessionService} from '../../../../../../../../services/session.service';
import {Profile} from '../../../../../../../../models/profile';
import {FinancialTransferEntity} from '../../../../../../../account-info/compensation/models/financial-transfer.entity';
import {FormGroupWithFormControls} from '../../../../../../../../typings/common';
import Decimal from 'decimal.js-light';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../../../../utilities/maskito';

@Component({
    selector: 'app-create-release-dialog',
    templateUrl: './create-release-dialog.component.html',
    styleUrls: ['./create-release-dialog.component.scss']
})
export class CreateReleaseDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    requestsList: IDealDepositRequest[] = [];
    releaseList: IDealDepositRelease[] = [];
    requestsContactList: {
        contact: IContact | undefined;
        availableAmount: number;
        ledgers: ILedgerAccount[];
        payments: IPayment[];
    }[] = [];
    selectedPayer:
        | {contact: IContact | undefined; availableAmount: number; ledgers: ILedgerAccount[]; payments: IPayment[]}
        | null
        | undefined = null;
    dealContacts: IContact[] = [];
    brokerContact: IContact | null = null;
    commissionPayerContact: IContact | null = null;
    selectedDealContact: IContact | null | undefined = null;

    dealPayouts: IFinancialTransfer[] = [];

    availableBills: IBill[] = [];
    isPayoutExist: boolean = false;
    selectedBills: UntypedFormControl;

    availableLedgerList: ILedgerAccount[];

    ledgerBalance: number | null = null;

    releaseForm: FormGroupWithFormControls = new UntypedFormGroup({}) as FormGroupWithFormControls;
    paymentForm: FormGroupWithFormControls = new UntypedFormGroup({}) as FormGroupWithFormControls;

    escrowTransfers: FinancialTransferEntity[] = [];
    remainingTransfers: FinancialTransferEntity[] = [];
    availableForRelease: number;
    availableForEntity: number | null;
    selectedTransfer: IFinancialTransfer | null = null;

    currentProfile: Profile | null = null;

    currencyMaskitoMask = currencyMaskitoOptions;

    isZipiFinancialAvailableForContact: boolean;
    isZipiFinancialAvailableForLedger: boolean;
    gateways: IPaymentGateway[] = [];

    paymentModes = PAYMENT_MODES;

    dealAddress: string | null = null;

    currentTabIndex: number;
    currentAmountForDisplay: number | null = null;

    constructor(
        private fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<CreateReleaseDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dealProcessingService: DealProcessingService,
        protected currentProfileSource: CurrentProfileSource,
        private companyGatewayService: CompanyGatewayService,
        private contactsService: ShipperContactsService,
        private sessionService: SessionService
    ) {
        this.requestsContactList = [];
        this.availableLedgerList = [];
        this.availableForRelease = 0;
        this.availableForEntity = 0;
        this.isZipiFinancialAvailableForContact = false;
        this.isZipiFinancialAvailableForLedger = false;
        this.selectedBills = this.fb.control('default_option', []);
        this.currentTabIndex = 0;
    }

    ngOnInit() {
        this.requestsList = this.data.requestsList;
        this.releaseList = this.data.releaseList;
        this.dealPayouts = this.data.dealPayouts;
        this.dealAddress = this.data.dealAddress;
        const dealContacts: {
            dealContactsIds: Array<number>;
            brokerContactId: number | undefined;
            commissionPayerContactId: number;
        } = this.data.dealContacts;
        const brokerId = this.sessionService.profile!.company!.main__contact_fk_id;

        dealContacts.dealContactsIds.push(brokerId!);
        dealContacts.brokerContactId = brokerId;

        this.contactsService
            .getContactsList(dealContacts.dealContactsIds)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((contacts) => {
                if (contacts) {
                    for (const contact of contacts) {
                        if (contact.contact_id === dealContacts.brokerContactId) {
                            this.brokerContact = contact;
                        } else if (contact.contact_id === dealContacts.commissionPayerContactId) {
                            this.commissionPayerContact = contact;
                        } else {
                            this.dealContacts.push(contact);
                        }
                    }
                }
            });
        this.currentProfileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe((profile) => {
            if (profile.company) {
                this.currentProfile = profile;
            }
        });
        const rawEscrowPayouts = this.dealProcessingService.dealFG.getRawValue().escrow_payouts;

        this.escrowTransfers = rawEscrowPayouts.map((transfer) => {
            if (!transfer.sender && transfer.sender_wildcard_id === 3) {
                transfer.sender = this.dealProcessingService.dealFG.getRawValue().deal_participants.escrow_agent;
            }
            return transfer;
        });

        this.remainingTransfers = this.dealProcessingService.dealFG.getRawValue().remaining_payouts;

        this.formsSetup();

        this.buildRequestContactsList();

        this.companyGatewayService
            .getCompanyGatewaysByTypes(['zipi_financial_trust'], null)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((gateways) => {
                this.gateways = gateways;
                this._isPaymentMethodExistLedger(this.releaseForm.get('release_from__ledger_account_fk_id')!.value);
            });
    }

    formsSetup() {
        this.releaseForm = this.fb.group({
            financial_transfer_fk_id: [null, []],
            amount: [0, [Validators.required]],
            money_sender__contact_fk_id: [null, []],
            money_receiver__contact_fk_id: [null, [Validators.required]],
            paid_date: [moment().format('YYYYMMDD'), [Validators.required]],
            release_from__ledger_account_fk_id: [null, [Validators.required]],
            ledger_account_fk_id: [null, []],
            request_payer__contact_fk_id: [null, [Validators.required]]
        }) as FormGroupWithFormControls;
        this.paymentForm = this.fb.group({
            money_receiver__contact_fk_id: [null, []],
            paid_by__ledger_account_fk_id: [null, []],
            paid_by__payment_method_fk_id: [null, []],
            pay_to__payment_method_fk_id: [null, []],
            payment_mode: ['zipi_financial', []],
            reference: [null, []],
            check_info: [null, []],
            memo: [this.dealAddress, []],
            check_number: [null, []]
        }) as FormGroupWithFormControls;
        this.releaseForm
            .get('request_payer__contact_fk_id')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((change) => {
                this.selectedPayer = this.requestsContactList.find(
                    (contactItem) => contactItem.contact!.contact_id === change
                );
                if (this.selectedPayer && this.selectedPayer.ledgers) {
                    this.availableLedgerList = this.selectedPayer.ledgers;
                }
                if (this.availableLedgerList.length === 1) {
                    this.releaseForm
                        .get('release_from__ledger_account_fk_id')!
                        .setValue(this.availableLedgerList[0].ledger_account_id);
                    this.paymentForm
                        .get('paid_by__ledger_account_fk_id')!
                        .setValue(this.availableLedgerList[0].ledger_account_id);
                }
            });
        this.releaseForm
            .get('money_receiver__contact_fk_id')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((change) => {
                this.selectedDealContact = this.dealContacts.find((contact) => contact.contact_id === change);
                if (!this.selectedDealContact) {
                    if (change === this.brokerContact!.contact_id) {
                        this.selectedDealContact = this.brokerContact;
                    }
                }
                if (!this.selectedDealContact) {
                    if (change === this.commissionPayerContact!.contact_id) {
                        this.selectedDealContact = this.commissionPayerContact;
                    }
                }
                if (this.selectedDealContact) {
                    this.checkIsZipiFinancialAvailableForContact(this.selectedDealContact!.contact_id!);
                    this.selectAvailableBills();
                }
            });

        this.releaseForm
            .get('release_from__ledger_account_fk_id')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((change) => {
                this.calculateLedgerBalance();
                this._isPaymentMethodExistLedger(change);
            });
        this.releaseForm
            .get('amount')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((change) => {
                this.currentAmountForDisplay = Number(cleanCurrencyString(change));
            });
        this.paymentForm
            .get('payment_mode')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((change) => {
                this.paymentForm.get('check_number')!.setValue(null);
            });
    }

    checkIsZipiFinancialAvailableForContact(contactId: number) {
        this.companyGatewayService
            .isContactAvailableToReceiveByPaymentMode(contactId, 'zipi_financial')
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                this.isZipiFinancialAvailableForContact =
                    response.zipi_financial_business || response.zipi_financial_receive_only;
            });
    }

    selectAvailableBills() {
        this.availableBills = [];
        this.selectedBills.setValue('default_option');
        this.isPayoutExist = false;
        if (this.dealPayouts && this.dealPayouts.length > 0) {
            for (const payout of this.dealPayouts) {
                if (payout.receiver && payout.receiver.contact_id === this.selectedDealContact!.contact_id) {
                    this.isPayoutExist = true;
                    if (payout.connected__bill_fk_id) {
                        this.availableBills.push(payout.connected_bill!);
                    }
                }
            }
        }
    }

    buildRequestContactsList() {
        if (this.requestsList && this.requestsList.length > 0) {
            const uniqueContacts: {
                [id: number]: {
                    contact: IContact | undefined;
                    availableAmount: number;
                    ledgers: ILedgerAccount[];
                    payments: IPayment[];
                };
            } = {};
            const uniqueLedgerAccounts: {[id: number]: {[id: number]: ILedgerAccount}} = {};

            for (const request of this.requestsList) {
                if (!uniqueContacts.hasOwnProperty(request.request_receiver__contact_fk_id)) {
                    const amountDecimal: Decimal = request.payments!.reduce(
                        (acc, pay) => acc.add(pay.amount),
                        new Decimal(0)
                    );
                    uniqueContacts[request.request_receiver__contact_fk_id] = {
                        contact: request.request_receiver_contact,
                        availableAmount: amountDecimal.toDecimalPlaces(2).toNumber(),
                        ledgers: [],
                        payments: []
                    };
                } else {
                    const amountDecimal: Decimal = request.payments!.reduce(
                        (acc, pay) => acc.add(pay.amount),
                        new Decimal(0)
                    );
                    uniqueContacts[request.request_receiver__contact_fk_id].availableAmount = amountDecimal
                        .add(uniqueContacts[request.request_receiver__contact_fk_id].availableAmount)
                        .toDecimalPlaces(2)
                        .toNumber();
                }
                if (request.payments && request.payments.length > 0) {
                    request.payments.forEach((pay) => {
                        uniqueContacts[request.request_receiver__contact_fk_id].payments.push(pay);

                        if (
                            pay.source_payment_received!.pay_to__ledger_account_fk_id &&
                            pay.source_payment_received!.pay_to_ledger_account
                        ) {
                            if (!uniqueLedgerAccounts.hasOwnProperty(request.request_receiver__contact_fk_id)) {
                                uniqueLedgerAccounts[request.request_receiver__contact_fk_id] = {
                                    [pay.source_payment_received!.pay_to__ledger_account_fk_id]:
                                        pay.source_payment_received!.pay_to_ledger_account
                                };
                            } else {
                                uniqueLedgerAccounts[request.request_receiver__contact_fk_id][
                                    pay.source_payment_received!.pay_to__ledger_account_fk_id
                                ] = pay.source_payment_received!.pay_to_ledger_account;
                            }
                        }
                    });
                }
            }
            for (const contactId in uniqueContacts) {
                if (uniqueContacts.hasOwnProperty(contactId)) {
                    let releasesAmount: Decimal = new Decimal(0);
                    const contactReleases = this.releaseList.filter(
                        (release) => release.request_payer__contact_fk_id === Number(contactId)
                    );
                    if (contactReleases && contactReleases.length > 0) {
                        releasesAmount = contactReleases.reduce(
                            (acc, rel) => acc.add(rel.release_amount),
                            new Decimal(0)
                        );
                    }
                    uniqueContacts[contactId].availableAmount = new Decimal(uniqueContacts[contactId].availableAmount)
                        .sub(releasesAmount)
                        .toDecimalPlaces(2)
                        .toNumber();
                    for (const accountId in uniqueLedgerAccounts[contactId]) {
                        if (uniqueLedgerAccounts[contactId].hasOwnProperty(accountId)) {
                            uniqueContacts[contactId].ledgers.push(uniqueLedgerAccounts[contactId][accountId]);
                        }
                    }
                    this.requestsContactList.push(uniqueContacts[contactId]);
                }
            }
        }

        if (this.requestsContactList.length === 1) {
            this.releaseForm
                .get('request_payer__contact_fk_id')!
                .setValue(this.requestsContactList[0].contact!.contact_id);
        }
    }

    _isPaymentMethodExistLedger(ledgerId: number) {
        if (this.gateways) {
            let currentMethodForLedger: IPaymentMethod | null = null;
            for (const gateway of this.gateways) {
                if (gateway.payment_methods && gateway.payment_methods.length > 0) {
                    for (const method of gateway.payment_methods) {
                        if (method.related__ledger_account_fk_id === ledgerId) {
                            currentMethodForLedger = method;
                        }
                    }
                }
            }

            if (currentMethodForLedger) {
                this.isZipiFinancialAvailableForLedger = true;
                this.paymentForm
                    .get('paid_by__payment_method_fk_id')!
                    .setValue(currentMethodForLedger.payment_method_id);
                this.paymentForm.get('paid_by__payment_method_fk_id')!.disable();
            } else {
                this.isZipiFinancialAvailableForLedger = false;
                this.paymentForm.get('paid_by__payment_method_fk_id')!.setValue(null);
                this.paymentForm.get('paid_by__payment_method_fk_id')!.disable();
            }
            this.paymentForm.get('paid_by__payment_method_fk_id')!.setValidators([Validators.email]);
            this.paymentForm.get('paid_by__payment_method_fk_id')!.updateValueAndValidity();
            this.paymentForm.get('paid_by__payment_method_fk_id')!.markAsTouched();
        }
    }

    calculateLedgerBalance() {
        const releases = this.releaseList.filter((release) => {
            return (
                release.request_payer__contact_fk_id === this.releaseForm.get('request_payer__contact_fk_id')!.value &&
                release.release_from__ledger_account_fk_id ===
                    this.releaseForm.get('release_from__ledger_account_fk_id')!.value
            );
        });
        const currentContact = this.requestsContactList.find(
            (contactItem) =>
                contactItem.contact!.contact_id === this.releaseForm.get('request_payer__contact_fk_id')!.value
        );
        if (!currentContact) {
            return;
        }
        const payments = currentContact.payments.filter((pay) => {
            return (
                pay.source_payment_received!.pay_to__ledger_account_fk_id ===
                    this.releaseForm.get('release_from__ledger_account_fk_id')!.value && pay.status === 'completed'
            );
        });

        const paymentsAmountDecimal: Decimal = payments.reduce((acc, pay) => acc.add(pay.amount), new Decimal(0));
        const releasesAmountDecimal: Decimal = releases.reduce(
            (acc, rel) => acc.add(rel.release_amount),
            new Decimal(0)
        );

        this.ledgerBalance = paymentsAmountDecimal.sub(releasesAmountDecimal).toDecimalPlaces(2).toNumber();
        this.releaseForm.get('amount')!.setValue(this.ledgerBalance);
        this.validateAmount({target: {value: this.releaseForm.get('amount')!.value}});
    }

    setAvailableAmount(availableAmount: number, isUserInput: boolean) {
        if (isUserInput) {
            this.availableForRelease = availableAmount;
            this.validateAmount({target: {value: this.releaseForm.get('amount')!.value}});
        }
    }

    validateAmount($event: any) {
        const inputValue = Number(cleanCurrencyString($event.target.value));
        if (inputValue > this.ledgerBalance! || inputValue === 0) {
            this.releaseForm.get('amount')!.setValidators([Validators.email]);
            this.releaseForm.get('amount')!.updateValueAndValidity();
            this.releaseForm.get('amount')!.markAsTouched();
            // this.payDataForm.markAllAsTouched();
        } else {
            this.releaseForm.get('amount')!.clearValidators();
            this.releaseForm.get('amount')!.updateValueAndValidity();
            // this.payDataForm.markAllAsTouched();
        }
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent) {
        this.currentTabIndex = tabChangeEvent.index;
        switch (tabChangeEvent.index) {
            case 0:
                this.paymentForm.get('payment_mode')!.setValue('zipi_financial');
                this.releaseForm.get('paid_date')!.setValue(moment().format('YYYYMMDD'));
                this._isPaymentMethodExistLedger(this.releaseForm.get('release_from__ledger_account_fk_id')!.value);
                break;
            case 1:
                this.paymentForm.get('payment_mode')!.setValue('check');
                this.paymentForm.get('paid_by__ledger_account_fk_id')!.setValue(null);
                this.paymentForm.get('paid_by__payment_method_fk_id')!.setValue(null);
                this.paymentForm.get('paid_by__payment_method_fk_id')!.clearValidators();
                this.paymentForm.get('paid_by__payment_method_fk_id')!.updateValueAndValidity();
                break;
            case 2:
                this.paymentForm.get('payment_mode')!.setValue('cash');
                this.paymentForm.get('paid_by__ledger_account_fk_id')!.setValue(null);
                this.paymentForm.get('paid_by__payment_method_fk_id')!.setValue(null);
                this.paymentForm.get('paid_by__payment_method_fk_id')!.clearValidators();
                this.paymentForm.get('paid_by__payment_method_fk_id')!.updateValueAndValidity();
                break;
        }
    }

    continue() {
        if (this.releaseForm.invalid || this.paymentForm.invalid) {
            this.releaseForm.markAllAsTouched();
            this.paymentForm.markAllAsTouched();
            return;
        }
        const requestData = {
            releaseData: this._buildRelease(),
            selectedFinTransData: this.selectedTransfer,
            paymentData: this._buildPaymentObjectForBillInvoice(this.selectedBills.value),
            releaseMethod: null
        };

        this.dialogRef.close(requestData);
    }

    _buildPaymentObjectForBillInvoice(entity: IBill) {
        const releaseData = this.releaseForm.getRawValue();
        const paymentData = this.paymentForm.getRawValue();
        let entityId = null;
        let entityAmount = null;
        if (entity && entity.bill_id) {
            entityId = entity.bill_id;
            entityAmount =
                entity.pending_balance! < Number(cleanCurrencyString(releaseData.amount))
                    ? entity.pending_balance
                    : Number(cleanCurrencyString(releaseData.amount));
        }

        const paymentRequestObject: IPaymentReceivedMadeRequestObject = {
            money_receiver__contact_fk_id: releaseData.money_receiver__contact_fk_id,
            money_sender__contact_fk_id: null,
            paid_date: releaseData.paid_date,
            scheduled_date: null,
            amount: Number(cleanCurrencyString(releaseData.amount)),
            paid_by__ledger_account_fk_id: releaseData.release_from__ledger_account_fk_id,
            paid_by__payment_method_fk_id: paymentData.paid_by__payment_method_fk_id,
            pay_to__ledger_account_fk_id: null,
            pay_to__payment_method_fk_id: null,
            payments: [
                {
                    entity_id: entityId,
                    payment_id: null,
                    entity_transfer_id: null,
                    entity: null,
                    amount: entityAmount ? entityAmount : Number(cleanCurrencyString(releaseData.amount))
                }
            ],
            is_create_multiple_payments: false,
            payment_mode: paymentData.payment_mode,
            payment_number: null,
            reference: paymentData.reference ? paymentData.reference : this.dealAddress,
            notes: null,
            matched__transaction_external_id: null,
            check_info: null,
            deposit_release_id: null,
            is_locked_for_applying: true,
            allow_auto_apply: false,
            source__deal_fk_id: null,
            sender_velocity: null,
            restrict_downgrade: false
        };
        if (paymentData.payment_mode === 'check') {
            paymentRequestObject.check_info = {
                check_status: 'uncleared',
                print_status: 'not_printed',
                check_number: paymentData.check_number,
                memo: paymentData.memo
            };
        }
        if (paymentData.payment_mode === 'check_record') {
            paymentRequestObject.check_info = {
                check_status: 'uncleared',
                print_status: 'unknown',
                check_number: paymentData.check_number,
                memo: paymentData.memo
            };
        }
        return paymentRequestObject;
    }

    _buildRelease() {
        const releaseData = this.releaseForm.getRawValue();
        const releaseObj: Partial<IDealDepositRelease> = {
            release_receiver__contact_fk_id: releaseData.money_receiver__contact_fk_id,
            release_sender__contact_fk_id: null,
            release_date: releaseData.paid_date,
            release_amount: Number(cleanCurrencyString(releaseData.amount)),
            release_from__ledger_account_fk_id: releaseData.release_from__ledger_account_fk_id,
            status: null,
            request_payer__contact_fk_id: releaseData.request_payer__contact_fk_id,
            financial_transfer_fk_id: releaseData.financial_transfer_fk_id
        };
        return releaseObj;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
