import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AddTransactionDialogComponent} from '../../modules/banking/components/sidebar/add-transaction-dialog/add-transaction-dialog.component';
import {catchError, filter, takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {of, Subject} from 'rxjs';
import {BankingService} from 'app/services/api/finance/banking.service';
import {FeatureFlagsService} from '../../modules/feature-flags/feature-flags.service';

@Component({
    selector: 'app-create-menu',
    templateUrl: 'create-menu.component.html',
    styleUrls: ['create-menu.component.scss']
})
export class CreateMenuComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    createNewItemEnabledFlag: boolean = false;

    constructor(
        protected router: Router,
        public dialog: MatDialog,
        private bankingService: BankingService,
        protected featureFlagsService: FeatureFlagsService
    ) {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.createNewItemEnabledFlag = this.featureFlagsService.isFeatureEnabled('platform:create_new_item');
            });
    }

    ngOnInit() {}

    goToPreset(id: number) {
        this.router.navigate(['customer-rater-list/request-quote/simple', id]);
    }

    openDialog(type: {value: string; label: string}) {
        const dialogRef = this.dialog.open(AddTransactionDialogComponent, {
            minWidth: 320,
            data: {
                transactionType: type,
                accountId: null
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((d) => !!d),
                takeUntil(this.unsubscribe)
            )
            .subscribe((data: Object) => {
                // copied method from bank-transactions.component.ts
                this.bankingService
                    .createAdditionalTransaction({
                        transaction: data,
                        type: type.value
                    })
                    .pipe(
                        catchError(() => of(null)),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
