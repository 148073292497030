import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {firstValueFrom, Subject} from 'rxjs';
import {map, filter, takeUntil} from 'rxjs/operators';
import {IInvoice, IPaymentMethod, IFinancialTransfer, IBill, IInvoiceItem} from '@cyberco-nodejs/zipi-typings';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {BillsService} from 'app/services/api/finance/bills.service';
import {MatDialog} from '@angular/material/dialog';
import {ProductMatchingDialogComponent} from '../../common/product-matching-dialog/product-matching-dialog.component';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {FinancialTransferService} from '../../../services/financial-transfer.service';
import {assign} from 'lodash-es';
import {ShipperContactsService} from '../../../../../services/api/shipper.contacts.service';

@Component({
    selector: 'app-payout-for-approve-as-bill-page',
    templateUrl: 'payout-for-approve-as-bill-page.component.html',
    styleUrls: ['payout-for-approve-as-bill-page.component.css']
})
export class PayoutForApproveAsBillPageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    payout: (IFinancialTransfer & {items: IInvoiceItem[]; created_at: number}) | undefined;

    connectedInvoice: IInvoice | undefined;
    connectedBill: IBill | undefined;

    currentAmount: UntypedFormControl;
    currentMethod: IPaymentMethod | undefined;

    constructor(
        private activatedRoute: ActivatedRoute,
        private invoiceSrv: InvoicesService,
        private billsService: BillsService,
        private financialTransferService: FinancialTransferService,
        private router: Router,
        public dialog: MatDialog,
        private fb: UntypedFormBuilder,
        private contactService: ShipperContactsService
    ) {
        this.currentAmount = this.fb.control(0, []);
    }

    ngOnInit() {
        this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['id']) {
                this.financialTransferService
                    .getFinancialTransferById(params['id'])
                    .pipe(
                        map((transfer) => {
                            const items = {
                                items: [
                                    {
                                        product_fk_id: transfer.result.product
                                            ? transfer.result.product.product_id
                                            : null,
                                        name: transfer.result.product ? transfer.result.product.name : null,
                                        description: transfer.result.product
                                            ? transfer.result.product.description
                                            : null,
                                        quantity: 1,
                                        rate: transfer.result.product ? transfer.result.amount : null,
                                        amount: transfer.result.product ? transfer.result.amount : null,
                                        ledger_account_fk_id: transfer.result.product
                                            ? transfer.result.product.ledger_account_fk_id
                                            : null
                                    }
                                ]
                            };

                            return assign(transfer.result, items);
                        }),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe((transfer) => {
                        this.payout = transfer as IFinancialTransfer & {items: IInvoiceItem[]; created_at: number};

                        if (this.payout && this.payout.partner__invoice_fk_id) {
                            this.invoiceSrv
                                .getInvoiceForApproveById(this.payout.partner__invoice_fk_id)
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((response) => {
                                    this.connectedInvoice = response.invoice;
                                });
                        }
                        if (this.payout && this.payout.partner__bill_fk_id) {
                            this.billsService
                                .getBillForApproveById(this.payout.partner__bill_fk_id)
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((bill) => {
                                    this.connectedBill = bill;
                                });
                        }
                    });
            }
        });
    }

    async createAndConnectBillFromPayout() {
        if (this.payout && this.payout.partner__invoice_fk_id) {
            const invoicesForBulkArray = [];
            if (this.connectedInvoice && this.connectedInvoice.payments && this.connectedInvoice.payments.length > 0) {
                const invoicesForBulk: {[key: number]: IInvoice} = {};
                for (const pay of this.connectedInvoice.payments) {
                    if (pay.source_payment_received && pay.source_payment_received.related_payments) {
                        for (const relatedPay of pay.source_payment_received.related_payments) {
                            if (relatedPay.invoice && relatedPay.invoice.invoice_id) {
                                invoicesForBulk[relatedPay.invoice.invoice_id] = relatedPay.invoice;
                            }
                        }
                    }
                }
                for (const key in invoicesForBulk) {
                    if (invoicesForBulk.hasOwnProperty(key)) {
                        invoicesForBulkArray.push(invoicesForBulk[key]);
                    }
                }
            }

            let partnerContact = null;
            if (this.connectedInvoice && this.connectedInvoice.owner__company_fk_id) {
                partnerContact = await firstValueFrom(
                    this.contactService.getContactInMyCompanyByPartnerCompanyId(
                        this.connectedInvoice.owner__company_fk_id
                    )
                );
            }

            const dialogRef = this.dialog.open(ProductMatchingDialogComponent, {
                maxHeight: '80vh',
                autoFocus: false,
                data: {
                    source_document: this.connectedInvoice,
                    source_documents: invoicesForBulkArray,
                    contact: partnerContact
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((matchResult) => {
                    this.invoiceSrv
                        .linkInvoices({
                            ...matchResult,
                            source_invoice_id: this.connectedInvoice ? this.connectedInvoice.invoice_id : null
                        })
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((result) => {
                            if (result) {
                                this.router.navigate(['/purchases/bills']);
                            }
                        });
                });
        } else {
            const dialogRef = this.dialog.open(ProductMatchingDialogComponent, {
                maxHeight: '80vh',
                autoFocus: false,
                data: {
                    payout: this.payout
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((matchResult) => {
                    if (this.payout && this.payout.financial_transfer_id) {
                        this.financialTransferService
                            .createBillFromPayout(this.payout.financial_transfer_id as number, matchResult)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((result) => {
                                if (result) {
                                    this.router.navigate(['/purchases/bills']);
                                }
                            });
                    }
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
