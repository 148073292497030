<section class="container my-3">
    <div class="my-3">
        <button mat-button routerLink="/contacts">
            <mat-icon>keyboard_arrow_left</mat-icon>
            Back to Contacts
        </button>
    </div>
    <mat-card>
        <app-contact-edit></app-contact-edit>
    </mat-card>
</section>
