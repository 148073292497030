<section class="bg-white">
    <!------- Filters ------->
    <header class="d-flex align-items-center px-3 list-header">
        <h3 class="m-0 mr-1 d-flex align-items-end">Reference Documents</h3>

        <i class="sp-spacer"></i>

        <mat-form-field class="mt-2" [floatLabel]="'never'">
            <mat-label>Connection filter</mat-label>
            <mat-select [formControl]="statusFilter" name="status" multiple>
                <mat-option
                    *ngFor="let type of connectionTypes"
                    [value]="type"
                    (onSelectionChange)="changeStatusFilter($event)"
                >
                    {{ type | fromSnakeCase }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </header>

    <!------- List of bills ------->
    <div class="table-background table-bottom">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pr-1">Document Date</th>
                <td mat-cell *matCellDef="let element" class="w-10 pr-1">
                    {{ element.date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="customer_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20 pl-1">Customer</th>
                <td mat-cell *matCellDef="let element" class="w-20 px-1">{{ element.customer_name }}</td>
            </ng-container>

            <ng-container matColumnDef="document_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15 pl-1">Document Title</th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1">
                    {{ element.document_number || 'N/A' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="connection">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Connection</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.connection | fromSnakeCase }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Status</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">{{ element.status | fromSnakeCase }}</td>
            </ng-container>

            <ng-container matColumnDef="connected_invoice_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Reference</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.connected_invoice_number || 'N/A' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="due_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Due Dat</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ (element.due_date | dateFromNumber | date: 'mediumDate') || 'N/A' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="payments_status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Payment</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ (element.payments_status | fromSnakeCase) || 'Unpaid' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 table-header-align-right">Amount</th>
                <td mat-cell *matCellDef="let element" class="w-10 pr-3 amount">{{ element.amount | currency }}</td>
            </ng-container>

            <ng-container matColumnDef="due_balance">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 table-header-align-right">Balance</th>
                <td mat-cell *matCellDef="let element" class="w-10 pr-3 amount">
                    {{ element.due_balance | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="w-5 text-right">Action</th>
                <td mat-cell *matCellDef="let element" class="w-5 text-right">
                    <button
                        mat-icon-button
                        [rule]="{sales__manage_source_documents: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        *ngIf="!element.is_payout"
                        [matMenuTriggerFor]="menu"
                        (click)="$event.stopPropagation()"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <!--                        <button mat-menu-item-->
                        <!--                                [rule]="{sales__manage_source_documents: true}" rbac [denyMethod]="'style'"-->
                        <!--                                [allowStyle]="{display : 'inline-block'}" [denyStyle]="{display : 'none'}"-->
                        <!--                                [disabled]="element.connection !== 'new'"-->
                        <!--                                *ngIf="!element.is_payout"-->
                        <!--                                (click)="$event.stopPropagation(); createInvoiceFromBill(element)">-->
                        <!--                            Connect-->
                        <!--                        </button>-->
                        <button
                            mat-menu-item
                            [rule]="{sales__manage_source_documents: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            *ngIf="!element.is_payout"
                            [disabled]="element.connected !== 'new'"
                            (click)="$event.stopPropagation(); archiveBill(element)"
                        >
                            Archive
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                (click)="navigateToSinglePage(row)"
                class="zp-table-row"
            ></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 text-center no-items">
            There are no source documents.
        </div>
    </div>
</section>
