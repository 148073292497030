<div [formGroup]="formGroup" class="pt-3">
    <mat-form-field class="col-6">
        <mat-label>Reference #</mat-label>
        <input type="text" matInput formControlName="reference_number" autocomplete="off" />
    </mat-form-field>
    <div class="col-6 d-inline-flex align-content-center">
        <label class="mr-3">Journal Type:</label>
        <mat-radio-group formControlName="type">
            <mat-radio-button value="cash">Cash</mat-radio-button>
            <mat-radio-button value="both">Both</mat-radio-button>
        </mat-radio-group>
        <mat-icon
            class="ml-3"
            matTooltip="Choose this option to make this journal entry appear only in the reports
                    that are generated on cash basis. If not, this transaction will appear in both the accrual
                     and cash basis reports."
            >error_outline
        </mat-icon>
    </div>
    <mat-form-field class="col-6">
        <mat-label>Notes</mat-label>
        <textarea type="text" matInput formControlName="notes" autocomplete="off"></textarea>
    </mat-form-field>

    <div class="w-100 transactions-wrapper">
        <div class="items-header__row">
            <div class="items-header__col account">Account</div>
            <div class="items-header__col description">Description</div>
            <div class="items-header__col division">Division</div>
            <div class="items-header__col amount">Debit</div>
            <div class="items-header__col amount">Credit</div>
        </div>

        <div formArrayName="line_items">
            <div *ngFor="let item of $any(formGroup.controls.line_items.controls); let i = index">
                <div class="items__row" [formGroupName]="i">
                    <div class="items__col account">
                        <app-ledger-account-selector
                            class="w-100"
                            [ledgerAccountControl]="item.get('ledger_account_fk_id')"
                            [ledgerAccountFullControl]="item.get('selected_full_ledger')"
                            [isInactiveSelectable]="true"
                            [disabledAccountSystemKeys]="[]"
                        >
                        </app-ledger-account-selector>
                    </div>
                    <div class="items__col description">
                        <mat-form-field class="w-100">
                            <textarea type="text" formControlName="description" matInput [rows]="1"> </textarea>
                        </mat-form-field>
                    </div>
                    <div class="items__col division">
                        <!--                        <app-combined-entity-picker-->
                        <!--                            class="w-100"-->
                        <!--                            [availableTypes]="['company_group']"-->
                        <!--                            [title]="''"-->
                        <!--                            [only_compensation_groups]="false"-->
                        <!--                            [onlyDivisions]="true"-->
                        <!--                            [initialGroupIds]="[item.get('selected_division_ids').value]"-->
                        <!--                            [nodesFA]="item.get('selected_divisions')"-->
                        <!--                            [singleSelected]="true"-->
                        <!--                            [bold_style]="false"-->
                        <!--                        ></app-combined-entity-picker>-->
                        <div class="w-100">
                            <app-division-picker
                                [isNeedToBeFiltered]="
                                    item.get('selected_full_ledger').value
                                        ? !item.get('selected_full_ledger').value.is_accessible_for_all
                                        : false
                                "
                                [filterById]="
                                    item.get('selected_full_ledger').value
                                        ? item.get('selected_full_ledger').value.accessible_for
                                        : []
                                "
                                [nodesFA]="item.get('selected_divisions')"
                                [initialGroupId]="item.get('selected_division_ids').value"
                                [isTotallyDisabled]="!item.get('ledger_account_fk_id').value"
                                [placeholder]="''"
                            >
                            </app-division-picker>
                        </div>
                    </div>
                    <div class="items__col amount">
                        <mat-form-field class="w-100">
                            <input
                                (blur)="onType(i, 'debit', $event)"
                                [maskito]="currencyMaskitoMask"
                                matInput
                                type="text"
                                formControlName="debit"
                                autocomplete="off"
                            />
                        </mat-form-field>
                    </div>
                    <div class="items__col amount">
                        <mat-form-field class="w-100">
                            <input
                                (blur)="onType(i, 'credit', $event)"
                                [maskito]="currencyMaskitoMask"
                                matInput
                                type="text"
                                formControlName="credit"
                                autocomplete="off"
                            />
                        </mat-form-field>
                    </div>
                    <div class="items__col edit">
                        <button
                            type="button"
                            mat-icon-button
                            (click)="deleteTransaction(i)"
                            [disabled]="$any(formGroup.controls.line_items.controls).length <= 2"
                        >
                            <mat-icon>remove_circle_outline</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="items__row footer">
            <div class="items__col add-line">
                <button mat-raised-button class="fix-focus-outline" (click)="addTransactions()">
                    Add line
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div class="items__col total align-items-center">Total</div>
            <div class="items__col align-items-center amount">
                {{ total_debit | currency: 'USD' : 'symbol' }}
            </div>
            <div class="items__col align-items-center amount">
                {{ total_credit | currency: 'USD' : 'symbol' }}
            </div>
            <div class="items__col edit"></div>
        </div>
    </div>
</div>
