import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {Deal} from '../../../../../../../../models/deal';
import {DealTemplateEntity} from '../../../../common/deal.models';

@Component({
    selector: 'app-narrowed-deal-list-item',
    templateUrl: './narrowed-deal-list-item.component.html',
    styleUrls: [
        '../expanded-deal-list-item/expanded-deal-list-item.component.scss',
        './narrowed-deal-list-item.component.scss'
    ]
})
export class NarrowedDealListItemComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() deal: Deal | undefined = undefined;
    @Input() dealTemplates: {[id: number]: DealTemplateEntity} | null = null;

    @Output() selectedDealEmitter: EventEmitter<Deal> = new EventEmitter<Deal>();

    DEAL = Deal;

    constructor() {}

    ngOnInit() {}

    isDateInPast(date: number | string | Date) {
        const currentDate = new Date();
        const targetDate = new Date(date);

        return currentDate > targetDate;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.selectedDealEmitter.complete();
    }
}
