import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject, Subscription} from 'rxjs';
import {IContact, IContactPerson} from '@cyberco-nodejs/zipi-typings';
import {MatTableDataSource} from '@angular/material/table';
import {takeUntil} from 'rxjs/operators';
import {ShipperContactsService} from '../../../../services/api/shipper.contacts.service';
import {NotificationsServiceZipi} from '../../../notifications/notifications.service';
import {CurrentProfileSource} from '../../../../services/sources/current-profile.source';
import {Profile} from '../../../../models/profile';

interface IDialogData {
    primaryApplicantsIds: [];
    mergedContacts: IContact[];
}

@Component({
    selector: 'app-merge-contacts-dialog',
    templateUrl: './merge-contacts-dialog.component.html',
    styles: [
        `
            .primary-radio-group {
                display: flex;
                flex-direction: column;
                margin: 0 0 15px;
            }
            .error-data {
                padding: 10px;
                border: 1px solid red;
                margin-top: 10px;
            }
            .primary-radio-button {
                margin: 5px;
            }
            .row-is-disabled {
                background-color: #f5f5f5;
            }
        `
    ]
})
export class MergeContactsDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    public primaryContact: IContact | null = null;
    dataSource: MatTableDataSource<IContact>;
    displayedColumns = ['contact_id', 'display_name', 'email', 'phone'];
    displayedUnmergeColumns = ['display_name', 'email', 'action'];
    getContactsRequest: Subscription | undefined;
    isInProcess: boolean = false;
    contacts: IContact[] | undefined;
    errorData: {message: string} | null = null;
    mode: string | undefined;
    public currentProfile: Profile | null = null;
    public isCurrentProfileInList: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<MergeContactsDialogComponent>,
        private contactsService: ShipperContactsService,
        protected notificationService: NotificationsServiceZipi,
        private cs: ShipperContactsService,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData,
        protected currentProfileSource: CurrentProfileSource
    ) {
        this.dataSource = new MatTableDataSource<IContact>([]);
        this.currentProfileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe((currProfile) => {
            this.currentProfile = currProfile;
        });
    }

    ngOnInit() {
        this.isInProcess = true;
        if (this.data.primaryApplicantsIds) {
            this.mode = 'merge';
            this.getContactsRequest = this.contactsService
                .getContactsList(this.data.primaryApplicantsIds)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((contacts) => {
                    if (contacts) {
                        this.contacts = contacts;
                        this.dataSource.data = contacts.map((contact) => {
                            return this.processContact(contact);
                        });
                    } else {
                        this.notificationService.addError('Error receiving contact data');
                    }
                    this.isInProcess = false;
                });
        } else if (this.data.mergedContacts) {
            this.mode = 'unmerge';
            this.dataSource.data = this.data.mergedContacts.map((contact) => {
                return this.processContact(contact);
            });
            this.isInProcess = false;
        }
    }

    processContact(contact: IContact) {
        if (this.currentProfile?.contact?.contact_id === contact.contact_id) {
            this.isCurrentProfileInList = true;
        }
        const mainPersonData = {
            email: '',
            phone: ''
        };
        const mainPerson = contact.contact_persons.find((person: IContactPerson) => person.type === 'main_person');
        if (mainPerson) {
            mainPersonData.email = mainPerson.email ? mainPerson.email : '';
            mainPersonData.phone = mainPerson.phone ? mainPerson.phone : '';
        }
        const returnContact = {
            ...contact,
            ...mainPersonData,
            ...{checked: this.currentProfile?.contact?.contact_id === contact.contact_id}
        };
        if (this.currentProfile?.contact?.contact_id === contact.contact_id) {
            this.primaryContact = returnContact;
            this.errorData = {
                message: `You can't erase yourself.`
            };
        }
        return returnContact;
    }

    async unmergeContact(contact: IContact) {
        if (contact && contact.contact_id) {
            this.errorData = null;
            this.isInProcess = true;
            this.cs
                .unmergeContacts({
                    contactId: contact.contact_id
                })
                .toPromise()
                .then((res) => {
                    this.dialogRef.close(true);
                })
                .catch((err) => {
                    this.errorData = {
                        message: err
                    };
                    this.isInProcess = false;
                });
        }
    }

    async mergeContacts() {
        const primaryContactId =
            this.primaryContact && this.primaryContact.contact_id ? Number(this.primaryContact.contact_id) : false;
        if (primaryContactId) {
            const primaryContact = this.data.primaryApplicantsIds.find((contact_id) => contact_id === primaryContactId);
            const notPrimaryContactIds = this.data.primaryApplicantsIds.filter(
                (contact_id) => contact_id !== primaryContactId
            );
            const data = {
                primaryContactId: primaryContact,
                notPrimaryContactIds: notPrimaryContactIds
            };
            this.errorData = null;
            this.isInProcess = true;
            this.cs
                // @ts-ignore
                .mergeContacts(data)
                .toPromise()
                .then((res: boolean) => {
                    this.dialogRef.close(true);
                })
                .catch((err: string) => {
                    this.errorData = {
                        message: err
                    };
                    this.isInProcess = false;
                });
        }
    }

    ngOnDestroy() {
        if (this.getContactsRequest) {
            this.getContactsRequest.unsubscribe();
        }
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
