<section class="container-fluid">
    <header class="my-2 d-flex align-items-center">
        <h1 *ngIf="!billId">Create Bill</h1>
        <h1 *ngIf="billId">Edit Bill</h1>

        <span class="sp-spacer"></span>
        <button
            *ngIf="bill && bill.bill_id && bill.connected__invoice_fk_id"
            type="submit"
            class="ml-1"
            mat-raised-button
            (click)="disconnectBill()"
            [disabled]="editType !== 'full' || deleteDisabled"
        >
            Disconnect
        </button>
    </header>

    <div [formGroup]="formGroup" class="mb-3">
        <div class="row mr-0">
            <div class="col col-md-6">
                <mat-form-field class="w-100">
                    <input matInput formControlName="bill_number" placeholder="Bill#" type="text" autocomplete="off" />
                </mat-form-field>
            </div>

            <div class="col col-md-6">
                <app-company-compensation-combined-picker
                    style="width: 100%; margin-right: 8px"
                    [required]="true"
                    [availableTypes]="['contact']"
                    [title]="'Vendor Name'"
                    [only_compensation_groups]="false"
                    [singleSelected]="true"
                    [bold_style]="false"
                    [nodesFA]="moneySenderCtrlArr"
                    [initialContactIds]="savedContacts"
                    [disabled]="(!!bill && !!bill.money_receiver__company_fk_id) || editType !== 'full'"
                >
                </app-company-compensation-combined-picker>
            </div>
        </div>

        <div class="row mr-0">
            <app-date-picker
                class="col col-sm-6"
                [placeholder]="'Bill date'"
                [dateControl]="formGroup.controls.invoice_date"
                [disabled]="editType === 'blocked'"
            ></app-date-picker>

            <mat-form-field
                class="col"
                [ngClass]="{
                    'col-md-6':
                        !formGroup.controls.payment_terms.value || formGroup.controls.payment_terms.value !== 'custom',
                    'col-md-3': formGroup.controls.payment_terms.value === 'custom'
                }"
            >
                <mat-select formControlName="payment_terms" placeholder="Payment Terms">
                    <mat-option *ngFor="let pt of paymentTerms" [value]="pt[0]">{{ pt[1] }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col col-md-3" *ngIf="formGroup.controls.payment_terms.value === 'custom'">
                <input matInput type="text" placeholder="Number of days" formControlName="payment_terms_custom" />
            </mat-form-field>
        </div>

        <div class="row mr-0">
            <div class="col col-md-6">
                <mat-form-field class="w-100">
                    <input
                        matInput
                        formControlName="invoice_note"
                        placeholder="References"
                        type="text"
                        autocomplete="off"
                    />
                </mat-form-field>
            </div>
        </div>

        <!------- Items Header ------->
        <h3 class="d-flex align-items-center">
            Items
            <button
                type="button"
                [disabled]="editType !== 'full'"
                mat-icon-button
                (click)="addItem()"
                matTooltip="Add item"
            >
                <mat-icon>add</mat-icon>
            </button>
        </h3>
        <!------- Items ------->
        <div class="overflow-auto">
            <div
                *ngFor="let itemGroup of itemsArray.controls; index as idx; first as first"
                class="d-grid-items-container"
                [ngClass]="groupList.length ? 'with-groups' : 'no-groups'"
            >
                <div class="d-grid-items" [formGroup]="itemGroup">
                    <!------- Product/Service ------->
                    <div class="mx-2">
                        <ng-container
                            *ngIf="
                                !itemGroup.controls.product_fk_id.value && !itemGroup.controls.item_has_transfer.value
                            "
                        >
                            <mat-form-field class="w-100">
                                <input
                                    formControlName="name"
                                    type="text"
                                    matInput
                                    placeholder="Product/Service"
                                    (blur)="trimOnBlur(itemGroup.get('name'))"
                                    [matAutocomplete]="auto"
                                    autocomplete="off"
                                />
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option (click)="$event.stopPropagation(); createProduct()"
                                        >Create New Product</mat-option
                                    >
                                    <ng-container *ngFor="let product of getProducts(itemGroup)">
                                        <mat-option
                                            *ngIf="
                                                product.status === 'active' ||
                                                itemGroup.controls.product_fk_id.value === product.product_id
                                            "
                                            [value]="product.name"
                                            [disabled]="
                                                ['closing_debit', 'closing_credit'].includes(product.system_key)
                                            "
                                            (onSelectionChange)="selectItem(itemGroup, product)"
                                        >
                                            {{ product.name }}
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                            </mat-form-field>
                        </ng-container>
                        <ng-container
                            *ngIf="itemGroup.controls.product_fk_id.value || itemGroup.controls.item_has_transfer.value"
                        >
                            <mat-form-field class="w-100">
                                <input formControlName="name" matInput type="text" />
                                <button
                                    mat-button
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Clear"
                                    *ngIf="!itemGroup.controls.item_has_transfer.value"
                                    [disabled]="editType !== 'full'"
                                    (click)="removeProductFkId(itemGroup)"
                                >
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                        </ng-container>
                    </div>
                    <!------- Description ------->
                    <div class="mx-2">
                        <mat-form-field class="w-100">
                            <input
                                matInput
                                formControlName="description"
                                placeholder="Description"
                                type="text"
                                autocomplete="off"
                                (blur)="trimOnBlur(itemGroup.get('description'))"
                            />
                        </mat-form-field>
                    </div>
                    <!------- Quantity ------->
                    <div class="mx-2">
                        <app-wildcard-picker
                            [type]="'quantity'"
                            [placeholder]="'Quantity'"
                            [placeholderAttribute]="'Custom Attribute Quantity'"
                            [contactFc]="formGroup.controls.money_receiver__contact_fk_id"
                            [productFC]="itemGroup.controls.product_fk_id"
                            [valueFC]="itemGroup.controls.quantity"
                            [valueWildcardIdFC]="itemGroup.controls.quantity__wildcard_fk_id"
                            style="
                                z-index: 2;
                                justify-content: space-between;
                                display: flex;
                                align-items: center;
                                margin-right: 5px;
                            "
                        >
                        </app-wildcard-picker>
                    </div>
                    <!------- Rate ------->
                    <div class="mx-2">
                        <app-wildcard-picker
                            [placeholder]="'Rate'"
                            [placeholderAttribute]="'Custom Attribute Price'"
                            [contactFc]="formGroup.controls.money_receiver__contact_fk_id"
                            [productFC]="itemGroup.controls.product_fk_id"
                            [valueFC]="itemGroup.controls.rate"
                            [valueWildcardIdFC]="itemGroup.controls.rate__wildcard_fk_id"
                            [type]="'currency'"
                            [allowNegative]="true"
                            style="
                                z-index: 2;
                                justify-content: space-between;
                                display: flex;
                                align-items: center;
                                margin-right: 5px;
                            "
                        >
                        </app-wildcard-picker>
                    </div>
                    <!------- Amount ------->
                    <div class="mx-2">
                        <mat-form-field class="w-100">
                            <input
                                matInput
                                formControlName="amount"
                                placeholder="Amount"
                                type="text"
                                [maskito]="currencyWithNegativeMaskitoMask"
                            />
                        </mat-form-field>
                    </div>
                    <!------- Ledger Account ------->
                    <div class="mx-2">
                        <app-ledger-account-selector
                            class="w-100"
                            [ledgerAccountControl]="itemGroup.controls.ledger_account_fk_id"
                            [ledgerAccountFullControl]="itemGroup.controls.selected_full_ledger"
                            [disabledReselect]="!!itemGroup.controls.product_fk_id.value"
                            [placeholder]="'Account'"
                            [excludedOptions]="['accounts_payable']"
                        >
                        </app-ledger-account-selector>
                    </div>
                    <!------- Assign to Division ------->
                    <div class="mx-2" *ngIf="groupList.length">
                        <app-division-picker
                            [isNeedToBeFiltered]="
                                itemGroup.controls.selected_full_ledger.value
                                    ? !itemGroup.controls.selected_full_ledger.value.is_accessible_for_all
                                    : false
                            "
                            [filterById]="
                                itemGroup.controls.selected_full_ledger.value
                                    ? itemGroup.controls.selected_full_ledger.value.accessible_for
                                    : []
                            "
                            [nodesFA]="itemGroup.get('selected_divisions')"
                            [initialGroupId]="itemGroup.controls.selected_division_ids.value"
                            [isTotallyDisabled]="!itemGroup.controls.ledger_account_fk_id.value"
                            [placeholder]="'Assign to Division'"
                        >
                        </app-division-picker>
                    </div>
                </div>
                <!------- Actions ------->
                <div class="actions">
                    <!--			<button type="button" mat-icon-button (click)="addItem()" matTooltip="Add item"><mat-icon>add</mat-icon></button>-->
                    <button
                        type="button"
                        [disabled]="editType !== 'full'"
                        *ngIf="itemsArray.length === 1"
                        mat-icon-button
                        (click)="resetItem(itemGroup)"
                        matTooltip="Clear Item"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                    <button
                        type="button"
                        [disabled]="editType !== 'full'"
                        *ngIf="itemsArray.length > 1"
                        mat-icon-button
                        (click)="itemsArray.removeAt(idx)"
                        matTooltip="Remove Item"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end align-items-baseline my-2">
            <span class="mr-2">Total amount: &nbsp;</span>
            <strong class="zp-total-amount">
                {{ formGroup.controls.total_amount.value | currency }}
            </strong>
        </div>

        <!------- Deal Association Header ------->
        <h3 class="d-flex align-items-center">
            Deal Association
            <button
                type="button"
                [disabled]="editType !== 'full'"
                mat-icon-button
                (click)="addDealAssociation()"
                matTooltip="Add item"
            >
                <mat-icon>add</mat-icon>
            </button>
        </h3>
        <!------- Deal Association ------->
        <div
            *ngFor="let associationGroup of associationItemArray.controls; index as idx; first as first"
            class="d-flex align-items-center"
        >
            <div class="d-flex align-items-center w-100" [formGroup]="associationGroup">
                <div class="w-30 px-1">
                    <app-deal-picker
                        class="w-100"
                        [disabledSelect]="associationGroup.controls.connected__deal_fk_id.disabled"
                        [dealControl]="associationGroup.controls.connected__deal_fk_id"
                        [placeholder]="'Associated Deal'"
                    >
                    </app-deal-picker>
                </div>
                <div class="w-30 px-1">
                    <mat-form-field *ngIf="!associationGroup.controls.transfer_id.value">
                        <mat-label>Select Product</mat-label>
                        <mat-select
                            formControlName="product_id"
                            (selectionChange)="
                                validateAmount(
                                    {target: associationGroup.controls.amount.value},
                                    associationGroup.controls.product_id.value
                                );
                                setProductName($event, associationGroup)
                            "
                        >
                            <ng-container *ngFor="let prodId of object.keys(prodSet)">
                                <mat-option [value]="prodId" *ngIf="prodSet[$any(prodId)]?.max_amount > 0">
                                    {{ prodSet[$any(prodId)]?.product?.name }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <div
                        class="pl-2"
                        *ngIf="
                            associationGroup.controls.transfer_id.value &&
                            prodSet[$any(associationGroup.controls.product_id.value)]!.product
                        "
                    >
                        {{ prodSet[$any(associationGroup.controls.product_id.value)]!.product!.name }}
                    </div>
                </div>
                <div class="w-20 px-1">
                    <div
                        *ngIf="
                            associationGroup.controls.product_id.value &&
                            prodSet[$any(associationGroup.controls.product_id.value)]!.product
                        "
                    >
                        <i class="text-muted">{{
                            prodSet[$any(associationGroup.controls.product_id.value)]!.product!.description
                        }}</i>
                    </div>
                </div>
                <div class="w-10 px-1">
                    <div
                        *ngIf="
                            associationGroup.controls.product_id.value &&
                            prodSet[$any(associationGroup.controls.product_id.value)]
                        "
                    >
                        {{ prodSet[$any(associationGroup.controls.product_id.value)]!.max_amount | currency }}
                    </div>
                </div>
                <div class="w-10 px-1 d-flex justify-content-end">
                    <mat-form-field class="w-70">
                        <input
                            matInput
                            type="text"
                            [maskito]="currencyMaskitoMask"
                            (keyup)="validateAmount($event, associationGroup.controls.product_id.value)"
                            [formControl]="associationGroup.controls.amount"
                        />
                    </mat-form-field>
                </div>
            </div>
            <div class="actions">
                <button
                    type="button"
                    mat-icon-button
                    [disabled]="editType !== 'full'"
                    matTooltip="Remove Association"
                    (click)="removeDealAssociation(idx)"
                >
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>

        <div class="tag-section" *ngIf="false">
            <span>Applied Tags: </span>
            <mat-chip-list [disabled]="disableTags">
                <ng-container *ngFor="let tag of allTags">
                    <mat-chip *ngIf="selectedTagsIds.includes(tag.tag_id)">
                        {{ tag.title }}
                    </mat-chip>
                </ng-container>
            </mat-chip-list>
        </div>

        <!--        <div>-->
        <!--            <mat-form-field class="">-->
        <!--                <mat-select formControlName="division__company_group_fk_id" placeholder="Assign to Division">-->
        <!--                    <mat-option *ngFor="let group of availableDivisionsGroups" [value]="group.id">-->
        <!--                        {{group.title}}-->
        <!--                    </mat-option>-->
        <!--                </mat-select>-->
        <!--            </mat-form-field>-->
        <!--        </div>-->

        <!--		<div class="mr-2">Available Credits ({{creditsBalance | currency}})</div>-->

        <div class="d-flex">
            <div
                class="ml-1"
                matTooltip="You cannot modify Bill associated with Deal."
                [matTooltipDisabled]="bill && !bill.source__deal_fk_id"
            >
                <button
                    *ngIf="bill && bill.bill_id"
                    type="submit"
                    mat-raised-button
                    color="warn"
                    (click)="deleteBill()"
                    [disabled]="editType !== 'full'"
                >
                    Delete
                </button>
            </div>
            <span class="sp-spacer"></span>
            <button mat-raised-button class="mr-2" routerLink="/purchases/bills">Cancel</button>

            <mat-menu #appMenu="matMenu">
                <button mat-menu-item (click)="createBill('draft')">Create as Draft</button>

                <button mat-menu-item (click)="createBill('open')">Create as Open</button>
            </mat-menu>

            <button mat-raised-button color="primary" *ngIf="!bill" [matMenuTriggerFor]="appMenu">Create</button>

            <button
                type="submit"
                *ngIf="bill"
                mat-raised-button
                [disabled]="updateDisabled"
                color="primary"
                (click)="updateBill()"
            >
                Save
            </button>
        </div>
    </div>
</section>
