import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {catchError, filter, first, map, switchMap} from 'rxjs/operators';
import {from, of as observableOf} from 'rxjs';
import {ICompanyWideState} from './company-wide.reducer';
import {
    CompanyWideActionType,
    CreateMembershipOrganizations,
    CreateMembershipOrganizationsSuccess,
    CreateTagCategory,
    CreateTagCategorySuccess,
    CreateWildcard,
    CreateWildcardSuccess,
    DeleteTagCategory,
    DeleteTagCategorySuccess,
    FetchMembershipOrganizations,
    FetchMembershipOrganizationsSuccess,
    FetchSubEntities,
    FetchSubEntitiesSuccess,
    FetchTagCategories,
    FetchTagCategoriesSuccess,
    FetchWildcards,
    FetchWildcardsSuccess,
    UpdateMembershipOrganizations,
    UpdateMembershipOrganizationsSuccess,
    UpdateSOB,
    UpdateSOBSuccess,
    UpdateSubSources,
    UpdateSubSourcesSuccess,
    UpdateSubTypes,
    UpdateSubTypesSuccess,
    UpdateTagCategory,
    UpdateTagCategorySuccess,
    UpdateWildcard,
    UpdateWildcardSuccess
} from './company-wide.actions';
import {selectMembershipOrganizations, selectSOB, selectTagCategories, selectWildcards} from './company-wide.selectors';
import {CompaniesService} from 'app/services/companies.service';
import {SessionService} from 'app/services/session.service';
import {MembershipService} from 'app/services/membership.service';
import {UserService} from 'app/services/user.service';
import {TagsService} from 'app/services/api/tags.service';
import {WildcardsService} from 'app/services/api/wildcards.service';
import {SubSource} from '../../models/sub-source';
import {SubType} from '../../models/sub-type';
import {SourceOfBusiness} from '../../models/source-of-business';
import {ITagCategory} from '@cyberco-nodejs/zipi-typings';
import {DialogConfirmationComponent} from '../../modules/shared/components/dialog-confirmation/dialog-confirmation.dialog';
import {MatDialog} from '@angular/material/dialog';

@Injectable()
export class CompanyWideEffectsService {
    constructor(
        protected actions$: Actions,
        private sessionService: SessionService,
        private companiesService: CompaniesService,
        private membershipService: MembershipService,
        private userService: UserService,
        private tagsService: TagsService,
        private wildcardsService: WildcardsService,
        protected store: Store<ICompanyWideState>,
        protected router: Router,
        public dialog: MatDialog
    ) {}

    @Effect()
    fetchSubEntities$ = this.actions$.pipe(
        ofType<FetchSubEntities>(CompanyWideActionType.FETCH_SUB_ENTITIES),
        switchMap(() => this.store.pipe(select(selectSOB), first())),
        switchMap((sob) => {
            if (sob && sob.length === 0) {
                return from(
                    this.companiesService.getAvailableSubEntities(this.sessionService.profile?.company_fk_id as number)
                ).pipe(catchError((err) => observableOf(null)));
            }
            return observableOf(null);
        }),
        filter((sob) => !!sob),
        map(
            (result) =>
                new FetchSubEntitiesSuccess(
                    result?.result as {sub_sources: SubSource[]; sub_types: SubType[]; sources: SourceOfBusiness[]}
                )
        )
    );

    @Effect()
    updateSOB$ = this.actions$.pipe(
        ofType<UpdateSOB>(CompanyWideActionType.UPDATE_SOB),
        map((action) => {
            this.userService.updateSOBsListForCurrentProfile(action.payload!);
            return new UpdateSOBSuccess(action.payload!);
        })
    );

    @Effect()
    updateSubTypes$ = this.actions$.pipe(
        ofType<UpdateSubTypes>(CompanyWideActionType.UPDATE_SUB_TYPES),
        map((action) => {
            this.userService.updateSubTypesListForCurrentProfile(action.payload!);
            return new UpdateSubTypesSuccess(action.payload!);
        })
    );

    @Effect()
    updateSubSources$ = this.actions$.pipe(
        ofType<UpdateSubSources>(CompanyWideActionType.UPDATE_SUB_SOURCES),
        map((action) => {
            this.userService.updateSubSourcesListForCurrentProfile(action.payload!);
            return new UpdateSubSourcesSuccess(action.payload!);
        })
    );

    @Effect()
    fetchMembershipOrganizations$ = this.actions$.pipe(
        ofType<FetchMembershipOrganizations>(CompanyWideActionType.FETCH_MEMBERSHIP_ORGANIZATIONS),
        switchMap(() => this.store.pipe(select(selectMembershipOrganizations), first())),
        switchMap((mo) => {
            if (mo && mo.length === 0) {
                return this.membershipService
                    .getAvailableMembershipOrganizations()
                    .pipe(catchError((err) => observableOf(null)));
            }
            return observableOf(null);
        }),
        filter((mo) => !!mo),
        map((result) => new FetchMembershipOrganizationsSuccess(result.result))
    );

    @Effect()
    createMembershipOrganizations$ = this.actions$.pipe(
        ofType<CreateMembershipOrganizations>(CompanyWideActionType.CREATE_MEMBERSHIP_ORGANIZATIONS),
        switchMap((action) =>
            from(this.membershipService.createMembershipOrganization(action.payload!)).pipe(
                catchError((err) => observableOf(null))
            )
        ),
        map((result) => new CreateMembershipOrganizationsSuccess(result))
    );

    @Effect()
    updateMembershipOrganizations$ = this.actions$.pipe(
        ofType<UpdateMembershipOrganizations>(CompanyWideActionType.UPDATE_MEMBERSHIP_ORGANIZATIONS),
        map((action) => {
            from(this.membershipService.updateMembershipOrganization(action.payload!)).pipe(
                catchError((err) => observableOf(null))
            );
            return new UpdateMembershipOrganizationsSuccess(action.payload!);
        })
    );

    @Effect()
    fetchTagCategories$ = this.actions$.pipe(
        ofType<FetchTagCategories>(CompanyWideActionType.FETCH_TAG_CATEGORIES),
        switchMap(() => this.store.pipe(select(selectTagCategories), first())),
        switchMap((tc) => {
            if (tc && tc.length === 0) {
                return this.tagsService.getTagCategories().pipe(catchError((err) => observableOf(null)));
            }
            return observableOf(null);
        }),
        filter((tc) => !!tc),
        map((result) => new FetchTagCategoriesSuccess(result?.result as ITagCategory[]))
    );

    @Effect()
    createTagCategory$ = this.actions$.pipe(
        ofType<CreateTagCategory>(CompanyWideActionType.CREATE_TAG_CATEGORY),
        switchMap((action) =>
            from(this.tagsService.createTagCategory(action.payload!)).pipe(catchError((err) => observableOf(null)))
        ),
        map((result) => new CreateTagCategorySuccess(result?.result as ITagCategory))
    );

    @Effect()
    updateTagCategory$ = this.actions$.pipe(
        ofType<UpdateTagCategory>(CompanyWideActionType.UPDATE_TAG_CATEGORY),
        switchMap((action) =>
            from(this.tagsService.updateTagCategory(action.payload!)).pipe(catchError((err) => observableOf(null)))
        ),
        switchMap(async (result) => {
            if (result && result.result && result.result.deactivatedIds && result.result.deactivatedIds.length) {
                const dialogRef = this.dialog.open(DialogConfirmationComponent, {
                    width: '320px',
                    data: {
                        title: 'Deactivate Tag',
                        text: `${result.result.errorMessage.join(' ')} \n\nTag${result.result.errorMessage.length > 1 ? 's' : ''} will be Deactivated. Please confirm.`,
                        cancel: 'Cancel',
                        confirmation: 'Deactivate'
                    }
                });

                const confirmation: boolean = await dialogRef.afterClosed().toPromise();
                if (confirmation) {
                    return this.tagsService
                        .deactivateTag(result.result.deactivatedIds, result.result.category.tag_category_id as number)
                        .pipe(map((r) => r.result));
                } else {
                    return observableOf(result.result.category);
                }
            } else {
                return observableOf(result?.result.category);
            }
        }),
        switchMap((result) => result),
        map((result) => new UpdateTagCategorySuccess(result))
    );

    @Effect()
    deleteTagCategory$ = this.actions$.pipe(
        ofType<DeleteTagCategory>(CompanyWideActionType.DELETE_TAG_CATEGORY),
        switchMap((action) =>
            from(this.tagsService.deleteTagCategory(action.payload!)).pipe(catchError((err) => observableOf(null)))
        ),
        map((result) => new DeleteTagCategorySuccess(result?.result as number))
    );

    @Effect()
    fetchWildcards$ = this.actions$.pipe(
        ofType<FetchWildcards>(CompanyWideActionType.FETCH_WILDCARDS),
        switchMap(() => from(this.wildcardsService.getWildcards()).pipe(catchError((err) => observableOf([])))),
        map((result) => new FetchWildcardsSuccess(result))
    );

    @Effect()
    createWildcard$ = this.actions$.pipe(
        ofType<CreateWildcard>(CompanyWideActionType.CREATE_WILDCARD),
        switchMap((action) =>
            from(this.wildcardsService.createWildcard(action.payload!)).pipe(catchError((err) => observableOf([])))
        ),
        map((result) => new CreateWildcardSuccess(result))
    );

    @Effect()
    updateWildcard$ = this.actions$.pipe(
        ofType<UpdateWildcard>(CompanyWideActionType.UPDATE_WILDCARD),
        switchMap((action) =>
            from(this.wildcardsService.updateWildcard(action.payload!)).pipe(catchError((err) => observableOf([])))
        ),
        map((result) => new UpdateWildcardSuccess(result))
    );
}
