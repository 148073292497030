<mat-button-toggle-group
    [disabled]="disabled"
    [multiple]="multiple"
    [vertical]="true"
    [value]="value"
    (change)="onChange($event)"
>
    <mat-button-toggle *ngFor="let item of list" [value]="item[valueAttribute]">
        <div class="flex-row">
            <div class="but-toggle-item icon">
                <div>
                    <span
                        *ngIf="multiple && value && value.length && value.includes(item[valueAttribute])"
                        class="material-icons"
                        >done</span
                    >
                    <span *ngIf="!multiple && value === item[valueAttribute]" class="material-icons">done</span>
                </div>
            </div>
            <div class="but-toggle-item title">
                <span>{{ item[titleAttribute] }}</span>
            </div>
        </div>
    </mat-button-toggle>
</mat-button-toggle-group>
