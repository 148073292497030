import {Routes, RouterModule} from '@angular/router';
import {IndexComponent} from './components/deal/pages/index/index.component';
import {EditDealComponent} from './components/deal/pages/edit-deal.component';
import {AuthBootstrapService} from '../../services/resolvers/auth-bootstrap.service';
import {UserLayoutComponent} from '../../layouts/user-layout.component';
import {DenyGlobalViewResolver} from '../../services/resolvers/deny-global-view.resolver';
import {CreateDealComponent} from './components/deal/pages/create-deal.component';
import {ViewDealComponent} from './components/deal/pages/view/view-deal.component';
import {GuestLayoutComponent} from '../../layouts/guest-layout.component';
import {PublicBootstrapService} from '../../services/resolvers/public-bootstrap.service';
import {DepositRequestPublicPageComponent} from './components/deal/common/deal-deposit/deposit-request-public/deposit-request-public-page/deposit-request-public-page.component';
import {AccessGuardService} from '../../services/resolvers/access-guard.service';
import {PendingChangesGuardService} from '../../services/resolvers/pending-changes-guard.service';
import {RemainingPayoutsListComponent} from './modules/remaining-payouts/components/remaining-payouts-list/remaining-payouts-list.component';
import {ClosingPayoutsModule} from './modules/closing-payouts/closing-payouts.module';
import {RedirectToNewEditDealPageGuard} from '@app/services/resolvers/redirect-to-new-edit-deal-page.guard';

export const routes: Routes = [
    {
        // path: 'finance',
        path: 'deals',
        component: UserLayoutComponent,
        resolve: {authBootstrap: AuthBootstrapService},
        children: [
            {
                path: 'create',
                component: CreateDealComponent,
                canActivate: [AccessGuardService],
                resolve: {
                    denyGv: DenyGlobalViewResolver
                },
                data: {
                    title: 'Create deal',
                    access: {anyRule: [{deals__create_own: true}, {deals__create_any: true}]}
                }
            },
            {
                path: 'remainingpayouts',
                loadChildren: () =>
                    import('./modules/remaining-payouts/remaining-payouts.module').then(
                        (module) => module.RemainingPayoutsModule
                    )
            },
            {
                path: 'closingpayouts',
                loadChildren: () =>
                    import('./modules/closing-payouts/closing-payouts.module').then(
                        (module) => module.ClosingPayoutsModule
                    )
            },
            {
                path: 'v2',
                loadChildren: () =>
                    import('./modules/all-deals/all-deals.module').then((module) => module.AllDealsModule)

                // @todo: do we need this?
                // canActivate: [AccessGuardService],
                // resolve: {
                //     denyGv: DenyGlobalViewResolver
                // },
                // data: {
                //     title: 'Deal',
                //     access: { anyRule: [
                //             {deals__edit_own: true},
                //             {deals__edit_any: true}
                //         ] },
                // },
                // canDeactivate: [PendingChangesGuardService],
            },
            {
                path: '',
                component: IndexComponent,
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        component: IndexComponent,
                        canActivate: [AccessGuardService],
                        data: {
                            title: 'Deals',
                            access: {rule: {deals__access: true}}
                        }
                    },
                    {
                        path: ':id',
                        component: ViewDealComponent,
                        canActivate: [AccessGuardService],
                        resolve: {
                            denyGv: DenyGlobalViewResolver
                        },
                        data: {
                            title: 'Deal',
                            access: {anyRule: [{deals__view_own: true}, {deals__view_any: true}]}
                        }
                    }
                ]
            },
            {
                path: 'edit/:id',
                component: EditDealComponent,
                canActivate: [RedirectToNewEditDealPageGuard, AccessGuardService],
                resolve: {
                    denyGv: DenyGlobalViewResolver
                },
                data: {
                    title: 'Deal',
                    access: {anyRule: [{deals__edit_own: true}, {deals__edit_any: true}]}
                },
                canDeactivate: [PendingChangesGuardService]
            }
        ]
    },
    {
        path: 'viewdepositrequest',
        component: GuestLayoutComponent,
        resolve: {
            guestBootstrap: PublicBootstrapService
        },
        children: [
            {
                path: ':request_hash',
                component: DepositRequestPublicPageComponent
            }
        ]
    }
];

export const routing = RouterModule.forChild(routes);
