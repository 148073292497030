<section class="sidebar-page" *ngIf="ledgerAccount">
    <div class="sidebar-wrapper">
        <app-banking-sidebar
            #sidebar
            (loadExternalTransactions)="externalTransactions.loadTransactions()"
            (loadInternalTransactions)="internalTransactions.loadTransactions()"
            (deselectTransaction)="deselectTransaction()"
            [isOpened]="isSidebarOpened"
            [ledgerAccountId]="ledgerAccountId"
            class="banking-bar"
        >
            <section class="sidebar-body" style="background-color: #fff; min-width: 1000px">
                <!------- Header ------->
                <header class="d-flex align-items-center mx-3" style="height: 60px; color: gray">
                    <h3
                        class="mb-0 d-flex action-icon text-decoration-underline align-items-center"
                        *ngIf="
                            accounts.length > 1 ||
                                (accounts.length === 1 &&
                                    ledgerAccount.ledger_account_id &&
                                    accounts[0].ledger_account_id.toString() !==
                                        ledgerAccount.ledger_account_id.toString());
                            else noMenu
                        "
                        [matMenuTriggerFor]="menuAccount"
                    >
                        {{ ledgerAccount?.name }}
                    </h3>
                    <mat-menu #menuAccount="matMenu">
                        <ng-container *ngFor="let account of accounts">
                            <button
                                mat-menu-item
                                class="mat-button"
                                *ngIf="account.ledger_account_id !== ledgerAccount.ledger_account_id"
                                (click)="router.navigate(['banking', account.ledger_account_id, 'transactions'])"
                            >
                                {{ account.name }}
                            </button>
                        </ng-container>
                    </mat-menu>
                    <ng-template #noMenu>
                        <h3 class="mb-0 d-flex align-items-center">
                            {{ ledgerAccount?.name }}
                        </h3>
                    </ng-template>

                    <span class="text-muted ml-2" *ngIf="isAvailableForFeed" matTooltip="Amount in Bank">
                        {{ ledgerAccount?.bank_balance | currency }}
                    </span>
                    <mat-icon
                        mat-icon-button
                        class="mx-1 sp-cursor-pointer activate-feed feed-activated"
                        *ngIf="!ledgerAccount?.activate_feed_from && isAvailableForFeed"
                        [anyRule]="[{banking__manage_bank_feed_automation: true}]"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        matTooltip="{{ getFeedTooltip(ledgerAccount) }}"
                        [matMenuTriggerFor]="menu"
                    >
                        wifi_off
                    </mat-icon>
                    <mat-icon
                        mat-icon-button
                        class="mx-1 sp-cursor-pointer activate-feed feed-activated"
                        *ngIf="ledgerAccount?.activate_feed_from && !isNeedToReLogin && !isNeedToReconnect"
                        [anyRule]="[{banking__manage_bank_feed_automation: true}]"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        matTooltip="{{ getFeedTooltip(ledgerAccount) }}"
                        [matMenuTriggerFor]="menu"
                    >
                        rss_feed
                    </mat-icon>
                    <mat-icon
                        mat-icon-button
                        class="mx-1 sp-cursor-pointer activate-feed"
                        style="color: red"
                        *ngIf="ledgerAccount?.activate_feed_from && (isNeedToReLogin || isNeedToReconnect)"
                        [anyRule]="[{banking__manage_bank_feed_automation: true}]"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        matTooltip="{{ getFeedTooltip(ledgerAccount) }}"
                        [matMenuTriggerFor]="menu"
                    >
                        rss_feed
                    </mat-icon>
                    <mat-menu #menu="matMenu">
                        <button
                            mat-menu-item
                            *ngIf="ledgerAccount?.status === 'active' && ledgerAccount.activate_feed_from"
                            (click)="fetchBankTransactions()"
                        >
                            Refresh
                        </button>

                        <button
                            mat-menu-item
                            *ngIf="
                                ledgerAccount?.status === 'active' &&
                                ledgerAccount.activate_feed_from &&
                                isNeedToReLogin
                            "
                            (click)="reLoginInBankAccount()"
                        >
                            Update Authentication
                        </button>

                        <button mat-menu-item (click)="showInstitutionInfo(ledgerAccount)">Show Bank Status</button>

                        <button mat-menu-item *ngIf="ledgerAccount.activate_feed_from" (click)="deactivateFeed()">
                            Stop Feed
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="!ledgerAccount.activate_feed_from && isAvailableForFeed"
                            (click)="activateFeed()"
                        >
                            Start Feed
                        </button>
                        <button
                            mat-menu-item
                            [disabled]="true"
                            *ngIf="isAvailableForDisconnect"
                            (click)="disconnectFeed()"
                        >
                            Disconnect Feed
                        </button>
                    </mat-menu>

                    <i class="sp-spacer"></i>
                    <button
                        mat-raised-button
                        class="ml-3"
                        [matMenuTriggerFor]="bulkmenu"
                        color="warn"
                        *ngIf="(externalTransactionIdsForBulk.length || isAllSelected) && selected.value === 0"
                    >
                        Bulk Action ({{ isAllSelected ? 'All' : externalTransactionIdsForBulk.length }})
                    </button>
                    <mat-menu #bulkmenu="matMenu">
                        <button
                            mat-menu-item
                            (click)="bulkDelete()"
                            *ngIf="filterExternalTransactions.value === 'not_deleted'"
                        >
                            Delete
                        </button>
                        <button
                            mat-menu-item
                            (click)="bulkRestore()"
                            *ngIf="filterExternalTransactions.value === 'deleted'"
                        >
                            Restore
                        </button>
                    </mat-menu>
                    <button
                        mat-raised-button
                        class="ml-2"
                        [rule]="{banking__view_import: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        *ngIf="selected.value === 0 && ledgerAccount?.status === 'active'"
                        (click)="redirectToImport()"
                    >
                        Import
                    </button>

                    <button
                        mat-raised-button
                        [matMenuTriggerFor]="groups"
                        class="ml-2"
                        style="min-width: fit-content"
                        *ngIf="ledgerAccount?.status === 'active' && selected.value === 1"
                    >
                        <mat-icon>add</mat-icon>
                        Add transaction
                    </button>
                    <mat-menu #groups="matMenu" xPosition="before">
                        <button mat-menu-item class="add-transaction-menu text-muted" disabled>MONEY OUT</button>
                        <ng-container *ngFor="let type of transactionTypes.moneyOut">
                            <button mat-menu-item class="add-transaction-menu-item" (click)="addTransaction(type)">
                                {{ type.label }}
                            </button>
                        </ng-container>

                        <button mat-menu-item class="add-transaction-menu text-muted" disabled>MONEY IN</button>
                        <ng-container *ngFor="let type of transactionTypes.moneyIn">
                            <button mat-menu-item class="add-transaction-menu-item" (click)="addTransaction(type)">
                                {{ type.label }}
                            </button>
                        </ng-container>
                    </mat-menu>

                    <div
                        matTooltip="You've already started a reconciliation and it is not yet reconciled. You need to complete it before you can start another reconciliation."
                        [matTooltipDisabled]="!lastReconciliation || lastReconciliation?.status === 'reconciled'"
                    >
                        <button
                            mat-raised-button
                            class="ml-2"
                            [disabled]="lastReconciliation && lastReconciliation?.status !== 'reconciled'"
                            *ngIf="selected.value === 2"
                            (click)="reconcileAccount()"
                        >
                            Reconcile
                        </button>
                    </div>
                </header>

                <div class="tabs-wrapper">
                    <div class="text-muted balance-container mr-3" *ngIf="zipiBalance || zipiBalance === 0">
                        <span>{{ zipiBalance | currency }}</span>
                        <span style="font-size: 0.875em">Amount in SkySlope Books</span>
                    </div>
                    <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="changeTab($event)">
                        <mat-tab
                            [rule]="{banking__view_bank_feed: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                        >
                            <ng-template mat-tab-label>
                                <div class="d-flex justify-content-center align-items-center" style="width: 225px">
                                    <span *ngIf="filterExternalTransactions.label">Bank Feed</span>
                                    <span
                                        *ngIf="
                                            filterExternalTransactions.label &&
                                            filterExternalTransactions.value !== 'not_deleted'
                                        "
                                        >&nbsp;({{ filterExternalTransactions?.label }})</span
                                    >
                                    <mat-icon
                                        mat-icon-button
                                        class="ml-1"
                                        (click)="$event.preventDefault()"
                                        [matMenuTriggerFor]="menu"
                                        >arrow_drop_down
                                    </mat-icon>
                                    <mat-menu #menu="matMenu" xPosition="before">
                                        <ng-container *ngFor="let filter of externalTransactionsFilters">
                                            <button
                                                class="filter-item"
                                                *ngIf="filter.value !== filterExternalTransactions.value"
                                                mat-menu-item
                                                (click)="filterTransactionsExternal(filter)"
                                            >
                                                <span>{{ filter.label }}</span>
                                            </button>
                                        </ng-container>
                                    </mat-menu>
                                </div>
                            </ng-template>
                            <app-external-transactions
                                #externalTransactions
                                [sidebar]="sidebar"
                                [selectedTransactions]="selectedExternalTransactions"
                                [ledgerAccount]="ledgerAccount"
                                (fetchTransactions)="fetchBankTransactions()"
                                (activateFeed)="activateFeed()"
                                (bulkSelect)="bulkSelect($event)"
                                (bulkSelectAll)="bulkSelectAll()"
                                [filterType]="filterExternalTransactions.value"
                                (toggleTransaction)="toggleExternalTransaction($event)"
                            ></app-external-transactions>
                        </mat-tab>
                        <mat-tab
                            [rule]="{banking__view_transactions: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                        >
                            <ng-template mat-tab-label>
                                <div class="d-flex justify-content-center align-items-center" style="width: 225px">
                                    <span *ngIf="filterInternalTransactions.label"
                                        >Transactions&nbsp;({{ filterInternalTransactions?.label }})</span
                                    >
                                    <mat-icon
                                        mat-icon-button
                                        class="ml-1"
                                        (click)="$event.preventDefault()"
                                        [matMenuTriggerFor]="menu"
                                        >arrow_drop_down
                                    </mat-icon>
                                    <mat-menu #menu="matMenu" xPosition="before">
                                        <ng-container *ngFor="let filter of transactionsFilters">
                                            <button
                                                class="filter-item"
                                                *ngIf="filter.value !== filterInternalTransactions.value"
                                                mat-menu-item
                                                (click)="filterTransactions(filter)"
                                            >
                                                <span>{{ filter.label }}</span>
                                                <mat-icon
                                                    mat-icon-button
                                                    class="ml-3 mr-0 default-icon"
                                                    [ngStyle]="
                                                        defaultTransactionsFilter === filter.value
                                                            ? {visibility: 'visible', color: '#444'}
                                                            : null
                                                    "
                                                    (click)="$event.stopPropagation(); setDefaultFilter(filter.value)"
                                                >
                                                    grade
                                                </mat-icon>
                                            </button>
                                        </ng-container>
                                    </mat-menu>
                                </div>
                            </ng-template>
                            <app-internal-transactions
                                #internalTransactions
                                [selectedTransaction]="selectedTransaction"
                                [ledgerAccount]="ledgerAccount"
                                [filterType]="filterInternalTransactions.value"
                                [sidebar]="sidebar"
                                (toggleTransaction)="toggleInternalTransaction($event)"
                            ></app-internal-transactions>
                        </mat-tab>
                        <mat-tab
                            label="Reconciliation"
                            [rule]="{banking__view_reconciliation: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                        >
                            <app-account-reconciliations
                                #reconciliations
                                [ledgerAccount]="ledgerAccount"
                                (loadInternalTransactions)="internalTransactions.loadInitTransactions()"
                                (loadLastReconcile)="loadLastReconcile()"
                            ></app-account-reconciliations>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </section>
        </app-banking-sidebar>
    </div>
</section>
