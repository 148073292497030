<div>
    <!-- Run report if it has reconciled transactions, we can know it by reportData.bookBalance.endDate -->
    <mat-card *ngIf="reportData && reportData.bookBalance && reportData.bookBalance.endDate">
        <p class="m-0">
            <strong class="status-label"
                >Status:
                <span>
                    {{
                        reportData.bookBalance.total === reportData.bookBalance.total &&
                        reportData.bookBalance.total === reportData.contactTotal
                            ? 'Reconciled'
                            : 'Unreconciled'
                    }}
                </span>
            </strong>
        </p>
        <p class="bank-info">
            <strong>
                <span>Bank Information: {{ reportData.ledgerAccount.bank_name }}</span>
                <span *ngIf="reportData.ledgerAccount.la_name || reportData.ledgerAccount.la_code"
                    >, {{ reportData.ledgerAccount.la_name }} {{ reportData.ledgerAccount.la_code }}
                </span>
            </strong>
        </p>
        <table class="report-table w-100">
            <thead>
                <tr>
                    <th class="label">Book Balance</th>
                    <th>Date</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="label">Beginning</td>
                    <td>{{ reportData.periodData.startDate | date: 'shortDate' }}</td>
                    <td>{{ reportData.bookBalance.begin | reportFormatted: 'financial' }}</td>
                </tr>
                <tr>
                    <td class="label">Cleared Deposits</td>
                    <td></td>
                    <td>{{ reportData.bookBalance.debit | reportFormatted: 'financial' }}</td>
                </tr>
                <tr>
                    <td class="label">Cleared Payments</td>
                    <td></td>
                    <td>
                        <span *ngIf="reportData.bankBalance.credit > 0">
                            ({{ reportData.bookBalance.credit | reportFormatted: 'financial' }})
                        </span>
                        <span *ngIf="reportData.bankBalance.credit === 0">
                            {{ reportData.bookBalance.credit | reportFormatted: 'financial' }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td class="label">Reconciled Book Balance</td>
                    <td>{{ reportData.bookBalance.endDate | date: 'shortDate' }}</td>
                    <td>{{ reportData.bookBalance.total | reportFormatted: 'financial' }}</td>
                </tr>
                <tr>
                    <td colspan="3"></td>
                    <td class="no-border" colspan="5"></td>
                </tr>
                <tr>
                    <td class="label"><strong>Bank Balance</strong></td>
                    <td><strong>Date</strong></td>
                    <td><strong>Amount</strong></td>
                </tr>
                <tr>
                    <td class="label">Statement Balance</td>
                    <td>{{ reportData.bankBalance.statementBankDate | date: 'shortDate' }}</td>
                    <td>
                        <span [matTooltip]="bankBalanceTooltip">
                            {{ reportData.bankBalance.begin | reportFormatted: 'financial' }}
                            <span>*</span>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td class="label">Deposits in Transit</td>
                    <td></td>
                    <td>
                        <span [matTooltip]="bankBalanceTooltip">
                            {{ reportData.bankBalance.debit | reportFormatted: 'financial' }}
                            <span>*</span>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td class="label">Outstanding Payments</td>
                    <td></td>
                    <td>
                        <span [matTooltip]="bankBalanceTooltip">
                            <ng-container *ngIf="reportData.bankBalance">
                                <span *ngIf="reportData.bankBalance.credit > 0">
                                    ({{ reportData.bankBalance.credit | reportFormatted: 'financial' }})
                                </span>
                                <span *ngIf="reportData.bankBalance.credit === 0">
                                    {{ reportData.bankBalance.credit | reportFormatted: 'financial' }}
                                </span>
                            </ng-container>
                            <span>*</span>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td class="label">Reconciled Bank Balance</td>
                    <td></td>
                    <td>{{ reportData.bankBalance.total | reportFormatted: 'financial' }}</td>
                </tr>
            </tbody>
        </table>

        <table class="report-table w-100" *ngIf="reportData.contacts && reportData.contacts.length">
            <thead>
                <tr>
                    <th class="label">Balances from Contacts</th>
                    <th></th>
                    <th>Amount</th>
                    <th>Contact</th>
                    <th>Deal</th>
                    <th>Cleared</th>
                    <th>Uncleared</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let contact of reportData.contacts">
                    <td></td>
                    <td></td>
                    <td>{{ contact.amount_sum }}</td>
                    <td>{{ contact.contact }}</td>
                    <td>{{ contact.address }}</td>
                    <td>{{ contact.cleared_amount }}</td>
                    <td>{{ contact.uncleared_amount }}</td>
                </tr>
                <tr>
                    <td class="label"><strong>Total Balance from Contacts</strong></td>
                    <td>{{ reportData.periodData.endDate | date: 'shortDate' }}</td>
                    <td>{{ reportData.contactTotal }}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </mat-card>

    <mat-card class="preview-body" *ngIf="!reportData || !reportData.bookBalance || !reportData.bookBalance.endDate">
        <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
    </mat-card>
</div>
