<section class="container-fluid py-4 px-5">
    <header class="mb-4">
        <h1 *ngIf="!creditNote">Create Credit Note</h1>
        <h1 *ngIf="creditNote">Edit Credit Note</h1>
    </header>

    <div class="mb-3">
        <div class="mb-4">
            <section [formGroup]="formGroup">
                <div class="row">
                    <div class="col-3">
                        <app-company-compensation-combined-picker
                            style="width: 100%; margin-right: 8px"
                            [availableTypes]="['contact']"
                            [title]="'Customer'"
                            [only_compensation_groups]="false"
                            [singleSelected]="true"
                            [bold_style]="false"
                            [nodesFA]="moneySenderCtrlArr"
                            [initialContactIds]="savedContacts"
                        >
                        </app-company-compensation-combined-picker>
                    </div>
                    <div class="col-3">
                        <mat-form-field>
                            <input
                                matInput
                                formControlName="credit_note_number"
                                placeholder="Credit Note#"
                                type="text"
                            />
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field>
                            <input matInput formControlName="reference" placeholder="Reference" type="text" />
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <app-date-picker
                            [placeholder]="'Credit Note Date'"
                            [dateControl]="formGroup.controls.credit_note_date"
                        ></app-date-picker>
                    </div>
                    <div class="col-3">
                        <app-company-compensation-combined-picker
                            style="width: 100%; margin-right: 8px"
                            [availableTypes]="['individual']"
                            [title]="'Sales Person'"
                            [singleSelected]="true"
                            [bold_style]="false"
                            [nodesFA]="selectedProfileArr"
                            [initialProfileIds]="selectedProfileIds"
                        ></app-company-compensation-combined-picker>
                    </div>
                    <div class="row mb-3">
                        <mat-slide-toggle
                            [formControl]="formGroup.controls.allow_auto_apply"
                            class="col align-self-center"
                        >
                            Auto-apply to future Invoices based on Recurring Invoices.
                        </mat-slide-toggle>
                    </div>
                </div>
                <!------- Items Header ------->
                <h3 class="d-flex align-items-center">
                    Items
                    <button
                        type="button"
                        mat-icon-button
                        (click)="addItem()"
                        matTooltip="Add item"
                        *ngIf="!disableEditItems"
                    >
                        <mat-icon>add</mat-icon>
                    </button>
                </h3>
                <!------- Items ------->
                <div class="overflow-auto">
                    <div
                        class="d-grid-items-container"
                        *ngFor="let itemGroup of itemsArray.controls; index as idx; first as first"
                        [ngClass]="groupList.length ? 'with-groups' : 'no-groups'"
                    >
                        <div class="d-grid-items" [formGroup]="itemGroup">
                            <!------- Product/Service ------->
                            <div class="mx-2">
                                <ng-container *ngIf="!itemGroup.controls.product_fk_id.value">
                                    <mat-form-field class="w-100">
                                        <input
                                            formControlName="name"
                                            type="text"
                                            matInput
                                            placeholder="Product item"
                                            [matAutocomplete]="auto"
                                        />
                                        <mat-autocomplete #auto="matAutocomplete">
                                            <mat-option (click)="$event.stopPropagation(); createProduct()"
                                                >Create New Product</mat-option
                                            >

                                            <ng-container *ngFor="let product of getProducts(itemGroup)">
                                                <mat-option
                                                    *ngIf="
                                                        product.status === 'active' ||
                                                        itemGroup.controls.product_fk_id.value === product.product_id
                                                    "
                                                    [value]="product.name"
                                                    [disabled]="
                                                        ['closing_debit', 'closing_credit'].includes(product.system_key)
                                                    "
                                                    (onSelectionChange)="selectItem(itemGroup, product)"
                                                >
                                                    {{ product.name }}
                                                </mat-option>
                                            </ng-container>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="itemGroup.controls.product_fk_id.value">
                                    <mat-form-field class="w-100">
                                        <input formControlName="name" matInput type="text" />
                                        <button
                                            mat-button
                                            matSuffix
                                            mat-icon-button
                                            aria-label="Clear"
                                            *ngIf="!disableEditItems"
                                            (click)="removeProductFkId(itemGroup)"
                                        >
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </ng-container>
                            </div>
                            <!------- Description ------->
                            <div class="mx-2">
                                <mat-form-field class="w-100">
                                    <input
                                        matInput
                                        formControlName="description"
                                        placeholder="Description"
                                        type="text"
                                    />
                                </mat-form-field>
                            </div>
                            <!------- Quantity ------->
                            <div class="mx-2">
                                <mat-form-field class="w-100">
                                    <input
                                        matInput
                                        formControlName="quantity"
                                        placeholder="Quantity"
                                        type="number"
                                        min="1"
                                    />
                                </mat-form-field>
                            </div>
                            <!------- Rate ------->
                            <div class="mx-2">
                                <mat-form-field class="w-100">
                                    <input
                                        matInput
                                        formControlName="rate"
                                        placeholder="Rate"
                                        [maskito]="currencyMaskitoMask"
                                    />
                                </mat-form-field>
                            </div>
                            <!------- Amount ------->
                            <div class="mx-2">
                                <mat-form-field class="w-100">
                                    <input
                                        matInput
                                        formControlName="amount"
                                        placeholder="Amount"
                                        type="text"
                                        [maskito]="currencyMaskitoMask"
                                    />
                                </mat-form-field>
                            </div>
                            <!------- Ledger Account ------->
                            <div class="mx-2">
                                <app-ledger-account-selector
                                    class="w-100"
                                    [ledgerAccountControl]="itemGroup.controls.ledger_account_fk_id"
                                    [ledgerAccountFullControl]="itemGroup.controls.selected_full_ledger"
                                    [disabledReselect]="!!itemGroup.controls.product_fk_id.value || disableEditItems"
                                    [placeholder]="'Account'"
                                >
                                </app-ledger-account-selector>
                            </div>
                            <!--<app-combined-entity-picker
                                class="mx-2"
                                [availableTypes]="['company_group']"
                                [title]="'Assign to Division'"
                                [only_compensation_groups]="false"
                                [onlyDivisions]="true"
                                [initialGroupIds]="[itemGroup.get('selected_division_ids').value]"
                                [nodesFA]="itemGroup.get('selected_divisions')"
                                [singleSelected]="true"
                                [bold_style]="false"
                            ></app-combined-entity-picker>-->
                            <!------- Assign to Division ------->
                            <div class="mx-2" *ngIf="groupList.length">
                                <app-division-picker
                                    [isNeedToBeFiltered]="
                                        itemGroup.controls.selected_full_ledger.value
                                            ? !itemGroup.controls.selected_full_ledger.value.is_accessible_for_all
                                            : false
                                    "
                                    [filterById]="
                                        itemGroup.controls.selected_full_ledger.value
                                            ? itemGroup.controls.selected_full_ledger.value.accessible_for
                                            : []
                                    "
                                    [nodesFA]="itemGroup.get('selected_divisions')"
                                    [initialGroupId]="itemGroup.controls.selected_division_ids.value"
                                    [isTotallyDisabled]="!itemGroup.controls.ledger_account_fk_id.value"
                                    [placeholder]="'Assign to Division'"
                                >
                                </app-division-picker>
                            </div>
                        </div>
                        <!------- Actions ------->
                        <div class="actions" *ngIf="!disableEditItems">
                            <button
                                type="button"
                                mat-icon-button
                                matTooltip="Clear Item"
                                *ngIf="itemsArray.length === 1"
                                (click)="resetItem(itemGroup)"
                            >
                                <mat-icon>close</mat-icon>
                            </button>
                            <button
                                type="button"
                                mat-icon-button
                                matTooltip="Remove Item"
                                *ngIf="itemsArray.length > 1"
                                (click)="itemsArray.removeAt(idx)"
                            >
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-end align-items-baseline my-2">
                    <span class="mr-2"> Total amount: &nbsp; </span>
                    <strong class="zp-total-amount">
                        {{ formGroup.controls.total_amount.value | currency }}
                    </strong>
                </div>
            </section>
        </div>

        <div class="d-flex">
            <div
                class="ml-2"
                matTooltip="You cannot modify Credit Note associated with Deal."
                [matTooltipDisabled]="creditNote && !creditNote.source__deal_fk_id"
            >
                <button
                    *ngIf="creditNote"
                    mat-raised-button
                    color="warn"
                    [disabled]="creditNote && !!creditNote.source__deal_fk_id"
                    (click)="deleteCreditNote()"
                >
                    Delete
                </button>
            </div>

            <span class="sp-spacer"></span>

            <button mat-raised-button class="mr-3" routerLink="/sales/creditnotes">Cancel</button>

            <mat-menu #appMenu="matMenu">
                <button *ngIf="!creditNote" mat-menu-item (click)="createCreditNoteAsDraft()">Create as Draft</button>

                <button *ngIf="!creditNote" mat-menu-item (click)="createCreditNoteAsOpen()">Create as Open</button>
            </mat-menu>

            <button mat-raised-button color="primary" *ngIf="!creditNote" [matMenuTriggerFor]="appMenu">Create</button>

            <div
                matTooltip="You cannot modify Credit Note associated with Deal."
                [matTooltipDisabled]="creditNote && !creditNote.source__deal_fk_id"
            >
                <button
                    *ngIf="creditNote"
                    mat-raised-button
                    color="primary"
                    (click)="updateCreditNote()"
                    [disabled]="creditNote && !!creditNote.source__deal_fk_id"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</section>
