import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';

export class SignInModel {
    email?: string;
    password?: string;
    submit?: boolean;
}

export class SignInFormGroup extends UntypedFormGroup {
    // @ts-ignore
    public controls: {
        email: UntypedFormControl;
        password: UntypedFormControl;
        submit: UntypedFormControl;
    };
    constructor(email: string) {
        const controls = {
            email: new UntypedFormControl({value: email, disabled: !!email}, [Validators.email, Validators.required]),
            password: new UntypedFormControl('', Validators.required),
            submit: new UntypedFormControl(false, Validators.requiredTrue)
        };
        super(controls);
        // this.controls = controls;
    }
    getRawValue(): SignInModel {
        return <SignInModel>super.getRawValue();
    }
    patchValue(value: SignInModel) {
        super.patchValue(value);
    }
}
