import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PaymentsReceivedService} from '../../../services/payments-received.service';
import {Subject} from 'rxjs';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {NotificationsService} from 'angular2-notifications';
import {PAYMENT_MODES_MAP, PAYMENT_CREDITS_STATUS_COLOR, PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {SessionService} from 'app/services/session.service';
import {ProfilesService} from 'app/services/profiles.service';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {MatDialog} from '@angular/material/dialog';
import {IPaymentReceived, ISettings} from '@cyberco-nodejs/zipi-typings';
import {SelectionModel} from '@angular/cdk/collections';
import {UntypedFormControl} from '@angular/forms';
import {MatOptionSelectionChange} from '@angular/material/core';
import {IScrollData} from 'app/models/scroll-data';
import {MatPaginator} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';

@Component({
    selector: 'app-payments-received-list',
    templateUrl: 'payments-received-list.component.html',
    styleUrls: ['payments-received-list.component.scss', '../../../../../../assets/infinite-scroll-table.scss']
})
export class PaymentsReceivedListComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;

    selection: SelectionModel<number> = new SelectionModel<number>(true, []);

    statusColor = PAYMENT_CREDITS_STATUS_COLOR;
    statusTypes = [
        'all',
        'processing',
        'completed',
        'scheduled',
        'expired',
        'declined',
        'reversed',
        'canceled',
        'review',
        'pending',
        'error'
    ];
    statusFilter: UntypedFormControl = new UntypedFormControl([]);

    paymentModesMap = PAYMENT_MODES_MAP;

    dataSource: MatTableDataSource<IPaymentReceived>;
    displayedColumns = [
        'checkbox',
        'paid_date',
        'summary_status',
        'payment_received_number',
        'customer_name',
        'reference',
        'payment_mode',
        'amount',
        'amount_applied',
        'action'
    ];

    scrollData: IScrollData = {
        offset: 0,
        limit: 50,
        sort_column: 'paid_date',
        sort_direction: 'asc',
        filter: null,
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    isSelectedForBulk: boolean;

    constructor(
        protected currentProfileSource: CurrentProfileSource,
        private paymentsReceivedService: PaymentsReceivedService,
        public sessionService: SessionService,
        private profileService: ProfilesService,
        private ntfs: NotificationsService,
        public dialog: MatDialog,
        public router: Router
    ) {
        this.isSelectedForBulk = false;
        this.dataSource = new MatTableDataSource<IPaymentReceived>([]);
    }

    ngOnInit() {
        if (this.sessionService.profile && this.sessionService.profile.settings) {
            this.scrollData.sort_direction = this.sessionService.profile.settings.sort_payments_received_direction;
            this.scrollData.sort_column = this.sessionService.profile.settings.sort_payments_received_column;
        }

        this.nextBatch();

        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.nextBatch();
            });
        }
    }

    onSortChange() {
        if (this.sessionService.profile) {
            const id = Number(this.sessionService.profile.id);
            const settings: ISettings = this.sessionService.profile.settings!;

            this.profileService
                .updateProfileSettings(id, settings)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response) {
                        this.currentProfileSource.triggers.changeRequesterProfile.next(response.result);
                    }
                });

            this.resetData();
        }
    }

    resetData() {
        // update values
        this.paginator!.pageIndex = 0;
        this.scrollData.offset = 0;

        this.nextBatch();
    }

    nextBatch() {
        // cancel previous batch requests
        this.unsubscribeBatch.next();

        if (this.statusFilter.value && !this.statusFilter.value.includes('all')) {
            this.scrollData.filter = this.statusFilter.value;
        } else {
            this.scrollData.filter = '';
        }

        this.paymentsReceivedService
            .getPaymentsReceived(this.scrollData)
            .pipe(
                tap((data) => {
                    this.dataSource.data = data.result;
                    this.scrollData.total = data._meta.total;
                }),
                takeUntil(this.unsubscribeBatch)
            )
            .subscribe();
    }

    recheckPaymentStatus() {
        this.paymentsReceivedService
            .recheckCompanyReceivedPaymentsStatuses()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isStatusesWasChanged) => {
                if (isStatusesWasChanged) {
                    this.ngOnInit();
                } else {
                    this.ntfs.warn(`Statuses have not changed.`);
                }
            });
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }
        if (this.sessionService.profile && this.sessionService.profile.settings) {
            this.sessionService.profile.settings.sort_payments_received_direction = this.scrollData.sort_direction;
            this.sessionService.profile.settings.sort_payments_received_column = this.scrollData.sort_column;
            const id = Number(this.sessionService.profile.id);
            const settings = this.sessionService.profile.settings;
            this.profileService
                .updateProfileSettings(id, settings)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response) {
                        this.currentProfileSource.triggers.changeRequesterProfile.next(response.result);
                    }
                });

            this.resetData();
        }
    }

    toggleBulkCheckbox($event: any, id: number) {
        if ($event) {
            this.selection.toggle(id as never); // dirty hack
        }
        this.isSelectedForBulk = this.selection.selected.length !== 0;
    }

    bulkDelete() {
        const paymentReceivedIdsForBulk = this.selection.selected;
        if (paymentReceivedIdsForBulk.length > 0) {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                minWidth: 320,
                data: {
                    title: 'Deleting Payments Received',
                    message: `${paymentReceivedIdsForBulk.length} Payments Received will be deleted.`,
                    messages: [
                        `If there is a payment related to the invoice or related journal has matched or reconciled transaction, it will not be deleted.`,
                        `You must first remove payments from the Payment Received, unmatch and unreconcile related transaction.`
                    ]
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((ok) => {
                    if (ok) {
                        this.paymentsReceivedService
                            .bulkDeletePaymentsReceived(paymentReceivedIdsForBulk)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((res) => {
                                this.isSelectedForBulk = false;
                                this.selection.clear();
                                this.dataSource.data = [];
                                this.resetData();
                                const successfulDeleted = res.deleted_payments
                                    ? `<br>${res.deleted_payments} Deleted`
                                    : '';
                                const notDeleted = res.not_deleted_payments
                                    ? `<br>${res.not_deleted_payments} Not Deleted`
                                    : '';

                                this.ntfs.success(`Payments:${successfulDeleted}${notDeleted}`);
                            });
                    }
                });
        }
    }

    changeStatusFilter(event: MatOptionSelectionChange) {
        if (event.isUserInput) {
            const {value, selected} = event.source;
            let statusFilter = this.statusFilter.value;

            if (value === 'all') {
                statusFilter = selected ? this.statusTypes : [];
            } else if (value !== 'all') {
                if (statusFilter.includes('all') && !selected) {
                    statusFilter = statusFilter.filter((f: string) => f !== 'all' && f !== value);
                } else {
                    statusFilter = this.statusTypes.filter(
                        (f) => (f !== value && statusFilter.includes(f)) || (f === value && selected)
                    );
                }
            }

            // update values
            this.scrollData.total = 0;
            this.statusFilter.setValue(statusFilter);
            this.resetData();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
    }
}
