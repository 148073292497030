<div id="dialog" class="delete-warning-container">
    <div mat-dialog-title class="header">
        Delete Deal<ng-container *ngIf="!router.url.includes('/edit/')">s</ng-container>
    </div>

    <mat-card-content>
        <div class="description-text">
            <ng-container *ngIf="router.url.includes('/edit/') && data.dualDealExists">
                Note: This Deal currently has a dual connection. Please confirm how you would like to proceed:
            </ng-container>
            <ng-container *ngIf="!router.url.includes('/edit/') && data.dualDealExists">
                Note: This action will break any related Dual Agency relationship.
            </ng-container>
            <ng-container *ngIf="!data.dualDealExists">
                Please confirm you would like to delete
                <ng-container *ngIf="router.url.includes('/edit/'); else theseDeals">this Deal</ng-container>
                <ng-template #theseDeals>these Deals</ng-template>
                and all
                <ng-container *ngIf="router.url.includes('/edit/'); else their">its</ng-container>
                <ng-template #their>their</ng-template>
                data
            </ng-container>
        </div>
    </mat-card-content>

    <div mat-dialog-actions class="d-flex justify-content-between">
        <button mat-raised-button class="mat-default" (click)="dialogRef.close(false)">Cancel</button>
        <div *ngIf="router.url.includes('/edit/') && data.dualDealExists; else dealList">
            <button mat-raised-button class="mat-primary" (click)="dialogRef.close('only')">
                <span>Delete</span><span class="font-weight-bold mx-1">Only This</span><span>Deal</span>
            </button>
            <button mat-raised-button class="mat-primary" (click)="dialogRef.close('both')">
                <span>Delete</span><span class="font-weight-bold mx-1">Both</span><span>Deals</span>
            </button>
        </div>
        <ng-template #dealList>
            <button mat-raised-button class="mat-primary" (click)="dialogRef.close(true)">Delete</button>
        </ng-template>
    </div>
</div>
