import {Subject} from 'rxjs';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CurrentProfileSource} from '../../../../../services/sources/current-profile.source';
import {Profile} from '../../../../../models/profile';
import {GenericFormArray, GenericFormGroup} from '../../../../../entites/generic.entity';
import {SalesEntity} from '../../../../../models/sales-entity';
import {GroupMemberEntity} from '../../../../../models/group-member.model';
import {RbacService} from '../../../../rbac/rbac.service';
import {CompensationPlanModel} from '../../../../account-info/compensation/models/compensation-plan.model';
import {DealProcessingService} from '../deal-processing.service';
import {FinancialTransferEntity} from '../../../../account-info/compensation/models/financial-transfer.entity';
import {InvoicesService} from '../../../../../services/api/finance/invoices.service';
import {LedgerAccountService} from '../../../../../services/api/finance/ledger-accounts.service';
import {LoansService} from '../../../../../services/api/finance/loans.service';
import {IInvoice, ILedgerAccount, ILoan, IProduct} from '@cyberco-nodejs/zipi-typings';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {FinancialElementModel} from '../../../../account-info/compensation/models/financial-element.model';
import {MatDialog} from '@angular/material/dialog';
import {MatAccordion} from '@angular/material/expansion';
import {ProductsService} from '../../../../../services/api/finance/products.service';
import {CreateProductDialogComponent} from '../../../../finance/components/products/create-product-dialog/create-product-dialog.component';
import {FetchProducts} from '../../../../finance/store/finance.actions';
import {Store} from '@ngrx/store';
import {IFinanceState} from '../../../../finance/store/finance.reducer';
import {UntypedFormControl} from '@angular/forms';
import {Deal} from '../../../../../models/deal';
import {FeatureFlagsService} from '../../../../feature-flags/feature-flags.service';

@Component({
    selector: 'app-sale-entity-item',
    styles: [
        `
            .actions {
                justify-content: flex-end;
                align-items: center;
                display: flex;
                opacity: 0.54;
            }
            .bold {
                font-weight: bold;
            }
            .nowrap {
                white-space: nowrap;
            }
            .sales-entity-input-block {
                /*font-size: small;*/
                font-size: 10px;
                width: 100px;
            }
        `
    ],
    styleUrls: ['./deal-expenses/deal-expenses.component.scss'],
    template: `
        <mat-expansion-panel
            [disabled]="
                !(
                    canCreateAnyDeals ||
                    (isPrimarySalesEntity && canCreateOwnDeals) ||
                    canEditAnyDeals ||
                    (isPrimarySalesEntity && canEditOwnDeals) ||
                    isOwnProfileSalesEntity(saleEntityFormGroup.getRawValue())
                )
            "
            class="mb-3"
            (opened)="isOpened()"
        >
            <mat-expansion-panel-header [collapsedHeight]="'64px'" (click)="clickOnPanel.emit()">
                <mat-panel-title style="align-items: center; width:100%; margin-right: 24px">
                    <div class="d-flex w-100 align-items-center h-100">
                        <!------- Icon ------->
                        <div class="actions mr-3">
                            <mat-icon
                                matTooltip="Primary"
                                *ngIf="
                                    saleEntityFormGroup.controls.role &&
                                    saleEntityFormGroup.controls.role.value === SALES_ENTITY.role_SET.agent &&
                                    saleEntityFormGroup.controls.is_primary &&
                                    saleEntityFormGroup.controls.is_primary.value
                                "
                            >
                                grade
                            </mat-icon>
                            <mat-icon
                                matTooltip="Agent"
                                *ngIf="
                                    saleEntityFormGroup.controls.role &&
                                    saleEntityFormGroup.controls.role.value === SALES_ENTITY.role_SET.agent &&
                                    saleEntityFormGroup.controls.is_primary &&
                                    !saleEntityFormGroup.controls.is_primary.value
                                "
                            >
                                account_circle
                            </mat-icon>
                            <mat-icon
                                matTooltip="Transfer"
                                *ngIf="
                                    saleEntityFormGroup.controls.role &&
                                    saleEntityFormGroup.controls.role.value === SALES_ENTITY.role_SET.transfer
                                "
                            >
                                swap_horizontal_circle
                            </mat-icon>
                            <mat-icon
                                matTooltip="Referral"
                                *ngIf="
                                    saleEntityFormGroup.controls.role &&
                                    saleEntityFormGroup.controls.role.value === SALES_ENTITY.role_SET.referral
                                "
                            >
                                supervised_user_circle
                            </mat-icon>
                        </div>

                        <!------- Sales Entity Name ------->
                        <div
                            *ngIf="
                                saleEntityFormGroup.controls.is_primary && saleEntityFormGroup.controls.is_primary.value
                            "
                            class="d-flex justify-content-between w-100"
                        >
                            <div style="display:flex; align-items: center; margin-right: 5px">
                                <span>{{ saleEntityFormGroup.getRawValue(SALES_ENTITY).contact.display_name }}</span>
                                <button
                                    mat-icon-button
                                    (click)="$event.stopPropagation()"
                                    [routerLink]="[
                                        '/contacts',
                                        saleEntityFormGroup.getRawValue(SALES_ENTITY).contact.contact_id
                                    ]"
                                    fragment="payment-methods"
                                    *ngIf="
                                        saleEntityFormGroup.getRawValue(SALES_ENTITY).has_zipi_pay ||
                                        saleEntityFormGroup.getRawValue(SALES_ENTITY).has_receive_only
                                    "
                                >
                                    <mat-icon [matTooltip]="'Direct deposits enabled'" [matTooltipClass]="'my-tooltip'">
                                        monetization_on
                                    </mat-icon>
                                </button>
                                <span
                                    class="font-weight-bold ml-1"
                                    *ngIf="
                                        saleEntityFormGroup.getRawValue().profile?.deleted_at ||
                                        saleEntityFormGroup.getRawValue().company_group?.deleted_at
                                    "
                                >
                                    (deleted)
                                </span>
                            </div>
                        </div>
                        <!------- Sales Entity Additional Name ------->
                        <div
                            *ngIf="
                                saleEntityFormGroup.controls.is_primary &&
                                !saleEntityFormGroup.controls.is_primary.value
                            "
                            class="d-flex justify-content-between w-100"
                        >
                            <div style="display:flex; align-items: center; margin-right: 5px">
                                <span>{{ saleEntityFormGroup.getRawValue(SALES_ENTITY).contact.display_name }}</span>
                                <span
                                    *ngIf="
                                        saleEntityFormGroup.controls.type &&
                                        saleEntityFormGroup.controls.type.value === SALES_ENTITY.type_SET.profile &&
                                        !saleEntityFormGroup.getRawValue().profile?.deleted_at &&
                                        hasReferralTransfers(saleEntityFormGroup)
                                    "
                                    class="ml-1"
                                >
                                    (REFERRAL)
                                </span>
                                <span
                                    *ngIf="
                                        saleEntityFormGroup.getRawValue().profile?.deleted_at ||
                                        saleEntityFormGroup.getRawValue().company_group?.deleted_at ||
                                        saleEntityFormGroup.getRawValue().contact?.deleted_at
                                    "
                                    class="font-weight-bold ml-1"
                                >
                                    {{ saleEntityFormGroup.getRawValue(SALES_ENTITY).contact.display_name }} (deleted)
                                </span>
                                <button
                                    mat-icon-button
                                    (click)="$event.stopPropagation()"
                                    [routerLink]="[
                                        '/contacts',
                                        saleEntityFormGroup.getRawValue(SALES_ENTITY).contact.contact_id
                                    ]"
                                    fragment="payment-methods"
                                    *ngIf="
                                        saleEntityFormGroup.getRawValue(SALES_ENTITY).has_zipi_pay ||
                                        saleEntityFormGroup.getRawValue(SALES_ENTITY).has_receive_only
                                    "
                                >
                                    <mat-icon [matTooltip]="'Direct deposits enabled'" [matTooltipClass]="'my-tooltip'">
                                        monetization_on
                                    </mat-icon>
                                </button>
                            </div>
                        </div>
                        <!------- Pay at Closing Picker ------->
                        <ng-container
                            *ngIf="
                                saleEntityFormGroup.controls.is_primary &&
                                saleEntityFormGroup.controls.payout &&
                                ((!saleEntityFormGroup.controls.is_primary.value &&
                                    saleEntityFormGroup.controls.role &&
                                    saleEntityFormGroup.controls.role.value === SALES_ENTITY.role_SET.agent) ||
                                    saleEntityFormGroup.controls.is_primary.value)
                            "
                        >
                            <app-pay-at-closing-picker
                                style="z-index: 2; padding-left: 5px; font-size: smaller; margin-top: -5px"
                                class="d-flex align-items-baseline h-100"
                                [financialTransferFG]="saleEntityFormGroup.controls.payout"
                                [disabled]="saleEntityFormGroup.disabled"
                                (click)="$event.stopPropagation()"
                            >
                            </app-pay-at-closing-picker>
                        </ng-container>
                    </div>
                </mat-panel-title>
                <mat-panel-description class="d-flex align-items-center">
                    <!------- Header % of Deal: can ------->
                    <div
                        *ngIf="
                            (canEditAnyDeals ||
                                (isPrimarySalesEntity && canEditOwnDeals) ||
                                canCreateAnyDeals ||
                                (isPrimarySalesEntity && canCreateOwnDeals)) &&
                            saleEntityFormGroup.controls.role &&
                            saleEntityFormGroup.controls.role.value === SALES_ENTITY.role_SET.agent
                        "
                        class="w-100 d-flex align-items-center justify-content-end"
                    >
                        <div class="d-flex flex-column bold sales-entity-input-block">
                            <span class="nowrap">% Deal Split</span>
                            <ng-container *ngIf="showSideCountAndSalesVolumeFlag">
                                <span class="nowrap">% Sales Volume</span>
                                <span class="nowrap">Side Count</span>
                            </ng-container>
                        </div>
                        <div class="d-flex flex-column sales-entity-input-block">
                            <div
                                class="d-flex justify-content-end"
                                *ngIf="saleEntityFormGroup.controls.participant_split"
                            >
                                <span>{{
                                    participantSplit.controls.percent
                                        ? (participantSplit.controls.percent.value | roundToDecimal)
                                        : 0
                                }}</span>
                                <span class="nowrap">% of Deal</span>
                            </div>
                            <ng-container *ngIf="showSideCountAndSalesVolumeFlag">
                                <div class="d-flex justify-content-end">
                                    <span>{{
                                        saleEntityFormGroup.controls.sales_volume_percent
                                            ? (saleEntityFormGroup.controls.sales_volume_percent.value | roundToDecimal)
                                            : 0
                                    }}</span>
                                    <span class="nowrap">% of Deal</span>
                                </div>
                                <div class="d-flex justify-content-end">
                                    <span>{{
                                        saleEntityFormGroup.controls.side_count
                                            ? (saleEntityFormGroup.controls.side_count.value
                                              | roundToDecimal
                                              | number: '1.2-2')
                                            : 0
                                    }}</span>
                                    <span class="nowrap ml-1">Deal</span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <!------- Header % of Deal: cannot ------->
                    <div
                        *ngIf="
                            !(
                                canEditAnyDeals ||
                                (isPrimarySalesEntity && canEditOwnDeals) ||
                                canCreateAnyDeals ||
                                (isPrimarySalesEntity && canCreateOwnDeals)
                            )
                        "
                        class="w-100 d-flex align-items-center justify-content-between"
                    >
                        <div style="flex-direction: row; display: flex; justify-content: flex-end; width: 100%;">
                            <ng-container
                                *ngIf="
                                    saleEntityFormGroup.controls.is_primary &&
                                    !saleEntityFormGroup.controls.is_primary.value
                                "
                            >
                                <span
                                    *ngIf="
                                        saleEntityFormGroup.controls.compensation_plan &&
                                        saleEntityFormGroup.controls.compensation_plan.controls.compensation_type &&
                                        saleEntityFormGroup.controls.compensation_plan.controls.compensation_type
                                            .value === 'deal' &&
                                        saleEntityFormGroup.controls.compensation_plan.controls.compensation_value
                                    "
                                >
                                    {{
                                        saleEntityFormGroup.controls.compensation_plan.controls.compensation_value.value
                                    }}
                                    % of Deal Split
                                </span>
                            </ng-container>
                        </div>
                    </div>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="deal-options">
                <div class="row-after-header">
                    <app-unlock-override
                        class="unlock-override"
                        *ngIf="
                            saleEntityFormGroup.controls.contact &&
                            saleEntityFormGroup.controls.contact.controls.disbursement_instructions &&
                            saleEntityFormGroup.controls.notes
                        "
                        [isOverrideLock]="isOverrideDisbursementInstructionsLocked"
                        [source]="saleEntityFormGroup.controls.contact.controls.disbursement_instructions"
                        [override]="saleEntityFormGroup.controls.notes"
                        [disabled]="saleEntityFormGroup.disabled"
                    >
                        <mat-form-field
                            class="w-100"
                            unlock-source
                            *ngIf="
                                saleEntityFormGroup.controls.contact &&
                                saleEntityFormGroup.controls.contact.controls.disbursement_instructions
                            "
                        >
                            <input
                                matInput
                                [type]="'text'"
                                [formControl]="saleEntityFormGroup.controls.contact.controls.disbursement_instructions"
                            />
                            <mat-placeholder>Default Disbursement Instructions</mat-placeholder>
                        </mat-form-field>
                        <mat-form-field class="w-100" unlock-override *ngIf="saleEntityFormGroup.controls.notes">
                            <input matInput [type]="'text'" [formControl]="saleEntityFormGroup.controls.notes" />
                            <mat-placeholder>Override Disbursement Instructions</mat-placeholder>
                        </mat-form-field>
                    </app-unlock-override>
                    <a href="#" style="font-weight: bold" (click)="handleCommissionClick($event)"
                        >Additional Commission Instructions</a
                    >
                </div>

                <div class="options">
                    <div class="options-body">
                        <button
                            mat-stroked-button
                            style="width: fit-content;"
                            class="mb-3"
                            [disabled]="
                                (saleEntityFormGroup.controls.role &&
                                    saleEntityFormGroup.controls.role.value !== SALES_ENTITY.role_SET.agent) ||
                                saleEntityFormGroup.disabled
                            "
                            [matMenuTriggerFor]="menu"
                        >
                            <mat-icon>add</mat-icon>
                            Add item
                        </button>
                        <mat-menu #menu="matMenu">
                            <button
                                mat-menu-item
                                *ngIf="!showDefaultCompensation && !hasAgentSplits"
                                (click)="showDefaultAgentCompensationTransfer()"
                            >
                                Default Compensation
                            </button>
                            <button
                                mat-menu-item
                                *ngIf="compensationExpenseEnabledFlag"
                                (click)="addFinancialTransfer(FINANCIAL_TRANSFER_ENTITY.type_SET.compensation_expense)"
                            >
                                Compensation Expense
                            </button>
                            <button
                                mat-menu-item
                                (click)="addFinancialTransfer(FINANCIAL_TRANSFER_ENTITY.type_SET.referral)"
                            >
                                Referral
                            </button>
                            <button
                                mat-menu-item
                                *ngIf="canEditAnyDeals"
                                (click)="addFinancialTransfer(FINANCIAL_TRANSFER_ENTITY.type_SET.income)"
                            >
                                Income
                            </button>
                            <button
                                mat-menu-item
                                (click)="addFinancialTransfer(FINANCIAL_TRANSFER_ENTITY.type_SET.expense)"
                            >
                                Expense
                            </button>
                        </mat-menu>

                        <div
                            class="option-item"
                            *ngIf="
                                saleEntityFormGroup.controls.default_agent_splits &&
                                saleEntityFormGroup.controls.default_agent_splits.length > 0 &&
                                showDefaultCompensation
                            "
                        >
                            <h3 class="option-title" style="display: flex; flex-direction: row; cursor: pointer;">
                                Sales Compensation
                            </h3>
                            <app-deal-transfers-collection
                                buttonLabel="Add sales compensation"
                                [allowPercents]="true"
                                [showOnlyAmount]="false"
                                [availableValueTypes]="[
                                    'flat_fee',
                                    'percent_of_commission',
                                    'percent_of_split',
                                    'flat_fee_sales_net'
                                ]"
                                [transfersFA]="saleEntityFormGroup.controls.default_agent_splits"
                                [type]="FINANCIAL_TRANSFER_ENTITY.type_SET.transfer"
                                (deleteItem)="removeDefaultAgentCompensationTransfer($event)"
                                [mode]="'edit'"
                            ></app-deal-transfers-collection>
                        </div>

                        <div
                            class="option-item"
                            *ngIf="hasAgentSplits || saleEntityFormGroup.controls.commission_deductions?.length"
                        >
                            <h3 class="option-title">Compensation</h3>
                            <app-deal-transfers-collection
                                *ngIf="saleEntityFormGroup.controls.agent_splits?.length"
                                buttonLabel="Add sales compensation"
                                [allowPercents]="true"
                                [showOnlyAmount]="false"
                                [availableValueTypes]="[
                                    'flat_fee',
                                    'percent_of_commission',
                                    'percent_of_split',
                                    'flat_fee_sales_net'
                                ]"
                                [transfersFA]="saleEntityFormGroup.controls.agent_splits"
                                [type]="FINANCIAL_TRANSFER_ENTITY.type_SET.transfer"
                                (deleteItem)="removeFinancialTransfer($event)"
                                [mode]="'edit'"
                            ></app-deal-transfers-collection>

                            <app-compensation-deductions
                                [salesEntityFG]="saleEntityFormGroup"
                                [reloadDataTriggerSubject]="cardOpened"
                                [disabled]="saleEntityFormGroup.disabled"
                            ></app-compensation-deductions>
                        </div>

                        <app-calculation-additional-expenses
                            *ngIf="
                                saleEntityFormGroup.controls.role &&
                                saleEntityFormGroup.controls.role.value === SALES_ENTITY.role_SET.agent &&
                                saleEntityFormGroup.controls.compensation_expenses &&
                                saleEntityFormGroup.controls.compensation_expenses.length > 0
                            "
                            [title]="'Compensation Expenses'"
                            [buttonLabel]="'Add compensation expense'"
                            [type]="FINANCIAL_TRANSFER_ENTITY.type_SET.compensation_expense"
                            [isSalesEntityAgentCardContext]="
                                saleEntityFormGroup.controls.role &&
                                saleEntityFormGroup.controls.role.value === SALES_ENTITY.role_SET.agent
                            "
                            [availableValueTypes]="[
                                'flat_fee',
                                'percent_of_commission',
                                'percent_of_split',
                                'flat_fee_sales_net'
                            ]"
                            (removeTransfer)="removeFinancialTransfer($event)"
                            [financialNodesFA]="saleEntityFormGroup.controls.compensation_expenses"
                        >
                        </app-calculation-additional-expenses>

                        <div
                            class="compensation-incomes"
                            *ngFor="
                                let compensationIncome of saleEntityFormGroup.controls.compensation_incomes?.controls
                            "
                        >
                            <div class="label">
                                <span>
                                    {{ compensationIncome.controls.amount?.value | currency }}
                                    {{
                                        compensationIncome.controls.contribute_to_cap?.value ===
                                        FINANCIAL_TRANSFER_ENTITY.contribute_to_cap_SET.receiver_split
                                            ? 'Agent Split'
                                            : 'Additional Income'
                                    }}
                                    received from {{ compensationIncome.controls.sender.value.link_title }} as
                                    Compensation Expense
                                </span>
                            </div>
                        </div>

                        <app-calculation-additional-expenses
                            *ngIf="
                                saleEntityFormGroup.controls.role &&
                                saleEntityFormGroup.controls.role.value === SALES_ENTITY.role_SET.agent &&
                                saleEntityFormGroup.controls.referral_expenses &&
                                saleEntityFormGroup.controls.referral_expenses.length > 0
                            "
                            [title]="'Referral'"
                            [buttonLabel]="'Add referral'"
                            [type]="FINANCIAL_TRANSFER_ENTITY.type_SET.referral"
                            [isSalesEntityAgentCardContext]="
                                saleEntityFormGroup.controls.role &&
                                saleEntityFormGroup.controls.role.value === SALES_ENTITY.role_SET.agent
                            "
                            [availableValueTypes]="[
                                'flat_fee',
                                'percent_of_commission',
                                'percent_of_split',
                                'flat_fee_sales_net'
                            ]"
                            (removeTransfer)="removeFinancialTransfer($event)"
                            [financialNodesFA]="saleEntityFormGroup.controls.referral_expenses"
                        >
                        </app-calculation-additional-expenses>

                        <app-calculation-additional-expenses
                            *ngIf="
                                saleEntityFormGroup.controls.role &&
                                saleEntityFormGroup.controls.role.value === SALES_ENTITY.role_SET.agent &&
                                saleEntityFormGroup.controls.user_royalties &&
                                saleEntityFormGroup.controls.user_royalties.length > 0
                            "
                            [title]="'Royalty'"
                            [buttonLabel]="'Add royaly'"
                            [type]="FINANCIAL_TRANSFER_ENTITY.type_SET.user_royalty"
                            [availableValueTypes]="[
                                'flat_fee',
                                'percent_of_commission',
                                'percent_of_split',
                                'flat_fee_sales_net'
                            ]"
                            (removeTransfer)="removeFinancialTransfer($event)"
                            [financialNodesFA]="saleEntityFormGroup.controls.user_royalties"
                        >
                        </app-calculation-additional-expenses>

                        <app-calculation-additional-incomes
                            *ngIf="
                                saleEntityFormGroup.controls.additional_incomes?.length ||
                                saleEntityFormGroup.controls.association_incomes?.length
                            "
                            [salesEntityFG]="saleEntityFormGroup"
                            [availableValueTypes]="[
                                'flat_fee',
                                'percent_of_commission',
                                'percent_of_split',
                                'flat_fee_sales_net'
                            ]"
                            (removeTransfer)="removeFinancialTransfer($event)"
                            [financialNodesFA]="saleEntityFormGroup.controls.additional_incomes"
                        >
                        </app-calculation-additional-incomes>

                        <app-calculation-additional-expenses
                            *ngIf="
                                saleEntityFormGroup.controls.additional_expenses?.length ||
                                saleEntityFormGroup.controls.association_expenses?.length
                            "
                            [title]="'Expenses'"
                            [isSalesEntityAgentCardContext]="
                                saleEntityFormGroup.controls.role &&
                                saleEntityFormGroup.controls.role.value === SALES_ENTITY.role_SET.agent
                            "
                            [salesEntityFG]="saleEntityFormGroup"
                            [availableValueTypes]="[
                                'flat_fee',
                                'percent_of_commission',
                                'percent_of_split',
                                'flat_fee_sales_net'
                            ]"
                            (removeTransfer)="removeFinancialTransfer($event)"
                            [financialNodesFA]="saleEntityFormGroup.controls.additional_expenses"
                        >
                        </app-calculation-additional-expenses>
                    </div>

                    <app-participant-preview
                        *ngIf="
                            saleEntityFormGroup.controls.role &&
                            saleEntityFormGroup.controls.role.value === SALES_ENTITY.role_SET.agent &&
                            saleEntityFormGroup.controls.calculation
                        "
                        [isGroup]="
                            !!(
                                saleEntityFormGroup.controls.type &&
                                saleEntityFormGroup.controls.type.value === 'company_group'
                            )
                        "
                        [participantCalculationFG]="saleEntityFormGroup.controls.calculation"
                        [dealSystemStatus]="dealSystemStatus"
                    >
                    </app-participant-preview>

                    <app-transfer-preview
                        *ngIf="
                            saleEntityFormGroup.controls.role &&
                            saleEntityFormGroup.controls.role.value === SALES_ENTITY.role_SET.transfer &&
                            saleEntityFormGroup.controls.transfer_calculation
                        "
                        [transferCalculationFG]="saleEntityFormGroup.controls.transfer_calculation"
                        [dealSystemStatus]="dealSystemStatus"
                    >
                    </app-transfer-preview>
                </div>
            </div>
        </mat-expansion-panel>

        <ng-container *ngIf="saleEntityFormGroup.controls.children && saleEntityFormGroup.controls.children.length > 0">
            <app-sale-entities
                [isPrimarySalesEntity]="isPrimarySalesEntity"
                [level]="level + 1"
                [salesEntitiesFormArray]="saleEntityFormGroup.controls.children"
            ></app-sale-entities>
        </ng-container>
    `
})
export class SaleEntityItemComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    isNewDeal: boolean = false;

    @Input() salesEntitiesFormArray: GenericFormArray<SalesEntity> = new GenericFormArray<SalesEntity>([]);
    @Input() saleEntityFormGroup: GenericFormGroup<SalesEntity> = new GenericFormGroup(new SalesEntity(), 'change');
    @Input() level: number = 0;
    @Input() coeController: GenericFormGroup<Date> | UntypedFormControl | undefined;
    @Input() dealSystemStatus: string = 'draft';
    @Input() showSideCountAndSalesVolumeFlag: boolean = false;

    // @Output() makePrimary = new EventEmitter();
    // @Output() delete = new EventEmitter();
    @Output() needSave = new EventEmitter();
    @Output() clickOnPanel = new EventEmitter();
    @Output() openSideBar = new EventEmitter();

    cardOpened: Subject<boolean> = new Subject();

    public SALES_ENTITY = SalesEntity;
    public FINANCIAL_TRANSFER_ENTITY = FinancialTransferEntity;
    public COMPENSATION_PLAN = CompensationPlanModel;

    public currentProfile: Profile = new Profile();

    public participantSplit: GenericFormGroup<FinancialTransferEntity> = new GenericFormGroup<FinancialTransferEntity>(
        new FinancialTransferEntity()
    );
    public participantPayout: GenericFormGroup<FinancialTransferEntity> = new GenericFormGroup<FinancialTransferEntity>(
        new FinancialTransferEntity()
    );

    @Input() public isPrimarySalesEntity: boolean = false;
    @Input() enableDisbursementInstructionsToggle: boolean = true;
    products: IProduct[] = [];

    public canEditOwnDeals: boolean = false;
    public canCreateOwnDeals: boolean = false;
    public canEditAnyDeals: boolean = false;
    public canCreateAnyDeals: boolean = false;

    public hasAgentSplits: boolean = false;
    public showCompanySplitIndividuals: boolean = false;
    public showDefaultCompensation: boolean = false;

    public compensationExpenseEnabledFlag: boolean = false;

    requestLedgerAccountDefaultPayment: boolean;
    ledgerAccountDefaultPayment: ILedgerAccount | null = null;
    isOverrideDisbursementInstructionsLocked: boolean = true;

    defaultAgentSplitFT: GenericFormGroup<FinancialTransferEntity> | null = null;
    agentSplitTransfers: GenericFormGroup<FinancialTransferEntity>[] = [];

    onToggleOverride(transfer: GenericFormGroup<FinancialTransferEntity>, override: boolean) {
        if (override) {
            // transfer.controls.override.patchValue(transfer.getRawValue());
            if (transfer.controls.type!.value === FinancialTransferEntity.type_SET.company_payout) {
                return;
            }
            transfer.controls.override = new GenericFormGroup<FinancialTransferEntity>(<FinancialTransferEntity>{
                pay_at_escrow: transfer.controls.pay_at_escrow!.value,
                disbursement_instructions: transfer.controls.disbursement_instructions!.value,
                notes: transfer.controls.notes!.value,
                notes_wildcard_fk_id: transfer.controls.notes_wildcard_fk_id!.value
            });
        } else {
            transfer.controls.override = <GenericFormGroup<FinancialTransferEntity>>(
                (<unknown>new UntypedFormControl(null))
            );
        }
        transfer.updateValueAndValidity({emitEvent: false});
    }

    createProduct() {
        const dialogRef = this.dialog.open(CreateProductDialogComponent, {
            // width: '90vw',
            // minHeight: '30vh'
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.store.dispatch(new FetchProducts()));
    }

    hasReferralTransfers(salesEntityFG: GenericFormGroup<SalesEntity>): boolean {
        return salesEntityFG.controls.additional_incomes!.controls.some(
            (transferFG) => transferFG.controls.type!.value === FinancialTransferEntity.type_SET.referral
        );
    }

    handleCommissionClick(e: MouseEvent) {
        e.preventDefault();
        this.openSideBar.emit();
    }

    addFinancialTransfer(type: string) {
        let financialTransferFG: GenericFormGroup<FinancialTransferEntity>;

        switch (type) {
            case FinancialTransferEntity.type_SET.compensation_expense:
                financialTransferFG = new GenericFormGroup(
                    new FinancialTransferEntity()
                        .setType(FinancialTransferEntity.type_SET.compensation_expense)
                        .setOrigin(FinancialElementModel.type_set.compensation_expense)
                        .setOriginIsExpense(true)
                );

                financialTransferFG.controls.sender!.patchValue(
                    this.saleEntityFormGroup.controls.contact_part_link!.value
                );

                this.saleEntityFormGroup.controls.additional_expenses!.push(financialTransferFG);
                break;
            case FinancialTransferEntity.type_SET.income:
                financialTransferFG = new GenericFormGroup(
                    new FinancialTransferEntity()
                        .setType(FinancialTransferEntity.type_SET.transfer)
                        .setOrigin(FinancialElementModel.type_set.disbursement_template)
                        .setOriginIsExpense(false)
                );

                financialTransferFG.controls.receiver!.patchValue(
                    this.saleEntityFormGroup.controls.contact_part_link!.value
                );

                this.saleEntityFormGroup.controls.additional_incomes!.push(financialTransferFG);
                break;
            case FinancialTransferEntity.type_SET.expense:
                financialTransferFG = new GenericFormGroup(
                    new FinancialTransferEntity()
                        .setType(FinancialTransferEntity.type_SET.transfer)
                        .setOrigin(FinancialElementModel.type_set.disbursement_template)
                        .setOriginIsExpense(true)
                );

                financialTransferFG.controls.sender!.patchValue(
                    this.saleEntityFormGroup.controls.contact_part_link!.value
                );

                this.saleEntityFormGroup.controls.additional_expenses!.push(financialTransferFG);
                break;
            case FinancialTransferEntity.type_SET.referral:
                financialTransferFG = new GenericFormGroup(
                    new FinancialTransferEntity()
                        .setType(FinancialTransferEntity.type_SET.referral)
                        .setOrigin(FinancialElementModel.type_set.disbursement_template)
                        .setOriginIsExpense(true)
                );

                financialTransferFG.controls.sender!.patchValue(
                    this.saleEntityFormGroup.controls.contact_part_link!.value
                );

                this.saleEntityFormGroup.controls.referral_expenses!.push(financialTransferFG);
                break;
        }

        this.dealProcessing.addFinancialTransfer(financialTransferFG!);
    }

    removeFinancialTransfer(financialTransferFG: GenericFormGroup<FinancialTransferEntity>) {
        if (financialTransferFG) {
            if (!financialTransferFG.controls.is_enforced!.value) {
                this.dealProcessing.deleteNotEnforcedTransfer(financialTransferFG);
                if (financialTransferFG.controls.type!.value === FinancialTransferEntity.type_SET.agent_split) {
                    const companySplitIndividualFtFg = this.saleEntityFormGroup.controls.company_splits!.controls.find(
                        (ftFg) =>
                            ftFg.controls.type!.value === FinancialTransferEntity.type_SET.company_split_individual &&
                            ftFg.controls.unique_hash!.value === financialTransferFG.controls.unique_hash!.value
                    );
                    if (companySplitIndividualFtFg) {
                        this.dealProcessing.deleteNotEnforcedTransfer(
                            companySplitIndividualFtFg as GenericFormGroup<FinancialTransferEntity>
                        );
                    }
                }
                this.dealProcessing.syncFinancialTransfers();
            } else {
                this.dealProcessing.deleteFinancialTransfer(financialTransferFG);
            }
        }
    }

    doToggleShowInDisbursementPDF(event: Event) {
        event.stopPropagation();
        if (this.saleEntityFormGroup.controls.show_in_disbursement_pdf!.value) {
            this.saleEntityFormGroup.controls.show_in_disbursement_pdf!.patchValue(false, {emitEvent: true});
        } else {
            this.saleEntityFormGroup.controls.show_in_disbursement_pdf!.patchValue(true, {emitEvent: true});
        }
    }

    ngOnInit() {
        this.participantPayout.disable({emitEvent: false});
        this.isOverrideDisbursementInstructionsLocked = this.saleEntityFormGroup.controls.notes?.value === null;
        // @TODO: Need refactor to better usage of store
        this.productsService.lastLoadedProducts.pipe(takeUntil(this.unsubscribe)).subscribe((next) => {
            this.products = next;
        });

        this.participantSplit.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            const primarySEFG = this.dealProcessing.dealFG.controls.sales_entities!.controls.find(
                (seFG) => seFG.controls.is_primary!.value
            );

            if (primarySEFG) {
                if (
                    this.participantSplit.controls.value_type!.value ===
                        FinancialTransferEntity.value_type_SET.percent_of_deal ||
                    this.participantSplit.controls.value_type!.value === FinancialTransferEntity.value_type_SET.deal
                ) {
                    this.participantSplit.controls.sender_wildcard_id!.patchValue(3, {emitEvent: false});
                    this.participantSplit.controls.sender!.patchValue(null, {emitEvent: false});
                } else {
                    this.participantSplit.controls.sender_wildcard_id!.patchValue(null, {emitEvent: false});
                    this.participantSplit.controls.sender!.patchValue(primarySEFG.controls.contact_part_link!.value, {
                        emitEvent: false
                    });
                }
            }
            this.saleEntityFormGroup.controls.participant_split!.patchValue(this.participantSplit.value);
            if (primarySEFG) {
                let sumPercentOfDeal = 0;
                this.salesEntitiesFormArray.controls.forEach((seFG) => {
                    if (!seFG.controls.is_primary!.value) {
                        if (seFG.controls.participant_split) {
                            if (
                                seFG.controls.participant_split.controls.value_type!.value ===
                                    FinancialTransferEntity.value_type_SET.percent_of_deal ||
                                seFG.controls.participant_split.controls.value_type!.value ===
                                    FinancialTransferEntity.value_type_SET.deal
                            ) {
                                sumPercentOfDeal += Number(seFG.controls.participant_split.controls.percent!.value);
                            }
                        }
                    }
                });
                if (primarySEFG.controls.participant_split) {
                    primarySEFG.controls.participant_split.controls.percent!.patchValue(
                        Math.round((100 - sumPercentOfDeal) * 100) / 100,
                        {emitEvent: true}
                    );
                    this.salesEntitiesFormArray.updateValueAndValidity({emitEvent: true});
                }
            }
        });

        this.participantPayout.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.saleEntityFormGroup.controls.payout!.patchValue(this.participantPayout.value);
        });

        this.dealProcessing.dealFG.valueChanges.pipe(debounceTime(100), takeUntil(this.unsubscribe)).subscribe(() => {
            this.participantSplit.patchValue(this.saleEntityFormGroup.controls.participant_split!.getRawValue(), {
                emitEvent: false
            });
            this.participantPayout.patchValue(this.saleEntityFormGroup.controls.payout!.getRawValue(), {
                emitEvent: false
            });
        });

        this.participantSplit.patchValue(this.saleEntityFormGroup.controls.participant_split!.getRawValue(), {
            emitEvent: false
        });
        this.participantPayout.patchValue(this.saleEntityFormGroup.controls.payout!.getRawValue(), {emitEvent: false});
        if (this.saleEntityFormGroup.controls.is_primary!.value) {
            this.participantSplit.controls.amount!.disable({emitEvent: false});
            this.participantSplit.controls.percent!.disable({emitEvent: false});
            this.participantSplit.controls.value_type!.disable({emitEvent: false});
            this.participantSplit.controls.value_wildcard_fk_id!.disable({emitEvent: false});
        } else {
            this.participantSplit.controls.amount!.enable({emitEvent: false});
            this.participantSplit.controls.percent!.enable({emitEvent: false});
            this.participantSplit.controls.value_type!.enable({emitEvent: false});
            this.participantSplit.controls.value_wildcard_fk_id!.enable({emitEvent: false});
        }
        this.salesEntitiesFormArray.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.participantSplit.patchValue(this.saleEntityFormGroup.controls.participant_split!.getRawValue(), {
                emitEvent: false
            });
            this.participantPayout.patchValue(this.saleEntityFormGroup.controls.payout!.getRawValue(), {
                emitEvent: false
            });
            if (this.saleEntityFormGroup.controls.is_primary!.value) {
                this.participantSplit.controls.amount!.disable({emitEvent: false});
                this.participantSplit.controls.percent!.disable({emitEvent: false});
                this.participantSplit.controls.value_type!.disable({emitEvent: false});
                this.participantSplit.controls.value_wildcard_fk_id!.disable({emitEvent: false});
            } else {
                this.participantSplit.controls.amount!.enable({emitEvent: false});
                this.participantSplit.controls.percent!.enable({emitEvent: false});
                this.participantSplit.controls.value_type!.enable({emitEvent: false});
                this.participantSplit.controls.value_wildcard_fk_id!.enable({emitEvent: false});
            }
        });

        this.hasAgentSplits = !!this.saleEntityFormGroup.controls.agent_splits!.controls.filter(
            (ftFg) => !ftFg.controls.is_deleted_by_user!.value
        ).length;

        this.showCompanySplitIndividuals = !!this.saleEntityFormGroup.controls.company_splits!.controls.filter(
            (ftFg) => !ftFg.controls.is_deleted_by_user!.value
        ).length;

        this.showDefaultCompensation =
            !!this.saleEntityFormGroup.controls.default_agent_splits!.controls.length &&
            this.saleEntityFormGroup.controls.default_agent_splits!.controls.some(
                (dfsFg) => dfsFg.controls.amount!.value > 0
            );

        this.dealProcessing.dealFG.controls
            .financial_transfers!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.hasAgentSplits = !!this.saleEntityFormGroup.controls.agent_splits!.controls.filter(
                    (ftFg) => !ftFg.controls.is_deleted_by_user!.value
                ).length;

                this.showCompanySplitIndividuals = !!this.saleEntityFormGroup.controls.company_splits!.controls.filter(
                    (ftFg) => !ftFg.controls.is_deleted_by_user!.value
                ).length;
            });
    }

    constructor(
        protected currentProfileSource: CurrentProfileSource,
        protected featureFlagsService: FeatureFlagsService,
        protected rbacService: RbacService,
        private invoicesService: InvoicesService,
        private loansService: LoansService,
        private ledgerAccountService: LedgerAccountService,
        public dealProcessing: DealProcessingService,
        protected productsService: ProductsService,
        public dialog: MatDialog,
        private store: Store<IFinanceState>
    ) {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.compensationExpenseEnabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'rules:compensation_expense_element'
                );
            });

        this.currentProfileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe((profile) => {
            this.currentProfile = profile;
        });

        this.rbacService.isAllowed({deals__edit_own: true}).then((can) => (this.canEditOwnDeals = can));

        this.rbacService.isAllowed({deals__create_own: true}).then((can) => (this.canCreateOwnDeals = can));

        this.rbacService.isAllowed({deals__edit_any: true}).then((can) => (this.canEditAnyDeals = can));

        this.rbacService.isAllowed({deals__create_any: true}).then((can) => (this.canCreateAnyDeals = can));

        this.requestLedgerAccountDefaultPayment = false;
    }

    saveAndRefresh($event: any) {
        this.needSave.emit($event);
        this.isOpened();
    }

    selectItem(product: IProduct) {
        if (this.saleEntityFormGroup.controls.participant_split!.controls.amount!.value === null) {
            this.saleEntityFormGroup.controls.participant_split!.controls.amount!.patchValue(product.price);
        }
        if (this.saleEntityFormGroup.controls.participant_split!.controls.label!.value === null) {
            this.saleEntityFormGroup.controls.participant_split!.controls.label!.patchValue(product.name);
        }
    }

    isOpened() {
        this.cardOpened.next(true);
    }

    isOwnSalesEntity(salesEntity: GenericFormGroup<SalesEntity>): boolean {
        return (
            this.isOwnProfileSalesEntity(salesEntity.getRawValue()) ||
            this.isOwnGroupSalesEntity(salesEntity.getRawValue())
        );
    }

    isOwnProfileSalesEntity(salesEntity: SalesEntity): boolean {
        if (salesEntity.type === SalesEntity.type_SET.profile) {
            return this.currentProfile.id === salesEntity.profile.id;
        } else {
            return false;
        }
    }

    isOwnGroupSalesEntity(salesEntity: SalesEntity): boolean {
        if (salesEntity.type === SalesEntity.type_SET.company_group) {
            return (
                salesEntity.children.filter((child) => {
                    if (child.profile) {
                        return this.currentProfile.id === child.profile.id;
                    } else {
                        return false;
                    }
                }).length > 0
            );
        } else {
            return false;
        }
    }

    isDefined(thing: any) {
        return typeof thing !== 'undefined';
    }

    groupProfiles(groupSE: GenericFormGroup<SalesEntity>): Profile[] {
        const profiles: Profile[] = [];

        const filterOutProfiles = groupSE.controls
            .children!.getRawValue()
            .map((child) => (child.profile ? child.profile.id : null))
            .filter((id) => id !== null);
        groupSE.controls.company_group!.value.members.forEach((member: GroupMemberEntity) => {
            if (member.profile && filterOutProfiles.filter((id) => id === member.profile!.id).length === 0) {
                profiles.push(Object.assign(new Profile(), member.profile));
            }
        });
        return profiles;
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.needSave.complete();
        this.clickOnPanel.complete();
        this.openSideBar.complete();
    }

    showDefaultAgentCompensationTransfer() {
        this.saleEntityFormGroup.controls.default_agent_splits!.controls.forEach((ftFg) => {
            ftFg.controls.product_fk_id!.patchValue(null, {emitEvent: false});
        });
        this.showDefaultCompensation = true;
    }

    removeDefaultAgentCompensationTransfer(financialTransferFG: GenericFormGroup<FinancialTransferEntity>) {
        financialTransferFG.controls.label!.patchValue(null, {emitEvent: false});
        financialTransferFG.controls.percent!.patchValue(null, {emitEvent: false});
        financialTransferFG.controls.amount!.patchValue(null, {emitEvent: false});
        financialTransferFG.controls.product_fk_id!.patchValue(-1, {emitEvent: false});
        if (financialTransferFG.controls.type!.value === FinancialTransferEntity.type_SET.company_split_individual) {
            financialTransferFG.controls.value_type!.patchValue(FinancialTransferEntity.value_type_SET.remaining, {
                emitEvent: false
            });
        } else {
            financialTransferFG.controls.value_type!.patchValue(FinancialTransferEntity.value_type_SET.flat_fee, {
                emitEvent: false
            });
        }
        financialTransferFG.controls.is_modified_by_user!.patchValue(false, {emitEvent: false});
        const defaultCompanySplitIndividualFtFg = this.saleEntityFormGroup.controls.company_splits!.controls.find(
            (ftFg) =>
                ftFg.controls.type!.value === FinancialTransferEntity.type_SET.company_split_individual &&
                // @ts-ignore
                ftFg.controls.sender!.controls.contact_id!.value ===
                    // @ts-ignore
                    financialTransferFG.controls.receiver!.controls.contact_id.value
        );
        if (defaultCompanySplitIndividualFtFg) {
            this.dealProcessing.deleteNotEnforcedTransfer(
                defaultCompanySplitIndividualFtFg as GenericFormGroup<FinancialTransferEntity>
            );
        }
        this.dealProcessing.syncFinancialTransfers();
        this.showDefaultCompensation = false;
    }
}

@Component({
    selector: 'app-sale-entities',
    styles: [
        `
            .calculations-accordion {
                width: 100%;
            }
        `
    ],
    template: `
        <mat-accordion #accordion="matAccordion" class="calculations-accordion" [multi]="true">
            <ng-container *ngFor="let sales_entity of salesEntitiesFormArray.controls; index as sei">
                <ng-container
                    *ngIf="
                        canEditAnyDeals ||
                        (isPrimarySalesEntity && canEditOwnDeals) ||
                        canCreateAnyDeals ||
                        (isPrimarySalesEntity && canCreateOwnDeals)
                    "
                >
                    <app-sale-entity-item
                        [level]="level"
                        (clickOnPanel)="clickOnPanel.emit()"
                        [salesEntitiesFormArray]="salesEntitiesFormArray"
                        [saleEntityFormGroup]="sales_entity"
                        [isPrimarySalesEntity]="isPrimarySalesEntity"
                        [coeController]="coeController"
                        [dealSystemStatus]="
                            dealFormGroup.controls.system_status ? dealFormGroup.controls.system_status.value : 'draft'
                        "
                        [showSideCountAndSalesVolumeFlag]="showSideCountAndSalesVolumeFlag"
                        (needSave)="needSave.emit($event)"
                        (openSideBar)="openSideBar.emit()"
                    >
                    </app-sale-entity-item>
                </ng-container>
                <ng-container
                    *ngIf="
                        !(
                            canEditAnyDeals ||
                            (isPrimarySalesEntity && canEditOwnDeals) ||
                            canCreateAnyDeals ||
                            (isPrimarySalesEntity && canCreateOwnDeals)
                        )
                    "
                >
                    <app-sale-entity-item
                        *ngIf="isOwnSalesEntity(sales_entity)"
                        [level]="level"
                        (clickOnPanel)="clickOnPanel.emit()"
                        [salesEntitiesFormArray]="salesEntitiesFormArray"
                        [saleEntityFormGroup]="sales_entity"
                        [isPrimarySalesEntity]="isPrimarySalesEntity"
                        [coeController]="coeController"
                        [dealSystemStatus]="
                            dealFormGroup.controls.system_status ? dealFormGroup.controls.system_status.value : 'draft'
                        "
                        [showSideCountAndSalesVolumeFlag]="showSideCountAndSalesVolumeFlag"
                        (needSave)="needSave.emit($event)"
                        (openSideBar)="openSideBar.emit()"
                    >
                    </app-sale-entity-item>
                </ng-container>
            </ng-container>
        </mat-accordion>
    `
})
export class EditDealSalesEntitiesComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('accordion') accordion: MatAccordion | undefined;
    @Output() clickOnPanel = new EventEmitter();
    @Output() openSideBar = new EventEmitter();
    @Input() dealFormGroup: GenericFormGroup<Deal> = new GenericFormGroup<Deal>(new Deal(), 'change');
    @Input() salesEntitiesFormArray: GenericFormArray<SalesEntity> = new GenericFormArray([]);
    @Input() level: number = 0;
    @Input() showSideCountAndSalesVolumeFlag: boolean = false;

    public currentProfile: Profile = new Profile();

    @Input() public isPrimarySalesEntity: boolean = false;
    @Input() coeController: GenericFormGroup<Date> | UntypedFormControl | undefined;
    @Output() needSave = new EventEmitter();

    public canEditOwnDeals: boolean = false;
    public canCreateOwnDeals: boolean = false;
    public canEditAnyDeals: boolean = false;
    public canCreateAnyDeals: boolean = false;

    ngOnInit() {}

    constructor(
        protected currentProfileSource: CurrentProfileSource,
        protected rbacService: RbacService
    ) {
        this.currentProfileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe((profile) => {
            this.currentProfile = profile;
        });

        this.rbacService.isAllowed({deals__create_any: true}).then((can) => (this.canCreateAnyDeals = can));

        this.rbacService.isAllowed({deals__create_own: true}).then((can) => (this.canCreateOwnDeals = can));

        this.rbacService.isAllowed({deals__edit_any: true}).then((can) => (this.canEditAnyDeals = can));

        this.rbacService.isAllowed({deals__edit_own: true}).then((can) => (this.canEditOwnDeals = can));
    }

    isOwnSalesEntity(salesEntity: GenericFormGroup<SalesEntity>): boolean {
        return (
            this.isOwnProfileSalesEntity(salesEntity.getRawValue()) ||
            this.isOwnGroupSalesEntity(salesEntity.getRawValue())
        );
    }

    isOwnProfileSalesEntity(salesEntity: SalesEntity): boolean {
        if (salesEntity.type === SalesEntity.type_SET.profile) {
            return this.currentProfile.id === salesEntity.profile.id;
        } else {
            return false;
        }
    }

    isOwnGroupSalesEntity(salesEntity: SalesEntity): boolean {
        if (salesEntity.type === SalesEntity.type_SET.company_group) {
            return (
                salesEntity.children.filter((child) => {
                    if (child.profile) {
                        return this.currentProfile.id === child.profile.id;
                    } else {
                        return false;
                    }
                }).length > 0
            );
        } else {
            return false;
        }
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.clickOnPanel.complete();
        this.openSideBar.complete();
        this.needSave.complete();
    }
}
