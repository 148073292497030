import {merge as observableMerge, Observable, Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {GroupApi} from '../api/group.api';
import {Group} from '../../models/group';
import {CurrentProfileSource} from './current-profile.source';
import {Profile} from '../../models/profile';
import {DataSource} from '@angular/cdk/collections';

@Injectable()
export class GroupsSource extends DataSource<Group> {
    public sources: {[key: string]: Subject<Group[]>} = {
        load: new Subject<Group[]>()
    };

    private _source: Observable<Group[]> = observableMerge(
        ...Object.keys(this.sources).map((key) => this.sources[key])
    );
    private _tmp: Group[] = [];
    private _tmpWithMembers: Group[] = [];
    constructor(
        public groupApi: GroupApi,
        protected profileSource: CurrentProfileSource
    ) {
        super();
        profileSource.changeProfileEvent.subscribe((profile: Profile) => {
            if (profile.type === Profile.type.default) {
                this.load(profile);
            }
        });
    }

    connect() {
        return this.source;
    }

    disconnect() {}

    public load(profile: Profile): Promise<Group[]> {
        return this.groupApi.getGroupInfoForCompany(profile.company!).then((list: Group[]) => {
            this._tmp = list.slice();
            this.sources.load.next(this._tmp);
            return list;
        });
    }

    public get source(): Observable<Group[]> {
        if (this._tmp) {
            return this.sources.load.pipe(startWith(this._tmp));
        }
        return this.sources.load;
    }
}
