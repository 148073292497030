<!-------- Header -------->
<nav class="middle-menu mt-4 d-flex justify-content-between mx-4" *ngIf="deal">
    <!-------- Total commission -------->
    <div>
        <h2 class="primary-text d-inline mr-2">Total Commission</h2>
        <h2 class="d-inline">
            {{ deal.company_calculation.commission.amount | currency }}
        </h2>
    </div>

    <!-------- Buttons -------->
    <ul class="menu-items d-flex">
        <li *ngIf="collapsed; else expanded">
            <button class="menu-item" type="button" (click)="collapsed = !collapsed">Expand All</button>
        </li>
        <ng-template #expanded>
            <li>
                <button class="menu-item" type="button" (click)="collapsed = !collapsed">Collapse All</button>
            </li>
        </ng-template>
    </ul>
</nav>

<div class="mx-3 d-flex flex-wrap" *ngIf="deal">
    <!---------------- Left column ---------------->
    <div class="col-4 mt-2">
        <!-------- Card-header -------->
        <mat-card class="d-flex justify-content-between p-2 pl-3 mat-card-hover-solid">
            <h3 class="font-weight-normal my-2">Deal</h3>
        </mat-card>

        <!-------- List fields -------->
        <div class="pt-4 px-3 pb-2 mb-2 items-fields-hover-solid">
            <hr />

            <div
                rbac
                [anyRule]="[
                    {deals__view_disbursement_card_deal_and_self: true},
                    {deals__view_disbursement_card_all: true}
                ]"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
            >
                <ng-container *ngFor="let calcValue of dealFields">
                    <ng-container *ngIf="!collapsed || calcValue.value !== 0">
                        <div
                            class="d-flex mb-2 justify-content-between"
                            [ngClass]="calcValue.value !== 0 ? 'font-weight-bold' : ''"
                        >
                            <div>{{ calcValue.name }}:</div>
                            <div>
                                {{ calcValue.value | currency }}
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div
                rbac
                class="w-100 justify-content-center"
                [rule]="{deals__view_disbursement_card_deal_and_self: false, deals__view_disbursement_card_all: false}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'flex'}"
                [denyStyle]="{display: 'none'}"
            >
                <span class="hidden-contact">Values Hidden</span>
                <mat-icon
                    class="ml-1 material-icons-outlined"
                    matTooltip="Visibility for these values is controlled through the Disbursement Card - View permissions."
                    >info</mat-icon
                >
            </div>
        </div>
    </div>

    <!---------------- Center column ---------------->
    <div class="col-4 mt-2">
        <!-------- Card-header -------->
        <mat-card class="d-flex justify-content-between p-2 pl-3 mat-card-hover-solid">
            <h3 class="font-weight-normal my-2">Company</h3>
        </mat-card>

        <!-------- List fields -------->
        <div class="pt-4 px-3 pb-2 mb-2 items-fields-hover-solid">
            <hr />

            <div
                rbac
                [rule]="{deals__view_disbursement_card_all: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'block'}"
                [denyStyle]="{display: 'none'}"
            >
                <ng-container *ngFor="let calcValue of companyFields">
                    <ng-container *ngIf="!collapsed || calcValue.value !== 0">
                        <div
                            class="d-flex mb-2 justify-content-between"
                            [ngClass]="calcValue.value !== 0 ? 'font-weight-bold' : ''"
                        >
                            <div>{{ calcValue.name }}:</div>
                            <div>
                                {{ calcValue.value | currency }}
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div
                rbac
                class="w-100 justify-content-center"
                [rule]="{deals__view_disbursement_card_all: false}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'flex'}"
                [denyStyle]="{display: 'none'}"
            >
                <span class="hidden-contact">Values Hidden</span>
                <mat-icon
                    class="ml-1 material-icons-outlined"
                    matTooltip="Visibility for these values is controlled through the Disbursement Card - View permissions."
                    >info</mat-icon
                >
            </div>
        </div>
    </div>

    <!---------------- Right column ---------------->
    <ng-container *ngFor="let entity of deal.sales_entities">
        <div class="col-4 mt-2">
            <!-------- Card-header -------->
            <mat-card
                class="d-flex justify-content-between align-items-baseline p-2 px-3 mat-card-hover-solid flex-wrap"
            >
                <h3 class="font-weight-normal my-2 d-inline">
                    <!-------- If Entity is primary -------->
                    <ng-container *ngIf="entity.is_primary; else notPrimary">
                        <!-------- profile -------->
                        <ng-container *ngIf="entity.type === SALES_ENTITY.type_SET.profile">
                            <span>{{ entity.profile.getNameOrEmail() }}</span>
                            <ng-container *ngIf="entity.profile?.deleted_at">
                                <span class="comment-text"> (deleted)</span>
                            </ng-container>
                        </ng-container>
                        <!-------- company_group -------->
                        <ng-container *ngIf="entity.type === SALES_ENTITY.type_SET.company_group">
                            <span>{{ entity.company_group?.title }}</span>
                            <ng-container *ngIf="entity.company_group?.deleted_at">
                                <span class="comment-text"> (deleted)</span>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <!-------- If not -------->
                    <ng-template #notPrimary>
                        <!-------- profile -------->
                        <ng-container *ngIf="entity.type === SALES_ENTITY.type_SET.profile">
                            <span>{{ entity.profile?.getNameOrEmail() }}</span>
                            <ng-container *ngIf="entity.profile?.deleted_at">
                                <span class="comment-text"> (deleted)</span>
                            </ng-container>
                        </ng-container>
                        <!-------- company_group -------->
                        <ng-container *ngIf="entity.type === SALES_ENTITY.type_SET.company_group">
                            <span>{{ entity.company_group?.title }}</span>
                            <ng-container *ngIf="entity.company_group?.deleted_at">
                                <span class="comment-text"> (deleted)</span>
                            </ng-container>
                        </ng-container>
                        <!-------- contact -------->
                        <ng-container *ngIf="entity.type === SALES_ENTITY.type_SET.contact">
                            <span>{{ entity.contact?.display_name }}</span>
                            <ng-container *ngIf="entity.contact?.deleted_at">
                                <span class="comment-text"> (deleted)</span>
                            </ng-container>
                        </ng-container>
                    </ng-template>
                </h3>
                <div>
                    <span class="thirdly-text" *ngIf="entity.is_primary">Primary</span>

                    <button
                        mat-icon-button
                        (click)="$event.stopPropagation()"
                        [routerLink]="['/contacts', entity.contact.contact_id]"
                        fragment="payment-methods"
                        *ngIf="entity.has_zipi_pay || entity.has_receive_only"
                    >
                        <mat-icon [matTooltip]="'Direct deposits enabled'" [matTooltipClass]="'my-tooltip'">
                            monetization_on
                        </mat-icon>
                    </button>
                </div>
            </mat-card>

            <!-------- List fields -------->
            <div class="pt-4 px-3 pb-2 mb-2 items-fields-hover-solid">
                <hr />

                <!-------- Participant (is logged profile) -------->
                <div *ngIf="sessionService?.profile?.contact?.contact_id === entity.contact.id">
                    <div
                        rbac
                        [anyRule]="[
                            {deals__view_disbursement_card_self: true},
                            {deals__view_disbursement_card_deal_and_self: true},
                            {deals__view_disbursement_card_all: true}
                        ]"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'block'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        <ng-container *ngIf="entity.role === SALES_ENTITY.role_SET.agent; else transferEnt">
                            <ng-container *ngFor="let calcValue of agentFields">
                                <ng-container
                                    *ngIf="!collapsed || $any(entity['calculation'][calcValue.value])['amount'] !== 0"
                                >
                                    <div
                                        class="d-flex mb-2 justify-content-between"
                                        [ngClass]="
                                            $any(entity['calculation'][calcValue.value])['amount'] !== 0
                                                ? 'font-weight-bold'
                                                : ''
                                        "
                                    >
                                        <div>{{ calcValue.name }}:</div>
                                        <div>
                                            {{ $any(entity['calculation'][calcValue.value])['amount'] | currency }}
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                        <!-------- Transfer -------->
                        <ng-template #transferEnt>
                            <ng-container *ngFor="let calcValue of transferFields">
                                <ng-container
                                    *ngIf="
                                        !collapsed || entity['transfer_calculation'][calcValue.value]['amount'] !== 0
                                    "
                                >
                                    <div
                                        class="d-flex mb-2 justify-content-between"
                                        [ngClass]="
                                            entity['transfer_calculation'][calcValue.value]['amount'] !== 0
                                                ? 'font-weight-bold'
                                                : ''
                                        "
                                    >
                                        <div>{{ calcValue.name }}:</div>
                                        <div>
                                            {{ entity['transfer_calculation'][calcValue.value]['amount'] | currency }}
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                    </div>

                    <div
                        rbac
                        class="w-100 justify-content-center"
                        [rule]="{
                            deals__view_disbursement_card_self: false,
                            deals__view_disbursement_card_deal_and_self: false,
                            deals__view_disbursement_card_all: false
                        }"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        <span class="hidden-contact">Values Hidden2</span>
                        <mat-icon
                            class="ml-1 material-icons-outlined"
                            matTooltip="Visibility for these values is controlled through the Disbursement Card - View permissions."
                            >info</mat-icon
                        >
                    </div>
                </div>

                <!-------- Participant (is another profile) -------->
                <div *ngIf="sessionService?.profile?.contact?.contact_id !== entity.contact.id">
                    <div
                        rbac
                        [rule]="{deals__view_disbursement_card_all: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'block'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        <ng-container *ngIf="entity.role === SALES_ENTITY.role_SET.agent; else transferEnt">
                            <ng-container *ngFor="let calcValue of agentFields">
                                <ng-container
                                    *ngIf="!collapsed || $any(entity['calculation'][calcValue.value])['amount'] !== 0"
                                >
                                    <div
                                        class="d-flex mb-2 justify-content-between"
                                        [ngClass]="
                                            $any(entity['calculation'][calcValue.value])['amount'] !== 0
                                                ? 'font-weight-bold'
                                                : ''
                                        "
                                    >
                                        <div>{{ calcValue.name }}:</div>
                                        <div>
                                            {{ $any(entity['calculation'][calcValue.value])['amount'] | currency }}
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                        <!-------- Transfer -------->
                        <ng-template #transferEnt>
                            <ng-container *ngFor="let calcValue of transferFields">
                                <ng-container
                                    *ngIf="
                                        !collapsed || entity['transfer_calculation'][calcValue.value]['amount'] !== 0
                                    "
                                >
                                    <div
                                        class="d-flex mb-2 justify-content-between"
                                        [ngClass]="
                                            entity['transfer_calculation'][calcValue.value]['amount'] !== 0
                                                ? 'font-weight-bold'
                                                : ''
                                        "
                                    >
                                        <div>{{ calcValue.name }}:</div>
                                        <div>
                                            {{ entity['transfer_calculation'][calcValue.value]['amount'] | currency }}
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                    </div>

                    <div
                        rbac
                        class="w-100 justify-content-center"
                        [rule]="{deals__view_disbursement_card_all: false}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'flex'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        <span class="hidden-contact">Values Hidden</span>
                        <mat-icon
                            class="ml-1 material-icons-outlined"
                            matTooltip="Visibility for these values is controlled through the Disbursement Card - View permissions."
                            >info</mat-icon
                        >
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<!-------- Add item -------->
<!--<nav class="bottom-menu">
    <ul class="menu-items d-flex">
        <li class="mr-4">
            <button class="menu-item add-item" type="button">
                <mat-icon class="mr-2">add</mat-icon>
                New Sales Entity
            </button>
        </li>
    </ul>
</nav>-->
