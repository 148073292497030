import {assign, sortBy} from 'lodash-es';
import {IAvailabledForContact, ICompany, IContact, IContactInvite} from '@cyberco-nodejs/zipi-typings';
import {ContactsAction, contactsActionTypes} from './contacts.actions';

// import { sortContacts } from './contact.utilities';

export interface IContactsState {
    contacts: IContact[];
    clients: IContact[];
    tpls: ICompany[];
    consactsInvites: IContactInvite[];
    importedContacts: IContact[];
    updatedContacts: [IContact, IContact][];

    selectedContact: IContact | IAvailabledForContact | null;
}

const initContactState: IContactsState = {
    contacts: [],
    clients: [],
    tpls: [],
    consactsInvites: [],
    importedContacts: [],
    updatedContacts: [],
    selectedContact: null
};

export function contactsReducer(state: IContactsState = initContactState, action: ContactsAction): IContactsState {
    switch (action.type) {
        case contactsActionTypes.FETCH_CONTACTS_SUCCESS:
            return assign({}, state, {
                contacts: sortBy(action.payload!, (v: IContact) => v.display_name)
                // contacts: sortContacts(action.payload)
            });

        case contactsActionTypes.ADD_CONTACT_SUCCESS:
            return assign({}, state, {
                contacts: sortBy([action.payload!, ...state.contacts], (v: IContact) => v.display_name)
                // contacts: sortContacts([action.payload!, ...state.contacts])
            });

        case contactsActionTypes.UPD_CONTACT_SUCCESS:
            return assign({}, state, {
                contacts: sortBy(
                    state.contacts.map((c) => (c.id === action.payload!.id ? action.payload! : c)),
                    (v: IContact) => v.display_name
                )
                // contacts: sortContacts(state.contacts.map(c =>
                //     c.id === action.payload!.id ? action.payload! : c)),
            });

        case contactsActionTypes.DELETE_CONTACT:
            return assign({}, state, {
                contacts: state.contacts.filter((c) => c.id !== action.payload!)
            });

        case contactsActionTypes.CANCEL_CONTACT_INVITE:
            return assign({}, state, {
                consactsInvites: state.consactsInvites.map((ci) =>
                    ci.id === action.payload!.id ? assign(ci, {status: 'canceled'}) : ci
                ),

                contacts: state.contacts.map((contact) =>
                    contact.id === action.payload!.contact_fk_id
                        ? assign(contact, {
                              contact_invites: contact.contact_invites!.map((ccontInv) =>
                                  ccontInv.id === action.payload!.id ? assign(ccontInv, {status: 'canceled'}) : ccontInv
                              )
                          })
                        : contact
                )
            });

        case contactsActionTypes.SELECT_CONTACT:
            return assign({}, state, {
                selectedContact: action.payload!
            });

        case contactsActionTypes.CLEAN_CONTACTS:
            return assign({}, state, {
                contacts: []
            });

        default:
            return state;
    }
}
