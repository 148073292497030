import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject, ReplaySubject} from 'rxjs';

@Injectable()
export class EventsService {
    contactsIdsFromSearchEvent: Subject<any> | null = null;
    contactsIdsFromSearchCurrentValue: Array<any> | [] | null = [];

    groupsIdsFromSearchEvent: Subject<any> | null = null;
    groupsIdsFromSearchCurrentValue: Array<any> | [] = [];

    goalsIdsFromSearchEvent: Subject<any> | null = null;
    goalsIdsFromSearchCurrentValue: Array<any> | [] = [];

    dealsIdsFromSearchEvent: Subject<any> | null = null;
    dealsIdsFromSearchCurrentValue: Array<any> | [] = [];

    loadIdsFromSearchEvent: Subject<any> | null = null;
    loadIdsFromSearchCurrentValue: Array<any> | [] = [];

    shipmentIdsFromSearchEvent: Subject<any> | null = null;
    shipmentIdsFromSearchCurrentValue: Array<any> | [] = [];

    currentSearchData: string = '';

    constructor() {}

    setSearchData(data: string) {
        this.currentSearchData = data;
    }
    getSearchData() {
        return this.currentSearchData;
    }

    getContactsEventObject() {
        this.contactsIdsFromSearchEvent = new BehaviorSubject<any>(this.contactsIdsFromSearchCurrentValue);

        return this.contactsIdsFromSearchEvent;
    }

    //
    // initCurrentContactEventValue() {
    //     this.contactsIdsFromSearchEvent.next(this.contactsIdsFromSearchCurrentValue);
    // }

    updateContactsEventValue(value: Array<any> | null) {
        this.contactsIdsFromSearchCurrentValue = value;
        if (this.contactsIdsFromSearchEvent) {
            this.contactsIdsFromSearchEvent.next(this.contactsIdsFromSearchCurrentValue);
        }
    }

    getGoalEventObject() {
        this.goalsIdsFromSearchEvent = new Subject<any>();

        return this.goalsIdsFromSearchEvent;
    }

    initCurrentGoalEventValue() {
        this.goalsIdsFromSearchEvent!.next(this.goalsIdsFromSearchCurrentValue);
    }

    updateGoalEventValue(value: Array<any>) {
        this.goalsIdsFromSearchCurrentValue = value;
        if (this.goalsIdsFromSearchEvent) {
            this.goalsIdsFromSearchEvent.next(this.goalsIdsFromSearchCurrentValue);
        }
    }

    getDealEventObject() {
        this.dealsIdsFromSearchEvent = new Subject<any>();

        return this.dealsIdsFromSearchEvent;
    }

    initCurrentDealEventValue() {
        this.dealsIdsFromSearchEvent!.next(this.dealsIdsFromSearchCurrentValue);
    }

    updateDealEventValue(value: Array<any>) {
        this.dealsIdsFromSearchCurrentValue = value;
        if (this.dealsIdsFromSearchEvent) {
            this.dealsIdsFromSearchEvent.next(this.dealsIdsFromSearchCurrentValue);
        }
    }

    getGroupEventObject() {
        this.groupsIdsFromSearchEvent = new Subject<any>();

        return this.groupsIdsFromSearchEvent;
    }

    initCurrentGroupEventValue() {
        this.groupsIdsFromSearchEvent!.next(this.groupsIdsFromSearchCurrentValue);
    }

    updateGroupEventValue(value: Array<any>) {
        this.groupsIdsFromSearchCurrentValue = value;
        if (this.groupsIdsFromSearchEvent) {
            this.groupsIdsFromSearchEvent.next(this.groupsIdsFromSearchCurrentValue);
        }
    }

    getLoadEventObject() {
        this.loadIdsFromSearchEvent = new Subject<any>();

        return this.loadIdsFromSearchEvent;
    }

    initCurrentLoadEventValue() {
        this.loadIdsFromSearchEvent!.next(this.loadIdsFromSearchCurrentValue);
    }

    updateLoadEventValue(value: Array<any>) {
        this.loadIdsFromSearchCurrentValue = value;
        if (this.loadIdsFromSearchEvent) {
            this.loadIdsFromSearchEvent.next(this.loadIdsFromSearchCurrentValue);
        }
    }

    getShipmentEventObject() {
        this.shipmentIdsFromSearchEvent = new Subject<any>();

        return this.shipmentIdsFromSearchEvent;
    }

    initCurrentShipmentEventValue() {
        this.shipmentIdsFromSearchEvent!.next(this.shipmentIdsFromSearchCurrentValue);
    }

    updateShipmentEventValue(value: Array<any>) {
        this.shipmentIdsFromSearchCurrentValue = value;
        if (this.shipmentIdsFromSearchEvent) {
            this.shipmentIdsFromSearchEvent.next(this.shipmentIdsFromSearchCurrentValue);
        }
    }
}
