import {assign} from 'lodash-es';
import {DealAction, dealActionTypes} from './deal.actions';

export interface IDealState {
    dealsIdsToRemove: number[];
}

const initDealState: IDealState = {
    dealsIdsToRemove: []
};

export function dealReducer(state: IDealState = initDealState, action: DealAction): IDealState {
    switch (action.type) {
        case dealActionTypes.CLEAR_DEAL_TO_REMOVE_LIST:
            return assign({}, state, {
                dealsIdsToRemove: []
            });

        case dealActionTypes.ADD_DEAL_TO_REMOVE_LIST:
            return assign({}, state, {
                dealsIdsToRemove: [...state.dealsIdsToRemove, action.payload!]
            });

        case dealActionTypes.RM_DEAL_TO_REMOVE_LIST:
            return assign({}, state, {
                dealsIdsToRemove: state.dealsIdsToRemove.filter((id) => id !== action.payload!)
            });

        default:
            return state;
    }
}
