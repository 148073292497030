<mat-card *ngIf="gateway && gateway.payment_gateway_id" class="mt-3" style="border: solid 1px #dcdada">
    <header class="d-flex align-items-baseline justify-content-between my-2">
        <h2 class="m-0 d-flex align-items-center" *ngIf="gateway.type !== 'zipi_financial_card_customer'">
            Connected Bank Accounts
        </h2>
        <div *ngIf="!gateway.payment_methods || gateway.payment_methods.length === 0">No accounts connected</div>
        <h2 class="m-0 d-flex align-items-center" *ngIf="gateway.type === 'zipi_financial_card_customer'">
            Connected Cards
        </h2>
    </header>

    <ng-container *ngFor="let method of gateway.payment_methods">
        <mat-card class="d-flex mb-1 align-items-center" *ngIf="method.is_available_for_payment">
            <div class="d-flex align-items-center">
                <div>{{ method.title }}</div>
                <mat-icon
                    *ngIf="method.type === 'zipi_financial_cloud_bank'"
                    [matTooltip]="'Cloud Bank'"
                    style="vertical-align: middle; font-size: 24px; margin-left: 5px"
                >
                    cloud
                </mat-icon>
                <mat-icon
                    *ngIf="method.related_ledger_account"
                    [matTooltip]="'Ledger Account: ' + method.related_ledger_account.name"
                    style="color: rgb(128, 128, 128); vertical-align: middle; font-size: 24px; margin-left: 5px"
                >
                    info_outline
                </mat-icon>
                <span *ngIf="['authorize_net_merchant'].includes(gateway.type)" class="company-gateway"
                    >(Company Gateway)</span
                >
                <span
                    *ngIf="
                        ['authorize_net_customer'].includes(gateway.type) &&
                        gateway.connected_company &&
                        gateway.connected_company.title
                    "
                    class=""
                    >(Connected to: {{ gateway.connected_company.title }})</span
                >
                <span *ngIf="['system'].includes(gateway.type)" class="company-gateway">(System Gateway)</span>
            </div>
            <div
                class="ml-4 font-weight-bold"
                *ngIf="method.related_ledger_account && method.type !== 'zipi_financial_balance'"
            >
                <span>Balance: </span>
                <span *ngIf="method.related_ledger_account.bank_balance !== null">{{
                    method.related_ledger_account.bank_balance | currency
                }}</span>
                <span *ngIf="method.related_ledger_account.bank_balance === null"
                    >n/a (need to connect Banking Feed)</span
                >
            </div>
            <div class="ml-4 font-weight-bold" *ngIf="method.type === 'zipi_financial_balance'">
                <span>Balance: </span>
                <span>{{ method.balance | currency }}</span>
                <button
                    mat-icon-button
                    [matTooltip]="'Refresh Balance'"
                    (click)="refreshBalanceOfMethod(method)"
                    *ngIf="gateway.status !== 'inactive'"
                >
                    <mat-icon>loop</mat-icon>
                </button>
            </div>

            <i class="sp-spacer"></i>
            <span
                class="mr-3 font-weight-bold"
                style="color: grey; font-size: 13px"
                *ngIf="method?.is_default_receiving || method.is_default_merchant"
            >
                <span *ngIf="gateway.type === 'zipi_financial_business'">
                    <span *ngIf="method.is_default_receiving && !method.is_default_merchant">
                        Default Operating Account
                    </span>
                    <span *ngIf="!method.is_default_receiving && method.is_default_merchant">
                        Default Merchant Account
                    </span>
                    <span *ngIf="method.is_default_receiving && method.is_default_merchant">
                        Default Operating & Merchant Account
                    </span>
                </span>
                <span *ngIf="gateway.type === 'zipi_financial_trust'">Default Trust Receiving</span>
            </span>

            <a
                class="mr-3 font-weight-bold"
                style="color: #f8a504; font-size: 20px"
                href="#"
                *ngIf="!method.related__ledger_account_fk_id"
                (click)="$event.preventDefault(); finishSetup(method, gateway)"
            >
                Complete Setup
            </a>

            <mat-icon
                class="mx-2"
                style="color: green"
                *ngIf="
                    method.status === 'active' && gateway.status === 'active' && method.related__ledger_account_fk_id
                "
                matTooltip="Active"
                >check</mat-icon
            >
            <div *ngIf="!method.related__ledger_account_fk_id">
                <mat-icon
                    class="mx-2"
                    style="color: orange"
                    *ngIf="method.status !== 'active' || !method.related__ledger_account_fk_id"
                    [matTooltip]="
                        !method.related__ledger_account_fk_id ? 'Need to finish setup' : 'Waiting for verification'
                    "
                    >warning</mat-icon
                >
            </div>
            <div *ngIf="method.related__ledger_account_fk_id">
                <button
                    type="button"
                    mat-flat-button
                    *ngIf="
                        method.status !== 'active' &&
                        method.plaid_refs &&
                        method.plaid_refs.pending_auth_status === 'pending_manual_verification'
                    "
                    (click)="verify(method)"
                >
                    <div class="d-flex align-items-center">
                        <mat-icon
                            class="mx-2"
                            style="color: orange; width: 17px; height: 17px; font-size: 17px"
                            [matTooltip]="'Waiting for verification'"
                            >warning</mat-icon
                        >
                        <div style="color: #0059da">Verify Account</div>
                    </div>
                </button>

                <div
                    *ngIf="
                        method.status !== 'active' &&
                        method.plaid_refs.pending_auth_status !== 'pending_manual_verification'
                    "
                >
                    <div class="d-flex align-items-center">
                        <mat-icon
                            class="mx-2"
                            style="color: red; width: 19px; height: 19px; font-size: 19px"
                            [matTooltip]="'Waiting for ACH approval'"
                            >watch_later</mat-icon
                        >
                        <div>Pending Approval</div>
                    </div>
                </div>
            </div>

            <button
                mat-icon-button
                (click)="$event.stopPropagation()"
                [matMenuTriggerFor]="menu"
                *ngIf="
                    gateway.status !== 'inactive' &&
                    (!method.plaid_refs ||
                        !method.plaid_refs.pending_auth_status ||
                        (method.plaid_refs && method.plaid_refs.pending_auth_status && method.id))
                "
            >
                <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
                <ng-container
                    *ngIf="
                        !method.plaid_refs ||
                        !method.plaid_refs.pending_auth_status ||
                        method.plaid_refs.pending_auth_status === 'manually_verified'
                    "
                >
                    <ng-container *ngIf="method.type !== 'zipi_financial_credit_card'">
                        <!--                        <button type="button" mat-menu-item class="mr-2" *ngIf="isMoneyTransfersFeatureFlagEnabled && gateway.type !== 'zipi_financial_card_merchant'"-->
                        <!--                                [disabled]="method.status !== 'active' || gateway.status !== 'active' || !method.related__ledger_account_fk_id || ['muhnee', 'tpp'].includes(gateway.driver_type)"-->
                        <!--                                (click)="transferMoneyInOut(method)">-->
                        <!--                            <mat-icon>add</mat-icon>New Transfer-->
                        <!--                        </button>-->

                        <button
                            type="button"
                            mat-menu-item
                            class="mr-2"
                            *ngIf="['zipi_financial_business', 'zipi_financial_trust'].includes(gateway.type)"
                            (click)="changeDefaultStatus(method.payment_method_id)"
                            [disabled]="method.is_default_receiving"
                        >
                            <span *ngIf="['zipi_financial_business'].includes(gateway.type)"
                                >Set Default Operating Account</span
                            >
                            <span *ngIf="['zipi_financial_trust'].includes(gateway.type)"
                                >Set Default Trust Receiving</span
                            >
                        </button>
                        <button
                            type="button"
                            mat-menu-item
                            class="mr-2"
                            *ngIf="
                                ['zipi_financial_business'].includes(gateway.type) && gateway.driver_type === 'payload'
                            "
                            (click)="changeDefaultMerchant(method.payment_method_id)"
                            [disabled]="method.is_default_merchant"
                        >
                            <span>Set Default Merchant Account</span>
                        </button>
                    </ng-container>
                    <button type="button" mat-menu-item class="mr-2" (click)="editPayment(method, gateway)">
                        Edit
                    </button>
                </ng-container>
                <ng-container
                    *ngIf="
                        method.plaid_refs &&
                        method.plaid_refs.pending_auth_status &&
                        method.plaid_refs.pending_auth_status === 'pending_manual_verification'
                    "
                >
                    <button
                        *ngIf="method.id && method.plaid_refs.pending_auth_status === 'pending_manual_verification'"
                        class="mr-2"
                        type="button"
                        mat-menu-item
                        (click)="verify(method)"
                        [disabled]="['authorize_net_merchant', 'system'].includes(gateway.type) || method.is_default"
                    >
                        Verify
                    </button>
                </ng-container>

                <button
                    *ngIf="
                        method.id &&
                        !['zipi_financial_balance'].includes(method.type) &&
                        gateway.type !== 'zipi_financial_card_merchant'
                    "
                    type="button"
                    mat-menu-item
                    color="accent"
                    (click)="deletePaymentMethod(method.payment_method_id, true)"
                    [disabled]="['authorize_net_merchant', 'system'].includes(gateway.type) || method.is_default"
                >
                    Delete
                </button>
            </mat-menu>
        </mat-card>
    </ng-container>
</mat-card>
