import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {filter, takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {firstValueFrom, Subject} from 'rxjs';
import {DealFinancialApiService} from '../../../../deal-financial.api.service';
import {ICompany, IDealDepositRequest, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from '../../../../../../../../services/session.service';
import {AuthService} from '../../../../../../../../services/auth.service';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {InvoicePublicService} from '../../../../../../../finance/services/invoice-public.service';
import {DepositEnterToPortalComponent} from '../deposit-enter-to-portal/deposit-enter-to-portal.component';
import {Profile} from '../../../../../../../../models/profile';
const win: any = window;
const Payload: any = win.Payload;

@Component({
    selector: 'app-deal-deposit-public-page',
    templateUrl: './deposit-request-public-page.component.html',
    styleUrls: ['./deposit-request-public-page.component.scss']
})
export class DepositRequestPublicPageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    requestHash: string | undefined;

    depositRequest: IDealDepositRequest | undefined;
    dataSourceRequest: MatTableDataSource<any>;
    displayedRequestColumns: string[] = ['request_date', 'product', 'amount', 'balance', 'pending_balance', 'due_date'];

    currentProfile: Profile | undefined;

    requestPaid: boolean;

    requestMethods: IPaymentMethod[] = [];
    currentMethod: IPaymentMethod | undefined;

    depositRequestPayloadBankMethod: IPaymentMethod | undefined;

    existUserCompanies: ICompany[] = [];

    canViewInApp: boolean | undefined;

    constructor(
        private activatedRoute: ActivatedRoute,
        private dealFinancialApiService: DealFinancialApiService,
        private sessionService: SessionService,
        private authService: AuthService,
        private invoicePublicService: InvoicePublicService,
        public router: Router,
        public dialog: MatDialog
    ) {
        this.dataSourceRequest = new MatTableDataSource<any>([]);
        this.requestPaid = false;
    }

    async ngOnInit() {
        this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            this.requestHash = params['request_hash'];

            if (this.requestHash) {
                this.dealFinancialApiService
                    .getRequestByHash(this.requestHash)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((depositRequest) => {
                        this.depositRequest = depositRequest;
                        this.dataSourceRequest.data = [depositRequest];
                        if (this.depositRequest.request_balance < 0.01) {
                            this.requestPaid = true;
                        }
                        this.checkPermissions();
                        this.loadMethods();
                    });
            }
        });

        if (this.sessionService.profile) {
            this.currentProfile = await this.authService.getCurrentProfile();
        }
    }

    loadMethods() {
        if (
            this.depositRequest &&
            this.depositRequest.pay_to__payment_method_fk_ids &&
            this.depositRequest.pay_to__payment_method_fk_ids.length > 0
        ) {
            this.invoicePublicService
                .getInvoiceMethods(this.depositRequest.pay_to__payment_method_fk_ids)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((methods) => {
                    this.depositRequestPayloadBankMethod = methods.find(
                        (method) => method.payment_gateway && method.payment_gateway.driver_type === 'payload'
                    );
                });
        }
    }

    checkPermissions() {
        this.canViewInApp =
            this.currentProfile &&
            this.currentProfile.company!.id !== this.depositRequest!.creator__company_fk_id &&
            this.depositRequest!.request_receiver_contact!.partner__company_fk_id === this.currentProfile.company!.id;
    }

    isAvailableForDisplay(method: IPaymentMethod) {
        if (this.requestMethods && this.requestMethods.length > 0) {
            if (
                this.requestMethods.some((im) => im.payment_gateway!.type === 'authorize_net_merchant') &&
                method.payment_gateway!.type === 'authorize_net_customer'
            ) {
                return true;
            }
        }
        return false;
    }

    openInPortal() {
        this.invoicePublicService
            .checkExistCompanies(this.depositRequest!.request_receiver_email)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((userCompanies) => {
                this.existUserCompanies = userCompanies;

                const dialogRef = this.dialog.open(DepositEnterToPortalComponent, {
                    width: '600px',
                    data: {
                        companies: this.existUserCompanies,
                        currentContact: this.depositRequest!.request_receiver_contact,
                        currentProfile: this.currentProfile,
                        depositRequest: this.depositRequest,
                        depositRequestHash: this.requestHash
                    }
                });

                dialogRef
                    .afterClosed()
                    .pipe(
                        filter((pn) => !!pn),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe((ok) => {
                        if (ok) {
                        }
                    });
            });
    }

    viewInApp() {
        this.router.navigate([`/escrowdeposit/${this.depositRequest!.deposit_request_id}`]);
    }

    async pay() {
        if (
            this.depositRequest &&
            this.depositRequest.deposit_request_id &&
            this.depositRequestPayloadBankMethod &&
            this.depositRequestPayloadBankMethod.payment_method_id
        ) {
            const settings = await firstValueFrom(
                this.dealFinancialApiService.getPayloadSettings(
                    this.depositRequestPayloadBankMethod.payment_method_id,
                    this.depositRequest.deposit_request_id
                )
            );
            if (!settings.token) {
                console.error('Cannot get settings to initialize payment popup.');
                return;
            }
            Payload(settings.token);

            const checkout = new Payload.Checkout({
                card_payments: false,
                bank_account_payments: true
            })
                .on('loaded', (evt: any) => {})
                .on('authorized', (evt: any) => {})
                .on('processed', (evt: {transaction_id: string; type: 'processed'}) => {})
                .on('success', (evt: {transaction_id: string; type: 'success'}) => {
                    this.createPayloadCoPayment(evt.transaction_id);
                })
                .on('declined', (evt: any) => {})
                .on('closed', (evt: any) => {});
        }
    }

    createPayloadCoPayment(payloadTransactionId: string) {
        if (this.depositRequest && this.depositRequestPayloadBankMethod && payloadTransactionId) {
            const payloadCoPaymentObj = {
                transaction_id: payloadTransactionId,
                deposit_request_id: this.depositRequest.deposit_request_id,
                pay_to_payment_method_id: this.depositRequestPayloadBankMethod.payment_method_id
            };
            this.dealFinancialApiService
                .publicReleasePayloadCoPay(payloadCoPaymentObj)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    this.ngOnInit();
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
