import {Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {timePeriodObj, listOfReportBasis, ledgerTypeGroups, listOfYesNoAnswer} from '../../../../constants';
import {getNextActivePanel, isLastPanelItem} from '../../helper';
import {LEDGER_TYPES_GROUPS} from '../../../../../../local-typings';
import {IReport, IReportSetting, ITag} from '@cyberco-nodejs/zipi-typings';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TagsService} from '../../../../../../services/api/tags.service';
import moment from 'moment';
import {UntypedFormControl} from '@angular/forms';

type GeneralLedgerReportSettings = Pick<
    IReportSetting,
    'time_period' | 'report_basis' | 'tags' | 'ledger_account_type' | 'divisions' | 'is_include_no_division'
> & {is_include_reverted_data: boolean; time_period_start: Date; time_period_end: Date};

const defaultReportSettings: Partial<GeneralLedgerReportSettings> = {
    time_period: timePeriodObj.this_year.value,
    time_period_start: moment().subtract(1, 'years').toDate(),
    time_period_end: new Date(),
    report_basis: 'accrual',
    is_include_reverted_data: false
};

@Component({
    selector: 'app-report-edit-bar-general-ledger',
    templateUrl: './general-ledger.component.html',
    styleUrls: ['./general-ledger.component.scss']
})
export class GeneralLedgerBarComponent implements OnInit, OnDestroy {
    @Output() valueChanges: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Output() action: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Input() report: IReport | null = null;

    public reportSettings: Partial<GeneralLedgerReportSettings> | null = null;
    private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);

    public allTags: ITag[] = [];
    public listOfReportBasis: Array<{title: string; value: string}> = listOfReportBasis;
    public listOfYesNoAnswer: Array<{title: string; value: boolean}> = listOfYesNoAnswer;
    public ledgerTypesGroups: any = ledgerTypeGroups
        .map((item: {[key: string]: any}) => {
            item.list = item.list.sort((a: {[key: string]: any}, b: {[key: string]: any}) => a.order - b.order);
            return item;
        })
        .sort((a, b) => a.order - b.order);

    public isShowNext: boolean = true;
    public activePanel: keyof GeneralLedgerReportSettings = 'time_period';
    public readonly reportOptionPanels: Array<{value: keyof GeneralLedgerReportSettings; title: string}> = [
        {value: 'time_period', title: 'Time Period'},
        {value: 'report_basis', title: 'Report Basis'},
        {value: 'tags', title: 'Report Tagging'},
        {value: 'ledger_account_type', title: 'Account Type'},
        {value: 'divisions', title: 'Offices'},
        {value: 'is_include_reverted_data', title: 'Options'}
    ];

    public includeDivisionFormControl: UntypedFormControl = new UntypedFormControl([]);
    public currentCompanyGroupId: number | null = null;
    public initialGroupIds: number[] = [];

    constructor(private tagsService: TagsService) {}

    ngOnInit() {
        this.beforeDataInit();
        this.initData();
        this.initSubscription();
    }

    beforeDataInit() {
        const settings = this.report?.settings || {};
        this.currentCompanyGroupId = Number(localStorage.getItem('current_company_group_id'));
        if (this.currentCompanyGroupId) {
            if (!settings.hasOwnProperty('divisions')) {
                this.initialGroupIds = [this.currentCompanyGroupId];
            }
        }
    }

    initData() {
        if (!this.report) return;

        // @ts-expect-error
        this.reportSettings = {...defaultReportSettings, ...this.report.settings};
        this.includeDivisionFormControl.setValue(this.reportSettings?.is_include_no_division ?? true);
    }

    initSubscription() {
        this.tagsService
            .getCompanyTags()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.allTags = data.result;
            });
    }

    onSelectOptions(event: {value: keyof GeneralLedgerReportSettings}) {
        this.activePanel = event.value;
        this.isShowNext = !isLastPanelItem(this.activePanel, this.reportOptionPanels);
    }

    onValueChanges<P extends keyof GeneralLedgerReportSettings>(property: P, data: GeneralLedgerReportSettings[P]) {
        switch (property) {
            case 'time_period':
                const timePeriodProps = data as unknown as Partial<GeneralLedgerReportSettings>;
                this.reportSettings = {...this.reportSettings, ...timePeriodProps};
                break;
            default:
                if (this.reportSettings) {
                    this.reportSettings[property] = data;
                }
        }
    }

    onAction(event: string) {
        if (this.reportSettings) {
            this.reportSettings.is_include_no_division = Boolean(this.includeDivisionFormControl.value);
        }

        switch (event) {
            case 'save':
            case 'run':
                this.action.emit({type: event, data: this.reportSettings});
                break;
            case 'cancel':
            case 'delete':
                this.action.emit({type: event, data: null});
                break;
            case 'next':
                const nextData = getNextActivePanel(this.activePanel, this.reportOptionPanels);
                if (nextData.name === '') break;
                this.activePanel = nextData.name;
                this.isShowNext = !nextData.isLast;
                break;
        }
    }

    ngOnDestroy() {
        this.action.complete();
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }
}
