import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'dialog-confirmation',
    templateUrl: 'dialog-confirmation.dialog.html'
})
export class DialogConfirmationComponent {
    constructor(
        public dialogRef: MatDialogRef<DialogConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            text: string;
            cancel?: string;
            confirmation?: string;
        }
    ) {
        if (!this.data) {
            this.data = {
                title: 'Confirmation',
                text: 'Are you sure?'
            };
        }
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    onYesClick(): void {
        this.dialogRef.close(true);
    }
}
