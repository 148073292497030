<section class="container-fluid">
    <h1>Yodata</h1>
    <hr />
    <mat-card>
        <h4>
            <span class="mr-1">Company Settings</span>
            <!--<mat-icon matTooltip="This will automatically get POD URIs from your Yodata's Real Estate Organization and set them to corresponded SkySlope Books profiles and divisions which have equal email address.">help_outline</mat-icon>-->
        </h4>
        <div class="container-fluid" [formGroup]="yodataSettingsForm">
            <div class="row" *ngIf="!isCompanySettingsExisting">
                <p>* please enter Yodata organization ID to use import POD URI's of agents and offices from Yodata</p>
            </div>
            <!-- SHOW IF NO COMPANY SETTINGS -->
            <div class="row" *ngIf="!isCompanySettingsExisting">
                <div class="col-3">
                    <mat-form-field class="w-100" appearance="fill">
                        <mat-label>Organization ID</mat-label>
                        <mat-icon
                            matSuffix
                            matTooltip="https://zz123.bhhs.hsfaffiliates.com/profile/card#me where 'zz123' is Organization ID"
                            >help_outline</mat-icon
                        >
                        <input
                            [disabled]="yodataSettingsPending"
                            matInput
                            required
                            type="text"
                            placeholder="zz123"
                            formControlName="realEstateOrganizationId"
                        />
                    </mat-form-field>
                </div>
                <!--<div class="col-3 d-flex align-items-center pb-3 flex-column">-->
                <!--    <mat-checkbox-->
                <!--        [matTooltip]="autocreateProfilesTooltip"-->
                <!--        formControlName="isProfilesCreate" class="w-100">-->
                <!--        Allow Profiles Creation-->
                <!--    </mat-checkbox>-->
                <!--</div>-->
                <div class="col-9 d-flex align-items-center pb-3 justify-content-end">
                    <button
                        [disabled]="yodataSettingsPending"
                        type="button"
                        mat-raised-button
                        color="primary"
                        (click)="saveCompanySettings($event)"
                    >
                        Save Settings
                    </button>
                </div>
            </div>
            <!-- SHOW IF COMPANY SETTINGS EXISTING -->
            <div class="row" *ngIf="isCompanySettingsExisting">
                <div class="col">
                    <div>
                        Organization Name: <b>{{ yodataCompanyName }}</b>
                    </div>
                    <div>
                        Organization ID: <b>{{ yodataCompanyId }}</b>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
    <hr />
    <mat-card>
        <h4>
            <span class="mr-1">Synchronize POD URIs for your profiles</span>
            <mat-icon
                matTooltip="This will automatically get POD URIs from your Yodata's Real Estate Organization and set them to corresponded SkySlope Books profiles and divisions which have equal EMAIL address."
                >help_outline</mat-icon
            >
        </h4>
        <div class="pb-2 text-muted">
            * the import might take a while. Please do not refresh the page until it's done.
        </div>
        <div class="container-fluid" [formGroup]="importAgentsPodsForm">
            <div class="row" *ngIf="!isPreviewModeDone">
                <div class="col-6 d-flex align-items-center pb-2">
                    <button
                        matTooltip="Run import in PREVIEW mode. No changes will be done in database."
                        [disabled]="importPending || !isCompanySettingsExisting"
                        type="button"
                        mat-raised-button
                        color="primary"
                        (click)="previewImportYodataPods($event)"
                    >
                        Preview Import
                    </button>
                </div>
            </div>
            <div class="row" *ngIf="!isImportPodsAlertHidden">
                <div class="col">
                    <!--<strong>Organization: "{{organizationName}}"</strong>-->
                    <div class="alert alert-success alert-dismissible fade show" role="alert">
                        <div style="height: 35px">
                            <button type="button" class="close" (click)="closePodsAlert()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div style="max-height: 300px; overflow-y: auto">
                            <div *ngFor="let mappingViewMode of mappingsViewModeUIreport">
                                <span>{{ mappingViewMode }}</span>
                                <hr />
                            </div>
                            <div *ngFor="let mappingEditMode of mappingsEditModeUIreport">
                                <span>{{ mappingEditMode }}</span>
                                <hr />
                            </div>
                        </div>
                        <div *ngIf="!mappingsEditModeUIreport.length && !mappingsViewModeUIreport.length">
                            No PODs for import.
                        </div>
                    </div>
                    <div
                        *ngIf="mappingsWarningsUIreport.length"
                        class="alert alert-danger alert-dismissible fade show"
                        role="alert"
                    >
                        <button type="button" class="close" (click)="closePodsAlert()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div style="max-height: 300px; overflow-y: auto">
                            <div *ngFor="let warningMessage of mappingsWarningsUIreport">
                                <span>{{ warningMessage }}</span>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="isPreviewModeDone">
                <div class="col-6 pb-3">
                    <div class="d-inline-flex align-items-center flex-column">
                        <mat-checkbox
                            matTooltip="It will override already existing POD URIs in SkySlope Books if not empty and not equal."
                            formControlName="overrideExisting"
                            class="w-100"
                        >
                            Override Existing POD URIs
                        </mat-checkbox>
                        <mat-checkbox
                            matTooltip="If turned on, will create non-existing (compared by email) profiles in SkySlope Books with no access."
                            formControlName="createProfiles"
                            class="w-100"
                        >
                            Create Profiles
                        </mat-checkbox>
                    </div>
                </div>
                <div class="col-6 d-flex align-items-center pb-3 justify-content-end">
                    <button
                        [disabled]="importPending || !isCompanySettingsExisting"
                        type="button"
                        mat-raised-button
                        color="primary"
                        (click)="runImportYodataPods($event)"
                    >
                        Run Import
                    </button>
                </div>
            </div>
            <hr />
            <div class="row">
                <div>
                    <span>
                        <mat-slide-toggle
                            labelPosition="before"
                            [disabled]="yodataSettingsPending"
                            [checked]="isAutocreateProfiles"
                            (change)="handleAutocreateToggleChange($event)"
                        >
                            Profiles Autocreate:
                        </mat-slide-toggle>
                        <mat-icon [matTooltip]="autocreateProfilesTooltip" class="ml-1">help_outline</mat-icon>
                    </span>
                </div>
                <div class="text-muted">
                    Note: To create new profiles (contacts) in SkySlope Books from Yodata automatically please make sure
                    that you authorized our POD <b>"https://zipi.bhhs.hsfaffiliates.com/profile/card#me"</b> in Yodata
                    to receive your's company updates.
                </div>
            </div>
        </div>
    </mat-card>
    <hr />

    <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="selectedTabChanged($event)">
        <mat-tab label="Company Profiles">
            <!------- Table of profiles ------->
            <app-yodata-settings-table
                #yodataProfilesTableComponent
                [entityType]="'profile'"
                [displayedColumns]="displayedColumns"
                [divisions]="dataSourceDivisions"
                [events]="eventsSubject.asObservable()"
            >
            </app-yodata-settings-table>
        </mat-tab>
        <mat-tab label="Office Locations">
            <!------- Table of divisions ------->
            <app-yodata-settings-table
                [dataSource]="dataSourceDivisions"
                [entityType]="'division'"
                [displayedColumns]="displayedColumnsDivisions"
            >
            </app-yodata-settings-table>
        </mat-tab>
        <!--<mat-tab label="Custom Fields">-->
        <!--    <section class="container-fluid">-->
        <!--    <h3 class="mt-1">Custom Fields mapping</h3>-->
        <!--    <p>Please add here deal custom fields names which you use in a deal. They will be used for yodata report.</p>-->
        <!--    <form [formGroup]="customFieldsForm">-->
        <!--        <div class="row">-->
        <!--            <div class="col-md-4">-->
        <!--                <span style="font-size: 15px;">A flag identifying whether or not the property involved in the transaction is considered unimproved land. <b>Required. Must have "boolean" field type.</b></span>-->
        <!--                <mat-form-field class="w-100">-->
        <!--                    <mat-placeholder>Custom Field for Unimproved Land</mat-placeholder>-->
        <!--                    <input matInput disabled required type="text" formControlName="unimprovedLandFlag">-->
        <!--                </mat-form-field>-->
        <!--            </div>-->
        <!--            <div class="col-md-4">-->
        <!--                <span style="font-size: 15px;">The date must be a valid date later than or equal to the Contract Date (purchaseContractDate) and on or prior to the current date. <b>Included for Misc. Income Transactions Only. Must have "date" field type.</b></span>-->
        <!--                <mat-form-field class="w-100">-->
        <!--                    <mat-placeholder>Custom Field for Commission Date</mat-placeholder>-->
        <!--                    <input matInput type="text" formControlName="commissionDate">-->
        <!--                </mat-form-field>-->
        <!--            </div>-->
        <!--        </div>-->
        <!--        <div class="row">-->
        <!--            <div class="col-md-4">-->
        <!--                <button class="mr-2" type="button" mat-raised-button tabindex="-1">-->
        <!--                    Cancel-->
        <!--                </button>-->
        <!--                <button class="mr-2"-->
        <!--                    mat-raised-button-->
        <!--                    type="button"-->
        <!--                    color="primary"-->
        <!--                    tabindex="1">-->
        <!--                    Save-->
        <!--                </button>-->
        <!--            </div>-->
        <!--        </div>-->
        <!--    </form>-->
        <!--    </section>-->
        <!--</mat-tab>-->
    </mat-tab-group>
</section>
