import {Injectable} from '@angular/core';
import {User} from '../models/user';
import {Profile} from '../models/profile';
import {ContactType} from '../models/contact-type';
import {CurrentProfileSource} from './sources/current-profile.source';
import {AvailableProfilesSource} from './sources/available-profiles.source';
import {SystemUserSource} from './sources/system-user.source';
import {ContactClass} from '../models/contact-class';

@Injectable()
export class SessionService {
    private currentSystemUser: User | null = null;
    private currentProfile: Profile | null = null;
    private userAvailableProfiles: Profile[] = [];

    private navigateAfterAuthTo: string[] = [];
    private emailPrefilled: string | undefined;
    private authInfoMessage: string | undefined;

    public companyTags: Array<any> = [];
    public contactTypes: Array<ContactType> = [];
    public contactClasses: Array<ContactClass> = [];

    public linkToCommunity: string = '';

    constructor(
        public currentProfileSource: CurrentProfileSource,
        public availableProfilesSource: AvailableProfilesSource,
        public systemUserSource: SystemUserSource
    ) {
        currentProfileSource.changeProfileEvent.subscribe((profile: Profile) => {
            this.currentProfile = profile;
        });
        currentProfileSource.updateProfileEvent.subscribe((data: any) => {
            this.currentProfile = {
                ...this.currentProfile,
                ...data
            };
        });
        currentProfileSource.companyTags.subscribe((tags) => {
            this.companyTags = tags;
        });
        availableProfilesSource.availableProfiles.subscribe((next: Profile[]) => {
            this.userAvailableProfiles = next;
        });
        systemUserSource.chageSystemUser.subscribe((user: User) => {
            this.currentSystemUser = user;
        });
        systemUserSource.changeLinkToCommunity.subscribe((linkToCommunity: string) => {
            this.linkToCommunity = linkToCommunity;
        });
        currentProfileSource.contactTypes.subscribe((contactTypes: ContactType[]) => {
            this.contactTypes = contactTypes;
        });
        currentProfileSource.contactClasses.subscribe((contactClasses: ContactClass[]) => {
            this.contactClasses = contactClasses;
        });
    }

    // todo do not inject any services here to prevent circular dependency problems

    get user() {
        return this.currentSystemUser;
    }

    get profile(): Profile | null {
        return this.currentProfile;
    }

    // set profile(profile: Profile) {
    //     this.currentProfileSource.changeProfileTrigger(profile)
    // }

    get availableProfiles() {
        return this.userAvailableProfiles;
    }

    public clearSessionState() {
        this.currentSystemUser = null;
        this.currentProfile = null;
        this.userAvailableProfiles = [];
    }

    public setRedirectParams(navigateAfterAuthTo: string[], emailPrefilled?: string, loginInfoMessage?: string) {
        this.navigateAfterAuthTo = navigateAfterAuthTo;
        this.emailPrefilled = emailPrefilled;
        this.authInfoMessage = loginInfoMessage;
    }

    get redirectParams(): any {
        return {
            navigateAfterAuthTo: this.navigateAfterAuthTo,
            emailPrefilled: this.emailPrefilled,
            authInfoMessage: this.authInfoMessage
        };
    }

    set setNewOrderList(list: number[]) {
        this.profile!.dashboard_order = list;
    }

    set setDealsOrderList(list: number[]) {
        this.profile!.deals_order = list;
    }

    set setSettings(settings: any) {
        this.profile!.settings = Object.assign(this.profile!.settings || {}, settings);
    }
}
