import {Component, Input, OnInit} from '@angular/core';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {EntityCompensationProfilesListItem} from '../models/entity-compensation-profiles-list-item';
import {Profile} from '../../../../models/profile';

@Component({
    selector: 'app-company-compensation-entity',
    styles: [
        `
            .label {
                display: flex;
                align-items: center;
                white-space: nowrap;
                margin-right: 16px;
            }

            .card-content {
                align-items: center;
                display: flex;
                width: 100%;
            }
        `
    ],
    template: `
        <mat-expansion-panel #panel>
            <mat-expansion-panel-header>
                <div class="card-content">
                    <div class="flex-row" style="align-items: center; justify-content: space-around; width: 100%">
                        <div
                            class="label"
                            *ngIf="
                                compensationEntity?.controls?.type?.value === COMPENSATION_ENTITY.type_SET.company_group
                            "
                        >
                            <mat-icon>domain</mat-icon> {{ compensationEntity?.controls?.title?.value }}
                        </div>
                        <div
                            class="label"
                            *ngIf="compensationEntity?.controls?.type?.value === COMPENSATION_ENTITY.type_SET.profile"
                        >
                            <mat-icon>person_outline</mat-icon> {{ compensationEntity?.controls?.title?.value }}
                        </div>
                        <app-company-compensation-profiles-chip-list
                            *ngIf="!panel.expanded"
                            [maxChips]="6"
                            [compensationProfilesList]="compensationEntity?.controls?.compensationProfiles"
                        >
                        </app-company-compensation-profiles-chip-list>
                        <div class="remaining-space"></div>
                    </div>
                </div>
            </mat-expansion-panel-header>
            <app-company-compensation-profiles-chip-list
                *ngIf="panel.expanded"
                [compensationProfilesList]="compensationEntity?.controls?.compensationProfiles"
            >
            </app-company-compensation-profiles-chip-list>
        </mat-expansion-panel>
    `
})
export class CompensationEntityComponent implements OnInit {
    public COMPENSATION_ENTITY = EntityCompensationProfilesListItem;
    public PROFILE = Profile;
    @Input() compensationEntity: GenericFormGroup<EntityCompensationProfilesListItem> | undefined;

    ngOnInit() {}

    constructor() {}
}
