import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-settings-toolbar',
    styles: [
        `
            .action-icon {
                cursor: pointer;
                font-weight: bold;
            }
            .action-icon:hover {
                color: #afafb3;
            }
        `
    ],
    templateUrl: './settings-inner-nav.component.html'
})
export class SettingsInnerNavComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    menuTitle: string = 'General';
    @Output() openTagDialogEmitter = new EventEmitter();
    @Output() openCreateAutoTagDialogEmitter = new EventEmitter();

    constructor(public router: Router) {}

    ngOnInit() {
        if (this.router.url.includes('general')) {
            this.menuTitle = 'General';
        } else if (this.router.url.includes('purchase-settings')) {
            this.menuTitle = 'Purchase Settings';
        } else if (this.router.url.includes('tags')) {
            this.menuTitle = 'Tags';
        } else if (this.router.url.includes('automation')) {
            this.menuTitle = 'Automation';
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.openTagDialogEmitter.complete();
        this.openCreateAutoTagDialogEmitter.complete();
    }
}
