import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {fromEvent, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-custom-popover',
    templateUrl: './custom-popover.component.html',
    styleUrls: ['./custom-popover.component.scss']
})
export class CustomPopoverComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() elementId: string = '';
    @Input() position: 'top' | 'right' = 'top';
    @Input() width: number = 0;
    @Input() theme: 'light' | 'dark' = 'light';

    // We can handle these events by @HostListener, because they only appear on current component
    @HostListener('mouseenter', ['$event'])
    mouseenter(event: any) {
        if (event && event.target && event.target.firstChild.matches(`#popover${this.elementId}`)) {
            clearTimeout(this.timerId);
        }
    }
    @HostListener('mouseleave', ['$event'])
    mouseleave(event: any) {
        if (event && event.target && event.target.firstChild.matches(`#popover${this.elementId}`)) {
            this.timerId = setTimeout(() => (this.isVisible = false), 200);
        }
    }

    isVisible: boolean = false;

    positionOptions = {
        bottom: 0,
        left: 0
    };
    arrowPositionOptions = {
        bottom: 0,
        left: 0
    };
    timerId: any = null;

    constructor(
        public zone: NgZone,
        private changeDetector: ChangeDetectorRef
    ) {
        // To listen these events we cannot use @HostListener. Because every event caught by @HostListener triggers detectChanges
        // So we need to handle these events outside angular and execute detectChanges only if needed
        this.zone.runOutsideAngular(() => {
            fromEvent(document, 'mouseover')
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((e) => {
                    const isNeedToUpdate = this.calculatePosition(e);
                    if (isNeedToUpdate) {
                        this.changeDetector.detectChanges();
                    }
                });
        });
        this.zone.runOutsideAngular(() => {
            fromEvent(document, 'mouseout')
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((e) => {
                    this.setTimer(e);
                });
        });
    }

    ngOnInit(): void {}

    setTimer(event: any) {
        if (event && event.target && event.target.matches(`#${this.elementId}`)) {
            this.timerId = setTimeout(() => {
                this.isVisible = false;
                this.changeDetector.detectChanges();
            }, 200);
        }
    }

    calculatePosition(event: any) {
        let isNeedToUpdate = false;
        if (event && event.target && event.target.matches(`#${this.elementId}`)) {
            clearTimeout(this.timerId);
            switch (this.position) {
                case 'top': {
                    const viewportHeight =
                        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
                    const elementPosition = event.target.getBoundingClientRect();
                    const elementAbsoluteTop = elementPosition.top + window.scrollY;
                    const elementAbsoluteLeft = elementPosition.left + window.scrollX;

                    this.positionOptions.bottom = viewportHeight - elementAbsoluteTop + 15; // 15 is the space for the arrow
                    this.arrowPositionOptions.bottom = viewportHeight - elementAbsoluteTop - 2; // 2 - shift to overlap the arrow side
                    this.positionOptions.left = Math.round(
                        elementAbsoluteLeft - this.width / 2 + event.target.offsetWidth / 2
                    );
                    this.arrowPositionOptions.left = Math.round(
                        elementAbsoluteLeft + event.target.offsetWidth / 2 - 20
                    ); // 20 - half width of the arrow
                    this.isVisible = true;
                    isNeedToUpdate = true;
                    break;
                }
                case 'right': {
                    this.isVisible = true;
                    this.changeDetector.detectChanges(); // we need to pre-render popover to get its actual height
                    const popover = document.getElementById('popover' + this.elementId);
                    const viewportHeight =
                        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
                    const elementPosition = event.target.getBoundingClientRect();
                    const elementAbsoluteTop = elementPosition.top + window.scrollY;
                    const elementAbsoluteLeft = elementPosition.left + window.scrollX;

                    this.positionOptions.bottom =
                        viewportHeight - elementAbsoluteTop - popover!.offsetHeight / 2 - event.target.offsetWidth / 2;
                    this.arrowPositionOptions.bottom =
                        viewportHeight - elementAbsoluteTop - 20 - event.target.offsetWidth / 2; // 20 - half width of the arrow
                    this.positionOptions.left = Math.round(elementAbsoluteLeft + event.target.offsetWidth + 20); // 20 is the space for the arrow
                    this.arrowPositionOptions.left = Math.round(elementAbsoluteLeft + event.target.offsetWidth + 5); // 2 - shift to overlap the arrow side

                    isNeedToUpdate = true;
                    break;
                }
            }
        }
        return isNeedToUpdate;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
