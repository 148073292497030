import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';
import {IReportsPreviewResultData} from '../../../../store/reports.reducer';
import {Subject} from 'rxjs';
import * as moment from 'moment';
import {payrollFilingStatuses} from '../../../../constants';
import * as __ from 'lodash-es';

@Component({
    selector: 'reports-preview-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactPreviewComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    public tableWidth: number = 0;

    @Input()
    set report(data: any) {
        this._report = data;
        this.setTableWidth();
    }
    get report() {
        return this._report;
    }
    private _report: any = null;

    @Input()
    /**
     * Set report data
     */
    set reportData(data: IReportsPreviewResultData[]) {
        this._reportData = this.prepareData(data);
        this.dataSource.data = this._reportData;
        this.treeDataControl.dataNodes = this._reportData;
    }

    /**
     * Get report data
     */
    get reportData() {
        return this._reportData;
    }

    _reportData: IReportsPreviewResultData[] | [] = [];
    dataSource = new MatTreeNestedDataSource<any>();
    treeDataControl = new NestedTreeControl<any>((node) => node.children);

    constructor() {}

    ngOnInit(): void {
        this.setTableWidth();
    }

    setTableWidth(): void {
        this.tableWidth = 2750;
        if (this.report.settings.is_include_contact_payroll) {
            this.tableWidth = this.tableWidth + 2270;
        }
        if (
            this.report.settings.contact_addons &&
            this.report.settings.contact_addons.indexOf('dotloop_integration') > -1
        ) {
            this.tableWidth = this.tableWidth + 120;
        }
        if (
            this.report.settings.contact_addons &&
            this.report.settings.contact_addons.indexOf('skyslope_integration') > -1
        ) {
            this.tableWidth = this.tableWidth + 120;
        }
        if (
            this.report.settings.contact_addons &&
            this.report.settings.contact_addons.indexOf('zipi_financial_integration') > -1
        ) {
            this.tableWidth = this.tableWidth + 120;
        }
    }

    /**
     * Check is tree has child item
     * @param _
     * @param node
     */
    hasChild = (_: number, node: any) => !!(node.children && node.children.length);

    /**
     * Change state node
     * @param node
     */
    changeState(node: {expanded: boolean}) {
        node.expanded = !node.expanded;
    }

    /**
     * Prepare data
     * @param data
     */
    prepareData = (data: IReportsPreviewResultData[]) => {
        const result: IReportsPreviewResultData[] = [];
        const defaultObj = {
            display_name: '',
            company_name: '',
            first_name: '',
            last_name: '',
            email: '',
            country_code: '',
            phone: '',
            city: '',
            state: '',
            country: '',
            zip: '',
            street_address: '',
            street_number: '',
            unit_number: '',
            contact_type: '',
            directory_status: '',
            contact_payroll_id: '',
            payroll_type: '',
            payroll_name: '',
            payroll_tin_type: '',
            payroll_tin: '',
            payroll_start_date: '',
            payroll_filing_status: '',
            payroll_withholding_amount: '',
            payroll_extra_withholding_amount: '',
            payroll_other_income: '',
            payroll_deductions: '',
            payroll_label: '',
            payroll_street_number: '',
            payroll_street_address: '',
            payroll_unit_number: '',
            payroll_city: '',
            payroll_state: '',
            payroll_zip: '',
            payroll_country: '',
            children: [],
            dotloop_integration: '',
            skyslope_integration: '',
            zipi_financial_integration: ''
        };

        if (data && data.length) {
            data.forEach((item) => {
                const mainPersonIndex = item.contact_persons.findIndex((cp: any) => cp.type === 'main_person');
                const itemResult: IReportsPreviewResultData = {
                    display_name: item.display_name,
                    company_name: item.company_name,
                    contact_type: item.contact_type,
                    addons: item.addons,
                    directory_status: item.directory_status,
                    first_name:
                        item.contact_persons.length && item.contact_persons[mainPersonIndex] && !item.company_name
                            ? item.contact_persons[mainPersonIndex].first_name
                            : '',
                    last_name:
                        item.contact_persons.length && item.contact_persons[mainPersonIndex] && !item.company_name
                            ? item.contact_persons[mainPersonIndex].last_name
                            : '',
                    email:
                        item.contact_persons.length && item.contact_persons[mainPersonIndex]
                            ? item.contact_persons[mainPersonIndex].email
                            : '',
                    country_code:
                        item.contact_persons.length && item.contact_persons[mainPersonIndex]
                            ? item.contact_persons[mainPersonIndex].country_code
                            : '',
                    phone:
                        item.contact_persons.length && item.contact_persons[mainPersonIndex]
                            ? item.contact_persons[mainPersonIndex].phone
                            : '',
                    city:
                        item.contact_locations.length && item.contact_locations[mainPersonIndex]
                            ? item.contact_locations[mainPersonIndex].city
                            : '',
                    state:
                        item.contact_locations.length && item.contact_locations[mainPersonIndex]
                            ? item.contact_locations[mainPersonIndex].state
                            : '',
                    country:
                        item.contact_locations.length && item.contact_locations[mainPersonIndex]
                            ? item.contact_locations[mainPersonIndex].country
                            : '',
                    zip:
                        item.contact_locations.length && item.contact_locations[mainPersonIndex]
                            ? item.contact_locations[mainPersonIndex].zip
                            : '',
                    street_address:
                        item.contact_locations.length && item.contact_locations[mainPersonIndex]
                            ? item.contact_locations[mainPersonIndex].street_address
                            : '',
                    street_number:
                        item.contact_locations.length && item.contact_locations[mainPersonIndex]
                            ? item.contact_locations[mainPersonIndex].street_number
                            : '',
                    unit_number:
                        item.contact_locations.length && item.contact_locations[mainPersonIndex]
                            ? item.contact_locations[mainPersonIndex].unit_number
                            : '',
                    payroll_type:
                        item.contact_payroll.length && item.contact_payroll[mainPersonIndex]
                            ? item.contact_payroll[mainPersonIndex].type
                            : '',
                    payroll_name:
                        item.contact_payroll.length && item.contact_payroll[mainPersonIndex]
                            ? item.contact_payroll[mainPersonIndex].name
                            : '',
                    payroll_tin_type:
                        item.contact_payroll.length && item.contact_payroll[mainPersonIndex]
                            ? item.contact_payroll[mainPersonIndex].tin_type
                            : '',
                    payroll_tin:
                        item.contact_payroll.length && item.contact_payroll[mainPersonIndex]
                            ? item.contact_payroll[mainPersonIndex].tin
                            : '',
                    payroll_start_date:
                        item.contact_payroll.length &&
                        item.contact_payroll[mainPersonIndex] &&
                        item.contact_payroll[mainPersonIndex].start_date
                            ? moment(item.contact_payroll[mainPersonIndex].start_date, 'YYYYMMDD').format('MM/DD/YYYY')
                            : '',
                    payroll_filing_status:
                        item.contact_payroll.length && item.contact_payroll[mainPersonIndex]
                            ? this.getLabelOfFilingStatus(item.contact_payroll[mainPersonIndex].filing_status)
                            : '',
                    payroll_withholding_amount:
                        item.contact_payroll.length && item.contact_payroll[mainPersonIndex]
                            ? item.contact_payroll[mainPersonIndex].withholding_amount
                            : '',
                    payroll_extra_withholding_amount:
                        item.contact_payroll.length && item.contact_payroll[mainPersonIndex]
                            ? item.contact_payroll[mainPersonIndex].extra_withholding_amount
                            : '',
                    payroll_other_income:
                        item.contact_payroll.length && item.contact_payroll[mainPersonIndex]
                            ? item.contact_payroll[mainPersonIndex].other_income
                            : '',
                    payroll_deductions:
                        item.contact_payroll.length && item.contact_payroll[mainPersonIndex]
                            ? item.contact_payroll[mainPersonIndex].deductions
                            : '',
                    payroll_label:
                        item.contact_payroll.length && item.contact_payroll[mainPersonIndex]
                            ? item.contact_payroll[mainPersonIndex].label
                            : '',
                    payroll_street_number:
                        item.contact_payroll.length && item.contact_payroll[mainPersonIndex]
                            ? item.contact_payroll[mainPersonIndex].street_number
                            : '',
                    payroll_street_address:
                        item.contact_payroll.length && item.contact_payroll[mainPersonIndex]
                            ? item.contact_payroll[mainPersonIndex].street_address
                            : '',
                    payroll_unit_number:
                        item.contact_payroll.length && item.contact_payroll[mainPersonIndex]
                            ? item.contact_payroll[mainPersonIndex].unit_number
                            : '',
                    payroll_city:
                        item.contact_payroll.length && item.contact_payroll[mainPersonIndex]
                            ? item.contact_payroll[mainPersonIndex].city
                            : '',
                    payroll_state:
                        item.contact_payroll.length && item.contact_payroll[mainPersonIndex]
                            ? item.contact_payroll[mainPersonIndex].state
                            : '',
                    payroll_zip:
                        item.contact_payroll.length && item.contact_payroll[mainPersonIndex]
                            ? item.contact_payroll[mainPersonIndex].zip
                            : '',
                    payroll_country:
                        item.contact_payroll.length && item.contact_payroll[mainPersonIndex]
                            ? item.contact_payroll[mainPersonIndex].country
                            : '',
                    children: [],
                    dotloop_integration: item.addon_slug_list.indexOf('dotloop_integration') === -1 ? '' : 'Activated',
                    skyslope_integration:
                        item.addon_slug_list.indexOf('skyslope_integration') === -1 ? '' : 'Activated',
                    zipi_financial_integration: !item.is_zipi_pay_enabled ? '' : 'Activated'
                };
                if (item.contact_persons && item.contact_persons.length > 1) {
                    item.contact_persons.forEach((contactPerson: IReportsPreviewResultData, key: number) => {
                        if (key === mainPersonIndex) {
                            return;
                        }
                        itemResult.children.push({
                            ...defaultObj,
                            first_name:
                                !item.company_name ||
                                (item.company_name && item.contact_persons[key].type !== 'main_person')
                                    ? contactPerson.first_name
                                    : '',
                            last_name:
                                !item.company_name ||
                                (item.company_name && item.contact_persons[key].type !== 'main_person')
                                    ? contactPerson.last_name
                                    : '',
                            email: contactPerson.email,
                            phone: contactPerson.phone,
                            country_code: contactPerson.country_code,
                            children: []
                        });
                    });
                }

                if (item.contact_locations && item.contact_locations.length > 1) {
                    item.contact_locations.forEach((contactLocation: IReportsPreviewResultData, key: number) => {
                        if (key === mainPersonIndex) {
                            return;
                        }
                        itemResult.children.push({
                            ...defaultObj,
                            city: contactLocation.city,
                            state: contactLocation.state,
                            country: contactLocation.country,
                            zip: contactLocation.zip,
                            street_address: contactLocation.street_address,
                            street_number: contactLocation.street_number,
                            unit_number: contactLocation.unit_number
                        });
                    });
                }

                if (item.contact_payroll && item.contact_payroll.length > 1) {
                    item.contact_payroll.forEach((contactPayroll: IReportsPreviewResultData, key: number) => {
                        if (key === mainPersonIndex) {
                            return;
                        }
                        itemResult.children.push({
                            ...defaultObj,
                            payroll_type: contactPayroll.type,
                            payroll_name: contactPayroll.name,
                            payroll_tin_type: contactPayroll.type,
                            payroll_tin: contactPayroll.tin,
                            payroll_start_date: contactPayroll.start_date,
                            payroll_filing_status: this.getLabelOfFilingStatus(contactPayroll.filing_status),
                            payroll_withholding_amount: contactPayroll.withholding_amount,
                            payroll_extra_withholding_amount: contactPayroll.extra_withholding_amount,
                            payroll_other_income: contactPayroll.other_income,
                            payroll_deductions: contactPayroll.deductions,
                            payroll_label: contactPayroll.label,
                            payroll_street_number: contactPayroll.street_number,
                            payroll_street_address: contactPayroll.street_address,
                            payroll_unit_number: contactPayroll.unit_number,
                            payroll_city: contactPayroll.city,
                            payroll_state: contactPayroll.state,
                            payroll_zip: contactPayroll.zip,
                            payroll_country: contactPayroll.country
                        });
                    });
                }
                result.push(itemResult);
            });
        }

        return result;
    };

    getLabelOfFilingStatus(status: string) {
        const filingStatus = __.find(payrollFilingStatuses, {value: status});
        if (filingStatus) {
            return filingStatus.label;
        }
        return status;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
