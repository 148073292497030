<div mat-dialog-title>
    <h3>Deals Calculation Settings</h3>
</div>

<mat-dialog-content>
    <div class="d-flex flex-column m-1">
        <div class="d-flex flex-column">
            <div class="d-flex flex-row align-items-center flex-wrap mb-2">
                <mat-label class="mr-1">
                    Capable Metrics by Products:
                    <span class="font-italic" *ngIf="!addedProducts.length">(No Products)</span>
                </mat-label>

                <mat-chip
                    class="p-0 px-1 m-0 mr-2 mt-1 chip-item"
                    *ngFor="let product of addedProducts"
                    (removed)="removeProduct(product)"
                >
                    {{ product.name }}
                    <button matChipRemove [disabled]="false">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip>
            </div>

            <button mat-raised-button style="width: fit-content" [matMenuTriggerFor]="productsMenu">Add Product</button>
            <mat-menu #productsMenu="matMenu" class="custom-fields-menu" [class]="'add-item-menu'">
                <button
                    mat-menu-item
                    class="d-flex justify-content-between align-items-center custom-add-field"
                    *ngFor="let product of productsToAdd"
                    (click)="productSelected(product)"
                >
                    {{ product.name }}
                </button>
            </mat-menu>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-raised-button class="mr-2" (click)="closeDialog(false)" tabindex="-1">Cancel</button>
    <button mat-raised-button class="mr-2" color="primary" (click)="saveChanges()" tabindex="1">Save</button>
</mat-dialog-actions>
