import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {
    IContact,
    IPaymentGateway,
    IWildcardInstance,
    IAvailabledForContact,
    IContactPerson,
    IContactLocationTable
} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {UpdContact} from '../store/contacts.actions';
import {AddContactPersonDialogComponent} from '../contact-dialogs/add-contact-person-dialog/add-contact-person-dialog.component';
import {AddLocationDialogComponent} from '../contact-dialogs/add-location-dialog/add-location-dialog.component';
import {filter, takeUntil} from 'rxjs/operators';
import {AddCreditCardDialogComponent} from 'app/layouts/cards-dialogs/add-credit-card-dialog/add-credit-card-dialog.component';
import {InputDialogComponent} from 'app/layouts/input-dialog/input-dialog.component';
import {Deal} from 'app/models/deal';
import {TransactionsService} from 'app/services/api/finance/transactions.service';
import {combineLatest, Subject, Subscription} from 'rxjs';
import {PermissionsSource} from 'app/services/sources/permissions.source';
import {CompanyGatewayService} from 'app/services/api/finance/company-gateway.service';
import {VendorCreditsDetailsDialogComponent} from '../contact-dialogs/vendor-credits-details-dialog/vendor-credits-details-dialog.component';
import {CreditNotesDetailsDialogComponent} from '../contact-dialogs/credit-notes-details-dialog/credit-notes-details-dialog.component';
import {ApplyCreditToInvoicesDialogComponent} from '../../shared/components/credit-apply/apply-credit-to-invoices-dialog/apply-credit-to-invoices-dialog.component';
import {ApplyCreditToBillsDialogComponent} from '../../shared/components/credit-apply/apply-credit-to-bills-dialog/apply-credit-to-bills-dialog.component';
import {MembershipService} from 'app/services/membership.service';
import {ProfileMembership} from 'app/models/profile-membership';
import {MembershipOrganization} from 'app/models/membership-organization';
import {AddMyCreditCardForContactDialogComponent} from 'app/layouts/cards-dialogs/add-my-credit-card-for-contact-dialog/add-my-credit-card-for-contact-dialog.component';
import {SessionService} from 'app/services/session.service';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {ContactType} from 'app/models/contact-type';
import {ContactTypeService} from 'app/services/api/contact-type.service';
import {WildcardsService} from 'app/services/api/wildcards.service';
import {selectContacts} from '../store/contacts.selectors';
import {Wildcard} from 'app/models/wildcard';
import {ContactCreateDialogComponent} from '../contact-dialogs/contact-create-dialog/contact-create-dialog.component';
import {Router} from '@angular/router';
import {GenericFormArray} from 'app/entites/generic.entity';
import {ChipNode} from '../../account-info/compensation/models/chip-node';
import {ShipperContactsService} from 'app/services/api/shipper.contacts.service';
import {NotificationsServiceZipi} from '../../notifications/notifications.service';
import {ContactClass} from 'app/models/contact-class';
import {ContactClassService} from 'app/services/api/contact-class.service';
import * as _ from 'lodash-es';
import {IPaymentMade, IPaymentMethod, IProfile, IVendorCredit} from '@cyberco-nodejs/zipi-typings/index';
import {CompanyPaymentMethodsService} from 'app/services/api/finance/company-payment-methods.service';
import {RbacService} from '../../rbac/rbac.service';
import {selectMembershipOrganizations} from 'app/store/company-wide/company-wide.selectors';
import {MergeContactsDialogComponent} from '../contact-dialogs/merge-contacts-dialog/merge-contacts-dialog.component';
import {NotificationsService} from 'angular2-notifications';
import {ICompanyWideState} from '../../../store/company-wide/company-wide.reducer';
import {FeatureFlagsService} from '../../feature-flags/feature-flags.service';
import {Location} from '@angular/common';
import {ZipiFinConnectToLedgerAccountDialogComponent} from '../../account-info/company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-fin-connect-to-ledger-account-dialog/zipi-fin-connect-to-ledger-account-dialog.component';

type IContactCardMode = 'preview' | 'import';

@Component({
    selector: 'app-contact-card',
    templateUrl: 'contact-card.component.html',
    styleUrls: ['contact-card.component.scss']
})
export class ContactCardComponent implements OnInit, OnChanges, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() contactIsSelected$: Subject<IContact> | undefined;

    DEAL = Deal;
    selectedTabIndex: number = 0;
    contact: IContact | IAvailabledForContact | undefined = undefined;
    @Input() isDefaultCompanyContact: boolean = false;
    @Input() mode: IContactCardMode = 'preview';

    financeSummaryReady: {
        vendorExcessFunds: number;
        customerExcessFunds: number;
        invoicesBalanceCurrent: number;
        invoicesBalanceOverdue: number;
        billsBalanceCurrent: number;
        billsBalanceOverdue: number;
    } = {
        vendorExcessFunds: 0,
        customerExcessFunds: 0,
        invoicesBalanceCurrent: 0,
        invoicesBalanceOverdue: 0,
        billsBalanceCurrent: 0,
        billsBalanceOverdue: 0
    };

    profilePermissions: any = {
        contacts__view_details: false,
        contacts__view_locations: false,
        contacts__view_payment_methods: false,
        contacts__view_compliance: false,
        contacts__view_approvals: false,
        contacts__view_notes: false
    };
    profileMemberships: ProfileMembership[] = [];
    membershipOrganizations: MembershipOrganization[] = [];

    myGateways: IPaymentGateway[] = [];
    contactGateways: IPaymentGateway[] | undefined;

    contactContactTypes: ContactType[] = [];
    contactClasses: ContactClass[] = [];
    wildcardInstances: IWildcardInstance[] = [];
    contactsList: IContact[] = [];
    WILDCARD = Wildcard;

    isShowLinkSelectbox = false;
    linkedUsersCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    linkedContacts: (IContact | IAvailabledForContact)[] = [];
    linkedPersons: IContactPerson[] = [];
    showLinkedPersons: boolean[] = [];
    linkedLocations: IContactLocationTable[] = [];
    showLinkedLocations: boolean[] = [];

    isFullContactLoading: boolean;
    isFinanceSummaryReady: boolean;

    isCompanyZipiFinancialActivated: boolean;
    getContactRequest: Subscription | undefined;
    mergedContacts: IContact[] = [];

    isAuthorizePanelOpen: boolean = false;

    // feature_flags
    isReceiveOnlyFeatureFlagEnabled: boolean = false;

    constructor(
        public dialog: MatDialog,
        private store: Store<ICompanyWideState>,
        private companyGatewayService: CompanyGatewayService,
        private transactionsService: TransactionsService,
        protected permissionsSource: PermissionsSource,
        private membershipService: MembershipService,
        public sessionService: SessionService,
        public currentProfileSource: CurrentProfileSource,
        private contactTypeService: ContactTypeService,
        private wildcardsService: WildcardsService,
        private contactsService: ShipperContactsService,
        protected notificationService: NotificationsServiceZipi,
        private contactClassService: ContactClassService,
        private router: Router,
        private companyPaymentMethodsService: CompanyPaymentMethodsService,
        protected rbacService: RbacService,
        private ntfs: NotificationsService,
        protected featureFlagsService: FeatureFlagsService,
        public location: Location
    ) {
        this.isFullContactLoading = false;
        this.isFinanceSummaryReady = false;
        this.isCompanyZipiFinancialActivated = false;
    }

    ngOnInit() {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.isReceiveOnlyFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:receive_only_accounts'
                );
            });
        if (this.contactIsSelected$) {
            this.contactIsSelected$
                .pipe(takeUntil(this.unsubscribe))
                // @ts-ignore
                .subscribe((contact: IContact) => {
                    if (contact) {
                        if (!this.contact || this.contact.contact_id !== contact.contact_id) {
                            this.contact = contact;
                            this.contactContactTypes = this._typesOnlyForThisContact(this.sessionService.contactTypes);
                            if (this.contact.contact_id) {
                                this.getContactAndInit(this.contact.contact_id);
                                if (!this.contact.is_delegated) {
                                    this.isContactAvailableToReceiveByPaymentMode(this.contact.contact_id);
                                }
                            }
                        }
                    }
                });
        }
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('contact') && changes.contact.currentValue) {
            this.isFullContactLoading = true;
            this.isFinanceSummaryReady = false;
        }
    }

    getContactAndInit(contact_id: number) {
        if (this.getContactRequest) {
            this.getContactRequest.unsubscribe();
        }

        // this.contact = null;
        this.isFullContactLoading = true;
        this.isFinanceSummaryReady = false;
        this.getContactRequest = this.contactsService
            .getContactById(contact_id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((contact) => {
                if (contact) {
                    this.contact = contact;
                    this.initPage();
                    this.isFullContactLoading = false;
                } else {
                    this.notificationService.addError('The contact not found.');
                    this.router.navigate(['/contacts']);
                    return;
                }
            });
    }

    isContactAvailableToReceiveByPaymentMode(contact_id: number) {
        if (
            this.sessionService.profile?.company?.company_settings &&
            this.sessionService.profile?.company?.company_settings.is_zipi_pay_enabled
        ) {
            this.isCompanyZipiFinancialActivated = true;
        }
    }

    initPage() {
        this.getAndSubscribeContactClasses();

        this.permissionsSource.permissions.pipe(takeUntil(this.unsubscribe)).subscribe((permissions) => {
            this.profilePermissions = permissions;
        });

        this.store
            .pipe(select(selectMembershipOrganizations), takeUntil(this.unsubscribe))
            .subscribe((organizations) => {
                if (organizations) {
                    this.membershipOrganizations = organizations;
                }
            });

        this.linkedUsersCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            if (value.length) {
                this.linkToContact(value[0].target_id);
            }
        });
        if (
            this.contact &&
            this.contact.contact_id &&
            this.contact.contact_type_ids &&
            this.contact.contact_type_ids.length > 0
        ) {
            this.getAndSubscribeContactTypes();
        }

        this.getExcess();

        this.profileMemberships = [];
        if (this.contact!.related__profile_fk_id) {
            this.membershipService
                .getProfileMemberships(this.contact!.related__profile_fk_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((memberships) => {
                    if (memberships) {
                        this.profileMemberships = memberships.result;
                    }
                });
        }

        this.checkLinkedContacts();

        if (this.contact!.is_delegated) {
            this.selectedTabIndex = 1;
        }

        const fragment = this.location.path(true).split('#')[1];
        switch (fragment) {
            case 'payment-methods':
                this.selectedTabIndex = 2;
                break;
            default:
                this.selectedTabIndex = 0;
                break;
        }

        if (this.contact && this.contact.contact_id) {
            this.companyGatewayService
                .getMyAuthGateways(this.contact.contact_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateways) => {
                    this.myGateways = gateways;
                });
            this.companyGatewayService
                .getContactAuthGateways(this.contact.contact_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateways) => {
                    this.contactGateways = gateways;
                });

            combineLatest([
                this.wildcardsService.getWildcardInstances(this.contact.contact_id),
                this.store.pipe(select(selectContacts), takeUntil(this.unsubscribe)), // migrate to ContactsLite: done
                this.contactsService.getMergedContacts(this.contact.contact_id)
            ])
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(([res, contactsList, mergedContacts]) => {
                    if (res.result) {
                        this.wildcardInstances = res.result.map((wildcardInstance: IWildcardInstance) => {
                            if (wildcardInstance.wildcard!.wildcard_type_sk === Wildcard.type_SET.contact) {
                                const foundContact = contactsList.find(
                                    (contact) => contact.contact_id === Number(wildcardInstance.value)
                                );
                                // @ts-ignore
                                wildcardInstance.contact = typeof foundContact !== 'undefined' ? foundContact : null;
                            }
                            return wildcardInstance;
                        });
                    }
                    this.contactsList = contactsList;
                    this.checkLinkedContacts();
                    this.mergedContacts = mergedContacts;
                });
        }
    }

    openMergedContactsDialog() {
        const dialogRef = this.dialog.open(MergeContactsDialogComponent, {
            minWidth: 500,
            data: {
                mergedContacts: this.mergedContacts
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                if (response) {
                    this.ntfs.success(`Restore Contacts is in process. Please wait..`);
                }
            });
    }

    checkLinkedContacts() {
        const contact = this.contact;
        if (
            contact &&
            this.contactsList.length &&
            contact.linked_to_contacts_ids &&
            contact.linked_to_contacts_ids.length
        ) {
            this.linkedContacts = this.contactsList.filter((availableForContact) => {
                return contact.linked_to_contacts_ids!.indexOf(availableForContact.contact_id!) !== -1;
            });
            const linkedContact = this.linkedContacts.find(
                (item) => item.contact_id === contact.linked_to_contacts_ids![0]
            );
            if (linkedContact) {
                contact.contact_persons.forEach((contact_person) => {
                    if (
                        contact_person.linked_to_contact_persons_ids &&
                        contact_person.linked_to_contact_persons_ids.length
                    ) {
                        this.linkedPersons.push(
                            <IContactPerson>linkedContact.contact_persons.find((item) => {
                                return item.contact_person_id === contact_person.linked_to_contact_persons_ids![0];
                            })
                        );
                    }
                });
                contact.contact_locations!.map((contact_location) => {
                    if (
                        contact_location.linked_to_contact_locations_ids &&
                        contact_location.linked_to_contact_locations_ids.length
                    ) {
                        this.linkedLocations.push(
                            <IContactLocationTable>linkedContact.contact_locations!.find((item) => {
                                return (
                                    item.contact_location_id === contact_location.linked_to_contact_locations_ids![0]
                                );
                            })
                        );
                    }
                });
                this.showLinkedPersons = contact.contact_persons.map((i) => false);
                this.showLinkedLocations = contact.contact_persons.map((i) => false);
            }
        }
    }

    isShowInZipiIcon(contact: IContact) {
        return (
            (contact.partner__company_fk_id &&
                contact.related__company_group_fk_id &&
                contact.related_group &&
                contact.related_group.type === 'division') ||
            (contact.partner__company_fk_id && !contact.related__company_group_fk_id)
        );
    }

    isShowPersonNotification(person: IContactPerson, linkedPerson: IContactPerson) {
        if (person && linkedPerson) {
            return (
                person.preferred_name !== linkedPerson.preferred_name ||
                person.first_name !== linkedPerson.first_name ||
                person.last_name !== linkedPerson.last_name ||
                person.phone !== linkedPerson.phone ||
                person.fax !== linkedPerson.fax ||
                person.email !== linkedPerson.email
            );
        }
        return false;
    }

    updateLinkedPerson(person: IContactPerson, linkedPerson: IContactPerson) {
        if (this.contact) {
            const persons = this.contact.contact_persons.map((item) => {
                if (item.contact_person_id === person.contact_person_id) {
                    return {
                        ...person,
                        ...{
                            preferred_name: linkedPerson.preferred_name,
                            first_name: linkedPerson.first_name,
                            last_name: linkedPerson.last_name,
                            phone: linkedPerson.phone,
                            fax: linkedPerson.fax,
                            email: linkedPerson.email
                        }
                    };
                }
                return item;
            });

            this.store.dispatch(
                new UpdContact({
                    data: _.assign(this.contact, {contact_persons: persons})
                })
            );
            this.showLinkedPersons = this.contact.contact_persons.map((i) => false);
        }
    }

    isShowLocationNotification(location: IContactLocationTable, linkedLocation: IContactLocationTable) {
        if (location && linkedLocation) {
            return (
                location.label !== linkedLocation.label ||
                location.street_number !== linkedLocation.street_number ||
                location.street_address !== linkedLocation.street_address ||
                location.unit_number !== linkedLocation.unit_number ||
                location.city !== linkedLocation.city ||
                location.state !== linkedLocation.state ||
                location.zip !== linkedLocation.zip ||
                location.country !== linkedLocation.country
            );
        }
        return false;
    }

    updateLinkedLocation(location: IContactLocationTable, linkedLocation: IContactLocationTable) {
        if (this.contact) {
            const locations = this.contact.contact_locations!.map((item) => {
                if (item.contact_location_id === location.contact_location_id) {
                    return {
                        ...location,
                        ...{
                            label: linkedLocation.label,
                            street_number: linkedLocation.street_number,
                            street_address: linkedLocation.street_address,
                            unit_number: linkedLocation.unit_number,
                            city: linkedLocation.city,
                            state: linkedLocation.state,
                            zip: linkedLocation.zip,
                            country: linkedLocation.country
                        }
                    };
                }
                return item;
            });

            this.store.dispatch(
                new UpdContact({
                    data: _.assign(this.contact, {contact_locations: locations})
                })
            );
            this.showLinkedLocations = this.contact.contact_locations!.map((i) => false);
        }
    }

    _typesOnlyForThisContact(allTypes: ContactType[]) {
        if (this.contact && this.contact.contact_type_ids && this.contact.contact_type_ids.length > 0) {
            return allTypes.filter((contactType) =>
                this.contact!.contact_type_ids.some((contactTypeId) => contactTypeId === contactType.contact_type_id)
            );
        }

        return [];
    }

    getAndSubscribeContactTypes() {
        // get contactTypes from sessionService variable
        this.contactContactTypes = this._typesOnlyForThisContact(this.sessionService.contactTypes);

        // subscribe to contactTypes changes
        this.currentProfileSource.contactTypes.pipe(takeUntil(this.unsubscribe)).subscribe((newList) => {
            this.contactContactTypes = this._typesOnlyForThisContact(newList);
        });

        // if no contactTypes - get them from backend and save in currentProfileSource
        if (this.sessionService.contactTypes.length === 0) {
            this.contactTypeService.getContactTypeListAsPromise().then((list) => {
                this.currentProfileSource.contactTypes.next(list);
            });
        }
    }

    getNameById(organization_id: number) {
        if (this.membershipOrganizations && this.membershipOrganizations.length) {
            const currentOrganization = this.membershipOrganizations.find((org) => org.id === organization_id);
            return currentOrganization ? currentOrganization.title : '';
        }
    }

    getExcess() {
        if (this.contact) {
            this.transactionsService
                .getExcessContactTransactions(Number(this.contact.contact_id))
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((excess) => {
                    this.financeSummaryReady.vendorExcessFunds = Number(
                        (excess.vendor_credit + excess.payment_made).toFixed(2)
                    );
                    this.financeSummaryReady.customerExcessFunds = Number(
                        (excess.credit_note + excess.payment_received).toFixed(2)
                    );
                    this.financeSummaryReady.invoicesBalanceCurrent = Number(excess.invoice_pending_balance_current);
                    this.financeSummaryReady.invoicesBalanceOverdue = Number(excess.invoice_pending_balance_overdue);
                    this.financeSummaryReady.billsBalanceCurrent = Number(excess.bill_pending_balance_current);
                    this.financeSummaryReady.billsBalanceOverdue = Number(excess.bill_pending_balance_overdue);
                    this.isFinanceSummaryReady = true;
                });
        }
    }

    isPMShown() {
        if (this.profileMemberships && this.profileMemberships.length) {
            return true;
        }
        return false;
    }

    isCCMShown() {
        return (
            this.contact &&
            this.contact.related_profile &&
            this.contact.related_profile.opening_performance_values &&
            this.contact.related_profile.opening_performance_values.length
        );
    }

    isCAShown() {
        return !!this.wildcardInstances.length;
    }

    addPerson(personType: 'person' | 'department') {
        if (this.contact) {
            const dialogRef = this.dialog.open(AddContactPersonDialogComponent, {
                minWidth: 320,
                data: {
                    type: personType
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((person) => {
                    this.store.dispatch(
                        new UpdContact({
                            data: _.assign(this.contact, {
                                contact_persons: this.contact!.contact_persons.concat(person)
                            })
                        })
                    );
                });
        }
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent) {
        this.selectedTabIndex = tabChangeEvent.index;
    }

    addLocation() {
        if (this.contact) {
            const dialogRef = this.dialog.open(AddLocationDialogComponent, {
                minWidth: 320
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((location) => {
                    this.store.dispatch(
                        new UpdContact({
                            data: _.assign(this.contact, {
                                contact_locations: this.contact!.contact_locations!.concat(location)
                            })
                        })
                    );
                });
        }
    }

    addCard() {
        if (this.contact) {
            const dialogRef = this.dialog.open(AddCreditCardDialogComponent, {
                minWidth: '320px',
                maxHeight: '80vh',
                data: {
                    moneySenderProfileId: this.contact.related__profile_fk_id,
                    invoice: null,
                    contactId: this.contact.id
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((ok) => {
                    if (ok && this.contact && this.contact.contact_id) {
                        this.companyGatewayService
                            .getMyAuthGateways(this.contact.contact_id)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((gateways) => {
                                this.myGateways = gateways;
                            });
                        this.companyGatewayService
                            .getContactAuthGateways(this.contact.contact_id)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((gateways) => {
                                this.contactGateways = gateways;
                            });
                    }
                });
        }
    }

    addMyCard() {
        const dialogRef = this.dialog.open(AddMyCreditCardForContactDialogComponent, {
            minWidth: 320,
            maxHeight: '80vh',
            data: {
                contact: this.contact
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {
                if (ok && this.contact && this.contact.contact_id) {
                    this.companyGatewayService
                        .getMyAuthGateways(this.contact.contact_id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((gateways) => {
                            this.myGateways = gateways;
                        });
                    this.companyGatewayService
                        .getContactAuthGateways(this.contact.contact_id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((gateways) => {
                            this.contactGateways = gateways;
                        });
                }
            });
    }

    addNote() {
        const dialogRef = this.dialog.open(InputDialogComponent, {
            minWidth: 320,
            data: {
                title: 'Add note',
                placeholders: ['note'],
                values: ['']
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => typeof pn !== 'undefined' && pn.length > 0),
                takeUntil(this.unsubscribe)
            )
            .subscribe(([newNote]) => {
                this.store.dispatch(
                    new UpdContact({
                        data: _.assign(this.contact, {
                            notes: (this.contact!.notes || '').concat('\n').concat(newNote).trim()
                        })
                    })
                );
            });
    }

    addFromDelegate() {
        if (this.contact) {
            const dialogRef = this.dialog.open(ContactCreateDialogComponent, {
                autoFocus: false,
                data: {
                    contact: {
                        display_name: this.contact.display_name,
                        linked_to_contacts_ids: [this.contact.contact_id],
                        contact_persons: this.contact.contact_persons,
                        contact_locations: this.contact.contact_locations,
                        contact_type_ids: this.contact.contact_type_ids.filter(
                            (contact_type_id) =>
                                ContactType.provideSystemContactTypesIds.indexOf(contact_type_id) !== -1
                        )
                    }
                }
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((addedContact) => {
                    this.router.navigate(['contacts']);
                });
        }
    }

    linkToContact(contactId: number) {
        const contact = this.contactsList.find((item) => item.contact_id === contactId);
        if (contact) {
            this.store.dispatch(
                new UpdContact({
                    data: _.assign(contact, {
                        linked_to_contacts_ids: [
                            ...(contact.linked_to_contacts_ids ? contact.linked_to_contacts_ids : []),
                            ...[this.contact!.contact_id]
                        ]
                    })
                })
            );
        }
    }

    finishSetup(method: IPaymentMethod, gateway: IPaymentGateway) {
        const dialogRef = this.dialog.open(ZipiFinConnectToLedgerAccountDialogComponent, {
            data: {
                method: method,
                gateway: gateway
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                const settings = {
                    related_ledger_account_id: null,
                    needDefaultLedger: false
                };

                if (result === false || result === undefined) {
                    return;
                } else if (result === null) {
                    settings.needDefaultLedger = true;
                } else {
                    settings.needDefaultLedger = false;
                    settings.related_ledger_account_id = result;
                }
                this.companyPaymentMethodsService
                    .updateLedgerAccountForPaymentMethod(Number(method.payment_method_id), settings)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((response) => {
                        this.ngOnInit();
                    });
            });
    }

    openVendorCreditsDialog() {
        if (this.financeSummaryReady.vendorExcessFunds === 0) {
            return;
        }

        const dialogRef = this.dialog.open(VendorCreditsDetailsDialogComponent, {
            minWidth: 800,
            data: {
                contact: this.contact,
                excess: this.financeSummaryReady.vendorExcessFunds
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((data) => {
                this.openApplyToBillsDialog(data);
            });
    }

    openCreditNotesDialog() {
        if (this.financeSummaryReady.customerExcessFunds === 0) {
            return;
        }

        const dialogRef = this.dialog.open(CreditNotesDetailsDialogComponent, {
            minWidth: 800,
            data: {
                contact: this.contact,
                excess: this.financeSummaryReady.customerExcessFunds
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((data) => {
                this.openApplyToInvoicesDialog(data);
            });
    }

    redirectTo(entity: 'bill' | 'invoice', status: 'current' | 'overdue') {
        switch (entity) {
            case 'bill':
                this.router.navigate(['/purchases/bills'], {
                    queryParams: {contact_id: this.contact?.contact_id, status: status}
                });
                break;
            case 'invoice':
                this.router.navigate(['/sales/invoices'], {
                    queryParams: {contact_id: this.contact?.contact_id, status: status}
                });
                break;
        }
    }

    openApplyToInvoicesDialog(data: IVendorCredit | IPaymentMade) {
        const dialogRef = this.dialog.open(ApplyCreditToInvoicesDialogComponent, {
            minWidth: 700,
            data: {
                credit: data,
                contact: this.contact
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.getExcess());
    }

    openApplyToBillsDialog(data: IVendorCredit | IPaymentMade) {
        const dialogRef = this.dialog.open(ApplyCreditToBillsDialogComponent, {
            minWidth: 700,
            data: {
                credit: data,
                contact: this.contact
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.getExcess());
    }

    getAndSubscribeContactClasses() {
        // get contactClasses from sessionService variable
        this.contactClasses = _.sortBy(this.sessionService.contactClasses, ['title']);
        if (this.contact && this.contact.contact_class_ids) {
            this.contactClasses = this.contactClasses.filter((contact_class) => {
                return (
                    this.contact &&
                    contact_class!.contact_class_id &&
                    this.contact.contact_class_ids.indexOf(contact_class.contact_class_id) !== -1
                );
            });
        } else {
            this.contactClasses = [];
        }

        // subscribe to contactClasses changes
        this.currentProfileSource.contactClasses.pipe(takeUntil(this.unsubscribe)).subscribe((newList) => {
            this.contactClasses = _.sortBy(newList, ['title']);
            if (this.contact && this.contact.contact_class_ids) {
                this.contactClasses = this.contactClasses.filter((contact_class) => {
                    return (
                        this.contact &&
                        contact_class!.contact_class_id &&
                        this.contact.contact_class_ids.indexOf(contact_class.contact_class_id) !== -1
                    );
                });
            } else {
                this.contactClasses = [];
            }
        });

        // if no contactClasses - get them from backend and save in currentProfileSource
        if (this.sessionService.contactClasses.length === 0) {
            this.contactClassService.getContactClassListAsPromise().then((list) => {
                this.currentProfileSource.contactClasses.next(list);
            });
        }
    }

    sortPersons(persons: IContactPerson[]) {
        let returnPersons = [];
        returnPersons.push(persons.find((person) => person.type === 'main_person'));
        returnPersons = [
            ...returnPersons,
            ..._.sortBy(
                persons.filter((person) => person.type !== 'main_person'),
                ['first_name']
            )
        ];
        return returnPersons;
    }

    ngOnDestroy() {
        if (this.getContactRequest) {
            this.getContactRequest.unsubscribe();
        }
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.contactIsSelected$!.complete();
    }
}
