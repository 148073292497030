<div>
    <mat-button-toggle-group
        [value]="value.time_period"
        *ngIf="isDisplayListOfPeriod"
        aria-label="Font Style"
        vertical="true"
        (change)="onChange($event)"
    >
        <mat-button-toggle *ngFor="let period of timePeriods" [value]="period.value">
            <div class="flex-row">
                <div class="but-toggle-item icon">
                    <div>
                        <span *ngIf="value.time_period === period.value" class="material-icons">done</span>
                    </div>
                </div>
                <div class="but-toggle-item title">
                    <span>{{ period.title }}</span>
                </div>
            </div>
        </mat-button-toggle>
    </mat-button-toggle-group>

    <div *ngIf="value.time_period === 'date_range'">
        <div class="row m-3">
            <ng-container *ngIf="!isDisplayDueDate">
                <div class="col-xl-6">
                    <app-date-picker
                        class="spaced-at-right w-100"
                        [inputDisabled]="true"
                        [placeholder]="'Start date'"
                        [valueType]="dateValueType"
                        [startDate]="startDateControl.value"
                        [dateControl]="startDateControl"
                        [disabled]="startDateDisable"
                    ></app-date-picker>
                </div>
                <div class="col-xl-6">
                    <app-date-picker
                        class="spaced-at-right w-100"
                        [inputDisabled]="true"
                        [placeholder]="'End date'"
                        [valueType]="dateValueType"
                        [startDate]="endDateControl.value"
                        [dateControl]="endDateControl"
                        [disabled]="endDateDisable"
                    ></app-date-picker>
                </div>
            </ng-container>
            <ng-container *ngIf="isDisplayDueDate">
                <div class="col-xl-6">
                    <app-date-picker
                        class="spaced-at-right w-100"
                        [placeholder]="'Due date'"
                        [valueType]="dateValueType"
                        [dateControl]="endDateControl"
                    ></app-date-picker>
                </div>
            </ng-container>
        </div>
    </div>
</div>
