<mat-form-field class="w-100 date-field" [floatLabel]="floatLabel" *ngIf="dateControlInternal">
    <input
        matInput
        type="text"
        placeholder="{{ placeholder }}"
        autocomplete="off"
        [required]="isRequired"
        [min]="minDate"
        [max]="maxDate"
        [matDatepicker]="datePicker"
        (dateChange)="onDateChange($event)"
        [formControl]="dateControlInternal"
        (click)="datePicker.open()"
        (focus)="datePicker.open()"
        [matDatepickerFilter]="myFilter"
    />
    <mat-datepicker-toggle matSuffix [for]="datePicker" [disabled]="disabled"></mat-datepicker-toggle>
    <mat-datepicker
        #datePicker
        [dateClass]="myDateClass"
        [disabled]="disabled"
        [startView]="startView"
        [startAt]="startDate"
    ></mat-datepicker>
</mat-form-field>
