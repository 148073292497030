import {GenericEntity} from '../entites/generic.entity';
import {
    IContact,
    IContactInvite,
    IContactLocationTable,
    IContactPartLink,
    IContactPerson
    // IProfileMembership // todo
} from '@cyberco-nodejs/zipi-typings/contacts.typings';
import {ICompany, IProfile} from '@cyberco-nodejs/zipi-typings/companies.typings';
import {Company} from './company';
import {IGroup, IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';

export class Contact extends GenericEntity implements IContact {
    contact_id?: number | null;
    creator__profile_fk_id?: number;
    creator_profile?: IProfile;

    creator__company_fk_id?: number;
    creator_company?: ICompany;
    company_id: number | null = null;

    owner__profile_fk_id?: number;
    owner__group_fk_id?: number;
    owner__company_fk_id?: number;
    owner_type?: 'profile' | 'group' | 'company';

    related__profile_fk_id?: number;
    related_profile?: IProfile;
    related_group?: IGroup;

    contact_payment_methods?: IPaymentMethod[];

    previous_partner__company_fk_id: number | null = null;
    partner__company_fk_id: number | null = null;
    // @ts-ignore
    partner_company: ICompany = new Company();

    display_name: string | null = null;

    disbursement_instructions?: string;
    // default_contact_person: string = null; // IContactPerson.full_name
    company_name: string | null =
        null; /*Currently we don't use it. Now display_name === company_name. Consider depreciation*/
    contact_persons: IContactPerson[] = [];
    contact_locations: IContactLocationTable[] = [];
    // contact_memberships: IProfileMembership[] = [];
    contact_memberships: any[] = []; // todo IProfileMembership
    notes: string | null = null;

    is_system_generated: boolean = false;

    contact_invites?: IContactInvite[];
    business_hours?: [string, string];
    appointment_needed?: boolean;
    reference_keys?: string[];
    service_cost_modifications?: [];
    negotiated_rates?: [];

    carrier_configuration_fk_id?: number | null;
    carrier_scac_code?: string;
    carrier_gs1_code?: number;

    related__company_group_fk_id?: number;
    contact_payment_gateways?: IPaymentGateway[];
    default_location?: IContactLocationTable;

    // deal_count?: {[key: string]: number};
    mentor?: IContactPartLink;
    mentor_team_leader?: IContactPartLink;
    trx_coordinator?: IContactPartLink;
    trx_coordinator_amount?: number;

    product_mapping: Array<{
        contact_company__product_fk_id: number;
        matched__product_fk_id: number;
    }> = [];
    // @ts-ignore
    partner_company_settings: {
        show_my_docs_as_source: boolean;
        approve_income_bills: boolean;
        approve_payout_income_bills: boolean;
        approve_income_invoices: boolean;
        approve_payout_income_invoices: boolean;
        approve_recurring_invoice_ids: Array<{
            title: string;
            recurring_invoice_id: number;
        }>;
    };

    contact_type_ids: number[] = [];
    metatypes: string[] = [];
    contact_class_ids: number[] = [];
    merged_to_contact_fk_id: number | null = null;

    setDisbursementInstructions(disbursementInstructions: string) {
        this.disbursement_instructions = disbursementInstructions;
        return this;
    }

    static get helpers() {
        return Object.assign(
            {
                props: Object.getOwnPropertyNames(new this()).reduce((acc: {[key: string]: any}, curr) => {
                    acc[curr] = curr;
                    return acc;
                }, {}),
                build: {
                    // profile: (val, fabric) => Object.assign(new Profile, val),
                    // disbursement_template: (val, fabric) => Object.assign(new DisbursementTemplateModel, val),
                    // company_group: (val, fabric) => Object.assign(new Group, val),
                    // children: (val: any[], fabric) => val.map(item => this.FABRIC(SalesEntity)[fabric](item)),
                }
            },
            GenericEntity.helpers
        );
    }

    static get type_SET() {
        return {
            company_group: 'company_group',
            profile: 'profile'
        };
    }
}
