import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';

@Component({
    selector: 'app-infinite-scroll',
    styles: [
        `
            :host {
                display: block;
            }
        `
    ],
    template: `
        <ng-content></ng-content>
        <div #anchor></div>
        <!------- Add for increase anchor height ------->
        <div style="height: 10px"></div>
    `
})
export class InfiniteScrollComponent implements OnInit, OnDestroy {
    @Input() options = {};
    @Output() scrolled: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild('anchor', {static: true}) anchor: ElementRef<HTMLElement> | undefined;

    private observer: IntersectionObserver | undefined;

    constructor(private host: ElementRef) {}

    ngOnInit() {
        const options = {
            root: this.isHostScrollable() ? this.host.nativeElement : null,
            ...this.options
        };

        this.observer = new IntersectionObserver(([entry]) => {
            entry.isIntersecting && this.scrolled.emit();
        }, options);

        if (typeof this.anchor !== 'undefined') {
            this.observer.observe(this.anchor.nativeElement);
        }
    }

    get element() {
        return this.host.nativeElement;
    }

    private isHostScrollable() {
        const style = window.getComputedStyle(this.element);

        return style.getPropertyValue('overflow') === 'auto' || style.getPropertyValue('overflow-y') === 'scroll';
    }

    ngOnDestroy() {
        if (typeof this.observer !== 'undefined') {
            this.observer.disconnect();
        }
        this.scrolled.complete();
    }
}
