import {Action} from '@ngrx/store';
import {ICompany} from '@cyberco-nodejs/zipi-typings';

export enum SubCompaniesActionType {
    GET_SUBCOMPANIES = 'SUBCOMPANIES] GET_SUBCOMPANIES',
    GET_SUBCOMPANIES_SUCCESS = 'SUBCOMPANIES] GET_SUBCOMPANIES_SUCCESS',
    GET_SUBCOMPANIES_ALL = 'SUBCOMPANIES] GET_SUBCOMPANIES_ALL',
    GET_SUBCOMPANIES_ALL_SUCCESS = 'SUBCOMPANIES] GET_SUBCOMPANIES_ALL_SUCCESS',
    CREATE_SUBCOMPANY = 'SUBCOMPANIES] CREATE_SUBCOMPANY',
    CREATE_SUBCOMPANY_SUCCESS = 'SUBCOMPANIES] CREATE_SUBCOMPANY_SUCCESS'
}

export class GetSubcompanies implements Action {
    readonly type: string = SubCompaniesActionType.GET_SUBCOMPANIES;
    constructor(public payload?: any) {}
}

export class GetSubcompaniesSuccess implements Action {
    readonly type: string = SubCompaniesActionType.GET_SUBCOMPANIES_SUCCESS;

    constructor(public payload?: ICompany[]) {}
}

export class GetSubcompaniesAll implements Action {
    readonly type: string = SubCompaniesActionType.GET_SUBCOMPANIES_ALL;
    constructor(public payload?: any) {}
}

export class GetSubcompaniesAllSuccess implements Action {
    readonly type: string = SubCompaniesActionType.GET_SUBCOMPANIES_ALL_SUCCESS;

    constructor(public payload?: ICompany[]) {}
}

export class CreateSubCompany implements Action {
    readonly type: string = SubCompaniesActionType.CREATE_SUBCOMPANY;

    constructor(public payload?: ICompany) {}
}

export class CreateSubCompanySuccess implements Action {
    readonly type: string = SubCompaniesActionType.CREATE_SUBCOMPANY_SUCCESS;

    constructor(public payload?: ICompany) {}
}

export type SubCompaniesAction =
    | GetSubcompanies
    | GetSubcompaniesSuccess
    | GetSubcompaniesAll
    | GetSubcompaniesAllSuccess
    | CreateSubCompany
    | CreateSubCompanySuccess;
