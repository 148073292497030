import {Component, Input, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {Subject} from 'rxjs';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {IReportsPreviewResultData} from '../../../../store/reports.reducer';

@Component({
    selector: 'reports-preview-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductPreviewComponent implements OnInit, OnDestroy {
    @ViewChild('productPreviewInput') productPreviewInput: ElementRef<HTMLInputElement> | undefined;
    @ViewChild('autoPreviewProduct') matAutocompleteProduct: MatAutocomplete | undefined;
    @Input() reportData: IReportsPreviewResultData = {
        products: [],
        data: [],
        total: [],
        dealCount: [],
        period: null
    };

    @Input()
    set report(data: any) {
        this._report = data;
    }

    get report() {
        return this._report;
    }

    private _report: any = null;
    private unsubscribe: Subject<void> = new Subject();

    constructor() {}

    /**
     * On init
     */
    ngOnInit() {}

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
