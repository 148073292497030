import {Component, Input, OnInit} from '@angular/core';
import {GenericFormGroup} from '../../../../../entites/generic.entity';
import {RuleModel} from '../../models/rule.model';

@Component({
    selector: 'app-company-commission-categorize-template',
    template: `
        <div style="margin-top: 16px">
            <div class="card-content" style="width: 100%; justify-content: space-between; flex-direction: row">
                <app-money-collection
                    buttonLabel="Categorize Commission"
                    [additionalCommissionType]="'FinancialTransferEntity'"
                    (removeItem)="removeFinancialFG($event)"
                    [financialTransfersArray]="ruleFG!.controls.commission_categorization!.controls.money_list!"
                    [disabled]="disabled"
                ></app-money-collection>
            </div>
        </div>
    `
})
export class ProvidesCommissionCategorizeTemplateComponent implements OnInit {
    @Input() ruleFG: GenericFormGroup<RuleModel> = new GenericFormGroup(new RuleModel());
    @Input() disabled: boolean = false;

    object_keys(obj: object) {
        return Object.keys(obj);
    }

    constructor() {}

    ngOnInit() {}

    removeFinancialFG(index: number) {
        this.ruleFG!.controls.commission_categorization!.controls.money_list!.removeAt(index);
    }
}
