import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-quickbooks-dialog',
    styles: [
        `
            .items-wrap {
                min-height: 40px;
                line-height: 18px;
            }
        `
    ],
    template: `
        <div>
            <h2 md-dialog-title>QuickBooks Integration</h2>
            <div md-dialog-content class="items-wrap pt-2">
                <p style="line-height: 22px;">
                    Please note that information in SkySlope Books (for example: ledger, product/services, invoices and
                    bills) will be shared with your QuickBook account, this includes mapping configurations created by
                    you or your users. Please ensure all details are accurate. SkySlope assumes no control or liability
                    regarding the accuracy of these mappings or data imported into Quickbooks.
                </p>
            </div>
            <div md-dialog-actions>
                <div class="w-100 mt-4" style="text-align: right;">
                    <button
                        type="button"
                        mat-raised-button
                        style="margin-right: 10px;"
                        tabindex="-1"
                        (click)="dialogRef.close(false)"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        mat-raised-button
                        tabindex="-1"
                        color="primary"
                        (click)="dialogRef.close(true)"
                    >
                        Agree & Continue
                    </button>
                </div>
            </div>
        </div>
    `
})
export class ConfirmQuickbooksDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmQuickbooksDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {}
    ) {}
}
