import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
// import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {SharedModule} from '../shared/shared.module';
import {RbacModule} from '../rbac/rbac.module';
import {UiModsSource} from './ui-mods.source';
import {CreateDealMenuOptionsComponent} from './components/create-deal-menu-options.component';
import {UiModsApi} from './api/ui-mods.api';
import {DealBadgesInListComponent} from './components/deal-badges-in-list.component';
import {DealBadgesOnPageComponent} from './components/deal-badges-on-page.component';
import {DealBadgeInListComponent} from './components/deal-badge-in-list.component';

// import {DealCommissionCategorizationNotesComponent} from './components/deal-commission-categorization-notes.component';

@NgModule({
    declarations: [
        CreateDealMenuOptionsComponent,
        DealBadgesInListComponent,
        DealBadgeInListComponent,
        DealBadgesOnPageComponent
        // DealCommissionCategorizationNotesComponent,
    ],
    imports: [
        CommonModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        // BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        RbacModule
    ],
    exports: [
        CreateDealMenuOptionsComponent,
        DealBadgesInListComponent,
        DealBadgeInListComponent,
        DealBadgesOnPageComponent
        // DealCommissionCategorizationNotesComponent,
    ],
    providers: [UiModsSource, UiModsApi],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UiModsModule {}
