import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {map, filter, takeUntil, tap} from 'rxjs/operators';
import {assign} from 'lodash-es';
import {IContact, ILoan} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';
import {INTEREST_PERIOD} from 'app/local-typings';
import * as moment from 'moment';
import {LoansService} from '../../../../../services/api/finance/loans.service';

@Component({
    selector: 'app-user-loan-page',
    templateUrl: 'user-loan-page.component.html',
    styleUrls: ['user-loan-page.component.css']
})
export class UserLoanPageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    loan:
        | (ILoan & {contacts: IContact[]; interestPeriod: {value: string; label: string}; interestLimit: number})
        | undefined;
    interestPeriod = INTEREST_PERIOD;

    constructor(
        private route: ActivatedRoute,
        public dialog: MatDialog,
        public loansService: LoansService
    ) {}

    initPreview() {
        this.route.paramMap
            .pipe(
                map((pm) => {
                    const stringId: string | null = pm.get('id');
                    return Number(stringId);
                }),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                if (id) {
                    this.loansService
                        .getLoanById(id)
                        .pipe(
                            tap((loan: ILoan) => {
                                const interestPeriod = this.interestPeriod.find(
                                    (period) => period.value === loan.interest_period
                                );
                                let interestLimit = '';
                                if (loan.interest_rate_limit_type === 'number_of_periods') {
                                    interestLimit = `(${loan.interest_rate_limit_number} periods)`;
                                } else if (loan.interest_rate_limit_type === 'date') {
                                    interestLimit = `(until ${moment(loan.interest_rate_limit_date).format('ll')})`;
                                }

                                return assign({}, loan, {
                                    interestPeriod: interestPeriod,
                                    interestLimit: interestLimit
                                });
                            }),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe((loan: any) => {
                            this.loan = loan;
                        });
                }
            });
    }

    ngOnInit() {
        this.initPreview();
    }

    payLoan(loan: ILoan) {
        alert('amount - $' + loan.amount);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
