<div>
    <mat-card class="preview-body" *ngIf="buyer.entities.length">
        <div class="tables">
            <div class="table-wrapper">
                <div class="row">
                    <div class="col-xl-12">
                        <h2 class="table-header sticky">Purchase Side</h2>
                        <table mat-table [dataSource]="buyer.entities" class="w-100">
                            <ng-container [matColumnDef]="column.value" *ngFor="let column of tableStructure">
                                <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element[column.value] | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="totalsName">
                                <td mat-footer-cell *matFooterCellDef class="table-footer-row-title">Totals:</td>
                            </ng-container>

                            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerTotalColumns">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getTotalCost(column.value, 'buyer') | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['totalsName'].concat(footerTotalColumnsToDisplay)"
                            ></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>

    <mat-card class="preview-body" *ngIf="seller.entities.length">
        <div class="tables">
            <div class="table-wrapper">
                <div class="row">
                    <div class="col-xl-12">
                        <h2 class="table-header sticky">Listing Side</h2>
                        <table mat-table [dataSource]="seller.entities" class="w-100">
                            <ng-container [matColumnDef]="column.value" *ngFor="let column of tableStructure">
                                <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element[column.value] | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="totalsName">
                                <td mat-footer-cell *matFooterCellDef class="table-footer-row-title">Totals:</td>
                            </ng-container>

                            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerTotalColumns">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getTotalCost(column.value, 'seller') | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['totalsName'].concat(footerTotalColumnsToDisplay)"
                            ></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>

    <mat-card class="preview-body" *ngIf="referral.entities.length">
        <div class="tables">
            <div class="table-wrapper">
                <div class="row">
                    <div class="col-xl-12">
                        <h2 class="table-header sticky">Referral Side</h2>
                        <table mat-table [dataSource]="referral.entities" class="w-100">
                            <ng-container [matColumnDef]="column.value" *ngFor="let column of tableStructure">
                                <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element[column.value] | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="totalsName">
                                <td mat-footer-cell *matFooterCellDef class="table-footer-row-title">Totals:</td>
                            </ng-container>

                            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerTotalColumns">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getTotalCost(column.value, 'referral') | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['totalsName'].concat(footerTotalColumnsToDisplay)"
                            ></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>

    <mat-card class="preview-body" *ngIf="rentalOwner.entities.length">
        <div class="tables">
            <div class="table-wrapper">
                <div class="row">
                    <div class="col-xl-12">
                        <h2 class="table-header sticky">Rental Owner Side</h2>
                        <table mat-table [dataSource]="rentalOwner.entities" class="w-100">
                            <ng-container [matColumnDef]="column.value" *ngFor="let column of tableStructure">
                                <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element[column.value] | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="totalsName">
                                <td mat-footer-cell *matFooterCellDef colspan="2" class="table-footer-row-title">
                                    Totals:
                                </td>
                            </ng-container>

                            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerTotalColumns">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getTotalCost(column.value, 'rentalOwner') | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['totalsName'].concat(footerTotalColumnsToDisplay)"
                            ></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>

    <mat-card class="preview-body" *ngIf="rentalTenant.entities.length">
        <div class="tables">
            <div class="table-wrapper">
                <div class="row">
                    <div class="col-xl-12">
                        <h2 class="table-header sticky">Rental Tenant Side</h2>
                        <table mat-table [dataSource]="rentalTenant.entities" class="w-100">
                            <ng-container [matColumnDef]="column.value" *ngFor="let column of tableStructure">
                                <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element[column.value] | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="totalsName">
                                <td mat-footer-cell *matFooterCellDef class="table-footer-row-title">Totals:</td>
                            </ng-container>

                            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerTotalColumns">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getTotalCost(column.value, 'rentalTenant') | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['totalsName'].concat(footerTotalColumnsToDisplay)"
                            ></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>

    <mat-card class="preview-body" *ngIf="combined.entities.length">
        <div class="tables">
            <div class="table-wrapper">
                <div class="row">
                    <div class="col-xl-12">
                        <h2 class="table-header sticky">Combined</h2>
                        <table mat-table [dataSource]="combined.entities" class="w-100">
                            <ng-container [matColumnDef]="column.value" *ngFor="let column of tableStructure">
                                <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element[column.value] | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="totalsName">
                                <td mat-footer-cell *matFooterCellDef class="table-footer-row-title">Totals:</td>
                            </ng-container>

                            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerTotalColumns">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getTotalCost(column.value, 'combined') | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['totalsName'].concat(footerTotalColumnsToDisplay)"
                            ></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>

    <mat-card class="preview-body" *ngIf="!combined.entities.length">
        <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
    </mat-card>
</div>
