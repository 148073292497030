import {filter, takeUntil} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';

import {Profile} from '../../../../models/profile';
import {Group} from '../../../../models/group';
import {CurrentProfileSource} from '../../../../services/sources/current-profile.source';
import {GroupApi} from '../../../../services/api/group.api';
import {GenericFormArray} from '../../../../entites/generic.entity';
import {GroupsSource} from '../../../../services/sources/groups.source';
import {FetchContactsMust} from '../../../contacts/store/contacts.actions';
import {AbstractControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {EditComponent} from '../common/edit/edit.component';

@Component({
    selector: 'app-company-groups-tab-1-all',
    styles: [
        `
            .sidebar-body {
                width: 100%;
            }

            .sidebar-page {
                margin-top: 7px;
                height: 100%;
                width: 100%;
                overflow: auto;
            }

            .sidebar-wrapper {
                height: 100%;
                overflow-y: hidden;
                display: flex;
                flex-direction: column;
            }
        `
    ],
    template: `
        <div class="sidebar-page">
            <!------- Edit group ------->
            <app-group-edit-sidenav #groupsSidebar [isOpened]="isSidebarOpened" class="sidebar-wrapper">
                <div class="sidebar-body">
                    <!------- Create group ------->
                    <div class="p-3" *ngIf="createMode">
                        <groups-new
                            (createdGroup)="doCreate($event.newGroup, $event.parent)"
                            [isOpenGroupArea]="createMode"
                            [groupList]="groupsList"
                            (hide)="createMode = false; createModeEmitter.emit(createMode)"
                        >
                        </groups-new>
                    </div>
                    <!------- List of groups ------->
                    <ng-container *ngIf="!createMode">
                        <div class="deals-list-area" *ngIf="getGroups().length > 0">
                            <ng-container *ngFor="let company_group of getGroups()">
                                <app-groups-list-item-all
                                    style="margin-bottom: 4px"
                                    [groupFG]="company_group"
                                    (click)="openSidenav(company_group)"
                                ></app-groups-list-item-all>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </app-group-edit-sidenav>
        </div>
    `
})
export class Tab1AllComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('groupsSidebar') groupsSidebar: EditComponent | undefined;

    protected currentProfile: Profile = new Profile();
    public groupsList: Group[] = [];
    public createMode: boolean = false;

    @Input() groupsListFA: GenericFormArray<Group> = new GenericFormArray([]);
    @Input() enterCreationMode$: Subject<any> = new Subject<any>();
    @Input() isExistSubCompanies: boolean = false;
    @Output() createModeEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

    isSidebarOpened: boolean = false;

    constructor(
        public dialog: MatDialog,
        protected currentProfileSource: CurrentProfileSource,
        protected groupsService: GroupApi,
        protected groupSrc: GroupsSource,
        private store: Store<any>
    ) {
        this.currentProfileSource.changeProfileEvent
            .pipe(
                filter((profile) => profile.company !== null),
                takeUntil(this.unsubscribe)
            )
            .subscribe((profile) => (this.currentProfile = profile));
        this.groupSrc.source
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((list) => (this.groupsList = list.filter((group) => group.type !== Group.type_SET.division)));
    }

    ngOnInit() {
        this.enterCreationMode$.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.enterCreationMode();
        });
    }

    getGroups() {
        return this.groupsListFA.controls.filter((groupFG) => groupFG.get('type')?.value !== Group.type_SET.division);
    }

    enterCreationMode() {
        this.createMode = true;
        this.createModeEmitter.emit(this.createMode);
    }

    doCreate(newGroup: Group, parent: Group | null = null) {
        const data = {
            title: newGroup.title,
            type: newGroup.type,
            permissions: newGroup.permissions,
            parent__group_fk_id: newGroup.parent__group_fk_id
        };

        // @todo: it should close create box, open newly created group and center the opened group on screen
        if (parent !== null) {
            return this.groupsService.clone({...data, parent}).then(this.handleSuccessCreation.bind(this));
        } else {
            return this.groupsService.create(data).then(this.handleSuccessCreation.bind(this));
        }
    }

    openSidenav(group: AbstractControl) {
        if (typeof this.groupsSidebar !== 'undefined') {
            this.groupsSidebar.openGroup(group.get('id')?.value);
        }
    }

    handleSuccessCreation(result: any) {
        this.createMode = false;
        this.createModeEmitter.emit(this.createMode);
        this.groupSrc.load(this.currentProfile);

        // update contact list after success
        this.store.dispatch(new FetchContactsMust());
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.enterCreationMode$.complete();
        this.createModeEmitter.complete();
    }
}
