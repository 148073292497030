<app-company-compensation-combined-picker
    [allowDealWildcard]="allowDealWildcard"
    [nodesFA]="nodesFA"
    [hideControls]="hideControls"
    [singleSelected]="singleSelected"
    [heading]="heading"
    [title]="title"
    [bold_style]="bold_style"
    [only_compensation_groups]="only_compensation_groups"
    [without_company_group]="without_company_group"
    [availableTypes]="availableTypes"
    [displayMode]="displayMode"
    [positionMode]="positionMode"
    [disabled]="disabled"
    [seemDisabled]="seemDisabled"
    [disableUpdate]="disableUpdate"
    [initialContactIds]="initialContactIds"
    [initialGroupIds]="initialGroupIds"
    [initialProfilesInGroupIds]="initialProfilesInGroupIds"
    [initialProfileIds]="initialProfileIds"
    [initialContactProfileIds]="initialContactProfileIds"
    [panelWidth]="panelWidth"
    [onlyDivisions]="onlyDivisions"
    [onlyCommonGroups]="onlyCommonGroups"
    [allowSubCompanySearch]="allowSubCompanySearch"
    [chosenSubCompanies]="updatedListOfSubCompanies"
    [closePanelEvent$]="closePanelEvent$"
    [contactMetatype]="contactMetatype"
    [contactPersonMain]="contactPersonMain"
    [isContactNeeded]="isContactNeeded"
    [dealParticipantsWithSubType]="dealParticipantsWithSubType"
    [excludeMembersFromGroup]="excludeMembersFromGroup"
    [closeAfterSelection]="closeAfterSelection"
    [lineClamp]="lineClamp"
    [textParticipants]="textParticipants"
    [needContactName]="needContactName"
    (contactCreateEmitter)="contactCreateEmitter.next($event)"
    (isLoadedEmitter)="isLoadedEmitter.next($event)"
    (toggleVisibilityEmitter)="toggleVisibilityEmitter.next($event)"
    (contactCreateFromTextEmitter)="contactCreateFromTextEmitter.next($event)"
>
    <ng-container *ngIf="availableCompanies.length > 1">
        <!--Advanced filters-->
        <h3 *ngIf="additionalTitle">{{ additionalTitle }}</h3>

        <!--Allow to select sub-companies, if they exists-->
        <mat-form-field class="w-auto">
            <mat-select multiple placeholder="Sub-Companies" [formControl]="chosenSubCompaniesFC">
                <mat-option *ngFor="let company of availableCompanies; trackBy: trackById" [value]="company">
                    {{ company.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="w-auto font-italic mb-4" style="color: darkgray">
            Selected sub-companies:
            <ng-container *ngFor="let comp of updatedListOfSubCompanies.value">
                <div>{{ comp.title }}</div>
            </ng-container>
        </div>
    </ng-container>
</app-company-compensation-combined-picker>
