import {Action} from '@ngrx/store';
import {IContactInvite, IUser, IAuthAcceptInviteData} from '../../../../typings';

export enum CONTACT_INVITES_ACTION_TYPES {
    FETCH_CONTACT_INVITE = '[contactInvites] FETCH_CONTACT_INVITE',
    CONTACT_INVITE_RECEIVED = '[contactInvites] CONTACT_INVITE_RECEIVED',
    INVITE_ACCEPT_ENDED = '[contactInvites] INVITE_ACCEPT_ENDED',
    SUBMIT_ACCEPT_INVITE_FORM = '[contactInvites] SUBMIT_ACCEPT_INVITE_FORM',
    LOGOUT_AND_GO_TO = '[contactInvites] LOGOUT_AND_GO_TO',
    LOGOUT_AND_GO_TO_WITHOUT_REDIRECT_ACTION = '[contactInvites] LOGOUT_AND_GO_TO_WITHOUT_REDIRECT_ACTION',
    LOGOUT_AND_GO_TO_INVOICE = '[contactInvites] LOGOUT_AND_GO_TO_INVOICE'
}

export class FetchContactInviteAction implements Action {
    readonly type: string = CONTACT_INVITES_ACTION_TYPES.FETCH_CONTACT_INVITE;

    constructor(public payload?: string) {}
}

export class ContactInviteReceivedAction implements Action {
    readonly type: string = CONTACT_INVITES_ACTION_TYPES.CONTACT_INVITE_RECEIVED;

    constructor(public payload?: IContactInvite) {}
}

export class SubmitAcceptInviteFormAction implements Action {
    readonly type: string = CONTACT_INVITES_ACTION_TYPES.SUBMIT_ACCEPT_INVITE_FORM;

    constructor(public payload?: IAuthAcceptInviteData) {}
}

export class InviteAcceptEndedAction implements Action {
    readonly type: string = CONTACT_INVITES_ACTION_TYPES.INVITE_ACCEPT_ENDED;

    constructor(public payload?: boolean) {}
}

export class LogoutAndGoToAction implements Action {
    readonly type: string = CONTACT_INVITES_ACTION_TYPES.LOGOUT_AND_GO_TO;

    constructor(public payload?: {goTo: string[]; authInfoMessage?: string}) {}
}

export class LogoutAndGoToInvoiceAction implements Action {
    readonly type: string = CONTACT_INVITES_ACTION_TYPES.LOGOUT_AND_GO_TO_INVOICE;

    constructor(public payload?: {goTo: string[]; authInfoMessage?: string}) {}
}

export class LogoutAndGoToWithoutRedirectAction implements Action {
    readonly type: string = CONTACT_INVITES_ACTION_TYPES.LOGOUT_AND_GO_TO_WITHOUT_REDIRECT_ACTION;

    constructor(public payload?: {goTo: string[]; authInfoMessage?: string}) {}
}
