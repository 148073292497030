import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {GenericFormGroup} from '../../../../../entites/generic.entity';
import {DisbursementTemplateModel} from '../../models/disbursement-template.model';
import {DisbursementInstructionsEntity} from '../../../../../entites/disbursement-instructions.entity';
import {DisbursementFieldEntity} from '../../../../../entites/disbursement-instructions.entity';
import {filter, takeUntil} from 'rxjs/operators';
import {CompaniesService} from '../../../../../services/companies.service';
import {Subject} from 'rxjs';
import {CurrentProfileSource} from '../../../../../services/sources/current-profile.source';
import {Profile} from '../../../../../models/profile';
import {ICustomDealField} from '@cyberco-nodejs/zipi-typings/index';
import {MatDialog} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {selectWildcards} from '../../../../../store/company-wide/company-wide.selectors';
import {IWildcard} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-company-disbursement-instructions',
    styles: [
        `
            .flex-row {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .h4-margin {
                margin-bottom: 0 !important;
                margin-top: 5px;
            }

            code {
                background-color: #eee;
                border-radius: 3px;
                font-family: courier, monospace;
                padding: 0 3px;
                color: red;
            }
            .h-70 {
                min-height: 70px;
            }

            .mb-0 {
                margin-bottom: 0 !important;
            }

            .ml-40 {
                margin-left: 40px;
            }
        `
    ],
    template: `
        <div style="margin-top: 16px">
            <h3>CDA Disbursement Title</h3>
            <mat-form-field class="w-100">
                <textarea
                    matInput
                    type="text"
                    name="title"
                    placeholder="CDA Disbursement Title"
                    [formControl]="disbursementInstructionsFG!.controls.disbursement_title!"
                ></textarea>
            </mat-form-field>

            <br /><br /><br />
            <h3>CDA Disbursement Fields</h3>
            <div class="flex-row">
                <div style="width: 33%;">
                    <div
                        *ngFor="
                            let dfFG of disbursementInstructionsFG?.controls?.disbursement_fields?.controls;
                            index as i
                        "
                    >
                        <div *ngIf="i < disbursementFieldsNumber / 3">
                            <mat-checkbox [formControl]="dfFG!.controls.is_shown!">{{
                                dfFG?.controls?.type?.value === 'standard'
                                    ? dfFG?.controls?.system_key?.value
                                    : dfFG?.controls?.custom_key?.value
                            }}</mat-checkbox>
                        </div>
                    </div>
                </div>
                <div style="width: 33%;">
                    <div
                        *ngFor="
                            let dfFG of disbursementInstructionsFG?.controls?.disbursement_fields?.controls;
                            index as i
                        "
                    >
                        <div *ngIf="i < (2 * disbursementFieldsNumber) / 3 && i >= disbursementFieldsNumber / 3">
                            <mat-checkbox [formControl]="dfFG!.controls.is_shown!">{{
                                dfFG?.controls?.type?.value === 'standard'
                                    ? dfFG?.controls?.system_key?.value
                                    : dfFG?.controls?.custom_key?.value
                            }}</mat-checkbox>
                        </div>
                    </div>
                </div>
                <div style="width: 33%;">
                    <div
                        *ngFor="
                            let dfFG of disbursementInstructionsFG?.controls?.disbursement_fields?.controls;
                            index as i
                        "
                    >
                        <div *ngIf="i >= (2 * disbursementFieldsNumber) / 3">
                            <mat-checkbox [formControl]="dfFG!.controls.is_shown!">{{
                                dfFG?.controls?.type?.value === 'standard'
                                    ? dfFG?.controls?.system_key?.value
                                    : dfFG?.controls?.custom_key?.value
                            }}</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>

            <br /><br /><br />
            <div class="flex-row">
                <h3 class="mb-0">CDA Disbursement Text</h3>
                <mat-slide-toggle class="ml-40" [formControl]="disbursementInstructionsFG!.controls.is_payouts_shown!"
                    >Display Payouts Table</mat-slide-toggle
                >
                <mat-icon
                    class="material-icons-outlined ml-2"
                    style="color: rgb(113, 113, 113)"
                    matTooltip="Turn on to display Sales Entities and payout amounts on CDA Disbursement file."
                    matTooltipClass="tooltip"
                    matTooltipPosition="right"
                >
                    info
                </mat-icon>
            </div>

            <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between;">
                <div style="display: flex; flex-direction: column; width: 47%; margin-top: 6px;">
                    <mat-form-field class="w-100">
                        <textarea
                            class="h-70"
                            matInput
                            type="text"
                            name="message_1"
                            placeholder="Deal Information"
                            [formControl]="disbursementInstructionsFG!.controls.deal_information!"
                        ></textarea>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                        <textarea
                            class="h-70"
                            matInput
                            type="text"
                            name="notification_1"
                            placeholder="Transaction Title"
                            [formControl]="disbursementInstructionsFG!.controls.transaction_title!"
                        ></textarea>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                        <textarea
                            class="h-70"
                            matInput
                            type="text"
                            name="message_2"
                            placeholder="Transaction Footer"
                            [formControl]="disbursementInstructionsFG!.controls.transaction_footer!"
                        ></textarea>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                        <textarea
                            class="h-70"
                            matInput
                            type="text"
                            name="footer_message"
                            placeholder="Disbursement Footer"
                            [formControl]="disbursementInstructionsFG!.controls.disbursement_footer!"
                        ></textarea>
                    </mat-form-field>
                </div>

                <div style="width: 47%">
                    <h4>Creation of Customized CDA Text</h4>
                    <div>
                        This feature allows for the use of text to be combined with certain fields from the SkySlope
                        Books Deal Card. In order to customize each CDA Disbursement Text section, please copy the
                        variable’s <code>red text</code> from the Available Inputs list below and insert as shown:
                    </div>
                    <ul>
                        <li>Example: GCI = $1,000</li>
                        <li>CDA Disbursement Text Input: GCI Amount of <code>[[gross_commission]]</code> Earned</li>
                        <li>CDA Display Result: GCI Amount of $1,000 Earned</li>
                    </ul>
                    <h4 class="h4-margin">Available Inputs</h4>
                    <div>Gross Commission: <code>[[gross_commission]]</code></div>
                    <div>Sales Price: <code>[[sales_price]]</code></div>
                    <div>Company Payout: <code>[[company_payout_amount]]</code></div>
                    <div *ngFor="let field of companyCustomFields">
                        {{ transformLabel(field.label) }}: <code>[[deal_cf_{{ field.custom_deal_field_id }}]]</code>
                    </div>
                    <div *ngFor="let wildcard of allWildcards">
                        {{ wildcard.title }}: <code>[[primary_agent_attr_{{ wildcard.wildcard_id }}]]</code>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class ProvidesDisbursementInstructionsComponent implements OnDestroy, OnChanges {
    public template_id: number | null = null;
    public list: DisbursementTemplateModel[] = [];
    @Input() disbursementInstructionsFG: GenericFormGroup<DisbursementInstructionsEntity> | undefined =
        new GenericFormGroup(new DisbursementInstructionsEntity());
    @Input() compensationType: string = 'default';
    @Input() disabled: boolean = false;

    private unsubscribe: Subject<void> = new Subject();
    public currentProfile: Profile = new Profile();
    public allWildcards: IWildcard[] = [];

    public companyCustomFields: ICustomDealField[] = [];
    public disbursementFieldsNumber: number = 0;

    constructor(
        private companiesService: CompaniesService,
        public dialog: MatDialog,
        protected currentProfileSource: CurrentProfileSource,
        private store: Store<any>
    ) {
        this.currentProfileSource.changeProfileEvent
            .pipe(
                filter((profile) => profile !== null),
                takeUntil(this.unsubscribe)
            )
            .subscribe((profile) => {
                this.currentProfile = profile;
            });
        this.companiesService
            .getCustomFields(this.currentProfile.company_fk_id!)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((fields) => {
                if (typeof this.disbursementInstructionsFG !== 'undefined') {
                    const fieldsInDisbursement: DisbursementFieldEntity[] =
                        this.disbursementInstructionsFG.controls.disbursement_fields!.value;
                    this.companyCustomFields = fields.result;
                    fieldsInDisbursement.forEach((field) => {
                        const dfToDelete: ICustomDealField | undefined = this.companyCustomFields.find(
                            (fd) => fd.custom_deal_field_id === field.custom_field_id
                        );
                        if (!dfToDelete && field.type === 'custom') {
                            const indexToDelete: number = fieldsInDisbursement.indexOf(field);
                            fieldsInDisbursement.splice(indexToDelete, 1);
                        }
                    });
                    this.companyCustomFields.forEach((field) => {
                        const dfToAdd: DisbursementFieldEntity | undefined = fieldsInDisbursement.find(
                            (fd) => fd.custom_field_id === field.custom_deal_field_id
                        );
                        if (!dfToAdd) {
                            fieldsInDisbursement.push({
                                is_shown: false,
                                custom_field_id: field.custom_deal_field_id!,
                                system_key: null,
                                type: 'custom',
                                custom_key: this.transformLabel(field.label)
                            });
                        } else {
                            if (dfToAdd.custom_key !== this.transformLabel(field.label)) {
                                const indexToChange: number = fieldsInDisbursement.indexOf(dfToAdd);
                                fieldsInDisbursement[indexToChange].custom_key = this.transformLabel(field.label);
                            }
                        }
                    });
                    this.disbursementInstructionsFG.controls.disbursement_fields!.patchValue(fieldsInDisbursement);
                    this.disbursementFieldsNumber = fieldsInDisbursement.length;
                }
            });

        this.store.pipe(select(selectWildcards), takeUntil(this.unsubscribe)).subscribe((wildcards) => {
            this.allWildcards = Array.from(wildcards);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.disabled && changes.disabled.currentValue) {
            this.disbursementInstructionsFG!.controls.disbursement_title?.disable({emitEvent: false});
            if (this.disbursementInstructionsFG?.controls?.disbursement_fields?.controls) {
                for (const dfFG of this.disbursementInstructionsFG?.controls?.disbursement_fields?.controls) {
                    dfFG!.controls.is_shown?.disable({emitEvent: false});
                }
            }
            this.disbursementInstructionsFG!.controls.is_payouts_shown?.disable({emitEvent: false});
            this.disbursementInstructionsFG!.controls.deal_information?.disable({emitEvent: false});
            this.disbursementInstructionsFG!.controls.transaction_title?.disable({emitEvent: false});
            this.disbursementInstructionsFG!.controls.transaction_footer?.disable({emitEvent: false});
            this.disbursementInstructionsFG!.controls.disbursement_footer?.disable({emitEvent: false});
        } else if (changes.disabled && !changes.disabled.currentValue) {
            this.disbursementInstructionsFG!.controls.disbursement_title?.enable({emitEvent: false});
            if (this.disbursementInstructionsFG?.controls?.disbursement_fields?.controls) {
                for (const dfFG of this.disbursementInstructionsFG?.controls?.disbursement_fields?.controls) {
                    dfFG!.controls.is_shown?.enable({emitEvent: false});
                }
            }
            this.disbursementInstructionsFG!.controls.is_payouts_shown?.enable({emitEvent: false});
            this.disbursementInstructionsFG!.controls.deal_information?.enable({emitEvent: false});
            this.disbursementInstructionsFG!.controls.transaction_title?.enable({emitEvent: false});
            this.disbursementInstructionsFG!.controls.transaction_footer?.enable({emitEvent: false});
            this.disbursementInstructionsFG!.controls.disbursement_footer?.enable({emitEvent: false});
        }
    }

    transformLabel(label: string): string {
        return label[0].toUpperCase() + label.split('_').join(' ').slice(1);
    }

    object_keys(obj: {[key: string]: any}): string[] {
        return Object.keys(obj);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
