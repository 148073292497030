import {NgModule} from '@angular/core';

import {StoreModule} from '@ngrx/store';
import {subCompaniesReducer} from './store/subcompanies.reducer';
import {EffectsModule} from '@ngrx/effects';
import {SubCompaniesEffectsService} from './store/subcompanies.effects';

@NgModule({
    declarations: [],
    imports: [
        StoreModule.forFeature('subcompanies', subCompaniesReducer),
        EffectsModule.forFeature([SubCompaniesEffectsService])
    ],
    providers: [],
    exports: [],
    bootstrap: []
})
export class SubCompaniesModule {}
