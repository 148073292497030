import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as _ from 'lodash-es';
import {MessagesService} from '../../messages.service';
import {SpannerNotificationInterface} from '../../../../models/user-notification';

@Component({
    selector: 'report-files-modal',
    templateUrl: './report-files-modal.component.html',
    styleUrls: ['./report-files-modal.component.css']
})
export class ReportFilesModalComponent implements OnInit, OnDestroy {
    files: Array<any> = [];
    notification: any = null;

    constructor(
        public dialogRef: MatDialogRef<ReportFilesModalComponent>,
        protected messageService: MessagesService,
        @Inject(MAT_DIALOG_DATA) public data: {notification: any}
    ) {
        this.setNotification(data.notification);
    }

    ngOnDestroy(): void {}

    ngOnInit(): void {}

    setNotification(notification: SpannerNotificationInterface) {
        const tmpNotification = {...notification};

        this.notification = tmpNotification;
        this.files = tmpNotification.payload.files ? Object.values(tmpNotification.payload.files) : [];
    }

    close() {
        this.dialogRef.close();
    }
}
