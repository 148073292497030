// import * as Sentry from '@sentry/browser';
// @ts-ignore - property Sentry doesn't exist on type 'Window'
// window.Sentry = Sentry;

import {BrowserModule} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injectable} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule, Router} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {NotificationsService, SimpleNotificationsModule} from 'angular2-notifications';

import {environment} from 'environments/environment';

import {AppComponent} from './app.component';
import {routes, routing} from './app.routing';

import {AuthService} from './services/auth.service';
import {ServiceRequester} from './services/service.requester';
import {UserService} from './services/user.service';
import {DealService} from './services/deal.service';
import {AccountInfoModule} from './modules/account-info/account-info.module';
import {GroupApi} from './services/api/group.api';
import {AuthModule} from './modules/auth/auth.module';
import {UserMenuComponent} from './components/user-menu/user-menu.component';
import {UserLayoutComponent} from './layouts/user-layout.component';
import {GuestLayoutComponent} from './layouts/guest-layout.component';
import {AuthBootstrapService} from './services/resolvers/auth-bootstrap.service';
import {GuestBootstrapService} from './services/resolvers/guest-bootstrap.service';
import {PublicBootstrapService} from './services/resolvers/public-bootstrap.service';
import {AdminBootstrapService} from './services/resolvers/admin-bootstrap.service';
import {AccessGuardService} from './services/resolvers/access-guard.service';
import {DashboardModule} from './modules/dashboard/dashboard.module';
import {ProfileModule} from './modules/profile/profile.module';
import {PendingRequestsService} from './services/pending-requests.service';
import {RbacModule} from './modules/rbac/rbac.module';
import {UserNotificationModule} from './modules/user-notification/user-notification.module';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {ProfilesMenuComponent, ProfilesSubMenuComponent} from './components/profiles-menu/profiles-menu.component';
import {ProfilesService} from './services/profiles.service';
import {DenyGlobalViewResolver} from './services/resolvers/deny-global-view.resolver';
import {SharedModule} from './modules/shared/shared.module';
import {FranchiseModule} from './modules/franchise/franchise.module';

import {SessionService} from './services/session.service';
import {AccountFormComponent} from './components/account-form/account-form.component';
import {AddCompanyDialogComponent} from './components/profiles-menu/add-company.dialog.component';
import {AddExistingCompanyDialogComponent} from './components/profiles-menu/add-existing-company/add-existing-company.dialog.component';
import {AddExistingCompanyStep1Component} from './components/profiles-menu/add-existing-company/add-existing-company-step-1.component';
import {AddExistingCompanyStep2Component} from './components/profiles-menu/add-existing-company/add-existing-company-step-2.component';
import {AddExistingCompanyStep3Component} from './components/profiles-menu/add-existing-company/add-existing-company-step-3.component';
import {AccountFormCredentialsComponent} from './components/account-form/account-form-credentials.component';
import {GroupsSource} from './services/sources/groups.source';
import {ProfilesSource} from './services/sources/profiles.source';
import {ChangeEmailApi} from './services/api/change-email.api';
import {SubCompaniesSource} from './services/sources/subCompanies.source';
import {ProfileAccessApi} from './services/api/profile-access.api';
import {AppInitSource} from './services/sources/app-init.source';
import {SystemUserSource} from './services/sources/system-user.source';
import {CurrentProfileSource} from './services/sources/current-profile.source';
import {AvailableProfilesSource} from './services/sources/available-profiles.source';
import {PermissionsSource} from './services/sources/permissions.source';
import {AppInitApi} from './services/api/app-init.api';
import {UiModsModule} from './modules/ui-mods/ui-mods.module';
import {SubscriptionInactiveComponent} from './components/subscription-inactive.component';
import {AccessDeniedComponent} from './components/access-denied.component';
import {NoAuthBootstrapService} from './services/resolvers/no-auth-bootstrap.service';
import {NotFound404Component} from './components/not-found-404.component';
import {ProgressModule} from './modules/progress/progress.module';
import {SelectProfileComponent} from './components/select-profile.component';

import {StoreRouterConnectingModule, FullRouterStateSerializer} from '@ngrx/router-store';
import {AppState, metaReducers, reducers} from './store/root.reducers';
import {EffectsModule} from '@ngrx/effects';
import {Action, StoreModule} from '@ngrx/store';
import {EventsService} from './services/events.service';
import {GPlacesPickerModule} from './modules/gplaces-picker/gplaces-picker.module';
import {ContactsModule} from './modules/contacts/contacts.module';
import {UsersService} from './services/api/users.service';
import {RoutingHistoryService} from './services/routing-history.service';
import {SettingsService} from './services/api/settings.service';
import {ReportsModule} from './modules/reports/reports.module';
import {ContactInvitesModule} from './modules/contact-invites/contact-invites.module';
import {MessagesModule} from './modules/messages/messages.module';
import {IndicatorsModule} from './modules/indicators/indicators.module';
import {GlobalSearchModule} from './modules/global-search/global-search.module';
import {CreateMenuComponent} from './components/create-menu/create-menu.component';
import {SubCompaniesModule} from './modules/sub-companies/sub-companies.module';
import {FinanceModule} from './modules/finance/finance.module';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {BankingModule} from './modules/banking/banking.module';
import {DefaultPageResolverService} from './services/resolvers/default-page-resolver.service';
import {ElasticsearchService} from './modules/global-search/services/elasticsearch.service';
import {MembershipService} from './services/membership.service';
import {CompanyPermissionsSource} from './services/sources/companyPermissions.source';
import {LoggingApiService} from './services/api/logging-api.service';
import {AutoOpenMenuComponent} from './layouts/auto-open-menu.component';
import * as Sentry from '@sentry/angular-ivy';
import {AvailableRolesSource} from './services/sources/available-roles.source';
import {ApiSessionService} from './services/api/api-session.service';
import {SkyslopeAppsMenuComponent} from './components/skyslope-apps-menu/skyslope-apps-menu.component';
import {DealsModule} from './modules/deals/deals.module';
import {DealPayoutsSource} from './services/sources/deal-payouts.source';
import {FeatureFlagsModule} from './modules/feature-flags/feature-flags.module';
import {QBSyncEventsSource} from './services/sources/qb-sync-events.source';
import {CalculationSettingsService} from './services/calculation-settings.service';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {TimeoutWarningDialogComponent} from './modules/auth/timeout-warning-dialog/timeout-warning-dialog.component';
import {UserAdminService} from '@app/services/user-admin.service';
import {CompanyAdminService} from '@app/services/company-admin.service';
import {RedirectToNewEditDealPageGuard} from '@app/services/resolvers/redirect-to-new-edit-deal-page.guard';

if (environment && environment.sentryConfig) {
    const config: {url: string} | null = environment.sentryConfig as any as {url: string};
    if (config) {
        Sentry.init({
            dsn: config.url,
            environment: environment.env,
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 1, // setting for replay rate. 1 means that 100% of replays will be recorded
            release: 'none',
            ignoreErrors: ['Non-Error exception captured'],
            integrations: [
                // integration with Replay.
                // currently commented out because this feature is disabled for us and attempting to save replay will cause an error
                // new Sentry.Replay({
                //     maskAllText: true,
                //     blockAllMedia: true,
                // }),
                new Sentry.BrowserTracing({
                    tracingOrigins: [
                        'localhost',
                        'https://api-core-dot-zipi-prod-v1.appspot.com',
                        'https://api-shipp-dot-zipi-prod-v1.appspot.com'
                    ],
                    beforeNavigate: (context) => {
                        return {
                            ...context,
                            name: location.pathname
                                .replace(/(\/auth\/sign-up-contact-invoice\/)[^\/]+$/, '$1<hash>')
                                .replace(/(\/viewinvoice\/)[^\/]+$/, '$1<hash>')
                                .replace(/(\/auth\/invite\/)[^\/]+$/, '$1<hash>')
                                .replace(/\/\d+/g, '/<digits>')
                        };
                    },
                    routingInstrumentation: Sentry.routingInstrumentation
                })
            ]
        });
    }
}

// if (environment.smartlook) {
//     smartlookClient.init(environment.smartlook.apiKey);
// }

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    handleError(error: any) {
        Sentry.captureException(error.originalError || error);
        console.error(error);
    }
}

@NgModule({
    declarations: [
        AppComponent,
        UserLayoutComponent,
        GuestLayoutComponent,
        // AdminLayoutComponent,
        UserMenuComponent,
        // DateRangePickerComponent,
        ProfilesMenuComponent,
        ProfilesSubMenuComponent,
        AccountFormComponent,
        AccountFormCredentialsComponent,
        AddCompanyDialogComponent,
        AddExistingCompanyDialogComponent,
        AddExistingCompanyStep1Component,
        AddExistingCompanyStep2Component,
        AddExistingCompanyStep3Component,
        SubscriptionInactiveComponent,
        SelectProfileComponent,
        NotFound404Component,
        CreateMenuComponent,
        AccessDeniedComponent,
        AutoOpenMenuComponent,
        SkyslopeAppsMenuComponent,
        SkyslopeAppsMenuComponent,
        TimeoutWarningDialogComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        DealsModule,
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            enableTracing: false
        }),
        BrowserAnimationsModule,
        ReactiveFormsModule,
        AccountInfoModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        DashboardModule,
        AuthModule,
        ProfileModule,
        UserNotificationModule,
        RbacModule,
        SharedModule,
        SimpleNotificationsModule.forRoot(),
        UiModsModule,
        ProgressModule,
        routing,
        StoreModule.forRoot<AppState, Action>(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false
            }
        }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        StoreRouterConnectingModule.forRoot({serializer: FullRouterStateSerializer}),
        EffectsModule.forRoot([]),
        GPlacesPickerModule,
        ContactsModule,
        FinanceModule,
        ReportsModule,
        ContactInvitesModule,
        MessagesModule,
        IndicatorsModule,
        GlobalSearchModule,
        SubCompaniesModule,
        FranchiseModule,
        BankingModule,
        FeatureFlagsModule,
        NgIdleKeepaliveModule.forRoot(),
        MatDialogModule
    ],
    providers: [
        SessionService,
        AppInitSource,
        LoggingApiService,
        SystemUserSource,
        CurrentProfileSource,
        AvailableProfilesSource,
        PermissionsSource,
        GroupsSource,
        CompanyPermissionsSource,
        AvailableRolesSource,
        SubCompaniesSource,
        ProfilesSource,
        DealPayoutsSource,
        QBSyncEventsSource,
        EventsService,
        GroupApi,
        ChangeEmailApi,
        ProfileAccessApi,
        AppInitApi,
        ServiceRequester,
        AuthService,
        CompanyAdminService,
        UserService,
        UserAdminService,
        DealService,
        MembershipService,
        AuthBootstrapService,
        GuestBootstrapService,
        PublicBootstrapService,
        AdminBootstrapService,
        AccessGuardService,
        RedirectToNewEditDealPageGuard,
        NoAuthBootstrapService,
        DenyGlobalViewResolver,
        PendingRequestsService,
        ProfilesService,
        AngularFireDatabase,
        {provide: ErrorHandler, useClass: SentryErrorHandler},
        {
            provide: Sentry.TraceService,
            deps: [Router]
        },
        ElasticsearchService,
        UsersService,
        RoutingHistoryService,
        SettingsService,
        DefaultPageResolverService,
        ApiSessionService,
        NotificationsService,
        CalculationSettingsService,
        // { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
        // { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
        {provide: MAT_DIALOG_DATA, useValue: {}},
        {provide: MatDialogRef, useValue: {}}
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    constructor(trace: Sentry.TraceService) {}
}
