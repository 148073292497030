import {Component, Input, Output, EventEmitter, ViewChild, OnInit, OnDestroy} from '@angular/core';
import {IRole} from '../../../../../typings';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {PermissionPreviewDialogComponent} from './permission-preview-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-edit-user-roles',
    template: `
        <div class="d-flex align-items-center w-100" *ngIf="allRoles">
            <mat-form-field
                *ngIf="rolesIdsFC"
                floatLabel="never"
                class="w-100 mr-2"
                style="height: 30px"
                [ngStyle]="needToMoveHigher ? {top: '-13px'} : null"
            >
                <mat-select
                    placeholder="{{ label }}"
                    [formControl]="rolesIdsFC"
                    multiple
                    (valueChange)="selectedRolesChange($event)"
                >
                    <mat-option *ngFor="let sysRole of allRoles.systemRoles" [value]="sysRole.id">
                        {{ sysRole.title }}
                    </mat-option>
                    <mat-divider></mat-divider>
                    <mat-option *ngFor="let compRole of allRoles.companyRoles" [value]="compRole.id">
                        {{ compRole.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="d-flex align-items-baseline">
                <button
                    mat-icon-button
                    type="button"
                    class="d-flex justify-content-center mr-1"
                    *ngIf="!isProfileCreation"
                    matTooltip="{{ label }} Permissions Preview"
                    (click)="viewPermissions(label)"
                >
                    <mat-icon class="permissions-icon">flip_to_front</mat-icon>
                </button>
            </div>
        </div>
    `,
    styleUrls: ['./edit-user.component.scss']
})
export class EditUserRolesComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    availableRoles: IRole[] = [];
    selectedRoles: IRole[] = [];

    availableCompanyPermissions: any = {};
    availableRolesPermissions: any = [];
    expandedPermissionPreview: boolean = false;

    @Input() label: string = 'Roles';

    @Input() isProfileCreation: boolean = false;
    @Input() allRoles:
        | {
              systemRoles: IRole[];
              companyRoles: IRole[];
          }
        | undefined;
    @Input() companyPermissions: any;
    @Input() needToMoveHigher: boolean = false;

    @Input() roles_ids: number[] | null = null;

    @Input() rolesIdsFC: UntypedFormControl = new UntypedFormControl([]);

    // for infinite scroll - it does not check changes in FormControl
    @Output() selectionChanged = new EventEmitter();

    constructor(public dialog: MatDialog) {}

    ngOnInit(): void {
        // create FormControl if we transmit array or empty value as array
        if (!this.rolesIdsFC.value || !this.rolesIdsFC.value.length) {
            this.rolesIdsFC = new UntypedFormControl(this.roles_ids);
        }

        if (!this.roles_ids && this.rolesIdsFC.value) {
            this.roles_ids = this.rolesIdsFC.value;
        }

        this.expandedPermissionPreview = this.isProfileCreation;

        if (this.allRoles && this.allRoles.systemRoles) {
            this.availableRoles = this.allRoles.systemRoles.concat(this.allRoles.companyRoles);
            this.selectedRolesChange(this.roles_ids);
            this.allRoles.systemRoles = this.allRoles.systemRoles.filter((role) => role.visible_for_companies);
        }

        if (this.companyPermissions) {
            this.availableCompanyPermissions = this.permissionsCategorise(this.companyPermissions);
        }
    }

    isChecked(permission: {key: any; module: any}) {
        return this.availableRolesPermissions.some(
            (permObj: {key: string}) =>
                permObj.key === permission.key || permObj.key === `${permission.module}____all__`
        );
    }

    permissionsCategorise(permissionsRawArray: {[x: string]: any; hasOwnProperty: (arg0: string) => any}): {
        [key: string]: any;
    } {
        const permObj = {};

        for (const key in permissionsRawArray) {
            if (!permissionsRawArray.hasOwnProperty(key)) {
                continue;
            }

            if (!permObj.hasOwnProperty(permissionsRawArray[key].module)) {
                // @ts-ignore
                permObj[permissionsRawArray[key].module] = [];
            }
            // @ts-ignore
            permObj[permissionsRawArray[key].module].push(permissionsRawArray[key]);
        }

        return permObj;
    }

    viewPermissions(label: string) {
        const dialogRef = this.dialog.open(PermissionPreviewDialogComponent, {
            data: {
                label: label,
                availableRolesPermissions: this.availableRolesPermissions,
                availableCompanyPermissions: this.availableCompanyPermissions
            }
        });

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    selectedRolesChange($event: any) {
        if ($event) {
            this.selectedRoles = [];
            this.availableRolesPermissions = [];
            for (const id of $event) {
                const findRole = this.availableRoles.find((role) => role.id === id);
                if (findRole) {
                    this.selectedRoles.push();
                    if (this.availableRoles.find((role) => role.id === id)) {
                        this.availableRolesPermissions = this.availableRolesPermissions.concat(findRole.permissions);
                    }
                }
            }

            this.selectionChanged.next($event);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.selectionChanged.complete();
    }
}
