import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {GoalFormGroup} from './model';
import {PickableGoalFormGroup} from '../../progress/user-goals/user-goals.component';
import {GenericFormGroup} from '../../../entites/generic.entity';

@Component({
    selector: 'app-goal-general',
    styles: [
        `
            mat-card-header {
                display: block;
                text-align: center;
                font-size: 20px;
                margin: 5%;
                color: #676161;
            }

            button {
                padding-left: 40px;
                padding-right: 40px;
                margin: 20px auto;
            }

            .prefix {
                font-size: 12px;
                margin-right: 4px;
                font-weight: 300;
            }

            button {
                height: 40px;
                margin: 4px;
            }

            mat-form-field {
                width: 100%;
            }
        `
    ],
    template: `
        <div style="display: flex">
            <div style="display: flex; width: 100%">
                <mat-form-field *ngIf="goal && goal.controls.title">
                    <span matPrefix class="prefix" *ngIf="index">#{{ index + 1 }}</span>
                    <input matInput name="title" type="text" [formControl]="$any(goal.controls.title)" />
                </mat-form-field>
            </div>
        </div>
    `
})
export class GoalGeneralComponent implements OnDestroy {
    @Input() goal: GoalFormGroup | PickableGoalFormGroup | GenericFormGroup<any> | undefined = undefined;
    @Input() index: number | null = null;
    @Output() deleteGoal = new EventEmitter();
    @Output() makeProgressGoal = new EventEmitter();

    ngOnDestroy() {
        this.deleteGoal.complete();
        this.makeProgressGoal.complete();
    }
}
