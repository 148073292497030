<div class="container-data dashboard-page" *ngIf="!busy">
    <!------- Filters header ------->
    <div class="pl-3 d-flex align-items-center header-filters" *ngIf="activeTabIndex !== null && !editMode">
        <!------- Button board list ------->
        <h3
            class="mb-0 mr-2 d-flex align-items-end action-icon font-weight-bold"
            style="font-size: 1.3em"
            [matMenuTriggerFor]="menuWidget"
        >
            <span>{{ boardsList[activeTabIndex].name }}</span>
            <span class="material-icons d-flex align-self-center">arrow_drop_down</span>
        </h3>
        <mat-menu #menuWidget="matMenu">
            <ng-container *ngFor="let board of boardsList; let idx = index">
                <button
                    mat-menu-item
                    class="d-flex align-items-center justify-content-between board-item"
                    (click)="board.id === activeTabId ? $event.stopPropagation() : handleTabClick(board.id, idx)"
                    *ngIf="board.id"
                >
                    {{ board.name }}
                    <span
                        class="material-icons ml-3 default-board-icon"
                        [ngStyle]="
                            (defaultBoardIndex | isNumber) && defaultBoardIndex === idx
                                ? {visibility: 'visible', color: '#444'}
                                : null
                        "
                        (click)="$event.stopPropagation(); setBoardAsDefault(board.id)"
                    >
                        grade
                    </span>
                </button>
            </ng-container>
            <button
                mat-menu-item
                style="font-style: italic"
                [disabled]="isExtraBoardsAllowed"
                (rbacIsAllowed)="isExtraBoardsAllowed = !$event"
                rbac
                [rule]="{create_extra_dealboards: true}"
                (click)="goToNewBoardTab()"
            >
                Create new dashboard +
            </button>
        </mat-menu>
        <!------- Button edit board ------->
        <button
            mat-flat-button
            color="primary"
            class="edit-icon"
            *ngIf="!editMode && boardsList.length && boardsList.length !== activeTabIndex"
            (click)="editMode = true"
        >
            Edit Dashboard
        </button>
    </div>

    <!------- Board with widgets ------->
    <div class="board-tabs">
        <!------- Show Board ------->
        <app-board
            *ngIf="activeTabIndex !== null"
            [board]="boardsList[activeTabIndex]"
            [editMode]="editMode"
            (deleteBoard)="onDeleteBoard($event)"
            (closeEditingEmitter)="onCloseBoard()"
        ></app-board>

        <!------- Create Board ------->
        <app-add-board
            *ngIf="activeTabIndex === null"
            (boardCreated)="onBoardCreated($event)"
            (boardClosed)="onCloseBoard()"
        ></app-add-board>
    </div>
</div>
