import {Component, ElementRef, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild} from '@angular/core';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {CompensationServiceApi} from '../../../../../account-info/compensation/compensation.service';
import * as _ from 'lodash-es';
import {UsersAndLicencesApiService} from '../../../../../account-info/users-licenses/users-and-licences.api.service';
import {SessionService} from '../../../../../../services/session.service';

@Component({
    selector: 'app-widget-compensation-profile-picker',
    templateUrl: './compensation-profile-picker.component.html',
    styleUrls: ['./compensation-profile-picker.component.scss']
})
export class CompensationProfilePickerComponent implements OnInit, OnDestroy {
    @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete | undefined;

    @Input()
    set value(val: any) {
        if (Array.isArray(val)) {
            this._value = val;
        } else if (val) {
            this._value = [val];
        } else {
            this._value = [];
        }
    }

    get value() {
        return this._value;
    }

    @Input() title: string = '';
    @Input() singleSelected: boolean = false;

    @Input()
    set profileEntity(data: any) {
        if (Array.isArray(data)) {
            this._profileEntity = data[0];
        } else {
            this._profileEntity = data;
        }
        this.updateCompensationProfilesBasedOnProfileEntity();
    }

    get profileEntity() {
        return this._profileEntity;
    }

    @Output() valueChanges = new EventEmitter();
    @ViewChild('input', {static: false}) input: ElementRef<HTMLInputElement> | undefined;
    _value: Array<string | number> = [];
    initialList: any[] = [];
    list: any[] = [];
    private _profileEntity: {label: string; target_id: number; type: string} | null = null;
    private _availableCompensationProfiles: any[] = [];
    constructor(
        protected compensationServiceApi: CompensationServiceApi,
        protected usersAndLicencesApiService: UsersAndLicencesApiService,
        public sessionService: SessionService
    ) {}

    /**
     * On init
     */
    async ngOnInit() {
        this.compensationServiceApi.getCompensationProfiles().then((response: any[]) => {
            // we will allow to pick only elements which have tiers
            const availableCompensationProfiles = response.filter((compensationProfile) => {
                if (compensationProfile.financial_elements && compensationProfile.financial_elements.length) {
                    const availableElements = compensationProfile.financial_elements.filter((fin_element: any) => {
                        return ['less_then', 'less_then_or_equal'].includes(fin_element.commonRule.operator);
                    });
                    return availableElements.length;
                } else {
                    return false;
                }
            });
            this._availableCompensationProfiles = availableCompensationProfiles;
            this.updateCompensationProfilesBasedOnProfileEntity();
        });
    }

    /**
     * Search
     * @param event
     */
    search(event: Event) {
        const list: any[] = _.cloneDeep(this.initialList);
        this.list = list.filter((group) => {
            if (group.financial_elements && group.financial_elements.length) {
                group.financial_elements = group.financial_elements.filter((item: any) => {
                    const text: string = (<HTMLInputElement>event.target).value;
                    return item.title.toLowerCase().includes(String(text).toLowerCase());
                });
                return group.financial_elements.length;
            } else {
                return false;
            }
        });
    }

    /**
     * Select
     * @param event
     */
    select(event: MatAutocompleteSelectedEvent) {
        if (!this.value.includes(event.option.value)) {
            const value: any = [event.option.value];
            this.valueChanges.emit([].concat(this.value, value));
        }
        this.list = this.removeItemFromList([event.option.value]);
        this.clearInput();
    }

    /**
     * Remove
     */
    remove(id: number) {
        const values = this.value.filter((item: number) => item !== id);
        this.valueChanges.emit(values);

        this.list = this.initialList;
        this.list = this.removeItemFromList(values);
        this.clearInput();
    }

    /**
     * Clear input
     */
    clearInput() {
        if (this.input) {
            this.input.nativeElement.value = '';
            this.input.nativeElement.blur();
        }
    }

    /**
     * Remove item from list
     * @param ids
     */
    removeItemFromList(ids: any[]) {
        return this.list.filter((group) => {
            return group.financial_elements && group.financial_elements.length
                ? group.financial_elements.filter((item: any) => !ids.includes(item.id)).length
                : [];
        });
    }

    /**
     * Check and prevent input
     * @param event
     */
    checkAndPreventInput(event: Event) {
        if (this.singleSelected && this.value && this.value.length) {
            event.stopPropagation();
            return false;
        }
    }

    /**
     * Update available compensation profiles based on profile entity
     */
    updateCompensationProfilesBasedOnProfileEntity() {
        if (this.profileEntity) {
            Promise.resolve(true)
                .then(() => {
                    // get compensation profile if profile includes in some groups
                    if (this.profileEntity.type === 'individual') {
                        return this.usersAndLicencesApiService
                            .getUpdatedProfile(this.profileEntity.target_id)
                            .then((response) => {
                                const result: {[key: string]: any} = {};
                                let groupsIdsToCheck: number[] = [];

                                if (response.company_group && response.company_group.length) {
                                    groupsIdsToCheck = groupsIdsToCheck.concat(
                                        response.company_group.map((cg: any) => cg.company_group_id)
                                    );
                                }

                                if (this.sessionService.profile?.company?.company_group?.id) {
                                    groupsIdsToCheck.push(this.sessionService.profile.company.company_group.id);
                                }

                                groupsIdsToCheck.forEach((groupId: number) => {
                                    const availableCompensationProfiles = this._availableCompensationProfiles.filter(
                                        (compensationProfile) => {
                                            return compensationProfile.apply_to.find((item: any) => {
                                                return (
                                                    item.type === 'individuals_in_group' && item.target_id === groupId
                                                );
                                            });
                                        }
                                    );

                                    if (availableCompensationProfiles.length) {
                                        availableCompensationProfiles.forEach((compensationProfile) => {
                                            if (!result[compensationProfile.id]) {
                                                result[compensationProfile.id] = compensationProfile;
                                            }
                                        });
                                    }
                                });
                                return result;
                            });
                    } else {
                        return {};
                    }
                })
                .then((userInGroupCompensationProfiles: {[key: string]: any}) => {
                    // get compensation profile when profile included directly to compensation profile
                    const availableCompensationProfiles = this._availableCompensationProfiles.filter(
                        (compensationProfile) => {
                            return compensationProfile.apply_to.find((item: any) => {
                                return (
                                    item.target_id === this.profileEntity.target_id &&
                                    item.type === this.profileEntity.type
                                );
                            });
                        }
                    );
                    if (availableCompensationProfiles.length) {
                        availableCompensationProfiles.forEach((compensationProfile) => {
                            if (!userInGroupCompensationProfiles[compensationProfile.id]) {
                                userInGroupCompensationProfiles[compensationProfile.id] = compensationProfile;
                            }
                        });
                    }
                    const result = Object.values(userInGroupCompensationProfiles);

                    this.list = result;
                    this.initialList = result;
                });
        } else {
            this.list = [];
            this.initialList = [];
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.valueChanges.complete();
    }
}
