import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {RecurringBillsService} from 'app/services/api/finance/recurring-bills.service';
import {IRecurringBill} from '@cyberco-nodejs/zipi-typings';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {SelectionModel} from '@angular/cdk/collections';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {IScrollData} from 'app/models/scroll-data';
import {PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
    selector: 'app-recurring-bill-list',
    templateUrl: './recurring-bill-list.component.html',
    styleUrls: ['./recurring-bill-list.component.scss', '../../../../../../assets/infinite-scroll-table.scss']
})
export class RecurringBillListComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBills: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;

    scrollData: IScrollData = {
        sort_column: 'last_bill_date',
        sort_direction: 'desc',
        limit: 50,
        offset: 0,
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<IRecurringBill>;
    displayedColumns = [
        'checkbox',
        'title',
        'vendor_name',
        'frequency',
        'last_bill_date',
        'next_bill_date',
        'total_amount',
        'action'
    ];

    selection: SelectionModel<number> = new SelectionModel<number>(true, []);
    isSelectedForBulk: boolean;

    constructor(
        private recurringBillsService: RecurringBillsService,
        public dialog: MatDialog
    ) {
        this.isSelectedForBulk = false;
        this.dataSource = new MatTableDataSource<IRecurringBill>([]);
    }

    ngOnInit() {
        this.loadRecurringBills();

        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.loadRecurringBills();
            });
        }
    }

    loadRecurringBills() {
        this.unsubscribeBills.next();

        this.recurringBillsService
            .getRecurringBills(this.scrollData)
            .pipe(takeUntil(this.unsubscribeBills))
            .subscribe((data) => {
                this.dataSource.data = data.result;
                this.scrollData.total = data._meta.total;
            });
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        // update values
        this.paginator!.pageIndex = 0;
        this.scrollData.offset = 0;
        this.loadRecurringBills();
    }

    toggleBulkCheckbox($event: MatCheckboxChange, id: number) {
        if ($event) {
            this.selection.toggle(id as never); // dirty hack
        }
        this.isSelectedForBulk = this.selection.selected.length !== 0;
    }

    bulkDelete() {
        const recurringIdsForBulk = this.selection.selected;
        if (recurringIdsForBulk.length > 0) {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                minWidth: 320,
                data: {
                    title: 'Deleting Recurring Bills',
                    message: `${recurringIdsForBulk.length} Recurring Bill(s) will be deleted`
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((ok) => {
                    if (ok) {
                        this.recurringBillsService
                            .bulkDeleteRecurringBills(recurringIdsForBulk)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((res) => {
                                this.selection.clear();
                                this.isSelectedForBulk = false;

                                // update values
                                this.paginator!.pageIndex = 0;
                                this.scrollData.offset = 0;
                                this.scrollData.total = 0;
                                this.ngOnInit();
                            });
                    }
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBills.next();
        this.unsubscribeBills.complete();
    }
}
