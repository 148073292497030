import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-addon-deactivate-confirmation-dialog',
    styles: [
        `
            .mat-dialog-actions {
                justify-content: space-between;
                display: flex;
            }

            #errors {
                color: #af1515;
                padding-left: 15px;
                line-height: 2em;
                font-size: 14px;
            }
        `
    ],
    template: `
        <div mat-dialog-title>
            <strong>{{ title }}</strong>
        </div>
        <div mat-dialog-content>
            <p>{{ description }}</p>
            <p><strong>Note:</strong> {{ note }}</p>
            <hr />
        </div>

        <div mat-dialog-actions class="justify-content-end">
            <button mat-button (click)="dialogRef.close(false)">Cancel</button>
            <button mat-raised-button color="warn" class="mat-primary" (click)="dialogRef.close(true)">
                Disconnect
            </button>
        </div>
    `
})
export class AddonDeactivateConfirmationDialogComponent implements OnInit {
    public title = 'Disconnect App';
    public description = 'It will disconnect this App for you.';
    public note = 'No data will be deleted, but all configurations will be lost and nothing further will be synced.';

    constructor(
        public dialogRef: MatDialogRef<AddonDeactivateConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        if (this.data) {
            if (this.data.title) {
                this.title = this.data.title;
            }
            if (this.data.description) {
                this.description = this.data.description;
            }
            if (this.data.note) {
                this.note = this.data.note;
            }
        }
    }
}
