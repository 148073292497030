import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from '../../../../../services/auth.service';
import {Router} from '@angular/router';
import {SessionService} from '../../../../../services/session.service';
import {UserService} from '../../../../../services/user.service';
import {LogoutAndGoToInvoiceAction} from '../../../../contact-invites/store/contact-invites.actions';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {IContactInvitesState} from '../../../../contact-invites/store/contact-invites.reducers';
import {ICompany, IProfile} from '@cyberco-nodejs/zipi-typings/companies.typings';
import {IContact, IInvoice} from '@cyberco-nodejs/zipi-typings';
import {InvoicePublicService} from '../../../services/invoice-public.service';
import {RbacService} from '../../../../rbac/rbac.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-enter-to-portal',
    templateUrl: './enter-to-portal.component.html',
    styleUrls: ['./enter-to-portal.component.scss']
})
export class EnterToPortalComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    invoiceEmailCompanies: ICompany[] = [];
    loggedUserCompanies: ICompany[] = [];

    loggedProfile: IProfile | undefined;
    currentContact: IContact | undefined;

    invoice: IInvoice | undefined;

    existingConnectedContact: {[key: number]: number} = {};
    public userFinancialCompanyId: boolean | number = false;

    constructor(
        protected authService: AuthService,
        private router: Router,
        public sessionService: SessionService,
        protected usersService: UserService,
        protected store: Store<IContactInvitesState>,
        public dialogRef: MatDialogRef<EnterToPortalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private invoicePublicService: InvoicePublicService,
        protected rbacService: RbacService,
        protected userService: UserService
    ) {}

    ngOnInit() {
        this.invoiceEmailCompanies = this.data.companies;
        this.loggedUserCompanies = this.data.loggedCompanies;
        this.loggedProfile = this.data.currentProfile;
        this.currentContact = this.data.currentContact;
        this.invoice = this.data.invoice;
        if (this.loggedUserCompanies && this.loggedUserCompanies.length > 0) {
            if (this.invoice && this.invoice.invoice_id) {
                this.invoicePublicService
                    .isAbleToAssociate(
                        this.invoice.invoice_id,
                        this.loggedUserCompanies.map((company) => company.company_id!)
                    )
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((result) => {
                        this.existingConnectedContact = result;
                    });
            }
        }

        if (this.invoiceEmailCompanies.length) {
            // get user id
            let userId: boolean | number = false;
            this.invoiceEmailCompanies.forEach((company: ICompany) => {
                if (company.profiles) {
                    company.profiles.forEach((profile: IProfile) => {
                        if (profile.user_fk_id) {
                            userId = profile.user_fk_id;
                        }
                    });
                }
            });
            // set userFinancialCompanyId
            if (userId) {
                this.invoiceEmailCompanies.forEach((company: ICompany) => {
                    if (company.company_id && company.finance_for__user_fk_id === userId) {
                        this.userFinancialCompanyId = company.company_id;
                    }
                });
            }
        }
    }

    canConnectToOtherAccounts(profile: IProfile, company: ICompany) {
        return this.rbacService.checkPermissions(profile.permissions, {
            company_settings__manage_connections_to_other_accounts: true
        });
    }

    isUserFinancialCompany(company: ICompany) {
        return this.userFinancialCompanyId === company.company_id;
    }

    isUserCanAssociateInvoiceToCompany(company: ICompany, invoice: IInvoice) {
        const profile = _.first(company.profiles);
        if (
            company &&
            company.id !== invoice.owner__company_fk_id &&
            profile &&
            (this.canConnectToOtherAccounts(profile, company) || this.isUserFinancialCompany(company))
        ) {
            return true;
        }
        return false;
    }

    async loginAssociate(company: ICompany) {
        if (!company || !company.company_id) {
            return null;
        }
        await this.authService.logout();
        this.sessionService.setRedirectParams(
            [`/viewinvoice/${this.data.invoiceHash}`],
            this.data.invoice.money_sender_email,
            ''
        );
        localStorage.setItem('desired_company_id', company.company_id + '');
        this.router.navigate([`auth/sign-in-contact-invoice`]);
        this.close();
    }

    registration() {
        this.logoutAndGoTo(
            ['auth/sign-up-contact-invoice', this.data.invoice.invoice_hash],
            'Register using this email to continue accepting invitation'
        );
        this.close();
    }

    associate(company: ICompany) {
        this.usersService
            .afterRegisterContactInvoice(this.data.invoiceHash, this.data.currentProfile.user, company)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                localStorage.setItem('pw_sidenav_state', 'opened');
                if (this.data.invoice.connected__bill_fk_id) {
                    window.location.replace(`/purchases/bills/${this.data.invoice.connected__bill_fk_id}`);
                } else {
                    window.location.replace(`/purchases/sourcedocuments/approve/${this.data.invoice.invoice_id}`);
                }
                this.close();
            });
    }

    switchToCompanyAndNavigate(targetCompany: ICompany) {
        const targetProfile = _.first(targetCompany.profiles);
        if (!targetProfile || !targetProfile.profile_id) return;

        localStorage.setItem('current_profile_id', targetProfile.profile_id.toString());
        localStorage.setItem('zipi_last_profile_id', targetProfile.profile_id.toString());
        if (this.data.invoice.connected__bill_fk_id) {
            window.location.replace(`/purchases/bills/${this.data.invoice.connected__bill_fk_id}`);
        } else {
            window.location.replace(`/purchases/sourcedocuments/approve/${this.data.invoice.invoice_id}`);
        }
        this.close();
    }

    async logoutAndGoTo(goTo: string[], authInfoMessage?: string) {
        return this.store.dispatch(new LogoutAndGoToInvoiceAction({goTo, authInfoMessage}));
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    /*
    Probably it's legacy and need to remove
     */
    async login(company: ICompany) {
        await this.authService.logout();

        let profileId = '';
        if (company.profiles && company.profiles[0] && company.profiles[0].profile_id) {
            profileId = String(company.profiles[0].profile_id);
        }
        localStorage.setItem('zipi_actual_profile_id', profileId);

        if (this.data.invoice.connected__bill_fk_id) {
            this.sessionService.setRedirectParams(
                [`/purchases/bills/${this.data.invoice.connected__bill_fk_id}`],
                this.data.invoice.money_sender_email,
                ''
            );
        } else {
            this.sessionService.setRedirectParams(
                [`/purchases/sourcedocuments/approve/${this.data.invoice.invoice_id}`],
                this.data.invoice.money_sender_email,
                ''
            );
        }

        this.router.navigate([`auth/sign-in-contact-invoice`]);
        this.close();
    }

    /*
    Probably it's legacy and need to remove
     */
    justLogin() {
        this.sessionService.setRedirectParams([`/viewinvoice/${this.data.invoiceHash}`], '');
        this.router.navigate([`auth/sign-in`]);
        this.close();
    }
}
