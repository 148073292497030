import {Routes, RouterModule} from '@angular/router';
import {ReportLayoutComponent} from './components/report-layout/report-layout.component';
import {ReportsPageComponent} from './components/reports-page/reports-page.component';
import {UserLayoutComponent} from '../../layouts/user-layout.component';
import {AuthBootstrapService} from '../../services/resolvers/auth-bootstrap.service';
import {ReportCategoryComponent} from './components/report-category/report-category.component';
import {RecommendedComponent} from './components/recommended/recommended.component';
import {ReportsPreviewComponent} from './components/reports-preview/reports-preview.component';
import {AccessGuardService} from '../../services/resolvers/access-guard.service';

export const routes: Routes = [
    {
        path: 'reports',
        component: UserLayoutComponent,
        resolve: {authBootstrap: AuthBootstrapService},
        data: {
            title: 'Reports',
            access: {rule: {reports__access: true}}
        },
        canActivate: [AccessGuardService],
        children: [
            {
                path: '',
                component: ReportLayoutComponent,
                children: [
                    {path: '', component: ReportsPageComponent},
                    {path: 'preview', component: ReportsPreviewComponent},
                    {path: 'preview/:id', component: ReportsPreviewComponent},
                    {path: 'categories/:type', component: ReportCategoryComponent},
                    {path: 'recommended', component: RecommendedComponent}
                ]
            }
        ]
    }
];

export const routing = RouterModule.forChild(routes);
