<div>
    <mat-card>
        <div class="report-table w-100 font-weight-bold">
            <div class="flex-row no-margin sticky">
                <div class="item-name">
                    <button
                        mat-icon-button
                        class="header-actions"
                        (click)="toggleExpand()"
                        [matTooltip]="expandTooltip"
                    >
                        <mat-icon>{{ isExpand ? 'unfold_less' : 'unfold_more' }}</mat-icon>
                    </button>
                    <span>Account</span>
                </div>
                <div class="item-value">Code</div>
                <div class="item-value">Total</div>
            </div>

            <p class="header-group">ASSETS</p>
            <ng-container *ngIf="reportData.asset.length">
                <mat-tree [dataSource]="assetDataSource" [treeControl]="treeControlAsset" class="tree">
                    <mat-tree-node
                        *matTreeNodeDef="let node"
                        matTreeNodePadding
                        [ngClass]="{'profit-row': node.type === 'profit'}"
                    >
                        <li class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div
                                class="flex-row item w-100"
                                [ngClass]="{
                                    'font-weight-bold': node.type === 'total',
                                    'profit-row': node.type === 'profit'
                                }"
                            >
                                <div class="item-name">{{ node.la_name }}</div>
                                <div class="item-value">{{ node.la_code }}</div>
                                <div
                                    class="item-value total-row amount-text"
                                    [ngClass]="{'negative-value': node.total < 0}"
                                >
                                    <span>{{ node.total | reportFormatted: 'financial' }}</span>
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.la_name"
                                    (click)="changeState(node)"
                                >
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{ treeControlAsset.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                    </mat-icon>
                                </button>

                                <div class="item w-100 flex-row">
                                    <div class="item-name">
                                        {{ node.la_name }}
                                    </div>
                                    <div class="item-value">{{ node.la_code }}</div>
                                    <div
                                        class="item-value amount-text"
                                        [ngClass]="{
                                            'negative-value': treeControlAsset.isExpanded(node)
                                                ? node.total < 0
                                                : node.total_with_children < 0
                                        }"
                                    >
                                        <span>
                                            {{
                                                (treeControlAsset.isExpanded(node)
                                                    ? node.total
                                                    : node.total_with_children
                                                ) | reportFormatted: 'financial'
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <ul [class.tree-invisible]="!treeControlAsset.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
            </ng-container>

            <div class="flex-row font-weight-bold profit">
                <div class="item-name"></div>
                <div class="item-value">Total Assets</div>
                <div
                    class="item-value profit-item amount-text"
                    [ngClass]="{'negative-value': reportData.totals.asset < 0}"
                >
                    <span>{{ reportData.totals.asset | reportFormatted: 'financial' }}</span>
                </div>
            </div>

            <p class="header-group">LIABILITIES & EQUITIES</p>
            <ng-container *ngIf="reportData.liability.length">
                <p class="header-group">Liabilities</p>

                <mat-tree [dataSource]="liabilityDataSource" [treeControl]="treeControlLiability" class="tree">
                    <mat-tree-node
                        *matTreeNodeDef="let node"
                        matTreeNodePadding
                        [ngClass]="{'profit-row': node.type === 'profit'}"
                    >
                        <li class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div
                                class="flex-row item w-100"
                                [ngClass]="{
                                    'font-weight-bold': node.type === 'total',
                                    'profit-row': node.type === 'profit'
                                }"
                            >
                                <div class="item-name">{{ node.la_name }}</div>
                                <div class="item-value">{{ node.la_code }}</div>
                                <div
                                    class="item-value total-row amount-text"
                                    [ngClass]="{'negative-value': node.total < 0}"
                                >
                                    <span>{{ node.total | reportFormatted: 'financial' }}</span>
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.la_name"
                                    (click)="changeState(node)"
                                >
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{ treeControlLiability.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                    </mat-icon>
                                </button>

                                <div class="item w-100 flex-row">
                                    <div class="item-name">
                                        {{ node.la_name }}
                                    </div>
                                    <div class="item-value">{{ node.la_code }}</div>

                                    <div
                                        class="item-value amount-text"
                                        [ngClass]="{
                                            'negative-value': treeControlLiability.isExpanded(node)
                                                ? node.total < 0
                                                : node.total_with_children < 0
                                        }"
                                    >
                                        <span>
                                            {{
                                                (treeControlLiability.isExpanded(node)
                                                    ? node.total
                                                    : node.total_with_children
                                                ) | reportFormatted: 'financial'
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <ul [class.tree-invisible]="!treeControlLiability.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
            </ng-container>
            <div class="flex-row font-weight-bold profit">
                <div class="item-name"></div>
                <div class="item-value">Total Liabilities</div>
                <div
                    class="item-value profit-item amount-text"
                    [ngClass]="{'negative-value': reportData.totals.liability < 0}"
                >
                    <span>
                        {{ reportData.totals.liability | reportFormatted: 'financial' }}
                    </span>
                </div>
            </div>

            <ng-container *ngIf="reportData.equity.length">
                <p class="header-group">Equities</p>

                <mat-tree [dataSource]="equityDataSource" [treeControl]="treeControlEquity" class="tree">
                    <mat-tree-node
                        *matTreeNodeDef="let node"
                        matTreeNodePadding
                        [ngClass]="{'profit-row': node.type === 'profit'}"
                    >
                        <li class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div
                                class="flex-row item w-100"
                                [ngClass]="{
                                    'font-weight-bold': node.type === 'total',
                                    'profit-row': node.type === 'profit'
                                }"
                            >
                                <div class="item-name flex-row">
                                    {{ node.la_name }}
                                    <span
                                        *ngIf="node.icon"
                                        class="material-icons-outlined icon-info"
                                        [matTooltip]="node.tooltip"
                                    >
                                        {{ node.icon }}
                                    </span>
                                </div>
                                <div class="item-value">{{ node.la_code }}</div>
                                <div
                                    class="item-value total-row amount-text"
                                    [ngClass]="{'negative-value': node.total < 0}"
                                >
                                    <span>{{ node.total | reportFormatted: 'financial' }}</span>
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.la_name"
                                    (click)="changeState(node)"
                                >
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{ treeControlEquity.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                    </mat-icon>
                                </button>

                                <div class="item w-100 flex-row">
                                    <div class="item-name">
                                        {{ node.la_name }}
                                    </div>
                                    <div class="item-value">{{ node.la_code }}</div>
                                    <div
                                        class="item-value amount-text"
                                        [ngClass]="{
                                            'negative-value': treeControlEquity.isExpanded(node)
                                                ? node.total < 0
                                                : node.total_with_children < 0
                                        }"
                                    >
                                        <span>
                                            {{
                                                (treeControlEquity.isExpanded(node)
                                                    ? node.total
                                                    : node.total_with_children
                                                ) | reportFormatted: 'financial'
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <ul [class.tree-invisible]="!treeControlEquity.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
                <div class="flex-row font-weight-bold profit">
                    <div class="item-name"></div>
                    <div class="item-value">Total Equities</div>
                    <div
                        class="item-value profit-item amount-text"
                        [ngClass]="{'negative-value': reportData.totals.equity < 0}"
                    >
                        <span>
                            {{ reportData.totals.equity | reportFormatted: 'financial' }}
                        </span>
                    </div>
                </div>
            </ng-container>

            <div class="flex-row font-weight-bold profit">
                <div class="item-name"></div>
                <div class="item-value-big">Total Liabilities & Equities</div>
                <div
                    class="item-value profit-item amount-text"
                    [ngClass]="{'negative-value': reportData.totals.liability_and_equity < 0}"
                >
                    <span>
                        {{ reportData.totals.liability_and_equity | reportFormatted: 'financial' }}
                    </span>
                </div>
            </div>
        </div>

        <p class="m-2">*Amount is displayed in your base currency <span class="badge-secondary">USD</span></p>
        <p class="m-2" *ngIf="report && report.settings && report.settings.report_basis === 'cash'">
            **Financial Data Presented is on a Cash Basis
        </p>
    </mat-card>
</div>
