<section class="bg-white" #releaseRef>
    <!------- Header ------->
    <header class="d-flex align-items-center px-3 list-header">
        <h3 class="m-0 d-flex align-items-end">Deposit Releases</h3>

        <div style="width: 180px" class="mx-2 mt-2">
            <app-company-compensation-combined-picker
                style="width: 100%"
                [availableTypes]="['contact']"
                [title]="'Receiver'"
                [only_compensation_groups]="false"
                [singleSelected]="true"
                [bold_style]="false"
                [nodesFA]="contactCtrlArr"
                [floatLabel]="'never'"
            ></app-company-compensation-combined-picker>
        </div>
        <i class="sp-spacer"></i>

        <!--        <mat-form-field class="mt-2 ml-2" floatLabel='never'>-->
        <!--            <mat-label>Status filter</mat-label>-->
        <!--            <mat-select [formControl]="statusFilter" multiple>-->
        <!--                <mat-option *ngFor="let status of statusTypes" [value]="status" (onSelectionChange)="changeStatusFilter($event)">-->
        <!--                    {{status | fromSnakeCase}}-->
        <!--                </mat-option>-->
        <!--            </mat-select>-->
        <!--        </mat-form-field>-->
    </header>

    <!------- List of requests ------->
    <div class="table-background">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Type</th>
                <td mat-cell *matCellDef="let element" class="w-10">
                    {{ element.type | fromSnakeCase }}
                </td>
            </ng-container>

            <ng-container matColumnDef="applied_to">
                <th mat-header-cell *matHeaderCellDef class="w-10">Applied To</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <span *ngIf="element.rpm__payment_made_id && element.rpm__is_locked_for_applying">{{
                        element.deal_name
                    }}</span>
                    <span
                        *ngIf="
                            element.rpm__payment_made_id &&
                            !element.rpm__is_locked_for_applying &&
                            (!element.rpm__related_payments || element.rpm__related_payments.length === 0)
                        "
                        >Unapplied</span
                    >
                    <span
                        *ngIf="
                            element.rpm__payment_made_id &&
                            !element.rpm__is_locked_for_applying &&
                            element.rpm__related_payments &&
                            element.rpm__related_payments.length > 0
                        "
                    >
                        <span *ngFor="let payment of element.rpm__related_payments">
                            <a *ngIf="payment.bill_id" routerLink="/purchases/bills/{{ payment.bill_id }}"
                                >{{ payment.bill_number }}
                            </a>
                        </span>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="release_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Release Date</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.release_date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="deal_address">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20">Deal Address</th>
                <td mat-cell *matCellDef="let element" class="w-20 px-1">
                    <a [routerLink]="['/deals', element.deal_fk_id]" *ngIf="element.type === 'outgoing'">
                        {{ element.deal_address }}
                    </a>
                    <span *ngIf="element.type !== 'outgoing'">{{ element.deal_address }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="contact_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">Contact</th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1">
                    <span *ngIf="element.type === 'outgoing'">{{ element.receiver_contact_name }}</span>
                    <span *ngIf="element.type === 'incoming'">{{
                        element.sender_contact_name || element.sender_company_title
                    }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="payment_status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Payment Status</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <span [ngStyle]="{color: statusColor[element.payment_status]}">
                        {{ element.payment_status | fromSnakeCase }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="rpm__payment_made_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Payment Number</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <span *ngIf="!element.rpm__payment_made_id || !element.rpm__payment_made_number">'N/A'</span>
                    <a
                        [routerLink]="['/purchases/payments', element.rpm__payment_made_id]"
                        *ngIf="element.rpm__payment_made_id && element.rpm__payment_made_number"
                    >
                        {{ element.rpm__payment_made_number }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="release_amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Amount</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1 text-right">
                    {{ element.release_amount | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="w-5">Action</th>
                <td mat-cell *matCellDef="let element" class="w-5">
                    <button mat-icon-button [matMenuTriggerFor]="appMenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #appMenu="matMenu">
                        <button mat-menu-item (click)="openConfirm(element)">Delete Release</button>
                        <button
                            mat-menu-item
                            *ngIf="
                                element.rpm__payment_made_id &&
                                element.rpm__check_info &&
                                element.rpm__check_info.print_status === 'not_printed'
                            "
                            (click)="printPaymentPdf(element)"
                        >
                            Print Check
                        </button>
                        <button
                            mat-menu-item
                            *ngIf="element.rpm__payment_made_id && element.rpm__is_locked_for_applying"
                            (click)="unlockReleasePayment(element)"
                        >
                            Unapply
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="zp-table-row"></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted p-3 text-center">
            There are no deposit releases.
        </div>
    </div>
</section>
