import {Component, HostListener, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'app-unlock-override',
    styles: [
        `
            .locked_icon:not(:empty) + .default_locked_icon {
                display: none;
            }

            .unlocked_icon:not(:empty) + .default_unlocked_icon {
                display: none;
            }
        `
    ],
    template: `
        <div class="w-100 d-flex">
            <button
                mat-icon-button
                style="margin-top: 8px"
                color="primary"
                (click)="changeLock()"
                [disabled]="disabled"
            >
                <ng-container *ngIf="isOverrideLock">
                    <div class="locked_icon"><ng-content select="[unlock-locked-icon]"></ng-content></div>
                    <div class="default_locked_icon">
                        <mat-icon>{{ lockIcon }}</mat-icon>
                    </div>
                </ng-container>
                <ng-container *ngIf="!isOverrideLock">
                    <div class="unlocked_icon"><ng-content select="[unlock-unlocked-icon]"></ng-content></div>
                    <div class="default_unlocked_icon">
                        <mat-icon>{{ unlockIcon }}</mat-icon>
                    </div>
                </ng-container>
            </button>
            <ng-container *ngIf="isOverrideLock">
                <ng-content select="[unlock-source]"></ng-content>
            </ng-container>
            <ng-container *ngIf="!isOverrideLock">
                <ng-content select="[unlock-override]"></ng-content>
            </ng-container>
        </div>
    `
})
export class UnlockOverrideComponent implements OnInit {
    @HostListener('click', ['$event']) onClick(event: Event) {
        if (event) {
            event.stopPropagation();
        }
    }

    @HostListener('keydown', ['$event']) onKeyDown(event: Event) {
        if (event) {
            event.stopPropagation();
        }
    }
    _isOverrideLock: boolean = true;
    @Input() set isOverrideLock(isLock) {
        this.changeLock(isLock);
    }

    get isOverrideLock(): boolean {
        return this._isOverrideLock;
    }

    @Input() override: UntypedFormControl = new UntypedFormControl();
    @Input() source: UntypedFormControl = new UntypedFormControl();

    @Input() lockIcon: string = 'lock';
    @Input() unlockIcon: string = 'lock_open';
    @Input() disabled: boolean = false;

    changeLock(lock: boolean | null = null) {
        if (typeof lock === 'boolean') {
            if (lock) {
                this._isOverrideLock = true;
                this.override.patchValue(null, {emitEvent: false});
            } else {
                this._isOverrideLock = false;
                this.override.patchValue(this.source.value, {emitEvent: false});
            }
        } else {
            if (!this._isOverrideLock) {
                this._isOverrideLock = true;
                this.override.patchValue(null);
            } else {
                this._isOverrideLock = false;
                this.override.patchValue(this.source.value);
            }
        }
    }

    constructor(protected dialog: MatDialog) {}

    ngOnInit(): void {
        setTimeout(() => this.source.disable({emitEvent: false}));
    }
}
