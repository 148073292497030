import {Component, Inject, Input, OnDestroy} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-edit-title',
    template: `
        <button
            mat-icon-button
            class="button"
            (click)="openPopup($event)"
            [disabled]="disabled"
            [matTooltip]="
                titleFormControl.value
                    ? 'This title has limited visibility to ONLY those with access/manage compensation profile permissions.'
                    : 'This title is visible to ALL Assigned Users (ie Selecting Cap Widget Parameters).'
            "
        >
            <mat-icon class="icon" fontSet="material-icons-outlined" *ngIf="titleFormControl.value"
                >visibility_off</mat-icon
            >
            <mat-icon class="icon" *ngIf="!titleFormControl.value">visibility</mat-icon>
        </button>
    `,
    styles: [
        `
            :host {
                position: absolute;
                right: 5px;
                top: 5px;
            }

            .button {
                width: 24px;
                height: 24px;
                line-height: 1px;
            }

            .icon {
                width: 24px;
                height: 24px;
                font-size: 24px;
            }
        `
    ]
})
export class EditTitleComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() titleFormControl: UntypedFormControl = new UntypedFormControl(null);
    @Input() disabled: boolean = false;

    constructor(public dialog: MatDialog) {}

    openPopup(event: Event) {
        event.stopPropagation();
        const dialogRef = this.dialog.open(EditTitlePopupComponent, {
            minWidth: 320,
            maxWidth: 600,
            data: {
                currentAlternativeTitle: this.titleFormControl.value
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result !== false) {
                    if (typeof result === 'string' && !result.length) {
                        this.titleFormControl.patchValue(null);
                    } else {
                        this.titleFormControl.patchValue(result);
                    }
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

@Component({
    selector: 'app-edit-title-popup',
    template: `
        <h5 mat-dialog-title>Public Title</h5>
        <mat-dialog-content>
            <div class="d-flex align-items-center">
                <mat-form-field
                    class="w-100 bold-input-placeholder"
                    style="padding-right: 4px; width: 45%; min-width: 150px;"
                    floatLabel="always"
                >
                    <input matInput [formControl]="titleFormControl" />
                </mat-form-field>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions class="mb-2">
            <div class="d-flex justify-content-end w-100">
                <button mat-raised-button class="mr-2" (click)="close(false)" tabindex="-1">Cancel</button>
                <button mat-raised-button color="primary" (click)="close(true)" tabindex="1">Apply</button>
            </div>
        </mat-dialog-actions>
    `
})
export class EditTitlePopupComponent {
    titleFormControl: UntypedFormControl = new UntypedFormControl(null);

    constructor(
        public dialogRef: MatDialogRef<EditTitlePopupComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            currentAlternativeTitle: string;
        }
    ) {
        this.titleFormControl.patchValue(data.currentAlternativeTitle, {emitEvent: false});
    }

    close(apply: boolean) {
        if (!apply) {
            this.dialogRef.close(false);
        }
        this.dialogRef.close(this.titleFormControl.value);
    }
}
