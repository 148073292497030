import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FetchProducts} from '../../../../finance/store/finance.actions';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {IFinanceState} from '../../../../finance/store/finance.reducer';
import {NotificationsService} from 'angular2-notifications';
import {ILedgerAccount, IPaymentGateway, ITransactionExternal, LedgerAccountTypes} from '@cyberco-nodejs/zipi-typings';
import {takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {BankingService} from 'app/services/api/finance/banking.service';
import {GatewayService} from '../../../../profile/services/gateway.service';
import {PAYMENT_MODES} from 'app/local-typings';
import {GenericFormArray} from 'app/entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {ProfilesService} from 'app/services/profiles.service';
import {ContactCreateDialogComponent} from '../../../../contacts/contact-dialogs/contact-create-dialog/contact-create-dialog.component';
import {Profile} from 'app/models/profile';
import * as moment from 'moment';
import {amountValidator} from '../../../../../utilities';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';

export class CustomFormGroup extends UntypedFormGroup {
    public controls: {
        vendor__contact_fk_id: UntypedFormControl;
        vendor_credit_number: UntypedFormControl;
        reference: UntypedFormControl;
        vendor_credit_date: UntypedFormControl;
        amount: UntypedFormControl;
        pay_to__ledger_account_fk_id: UntypedFormControl;
        payment_mode: UntypedFormControl;
    };

    constructor(controls: {
        vendor__contact_fk_id: UntypedFormControl;
        vendor_credit_number: UntypedFormControl;
        reference: UntypedFormControl;
        vendor_credit_date: UntypedFormControl;
        amount: UntypedFormControl;
        pay_to__ledger_account_fk_id: UntypedFormControl;
        payment_mode: UntypedFormControl;
    }) {
        super(controls);
        this.controls = controls;
    }
}

@Component({
    selector: 'app-form-vendor-advance',
    template: `
        <div [formGroup]="formGroup">
            <div class="d-flex align-items-baseline">
                <app-company-compensation-combined-picker
                    style="width:100%; margin-right: 8px;"
                    [availableTypes]="['contact']"
                    [title]="'Select Vendor *'"
                    [only_compensation_groups]="false"
                    [singleSelected]="true"
                    [bold_style]="false"
                    [nodesFA]="moneySenderCtrlArr"
                >
                </app-company-compensation-combined-picker>
                <button type="button" mat-icon-button matSuffix (click)="contactCreate()" matTooltip="Create Contact">
                    <mat-icon>person_add</mat-icon>
                </button>
            </div>

            <mat-form-field class="w-100">
                <input
                    matInput
                    formControlName="vendor_credit_number"
                    placeholder="Vendor#"
                    type="text"
                    autocomplete="off"
                />
            </mat-form-field>

            <mat-form-field class="w-100">
                <input matInput formControlName="reference" placeholder="Reference#" type="text" autocomplete="off" />
            </mat-form-field>

            <app-date-picker
                [placeholder]="'Date'"
                [dateControl]="formGroup.controls.vendor_credit_date"
            ></app-date-picker>

            <app-ledger-account-selector
                [ledgerAccountControl]="formGroup.controls.pay_to__ledger_account_fk_id"
                [placeholder]="'Deposit To'"
                [types]="types"
            >
            </app-ledger-account-selector>

            <mat-form-field class="w-100">
                <mat-label>Amount</mat-label>
                <input
                    [maskito]="currencyMaskitoMask"
                    matInput
                    type="text"
                    formControlName="amount"
                    matTooltipPosition="above"
                    placeholder="Amount"
                    autocomplete="off"
                    required
                />
            </mat-form-field>
            <mat-form-field class="w-100">
                <mat-select formControlName="payment_mode" required>
                    <ng-container *ngFor="let mode of paymentModes">
                        <mat-option [value]="mode.slug" *ngIf="mode.is_selectable">
                            <span>{{ mode.title }}</span>
                        </mat-option>
                    </ng-container>
                </mat-select>
                <mat-placeholder>Paid Via</mat-placeholder>
            </mat-form-field>
        </div>
        <div class="footer-row">
            <button
                mat-raised-button
                class="mat-primary"
                [disabled]="!formGroup.valid || createDisabled || !ledgerAccountId"
                (click)="save()"
            >
                Create
            </button>
            <button mat-button class="mat-primary" (click)="closeSidebar.emit()">Cancel</button>
        </div>
    `
})
export class FormVendorAdvanceComponent implements OnInit, OnDestroy {
    @Output() closeSidebar = new EventEmitter<void>();
    @Output() create = new EventEmitter<object>();
    @Input() createDisabled: boolean = false;
    @Input() bankTransaction: ITransactionExternal | undefined;
    @Input() ledgerAccountId: number | null = null;

    private unsubscribe: Subject<void> = new Subject();

    ledgerAccounts: ILedgerAccount[] = [];

    formGroup: CustomFormGroup = new CustomFormGroup({
        vendor__contact_fk_id: new UntypedFormControl(null, Validators.required),
        vendor_credit_number: new UntypedFormControl(null),
        reference: new UntypedFormControl(null, []),
        vendor_credit_date: new UntypedFormControl(null, Validators.required),
        amount: new UntypedFormControl(null, [Validators.required, amountValidator()]),

        pay_to__ledger_account_fk_id: new UntypedFormControl(null, []),
        payment_mode: new UntypedFormControl(null, Validators.required)
    });
    initFormGroupSnapshot = {};
    currencyMaskitoMask = currencyMaskitoOptions;

    profiles: Profile[] = [];
    gateway: IPaymentGateway | undefined;
    types: LedgerAccountTypes[] = ['other_current_asset'];
    paymentModes = PAYMENT_MODES;

    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);

    constructor(
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService,
        public dialog: MatDialog,
        private store: Store<IFinanceState>,
        private profileService: ProfilesService,
        private bankingService: BankingService,
        private gatewayService: GatewayService
    ) {}

    ngOnInit() {
        this.store.dispatch(new FetchProducts());

        this.gatewayService
            .getCompanySystemGateway()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((gateway) => {
                this.gateway = gateway;
            });

        this.profileService.getMyCompanyProfiles().then((response) => {
            this.profiles = response;
        });

        this.initFormGroupSnapshot = this.formGroup.getRawValue();

        if (this.bankTransaction) {
            this.formGroup.controls.amount.patchValue(this.bankTransaction.amount);
            this.formGroup.controls.amount.disable();
            this.formGroup.controls.vendor_credit_date.patchValue(this.bankTransaction.date);
        } else {
            this.formGroup.controls.vendor_credit_date.patchValue(moment().format('YYYYMMDD'));
        }

        this.moneySenderCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            if (values && values.length > 0) {
                this.formGroup.controls.vendor__contact_fk_id.patchValue(values[0].target_id);
            } else {
                this.formGroup.controls.vendor__contact_fk_id.patchValue(null);
            }
        });
    }

    private validateForm() {
        if (!this.formGroup.valid) {
            this.ntfs.warn('Vendor Template is not valid');
            return false;
        }

        return true;
    }

    save() {
        if (!this.validateForm() || this.createDisabled) {
            return;
        }
        const dataRaw = this.formGroup.getRawValue();

        this.create.emit({
            vendor__contact_fk_id: dataRaw.vendor__contact_fk_id,
            vendor_credit_number: dataRaw.vendor_credit_number,
            reference: dataRaw.reference,
            vendor_credit_date: dataRaw.vendor_credit_date,
            amount: Number(cleanCurrencyString(dataRaw.amount)),

            pay_to__ledger_account_fk_id: dataRaw.pay_to__ledger_account_fk_id,
            pay_to__payment_method_fk_id: dataRaw.pay_to__payment_method_fk_id,
            bank_ledger_account_id: this.ledgerAccountId
        });
    }

    contactCreate() {
        const dialogRef = this.dialog.open(ContactCreateDialogComponent, {
            autoFocus: false,
            data: {category: 'vendor'}
        });

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.closeSidebar.complete();
        this.create.complete();
    }
}
