import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {GenericFormArray, GenericFormGroup} from '../../../../../entites/generic.entity';
import {GroupCompensationProfilesListItem} from '../../models/group-compensation-profiles-list-item';
import {BehaviorSubject, merge as observableMerge, Subject} from 'rxjs';
import {startWith, takeUntil} from 'rxjs/operators';
import {ChipNode} from '../../models/chip-node';
import {Group} from '../../../../../models/group';
import {GroupsSource} from '../../../../../services/sources/groups.source';
import {CompensationProfileModel} from '../../models/compensation-profile.model';

const sortGroupListItemBy = {
    alpha_asc: (a: GroupCompensationProfilesListItem, b: GroupCompensationProfilesListItem) => {
        if (a.company_group.title! > b.company_group.title!) {
            return 1;
        } else if (a.company_group.title! < b.company_group.title!) {
            return -1;
        } else {
            return 0;
        }
    },
    order_desc: (a: GroupCompensationProfilesListItem, b: GroupCompensationProfilesListItem) => {
        if (a.company_group.sort_order < b.company_group.sort_order) {
            return 1;
        } else if (a.company_group.sort_order > b.company_group.sort_order) {
            return -1;
        } else {
            return 0;
        }
    }
};

@Component({
    selector: 'app-tab-2-groups',
    template: `
        <app-company-compensation-groups-list
            [includeDrafts]="includeDrafts"
            [sortBy]="sortBy"
            [compensationGroupsList]="compensationGroupsList"
        >
        </app-company-compensation-groups-list>
    `
})
export class Tab2GroupsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() public includeDrafts: UntypedFormControl = new UntypedFormControl(false);
    @Input() public sortBy: UntypedFormControl = new UntypedFormControl('order_desc');
    @Input() public unfilteredCompensationProfilesList: BehaviorSubject<CompensationProfileModel[]> | undefined;
    public compensationGroupsList: GenericFormArray<GroupCompensationProfilesListItem> =
        new GenericFormArray<GroupCompensationProfilesListItem>([]);
    filteredCompensationGroupsList: BehaviorSubject<GroupCompensationProfilesListItem[]> = new BehaviorSubject<
        GroupCompensationProfilesListItem[]
    >([]);
    unfilteredCompensationGroupsList: BehaviorSubject<GroupCompensationProfilesListItem[]> = new BehaviorSubject<
        GroupCompensationProfilesListItem[]
    >([]);
    unfilteredGroupsListAsGoE: BehaviorSubject<Group[]> = new BehaviorSubject<Group[]>([]);

    constructor(protected groupsSource: GroupsSource) {
        this.groupsSource.source.pipe(takeUntil(this.unsubscribe)).subscribe((next) => {
            this.unfilteredGroupsListAsGoE.next(
                next.filter((item) => item.permissions.use_in_compensation_as_group_of_entities)
            );
        });
    }

    ngOnInit() {
        this.filteredCompensationGroupsList.pipe(takeUntil(this.unsubscribe)).subscribe((next) => {
            this.compensationGroupsList = new GenericFormArray(next.map((item) => new GenericFormGroup(item)));
        });

        observableMerge(this.unfilteredCompensationGroupsList, this.sortBy.valueChanges)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((next) => {
                this.filteredCompensationGroupsList.next(
                    // @ts-ignore
                    this.unfilteredCompensationGroupsList.value.sort(sortGroupListItemBy[this.sortBy.value])
                );
            });

        observableMerge(this.unfilteredGroupsListAsGoE, this.unfilteredCompensationProfilesList!)
            .pipe(startWith(true), takeUntil(this.unsubscribe))
            .subscribe((next) => {
                const compensations = this.unfilteredCompensationProfilesList!.value;
                const groups = this.unfilteredGroupsListAsGoE.value;

                this.unfilteredCompensationGroupsList.next(
                    groups.map((company_group) => {
                        return new GroupCompensationProfilesListItem().setGroup(company_group).setCompensationProfiles(
                            compensations.filter((compensation) => {
                                return (
                                    compensation.apply_to.filter(
                                        (chip) =>
                                            chip.type === ChipNode.type_SET.individuals_in_group &&
                                            chip.target_id === company_group.id
                                    ).length > 0
                                );
                            })
                        );
                    })
                );
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
