import {Component, Input, OnDestroy} from '@angular/core';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {FinancialTransferEntity} from '../../../account-info/compensation/models/financial-transfer.entity';
import {takeUntil, tap} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';

interface IPayAtClosingValue {
    title: string;
    value: boolean;
    isOverridden: boolean;
    icon: string;
    isIconOutline: boolean;
    isHidden: boolean;
    system_key: PayAtClosingSystemKeys;
}

type PayAtClosingSystemKeys =
    | 'pay_at_closing'
    | 'pay_at_closing_overridden'
    | 'pay_at_remaining'
    | 'pay_at_remaining_overridden';

@Component({
    selector: 'app-pay-at-closing-picker',
    templateUrl: './pay-at-closing-picker.component.html',
    styleUrls: ['./pay-at-closing-picker.component.scss']
})
export class PayAtClosingPickerComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() canOverride: boolean = true;
    @Input() disabled: boolean = false;
    @Input() set financialTransferFG(fx: GenericFormGroup<FinancialTransferEntity>) {
        fx.valueChanges
            .pipe(
                tap(() => {
                    if (this.canOverride) {
                        fx.controls.override!.patchValue(fx.controls.override?.value, {emitEvent: false});
                    }
                    fx.controls.pay_at_escrow!.patchValue(fx.controls.pay_at_escrow?.value, {emitEvent: false});
                    fx.controls.disbursement_instructions!.patchValue(fx.controls.disbursement_instructions?.value, {
                        emitEvent: false
                    });
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((next: FinancialTransferEntity) => {
                if (this.canOverride) {
                    this.hideWrongValueToInherit(fx);
                } else {
                    this.hideOverrideValues();
                }
                this.currentPayAtClosingValue = this.getPayAtClosingValue(fx);
            });
        if (this.canOverride) {
            this.hideWrongValueToInherit(fx);
        } else {
            this.hideOverrideValues();
        }
        this.currentPayAtClosingValue = this.getPayAtClosingValue(fx);

        this.currentFinancialTransferFG = fx;
    }

    currentFinancialTransferFG: GenericFormGroup<FinancialTransferEntity> = new GenericFormGroup(
        new FinancialTransferEntity()
    );
    currentPayAtClosingValue: IPayAtClosingValue | null | undefined = null;
    defaultTooltip: string =
        'This setting has been inherited from the Compensation module. Unless the deal is overridden or approved, this setting will continue to update and reflect those changes automatically.';
    overriddenTooltip: string =
        'This setting has been overridden by the user. If this was done in error, please select the other option.';

    availableValues: IPayAtClosingValue[] = [
        {
            title: 'Pay at Closing',
            value: true,
            isOverridden: true,
            icon: 'work',
            isIconOutline: false,
            isHidden: false,
            system_key: 'pay_at_closing_overridden'
        },
        {
            title: 'Pay at Closing',
            value: true,
            isOverridden: false,
            icon: 'work_outline',
            isIconOutline: false,
            isHidden: false,
            system_key: 'pay_at_closing'
        },
        {
            title: 'Pay at Remaining',
            value: false,
            isOverridden: true,
            icon: 'next_week',
            isIconOutline: false,
            isHidden: false,
            system_key: 'pay_at_remaining_overridden'
        },
        {
            title: 'Pay at Remaining',
            value: false,
            isOverridden: false,
            icon: 'next_week',
            isIconOutline: true,
            isHidden: false,
            system_key: 'pay_at_remaining'
        }
    ];

    constructor() {}

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    hideWrongValueToInherit(financialTransfer: GenericFormGroup<FinancialTransferEntity>) {
        let systemKeyToHide: PayAtClosingSystemKeys;
        if (financialTransfer.controls.pay_at_escrow && financialTransfer.controls.pay_at_escrow.value) {
            systemKeyToHide = 'pay_at_remaining';
        } else {
            systemKeyToHide = 'pay_at_closing';
        }

        const valueToHide = this.availableValues.find((value) => value.system_key === systemKeyToHide);
        if (typeof valueToHide !== 'undefined') {
            valueToHide.isHidden = true;
        }
    }

    hideOverrideValues() {
        this.availableValues.forEach((value) => {
            if (value.isOverridden) {
                value.isHidden = true;
            }
        });
    }

    getPayAtClosingValue(financialTransfer: GenericFormGroup<FinancialTransferEntity>): IPayAtClosingValue | undefined {
        if (
            financialTransfer.controls.override &&
            (financialTransfer.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls &&
            (financialTransfer.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls?.pay_at_escrow
        ) {
            return this.availableValues.find(
                (availableValue) =>
                    !availableValue.isHidden &&
                    availableValue.value ===
                        (financialTransfer.controls.override as GenericFormGroup<FinancialTransferEntity>)?.controls
                            ?.pay_at_escrow?.value &&
                    availableValue.isOverridden === true
            );
        }
        return this.availableValues.find(
            (availableValue) =>
                availableValue.value === financialTransfer.controls.pay_at_escrow?.value &&
                availableValue.isOverridden === false
        );
    }

    onValueChange(valueIndex: number) {
        this.currentPayAtClosingValue = this.availableValues[valueIndex];
        switch (this.currentPayAtClosingValue.system_key) {
            case 'pay_at_closing':
                this.setValue();
                if (
                    this.currentFinancialTransferFG.controls.override &&
                    (this.currentFinancialTransferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)
                        ?.controls
                ) {
                    (this.currentFinancialTransferFG.controls
                        .override as GenericFormGroup<FinancialTransferEntity>)!.removeControl('pay_at_escrow');
                }
                this.currentFinancialTransferFG.controls.disbursement_instructions!.enable();
                this.currentFinancialTransferFG.controls.notes!.enable();
                break;
            case 'pay_at_closing_overridden':
                this.setOverriddenValue();
                this.currentFinancialTransferFG.controls.disbursement_instructions!.enable();
                this.currentFinancialTransferFG.controls.notes!.enable();
                break;
            case 'pay_at_remaining':
                this.setValue();
                if (
                    this.currentFinancialTransferFG.controls.override &&
                    (this.currentFinancialTransferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)
                        ?.controls
                ) {
                    (this.currentFinancialTransferFG.controls
                        .override as GenericFormGroup<FinancialTransferEntity>)!.removeControl('pay_at_escrow');
                }
                this.disableDisbursementInstructions();
                break;
            case 'pay_at_remaining_overridden':
                this.setOverriddenValue();
                this.disableDisbursementInstructions();
                break;
        }
    }

    private setValue() {
        if (!this.canOverride) {
            this.currentFinancialTransferFG.controls.pay_at_escrow!.patchValue(this.currentPayAtClosingValue?.value);
        }
    }

    private setOverriddenValue() {
        if (this.canOverride) {
            if (
                this.currentFinancialTransferFG.controls.override &&
                (this.currentFinancialTransferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)
                    ?.controls
            ) {
                if (
                    (this.currentFinancialTransferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)
                        ?.controls.pay_at_escrow
                ) {
                    (
                        this.currentFinancialTransferFG.controls.override as GenericFormGroup<FinancialTransferEntity>
                    )?.controls.pay_at_escrow!.patchValue(this.currentPayAtClosingValue?.value);
                } else {
                    (this.currentFinancialTransferFG.controls
                        .override as GenericFormGroup<FinancialTransferEntity>)!.addControl(
                        'pay_at_escrow',
                        new UntypedFormControl(this.currentPayAtClosingValue?.value)
                    );
                }
            } else {
                this.currentFinancialTransferFG.controls.override = new GenericFormGroup<FinancialTransferEntity>(<
                    FinancialTransferEntity
                >{
                    pay_at_escrow: this.currentPayAtClosingValue?.value
                });
            }
        }
    }

    private disableDisbursementInstructions() {
        this.currentFinancialTransferFG.controls.disbursement_instructions?.disable();
        this.currentFinancialTransferFG.controls.notes?.disable();
        if (this.canOverride) {
            if (
                this.currentFinancialTransferFG.controls.override &&
                (this.currentFinancialTransferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)
                    ?.controls &&
                ((this.currentFinancialTransferFG.controls.disbursement_instructions!.value &&
                    !(this.currentFinancialTransferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)
                        ?.controls?.disbursement_instructions) ||
                    ((this.currentFinancialTransferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)
                        ?.controls?.disbursement_instructions &&
                        (this.currentFinancialTransferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)
                            ?.controls?.disbursement_instructions?.value))
            ) {
                if (
                    this.currentFinancialTransferFG.controls.disbursement_instructions?.value &&
                    !(this.currentFinancialTransferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)
                        ?.controls?.disbursement_instructions
                ) {
                    (
                        this.currentFinancialTransferFG.controls.override as GenericFormGroup<FinancialTransferEntity>
                    )?.controls?.disbursement_instructions?.disable();
                    (
                        this.currentFinancialTransferFG.controls.override as GenericFormGroup<FinancialTransferEntity>
                    )?.controls?.notes?.disable();
                }
                if (
                    (this.currentFinancialTransferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)
                        ?.controls?.disbursement_instructions &&
                    (this.currentFinancialTransferFG.controls.override as GenericFormGroup<FinancialTransferEntity>)
                        ?.controls?.disbursement_instructions?.value
                ) {
                    (
                        this.currentFinancialTransferFG.controls.override as GenericFormGroup<FinancialTransferEntity>
                    )?.controls?.disbursement_instructions?.disable();
                    (
                        this.currentFinancialTransferFG.controls.override as GenericFormGroup<FinancialTransferEntity>
                    )?.controls?.notes?.disable();
                }
            }
        }
    }
}
