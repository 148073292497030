import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {cloneDeep} from 'lodash-es';

@Component({
    selector: 'app-report-ledger-account-type',
    templateUrl: './report-ledger-account-type.component.html',
    styleUrls: ['./report-ledger-account-type.component.scss']
})
export class ReportLedgerAccountTypeComponent implements OnInit, OnDestroy {
    @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete | undefined;
    @Input() list: any[] = [];
    @Input()
    set value(value: Array<string | number>) {
        this._value = value;
        if (this.initialList.length) {
            this.filterGroupData();
        }
    }
    get value() {
        return this._value;
    }
    @Input() titleAttribute: string = '';
    @Input() groupNameAttribute: string = '';
    @Input() valueAttribute: string = '';
    @Output() valueChanges = new EventEmitter();
    @ViewChild('input', {static: false}) input: ElementRef<HTMLInputElement> | undefined;
    initialList: any[] = [];

    private _value: Array<string | number> = [];

    /**
     * On init
     */
    ngOnInit() {
        this.initialList = cloneDeep(this.list);
        this.value = this.value || [];
        this.filterGroupData();
    }

    /**
     * Search
     * @param event
     */
    search(event: Event) {
        const value: string = (<HTMLInputElement>event.target).value;
        const result: any = [];
        this.initialList.forEach((group) => {
            const list = group.list.filter((item: any) =>
                item[this.titleAttribute].toLowerCase().includes(value.toLowerCase())
            );

            if (list.length) {
                result.push({
                    ...group,
                    list
                });
            }
        });

        this.list = result;
    }

    filterGroupData() {
        const result: any = [];
        this.initialList.forEach((group) => {
            const list = group.list.filter((item: any) => !this._value.includes(item[this.valueAttribute]));

            if (list.length) {
                result.push({
                    ...group,
                    list
                });
            }
        });
        this.list = result;
    }

    select(event: MatAutocompleteSelectedEvent) {
        if (event.option.value === 'none') {
            this.valueChanges.emit([]);
        } else if (!this.value.includes(event.option.value)) {
            this.valueChanges.emit(([] as string[]).concat(this.value as string[], [event.option.value]));
        }

        this.clearInput();
        this.filterGroupData();
    }

    remove(id: number) {
        this.valueChanges.emit(this.value.filter((item) => item !== id));
    }

    clearInput() {
        this.input!.nativeElement.value = '';
        this.input!.nativeElement.blur();
        this.list = this.initialList;
    }

    ngOnDestroy() {
        this.valueChanges.complete();
    }
}
