import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {of, Subject} from 'rxjs';
import {ILedgerAccount, IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {ZipiFinancialTransferService} from '../../../../../../../services/api/finance/zipi-financia-transfer.service';
import {CompanyGatewayService} from '../../../../../../../services/api/finance/company-gateway.service';
import {catchError, takeUntil} from 'rxjs/operators';
import {FormGroupWithFormControls} from '../../../../../../../typings/common';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../../../utilities/maskito';

@Component({
    selector: 'app-dwolla-transfer-money-dialog',
    templateUrl: './zipi-financial-transfer-money-dialog.component.html',
    styleUrls: ['./zipi-financial-transfer-money-dialog.component.scss']
})
export class ZipiFinancialTransferMoneyDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    formGroup: FormGroupWithFormControls = this.fb.group({
        reference: [null, []],
        amount: [null, Validators.required],
        paid_by__payment_method_fk_id: [null, Validators.required],
        pay_to__payment_method_fk_id: [null, Validators.required]
    }) as FormGroupWithFormControls;

    currencyMaskitoMask = currencyMaskitoOptions;
    method: IPaymentMethod | undefined;
    ledgerAccount: ILedgerAccount | undefined;
    paymentGateways: IPaymentGateway[] = [];

    isProcessing = false;

    constructor(
        public dialogRef: MatDialogRef<ZipiFinancialTransferMoneyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder,
        private zipiFinancialTransferService: ZipiFinancialTransferService,
        private companyGatewayService: CompanyGatewayService
    ) {}

    async ngOnInit() {
        if (this.data.method && this.formGroup) {
            this.method = this.data.method;
            if (this.method && this.method.payment_method_id) {
                this.formGroup.controls.paid_by__payment_method_fk_id.patchValue(this.method.payment_method_id);
            }
        }
        this.companyGatewayService
            .getCompanyGatewaysByTypes(['zipi_financial_business', 'zipi_financial_trust'], null)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.paymentGateways = data;
            });
    }

    initiateTransfer() {
        if (!this.formGroup.valid) {
            return;
        }
        this.isProcessing = true;
        const data = this.formGroup.getRawValue();
        data.amount = Number(cleanCurrencyString(data.amount));

        this.zipiFinancialTransferService
            .initiateZipiFinancialSelfTransfer(data)
            .pipe(
                catchError(() => {
                    this.isProcessing = false;
                    return of(null);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((res) => {
                if (res) {
                    this.dialogRef.close(res);
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
