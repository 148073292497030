<div class="d-flex flex-wrap flex-column justify-content-center">
    <h2>Create New Report</h2>

    <div [formGroup]="commonReportForm" class="w-100">
        <mat-form-field class="w-100">
            <input matInput type="text" placeholder="Name" formControlName="title" />
        </mat-form-field>

        <mat-form-field class="w-100">
            <mat-select
                placeholder="Uncategorized"
                formControlName="category_id"
                (selectionChange)="selectCategory($event)"
            >
                <mat-option [value]="0">
                    <mat-icon>add</mat-icon>
                    <span>Add category</span></mat-option
                >
                <mat-option *ngFor="let category of categories" [value]="category.id">
                    {{ category.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-card [formGroup]="categoryForm" class="w-100" *ngIf="isShowCreateCategory">
        <h4>Create category</h4>
        <mat-form-field class="w-100">
            <input matInput type="text" placeholder="Name" formControlName="title" />
        </mat-form-field>

        <mat-form-field class="w-100">
            <mat-select placeholder="Select Type" formControlName="category_type">
                <mat-option *ngFor="let category of categoryTypes" [value]="category.value">
                    {{ category.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div>
            <mat-icon class="sp-cursor-pointer pull-right m-2" (click)="cancelCreateCategory()">close</mat-icon>
            <mat-icon class="sp-cursor-pointer pull-right m-2" (click)="createCategory()">check</mat-icon>
        </div>
    </mat-card>

    <div class="m-3"></div>

    <div class="d-flex justify-content-between w-100">
        <span class="sp-spacer"></span>

        <button
            mat-raised-button
            color="primary"
            class="fix-focus-outline m-2"
            (click)="save()"
            [disabled]="isShowCreateCategory"
        >
            Save
        </button>

        <button mat-button class="fix-focus-outline m-2" (click)="close()">Cancel</button>
    </div>
</div>
