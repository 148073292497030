import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {PaymentsMadeService} from '../../../services/payments-made.service';
import {IPaymentMadeListItem} from '../typings';
import {GenericFormArray, GenericFormGroup} from '../../../../../entites/generic.entity';
import {takeUntil} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Subject} from 'rxjs';

interface IPaymentMadeData {
    vendor_name: string;
    payment_made_id: number;
    check_number: string;
    amount: number;
    is_printed_successfully?: boolean;
}

@Component({
    selector: 'app-post-print-checks-dialog',
    templateUrl: './post-print-checks-dialog.component.html',
    styleUrls: ['./post-print-checks-dialog.component.scss']
})
export class PostPrintChecksDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    paymentsMadeForms: GenericFormArray<IPaymentMadeData> = new GenericFormArray<IPaymentMadeData>([]);
    isChecksSaving: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<PostPrintChecksDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder,
        private paymentsMadeService: PaymentsMadeService
    ) {}

    enableDisableForms(action: 'enable' | 'disable') {
        if (action === 'disable') {
            this.paymentsMadeForms.controls.forEach((form) => form.disable());
            this.isChecksSaving = true;
        } else {
            this.paymentsMadeForms.controls.forEach((form) => form.enable());
            this.isChecksSaving = false;
        }
    }

    ngOnInit(): void {
        const checksIsPrintedSuccessfullyChanged: Observable<IPaymentMadeData>[] = [];
        let enteredFirstCheckNumber = this.data.firstCheckNumber;
        let numericPart: number | null = null;
        let numericPartLength: number = 0;
        let textPart: string = '';
        let numericPartAsString: string = '';

        if (enteredFirstCheckNumber) {
            let numberPartRegExp = RegExp(/[0-9]+(?![a-zA-Z0-9])$/);
            const numberPartResult = numberPartRegExp.exec(enteredFirstCheckNumber);
            numericPartAsString = numberPartResult ? String(numberPartResult[0]) : '';
            numericPartLength = numericPartAsString.length;
            numericPart = Number(numericPartAsString);
            if (numberPartResult && numberPartResult.index) {
                textPart = enteredFirstCheckNumber.substr(0, numberPartResult.index);
            }
        }
        let checkNumber: string | null = null;
        if (enteredFirstCheckNumber && numericPart) {
            checkNumber = `${textPart}${numericPartAsString}`;
        }
        this.data.payments.forEach((payment: IPaymentMadeListItem) => {
            const newForm = this.fb.group({
                vendor_name: [payment.vendor_name, [Validators.required]],
                payment_made_id: [payment.payment_made_id, [Validators.required]],
                check_number: [payment.check_number || checkNumber, [Validators.required]],
                amount: [payment.amount, [Validators.required]],
                is_printed_successfully: [true, [Validators.required]]
            });
            this.paymentsMadeForms.push(newForm);
            checksIsPrintedSuccessfullyChanged.push(newForm.valueChanges);
            if (!payment.check_number && numericPart) {
                numericPart++;
                const numericPartToStr = String(numericPart);
                checkNumber = `${textPart ? textPart : ''}${numericPartToStr.length < numericPartLength ? numericPartToStr.padStart(numericPartLength, '0') : numericPartToStr}`;
            }
        });

        checksIsPrintedSuccessfullyChanged.forEach((checkIsPrintedSuccessfullyChanged) => {
            checkIsPrintedSuccessfullyChanged.pipe(takeUntil(this.unsubscribe)).subscribe((formValues) => {
                this.paymentsMadeForms.controls.forEach((form: GenericFormGroup<IPaymentMadeData>) => {
                    const paymentMadeId = form.controls.payment_made_id!.value;
                    if (paymentMadeId === formValues.payment_made_id) {
                        if (formValues.is_printed_successfully) {
                            if (!form.controls.check_number?.hasValidator(Validators.required)) {
                                form.controls.check_number!.addValidators(Validators.required);
                                form.controls.check_number!.markAsTouched();
                            }
                        } else {
                            if (form.controls.check_number?.hasValidator(Validators.required)) {
                                form.controls.check_number!.removeValidators(Validators.required);
                                form.controls.check_number!.markAsUntouched();
                            }
                        }
                    }
                });
            });
        });
    }

    save() {
        const formsToSubmit = this.paymentsMadeForms.controls.filter(
            (checkForm) => checkForm.controls.is_printed_successfully!.value
        );

        if (formsToSubmit.length === 0) {
            this.dialogRef.close();
            return;
        }

        formsToSubmit.forEach((form) => {
            form.get('check_number')!.markAsTouched();
        });

        if (!formsToSubmit.every((form) => form.valid)) {
            return;
        }

        this.enableDisableForms('disable');
        const valuesToSave: {payment_made_id: number; check_number: string}[] = [];
        formsToSubmit.forEach((checkForm) => {
            const check = checkForm.getRawValue();
            valuesToSave.push({
                payment_made_id: check.payment_made_id,
                check_number: check.check_number
            });
        });
        this.paymentsMadeService
            .setChecksPrinted(valuesToSave)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.enableDisableForms('enable');
                this.dialogRef.close({submitted: true});
            });
    }

    cancel() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
