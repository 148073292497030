<mat-card class="sign-in-card">
    <mat-card-header>
        <div class="title">Connect User</div>
    </mat-card-header>

    <mat-card-content>
        <form #form="ngForm" method="post">
            <div class="flex-row">
                <mat-form-field>
                    <input matInput required type="email" ngModel name="email" placeholder="Email" />
                </mat-form-field>
            </div>

            <div>
                <mat-form-field>
                    <input
                        matInput
                        required
                        type="password"
                        ngModel
                        name="password"
                        placeholder="Password"
                        autocomplete="off"
                    />
                </mat-form-field>
                <a href="javascript:" (click)="forgotPassword()">Forgot Password?</a>
            </div>

            <div style="text-align: center">
                <button mat-button class="mat-primary" (click)="onSubmit(form)">continue</button>
                <br />
                <a [routerLink]="['/auth', 'sign-up']">Don't have an account? Sign Up ...</a>
            </div>
        </form>
        <div style="text-align: center">
            <div style="font-size: 24px; margin-top: 25px">- OR -</div>
            <button mat-button class="mat-primary" (click)="tryGoogleAuth()">Google Auth</button>
        </div>
    </mat-card-content>
</mat-card>
