import {BaseModel} from './base.model';
import {PickerItem} from '../modules/shared/components/profiles-picker/models';

export class BoardSettings {
    data_specified_by_current_user = true;
    filter_by_deal_members: PickerItem[] = [];
}

export class Board extends BaseModel {
    widget_board_id: number | null = null;
    user_id: number | null = null;
    creator_account_id: number | null = null;
    name: string | null = null;
    settings: BoardSettings | null = null;
}
