import {GenericEntity} from '../entites/generic.entity';
import {IContact, IContactPartLink} from '../../typings';
import {IParticipantSubTypes} from '@cyberco-nodejs/zipi-typings/companies.typings';
import {IContactPerson} from '@cyberco-nodejs/zipi-typings/contacts.typings';
import {Contact} from './contact';

export enum DEAL_PARTICIPANT_FORMAT {
    text = 'text',
    contact = 'contact'
}

export class ContactPartLink extends GenericEntity implements IContactPartLink {
    deal_participant_id?: number;
    contact_id: number | null = null;
    contact_person_fk_id: number | null = null;
    link_title: string | null = null;
    contact_location_fk_id: number | null = null;
    location_title: string | null = null;

    contact?: IContact | null = null;
    contact_person?: IContactPerson | null = null;
    sub_type?: IParticipantSubTypes[] = [];
    hidden?: boolean = false;

    format?: 'contact' | 'text' = 'contact';
    is_ignored?: boolean = false;
    added_by__addon_id?: number | null = null;

    // setPersonHash(hash: string) {
    //     this.person_hash = hash;
    //     return this;
    // }

    setLinkTitle(linkTitle: string) {
        this.link_title = linkTitle;
        return this;
    }

    setContactId(id: number | null) {
        this.contact_id = id;
        return this;
    }

    static get helpers() {
        return Object.assign(
            {
                props: Object.getOwnPropertyNames(new this()).reduce((acc: {[key: string]: any}, curr) => {
                    acc[curr] = curr;
                    return acc;
                }, {}),
                build: {
                    // profile: (val, fabric) => Object.assign(new Profile, val),
                    // disbursement_template: (val, fabric) => Object.assign(new DisbursementTemplateModel, val),
                    // company_group: (val, fabric) => Object.assign(new Group, val),
                    // children: (val: any[], fabric) => val.map(item => this.FABRIC(SalesEntity)[fabric](item)),
                }
            },
            GenericEntity.helpers
        );
    }
}
