import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, pipe, Subject} from 'rxjs';
import {UntypedFormArray, UntypedFormBuilder} from '@angular/forms';
import {IBill, IContact, IInvoice, IProduct} from '@cyberco-nodejs/zipi-typings';
import {InvoicesService} from '../../../../services/api/finance/invoices.service';
import {select, Store} from '@ngrx/store';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {IFinanceState} from '../../../finance/store/finance.reducer';
import {FetchProducts} from '../../../finance/store/finance.actions';
import {selectProducts} from '../../../finance/store/finance.selectors';
import {ProductsService} from '../../../../services/api/finance/products.service';
import {CreateProductDialogComponent} from '../../../finance/components/products/create-product-dialog/create-product-dialog.component';
import {ShipperContactsService} from '../../../../services/api/shipper.contacts.service';

interface IDialogData {
    contactId: number;
}

@Component({
    selector: 'app-contact-products-mapping-dialog',
    templateUrl: './contact-products-mapping-dialog.component.html',
    styleUrls: ['./contact-products-mapping-dialog.component.scss']
})
export class ContactProductsMappingDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    invoice: IInvoice | IBill | undefined;
    itemsArray: UntypedFormArray | undefined;
    product$: Observable<IProduct[]> | undefined;
    products: IProduct[] | undefined;
    contactProducts: IProduct[] | undefined;
    contact: IContact | undefined;

    constructor(
        private fb: UntypedFormBuilder,
        private invoicesService: InvoicesService,
        private productsSrv: ProductsService,
        private store: Store<IFinanceState>,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ContactProductsMappingDialogComponent>,
        private cs: ShipperContactsService,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData & {hideCancel: boolean}
    ) {}

    ngOnInit() {
        this.cs
            .showContact(this.data.contactId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((contact) => {
                this.contact = contact;

                const contactProductIds = this.contact.product_mapping.map((map) => map.contact_company__product_fk_id);
                if (contactProductIds && contactProductIds.length > 0) {
                    this.productsSrv
                        .getProductsByIds(contactProductIds)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((products) => {
                            this.contactProducts = products;
                        });
                }
            });

        this.store.dispatch(new FetchProducts());
        this.product$ = this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe));
        this.product$.pipe(takeUntil(this.unsubscribe)).subscribe((prods) => {
            this.products = prods;
        });
    }

    getProductName(contact_company__product_fk_id: number) {
        if (this.contactProducts && this.contactProducts.length > 0) {
            const currentProd = this.contactProducts.find((prod) => prod.product_id === contact_company__product_fk_id);

            if (currentProd) {
                return currentProd.name;
            }

            return 'N/A';
        }
        return '';
    }

    getProductAmount(contact_company__product_fk_id: number) {
        if (this.contactProducts && this.contactProducts.length > 0) {
            const currentProd = this.contactProducts.find((prod) => prod.product_id === contact_company__product_fk_id);

            if (currentProd) {
                return currentProd.price;
            }

            return 'N/A';
        }
        return '';
    }

    createProduct() {
        const dialogRef = this.dialog.open(CreateProductDialogComponent, {
            // width: '90vw',
            // minHeight: '30vh'
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => this.store.dispatch(new FetchProducts()));
    }

    removeProductFkId(myProdId: number) {
        if (!this.contact) {
            return;
        }
        this.contact.product_mapping = this.contact.product_mapping.filter(
            (map) => map.matched__product_fk_id !== myProdId
        );
    }

    continue() {
        this.dialogRef.close(this.contact);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
