<app-form-expense
    *ngIf="transactionType?.value === 'expense'"
    (closeSidebar)="closeSidebar.emit()"
    (create)="create.emit($event)"
    [bankTransaction]="bankTransaction"
    [createDisabled]="createDisabled"
    [ledgerAccountId]="ledgerAccountId"
></app-form-expense>
<app-form-vendor-advance
    *ngIf="transactionType?.value === 'vendor_advance'"
    (closeSidebar)="closeSidebar.emit()"
    (create)="create.emit($event)"
    [bankTransaction]="bankTransaction"
    [createDisabled]="createDisabled"
    [ledgerAccountId]="ledgerAccountId"
></app-form-vendor-advance>
<app-form-vendor-payment
    *ngIf="transactionType?.value === 'vendor_payment'"
    (closeSidebar)="closeSidebar.emit()"
    (create)="create.emit($event)"
    [bankTransaction]="bankTransaction"
    [createDisabled]="createDisabled"
    [ledgerAccountId]="ledgerAccountId"
></app-form-vendor-payment>
<app-form-transfer-to-another-account
    *ngIf="transactionType?.value === 'transfer_to_another_account'"
    (closeSidebar)="closeSidebar.emit()"
    (create)="create.emit($event)"
    [bankTransaction]="bankTransaction"
    [createDisabled]="createDisabled"
    [ledgerAccountId]="ledgerAccountId"
></app-form-transfer-to-another-account>
<app-form-card-payment
    *ngIf="transactionType?.value === 'card_payment'"
    (closeSidebar)="closeSidebar.emit()"
    (create)="create.emit($event)"
    [bankTransaction]="bankTransaction"
    [createDisabled]="createDisabled"
    [ledgerAccountId]="ledgerAccountId"
></app-form-card-payment>
<app-form-owner-drawings
    *ngIf="transactionType?.value === 'owner_drawings'"
    (closeSidebar)="closeSidebar.emit()"
    (create)="create.emit($event)"
    [bankTransaction]="bankTransaction"
    [createDisabled]="createDisabled"
    [ledgerAccountId]="ledgerAccountId"
></app-form-owner-drawings>

<app-form-customer-advance
    *ngIf="transactionType?.value === 'customer_advance'"
    (closeSidebar)="closeSidebar.emit()"
    (create)="create.emit($event)"
    [bankTransaction]="bankTransaction"
    [createDisabled]="createDisabled"
    [ledgerAccountId]="ledgerAccountId"
></app-form-customer-advance>
<app-form-customer-payment
    *ngIf="transactionType?.value === 'customer_payment'"
    (closeSidebar)="closeSidebar.emit()"
    (create)="create.emit($event)"
    [bankTransaction]="bankTransaction"
    [createDisabled]="createDisabled"
    [ledgerAccountId]="ledgerAccountId"
></app-form-customer-payment>
<app-form-transfer-from-another-account
    *ngIf="transactionType?.value === 'transfer_from_another_account'"
    (closeSidebar)="closeSidebar.emit()"
    (create)="create.emit($event)"
    [bankTransaction]="bankTransaction"
    [createDisabled]="createDisabled"
    [ledgerAccountId]="ledgerAccountId"
></app-form-transfer-from-another-account>
<app-form-other-deposit
    *ngIf="transactionType?.value === 'other_deposit'"
    (closeSidebar)="closeSidebar.emit()"
    (create)="create.emit($event)"
    [bankTransaction]="bankTransaction"
    [createDisabled]="createDisabled"
    [ledgerAccountId]="ledgerAccountId"
></app-form-other-deposit>
<app-form-owners-contribution
    *ngIf="transactionType?.value === 'owners_contribution'"
    (closeSidebar)="closeSidebar.emit()"
    (create)="create.emit($event)"
    [bankTransaction]="bankTransaction"
    [createDisabled]="createDisabled"
    [ledgerAccountId]="ledgerAccountId"
></app-form-owners-contribution>
