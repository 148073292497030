<section class="container-fluid" *ngIf="vendorCredit">
    <div class="w-100 text-right py-2 d-flex align-items-center">
        <!--        <div>Status: {{vendorCredit.status | fromSnakeCase}}</div>-->
        <i class="sp-spacer"></i>
        <button
            mat-stroked-button
            color="primary"
            class="mr-2"
            *ngIf="isPayStubPrintAvailable"
            (click)="printPayStub()"
        >
            Print Paystub
        </button>
        <div
            class="mr-2"
            matTooltip="You cannot modify Vendor Credit associated with Deal."
            [matTooltipDisabled]="vendorCredit && !vendorCredit.source__deal_fk_id"
        >
            <button
                [routerLink]="['edit']"
                mat-raised-button
                [rule]="{purchases__manage_vendor_credits: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                [disabled]="
                    vendorCredit.type === 'expense' ||
                    (vendorCredit && !!vendorCredit.source__deal_fk_id) ||
                    (vendorCredit && ['closed', 'void'].includes(vendorCredit.status))
                "
            >
                Edit
            </button>
        </div>
        <button mat-raised-button type="button" disabled="true">Download PDF</button>
    </div>
    <mat-card class="p-5">
        <div class="credit-status" [ngStyle]="{'background-color': statusColor[vendorCredit.status]}">
            {{ vendorCredit.status | fromSnakeCase }}
        </div>

        <header class="row">
            <div class="col-6 zp-company-info">
                <!-- <p>Company Logo</p> -->
                <p class="zp-company-title">{{ vendorCredit?.creator_company?.title }}</p>
                <p class="text-uppercase">
                    {{ vendorCredit?.creator_profile?.first_name }} {{ vendorCredit?.creator_profile?.last_name }}
                </p>
            </div>

            <div class="col-6 text-right">
                <p class="zp-inveice_title">Vendor Credits</p>
                <p class="zp-invoice-num_value">#{{ vendorCredit?.vendor_credit_number }}</p>

                <p class="mb-2">
                    <span>Amount :</span>
                    <span class="zp-w-150">{{ vendorCredit?.total_amount | currency }}</span>
                </p>
                <div
                    class="mb-2 d-flex justify-content-end align-items-center"
                    *ngIf="vendorCredit.type === 'closing_credit'"
                >
                    <div class="mr-1">1099 Amount</div>
                    <mat-icon
                        [matTooltip]="'Edit amount for 1099'"
                        style="font-size: 18px; width: 18px; height: 18px; cursor: pointer"
                        (click)="open1099Edit()"
                    >
                        edit
                    </mat-icon>
                    <span>:</span>
                    <div class="zp-w-150">
                        <div
                            *ngIf="
                                vendorCredit?.amount_1099_nec_custom !== undefined &&
                                    vendorCredit?.amount_1099_nec_custom !== null;
                                else elseBlock
                            "
                        >
                            {{ vendorCredit?.amount_1099_nec_custom | currency }}
                        </div>
                        <ng-template #elseBlock>
                            {{ vendorCredit?.amount_1099_nec || 0 | currency }}
                        </ng-template>
                    </div>
                </div>
            </div>
        </header>

        <p>Bill To</p>
        <div class="row">
            <div class="col-6">
                <p>
                    <a [routerLink]="['/contacts/', vendorCredit?.vendor_contact?.contact_id]">{{
                        vendorCredit?.vendor_contact?.display_name
                    }}</a>
                </p>
            </div>

            <div class="col-6 text-right">
                <p class="mb-2">
                    <span>Credit Date :</span>
                    <span class="zp-w-150">{{
                        vendorCredit?.vendor_credit_date! | dateFromNumber | date: 'longDate'
                    }}</span>
                </p>

                <p class="mb-2 d-flex justify-content-end">
                    <span>Reference :</span>
                    <span class="zp-w-150">{{ vendorCredit?.reference }}</span>
                </p>
            </div>
        </div>

        <table class="table">
            <thead class="bg-dark text-white">
                <tr>
                    <td scope="col">#</td>
                    <td scope="col">Item</td>
                    <td scope="col">Related Deal</td>
                    <td scope="col">Qty</td>
                    <td scope="col">Rate</td>
                    <td scope="col" class="text-right">Amount</td>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of vendorCredit?.items; index as idx">
                    <td>{{ idx + 1 }}</td>
                    <td>
                        {{ item?.name }}
                        <i class="text-muted">{{ item?.description }}</i>
                    </td>
                    <td>
                        <a
                            *ngIf="item.related_deal && item.related_deal.id"
                            [routerLink]="['/deals/edit', item.related_deal.id]"
                        >
                            <span *ngIf="!item.related_deal?.address" class="text-muted">No address </span>
                            <span *ngIf="item.related_deal?.address"
                                >{{ item.related_deal?.street_number }} {{ item.related_deal?.address }}
                                {{ item.related_deal?.city }} {{ item.related_deal?.city }},
                                {{ item.related_deal?.state }} {{ item.related_deal?.zip }}
                            </span>
                            — {{ item.related_deal?.type }}/{{ item.related_deal?.status }}
                        </a>
                        <ng-container *ngIf="item.related_deal && !item.related_deal.id">
                            <span *ngIf="!item.related_deal?.address" class="text-muted">No address </span>
                            <span *ngIf="item.related_deal?.address"
                                >{{ item.related_deal?.street_number }}&nbsp;{{ item.related_deal?.address }} &nbsp;{{
                                    item.related_deal?.city
                                }}&nbsp;{{ item.related_deal?.city }},&nbsp;{{ item.related_deal?.state }}&nbsp;{{
                                    item.related_deal?.zip
                                }}
                            </span>
                            — {{ item.related_deal?.type }}/{{ item.related_deal?.status }}
                        </ng-container>
                    </td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.rate }}</td>
                    <td class="text-right">{{ item.amount | currency }}</td>
                </tr>
            </tbody>
        </table>

        <div class="w-100 text-right">
            <p class="mb-2">
                <strong>
                    Total:
                    <span class="zp-total-nember font-weight-bold">{{ vendorCredit?.total_amount | currency }}</span>
                </strong>
            </p>
            <p class="mb-2">
                <strong class="bg-light py-2">
                    <span class="zp-w-150">&nbsp;</span>
                    Credits Applied:
                    <strong class="zp-total-nember">{{
                        vendorCredit?.total_amount! - vendorCredit?.balance! | currency
                    }}</strong>
                </strong>
            </p>
            <p class="mb-2">
                <strong
                    >Balance: <strong class="zp-total-nember">{{ vendorCredit?.balance | currency }}</strong></strong
                >
            </p>
        </div>

        <div class="my-3">
            Related Records
            <button mat-icon-button (click)="showPayments = !showPayments">
                <mat-icon *ngIf="showPayments">expand_more</mat-icon>
                <mat-icon *ngIf="!showPayments">chevron_right</mat-icon>
            </button>

            <div *ngIf="showPayments && dataSource.data && dataSource.data.length === 0">No related payments.</div>

            <div *ngIf="showPayments && dataSource.data && dataSource.data.length > 0">
                <table mat-table [dataSource]="dataSource" class="w-100">
                    <ng-container matColumnDef="payment_date">
                        <th mat-header-cell *matHeaderCellDef>Payment Date</th>
                        <td mat-cell *matCellDef="let element">
                            {{
                                element.paid_date
                                    ? (element.paid_date | dateFromNumber | date: 'mediumDate')
                                    : (element.created_at | date: 'mediumDate')
                            }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="invoice_id">
                        <th mat-header-cell *matHeaderCellDef>Bill#</th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.bill_fk_id && element.bill && element.bill.bill_number">{{
                                element.bill?.bill_number || 'N/A'
                            }}</span>
                            <span *ngIf="element.bill_fk_id && !element.bill">Bill has been removed</span>
                            <span *ngIf="!element.bill_fk_id && !element.bill">{{ 'N/A' }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef style="text-align: end">Total Amount</th>
                        <td mat-cell *matCellDef="let element" style="text-align: end">
                            {{ element.amount | currency }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedRelatedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedRelatedColumns" class="zp-table-row"></tr>
                </table>
            </div>
        </div>
    </mat-card>

    <div class="d-flex justify-content-end py-2">
        <button
            class="mr-2"
            mat-raised-button
            type="button"
            [rule]="{purchases__manage_vendor_credits: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            *ngIf="isBillsToApply && vendorCredit.status === 'open'"
            (click)="openApplyToBillsDialog()"
        >
            Apply to Bill
        </button>
        <button
            *ngIf="vendorCredit.status === 'draft'"
            [rule]="{purchases__manage_vendor_credits: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            mat-raised-button
            (click)="updateStatus('open')"
            class="mr-2"
        >
            Open
        </button>
        <button
            *ngIf="vendorCredit.status === 'draft' || vendorCredit.status === 'open'"
            [rule]="{purchases__manage_vendor_credits: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            mat-raised-button
            (click)="updateStatus('void')"
        >
            Void
        </button>
    </div>
</section>
