import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IRecurringCreditNote} from '@cyberco-nodejs/zipi-typings';
import {Subject} from 'rxjs';
import {RecurringCreditNotesService} from '../../../services/recurring-credit-notes.service';
import {takeUntil} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {IScrollData} from 'app/models/scroll-data';
import {PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-recurring-credit-notes',
    styleUrls: ['../../../../../../assets/infinite-scroll-table.scss'],
    templateUrl: 'recurring-credit-notes-list.component.html'
})
export class RecurringCreditNotesListComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeRecurring: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;

    scrollData: IScrollData = {
        offset: 0,
        limit: 50,
        sort_column: 'recurrence_title',
        sort_direction: 'desc',
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<IRecurringCreditNote>;
    displayedColumns = [
        'recurrence_title',
        'period',
        'last_credit_note_date',
        'next_credit_note_date',
        'status',
        'total_amount',
        'action'
    ];

    constructor(private recurringCreditsService: RecurringCreditNotesService) {
        this.dataSource = new MatTableDataSource<IRecurringCreditNote>([]);
    }

    ngOnInit() {
        this.initRecurrings();

        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.initRecurrings();
            });
        }
    }

    initRecurrings() {
        this.unsubscribeRecurring.next();

        this.recurringCreditsService
            .getRecurringCreditNotes(this.scrollData)
            .pipe(takeUntil(this.unsubscribeRecurring))
            .subscribe((result) => {
                this.dataSource.data = result.result;
                this.scrollData.total = result._meta.total;
            });
    }

    activateRecurring(id: number) {
        this.recurringCreditsService
            .activateRecurringCreditNote(id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.initRecurrings();
            });
    }

    stopRecurring(id: number) {
        this.recurringCreditsService
            .stopRecurringCreditNote(id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.initRecurrings();
            });
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        this.scrollData.offset = 0;
        this.paginator!.pageIndex = 0;
        this.initRecurrings();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeRecurring.next();
        this.unsubscribeRecurring.complete();
    }
}
