<div class="confirm-payments-content d-flex flex-column">
    <div class="title">Did the checks print correctly?</div>
    <div class="description">
        Please confirm that checks have printed correctly. If they have been printed incorrectly, they will remain in
        the to-be printed check status.
    </div>
    <div class="payments-made">
        <div class="payments-made-header">
            <div class="flex-2">Vendor</div>
            <div class="flex-1">Amount</div>
            <div class="flex-2">Check Number</div>
            <div class="flex-3">Check Printed Correctly</div>
        </div>
        <div class="payments-made-rows">
            <div class="payment-made" *ngFor="let form of paymentsMadeForms.controls" [formGroup]="form">
                <div class="flex-2">{{ form.controls.vendor_name.value }}</div>
                <div class="flex-1">{{ form.controls.amount.value | currency }}</div>
                <div class="flex-2">
                    <mat-form-field style="width: 60%">
                        <input matInput placeholder="" formControlName="check_number" type="text" />
                    </mat-form-field>
                </div>
                <div class="flex-3">
                    <mat-button-toggle-group
                        class="books-toggle-button"
                        formControlName="is_printed_successfully"
                        aria-label="Font Style"
                    >
                        <mat-button-toggle disableRipple="true" [value]="true">Yes</mat-button-toggle>
                        <mat-button-toggle disableRipple="true" [value]="false">No</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="confirm-payments-footer">
    <div class="buttons-row">
        <button mat-raised-button class="mat-focus-indicator mat-raised-button mat-button-base" (click)="cancel()">
            Cancel
        </button>
    </div>
    <div class="buttons-row">
        <button
            mat-raised-button
            class="mat-primary ml-2 button print-btn"
            (click)="save()"
            [disabled]="isChecksSaving"
        >
            Save
        </button>
    </div>
</div>
