<div class="w-100 compensation-profile">
    <h3 class="title">{{ title }}</h3>
    <mat-form-field>
        <h4 *ngIf="!profileEntity" class="title-info">Please select profile</h4>
        <h4 *ngIf="profileEntity && !initialList.length" class="title-info">No data for this profile</h4>
        <mat-chip-list #chipList aria-label="Tags selection">
            <ng-container *ngFor="let item of initialList">
                <ng-container *ngFor="let elements of item.financial_elements">
                    <mat-chip *ngIf="value.includes(elements.id)" (removed)="remove(elements.id)">
                        {{ elements.title }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </ng-container>
            </ng-container>
            <input
                *ngIf="initialList.length"
                #input
                [ngClass]="{hide: singleSelected && value && value.length}"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                (keydown)="checkAndPreventInput($event)"
                (keyup)="search($event)"
            />
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="select($event)">
            <ng-container *ngIf="!singleSelected || (singleSelected && (!value || !value.length))">
                <mat-optgroup
                    *ngFor="let group of list; let i = index"
                    [label]="group.title ? group.title : 'Compensation Profile-' + (i + 1)"
                >
                    <ng-container *ngFor="let elements of group.financial_elements">
                        <mat-option
                            *ngIf="
                                !value.includes(elements.id) &&
                                ['less_then', 'less_then_or_equal'].includes(elements.commonRule.operator)
                            "
                            [value]="elements.id"
                        >
                            {{ elements.title }}
                        </mat-option>
                    </ng-container>
                </mat-optgroup>
            </ng-container>
        </mat-autocomplete>
    </mat-form-field>
</div>
