import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {GenericFormGroup} from '../../../../../../entites/generic.entity';
import {CompanyCalculation} from '../../../../../shared/components/calculations/calculations.model';
import {FinancialTransferEntity} from '../../../../../account-info/compensation/models/financial-transfer.entity';
import {ContactPartLink} from '../../../../../../models/contact-part-link';
import {DealProcessingService} from '../../deal-processing.service';
import {FinancialElementModel} from '../../../../../account-info/compensation/models/financial-element.model';
import {MatDialog} from '@angular/material/dialog';
import {MatExpansionPanel} from '@angular/material/expansion';
import {Subject} from 'rxjs';
import {InvoiceBillModalDialogComponent} from '../../../../../finance/components/invoice-bill-modal-dialog/invoice-bill-modal-dialog.component';
import {takeUntil} from 'rxjs/operators';
import {Deal} from '../../../../../../models/deal';
import {IProduct} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-company-expenses',
    styleUrls: ['../deal-expenses/deal-expenses.component.scss'],
    template: `
        <mat-expansion-panel #panel class="mb-3">
            <mat-expansion-panel-header [collapsedHeight]="'64px'" (click)="clickOnPanel.emit()">
                <mat-panel-title style="align-items: center;">
                    <b class="label">Company</b>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="deal-options">
                <div class="notes">
                    <mat-form-field
                        style="margin-top: 16px;"
                        class="w-100 spaced"
                        *ngIf="companyCalculationFG.controls.notes"
                    >
                        <input
                            matInput
                            placeholder="Disbursement Instructions"
                            [formControl]="companyCalculationFG!.controls.notes!"
                        />
                    </mat-form-field>
                </div>
                <div class="options">
                    <div class="options-body">
                        <button
                            mat-stroked-button
                            style="width: fit-content;"
                            class="mb-3"
                            [disabled]="companyCalculationFG.disabled"
                            [matMenuTriggerFor]="menu"
                        >
                            <mat-icon>add</mat-icon>
                            Add item
                        </button>
                        <mat-menu #menu="matMenu">
                            <button
                                mat-menu-item
                                (click)="addFinancialTransfer(FINANCIAL_TRANSFER_ENTITY.type_SET.income)"
                            >
                                Other Company Income
                            </button>
                            <button
                                mat-menu-item
                                (click)="addFinancialTransfer(FINANCIAL_TRANSFER_ENTITY.type_SET.expense)"
                            >
                                Other Company Expense
                            </button>
                        </mat-menu>
                        <div class="option-item" *ngIf="companyCalculationFG!.controls.company_splits!.length > 0">
                            <h3 class="option-title">Company Compensation</h3>
                            <app-deal-transfers-collection
                                [allowPercents]="true"
                                [showOnlyAmount]="false"
                                [availableValueTypes]="[
                                    'flat_fee',
                                    'percent_of_commission',
                                    'percent_of_split',
                                    'flat_fee_sales_net'
                                ]"
                                [transfersFA]="companyCalculationFG!.controls.company_splits!"
                                [type]="FINANCIAL_TRANSFER_ENTITY.type_SET.transfer"
                                [isExpense]="false"
                                (deleteItem)="removeFinancialTransfer($event)"
                                [mode]="'edit'"
                            ></app-deal-transfers-collection>
                        </div>

                        <div
                            class="compensation-incomes"
                            *ngFor="
                                let compensationIncome of companyCalculationFG!.controls.compensation_incomes?.controls
                            "
                        >
                            <div class="label">
                                <span>
                                    {{ compensationIncome.controls.amount?.value | currency }}
                                    {{
                                        compensationIncome.controls.contribute_to_cap?.value ===
                                        FINANCIAL_TRANSFER_ENTITY.contribute_to_cap_SET.receiver_split
                                            ? 'Company Split'
                                            : 'Company Income'
                                    }}
                                    received from {{ compensationIncome.controls.sender.value.link_title }} as
                                    Compensation Expense
                                </span>
                            </div>
                        </div>

                        <div
                            class="option-item"
                            *ngIf="
                                companyCalculationFG!.controls.company_incomes!.length ||
                                companyCalculationFG!.controls.company_association_incomes!.length
                            "
                        >
                            <h3 class="option-title">Company Income</h3>
                            <app-deal-transfers-collection
                                [isExpense]="false"
                                [allowPercents]="true"
                                [showOnlyAmount]="false"
                                [origin]="FINANCIAL_ELEMENT_ENTITY.type_set.company_income_expense"
                                (deleteItem)="removeFinancialTransfer($event)"
                                [type]="FINANCIAL_TRANSFER_ENTITY.type_SET.transfer"
                                buttonLabel="Other Company Income"
                                [availableValueTypes]="['flat_fee', 'percent_of_company_split']"
                                [transfersFA]="companyCalculationFG!.controls.company_incomes!"
                            ></app-deal-transfers-collection>

                            <div
                                class="association-item"
                                *ngFor="
                                    let associatedTransfer of companyCalculationFG.controls.company_association_incomes
                                        ?.controls
                                "
                            >
                                <div class="label">
                                    <span>
                                        {{ associatedTransfer.controls.amount.value | currency }} has been associated to
                                        this deal for reporting purposes, reference "{{
                                            associatedTransfer.controls.label.value
                                        }}" on
                                        <a
                                            href="javascript:"
                                            (click)="
                                                openViewDialog(
                                                    associatedTransfer.controls.connected__invoice_fk_id?.value,
                                                    'invoice'
                                                )
                                            "
                                            matTooltip="View Invoice"
                                            >{{
                                                associatedTransfer.controls.connected_invoice?.value?.invoice_number
                                            }}</a
                                        >
                                        ({{
                                            associatedTransfer.controls.connected_invoice?.value?.summary_status
                                                | fromSnakeCase
                                        }})
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="option-item"
                            *ngIf="
                                companyCalculationFG!.controls.company_expenses!.length ||
                                companyCalculationFG!.controls.company_association_expenses!.length
                            "
                        >
                            <h3 class="option-title">Company Expense</h3>
                            <app-deal-transfers-collection
                                [isExpense]="true"
                                [allowPercents]="true"
                                [showOnlyAmount]="false"
                                [origin]="FINANCIAL_ELEMENT_ENTITY.type_set.company_income_expense"
                                (deleteItem)="removeFinancialTransfer($event)"
                                [type]="FINANCIAL_TRANSFER_ENTITY.type_SET.transfer"
                                buttonLabel="Other Company Expense"
                                [availableValueTypes]="['flat_fee', 'percent_of_company_split']"
                                [transfersFA]="companyCalculationFG!.controls.company_expenses!"
                            ></app-deal-transfers-collection>

                            <div
                                class="association-item"
                                *ngFor="
                                    let associatedTransfer of companyCalculationFG.controls.company_association_expenses
                                        ?.controls
                                "
                            >
                                <div class="label">
                                    <span>
                                        {{ associatedTransfer.controls.amount.value | currency }} has been associated to
                                        this deal for reporting purposes, reference "{{
                                            associatedTransfer.controls.label.value
                                        }}" on
                                        <a
                                            href="javascript:"
                                            (click)="
                                                openViewDialog(
                                                    associatedTransfer.controls.connected__bill_fk_id?.value,
                                                    'invoice'
                                                )
                                            "
                                            matTooltip="View Invoice"
                                            >{{ associatedTransfer.controls.connected_bill?.value?.bill_number }}</a
                                        >
                                        ({{
                                            associatedTransfer.controls.connected_bill?.value?.summary_status
                                                | fromSnakeCase
                                        }})
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="option-item" *ngIf="companyCalculationFG!.controls.commission_deductions!.length">
                            <h3 class="option-title">Deduction Credits</h3>
                            <div
                                class="commission-deduction-item"
                                *ngFor="
                                    let financialTransfer of companyCalculationFG.controls.commission_deductions
                                        ?.controls
                                "
                            >
                                <div class="label">
                                    <span *ngIf="financialTransfer.controls.connected__invoice_fk_id?.value">
                                        {{ financialTransfer.controls.amount.value | currency }}
                                        deduction credit has been contributed to "{{
                                            financialTransfer.controls.label.value
                                        }}" on
                                        <a
                                            href="javascript:"
                                            (click)="
                                                openViewDialog(
                                                    financialTransfer.controls.connected__invoice_fk_id?.value,
                                                    'invoice'
                                                )
                                            "
                                            matTooltip="View Invoice"
                                            >{{
                                                financialTransfer.controls.connected_invoice?.value?.invoice_number
                                            }}</a
                                        >
                                        ({{
                                            financialTransfer.controls.connected_invoice?.value?.summary_status
                                                | fromSnakeCase
                                        }})
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-company-preview
                        [companyCalculationFG]="companyCalculationFG"
                        [dealSystemStatus]="dealFG!.controls.system_status!.value"
                    ></app-company-preview>
                </div>
            </div>
        </mat-expansion-panel>
    `
})
export class CompanyExpensesComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @ViewChild('panel') panel: MatExpansionPanel | null = null;
    @Output() clickOnPanel = new EventEmitter();

    public FINANCIAL_TRANSFER_ENTITY = FinancialTransferEntity;
    public FINANCIAL_ELEMENT_ENTITY = FinancialElementModel;
    defaultCompanySplitFT: GenericFormGroup<FinancialTransferEntity> | null =
        new GenericFormGroup<FinancialTransferEntity>(new FinancialTransferEntity());
    dealFG: GenericFormGroup<Deal> = new GenericFormGroup<Deal>(new Deal());

    @Input() companyCalculationFG: GenericFormGroup<CompanyCalculation> = new GenericFormGroup(
        new CompanyCalculation(),
        'change'
    );
    @Input() dealId: string | null | undefined = null;

    @Input() set dealFormGroup(dealFormGroup: GenericFormGroup<Deal>) {
        this.dealFG = dealFormGroup;
        dealFormGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            const rawDeal = dealFormGroup.getRawValue();
            const primarySalesEntity = rawDeal.sales_entities.find((se) => se.is_primary);
            if (primarySalesEntity) {
                const companySplitTransfer = dealFormGroup.controls.financial_transfers!.controls.find((transferFG) => {
                    const transferRaw = transferFG.getRawValue();
                    return (
                        transferRaw.type === FinancialTransferEntity.type_SET.company_split_individual &&
                        transferRaw.sender!.contact_id === primarySalesEntity.contact.id &&
                        transferRaw.unique_hash !== 'NO-HASH'
                    );
                });
                if (companySplitTransfer) {
                    this.defaultCompanySplitFT = null;
                }
            }
        });
    }

    constructor(
        protected dealProcessing: DealProcessingService,
        public dialog: MatDialog
    ) {}

    // removeAncillaryIncome(event: GenericFormGroup<FinancialTransferEntity>) {
    //     const foundIdx = this.companyCalculationFG.controls.ancillary_incomes.controls.findIndex((element) => {
    //         return element.controls._id === event.controls._id;
    //     });
    //
    //     if (foundIdx || foundIdx === 0) {
    //         this.companyCalculationFG.controls.ancillary_incomes.removeAt(foundIdx);
    //     }
    // }
    //
    // removeAncillaryExpense(event) {
    //     const foundIdx = this.companyCalculationFG.controls.ancillary_expenses.controls.findIndex((element) => {
    //         return element.controls._id === event.controls._id;
    //
    //     });
    //
    //     if (foundIdx || foundIdx === 0) {
    //         this.companyCalculationFG.controls.ancillary_expenses.removeAt(foundIdx);
    //     }
    // }

    addFinancialTransfer(type: string) {
        let financialTransferFG: GenericFormGroup<FinancialTransferEntity> = new GenericFormGroup(
            new FinancialTransferEntity()
        );

        switch (type) {
            case FinancialTransferEntity.type_SET.income:
                financialTransferFG = new GenericFormGroup(
                    new FinancialTransferEntity()
                        .setType(FinancialTransferEntity.type_SET.transfer)
                        .setOrigin(FinancialElementModel.type_set.company_income_expense)
                        .setOriginIsExpense(false)
                );

                financialTransferFG.controls.receiver!.patchValue(
                    new ContactPartLink()
                        .setLinkTitle(
                            this.dealProcessing.currentProfile!.company!.company_group!.contact!.display_name!
                        )
                        .setContactId(this.dealProcessing.currentProfile!.company!.company_group!.contact!.id!)
                );

                this.companyCalculationFG.controls.company_incomes!.push(financialTransferFG);

                break;
            case FinancialTransferEntity.type_SET.expense:
                financialTransferFG = new GenericFormGroup(
                    new FinancialTransferEntity()
                        .setType(FinancialTransferEntity.type_SET.transfer)
                        .setOrigin(FinancialElementModel.type_set.company_income_expense)
                        .setOriginIsExpense(true)
                );

                financialTransferFG.controls.sender!.patchValue(
                    new ContactPartLink()
                        .setLinkTitle(
                            this.dealProcessing.currentProfile!.company!.company_group!.contact!.display_name!
                        )
                        .setContactId(this.dealProcessing.currentProfile!.company!.company_group!.contact!.id!)
                );

                this.companyCalculationFG.controls.company_expenses!.push(financialTransferFG);
                break;
        }
        this.dealProcessing.addFinancialTransfer(financialTransferFG);
    }

    removeFinancialTransfer(financialTransferFG: GenericFormGroup<FinancialTransferEntity>) {
        if (financialTransferFG) {
            if (!financialTransferFG.controls.is_enforced!.value) {
                this.dealProcessing.deleteNotEnforcedTransfer(financialTransferFG);
                this.dealProcessing.syncFinancialTransfers();
            } else {
                this.dealProcessing.deleteFinancialTransfer(financialTransferFG);
            }
        }
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }

    changeCompanyPayoutLabel(product: IProduct) {
        this.defaultCompanySplitFT!.controls.label!.patchValue(product.name);

        const companySplitIndividualTransfers = this.dealFG.controls.financial_transfers!.controls.filter(
            (transferFG) => {
                const transferRaw = transferFG.getRawValue();
                return (
                    transferRaw.type === FinancialTransferEntity.type_SET.company_split_individual &&
                    transferRaw.unique_hash === 'NO-HASH'
                );
            }
        );
        companySplitIndividualTransfers.forEach((transferFG) => {
            transferFG.controls.product_fk_id!.patchValue(product.id, {emitEvent: false});
        });
    }

    openViewDialog(entityId: number, type: string) {
        if (!['invoice', 'bill'].includes(type)) {
            return;
        }
        const data: {invoiceId?: number; billId?: number} = {};

        if (type === 'invoice') {
            data.invoiceId = entityId;
        }
        if (type === 'bill') {
            data.billId = entityId;
        }

        const dialogRef = this.dialog.open(InvoiceBillModalDialogComponent, {
            position: {
                top: '10px',
                right: '10px'
            },
            height: '98%',
            width: '100vw',
            maxWidth: '98vw',
            panelClass: 'full-screen-modal',
            data: data
        });
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.clickOnPanel.complete();
    }
}
