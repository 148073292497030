import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GenericFormGroup} from '../../../../../entites/generic.entity';
import {SplitPlanModel} from '../../models/split-plan.model';

@Component({
    selector: 'app-company-split-plan',
    template: `
        <div style="margin-top: 16px">
            <mat-form-field>
                <mat-select placeholder="Split Type" [formControl]="splitForm!.controls.type!">
                    <mat-option *ngFor="let type of object_keys(compensation_type_LABLES)" [value]="type">
                        {{ compensation_type_LABLES[type] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Split Amount" [formControl]="splitForm!.controls.amount!" />
            </mat-form-field>
        </div>
    `
})
export class ProvidesSplitPlanComponent implements OnInit, OnChanges {
    @Input() splitForm: GenericFormGroup<SplitPlanModel> | undefined = new GenericFormGroup(new SplitPlanModel());
    @Input() disabled: boolean = false;

    public compensation_type_LABLES: {[key: string]: string} = {
        flat: 'Flat',
        percent: 'Percent'
    };

    object_keys(obj: {[key: string]: any}): string[] {
        return Object.keys(obj);
    }

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.disabled && changes.disabled.currentValue) {
            this.splitForm!.controls.type?.disable({emitEvent: false});
            this.splitForm!.controls.amount?.disable({emitEvent: false});
        } else if (changes.disabled && !changes.disabled.currentValue) {
            this.splitForm!.controls.type?.enable({emitEvent: false});
            this.splitForm!.controls.amount?.enable({emitEvent: false});
        }
    }
}
