import {GenericEntity} from '../../../../../../entites/generic.entity';

class YodataReportCurrencyTypeValueModel extends GenericEntity {
    currency: string | null = null;
    type: string | null = null;
    value: number | null = null;
}

class YodataReportTransactionEntryRecipientModel extends GenericEntity {
    id: string | null = null;
    roleName: string | null = null;
    type: string | null = null;
    identifier: {bmsAgentId: string} | null = null;
}

export class YodataReportParticipantModel extends GenericEntity {
    type: string | null = null;
    roleName: string | null = null;
    position: number | null = null;
    givenName: string | null = null;
    familyName: string | null = null;
    additionalName: string | null = null;
    email: string | null = null;
    telephone: string | null = null;
    affiliation: string | null = null;
}

export class YodataReportTransactionEntryModel extends GenericEntity {
    _IS_PRIMARY: boolean | null = null;
    SE_NAME: string | null = null;
    recipient: YodataReportTransactionEntryRecipientModel | null = null;
    salesProductionGCI: YodataReportCurrencyTypeValueModel | null = null;
    salesProductionUnit: number | null = null;
    type: string | null = null;
}

class YodataReportAddressModel extends GenericEntity {
    type: string | null = null;
    propertyType: string | null = null;
    addressCountry: string | null = null;
    addressLocality: string | null = null;
    addressRegion: string | null = null;
    postalCode: string | null = null;
    streetAddress: string | null = null;
}

class YodataReportAdditionalPropertyModel extends GenericEntity {
    batchId: string | null = null;
    transactionSequence: string | null = null;
    unimprovedLandFlag: boolean | null = null;
}

class YodataReportDataObjectModel extends GenericEntity {
    additionalProperty: YodataReportAdditionalPropertyModel | null = null;
    commissionDate: string | null = null;
    closeDate: string | null = null;
    purchaseContractDate: string | null = null;
    reportingOffice: string | null = null;
    listingOffice: string | null = null;
    buyerOffice: string | null = null;

    totalSalesProductionGCI: YodataReportCurrencyTypeValueModel | null = null;
    totalSalesProductionGCIDeduction: YodataReportCurrencyTypeValueModel | null = null;
    closePrice: YodataReportCurrencyTypeValueModel | null = null;
    identifier: {bmsTransactionId: string} | null = null;

    // ADDRESS
    object: YodataReportAddressModel | null = null;

    transactionEntry: YodataReportTransactionEntryModel[] | null = null;
    participant: YodataReportParticipantModel[] | null = null;

    transactionStatus: string | null = null;
    transactionType: string | null = null;
    type: string | null = null;
}

export class YodataReportModel extends GenericEntity {
    topic: string | null = null;
    recipient: string | null = null;
    source: string | null = null;
    data: {type: string; object: YodataReportDataObjectModel} | null = null;

    static get helpers() {
        return Object.assign(
            {
                props: Object.getOwnPropertyNames(new this()).reduce((acc: {[key: string]: any}, curr) => {
                    acc[curr] = curr;
                    return acc;
                }, {}),
                build: {}
            },
            GenericEntity.helpers
        );
    }

    // static get status_set() {
    //     return {
    //         draft: 'draft',
    //         active: 'active',
    //         imported: 'imported',
    //     };
    // }
}
