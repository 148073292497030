import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AddonConnectedProfile, AddonsApi} from '../../../../profile/addons/addons.api';
import {MarketplaceApi} from '../../../marketplace/marketplace.api';
import {AddonsSource} from '../../../../profile/addons/addons.source';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AddonDeactivateConfirmationDialogComponent} from '../../../../profile/addons/addon-deactivate-confirmation.dialog';
import {MatDialog} from '@angular/material/dialog';
import {AddonInstanceModel} from '../../../../profile/addons/addons.models';
import {Sort} from '@angular/material/sort';

@Component({
    selector: 'app-addon-connection',
    templateUrl: './addon-connection.component.html',
    styleUrls: ['./addon-connection.component.scss']
})
export class AddonConnectionComponent implements OnInit, AfterViewInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @ViewChild('listSection') listSection: ElementRef | undefined;
    private searchTimerId: NodeJS.Timeout | null = null;
    public dataSource: AddonConnectedProfile[] = [];
    public displayedColumns: string[] = ['name', 'email', 'created_at', 'description', 'connected_status'];
    public connectedStatus: string = 'all';
    public searchProfile: string = '';
    public filters: {[key: string]: string} = {};
    public isFetching: boolean = false;
    public pageIndex: number = 0;
    public pageSize: number = 15;
    public total: number | null = 0;
    public isLoaded: boolean = false;
    public companySettingsBtnHidden: boolean = false;
    public showActionsRow: boolean = false;
    public showManageUsersBtn: boolean = false;
    public manageUsersBtnRules: any = {};
    public disconnectUserBtnRules: any = {};
    public manageUsersBtnUrl: string | null = null;

    public sort: {
        column?: string;
        direction?: string;
    } = {};
    public listOfConnectionStatus: any[] = [
        {
            label: 'All',
            value: 'all'
        },
        {
            label: 'Activated',
            value: 'activated'
        },
        {
            label: 'Connected',
            value: 'connected'
        },
        {
            label: 'N/A',
            value: 'n/a'
        }
    ];

    public addonCompanySettingsUrl: string | null = null;
    // public availableAddon: {[key: string]: string} = {
    //     skyslope: 'skyslope_integration',
    //     dotloop: 'dotloop_integration',
    // };
    public currentAddonProfileInstance: AddonInstanceModel | null = null;
    public currentAddonCompanyInstance: AddonInstanceModel | null = null;
    public ADDON_SLUG: string | null = null;

    constructor(
        public router: Router,
        protected addonsApi: AddonsApi,
        protected activatedRoute: ActivatedRoute,
        protected addonsSource: AddonsSource,
        protected marketplaceApi: MarketplaceApi,
        protected dialog: MatDialog
    ) {}

    /**
     * On init
     */
    ngOnInit() {
        const pathFromZipiUrl = this.router.url
            .split('/')
            .filter((str) => !!str)
            .join('/');

        // run this after we detect which exactly addon we use
        // this.getData();

        // company-settings link-button
        this.addonsSource.addonsChangeEvent.pipe(takeUntil(this.unsubscribe)).subscribe((instances) => {
            // const activeAddonSlug: string = this.availableAddon[this.activatedRoute.snapshot.params.addon];

            // FIND addon by REDIRECT_SETTINGS_URL
            const activeAddonProfileInstance = instances.find(
                (instance) =>
                    instance.activated &&
                    instance.profile_fk_id &&
                    instance.addon &&
                    instance.addon.settings &&
                    instance.addon.settings.urls &&
                    instance.addon.settings.urls.redirect_settings === pathFromZipiUrl
            );
            this.currentAddonProfileInstance = activeAddonProfileInstance || null;

            this.currentAddonCompanyInstance =
                instances.find(
                    (instance) =>
                        (instance as any).company_fk_id &&
                        instance.addon &&
                        instance.addon.settings &&
                        instance.addon.settings.urls &&
                        instance.addon.settings.urls.redirect_settings === pathFromZipiUrl
                ) || null;

            const addonInstance = this.currentAddonProfileInstance || this.currentAddonCompanyInstance;

            // if not enabled_company_settings - disable settings btn
            if (
                !addonInstance ||
                !addonInstance.addon ||
                !addonInstance.addon.settings ||
                !addonInstance.addon.settings.enabled_company_settings
            ) {
                this.companySettingsBtnHidden = true;
                return;
            }

            this.ADDON_SLUG = addonInstance.addon.slug;
            this.getData();

            // add 'Disconnect User' action
            if (addonInstance.addon.settings && addonInstance.addon.settings.is_disconnect_user_button) {
                this.showActionsRow = true;
                if (!this.displayedColumns.find((col) => col === 'actions')) {
                    this.displayedColumns = this.displayedColumns.concat(['actions']);
                }
                const addonRule = this.marketplaceApi._getAddonAccessRuleByAddonSlug(this.ADDON_SLUG);
                this.disconnectUserBtnRules = {...addonRule, company_settings__manage_users: true};
            }

            if (addonInstance.addon.settings && addonInstance.addon.settings.is_manage_users_button) {
                this.showManageUsersBtn = true;
                const addonRule = this.marketplaceApi._getAddonAccessRuleByAddonSlug(this.ADDON_SLUG);
                this.manageUsersBtnRules = {...addonRule, company_settings__manage_users: true};
                if (
                    activeAddonProfileInstance &&
                    activeAddonProfileInstance.addon &&
                    activeAddonProfileInstance.addon.addon_url
                ) {
                    let path = '/integration/company-users';
                    if (activeAddonProfileInstance.addon.settings.urls.manage_users_url) {
                        path = activeAddonProfileInstance.addon.settings.urls.manage_users_url;
                    }
                    this.manageUsersBtnUrl = activeAddonProfileInstance.addon.addon_url + path;
                }
            }

            if (
                activeAddonProfileInstance &&
                activeAddonProfileInstance.addon &&
                activeAddonProfileInstance.addon.settings.urls.redirect_company_settings_url
            ) {
                this.addonCompanySettingsUrl =
                    activeAddonProfileInstance.addon.addon_url +
                    '/' +
                    activeAddonProfileInstance.addon.settings.urls.redirect_company_settings_url;
            }
        });
    }

    /**
     * After View Init
     */
    ngAfterViewInit(): void {
        this.addScrollListener();
    }

    /**
     * Apply filters
     */
    applyFilter = () => {
        this.pageIndex = 0;
        this.total = null;

        this.filters = {};
        if (this.connectedStatus && this.connectedStatus !== 'all') {
            this.filters.connected_status = this.connectedStatus;
        }
        if (this.searchProfile && this.searchProfile !== '') {
            this.filters.search_profile = this.searchProfile;
        }
        this.getData();
        this.addScrollListener();
    };

    /**
     * On sort change
     * @param event
     */
    onSortChange = (event: Sort) => {
        this.pageIndex = 0;
        this.total = null;

        let column = event.active;
        if (event.active === 'name') {
            column = 'first_name';
        }
        this.sort.column = column;
        this.sort.direction = event.direction;
        this.getData();
        this.addScrollListener();
    };

    /**
     * Search by profile
     */
    searchByProfile = () => {
        this.pageIndex = 0;
        this.total = null;

        if (this.searchTimerId) {
            clearTimeout(this.searchTimerId);
        }

        this.searchTimerId = setTimeout(() => {
            this.applyFilter();
        }, 700);
    };

    /**
     * Add scrool listener
     */
    addScrollListener() {
        setTimeout(() => {
            if (!this.listSection) {
                return;
            }
            this.listSection.nativeElement.parentElement.parentElement.parentElement.removeAllListeners();
            this.listSection.nativeElement.parentElement.parentElement.parentElement.addEventListener(
                'scroll',
                (event: any) => {
                    // note: do "-10" correction to care about "almost on bottom" case
                    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 10) {
                        this.nextPageFetch();
                    }
                }
            );
        });
    }

    /**
     * Next page fetch
     */
    nextPageFetch() {
        if (this.isFetching) {
            return;
        }

        this.pageIndex = this.pageIndex + 1;
        this.getData();
    }

    /**
     * Get data
     */
    getData = () => {
        if (this.isFetching) {
            return;
        }

        this.isFetching = true;
        const params = this.prepareParams();
        // if (this.activatedRoute.snapshot.params.addon && this.availableAddon[this.activatedRoute.snapshot.params.addon]) {
        if (this.ADDON_SLUG) {
            this.addonsApi
                .getCompanyAddonsInstances(this.ADDON_SLUG, params)
                .then((response) => {
                    response = response.map((item) => {
                        const status = item.connected_status;
                        switch (item.connected_status) {
                            case 'n/a':
                                item.connected_status = status.toUpperCase();
                                break;
                            case 'activated':
                            case 'connected':
                                item.connected_status = `${status.charAt(0).toUpperCase()}${status.slice(1)}`;
                                break;
                            default:
                        }
                        return item;
                    });
                    if (this.pageIndex === 0) {
                        this.dataSource = response;
                    } else {
                        this.dataSource = this.dataSource.concat(response);
                    }

                    this.isFetching = false;
                    this.isLoaded = true;

                    if (this.listSection) {
                        const parent = this.listSection.nativeElement.parentElement.parentElement.parentElement;

                        window.setTimeout(() => {
                            if (response.length >= this.pageSize && parent.offsetHeight === parent.scrollHeight) {
                                this.nextPageFetch();
                            }
                        }, 500);

                        if (response.length < this.pageSize) {
                            parent.removeAllListeners();
                            this.total = this.dataSource.length;
                        }
                    }
                })
                .catch(() => {
                    this.isFetching = false;
                    this.isLoaded = true;
                });
        }
    };

    /**
     * Prepare params
     */
    prepareParams = () => {
        const params: {[key: string]: string | number | string[]} = {
            offset: Number(this.pageSize * this.pageIndex),
            limit: Number(this.pageSize)
        };

        const filters = [];
        for (const key in this.filters) {
            if (!this.filters.hasOwnProperty(key)) {
                continue;
            }
            filters.push(`${key}=${this.filters[key]}`);
        }

        if (filters.length) {
            params['filters'] = filters;
        }
        if (this.sort.column) {
            params['sort_by'] = this.sort.column;
        }
        if (this.sort.direction) {
            params['sort_direction'] = this.sort.direction;
        }
        return params;
    };

    disconnectAddonForUser(connectedUser: AddonConnectedProfile) {
        const dialogRef = this.dialog.open(AddonDeactivateConfirmationDialogComponent, {
            data: {
                title: `Disconnect App for User ${connectedUser.first_name} ${connectedUser.last_name}`,
                description: 'It will disconnect this App for this user',
                note: 'All connections and configurations for this user will be LOST and nothing further will be synced.'
            }
        });
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((reallyDeactivate) => {
                if (reallyDeactivate) {
                    if (!this.currentAddonProfileInstance || !this.currentAddonProfileInstance.addon) {
                        return;
                    }
                    this.addonsApi
                        .deactivateAddon(this.currentAddonProfileInstance.addon, connectedUser.profile_id)
                        .then(() => {
                            // update connected users list
                            this.dataSource = this.dataSource.filter(
                                (user) => user.profile_id !== connectedUser.profile_id
                            );
                            // update total of connected users list
                            this.total = this.dataSource.length;
                        });
                } else {
                    // dialog was just closed, no need to save anything
                    return;
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
