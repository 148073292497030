import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {Subject} from 'rxjs';
import {NotificationsService} from 'angular2-notifications';
import {
    IInvoice,
    IPaymentGateway,
    IPaymentReceivedMadeRequestObject,
    ITransactionExternal
} from '@cyberco-nodejs/zipi-typings';
import {takeUntil, tap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {BankingService} from 'app/services/api/finance/banking.service';
import {GatewayService} from '../../../../profile/services/gateway.service';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {PAYMENT_MODES} from 'app/local-typings';
import {GenericFormArray} from 'app/entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {ProfilesService} from 'app/services/profiles.service';
import {ContactCreateDialogComponent} from '../../../../contacts/contact-dialogs/contact-create-dialog/contact-create-dialog.component';
import {Profile} from 'app/models/profile';
import * as moment from 'moment';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';

export class CustomFormArray extends UntypedFormArray {
    public controls: UntypedFormGroup[];

    constructor(inputs?: UntypedFormGroup[]) {
        super(inputs || []);
        this.controls = inputs || [];
    }
}

@Component({
    selector: 'app-form-customer-payment',
    template: `
        <div [formGroup]="formGroup">
            <div class="d-flex align-items-baseline">
                <app-company-compensation-combined-picker
                    style="width:100%; margin-right: 8px;"
                    [availableTypes]="['contact']"
                    [title]="'Select Customer *'"
                    [only_compensation_groups]="false"
                    [singleSelected]="true"
                    [bold_style]="false"
                    [nodesFA]="moneySenderCtrlArr"
                >
                </app-company-compensation-combined-picker>
                <button type="button" mat-icon-button matSuffix (click)="contactCreate()" matTooltip="Create Contact">
                    <mat-icon>person_add</mat-icon>
                </button>
            </div>

            <mat-form-field class="w-100">
                <input matInput formControlName="reference" placeholder="Reference#" type="text" autocomplete="off" />
            </mat-form-field>

            <app-date-picker [placeholder]="'Date'" [dateControl]="formGroup.controls.paid_date"></app-date-picker>

            <mat-form-field class="w-100">
                <mat-label>Amount</mat-label>
                <input
                    [maskito]="currencyMaskitoMask"
                    matInput
                    type="text"
                    formControlName="amount"
                    matTooltipPosition="above"
                    placeholder="Amount"
                    autocomplete="off"
                    required
                />
            </mat-form-field>
            <div
                [formGroup]="formGroup"
                *ngIf="
                    formGroup.controls.payment_mode &&
                    formGroup.controls.payment_mode.value &&
                    formGroup.controls.payment_mode.value === 'check_record'
                "
            >
                <div class="w-100">
                    <mat-form-field class="w-100">
                        <input matInput autocomplete="off" type="search" formControlName="check_number" />
                        <mat-label>Check Number</mat-label>
                    </mat-form-field>
                </div>
                <div class="w-100">
                    <mat-form-field class="w-100">
                        <textarea matInput type="text" formControlName="memo"> </textarea>
                        <mat-label>Memo</mat-label>
                    </mat-form-field>
                </div>
            </div>
            <mat-form-field class="w-100">
                <mat-select formControlName="payment_mode" required>
                    <ng-container *ngFor="let mode of paymentModes">
                        <mat-option [value]="mode.slug" *ngIf="mode.is_selectable">
                            <span>{{ mode.title }}</span>
                        </mat-option>
                    </ng-container>
                </mat-select>
                <mat-placeholder>Received Via</mat-placeholder>
            </mat-form-field>

            <div *ngIf="invoicesArray.length !== 0" class="invoices-list">
                <div class="d-flex justify-content-between">
                    <span class="text-muted">Invoice Details</span>
                    <span class="text-muted">Payment</span>
                </div>
                <ng-container *ngFor="let invoice of invoicesArray.controls; index as idx; first as first">
                    <div class="invoice-item d-flex justify-content-between" [formGroup]="invoice">
                        <div class="invoice-details">
                            <p class="m-0">#{{ invoice.value.invoice_number }}</p>
                            <p class="m-0">Due {{ invoice.value.due_date | dateFromNumber | date: 'mediumDate' }}</p>
                            <p class="m-0">Balance: {{ invoice.value.balance | currency: 'USD' : 'symbol' }}</p>
                        </div>
                        <div class="payment">
                            <a class="pay-full" (click)="payInFull(invoice)">Pay in Full</a>
                            <mat-form-field class="w-100">
                                <input
                                    [maskito]="currencyMaskitoMask"
                                    matInput
                                    type="text"
                                    formControlName="payment"
                                    name="payment"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="d-flex justify-content-end" *ngIf="invoicesArray.getRawValue().length > 0">
            <div class="payment-summary mb-3 p-3 text-right">
                <div class="row">
                    <div class="col-6">Amount Paid:</div>
                    <div class="col-6">{{ amountReceived | currency: 'USD' : 'symbol' }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Payments amount:</div>
                    <div class="col-6">{{ paymentsAmount | currency: 'USD' : 'symbol' }}</div>
                </div>
                <div class="row">
                    <div class="col-6 ">Amount in excess:</div>
                    <div class="col-6">{{ excessAmount | currency: 'USD' : 'symbol' }}</div>
                </div>
            </div>
        </div>
        <div class="footer-row">
            <button
                mat-raised-button
                class="mat-primary"
                [disabled]="createDisabled || excessAmount < 0 || !ledgerAccountId"
                (click)="save()"
            >
                Create
            </button>
            <button mat-button class="mat-primary" (click)="closeSidebar.emit()">Cancel</button>
        </div>
    `,
    styleUrls: ['add-transaction-form.component.scss']
})
export class FormCustomerPaymentComponent implements OnInit, OnDestroy {
    @Output() closeSidebar: EventEmitter<void> = new EventEmitter<void>();
    @Output() create: EventEmitter<IPaymentReceivedMadeRequestObject> =
        new EventEmitter<IPaymentReceivedMadeRequestObject>();
    @Input() transactionType: any;
    @Input() createDisabled: boolean = false;
    @Input() bankTransaction: ITransactionExternal | undefined;
    @Input() ledgerAccountId: number | null = null;

    private unsubscribe: Subject<void> = new Subject();

    invoicesArray: CustomFormArray = new CustomFormArray([]);
    formGroup: UntypedFormGroup = this.fb.group({
        money_sender__contact_fk_id: [null, [Validators.required]],
        reference: [null],
        paid_date: [null, Validators.required],

        amount: [null, [Validators.required, this.amountValidator()]],

        payment_mode: [null, [Validators.required]],
        check_number: [null, []],
        memo: [null, []]
    });
    currencyMaskitoMask = currencyMaskitoOptions;

    profiles: Profile[] = [];
    gateway: IPaymentGateway | undefined;
    invoices: IInvoice[] = [];
    paymentModes = PAYMENT_MODES;

    amountReceived: number = 0;
    paymentsAmount: number = 0;
    excessAmount: number = 0;

    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);

    constructor(
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService,
        public dialog: MatDialog,
        private profileService: ProfilesService,
        private bankingService: BankingService,
        private gatewayService: GatewayService,
        private invoicesService: InvoicesService
    ) {}

    ngOnInit() {
        this.gatewayService
            .getCompanySystemGateway()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((gateway) => {
                this.gateway = gateway;
            });

        this.profileService.getMyCompanyProfiles().then((response) => {
            this.profiles = response;
        });

        this.formGroup.controls.money_sender__contact_fk_id.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((id) => {
                this.invoicesArray.clear();

                if (id) {
                    this.invoicesService
                        .getInvoicesForCreditsApply(id)
                        .pipe(
                            tap((invoices) =>
                                invoices.map((invoice: IInvoice) => {
                                    const arrItem = this.fb.group({
                                        invoice_id: invoice.invoice_id,
                                        invoice_number: invoice.invoice_number,
                                        due_date: invoice.due_date,
                                        payment: 0,
                                        balance: invoice.pending_balance
                                    });
                                    arrItem
                                        .get('payment')!
                                        .valueChanges.pipe(takeUntil(this.unsubscribe))
                                        .subscribe(() => this.formGroup.get('amount')!.updateValueAndValidity());

                                    this.invoicesArray.push(arrItem);
                                })
                            ),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe();
                }
            });

        if (this.bankTransaction) {
            this.formGroup.controls.amount.patchValue(this.bankTransaction.amount);
            this.formGroup.controls.amount.disable();
            this.formGroup.controls.paid_date.patchValue(this.bankTransaction.date);
        } else {
            this.formGroup.controls.paid_date.patchValue(moment().format('YYYYMMDD'));
        }

        this.moneySenderCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            if (values && values.length > 0) {
                // this.selectMoneySender(values[0].target_id);
                this.formGroup.controls.money_sender__contact_fk_id.patchValue(values[0].target_id);
            } else {
                // this.deselectCustomer();
                this.formGroup.controls.money_sender__contact_fk_id.patchValue(null);
            }
        });
    }

    amountValidator(): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} | null => {
            if (!control.parent) {
                return null;
            }

            const value = Number(cleanCurrencyString(control.value));
            const invoicesArray = this.invoicesArray.getRawValue();

            let sumOfPayments = 0;

            invoicesArray.map((invoice) => {
                sumOfPayments += Number(cleanCurrencyString(invoice.payment));
            });

            this.amountReceived = value;
            this.paymentsAmount = sumOfPayments;
            this.excessAmount = value - sumOfPayments;

            this.validatePayments();

            return value >= sumOfPayments ? null : {amount: {value}};
        };
    }

    validatePayments() {
        this.invoicesArray.controls.forEach((control) => {
            const payment = Number(cleanCurrencyString(control.get('payment')?.value));

            if (payment > 0 && (this.excessAmount < 0 || payment > control.get('balance')?.value)) {
                control.get('payment')!.setErrors({incorrect: true});
            } else {
                control.get('payment')!.setErrors(null);
            }
        });
    }

    private validateForm() {
        this.formGroup.get('amount')!.updateValueAndValidity();

        if (!this.formGroup.valid) {
            this.ntfs.warn('Vendor Template is not valid');
            return false;
        }

        return true;
    }

    payInFull(itemGroup: AbstractControl) {
        const amount = cleanCurrencyString(itemGroup.get('balance')?.value);

        itemGroup.get('payment')!.patchValue(amount);
    }

    save() {
        if (!this.validateForm() || this.createDisabled) {
            return;
        }
        const dataRaw = this.formGroup.getRawValue();
        const invoicesRaw = this.invoicesArray
            .getRawValue()
            .filter((item) => Number(cleanCurrencyString(item.payment)) > 0)
            .map((invoice) => ({
                entity_id: invoice.invoice_id,
                payment_id: null,
                amount: Number(cleanCurrencyString(invoice.payment)),
                entity_transfer_id: null,
                entity: null
            }));

        this.create.emit({
            money_sender__contact_fk_id: dataRaw.money_sender__contact_fk_id,
            money_receiver__contact_fk_id: null,
            paid_date: dataRaw.paid_date,
            scheduled_date: null,
            amount: Number(cleanCurrencyString(dataRaw.amount)),
            pay_to__ledger_account_fk_id: this.ledgerAccountId,
            paid_by__ledger_account_fk_id: null,
            pay_to__payment_method_fk_id: null,
            paid_by__payment_method_fk_id: null,
            payments: invoicesRaw,
            payment_mode: dataRaw.payment_mode,
            payment_number: null,
            reference: dataRaw.reference,
            notes: null,

            check_info:
                dataRaw.payment_mode === 'check_record'
                    ? {
                          memo: dataRaw.memo,
                          check_number: dataRaw.check_number,
                          check_status: 'uncleared',
                          print_status: 'unknown'
                      }
                    : null,
            is_create_multiple_payments: false,
            matched__transaction_external_id: null,
            deposit_release_id: null,
            is_locked_for_applying: false,
            allow_auto_apply: false,
            source__deal_fk_id: null,
            sender_velocity: null,
            restrict_downgrade: false
        });
    }

    contactCreate() {
        const dialogRef = this.dialog.open(ContactCreateDialogComponent, {
            autoFocus: false,
            data: {category: 'customer'}
        });

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.closeSidebar.complete();
        this.create.complete();
    }
}
