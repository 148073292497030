import secrets from './secrets/dev-secrets.json';
// Importing JSON directly, so that we can use SOPS for securing attributes within the config file.
// Encrypting TS files are not supported.

export const environment = {
    production: true,
    env: 'dev',
    maxIdleSeconds: 3540,
    sessionExpireWarningSeconds: 60,
    dwollaEnvironment: 'sandbox',

    sentryConfig: {
        url: 'https://e443226d09ce41a78b336d2b16fcb4c2@o106491.ingest.sentry.io/1373783'
    },
    namespace: 'dev',
    default_deal_timezone: 'America/Los_Angeles',
    firestoreInvoiceConfigurationBucket: 'invoice-configurations',
    firestoreCSVUploadBucket: 'invoice-uploads-csv',
    firestoreDataCSVUploadBucket: 'data-uploads-csv',
    firestoreDocumentsUploadBucket: 'uploads',
    oAuthCallbackURL: 'https://apidev.zipi.app/oauth-dev/user-authorized',
    unauthorizedUserRedirectPath: '/login',
    loginPageURL: 'https://zipi-dev.skyslope.com/login',
    legacyHostname: 'app-ui-dot-zipi-dev-v4.appspot.com',
    barePageURL: 'https://zipi-dev.skyslope.com',
    marketingPageLogin: 'https://skyslope.com/books-login',
    addonHashCookieDomain: null,
    services: {
        pwCore: {url: 'https://books-api-core-dev.skyslope.com'},
        shippCore: {url: 'https://books-api-shipp-dev.skyslope.com'},
        pwAdmin: {url: 'https://books-admin-dev.skyslope.com'}
    },
    firebase: {
        apiKey: secrets.firebase.apiKey,
        authDomain: 'zipi-dev-v4.firebaseapp.com',
        databaseURL: 'https://zipi-dev-v4.firebaseio.com',
        projectId: 'zipi-dev-v4',
        storageBucket: 'zipi-dev-v4.appspot.com',
        messagingSenderId: '609143078948'
    },
    smartlook: null,
    zipi_pay: {
        origin: 'https://backend-dot-zipi-financial.appspot.com',
        setup_account_url: '/v1/account-setup',
        setup_card_url: '/v1/card-setup',
        setup_complex_url: '/v1/setup',
        setup_muhnee_receive_only_url: '/v1/muhnee/setup/receive-only',
        setup_payload_receive_only_url: '/v1/payload/setup/receive-only',
        setup_payload_credit_card_url: '/v1/payload/setup/credit-card',
        setup_payload_bank_account_url: '/v1/payload/setup/bank-account',
        client_id: 'zipi_dev'
    },
    flagsmithKey: secrets.flagsmithKey,
    flatFileKey: secrets.flatFileKey,
    okta: {
        callbackRedirectPath: '/login/skyslope-callback',
        postLogoutRedirectPath: '/login/skyslope',
        issuer: 'https://id-integ.skyslope.com/oauth2/ausku1rdgaFoooRXy357',
        clientId: '0oarm6obd9SUYSZ1M357',
        audience: 'integ.skyslope.com'
    },
    useOktaConversion: true
};
