<div>
    <div class="d-flex justify-content-end">
        <mat-icon class="sp-cursor-pointer" (click)="close()">close</mat-icon>
    </div>
    <div class="mb-2" *ngIf="contactId">
        <app-notifications [notification_mode]="'popup'"></app-notifications>
    </div>
    <!--        Receive Only Bank Account Section        -->
    <div *ngIf="isMuhneeAvailable">
        <h4>Direct Deposit {{ isMuhneeAvailable && isPayloadAvailable ? '(ZilMoney)' : '' }}</h4>

        <div
            class="account-card"
            *ngIf="
                !contactZipiFinancialReceiveGateway ||
                !contactZipiFinancialReceiveGateway.payment_methods ||
                contactZipiFinancialReceiveGateway.payment_methods.length === 0
            "
        >
            <div *ngIf="isBankGatewayLoaded">Add an account to start receiving payments</div>
            <div *ngIf="!isBankGatewayLoaded">Information is being loaded</div>
            <div></div>
        </div>

        <div *ngIf="contactZipiFinancialReceiveGateway">
            <div class="account-card" *ngFor="let method of contactZipiFinancialReceiveGateway.payment_methods">
                <ng-container
                    *ngIf="method.is_available_for_payment && method.type !== 'zipi_financial_credit_card'"
                    class="d-flex align-items-center"
                    [ngClass]="!method.is_visible_to_connect ? 'disabled-card' : ''"
                    [matTooltip]="'Not authorized to pay to this Account.'"
                    [matTooltipDisabled]="method.is_visible_to_connect"
                >
                    <div class="d-flex align-items-center">
                        <mat-icon class="mr-1">account_balance</mat-icon>
                        <span>{{ method.title }}</span>
                        <span class="ml-1" *ngIf="method.expiration_date">({{ method.expiration_date }})</span>
                    </div>
                    <span class="sp-spacer"></span>
                    <button
                        type="button"
                        mat-raised-button
                        [disabled]="isButtonsLocked"
                        (click)="deletePaymentMethod(method)"
                    >
                        Remove
                    </button>
                </ng-container>
            </div>
        </div>
        <span matTooltip="Information is being loaded" matTooltipDisabled="{{ isBankGatewayLoaded }}">
            <button
                class="mb-2"
                type="button"
                mat-raised-button
                (click)="setupZipiFinancialForContact('contact_bank', 'muhnee')"
                [disabled]="!isBankGatewayLoaded || isButtonsLocked"
            >
                Add {{ isMuhneeAvailable && isPayloadAvailable ? 'ZilMoney' : '' }} Bank Account
            </button>
        </span>
    </div>

    <div *ngIf="isPayloadAvailable">
        <h4>Direct Deposit {{ isMuhneeAvailable && isPayloadAvailable ? '(Payload.co)' : '' }}</h4>

        <div
            class="account-card"
            *ngIf="
                !contactPayloadReceiveGateway ||
                !contactPayloadReceiveGateway.payment_methods ||
                contactPayloadReceiveGateway.payment_methods.length === 0
            "
        >
            <div *ngIf="isBankGatewayLoaded">Add an account to start receiving payments</div>
            <div *ngIf="!isBankGatewayLoaded">Information is being loaded</div>
            <div></div>
        </div>

        <div *ngIf="contactPayloadReceiveGateway">
            <div class="account-card" *ngFor="let method of contactPayloadReceiveGateway.payment_methods">
                <ng-container
                    *ngIf="method.is_available_for_payment && method.type !== 'zipi_financial_credit_card'"
                    class="d-flex align-items-center"
                    [ngClass]="!method.is_visible_to_connect ? 'disabled-card' : ''"
                    [matTooltip]="'Not authorized to pay to this Account.'"
                    [matTooltipDisabled]="method.is_visible_to_connect"
                >
                    <div class="d-flex align-items-center">
                        <mat-icon class="mr-1">account_balance</mat-icon>
                        <span>{{ method.title }}</span>
                        <span class="ml-1" *ngIf="method.expiration_date">({{ method.expiration_date }})</span>
                    </div>
                    <span class="sp-spacer"></span>
                    <button
                        type="button"
                        mat-raised-button
                        [disabled]="isButtonsLocked"
                        (click)="deletePaymentMethod(method)"
                    >
                        Remove
                    </button>
                </ng-container>
            </div>
        </div>
        <span matTooltip="Information is being loaded" matTooltipDisabled="{{ isBankGatewayLoaded }}">
            <button
                class="mb-2"
                type="button"
                mat-raised-button
                (click)="setupZipiFinancialForContact('contact_bank', 'payload')"
                [disabled]="!isBankGatewayLoaded || isButtonsLocked"
            >
                Add {{ isMuhneeAvailable && isPayloadAvailable ? 'Payload.co' : '' }} Bank Account
            </button>
        </span>
    </div>

    <!--        Credit Card Section        -->
    <div *ngIf="isPayloadAvailable" class="mb-2">
        <h4>Stored Cards</h4>
        <div
            class="account-card"
            *ngIf="
                !contactPayloadCardGateway ||
                !contactPayloadCardGateway.payment_methods ||
                contactPayloadCardGateway.payment_methods.length === 0
            "
        >
            <div *ngIf="isCardGatewayLoaded">Add a credit card to start making payments</div>
            <div *ngIf="!isCardGatewayLoaded">Information is being loaded</div>
            <div></div>
        </div>
        <div *ngIf="contactPayloadCardGateway">
            <div class="account-card" *ngFor="let method of contactPayloadCardGateway.payment_methods">
                <ng-container
                    *ngIf="method.is_available_for_payment && method.type === 'zipi_financial_credit_card'"
                    class="d-flex align-items-center"
                    [ngClass]="!method.is_visible_to_connect ? 'disabled-card' : ''"
                    [matTooltip]="'Not authorized to charge this Card.'"
                    [matTooltipDisabled]="method.is_visible_to_connect"
                >
                    <div class="d-flex align-items-center">
                        <mat-icon class="mr-1">credit_card</mat-icon>
                        <span>{{ method.title }}</span>
                        <span class="ml-1" *ngIf="method.expiration_date">({{ method.expiration_date }})</span>
                    </div>
                    <span class="sp-spacer"></span>
                    <button
                        type="button"
                        mat-raised-button
                        *ngIf="contactPayloadCardGateway"
                        [disabled]="isButtonsLocked"
                        (click)="deletePaymentMethod(method)"
                    >
                        Remove
                    </button>
                </ng-container>
            </div>
        </div>
        <span matTooltip="Information is being loaded" matTooltipDisabled="{{ isCardGatewayLoaded }}">
            <button
                class="mb-2"
                type="button"
                mat-raised-button
                (click)="setupZipiFinancialForContact('contact_card', 'payload')"
                [disabled]="!isCardGatewayLoaded || isButtonsLocked"
            >
                Add Credit Card
            </button>
        </span>
    </div>
</div>
