import {AfterViewInit, Component, Inject, OnDestroy, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, FormGroup, Validators} from '@angular/forms';
import {ILedgerAccount, IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {takeUntil} from 'rxjs/operators';
import {PaymentMethodsService} from '../../../../../../profile/services/payment-methods.service';
import {LedgerAccountService} from '../../../../../../../services/api/finance/ledger-accounts.service';
import {environment} from '../../../../../../../../environments/environment';
import {CompanyGatewayService} from '../../../../../../../services/api/finance/company-gateway.service';
import {IZipiFinancialIframeDialogData} from '../../../../../../../typings/zipi-financial-iframe';

@Component({
    selector: 'app-zipi-financial-iframe-dialog',
    templateUrl: './zipi-financial-iframe-dialog.component.html',
    styleUrls: ['./zipi-financial-iframe-dialog.component.scss']
})
export class ZipiFinancialIframeDialogComponent implements OnInit, AfterViewInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    origin: string | null = null;
    url: string | null = null;
    sanitizedUrl: SafeResourceUrl | null = null;

    accessMethod: 'public' | 'internal' = 'internal';

    currentGateway: IPaymentGateway | null = null;
    ownOperatingGateway: IPaymentGateway | undefined | null;

    driverType: 'plaid' | 'moov' | 'muhnee' | 'tpp' | 'payload' | undefined;
    paymentMethodForVerification: IPaymentMethod | null = null;
    contactId: number | null = null;
    dataForPrefilling: {
        company_name: string;
        email: string;
        first_name: string;
        last_name: string;
        nick_name?: string;
    } | null = null;

    paymentMethodType: 'contact_bank' | 'contact_card' | 'bank' | 'card' | 'contact_gateway_only' = 'bank';

    defaultReceiving: boolean = false;

    agreeContinueButtonDisabled: boolean = false;
    iframeInit: boolean = false;
    isGatewayCreatedSeparately: boolean = false;

    disallowSetupLedger: boolean | undefined = false;

    dataFromIframe:
        | {
              gateway: {
                  business_name: string;
                  driver_type: 'plaid' | 'moov';
                  external_gateway_id: string;
              };
              method: {
                  title: string;
                  external_method_id: string;
                  type: 'credit_card' | 'bank';
                  pending_auth_status: string;
              };
              creation_source: 'current_company' | 'partner_company';
          }
        | null
        | any = null;

    currentStep: '1' | '2';

    availableLedgerAccounts: ILedgerAccount[];
    isDefault: boolean = false;
    accountId: UntypedFormControl | undefined;

    listener: any;

    applyDisabled: boolean;

    countryList: Array<{[key: string]: any}> = [
        {label: 'Belgium', slug: 'Belgium'},
        {label: 'Canada', slug: 'Canada'},
        {label: 'France', slug: 'France'},
        {label: 'Germany', slug: 'Germany'},
        {label: 'Mexico', slug: 'Mexico'},
        {label: 'USA', slug: 'USA'}
    ];

    creationSource: 'current_company' | 'partner_company' = 'current_company';
    storeAccountMethod: 'disallowed' | 'allowed' | 'preferred' | 'required' | undefined;

    constructor(
        public dialogRef: MatDialogRef<ZipiFinancialIframeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IZipiFinancialIframeDialogData,
        private fb: UntypedFormBuilder,
        public sanitizer: DomSanitizer,
        private paymentMethodsService: PaymentMethodsService,
        private companyGatewayService: CompanyGatewayService,
        private ledgerAccountService: LedgerAccountService
    ) {
        this.currentStep = '1';
        this.availableLedgerAccounts = [];
        this.listener = (e: MessageEvent<any>) => {
            const origin = e.origin;
            if (origin !== this.origin) {
                return false;
            }
            const eventData = JSON.parse(e.data);
            switch (eventData.event_type) {
                case 'EXIT': {
                    this.iframeInit = false;
                    this.dialogRef.close({isError: false, success: false, message: eventData.message, isClosed: true});
                    break;
                }
                case 'GATEWAY_CREATED': {
                    this.dataFromIframe = eventData;
                    if (this.paymentMethodType !== 'contact_gateway_only') {
                        this.isGatewayCreatedSeparately = true;
                    }
                    this.createContactGatewayWithPaymentMethod(this.dataFromIframe);
                    break;
                }
                case 'DONE': {
                    this.currentStep = '2';
                    this.dataFromIframe = eventData;
                    if (
                        this.dataFromIframe.hasOwnProperty('storing_status') &&
                        this.dataFromIframe.storing_status === 'not_stored'
                    ) {
                        this.dialogRef.close({success: true, unsaved_method: this.dataFromIframe.method});
                    } else {
                        this.currentStep = '2';
                        this.iframeInit = false;
                        if (!this.currentGateway && !this.isGatewayCreatedSeparately) {
                            this.createContactGatewayWithPaymentMethod(this.dataFromIframe);
                        } else {
                            if (['contact_bank', 'contact_card'].includes(this.paymentMethodType)) {
                                this.createContactPaymentMethod(this.dataFromIframe.method);
                            }
                            if (
                                ['bank'].includes(this.paymentMethodType) &&
                                this.currentGateway &&
                                this.currentGateway.payment_gateway_id
                            ) {
                                this.createPaymentMethod(this.currentGateway.payment_gateway_id, {
                                    public_token: null,
                                    account_id: this.dataFromIframe.method.external_method_id,
                                    pending_auth_status: '',
                                    title: this.dataFromIframe.method.title,
                                    related_ledger_account_id: null,
                                    is_default_receiving: false,
                                    needDefaultLedger: false,
                                    account_type: 'bank'
                                });
                            }
                        }
                    }
                    break;
                }
                case 'ERROR': {
                    this.iframeInit = false;
                    this.dialogRef.close({isError: true, success: false, message: eventData.message});
                    break;
                }
            }
        };
        this.applyDisabled = false;
    }

    ngOnInit() {
        this.driverType = this.data.driverType;
        this.ownOperatingGateway = this.data.ownGateway;
        this.currentGateway = this.data.gateway;
        this.paymentMethodForVerification = this.data.paymentMethod;
        this.contactId = this.data.contactId;
        this.dataForPrefilling = this.data.prefillData;
        this.paymentMethodType = this.data.methodType;
        this.accessMethod = this.data.accessMethod;
        this.creationSource = this.data.creationSource;
        this.storeAccountMethod = this.data.storeAccountMethod;
        this.disallowSetupLedger = this.data.disallowSetupLedger;

        this.origin = environment.zipi_pay.origin;

        this._setupUrl();
        this.sanitize(this.url);
        this.accountId = this.fb.control(null, []);
        if (
            !this.paymentMethodForVerification &&
            this.currentGateway &&
            ['card', 'bank'].includes(this.paymentMethodType)
        ) {
            this.ledgerAccountService
                .getAvailableLedgerAccountsForFundingSourceByGatewayType(this.currentGateway.type)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((accounts) => {
                    this.availableLedgerAccounts = accounts.filter((account) => {
                        if (this.paymentMethodType === 'card') {
                            return account.type === 'credit_card';
                        } else {
                            return account.type !== 'credit_card';
                        }
                    });
                });
        }
    }

    ngAfterViewInit() {
        window.addEventListener('message', this.listener);
        this.openIframe();
    }

    sanitize(url: string | null) {
        if (url) {
            this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
    }

    createContactGatewayWithPaymentMethod(data: any) {
        if (this.contactId) {
            data.creation_source = this.creationSource;
            if (this.accessMethod === 'internal') {
                this.companyGatewayService
                    .createZipiFinancialReceiveOnlyCustomerWithFundigSource(this.contactId, data)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((gateway) => {
                        if (this.isGatewayCreatedSeparately) {
                            this.currentGateway = gateway;
                            if (window && window.document) {
                                const iframe = window.document.getElementById('frame') as any;
                                if (iframe && iframe.contentWindow) {
                                    iframe.contentWindow.postMessage({event_type: 'CAN_PROCEED'}, '*');
                                }
                            }
                        } else {
                            this.dialogRef.close({success: true});
                        }
                    });
            } else {
                this.companyGatewayService
                    .createPublicZipiFinancialReceiveOnlyCustomerWithFundigSource(this.contactId, data)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((gateway) => {
                        if (this.isGatewayCreatedSeparately) {
                            this.currentGateway = gateway;
                            if (window && window.document) {
                                const iframe = window.document.getElementById('frame') as any;
                                if (iframe && iframe.contentWindow) {
                                    iframe.contentWindow.postMessage({event_type: 'CAN_PROCEED'}, '*');
                                }
                            }
                        } else {
                            this.dialogRef.close({success: true});
                        }
                    });
            }
        }
    }

    createContactPaymentMethod(data: any) {
        if (this.currentGateway && this.currentGateway.payment_gateway_id && data) {
            data.creation_source = this.creationSource;
            if (this.accessMethod === 'internal') {
                this.paymentMethodsService
                    .createZipiFinancialPaymentMethodForContact(this.currentGateway.payment_gateway_id, data)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((gateway) => {
                        this.dialogRef.close({success: true});
                    });
            } else {
                this.paymentMethodsService
                    .createPublicZipiFinancialPaymentMethodForContact(this.currentGateway.payment_gateway_id, data)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((gateway) => {
                        this.dialogRef.close({success: true});
                    });
            }
        }
    }

    apply(skipLedger: boolean) {
        this.applyDisabled = true;
        if (this.accountId && this.currentGateway && this.currentGateway.payment_gateway_id && this.dataFromIframe) {
            const ledgerAccountId = this.accountId.value;
            let settings: {
                public_token: any;
                account_id: string;
                pending_auth_status: 'pending_manual_verification' | 'manually_verified' | 'need_relogin' | '';
                title: string;
                related_ledger_account_id: number | null;
                is_default_receiving: boolean | null;
                needDefaultLedger: boolean | null;
                account_type: 'bank' | 'card';
            } | null = null;
            if (this.data.isUniversal) {
                settings = {
                    public_token: null,
                    account_id: this.dataFromIframe?.method.external_method_id,
                    pending_auth_status: this.dataFromIframe.method.pending_auth_status,
                    title: this.dataFromIframe.method.title,
                    related_ledger_account_id: null,
                    is_default_receiving: this.defaultReceiving,
                    needDefaultLedger: null,
                    account_type: this.paymentMethodType === 'card' ? 'card' : 'bank'
                };
            } else {
                settings = {
                    public_token: null,
                    account_id: this.dataFromIframe.account_id,
                    pending_auth_status: this.dataFromIframe.pending_auth_status,
                    title: this.dataFromIframe.title,
                    related_ledger_account_id: null,
                    is_default_receiving: this.defaultReceiving,
                    needDefaultLedger: null,
                    account_type: this.paymentMethodType === 'card' ? 'card' : 'bank'
                };
            }

            if (skipLedger) {
                settings.needDefaultLedger = false;
            } else if (this.isDefault) {
                settings.needDefaultLedger = true;
            } else {
                settings.needDefaultLedger = false;
                settings.related_ledger_account_id = ledgerAccountId;
            }

            this.createPaymentMethod(this.currentGateway.payment_gateway_id, settings);
        }
    }

    createPaymentMethod(
        gatewayId: number,
        settings: {
            public_token: any;
            account_id: string;
            pending_auth_status: 'pending_manual_verification' | 'manually_verified' | 'need_relogin' | '';
            title: string;
            related_ledger_account_id: number | null;
            is_default_receiving: boolean | null;
            needDefaultLedger: boolean | null;
            account_type: 'bank' | 'card';
        }
    ) {
        this.paymentMethodsService
            .createZipiFinancialPaymentMethod(gatewayId, settings)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe({
                next: (response) => {
                    this.applyDisabled = false;
                    if (response) {
                        this.dialogRef.close({
                            isError: false,
                            success: true,
                            message: '',
                            data: {payment_method_id: response.payment_method_id}
                        });
                    }
                },
                error: (error) => {
                    this.applyDisabled = false;
                }
            });
    }

    openIframe() {
        this.iframeInit = true;
    }

    back() {
        this.dataFromIframe = null;
        this.currentStep = '1';
    }

    close() {
        this.dialogRef.close({isError: false, success: false, message: ''});
    }

    // saveCard() {
    //     if (this.cardForm) {
    //         if (this.cardForm.invalid) {
    //             this.cardForm.markAllAsTouched();
    //             return;
    //         } else {
    //
    //             const cardData ={
    //                 gateway: {
    //                     title: this.dataForPrefilling ? this.dataForPrefilling.company_name ? this.dataForPrefilling.company_name : `${this.dataForPrefilling.first_name} ${this.dataForPrefilling.last_name}` : '',
    //                     driver_type: 'tpp',
    //                     external_gateway_id: this.currentGateway ? this.currentGateway.customer_ref : null,
    //                 },
    //                 method: {
    //                     title: this.cardTitleBuild(),
    //                     external_method_id: null,
    //                     type: 'credit_card',
    //                     pending_auth_status: null,
    //                     card_info: this.cardForm.getRawValue(),
    //                 }
    //             };
    //
    //             if (!this.currentGateway) {
    //                 this.createContactGatewayWithPaymentMethod(cardData);
    //             } else {
    //                 if (['contact_bank', 'contact_card'].includes(this.paymentMethodType)) {
    //                     this.createContactPaymentMethod(cardData);
    //                 }
    //             }
    //         }
    //     }
    // }

    // cardTitleBuild(): string {
    //     if (typeof this.cardForm !== 'undefined') {
    //         return 'XXXX XXXX XXXX ' + this.cardForm.getRawValue().cardNumber.slice(-4);
    //     }
    //     return '';
    // }

    _setupUrl() {
        let data:
            | {
                  business_type: string;
                  business_name: string | null;
                  is_has_website: 'no' | 'yes' | null;
                  web: string;
                  description: string;
                  email: string;
                  first_name: string;
                  last_name: string;
                  customer_id: string | null;
              }
            | any = null;

        switch (this.driverType) {
            case 'moov': {
                if (this.data.isUniversal) {
                    this.url =
                        this.origin +
                        environment.zipi_pay.setup_complex_url +
                        `?&acid=${environment.zipi_pay.client_id}`;

                    if (!this.currentGateway) {
                        data = {
                            business_type: 'soleProprietorship',
                            business_name: this.dataForPrefilling ? this.dataForPrefilling.company_name : '',
                            is_has_website: null,
                            web: '',
                            description: '',
                            email: this.dataForPrefilling ? this.dataForPrefilling.email : '',
                            first_name: this.dataForPrefilling ? this.dataForPrefilling.first_name : '',
                            last_name: this.dataForPrefilling ? this.dataForPrefilling.last_name : '',
                            customer_id: this.data.customerId
                        };
                    }
                } else {
                    switch (this.paymentMethodType) {
                        case 'bank': {
                            this.url =
                                this.origin +
                                environment.zipi_pay.setup_account_url +
                                `?&acid=${environment.zipi_pay.client_id}`;
                            break;
                        }
                        case 'card': {
                            this.url =
                                this.origin +
                                environment.zipi_pay.setup_card_url +
                                `?&acid=${environment.zipi_pay.client_id}`;
                            break;
                        }
                    }
                }
                break;
            }
            case 'muhnee': {
                this.url =
                    this.origin +
                    environment.zipi_pay.setup_muhnee_receive_only_url +
                    `?&acid=${environment.zipi_pay.client_id}`;
                if (!this.currentGateway) {
                    data = {
                        first_name: this.dataForPrefilling ? this.dataForPrefilling.first_name : '',
                        last_name: this.dataForPrefilling ? this.dataForPrefilling.last_name : '',
                        nick_name: this.dataForPrefilling ? this.dataForPrefilling.nick_name : '',
                        business_name: this.dataForPrefilling ? this.dataForPrefilling.company_name : '',
                        payee_address_line: '',
                        payee_city: '',
                        payee_state: '',
                        payee_zip: '',
                        payee_phone: '',
                        email: this.dataForPrefilling ? this.dataForPrefilling.email : '',
                        customer_id: this.data.customerId,
                        own_gateway_id: this.ownOperatingGateway?.customer_ref,
                        intermediate_gateway_creation: true
                    };
                } else {
                    data = {
                        first_name: this.dataForPrefilling ? this.dataForPrefilling.first_name : '',
                        last_name: this.dataForPrefilling ? this.dataForPrefilling.last_name : '',
                        nick_name: this.dataForPrefilling ? this.dataForPrefilling.nick_name : '',
                        business_name: this.dataForPrefilling ? this.dataForPrefilling.company_name : '',
                        own_gateway_id: this.ownOperatingGateway?.customer_ref,
                        intermediate_gateway_creation: true
                    };
                }
                break;
            }
            case 'payload': {
                switch (this.paymentMethodType) {
                    case 'bank': {
                        this.url =
                            this.origin +
                            environment.zipi_pay.setup_payload_bank_account_url +
                            `?&acid=${environment.zipi_pay.client_id}`;
                        break;
                    }
                    case 'contact_gateway_only':
                    case 'contact_bank': {
                        this.url =
                            this.origin +
                            environment.zipi_pay.setup_payload_receive_only_url +
                            `?&acid=${environment.zipi_pay.client_id}`;
                        data = {
                            first_name: this.dataForPrefilling ? this.dataForPrefilling.first_name : '',
                            last_name: this.dataForPrefilling ? this.dataForPrefilling.last_name : '',
                            nick_name: this.dataForPrefilling ? this.dataForPrefilling.nick_name : '',
                            business_name: this.dataForPrefilling ? this.dataForPrefilling.company_name : '',
                            email: this.dataForPrefilling ? this.dataForPrefilling.email : '',
                            customer_id: this.data.customerId,
                            intermediate_gateway_creation: true
                        };
                        break;
                    }
                    case 'contact_card': {
                        this.url =
                            this.origin +
                            environment.zipi_pay.setup_payload_credit_card_url +
                            `?&acid=${environment.zipi_pay.client_id}`;
                        data = {
                            first_name: this.dataForPrefilling ? this.dataForPrefilling.first_name : '',
                            last_name: this.dataForPrefilling ? this.dataForPrefilling.last_name : '',
                            nick_name: this.dataForPrefilling ? this.dataForPrefilling.nick_name : '',
                            business_name: this.dataForPrefilling ? this.dataForPrefilling.company_name : '',
                            email: this.dataForPrefilling ? this.dataForPrefilling.email : '',
                            customer_id: this.data.customerId,
                            intermediate_gateway_creation: true
                        };
                        break;
                    }
                }
                break;
            }
            default:
                this.close();
        }

        if (this.currentGateway) {
            this.url += `&gid=${this.currentGateway.customer_ref}`;
        } else {
            this.url += `&gid=${null}`;
            // this.dialogRef.close({isError: true, success: false, message: `No Payment Gateway`});
        }

        if (this.paymentMethodForVerification) {
            this.url += `&vbaid=${this.paymentMethodForVerification.payment_method_ref}`;
        }

        this.url += `&type=${this.paymentMethodType}`;

        if (data) {
            this.url += `&data=${btoa(JSON.stringify(data))}`;
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        window.removeEventListener('message', this.listener);
    }
}
