import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {CompanyPaymentMethodsService} from '../../../../../services/api/finance/company-payment-methods.service';
import {Subject} from 'rxjs';
import {NotificationsService} from 'angular2-notifications';

const win: {[key: string]: any} = window;

@Component({
    selector: 'app-update-plaid-creds-by-link-dialog',
    templateUrl: './update-plaid-creds-by-link-dialog.component.html',
    styleUrls: ['./update-plaid-creds-by-link-dialog.component.scss']
})
export class UpdatePlaidCredsByLinkDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    currentMethod: IPaymentMethod | undefined;

    linkHandler: any;

    constructor(
        private companyPaymentMethodsService: CompanyPaymentMethodsService,
        private ntfs: NotificationsService,
        public dialogRef: MatDialogRef<UpdatePlaidCredsByLinkDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    async ngOnInit() {
        this.currentMethod = this.data.method;
        if (
            this.currentMethod &&
            this.currentMethod.plaid_refs &&
            this.currentMethod.plaid_refs.pending_auth_status === 'need_relogin' &&
            this.currentMethod.plaid_refs.access_token
        ) {
            await this.setupLink();
            this.linkHandler.open();
        } else {
            this.dialogRef.close(false);
        }
    }

    async setupLink() {
        if (typeof this.currentMethod === 'undefined') {
            return;
        }
        this.linkHandler = win.Plaid.create({
            token: await this.companyPaymentMethodsService
                .getPlaidLinkToken(this.currentMethod.payment_method_id as number)
                .toPromise(),
            onLoad: function () {
                // The Link module finished loading.
            },
            onSuccess: async (public_token: any, metadata: any) => {
                this.dialogRef.close(true);
            },
            onExit: (err: any, metadata: any) => {
                if (err !== null && err.error_code === 'item-no-error') {
                    this.dialogRef.close(true);
                    return;
                }

                if (err !== null && err.display_message) {
                    this.ntfs.error(err.display_message);
                }
                this.dialogRef.close(false);
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
