import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IJournalTemplate} from '@cyberco-nodejs/zipi-typings';
import {Group} from '../models/group';
import {UntypedFormControl} from '@angular/forms';
import {JournalsTemplatesService} from '../services/api/finance/journal-templates.service';

@Component({
    selector: 'app-company-inner-nav',
    styles: [
        `
            .company-toolbar-container {
                position: fixed;
                z-index: 2;
            }

            .action-icon {
                cursor: pointer;
                color: grey;
                font-weight: bold;
                height: 36px;
            }
            .action-icon:hover {
                color: #afafb3;
            }

            .buttons-container {
                width: 100%;
                position: sticky;
                top: 0;
                border-bottom: 1px lightgrey solid;
                background-color: #fff;
            }
        `
    ],
    templateUrl: './company-inner-nav.component.html'
})
export class CompanyInnerNavComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() isButtonsShowed: boolean = false;
    @Input() hasChanges: boolean = false;
    @Input() onSaveProfiles: EventEmitter<void> = new EventEmitter<void>();
    @Input() section: string = 'journals';
    @Input() groupsList: Group[] = [];
    @Input() openingBalanceDate: number | undefined;
    @Output() openSidebarEmitter = new EventEmitter();
    @Output() openTemplateEmitter = new EventEmitter();
    @Output() initAccountsEmitter: EventEmitter<number> = new EventEmitter();
    @Output() deleteOpeningBalancesEmitter = new EventEmitter();

    hoverer$: Subject<void> = new Subject<void>();
    randomFragment: string = '';
    menuTitle: string = 'Journal';

    divisionModel: UntypedFormControl = new UntypedFormControl(null);

    templates: IJournalTemplate[] = [];

    constructor(
        public router: Router,
        public journalsTemplatesService: JournalsTemplatesService
    ) {}

    ngOnInit() {
        this.hoverer$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.randomFragment = (+new Date()).toString(32);
        });

        if (this.router.url.includes('journal')) {
            this.menuTitle = 'Journal';
        } else if (this.router.url.includes('products')) {
            this.menuTitle = 'Services / Products';
        } else if (this.router.url.includes('chartofaccounts')) {
            this.menuTitle = 'Chart of Accounts';
        } else if (this.router.url.includes('openingbalances')) {
            this.menuTitle = 'Opening Balances';
        }

        this.divisionModel.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((division) => {
            this.initAccountsEmitter.next(division);
        });

        this.journalsTemplatesService
            .getJournalTemplates()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((values) => {
                this.templates = values.result;
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.hoverer$.complete();
        this.openSidebarEmitter.complete();
        this.openTemplateEmitter.complete();
        this.initAccountsEmitter.complete();
        this.deleteOpeningBalancesEmitter.complete();
    }
}
