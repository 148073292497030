import {Injectable} from '@angular/core';
import {ServiceRequester} from './service.requester';
import {Observable} from 'rxjs';
import {IDocumentItem} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class DocumentsService {
    private url = '/companies';

    constructor(public requester: ServiceRequester) {}

    createDocument(companyId: number, data: IDocumentItem): Observable<IDocumentItem> {
        return this.requester.makeMsCall$(`${this.url}/${companyId}/document`, 'POST', 'shipp', data);
    }

    deleteDocument(companyId: number, documentId: number): Observable<boolean> {
        return this.requester.makeMsCall$(`${this.url}/${companyId}/document/${documentId}`, 'DELETE', 'shipp');
    }

    getDocument(companyId: number, documentId: number): Observable<any> {
        return this.requester.makeMsCall$(`${this.url}/${companyId}/document/${documentId}`, 'GET', 'shipp');
    }
}
