<div class="view-deal" *ngIf="deal">
    <div>
        <mat-sidenav-container class="w-100">
            <mat-sidenav
                style="width: 800px; margin-top: 112px"
                position="end"
                mode="over"
                #rightSideNav
                [(opened)]="isSidebarOpen"
                (closed)="closeSideBar()"
                [fixedInViewport]="true"
            >
                <div class="p-2">
                    <div *ngIf="currentSidebarTab === 'finance'">
                        <app-deal-payouts [dealFG]="dealFormGroup"></app-deal-payouts>
                        <!--                (needReloadDeaL)="reloadDeaL()"-->
                    </div>

                    <div *ngIf="currentSidebarTab === 'commission-instructions'">
                        <app-commission-instructions
                            class="section"
                            style="margin-top: 22px; width: 100%"
                            [dealFormGroup]="dealFormGroup"
                            [isDealConnectedToSkyslope]="isDealConnectedToSkyslope"
                        ></app-commission-instructions>
                    </div>

                    <div *ngIf="currentSidebarTab === 'activity'">
                        <app-audit-log-container
                            class="section"
                            style="padding-top: 20px"
                            [dealFormGroup]="dealFormGroup"
                        ></app-audit-log-container>
                    </div>

                    <div *ngIf="currentSidebarTab === 'override'">
                        <app-company-compensation-profile-override
                            [readonly]="true"
                            [financialElementsBySalesEntity]="financialElementsBySalesEntity"
                            [financialElementsBySalesEntityFC]="financialElementsBySalesEntityFC"
                            [viewMode]="true"
                            [salesEntities]="salesEntities"
                            [order]="0"
                            [disabled]="dealFormGroup.disabled"
                            (overrideChange)="onOverrideChange($event)"
                        ></app-company-compensation-profile-override>
                        <!--(save)="doSaveOverriddenCompensation($event)"
                        (cancel)="doCloseOverriddenCompensation()"
                        (delete)="doDeleteOverriddenCompensation($event)"
                        [compensationForm]="overriddenCompensationFG"-->
                    </div>
                </div>
            </mat-sidenav>
        </mat-sidenav-container>

        <!-------- Sub-menu -------->
        <div class="sub-menu mt-3">
            <div class="row m-0">
                <div class="col-6 roll-overlfow">
                    <!------- Mat-card address ------->
                    <mat-card class="address-card d-flex justify-content-between align-items-center">
                        <div class="address-text">
                            <span *ngIf="deal.address">
                                <span *ngIf="deal.street_number">{{ deal.street_number }}&nbsp;</span>
                                <span>{{ deal.address }}&nbsp;</span>
                                <span *ngIf="deal.unit">{{ deal.unit }},&nbsp;</span>
                                <br />
                                <span *ngIf="deal.city">{{ deal.city }},&nbsp;</span>
                                <span *ngIf="deal.state">{{ deal.state }}&nbsp;</span>
                                <span *ngIf="deal.zip">{{ deal.zip }}</span>
                            </span>
                            <span *ngIf="!deal.address" class="comment-text">Property address</span>
                        </div>

                        <div class="address-controls">
                            <div
                                class="system-status"
                                *ngIf="
                                    deal.system_status === DEAL_SYSTEM_STATUS.draft ||
                                    deal.system_status === DEAL_SYSTEM_STATUS.approved
                                "
                            >
                                <strong>{{ deal.system_status | titlecase }}</strong>
                            </div>

                            <button mat-icon-button class="mat-default" *ngIf="addressShowToggle">
                                <i class="material-icons styled-icons" (click)="addressShowToggle = !addressShowToggle"
                                    >info_outline</i
                                >
                            </button>

                            <button mat-icon-button class="mat-default" *ngIf="!addressShowToggle">
                                <i
                                    class="material-icons rotated-icon styled-icons"
                                    (click)="addressShowToggle = !addressShowToggle"
                                    >linear_scale</i
                                >
                            </button>
                        </div>
                    </mat-card>

                    <div class="info">
                        <!------- Status Stepper ------->
                        <div class="container-stepper mt-3" *ngIf="addressShowToggle">
                            <div class="step">
                                <div class="v-stepper">
                                    <div class="circle"></div>
                                    <div class="line"></div>
                                </div>

                                <div class="content">
                                    <h4 class="text-uppercase font-weight-normal">close escrow</h4>
                                    <div>{{ deal.close_of_escrow | date: 'longDate' }}</div>
                                </div>
                            </div>

                            <!--<div class="step active">
                                <div class="v-stepper">
                                    <div class="circle"></div>
                                    <div class="line"></div>
                                </div>

                                <div class="content">
                                    <h4 class="text-uppercase font-weight-normal">title evidence</h4>
                                    <div class="font-weight-bold">September 25, 2019</div>
                                </div>
                            </div>

                            <div class="step active">
                                <div class="v-stepper">
                                    <div class="circle"></div>
                                    <div class="line"></div>
                                </div>

                                <div class="content">
                                    <h4 class="text-uppercase font-weight-normal">finding approved escrow</h4>
                                    <div class="font-weight-bold">September 20, 2019</div>
                                </div>
                            </div>

                            <div class="step active">
                                <div class="v-stepper">
                                    <div class="circle"></div>
                                    <div class="line"></div>
                                </div>

                                <div class="content">
                                    <h4 class="text-uppercase font-weight-normal">inspection completed</h4>
                                    <div class="font-weight-bold">September 15, 2019</div>
                                </div>
                            </div>

                            <div class="step active">
                                <div class="v-stepper">
                                    <div class="circle"></div>
                                    <div class="line"></div>
                                </div>

                                <div class="content">
                                    <h4 class="text-uppercase font-weight-normal">offer accepted</h4>
                                    <div class="font-weight-bold">September 5, 2019</div>
                                </div>
                            </div>

                            <div class="step active">
                                <div class="v-stepper">
                                    <div class="circle"></div>
                                    <div class="line"></div>
                                </div>

                                <div class="content">
                                    <h4 class="text-uppercase font-weight-normal">listed</h4>
                                    <div class="font-weight-bold">August 10, 2019</div>
                                </div>
                            </div>-->
                        </div>

                        <!------- Property information ------->
                        <div class="property-information" *ngIf="!addressShowToggle">
                            <h3 class="ml-3 my-4 font-weight-normal text-uppercase primary-text">
                                Property information
                            </h3>

                            <div class="ml-3 my-4">
                                <div class="mb-3">
                                    <div class="mb-2 primary-text">Property class:</div>
                                    <div class="text-capitalize">{{ deal.property_class }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!------- Image deal ------->
                <div class="col-6">
                    <div class="deal-photo">
                        <!------- Deal status ------->
                        <div
                            class="image-label text-uppercase"
                            rbac
                            [rule]="{deals__edit_any: false, deals__edit_own: false}"
                            [ngClass]="deal.status"
                        >
                            {{ DEAL.status_LABELS[deal.status] }}
                        </div>
                        <!------- Change deal status ------->
                        <button
                            mat-button
                            class="image-label text-uppercase"
                            rbac
                            [anyRule]="[{deals__edit_any: true}, {deals__edit_own: true}]"
                            style="line-height: unset; font-size: inherit"
                            [ngClass]="deal.status"
                            [matMenuTriggerFor]="menu"
                        >
                            {{ DEAL.status_LABELS[deal.status] }}
                        </button>
                        <mat-menu #menu="matMenu" xPosition="before">
                            <button
                                mat-menu-item
                                *ngFor="let status of object_keys(DEAL.status_SET)"
                                (click)="updateDealStatus(status)"
                            >
                                {{ DEAL.status_LABELS[status] }}
                            </button>
                        </mat-menu>

                        <ng-container *ngIf="deal.image_url; else defaultImage">
                            <img class="deal-photo_image w-100" [src]="deal.image_url" alt="image" />
                        </ng-container>
                        <ng-template #defaultImage>
                            <img
                                class="deal-photo_image w-100"
                                [src]="'assets/images/default-deal-image.png'"
                                alt="image"
                            />
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>

        <!-------- Icon in the end of Tabs -------->
        <div
            class="trade-sheet-icon"
            style="width: 72px"
            [rule]="{deals__view_trade_sheet: false}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
        ></div>
        <i
            class="material-icons-outlined trade-sheet-icon"
            style="cursor: pointer"
            [matMenuTriggerFor]="downloadTradeSheetMenu"
            [rule]="{deals__view_trade_sheet: true}"
            rbac
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            >print</i
        >
        <mat-menu #downloadTradeSheetMenu>
            <button mat-menu-item (click)="downloadTradeSheetPDF('condensed')">Condensed</button>
            <button mat-menu-item (click)="downloadTradeSheetPDF('expanded')">Expanded</button>
            <button *ngIf="isBrokerTradesheetEnabled" mat-menu-item (click)="downloadTradeSheetPDF('broker')">
                Broker
            </button>
        </mat-menu>

        <!------- Deal dual link item ------->
        <div class="view-dual-deal-block" *ngIf="deal.dual_deal_id">
            <button mat-button [matMenuTriggerFor]="dualMenu">
                Dual
                <mat-icon style="line-height: 22px">link</mat-icon>
            </button>
            <mat-menu #dualMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="router.navigate(['/deals', deal.dual_deal_id])">Open Dual Deal</button>
            </mat-menu>
        </div>

        <!-------- Tab group -------->
        <mat-tab-group class="mt-4" id="deal-tabs">
            <!-------- Transaction -------->
            <mat-tab label="Transaction">
                <ng-template mat-tab-label>
                    <i class="material-icons material-icons-outlined">description</i>
                    <h2 class="ml-2 primary-text mb-0 mat-label-text">Transaction</h2>
                </ng-template>

                <app-view-transaction-deal
                    [deal]="deal"
                    [isUiModsPending]="isUiModsPending"
                ></app-view-transaction-deal>
            </mat-tab>

            <!-------- Disbursements -------->
            <mat-tab label="Disbursements">
                <ng-template mat-tab-label>
                    <i class="material-icons">picture_as_pdf</i>
                    <h2 class="ml-2 primary-text mb-0 mat-label-text">Disbursements</h2>
                </ng-template>

                <app-view-disbursements-deal [deal]="deal"></app-view-disbursements-deal>
            </mat-tab>

            <!-------- Documents -------->
            <!--<mat-tab label="Documents" *ngIf="deal.documents">
                <ng-template mat-tab-label>
                    <i class="material-icons">cloud_queue</i>
                    <h2 class="ml-2 primary-text mb-0 mat-label-text">Documents</h2>
                </ng-template>

                <app-view-documents-deal
                    [deal]="deal"
                ></app-view-documents-deal>
            </mat-tab>-->

            <!-------- Parties -------->
            <mat-tab label="Parties">
                <ng-template mat-tab-label>
                    <i class="material-icons">people_outline</i>
                    <h2 class="ml-2 primary-text mb-0 mat-label-text">Parties</h2>
                </ng-template>

                <app-view-parties-deal [deal]="deal"></app-view-parties-deal>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
