import {Component, EventEmitter, OnDestroy, OnInit, Output, Input} from '@angular/core';
import {Profile} from 'app/models/profile';
import {AvailableProfilesSource} from 'app/services/sources/available-profiles.source';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {AuthService} from 'app/services/auth.service';
import {Store} from '@ngrx/store';
import {AppInitSource} from 'app/services/sources/app-init.source';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {
    FetchMembershipOrganizations,
    FetchSubEntities,
    FetchTagCategories,
    FetchWildcards
} from 'app/store/company-wide/company-wide.actions';
import {CompanyPermissionsSource} from 'app/services/sources/companyPermissions.source';
import {AvailableRolesSource} from 'app/services/sources/available-roles.source';

@Component({
    styles: [
        `
            .company-users-toolbar-container {
                margin-bottom: 64px;
            }
            .company-users-toolbar {
                position: fixed;
                z-index: 2;
                left: 0;
                top: 64px;
                padding-left: 200px;
            }
            ::ng-deep .is-collapsed .company-users-toolbar {
                padding-left: 60px;
            }
            .app-sub-companies-panel-btn-active {
                background-color: whitesmoke !important;
                color: #212529 !important;
            }
        `
    ],
    selector: 'app-sub-companies-panel',
    template: `
        <div
            *ngIf="availableProfiles.length > 1"
            class="px-3 company-users-toolbar-container"
            style="margin-bottom: 20px; margin-top: -15px; min-height: 40px;"
        >
            <div style="top: 115px;">
                <ng-container *ngFor="let availableProfile of availableProfiles">
                    <button
                        type="button"
                        style="background-color: #e0e0e0; color: #6c757d;"
                        class="mr-2"
                        [ngClass]="
                            availableProfile?.id === currentProfile?.id ? 'app-sub-companies-panel-btn-active' : ''
                        "
                        mat-raised-button
                        (click)="
                            availableProfile?.id === currentProfile?.id ? null : setCurrentProfile(availableProfile)
                        "
                    >
                        <span style="margin-top: 10px;display: flex;" *ngIf="availableProfile.company">{{
                            availableProfile.company.title
                        }}</span>
                    </button>
                </ng-container>
            </div>
        </div>
    `
})
export class SubCompaniesPanelComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    currentProfile: Profile | undefined;
    availableProfiles: Profile[] = [];
    @Output() currentProfileChanged: EventEmitter<Profile> = new EventEmitter<Profile>();
    @Output() availableProfilesEmitter: EventEmitter<Profile[]> = new EventEmitter<Profile[]>();
    @Input() permission:
        | 'company_settings__manage_users'
        | 'company_settings__manage_groups'
        | 'company_settings__view_groups'
        | undefined;

    constructor(
        protected availableProfilesSource: AvailableProfilesSource,
        protected currentProfileSource: CurrentProfileSource,
        public authService: AuthService,
        private store: Store<any>,
        protected appInitSource: AppInitSource,
        private companyPermissionsSource: CompanyPermissionsSource,
        private availableRolesSource: AvailableRolesSource
    ) {}

    ngOnInit() {
        this.currentProfileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe((currProfile) => {
            this.currentProfile = currProfile;
        });

        this.availableProfilesSource.availableProfiles
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((profilesList) => this.onlyParentChildCompanies(profilesList));
    }

    async setCurrentProfile(profile: Profile) {
        if (profile.id !== (this.currentProfile?.id as number)) {
            this.store.dispatch({type: 'RESET'});
            await this.authService.loadProfile(profile?.id as number);

            // load once
            this.store.dispatch(new FetchSubEntities());
            this.store.dispatch(new FetchMembershipOrganizations());
            this.store.dispatch(new FetchTagCategories());
            this.store.dispatch(new FetchWildcards());
            this.companyPermissionsSource.load();
            this.availableRolesSource.load();
        } else {
            localStorage.removeItem('current_company_group_id');
            this.appInitSource.resetLastAppInitResponse();
            await this.authService.initApp();
        }

        this.currentProfileChanged.emit(profile);
    }

    onlyParentChildCompanies(profilesList: Profile[]) {
        const currentCompanyId = this.currentProfile?.company_fk_id;
        const currentParentCompanyId =
            this.currentProfile?.company && this.currentProfile.company.parent__company_fk_id;
        const preparedProfiles = [];

        if (currentParentCompanyId) {
            const parentCompany = profilesList.find((el) => el.company_fk_id === currentParentCompanyId);
            if (!parentCompany) {
                return;
            }

            preparedProfiles.push(parentCompany);

            profilesList.forEach((el) => {
                if (
                    el.company &&
                    el.company.parent__company_fk_id &&
                    el.company.parent__company_fk_id === currentParentCompanyId
                ) {
                    preparedProfiles.push(el);
                }
            });
        } else {
            const parentCompany = profilesList.find((el) => el.company_fk_id === currentCompanyId);
            if (!parentCompany) {
                return;
            }

            preparedProfiles.push(parentCompany);

            profilesList.forEach((el) => {
                if (
                    el.company &&
                    el.company.parent__company_fk_id &&
                    el.company.parent__company_fk_id === parentCompany.company_fk_id
                ) {
                    preparedProfiles.push(el);
                }
            });
        }

        // only profiles with company_settings__manage_users permission allowed
        this.availableProfiles = preparedProfiles.filter((profile) => {
            return (
                profile.permissions &&
                this.permission &&
                profile.permissions[this.permission] && // so user have permission to this functionality
                profile.company
            ); // need ignore profiles without company, seems that is only "global" profile
        });

        this.availableProfilesEmitter.emit(this.availableProfiles);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.currentProfileChanged.complete();
        this.availableProfilesEmitter.complete();
    }
}
