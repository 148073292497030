import {merge as observableMerge, Observable, Subject} from 'rxjs';

import {startWith} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {AddonInstanceModel} from './addons.models';

@Injectable()
export class AddonsSource {
    private triggers = {
        loadAddons: new Subject<AddonInstanceModel[]>()
    };

    private events = {
        addonsChangeEvent: <Observable<AddonInstanceModel[]>>observableMerge(this.triggers.loadAddons)
    };

    private currentAddons: AddonInstanceModel[] | null = null;

    public get addonsChangeEvent(): Observable<AddonInstanceModel[]> {
        if (this.currentAddons) {
            return this.events.addonsChangeEvent.pipe(startWith(this.currentAddons));
        }
        return this.events.addonsChangeEvent;
    }

    public setLoadedAddons(addons: AddonInstanceModel[]) {
        this.triggers.loadAddons.next(addons);
    }

    constructor() {
        this.addonsChangeEvent.subscribe((addons: AddonInstanceModel[]) => {
            this.currentAddons = addons;
        });
    }
}
