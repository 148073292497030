<div>
    <mat-card
        class="report-table"
        [ngClass]="{reportTableWithSubTypes: report.settings.is_include_sub_types}"
        *ngIf="reportData.length"
    >
        <div class="w-100">
            <div class="flex-row font-weight-bold title header">
                <div class="item w-40">
                    <span class="header-actions" [matTooltip]="expandTooltip" (click)="toggleExpand()">
                        <mat-icon>{{ isExpanded ? 'unfold_less' : 'unfold_more' }}</mat-icon>
                    </span>
                </div>
                <div class="item w-250"><span>Agent</span></div>
                <div class="item w-150"><span>Status</span></div>
                <div class="item w-150"><span>Property Class</span></div>
                <div class="item w-150"><span>Type</span></div>
                <div class="item w-150" *ngIf="report.settings.is_include_sub_types"><span>Sub-Type</span></div>
                <div class="item w-150"><span>Division</span></div>
                <div class="item w-200"><span>Source Of Business</span></div>
                <div class="item w-150 amount-text"><span>Sales Price</span></div>
                <div class="item w-300"><span>Address</span></div>
                <div class="item w-150"><span>Closing Date</span></div>
                <div class="item w-150 amount-text"><span>GCI</span></div>
                <div class="item w-150 amount-text"><span>Comm %</span></div>
                <div class="item w-150 amount-text"><span>Agent Split</span></div>
                <div class="item w-150 amount-text"><span>Company Split</span></div>
                <div class="item w-150 amount-text"><span>Company Income</span></div>
                <div class="item w-200 amount-text"><span>Company Expense</span></div>
                <div class="item w-150 amount-text"><span>Net Office</span></div>
            </div>

            <ng-container *ngFor="let agent of reportData">
                <div class="flex-row w-100 table-body">
                    <div class="action-icon item w-40">
                        <span class="material-icons" (click)="toggleSection(agent)">
                            <ng-container *ngIf="agent.children.length">
                                {{ agent.is_expanded ? 'expand_more' : 'chevron_right' }}
                            </ng-container>
                        </span>
                    </div>
                    <div class="item w-250">{{ agent.agent_name }}</div>
                    <div class="item w-150">{{ agent.status }}</div>
                    <div class="item w-150">{{ agent.property_class | titlecase }}</div>
                    <div class="item w-150">{{ agent.type }}</div>
                    <div class="item w-150" *ngIf="report.settings.is_include_sub_types">{{ agent.sub_type }}</div>
                    <div class="item w-150">{{ agent.company_group_title }}</div>
                    <div class="item w-200">{{ agent.source_of_business }}</div>
                    <div class="item w-150 amount-text" [ngClass]="{'negative-value': agent.sales_price < 0}">
                        <span>${{ agent.sales_price | reportFormatted: 'financial' }}</span>
                    </div>
                    <div class="item w-300">{{ agent.address }}</div>
                    <div class="item w-150">{{ agent.close_of_escrow }}</div>
                    <div class="item w-150 amount-text" [ngClass]="{'negative-value': agent.gci < 0}">
                        <span>${{ agent.gci | reportFormatted: 'financial' }}</span>
                    </div>
                    <div class="item w-150 amount-text" [ngClass]="{'negative-value': agent.commission < 0}">
                        <span>{{ agent.commission | reportFormatted: 'percent' }}</span>
                    </div>
                    <div class="item w-150 amount-text" [ngClass]="{'negative-value': agent.agent_split < 0}">
                        <span>${{ agent.agent_split | reportFormatted: 'financial' }}</span>
                    </div>
                    <div class="item w-150 amount-text" [ngClass]="{'negative-value': agent.company_split < 0}">
                        <span>${{ agent.company_split | reportFormatted: 'financial' }}</span>
                    </div>
                    <div class="item w-150 amount-text" [ngClass]="{'negative-value': agent.company_incomes_sum < 0}">
                        <span>${{ agent.company_incomes_sum | reportFormatted: 'financial' }}</span>
                    </div>
                    <div class="item w-200 amount-text" [ngClass]="{'negative-value': agent.company_expenses_sum < 0}">
                        <span>${{ agent.company_expenses_sum | reportFormatted: 'financial' }}</span>
                    </div>
                    <div class="item w-150 amount-text" [ngClass]="{'negative-value': agent.company_net < 0}">
                        <span>${{ agent.company_net | reportFormatted: 'financial' }}</span>
                    </div>
                </div>

                <ng-container *ngIf="agent.is_expanded && agent.children.length">
                    <div class="flex-row w-100 table-body sub-item" *ngFor="let children of agent.children">
                        <div class="item w-40"></div>
                        <div class="item agent-name w-250">{{ children.agent_name }}</div>
                        <div class="item w-150"></div>
                        <div class="item w-150"></div>
                        <div class="item w-150"></div>
                        <div class="item w-150" *ngIf="report.settings.is_include_sub_types"></div>
                        <div class="item w-150"></div>
                        <div class="item w-200"></div>
                        <div class="item w-150"></div>
                        <div class="item w-300"></div>
                        <div class="item w-150"></div>
                        <div class="item w-150"></div>
                        <div class="item w-150"></div>
                        <div class="item w-150 amount-text" [ngClass]="{'negative-value': children.agent_split < 0}">
                            <span>${{ children.agent_split | reportFormatted: 'financial' }}</span>
                        </div>
                        <div class="item w-150"></div>
                        <div class="item w-150"></div>
                        <div class="item w-200"></div>
                        <div class="item w-150"></div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </mat-card>
    <mat-card class="preview-body" *ngIf="!reportData.length">
        <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
    </mat-card>
</div>
