import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IContactInvitesState} from './contact-invites.reducers';

export const selectContactInvitesState = createFeatureSelector<IContactInvitesState>('contactInvites');

export const selectContactInvite = createSelector(
    selectContactInvitesState,
    (state: IContactInvitesState) => state.contactInvite
);

export const selectLoadingState = createSelector(
    selectContactInvitesState,
    (state: IContactInvitesState) => state.loading
);
