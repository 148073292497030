<mat-tab-group style="height: 100%">
    <mat-tab label="Invoices">
        <app-contact-invoices [contactId]="contactId"></app-contact-invoices>
    </mat-tab>
    <mat-tab label="Recurring Invoices">
        <app-contact-recurring-invoices [contactId]="contactId"></app-contact-recurring-invoices>
    </mat-tab>
    <mat-tab label="Bills">
        <app-contact-bills [contactId]="contactId"></app-contact-bills>
    </mat-tab>
    <mat-tab label="Expenses">
        <app-contact-expenses [contactId]="contactId"></app-contact-expenses>
    </mat-tab>
</mat-tab-group>
