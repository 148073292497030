import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {GenericFormArray} from 'app/entites/generic.entity';
import {ChipNode} from 'app/modules/account-info/compensation/models/chip-node';
import {AvailableProfilesSource} from 'app/services/sources/available-profiles.source';
import {Profile} from 'app/models/profile';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {UntypedFormControl} from '@angular/forms';
import {Company} from 'app/models/company';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ITextPartcipantData} from 'app/modules/shared/components/deal-participants/deal-participants.component';
import {IContact, IContactPartLink} from '@cyberco-nodejs/zipi-typings';

type ___TYPE =
    | 'company_group'
    | 'individuals_in_group'
    | 'individual'
    | 'contact'
    | 'contact_wildcard'
    | 'wildcard'
    | 'contact_class'
    | 'contact_person';
type CONTACT_METATYPE = 'agent' | 'customer' | 'vendor';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-combined-entity-picker',
    templateUrl: 'entity-picker-advanced.component.html'
})
export class EntityPickerAdvancedComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() allowSubCompanySearch: boolean = false;
    @Input() additionalTitle: string = 'Advanced filters';

    // uses in parent Component (CombinedCompensationProfilePickerComponent)
    // @workaround around a "Contact Wildcard" workaround
    @Input() allowDealWildcard: boolean = false;
    @Input() nodesFA: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    @Input() hideControls: boolean = false;
    @Input() singleSelected: boolean = false;
    @Input() heading: string = '';
    @Input() title: string = 'Participants';
    @Input() bold_style: boolean = true;
    @Input() only_compensation_groups: boolean = true;
    @Input() without_company_group: boolean = false;
    @Input() availableTypes: Array<___TYPE> = ['company_group', 'individuals_in_group', 'individual'];
    @Input() displayMode: 'single-line' | 'double-line' = 'single-line';
    @Input() positionMode: 'bottom' | 'top' | 'none' = 'top';
    @Input() disabled: boolean = false;
    @Input() seemDisabled: boolean = false;
    @Input() disableUpdate: boolean = false;
    @Input() initialContactIds: number[] | {id: number; fake_title: string}[] = [];
    @Input() initialGroupIds: number[] = [];
    @Input() initialProfilesInGroupIds: number[] = [];
    @Input() initialProfileIds: number[] = [];
    @Input() initialContactProfileIds: {id: number; fake_title: string}[] = [];
    @Input() panelWidth: string | number = 0;
    @Input() onlyDivisions: boolean = false;
    @Input() onlyCommonGroups: boolean = false;
    @Input() closePanelEvent$: Subject<void> = new Subject<void>();
    @Input() contactMetatype: CONTACT_METATYPE | null = null;
    @Input() contactPersonMain: boolean = false;
    @Input() isContactNeeded: boolean = false;
    @Input() dealParticipantsWithSubType: IContactPartLink[] | null = null;
    @Input() excludeMembersFromGroup: number | null = null;
    @Input() closeAfterSelection: boolean = true;
    @Input() lineClamp: boolean = false; // show text reduced and in 2 rows if it's too long
    @Input() textParticipants: ITextPartcipantData[] = [];
    @Input() needContactName: boolean = false;

    @Output() contactCreateEmitter: EventEmitter<{created: IContact}> = new EventEmitter<{created: IContact}>();
    @Output() isLoadedEmitter: EventEmitter<void> = new EventEmitter<void>();
    @Output() toggleVisibilityEmitter: EventEmitter<{is_ignored: boolean; id: number}> = new EventEmitter<{
        is_ignored: boolean;
        id: number;
    }>();
    @Output() contactCreateFromTextEmitter: EventEmitter<{id: number}> = new EventEmitter<{id: number}>();

    public currentProfile: Profile | null = null;
    public availableCompanies: Company[] = [];
    public chosenSubCompaniesFC: UntypedFormControl = new UntypedFormControl([]);
    public updatedListOfSubCompanies: BehaviorSubject<Company[]> = new BehaviorSubject<Company[]>([]);

    constructor(
        protected currentProfileSource: CurrentProfileSource,
        protected availableProfilesSource: AvailableProfilesSource
    ) {}

    trackById(index: number, c: Company) {
        return c?.id;
    }

    ngOnInit() {
        // Watch on changes current profile
        this.currentProfileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe((currProfile) => {
            this.currentProfile = currProfile;
        });

        // Watch on changes number of available profiles
        this.availableProfilesSource.availableProfiles
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((profilesList: Profile[]) => {
                this.onlyParentChildCompanies(profilesList);
            });

        // Watch on changes chosen companies
        this.chosenSubCompaniesFC.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
            if (list.length) {
                this.updatedListOfSubCompanies.next(list);
            } else {
                // if chosen is nothing, send current company
                const company = this.currentProfile?.company;
                if (company) {
                    this.updatedListOfSubCompanies.next([company]);
                }
            }
        });

        // Set default company as current
        if (this.availableCompanies.length) {
            this.chosenSubCompaniesFC.setValue([
                this.availableCompanies.find((company) => company.id === this.currentProfile?.company?.id)
            ]);
        }

        // if user is edited report with sub-companies that are absent in list
        if (this.nodesFA.value.length) {
            const companyIds = Array.from(new Set(this.nodesFA.value.map((c: ChipNode) => c.company_fk_id)));
            const companyIdsToAdd = companyIds.filter(
                (id) => !this.updatedListOfSubCompanies.value.find((c) => c.id === id)
            );

            // add companies to FormGroup
            if (companyIdsToAdd.length) {
                const companiesToAdding = this.availableCompanies.filter((c) => companyIdsToAdd.includes(c.id));
                this.chosenSubCompaniesFC.setValue([...this.chosenSubCompaniesFC.value, ...companiesToAdding]);
            }
        }
    }

    onlyParentChildCompanies(profilesList: Profile[]) {
        const currentCompanyId = this.currentProfile?.company?.id;
        const parentCompany = profilesList.find((el) => el.company_fk_id === currentCompanyId);
        const availableProfiles = [];

        if (typeof parentCompany === 'undefined') {
            return;
        }

        availableProfiles.push(parentCompany);

        profilesList.forEach((el) => {
            if (
                el.company &&
                el.company.parent__company_fk_id &&
                el.company.parent__company_fk_id === parentCompany.company_fk_id
            ) {
                availableProfiles.push(el);
            }
        });

        this.availableCompanies = availableProfiles
            .map((profile) => profile?.company)
            .filter((c) => c)
            .map((c) => c as Company);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.closePanelEvent$.complete();
        this.updatedListOfSubCompanies.complete();
        this.contactCreateEmitter.complete();
        this.isLoadedEmitter.complete();
        this.toggleVisibilityEmitter.complete();
        this.contactCreateFromTextEmitter.complete();
    }
}
