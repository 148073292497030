import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Company} from '../../models/company';
import {SessionService} from '../../services/session.service';

@Component({
    selector: 'app-add-company-dialog',
    styles: [
        `
            .row.footer {
                justify-content: space-between;
                flex: initial;
            }

            .col {
                min-width: 400px;
                min-height: 200px;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }
        `
    ],
    template: `
        <div class="col">
            <div class="header">Add Company</div>
            <div>
                <mat-form-field>
                    <input matInput type="text" name="title" placeholder="Title" [(ngModel)]="newCompany.title" />
                </mat-form-field>
                <div
                    rbac
                    [rule]="{create_sub_companies: true}"
                    [denyMethod]="'display-none'"
                    *ngIf="sessionService.profile?.company"
                >
                    <mat-checkbox [(ngModel)]="is_paid_by_parent">
                        {{ sessionService.profile?.company?.title }} company will pay for this sub-company
                    </mat-checkbox>
                </div>
            </div>
            <div class="row footer">
                <button mat-button class="mat-primary" (click)="save()">Add New</button>
                <button mat-button class="mat-default" (click)="dialogRef.close()">Cancel</button>
            </div>
        </div>
    `
})
export class AddCompanyDialogComponent {
    public newCompany: Company;
    public is_paid_by_parent: Boolean = false;
    public lockSubCompanyCheckbox: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<AddCompanyDialogComponent>,
        public sessionService: SessionService
    ) {
        this.newCompany = new Company();
    }

    save() {
        this.dialogRef.close(<AddCompanyDialogPayload>{
            company: this.newCompany,
            is_paid_by_parent: this.is_paid_by_parent
        });
    }

    existing() {
        this.dialogRef.close();
    }
}

export class AddCompanyDialogPayload {
    company: Company | undefined;
    is_paid_by_parent: boolean | undefined;
}
