import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../../services/service.requester';

interface YodataCompanySettings {
    yodata_company_settings_id?: number;
    yodata_company_id: string;
    yodata_company_name: string;
    company_fk_id: number;
    is_create_profiles: boolean;
}

@Injectable()
export class YodataAddonApi {
    constructor(public requester: ServiceRequester) {}

    loadYodataAddonCompanySettings(): Promise<YodataCompanySettings> {
        return this.requester.makeMsCall('/yodata/company-settings', 'GET');
    }

    createYodataAddonCompanySettings(data: {realEstateOrganizationId: string; isProfilesCreate: boolean}): Promise<{
        newSettings: YodataCompanySettings;
        yodataCompany: any;
    }> {
        return this.requester.makeMsCall('/yodata/company-settings', 'POST', data);
    }

    patchYodataAddonCompanySettings(data: any): Promise<boolean> {
        return this.requester.makeMsCall('/yodata/company-settings', 'PATCH', data);
    }
}
