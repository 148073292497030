import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UntypedFormBuilder} from '@angular/forms';
import {FormGroupArray} from '../../../../../../../typings/common';
import {
    IRemainingPayoutsWithAdditionalInfo,
    IDataToBeProcessed,
    IProcessDialogData
} from '../../../../remaining-payouts/types/remaining-payouts.types';
import {RemainingPayoutsApiService} from '../../../services/remaining-payouts.api-service';
import {RemainingPayoutsDataService} from '../../../services/remaining-payouts.data-service';

@Component({
    selector: 'process-remaining-payouts-bill-create-only-dialog',
    templateUrl: 'process-remaining-payouts-bill-create-only-dialog.component.html',
    styleUrls: ['./process-remaining-payouts-bill-create-only-dialog.component.scss']
})
export class ProcessRemainingPayoutsBillCreateOnlyDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    payoutIds: number[] = [];
    fullPayoutsInfo: IRemainingPayoutsWithAdditionalInfo[] = [];

    itemsArray: FormGroupArray = this.fb.array([]) as FormGroupArray;
    itemsArrayBuilt: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ProcessRemainingPayoutsBillCreateOnlyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IProcessDialogData,
        private remainingPayoutsService: RemainingPayoutsApiService,
        private remainingPayoutsDataService: RemainingPayoutsDataService,
        private fb: UntypedFormBuilder
    ) {}

    ngOnInit() {
        this.payoutIds = this.data.payoutIds;
        if (this.payoutIds && this.payoutIds.length > 0) {
            this.loadPayoutsInfo(this.payoutIds);
        } else {
            this.close();
        }
    }

    loadPayoutsInfo(payoutIds: Array<number>) {
        this.remainingPayoutsService
            .getRemainingPayoutsInfo(payoutIds)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((payouts) => {
                this.fullPayoutsInfo = payouts;
                this.setupItemsArray();
            });
    }

    setupItemsArray() {
        if (
            this.fullPayoutsInfo &&
            this.fullPayoutsInfo.length > 0 &&
            this.fullPayoutsInfo.some((payout) => !payout.bill_id)
        ) {
            for (const item of this.fullPayoutsInfo) {
                if (!item.bill_id) {
                    this.itemsArray.push(this.remainingPayoutsDataService.createItem(item, false));
                }
            }
        }
        this.itemsArrayBuilt = true;
    }

    continue() {
        if (this.itemsArray.invalid) {
            this.itemsArray.markAllAsTouched();
            return;
        }

        const result: IDataToBeProcessed = {
            processing_mode: 'skip',
            after_action: null,
            payouts: this.itemsArray.getRawValue()
        };
        this.dialogRef.close(result);
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
