import {Component, Input} from '@angular/core';
import {IReportsPreviewResultData} from '../../../../store/reports.reducer';

@Component({
    selector: 'reports-preview-membership',
    templateUrl: './membership.component.html',
    styleUrls: ['./membership.component.scss']
})
export class MembershipReportPreviewComponent {
    @Input()
    /**
     * Set report data
     */
    set reportData(data: IReportsPreviewResultData[]) {
        this._reportData = data;
    }

    /**
     * Get report data
     */
    get reportData() {
        return this._reportData;
    }

    _reportData: IReportsPreviewResultData[] | [] = [];

    overallTable: Array<{[key: string]: any}> = [];

    tableStructure: Array<{
        title: string;
        value: string;
    }> = [
        {
            title: 'Contact',
            value: 'display_name'
        },
        {
            title: 'Email',
            value: 'email'
        },
        {
            title: 'Category',
            value: 'contact_type'
        },
        {
            title: 'Anniversary Date',
            value: 'anniversary_date'
        },
        {
            title: 'Hire Date',
            value: 'hire_date'
        },
        {
            title: 'Termination Date',
            value: 'termination_date'
        },
        {
            title: 'Organization',
            value: 'organization'
        },
        {
            title: 'Number',
            value: 'member_license_number'
        },
        {
            title: 'Expiration Date',
            value: 'expiration_date'
        },
        {
            title: 'Dues Responsibility',
            value: 'dues_responsibility'
        },
        {
            title: 'Type',
            value: 'membership_type'
        }
    ];

    columnsToDisplay: string[] = [
        'display_name',
        'email',
        'contact_type',
        'anniversary_date',
        'hire_date',
        'termination_date',
        'organization',
        'member_license_number',
        'expiration_date',
        'dues_responsibility',
        'membership_type'
    ];

    constructor() {}
}
