import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';

import {RbacModule} from '../rbac/rbac.module';
import {SharedModule} from '../shared/shared.module';
import {GroupsModule} from '../account-info/groups/groups.module';
import {DivisionsModule} from '../account-info/divisions/divisions.module';
import {ProfileModule} from '../profile/profile.module';
import {CompensationModule} from '../account-info/compensation/compensation.module';
import {GoalsModule} from '../goals/goals.module';

import {routing} from './progress.routing';
import {FocusAreasComponent} from './focus-areas/focus-areas.component';
import {GoalsManageComponent} from './goals-manage/goals-manage.component';
import {ProgressApi, ProgressService, ProgressSource} from './progress.service';
import {GoalsCreateBarComponent} from './goals-create-bar/goals-create-bar.component';
import {GoalsComponent} from './goals/goals.component';
import {ProgressComponent} from './progress.component';
import {UserGoalsComponent} from './user-goals/user-goals.component';
import {ProfileGoalsComponent} from './profile-goals/profile-goals.component';
import {GroupsGoalsComponent} from './groups-goals/groups-goals.component';

@NgModule({
    imports: [
        CommonModule,
        // MaterialModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        RbacModule,
        SharedModule,
        ProfileModule,
        GroupsModule,
        DivisionsModule,
        CompensationModule,
        GoalsModule,
        routing
    ],
    exports: [BrowserModule],
    declarations: [
        ProgressComponent,
        FocusAreasComponent,
        GoalsComponent,
        GoalsCreateBarComponent,
        GoalsManageComponent,
        UserGoalsComponent,
        ProfileGoalsComponent,
        GroupsGoalsComponent
    ],
    providers: [ProgressService, ProgressSource, ProgressApi],
    bootstrap: [FocusAreasComponent]
})
export class ProgressModule {}
