import {Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {SessionService} from '../../../../../../services/session.service';
import {timePeriodObj, listOfStatusInDirectoryOptions} from '../../../../constants';
import {getNextActivePanel, isLastPanelItem} from '../../helper';
import {ProductsService} from '../../../../../../services/api/finance/products.service';
import {CONTACT_METATYPES} from '../../../../../../local-typings';
import {GroupsSource} from '../../../../../../services/sources/groups.source';
import {takeUntil} from 'rxjs/operators';
import {Group} from '../../../../../../models/group';
import {Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {selectMembershipOrganizations} from '../../../../../../store/company-wide/company-wide.selectors';
import {IReport, IReportSetting, IReportSettingKeys} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-report-edit-bar-membership',
    templateUrl: './membership.component.html',
    styleUrls: ['./membership.component.scss']
})
export class MembershipBarComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Output() valueChanges: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Output() action: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Input() report: IReport | null = null;

    private defaultValues: {[key: string]: any} = {
        time_period: timePeriodObj.this_year.value,
        contact_type: ['agent'],
        status_in_directory: ['active']
    };

    public activePanel: string = 'time_period';
    public reportSettings: IReportSetting | null = null;
    public isShowNext: boolean = true;
    public listOfStatusInDirectoryOptions: Array<{title: string; value: string}> = listOfStatusInDirectoryOptions;
    public listOfContactCategory: Array<{title: string; value: string}> = CONTACT_METATYPES.filter(
        (item) => item[0] !== 'delegate'
    ).map((item) => ({
        title: item[1],
        value: item[0]
    }));

    public reportOptions = [
        {
            value: 'time_period',
            title: 'Time Period'
        },
        {
            value: 'entity',
            title: 'Entity'
        },
        {
            value: 'organization',
            title: 'Organization'
        },
        {
            value: 'contact_type',
            title: 'Contact Type'
        },
        {
            value: 'status_in_directory',
            title: 'Status in Directory'
        }
    ];

    public groupsList: any[] = [];
    public divisionsList: any[] = [];
    public membershipOrganizationsList: any[] = [];

    constructor(
        private sessionService: SessionService,
        private productsService: ProductsService,
        private groupSrc: GroupsSource,
        private store: Store<any>
    ) {}

    ngOnInit() {
        this.initData();
    }

    initData() {
        if (this.report) {
            this.reportSettings = {...this.defaultValues, ...this.report.settings};

            this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
                this.groupsList = list.filter((group) => group.type === Group.type_SET.division);
                this.divisionsList = list.filter((group) => group.type === Group.type_SET.default);
            });

            this.store
                .pipe(select(selectMembershipOrganizations), takeUntil(this.unsubscribe))
                .subscribe((organizations) => {
                    if (organizations) {
                        this.membershipOrganizationsList = organizations;
                    }
                });
        }
    }

    onSelectOptions(event: {value: string}) {
        this.activePanel = event.value;
        this.isShowNext = !isLastPanelItem(this.activePanel, this.reportOptions);
    }

    onValueChanges(property: IReportSettingKeys, data: any) {
        switch (property) {
            case 'time_period':
                this.reportSettings = {
                    ...this.reportSettings,
                    ...data
                };
                break;
            default:
                if (this.reportSettings) {
                    this.reportSettings[property] = data as never;
                }
        }
    }

    onAction(event: string) {
        const response: {
            type: string;
            data: IReportSetting | null;
        } = {
            type: event,
            data: null
        };

        switch (event) {
            case 'save':
            case 'run':
                response['data'] = this.reportSettings;
                this.action.emit(response);
                break;
            case 'cancel':
            case 'delete':
                this.action.emit(response);
                break;
            case 'next':
                const nextData = getNextActivePanel(this.activePanel, this.reportOptions);
                this.activePanel = nextData.name;
                this.isShowNext = !nextData.isLast;
                break;
            default:
        }
    }

    ngOnDestroy() {
        this.action.complete();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
