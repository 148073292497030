import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {FormGroup, UntypedFormArray, UntypedFormBuilder, Validators} from '@angular/forms';
import {IBill, IInvoice, IProduct} from '@cyberco-nodejs/zipi-typings';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormGroupWithFormControls} from '@app/typings/common';
import {NotificationsService} from 'angular2-notifications';
import {cleanCurrencyString, currencyMaskitoOptions} from '@app/utilities/maskito';
import {PaymentsMadeService} from '@app/modules/finance/services/payments-made.service';
import {takeUntil} from 'rxjs/operators';
import {VendorCreditsService} from '@app/services/api/finance/vendor-credits.service';

export interface IEdit1099AmountsDialogData {
    entity_type: 'payment_made' | 'vendor_credit';
    entity_id: number;
    amount_1099_nec: number;
}

@Component({
    selector: 'app-edit-1099-amounts-dialog-dialog',
    templateUrl: './edit-1099-amounts-dialog.component.html',
    styleUrls: ['./edit-1099-amounts-dialog.component.scss']
})
export class Edit1099AmountsDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    currencyMaskitoMask = currencyMaskitoOptions;

    entityType: 'payment_made' | 'vendor_credit' | null = null;
    entityId: number | null = null;

    initialAmount1099NEC: number = 0;

    form1099: FormGroup = this.fb.group({
        amount_1099_nec: [null, [Validators.min(0)]]
    });

    isButtonDisabled: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService,
        private paymentsMadeService: PaymentsMadeService,
        private vendorCreditsService: VendorCreditsService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<Edit1099AmountsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IEdit1099AmountsDialogData
    ) {}

    ngOnInit() {
        this.entityType = this.data.entity_type;
        this.entityId = this.data.entity_id;
        this.initialAmount1099NEC = this.data.amount_1099_nec;
        this.setupForm();
    }

    setupForm() {
        this.form1099.controls.amount_1099_nec.setValue(this.initialAmount1099NEC);
    }

    saveAmounts() {
        if (!this.entityId) {
            return;
        }
        if (!this.entityType) {
            this.ntfs.error('Error', 'Wrong type of entity');
            this.close();
        }
        if (this.form1099.invalid) {
            this.ntfs.error('Error', 'Please fill in all required fields');
            return;
        }
        this.isButtonDisabled = true;
        const requestData: {amount_1099_nec?: number} = {
            amount_1099_nec: Number(cleanCurrencyString(this.form1099.controls.amount_1099_nec.value))
        };
        // if (this.form1099.controls.amount_1099_nec.value !== this.initialAmount1099NEC) {
        //     requestData['amount_1099_nec'] = Number(cleanCurrencyString(this.form1099.controls.amount_1099_nec.value));
        // }
        // if (Object.keys(requestData).length === 0) {
        //     this.close();
        // }
        if (this.entityType === 'payment_made') {
            this.paymentsMadeService
                .update1099AmountsOfPaymentMade(this.entityId, requestData)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (result) {
                        this.dialogRef.close(true);
                    }
                    this.isButtonDisabled = false;
                });
        } else {
            this.vendorCreditsService
                .update1099AmountsOfVendorCredit(this.entityId, requestData)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (result) {
                        this.dialogRef.close(true);
                    }
                    this.isButtonDisabled = false;
                });
        }
    }

    close() {
        this.dialogRef.close(null);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
