import {AfterViewInit, Component, Inject, OnDestroy, ViewChild} from '@angular/core';
import {ILicense} from '@cyberco-nodejs/zipi-typings';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormArray} from '@angular/forms';
import {InputDialogComponent} from '../../../../layouts/input-dialog/input-dialog.component';
import {PageEvent} from '@angular/material/paginator';
import {LicensesService} from '../../../../services/licenses.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

export interface IInputDialogData {
    title: string;
    placeholders: string[];
    values: {companyId: number};
}

@Component({
    selector: 'app-purchased-deals-dialog',
    templateUrl: 'purchased-deals-dialog.component.html',
    styles: [
        `
            .table-link {
                color: #007bff;
            }
            .table-link:hover {
                color: #0056b3;
                text-decoration: underline;
            }
        `
    ]
})
export class PurchasedDealsDialogComponent implements OnDestroy, AfterViewInit {
    private unsubscribe: Subject<void> = new Subject();
    @ViewChild(MatSort, {static: true}) sort: MatSort = new MatSort();

    dataSource: MatTableDataSource<ILicense>;

    displayedColumns = ['license_id', 'status', 'type', 'activated', 'created_at', 'expire_date', 'activated_at'];

    inputs: UntypedFormArray | undefined = undefined;

    // MatPaginator Inputs
    length = 0;
    pageSize = 10;
    pageSizeOptions: number[] = [5, 10, 25, 100];
    licenses: ILicense[] = [];

    constructor(
        public dialogRef: MatDialogRef<InputDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IInputDialogData,
        private licensesService: LicensesService
    ) {
        this.dataSource = new MatTableDataSource<ILicense>([]);
    }

    ngAfterViewInit(): void {
        // Get Licenses
        this.licensesService
            .getAllLicenses(this.data.values.companyId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                this.licenses = result;
                this.dataSource.data = this.licenses.slice(0, this.pageSize);

                this.length = result.length;
                this.sort.active = 'created_at';
                this.sort.direction = 'desc';
                this.dataSource.sort = this.sort;
            });
    }

    handleClick(e: PageEvent): void {
        this.pageSize = e.pageSize;
        this.dataSource.data = this.licenses.slice(this.pageSize * e.pageIndex, this.pageSize * (e.pageIndex + 1));
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
