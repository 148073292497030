<div class="d-flex w-100 h-100 justify-content-center align-items-center">
    <mat-card style="width: 400px">
        <h2 *ngIf="!isSpeedUpSuccess">Get Money Faster</h2>
        <h2 *ngIf="isSpeedUpSuccess">Transfer Upgraded</h2>
        <div class="mt-3" *ngIf="transferInfo && !isSpeedUpSuccess">
            <div class="mb-3">Payment: {{ transferInfo.transfer_amount | currency }}</div>

            <div class="font-weight-bold">Transfer Fee</div>
            <div style="color: grey">Original: {{ transferInfo.current_commission | currency }}</div>
            <div>Upgraded: {{ transferInfo.new_commission | currency }}</div>
        </div>

        <div *ngIf="isSpeedUpSuccess">Payment should be available within 24 banking hours.</div>

        <div class="mt-2 d-flex w-100 justify-content-center" *ngIf="!isResponseReceived">
            <mat-spinner [diameter]="24"></mat-spinner>
        </div>
        <div class="d-flex justify-content-end mt-4">
            <button
                type="button"
                *ngIf="!isSpeedUpSuccess"
                mat-raised-button
                color="primary"
                [disabled]="!transferInfo"
                (click)="speedup()"
            >
                Approve Instant Transfer
            </button>
        </div>
    </mat-card>
</div>
