import {Routes, RouterModule} from '@angular/router';
import {IndexComponent} from './pages/index.component';
import {EditComponent} from './common/edit/edit.component';

export const routes: Routes = [
    // {
    //     path: '',
    //     pathMatch: 'full',
    //     redirectTo: 'list',
    // },
    {
        path: '',
        component: IndexComponent
    },
    {path: ':id', component: EditComponent}
];

export const routing = RouterModule.forChild(routes);
