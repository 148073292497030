import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OktaAuth} from '@okta/okta-auth-js';
import {routing} from './auth.routing';
import {SignUpComponent} from './sign-up/sign-up.component';
import {SignInComponent} from './sign-in/sign-in.component';
import {SignInSkySlopeComponent} from './sign-in-skyslope/sign-in-skyslope.component';
import {OauthComponent} from './oauth/oauth.component';
import {OAuthApi} from './oauth/oauth.service.api';
import {OktaAuthModule, OKTA_CONFIG} from '@okta/okta-angular';
import {ConnectUserComponent} from './connect-user/connect-user.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {InviteComponent} from './invite/invite.component';
import {LoginFormDialogComponent} from './invite/login-form-dialog.component';
import {CompaniesService} from '../../services/companies.service';
import {SwitchProfileComponent} from './switch-profile/switch-profile.component';
import {SharedModule} from '../shared/shared.module';
import {MatInputModule} from '@angular/material/input';
import {EmailChangeComponent} from './email-change/email-change.component';
import {AccountInfoModule} from '../account-info/account-info.module';
import {ProfileModule} from '../profile/profile.module';
import {SignUpContactInviteComponent} from './sign-up-contact-invite/sign-up-contact-invite.component';
import {OuterLinkTriggerComponent} from './outer-link-trigger/outer-link-trigger.component';
import {GoalsModule} from '../goals/goals.module';
import {SignUpZohoInviteComponent} from './sign-up-zoho-invite/sign-up-zoho-invite.component';
import {ZohoInviteService} from './services/zoho-invite.service';
import {SignUpContactInvoiceComponent} from './sign-up-contact-invoice/sign-up-contact-invoice.component';
import {SignInContactInvoiceComponent} from './sign-in-contact-invoice/sign-in-contact-invoice.component';
import {SignUpContactDepositRequestComponent} from './sign-up-contact-deposit-request/sign-up-contact-deposit-request.component';
import {AfterSignUpDialogComponent} from './after-sign-up-dialog/after-sign-up-dialog.component';
import {getUserManager} from './services/okta/user-manager';
import {LogOutSkyslopeComponent} from './log-out-skyslope/log-out-skyslope.component';
import {SkyslopePostLogoutComponent} from './skyslope-post-logout/skyslope-post-logout.component';
import {SignInSkyslopeConversionComponent} from './sign-in-skyslope-conversion/sign-in-skyslope-conversion.component';
import {SignInSkyslopeCallbackComponent} from './sign-in-skyslope-callback/sign-in-skyslope-callback.component';
import {SignInAsComponent} from '@app/modules/auth/sign-in-as/sign-in-as.component';

@NgModule({
    declarations: [
        SignUpComponent,
        SignInAsComponent,
        SignInComponent,
        SignInContactInvoiceComponent,
        SignInSkySlopeComponent,
        SignInSkyslopeConversionComponent,
        LogOutSkyslopeComponent,
        SkyslopePostLogoutComponent,
        OauthComponent,
        ConnectUserComponent,
        SwitchProfileComponent,
        ForgotPasswordComponent,
        InviteComponent,
        LoginFormDialogComponent,
        EmailChangeComponent,
        SignUpContactInviteComponent,
        SignUpContactInvoiceComponent,
        SignUpContactDepositRequestComponent,
        SignInSkyslopeCallbackComponent,
        OuterLinkTriggerComponent,
        SignUpZohoInviteComponent,
        AfterSignUpDialogComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        SharedModule,
        MatInputModule,
        ReactiveFormsModule,
        AccountInfoModule,
        ProfileModule,
        GoalsModule,
        OktaAuthModule,
        routing
    ],
    providers: [
        CompaniesService,
        ZohoInviteService,
        OAuthApi,
        {
            provide: OKTA_CONFIG,
            useValue: {
                oktaAuth: getUserManager()._oktaAuth.oktaAuth,
                onAuthRequired: getUserManager().options.onAuthRequired
            }
        }
    ],
    exports: [LoginFormDialogComponent],
    bootstrap: [ForgotPasswordComponent]
})
export class AuthModule {}
