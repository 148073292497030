import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RbacDirective} from './rbac.directive';
import {RbacService} from './rbac.service';

@NgModule({
    imports: [],
    exports: [RbacDirective],
    declarations: [RbacDirective],
    providers: [RbacService],
    bootstrap: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RbacModule {}
