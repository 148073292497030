<div>
    <mat-card class="preview-body" *ngIf="reportData.contacts && reportData.contacts.length">
        <div>
            <table class="mat-table tables w-100">
                <tr class="mat-header-row">
                    <th class="header-cell sticky w-2">
                        <div class="item-name">
                            <span class="header-actions" [matTooltip]="expandTooltip" (click)="toggleExpand()">
                                <mat-icon>{{ isExpanded ? 'unfold_less' : 'unfold_more' }}</mat-icon>
                            </span>
                        </div>
                    </th>
                    <th class="header-cell sticky w-9">Entity Date</th>
                    <th class="header-cell sticky w-20">Entity Number</th>
                    <th class="header-cell sticky w-9">Due Date</th>
                    <th class="header-cell sticky w-10 text-right">Current</th>
                    <th class="header-cell sticky w-10 text-right">1 - 30</th>
                    <th class="header-cell sticky w-10 text-right">31 - 60</th>
                    <th class="header-cell sticky w-10 text-right">61 - 90</th>
                    <th class="header-cell sticky w-10 text-right">90+ Days</th>
                    <th class="header-cell sticky w-10 text-right">Total</th>
                </tr>
                <ng-container *ngFor="let contact of reportData.contacts">
                    <tr
                        class="table-row"
                        [ngClass]="{
                            bordered: !contact.is_expanded || !contact.items.length,
                            'total-row': contact.is_expanded && contact.items.length
                        }"
                    >
                        <td class="body-cell w-40" colspan="4">
                            <div class="action-icon w-100">
                                <span class="material-icons" (click)="toggleSection(contact)">
                                    {{ contact.is_expanded ? 'expand_more' : 'chevron_right' }}
                                </span>
                                <span>{{ contact.contact_name }}</span>
                            </div>
                        </td>
                        <td
                            class="body-cell w-10 amount-text"
                            [ngClass]="{'negative-value': contact.current_total < 0}"
                        >
                            {{ contact.current_total | reportFormatted: 'financial' }}
                        </td>
                        <td
                            class="body-cell w-10 amount-text"
                            [ngClass]="{'negative-value': contact.less_30_total < 0}"
                        >
                            {{ contact.less_30_total | reportFormatted: 'financial' }}
                        </td>
                        <td
                            class="body-cell w-10 amount-text"
                            [ngClass]="{'negative-value': contact.between_31_and_60_total < 0}"
                        >
                            {{ contact.between_31_and_60_total | reportFormatted: 'financial' }}
                        </td>
                        <td
                            class="body-cell w-10 amount-text"
                            [ngClass]="{'negative-value': contact.between_61_and_90_total < 0}"
                        >
                            {{ contact.between_61_and_90_total | reportFormatted: 'financial' }}
                        </td>
                        <td
                            class="body-cell w-10 amount-text"
                            [ngClass]="{'negative-value': contact.more_91_total < 0}"
                        >
                            {{ contact.more_91_total | reportFormatted: 'financial' }}
                        </td>
                        <td class="body-cell w-10 amount-text" [ngClass]="{'negative-value': contact.total < 0}">
                            {{ contact.total | reportFormatted: 'financial' }}
                        </td>
                    </tr>
                    <tr
                        class="table-row detailed-item"
                        *ngFor="let item of contact.items; let last = last"
                        [ngClass]="{
                            expanded: contact.is_expanded,
                            not_expanded: !contact.is_expanded,
                            bordered: last
                        }"
                    >
                        <td class="body-cell w-11" colspan="2">{{ item.entity_date }}</td>
                        <td class="body-cell w-20">
                            <span *ngIf="entityRouterListByJournalType[item.type]">
                                <a [routerLink]="[entityRouterListByJournalType[item.type], item.entity_id]">
                                    {{ item.type_title }} {{ item.entity_number }}
                                </a>
                            </span>
                            <span *ngIf="!entityRouterListByJournalType[item.type]">
                                {{ item.type_title }} {{ item.entity_number }}
                            </span>
                        </td>
                        <td class="body-cell w-9">{{ item.due_date }}</td>
                        <td class="body-cell w-10 amount-text" [ngClass]="{'negative-value': item.current < 0}">
                            {{ item.current | reportFormatted: 'financial' }}
                        </td>
                        <td class="body-cell w-10 amount-text" [ngClass]="{'negative-value': item.less_30 < 0}">
                            {{ item.less_30 | reportFormatted: 'financial' }}
                        </td>
                        <td
                            class="body-cell w-10 amount-text"
                            [ngClass]="{'negative-value': item.between_31_and_60 < 0}"
                        >
                            {{ item.between_31_and_60 | reportFormatted: 'financial' }}
                        </td>
                        <td
                            class="body-cell w-10 amount-text"
                            [ngClass]="{'negative-value': item.between_61_and_90 < 0}"
                        >
                            {{ item.between_61_and_90 | reportFormatted: 'financial' }}
                        </td>
                        <td class="body-cell w-10 amount-text" [ngClass]="{'negative-value': item.more_91 < 0}">
                            {{ item.more_91 | reportFormatted: 'financial' }}
                        </td>
                        <td class="body-cell w-10 amount-text" [ngClass]="{'negative-value': item.total < 0}">
                            {{ item.total | reportFormatted: 'financial' }}
                        </td>
                    </tr>
                </ng-container>
                <tr class="total-row" *ngIf="reportData.total">
                    <td class="body-cell w-40" colspan="4">TOTAL</td>
                    <td class="body-cell w-10 amount-text" [ngClass]="{'negative-value': reportData.total.current < 0}">
                        {{ reportData.total.current | reportFormatted: 'financial' }}
                    </td>
                    <td class="body-cell w-10 amount-text" [ngClass]="{'negative-value': reportData.total.less_30 < 0}">
                        {{ reportData.total.less_30 | reportFormatted: 'financial' }}
                    </td>
                    <td
                        class="body-cell w-10 amount-text"
                        [ngClass]="{'negative-value': reportData.total.between_31_and_60 < 0}"
                    >
                        {{ reportData.total.between_31_and_60 | reportFormatted: 'financial' }}
                    </td>
                    <td
                        class="body-cell w-10 amount-text"
                        [ngClass]="{'negative-value': reportData.total.between_61_and_90 < 0}"
                    >
                        {{ reportData.total.between_61_and_90 | reportFormatted: 'financial' }}
                    </td>
                    <td class="body-cell w-10 amount-text" [ngClass]="{'negative-value': reportData.total.more_91 < 0}">
                        {{ reportData.total.more_91 | reportFormatted: 'financial' }}
                    </td>
                    <td class="body-cell w-10 amount-text" [ngClass]="{'negative-value': reportData.total.total < 0}">
                        {{ reportData.total.total | reportFormatted: 'financial' }}
                    </td>
                </tr>
            </table>
        </div>

        <p class="m-2">*Amount is displayed in your base currency <span class="badge-secondary">USD</span></p>
    </mat-card>

    <mat-card class="preview-body" *ngIf="!reportData.contacts || !reportData.contacts.length">
        <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
    </mat-card>
</div>
