import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {AutomationDialogComponent} from '../../tabs/tab2-tags/automation-dialog/automation-dialog.component';
import {takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {Automation} from 'app/models/automation';
import {AutomationService} from 'app/services/automation.service';
import {DialogConfirmationComponent} from '../../../../shared/components/dialog-confirmation/dialog-confirmation.dialog';
import {NotificationsServiceZipi} from '../../../../notifications/notifications.service';
import {MatSort, Sort} from '@angular/material/sort';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {IScrollData} from 'app/models/scroll-data';
import {PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {FeatureFlagsService} from '../../../../feature-flags/feature-flags.service';

@Component({
    selector: 'app-automation-list',
    templateUrl: 'automation-list.component.html',
    styleUrls: ['../../../../../../assets/infinite-scroll-table.scss']
})
export class AutomationListComponent implements OnDestroy, AfterViewInit {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();

    @ViewChild(MatSort, {static: false}) sort: MatSort = new MatSort();
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator = new MatPaginator(
        new MatPaginatorIntl(),
        ChangeDetectorRef.prototype
    );

    isLoading: boolean = false;
    scrollData: IScrollData = {
        offset: 0,
        limit: 50,
        sort_column: 'created_at',
        sort_direction: 'desc',
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<Automation> = new MatTableDataSource<Automation>([]);
    displayedColumns = ['created_at', 'title', 'tag_category_title', 'tag_title', 'actions'];

    // feature flag
    public autoTagsEnabledFlag: boolean = false;

    constructor(
        public dialog: MatDialog,
        private automationService: AutomationService,
        private notificationsService: NotificationsServiceZipi,
        protected router: Router,
        protected featureFlagsService: FeatureFlagsService
    ) {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.autoTagsEnabledFlag = this.featureFlagsService.isFeatureEnabled('company:deals_auto_tagging');

                if (typeof this.autoTagsEnabledFlag === 'boolean' && !this.autoTagsEnabledFlag) {
                    this.router.navigate(['/default-page']);
                }
            });
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;

        this.paginator?.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.scrollData.limit = data.pageSize;
            this.scrollData.offset = data.pageSize * data.pageIndex;

            this.nextBatch();
        });

        this.nextBatch();
    }

    nextBatch() {
        // cancel previous batch requests
        this.unsubscribeBatch.next();
        this.isLoading = true;

        this.automationService
            .getAllAutomations(this.scrollData)
            .pipe(takeUntil(this.unsubscribeBatch))
            .subscribe((data) => {
                this.dataSource.data = data.result?.map((a) => {
                    return Object.assign(a, {trigger_condition: a.trigger_condition.map((c) => JSON.parse(c))});
                });
                this.scrollData.total = data._meta.total;
                this.isLoading = false;
            });
    }

    openCreateAutoTagDialog(auto: Automation) {
        const dialogRef = this.dialog.open(AutomationDialogComponent, {
            width: '500px',
            data: auto,
            disableClose: true
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.scrollData.offset = 0;
                    this.dataSource.data = [];
                    this.scrollData.total = 0;
                    this.nextBatch();

                    if (typeof result !== 'boolean') {
                        // ask about apply tag on all deals
                        this.dialogApplyNewTagToAllDeals(result);
                    }
                }
            });
    }

    deleteAutogeneratedTags(automation: Automation) {
        const dialogRef = this.dialog.open(DialogConfirmationComponent, {
            width: '320px',
            data: {
                title: 'Unapply Auto-tag',
                text: 'Please confirm you would like to unapply this tag from all associated items.',
                cancel: 'Cancel',
                confirmation: 'Unapply'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result && automation.automation_id) {
                    // delete tags
                    this.automationService.deleteAutogeneratedTags(automation.automation_id).then((data) => {
                        if (data) {
                            // update automation
                            this.automationService
                                .updateAutomation(Object.assign(automation, {is_autotag_applied: false}))
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((auto) => {
                                    // change boolean
                                    this.dataSource.data.forEach((a) => {
                                        if (a.automation_id === automation.automation_id) {
                                            a.is_autotag_applied = false;
                                        }
                                    });
                                });

                            this.notificationsService.addInfo('Autogenerated tags were successfully deleted');
                        }
                    });
                }
            });
    }

    deleteAutoTag(automation_id: number, index: number) {
        const dialogRef = this.dialog.open(DialogConfirmationComponent, {
            width: '320px',
            data: {
                title: 'Delete Auto-tag',
                text: 'Please confirm you would like to delete this Auto-tag and all its data.\n\nWarning - Removal will result in this tag being removed all associated items',
                cancel: 'Cancel',
                confirmation: 'Delete'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    // on delete - delete all connected tags
                    this.automationService
                        .deleteAutomation(automation_id, true)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((data) => {
                            const automations = this.dataSource.data;
                            automations.splice(index, 1);
                            this.dataSource.data = automations;

                            this.notificationsService.addInfo('Auto-tag was successfully deleted');
                        });
                }
            });
    }

    dialogApplyNewTagToAllDeals(auto: Automation) {
        const dialogRef = this.dialog.open(DialogConfirmationComponent, {
            width: '320px',
            data: {
                title: 'New Auto-tag',
                text: 'Would you like to apply this Auto-tag to all associated items?',
                cancel: 'Apply Later',
                confirmation: 'Apply Now'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.applyTagToAllDeals(auto);
                }
            });
    }

    dialogApplySavedTagToAllDeals(auto: Automation) {
        const dialogRef = this.dialog.open(DialogConfirmationComponent, {
            width: '320px',
            data: {
                title: 'Apply Auto-tag',
                text: 'Please confirm you would like to apply this tag to all associated items.',
                cancel: 'Cancel',
                confirmation: 'Apply'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.applyTagToAllDeals(auto);
                }
            });
    }

    applyTagToAllDeals(auto: Automation) {
        if (!auto.automation_id) {
            return;
        }
        this.automationService.applyTagToAllDeals(auto.automation_id).then((number) => {
            if (number) {
                this.notificationsService.addInfo(`Successfully added tags to ${number} deals`);
            } else {
                this.notificationsService.addInfo('Conditions for this Auto-tag were not matched to any items.');
            }

            // change boolean
            this.dataSource.data.forEach((a) => {
                if (a.automation_id === auto.automation_id) {
                    a.is_autotag_applied = true;
                }
            });
        });
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        this.nextBatch();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
    }
}
