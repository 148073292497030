<button mat-icon-button class="close-button" mat-dialog-close="true" tabindex="-1" (click)="dialogRef.close('return')">
    <mat-icon>close</mat-icon>
</button>

<div mat-dialog-title>
    <h3>{{ data.title }}</h3>
</div>

<mat-dialog-content>
    <p *ngIf="data.message">
        {{ data.message }}
    </p>

    <p *ngFor="let msg of data.messages">
        {{ msg }}
    </p>

    <dl *ngFor="let msg of data.definitions" class="row">
        <dl class="col-9">{{ msg[0] }}</dl>
        <dd class="col-3">{{ msg[1] }}</dd>
    </dl>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
    <button class="mr-2" mat-raised-button (click)="dialogRef.close('discard')" tabindex="-1">Discard</button>
    <button
        mat-raised-button
        class="mr-2 mat-primary"
        *ngIf="data.isExistSaveChangesFunction"
        (click)="dialogRef.close('save')"
        tabindex="1"
    >
        Save
    </button>
</mat-dialog-actions>
