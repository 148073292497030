export function getNextActivePanel<T extends string>(current: string, list: Array<{title: string; value: T}> = []) {
    const maxIndex = list.length ? list.length - 1 : 0;
    const currentReportItemIndex = list.findIndex((item) => item.value === current);
    const nextIndex = currentReportItemIndex + 1;
    let nextValue: T | '' = '';
    if (nextIndex <= maxIndex) {
        nextValue = list[nextIndex].value;
    }

    return {
        name: nextValue,
        isLast: nextIndex === maxIndex
    };
}

export const isLastPanelItem = (current: string, list: Array<{title: string; value: string}> = []) => {
    const maxIndex = list.length ? list.length - 1 : 0;
    const currentReportItemIndex = list.findIndex((item) => item.value === current);

    return currentReportItemIndex === maxIndex;
};
