import {Component, Input} from '@angular/core';
import {IReportsPreviewResultData} from '../../../../store/reports.reducer';
import {entityRouterListByJournalType} from '../../../../constants';

@Component({
    selector: 'reports-preview-journal',
    templateUrl: './journal.component.html',
    styleUrls: ['./journal.component.scss']
})
export class JournalReportPreviewComponent {
    @Input() reportData: IReportsPreviewResultData | null = null;

    public entityRouterListByJournalType = entityRouterListByJournalType;
}
