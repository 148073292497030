import {Action} from '@ngrx/store';
import {IUploadedFile} from './indicators.reducer';

export const HTTP_REQUEST_START = '[indicators] HTTP_REQUEST_START';
export const HTTP_REQUEST_END = '[indicators] HTTP_REQUEST_END';

export const UPLOAD_FILE_START = '[indicators] UPLOAD_FILE_START';
export const UPLOAD_FILE_END = '[indicators] UPLOAD_FILE_END';
export const CLEAR_UPLOAD_LIST = '[indicators] CLEAR_UPLOAD_LIST';

export class HttpRequestStartedAction implements Action {
    readonly type: string = HTTP_REQUEST_START;
    constructor(public payload?: any) {}
}

export class HttpRequestEndedAction implements Action {
    readonly type: string = HTTP_REQUEST_END;
    constructor(public payload?: any) {}
}

export class UploadFileStartAction implements Action {
    readonly type: string = UPLOAD_FILE_START;

    constructor(public payload?: IUploadedFile) {} // filename
}

export class UploadFileEndAction implements Action {
    readonly type: string = UPLOAD_FILE_END;

    constructor(public payload?: IUploadedFile) {}
}

export class ClearUploadListAction implements Action {
    readonly type: string = CLEAR_UPLOAD_LIST;
    constructor(public payload?: any) {}
}
