<section class="sidebar-body">
    <!------- Table of Journal Templates ------->
    <div class="table-background" style="height: calc(100vh - 180px)">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-50">Template Title</th>
                <td mat-cell *matCellDef="let element" class="w-50">
                    {{ element.title }}
                </td>
            </ng-container>

            <ng-container matColumnDef="line_item_total">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25">Amount</th>
                <td mat-cell *matCellDef="let element" class="w-25 px-1 text-right">
                    <ng-container *ngIf="element.line_item_total; else noTotal">
                        {{ element.line_item_total | currency }}
                    </ng-container>
                    <ng-template #noTotal> - </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="notes">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25">Notes</th>
                <td mat-cell *matCellDef="let element" class="w-25">
                    {{ element.notes }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [routerLink]="['/company/finance/journaltemplates', row.id]"
                class="zp-table-row"
            ></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator class="custom-paginator" [length]="scrollData.total" [pageSize]="scrollData.total">
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 text-center no-items">
            There are no journal templates.
        </div>
    </div>
</section>
