<!------- View deal Header ------->
<mat-toolbar
    class="deal-toolbar d-flex align-items-center justify-content-between"
    *ngIf="selectedDeal && !createBoardMode"
>
    <div class="d-flex align-items-center left-header-side">
        <button type="button" mat-icon-button (click)="sidenav.toggle(); sidebarOpened$.next(); currentSidebarTab = ''">
            <mat-icon>menu</mat-icon>
        </button>

        <h2 class="header-text m-0">
            <ng-container *ngIf="selectedDeal.name; else nameless">
                <span>{{ selectedDeal.name }}</span>
            </ng-container>
            <ng-template #nameless>
                <span *ngIf="selectedDeal.address">{{ selectedDeal.address }} </span>
                <span *ngIf="selectedDeal.unit">{{ selectedDeal.unit }}</span>
            </ng-template>
        </h2>
    </div>

    <!------- Manage deal buttons ------->
    <div class="d-flex">
        <button
            mat-button
            type="button"
            rbac
            [rule]="{deals__view_payouts: true}"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            (click)="sidenav.close(); sidebarOpened$.next('finance'); currentSidebarTab = 'finance'"
            class="rounded-0"
            [ngClass]="{'active-tab': currentSidebarTab === 'finance'}"
            [disabled]="isTopBarButtonsLocked"
        >
            Payouts
        </button>
        <button
            mat-button
            type="button"
            rbac
            [rule]="{deals__view_communication: true}"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            (click)="
                sidenav.close();
                sidebarOpened$.next('commission-instructions');
                currentSidebarTab = 'commission-instructions'
            "
            class="rounded-0"
            [ngClass]="{'active-tab': currentSidebarTab === 'commission-instructions'}"
            [disabled]="isTopBarButtonsLocked"
        >
            Commission Instructions
        </button>
        <button
            mat-button
            type="button"
            rbac
            [rule]="{deals__view_activity: true}"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            (click)="sidenav.close(); sidebarOpened$.next('activity'); currentSidebarTab = 'activity'"
            class="rounded-0"
            [ngClass]="{'active-tab': currentSidebarTab === 'activity'}"
            [disabled]="isTopBarButtonsLocked"
        >
            Activity
        </button>
        <button
            mat-button
            type="button"
            rbac
            [rule]="{deals__view_override: true}"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'inline-block'}"
            [denyStyle]="{display: 'none'}"
            (click)="sidenav.close(); sidebarOpened$.next('override'); currentSidebarTab = 'override'"
            class="rounded-0"
            [ngClass]="{
                'button-override-indicator': isDealOverridden,
                'active-tab': currentSidebarTab === 'override'
            }"
            [disabled]="isTopBarButtonsLocked"
        >
            <div style="align-items: center; display: flex; justify-content: center">
                Rules
                <mat-icon
                    *ngIf="isDealOverridden && dealProcessing.dealFG?.controls?.apply_rules?.value"
                    color="warn"
                    style="margin-left: 3px"
                    >error</mat-icon
                >
            </div>
        </button>

        <button
            mat-icon-button
            class="mat-default edit-button"
            *ngIf="isEditSelectedDealAllowed"
            (click)="router.navigate(['/deals', 'edit', this.selectedDeal.id])"
        >
            <mat-icon>edit</mat-icon>
        </button>
    </div>
</mat-toolbar>

<mat-sidenav-container [ngStyle]="createBoardMode ? {height: 0} : {height: containerCssHeight}">
    <!------- Left side bar ------->
    <mat-sidenav #sidenav mode="side" opened class="zp-sidenav" [ngStyle]="sidenavStyle">
        <!-- Deal board with filters -->
        <app-deal-board
            *ngIf="!createBoardMode"
            [selectedDeal]="!!selectedDeal"
            [dealsBoard]="dealsBoard"
            [boardsList]="boardsList"
            [activeTabIndex]="activeTabIndex"
            [boardMeta]="boardMeta"
            [defaultBoardIndex]="defaultBoardIndex"
            (frontendFilterChanged)="frontendFilter$.next($event)"
            (dealsBoardChanged)="dealsBoard = $event; boardChanged$.next(this.dealsBoard)"
            (savedActiveTabEmitter)="savedActiveTabEmitter.next($event)"
            (createBoardModeEmitter)="createBoardModeEmitter.emit($event)"
            (dealsBoardRemoved)="dealsBoardRemoved.emit($event); boardsList = $event"
            (dealsBoardUpdated)="boardUpdated$.next(this.dealsBoard)"
            (offsetHeightEmitter)="offsetHeight$.next($event)"
        ></app-deal-board>

        <div class="page-container" *ngIf="!isBusy && dealsBoard && !createBoardMode">
            <app-lazy-loading
                class="d-flex w-100"
                *ngIf="deals.length > 0 && offsetTopToLazyLoading$"
                [data]="deals"
                [itemHeightPx]="123"
                [useInfiniteScrollTop]="false"
                [offsetTop]="offsetTopToLazyLoading$"
                [closeSubject]="false"
                [minBufferPx]="500"
                [maxBufferPx]="1000"
                (scrolledDown)="nextBatch($event)"
                [checkViewport]="checkViewport$"
                [scrollToIndex]="scrollToIndex$"
            >
                <ng-template let-deal="listItem" let-idx="index">
                    <div class="deal-item-container" *ngIf="!selectedDeal" [ngClass]="idx === 0 ? 'pt-2' : ''">
                        <app-expanded-deal-list-item
                            #dealComponent
                            [deal]="deal"
                            [idx]="idx"
                            [dealTemplates]="dealTemplates"
                            [dealsIdsToRemove]="selectedDeals"
                            (selectedDealEmitter)="handleSelectDeal($event)"
                            (toggleDealCheckboxEmitter)="toggleDealCheckbox($event)"
                        ></app-expanded-deal-list-item>
                    </div>

                    <div
                        class="deal-item-container"
                        *ngIf="selectedDeal"
                        [ngStyle]="selectedDeal.id === deal.id ? {'background-color': '#ecf4ff'} : null"
                    >
                        <app-narrowed-deal-list-item
                            [deal]="deal"
                            [dealTemplates]="dealTemplates"
                            (selectedDealEmitter)="handleSelectDeal($event)"
                        ></app-narrowed-deal-list-item>
                    </div>
                </ng-template>
            </app-lazy-loading>

            <div class="py-3 text-muted" *ngIf="deals.length === 0">No deals to satisfy your filter parameters.</div>
        </div>

        <app-multi-selection-toolbar
            [numberSelected]="selectedDeals.length"
            [toolbarMinWidth]="'880px'"
            selectionName="Deal"
            *ngIf="selectedDeals.length > 0"
        >
            <button
                style="width: 105px; margin-left: 5px"
                class="mat-button"
                rbac
                [anyRule]="[{deals__delete_own: true}, {deals__delete_any: true}]"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                (click)="removeDeals()"
            >
                <mat-icon class="action-button-icon">delete</mat-icon>
                Delete
            </button>

            <button class="mat-button" (click)="downloadPayStub()" *ngIf="isPayStubPrintAvailable">
                <span style="width: 18px; height: 18px" class="material-symbols-outlined action-button-icon"
                    >download</span
                >
                Download {{ selectedDeals.length > 1 ? 'PayStubs' : 'PayStub' }}
            </button>
        </app-multi-selection-toolbar>
    </mat-sidenav>

    <!------- Right side bar ------->
    <mat-sidenav-content style="transition: none">
        <app-view-deal
            *ngIf="!createBoardMode"
            [dealIsSelected$]="dealIsSelected$"
            [sidebarOpened$]="sidebarOpened$"
            (isDealOverriddenEmitter)="isDealOverridden = $event"
            (currentSidebarTabEmitter)="currentSidebarTab = ''"
            (topBarButtonsState)="changeTopBarButtonsState($event)"
            (viewDealLoadedEmitter)="checkIfEditSelectedDealAllowed($event)"
        ></app-view-deal>
    </mat-sidenav-content>
</mat-sidenav-container>
