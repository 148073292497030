export class LedgerAccount {
    static get types_order(): string[] {
        return [
            'cash',
            'bank',
            'bank_account',
            'trust',
            'payment_clearing',
            'investment',
            'other_current_asset_accounts_receivable',
            'other_current_asset',
            'inventory',
            'fixed_asset',
            'other_asset',

            'current_liability',
            'credit_card',
            'trust_liability',
            'other_current_liability',
            'long_term_liability',
            'other_liability',

            'equity',
            'common_stock',
            'preferred_stock',
            'treasury_stock',
            'additional_paid_in_capital',
            'partner_contributions',
            'partner_distributions',
            'retained_earnings',

            'income',
            'cost_of_goods_sold',
            'expense',
            'other_income',
            'other_expense'
        ];
    }
}
