<mat-card class="compact-card" [class.none]="!isDepositShow">
    <!--    <div *ngIf="!isDepositShow" class="d-flex justify-content-center sp-cursor-pointer" (click)="showDeposit()">Click For Deposit</div>-->
    <!--    <div *ngIf="isDepositShow" class="d-flex justify-content-center sp-cursor-pointer" (click)="isDepositShow = !isDepositShow">Close Deposit</div>-->

    <mat-card-header class="header deal-header w-100 align-items-baseline">
        <mat-card-title class="deal-header-title w-100"> Deposits </mat-card-title>
        <span class="sp-spacer"></span>
        <div *ngIf="isDepositShow && emptyRequests" class="d-flex justify-content-end sp-cursor-pointer">
            <button mat-icon-button (click)="closeDeposit()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-card-header>

    <!--    <div class="w-100 font-weight-bold d-flex" style="color: white; background-color: red; font-size: 17px; height: 60px; text-align: center" *ngIf="showNeedToSaveBar">-->
    <!--        <span style="margin: auto">The Deal Deposit section has changed. To refresh financial part of Deal please click "Save Changes" button below.</span>-->
    <!--    </div>-->

    <div *ngIf="isDepositShow" class="mx-3">
        <div>
            <app-deposit-request-list
                [dealId]="dealId"
                [division_id]="dealFG.controls.division__company_group_fk_id.value"
                [disabled]="dealFG.controls.disbursement_approved.value"
                [requestsList]="requestsList"
                [dealDepositAmounts]="dealDeposit"
                (refreshRequests)="refreshRequestsList()"
            >
            </app-deposit-request-list>

            <app-deposit-payment-list
                [dealId]="dealId!"
                [disabled]="dealFG.controls.disbursement_approved.value"
                [dealAddress]="dealAddress!"
                [requestsList]="requestsList"
                [paymentsList]="paymentsList"
                [dealDepositAmounts]="dealDeposit!"
                (refreshPayments)="refreshRequestsList()"
            >
            </app-deposit-payment-list>

            <app-deposit-release-list
                [dealId]="dealId!"
                [disabled]="dealFG.controls.disbursement_approved.value"
                [dealAddress]="dealAddress!"
                [dealName]="dealName!"
                [requestsList]="requestsList"
                [paymentsList]="paymentsList"
                [releaseList]="releaseList"
                [dealDepositAmounts]="dealDeposit!"
                (refreshReleases)="refreshRequestsList()"
            >
            </app-deposit-release-list>
        </div>

        <div class="d-flex justify-content-end font-weight-bold mt-2">
            Balance Preview: {{ (dealDeposit?.unreleased_balance | currency) || (0 | currency) }}
        </div>

        <!--        <mat-card>-->

        <!--        </mat-card>-->
        <!--        <button mat-raised-button >-->
        <!--            Deposit-->
        <!--            <mat-icon>add</mat-icon>-->
        <!--        </button>-->
    </div>
</mat-card>
