<div class="pay-at-closing">
    <div
        class="active-element pay-at-closing-value"
        [matMenuTriggerFor]="matMenu"
        [matTooltip]="canOverride ? (currentPayAtClosingValue?.isOverridden ? overriddenTooltip : defaultTooltip) : ''"
    >
        <mat-icon [class.material-icons-outlined]="currentPayAtClosingValue?.isIconOutline">
            {{ currentPayAtClosingValue?.icon }}
        </mat-icon>
        <span class="nowrap">{{ currentPayAtClosingValue?.title }}</span>
    </div>

    <mat-menu class="available-values-menu" #matMenu="matMenu">
        <ng-container *ngFor="let availableValue of availableValues; let i = index">
            <button
                mat-menu-item
                [disabled]="disabled"
                *ngIf="
                    !availableValue.isHidden &&
                    currentPayAtClosingValue &&
                    availableValue.system_key !== currentPayAtClosingValue.system_key
                "
                class="pay-at-closing-value"
                (click)="onValueChange(i)"
            >
                <mat-icon [class.material-icons-outlined]="availableValue.isIconOutline">
                    {{ availableValue.icon }}
                </mat-icon>
                {{ availableValue?.title }}
            </button>
        </ng-container>
    </mat-menu>
</div>
