import {Component, ElementRef, Inject, OnDestroy, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {IUser} from '@cyberco-nodejs/zipi-typings';
import {UserAdminService} from '@app/services/user-admin.service';
import {SkyslopeAuth} from '@app/modules/auth/services/okta/skyslope-auth.service';
import {UserContext} from '@skyslope/auth-js';
import {AuthService} from '@app/services/auth.service';
import {User} from '@app/models/user';
import {CompanyModalComponent} from '@app/modules/account-info/users-licenses/support-account-lookup-modal-dialog/company-modal.component';

const columnName = {
    columnDef: 'name',
    columnName: 'Name'
};
const columnTitle = {
    columnDef: 'title',
    columnName: 'Title'
};

const columnRoles = {
    columnDef: 'roles',
    columnName: 'Roles'
};

const columnLogin = {
    columnDef: 'login',
    columnName: 'Login'
};

const columnEdit = {
    columnDef: 'edit',
    columnName: 'Edit'
};

const columnStatus = {
    columnDef: 'status',
    columnName: 'Status'
};

@Component({
    selector: 'app-support-account-lookup-modal-dialog',
    templateUrl: 'support-account-lookup-modal.component.html',
    styleUrls: ['./support-account-lookup-modal.component.scss']
})
export class SupportAccountLookupModalComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    displayedColumns = ['name', 'email', 'company', 'roles', 'userAccess', 'adminAccess', 'login'];
    dataSource = new MatTableDataSource();
    pageIndex = 0;
    pageSize = 15;
    user: IUser | undefined;
    filterValue = '';
    isFetching: boolean = false;
    timer: any;
    sort_column: string | undefined;
    sort_direction: string | undefined;
    lastQueryFilter: object = {
        limit: 0,
        offset: 0,
        filterValue: '',
        sort_column: '',
        sort_direction: ''
    };
    adminBasicSettings = {
        company_plan_filter: 'zipi_access_broker',
        sort_users_column: 'name',
        sort_users_direction: 'asc',
        sort_companies_column: 'title',
        sort_companies_direction: 'asc'
    };
    usersContentElementHolder:
        | {
              offsetHeight: any;
              scrollHeight: any;
              removeAllListeners: () => void;
              addEventListener: (arg0: string, arg1: (event: any) => void) => void;
          }
        | undefined;

    private _unsubscribe: Subject<void> = new Subject();
    public currentUser: UserContext | undefined | null;
    public showError: boolean = false;
    public currentSystemUser: User | null = null;

    constructor(
        public dialogRef: MatDialogRef<SupportAccountLookupModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router,
        private userService: UserAdminService,
        private skyslopeAuthService: SkyslopeAuth,
        public authService: AuthService
    ) {
        this.router.events.pipe(takeUntil(this._unsubscribe)).subscribe((event) => {
            this.dialogRef.close();
        });
    }

    @ViewChild(MatSort, {static: true}) sort: MatSort | undefined;
    @ViewChild('usersSection', {static: false}) usersSection: ElementRef | undefined;

    async ngOnInit() {
        const isAuthenticated = await this.skyslopeAuthService.isAuthenticated();
        if (isAuthenticated) {
            this.skyslopeAuthService.getUser().then((user) => {
                this.currentUser = user;
                // Calling Admin User Endpoint to ensure current user has Admin Access.
                this.userService
                    .getAdminMe()
                    .then((observable) => {
                        observable.pipe(takeUntil(this.unsubscribe)).subscribe((response: any) => {
                            if (!response.ok) {
                                this.showError = true;
                            } else {
                                // @ts-ignore
                                this.authService.getSystemUser().then((systemUser: User) => {
                                    return (this.currentSystemUser = systemUser);
                                });
                            }
                        });
                    })
                    .catch((err) => {
                        console.error(`err => ${JSON.stringify(err)}`);
                        this.showError = true;
                    });
            });
        } else {
            this.showError = true;
        }
    }

    ngAfterViewInit() {
        this.usersContentElementHolder = this.usersSection?.nativeElement.parentElement;
        this.initFetch();
    }
    compareFilterQuery(
        a: {filter: any; offset: any; limit: any; sort_column: any; sort_direction: any},
        b: {filter: any; offset: any; limit: any; sort_column: any; sort_direction: any}
    ) {
        return (
            a.filter === b.filter &&
            a.offset === b.offset &&
            a.sort_column === b.sort_column &&
            a.sort_direction === b.sort_direction &&
            a.limit === b.limit
        );
    }

    fetchNextPartOfTable() {
        const filterQuery = {
            filter: encodeURIComponent(this.filterValue),
            offset: Number(this.pageSize * this.pageIndex),
            limit: Number(this.pageSize),
            sort_column: this.sort?.active,
            sort_direction: this.sort?.direction
        };
        // @ts-ignore
        if (!this.compareFilterQuery(filterQuery, this.lastQueryFilter)) {
            this.lastQueryFilter = filterQuery;
            this.userService.getPartOfUsers(filterQuery).then((data) => {
                data.pipe(takeUntil(this.unsubscribe)).subscribe((u: any) => {
                    const userList = u.body.map((obj: any) => {
                        const roles = obj.roles.split(',').filter((element: string) => element != '');

                        obj.roles = this.uniqFilter(roles).join(', ');
                    });

                    if (this.pageIndex === 0) {
                        // @ts-ignore
                        this.dataSource.data = u.body;
                    } else {
                        this.dataSource.data = this.dataSource.data.concat(u.body);
                    }

                    this.isFetching = false;

                    if (this.usersSection) {
                        window.setTimeout(() => {
                            // @ts-ignore
                            if (
                                u.length >= this.pageSize &&
                                this.usersContentElementHolder?.offsetHeight ===
                                    this.usersContentElementHolder?.scrollHeight
                            ) {
                                this.nextPageFetch();
                            }
                        }, 500);

                        // @ts-ignore
                        if (u.length < this.pageSize) {
                            this.usersContentElementHolder?.removeAllListeners();
                        }
                    }
                });
            });
        }
    }
    addScrollListener() {
        this.usersContentElementHolder?.removeAllListeners();
        this.usersContentElementHolder?.addEventListener('scroll', (event) => {
            // note: do "-10" correction to care about "almost on bottom" case
            if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 10) {
                this.nextPageFetch();
            }
        });
    }
    nextPageFetch() {
        if (this.isFetching) {
            return;
        }

        this.pageIndex = this.pageIndex + 1;
        this.fetchNextPartOfTable();
    }

    initFetch() {
        this.pageIndex = 0;

        this.fetchNextPartOfTable();
        this.addScrollListener();
    }

    forceFilterSearch(filterValue: string) {
        filterValue = filterValue.trim().toLowerCase();
        this.filterValue = filterValue;
        clearTimeout(this.timer);
        this.initFetch();
    }
    sortChange() {
        // @ts-ignore
        const localStorageAdminSettings = JSON.parse(window.localStorage.getItem('settings'));
        localStorageAdminSettings.sort_users_column = this.sort?.active;
        localStorageAdminSettings.sort_users_direction = this.sort?.direction;
        window.localStorage.setItem('settings', JSON.stringify(localStorageAdminSettings));
        this.sort_column = this.sort?.active;
        this.sort_direction = this.sort?.direction;
        this.initFetch();
    }

    applyFilter(userFilterValue: string) {
        this.filterValue = userFilterValue.trim().toLowerCase();
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            this.initFetch();
        }, 500);
    }

    async loginAs(user: any, event: {stopPropagation: () => void; preventDefault: () => void}) {
        console.info(`user => ${JSON.stringify(user)}`);
        this.router.navigate([`/auth/sign-in/as/${user.id}`]);
        event.stopPropagation();
        event.preventDefault();
        return false;
    }

    clearLoginAs() {
        this.authService.clearLoginAsUID();
        this.authService.loadAuth().then((isLoaded) => {
            if (isLoaded) {
                setTimeout(() => {
                    window.location.href = '/default-page';
                }, 500);
            }
        });
    }

    uniqFilter(a: any[]) {
        const seen = {};
        return a.filter(function (item: PropertyKey) {
            // @ts-ignore
            return seen.hasOwnProperty(item) ? false : (seen[item] = true);
        });
    }

    ngOnDestroy() {
        this._unsubscribe.next();
        this._unsubscribe.complete();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    protected readonly CompanyModalComponent = CompanyModalComponent;
}
