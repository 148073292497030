import {BaseModel} from './base.model';
import DateTimeFormat = Intl.DateTimeFormat;
import {VIEW_MY_DEALS} from '../modules/shared/components/deals-view-picker/deals-view-picker.component';
import {DealsBoardBackendFilterSettingsByCompany} from '../modules/deals/components/deal/common/deal.models';
import {Profile} from './profile';

export class Widget extends BaseModel {
    widget_id: number | null = null;
    name: string | null = null;
    board_id: number | null = null;
    type: string | null = null;
    uid_for_modify?: number;
    data?: any;
    na?: any;
    settings: {
        range?: string;
        picker_date_from?: DateTimeFormat;
        picker_date_to?: DateTimeFormat;
        container: number;
        order: number;
        deal_view?: string;
        period?: string;
        by_company?: {[id: number]: DealsBoardBackendFilterSettingsByCompany};
        show_net_by?: string;
        financial_element_id?: number;
        profile_id?: number | null;
        division_id?: number | null;
        entities?: any[];
        period_start_date?: string | null;
        period_end_date?: string | null;
        avg_type?: string;
        deal_type?: string;
        deal_status?: string;
        net_goal?: string;
        gci_goal?: string;
        units_goal?: string;
        volume_goal?: string;
    } = {
        container: 0,
        order: 0,
        deal_view: VIEW_MY_DEALS,
        by_company: {},
        show_net_by: 'user'
    };

    setupBackendSettings(profiles: Profile[]): Widget {
        if (typeof this.settings.by_company === 'undefined') {
            this.settings.by_company = {};
        }
        profiles.forEach((profile) => {
            if (
                this.settings &&
                this.settings.by_company &&
                profile.id &&
                typeof this.settings.by_company[profile.id] === 'undefined'
            ) {
                this.settings.by_company[profile.id] = new DealsBoardBackendFilterSettingsByCompany();
                this.settings.by_company[profile.id].enabled = true;
                if (this.settings.deal_view) {
                    this.settings.by_company[profile.id].deals_view_mode = this.settings.deal_view;
                }
                this.settings.by_company[profile.id].filter_by_deal_members = [];
            }
        });
        if (!this.settings.show_net_by) {
            this.settings.show_net_by = 'user';
        }
        return this;
    }

    setType(type: string): Widget {
        this.type = type;
        return this;
    }
}
