import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {IJournalBase} from '@cyberco-nodejs/zipi-typings';
import {JournalsTemplatesService} from 'app/services/api/finance/journal-templates.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationsService} from 'angular2-notifications';
import {IMaybeValidJournalTemplate} from '../journal-template/journal-template.component';
import {assign} from 'lodash-es';

@Component({
    selector: 'app-create-journal-template',
    templateUrl: 'create-journal-template.component.html',
    styleUrls: ['create-journal-template.component.scss']
})
export class CreateJournalTemplateComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() isOpened: boolean = false;

    closeEvent: Subject<boolean> = new Subject();

    journalTemplateId: number | null | undefined = null;
    journalTemplateBase: IJournalBase | null = null;

    formGroup: UntypedFormGroup = this.fb.group({
        title: [null, Validators.required]
    });

    maybeValidJournal: IMaybeValidJournalTemplate = {valid: false, template: null};

    constructor(
        private journalsTemplatesService: JournalsTemplatesService,
        private route: ActivatedRoute,
        public router: Router,
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService
    ) {}

    ngOnInit() {
        this.route.paramMap
            .pipe(
                map((pm) => +(pm.get('id') as string)),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                if (id) {
                    this.journalsTemplatesService
                        .getJournalTemplateById(id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((journalTemplate) => {
                            const {journal_template_id, owner__company_fk_id, title, ...journalBase} = journalTemplate;

                            this.journalTemplateId = journal_template_id;
                            this.journalTemplateBase = journalBase;
                            this.formGroup.get('title')!.patchValue(title);
                        });
                }
            });
    }

    onTemplateUpdate(event: IMaybeValidJournalTemplate) {
        this.maybeValidJournal = event;
    }

    validateForm() {
        if (this.formGroup.valid && this.maybeValidJournal.valid) {
            return true;
        }

        this.ntfs.warn('Form is not valid');
        this.formGroup.updateValueAndValidity();

        return false;
    }

    createJournalTemplate() {
        if (!this.validateForm()) {
            return;
        }

        const journalTemplate = assign(
            {},
            {title: this.formGroup.get('title')!.value},
            this.maybeValidJournal.template
        );

        this.journalsTemplatesService
            .createJournalTemplate(journalTemplate)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                this.router.navigate([`/company/finance/journals`]);
            });
    }

    updateJournalTemplate() {
        if (!this.validateForm()) {
            return;
        }

        const journalTemplate = assign(
            {},
            {
                journal_template_id: this.journalTemplateId as number,
                title: this.formGroup.get('title')!.value || ('' as string)
            },
            this.maybeValidJournal.template
        );

        this.journalsTemplatesService
            .updateJournalTemplate(journalTemplate)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                this.router.navigate([`/company/finance/journals`]);
            });
    }

    deleteJournalTemplate() {
        this.journalsTemplatesService
            .deleteJournalTemplate(this.journalTemplateId as number)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                this.router.navigate([`/company/finance/journals`]);
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.closeEvent.complete();
    }
}
