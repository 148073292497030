import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from 'app/services/user.service';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationsServiceZipi} from '../../notifications/notifications.service';
import {Profile} from '../../../models/profile';
import {SessionService} from '../../../services/session.service';
import {EmailChangeFormGroup} from './email-change.forms';
import {SignInFormGroup} from '../../../models/forms/sign-in.form';
import {ChangeEmailApi} from '../../../services/api/change-email.api';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-email-change',
    template: `
        <mat-card class="card">
            <mat-card-header>
                <div *ngIf="invitedProfile" class="title">
                    That is the last step for changing your email<br />
                    in <b>"{{ invitedProfile.company!.title }}"</b> company <br />
                    from <b>{{ invitedProfile.email }}</b> to <b>{{ invitedProfile.new_email }}</b>
                </div>
            </mat-card-header>

            <mat-card-content *ngIf="isLoaded">
                <div *ngIf="!isSignUpRequired">
                    <div class="title">Please Log In</div>
                    <form [formGroup]="signInForm">
                        <mat-form-field>
                            <input matInput type="email" name="email" placeholder="Email" [formControlName]="'email'" />
                        </mat-form-field>

                        <mat-form-field>
                            <input
                                matInput
                                type="password"
                                name="password"
                                placeholder="Password"
                                [formControlName]="'password'"
                            />
                        </mat-form-field>

                        <div class="flex-row">
                            <button mat-button class="mat-primary" (click)="onLoginSubmit(signInForm)">Submit</button>
                        </div>
                    </form>
                </div>
                <div *ngIf="isSignUpRequired">
                    <div class="title">Please Register</div>
                    <form [formGroup]="emailChangeForm">
                        <mat-form-field>
                            <input matInput type="email" name="email" placeholder="Email" [formControlName]="'email'" />
                        </mat-form-field>

                        <mat-form-field>
                            <input
                                matInput
                                required
                                type="text"
                                name="first_name"
                                placeholder="First Name"
                                [formControlName]="'first_name'"
                            />
                        </mat-form-field>

                        <mat-form-field>
                            <input
                                matInput
                                required
                                type="text"
                                name="last_name"
                                placeholder="Last Name"
                                [formControlName]="'last_name'"
                            />
                        </mat-form-field>

                        <mat-form-field>
                            <input
                                matInput
                                type="password"
                                name="password"
                                placeholder="Password"
                                [formControlName]="'password'"
                            />
                        </mat-form-field>

                        <mat-form-field>
                            <input
                                matInput
                                type="password"
                                name="password_confirm"
                                [formControlName]="'password_confirm'"
                                placeholder="Password Confirmation"
                            />
                        </mat-form-field>

                        <div class="flex-row">
                            <button mat-button class="mat-primary" (click)="onRegisterSubmit(emailChangeForm)">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </mat-card-content>
        </mat-card>
    `,
    styleUrls: ['email-change.component.scss']
})
export class EmailChangeComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    protected changeEmailHash: string | null = null;
    public invitedProfile: Profile | null = null;
    public isSignUpRequired = true;
    public isLoaded = false;

    public emailChangeForm: EmailChangeFormGroup = new EmailChangeFormGroup();
    public signInForm: SignInFormGroup = new SignInFormGroup('');

    constructor(
        protected userService: UserService,
        protected changeEmailApi: ChangeEmailApi,
        public sessionService: SessionService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected notificationsService: NotificationsServiceZipi,
        public authService: AuthService
    ) {
        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['change_hash']) {
                this.changeEmailHash = params['change_hash'];
            }
        });
    }

    ngOnInit() {
        if (!this.changeEmailHash) {
            this.notificationsService.addError('No change_hash provided');

            throw new Error('No change_hash provided');
        }
        return this.authService
            .logout()
            .then(() => {
                return this.changeEmailApi.getChangeEmailProfileInfo(this.changeEmailHash!);
            })
            .then((invitedProfileInfo) => {
                this.invitedProfile = invitedProfileInfo.profile;
                if (!this.invitedProfile) {
                    this.notificationsService.addError('Invited profile not provided');

                    throw new Error('Invited profile not provided');
                }

                this.signInForm.patchValue({email: this.invitedProfile.new_email});
                this.emailChangeForm.patchValue({email: this.invitedProfile.new_email});

                if (invitedProfileInfo.existingUser) {
                    this.isSignUpRequired = false;
                    this.emailChangeForm.patchValue({
                        first_name: invitedProfileInfo.existingUser.first_name,
                        last_name: invitedProfileInfo.existingUser.last_name
                    });
                } else {
                    if (invitedProfileInfo.profile.first_name) {
                        this.emailChangeForm.patchValue({
                            first_name: invitedProfileInfo.profile.first_name
                        });
                    }
                    if (invitedProfileInfo.profile.last_name) {
                        this.emailChangeForm.patchValue({
                            last_name: invitedProfileInfo.profile.last_name
                        });
                    }
                }

                this.isLoaded = true;
            });
    }

    onRegisterSubmit(form: EmailChangeFormGroup) {
        if (!form.valid) {
            this.notificationsService.addError('Not all required fields are filled');
            if (!form.errors) {
                return false;
            }
            Object.keys(form.errors).forEach((error) => {
                if (form.errors) {
                    this.notificationsService.addError(form.errors[error]);
                }
            });
            return false;
        }

        return this.authService
            .createUserWithEmailAndPassword(form.value.email, form.value.password)
            .then(() => this.authService.signInWithEmailAndPassword(form.value.email, form.value.password))
            .then(() => {
                if (!this.invitedProfile) {
                    this.notificationsService.addError('Invited profile not provided');

                    throw new Error('Invited profile not provided');
                }
                this.invitedProfile.first_name = form.value.first_name;
                this.invitedProfile.last_name = form.value.last_name;
                return this.afterAuth(false, {
                    first_name: form.value.first_name,
                    last_name: form.value.last_name
                });
            })
            .catch((err) => {
                this.notificationsService.addError(err.message);
            });
    }

    onLoginSubmit(form: SignInFormGroup) {
        form.controls.submit.setValue(true);
        if (!form.valid) {
            this.notificationsService.addError('Not all required fields are filled');
            return false;
        }
        return this.authService
            .signInWithEmailAndPassword(form.value.email, form.value.password)
            .then(() => {
                return this.afterAuth(false);
            })
            .catch((err) => {
                this.notificationsService.addError(err.message);
            });
    }

    afterAuth(isFullPageReload = false, userData = {}) {
        if (!this.invitedProfile || !this.invitedProfile.id || !this.invitedProfile.change_email_hash) {
            this.notificationsService.addError('Invited profile not provided');
            throw new Error('Invited profile not provided');
        }

        return this.changeEmailApi
            .completeEmailChange(this.invitedProfile.id, this.invitedProfile.change_email_hash, userData)
            .then(() => {
                this.authService.setCurrentProfile(this.invitedProfile!, true);
                return Promise.resolve();
            })
            .then(() => {
                if (!isFullPageReload) {
                    this.router.navigate(['/default-page']);
                    return;
                }
                window.location.replace('/default-page');
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
