import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {NotificationsService} from 'angular2-notifications';
import {ITransactionExternal, LedgerAccountTypes} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';
import {UsersAndLicencesApiService} from '../../../../account-info/users-licenses/users-and-licences.api.service';
import {IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import * as moment from 'moment';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';
import {amountValidator} from '../../../../../utilities';

export class CustomFormGroup extends UntypedFormGroup {
    public controls: {
        reference: UntypedFormControl;
        date: UntypedFormControl;
        amount: UntypedFormControl;
        paid_by__ledger_account_fk_id: UntypedFormControl;
    };

    constructor(controls: {
        reference: UntypedFormControl;
        date: UntypedFormControl;
        amount: UntypedFormControl;
        paid_by__ledger_account_fk_id: UntypedFormControl;
    }) {
        super(controls);
        this.controls = controls;
    }
}

@Component({
    selector: 'app-form-transfer-from-another-account',
    template: `
        <div [formGroup]="formGroup">
            <app-ledger-account-selector
                [ledgerAccountControl]="formGroup.controls.paid_by__ledger_account_fk_id"
                [placeholder]="'From Account'"
                [types]="types"
            >
            </app-ledger-account-selector>

            <app-date-picker [placeholder]="'Date'" [dateControl]="formGroup.controls.date"></app-date-picker>

            <mat-form-field class="w-100">
                <input matInput formControlName="reference" placeholder="Reference#" type="text" autocomplete="off" />
            </mat-form-field>

            <mat-form-field class="w-100">
                <mat-label>Amount</mat-label>
                <input
                    [maskito]="currencyMaskitoMask"
                    matInput
                    type="text"
                    formControlName="amount"
                    matTooltipPosition="above"
                    placeholder="Amount"
                    autocomplete="off"
                    required
                />
            </mat-form-field>
        </div>
        <div class="footer-row">
            <button
                mat-raised-button
                class="mat-primary"
                [disabled]="!formGroup.valid || createDisabled || !ledgerAccountId"
                (click)="save()"
            >
                Create
            </button>
            <button mat-button class="mat-primary" (click)="closeSidebar.emit()">Cancel</button>
        </div>
    `
})
export class FormTransferFromAnotherAccountComponent implements OnInit, OnDestroy {
    @Output() closeSidebar: EventEmitter<void> = new EventEmitter<void>();
    @Output() create: EventEmitter<object> = new EventEmitter<object>();
    @Input() createDisabled: boolean = false;
    @Input() bankTransaction: ITransactionExternal | undefined;
    @Input() ledgerAccountId: number | null = null;

    private unsubscribe: Subject<void> = new Subject();

    formGroup: CustomFormGroup = new CustomFormGroup({
        reference: new UntypedFormControl(null, []),
        date: new UntypedFormControl(null, Validators.required),
        amount: new UntypedFormControl(null, [Validators.required, amountValidator()]),

        paid_by__ledger_account_fk_id: new UntypedFormControl(null, Validators.required)
    });
    initFormGroupSnapshot = {};
    currencyMaskitoMask = currencyMaskitoOptions;

    profiles = [];
    gateway: IPaymentGateway | undefined;
    types: LedgerAccountTypes[] = ['other_current_asset', 'cash', 'bank', 'credit_card', 'equity', 'payment_clearing'];

    constructor(
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService,
        public dialog: MatDialog,
        public usersAndLicencesApiService: UsersAndLicencesApiService
    ) {}

    ngOnInit() {
        if (this.bankTransaction) {
            this.formGroup.controls.amount.patchValue(this.bankTransaction.amount);
            this.formGroup.controls.amount.disable();
            this.formGroup.controls.date.patchValue(this.bankTransaction.date);
        } else {
            this.formGroup.controls.date.patchValue(moment().format('YYYYMMDD'));
        }

        this.initFormGroupSnapshot = this.formGroup.getRawValue();
    }

    private validateForm() {
        if (!this.formGroup.valid) {
            this.ntfs.warn('Transfer from another account template is not valid');
            return false;
        }

        return true;
    }

    save() {
        if (!this.validateForm() || this.createDisabled) {
            return;
        }
        const dataRaw = this.formGroup.getRawValue();

        this.create.emit({
            date: dataRaw.date,
            reference: dataRaw.reference,
            amount: Number(cleanCurrencyString(dataRaw.amount)),

            paid_by__ledger_account_fk_id: dataRaw.paid_by__ledger_account_fk_id,
            bank_ledger_account_id: this.ledgerAccountId
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.closeSidebar.complete();
        this.create.complete();
    }
}
