<div class="dialog-container" style="max-height: 80vh">
    <!------- Header ------->
    <h2 mat-dialog-title *ngIf="!automation">Create Auto-tag</h2>
    <h2 mat-dialog-title *ngIf="automation">Edit Auto-tag</h2>

    <div *ngIf="automation && !disableButton" class="warning-block p-2 mb-3 w-100">
        <span class="font-weight-bold">Please note:</span> Changes to this Auto-tag will override and replace all
        associated items.
    </div>

    <!------- Automation Title ------->
    <mat-form-field class="w-100">
        <input
            matInput
            placeholder="Automation Title"
            [formControl]="automationFG.controls.title"
            required
            autocomplete="off"
        />
    </mat-form-field>
    <!------- Select Tag to apply ------->
    <mat-form-field class="w-100">
        <mat-select placeholder="Tags" [formControl]="automationFG.controls.action_option_id" required>
            <mat-option class="font-italic" (click)="createNewTag()" [value]="null"
                >+ Create new Tag Category</mat-option
            >
            <mat-option
                *ngFor="let tag of tags"
                [value]="tag.tag_id"
                (click)="unselectDeactivatedTag(tag)"
                [disabled]="tag.deactivated"
                [matTooltip]="tag.deactivated ? 'This Tag couldn\'t be selected because it\'s deactivated' : ''"
            >
                {{ tag.title }}
                <span class="font-italic text-muted">(category - {{ tag.category_title }})</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
    <!------- Item ------->
    <mat-form-field class="w-100 mb-5">
        <mat-select placeholder="Item" [formControl]="itemFC" required>
            <mat-option *ngFor="let type of items" [value]="type">
                {{ type | titlecase }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div mat-dialog-content class="dialog-content" *ngFor="let condition of conditionFG.controls; index as idx">
        <!------- Field ------->
        <mat-form-field class="w-100" *ngIf="itemFC.value === 'deal' && condition.controls.field">
            <mat-select
                placeholder="{{ itemFC.value | titlecase }} Field"
                [formControl]="condition.controls.field"
                required
                (selectionChange)="selectValueType($event.value, idx)"
            >
                <mat-option *ngFor="let type of dealFields" [value]="type.value">
                    {{ type.value | fromSnakeCase | titlecase }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!------- Condition type ------->
        <mat-form-field
            class="w-100"
            [class.d-none]="condition.controls.field.value === 'status' || condition.controls.field.value === 'type'"
            *ngIf="
                condition.controls.field &&
                condition.controls.valueType &&
                condition.controls.type &&
                condition.controls.valueType.value === 'text'
            "
        >
            <mat-select placeholder="Condition type" [formControl]="condition.controls.type" required>
                <mat-option *ngFor="let type of textFilteredTypes" [value]="type.value">
                    {{ type.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field
            class="w-100"
            *ngIf="
                condition.controls.type &&
                condition.controls.valueType &&
                condition.controls.valueType.value === 'number'
            "
        >
            <mat-select placeholder="Condition type" [formControl]="condition.controls.type" required>
                <mat-option *ngFor="let type of numberFilteredTypes" [value]="type.value">
                    {{ type.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!------- Filter ------->
        <div class="d-flex align-items-center">
            <ng-container
                *ngIf="
                    condition.controls.field &&
                    condition.controls.type &&
                    condition.controls.filter &&
                    condition.controls.field.value !== 'status' &&
                    condition.controls.field.value !== 'type' &&
                    condition.controls.type.value !== 'empty'
                "
            >
                <mat-form-field class="w-100">
                    <input
                        matInput
                        type="text"
                        placeholder="Filter"
                        [formControl]="$any(condition.controls.filter)"
                        required
                        autocomplete="off"
                    />
                </mat-form-field>
                <mat-icon
                    class="text-muted"
                    *ngIf="
                        condition.controls.type.value === 'in_array' ||
                        condition.controls.type.value === 'in_array_numbers'
                    "
                    matTooltip="List items separated by commas"
                >
                    help_outline
                </mat-icon>
            </ng-container>

            <mat-form-field
                class="w-100"
                *ngIf="
                    condition.controls.field && condition.controls.filter && condition.controls.field.value === 'status'
                "
            >
                <mat-select
                    placeholder="Select Status"
                    required
                    [formControl]="$any(condition.controls.filter)"
                    multiple
                >
                    <mat-option *ngFor="let status of object_keys(DEAL.status_SET)" [value]="status">
                        {{ DEAL.status_LABELS[status] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field
                class="w-100"
                *ngIf="
                    condition.controls.filter && condition.controls.field && condition.controls.field.value === 'type'
                "
            >
                <mat-select placeholder="Select Type" required [formControl]="$any(condition.controls.filter)" multiple>
                    <mat-option *ngFor="let type of object_keys(DEAL.type_SET)" [value]="type">
                        {{ DEAL.type_LABELS[type] }}
                    </mat-option>
                    <mat-option [value]="'none'">None</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!------- OR / AND ------->
        <mat-radio-group
            class="d-flex justify-content-center"
            *ngIf="
                condition.controls.operator && condition.controls.filter && condition.controls.filter.value && idx === 0
            "
            [formControl]="condition.controls.operator"
            (change)="selectRadioButton($event, idx)"
        >
            <mat-radio-button value="and" class="mr-3">AND</mat-radio-button>
            <mat-radio-button value="or" class="mr-3">OR</mat-radio-button>
            <mat-radio-button [value]="null">
                <span class="text-muted font-italic">Deselect</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <!------- Buttons ------->
    <div mat-dialog-actions class="row dialog-actions">
        <span class="sp-spacer"></span>

        <button
            mat-raised-button
            color="primary"
            class="fix-focus-outline m-2"
            [disabled]="disableButton"
            *ngIf="!automation"
            (click)="saveAutoTag()"
        >
            Save
        </button>
        <button
            mat-raised-button
            color="primary"
            class="fix-focus-outline m-2"
            [disabled]="disableButton"
            *ngIf="automation"
            (click)="updateAutoTag()"
        >
            Update
        </button>

        <button mat-button class="fix-focus-outline m-2" [disabled]="disableButton" (click)="close(false)">
            Cancel
        </button>
    </div>
</div>
