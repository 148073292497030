import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {Observable} from 'rxjs';
import {IExpense, IVendorCredit} from '@cyberco-nodejs/zipi-typings';
import {IScrollData} from '../../../models/scroll-data';

@Injectable()
export class ExpensesService {
    private url = '/finance/expenses/';

    constructor(protected requester: ServiceRequester) {}

    getExpenseById(expenseId: number): Observable<IExpense> {
        return this.requester.makeMsCall$(this.url + `${expenseId}`, 'GET', 'shipp');
    }

    getExpenses(params = {}): Observable<{result: IExpense[]; _meta: {total: number}}> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp', params);
    }

    getContactExpenses(contactId: number, data: IScrollData): Observable<{_meta: {total: number}; result: IExpense[]}> {
        return this.requester.makeMsCall$(this.url + `${contactId}/vendorcontact`, 'GET', 'shipp', data);
    }

    createExpense(data: {
        expense: Partial<IExpense>;
        vendor_credit: Partial<IVendorCredit> | null;
    }): Observable<IExpense> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    editExpense(
        expenseId: number,
        data: {expense: Partial<IExpense>; vendor_credit: Partial<IVendorCredit>}
    ): Observable<IExpense> {
        return this.requester.makeMsCall$(this.url + expenseId, 'PUT', 'shipp', data);
    }

    deleteExpense(expenseId: number): Observable<number> {
        return this.requester.makeMsCall$(this.url + expenseId, 'DELETE', 'shipp');
    }
}
