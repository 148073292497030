import {NgModule} from '@angular/core';

import {routing} from './contacts.routing';
import {SharedModule} from '../shared/shared.module';
import {GPlacesPickerModule} from '../gplaces-picker/gplaces-picker.module';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {contactsReducer} from './store/contacts.reducer';
import {ContactsEffectsService} from './store/contacts.effects';
import {ContactEditComponent} from './contact-edit';
import {ContactCardComponent} from './contact-card';
import {ContactCreateComponent} from './contact-create';
import {ContactCreateDialogComponent} from './contact-dialogs/contact-create-dialog/contact-create-dialog.component';
import {ContactsRootComponent} from './contacts-root/contacts-root.component';
import {GroupsModule} from '../account-info/groups/groups.module';
import {DivisionsModule} from '../account-info/divisions/divisions.module';
import {ContactEditPageComponent} from './contact-edit-page/contact-edit-page.component';
import {ContactLocationComponent} from './contact-location/contact-location.component';
import {ContactLocationSpacedComponent} from './contact-location-spaced/contact-location-spaced.component';
import {ContactInviteStatusComponent} from './contact-invite-status/contact-invite-status.component';
import {AddContactPersonDialogComponent} from './contact-dialogs/add-contact-person-dialog/add-contact-person-dialog.component';
import {AddLocationDialogComponent} from './contact-dialogs/add-location-dialog/add-location-dialog.component';
import {ContactDealsComponent} from './contact-deals/contact-deals.component';
import {ContactActivitiesComponent} from './contact-activities/contact-activities.component';
import {ContactShareComponent} from './contact-share/contact-share.component';
import {ContactFinanceComponent} from './contact-finance/contact-finance.component';
import {OpeningPerformanceValuesComponent} from './contact-edit/compliance/opening-performance-values.component';
import {OpeningPerformanceValuesItemComponent} from './contact-edit/compliance/opening-performance-values-item.component';
import {ContactInvoicesComponent} from './contact-finance/contact-finance-subtabs/contact-invoices/contact-invoices.component';
import {ContactRecurringInvoicesComponent} from './contact-finance/contact-finance-subtabs/contact-recurring-invoices/contact-recurring-invoices.component';
import {ContactBillsComponent} from './contact-finance/contact-finance-subtabs/contact-bills/contact-bills.component';
import {ContactExpensesComponent} from './contact-finance/contact-finance-subtabs/contact-expenses/contact-expenses.component';
import {MembershipComponent} from './contact-edit/compliance/membership.component';
import {MembershipItemComponent} from './contact-edit/compliance/membership-item.component';
import {MembershipOrganizationComponent} from './contact-edit/compliance/membership-organization.component';
import {CreateOrganizationDialogComponent} from './contact-edit/compliance/create-organization-dialog.component';
import {VendorCreditsDetailsDialogComponent} from './contact-dialogs/vendor-credits-details-dialog/vendor-credits-details-dialog.component';
import {CreditNotesDetailsDialogComponent} from './contact-dialogs/credit-notes-details-dialog/credit-notes-details-dialog.component';
import {ContactProductsMappingDialogComponent} from './contact-dialogs/contact-products-mapping-dialog/contact-products-mapping-dialog.component';
import {ContactNotesComponent} from './contact-notes/contact-notes.component';
import {CreateWildcardDialogComponent} from './contact-edit/compliance/create-wildcard-dialog.component';
import {ContactTypeCreationDialogComponent} from './contact-dialogs/contact-type-dialog/contact-type-creation-dialog.component';
import {AlertDeleteContactDialogComponent} from './contact-dialogs/alert-delete-contact-dialog.component';
import {DealPayoutsComponent} from './contact-edit/paymeny-methods/deal-payouts.component';
import {AddContactPayroll1099DialogComponent} from './contact-dialogs/add-contact-payroll-1099-dialog/add-contact-payroll-1099-dialog.component';
import {AddContactPayrollW2DialogComponent} from './contact-dialogs/add-contact-payroll-w2-dialog/add-contact-payroll-w2-dialog.component';
import {ContactPayrollsComponent} from './contact-payrolls/contact-payrolls.component';
import {ContactClassCreationDialogComponent} from './contact-dialogs/contact-class-dialog/contact-class-creation-dialog.component';
import {MergeContactsDialogComponent} from './contact-dialogs/merge-contacts-dialog/merge-contacts-dialog.component';
import {PaymentMethodsTabComponent} from './contact-card-tabs/payment-methods-tab/payment-methods-tab.component';
import {ManageMyPaymentMethodsAddedByContactDialogComponent} from './contact-dialogs/manage-my-payment-methods-added-by-contact-dialog/manage-my-payment-methods-added-by-contact-dialog.component';
import {SelectOwnGatewayForReceiveOnlyDialogComponent} from './contact-dialogs/select-own-gateway-for-receive-only-dialog/select-own-gateway-for-receive-only-dialog.component';
import {ManageContactPaymentMethodsInCurrentCmpanyDialogComponent} from './contact-dialogs/manage-contact-payment-methods-in-current-cmpany-dialog/manage-contact-payment-methods-in-current-cmpany-dialog.component';
import {OpeningPerformanceValuesMetricItemComponent} from './contact-edit/compliance/opening-performance-values-metric-item.component';

@NgModule({
    declarations: [
        DealPayoutsComponent,
        ContactsRootComponent,
        ContactEditComponent,
        ContactCardComponent,
        ContactCreateComponent,
        ContactCreateDialogComponent,
        ContactEditPageComponent,
        ContactLocationComponent,
        ContactLocationSpacedComponent,
        ContactInviteStatusComponent,
        AddContactPersonDialogComponent,
        AddLocationDialogComponent,
        AddContactPayroll1099DialogComponent,
        AddContactPayrollW2DialogComponent,
        ContactDealsComponent,
        ContactActivitiesComponent,
        ContactShareComponent,
        ContactFinanceComponent,
        ContactBillsComponent,
        ContactExpensesComponent,
        ContactRecurringInvoicesComponent,
        ContactInvoicesComponent,
        OpeningPerformanceValuesComponent,
        OpeningPerformanceValuesItemComponent,
        VendorCreditsDetailsDialogComponent,
        AlertDeleteContactDialogComponent,
        CreditNotesDetailsDialogComponent,
        MembershipComponent,
        MembershipItemComponent,
        MembershipOrganizationComponent,
        CreateOrganizationDialogComponent,
        ContactProductsMappingDialogComponent,
        ContactNotesComponent,
        CreateWildcardDialogComponent,
        ContactTypeCreationDialogComponent,
        ContactPayrollsComponent,
        ContactClassCreationDialogComponent,
        MergeContactsDialogComponent,
        PaymentMethodsTabComponent,
        ManageMyPaymentMethodsAddedByContactDialogComponent,
        ManageContactPaymentMethodsInCurrentCmpanyDialogComponent,
        SelectOwnGatewayForReceiveOnlyDialogComponent,
        OpeningPerformanceValuesMetricItemComponent
    ],
    imports: [
        GroupsModule,
        DivisionsModule,
        SharedModule,
        routing,
        GPlacesPickerModule,
        StoreModule.forFeature('contacts', contactsReducer),
        EffectsModule.forFeature([ContactsEffectsService])
    ],
    providers: [],
    exports: [],
    bootstrap: []
})
export class ContactsModule {}
