import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {FeatureFlagsService} from '@app/modules/feature-flags/feature-flags.service';

@Injectable()
export class RedirectToNewEditDealPageGuard implements CanActivate {
    constructor(
        private featureFlagsService: FeatureFlagsService,
        private _router: Router
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        /**
         * If user access /deals/edit/123123213#fallback then we should not redirect to new edit deal page,
         * but let him use the old page.
         */
        if (route.fragment === 'fallback') {
            return true;
        }

        const isNewEditDealPageDefault = this.featureFlagsService.isFeatureEnabled(
            'deals:new_edit_deal_page_is_default'
        );
        const dealID = route.params.id;
        if (isNewEditDealPageDefault && dealID) {
            this._router.navigate(['/deals/v2/edit', dealID]);
            return false;
        }
        return true;
    }
}
