import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {GenericFormArray, GenericFormGroup} from '../../../../../entites/generic.entity';
import {Deal} from '../../../../../models/deal';
import {FinancialNode} from '../../../../shared/components/financial-node/financial-node.model';
import {startWith, takeUntil, tap} from 'rxjs/operators';
import {ListItem} from '../../../../../models/calculation';
import {merge as observableMerge, Subject} from 'rxjs';
import {FinancialSource} from '../../../../shared/components/financial-node/financial-source.model';
import {cleanCurrencyString} from '../../../../../utilities/maskito';

@Component({
    selector: 'app-income-commission',
    styles: [
        `
            .money {
                color: rgba(0, 0, 0, 0.87);
            }

            .label {
                color: rgba(0, 0, 0, 0.87);
            }

            .flex-row {
                width: 100% !important;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }

            .full-width {
                width: 100% !important;
            }

            .half-width {
                width: 50%;
            }

            .spaced {
                margin-right: 10px;
            }
        `
    ],
    template: `
        <app-money-collection
            buttonLabel="+ Additional Commission"
            [financialSourcesArray]="financialNodesFA"
            [isButtonFirst]="true"
            [disabled]="dealFormGroup.controls.flat_fee_commissions?.disabled"
            (addItem)="addFinancialFG($event)"
            (removeItem)="removeFinancialFG($event)"
            [silent]="true"
        >
        </app-money-collection>
    `
})
export class IncomeCommissionComponent implements OnInit, OnDestroy, OnChanges {
    private unsubscribe: Subject<void> = new Subject();

    @Input() dealFormGroup: GenericFormGroup<Deal> = new GenericFormGroup<Deal>(new Deal(), 'change');
    @Input() baseCommissionNodeFG: GenericFormGroup<FinancialNode> = new GenericFormGroup<FinancialNode>(
        new FinancialNode()
    );
    @Input() grossCommissionNodeFG: GenericFormGroup<FinancialNode> = new GenericFormGroup<FinancialNode>(
        new FinancialNode()
    );
    @Input() adjustedSalesPriceNodeFG: GenericFormGroup<FinancialNode> = new GenericFormGroup<FinancialNode>(
        new FinancialNode()
    );
    @Input() editableBaseCommissionNode: GenericFormGroup<FinancialNode> = new GenericFormGroup<FinancialNode>(
        new FinancialNode().setLabel('Base Commission')
    );
    @Input() disabled: boolean = false;

    financialNodesFA: GenericFormArray<FinancialSource> = new GenericFormArray<FinancialSource>([]);
    manualFeeCommission: number = 0;

    public commissionsLength: number = 0;
    last_sum: number = 0;

    constructor() {}

    ngOnInit() {
        this.editableBaseCommissionNode.controls.label!.disable();

        this.editableBaseCommissionNode
            .get('amount')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((newBaseCommission) => {
                const newBaseCommissionValue = Number(cleanCurrencyString(newBaseCommission));
                const salesPrice: number = Number(cleanCurrencyString(this.dealFormGroup.controls.sales_price!.value));
                const salesPriceAdjustment: number = Number(
                    cleanCurrencyString(this.dealFormGroup.controls.sales_price_adjustment!.value)
                );
                const incomeCommissionValue: number = Number(
                    this.dealFormGroup.controls.income_commission_value!.value
                );
                const minBaseCommission: number =
                    ((Number(salesPrice) + Number(salesPriceAdjustment)) * Number(incomeCommissionValue)) / 100;
                const newMargin = Number((newBaseCommissionValue - minBaseCommission).toFixed(2));
                this.dealFormGroup.controls.base_commission_margin_value!.patchValue(newMargin, {emitEvent: false});
                if (newMargin < 0) {
                    this.editableBaseCommissionNode.controls.amount!.setErrors({incorrect: true});
                    this.dealFormGroup.controls.base_commission_margin_value!.setErrors({incorrect: true});
                }
                this.baseCommissionNodeFG.controls.amount!.patchValue(newBaseCommissionValue, {emitEvent: false});
                const categorizedCommission = this.financialNodesFA.getRawValue();
                const newFeeCommission = Number(
                    categorizedCommission.reduce((acc, item) => acc + Number(item.amount), 0) +
                        this.dealFormGroup.controls.base_commission_margin_value!.value
                ).toFixed(2);
                // Alex: we need to emit event about income_flat_commission_value changes only when new income_flat_commission_value is not equal to old income_flat_commission_value
                const emitEvent =
                    Number(this.dealFormGroup.controls.income_flat_commission_value!.value) !==
                    Number(newFeeCommission);
                this.dealFormGroup.controls.income_flat_commission_value!.patchValue(newFeeCommission, {emitEvent});
                this.recalculateGrossCommission();
            });

        this.dealFormGroup
            .get('income_commission_value')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.recalculateBaseCommission(!this.commissionsLength);
                this.recalculateGrossCommission();
            });

        observableMerge(
            this.dealFormGroup.get('sales_price')!.valueChanges,
            this.dealFormGroup.get('sales_price_adjustment')!.valueChanges
        )
            .pipe(startWith(<string>(<unknown>null)), takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.recalculateBaseCommission(!this.commissionsLength);
                this.recalculateGrossCommission();
            });

        // this need to revert fee commission to entered value after removing all categorization
        this.dealFormGroup
            .get('income_flat_commission_value')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((lastFeeCommission) => {
                if (this.manualFeeCommission === null) {
                    this.manualFeeCommission = lastFeeCommission;
                }
                this.recalculateBaseCommission(!this.commissionsLength);
                this.recalculateGrossCommission();
            });

        this.financialNodesFA.valueChanges
            .pipe(
                tap(() =>
                    this.financialNodesFA.controls.forEach((ctrlGroup) =>
                        ctrlGroup
                            .get('amount')!
                            .patchValue(cleanCurrencyString(ctrlGroup.get('amount')!.value), {emitEvent: false})
                    )
                ),
                takeUntil(this.unsubscribe)
            )
            .subscribe((next: any[]) => {
                const currentCommissions = this.financialNodesFA.getRawValue();
                this.commissionsLength = currentCommissions.length;

                this.recalculateBaseCommission(!this.commissionsLength);
                if (this.commissionsLength === 0) {
                    if (this.dealFormGroup.controls.income_flat_commission_value?.disabled) {
                        this.dealFormGroup.controls.income_flat_commission_value?.disable({emitEvent: false});
                    } else {
                        this.dealFormGroup.controls.income_flat_commission_value?.enable({emitEvent: false});
                    }
                    this.dealFormGroup.get('base_commission_margin_value')!.patchValue(0, {emitEvent: false});
                } else {
                    const newFeeCommission = Number(
                        currentCommissions.reduce((acc, item) => acc + Number(item.amount), 0) +
                            this.dealFormGroup.controls.base_commission_margin_value!.value
                    ).toFixed(2);
                    this.dealFormGroup.get('income_flat_commission_value')!.disable({emitEvent: false});
                    // Alex: we need to emit event about income_flat_commission_value changes only when new income_flat_commission_value is not equal to old income_flat_commission_value
                    const emitEvent =
                        Number(this.dealFormGroup.controls.income_flat_commission_value!.value) !==
                        Number(newFeeCommission);
                    this.dealFormGroup.controls.income_flat_commission_value!.patchValue(newFeeCommission, {emitEvent});
                }
                this.recalculateGrossCommission();
            });

        this.dealFormGroup
            .get('flat_fee_commissions')!
            .valueChanges.pipe(startWith(1), takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.financialNodesFA.patchValue(
                    this.dealFormGroup.controls
                        .flat_fee_commissions!.getRawValue()
                        .map((item) => new FinancialSource().setAmount(item.value!).setLabel(item.name!)),
                    {emitEvent: false}
                );
                this.dealFormGroup.controls.flat_fee_commissions!.controls.forEach((item, idx) => {
                    if (item.disabled) {
                        this.financialNodesFA.controls[idx].disable({emitEvent: false});
                    }
                });
                this.financialNodesFA.updateValueAndValidity();
            });

        this.financialNodesFA.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((_next: FinancialNode[]) => {
            const next = this.financialNodesFA.getRawValue();
            let summ = 0;

            if (next.length > 0) {
                summ = next.reduce((acc, item) => acc + Number(item.amount), 0);
            }

            this.last_sum = summ;

            this.dealFormGroup.controls.flat_fee_commissions!.patchValue(
                next.map((node) => {
                    const newLI = new ListItem();
                    newLI.value = node.amount;
                    newLI.name = node.label;
                    return newLI;
                }),
                {emitEvent: false}
            );
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.disabled && changes.disabled.currentValue) {
            this.editableBaseCommissionNode.controls.amount?.disable({emitEvent: false});
        } else if (changes.disabled && !changes.disabled.currentValue) {
            this.editableBaseCommissionNode.controls.amount?.enable({emitEvent: false});
        }
    }

    addFinancialFG(fg: GenericFormGroup<FinancialNode>) {
        const income_flat_commission_value: number = this.dealFormGroup.get('income_flat_commission_value')!.value;
        if (this.commissionsLength === 0) {
            fg.controls.amount!.patchValue(income_flat_commission_value);
        } else {
            fg.controls.amount!.patchValue(0);
        }
        this.financialNodesFA.push(fg);
    }

    removeFinancialFG(index: number) {
        if (this.financialNodesFA.length === 1) {
            const baseCommissionMargin = Number(this.dealFormGroup.get('base_commission_margin_value')!.value);
            this.dealFormGroup
                .get('income_flat_commission_value')!
                .patchValue(baseCommissionMargin, {emitEvent: false});
        }
        this.financialNodesFA.removeAt(index);

        if (this.financialNodesFA.length === 0) {
            this.recalculateBaseCommission(true);
        }
        this.recalculateGrossCommission();
    }

    recalculateBaseCommission(withFlatCommission: boolean = false) {
        const salesPrice: number = Number(cleanCurrencyString(this.dealFormGroup.controls.sales_price!.value));
        const salesPriceAdjustment: number = Number(
            cleanCurrencyString(this.dealFormGroup.controls.sales_price_adjustment!.value)
        );
        const baseCommissionMargin: number = Number(this.dealFormGroup.controls.base_commission_margin_value!.value);
        const incomeCommissionValue: number = Number(this.dealFormGroup.controls.income_commission_value!.value);
        let newBaseCommission: number = Number(
            ((salesPrice + salesPriceAdjustment) * incomeCommissionValue) / 100 + baseCommissionMargin
        );
        newBaseCommission = Number(newBaseCommission.toFixed(2));
        const currentBaseCommission = Number(
            cleanCurrencyString(this.editableBaseCommissionNode.controls.amount!.value)
        );
        if (withFlatCommission) {
            const flatCommission: number = Number(this.dealFormGroup.controls.income_flat_commission_value!.value);
            newBaseCommission += flatCommission;
        }
        if (currentBaseCommission !== newBaseCommission) {
            this.editableBaseCommissionNode.controls.amount!.patchValue(newBaseCommission, {emitEvent: false});
        }
        this.baseCommissionNodeFG.controls.amount!.patchValue(newBaseCommission, {emitEvent: false});
    }

    recalculateGrossCommission() {
        const salesPrice: number = Number(cleanCurrencyString(this.dealFormGroup.controls.sales_price!.value));
        const salesPriceAdjustment: number = Number(
            cleanCurrencyString(this.dealFormGroup.controls.sales_price_adjustment!.value)
        );
        const flatCommission: number = Number(this.dealFormGroup.controls.income_flat_commission_value!.value);
        const incomeCommissionValue: number = Number(this.dealFormGroup.controls.income_commission_value!.value);
        const newGrossCommission = ((salesPrice + salesPriceAdjustment) * incomeCommissionValue) / 100 + flatCommission;
        this.grossCommissionNodeFG.controls.amount!.patchValue(newGrossCommission, {emitEvent: false});
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
