import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Deal} from '../../../../../../models/deal';
import {HttpClient} from '@angular/common/http';
import {CropperControlsComponent} from './cropper-controls.component';
import {CustomCropperComponent} from './custom-cropper.component';
import {GenericFormGroup} from '../../../../../../entites/generic.entity';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-deal-image',
    templateUrl: 'deal-image-template-2.component.html'
})
export class DealImageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() dealFormGroup: GenericFormGroup<Deal> = new GenericFormGroup<Deal>(new Deal(), 'change');

    @ViewChild('cropper', {static: true}) customCropper: CustomCropperComponent | undefined;
    @ViewChild('controls', {static: true}) controls: CropperControlsComponent | undefined;

    image_url: string | undefined;

    ngOnInit() {
        this.dealFormGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            // we need init crop only if image_url changed
            if (changes.image_url && this.image_url !== changes.image_url) {
                this.image_url = changes.image_url;
                this.initCropper(changes.image_url);
            }
        });
    }

    constructor(private http: HttpClient) {}

    initCropper(imageUrl: string) {
        if (imageUrl) {
            this.downloadImageByUrl(imageUrl).then((file) => {
                this.customCropper!.setImageFromFile(file).then((success) => {
                    if (this.controls) {
                        this.controls.inputText = 'Uploaded photo';
                    }
                });
            });
        }
    }

    downloadImageByUrl(imageUrl: string): Promise<any> {
        return this.http
            .get(imageUrl, {responseType: 'blob'})
            .pipe(takeUntil(this.unsubscribe))
            .toPromise()
            .then((response) => {
                return response;
            });
    }

    fileChanged($event: any) {
        this.customCropper!.cropPositionChange();
        this.customCropper!.setImageFromFile($event.file);
    }

    cropperOnChange(event: Event) {
        this.dealFormGroup.controls.image_url!.patchValue(event, {emitEvent: false});
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
