import {filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Injectable()
export class RoutingHistoryService {
    private history: string[] = [];

    constructor(private router: Router) {}

    public startSavingHistory(): void {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(({urlAfterRedirects}: NavigationEnd | any) => {
                urlAfterRedirects = urlAfterRedirects.replace(/[\?#%].*$/, '');

                this.history = [...this.history, urlAfterRedirects];
            });
    }

    public getHistory(): string[] {
        return this.history;
    }

    public getPreviousUrl(): string {
        return this.history[this.history.length - 2] || '/default-page';
    }

    public getPreviousUrlForAnalytics(): string {
        return this.history[this.history.length - 3] || '/default-page';
    }
}
