import {Component, Input} from '@angular/core';
import {transactionTypeListByName, transactionStatusListByName} from '../../../../constants';
import {IReportsPreviewResultData} from '../../../../store/reports.reducer';

interface IKeyValueNumber {
    [key: string]: number;
}

interface IKeyValueString {
    [key: string]: string;
}

interface IColumn {
    title?: string;
    value: string;
    filter?: string;
}

/**
 * There are a range for different type, e.g
 *
 * REPORT_STATUS_SORTING_WEIGTH = [1,...,9]
 * REPORT_TYPE_SORTING_WEIGTH = [10,...,90]
 */
const REPORT_STATUS_SORTING_WEIGTH = 1;
const REPORT_TYPE_SORTING_WEIGTH = 10;

const reportTypeOrder: IKeyValueNumber = {
    [transactionTypeListByName.buyer]: 1,
    [transactionTypeListByName.seller]: 2,
    [transactionTypeListByName.referral]: 3,
    [transactionTypeListByName.rental_tenant]: 4,
    [transactionTypeListByName.rental_owner]: 5
};

const reportStatusOrder: IKeyValueNumber = {
    [transactionStatusListByName.active]: 1,
    [transactionStatusListByName.pending]: 2,
    [transactionStatusListByName.closed]: 3,
    [transactionStatusListByName.opportunity]: 4,
    [transactionStatusListByName.cancelled]: 5
};

const reportTypeTitle: IKeyValueString = {
    [transactionTypeListByName.buyer]: 'Purchase Side',
    [transactionTypeListByName.seller]: 'Listing Side',
    [transactionTypeListByName.referral]: 'Referral Side',
    [transactionTypeListByName.rental_tenant]: 'Rental Tenant Side',
    [transactionTypeListByName.rental_owner]: 'Rental Owner Side'
};

const reportStatusTitle: IKeyValueString = {
    [transactionStatusListByName.active]: 'Active Transactions',
    [transactionStatusListByName.pending]: 'Pending Transactions',
    [transactionStatusListByName.closed]: 'Closed Transactions',
    [transactionStatusListByName.processed]: 'Processed Transactions',
    [transactionStatusListByName.opportunity]: 'Opportunity Transactions',
    [transactionStatusListByName.cancelled]: 'Cancelled Transactions'
};

@Component({
    selector: 'reports-preview-transaction-summary',
    templateUrl: './transaction-summary.component.html',
    styleUrls: ['./transaction-summary.component.scss']
})
export class TransactionSummaryReportPreviewComponent {
    @Input()
    /**
     * Set report data
     */
    set reportData(data: IReportsPreviewResultData | null) {
        let result = data;
        this.overallTable = [];
        if (data) {
            if (data.groups && data.groups.length) {
                result = {
                    ...data,
                    groups: data.groups
                        .map((item: IReportsPreviewResultData) => {
                            return {
                                ...item,
                                order: this.prepareOrderNumber(item),
                                title: this.prepareTableTitle(item)
                            };
                        })
                        .sort((a: IReportsPreviewResultData, b: IReportsPreviewResultData) => a.order - b.order)
                };
            }

            if (data.average) {
                this.overallTable.push({
                    ...data.average,
                    label: 'Average'
                });
            }

            if (data.total) {
                this.overallTable.push({
                    ...data.total,
                    label: 'Total'
                });
            }
        }

        this._reportData = result;
    }

    /**
     * Get report data
     */
    get reportData() {
        return this._reportData;
    }

    _reportData: IReportsPreviewResultData | null = null;

    overallTable: IReportsPreviewResultData[] | null = null;

    tableStructure: IColumn[] = [
        {
            title: 'Agent',
            value: 'agent_name'
        },
        {
            title: 'Address',
            value: 'address'
        },
        {
            title: 'Close Date',
            value: 'close_of_escrow'
        },
        {
            title: 'Sell Price',
            value: 'sales_price',
            filter: 'currency'
        },
        {
            title: 'Gross Comm.',
            value: 'gross_commission',
            filter: 'currency'
        },
        {
            title: 'Comm. %',
            value: 'income_commission_value',
            filter: 'percent'
        },
        {
            title: 'Referrals',
            value: 'referral',
            filter: 'currency'
        },
        {
            title: 'Pay Agents',
            value: 'pay_agents',
            filter: 'currency'
        },
        {
            title: 'Expenses',
            value: 'expenses',
            filter: 'currency'
        },
        {
            title: 'Net Office',
            value: 'company_net',
            filter: 'currency'
        }
    ];

    footerAverageColumns: IColumn[] = [
        {
            value: 'avg_sales_price',
            filter: 'currency'
        },
        {
            value: 'avg_gross',
            filter: 'currency'
        },
        {
            value: 'avg_commission',
            filter: 'percent'
        },
        {
            value: 'avg_referrals',
            filter: 'currency'
        },
        {
            value: 'avg_pay_agent',
            filter: 'currency'
        },
        {
            value: 'avg_expenses',
            filter: 'currency'
        },
        {
            value: 'avg_net_office',
            filter: 'currency'
        }
    ];

    footerTotalColumns: IColumn[] = [
        {
            value: 'total_sales_price',
            filter: 'currency'
        },
        {
            value: 'total_gross',
            filter: 'currency'
        },
        {
            value: 'total_commission',
            filter: 'percent'
        },
        {
            value: 'total_referrals',
            filter: 'currency'
        },
        {
            value: 'total_pay_agent',
            filter: 'currency'
        },
        {
            value: 'total_expenses',
            filter: 'currency'
        },
        {
            value: 'total_net_office',
            filter: 'currency'
        }
    ];

    overallStructure: IColumn[] = [
        {
            title: 'Type',
            value: 'label'
        },
        {
            title: 'Deal Count',
            value: 'deals_count'
        },
        {
            title: 'Sell Price',
            value: 'sales_price',
            filter: 'currency'
        },
        {
            title: 'Gross Comm.',
            value: 'gross_commission',
            filter: 'currency'
        },
        {
            title: 'Comm. %',
            value: 'income_commission_value',
            filter: 'percent'
        },
        {
            title: 'Referrals',
            value: 'referral'
        },
        {
            title: 'Pay Agents',
            value: 'pay_agents',
            filter: 'currency'
        },
        {
            title: 'Expenses',
            value: 'expenses',
            filter: 'currency'
        },
        {
            title: 'Net Office',
            value: 'company_net',
            filter: 'currency'
        }
    ];

    columnsToDisplay: string[] = [
        'agent_name',
        'address',
        'close_of_escrow',
        'sales_price',
        'gross_commission',
        'income_commission_value',
        'referral',
        'pay_agents',
        'expenses',
        'company_net'
    ];
    footerAverageColumnsToDisplay: string[] = [
        'avg_sales_price',
        'avg_gross',
        'avg_commission',
        'avg_referrals',
        'avg_pay_agent',
        'avg_expenses',
        'avg_net_office'
    ];
    footerTotalColumnsToDisplay: string[] = [
        'total_sales_price',
        'total_gross',
        'total_commission',
        'total_referrals',
        'total_pay_agent',
        'total_expenses',
        'total_net_office'
    ];
    overallColumnsToDisplay: string[] = [
        'label',
        'deals_count',
        'sales_price',
        'gross_commission',
        'income_commission_value',
        'referral',
        'pay_agents',
        'expenses',
        'company_net'
    ];

    constructor() {}

    /**
     * Get total cost
     * @param element
     * @param data
     */
    getTotalCost(element: string, data: IReportsPreviewResultData) {
        switch (element) {
            case 'total_sales_price':
                return data.sales_price;
            case 'total_gross':
                return data.gross_commission;
            case 'total_commission':
                return null;
            case 'total_referrals':
                return data.referral;
            case 'total_pay_agent':
                return data.pay_agents;
            case 'total_expenses':
                return data.expenses;
            case 'total_net_office':
                return data.company_net;
            default:
                return '';
        }
    }

    /**
     * Get average
     * @param element
     * @param data
     */
    getAverage(element: string, data: IReportsPreviewResultData) {
        switch (element) {
            case 'avg_sales_price':
                return data.sales_price;
            case 'avg_gross':
                return data.gross_commission;
            case 'avg_commission':
                return data.income_commission_value;
            case 'avg_referrals':
                return data.referral;
            case 'avg_pay_agent':
                return data.pay_agents;
            case 'avg_expenses':
                return data.expenses;
            case 'avg_net_office':
                return data.company_net;
            default:
                return '';
        }
    }

    /**
     * Prepare order number
     * @param reportItem
     */
    prepareOrderNumber(reportItem: IReportsPreviewResultData) {
        let result = 0;
        if (reportItem.dealStatus) {
            result += reportStatusOrder[reportItem.dealStatus] * REPORT_STATUS_SORTING_WEIGTH;
        }

        if (reportItem.dealType) {
            result += reportTypeOrder[reportItem.dealType] * REPORT_TYPE_SORTING_WEIGTH;
        }

        return result;
    }

    /**
     * Prepare order number
     * @param reportItem
     */
    prepareTableTitle(reportItem: IReportsPreviewResultData) {
        const result = [];

        if (reportItem.dealType) {
            result.push(reportTypeTitle[reportItem.dealType]);
        }

        if (reportItem.dealStatus) {
            result.push(reportStatusTitle[reportItem.dealStatus]);
        }

        return result.join(' - ');
    }
}
