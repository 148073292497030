import {Input, Component, ViewChild, TemplateRef, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {SkyslopeAuth} from '@app/modules/auth/services/okta/skyslope-auth.service';
import {CompanyAdminService} from '@app/services/company-admin.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

export class Company {
    id = '';
    name = '';
}

@Component({
    selector: 'link-cell',
    template: `
        <ng-template #companyDialog>
            <h2 matDialogTitle>{{ companyName }}</h2>
            <mat-dialog-content class="company-dialog">
                <div class="notes-container">
                    <mat-form-field id="company-notes">
                        <mat-label class="company-notes-label">Company Notes</mat-label>
                        <textarea
                            readonly
                            class="company-notes"
                            matInput
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="10"
                            >{{ companyNotes }}</textarea
                        >
                    </mat-form-field>
                </div>
            </mat-dialog-content>
            <mat-dialog-actions align="end">
                <button mat-button matDialogClose color="primary">Dismiss</button>
            </mat-dialog-actions>
        </ng-template>
        <div *ngFor="let company of companiesArr">
            <a href="#" (click)="selectCompany($event, company.id, company.name)">{{ company.name }}</a>
        </div>
    `,
    styles: [
        `
            .company-dialog {
                width: 75vw;
            }

            .company-notes-label {
                font-weight: bold;
            }

            ::ng-deep .mat-form-field-infix {
                width: 70vw !important;
            }
        `
    ]
})
export class CompanyModalComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Input() companies: string = '';
    companiesArr: Company[] = [];
    public companyNotes: string = '';
    public companyName: string = '';

    constructor(
        private dialog: MatDialog,
        private skyslopeAuthService: SkyslopeAuth,
        private companyAdminService: CompanyAdminService
    ) {}

    // @ts-ignore
    @ViewChild('companyDialog', {static: true}) companyDialog: TemplateRef<any>;

    async ngOnInit() {
        if (this.companies) {
            for (const item of this.companies.split(',')) {
                const companyId = item.split('|')[0];
                const companyName = item.split('|')[1];
                this.companiesArr.push({id: companyId, name: companyName});
            }
        }
    }

    async selectCompany(event: any, companyId: string, companyName: string) {
        event.preventDefault();

        this.companyName = companyName;

        const isAuthenticated = await this.skyslopeAuthService.isAuthenticated();
        if (isAuthenticated) {
            const companyIdInt = +companyId;
            this.companyAdminService.getCompanyBillingSettings(companyIdInt).then((data) => {
                data.pipe(takeUntil(this.unsubscribe)).subscribe((u: any) => {
                    const company = u.body;
                    this.companyNotes = company.billing_settings.internal_notes;
                    this.dialog.open(this.companyDialog, {width: '90vw !important'});
                });
            });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
