import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {selectReportsCategoriesList} from '../../../../store/reports.selectors';
import {combineLatest, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FetchReportsCategoriesList} from '../../../../store/reports.action';
import {Store} from '@ngrx/store';
import {IReportsState} from '../../../../store/reports.reducer';
import {IReportCategory} from '@cyberco-nodejs/zipi-typings/reports.typings';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {markFormGroupTouched} from '../../../../../../utilities';
import {ReportCategoriesService} from '../../../../services/report-categories.service';

@Component({
    selector: 'save-report-dialog',
    templateUrl: './save-report-dialog.component.html',
    styleUrls: ['./save-report-dialog.component.css']
})
export class SaveReportDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    categoryName: string = '';
    categoryType: string = '';
    categories: IReportCategory[] | [] = [];
    isShowCreateCategory: boolean = false;

    commonReportForm: UntypedFormGroup = this.fb.group({
        title: ['', [Validators.required]],
        category_id: [null, []]
    });

    categoryForm = this.fb.group({
        title: ['', [Validators.required]],
        category_type: [null, Validators.required]
    });

    categoryTypes: Array<{title: string; value: string}> = [
        {
            title: 'Personal',
            value: 'personal'
        },
        {
            title: 'Company',
            value: 'company'
        }
    ];

    constructor(
        public dialogRef: MatDialogRef<SaveReportDialogComponent>,
        private fb: UntypedFormBuilder,
        private store: Store<IReportsState>,
        private reportCategoriesService: ReportCategoriesService,
        @Inject(MAT_DIALOG_DATA) public modeParams: any
    ) {}

    /**
     * On init
     */
    ngOnInit() {
        const categories$ = this.store.select(selectReportsCategoriesList);
        combineLatest(categories$)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([categories]) => {
                this.categories = categories;
            });

        this.store.dispatch(new FetchReportsCategoriesList());
    }

    /**
     * Save
     */
    save() {
        if (this.commonReportForm) {
            if (this.commonReportForm.invalid) {
                markFormGroupTouched(this.commonReportForm.controls);
            } else {
                this.dialogRef.close(this.commonReportForm.value);
            }
        }
    }

    /**
     * Close
     */
    close() {
        this.dialogRef.close();
    }

    /**
     * Select category
     * @param event
     */
    selectCategory(event: {value: number}) {
        if (event.value === 0) {
            this.isShowCreateCategory = true;
            if (this.commonReportForm) {
                this.commonReportForm.controls['category_id'].setValue(null);
            }
        } else {
            this.isShowCreateCategory = false;
        }
    }

    /**
     * Create category
     */
    createCategory() {
        if (this.categoryForm) {
            if (this.categoryForm.invalid) {
                markFormGroupTouched(this.categoryForm.controls);
            } else {
                const newCategory: IReportCategory | any = this.categoryForm.value;

                this.reportCategoriesService
                    .createReportsCategory(newCategory)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(() => {
                        this.store.dispatch(new FetchReportsCategoriesList());
                        this.isShowCreateCategory = false;
                    });
            }
        }
    }

    /**
     * Cancel create category
     */
    cancelCreateCategory() {
        this.isShowCreateCategory = false;
        if (this.categoryForm) {
            this.categoryForm.reset();
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
