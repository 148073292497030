import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';

import {IFinanceState} from '../../../store/finance.reducer';
import {Subject} from 'rxjs';
import {selectCompanySettings} from 'app/store/root.selectors';
import {filter, takeUntil} from 'rxjs/operators';
import {FetchCompanySettings} from '../../../../account-info/store/settings.actions';
import {LedgerAccountService} from 'app/services/api/finance/ledger-accounts.service';
import {DeleteOpeningBalance} from '../../../store/finance.actions';
import {ConfirmComponent} from '../../../../../layouts/confirm/confirm.component';
import {MatDialog} from '@angular/material/dialog';
import {RbacService} from '../../../../rbac/rbac.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-opening-balances-page',
    templateUrl: 'opening-balances-page.component.html',
    styleUrls: ['opening-balances-page.component.scss']
})
export class OpeningBalancesPageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('accountTransactions') accountTransactions: any;

    isSidebarOpened: boolean = false;
    openingBalanceDate: number | undefined;

    ledgerAccountsData: {
        items: any[];
        debit_total: number;
        credit_total: number;
    } = {
        items: [],
        debit_total: 0,
        credit_total: 0
    };
    displayedColumns: string[] = ['ledger_account_name', 'debit', 'credit'];

    constructor(
        private store: Store<IFinanceState>,
        private ledgerAccountService: LedgerAccountService,
        public dialog: MatDialog,
        protected rbacService: RbacService,
        public router: Router
    ) {}

    ngOnInit() {
        this.rbacService.isAllowed({company_settings__manage_opening_balances: true}).then((can) => {
            if (!can) {
                this.router.navigate(['/']);
            }
        });
        this.store.dispatch(new FetchCompanySettings());

        this.store
            .select(selectCompanySettings)
            .pipe(
                filter((cs) => cs !== null),
                takeUntil(this.unsubscribe)
            )
            .subscribe((companySettings) => {
                if (companySettings) {
                    this.openingBalanceDate = companySettings.opening_balance_date;
                }
            });

        this.ledgerAccountService
            .getOpeningBalanceLedgerAccountsTransactions()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.ledgerAccountsData = data;
            });
    }

    deleteOpeningBalances() {
        if (!this.openingBalanceDate) {
            return;
        }

        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            maxWidth: 600,
            data: {
                title: 'Deleting Opening Balances',
                message: `You're about to delete the opening balances you’ve recorded.
                This will delete all the opening balances and you cannot retrieve them again.
                Are you sure you want to delete?`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((ok) => {
                this.store.dispatch(new DeleteOpeningBalance());
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
