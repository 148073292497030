import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    FormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {
    IBill,
    IBillItem,
    IContact,
    IFinancialTransfer,
    IInvoice,
    IInvoiceItem,
    IProduct
} from '@cyberco-nodejs/zipi-typings';
import {InvoicesService} from '../../../../../services/api/finance/invoices.service';
import {select, Store} from '@ngrx/store';
import {IFinanceState} from '../../../store/finance.reducer';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {selectProducts} from '../../../store/finance.selectors';
import {FetchProducts} from '../../../store/finance.actions';
import {CreateProductDialogComponent} from '../../products/create-product-dialog/create-product-dialog.component';
import {RECURRING_INVOICE_PERIOD} from '../../../../../local-typings';
import {takeUntil} from 'rxjs/operators';
import {FetchContacts} from '../../../../contacts/store/contacts.actions';
import {selectContacts} from '../../../../contacts/store/contacts.selectors';
import {BillsService} from 'app/services/api/finance/bills.service';
import {FormGroupArray, FormGroupArrayWithGroupAsControl} from '../../../../../typings/common';

@Component({
    selector: 'app-product-matching-dialog',
    templateUrl: './product-matching-dialog.component.html',
    styleUrls: ['./product-matching-dialog.component.scss']
})
export class ProductMatchingDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    payout: IFinancialTransfer | undefined;
    source_document: (IInvoice & IBill) | undefined;
    source_documents: (IInvoice & IBill)[] = [];
    recurringInvoices: FormGroupArray;
    prodArray: FormGroupArrayWithGroupAsControl;
    product$: Observable<IProduct[]> | undefined;
    products: IProduct[] = [];
    contact: IContact | undefined;
    repeatPeriod = RECURRING_INVOICE_PERIOD;

    need_save_map: boolean = true;
    hasProductForMapping: boolean = false;

    payments = [];

    constructor(
        private fb: UntypedFormBuilder,
        private invoicesService: InvoicesService,
        private billsService: BillsService,
        private store: Store<IFinanceState>,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ProductMatchingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.recurringInvoices = this.fb.array([]) as FormGroupArray;
        this.prodArray = this.fb.array([]) as FormGroupArrayWithGroupAsControl;
    }

    ngOnInit() {
        this.store.dispatch(new FetchProducts());
        this.product$ = this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe));

        this.payout = this.data.payout || null;
        this.contact = this.data.contact || null;

        if (this.data.source_document) {
            this.source_document = this.data.source_document;
            this.source_documents =
                this.data.source_documents && this.data.source_documents.length > 0
                    ? this.data.source_documents
                    : [this.data.source_document];
        }

        if (!this.contact) {
            this.store.dispatch(new FetchContacts());
            this.store
                .pipe(select(selectContacts), takeUntil(this.unsubscribe)) // migrate to ContactsLite: done
                .subscribe((contacts) => {
                    if (this.source_document) {
                        this.contact = contacts.find(
                            (c) =>
                                this.source_document &&
                                c.partner__company_fk_id === this.source_document.owner__company_fk_id
                        );
                    }
                    if (this.payout) {
                        this.contact = contacts.find(
                            (c) => this.payout && c.partner__company_fk_id === this.payout.creator__company_fk_id
                        );
                    }

                    this.buildProductList();
                });
        } else {
            this.buildProductList();
        }
    }

    /**
     * Build product group with items list
     */
    buildProductList() {
        if (this.product$) {
            this.product$.pipe(takeUntil(this.unsubscribe)).subscribe((prods) => {
                this.products = prods;

                if (!this.prodArray.getRawValue().length) {
                    if (this.data.source_document) {
                        this.fillSourceDocuments();
                    }

                    if (this.data.payout) {
                        this.fillPayoutItem();
                    }
                }
            });
        }
    }

    fillPayoutItem() {
        this.prodArray.clear();

        if (this.payout && this.payout.items && this.payout.items.length) {
            for (const item of this.payout.items) {
                const items = this.fb.array([]);

                let myMappedProduct: IProduct | null = null;
                if (this.contact && this.contact.product_mapping.length > 0) {
                    const productMatch = this.contact.product_mapping.find(
                        (map) => this.payout && map.contact_company__product_fk_id === this.payout.product_fk_id
                    );
                    if (productMatch) {
                        myMappedProduct = this.products.find(
                            (prod) => prod.product_id === productMatch.matched__product_fk_id
                        )!;
                    }
                }
                if (item.product_fk_id) {
                    this.hasProductForMapping = true;
                }

                items.push(
                    this.fb.group({
                        product_fk_id: [item.product_fk_id, []],
                        name: item.name,
                        description: item.description,
                        invoice_item_description: item.description,
                        quantity: [1, [Validators.required, Validators.min(1)]],
                        amount: [{value: item.amount || 0, disabled: true}],

                        match_product: this.fb.group({
                            product_fk_id: [myMappedProduct ? myMappedProduct.product_id : null, []],
                            name: [
                                {
                                    value: myMappedProduct ? myMappedProduct.name : null,
                                    disabled: !!myMappedProduct
                                },
                                [Validators.required]
                            ],
                            description: [myMappedProduct ? myMappedProduct.description : null, []],
                            ledger_account_fk_id: [
                                myMappedProduct ? myMappedProduct.ledger_account_fk_id : null,
                                [Validators.required]
                            ]
                        })
                    })
                );

                this.prodArray.push(this.createProductItem(item, items, myMappedProduct));
            }
        }
    }

    /**
     * Fill
     */
    fillSourceDocuments() {
        const uniqueProdId: Array<number> = [];

        this.prodArray.clear();
        this.recurringInvoices.clear();

        for (let i = 0; i < this.source_documents.length; i++) {
            if (
                !!this.source_documents[i].recurring_invoice &&
                !this.recurringInvoices
                    .getRawValue()
                    .find((ri) => ri.invoice_id === (<IInvoice[]>this.source_documents)[i].invoice_id)
            ) {
                this.recurringInvoices.push(this.createRecurringItem(this.source_documents[i]));
            }

            if (this.source_documents && this.source_documents[i]) {
                const document = this.source_documents[i];
                if (document.items) {
                    document.items.forEach((item: IInvoiceItem | IBillItem) => {
                        if (item.product_fk_id && uniqueProdId.includes(item.product_fk_id)) {
                            return;
                        }
                        if (item.product_fk_id) {
                            uniqueProdId.push(item.product_fk_id);

                            this.hasProductForMapping = true;
                        }

                        this.prodArray.push(this.prepareProductItem(item));
                    });
                }
            }
        }
    }

    /**
     * Create invoice-title-item for auto-create from recurring
     * @param invoice
     */
    createRecurringItem(invoice: any) {
        const currentRepeatPeriod = this.repeatPeriod.find((per) => {
            const perObj: any = per[1];
            return perObj.period === invoice.recurring_invoice.repeat.period;
        });
        const group = this.fb.group({
            invoice_id: [invoice.invoice_id || null, []],
            invoice_number: [invoice.invoice_number, []],
            auto_create_from_recurring: [false, []],
            title: [{value: this.buildDefaultTitle(invoice), disabled: true}, []],
            total_amount: [invoice.recurring_invoice.total_amount, []],
            current_repeat_period: [currentRepeatPeriod ? currentRepeatPeriod[0] : '', []]
        });

        group.controls.auto_create_from_recurring.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => (value ? group.controls.title.enable() : group.controls.title.disable()));

        return group;
    }

    /**
     *  Create item for each product item
     * @param item
     * @param myMappedProduct
     */
    createItem(item?: IInvoiceItem | IBillItem, myMappedProduct?: IProduct | null) {
        let billEntity: IBill | null = null;
        let invoiceEntity: IInvoice | null = null;

        if ((<IBillItem>item).bill_fk_id) {
            billEntity = this.source_documents.find(
                (sdoc) => (<IBill>sdoc).bill_id === (<IBillItem>item).bill_fk_id
            ) as IBill;
        } else if ((<IInvoiceItem>item).invoice_fk_id) {
            invoiceEntity = this.source_documents.find(
                (sdoc) => (<IInvoice>sdoc).invoice_id === (<IInvoiceItem>item).invoice_fk_id
            ) as IInvoice;
        }
        const entityNumber = invoiceEntity ? invoiceEntity.invoice_number : billEntity ? billEntity.bill_number : null;

        const group = this.fb.group({
            bill_fk_id: [(<IBillItem>item).bill_fk_id || null, []],
            invoice_fk_id: [(<IInvoiceItem>item).invoice_fk_id || null, []],
            entity_number: entityNumber,
            product_fk_id: [item ? item.product_fk_id : null, []],
            name: item ? item.name : '',
            description: item ? item.description : '',
            invoice_item_description: item ? item.description : '',
            quantity: [item ? item.quantity : 1, [Validators.required, Validators.min(1)]],
            rate: [{value: item ? item.rate : 0, disabled: false}, Validators.required],
            amount: [{value: item ? item.amount : 0, disabled: true}],
            connected__deal_fk_id: item ? item.connected__deal_fk_id : null,
            connected__financial_transfer_fk_id: item ? item.connected__financial_transfer_fk_id : null,
            deal_connection_mode: item ? item.deal_connection_mode : null,
            order_index: item ? item.order_index : null,
            division__company_group_fk_id: item ? item.division__company_group_fk_id : null,

            match_product: this.fb.group({
                product_fk_id: [myMappedProduct ? myMappedProduct.product_id : null, []],
                name: [
                    {
                        value: myMappedProduct ? myMappedProduct.name : null,
                        disabled: !!myMappedProduct
                    },
                    [Validators.required]
                ],
                description: [myMappedProduct ? myMappedProduct.description : null, []],
                ledger_account_fk_id: [
                    myMappedProduct ? myMappedProduct.ledger_account_fk_id : null,
                    [Validators.required]
                ]
            })
        });

        if (item) {
            group.patchValue(item);
        }

        return group;
    }

    /**
     * Create product group with items
     * @param item
     * @param items
     * @param myMappedProduct
     */
    createProductItem(item: any, items: UntypedFormArray, myMappedProduct?: IProduct | null) {
        const group = this.fb.group({
            is_expanded: [false, []],
            product_fk_id: [{value: item.product_fk_id, disabled: true}, []],
            name: [{value: item.name ? item.name : '', disabled: true}, []],
            match_product: this.fb.group({
                product_fk_id: [
                    {
                        value: myMappedProduct ? myMappedProduct.product_id : null,
                        disabled: !item.product_fk_id
                    },
                    []
                ],
                name: [
                    {
                        value: myMappedProduct ? myMappedProduct.name : null,
                        disabled: !!myMappedProduct || !item.product_fk_id
                    },
                    []
                ],
                ledger_account_fk_id: [
                    {
                        value: myMappedProduct ? myMappedProduct.ledger_account_fk_id : null,
                        disabled: !item.product_fk_id
                    },
                    []
                ]
            }),
            items: items
        });

        return group;
    }

    /**
     *
     * @param item
     */
    prepareProductItem(item: any) {
        let myMappedProduct: IProduct | null = null;
        if (this.contact && this.contact.product_mapping.length > 0) {
            const productMatch = this.contact.product_mapping.find(
                (map) => map.contact_company__product_fk_id === item.product_fk_id
            );
            if (productMatch) {
                myMappedProduct = this.products.find(
                    (prod) => prod.product_id === productMatch.matched__product_fk_id
                )!;
            }
        }
        const items = this.fb.array([]);

        if (item.product_fk_id) {
            let productItems: (IInvoiceItem | IBillItem)[] = [];
            for (const sdoc of this.source_documents) {
                // @ts-ignore
                const docProdItems: (IInvoiceItem | IBillItem)[] = (sdoc.items as (IInvoice | IBill)[]).filter(
                    // @ts-ignore
                    (inv_item) => inv_item.product_fk_id === item.product_fk_id
                );
                productItems = productItems.concat(docProdItems);
            }

            productItems.forEach((pi) => {
                const createdItem = this.createItem(pi, myMappedProduct);
                if (createdItem) {
                    items.push(createdItem);
                }
            });
        } else {
            items.push(this.createItem(item, null));
        }

        const group = this.createProductItem(item, items, myMappedProduct);

        return group;
    }

    buildDefaultTitle(invoice: IInvoice) {
        let defaultRecurringTitle = '';
        if (invoice.recurring_invoice) {
            const currentRepeatPeriod = this.repeatPeriod.find((per) => {
                const perObj: any = per[1];
                return perObj.period === invoice.recurring_invoice!.repeat.period;
            });

            if (invoice && invoice.recurring_invoice_fk_id && invoice.recurring_invoice) {
                defaultRecurringTitle = `$${invoice.recurring_invoice.total_amount} ${currentRepeatPeriod ? currentRepeatPeriod[0] : ''} ${this.contact ? 'to ' + this.contact.display_name : ''}`;
            }
        }

        return defaultRecurringTitle;
    }

    createProduct() {
        const dialogRef = this.dialog.open(CreateProductDialogComponent, {});

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    selectItem(item: AbstractControl, product: IProduct, items?: AbstractControl[]) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.controls.product_fk_id.patchValue(product.product_id);

        itemGroup.controls.ledger_account_fk_id.patchValue(product.ledger_account_fk_id);
        itemGroup.controls.ledger_account_fk_id.disable();

        itemGroup.controls.name.patchValue(product.name);
        itemGroup.controls.name.disable();

        if (items) {
            items.forEach((anotherItem) => {
                const anotherItemGroup: UntypedFormGroup = anotherItem as UntypedFormGroup;
                const matchProductControl: UntypedFormGroup = anotherItemGroup.controls
                    .match_product as UntypedFormGroup;
                matchProductControl.controls.product_fk_id.patchValue(product.product_id);
                matchProductControl.controls.ledger_account_fk_id.patchValue(product.ledger_account_fk_id);
                matchProductControl.controls.name.patchValue(product.name);
                matchProductControl.controls.ledger_account_fk_id.disable();
                matchProductControl.controls.name.disable();
            });
        }
    }

    removeProductFkId(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.controls.ledger_account_fk_id.enable();
        itemGroup.controls.name.enable();
        itemGroup.controls.name.patchValue('');
        itemGroup.controls.product_fk_id.patchValue(null);
    }

    continue() {
        if (this.prodArray.invalid) {
            this.prodArray.markAllAsTouched();
            this.prodArray.controls.forEach((item) => {
                if (item.invalid) {
                    const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
                    itemGroup.controls.is_expanded.patchValue(true);
                }
            });
            return;
        }
        const products = this.prodArray.getRawValue();

        if (this.source_documents.length > 0) {
            const recurringInvoices = this.recurringInvoices.getRawValue();
            const mapping: Array<{contact_company__product_fk_id: number; matched__product_fk_id: number}> = [];
            const entities: {[key: number]: {items: []; autoCreateFromRecurringTitle: string | null}} = {};

            this.source_documents.forEach((sd) => {
                const recurring = recurringInvoices.find((rt) => rt.invoice_id === (<IInvoice>sd).invoice_id);

                if ((<IBill>sd).bill_id) {
                    entities[(<IBill>sd).bill_id as number] = {items: [], autoCreateFromRecurringTitle: null};
                } else {
                    entities[(<IInvoice>sd).invoice_id as number] = {
                        items: [],
                        autoCreateFromRecurringTitle:
                            recurring && recurring.auto_create_from_recurring ? recurring.title : null
                    };
                }
            });

            products.map((productGroup) => {
                if (this.need_save_map && productGroup.product_fk_id && productGroup.match_product.product_fk_id) {
                    mapping.push({
                        contact_company__product_fk_id: productGroup.product_fk_id,
                        matched__product_fk_id: productGroup.match_product.product_fk_id
                    });
                }
                productGroup.items.forEach((item: any) => {
                    item.name = item.match_product.name;
                    item.product_fk_id = item.match_product.product_fk_id;
                    item.ledger_account_fk_id = item.match_product.ledger_account_fk_id;

                    delete item.match_product;
                    delete item.invoice_item_description;

                    // @ts-ignore
                    entities[item.invoice_fk_id || item.bill_fk_id].items.push(item);
                });
            });

            this.dialogRef.close({
                mapping,
                entities
            });
        }

        if (this.payout) {
            const productGroup = products[0];
            const productItem = productGroup.items[0];
            let mapped_product: {contact_company__product_fk_id: null; matched__product_fk_id: null} | null = null;

            if (this.need_save_map && productGroup.product_fk_id && productGroup.match_product.product_fk_id) {
                mapped_product = {
                    contact_company__product_fk_id: productGroup.product_fk_id,
                    matched__product_fk_id: productGroup.match_product.product_fk_id
                };
            }

            productItem.name = productItem.match_product.name;
            productItem.product_fk_id = productItem.match_product.product_fk_id;
            productItem.ledger_account_fk_id = productItem.match_product.ledger_account_fk_id;

            delete productItem.match_product;
            delete productItem.invoice_item_description;

            this.dialogRef.close({
                mapped_product,
                item: productItem
            });
        }
    }

    expandGroup(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.controls.is_expanded.patchValue(!itemGroup.controls.is_expanded.value);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
