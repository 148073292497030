import {Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {IContact, IContactShare} from '@cyberco-nodejs/zipi-typings';
import {takeUntil, tap, catchError} from 'rxjs/operators';
import {of as observableOf, Subject} from 'rxjs';
import {ShipperContactsService} from 'app/services/api/shipper.contacts.service';
import {Router} from '@angular/router';
import {NotificationsService} from 'angular2-notifications';

@Component({
    selector: 'app-contact-share',
    templateUrl: 'contact-share.component.html',
    styleUrls: ['contact-share.component.css']
})
export class ContactShareComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    @Output() closeSidebar = new EventEmitter<void>();
    @Input() selectedContact: IContact | undefined;
    contactShares: IContactShare[] = [];

    savedGroups: number[] = [];
    savedProfiles: number[] = [];

    @ViewChild('sharePicker', {static: true}) sharedPicker: ElementRef | undefined;

    constructor(
        private contactSrv: ShipperContactsService,
        protected router: Router,
        private ntfs: NotificationsService
    ) {}

    share() {
        if (this.sharedPicker && this.selectedContact) {
            // @ts-ignore
            const contactShares = this.sharedPicker.nodesFA.value.map(({type, target_id}) => {
                if ([type, target_id].some((val) => !!val === false)) {
                    throw new ReferenceError(`All properties type="${type}", target_id="${target_id}" must be valid`);
                }
                const keyTarget = {
                    individuals_in_group: 'shared_to__group_fk_id',
                    individual: 'shared_to__profile_fk_id'
                };
                return {
                    // @ts-ignore
                    [keyTarget[type]]: target_id,
                    contact_fk_id: this.selectedContact!.id
                };
            });

            this.contactSrv
                .updateContactShares(Number(this.selectedContact.id), contactShares)
                .pipe(
                    catchError((err) => {
                        console.error(err);
                        return observableOf(null);
                    }),
                    tap((result) => {
                        console.info(result);
                        this.ntfs.info('Shared successfully');
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe();
        }
    }

    private addShared(cShares: IContactShare[]) {
        this.savedGroups = cShares.map((cs) => Number(cs.shared_to__group_fk_id)).filter((cs) => cs);
        this.savedProfiles = cShares.map((cs) => Number(cs.shared_to__profile_fk_id)).filter((cs) => cs);
    }

    ngOnInit() {
        if (this.selectedContact) {
            this.contactSrv
                .getContactShares(Number(this.selectedContact.id))
                .pipe(
                    tap((cShares) => (this.contactShares = cShares)),
                    tap((cShares) => this.addShared(cShares)),
                    takeUntil(this.unsubscribe)
                )
                .subscribe();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.closeSidebar.complete();
    }
}
