import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-muhnee-companies-select-dialog',
    templateUrl: './muhnee-kyc-redirect-dialog.component.html',
    styleUrls: ['./muhnee-kyc-redirect-dialog.component.scss']
})
export class MuhneeKycRedirectDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    link: string | undefined;

    constructor(
        public dialogRef: MatDialogRef<MuhneeKycRedirectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {link: string}
    ) {}

    ngOnInit() {
        this.link = this.data.link;
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
