import {Component, Inject, OnInit, ChangeDetectorRef, OnDestroy, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {combineLatest, Subject} from 'rxjs';
import {IContact, ICreditNote, IInvoice, IPaymentReceived} from '@cyberco-nodejs/zipi-typings';
import {MatTableDataSource} from '@angular/material/table';
import {PaymentsReceivedService} from '../../../services/payments-received.service';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {CreditNotesService} from 'app/services/api/finance/credit-notes.service';
import {map, takeUntil, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {FormGroupArray, FormGroupWithFormControls} from '../../../../../typings/common';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';

@Component({
    selector: 'app-apply-credits-to-invoice-dialog',
    templateUrl: 'apply-credits-to-invoice-dialog.component.html',
    styleUrls: ['apply-credits-to-invoice-dialog.component.scss']
})
export class ApplyCreditsToInvoiceDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    dataSource: MatTableDataSource<any>;
    displayedColumns = ['date', 'credit_info', 'credit_number', 'amount', 'actions'];
    excess: number | null = null;

    invoice: IInvoice | undefined;
    contact: IContact | undefined;

    creditsArray: FormGroupArray = this.fb.array([]) as FormGroupArray;
    formGroup: FormGroupWithFormControls = this.fb.group({credits: this.creditsArray}) as FormGroupWithFormControls;

    pendingBalance: number = 0;
    amountFromCredits: number = 0;
    remaining: number = 0;
    currencyMaskitoMask = currencyMaskitoOptions;

    isSaveButtonDisabled: boolean;

    constructor(
        public dialogRef: MatDialogRef<ApplyCreditsToInvoiceDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder,
        private changeDetector: ChangeDetectorRef,
        private paymentsReceivedService: PaymentsReceivedService,
        private invoicesService: InvoicesService,
        private creditNotesService: CreditNotesService,
        protected router: Router
    ) {
        this.dataSource = new MatTableDataSource<any>([]);
        this.isSaveButtonDisabled = false;
    }

    ngOnInit() {
        this.invoice = this.data.invoice;
        this.pendingBalance = this.data.invoice.pending_balance;
        this.contact = this.data.invoice.money_sender_contact;
        this.remaining = this.data.invoice.pending_balance;

        if (this.contact && this.contact.contact_id) {
            combineLatest(
                this.creditNotesService.getContactExcessCreditNotes(this.contact.contact_id),
                this.paymentsReceivedService.getContactOverpaymentPaymentsReceived(this.contact.contact_id)
            )
                .pipe(
                    map(([credits, payments]) => {
                        const paymentsReceived: Array<ICreditNote | IPaymentReceived> = payments;
                        const creditNotes: ICreditNote[] = credits as ICreditNote[];

                        return paymentsReceived.concat(creditNotes);
                    }),
                    tap((data: any) => {
                        const credits = data;

                        for (const credit of credits) {
                            const creditControl = this.fb.group({
                                payment_received_id: [],

                                credit_note_id: [],
                                credit_note_number: [],

                                balance: [],
                                total_amount: [],
                                reference: [],
                                date: [],

                                amount_to_invoice: [0]
                            });
                            const dueBalance = credit.credit_note_id ? credit.balance : credit.over_payment;
                            let amountToInvoice = 0;

                            if (this.remaining >= dueBalance) {
                                amountToInvoice = dueBalance;
                                const remaining = this.remaining - dueBalance;
                                this.remaining = Number(remaining.toFixed(2));
                            } else {
                                amountToInvoice = this.remaining;
                                this.remaining = 0;
                            }

                            if (credit.payment_received_id) {
                                creditControl.patchValue({
                                    payment_received_id: credit.payment_received_id,
                                    date: credit.paid_date,
                                    balance: credit.over_payment,
                                    total_amount: credit.amount,
                                    reference: credit.reference,
                                    amount_to_invoice: amountToInvoice
                                });
                            } else {
                                creditControl.patchValue({
                                    credit_note_id: credit.credit_note_id,
                                    credit_note_number: credit.credit_note_number,
                                    date: credit.credit_note_date,
                                    balance: credit.balance,
                                    total_amount: credit.total_amount,
                                    reference: credit.reference,
                                    amount_to_invoice: amountToInvoice
                                });
                            }

                            this.creditsArray.push(creditControl);
                        }

                        this.dataSource.data = this.creditsArray.getRawValue();
                        this.calculateTotals();
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe();
        }
    }

    onBlurCredit(index: number) {
        if (this.creditsArray) {
            const creditGroup: UntypedFormGroup = this.creditsArray.at(index) as unknown as UntypedFormGroup;
            const amountToInvoice = creditGroup.controls.amount_to_invoice.value;
            const balance = creditGroup.controls.balance.value;
            const unmaskAmount = Number(cleanCurrencyString(amountToInvoice));

            const credits = this.creditsArray.getRawValue();
            const amountFromCredits = credits.reduce(
                (sum, item) => Number(cleanCurrencyString(item.amount_from_credit)) + sum,
                0
            );
            const realRemaining = Number((this.pendingBalance - amountFromCredits + unmaskAmount).toFixed(2));

            if (unmaskAmount > realRemaining || unmaskAmount > balance) {
                const newAmount = realRemaining > balance ? balance : realRemaining;
                creditGroup.controls.amount_to_invoice.setValue(newAmount);
            }

            this.calculateTotals();
        }
    }

    calculateTotals() {
        const creditsNew = this.creditsArray.getRawValue();
        const amountFromCreditsNew = creditsNew.reduce(
            (sum, item) => Number(cleanCurrencyString(item.amount_to_invoice)) + sum,
            0
        );

        this.amountFromCredits = amountFromCreditsNew;
        this.remaining = this.pendingBalance - amountFromCreditsNew;
    }

    routeToElement(element: any) {
        this.dialogRef.close();

        if (element.payment_received_id) {
            this.router.navigate(['sales/paymentsreceived', element.payment_received_id]);
        } else {
            this.router.navigate(['sales/creditnotes', element.credit_note_id]);
        }
    }

    save() {
        const credits = this.creditsArray.getRawValue();
        const filteredCredits: {
            customer_credits: Array<{credit_note_id: number; amount_to_apply: number}>;
            payments_received: Array<{payment_received_id: number; amount_to_apply: number}>;
        } = {
            customer_credits: [],
            payments_received: []
        };
        for (const cr of credits) {
            const unmaskAmount = Number(cleanCurrencyString(cr.amount_to_invoice));

            if (unmaskAmount !== 0) {
                if (cr.credit_note_id) {
                    filteredCredits.customer_credits.push({
                        credit_note_id: cr.credit_note_id,
                        amount_to_apply: unmaskAmount
                    });
                }
            }

            if (unmaskAmount !== 0) {
                if (cr.payment_received_id) {
                    filteredCredits.payments_received.push({
                        payment_received_id: cr.payment_received_id,
                        amount_to_apply: unmaskAmount
                    });
                }
            }
        }

        this.isSaveButtonDisabled = true;
        if (this.invoice && this.invoice.invoice_id) {
            this.invoicesService
                .applyFromCredits(this.invoice.invoice_id, filteredCredits)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((data) => {
                    this.isSaveButtonDisabled = false;
                    this.dialogRef.close(data);
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
