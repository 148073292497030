import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {GenericFormGroup} from '../../../../../../entites/generic.entity';
import {ParticipantCalculation} from '../../../../../shared/components/calculations/calculations.model';
import {DealService} from '../../../../../../services/deal.service';

@Component({
    selector: 'app-participant-preview',
    styleUrls: ['preview.component.scss'],
    template: `
        <mat-card class="wide-card preview-card">
            <mat-card-title
                (click)="toggleCollapse()"
                style="font-size: 15px; align-items: center;display: flex;cursor: pointer;"
            >
                Preview
                <mat-icon>
                    {{ isZeroValuesExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                </mat-icon>
            </mat-card-title>

            <mat-card-content class="full-width">
                <div
                    class="preview-row subtotal-section"
                    [class.pr-36]="
                        participantCalculationFG.controls.agent_split &&
                        participantCalculationFG.controls.agent_split.controls.details &&
                        participantCalculationFG.controls.agent_split.controls.details.length === 0
                    "
                    *ngIf="
                        isZeroValuesExpanded ||
                        (participantCalculationFG.controls.agent_split &&
                            participantCalculationFG.controls.agent_split.controls.amount &&
                            participantCalculationFG.controls.agent_split.controls.amount.value)
                    "
                >
                    <div class="w-55 label text-left">Split:</div>
                    <div
                        class="text-right"
                        [ngClass]="
                            participantCalculationFG.controls.agent_split &&
                            participantCalculationFG.controls.agent_split.controls.details &&
                            participantCalculationFG.controls.agent_split.controls.details.length > 0
                                ? 'w-35'
                                : 'w-45'
                        "
                    >
                        <app-financial-node
                            moneyClass="fee-value"
                            *ngIf="participantCalculationFG.controls.agent_split"
                            [financialNodeFG]="participantCalculationFG.controls.agent_split"
                            [highlightIfNegative]="true"
                        ></app-financial-node>
                    </div>
                    <div
                        class="w-10"
                        *ngIf="
                            participantCalculationFG.controls.agent_split &&
                            participantCalculationFG.controls.agent_split.controls.amount &&
                            participantCalculationFG.controls.agent_split.controls.amount.value &&
                            participantCalculationFG.controls.agent_split.controls.details
                        "
                    >
                        <div
                            *ngIf="participantCalculationFG.controls.agent_split.controls.details.length > 0"
                            class="arrow-button"
                            (click)="isDetailsOpenedAgentSplit = !isDetailsOpenedAgentSplit"
                        >
                            <mat-icon>{{
                                isDetailsOpenedAgentSplit ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                            }}</mat-icon>
                        </div>
                    </div>
                    <app-financial-details
                        class="w-100"
                        *ngIf="
                            isDetailsOpenedAgentSplit &&
                            participantCalculationFG.controls.agent_split &&
                            participantCalculationFG.controls.agent_split.controls.details
                        "
                        [detailsFA]="participantCalculationFG.controls.agent_split.controls.details"
                    ></app-financial-details>
                </div>
                <div
                    class="preview-row subtotal-section column"
                    *ngIf="
                        !isGroup &&
                        (isZeroValuesExpanded ||
                            (participantCalculationFG.controls.referral_expenses_sum &&
                                participantCalculationFG.controls.referral_expenses_sum.controls.amount &&
                                participantCalculationFG.controls.referral_expenses_sum.controls.amount.value))
                    "
                >
                    <div class="w-100 node-with-details">
                        <div class="w-55 label text-left">Referral:</div>
                        <div class="w-35 text-right">
                            <app-financial-node
                                moneyClass="fee-value negative"
                                *ngIf="participantCalculationFG.controls.referral_expenses_sum"
                                [financialNodeFG]="participantCalculationFG.controls.referral_expenses_sum"
                                [highlightIfNegative]="true"
                            ></app-financial-node>
                        </div>
                        <div
                            class="w-10"
                            *ngIf="
                                participantCalculationFG.controls.referral_expenses_sum &&
                                participantCalculationFG.controls.referral_expenses_sum.controls.amount &&
                                participantCalculationFG.controls.referral_expenses_sum.controls.amount.value
                            "
                        >
                            <div
                                *ngIf="
                                    participantCalculationFG.controls.referral_expenses_sum.controls.details &&
                                    participantCalculationFG.controls.referral_expenses_sum.controls.details.length > 0
                                "
                                class="arrow-button"
                                (click)="isDetailsOpenedReferral = !isDetailsOpenedReferral"
                            >
                                <mat-icon>{{
                                    isDetailsOpenedReferral ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                }}</mat-icon>
                            </div>
                            <div
                                *ngIf="
                                    participantCalculationFG.controls.referral_expenses_sum.controls.details &&
                                    participantCalculationFG.controls.referral_expenses_sum.controls.details.length ===
                                        0
                                "
                                style="height: 24px"
                            >
                                <mat-icon
                                    *ngIf="dealSystemStatus === 'open'"
                                    matTooltip="To see details section in this deal please save it and edit it again"
                                    >help_outline</mat-icon
                                >
                                <mat-icon
                                    *ngIf="dealSystemStatus === 'approved'"
                                    matTooltip="To see details section in this deal please disapprove it, save it and edit it again."
                                    >help_outline</mat-icon
                                >
                            </div>
                        </div>
                    </div>
                    <app-financial-details
                        class="w-100"
                        *ngIf="
                            isDetailsOpenedReferral &&
                            participantCalculationFG.controls.referral_expenses_sum &&
                            participantCalculationFG.controls.referral_expenses_sum.controls.details
                        "
                        [detailsFA]="participantCalculationFG.controls.referral_expenses_sum.controls.details"
                    ></app-financial-details>
                </div>
                <div
                    class="preview-row subtotal-section"
                    *ngIf="
                        !isGroup &&
                        (isZeroValuesExpanded ||
                            (participantCalculationFG.controls.participant_royalty &&
                                participantCalculationFG.controls.participant_royalty.controls.amount &&
                                participantCalculationFG.controls.participant_royalty.controls.amount.value))
                    "
                >
                    <div class="w-100 node-with-details">
                        <div class="w-55 label text-left">Royalty:</div>
                        <div class="w-35 text-right">
                            <app-financial-node
                                moneyClass="fee-value negative"
                                [financialNodeFG]="participantCalculationFG.controls.participant_royalty"
                                [highlightIfNegative]="true"
                                *ngIf="participantCalculationFG.controls.participant_royalty"
                            ></app-financial-node>
                        </div>
                    </div>
                </div>

                <div
                    class="preview-row subtotal-section"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (participantCalculationFG.controls.additional_incomes_sum &&
                            participantCalculationFG.controls.additional_incomes_sum.controls.amount &&
                            participantCalculationFG.controls.additional_incomes_sum.controls.amount.value) ||
                        (participantCalculationFG.controls.additional_expenses_sum &&
                            participantCalculationFG.controls.additional_expenses_sum.controls.amount &&
                            participantCalculationFG.controls.additional_expenses_sum.controls.amount.value)
                    "
                >
                    <div
                        class="sub-row column"
                        *ngIf="
                            isZeroValuesExpanded ||
                            (participantCalculationFG.controls.additional_incomes_sum &&
                                participantCalculationFG.controls.additional_incomes_sum.controls.amount &&
                                participantCalculationFG.controls.additional_incomes_sum.controls.amount.value)
                        "
                    >
                        <div class="w-100 node-with-details">
                            <div class="w-55 label text-left">Incomes:</div>
                            <div class="w-35 text-right">
                                <app-financial-node
                                    moneyClass="fee-value positive"
                                    *ngIf="participantCalculationFG.controls.additional_incomes_sum"
                                    [financialNodeFG]="participantCalculationFG.controls.additional_incomes_sum"
                                    [highlightIfNegative]="true"
                                ></app-financial-node>
                            </div>
                            <div
                                class="w-10"
                                *ngIf="
                                    participantCalculationFG.controls.additional_incomes_sum &&
                                    participantCalculationFG.controls.additional_incomes_sum.controls.amount &&
                                    participantCalculationFG.controls.additional_incomes_sum.controls.amount.value
                                "
                            >
                                <div
                                    *ngIf="
                                        participantCalculationFG.controls.additional_incomes_sum.controls.details &&
                                        participantCalculationFG.controls.additional_incomes_sum.controls.details
                                            .length > 0
                                    "
                                    class="arrow-button"
                                    (click)="isDetailsOpenedIncomes = !isDetailsOpenedIncomes"
                                >
                                    <mat-icon>{{
                                        isDetailsOpenedIncomes ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                    }}</mat-icon>
                                </div>
                                <div
                                    *ngIf="
                                        participantCalculationFG.controls.additional_incomes_sum.controls.details &&
                                        participantCalculationFG.controls.additional_incomes_sum.controls.details
                                            .length === 0
                                    "
                                    style="height: 24px"
                                >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'open'"
                                        matTooltip="To see details section in this deal please save it and edit it again"
                                        >help_outline</mat-icon
                                    >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'approved'"
                                        matTooltip="To see details section in this deal please disapprove it, save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                </div>
                            </div>
                        </div>
                        <app-financial-details
                            class="w-100"
                            *ngIf="
                                isDetailsOpenedIncomes &&
                                participantCalculationFG.controls.additional_incomes_sum &&
                                participantCalculationFG.controls.additional_incomes_sum.controls.details
                            "
                            [detailsFA]="participantCalculationFG.controls.additional_incomes_sum.controls.details"
                        ></app-financial-details>
                    </div>
                    <div
                        class="sub-row column"
                        *ngIf="
                            isZeroValuesExpanded ||
                            (participantCalculationFG.controls.additional_expenses_sum &&
                                participantCalculationFG.controls.additional_expenses_sum.controls.amount &&
                                participantCalculationFG.controls.additional_expenses_sum.controls.amount.value)
                        "
                    >
                        <div class="w-100 node-with-details">
                            <div class="w-55 label text-left">Expenses:</div>
                            <div class="w-35 text-right">
                                <app-financial-node
                                    moneyClass="fee-value negative"
                                    *ngIf="participantCalculationFG.controls.additional_expenses_sum"
                                    [financialNodeFG]="participantCalculationFG.controls.additional_expenses_sum"
                                    [highlightIfNegative]="true"
                                ></app-financial-node>
                            </div>
                            <div
                                class="w-10"
                                *ngIf="
                                    participantCalculationFG.controls.additional_expenses_sum &&
                                    participantCalculationFG.controls.additional_expenses_sum.controls.amount &&
                                    participantCalculationFG.controls.additional_expenses_sum.controls.amount.value
                                "
                            >
                                <div
                                    *ngIf="
                                        participantCalculationFG.controls.additional_expenses_sum.controls.details &&
                                        participantCalculationFG.controls.additional_expenses_sum.controls.details
                                            .length > 0
                                    "
                                    class="arrow-button"
                                    (click)="isDetailsOpenedExpenses = !isDetailsOpenedExpenses"
                                >
                                    <mat-icon>{{
                                        isDetailsOpenedExpenses ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                    }}</mat-icon>
                                </div>
                                <div
                                    *ngIf="
                                        participantCalculationFG.controls.additional_expenses_sum.controls.details &&
                                        participantCalculationFG.controls.additional_expenses_sum.controls.details
                                            .length === 0
                                    "
                                    style="height: 24px"
                                >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'open'"
                                        matTooltip="To see details section in this deal please save it and edit it again"
                                        >help_outline</mat-icon
                                    >
                                    <mat-icon
                                        *ngIf="dealSystemStatus === 'approved'"
                                        matTooltip="To see details section in this deal please disapprove it, save it and edit it again."
                                        >help_outline</mat-icon
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-financial-details
                        class="w-100"
                        *ngIf="
                            isDetailsOpenedExpenses &&
                            participantCalculationFG.controls.additional_expenses_sum &&
                            participantCalculationFG.controls.additional_expenses_sum.controls.details
                        "
                        [detailsFA]="participantCalculationFG.controls.additional_expenses_sum.controls.details"
                    ></app-financial-details>
                </div>

                <div
                    class="preview-row subtotal-section pr-36"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (participantCalculationFG.controls.net &&
                            participantCalculationFG.controls.net.controls.amount &&
                            participantCalculationFG.controls.net.controls.amount.value)
                    "
                >
                    <div class="w-55 label text-left">NET:</div>
                    <div class="w-45 text-right">
                        <app-financial-node
                            moneyClass="fee-value"
                            *ngIf="participantCalculationFG.controls.net"
                            [financialNodeFG]="participantCalculationFG.controls.net"
                            [highlightIfNegative]="true"
                        ></app-financial-node>
                    </div>
                </div>

                <div
                    class="preview-row subtotal-section"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (participantCalculationFG.controls.taxes &&
                            participantCalculationFG.controls.taxes.controls.amount &&
                            participantCalculationFG.controls.taxes.controls.amount.value) ||
                        (participantCalculationFG.controls.incentive &&
                            participantCalculationFG.controls.incentive.controls.amount &&
                            participantCalculationFG.controls.incentive.controls.amount.value) ||
                        (participantCalculationFG.controls.overhead &&
                            participantCalculationFG.controls.overhead.controls.amount &&
                            participantCalculationFG.controls.overhead.controls.amount.value)
                    "
                >
                    <div
                        class="sub-row pr-36"
                        *ngIf="
                            isZeroValuesExpanded ||
                            (participantCalculationFG.controls.taxes &&
                                participantCalculationFG.controls.taxes.controls.amount &&
                                participantCalculationFG.controls.taxes.controls.amount.value)
                        "
                    >
                        <div class="w-55 label text-left">Taxes:</div>
                        <div class="w-45 text-right">
                            <app-financial-node
                                moneyClass="fee-value positive"
                                *ngIf="participantCalculationFG.controls.taxes"
                                [financialNodeFG]="participantCalculationFG.controls.taxes"
                                [highlightIfNegative]="true"
                            ></app-financial-node>
                        </div>
                    </div>
                    <div
                        class="sub-row pr-36"
                        *ngIf="
                            isZeroValuesExpanded ||
                            (participantCalculationFG.controls.incentive &&
                                participantCalculationFG.controls.incentive.controls.amount &&
                                participantCalculationFG.controls.incentive.controls.amount.value)
                        "
                    >
                        <div class="w-55 label text-left">Incentive:</div>
                        <div class="w-45 text-right">
                            <app-financial-node
                                moneyClass="fee-value positive"
                                *ngIf="participantCalculationFG.controls.incentive"
                                [financialNodeFG]="participantCalculationFG.controls.incentive"
                                [highlightIfNegative]="true"
                            ></app-financial-node>
                        </div>
                    </div>
                    <div
                        class="sub-row pr-36"
                        *ngIf="
                            isZeroValuesExpanded ||
                            (participantCalculationFG.controls.overhead &&
                                participantCalculationFG.controls.overhead.controls.amount &&
                                participantCalculationFG.controls.overhead.controls.amount.value)
                        "
                    >
                        <div class="w-55 label text-left">Overhead:</div>
                        <div class="w-45 text-right">
                            <app-financial-node
                                moneyClass="fee-value negative"
                                *ngIf="participantCalculationFG.controls.overhead"
                                [financialNodeFG]="participantCalculationFG.controls.overhead"
                                [highlightIfNegative]="true"
                            ></app-financial-node>
                        </div>
                    </div>
                </div>

                <div
                    class="preview-row subtotal-section pr-36"
                    *ngIf="
                        isZeroValuesExpanded ||
                        (participantCalculationFG.controls.budget &&
                            participantCalculationFG.controls.budget.controls.amount &&
                            participantCalculationFG.controls.budget.controls.amount.value)
                    "
                >
                    <div class="w-55 label text-left">Budget:</div>
                    <div class="w-45 text-right">
                        <app-financial-node
                            moneyClass="fee-value"
                            *ngIf="participantCalculationFG.controls.budget"
                            [financialNodeFG]="participantCalculationFG.controls.budget"
                            [highlightIfNegative]="true"
                        ></app-financial-node>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    `
})
export class ParticipantPreviewComponent {
    @Input() public isGroup: boolean = false;
    @Input() public participantCalculationFG: GenericFormGroup<ParticipantCalculation> = new GenericFormGroup(
        new ParticipantCalculation(),
        'change'
    );
    @Input() public dealSystemStatus: string = 'draft';

    isZeroValuesExpanded: boolean = false;

    isDetailsOpenedAgentSplit: boolean = false;
    isDetailsOpenedIncomes: boolean = false;
    isDetailsOpenedExpenses: boolean = false;
    isDetailsOpenedReferral: boolean = false;

    constructor(
        protected dealService: DealService,
        protected router: Router,
        protected dialog: MatDialog
    ) {}

    toggleCollapse() {
        this.isZeroValuesExpanded = !this.isZeroValuesExpanded;
    }
}
