import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {GenericControl, GenericFormGroup} from 'app/entites/generic.entity';
import {
    AvailableNamesForCompensationProfileFilter,
    CompensationProfileFilter,
    MultipleTargetsDealsQueryModel,
    SingleTargetDealsQueryModel
} from '../../../deals/components/deal/common/deal.models';
import {Deal} from 'app/models/deal';
import {UntypedFormArray, UntypedFormControl} from '@angular/forms';
import {combineLatest, Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {MembershipOrganization} from 'app/models/membership-organization';
import {select, Store} from '@ngrx/store';
import {ICompanyWideState} from 'app/store/company-wide/company-wide.reducer';
import {selectMembershipOrganizations, selectProcessedSOB} from 'app/store/company-wide/company-wide.selectors';

@Component({
    selector: 'app-company-compensation-filters',
    template: `
        <div style="display: flex; flex-direction: row; align-items: center">
            <h3
                class="label"
                style="margin: 0; padding-right: 40px; font-size: 16px; position: relative; margin-bottom: 10px"
                *ngIf="label"
            >
                {{ label }}
                <div
                    style="position: absolute; top: -13px; right: 0"
                    *ngIf="!isDefaultConditionParameters && viewType === 'row' && dropdown"
                >
                    <button
                        color="primary"
                        mat-icon-button
                        [disabled]="disabled"
                        (click)="filtersForm?.controls?.used_default_values?.patchValue(false)"
                        *ngIf="filtersForm?.controls?.used_default_values?.value"
                        [matTooltip]="'Unlock to set custom Condition Parameters'"
                    >
                        <mat-icon>lock</mat-icon>
                    </button>
                    <button
                        color="primary"
                        mat-icon-button
                        [disabled]="disabled"
                        (click)="filtersForm?.controls?.used_default_values?.patchValue(true)"
                        *ngIf="!filtersForm?.controls?.used_default_values?.value"
                        [matTooltip]="'Lock to set the values of the first element in the order'"
                    >
                        <mat-icon>lock_open</mat-icon>
                    </button>
                </div>
            </h3>

            <ng-container *ngIf="viewType === 'menu'">
                <button [disabled]="addFilterDisabled || disabled" mat-icon-button [matMenuTriggerFor]="criteriaMenu">
                    <mat-icon>add</mat-icon>
                </button>

                <mat-menu #criteriaMenu="matMenu" [class]="'add-item-menu'">
                    <button
                        mat-menu-item
                        [disabled]="disabled"
                        *ngFor="let filter of filtersToAdd"
                        (click)="addFilter(filter.name)"
                    >
                        {{ filter.placeholder }}
                    </button>
                </mat-menu>
            </ng-container>
        </div>
        <div
            style="display: flex; flex-wrap: wrap;"
            *ngIf="
                viewType === 'row' &&
                (!dropdown || !filtersForm?.controls?.used_default_values?.value || isDefaultConditionParameters)
            "
        >
            <mat-form-field style="margin-right: 4px">
                <mat-select placeholder="Deal Date" [formControl]="filtersForm?.controls?.deal_date!">
                    <mat-option [value]="null">NONE</mat-option>
                    <mat-option *ngFor="let type of object_keys(deal_date_SET)" [value]="type">
                        {{ deal_date_LABELS[type] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf="filtersForm?.controls?.deal_date?.value === deal_date_SET.specific_dates">
                <mat-form-field style="margin-right: 4px; width:180px">
                    <input
                        matInput
                        [matDatepicker]="closeOfEscrowAfter"
                        [formControl]="filtersForm.controls.close_of_escrow_from!"
                        (dateChange)="changeElementDate($event, 'close_of_escrow_from')"
                        placeholder="Deals Closed: Start"
                    />
                    <mat-datepicker-toggle matSuffix [for]="closeOfEscrowAfter"></mat-datepicker-toggle>
                    <mat-datepicker #closeOfEscrowAfter></mat-datepicker>
                </mat-form-field>

                <mat-form-field style="margin-right: 4px; width:180px">
                    <input
                        matInput
                        [matDatepicker]="closeOfEscrowBefore"
                        [formControl]="filtersForm.controls.close_of_escrow_till!"
                        (dateChange)="changeElementDate($event, 'close_of_escrow_till')"
                        placeholder="Deals Closed: End"
                    />
                    <mat-datepicker-toggle matSuffix [for]="closeOfEscrowBefore"></mat-datepicker-toggle>
                    <mat-datepicker #closeOfEscrowBefore></mat-datepicker>
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="filtersForm?.controls?.deal_date?.value === deal_date_SET.dynamic_dates">
                <mat-form-field style="margin-right: 4px">
                    <mat-select placeholder="Frequency" [formControl]="filtersForm!.controls.frequency!">
                        <mat-option *ngFor="let type of object_keys(frequency_SET)" [value]="type">
                            {{ frequency_LABELS[type] }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field style="margin-right: 4px; width:180px">
                    <input
                        matInput
                        [matDatepicker]="overrideAnniversary"
                        [formControl]="
                            getFilterFormControl(
                                filtersForm!.controls,
                                AvailableNamesForCompensationProfileFilter.override_user_anniversary
                            )
                        "
                        (dateChange)="changeElementDate($event, 'override_user_anniversary')"
                        placeholder="Override Anniversary"
                    />
                    <mat-datepicker-toggle matSuffix [for]="overrideAnniversary"></mat-datepicker-toggle>
                    <mat-datepicker #overrideAnniversary></mat-datepicker>
                </mat-form-field>
            </ng-container>

            <mat-form-field style="margin-right: 4px">
                <mat-select
                    placeholder="Type"
                    multiple
                    [formControl]="
                        getFilterFormControl(filtersForm!.controls, AvailableNamesForCompensationProfileFilter.types)
                    "
                >
                    <mat-option *ngFor="let type of object_keys(DEAL.type_SET)" [value]="type">
                        {{ DEAL.type_LABELS[type] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-right: 4px;">
                <mat-select
                    placeholder="Property Class"
                    multiple
                    [formControl]="
                        getFilterFormControl(
                            filtersForm!.controls,
                            AvailableNamesForCompensationProfileFilter.property_classes
                        )
                    "
                >
                    <mat-option
                        *ngFor="let property_class of object_keys(DEAL.property_class_SET)"
                        [value]="property_class"
                    >
                        {{ DEAL.property_class_LABELS[property_class] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-right: 4px; width: 180px;">
                <mat-select
                    placeholder="Source of Business"
                    multiple
                    [formControl]="
                        getFilterFormControl(
                            filtersForm!.controls,
                            AvailableNamesForCompensationProfileFilter.source_of_business
                        )
                    "
                >
                    <mat-option
                        *ngFor="let sob of sourcesOfBusiness$ | async"
                        [ngClass]="{'font-italic text-muted': sob.value === 'null'}"
                        [value]="sob.value"
                    >
                        {{ sob.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-right: 4px; width: 180px;">
                <mat-select
                    placeholder="Organizations"
                    multiple
                    [formControl]="
                        getFilterFormControl(
                            filtersForm!.controls,
                            AvailableNamesForCompensationProfileFilter.mls_organisations
                        )
                    "
                >
                    <mat-option *ngFor="let org of mlsOrganizations$ | async" [value]="org.id">
                        {{ org.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-right: 4px; width: 180px;">
                <mat-select
                    placeholder="Status"
                    multiple
                    [formControl]="
                        getFilterFormControl(filtersForm!.controls, AvailableNamesForCompensationProfileFilter.statuses)
                    "
                >
                    <mat-option *ngFor="let status of object_keys(DEAL.status_SET)" [value]="status">
                        {{ DEAL.status_LABELS[status] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="w-100 zp-pd-15" *ngIf="viewType === 'menu'" style="display: flex; flex-wrap: wrap">
            <app-deal-filter
                *ngFor="let filter of existingFilters; index as filterIndex"
                [dealFilter]="filter"
                [control]="CompensationProfileFilter.getFilterFormControl(filtersForm!.controls, filter.name)"
                [advancedFilterFormGroup]="filtersForm!"
                (removeFilter)="removeFilter(filterIndex)"
            >
            </app-deal-filter>
        </div>
    `
})
export class FiltersComponent implements OnInit, OnDestroy, OnChanges {
    CompensationProfileFilter = CompensationProfileFilter;
    AvailableNamesForCompensationProfileFilter = AvailableNamesForCompensationProfileFilter;

    private unsubscribe: Subject<void> = new Subject();
    @Input() viewType: 'row' | 'menu' = 'row';
    @Input() filtersForm: GenericFormGroup<MultipleTargetsDealsQueryModel> = new GenericFormGroup(
        new MultipleTargetsDealsQueryModel()
    );
    @Input() label: string = 'Condition Parameters';
    @Input() dropdown: boolean = false;
    @Input() isDefaultConditionParameters: boolean = false;
    @Input() disabled: boolean = false;

    expand: boolean = false;
    public type_SET = Deal.type_SET;
    public type_LABELS = {
        buyer: 'Buyer',
        seller: 'Seller'
    };

    public status_SET = Deal.status_SET;
    public status_LABELS = {
        opportunity: 'Opportunity',
        active: 'Active',
        pending: 'Pending',
        closed: 'Closed',
        cancelled: 'Cancelled'
    };

    public DEAL = Deal;

    deal_date_SET: {[key: string]: string} = SingleTargetDealsQueryModel.deal_date_SET;
    deal_date_LABELS: {[key: string]: string} = {
        specific_dates: 'Specific Dates',
        dynamic_dates: 'Dynamic Dates'
    };

    frequency_SET: {[key: string]: string} = SingleTargetDealsQueryModel.frequency_SET;
    frequency_LABELS: {[key: string]: string} = {
        annually: 'annually',
        monthly: 'monthly',
        weekly: 'weekly',
        daily: 'daily'
    };

    public availableFilters: CompensationProfileFilter[] = [];
    public filtersToAdd: CompensationProfileFilter[] = [];
    public existingFilters: CompensationProfileFilter[] = [];
    public addFilterDisabled: boolean = false;
    public sourcesOfBusiness$: Observable<
        {
            label: string;
            value: string;
        }[]
    > = this.store.pipe(
        select(selectProcessedSOB),
        map((sob) => {
            let arrayToReturn = [
                {
                    value: String(null),
                    label: 'None'
                }
            ];
            arrayToReturn = arrayToReturn.concat(
                sob.sourceList.map((sob) => {
                    return {
                        value: sob.label!,
                        label: sob.label!
                    };
                })
            );
            return arrayToReturn;
        })
    );
    public mlsOrganizations$: Observable<MembershipOrganization[]> = this.store.pipe(
        select(selectMembershipOrganizations)
    );

    object_keys(obj: Object) {
        return Object.keys(obj);
    }

    addFilter(filterName: string | null) {
        const foundFilter = this.filtersToAdd.find((filter) => filter.name === filterName);
        if (typeof foundFilter !== 'undefined') {
            this.filtersToAdd.splice(this.filtersToAdd.indexOf(foundFilter), 1);
            this.existingFilters.push(foundFilter);
            if (foundFilter.type === 'advanced-filter') {
                this.applyAdvancedFilter('add', foundFilter);
            }
            this.existingFilters.sort(CompensationProfileFilter.compareFiltersByOrder);
        }
    }

    removeFilter(filterIndex: number) {
        const filterToDelete = this.existingFilters[filterIndex];
        if (typeof filterToDelete !== 'undefined') {
            if (filterToDelete.name === 'apply_to') {
                const formArray = this.filtersForm.get(filterToDelete.name) as UntypedFormArray;
                formArray.clear();
            } else if (filterToDelete.type === 'advanced-filter') {
                filterToDelete.childrenFilters!.forEach((childFilter) => {
                    this.filtersForm.get(childFilter.name!)!.setValue(null);
                });
            } else {
                this.filtersForm.get(filterToDelete.name!)!.setValue(null);
            }
            this.filtersToAdd.push(this.existingFilters[filterIndex]);
            if (filterToDelete.type === 'advanced-filter') {
                this.applyAdvancedFilter('remove', filterToDelete);
            }
            this.existingFilters.splice(filterIndex, 1);
            this.filtersToAdd.sort(CompensationProfileFilter.compareFiltersByOrder);
        }
    }

    applyAdvancedFilter(action: 'add' | 'remove', filterToApply: CompensationProfileFilter) {
        switch (action) {
            case 'add':
                switch (filterToApply.name) {
                    case 'deal_date_specific_dates': {
                        this.filtersForm.controls.deal_date!.patchValue('specific_dates', {emitEvent: false});
                        const dynamicDatesFilterIndex = this.filtersToAdd.findIndex(
                            (filter) => filter.name === 'deal_date_dynamic_dates'
                        );
                        if (dynamicDatesFilterIndex !== -1) {
                            this.filtersToAdd.splice(dynamicDatesFilterIndex, 1);
                        }
                        break;
                    }
                    case 'deal_date_dynamic_dates': {
                        this.filtersForm.controls.deal_date!.patchValue('dynamic_dates', {emitEvent: false});
                        const specificDatesFilterIndex = this.filtersToAdd.findIndex(
                            (filter) => filter.name === 'deal_date_specific_dates'
                        );
                        if (specificDatesFilterIndex !== -1) {
                            this.filtersToAdd.splice(specificDatesFilterIndex, 1);
                        }
                        break;
                    }
                }
                break;
            case 'remove':
                this.filtersForm.controls.deal_date!.patchValue(null, {emitEvent: false});
                switch (filterToApply.name) {
                    case 'deal_date_specific_dates': {
                        const dynamicDatesFilter = this.availableFilters.find(
                            (filter) => filter.name === 'deal_date_dynamic_dates'
                        );
                        const dynamicDatesFilterIndex = this.filtersToAdd.findIndex(
                            (filter) => filter.name === 'deal_date_dynamic_dates'
                        );
                        if (typeof dynamicDatesFilter !== 'undefined' && dynamicDatesFilterIndex === -1) {
                            this.filtersToAdd.push(dynamicDatesFilter);
                        }
                        break;
                    }
                    case 'deal_date_dynamic_dates': {
                        const specificDatesFilter = this.availableFilters.find(
                            (filter) => filter.name === 'deal_date_specific_dates'
                        );
                        const specificDatesFilterIndex = this.filtersToAdd.findIndex(
                            (filter) => filter.name === 'deal_date_specific_dates'
                        );
                        if (typeof specificDatesFilter !== 'undefined' && specificDatesFilterIndex === -1) {
                            this.filtersToAdd.push(specificDatesFilter);
                        }
                        break;
                    }
                }
                break;
        }
    }

    ngOnInit(): void {
        if (!this.dropdown) {
            this.availableFilters = CompensationProfileFilter.available_compensation_profiles_filters_SET;

            combineLatest([
                this.store.pipe(select(selectProcessedSOB)),
                this.store.pipe(select(selectMembershipOrganizations))
            ])
                .pipe(
                    map(([sob, orgList]) => {
                        const sourceOfBusinessFilter = this.availableFilters.find(
                            (filter) => filter.name === 'source_of_business'
                        );
                        if (typeof sourceOfBusinessFilter !== 'undefined') {
                            sourceOfBusinessFilter.options = sob.sourceList.map((sob) => {
                                return {
                                    title: sob.label!,
                                    value: sob.label
                                };
                            });
                        }

                        const organisationsFilter = this.availableFilters.find(
                            (filter) => filter.name === 'mls_organisations'
                        );
                        if (typeof organisationsFilter !== 'undefined') {
                            organisationsFilter.options = orgList.map((organisation) => {
                                return {
                                    title: organisation.title!,
                                    value: organisation.id
                                };
                            });
                        }
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe(() => {
                    const filtersSnapshot = this.filtersForm.getRawValue();
                    this.filtersToAdd = this.availableFilters.slice(); // slice() just to "clone" value
                    this.existingFilters = [];
                    this.object_keys(filtersSnapshot).forEach((filterName) => {
                        const foundFilter = this.filtersToAdd.find((filter) => {
                            if (filter.type === 'advanced-filter') {
                                return filter.childrenFilters!.some((childFilter) => childFilter.name === filterName);
                            }
                            return filter.name === filterName;
                        });
                        // @ts-ignore
                        if (typeof foundFilter !== 'undefined' && filtersSnapshot[filterName] !== null) {
                            this.filtersToAdd.splice(this.filtersToAdd.indexOf(foundFilter), 1);
                            this.existingFilters.push(foundFilter);
                            if (foundFilter.type === 'advanced-filter') {
                                this.applyAdvancedFilter('add', foundFilter);
                            }
                        }
                    });
                    this.existingFilters.sort(CompensationProfileFilter.compareFiltersByOrder);
                });
        }
    }

    changeElementDate(event: any, controlName: string) {
        this.filtersForm.get(controlName)!.patchValue(event.value.toISOString(), {emitEvent: false});
    }

    constructor(private store: Store<ICompanyWideState>) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.disabled && changes.disabled.currentValue) {
            this.filtersForm.controls.deal_date?.disable({emitEvent: false});
            this.filtersForm.controls.close_of_escrow_from?.disable({emitEvent: false});
            this.filtersForm.controls.close_of_escrow_till?.disable({emitEvent: false});
            this.filtersForm.controls.frequency?.disable({emitEvent: false});
            this.getFilterFormControl(
                this.filtersForm!.controls,
                this.AvailableNamesForCompensationProfileFilter.override_user_anniversary
            ).disable({emitEvent: false});
            this.getFilterFormControl(
                this.filtersForm!.controls,
                this.AvailableNamesForCompensationProfileFilter.types
            ).disable({emitEvent: false});
            this.getFilterFormControl(
                this.filtersForm!.controls,
                this.AvailableNamesForCompensationProfileFilter.property_classes
            ).disable({emitEvent: false});
            this.getFilterFormControl(
                this.filtersForm!.controls,
                this.AvailableNamesForCompensationProfileFilter.source_of_business
            ).disable({emitEvent: false});
            this.getFilterFormControl(
                this.filtersForm!.controls,
                this.AvailableNamesForCompensationProfileFilter.mls_organisations
            ).disable({emitEvent: false});
            this.getFilterFormControl(
                this.filtersForm!.controls,
                this.AvailableNamesForCompensationProfileFilter.statuses
            ).disable({emitEvent: false});
            for (const filter of this.existingFilters) {
                this.CompensationProfileFilter.getFilterFormControl(this.filtersForm!.controls, filter.name)?.disable({
                    emitEvent: false
                });
            }
        } else if (changes.disabled && !changes.disabled.currentValue) {
            this.filtersForm.controls.deal_date?.enable({emitEvent: false});
            this.filtersForm.controls.close_of_escrow_from?.enable({emitEvent: false});
            this.filtersForm.controls.close_of_escrow_till?.enable({emitEvent: false});
            this.filtersForm.controls.frequency?.enable({emitEvent: false});
            this.getFilterFormControl(
                this.filtersForm!.controls,
                this.AvailableNamesForCompensationProfileFilter.override_user_anniversary
            ).enable({emitEvent: false});
            this.getFilterFormControl(
                this.filtersForm!.controls,
                this.AvailableNamesForCompensationProfileFilter.types
            ).enable({emitEvent: false});
            this.getFilterFormControl(
                this.filtersForm!.controls,
                this.AvailableNamesForCompensationProfileFilter.property_classes
            ).enable({emitEvent: false});
            this.getFilterFormControl(
                this.filtersForm!.controls,
                this.AvailableNamesForCompensationProfileFilter.source_of_business
            ).enable({emitEvent: false});
            this.getFilterFormControl(
                this.filtersForm!.controls,
                this.AvailableNamesForCompensationProfileFilter.mls_organisations
            ).enable({emitEvent: false});
            this.getFilterFormControl(
                this.filtersForm!.controls,
                this.AvailableNamesForCompensationProfileFilter.statuses
            ).enable({emitEvent: false});
            for (const filter of this.existingFilters) {
                this.CompensationProfileFilter.getFilterFormControl(this.filtersForm!.controls, filter.name)?.enable({
                    emitEvent: false
                });
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getFilterFormControl(
        controls: {[key in keyof MultipleTargetsDealsQueryModel]?: GenericControl<MultipleTargetsDealsQueryModel[key]>},
        filterName: keyof MultipleTargetsDealsQueryModel
    ): UntypedFormControl {
        return controls[filterName] as UntypedFormControl;
    }
}
