<div class="multi-selection-toolbar-container" [ngStyle]="{left: 'calc(0.5 * (100% - ' + toolbarMinWidth + '))'}">
    <div class="multi-selection-toolbar" [ngStyle]="{'min-width': toolbarMinWidth}">
        <div class="selection-info">
            <div class="selection-text">
                {{ numberSelected }} {{ numberSelected > 1 ? selectionName + 's' : selectionName }} Selected
            </div>
            <div *ngIf="!hideSelectionDivider" class="selection-divider"></div>
        </div>
        <div class="actions-area">
            <ng-content></ng-content>
        </div>
    </div>
</div>
