import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {QuickBooksService} from '../../../../../services/api/addon/quickbooks/quickbooks.service';
import {LedgerAccountService} from '../../../../../services/api/finance/ledger-accounts.service';
import {ILedgerAccount} from '@cyberco-nodejs/zipi-typings';
import {QbLedgerAccountMappingModel} from '../../../../../models/addon/qb-ledger-account-mapping-model';
import {LEDGER_TYPES_MAP} from '../../../../../local-typings';
import {MatSelectChange} from '@angular/material/select';
import {QbCompanySettingsModel} from '../../../../../models/addon/qb-company-settings-model';

interface LedgerAccountQuickbooksMapping {
    account_id: number;
    account_name: string;
    account_code: string;
    account_type: string;
    mapped_quickbooks_ledger: string | null;
}

@Component({
    selector: 'app-quickbooks-ledgers',
    templateUrl: 'quickbooks-ledgers.component.html',
    styleUrls: ['../quickbooks-ledgers-products.component.scss']
})
export class QuickBooksLedgersComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    dataSource: LedgerAccountQuickbooksMapping[] = [];
    qbAccounts: any[] = [];
    listOfLedgerAccounts: ILedgerAccount[] = [];
    mappingsToUpdate: LedgerAccountQuickbooksMapping[] = [];
    defaultLedgerToUpdate: string | null = null;
    ledgerMappings: QbLedgerAccountMappingModel[] = [];
    displayedColumns: string[] = ['account_name', 'account_code', 'account_type', 'mapped_qb_account'];
    @Input() companySettings: QbCompanySettingsModel | null = null;

    constructor(
        private quickBooksService: QuickBooksService,
        private ledgerAccountService: LedgerAccountService
    ) {}

    onSave() {
        const mappingPayload = this.mappingsToUpdate.map((d) => ({
            books_ledger_account_ref: d.account_id as number,
            qb_account_ref: d.mapped_quickbooks_ledger as string
        }));

        if (this.mappingsToUpdate.length > 0) {
            this.quickBooksService
                .createLedgerMappings(mappingPayload)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((next) => {
                    // After successfully updating, reset mappingsToUpdate
                    this.mappingsToUpdate = [];
                });
        }

        // If default ledger was changed, save it
        if (this.companySettings && this.companySettings.default_ap_ledger_account_ref !== this.defaultLedgerToUpdate) {
            this.quickBooksService
                .setDefaultLedger(this.defaultLedgerToUpdate)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {});
        }
    }

    onSelectionChange(event: MatSelectChange, element: any): void {
        // On change, add the updated mapping to a list to be updated onSave.
        const mapping = this.dataSource.find((d) => d.account_id === element.account_id);

        if (mapping) {
            // If this product mapping was already marked for update, remove the old updated value.
            // i.e. the user changed the select option multiple times
            const foundIndex = this.mappingsToUpdate.findIndex((m) => m.account_id === mapping.account_id);
            if (foundIndex !== -1) {
                this.mappingsToUpdate.splice(foundIndex, 1);
            }

            mapping.mapped_quickbooks_ledger = event.value;
            this.mappingsToUpdate.push(mapping);
        }
    }

    ngOnInit() {
        if (this.companySettings?.default_ap_ledger_account_ref) {
            this.defaultLedgerToUpdate = this.companySettings.default_ap_ledger_account_ref;
        }

        const qbAccounts$ = this.quickBooksService.getQbAccounts();
        const ledgerAccounts$ = this.ledgerAccountService.getLedgerAccounts();
        const ledgerMappings$ = this.quickBooksService.getLedgerMappings();

        // Wait until all the required data is available, then set the dataSource.
        forkJoin([qbAccounts$, ledgerAccounts$, ledgerMappings$])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                ([qbAccounts, ledgerAccounts, ledgerMappings$]) => {
                    this.qbAccounts = qbAccounts;
                    this.listOfLedgerAccounts = ledgerAccounts;
                    this.ledgerMappings = ledgerMappings$;

                    this.dataSource = this.listOfLedgerAccounts.map((l) => {
                        const mapped = this.ledgerMappings.find(
                            (m) => m.books_ledger_account_ref === l.ledger_account_id
                        );

                        let qbAccount = null;
                        if (mapped) {
                            qbAccount = this.qbAccounts.find((qba) => qba.Id === mapped.qb_account_ref);
                        }

                        return {
                            account_id: l.ledger_account_id as number,
                            account_name: l.name,
                            account_code: l.code,
                            account_type: LEDGER_TYPES_MAP[l.type],
                            mapped_quickbooks_ledger: qbAccount ? qbAccount.Id : null
                        };
                    });
                },
                (error) => {
                    console.error('Error fetching QB Chart of Accounts data:', error);
                }
            );
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
