<mat-card
    class="widget-container cap-commission-widget"
    *ngIf="widget"
    [ngClass]="widget.widget_id && customizeVisibility ? 'editable' : ''"
>
    <div class="widget-header" style="height: 40px; align-items: center">
        <mat-card-title style="vertical-align: center">{{ widget.name ? widget.name : ' ' }}</mat-card-title>
        <span class="d-flex align-items-center">
            <i class="d-flex align-items-center icon-minutes-ago mr-1">
                <button *ngIf="widget.widget_id" mat-icon-button (click)="reloadWidgetData()">
                    <mat-icon>cached</mat-icon>
                </button>
                <span style="white-space: nowrap; margin-top: 4px">{{ getMinutesAgo() }} min</span>
            </i>
            <span *ngIf="customizeVisibility && !configPanelVisible">
                <i class="material-icons icon-size" style="color: #ff8566" (click)="deleteWidget()">delete</i>
                <i class="material-icons icon-size" (click)="showConfigPanel()">settings</i>
            </span>
        </span>
    </div>
    <mat-card-content *ngIf="configPanelVisible">
        <form [id]="idFormName" #capForm="ngForm" (ngSubmit)="saveWidgetConfig(capForm)">
            <div class="mb-3">
                <mat-form-field class="mr-2">
                    <input matInput [(ngModel)]="widget.name" required name="name" placeholder="Title" />
                </mat-form-field>
                <mat-form-field class="mr-2">
                    <input matInput name="type" disabled placeholder="Type" value="Cap Commission Structure" />
                </mat-form-field>
            </div>
            <div class="mt-2">
                <div class="w-auto select-entity pr-3" [ngClass]="{error: !isEntityPickerValid}">
                    <app-combined-entity-picker
                        rbac
                        [rule]="{company_settings__manage_compensation: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        title="Select profile"
                        [singleSelected]="true"
                        [availableTypes]="['individual', 'company_group']"
                        [only_compensation_groups]="true"
                        [nodesFA]="profileControl"
                    ></app-combined-entity-picker>
                    <span
                        rbac
                        [rule]="{company_settings__manage_compensation: false}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                    >
                        <h3>Select Profile</h3>
                        <span *ngIf="currentProfile">
                            <strong> {{ currentProfile.first_name }} {{ currentProfile.last_name }} </strong>
                        </span>
                    </span>
                </div>
            </div>
            <div class="mt-2">
                <div class="w-auto select-entity pr-3" [ngClass]="{error: !isFinancialElementPickerValid}">
                    <app-widget-compensation-profile-picker
                        title="Select Financial element"
                        [value]="widget.settings.financial_element_id"
                        [profileEntity]="profileControl.value"
                        [singleSelected]="true"
                        (valueChanges)="onCompensationProfileChange($event)"
                    ></app-widget-compensation-profile-picker>
                </div>
            </div>
            <mat-card-actions *ngIf="configPanelVisible" class="action-buttons">
                <button mat-raised-button class="mat-primary" [disabled]="!capForm.valid">Save</button>
                <button mat-raised-button class="mat-active" (click)="hideConfigPanel()">Cancel</button>
            </mat-card-actions>
        </form>
    </mat-card-content>

    <mat-card-content *ngIf="!configPanelVisible && isDataExist && isLoaded && isAllowToDisplay" class="w-data">
        <div class="space-around income-result cap-structure">
            <div class="row w-100 flex-row">
                <div class="user-info">
                    <span class="name"
                        >{{ data.widget_data.profile.first_name }} {{ data.widget_data.profile.last_name }}</span
                    >
                    <span>{{ data.commissionTitle }}</span>
                </div>
            </div>

            <div class="row w-100 mt-xl-2" *ngIf="data.isCurrentRulePresent">
                <div class="col-xl-8 p-0">
                    <span class="entity-name">Contribution (by today)</span>
                    <ng-container *ngIf="data.currentValue">
                        <span
                            class="entity-value"
                            *ngIf="
                                data.widget_data.current_rule.type !== 'count_of_deals' &&
                                data.widget_data.current_rule.type !== 'total_side_count'
                            "
                        >
                            {{ data.currentValue | currency }}
                        </span>
                        <span class="entity-value" *ngIf="data.widget_data.current_rule.type === 'count_of_deals'">
                            {{ data.currentValue | i18nPlural: itemPluralMapping['deal'] }}
                        </span>
                        <span class="entity-value" *ngIf="data.widget_data.current_rule.type === 'total_side_count'">
                            {{ data.currentValue }}
                        </span>
                    </ng-container>
                </div>
                <div class="col-xl-4 p-0 text-right">
                    <span class="entity-name">Cap goal</span>
                    <ng-container *ngIf="data.goalValue">
                        <span
                            class="entity-value"
                            *ngIf="
                                data.widget_data.current_rule.type !== 'count_of_deals' &&
                                data.widget_data.current_rule.type !== 'total_side_count'
                            "
                        >
                            {{ data.goalValue | currency }}
                        </span>
                        <span class="entity-value" *ngIf="data.widget_data.current_rule.type === 'count_of_deals'">
                            {{ data.goalValue | i18nPlural: itemPluralMapping['deal'] }}
                        </span>
                        <span class="entity-value" *ngIf="data.widget_data.current_rule.type === 'total_side_count'">
                            {{ data.goalValue }}
                        </span>
                    </ng-container>
                    <span class="entity-value" *ngIf="!data.goalValue">No value</span>
                </div>
                <mat-progress-bar
                    mode="determinate"
                    class="blue-bar"
                    [value]="(data.currentValue * 100) / data.goalValue"
                >
                </mat-progress-bar>
            </div>
            <div class="row w-100 mt-xl-2" *ngIf="!data.isCurrentRulePresent">
                <div class="col-xl-12 p-0">No rules matches.</div>
            </div>
        </div>
    </mat-card-content>

    <mat-card-content *ngIf="!configPanelVisible && isDataExist && isLoaded && !isAllowToDisplay" class="w-data">
        <div class="space-around income-result cap-structure">
            Data access deny. Widget settings conflict with your permissions.
        </div>
    </mat-card-content>

    <mat-card-content *ngIf="!configPanelVisible && !isDataExist && isLoaded && isAllowToDisplay" class="w-data">
        <div class="space-around income-result cap-structure">
            No data to display. Person might not be assigned to this compensation plan.
        </div>
    </mat-card-content>
</mat-card>
