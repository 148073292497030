<div class="container container-fluid">
    <mat-card class="my-3">
        <mat-card-content>
            <app-company-setup [formGroup]="$any(companySetupForm)"> </app-company-setup>
        </mat-card-content>
        <mat-card-actions class="w-100 text-right">
            <button mat-raised-button color="primary" [disabled]="saveDisabled" (click)="saveCompanySetup()">
                Save
            </button>
        </mat-card-actions>
    </mat-card>

    <app-company-settings></app-company-settings>
</div>

<!--<div class="container">-->
<!--<mat-expansion-panel #companySetupPanel (opened)="loadCompanySetup()">-->
<!--<mat-expansion-panel-header>-->
<!--<mat-panel-title>Company Setup</mat-panel-title>-->
<!--</mat-expansion-panel-header>-->
<!--<app-company-setup *ngIf="companySetupLoaded" [formGroup]="companySetupForm"></app-company-setup>-->
<!--<button mat-button class="mat-primary"-->
<!--[disabled]="!companySetupLoaded"-->
<!--(click)="saveCompanySetup()">SAVE</button>-->
<!--</mat-expansion-panel>-->
<!--</div>-->

<!--<div class="container">-->
<!--<mat-expansion-panel #companyGoalsPanel>-->
<!--<mat-expansion-panel-header>-->
<!--<mat-panel-title>Company Goals</mat-panel-title>-->
<!--</mat-expansion-panel-header>-->
<!--<app-company-goals *ngIf="companyGoalsLoaded" [formGroup]="companyGoalsForm"></app-company-goals>-->
<!--<button mat-button class="mat-primary"-->
<!--[disabled]="!companyGoalsLoaded"-->
<!--(click)="saveCompanyGoals()">SAVE</button>-->
<!--</mat-expansion-panel>-->
<!--</div>-->

<!--<div class="container">-->
<!--<mat-expansion-panel #userSetupPanel (opened)="loadUserSetup()" [disabled]="!companyGoalsSaved || !companySetupSaved">-->
<!--<mat-expansion-panel-header>-->
<!--<mat-panel-title>User Setup</mat-panel-title>-->
<!--</mat-expansion-panel-header>-->
<!--<app-user-setup *ngIf="userSetupLoaded" [formGroup]="userSetupForm"></app-user-setup>-->
<!--<button mat-button class="mat-primary"-->
<!--[disabled]="!userSetupLoaded"-->
<!--(click)="saveUserSetup()">SAVE</button>-->
<!--</mat-expansion-panel>-->
<!--</div>-->
