import {Component} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';

@Component({
    selector: 'app-auto-open-menu',
    template: `
        <div
            class="app-nav-item"
            [matMenuTriggerFor]="menu"
            #menuTrigger="matMenuTrigger"
            (mouseenter)="mouseEnter(menuTrigger)"
            (mouseleave)="opened = false; mouseLeave(menuTrigger)"
        >
            <ng-content select="[trigger]"></ng-content>
        </div>

        <mat-menu class="mat-menu-item-custom" #menu="matMenu" [hasBackdrop]="false">
            <div (mouseenter)="opened = true" (mouseleave)="opened = false; mouseLeave(menuTrigger)">
                <ng-content select="[content]"></ng-content>
            </div>
        </mat-menu>
    `,
    styles: [
        `
            ::ng-deep .mat-menu-item-custom {
                background-color: rgba(255, 255, 255, 0.9);
                border-radius: 4px !important;
                box-shadow: 0 0 3px 0 #3f5b77;
                height: fit-content;
                min-height: 10px !important;
                position: absolute;
                left: 183px;
                top: -35px;
            }

            ::ng-deep .mat-menu-item-custom .mat-menu-content {
                padding: 0;
            }

            ::ng-deep .mat-menu-item-custom .mat-menu-content .mat-menu-item {
                color: #3f5b77;
                line-height: 36px;
                height: 36px;
                font-size: 0.9rem;
            }
            ::ng-deep .mat-menu-item-custom .mat-menu-content .mat-menu-item:hover {
                text-decoration: none;
                background-color: #f4f8fc;
            }
        `
    ]
})
export class AutoOpenMenuComponent {
    opened = false;

    constructor() {}

    mouseEnter(trigger: MatMenuTrigger) {
        this.opened = true;
        trigger.openMenu();
    }

    mouseLeave(trigger: MatMenuTrigger) {
        requestAnimationFrame(() => {
            if (!this.opened) {
                trigger.closeMenu();
            }
        });
    }
}
