<div mat-dialog-title>
    <h3>Warning!</h3>
</div>

<div>Total Amount of Bill different from the Total Amount of Source Document</div>

<mat-dialog-actions class="d-flex mb-2 justify-content-end">
    <button mat-raised-button color="accent" (click)="dialogRef.close('reconnect')" tabindex="1">
        Delete this Bill and Reconnect
    </button>
    <button *ngIf="!data.hideCancel" mat-raised-button (click)="dialogRef.close('link')" tabindex="-1">
        Dismiss and keep it Linked
    </button>
</mat-dialog-actions>
