import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {firstValueFrom, Observable, Subject} from 'rxjs';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CompanyGatewayService} from '../../../../../../../../services/api/finance/company-gateway.service';
import {NotificationsService} from 'angular2-notifications';
import {SessionService} from '../../../../../../../../services/session.service';
import {GatewayTypes, IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {
    US_STATE_MAPPING_LIST,
    ZIPI_FINANCIAL_BUSINESS_INDUSTRIES,
    ZIPI_FINANCIAL_MUHNEE_BUSINESS_INDUSTRIES,
    ZIPI_FINANCIAL_MUHNEE_BUSINESS_TYPES
} from '../../../../../../../../local-typings';
import {MatDialog} from '@angular/material/dialog';
import {FormGroupArrayWithGroupAsControl} from '../../../../../../../../typings/common';
import {MuhneePersonaRedirectDialogComponent} from '../../../dialogs/muhnee-persona-redirect-dialog/muhnee-persona-redirect-dialog.component';
import {NotificationsServiceZipi} from '../../../../../../../notifications/notifications.service';
import {MatStepper} from '@angular/material/stepper';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {whitespaceValidator} from 'app/forms/validators';

@Component({
    selector: 'app-zipi-financial-muhnee-hosted-setup',
    templateUrl: './zipi-financial-muhnee-hosted-setup.component.html',
    styleUrls: ['./zipi-financial-muhnee-hosted-setup.component.scss']
})
export class ZipiFinancialMuhneeHostedSetupComponent implements OnInit, OnChanges, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() existingOperatingGateways: IPaymentGateway[] | undefined;
    @Input() existingGatewayInfo: ({[key: string]: any} & {representatives: Array<{[key: string]: any}>}) | undefined;
    @Input() existingGateway: IPaymentGateway | undefined;
    @Input() gatewayType: GatewayTypes | undefined;
    @Output() reloadAndShowFinalPopup = new EventEmitter();
    @Output() setupStarted = new EventEmitter();

    @ViewChild('documentFormation') documentFormation: ElementRef | undefined;
    @ViewChild('documentCp575') documentCp575: ElementRef | undefined;
    @ViewChild('documentBankStatement') documentBankStatement: ElementRef | undefined;
    @ViewChild('documentOtherDoc') documentOtherDoc: ElementRef | undefined;
    @ViewChild('documentSupporting') documentSupporting: ElementRef | undefined;
    @ViewChild('stepper') stepper: MatStepper | undefined;

    startDate = new Date(1990, 0, 1);
    stateProvinceRegionList = US_STATE_MAPPING_LIST;
    businessTypesMap: Array<{title: string; slug: string}> = ZIPI_FINANCIAL_MUHNEE_BUSINESS_TYPES;

    companyTitle: string | null | undefined;
    businessTypeTitle: string = '';

    lockButton: boolean = false;
    certify: boolean = false;
    certifyValidated: boolean = false;
    isAbleToSetupTrust: boolean = false;
    agree: boolean = false;

    muhneeBusinessDetailsForm: UntypedFormGroup = this.fb.group({});
    muhneeBusinessDocumentsForm: UntypedFormGroup = this.fb.group({
        formation: [null, [Validators.required]],
        cp575: [null, [Validators.required]]
    });
    muhneeNonBusinessDocumentsForm: UntypedFormGroup = this.fb.group({
        bankStatement: [null, [Validators.required]],
        otherDoc: [null, [Validators.required]]
    });
    muhneeSupportingDocumentsArray: FormGroupArrayWithGroupAsControl = this.fb.array(
        []
    ) as FormGroupArrayWithGroupAsControl;
    readerDocument = new FileReader();
    muhneeRepresentativesArray: FormGroupArrayWithGroupAsControl = this.fb.array(
        []
    ) as FormGroupArrayWithGroupAsControl;
    muhneeControllerDetailsForm: UntypedFormGroup = this.fb.group({});

    industries: Array<{title: string; hash: string}> = ZIPI_FINANCIAL_MUHNEE_BUSINESS_INDUSTRIES;
    industryTitle: UntypedFormControl = this.fb.control('Real Estate Agents, Rentals - 6513');
    filteredIndustries: Observable<{title: string; hash: string}[]> | undefined;

    currentStep: number = 0;

    verifyIdLink: string = '';
    verified: boolean = false;

    constructor(
        private companyGatewayService: CompanyGatewayService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        public sessionService: SessionService,
        public dialog: MatDialog,
        private notificationServiceZipi: NotificationsServiceZipi
    ) {}

    ngOnInit() {
        this.initForm();
        this.filteredIndustries = this.industryTitle?.valueChanges.pipe(
            startWith(''),
            map((val) => this._filterIndustries(val)),
            takeUntil(this.unsubscribe)
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('existingOperatingGateways') && changes.existingOperatingGateways.currentValue) {
            this.checkIfTrustAvailable();
        }
    }

    initForm() {
        this.muhneeBusinessDetailsForm = this.fb.group({
            title: ['Money Transfer', [Validators.required]],
            gateway_type: ['zipi_financial_business', [Validators.required]],
            business_name: [null, [Validators.required, whitespaceValidator]],
            dba: [null, [Validators.required, whitespaceValidator]],
            business_type: ['', [Validators.required]],
            ein: ['', []],
            ssn: ['', [Validators.required, whitespaceValidator, Validators.minLength(11)]],
            web: ['', [Validators.required, whitespaceValidator]],
            business_email: [
                null,
                [
                    Validators.required,
                    whitespaceValidator,
                    Validators.pattern(/^([a-zA-Z0-9_\-\.+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)
                ]
            ],
            formation_date: [null, [Validators.required]],
            description: [
                '',
                [Validators.required, whitespaceValidator, Validators.minLength(10), Validators.maxLength(100)]
            ],
            industry_hash: ['realEstate', [Validators.required]],
            is_has_website: [null, [Validators.required]],
            is_has_dba: [null, [Validators.required]],
            address_line_1: ['', [Validators.required, whitespaceValidator, Validators.maxLength(32)]],
            city: ['', [Validators.required, whitespaceValidator]],
            state: ['', [Validators.required]],
            postalCode: [null, [Validators.required, whitespaceValidator, Validators.minLength(5)]],
            country: ['US', [Validators.required, whitespaceValidator]]
        });

        this.muhneeControllerDetailsForm = this.fb.group({
            first_name: ['', [Validators.required, whitespaceValidator]],
            last_name: ['', [Validators.required, whitespaceValidator]],
            date_of_birth: [null, [Validators.required]],
            ssn: ['', [Validators.required, Validators.minLength(11)]],

            phone_country_code: ['1', [Validators.required]],
            phone_number: [
                null,
                [Validators.required, whitespaceValidator, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]
            ],

            address_line_1: ['', [Validators.required, whitespaceValidator, Validators.maxLength(32)]],
            city: ['', [Validators.required, whitespaceValidator]],
            state: ['', [Validators.required]],
            postalCode: [null, [Validators.required, whitespaceValidator, Validators.minLength(5)]],
            country: ['US', [Validators.required]]
        });

        const currentProfile = this.sessionService.profile;
        if (currentProfile && currentProfile.company && currentProfile.company.access_plan) {
            if (currentProfile.company.access_plan === 'agent') {
                this.muhneeBusinessDetailsForm.controls.business_type.setValue('soleProprietor');
            }
        }

        if (this.existingGateway && this.existingGateway.payment_gateway_id && this.existingGatewayInfo) {
            if (!this.existingGatewayInfo.is_onboarding_data_setup) {
                this.muhneeBusinessDetailsForm.controls.business_name.setValue(this.existingGatewayInfo.name);
                this.muhneeBusinessDetailsForm.controls.business_name.disable();
                this.muhneeBusinessDetailsForm.controls.business_email.setValue(this.existingGatewayInfo.email);
                this.muhneeBusinessDetailsForm.controls.business_email.disable();
                this.muhneeControllerDetailsForm.controls.phone_number.setValue(
                    this.existingGatewayInfo.phone.slice(2)
                );
                this.muhneeControllerDetailsForm.controls.phone_number.disable();
            } else {
                this.muhneeBusinessDetailsForm.disable();
                this.currentStep = 3;
            }
        }

        this.muhneeBusinessDetailsForm.controls.postalCode = this._addZipMaskListener(
            this.muhneeBusinessDetailsForm.controls.postalCode
        );
        this.muhneeBusinessDetailsForm.controls.ein = this._addEnnMaskListener(
            this.muhneeBusinessDetailsForm.controls.ein
        );
        this.muhneeBusinessDetailsForm.controls.ssn = this._addFullSnnMaskListener(
            this.muhneeBusinessDetailsForm.controls.ssn
        );
        this.muhneeBusinessDetailsForm.controls.is_has_website = this._addIsHasWebsiteListener(
            this.muhneeBusinessDetailsForm.controls.is_has_website
        );
        this.muhneeBusinessDetailsForm.controls.is_has_dba = this._addIsHasDbaListener(
            this.muhneeBusinessDetailsForm.controls.is_has_dba
        );

        this.muhneeControllerDetailsForm.controls.ssn = this._addFullSnnMaskListener(
            this.muhneeControllerDetailsForm.controls.ssn
        );
        this.muhneeControllerDetailsForm.controls.postalCode = this._addZipMaskListener(
            this.muhneeControllerDetailsForm.controls.postalCode
        );
        this.muhneeControllerDetailsForm.controls.phone_number = this._addPhoneMaskListener(
            this.muhneeControllerDetailsForm.controls.phone_number
        );

        this.muhneeBusinessDetailsForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (changes) {
                this.setupStarted.emit(true);
            }
        });

        this.initValueChangeSubscription();

        this.checkIfTrustAvailable();
    }

    checkIfTrustAvailable() {
        if (
            this.existingOperatingGateways &&
            this.existingOperatingGateways.length > 0 &&
            this.existingOperatingGateways.some((gateway) => gateway.status === 'active')
        ) {
            this.isAbleToSetupTrust = true;
        } else {
            this.isAbleToSetupTrust = false;
        }
    }

    typeChange(type: string) {
        const foundBusinessType = this.businessTypesMap.find((businessType) => businessType.slug === type);
        if (foundBusinessType) {
            this.businessTypeTitle = foundBusinessType.title;
        }

        if (type === 'soleProprietor') {
            this.muhneeBusinessDetailsForm.controls.ssn.setValidators(Validators.required);
            this.muhneeBusinessDetailsForm.controls.ssn.setValidators(Validators.minLength(11));
            this.muhneeBusinessDetailsForm.controls.ssn = this._addFullSnnMaskListener(
                this.muhneeBusinessDetailsForm.controls.ssn
            );
            this.muhneeBusinessDetailsForm.controls.ein.clearValidators();
            this.muhneeBusinessDetailsForm.controls.ein.setValue('');
        } else {
            this.muhneeBusinessDetailsForm.controls.ein.setValidators(Validators.required);
            this.muhneeBusinessDetailsForm.controls.ein.setValidators(Validators.minLength(10));

            this.muhneeBusinessDetailsForm.controls.ein = this._addEnnMaskListener(
                this.muhneeBusinessDetailsForm.controls.ein
            );
            this.muhneeBusinessDetailsForm.controls.ssn.clearValidators();
            this.muhneeBusinessDetailsForm.controls.ssn.setValue('');
        }
        this.muhneeBusinessDetailsForm.controls.ein.updateValueAndValidity();
        this.muhneeBusinessDetailsForm.controls.ssn.updateValueAndValidity();
    }

    copyAddress($event: MatCheckboxChange) {
        if ($event.checked) {
            this.muhneeControllerDetailsForm.controls.address_line_1.setValue(
                this.muhneeBusinessDetailsForm.controls.address_line_1.value
            );
            this.muhneeControllerDetailsForm.controls.city.setValue(this.muhneeBusinessDetailsForm.controls.city.value);
            this.muhneeControllerDetailsForm.controls.state.setValue(
                this.muhneeBusinessDetailsForm.controls.state.value
            );
            this.muhneeControllerDetailsForm.controls.postalCode.setValue(
                this.muhneeBusinessDetailsForm.controls.postalCode.value
            );

            this.muhneeControllerDetailsForm.controls.address_line_1.disable();
            this.muhneeControllerDetailsForm.controls.city.disable();
            this.muhneeControllerDetailsForm.controls.state.disable();
            this.muhneeControllerDetailsForm.controls.postalCode.disable();
        } else {
            this.muhneeControllerDetailsForm.controls.address_line_1.setValue('');
            this.muhneeControllerDetailsForm.controls.city.setValue('');
            this.muhneeControllerDetailsForm.controls.state.setValue('');
            this.muhneeControllerDetailsForm.controls.postalCode.setValue(null);

            this.muhneeControllerDetailsForm.controls.address_line_1.enable();
            this.muhneeControllerDetailsForm.controls.city.enable();
            this.muhneeControllerDetailsForm.controls.state.enable();
            this.muhneeControllerDetailsForm.controls.postalCode.enable();
        }
    }

    selectIndustry($event: any, industry: any) {
        if ($event.isUserInput) {
            this.muhneeBusinessDetailsForm?.controls.industry_hash.setValue(industry.hash);
        }
    }

    removeBeneficial(idx: number) {
        if (this.muhneeRepresentativesArray) {
            this.muhneeRepresentativesArray.removeAt(idx);
        }
    }

    addRepresentative() {
        const newBeneficialGroup = this.fb.group({
            first_name: ['', [Validators.required, whitespaceValidator]],
            last_name: ['', [Validators.required, whitespaceValidator]],
            date_of_birth: [null, [Validators.required]]
        });
        if (this.muhneeRepresentativesArray) {
            this.muhneeRepresentativesArray.push(newBeneficialGroup);
        }
    }

    handleFileInput(files: any, type: 'formation' | 'cp575' | 'supporting' | 'bankStatement' | 'otherDoc') {
        if (files.length === 0 || !files[0]) {
            return;
        }
        const [file] = files;

        switch (type) {
            case 'formation': {
                if (file.size > 5000000) {
                    this.notificationServiceZipi.addError('Uploaded file is too big. File size limit is 5 MB.');
                    this.muhneeBusinessDocumentsForm.controls.formation.setValue(null);
                    this.documentFormation!.nativeElement.value = null;
                } else {
                    this.muhneeBusinessDocumentsForm.controls.formation.setValue(file);
                }
                break;
            }
            case 'cp575': {
                if (file.size > 5000000) {
                    this.notificationServiceZipi.addError('Uploaded file is too big. File size limit is 5 MB.');
                    this.muhneeBusinessDocumentsForm.controls.cp575.setValue(null);
                    this.documentCp575!.nativeElement.value = null;
                } else {
                    this.muhneeBusinessDocumentsForm.controls.cp575.setValue(file);
                }
                break;
            }
            case 'bankStatement': {
                if (file.size > 5000000) {
                    this.notificationServiceZipi.addError('Uploaded file is too big. File size limit is 5 MB.');
                    this.muhneeNonBusinessDocumentsForm.controls.bankStatement.setValue(null);
                    this.documentBankStatement!.nativeElement.value = null;
                } else {
                    this.muhneeNonBusinessDocumentsForm.controls.bankStatement.setValue(file);
                }
                break;
            }
            case 'otherDoc': {
                if (file.size > 5000000) {
                    this.notificationServiceZipi.addError('Uploaded file is too big. File size limit is 5 MB.');
                    this.muhneeNonBusinessDocumentsForm.controls.otherDoc.setValue(null);
                    this.documentOtherDoc!.nativeElement.value = null;
                } else {
                    this.muhneeNonBusinessDocumentsForm.controls.otherDoc.setValue(file);
                }
                break;
            }
            case 'supporting': {
                if (file.size > 5000000) {
                    this.notificationServiceZipi.addError('Uploaded file is too big. File size limit is 5 MB.');
                    this.documentSupporting!.nativeElement.value = null;
                } else {
                    const newFile = this.fb.control(file);
                    this.muhneeSupportingDocumentsArray.push(newFile);
                }
                break;
            }
        }
    }

    removeFile(type: 'formation' | 'cp575' | 'bankStatement' | 'otherDoc' | 'supporting', index: number | null) {
        switch (type) {
            case 'formation': {
                this.muhneeBusinessDocumentsForm.controls.formation.setValue(null);
                this.documentFormation!.nativeElement.value = null;
                break;
            }
            case 'cp575': {
                this.muhneeBusinessDocumentsForm.controls.cp575.setValue(null);
                this.documentCp575!.nativeElement.value = null;
                break;
            }
            case 'bankStatement': {
                this.muhneeNonBusinessDocumentsForm.controls.bankStatement.setValue(null);
                this.documentBankStatement!.nativeElement.value = null;
                break;
            }
            case 'otherDoc': {
                this.muhneeNonBusinessDocumentsForm.controls.otherDoc.setValue(null);
                this.documentOtherDoc!.nativeElement.value = null;
                break;
            }
            case 'supporting': {
                if (this.muhneeSupportingDocumentsArray && index !== null) {
                    this.documentSupporting!.nativeElement.value = null;
                    this.muhneeSupportingDocumentsArray.removeAt(index);
                }
                break;
            }
        }
    }

    async submitMuhneeData() {
        if (
            this.muhneeBusinessDetailsForm.invalid ||
            this.muhneeRepresentativesArray.invalid ||
            this.muhneeControllerDetailsForm.invalid
        ) {
            this.muhneeBusinessDetailsForm.markAllAsTouched();
            this.muhneeRepresentativesArray.markAllAsTouched();
            this.muhneeControllerDetailsForm.markAllAsTouched();
            return false;
        }

        this.lockButton = true;

        const gatewayData = {
            customer: null,
            customer_type: null,
            muhnee_api_key: null,
            muhnee_login_data: {},
            muhnee_register_data: {},
            muhnee_register_hosted_data: {
                business_data: this.muhneeBusinessDetailsForm.getRawValue(),
                controller_data: this.muhneeControllerDetailsForm.getRawValue(),
                representatives: this.muhneeRepresentativesArray.getRawValue()
            },
            muhnee_connection_type: 'register_hosted',
            title: this.gatewayType === 'zipi_financial_business' ? 'Muhnee Operating' : 'Muhnee Trust',
            driver_type: 'muhnee',
            gateway_type: this.gatewayType
        } as any;
        if (this.muhneeBusinessDocumentsForm.controls.formation.value) {
            gatewayData.muhnee_register_hosted_data.business_data['formation'] = {
                name: this.muhneeBusinessDocumentsForm.controls.formation.value.name,
                file: await this.readFile(this.muhneeBusinessDocumentsForm.controls.formation.value)
            };
        }
        if (this.muhneeBusinessDocumentsForm.controls.cp575.value) {
            gatewayData.muhnee_register_hosted_data.business_data['cp575'] = {
                name: this.muhneeBusinessDocumentsForm.controls.cp575.value.name,
                file: await this.readFile(this.muhneeBusinessDocumentsForm.controls.cp575.value)
            };
        }
        if (this.muhneeNonBusinessDocumentsForm.controls.bankStatement.value) {
            gatewayData.muhnee_register_hosted_data.business_data['bankStatement'] = {
                name: this.muhneeNonBusinessDocumentsForm.controls.bankStatement.value.name,
                file: await this.readFile(this.muhneeNonBusinessDocumentsForm.controls.bankStatement.value)
            };
        }
        if (this.muhneeNonBusinessDocumentsForm.controls.otherDoc.value) {
            gatewayData.muhnee_register_hosted_data.business_data['otherDoc'] = {
                name: this.muhneeNonBusinessDocumentsForm.controls.otherDoc.value.name,
                file: await this.readFile(this.muhneeNonBusinessDocumentsForm.controls.otherDoc.value)
            };
        }
        const supportingDocuments = this.muhneeSupportingDocumentsArray.getRawValue();
        if (supportingDocuments && supportingDocuments.length > 0) {
            gatewayData.muhnee_register_hosted_data.business_data['supporting'] = [];
            for (const doc of supportingDocuments) {
                gatewayData.muhnee_register_hosted_data.business_data.supporting.push({
                    name: doc.name,
                    file: await this.readFile(doc)
                });
            }
        }

        if (this.existingGateway && this.existingGateway.payment_gateway_id) {
            this.companyGatewayService
                .doRetryForZipiFinancial(this.existingGateway.payment_gateway_id, gatewayData)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response) {
                        this.setupStarted.emit(false);
                        this.lockButton = false;
                        this.currentStep = 3;
                    }
                });
        } else {
            this.createPaymentGateway(gatewayData);
        }
    }

    async createPaymentGateway(customerData: Partial<IPaymentGateway>) {
        const gateway = await firstValueFrom(this.companyGatewayService.createZipiFinancialGateway(customerData));
        this.lockButton = false;
        if (gateway) {
            if (gateway.hasOwnProperty('error')) {
                this.notificationServiceZipi.addError(gateway.error.message);
                this.existingGateway = gateway;
                this.muhneeBusinessDetailsForm.controls.business_name.disable();
                this.muhneeBusinessDetailsForm.controls.business_email.disable();
                this.muhneeControllerDetailsForm.controls.phone_number.disable();
                return;
            } else {
                this.setupStarted.emit(false);
            }

            if (gateway.deep_link_redirect) {
                this.verifyIdLink = gateway.deep_link_redirect;
            }
            this.currentStep = 3;
        }
    }

    async verifyId() {
        const dialogRef = this.dialog.open(MuhneePersonaRedirectDialogComponent, {
            disableClose: true,
            maxHeight: '80vh',
            width: '650px',
            // height: '800px',
            panelClass: 'hide-dialog-container',
            data: {
                gateway: this.existingGateway,
                link: this.verifyIdLink
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result && result.isError) {
                    this.notificationServiceZipi.addError(result.message);
                } else {
                    this.verified = true;
                }
            });
    }

    finish() {
        this.reloadAndShowFinalPopup.emit(null);
    }

    async readFile(file: any) {
        let result = await new Promise((resolve) => {
            this.readerDocument.onload = (e) => resolve(this.readerDocument.result);
            this.readerDocument.readAsBinaryString(file);
        });
        return result;
    }

    initValueChangeSubscription() {
        this.muhneeBusinessDetailsForm?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (this.lockButton) {
                this.lockButton = false;
            }
        });

        this.muhneeRepresentativesArray?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (this.lockButton) {
                this.lockButton = false;
            }
        });
        this.muhneeControllerDetailsForm?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (this.lockButton) {
                this.lockButton = false;
            }
        });
        this.muhneeSupportingDocumentsArray?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (this.lockButton) {
                this.lockButton = false;
            }
        });
        this.muhneeBusinessDocumentsForm?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (this.lockButton) {
                this.lockButton = false;
            }
        });
    }

    validateAndChangeStep(currentStep: 0 | 1 | 2 | 3, stepper: MatStepper) {
        switch (currentStep) {
            case 0: {
                if (this.muhneeBusinessDetailsForm.invalid) {
                    this.muhneeBusinessDetailsForm.markAllAsTouched();
                } else {
                    stepper.next();
                }
                break;
            }
            case 1: {
                if (
                    this.muhneeBusinessDetailsForm.controls.business_type.value &&
                    this.muhneeBusinessDetailsForm.controls.business_type.value !== 'soleProprietor' &&
                    this.muhneeBusinessDocumentsForm.invalid
                ) {
                    this.notificationsService.error('Documents required', 'Please provide required documentation.');
                } else if (
                    this.muhneeBusinessDetailsForm.controls.business_type.value &&
                    this.muhneeBusinessDetailsForm.controls.business_type.value === 'soleProprietor' &&
                    this.muhneeNonBusinessDocumentsForm.invalid
                ) {
                    this.notificationsService.error(
                        'Documents required',
                        'Please provide "Bank Statement" and "Proof of Business" documentation.'
                    );
                } else {
                    if (
                        this.muhneeBusinessDetailsForm.controls.business_type.value &&
                        this.muhneeBusinessDetailsForm.controls.business_type.value === 'soleProprietor'
                    ) {
                        this.muhneeControllerDetailsForm.controls.ssn.setValue(
                            this.muhneeBusinessDetailsForm.controls.ssn.value
                        );
                    } else {
                        this.muhneeControllerDetailsForm.controls.ssn.setValue('');
                    }
                    stepper.next();
                }
                break;
            }
            case 2: {
                if (
                    this.muhneeControllerDetailsForm.invalid ||
                    this.muhneeRepresentativesArray.invalid ||
                    (this.muhneeBusinessDetailsForm.controls.business_type.value !== 'soleProprietor' && !this.certify)
                ) {
                    this.muhneeControllerDetailsForm.markAllAsTouched();
                    this.muhneeRepresentativesArray.markAllAsTouched();
                    this.certifyValidated = true;
                } else {
                    this.submitMuhneeData();
                }
                break;
            }
            case 3:
        }
    }

    back(stepper: MatStepper) {
        stepper.previous();
    }

    _addPhoneMaskListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (typeof changes === 'string') {
                const charArr = changes.split('');
                const digitsArr: Array<string> = [];
                const newCharArr: Array<string> = [];
                charArr.forEach((char: string, index: number) => {
                    if (!isNaN(+char) && char !== ' ' && char !== null) {
                        digitsArr.push(char);
                    }
                });
                digitsArr.forEach((digit, index) => {
                    if (index <= 9) {
                        if ([3, 6].includes(index)) {
                            newCharArr.push('-');
                            newCharArr.push(digit);
                        } else {
                            newCharArr.push(digit);
                        }
                    }
                });

                const actualStr = newCharArr.join('');
                control.setValue(actualStr, {emitEvent: false});
            } else {
                control.setValue('', {emitEvent: false});
            }
        });

        return control;
    }

    _addZipMaskListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (changes) {
                const charArr = changes.split('');
                const digitsArr: Array<string> = [];
                charArr.forEach((char: string, index: number) => {
                    if (index <= 4) {
                        if (!isNaN(+char) && char !== ' ' && char !== null) {
                            digitsArr.push(char);
                        }
                    }
                });
                control.setValue(digitsArr.join(''), {emitEvent: false});
            }
        });
        return control;
    }

    _addSsnMaskListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            const charArr = changes.split('');
            const digitsArr: Array<string> = [];
            charArr.forEach((char: string, index: number) => {
                if (index <= 3) {
                    if (!isNaN(+char) && char !== ' ' && char !== null) {
                        digitsArr.push(char);
                    }
                }
            });
            control.setValue(digitsArr.join(''), {emitEvent: false});
        });
        return control;
    }

    _addFullSnnMaskListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            const charArr = changes.split('');
            const digitsArr: Array<string> = [];
            const newCharArr: Array<string> = [];
            charArr.forEach((char: string, index: number) => {
                if (!isNaN(+char) && char !== ' ' && char !== null) {
                    digitsArr.push(char);
                }
            });
            digitsArr.forEach((digit, index) => {
                if (index <= 8) {
                    if ([3, 5].includes(index)) {
                        newCharArr.push('-');
                        newCharArr.push(digit);
                    } else {
                        newCharArr.push(digit);
                    }
                }
            });

            const actualStr = newCharArr.join('');
            control.setValue(actualStr, {emitEvent: false});
        });

        return control;
    }

    _addEnnMaskListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (typeof changes === 'string') {
                const charArr = changes.split('');
                const digitsArr: Array<string> = [];
                const newCharArr: Array<string> = [];
                charArr.forEach((char: string, index: number) => {
                    if (!isNaN(+char) && char !== ' ' && char !== null) {
                        digitsArr.push(char);
                    }
                });
                digitsArr.forEach((digit, index) => {
                    if (index <= 8) {
                        if ([2].includes(index)) {
                            newCharArr.push('-');
                            newCharArr.push(digit);
                        } else {
                            newCharArr.push(digit);
                        }
                    }
                });

                const actualStr = newCharArr.join('');
                control.setValue(actualStr, {emitEvent: false});
            } else {
                control.setValue('', {emitEvent: false});
            }
        });

        return control;
    }

    _addIsHasWebsiteListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((is_has_website) => {
            if (this.muhneeBusinessDetailsForm) {
                this._updateWebAndDescriptinValidators(is_has_website);
            }
        });

        return control;
    }

    _addIsHasDbaListener(control: AbstractControl) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((is_has_dba) => {
            if (this.muhneeBusinessDetailsForm) {
                this._updateDbaValidators(is_has_dba);
            }
        });

        return control;
    }

    _updateWebAndDescriptinValidators(is_has_website: string | null) {
        if (this.muhneeBusinessDetailsForm) {
            if (is_has_website && is_has_website === 'yes') {
                this.muhneeBusinessDetailsForm.controls.web.setValidators([Validators.required]);
                this.muhneeBusinessDetailsForm.controls.description.clearValidators();
                this.muhneeBusinessDetailsForm.controls.description.setValue('');
            } else if (is_has_website && is_has_website === 'no') {
                this.muhneeBusinessDetailsForm.controls.description.setValidators([
                    Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(100)
                ]);
                this.muhneeBusinessDetailsForm.controls.web.clearValidators();
                this.muhneeBusinessDetailsForm.controls.web.setValue('');
            } else {
                this.muhneeBusinessDetailsForm.controls.web.clearValidators();
                this.muhneeBusinessDetailsForm.controls.description.clearValidators();
                this.muhneeBusinessDetailsForm.controls.web.setValue('');
                this.muhneeBusinessDetailsForm.controls.description.setValue('');
            }
            this.muhneeBusinessDetailsForm.controls.web.updateValueAndValidity({emitEvent: false});
            this.muhneeBusinessDetailsForm.controls.description.updateValueAndValidity({emitEvent: false});
        }
    }

    _updateDbaValidators(is_has_dba: string | null) {
        if (this.muhneeBusinessDetailsForm) {
            if (is_has_dba && is_has_dba === 'yes') {
                this.muhneeBusinessDetailsForm.controls.dba.setValidators([Validators.required]);
            } else {
                this.muhneeBusinessDetailsForm.controls.dba.clearValidators();
                this.muhneeBusinessDetailsForm.controls.dba.setValue('');
            }
            this.muhneeBusinessDetailsForm.controls.dba.updateValueAndValidity({emitEvent: false});
        }
    }

    _filterIndustries(value: string): {title: string; hash: string}[] {
        const filterValue = value.toLowerCase();

        return this.industries.filter((industry) => industry.title.toLowerCase().includes(filterValue));
    }

    ngOnDestroy() {
        this.setupStarted.emit(false);
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    protected readonly US_STATE_MAPPING_LIST = US_STATE_MAPPING_LIST;
    protected readonly ZIPI_FINANCIAL_BUSINESS_INDUSTRIES = ZIPI_FINANCIAL_BUSINESS_INDUSTRIES;
}
