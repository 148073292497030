<div>
    <h2 class="mr-2">Sign Up Form</h2>

    <div>
        To complete the signup process we need to collect some additional information, please proceed to the following
        page.
    </div>

    <div class="d-flex mt-3">
        <span class="sp-spacer"></span>
        <button type="button" mat-raised-button class="mr-2" (click)="close()">Cancel</button>
        <a [href]="link">
            <button type="button" mat-raised-button color="primary" (click)="close()">Proceed</button>
        </a>
    </div>
</div>
