import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IJournal, IJournalTemplate} from '@cyberco-nodejs/zipi-typings';
import {RbacService} from '../../../../rbac/rbac.service';
import {ActivatedRoute, Router} from '@angular/router';
import {map, takeUntil} from 'rxjs/operators';
import {JournalCreateBarComponent} from '../journal-create-bar/journal-create-bar.component';

@Component({
    selector: 'app-journals-page',
    templateUrl: 'journal-lists.component.html',
    styleUrls: ['journal-lists.component.scss']
})
export class JournalListsComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('journalCreate', {static: false}) journalCreate: JournalCreateBarComponent | undefined;
    @ViewChild('header', {static: false}) header: ElementRef | undefined;

    @Input() section: string = 'journals';

    filterHeader: number = 0;

    isSidebarOpened: boolean = false;

    updateList$: Subject<any> = new Subject<any>();

    templates: IJournalTemplate[] = [];

    state$: Observable<{[key: string]: string | number | boolean}> | undefined;

    constructor(
        protected rbacService: RbacService,
        public router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.rbacService.isAllowed({company_settings__manage_journals: true}).then((can) => {
            if (!can) {
                this.router.navigate(['/company', 'finance', 'chartofaccounts']);
            }
        });
    }

    ngAfterViewInit() {
        this.state$ = this.route.paramMap.pipe(
            map(() => window.history.state),
            takeUntil(this.unsubscribe)
        );
        this.state$.pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
            if (data.open && this.journalCreate) {
                this.journalCreate.open(null);
            }
        });
    }

    ngAfterViewChecked() {
        if (typeof this.header !== 'undefined' && this.header.nativeElement.offsetTop) {
            this.filterHeader = this.header.nativeElement.offsetTop;
        }
    }

    editManualJournal(mj: IJournal) {
        if (this.journalCreate) {
            this.journalCreate.open(mj);
        }
    }

    changeSection(section: string) {
        this.section = section;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
