<div class="upload-progress" [hidden]="(uploadedFile$ | async).length === 0">
    <mat-toolbar class="text-white bg-dark sp-progress-toolbar">
        Upload status
        <span class="sp-spacer"></span>
        <button mat-icon-button *ngIf="!isCollapsed" (click)="toggleCollapse()">
            <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <button mat-icon-button *ngIf="isCollapsed" (click)="toggleCollapse()">
            <mat-icon>arrow_drop_up</mat-icon>
        </button>
        <button mat-icon-button [disabled]="isUploading$ | async" (click)="closeWidget()">
            <mat-icon>clear</mat-icon>
        </button>
    </mat-toolbar>

    <mat-card #uploadBox class="p-1 pr-0" [hidden]="isCollapsed">
        <mat-list class="upload-list">
            <mat-list-item *ngFor="let ufile of uploadedFile$ | async">
                <div class="d-flex justify-content-between pr-1 w-100" [ngSwitch]="ufile.status">
                    <div>
                        <span mat-line *ngSwitchCase="'progress'">File Uploading</span>
                        <span mat-line *ngSwitchCase="'success'">File Uploaded</span>
                        <span mat-line *ngSwitchCase="'success'">Starting Saving Process</span>
                    </div>
                    <span class="sp-spacer"></span>
                    <div>
                        <mat-icon *ngSwitchCase="'success'" class="text-success">check_circle</mat-icon>
                        <!-- <mat-icon *ngSwitchCase="'success'" class="text-success">done</mat-icon> -->
                        <mat-icon *ngSwitchCase="'progress'" class="text-primary sp-spinner">autorenew</mat-icon>
                        <mat-icon *ngSwitchCase="'failure'" class="text-danger">cancel</mat-icon>
                    </div>
                </div>
            </mat-list-item>
        </mat-list>
    </mat-card>
</div>
