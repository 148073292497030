import {Component, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {IPaymentGateway, IProduct, IBill, IBillItem, IGroup, IFinancialTransfer} from '@cyberco-nodejs/zipi-typings';
import {pipe, Subject, of} from 'rxjs';
import {catchError, takeUntil, tap} from 'rxjs/operators';
import {IFinanceState} from '../../../store/finance.reducer';
import {selectProducts} from 'app/store/root.selectors';
import {FetchProducts} from '../../../store/finance.actions';
import {NotificationsService} from 'angular2-notifications';
import {PaymentMethodsService} from '../../../../profile/services/payment-methods.service';
import {PAYMENT_TERMS} from 'app/local-typings';
import {SessionService} from '../../../../../services/session.service';
import {MatDialog} from '@angular/material/dialog';
import {BillsService} from 'app/services/api/finance/bills.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {TagsService} from 'app/services/api/tags.service';
import {CreateProductDialogComponent} from '../../products/create-product-dialog/create-product-dialog.component';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {get as getProp} from 'lodash-es';
import {GenericFormArray} from 'app/entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {GroupsSource} from 'app/services/sources/groups.source';
import {FormGroupArray, FormGroupWithFormControls} from '../../../../../typings/common';
import {DealService} from '../../../../../services/deal.service';
import Decimal from 'decimal.js-light';
import {
    cleanCurrencyString,
    currencyMaskitoOptions,
    currencyWithNegativeMaskitoOptions,
    unmaskCurrencyControlValue
} from '../../../../../utilities/maskito';
import {customAmountValidator} from '../../../../../utilities';
import {IRelatedDealPayoutInfo} from '@app/modules/finance/types/financial.types';

@Component({
    selector: 'app-edit-bill',
    templateUrl: 'edit-bill.component.html',
    styleUrls: ['edit-bill.component.scss']
})
export class EditBillComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('auto') matAutocomplete: MatAutocomplete | undefined;
    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement> | undefined;

    bill: IBill | undefined;
    billId: number | null = null;

    currentCompanyId: number | null = null;

    products: IProduct[] = [];

    companyGateways: IPaymentGateway[] = [];

    associationItemArray: FormGroupArray = this.fb.array([]) as FormGroupArray;
    associationItemForDeleteArray: FormGroupArray = this.fb.array([]) as FormGroupArray;
    itemsArray: FormGroupArray = this.fb.array([this.createItem()]) as FormGroupArray;
    formGroup: FormGroupWithFormControls = this.fb.group({
        money_receiver__company_fk_id: [null, []],
        money_receiver__contact_fk_id: [null, [Validators.required]],
        money_receiver_email: [null, []],

        sales_person__profile_fk_id: [null, []],

        billing_address: [null, []],
        shipping_address: [null, []],

        to_be_paid__payment_method_fk_id: [null, []],
        pay_to__payment_method_fk_ids: [[], []],
        pay_to_card__payment_method_fk_id: [null, []],
        pay_to_bank__payment_method_fk_id: [null, []],

        payment_terms: ['due-on-receipt', Validators.required],
        payment_terms_custom: null,
        invoice_date: [moment().format('YYYYMMDD'), []],
        due_date: [null, []],
        bill_number: [null, []],

        invoice_note: [null, []],
        manual_pay_confirm_details: [null, []],

        items: this.itemsArray,
        items_preview: [null, []],
        total_amount: [{value: 0, disabled: true}],

        invoice_hash: [null, []],
        recurring_invoice_fk_id: [null, []],
        is_deduct_from_deal: [null, []],
        loan_fk_id: [null, []],
        division__company_group_fk_id: [null, []]
    }) as FormGroupWithFormControls;

    currencyMaskitoMask = currencyMaskitoOptions;
    currencyWithNegativeMaskitoMask = currencyWithNegativeMaskitoOptions;

    emptyItem = {
        rate: 0,
        quantity: 1,
        amount: 0,
        bill_item_id: null,
        product_fk_id: null,
        name: null,
        description: null,
        ledger_account_fk_id: null,
        connected__deal_fk_id: null,
        item_has_transfer: null
    };
    paymentTerms = PAYMENT_TERMS;

    prodSet: {[key: number]: {product: IProduct | undefined; max_amount: number}} = {};
    selectedTagsIds: Array<any> = [];
    currentTag: any;
    allTags: Array<any> = [];
    disableTags: boolean = false;

    updateDisabled: boolean = false;
    createDisabled: boolean = false;
    deleteDisabled: boolean = false;

    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    savedContacts: Array<number> = [];

    availableDivisionsGroups: IGroup[] = [];

    lastGroupId: number | undefined;

    groupList: IGroup[] = [];

    object = Object;

    editType: 'full' | 'restricted' | 'blocked' = 'full';

    constructor(
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService,
        private paymentMethodsService: PaymentMethodsService,
        private store: Store<IFinanceState>,
        public dialog: MatDialog,
        protected sessionService: SessionService,
        private tagsService: TagsService,
        private billsSrv: BillsService,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        private groupSrc: GroupsSource,
        private dealService: DealService
    ) {}

    ngOnInit() {
        if (
            this.sessionService.profile &&
            this.sessionService.profile.company &&
            this.sessionService.profile.company.id
        ) {
            this.currentCompanyId = this.sessionService.profile.company.id;
        }
        this.lastGroupId = Number(localStorage.getItem('current_company_group_id'));

        if (this.sessionService.profile && this.sessionService.profile.company_groups_member) {
            const currentGroupId = Number(localStorage.getItem('current_company_group_id'));

            const currentGroupMember = this.sessionService.profile.company_groups_member.find((gm) => {
                if (gm.company_group) {
                    return gm.company_group.id === currentGroupId;
                } else {
                    return false;
                }
            });
            if (currentGroupMember && currentGroupMember.company_group && currentGroupMember.company_group.tag) {
                this.currentTag = currentGroupMember.company_group.tag;
            }
        }
        this.allTags = this.sessionService.companyTags;

        if (this.currentTag && this.currentTag.tag_id) {
            this.selectedTagsIds.push(this.currentTag.tag_id);
        }

        this.store.dispatch(new FetchProducts());

        this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe)).subscribe((data) => {
            this.products = data;
            this.buildAssociationProductsArray();
        });

        this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            this.billId = params['id'];
            this.initForm();

            if (this.billId) {
                this.billsSrv
                    .getBillById(this.billId)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((bill) => {
                        if (bill) {
                            if (
                                bill &&
                                bill.status_of_invoice &&
                                ['draft', 'need_approve', 'approved'].includes(bill.status_of_invoice) &&
                                (bill.creator__company_fk_id === this.currentCompanyId ||
                                    bill.owner__company_fk_id === this.currentCompanyId)
                            ) {
                                this.bill = bill;
                                this.defineEditType();

                                this.initEdit(bill);

                                if (this.bill && this.bill.bill_id) {
                                    this.tagsService
                                        .getBillTagsIds(this.bill.bill_id)
                                        .pipe(takeUntil(this.unsubscribe))
                                        .subscribe((ids) => {
                                            this.selectedTagsIds = ids.result;
                                            this.disableTags = true;
                                        });
                                }
                            } else {
                                this.ntfs.warn(`Edit Bill with status ${bill.status_of_invoice} is not available.`);
                                this.router.navigate(['/purchases/bills/']);
                            }
                        } else {
                            this.ntfs.warn(`Bill #: ${this.billId} is not available.`);
                            this.router.navigate(['/purchases/bills/']);
                        }
                    });
                this.setupExistingAssociations();
            }
        });

        this.moneySenderCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            if (values && values.length > 0) {
                // this.selectMoneySender(values[0].target_id);
                this.formGroup.controls.money_receiver__contact_fk_id.patchValue(values[0].target_id);
            } else {
                // this.deselectCustomer();
                this.formGroup.controls.money_receiver__contact_fk_id.patchValue(null);
            }
        });

        this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
            this.groupList = list.filter((group) => group.type === 'division') as IGroup[];
        });
    }

    defineEditType() {
        if (this.bill && this.bill.summary_status && this.bill.status_of_payment) {
            if (['write_off', 'void', 'rejected'].includes(this.bill.summary_status)) {
                this.editType = 'blocked';
            } else if (
                ['partially_paid', 'paid'].includes(this.bill.summary_status) ||
                ['processing', 'pending', 'partially_paid', 'paid'].includes(this.bill.status_of_payment) ||
                !!this.bill.source__deal_fk_id
            ) {
                this.editType = 'restricted';
            } else if (['draft', 'open', 'sent', 'overdue'].includes(this.bill.summary_status)) {
                this.editType = 'full';
            }
        }
        if (!this.bill || !this.bill.bill_id) {
            this.editType = 'full';
        }
    }

    async setupExistingAssociations() {
        if (
            !this.billId ||
            !this.products ||
            this.products.length === 0 ||
            !this.prodSet ||
            Object.keys(this.prodSet).length === 0
        ) {
            await new Promise((resolve, reject) => {
                setTimeout(() => resolve(true), 1000);
            });
            this.setupExistingAssociations();
        } else {
            this.dealService.getFinancialTransfersByBillId(this.billId).then((transfers) => {
                if (transfers && transfers.length > 0) {
                    transfers.forEach((transfer) => this.addDealAssociation(transfer));
                }
            });
        }
    }

    initForm() {
        this.formGroup = this.fb.group({
            money_receiver__company_fk_id: [null, []],
            money_receiver__contact_fk_id: [null, [Validators.required]],
            money_receiver_email: [null, []],

            sales_person__profile_fk_id: [null, []],

            billing_address: [null, []],
            shipping_address: [null, []],

            to_be_paid__payment_method_fk_id: [null, []],
            pay_to__payment_method_fk_ids: [[], []],
            pay_to_card__payment_method_fk_id: [null, []],
            pay_to_bank__payment_method_fk_id: [null, []],

            payment_terms: ['due-on-receipt', Validators.required],
            payment_terms_custom: null,
            invoice_date: [moment().format('YYYYMMDD'), []],
            due_date: [null, []],
            bill_number: [null, []],

            invoice_note: [null, []],
            manual_pay_confirm_details: [null, []],

            items: this.itemsArray,
            items_preview: [null, []],
            total_amount: [{value: 0, disabled: true}],

            invoice_hash: [null, []],
            recurring_invoice_fk_id: [null, []],
            is_deduct_from_deal: [null, []],
            loan_fk_id: [null, []],
            division__company_group_fk_id: [null, []]
        }) as FormGroupWithFormControls;

        this.formGroup.controls.payment_terms.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            if (value === 'custom') {
                this.formGroup.controls.payment_terms_custom.setValidators([Validators.required]);
                this.formGroup.controls.payment_terms_custom.updateValueAndValidity();
            } else {
                this.formGroup.controls.payment_terms_custom.clearValidators();
                this.formGroup.controls.payment_terms_custom.updateValueAndValidity();
            }
        });

        this.itemsArray.valueChanges
            .pipe(
                tap(() => this.computeTotal()),
                takeUntil(this.unsubscribe)
            )
            .subscribe((change) => this.buildAssociationProductsArray());
    }

    initEdit(bill: IBill) {
        this.formGroup.patchValue(bill);

        const items = getProp(bill, 'items');
        if (!items || items.length === 0) {
            return;
        }
        this.itemsArray.removeAt(0);
        items.forEach((item: IBillItem) => this.addItem(item));

        if (bill.invoice_date) {
            this.formGroup.controls.invoice_date.setValue(bill.invoice_date);
        }

        if (bill.money_receiver__contact_fk_id) {
            this.savedContacts = [bill.money_receiver__contact_fk_id];
        }

        this.formGroup.controls.bill_number.setValidators([Validators.required]);
        this.formGroup.controls.bill_number.updateValueAndValidity();
        this.buildAssociationProductsArray();
        this.applyEditType();
    }

    buildAssociationProductsArray() {
        this.prodSet = {};
        // this.productForDeductArray.clear();
        const items = this.itemsArray.getRawValue();
        if (items && items.length > 0) {
            items.forEach((item) => {
                if (item.product_fk_id) {
                    if (this.prodSet.hasOwnProperty(item.product_fk_id)) {
                        const newProductMax = new Decimal(item.amount)
                            .add(this.prodSet[item.product_fk_id].max_amount)
                            .toDecimalPlaces(2)
                            .toNumber();
                        this.prodSet[item.product_fk_id].max_amount =
                            newProductMax > this.formGroup.controls.total_amount.value
                                ? this.formGroup.controls.total_amount.value
                                : newProductMax;
                    } else {
                        const newProductMax = item.amount;
                        this.prodSet[item.product_fk_id] = {
                            max_amount:
                                newProductMax > this.formGroup.controls.total_amount.value
                                    ? this.formGroup.controls.total_amount.value
                                    : newProductMax,
                            product: this.products.find((product) => product.product_id === item.product_fk_id)
                        };
                    }
                }
            });
        }
        if (this.associationItemArray.length > 0) {
            this.associationItemArray.controls.forEach((associationControl) => {
                const associationGroup: UntypedFormGroup = associationControl as unknown as UntypedFormGroup;
                if (
                    associationGroup.controls.product_id.value &&
                    this.prodSet.hasOwnProperty(associationGroup.controls.product_id.value)
                ) {
                    // if (associationGroup.controls.transfer_id.value) {
                    //     this.prodSet[associationGroup.controls.product_id.value].max_amount -= associationGroup.controls.amount.value;
                    // }
                } else {
                    associationGroup.controls.product_id.setValue(null);
                }
            });
        }
    }

    applyEditType() {
        if (this.formGroup) {
            if (this.editType === 'blocked') {
                this.formGroup.disable();
                this.associationItemArray.disable();
            }
            if (this.editType === 'restricted') {
                this.formGroup.disable();
                this.associationItemArray.disable();

                this.formGroup.controls.invoice_note.enable();

                const itemArray = this.formGroup.controls.items as any as FormGroupArray;
                itemArray.controls.forEach((item) => {
                    item.controls.description.enable();
                    item.controls.division__company_group_fk_id.enable();
                });
            }
        }
    }

    private createItem(item: IBillItem | null = null, itemIndex: number = 0) {
        const isDisabled = !!(item && item.connected__deal_fk_id && item.connected__financial_transfer_fk_id);

        const group = this.fb.group({
            bill_item_id: [item ? item.bill_item_id : null],
            product_fk_id: [
                {
                    value: item ? item.product_fk_id : null,
                    disabled: isDisabled
                }
            ],
            name: [
                {
                    value: item ? item.name : null,
                    disabled: item && item.product_fk_id
                },
                [Validators.required, Validators.minLength(1)]
            ],
            description: [item ? item.description : null],
            quantity: [item ? item.quantity : 1, [Validators.required, Validators.min(1)]],
            rate: [{value: item ? item.rate : 0, disabled: false}, customAmountValidator],
            rate__wildcard_fk_id: [{value: null, disabled: false}],
            quantity__wildcard_fk_id: [{value: null, disabled: false}],
            amount: [{value: item ? item.amount : 0, disabled: true}],
            ledger_account_fk_id: [
                {
                    value: item ? item.ledger_account_fk_id : null,
                    disabled: (item && item.product_fk_id) || isDisabled
                },
                Validators.required
            ],
            connected__deal_fk_id: [
                {
                    value: item ? item.connected__deal_fk_id : null,
                    disabled: isDisabled
                }
            ],
            item_has_transfer: [isDisabled],
            order_index: [item && item.order_index],
            division__company_group_fk_id: [
                {
                    value: item ? item.division__company_group_fk_id : this.lastGroupId,
                    disabled: !!this.lastGroupId
                }
            ],
            selected_divisions: new GenericFormArray<ChipNode>([]),
            selected_division_ids: [item ? item.division__company_group_fk_id : null],
            selected_full_ledger: null
        });

        group.controls.selected_divisions.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (changes && changes.length > 0) {
                group.controls.division__company_group_fk_id.setValue(changes[0].target_id);
            } else {
                group.controls.division__company_group_fk_id.setValue(null);
            }
        });

        const handleValueChanges = pipe(
            takeUntil(this.unsubscribe),
            tap(() => {
                const quantity = group.controls.quantity.value;
                const rate = group.controls.rate.value;
                if (!isNaN(quantity) && !isNaN(rate)) {
                    group.controls.amount.patchValue(quantity * rate);
                }
            })
        );

        const unmaskRateFn = unmaskCurrencyControlValue(group.controls.rate);

        group.controls.quantity.valueChanges
            .pipe(
                handleValueChanges,
                tap(() => this.computeTotal()),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
        group.controls.rate.valueChanges
            .pipe(
                unmaskRateFn,
                handleValueChanges,
                tap(() => this.computeTotal()),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
        group.controls.rate__wildcard_fk_id.valueChanges
            .pipe(
                tap(() => this.computeTotal()),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
        group.controls.quantity__wildcard_fk_id.valueChanges
            .pipe(
                tap(() => this.computeTotal()),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        return group;
    }

    addItem(item?: IBillItem) {
        this.itemsArray.push(this.createItem(item, this.itemsArray.length));
    }

    selectItem(item: AbstractControl, product: IProduct) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.controls.product_fk_id.patchValue(product.product_id);
        itemGroup.controls.rate.patchValue(product.price);
        itemGroup.controls.description.patchValue(product.description);
        itemGroup.controls.ledger_account_fk_id.patchValue(product.ledger_account_fk_id);
        itemGroup.controls.quantity.patchValue(1);
        itemGroup.controls.name.patchValue(product.name);
        itemGroup.controls.rate__wildcard_fk_id.patchValue(null);
        itemGroup.controls.quantity__wildcard_fk_id.patchValue(null);

        itemGroup.controls.ledger_account_fk_id.disable();
        itemGroup.controls.name.disable();
    }

    removeProductFkId(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.controls.ledger_account_fk_id.enable();
        itemGroup.controls.name.enable();
        itemGroup.controls.name.patchValue(null);
        itemGroup.controls.product_fk_id.patchValue(null);
    }

    resetItem(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        // if (itemGroup.controls.item_has_transfer').value
        //     || (itemGroup.controls.bill_item_id').value && itemGroup.controls.ledger_account_fk_id').value)) {
        //     return;
        // }
        itemGroup.reset(this.emptyItem);
        itemGroup.controls.name.enable();
    }

    addDealAssociation(finTransfer?: IRelatedDealPayoutInfo) {
        if (finTransfer && finTransfer.amount !== null) {
            const group = this.fb.group({
                transfer_id: [finTransfer.financial_transfer_id, []],
                product_id: [finTransfer.product_id, [Validators.required]],
                product_name: [finTransfer.product_name, [Validators.required]],
                amount: [finTransfer.amount ? finTransfer.amount : null, []],
                connected__deal_fk_id: [finTransfer.deal_id, [Validators.required]]
            });
            group.disable();
            this.associationItemArray.push(group);
        } else {
            this.associationItemArray.push(
                this.fb.group({
                    transfer_id: [null, []],
                    product_id: [null, [Validators.required]],
                    product_name: [null, [Validators.required]],
                    amount: [0, []],
                    connected__deal_fk_id: [null, [Validators.required]]
                })
            );
        }
    }

    removeDealAssociation(index: number) {
        const itemToDelete: UntypedFormGroup = this.associationItemArray.at(index) as UntypedFormGroup;

        if (itemToDelete.controls.transfer_id?.value) {
            this.associationItemForDeleteArray.push(itemToDelete);
        }
        this.associationItemArray.removeAt(index);
    }

    validateAmount($event: any, product_id: number) {
        if (product_id) {
            const inputValue = Number(cleanCurrencyString($event.target.value));

            const associations = this.associationItemArray.getRawValue();
            const usedAmount: Decimal = associations
                .reduce((acc, ass) => {
                    return Number(ass.product_id) === Number(product_id)
                        ? acc.add(Number(cleanCurrencyString(ass.amount)))
                        : acc;
                }, new Decimal(0))
                .sub(inputValue);
            const maxAmount: Decimal = new Decimal(this.prodSet[product_id].max_amount);
            const availableAmount: Decimal = maxAmount.sub(usedAmount);

            if (availableAmount.toDecimalPlaces(2).toNumber() < inputValue || inputValue === 0) {
                this.associationItemArray.controls.forEach((associationItem) => {
                    const associationGroup: UntypedFormGroup = associationItem as unknown as UntypedFormGroup;
                    if (
                        associationGroup.controls.product_id.value &&
                        associationGroup.controls.product_id.value === product_id
                    ) {
                        associationGroup.controls.amount.setValidators([Validators.email, Validators.min(1)]);
                        associationGroup.controls.amount.updateValueAndValidity();
                        associationGroup.controls.amount.markAsTouched();
                    }
                });
            } else {
                this.associationItemArray.controls.forEach((associationItem) => {
                    const associationGroup: UntypedFormGroup = associationItem as unknown as UntypedFormGroup;
                    if (
                        associationGroup.controls.product_id.value &&
                        associationGroup.controls.product_id.value === product_id
                    ) {
                        associationGroup.controls.amount.clearValidators();
                        associationGroup.controls.amount.setValidators([Validators.min(1)]);
                        associationGroup.controls.amount.updateValueAndValidity();
                    }
                });
            }
        }
    }

    setProductName($event: any, associationGroup: UntypedFormGroup) {
        const prodId = $event.value;
        const currentProduct = this.prodSet[prodId].product;
        if (currentProduct) {
            associationGroup.controls.product_name.setValue(currentProduct.name);
        }
    }

    trimOnBlur(control: AbstractControl | null) {
        if (control && control.value) {
            const trimmedValue = control.value.trim().length === 0 ? null : control.value.trim();
            control.patchValue(trimmedValue);
        }
    }

    private computeTotal = () => {
        const total: Decimal = this.itemsArray.controls.reduce<Decimal>((acc: Decimal, item: AbstractControl) => {
            const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
            return acc.add(itemGroup.getRawValue().amount);
        }, new Decimal(0));
        this.formGroup.controls.total_amount.patchValue(total.toDecimalPlaces(2).toNumber());
    };

    getProducts(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        if (itemGroup.getRawValue().name) {
            const filteredValue = itemGroup.getRawValue().name.toLowerCase().trim();
            return this.products.filter((pr) => pr.name.toLowerCase().trim().includes(filteredValue));
        }

        return this.products;
    }

    createProduct() {
        const dialogRef = this.dialog.open(CreateProductDialogComponent, {
            // width: '90vw',
            // minHeight: '30vh'
        });

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    // add(event: MatChipInputEvent): void {
    //
    //     // Add fruit only when MatAutocomplete is not open
    //     // To make sure this does not conflict with OptionSelected Event
    //     if (!this.matAutocomplete.isOpen) {
    //
    //         const input = event.input;
    //         const value = event.value;
    //
    //         // Add our fruit
    //         if ((value || '').trim()) {
    //             this.selectedTagsIds.push(value.trim());
    //         }
    //
    //         // Reset the input value
    //         if (input) {
    //             input.value = '';
    //         }
    //
    //         // this.fruitCtrl.setValue(null);
    //     }
    // }
    //
    // selectedTag(event: MatAutocompleteSelectedEvent) {
    //     this.selectedTagsIds.push(event.option.value);
    // }
    //
    // remove(tagId) {
    //     const index = this.selectedTagsIds.indexOf(tagId);
    //
    //     if (index >= 0) {
    //         this.selectedTagsIds.splice(index, 1);
    //     }
    // }
    //
    // clearInput($event) {
    //     this.tagInput.nativeElement.value = '';
    // }

    createBill(creationType: 'send' | 'draft' | 'open') {
        if (
            this.formGroup.invalid ||
            this.itemsArray.invalid ||
            this.associationItemArray.invalid ||
            this.formGroup.controls.total_amount.value <= 0
        ) {
            this.formGroup.markAllAsTouched();
            this.itemsArray.markAllAsTouched();
            this.associationItemArray.markAllAsTouched();
            this.ntfs.warn('Bill Form is not valid');
        } else {
            const newBill: IBill = this.formGroup.getRawValue();
            newBill.scenario = 'regular';
            newBill.items = this.itemsArray.getRawValue().map((item, idx) => {
                delete item.rate__wildcard_fk_id;
                delete item.quantity__wildcard_fk_id;
                return {...item, rate: Number(item.rate), order_index: idx + 1};
            });

            let billAssociationForCreation = null;
            let billAssociationForDeletion = null;
            if (this.associationItemArray.getRawValue()) {
                billAssociationForCreation = this.associationItemArray
                    .getRawValue()
                    .map((association) => {
                        association.amount = Number(cleanCurrencyString(association.amount as any));
                        return association;
                    })
                    .filter((association) => association.amount > 0);
            }
            if (this.associationItemForDeleteArray.getRawValue()) {
                billAssociationForDeletion = this.associationItemForDeleteArray.getRawValue();
            }
            const requestObject: {
                bill: IBill;
                bill_association: {
                    to_create: Array<{[key: string]: any}>;
                    to_delete: Array<{[key: string]: any}>;
                } | null;
            } = {
                bill: newBill,
                bill_association: {
                    to_create: billAssociationForCreation as any,
                    to_delete: billAssociationForDeletion as any
                }
            };

            if (!this.bill || !this.bill?.bill_id) {
                this.createDisabled = true;
                switch (creationType) {
                    case 'send': {
                        this.createSend(requestObject);
                        break;
                    }
                    case 'draft': {
                        this.createAsDraft(requestObject);
                        break;
                    }
                    case 'open': {
                        this.createAsOpen(requestObject);
                        break;
                    }
                }
            }
        }
    }

    createSend(requestObject: {
        bill: IBill;
        bill_association: {to_create: Array<{[key: string]: any}>; to_delete: Array<{[key: string]: any}>} | null;
    }) {
        this.billsSrv
            .createBillAndSend(requestObject)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this._createConnectionWithTag(result);
                }
                this.router.navigate(['/purchases/bills']);
            });
    }

    createAsDraft(requestObject: {
        bill: IBill;
        bill_association: {to_create: Array<{[key: string]: any}>; to_delete: Array<{[key: string]: any}>} | null;
    }) {
        this.billsSrv
            .createBillAsDraft(requestObject)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this._createConnectionWithTag(result);
                }
                this.router.navigate(['/purchases/bills']);
            });
    }

    createAsOpen(requestObject: {
        bill: IBill;
        bill_association: {to_create: Array<{[key: string]: any}>; to_delete: Array<{[key: string]: any}>} | null;
    }) {
        this.billsSrv
            .createBillAsOpen(requestObject)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this._createConnectionWithTag(result);
                }
                this.router.navigate(['/purchases/bills']);
            });
    }

    isPaymentsCanceled(bill: IBill) {
        if (bill.payments) {
            return bill.payments.some((pay) => pay.status !== 'canceled');
        } else {
            return false;
        }
    }

    updateBill() {
        if (this.formGroup.invalid || this.itemsArray.invalid || this.formGroup.controls.total_amount.value <= 0) {
            this.ntfs.warn('Bill Form is not valid');
        } else {
            const billForUpdate = this.formGroup.getRawValue();

            if (
                this.bill &&
                this.bill.total_amount !== billForUpdate.total_amount &&
                this.bill.status_of_payment !== 'unpaid'
            ) {
                this.ntfs.warn(`Amount of Bill with payments cannot be changed.`);
                return;
            }

            billForUpdate.items = this.itemsArray.getRawValue().map((item, idx) => {
                delete item.rate__wildcard_fk_id;
                delete item.quantity__wildcard_fk_id;
                return {...item, rate: Number(item.rate), order_index: idx + 1};
            });

            let billAssociationForCreation = null;
            let billAssociationForDeletion = null;
            if (this.associationItemArray.getRawValue()) {
                billAssociationForCreation = this.associationItemArray
                    .getRawValue()
                    .map((association) => {
                        association.amount = Number(cleanCurrencyString(association.amount as any));
                        return association;
                    })
                    .filter((association) => association.amount > 0);
            }
            if (this.associationItemForDeleteArray.getRawValue()) {
                billAssociationForDeletion = this.associationItemForDeleteArray.getRawValue();
            }
            const requestObject: {
                bill: IBill;
                bill_association: {
                    to_create: Array<{[key: string]: any}>;
                    to_delete: Array<{[key: string]: any}>;
                } | null;
                edit_type: 'full' | 'restricted' | 'blocked';
            } = {
                bill: billForUpdate,
                bill_association: {
                    to_create: billAssociationForCreation as any,
                    to_delete: billAssociationForDeletion as any
                },
                edit_type: this.editType
            };

            if (this.bill && this.bill.bill_id && !this.updateDisabled) {
                this.updateDisabled = true;

                this.billsSrv
                    .editBill(this.bill.bill_id, requestObject)
                    .pipe(
                        catchError(() => {
                            this.updateDisabled = false;
                            return of(null);
                        }),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe((result) => {
                        if (result) {
                            this.ntfs.success('Bill successfully updated');
                        }

                        this.router.navigate(['/purchases/bills/']);
                    });
            }
        }
    }

    disconnectBill() {
        if (this.bill && this.bill.bill_id && !this.deleteDisabled) {
            this.deleteDisabled = true;

            this.billsSrv
                .disconnectBill(this.bill.bill_id)
                .pipe(
                    catchError(() => {
                        this.deleteDisabled = false;
                        return of(null);
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((result) => {
                    if (result) {
                        this.ntfs.success('Bill successfully disconnected');
                    }
                    this.router.navigate(['/purchases/bills/']);
                });
        }
    }

    deleteBill() {
        if (this.bill && this.bill.bill_id && !this.deleteDisabled) {
            this.deleteDisabled = true;

            this.billsSrv
                .deleteBill(this.bill.bill_id)
                .pipe(
                    catchError(() => {
                        this.deleteDisabled = false;
                        return of(null);
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((result) => {
                    if (result) {
                        this.ntfs.success('Bill successfully deleted');
                    }
                    this.router.navigate(['/purchases/bills/']);
                });
        }
    }

    async _createConnectionWithTag(bill: IBill) {
        if (!bill || !bill.bill_id) {
            return null;
        }
        await this.tagsService.createTagBillRelation(this.selectedTagsIds, bill.bill_id).toPromise();
        this.ntfs.info(`Bill created`);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
