<section class="bg-white">
    <header class="d-flex align-items-center px-3 list-header">
        <h3 class="m-0">Garnishments</h3>

        <i class="sp-spacer"></i>

        <button type="button" mat-raised-button routerLink="/lending/garnishments/create">
            <mat-icon>add</mat-icon>
            Create
        </button>
    </header>

    <!------- List of bills ------->
    <div class="table-background">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">Date</th>
                <td mat-cell *matCellDef="let element" class="w-15">
                    {{ element.date | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="receiver_contact_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">Contact/User</th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1">
                    {{ element.receiver_contact_name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="sender_contact_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Originator</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.sender_contact_name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="product_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">Product/Service</th>
                <td mat-cell *matCellDef="let element" class="w-15 px-1">
                    {{ element.product_name || '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Amount</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1 text-right">
                    {{ element.amount | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="interest_rate">
                <th mat-header-cell *matHeaderCellDef class="w-10">Interest Rate</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1 text-right">
                    <span *ngIf="element.interest_rate_type === 'flat'">
                        {{ element.interest_rate_flat | currency }}
                    </span>
                    <span *ngIf="element.interest_rate_type === 'percent'"> {{ element.interest_rate_percent }}% </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="interest_rate_limit">
                <th mat-header-cell *matHeaderCellDef class="w-10">Interest Rate Limit</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <span *ngIf="element.interest_rate_limit_type === 'none'">None</span>
                    <span *ngIf="element.interest_rate_limit_type === 'date'">
                        {{ element.interest_rate_limit_date | date: 'mediumDate' }}
                    </span>
                    <span *ngIf="element.interest_rate_limit_type === 'number_of_periods'">
                        {{ element.interest_rate_limit_number }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="interest_period">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">Interest Rate Period</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.interestPeriod.label }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="w-5">Action</th>
                <td mat-cell *matCellDef="let element" class="w-5">
                    <button
                        mat-icon-button
                        [rule]="{lending__manage_garnishments: true}"
                        rbac
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        (click)="$event.stopPropagation(); $event.preventDefault()"
                        [matMenuTriggerFor]="menu"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="$event.stopPropagation(); editGarnishment(element)">Edit</button>
                        <button
                            mat-menu-item
                            [disabled]="element.balance < element.amount"
                            (click)="$event.stopPropagation(); deleteGarnishment(element)"
                        >
                            Delete
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [routerLink]="row.loan_id"
                class="zp-table-row"
            ></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 text-center no-items">
            There are no garnishments.
        </div>
    </div>
</section>
