import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {firstValueFrom, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {IInvoice, IContact, IPaymentMethod, IFinancialTransfer} from '@cyberco-nodejs/zipi-typings';
import {FINANCIAL_TRANSFER_TYPE_LABELS, PAYMENT_TERMS} from 'app/local-typings';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {BillsService} from 'app/services/api/finance/bills.service';
import {MatDialog} from '@angular/material/dialog';
import {ProductMatchingDialogComponent} from '../../common/product-matching-dialog/product-matching-dialog.component';
import {AssociateContactDialogComponent} from '../associate-contact-dialog/associate-contact-dialog.component';
import {NotificationsServiceZipi} from '../../../../notifications/notifications.service';
import {ShipperContactsService} from '../../../../../services/api/shipper.contacts.service';
import {PayInvoiceDialogComponent} from '../../invoice/pay-invoice-dialog/pay-invoice-dialog.component';
import {InvoicePublicService} from '@app/modules/finance/services/invoice-public.service';
import {PaymentTypeSelectionDialogComponent} from '@app/modules/finance/components/invoice-public/dialogs/payment-type-selection-dialog/payment-type-selection-dialog.component';
import {PayViaAuthorizeDialogComponent} from '@app/modules/finance/components/invoice-public/dialogs/pay-via-authorize-dialog/pay-via-authorize-dialog.component';
import {ZipiFinancialIframeDialogComponent} from '@app/modules/account-info/company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-financial-iframe-dialog/zipi-financial-iframe-dialog.component';
import {IZipiFinancialIframeDialogData} from '@app/typings/zipi-financial-iframe';
import {DealService} from '@app/services/deal.service';
import {MatTableDataSource} from '@angular/material/table';
import {IRelatedDealPayoutInfo} from '@app/modules/finance/types/financial.types';

const win: any = window;
const Payload: any = win.Payload;

@Component({
    selector: 'app-invoice-for-approve-page',
    templateUrl: 'invoice-for-approve-page.component.html',
    styleUrls: ['invoice-for-approve-page.component.css']
})
export class InvoiceForApprovePageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    termsMap = new Map<string, string>(<any>PAYMENT_TERMS);

    invoice: IInvoice | undefined;
    contacts: IContact[] = [];
    displayedColumns: string[] = ['account_name', 'debit', 'credit'];

    showJournals: boolean;

    invoicePayloadBankMethod: IPaymentMethod | null = null;
    invoicePayloadCardMethod: IPaymentMethod | null = null;
    invoiceAuthorizeNetMethod: IPaymentMethod | null = null;
    currentContact: IContact | undefined;
    zipiFinCustomerRef: string | null = null;

    showTransfers: boolean = true;
    transfersDataSource: MatTableDataSource<IRelatedDealPayoutInfo>;
    transferDisplayedColumns: string[] = ['index', 'deal_address', 'product', 'connection_type', 'amount'];
    FINANCIAL_TRANSFER_TYPE_LABELS = FINANCIAL_TRANSFER_TYPE_LABELS;

    constructor(
        private activatedRoute: ActivatedRoute,
        private invoiceSrv: InvoicesService,
        private invoicePublicService: InvoicePublicService,
        private dealService: DealService,
        private billsService: BillsService,
        private router: Router,
        public dialog: MatDialog,
        private notificationServiceZipi: NotificationsServiceZipi,
        private contactService: ShipperContactsService
    ) {
        this.showJournals = false;
        this.transfersDataSource = new MatTableDataSource<IRelatedDealPayoutInfo>([]);
    }

    ngOnInit() {
        this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['id']) {
                this.invoiceSrv
                    .getInvoiceForApproveById(params['id'])
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((response) => {
                        this.zipiFinCustomerRef = response.zipi_fin_customer_ref;
                        this.invoice = response.invoice;
                        this.loadContact();
                        this.loadMethods(this.invoice);
                        this.getInvoiceFinancialTransfers();
                    });
            }
        });
    }

    getInvoiceFinancialTransfers() {
        if (this.invoice && this.invoice.invoice_id) {
            this.dealService.getFinancialTransfersByInvoiceId(this.invoice.invoice_id).then((transfers) => {
                this.transfersDataSource.data = transfers;
            });
        }
    }

    loadContact() {
        if (this.invoice && this.invoice.money_sender__contact_fk_id) {
            this.invoicePublicService
                .getPublicContact(this.invoice.money_sender__contact_fk_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((contact) => {
                    this.currentContact = contact;
                });
        }
    }

    loadMethods(invoice: IInvoice) {
        if (invoice.pay_to_bank__payment_method_fk_id) {
            this.invoicePublicService
                .getInvoiceMethod(invoice.pay_to_bank__payment_method_fk_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((bankMethod) => {
                    if (
                        bankMethod &&
                        bankMethod.payment_gateway &&
                        bankMethod.payment_gateway.driver_type === 'payload'
                    ) {
                        this.invoicePayloadBankMethod = bankMethod;
                    }
                });
        }
        if (invoice.pay_to_card__payment_method_fk_id) {
            this.invoicePublicService
                .getInvoiceMethod(invoice.pay_to_card__payment_method_fk_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((cardMethod) => {
                    if (
                        cardMethod &&
                        cardMethod.payment_gateway &&
                        cardMethod.payment_gateway.driver_type === 'payload'
                    ) {
                        this.invoicePayloadCardMethod = cardMethod;
                    } else if (
                        cardMethod &&
                        cardMethod.payment_gateway &&
                        cardMethod.payment_gateway.type === 'authorize_net_merchant'
                    ) {
                        this.invoiceAuthorizeNetMethod = cardMethod;
                    }
                });
        }
    }

    isProcessingPayment() {
        if (this.invoice && this.invoice.payments && this.invoice.payments.length > 0) {
            for (const pay of this.invoice.payments) {
                if (pay.status === 'processing') {
                    return true;
                }
            }
        }
        return false;
    }

    async createAndConnectBillFromInvoice() {
        const invoicesForBulkArray = [];
        if (this.invoice && this.invoice.payments && this.invoice.payments.length > 0) {
            const invoicesForBulk: {[key: number]: IInvoice} = {};
            for (const pay of this.invoice.payments) {
                if (pay.source_payment_received && pay.source_payment_received.related_payments) {
                    for (const relatedPay of pay.source_payment_received.related_payments) {
                        if (relatedPay.invoice && relatedPay.invoice.invoice_id) {
                            invoicesForBulk[relatedPay.invoice.invoice_id] = relatedPay.invoice;
                        }
                    }
                }
            }
            for (const key in invoicesForBulk) {
                if (invoicesForBulk.hasOwnProperty(key)) {
                    invoicesForBulkArray.push(invoicesForBulk[key]);
                }
            }
        }

        let partnerContact = null;
        if (this.invoice && this.invoice.owner__company_fk_id) {
            partnerContact = await firstValueFrom(
                this.contactService.getContactInMyCompanyByPartnerCompanyId(this.invoice.owner__company_fk_id)
            );
        }

        if (!partnerContact) {
            const contactDialogRef = this.dialog.open(AssociateContactDialogComponent, {
                minWidth: 320,
                data: {}
            });

            const contactId = await contactDialogRef.afterClosed().toPromise();
            if (contactId === false || contactId === undefined) {
                return;
            }
            if (this.invoice && this.invoice.invoice_id) {
                if (contactId) {
                    partnerContact = await this.billsService
                        .associateVendorContactByInvoice(this.invoice.invoice_id, contactId)
                        .toPromise();
                } else {
                    partnerContact = await this.billsService
                        .createVendorContactByInvoice(this.invoice.invoice_id)
                        .toPromise();
                }
            }
        }

        const dialogRef = this.dialog.open(ProductMatchingDialogComponent, {
            maxHeight: '80vh',
            autoFocus: false,
            data: {
                source_document: this.invoice,
                source_documents: invoicesForBulkArray,
                contact: partnerContact
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((matchResult) => {
                this.invoiceSrv
                    .approveInvoices({
                        ...matchResult,
                        source_invoice_id: this.invoice!.invoice_id
                    })
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((result) => {
                        if (result) {
                            this.router.navigate(['/purchases/bills']);
                        }
                    });
            });
    }

    async createAndLinkBillFromInvoice() {
        const invoicesForBulkArray = [];
        if (this.invoice && this.invoice.payments && this.invoice.payments.length > 0) {
            const invoicesForBulk: {[key: number]: IInvoice} = {};
            for (const pay of this.invoice.payments) {
                if (pay.source_payment_received && pay.source_payment_received.related_payments) {
                    for (const relatedPay of pay.source_payment_received.related_payments) {
                        if (relatedPay.invoice && relatedPay.invoice.invoice_id) {
                            invoicesForBulk[relatedPay.invoice.invoice_id] = relatedPay.invoice;
                        }
                    }
                }
            }
            for (const key in invoicesForBulk) {
                if (invoicesForBulk.hasOwnProperty(key)) {
                    invoicesForBulkArray.push(invoicesForBulk[key]);
                }
            }
        }
        let partnerContact = this.contacts.find(
            (con) => this.invoice && con.partner__company_fk_id === this.invoice.owner__company_fk_id
        );

        if (!partnerContact) {
            const contactDialogRef = this.dialog.open(AssociateContactDialogComponent, {
                minWidth: 320,
                data: {}
            });

            const contactId = await contactDialogRef.afterClosed().toPromise();
            if (contactId === false || contactId === undefined) {
                return;
            }
            if (this.invoice && this.invoice.invoice_id) {
                if (contactId) {
                    partnerContact = await this.billsService
                        .associateVendorContactByInvoice(this.invoice.invoice_id, contactId)
                        .toPromise();
                } else {
                    partnerContact = await this.billsService
                        .createVendorContactByInvoice(this.invoice.invoice_id)
                        .toPromise();
                }
            }
        }

        const dialogRef = this.dialog.open(ProductMatchingDialogComponent, {
            maxHeight: '80vh',
            autoFocus: false,
            data: {
                source_document: this.invoice,
                source_documents: invoicesForBulkArray,
                contact: partnerContact
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((matchResult) => {
                this.invoiceSrv
                    .linkInvoices({
                        ...matchResult,
                        source_invoice_id: this.invoice!.invoice_id
                    })
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((result) => {
                        if (result) {
                            this.router.navigate(['/purchases/bills']);
                        }
                    });
            });
    }

    archiveInvoice() {
        if (this.invoice && this.invoice.invoice_id) {
            this.invoiceSrv
                .archiveInvoice(this.invoice.invoice_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (result) {
                        this.router.navigate(['/purchases/sourcedocuments']);
                    }
                });
        }
    }

    redirect(journalId: number) {
        this.router.navigate([`company/journals/${journalId}`]);
    }

    payInvoice() {
        if (this.invoicePayloadBankMethod && (this.invoicePayloadCardMethod || this.invoiceAuthorizeNetMethod)) {
            this.openSelectionPopup();
        } else if (
            !this.invoiceAuthorizeNetMethod &&
            (this.invoicePayloadBankMethod || this.invoicePayloadCardMethod)
        ) {
            this.prepareToOpenPayloadForm(this.invoicePayloadBankMethod ? 'bank' : 'card');
        } else if (
            !this.invoicePayloadBankMethod &&
            !this.invoicePayloadCardMethod &&
            !!this.invoiceAuthorizeNetMethod
        ) {
            this.payViaAuthorize();
        } else {
            this.notificationServiceZipi.addInfo('There is no way to pay this Invoice.');
        }
    }

    openSelectionPopup() {
        const dialogRef = this.dialog.open<PaymentTypeSelectionDialogComponent>(PaymentTypeSelectionDialogComponent, {
            minWidth: '600px',
            maxHeight: '80vh',
            disableClose: true,
            data: {}
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result: 'bank' | 'card' | null) => {
                if (result) {
                    if (result === 'bank') {
                        this.prepareToOpenPayloadForm('bank');
                    } else if (result === 'card') {
                        if (this.invoiceAuthorizeNetMethod) {
                            this.payViaAuthorize();
                        } else {
                            this.prepareToOpenPayloadForm('card');
                        }
                    }
                }
            });
    }

    prepareToOpenPayloadForm(payloadPayType: 'bank' | 'card') {
        if (this.currentContact) {
            if (
                !this.currentContact.contact_payment_gateways ||
                this.currentContact.contact_payment_gateways.length === 0 ||
                !this.currentContact.contact_payment_gateways.some((cpg) => cpg.driver_type === 'payload')
            ) {
                this.setupZipiFinancialForContact('contact_gateway_only', 'payload', payloadPayType);
            } else {
                this.payViaPayload(payloadPayType);
            }
        }
    }

    async payViaPayload(type: 'bank' | 'card') {
        if (this.currentContact) {
            const currentMethod: IPaymentMethod | null =
                type === 'bank' ? this.invoicePayloadBankMethod : this.invoicePayloadCardMethod;
            if (
                this.invoice &&
                this.invoice.invoice_id &&
                currentMethod &&
                currentMethod &&
                currentMethod.payment_method_id
            ) {
                const settings = await firstValueFrom(
                    this.invoicePublicService.getPayloadSettings(
                        currentMethod.payment_method_id,
                        this.invoice.invoice_id
                    )
                );
                if (!settings.token) {
                    console.error('Cannot get settings to initialize payment popup.');
                    return;
                }
                Payload(settings.token);

                const checkout = new Payload.Checkout({
                    card_payments: type === 'card',
                    bank_account_payments: type === 'bank'
                })
                    .on('loaded', (evt: any) => {})
                    .on('authorized', (evt: any) => {})
                    .on('processed', (evt: {transaction_id: string; type: 'processed'}) => {})
                    .on('success', (evt: {transaction_id: string; type: 'success'}) => {
                        this.createPayloadCoPayment(evt.transaction_id, type);
                    })
                    .on('declined', (evt: any) => {})
                    .on('closed', (evt: any) => {});
            }
        }
    }

    createPayloadCoPayment(payloadTransactionId: string, type: 'bank' | 'card') {
        const payloadCoPaymentObj = {
            transaction_id: payloadTransactionId,
            invoice_id: this.invoice!.invoice_id,
            pay_to_payment_method_id: null,
            type: type
        };

        if (type === 'bank' && this.invoicePayloadBankMethod && this.invoicePayloadBankMethod.payment_method_id) {
            payloadCoPaymentObj.pay_to_payment_method_id = this.invoicePayloadBankMethod.payment_method_id as any;
        } else if (
            type === 'card' &&
            this.invoicePayloadCardMethod &&
            this.invoicePayloadCardMethod.payment_method_id
        ) {
            payloadCoPaymentObj.pay_to_payment_method_id = this.invoicePayloadCardMethod.payment_method_id as any;
        }

        this.invoicePublicService
            .publicPayloadCoPay(payloadCoPaymentObj)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                this.ngOnInit();
            });
    }

    payViaAuthorize() {
        if (this.invoice && this.currentContact && this.invoiceAuthorizeNetMethod) {
            const dialogRef = this.dialog.open<
                PayViaAuthorizeDialogComponent,
                {title: string; contact: IContact; method: IPaymentMethod; invoice: IInvoice}
            >(PayViaAuthorizeDialogComponent, {
                minWidth: '600px',
                maxHeight: '80vh',
                data: {
                    title: 'Pay Invoice',
                    invoice: this.invoice,
                    contact: this.currentContact,
                    method: this.invoiceAuthorizeNetMethod
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((result) => {
                    this.ngOnInit();
                });
        }
    }

    async setupZipiFinancialForContact(
        methodType: 'contact_gateway_only',
        driverType: 'payload',
        payloadPayType: 'bank' | 'card'
    ) {
        let mainPerson = null;
        if (
            this.currentContact &&
            this.currentContact.contact_persons &&
            this.currentContact.contact_persons.length > 0
        ) {
            mainPerson = this.currentContact.contact_persons.find((person) => person.type === 'main_person');
        }
        const contactData = {
            company_name: this.currentContact?.company_name ? this.currentContact?.company_name : '',
            email: mainPerson && mainPerson.email ? mainPerson.email : '',
            first_name: mainPerson && mainPerson.first_name ? mainPerson.first_name : '',
            last_name: mainPerson && mainPerson.last_name ? mainPerson.last_name : '',
            nick_name: mainPerson && mainPerson.preferred_name ? mainPerson.preferred_name : ''
        };
        const dialogRef = this.dialog.open<ZipiFinancialIframeDialogComponent, IZipiFinancialIframeDialogData>(
            ZipiFinancialIframeDialogComponent,
            {
                disableClose: true,
                maxHeight: '80vh',
                width: '650px',
                panelClass: 'custom-dialog-container',
                data: {
                    contactId: this.currentContact?.contact_id ? this.currentContact?.contact_id : null,
                    gateway: null,
                    ownGateway: null,
                    driverType: driverType,
                    paymentMethod: null,
                    methodType: methodType,
                    accessMethod: 'internal',
                    isUniversal: true,
                    customerId: this.zipiFinCustomerRef,
                    prefillData: contactData,
                    creationSource: 'current_company',
                    storeAccountMethod: 'required'
                }
            }
        );

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result.isError) {
                    this.notificationServiceZipi.addError(result.message);
                }
                if (result.isClosed) {
                    return;
                }
                this.loadContact();
                this.payViaPayload(payloadPayType);
            });
    }

    chargeCustomer() {
        if (this.invoice) {
            const dialogRef = this.dialog.open<
                PayInvoiceDialogComponent,
                {invoice: IInvoice; access_method: 'public' | 'internal'; title: string}
            >(PayInvoiceDialogComponent, {
                minWidth: '600px',
                maxHeight: '80vh',
                data: {
                    invoice: this.invoice,
                    access_method: 'internal',
                    title: 'Pay Invoice'
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((result) => {
                    if (!result.error) {
                        this.notificationServiceZipi.addInfo(`Invoice successfully charged.`);
                        this.router.navigate(['/purchases/sourcedocuments']);
                    } else {
                        this.notificationServiceZipi.addError(result.error);
                    }
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
