import {Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {getNextActivePanel, isLastPanelItem} from '../../helper';
import {RecurringInvoicesService} from '../../../../../../services/api/finance/recurring-invoices.service';
import {takeUntil} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs';
import * as moment from 'moment';
import {UntypedFormControl} from '@angular/forms';
import {IReport, IReportSetting, IReportSettingKeys} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-report-edit-bar-recurring-invoice',
    templateUrl: './recurring-invoice.component.html',
    styleUrls: ['./recurring-invoice.component.scss']
})
export class RecurringInvoiceBarComponent implements OnInit, OnDestroy {
    @Output() valueChanges: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Output() action: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Input() report: IReport | null = null;

    private defaultValues: {[key: string]: any} = {};
    private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);

    public activePanel: string = 'user_groups';
    public moment: any = moment;
    public dateControlObj: any = {};
    public reportSettings: IReportSetting | null = null;
    public isShowNext: boolean = true;
    public listOfRecurringInvoices: Array<any> = [];
    public listOfPickedRecurringInvoices: Array<any> = [];
    public indexCounter: number = 0;
    public reportOptions = [
        {
            value: 'user_groups',
            title: 'Group of Users'
        },
        {
            value: 'recurring_invoices',
            title: 'Recurring Invoices'
        }
    ];

    constructor(private recurringInvoicesService: RecurringInvoicesService) {}

    ngOnInit() {
        this.initData();
        this.initSubscription();
    }

    initSubscription() {
        this.recurringInvoicesService
            .getRecurringInvoices()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.listOfRecurringInvoices = data.result.sort((a, b) => {
                    if (a.title > b.title) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
            });
    }

    initData() {
        if (this.report) {
            this.reportSettings = {...this.defaultValues, ...this.report.settings};
            this.listOfPickedRecurringInvoices = this.reportSettings.recurring_invoices_prefered_date || [];
            this.indexCounter = this.listOfPickedRecurringInvoices.length;

            if (this.listOfPickedRecurringInvoices.length) {
                this.listOfPickedRecurringInvoices.forEach((item) => {
                    this.dateControlObj[item.id] = new UntypedFormControl(item.date);
                });
            }
        }
    }

    addRecurringInvoice(recurringInvoice: any) {
        this.listOfPickedRecurringInvoices = this.listOfPickedRecurringInvoices.concat({
            recurringInvoice: recurringInvoice,
            id: this.indexCounter
        });
        this.indexCounter++;
    }

    deleteRecurringInvoice(recurringInvoice: any) {
        if (
            this.reportSettings &&
            this.reportSettings.recurring_invoices_prefered_date &&
            this.reportSettings.recurring_invoices_prefered_date.length
        ) {
            const recurringInvoicesWithDate = this.reportSettings.recurring_invoices_prefered_date.filter(
                (obj) => obj.id !== recurringInvoice.id
            );

            this.reportSettings.recurring_invoices_prefered_date = recurringInvoicesWithDate;
        } else {
            throw new Error('Already empty list of recurring invoices');
        }
        this.listOfPickedRecurringInvoices = this.listOfPickedRecurringInvoices.filter(
            (obj) => obj.id !== recurringInvoice.id
        );
    }

    datePickerChange(recurringInvoice: any, event: number) {
        const dateInt = Number(event);
        if (
            this.reportSettings &&
            this.reportSettings.recurring_invoices_prefered_date &&
            this.reportSettings.recurring_invoices_prefered_date.length
        ) {
            const recurringInvoicesWithDate = this.reportSettings.recurring_invoices_prefered_date.filter(
                (obj) => obj.id !== recurringInvoice.id
            );
            recurringInvoicesWithDate.push({
                id: recurringInvoice.id,
                recurringInvoice: recurringInvoice.recurringInvoice,
                date: dateInt
            });
            this.reportSettings.recurring_invoices_prefered_date = recurringInvoicesWithDate;
        } else {
            const recurringInvoicesWithDate = {
                id: recurringInvoice.id,
                recurringInvoice: recurringInvoice.recurringInvoice,
                date: dateInt
            };
            if (this.reportSettings) {
                this.reportSettings.recurring_invoices_prefered_date = [recurringInvoicesWithDate];
            }
        }
    }

    formatDateFromIntToDate(el: number) {
        if (el) {
            return new Date(el / 10000, ((el / 100) % 100) - 1, el % 100);
        } else {
            return null;
        }
    }

    onSelectOptions(event: {value: string}) {
        this.activePanel = event.value;
        this.isShowNext = !isLastPanelItem(this.activePanel, this.reportOptions);
    }

    onValueChanges(property: IReportSettingKeys, data: any) {
        if (this.reportSettings) {
            this.reportSettings[property] = data as never;
        }
    }

    onAction(event: string) {
        const response: {
            type: string;
            data: IReportSetting | null;
        } = {
            type: event,
            data: null
        };

        switch (event) {
            case 'save':
            case 'run':
                response['data'] = this.reportSettings;
                this.action.emit(response);
                break;
            case 'cancel':
            case 'delete':
                this.action.emit(response);
                break;
            case 'next':
                const nextData = getNextActivePanel(this.activePanel, this.reportOptions);
                this.activePanel = nextData.name;
                this.isShowNext = !nextData.isLast;
                break;
            default:
        }
    }

    ngOnDestroy() {
        this.action.complete();
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }
}
