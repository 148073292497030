<section class="container-fluid" *ngIf="loan">
    <mat-card class="p-5">
        <header class="row">
            <div class="col-6"></div>

            <div class="col-6 text-right">
                <p class="zp-payment_title">Loan</p>

                <p class="zp-refference_due_title">Amount</p>
                <p class="zp-refference_due_value">{{ loan?.amount | currency }}</p>

                <p class="zp-refference_due_title">Balance</p>
                <p class="zp-refference_due_value">
                    {{ (loan?.balance || loan?.balance === 0 ? loan?.balance : loan?.amount) | currency }}
                </p>

                <p class="mb-2">
                    <span>Issuance Date:</span>
                    <span class="zp-w-300">{{ loan?.date | date: 'longDate' }}</span>
                </p>

                <p class="mb-2">
                    <span>Contacts/User:</span>
                    <span class="zp-w-300">{{ loan?.receiver_contact?.display_name }}</span>
                </p>

                <p class="mb-2">
                    <span>Originator:</span>
                    <span class="zp-w-300">{{ loan?.sender_contact?.display_name }}</span>
                </p>

                <p class="mb-2">
                    <span>Product/Service:</span>
                    <span class="zp-w-300">{{ loan.product ? loan.product.name : '-' }}</span>
                </p>

                <p class="mb-2">
                    <span>Interest Rate:</span>

                    <span class="zp-w-300" *ngIf="loan?.interest_period === 'none'">None</span>
                    <span
                        class="zp-w-300"
                        *ngIf="loan?.interest_period !== 'none' && loan?.interest_rate_type === 'flat'"
                    >
                        {{ loan?.interest_rate_flat | currency }} {{ loan?.interestPeriod?.label }}
                        {{ loan?.interestLimit }}
                    </span>
                    <span
                        class="zp-w-300"
                        *ngIf="loan?.interest_period !== 'none' && loan?.interest_rate_type === 'percent'"
                    >
                        {{ loan?.interest_rate_percent }}% {{ loan?.interestPeriod?.label }} {{ loan?.interestLimit }}
                    </span>
                </p>
            </div>
        </header>
    </mat-card>
</section>
