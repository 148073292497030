<div class="actions row">
    <div class="col-xl-5">
        <div class="action-row m-3 pr-3 justify-content-start">
            <button
                *ngIf="isExistReport"
                mat-raised-button
                color="warn"
                class="fix-focus-outline mr-2"
                (click)="delete()"
            >
                Delete
            </button>
        </div>
    </div>
    <div class="col-xl-7">
        <div class="right-section m-3">
            <button mat-raised-button class="fix-focus-outline mr-2" (click)="cancel()">Cancel</button>
            <button
                mat-raised-button
                color="primary"
                *ngIf="isExistReport"
                class="fix-focus-outline mr-2"
                (click)="save()"
            >
                Save
            </button>

            <div class="mat-raised-button drop-btn" *ngIf="!isExistReport && isShowNext">
                <button mat-raised-button class="fix-focus-outline btn-next" color="primary" (click)="next()">
                    Next
                </button>

                <mat-select *ngIf="!enableSaveAndRun" class="mat-raised-button btn-select" (selectionChange)="run()">
                    <mat-option value="run">Run Report</mat-option>
                </mat-select>
                <mat-select
                    *ngIf="enableSaveAndRun"
                    class="mat-raised-button btn-select"
                    (selectionChange)="saveAndRun()"
                >
                    <mat-option value="saveAndRun">Save &amp; Run Report</mat-option>
                </mat-select>
            </div>

            <button
                mat-raised-button
                *ngIf="!isExistReport && !isShowNext && !enableSaveAndRun"
                class="fix-focus-outline mr-2"
                color="primary"
                (click)="run()"
            >
                Run Report
            </button>

            <button
                mat-raised-button
                *ngIf="!isExistReport && !isShowNext && enableSaveAndRun"
                class="fix-focus-outline mr-2"
                color="primary"
                (click)="saveAndRun()"
            >
                Save & Run Report
            </button>
        </div>
    </div>
</div>
