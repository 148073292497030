<div>
    <h3>Update Contact</h3>
    <div class="my-3">Keep Profile and related Contact information (such as name and email address) in sync.</div>

    <div mat-dialog-actions class="d-flex justify-content-end align-items-baseline">
        <button class="ml-2" (click)="continueWithoutContactUpdate()" mat-raised-button>Skip</button>
        <button class="ml-2" (click)="continueWithContactUpdate()" mat-raised-button color="primary">
            Update Contact
        </button>
    </div>
</div>
