<app-sob-widget
    *ngIf="widget && widget.type === 'sob'"
    [getDataSubj]="filterSubj"
    [customizeVisibility]="customizeVisibility"
    [widget]="widget"
    [widgetsList]="widgetsList"
    [inputFilter]="inputFilter"
    (changeWidgetList)="onChangeWidgetList($event)"
    (onCrudAction)="onCrudAction.emit($event)"
>
</app-sob-widget>
<app-avg-commission-widget
    *ngIf="widget && widget.type === 'avg-commission'"
    [getDataSubj]="filterSubj"
    [customizeVisibility]="customizeVisibility"
    [widget]="widget"
    [widgetsList]="widgetsList"
    [inputFilter]="inputFilter"
    (changeWidgetList)="onChangeWidgetList($event)"
    (onCrudAction)="onCrudAction.emit($event)"
>
</app-avg-commission-widget>
<app-avg-price-widget
    *ngIf="widget && widget.type === 'avg-price'"
    [getDataSubj]="filterSubj"
    [customizeVisibility]="customizeVisibility"
    [widget]="widget"
    [widgetsList]="widgetsList"
    [inputFilter]="inputFilter"
    (changeWidgetList)="onChangeWidgetList($event)"
    (onCrudAction)="onCrudAction.emit($event)"
>
</app-avg-price-widget>
<app-production-widget
    *ngIf="widget && widget.type === 'production'"
    [getDataSubj]="filterSubj"
    [customizeVisibility]="customizeVisibility"
    [widget]="widget"
    [widgetsList]="widgetsList"
    [inputFilter]="inputFilter"
    (changeWidgetList)="onChangeWidgetList($event)"
    (onCrudAction)="onCrudAction.emit($event)"
>
</app-production-widget>
<app-income-widget
    *ngIf="widget && widget.type === 'income'"
    [getDataSubj]="filterSubj"
    [customizeVisibility]="customizeVisibility"
    [widget]="widget"
    [widgetsList]="widgetsList"
    [inputFilter]="inputFilter"
    (changeWidgetList)="onChangeWidgetList($event)"
    (onCrudAction)="onCrudAction.emit($event)"
>
</app-income-widget>
<app-status-widget
    *ngIf="widget && widget.type === 'status'"
    [getDataSubj]="filterSubj"
    [customizeVisibility]="customizeVisibility"
    [widget]="widget"
    [widgetsList]="widgetsList"
    [inputFilter]="inputFilter"
    (changeWidgetList)="onChangeWidgetList($event)"
    (onCrudAction)="onCrudAction.emit($event)"
>
</app-status-widget>
<app-cap-commission-structure-widget
    *ngIf="widget && widget.type === 'cap_commission_structure'"
    [getDataSubj]="filterSubj"
    [customizeVisibility]="customizeVisibility"
    [widget]="widget"
    [widgetsList]="widgetsList"
    [inputFilter]="inputFilter"
    (changeWidgetList)="onChangeWidgetList($event)"
    (onCrudAction)="onCrudAction.emit($event)"
></app-cap-commission-structure-widget>
<app-add-widget
    *ngIf="widget && widget.type === 'add'"
    [getDataSubj]="filterSubj"
    [customizeVisibility]="customizeVisibility"
    [widget]="widget"
    [widgetsList]="widgetsList"
    [inputFilter]="inputFilter"
    [board]="board"
    (changeWidgetList)="onChangeWidgetList($event)"
    (onCrudAction)="onCrudAction.emit($event)"
>
</app-add-widget>
