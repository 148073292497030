<div class="row content">
    <div class="col-xl-4 left-side">
        <mat-button-toggle-group vertical="true" [value]="activePanel" (change)="onSelectOptions($event)">
            <mat-button-toggle
                *ngFor="let item of reportOptions"
                [value]="item.value"
                (change)="onSelectOptions($event)"
            >
                {{ item.title }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="col-xl-8 right-side">
        <ng-container *ngIf="reportSettings">
            <div *ngIf="activePanel === 'report_period'">
                <app-report-edit-bar-report-period
                    [value]="{
                        time_period: reportSettings.time_period,
                        time_period_start: reportSettings.time_period_start,
                        time_period_end: reportSettings.time_period_end,
                        date_type: reportSettings.date_type,
                        compare_to_periods: reportSettings.compare_to_periods
                    }"
                    (valueChanges)="onReportPeriodValueChanges($event)"
                    [isCompareToPeriodAllowed]="false"
                    [isPickingDateTypePossible]="true"
                >
                </app-report-edit-bar-report-period>
            </div>

            <div *ngIf="activePanel === 'deal_type'">
                <app-report-edit-bar-button-list
                    [multiple]="true"
                    [list]="transactionTypeList"
                    [value]="reportSettings.transaction_type"
                    (valueChanges)="onValueChanges('transaction_type', $event)"
                >
                </app-report-edit-bar-button-list>
                <mat-checkbox
                    class="include-checkbox"
                    (click)="$event.stopPropagation()"
                    [(ngModel)]="reportSettings.is_include_sub_types"
                    (change)="toggleIncludeSubItemsCheckbox()"
                >
                    Include Sub-Types
                </mat-checkbox>
                <div *ngIf="reportSettings.is_include_sub_types" class="subtypes-wrapper">
                    <app-report-autocomplete
                        [list]="subTypes"
                        [value]="reportSettings.transaction_sub_type"
                        (valueChanges)="onValueChanges('transaction_sub_type', $event)"
                        titleAttribute="title"
                        valueAttribute="value"
                    >
                    </app-report-autocomplete>
                </div>
            </div>

            <div *ngIf="activePanel === 'deal_property_class'">
                <app-report-edit-bar-button-list
                    [multiple]="true"
                    [list]="listOfPropertyClass"
                    [value]="reportSettings.property_class"
                    (valueChanges)="onValueChanges('property_class', $event)"
                >
                </app-report-edit-bar-button-list>
            </div>

            <div *ngIf="activePanel === 'deal_status'">
                <app-report-edit-bar-button-list
                    [multiple]="true"
                    [list]="listOfTransactionStatus"
                    [value]="reportSettings.transaction_status"
                    (valueChanges)="onValueChanges('transaction_status', $event)"
                >
                </app-report-edit-bar-button-list>
            </div>

            <div *ngIf="activePanel === 'deal_approval'">
                <app-report-edit-bar-button-list
                    [multiple]="true"
                    [list]="listOfDealApproval"
                    [value]="reportSettings.deal_approval"
                    (valueChanges)="onValueChanges('deal_approval', $event)"
                >
                </app-report-edit-bar-button-list>
            </div>

            <div *ngIf="activePanel === 'layout'">
                <h4>Select tags</h4>
                <app-report-edit-bar-layout
                    [value]="reportSettings.layout"
                    (valueChanges)="onValueChanges('layout', $event)"
                >
                </app-report-edit-bar-layout>
            </div>

            <div *ngIf="activePanel === 'report_tagging'" class="content-item">
                <h4>Select tags</h4>
                <app-report-autocomplete
                    [list]="allTags"
                    [value]="reportSettings.tags"
                    (valueChanges)="onValueChanges('tags', $event)"
                    titleAttribute="title"
                    valueAttribute="tag_id"
                >
                </app-report-autocomplete>
            </div>

            <div *ngIf="activePanel === 'entity'" class="content-item">
                <h4>Participants</h4>
                <app-report-edit-bar-entity
                    [value]="reportSettings.entities"
                    [entityTypeList]="['individual', 'individuals_in_group']"
                    (valueChanges)="onValueChanges('entities', $event)"
                >
                </app-report-edit-bar-entity>
            </div>

            <div *ngIf="activePanel === 'division'" class="content-item">
                <h4>Divisions</h4>
                <app-report-edit-bar-entity
                    [value]="reportSettings.divisions"
                    [entityTypeList]="['company_group']"
                    [onlyDivisions]="true"
                    (valueChanges)="onValueChanges('divisions', $event)"
                >
                </app-report-edit-bar-entity>
            </div>

            <div *ngIf="activePanel === 'source_of_business'" class="content-item">
                <h4>Source Of Business</h4>
                <app-report-autocomplete
                    [list]="sourcesOfBusiness"
                    [value]="reportSettings.source_of_business"
                    (valueChanges)="onValueChanges('source_of_business', $event)"
                    titleAttribute="title"
                    valueAttribute="value"
                >
                </app-report-autocomplete>
            </div>
        </ng-container>

        <div *ngIf="report">
            <app-report-edit-bar-action [reportId]="report.id" [isShowNext]="isShowNext" (action)="onAction($event)">
            </app-report-edit-bar-action>
        </div>
    </div>
</div>
