import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {Observable} from 'rxjs';
import {IVendorCredit, ICreditNote} from '@cyberco-nodejs/zipi-typings';
import {IScrollData} from '../../../models/scroll-data';

@Injectable()
export class CreditsService {
    private urlVendor = '/finance/vendor-credits';
    private urlCustomer = '/finance/credit-notes';

    constructor(protected requester: ServiceRequester) {}

    getSourceVendorCredits(scrollData: IScrollData): Observable<{_meta: {total: number}; result: IVendorCredit[]}> {
        return this.requester.makeMsCall$(this.urlCustomer + '/sourcevendor', 'GET', 'shipp', scrollData);
    }

    getSourceCustomerCredits(scrollData: IScrollData): Observable<{_meta: {total: number}; result: ICreditNote[]}> {
        return this.requester.makeMsCall$(this.urlVendor + '/sourcecustomer', 'GET', 'shipp', scrollData);
    }

    getSourceVendorCreditById(vendorCreditId: number): Observable<IVendorCredit> {
        return this.requester.makeMsCall$(this.urlCustomer + `/sourcevendor/${vendorCreditId}`, 'GET', 'shipp');
    }

    getSourceCustomerCreditById(customerCreditId: number): Observable<ICreditNote> {
        return this.requester.makeMsCall$(this.urlVendor + `/sourcecustomer/${customerCreditId}`, 'GET', 'shipp');
    }
}
