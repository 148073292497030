import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {SessionService} from '../../../services/session.service';
import {NotificationsServiceZipi} from '../../../modules/notifications/notifications.service';
import {SignInFormGroup} from '../../../models/forms/sign-in.form';

@Component({
    selector: 'app-add-existing-company-step-1',
    styles: [
        `
            mat-card {
                /*margin: 70px auto;*/
                margin-right: 70px;
                margin-left: 70px;
                margin-top: 10px;
                margin-bottom: 10px;
                max-width: 350px;
            }

            mat-card-header {
                display: block;
                text-align: center;
                font-size: 22px;
                color: #676161;
            }

            img {
                width: 280px;
            }

            mat-card-header .title {
                padding: 15px 0;
                font-size: 14px;
            }

            mat-card-content .title {
                padding: 15px 0;
                text-align: center;
                font-size: 18px;
                border-top: 1px solid #ccc;
            }

            mat-form-field {
                width: 100%;
            }

            button {
                padding-left: 40px;
                padding-right: 40px;
                margin: 20px auto;
            }
        `
    ],
    template: `
        <mat-card class="card">
            <mat-card-header>
                <div class="title">Confirm your current Account Credentials</div>
            </mat-card-header>
            <mat-card-content *ngIf="firstFormGroup">
                <form [formGroup]="firstFormGroup">
                    <mat-form-field>
                        <input matInput type="email" name="email" placeholder="Email" [formControlName]="'email'" />
                    </mat-form-field>

                    <mat-form-field>
                        <input
                            matInput
                            type="password"
                            name="password_confirm"
                            [formControlName]="'password'"
                            placeholder="Please re-enter your password"
                        />
                    </mat-form-field>

                    <div class="flex-row">
                        <button mat-button class="mat-primary" (click)="onConfirmSubmit()">Confirm</button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    `
})
export class AddExistingCompanyStep1Component implements OnDestroy {
    @Input() firstFormGroup: SignInFormGroup | undefined;
    @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        public sessionService: SessionService,
        protected notificationsService: NotificationsServiceZipi
    ) {}

    onConfirmSubmit() {
        if (typeof this.firstFormGroup === 'undefined') {
            return;
        }
        this.firstFormGroup.controls['submit'].setValue(true);
        this.confirm.emit();
    }

    ngOnDestroy() {
        this.confirm.complete();
    }
}
