<div mat-dialog-title *ngIf="transactionType">
    <h3>
        {{
            data.ledgerAccountId || transactionType.value !== 'transfer_from_another_account'
                ? transactionType.label
                : 'Transfer'
        }}
    </h3>
</div>

<mat-dialog-content>
    <app-ledger-account-selector [ledgerAccountControl]="accountFC" [placeholder]="ledgerAccountLabel" [types]="types">
    </app-ledger-account-selector>

    <app-add-transaction
        *ngIf="transactionType"
        (closeSidebar)="dialogRef.close(false)"
        (create)="dialogRef.close($event)"
        [createDisabled]="false"
        [transactionType]="transactionType"
        [ledgerAccountId]="accountFC.value"
    >
    </app-add-transaction>
</mat-dialog-content>
