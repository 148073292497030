import {Component, OnInit, Input, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {IContactsState} from '../store/contacts.reducer';
import {IContactNote, IDocumentItem} from '@cyberco-nodejs/zipi-typings';
import {ShipperContactsService} from '../../../services/api/shipper.contacts.service';
import {DocumentsService} from '../../../services/documents.service';
import {Observable, Subject} from 'rxjs';
import {AngularFireStorage, BUCKET} from '@angular/fire/compat/storage';
import {environment} from '../../../../environments/environment';
import {isEqual} from 'lodash-es';
import * as FileSaver from 'file-saver';
import {SessionService} from 'app/services/session.service';

function makeBucketName(env: any) {
    return [env.namespace, env.firebase.projectId, env.firestoreDocumentsUploadBucket].join('_');
}

@Component({
    selector: 'app-contact-notes',
    templateUrl: 'contact-notes.component.html',
    providers: [{provide: BUCKET, useValue: makeBucketName(environment)}, AngularFireStorage]
})
export class ContactNotesComponent implements OnInit, OnDestroy {
    @Input() contactId: number | null = null;
    @Input() companyId: number | undefined;
    @ViewChild('noteCreateDoc') noteCreateDoc: ElementRef | undefined;

    note: IContactNote | undefined;
    notes: IContactNote[] | undefined;
    text: string = '';
    profileId: number | undefined;

    private unsubscribe: Subject<void> = new Subject();

    editMode: number | null = null;
    createMode: boolean = false;

    sourceFileDocs: File | undefined;
    readerDocuments = new FileReader();
    documentId: number | null = null;
    fileName: string = '';
    errorMsg: string | null = null;
    loadingProgress: number | null | undefined = null;

    constructor(
        public dialog: MatDialog,
        private store: Store<IContactsState>,
        private contactSrv: ShipperContactsService,
        private documentSrv: DocumentsService,
        private storage: AngularFireStorage,
        private sessionService: SessionService
    ) {}

    ngOnInit(): void {
        this.getContactNotes();
        if (this.sessionService.profile && this.sessionService.profile.id) {
            this.profileId = this.sessionService.profile.id;
        }
    }

    openCreateNote(): void {
        this.createMode = true;
        this.editMode = null;
    }

    addNote(): void {
        if (this.noteCreateDoc && !this.noteCreateDoc.nativeElement.value) {
            this.createNote();
        } else {
            this.uploadFile();
        }
    }

    private createNote() {
        if (this.contactId && this.profileId && this.documentId) {
            this.createMode = false;

            this.note = {
                contact_fk_id: this.contactId,
                creator__profile_fk_id: this.profileId,
                text: this.text,
                document_fk_id: this.documentId
            };

            this.contactSrv
                .createContactNote(this.contactId, this.note)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((ok) => {
                    if (!ok) {
                        return;
                    }
                    this.getContactNotes();
                    this.text = '';
                });
        }
    }

    closeNote(): void {
        this.createMode = false;
        this.editMode = null;
        this.text = '';
    }

    private getContactNotes(): void {
        if (this.contactId) {
            this.contactSrv
                .getContactNotes(this.contactId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (result) {
                        this.notes = result;
                    }
                });
        }
    }

    openEditNote(note: IContactNote): void {
        this.createMode = false;
        this.editMode = Number(note.id);
        this.text = note.text;
    }

    editNote(editNote: string, note: IContactNote): void {
        if (this.contactId) {
            this.editMode = null;
            this.text = '';

            if (isEqual(editNote, this.text)) {
                return;
            }

            note.text = editNote;

            this.contactSrv
                .updateContactNoteById(this.contactId, note)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((ok) => {
                    if (!ok) {
                        return;
                    }
                    this.getContactNotes();
                });
        }
    }

    deleteNote(noteId: number): void {
        if (this.contactId) {
            this.contactSrv
                .deleteContactNoteById(this.contactId, noteId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((ok) => {
                    if (!ok) {
                        return;
                    }
                    this.getContactNotes();
                });
        }
    }

    handleUploadFileToBuffer(files: FileList | null): void {
        if (files && (files.length === 0 || !files[0])) {
            return;
        }

        // @ts-ignore
        const [file] = files;

        if (this.noteCreateDoc && file.size > 2147483648) {
            this.noteCreateDoc.nativeElement.value = null;
            this.errorMsg = 'File size too big. It must be under 2 Gb';
        } else {
            this.sourceFileDocs = file;
            this.readerDocuments.readAsBinaryString(file);
        }
    }

    private uploadFile(): void {
        if (this.sourceFileDocs) {
            const filePath = this.makeFileUploadNameDocs(this.sourceFileDocs.name);

            this._uploadFileToBucket(filePath, this.sourceFileDocs)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((progress: number | null | undefined) => {
                    this.loadingProgress = progress;

                    if (this.noteCreateDoc && progress === 100) {
                        this.noteCreateDoc.nativeElement.value = null;
                        this.documentId = null;
                        this.loadingProgress = null;

                        this.createDocument(filePath);
                    }
                });
        }
    }

    closeFileMode(): void {
        if (this.noteCreateDoc) {
            this.noteCreateDoc.nativeElement.value = null;
            this.documentId = null;
        }
    }

    private createDocument(filePath: string): void {
        if (this.companyId && this.contactId) {
            const document: IDocumentItem = {
                related_to__contact_fk_id: this.contactId,
                creator__profile_fk_id: Number(this.profileId),
                creator__company_fk_id: this.companyId,
                path: filePath,
                name: this.fileName,
                note: ''
            };

            this.documentSrv
                .createDocument(this.companyId, document)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (!result) {
                        return;
                    }
                    this.documentId = Number(result.document_id);

                    this.createNote();
                });
        }
    }

    private makeFileUploadNameDocs(fileName: string): string {
        this.fileName = `${Date.now()}_${fileName}`;
        return `companies/${this.companyId}/contacts/${this.contactId}/documents/${fileName}`;
    }

    private _uploadFileToBucket(filePath: string, file: File): any {
        return this.storage.upload(filePath, file).percentageChanges();
    }

    downloadFile(id: number): void {
        if (this.companyId) {
            this.documentSrv
                .getDocument(this.companyId, id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    const blob = new Blob([new Uint8Array(result.buffer.data)]);
                    FileSaver.saveAs(blob, result.name);
                });
        }
    }

    clearError() {
        this.errorMsg = null;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
