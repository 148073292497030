import {Action} from '@ngrx/store';

export enum dealActionTypes {
    CLEAR_DEAL_TO_REMOVE_LIST = '[DEAL] CLEAR_DEAL_TO_REMOVE_LIST',
    ADD_DEAL_TO_REMOVE_LIST = '[DEAL] ADD_DEAL_TO_REMOVE_LIST',
    RM_DEAL_TO_REMOVE_LIST = '[DEAL] RM_DEAL_TO_REMOVE_LIST'
}

export class ClearDealToRemoveListAction implements Action {
    readonly type: string = dealActionTypes.CLEAR_DEAL_TO_REMOVE_LIST;
    constructor(public payload?: any) {}
}

export class AddDealToRemoveListAction implements Action {
    readonly type: string = dealActionTypes.ADD_DEAL_TO_REMOVE_LIST;
    constructor(public payload?: number) {}
}

export class RmDealToRemoveListAction implements Action {
    readonly type: string = dealActionTypes.RM_DEAL_TO_REMOVE_LIST;
    constructor(public payload?: number) {}
}

export type DealAction = ClearDealToRemoveListAction | AddDealToRemoveListAction | RmDealToRemoveListAction;
