import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
    OnChanges,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {IDealParticipants} from 'typings';
import {assign, flow as pipe, reduce as _reduce} from 'lodash-es';
import {first, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {
    IChipNode,
    IContact,
    IContactLocationTable,
    IContactPerson,
    IContactPartLink
} from '@cyberco-nodejs/zipi-typings';
import {ContactCreateDialogComponent} from '../../../contacts/contact-dialogs/contact-create-dialog/contact-create-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Deal} from 'app/models/deal';
import {ContactType} from 'app/models/contact-type';
import {GenericFormArray, GenericFormGroup} from 'app/entites/generic.entity';
import {ChipNode} from '../../../account-info/compensation/models/chip-node';
import {ShipperContactsService} from 'app/services/api/shipper.contacts.service';
import {NotificationsServiceZipi} from '../../../notifications/notifications.service';
import {UntypedFormControl} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {IContactsState} from '../../../contacts/store/contacts.reducer';
import {DealService} from 'app/services/deal.service';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {DEAL_PARTICIPANT_FORMAT} from 'app/models/contact-part-link';
import {AssociateContactDialogComponent} from '../../../finance/components/bills/associate-contact-dialog/associate-contact-dialog.component';
import {SessionService} from 'app/services/session.service';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {ContactTypeService} from 'app/services/api/contact-type.service';
import {RbacService} from '../../../rbac/rbac.service';
import {MatInput} from '@angular/material/input';
import {SearchEntitiesSource} from 'app/services/sources/search-entities.source';

const extractContactLocations = pipe(
    (
        contacts: {
            contact_id: number | undefined;
            company_name: string;
            display_name: string;
            contact_persons: IContactPerson[];
            contact_locations: IContactLocationTable[] | undefined;
        }[]
    ) =>
        _reduce(
            contacts,
            (
                accMap: any,
                contact: {
                    contact_id: number | undefined;
                    company_name: string;
                    display_name: string;
                    contact_persons: IContactPerson[];
                    contact_locations: IContactLocationTable[] | undefined;
                }
            ) => {
                accMap.set(contact.contact_id, contact.contact_locations);
                return accMap;
            },
            new Map()
        )
);

export interface ITextPartcipantData {
    fake_title: string;
    format: 'contact' | 'text';
    is_ignored: boolean | undefined | null;
    deal_participant_id: number | undefined;
    added_by__addon_id: number | undefined | null;
}

@Component({
    selector: 'app-deal-participants',
    templateUrl: 'deal-participants.component.html',
    styles: [
        `
            .escrow-company-btn {
                position: absolute;
                right: 12px;
                margin-top: 9px;
                z-index: 100;
                width: 28px;
                height: 28px;
                background: #fff;
            }
            .make-client {
                display: flex;
            }
            .make-client__btn {
                color: #324c58;
                cursor: pointer;
                margin-left: 6px;
            }
            .make-client__btn:hover {
                opacity: 0.8;
            }
            .hide {
                display: none;
            }
            .chip-item {
                min-height: 23px !important;
                height: auto !important;
                max-width: 85%;
            }
            .picker-form-input {
                opacity: 0.4;
            }
            .thin-title {
                position: absolute;
                color: gray;
                font-size: 0.7rem;
            }
        `
    ]
})
export class DealParticipantsComponent implements OnInit, OnDestroy, OnChanges {
    private unsubscribe: Subject<void> = new Subject();

    @Input() dealParticipants: IDealParticipants = {
        buyer_agents: null,
        seller_agents: null,
        escrow_agent: null,
        buyers: null,
        sellers: null,
        referring_from_agent: null,
        referring_to_agent: null,
        others: null,
        commission_payer: null
    };
    @Input() dealClientType: 'seller' | 'buyer' | undefined = undefined;
    @Input() transactionType: string | undefined = undefined;
    @Input() dealId: number | undefined = undefined;
    @Input() dealFormGroup = new GenericFormGroup(new Deal(), 'change');
    @Output() updateParticipants = new EventEmitter<IDealParticipants>();
    @Output() updateDealClientTypeEvent: EventEmitter<null | string> = new EventEmitter();
    @Output() updateDealTransactionTypeEvent = new EventEmitter();

    @ViewChild('filter') filterInput: MatInput | undefined = undefined;

    filterFC: UntypedFormControl = new UntypedFormControl('');

    DEAL_CLIENT_TYPES = Deal.deal_client_type_SET;
    DEAL_TRANSACTION_TYPES = Deal.type_SET;

    escrowContacts: Array<{
        contact_id: number;
        company_name: string;
        display_name: string;
        contact_persons: IContactPerson[];
        contact_locations: IContactLocationTable[] | undefined;
    }> = [];
    contactLocations: Map<number, IContactLocationTable[]> = new Map<number, IContactLocationTable[]>();
    contactPersons: {contact_id: number; link_title: string; contact_person_id: number}[] = [];
    contactPersonsMapped: (
        | {
              contact_person_id: number;
              full_name: string;
              company_name: string;
              type: string;
          }
        | IContactPerson
    )[] = [];

    scrollDataVendor = {
        limit: 30,
        offset: 0,
        except: ''
    };
    showMore: boolean = true;

    selectedBuyerAgentForm: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    selectedBuyerAgentIds: {id: number; fake_title: string}[] = [];
    textParticipantsBuyerAgents: ITextPartcipantData[] = [];

    selectedSellerAgentForm: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    selectedSellerAgentIds: {id: number; fake_title: string}[] = [];
    textParticipantsSellerAgents: ITextPartcipantData[] = [];

    selectedReferringFromAgentForm: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    selectedReferringFromAgentIds: {id: number; fake_title: string}[] = [];
    textParticipantsReferringFromAgent: ITextPartcipantData[] = [];

    selectedReferringToAgentForm: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    selectedReferringToAgentIds: {id: number; fake_title: string}[] = [];
    textParticipantsReferringToAgent: ITextPartcipantData[] = [];

    selectedSellersForm: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    selectedSellersIds: {id: number; fake_title: string}[] = [];
    textParticipantsSellers: ITextPartcipantData[] = [];

    selectedBuyersForm: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    selectedBuyersIds: {id: number; fake_title: string}[] = [];
    textParticipantsBuyers: ITextPartcipantData[] = [];

    selectedOthersForm: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    selectedOthersIds: {id: number; fake_title: string}[] = [];
    textParticipantsOthers: ITextPartcipantData[] = [];

    selectedEscrowAgentForm: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    selectedEscrowAgentIds: {id: number; fake_title: string}[] = [];
    textParticipantsClothingCompany: ITextPartcipantData[] = [];

    DEFAULT_ESCROW_VALUES = {
        category: ContactType.metatype_SET.vendor,
        isCompany: true
    };

    lastLengthParticipants = {
        seller_agents: 0,
        buyer_agents: 0,
        sellers: 0,
        buyers: 0,
        others: 0
    };

    disableSellerAgents: boolean = true;
    disableBuyerAgents: boolean = true;
    disableFromAgent: boolean = true;
    disableToAgent: boolean = true;
    disableClosingCompany: boolean = true;
    disableSellers: boolean = true;
    disableBuyers: boolean = true;
    disableOthers: boolean = true;

    isAllowedContacts: boolean = false;

    constructor(
        public dialog: MatDialog,
        private contactsService: ShipperContactsService,
        private notificationServiceZipi: NotificationsServiceZipi,
        private store: Store<IContactsState>,
        private dealService: DealService,
        private sessionService: SessionService,
        private currentProfileSource: CurrentProfileSource,
        private contactTypeService: ContactTypeService,
        protected rbacService: RbacService,
        protected searchEntitiesSource: SearchEntitiesSource
    ) {}

    changeClientType(type: string) {
        if (this.dealFormGroup.controls.client_type!.disabled) {
            return;
        }
        if (this.dealClientType === type) {
            this.updateDealClientTypeEvent.emit(null);
            return;
        }

        this.updateDealClientTypeEvent.emit(type);

        // set deal.type
        if (!this.transactionType) {
            switch (type) {
                case this.DEAL_CLIENT_TYPES.seller: {
                    this.updateDealTransactionTypeEvent.emit(this.DEAL_TRANSACTION_TYPES.seller);
                    break;
                }
                case this.DEAL_CLIENT_TYPES.buyer: {
                    this.updateDealTransactionTypeEvent.emit(this.DEAL_TRANSACTION_TYPES.buyer);
                    break;
                }
                default:
                    break;
            }
        }
    }

    update(updVal: object) {
        this.updateParticipants.emit(assign({}, this.dealParticipants, updVal));
    }

    /*updateEscrowCompany(contact) {
        const mainPerson = contact.contact_persons.find(person => person.type === 'main_person');
        const somePerson = contact.contact_persons.find(person => person.type === 'person');
        const p = mainPerson || somePerson;

        const partlink = {
            contact_id: contact.id,
            link_title: p
                ? p.full_name + (!!contact.company_name && contact.company_name !== p.full_name ? `, ${contact.company_name}` : '')
                : contact.company_name,
            contact_person_fk_id: p
                ? p.contact_person_id
                : null
        };

        this.update({ escrow_agent: { partlink } });
    }*/

    updateEscrowPerson(
        contactPerson:
            | {contact_person_id: number; full_name: string; company_name: string; type: string}
            | IContactPerson
    ) {
        if (
            this.dealParticipants.escrow_agent &&
            this.dealParticipants.escrow_agent.contact_person_fk_id === contactPerson.contact_person_id
        ) {
            return;
        }

        this.update({
            escrow_agent: {
                contact_id: this.dealParticipants.escrow_agent?.contact_id,
                contact_location_fk_id: this.dealParticipants.escrow_agent?.contact_location_fk_id,
                location_title: this.dealParticipants.escrow_agent?.location_title,
                link_title:
                    contactPerson.full_name +
                    (!!contactPerson.company_name && contactPerson.company_name !== contactPerson.full_name
                        ? `, ${contactPerson.company_name}`
                        : ''),
                contact_person_fk_id: contactPerson.contact_person_id
            }
        });
    }

    updateEscrowPersonFirst(contactPerson: {contact_id: number; link_title: string; contact_person_id: number}) {
        const contact = this.escrowContacts.find((c) => c.contact_id === contactPerson.contact_id);
        if (typeof contact === 'undefined' || typeof contact.contact_id === 'undefined') {
            alert('Error on contactPerson selection');
            return;
        }

        this.selectedEscrowAgentIds = [{id: contactPerson.contact_id, fake_title: contactPerson.link_title}];
    }

    doRemoveRepresentative() {
        const contact = this.escrowContacts.find(
            (c) => c?.contact_id === this.dealParticipants.escrow_agent?.contact_id
        );
        if (typeof contact === 'undefined') {
            alert('Error on contactPerson selection');
            return;
        }

        this.update({
            escrow_agent: {
                contact_id: this.dealParticipants.escrow_agent?.contact_id,
                contact_person_fk_id: null,
                link_title: contact.display_name,
                contact_location_fk_id: this.dealParticipants.escrow_agent?.contact_location_fk_id,
                location_title: this.dealParticipants.escrow_agent?.location_title
            }
        });
    }

    buildLocationTitle(location: IContactLocationTable) {
        return [location.street_address, location.city, [location.state, location.zip].join(' ')].join(', ');
    }

    updateEscrowLocation(contactLocation: IContactLocationTable) {
        const contact = this.escrowContacts.find((c) => c.contact_id === contactLocation.contact_id);
        if (!contact) {
            alert('Error on contactLocation selection');
            return;
        }

        this.update({
            escrow_agent: {
                ...this.dealParticipants.escrow_agent,
                location_title: this.buildLocationTitle(contactLocation),
                contact_location_fk_id: contactLocation.contact_location_id
            }
        });
    }

    openContactCreationDialog(data: {
        contact: {
            contact_id: number | undefined;
            company_name: string;
            display_name: string;
            contact_persons: IContactPerson[];
            contact_locations: IContactLocationTable[] | undefined;
        };
        goToLocationTab?: boolean;
        isCompany?: boolean;
        category?: string;
        ownerType?: string;
    }) {
        const dialogRef = this.dialog.open(ContactCreateDialogComponent, {
            width: '90vw',
            autoFocus: false,
            data: Object.assign(data, {isCompany: true})
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((contactUpdated) => {
                if (contactUpdated) {
                    this.contactsService
                        .getContactById(data.contact.contact_id as number)
                        .pipe(first())
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((newContact) => {
                            if (typeof newContact === 'undefined') {
                                return;
                            }

                            this.escrowContacts = [
                                ...this.escrowContacts,
                                {
                                    contact_id: newContact.contact_id as number,
                                    company_name: newContact.company_name as string,
                                    display_name: newContact.display_name as string,
                                    contact_persons: newContact.contact_persons,
                                    contact_locations: newContact.contact_locations
                                }
                            ];
                            this.contactLocations = extractContactLocations(this.escrowContacts);
                            this.updateContactPersons();
                            this.contactPersonsMapped = newContact.contact_persons
                                .filter((p) => p.type !== 'main_person')
                                .map((cp) => {
                                    cp.company_name = newContact.company_name as string;
                                    return cp;
                                });
                        });
                }
            });
    }

    contactCreated(
        contactCreated: {created: IContact},
        type:
            | 'buyer_agents'
            | 'seller_agents'
            | 'escrow_agent'
            | 'buyers'
            | 'sellers'
            | 'referring_from_agent'
            | 'referring_to_agent'
            | 'others'
            | 'commission_payer'
    ) {
        this.searchEntitiesSource.clearCache();

        if (contactCreated && contactCreated.hasOwnProperty('created') && contactCreated.created) {
            // for single-selected
            if (['referring_from_agent', 'referring_to_agent'].includes(type)) {
                // for first value calls onChange method
                switch (type) {
                    case 'referring_from_agent':
                        if (
                            this.selectedReferringFromAgentIds.length === 0 &&
                            typeof contactCreated.created.contact_persons[0].contact_person_id !== 'undefined'
                        ) {
                            this.selectedReferringFromAgentIds = [
                                {
                                    id: contactCreated.created.contact_persons[0].contact_person_id,
                                    fake_title:
                                        contactCreated.created.contact_persons[0].first_name +
                                        ' ' +
                                        contactCreated.created.contact_persons[0].last_name
                                }
                            ];
                        }
                        break;
                    case 'referring_to_agent':
                        if (
                            this.selectedReferringToAgentIds.length === 0 &&
                            typeof contactCreated.created.contact_persons[0].contact_person_id !== 'undefined'
                        ) {
                            this.selectedReferringToAgentIds = [
                                {
                                    id: contactCreated.created.contact_persons[0].contact_person_id,
                                    fake_title:
                                        contactCreated.created.contact_persons[0].first_name +
                                        ' ' +
                                        contactCreated.created.contact_persons[0].last_name
                                }
                            ];
                        }
                        break;
                }
            } else if (['seller_agents', 'buyer_agents', 'sellers', 'buyers', 'others'].includes(type)) {
                // for multiple-selected
                let participants: IContactPartLink[] = [];
                if (this.dealParticipants[type] && Array.isArray(this.dealParticipants[type])) {
                    participants = (this.dealParticipants[type] as IContactPartLink[]).slice();
                }

                participants.push({
                    contact_id: contactCreated.created.contact_id as number,
                    contact_person_fk_id: contactCreated.created.contact_persons[0].contact_person_id as number,
                    link_title: contactCreated.created.contact_persons[0].full_name,
                    contact_location_fk_id: null,
                    location_title: null
                });

                this.update({[type]: participants});

                // for any value calls onChange method
                switch (type) {
                    case 'seller_agents':
                        if (typeof contactCreated.created.contact_persons[0].contact_person_id !== 'undefined') {
                            this.selectedSellerAgentIds = [
                                ...this.selectedSellerAgentIds,
                                {
                                    id: contactCreated.created.contact_persons[0].contact_person_id,
                                    fake_title:
                                        contactCreated.created.contact_persons[0].first_name +
                                        ' ' +
                                        contactCreated.created.contact_persons[0].last_name
                                }
                            ];
                        }
                        break;
                    case 'buyer_agents':
                        if (typeof contactCreated.created.contact_persons[0].contact_person_id !== 'undefined') {
                            this.selectedBuyerAgentIds = [
                                ...this.selectedBuyerAgentIds,
                                {
                                    id: contactCreated.created.contact_persons[0].contact_person_id,
                                    fake_title:
                                        contactCreated.created.contact_persons[0].first_name +
                                        ' ' +
                                        contactCreated.created.contact_persons[0].last_name
                                }
                            ];
                        }
                        break;
                    case 'sellers':
                        if (typeof contactCreated.created.contact_persons[0].contact_person_id !== 'undefined') {
                            this.selectedSellersIds = [
                                ...this.selectedSellersIds,
                                {
                                    id: contactCreated.created.contact_persons[0].contact_person_id,
                                    fake_title:
                                        contactCreated.created.contact_persons[0].first_name +
                                        ' ' +
                                        contactCreated.created.contact_persons[0].last_name
                                }
                            ];
                        }
                        break;
                    case 'buyers':
                        if (typeof contactCreated.created.contact_persons[0].contact_person_id !== 'undefined') {
                            this.selectedBuyersIds = [
                                ...this.selectedBuyersIds,
                                {
                                    id: contactCreated.created.contact_persons[0].contact_person_id,
                                    fake_title:
                                        contactCreated.created.contact_persons[0].first_name +
                                        ' ' +
                                        contactCreated.created.contact_persons[0].last_name
                                }
                            ];
                        }
                        break;
                    case 'others':
                        if (typeof contactCreated.created.contact_persons[0].contact_person_id !== 'undefined') {
                            this.selectedOthersIds = [
                                ...this.selectedOthersIds,
                                {
                                    id: contactCreated.created.contact_persons[0].contact_person_id,
                                    fake_title:
                                        contactCreated.created.contact_persons[0].first_name +
                                        ' ' +
                                        contactCreated.created.contact_persons[0].last_name
                                }
                            ];
                        }
                        break;
                }
            } else if (type === 'escrow_agent') {
                // for escrow_agent
                if (
                    this.selectedEscrowAgentIds.length === 0 &&
                    typeof contactCreated.created.contact_persons[0].contact_person_id !== 'undefined'
                ) {
                    this.selectedEscrowAgentIds = [
                        {
                            id: contactCreated.created.contact_id as number,
                            fake_title: contactCreated.created.display_name as string
                        }
                    ];
                }
            }
        }
    }

    escrowAgentCreate(event: Event) {
        event.stopPropagation();

        let contact = null;
        if (this.dealParticipants.escrow_agent) {
            contact = this.escrowContacts.find((c) => c.contact_id === this.dealParticipants.escrow_agent?.contact_id);
        }
        if (!contact) {
            throw new Error('Contact for creating Contact Person was not found');
        }

        this.openContactCreationDialog({contact});
    }

    escrowLocationCreate(event: Event) {
        event.stopPropagation();

        let contact = null;
        if (this.dealParticipants.escrow_agent) {
            contact = this.escrowContacts.find((c) => c.contact_id === this.dealParticipants.escrow_agent?.contact_id);
        }
        if (!contact) {
            throw new Error('Contact for creating Contact Location was not found');
        }

        this.openContactCreationDialog({...{contact}, goToLocationTab: true});
    }

    removeEscrowCompany() {
        this.update({escrow_agent: null});
    }

    async ngOnInit() {
        // check permissions
        this.isAllowedContacts = await this.rbacService.isAllowedAny([
            {contacts__view_limited_contacts: true},
            {contacts__view_any_contacts: true}
        ]);
        if (this.isAllowedContacts) {
            // get escrow contacts with persons and locations
            this.contactsService
                .getVendorContacts(this.scrollDataVendor)
                .pipe(first(), takeUntil(this.unsubscribe))
                .subscribe((data) => {
                    this.escrowContacts = data;
                    this.contactLocations = extractContactLocations(this.escrowContacts);
                    this.updateContactPersons();

                    this.showMore = data.length === this.scrollDataVendor.limit;
                });
        }

        // add buyer agent
        this.selectedBuyerAgentForm.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((chipNodes: IChipNode[]) => {
                if (chipNodes.length) {
                    // do not update existing participants
                    const doNotUpdate = chipNodes.filter(
                        (chipNode) =>
                            (chipNode.contact_persons.length &&
                                this.dealParticipants.buyer_agents?.find(
                                    (dp) =>
                                        dp.deal_participant_id &&
                                        chipNode.contact_persons
                                            .map((cp) => cp.contact_person_id)
                                            .includes(dp.contact_person_fk_id as number)
                                )) || // if in chipNodes exist Contact Persons, DP were saved and exists in CP
                            this.dealParticipants.buyer_agents?.find(
                                (dp) => dp.deal_participant_id && dp.contact_id === chipNode.target_id
                            ) // if in chipNodes no CP, DP were saved and equals CP
                    );
                    // equal length can be only on init
                    if (
                        doNotUpdate.filter((d) => d).length === chipNodes.length &&
                        this.lastLengthParticipants.buyer_agents < chipNodes.length
                    ) {
                        this.lastLengthParticipants.buyer_agents = chipNodes.length;
                        return;
                    }
                    this.lastLengthParticipants.buyer_agents = chipNodes.length;

                    const buyerAgentsToUpdate = chipNodes.map((chipNode) => {
                        const defaultPerson = chipNode.contact_persons?.length ? chipNode.contact_persons[0] : null;
                        const foundParticipant = this.dealParticipants.buyer_agents?.find(
                            (p) =>
                                p.contact_person_fk_id === defaultPerson?.contact_person_id ||
                                chipNode.target_id === p.contact_id
                        );

                        if (foundParticipant) {
                            return foundParticipant;
                        } else {
                            return {
                                contact_id: chipNode.target_id,
                                contact_person_fk_id: defaultPerson ? defaultPerson.contact_person_id : null,
                                link_title: chipNode.label
                            };
                        }
                    });

                    this.update({
                        buyer_agents: [
                            ...buyerAgentsToUpdate.filter((d) => d),
                            ...(this.dealParticipants.buyer_agents
                                ? this.dealParticipants.buyer_agents?.filter(
                                      (dp) => dp.format === DEAL_PARTICIPANT_FORMAT.text
                                  )
                                : [])
                        ]
                    });
                } else {
                    this.update({
                        buyer_agents: this.dealParticipants.buyer_agents?.filter(
                            (dp) => dp.format === DEAL_PARTICIPANT_FORMAT.text
                        )
                    });
                }
            });

        // add seller agent
        this.selectedSellerAgentForm.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((chipNodes: IChipNode[]) => {
                if (chipNodes.length) {
                    // do not update existing participants
                    const doNotUpdate = chipNodes.filter(
                        (chipNode) =>
                            (chipNode.contact_persons.length &&
                                this.dealParticipants.seller_agents?.find(
                                    (dp) =>
                                        dp.deal_participant_id &&
                                        chipNode.contact_persons
                                            .map((cp) => cp.contact_person_id)
                                            .includes(dp.contact_person_fk_id as number)
                                )) || // if in chipNodes exist Contact Persons, DP were saved and exists in CP
                            this.dealParticipants.seller_agents?.find(
                                (dp) => dp.deal_participant_id && dp.contact_id === chipNode.target_id
                            ) // if in chipNodes no CP, DP were saved and equals CP
                    );
                    // equal length can be only on init
                    if (
                        doNotUpdate.filter((d) => d).length === chipNodes.length &&
                        this.lastLengthParticipants.seller_agents < chipNodes.length
                    ) {
                        this.lastLengthParticipants.seller_agents = chipNodes.length;
                        return;
                    }
                    this.lastLengthParticipants.seller_agents = chipNodes.length;

                    const sellerAgentsToUpdate = chipNodes.map((chipNode) => {
                        const defaultPerson = chipNode.contact_persons?.length ? chipNode.contact_persons[0] : null;
                        const foundParticipant = this.dealParticipants.seller_agents?.find(
                            (p) =>
                                p.contact_person_fk_id === defaultPerson?.contact_person_id ||
                                chipNode.target_id === p.contact_id
                        );

                        if (foundParticipant) {
                            return foundParticipant;
                        } else {
                            return {
                                contact_id: chipNode.target_id,
                                contact_person_fk_id: defaultPerson ? defaultPerson.contact_person_id : null,
                                link_title: chipNode.label
                            };
                        }
                    });

                    this.update({
                        seller_agents: [
                            ...sellerAgentsToUpdate.filter((d) => d),
                            ...(this.dealParticipants.seller_agents
                                ? this.dealParticipants.seller_agents?.filter(
                                      (dp) => dp.format === DEAL_PARTICIPANT_FORMAT.text
                                  )
                                : [])
                        ]
                    });
                } else {
                    this.update({
                        seller_agents: this.dealParticipants.seller_agents?.filter(
                            (dp) => dp.format === DEAL_PARTICIPANT_FORMAT.text
                        )
                    });
                }
            });

        // add referring from agent
        this.selectedReferringFromAgentForm.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((chipNodes: IChipNode[]) => {
                if (chipNodes.length) {
                    // prevent from initial load
                    const defaultPerson =
                        chipNodes[0].contact_persons && chipNodes[0].contact_persons.length
                            ? chipNodes[0].contact_persons[0]
                            : null;
                    if (
                        (defaultPerson &&
                            this.dealParticipants.referring_from_agent?.contact_person_fk_id ===
                                defaultPerson.contact_person_id) ||
                        (!defaultPerson &&
                            this.dealParticipants.referring_from_agent?.contact_id === chipNodes[0].target_id)
                    ) {
                        return;
                    }
                    this.update({
                        referring_from_agent: {
                            contact_id: chipNodes[0].target_id,
                            contact_person_fk_id: defaultPerson ? defaultPerson.contact_person_id : null,
                            link_title: chipNodes[0].label,
                            deal_participant_id:
                                this.dealParticipants.referring_from_agent &&
                                (this.dealParticipants.referring_from_agent?.contact_person_fk_id ===
                                    defaultPerson?.contact_person_id ||
                                    chipNodes[0].target_id === this.dealParticipants.referring_from_agent?.contact_id)
                                    ? this.dealParticipants.referring_from_agent.deal_participant_id
                                    : null
                        }
                    });
                } else {
                    this.update({referring_from_agent: null});
                }
            });

        // add referring to agent
        this.selectedReferringToAgentForm.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((chipNodes: IChipNode[]) => {
                if (chipNodes.length) {
                    // prevent from initial load
                    const defaultPerson =
                        chipNodes[0].contact_persons && chipNodes[0].contact_persons.length
                            ? chipNodes[0].contact_persons[0]
                            : null;
                    if (
                        (defaultPerson &&
                            this.dealParticipants.referring_to_agent?.contact_person_fk_id ===
                                defaultPerson.contact_person_id) ||
                        (!defaultPerson &&
                            this.dealParticipants.referring_to_agent?.contact_id === chipNodes[0].target_id)
                    ) {
                        return;
                    }
                    this.update({
                        referring_to_agent: {
                            contact_id: chipNodes[0].target_id,
                            contact_person_fk_id: defaultPerson ? defaultPerson.contact_person_id : null,
                            link_title: chipNodes[0].label,
                            deal_participant_id:
                                this.dealParticipants.referring_to_agent &&
                                (this.dealParticipants.referring_to_agent?.contact_person_fk_id ===
                                    defaultPerson?.contact_person_id ||
                                    chipNodes[0].target_id === this.dealParticipants.referring_to_agent?.contact_id)
                                    ? this.dealParticipants.referring_to_agent.deal_participant_id
                                    : null
                        }
                    });
                } else {
                    this.update({referring_to_agent: null});
                }
            });

        // add buyers
        this.selectedBuyersForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((chipNodes: IChipNode[]) => {
            if (chipNodes.length) {
                // do not update existing participants
                const doNotUpdate = chipNodes.filter(
                    (chipNode) =>
                        (chipNode.contact_persons.length &&
                            this.dealParticipants.buyers?.find(
                                (dp) =>
                                    dp.deal_participant_id &&
                                    chipNode.contact_persons
                                        .map((cp) => cp.contact_person_id)
                                        .includes(dp.contact_person_fk_id as number)
                            )) || // if in chipNodes exist Contact Persons, DP were saved and exists in CP
                        this.dealParticipants.buyers?.find(
                            (dp) => dp.deal_participant_id && dp.contact_id === chipNode.target_id
                        ) // if in chipNodes no CP, DP were saved and equals CP
                );
                // equal length can be only on init
                if (
                    doNotUpdate.filter((d) => d).length === chipNodes.length &&
                    this.lastLengthParticipants.buyers < chipNodes.length
                ) {
                    this.lastLengthParticipants.buyers = chipNodes.length;
                    return;
                }
                this.lastLengthParticipants.buyers = chipNodes.length;

                const buyersToUpdate = chipNodes.map((chipNode) => {
                    const defaultPerson = chipNode.contact_persons?.length ? chipNode.contact_persons[0] : null;
                    const foundParticipant = this.dealParticipants.buyers?.find(
                        (p) =>
                            p.contact_person_fk_id === defaultPerson?.contact_person_id ||
                            chipNode.target_id === p.contact_id
                    );

                    if (foundParticipant) {
                        return foundParticipant;
                    } else {
                        return {
                            contact_id: chipNode.target_id,
                            contact_person_fk_id: defaultPerson ? defaultPerson.contact_person_id : null,
                            link_title: chipNode.label
                        };
                    }
                });

                this.update({
                    buyers: [
                        ...buyersToUpdate.filter((d) => d),
                        ...(this.dealParticipants.buyers
                            ? this.dealParticipants.buyers?.filter((dp) => dp.format === DEAL_PARTICIPANT_FORMAT.text)
                            : [])
                    ]
                });
            } else {
                this.update({
                    buyers: this.dealParticipants.buyers?.filter((dp) => dp.format === DEAL_PARTICIPANT_FORMAT.text)
                });
            }
        });

        // add sellers
        this.selectedSellersForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((chipNodes: IChipNode[]) => {
            if (chipNodes.length) {
                // do not update existing participants
                const doNotUpdate = chipNodes.filter(
                    (chipNode) =>
                        (chipNode.contact_persons.length &&
                            this.dealParticipants.sellers?.find(
                                (dp) =>
                                    dp.deal_participant_id &&
                                    chipNode.contact_persons
                                        .map((cp) => cp.contact_person_id)
                                        .includes(dp.contact_person_fk_id as number)
                            )) || // if in chipNodes exist Contact Persons, DP were saved and exists in CP
                        this.dealParticipants.sellers?.find(
                            (dp) => dp.deal_participant_id && dp.contact_id === chipNode.target_id
                        ) // if in chipNodes no CP, DP were saved and equals CP
                );
                // equal length can be only on init
                if (
                    doNotUpdate.filter((d) => d).length === chipNodes.length &&
                    this.lastLengthParticipants.sellers < chipNodes.length
                ) {
                    this.lastLengthParticipants.sellers = chipNodes.length;
                    return;
                }
                this.lastLengthParticipants.sellers = chipNodes.length;

                const sellersToUpdate = chipNodes.map((chipNode) => {
                    const defaultPerson = chipNode.contact_persons?.length ? chipNode.contact_persons[0] : null;
                    const foundParticipant = this.dealParticipants.sellers?.find(
                        (p) =>
                            p.contact_person_fk_id === defaultPerson?.contact_person_id ||
                            chipNode.target_id === p.contact_id
                    );

                    if (foundParticipant) {
                        return foundParticipant;
                    } else {
                        return {
                            contact_id: chipNode.target_id,
                            contact_person_fk_id: defaultPerson ? defaultPerson.contact_person_id : null,
                            link_title: chipNode.label
                        };
                    }
                });

                this.update({
                    sellers: [
                        ...sellersToUpdate.filter((d) => d),
                        ...(this.dealParticipants.sellers
                            ? this.dealParticipants.sellers?.filter((dp) => dp.format === DEAL_PARTICIPANT_FORMAT.text)
                            : [])
                    ]
                });
            } else {
                this.update({
                    sellers: this.dealParticipants.sellers?.filter((dp) => dp.format === DEAL_PARTICIPANT_FORMAT.text)
                });
            }
        });

        // add others
        this.selectedOthersForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((chipNodes: IChipNode[]) => {
            if (chipNodes.length) {
                // do not update existing participants
                const doNotUpdate = chipNodes.filter(
                    (chipNode) =>
                        (chipNode.contact_persons.length &&
                            this.dealParticipants.others?.find(
                                (dp) =>
                                    dp.deal_participant_id &&
                                    chipNode.contact_persons
                                        .map((cp) => cp.contact_person_id)
                                        .includes(dp.contact_person_fk_id as number)
                            )) || // if in chipNodes exist Contact Persons, DP were saved and exists in CP
                        this.dealParticipants.others?.find(
                            (dp) => dp.deal_participant_id && dp.contact_id === chipNode.target_id
                        ) // if in chipNodes no CP, DP were saved and equals CP
                );
                // equal length can be only on init
                if (
                    doNotUpdate.filter((d) => d).length === chipNodes.length &&
                    this.lastLengthParticipants.others < chipNodes.length
                ) {
                    this.lastLengthParticipants.others = chipNodes.length;
                    return;
                }
                this.lastLengthParticipants.others = chipNodes.length;

                const othersToUpdate = chipNodes.map((chipNode) => {
                    const defaultPerson = chipNode.contact_persons?.length ? chipNode.contact_persons[0] : null;
                    const foundParticipant = this.dealParticipants.others?.find(
                        (p) =>
                            p.contact_person_fk_id === defaultPerson?.contact_person_id ||
                            chipNode.target_id === p.contact_id
                    );

                    if (foundParticipant) {
                        return foundParticipant;
                    } else {
                        return {
                            contact_id: chipNode.target_id,
                            contact_person_fk_id: defaultPerson ? defaultPerson.contact_person_id : null,
                            link_title: chipNode.label,
                            sub_type: chipNode.subType
                        };
                    }
                });

                this.update({
                    others: [
                        ...othersToUpdate.filter((d) => d),
                        ...(this.dealParticipants.others
                            ? this.dealParticipants.others?.filter((dp) => dp.format === DEAL_PARTICIPANT_FORMAT.text)
                            : [])
                    ]
                });
            } else {
                this.update({
                    others: this.dealParticipants.others?.filter((dp) => dp.format === DEAL_PARTICIPANT_FORMAT.text)
                });
            }
        });

        // add escrow agent
        this.selectedEscrowAgentForm.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((chipNodes: IChipNode[]) => {
                if (chipNodes.length) {
                    const newEscrowAgentChipNode = chipNodes[0];
                    this.contactPersonsMapped = newEscrowAgentChipNode.contact_persons
                        .filter((cp) => cp.contact_person_type === 'person')
                        .map((cp) => {
                            return {
                                contact_person_id: cp.contact_person_id,
                                full_name: cp.contact_person_name,
                                company_name: cp.contact_company_name,
                                type: cp.contact_person_type
                            };
                        });

                    // This "if" will be executed only when we pick new value.
                    // BUT on "deal load" if deal have escrow_agent this "if" will be skipped.
                    if (!this.dealParticipants.escrow_agent) {
                        const defaultPerson = this.contactPersonsMapped.length ? this.contactPersonsMapped[0] : null;

                        let defaultLocation = null;
                        if (
                            this.contactLocations.get(newEscrowAgentChipNode.target_id as number) &&
                            this.contactLocations.get(newEscrowAgentChipNode.target_id as number)?.length
                        ) {
                            const locations = this.contactLocations.get(newEscrowAgentChipNode.target_id as number);
                            defaultLocation = locations ? locations[0] : null;
                        } else if (
                            !this.contactLocations.get(newEscrowAgentChipNode.target_id as number) &&
                            this.isAllowedContacts
                        ) {
                            // if contact was not in list
                            this.contactsService
                                .getContactById(newEscrowAgentChipNode.target_id as number)
                                .pipe(first())
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((escrowAgent) => {
                                    if (!this.escrowContacts.find((c) => c.contact_id === escrowAgent.contact_id)) {
                                        this.escrowContacts = [
                                            ...this.escrowContacts,
                                            {
                                                contact_id: escrowAgent.contact_id as number,
                                                company_name: escrowAgent.company_name as string,
                                                display_name: escrowAgent.display_name as string,
                                                contact_persons: escrowAgent.contact_persons,
                                                contact_locations: escrowAgent.contact_locations
                                            }
                                        ];
                                        this.contactLocations = extractContactLocations(this.escrowContacts);
                                        this.updateContactPersons();
                                        this.contactPersonsMapped = escrowAgent.contact_persons
                                            .filter((cp) => cp.type === 'person')
                                            .map((cp) => {
                                                cp.company_name = escrowAgent.company_name as string;
                                                return cp;
                                            });
                                    }
                                });
                        }

                        let locationTitle = '';
                        if (defaultLocation) {
                            locationTitle = this.buildLocationTitle(defaultLocation);
                        }

                        let linkTitle = newEscrowAgentChipNode.label;
                        // add company_name
                        if (defaultPerson) {
                            linkTitle =
                                defaultPerson.full_name +
                                (!!linkTitle && linkTitle !== defaultPerson.full_name ? `, ${linkTitle}` : '');
                        }

                        const contact_person_id = defaultPerson ? defaultPerson.contact_person_id : null;

                        this.update({
                            escrow_agent: {
                                contact_id: newEscrowAgentChipNode.target_id,
                                contact_person_fk_id: contact_person_id,
                                link_title: linkTitle,
                                contact_location_fk_id: defaultLocation ? defaultLocation.contact_location_id : null,
                                location_title: defaultLocation ? locationTitle : null,
                                deal_participant_id: null
                            }
                        });
                    }
                } else {
                    this.update({escrow_agent: null});
                    this.selectedEscrowAgentIds = [];
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dealParticipants) {
            if (this.dealParticipants['buyer_agents'] && this.selectedBuyerAgentIds.length === 0) {
                this.fillBuyerAgentIds();
            }
            if (this.dealParticipants['seller_agents'] && this.selectedSellerAgentIds.length === 0) {
                this.fillSellerAgentIds();
            }
            if (this.dealParticipants['referring_from_agent'] && this.selectedReferringFromAgentIds.length === 0) {
                this.fillReferringFromAgentIds();
            }
            if (this.dealParticipants['referring_to_agent'] && this.selectedReferringToAgentIds.length === 0) {
                this.fillReferringToAgentIds();
            }
            if (this.dealParticipants['buyers'] && this.selectedBuyersIds.length === 0) {
                this.fillBuyersIds();
            }
            if (this.dealParticipants['sellers'] && this.selectedSellersIds.length === 0) {
                this.fillSellersIds();
            }
            if (this.dealParticipants['others'] && this.selectedOthersIds.length === 0) {
                this.fillOthersIds();
            }
            if (this.dealParticipants.escrow_agent && this.selectedEscrowAgentIds.length === 0) {
                this.fillEscrowAgentIds();
            }

            // check is dealParticipants.escrow_agent selected and in the list
            if (
                this.dealParticipants.escrow_agent !== null &&
                this.escrowContacts &&
                !this.escrowContacts.find((c) => c.contact_id === this.dealParticipants.escrow_agent?.contact_id) &&
                this.scrollDataVendor.except === ''
            ) {
                // exclude current contact from search
                if (this.dealParticipants.escrow_agent.contact_id && this.isAllowedContacts) {
                    this.scrollDataVendor.except = this.dealParticipants.escrow_agent.contact_id.toString();
                    this.contactsService
                        .getContactById(this.dealParticipants.escrow_agent.contact_id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((escrowAgent) => {
                            if (!this.escrowContacts.find((c) => c.contact_id === escrowAgent.contact_id)) {
                                this.escrowContacts = [
                                    ...this.escrowContacts,
                                    {
                                        contact_id: escrowAgent.contact_id as number,
                                        company_name: escrowAgent.company_name as string,
                                        display_name: escrowAgent.display_name as string,
                                        contact_persons: escrowAgent.contact_persons,
                                        contact_locations: escrowAgent.contact_locations
                                    }
                                ];
                                this.contactLocations = extractContactLocations(this.escrowContacts);
                                this.updateContactPersons();
                                this.contactPersonsMapped = escrowAgent.contact_persons
                                    .filter((cp) => cp.type === 'person')
                                    .map((cp) => {
                                        cp.company_name = escrowAgent.company_name as string;
                                        return cp;
                                    });
                            }
                        });
                }
            }
        }

        if (
            (changes.disableClosingCompany && changes.disableClosingCompany.currentValue) ||
            (changes.dealFormGroup && this.dealFormGroup.controls.deal_participants?.disabled)
        ) {
            this.filterFC.disable({emitEvent: false});
        }
        if (changes.disableClosingCompany && !changes.disableClosingCompany.currentValue) {
            this.filterFC.enable({emitEvent: false});
        }
    }

    //

    nextBatch() {
        if (this.showMore && this.isAllowedContacts) {
            this.showMore = false;
            this.scrollDataVendor.offset += this.scrollDataVendor.limit;

            this.contactsService
                .getVendorContacts(this.scrollDataVendor)
                .pipe(first(), takeUntil(this.unsubscribe))
                .subscribe((data) => {
                    this.escrowContacts = [...this.escrowContacts, ...data];
                    this.contactLocations = extractContactLocations(this.escrowContacts);
                    this.updateContactPersons();

                    this.showMore = data.length === this.scrollDataVendor.limit;
                });
        }
    }

    updateContactPersons() {
        this.contactPersons = this.escrowContacts
            .filter((c) => c.contact_persons && c.contact_persons.length > 0)
            .map((contact) =>
                contact.contact_persons
                    .filter((cp) => cp.type === 'person')
                    .map((cp) => {
                        return {
                            contact_id: contact.contact_id,
                            link_title:
                                cp.full_name +
                                (!!contact.company_name && contact.company_name !== cp.full_name
                                    ? `, ${contact.company_name}`
                                    : ''),
                            contact_person_id: cp.contact_person_id as number
                        };
                    })
            )
            .flat(1);
    }

    private fillSellerAgentIds() {
        if (!this.dealParticipants['seller_agents']) {
            return;
        }

        this.selectedSellerAgentIds = [];
        this.textParticipantsSellerAgents = [];

        this.dealParticipants['seller_agents'].forEach((agent) => {
            if (agent.format === DEAL_PARTICIPANT_FORMAT.contact) {
                this.selectedSellerAgentIds.push({
                    id: agent.contact_person_fk_id as number,
                    fake_title: agent.link_title as string
                });
            } else if (agent.format === DEAL_PARTICIPANT_FORMAT.text) {
                this.textParticipantsSellerAgents.push({
                    fake_title: agent.link_title as string,
                    format: agent.format,
                    is_ignored: agent.is_ignored !== null ? agent.is_ignored : false,
                    deal_participant_id: agent.deal_participant_id,
                    added_by__addon_id: agent.added_by__addon_id
                });
            }
        });
    }

    private fillBuyerAgentIds() {
        if (!this.dealParticipants['buyer_agents']) {
            return;
        }

        this.selectedBuyerAgentIds = [];
        this.textParticipantsBuyerAgents = [];

        this.dealParticipants['buyer_agents'].forEach((agent) => {
            if (agent.format === DEAL_PARTICIPANT_FORMAT.contact) {
                this.selectedBuyerAgentIds.push({
                    id: agent.contact_person_fk_id as number,
                    fake_title: agent.link_title as string
                });
            } else if (agent.format === DEAL_PARTICIPANT_FORMAT.text) {
                this.textParticipantsBuyerAgents.push({
                    fake_title: agent.link_title as string,
                    format: agent.format,
                    is_ignored: agent.is_ignored !== null ? agent.is_ignored : false,
                    deal_participant_id: agent.deal_participant_id,
                    added_by__addon_id: agent.added_by__addon_id
                });
            }
        });
    }

    private fillReferringFromAgentIds() {
        if (!this.dealParticipants['referring_from_agent']) {
            return;
        }

        if (this.dealParticipants['referring_from_agent'].format === DEAL_PARTICIPANT_FORMAT.contact) {
            this.selectedReferringFromAgentIds = [
                {
                    id: this.dealParticipants['referring_from_agent'].contact_person_fk_id as number,
                    fake_title: this.dealParticipants['referring_from_agent'].link_title as string
                }
            ];
            this.textParticipantsReferringFromAgent = [];
        } else if (this.dealParticipants['referring_from_agent'].format === DEAL_PARTICIPANT_FORMAT.text) {
            this.textParticipantsReferringFromAgent = [
                {
                    fake_title: this.dealParticipants['referring_from_agent'].link_title as string,
                    format: this.dealParticipants.referring_from_agent.format,
                    is_ignored:
                        this.dealParticipants.referring_from_agent.is_ignored !== null
                            ? this.dealParticipants.referring_from_agent.is_ignored
                            : false,
                    deal_participant_id: this.dealParticipants.referring_from_agent.deal_participant_id,
                    added_by__addon_id: this.dealParticipants.referring_from_agent.added_by__addon_id
                }
            ];
            this.selectedReferringFromAgentIds = [];
        }
    }

    private fillReferringToAgentIds() {
        if (!this.dealParticipants['referring_to_agent']) {
            return;
        }

        if (this.dealParticipants['referring_to_agent'].format === DEAL_PARTICIPANT_FORMAT.contact) {
            this.selectedReferringToAgentIds = [
                {
                    id: this.dealParticipants['referring_to_agent'].contact_person_fk_id as number,
                    fake_title: this.dealParticipants['referring_to_agent'].link_title as string
                }
            ];
            this.textParticipantsReferringToAgent = [];
        } else if (this.dealParticipants['referring_to_agent'].format === DEAL_PARTICIPANT_FORMAT.text) {
            this.textParticipantsReferringToAgent = [
                {
                    fake_title: this.dealParticipants['referring_to_agent'].link_title as string,
                    format: this.dealParticipants.referring_to_agent.format,
                    is_ignored:
                        this.dealParticipants.referring_to_agent.is_ignored !== null
                            ? this.dealParticipants.referring_to_agent.is_ignored
                            : false,
                    deal_participant_id: this.dealParticipants.referring_to_agent.deal_participant_id,
                    added_by__addon_id: this.dealParticipants.referring_to_agent.added_by__addon_id
                }
            ];
            this.selectedReferringToAgentIds = [];
        }
    }

    private fillEscrowAgentIds() {
        if (!this.dealParticipants['escrow_agent']) {
            return;
        }

        if (this.dealParticipants.escrow_agent.format === DEAL_PARTICIPANT_FORMAT.contact) {
            this.selectedEscrowAgentIds = [
                {
                    id: this.dealParticipants.escrow_agent.contact_id as number,
                    fake_title: this.dealParticipants.escrow_agent.link_title as string
                }
            ];
            this.textParticipantsClothingCompany = [];
        } else if (this.dealParticipants.escrow_agent.format === DEAL_PARTICIPANT_FORMAT.text) {
            this.textParticipantsClothingCompany = [
                {
                    fake_title: this.dealParticipants.escrow_agent.link_title as string,
                    format: this.dealParticipants.escrow_agent.format,
                    is_ignored:
                        this.dealParticipants.escrow_agent.is_ignored !== null
                            ? this.dealParticipants.escrow_agent.is_ignored
                            : false,
                    deal_participant_id: this.dealParticipants.escrow_agent.deal_participant_id,
                    added_by__addon_id: this.dealParticipants.escrow_agent.added_by__addon_id
                }
            ];
            this.selectedEscrowAgentIds = [];
        }
    }

    private fillSellersIds() {
        if (!this.dealParticipants['sellers']) {
            return;
        }

        this.selectedSellersIds = [];
        this.textParticipantsSellers = [];

        this.dealParticipants['sellers'].forEach((agent) => {
            if (agent.format === DEAL_PARTICIPANT_FORMAT.contact) {
                this.selectedSellersIds.push({
                    id: agent.contact_person_fk_id as number,
                    fake_title: agent.link_title as string
                });
            } else if (agent.format === DEAL_PARTICIPANT_FORMAT.text) {
                this.textParticipantsSellers.push({
                    fake_title: agent.link_title as string,
                    format: agent.format,
                    is_ignored: agent.is_ignored !== null ? agent.is_ignored : false,
                    deal_participant_id: agent.deal_participant_id,
                    added_by__addon_id: agent.added_by__addon_id
                });
            }
        });
    }

    private fillBuyersIds() {
        if (!this.dealParticipants['buyers']) {
            return;
        }

        this.selectedBuyersIds = [];
        this.textParticipantsBuyers = [];

        this.dealParticipants['buyers'].forEach((agent) => {
            if (agent.format === DEAL_PARTICIPANT_FORMAT.contact) {
                this.selectedBuyersIds.push({
                    id: agent.contact_person_fk_id as number,
                    fake_title: agent.link_title as string
                });
            } else if (agent.format === DEAL_PARTICIPANT_FORMAT.text) {
                this.textParticipantsBuyers.push({
                    fake_title: agent.link_title as string,
                    format: agent.format,
                    is_ignored: agent.is_ignored !== null ? agent.is_ignored : false,
                    deal_participant_id: agent.deal_participant_id,
                    added_by__addon_id: agent.added_by__addon_id
                });
            }
        });
    }

    private fillOthersIds() {
        if (!this.dealParticipants['others']) {
            return;
        }

        this.selectedOthersIds = [];
        this.textParticipantsOthers = [];

        this.dealParticipants['others'].forEach((agent) => {
            if (agent.format === DEAL_PARTICIPANT_FORMAT.contact) {
                this.selectedOthersIds.push({
                    id: agent.contact_person_fk_id as number,
                    fake_title: agent.link_title as string
                });
            } else if (agent.format === DEAL_PARTICIPANT_FORMAT.text) {
                this.textParticipantsOthers.push({
                    fake_title: agent.link_title as string,
                    format: agent.format,
                    is_ignored: agent.is_ignored !== null ? agent.is_ignored : false,
                    deal_participant_id: agent.deal_participant_id,
                    added_by__addon_id: agent.added_by__addon_id
                });
            }
        });
    }

    toggleVisibility(
        event: {id: number; is_ignored: boolean},
        dealParticipantType:
            | 'buyer_agents'
            | 'seller_agents'
            | 'escrow_agent'
            | 'buyers'
            | 'sellers'
            | 'referring_from_agent'
            | 'referring_to_agent'
            | 'others'
            | 'commission_payer'
    ) {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            data: {
                title: event.is_ignored ? 'Disabling' : 'Making visible',
                message: `Are you sure you want to ${event.is_ignored ? 'disable' : 'make visible'} this participant?`,
                buttonOkMessage: 'Continue'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((toggle) => {
                if (!toggle || !this.dealId) {
                    return;
                }

                this.dealService
                    .toggleTextDealParticipantVisibility(this.dealId, event.id, event.is_ignored)
                    .then((result: boolean) => {
                        if (!result) {
                            return;
                        }
                        this.toggleVisibilityParticipantsUI(event, dealParticipantType);
                        this.notificationServiceZipi.addInfo(
                            `Deal participant is ${event.is_ignored ? 'disabled' : 'visible'}`
                        );
                    });
            });
    }

    toggleVisibilityParticipantsUI(
        event: {id: number; is_ignored: boolean},
        dealParticipantType:
            | 'buyer_agents'
            | 'seller_agents'
            | 'escrow_agent'
            | 'buyers'
            | 'sellers'
            | 'referring_from_agent'
            | 'referring_to_agent'
            | 'others'
            | 'commission_payer'
    ) {
        if (!this.dealParticipants[dealParticipantType]) {
            return;
        }

        if (Array.isArray(this.dealParticipants[dealParticipantType])) {
            (this.dealParticipants[dealParticipantType] as IContactPartLink[]).forEach((dp) => {
                if (dp.deal_participant_id === event.id) {
                    dp.is_ignored = !dp.is_ignored;
                }
            });
        } else {
            (this.dealParticipants[dealParticipantType] as IContactPartLink).is_ignored = !(
                this.dealParticipants[dealParticipantType] as IContactPartLink
            ).is_ignored;
        }

        this.update({
            [dealParticipantType]: this.dealParticipants[dealParticipantType]
        });

        if (dealParticipantType === 'seller_agents') {
            this.fillSellerAgentIds();
        } else if (dealParticipantType === 'buyer_agents') {
            this.fillBuyerAgentIds();
        } else if (dealParticipantType === 'referring_from_agent') {
            this.fillReferringFromAgentIds();
        } else if (dealParticipantType === 'referring_to_agent') {
            this.fillReferringToAgentIds();
        } else if (dealParticipantType === 'escrow_agent') {
            this.fillEscrowAgentIds();
        } else if (dealParticipantType === 'sellers') {
            this.fillSellersIds();
        } else if (dealParticipantType === 'buyers') {
            this.fillBuyersIds();
        } else if (dealParticipantType === 'others') {
            this.fillOthersIds();
        }
    }

    async contactCreateFromText(
        event: {id: number; addon_title?: string},
        dealParticipantType:
            | 'buyer_agents'
            | 'seller_agents'
            | 'escrow_agent'
            | 'buyers'
            | 'sellers'
            | 'referring_from_agent'
            | 'referring_to_agent'
            | 'others'
            | 'commission_payer',
        metatype: string | null
    ) {
        let textDealParticipant: IContactPartLink | null | undefined = null;
        if (['seller_agents', 'buyer_agents', 'sellers', 'buyers', 'others'].includes(dealParticipantType)) {
            textDealParticipant = (this.dealParticipants[dealParticipantType] as IContactPartLink[]).find(
                (dp) => dp.deal_participant_id === event.id
            );
        } else if (['referring_from_agent', 'referring_to_agent', 'escrow_agent'].includes(dealParticipantType)) {
            textDealParticipant = this.dealParticipants[dealParticipantType] as IContactPartLink;
        }
        if (!textDealParticipant) {
            throw new Error('text deal participant is not found');
        }
        const splitName = textDealParticipant.link_title!.split(' ');

        // select converting type - create or update existing contact
        const dialogRef = this.dialog.open(AssociateContactDialogComponent, {
            minWidth: 320,
            autoFocus: false,
            data: {
                hideCancel: false,
                contactWithoutPartnerCompany: false,
                title: 'Convert to contact',
                placeholder: 'Choose existing contact',
                addonTitle: event.addon_title,
                metatype: metatype,
                availableTypes: dealParticipantType === 'escrow_agent' ? ['contact'] : ['contact_person']
            }
        });

        // wait answer on closing dialog
        const contactId = await dialogRef.afterClosed().toPromise();
        if (contactId === false || contactId === undefined) {
            return;
        }

        // get contact info for transformation (contact was selected from existing)
        let transformToContact = null;
        if (contactId && dealParticipantType === 'escrow_agent' && this.isAllowedContacts) {
            const contact = await this.contactsService.getContactById(contactId).toPromise();
            if (!contact) {
                return;
            }
            transformToContact = {
                contact_id: contact.contact_id,
                contact_person_fk_id: null,
                link_title: contact.display_name
            };
        } else if (contactId && dealParticipantType !== 'escrow_agent' && this.isAllowedContacts) {
            const persons = await this.contactsService.getContactPersonsById([contactId]).toPromise();
            if (!persons || !persons.length) {
                return;
            }
            transformToContact = {
                // contact_id: persons[0].target_id,
                contact_id: persons[0].id,
                contact_person_fk_id: persons[0].contact_persons?.length
                    ? persons[0].contact_persons[0]?.contact_person_id
                    : null,
                link_title: persons[0].label
            };
            // contact is creating
        } else {
            // get contactTypes from sessionService variable
            let contactTypes = this.sessionService.contactTypes;

            // if no contactTypes - get them from backend and save in currentProfileSource
            if (this.sessionService.contactTypes.length === 0) {
                contactTypes = await this.contactTypeService.getContactTypeListAsPromise();
                this.currentProfileSource.contactTypes.next(contactTypes);
            }
            const contactMetatypeId = contactTypes.find((ct) => ct.metatype === metatype)?.id;

            // insert text Deal Participant inside selected contact
            const dialogRef2 = this.dialog.open(ContactCreateDialogComponent, {
                width: '90vw',
                autoFocus: false,
                data: {
                    contact: {
                        display_name: textDealParticipant.link_title,
                        contact_type_ids: metatype ? [contactMetatypeId] : null,
                        contact_persons: [
                            {
                                contact_id: null,
                                type: 'main_person',
                                first_name: splitName[0],
                                last_name: splitName.length > 1 ? splitName[1] : '',
                                preferred_name: textDealParticipant.link_title,
                                full_name: textDealParticipant.link_title
                            }
                        ]
                    },
                    isCompany: dealParticipantType === 'escrow_agent'
                }
            });

            // wait answer on closing dialog
            const result: {created: IContact} = await dialogRef2.afterClosed().toPromise();
            if (
                !result ||
                !result['created'] ||
                !result['created'].contact_persons ||
                typeof result['created'].contact_id === 'undefined'
            ) {
                return;
            }
            transformToContact = {
                contact_id: result['created'].contact_id,
                contact_person_fk_id:
                    dealParticipantType === 'escrow_agent'
                        ? null
                        : result['created'].contact_persons.find((cp) => cp.type === 'main_person')
                              ?.contact_person_id || null,
                link_title:
                    dealParticipantType === 'escrow_agent'
                        ? result['created'].display_name
                        : result['created'].contact_persons.find((cp) => cp.type === 'main_person')?.full_name || ''
            };
        }

        this.replaceTextParticipantWithContact(
            // @ts-ignore - contact_id cannot be undefined and it's verified
            transformToContact,
            event.id,
            textDealParticipant.link_title,
            dealParticipantType
        );
    }

    replaceTextParticipantWithContact(
        newContact: {contact_id: number; contact_person_fk_id: number | null; link_title: string},
        participantId: number,
        linkTitle: string,
        dealParticipantType:
            | 'buyer_agents'
            | 'seller_agents'
            | 'escrow_agent'
            | 'buyers'
            | 'sellers'
            | 'referring_from_agent'
            | 'referring_to_agent'
            | 'others'
            | 'commission_payer'
    ) {
        let participants: IContactPartLink | IContactPartLink[] | null = null;
        // for multiple-selected
        if (['seller_agents', 'buyer_agents', 'sellers', 'buyers', 'others'].includes(dealParticipantType)) {
            participants = [];
            if (
                this.dealParticipants[dealParticipantType] &&
                (this.dealParticipants[dealParticipantType] as IContactPartLink[]).length
            ) {
                participants = (this.dealParticipants[dealParticipantType] as IContactPartLink[]).slice();
            }
            participants.forEach((p) => {
                if (p.deal_participant_id === participantId) {
                    p.contact_id = newContact.contact_id;
                    p.contact_person_fk_id = newContact.contact_person_fk_id;
                    p.link_title = newContact.link_title;
                    p.format = DEAL_PARTICIPANT_FORMAT.contact;
                    p.is_ignored = undefined;
                }
            });
            // for single-selected
        } else if (['referring_from_agent', 'referring_to_agent', 'escrow_agent'].includes(dealParticipantType)) {
            participants = Object.assign(this.dealParticipants[dealParticipantType] || {}, {
                contact_id: newContact.contact_id,
                contact_person_fk_id: newContact.contact_person_fk_id,
                link_title: newContact.link_title,
                format: DEAL_PARTICIPANT_FORMAT.contact,
                deal_participant_id: (this.dealParticipants[dealParticipantType] as IContactPartLink)
                    ?.deal_participant_id
            } as IContactPartLink);
        }

        this.update({[dealParticipantType]: participants});

        if (dealParticipantType === 'seller_agents') {
            this.fillSellerAgentIds();
        } else if (dealParticipantType === 'buyer_agents') {
            this.fillBuyerAgentIds();
        } else if (dealParticipantType === 'sellers') {
            this.fillSellersIds();
        } else if (dealParticipantType === 'buyers') {
            this.fillBuyersIds();
        } else if (dealParticipantType === 'others') {
            this.fillOthersIds();
        } else if (dealParticipantType === 'referring_from_agent') {
            this.fillReferringFromAgentIds();
        } else if (dealParticipantType === 'referring_to_agent') {
            this.fillReferringToAgentIds();
        } else if (dealParticipantType === 'escrow_agent') {
            this.fillEscrowAgentIds();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.updateParticipants.complete();
        this.updateDealClientTypeEvent.complete();
        this.updateDealTransactionTypeEvent.complete();
    }
}
