import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../service.requester';
import {Observable} from 'rxjs';
import {GatewayTypes, ILedgerAccount} from '../../../../typings';
import {IScrollData} from '../../../models/scroll-data';

@Injectable()
export class LedgerAccountService {
    private url = '/finance/ledger-accounts/';

    constructor(public requester: ServiceRequester) {}

    getLedgerAccounts(): Observable<ILedgerAccount[]> {
        return this.requester.makeMsCall$(this.url + 'methods', 'GET', 'shipp');
    }

    getBankingLedgerAccounts(data: IScrollData): Observable<{result: ILedgerAccount[]; _meta: {total: number}}> {
        return this.requester.makeMsCall$(this.url + 'banking', 'GET', 'shipp', data);
    }

    getAvailableLedgerAccountsForFundingSourceByGatewayType(gatewayType: GatewayTypes): Observable<ILedgerAccount[]> {
        return this.requester.makeMsCall$(this.url + 'availablebank/' + gatewayType, 'GET', 'shipp');
    }

    getLedgerAccountById(id: number): Observable<ILedgerAccount & {has_matched_transactions: boolean}> {
        return this.requester.makeMsCall$(this.url + id, 'GET', 'shipp');
    }

    getLedgerAccountBalanceById(id: number): Observable<{zipi_balance: number}> {
        return this.requester.makeMsCall$(this.url + id + '/zipibalance', 'GET', 'shipp');
    }

    getAvailableForBankingLedgerAccounts(type: 'trust' | 'bank'): Observable<ILedgerAccount[]> {
        return this.requester.makeMsCall$(this.url + 'availablebanking/' + type, 'GET', 'shipp');
    }

    getCompanyLedgerAccountForDefaultPaymentMethod(): Observable<ILedgerAccount> {
        return this.requester.makeMsCall$(this.url + 'la-for-default-payments', 'GET', 'shipp');
    }

    checkForConnectedTransactions(ledgerAccountId: number): Observable<boolean> {
        return this.requester.makeMsCall$(
            this.url + ledgerAccountId + '/check-for-connected-transaction',
            'GET',
            'shipp'
        );
    }

    createLedgerAccount(data: ILedgerAccount): Observable<ILedgerAccount> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    updateLedgerAccount(data: ILedgerAccount): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + data.id, 'PUT', 'shipp', data);
    }

    deleteLedgerAccount(data: number): Observable<any> {
        return this.requester.makeMsCall$(this.url + data, 'DELETE', 'shipp');
    }

    deactivateLedgerAccount(data: number): Observable<any> {
        return this.requester.makeMsCall$(this.url + data + '/deactivate', 'PUT', 'shipp');
    }

    reactivateLedgerAccount(data: number): Observable<any> {
        return this.requester.makeMsCall$(this.url + data + '/reactivate', 'PUT', 'shipp');
    }

    disconnectFeed(data: number): Observable<any> {
        return this.requester.makeMsCall$(this.url + data + '/disconnectfeed', 'PUT', 'shipp');
    }

    updateActivateFeedDate(id: number, data: {activate_feed_from: any}): Observable<any> {
        return this.requester.makeMsCall$(this.url + id + '/activatefeed', 'PUT', 'shipp', data);
    }

    createOpeningBalance(data: {date: number; items: any}): Observable<any> {
        return this.requester.makeMsCall$(this.url + `openingbalances`, 'POST', 'shipp', {data: data});
    }

    updateOpeningBalance(data: {date: number; items: any}): Observable<any> {
        return this.requester.makeMsCall$(this.url + `openingbalances`, 'PUT', 'shipp', {data: data});
    }

    deleteOpeningBalance(): Observable<any> {
        return this.requester.makeMsCall$(this.url + `openingbalances`, 'DELETE', 'shipp');
    }

    getOpeningBalanceLedgerAccountsTransactions(): Observable<any> {
        return this.requester.makeMsCall$(this.url + `openingbalances`, 'GET', 'shipp');
    }

    getOpeningBalanceLedgerAccountTransactionsById(ledgerAccountId: number): Observable<{
        opening_balance_date: number;
        debit_or_credit: 'debit' | 'credit';
        debit: number;
        credit: number;
        total: number;
    }> {
        return this.requester.makeMsCall$(this.url + `openingbalances/` + ledgerAccountId, 'GET', 'shipp');
    }
}
