export class FinancialSource {
    label: string = '';
    amount: number = 0;
    notes: string = '';
    show_in_disbursement_pdf: boolean = false;

    setLabel(label: string) {
        this.label = label;
        return this;
    }

    setAmount(amount: number) {
        this.amount = amount;
        return this;
    }

    setNotes(notes: string) {
        this.notes = notes;
        return this;
    }

    setShowInDisbursementPDF(show: boolean) {
        this.show_in_disbursement_pdf = show;
        return this;
    }
}
