import {Component, Input, OnInit} from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';

@Component({
    selector: 'reports-preview-balance-sheet',
    templateUrl: './balance-sheet.component.html',
    styleUrls: ['./balance-sheet.component.scss']
})
export class BalanceSheetReportPreviewComponent implements OnInit {
    @Input() report: any;
    @Input()
    set reportData(data: any) {
        this._reportData = data;

        this.assetDataSource.data = this._reportData!.asset;
        this.liabilityDataSource.data = this._reportData!.liability;
        this.equityDataSource.data = this._reportData!.equity;

        this.treeControlAsset.dataNodes = this._reportData!.asset;
        this.treeControlLiability.dataNodes = this._reportData!.liability;
        this.treeControlEquity.dataNodes = this._reportData!.equity;
    }

    get reportData() {
        return this._reportData;
    }

    _reportData: {
        asset: Array<any>;
        equity: Array<any>;
        liability: Array<any>;
        totals: {
            asset: number;
            liability: number;
            equity: number;
            liability_and_equity: number;
        };
    } | null = null;

    assetDataSource = new MatTreeNestedDataSource<any>();
    liabilityDataSource = new MatTreeNestedDataSource<any>();
    equityDataSource = new MatTreeNestedDataSource<any>();
    treeControlLiability = new NestedTreeControl<any>((node) => node.children);
    treeControlAsset = new NestedTreeControl<any>((node) => node.children);
    treeControlEquity = new NestedTreeControl<any>((node) => node.children);
    isExpand: boolean = false;
    expandTooltip: string = 'Expand All';

    ngOnInit(): void {
        this.toggleExpand();
    }

    hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

    changeState(node: {expanded: boolean}) {
        node.expanded = !node.expanded;
    }

    toggleExpand() {
        if (this.isExpand) {
            this.treeControlAsset.collapseAll();
            this.treeControlEquity.collapseAll();
            this.treeControlLiability.collapseAll();
            this.expandTooltip = 'Expand All';
        } else {
            this.treeControlAsset.expandAll();
            this.treeControlEquity.expandAll();
            this.treeControlLiability.expandAll();
            this.expandTooltip = 'Collapse All';
        }
        this.isExpand = !this.isExpand;
    }
}
