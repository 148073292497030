import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {EntityPickerAdvancedComponent} from '../../../../../../components/entity-picker-advanced/entity-picker-advanced.component';
import {GenericFormArray, GenericFormGroup} from '../../../../../../entites/generic.entity';
import {SalesEntity} from '../../../../../../models/sales-entity';
import {FinancialTransferEntity} from '../../../../../account-info/compensation/models/financial-transfer.entity';
import {ChipNode} from '../../../../../account-info/compensation/models/chip-node';
import {DealProcessingService} from '../../deal-processing.service';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {ContactPartLink} from '../../../../../../models/contact-part-link';
import {numberWithNegativeMaskitoOptions} from '../../../../../../utilities/maskito';

interface ICustomEntity {
    role: 'agent' | 'transfer' | 'referral';
    label: string;
    target_id: number;
    percent: number;
    primary: boolean;
    sales_entity_type: string;
    added_automatic: boolean;
    financialTransferFG: GenericFormGroup<FinancialTransferEntity>;
    salesEntityFD: GenericFormGroup<SalesEntity> | null;
}

@Component({
    selector: 'app-manage-sales-entity-block',
    templateUrl: './manage-sales-entity-block.component.html',
    styleUrls: ['../deal-creation.scss']
})
export class ManageSalesEntityBlockComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() set salesEntities(salesEntities: GenericFormGroup<SalesEntity>[]) {
        this.initialSalesEntities = [...salesEntities];
        this.updatedCustomEntities = this.initialSalesEntities.map((se) => {
            // set label
            let label = se.getRawValue(this.SALES_ENTITY).contact.display_name;
            if (
                se.controls.type!.value === this.SALES_ENTITY.type_SET.profile &&
                !se.controls.is_primary!.value &&
                this.hasReferralTransfers(se)
            ) {
                label += ' (REFERRAL)';
            }
            if (
                (se.controls.type!.value === this.SALES_ENTITY.type_SET.profile &&
                    se.getRawValue().profile?.deleted_at) ||
                (se.controls.type!.value === this.SALES_ENTITY.type_SET.company_group &&
                    se.getRawValue().company_group?.deleted_at) ||
                (se.controls.type!.value === this.SALES_ENTITY.type_SET.contact && se.getRawValue().contact?.deleted_at)
            ) {
                label += ' (deleted)';
            }
            // set financial transfer
            let financialTransferFG = null;
            if (se.controls.role!.value === this.SALES_ENTITY.role_SET.agent) {
                financialTransferFG = this.dealProcessing.dealFG.controls
                    .financial_transfers!.controls.filter(
                        (ft) => ft.controls.type!.value === FinancialTransferEntity.type_SET.participant_split
                    )
                    .find(
                        (ft) =>
                            // @ts-ignore
                            ft.controls.receiver!.controls.contact_id.value ===
                            se.controls.contact_part_link!.controls.contact_id!.value
                    );
            }

            return {
                role: se.controls.role!.value,
                label: label,
                target_id:
                    se.getRawValue().profile?.id || se.getRawValue().company_group?.id || se.getRawValue().contact?.id,
                percent: se.controls.participant_split!.controls.percent!.value,
                primary: se.controls.is_primary!.value,
                sales_entity_type: se.controls.type!.value,
                added_automatic: se.controls.added_automatic!.value,
                financialTransferFG: financialTransferFG,
                salesEntityFD: se,
                sales_volume_percent: se.controls.sales_volume_percent?.value,
                side_count: se.controls.side_count?.value
            };
        });
        this.initialGroupIds = salesEntities
            .filter((entity) => entity.controls.type!.value === 'company_group')
            .reduce((acc, curr) => {
                // @ts-ignore
                acc.push(curr.controls.company_group.controls.id!.value);
                return acc;
            }, []);
        this.initialProfileIds = salesEntities
            .filter((entity) => entity.controls.type!.value === 'profile')
            .reduce((acc, curr) => {
                // @ts-ignore
                acc.push(curr.controls.profile.controls.id!.value);
                return acc;
            }, []);
        this.initialContactIds = salesEntities
            .filter((entity) => entity.controls.type!.value === 'contact')
            .reduce((acc, curr) => {
                // @ts-ignore
                acc.push(curr.controls.contact.controls.id!.value);
                return acc;
            }, []);
    }
    @Input() showSideCountAndSalesVolumeFlag: boolean = false;

    @ViewChild('entityPicker') entityPicker: EntityPickerAdvancedComponent | undefined;
    @ViewChildren('dealSplitInputs') dealSplitInputs: QueryList<ElementRef> | undefined;
    @ViewChildren('salesVolumeInputs') salesVolumeInputs: QueryList<ElementRef> | undefined;
    @ViewChildren('sideCountInputs') sideCountInputs: QueryList<ElementRef> | undefined;

    VALIDATION_TYPES = {
        sum: 'sum',
        sumNonPrimaryAgents: 'sumNonPrimaryAgents',
        negativeNumbers: 'negativeNumbers',
        salesVolumeSum: 'salesVolumeSum',
        sideCountSum: 'sideCountSum'
    };
    validateSalesManagerBS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    @Output() validateSalesManager: EventEmitter<boolean> = new EventEmitter<boolean>();

    initialSalesEntities: GenericFormGroup<SalesEntity>[] = [];
    updatedCustomEntities: {
        salesEntityFD: GenericFormGroup<SalesEntity> | null;
        sales_entity_type: any;
        role: any;
        financialTransferFG: any;
        target_id: number | null | undefined;
        label: any;
        percent: any;
        primary: any;
        added_automatic: any;
        sales_volume_percent: number;
        side_count: number;
    }[] = [];
    deletedEntities: {
        salesEntityFD: GenericFormGroup<SalesEntity> | null;
        sales_entity_type: any;
        role: any;
        financialTransferFG: any;
        target_id: number | null | undefined;
        label: any;
        percent: any;
        primary: any;
        added_automatic: any;
    }[] = [];

    selectedSalesForm: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    initialProfileIds: number[] = [];
    initialGroupIds: number[] = [];
    initialContactIds: number[] = [];

    public FINANCIAL_TRANSFER_ENTITY = FinancialTransferEntity;
    public SALES_ENTITY = SalesEntity;
    numberMask = numberWithNegativeMaskitoOptions;
    hideSideCountInput: boolean[] = [];

    constructor(public dealProcessing: DealProcessingService) {}

    ngOnInit() {
        this.selectedSalesForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((chipNodes) => {
            // add entity and create financial transfer for it
            const added = chipNodes.find(
                (n: ChipNode) => !this.updatedCustomEntities.map((i) => i.target_id).includes(n.target_id)
            );
            if (!added) {
                return;
            }

            const contactPartLink = new ContactPartLink()
                .setContactId(added.contact_id)
                .setLinkTitle(added.contact_name);
            const primaryExists = this.updatedCustomEntities.find((e) => e.primary);
            let financialTransferFG;
            if (primaryExists) {
                financialTransferFG = this.dealProcessing.createNewFinancialTransfer(contactPartLink, 0);
            } else {
                financialTransferFG = this.dealProcessing.createNewFinancialTransfer(contactPartLink, 100);
            }

            this.hideSideCountInput.push(true);

            this.updatedCustomEntities.push({
                role: 'agent',
                label: added.label,
                target_id: added.target_id,
                percent: primaryExists ? 0 : 100,
                primary: !primaryExists,
                sales_entity_type:
                    added.type === ChipNode.type_SET.individual ? this.SALES_ENTITY.type_SET.profile : added.type,
                added_automatic: false,
                financialTransferFG: financialTransferFG,
                salesEntityFD: null,
                sales_volume_percent: primaryExists ? 0 : 100,
                side_count: primaryExists ? 0 : 1
            });
            this.manageSalesEntities();
        });

        this.validateSalesManagerBS.pipe(debounceTime(200), takeUntil(this.unsubscribe)).subscribe((result) => {
            this.validateSalesManager.emit(!result);
        });
    }

    manageSalesEntities() {
        this.updatedCustomEntities.forEach((se, idx) => {
            // replace percentage in real financial transfers
            if (se.role === this.SALES_ENTITY.role_SET.agent) {
                se.financialTransferFG.controls.percent!.setValue(this.updatedCustomEntities[idx].percent);
            }
            // find agent transforming into transfer, by having participant_split
            if (
                se.role === this.SALES_ENTITY.role_SET.transfer &&
                se.salesEntityFD!.controls.participant_split!.controls?.id?.value
            ) {
                // delete transfer
                const f_t = this.dealProcessing.dealFG.controls.financial_transfers!.controls.find(
                    (ft) => ft.controls.id!.value === se.salesEntityFD!.controls.participant_split!.controls.id!.value
                );
                this.dealProcessing.deleteFinancialTransfer(f_t!);
            }
        });

        // add
        // if in deal-processing contacts list is empty - here will be strange errors
        if (this.updatedCustomEntities.length) {
            for (const ft of this.updatedCustomEntities.filter((i) => i.role === this.SALES_ENTITY.role_SET.agent)) {
                if (
                    !this.dealProcessing.dealFG.controls.financial_transfers!.controls.find((ftFg) => {
                        const existingTransfer = ftFg.getRawValue();
                        const transferToAdd = ft.financialTransferFG.getRawValue();
                        return (
                            existingTransfer.type === FinancialTransferEntity.type_SET.participant_split &&
                            existingTransfer.receiver!.contact_id === transferToAdd.receiver!.contact_id
                        );
                    })
                ) {
                    this.dealProcessing.addFinancialTransfer(ft.financialTransferFG);
                }
            }
        }

        // update primary sales entity, if neededZ
        this.checkPrimarySalesEntity();

        // update additional inputs
        this.updatedCustomEntities.forEach((se, idx) => {
            if (se.role === this.SALES_ENTITY.role_SET.agent) {
                const currentSE = this.dealProcessing.dealFG.controls.sales_entities?.controls.find(
                    (ise) =>
                        (ise.controls.type?.value === SalesEntity.type_SET.profile &&
                            ise.controls.profile?.controls.id?.value === se.target_id) ||
                        (ise.controls.type?.value === SalesEntity.type_SET.company_group &&
                            ise.controls.company_group?.controls.id?.value === se.target_id)
                );
                if (currentSE) {
                    // update side count
                    currentSE.controls.side_count?.patchValue(se.side_count);
                    // update sales volume
                    currentSE.controls.sales_volume_percent?.patchValue(se.sales_volume_percent);
                }
            }
        });

        // remove
        for (const entity of this.deletedEntities) {
            this.dealProcessing.dealFG.controls.financial_transfers!.controls =
                this.dealProcessing.dealFG.controls.financial_transfers!.controls.filter((transferFG) => {
                    const transfer = transferFG.getRawValue(FinancialTransferEntity);
                    // @ts-ignore
                    if (
                        transfer.receiver &&
                        transfer.receiver.contact_id ===
                            entity.financialTransferFG.controls.receiver!.controls.contact_id.value
                    ) {
                        return false;
                    }
                    return true;
                });
            this.dealProcessing.dealFG.controls.sales_entities!.controls =
                this.dealProcessing.dealFG.controls.sales_entities!.controls.filter(
                    (seFg) =>
                        // @ts-ignore
                        seFg.controls.contact_part_link!.controls.contact_id!.value !==
                        entity.financialTransferFG.controls.receiver!.controls.contact_id.value
                );
        }
        this.deletedEntities = [];

        this.dealProcessing.doRefresh({});
    }

    validation(type: string) {
        const result =
            this.validateSum() ||
            this.validateNegativeNumbers() ||
            this.validateSalesVolumeSum() ||
            this.validateSideCountSum();
        if (this.validateSalesManagerBS.value !== result) {
            this.validateSalesManagerBS.next(result);
        }

        switch (type) {
            case this.VALIDATION_TYPES.sum:
                return this.validateSum();
            case this.VALIDATION_TYPES.negativeNumbers:
                return this.validateNegativeNumbers();
            case this.VALIDATION_TYPES.salesVolumeSum:
                return this.validateSalesVolumeSum();
            case this.VALIDATION_TYPES.sideCountSum:
                return this.validateSideCountSum();
        }
    }

    validateSum(): boolean {
        const amount = this.updatedCustomEntities.reduce((acc, cur, idx) => {
            return acc + (cur.role === this.SALES_ENTITY.role_SET.agent ? Number(cur.percent?.toFixed(2)) : 0);
        }, 0);
        return Number(amount.toFixed(2)) !== 100;
    }
    validateNegativeNumbers(): boolean {
        // side count values and sales volume values should be positive
        return !this.updatedCustomEntities.every(
            (e) => e.percent >= 0 && e.sales_volume_percent >= 0 && e.side_count >= 0
        );
    }
    validateSalesVolumeSum(): boolean {
        const amount = this.updatedCustomEntities.reduce((acc, cur, idx) => {
            return (
                acc + (cur.role === this.SALES_ENTITY.role_SET.agent ? Number(cur.sales_volume_percent?.toFixed(2)) : 0)
            );
        }, 0);
        return Number(amount.toFixed(2)) !== 100;
    }
    validateSideCountSum(): boolean {
        const amount = this.updatedCustomEntities.reduce((acc, cur, idx) => {
            return acc + (cur.role === this.SALES_ENTITY.role_SET.agent ? Number(cur.side_count?.toFixed(2)) : 0);
        }, 0);
        return Number(amount.toFixed(2)) !== 1;
    }

    removeOptionFromArray(index: number) {
        this.deletedEntities.push(this.updatedCustomEntities[index]);
        const pickerIndex = this.selectedSalesForm.controls.findIndex(
            (chip) => chip.controls.target_id!.value === this.updatedCustomEntities[index].target_id
        );
        if (pickerIndex > -1) {
            this.entityPicker!.nodesFA.removeAt(pickerIndex);
        }
        this.updatedCustomEntities.splice(index, 1);
        this.recalculateSumPrimaryAgent();
        this.recalculateSumSalesVolume();
        this.recalculateSumSideCount();
    }

    recalculateSumPrimaryAgent(i: number | undefined = undefined, event: any = undefined) {
        if (typeof i !== 'undefined' && typeof event !== 'undefined' && event !== null && this.dealSplitInputs) {
            if (event.target.value === '0._') {
                this.updatedCustomEntities[i].percent = 0;
                this.dealSplitInputs.toArray()[i].nativeElement.selectionStart = 2;
                this.dealSplitInputs.toArray()[i].nativeElement.selectionEnd = 2;
            } else {
                this.updatedCustomEntities[i].percent = Number(event.target.value);
            }
        }
        let primaryAgentIndex = this.updatedCustomEntities.findIndex((se) => se.primary);
        if (primaryAgentIndex === -1) {
            primaryAgentIndex = 0;
        }

        const amount = this.updatedCustomEntities.reduce((acc, cur, idx) => {
            return (
                acc +
                (cur.role === this.SALES_ENTITY.role_SET.agent && idx !== primaryAgentIndex
                    ? Number(cur.percent.toFixed(2))
                    : 0)
            );
        }, 0);
        this.updatedCustomEntities[primaryAgentIndex].percent = Number((100 - amount).toFixed(2));
        this.manageSalesEntities();
    }

    recalculateSumSalesVolume(i: number | undefined = undefined, event: any | undefined = undefined) {
        // set new number to proper agent
        if (typeof i !== 'undefined' && typeof event !== 'undefined' && event !== null && this.salesVolumeInputs) {
            if (event.target.value === '0._') {
                this.updatedCustomEntities[i].sales_volume_percent = 0;
                this.salesVolumeInputs.toArray()[i].nativeElement.selectionStart = 2;
                this.salesVolumeInputs.toArray()[i].nativeElement.selectionEnd = 2;
            } else {
                this.updatedCustomEntities[i].sales_volume_percent = Number(event.target.value);
            }
        }
        // find primary agent
        let primaryAgentIndex = this.updatedCustomEntities.findIndex((se) => se.primary);
        if (primaryAgentIndex === -1) {
            primaryAgentIndex = 0;
        }
        // sum of all non-primary agents
        const amount = this.updatedCustomEntities.reduce((acc, cur, idx) => {
            return (
                acc +
                (cur.role === this.SALES_ENTITY.role_SET.agent && idx !== primaryAgentIndex
                    ? Number(cur.sales_volume_percent?.toFixed(2))
                    : 0)
            );
        }, 0);
        // update number of primary agent
        this.updatedCustomEntities[primaryAgentIndex].sales_volume_percent = Number((100 - amount).toFixed(2));
        this.manageSalesEntities();
    }
    recalculateSumSideCount(i: number | undefined = undefined, event: any | undefined = undefined) {
        // set new number to proper agent
        if (typeof i !== 'undefined' && typeof event !== 'undefined' && event !== null && this.sideCountInputs) {
            if (event.target.value === '0._') {
                this.updatedCustomEntities[i].side_count = 0;
                this.sideCountInputs.toArray()[i].nativeElement.selectionStart = 2;
                this.sideCountInputs.toArray()[i].nativeElement.selectionEnd = 2;
            } else {
                this.updatedCustomEntities[i].side_count = Number(event.target.value);
            }
        }
        // find primary agent
        let primaryAgentIndex = this.updatedCustomEntities.findIndex((se) => se.primary);
        if (primaryAgentIndex === -1) {
            primaryAgentIndex = 0;
        }
        // sum of all non-primary agents
        const amount = this.updatedCustomEntities.reduce((acc, cur, idx) => {
            return (
                acc +
                (cur.role === this.SALES_ENTITY.role_SET.agent && idx !== primaryAgentIndex
                    ? Number(cur.side_count?.toFixed(2))
                    : 0)
            );
        }, 0);
        // update number of primary agent
        this.updatedCustomEntities[primaryAgentIndex].side_count = Number((1 - amount).toFixed(2));
        this.manageSalesEntities();
    }

    doMakePrimary(index: number) {
        this.updatedCustomEntities.forEach((ft, idx) => {
            // remove primary from previous owner
            if (ft.primary) {
                ft.primary = false;
            }
            // add primary to selected agent
            if (idx === index) {
                ft.primary = true;
            }
        });
        this.manageSalesEntities();
    }

    checkPrimarySalesEntity() {
        const newPrimary = this.updatedCustomEntities.find((ft) => ft.primary);
        const oldPrimaryEntity = this.dealProcessing.dealFG.controls.sales_entities!.controls.find(
            (se) => se.controls.is_primary!.value
        );
        const currentPrimaryEntityForUpdate = this.dealProcessing.dealFG.controls.sales_entities!.controls.find(
            (se) =>
                se.controls.profile?.controls?.id!.value === newPrimary!.target_id ||
                se.controls.company_group?.controls?.id!.value === newPrimary!.target_id ||
                se.controls.contact?.controls?.id!.value === newPrimary!.target_id
        );

        // check if need to update primary sales entity
        const checkPrimaryIsTheSame =
            oldPrimaryEntity &&
            oldPrimaryEntity.controls.profile &&
            oldPrimaryEntity.controls.profile.controls.id &&
            (oldPrimaryEntity.controls.profile?.controls?.id.value === newPrimary!.target_id ||
                oldPrimaryEntity.controls.company_group?.controls?.id!.value === newPrimary!.target_id ||
                oldPrimaryEntity.controls.contact?.controls?.id!.value === newPrimary!.target_id);

        // update primary sales entity
        if (currentPrimaryEntityForUpdate && !checkPrimaryIsTheSame) {
            // update new primary se
            currentPrimaryEntityForUpdate.controls.is_primary!.patchValue(true, {emitEvent: false});
            currentPrimaryEntityForUpdate.controls.added_automatic!.patchValue(false, {emitEvent: false});
            currentPrimaryEntityForUpdate.controls.show_in_disbursement_pdf!.patchValue(true, {emitEvent: false});
            // it's for case when sales entity was added by compensation profile with type Participant;
            // Such sales entity has financial transfer with type 'participant_split' and added_automatic=true
            // If we make this sales entity primary, compensation profile can stop to apply and transfer will be remove
            // That's way we need to change flag 'added_automatic' to false
            const primarySEParticipantSplit = this.dealProcessing.dealFG.controls.financial_transfers!.controls.find(
                (transferFG) =>
                    // @ts-ignore
                    transferFG.controls.receiver!.controls &&
                    currentPrimaryEntityForUpdate.controls.contact!.controls &&
                    currentPrimaryEntityForUpdate.controls.contact!.controls.id &&
                    // @ts-ignore
                    transferFG.controls.receiver!.controls.contact_id.value ===
                        currentPrimaryEntityForUpdate.controls.contact!.controls.id.value &&
                    transferFG.controls.type!.value === FinancialTransferEntity.type_SET.participant_split
            );
            if (primarySEParticipantSplit && primarySEParticipantSplit.controls.added_automatic!.value === true) {
                primarySEParticipantSplit.controls.added_automatic!.patchValue(false, {emitEvent: false});
            }

            if (oldPrimaryEntity) {
                // update old primary se
                oldPrimaryEntity.controls.is_primary!.patchValue(false, {emitEvent: false});
                oldPrimaryEntity.controls.show_in_disbursement_pdf!.patchValue(false, {emitEvent: false});
                oldPrimaryEntity.controls.added_automatic!.patchValue(false, {emitEvent: false});
            }
        }
    }

    hasReferralTransfers(salesEntityFG: GenericFormGroup<SalesEntity>): boolean {
        return salesEntityFG.controls.additional_incomes!.controls.some(
            (transferFG) => transferFG.controls.type!.value === FinancialTransferEntity.type_SET.referral
        );
    }

    findCompared(item: GenericFormGroup<SalesEntity>, entity: any) {
        return (
            (entity.sales_entity_type === this.SALES_ENTITY.type_SET.profile &&
                item.value.type === this.SALES_ENTITY.type_SET.profile &&
                item.value.profile.id === entity.salesEntityFD.value.profile.id) ||
            (entity.sales_entity_type === this.SALES_ENTITY.type_SET.company_group &&
                item.value.type === this.SALES_ENTITY.type_SET.company_group &&
                item.value.company_group.id === entity.salesEntityFD.value.company_group.id) ||
            (entity.sales_entity_type === this.SALES_ENTITY.type_SET.contact &&
                item.value.type === this.SALES_ENTITY.type_SET.contact &&
                item.value.contact.id === entity.salesEntityFD.value.contact.id)
        );
    }

    toggleSideCount(i: number) {
        this.hideSideCountInput[i] = !this.hideSideCountInput[i];
        setTimeout(() => {
            if (
                typeof this.sideCountInputs !== 'undefined' &&
                typeof this.sideCountInputs.toArray()[i] !== 'undefined'
            ) {
                this.sideCountInputs.toArray()[i].nativeElement.focus();
            }
        });
    }

    onBlurSideCount(i: number) {
        this.hideSideCountInput[i] = !this.hideSideCountInput[i];
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.validateSalesManager.complete();
        this.validateSalesManagerBS.complete();
    }
}
