import {Component, OnInit} from '@angular/core';
import {ProgressService} from './progress.service';

@Component({
    selector: 'app-profile',
    styles: [
        `
            .pw-sidenav-container {
                height: 100%;
            }

            .pw-sidenav {
                width: 250px;
            }

            .pw-sidenav .menu-line {
                padding: 0;
                height: 50px;
            }

            .pw-sidenav .menu-line.is-active {
                background-color: #c5c5c5;
            }

            .pw-sidenav .menu-line:first-of-type {
                height: 70px;
                border-bottom: 1px solid #ccc;
                cursor: pointer;
                outline: 0;
            }

            .pw-sidenav div {
                position: relative;
                font-size: 20px;
                top: 22px;
                padding-left: 15px;
            }

            button {
                width: 100%;
                height: 100%;
                text-align: left;
            }
        `
    ],
    template: `
        <div class="page-content">
            <router-outlet></router-outlet>
        </div>
    `
})
export class ProgressComponent implements OnInit {
    constructor(public progressService: ProgressService) {}

    ngOnInit() {
        this.progressService.api.load();
    }
}
