<div>
    <h2>Fee Schedule</h2>

    <div *ngIf="isLoading && overrides">
        <div *ngFor="let scenario of feeScenarios">
            <div class="mb-1">
                <div class="font-weight-bold">{{ scenario | fromSnakeCase }} (Receive Money)</div>
                <div class="pl-2">
                    Standard Transfer Fee (cap {{ overrides[scenario].incoming.standard_fee_cap | currency }}):
                    {{ overrides[scenario].incoming.standard_fee_percentage }}%
                </div>
                <div class="pl-2">
                    Instant Transfer Fee (cap {{ overrides[scenario].incoming.instant_fee_cap | currency }}):
                    {{ overrides[scenario].incoming.instant_fee_percentage }}%
                </div>
                <div class="mt-1 pl-2">
                    Credit Card Flat Rate Processing: {{ overrides[scenario].incoming.credit_card_fee_percentage }}%
                </div>
            </div>

            <div class="mb-3">
                <div class="font-weight-bold">{{ scenario | fromSnakeCase }} (Send Money)</div>
                <div class="pl-2">
                    Standard Transfer Fee (cap {{ overrides[scenario].outgoing.standard_fee_cap | currency }}):
                    {{ overrides[scenario].outgoing.standard_fee_percentage }}%
                </div>
                <div class="pl-2">
                    Instant Transfer Fee (cap {{ overrides[scenario].outgoing.instant_fee_cap | currency }}):
                    {{ overrides[scenario].outgoing.instant_fee_percentage }}%
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isLoading" class="d-flex w-100 justify-content-center">
        <mat-spinner></mat-spinner>
    </div>

    <div class="d-flex mt-3">
        <span class="sp-spacer"></span>
        <button type="button" mat-raised-button (click)="close()">Ok</button>
    </div>
</div>
