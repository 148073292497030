import {assign} from 'lodash-es';
import {CompanyWideAction, CompanyWideActionType} from './company-wide.actions';
import {SourceOfBusiness} from 'app/models/source-of-business';
import {SubType} from 'app/models/sub-type';
import {SubSource} from 'app/models/sub-source';
import {MembershipOrganization} from 'app/models/membership-organization';
import {ITagCategory, IWildcard} from '@cyberco-nodejs/zipi-typings';

export interface ICompanyWideState {
    sob: SourceOfBusiness[];
    subTypes: SubType[];
    subSources: SubSource[];
    membershipOrganizations: MembershipOrganization[];
    tagCategories: ITagCategory[];
    wildcards: IWildcard[];
}

export const INITIAL_COMPANY_WIDE: ICompanyWideState = {
    sob: [],
    subTypes: [],
    subSources: [],
    membershipOrganizations: [],
    tagCategories: [],
    wildcards: []
};

export function companyWideReducer(
    state: ICompanyWideState = INITIAL_COMPANY_WIDE,
    action: CompanyWideAction
): ICompanyWideState {
    switch (action.type) {
        case CompanyWideActionType.FETCH_SUB_ENTITIES_SUCCESS:
            return assign({}, state, {
                sob: action.payload!.sources,
                subTypes: action.payload!.sub_types,
                subSources: action.payload!.sub_sources
            });

        case CompanyWideActionType.UPDATE_SOB_SUCCESS:
            return assign({}, state, {
                sob: action.payload!
            });

        case CompanyWideActionType.UPDATE_SUB_TYPES_SUCCESS:
            return assign({}, state, {
                subTypes: action.payload!
            });

        case CompanyWideActionType.UPDATE_SUB_SOURCES_SUCCESS:
            return assign({}, state, {
                subSources: action.payload!
            });

        case CompanyWideActionType.FETCH_MEMBERSHIP_ORGANIZATIONS_SUCCESS:
            return assign({}, state, {
                membershipOrganizations: action.payload!
            });

        case CompanyWideActionType.CREATE_MEMBERSHIP_ORGANIZATIONS_SUCCESS:
            return assign({}, state, {
                membershipOrganizations: [...state.membershipOrganizations, action.payload!]
            });

        case CompanyWideActionType.UPDATE_MEMBERSHIP_ORGANIZATIONS_SUCCESS:
            return assign({}, state, {
                membershipOrganizations: state.membershipOrganizations.map((mo) =>
                    mo.id === action.payload!.id ? action.payload! : mo
                )
            });

        case CompanyWideActionType.FETCH_TAG_CATEGORIES_SUCCESS:
            return assign({}, state, {
                tagCategories: action.payload!
            });

        case CompanyWideActionType.CREATE_TAG_CATEGORY_SUCCESS:
            return assign({}, state, {
                tagCategories: [...state.tagCategories, action.payload!]
            });

        case CompanyWideActionType.UPDATE_TAG_CATEGORY_SUCCESS:
            return assign({}, state, {
                tagCategories: state.tagCategories.map((tc) => (tc.id === action.payload!.id ? action.payload! : tc))
            });

        case CompanyWideActionType.DELETE_TAG_CATEGORY_SUCCESS:
            return assign({}, state, {
                tagCategories: state.tagCategories.filter((tc) => tc.id !== action.payload!)
            });

        case CompanyWideActionType.FETCH_WILDCARDS_SUCCESS:
            return assign({}, state, {
                wildcards: action.payload!
            });

        case CompanyWideActionType.CREATE_WILDCARD_SUCCESS:
            return assign({}, state, {
                wildcards: [...state.wildcards, action.payload!]
            });

        case CompanyWideActionType.UPDATE_WILDCARD_SUCCESS:
            return assign({}, state, {
                wildcards: state.wildcards.map((w) =>
                    w.wildcard_id === action.payload!.wildcard_id ? action.payload! : w
                )
            });

        case CompanyWideActionType.DELETE_WILDCARD:
            return assign({}, state, {
                wildcards: state.wildcards.filter((w) => w.wildcard_id !== action.payload!)
            });

        default:
            return state;
    }
}
