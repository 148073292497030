import {Component, Inject, OnInit, ChangeDetectorRef, OnDestroy, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {combineLatest, Subject} from 'rxjs';
import {IBill, IContact, IPaymentMade, IVendorCredit} from '@cyberco-nodejs/zipi-typings';
import {MatTableDataSource} from '@angular/material/table';
import {map, takeUntil, tap} from 'rxjs/operators';
import {BillsService} from 'app/services/api/finance/bills.service';
import {VendorCreditsService} from 'app/services/api/finance/vendor-credits.service';
import {PaymentsMadeService} from '../../../services/payments-made.service';
import {Router} from '@angular/router';
import {FormGroupArray, FormGroupWithFormControls} from '../../../../../typings/common';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';

@Component({
    selector: 'app-apply-credits-to-bill-dialog',
    templateUrl: 'apply-credits-to-bill-dialog.component.html',
    styleUrls: ['apply-credits-to-bill-dialog.component.scss']
})
export class ApplyCreditsToBillDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    dataSource: MatTableDataSource<IVendorCredit>;
    displayedColumns = ['date', 'credit_info', 'credit_number', 'amount', 'actions'];
    excess: number | null = null;

    bill: IBill | undefined;
    contact: IContact | undefined;

    creditsArray: FormGroupArray = this.fb.array([]) as FormGroupArray;
    formGroup: FormGroupWithFormControls = this.fb.group({credits: this.creditsArray}) as FormGroupWithFormControls;

    pendingBalance: number = 0;
    amountFromCredits: number = 0;
    remaining: number = 0;
    currencyMaskitoMask = currencyMaskitoOptions;

    constructor(
        public dialogRef: MatDialogRef<ApplyCreditsToBillDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder,
        private changeDetector: ChangeDetectorRef,
        private paymentsMadeService: PaymentsMadeService,
        private vendorCreditsService: VendorCreditsService,
        private billsService: BillsService,
        protected router: Router
    ) {
        this.dataSource = new MatTableDataSource<IVendorCredit>([]);
    }

    ngOnInit() {
        this.bill = this.data.bill;
        this.pendingBalance = this.data.bill.pending_balance;
        this.contact = this.data.bill.money_receiver_contact;
        this.remaining = this.data.bill.pending_balance;

        if (this.contact && this.contact.contact_id) {
            combineLatest(
                this.vendorCreditsService.getContactExcessVendorCredits(this.contact.contact_id),
                this.paymentsMadeService.getContactOverpaymentPaymentsMade(this.contact.contact_id)
            )
                .pipe(
                    map(([credits, payments]) => {
                        const paymentsMade: Array<IVendorCredit | IPaymentMade> = payments;
                        const vendorCredits: IVendorCredit[] = credits;

                        return paymentsMade.concat(vendorCredits);
                    }),
                    tap((data: any) => {
                        const credits = data;

                        for (const credit of credits) {
                            const creditControl = this.fb.group({
                                payment_made_id: [],

                                vendor_credit_id: [],
                                vendor_credit_number: [],

                                balance: [],
                                total_amount: [],
                                reference: [],
                                date: [],

                                amount_to_bill: [0]
                            });
                            const dueBalance = credit.vendor_credit_id ? credit.balance : credit.over_payment;
                            let amountToBill = 0;

                            if (this.remaining >= dueBalance) {
                                amountToBill = dueBalance;
                                const remaining = this.remaining - dueBalance;
                                this.remaining = Number(remaining.toFixed(2));
                            } else {
                                amountToBill = this.remaining;
                                this.remaining = 0;
                            }

                            if (credit.payment_made_id) {
                                creditControl.patchValue({
                                    payment_made_id: credit.payment_made_id,
                                    date: credit.paid_date,
                                    balance: credit.over_payment,
                                    total_amount: credit.amount,
                                    reference: credit.reference,
                                    amount_to_bill: amountToBill
                                });
                            } else {
                                creditControl.patchValue({
                                    vendor_credit_id: credit.vendor_credit_id,
                                    vendor_credit_number: credit.vendor_credit_number,
                                    date: credit.vendor_credit_date,
                                    balance: credit.balance,
                                    total_amount: credit.total_amount,
                                    reference: credit.reference,
                                    amount_to_bill: amountToBill
                                });
                            }

                            this.creditsArray.push(creditControl);
                        }

                        this.dataSource.data = this.creditsArray.getRawValue();
                        this.calculateTotals();
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe();
        }
    }

    onBlurCredit(index: number) {
        if (this.creditsArray) {
            const creditGroup: UntypedFormGroup = this.creditsArray.at(index) as unknown as UntypedFormGroup;
            const amountToBill = creditGroup.controls.amount_to_bill.value;
            const balance = creditGroup.controls.balance.value;
            const unmaskAmount = Number(cleanCurrencyString(amountToBill));

            const credits = this.creditsArray.getRawValue();
            const amountFromCredits = credits.reduce(
                (sum, item) => Number(cleanCurrencyString(item.amount_from_credit)) + sum,
                0
            );
            const realRemaining = this.pendingBalance - amountFromCredits + unmaskAmount;

            if (unmaskAmount > realRemaining || unmaskAmount > balance) {
                const newAmount = realRemaining > balance ? balance : realRemaining;
                creditGroup.controls.amount_to_bill.setValue(newAmount);
            }

            this.calculateTotals();
        }
    }

    calculateTotals() {
        const creditsNew = this.creditsArray.getRawValue();
        const amountFromCreditsNew = creditsNew.reduce(
            (sum, item) => Number(cleanCurrencyString(item.amount_to_bill)) + sum,
            0
        );

        this.amountFromCredits = amountFromCreditsNew;
        this.remaining = this.pendingBalance - amountFromCreditsNew;
    }

    routeToElement(element: any) {
        this.dialogRef.close();

        if (element.payment_made_id) {
            this.router.navigate(['purchases/payments', element.payment_made_id]);
        } else {
            this.router.navigate(['purchases/vendorcredits', element.vendor_credit_id]);
        }
    }

    save() {
        const credits = this.creditsArray.getRawValue();
        const filteredCredits: {
            vendor_credits: Array<{vendor_credit_id: number; amount_to_apply: number}>;
            payments_made: Array<{payment_made_id: number; amount_to_apply: number}>;
        } = {
            vendor_credits: [],
            payments_made: []
        };
        for (const cr of credits) {
            const unmaskAmount = Number(cleanCurrencyString(cr.amount_to_bill));

            if (unmaskAmount !== 0 && cr.vendor_credit_id) {
                filteredCredits.vendor_credits.push({
                    vendor_credit_id: cr.vendor_credit_id,
                    amount_to_apply: unmaskAmount
                });
            }

            if (unmaskAmount !== 0 && cr.payment_made_id) {
                filteredCredits.payments_made.push({
                    payment_made_id: cr.payment_made_id,
                    amount_to_apply: unmaskAmount
                });
            }
        }

        if (this.bill && this.bill.bill_id) {
            this.billsService
                .applyFromCredits(this.bill.bill_id, filteredCredits)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((data) => {
                    this.dialogRef.close(data);
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
