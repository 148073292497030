import {
    AfterViewChecked,
    Component,
    ContentChild,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-lazy-loading',
    template: `
        <cdk-virtual-scroll-viewport
            style="width: 100%; overflow-x: hidden"
            [ngStyle]="virtualScrollStyle"
            (scroll)="onScroll($event)"
            #virtualScrollViewport
            [itemSize]="itemHeightPx"
            [minBufferPx]="minBufferPx"
            [maxBufferPx]="maxBufferPx"
            (scrolledIndexChange)="nextBatch($event)"
        >
            <ng-container
                *cdkVirtualFor="let item of data; index as idx; last as last; first as first; trackBy: trackByID"
            >
                <ng-container
                    [ngTemplateOutlet]="listItemTemplate"
                    [ngTemplateOutletContext]="{listItem: item, index: idx, last: last, first: first}"
                ></ng-container>
            </ng-container>
        </cdk-virtual-scroll-viewport>
    `
})
export class LazyLoadingComponent implements OnInit, OnDestroy, AfterViewChecked {
    private unsubscribe: Subject<void> = new Subject();

    // Observable, get index of list item and scroll viewport to it
    @Input() scrollToIndex: Subject<number> = new Subject<number>();
    // Observable, get event and update CdkVirtualScrollViewport
    @Input() checkViewport: Subject<void> = new Subject<void>();

    // array of items
    @Input() data: any[] = [];
    // the height of the item in pixels, including margin, border and padding
    @Input() itemHeightPx: number = 0;
    // if viewport checks that till the end of list left _those_ number of items
    // LazyLoadingComponent sends an event that informs that it's time to load more items
    @Input() numberOfItemsTillTheListEnd: number = 5;
    // determines how much extra content is rendered beyond what is visible in the viewport
    @Input() minBufferPx: number = 100;
    // tells the viewport how much buffer space to render back up to when it detects that more buffer is required
    @Input() maxBufferPx: number = 200;
    // height of Angular virtual scroll Component
    @Input() offsetTop: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    // measure of offset height
    @Input() heightMeasure: string = 'vh';
    // could be opened on the same page several times
    @Input() closeSubject: boolean = true;
    // allow Lazy-loading use own offsetTop to calculate object height
    @Input() useInfiniteScrollTop: boolean = true;

    // EventEmitter, tell when the user scrolled to the top/bottom of the list
    @Output() scrolledUp = new EventEmitter();
    @Output() scrolledDown = new EventEmitter();

    // for transfer items to place where them are used
    @ContentChild(TemplateRef) listItemTemplate: TemplateRef<any> | null = null;

    @ViewChild('virtualScrollViewport', {static: true}) virtualScrollViewport: CdkVirtualScrollViewport | undefined =
        undefined;

    scrollTopPx: number = 0;
    scrollToTop: boolean = false;
    virtualScrollStyle = {height: '100vh'};

    // watch on scrolling
    @HostListener('wheel', [])
    onScroll(event: any) {
        if (event) {
            if (this.scrollTopPx && this.scrollTopPx > event.target.scrollTop) {
                this.scrollToTop = true;
            }
            if (this.scrollTopPx && this.scrollTopPx < event.target.scrollTop) {
                this.scrollToTop = false;
            }
            this.scrollTopPx = event.target.scrollTop;
        }
    }

    constructor() {}

    ngOnInit() {
        this.scrollToIndex
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (index: number) => this.virtualScrollViewport && this.virtualScrollViewport.scrollToIndex(index)
            );

        this.checkViewport
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.virtualScrollViewport && this.virtualScrollViewport.checkViewportSize());
    }
    ngAfterViewChecked() {
        if (this.virtualScrollStyle.height === '100vh') {
            let infiniteScrollTop = 0;
            if (
                this.virtualScrollViewport &&
                this.virtualScrollViewport.elementRef.nativeElement.offsetTop &&
                this.useInfiniteScrollTop
            ) {
                infiniteScrollTop = this.virtualScrollViewport.elementRef.nativeElement.offsetTop;
            }

            this.offsetTop.pipe(takeUntil(this.unsubscribe)).subscribe((offsetTop) => {
                if (
                    this.virtualScrollViewport &&
                    this.useInfiniteScrollTop &&
                    infiniteScrollTop !== this.virtualScrollViewport.elementRef.nativeElement.offsetTop
                ) {
                    infiniteScrollTop = this.virtualScrollViewport.elementRef.nativeElement.offsetTop;
                }
                this.virtualScrollStyle = {
                    height: `calc(100${this.heightMeasure} - ${offsetTop + infiniteScrollTop}px)`
                };
            });
        }
    }

    nextBatch(index: number) {
        if (typeof this.virtualScrollViewport === 'undefined') {
            return;
        }

        const end = this.virtualScrollViewport.getRenderedRange().end;
        const start = this.virtualScrollViewport.getRenderedRange().start;
        const total = this.virtualScrollViewport.getDataLength();

        // to prevent initial request when users list length = 0
        if (total === 0) {
            return;
        }

        // scroll to top and has available contacts
        if (this.scrollToTop && index === 0) {
            this.scrolledUp.emit({end, start, total});
        }

        // scroll to bottom and has available contacts
        // upload new data by 5 items to the end of the list
        if (!this.scrollToTop && total - end < this.numberOfItemsTillTheListEnd) {
            this.scrolledDown.emit({end, start, total});
        }
    }

    trackByID(idx: number, item: any) {
        return item.id;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.scrollToIndex.complete();
        this.checkViewport.complete();
        if (this.closeSubject) {
            this.offsetTop.complete();
        }
        this.scrolledUp.complete();
        this.scrolledDown.complete();
    }
}
