import * as FileSaver from 'file-saver';
import {Subject} from 'rxjs';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IJournal} from '@cyberco-nodejs/zipi-typings';

import {JournalsService} from 'app/services/api/finance/journals.service';
import {NotificationsService} from 'angular2-notifications';

@Component({
    selector: 'app-journal-page',
    templateUrl: 'journal-page.component.html',
    styleUrls: ['journal-page.component.scss']
})
export class JournalPageComponent implements OnInit, OnDestroy {
    @ViewChild('journalCreate') journalCreate: any;

    private unsubscribe: Subject<void> = new Subject();

    journal: IJournal | undefined;
    sumOfDebit: number = 0;
    sumOfCredit: number = 0;
    journalId: number | undefined;
    isSidebarOpened: boolean = false;

    constructor(
        private route: ActivatedRoute,
        public router: Router,
        private journalsService: JournalsService,
        private ntfs: NotificationsService
    ) {}

    downloadJournalPdf() {
        if (typeof this.journal === 'undefined') {
            return;
        }
        this.journalsService
            .downloadJournalPdf(this.journal.id as number)
            .pipe(
                map((res) => new Blob([new Uint8Array(res.data)])),
                tap((result) => FileSaver.saveAs(result, `journal_${this.journal?.id as number}.pdf`)),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    }

    reverseJournal() {
        this.journalCreate.openReverse(this.journal);
    }

    editJournal() {
        this.journalCreate.open(this.journal);
    }

    publishJournal() {
        this.journalsService
            .updateJournal({
                ...this.journal,
                status: 'published'
            } as IJournal)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.ntfs.info(`Journal published`);
            });
    }

    initPreview() {
        if (this.journalId) {
            this.journalsService
                .getJournalById(this.journalId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((foundJournal) => {
                    if (!!foundJournal) {
                        foundJournal.line_items?.map((item) => {
                            if (item.debit_or_credit === 'debit') {
                                this.sumOfDebit = Number((this.sumOfDebit + item.amount).toFixed(2));
                            } else {
                                this.sumOfCredit = Number((this.sumOfCredit + item.amount).toFixed(2));
                            }
                        });

                        this.journal = foundJournal;
                    }
                });
        }
    }

    ngOnInit() {
        this.route.paramMap
            .pipe(
                map((pm) => +(pm.get('id') as string)),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                this.journalId = id;

                this.initPreview();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
