<!------- Loading ------->
<div class="load-container d-flex align-items-center justify-content-center" id="load-container" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
<div class="container">
    <mat-card class="card-override">
        <mat-card-content class="col card-component">
            <div class="row">
                <div class="col-6 intro-section">
                    <div class="mat-typography">
                        <div class="books-logo">
                            <img class="books-img" src="/assets/images/skyslope-books-white.png" alt="" />
                        </div>
                        <H1 class="title">Legacy Account Login</H1>
                        <p class="description">
                            To enhance your experience, we're transitioning your current Books account to work within
                            the SkySlope login system.
                        </p>
                        <p class="description">Please create your new credentials so you can get started.</p>
                        <p class="description">
                            Once your new credentials are set, the "Zipi Legacy Users" login option will no longer work.
                            Instead, you will log in directly at
                            <a href="https://skyslope.com/books-login">https://skyslope.com/books-login</a>.
                        </p>
                        <p>{{ userEmail }}</p>
                    </div>
                </div>
                <div class="col-6 mt-2" style="flex: auto">
                    <div style="margin-top: 30px" id="okta-login-container"></div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
