import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {DealDepositService} from '../../../../services/deal-deposit.service';
import {filter, takeUntil} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {DEPOSIT_REQUEST_STATUS_COLOR, PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {MatOptionSelectionChange} from '@angular/material/core';
import {ResendRequestDialogComponent} from '../../../../../deals/components/deal/common/deal-deposit/deposit-request/resend-request-dialog/resend-request-dialog.component';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {IScrollData} from 'app/models/scroll-data';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {IDealDepositRequest} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-request-list',
    templateUrl: './request-list.component.html',
    styleUrls: ['./request-list.component.scss', '../../../../../../../assets/infinite-scroll-table.scss']
})
export class RequestListComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;

    dataSource: MatTableDataSource<IDealDepositRequest>;
    scrollData: IScrollData = {
        offset: 0,
        limit: 50,
        summary_status_filter: '',
        filter: '',
        sort_column: '',
        sort_direction: 'desc',
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    displayedColumns = [
        'status',
        'type',
        'contact_name',
        'deal_address',
        'due_date',
        'request_amount',
        'request_balance',
        'action'
    ];

    statusTypes = ['all', 'open', 'sent', 'partially_paid', 'paid'];
    statusFilter: UntypedFormControl = new UntypedFormControl([]);
    typeFilters = ['all', 'incoming', 'outgoing'];
    typeFilter: UntypedFormControl = new UntypedFormControl('all');

    statusColor = DEPOSIT_REQUEST_STATUS_COLOR;

    constructor(
        private dealDepositService: DealDepositService,
        public dialog: MatDialog
    ) {
        this.dataSource = new MatTableDataSource<IDealDepositRequest>([]);
    }

    ngOnInit() {
        this.nextBatch();

        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.nextBatch();
            });
        }
    }

    nextBatch() {
        // cancel previous batch requests
        this.unsubscribeBatch.next();

        if (this.statusFilter.value && !this.statusFilter.value.includes('all')) {
            this.scrollData.summary_status_filter = this.statusFilter.value;
        } else {
            this.scrollData.summary_status_filter = '';
        }

        if (this.typeFilter.value && this.typeFilter.value !== 'all') {
            this.scrollData.filter = this.typeFilter.value;
        } else {
            this.scrollData.filter = '';
        }

        this.dealDepositService
            .getDepositRequestsForMe(this.scrollData)
            .pipe(takeUntil(this.unsubscribeBatch))
            .subscribe((data) => {
                this.dataSource.data = data.result;
                this.scrollData.total = data._meta.total;
            });
    }

    resetData() {
        this.paginator!.pageIndex = 0;
        this.scrollData.offset = 0;
        this.scrollData.total = 0;

        this.nextBatch();
    }

    changeStatusFilter(event: MatOptionSelectionChange) {
        if (event.isUserInput) {
            const {value, selected} = event.source;
            let statusFilter = this.statusFilter.value;

            if (value === 'all') {
                statusFilter = selected ? this.statusTypes : [];
            } else if (value !== 'all') {
                if (statusFilter.includes('all') && !selected) {
                    statusFilter = statusFilter.filter((f: string) => f !== 'all' && f !== value);
                } else {
                    statusFilter = this.statusTypes.filter(
                        (f) => (f !== value && statusFilter.includes(f)) || (f === value && selected)
                    );
                }
            }

            // update values
            this.statusFilter.setValue(statusFilter);
            this.resetData();
        }
    }

    resendRequest(depositRequest: IDealDepositRequest) {
        const dialogRef = this.dialog.open(ResendRequestDialogComponent, {
            minWidth: '500px',
            data: {
                depositRequest: depositRequest
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((resendSettings) => {
                if (depositRequest && depositRequest.deposit_request_id) {
                    this.dealDepositService
                        .resendDealDepositRequests(depositRequest.deposit_request_id, resendSettings)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((response) => {
                            if (response) {
                                this.resetData();
                            }
                        });
                }
            });
    }

    deleteRequest(depositRequest: IDealDepositRequest & {has_payments: boolean}) {
        if (depositRequest.has_payments) {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                width: '400px',
                data: {
                    title: 'Deleting Deposit Request',
                    message: `The Deposit Request has associated Payments. To delete Deposit Request you must first delete all Payments.`,
                    hideCancel: true
                }
            });

            dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
        } else {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                minWidth: '300px',
                data: {
                    title: 'Deleting Deposit Request',
                    message: 'Deposit Request will be deleted'
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((ok) => {
                    if (ok && depositRequest && depositRequest.deposit_request_id) {
                        this.dealDepositService
                            .deleteDealDepositRequests(depositRequest.deposit_request_id)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((response) => {
                                if (response) {
                                    this.resetData();
                                }
                            });
                    }
                });
        }
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        this.resetData();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
    }
}
