import {Component, OnInit, OnDestroy, SimpleChanges, OnChanges, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl} from '@angular/forms';
import {ICreditItem, ICreditNote, ICreditNoteBase, IGroup, IProduct} from '@cyberco-nodejs/zipi-typings';
import {pipe, Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {takeUntil, tap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {IFinanceState} from '../../../store/finance.reducer';
import {FetchProducts} from '../../../store/finance.actions';
import {selectProducts} from '../../../store/finance.selectors';
import {CreateProductDialogComponent} from '../../products/create-product-dialog/create-product-dialog.component';
import {GenericFormArray} from 'app/entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {IMaybeValidCreditNoteTemplate} from '../edit-recurring-credit-note/edit-recurring-credit-note.component';
import {GroupsSource} from 'app/services/sources/groups.source';
import {FormGroupArray, FormGroupWithFormControls} from '../../../../../typings/common';
import {
    currencyMaskitoOptions,
    numberMaskitoOptions,
    unmaskCurrencyControlValue
} from '../../../../../utilities/maskito';
import {formatToDate} from '../../../../../utilities';

@Component({
    selector: 'app-credit-note-template',
    templateUrl: 'credit-note-template.component.html',
    styleUrls: ['credit-note-template.component.scss']
})
export class CreditNoteTemplateComponent implements OnInit, OnChanges, OnDestroy {
    @Input() creditNoteTemplate: ICreditNoteBase | null = null;
    @Output() onUpdate = new EventEmitter<IMaybeValidCreditNoteTemplate>();

    private unsubscribe: Subject<void> = new Subject();

    itemsArray: FormGroupArray = this.fb.array([this.createItem()]) as FormGroupArray;
    formGroup: FormGroupWithFormControls = this.fb.group({
        customer__contact_fk_id: [null, [Validators.required]],
        credit_note_number: [null],
        reference: [null],
        credit_note_date: [new Date(), Validators.required],
        sales_person__profile_fk_id: null, // Sales Person

        total_amount: [{value: 0, disabled: true}],
        items: this.itemsArray,

        allow_auto_apply: [false, Validators.required]
    }) as FormGroupWithFormControls;
    emptyItem = {name: '', rate: 0, quantity: 1, amount: 0};

    currencyMaskitoMask = currencyMaskitoOptions;
    numberMaskitoMask = numberMaskitoOptions;
    amountWithCurrencyMask: boolean[] = [];
    rateWithCurrencyMask: boolean[] = [];

    creditNote: ICreditNote | undefined;

    products: IProduct[] = [];

    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    initContactId: number[] = [];
    selectedProfileArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    selectedProfileIds: number[] = [];

    groupList: IGroup[] = [];

    constructor(
        private fb: UntypedFormBuilder,
        public dialog: MatDialog,
        private store: Store<IFinanceState>,
        private groupSrc: GroupsSource
    ) {}

    private createItem(item?: ICreditItem, itemIndex: number = 0) {
        const group = this.fb.group({
            product_fk_id: [item ? item.product_fk_id : null],
            name: [{value: item ? item.name : '', disabled: item && item.product_fk_id}, Validators.required],
            description: item ? item.description : '',
            quantity: [1, Validators.required],
            rate: [0, Validators.required],
            amount: [{value: 0, disabled: true}],
            ledger_account_fk_id: [
                {
                    value: item ? item.ledger_account_fk_id : '',
                    disabled: item && item.product_fk_id
                },
                Validators.required
            ],
            order_index: [item && item.order_index],
            division__company_group_fk_id: [item ? item.division__company_group_fk_id : null, []],
            selected_divisions: new GenericFormArray<ChipNode>([]),
            selected_division_ids: [item ? item.division__company_group_fk_id : null],
            selected_full_ledger: null
        });

        group.controls.selected_divisions.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            if (changes && changes.length > 0) {
                group.controls.division__company_group_fk_id.setValue(changes[0].target_id);
            } else {
                group.controls.division__company_group_fk_id.setValue(null);
            }
        });

        const handleValueChanges = pipe(
            takeUntil(this.unsubscribe),
            tap(() => group.controls.amount.patchValue(group.controls.quantity.value * group.controls.rate.value)),
            tap(() => {
                this.amountWithCurrencyMask[itemIndex] = String(group.controls.amount.value).length < 13;
            })
        );

        const unmaskRateFn = unmaskCurrencyControlValue(group.controls.rate);

        group.controls.quantity.valueChanges.pipe(handleValueChanges, takeUntil(this.unsubscribe)).subscribe();
        group.controls.rate.valueChanges
            .pipe(
                unmaskRateFn,
                handleValueChanges,
                tap(() => {
                    this.rateWithCurrencyMask[itemIndex] = String(group.controls.rate.value).length < 13;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
        if (item) {
            group.patchValue(item);
        }
        return group;
    }

    addItem(item?: ICreditItem) {
        this.itemsArray.push(this.createItem(item, this.itemsArray.length));
    }

    private computeTotal = () => {
        const total = this.itemsArray.controls.reduce<number>((acc: number, item: AbstractControl) => {
            const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
            return itemGroup.getRawValue().amount + acc;
        }, 0);
        this.formGroup.controls.total_amount.patchValue(total);
    };

    selectItem(item: AbstractControl, product: IProduct) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.controls.product_fk_id.patchValue(product.product_id);
        itemGroup.controls.rate.patchValue(product.price);
        itemGroup.controls.description.patchValue(product.description);
        itemGroup.controls.ledger_account_fk_id.patchValue(product.ledger_account_fk_id);
        itemGroup.controls.quantity.patchValue(1);
        itemGroup.controls.name.patchValue(product.name);

        itemGroup.controls.ledger_account_fk_id.disable();
        itemGroup.controls.name.disable();
    }

    removeProductFkId(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.controls.ledger_account_fk_id.enable();
        itemGroup.controls.name.enable();
        itemGroup.controls.name.patchValue('');
        itemGroup.controls.product_fk_id.patchValue(null);
    }

    resetItem(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        itemGroup.reset(this.emptyItem);
        itemGroup.controls.name.enable();
        itemGroup.controls.ledger_account_fk_id.enable();
    }

    createProduct() {
        const dialogRef = this.dialog.open(CreateProductDialogComponent);

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    getProducts(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        if (itemGroup.getRawValue().name) {
            const filteredValue = itemGroup.getRawValue().name.toLowerCase().trim();
            return this.products.filter((pr) => pr.name.toLowerCase().trim().includes(filteredValue));
        }

        return this.products;
    }

    ngOnInit() {
        this.store.dispatch(new FetchProducts());

        this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe)).subscribe((data) => {
            this.products = data;
        });

        this.formGroup.controls.credit_note_date.patchValue(
            formatToDate(this.formGroup.controls.credit_note_date.value)
        );

        this.itemsArray.valueChanges
            .pipe(
                tap(() => this.computeTotal()),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.moneySenderCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            if (values && values.length > 0) {
                this.formGroup.controls.customer__contact_fk_id.patchValue(values[0].target_id);
            } else {
                this.formGroup.controls.customer__contact_fk_id.patchValue(null);
            }
        });

        this.selectedProfileArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: any[]) => {
            if (values && values.length > 0) {
                if (values[0].type === 'individual') {
                    this.formGroup.controls.sales_person__profile_fk_id.setValue(values[0].target_id);
                }
            } else {
                this.formGroup.controls.sales_person__profile_fk_id.reset(null, {emitEvent: false});
            }
        });

        this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() =>
            this.onUpdate.emit({
                valid: this.formGroup.valid,
                template: this.formGroup.getRawValue()
            })
        );

        this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
            this.groupList = list.filter((group) => group.type === 'division') as IGroup[];
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.creditNoteTemplate.currentValue) {
            this.patchTemplate(changes.creditNoteTemplate.currentValue);
        }
    }

    patchTemplate(cnTemplate: ICreditNoteBase) {
        this.formGroup.patchValue(cnTemplate);
        this.itemsArray.removeAt(0);
        cnTemplate.items.forEach((item) => this.addItem(item));
        if (cnTemplate && cnTemplate.customer__contact_fk_id) {
            this.initContactId = [cnTemplate.customer__contact_fk_id];
        }
        if (cnTemplate && cnTemplate.sales_person__profile_fk_id) {
            this.selectedProfileIds = [cnTemplate.sales_person__profile_fk_id];
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.onUpdate.complete();
    }
}
