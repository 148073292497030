import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {SpannerNotificationInterface, SpannerNotificationRefValueInterface} from '../../../../models/user-notification';

@Component({
    selector: 'app-profile-upload-notification-item',
    templateUrl: './profile-upload-notification-item.component.html',
    styleUrls: ['./profile-upload-notification-item.component.scss']
})
export class ProfileUploadNotificationItemComponent implements OnInit {
    @Input() flagged = false;
    @Input() seen = false;
    @Input() notification: SpannerNotificationRefValueInterface | null = null;
    @Output() markNotificationAsWarning = new EventEmitter();
    @Output() markAsFlaggedEvent = new EventEmitter();
    @Output() markAsSeenEvent = new EventEmitter();
    @Output() markAsUnseenEvent = new EventEmitter();

    constructor(private router: Router) {}

    ngOnInit() {}

    markAsWarning(notificationToMark: {value: SpannerNotificationInterface}) {
        this.markNotificationAsWarning.emit(notificationToMark);
    }

    markAsSeen() {
        this.markAsSeenEvent.emit();
    }

    markAsUnseen() {
        this.markAsUnseenEvent.emit();
    }

    markAsFlagged() {
        this.markAsFlaggedEvent.emit();
    }

    redirect() {
        // @ts-ignore
        this.router.navigate([this.notification.value.link]);
    }
}
