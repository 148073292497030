import {Component, OnInit, OnDestroy} from '@angular/core';
import {IContact} from '@cyberco-nodejs/zipi-typings';
import {IContactsState} from '../store/contacts.reducer';
import {Store} from '@ngrx/store';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {map, takeUntil, filter, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-contact-edit-page',
    templateUrl: 'contact-edit-page.component.html',
    styleUrls: ['contact-edit-page.component.css']
})
export class ContactEditPageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    contact: IContact | undefined;
    contact_id: number | undefined;
    index: number | undefined;

    constructor(
        private store: Store<IContactsState>,
        protected router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((parameter) => {
            this.index = Number(parameter.index);
        });

        this.route.paramMap
            .pipe(
                map((params: ParamMap) => +params.get('id')!),
                filter((id) => !isNaN(id)),
                tap((contact_id) => (this.contact_id = contact_id)),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
