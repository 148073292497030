import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ServiceRequester} from '../../service.requester';
import {IVendorCredit} from '../../../../typings';

@Injectable()
export class VendorCreditsService {
    private url = '/finance/vendor-credits/';

    constructor(public requester: ServiceRequester) {}

    getVendorCredit(id: number): Observable<IVendorCredit> {
        return this.requester.makeMsCall$(this.url + id, 'GET', 'shipp');
    }

    getVendorCredits(params: any = {}): Observable<{result: IVendorCredit[]; _meta: {total: number}}> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp', params);
    }

    getContactExcessVendorCredits(contactId: number): Observable<IVendorCredit[]> {
        return this.requester.makeMsCall$(this.url + `${contactId}/excess`, 'GET', 'shipp');
    }

    createVendorCreditAsDraft(data: Partial<IVendorCredit>): Observable<IVendorCredit> {
        return this.requester.makeMsCall$(this.url + 'draft', 'POST', 'shipp', data);
    }

    createVendorCreditAsOpen(data: Partial<IVendorCredit>): Observable<IVendorCredit> {
        return this.requester.makeMsCall$(this.url + 'open', 'POST', 'shipp', data);
    }

    updateVendorCredit(data: IVendorCredit): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + data.vendor_credit_id, 'PUT', 'shipp', data);
    }

    update1099AmountsOfVendorCredit(vendorCreditId: number, data: {}): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${vendorCreditId}/1099`, 'PUT', 'shipp', data);
    }

    updateVendorCreditStatus(vendorCreditId: number, status: string): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + vendorCreditId + '/status', 'PUT', 'shipp', {status});
    }

    applyToBills(
        vendorCreditId: number,
        data: Array<{bill_id: number; amount_to_credit: number}>
    ): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + vendorCreditId + '/apply', 'PUT', 'shipp', data);
    }

    deleteVendorCredit(vendorCreditId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + vendorCreditId, 'DELETE', 'shipp');
    }

    createPayStub(vendorCreditId: number): Observable<any> {
        return this.requester.makeMsCall$(`/pay-stub/vendor-credit/${vendorCreditId}/create`, 'GET');
    }

    orderPayStubGeneration(vendorCreditIds: Array<number>): Observable<boolean> {
        return this.requester.makeMsCall$(`/pay-stub/order-generation/vendor-credits`, 'POST', 'zipi', {
            vendor_credit_ids: vendorCreditIds
        });
    }
}
