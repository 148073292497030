import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-user-suspend-dialog',
    styles: [
        `
            .mat-dialog-actions {
                justify-content: space-between;
                display: flex;
            }

            #errors {
                color: #af1515;
                padding-left: 15px;
                line-height: 2em;
                font-size: 14px;
            }
        `
    ],
    template: `
        <div mat-dialog-content>
            <p>{{ action }} {{ name }}</p>
            <hr />
            <p>Are you sure to {{ action }} this profile?<br /></p>
            <p *ngIf="additionalMessage.length">
                {{ additionalMessage }}
            </p>
        </div>

        <div class="text-right">
            <button mat-button class="mat-primary" (click)="dialogRef.close()" style="margin-right: 5px;">
                CANCEL
            </button>
            <button mat-raised-button class="mat-primary" (click)="dialogRef.close(true)">{{ action }}</button>
        </div>
    `
})
export class UserInfoDialogComponent {
    action: string = '';
    name: string = '';
    additionalMessage: string = '';
    constructor(public dialogRef: MatDialogRef<UserInfoDialogComponent>) {}
}
