import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../service.requester';
import {ICompanySettings} from '../../../../typings';
import {Observable} from 'rxjs';

@Injectable()
export class CompanySettingsService {
    constructor(public requester: ServiceRequester) {}

    getCompanySettings(companyId: number): Observable<ICompanySettings> {
        return this.requester.makeMsCall$(`/companies/${companyId}/settings`, 'GET', 'shipp');
    }

    updateCompanySettings(companyId: number, settings: ICompanySettings): Observable<boolean> {
        return this.requester.makeMsCall$(`/companies/${companyId}/settings`, 'PUT', 'shipp', settings);
    }
}
