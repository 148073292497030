import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UserService} from 'app/services/user.service';
import {AuthService} from 'app/services/auth.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: 'forgot-password.component.html',
    styleUrls: ['forgot-password.component.scss']
})
export class ForgotPasswordComponent {
    public email = '';
    public dataIsSent = false;

    constructor(
        public dialogRef: MatDialogRef<ForgotPasswordComponent>,
        public userService: UserService,
        protected authService: AuthService
    ) {}

    sendEmail(form: any) {
        if (!form.valid) {
            return false;
        }

        return this.authService.afAuth.sendPasswordResetEmail(this.email).then(() => {
            this.dataIsSent = true;
        });
    }
}
