<section class="sidebar-page">
    <div class="sidebar-wrapper">
        <section class="sidebar-body">
            <header class="d-flex align-items-center">
                <button mat-raised-button class="mr-3" (click)="toTransacionsList()">
                    <mat-icon>arrow_back</mat-icon>
                    Back
                </button>
                <h1 class="m-0 page-title">Import transactions</h1>
                <i class="sp-spacer"></i>
                <div class="text-right my-3">
                    <form [formGroup]="importForm">
                        <input
                            formControlName="fileSource"
                            #contactFromCsv
                            type="file"
                            accept=".csv"
                            (change)="handleFileInput(contactFromCsv.files)"
                            hidden
                        />
                        <button mat-raised-button class="fix-focus-outline" (click)="contactFromCsv.click()">
                            <mat-icon>attach_filet</mat-icon>
                            Select CSV file
                        </button>
                    </form>
                </div>
            </header>
            <mat-card>
                <div *ngIf="!importedTransactions.length">
                    <p>No CSV file selected.</p>

                    <button mat-raised-button (click)="downloadFile('/assets/example-files/transactions-import.csv')">
                        <mat-icon>cloud_download</mat-icon>
                        Example CSV file
                    </button>
                </div>

                <button
                    mat-raised-button
                    class="m-3"
                    *ngIf="importedTransactions.length"
                    (click)="uploadTransactions()"
                >
                    <mat-icon>cloud_upload</mat-icon>
                    Upload Transactions
                </button>

                <ul class="match-transactions" *ngIf="importedTransactions.length">
                    <li *ngFor="let transaction of importedTransactions">
                        <div>
                            <p>{{ transaction.name }}</p>
                            <p>{{ transaction.amount | currency: 'USD' : 'symbol' }}</p>
                            <p *ngIf="transaction.date">Dated: {{ transaction.date | date: 'mediumDate' }}</p>
                        </div>
                        <!--                        <button mat-icon-button-->
                        <!--                                color="warn">-->
                        <!--                            <mat-icon>clear</mat-icon>-->
                        <!--                        </button>-->
                    </li>
                </ul>
            </mat-card>
        </section>
    </div>
</section>
