import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'fromCamelCase'
})
export class FromCamelCasePipe implements PipeTransform {
    transform(value: string, args?: any): string {
        if (typeof value !== 'string') {
            return value;
        }

        return value.split(/(?=[A-Z])/).join(' ');
    }
}
