import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {phoneMaskitoOptions} from '../../../../../utilities/maskito';

@Component({
    selector: 'app-create-yodata-contact-dialog',
    templateUrl: 'create-contact-dialog.component.html',
    styles: [
        `
            .mat-dialog-content {
                overflow-wrap: break-word;
                white-space: pre-wrap;
            }
        `
    ]
})
export class YodataCreateContactDialogComponent implements OnInit {
    createContactForm: UntypedFormGroup = this.fb.group({
        givenName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
        additionalName: [null, [Validators.minLength(2), Validators.maxLength(30)]],
        familyName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
        email: [{value: null, disabled: true}, [Validators.required]],
        telephone: [null, [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]], // 111-111-1111
        // branchCode: [null, [Validators.required, Validators.pattern('[aA-zZ]{2}[0-9]{3}-[0-9]{3}')]], // ak702-001
        branchCode: [null, [Validators.required, Validators.pattern('https://[aA-zZ]{2}[0-9]{3}-[0-9]{3}.\\S+')]], // https://ak702-001.{any except whitespace}
        jobTitle: ['Sales Associate', [Validators.minLength(3), Validators.maxLength(30)]],
        roleName: [
            'Part Time Sales Professional',
            [Validators.required, Validators.minLength(3), Validators.maxLength(30)]
        ]

        // address: this.fb.group({
        //     address1: [null, [Validators.required]],
        //     city: [null, [Validators.required]],
        //     stateProvinceRegion: [null, [Validators.required]],
        //     country: ['US', [Validators.required]],
        //     postalCode: [null, [Validators.required]],
        // }),
    });
    isAllowedNewMapping: boolean = false;
    phoneMaskitoInputMask = phoneMaskitoOptions;
    divisions: any[] = [];

    constructor(
        public dialogRef: MatDialogRef<YodataCreateContactDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder
    ) {}

    ngOnInit() {
        this.createContactForm.controls.givenName.patchValue(this.data.entity.first_name);
        this.createContactForm.controls.familyName.patchValue(this.data.entity.last_name);
        this.createContactForm.controls.email.patchValue(this.data.entity.email);

        if (this.data.divisions) {
            this.divisions = this.data.divisions;
        }
    }

    markFormGroupTouched(formGroup: UntypedFormGroup) {
        (<any>Object).values(formGroup.controls).forEach((control: any) => {
            if (!control.touched) {
                control.markAsTouched();
            }
        });
    }

    closeForm(isCancelled: boolean) {
        if (isCancelled) {
            this.dialogRef.close(false);
            return;
        }

        // make required fields to be red
        if (this.createContactForm.invalid) {
            this.markFormGroupTouched(this.createContactForm);
            return;
        }

        const values = this.createContactForm.getRawValue();

        // destruct branchCode from POR URI (https://AK702-001.example.com ==> ak702-001)
        values.branchCode = values.branchCode.split('//')[1].split('.')[0].toLowerCase();

        this.dialogRef.close({
            zipiProfileId: this.data.entity.id,
            yodataContactData: values
        });
    }
}
