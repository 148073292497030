import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {Observable} from 'rxjs';
import {
    IInvoice,
    IBill,
    IFinancialTransfer,
    IPaymentReceivedMadeRequestObject,
    IPaymentMade
} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class FinancialTransferService {
    private url = '/financial-transfer';
    private shippUrl = '/finance/transfer';

    constructor(protected requester: ServiceRequester) {}

    getFinancialTransferById(trId: number): Observable<{result: IFinancialTransfer}> {
        return this.requester.makeMsCall$(this.url + `/${trId}`, 'GET', 'zipi');
    }

    getFinancialTransfersByBillIds(billIds: number[]): Promise<{
        result: {
            financial_transfer_id: number;
            connected__bill_fk_id: number;
            deal_id: number;
            type: string;
            amount: number;
            product_id: number;
            deal_address: string;
            deal_city: string;
            deal_street_number: string;
            deal_zip: string;
            deal_state: string;
        }[];
    }> {
        const queryParams = billIds.map((id) => `bill_ids[]=${id}`).join('&');
        return this.requester.makeMsCallPromise({
            method: 'GET',
            url: this.url + `/bills/?${queryParams}`,
            backend: 'core'
        });
    }

    createBillFromPayout(
        financialTransferId: number,
        matchResult: {
            mapped_product: {contact_company__product_fk_id: null; matched__product_fk_id: null} | null;
            item: [];
        }
    ): Observable<IBill> {
        return this.requester.makeMsCall$(
            this.shippUrl + `/${financialTransferId}/approve/bill`,
            'POST',
            'shipp',
            matchResult
        );
    }

    createInvoiceFromPayout(
        financialTransferId: number,
        matchResult: {
            mapped_product: {contact_company__product_fk_id: null; matched__product_fk_id: null} | null;
            item: [];
        }
    ): Observable<IInvoice> {
        return this.requester.makeMsCall$(
            this.shippUrl + `/${financialTransferId}/approve/invoice`,
            'POST',
            'shipp',
            matchResult
        );
    }

    // createBatchInvoicesFromPayout(data): Observable<any> {
    //     return this.requester.makeMsCall$(
    //         this.shippUrl + `/batch/invoice`,
    //         'POST',
    //         'shipp',
    //         data
    //     );
    // }
}
