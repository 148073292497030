import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
    CreateReport,
    DeleteReport,
    FetchReportsPreviewData,
    ToggleEditReportSidebarStatus,
    UpdateReport
} from '../../store/reports.action';
import {Store} from '@ngrx/store';
import {IReportsState} from '../../store/reports.reducer';
import {MatDialog} from '@angular/material/dialog';
import {ReportsService} from '../../services/reports.service';
import {availableReportType, financialReportsTypes, groupReportsTypes, reportsTypes} from '../../constants';
import {selectReportsEditSibedar} from '../../store/reports.selectors';
import {from, ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NavigationStart, Router} from '@angular/router';
import {ReportsLocalService} from '../../services/reports-local.service';
import * as _ from 'lodash-es';
import {markFormGroupTouched} from '../../../../utilities';
import {RbacService} from '../../../rbac/rbac.service';
import {FeatureFlagsService} from '../../../feature-flags/feature-flags.service';
import {IGroupReportsTypes, IReport, ReportGroupValue} from '@cyberco-nodejs/zipi-typings';
import {ConfirmComponent} from '../../../../layouts/confirm/confirm.component';
import {SaveReportDialogComponent} from '../reports-preview/components/save-report-dialog/save-report-dialog.component';

@Component({
    selector: 'app-report-edit-bar',
    templateUrl: './report-edit-bar.component.html',
    styleUrls: ['./report-edit-bar.component.scss']
})
export class ReportEditBarComponent implements OnInit, OnDestroy {
    @ViewChild('sidenav', {static: true}) sidenav: MatSidenav | undefined;
    private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
    private defaultValues: IReport | null = {
        settings: {}
    } as IReport;
    private oldReportTitle: string = '';

    public id: number | null | undefined = null;
    public commonReportForm: UntypedFormGroup | null = null;
    public reportTypeTitle: string = '';
    public activePanel: ReportGroupValue = 'general_company';
    public defaultActivePanel: ReportGroupValue = 'general_company';
    public isEditTitle: boolean = false;
    public isEditMode: boolean = false;
    public reportsTypes = reportsTypes;
    public financialReportsTypes = financialReportsTypes;
    public groupReportsTypes: IGroupReportsTypes[] = groupReportsTypes;
    public userReportTypes: IGroupReportsTypes[] = [];
    public companyReportTypes: IGroupReportsTypes[] = [];
    public availableReportType: {[key: string]: string} = availableReportType;
    public reportType: string = '';
    public report: any = {};
    public apArAgingEnabledFlag = false;
    public additional1099ReportsEnabledFlag = false;

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store<IReportsState>,
        public dialog: MatDialog,
        private router: Router,
        private reportsLocalService: ReportsLocalService,
        protected rbacService: RbacService,
        protected reportsService: ReportsService,
        protected featureFlagsService: FeatureFlagsService
    ) {}

    async ngOnInit() {
        this.initForm();
        this.initSubscribe();
        await this.prepareDataDependOnPermissions();
    }

    initForm() {
        this.commonReportForm = this.fb.group({
            title: ['', [Validators.required]],
            type: ['', [Validators.required]]
        });
    }

    populateForm(data: IReport | null) {
        if (!data) {
            return;
        }

        this.id = data.id;
        this.commonReportForm!.patchValue(data);
    }

    /**
     * Init subscribe
     */
    initSubscribe() {
        this.commonReportForm!.controls['type'].valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((val) => {
            this.handleReportTypeChanges(val);
        });

        const reportEditSidebarStatus$ = this.store.select(selectReportsEditSibedar);
        from(reportEditSidebarStatus$)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((reportSidebarStatus) => {
                this.sidenav!.opened = reportSidebarStatus.isOpened;
                this.isEditMode = reportSidebarStatus.isEditMode;
                if (reportSidebarStatus.isEditMode) {
                    if (reportSidebarStatus.editId) {
                        this.reportsService
                            .getReport(reportSidebarStatus.editId)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((data) => {
                                this.report = data.result;
                                this.populateForm(data.result);
                            });
                    } else {
                        const reportEntity = _.cloneDeep(this.reportsLocalService.getReportEntityData());
                        this.report = reportEntity;
                        this.populateForm(reportEntity);
                    }
                } else {
                    this.populateForm(this.defaultValues);
                }
            });

        this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (this.sidenav!.opened) {
                    this.close();
                }
            }
        });
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.apArAgingEnabledFlag = this.featureFlagsService.isFeatureEnabled('reports:ap_ar_aging');
                if (!this.apArAgingEnabledFlag) {
                    this.financialReportsTypes = financialReportsTypes.filter((item) => {
                        return ![availableReportType.ap_aging, availableReportType.ar_aging].includes(item.value);
                    });
                } else {
                    this.financialReportsTypes = financialReportsTypes;
                }

                this.additional1099ReportsEnabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'reports:additional_1099_reports'
                );
            });
    }

    /**
     * Select report options
     * @param event
     */
    selectReportOptions(event: {value: ReportGroupValue}) {
        this.activePanel = event.value;
    }

    /**
     * Save
     */
    save(data: IReport | {}, options?: {[key: string]: string | boolean}) {
        if (this.commonReportForm!.invalid) {
            markFormGroupTouched(this.commonReportForm!.controls);
        } else {
            const requestData = {
                ...this.commonReportForm!.value,
                ...data
            };

            if (this.id) {
                let dispatchOptions = {};
                if (options) {
                    if (options.isRedirectToPreview) {
                        dispatchOptions = {
                            ...dispatchOptions,
                            redirectTo: ['reports/preview', this.id],
                            fetchReportPreviewId: this.id
                        };
                    }

                    if (options.isFetchReportsPreview) {
                        dispatchOptions = {
                            ...dispatchOptions,
                            isFetchReportsPreview: true,
                            fetchReportPreviewId: this.id
                        };
                    }
                }

                this.store.dispatch(
                    new UpdateReport({
                        request: {
                            ...requestData,
                            id: Number(this.id)
                        },
                        options: {
                            ...dispatchOptions,
                            isFetchReports: true
                        }
                    })
                );
            } else {
                this.store.dispatch(new CreateReport(requestData));
            }
        }
    }

    /**
     * Run
     */
    run(data: IReport | {} = {}) {
        if (this.commonReportForm!.invalid) {
            markFormGroupTouched(this.commonReportForm!.controls);
            return;
        }

        const requestData = {...this.commonReportForm!.value, ...data};

        if (this.id) {
            requestData.id = Number(this.id);
            this.reportsLocalService.setReportData(requestData);
            this.router.navigate(['/reports/preview', this.id]);
        } else {
            this.reportsLocalService.setReportData(requestData);

            // If we have are on this page just fetch report data
            if (this.router.url === '/reports/preview') {
                this.store.dispatch(new FetchReportsPreviewData());
                this.close();
            } else {
                this.router.navigate(['/reports/preview']);
            }
        }
    }

    /**
     * Close sidebar
     */
    close() {
        this.id = null;
        this.report = {};
        this.commonReportForm!.reset();
        this.activePanel = this.defaultActivePanel;

        this.store.dispatch(new ToggleEditReportSidebarStatus({isOpened: false}));
    }

    /**
     * Show edit report title
     */
    showEditReportTitle($event: Event) {
        $event.stopPropagation();
        this.isEditTitle = true;
        this.oldReportTitle = this.commonReportForm!.controls['title'].value;
    }

    /**
     * Cancel edit title
     */
    cancelEditReportTitle() {
        this.isEditTitle = false;
        this.commonReportForm!.controls['title'].setValue(this.oldReportTitle);
    }

    applyReportTitle() {
        this.isEditTitle = false;
    }

    deleteReport(options: {[key: string]: any}) {
        if (!this.id) {
            return;
        }

        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            minHeight: 100,
            data: {
                title: `Deleting Report`,
                message: `Action will delete this Report and any saved data. Please confirm`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.store.dispatch(new DeleteReport({id: Number(this.id), options: options}));
                }
            });
    }

    onClose() {
        if (this.id) {
            this.commonReportForm!.reset();
            this.activePanel = this.defaultActivePanel;
        }
    }

    async prepareDataDependOnPermissions() {
        const isGeneralReportsAllowed = await this.rbacService.isAllowed({reports__view_general_reports: true});
        const isDealReportsAllowed = await this.rbacService.isAllowed({reports__view_deal_reports: true});
        const isFinancialReportsAllowed = await this.rbacService.isAllowed({reports__view_financial_reports: true});
        const isUserDealReportsAllowed = await this.rbacService.isAllowed({reports__view_deal_reports__user: true});
        const isUserGeneralReportsAllowed = await this.rbacService.isAllowed({
            reports__view_general_reports__user: true
        });

        if (isGeneralReportsAllowed) {
            this.activePanel = 'general_company';
        } else if (isUserGeneralReportsAllowed) {
            this.activePanel = 'general_user';
        } else if (isFinancialReportsAllowed) {
            this.activePanel = 'financial';
        } else if (isDealReportsAllowed) {
            this.activePanel = 'deal_company';
        } else if (isUserDealReportsAllowed) {
            this.activePanel = 'deal_user';
        }

        this.defaultActivePanel = this.activePanel;

        const foundReportGroup = groupReportsTypes.find((item) => item.value === this.activePanel);
        if (foundReportGroup) {
            this.reportsTypes = foundReportGroup.reports;
        }

        for (const item of groupReportsTypes) {
            if (await this.rbacService.isAllowed(item.permissionRule)) {
                switch (item.group) {
                    case 'company':
                        this.companyReportTypes.push(item);
                        break;
                    case 'user':
                        this.userReportTypes.push(item);
                        break;
                    default:
                }
            }
        }
    }

    handleReportTypeChanges(val: string) {
        this.reportType = val;
        this.reportTypeTitle = '';
        const findReport = _.find(reportsTypes, {value: val});
        if (findReport) {
            this.reportTypeTitle = findReport.title;
        }

        if (!this.id) {
            this.commonReportForm!.controls['title'].setValue(this.reportTypeTitle);
        }
    }

    onAction(event: {[key: string]: any}) {
        switch (event.type) {
            case 'cancel':
                this.close();
                break;
            case 'delete': {
                const regex = new RegExp(/\/reports\/preview\/\d+/);
                const redirectTo = regex.test(this.router.url) ? ['reports'] : false;
                this.deleteReport({
                    redirectTo: redirectTo,
                    isFetchReports: !redirectTo
                });
                break;
            }

            case 'run':
                this.run(event.data);
                break;

            case 'save': {
                const isRedirectToPreview = this.router.url === '/reports';
                const regExpEditReport = new RegExp('/reports/preview/\\d+', 'gi');
                this.save(event.data, {
                    isRedirectToPreview: isRedirectToPreview,
                    isFetchReportsPreview: regExpEditReport.test(this.router.url)
                });
                break;
            }

            case 'saveAndRun': {
                const dialogRef = this.dialog.open(SaveReportDialogComponent, {width: '450px'});
                // const reportLocalData = this.reportsLocalService.getReportData();

                dialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((data) => {
                        if (data) {
                            // this.run(data);
                            const isRedirectToPreview = this.router.url === '/reports';
                            const regExpEditReport = new RegExp('/reports/preview/\\d+', 'gi');
                            this.save(
                                {...data, ...event.data},
                                {
                                    isRedirectToPreview: isRedirectToPreview,
                                    isFetchReportsPreview: regExpEditReport.test(this.router.url)
                                }
                            );
                        }
                    });
                break;
            }

            default:
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }
}
