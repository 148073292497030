<ng-container *ngIf="notification && notification.value && notification.value.status !== 'ignored'">
    <!-- for: 'prepare_profiles', 'prepare_deals', 'prepare_invoices' notification.value.status -->
    <app-profile-upload-notification-item
        [flagged]="isFlagged(notification.value)"
        [seen]="notification.value.IS_SEEN"
        [notification]="notification"
        (markNotificationAsWarning)="markAsWarningEvent.emit($event)"
        (markAsSeenEvent)="markAsSeen(notification)"
        (markAsUnseenEvent)="markAsUnseen(notification)"
        (markAsFlaggedEvent)="markAsFlagged(notification)"
    >
    </app-profile-upload-notification-item>

    <mat-card
        [ngStyle]="notification.value.IS_SEEN ? {} : {'background-color': 'aliceblue', cursor: 'pointer'}"
        (click)="$event.stopPropagation(); markAsSeen(notification)"
        class="p-2 d-flex w-100 align-items-center async-notification-card mb-1"
        *ngIf="
            notification.value.status !== STATUS_INFO &&
            notification.value.action !== ACTION_PREPARE_PROFILES &&
            notification.value.action !== ACTION_PREPARE_DEALS &&
            notification.value.action !== ACTION_PREPARE_INVOICES &&
            notification.value.action !== IMPORT_PRODUCTS_SERVICES &&
            notification.value.action !== IMPORT_LEDGER_ACCOUNTS
        "
        style="font-size: 14px"
    >
        <div class="mr-2 timestamp">
            <div>{{ notification.value.created_at | amDateFormat: 'MM/DD/YYYY' }}</div>
            <span>{{ notification.value.created_at | amDateFormat: 'HH:mm:ss' }}</span>
            <button
                *ngIf="notification.value.IS_SEEN"
                disableRipple
                (click)="$event.stopPropagation(); markAsUnseen(notification)"
                class="notification-unread-btn"
                type="button"
                mat-icon-button
            >
                <mat-icon style="font-size: 14px; width: 18px">remove_red_eye</mat-icon>
            </button>
        </div>
        <div class="mr-2 mess-body ellipsis">
            <div *ngIf="notification.value.action === ACTION_SAVE_AS_CSV">Save as CSV</div>
            <div *ngIf="notification.value.action === ACTION_IMPORT_SHIPMENTS_FROM_CSV">Import Shipments from CSV</div>
            <div *ngIf="notification.value.action === ACTION_RECALCULATE">Deal Calculations</div>
            <div *ngIf="notification.value.action === ACTION_EXPORT_REPORT_IRIS_CSV">Export IRIS CSV File</div>
            <div *ngIf="notification.value.action === 'download_pay_stub'">Download PayStub PDFs</div>
            <div
                *ngIf="
                    notification.value.action !== ACTION_SAVE_AS_CSV &&
                    notification.value.action !== ACTION_IMPORT_SHIPMENTS_FROM_CSV &&
                    notification.value.action !== ACTION_ADDON_EVENT &&
                    notification.value.action !== 'skyslope_sync_error' &&
                    notification.value.action !== 'dotloop_sync_error' &&
                    notification.value.action !== 'download_pay_stub' &&
                    notification.value.action !== ACTION_RECALCULATE &&
                    notification.value.action !== ACTION_EXPORT_REPORT_IRIS_CSV
                "
            >
                {{ notification.value.action | fromSnakeCase }}
            </div>
            <div
                style="font-size: 10px"
                *ngIf="
                    notification.value.status === STATUS_WARNING &&
                    notification.value.action === 'download_pay_stub' &&
                    !notification.value.message?.message
                "
            >
                {{ notification.value.message }}
            </div>

            <!--<div style="font-size: 10px"-->
            <!--     *ngIf="notification.value.status === STATUS_ERROR && !notification.value.message?.message">{{notification.value.message}}</div>-->
            <!--<div style="font-size: 10px"-->
            <!--     *ngIf="notification.value.status === STATUS_ERROR && notification.value.message?.message">{{notification.value.message?.message}}</div>-->

            <div
                style="font-size: 10px"
                *ngIf="
                    notification.value.action === ACTION_REPROCESSING_UPLOADED_FILES &&
                    notification.value.payload.length
                "
            >
                Processed {{ reprocessDone(notification) }} of {{ notification.value.payload.length }}
            </div>

            <div
                style="font-size: 10px"
                *ngIf="
                    notification.value.action === ACTION_RECALCULATE &&
                    notification.value.payload &&
                    notification.value.payload.link
                "
            >
                Processed {{ notification.value.payload.link }}
            </div>

            <div
                style="font-size: 10px"
                *ngIf="
                    notification.value.action === ACTION_EXPORT_REPORT_CSV &&
                    notification.value.payload &&
                    notification.value.payload.counter
                "
            >
                Processed {{ notification.value.payload.counter }} item in chain.
            </div>

            <div
                style="font-size: 10px"
                *ngIf="
                    notification.value.action === ACTION_EXPORT_REPORT_PDF &&
                    notification.value.payload &&
                    notification.value.payload.counter
                "
            >
                Processed {{ notification.value.payload.counter }} item in chain.
            </div>

            <div
                style="font-size: 10px"
                *ngIf="notification.value.action === ACTION_EXPORT_REPORT_PDF && notification.value.payload"
            >
                <button class="mat-button btn-link" (click)="viewReportPdfFiles(notification.value)">
                    View File(s)
                </button>
            </div>

            <div style="font-size: 10px" *ngIf="notification.value.payload && notification.value.payload.files">
                <span>{{ notification.value.message }} </span>
                <button class="mat-button btn-link" (click)="viewReportFiles(notification.value)">View File(s)</button>
            </div>

            <div style="font-size: 10px" *ngIf="notification.value.link">
                <span>{{ notification.value.message }} </span>
                <span
                    >(<a (click)="$event.stopPropagation()" href="{{ notification.value.link }}"> Download File </a>)
                </span>
            </div>

            <!--message for dotloop/skyslope SYNC errors-->
            <!--<div class="ellipsis"-->
            <!--    *ngIf="notification.value.action === 'dotloop_sync_error'-->
            <!--        || notification.value.action === 'skyslope_sync_error'">-->
            <!--    <span style="font-size: 10px;"-->
            <!--        [title]="notification.value.message">-->
            <!--        {{notification.value.message}}-->
            <!--    </span>-->
            <!--</div>-->

            <div
                class="ellipsis"
                *ngIf="
                    notification.value.action === ACTION_ADDON_EVENT ||
                    notification.value.action === 'skyslope_sync_error' ||
                    notification.value.action === 'dotloop_sync_error'
                "
            >
                <!-- notification TITLE of addon should be in 'Skyslope (addon)' format where Skyslope is name of addon -->
                <div>
                    {{ notification.value?.metadata?.metadata?.addon_title.split('_')[0] || 'Addon Event' | titlecase }}
                    <span style="font-size: 10px">(addon)</span>
                </div>

                <!--deal name-->
                <div
                    [matTooltip]="notification.value.payload.deal_name"
                    class="ellipsis"
                    *ngIf="notification.value.payload.deal_name"
                >
                    <a
                        *ngIf="notification.value.payload.deal_id || notification.value.payload.related_deal_id"
                        (click)="$event.stopPropagation()"
                        [routerLink]="[
                            '/deals/',
                            notification.value.payload.deal_id || notification.value.payload.related_deal_id
                        ]"
                    >
                        {{ notification.value.payload.deal_name }}
                    </a>
                    <span *ngIf="!notification.value.payload.deal_id && !notification.value.payload.related_deal_id">
                        {{ notification.value.payload.deal_name }}
                    </span>
                </div>

                <span style="font-size: 10px" [matTooltip]="notification.value.message">{{
                    notification.value.message
                }}</span>
                <div *ngIf="notification.value.payload.related_deal_id">
                    (<a
                        (click)="$event.stopPropagation()"
                        [routerLink]="['/deals/edit/', notification.value.payload.related_deal_id]"
                        >Go to deal</a
                    >)
                </div>
                <div *ngIf="notification.value.payload.details_external_path">
                    (<a
                        (click)="$event.stopPropagation()"
                        [href]="
                            notification.value.metadata.metadata.addon_url +
                            notification.value.payload.details_external_path
                        "
                        target="_blank"
                        >click here to fix this issue</a
                    >)
                </div>
                <div *ngIf="notification.value.payload.details_internal_path">
                    (<a
                        (click)="$event.stopPropagation()"
                        [routerLink]="notification.value.payload.details_internal_path"
                        >Follow Here</a
                    >)
                </div>
            </div>
        </div>
        <span class="sp-spacer"></span>
        <button
            mat-icon-button
            color="primary"
            style="color: red; opacity: 0.6"
            *ngIf="notification.value.status === STATUS_START"
            matTooltip="Stop notification spin"
            (click)="$event.stopPropagation(); markAsWarningEvent.emit(notification)"
        >
            <mat-icon>remove_circle_outline</mat-icon>
        </button>
        <div style="display: flex; align-items: center">
            <button
                [ngClass]="isFlagged(notification.value) ? 'notification-flagged-btn--active' : ''"
                class="notification-flagged-btn"
                disableRipple
                (click)="$event.stopPropagation(); markAsFlagged(notification)"
                type="button"
                mat-icon-button
            >
                <mat-icon>flag</mat-icon>
            </button>
            <mat-icon *ngIf="notification.value.status === STATUS_WARNING" style="color: orange">warning</mat-icon>
            <mat-icon *ngIf="notification.value.status === STATUS_SUCCESS" style="color: green">check_circle</mat-icon>
            <mat-icon *ngIf="notification.value.status === STATUS_ERROR" style="color: red">cancel</mat-icon>
            <mat-spinner
                *ngIf="notification.value.status === STATUS_START"
                [diameter]="30"
                [strokeWidth]="4"
            ></mat-spinner>
        </div>
    </mat-card>
</ng-container>
