import {Component, Input, OnInit} from '@angular/core';
import {IReportsPreviewResultData} from '../../../../store/reports.reducer';
@Component({
    selector: 'reports-preview-recurring_invoice',
    templateUrl: './recurring_invoice.component.html',
    styleUrls: ['./recurring_invoice.component.scss']
})
export class RecurringInvoicePreviewComponent implements OnInit {
    @Input() reportData: IReportsPreviewResultData = {
        listOfRecurringInvoices: [],
        userData: [],
        totalInvoiceData: {}
    };
    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    tableColumns: IReportsPreviewResultData[] = [];
    allTableColumns: string[] = [];
    footerOutstandingColumns: IReportsPreviewResultData[] = [];
    allFooterOutstandingColumns: string[] = [];

    footerReceivedColumns: IReportsPreviewResultData[] = [];
    allFooterReceivedColumns: string[] = [];

    ngOnInit(): void {
        this.reportData.listOfRecurringInvoices = this.reportData.listOfRecurringInvoices || [];
        this.tableColumns = this.reportData.listOfRecurringInvoices.map((el: IReportsPreviewResultData) => {
            const date = new Date(el.date / 10000, ((el.date / 100) % 100) - 1, el.date % 100);
            return {
                title: el.recurringInvoice.title,
                value: el.id.toString(),
                data: el.recurringInvoice,
                date: date
            };
        });
        this.footerOutstandingColumns = this.tableColumns.map((el: IReportsPreviewResultData) => {
            return {value: el.value.concat('_footer_outstanding'), id: el.value};
        });
        this.footerReceivedColumns = this.tableColumns.map((el) => {
            return {value: el.value.concat('_footer_received'), id: el.value};
        });
        this.allTableColumns = ['name'];
        this.allFooterOutstandingColumns = ['totalOutstanding'];
        this.allFooterOutstandingColumns = this.allFooterOutstandingColumns.concat(
            this.footerOutstandingColumns.map((el) => el.value) as []
        );
        this.allFooterReceivedColumns = ['totalReceived'];
        this.allFooterReceivedColumns = this.allFooterReceivedColumns.concat(
            this.footerReceivedColumns.map((el) => el.value) as []
        );
        this.allTableColumns = this.allTableColumns.concat(this.tableColumns.map((el) => el.value) as []);
    }
}
