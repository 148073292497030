<mat-card
    (click)="$event.stopPropagation(); markAsSeen()"
    [ngStyle]="seen ? {} : {'background-color': 'aliceblue', cursor: 'pointer'}"
    class="p-2 d-flex w-100 align-items-center async-notification-card mb-1"
    *ngIf="
        notification &&
        notification.value &&
        [
            'prepare_profiles',
            'prepare_deals',
            'prepare_invoices',
            'import_products_services',
            'import_ledger_accounts'
        ].includes(notification.value.action)
    "
    style="font-size: 14px; margin-bottom: 4px"
>
    <div class="mr-2 timestamp">
        <div>{{ notification.value.created_at | amDateFormat: 'MM/DD/YYYY' }}</div>
        <span>{{ notification.value.created_at | amDateFormat: 'HH:mm:ss' }}</span>
        <button
            *ngIf="seen"
            disableRipple
            (click)="$event.stopPropagation(); markAsUnseen()"
            class="notification-unread-btn"
            type="button"
            mat-icon-button
        >
            <mat-icon style="font-size: 14px; width: 18px">remove_red_eye</mat-icon>
        </button>
    </div>
    <div class="mr-2 mess-body ellipsis">
        <div class="font-weight-bold">
            {{ notification.value.action | fromSnakeCase }}
        </div>

        <div
            style="font-size: 10px"
            *ngIf="notification.value.status === 'error' && !notification.value.message?.message"
        >
            {{ notification.value.message }}
        </div>
        <div
            style="font-size: 10px"
            *ngIf="notification.value.status === 'error' && notification.value.message?.message"
        >
            {{ notification.value.message?.message }}
        </div>

        <div *ngIf="notification.value.link" style="font-size: 10px">
            <span>{{ notification.value.message }} </span>
            <div><a href="" (click)="$event.preventDefault(); redirect()">Follow</a></div>
        </div>
    </div>
    <span class="sp-spacer"></span>
    <button
        mat-icon-button
        color="primary"
        style="color: red; opacity: 0.6"
        *ngIf="notification.value.status === 'start'"
        matTooltip="Stop notification spin"
        (click)="$event.stopPropagation(); markAsWarning(notification)"
    >
        <mat-icon>remove_circle_outline</mat-icon>
    </button>
    <div style="display: flex; align-items: center">
        <button
            [ngClass]="flagged ? 'notification-flagged-btn--active' : ''"
            class="notification-flagged-btn"
            disableRipple
            (click)="$event.stopPropagation(); markAsFlagged()"
            type="button"
            mat-icon-button
        >
            <mat-icon>flag</mat-icon>
        </button>
        <!--<span>{{notification.status}}</span>-->
        <mat-icon *ngIf="notification.value.status === 'warning'" style="color: orange">warning</mat-icon>
        <mat-icon *ngIf="notification.value.status === 'success'" style="color: green">check_circle</mat-icon>
        <mat-icon *ngIf="notification.value.status === 'error'" style="color: red">cancel</mat-icon>
        <mat-spinner *ngIf="notification.value.status === 'start'" [diameter]="30" [strokeWidth]="4"></mat-spinner>
    </div>
</mat-card>
