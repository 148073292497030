import {Component, Input} from '@angular/core';
import {Profile} from '../../../../models/profile';
import {DealsBoardBackendFilterSettingsByCompany} from '../../../deals/components/deal/common/deal.models';

@Component({
    selector: 'deals-vew-picker-container',
    styles: [
        `
            .row {
                display: flex;
                justify-content: flex-start;
                flex-direction: row;
            }
            .col {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
            }
            mat-form-field {
                margin: 4px;
                margin-bottom: 0;
            }
            .container {
                display: flex;
                flex-wrap: wrap;
            }
            .item {
                width: 200px;
            }
        `
    ],
    template: `
        <div class="container">
            <ng-container *ngIf="availableProfiles.length === 0">
                <div class="load-container">
                    <div class="loader">Loading...</div>
                </div>
            </ng-container>
            <ng-container *ngIf="availableProfiles.length > 0">
                <div *ngFor="let profile of availableProfiles" style="margin-left: 5%; margin-top: 8px">
                    <mat-checkbox [(ngModel)]="byCompany[profile.id!]!.enabled">{{
                        profile.company!.title
                    }}</mat-checkbox>
                    <div class="item">
                        <app-deals-view-picker-select
                            *ngIf="byCompany[profile.id!].enabled"
                            [currentProfile]="profile"
                            [pickedList]="byCompany[profile.id!].filter_by_deal_members"
                            [mode]="byCompany[profile.id!].deals_view_mode"
                            (modeChange)="byCompany[profile.id!].deals_view_mode = $event"
                        >
                        </app-deals-view-picker-select>
                    </div>
                </div>
            </ng-container>
        </div>
    `
})
export class DealsViewPickerContainerComponent {
    @Input() public byCompany: {[id: number]: DealsBoardBackendFilterSettingsByCompany} = {};
    @Input() public availableProfiles: Profile[] = [];

    checkAll() {
        Object.keys(this.byCompany).forEach((key) => {
            this.byCompany[Number(key)].enabled = true;
        });
    }

    uncheckAll() {
        Object.keys(this.byCompany).forEach((key) => {
            this.byCompany[Number(key)].enabled = false;
        });
    }

    constructor() {}
}
