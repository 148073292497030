import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {IReportCategory} from '../../../../typings';
import {Observable} from 'rxjs';

@Injectable()
export class ReportCategoriesService {
    private url = '/reports/categories';

    constructor(public requester: ServiceRequester) {}

    /**
     * Get reports categories list
     */
    getReportsCategoriesList(): Observable<{result: IReportCategory[]}> {
        return this.requester.makeMsCall$(this.url, 'GET', 'zipi');
    }

    /**
     * Create report category
     * @param data
     */
    createReportsCategory(data: IReportCategory): Observable<IReportCategory> {
        return this.requester.makeMsCall$(this.url, 'POST', 'zipi', data);
    }

    updateReportsCategory(id: number, data: object): Observable<IReportCategory> {
        return this.requester.makeMsCall$(`${this.url}/${id}`, 'PUT', 'zipi', data);
    }

    /**
     * Delete report category
     * @param id
     */
    deleteReportsCategory(id: number): Observable<boolean> {
        return this.requester.makeMsCall$(`${this.url}/${id}`, 'DELETE', 'zipi');
    }
}
