import {Component, Input} from '@angular/core';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {Contact} from '../../../../models/contact';
import {IContact} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-contact-edit-payment-methods-deal-payouts',
    template: `
        <div class="my-3" style="display: flex; flex-direction: column; height: 100%; align-items: baseline">
            <h3>Agent Disbursement Instructions:</h3>
            <mat-form-field style="width: 400px;" *ngIf="!view_mode">
                <input
                    type="text"
                    *ngIf="contactFormGroup.controls.disbursement_instructions"
                    [formControl]="contactFormGroup.controls.disbursement_instructions"
                    matInput
                    placeholder="Disbursement Instructions"
                    name="disbursement_instructions"
                />
            </mat-form-field>
            <div *ngIf="view_mode">
                <span *ngIf="contact.disbursement_instructions"
                    >Disbursement Instructions: {{ contact.disbursement_instructions }}</span
                >
                <span *ngIf="!contact.disbursement_instructions">No Instructions Have Been Defined.</span>
            </div>
        </div>
    `,
    styleUrls: ['../contact-edit.component.css']
})
export class DealPayoutsComponent {
    @Input() contactFormGroup: GenericFormGroup<Contact> = new GenericFormGroup<Contact>(
        new Contact().setDisbursementInstructions('Hello World')
    );
    @Input() contact: Contact | IContact = new Contact();
    @Input() view_mode: boolean = false;
    constructor() {}
}
