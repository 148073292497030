<mat-expansion-panel [expanded]="false" class="ml-3">
    <!-------- HEADER -------->
    <mat-expansion-panel-header [collapsedHeight]="'64px'">
        <mat-panel-title>
            <strong>Information</strong>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="d-grid" style="margin-left: -0.5em">
        <!-------- Property Class -------->
        <mat-form-field class="mx-2" *ngIf="dealFormGroup.controls.property_class">
            <mat-select
                placeholder="Property Class"
                required
                name="property_class"
                [formControl]="dealFormGroup.controls.property_class"
            >
                <mat-option *ngFor="let pclass of object_keys(DEAL.property_class_SET)" [value]="pclass">
                    {{ DEAL.property_class_LABELS[pclass] }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-------- Contract Date -------->
        <mat-form-field class="mx-2" *ngIf="dealFormGroup.controls.contract_date">
            <input
                matInput
                [matDatepicker]="picker_cd"
                name="contract_date"
                [formControl]="$any(dealFormGroup.controls.contract_date)"
                placeholder="Contract Date"
            />
            <mat-datepicker-toggle matSuffix [for]="picker_cd"></mat-datepicker-toggle>
            <mat-datepicker #picker_cd></mat-datepicker>
        </mat-form-field>

        <!-------- Listing Date -------->
        <mat-form-field class="mx-2" *ngIf="dealFormGroup.controls.listing_date">
            <input
                matInput
                [matDatepicker]="picker_ld"
                name="listing_date"
                [formControl]="$any(dealFormGroup.controls.listing_date)"
                placeholder="Listing Date"
            />
            <mat-datepicker-toggle matSuffix [for]="picker_ld"></mat-datepicker-toggle>
            <mat-datepicker #picker_ld></mat-datepicker>
        </mat-form-field>

        <!-------- Acceptance Date -------->
        <mat-form-field class="mx-2" *ngIf="dealFormGroup.controls.acceptance_date">
            <input
                matInput
                [matDatepicker]="picker_ad"
                name="acceptance_date"
                [formControl]="$any(dealFormGroup.controls.acceptance_date)"
                placeholder="Acceptance Date"
            />
            <mat-datepicker-toggle matSuffix [for]="picker_ad"></mat-datepicker-toggle>
            <mat-datepicker #picker_ad></mat-datepicker>
        </mat-form-field>

        <!-------- Original Price -------->
        <mat-form-field class="mx-2" *ngIf="dealFormGroup.controls.original_price">
            <input
                matInput
                type="number"
                pattern="^[0-9]\d*$"
                name="original_price"
                [formControl]="dealFormGroup.controls.original_price"
                placeholder="Original Price"
            />
        </mat-form-field>

        <!-------- Expiration Date -------->
        <mat-form-field class="mx-2" *ngIf="dealFormGroup.controls.expiration_date">
            <input
                matInput
                [matDatepicker]="picker_ed"
                name="expiration_date"
                [formControl]="$any(dealFormGroup.controls.expiration_date)"
                placeholder="Expiration Date"
            />
            <mat-datepicker-toggle matSuffix [for]="picker_ed"></mat-datepicker-toggle>
            <mat-datepicker #picker_ed></mat-datepicker>
        </mat-form-field>

        <!-------- MLS Organization -------->
        <mat-form-field class="mx-2" *ngIf="dealFormGroup.controls.mls_organisation_fk_id">
            <mat-select
                [formControl]="dealFormGroup.controls.mls_organisation_fk_id"
                placeholder="MLS Organization"
                name="mls_org"
            >
                <mat-option *ngFor="let organization of membershipOrganizations" [value]="organization.id">
                    {{ organization.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-------- MLS ID -------->
        <mat-form-field class="mx-2" *ngIf="dealFormGroup.controls.mls_id">
            <input
                matInput
                type="text"
                name="mls_id"
                [formControl]="dealFormGroup.controls.mls_id"
                placeholder="MLS ID"
            />
        </mat-form-field>

        <!-------- Sub-Type -------->
        <div class="ml-2">
            <mat-form-field style="width: calc(100% - 40px)" *ngIf="dealFormGroup.controls.sub_type">
                <mat-select [formControl]="dealFormGroup.controls.sub_type" placeholder="Sub-Type" name="sub_type">
                    <mat-option *ngFor="let type of subTypes" [value]="type.label">
                        {{ type.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button
                mat-icon-button
                type="button"
                rbac
                [rule]="{deals__manage_source_of_business: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                (click)="openDialog('SubType')"
            >
                <mat-icon>add_circle</mat-icon>
            </button>
        </div>

        <!-------- Sub-Source -------->
        <div class="ml-2">
            <mat-form-field style="width: calc(100% - 40px)" *ngIf="dealFormGroup.controls.sub_source">
                <mat-select [formControl]="dealFormGroup.controls.sub_source" placeholder="Sub-Source" name="sub_type">
                    <mat-option *ngFor="let type of subSources" [value]="type.label">
                        {{ type.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button
                mat-icon-button
                type="button"
                rbac
                [rule]="{deals__manage_source_of_business: true}"
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
                (click)="openDialog('SubSource')"
            >
                <mat-icon>add_circle</mat-icon>
            </button>
        </div>

        <!-------- Escrow Number -------->
        <mat-form-field class="mx-2" *ngIf="dealFormGroup.controls.escrow_number">
            <input
                matInput
                type="text"
                name="escrow_number"
                [formControl]="dealFormGroup.controls.escrow_number"
                placeholder="Escrow Number"
            />
        </mat-form-field>

        <!-------- Timezone -------->
        <mat-form-field class="mx-2" *ngIf="dealFormGroup.controls.timezone">
            <mat-select placeholder="Timezone" required name="timezone" [formControl]="dealFormGroup.controls.timezone">
                <mat-option *ngFor="let timezone of object_keys(DEAL.timezone_SET)" [value]="timezone">
                    {{ DEAL.getTimezoneLabel(timezone) }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-------- Source other -------->
        <mat-form-field
            class="mx-2"
            *ngIf="dealFormGroup && dealFormGroup.controls.source_of_business?.value?.toLowerCase() === 'other'"
        >
            <input
                matInput
                type="text"
                name="source_other"
                [formControl]="dealFormGroup.controls.source_other"
                placeholder="Source Other"
            />
        </mat-form-field>

        <!-------- Office Lead -------->
        <mat-form-field class="mx-2" *ngIf="dealFormGroup.controls.office_lead">
            <mat-select placeholder="Office Lead" name="office_lead" [formControl]="dealFormGroup.controls.office_lead">
                <mat-option *ngFor="let opt of booleanOptions" [value]="opt.value">
                    {{ opt.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-------- Custom fields (after named fields) -------->
        <ng-container *ngIf="dealFormGroup.controls.custom_deal_field_values">
            <ng-container
                [formGroup]="$any(customFieldForm)"
                *ngFor="let customFieldForm of dealFormGroup.controls.custom_deal_field_values.controls"
            >
                <ng-container
                    *ngIf="
                        customFieldForm.controls.custom_deal_field &&
                        customFieldForm.controls.custom_deal_field.controls.type
                    "
                    [ngSwitch]="customFieldForm.controls.custom_deal_field.controls.type.value"
                >
                    <!------- Boolean ------->
                    <div
                        class="mx-2 d-flex justify-content-between align-items-center"
                        *ngSwitchCase="DEAL.custom_deal_field_LABELS.boolean"
                    >
                        <mat-checkbox
                            [formControl]="customFieldForm.controls.value"
                            *ngIf="
                                customFieldForm.controls.value &&
                                customFieldForm.controls.custom_deal_field &&
                                customFieldForm.controls.custom_deal_field.controls.label
                            "
                        >
                            <span style="white-space: initial">
                                {{ transformLabel(customFieldForm.controls.custom_deal_field.controls.label.value) }}
                            </span>
                        </mat-checkbox>

                        <button
                            (click)="delete(customFieldForm.controls.custom_deal_field)"
                            mat-icon-button
                            type="button"
                            matTooltip="Remove value"
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>

                    <!------- Integer ------->
                    <div class="mx-2" *ngSwitchCase="DEAL.custom_deal_field_LABELS.number">
                        <mat-form-field
                            style="width: calc(100% - 40px)"
                            [formGroup]="$any(customFieldForm)"
                            *ngIf="
                                customFieldForm.controls.value &&
                                customFieldForm.controls.custom_deal_field &&
                                customFieldForm.controls.custom_deal_field.controls.label
                            "
                        >
                            <input
                                matInput
                                type="text"
                                [maskito]="numberMask"
                                [placeholder]="
                                    transformLabel(customFieldForm.controls.custom_deal_field.controls.label.value)
                                "
                                [formControl]="customFieldForm.controls.value"
                            />
                        </mat-form-field>

                        <button
                            (click)="delete(customFieldForm.controls.custom_deal_field)"
                            mat-icon-button
                            type="button"
                            matTooltip="Remove value"
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>

                    <!------- Float ------->
                    <div class="mx-2" *ngSwitchCase="DEAL.custom_deal_field_LABELS.float">
                        <mat-form-field
                            style="width: calc(100% - 40px)"
                            [formGroup]="$any(customFieldForm)"
                            *ngIf="
                                customFieldForm.controls.value &&
                                customFieldForm.controls.custom_deal_field &&
                                customFieldForm.controls.custom_deal_field.controls.label
                            "
                        >
                            <input
                                matInput
                                type="text"
                                [maskito]="floatMask"
                                [placeholder]="
                                    transformLabel(customFieldForm.controls.custom_deal_field.controls.label.value)
                                "
                                [formControl]="customFieldForm.controls.value"
                            />
                        </mat-form-field>

                        <button
                            (click)="delete(customFieldForm.controls.custom_deal_field)"
                            mat-icon-button
                            type="button"
                            matTooltip="Remove value"
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>

                    <!------- String (text) ------->
                    <div class="mx-2" *ngSwitchCase="DEAL.custom_deal_field_LABELS.text">
                        <mat-form-field
                            style="width: calc(100% - 40px)"
                            [formGroup]="$any(customFieldForm)"
                            *ngIf="
                                customFieldForm.controls.value &&
                                customFieldForm.controls.custom_deal_field &&
                                customFieldForm.controls.custom_deal_field.controls.label
                            "
                        >
                            <input
                                matInput
                                type="text"
                                [placeholder]="
                                    transformLabel(customFieldForm.controls.custom_deal_field.controls.label.value)
                                "
                                [formControl]="customFieldForm.controls.value"
                            />
                        </mat-form-field>

                        <button
                            mat-icon-button
                            type="button"
                            matTooltip="Remove value"
                            (click)="delete(customFieldForm.controls.custom_deal_field)"
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>

                    <!------- Date ------->
                    <div class="mx-2" *ngSwitchCase="DEAL.custom_deal_field_LABELS.date">
                        <div
                            class="d-inline-block"
                            style="width: calc(100% - 40px)"
                            [formGroup]="$any(customFieldForm)"
                        >
                            <app-date-picker
                                *ngIf="
                                    customFieldForm.controls.custom_deal_field &&
                                    customFieldForm.controls.custom_deal_field.controls.label &&
                                    customFieldForm.controls.custom_deal_field.controls.options
                                "
                                [placeholder]="
                                    transformLabel(customFieldForm.controls.custom_deal_field.controls.label.value)
                                "
                                [valueType]="customFieldForm.controls.custom_deal_field.controls.options.value[0]"
                                [dateControl]="customFieldForm.controls.value"
                            ></app-date-picker>
                        </div>

                        <button
                            (click)="delete(customFieldForm.controls.custom_deal_field)"
                            mat-icon-button
                            type="button"
                            matTooltip="Remove value"
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>

                    <!------- Select ------->
                    <div class="mx-2" *ngSwitchCase="DEAL.custom_deal_field_LABELS.select">
                        <mat-form-field
                            style="width: calc(100% - 40px)"
                            [formGroup]="$any(customFieldForm)"
                            *ngIf="
                                customFieldForm.controls.value &&
                                customFieldForm.controls.custom_deal_field &&
                                customFieldForm.controls.custom_deal_field.controls.label
                            "
                        >
                            <mat-select
                                class="custom-input"
                                [placeholder]="
                                    transformLabel(customFieldForm.controls.custom_deal_field.controls.label.value)
                                "
                                [formControl]="customFieldForm.controls.value"
                            >
                                <ng-container
                                    *ngIf="
                                        customFieldForm.controls.custom_deal_field &&
                                        customFieldForm.controls.custom_deal_field.controls.options
                                    "
                                >
                                    <mat-option
                                        *ngFor="
                                            let type of customFieldForm.controls.custom_deal_field.controls.options
                                                .value
                                        "
                                        [value]="type"
                                    >
                                        {{ type }}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>

                        <button
                            (click)="delete(customFieldForm.controls.custom_deal_field)"
                            mat-icon-button
                            type="button"
                            matTooltip="Remove value"
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>

        <!-------- Show custom field (should always be the last) -------->
        <div class="mx-2">
            <button
                mat-raised-button
                [disabled]="dealFormGroup.controls.custom_deal_field_values?.disabled"
                [matMenuTriggerFor]="customFields"
            >
                More Fields
            </button>
            <mat-menu #customFields="matMenu" class="custom-fields-menu">
                <button
                    mat-menu-item
                    class="font-italic create-custom-field"
                    (click)="$event.stopPropagation(); openCustomDialog()"
                >
                    Create New +
                </button>
                <button
                    mat-menu-item
                    class="d-flex justify-content-between align-items-center custom-add-field"
                    *ngFor="let field of listOfCustomFields; index as idx"
                    (click)="toggleCustomField(field.field)"
                >
                    {{ field.label }}
                    <button
                        class="ml-4 custom-add-field-button-icon"
                        type="button"
                        mat-icon-button
                        rbac
                        [rule]="{deals__manage_custom_fields: true}"
                        [denyMethod]="'style'"
                        [allowStyle]="{display: 'inline-block'}"
                        [denyStyle]="{display: 'none'}"
                        (click)="$event.stopPropagation(); openCustomDialog(idx)"
                    >
                        <mat-icon class="mr-0 custom-add-field-icon">edit</mat-icon>
                    </button>
                </button>
            </mat-menu>
        </div>
    </div>
</mat-expansion-panel>
