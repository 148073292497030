import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from 'app/services/auth.service';
import {UserService} from 'app/services/user.service';

@Injectable()
export class AdminBootstrapService implements Resolve<{}> {
    constructor(
        protected authService: AuthService,
        protected router: Router,
        protected userService: UserService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.load();
    }

    load() {
        return true;
    }
}
