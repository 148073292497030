<div class="bg-white">
    <div class="d-flex align-items-center justify-content-between flex-wrap p-3 border-bottom">
        <h3 class="mr-4 mb-0">QuickBooks Integration</h3>
    </div>
    <div class="d-flex flex-column align-items-baseline p-5">
        <h3 class="mb-3">Connect to QuickBooks Account</h3>

        <div class="text-secondary mb-3">
            Seamlessly sync your QuickBooks account to ensure your deals and financial ledgers are consistently updated
            and tracked across all platforms.
        </div>

        <button mat-raised-button class="mat-primary" (click)="connectQB()">Get Started</button>
    </div>
</div>
