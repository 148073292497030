<button mat-icon-button class="close-button" mat-dialog-close="true" tabindex="-1" (click)="dialogRef.close('return')">
    <mat-icon>close</mat-icon>
</button>

<div mat-dialog-title>
    <h3>{{ data.title }}</h3>
</div>

<mat-dialog-content>
    <p *ngIf="data.message">
        {{ data.message }}
    </p>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
    <button class="mr-2" mat-flat-button (click)="dialogRef.close('return')" tabindex="-1">Back</button>
    <button class="mr-2" mat-raised-button color="primary" (click)="dialogRef.close('continue')" tabindex="-1">
        Continue
    </button>
</mat-dialog-actions>
