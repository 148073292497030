<div mat-dialog-title>
    <h3>{{ data.title }}</h3>
</div>

<mat-dialog-content>
    <div *ngFor="let ipt of inputs.controls; index as idx">
        <mat-form-field>
            <input matInput required type="text" [formControl]="ipt" [placeholder]="data.placeholders[idx]" />
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button [disabled]="inputs.invalid" mat-raised-button color="accent" (click)="submitData()">Ok</button>
    <button mat-raised-button (click)="dialogRef.close()" tabindex="-1">Cancel</button>
</mat-dialog-actions>
