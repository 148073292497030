import {Company} from '../../../../models/company';
import {Group} from '../../../../models/group';
import {Profile} from '../../../../models/profile';
import {FinancialTransferEntity} from './financial-transfer.entity';

export class DisbursementTemplateModel {
    id: number | null = null;
    company: Company | null = null;
    company_group: Group | null = null;
    profile: Profile | null = null;
    title: string = '';
    additional_incomes: FinancialTransferEntity[] = [];
    additional_expenses: FinancialTransferEntity[] = [];
}
