import {assign} from 'lodash-es';
import {IBill, IInvoice, ILedgerAccount, IProduct, IRecurringInvoice} from '@cyberco-nodejs/zipi-typings';
import {FinanceActions, FinanceActionTypes} from './finance.actions';

export interface IFinanceState {
    invoices: IInvoice[];
    bills: IBill[];
    recurringInvoices: IRecurringInvoice[];
    ledgerAccounts: ILedgerAccount[];
    products: IProduct[];
}

const initFinanceState: IFinanceState = {
    invoices: [],
    bills: [],
    recurringInvoices: [], // <any>MOCK_RECURRING_INVOICES,
    ledgerAccounts: [], // MOCK_LEDGER_ACCOUNTS,
    products: [] // <any>PRODUCTS_MOCK,
};

export function financeReducer(state: IFinanceState = initFinanceState, action: FinanceActions): IFinanceState {
    switch (action.type) {
        case FinanceActionTypes.FETCH_INVOICES_SUCCESS:
            return assign({}, state, {
                invoices: action.payload!
            });

        case FinanceActionTypes.FETCH_BILLS_SUCCESS:
            return assign({}, state, {
                bills: action.payload!
            });

        case FinanceActionTypes.CREATE_RECURRING_INVOICE:
            return assign({}, state, {
                recurringInvoices: [action.payload!].concat(state.recurringInvoices)
            });

        case FinanceActionTypes.CREATE_RECURRING_INVOICE_SUCCESS:
            return assign({}, state, {
                recurringInvoices: state.recurringInvoices.map((ri) => (!ri.id ? action.payload! : ri))
            });

        case FinanceActionTypes.UPDATE_RECURRING_INVOICE_SUCCESS:
            if (action.payload!.ok === false) {
                return state;
            }

            return assign({}, state, {
                recurringInvoices: state.recurringInvoices.map((ri) =>
                    ri.id === action.payload!.invoice.id ? action.payload!.invoice : ri
                )
            });

        case FinanceActionTypes.DELETE_RECURRING_INVOICE_SUCCESS:
            return assign({}, state, {
                recurringInvoices: state.recurringInvoices.filter((ri) => ri.id !== action.payload!)
            });

        case FinanceActionTypes.FETCH_PRODUCTS_SUCCESS:
            return assign({}, state, {
                products: action.payload!
            });

        case FinanceActionTypes.CREATE_PRODUCT:
            return assign({}, state, {
                products: [action.payload!].concat(state.products)
            });

        case FinanceActionTypes.CREATE_PRODUCT_SUCCESS:
            return assign({}, state, {
                products: state.products.map((ri) => (!ri.id ? action.payload! : ri))
            });

        case FinanceActionTypes.UPDATE_PRODUCT_SUCCESS:
            if (action.payload!.ok === false) {
                return state;
            }

            return assign({}, state, {
                products: state.products.map((ri) =>
                    ri.id === action.payload!.product.id ? action.payload!.product : ri
                )
            });

        case FinanceActionTypes.DELETE_PRODUCT_SUCCESS:
            return assign({}, state, {
                products: state.products.filter((ri) => ri.id !== action.payload!)
            });

        case FinanceActionTypes.FETCH_LEDGER_ACCOUNTS_SUCCESS:
            return assign({}, state, {
                ledgerAccounts: action.payload!
            });

        case FinanceActionTypes.CREATE_LEDGER_ACCOUNT:
            return assign({}, state, {
                ledgerAccounts: [action.payload!].concat(state.ledgerAccounts)
            });

        case FinanceActionTypes.CREATE_LEDGER_ACCOUNT_SUCCESS:
            return assign({}, state, {
                ledgerAccounts: state.ledgerAccounts.map((ri) => (!ri.id ? action.payload! : ri))
            });

        case FinanceActionTypes.UPDATE_LEDGER_ACCOUNT_SUCCESS:
            if (action.payload!.ok === false) {
                return state;
            }

            return assign({}, state, {
                ledgerAccounts: state.ledgerAccounts.map((ri) =>
                    ri.id === action.payload!.account.id ? action.payload!.account : ri
                )
            });

        case FinanceActionTypes.DELETE_LEDGER_ACCOUNT_SUCCESS:
            return assign({}, state, {
                ledgerAccounts: state.ledgerAccounts.filter((ri) => ri.id !== action.payload!)
            });

        default:
            return state;
    }
}
