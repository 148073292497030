import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../service.requester';
import {Observable} from 'rxjs';
import {ILoan} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class LoansService {
    private url = '/finance/loans/';

    constructor(public requester: ServiceRequester) {}

    getLoansByTypeList(
        type: 'advance' | 'garnishment',
        params: any = {}
    ): Observable<{result: ILoan[]; _meta: {total: number}}> {
        return this.requester.makeMsCall$(this.url + type, 'GET', 'shipp', params);
    }

    getLoanById(loanId: number): Observable<ILoan> {
        return this.requester.makeMsCall$(this.url + `${loanId}`, 'GET', 'shipp');
    }

    createLoan(data: Partial<ILoan>): Observable<ILoan> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    updateLoan(data: ILoan): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + data.loan_id, 'PUT', 'shipp', data);
    }

    deleteLoan(loan_id: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + loan_id, 'DELETE', 'shipp');
    }

    getDeductedLoansByContactId(contactId: number): Observable<ILoan[]> {
        return this.requester.makeMsCall$(this.url + `deducted/${contactId}`, 'GET', 'shipp');
    }

    getUserLoans(params: any = {}): Observable<{result: ILoan[]; _meta: {total: number}}> {
        return this.requester.makeMsCall$(this.url + `user-loans`, 'GET', 'shipp', params);
    }
}
