<div class="report-layout">
    <div class="reports-header w-100 px-4">
        <div class="d-flex align-items-center justify-content-between h-100">
            <!------- Button board list ------->
            <h3 class="mb-0 d-flex align-items-center">{{ menuTitle }}</h3>
            <button mat-raised-button color="primary" (click)="toggleEditReportSidebar(true)">Create Report</button>
        </div>
    </div>

    <app-report-edit-bar>
        <router-outlet></router-outlet>
    </app-report-edit-bar>
</div>
