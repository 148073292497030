import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {IContactsState} from '../store/contacts.reducer';
import {IAvailabledForContact, IContact, IContactPayroll, IContactPayrollHistory} from '@cyberco-nodejs/zipi-typings';
import {firstValueFrom, Subject} from 'rxjs';
import * as moment from 'moment';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {UpdContact} from '../store/contacts.actions';
import {assign} from 'lodash-es';
import {PAYROLL_FILING_STATUSES} from '../../../local-typings';
import {takeUntil} from 'rxjs/operators';
import {AddContactPayroll1099DialogComponent} from '../contact-dialogs/add-contact-payroll-1099-dialog/add-contact-payroll-1099-dialog.component';
import {AddContactPayrollW2DialogComponent} from '../contact-dialogs/add-contact-payroll-w2-dialog/add-contact-payroll-w2-dialog.component';
import {ShipperContactsService} from '../../../services/api/shipper.contacts.service';
import {MatTableDataSource} from '@angular/material/table';
import {FeatureFlagsService} from '../../feature-flags/feature-flags.service';

@Component({
    selector: 'app-contact-payrolls',
    templateUrl: 'contact-payrolls.component.html',
    styleUrls: ['contact-payrolls.component.scss']
})
export class ContactPayrollsComponent implements OnInit, OnDestroy {
    @Input() contact: IContact | IAvailabledForContact | undefined;
    @Input() isEditMode: boolean = false;
    private unsubscribe: Subject<void> = new Subject();

    contactPayrollsMisc: IContactPayroll[] = [];
    contactPayrollsW: IContactPayroll[] = [];

    currentPayrollMiscId: UntypedFormControl | undefined;
    currentPayrollMisc: IContactPayroll | undefined;
    activePayrollMiscId: number | undefined;

    currentPayrollWId: UntypedFormControl | undefined;
    currentPayrollW: IContactPayroll | undefined;
    activePayrollWId: number | undefined;

    filingStatuses = PAYROLL_FILING_STATUSES;

    payrollHistoryMatTableDataSource: MatTableDataSource<IContactPayrollHistory>;
    displayedColumns = ['formName', 'datePrinted', 'total', 'submissionType'];

    public additional1099ReportsEnabledFlag = false;

    constructor(
        public dialog: MatDialog,
        private store: Store<IContactsState>,
        private fb: UntypedFormBuilder,
        private contactsService: ShipperContactsService,
        protected featureFlagsService: FeatureFlagsService
    ) {
        this.payrollHistoryMatTableDataSource = new MatTableDataSource<IContactPayrollHistory>();
    }

    ngOnInit(): void {
        this.initPayrolls();

        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.additional1099ReportsEnabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'reports:additional_1099_reports'
                );
            });
    }

    initPayrolls() {
        if (this.contact && this.contact.contact_payrolls && this.contact.contact_payrolls.length) {
            this.currentPayrollMiscId = this.fb.control(null, []);
            this.currentPayrollWId = this.fb.control(null, []);

            this.contactPayrollsMisc = this.contact.contact_payrolls
                .filter((payroll) => payroll.type === '1099_misc')
                .sort((a, b) => a.start_date - b.start_date);
            for (const payroll of this.contactPayrollsMisc) {
                if (payroll.start_date <= Number(moment(new Date()).format('YYYYMMDD'))) {
                    this.currentPayrollMisc = payroll;
                    this.currentPayrollMiscId.patchValue(payroll.contact_payroll_id);
                    this.activePayrollMiscId = payroll.contact_payroll_id;
                } else if (payroll.start_date > Number(moment(new Date()).format('YYYYMMDD'))) {
                    break;
                }
            }

            if (this.currentPayrollMiscId.value) {
                this.getContactPayrollHistory(this.currentPayrollMiscId.value);
            }

            this.contactPayrollsW = this.contact.contact_payrolls
                .filter((payroll) => payroll.type === 'w_2')
                .sort((a, b) => a.start_date - b.start_date);
            for (const payroll of this.contactPayrollsW) {
                if (payroll.start_date <= Number(moment(new Date()).format('YYYYMMDD'))) {
                    this.currentPayrollW = payroll;
                    this.currentPayrollWId.patchValue(payroll.contact_payroll_id);
                    this.activePayrollWId = payroll.contact_payroll_id;
                } else if (payroll.start_date > Number(moment(new Date()).format('YYYYMMDD'))) {
                    break;
                }
            }

            this.currentPayrollMiscId.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
                if (value) {
                    this.currentPayrollMisc = this.contact!.contact_payrolls!.find(
                        (payroll) => payroll.contact_payroll_id === value
                    );

                    this.getContactPayrollHistory(value);
                }
            });

            this.currentPayrollWId.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
                if (value) {
                    this.currentPayrollW = this.contact!.contact_payrolls!.find(
                        (payroll) => payroll.contact_payroll_id === value
                    );
                }
            });
        }
    }

    getContactPayrollHistory(contactPayrollId: number) {
        this.contactsService
            .getContactPayrollHistory(contactPayrollId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((payrollHistory) => {
                this.payrollHistoryMatTableDataSource.data = payrollHistory;
            });
    }

    add1099Payroll() {
        const contact = this.contact;
        if (contact) {
            const dialogRef = this.dialog.open(AddContactPayroll1099DialogComponent, {
                panelClass: 'no-padding-dialog',
                minWidth: 320,
                maxWidth: 750,
                data: {
                    contact: contact,
                    type: '1099_misc'
                }
            });
            this.addPayroll(dialogRef, contact);
        }
    }

    addW2Payroll() {
        const contact = this.contact;
        if (contact) {
            const dialogRef = this.dialog.open(AddContactPayrollW2DialogComponent, {
                panelClass: 'no-padding-dialog',
                minWidth: 320,
                maxWidth: 750,
                data: {
                    contact: contact,
                    type: 'w_2'
                }
            });
            this.addPayroll(dialogRef, contact);
        }
    }

    addPayroll(
        dialogRef: MatDialogRef<AddContactPayroll1099DialogComponent | AddContactPayrollW2DialogComponent>,
        contact: IContact | IAvailabledForContact
    ) {
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((contact_payroll) => {
                if (contact_payroll) {
                    let contactPayrolls = [];
                    if (contact.contact_payrolls && contact.contact_payrolls.length) {
                        contactPayrolls = [...contact.contact_payrolls, ...[contact_payroll]];
                    } else {
                        contactPayrolls = [contact_payroll];
                    }
                    this.store.dispatch(
                        new UpdContact({
                            data: assign({}, contact, {
                                contact_payrolls: contactPayrolls
                            })
                        })
                    );
                }
            });
    }

    edit1099Payroll(payroll: IContactPayroll) {
        const contact = this.contact;
        if (contact) {
            const dialogRef = this.dialog.open(AddContactPayroll1099DialogComponent, {
                minWidth: 320,
                maxWidth: 750,
                data: {
                    payroll: payroll,
                    contact: contact,
                    type: '1099_misc'
                }
            });
            this.editPayroll(dialogRef, contact);
        }
    }

    editW2Payroll(payroll: IContactPayroll) {
        const contact = this.contact;
        if (contact) {
            const dialogRef = this.dialog.open(AddContactPayrollW2DialogComponent, {
                minWidth: 320,
                maxWidth: 750,
                data: {
                    payroll: payroll,
                    contact: contact,
                    type: 'w_2'
                }
            });
            this.editPayroll(dialogRef, contact);
        }
    }

    editPayroll(
        dialogRef: MatDialogRef<AddContactPayroll1099DialogComponent | AddContactPayrollW2DialogComponent>,
        contact: IContact | IAvailabledForContact
    ) {
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((contact_payroll) => {
                if (contact_payroll) {
                    const contact_payrolls = contact.contact_payrolls!.map((item) => {
                        if (item.contact_payroll_id === contact_payroll.contact_payroll_id) {
                            return {...item, ...contact_payroll};
                        } else {
                            return item;
                        }
                    });
                    this.store.dispatch(
                        new UpdContact({
                            data: assign({}, contact, {
                                contact_payrolls: contact_payrolls
                            })
                        })
                    );
                }
            });
    }

    payrollRemove(payroll: IContactPayroll) {
        if (this.contact) {
            const contact_payrolls = this.contact.contact_payrolls!.filter(
                (contact_payroll) => contact_payroll.contact_payroll_id !== payroll.contact_payroll_id
            );
            this.store.dispatch(
                new UpdContact({
                    data: assign({}, this.contact, {
                        contact_payrolls: contact_payrolls
                    })
                })
            );
        }
    }

    getStatusLabel(value: string) {
        const item = this.filingStatuses.find((statusItem) => statusItem.value === value);
        if (item) {
            return item.label;
        }
        return value;
    }

    getTinWithMask(payroll: IContactPayroll) {
        if (!payroll) {
            return 'Invalid TIN';
        }
        if (payroll.tin_type === 'ssn') {
            return 'XXX-XX-XXXX';
        } else if (payroll.tin_type === 'ein') {
            return 'XX-XXXXXXX';
        } else {
            return 'XXXXXXXXX';
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
