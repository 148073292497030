import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IContact, IPaymentGateway} from '@cyberco-nodejs/zipi-typings';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PaymentMethodsService} from '../../../modules/profile/services/payment-methods.service';
import {InvoicePublicService} from '../../../modules/finance/services/invoice-public.service';
import {NotificationsService} from 'angular2-notifications';
import {GatewayService} from '../../../modules/profile/services/gateway.service';

const win: {[key: string]: any} = window;
const Accept = win.Accept;

@Component({
    selector: 'app-add-my-credit-card-for-contact-dialog',
    templateUrl: './add-my-credit-card-for-contact-dialog.component.html',
    styleUrls: ['./add-my-credit-card-for-contact-dialog.component.scss']
})
export class AddMyCreditCardForContactDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    countryList: Array<{[key: string]: any}> = [
        {label: 'Belgium', slug: 'Belgium'},
        {label: 'Canada', slug: 'Canada'},
        {label: 'France', slug: 'France'},
        {label: 'Germany', slug: 'Germany'},
        {label: 'Mexico', slug: 'Mexico'},
        {label: 'USA', slug: 'USA'}
    ];

    cardForm: UntypedFormGroup | undefined;

    companyGateways: Array<IPaymentGateway> | undefined;

    authorizeMerchantGateway: UntypedFormControl | undefined;

    invoiceAuthorizeAvailable: boolean;

    forContact: IContact | undefined;

    showAccordion = false;

    isLoading: boolean;

    constructor(
        public dialogRef: MatDialogRef<AddMyCreditCardForContactDialogComponent>,
        private invoicePublicService: InvoicePublicService,
        private paymentMethodsService: PaymentMethodsService,
        private gatewayService: GatewayService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        protected dialog: MatDialog
    ) {
        this.invoiceAuthorizeAvailable = false;
        this.isLoading = false;
    }

    ngOnInit() {
        this.forContact = this.data.contact;

        if (typeof this.forContact !== 'undefined' && this.forContact.partner__company_fk_id) {
            this.gatewayService
                .getCompanyGatewaysByCompanyId(this.forContact.partner__company_fk_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateways) => {
                    this.companyGateways = gateways;
                    this.authorizeMerchantGateway = this.fb.control(
                        this.companyGateways.filter((gateway) => gateway.type === 'authorize_net_merchant')[0],
                        Validators.required
                    );

                    this.isLoading = true;
                });
        }

        this.initForm();

        // dirty hack to prevent expand mat-accordion panels
        setTimeout(() => {
            this.showAccordion = true;
        }, 10);
    }

    initForm() {
        this.cardForm = this.fb.group({
            cardNumber: ['', Validators.required],
            expMonth: ['', Validators.required],
            expYear: ['', Validators.required],
            cardCode: ['', Validators.required],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            address: ['', Validators.required],
            city: ['', Validators.required],
            zipCode: [null, Validators.required],
            state: ['', Validators.required],
            country: ['', Validators.required],
            use_as_default: [false, Validators.required]
        });
    }

    close() {
        this.dialogRef.close();
    }

    cardTitleBuild(): string {
        if (typeof this.cardForm !== 'undefined') {
            return 'XXXX XXXX XXXX ' + this.cardForm.getRawValue().cardNumber.slice(-4);
        }
        return '';
    }

    expirationDateBuild(): string {
        if (typeof this.cardForm !== 'undefined') {
            return `${this.cardForm.getRawValue().expMonth}/${this.cardForm.getRawValue().expYear}`;
        }
        return '';
    }

    async saveCard() {
        if (typeof this.cardForm !== 'undefined' && this.cardForm.invalid) {
            this.cardForm.markAllAsTouched();
        } else {
            if (typeof this.authorizeMerchantGateway !== 'undefined' && this.authorizeMerchantGateway.invalid) {
                this.authorizeMerchantGateway.markAllAsTouched();
            } else {
                let successResponse: any = null;

                const gateway = this.authorizeMerchantGateway?.value;
                const publicKey = await this.getPublicKey(gateway.id);

                const authData: {[key: string]: any} = {};
                authData['clientKey'] = publicKey;
                authData['apiLoginID'] = gateway.settings.api_login;

                const cardFormData = this.cardForm?.getRawValue();

                const cardData: {[key: string]: any} = {};
                cardData['cardNumber'] = cardFormData.cardNumber;
                cardData['month'] = cardFormData.expMonth;
                cardData['year'] = cardFormData.expYear;
                cardData['cardCode'] = cardFormData.cardCode;
                cardData['use_as_default'] = cardFormData.use_as_default;

                const billingData: {[key: string]: any} = {};
                billingData['firstName'] = cardFormData.firstName;
                billingData['lastName'] = cardFormData.lastName;
                billingData['address'] = cardFormData.address;
                billingData['city'] = cardFormData.city;
                billingData['zipCode'] = cardFormData.zipCode;
                billingData['state'] = cardFormData.state;
                billingData['country'] = cardFormData.country;
                billingData['use_as_default'] = cardFormData.use_as_default;

                const secureData: {[key: string]: any} = {};
                secureData['authData'] = authData;
                secureData['cardData'] = cardData;

                const cardDataForSaving = {
                    card_number: cardFormData.cardNumber,
                    month: cardFormData.expMonth,
                    year: cardFormData.expYear,
                    cardCode: cardFormData.cardCode,
                    zipCode: cardFormData.zipCode
                };

                const cardBillingDataForSaving = {
                    firstName: cardFormData.firstName,
                    lastName: cardFormData.lastName,
                    address: cardFormData.address,
                    city: cardFormData.city,
                    zipCode: cardFormData.zipCode,
                    state: cardFormData.state,
                    country: cardFormData.country
                };

                Accept.dispatchData(secureData, (response: any) => {
                    if (!successResponse) {
                        if (response.messages.resultCode === 'Error') {
                            let i = 0;
                            while (i < response.messages.message.length) {
                                console.info(
                                    response.messages.message[i].code + ': ' + response.messages.message[i].text
                                );
                                i = i + 1;
                            }
                        } else {
                            successResponse = response;

                            const paymentObject = {
                                gatewayId: this.authorizeMerchantGateway?.value.payment_gateway_id,
                                payToPaymentId: null,
                                opaqueData: successResponse.opaqueData,
                                cardTitle: this.cardTitleBuild(),
                                billingData: billingData,
                                invoiceId: null,
                                expirationDate: this.expirationDateBuild()
                            };
                            if (this.forContact && this.forContact.contact_id) {
                                this.paymentMethodsService
                                    .addCardForCompany(paymentObject, this.forContact.contact_id)
                                    .pipe(takeUntil(this.unsubscribe))
                                    .subscribe((res) => {
                                        if (res) {
                                            this.dialogRef.close(true);
                                        }
                                    });
                            }
                        }
                    } else {
                        const paymentObject = {
                            gatewayId: this.authorizeMerchantGateway?.value.payment_gateway_id,
                            payToPaymentId: null,
                            opaqueData: successResponse.opaqueData,
                            cardTitle: this.cardTitleBuild(),
                            billingData: billingData,
                            invoiceId: null,
                            expirationDate: this.expirationDateBuild()
                        };

                        if (this.forContact && this.forContact.contact_id) {
                            this.paymentMethodsService
                                .addCardForCompany(paymentObject, this.forContact?.contact_id)
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((res) => {
                                    if (res) {
                                        this.dialogRef.close(true);
                                    }
                                });
                        }
                    }
                });
            }
        }
    }

    async getPublicKey(gatewayId: number) {
        return await this.invoicePublicService.getPublicKey(gatewayId).toPromise();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
