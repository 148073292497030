import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {IPaymentGateway} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-dwolla-gateway-edit-dialog',
    templateUrl: './zipi-fin-gateway-edit-dialog.component.html',
    styleUrls: ['./zipi-fin-gateway-edit-dialog.component.scss']
})
export class ZipiFinGatewayEditDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    currentGateway: IPaymentGateway | undefined;

    gatewayForm: UntypedFormGroup | undefined;

    constructor(
        public dialogRef: MatDialogRef<ZipiFinGatewayEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder
    ) {}

    ngOnInit() {
        this.currentGateway = this.data.gateway;
        this.initForm();
    }

    initForm() {
        if (this.currentGateway) {
            this.gatewayForm = this.fb.group({
                title: [this.currentGateway.title, [Validators.required]]
            });
        }
    }

    continue() {
        if (this.gatewayForm) {
            if (this.gatewayForm.invalid) {
                this.gatewayForm.markAllAsTouched();
                return;
            } else {
                const gatewayModifiedData = this.gatewayForm.getRawValue();

                this.dialogRef.close(gatewayModifiedData);
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
