import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';

@Injectable()
export class OAuthApi {
    constructor(public requester: ServiceRequester) {}

    // async generateAuthorizationCode(oauthClientId: string, oauthState = '', oAuthScope = 'profile_access'): Promise<any> {
    //     return this.requester.makeMsCall(
    //         '/oauth/generate-code',
    //         'GET',
    //         {client_id: oauthClientId, oauth_state: oauthState, oauth_scope: oAuthScope}
    //     ).then(res => {
    //         return res;
    //     });
    // }
}
