<div>
    <!--Content Container-->
    <div style="padding: 24px 24px 0 24px">
        <!--Header-->
        <div class="d-flex">
            <h2>Skip Payment</h2>
            <span class="sp-spacer"></span>
            <mat-icon class="sp-cursor-pointer" (click)="close()">close</mat-icon>
        </div>

        <!--Payouts List-->
        <div>
            <!--Table Header-->
            <div class="table-header">
                <div class="w-60">Payment To</div>
                <div class="w-40">Amount</div>
            </div>

            <!--Items Section-->
            <div style="height: 400px; max-height: 45vh; overflow-y: auto">
                <div *ngIf="itemsArray && itemsArray.length > 0 && itemsArrayBuilt">
                    <!--Item-->
                    <div *ngFor="let payoutGroup of itemsArray.controls">
                        <div [formGroup]="payoutGroup" class="list-item">
                            <div class="w-60 d-flex">
                                <span>{{ payoutGroup.controls.pay_to_name.value }}</span>
                            </div>
                            <div class="w-40">{{ payoutGroup.controls.amount.value | currency }}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!itemsArrayBuilt" class="w-100 text-center mt-2">Data Loading...</div>
                <div *ngIf="!itemsArray || (itemsArray.length === 0 && itemsArrayBuilt)" class="w-100 text-center mt-2">
                    Nothing to Display
                </div>
            </div>
        </div>
    </div>
</div>

<div>
    <div class="action-section">
        <button mat-button color="primary" class="ml-2" (click)="dialogRef.close(false)" tabindex="-1">Back</button>
        <button
            mat-raised-button
            class="ml-1"
            [disabled]="!itemsArrayBuilt || !itemsArray || (itemsArray.length === 0 && itemsArrayBuilt)"
            color="primary"
            (click)="continue()"
            tabindex="1"
        >
            Save & Proceed
        </button>
    </div>
</div>
