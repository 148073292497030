import {Injectable} from '@angular/core';
import {ServiceRequester} from './service.requester';
import {Observable} from 'rxjs';

@Injectable()
export class FranchiseRequestService {
    private url = '/franchise-request/';

    constructor(public requester: ServiceRequester) {}

    getFranchiseRequests(): Observable<any[]> {
        return this.requester.makeMsCall$(this.url + 'list', 'GET', 'zipi');
    }

    createFranchiseRequest(data: any): Observable<any> {
        return this.requester.makeMsCall$(this.url, 'POST', 'zipi', data);
    }

    updateFranchiseRequest(data: any): Observable<any> {
        return this.requester.makeMsCall$(this.url, 'PUT', 'zipi', data);
    }

    disconnectFranchiseRequest(id: number): Observable<any> {
        return this.requester.makeMsCall$(this.url + 'disconnect/' + id, 'DELETE', 'zipi');
    }

    deleteFranchiseRequest(id: number): Observable<any> {
        return this.requester.makeMsCall$(this.url + id, 'DELETE', 'zipi');
    }
}
