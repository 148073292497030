import {Action} from '@ngrx/store';

export enum NavbarActionType {
    SET_PAGE_TITLE = '[NAVBAR] SET_PAGE_TITLE'
}

export class SetPageTitle implements Action {
    readonly type: string = NavbarActionType.SET_PAGE_TITLE;

    constructor(public payload?: string) {}
}

export type NavbarAction = SetPageTitle;
