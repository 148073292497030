<div class="row content">
    <div class="col-xl-4 left-side">
        <mat-button-toggle-group vertical="true" [value]="activePanel" (change)="onSelectOptions($event)">
            <mat-button-toggle
                *ngFor="let item of reportOptions"
                [value]="item.value"
                (change)="onSelectOptions($event)"
            >
                {{ item.title }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="col-xl-8 right-side">
        <ng-container *ngIf="reportSettings">
            <div *ngIf="activePanel === 'time_period'">
                <app-report-edit-bar-time-period
                    [value]="{
                        time_period: reportSettings.time_period,
                        time_period_start: reportSettings.time_period_start,
                        time_period_end: reportSettings.time_period_end
                    }"
                    (valueChanges)="onValueChanges('time_period', $event)"
                >
                </app-report-edit-bar-time-period>
            </div>

            <div *ngIf="activePanel === 'layout'">
                <h4>Select tags</h4>
                <app-report-edit-bar-layout
                    [value]="reportSettings.layout"
                    (valueChanges)="onValueChanges('layout', $event)"
                >
                </app-report-edit-bar-layout>
            </div>

            <div *ngIf="activePanel === 'aggregation'">
                <app-report-edit-bar-button-list
                    [list]="listOfSummarized"
                    [value]="reportSettings.summarized"
                    (valueChanges)="onValueChanges('summarized', $event)"
                >
                </app-report-edit-bar-button-list>
            </div>

            <div *ngIf="activePanel === 'entity'" class="content-item">
                <h4>Participants</h4>
                <app-report-edit-bar-entity
                    [value]="reportSettings.entities"
                    [entityTypeList]="['individual', 'individuals_in_group']"
                    (valueChanges)="onValueChanges('entities', $event)"
                >
                </app-report-edit-bar-entity>
            </div>

            <div *ngIf="activePanel === 'goals'">
                <app-report-edit-bar-button-list
                    [multiple]="true"
                    [list]="listOfGoals"
                    [value]="selectedGoals"
                    valueAttribute="id"
                    (valueChanges)="onValueChanges('goals', $event)"
                >
                </app-report-edit-bar-button-list>
            </div>

            <div *ngIf="activePanel === 'ratios'" class="content-item">
                <h5 class="text-center">Coming soon</h5>
            </div>
        </ng-container>

        <div *ngIf="report">
            <app-report-edit-bar-action [reportId]="report.id" [isShowNext]="isShowNext" (action)="onAction($event)">
            </app-report-edit-bar-action>
        </div>
    </div>
</div>
