import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {BoardService} from '../../services/board.service';
import {SessionService} from 'app/services/session.service';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-add-board',
    templateUrl: './add-board.component.html',
    styleUrls: ['./add-board.component.scss']
})
export class AddBoardComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Output() boardCreated = new EventEmitter();
    @Output() boardClosed = new EventEmitter();

    createDisabled: boolean = false;

    public model = {
        name: ''
    };

    constructor(
        protected boardService: BoardService,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {}

    create() {
        if (!this.createDisabled) {
            this.createDisabled = true;

            return this.boardService
                .create({
                    name: this.model.name
                })
                .then((board) => {
                    this.boardCreated.emit(board);
                    this.createDisabled = false;

                    this.model.name = '';

                    // update order list after removing
                    if (this.sessionService.profile) {
                        const dbOrder = this.sessionService.profile.dashboard_order;
                        this.sessionService.setNewOrderList = dbOrder
                            ? [...dbOrder, Number(board.id)]
                            : [Number(board.id)];
                    }
                })
                .catch(() => {
                    this.createDisabled = false;
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.boardCreated.complete();
        this.boardClosed.complete();
    }
}
