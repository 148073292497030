import {filter, first, takeUntil} from 'rxjs/operators';
import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {IContact, IContactInvite} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {IContactsState} from '../store/contacts.reducer';
import {CancelContactInvite} from '../store/contacts.actions';
import {ConfirmComponent} from '../../../layouts/confirm/confirm.component';
import {Subject} from 'rxjs';
import {ShipperContactsService} from '../../../services/api/shipper.contacts.service';
import {NotificationsServiceZipi} from '../../notifications/notifications.service';

@Component({
    selector: 'app-contact-invite-status',
    templateUrl: 'contact-invite-status.component.html',
    styleUrls: ['contact-invite-status.component.css']
})
export class ContactInviteStatusComponent implements OnChanges, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() contact: IContact | undefined;

    zipiInvite: IContactInvite | null = null;

    constructor(
        private store: Store<IContactsState>,
        public dialog: MatDialog,
        protected notificationService: NotificationsServiceZipi,
        protected contactsService: ShipperContactsService
    ) {}

    inviteContactToZipi() {
        if (this.isSetEmail()) {
            this.inviteToShipya('zipi_contact');
        } else {
            this.notificationService.addError(`Contact don't have primary person email.`);
        }
    }

    private inviteToShipya(invType: 'zipi_contact') {
        const contact = this.contact;
        if (contact) {
            this.contactsService
                .createContactInvite(Number(contact.id), {
                    invite_company_type: invType,
                    create_company_for_client: false
                })
                .pipe(first(), takeUntil(this.unsubscribe))
                .subscribe((res) => {
                    this.contactsService
                        .getContactById(Number(contact.id))
                        .pipe(first(), takeUntil(this.unsubscribe))
                        .subscribe((updatedContact) => {
                            if (
                                updatedContact &&
                                updatedContact.contact_invites?.length &&
                                this.zipiInvite?.status !== 'new'
                            ) {
                                contact.contact_invites = updatedContact.contact_invites.filter(
                                    (inv) => inv.contact_fk_id === contact.id
                                );
                                this.initInvites(contact.contact_invites);
                            }
                        });
                });
        }
    }

    cancelInvite(contactInv: IContactInvite | null) {
        const contact = this.contact;
        if (contact && contactInv) {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                data: {
                    title: 'Cancel Invite',
                    message: `Invite will be canceled`
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((ok) => {
                    this.store.dispatch(new CancelContactInvite(contactInv));
                    contact.contact_invites!.map((inv) => {
                        if (inv.id === contactInv.id) {
                            inv.status = 'canceled';
                        }
                        return inv;
                    });
                    this.initInvites(contact.contact_invites!);
                });
        }
    }

    private initInvites(contactInvites: IContactInvite[]) {
        this.zipiInvite = null;
        if (!Array.isArray(contactInvites) || contactInvites.length === 0) {
            return;
        }

        for (const invite of contactInvites) {
            if (invite.status === 'canceled') {
                continue;
            }

            if (invite.company_type === 'zipi_contact') {
                this.zipiInvite = invite;
                return;
            }
        }
    }

    isSetEmail() {
        if (this.contact && this.contact.contact_persons) {
            const mainPerson = this.contact.contact_persons.find((person) => person.type === 'main_person');
            if (mainPerson && mainPerson.email && mainPerson.email.length) {
                return true;
            }
        }
        return false;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.contact.currentValue) {
            return;
        }

        this.initInvites(this.contact!.contact_invites!);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
