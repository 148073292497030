import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../auth.service';
import {SkyslopeAuth} from '../../modules/auth/services/okta/skyslope-auth.service';

@Injectable()
export class PublicBootstrapService implements Resolve<{}> {
    constructor(
        @Inject(AuthService)
        protected authService: AuthService,
        protected router: Router,
        private skyslopeAuthService: SkyslopeAuth
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.load(route, state);
    }

    async load(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isOktaAuthenticated = await this.skyslopeAuthService.isAuthenticated();
        if (!isOktaAuthenticated) {
            // If not authenticated, then update auth state to see if a session is available.
            const oktaAuth = await this.skyslopeAuthService.updateAuthState();
        }

        return this.authService.loadAuth().then((isUserLoaded) => {
            return true;
        });
    }
}
