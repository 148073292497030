import {Component, Input, OnDestroy, OnInit, ChangeDetectorRef} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {UiModsSource} from '../ui-mods.source';
import {UiModsDealCommissionCategorizationNoteModel} from '../models/ui-mods.model';
import {UiModsApi} from '../api/ui-mods.api';
import {takeUntil} from 'rxjs/operators';
import {MatMenuPanel} from '@angular/material/menu';

@Component({
    selector: 'app-deal-commission-categorization-notes',
    styles: [
        `
            .ui-mod-deal-badge-container {
                padding: 3px 0;
                display: flex;
                /*justify-content: flex-end;*/
                /*flex-direction: column;*/
                align-items: flex-end;
            }
            .ui-mod-deal-badge-container--dir-row {
                flex-direction: row;
            }
            .ui-mod-deal-badge-box {
                display: flex;
                align-items: center;
                margin-right: 3px;
                margin-bottom: 2px;
            }
            .ui-mod-deal-badge-box--labeled {
                /*background: lightgray;*/
                padding: 3px 2px 3px 0;
                border-radius: 10px;
            }
            .ui-mod-deal-badge-label {
                font-size: 12px;
                padding: 0 6px;
            }
            .ui-mod-deal-badge {
                height: 20px;
                width: 20px;
            }
        `
    ],
    template: `
        <h3 *ngIf="withTitle && badges && badges.length > 0">Addon Notifications</h3>
        <div class="ui-mod-deal-badge-container" [ngClass]="onPage ? 'ui-mod-deal-badge-container--dir-row' : ''">
            <ng-container *ngFor="let badge of badges">
                <ng-container *ngIf="badge && badge.on_click.action === 'dropdown_menu'">
                    <!--if badge disabled - no matMenuTriggerFor-->
                    <div
                        *ngIf="badge.disabled"
                        [matTooltip]="badge.hint || ''"
                        [ngClass]="!!badge.label ? 'ui-mod-deal-badge-box--labeled' : ''"
                        style="cursor:pointer;"
                        class="ui-mod-deal-badge-box"
                    >
                        <button
                            type="button"
                            mat-icon-button
                            class="ui-mod-deal-badge"
                            [ngStyle]="badge.style"
                        ></button>
                        <!--[matMenuTriggerFor]="appMenu">-->
                        <span
                            [ngStyle]="labelStyle"
                            *ngIf="!!badge.label"
                            class="ui-mod-deal-badge-label"
                            tooltip="status"
                        >
                            {{ badge.label }}
                        </span>
                    </div>

                    <!--else if badge not disabled - show matMenuTriggerFor-->
                    <div
                        *ngIf="!badge.disabled"
                        [matTooltip]="badge.hint || ''"
                        [ngClass]="!!badge.label ? 'ui-mod-deal-badge-box--labeled' : ''"
                        style="cursor:pointer;"
                        [matMenuTriggerFor]="appMenu"
                        class="ui-mod-deal-badge-box"
                    >
                        <mat-menu #appMenu="matMenu">
                            <button
                                type="button"
                                mat-menu-item
                                *ngFor="let menu_option of badge.on_click.dropdownMenu.menu_options"
                                (click)="menuClick(menu_option, badge)"
                            >
                                {{ menu_option.label }}
                            </button>
                        </mat-menu>
                        <button
                            type="button"
                            mat-icon-button
                            class="ui-mod-deal-badge"
                            [ngStyle]="badge.style"
                        ></button>
                        <!--[matMenuTriggerFor]="appMenu">-->
                        <span [ngStyle]="labelStyle" *ngIf="!!badge.label" class="ui-mod-deal-badge-label">
                            {{ badge.label }}
                        </span>
                    </div>
                </ng-container>

                <ng-container *ngIf="badge && badge.on_click.action !== 'dropdown_menu'">
                    <div
                        [ngClass]="!!badge.label ? 'ui-mod-deal-badge-box--labeled' : ''"
                        class="ui-mod-deal-badge-box"
                    >
                        <button
                            type="button"
                            mat-icon-button
                            class="ui-mod-deal-badge"
                            [matTooltip]="badge.hint || ''"
                            [ngStyle]="badge.style"
                            (click)="click(badge)"
                        ></button>
                        <span [ngStyle]="labelStyle" *ngIf="!!badge.label" class="ui-mod-deal-badge-label">
                            {{ badge.label }}
                        </span>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    `
})
export class DealCommissionCategorizationNotesComponent implements OnDestroy, OnInit {
    private unsubscribe: Subject<void> = new Subject();

    @Input() labelStyle: any = {};
    @Input() withTitle: boolean = false;
    @Input() onPage: boolean = false;
    @Input() dealId: string | null = null;
    public badges: UiModsDealCommissionCategorizationNoteModel[] = [];
    public appMenu: MatMenuPanel | undefined = undefined; // workaround for TSLint;
    private subscription: Subscription | null = null;

    constructor(
        public uiModsSource: UiModsSource,
        public uiModsApi: UiModsApi,
        public changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.subscription = this.uiModsSource.dealCommissionCategorizationNote
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((badges) => {
                if (!badges) {
                    this.badges = [];
                    this.changeDetector.detectChanges();
                    return;
                }

                this.badges = badges
                    .filter((badge) => badge.deal_id === Number(this.dealId))
                    .filter((badge) => !badge.hidden)
                    .map((badge) => {
                        const preset = badge.uiMod ? badge.uiMod.preset : {};
                        const res: UiModsDealCommissionCategorizationNoteModel = Object.assign(
                            new UiModsDealCommissionCategorizationNoteModel(),
                            preset
                        );
                        res.id = badge.id;
                        res.uiMod = badge.uiMod;
                        if (badge.style) {
                            res.style = badge.style;
                        }
                        if (badge.on_click) {
                            res.on_click = badge.on_click;
                        }

                        if (badge.disabled !== undefined) {
                            res.disabled = badge.disabled;
                        }
                        if (badge.label) {
                            res.label = badge.label;
                        }
                        if (badge.created_by__profile_fk_id) {
                            res.created_by__profile_fk_id = badge.created_by__profile_fk_id;
                        }
                        return res;
                    });

                // UI not updates without this after successful disconnect from addon
                this.changeDetector.detectChanges();
            });
    }

    ngOnDestroy() {
        this.subscription!.unsubscribe();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    click(badge: UiModsDealCommissionCategorizationNoteModel) {
        if (badge.clickable && badge.id) {
            this.uiModsApi.uiModClicked(badge.id);
        }
    }

    menuClick(menu_option: any, badge: UiModsDealCommissionCategorizationNoteModel) {
        if (badge.clickable && badge.uiMod && badge.id) {
            const redirectParams: {[key: string]: any} = {
                deal_id: this.dealId
            };
            switch (menu_option.on_click.action) {
                case 'open_new_tab':
                    window.open(
                        badge.uiMod.addon.addon_url +
                            menu_option.on_click.path +
                            '?' +
                            Object.keys(redirectParams)
                                .map((key) => key + '=' + encodeURIComponent(redirectParams[key]))
                                .join('&')
                    );

                    break;
                case 'redirect_user':
                    window.location.href =
                        badge.uiMod.addon.addon_url +
                        menu_option.on_click.path +
                        '?' +
                        Object.keys(redirectParams)
                            .map((key) => key + '=' + encodeURIComponent(redirectParams[key]))
                            .join('&');

                    break;
                case 'request_from_backend':
                    this.uiModsApi.uiModOptionClicked(badge.id, menu_option.on_click);
                    break;
                default:
                    throw new Error('Unknown action');
            }
        }
    }
}
