import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {IGroup} from '@cyberco-nodejs/zipi-typings';
import {takeUntil} from 'rxjs/operators';
import {GroupsSource} from '../../services/sources/groups.source';
import {Subject} from 'rxjs';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {GenericFormArray, GenericFormGroup} from '../../entites/generic.entity';
import {ChipNode} from '../../modules/account-info/compensation/models/chip-node';

@Component({
    selector: 'app-division-picker',
    templateUrl: './division-picker.component.html',
    styleUrls: ['./division-picker.component.scss']
})
export class DivisionPickerComponent implements OnInit, OnChanges, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() initialGroupId: number | undefined = undefined;
    @Input() placeholder: string = 'Division';
    @Input() filterById: Array<number> = [];
    @Input() isNeedToBeFiltered: boolean = false;
    @Input() isTotallyDisabled: boolean = false;
    @Input() nodesFA: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);

    // @Output() selectedDivisionId: EventEmitter<number> = new EventEmitter<number>();

    groupList: IGroup[] = [];
    availableList: IGroup[] = [];

    selectedGroupControl: UntypedFormControl | undefined = undefined;

    constructor(
        private groupSrc: GroupsSource,
        private fb: UntypedFormBuilder
    ) {
        this.selectedGroupControl = this.fb.control(null);
    }

    ngOnInit() {
        this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
            this.groupList = list as IGroup[];
            this.buildAvailableList();
        });

        if (this.selectedGroupControl) {
            this.selectedGroupControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((group) => {
                if (group) {
                    this.doAddNode(group);
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('filterById') && changes.filterById.currentValue) {
            this.buildAvailableList();
        }
        if (changes.hasOwnProperty('isNeedToBeFiltered')) {
            this.buildAvailableList();
        }
        if (changes.hasOwnProperty('initialGroupId') && changes.initialGroupId.currentValue) {
            if (this.groupList.length === 0) {
                this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
                    const found = list.find((chip) => chip.id === changes.initialGroupId.currentValue);
                    if (found) {
                        this.doAddNode(found);
                        if (this.selectedGroupControl) {
                            this.selectedGroupControl.setValue(found, {emitEvent: false});
                        }
                    }
                });
            } else {
                const found = this.groupList.find((chip) => chip.id === changes.initialGroupId.currentValue);
                if (found) {
                    this.doAddNode(found);
                    if (this.selectedGroupControl) {
                        this.selectedGroupControl.setValue(found, {emitEvent: false});
                    }
                }
            }
        }
        if (changes.hasOwnProperty('isTotallyDisabled')) {
            if (changes.isTotallyDisabled.currentValue) {
                if (this.selectedGroupControl) {
                    this.selectedGroupControl.disable();
                    this.selectedGroupControl.setValue(null);
                }
            } else {
                if (this.selectedGroupControl) {
                    this.selectedGroupControl.enable();
                }
            }
        }
    }

    buildAvailableList() {
        const allDivisions = this.groupList.filter((group) => group.type === 'division');

        if (this.isNeedToBeFiltered) {
            if (Array.isArray(this.filterById)) {
                this.availableList = allDivisions.filter((division) => this.filterById.includes(division.id!));
            } else {
                this.availableList = [];
            }
        } else {
            this.availableList = allDivisions;
        }
    }

    doAddNode(value: any) {
        const chip = new ChipNode()
            .setType(value.type)
            .setLabel(value.label)
            .setTargetId(value.id)
            .setCompanyFkId(value.company_fk_id)
            .setSubType(value.hasOwnProperty('subType') && value.subType ? value.subType : [])
            .setPermissions(value.permissions)
            .setEmail(value.email)
            .setContactId(value.contact_id)
            .setContactName(value.contact_name)
            .setContactPerson(value.contact_persons);

        if (this.nodesFA.controls.length > 0) {
            this.nodesFA.clear();
        }
        this.nodesFA.push(new GenericFormGroup(chip));
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
