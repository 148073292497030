import {Component, OnInit, Input} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, AbstractControl} from '@angular/forms';

@Component({
    selector: 'app-contact-location',
    templateUrl: 'contact-location.component.html',
    styleUrls: ['contact-location.component.css']
})
export class ContactLocationComponent implements OnInit {
    @Input() locationCtrl: UntypedFormGroup | null = null;
    @Input() removeLocation: (() => void) | undefined;
    @Input() isEditingMode = true;
    @Input() isAbleToEdit = true;

    showErrors = false;

    errMatcher = {
        isErrorState: (control: UntypedFormControl) => this.showErrors && control.hasError('required')
    };

    editLocation() {
        this.isEditingMode = true;
    }

    preSaveLocation() {
        if (this.locationCtrl && this.locationCtrl.invalid) {
            this.showErrors = true;
            return;
        }

        this.showErrors = false;
        this.isEditingMode = false;
    }

    constructor() {}

    ngOnInit() {}
}
