import {Component, OnDestroy, OnInit} from '@angular/core';
import {getUserManager} from '../services/okta/user-manager';
import {AuthService} from 'app/services/auth.service';
import {OktaAuthService} from '@skyslope/auth-js';
import {AuthState} from '@okta/okta-auth-js';
import {OktaAuthStateService} from '@okta/okta-angular';
import {Subject, takeUntil} from 'rxjs';
import {Router} from '@angular/router';
import {set} from 'lodash-es';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-log-out-skyslope',
    templateUrl: './log-out-skyslope.component.html'
})
export class LogOutSkyslopeComponent implements OnInit, OnDestroy {
    unsubscribe = new Subject<void>();

    constructor(
        private authService: AuthService,
        private oktaAuthService: OktaAuthStateService,
        private router: Router
    ) {}

    ngOnInit() {
        const userManager = getUserManager();

        // If the user is coming from /login-error page, delay the logout process to circumvent the issue of consecutive login/logout calls
        const delay = document.referrer.includes('login-error') ? 5000 : 0;
        setTimeout(() => {
            this.oktaAuthService.authState$.pipe(takeUntil(this.unsubscribe)).subscribe((authState: AuthState) => {
                userManager.primeAuth?.startBackgroundLogin().then(() => {
                    if (authState.isAuthenticated) {
                        userManager.startLogout();
                    } else {
                        userManager.primeAuth?.isAuthenticated().then((isPrimeAuthenticated) => {
                            if (isPrimeAuthenticated) {
                                userManager.endLogout();
                            } else {
                                if (environment.env === 'production') {
                                    window.location.replace(environment.marketingPageLogin);
                                } else {
                                    window.location.replace(
                                        `${window.location.origin}${environment.unauthorizedUserRedirectPath}`
                                    );
                                }
                            }
                        });
                    }
                });
            });
        }, delay);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
