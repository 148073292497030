import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {SessionService} from '../../services/session.service';
import {UserService} from '../../services/user.service';
import {NotificationsServiceZipi} from '../../modules/notifications/notifications.service';
import {SystemUserSource} from '../../services/sources/system-user.source';
import {ProfilesService} from '../../services/profiles.service';
import {CurrentProfileSource} from '../../services/sources/current-profile.source';
import {Deal} from 'app/models/deal';
import {ChangeEmailApi} from '../../services/api/change-email.api';
import {ProfileAccessApi} from '../../services/api/profile-access.api';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {environment} from '../../../environments/environment';
import {takeUntil} from 'rxjs/operators';
import {Subject, firstValueFrom} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/root.reducers';
import {Router} from '@angular/router';
import {LoginFormDialogComponent} from '../../modules/auth/invite/login-form-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmComponent} from '../../layouts/confirm/confirm.component';
import {ManageContactPaymentMethodsInCurrentCmpanyDialogComponent} from '../../modules/contacts/contact-dialogs/manage-contact-payment-methods-in-current-cmpany-dialog/manage-contact-payment-methods-in-current-cmpany-dialog.component';
import {Profile} from '../../models/profile';
import {NotificationsService} from 'angular2-notifications';
import {IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {CompanyGatewayService} from '../../services/api/finance/company-gateway.service';

declare var window: any;

@Component({
    selector: 'app-account-form',
    encapsulation: ViewEncapsulation.None,
    template: `
        <div class="container app-account-form" *ngIf="sessionService?.user">
            <mat-card class="card mat-elevation-z0">
                <mat-card-content class="content">
                    <mat-tab-group
                        mat-stretch-tabs
                        [selectedIndex]="mainTabIndex"
                        (selectedTabChange)="mainTabChanged($event)"
                    >
                        <mat-tab label="My Login">
                            <mat-form-field style="padding-top:10px">
                                <input
                                    matInput
                                    type="text"
                                    name="first_name"
                                    placeholder="First Name"
                                    [(ngModel)]="newFirstName"
                                />
                            </mat-form-field>
                            <mat-form-field>
                                <input
                                    matInput
                                    type="text"
                                    name="last_name"
                                    placeholder="Last Name"
                                    [(ngModel)]="newLastName"
                                />
                            </mat-form-field>
                            <mat-form-field>
                                <input
                                    matInput
                                    type="text"
                                    placeholder="Email"
                                    name="new_email"
                                    (click)="turnEditModeOn()"
                                    [(ngModel)]="newEmail"
                                />
                            </mat-form-field>
                            <mat-form-field *ngIf="editMode && !showPassForm">
                                <input
                                    matInput
                                    type="password"
                                    [required]="editMode"
                                    name="old_password"
                                    placeholder="Current Password"
                                    [(ngModel)]="oldPassword"
                                />
                            </mat-form-field>
                            <div style="padding-bottom: 15px;">
                                <div align="end" style="margin-top:10px; margin-left: 0 !important;">
                                    <button
                                        mat-raised-button
                                        class="ml-2"
                                        [disabled]="false"
                                        (click)="showPassForm = !showPassForm"
                                        style="margin-right: 9px;"
                                    >
                                        Change Password
                                    </button>
                                </div>
                                <div *ngIf="showPassForm">
                                    <div class="data-inputs">
                                        <mat-form-field>
                                            <input
                                                matInput
                                                type="password"
                                                name="new_password"
                                                placeholder="New Password"
                                                [(ngModel)]="newPassword"
                                            />
                                        </mat-form-field>
                                        <mat-form-field>
                                            <input
                                                matInput
                                                type="password"
                                                name="old_password"
                                                placeholder="Current Password"
                                                [(ngModel)]="oldPassword"
                                            />
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <h4>Preferences</h4>
                            <mat-slide-toggle [checked]="helpToggle" (change)="isHelpButtonShown()">
                                Show Help button
                            </mat-slide-toggle>
                            <div align="end">
                                <button mat-raised-button class="mat-primary" (click)="save()">SAVE</button>
                                <button mat-raised-button class="mat-default" (click)="cancel()" style="margin: 8px;">
                                    CANCEL
                                </button>
                            </div>
                        </mat-tab>
                        <mat-tab label="Company Profile" *ngIf="sessionService.profile" class="mb-2">
                            <h4 style="padding-top:15px; margin-bottom: 0 !important;">
                                <p style="margin-bottom: 0 !important;">Company: {{ currentCompanyTitle }}</p>
                            </h4>

                            <h4 style="padding-top:15px; margin-bottom: 0 !important;">
                                <p>Name: {{ newFirstName }} {{ newLastName }}</p>
                            </h4>

                            <div style="padding-bottom: 15px;">
                                <span>
                                    <span class="reminders"
                                        >Email address used with this company:
                                        <b>{{ sessionService.profile.email }}</b></span
                                    >
                                    <div
                                        align="end"
                                        style="margin-top:10px; margin-left: 0 !important;"
                                        rbac
                                        [rule]="{general__edit_own_profile_email: true}"
                                        [denyMethod]="'style'"
                                        [allowStyle]="{display: 'block'}"
                                        [denyStyle]="{display: 'none'}"
                                    >
                                        <button
                                            mat-raised-button
                                            class="ml-2"
                                            (click)="showEmailForm = !showEmailForm"
                                            style="margin-right: 9px;"
                                        >
                                            Change Email
                                        </button>
                                    </div>
                                </span>
                                <div *ngIf="showEmailForm">
                                    <div class="data-inputs">
                                        <mat-form-field>
                                            <input
                                                matInput
                                                [(ngModel)]="newProfileEmail"
                                                type="text"
                                                name="email"
                                                placeholder="New Email"
                                                [disabled]="false"
                                            />
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <button
                                            mat-raised-button
                                            class="mat-primary"
                                            form="profile-form"
                                            (click)="doSend()"
                                        >
                                            SAVE
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <mat-form-field class="flex-2" style="padding-top:20px">
                                <mat-select placeholder="Default Timezone" [(ngModel)]="profileTimezone">
                                    <mat-option
                                        *ngFor="let timezone of object_keys(DealModel.timezone_SET)"
                                        [value]="timezone"
                                    >
                                        {{ DealModel.getTimezoneLabel(timezone) }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-divider></mat-divider>

                            <div class="py-4 px-1">
                                <h4 class="mb-2">Financial Information</h4>
                                <div class="mb-2" *ngIf="!isReceiveOnlyGatewayLoaded">
                                    <mat-spinner diameter="21"></mat-spinner>
                                </div>
                                <div
                                    class="mb-2"
                                    *ngIf="
                                        isReceiveOnlyGatewayLoaded &&
                                        !receiveOnlyBankAccount &&
                                        !payloadcoReceiveOnlyBankAccount
                                    "
                                >
                                    No accounts connected
                                </div>
                                <div
                                    class="mb-2 d-flex align-items-center"
                                    *ngIf="
                                        isReceiveOnlyGatewayLoaded &&
                                        !payloadcoReceiveOnlyBankAccount &&
                                        receiveOnlyBankAccount &&
                                        receiveOnlyBankAccount.isCutOff
                                    "
                                >
                                    <mat-icon class="mr-1" style="font-size: 18px; width: 18px; height: 18px"
                                        >account_balance</mat-icon
                                    >
                                    <span class="cut-text" [matTooltip]="receiveOnlyBankAccount.fullName">{{
                                        receiveOnlyBankAccount.title
                                    }}</span>
                                    <span>{{ receiveOnlyBankAccount.lastFour }}</span>
                                </div>
                                <div
                                    class="mb-2 d-flex align-items-center"
                                    *ngIf="
                                        isReceiveOnlyGatewayLoaded &&
                                        !payloadcoReceiveOnlyBankAccount &&
                                        receiveOnlyBankAccount &&
                                        !receiveOnlyBankAccount.isCutOff
                                    "
                                >
                                    <mat-icon class="mr-1" style="font-size: 18px; width: 18px; height: 18px"
                                        >account_balance</mat-icon
                                    >
                                    <span>{{ receiveOnlyBankAccount.fullName }}</span>
                                </div>
                                <div
                                    class="mb-2 d-flex align-items-center"
                                    *ngIf="
                                        isReceiveOnlyGatewayLoaded &&
                                        payloadcoReceiveOnlyBankAccount &&
                                        payloadcoReceiveOnlyBankAccount.isCutOff
                                    "
                                >
                                    <mat-icon class="mr-1" style="font-size: 18px; width: 18px; height: 18px"
                                        >account_balance</mat-icon
                                    >
                                    <span class="cut-text" [matTooltip]="payloadcoReceiveOnlyBankAccount.fullName">{{
                                        payloadcoReceiveOnlyBankAccount.title
                                    }}</span>
                                    <span>{{ payloadcoReceiveOnlyBankAccount.lastFour }}</span>
                                </div>
                                <div
                                    class="mb-2 d-flex align-items-center"
                                    *ngIf="
                                        isReceiveOnlyGatewayLoaded &&
                                        payloadcoReceiveOnlyBankAccount &&
                                        !payloadcoReceiveOnlyBankAccount.isCutOff
                                    "
                                >
                                    <mat-icon class="mr-1" style="font-size: 18px; width: 18px; height: 18px"
                                        >account_balance</mat-icon
                                    >
                                    <span>{{ payloadcoReceiveOnlyBankAccount.fullName }}</span>
                                </div>
                                <div>
                                    <span
                                        [matTooltipDisabled]="isMuhneeAvailable || isPayloadCoAvailable"
                                        [matTooltip]="'Money Transfers (EFT) not activated'"
                                    >
                                        <button
                                            mat-raised-button
                                            [disabled]="!isMuhneeAvailable && !isPayloadCoAvailable"
                                            (click)="openManage()"
                                            style="margin-right: 9px;"
                                        >
                                            Manage Accounts
                                        </button>
                                    </span>
                                </div>
                            </div>

                            <mat-divider></mat-divider>

                            <mat-tab-group class="child-mat-tab" *ngIf="mainTabIndex === 1" [selectedIndex]="0">
                                <mat-tab label="Remove Access">
                                    <div style="margin-top: 10px;">
                                        <p>
                                            This will remove all access to this company and you will need to be
                                            re-invited by an admin if you need to access again.
                                        </p>
                                        <div align="end">
                                            <button
                                                mat-raised-button
                                                color="warn"
                                                [disabled]="isMyBusinessCompany"
                                                (click)="removeAccessFromCompany()"
                                                style="margin-bottom: 10px"
                                            >
                                                Remove Access
                                            </button>
                                        </div>
                                    </div>
                                </mat-tab>
                                <mat-tab label="Disconnect">
                                    <div style="margin-top: 10px;">
                                        <p>
                                            This will remove this Company from your Single Sign On (SS0) company list,
                                            but you will still be able to access the profile through the email address
                                            listed above. If you have not signed into SkySlope Books with this email
                                            address in the past you may need to use the 'forgot password' functionality
                                            on the login page.
                                        </p>
                                        <div align="end">
                                            <button
                                                [disabled]="!isProfileLinked"
                                                mat-raised-button
                                                color="warn"
                                                (click)="disconnectCurrentProfileFromCompany()"
                                                style="margin-top: 10px;"
                                            >
                                                Disconnect
                                            </button>
                                        </div>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>
                        </mat-tab>
                    </mat-tab-group>
                </mat-card-content>
            </mat-card>
        </div>
    `,
    styleUrls: ['./account-form.component.scss']
})
export class AccountFormComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    public newFirstName: string = '';
    public newLastName: string = '';
    public newEmail: string = '';
    public newProfileEmail: string = '';
    public newPassword: string = '';
    public oldPassword: string = '';
    public helpToggle: boolean = true;
    public DealModel = Deal;
    public tabContent: string = 'details';
    public showPassForm: boolean = false;
    public showEmailForm: boolean = false;
    object_keys = Object.keys;
    public currentCompanyTitle: string = '';
    public profileTimezone: string = '';

    public editMode: boolean = false;
    public mainTabIndex: number = 0;

    public isProfileLinked: boolean = false;
    public isMyBusinessCompany: boolean = false;

    currentProfile: Profile | undefined;
    isReceiveOnlyGatewayLoaded: boolean = false;
    receiveOnlyBankAccount: {
        fullName: string;
        title: string;
        lastFour: string;
        isCutOff: boolean;
    } | null = null;
    payloadcoReceiveOnlyBankAccount: {
        fullName: string;
        title: string;
        lastFour: string;
        isCutOff: boolean;
    } | null = null;
    isMuhneeAvailable: boolean = false;
    isPayloadCoAvailable: boolean = false;

    @Output() onSave: EventEmitter<void> = new EventEmitter();
    @Output() onCancel: EventEmitter<void> = new EventEmitter();

    constructor(
        public authService: AuthService,
        public sessionService: SessionService,
        public userService: UserService,
        public notificationService: NotificationsService,
        public systemUserSource: SystemUserSource,
        public profileAccessApi: ProfileAccessApi,
        public profileService: ProfilesService,
        public emailChangeApi: ChangeEmailApi,
        public notificationsService: NotificationsServiceZipi,
        public currentProfileSource: CurrentProfileSource,
        private companyGatewayService: CompanyGatewayService,
        private store: Store<AppState>,
        protected router: Router,
        public dialog: MatDialog
    ) {
        systemUserSource.chageSystemUser.pipe(takeUntil(this.unsubscribe)).subscribe((user) => {
            this.newEmail = user.email?.slice() || ''; // slice() just to "clone" value
            this.newFirstName = user.first_name?.slice() || '';
            this.newLastName = user.last_name?.slice() || '';
            this.helpToggle = user.is_help_button_shown !== false;
        });
        this.currentCompanyTitle = this.sessionService.profile?.company?.title || '';
        this.newProfileEmail = this.sessionService.profile?.email?.slice() || ''; // slice() just to "clone" value
        this.profileTimezone = this.sessionService.profile?.settings?.default_timezone || '';
        this.isProfileLinked = !this.sessionService.availableProfiles.find(
            (profile) =>
                profile.type === 'global' &&
                this.sessionService.profile &&
                this.sessionService.profile.user &&
                profile.user?.id === this.sessionService.profile.user.id
        );
        if (this.sessionService.profile) {
            this.currentProfile = this.sessionService.profile;
            if (this.sessionService.profile.company) {
                this.isMyBusinessCompany = this.sessionService.profile.company.zoho_subscription.find(
                    (subscription) =>
                        subscription.plan === 'zipi_access_agent' || subscription.plan === 'zipi_collaborator_customer'
                );
            }
        }
        this.sessionService.currentProfileSource.changeProfileEvent
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((profile) => {
                this.cancel();
            });
    }

    ngOnInit() {
        this.companyGatewayService
            .getCompanyGatewaysByTypes(['zipi_financial_business'], null)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((gateways) => {
                gateways.forEach((gateway) => {
                    gateway.driver_type === 'muhnee'
                        ? (this.isMuhneeAvailable = true)
                        : gateway.driver_type === 'payload'
                          ? (this.isPayloadCoAvailable = true)
                          : false;
                });
            });
        this.getContactReceiveOnlyGateway();
    }

    getContactReceiveOnlyGateway() {
        if (this.currentProfile && this.currentProfile.contact_fk_id) {
            this.isReceiveOnlyGatewayLoaded = false;
            this.companyGatewayService
                .getContactZipiFinancialReceiveOnlyGateway(this.currentProfile.contact_fk_id, 'muhnee', 'all')
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateway) => {
                    if (gateway && gateway.payment_methods && gateway.payment_methods.length > 0) {
                        this.receiveOnlyBankAccount = this.parsePaymentMethod(gateway.payment_methods[0]);
                    } else {
                        this.receiveOnlyBankAccount = null;
                    }
                });

            this.companyGatewayService
                .getContactZipiFinancialReceiveOnlyGateway(this.currentProfile.contact_fk_id, 'payload', 'bank')
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateway) => {
                    if (gateway && gateway.payment_methods && gateway.payment_methods.length > 0) {
                        this.payloadcoReceiveOnlyBankAccount = this.parsePaymentMethod(gateway.payment_methods[0]);
                    } else {
                        this.payloadcoReceiveOnlyBankAccount = null;
                    }
                    this.isReceiveOnlyGatewayLoaded = true;
                });
        }
    }

    parsePaymentMethod(method: IPaymentMethod) {
        let lastFour = '';
        let title = '';
        let isCutOff = false;
        if (method.title && method.title.length > 43) {
            isCutOff = true;
            const lastFourDigits = method.title.slice(-5, -1);
            if (lastFourDigits && !isNaN(Number(lastFourDigits))) {
                lastFour = `(${lastFourDigits})`;
                title = method.title.slice(0, method.title.length - 7);
            } else {
                title = method.title;
            }
        }
        return {
            fullName: method.title,
            title: title,
            lastFour: lastFour,
            isCutOff: isCutOff
        };
    }

    isHelpButtonShown() {
        this.helpToggle = !this.helpToggle;
    }

    mainTabChanged(tabChangeEvent: MatTabChangeEvent) {
        this.mainTabIndex = tabChangeEvent.index;
    }

    public turnEditModeOn() {
        this.newPassword = '';
        this.editMode = true;
    }

    public async save() {
        if (
            (this.sessionService.user && this.newEmail !== this.sessionService.user.email) ||
            (this.newPassword && this.newPassword !== this.oldPassword)
        ) {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                minWidth: 320,
                data: {
                    title: 'Warning',
                    message: `You will be logged out.`,
                    buttonOkMessage: 'Continue'
                }
            });

            const result = await firstValueFrom(dialogRef.afterClosed());
            if (!result) {
                return null;
            }
        }
        Promise.resolve(true)
            .then(() => {
                if (
                    this.sessionService.user &&
                    this.newFirstName === this.sessionService.user.first_name &&
                    this.newLastName === this.sessionService.user.last_name
                ) {
                    return Promise.resolve();
                }
                return this.userService.changeCurrentUserSignature(this.newFirstName, this.newLastName);
            })
            .then(() => {
                if (this.helpToggle === this.sessionService.user?.is_help_button_shown) {
                    return Promise.resolve();
                }
                return this.userService.changeHelpButtonNecessity(this.helpToggle);
            })
            .then(() => {
                if (
                    this.sessionService.profile &&
                    this.sessionService.profile.settings &&
                    this.profileTimezone === this.sessionService.profile.settings.default_timezone
                ) {
                    return Promise.resolve();
                }
                return this.doChangeSettings();
            })
            .then(async () => {
                if (this.sessionService.user && this.newEmail === this.sessionService.user.email) {
                    return Promise.resolve(true);
                }
                const res = await this.doChangeEmail();
                if (res && this.sessionService.user && this.sessionService.profile) {
                    this.sessionService.user.email = this.newEmail.slice();
                    this.sessionService.profile.email = this.newEmail.slice();
                }
                return res;
            })
            .then(() => {
                return this.doChangePassword();
            })
            .then(async (res) => {
                if (
                    (this.sessionService.user && this.newEmail !== this.sessionService.user.email) ||
                    (this.newPassword && this.newPassword !== this.oldPassword)
                ) {
                    await this.authService.logout();
                    if (environment.env === 'production') {
                        window.location.replace(environment.marketingPageLogin);
                    } else {
                        window.location.replace(`${window.location.origin}${environment.unauthorizedUserRedirectPath}`);
                    }
                    if (environment.env !== 'production') {
                        // needed only if APP have the same domain as in unauthorizedUserRedirectPath
                        window.location.reload(true);
                    }
                }
                if (res) {
                    if (this.sessionService.profile) {
                        this.authService.loadProfile(this.sessionService.profile.id as number);
                    }
                    this.notificationsService.addInfo('Profile info updated');
                    this.editMode = false;
                    this.onSave.emit();
                }
            })
            .catch((err) => {
                this.notificationsService.addError(err.message);
            });
    }

    public cancel() {
        this.editMode = false;
        this.onCancel.emit();
    }

    /**
     *
     * @return {Promise.<any>}
     */
    protected doChangePassword() {
        if (this.newPassword === '') {
            return Promise.resolve();
        }

        if (this.oldPassword === '') {
            throw new Error('Old Password must be specified');
        }

        if (this.newPassword === this.oldPassword) {
            throw new Error('New Password must differ from Old Password');
        }

        if (!this.sessionService.user || !this.sessionService.user.email) {
            throw new Error('Session user must be defined');
        }

        const oldCreds = this.authService.generateCredentials(this.sessionService.user.email, this.oldPassword);
        return this.authService.currentFirebaseUser?.reauthenticateWithCredential(oldCreds).then(() => {
            return this.authService.updatePassword(this.newPassword);
        });
    }

    disconnectCurrentProfileFromCompany() {
        const dialog = this.notificationsService.addConfirm('Are you sure?');
        if (!dialog) {
            return;
        }

        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((leave: boolean) => {
                if (!leave) {
                    return;
                }
                this.profileAccessApi
                    .disconnectCurrentProfileFromCompany()
                    .then(() => {
                        // load first available profile, but not disconnected and not global (it cause error on backend)
                        this.store.dispatch({type: 'RESET'});
                        return this.authService.loadProfile(
                            this.sessionService.availableProfiles
                                .filter(
                                    (profile) =>
                                        profile.type !== 'global' &&
                                        this.sessionService.profile &&
                                        profile.id !== this.sessionService.profile.id
                                )
                                .shift()?.id as number
                        );
                        // this.currentProfile.changeProfileTrigger(
                        //     this.sessionService.availableProfiles.filter(profile => profile.type === 'global').shift()
                        // );
                    })
                    .then(() => {
                        this.router.navigate(['/default-page']);
                    });
            });
    }
    /**
     *
     * @return {Promise.<any>}
     */
    protected doChangeEmail() {
        if (this.oldPassword === '') {
            throw new Error('Old Password must be specified');
        }
        if (!this.sessionService.user || !this.sessionService.user.email) {
            throw new Error('Session user must be defined');
        }
        const oldCreds = this.authService.generateCredentials(this.sessionService.user.email, this.oldPassword);
        return this.authService.currentFirebaseUser
            ?.reauthenticateWithCredential(oldCreds)
            .then((res) => {
                return this.userService.changeCurrentUserCredentials(this.newEmail);
            })
            .then(async (res) => {
                await this.authService.updateEmail(this.newEmail);
                return res;
            })
            .then((res) => {
                if (this.sessionService.user && this.sessionService.profile) {
                    this.sessionService.user.email = this.newEmail.slice();
                    this.sessionService.profile.email = this.newEmail.slice();
                }
                return res;
            })
            .catch((err) => {
                if (err.code === 'auth/email-already-in-use') {
                    const dialogRef = this.dialog.open(LoginFormDialogComponent, {
                        data: {email: this.newEmail}
                    });

                    dialogRef
                        .afterClosed()
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((result) => {
                            if (result) {
                                // check if user have access to other user
                                return this.authService
                                    .signInWithEmailAndPassword(this.newEmail, result.password)
                                    .then((res) => {
                                        // sign in as current user
                                        return this.authService.signInWithEmailAndPassword(
                                            this.sessionService.user?.email as string,
                                            this.oldPassword
                                        );
                                    })
                                    .then((res) => {
                                        // set other user to current profile
                                        return this.profileService.changeCurrentUserProfileUser(this.newEmail);
                                    })
                                    .then((res) => {
                                        // sign in as new user
                                        return this.authService.signInWithEmailAndPassword(
                                            this.newEmail,
                                            result.password
                                        );
                                    })
                                    .then((res) => {
                                        this.authService.loadProfile(this.sessionService.profile?.id as number);
                                        this.notificationsService.addInfo('Profile info updated');
                                        this.editMode = false;
                                        this.onSave.emit();
                                    })
                                    .catch((error) => {
                                        this.notificationsService.addError(error.message);
                                    });
                            }
                        });
                } else {
                    this.notificationsService.addError(err.message);
                }
                return false;
            });
    }
    /**
     *
     * @return {Promise.<any>}
     */
    protected doChangeSettings() {
        if (!this.sessionService.profile || !this.sessionService.profile.settings) {
            return;
        }
        this.sessionService.profile.settings.default_timezone = this.profileTimezone;
        const currentProfileId = this.sessionService.profile.id;
        const settings = this.sessionService.profile.settings;
        this.profileService
            .updateProfileSettings(currentProfileId as number, settings)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe();
    }

    doSend() {
        this.emailChangeApi.initEmailChange(this.newProfileEmail).then(() => {
            this.showEmailForm = false;
            this.notificationsService.addInfo('An email has been updated');
            if (this.sessionService.profile) {
                this.sessionService.profile.email = this.newProfileEmail;
            }
        });
    }

    removeAccessFromCompany() {
        const dialog = this.notificationsService.addConfirm('Are you sure?');
        if (!dialog) {
            return;
        }

        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((leave: boolean) => {
                if (!leave) {
                    return;
                }
                this.profileAccessApi
                    .removeAccessFromCompany()
                    .then(() => {
                        return this.authService.logout();
                    })
                    .then(() => {
                        localStorage.removeItem('zipi_last_profile_id');
                        if (environment.env === 'production') {
                            window.location.replace(environment.marketingPageLogin);
                        } else {
                            window.location.replace(
                                `${window.location.origin}${environment.unauthorizedUserRedirectPath}`
                            );
                        }
                    });
            });
    }

    openManage() {
        if (this.currentProfile && this.currentProfile.contact_fk_id) {
            const dialogRef = this.dialog.open(ManageContactPaymentMethodsInCurrentCmpanyDialogComponent, {
                width: '600px',
                maxHeight: '80vh',
                disableClose: true,
                data: {
                    contact_id: this.currentProfile.contact_fk_id,
                    contactInfo: {
                        company_name: '',
                        email: this.currentProfile.email ? this.currentProfile.email : '',
                        first_name: this.currentProfile.first_name ? this.currentProfile.first_name : '',
                        last_name: this.currentProfile.last_name ? this.currentProfile.last_name : '',
                        nick_name: ''
                    }
                }
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    this.getContactReceiveOnlyGateway();
                });
        } else {
            this.notificationService.error(`Profile has no Contact`);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
