<div id="dialog" class="deal-approval-error-container">
    <div md-dialog-title class="header">Warning</div>

    <mat-card-content>
        <div class="description-text">
            The below Deals have been Approved out of sequence. Proceeding may cause values to be incorrect for
            Unapproved Deals with Closing Date prior to {{ currentDeal.close_of_escrow | date: 'MM/dd/yyyy' }} and
            Approved Deals with Closing Date after {{ currentDeal.close_of_escrow | date: 'MM/dd/yyyy' }}. Please
            confirm.
        </div>

        <label> List of Deals: </label>
        <div class="more-deals-text" *ngIf="notShowedUnapprovedDeals > 0">
            (+{{ notShowedUnapprovedDeals }} unapproved deal(s))
        </div>
        <div class="approved-deals-list">
            <div class="deal" *ngFor="let unapprovedDeal of unapprovedDealsInPast">
                <div class="deal-content">
                    {{ unapprovedDeal.close_of_escrow | date: 'MM/dd/yyyy' }} (Unapproved):
                    <a class="name" [routerLink]="['/deals', unapprovedDeal.id]" target="_blank">
                        {{ unapprovedDeal.name }}
                    </a>
                </div>
            </div>
        </div>

        <div class="deals-list">
            <div class="deal">
                <div class="deal-content current-deal">
                    {{ currentDeal.close_of_escrow | date: 'MM/dd/yyyy' }} (Current):
                    <span> {{ currentDeal.name }}</span>
                </div>
            </div>
        </div>

        <div class="deals-list">
            <div class="deal" *ngFor="let approvedDeal of approvedDeals">
                <div class="deal-content">
                    {{ approvedDeal.close_of_escrow | date: 'MM/dd/yyyy' }} (Approved):
                    <a class="name" [routerLink]="['/deals', approvedDeal.id]" target="_blank">
                        {{ approvedDeal.name }}
                    </a>

                    <mat-icon
                        matTooltip="This deal has payouts with connected bills or invoices"
                        *ngIf="approvedDeal.hasConnectedBillsOrInvoices"
                        >error_outline</mat-icon
                    >
                </div>
            </div>
        </div>
        <div class="more-deals-text" *ngIf="notShowedApprovedDeals > 0">
            (+{{ notShowedApprovedDeals }} approved deal(s))
        </div>
    </mat-card-content>

    <div md-dialog-actions class="footer">
        <button mat-raised-button class="mr-2 mat-default" (click)="dialogRef.close(false)">Cancel</button>
        <button mat-raised-button class="mr-2 mat-primary" (click)="dialogRef.close(true)">Continue</button>
    </div>
</div>
