import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {routing} from './contact-invites.routing';
import {SharedModule} from '../shared/shared.module';
import {AcceptInviteComponent} from './components/accept-invite/accept-invite.component';
import {contactInvitesReducer} from './store/contact-invites.reducers';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ContactInvitesEffectsService} from './store/contact-invites.effects';
import {ContactInvitesService} from './services/contact-invites.service';

@NgModule({
    declarations: [AcceptInviteComponent],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        SharedModule,
        routing,
        StoreModule.forFeature('contactInvites', contactInvitesReducer),
        EffectsModule.forFeature([ContactInvitesEffectsService])
    ],
    providers: [ContactInvitesService],
    exports: [],
    bootstrap: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ContactInvitesModule {}
