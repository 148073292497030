export class SplitPlanModel {
    amount: number = 0;
    type: string = SplitPlanModel.compensation_type_SET.none;
    royalty_base: string = SplitPlanModel.royalty_base_SET.sales_compensation;

    static get compensation_type_SET() {
        return {
            flat: 'flat',
            percent: 'percent',
            none: 'none'
        };
    }

    static get royalty_base_SET() {
        return {
            sales_compensation: 'sales_compensation',
            total_commission: 'total_commission',
            sales_price: 'sales_price',
            sales_net: 'sales_net',
            amount_of_split: 'amount_of_split'
        };
    }
}
