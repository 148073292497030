import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Group} from 'app/models/group';
import {takeUntil} from 'rxjs/operators';
import {GroupsSource} from 'app/services/sources/groups.source';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-create-ledger-account-dialog',
    templateUrl: 'create-ledger-account-dialog.component.html'
})
export class CreateLedgerAccountDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    groupsList: Group[] = [];

    constructor(
        public dialogRef: MatDialogRef<CreateLedgerAccountDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private groupSrc: GroupsSource
    ) {}

    ngOnInit() {
        this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
            this.groupsList = list.filter((group) => group.type === Group.type_SET.division);
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
