import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {BillsService} from 'app/services/api/finance/bills.service';
import {ProductMatchingDialogComponent} from '../../common/product-matching-dialog/product-matching-dialog.component';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AssociateContactDialogComponent} from '../associate-contact-dialog/associate-contact-dialog.component';
import {UntypedFormControl} from '@angular/forms';
import {MatOptionSelectionChange} from '@angular/material/core';
import {MatPaginator} from '@angular/material/paginator';
import {IScrollData} from 'app/models/scroll-data';
import {PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {IInvoice} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-invoices-for-approve-list',
    templateUrl: './invoices-for-approve-list.component.html',
    styleUrls: ['./invoices-for-approve-list.component.scss', '../../../../../../assets/infinite-scroll-table.scss']
})
export class InvoicesForApproveListComponent implements OnInit, AfterViewInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;

    statusFilter: UntypedFormControl = new UntypedFormControl([]);
    connectionTypes = ['all', 'new', 'connected', 'linked', 'archived'];
    scrollData: IScrollData = {
        offset: 0,
        limit: 50,
        sort_column: 'date',
        sort_direction: 'asc',
        filter: null,
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<IInvoice>;
    displayedColumns = [
        'date',
        'customer_name',
        'document_number',
        'connection',
        'status',
        'connected_bill_number',
        'due_date',
        'payments_status',
        'amount',
        'due_balance',
        'action'
    ];

    billsMenuOpened: boolean = false;

    constructor(
        private billsService: BillsService,
        private invoiceSrv: InvoicesService,
        public dialog: MatDialog,
        private router: Router
    ) {
        this.dataSource = new MatTableDataSource<IInvoice>([]);
    }

    ngOnInit() {
        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.fetchInvoices();
            });
        }
    }

    ngAfterViewInit(): void {
        this.fetchInvoices();
    }

    fetchInvoices() {
        // cancel previous batch requests
        this.unsubscribeBatch.next();

        if (this.statusFilter.value && !this.statusFilter.value.includes('all')) {
            this.scrollData.filter = this.statusFilter.value;
        } else {
            this.scrollData.filter = '';
        }

        this.billsService
            .getMySourceDocumentsForBill(this.scrollData)
            .pipe(takeUntil(this.unsubscribeBatch))
            .subscribe((result) => {
                if (result) {
                    this.dataSource.data = result.result;
                    this.scrollData.total = result._meta.total;
                }
            });
    }

    createBillFromInvoice(entity: any) {
        this.invoiceSrv
            .getInvoiceForApproveById(entity.entity_id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                const invoicesForBulkArray = [];
                if (response.invoice.payments && response.invoice.payments.length > 0) {
                    const invoicesForBulk: {[key: number]: IInvoice} = {};
                    for (const pay of response.invoice.payments) {
                        if (pay.source_payment_received && pay.source_payment_received.related_payments) {
                            for (const relatedPay of pay.source_payment_received.related_payments) {
                                if (relatedPay.invoice && relatedPay.invoice.invoice_id) {
                                    invoicesForBulk[relatedPay.invoice.invoice_id] = relatedPay.invoice;
                                }
                            }
                        }
                    }
                    for (const key in invoicesForBulk) {
                        if (invoicesForBulk.hasOwnProperty(key)) {
                            invoicesForBulkArray.push(invoicesForBulk[key]);
                        }
                    }
                }

                const dialogRef = this.dialog.open(ProductMatchingDialogComponent, {
                    maxHeight: '80vh',
                    autoFocus: false,
                    data: {
                        source_document: response.invoice,
                        source_documents: invoicesForBulkArray
                    }
                });

                dialogRef
                    .afterClosed()
                    .pipe(
                        filter((pn) => !!pn),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe((matchResult) => {
                        this.invoiceSrv
                            .approveInvoices({
                                ...matchResult,
                                source_invoice_id: response.invoice.invoice_id
                            })
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((result) => {
                                if (result) {
                                    this.router.navigate(['/purchases/bills']);
                                }
                            });
                    });
            });
    }

    addVendorContact(element: any) {
        const dialogRef = this.dialog.open(AssociateContactDialogComponent, {
            minWidth: 320,
            data: {}
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((contactId) => {
                if (contactId === false || contactId === undefined) {
                    return;
                }
                if (contactId) {
                    this.billsService
                        .associateVendorContactByInvoice(element.entity_id, contactId)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe(() => this.resetData());
                } else {
                    this.billsService
                        .createVendorContactByInvoice(element.entity_id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe(() => this.resetData());
                }
            });
    }

    archiveInvoice(invoice: any) {
        this.invoiceSrv
            .archiveInvoice(invoice.entity_id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.resetData();
                }
            });
    }

    resetData() {
        this.paginator!.pageIndex = 0;
        this.scrollData.offset = 0;
        this.scrollData.total = 0;

        this.fetchInvoices();
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        this.resetData();
    }

    navigateToSinglePage(sourceDocument: any) {
        if (sourceDocument.is_payout) {
            this.router.navigate([`/purchases/payout/approve/${sourceDocument.entity_id}`]);
        } else {
            this.router.navigate([`/purchases/sourcedocuments/approve/${sourceDocument.entity_id}`]);
        }
    }

    changeStatusFilter(event: MatOptionSelectionChange) {
        if (event.isUserInput) {
            const {value, selected} = event.source;
            let statusFilter = this.statusFilter.value;

            if (value === 'all') {
                statusFilter = selected ? this.connectionTypes : [];
            } else if (value !== 'all') {
                if (statusFilter.includes('all') && !selected) {
                    statusFilter = statusFilter.filter((f: string) => f !== 'all' && f !== value);
                } else {
                    statusFilter = this.connectionTypes.filter(
                        (f) => (f !== value && statusFilter.includes(f)) || (f === value && selected)
                    );
                }
            }

            // update values
            this.statusFilter.setValue(statusFilter);
            this.resetData();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
    }
}
