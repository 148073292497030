import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ServiceRequester} from '../../../services/service.requester';

@Injectable()
export class ElasticsearchService {
    constructor(public requester: ServiceRequester) {}

    private url = '/elastic';

    searchData(searchRequest: {[key: string]: any}): Observable<any> {
        return this.requester.makeMsCall$(this.url + '/search', 'POST', 'zipi', searchRequest);
    }
}
