import {GenericEntity} from '../../../entites/generic.entity';
import {Group} from '../../../models/group';
import {Company} from '../../../models/company';

export class FocusAreaEntity extends GenericEntity {
    id: number | undefined = undefined;
    title: string | null = null;
    company_group: Group | null = null;
    company: Company | null = null;

    constructor() {
        super();
    }

    static get helpers() {
        return Object.assign(
            {
                props: Object.getOwnPropertyNames(new this()).reduce(
                    (acc, curr) => {
                        acc[curr] = curr;
                        return acc;
                    },
                    {} as {[key: string]: any}
                ),
                build: {
                    company_group: (val: Group, fabric = 'object.assign') => Object.assign(new Group(), val),
                    company: (val: Company, fabric = 'object.assign') => Object.assign(new Company(), val)
                }
            },
            GenericEntity.helpers
        );
    }

    static get fabric(): any {
        return Object.assign(GenericEntity.fabric, {
            hydrate: (
                input: object,
                output: FocusAreaEntity = new this(),
                props: {[prop: string]: string} = this.helpers.props,
                transform: {[id: string]: (val: any, fabric: any) => any} = this.helpers.build
            ): FocusAreaEntity => {
                // @ts-ignore: might be null if input is null
                return <FocusAreaEntity>GenericEntity.fabric.hydrate(input, output, props, transform);
            },
            dehydrate: (
                input: object | FocusAreaEntity,
                output: object = {},
                props: {[prop: string]: string} = this.helpers.props,
                transform: {[id: string]: (val: any, fabric: any) => any} = this.helpers.build
            ): object => {
                // @ts-ignore: might be null if input is null
                return <object>GenericEntity.fabric.dehydrate(input, output, props, transform);
            }
        });
    }
}
