<div class="d-flex flex-column">
    <mat-form-field>
        <!--   [floatLabel]="'never'"-->
        <mat-placeholder>
            {{ placeholder }}
        </mat-placeholder>

        <mat-select
            [formControl]="selectedGroupControl"
            [disabled]="!this.availableList || this.availableList.length === 0"
        >
            <mat-option *ngFor="let division of availableList" [value]="division">
                {{ division.title }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
