import {filter, takeUntil} from 'rxjs/operators';
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatExpansionPanel} from '@angular/material/expansion';
import {Group} from '../../../../models/group';
import {Profile} from '../../../../models/profile';
import {CurrentProfileSource} from '../../../../services/sources/current-profile.source';
import {GenericFormGroup} from '../../../../entites/generic.entity';
import {GroupApi} from '../../../../services/api/group.api';
import {GroupsSource} from '../../../../services/sources/groups.source';
import {NotificationsServiceZipi} from '../../../notifications/notifications.service';
import {OrgChartSettingsDialogComponent} from './settings-dialog.component';
import {Subject} from 'rxjs';
import {EventsService} from '../../../../services/events.service';
import {AuthService} from '../../../../services/auth.service';
import {AppInitSource} from '../../../../services/sources/app-init.source';
import {ShipperContactsService} from '../../../../services/api/shipper.contacts.service';
import {AlertDeleteContactDialogComponent} from '../../../contacts/contact-dialogs/alert-delete-contact-dialog.component';
import {FetchContacts} from '../../../contacts/store/contacts.actions';
import {Store} from '@ngrx/store';
import {IContactsState} from '../../../contacts/store/contacts.reducer';
import {UserDeleteDialogComponent} from '../../users-licenses/user-delete.dialog';
import {UsersAndLicencesApiService} from '../../users-licenses/users-and-licences.api.service';
import {MatSidenav} from '@angular/material/sidenav';

@Component({
    selector: 'app-org-charts-list-item-all',
    styles: [
        `
            .form {
                flex: 1;
                padding: 20px;
            }

            .visibility-hidden {
                visibility: hidden;
            }

            .h-font {
                font-size: 25px;
                font-weight: 400;
                margin-right: 30px;
                margin-bottom: 6px;
            }

            .user-card {
                margin-bottom: 3px;
                padding: 16px 0;
                height: 64px;

                max-width: 900px;
                margin-right: auto;
                margin-left: auto;
                border-bottom: 1px #e8e8e8 solid;
            }
            .user-row {
                cursor: pointer;
            }
            .user-row:hover .user-card {
                box-shadow: 0 0 5px #ccc;
            }
            ::ng-deep .my-tooltip {
                white-space: pre-line;
            }
        `
    ],
    template: `
        <div class="user-row">
            <div class="user-card" [ngStyle]="{'padding-left': groupFG.controls.child_level!.value * 20 + 'px'}">
                <div class="d-flex align-items-top justify-content-between mx-4">
                    <div class="d-flex">
                        <span *ngIf="groupFG.controls.type!.value === GROUP.type_SET.focus_area" class="h-font"
                            ><b>
                                <ng-container *ngIf="groupFG.controls.parent_group!.value !== null">
                                    {{ groupFG.controls.parent_group!.value.title }} >>
                                </ng-container>
                                {{ groupFG.controls.title!.value }}
                            </b></span
                        >
                        <span
                            *ngIf="
                                groupFG.controls.type!.value !== GROUP.type_SET.focus_area &&
                                groupFG.controls.type!.value !== GROUP.type_SET.division
                            "
                            class="h-font"
                        >
                            <ng-container *ngIf="groupFG.controls.parent_group!.value !== null">
                                {{ groupFG.controls.parent_group!.value.title }} >>
                            </ng-container>
                            {{ groupFG.controls.title!.value }}
                        </span>
                        <span *ngIf="groupFG.controls.type!.value === GROUP.type_SET.division" class="h-font">
                            {{ groupFG.controls.title!.value }}
                            <span *ngIf="groupFG.controls.email!.value" style="font-size: 1rem; color: #6c757d">
                                ( {{ groupFG.controls.email!.value }} )
                            </span>
                        </span>
                    </div>
                    <div class="d-flex justify-content-end m-0">
                        <span style="width: 120px; font-size: 1rem; color: #6c757d; margin-top: 5px">
                            Members: {{ groupFG.controls.number_of_members!.value }}
                        </span>

                        <div class="d-flex" style="width: 64px; padding-left: 9px; margin-top: 6px;">
                            <mat-icon
                                [ngClass]="{
                                    'visibility-hidden':
                                        !groupFG.controls.permissions!.controls!.use_in_compensation_as_entity_group!
                                            .value
                                }"
                                [matTooltip]="
                                    'This division has been configured with the ability to conduct transactions. &#13; &#13; Details: This means that the division can be used as a Sales Entity and assigned Users that have appropriate permissions can log into the division\\'s portal using their company selector to track finances separately.'
                                "
                                [matTooltipClass]="'my-tooltip'"
                                style="color:#9E9E9E; margin: 0 4px; float: right"
                                >monetization_on</mat-icon
                            >
                            <mat-icon
                                [ngClass]="{
                                    'visibility-hidden':
                                        !groupFG.controls.permissions!.controls!
                                            .use_in_compensation_as_group_of_entities!.value
                                }"
                                matTooltip="User Finance Group"
                                style="color:#9E9E9E; margin: 0 4px;"
                                >group</mat-icon
                            >
                        </div>

                        <button
                            mat-icon-button
                            class="justify-content-end"
                            [rule]="{company_settings__manage_divisions: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                            (click)="stopPropagation($event)"
                            [matMenuTriggerFor]="menu"
                        >
                            <mat-icon style="margin-bottom: 6px">more_vert</mat-icon>
                        </button>
                        <mat-menu
                            #menu="matMenu"
                            [rule]="{company_settings__manage_divisions: true}"
                            rbac
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'inline-block'}"
                            [denyStyle]="{display: 'none'}"
                        >
                            <button
                                [disabled]="groupFG.controls.id!.value === (currentProfile?.company?.company_group)!.id"
                                mat-menu-item
                                (click)="groupSettings($event, groupFG)"
                            >
                                Settings
                            </button>
                            <button
                                mat-menu-item
                                [disabled]="
                                    groupFG.getRawValue().contact &&
                                    groupFG.getRawValue().contact!.partner__company_fk_id
                                        ? true
                                        : false
                                "
                                (click)="createBusinessCompany()"
                            >
                                Create Business Account
                            </button>
                            <button [disabled]="disableDeleting" mat-menu-item (click)="deleteActiveGroup($event)">
                                Delete
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class GroupsListItemAllComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    protected groupsList: Group[] = [];
    public disableDeleting: boolean = false;

    @ViewChild('panel', {static: true}) panel: MatExpansionPanel | undefined;
    public GROUP = Group;
    @Input() groupFG: GenericFormGroup<Group> = new GenericFormGroup(new Group());

    selectedGroupFG: GenericFormGroup<Group> | undefined;
    isOpened: boolean = false;

    @ViewChild('leftSidebar', {static: true}) leftSidebar: MatSidenav | undefined;

    public currentProfile: Profile | null = null;

    public isLoaded: Promise<any>;
    groupSnapshot: Group = new Group();

    groupsSeachSubject: Subject<any> | undefined;

    constructor(
        private groupService: GroupApi,
        public dialog: MatDialog,
        protected currentProfileSource: CurrentProfileSource,
        private notificationsService: NotificationsServiceZipi,
        private eventsService: EventsService,
        private groupSrc: GroupsSource,
        public authService: AuthService,
        protected appInitSource: AppInitSource,
        private cs: ShipperContactsService,
        private store: Store<IContactsState>,
        public usersAndLicencesApiService: UsersAndLicencesApiService
    ) {
        this.isLoaded = new Promise((resolve) => {
            this.currentProfileSource.changeProfileEvent
                .pipe(
                    filter((profile) => profile.company !== null),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((profile) => {
                    this.currentProfile = profile;
                    resolve(null);
                });
        });

        this.groupSrc.source.pipe(takeUntil(this.unsubscribe)).subscribe((list) => {
            this.groupsList = list;
            const groupForm = this.groupFG.getRawValue();
            this.disableDeleting = this.groupsList.some((group) => {
                return group.parent_group && group.parent_group.id === this.groupFG.controls.id!.value;
            });
        });
    }

    sidenavOpenChanged(isOpen: boolean) {
        this.isOpened = isOpen;
    }

    openSidenav(group: GenericFormGroup<Group>) {
        if (this.leftSidebar) {
            this.selectedGroupFG = group;
            this.isOpened = true;
            this.leftSidebar.open();
        }
    }

    closeLeftSidebar() {
        if (this.isOpened === false) {
            return;
        }
        this.isOpened = false;
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }

    groupSettings(event: MouseEvent, groupFG: GenericFormGroup<Group>) {
        const dialogRef = this.dialog.open(OrgChartSettingsDialogComponent);
        dialogRef.componentInstance.groupFG.patchValue(groupFG.getRawValue());

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                if (!res) {
                    // dialog was just closed, no need to save anything
                    return;
                }
                groupFG.patchValue(res);
                if (!res.email) {
                    res.email = null;
                }
                this.groupService.updateSettings(res.id, res).then(() => {
                    this.store.dispatch(new FetchContacts());
                    if (this.currentProfile) {
                        this.groupSrc.load(this.currentProfile);
                    }
                });
            });
    }

    deleteActiveGroup(event: MouseEvent) {
        this.notificationsService
            .addConfirm('Are you sure that you want to delete this division?')!
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allowDeleting) => {
                if (allowDeleting) {
                    const divisionToDelete = this.groupFG.getRawValue();
                    if (divisionToDelete.financial_profile && divisionToDelete.financial_profile.contact) {
                        this.cs
                            .isDeletableDivisionContact(Number(divisionToDelete.financial_profile.contact.contact_id))
                            .toPromise()
                            .then((result) => {
                                if (!result.isDeletable) {
                                    const dialogData = result;
                                    dialogData.title = 'Division Contact Deleting';
                                    this.dialog.open(AlertDeleteContactDialogComponent, {
                                        minWidth: 200,
                                        maxWidth: 500,
                                        data: dialogData
                                    });
                                    return false;
                                }
                                return true;
                            })
                            .then((isContactDeletable) => {
                                if (isContactDeletable && divisionToDelete.financial_profile!.id) {
                                    return this.usersAndLicencesApiService
                                        .deleteProfile(divisionToDelete.financial_profile?.id as number, 'forced')
                                        .then((response) => {
                                            if (response.unpaid_deals) {
                                                const dialogRefDeals = this.dialog.open(UserDeleteDialogComponent, {
                                                    data: {
                                                        title: 'Delete Division Profile',
                                                        descriptionPrefix: 'The Division profile'
                                                    }
                                                });
                                                dialogRefDeals.componentInstance.deals = response.unpaid_deals;
                                                return false;
                                            }
                                            if (divisionToDelete.financial_profile?.contact) {
                                                return this.cs
                                                    .deleteContact(
                                                        Number(
                                                            divisionToDelete.financial_profile?.contact
                                                                .contact_id as number
                                                        ),
                                                        true
                                                    )
                                                    .toPromise()
                                                    .then(() =>
                                                        this.groupService.deleteGroup(this.groupFG.controls.id!.value)
                                                    )
                                                    .then(() => {
                                                        return true;
                                                    });
                                            }
                                        });
                                }
                                return false;
                            })
                            .then((result) => {
                                if (result && this.currentProfile) {
                                    this.groupSrc.load(this.currentProfile);
                                }
                            });
                    } else {
                        this.groupService.deleteGroup(this.groupFG.controls.id!.value).then(() => {
                            if (this.currentProfile) {
                                this.groupSrc.load(this.currentProfile);
                            }
                        });
                    }
                }
            });
    }

    toggle() {}

    createBusinessCompany() {
        const group = this.groupFG.getRawValue();
        this.groupService.createBusinessCompanyForGroup(Number(group.id)).then(() => {});
    }

    ngOnInit() {
        this.isLoaded.then(() => {
            if (
                this.groupFG.controls.id &&
                this.currentProfile &&
                this.currentProfile.company &&
                this.currentProfile.company.company_group &&
                this.groupFG.controls.id.value === this.currentProfile.company.company_group.id
            ) {
                const currentGroup = this.groupFG.getRawValue();
                currentGroup.isPersonalGroup = true;
                this.groupFG.patchValue(currentGroup);
            }
        });

        this.groupsSeachSubject = this.eventsService.getGroupEventObject();
        this.groupsSeachSubject.pipe(takeUntil(this.unsubscribe)).subscribe((ids) => {
            if (ids && ids.length === 1) {
                if (this.panel && Number(ids[0]) === this.groupFG.getRawValue().id) {
                    this.panel.open();
                }
            }
        });

        this.eventsService.initCurrentGroupEventValue();
    }

    ngOnDestroy() {
        this.eventsService.updateGroupEventValue([]);
        this.unsubscribe.next();
        this.unsubscribe.complete();
        if (this.groupsSeachSubject) {
            this.groupsSeachSubject.complete();
        }
    }
}
