<section class="bg-white">
    <!------- Header ------->
    <header class="d-flex align-items-center px-3 list-header">
        <h3 class="m-0 mr-1 d-flex align-items-end">Recurring Bills</h3>

        <button mat-stroked-button class="ml-3" [matMenuTriggerFor]="menu" color="warn" *ngIf="isSelectedForBulk">
            Bulk Action
        </button>
        <mat-menu #menu="matMenu">
            <button
                mat-menu-item
                (click)="bulkDelete()"
                [rule]="{purchases__manage_recurring_bills: true}"
                rbac
                [denyMethod]="'style'"
                [allowStyle]="{display: 'inline-block'}"
                [denyStyle]="{display: 'none'}"
            >
                Delete
            </button>
        </mat-menu>

        <i class="sp-spacer"></i>

        <!--	    <button type="button" mat-raised-button (click)="runScheduler()">-->
        <!--		    Run Scheduler Manually-->
        <!--	    </button>-->

        <button
            class="ml-2"
            type="button"
            mat-raised-button
            color="primary"
            routerLink="/purchases/bills/recurring/create"
        >
            <mat-icon>add</mat-icon>
            Create
        </button>
    </header>

    <div class="table-background">
        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef class="w-5"></th>
                <td mat-cell *matCellDef="let element" class="w-5" (click)="$event.stopPropagation()">
                    <mat-checkbox
                        (change)="toggleBulkCheckbox($event, element.recurring_bill_id)"
                        [value]="element.recurring_bill_id"
                        [checked]="selection.isSelected(element.recurring_bill_id)"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25 pl-1">Title</th>
                <td mat-cell *matCellDef="let element" class="w-25 px-1">
                    {{ element.title }}
                </td>
            </ng-container>

            <ng-container matColumnDef="vendor_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25 pl-1">Vendor</th>
                <td mat-cell *matCellDef="let element" class="w-25 px-1">
                    <span [ngStyle]="element.vendor_name === 'Group' ? {'font-style': 'italic'} : {}">
                        {{ element.vendor_name || 'N/A' }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="frequency">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Frequency</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <span *ngIf="element.period === 'custom'">
                        Every {{ element?.frequency === 1 ? 'day' : element.frequency + ' days' }}
                    </span>
                    <span *ngIf="element.period !== 'custom'">
                        {{ element?.frequency === 1 ? '' : element.frequency }}
                        {{
                            element?.frequency === 1
                                ? (element.period | fromSnakeCase)
                                : (element.period + 's' | fromSnakeCase)
                        }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="last_bill_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Last Bill</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{
                        element.last_bill_date ? (element.last_bill_date | dateFromNumber | date: 'mediumDate') : 'N/A'
                    }}
                </td>
            </ng-container>

            <ng-container matColumnDef="next_bill_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 pl-1">Next Bill</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <span *ngIf="element.status === 'stopped'" style="color: red">Suspended</span>
                    <span *ngIf="element.status === 'active'">{{
                        element.next_bill_date | dateFromNumber | date: 'mediumDate'
                    }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="total_amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-10 table-header-align-right">Amount</th>
                <td mat-cell *matCellDef="let element" class="w-10 px-3 text-right">
                    {{ element.total_amount | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="w-5 text-right">Action</th>
                <td mat-cell *matCellDef="let element" class="w-5 text-right">
                    <!--                            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation();">-->
                    <!--                                <mat-icon>more_vert</mat-icon>-->
                    <!--                            </button>-->
                    <!--                            <mat-menu #menu="matMenu">-->
                    <!--                                <button mat-menu-item-->
                    <!--                                        (click)="$event.stopPropagation(); cloneInvoice(invoice)">Clone-->
                    <!--                                </button>-->
                    <!--                            </mat-menu>-->
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [routerLink]="row.recurring_bill_id"
                class="zp-table-row"
            ></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 text-center no-items">
            There are no recurring bills.
        </div>
    </div>
</section>
