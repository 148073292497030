import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ExpensesService} from '../../../services/expenses.service';
import {IScrollData} from 'app/models/scroll-data';
import {MatPaginator} from '@angular/material/paginator';
import {PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {MatTableDataSource} from '@angular/material/table';
import {Sort} from '@angular/material/sort';
import {IExpense} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-expense-list',
    templateUrl: './expense-list.component.html',
    styleUrls: ['./expense-list.component.scss', '../../../../../../assets/infinite-scroll-table.scss']
})
export class ExpenseListComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;

    scrollData: IScrollData = {
        limit: 50,
        offset: 0,
        sort_column: 'expense_date',
        sort_direction: 'desc',
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<IExpense>;
    displayedColumns = ['expense_date', 'expense_account_name', 'vendor_contact_name', 'paid_through_name', 'amount'];

    constructor(public expenseService: ExpensesService) {
        this.dataSource = new MatTableDataSource<IExpense>([]);
    }

    ngOnInit() {
        this.getExpenses();

        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.getExpenses();
            });
        }
    }

    getExpenses() {
        this.unsubscribeBatch.next();

        this.expenseService
            .getExpenses(this.scrollData)
            .pipe(takeUntil(this.unsubscribeBatch))
            .subscribe((data) => {
                this.dataSource.data = data.result;
                this.scrollData.total = data._meta.total;
            });
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        // update values
        this.paginator!.pageIndex = 0;
        this.scrollData.offset = 0;
        this.getExpenses();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
    }
}
