import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-override-dialog',
    styles: [
        `
            .mat-dialog-actions {
                justify-content: space-between;
                display: flex;
            }
            #errors {
                color: #af1515;
                padding-left: 15px;
                line-height: 2em;
                font-size: 14px;
            }
        `
    ],
    template: `
        <div mat-dialog-content>
            <p>Override Compensation Profile</p>
            <hr />
            <p>
                Depending on user modifications, if conditions on this cloned profile are changed, the new compensation
                profile may or may not override desired transactions.
            </p>
        </div>

        <div mat-dialog-actions>
            <button mat-button class="mat-primary" (click)="dialogRef.close(true)">OK</button>
            <button mat-button class="mat-primary" (click)="dialogRef.close()">Cancel</button>
        </div>
    `
})
export class OverrideDialogComponent {
    constructor(public dialogRef: MatDialogRef<OverrideDialogComponent>) {}
}
