<div>
    <mat-card class="preview-body" *ngIf="overallTable.length">
        <div class="tables">
            <div class="table-wrapper">
                <div class="report-flex-row">
                    <div class="report-item" *ngIf="buyer.entities.length">
                        <h2 class="table-header sticky">Purchase Side</h2>
                        <table mat-table [dataSource]="buyer.entities" class="w-100">
                            <ng-container [matColumnDef]="column.value" *ngFor="let column of tableStructure">
                                <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element[column.value] | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="totalsName">
                                <td mat-footer-cell *matFooterCellDef class="table-footer-row-title">Totals:</td>
                            </ng-container>

                            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerTotalColumns">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getTotalCost(column.value, 'buyer') | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="averageName">
                                <td mat-footer-cell *matFooterCellDef class="table-footer-row-title">Average:</td>
                            </ng-container>

                            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerAverageColumns">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getAverage(column.value, 'buyer') | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['averageName'].concat(footerAverageColumnsToDisplay)"
                            ></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['totalsName'].concat(footerTotalColumnsToDisplay)"
                            ></tr>
                        </table>
                    </div>

                    <div class="report-item" *ngIf="seller.entities.length">
                        <h2 class="table-header sticky">Listing Side</h2>
                        <table mat-table [dataSource]="seller.entities" class="w-100">
                            <ng-container [matColumnDef]="column.value" *ngFor="let column of tableStructure">
                                <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element[column.value] | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="totalsName">
                                <td mat-footer-cell *matFooterCellDef class="table-footer-row-title">Totals:</td>
                            </ng-container>

                            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerTotalColumns">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getTotalCost(column.value, 'seller') | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="averageName">
                                <td mat-footer-cell *matFooterCellDef class="table-footer-row-title">Average:</td>
                            </ng-container>

                            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerAverageColumns">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getAverage(column.value, 'seller') | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['averageName'].concat(footerAverageColumnsToDisplay)"
                            ></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['totalsName'].concat(footerTotalColumnsToDisplay)"
                            ></tr>
                        </table>
                    </div>

                    <div class="report-item" *ngIf="referral.entities.length">
                        <h2 class="table-header sticky">Referral Side</h2>
                        <table mat-table [dataSource]="referral.entities" class="w-100">
                            <ng-container [matColumnDef]="column.value" *ngFor="let column of tableStructure">
                                <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element[column.value] | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="totalsName">
                                <td mat-footer-cell *matFooterCellDef class="table-footer-row-title">Totals:</td>
                            </ng-container>

                            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerTotalColumns">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getTotalCost(column.value, 'referral') | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="averageName">
                                <td mat-footer-cell *matFooterCellDef class="table-footer-row-title">Average:</td>
                            </ng-container>

                            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerAverageColumns">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getAverage(column.value, 'referral') | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['averageName'].concat(footerAverageColumnsToDisplay)"
                            ></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['totalsName'].concat(footerTotalColumnsToDisplay)"
                            ></tr>
                        </table>
                    </div>

                    <div class="report-item" *ngIf="rentalOwner.entities.length">
                        <h2 class="table-header sticky">Rental Owner Side</h2>
                        <table mat-table [dataSource]="rentalOwner.entities" class="w-100">
                            <ng-container [matColumnDef]="column.value" *ngFor="let column of tableStructure">
                                <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element[column.value] | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="totalsName">
                                <td mat-footer-cell *matFooterCellDef class="table-footer-row-title">Totals:</td>
                            </ng-container>

                            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerTotalColumns">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getTotalCost(column.value, 'rentalOwner') | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="averageName">
                                <td mat-footer-cell *matFooterCellDef class="table-footer-row-title">Average:</td>
                            </ng-container>

                            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerAverageColumns">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getAverage(column.value, 'rentalOwner') | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['averageName'].concat(footerAverageColumnsToDisplay)"
                            ></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['totalsName'].concat(footerTotalColumnsToDisplay)"
                            ></tr>
                        </table>
                    </div>

                    <div class="report-item" *ngIf="rentalTenant.entities.length">
                        <h2 class="table-header sticky">Rental Tenant Side</h2>
                        <table mat-table [dataSource]="rentalTenant.entities" class="w-100">
                            <ng-container [matColumnDef]="column.value" *ngFor="let column of tableStructure">
                                <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element[column.value] | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="totalsName">
                                <td mat-footer-cell *matFooterCellDef class="table-footer-row-title">Totals:</td>
                            </ng-container>

                            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerTotalColumns">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getTotalCost(column.value, 'rentalTenant') | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="averageName">
                                <td mat-footer-cell *matFooterCellDef class="table-footer-row-title">Average:</td>
                            </ng-container>

                            <ng-container [matColumnDef]="column.value" *ngFor="let column of footerAverageColumns">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getAverage(column.value, 'rentalTenant') | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['averageName'].concat(footerAverageColumnsToDisplay)"
                            ></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['totalsName'].concat(footerTotalColumnsToDisplay)"
                            ></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>

    <mat-card class="preview-body" *ngIf="overallTable.length">
        <h2 class="table-header">Combined Purchase & Listing</h2>
        <div class="row">
            <div class="col-xl-2">
                <div class="overall-type">
                    <p>OVERALL AVERAGE</p>
                    <p>OVERALL TOTAL</p>
                </div>
            </div>
            <div class="col-xl-10">
                <div class="tables">
                    <div class="table-wrapper">
                        <table mat-table [dataSource]="overallTable" class="w-100">
                            <ng-container [matColumnDef]="column.value" *ngFor="let column of overallStructure">
                                <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element[column.value] | reportFormatted: column.filter }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="overallColumnsToDisplay"></tr>
                            <tr mat-row *matRowDef="let row; columns: overallColumnsToDisplay"></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>

    <mat-card class="preview-body" *ngIf="!overallTable.length">
        <h4>We didn't find any data matching report criteria, please adjust report parameters to get some results</h4>
    </mat-card>
</div>
