import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../service.requester';
import {Observable} from 'rxjs';
import {IRecurringInvoice} from '../../../../typings';

@Injectable()
export class RecurringInvoicesService {
    private url = '/finance/recurring-invoices/';

    constructor(public requester: ServiceRequester) {}

    getRecurringInvoices(params = {}): Observable<{_meta: {total: number}; result: IRecurringInvoice[]}> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp', params);
    }

    getRecurringInvoiceById(recurringInvoiceId: number): Observable<IRecurringInvoice> {
        return this.requester.makeMsCall$(this.url + `${recurringInvoiceId}`, 'GET', 'shipp');
    }

    getRecurringInvoicesBySenderContactId(
        contactId: number,
        data: any
    ): Observable<{
        _meta: {total: number};
        result: IRecurringInvoice[];
    }> {
        return this.requester.makeMsCall$(this.url + `${contactId}/sender`, 'GET', 'shipp', data);
    }

    createRecurringInvoice(data: IRecurringInvoice): Observable<IRecurringInvoice> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    updateRecurringInvoice(data: IRecurringInvoice): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + data.id, 'PUT', 'shipp', data);
    }

    deleteRecurringInvoice(data: number): Observable<number> {
        return this.requester.makeMsCall$(this.url + data, 'DELETE', 'shipp');
    }

    bulkDeleteRecurringInvoices(data: number[]): Observable<number> {
        return this.requester.makeMsCall$(this.url + 'bulk/delete', 'PUT', 'shipp', data);
    }

    runScheduler(): Observable<any> {
        return this.requester.makeMsCall$('/cron/recurring', 'GET', 'shipp');
    }
}
