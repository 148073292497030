import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {IScrollData} from 'app/models/scroll-data';
import {SortDirection} from '@angular/material/sort';
import {LedgerAccountTypes} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-transaction-dialog',
    templateUrl: 'app-transaction-dialog.component.html'
})
export class AddTransactionDialogComponent implements OnInit, AfterViewInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    transactionType: {value: string; label: string} | undefined;
    ledgerAccountId: number | null = null;
    accountFC: UntypedFormControl = new UntypedFormControl(null);
    ledgerAccountLabel: string = '';

    scrollData: IScrollData = {
        sort_column: 'name',
        sort_direction: 'asc' as SortDirection,
        filter: 'active',
        total: 0
    };

    types: LedgerAccountTypes[] = ['cash', 'trust', 'credit_card', 'payment_clearing', 'bank'];

    constructor(
        public dialogRef: MatDialogRef<AddTransactionDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            transactionType: {value: string; label: string};
            ledgerAccountId: number | null;
        }
    ) {
        this.transactionType = this.data.transactionType;
        this.ledgerAccountId = this.data.ledgerAccountId;

        switch (this.transactionType.value) {
            case 'other_deposit':
            case 'transfer_from_another_account':
            case 'owners_contribution':
                this.ledgerAccountLabel = 'To Account';
                break;
            case 'owner_drawings':
                this.ledgerAccountLabel = 'From Account';
                break;
        }
    }

    ngOnInit() {}

    ngAfterViewInit() {
        if (this.ledgerAccountId) {
            this.accountFC.patchValue(this.ledgerAccountId);
            this.accountFC.disable();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
