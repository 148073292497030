import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IFinanceState} from '../../../store/finance.reducer';
import {Store} from '@ngrx/store';
import {of, Subject} from 'rxjs';
import {map, filter, takeUntil, catchError} from 'rxjs/operators';
import {IContact, ICreditNote, IPayment} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ApplyCreditToInvoicesDialogComponent} from '../../../../shared/components/credit-apply/apply-credit-to-invoices-dialog/apply-credit-to-invoices-dialog.component';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {CREDITS_STATUS_COLOR} from 'app/local-typings';
import {CreditNotesService} from '../../../../../services/api/finance/credit-notes.service';
import {NotificationsService} from 'angular2-notifications';
import {DealService} from '../../../../../services/deal.service';

@Component({
    selector: 'app-view-credit-note',
    templateUrl: 'view-credit-note.component.html',
    styles: [
        `
            .zp-company-info {
                width: 100%;
            }

            .zp-company-title {
                font-weight: bold;
                margin-bottom: 0;
            }

            .zp-inveice_title {
                font-size: 33px;
                margin-bottom: 0;
            }

            .zp-invoice-num_value {
                font-size: 14px;
                letter-spacing: 0.07rem;
                font-weight: bold;
                margin-bottom: 25px;
                margin-top: -5px;
            }

            .zp-refference_due_title {
                margin-bottom: 0;
            }

            .zp-refference_due_value {
                font-size: 14px;
                margin-top: 0px;
                font-weight: bold;
                margin-bottom: 30px;
            }

            .zp-w-150 {
                width: 150px;
                display: inline-block;
            }

            .zp-balance-due {
                font-size: 20px;
            }

            .zp-total-nember {
                display: inline-block;
                width: 150px;
                text-align: right;
                padding-right: 0.75rem;
            }

            .credit-status {
                right: 0;
                top: 0;
                position: absolute;
                color: #fff;
                font-size: 18px;
                padding: 0 10px;
                display: inline-block;
            }
        `
    ]
})
export class ViewCreditNoteComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    invoice: (ICreditNote & {contact: IContact}) | undefined;
    creditNoteId: number | null = null;
    creditNote: ICreditNote | undefined;

    dataSource: MatTableDataSource<IPayment>;
    showPayments: boolean;
    isInvoicesToApply: boolean = false;

    statusColor = CREDITS_STATUS_COLOR;
    displayedRelatedColumns = ['payment_date', 'invoice_id', 'status', 'amount'];

    constructor(
        private store: Store<IFinanceState>,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private invoicesService: InvoicesService,
        private creditNotesService: CreditNotesService,
        private ntfs: NotificationsService,
        private router: Router,
        private dealService: DealService
    ) {
        this.dataSource = new MatTableDataSource<IPayment>([]);
        this.showPayments = true;
    }

    openApplyToInvoicesDialog() {
        if (!this.creditNote) {
            return null;
        }
        const dialogRef = this.dialog.open(ApplyCreditToInvoicesDialogComponent, {
            minWidth: 700,
            data: {
                credit: this.creditNote,
                contact: this.creditNote.customer_contact
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((c) => !!c),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.getCreditNote();
            });
    }

    invoicesToApply() {
        if (this.creditNote && this.creditNote.customer__contact_fk_id) {
            this.invoicesService
                .getInvoiceBySenderContactIdCount(this.creditNote.customer__contact_fk_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((data) => {
                    this.isInvoicesToApply = !!data.count;
                });
        }
    }

    ngOnInit() {
        this.route.paramMap
            .pipe(
                map((pm) => {
                    const stringId: string | null = pm.get('id');
                    return Number(stringId);
                }),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                this.creditNoteId = id;
                this.getCreditNote();
            });
    }

    getCreditNote() {
        if (this.creditNoteId) {
            this.creditNotesService
                .getCreditNote(this.creditNoteId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((cn) => {
                    if (!cn) {
                        return;
                    }

                    this.creditNote = cn;
                    if (this.creditNote.items && this.creditNote.items.length > 0) {
                        // need to get related deals on backend
                        this.getItemsRelatedDeals();
                    }
                    if (this.creditNote.payments) {
                        this.dataSource.data = this.creditNote.payments;
                    }
                    this.invoicesToApply();
                });
        }
    }

    updateStatus(status: string) {
        if (this.creditNoteId) {
            this.creditNotesService
                .updateCreditNoteStatus(this.creditNoteId, status)
                .pipe(
                    catchError((err) => of(null)),
                    filter((act) => !!act),
                    takeUntil(this.unsubscribe)
                )
                .subscribe(() => {
                    this.ntfs.info(`Credit Note status updated`);
                    this.router.navigate(['/sales/creditnotes']);
                });
        }
    }

    getItemsRelatedDeals() {
        if (this.creditNote && this.creditNote.items) {
            for (const item of this.creditNote.items) {
                if (item.connected__deal_fk_id) {
                    this.dealService.getDealBaseView(item.connected__deal_fk_id).then((deal) => {
                        item.related_deal = deal;
                    });
                }
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
