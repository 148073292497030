import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'app-user-notification-item-base',
    template: `<ng-content></ng-content>`
})
export class UserNotificationItemBaseComponent {
    @Output() dismiss = new EventEmitter();
    @Output() hide = new EventEmitter();

    doDismiss() {
        this.dismiss.emit();
    }

    doHide() {
        this.hide.emit();
    }

    constructor() {}
}
