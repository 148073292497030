import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {of, Subject} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PaymentsMadeService} from '../../../services/payments-made.service';
import {CompanySettingsService} from '../../../../../services/api/companies/company-settings.service';
import {map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {ICompanySettings} from '@cyberco-nodejs/zipi-typings/companies.typings';
import {CheckPrintSettingsDialogComponent} from '../check-print-settings-dialog/check-print-settings.dialog';
import {SessionService} from '../../../../../services/session.service';
import {IPaymentMadeListItem} from '../typings';
import {LedgerAccountService} from '../../../../../services/api/finance/ledger-accounts.service';
import {PostPrintChecksDialogComponent} from '../post-print-checks-dialog/post-print-checks-dialog.component';
import {FirstCheckNumberDialogComponent} from '../first-check-number-dialog/first-check-number-dialog.component';
import {NotificationsService} from 'angular2-notifications';

@Component({
    selector: 'app-print-checks-dialog',
    templateUrl: './print-checks-dialog.component.html',
    styleUrls: ['./print-checks-dialog.component.scss']
})
export class PrintChecksDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    firstPaymentMadeId: number | undefined;
    firstPaymentMade: IPaymentMadeListItem | undefined;
    paymentsMadeToPrint: IPaymentMadeListItem[] = [];
    shiftsForm: UntypedFormGroup = this.fb.group({
        top: [0, [Validators.required]],
        left: [0, [Validators.required]]
    });
    saveDisabled: boolean = false;
    blobPreviewUrl: string | null = null;
    bankName: string | null = null;
    isPdfLoading: boolean = true;
    isChecksSaving: boolean = false;
    positions: string[] = ['top', 'middle', 'bottom'];
    selectedPosition: 'top' | 'middle' | 'bottom' = 'top';

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<CheckPrintSettingsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {paymentMadeIds: Array<number>; paymentMadeItems: IPaymentMadeListItem[]},
        private fb: UntypedFormBuilder,
        private paymentsMadeService: PaymentsMadeService,
        private ledgerAccountService: LedgerAccountService,
        private companySettingsSrv: CompanySettingsService,
        private sessionService: SessionService,
        private ntfs: NotificationsService
    ) {}

    ngOnInit() {
        if (
            (!this.data.paymentMadeItems || this.data.paymentMadeItems.length === 0) &&
            (!this.data.paymentMadeIds || this.data.paymentMadeIds.length === 0)
        ) {
            this.ntfs.error('Error', 'No checks to print.');
            this.close();
        }
        if (
            this.data.paymentMadeItems &&
            this.data.paymentMadeItems.length > 0 &&
            this.data.paymentMadeIds &&
            this.data.paymentMadeIds.length > 0
        ) {
            this.ntfs.error('Internal Error', 'Please please try again or contact support.');
            this.close();
        }
        this.setupPaymentsToPrint();
    }

    async setupPaymentsToPrint() {
        // TODO: should be refactored in future. This component should works only with IDs. Also some validation should be moved to this component after refactoring.
        if (this.data.paymentMadeIds.length > 0) {
            const paymentsMade = await this.paymentsMadeService.getPaymentsMadeForPrintCheck(this.data.paymentMadeIds);
            if (!paymentsMade || paymentsMade.length === 0) {
                this.ntfs.error('Error', 'No checks to print.');
                this.close();
            }
            this.paymentsMadeToPrint = paymentsMade;
        } else {
            // we keep this for backward compatibility and in future this should be removed
            this.paymentsMadeToPrint = this.data.paymentMadeItems;
        }

        this.firstPaymentMadeId = this.paymentsMadeToPrint[0].payment_made_id;
        const firstPaymentMadeLAId = this.paymentsMadeToPrint[0].paid_by__ledger_account_fk_id;
        this.firstPaymentMade = this.paymentsMadeToPrint[0];

        this.ledgerAccountService
            .getLedgerAccountById(firstPaymentMadeLAId as number)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                this.bankName = res.bank_name;
            });

        this.getCheckPreview();
    }

    getCheckPreview() {
        this.companySettingsSrv
            .getCompanySettings(this.sessionService.profile!.company_fk_id!)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data: ICompanySettings) => {
                this.downloadCheckPreview(data);
            });
    }

    async downloadCheckPreview(data: ICompanySettings) {
        if (data.check_margins) {
            this.shiftsForm.patchValue({
                top: data.check_margins.top,
                left: data.check_margins.left
            });

            this.selectedPosition = data.check_margins.check_position || 'top';

            const checkPdf = await this.paymentsMadeService.downloadCheckPdfPromise(this.firstPaymentMadeId as number, {
                check_margins: true,
                show_background: true,
                check_position: this.selectedPosition
            });

            if (checkPdf) {
                const blob = new Blob([new Uint8Array(checkPdf.data)], {type: 'application/pdf'});
                this.blobPreviewUrl = URL.createObjectURL(blob);
                this.isPdfLoading = false;
            }
        }
    }

    async applyShift() {
        if (!this.firstPaymentMadeId) {
            return null;
        }
        const rawForm = this.shiftsForm.getRawValue();
        this.blobPreviewUrl = null;
        this.isPdfLoading = true;

        if (this.firstPaymentMadeId) {
            const checkPdf = await this.paymentsMadeService.downloadCheckPdfPromise(this.firstPaymentMadeId, {
                check_margins: {top: rawForm.top, left: rawForm.left},
                show_background: true,
                check_position: this.selectedPosition
            });

            if (checkPdf) {
                const blob = new Blob([new Uint8Array(checkPdf.data)], {type: 'application/pdf'});
                this.blobPreviewUrl = URL.createObjectURL(blob);
                this.isPdfLoading = false;
            }
        }
    }

    enableDisableForms(action: 'enable' | 'disable') {
        if (action === 'disable') {
            this.shiftsForm.disable();
            this.isChecksSaving = true;
        } else {
            this.shiftsForm.enable();
            this.isChecksSaving = false;
        }
    }

    print() {
        this.enableDisableForms('disable');
        const rawForm = this.shiftsForm.getRawValue();

        this.companySettingsSrv
            .updateCompanySettings(this.sessionService.profile!.company_fk_id!, {
                check_margins: {
                    top: rawForm.top,
                    left: rawForm.left,
                    check_position: this.selectedPosition
                }
            })
            .pipe(
                switchMap(() =>
                    this.paymentsMadeService.downloadChecksPdf({
                        payments_made: this.paymentsMadeToPrint.map((check) => {
                            return {
                                payment_made_id: check.payment_made_id,
                                check_number: check.check_number
                            };
                        }),
                        check_margins: true,
                        show_background: false,
                        check_position: this.selectedPosition
                    })
                ),
                map((res) => new Blob([new Uint8Array(res.data)], {type: 'application/pdf'})),
                tap((result) => {
                    this.enableDisableForms('enable');
                    const blobUrl = URL.createObjectURL(result);
                    const iframe = document.createElement('iframe');
                    iframe.id = 'print-frame';

                    iframe.style.display = 'none';
                    iframe.src = blobUrl;
                    iframe.onload = () => {
                        setTimeout(() => {
                            if (iframe.contentWindow) {
                                iframe.contentWindow.print();

                                const firstCheckNumberDialogRef = this.dialog.open(FirstCheckNumberDialogComponent, {
                                    panelClass: 'padding-0',
                                    minWidth: 320,
                                    data: {
                                        payments: this.paymentsMadeToPrint
                                    }
                                });

                                firstCheckNumberDialogRef
                                    .afterClosed()
                                    .pipe(
                                        map((firstCheckNumber: number | null) =>
                                            this.dialog.open(PostPrintChecksDialogComponent, {
                                                panelClass: 'padding-0',
                                                disableClose: true,
                                                minWidth: 320,
                                                data: {
                                                    firstCheckNumber,
                                                    payments: this.paymentsMadeToPrint
                                                }
                                            })
                                        ),
                                        switchMap((postPrintChecksDialogRef) => postPrintChecksDialogRef.afterClosed()),
                                        map((result) => {
                                            if (result && result.submitted) {
                                                this.close();
                                            }
                                        }),
                                        takeUntil(this.unsubscribe)
                                    )
                                    .subscribe();
                            }
                        }, 0);
                    };
                    document.body.appendChild(iframe);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    }

    close() {
        this.dialogRef.close();
    }

    selectionChange(event: any) {
        this.selectedPosition = event.value;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
