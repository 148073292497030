import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {ITagCategory} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {EditTagCategoryDialogComponent} from '../edit-tag-category/edit-tag-category-dialog.component';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {selectTagCategories} from 'app/store/company-wide/company-wide.selectors';

@Component({
    selector: 'app-tag-list',
    templateUrl: 'tag-list.component.html',
    styleUrls: ['tag-list.component.scss']
})
export class TagListComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    displayedColumns: string[] = ['category', 'tags', 'settings'];
    dataSource: MatTableDataSource<ITagCategory>;

    constructor(
        private store: Store<any>,
        public dialog: MatDialog
    ) {
        this.dataSource = new MatTableDataSource<ITagCategory>([]);
    }

    ngOnInit() {
        this.loadCategories();
    }

    openTagDialog(tagCategory = {}) {
        const dialogRef = this.dialog.open(EditTagCategoryDialogComponent, {
            width: '500px',
            data: {
                tag_category: tagCategory
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {});
    }

    loadCategories() {
        this.store.pipe(select(selectTagCategories), takeUntil(this.unsubscribe)).subscribe((res) => {
            if (res) {
                this.dataSource.data = res;
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
