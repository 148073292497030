<div mat-dialog-title>
    <h3>{{ title }}</h3>
</div>
<div class="d-flex align-items-center" *ngIf="!isContactLoad">
    <span class="mr-2">Loading...</span> <mat-spinner [diameter]="20"></mat-spinner>
</div>
<div
    class="d-flex align-items-center mt-3 mb-3"
    *ngIf="isContactLoad && !isAbleToPayByZipiFinancialCard && !isAbleToPayByZipiFinancialBank && !authMerchantGateway"
>
    You cannot pay this Invoice.
</div>

<div class="my-2" *ngIf="isContactLoad && isAbleToPayByZipiFinancialCard" [formGroup]="formGroup">
    <div class="p-1">
        <div *ngIf="accessMethod === 'internal'">
            <div class="d-flex align-items-center" *ngIf="zipiPayCardGateway && zipiPayCardGatewayLoaded">
                <mat-form-field class="w-48 mr-2" *ngIf="moneySenderContact && !unsavedMethod">
                    <mat-select formControlName="paid_by__payment_method_fk_id" required>
                        <ng-container *ngIf="zipiPayCardGateway">
                            <div *ngFor="let method of zipiPayCardGateway.payment_methods">
                                <mat-option
                                    *ngIf="isAbleToDisplay(method, 'zipi_fin')"
                                    [value]="method.payment_method_id"
                                >
                                    {{ method?.title }}
                                </mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                    <mat-placeholder>Credit Cards</mat-placeholder>
                </mat-form-field>

                <span *ngIf="unsavedMethod" class="w-48 mr-2">{{ unsavedMethod.title }}</span>

                <button
                    [rule]="{contacts__manage_payment_methods: true}"
                    rbac
                    [denyMethod]="'style'"
                    [allowStyle]="{display: 'inline-block'}"
                    [denyStyle]="{display: 'none'}"
                    class="mb-2"
                    type="button"
                    mat-raised-button
                    [disabled]="!contactOwnerZipiFinancialSettings || !contactOwnerZipiFinancialSettings.customer_ref"
                    (click)="addPaymentMethod('card')"
                    *ngIf="isTppCreditCardFeatureFlagEnabled && accessMethod === 'internal'"
                >
                    Enter Credit Card
                </button>
            </div>

            <div
                class="d-flex align-items-center justify-content-center w-100 mb-2"
                *ngIf="!zipiPayCardGateway && zipiPayCardGatewayLoaded"
            >
                <span style="color: red">To use "Credit Card" you must activate Money Transfer Service.</span>
            </div>
        </div>
        <div *ngIf="accessMethod === 'public'">
            <div class="d-flex align-items-center">
                <div *ngIf="!unsavedMethod" class="w-48 mr-2">
                    <mat-form-field
                        class="w-100"
                        *ngIf="
                            moneySenderContact &&
                            zipiPayCardGateway &&
                            zipiPayCardGatewayLoaded &&
                            currentProfile &&
                            currentProfile.company_fk_id !== invoice.owner__company_fk_id
                        "
                    >
                        <mat-select formControlName="paid_by__payment_method_fk_id" required>
                            <ng-container *ngIf="zipiPayCardGateway">
                                <div *ngFor="let method of zipiPayCardGateway.payment_methods">
                                    <mat-option
                                        *ngIf="isAbleToDisplay(method, 'zipi_fin')"
                                        [value]="method.payment_method_id"
                                    >
                                        {{ method?.title }}
                                    </mat-option>
                                </div>
                            </ng-container>
                        </mat-select>
                        <mat-placeholder>Credit Cards</mat-placeholder>
                    </mat-form-field>
                </div>

                <span *ngIf="unsavedMethod" class="w-48 mr-2">{{ unsavedMethod.title }}</span>

                <button
                    class="mb-2"
                    type="button"
                    mat-raised-button
                    (click)="addPaymentMethod('card')"
                    *ngIf="accessMethod === 'public'"
                >
                    Enter Credit Card
                </button>
            </div>

            <div
                class="d-flex align-items-center justify-content-center w-100 mb-2"
                *ngIf="!zipiPayCardGateway && zipiPayCardGatewayLoaded"
            >
                <span style="color: red">To use "Credit Card" you must activate Money Transfer Service.</span>
            </div>
        </div>

        <div class="d-flex">
            <mat-form-field class="w-48">
                <input
                    matInput
                    formControlName="amount"
                    placeholder="Amount"
                    [maskito]="currencyMaskitoMask"
                    (keyup)="validateAmount($event)"
                />
            </mat-form-field>
        </div>
    </div>
</div>

<mat-accordion [formGroup]="formGroup">
    <mat-expansion-panel
        [expanded]="false"
        (opened)="createNewAuthCardMode = false; formGroup.controls.paid_by__payment_method_fk_id.setValue(null)"
        *ngIf="
            isContactLoad &&
            isAbleToPayByZipiFinancialBank &&
            accessMethod === 'internal' &&
            sessionService.profile &&
            sessionService?.profile?.company_fk_id !== invoice?.owner__company_fk_id
        "
        [formGroup]="formGroup"
    >
        <mat-expansion-panel-header>
            <mat-panel-title> Connected Bank </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="contactZipiFinBankGateways && contactZipiFinBankGateways.length > 0" class="d-flex">
            <mat-form-field class="w-48 mr-2">
                <mat-select [placeholder]="'Payment Method'" formControlName="paid_by__payment_method_fk_id">
                    <mat-optgroup *ngFor="let gateway of contactZipiFinBankGateways" [label]="gateway.title">
                        <ng-container *ngFor="let method of gateway.payment_methods">
                            <!--                        (onSelectionChange)="methodChanged($event, gateway, method)"-->
                            <mat-option
                                *ngIf="method.type !== 'zipi_financial_credit_card'"
                                [value]="method.payment_method_id"
                                [disabled]="
                                    gateway.status !== 'active' ||
                                    method.status !== 'active' ||
                                    !method.related__ledger_account_fk_id
                                "
                            >
                                <span>{{ method.title }}</span>
                            </mat-option>
                        </ng-container>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-48">
                <input
                    matInput
                    formControlName="amount"
                    placeholder="Amount"
                    [maskito]="currencyMaskitoMask"
                    (keyup)="validateAmount($event)"
                />
            </mat-form-field>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel
        [expanded]="true"
        *ngIf="moneySenderContact && authMerchantGateway"
        (opened)="createNewAuthCardMode = false; formGroup.controls.paid_by__payment_method_fk_id.setValue(null)"
    >
        <mat-expansion-panel-header>
            <mat-panel-title> Stored Cards </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="d-flex">
            <mat-form-field class="w-48 mr-2">
                <mat-select formControlName="paid_by__payment_method_fk_id" required>
                    <ng-container *ngIf="moneySenderContact && moneySenderContact.contact_payment_gateways">
                        <ng-container *ngFor="let gateway of moneySenderContact.contact_payment_gateways">
                            <div *ngFor="let method of gateway.payment_methods">
                                <mat-option *ngIf="isAbleToDisplay(method, 'auth')" [value]="method.id">
                                    {{ method?.title }}
                                </mat-option>
                            </div>
                        </ng-container>
                    </ng-container>
                </mat-select>
                <mat-placeholder>Credit Cards</mat-placeholder>
            </mat-form-field>
            <mat-form-field class="w-48">
                <input
                    matInput
                    formControlName="amount"
                    placeholder="Amount"
                    [maskito]="currencyMaskitoMask"
                    (keyup)="validateAmount($event)"
                />
            </mat-form-field>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel
        *ngIf="moneySenderContact && authMerchantGateway"
        (opened)="createNewAuthCardMode = true"
        (closed)="createNewAuthCardMode = false"
    >
        <mat-expansion-panel-header>
            <mat-panel-title> Enter Card Info </mat-panel-title>
        </mat-expansion-panel-header>
        <div [formGroup]="newCardGroup">
            <div style="max-width: 500px; margin: auto; width: 500px" class="m-2">
                <div class="my-3">
                    <h3>Card Details</h3>
                    <div class="w-100 px-2 d-flex">
                        <mat-form-field class="w-50 mr-2">
                            <input matInput required type="text" formControlName="firstName" />
                            <mat-placeholder>First Name</mat-placeholder>
                        </mat-form-field>

                        <mat-form-field class="w-50 mr-2">
                            <input matInput required type="text" formControlName="lastName" />
                            <mat-placeholder>Last Name</mat-placeholder>
                        </mat-form-field>
                    </div>

                    <div class="w-100 px-2 d-flex">
                        <mat-form-field class="w-50 mr-2">
                            <input matInput required type="text" formControlName="cardNumber" />
                            <mat-placeholder>Card Number</mat-placeholder>
                        </mat-form-field>

                        <mat-form-field class="w-50 mr-2">
                            <input matInput required type="text" formControlName="cardCode" />
                            <mat-placeholder>Card Code</mat-placeholder>
                        </mat-form-field>
                    </div>

                    <div class="w-100 px-2 d-flex">
                        <mat-form-field class="w-50 mr-2">
                            <input matInput required type="text" formControlName="expMonth" />
                            <mat-placeholder>Expiration Month (ie 07)</mat-placeholder>
                        </mat-form-field>

                        <mat-form-field class="w-50 mr-2">
                            <input matInput required type="text" formControlName="expYear" />
                            <mat-placeholder>Expiration Year (ie 23)</mat-placeholder>
                        </mat-form-field>
                    </div>
                </div>

                <div class="my-3">
                    <h3>Billing Address</h3>

                    <mat-form-field class="w-100 px-2">
                        <input matInput required type="text" formControlName="address" />
                        <mat-placeholder>Address</mat-placeholder>
                    </mat-form-field>

                    <mat-form-field class="w-100 px-2">
                        <input matInput required type="text" formControlName="city" />
                        <mat-placeholder>City</mat-placeholder>
                    </mat-form-field>

                    <div class="w-100 px-2 d-flex">
                        <mat-form-field class="w-50 mr-2">
                            <input matInput required type="text" formControlName="state" />
                            <mat-placeholder>State</mat-placeholder>
                        </mat-form-field>

                        <mat-form-field class="w-50 mr-2">
                            <input matInput required type="number" formControlName="zipCode" />
                            <mat-placeholder>Zip Code</mat-placeholder>
                        </mat-form-field>
                    </div>

                    <mat-form-field class="w-100 px-2">
                        <mat-select [placeholder]="'Country'" formControlName="country">
                            <mat-option *ngFor="let country of countryList" [value]="country.slug">
                                {{ country.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div>
                <mat-checkbox formControlName="use_as_default">Save this Credit Card</mat-checkbox>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<div class="d-flex justify-content-end mb-2 mt-2">
    <button class="mr-2" mat-raised-button (click)="dialogRef.close(false)" tabindex="-1">Cancel</button>
    <button
        class="mr-2"
        *ngIf="
            !createNewAuthCardMode &&
            isContactLoad &&
            (isAbleToPayByZipiFinancialCard || isAbleToPayByZipiFinancialBank || authMerchantGateway)
        "
        mat-raised-button
        color="primary"
        [disabled]="isContinueButtonDisabled"
        (click)="chargeInvoice()"
        tabindex="1"
    >
        Continue
    </button>
    <button
        class="mr-2"
        *ngIf="
            createNewAuthCardMode &&
            isContactLoad &&
            (isAbleToPayByZipiFinancialCard || isAbleToPayByZipiFinancialBank || authMerchantGateway)
        "
        mat-raised-button
        color="primary"
        [disabled]="!data || !data.invoice || isContinueButtonDisabled"
        (click)="chargeNewCard()"
        tabindex="1"
    >
        Continue
    </button>
</div>
