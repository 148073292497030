import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {GenericFormArray} from '../../../../../../../../entites/generic.entity';
import {ChipNode} from '../../../../../../../account-info/compensation/models/chip-node';
import {map, takeUntil} from 'rxjs/operators';
import {FetchContacts} from '../../../../../../../contacts/store/contacts.actions';
import {select, Store} from '@ngrx/store';
import {selectContacts} from '../../../../../../../contacts/store/contacts.selectors';
import {IContactsState} from '../../../../../../../contacts/store/contacts.reducer';
import {IContact, IPaymentGateway, IProduct} from '@cyberco-nodejs/zipi-typings';
import * as moment from 'moment';
import {PaymentMethodsService} from '../../../../../../../profile/services/payment-methods.service';
import {ContactCreateDialogComponent} from '../../../../../../../contacts/contact-dialogs/contact-create-dialog/contact-create-dialog.component';
import {CombinedCompensationProfilePickerComponent} from '../../../../../../../account-info/compensation/components/combined-compensation-profile-picker/combined-compensation-profile-picker.component';
import {FormGroupWithFormControls} from '../../../../../../../../typings/common';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../../../../utilities/maskito';

@Component({
    selector: 'app-create-request-dialog',
    templateUrl: './create-request-dialog.component.html',
    styleUrls: ['./create-request-dialog.component.scss']
})
export class CreateRequestDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('combinedPicker', {static: true}) combinedPicker: CombinedCompensationProfilePickerComponent | undefined;

    requestForm: FormGroupWithFormControls = new UntypedFormGroup({}) as FormGroupWithFormControls;

    requestReceiverCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);

    contactsList: IContact[] = [];
    currencyMaskitoMask = currencyMaskitoOptions;
    companyGateways: Array<IPaymentGateway> = [];

    constructor(
        private fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<CreateRequestDialogComponent>,
        private paymentMethodsService: PaymentMethodsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        protected store: Store<IContactsState>,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        this.store.dispatch(new FetchContacts());
        this.store
            .pipe(
                select(selectContacts), // migrate to ContactsLite: done
                map((contacts) => {
                    this.contactsList = contacts;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.requestForm = this.fb.group({
            deal_fk_id: ['', []],
            product_fk_id: [null, [Validators.required]],

            title: ['', []],

            request_receiver__contact_fk_id: ['', [Validators.required]],
            request_receiver_email: ['', [Validators.required]],

            request_date: [Number(moment().format('YYYYMMDD')), []],
            due_date: [null, []],
            request_amount: [null, [Validators.required]],

            pay_to__payment_method_fk_ids: [[], []]
        }) as FormGroupWithFormControls;

        this.paymentMethodsService
            .getCompanyGateways()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((gateways) => {
                this.companyGateways = gateways.filter((gateway) =>
                    ['authorize_net_merchant', 'dwolla_business'].includes(gateway.type)
                );
            });

        this.requestReceiverCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            if (values && values.length > 0) {
                this.requestForm.controls.request_receiver__contact_fk_id.patchValue(values[0].target_id);

                const contactEmail = this._getContactEmail(values[0].target_id);
                this.requestForm.controls.request_receiver_email.patchValue(contactEmail);
            } else {
                this.requestForm.controls.request_receiver__contact_fk_id.patchValue(null);
                this.requestForm.controls.request_receiver_email.patchValue('');
            }
        });
    }

    selectItem(product: IProduct) {
        if (this.requestForm.controls.request_amount.value === null) {
            this.requestForm.controls.request_amount.patchValue(product.price);
        }
        // if (this.requestForm.controls.label.value === null) {
        //     this.requestForm.controls.label.patchValue(product.name);
        // }
    }

    contactCreate() {
        const dialogRef = this.dialog.open(ContactCreateDialogComponent, {
            // minWidth: 320,
            // width: '90vw',
            // maxWidth: '90vh',
            autoFocus: false,
            data: {
                category: 'customer'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                // force reload
                this.combinedPicker!.params.offset = 0;
                this.combinedPicker!.initialLoadData();
            });
    }

    _getContactEmail(contactId: number) {
        const selectedContact = this.contactsList.find((contact) => contact.contact_id === contactId);
        let selectedContactEmail = '';
        if (selectedContact) {
            if (!selectedContactEmail) {
                const mainPerson = selectedContact.contact_persons.find((person) => person.type === 'main_person');
                selectedContactEmail = mainPerson ? mainPerson.email : '';

                if (!selectedContactEmail) {
                    const anyPersonWithEmail = selectedContact.contact_persons.find((person) => !!person.email);
                    selectedContactEmail = anyPersonWithEmail ? anyPersonWithEmail.email : '';
                }
            }
        }

        return selectedContactEmail;
    }

    continue(status: string) {
        if (this.requestForm.invalid) {
            this.requestForm.markAllAsTouched();
            return;
        }
        const requestData = this.requestForm.getRawValue();
        requestData['status'] = status;
        requestData.request_amount = Number(cleanCurrencyString(requestData.request_amount));
        this.dialogRef.close(requestData);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
