<!------- List of bills ------->
<div class="table-background" style="height: calc(100vh - 192px)">
    <table mat-table [dataSource]="dataSource" [ngClass]="{'d-none': dataSource.data.length === 0}" class="w-100">
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef class="w-40">Title</th>
            <td mat-cell *matCellDef="let element" class="w-40">
                {{ element.title }}
            </td>
        </ng-container>

        <ng-container matColumnDef="last_invoice_date">
            <th mat-header-cell *matHeaderCellDef class="w-20">Last Invoice</th>
            <td mat-cell *matCellDef="let element" class="w-20 px-1">
                {{
                    element.last_invoice_date
                        ? (element.last_invoice_date | dateFromNumber | date: 'mediumDate')
                        : 'N/A'
                }}
            </td>
        </ng-container>

        <ng-container matColumnDef="next_invoice_date">
            <th mat-header-cell *matHeaderCellDef class="w-20">Next Invoice</th>
            <td mat-cell *matCellDef="let element" class="w-20 px-1">
                {{ element.next_invoice_date | dateFromNumber | date: 'mediumDate' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="summary_status">
            <th mat-header-cell *matHeaderCellDef class="w-20 pr-5 text-right">Amount</th>
            <td mat-cell *matCellDef="let element" class="w-20 pr-5 text-right">
                {{ element.total_amount | currency }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [routerLink]="['../sales/invoices/recurring', row.recurring_invoice_id]"
            class="zp-table-row"
        ></tr>
    </table>

    <!------- Paginator ------->
    <div class="custom-paginator-background" style="width: 638px"></div>
    <mat-paginator
        class="custom-paginator"
        [length]="scrollData.total"
        [pageSize]="scrollData.limit"
        [pageSizeOptions]="pageSizeOptions"
    >
    </mat-paginator>
    <!------- No values ------->
    <div
        class="text-muted m-3 text-center no-items"
        *ngIf="dataSource.data && dataSource.data.length === 0 && isLoaded"
    >
        No invoices yet.
    </div>

    <div class="load-container" id="load-container" *ngIf="!isLoaded">
        <div class="loader">Loading...</div>
    </div>
</div>
