import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, NgForm, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {AuthService} from '../../../services/auth.service';
import {UserService} from '../../../services/user.service';
import {SessionService} from '../../../services/session.service';
import {ContactInvitesService} from '../../contact-invites/services/contact-invites.service';
import {IDealDepositRequest, IInvoice, IUser} from '../../../../typings';
import {NotificationsServiceZipi} from '../../notifications/notifications.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {InvoicePublicService} from '../../finance/services/invoice-public.service';
import {DealFinancialApiService} from '../../deals/components/deal/deal-financial.api.service';
import {MatDialog} from '@angular/material/dialog';
import {AfterSignUpDialogComponent} from '../after-sign-up-dialog/after-sign-up-dialog.component';

@Component({
    selector: 'app-sign-up-contact-deposit-request',
    templateUrl: './sign-up-contact-deposit-request.component.html',
    styleUrls: ['./sign-up-contact-deposit-request.component.scss']
})
export class SignUpContactDepositRequestComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    protected requestHash = '';
    depositRequest: IDealDepositRequest | null = null;
    user: IUser | null = null;

    signUpForm: UntypedFormGroup | null = null;

    constructor(
        protected authService: AuthService,
        protected usersService: UserService,
        protected notificationsService: NotificationsServiceZipi,
        protected route: ActivatedRoute,
        protected fb: UntypedFormBuilder,
        protected sessionService: SessionService,
        protected contactInvitesService: ContactInvitesService,
        private invoicePublicService: InvoicePublicService,
        protected router: Router,
        private dealFinancialApiService: DealFinancialApiService,
        public dialog: MatDialog
    ) {
        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            if (params['request_hash']) {
                this.requestHash = params['request_hash'];
            }
        });
    }

    async ngOnInit() {
        this.signUpForm = this.fb.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            account_name: ['', Validators.required],
            company_plan: ['', Validators.required]
        });

        if (this.requestHash) {
            this.dealFinancialApiService
                .getRequestByHash(this.requestHash)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((depositRequest) => {
                    this.depositRequest = depositRequest;
                    if (!this.signUpForm) {
                        return;
                    }
                    this.signUpForm.get('email')!.setValue(this.depositRequest.request_receiver_email);
                    this.signUpForm.get('email')!.disable();
                    this.signUpForm.get('company_plan')!.setValue('free');

                    this.existUserCheck(this.depositRequest.request_receiver_email);
                });
        }
    }

    submitSignUpForm() {
        if (!this.signUpForm || !this.signUpForm.valid) {
            this.notificationsService.addError('Not all required fields are filled');

            return false;
        }

        const formObj = this.signUpForm.getRawValue();

        const company = {
            title: formObj.account_name,
            plan: formObj.company_plan
        };

        const user = {
            first_name: formObj.first_name,
            last_name: formObj.last_name,
            email: formObj.email.toLowerCase(),
            firebase_id: ''
        };

        if (this.user) {
            return this.authService
                .signInWithEmailAndPassword(user.email, formObj.password)
                .then((firebaseUserInfo) => {
                    user.firebase_id = firebaseUserInfo.user!.uid;

                    return this.afterRegister(user, company);
                })
                .catch((err) => {
                    this.notificationsService.addError(err.message);

                    throw err;
                });
        } else {
            return this.authService
                .createUserWithEmailAndPassword(user.email, formObj.password)
                .then((firebaseUserInfo) => {
                    user.firebase_id = firebaseUserInfo.user!.uid;

                    return this.afterRegister(user, company);
                })
                .catch((err) => {
                    this.notificationsService.addError(err.message);

                    throw err;
                });
        }
    }

    protected afterRegister(user: any, company: any, fullPageReload = false) {
        if (!this.depositRequest) {
            return;
        }
        return this.usersService
            .afterRegister(user, company, this.depositRequest.request_receiver__contact_fk_id)
            .then((response) => {
                this.usersService
                    .afterRegisterContactDepositRequest(this.requestHash, user, response.company)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((res) => {
                        return this.openAfterRegisterPopUp();

                        // window.location.replace(`/escrowdeposit/${this.depositRequest!.deposit_request_id}`);
                    });
            });
    }

    openAfterRegisterPopUp() {
        const dialogRef = this.dialog.open(AfterSignUpDialogComponent, {
            minWidth: 320,
            data: {}
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((redirect) => {
                if (redirect) {
                    this.authService.logout().then(() => {
                        localStorage.setItem('pw_sidenav_state', 'opened');
                        window.location.replace('/login');
                    });
                }
            });
    }

    existUserCheck(email: string) {
        this.invoicePublicService
            .getUserByEmail(email)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((user) => {
                this.user = user;
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
