import {Injectable} from '@angular/core';
import {ServiceRequester} from '../service.requester';
import {NotificationsServiceZipi} from '../../modules/notifications/notifications.service';
import {User} from '../../models/user';
import {Profile} from '../../models/profile';
import {
    UiModsDealBadgeInListModel,
    UiModsDealBadgeOnPageModel,
    UiModsDealCommissionCategorizationNoteModel,
    UiModsDealsBoardsButtonModel,
    UiModsDealsButtonModel,
    UiModsMenuOptionModel
} from '../../modules/ui-mods/models/ui-mods.model';
import {DealTemplateEntity} from '../../modules/deals/components/deal/common/deal.models';

@Injectable()
export class AppInitApi {
    constructor(
        public requester: ServiceRequester,
        protected notificationsService: NotificationsServiceZipi
    ) {}

    appInit(redirectUrl: string | null = null): Promise<AppInitResponse> {
        let additional = '';
        if (redirectUrl) {
            additional = `?redirectUrl=${redirectUrl}`;
        }
        return this.requester.makeMsCall(`/app/init${additional}`, 'GET').then((res) => {
            return Object.assign(new AppInitResponse(), res);
        });
    }

    loadProfile(profileId: number) {
        return this.requester.makeMsCall('/app/load-profile/' + profileId, 'GET').then((res) => {
            return Object.assign(new AppInitResponse(), res);
        });
    }
}

export class AppInitResponse {
    systemUser: User | null = null;
    currentProfile: Profile | null = null;
    zipiAddonHash: string = '';
    zipiZohoToken: string = '';
    availableProfiles: Profile[] = [];
    companyTags: any;
    permissions: Object | null = null;
    groupsPermissions: Object | null = null;
    dealTemplates: {[id: number]: DealTemplateEntity} | null = null;
    linkToCommunity: string = '';
    linkForBackgroundAuthInCommunity: string = '';
    linkToZendesk: string = '';
    uiMods?: {
        dealsBoardButton: UiModsDealsBoardsButtonModel[];
        createDealMenuOptions: UiModsMenuOptionModel[];
        dealBadgesInList: UiModsDealBadgeInListModel[];
        dealBadgesOnPage: UiModsDealBadgeOnPageModel[];
        dealsButton: UiModsDealsButtonModel[];
        dealCommissionCategorizationNote: UiModsDealCommissionCategorizationNoteModel[];
    };
    superAdminUserEmail: string | null = null;
    payloadCoActivatedAt: string | null = null;
}
