import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {WildcardsService} from 'app/services/api/wildcards.service';
import {WildcardType} from 'app/models/wildcard-type';
import {Wildcard} from 'app/models/wildcard';
import {IWildcard} from '@cyberco-nodejs/zipi-typings';
import {takeUntil} from 'rxjs/operators';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {NotificationsService} from 'angular2-notifications';
import {select, Store} from '@ngrx/store';
import {ICompanyWideState} from 'app/store/company-wide/company-wide.reducer';
import {selectWildcards} from 'app/store/company-wide/company-wide.selectors';
import {CreateWildcard, DeleteWildcard, UpdateWildcard} from 'app/store/company-wide/company-wide.actions';

@Component({
    selector: 'app-contact-edit-compliance-create-wildcard',
    template: `
        <section>
            <mat-dialog-content>
                <div class="d-flex flex-row align-items-baseline">
                    <h1 mat-dialog-title style="margin-right: 15px">Create Attribute or edit</h1>
                    <mat-form-field>
                        <mat-select
                            [(ngModel)]="currentAttribute"
                            (selectionChange)="selectedAttributeChange($event.value)"
                            placeholder="Select attribute"
                        >
                            <mat-option [value]="newAttribute">None</mat-option>
                            <mat-option *ngFor="let attribute of attributesList" [value]="attribute">
                                {{ attribute.title }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="d-flex flex-column">
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field ngDefaultControl class="w-100">
                                <input
                                    [(ngModel)]="wildcardTitle"
                                    required
                                    matInput
                                    type="text"
                                    placeholder="Custom Name"
                                />
                            </mat-form-field>
                        </div>

                        <div class="col-6">
                            <mat-form-field ngDefaultControl class="w-100">
                                <mat-label>Type</mat-label>
                                <mat-select
                                    [(ngModel)]="wildcardSystemKey"
                                    required
                                    [disabled]="currentAttribute.wildcard_id ? true : false"
                                >
                                    <mat-option
                                        *ngFor="let wildcardType of wildcardTypes"
                                        [value]="wildcardType.system_key"
                                    >
                                        {{ wildcardType.title }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-dialog-content>
            <mat-dialog-actions>
                <div class="justify-content-start" style="display: flex; flex: 1;">
                    <button
                        type="button"
                        mat-raised-button
                        color="warn"
                        tabindex="1"
                        *ngIf="currentAttribute.wildcard_id"
                        (click)="deleteWildcard()"
                    >
                        Delete
                    </button>
                </div>
                <div class="justify-content-end" style="display: flex; flex: 1;">
                    <button type="button" mat-raised-button color="primary" tabindex="1" (click)="saveWildcard()">
                        Save
                    </button>
                    <button type="button" mat-raised-button tabindex="-1" (click)="dialogRef.close(false)">
                        Cancel
                    </button>
                </div>
                <div *ngIf="usedInProfiles?.length" class="delete-messages">
                    References to Attribute must be removed before this can be deleted.
                    <div *ngFor="let compProfile of usedInProfiles; let i = index">
                        {{ i + 1 }}) Compensation Rule:
                        <span [ngClass]="{untitled: !compProfile.profileTitle}">{{
                            compProfile.profileTitle ? compProfile.profileTitle : 'Untitled Compensation Profile'
                        }}</span>
                        >
                        <span [ngClass]="{untitled: !compProfile.elementTitle}">{{
                            compProfile.elementTitle ? compProfile.elementTitle : 'Untitled Compensation Element'
                        }}</span>
                    </div>
                </div>
            </mat-dialog-actions>
        </section>
    `,
    styles: [
        `
            mat-dialog-actions {
                display: flex;
                justify-content: space-around;
            }

            .untitled {
                font-style: italic;
                color: gray;
            }

            .delete-messages {
                display: flex;
                flex-direction: column;
                margin: 10px 0;
                max-height: 125px;
                overflow-x: hidden;
                overflow-y: auto;
            }
        `
    ]
})
export class CreateWildcardDialogComponent implements OnInit, OnDestroy {
    wildcardTypes: WildcardType[] = WildcardType.hardcoded;
    private unsubscribe: Subject<void> = new Subject();
    attributesList: IWildcard[] = [];
    wildcardTitle: string | undefined = '';
    wildcardSystemKey: string | undefined = '';
    currentAttribute: Wildcard = new Wildcard();
    newAttribute: Wildcard = new Wildcard();
    usedInProfiles: {
        profileTitle: string;
        elementTitle: string;
    }[] = [];

    constructor(
        public dialogRef: MatDialogRef<CreateWildcardDialogComponent>,
        private wildcardsService: WildcardsService,
        protected dialog: MatDialog,
        private ntfs: NotificationsService,
        private store: Store<ICompanyWideState>
    ) {}

    saveWildcard() {
        this.currentAttribute.title = this.wildcardTitle;
        this.currentAttribute.wildcard_type_sk = this.wildcardSystemKey;
        if (!this.wildcardSystemKey || !this.wildcardTitle || (this.wildcardTitle && !this.wildcardTitle.length)) {
            this.ntfs.error('Compensation Attributes are not valid');
            return;
        }
        if (!this.currentAttribute.wildcard_id) {
            this.store.dispatch(new CreateWildcard(this.currentAttribute));
            this.dialogRef.close(true);
        } else {
            this.store.dispatch(new UpdateWildcard(this.currentAttribute));
            this.dialogRef.close(true);
        }
    }

    deleteWildcard() {
        const confirmDialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            minHeight: 160,
            data: {
                title: `Confirm deleting attribute type`,
                message: `Please, confirm deleting`
            }
        });

        confirmDialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((confirmed: boolean) => {
                if (!confirmed) {
                    return;
                }
                const wildcard_id = this.currentAttribute.wildcard_id;
                if (wildcard_id) {
                    this.wildcardsService.deleteWildcard(wildcard_id).then((res) => {
                        if (
                            res &&
                            res.hasOwnProperty('result') &&
                            !res.result &&
                            res.hasOwnProperty('compensation_profiles_list')
                        ) {
                            this.usedInProfiles = res.compensation_profiles_list;
                            return;
                        }
                        this.store.dispatch(new DeleteWildcard(wildcard_id));
                        this.dialogRef.close(true);
                    });
                }
            });
    }

    selectedAttributeChange(value: string) {
        if (value) {
            this.wildcardTitle = this.currentAttribute.title;
            this.wildcardSystemKey = this.currentAttribute.wildcard_type_sk;
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngOnInit(): void {
        this.store.pipe(select(selectWildcards), takeUntil(this.unsubscribe)).subscribe((attributes) => {
            this.attributesList = attributes;
        });
    }

    @HostListener('document:click', ['$event'])
    documentClick(event: MouseEvent) {
        this.usedInProfiles = [];
    }
}
