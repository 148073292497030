import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {firstValueFrom, Subject, takeUntil} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CompanyGatewayService} from '../../../../../../../../../services/api/finance/company-gateway.service';
import {NotificationsService} from 'angular2-notifications';
import {SessionService} from '../../../../../../../../../services/session.service';
import {GatewayTypes, IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-zipi-financial-tpp-merchant-setup',
    templateUrl: './zipi-financial-tpp-merchant-setup.component.html',
    styleUrls: ['./zipi-financial-tpp-merchant-setup.component.scss']
})
export class ZipiFinancialTppMerchantSetupComponent implements OnInit, OnDestroy, OnChanges {
    private unsubscribe: Subject<void> = new Subject();

    @Input() gatewayType: GatewayTypes | undefined;
    @Input() operatingGateways: IPaymentGateway[] | undefined = [];
    @Output() reloadAndShowFinalPopup = new EventEmitter();

    availableOperatingGateways: IPaymentGateway[] = [];
    availableCloudBanks: IPaymentMethod[] = [];

    onboardingInfo:
        | {
              public_info: {legal_name: string; dba_name: string; email: string; business_phone_number: string};
              problem_fields: string[];
          }
        | undefined;

    connectToForm: UntypedFormGroup;

    currentStep: 1 | 2;

    isButtonLock: boolean = false;
    agree: boolean = false;

    constructor(
        private companyGatewayService: CompanyGatewayService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        public sessionService: SessionService,
        public dialog: MatDialog
    ) {
        this.currentStep = 1;
        this.connectToForm = this.fb.group({
            selected_gateway_id: [null, [Validators.required]],
            selected_cloud_bank_id: [null, [Validators.required]]
        });
    }

    ngOnInit() {
        this.connectToForm.controls.selected_gateway_id.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((change) => {
                this.connectToForm.controls.selected_cloud_bank_id.setValue(null);
                this.availableCloudBanks = [];
                const selectedGateway = this.availableOperatingGateways.find(
                    (gateway: IPaymentGateway) => gateway.customer_ref === change
                );
                if (selectedGateway) {
                    this.tryToSelectMethod(selectedGateway);
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.hasOwnProperty('operatingGateways') && changes.operatingGateways.currentValue) {
            this.availableOperatingGateways = changes.operatingGateways.currentValue.filter(
                (gateway: IPaymentGateway) => gateway.driver_type === 'muhnee'
            );
            if (this.availableOperatingGateways.length === 1) {
                this.connectToForm.controls.selected_gateway_id.setValue(
                    this.availableOperatingGateways[0].customer_ref
                );
                const result = this.tryToSelectMethod(this.availableOperatingGateways[0]);
                if (result) {
                    this.currentStep = 2;
                    this.checkGatewayInfo();
                }
            }
        }
    }

    tryToSelectMethod(gateway: IPaymentGateway) {
        let isSelected = false;
        if (gateway.payment_methods && gateway.payment_methods.length > 0) {
            this.availableCloudBanks = gateway.payment_methods.filter(
                (method) => method.type === 'zipi_financial_cloud_bank'
            );

            if (this.availableCloudBanks.length === 1) {
                this.connectToForm.controls.selected_cloud_bank_id.setValue(
                    this.availableCloudBanks[0].payment_method_ref
                );
                isSelected = true;
            }
        }

        return isSelected;
    }

    checkGatewayInfo() {
        if (
            !this.connectToForm.controls.selected_gateway_id.value ||
            !this.connectToForm.controls.selected_cloud_bank_id.value
        ) {
            return;
        }
        this.companyGatewayService
            .checkGatewayInfo(
                this.connectToForm.controls.selected_gateway_id.value,
                this.connectToForm.controls.selected_cloud_bank_id.value
            )
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.onboardingInfo = result;
                }
            });
    }

    continue() {
        this.currentStep = 2;
        this.checkGatewayInfo();
    }

    async createTppGateway() {
        if (this.connectToForm.invalid) {
            return;
        }

        this.isButtonLock = true;

        const formData = this.connectToForm.getRawValue();

        const gatewayData = {
            customer: null,
            customer_type: null,
            title: 'Credit Card',
            driver_type: 'tpp',
            gateway_type: this.gatewayType,
            connect_to_gateway: formData.selected_gateway_id,
            connect_to_cloud_bank: formData.selected_cloud_bank_id
        } as any;
        this.createPaymentGateway(gatewayData);
    }

    async createPaymentGateway(customerData: Partial<IPaymentGateway>) {
        const gateway = await firstValueFrom(this.companyGatewayService.createZipiFinancialGateway(customerData));

        if (gateway) {
            this.notificationsService.success(`Customer Created`);
            this.notificationsService.success(`Settings saved`);
            this.reloadAndShowFinalPopup.emit(null);
        } else {
            this.isButtonLock = false;
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
