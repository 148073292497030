<mat-card *ngIf="createBoardMode">
    <mat-card-title>Create New Board</mat-card-title>
    <!------- Create board ------->
    <mat-card-content>
        <mat-form-field>
            <input matInput placeholder="Board Name" [(ngModel)]="model.name" name="name" />
        </mat-form-field>
    </mat-card-content>

    <mat-card-actions class="d-flex justify-content-end">
        <!------- Button Close ------->
        <button mat-raised-button (click)="createBoardModeClosed.emit()">Close</button>
        <!------- Button Create ------->
        <button
            mat-raised-button
            class="mat-primary"
            [disabled]="!model.name.length || createDisabled"
            (click)="create()"
        >
            Create
        </button>
    </mat-card-actions>
</mat-card>
