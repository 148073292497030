<div class="sidebar-page">
    <!------- Edit division ------->
    <app-division-edit-sidenav #divisionsSidebar [isOpened]="isSidebarOpened" class="sidebar-wrapper">
        <div class="sidebar-body">
            <!------- Create division ------->
            <div class="p-3" *ngIf="createMode">
                <org-chart-new
                    (createdGroup)="doCreate($event.newGroup, $event.parent)"
                    [isOpenGroupArea]="createMode"
                    [hideUseInCompensation]="true"
                    [groupList]="groupsList"
                    (hide)="createMode = false; createModeEmitter.emit(createMode)"
                >
                </org-chart-new>
            </div>
            <!------- List of divisions ------->
            <ng-container *ngIf="!createMode">
                <div class="deals-list-area" *ngIf="groupsListFA.controls.length > 0">
                    <ng-container *ngFor="let company_group of groupsListFA.controls">
                        <app-org-charts-list-item-all
                            style="margin-bottom: 4px"
                            [groupFG]="company_group"
                            (click)="openSidenav(company_group)"
                        ></app-org-charts-list-item-all>
                    </ng-container>
                </div>

                <!--<ng-container *ngFor="let company_group of groupsListFA.controls">
                    <app-org-charts-list-item-all style="margin-bottom: 4px"
                                          [groupFG]="company_group"
                                          (click)="openSidenav(company_group)"
                    ></app-org-charts-list-item-all>
                </ng-container>-->
            </ng-container>
        </div>
    </app-division-edit-sidenav>
</div>
