<div *ngIf="zipiFinancialGateway && zipiFinancialGateway.payment_gateway_id">
    <div class="d-flex">
        <span class="sp-spacer"></span>

        <div style="width: 500px; margin-bottom: 5px">
            <div class="d-flex align-items-center">
                <span class="sp-spacer"></span>
                <a
                    class="mr-3"
                    [href]="redirectToDashboard"
                    target="_blank"
                    *ngIf="
                        zipiFinancialGateway.driver_type === 'muhnee' &&
                        redirectToDashboard &&
                        isLinkToMuhneeFeatureFlagEnabled
                    "
                >
                    <button type="button" mat-raised-button style="color: black">Open Muhnee.com</button>
                </a>
                <a
                    [href]="redirectToDashboard"
                    target="_blank"
                    *ngIf="
                        zipiFinancialGateway.driver_type === 'muhnee' &&
                        redirectToDashboard &&
                        !isMuhneeHostedIntegrationFeatureFlagEnabled
                    "
                >
                    <button type="button" mat-raised-button color="primary">Muhnee Dashboard</button>
                </a>
            </div>
        </div>
    </div>
    <mat-card style="border: solid 1px #dcdada">
        <div class="d-flex flex-column">
            <div class="d-flex align-items-baseline">
                <h2 class="d-flex align-items-center">
                    <span *ngIf="['zipi_financial_business'].includes(zipiFinancialGateway.type); else elseBlock"
                        >Operating Account</span
                    >
                    <span *ngIf="['zipi_financial_trust'].includes(zipiFinancialGateway.type); else elseBlock"
                        >Trust Account</span
                    >
                    <span *ngIf="['zipi_financial_card_merchant'].includes(zipiFinancialGateway.type); else elseBlock"
                        >Merchant Account</span
                    >
                    <span *ngIf="['zipi_financial_card_customer'].includes(zipiFinancialGateway.type); else elseBlock"
                        >Card Account</span
                    >
                    <ng-template #elseBlock></ng-template>
                    <mat-icon
                        *ngIf="!!accountBusinessType"
                        matTooltip="{{ accountBusinessType }}"
                        class="ml-1"
                        style="color: rgb(151, 150, 150); vertical-align: middle; font-size: 26px"
                    >
                        info_outline
                    </mat-icon>
                </h2>

                <span class="mx-4" *ngIf="zipiFinancialGateway?.driver_type === 'payload'">Powered by Payload.co</span>
                <span class="mx-4" *ngIf="zipiFinancialGateway?.driver_type === 'muhnee'">Powered by ZilMoney</span>

                <mat-spinner class="ml-2" [diameter]="25" *ngIf="!zipiFinancialGatewayOwnerInfo"></mat-spinner>
                <span class="sp-spacer"></span>
                <div
                    class="mr-2"
                    *ngIf="zipiFinancialGatewayOwnerInfo"
                    [ngStyle]="
                        summaryStatus === 'Application Pending Review' || summaryStatus === 'Active'
                            ? {color: 'green', 'font-size': '16px'}
                            : summaryStatus === 'Internal Review' || summaryStatus === 'Action Required'
                              ? {color: '#FF0000', 'font-size': '16px'}
                              : {color: '#FF0000', 'font-size': '16px'}
                    "
                >
                    {{ summaryStatus }}
                </div>
            </div>
        </div>

        <div *ngIf="zipiFinancialGateway.driver_type === 'moov'">
            <div
                *ngIf="
                    zipiFinancialGatewayOwnerInfo && this.zipiFinancialGatewayOwnerInfo.customer_type === 'individual'
                "
            >
                <div class="d-flex align-items-center">
                    <mat-form-field class="w-30 px-2">
                        <input
                            matInput
                            required
                            type="text"
                            [value]="zipiFinancialGatewayOwnerInfo.name.firstName || ''"
                            [disabled]="true"
                        />
                        <mat-placeholder>First Name</mat-placeholder>
                    </mat-form-field>

                    <mat-form-field class="w-30 px-2">
                        <input
                            matInput
                            required
                            type="text"
                            [value]="zipiFinancialGatewayOwnerInfo.name.lastName || ''"
                            [disabled]="true"
                        />
                        <mat-placeholder>Last Name</mat-placeholder>
                    </mat-form-field>
                    <mat-form-field class="w-30 pl-2">
                        <input
                            matInput
                            required
                            type="text"
                            [value]="zipiFinancialGatewayOwnerInfo.email || ''"
                            [disabled]="true"
                        />
                        <mat-placeholder>Email</mat-placeholder>
                    </mat-form-field>
                </div>
            </div>

            <div *ngIf="zipiFinancialGatewayOwnerInfo && zipiFinancialGatewayOwnerInfo.customer_type === 'business'">
                <div class="d-flex align-items-center">
                    <mat-form-field class="w-30 px-2">
                        <input
                            matInput
                            required
                            type="text"
                            [value]="zipiFinancialGatewayOwnerInfo.legalBusinessName || ''"
                            [disabled]="true"
                        />
                        <mat-placeholder>Legal Business Name</mat-placeholder>
                    </mat-form-field>

                    <mat-form-field
                        class="w-30 px-2"
                        *ngIf="
                            zipiFinancialGateway &&
                            zipiFinancialGateway.settings &&
                            zipiFinancialGateway.settings.business_type_title
                        "
                    >
                        <input
                            matInput
                            required
                            type="text"
                            [value]="zipiFinancialGateway.settings.business_type_title || ''"
                            [disabled]="true"
                        />
                        <mat-placeholder>Type of Entity</mat-placeholder>
                    </mat-form-field>
                    <mat-form-field class="w-30 pl-2" *ngIf="zipiFinancialGatewayOwnerInfo.description">
                        <input
                            matInput
                            required
                            type="text"
                            [value]="zipiFinancialGatewayOwnerInfo.description || ''"
                            [disabled]="true"
                        />
                        <mat-placeholder>Description</mat-placeholder>
                    </mat-form-field>
                    <mat-form-field class="w-30 pl-2" *ngIf="zipiFinancialGatewayOwnerInfo.website">
                        <input
                            matInput
                            required
                            type="text"
                            [value]="zipiFinancialGatewayOwnerInfo.website || ''"
                            [disabled]="true"
                        />
                        <mat-placeholder>Website</mat-placeholder>
                    </mat-form-field>
                </div>
            </div>

            <mat-divider
                *ngIf="
                    !!(
                        zipiFinancialGatewayOwnerInfo &&
                        zipiFinancialGatewayOwnerInfo.errors &&
                        zipiFinancialGatewayOwnerInfo.errors.length > 0
                    )
                "
            ></mat-divider>

            <div class="w-30 px-2 d-flex justify-content-between w-100" style="padding-bottom: 1.25em">
                <div class="my-2">
                    <div
                        class="my-2"
                        style="color: red"
                        *ngIf="
                            !!(
                                zipiFinancialGatewayOwnerInfo &&
                                zipiFinancialGatewayOwnerInfo.errors &&
                                zipiFinancialGatewayOwnerInfo.errors.length > 0
                            )
                        "
                    >
                        <div>The following information could not be verified,</div>
                        <div>please review and make any necessary corrections:</div>
                    </div>
                    <div
                        *ngIf="
                            !!(
                                zipiFinancialGatewayOwnerInfo &&
                                zipiFinancialGatewayOwnerInfo.errors &&
                                zipiFinancialGatewayOwnerInfo.errors.length > 0
                            )
                        "
                    >
                        <div *ngFor="let field of zipiFinancialGatewayOwnerInfo.errors; index as i" style="color: red">
                            {{ i + 1 }}. {{ field | fromSnakeCase }}
                        </div>
                    </div>
                </div>
                <div
                    class="justify-content-end"
                    *ngIf="
                        (zipiFinancialGatewayOwnerInfo && zipiFinancialGateway.status === 'checking') ||
                        !!(
                            zipiFinancialGatewayOwnerInfo &&
                            zipiFinancialGatewayOwnerInfo.errors &&
                            zipiFinancialGatewayOwnerInfo.errors.length > 0
                        )
                    "
                >
                    <!--                <div class="font-weight-bold my-2" *ngIf="!!(zipiFinancialGatewayOwnerInfo && zipiFinancialGatewayOwnerInfo.errors && zipiFinancialGatewayOwnerInfo.errors.length > 0)" style="color: red; text-align: right">Action Required</div>-->
                    <button
                        class="mt-3"
                        mat-raised-button
                        (click)="openRetryDialog()"
                        *ngIf="zipiFinancialGateway.status !== 'inactive'"
                    >
                        Edit Account
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="zipiFinancialGateway.driver_type === 'muhnee' || zipiFinancialGateway.driver_type === 'payload'">
            <div *ngIf="zipiFinancialGatewayOwnerInfo">
                <div class="d-flex align-items-center">
                    <mat-form-field class="w-30 px-2">
                        <input
                            matInput
                            required
                            type="text"
                            [value]="zipiFinancialGatewayOwnerInfo.name || ''"
                            [disabled]="true"
                        />
                        <mat-placeholder>Name</mat-placeholder>
                    </mat-form-field>
                    <mat-form-field class="w-30 pl-2">
                        <input
                            matInput
                            required
                            type="text"
                            [value]="zipiFinancialGatewayOwnerInfo.email || ''"
                            [disabled]="true"
                        />
                        <mat-placeholder>Email</mat-placeholder>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-card>

    <mat-card
        *ngIf="
            zipiFinancialGateway.driver_type === 'moov' &&
            zipiFinancialGatewayOwnerInfo &&
            zipiFinancialGatewayOwnerInfo.customer_type === 'business'
        "
        class="mt-3"
        style="border: solid 1px #dcdada"
    >
        <div class="d-flex align-items-baseline">
            <h2>Representatives</h2>
            <span class="sp-spacer"></span>
            <!--            <button class="ml-2" type="button" mat-raised-button *ngIf="zipiFinancialGateway.status !== 'active' && ['privateCorporation', 'llc', 'partnership'].includes(zipiFinancialGatewayOwnerInfo.businessType)"-->
            <!--                    (click)="confirmRepresentatives()">-->
            <!--                Confirm Representatives-->
            <!--            </button>-->
        </div>

        <div
            *ngIf="
                zipiFinancialGatewayOwnerInfo &&
                (!zipiFinancialGatewayOwnerInfo.representatives ||
                    zipiFinancialGatewayOwnerInfo.representatives.length === 0)
            "
            class="font-weight-bold pl-2"
            style="color: red"
        >
            No Representatives Submitted. Representatives are required for Business Accounts.
        </div>
        <div
            *ngIf="
                zipiFinancialGatewayOwnerInfo &&
                zipiFinancialGatewayOwnerInfo.representatives &&
                zipiFinancialGatewayOwnerInfo.representatives.length > 0
            "
        >
            <div *ngFor="let representative of zipiFinancialGatewayOwnerInfo.representatives" style="">
                <mat-divider></mat-divider>
                <div class="my-3 pt-3">
                    <div class="d-flex align-items-baseline" style="padding: 15px 0 15px 15px">
                        <div>
                            <div class="d-flex align-items-center">
                                <div style="font-size: 27px; color: gray; font-weight: bold" class="mr-3">
                                    <span class=""
                                        >{{ representative.name.firstName }} {{ representative.name.lastName }}</span
                                    >
                                </div>
                                <div
                                    *ngIf="representative.responsibilities"
                                    style="font-size: 16px; color: gray"
                                    class="mr-3"
                                >
                                    (<span
                                        *ngIf="
                                            representative.responsibilities.isOwner &&
                                            !representative.responsibilities.isController
                                        "
                                        >Owner</span
                                    >
                                    <span
                                        *ngIf="
                                            representative.responsibilities.isOwner &&
                                            representative.responsibilities.isController
                                        "
                                        >Owner,
                                    </span>
                                    <span *ngIf="representative.responsibilities.isController">Controller</span>)
                                </div>
                                <div *ngIf="representative.email" style="font-size: 16px; color: gray" class="mr-3">
                                    <span class="">{{ representative.email }}</span>
                                </div>
                                <button
                                    mat-icon-button
                                    color="primary"
                                    class="mr-1"
                                    (click)="deleteRepresentative(representative)"
                                    [matTooltip]="'Delete Representative'"
                                    *ngIf="
                                        zipiFinancialGateway.status === 'checking' ||
                                        !!(representative && representative.errors && representative.errors.length > 0)
                                    "
                                >
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                            <div
                                *ngIf="!!(representative && representative.errors && representative.errors.length > 0)"
                            >
                                <div class="my-2" style="color: red">
                                    <div>The verification process encountered an error with the below information.</div>
                                    <div>Please review and make corrections as necessary then resubmit.</div>
                                </div>
                                <div *ngFor="let field of representative.errors; index as i" style="color: red">
                                    {{ i + 1 }}. {{ field | fromSnakeCase }}
                                </div>
                            </div>
                        </div>

                        <span class="sp-spacer"></span>
                        <div>
                            <!--                            <div class="font-weight-bold my-2 d-flex align-items-center justify-content-end" style="color: red; text-align: right" *ngIf="!!(representative && representative.errors && representative.errors.length > 0)">Action Required</div>-->
                            <div
                                class="justify-content-end"
                                *ngIf="
                                    zipiFinancialGateway.status === 'checking' ||
                                    !!(representative && representative.errors && representative.errors.length > 0)
                                "
                            >
                                <button
                                    mat-raised-button
                                    (click)="openRetryRepresentativeDialog(representative)"
                                    *ngIf="zipiFinancialGateway.status !== 'inactive'"
                                >
                                    Edit Representative
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
        </div>
        <div class="d-flex pt-3">
            <span class="sp-spacer"></span>
            <button
                class="ml-2"
                type="button"
                mat-raised-button
                *ngIf="zipiFinancialGateway.status !== 'active' && zipiFinancialGateway.status !== 'inactive'"
                (click)="addRepresentative()"
            >
                Add Representative(s)
            </button>
        </div>
    </mat-card>

    <mat-card
        class="mt-3"
        style="border: solid 1px #dcdada"
        *ngIf="
            zipiFinancialGateway.driver_type === 'payload' && zipiFinancialGateway.type === 'zipi_financial_business'
        "
    >
        <div style="position: relative">
            <h2>Transfers Settings</h2>
            <!--            <div style="position: absolute; right: 0; top: 0">-->
            <!--                <a href="javascript:" (click)="openFee()">Fee Schedule</a>-->
            <!--            </div>-->

            <div
                *ngIf="
                    zipiFinancialGateway.driver_type === 'payload' &&
                    zipiFinancialGatewayOwnerInfo &&
                    zipiFinancialGatewayOwnerInfo.payload_co_payment_methods &&
                    zipiFinancialGatewayOwnerInfo.payload_co_payment_methods.length > 0 &&
                    defaultPayloadCoMethodControl
                "
            >
                <div class="d-flex align-items-center">
                    <mat-form-field class="w-50 px-2">
                        <mat-select
                            [formControl]="defaultPayloadCoMethodControl"
                            (valueChange)="saveDefaultPayloadCoMethod($event)"
                            placeholder="Payload.co Auto-charged Fee Account"
                        >
                            <mat-select-trigger
                                *ngIf="payloadCoSelectedMethod && !payloadCoSelectedMethod.is_connected"
                                class="not-connected-account"
                            >
                                <div class="d-flex align-items-center justify-content-between">
                                    <div>
                                        {{ payloadCoSelectedMethod.method_title }}
                                    </div>
                                    <div>
                                        <mat-icon [matTooltip]="'Exists only in Payload.co, but not in SkySlope Books'"
                                            >warning</mat-icon
                                        >
                                    </div>
                                </div>
                            </mat-select-trigger>
                            <mat-select-trigger *ngIf="payloadCoSelectedMethod?.is_connected">
                                <div class="d-flex align-items-center">
                                    <span>{{ payloadCoSelectedMethod.method_title }}</span>
                                </div>
                            </mat-select-trigger>
                            <ng-container
                                *ngFor="let method of zipiFinancialGatewayOwnerInfo.payload_co_payment_methods"
                            >
                                <mat-option [value]="method.method_id">
                                    <div class="d-flex justify-content-between">
                                        <div [ngClass]="!method.is_connected ? 'not-connected-account' : ''">
                                            {{ method.method_title }}
                                        </div>
                                        <div>
                                            <mat-icon
                                                *ngIf="!method.is_connected"
                                                class="not-connected-account"
                                                [matTooltip]="'Exists only in Payload.co, but not in SkySlope Books'"
                                                >warning</mat-icon
                                            >
                                        </div>
                                    </div>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-progress-spinner
                        diameter="20"
                        mode="indeterminate"
                        *ngIf="defaultMethodSaving"
                    ></mat-progress-spinner>
                </div>
            </div>

            <div
                [formGroup]="feePayerForm"
                *ngIf="zipiFinancialGateway.driver_type === 'payload' && isFeeOverridesLoaded && feePayerForm"
            >
                <!--                <h4>Fee allocation during regular incoming payments (for Invoices)</h4>-->
                <div class="d-flex align-items-center">
                    <mat-form-field class="w-50 px-2">
                        <mat-select
                            formControlName="default_bank_fee_payer"
                            (valueChange)="saveFeePayer($event, 'bank')"
                            placeholder="Default Bank Convenience Fee Allocation (Invoices)"
                        >
                            <mat-option [value]="'money_sender'">Money Sender</mat-option>
                            <mat-option [value]="'money_receiver'">Money Receiver</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-progress-spinner
                        diameter="20"
                        mode="indeterminate"
                        *ngIf="bankFeePayerSaving"
                    ></mat-progress-spinner>
                </div>
                <div class="d-flex align-items-center">
                    <mat-form-field class="w-50 px-2">
                        <mat-select
                            formControlName="default_card_fee_payer"
                            (valueChange)="saveFeePayer($event, 'card')"
                            placeholder="Default Credit Card Convenience Fee Allocation (Invoices)"
                        >
                            <mat-option [value]="'money_sender'">Money Sender</mat-option>
                            <mat-option [value]="'money_receiver'">Money Receiver</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-progress-spinner
                        diameter="20"
                        mode="indeterminate"
                        *ngIf="cardFeePayerSaving"
                    ></mat-progress-spinner>
                </div>
                <!--                <button class="ml-2 mr-2" type="button" color="primary" mat-raised-button-->
                <!--                        [disabled]="feePayerForm.untouched || feePayerSaving"-->
                <!--                        (click)="saveFeePayer()">-->
                <!--                    <div class="d-flex align-items-center">-->
                <!--                        <div>Save Transfer Settings</div>-->
                <!--                        <div class="ml-2">-->
                <!--                            <mat-progress-spinner diameter="20" mode="indeterminate" *ngIf="feePayerSaving"></mat-progress-spinner>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </button>-->
            </div>

            <div class="d-flex">
                <div
                    *ngIf="
                        isVelocityFeatureFlagEnabled &&
                        ['zipi_financial_business'].includes(zipiFinancialGateway.type) &&
                        senderTransferPreference &&
                        receivingTransferPreference
                    "
                >
                    <div *ngIf="sendingTransferPreferenceForm" [formGroup]="sendingTransferPreferenceForm">
                        <div class="d-flex mr-2 align-items-center">
                            <div class="d-flex mr-3 align-items-center" style="min-height: 66px">
                                <!--                                <div class="d-flex mr-4" style="margin-top: 22px">-->
                                <div class="mr-2" style="min-width: 75px">Sending Money:</div>
                                <!--                            <div class="mr-3" style="min-width: 75px">Fee Payer</div>-->
                                <!--                                    <div class="mr-1">Receiver</div>-->
                                <!--                                    <mat-slide-toggle [checked]="sendingTransferPreferenceForm.controls.velocity && sendingTransferPreferenceForm.controls.velocity.value !== 'no_fee'"-->
                                <!--                                                      (change)="commissionPayerChanged($event, sendingTransferPreferenceForm)">-->
                                <!--                                        Me-->
                                <!--                                    </mat-slide-toggle>-->
                                <!--                                </div>-->
                                <div *ngIf="sendingTransferPreferenceForm.controls.velocity">
                                    <mat-form-field class="w-100">
                                        <mat-select
                                            [placeholder]="'Preferred Service Level'"
                                            formControlName="velocity"
                                        >
                                            <ng-container *ngFor="let type of senderVelocityTypes">
                                                <mat-option [value]="type">
                                                    {{ type | fromSnakeCase }}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div
                                class="ml-2"
                                *ngIf="zipiFinancialGateway && zipiFinancialGateway.driver_type !== 'muhnee'"
                            >
                                <mat-checkbox style="margin-top: 10px" formControlName="is_downgrade_restricted"
                                    >Restrict auto-downgrade when sending</mat-checkbox
                                >
                            </div>
                            <button
                                class="ml-2 mr-2"
                                type="button"
                                mat-raised-button
                                *ngIf="
                                    zipiFinancialGateway &&
                                    zipiFinancialGateway.status !== 'inactive' &&
                                    sendingTransferPreferenceForm &&
                                    senderTransferPreference &&
                                    (sendingTransferPreferenceForm.controls.velocity?.value !==
                                        senderTransferPreference.velocity ||
                                        sendingTransferPreferenceForm.controls.is_downgrade_restricted?.value !==
                                            senderTransferPreference.restrict_auto_downgrade)
                                "
                                (click)="saveDefaultVelocity('when_sending')"
                            >
                                Save
                            </button>
                        </div>
                    </div>

                    <div *ngIf="receivingTransferPreferenceForm" [formGroup]="receivingTransferPreferenceForm">
                        <div class="d-flex mr-2 align-items-center">
                            <div class="mr-2">Receiving Money from other SkySlope Books Users:</div>
                            <mat-form-field class="mr-2">
                                <mat-select [placeholder]="'Preferred Service Level'" formControlName="velocity">
                                    <mat-option *ngFor="let type of receiverVelocityTypes" [value]="type">
                                        {{ type | fromSnakeCase }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-icon class="mr-2" matTooltip="Default Receiving Velocity for this Gateway"
                                >info</mat-icon
                            >
                            <div class="ml-2" *ngIf="zipiFinancialGateway.driver_type !== 'muhnee'">
                                <mat-checkbox style="margin-top: 10px" formControlName="is_downgrade_restricted"
                                    >Restrict auto-downgrade when receiving</mat-checkbox
                                >
                            </div>
                            <button
                                class="ml-2 mr-2"
                                type="button"
                                mat-raised-button
                                *ngIf="
                                    zipiFinancialGateway.status !== 'inactive' &&
                                    receivingTransferPreferenceForm &&
                                    receivingTransferPreference &&
                                    (receivingTransferPreferenceForm.controls.velocity?.value !==
                                        receivingTransferPreference.velocity ||
                                        receivingTransferPreferenceForm.controls.is_downgrade_restricted?.value !==
                                            receivingTransferPreference.restrict_auto_downgrade)
                                "
                                (click)="saveDefaultVelocity('when_receiving')"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="
                        isVelocityFeatureFlagEnabled &&
                        ['zipi_financial_trust'].includes(zipiFinancialGateway.type) &&
                        senderTransferPreference &&
                        receivingTransferPreference
                    "
                >
                    <div *ngIf="sendingTransferPreferenceForm" [formGroup]="sendingTransferPreferenceForm" class="mb-1">
                        <div class="d-flex" style="min-height: 36px">
                            <div style="padding-top: 6px">
                                <span class="mr-3">Commission Payer (Sending)</span>
                                <span class="mr-1">Receiver</span>
                                <mat-slide-toggle formControlName="force_me_as_trust_fee_payer">Me</mat-slide-toggle>
                            </div>
                            <button
                                class="ml-2 mr-2"
                                type="button"
                                mat-raised-button
                                *ngIf="
                                    zipiFinancialGateway.status !== 'inactive' &&
                                    sendingTransferPreferenceForm &&
                                    senderTransferPreference &&
                                    sendingTransferPreferenceForm.controls.force_me_as_trust_fee_payer?.value !==
                                        senderTransferPreference.force_me_as_trust_fee_payer
                                "
                                (click)="saveDefaultVelocity('when_sending')"
                            >
                                Save
                            </button>
                        </div>
                    </div>

                    <div
                        *ngIf="receivingTransferPreferenceForm"
                        [formGroup]="receivingTransferPreferenceForm"
                        class="mb-1"
                    >
                        <div class="d-flex" style="min-height: 36px">
                            <div style="padding-top: 6px">
                                <span class="mr-3">Commission Payer (Receiving)</span>
                                <span class="mr-1">Sender</span>
                                <mat-slide-toggle formControlName="force_me_as_trust_fee_payer">Me</mat-slide-toggle>
                            </div>
                            <button
                                class="ml-2 mr-2"
                                type="button"
                                mat-raised-button
                                *ngIf="
                                    zipiFinancialGateway.status !== 'inactive' &&
                                    receivingTransferPreferenceForm &&
                                    receivingTransferPreference &&
                                    receivingTransferPreferenceForm.controls.force_me_as_trust_fee_payer?.value !==
                                        receivingTransferPreference.force_me_as_trust_fee_payer
                                "
                                (click)="saveDefaultVelocity('when_receiving')"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
</div>
