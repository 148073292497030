<div mat-dialog-title>
    <h3>Add profiles to selected {{ addToDivision ? 'division' : 'group' }}</h3>
</div>

<mat-dialog-content>
    <app-company-compensation-combined-picker
        [title]="''"
        [only_compensation_groups]="false"
        [without_company_group]="true"
        [availableTypes]="['company_group']"
        [nodesFA]="selectedGroupsFA"
        [onlyDivisions]="addToDivision"
        [onlyCommonGroups]="!addToDivision"
        [singleSelected]="true"
    ></app-company-compensation-combined-picker>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex w-100 my-3">
        <span class="sp-spacer"></span>
        <button mat-raised-button class="mr-2" (click)="dialogRef.close()" tabindex="-1">Cancel</button>
        <button mat-raised-button color="primary" (click)="dialogRef.close(groupId)" tabindex="1" [disabled]="!groupId">
            Add to {{ addToDivision ? 'division' : 'group' }}
        </button>
    </div>
</mat-dialog-actions>
