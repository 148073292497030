import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {routing} from './deals.routing';
import {RbacModule} from '../rbac/rbac.module';
import {DealModule} from './components/deal/deal.module';

@NgModule({
    declarations: [],
    imports: [DealModule, RbacModule, routing],
    exports: [],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DealsModule {}
