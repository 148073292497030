import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {IPaymentReceived, IPaymentReceivedMadeRequestObject} from '@cyberco-nodejs/zipi-typings';
import {PaymentsReceivedService} from '../../../services/payments-received.service';
import {AbstractControl, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {GenericFormArray} from '../../../../../entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {PAYMENT_MODES} from '../../../../../local-typings';
import {FormGroupArray, FormGroupWithFormControls} from '../../../../../typings/common';
import {NotificationsService} from 'angular2-notifications';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';
import {formatToDate} from '../../../../../utilities';

@Component({
    selector: 'app-payment-received-refund',
    templateUrl: './payment-received-refund.component.html',
    styleUrls: ['./payment-received-refund.component.scss']
})
export class PaymentReceivedRefundComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    basePaymentReceivedId: number | null = null;
    basePaymentReceived: IPaymentReceived | null = null;

    invoicesArray: FormGroupArray;
    formGroup: FormGroupWithFormControls = this.fb.group({
        money_sender__contact_fk_id: [null, [Validators.required]],
        paid_date: [new Date(), Validators.required],
        pay_to__ledger_account_fk_id: [null, []],
        amount: [null, [Validators.required]],
        refund_amount: [0, [Validators.required, this.refundAmountValidator()]],
        sales_person__profile_fk_id: [null],

        payment_mode: [null, [Validators.required]],
        payment_number: [null, []],
        notes: [null, []],
        reference: [null, []]
    }) as FormGroupWithFormControls;

    savedContacts: Array<number> = [];
    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    currencyMaskitoMask = currencyMaskitoOptions;
    paymentModes = PAYMENT_MODES;

    totalForRefund: number = 0;

    constructor(
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private paymentsReceivedService: PaymentsReceivedService,
        public router: Router,
        private ntfs: NotificationsService
    ) {
        this.invoicesArray = this.fb.array([]) as FormGroupArray;
    }

    ngOnInit() {
        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            const id = params['id'];
            if (id) {
                this.basePaymentReceivedId = Number(id);
                this.initPaymentForm();
            }
        });
    }

    initPaymentForm() {
        if (!this.basePaymentReceivedId) {
            return;
        }
        this.paymentsReceivedService
            .getPaymentReceivedById(this.basePaymentReceivedId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((payment: IPaymentReceived) => {
                this.basePaymentReceived = payment;

                if (payment.related_payments && payment.related_payments.length !== 0) {
                    for (const rp of payment.related_payments) {
                        if (rp.invoice) {
                            const arrItem = this.fb.group({
                                invoice_id: rp.invoice.invoice_id,
                                payment_id: rp.payment_id,
                                invoice_number: rp.invoice.invoice_number,
                                due_date: rp.invoice.due_date,
                                payment: [rp.amount - rp.refunded_amount, []],
                                balance: rp.invoice.pending_balance,
                                refund_amount: [rp.amount - rp.refunded_amount, [this.refundPValidator()]]
                            });

                            arrItem.get('payment')!.disable();
                            arrItem.get('balance')!.disable();
                            this.invoicesArray.push(arrItem);
                        }
                    }
                }

                this.formGroup.patchValue({
                    pay_to__ledger_account_fk_id: payment.pay_to__ledger_account_fk_id,
                    money_receiver__contact_fk_id: null,
                    money_sender__contact_fk_id: payment.money_sender__contact_fk_id,
                    amount: payment.amount,
                    payment_mode: payment.payment_mode,
                    payment_number: payment.payment_received_number,
                    reference: payment.reference,
                    notes: payment.notes,
                    refund_amount: payment.over_payment
                });

                this.formGroup.get('money_sender__contact_fk_id')!.disable();
                this.formGroup.get('reference')!.disable();
                this.formGroup.get('payment_number')!.disable();
                this.formGroup.get('paid_date')!.disable();
                this.formGroup.get('amount')!.disable();
                this.formGroup.get('notes')!.disable();
                this.formGroup.get('money_sender__contact_fk_id')!.disable();
                this.formGroup.get('money_sender__contact_fk_id')!.disable();
                this.formGroup.get('money_sender__contact_fk_id')!.disable();

                if (payment.money_sender__contact_fk_id) {
                    this.savedContacts = [payment.money_sender__contact_fk_id];
                }
            });
    }

    refundPValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.parent) {
                return null;
            }

            const parent = control.parent.getRawValue();
            const {payment} = parent;
            const value = Number(cleanCurrencyString(control.value));

            const isValid = value <= payment;

            if (isValid) {
                this.calculateTotal();
            }
            return isValid ? null : {refund_amount: {value: control.value}};
        };
    }

    refundAmountValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.parent) {
                return null;
            }
            let availableForRefund = 0;
            if (this.basePaymentReceived) {
                availableForRefund = this.basePaymentReceived.over_payment;
            }

            const value = Number(cleanCurrencyString(control.value));

            const isValid = value <= availableForRefund;
            if (isValid) {
                this.calculateTotal();
            }
            return isValid ? null : {refund_amount: {value: control.value}};
        };
    }

    calculateTotal() {
        const refundAmountControl = this.formGroup.get('refund_amount');
        if (!refundAmountControl) {
            return;
        }
        const excessRefund = Number(cleanCurrencyString(refundAmountControl.value));
        let paymentsRefund = 0;
        const invoicesArray = this.invoicesArray.getRawValue();
        invoicesArray.map((invoice) => {
            paymentsRefund += Number(cleanCurrencyString(invoice.refund_amount));
        });
        this.totalForRefund = excessRefund + paymentsRefund;
    }

    showPaymentMode() {
        if (this.basePaymentReceived && this.basePaymentReceivedId) {
            let paymentModeTitle = '';
            for (const mode of this.paymentModes) {
                if (mode.slug === this.basePaymentReceived.payment_mode) {
                    paymentModeTitle = mode.title;
                }
            }
            return paymentModeTitle;
        }
    }

    fullRefund(itemGroup: AbstractControl) {
        const paymentControl = itemGroup.get('payment');
        if (!paymentControl) {
            return;
        }
        const amount = cleanCurrencyString(paymentControl.value);

        itemGroup.get('refund_amount')!.patchValue(amount);
    }

    create() {
        if (!this.basePaymentReceivedId) {
            return;
        }
        if (this.formGroup.invalid || this.invoicesArray.invalid) {
            this.formGroup.markAllAsTouched();
            this.invoicesArray.markAllAsTouched();
            this.ntfs.warn('Information could not be saved. Please resolve the highlighted errors.');
            return;
        }

        const dataRaw = this.formGroup.getRawValue();
        const invoicesRaw = this.invoicesArray
            .getRawValue()
            .map((invoice) => {
                return {
                    entity_id: invoice.invoice_id as number,
                    payment_id: invoice.payment_id as number,
                    amount: Number(cleanCurrencyString(invoice.refund_amount)),
                    entity: null,
                    entity_transfer_id: null
                };
            })
            .filter((item) => item.amount > 0);

        let fullRefundAmount = Number(cleanCurrencyString(dataRaw.refund_amount));
        invoicesRaw.forEach((item) => (fullRefundAmount += item.amount));

        const requestObj: IPaymentReceivedMadeRequestObject = {
            money_sender__contact_fk_id: this.basePaymentReceived
                ? this.basePaymentReceived.money_sender__contact_fk_id
                : null,
            money_receiver__contact_fk_id: null,
            paid_date: formatToDate(new Date()),
            scheduled_date: null,
            amount: fullRefundAmount,
            pay_to__ledger_account_fk_id: this.basePaymentReceived
                ? this.basePaymentReceived.paid_by__ledger_account_fk_id
                : null,
            paid_by__ledger_account_fk_id: this.basePaymentReceived
                ? this.basePaymentReceived.pay_to__ledger_account_fk_id
                : null,
            pay_to__payment_method_fk_id: this.basePaymentReceived
                ? this.basePaymentReceived.paid_by__payment_method_fk_id
                : null,
            paid_by__payment_method_fk_id: this.basePaymentReceived
                ? this.basePaymentReceived.pay_to__payment_method_fk_id
                : null,
            payments: invoicesRaw,
            is_create_multiple_payments: true,
            payment_mode: this.basePaymentReceived ? this.basePaymentReceived.payment_mode : null,
            payment_number: null,
            reference: dataRaw.reference,
            notes: dataRaw.notes,

            matched__transaction_external_id: null,
            check_info: null,
            deposit_release_id: null,
            is_locked_for_applying: false,
            allow_auto_apply: false,
            source__deal_fk_id: null,
            sender_velocity: null,
            restrict_downgrade: false
        };

        this.paymentsReceivedService
            .refundPayment(this.basePaymentReceivedId, requestObj)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                if (res) {
                    this.router.navigate(['/sales/paymentsreceived/']);
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
