import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../auth.service';
import {RbacService} from 'app/modules/rbac/rbac.service';
import {environment} from '../../../environments/environment';
import {SessionService} from '../session.service';
import {SkyslopeAuth} from 'app/modules/auth/services/okta/skyslope-auth.service';
import {UserContext} from '@skyslope/auth-js';

@Injectable()
export class DefaultPageResolverService implements Resolve<{}> {
    constructor(
        @Inject(AuthService) protected authService: AuthService,
        @Inject(RbacService) protected rbacService: RbacService,
        @Inject(SessionService) protected sessionService: SessionService,
        protected skyslopeAuth: SkyslopeAuth,
        protected router: Router,
        protected skyslopeAuthService: SkyslopeAuth
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.load(route, state);
    }

    // check if user plan is 'zipi_collaborator_customer'
    // navigate to /purchases/bills
    // else - navigate to /insights
    async load(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
        if (urlParams.has('sso_redirect_url')) {
            const ssoRedirectUrl = String(urlParams.get('sso_redirect_url'));
            this.authService.setRedirectUrl(ssoRedirectUrl);
        }

        await this.skyslopeAuthService.getUser().then(async (user) => {
            if (user && user.impersonatingUser) {
                this.authService.getCurrentOktaUser().then(async (oktaUser) => {
                    // If impersonation context has changed, then update auth.
                    if ((oktaUser as UserContext).id != user.id) {
                        this.authService.setLoginAsOktaUid(user.id);
                        await this.authService.loadAuth();
                    }
                });
            }
        });

        let isSystemUnderMaintenance = false;

        const isUserLoaded = await this.authService.loadAuth().catch((err) => {
            console.error(err);
            if (err === '503_Error') {
                isSystemUnderMaintenance = true;
            }
        });

        if (!isUserLoaded) {
            this.authService.logout().then(() => {
                if (environment.env === 'production') {
                    window.location.replace(environment.marketingPageLogin);
                } else {
                    window.location.replace(`${window.location.origin}${environment.unauthorizedUserRedirectPath}`);
                }
            });
        }

        const currentProfile = await this.authService.getCurrentProfile();
        if (!currentProfile) {
            throw new Error('Can`t define what profile to use');
        }

        if (urlParams.has('sso_redirect_url')) {
            window.location.href = this.authService.redirectToAfterSSO;
            return;
        }

        // if global
        if (currentProfile.type === 'global') {
            await this.router.navigate(['/deals']);
            return;
        }

        const subscriptions = currentProfile.company && currentProfile.company.zoho_subscription;
        if (subscriptions && subscriptions.length) {
            if (subscriptions.length === 1 && subscriptions[0].plan === 'zipi_collaborator_customer') {
                // for customer - navigate to /purchases/sourcedocuments
                await this.router.navigate(['/purchases/sourcedocuments']);
            } else {
                // for others - need find the first module where user have access to and navigate there.
                // To do this we call rbac service and getting map "path -> isAllowed"
                const rbacState = await this.rbacService.isAllowedFirst([
                    {path: 'deals', rules: [{deals__access: true}]},
                    {path: 'sales/invoices', rules: [{sales__access: true}]},
                    {path: 'purchases/bills', rules: [{purchases__access: true}]},
                    {path: 'insights', rules: [{dashboards__access: true}, {reports__view_dashboard: true}]},
                    {path: 'banking', rules: [{banking__access: true}]},
                    {path: 'reports', rules: [{reports__access: true}]},
                    {path: 'company/settings/general', rules: [{company_settings__access: true}]},
                    {path: 'marketplace', rules: [{marketplace__access: true}]},
                    {path: 'contacts', rules: [{contacts__access: true}]}
                ]);

                const firstAllowedRoute = rbacState.find((el) => el.isAllowed);
                if (!firstAllowedRoute) {
                    await this.router.navigate(['accessdenied']);
                    return false;
                }

                // need to define the first allowed route
                if (firstAllowedRoute.path === 'sales/invoices') {
                    if (await this.rbacService.isAllowed({sales__view_invoices: true})) {
                        await this.router.navigate(['/sales/invoices']);
                    } else if (await this.rbacService.isAllowed({sales__view_recurring_invoices: true})) {
                        await this.router.navigate(['/sales/invoices/recurring']);
                    } else if (await this.rbacService.isAllowed({sales__view_source_documents: true})) {
                        await this.router.navigate(['/sales/sourcedocuments']);
                    }
                } else if (firstAllowedRoute.path === 'purchases/bills') {
                    if (await this.rbacService.isAllowed({purchases__view_bills: true})) {
                        await this.router.navigate(['/purchases/bills']);
                    } else if (await this.rbacService.isAllowed({purchases__view_recurring_bills: true})) {
                        await this.router.navigate(['/purchases/bills/recurring']);
                    } else if (await this.rbacService.isAllowed({purchases__view_source_documents: true})) {
                        await this.router.navigate(['/purchases/sourcedocuments']);
                    }
                } else {
                    await this.router.navigate(firstAllowedRoute.path.split('/'));
                }
            }
        } else {
            throw new Error('No subscriptions');
        }

        return;
    }
}
