import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';

export class CustomFormGroup extends UntypedFormGroup {
    public controls: {
        start_date: UntypedFormControl;
        end_date: UntypedFormControl;
        basis_type: UntypedFormControl;
    };

    constructor(controls: {
        start_date: UntypedFormControl;
        end_date: UntypedFormControl;
        basis_type: UntypedFormControl;
    }) {
        super(controls);
        this.controls = controls;
    }
}

@Component({
    selector: 'app-export-data-form-dialog',
    styles: [
        `
            .container {
                display: flex;
                justify-content: space-between;
                justify-items: center;
                flex-direction: column;

                width: 340px;
            }
        `
    ],
    template: `
        <div mat-dialog-title>
            <h3>Export Journal Entries</h3>
        </div>

        <mat-dialog-content>
            <div class="container" [formGroup]="formGroup">
                <div class="d-flex align-content-center" style="margin-bottom: 20px;">
                    <label class="mr-2">Type:</label>
                    <mat-radio-group formControlName="basis_type">
                        <mat-radio-button value="accrual">Accrual Basis</mat-radio-button>
                        <mat-radio-button [disabled]="true" value="cash">Cash Basis</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="d-flex">
                    <div class="w-45" style="padding-right: 10px;">
                        <app-date-picker
                            [placeholder]="'Start Date'"
                            [dateControl]="formGroup.get('start_date')"
                        ></app-date-picker>
                    </div>
                    <div class="w-45" style="padding-right: 10px;">
                        <app-date-picker
                            [placeholder]="'End Date'"
                            [dateControl]="formGroup.get('end_date')"
                        ></app-date-picker>
                    </div>
                </div>
                <div style="font-size: 0.8em;">
                    <strong>Note:</strong> Start Date prefilled based on last successful export. End Date prefilled with
                    yesterday.
                </div>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions>
            <div class="d-flex w-100 my-3">
                <span class="sp-spacer"></span>
                <button mat-raised-button class="mat-primary" (click)="save()">Submit</button>
                <button mat-raised-button class="mr-2" (click)="dialogRef.close()" tabindex="-1">Cancel</button>
            </div>
        </mat-dialog-actions>
    `
})
export class ExportDataFormDialogComponent implements OnInit {
    public automaticStartingDate: string = '';
    public formGroup: CustomFormGroup = new CustomFormGroup({
        start_date: new UntypedFormControl(null, [Validators.required]),
        end_date: new UntypedFormControl(Number(moment().subtract(1, 'days').format('YYYYMMDD')), [
            Validators.required
        ]),
        basis_type: new UntypedFormControl('accrual', [Validators.required])
    });

    constructor(
        public dialogRef: MatDialogRef<ExportDataFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        if (this.data?.latest_accrual_basis_state?.end_date) {
            this.formGroup.controls.start_date.patchValue(Number(this.data.latest_accrual_basis_state.end_date));
        }
    }

    save() {
        if (!this.formGroup.valid) {
            return;
        }
        this.dialogRef.close(this.formGroup.getRawValue());
    }
}
