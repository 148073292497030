import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {QuickBooksService} from '../../../../../services/api/addon/quickbooks/quickbooks.service';
import {QbCompanySettingsModel} from '../../../../../models/addon/qb-company-settings-model';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
    selector: 'app-qb-integration-settings',
    templateUrl: 'qb-integration-settings.component.html',
    styleUrls: ['./qb-integration-settings.component.scss']
})
export class QbIntegrationSettingsComponent implements OnInit, OnChanges, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() companySettings: QbCompanySettingsModel | null = null;
    @Output() reloadCompanySettings: EventEmitter<boolean> = new EventEmitter<boolean>();

    formGroup: UntypedFormGroup | null = null;

    constructor(
        private quickBooksService: QuickBooksService,
        private fb: UntypedFormBuilder
    ) {
        this.formGroup = this.fb.group({
            invoice: [false, []],
            bill: [false, []]
        });
    }

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('companySettings') && changes.companySettings.currentValue) {
            this.setupForm(changes.companySettings.currentValue);
        }
    }

    setupForm(currentSettings: QbCompanySettingsModel) {
        if (this.formGroup) {
            this.formGroup.controls.invoice.setValue(currentSettings.auto_sync_invoices_enabled);
            this.formGroup.controls.bill.setValue(currentSettings.auto_sync_bills_enabled);
            this.formGroup.updateValueAndValidity();
        }
    }

    saveSettings() {
        const settingsObj = {
            auto_sync_invoices_enabled: this.formGroup?.get('invoice')?.value,
            auto_sync_bills_enabled: this.formGroup?.get('bill')?.value
        };
        if (this.companySettings && this.companySettings?.qb_company_settings_id) {
            this.quickBooksService
                .updateAutoSyncSettings(this.companySettings.qb_company_settings_id, settingsObj)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    this.reloadCompanySettings.emit(true);
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
