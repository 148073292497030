import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {UserSetupFormGroup} from '../user-goals/user-goals.component';
import {PendingRequestsService} from '../../../services/pending-requests.service';
import {InitialSetupService} from '../../auth/welcome/initial-setup.service';
import {ProgressService} from '../progress.service';

@Component({
    selector: 'app-profile-goals',
    template: `
        <div class="p-4">
            <app-user-goals [formGroup]="userGoalsForm"></app-user-goals>
            <div class="text-right">
                <button mat-button class="mat-primary" (click)="closeSidebar.emit()">Cancel</button>
                <button mat-raised-button class="mat-primary" [disabled]="saveDisabled" (click)="saveUserSetup()">
                    Save
                </button>
            </div>
        </div>
    `
})
export class ProfileGoalsComponent implements OnDestroy {
    @Output() closeSidebar = new EventEmitter<void>();

    public userGoalsForm: UserSetupFormGroup = new UserSetupFormGroup();

    public saveDisabled: boolean = false;

    constructor(
        protected initialSetupService: InitialSetupService,
        protected pendingRequestsService: PendingRequestsService,
        protected progressService: ProgressService
    ) {
        this.loadUserSetup();
    }

    loadUserSetup() {
        this.pendingRequestsService.hasPendingRequest = true;
        return this.initialSetupService.api.getUserSetup().then((value) => {
            this.pendingRequestsService.hasPendingRequest = false;
            this.userGoalsForm.patchValue(value);
        });
    }

    saveUserSetup() {
        if (!this.saveDisabled) {
            this.saveDisabled = true;

            this.initialSetupService.api
                .postUserSetup(this.userGoalsForm.getRawValue())
                .then((ok) => {
                    this.saveDisabled = false;

                    this.loadUserSetup();
                    this.progressService.api.load();
                    this.closeSidebar.emit();
                })
                .catch(() => {
                    this.saveDisabled = false;
                });
        }
    }

    ngOnDestroy() {
        this.closeSidebar.complete();
    }
}
