import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {map} from 'rxjs/operators';
import {UnsavedChangesComponent} from '../../layouts/unsaved-changes/unsaved-changes.component';
import * as _ from 'lodash-es';
import {UnsavedChangesNewDialogComponent} from '../../layouts/unsaved-changes-new-dialog/unsaved-changes-new-dialog.component';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
    saveChanges?: () => void;
}

@Injectable({
    providedIn: 'root'
})
export class PendingChangesGuardService implements CanDeactivate<ComponentCanDeactivate> {
    previousDestinationRouteArray: string[] = [];
    previousUserAnswer: string = '';

    constructor(public dialog: MatDialog) {}

    canDeactivate(
        component: ComponentCanDeactivate,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): boolean | Observable<boolean> {
        if (component.canDeactivate()) {
            return true;
        }

        // This code is for detecting that the system redirects user from one path to child/ to prevent double showing UnsavedChangesDialog
        // For example user clicks link to /company/settings and the system redirects him to /company/settings/general
        // TODO: need to find better way to do this.
        const currentDestinationRoute = nextState!.url.trim().split('/');
        const currentDestinationRouteWithoutLastPath = currentDestinationRoute.slice(0);
        currentDestinationRouteWithoutLastPath.pop();
        if (currentDestinationRoute.length && this.previousDestinationRouteArray.length) {
            if (_.isEqual(this.previousDestinationRouteArray.sort(), currentDestinationRouteWithoutLastPath.sort())) {
                switch (this.previousUserAnswer) {
                    case 'save':
                    case 'discard':
                        return true;
                    case 'return':
                        return false;
                }
            }
        }
        this.previousDestinationRouteArray = currentDestinationRoute;

        if (currentState.url === '/marketplace/setup/moneytransfers') {
            const confirmDialogRef = this.dialog.open(UnsavedChangesNewDialogComponent, {
                minWidth: 320,
                minHeight: 160,
                disableClose: true,
                data: {
                    title: 'Are you sure?',
                    message: `If you leave account creation at this time, you will lose all progress from this session.`
                }
            });

            return confirmDialogRef.afterClosed().pipe(
                map((result) => {
                    this.previousUserAnswer = result;
                    switch (result) {
                        case 'continue':
                            return true;
                        case 'return':
                            return false;
                        default:
                            return false;
                    }
                })
            );
        } else {
            const confirmDialogRef = this.dialog.open(UnsavedChangesComponent, {
                minWidth: 320,
                minHeight: 160,
                disableClose: true,
                data: {
                    title: `Unsaved Changes`,
                    message: `How would you like to proceed?`,
                    isExistSaveChangesFunction: component.saveChanges
                }
            });

            return confirmDialogRef.afterClosed().pipe(
                map((result) => {
                    this.previousUserAnswer = result;
                    switch (result) {
                        case 'save':
                            component.saveChanges!();
                            return true;
                        case 'discard':
                            return true;
                        case 'return':
                            return false;
                        default:
                            return false;
                    }
                })
            );
        }
    }
}
