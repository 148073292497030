import {filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {MarketplaceSource} from './marketplace.source';
import {ServiceRequester} from '../../../services/service.requester';
import {NotificationsServiceZipi} from '../../notifications/notifications.service';
import {MarketplaceAddonInstanceModel, MarketplaceAddonModel} from './marketplace.models';
import {CurrentProfileSource} from '../../../services/sources/current-profile.source';
import {Group} from '../../../models/group';
import {Profile} from '../../../models/profile';

export interface ImportYodataAgentsPodsActionResponseInterface {
    yodataAgentsRes: {
        meta: {
            orgName: string;
            numOfRequests: number;
            organizationOfficesUrls: string[];
            officesList: any[];
            agentsUrls: string[];
        };
        value: any[];
    };
    meta: {
        addedNewProfiles: boolean;
        mappingsToCreate: Array<{
            owner__company_fk_id: number;
            profile_fk_id: number;
            company_group_fk_id: number | null;
            pod_uri: string;
        }>;
        mappingsToUpdate: Array<{
            yodata_pod_mapping_id: number;
            pod_uri: string;
        }>;
        updatedMappings: Array<{
            yodata_pod_mapping_id: number;
            pod_uri: string;
        }>;
        createdMappings: Array<{
            id: number;
            yodata_pod_mapping_id: number;
            owner__company_fk_id: number;
            profile_fk_id: number | null;
            company_group_fk_id: number | null;
            pod_uri: string;
            type: string;
        }>;

        mappingsViewModeUIreport: string[];
        mappingsEditModeUIreport: string[];
        mappingsWarningsUIreport: string[];
    };
}

@Injectable()
export class MarketplaceApi {
    constructor(
        protected marketplaceSource: MarketplaceSource,
        public requester: ServiceRequester,
        protected notificationsService: NotificationsServiceZipi,
        protected currentProfileSource: CurrentProfileSource
    ) {
        this.currentProfileSource.changeProfileEvent
            .pipe(filter((profile) => profile.company !== null))
            .subscribe((profile) => {
                this.loadAddons();
            });
    }

    loadAddons(): Promise<any> {
        return this.requester.makeMsCall('/marketplace/load', 'GET').then((addons) => {
            this.marketplaceSource.setLoadedAddons(addons);
            return addons;
        });
    }

    activateAddonForProfile(addon: MarketplaceAddonModel): Promise<any> {
        return this.requester.makeMsCall('/marketplace/' + addon.id + '/profile/activate', 'PUT');
    }

    activateAddon(addon: MarketplaceAddonModel): Promise<any> {
        return this.requester.makeMsCall('/marketplace/' + addon.id + '/activate', 'PUT');
    }

    deactivateAddon(addon: MarketplaceAddonModel): Promise<any> {
        return this.requester.makeMsCall('/marketplace/' + addon.id + '/deactivate', 'PUT');
    }

    pushYodataReport(dealId: number, data: any, uiModInstanceId: number): Promise<any> {
        return this.requester.makeMsCall(
            '/yodata/yodata-report/' + dealId + '/ui-mod-instance/' + uiModInstanceId,
            'POST',
            data
        );
    }

    createYodataMapping(data: {
        type: string;
        pod_uri: string;
        profile_fk_id: number | null;
        company_group_fk_id: number | null;
    }): Promise<{
        id: number | null;
        yodata_pod_mapping_id: number | null;
        owner__company_fk_id: number | null;
        profile_fk_id: number | null;
        company_group_fk_id: number | null;
        pod_uri: string | null;
        type: string | null;
    }> {
        return this.requester.makeMsCall('/yodata/yodata-pod-mapping', 'POST', {data: data});
    }
    updateYodataMapping(
        mappingId: number,
        data: {
            type: string;
            pod_uri: string;
            profile_fk_id: number | null;
            company_group_fk_id: number | null;
        }
    ): Promise<boolean> {
        return this.requester.makeMsCall('/yodata/yodata-pod-mapping/' + mappingId, 'PUT', {data: data});
    }

    importYodataAgentsPodsAction(
        overrideExisting: boolean,
        data: {
            createProfiles: boolean;
        },
        editMode: boolean = false
    ): Promise<ImportYodataAgentsPodsActionResponseInterface> {
        return this.requester.makeMsCall(
            '/yodata/import-agents-pods?override_existing=' + overrideExisting + '&edit_mode=' + editMode,
            'POST',
            data
        );
    }

    createYodataContactAction(data: {zipiProfileId: number; yodataContactData: any}): Promise<{
        createdYodataContact: any;
        createdMapping: {
            id: number;
            yodata_pod_mapping_id: number;
            owner__company_fk_id: number;
            profile_fk_id: number | null;
            company_group_fk_id: number | null;
            pod_uri: string | null;
            type: string;
        };
    }> {
        return this.requester.makeMsCall('/yodata/contact', 'POST', data);
    }

    getDivisionsList(): Promise<Group[]> {
        return this.requester.makeMsCall('/yodata/divisions', 'GET');
    }

    getProfilesListForYodata(
        params = ''
    ): Promise<{data: Profile[]; _meta: {limit: number; offset: number; total: number}}> {
        return this.requester.makeMsCall('/yodata/profiles' + params, 'GET');
    }

    updateAddonInstanceFeatureState(addonInstance: MarketplaceAddonInstanceModel): Promise<any> {
        return this.requester
            .makeMsCall('/marketplace/' + addonInstance.id + '/update/feature_state', 'PUT', {
                addonInstance: addonInstance
            })
            .then((update) => {
                this.loadAddons();
            });
    }

    _getAddonAccessRuleByAddonSlug(addonSlug: string | null): {[key: string]: boolean} {
        let rule;

        switch (addonSlug) {
            case 'dwolla_integration':
            case 'zipi_financial_integration': {
                rule = {marketplace__manage_zipipay: true};
                break;
            }
            case 'authorize_net_integration': {
                rule = {marketplace__manage_authorizenet: true};
                break;
            }
            case 'dotloop_integration': {
                rule = {marketplace__manage_dotloop: true};
                break;
            }
            case 'skyslope_integration': {
                rule = {marketplace__manage_skyslope: true};
                break;
            }
            case 'yodata_integration': {
                rule = {marketplace__manage_yodata: true};
                break;
            }
            case 'dash_integration': {
                rule = {marketplace__manage_dash: true};
                break;
            }
            case 'datastudio_connector': {
                rule = {marketplace__manage_google_data_studio: true};
                break;
            }
            case 'quickbooks_integration': {
                rule = {marketplace__manage_quickbooks: true};
                break;
            }
            default: {
                rule = {};
                break;
            }
        }

        return rule;
    }
}
