<div *ngIf="currentGateway">
    <app-zipi-financial-muhnee-hosted-setup
        [existingGateway]="currentGateway"
        [existingGatewayInfo]="currentGatewayInfo"
        (reloadAndShowFinalPopup)="dialogRef.close()"
        [gatewayType]="currentGateway.type"
        [existingOperatingGateways]="operatingGateways"
    >
    </app-zipi-financial-muhnee-hosted-setup>
</div>
