import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {GenericFormArray, GenericFormGroup} from '../../../../entites/generic.entity';
import {FinancialSource} from '../financial-node/financial-source.model';
import {Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {FinancialTransferEntity} from '../../../account-info/compensation/models/financial-transfer.entity';
import {cleanCurrencyString, currencyWithNegativeMaskitoOptions} from '../../../../utilities/maskito';

@Component({
    selector: 'app-money-collection',
    styles: [
        `
            .flex-row {
                width: 100% !important;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }

            .full-width {
                width: 100% !important;
            }

            .half-width {
                width: 50%;
            }

            .spaced {
                /*margin-left: 5px;*/
                margin-right: 5px;
            }

            .label {
                font-weight: bolder;
                color: grey;
            }

            .remove-item {
                cursor: pointer;
            }
        `
    ],
    template: `
        <div *ngIf="isButtonFirst && buttonLabel" style="margin-top: 12px; margin-bottom: 24px;">
            <button
                type="button"
                [disabled]="disabled"
                mat-stroked-button
                color="primary"
                style="width: 49%"
                (click)="addAdditional()"
            >
                {{ buttonLabel }}
            </button>
        </div>

        <div
            style="justify-content: space-between; display: flex; align-items: center;"
            *ngFor="
                let additional of additionalCommissionType === 'FinancialSource'
                    ? $any(financialSourcesArray.controls)
                    : $any(financialTransfersArray.controls);
                index as ai
            "
        >
            <mat-form-field class="half-width spaced" *ngIf="additional.controls.label">
                <input matInput placeholder="Label" [formControl]="$any(additional.controls.label)" type="text" />
            </mat-form-field>

            <mat-form-field class="half-width spaced" *ngIf="additional.controls.amount">
                <input
                    matInput
                    [maskito]="currencyWithNegativeMaskitoMask"
                    placeholder="Amount"
                    [formControl]="$any(additional.controls.amount)"
                />
            </mat-form-field>
            <button
                mat-icon-button
                color="warn"
                [disabled]="additional.disabled || disabled"
                (click)="removeAdditional(ai)"
            >
                <mat-icon>clear</mat-icon>
            </button>
        </div>

        <ng-content class="flex-row full-width"></ng-content>

        <div
            *ngIf="!isButtonFirst && buttonLabel"
            style="justify-content: space-between; margin-top: 8px; text-align: center"
        >
            <button
                type="button"
                [disabled]="disabled"
                mat-stroked-button
                class=""
                style="width: 90%"
                (click)="addAdditional()"
            >
                {{ buttonLabel }}
            </button>
        </div>
    `
})
export class MoneyCollectionComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    @Input() silent: boolean = false;
    @Input() buttonLabel: string | null = null;
    @Input() isButtonFirst: boolean = false;
    @Input() additionalCommissionType: 'FinancialSource' | 'FinancialTransferEntity' = 'FinancialSource';
    _financialSourcesArray: GenericFormArray<FinancialSource> = new GenericFormArray([]);
    @Input() set financialSourcesArray(fx: GenericFormArray<FinancialSource>) {
        fx.valueChanges
            .pipe(
                tap(() =>
                    fx.controls.forEach((ctrlGroup) =>
                        ctrlGroup
                            .get('amount')!
                            .patchValue(cleanCurrencyString(ctrlGroup.get('amount')?.value), {emitEvent: false})
                    )
                ),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this._financialSourcesArray = fx;
    }
    get financialSourcesArray() {
        return this._financialSourcesArray;
    }
    _financialTransfersArray: GenericFormArray<FinancialTransferEntity> = new GenericFormArray([]);
    @Input() set financialTransfersArray(fx: GenericFormArray<FinancialTransferEntity>) {
        fx.valueChanges
            .pipe(
                tap(() =>
                    fx.controls.forEach((ctrlGroup) =>
                        ctrlGroup
                            .get('amount')!
                            .patchValue(cleanCurrencyString(ctrlGroup.get('amount')?.value), {emitEvent: false})
                    )
                ),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this._financialTransfersArray = fx;
    }
    get financialTransfersArray() {
        return this._financialTransfersArray;
    }
    @Input() disabled: boolean = false;

    @Output() addItem: EventEmitter<GenericFormGroup<any>> = new EventEmitter<GenericFormGroup<any>>();
    @Output() removeItem: EventEmitter<number> = new EventEmitter<number>();

    currencyWithNegativeMaskitoMask = currencyWithNegativeMaskitoOptions;

    constructor(protected dialog: MatDialog) {}

    addAdditional() {
        let newFG: GenericFormGroup<FinancialSource> | GenericFormGroup<FinancialTransferEntity> = new GenericFormGroup(
            new FinancialSource()
        );
        if (this.additionalCommissionType === 'FinancialSource') {
            if (!this.silent) {
                this.financialSourcesArray.push(newFG);
            }
        }
        if (this.additionalCommissionType === 'FinancialTransferEntity') {
            newFG = new GenericFormGroup(new FinancialTransferEntity());

            if (!this.silent) {
                this.financialTransfersArray.push(newFG);
            }
        }

        this.addItem.emit(newFG);
    }

    removeAdditional(index: number) {
        this.removeItem.emit(index);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.addItem.complete();
    }
}
