import {GenericEntity} from '../entites/generic.entity';
import {Wildcard} from './wildcard';
import {IWildcardType} from '@cyberco-nodejs/zipi-typings';

export class WildcardType extends GenericEntity implements IWildcardType {
    title: string | null = null;
    system_key: string | null = null;
    usable_in: string[] = [];
    value_type: string | null = null;

    static get hardcoded() {
        return [
            {
                title: 'Contact',
                system_key: Wildcard.type_SET.contact,
                usable_in: ['sender', 'receiver'],
                value_type: 'contact_id'
            },
            {
                title: 'Text',
                system_key: Wildcard.type_SET.text,
                usable_in: ['financial_transfer.disbursement_instruction', 'sales_entity.disbursement_instruction'],
                value_type: 'string'
            },
            {
                title: 'Numerical',
                system_key: Wildcard.type_SET.numeric,
                usable_in: ['financial_transfer.money_amount'],
                value_type: 'number'
            }
        ];
    }
}
