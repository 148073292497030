<ng-container *ngIf="contactInvite && (formIsLoading$ | async) === false">
    <mat-card class="accept-invite-card" *ngIf="sessionService.user?.email !== contactInvite.invited_email">
        <mat-card-content>
            <p class="auth-warning text-center" *ngIf="sessionService.user">
                You are currently logged in as <i>{{ sessionService.user?.email }}</i>
            </p>
            <p class="auth-warning text-center" *ngIf="!sessionService.user">Account Setup and Authorization</p>
            <!-- <p class="auth-warning text-center">
                You can not accept invite as it was addressed to <b>{{ contactInvite.invited_email }}</b>
            </p> -->
        </mat-card-content>

        <mat-card-actions>
            <button
                *ngIf="contactInvite.user_exists_in_system"
                mat-raised-button
                color="primary"
                (click)="logoutAndGoTo(['auth/sign-in'], 'Login using this email to continue accepting invitation')"
            >
                LOGIN AND ACCEPT INVITE
            </button>
            <button
                *ngIf="contactInvite && contactInvite.invite_hash && !contactInvite.user_exists_in_system"
                mat-raised-button
                color="primary"
                (click)="
                    logoutAndGoTo(
                        ['auth/sign-up-contact-invite', contactInvite.invite_hash],
                        'Register using this email to continue accepting invitation'
                    )
                "
            >
                REGISTER AND ACCEPT INVITE
            </button>
        </mat-card-actions>
    </mat-card>

    <mat-card
        class="accept-invite-card"
        *ngIf="sessionService.user && sessionService.user.email === contactInvite.invited_email"
    >
        <mat-card-header>
            <div class="title">Accept Invite</div>
        </mat-card-header>

        <mat-card-subtitle *ngIf="contactInvite">
            As a {{ contactInvite && contactInvite.company_type | fromSnakeCase }} for
            <b>{{ contactInvite.creator_profile?.email }}</b>
            {{ contactInvite.creator_company?.title }}
        </mat-card-subtitle>

        <mat-card-content>
            <form class="accept-invite-form" *ngIf="acceptInviteForm" [formGroup]="acceptInviteForm">
                <mat-radio-group formControlName="accept_method">
                    <mat-radio-button value="accept_already_created_company" *ngIf="contactInvite.contact_company">
                        Accept with already created company for you -
                        <b>{{ (contactInvite$ | async)!.contact_company!.title }}</b></mat-radio-button
                    >

                    <mat-radio-button value="use_current_company" *ngIf="!contactInvite.contact_company">
                        Accept with current company
                        <b *ngIf="currentProfile && currentProfile.company">" {{ currentProfile.company.title }} "</b>
                    </mat-radio-button>
                    <!--	                <mat-radio-button value="create_new_company"-->
                    <!--	                                  *ngIf="!contactInvite.contact_company">-->
                    <!--		                Create new company and accept invite with it-->
                    <!--	                </mat-radio-button>-->

                    <mat-radio-button value="merge_into_existing_company" [disabled]="true"
                        >Merge into one of your existing companies (not available currently)
                    </mat-radio-button>
                </mat-radio-group>
            </form>
        </mat-card-content>

        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="submitAcceptInviteForm()">ACCEPT INVITE</button>
        </mat-card-actions>
    </mat-card>
</ng-container>

<mat-card class="accept-invite-card centered" *ngIf="formIsLoading$ | async">
    <mat-spinner></mat-spinner>
</mat-card>
