<div class="dialog-container">
    <!------- Header ------->
    <div mat-dialog-title>
        <div class="d-flex flex-row align-items-baseline">
            <h1 mat-dialog-title class="m-0 font-weight-bold">Email Disbursement Authorization</h1>
        </div>
    </div>

    <!------- Body ------->
    <mat-dialog-content>
        <!------- PDF options ------->
        <div class="mb-2" *ngIf="data.dual">
            <mat-radio-group aria-label="Select an option" [formControl]="pdfSelectionFC">
                <mat-radio-button value="deal_disbursement_only">Deal Disbursement Only</mat-radio-button>
                <mat-radio-button value="dual_deal_disbursement">Dual Deal Disbursement</mat-radio-button>
                <mat-radio-button value="both">Both</mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="mb-3">Disbursement PDF will be delivered to the below Recipients:</div>

        <div class="d-flex align-items-center w-100" *ngFor="let recipient of recipientFA.controls; index as idx">
            <div class="d-flex align-items-center mr-1" style="width: calc(50% - 40px)">
                <!------- Recipients selection ------->
                <app-company-compensation-combined-picker
                    *ngIf="!recipient.controls.name.value.length; else recipientNameFilled"
                    class="w-100"
                    [title]="'Recipients'"
                    [availableTypes]="['contact']"
                    [nodesFA]="recipient.controls.name"
                    [bold_style]="false"
                    [singleSelected]="true"
                    displayMode="double-line"
                    [positionMode]="'none'"
                ></app-company-compensation-combined-picker>
                <!------- Recipients shown ------->
                <ng-template #recipientNameFilled>
                    <span>{{ recipient.controls.name.value[0].label }}</span>
                </ng-template>
            </div>
            <!------- Email ------->
            <mat-form-field class="w-50 mr-1">
                <input
                    matInput
                    [formControl]="recipient.controls.email"
                    placeholder="Email"
                    type="text"
                    autocomplete="off"
                    required
                />
            </mat-form-field>

            <!------- Delete button ------->
            <ng-container *ngIf="multipleEmailPDFenabledFlag">
                <button
                    mat-icon-button
                    type="button"
                    *ngIf="
                        idx + 1 !== recipientFA.controls.length || validateEmailSingle(recipient.controls.email.value);
                        else addAdditionalRecipientButton
                    "
                    (click)="removeAdditionalRecipient(idx)"
                >
                    <mat-icon>cancel</mat-icon>
                </button>
                <!------- Add button ------->
                <ng-template #addAdditionalRecipientButton>
                    <button
                        mat-icon-button
                        type="button"
                        (click)="addAdditionalRecipient()"
                        [disabled]="
                            !recipient.controls.email.value || validateEmailSingle(recipient.controls.email.value)
                        "
                    >
                        <mat-icon>add_circle</mat-icon>
                    </button>
                </ng-template>
            </ng-container>
        </div>
    </mat-dialog-content>

    <!------- Buttons ------->
    <mat-dialog-actions class="text-right">
        <div class="d-flex justify-content-end w-100 py-2">
            <button type="button" mat-raised-button tabindex="-1" (click)="dialogRef.close()">Cancel</button>
            <button
                type="button"
                mat-raised-button
                color="primary"
                tabindex="1"
                (click)="save()"
                [disabled]="!pdfSelectionFC.value || validateEmails()"
            >
                Send Email
            </button>
        </div>
    </mat-dialog-actions>
</div>
